import {
  Badge,
  Button,
  HStack,
  PrimaryField,
  space,
  TextInput,
  Tooltip,
  VStack,
} from '@meterup/atto';
import { useGraphQLMutation } from '@meterup/graphql';
import useCompanySlug from 'connect-frontend/src/dashboard/hooks/useCompanySlug';
import React, { useMemo } from 'react';

import type { Maybe, SsoConnection } from '../../../../gql/graphql';
import { Box } from '../../../../components/Box';
import { SsoAdminPortalType, SsoConnectionState } from '../../../../gql/graphql';
import { useIdentity } from '../../../../providers/IdentityDataProvider';
import AdminPortalCopyBox from './AdminPortalCopyBox';
import { ConfigureSSOMutation } from './configureSSOMutation';
import { DirectoryTypeLogo } from './DirectoryTypeLogo';
import useCreatePortalURL from './useCreatePortalURL';

type ConfigureSSOProps = {
  connections?: Maybe<SsoConnection[]>;
};

function SSOConnectionStateBadge({ connection }: { connection?: SsoConnection }) {
  if (connection?.state === SsoConnectionState.Active) {
    return (
      <Badge variant="positive" icon="checkmark" arrangement="leading-icon">
        Configured
      </Badge>
    );
  }
  if (connection?.state === SsoConnectionState.Validating) {
    return (
      <Badge variant="neutral" icon="clock" arrangement="leading-icon">
        Validating
      </Badge>
    );
  }
  if (connection?.state === SsoConnectionState.Inactive) {
    return (
      <Badge variant="negative" icon="cross" arrangement="leading-icon">
        Inactive
      </Badge>
    );
  }
  if (connection?.state === SsoConnectionState.Draft) {
    return (
      <Badge variant="neutral" icon="clock" arrangement="leading-icon">
        Draft
      </Badge>
    );
  }
  return null;
}

export default function ConfigureSSO({ connections }: ConfigureSSOProps) {
  const mutation = useGraphQLMutation(ConfigureSSOMutation);
  const identity = useIdentity();

  const userEmail = identity.username;
  const companySlug = useCompanySlug();
  const [state, , onSubmit] = useCreatePortalURL({
    companySlug,
    portalType: SsoAdminPortalType.SsoProvider,
  });
  const domain = useMemo(() => {
    const emailParts = userEmail.split('@');
    if (emailParts.length !== 2) {
      return '';
    }
    return emailParts[1];
  }, [userEmail]);
  const connectionsByState = useMemo(() => {
    if (!connections) {
      return {} as Record<SsoConnectionState, SsoConnection[] | undefined>;
    }
    return connections.reduce(
      (acc, connection) => {
        const conns = acc[connection.state] || [];
        conns.push(connection);
        acc[connection.state] = conns;
        return acc;
      },
      {} as Record<SsoConnectionState, SsoConnection[] | undefined>,
    );
  }, [connections]);

  const isLoading = useMemo(
    () => !companySlug || mutation.isLoading,
    [companySlug, mutation.isLoading],
  );

  const activeConnection = connectionsByState[SsoConnectionState.Active]?.[0];
  const draftConnection = connectionsByState[SsoConnectionState.Draft]?.[0];
  const inactiveConnection = connectionsByState[SsoConnectionState.Inactive]?.[0];
  const validatingConnection = connectionsByState[SsoConnectionState.Validating]?.[0];

  const currentConnection =
    activeConnection || validatingConnection || draftConnection || inactiveConnection;

  return (
    <PrimaryField
      badges={<SSOConnectionStateBadge connection={currentConnection} />}
      label="SSO"
      description="Once configured, sign in to the Meter Dashboard will be restricted to SSO with this email domain."
      element={
        <VStack spacing={space(12)}>
          <HStack spacing={space(8)}>
            <Tooltip contents="This is set based on your email address. To change it, contact Meter support.">
              <Box css={{ flex: 1 }}>
                <TextInput name="domain" value={domain} aria-label="domain" disabled />
              </Box>
            </Tooltip>
            <Button
              variant="primary"
              onClick={onSubmit}
              css={{ overflow: 'visible' }}
              loading={isLoading}
              disabled={isLoading}
              aria-disabled={isLoading}
            >
              Configure
            </Button>
          </HStack>
          {state.portalURL ? <AdminPortalCopyBox portalURL={state.portalURL} /> : null}
          {activeConnection ? (
            <HStack spacing={space(8)}>
              <DirectoryTypeLogo type={activeConnection.type} />
            </HStack>
          ) : null}
        </VStack>
      }
      errorMessage={state.errorMessage}
    />
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  DetailHeader,
  expectDefinedOrThrow,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useNavigate } from 'react-router-dom';

import { linkToSwitchPort } from '../../../../../../components/Connections';
import { DetailPageLayout } from '../../../../../../components/DetailPageLayout';
import { FindLLDPEntryOnSwitchQuery } from '../../../../../../components/Hardware/Switches/utils';
import { AccessPointQuery } from '../../../../../../components/Wireless/utils';
import { paths } from '../../../../../../constants';
import { PermissionType } from '../../../../../../gql/graphql';
import { useNetworkAndActiveControllerForUUID } from '../../../../../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../../nav';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../../../../providers/PermissionsProvider';

function AccessPointActions({ macAddress }: { macAddress: string }) {
  const network = useNetwork();
  const networkAndControllerSerial = useNetworkAndActiveControllerForUUID(network.UUID);
  const companyName = useCurrentCompany();
  const navigate = useNavigate();

  const device = useGraphQL(
    FindLLDPEntryOnSwitchQuery,
    { networkUUID: network.UUID, mac: macAddress },
    { useErrorBoundary: false },
  ).data?.findSwitchLLDPEntryForMAC;

  if (!device || !networkAndControllerSerial?.network) return null;

  const link = linkToSwitchPort({
    networkSlug: networkAndControllerSerial.network.slug,
    companyName,
    switchUUID: device.virtualDevice.UUID,
    portUUID: device.phyInterface.UUID,
  });

  return (
    <DropdownMenu>
      <DropdownMenuButton variant="secondary" icon="overflow-horizontal" arrangement="hidden-label">
        Actions
      </DropdownMenuButton>
      <DropdownMenuPopover align="end">
        <DropdownMenuItem onSelect={() => navigate(link)} icon="switch">
          View switch
        </DropdownMenuItem>
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}

function AccessPointClientListDetailPageHeader() {
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.AccessPointClientsListDetailPage),
  );

  const virtualDevice = useGraphQL(AccessPointQuery, { uuid, includeUptime }).data?.virtualDevice;

  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Client not found'));

  return (
    <DetailHeader
      icon="access-point"
      heading={virtualDevice.label}
      type="access-point"
      actions={
        virtualDevice.hardwareDevice?.macAddress ? (
          <AccessPointActions macAddress={virtualDevice.hardwareDevice?.macAddress} />
        ) : undefined
      }
    />
  );
}

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/devices/:uuid',
  layout: 'VirtualNetworkLayout',
});

export default function AccessPointClientListDetailLayout() {
  return <DetailPageLayout header={<AccessPointClientListDetailPageHeader />} />;
}

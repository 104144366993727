import type { ResultItem } from '@meterup/graphql';
import type { SortingState } from '@tanstack/react-table';
import {
  Badge,
  Button,
  EmptyState,
  Pane,
  PaneContent,
  PaneHeader,
  Text,
  Tooltip,
} from '@meterup/atto';
import { deletedAtToStatus, highestRole, roleToName, useIsOperator } from '@meterup/authorization';
import {
  AutoTable,
  checkDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import type { GetCompanyUsersQuery } from '../../gql/graphql';
import { paths } from '../../constants';
import { RoleName } from '../../gql/graphql';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { KeyBoundSearchInput } from '../KeyBoundSearchInput';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { useCompanyUsers } from './utils/queries';

type CompanyUser = ResultItem<GetCompanyUsersQuery, 'companyUsers'>;

function getRoleVariant(role: string) {
  switch (role) {
    case 'Company admin':
      return 'negative';
    case 'Network admin':
      return 'negative';
    case 'Guest':
      return 'neutral';
    default:
      return 'neutral';
  }
}

function StatusBadge({ row }: { row: CompanyUser }) {
  if (row.deletedAt) {
    return (
      <Tooltip
        contents={DateTime.fromISO(row.deletedAt).toLocaleString(
          DateTime.DATETIME_FULL_WITH_SECONDS,
        )}
      >
        <Badge ends="card" size="small" variant="neutral">
          Deleted
        </Badge>
      </Tooltip>
    );
  }

  return (
    <Badge ends="card" size="small" variant="positive">
      Active
    </Badge>
  );
}

const builder = createColumnBuilder<CompanyUser>();
const columns = [
  builder.data((row) => row.user.email, {
    id: 'email',
    header: 'Email',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.user.firstName, {
    id: 'firstName',
    header: 'First name',
  }),
  builder.data((row) => row.user.lastName, {
    id: 'lastName',
    header: 'Last name',
  }),
  builder.data((row) => roleToName(highestRole(row.roles.map((x) => x.name))), {
    id: 'role',
    header: 'Role',
    cell: ({ value }) => (
      <Badge
        ends="card"
        internal={value === 'Operator'}
        size="small"
        variant={getRoleVariant(value)}
      >
        {value}
      </Badge>
    ),
  }),
  builder.data((row) => deletedAtToStatus(row.deletedAt), {
    id: 'status',
    header: 'Status',
    cell: ({ row }) => <StatusBadge row={row} />,
  }),
  builder.data((row) => row.createdAt, {
    id: 'createdAt',
    header: 'Created at',
    meta: {
      alignment: 'end',
    },
    cell: ({ value }) => (
      <Tooltip
        contents={DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
      >
        <Text>{DateTime.fromISO(value).toRelative()}</Text>
      </Tooltip>
    ),
  }),
];

function UsersListForCompany({ companySlug, back }: { companySlug: string; back?: () => void }) {
  const data = useCompanyUsers(companySlug);
  const companyUsersOriginal = checkDefinedOrThrow(
    data?.data?.companyUsers,
    new ResourceNotFoundError('No company users found'),
  );
  const isOperator = useIsOperator({ respectDemoMode: true });
  const isTrueOperator = useIsOperator({ respectDemoMode: false });
  const companyUsers = useMemo(
    () =>
      companyUsersOriginal.flatMap((cu) => {
        // If the user viewing the page isn't a true operator or they're an operator in context of demo mode, just render normally.
        if (!isTrueOperator || isOperator) {
          return [cu];
        }

        // In this case, the user viewing the page is a true operator but demo mode is turned on. Filter out
        // the operators from the API response.
        if (highestRole(cu.roles.map((r) => r.name)) === RoleName.Operator) {
          return [];
        }

        return [cu];
      }),
    [companyUsersOriginal, isOperator, isTrueOperator],
  );
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      id: 'email',
      desc: false,
    },
  ]);
  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.UserDrawerPage);

  return (
    <Pane>
      <PaneHeader
        icon="user"
        heading="Users"
        count={companyUsers.length}
        back={back}
        actions={
          <>
            <Button
              condense
              size="small"
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.CreateUserPage, {
                companyName: companySlug,
              })}
              type="button"
              variant="secondary"
              icon="plus"
              arrangement="leading-icon"
            >
              Add user
            </Button>
            <KeyBoundSearchInput
              placeholder="..."
              aria-label="Filter users"
              scope="scoped"
              searchParamStateKey="filter"
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent gutter="bottom" ref={tableContainerRef}>
        {companyUsers.length > 0 ? (
          <AutoTable
            data={companyUsers}
            columns={columns}
            isVirtual
            tableContainerRef={tableContainerRef}
            getLinkTo={(row) =>
              makeDrawerLink(window.location, paths.drawers.UserDrawerPage, {
                companyName: companySlug,
                uuid: row.UUID,
              })
            }
            globalFilter={globalFilter}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            exportToCSVFilename="Users list"
            isRowSelected={(row) => row.UUID === drawerParams?.uuid}
            isRowDisabled={(row) => row.deletedAt !== null}
          />
        ) : (
          <EmptyState icon="user" heading="No users found" />
        )}
      </PaneContent>
    </Pane>
  );
}

function UsersListForCurrentCompany({ back }: { back?: () => void }) {
  const companySlug = useCurrentCompany();
  return <UsersListForCompany companySlug={companySlug} back={back} />;
}

export default function UsersList({
  companySlug,
  back,
}: {
  companySlug?: string;
  back?: () => void;
}) {
  if (!isDefinedAndNotEmpty(companySlug)) {
    return <UsersListForCurrentCompany back={back} />;
  }

  return <UsersListForCompany companySlug={companySlug} back={back} />;
}

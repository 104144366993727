import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  PrimaryField,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { orderBy } from 'lodash-es';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FieldProvider } from '../../../../../components/Form/FieldProvider';
import { paths } from '../../../../../constants';
import { useNetworkClients } from '../../../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { logError } from '../../../../../utils/logError';

const validClientFormData = z.object({
  alias: z.string().optional(),
});

type ValidClientFormData = z.infer<typeof validClientFormData>;

const StyledForm = styled(Form, {
  display: 'contents',
});

const successfulMutationString = (
  originalValues: { alias: string; hostname: string },
  newValue = '',
): string => {
  const originalName = originalValues.alias || originalValues.hostname;

  if (newValue === '') {
    return `Alias removed from ${originalName}.`;
  }
  if (originalValues.alias) {
    return `${originalName} was renamed to ${newValue}.`;
  }

  return `Added ${newValue} as an alias for ${originalName}.`;
};

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/clients/:macAddress/rename',
});

export default function ClientRenamePage() {
  const controller = useCurrentControllerData();
  const api = useAPIClient();
  const { macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ClientRenamePage),
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const network = useNetwork();

  const clientHistory = useNetworkClients(network, {
    filter: { macAddress, excludeMeterHardware: true },
  });

  const client = orderBy(clientHistory, (d) => d.lastSeen, 'desc')[0] ?? null;

  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));

  const createUserMutation = useMutation(
    async (values: ValidClientFormData) =>
      api.upsertMACAddressAlias(macAddress, values.alias || ''),
    {
      onMutate: () => ({ originalValues: { alias: client.alias!, hostname: client.clientName! } }),
      onSuccess: (_, v, context) => {
        notify(successfulMutationString(context!.originalValues, v.alias), {
          variant: 'positive',
          icon: 'checkmark',
        });
        queryClient.invalidateQueries(['client_history', macAddress]);
        queryClient.invalidateQueries(['clients', controller]);
        queryClient.invalidateQueries(['controller', controller, 'clients']);
        navigate(-1);
      },
      onError: (e) => {
        notify('There was an error setting the name. Please try again.', {
          variant: 'negative',
          icon: 'cross',
        });
        logError(e);
      },
    },
  );

  return (
    <Formik<ValidClientFormData>
      validationSchema={toFormikValidationSchema(validClientFormData)}
      initialValues={{ alias: client.alias! }}
      onSubmit={(v) => createUserMutation.mutate(v)}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader icon="client" heading="Rename client" onClose={useCloseDrawerCallback()} />
          <DrawerContent>
            <Alert
              icon="information"
              variant="alternative"
              heading="Renaming a client"
              copy={
                <VStack spacing={space(8)}>
                  <div>
                    This sets or changes the existing alias for the client but the client’s name
                    will continue to show in places where aliases aren’t supported.
                  </div>
                </VStack>
              }
            />
            <FieldProvider name="alias">
              <PrimaryField label="New name" element={<TextInput />} />
            </FieldProvider>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={() => navigate(-1)} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </Drawer>
      </StyledForm>
    </Formik>
  );
}

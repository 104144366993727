import { darkThemeSelector, sizing, Small, styled } from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { colors, shadows } from '@meterup/common';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';

import type { TimeSeries } from '../../../utils/chart_utils';

export const ChartLegendColor = styled('div', {
  display: 'flex',
  width: '10px',
  height: '10px',
  borderRadius: '$2',
});

const ChartLegendLabel = styled(Small, LegendLabel, {
  variants: {
    active: {
      true: {
        color: colors.headingBrandLight,
        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
      false: {},
    },
  },
});

const ChartLegendItem = styled(LegendItem, {
  hStack: '$4',
  padding: '$2 $4',
  borderRadius: '$6',
  strokeAll: colors.transparent,
  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },
  [selectors.hover]: {
    cursor: 'pointer',
  },
  variants: {
    active: {
      true: {
        backgroundColor: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,
        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },
      },
    },
  },
});

const ChartLegendContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '$4',
  height: '100%',
  maxHeight: '$84', // Roughly three rows of legend items.
  padding: `$6 ${sizing.sides}`,
  overflow: 'auto',
});

export function ChartLegend({
  handleSelectItem,
  series,
  chartColors,
  selectedItem,
}: {
  handleSelectItem: (item: string) => void;

  selectedItem: string;
  series: TimeSeries[];
  chartColors: string[];
}) {
  const legendDomain = series.map((s) => s.series_id);

  const ordinalColorScale = scaleOrdinal({
    domain: legendDomain,
    range: chartColors,
  });

  return (
    <LegendOrdinal direction="row" scale={ordinalColorScale}>
      {(labels) => (
        <ChartLegendContainer>
          {labels.map((label) => (
            <ChartLegendItem
              active={selectedItem === label.datum}
              key={label.text}
              tabIndex={0}
              onClick={() => {
                handleSelectItem(label.datum);
              }}
            >
              <ChartLegendColor style={{ backgroundColor: label.value as string }} />
              <ChartLegendLabel weight="bold">{label.text}</ChartLegendLabel>
            </ChartLegendItem>
          ))}
        </ChartLegendContainer>
      )}
    </LegendOrdinal>
  );
}

import type { BadgeVariant, ButtonSizeProp, ButtonVariantProp } from '@meterup/atto';
import {
  Badge,
  Button,
  EmptyState,
  HStack,
  Icon,
  PaneContent,
  space,
  styled,
  Text,
  Tooltip,
  VStack,
} from '@meterup/atto';
import { AutoTable, notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import type {
  InterfaceBindings,
  PolicyRoutingRule,
  PolicyRoutingRuleBinding,
  PolicyRoutingRuleInterface,
  PolicyRoutingRulePhyInterface,
} from './utils';
import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { ucfirst } from '../../utils/strings';
import {
  displayIPProtocol,
  labelForRuleInterface,
  OrderButtons,
  PortRangeCell,
  prefixForRule,
  ReorderAction,
  sortBindings,
} from '../Firewall/utils';
import { NoValue } from '../NoValue';
import IsPermitted from '../permissions/IsPermitted';
import { RuleControls } from '../RuleControls';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import {
  labelForBindingType,
  policyRoutingRulesForNetworkQuery,
  updateBindingsForPolicyRoutingRuleMutation,
} from './utils';

type PolicyRoutingBindingWithRule = PolicyRoutingRuleBinding & { rule: PolicyRoutingRule };

const builder = createColumnBuilder<PolicyRoutingBindingWithRule>();

const PrefixContainer = styled(HStack, {
  whiteSpace: 'nowrap',
});

const baseColumns = [
  builder.data((row) => (row.rule.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      width: 40,
      tooltip: {
        contents: 'Enabled',
      },
    },
    cell: ({ row }) =>
      row.rule.isEnabled ? (
        <Tooltip contents="Enabled">
          <Icon
            icon="checkmark"
            color={{
              light: 'iconPositiveLight',
              dark: 'iconPositiveDark',
            }}
            size={space(16)}
          />
        </Tooltip>
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.rule.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.rule.protocols.join(', '), {
    id: 'protocols',
    header: 'Protocols',
    size: 128,
    cell: ({ row }) => (
      <HStack spacing={space(4)}>
        {row.rule.protocols.map((protocol) => (
          <Badge size="small" ends="card" variant="neutral">
            {displayIPProtocol(protocol)}
          </Badge>
        ))}
      </HStack>
    ),
  }),
  builder.data((row) => prefixForRule(row.rule, 'src') ?? '', {
    id: 'source-prefix',
    header: 'Source / mask',
    cell: ({ value, row }) =>
      !value || value === '0.0.0.0/0' ? (
        <Badge size="small" ends="card" variant="neutral">
          Any
        </Badge>
      ) : (
        <PrefixContainer spacing={space(8)} align="center">
          {row.rule.srcVLAN && <Icon icon="vlan" />}
          <Text family="monospace">{value.replace('/', ' / ')}</Text>
        </PrefixContainer>
      ),
  }),
  builder.data(
    (row) =>
      row.rule.srcPortRanges.map((portRange) => `${portRange.lower}-${portRange.upper}`).join(', '),
    {
      id: 'source-ports',
      header: 'Source ports',
      cell: ({ row }) => (
        <>
          {row.rule.srcPortRanges.map((portRange) => (
            <PortRangeCell row={row} portRange={portRange} />
          ))}
        </>
      ),
    },
  ),
  builder.data((row) => prefixForRule(row.rule, 'dst') ?? '', {
    id: 'dest-prefix',
    header: 'Destination / mask',
    cell: ({ value }) =>
      !value || value === '0.0.0.0/0' ? (
        <Badge size="small" ends="card" variant="neutral">
          Any
        </Badge>
      ) : (
        <PrefixContainer spacing={space(8)} align="center">
          <Text family="monospace">{value.replace('/', ' / ')}</Text>
        </PrefixContainer>
      ),
  }),
  builder.data(
    (row) =>
      row.rule.dstPortRanges.map((portRange) => `${portRange.lower}-${portRange.upper}`).join(', '),
    {
      id: 'dest-ports',
      header: 'Destination ports',
      cell: ({ row }) => (
        <>
          {row.rule.dstPortRanges.map((portRange) => (
            <PortRangeCell row={row} portRange={portRange} />
          ))}
        </>
      ),
    },
  ),
];

export function InterfaceTable({
  data,
  ...restProps
}: {
  data: [string, PolicyRoutingRuleInterface];
}) {
  const ruleInterface = data[1];
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.PolicyRoutingRuleEditPage);

  const closeDrawer = useCloseDrawerCallback();
  const [bindings, setBindings] = useState<PolicyRoutingBindingWithRule[]>(ruleInterface.bindings);
  const isDirty = useMemo(
    () =>
      bindings !== ruleInterface.bindings &&
      bindings.some((binding, i) => binding !== ruleInterface.bindings[i]),
    [bindings, ruleInterface.bindings],
  );
  useEffect(() => {
    setBindings(ruleInterface.bindings);
  }, [ruleInterface.bindings]);

  const handleReorder = useCallback(
    (index: number, action: ReorderAction) => {
      setBindings((prev) => {
        let nextIndex: number;
        switch (action) {
          case ReorderAction.Decrease:
            if (index === 0) return prev;
            nextIndex = index - 1;
            break;
          case ReorderAction.Increase:
            if (index === prev.length - 1) return prev;
            nextIndex = index + 1;
            break;
        }

        const next = [...prev];
        const tmp = next[nextIndex];
        next[nextIndex] = next[index];
        next[index] = tmp;
        return next;
      });
    },
    [setBindings],
  );

  const columns = useMemo(
    () => [
      builder.display({
        id: 'order',
        meta: {
          width: 68,
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row, table }) => (
          <OrderButtons
            key={`${row.id}-${row.index}` /* to prevent incorrect button enabled state flashing */}
            index={row.index}
            length={table.getRowModel().rows.length}
            handleReorder={handleReorder}
          />
        ),
      }),
      ...baseColumns,
    ],
    [handleReorder],
  );

  const handleCancel = useCallback(() => {
    setBindings(ruleInterface.bindings);
  }, [ruleInterface.bindings]);

  const updateBindings = useGraphQLMutation(updateBindingsForPolicyRoutingRuleMutation);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(() => {
    const orderedRuleUUIDs = bindings.map((b) => b.rule.UUID);
    const bindingType = ruleInterface.__typename;
    const binding = {
      phyInterfaceUUID: ruleInterface.UUID,
    };

    updateBindings.mutate(
      { input: { binding }, orderedRuleUUIDs },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(policyRoutingRulesForNetworkQuery, { networkUUID: network.UUID }),
          );
          notify(`Successfully updated bindings for ${labelForBindingType(bindingType)}.`, {
            variant: 'positive',
          });
        },
        onError: (error) => {
          notify(
            `There was a problem updating bindings for ${labelForBindingType(bindingType)}${getGraphQLErrorMessageOrEmpty(error)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [
    ruleInterface.__typename,
    ruleInterface.UUID,
    updateBindings,
    queryClient,
    bindings,
    network.UUID,
  ]);

  return (
    <VStack width="full">
      <AutoTable
        {...restProps}
        columns={columns}
        data={bindings}
        enableSorting={false}
        getRowId={(row) => row.rule.UUID}
        getLinkTo={(row) =>
          makeDrawerLink(window.location, paths.drawers.PolicyRoutingRuleEditPage, {
            ruleUUID: row.rule.UUID,
            companyName,
            networkSlug: network.slug,
          })
        }
        isRowSelected={(row) => row.rule.UUID === drawerParams?.ruleUUID}
        onRowDeselect={closeDrawer}
        actions={
          isDirty && (
            <RuleControls relation="separate" size="small">
              <Button onClick={handleCancel} variant="secondary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} variant="primary">
                Save
              </Button>
            </RuleControls>
          )
        }
      />
    </VStack>
  );
}

export function AddPolicyRoutingRuleButton({
  variant = 'secondary',
  size,
}: {
  variant?: ButtonVariantProp;
  size?: ButtonSizeProp;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Button
      condense
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.PolicyRoutingRuleCreatePage, {
        companyName,
        networkSlug: network.slug,
      })}
      arrangement="leading-icon"
      icon="plus"
      variant={variant}
      size={size}
    >
      Add policy routing rule
    </Button>
  );
}

type PhyInterfaceStatus = 'active' | 'ready' | 'disconnected' | 'disabled';

function statusForPhyInterface(phyInterface: PolicyRoutingRulePhyInterface): PhyInterfaceStatus {
  if (phyInterface.isUplinkActive) return 'active';

  if (phyInterface.hasWANActivity) return 'ready';

  if (!phyInterface.isEnabled) return 'disabled';

  return 'disconnected';
}

// eslint-disable-next-line consistent-return
function variantForPhyInterfaceStatus(status: PhyInterfaceStatus): BadgeVariant {
  switch (status) {
    case 'active':
      return 'positive';
    case 'ready':
      return 'neutral';
    case 'disconnected':
      return 'negative';
    case 'disabled':
      return 'disabled';
  }
}

function BadgeForPhyInterface({ row }: { row: [string, PolicyRoutingRuleInterface] }) {
  const status = statusForPhyInterface(row[1]);

  return (
    <Tooltip contents={ucfirst(status)} asChild={false}>
      <Badge
        arrangement="hidden-label"
        ends="pill"
        icon="globe"
        size="small"
        variant={variantForPhyInterfaceStatus(status)}
      >
        {ucfirst(status)}
      </Badge>
    </Tooltip>
  );
}

const policyRoutingColumnsBuilder = createColumnBuilder<[string, PolicyRoutingRuleInterface]>();
const policyRoutingColumns = [
  policyRoutingColumnsBuilder.data((row) => statusForPhyInterface(row[1]), {
    id: 'wan-status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status of the WAN interface',
      },
    },
    cell: BadgeForPhyInterface,
  }),
  policyRoutingColumnsBuilder.data((row) => labelForRuleInterface(row[1]), {
    header: 'WAN',
    id: 'wan',
    cell: ({ row }) => (
      <Text variant="tabular" weight="bold">
        {labelForRuleInterface(row[1])}
      </Text>
    ),
  }),
];

export default function PolicyRouting() {
  const network = useNetwork();
  const policyRoutingRules = useGraphQL(policyRoutingRulesForNetworkQuery, {
    networkUUID: network.UUID,
  }).data?.policyRoutingRulesForNetwork;

  // Return is checked by types
  // eslint-disable-next-line consistent-return
  const interfacesToRules: InterfaceBindings = useMemo(() => {
    const dict: Record<string, PolicyRoutingRuleInterface> = {};
    for (const rule of policyRoutingRules ?? []) {
      if (!rule?.bindings) continue;
      for (const binding of rule.bindings) {
        if (binding.phyInterface) {
          if (!(binding.phyInterface.UUID in dict)) {
            dict[binding.phyInterface.UUID] = { ...binding.phyInterface, bindings: [] };
          }
          dict[binding.phyInterface.UUID].bindings.push({ ...binding, rule });
        }
      }
    }
    for (const i of Object.values(dict)) {
      i.bindings.sort(sortBindings);
    }

    return dict;
  }, [policyRoutingRules]);

  const sortedInterfaceBindingsEntries = useMemo(() => {
    const entries = Object.entries(interfacesToRules);
    entries.sort(([, a], [, b]) =>
      labelForRuleInterface(a).localeCompare(labelForRuleInterface(b)),
    );
    return entries;
  }, [interfacesToRules]);

  if (!policyRoutingRules?.length) {
    return (
      <EmptyState
        icon="connection"
        heading="You have no policy routing rules"
        action={
          <IsPermitted permissions={PermissionType.PermNetworkRouteWrite}>
            <AddPolicyRoutingRuleButton />
          </IsPermitted>
        }
      />
    );
  }

  return (
    <PaneContent>
      <AutoTable
        columns={policyRoutingColumns}
        data={sortedInterfaceBindingsEntries}
        renderSubTable={InterfaceTable}
        subTableCollapsedByDefault={false}
      />
    </PaneContent>
  );
}

import type { SortingState } from '@tanstack/react-table';
import {
  Badge,
  Body,
  Drawer,
  DrawerContent,
  DrawerHeader,
  EmptyState,
  Icon,
  ManufacturerIcon,
  space,
} from '@meterup/atto';
import { AutoTable, checkDefinedOrThrow } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { Box } from '../../../../../components/Box';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import {
  type NetworkClient,
  useNetworkClients,
} from '../../../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { styled } from '../../../../../stitches';
import {
  clientNameOrNull,
  getClientConnectionStatus,
  getClientSortKey,
  isOnline,
  isWireless,
  manufacturerIconName,
} from '../../../../../utils/clientLists';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/devices/:deviceName/clients',
});

const StyledDrawerContent = styled(DrawerContent, {
  padding: 0,
});

const builder = createColumnBuilder<NetworkClient>();

const columns = [
  builder.data((d) => `${getClientConnectionStatus(d)} ${isWireless(d) ? 'wireless' : 'wired'}`, {
    id: 'status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: (p) => (
      <Badge
        arrangement="hidden-label"
        variant={isOnline(p.row) ? 'positive' : 'neutral'}
        icon={isWireless(p.row) ? 'wifi' : 'wired'}
        size="small"
        ends="pill"
      >
        {isOnline(p.row) ? 'Online' : 'Offline'}
      </Badge>
    ),
  }),
  builder.data((row) => getClientSortKey(row), {
    id: 'icon',
    header: '',
    meta: { alignment: 'center', width: 36 },
    cell: (p) => <ManufacturerIcon icon={manufacturerIconName(p.row)} size={20} />,
  }),
  builder.data((row) => getClientSortKey(row), {
    id: 'name',
    header: 'Name',
    meta: {
      minWidth: 44,
      isLeading: true,
    },
    cell: (p) => (
      <Box css={{ truncate: true }}>
        {clientNameOrNull(p.row) ?? <Body family="monospace">-</Body>}
      </Box>
    ),
  }),
];

export default function ClientsListPage() {
  const { deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ClientsListPage),
  );

  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const network = useNetwork();
  const apiClient = useAPIClient();

  const { data: ap } = useQuery(
    ['device_with_radio', controllerName, deviceName],
    () => apiClient.accessPoint(deviceName),
    {
      suspense: true,
    },
  );

  const clients = useNetworkClients(network, {
    deviceName,
    filter: {
      apSerialNumber: ap?.serial_number,
      excludeMeterHardware: true,
    },
  });

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const linkTo = useCallback(
    (row: any) =>
      row.lease?.mac
        ? makeLink(paths.pages.ClientDetailPage, {
            macAddress: row.lease?.mac!,
            companyName,
            controllerName,
          })
        : null,
    [companyName, controllerName],
  );

  return (
    <Drawer>
      <DrawerHeader icon="client" heading="Clients" onClose={useCloseDrawerCallback()} />
      <StyledDrawerContent>
        {clients.length > 0 && (
          <AutoTable
            size="small"
            columns={columns}
            data={clients}
            getLinkTo={linkTo}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            isRowSelected={() => false}
          />
        )}
        {clients.length === 0 && <EmptyState icon="client" heading="No clients on this network" />}
      </StyledDrawerContent>
    </Drawer>
  );
}

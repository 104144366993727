import {
  Badge,
  Body,
  Box,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  HStack,
  Icon,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  Small,
  space,
  styled,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Tooltip,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import IsOperator from '@meterup/authorization/src/components/IsOperator';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  getEnabledRadioForBandOrNull,
  isDefinedAndNotEmpty,
  isGuest,
  isOnline,
  OnlineOfflineClientCountBadge,
  WirelessBandDetails,
} from '@meterup/common';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import type { AccessPointDeviceData } from '../../../api/types';
import { paths } from '../../../constants';
import { useNetworkClients } from '../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useAPIClient } from '../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../providers/CurrentControllerProvider';
import { getAPNameForDevice } from '../../../utils/access_point_utils';
import formatTime from '../../../utils/formatTime';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { secToHuman } from '../../../utils/time';
import { ConnectedSwitchSection } from '../../Connections';
import { NoValue } from '../../NoValue';
import { StickyObjectHeader } from '../../Object/ObjectHeader';

const TooltipIcon = styled(Icon, {
  display: 'flex',
});

function AccessPointDetailsWidget({
  data,
  uptime,
}: {
  data: AccessPointDeviceData;
  uptime: number;
}) {
  const network = useNetwork();

  const networkClients = useNetworkClients(network, {
    filter: {
      macAddress: data.mac_address,
    },
  });

  const lastAPClientConnection = networkClients[0] ?? null;

  const ipAddress = data.ip_address || lastAPClientConnection?.ip;

  return (
    <Section relation="stacked">
      <SectionHeader icon="information" heading="Metadata" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <IsOperator>
            <SummaryListRow internal>
              <SummaryListKey>Version</SummaryListKey>
              <SummaryListValue>
                <Badge internal size="small">
                  {data.firmware_version}
                </Badge>
              </SummaryListValue>
            </SummaryListRow>
          </IsOperator>
          <SummaryListRow>
            <SummaryListKey>IP</SummaryListKey>
            <SummaryListValue>
              {ipAddress ? (
                <HStack align="center" spacing={space(6)}>
                  <CopyBox
                    aria-label="Copy IP address to clipboard"
                    relation="stacked"
                    size="small"
                    value={ipAddress}
                  >
                    <Body family="monospace">{ipAddress}</Body>
                  </CopyBox>
                  {lastAPClientConnection?.lastSeen && ipAddress === lastAPClientConnection.ip && (
                    <Tooltip
                      asChild
                      toggle
                      contents={
                        <Small>
                          Last updated{' '}
                          {formatTime(
                            DateTime.fromISO(lastAPClientConnection?.lastSeen).toJSDate(),
                          )}
                        </Small>
                      }
                    >
                      <Box justify="center">
                        <TooltipIcon icon="information" size={space(12)} />
                      </Box>
                    </Tooltip>
                  )}
                </HStack>
              ) : (
                <NoValue />
              )}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>MAC</SummaryListKey>
            <SummaryListValue>
              {data.mac_address ? (
                <CopyBox
                  aria-label="Copy MAC address to clipboard"
                  relation="stacked"
                  size="small"
                  value={data.mac_address}
                >
                  <Body family="monospace">{data.mac_address}</Body>
                </CopyBox>
              ) : (
                <NoValue />
              )}
            </SummaryListValue>
          </SummaryListRow>
          {data.link_speed_mbps && (
            <SummaryListRow>
              <SummaryListKey>Link speed</SummaryListKey>
              <SummaryListValue>
                <Badge size="small">{`${data.link_speed_mbps} Mbps`}</Badge>
              </SummaryListValue>
            </SummaryListRow>
          )}
          <IsOperator>
            <SummaryListRow internal>
              <SummaryListKey>Hostname</SummaryListKey>
              <SummaryListValue>{data.name}</SummaryListValue>
            </SummaryListRow>
            {isDefinedAndNotEmpty(uptime) && (
              <SummaryListRow internal>
                <SummaryListKey>Uptime</SummaryListKey>
                <SummaryListValue>{secToHuman(uptime)}</SummaryListValue>
              </SummaryListRow>
            )}
          </IsOperator>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

function AccessPointDetail() {
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const companyName = useCurrentCompany();
  const apiClient = useAPIClient();
  const isOperator = useIsOperator({ respectDemoMode: true });

  const { deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.AccessPointSummary),
  );

  const { data: result } = useQuery(
    ['device_with_radio', controllerName, deviceName],
    () => apiClient.accessPoint(deviceName),
    {
      suspense: true,
    },
  );

  const uptime = useQuery(
    ['ap_uptime', deviceName, controller.name],
    () => apiClient.fetchLatestUptimeMetric(deviceName),
    { enabled: isOperator, useErrorBoundary: false },
  ).data;

  const network = useNetwork();
  const clients = useNetworkClients(network, {
    deviceName: result?.name,
    filter: {
      apSerialNumber: result?.serial_number,
      excludeMeterHardware: true,
    },
  });

  const onlineClients = clients.filter(isOnline);
  const userClients = onlineClients.filter((c) => !isGuest(c));
  const guestClients = onlineClients.filter(isGuest);

  expectDefinedOrThrow(result);

  const device = result;
  const { radios } = result;

  expectDefinedOrThrow(device);

  return (
    <DrawerContent gutter="none" spacing={space(0)}>
      <StickyObjectHeader
        icon="access-point"
        name={getAPNameForDevice(device)}
        status={device.connected_status}
        link={makeLink(paths.pages.LegacyAccessPointDetailPage, {
          deviceName: result.name,
          controllerName,
          companyName,
        })}
        cta="View access point"
      />
      <Sections>
        <AccessPointDetailsWidget data={result} uptime={uptime || 0} />
        <ConnectedSwitchSection
          relation="stacked"
          lookupMethod="lldp"
          macAddress={device.mac_address}
        />
        <Section relation="stacked">
          <SectionHeader icon="client" heading="Clients" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <SummaryListRow>
                <SummaryListKey>Online</SummaryListKey>
                <SummaryListValue>
                  <OnlineOfflineClientCountBadge icon="wifi" value={onlineClients.length} />
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Users</SummaryListKey>
                <SummaryListValue>
                  <OnlineOfflineClientCountBadge icon="wifi" value={userClients.length} />
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Guests</SummaryListKey>
                <SummaryListValue>
                  <OnlineOfflineClientCountBadge icon="wifi" value={guestClients.length} />
                </SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
        </Section>
        <WirelessBandDetails
          title="5 GHz band"
          radio={getEnabledRadioForBandOrNull(radios, api.RadioBand.RB_5G)}
        />
        <WirelessBandDetails
          title="2.4 GHz band"
          radio={getEnabledRadioForBandOrNull(radios, api.RadioBand.RB_2G)}
        />
      </Sections>
    </DrawerContent>
  );
}

export function AccessPointSummaryDrawer() {
  return (
    <Drawer>
      <DrawerHeader icon="access-point" heading="Access point" onClose={useCloseDrawerCallback()} />
      <AccessPointDetail />
    </Drawer>
  );
}

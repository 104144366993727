import {
  Column,
  Columns,
  DeviceTarget,
  Icon,
  Section,
  SectionContent,
  SectionHeader,
  Small,
  space,
} from '@meterup/atto';
import {
  AutoTable,
  expectDefinedOrThrow,
  isDefined,
  isDefinedAndNotEmpty,
  isOnline,
  PositiveBadge,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash-es';

import { Box } from '../../../../../components/Box';
import {
  ClientHardwareWidget,
  WiredConnectionWidget,
  WirelessConnectionWidget,
  WirelessQualityWidget,
} from '../../../../../components/clients';
import { ListItemTableContainer } from '../../../../../components/ListItemTableContainer';
import { NoValue } from '../../../../../components/NoValue';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { fallbackAccessPointHardwareDeviceQuery } from '../../../../../components/Wireless/utils';
import { paths } from '../../../../../constants';
import {
  type NetworkClient,
  useNetworkClients,
} from '../../../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { getAPNameForClient } from '../../../../../utils/access_point_utils';
import { isLastSeenKnown, isWired, isWireless } from '../../../../../utils/clientLists';
import formatTime from '../../../../../utils/formatTime';
import { makeDrawerLink, makeLink } from '../../../../../utils/main_and_drawer_navigation';

const rankBy = <T, U>(items: T[], iteratee: (item: T) => U): { value: T; rank: number }[] => {
  let rank = 0;
  let lastValue: U | undefined;
  return items.map((item) => {
    if (iteratee(item) === lastValue) {
      return { value: item, rank };
    }
    lastValue = iteratee(item);
    rank += 1;
    return { value: item, rank };
  });
};

const bldr = createColumnBuilder<NetworkClient>();

const columns = [
  bldr.display({
    id: 'icon',
    header: '',
    meta: { alignment: 'center', width: 40 },
    // eslint-disable-next-line no-empty-pattern
    cell: ({}) => <Icon icon="access-point" size={space(16)} />,
  }),
  bldr.data((d) => getAPNameForClient(d), {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  bldr.data((d) => d.lastSeen ?? '', {
    id: 'last-seen',
    header: 'Last seen',
    meta: {
      alignment: 'end',
    },
    cell: (p) =>
      isLastSeenKnown(p.row) && p.row.lastSeen ? (
        <>{formatTime(new Date(p.row.lastSeen))}</>
      ) : (
        <NoValue />
      ),
  }),
];

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/insights/clients/:macAddress',
});

export default function ClientDetailPage() {
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const companyName = useCurrentCompany();
  const api = useAPIClient();
  const { macAddress } = Nav.useRegionParams('root', paths.pages.ClientDetailPage)!;
  const network = useNetwork();

  const clientHistory = useNetworkClients(network, {
    filter: { macAddress, excludeMeterHardware: true },
  });

  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);

  const client = clientHistory[0] ?? null;

  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));

  const { data: device } = useQuery(
    ['device', controllerName, client.apSerialNumber],
    () => api.accessPoint(client.apSerialNumber),
    {
      suspense: true,
      enabled: !isWOS2Enabled && isDefinedAndNotEmpty(client.apSerialNumber),
    },
  );

  const wos2Device = useGraphQL(
    fallbackAccessPointHardwareDeviceQuery,
    { serialNumber: client.apSerialNumber ?? '' },
    { enabled: isWOS2Enabled && isDefinedAndNotEmpty(client.apSerialNumber) },
  ).data?.hardwareDevice;

  const selectedHistory = Nav.useRegionParams('drawer', paths.drawers.AccessPointSummary);

  const closeDrawer = useCloseDrawerCallback();

  const filteredClientHistory = uniqBy(
    rankBy(clientHistory, (c) => c.apSerialNumber),
    'rank',
  )
    .map((r) => r.value)
    .slice(0, 10);

  return (
    <Columns scroll="none" template="wide-narrow">
      <Column gutter="vertical">
        <Section relation="stacked">
          <SectionHeader icon="log" heading="History" />
          <SectionContent>
            {isOnline(client) && isDefined(device) && (
              <Box
                css={{
                  width: '100%',
                  vStack: '$4',
                  padding: '$12',
                }}
              >
                <DeviceTarget
                  as={ReactRouterLink}
                  to={makeLink(paths.pages.LegacyAccessPointDetailPage, {
                    deviceName: device.name,
                    controllerName,
                    companyName,
                  })}
                  replace={false}
                  aria-label={`Go to ${getAPNameForClient(client)}`}
                  size="large"
                  type="access-point"
                >
                  {getAPNameForClient(client)}
                  <PositiveBadge size="large">Online</PositiveBadge>
                </DeviceTarget>
                <Small>Current connection</Small>
              </Box>
            )}
            <ListItemTableContainer>
              <AutoTable
                data={filteredClientHistory ?? []}
                columns={columns}
                getLinkTo={(row) =>
                  makeDrawerLink(window.location, paths.drawers.AccessPointSummary, {
                    deviceName: row.apSerialNumber!,
                    controllerName,
                    companyName,
                  })
                }
                isRowSelected={(row) => row.apSerialNumber === selectedHistory?.deviceName}
                onRowDeselect={closeDrawer}
              />
            </ListItemTableContainer>
          </SectionContent>
        </Section>
      </Column>
      <Column gutter="vertical">
        <Section relation="stacked">
          <SectionHeader icon="information" heading="Metadata" />
          <SectionContent gutter="all">
            <ClientHardwareWidget client={client} />
          </SectionContent>
        </Section>
        {isWired(client) && <WiredConnectionWidget relation="stacked" client={client} />}
        {isWireless(client) && (
          <>
            <WirelessConnectionWidget
              relation="stacked"
              client={client}
              device={device}
              virtualDeviceUUID={wos2Device?.virtualDeviceUUID}
              virtualDeviceLabel={wos2Device?.virtualDevice?.label ?? 'Access point'}
            />
            <WirelessQualityWidget relation="stacked" client={client} />
          </>
        )}
      </Column>
    </Columns>
  );
}

import { isDefinedAndNotEmpty } from '@meterup/common';
import { useMemo } from 'react';

import { PermissionType } from '../gql/graphql';
import { useCurrentCompanyOrDefault } from '../providers/CurrentCompanyProvider';
import { usePermissions } from '../providers/PermissionsProvider';
import { useCurrentCompanyContracts } from './useContracts';
import { useCurrentControllerOrNull } from './useCurrentControllerOrNull';
import { useFeatureFlags } from './useFeatureFlags';
import { useDefaultNetworkForCompany } from './useNetworksForCompany';

export enum ProductTypes {
  AUTO_VPN = 'auto-vpn',
  CONNECT = 'connect',
  NETWORK = 'network',
  TUNNEL = 'tunnel',
}

export interface UserProductsAccess {
  isCustomerOfProduct(productType: ProductTypes): boolean;
  hasAccessToProduct(productType: ProductTypes): boolean;
  shouldSeeProduct(productType: ProductTypes): boolean;
}

type ProductAccessMap = {
  [key in ProductTypes]: {
    isCustomer: boolean;
    hasAccess?: boolean;
    adminOverride?: boolean;
  };
};

class UserProductsAccessImpl implements UserProductsAccess {
  constructor(private readonly productAccessMap: ProductAccessMap) {}

  isCustomerOfProduct(productType: ProductTypes): boolean {
    return this.productAccessMap[productType].isCustomer;
  }

  hasAccessToProduct(productType: ProductTypes): boolean {
    return this.productAccessMap[productType].hasAccess ?? false;
  }

  shouldSeeProduct(productType: ProductTypes): boolean {
    return (
      (this.isCustomerOfProduct(productType) && this.hasAccessToProduct(productType)) ||
      (this.productAccessMap[productType].adminOverride ?? false)
    );
  }
}

export function useUserProductsAccess() {
  const currentController = useCurrentControllerOrNull(true);
  const contracts = useCurrentCompanyContracts();
  const featureFlags = useFeatureFlags();
  const companySlug = useCurrentCompanyOrDefault();
  const defaultNetwork = useDefaultNetworkForCompany(companySlug);
  const { hasPermission } = usePermissions();

  return useMemo(
    () =>
      new UserProductsAccessImpl({
        [ProductTypes.AUTO_VPN]: {
          isCustomer: true,
          hasAccess: true,
        },
        [ProductTypes.CONNECT]: {
          isCustomer: contracts.length > 0,
          hasAccess: hasPermission(PermissionType.PermCompanyUpdate),
        },
        [ProductTypes.NETWORK]: {
          isCustomer:
            isDefinedAndNotEmpty(currentController) || isDefinedAndNotEmpty(defaultNetwork),
          hasAccess: true,
        },
        [ProductTypes.TUNNEL]: {
          isCustomer: isDefinedAndNotEmpty(currentController),
          hasAccess: featureFlags['tunnel-ui'],
        },
      }),
    [contracts, currentController, featureFlags, defaultNetwork, hasPermission],
  );
}

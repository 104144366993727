import type { AlertParameter } from '@meterup/common/src/gql/graphql';
import type { FieldArrayRenderProps, FormikContextType } from 'formik';
import { PrimaryField, PrimaryToggleField, TextInput } from '@meterup/atto';
import { AlertParameterType } from '@meterup/common/src/gql/graphql';

import type { NotificationAddSchema } from './utils';
import { FieldProvider, NumberFieldProvider } from '../../../Form/FieldProvider';

function StringField({
  name,
  label,
  description,
}: {
  name: string;
  label: string;
  description?: string;
}) {
  return (
    <FieldProvider name={name}>
      <PrimaryField label={label} description={description} element={<TextInput />} />
    </FieldProvider>
  );
}

function NumberField({
  name,
  label,
  description,
}: {
  name: string;
  label: string;
  description?: string;
}) {
  return (
    <NumberFieldProvider name={name} defaultValue={0}>
      <PrimaryField label={label} description={description} element={<TextInput />} />
    </NumberFieldProvider>
  );
}

function BooleanField({
  name,
  label,
  description,
}: {
  name: string;
  label: string;
  description?: string;
}) {
  return (
    <FieldProvider name={name}>
      <PrimaryToggleField label={label} description={description} />
    </FieldProvider>
  );
}

// datetime and duration are not supported yet
// eslint-disable-next-line consistent-return
function renderArgumentField({
  param,
  index,
}: {
  param: AlertParameter;
  form: FormikContextType<NotificationAddSchema>;
  index: number;
}): JSX.Element {
  const args = {
    name: `input.arguments[${index}].value`,
    label: param.name,
    ...(param.description ? { description: param.description } : {}),
  };

  switch (param.type) {
    case AlertParameterType.Number:
      return <NumberField {...args} key={param.uuid} />;
    case AlertParameterType.String:
      return <StringField {...args} key={param.uuid} />;
    case AlertParameterType.Boolean:
      return <BooleanField {...args} key={param.uuid} />;
    case AlertParameterType.Datetime:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    case AlertParameterType.Duration:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    case AlertParameterType.Date:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
}

export function ArgumentFields({
  params,
  form,
}: {
  params: AlertParameter[];
  form: FormikContextType<NotificationAddSchema>;
}) {
  return <>{params.map((param, index) => renderArgumentField({ param, form, index }))}</>;
}

export function generateArgumentsState(
  params: AlertParameter[],
  arrayHelper: FieldArrayRenderProps,
) {
  params.forEach((param) => {
    arrayHelper.push({
      parameterUUID: param.uuid,
      value: '',
      type: param.type,
      required: param.required,
    });
  });
}

import type { GutterProp, SectionPropRelation } from '@meterup/atto';
import type { api } from '@meterup/proto';
import {
  Badge,
  Body,
  Button,
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import IsOperator from '@meterup/authorization/src/components/IsOperator';
import {
  isDefined,
  isDefinedAndNotEmpty,
  lookupMACAddressOUI,
  SignalStrengthBadge,
} from '@meterup/common';

import type { NetworkClient } from '../hooks/networkClients/useNetworkClients';
import { NosFeature, useNosFeatureEnabled } from '../hooks/useNosFeatures';
import {
  SwitchMACTableConnectionSummaryListRows,
  VLANConnectionSummaryListRows,
} from './Connections';
import { formatTimestamp } from './Devices/utils';
import { APWOS1DeviceTarget, APWOS2DeviceTarget, SSIDDeviceTarget } from './DeviceTargets';
import { NoValue } from './NoValue';

function PingTestDialogButton({ onClick }: { onClick: () => void }) {
  return (
    <Button arrangement="leading-icon" variant="secondary" icon="ping" onClick={onClick}>
      Ping
    </Button>
  );
}

export function ClientHardwareWidget({
  client,
  gutter = 'none',
}: {
  client: Pick<NetworkClient, 'clientName' | 'macAddress' | 'alias'>;
  gutter?: GutterProp;
}) {
  const manufacturer = client.macAddress ? lookupMACAddressOUI(client.macAddress) : undefined;
  // Don't show Ruby Tech as a manufacturer since these switches are actually Meter switches with
  // Ruby Tech's MAC addresses, which could confuse the customer.
  const shouldShowManufacturer =
    isDefinedAndNotEmpty(manufacturer) && !manufacturer.toLowerCase().includes('ruby tech');
  return (
    <SummaryList gutter={gutter}>
      {shouldShowManufacturer && (
        <SummaryListRow>
          <SummaryListKey>Manufacturer</SummaryListKey>
          <SummaryListValue>{manufacturer}</SummaryListValue>
        </SummaryListRow>
      )}
      <SummaryListRow>
        <SummaryListKey>MAC</SummaryListKey>
        <SummaryListValue>
          <CopyBox
            aria-label="Copy MAC address to clipboard"
            relation="stacked"
            value={client?.macAddress}
          >
            <Body family="monospace">{client?.macAddress}</Body>
          </CopyBox>
        </SummaryListValue>
      </SummaryListRow>
      {client.clientName && (
        <SummaryListRow>
          <SummaryListKey>Hostname</SummaryListKey>
          <SummaryListValue>
            <Body family="monospace">{client.clientName!}</Body>
          </SummaryListValue>
        </SummaryListRow>
      )}
      {client.alias && (
        <SummaryListRow>
          <SummaryListKey>Alias</SummaryListKey>
          <SummaryListValue>{client.alias}</SummaryListValue>
        </SummaryListRow>
      )}
    </SummaryList>
  );
}

export function WiredConnectionWidget({
  client,
  openPingTestDialog,
  relation = 'standalone',
}: {
  client: NetworkClient;
  openPingTestDialog?: () => void;
  relation?: SectionPropRelation;
}) {
  const lanPingEnabled = useNosFeatureEnabled(NosFeature.LAN_PING);

  return (
    <Section relation={relation}>
      <SectionHeader
        heading="Connection"
        actions={
          openPingTestDialog && lanPingEnabled ? (
            <IsOperator>
              <PingTestDialogButton onClick={openPingTestDialog} />
            </IsOperator>
          ) : undefined
        }
      />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          {client.macAddress && (
            <SwitchMACTableConnectionSummaryListRows macAddress={client.macAddress} />
          )}
          {client.connectedVLAN && (
            <VLANConnectionSummaryListRows uuid={client.connectedVLAN.UUID} />
          )}
          <SummaryListRow>
            <SummaryListKey>IP</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy IP address to clipboard"
                relation="stacked"
                value={client.ip!}
              >
                <Body family="monospace">{client.ip!}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          {client.dhcpLeaseExpiresAt && (
            <SummaryListRow>
              <SummaryListKey>DHCP lease expires</SummaryListKey>
              <SummaryListValue>{formatTimestamp(client.dhcpLeaseExpiresAt)}</SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function WirelessConnectionWidget({
  device,
  virtualDeviceUUID,
  virtualDeviceLabel,
  client,
  openPingTestDialog,
  relation = 'standalone',
}: {
  device?: api.AccessPointDeviceResponse | null | undefined;
  virtualDeviceUUID: string | null | undefined;
  virtualDeviceLabel: string;
  client: NetworkClient;
  openPingTestDialog?: () => void;
  relation?: SectionPropRelation;
}) {
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);
  const lanPingEnabled = useNosFeatureEnabled(NosFeature.LAN_PING);

  return (
    <Section relation={relation}>
      <SectionHeader
        heading="Connections"
        actions={
          openPingTestDialog && lanPingEnabled ? (
            <IsOperator>
              <PingTestDialogButton onClick={openPingTestDialog} />
            </IsOperator>
          ) : undefined
        }
      />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          {(isDefinedAndNotEmpty(virtualDeviceUUID) || isDefined(device)) && (
            <SummaryListRow>
              <SummaryListKey>Access point</SummaryListKey>
              <SummaryListValue>
                {isWOS2Enabled ? (
                  <APWOS2DeviceTarget uuid={virtualDeviceUUID} label={virtualDeviceLabel} />
                ) : (
                  device && <APWOS1DeviceTarget device={device} />
                )}
              </SummaryListValue>
            </SummaryListRow>
          )}
          {isDefinedAndNotEmpty(client.ssid) && (
            <SummaryListRow>
              <SummaryListKey>SSID</SummaryListKey>
              <SummaryListValue>
                <SSIDDeviceTarget ssidName={client.ssid} />
              </SummaryListValue>
            </SummaryListRow>
          )}
          {isDefinedAndNotEmpty(client.vlan) && isDefined(client.connectedVLAN) && (
            <VLANConnectionSummaryListRows uuid={client.connectedVLAN.UUID} />
          )}
          {isDefinedAndNotEmpty(client.channel) && (
            <SummaryListRow>
              <SummaryListKey>Channel</SummaryListKey>
              <SummaryListValue>
                {client.channel !== 0 ? (
                  <Badge arrangement="leading-icon" variant="neutral" size="small">
                    {client.channel}
                  </Badge>
                ) : (
                  <NoValue />
                )}
              </SummaryListValue>
            </SummaryListRow>
          )}
          <SummaryListRow>
            <SummaryListKey>IP</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy IP address to clipboard"
                relation="stacked"
                value={client.ip!}
              >
                <Body family="monospace">{client.ip!}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          {client.associatedAt && (
            <SummaryListRow>
              <SummaryListKey>Connected since</SummaryListKey>
              <SummaryListValue>{formatTimestamp(client.associatedAt)}</SummaryListValue>
            </SummaryListRow>
          )}
          {client.dhcpLeaseExpiresAt && (
            <SummaryListRow>
              <SummaryListKey>DHCP lease expires</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy DHCP lease expiration to clipboard"
                  relation="stacked"
                  value={client.dhcpLeaseExpiresAt}
                >
                  {formatTimestamp(client.dhcpLeaseExpiresAt)}
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function WirelessQualityWidget({
  client,
  relation = 'standalone',
}: {
  client: NetworkClient;
  relation?: SectionPropRelation;
}) {
  if (!isDefinedAndNotEmpty(client.signal) && !isDefinedAndNotEmpty(client.noise)) {
    return null;
  }
  return (
    <Section relation={relation}>
      <SectionHeader heading="Quality" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          {isDefinedAndNotEmpty(client.signal) && (
            <SummaryListRow>
              <SummaryListKey>Signal</SummaryListKey>
              <SummaryListValue>
                <SignalStrengthBadge value={client.signal} />
              </SummaryListValue>
            </SummaryListRow>
          )}
          {isDefinedAndNotEmpty(client.noise) && (
            <SummaryListRow>
              <SummaryListKey>Noise</SummaryListKey>
              <SummaryListValue>
                <Badge arrangement="leading-icon" variant="neutral" size="small" ends="pill">
                  {client.noise} dBm
                </Badge>
              </SummaryListValue>
            </SummaryListRow>
          )}
          {isDefinedAndNotEmpty(client.signal) && isDefinedAndNotEmpty(client.noise) && (
            <SummaryListRow>
              <SummaryListKey>SNR</SummaryListKey>
              <SummaryListValue>
                <Badge arrangement="leading-icon" variant="neutral" size="small" ends="pill">
                  {client.signal - client.noise} dB
                </Badge>
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

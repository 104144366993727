import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import DNSSecurityRuleDrawer from '../../../../../components/DNSSecurity/DNSSecurityRuleDrawer';
import { dnsFirewallRuleQuery } from '../../../../../components/DNSSecurity/utils';
import { paths } from '../../../../../constants';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/dns-security/:ruleUUID',
  title: 'Edit rule - DNS security - Firewall',
});

export default function DNSSecurityRuleEditPage() {
  const { ruleUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.DNSSecurityRuleEditPage),
  );
  const featureFlags = useFeatureFlags();
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator && !featureFlags['content-filtering-ui'])
    throw new ResourceNotFoundError('Page not found');

  const rule = checkDefinedOrThrow(
    useGraphQL(dnsFirewallRuleQuery, {
      uuid: ruleUUID,
    })?.data?.applicationDNSFirewallRule,
    new ResourceNotFoundError('DNS security rule not found'),
  );

  return <DNSSecurityRuleDrawer rule={rule} />;
}

import React from 'react';

import { HStack, Icon, IconName, LoadingIcon, space } from '@meterup/atto';
import { components, ValueContainerProps } from 'react-select';

import { styled } from '../../styled';

const ValueContainerWrapper = styled(HStack, {
  paddingLeft: '$8',
  '& > div': {
    paddingLeft: 0,
  },
  '& input, & #react-select-3-placeholder': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& input': {
    // 30px is 16px for icon + 6px spacing + 8px padding
    // maxWidth: "calc(100% - 30px)",
  },
  '& #react-select-3-placeholder': {
    maxWidth: '100%',
  },
});
type IconValueContainerProps<T, Q extends boolean = false> = ValueContainerProps<T, Q> & {
  icon?: IconName;
};
export function IconValueContainer<T>(props: IconValueContainerProps<T, false>) {
  const { children, icon, ...rest } = props;
  return (
    <ValueContainerWrapper spacing={space(6)} align="center">
      {rest.selectProps.isLoading ? (
        <LoadingIcon size={space(16)} />
      ) : (
        <Icon icon={icon || 'checkmark'} size={16} />
      )}
      <components.ValueContainer {...rest}>{children}</components.ValueContainer>
    </ValueContainerWrapper>
  );
}

import { useNetwork } from '../../../hooks/useNetworkFromPath';
import CaptivePortalEdit from './CaptivePortalEdit';
import CaptivePortalNew from './CaptivePortalNew';
import { useCaptivePortalForNetwork } from './queries';

export default function CaptivePortal() {
  const network = useNetwork();
  const captivePortal = useCaptivePortalForNetwork(network.UUID).data?.captivePortalForNetwork;
  return captivePortal ? <CaptivePortalEdit captivePortal={captivePortal} /> : <CaptivePortalNew />;
}

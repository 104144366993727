import { Button, PaneHeader, Tab, useDialogState } from '@meterup/atto';
import { makeQueryKey } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useCompany } from '../../hooks/useCompany';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import IsPermitted from '../permissions/IsPermitted';
import { ReactRouterLink } from '../ReactRouterLink';
import { CreateNetworkDialog } from '../Settings/Network/EditNetwork';
import { hubNetworksOverviewQuery } from './useHubNetworksOverview';

export enum ValidHubTabs {
  Cards = 'cards',
  List = 'list',
  Map = 'map',
}

function HubTabs({ activeTab }: { activeTab: ValidHubTabs }) {
  const companyName = useCurrentCompany();
  return (
    <>
      <Tab
        as={ReactRouterLink}
        to={makeLink(paths.pages.HubCardsPage, { companyName })}
        icon="cards"
        selected={activeTab === ValidHubTabs.Cards}
      >
        Cards
      </Tab>
      <Tab
        as={ReactRouterLink}
        to={makeLink(paths.pages.HubListPage, { companyName })}
        icon="list"
        selected={activeTab === ValidHubTabs.List}
      >
        List
      </Tab>
      <Tab
        as={ReactRouterLink}
        to={makeLink(paths.pages.HubMapPage, { companyName })}
        icon="globe"
        selected={activeTab === ValidHubTabs.Map}
      >
        Map
      </Tab>
    </>
  );
}

function HubActions() {
  const companySlug = useCurrentCompany();
  const company = useCompany(companySlug);
  const { state: createNetworkDialogState } = useDialogState();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(() => {
    queryClient.invalidateQueries(makeQueryKey(hubNetworksOverviewQuery, { companySlug }));
  }, [queryClient, companySlug]);

  return (
    !company?.isCustomer && (
      <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
        <Button
          type="button"
          arrangement="leading-icon"
          variant="secondary"
          icon="plus"
          onClick={createNetworkDialogState.open}
          internal
        >
          Add network
        </Button>
        <CreateNetworkDialog state={createNetworkDialogState} onSuccess={handleSuccess} />
      </IsPermitted>
    )
  );
}

export default function HubHeader({ activeTab }: { activeTab: ValidHubTabs }) {
  return (
    <PaneHeader
      icon="hub"
      heading="Hub"
      actions={<HubActions />}
      tabs={<HubTabs activeTab={activeTab} />}
    />
  );
}

import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import IntegrationsLayout from '../../../../../components/Settings/Organization/Integrations/IntegrationsLayout';
import { IntegrationsValidTabs } from '../../../../../components/Settings/Organization/Integrations/IntegrationsTabs';
import Receivers from '../../../../../components/Settings/Organization/Integrations/Receivers/Receivers';

export function Meta() {
  return {
    path: '/org/:companyName/settings/org/integrations/receivers',
    title: 'Receivers - Integrations - Org',
  };
}

export default function ReceiversPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  return <IntegrationsLayout activeTab={IntegrationsValidTabs.Receivers} main={<Receivers />} />;
}

import type { OverlayTriggerState } from '@meterup/atto';
import type { ClientError } from 'graphql-request';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  PrimaryField,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import { expectDefinedOrThrow, isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { z } from 'zod';

import type { RpceapolTestMutationMutation } from '../../../gql/graphql';
import { graphql } from '../../../gql';
import { PermissionType, RpcEapolTestForSerialStatus } from '../../../gql/graphql';
import { RpcEapolTestForSerialsInputSchema } from '../../../gql/zod-types';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { pluralize } from '../../../utils/strings';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider } from '../../Form/FieldProvider';
import { AccessPointsQuery } from '../utils';

const RPCEAPOLTestMutation = graphql(`
  mutation RPCEAPOLTestMutation($input: RPCEapolTestForSerialsInput!) {
    rpcWosEapolTestForSerials(input: $input) {
      status
      error_reason
      serialNumber
    }
  }
`);

type EAPOLTestDialogProps = {
  state: OverlayTriggerState;
  ssidUUID: string;
  radiusProfileUUID: string;
};

const RPCEAPOLTestSchema = RpcEapolTestForSerialsInputSchema.extend({
  Encryption8021XUUID: z.string().nonempty({ message: 'Please select a RADIUS profile.' }),
  user: z.string().nonempty({ message: 'Please enter a username.' }),
  password: z.string().nonempty({ message: 'Please enter a password.' }),
  ssidUuid: z.string().nonempty({ message: 'Please select an SSID.' }),
  serialNumbers: z.array(
    z.string().nonempty({ message: 'Please select one or more access point.' }),
  ),
});

type ValidEAPOLParams = z.infer<typeof RPCEAPOLTestSchema>;

function EAPOLResults({
  data,
  error,
  loading,
}: {
  data: RpceapolTestMutationMutation | undefined;
  error: ClientError | null;
  loading: boolean;
}) {
  if (loading) {
    return (
      <Alert icon="arrows-rotate" variant="neutral" heading="Running..." relation="standalone" />
    );
  }

  if (error) {
    const gqlErr = getGraphQLError(error);

    return (
      <Alert
        icon="warning"
        variant="negative"
        copy={`Error running the EAPOL test${gqlErr?.message ? `: ${gqlErr.message}` : '.'}`}
        relation="standalone"
      />
    );
  }

  if (!data) {
    return (
      <Alert
        icon="information"
        variant="neutral"
        copy="To run an EAPOL test, enter a user and password."
        relation="standalone"
      />
    );
  }

  const successes = data.rpcWosEapolTestForSerials.filter(
    (res) => res.status === RpcEapolTestForSerialStatus.Success,
  );

  const failures = data.rpcWosEapolTestForSerials.filter(
    (res) => res.status !== RpcEapolTestForSerialStatus.Success,
  );

  if (failures.length > 0) {
    return (
      <VStack>
        {successes.length > 0 && (
          <Alert
            icon="checkmark"
            variant="positive"
            copy={`EAPOL test successful on ${successes.length} ${pluralize(successes.length, 'access point', 'access points')}`}
            relation="stacked"
          />
        )}
        {failures.map((failure) => (
          <Alert
            key={failure.serialNumber}
            icon="cross"
            variant="negative"
            copy={`${failure.serialNumber}: ${failure.status} ${failure.error_reason ? `: (${failure.error_reason})` : ''}`}
            relation="stacked"
          />
        ))}
      </VStack>
    );
  }

  return (
    <Alert
      icon="checkmark"
      variant="positive"
      copy={`EAPOL test successful on all (${successes.length}) access points.`}
      relation="standalone"
    />
  );
}

export function EAPOLTestDialog({ state, ssidUUID, radiusProfileUUID }: EAPOLTestDialogProps) {
  const network = useNetwork();
  const mutation = useGraphQLMutation(RPCEAPOLTestMutation);
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const accessPoints = useGraphQL(AccessPointsQuery, {
    networkUUID: network.UUID,
    includeUptime,
  }).data?.virtualDevicesForNetwork;
  expectDefinedOrThrow(accessPoints, new ResourceNotFoundError('Unable to load access points'));

  const hardwareAccessPoints = accessPoints.filter((ap) => isDefinedAndNotEmpty(ap.hardwareDevice));

  const enabled = hardwareAccessPoints.length > 0;

  useEffect(() => {
    if (!state.isOpen) {
      mutation.reset();
    }
  }, [mutation, state.isOpen]);

  return (
    <Dialog preset="narrow" state={state}>
      <Formik<ValidEAPOLParams>
        initialValues={{
          serialNumbers: hardwareAccessPoints.map((ap) => ap.hardwareDevice!.serialNumber),
          user: '',
          password: '',
          Encryption8021XUUID: radiusProfileUUID,
          ssidUuid: ssidUUID,
        }}
        validate={withZodSchema(RPCEAPOLTestSchema)}
        onSubmit={(values) => {
          if (mutation.isLoading) return;
          mutation.mutate({ input: values });
        }}
      >
        <Form>
          <DialogHeader icon="radius" heading="Run EAPOL test" />
          <DialogContent gutter="all">
            <VStack spacing={space(12)}>
              {enabled ? (
                <>
                  <EAPOLResults
                    data={mutation.data}
                    error={mutation.error}
                    loading={mutation.isLoading}
                  />

                  <FieldProvider name="user">
                    <PrimaryField label="User" element={<TextInput />} />
                  </FieldProvider>

                  <FieldProvider name="password">
                    <PrimaryField label="Password" element={<TextInput type="password" />} />
                  </FieldProvider>
                </>
              ) : (
                <Alert
                  icon="access-point"
                  variant="brand"
                  heading="No access points"
                  copy="At least one access point is required to run an EAPOL test."
                />
              )}
            </VStack>
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button variant="secondary" disabled={mutation.isLoading} onClick={state.close}>
                  {enabled ? 'Cancel' : 'Close'}
                </Button>
                {enabled && (
                  <Button
                    type="submit"
                    loading={mutation.isLoading}
                    disabled={mutation.isLoading}
                    variant="primary"
                  >
                    Run
                  </Button>
                )}
              </>
            }
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

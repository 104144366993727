import { checkDefinedOrThrow } from '@meterup/common';

import ISPEdit from '../../../../../components/NetworkWide/ISPs/ISPEdit';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/isps/:isp/edit',
  title: 'Edit ISP - ISPs - Network-wide',
});

export default function ISPEditDrawerPage() {
  const { isp } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ISPEditDrawerPage),
  );
  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermIspWriteRestricted))
      }
      should404OnAccessDenied
    >
      <ISPEdit ispUUID={isp} />
    </IsPermitted>
  );
}

import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { notify } from '@meterup/common';
import { api } from '@meterup/proto';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { isEmpty, range } from 'lodash-es';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { FixMeLater } from '../../../../../types/fixMeLater';
import type { ValidContentFilterRuleData } from './form_data';
import { createRuleForCompany, getRulesForCompany } from '../../../../../api/api';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { categoryRules } from '../../../../../utils/content_filters';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { ContentField, NO_CATEGORY_ID, PriorityField } from './fields';
import { toAPIData, validContentFilterRuleData } from './form_data';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/dns-security/rules/create',
});

export default function LegacyDNSSecurityCategoryRuleCreatePage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const ruleData =
    useQuery(['dns_security', companyName, 'rules'], async () => getRulesForCompany(companyName), {
      suspense: true,
    }).data ?? [];

  const rules = categoryRules(ruleData);
  const usedPrecedence = rules.map((rule) => rule.precedence - 2);
  const availablePrecedences = range(0, 99).filter((i) => !usedPrecedence.includes(i));

  const { mutate: createRuleMutation, isLoading: mutating } = useMutation(
    (data: ValidContentFilterRuleData) => {
      const apiData = toAPIData(data, { precedenceOffset: 2 });
      return createRuleForCompany(companyName, apiData);
    },
    {
      onSuccess: async (value) => {
        await queryClient.invalidateQueries(['dns_security', companyName]);
        if (value) {
          navigate(
            makeDrawerLink(window.location, paths.drawers.LegacyDNSSecurityCategoryRuleEditPage, {
              id: value.id,
              companyName,
              controllerName,
            }),
          );

          notify('Rule created successfully', { variant: 'positive' });
        }
      },
      onError: () => {
        notify('Failed to create rule', { variant: 'negative' });
      },
    },
  );

  const closeDrawer = useCloseDrawerCallback();

  const validateForm = (values: any) => {
    const validation = withZodSchema(validContentFilterRuleData)(values);
    if (!isEmpty(validation)) return validation;

    if (!availablePrecedences.includes(parseInt(values.precedence, 10))) {
      return {
        precedence: `Precedence ${values.precedence} is already in use.`,
      };
    }

    return {};
  };

  return (
    <Formik<ValidContentFilterRuleData>
      validate={validateForm}
      initialValues={{
        content: {
          type: 'category',
          domain: '',
          categoryId: NO_CATEGORY_ID as FixMeLater,
        },
        precedence: availablePrecedences[0],
        action: api.ContentFilterAction.CONTENTFILTER_ACTION_BLOCK,
      }}
      onSubmit={(values) => createRuleMutation(values)}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader icon="dns-security" heading="Add rule" onClose={useCloseDrawerCallback()} />
          <DrawerContent>
            <ContentField />
            <PriorityField />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" variant="secondary" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button type="submit" loading={mutating}>
                  Save
                </Button>
              </>
            }
          />
        </Drawer>
      </StyledForm>
    </Formik>
  );
}

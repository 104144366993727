import { EmptyState } from '@meterup/atto';
import { CaptivePortal, presignedDownloadUrlQuery } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useFormikContext } from 'formik';

import type { CaptivePortalInputSchemaType } from './schema';

export default function CaptivePortalPreview() {
  const form = useFormikContext<CaptivePortalInputSchemaType>();
  const imageData = useGraphQL(
    presignedDownloadUrlQuery,
    { s3Key: form.values.logoImageS3Key ?? '' },
    {
      enabled: !!form.values.logoImageS3Key,
      suspense: false,
      useErrorBoundary: false,
    },
  );

  return form.values.isExternal ? (
    <EmptyState icon="captive-portal" heading="External captive portal, nothing to preview" />
  ) : (
    <CaptivePortal
      relation="nested"
      captivePortal={{
        ...form.values,
        ...(imageData.data ? { logoImageURL: imageData.data.downloadUrl.presignedUrl } : {}),
      }}
    />
  );
}

import { DateTime } from 'luxon';
import nacl from 'tweetnacl';

import type { ClientVpnClientsQuery, ClientVpnServersQuery } from '../../gql/graphql';
import { graphql } from '../../gql';

export const DEFAULT_LISTEN_PORT = 51820;
export const DEFAULT_DEFAULT_CLIENT_ALLOWED_IPS = ['10.0.0.0/8', '224.0.0.0/4'];

graphql(`
  fragment ClientVPNServerFields on ClientVPNServer {
    UUID
    endpoint
    publicKey
    port
    defaultClientAllowedIPs
    address
    addressMask
    phyInterface {
      ...PhyInterfaceLabelFields
    }
    dnsListenAddress
    defaultClientAllowedIPs
    isFailoverEnabled

    clients {
      ...ClientVPNClientFields
    }
  }
`);

export type ClientVPNServer = ClientVpnServersQuery['clientVPNServers'][number];

export const clientVPNServerQuery = graphql(`
  query ClientVPNServer($UUID: UUID!) {
    clientVPNServer(UUID: $UUID) {
      ...ClientVPNServerFields
    }
  }
`);

export const clientVPNServersQuery = graphql(`
  query ClientVPNServers($networkUUID: UUID!) {
    clientVPNServers(networkUUID: $networkUUID) {
      ...ClientVPNServerFields
    }
  }
`);

export const createClientVPNServerMutation = graphql(`
  mutation CreateClientVPNServer($networkUUID: UUID!, $input: CreateClientVPNServerInput!) {
    createClientVPNServer(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateClientVPNServerMutation = graphql(`
  mutation UpdateClientVPNServer($uuid: UUID!, $input: UpdateClientVPNServerInput!) {
    updateClientVPNServer(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteClientVPNServerMutation = graphql(`
  mutation DeleteClientVPNServer($uuid: UUID!) {
    deleteClientVPNServer(UUID: $uuid)
  }
`);

graphql(`
  fragment ClientVPNClientFields on ClientVPNClient {
    UUID
    createdAt
    createdBy
    ipAddress
    name
    publicKey
    userEmail
    userSID
    vpnServerUUID

    connectionInfo {
      observedAt
      lastHandshakeTx
      lastHandshakeRx
      lastPacketTx
      lastPacketRx
    }
  }
`);

export const clientVPNClientsQuery = graphql(`
  query ClientVPNClients($serverUUID: UUID!) {
    clientVPNClients(serverUUID: $serverUUID) {
      ...ClientVPNClientFields
    }
  }
`);

export const clientVPNClientQuery = graphql(`
  query ClientVPNClient($UUID: UUID!) {
    clientVPNClient(UUID: $UUID) {
      ...ClientVPNClientFields
    }
  }
`);

export type ClientVPNClient = ClientVpnClientsQuery['clientVPNClients'][number];

export const createClientVPNClientMutation = graphql(`
  mutation CreateClientVPNClient($serverUUID: UUID!, $input: CreateClientVPNClientInput!) {
    createClientVPNClient(serverUUID: $serverUUID, input: $input) {
      UUID
      createdAt
      createdBy
      ipAddress
      name
      publicKey
      userEmail
      userSID
    }
  }
`);

export const deleteClientVPNClientMutation = graphql(`
  mutation DeleteClientVPNClient($uuid: UUID!) {
    deleteClientVPNClient(UUID: $uuid)
  }
`);

export function isClientActive(c: ClientVPNClient): boolean {
  const observedAt = c.connectionInfo?.lastHandshakeRx;
  if (observedAt) {
    const handshakeTime = DateTime.fromISO(observedAt);
    const now = DateTime.now();
    return now.diff(handshakeTime, 'minutes').minutes <= 5;
  }

  return false;
}

export function generateKeyPair() {
  const { publicKey, secretKey } = nacl.box.keyPair();
  return {
    publicKey: btoa(String.fromCharCode(...publicKey)),
    privateKey: btoa(String.fromCharCode(...secretKey)),
  };
}

export function wireguardConfig({
  server,
  client,
  privateKey,
}: {
  server: ClientVPNServer;
  client: ClientVPNClient;
  privateKey?: string;
}) {
  const k = privateKey ?? '$YOUR_PRIVATE_KEY';
  return `
[Interface]
PrivateKey = ${k}
Address = ${client.ipAddress}/32
DNS = ${server.dnsListenAddress ?? server.address}

[Peer]
PublicKey = ${server.publicKey}
AllowedIPs = ${(server.defaultClientAllowedIPs ?? DEFAULT_DEFAULT_CLIENT_ALLOWED_IPS).join(', ')}
Endpoint = ${server.endpoint}:${server.port}
PersistentKeepalive = 30`.trim();
}

export enum ClientTab {
  Details = 'details',
  WireGuardConfig = 'wireguard-config',
}

import type { BadgeVariant } from '@meterup/atto';
import { Badge } from '@meterup/atto';
import { formatBytes, formatDataRateBits, kilobitsPerSecond } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { mean, sum } from 'lodash-es';
import { Duration } from 'luxon';

import { RadioBand, WirelessClientConnectionEventType } from '../../gql/graphql';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import {
  WirelessClientMetricsByClientQuery,
  WirelessConnectionEventsQuery,
} from '../Clients/utils';
import {
  WirelessClientMetricsByAccessPointQuery,
  WirelessConnectionEventsByAccessPointQuery,
} from '../Wireless/utils';

export enum MetricsDeviceType {
  AccessPoint = 'access-point',
  Client = 'client',
}

export enum MetricBandFilter {
  All = 'All',
  Band2_4 = '2.4',
  Band5 = '5',
  Band2_4GHz = '2.4 GHz',
  Band5GHz = '5 GHz',
}

export type ClientMetricAggregate = {
  aggregate?: number;
  values: number[];
};

export type ClientMetricAggregates = {
  snr: ClientMetricAggregate;
  rxRate: ClientMetricAggregate;
  txRate: ClientMetricAggregate;
  rxUsage: ClientMetricAggregate;
  txUsage: ClientMetricAggregate;
  connectionSuccess: ClientMetricAggregate;
  connectionTime: ClientMetricAggregate;
};

export function metricBandFilterToRadioBand(bandFilter: MetricBandFilter) {
  switch (bandFilter) {
    case MetricBandFilter.Band2_4:
    case MetricBandFilter.Band2_4GHz:
      return RadioBand.Band_2_4G;
    case MetricBandFilter.Band5:
    case MetricBandFilter.Band5GHz:
      return RadioBand.Band_5G;
    default:
      return undefined;
  }
}

// Eventually, we want this calculation to be done on the server side. It's not a big deal for now,
// because we are only calculating aggregates for the last 30 minutes (which will be a very small
// number of data points), but we want to create an "aggregate" resolver for these if we ever want
// to do anything with more data.
export const useAggregateMetricsAndConnectionEvents = (
  identifier: string,
  requestType: 'access-point' | 'client',
  durationSeconds: number,
) => {
  const network = useNetwork();

  const baseParams = {
    networkUUID: network.UUID,
    filter: {
      timeFilter: {
        durationSeconds,
        stepSeconds: 1,
      },
    },
  };

  const clientConnectionEvents =
    useGraphQL(
      WirelessConnectionEventsQuery,
      { ...baseParams, mac: identifier },
      {
        enabled: requestType === 'client',
      },
    ).data?.wirelessClientConnectionEventsByClient ?? [];

  const clientMetrics =
    useGraphQL(
      WirelessClientMetricsByClientQuery,
      { ...baseParams, macAddress: identifier },
      {
        enabled: requestType === 'client',
      },
    ).data?.wirelessClientMetricsByClient?.values ?? [];

  const apConnectionEvents =
    useGraphQL(
      WirelessConnectionEventsByAccessPointQuery,
      { ...baseParams, virtualDeviceUUID: identifier },
      {
        enabled: requestType === 'access-point',
      },
    ).data?.wirelessClientConnectionEventsByAP ?? [];

  const apMetrics =
    useGraphQL(
      WirelessClientMetricsByAccessPointQuery,
      { ...baseParams, virtualDeviceUUID: identifier },
      {
        enabled: requestType === 'access-point',
      },
    ).data?.wirelessClientMetricsByAP?.values ?? [];

  const connectionEvents = requestType === 'client' ? clientConnectionEvents : apConnectionEvents;
  const metrics = requestType === 'client' ? clientMetrics : apMetrics;

  const aggregates: {
    snr: ClientMetricAggregate;
    rxRate: ClientMetricAggregate;
    txRate: ClientMetricAggregate;
    rxUsage: ClientMetricAggregate;
    txUsage: ClientMetricAggregate;
    connectionSuccess: ClientMetricAggregate;
    connectionTime: ClientMetricAggregate;
  } = {
    snr: { values: [] },
    rxRate: { values: [] },
    txRate: { values: [] },
    rxUsage: { values: [] },
    txUsage: { values: [] },
    connectionSuccess: { values: [] },
    connectionTime: { values: [] },
  };

  metrics.forEach((metric) => {
    aggregates.snr.values.push(metric.snr);
    aggregates.rxRate.values.push(metric.rxRate);
    aggregates.txRate.values.push(metric.txRate);
    aggregates.rxUsage.values.push(metric.rxBytes);
    aggregates.txUsage.values.push(metric.txBytes);
  });

  connectionEvents.forEach((event) => {
    if (event.eventType === WirelessClientConnectionEventType.Connected) {
      aggregates.connectionSuccess.values.push(1);

      if (event.timeToConnect) {
        aggregates.connectionTime.values.push(Duration.fromISO(event.timeToConnect).milliseconds);
      }
    }

    if (event.eventType === WirelessClientConnectionEventType.Failed) {
      aggregates.connectionSuccess.values.push(0);
    }
  });

  // Perform the actual aggregate calculations
  aggregates.snr.aggregate = mean(aggregates.snr.values);
  aggregates.rxRate.aggregate = mean(aggregates.rxRate.values);
  aggregates.txRate.aggregate = mean(aggregates.txRate.values);
  aggregates.rxUsage.aggregate = sum(aggregates.rxUsage.values);
  aggregates.txUsage.aggregate = sum(aggregates.rxUsage.values);

  if (aggregates.connectionTime.values.length > 0) {
    aggregates.connectionTime.aggregate = mean(aggregates.connectionTime.values);
  }

  if (aggregates.connectionSuccess.values.length > 0) {
    aggregates.connectionSuccess.aggregate =
      sum(aggregates.connectionSuccess.values) / aggregates.connectionSuccess.values.length;
  }

  return aggregates;
};

export const statsFromAggregates = (
  aggregates: ClientMetricAggregates,
  stats: (
    | 'snr'
    | 'txRate'
    | 'rxRate'
    | 'dataRate'
    | 'usage'
    | 'connectionTime'
    | 'connectionSuccess'
  )[],
) => {
  const computedVariant = (
    value: number,
    positiveThreshold: number,
    negativeThreshold: number,
    lowerIsBetter: boolean = true,
  ) => {
    let variant: BadgeVariant = 'positive';

    if (lowerIsBetter) {
      if (value > positiveThreshold && value <= negativeThreshold) {
        variant = 'attention';
      } else if (value > negativeThreshold && value) {
        variant = 'negative';
      }
    } else if (value < positiveThreshold && value >= negativeThreshold) {
      variant = 'attention';
    } else if (value < negativeThreshold && value !== 0) {
      variant = 'negative';
    }

    return variant;
  };

  const variantWeight = (variant: 'positive' | 'attention' | 'negative') => {
    if (variant === 'positive') {
      return 1;
    }

    if (variant === 'attention') {
      return 0.5;
    }

    return 0;
  };

  const weights: number[] = [];
  const items: { label: string; value: JSX.Element }[] = [];

  if (stats.includes('snr') && aggregates.snr.values.length > 0) {
    const snr = aggregates.snr.aggregate ?? 0;
    const snrBadgeVariant = computedVariant(snr, 25, 15, false);
    weights.push(variantWeight(snrBadgeVariant));
    items.push({
      label: 'SNR',
      value: <Badge variant={snrBadgeVariant}>{snr.toFixed(2)} dB</Badge>,
    });
  }

  if (
    (stats.includes('usage') && aggregates.rxUsage.values.length > 0) ||
    aggregates.txUsage.values.length > 0
  ) {
    items.push({
      label: 'Usage',
      value: (
        <Badge variant="neutral">
          {formatBytes((aggregates.rxUsage.aggregate ?? 0) + (aggregates.txUsage.aggregate ?? 0))}
        </Badge>
      ),
    });
  }

  if (stats.includes('rxRate') && aggregates.rxRate.values.length > 0) {
    const rxRate = aggregates.rxRate.aggregate ?? 0;
    const rxRateBadgeVariant = computedVariant(rxRate, 100000, 20000, false);
    weights.push(variantWeight(rxRateBadgeVariant));

    items.push({
      label: 'Data rate (Upload)',
      value: (
        <Badge variant={rxRateBadgeVariant}>{formatDataRateBits(rxRate, kilobitsPerSecond)}</Badge>
      ),
    });
  }

  if (stats.includes('txRate') && aggregates.txRate.values.length > 0) {
    const txRate = aggregates.txRate.aggregate ?? 0;
    const txRateBadgeVariant = computedVariant(txRate, 100000, 20000, false);
    weights.push(variantWeight(txRateBadgeVariant));
    items.push({
      label: 'Data rate (Download)',
      value: (
        <Badge variant={txRateBadgeVariant}>{formatDataRateBits(txRate, kilobitsPerSecond)}</Badge>
      ),
    });
  }

  if (
    stats.includes('dataRate') &&
    (aggregates.rxRate.values.length > 0 || aggregates.txRate.values.length > 0)
  ) {
    const dataRate = (aggregates.txRate.aggregate ?? 0) + (aggregates.rxRate.aggregate ?? 0);
    const dataRateBadgeVariant = computedVariant(dataRate, 100000, 20000, false);
    weights.push(variantWeight(dataRateBadgeVariant));
    items.push({
      label: 'Data rate',
      value: (
        <Badge variant={dataRateBadgeVariant}>
          {formatDataRateBits(dataRate, kilobitsPerSecond)}
        </Badge>
      ),
    });
  }

  if (stats.includes('connectionTime') && aggregates.connectionTime.values.length > 0) {
    const connectionTime = aggregates.connectionTime.aggregate!;
    const connectionTimeBadgeVariant = computedVariant(connectionTime, 50, 100);
    weights.push(variantWeight(connectionTimeBadgeVariant));

    items.push({
      label: 'Connection time',
      value: <Badge variant={connectionTimeBadgeVariant}>{connectionTime.toFixed(0)} ms</Badge>,
    });
  }

  if (stats.includes('connectionSuccess') && aggregates.connectionSuccess.values.length > 0) {
    const successPercent = aggregates.connectionSuccess.aggregate! * 100;
    const successPercentBadgeVariant = computedVariant(successPercent, 70, 50, false);
    weights.push(variantWeight(successPercentBadgeVariant));

    items.push({
      label: 'Connection success rate',
      value: <Badge variant={successPercentBadgeVariant}>{successPercent.toFixed(0)} %</Badge>,
    });
  }

  const health = sum(weights) / weights.length;

  return { items, healthVariant: computedVariant(health, 0.75, 0.25, false) };
};

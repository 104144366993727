import { DeprecatedCloseDrawerButton as CDB } from '@meterup/atto';
import React from 'react';

import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';

export function CloseDrawerButton(props: { 'aria-label'?: string }) {
  return (
    <CDB
      aria-label={props['aria-label'] ?? 'Close drawer'}
      onClick={useCloseDrawerCallback()}
      type="button"
    />
  );
}

import { Pane, PaneContent, PaneHeader, Skeleton, space, VStack } from '@meterup/atto';
import React from 'react';

export function DetailLoadingFallback() {
  return (
    <Pane>
      <PaneHeader heading={<Skeleton height={20} width={200} radius={6} />} />
      <PaneContent gutter="all">
        <VStack spacing={space(16)}>
          <Skeleton height={120} width="100%" radius={6} />
          <Skeleton height={120} width="100%" radius={6} />
          <Skeleton height={120} width="100%" radius={6} />
        </VStack>
      </PaneContent>
    </Pane>
  );
}

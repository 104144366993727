import type { ButtonSizeProp, ButtonVariantProp } from '@meterup/atto';
import type { SortingState } from '@meterup/common';
import {
  Badge,
  Button,
  EmptyState,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  space,
  Text,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { type PortForwardingRulesForNetworkQuery, PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useFirewallCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { NoValue } from '../../NoValue';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { getPhyInterfaceLabel } from '../utils';
import PortForwardingActions from './PortForwardingActions';

export const portForwardingRulesForNetworkQuery = graphql(`
  query PortForwardingRulesForNetwork($networkUUID: UUID!) {
    portForwardingRulesForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      description
      isEnabled
      protocol
      localIPAddress

      externalPhyInterfaceUUID
      externalIPAddress

      externalPhyInterface {
        __typename
        UUID
        label
        hardwareLabel
        portNumber
        virtualDevice {
          UUID
          label
          deviceModel
        }
        internetServicePlan {
          provider {
            name
          }
        }
      }

      externalPort
      localPort
    }
  }
`);

type PortForwardingRule =
  PortForwardingRulesForNetworkQuery['portForwardingRulesForNetwork'][number];

const builder = createColumnBuilder<PortForwardingRule>();

const columns = [
  builder.data((row) => (row.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Enabled',
      },
    },
    cell: ({ row }) =>
      row.isEnabled ? (
        <Icon
          icon="checkmark"
          color={{
            light: 'iconPositiveLight',
            dark: 'iconPositiveDark',
          }}
        />
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.description ?? '', {
    id: 'description',
    header: 'Description',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    cell: ({ row }) => <>{row.description ?? <NoValue />}</>,
  }),
  builder.data(
    (row) => (row.externalPhyInterface ? getPhyInterfaceLabel(row.externalPhyInterface) : null),
    {
      id: 'external-phy-interface',
      header: 'WAN',
      cell: ({ row }) => {
        if (row.externalPhyInterface) {
          return (
            <Badge variant="neutral" size="small" icon="globe" arrangement="leading-icon">
              {getPhyInterfaceLabel(row.externalPhyInterface)}
            </Badge>
          );
        }

        return <NoValue />;
      },
    },
  ),
  builder.data((row) => row.externalIPAddress ?? '', {
    id: 'external-ip-address',
    header: 'External IP address',
    cell: ({ row }) => {
      if (row.externalIPAddress) {
        return <Text family="monospace">{row.externalIPAddress}</Text>;
      }
      return <NoValue />;
    },
  }),
  builder.data((row) => row.localPort.toString(), {
    id: 'local-port',
    header: 'Local port',
    meta: {
      alignment: 'end',
    },
    cell: ({ row }) => <Text family="monospace">{row.localPort}</Text>,
  }),
  builder.data((row) => row.protocol, {
    id: 'protocol',
    header: 'Protocol',
    cell: ({ row }) => (
      <Badge size="small" ends="card" variant="neutral">
        {row.protocol}
      </Badge>
    ),
  }),
  builder.data((row) => row.localIPAddress, {
    id: 'local-ip-address',
    header: 'Local IP address',
    cell: ({ row }) => <Text family="monospace">{row.localIPAddress}</Text>,
  }),
  builder.data((row) => row.externalPort.toString(), {
    id: 'external-port',
    header: 'External port',
    meta: {
      alignment: 'end',
    },
    cell: ({ row }) => <Text family="monospace">{row.externalPort}</Text>,
  }),
];

export function AddPortForwardingRuleButton({
  variant,
  size,
}: {
  variant?: ButtonVariantProp;
  size?: ButtonSizeProp;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Button
      condense
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.CreatePortForwardingRulePage, {
        companyName,
        networkSlug: network.slug,
      })}
      arrangement="leading-icon"
      icon="plus"
      variant={variant}
      size={size}
    >
      Add port forward rule
    </Button>
  );
}

export function PortForwardingList() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const flags = useFeatureFlags();

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditPortForwardingRulePage);

  const portForwardingRules = useGraphQL(portForwardingRulesForNetworkQuery, {
    networkUUID: network.UUID,
  }).data?.portForwardingRulesForNetwork;

  const closeDrawer = useCloseDrawerCallback();

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const { hasPermission } = usePermissions();
  const canEditPortForwarding =
    (hasPermission(PermissionType.PermPortForwardWrite) && flags['port-forwarding']) || isOperator;

  const getLinkTo = useMemo(() => {
    if (canEditPortForwarding) {
      return (row: PortForwardingRule) =>
        makeDrawerLink(window.location, paths.drawers.EditPortForwardingRulePage, {
          ruleUUID: row.UUID,
          companyName,
          networkSlug: network.slug,
        });
    }
    return undefined;
  }, [canEditPortForwarding, companyName, network]);

  if (!portForwardingRules?.length) {
    return (
      <EmptyState
        icon="port-forward"
        heading="You have no port forwarding rules"
        action={<AddPortForwardingRuleButton />}
      />
    );
  }

  return (
    <AutoTable
      columns={columns}
      data={portForwardingRules}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      getLinkTo={getLinkTo}
      isRowSelected={(row) => row.UUID === drawerParams?.ruleUUID}
      onRowDeselect={closeDrawer}
    />
  );
}

export default function PortForwarding() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const firewallCrumb = useFirewallCrumbs();

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...firewallCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.PortForwardingPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'Port forwarding',
            },
          },
        ]}
        icon="port-forward"
        heading="Port forwarding"
        actions={
          <IsPermitted
            isPermitted={({ permissions, ldFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermPortForwardWrite) &&
                  ldFlags['port-forwarding'],
              )
            }
          >
            <PortForwardingActions />
          </IsPermitted>
        }
      />
      <PaneContent>
        <PortForwardingList />
      </PaneContent>
    </Pane>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';

import EditOneToOneNATDrawer from '../../../../../components/Firewall/OneToOneNAT/EditOneToOneNATRuleDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/one-to-one-nat/:ruleUUID',
  title: 'Edit rule - 1:1 NAT - Firewall',
});

export default function EditOneToOneNATRulePage() {
  const { ruleUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditOneToOneNATRulePage),
  );

  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, nosFlags, ldFlags, pathParams }) =>
        permissions.hasPermission(PermissionType.PermOneToOneNatWrite) &&
        nosFlags[NosFeature.ONE_TO_ONE_NAT] &&
        (isOperator || !!ldFlags['one-to-one-nat']) &&
        !!pathParams?.ruleUUID
      }
      should404OnAccessDenied
      allowOperatorAlways={false}
      path={Meta().path}
    >
      <EditOneToOneNATDrawer uuid={ruleUUID} />
    </IsPermitted>
  );
}

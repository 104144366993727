import type { HasChildren } from '@meterup/atto';
import type { Paths } from '@meterup/common';
import type React from 'react';
import { createCustomFieldPropsProvider } from '@meterup/atto';
import { truthy } from '@meterup/common';
import { useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { useMemo } from 'react';

import { getFieldProps, getInputProps } from '../../utils/inputs';

export const FieldProvider = createCustomFieldPropsProvider(({ name }: { name: string }) => {
  const form = useFormikContext<any>();

  return {
    inputProps: getInputProps(form, name),
    fieldProps: getFieldProps(form, name),
  };
});

export const NumberFieldProvider = createCustomFieldPropsProvider(
  ({ name, defaultValue }: { name: string; defaultValue: number | null }) => {
    const form = useFormikContext<any>();

    const setFormValue = (val: string) => {
      if (val === '' || val === 'default') {
        form.setFieldValue(name, defaultValue);
      } else {
        const numVal = parseInt(val, 10);
        if (Number.isInteger(numVal) && !Number.isNaN(numVal)) {
          form.setFieldValue(name, numVal);
        } else {
          form.setFieldValue(name, defaultValue);
        }
      }
    };

    return {
      inputProps: {
        ...getInputProps(form, name),
        value: get(form.values, name)?.toString() ?? defaultValue?.toString(),
        onValueChange: (val: string) => {
          setFormValue(val);
        },
        onChange: (eventOrValue: React.ChangeEvent | any) => {
          if (typeof eventOrValue === 'object') {
            setFormValue(eventOrValue.target.value);
          } else {
            setFormValue(eventOrValue);
          }
        },
      },
      fieldProps: getFieldProps(form, name),
    };
  },
);

export const MultiComboBoxFieldProvider = createCustomFieldPropsProvider(
  ({ name }: { name: string }) => {
    const form = useFormikContext<any>();

    return {
      inputProps: {
        ...getInputProps(form, name),
        onValueChange: (val: Set<string>) => {
          form.setFieldValue(name, Array.from(val));
        },
      },
      fieldProps: getFieldProps(form, name),
    };
  },
);

/**
 * Converts a boolean toggle to one of two options.
 */
export const ToggleOptionsFieldProvider = createCustomFieldPropsProvider(
  <T extends string | boolean>({
    name,
    negative,
    positive,
  }: {
    name: string;
    negative: T;
    positive: T;
  }) => {
    const form = useFormikContext<any>();

    const inputProps = getInputProps(form, name);

    return {
      inputProps: {
        ...inputProps,
        selected: inputProps.value === positive,
        onValueChange: (val: boolean) => {
          form.setFieldValue(name, val ? positive : negative);
        },
        onChange: (eventOrValue: React.ChangeEvent | any) => {
          if (
            typeof eventOrValue === 'object' &&
            'target' in eventOrValue &&
            typeof eventOrValue.target === 'object' &&
            'checked' in eventOrValue.target
          ) {
            form.setFieldValue(
              name,
              (eventOrValue as React.ChangeEvent<HTMLInputElement>)?.target?.checked
                ? positive
                : negative,
            );
          } else {
            form.setFieldValue(name, eventOrValue ? positive : negative);
          }
        },
      },
      fieldProps: getFieldProps(form, name),
    };
  },
);

export const ListFieldProvider = createCustomFieldPropsProvider(
  ({
    name,
    defaultValue,
    delimiter = '\\n',
  }: {
    name: string;
    defaultValue?: any[] | null | undefined;
    delimiter?: ',' | '\\n';
  }) => {
    const form = useFormikContext<any>();

    const touched = get(form.touched, name, false);

    function handleChange(value: string) {
      const trimmed = delimiter === ',' ? value.trim() : value;
      if (!trimmed) {
        form.setFieldTouched(name, false);
        form.setFieldValue(name, defaultValue, true);
        return;
      }
      const regex = new RegExp(`\\s*${delimiter}\\s*`);
      const values = trimmed.split(regex);
      form.setFieldTouched(name, true);
      form.setFieldValue(name, values, true);
    }

    const errorMessage: React.ReactNode | undefined = useMemo(() => {
      if (!touched) return undefined;

      const errorValues = Object.entries(form.errors)
        .filter(([key]) => key.startsWith(name))
        .map(([, message]) => message);

      if (!errorValues || errorValues.length < 1) {
        return undefined;
      }

      let errVal = errorValues.find(truthy);
      if (!errVal) {
        return undefined;
      }
      if (Array.isArray(errVal)) {
        [errVal] = errVal;
      }
      if (typeof errVal === 'object' && errVal.message) {
        errVal = errVal.message;
      }
      if (typeof errVal === 'string') {
        return errVal;
      }
      return undefined;
    }, [touched, form.errors, name]);

    let value;
    if (delimiter === ',') {
      value = get(form.values, name)?.join(', ')?.trim() ?? '';
    } else {
      value = get(form.values, name)?.join('\n') ?? '';
    }

    return {
      inputProps: {
        ...getInputProps(form, name),
        value,
        onChange: (event: React.ChangeEvent | string | any) => {
          if (typeof event === 'string') {
            handleChange(event);
          } else if ('target' in event && 'value' in event.target) {
            handleChange(event.target.value);
          }
        },
        onValueChange: handleChange,
      },
      fieldProps: {
        ...getFieldProps(form, name),
        errorMessage,
      },
    };
  },
);

export const FileUploadFieldProvider = createCustomFieldPropsProvider(
  ({ name }: { name: string }) => {
    const form = useFormikContext<any>();

    return {
      inputProps: {
        ...getInputProps(form, name),
        onFileUploaded({ s3Key }: { s3Key: string }) {
          form.setFieldValue(name, s3Key);
        },
        onFileRemoved({ s3Key }: { s3Key: string }) {
          if (form.values[name] === s3Key) {
            form.setFieldValue(name, '');
          }
        },
      },
      fieldProps: getFieldProps(form, name),
    };
  },
);

export const FilesUploadFieldProvider = createCustomFieldPropsProvider(
  ({ name }: { name: string }) => {
    const form = useFormikContext<any>();

    return {
      inputProps: {
        ...getInputProps(form, name),
        onFileUploaded({ s3Key }: { s3Key: string }) {
          form.setFieldValue(name, Array.from(new Set(...get(form.values, name), s3Key).values()));
        },
        onFileRemoved({ s3Key }: { s3Key: string }) {
          form.setFieldValue(
            name,
            get(form.values, name).filter((key: string) => key !== s3Key),
          );
        },
      },
      fieldProps: getFieldProps(form, name),
    };
  },
);

type LegacyFC<T extends Object> = React.FC<T & HasChildren>;

export const createFieldProvider = <T extends object>() =>
  FieldProvider as LegacyFC<{ name: Paths<T> }>;

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';
import { Navigate, useSearchParams } from 'react-router-dom';

import { FatalErrorFallback } from '../../../components/ErrorFallback/ErrorFallback';
import { paths } from '../../../constants';
import { UnauthenticatedError } from '../../../errors';
import { useNetworkAndActiveControllerForSlug } from '../../../hooks/useActiveControllerForNetwork';
import { useCompanyAndNetworkSlugsFromPath } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { ProductTypes, useUserProductsAccess } from '../../../hooks/useUserProductsAccess';
import { makeLink } from '../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug',
  layout: 'VirtualNetworkLayout',
});

// Similar logic to CompanyRoot.page.tsx
export default function IndividualNetworkRootPage() {
  const [params] = useSearchParams();
  const networkAndCompanySlugs = checkDefinedOrThrow(useCompanyAndNetworkSlugsFromPath());

  const access = useUserProductsAccess();

  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const networkAndActiveController = useNetworkAndActiveControllerForSlug(
    networkAndCompanySlugs?.networkSlug ?? null,
  );

  if (access.hasAccessToProduct(ProductTypes.NETWORK)) {
    if (isCOS2Enabled) {
      return (
        <Navigate
          to={{
            pathname: makeLink(paths.pages.InsightsNetworkPage, {
              companyName: networkAndCompanySlugs.companySlug,
              networkSlug: networkAndCompanySlugs.networkSlug,
            }),
            search: params.toString(),
          }}
          replace
        />
      );
    }

    if (networkAndActiveController?.controllerSerialNumber) {
      return (
        <Navigate
          to={{
            pathname: makeLink(paths.pages.OverviewPage, {
              companyName: networkAndCompanySlugs.companySlug,
              controllerName: networkAndActiveController.controllerSerialNumber,
            }),
            search: params.toString(),
          }}
          replace
        />
      );
    }

    return (
      <Navigate
        to={{
          pathname: makeLink(paths.pages.OnboardingPage, {
            networkSlug: networkAndCompanySlugs.networkSlug,
            companyName: networkAndCompanySlugs.companySlug,
          }),
          search: params.toString(),
        }}
        replace
      />
    );
  }

  if (access.hasAccessToProduct(ProductTypes.CONNECT)) {
    return (
      <Navigate
        to={makeLink(paths.pages.ConnectDashboardAllLocationsPage, {
          companyName: networkAndCompanySlugs.companySlug,
        })}
        replace
      />
    );
  }

  // Should basically never happen
  const error = new UnauthenticatedError(
    'You do not have access to Meter Network or Meter Connect for this network. Please contact support.',
  );

  return (
    <FatalErrorFallback error={error} componentStack={null} eventId="" resetError={() => null} />
  );
}

import { expectDefinedOrThrow } from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { TrafficDirection } from '../../../gql/graphql';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { getDurationSeconds, getStep } from '../../../utils/chart_utils';
import { SwitchMetricsQuery } from '../../../utils/switch_charts_utils';
import ChartFilter from '../../ChartFilter';
import { SwitchPortNumbersQuery } from './SwitchInsights';

export default function SwitchFilters({
  serialNumber,
  uuid,
}: {
  serialNumber: string;
  uuid: string;
}) {
  const queryClient = useQueryClient();

  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const duration = useMemo(() => getDurationSeconds(currentTimePeriod), [currentTimePeriod]);
  const step = useMemo(() => getStep(currentTimePeriod), [currentTimePeriod]);

  const switchSeriesIds = useGraphQL(SwitchPortNumbersQuery, {
    virtualDeviceUUID: uuid,
  }).data?.phyInterfacesForVirtualDevice.map((d) => `Port ${d.portNumber}`);
  expectDefinedOrThrow(switchSeriesIds);

  const refetchMetrics = () => {
    queryClient.invalidateQueries(
      makeQueryKey(SwitchMetricsQuery, {
        input: {
          serialNumber,
          directions: [TrafficDirection.Rx, TrafficDirection.Tx],
        },
        filter: {
          durationSeconds: duration,
          stepSeconds: step,
        },
      }),
    );
  };
  return (
    <ChartFilter
      sticky={false}
      currentTimePeriod={currentTimePeriod}
      setCurrentTimePeriod={setCurrentTimePeriod}
      refetchMetrics={refetchMetrics}
      seriesIds={switchSeriesIds}
    />
  );
}

import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { graphql } from '../../../gql';
import { BootHistoryTable } from '../../Devices/Insights';

const APBootHistoryQuery = graphql(`
  query APBootHistoryQuery($serialNumber: String!, $count: Int) {
    hardwareDevice(serialNumber: $serialNumber) {
      bootHistory(count: $count) {
        bootCount
        buildName
        rebootReason
        createdAt
      }
    }
  }
`);

export default function AccessPointBootHistory({
  serialNumber,
  globalFilter,
}: {
  serialNumber: string;
  globalFilter: string | undefined;
}) {
  const accessPointData = useGraphQL(APBootHistoryQuery, {
    serialNumber,
    count: 20,
  }).data;
  expectDefinedOrThrow(accessPointData, new ResourceNotFoundError('Access point not found'));

  return (
    <BootHistoryTable
      bootHistory={accessPointData.hardwareDevice.bootHistory}
      globalFilter={globalFilter}
    />
  );
}

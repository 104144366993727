import { Text } from '@meterup/atto';
import React from 'react';

export function NoValue({ internal }: { internal?: boolean }) {
  return (
    <Text family="monospace" internal={internal} style={{ color: 'currentColor' }}>
      -
    </Text>
  );
}

import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@meterup/atto';
import { useMemo } from 'react';

import { VirtualDeviceType } from '../../gql/graphql';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { FilterBar } from '../Insights/FilterBar';

export default function RoutingTableFilters() {
  const network = useNetwork();
  const controllers = useMemo(
    () =>
      network.virtualDevices.filter(
        (vd) => vd.deviceType === VirtualDeviceType.Controller && !!vd?.hardwareDevice?.isActive,
      ),
    [network],
  );

  const [selectedControllerUUID, setSelectedControllerUUID] = useSearchParamsState<string>(
    'selectedController',
    controllers[0]?.UUID ?? '',
  );

  const selectedController = useMemo(
    () => controllers.find((c) => c.UUID === selectedControllerUUID),
    [controllers, selectedControllerUUID],
  );

  return (
    <FilterBar>
      {controllers.length > 0 && (
        <DropdownMenu>
          <DropdownMenuButton
            variant="secondary"
            arrangement="leading-icon"
            icon="security-appliance"
            size="small"
          >
            {selectedController ? selectedController.label : 'Select security appliance'}
          </DropdownMenuButton>
          <DropdownMenuPopover>
            <DropdownMenuRadioGroup
              value={selectedControllerUUID}
              onValueChange={(val) => setSelectedControllerUUID(val)}
            >
              {controllers.map((controller) => (
                <DropdownMenuRadioItem key={controller.UUID} value={controller.UUID}>
                  {controller.label}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuPopover>
        </DropdownMenu>
      )}
    </FilterBar>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';
import { Navigate } from 'react-router-dom';

import SwitchDrawer from '../../../../../components/Hardware/Switches/SwitchDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../../../hooks/useActiveControllerForNetwork';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/switches/:uuid',
  title: 'Switch - Switches - Hardware',
});

export default function SwitchSummaryPage() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const activeController = useActiveControllerForNetwork(network);
  const isOperator = useIsOperator({ respectDemoMode: true });
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);
  const featureFlags = useFeatureFlags();

  const controllerSerialNumber = activeController?.hardwareDevice?.serialNumber;

  if (!isOperator && !featureFlags['sos-ui']) {
    throw new ResourceNotFoundError('Sorry, page not found');
  }

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SwitchSummaryPage);

  if (!isOperator && !isSOSEnabled) {
    if (controllerSerialNumber) {
      return (
        <Navigate
          to={makeDrawerLink(window.location, paths.drawers.LegacySwitchSummaryPage, {
            controllerName: controllerSerialNumber,
            companyName,
            id: drawerParams?.uuid!,
          })}
        />
      );
    }

    return (
      <Navigate
        to={makeDrawerLink(window.location, paths.pages.IndividualNetworkRootPage, {
          companyName,
          networkSlug: network.slug,
        })}
      />
    );
  }
  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
            nosFlags[NosFeature.SOS],
        )
      }
      should404OnAccessDenied
      path={Meta().path}
    >
      <SwitchDrawer uuid={drawerParams?.uuid!} />
    </IsPermitted>
  );
}

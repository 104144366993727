import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  PrimaryField,
  Select,
  SelectItem,
  space,
  Textarea,
  TextInput,
  VStack,
} from '@meterup/atto';
import { checkDefinedOrThrow, notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import { paths } from '../../constants';
import { type DeviceModel, PermissionType, VirtualDeviceType } from '../../gql/graphql';
import {
  CreateVirtualDeviceInputSchema,
  CreateVirtualDevicesInputSchema,
} from '../../gql/zod-types';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useCompanyAndNetworkSlugsFromPath, useNetworkUUID } from '../../hooks/useNetworkFromPath';
import { usePermissions } from '../../providers/PermissionsProvider';
import { useVirtualDevices } from '../../routes/pages/network/insights/logs/hooks/useVirtualDevices';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { lcfirst } from '../../utils/strings';
import { deviceTypeToHardwareIconPropHardware } from '../../utils/types';
import { withZodSchema } from '../../utils/withZodSchema';
import { FieldProvider } from '../Form/FieldProvider';
import { NumberField, TextField } from '../Form/Fields';
import { PowerDistributionUnitsQuery } from '../Hardware/PowerDistributionUnits/utils';
import {
  ControllersForSecurityApplianceQuery,
  ControllersQuery,
} from '../Hardware/SecurityAppliance/utils';
import { SwitchesQuery } from '../Hardware/Switches/utils';
import { AccessPointsQuery, ValidAPDrawerTabs } from '../Wireless/utils';
import {
  createVirtualDevice,
  createVirtualDevicesMutation,
  defaultDeviceTypeModels,
  deviceTypeForVirtualDeviceType,
  deviceTypeLabelPlural,
  deviceTypeModels,
  deviceTypeToLabelMap,
  deviceTypeToLabelPrefix,
} from './utils';

export const createVirtualDeviceInputSchema = CreateVirtualDeviceInputSchema.refine(
  (values) =>
    deviceTypeModels[deviceTypeForVirtualDeviceType[values.deviceType]].includes(
      values.deviceModel as DeviceModel,
    ),
  'Model is not valid for device type.',
);

const LABEL_SUFFIX_INDEX_MESSAGE = 'Please enter a positive integer.';
const COUNT_MESSAGE = 'Please enter a positive integer between 1 and 100 (inclusive).';

export const createVirtualDevicesInputSchema = CreateVirtualDevicesInputSchema.extend({
  labelSuffixIndex: z
    .number({ invalid_type_error: LABEL_SUFFIX_INDEX_MESSAGE })
    .int({ message: LABEL_SUFFIX_INDEX_MESSAGE })
    .positive({ message: LABEL_SUFFIX_INDEX_MESSAGE }),
  count: z
    .number({ invalid_type_error: COUNT_MESSAGE })
    .int({ message: COUNT_MESSAGE })
    .positive({ message: COUNT_MESSAGE })
    .max(100, { message: COUNT_MESSAGE }),
}).refine(
  (values) =>
    deviceTypeModels[deviceTypeForVirtualDeviceType[values.deviceType]].includes(
      values.deviceModel as DeviceModel,
    ),
  'Model is not valid for device type.',
);

export function CreateVirtualDeviceModelField() {
  const { values, setFieldValue } =
    useFormikContext<z.infer<typeof createVirtualDeviceInputSchema>>();

  useEffect(() => {
    setFieldValue(
      'deviceModel',
      defaultDeviceTypeModels[deviceTypeForVirtualDeviceType[values.deviceType]],
    );
  }, [values.deviceType, setFieldValue]);

  return (
    <FieldProvider name="deviceModel">
      <PrimaryField
        label="Model"
        element={
          <Select width="100%" placeholder="Select model">
            {deviceTypeModels[deviceTypeForVirtualDeviceType[values.deviceType]].map((model) => (
              <SelectItem key={model}>{model.toUpperCase()}</SelectItem>
            ))}
          </Select>
        }
      />
    </FieldProvider>
  );
}

export function VirtualDevicesCreateDrawer({ deviceType }: { deviceType: VirtualDeviceType }) {
  const closeDrawer = useCloseDrawerCallback();
  const networkUUID = checkDefinedOrThrow(useNetworkUUID());
  const queryClient = useQueryClient();
  const createVirtualDevices = useGraphQLMutation(createVirtualDevicesMutation);
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const lowercaseLabel = lcfirst(deviceTypeLabelPlural(deviceType));

  const { virtualDevices: existingVirtualDevices } = useVirtualDevices({
    deviceType,
  });

  const hardwareDeviceType = deviceTypeForVirtualDeviceType[deviceType];

  const graphqlError = useMemo(
    () => (createVirtualDevices.error ? getGraphQLError(createVirtualDevices.error) : null),
    [createVirtualDevices.error],
  );

  return (
    <Drawer>
      <Formik<z.infer<typeof createVirtualDevicesInputSchema>>
        initialValues={{
          deviceType,
          deviceModel: defaultDeviceTypeModels[hardwareDeviceType],
          count: 1,
          labelPrefix: deviceTypeToLabelPrefix[hardwareDeviceType],
          labelSuffixIndex: existingVirtualDevices.length + 1,
        }}
        validate={withZodSchema(createVirtualDevicesInputSchema)}
        onSubmit={(values) => {
          if (createVirtualDevices.isLoading) return;

          createVirtualDevices.mutate(
            { networkUUID, input: values },
            {
              onSuccess: (response) => {
                switch (deviceType) {
                  case VirtualDeviceType.Switch:
                    queryClient.invalidateQueries(makeQueryKey(SwitchesQuery, { networkUUID }));
                    break;
                  case VirtualDeviceType.AccessPoint:
                    queryClient.invalidateQueries(
                      makeQueryKey(AccessPointsQuery, { networkUUID, includeUptime }),
                    );
                    break;
                  case VirtualDeviceType.Controller:
                    queryClient.invalidateQueries(makeQueryKey(ControllersQuery, { networkUUID }));
                    queryClient.invalidateQueries(
                      makeQueryKey(ControllersForSecurityApplianceQuery, { networkUUID }),
                    );
                    break;
                  case VirtualDeviceType.PowerDistributionUnit:
                    queryClient.invalidateQueries(
                      makeQueryKey(PowerDistributionUnitsQuery, { networkUUID }),
                    );
                    break;
                }
                notify(
                  `Successfully created ${response.createVirtualDevices.length} virtual ${lowercaseLabel}.`,
                  {
                    variant: 'positive',
                  },
                );
              },
            },
          );
        }}
      >
        <Form>
          <DrawerHeader
            icon={deviceTypeToHardwareIconPropHardware(hardwareDeviceType)}
            heading={`Add ${lowercaseLabel}`}
            onClose={closeDrawer}
          />
          <DrawerContent>
            <VStack spacing={space(16)}>
              <FieldContainer>
                <CreateVirtualDeviceModelField />
              </FieldContainer>
              <FieldContainer>
                <TextField
                  name="labelPrefix"
                  label="Label prefix"
                  description='The labels of the devices will all start with the prefix, followed by an increasing suffix number, zero-padded for single digits (e.g. "AP" -> "AP01", "AP02", "AP03", ...).'
                />
              </FieldContainer>
              <FieldContainer>
                <NumberField
                  name="labelSuffixIndex"
                  label="Suffix number start"
                  description="The number to start with when counting upward."
                />
              </FieldContainer>
              <FieldContainer>
                <NumberField
                  name="count"
                  label="Number of devices to create"
                  description="Between 1 and 100 (inclusive)."
                />
              </FieldContainer>
              {createVirtualDevices.isError && (
                <Alert
                  heading="Error creating virtual devices"
                  copy={graphqlError?.message}
                  variant="negative"
                />
              )}
            </VStack>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={createVirtualDevices.isLoading}
                  loading={createVirtualDevices.isLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

export default function VirtualDeviceCreateDrawer({
  deviceType,
}: {
  deviceType: VirtualDeviceType;
}) {
  const closeDrawer = useCloseDrawerCallback();
  const networkUUID = checkDefinedOrThrow(useNetworkUUID());
  const slugs = checkDefinedOrThrow(useCompanyAndNetworkSlugsFromPath());
  const [errorText, setErrorText] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const createVirtualDeviceMutation = useGraphQLMutation(createVirtualDevice);
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const hardwareDeviceType = deviceTypeForVirtualDeviceType[deviceType];

  const lowercaseLabel = lcfirst(deviceTypeToLabelMap[hardwareDeviceType]);

  return (
    <Drawer>
      <Formik<z.infer<typeof createVirtualDeviceInputSchema>>
        initialValues={{
          label: '',
          description: '',
          deviceType,
          deviceModel: defaultDeviceTypeModels[deviceTypeForVirtualDeviceType[deviceType]],
        }}
        validate={withZodSchema(createVirtualDeviceInputSchema)}
        onSubmit={(values) => {
          if (createVirtualDeviceMutation.isLoading) return;

          setErrorText(null);

          const variables = { networkUUID, input: values };
          createVirtualDeviceMutation.mutate(variables, {
            onError: (error) => {
              const gqlError = getGraphQLError(error);
              setErrorText(gqlError?.message ?? 'Unknown error');
            },
            onSuccess: (response) => {
              switch (deviceType) {
                case VirtualDeviceType.Switch:
                  queryClient.invalidateQueries(makeQueryKey(SwitchesQuery, { networkUUID }));
                  navigate(
                    makeDrawerLink(window.location, paths.drawers.SwitchSummaryPage, {
                      networkSlug: slugs.networkSlug,
                      companyName: slugs.companySlug,
                      uuid: response.createVirtualDevice.UUID,
                    }),
                  );
                  break;
                case VirtualDeviceType.AccessPoint:
                  queryClient.invalidateQueries(
                    makeQueryKey(AccessPointsQuery, { networkUUID, includeUptime }),
                  );
                  navigate(
                    makeDrawerLink(window.location, paths.drawers.AccessPointDrawerPage, {
                      networkSlug: slugs.networkSlug,
                      companyName: slugs.companySlug,
                      uuid: response.createVirtualDevice.UUID,
                      tab: ValidAPDrawerTabs.AccessPoint,
                    }),
                  );
                  break;
                case VirtualDeviceType.Controller:
                  queryClient.invalidateQueries(makeQueryKey(ControllersQuery, { networkUUID }));
                  queryClient.invalidateQueries(
                    makeQueryKey(ControllersForSecurityApplianceQuery, { networkUUID }),
                  );
                  navigate(
                    makeDrawerLink(window.location, paths.drawers.SecurityApplianceSummaryPage, {
                      networkSlug: slugs.networkSlug,
                      companyName: slugs.companySlug,
                      uuid: response.createVirtualDevice.UUID,
                    }),
                  );
                  break;
                case VirtualDeviceType.PowerDistributionUnit:
                  queryClient.invalidateQueries(
                    makeQueryKey(PowerDistributionUnitsQuery, { networkUUID }),
                  );
                  navigate(
                    makeDrawerLink(
                      window.location,
                      paths.drawers.PowerDistributionUnitSummaryPage,
                      {
                        networkSlug: slugs.networkSlug,
                        companyName: slugs.companySlug,
                        uuid: response.createVirtualDevice.UUID,
                      },
                    ),
                  );
                  break;
              }
              notify(`Successfully created virtual ${lowercaseLabel} "${values.label}".`, {
                variant: 'positive',
              });
            },
          });
        }}
      >
        <Form>
          <DrawerHeader
            icon={deviceTypeToHardwareIconPropHardware(hardwareDeviceType)}
            heading={`Add ${lowercaseLabel}`}
            onClose={closeDrawer}
          />
          <DrawerContent>
            <VStack spacing={space(16)}>
              <FieldContainer>
                <CreateVirtualDeviceModelField />
              </FieldContainer>
              <FieldContainer>
                <FieldProvider name="label">
                  <PrimaryField label="Label" element={<TextInput />} />
                </FieldProvider>
              </FieldContainer>
              <FieldContainer>
                <FieldProvider name="description">
                  <PrimaryField optional label="Description" element={<Textarea />} />
                </FieldProvider>
              </FieldContainer>
              {errorText && (
                <Alert
                  heading="Error creating virtual device"
                  copy={errorText}
                  variant="negative"
                />
              )}
            </VStack>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={createVirtualDeviceMutation.isLoading}
                  loading={createVirtualDeviceMutation.isLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

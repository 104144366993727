import ISPsAdd from '../../../../../components/NetworkWide/ISPs/ISPsAdd';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/isps/add',
  title: 'Add ISP - ISPs - Network-wide',
});

export default function ISPsAddDrawerPage() {
  return <ISPsAdd />;
}

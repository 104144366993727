import { Drawer, DrawerHeader } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';

import AutoVPNGroupAddEditForm from '../../../components/autoVPN/AutoVPNGroupAddEditForm';
import { AutoVPNGroupQuery } from '../../../components/autoVPN/utils';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/auto-vpn/groups/:groupUUID/edit',
  title: 'Edit group - Group - Auto VPN - Network',
});

export default function AutoVPNGroupConfigEditPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AutoVPNGroupConfigEditPage);

  const group = useGraphQL(
    AutoVPNGroupQuery,
    {
      uuid: drawerParams?.groupUUID!,
    },
    {
      enabled: !!drawerParams?.groupUUID,
    },
  )?.data?.autoVPNGroup;

  const closeDrawer = useCloseDrawerCallback();

  return (
    <IsPermitted
      should404OnAccessDenied
      allowOperatorAlways={false}
      path={Meta().path}
      isPermitted={({ isOperator, permissions, companyNosFlags, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermAutoVpnWrite) &&
        companyNosFlags[NosFeature.SITE_TO_SITE_VPN] &&
        (isOperator || !!ldFlags['site-to-site-vpn']) &&
        !!drawerParams?.groupUUID &&
        !!group
      }
    >
      <Drawer>
        <DrawerHeader icon="pencil" heading="Edit group" onClose={closeDrawer} />
        <AutoVPNGroupAddEditForm group={group} />
      </Drawer>
    </IsPermitted>
  );
}

import type { IconName } from '@meterup/atto';
import { Badge, EmptyState, Link, PaneContent, Text } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { type To, Link as ReactRouterLink } from 'react-router-dom';

import type { RoutesForVirtualDeviceQuery } from '../../gql/graphql';
import { paths } from '../../constants';
import { graphql } from '../../gql';
import { VirtualDeviceType } from '../../gql/graphql';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import { AutoVPNGroupTab } from '../autoVPN/utils';
import { getPhyInterfaceLabel } from '../Firewall/utils';
import { SecurityApplianceDetailsTab } from '../Hardware/SecurityAppliance/utils2';
import { VLANTab } from '../NetworkWide/VLANs/VLANDetails/VLANDetails';
import { NoValue } from '../NoValue';
import { createColumnBuilder } from '../Table/createColumnBuilder';

const routesForVirtualDeviceQuery = graphql(`
  query RoutesForVirtualDevice($virtualDeviceUUID: UUID!) {
    networkRoutesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {
      destination
      gateway
      attachment {
        vlan {
          __typename
          UUID
          name
        }
        phyInterface {
          __typename
          UUID
          portNumber
          label
          hardwareLabel
          virtualDevice {
            UUID
            label
            deviceType
            deviceModel
          }
          internetServicePlan {
            provider {
              name
            }
          }
        }
        autoVPNGroup {
          __typename
          UUID
          name
        }
        ipSecTunnel {
          __typename
          UUID
          name
        }
        clientVPNServer {
          __typename
          UUID
          endpoint
        }
      }
    }
  }
`);

type NetworkRoute = RoutesForVirtualDeviceQuery['networkRoutesForVirtualDevice'][number];

type RouteAttachment = NetworkRoute['attachment'];

function labelForAttachment(attachment: RouteAttachment): string {
  if (attachment.vlan) {
    return attachment.vlan.name;
  }
  if (attachment.phyInterface) {
    return getPhyInterfaceLabel(attachment.phyInterface, false);
  }
  if (attachment.ipSecTunnel) {
    return attachment.ipSecTunnel.name;
  }
  if (attachment.autoVPNGroup) {
    return attachment.autoVPNGroup.name;
  }
  if (attachment.clientVPNServer) {
    // TODO: Replace with name
    return attachment.clientVPNServer.endpoint;
  }

  return '';
}

function AttachmentCell({ row, value }: { row: NetworkRoute; value: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  let to: To | undefined;
  let icon: IconName | undefined;
  if (row.attachment.vlan) {
    icon = 'vlan';
    to = makeLink(paths.pages.VLANDetailsPage, {
      companyName,
      networkSlug: network.slug,
      uuid: row.attachment.vlan.UUID,
      tab: VLANTab.Details,
    });
  }
  if (row.attachment.phyInterface) {
    icon = 'globe';
    to = makeDrawerLink(
      {
        pathname: makeLink(paths.pages.SecurityApplianceDetailPage, {
          companyName,
          networkSlug: network.slug,
          uuid: row.attachment.phyInterface.virtualDevice.UUID,
          tab: SecurityApplianceDetailsTab.Ports,
        }),
        search: window.location.search,
      },
      paths.drawers.SecurityAppliancePortDetailPage,
      {
        companyName,
        networkSlug: network.slug,
        virtualDeviceUUID: row.attachment.phyInterface.virtualDevice.UUID,
        phyInterfaceUUID: row.attachment.phyInterface.UUID,
      },
    );
  }
  if (row.attachment.ipSecTunnel) {
    icon = 'site-to-site';
    to = makeDrawerLink(
      {
        pathname: makeLink(paths.pages.IPSecTunnelsPage, {
          companyName,
          networkSlug: network.slug,
        }),
        search: window.location.search,
      },
      paths.drawers.EditIPSecTunnelPage,
      {
        companyName,
        networkSlug: network.slug,
        IPSecUUID: row.attachment.ipSecTunnel.UUID,
      },
    );
  }
  if (row.attachment.autoVPNGroup) {
    icon = 'site-to-site';
    if (row.attachment.autoVPNGroup.UUID) {
      to = makeDrawerLink(
        {
          pathname: makeLink(paths.pages.AutoVPNGroupsPage, {
            companyName,
          }),
          search: window.location.search,
        },
        paths.pages.AutoVPNGroupPage,
        {
          companyName,
          groupUUID: row.attachment.autoVPNGroup.UUID,
          activeTab: AutoVPNGroupTab.Routes,
        },
      );
    }
  }
  if (row.attachment.clientVPNServer) {
    icon = 'vpn';
    // TODO: to
  }

  const badge = (
    <Badge arrangement="leading-icon" ends="card" icon={icon} size="small">
      {value}
    </Badge>
  );

  if (!to) {
    return badge;
  }

  return (
    <Link as={ReactRouterLink} to={to}>
      {badge}
    </Link>
  );
}

function MonoCell({ value }: { value: string | undefined | null }) {
  if (!value) return <NoValue />;

  return <Text family="monospace">{value}</Text>;
}

const builder = createColumnBuilder<NetworkRoute>();

const columns = [
  builder.data((row) => row.gateway, {
    id: 'gateway',
    header: 'Gateway',
    cell: MonoCell,
  }),
  builder.data((row) => row.destination, {
    id: 'destination',
    header: 'Destination',
    cell: MonoCell,
  }),
  builder.data((row) => labelForAttachment(row.attachment), {
    id: 'attachment',
    header: 'Entity',
    cell: AttachmentCell,
  }),
];

function RoutingTableContent({ virtualDeviceUUID }: { virtualDeviceUUID: string }) {
  const routes =
    useGraphQL(routesForVirtualDeviceQuery, {
      virtualDeviceUUID,
    }).data?.networkRoutesForVirtualDevice ?? [];

  if (!routes.length) {
    return <EmptyState heading="No routes found for security appliance" />;
  }

  return <AutoTable columns={columns} data={routes} />;
}

export default function RoutingTable() {
  const network = useNetwork();
  const controllers = useMemo(
    () =>
      network.virtualDevices.filter(
        (vd) => vd.deviceType === VirtualDeviceType.Controller && !!vd?.hardwareDevice?.isActive,
      ),
    [network],
  );

  const [selectedControllerUUID] = useSearchParamsState<string>(
    'selectedController',
    controllers[0]?.UUID ?? '',
  );

  return (
    <PaneContent>
      {selectedControllerUUID ? (
        <RoutingTableContent virtualDeviceUUID={selectedControllerUUID} />
      ) : (
        <EmptyState heading="No security appliances found" />
      )}
    </PaneContent>
  );
}

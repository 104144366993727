import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import CreateClientVPNServerDrawer from '../../../../../components/ClientVPN/ServerDrawer';
import { clientVPNServersQuery } from '../../../../../components/ClientVPN/utils';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/secure-tunnels/vpn/:serverUUID',
  title: 'Edit VPN server - Client VPN - Secure tunnels',
});

export default function ClientVPNServerUpdatePage() {
  const network = useNetwork();

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ClientVPNServerUpdatePage);
  const server = useGraphQL(clientVPNServersQuery, {
    networkUUID: network.UUID,
  }).data?.clientVPNServers?.find((s) => s.UUID === drawerParams?.serverUUID);
  expectDefinedOrThrow(server, new ResourceNotFoundError('VPN server not found'));

  return (
    <IsPermitted
      permissions={PermissionType.PermClientVpnWrite}
      should404OnAccessDenied
      path={Meta().path}
    >
      <CreateClientVPNServerDrawer server={server} />
    </IsPermitted>
  );
}

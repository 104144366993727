import type { OverlayTriggerState } from '@meterup/atto';
import { Alert, Button, Dialog, DialogContent, DialogFooter, DialogHeader } from '@meterup/atto';
import { makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { InternalNote } from './utils';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import {
  deleteInternalNoteMutation,
  internalNoteQuery,
  internalNotesForNetworkQuery,
} from './utils';

type DeleteNoteDialogProps = {
  state: OverlayTriggerState;
  note: InternalNote;
};

export function DeleteNoteDialog({ state, note }: DeleteNoteDialogProps) {
  const network = useNetwork();
  const deleteNote = useGraphQLMutation(deleteInternalNoteMutation);

  const queryClient = useQueryClient();
  const { mutate: doDeleteNote } = deleteNote;
  const { close: closeDialog } = state;

  const handleDelete = useCallback(() => {
    doDeleteNote(
      { UUID: note.UUID },
      {
        onSuccess() {
          closeDialog();
          queryClient.invalidateQueries(
            makeQueryKey(internalNotesForNetworkQuery, { networkUUID: network.UUID }),
          );
          queryClient.invalidateQueries(makeQueryKey(internalNoteQuery, { UUID: note.UUID }));
        },
      },
    );
  }, [network.UUID, note.UUID, doDeleteNote, closeDialog, queryClient]);

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete note" />
      <DialogContent gutter="all">
        <Alert
          icon="attention"
          heading="Are you sure you want to delete this note along with any attachments?"
        />

        {deleteNote.isError && (
          <Alert
            icon="warning"
            variant="negative"
            heading="There was a problem deleting the note."
            copy={
              deleteNote.error && deleteNote.error instanceof Error
                ? deleteNote.error.message
                : undefined
            }
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button variant="secondary" onClick={state.close}>
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

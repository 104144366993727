import { api } from '@meterup/proto';

import type { NetworkPlansData } from '../../types';
import { getRealm, Realm } from '../../../utils/realm';

export function demoISPs(controllerName: string): NetworkPlansData {
  const realm = getRealm();
  // A bit of a hack here. This basically lets us see the logos of comcast
  // and centuryLink for the stubbed data. Assumption here is the providers
  // on your local machine match those in dev.
  let comcastSid = 'a5693c26-a167-4fd3-b6b0-6fa0868c2269';
  let centuryLinkSid = 'c488c76d-1ca5-4df3-96ed-f98748ff3987';

  if (realm === Realm.PRODUCTION) {
    comcastSid = '2a431254-18b9-41cc-963e-05d049e195d1';
    centuryLinkSid = '21596285-4e60-4a51-9c59-3429a416793a';
  }

  return {
    plans: [
      {
        sid: 'demo-isp-1',
        created_at: '2023-01-01T01:44:12.268018Z',
        controller: controllerName,
        provider: comcastSid,
        provider_name: 'Comcast',
        product: api.ISPProduct.IR_DEDICATED_FIBER,
        status: api.ISPStatus.IS_PRIMARY,
        download_kbps: 1024000,
        upload_kbps: 1024000,
        monthly_cost_cents: 156505,
        has_static_ip: true,
        static_ip_range: '50.217.234.18/29',
        gateway_addr: '50.217.234.17',
        controller_ip: '50.217.234.18',
        first_usable_ip: '50.217.234.17',
        last_usable_ip: '50.217.234.22',
        network_interface: 'wan0',
      },
      {
        sid: 'demo-isp-2',
        created_at: '2023-01-01T01:44:15.276039Z',
        controller: controllerName,
        provider: centuryLinkSid,
        provider_name: 'CenturyLink',
        product: api.ISPProduct.IR_SHARED_FIBER,
        status: api.ISPStatus.IS_BACKUP,
        download_kbps: 1024000,
        upload_kbps: 1024000,
        monthly_cost_cents: 16500,
        has_static_ip: true,
        static_ip_range: '75.51.44.49/29',
        gateway_addr: '75.51.44.54',
        controller_ip: '75.51.44.49',
        first_usable_ip: '75.51.44.49',
        last_usable_ip: '75.51.44.54',
        network_interface: 'wan1',
      },
    ],
  };
}

import {
  Alert,
  Button,
  CompositeField,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  PrimaryFieldComposite,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import UnifiedFileUploader from '@meterup/common/src/components/UnifiedFileUploader';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { paths } from '../../constants';
import { AllowedFolder, PermissionType } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../providers/PermissionsProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../utils/withZodSchema';
import DeleteDialog from '../Dialogs/DeleteDialog';
import { FileUploadFieldProvider } from '../Form/FieldProvider';
import { TextareaField, TextField } from '../Form/Fields';
import {
  type FloorPlan,
  type FloorPlanInputValues,
  createFloorPlanMutation,
  deleteFloorPlanMutation,
  floorPlanInputSchema,
  floorPlansQuery,
  updateFloorPlanMutation,
} from './utils';

export default function FloorPlanEditDrawer({ floorPlan }: { floorPlan?: FloorPlan }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const { mutate: createFloorPlan } = useGraphQLMutation(createFloorPlanMutation);
  const { mutate: updateFloorPlan } = useGraphQLMutation(updateFloorPlanMutation);
  const { mutate: deleteFloorPlan, error: deleteFloorPlanError } =
    useGraphQLMutation(deleteFloorPlanMutation);

  const queryClient = useQueryClient();

  const closeDrawer = useCloseDrawerCallback();
  const navigate = useNavigate();

  const { state: deleteDialogState } = useDialogState();
  const { close: closeDialog } = deleteDialogState;
  const { hasPermission } = usePermissions();

  const handleDelete = useCallback(() => {
    if (!floorPlan) return;

    deleteFloorPlan(
      { UUID: floorPlan.UUID },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(floorPlansQuery, { networkUUID: network.UUID }),
          );
          notify('Successfully deleted floor plan.', {
            variant: 'positive',
          });
          closeDialog();
          closeDrawer();
        },
      },
    );
  }, [deleteFloorPlan, floorPlan, closeDialog, closeDrawer, queryClient, network.UUID]);

  const handleSubmit = useCallback(
    (values: FloorPlanInputValues) => {
      if (floorPlan?.UUID) {
        updateFloorPlan(
          { UUID: floorPlan?.UUID, input: values },
          {
            onSuccess() {
              queryClient.invalidateQueries(
                makeQueryKey(floorPlansQuery, { networkUUID: network.UUID }),
              );
              notify('Floor plan updated successfully.', {
                variant: 'positive',
              });
              closeDrawer();
            },
            onError(err) {
              notify(`Failed to update floor plan${getGraphQLErrorMessageOrEmpty(err)}`, {
                variant: 'negative',
              });
            },
          },
        );
      } else {
        createFloorPlan(
          { networkUUID: network.UUID, input: values },
          {
            onSuccess(result) {
              queryClient.invalidateQueries(
                makeQueryKey(floorPlansQuery, { networkUUID: network.UUID }),
              );
              notify('Floor plan created successfully.', {
                variant: 'positive',
              });
              closeDrawer();
              navigate(
                makeLink(paths.pages.FloorPlanPage, {
                  companyName,
                  networkSlug: network.slug,
                  floorPlanUUID: result.createFloorPlan.UUID,
                }),
              );
            },
            onError(err) {
              notify(`Failed to create floor plan${getGraphQLErrorMessageOrEmpty(err)}`, {
                variant: 'negative',
              });
            },
          },
        );
      }
    },
    [
      floorPlan?.UUID,
      companyName,
      network.UUID,
      network.slug,
      createFloorPlan,
      updateFloorPlan,
      queryClient,
      closeDrawer,
      navigate,
    ],
  );

  return (
    <Drawer>
      <DrawerHeader
        icon={floorPlan ? 'pencil' : 'plus'}
        heading={`${floorPlan ? 'Edit' : 'Add'} floor plan`}
        actions={
          floorPlan && (
            <DropdownMenu>
              <DropdownMenuButton arrangement="hidden-label" icon="overflow-horizontal">
                Actions
              </DropdownMenuButton>
              <DropdownMenuPopover align="end">
                <DropdownMenuGroup>
                  <DropdownMenuItem icon="trash-can" onSelect={deleteDialogState.open}>
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuPopover>
            </DropdownMenu>
          )
        }
        onClose={closeDrawer}
      />
      <Formik<FloorPlanInputValues>
        initialValues={{
          label: floorPlan?.label ?? '',
          description: floorPlan?.description ?? '',
          imageS3Key: floorPlan?.imageS3Key ?? '',
        }}
        validate={withZodSchema(floorPlanInputSchema)}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerContent gutter="all">
            <TextField name="label" label="Label" />
            <TextareaField name="description" label="Description" optional />

            <PrimaryFieldComposite
              label="Image"
              fields={
                <FileUploadFieldProvider name="imageS3Key">
                  <CompositeField
                    label="Image"
                    element={
                      <UnifiedFileUploader
                        compact
                        folder={AllowedFolder.FloorPlanImages}
                        allowedTypes={{
                          images: true,
                        }}
                        maxFiles={1}
                      />
                    }
                  />
                </FileUploadFieldProvider>
              }
            />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" variant="secondary" size="medium" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary" size="medium">
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
      {floorPlan && hasPermission(PermissionType.PermCompanyUpdate) && (
        <DeleteDialog
          state={deleteDialogState}
          label={floorPlan.label}
          handleDelete={handleDelete}
          content={
            deleteFloorPlanError && (
              <Alert
                icon="attention"
                heading="There was a problem deleting the floor plan"
                copy={deleteFloorPlanError.message}
                variant="negative"
              />
            )
          }
        />
      )}
    </Drawer>
  );
}

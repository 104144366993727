import { Section, SectionContent, SectionHeader, Sections, space } from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { GraphQLErrorBoundary, useGraphQL } from '@meterup/graphql';
import * as d3 from 'd3';
import { Suspense, useMemo } from 'react';

import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import {
  calculateXTickValues,
  ChartsFallbackContainer,
  controllerDNSRequestRatesToSeries,
  controllerPortMetricsToSeries,
  dataRateBytesValueFormatter,
  getDurationSeconds,
  getStep,
  getStepForDuration,
  packetRateValueFormatter,
  requestRateValueFormatter,
  tickLabelProps,
} from '../../../utils/chart_utils';
import TimeSeriesChart from '../../Charts/TimeSeriesChart';
import { UptimeChart } from '../../Charts/UptimeChart';
import { getPhyInterfaceLabel, uplinkPhyInterfacesQuery } from '../../Firewall/utils';
import { StackedSkeletons } from '../../Placeholders/AppLoadingFallback';
import {
  controllerMetricsQuery,
  dhcpRulesForNetworkQuery,
  uptimeStatsForNetworkQuery,
} from './utils2';

function ControllerMetricGraphs({
  virtualDeviceUUID,
  duration,
  step,
  currentTimePeriod,
  uptimeChartStep,
}: {
  virtualDeviceUUID: string;
  duration: number;
  uptimeChartStep: number;
  step: number;
  currentTimePeriod: string;
}) {
  const networkUUID = useNetwork().UUID;
  const ispAvailabilityEnabled = useNosFeatureEnabled(NosFeature.ISP_AVAILABILITY);
  const { data: metricsData } = useGraphQL(controllerMetricsQuery, {
    virtualDeviceUUID,
    filter: {
      durationSeconds: duration,
      stepSeconds: step,
    },
  });
  expectDefinedOrThrow(metricsData, new ResourceNotFoundError('Unable to load metrics'));

  const { data: dhcpData } = useGraphQL(dhcpRulesForNetworkQuery, {
    networkUUID,
  });
  expectDefinedOrThrow(dhcpData, new ResourceNotFoundError('Unable to load metrics'));

  const series = useMemo(
    () => controllerPortMetricsToSeries(metricsData?.controllerPortMetricsRate?.values),
    [metricsData?.controllerPortMetricsRate?.values],
  );

  const dnsRequestSeries = useMemo(
    () =>
      controllerDNSRequestRatesToSeries(
        metricsData?.controllerDNSRequestRates?.values,
        dhcpData?.dhcpRulesForNetwork,
      ),
    [dhcpData.dhcpRulesForNetwork, metricsData?.controllerDNSRequestRates?.values],
  );

  const uptimeStats = useGraphQL(uptimeStatsForNetworkQuery, {
    networkUUID,
    lookBack: duration,
    stepSeconds: uptimeChartStep,
  }).data?.networkUplinkQuality.values;

  const wanStatsByPhyInterface = useMemo(() => {
    const map = new Map<
      string,
      {
        timestamp: Date;
        value: number;
      }[]
    >();
    for (const item of uptimeStats ?? []) {
      let arr = map.get(item.phyInterfaceUUID);
      if (!arr) {
        arr = [];
        map.set(item.phyInterfaceUUID, arr);
      }

      arr.push({
        ...item,
        timestamp: new Date(item.timestamp),
      });
    }

    return map;
  }, [uptimeStats]);

  const phyInterfaces = useGraphQL(uplinkPhyInterfacesQuery, {
    networkUUID,
  })?.data?.uplinkPhyInterfacesForNetwork.filter(
    (phy) =>
      phy.isEnabled &&
      phy.virtualDevice.hardwareDevice?.isActive &&
      phy.virtualDevice.UUID === virtualDeviceUUID &&
      phy.virtualDevice.hardwareDevice.isConnectedToBackend,
  );

  const xTickValues = useMemo(() => calculateXTickValues(duration, 3), [duration]);
  return (
    <Sections>
      {wanStatsByPhyInterface.size > 0 && !!phyInterfaces?.length && ispAvailabilityEnabled ? (
        <Section relation="stacked">
          <SectionHeader heading="ISP availability" />
          <SectionContent gutter="all" spacing={space(12)}>
            {phyInterfaces
              .filter((pi) => wanStatsByPhyInterface.has(pi.UUID))
              .map((pi) => (
                <UptimeChart
                  key={pi.UUID}
                  label={getPhyInterfaceLabel(pi)}
                  average
                  data={wanStatsByPhyInterface.get(pi.UUID)!}
                />
              ))}
          </SectionContent>
        </Section>
      ) : null}
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.totalRxBytes}
        seriesType="area"
        seriesLabel="security-appliance-rx-rate"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="RX rate"
        tooltipBody="The rate of data received (rx) through a port."
        valueFormatter={dataRateBytesValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.totalTxBytes}
        seriesType="area"
        seriesLabel="security-appliance-tx-rate"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="TX rate"
        tooltipBody="The rate of data sent (tx) through a port."
        valueFormatter={dataRateBytesValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.drops}
        seriesType="area"
        seriesLabel="security-appliance-drop-rate"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Drop rate"
        tooltipBody="The number of packets per second (pps) dropped on this port."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.rxErr}
        seriesType="area"
        seriesLabel="security-appliance-rx-error-rate"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="RX error rate"
        tooltipBody="The number of packets per second (pps) that encountered errors being received."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.txErr}
        seriesType="area"
        seriesLabel="security-appliance-tx-error-rate"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="TX error rate"
        tooltipBody="The number of packets per second (pps) that encountered errors being sent."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
      />
      <IsOperator>
        <TimeSeriesChart
          internal
          curve={d3.curveLinear}
          series={dnsRequestSeries}
          seriesType="area"
          seriesLabel="security-appliance-dns-request-rate"
          showSeriesGlyphs={false}
          timePeriod={currentTimePeriod}
          title="DNS request rate"
          tooltipBody="The rate of requests per second (rps) received per VLAN."
          valueFormatter={requestRateValueFormatter}
          tickLabelProps={tickLabelProps}
          xTickValues={xTickValues}
        />
      </IsOperator>
    </Sections>
  );
}

function SecurityApplianceInsights({ virtualDeviceUUID }: { virtualDeviceUUID: string }) {
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const duration = useMemo(() => getDurationSeconds(currentTimePeriod), [currentTimePeriod]);
  const step = useMemo(() => getStep(currentTimePeriod), [currentTimePeriod]);
  const uptimeChartStep = useMemo(() => getStepForDuration(duration), [duration]);
  return (
    <>
      {/* TODO: Add a better error boundary */}
      <GraphQLErrorBoundary fallback={() => null}>
        <Suspense
          fallback={
            <ChartsFallbackContainer>
              <StackedSkeletons />
            </ChartsFallbackContainer>
          }
        >
          <ControllerMetricGraphs
            virtualDeviceUUID={virtualDeviceUUID}
            duration={duration}
            uptimeChartStep={uptimeChartStep}
            step={step}
            currentTimePeriod={currentTimePeriod}
          />
        </Suspense>
      </GraphQLErrorBoundary>
    </>
  );
}

export default SecurityApplianceInsights;

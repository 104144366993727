import type { Row, SortingState } from '@tanstack/react-table';
import { Badge, EmptyState, styled, Text } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable, rankings, rankItem } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { NosVersionsQueryQuery } from '../../gql/graphql';
import type { NosVersionsQueryResult } from './utils';
import { paths } from '../../constants';
import { graphql } from '../../gql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { Nav } from '../../nav';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { NoValue } from '../NoValue';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { nosVersionsQuery } from './utils';

const networksForNosVersionQuery = graphql(`
  query NetwoorksForNosVersionQuery {
    networks(filter: { active: { eq: true } }) {
      pinnedNOSVersionID
    }
  }
`);

// Based on https://tanstack.com/table/v8/docs/api/features/filters#filter-meta
function globalFilterFn<D extends any>(
  row: Row<D>,
  columnId: string,
  filterValue: string,
  addMeta: (meta: any) => void,
) {
  const itemRank = rankItem(row.getValue(columnId), filterValue, { threshold: rankings.CONTAINS });

  addMeta(itemRank);

  return itemRank.passed;
}

const builder = createColumnBuilder<NosVersionsQueryResult>();

const BuildContainer = styled('span', {
  fontSize: '$11',
});

function BuildCell({ value }: { value: string | undefined | null }) {
  if (value) {
    return (
      <Text family="monospace">
        <BuildContainer>{value}</BuildContainer>
      </Text>
    );
  }
  return <NoValue />;
}

function useColumns() {
  const networks = useGraphQL(networksForNosVersionQuery).data?.networks ?? [];
  const nosVersionCountMap: Record<number, number> = {};
  for (const network of networks) {
    const versionID = network.pinnedNOSVersionID;
    if (versionID) {
      if (!nosVersionCountMap[versionID]) {
        nosVersionCountMap[versionID] = 0;
      }
      nosVersionCountMap[versionID] += 1;
    }
  }

  return [
    builder.data((device) => device.version, {
      id: 'name',
      header: 'Name',
      meta: {
        isLeading: true,
      },
      cell: ({ value }) => {
        if (value) {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <>{value}</>;
        }

        return <NoValue />;
      },
    }),
    builder.data((nos) => (nos.isDefault ? 'Default' : ''), {
      id: 'default',
      header: 'Default',
      cell: ({ value }) => {
        if (value) {
          return (
            <Badge size="small" icon="checkmark" variant="positive" arrangement="hidden-label">
              Default
            </Badge>
          );
        }

        return <NoValue />;
      },
    }),
    builder.data((nos) => `${nos.major}.${nos.minor}.${nos.patch}`, {
      id: 'version',
      header: 'Version',
      cell: ({ value }) => <Text family="monospace">{value}</Text>,
    }),
    builder.data((nos) => (nosVersionCountMap[nos.id] ?? 0).toString(), {
      id: 'networks',
      header: 'Networks',
    }),
    builder.data((nos) => nos.mc01Build, {
      id: 'cos',
      header: 'COS',
      cell: BuildCell,
    }),
    builder.data((nos) => nos.mw07Build, {
      id: 'wos',
      header: 'WOS',
      cell: BuildCell,
    }),
    builder.data((nos) => nos.ms10Build, {
      id: 'sos',
      header: 'SOS',
      cell: BuildCell,
    }),
    builder.data((nos) => nos.mp01Build, {
      id: 'pos',
      header: 'POS',
      cell: BuildCell,
    }),
  ];
}

function NOSVersionsList({
  globalFilter,
  containerRef,
}: {
  globalFilter: string | undefined;
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const columns = useColumns();
  const nosData = useGraphQL(nosVersionsQuery).data as NosVersionsQueryQuery;
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.NOSVersionDetailPage);

  const n = useMemo(() => nosData.nosVersions.filter((nos) => !nos.isDeprecated), [nosData]);
  const nosversions = n.sort((a, b) => {
    // Convert the timestamps to Date objects
    const dateA = new Date(a.updatedAt);
    const dateB = new Date(b.updatedAt);

    // Compare the dates and return either -1, 0, or 1
    // depending on whether dateA is before, the same as,
    // or after dateB
    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    return 0;
  });

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort', [
    {
      id: 'default',
      desc: true,
    },
    {
      id: 'version',
      desc: true,
    },
  ]);
  const closeDrawer = useCloseDrawerCallback();

  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) {
    return <EmptyState icon="upgrading" heading="No firmware versions found" />;
  }

  return (
    <AutoTable
      isVirtual
      tableContainerRef={containerRef}
      columns={columns}
      data={nosversions}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      globalFilterFn={globalFilterFn}
      onRowDeselect={closeDrawer}
      isRowSelected={(row) => row.id.toString() === drawerParams?.nosVersionID}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.NOSVersionDetailPage, {
          nosVersionID: row.id.toString(),
        })
      }
    />
  );
}

export default NOSVersionsList;

import { css, darkThemeSelector, Drawer as DrawerMetric, styled } from '@meterup/atto';
import { colors } from '@meterup/atto/src/stitches.config';

export const Drawer = styled(DrawerMetric, {
  width: '500px',
  overflowY: 'auto',
});

export const FooterWrapper = css({
  width: '100%',
  flex: 0,
  overflow: 'hidden',
  maxHeight: 0,
  backgroundColor: '$gray-50',
  boxShadow: '$fenceLeftLight, $fenceRightLight, $fenceBottomLight',
  borderRadius: '0 0 $8 $8',
  transition: 'all 0.10s linear 0.10s',

  [darkThemeSelector]: {
    backgroundColor: '$gray-800',
    boxShadow: '$fenceLeftDark, $fenceRightDark, $fenceBottomDark',
  },

  variants: {
    state: {
      entering: {
        maxHeight: '120px',
      },
      entered: {
        maxHeight: '120px',
      },
      exiting: {
        maxHeight: 0,
        padding: 0,
      },
      exited: {
        maxHeight: 0,
        padding: 0,
      },
      unmounted: {},
    },
  },
});
export const EditorMessage = styled('div', FooterWrapper, {
  boxShadow: '$fenceAllLight',
  [darkThemeSelector]: {
    boxShadow: '$fenceAllDark',
  },
  position: 'relative',
  padding: '$4 $8 $4 $16',
  '&:before': {
    position: 'absolute',
    content: '" "',
    display: 'block',
    width: '$4',
    left: '5px',
    top: '$4',
    bottom: '$4',
    borderRadius: '$4',
  },
  variants: {
    type: {
      error: {
        backgroundColor: '$red-50',
        color: '$red-700',
        [darkThemeSelector]: {
          backgroundColor: '$red-800',
          color: '$red-50',
        },
        '&:before': {
          backgroundColor: '$red-600',
          [darkThemeSelector]: {
            backgroundColor: '$red-100',
          },
        },
      },
      success: {
        backgroundColor: '$green-50',
        color: '$green-700',
        [darkThemeSelector]: {
          backgroundColor: '$green-800',
          color: '$green-50',
        },
        '&:before': {
          backgroundColor: '$green-600',
          [darkThemeSelector]: {
            backgroundColor: '$green-100',
          },
        },
      },
    },
  },
});

export const ModalContent = styled('div', {
  backgroundColor: colors.bgApplicationLight,
  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
  },
  // $shadows-dialogLightAll
  boxShadow:
    '0px 0px 0px 1px rgba(9, 11, 20, 0.08), 0px 3px 0px rgba(9, 11, 20, 0.04), 0px 7px 8px rgba(9, 11, 20, 0.05), 0px 9px 11px rgba(9, 11, 20, 0.05)',
  borderRadius: '$8',

  [`&:has(+ ${EditorMessage})`]: {
    borderRadius: '$8 $8 0 0',
  },

  [`& + ${EditorMessage}`]: {
    boxShadow:
      '0px 0px 0px 1px rgba(9, 11, 20, 0.08), 0px 3px 0px rgba(9, 11, 20, 0.04), 0px 7px 8px rgba(9, 11, 20, 0.05), 0px 9px 11px rgba(9, 11, 20, 0.05)',
  },
});
export const ModalWrapper = styled('div', {
  '@sm': {
    maxWidth: 360,
  },
});

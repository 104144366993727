import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import ReportsAdd from '../../../../../../components/Settings/Network/Notifications/Reports/ReportsAdd';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/network/:networkSlug/notifications/reports/add',
  title: 'Add report - Reports - Notifications - Network',
});

export default function ReportsAddPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  return <ReportsAdd />;
}

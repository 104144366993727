import type { api } from '@meterup/proto';
import { Badge, colors, DeviceTarget, Text } from '@meterup/atto';
import { getManufacturerIconName, isDefined, lookupMACAddressOUI, styled } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import { paths } from '../constants';
import { useNetwork } from '../hooks/useNetworkFromPath';
import { Nav } from '../nav';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { getAPNameForDevice, isOnline } from '../utils/access_point_utils';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { NoValue } from './NoValue';
import { ReactRouterLink } from './ReactRouterLink';
import { SSIDsQuery } from './Wireless/SSIDs/SSIDsUtils';
import { ValidAPDetailsTabs } from './Wireless/utils';

export function APWOS1DeviceTarget({
  device,
}: {
  device: api.AccessPointDeviceResponse | null | undefined;
}) {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();

  if (isDefined(device)) {
    return (
      <DeviceTarget
        as={ReactRouterLink}
        to={Nav.makeTo({
          root: {
            pathname: makeLink(paths.pages.LegacyAccessPointListPage, {
              controllerName,
              companyName,
            }),
          },
          drawer: makeLink(paths.drawers.AccessPointSummary, {
            deviceName: device?.name,
            controllerName,
            companyName,
          }),
        })}
        replace={false}
        type="access-point"
        aria-label={`Go to ${getAPNameForDevice(device)}`}
      >
        {getAPNameForDevice(device)}
        <Badge
          icon="access-point"
          arrangement="leading-icon"
          variant={isOnline(device) ? 'positive' : 'neutral'}
          size="small"
          ends="pill"
        >
          {isOnline(device) ? 'Online' : 'Offline'}
        </Badge>
      </DeviceTarget>
    );
  }

  return <NoValue />;
}

export function APWOS2DeviceTarget({
  uuid,
  label,
  rootParams,
  rootPath = paths.pages.AccessPointPage,
  wrap = false,
}: {
  uuid: string | null | undefined;
  label: string;
  rootParams?: Record<string, string>;
  rootPath?: string;
  wrap?: boolean;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  if (isDefined(uuid) && isDefined(network)) {
    return (
      <DeviceTarget
        as={Link}
        to={makeLink(rootPath ?? paths.pages.AccessPointPage, {
          ...(rootParams || {
            companyName,
            networkSlug: network.slug,
            uuid,
            tab: ValidAPDetailsTabs.Insights,
          }),
        })}
        replace={false}
        type="access-point"
        aria-label={`Go to ${label}`}
        wrap={wrap}
      >
        {label}
      </DeviceTarget>
    );
  }

  return <NoValue />;
}

const ForeignSSIDName = styled(Text, {
  color: colors.gray300,
});

export function SSIDDeviceTarget({
  rootParams,
  rootPath = paths.pages.SSIDsPage,
  ssidName,
  wrap,
  plainTextForeignSSIDs,
}: {
  rootParams?: Record<string, string>;
  rootPath?: string;
  ssidName: string;
  wrap?: boolean;
  plainTextForeignSSIDs?: boolean;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork ?? [];
  const ssid = ssids.find((s) => s.ssid === ssidName);

  if (!ssid) {
    if (ssidName && plainTextForeignSSIDs) {
      return <ForeignSSIDName>{ssidName}</ForeignSSIDName>;
    }
    return <NoValue />;
  }

  return (
    <DeviceTarget
      as={ReactRouterLink}
      to={Nav.makeTo({
        root: {
          pathname: makeLink(rootPath, {
            ...(rootParams || {
              companyName,
              networkSlug: network.slug,
            }),
          }),
        },
        drawer: makeLink(paths.drawers.SSIDEditPage, {
          uuid: ssid.UUID,
          networkSlug: network.slug,
          companyName,
        }),
      })}
      replace={false}
      aria-label={`Go to ${ssid.ssid}`}
      type="ssid"
      wrap={wrap}
    >
      {ssid.ssid}
    </DeviceTarget>
  );
}

export function ClientDeviceTarget({
  macAddress,
  clientName,
  rootParams,
  rootPath,
}: {
  macAddress: string;
  clientName?: string;
  rootParams?: Record<string, string>;
  rootPath?: string;
}) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const manufacturer = lookupMACAddressOUI(macAddress) ?? undefined;
  const iconName = getManufacturerIconName(manufacturer ?? '');

  return (
    <DeviceTarget
      as={Link}
      to={makeLink(rootPath ?? paths.pages.ClientInsightsPage, {
        ...(rootParams ?? {
          companyName,
          networkSlug: network.slug,
          macAddress,
        }),
      })}
      replace={false}
      type={iconName}
      aria-label={`Go to ${clientName ?? macAddress}`}
      wrap={false}
    >
      {clientName ?? macAddress}
    </DeviceTarget>
  );
}

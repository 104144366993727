import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import AccessPoints from '../../../../../components/Hardware/AccessPoints/LegacyAccessPoints';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/hardware/access-points',
  layout: 'NetworkLayout',
});

export default function LegacyAccessPointListPage() {
  return <AccessPoints />;
}

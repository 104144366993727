import { Button, HStack, Shortcut, space, SpecialKey, Text } from '@meterup/atto';
import { useUsersOS } from '@meterup/atto/src/utilities/useUsersOS';
import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { useWorkbenchSide } from '../utils/useWorkbenchSide';

export default function ToolbarSideToggle({ onClick }: { onClick: () => void }) {
  const platform = useUsersOS();
  const sideRef = useRef<HTMLButtonElement>(null);
  useHotkeys(
    [platform === 'mac' ? 'meta + /' : 'ctrl + /'],
    () => {
      if (sideRef?.current) {
        sideRef.current.click();
      }
    },
    { preventDefault: true },
  );

  const workbenchSide = useWorkbenchSide();

  return (
    <Button
      ref={sideRef}
      tabIndex={0}
      onClick={onClick}
      side={workbenchSide}
      arrangement="hidden-label"
      icon={workbenchSide === 'bottom' ? 'pane-end' : 'pane-bottom'}
      size="x-small"
      variant="secondary"
    >
      <HStack align="center" spacing={space(6)}>
        <Text>Pin to {workbenchSide === 'bottom' ? 'side' : 'bottom'}</Text>
        <Shortcut keys={[SpecialKey.CmdOrCtrl, '/']} />
      </HStack>
    </Button>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import PowerUnitsList from '../../../../../components/Hardware/PowerDistributionUnits/PowerDistributionUnitList';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Power distribution units - Hardware',
});

export default function PowerDistributionUnitListPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
        !!nosFlags[NosFeature.POS]
      }
      should404OnAccessDenied
    >
      <PowerUnitsList />
    </IsPermitted>
  );
}

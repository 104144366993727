import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import ConnectionEvents from '../../Metrics/ConnectionEvents';
import { MetricBandFilter, MetricsDeviceType } from '../../Metrics/utils';

export default function ClientConnectionEvents({ macAddress }: { macAddress: string }) {
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const [currentBandOrUndefined] = useSearchParamsState<MetricBandFilter>(
    'band',
    MetricBandFilter.All,
  );
  const currentBand = currentBandOrUndefined ?? MetricBandFilter.All;

  const [currentSSIDOrUndefined] = useSearchParamsState<{
    UUID: string;
    ssid: string;
  }>('ssid', { UUID: 'All', ssid: 'All' });
  const currentSSID = currentSSIDOrUndefined ?? { UUID: 'All', ssid: 'All' };

  return (
    <ConnectionEvents
      identifier={macAddress}
      deviceType={MetricsDeviceType.Client}
      band={currentBand}
      ssidUUID={currentSSID.UUID}
      timePeriod={currentTimePeriod}
      globalFilter={undefined}
    />
  );
}

import { DrawerContent } from '@meterup/atto';
import {
  checkDefinedOrThrow,
  getErrorMessage,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';

import type { CompanyUserInputSchemaType } from './utils/schema';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { withZodSchema } from '../../utils/withZodSchema';
import { useSafeCloseDrawer } from '../NetworkWide/CaptivePortals/utils';
import EditUserForm from './EditUserForm';
import FormFooter from './FormFooter';
import { updateCompanyUser, useCompanyUser, useInvalidateCompanyUsers } from './utils/queries';
import { CompanyUserInputSchema } from './utils/schema';

type EditUserProps = {
  uuid: string;
};

export default function EditUser({ uuid }: EditUserProps) {
  const companySlug = useCurrentCompany();
  const data = useCompanyUser(uuid);
  const companyUser = checkDefinedOrThrow(
    data.data?.companyUser,
    new ResourceNotFoundError('User not found'),
  );
  const user = checkDefinedOrThrow(companyUser?.user, new ResourceNotFoundError('User not found'));
  const initialValues = useMemo(
    () => ({
      ...user,
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      companyMembershipRole: companyUser.companyMembershipRole,
      companySlug,
    }),
    [companySlug, companyUser.companyMembershipRole, user],
  );
  const closeDrawer = useSafeCloseDrawer();
  const updateUserMutation = useGraphQLMutation(updateCompanyUser);
  const invalidateUsers = useInvalidateCompanyUsers(companySlug);
  const handleSubmit = useCallback(
    (values: CompanyUserInputSchemaType) => {
      const { firstName, lastName, companyMembershipRole } = values;
      updateUserMutation.mutate(
        {
          uuid,
          input: {
            firstName,
            lastName,
            companyMembershipRole,
          },
        },
        {
          onSuccess() {
            invalidateUsers();
            notify('Successfully updated user', { variant: 'positive' });
            closeDrawer();
          },
          onError(err) {
            const errorMessage = getErrorMessage(err) ?? 'Failed to update user';
            notify(errorMessage, { variant: 'negative' });
          },
        },
      );
    },
    [closeDrawer, invalidateUsers, uuid, updateUserMutation],
  );

  return (
    <Formik<CompanyUserInputSchemaType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validate={withZodSchema(CompanyUserInputSchema)}
    >
      <Form>
        <DrawerContent>
          <EditUserForm emailIsEditable={false} />
        </DrawerContent>
        <FormFooter />
      </Form>
    </Formik>
  );
}

import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  PrimaryField,
  TextInput,
} from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { ValidPhyInterfaceParams } from './utils';
import {
  type UpdatePhyInterfacesMutationVariables,
  PermissionType,
  PhyInterfaceFrameAcceptTypeFilter,
} from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { styled } from '../../../stitches';
import { pluralize } from '../../../utils/strings';
import { updatePhyInterfacesMutation } from '../../Devices/utils';
import { FieldProvider } from '../../Form/FieldProvider';
import { ToggleField } from '../../Form/Fields';
import { FormikConditional } from '../../FormikConditional';
import { vlansQuery } from '../../NetworkWide/VLANs/utils';
import IsPermitted from '../../permissions/IsPermitted';
import {
  AllowedVLANField,
  ForcedPortSpeedField,
  FrameAcceptTypeField,
  NativeVLANField,
  PortTypeField,
  StormControlBroadcastField,
  StormControlUnknownMulticastField,
  StormControlUnknownUnicastField,
} from './Fields';
import { portMaxSpeed, PortsQuery, UpdatePhyInterfaceInputSchema } from './utils';

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

interface PortDrawerProps {
  phyInterfaceUUIDs: string[];
  virtualDeviceUUID: string;
}

export default function PortBatchEditDrawer({
  virtualDeviceUUID,
  phyInterfaceUUIDs,
}: PortDrawerProps) {
  const network = useNetwork();
  const portData = useGraphQL(PortsQuery, { virtualDeviceUUID }).data
    ?.phyInterfacesForVirtualDevice;
  expectDefinedOrThrow(
    portData,
    new ResourceNotFoundError(`PhyInterfaces not found for device ${virtualDeviceUUID}`),
  );
  const ports = portData.filter((p) => phyInterfaceUUIDs.includes(p.UUID));
  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;
  expectDefinedOrThrow(vlans, new ResourceNotFoundError(`VLANs not found for network`));

  const mutation = useGraphQLMutation(updatePhyInterfacesMutation);
  const queryClient = useQueryClient();

  const hasSFPPorts = ports.some((p) => p.isSFP);

  const handleSubmit = (v: ValidPhyInterfaceParams) => {
    const {
      description,
      isTrunkPort,
      isBoundToAllVLANs,
      frameAcceptTypeFilter,
      allowedVLANUUIDs,
      ...rest
    } = v;

    const params: UpdatePhyInterfacesMutationVariables = {
      virtualDeviceUUID,
      phyInterfaceUUIDs,
      input: {
        ...rest,
        description: description || null, // don't send empty string
      },
    };

    if (frameAcceptTypeFilter === 'UNTAGGED_ONLY') {
      params.input.frameAcceptTypeFilter = PhyInterfaceFrameAcceptTypeFilter.UntaggedOnly;
    } else if (frameAcceptTypeFilter === 'TAGGED_ONLY') {
      params.input.frameAcceptTypeFilter = PhyInterfaceFrameAcceptTypeFilter.TaggedOnly;
    } else if (frameAcceptTypeFilter === 'ALL') {
      params.input.frameAcceptTypeFilter = null;
    }

    if (isTrunkPort != null) {
      params.input.isTrunkPort = isTrunkPort;
      if (isTrunkPort) {
        if (isBoundToAllVLANs) {
          params.input.allowedVLANUUIDs = [];
          params.input.isBoundToAllVLANs = true;
        } else {
          params.input.allowedVLANUUIDs = allowedVLANUUIDs;
          params.input.isBoundToAllVLANs = false;
        }
      } else {
        params.input.isBoundToAllVLANs = false;
        params.input.allowedVLANUUIDs = [];
      }
    }

    mutation.mutate(params, {
      onSuccess: () => {
        notify(`Successfully updated ${ports.length} ${pluralize(ports.length, 'port', 'ports')}`, {
          variant: 'positive',
        });
        queryClient.invalidateQueries(makeQueryKey(PortsQuery, { virtualDeviceUUID }));
      },
      onError: (err) => {
        const gqlErr = getGraphQLError(err);
        notify(
          `There was an error updating ${pluralize(ports.length, 'this port', 'these ports')}: ${
            gqlErr?.message
          }`,
          {
            variant: 'negative',
          },
        );
      },
    });
  };

  return (
    <Formik<ValidPhyInterfaceParams>
      validationSchema={toFormikValidationSchema(UpdatePhyInterfaceInputSchema)}
      initialValues={{
        isEnabled: undefined,
        isPOEEnabled: undefined,
        forcedPortSpeedMbps: undefined,
        nativeVLANUUID: undefined,
        allowedVLANUUIDs: undefined,
        isBoundToAllVLANs: undefined,
        isIngressFilteringEnabled: undefined,
        frameAcceptTypeFilter: undefined,
        isStormControlEnabled: undefined,
        stormControlBroadcastTrafficPercent: undefined,
        stormControlUnknownMulticastTrafficPercent: undefined,
        stormControlUnknownUnicastTrafficPercent: undefined,
      }}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader
            icon="ethernet-up"
            heading={`Batch edit ${ports.length} ${pluralize(ports.length, 'port', 'ports')}`}
            onClose={useCloseDrawerCallback()}
          />
          <DrawerContent>
            <Alert
              variant="neutral"
              icon="attention"
              copy="Changing a field below will overwrite all existing values for that field on the selected ports."
            />
            <ToggleField name="isEnabled" label="Enable" supportsIndeterminate />
            <FieldProvider name="label">
              <PrimaryField label="Name" element={<TextInput />} />
            </FieldProvider>
            {!hasSFPPorts && <ToggleField name="isPOEEnabled" label="PoE+" supportsIndeterminate />}
            {!hasSFPPorts && (
              <ForcedPortSpeedField
                maxSpeed={portMaxSpeed({ isSFP: false })}
                supportsIndeterminate
              />
            )}
            <PortTypeField />
            <NativeVLANField vlans={vlans} supportsIndeterminate />

            <FormikConditional<ValidPhyInterfaceParams>
              condition={(values) => !!values.isTrunkPort}
            >
              <AllowedVLANField vlans={vlans} supportsIndeterminate />
            </FormikConditional>

            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
                    permissions.hasPermission(PermissionType.PermPhyInterfaceWrite) &&
                    nosFlags[NosFeature.SOS],
                )
              }
            >
              <ToggleField
                name="isIngressFilteringEnabled"
                label="Ingress Filtering"
                internal
                supportsIndeterminate
              />
              <FrameAcceptTypeField supportsIndeterminate />

              <FieldContainer>
                <ToggleField
                  name="isStormControlEnabled"
                  label="Enable storm control"
                  internal
                  supportsIndeterminate
                />

                <FormikConditional<ValidPhyInterfaceParams>
                  condition={(v) => v.isStormControlEnabled === true}
                >
                  <StormControlBroadcastField internal supportsIndeterminate />
                  <StormControlUnknownMulticastField internal supportsIndeterminate />
                  <StormControlUnknownUnicastField internal supportsIndeterminate />
                </FormikConditional>
              </FieldContainer>
            </IsPermitted>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </Drawer>
      </StyledForm>
    </Formik>
  );
}

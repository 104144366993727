import type { SectionPropRelation } from '@meterup/atto';
import type { Address4Range } from '@meterup/common';
import type { MeterV1NetworkVLAN } from '@meterup/config';
import {
  Body,
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  SectionTarget,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import {
  isDefined,
  isOffline,
  isOnline,
  NeutralBadge,
  OnlineOfflineClientCountBadge,
  TaggedOrUntaggedBadge,
} from '@meterup/common';

import { paths } from '../constants';
import { useVLANClients } from '../hooks/useVLANClients';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import {
  useCurrentController,
  useCurrentControllerData,
} from '../providers/CurrentControllerProvider';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { NoValue } from './NoValue';
import { StickyObjectHeader } from './Object/ObjectHeader';
import { ReactRouterLink } from './ReactRouterLink';

function IPRange({ range }: { range: Address4Range }) {
  const serializedRange = `${range.start.correctForm()} - ${range.end.correctForm()}`;

  return (
    <CopyBox
      aria-label="Copy IP range to clipboard"
      relation="stacked"
      size="small"
      value={serializedRange}
    >
      <Body family="monospace">{serializedRange}</Body>
    </CopyBox>
  );
}

export function VLANMetadataListWidget({ vlan }: { vlan: MeterV1NetworkVLAN }) {
  return (
    <SummaryList gutter="none">
      <SummaryListRow>
        <SummaryListKey>Tagging</SummaryListKey>
        <SummaryListValue>
          <TaggedOrUntaggedBadge isUntagged={vlan.untagged} />
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>ID</SummaryListKey>
        <SummaryListValue>{vlan.vlanId}</SummaryListValue>
      </SummaryListRow>
    </SummaryList>
  );
}

export function VLANTaggingWidget({
  vlan,
  shouldLinkToDetails = false,
}: {
  vlan: MeterV1NetworkVLAN;
  shouldLinkToDetails?: boolean;
}) {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  return (
    <>
      <StickyObjectHeader
        icon="vlan"
        name={vlan.name}
        link={
          shouldLinkToDetails &&
          makeLink(paths.pages.VLANDetailPage, {
            vlanName: vlan.name,
            controllerName,
            companyName,
          })
        }
        cta="View VLAN"
      />
      <Section relation="stacked">
        <SectionHeader icon="information" heading="Metadata" />
        <SectionContent gutter="all">
          <VLANMetadataListWidget vlan={vlan} />
        </SectionContent>
      </Section>
    </>
  );
}

export function VLANDHCPWidget({
  vlan,
  relation = 'standalone',
}: {
  vlan: MeterV1NetworkVLAN;
  relation?: SectionPropRelation;
}) {
  return (
    <Section relation={relation}>
      <SectionHeader icon="dhcp" heading="DHCP" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>Subnet</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy subnet CIDR address to clipboard"
                relation="stacked"
                size="small"
                value={vlan.gatewayAddress}
              >
                <Body family="monospace">{vlan.subnet?.address}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Mask</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy netmask to clipboard"
                relation="stacked"
                size="small"
                value={vlan.json.dhcp?.netmask ?? ''}
              >
                <Body family="monospace">{vlan.json.dhcp?.netmask}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Gateway</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy gateway IP address to clipboard"
                relation="stacked"
                size="small"
                value={vlan.gatewayAddress}
              >
                <Body family="monospace">{vlan.gatewayAddress}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Usable range</SummaryListKey>
            <SummaryListValue>
              {isDefined(vlan.usableRange) ? <IPRange range={vlan.usableRange} /> : <NoValue />}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>DHCP range</SummaryListKey>
            <SummaryListValue>
              {isDefined(vlan.dhcpRange) ? <IPRange range={vlan.dhcpRange} /> : <NoValue />}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Static IPs</SummaryListKey>
            <SummaryListValue>
              <NeutralBadge>{vlan.staticClients.length}</NeutralBadge>
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function VLANClientsWidget({
  vlan,
  relation = 'standalone',
}: {
  vlan: MeterV1NetworkVLAN;
  relation?: SectionPropRelation;
}) {
  const controller = useCurrentControllerData();
  const companyName = useCurrentCompany();
  const clients = useVLANClients(controller, vlan.name);
  return (
    <Section relation={relation}>
      <SectionHeader icon="client" heading="Clients" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>All</SummaryListKey>
            <SummaryListValue>
              <OnlineOfflineClientCountBadge value={clients.length} />
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Online</SummaryListKey>
            <SummaryListValue>
              <OnlineOfflineClientCountBadge value={clients.filter(isOnline).length} />
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Offline</SummaryListKey>
            <SummaryListValue>
              <NeutralBadge arrangement="leading-icon" icon="client">
                {clients.filter(isOffline).length}
              </NeutralBadge>
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
      <SectionTarget
        tabIndex={0}
        as={ReactRouterLink}
        to={makeLink(paths.pages.VLANClientListPage, {
          vlanName: vlan.name,
          controllerName: controller.name,
          companyName,
        })}
        replace={false}
      >
        View clients
      </SectionTarget>
    </Section>
  );
}

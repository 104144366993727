import { Section, SectionContent } from '@meterup/atto';

import { getWiFiQRCodeConnectionString } from '../utils/getWiFiQRCodeConnectionString';
import { QRCodeListItem } from './Drawers/AddClientDrawer/QRCodeListItem';

export function NetworkJoinWidget({ ssid, password }: { ssid: string; password: string }) {
  return (
    <Section relation="standalone">
      <SectionContent>
        <QRCodeListItem text={getWiFiQRCodeConnectionString(ssid, password)} />
      </SectionContent>
    </Section>
  );
}

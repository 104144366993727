import { Badge, Button, Legends, styled } from '@meterup/atto';
import { useEffect, useState } from 'react';

import { timeAgo } from '../../../../../utils/formatTime';

export function SwitchSpeedLegend() {
  return (
    <Legends
      legends={[
        { variant: 'neutral', label: 'Disconnected' },
        { variant: 'attention', label: '100 Mbps' },
        { variant: 'positive', label: '1 Gbps' },
        { variant: 'alternative', label: '10 Gbps' },
        { error: true, label: 'Error' },
        { disabled: true, label: 'Disabled' },
      ]}
    />
  );
}

export function SwitchPropertiesLegend() {
  return (
    <Legends
      legends={[
        { icon: 'arrow-up', label: 'Uplink' },
        { icon: 'block', label: 'Blocking' },
        { icon: 'lightning', label: 'PoE+' },
      ]}
    />
  );
}

const Controls = styled('div', {
  hStack: '$8',
  justifyContent: 'space-between',
  width: '100%',
});

const ControlsEdge = styled('div', {
  hStack: '$8',
});
const ControlsStart = styled(ControlsEdge);
const ControlsEnd = styled(ControlsEdge);

export function SwitchControls({
  refetch,
  refreshDisabled,
  updatedAt,
}: {
  refetch: () => void;
  refreshDisabled: boolean;
  updatedAt: number;
}) {
  const [updatedString, setUpdatedString] = useState(timeAgo.format(updatedAt, 'round'));

  useEffect(() => {
    const handle = setInterval(() => setUpdatedString(timeAgo.format(updatedAt, 'round')), 1000);
    return () => {
      clearInterval(handle);
    };
  }, [updatedAt]);

  return (
    <Controls>
      <ControlsStart>
        <Button size="small" variant="secondary" onClick={refetch} disabled={refreshDisabled}>
          Refresh
        </Button>
        <Badge size="small" variant="neutral">
          Updated {updatedString}
        </Badge>
      </ControlsStart>
      <ControlsEnd />
    </Controls>
  );
}

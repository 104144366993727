// Shared chart utilities and components

import type { TickLabelProps } from '@visx/axis';
import { HStack, Icon, space } from '@meterup/atto';
import {
  bytesPerSecond,
  colors,
  darkThemeSelector,
  fonts,
  fontWeights,
  formatBytes,
  formatDataRateBits,
  kilobitsPerSecond,
  shadows,
  styled,
} from '@meterup/common';
import { buildChartTheme, DataContext } from '@visx/xychart';
import * as d3 from 'd3';
import { DateTime, Duration } from 'luxon';
import React, { useContext } from 'react';
import { useId } from 'react-aria';

import type {
  AccessPointsQueryResult,
  APChannelUtilizationByAccessPointQueryResult,
  APChannelUtilizationByNetworkQueryResult,
} from '../components/Wireless/utils';
import type {
  ApChannelUtilizationMetricsValue,
  ApUptimeMetricsValue,
  ClientMetricsTimeseriesValue,
  ClientMetricsValue,
  ControllerDnsRequestRatesValue,
  ControllerPortMetricsRateValue,
  DhcpRulesForNetworkQueryQuery,
  SwitchPortMetricsRateValue,
  SwitchPortMetricsValue,
} from '../gql/graphql';
import { RadioBand } from '../gql/graphql';
import { ThemeContext } from '../providers/ThemeProvider';

/** Types */
export type DataPoint = {
  series_id: string; // Series the data point belongs to
  timestamp: Date;
  value: number | null;
};
export type TimeSeries = {
  series_id: string;
  series_name: string;
  min_value?: number;
  max_value?: number;
  data: DataPoint[];
};
type DataByGroupBy = Record<string, DataPoint[]>;

/** Data parsing functions */
const dataByGroupByToSeries = (dataByGroupBy: DataByGroupBy) =>
  Object.entries(dataByGroupBy).map(([groupByValue, data]) => ({
    data,
    series_id: groupByValue,
    series_name: groupByValue,
  }));

const dataByPortToSeries = (dataByGroupBy: DataByGroupBy) =>
  Object.entries(dataByGroupBy).map(([groupByValue, data]) => ({
    data,
    series_id: `Port ${groupByValue}`,
    series_name: `Port ${groupByValue}`,
  }));

function truncateValue(value: number) {
  return Number(value.toFixed(1));
}

export function controllerDNSRequestRatesToSeries(
  metricsValues: ControllerDnsRequestRatesValue[],
  dhcpRules: DhcpRulesForNetworkQueryQuery['dhcpRulesForNetwork'],
) {
  // create map using list of dhcp rules for networks and set that as the key
  const dhcpUuidToVlanMap: Record<string, string> = {};
  dhcpRules.forEach((rule) => {
    dhcpUuidToVlanMap[rule.UUID] = rule.vlan.name;
  });

  const dataByGroupBy: DataByGroupBy = {};

  metricsValues.forEach((metricValue) => {
    const key = dhcpUuidToVlanMap[metricValue.uuid] ?? metricValue.uuid;
    const dataPoint = {
      series_id: key,
      timestamp: new Date(metricValue.timestamp),
      value: truncateValue(metricValue.value),
    };
    if (!dataByGroupBy[key]) {
      dataByGroupBy[key] = [dataPoint];
    } else {
      dataByGroupBy[key].push(dataPoint);
    }
  });
  return dataByGroupByToSeries(dataByGroupBy);
}

export function controllerPortMetricsToSeries(metricsValues: ControllerPortMetricsRateValue[]) {
  const dropsDataByGroupBy: DataByGroupBy = {};
  const txErrDataByGroupBy: DataByGroupBy = {};
  const rxErrDataByGroupBy: DataByGroupBy = {};
  const totalRxBytesDataByGroupBy: DataByGroupBy = {};
  const totalTxBytesDataByGroupBy: DataByGroupBy = {};

  metricsValues.forEach((metricValue) => {
    const key = metricValue.portNumber.toString();
    const dataPoint = {
      series_id: `Port ${key}`,
      timestamp: new Date(metricValue.timestamp),
    };
    const dropsDataPoint = {
      ...dataPoint,
      value: truncateValue(metricValue.dropsPerSecond),
    };

    const txErrDataPoint = {
      ...dataPoint,
      value: truncateValue(metricValue.txErrPerSecond),
    };
    const rxErrDataPoint = {
      ...dataPoint,
      value: truncateValue(metricValue.rxErrPerSecond),
    };
    const totalRxBytesDataPoint = {
      ...dataPoint,
      value: truncateValue(metricValue.totalRxBytesPerSecond),
    };
    const totalTxBytesDataPoint = {
      ...dataPoint,
      value: truncateValue(metricValue.totalTxBytesPerSecond),
    };

    if (!dropsDataByGroupBy[key]) {
      dropsDataByGroupBy[key] = [dropsDataPoint];
    } else {
      dropsDataByGroupBy[key].push(dropsDataPoint);
    }
    if (!txErrDataByGroupBy[key]) {
      txErrDataByGroupBy[key] = [txErrDataPoint];
    } else {
      txErrDataByGroupBy[key].push(txErrDataPoint);
    }
    if (!rxErrDataByGroupBy[key]) {
      rxErrDataByGroupBy[key] = [rxErrDataPoint];
    } else {
      rxErrDataByGroupBy[key].push(rxErrDataPoint);
    }
    if (!totalRxBytesDataByGroupBy[key]) {
      totalRxBytesDataByGroupBy[key] = [totalRxBytesDataPoint];
    } else {
      totalRxBytesDataByGroupBy[key].push(totalRxBytesDataPoint);
    }
    if (!totalTxBytesDataByGroupBy[key]) {
      totalTxBytesDataByGroupBy[key] = [totalTxBytesDataPoint];
    } else {
      totalTxBytesDataByGroupBy[key].push(totalTxBytesDataPoint);
    }
  });

  return {
    drops: dataByPortToSeries(dropsDataByGroupBy),
    txErr: dataByPortToSeries(txErrDataByGroupBy),
    rxErr: dataByPortToSeries(rxErrDataByGroupBy),
    totalRxBytes: dataByPortToSeries(totalRxBytesDataByGroupBy),
    totalTxBytes: dataByPortToSeries(totalTxBytesDataByGroupBy),
  };
}

export function switchPortMetricsToSeries(metricsValues: SwitchPortMetricsRateValue[]) {
  const txErrDataByGroupBy: DataByGroupBy = {};
  const rxErrDataByGroupBy: DataByGroupBy = {};
  const totalRxBytesDataByGroupBy: DataByGroupBy = {};
  const totalTxBytesDataByGroupBy: DataByGroupBy = {};
  const broadcastTxDataByGroupBy: DataByGroupBy = {};
  const broadcastRxDataByGroupBy: DataByGroupBy = {};
  const multicastTxDataByGroupBy: DataByGroupBy = {};
  const multicastRxDataByGroupBy: DataByGroupBy = {};

  metricsValues.forEach((metricValue) => {
    const key = metricValue.portNumber.toString();
    const dataPoint = {
      series_id: `Port ${key}`,
      timestamp: new Date(metricValue.timestamp),
    };
    const txErrDataPoint = {
      ...dataPoint,
      value: metricValue.txErrPerSecond,
    };
    const rxErrDataPoint = {
      ...dataPoint,
      value: metricValue.rxErrPerSecond,
    };
    const totalRxBytesDataPoint = {
      ...dataPoint,
      value: metricValue.totalRxBytesPerSecond,
    };
    const totalTxBytesDataPoint = {
      ...dataPoint,
      value: metricValue.totalTxBytesPerSecond,
    };
    const broadcastTxDataPoint = {
      ...dataPoint,
      value: metricValue.broadcastTxPacketsPerSecond,
    };
    const broadcastRxDataPoint = {
      ...dataPoint,
      value: metricValue.broadcastRxPacketsPerSecond,
    };
    const multicastTxDataPoint = {
      ...dataPoint,
      value: metricValue.multicastTxPacketsPerSecond,
    };
    const multicastRxDataPoint = {
      ...dataPoint,
      value: metricValue.multicastRxPacketsPerSecond,
    };

    if (!txErrDataByGroupBy[key]) {
      txErrDataByGroupBy[key] = [txErrDataPoint];
    } else {
      txErrDataByGroupBy[key].push(txErrDataPoint);
    }
    if (!rxErrDataByGroupBy[key]) {
      rxErrDataByGroupBy[key] = [rxErrDataPoint];
    } else {
      rxErrDataByGroupBy[key].push(rxErrDataPoint);
    }
    if (!totalRxBytesDataByGroupBy[key]) {
      totalRxBytesDataByGroupBy[key] = [totalRxBytesDataPoint];
    } else {
      totalRxBytesDataByGroupBy[key].push(totalRxBytesDataPoint);
    }
    if (!totalTxBytesDataByGroupBy[key]) {
      totalTxBytesDataByGroupBy[key] = [totalTxBytesDataPoint];
    } else {
      totalTxBytesDataByGroupBy[key].push(totalTxBytesDataPoint);
    }
    if (!broadcastTxDataByGroupBy[key]) {
      broadcastTxDataByGroupBy[key] = [broadcastTxDataPoint];
    } else {
      broadcastTxDataByGroupBy[key].push(broadcastTxDataPoint);
    }
    if (!broadcastRxDataByGroupBy[key]) {
      broadcastRxDataByGroupBy[key] = [broadcastRxDataPoint];
    } else {
      broadcastRxDataByGroupBy[key].push(broadcastRxDataPoint);
    }
    if (!multicastTxDataByGroupBy[key]) {
      multicastTxDataByGroupBy[key] = [multicastTxDataPoint];
    } else {
      multicastTxDataByGroupBy[key].push(multicastTxDataPoint);
    }
    if (!multicastRxDataByGroupBy[key]) {
      multicastRxDataByGroupBy[key] = [multicastRxDataPoint];
    } else {
      multicastRxDataByGroupBy[key].push(multicastRxDataPoint);
    }
  });

  return {
    txErr: dataByPortToSeries(txErrDataByGroupBy),
    rxErr: dataByPortToSeries(rxErrDataByGroupBy),
    totalRxBytes: dataByPortToSeries(totalRxBytesDataByGroupBy),
    totalTxBytes: dataByPortToSeries(totalTxBytesDataByGroupBy),
    txBroadcasts: dataByPortToSeries(broadcastTxDataByGroupBy),
    rxBroadcasts: dataByPortToSeries(broadcastRxDataByGroupBy),
    txMulticasts: dataByPortToSeries(multicastTxDataByGroupBy),
    rxMulticasts: dataByPortToSeries(multicastRxDataByGroupBy),
  };
}

export const switchMetricsToSeries = (metricsValues: SwitchPortMetricsValue[]) => {
  const rxDataByGroupBy: DataByGroupBy = {};
  const txDataByGroupBy: DataByGroupBy = {};

  metricsValues.forEach((metricValue) => {
    const key = metricValue.port;
    const dataPoint = {
      series_id: `Port ${key}`,
      timestamp: new Date(metricValue.timestamp),
      value: metricValue.value,
    };

    if (metricValue.direction === 'RX') {
      if (!rxDataByGroupBy[key]) {
        rxDataByGroupBy[key] = [dataPoint];
      } else {
        rxDataByGroupBy[key].push(dataPoint);
      }
    } else if (!txDataByGroupBy[key]) {
      txDataByGroupBy[key] = [dataPoint];
    } else {
      txDataByGroupBy[key].push(dataPoint);
    }
  });
  return {
    rx: dataByPortToSeries(rxDataByGroupBy),
    tx: dataByPortToSeries(txDataByGroupBy),
  };
};

export const apUptimeMetricsToSeries = (metricsValues: ApUptimeMetricsValue[]) => {
  const dataByGroupBy: DataByGroupBy = {};

  metricsValues.forEach((metricValue) => {
    const key = metricValue.apName;
    const dataPoint = {
      series_id: key,
      timestamp: new Date(metricValue.timestamp),
      value: metricValue.value,
    };
    if (!dataByGroupBy[key]) {
      dataByGroupBy[key] = [dataPoint];
    } else {
      dataByGroupBy[key].push(dataPoint);
    }
  });
  return dataByGroupByToSeries(dataByGroupBy);
};

export const apChannelUtilizationMetricsToSeries = (
  metricsValues: ApChannelUtilizationMetricsValue[],
) => {
  const dataByGroupBy: DataByGroupBy = {};

  metricsValues.forEach((metricValue) => {
    const key = metricValue.apName;
    const dataPoint = {
      series_id: key,
      timestamp: new Date(metricValue.timestamp),
      value: metricValue.value,
    };

    if (!dataByGroupBy[key]) {
      dataByGroupBy[key] = [dataPoint];
    } else {
      dataByGroupBy[key].push(dataPoint);
    }
  });
  return dataByGroupByToSeries(dataByGroupBy);
};

export const clientMetricsToSeries = (metricsValues: ClientMetricsValue[]) => {
  const noiseDataByGroupBy: DataByGroupBy = {};
  const rxRateDataByGroupBy: DataByGroupBy = {};
  const signalDataByGroupBy: DataByGroupBy = {};
  const txRateDataByGroupBy: DataByGroupBy = {};

  metricsValues.forEach((metricValue) => {
    const key = metricValue.macAddress;
    const dataPoint = {
      series_id: key,
      timestamp: new Date(metricValue.timestamp),
    };
    const noiseDataPoint = {
      ...dataPoint,
      value: metricValue.noise,
    };

    const rxRateDataPoint = {
      ...dataPoint,
      value: metricValue.rxRate,
    };
    const signalDataPoint = {
      ...dataPoint,
      value: metricValue.signal,
    };
    const txRateDataPoint = {
      ...dataPoint,
      value: metricValue.txRate,
    };

    if (!noiseDataByGroupBy[key]) {
      noiseDataByGroupBy[key] = [noiseDataPoint];
    } else {
      noiseDataByGroupBy[key].push(noiseDataPoint);
    }
    if (!rxRateDataByGroupBy[key]) {
      rxRateDataByGroupBy[key] = [rxRateDataPoint];
    } else {
      rxRateDataByGroupBy[key].push(rxRateDataPoint);
    }
    if (!signalDataByGroupBy[key]) {
      signalDataByGroupBy[key] = [signalDataPoint];
    } else {
      signalDataByGroupBy[key].push(signalDataPoint);
    }
    if (!txRateDataByGroupBy[key]) {
      txRateDataByGroupBy[key] = [txRateDataPoint];
    } else {
      txRateDataByGroupBy[key].push(txRateDataPoint);
    }
  });

  return {
    noise: dataByGroupByToSeries(noiseDataByGroupBy),
    rxRate: dataByGroupByToSeries(rxRateDataByGroupBy),
    signal: dataByGroupByToSeries(signalDataByGroupBy),
    txRate: dataByGroupByToSeries(txRateDataByGroupBy),
  };
};

export const addValueToTimeSeries = (
  series: TimeSeries,
  timestamp: Date,
  value: number | undefined | null,
) => {
  // Null values are added because they create blank spaces in the graph, which we want.
  // Undefined values are optional from the API and we don't want to try to plot these.
  if (value === undefined) return;

  const s = series;

  s.data.push({
    timestamp,
    value,
    series_id: series.series_id,
  });

  if (value !== null) {
    if (s.min_value === undefined || value < s.min_value) {
      s.min_value = value;
    }
    if (s.max_value === undefined || value > s.max_value) {
      s.max_value = value;
    }
  }
};

export const apChannelUtilizationByNetworkV2MetricsToSeries = (
  results: APChannelUtilizationByNetworkQueryResult[],
  step: number,
  accessPoints: AccessPointsQueryResult[],
) => {
  const utilizations: TimeSeries[] = [];

  const getSeriesID = (result: APChannelUtilizationByNetworkQueryResult) => {
    const seriesAP = accessPoints.find((ap) => ap.UUID === result.virtualDeviceUUID);

    if (result.band === RadioBand.Band_2_4G) {
      return `2.4 GHz (${seriesAP ? seriesAP.label : result.virtualDeviceUUID})`;
    }

    return `5 GHz (${seriesAP ? seriesAP.label : result.virtualDeviceUUID})`;
  };

  results.forEach((result) => {
    const seriesID = getSeriesID(result);

    const series: TimeSeries = {
      series_id: seriesID,
      series_name: seriesID,
      data: [],
    };

    result.values.forEach((metricValue, i) => {
      const currDate = new Date(metricValue.timestamp);
      const prevDate = i > 0 ? new Date(result.values[i - 1].timestamp) : null;

      if (i === result.values.length - 1 && Date.now() - 5 * 60 * 1000 > currDate.getTime()) {
        // If the last element in the graph is older than 10 minutes, ad a null value for the current
        // time, so we see the graph up to the current time.
        addValueToTimeSeries(series, new Date(), null);
      }

      if (prevDate && currDate.getTime() - prevDate.getTime() > 1000 * step) {
        const midpoint = new Date((prevDate.getTime() + currDate.getTime()) / 2);
        addValueToTimeSeries(series, midpoint, null);
      }

      addValueToTimeSeries(series, currDate, metricValue.totalUtilization);
    });

    utilizations.push(series);
  });

  return utilizations;
};

export const apChannelUtilizationV2MetricsToSeries = (
  metricsValues: APChannelUtilizationByAccessPointQueryResult[],
  step: number,
) => {
  const fiveGUtilization: TimeSeries = {
    series_id: '5 GHz utilization',
    series_name: '5 GHz utilization',
    data: [],
  };
  const twoGUtilization: TimeSeries = {
    series_id: '2.4 GHz utilization',
    series_name: '2.4 GHz utilization',
    data: [],
  };

  const getSeriesForBand = (band: RadioBand) => {
    switch (band) {
      case RadioBand.Band_2_4G:
        return twoGUtilization;
      case RadioBand.Band_5G:
        return fiveGUtilization;
      default:
        throw new Error(`Unknown band: ${band}`);
    }
  };

  metricsValues.forEach((bandMetrics) => {
    const series = getSeriesForBand(bandMetrics.band);

    bandMetrics.values.forEach((metricValue, i) => {
      const currDate = new Date(metricValue.timestamp);
      const prevDate = i > 0 ? new Date(bandMetrics.values[i - 1].timestamp) : null;

      if (i === bandMetrics.values.length - 1 && Date.now() - 5 * 60 * 1000 > currDate.getTime()) {
        // If the last element in the graph is older than 10 minutes, ad a null value for the current
        // time, so we see the graph up to the current time.
        addValueToTimeSeries(series, new Date(), null);
      }

      if (prevDate && currDate.getTime() - prevDate.getTime() > 1000 * step) {
        const midpoint = new Date((prevDate.getTime() + currDate.getTime()) / 2);
        addValueToTimeSeries(series, midpoint, null);
      }

      addValueToTimeSeries(series, currDate, metricValue.totalUtilization);
    });
  });

  return {
    fiveGUtilization,
    twoGUtilization,
  };
};

export const clientMetricsV2ToSeries = (
  metricsTimeseriesValues: Pick<
    ClientMetricsTimeseriesValue,
    | 'timestamp'
    | 'noise'
    | 'signal'
    | 'snr'
    | 'rxRate'
    | 'txRate'
    | 'rxBytes'
    | 'txBytes'
    | 'rxSuccessRatio'
    | 'txSuccessRatio'
    | 'rxRetryRatio'
    | 'txRetryRatio'
    | 'clientCount'
    | 'rxUnicastBytes'
    | 'txUnicastBytes'
    | 'rxMulticastBytes'
    | 'txMulticastBytes'
  >[],
  step: number,
) => {
  // Tx is transmission from the AP's perspective, but is more clear as 'download' from the client's perspective, vice versa for Rx and 'upload'.
  const seriesIDs = {
    noise: 'Noise',
    signal: 'Signal',
    snr: 'Signal / Noise',
    rxRate: 'Upload rate',
    txRate: 'Download rate',
    rxUsage: 'Upload usage',
    txUsage: 'Download usage',
    rxSuccessRatio: 'Upload success ratio',
    txSuccessRatio: 'Download success ratio',
    rxRetryRatio: 'Upload retry ratio',
    txRetryRatio: 'Download retry ratio',
    clientCount: 'Client count',
    rxUnicastBytes: 'Upload unicast',
    txUnicastBytes: 'Download unicast',
    unicastBytes: 'Unicast',
    rxMulticastBytes: 'Upload multicast',
    txMulticastBytes: 'Download multicast',
    multicastBytes: 'Multicast',
  };

  const noise: TimeSeries = { series_id: seriesIDs.noise, series_name: seriesIDs.noise, data: [] };
  const signal: TimeSeries = {
    series_id: seriesIDs.signal,
    series_name: seriesIDs.signal,
    data: [],
  };
  const snr: TimeSeries = { series_id: seriesIDs.snr, series_name: seriesIDs.snr, data: [] };
  const rxRate: TimeSeries = {
    series_id: seriesIDs.rxRate,
    series_name: seriesIDs.rxRate,
    data: [],
  };
  const txRate: TimeSeries = {
    series_id: seriesIDs.txRate,
    series_name: seriesIDs.txRate,
    data: [],
  };
  const rxUsage: TimeSeries = {
    series_id: seriesIDs.rxUsage,
    series_name: seriesIDs.rxUsage,
    data: [],
  };
  const txUsage: TimeSeries = {
    series_id: seriesIDs.txUsage,
    series_name: seriesIDs.txUsage,
    data: [],
  };
  const rxSuccessRatio: TimeSeries = {
    series_id: seriesIDs.rxSuccessRatio,
    series_name: seriesIDs.rxSuccessRatio,
    data: [],
  };
  const txSuccessRatio: TimeSeries = {
    series_id: seriesIDs.txSuccessRatio,
    series_name: seriesIDs.txSuccessRatio,
    data: [],
  };
  const rxRetryRatio: TimeSeries = {
    series_id: seriesIDs.rxRetryRatio,
    series_name: seriesIDs.rxRetryRatio,
    data: [],
  };
  const txRetryRatio: TimeSeries = {
    series_id: seriesIDs.txRetryRatio,
    series_name: seriesIDs.txRetryRatio,
    data: [],
  };
  const clientCount: TimeSeries = {
    series_id: seriesIDs.clientCount,
    series_name: seriesIDs.clientCount,
    data: [],
  };
  const txUnicastBytes: TimeSeries = {
    series_id: seriesIDs.txUnicastBytes,
    series_name: seriesIDs.txUnicastBytes,
    data: [],
  };
  const rxUnicastBytes: TimeSeries = {
    series_id: seriesIDs.rxUnicastBytes,
    series_name: seriesIDs.rxUnicastBytes,
    data: [],
  };
  const unicastBytes: TimeSeries = {
    series_id: seriesIDs.unicastBytes,
    series_name: seriesIDs.unicastBytes,
    data: [],
  };
  const txMulticastBytes: TimeSeries = {
    series_id: seriesIDs.txMulticastBytes,
    series_name: seriesIDs.txMulticastBytes,
    data: [],
  };
  const rxMulticastBytes: TimeSeries = {
    series_id: seriesIDs.rxMulticastBytes,
    series_name: seriesIDs.rxMulticastBytes,
    data: [],
  };
  const multicastBytes: TimeSeries = {
    series_id: seriesIDs.multicastBytes,
    series_name: seriesIDs.multicastBytes,
    data: [],
  };

  const addNullValueForTime = (time: Date) => {
    addValueToTimeSeries(noise, time, null);
    addValueToTimeSeries(signal, time, null);
    addValueToTimeSeries(snr, time, null);
    addValueToTimeSeries(rxRate, time, null);
    addValueToTimeSeries(txRate, time, null);
    addValueToTimeSeries(rxUsage, time, null);
    addValueToTimeSeries(txUsage, time, null);
    addValueToTimeSeries(rxSuccessRatio, time, null);
    addValueToTimeSeries(txSuccessRatio, time, null);
    addValueToTimeSeries(rxRetryRatio, time, null);
    addValueToTimeSeries(txRetryRatio, time, null);
    addValueToTimeSeries(clientCount, time, null);
    addValueToTimeSeries(txUnicastBytes, time, null);
    addValueToTimeSeries(rxUnicastBytes, time, null);
    addValueToTimeSeries(txMulticastBytes, time, null);
    addValueToTimeSeries(rxMulticastBytes, time, null);
    addValueToTimeSeries(unicastBytes, time, null);
    addValueToTimeSeries(multicastBytes, time, null);
  };

  metricsTimeseriesValues.forEach((metricValue, i) => {
    const currDate = new Date(metricValue.timestamp);
    const prevDate = i > 0 ? new Date(metricsTimeseriesValues[i - 1].timestamp) : null;

    if (
      i === metricsTimeseriesValues.length - 1 &&
      Date.now() - 10 * 60 * 1000 > currDate.getTime()
    ) {
      // If the last element in the graph is older than 10 minutes, ad a null value for the current
      // time, so we see the graph up to the current time.
      addNullValueForTime(new Date());
    }

    if (prevDate && currDate.getTime() - prevDate.getTime() > 1000 * step) {
      const midpoint = new Date((prevDate.getTime() + currDate.getTime()) / 2);
      addNullValueForTime(midpoint);
    }

    addValueToTimeSeries(noise, currDate, metricValue.noise);
    addValueToTimeSeries(signal, currDate, metricValue.signal);
    addValueToTimeSeries(snr, currDate, metricValue.snr);
    addValueToTimeSeries(rxRate, currDate, metricValue.rxRate);
    addValueToTimeSeries(txRate, currDate, metricValue.txRate);
    addValueToTimeSeries(rxUsage, currDate, metricValue.rxBytes);
    addValueToTimeSeries(txUsage, currDate, metricValue.txBytes);
    addValueToTimeSeries(rxSuccessRatio, currDate, metricValue.rxSuccessRatio);
    addValueToTimeSeries(txSuccessRatio, currDate, metricValue.txSuccessRatio);
    addValueToTimeSeries(rxRetryRatio, currDate, metricValue.rxRetryRatio);
    addValueToTimeSeries(txRetryRatio, currDate, metricValue.txRetryRatio);
    addValueToTimeSeries(clientCount, currDate, metricValue.clientCount);
    addValueToTimeSeries(txUnicastBytes, currDate, metricValue.txUnicastBytes);
    addValueToTimeSeries(rxUnicastBytes, currDate, metricValue.rxUnicastBytes);
    addValueToTimeSeries(txMulticastBytes, currDate, metricValue.txMulticastBytes);
    addValueToTimeSeries(rxMulticastBytes, currDate, metricValue.rxMulticastBytes);
    addValueToTimeSeries(
      unicastBytes,
      currDate,
      metricValue.rxUnicastBytes + metricValue.txUnicastBytes,
    );
    addValueToTimeSeries(
      multicastBytes,
      currDate,
      metricValue.rxMulticastBytes + metricValue.txMulticastBytes,
    );
  });

  return {
    noise,
    signal,
    snr,
    txRate,
    rxRate,
    rxUsage,
    txUsage,
    rxSuccessRatio,
    txSuccessRatio,
    rxRetryRatio,
    txRetryRatio,
    clientCount,
    txUnicastBytes,
    rxUnicastBytes,
    unicastBytes,
    txMulticastBytes,
    rxMulticastBytes,
    multicastBytes,
  };
};

/**
 * Components and functions pertaining to time period selection
 */

export function timePeriodLabel(period: string): string {
  switch (period) {
    case '1h':
      return 'Last hour';
    case '6h':
      return 'Last 6 hours';
    case '7d':
      return 'Last 7 days';
    case '30d':
      return 'Last 30 days';
    case '24h':
    default:
      return 'Last 24 hours';
  }
}

export function getDurationMinutes(currentTimePeriod: string): number {
  const min = 1;
  const hour = 60 * min;
  const day = 24 * hour;

  switch (currentTimePeriod) {
    case '30m':
      return 30 * min;
    case '1h':
      return 1 * hour;
    case '6h':
      return 6 * hour;
    case '7d':
      return 7 * day;
    case '30d':
      return 30 * day;
    case '24h':
    default:
      return 24 * hour;
  }
}

export function getDurationSeconds(currentTimePeriod: string): number {
  return getDurationMinutes(currentTimePeriod) * 60;
}

export function getStep(currentTimePeriod: string): number {
  const min = 60;
  const hour = 60 * min;
  switch (currentTimePeriod) {
    case '7d':
      return 30 * min;
    case '30d':
      return 1 * hour;
    case '3m':
      return 3 * hour;
    case '6m':
      return 6 * hour;
    case '9m':
      return 9 * hour;
    case '1y':
      return 12 * hour;
    default:
      return 5 * min;
  }
}

export function getStepForDuration(duration: number): number {
  if (duration > 2000000) {
    return 20000;
  }
  if (duration > 604000) {
    return 8000;
  }
  if (duration > 80000) {
    return 1000;
  }
  if (duration > 20000) {
    return 200;
  }
  return 30;
}

/**
 * Chart component rendering utils
 */

export const nonZeroCountFormatter = (value: number) => {
  if (value < 0) return '';

  return value.toFixed(0);
};

export const dbmValueFormatter = (value: number) => `${value.toFixed(0)} dBm`;
export const percentValueFormatter = d3.format('.2~%');
export const durationValueFormatter = (seconds: number) => {
  const d = Duration.fromMillis(seconds * 1000);
  return d.toFormat("dd'd,' hh'h,' mm'm,' ss's'");
};
export function dataRateBytesValueFormatter(value: number) {
  return formatDataRateBits(value, bytesPerSecond);
}
export const dataRateValueFormatter = (value: number) =>
  formatDataRateBits(value, kilobitsPerSecond);
export const dataRateValueFormatterShort = (value: number) =>
  formatDataRateBits(value, kilobitsPerSecond, 0, 's');
export function usageValueFormatter(value: number) {
  return formatBytes(value, 2);
}
export function usageValueFormatterShort(value: number) {
  return formatBytes(value, 0);
}
export function snrValueFormatter(value: number) {
  return `${value.toFixed(2)} dB`;
}
export function snrValueFormatterShort(value: number) {
  return `${value.toFixed(0)} dB`;
}
export function packetValueFormatter(value: number) {
  return `${d3.format(',.2~f')(value)} packets`;
}
export function packetRateValueFormatter(value: number) {
  return `${d3.format(',.2~f')(value)} pps`;
}
export function requestRateValueFormatter(value: number) {
  return `${d3.format(',.2~f')(value)} rps`;
}

const repeatArray = (arr: string[], n: number) => Array.from({ length: n }).flatMap(() => arr);
export const lightColors = repeatArray(
  [
    '#5461C8',
    '#228B22',
    '#1E90FF',
    '#DC143C',
    '#4169E1',
    '#228B22',
    '#6A5ACD',
    '#FFA500',
    '#B22222',
    '#9ACD32',
    '#DB7093',
    '#00008B',
    '#D2691E',
    '#9932CC',
    '#8B0000',
    '#6B8E23',
    '#191970',
    '#800000',
    '#4B0082',
    '#FFD700',
    '#C71585',
    '#800080',
  ],
  30,
);

export const darkColors = repeatArray(
  [
    '#40E0D0',
    '#FFA07A',
    '#B0E0E6',
    '#98FB98',
    '#DDA0DD',
    '#CD5C5C',
    '#F08080',
    '#32CD32',
    '#FFC0CB',
    '#E0FFFF',
    '#FFE4C4',
    '#D8BFD8',
    '#E9967A',
    '#7CFC00',
    '#7FFFD4',
    '#BC8F8F',
    '#EE82EE',
    '#FFFACD',
    '#FFB6C1',
    '#DA70D6',
  ],
  30,
);

export const calculateXTickValues = (durationSeconds: number, numTicks: number) => {
  let tickValues: Date[] = [];
  const currentTime = Date.now();
  const interval = (durationSeconds * 1000) / (numTicks - 1);

  for (let i = 0; i < numTicks; i += 1) {
    let tickTime = currentTime - interval * i;
    tickTime = Math.round(tickTime / 300000) * 300000; // Buckets ticks to nearest 5 minute time
    tickValues = [new Date(tickTime)].concat(tickValues);
  }

  return tickValues;
};

export const calculateXTickValuesSwitches = (series: TimeSeries[]) => {
  const data = series[0]?.data || {};

  if (!data || data.length === 0 || series.length === 0) {
    return [];
  }

  const first = data[0];
  const mid = data[Math.floor(data.length / 2)];
  const last = data[data.length - 1];

  return [first.timestamp, mid.timestamp, last.timestamp];
};

export const useChartColors = (series: TimeSeries[]) => {
  const { dark } = useContext(ThemeContext);
  const chartColors = dark ? darkColors : lightColors;

  const colorMap: Record<string, string> = {};
  series.forEach((s, idx) => {
    colorMap[s.series_id] = chartColors[idx];
  });

  return { chartColors, colorMap };
};

export const useChartColorsById = (seriesIds: string[]) => {
  const { theme: appTheme } = useContext(ThemeContext);
  const chartColors = appTheme === 'light' ? lightColors : darkColors;

  const colorMap: Record<string, string> = {};
  seriesIds.forEach((s, idx) => {
    colorMap[s] = chartColors[idx];
  });

  return { chartColors, colorMap };
};

export const buildChartThemeImpl = (chartColors: string[], mode: 'light' | 'dark') =>
  mode === 'light'
    ? buildChartTheme({
        svgLabelSmall: {
          fontFamily: fonts.sans.toString(),
          fontWeight: fontWeights.regular.toString(),
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: 0,
          fill: colors.bodyNeutralLight.toString(),
        },
        xAxisLineStyles: {
          stroke: colors.strokeNeutralLight.toString(),
        },
        xTickLineStyles: {
          stroke: colors.strokeNeutralLight.toString(),
        },
        yTickLineStyles: {
          stroke: colors.strokeNeutralLight.toString(),
        },
        colors: chartColors,
        gridColor: colors.brand200.toString(),
        backgroundColor: colors.bgApplicationLight.toString(),
        gridColorDark: colors.strokeNeutralLight.toString(),
        tickLength: 4,
      })
    : buildChartTheme({
        svgLabelSmall: {
          fontFamily: fonts.sans.toString(),
          fontWeight: fontWeights.regular.toString(),
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: 0,
          fill: colors.bodyNeutralDark.toString(),
        },
        xAxisLineStyles: {
          stroke: colors.strokeNeutralDark.toString(),
        },
        xTickLineStyles: {
          stroke: colors.strokeNeutralDark.toString(),
        },
        yTickLineStyles: {
          stroke: colors.strokeNeutralDark.toString(),
        },
        colors: [colors.iconBrandDark.toString(), colors.iconPositiveDark.toString()],
        gridColor: colors.brand800.toString(),
        backgroundColor: colors.bgApplicationDark.toString(),
        gridColorDark: colors.strokeNeutralDark.toString(),
        tickLength: 4,
      });

export const DELTA_MINUTES_FOR_NOW = 10;

export const dateFormatter = (date: Date, timePeriod: string, format?: string, allowNow = true) => {
  const d = DateTime.fromJSDate(date);

  if (allowNow && Math.abs(d.diffNow('minutes').minutes) < DELTA_MINUTES_FOR_NOW) {
    return '• Now';
  }

  if (format) {
    return d.toFormat(format);
  }

  if (['1h', '6h', '24h'].includes(timePeriod)) {
    return d.toFormat('t');
  }

  return d.toFormat('LLL d');
};

export const tickLabelProps: TickLabelProps<any> | undefined = (
  tickValue: Date,
  i: number,
  allValues: { value: Date; index: number }[],
) => {
  if (i === 0) {
    return { textAnchor: 'inherit' };
  }
  if (i === allValues.length - 1) {
    const props: TickLabelProps<any> = { textAnchor: 'end' };
    if (
      Math.abs(DateTime.fromJSDate(tickValue).diffNow('minutes').minutes) < DELTA_MINUTES_FOR_NOW
    ) {
      props.fill = colors.green600.toString();
    }
    return props;
  }
  return { textAnchor: 'middle' };
};

export const TooltipItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$6',
  width: '100%',
});

export const TooltipContainer = styled('div', {
  vStack: '$4',
  alignItems: 'flex-start',
  minWidth: '160px',
  padding: '$6 $8',
  borderRadius: '$8',
  background: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },
});

const ThemeAwareRect = styled('rect', {
  fill: colors.bgNeutralLight,

  [darkThemeSelector]: {
    fill: colors.bgNeutralDark,
  },
});

const ThemeAwareStrokeLeft = styled('line', {
  stroke: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    stroke: colors.strokeNeutralDark,
  },
});

const ThemeAwareStrokeBottom = styled('line', {
  stroke: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    stroke: colors.strokeNeutralDark,
  },
});

const ThemeAwareLine = styled('line', {
  stroke: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    stroke: colors.strokeNeutralDark,
  },
});

export function CheckeredBackground() {
  const id = useId();
  const {
    width = 0,
    height = 0,
    margin = { left: 0, right: 0, top: 0, bottom: 0 },
  } = useContext(DataContext);
  const backgroundWidth = width > 0 ? width - margin.left : 0;

  return (
    <>
      <pattern id={id} x="0" y="0" width="8" height="8" patternUnits="userSpaceOnUse">
        <rect x="0" width="4" height="4" y="0" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="0" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="4" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="0" width="4" height="4" y="4" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
      </pattern>
      <ThemeAwareRect x={margin.left} width={backgroundWidth} height={height - margin.bottom} />
      <rect
        x={margin.left}
        width={backgroundWidth}
        height={height - margin.bottom}
        fill={`url(#${id})`}
      />
      <ThemeAwareLine
        x1={margin.left}
        x2={width - margin.right}
        y1={height - margin.bottom}
        y2={height - margin.bottom}
      />
      <ThemeAwareStrokeLeft x1={margin.left} x2={margin.left} y1={0} y2={height - margin.bottom} />
      <ThemeAwareStrokeBottom
        x1={margin.left}
        x2={width}
        y1={height - margin.bottom}
        y2={height - margin.bottom}
      />
    </>
  );
}

interface MetricToolTipProps {
  content: React.ReactNode;
}

export function MetricTooltip({ content }: MetricToolTipProps) {
  const StyledIcon = styled(Icon, {
    marginTop: '$2',
  });

  return (
    <HStack spacing={space(6)}>
      <StyledIcon icon="information" size={12} />
      {content}
    </HStack>
  );
}

export const ChartsFallbackContainer = styled('div', {
  padding: '$16 $20',
});

/*
  A list of work that still needs to be done, or are nice to haves:

  - Better understanding `series` vs `series labels`. We want the backend to tell us what the series
    is, so we can make decisions for the frontend (area vs line, curve, etc). We don't, however,
    want the backend to make decisions about the labels etc. (for tooltips, multiple series on the
    same graph, etc.). So, we want to know if a series is `wan_throughput` for curve, area vs. line,
    etc., but we want to know if a series is `wan0` or `wan1` for the type of line to draw, etc.
  - Backend API support.
  - Ultimately support the API for `local` as well (so we can stop doing the hacky json parsing /
    injecting / etc).
  - Tests?
 */

import type { metrics } from '@meterup/proto';
import { makeAPICall } from '@meterup/common';
import { api } from '@meterup/proto';
import * as d3 from 'd3';
import { uniq } from 'lodash-es';
import { DateTime } from 'luxon';

import type { TimeSeriesData } from './types';
import { DURATION_24H_MS } from '../constants';
import { delay } from '../utils/delay';
import { getRealm, Realm } from '../utils/realm';
import { axiosInstanceJSON } from './axiosInstanceJSON';
import ONLINE_CLIENTS from './data/online_clients.json';
import WAN_SPEED_TESTS from './data/speedtests.json';
import WAN0_QUALITY from './data/wan0_quality.json';
import WAN0_THROUGHPUT from './data/wan0_throughput.json';
import WAN1_QUALITY from './data/wan1_quality.json';
import WAN1_THROUGHPUT from './data/wan1_throughput.json';

const METRICS_ENDPOINT = '/v1/metrics2';

export interface MetricsAPIEndpoint {
  series_id: string;
  stubbed_data: { [key: string]: metrics.GetMetrics2Response };
  curve?: d3.CurveFactory; // defaults to d3.curveStepBefore
  series_type?: 'area' | 'line'; // defaults to 'line'
}

interface MetricsAPIParams {
  controllerName: string;
  controllerState: api.LifecycleStatus;
  endTime: Date;
  durationMs: number;
  wan?: string;
  step?: number;
}

const calculateStartTime = (endTime: Date, durationMs: number): Date =>
  DateTime.fromJSDate(endTime).minus({ milliseconds: durationMs }).toJSDate();

// This is an expensive computation, so we should push it to the API. If, for some reason, it is
// not present, we will fall back to computing it here.
//
// Unclear if we should actually do this or if we should just enforce the constraint from the API
// that it should always be present.
const calculateRange = (values: metrics.Metric2Datapoint[]): metrics.Metric2Range => {
  const vals = values.map((data) => data.value);

  return {
    min: Math.min(...vals),
    max: Math.max(...vals),
  };
};

/*
    `parseResponse` should not do anything to heavy and/or specific to any single series. We want ~all
    of this logic pushed to the API. This method simply exists to convert the over-the-wire data
    (which is made up of mostly strings) to the properly typed data we will actually be using in our
    app.
   */
const parseAPIResponse = async (
  apiResponse: metrics.GetMetrics2Response,
  metric: MetricsAPIEndpoint,
): Promise<TimeSeriesData[]> => {
  const response: TimeSeriesData[] = [];

  apiResponse.data.forEach((series) => {
    const data = series.values.map(({ timestamp, value, ...rest }) => ({
      timestamp: new Date(timestamp!),
      source: rest?.tags?.source,
      value,
    }));

    response.push({
      data,
      range: series?.metadata?.range ?? calculateRange(series.values),
      curve: metric.curve ?? d3.curveStepBefore,
      series_type: metric.series_type ?? 'line',
      series_id: metric.series_id,
      series_name: series?.metadata?.name,
    });
  });

  return response;
};

const fetchMetrics = async (
  metric: MetricsAPIEndpoint,
  params: MetricsAPIParams,
): Promise<TimeSeriesData[]> => {
  const apiResult = await makeAPICall(async () => {
    const apiParams = {
      series_id: metric.series_id,
      object_id: params.controllerName,
      end_time: params.endTime.toISOString(),
      duration_ms: params.durationMs,
      wan: params.wan,
      step: params.step,
    };

    const result = await axiosInstanceJSON.get<metrics.GetMetrics2Response>(METRICS_ENDPOINT, {
      params: apiParams,
    });

    return result.data;
  });

  return parseAPIResponse(apiResult, metric);
};

/*
    This method is a bit of a hack for local development / demos / etc. It basically takes the data we
    have populated (likely through a static JSON file) and forces the timestamps to match the time
    period we care about. This will allow us to change the duration in the frontend (i.e. last 24
    hours, last 7 days, etc.), and see the timestamps be reflected in the graphs (even though the
    values won't change because it's static).
   */
const injectTimestamps = async (
  data: TimeSeriesData[],
  p: MetricsAPIParams,
): Promise<TimeSeriesData[]> => {
  // This shouldn't happen, but we can be defensive for safety here.
  const realm = getRealm();
  if (realm !== Realm.LOCAL) return data;

  const response: TimeSeriesData[] = [];

  const numSegments = uniq(data.map((d) => d.data.length));
  if (numSegments.length > 1) {
    throw new Error('Expected all sets to have the same number of values');
  }

  const startTime = calculateStartTime(p.endTime, p.durationMs);
  const totalMinutes = p.durationMs / 1000 / 60;
  const frequency = totalMinutes / numSegments[0];

  data.forEach((seriesData) => {
    const injectedData = seriesData;
    injectedData.data = injectedData.data.map((val, index) => ({
      timestamp: DateTime.fromJSDate(startTime)
        .plus({ minutes: frequency * index })
        .toJSDate(),
      value: val.value,
      ...(val.source && { source: val.source }),
    }));

    response.push(injectedData);
  });

  return response;
};

// Helper method that takes in some data, and some information about the data set, and calls out
// to parse the data and inject timestamps, among other things.
const stubbedData = async (
  metric: MetricsAPIEndpoint,
  params: MetricsAPIParams,
): Promise<TimeSeriesData[]> =>
  injectTimestamps(
    await parseAPIResponse(metric.stubbed_data[params.wan || 'wan0']!, metric),
    params,
  );

/*
    The actual API methods, and exporting the API itself, should all go below this line.
   */

export const ISPQuality: MetricsAPIEndpoint = {
  series_id: 'isp_quality',
  curve: d3.curveMonotoneX,
  stubbed_data: { wan0: WAN0_QUALITY, wan1: WAN1_QUALITY },
};

export const ISPThroughput: MetricsAPIEndpoint = {
  series_id: 'isp_throughput',
  curve: d3.curveCatmullRom,
  series_type: 'area',
  stubbed_data: { wan0: WAN0_THROUGHPUT, wan1: WAN1_THROUGHPUT },
};

export const ActiveClientCount: MetricsAPIEndpoint = {
  series_id: 'active_client_count',
  stubbed_data: { wan0: ONLINE_CLIENTS, wan1: ONLINE_CLIENTS },
};

export const WANSpeedTests: MetricsAPIEndpoint = {
  series_id: 'wan_speedtests',
  stubbed_data: { wan0: WAN_SPEED_TESTS, wan1: WAN_SPEED_TESTS },
};

const fetchMetric = async (
  metric: MetricsAPIEndpoint,
  params: Partial<MetricsAPIParams>,
): Promise<TimeSeriesData[]> => {
  const controllerName = params.controllerName!;
  const controllerState = params.controllerState!;
  const realm = getRealm();

  const defaultParams = {
    endTime: new Date(),
    durationMs: DURATION_24H_MS,
  };

  const formedParams: MetricsAPIParams = {
    ...defaultParams,
    ...params,
    controllerName,
    controllerState,
  };

  const returnStubbedData =
    realm === Realm.LOCAL || controllerState === api.LifecycleStatus.LIFECYCLE_STATUS_DEMO;

  return returnStubbedData
    ? delay(1500).then(() => stubbedData(metric, formedParams))
    : fetchMetrics(metric, formedParams);
};

const MetricsAPI = {
  fetchMetric,
};

export default MetricsAPI;

import { EmptyState, PaneContent, Text } from '@meterup/atto';
import { AutoTable, isDefinedAndNotEmpty } from '@meterup/common';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';

import { NoValue } from '../../../../../components/NoValue';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useNetworkSlugFromPath } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import {
  type MutationAuditLogQueryResult,
  useNetworkMutationAuditLog,
} from './hooks/useNetworkMutationAuditLog';

const builder = createColumnBuilder<MutationAuditLogQueryResult>();

const columns = [
  builder.data((d) => d.action, {
    id: 'event',
    header: 'Event',
    cell: (d) => <Text family="monospace">{d.value}</Text>,
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.username, {
    id: 'username',
    header: 'Username',
  }),
  builder.data((d) => d.createdAt ?? '', {
    id: 'time',
    header: `Time (${DateTime.local().toFormat('ZZZZZ')})`,
    meta: {
      alignment: 'end',
    },
    cell: (d) =>
      isDefinedAndNotEmpty(d.value) ? (
        <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</>
      ) : (
        <NoValue />
      ),
  }),
];

export default function NetworkMutationListTable() {
  const params = Nav.useRegionParams('drawer', paths.drawers.NetworkMutationSummaryPage);
  const networkSlug = useNetworkSlugFromPath();

  const companyName = useCurrentCompany();

  const defaultStartDate = DateTime.now().startOf('day').minus({ months: 3 }).toISO({
    includeOffset: false,
  });
  const defaultEndDate = DateTime.now()
    .endOf('day')
    .set({
      second: 0,
      millisecond: 0,
    })
    .toISO({
      includeOffset: false,
      suppressSeconds: true,
      suppressMilliseconds: true,
    });
  const [queryStartDate] = useSearchParamsState<string>('startDate', defaultStartDate);
  const [queryEndDate] = useSearchParamsState<string>('endDate', defaultEndDate);

  const startDate = queryStartDate || defaultStartDate;
  const endDate = queryEndDate || defaultEndDate;

  const [querySearch] = useSearchParamsState<string>('search', '');

  const [includeMachine] = useSearchParamsState<boolean>('includeMachine', false);

  const filter = useMemo(
    () => ({
      minCreatedAt: DateTime.fromISO(startDate).toISO(),
      maxCreatedAt: DateTime.fromISO(endDate).toISO(),
      includeMachine,
    }),
    [startDate, endDate, includeMachine],
  );

  const linkTo = (row: MutationAuditLogQueryResult) =>
    makeDrawerLink(window.location, paths.drawers.NetworkMutationSummaryPage, {
      companyName,
      networkSlug,
      mutationAuditLogEntryUUID: row.UUID,
    });

  const rowSelected = useCallback(
    (row: MutationAuditLogQueryResult) => params?.mutationAuditLogEntryUUID === row.UUID,
    [params?.mutationAuditLogEntryUUID],
  );

  const events = useNetworkMutationAuditLog(filter);

  const tableContainerRef = useRef(null);
  return (
    <PaneContent ref={tableContainerRef}>
      {events?.length ? (
        <AutoTable
          isVirtual
          tableContainerRef={tableContainerRef}
          columns={columns}
          data={events}
          globalFilter={querySearch}
          getLinkTo={linkTo}
          isRowSelected={rowSelected}
        />
      ) : (
        <EmptyState icon="log" heading="No events for criteria" />
      )}
    </PaneContent>
  );
}

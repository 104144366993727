import { Section, SectionContent, SectionHeader, SummaryList } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { Duration } from 'luxon';

import { CopyableMonoOrNoValue } from '../../../../Devices/Insights';
import {
  DeviceUptimeQuery,
  formatCPULoad,
  formatMemoryUsage,
  formatPoEInfo,
  LatestDeviceCPULoadQuery,
  LatestDeviceMemoryUsageQuery,
  switchPoEInfoQuery,
} from '../../../../Devices/utils';

export function InspectorMetrics({ serialNumber }: { serialNumber: string }) {
  const deviceUptime = useGraphQL(
    DeviceUptimeQuery,
    { serialNumber },
    { useErrorBoundary: false, suspense: false },
  );

  const latestDeviceCPULoad = useGraphQL(
    LatestDeviceCPULoadQuery,
    { serialNumber },
    { useErrorBoundary: false, suspense: false },
  );

  const latestDeviceMemoryUsage = useGraphQL(
    LatestDeviceMemoryUsageQuery,
    { serialNumber },
    { useErrorBoundary: false, suspense: false },
  );

  const poeInfo = useGraphQL(
    switchPoEInfoQuery,
    { serialNumber },
    { useErrorBoundary: false, suspense: false },
  );

  return (
    <Section relation="stacked" size="x-small">
      <SectionHeader heading="Metrics" />
      <SectionContent gutter="all">
        <SummaryList
          gutter="none"
          size="x-small"
          pairs={[
            {
              label: 'Uptime',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy uptime"
                  value={
                    deviceUptime.isError || !deviceUptime.data
                      ? null
                      : Duration.fromISO(deviceUptime?.data?.deviceUptime).toHuman({
                          unitDisplay: 'long',
                        })
                  }
                />
              ),
            },
            {
              label: 'CPU load',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy CPU load"
                  value={
                    latestDeviceCPULoad.isError || !latestDeviceCPULoad.data
                      ? null
                      : formatCPULoad(latestDeviceCPULoad?.data?.latestDeviceCPULoad.usedPercentage)
                  }
                />
              ),
            },
            {
              label: 'Memory available',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy memory available"
                  value={
                    latestDeviceMemoryUsage.isError || !latestDeviceMemoryUsage.data
                      ? null
                      : formatMemoryUsage(latestDeviceMemoryUsage.data.latestDeviceMemoryUsage)
                  }
                />
              ),
            },
            ...(!poeInfo.isError &&
            poeInfo.data &&
            poeInfo.data.hardwareDevice.__typename === 'SwitchHardwareDevice'
              ? [
                  {
                    label: 'PoE power used',
                    value: (
                      <CopyableMonoOrNoValue
                        label="Copy PoE power used"
                        value={formatPoEInfo(poeInfo.data.hardwareDevice?.poeInfo)}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </SectionContent>
    </Section>
  );
}

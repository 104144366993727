import type { ClientError } from 'graphql-request';
import {
  Alert,
  Body,
  Button,
  CopyBox,
  darkThemeSelector,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FieldContainer,
  HStack,
  Icon,
  PrimaryField,
  SecondaryToggleField,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getExtensionErrorCode,
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  GraphQLExtensionErrorCode,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { Formik } from 'formik';
import React, { Suspense, useCallback, useState } from 'react';
import { toFormikValidate } from 'zod-formik-adapter';

import type {
  DeviceModel,
  DeviceType,
  HardwareDeviceQuery,
  UpdateHardwareDeviceInput,
  VirtualDeviceType,
} from '../../gql/graphql';
import { UpdateHardwareDeviceInputSchema } from '../../gql/zod-types';
import { colors, styled } from '../../stitches';
import { getRealm, Realm } from '../../utils/realm';
import { deviceTypeToHardwareIconPropHardware } from '../../utils/types';
import { FieldProvider } from '../Form/FieldProvider';
import { FormlessResetButton, FormlessSubmitButton } from '../NetworkWide/VLANs/VLANDetails/Common';
import {
  assignHardwareDeviceToVirtualDevice,
  createDevHardwareDeviceMutation,
  deviceTypeForVirtualDeviceType,
  hardwareDeviceQuery,
  unassignHardwareDeviceFromVirtualDevice,
  updateHardwareDeviceMutation,
} from './utils';

const HardwareDeviceSectionContainer = styled(Section, {
  backgroundColor: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },
});

const HardwareDeviceInfoIcon = styled(Icon, {
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },
});

export function HardwareDeviceInfo({
  hardwareDevice,
  showVirtualDeviceInfo = false,
}: {
  hardwareDevice: NonNullable<HardwareDeviceQuery['hardwareDevice']>;
  showVirtualDeviceInfo?: boolean;
}) {
  return (
    <VStack spacing={space(12)}>
      <HStack align="center" spacing={space(8)}>
        <HardwareDeviceInfoIcon
          icon={deviceTypeToHardwareIconPropHardware(hardwareDevice.deviceType)}
        />
        <CopyBox aria-label="Copy device model" value={hardwareDevice.deviceModel}>
          <Body family="monospace">{hardwareDevice.deviceModel}</Body>
        </CopyBox>
        <CopyBox aria-label="Copy serial number" value={hardwareDevice.serialNumber}>
          <Body family="monospace">{hardwareDevice.serialNumber}</Body>
        </CopyBox>
      </HStack>

      {showVirtualDeviceInfo && hardwareDevice.virtualDevice && (
        <Section>
          <SectionHeader heading="Attached virtual device" icon="warning" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <SummaryListRow>
                <SummaryListKey>UUID</SummaryListKey>
                <SummaryListValue>
                  <CopyBox
                    aria-label="Copy virtual device UUID"
                    value={hardwareDevice.virtualDevice.UUID}
                  >
                    <Body family="monospace">{hardwareDevice.virtualDevice.UUID}</Body>
                  </CopyBox>
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Label</SummaryListKey>
                <SummaryListValue>{hardwareDevice.virtualDevice.label}</SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Description</SummaryListKey>
                <SummaryListValue>{hardwareDevice.virtualDevice.description}</SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
        </Section>
      )}
    </VStack>
  );
}

function HardwareDeviceInfoSection({
  hardwareDevice,
  children,
  internal,
}: {
  hardwareDevice: NonNullable<HardwareDeviceQuery['hardwareDevice']>;
  children?: React.ReactNode;
  internal?: boolean;
}) {
  return (
    <HardwareDeviceSectionContainer internal={internal}>
      <SectionHeader heading="Hardware" />
      <SectionContent gutter="all">
        <VStack spacing={space(6)}>
          <HardwareDeviceInfo hardwareDevice={hardwareDevice} showVirtualDeviceInfo />
          {children}
        </VStack>
      </SectionContent>
    </HardwareDeviceSectionContainer>
  );
}

export function HardwareDeviceInfoFetcher({
  serialNumber,
  internal,
}: {
  serialNumber: string;
  internal?: boolean;
}) {
  const hardwareDeviceQueryHandle = useGraphQL(
    hardwareDeviceQuery,
    { serialNumber: serialNumber! },
    {
      enabled: !!serialNumber,
      useErrorBoundary: false,
    },
  );

  if (!hardwareDeviceQueryHandle?.data || hardwareDeviceQueryHandle.isError) return null;

  const { hardwareDevice } = hardwareDeviceQueryHandle.data;

  return <HardwareDeviceInfoSection hardwareDevice={hardwareDevice} internal={internal} />;
}

const SerialNumberHardwareDeviceInfoContainer = styled('div', {
  marginTop: '$4',
});

const DialogBodyContainer = styled('div', {
  padding: '$16',
});

const UpdateHardwareFormContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '$6',
  width: '100%',
});

const ActiveFieldContainer = styled('div', {
  '& > div': {
    height: '100%',
    strokeAll: colors.transparent,

    [darkThemeSelector]: {
      strokeAll: colors.transparent,
    },
  },

  '& > div > div': {
    height: '100%',
    padding: 0,
  },

  '& > div > div > div': {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    gap: '$6',
    height: '100%',
  },
});

export function HardwareDeviceField({
  virtualDevice,
  onSuccess,
  internal,
}: {
  virtualDevice: {
    UUID: string;
    label: string;
    networkUUID: string;
    deviceType: VirtualDeviceType;
    deviceModel: DeviceModel;
    hardwareDevice?: {
      serialNumber: string;
      deviceType: DeviceType;
      deviceModel: DeviceModel;
      isActive: boolean;
    } | null;
  };
  onSuccess: () => Promise<void>;
  internal?: boolean;
}) {
  const realm = getRealm();
  const updateHardwareDevice = useGraphQLMutation(updateHardwareDeviceMutation);
  const assignToDeviceMutation = useGraphQLMutation(assignHardwareDeviceToVirtualDevice);
  const createDevDeviceMutation = useGraphQLMutation(createDevHardwareDeviceMutation);
  const unassignHardwareDeviceMutation = useGraphQLMutation(
    unassignHardwareDeviceFromVirtualDevice,
  );
  const [serialNumber, setSerialNumber] = useState('');
  const dialogProps = useDialogState();
  const [unassignErrorText, setUnassignErrorText] = useState<string | null>(null);

  const { hardwareDevice } = virtualDevice;

  const hasHardwareDevice = !!hardwareDevice;

  const handleAssignHardware = useCallback(() => {
    if (
      hasHardwareDevice ||
      assignToDeviceMutation.isLoading ||
      createDevDeviceMutation.isLoading ||
      createDevDeviceMutation.isError
    )
      return;

    const handleSuccess = () => {
      onSuccess().then(() => {
        setSerialNumber('');
      });
      notify('Successfully assigned hardware device.', { variant: 'positive' });
    };

    const handleError = (error: ClientError) => {
      notify(
        `There was a problem assigning the hardware device${getGraphQLErrorMessageOrEmpty(error)}.`,
        { variant: 'negative' },
      );
    };

    assignToDeviceMutation.mutate(
      {
        serialNumber,
        virtualDeviceUUID: virtualDevice.UUID,
      },
      {
        onError: (error) => {
          const graphqlError = getGraphQLError(error);

          // If local or in dev, automatically create the hardware device for convenience
          if (
            (realm === Realm.LOCAL || realm === Realm.STAGING) &&
            getExtensionErrorCode(graphqlError) === GraphQLExtensionErrorCode.NotFound
          ) {
            return createDevDeviceMutation.mutate(
              {
                input: {
                  deviceType: deviceTypeForVirtualDeviceType[virtualDevice.deviceType],
                  deviceModel: virtualDevice.deviceModel,
                  serialNumber,
                },
              },
              {
                onSuccess: handleAssignHardware,
                onError: handleError,
              },
            );
          }

          return handleError(error);
        },
        onSuccess: handleSuccess,
      },
    );
  }, [
    realm,
    hasHardwareDevice,
    assignToDeviceMutation,
    createDevDeviceMutation,
    virtualDevice.UUID,
    virtualDevice.deviceType,
    virtualDevice.deviceModel,
    onSuccess,
    serialNumber,
  ]);

  const handleUnassignHardware = useCallback(() => {
    if (!hardwareDevice || unassignHardwareDeviceMutation.isLoading) return;

    unassignHardwareDeviceMutation.mutate(
      {
        serialNumber: hardwareDevice.serialNumber,
      },
      {
        onError: (error) => {
          const graphqlError = getGraphQLError(error);
          setUnassignErrorText(graphqlError?.message ?? 'Unknown error');
        },
        onSuccess: (data) => {
          dialogProps.state.close();
          onSuccess();
          notify(
            `Successfully removed hardware device ${data.unassignHardwareDeviceFromVirtualDevice.serialNumber}.`,
            { variant: 'positive' },
          );
        },
      },
    );
  }, [hardwareDevice, unassignHardwareDeviceMutation, dialogProps.state, onSuccess]);

  const handleUpdate = useCallback(
    (input: UpdateHardwareDeviceInput) => {
      if (!hardwareDevice?.serialNumber) return;

      updateHardwareDevice.mutate(
        { serialNumber: hardwareDevice?.serialNumber, input },
        {
          onSuccess: () => {
            notify('Successfully updated hardware device.', {
              variant: 'positive',
            });
            onSuccess();
          },
          onError: (err) => {
            notify(
              `There was a problem updating the hardware device${getGraphQLErrorMessageOrEmpty(err)}.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [updateHardwareDevice, hardwareDevice?.serialNumber, onSuccess],
  );

  if (hardwareDevice) {
    return (
      <HardwareDeviceInfoSection hardwareDevice={hardwareDevice} internal={internal}>
        <>
          <Formik<UpdateHardwareDeviceInput>
            validate={toFormikValidate(UpdateHardwareDeviceInputSchema)}
            initialValues={{
              active: hardwareDevice.isActive,
            }}
            onSubmit={handleUpdate}
          >
            <UpdateHardwareFormContainer>
              <FieldProvider name="active">
                <ActiveFieldContainer>
                  <SecondaryToggleField label="Active" />
                </ActiveFieldContainer>
              </FieldProvider>
              <HStack spacing={space(6)}>
                <Button
                  variant="destructive"
                  onClick={dialogProps.state.open}
                  size="small"
                  icon="trash-can"
                  arrangement="hidden-label"
                >
                  Remove
                </Button>
                <FormlessResetButton
                  variant="secondary"
                  size="small"
                  icon="arrows-rotate"
                  arrangement="hidden-label"
                >
                  Reset
                </FormlessResetButton>
                <FormlessSubmitButton variant="primary" size="small">
                  Save
                </FormlessSubmitButton>
              </HStack>
            </UpdateHardwareFormContainer>
          </Formik>

          <Dialog state={dialogProps.state} preset="narrow">
            <DialogHeader
              heading="Delete hardware device"
              icon={deviceTypeToHardwareIconPropHardware(hardwareDevice.deviceType)}
            />
            <DialogContent>
              <DialogBodyContainer>
                <Body>
                  Are you sure you want to remove the hardware device{' '}
                  <Body weight="bold" family="monospace">
                    {hardwareDevice.serialNumber}
                  </Body>{' '}
                  from virtual device{' '}
                  <Body weight="bold" family={!virtualDevice.label ? 'monospace' : undefined}>
                    {virtualDevice.label || virtualDevice.UUID}
                  </Body>
                  ?
                </Body>

                {unassignErrorText && (
                  <Alert heading="Failed removing hardware device" copy={unassignErrorText} />
                )}
              </DialogBodyContainer>
            </DialogContent>
            <DialogFooter
              actions={
                <>
                  <Button variant="secondary" onClick={dialogProps.state.close}>
                    Cancel
                  </Button>
                  <Button type="button" variant="destructive" onClick={handleUnassignHardware}>
                    Delete
                  </Button>
                </>
              }
            />
          </Dialog>
        </>
      </HardwareDeviceInfoSection>
    );
  }

  return (
    <FieldContainer>
      <PrimaryField
        label="Serial number"
        element={<TextInput value={serialNumber} onChange={setSerialNumber} />}
        internal={internal}
      >
        <SerialNumberHardwareDeviceInfoContainer>
          <VStack spacing={space(12)}>
            <Suspense fallback={null}>
              <HardwareDeviceInfoFetcher serialNumber={serialNumber} internal={internal} />
            </Suspense>

            <HStack spacing={space(8)} justify="end">
              <Button
                type="button"
                onClick={() => {
                  setSerialNumber('');
                }}
                disabled={!serialNumber || assignToDeviceMutation.isLoading}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={handleAssignHardware}
                disabled={!serialNumber}
                loading={assignToDeviceMutation.isLoading}
                variant="secondary"
              >
                Assign to virtual device
              </Button>
            </HStack>
          </VStack>
        </SerialNumberHardwareDeviceInfoContainer>
      </PrimaryField>
    </FieldContainer>
  );
}

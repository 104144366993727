import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { DeviceConfigsQueryQuery } from '../../gql/graphql';
import { graphql } from '../../gql';

export type DeviceConfigsQueryResult = NonNullable<
  DeviceConfigsQueryQuery['deviceConfigs']
>[number];

export const DeviceConfigsQuery = graphql(`
  query DeviceConfigsQuery($serialNumber: String!) {
    deviceConfigs(serialNumber: $serialNumber) {
      version
      acknowledgedAt
      createdAt
    }
  }
`);

export const DeviceConfigByVersionQuery = graphql(`
  query DeviceConfigByVersion($serialNumber: String!, $version: Int!) {
    deviceConfigByVersion(serialNumber: $serialNumber, version: $version) {
      version
      acknowledgedAt
      configJSON
    }
  }
`);

export const renderDeviceConfigMutation = graphql(`
  mutation RenderDeviceConfig($serialNumber: String!) {
    renderDeviceConfig(serialNumber: $serialNumber) {
      version
      acknowledgedAt
      configJSON
    }
  }
`);

export function useHandleRerenderDeviceConfig(serialNumber: string | undefined) {
  const renderDeviceConfig = useGraphQLMutation(renderDeviceConfigMutation);
  const queryClient = useQueryClient();

  const handleRerender = useCallback(() => {
    if (!serialNumber) return;
    renderDeviceConfig.mutate(
      { serialNumber },
      {
        onSuccess() {
          notify('Successfully rerendered device config.', {
            variant: 'positive',
          });
          queryClient.invalidateQueries(makeQueryKey(DeviceConfigsQuery, { serialNumber }));
        },
        onError(err) {
          notify(
            `There was a problem rerendering device config${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [renderDeviceConfig, serialNumber, queryClient]);

  return { renderDeviceConfig, handleRerender };
}

import { DateTime } from 'luxon';

import type { NetworkForOnboardingDocumentQuery } from '../../../../gql/graphql';
import { graphql } from '../../../../gql';

export function fmtISODateString<T extends string | null>(date: T) {
  if (date === null) return null as T;
  const dt = DateTime.fromISO(date).startOf('day');
  return dt.toLocaleString(DateTime.DATE_MED) as T;
}

export const upsertOnboardingFieldDelegationMutation = graphql(`
  mutation UpsertNetworkOnboardingFieldDelegation(
    $networkUUID: UUID!
    $fieldName: String!
    $input: UpsertNetworkOnboardingFieldDelegationInput!
  ) {
    upsertNetworkOnboardingFieldDelegation(
      networkUUID: $networkUUID
      fieldName: $fieldName
      input: $input
    ) {
      UUID
    }
  }
`);

export const deleteOnboardingFieldDelegationMutation = graphql(`
  mutation DeleteNetworkOnboardingFieldDelegation($uuid: UUID!) {
    deleteNetworkOnboardingFieldDelegation(UUID: $uuid) {
      UUID
    }
  }
`);

export type OnboardingFieldDelegation = NonNullable<
  NetworkForOnboardingDocumentQuery['network']['onboardingDocument']
>['delegations'][number];

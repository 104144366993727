import {
  Body,
  darkThemeSelector,
  Pane,
  PaneContent,
  PaneHeader,
  Section,
  SectionContent,
  SectionHeader,
  Small,
} from '@meterup/atto';
import { AutoTable, isDefinedAndNotEmpty, ISPStatusBadge, NeutralBadge } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import type { SSIDData } from '../../../api/types';
import { fetchControllerSSIDs, fetchProviders } from '../../../api/api';
import { useAPIClient } from '../../../providers/APIClientProvider';
import { useCurrentControllerData } from '../../../providers/CurrentControllerProvider';
import { colors, fontWeights, styled } from '../../../stitches';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { UploadDownloadStat } from './UploadDownloadStat';

const ConnectionGrid = styled('div', {
  display: 'table',
  width: '100%',
});

const ConnectionRow = styled('div', {
  display: 'table-row',
  strokeTopBottom: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    strokeTopBottom: colors.strokeNeutralDark,
  },
});

const ConnectionCell = styled('div', Body, {
  display: 'table-cell',
  verticalAlign: 'middle',
  padding: '$12 $8',
  cursor: 'pointer',

  '&:first-child': {
    paddingLeft: '$16',
  },
  '&:last-child': {
    paddingRight: '$16',
  },
});

const Stat = styled('div', {
  vStack: '$4',
  alignItems: 'stretch',
});

const PrimaryStatLabel = styled('div', Body, {
  fontWeight: fontWeights.bold,
  color: colors.gray700,
});

const StatLabel = styled('div', Small);

const StatValue = styled('div', {
  hStack: 0,
});

const bldr = createColumnBuilder<SSIDData>();

const columns = [
  bldr.data((d) => d.ssid, {
    id: 'ssid',
    header: 'SSID',
    meta: {
      isLeading: true,
    },
  }),
  bldr.data((d) => d.type, {
    id: 'type',
    header: 'Type',
    cell: (p) => <NeutralBadge ends="card">{p.value}</NeutralBadge>,
  }),
];

function InternetAndWireless() {
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const api = useAPIClient();

  const providers =
    useQuery(['providers'], fetchProviders, {
      suspense: true,
    }).data ?? [];

  const connections =
    useQuery(['network_isp_info', controllerName], () => api.isps(), {
      suspense: true,
    }).data ?? [];

  const networkInfo =
    useQuery(['controller_ssids', controllerName], () => fetchControllerSSIDs(controllerName), {
      suspense: true,
    }).data ?? [];

  return (
    <Pane>
      <PaneHeader icon="globe" heading="Internet & wireless" />
      <PaneContent>
        {connections.length > 0 && (
          <Section relation="stacked">
            <SectionHeader heading="Internet connections" />
            <SectionContent>
              <ConnectionGrid>
                {connections.map((plan) => {
                  const path = providers.find((p) => p.sid === plan.provider)?.path;
                  return (
                    <ConnectionRow key={plan.sid}>
                      <ConnectionCell>
                        {isDefinedAndNotEmpty(path) && (
                          <img
                            crossOrigin="anonymous"
                            src={`//public.meter.com${path}`}
                            alt={plan.provider}
                          />
                        )}
                      </ConnectionCell>
                      <ConnectionCell>
                        <Stat>
                          <PrimaryStatLabel>{plan.provider_name}</PrimaryStatLabel>
                          <StatValue>
                            <ISPStatusBadge status={plan.status} />
                          </StatValue>
                        </Stat>
                      </ConnectionCell>
                      <ConnectionCell>
                        <Stat>
                          <StatLabel>Bandwidth</StatLabel>
                          <StatValue>
                            <UploadDownloadStat
                              downloadKbps={plan.download_kbps}
                              uploadKbps={plan.upload_kbps}
                            />
                          </StatValue>
                        </Stat>
                      </ConnectionCell>
                    </ConnectionRow>
                  );
                })}
              </ConnectionGrid>
            </SectionContent>
          </Section>
        )}
        <Section relation="stacked">
          <SectionHeader heading="SSIDs" />
          <SectionContent>
            <AutoTable columns={columns} data={networkInfo} />
          </SectionContent>
        </Section>
      </PaneContent>
    </Pane>
  );
}

export default InternetAndWireless;

import type { SortingState } from '@meterup/common';
import {
  ComboBox,
  ComboBoxItem,
  EmptyState,
  Pane,
  PaneContent,
  PaneHeader,
  space,
  Tab,
  useDialogState,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { GraphQLErrorBoundary, useGraphQL } from '@meterup/graphql';
import { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AccessPointQueryQuery, VirtualDevice } from '../../../gql/graphql';
import { paths } from '../../../constants';
import { DeviceType, PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import DeviceConfigEditorDialog from '../../../routes/pages/network/operators/device_config/DeviceConfigEditorDialog';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useHardwareCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { ACSDialog, ACSJobType } from '../../ACS/ACSDialog';
import { KeyBoundSearchInput } from '../../KeyBoundSearchInput';
import { ObjectStatus } from '../../Object/ObjectStatus';
import IsPermitted from '../../permissions/IsPermitted';
import { StackedSkeletons } from '../../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../../ReactRouterLink';
import {
  AccessPointQuery,
  AccessPointsQuery,
  fallbackAccessPointHardwareDeviceQuery,
  ValidAPDetailsTabs,
} from '../../Wireless/utils';
import { useSiblings } from '../utils';
import { AccessPointActions } from './AccessPointActions';
import AccessPointBootHistory from './AccessPointBootHistory';
import AccessPointBSSIDsList from './AccessPointBSSIDsList';
import AccessPointClientList from './AccessPointClientList';
import AccessPointConnectionEvents from './AccessPointConnectionEvents';
import AccessPointFilters from './AccessPointFilters';
import AccessPointInsights from './AccessPointInsights';
import { AccessPointRebootDialog } from './AccessPointRebootDialog';
import AccessPointScanDataList from './AccessPointScanDataList';

type AccessPointViewProps = {
  uuid: string;
  tab: ValidAPDetailsTabs;
};

function AccessPointTabs({
  activeTab,
  setActiveTab,
}: {
  activeTab: ValidAPDetailsTabs;
  setActiveTab: (val: ValidAPDetailsTabs) => void;
}) {
  return (
    <>
      <Tab
        icon="reporting"
        selected={activeTab === ValidAPDetailsTabs.Insights}
        onClick={() => setActiveTab(ValidAPDetailsTabs.Insights)}
      >
        Insights
      </Tab>
      <Tab
        icon="connection-events"
        selected={activeTab === ValidAPDetailsTabs.ConnectionEvents}
        onClick={() => setActiveTab(ValidAPDetailsTabs.ConnectionEvents)}
      >
        Connection events
      </Tab>
      <Tab
        icon="client"
        selected={activeTab === ValidAPDetailsTabs.Clients}
        onClick={() => setActiveTab(ValidAPDetailsTabs.Clients)}
      >
        Clients
      </Tab>
      <Tab
        icon="mac-address"
        selected={activeTab === ValidAPDetailsTabs.BSSIDs}
        onClick={() => setActiveTab(ValidAPDetailsTabs.BSSIDs)}
      >
        BSSIDs
      </Tab>
      <IsPermitted permissions={PermissionType.PermNetworkDevicesReadRestricted}>
        <Tab
          icon="list"
          selected={activeTab === ValidAPDetailsTabs.BootHistory}
          onClick={() => setActiveTab(ValidAPDetailsTabs.BootHistory)}
          internal
        >
          Boot history
        </Tab>
      </IsPermitted>
      <IsPermitted permissions={PermissionType.PermAutoSelectionJobRead}>
        <Tab
          icon="access-point-scan"
          selected={activeTab === ValidAPDetailsTabs.ScanData}
          onClick={() => setActiveTab(ValidAPDetailsTabs.ScanData)}
          internal
        >
          Scan data
        </Tab>
      </IsPermitted>
    </>
  );
}

function AccessPointDetailActions({
  serialNumber,
  status,
  activeTab,
  uuid,
}: {
  serialNumber: string;
  status: 'online' | 'offline';
  activeTab: ValidAPDetailsTabs;
  uuid: string;
}) {
  return (
    <>
      <AccessPointActions view="detail" uuid={uuid} serialNumber={serialNumber} status={status} />
      {activeTab !== ValidAPDetailsTabs.Insights && (
        <KeyBoundSearchInput
          searchParamStateKey="filter"
          placeholder="..."
          aria-label={`Filter ${activeTab}`}
          scope="scoped"
          minWidth="56px"
        />
      )}
    </>
  );
}

export function getAccessPointStatus(virtualDevice: AccessPointQueryQuery['virtualDevice']) {
  if (!virtualDevice.hardwareDevice) return undefined;

  return virtualDevice.hardwareDevice.isConnectedToBackend ? 'online' : 'offline';
}

export default function AccessPoint({ uuid, tab }: AccessPointViewProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const hardwareCrumb = useHardwareCrumbs();
  const sortedSiblings = useSiblings({
    query: AccessPointsQuery,
    deviceType: DeviceType.AccessPoint,
  });
  const navigate = useNavigate();
  const rebootDialogProps = useDialogState();
  const configDialogProps = useDialogState();
  const acsDialogProps = useDialogState();
  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const virtualDevice = useGraphQL(
    AccessPointQuery,
    {
      uuid,
      includeUptime,
    },
    { useErrorBoundary: false },
  ).data?.virtualDevice;
  const hardwareDeviceResponse = useGraphQL(
    fallbackAccessPointHardwareDeviceQuery,
    { serialNumber: uuid },
    { useErrorBoundary: false, enabled: !virtualDevice },
  );

  const [clientsSortingState, setClientsSortingState] = useSearchParamsState<SortingState>('sort', [
    { id: 'client', desc: false },
  ]);

  const navigateToTab = useCallback(
    (newTab: ValidAPDetailsTabs) => {
      navigate(
        makeLink(paths.pages.AccessPointPage, {
          companyName,
          networkSlug: network.slug,
          uuid,
          tab: newTab,
        }),
      );
    },
    [navigate, companyName, network.slug, uuid],
  );

  const navigateToAccessPoint = useCallback(
    (newUUID: string) => {
      navigate(
        makeLink(paths.pages.AccessPointPage, {
          companyName,
          networkSlug: network.slug,
          uuid: newUUID,
          tab,
        }),
      );
    },
    [navigate, companyName, network.slug, tab],
  );

  if (!virtualDevice && hardwareDeviceResponse.isLoading) {
    // This won't be visible for more than an instant until the suspense boundary kicks in
    // (if at all)
    return null;
  }

  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Access point not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('Access point not found on this network');
  }
  const status = getAccessPointStatus(virtualDevice);

  return (
    <>
      <Pane layoutMode="detailed">
        <PaneHeader
          back={back}
          home={home}
          crumbs={[
            ...hardwareCrumb,
            {
              type: 'page',
              page: {
                as: ReactRouterLink,
                to: makeLink(paths.pages.AccessPointsListPage, {
                  companyName,
                  networkSlug: network.slug,
                }),
                label: 'Access points',
              },
            },
          ]}
          switcher={
            <ComboBox
              maxWidth="100%"
              icon="access-point"
              value={virtualDevice.UUID}
              onValueChange={navigateToAccessPoint}
              size="small"
            >
              {sortedSiblings.map((s: VirtualDevice) => (
                <ComboBoxItem key={s.UUID} textValue={s.label}>
                  {s.label}
                </ComboBoxItem>
              ))}
            </ComboBox>
          }
          icon="access-point"
          heading={virtualDevice.label}
          badges={status && <ObjectStatus size="small" status={status} />}
          tabs={<AccessPointTabs activeTab={tab} setActiveTab={navigateToTab} />}
          contentActions={
            virtualDevice.hardwareDevice ? (
              <AccessPointDetailActions
                serialNumber={virtualDevice.hardwareDevice?.serialNumber}
                status={status === 'online' ? 'online' : 'offline'}
                uuid={uuid}
                activeTab={tab}
              />
            ) : undefined
          }
          views={tab === 'insights' && <AccessPointFilters uuid={uuid} />}
        />
        <Suspense
          fallback={
            <PaneContent gutter="all">
              <StackedSkeletons />
            </PaneContent>
          }
        >
          {tab === 'insights' && (
            <PaneContent gutter="none">
              <AccessPointInsights uuid={uuid} />
            </PaneContent>
          )}
          {tab === 'clients' && (
            <AccessPointClientList
              uuid={uuid}
              view="full"
              globalFilter={globalFilter}
              sortingState={clientsSortingState}
              setSortingState={setClientsSortingState}
            />
          )}
          {tab === ValidAPDetailsTabs.BSSIDs && (
            <PaneContent gutter="none" spacing={space(0)}>
              <AccessPointBSSIDsList virtualDeviceUUID={uuid} globalFilter={globalFilter} />
            </PaneContent>
          )}
          {tab === 'boot-history' &&
            (virtualDevice.hardwareDevice ? (
              <PaneContent gutter="none" spacing={space(0)}>
                <AccessPointBootHistory
                  serialNumber={virtualDevice.hardwareDevice.serialNumber}
                  globalFilter={globalFilter}
                />
              </PaneContent>
            ) : (
              <EmptyState heading="No data available" />
            ))}
          {tab === ValidAPDetailsTabs.ScanData && (
            <PaneContent gutter="none" spacing={space(0)}>
              <AccessPointScanDataList virtualDeviceUUID={uuid} globalFilter={globalFilter} />
            </PaneContent>
          )}
          {tab === ValidAPDetailsTabs.ConnectionEvents && (
            <AccessPointConnectionEvents uuid={uuid} globalFilter={globalFilter} />
          )}
        </Suspense>
      </Pane>
      <IsPermitted permissions={PermissionType.PermAutoSelectionJobWrite}>
        <ACSDialog
          state={acsDialogProps.state}
          label={virtualDevice.label}
          identifier={virtualDevice.UUID}
          jobType={ACSJobType.AccessPoint}
        />
      </IsPermitted>
      <IsPermitted permissions={PermissionType.PermNetworkDevicesWrite}>
        {virtualDevice.hardwareDevice && (
          <AccessPointRebootDialog
            state={rebootDialogProps.state}
            serialNumber={virtualDevice.hardwareDevice.serialNumber}
            label={virtualDevice.label}
          />
        )}
      </IsPermitted>
      <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
        {virtualDevice.hardwareDevice && (
          <GraphQLErrorBoundary fallback={() => null}>
            <Suspense fallback={null}>
              <DeviceConfigEditorDialog
                state={configDialogProps.state}
                serialNumber={virtualDevice.hardwareDevice.serialNumber}
              />
            </Suspense>
          </GraphQLErrorBoundary>
        )}
      </IsPermitted>
    </>
  );
}

import type { OutletProps, OverlayTriggerState, SlotConnectionProps } from '@meterup/atto';
import type { To } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Device,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  EmptyState,
  HStack,
  Icon,
  Legends,
  Outlet,
  Slots,
  space,
  styled,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  ToggleInput,
  useDialogState,
  Visualizer,
  VStack,
} from '@meterup/atto';
import { colors, darkThemeSelector } from '@meterup/atto/src/stitches.config';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable } from '@meterup/common';
import { notify } from '@meterup/connect-ui/src/components/stolenFromFrontends/notify';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../../constants';
import {
  type OutletsQueryQuery,
  type UpdateOutletInterfaceInput,
  VirtualDeviceType,
} from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../../utils/types';
import { NoValue } from '../../NoValue';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { PowerDistributionUnitOutletsQuery, updateOutletInterfaceMutation } from './utils';

const Outlets = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

const OutletTableContainer = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  background: colors.bgApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },
});

type ColumnData = {
  virtualDeviceUUID: string;
};

const builder = createColumnBuilder<
  OutletsQueryQuery['outletInterfacesForVirtualDevice'][number] & ColumnData
>();

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 3,
});

type OutletDisableDialogProps = {
  outlet: OutletsQueryQuery['outletInterfacesForVirtualDevice'][number];
  state: OverlayTriggerState;
  onOutletDisable: () => void;
  error: Error | null;
};

export default function OutletDisableDialog({
  outlet,
  state,
  onOutletDisable,
  error,
}: OutletDisableDialogProps) {
  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="warning" heading="Disable outlet" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to disable the outlet <Text weight="bold">{outlet.label}</Text> from your
              power distribution unit.
            </>
          }
        />
        <SummaryList gutter="vertical">
          {outlet?.connectedVirtualDevice && (
            <SummaryListRow>
              <SummaryListKey>Affected device</SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  <Badge
                    variant="neutral"
                    size="small"
                    icon="virtual-device"
                    arrangement="leading-icon"
                  >
                    {outlet.connectedVirtualDevice.label}
                  </Badge>
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
        {error && (
          <Alert
            icon="warning"
            variant="negative"
            copy={`There was an error deleting the AutoVPN group${getGraphQLErrorMessageOrEmpty(error)}.`}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={state.close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={onOutletDisable} variant="destructive">
              Disable
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

function OutletEnableToggle({
  virtualDeviceUUID,
  outlet,
}: {
  virtualDeviceUUID: string;
  outlet: OutletsQueryQuery['outletInterfacesForVirtualDevice'][number];
}) {
  const outletDialogProps = useDialogState();
  const { close } = outletDialogProps.state;

  const queryClient = useQueryClient();
  const updateMutation = useGraphQLMutation(updateOutletInterfaceMutation);
  const { mutate, error } = updateMutation;

  const updateOutlet = useCallback(
    (uuid: string, input: UpdateOutletInterfaceInput) =>
      mutate(
        {
          uuid: outlet.UUID,
          input,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              makeQueryKey(PowerDistributionUnitOutletsQuery, { virtualDeviceUUID }),
            );
            notify('Successfully updated outlet configuration.', {
              variant: 'positive',
            });
            close();
          },
          onError: (err) => {
            notify(
              `There was a problem updating the outlet configuration${getGraphQLErrorMessageOrEmpty(err)}.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      ),
    [outlet, virtualDeviceUUID, queryClient, mutate, close],
  );
  const handleChange = useCallback(
    (isSelected: boolean) => {
      if (!isSelected) {
        outletDialogProps.openFromMenu();
      } else {
        updateOutlet(outlet.UUID, {
          isEnabled: isSelected,
        });
      }
    },
    [outlet.UUID, outletDialogProps, updateOutlet],
  );
  const onOutletDisable = useCallback(() => {
    updateOutlet(outlet.UUID, {
      isEnabled: false,
    });
  }, [outlet.UUID, updateOutlet]);
  return (
    <>
      <OutletDisableDialog
        outlet={outlet}
        state={outletDialogProps.state}
        error={error}
        onOutletDisable={onOutletDisable}
      />
      <ToggleInput
        onChange={handleChange}
        selected={outlet.isEnabled}
        aria-label="Enable/disable outlet"
        controlSize="small"
      />
    </>
  );
}

const columns = [
  builder.data((row) => row.label ?? `Outlet: ${row.label}`, {
    id: 'outlet-status',
    header: '#',
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: { contents: 'Outlet number' },
    },
    cell: (props) => {
      const { isEnabled, outletNumber, powerWatts, connectedVirtualDevice } = props.row;
      if (isEnabled && powerWatts) {
        return <Outlet variant="simple" number={outletNumber} status="connected" />;
      }
      if (isEnabled && !connectedVirtualDevice) {
        return <Outlet variant="simple" number={outletNumber} status="disconnected" />;
      }
      return <Outlet variant="simple" number={outletNumber} disabled />;
    },
  }),
  builder.data((row) => (row.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'outlet-enabled',
    header: 'Enabled',
    meta: {
      width: 72,
    },
    // eslint-disable-next-line react/jsx-no-useless-fragment
    cell: (props) => {
      const { virtualDeviceUUID, ...restOutlet } = props.row;
      return <OutletEnableToggle virtualDeviceUUID={virtualDeviceUUID} outlet={restOutlet} />;
    },
  }),
  builder.data((row) => row.label ?? `Outlet: ${row.label}`, {
    id: 'outlet-label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.label ?? `Outlet: ${row.label}`, {
    id: 'outlet-connected-device',
    header: 'Connected',
    cell: (props) => {
      const { connectedVirtualDeviceUUID, connectedVirtualDevice } = props.row;
      if (connectedVirtualDeviceUUID && connectedVirtualDevice) {
        return (
          <HStack align="center" spacing={space(4)}>
            <Icon
              icon={deviceTypeToHardwareIconPropHardware(connectedVirtualDevice.deviceType)}
              size={16}
            />
            <VStack>{connectedVirtualDevice.label || connectedVirtualDevice.UUID}</VStack>
          </HStack>
        );
      }
      return <NoValue />;
    },
  }),
  builder.data((row) => row.label ?? `Outlet: ${row.label}`, {
    id: 'outlet-consumption-watts',
    header: 'Consumption',
    cell: (props) => {
      const { powerWatts } = props.row;
      if (powerWatts) {
        return <>{formatter.format(powerWatts)}W</>;
      }
      return <NoValue />;
    },
  }),
  builder.data((row) => row.label ?? `Outlet: ${row.label}`, {
    id: 'outlet-consumption-amps',
    header: 'Current',
    cell: (props) => {
      const { currentAmps } = props.row;
      if (currentAmps) {
        return <>{formatter.format(currentAmps)}A</>;
      }
      return <NoValue />;
    },
  }),
];

export function PowerDistributionUnitOutlets({ virtualDeviceUUID }: { virtualDeviceUUID: string }) {
  // const onRowDeselect = useCloseDrawerCallback();

  const isOperator = useIsOperator({ respectDemoMode: true });
  const network = useNetwork();
  const networkSlug = network.slug;
  const companyName = useCurrentCompany();
  const detailParams = Nav.useRegionParams('drawer', paths.drawers.OutletDetailPage);
  const switchParams = Nav.useRegionParams('drawer', paths.drawers.SwitchSummaryPage);
  const saParams = Nav.useRegionParams('drawer', paths.drawers.SecurityApplianceSummaryPage);
  const pduParams = Nav.useRegionParams('drawer', paths.drawers.PowerDistributionUnitSummaryPage);
  // const batchParams = Nav.useRegionParams('drawer', paths.drawers.OutletBatchEditPage);
  const navigate = useNavigate();

  const outlets = useGraphQL(PowerDistributionUnitOutletsQuery, {
    virtualDeviceUUID,
  })?.data?.outletInterfacesForVirtualDevice;

  const parsedOutlets = useMemo(
    () =>
      outlets?.map((outlet) => ({
        virtualDeviceUUID,
        ...outlet,
      })),
    [virtualDeviceUUID, outlets],
  );

  const getConnectedDeviceLink = useCallback(
    (
      connectedVirtualDevice: OutletsQueryQuery['outletInterfacesForVirtualDevice'][number]['connectedVirtualDevice'],
    ): To | undefined => {
      if (connectedVirtualDevice) {
        if (connectedVirtualDevice.deviceType === VirtualDeviceType.Controller) {
          return makeDrawerLink(window.location, paths.drawers.SecurityApplianceSummaryPage, {
            companyName,
            networkSlug: network.slug,
            uuid: connectedVirtualDevice.UUID,
          });
        }
        if (connectedVirtualDevice.deviceType === VirtualDeviceType.Switch) {
          return makeDrawerLink(window.location, paths.drawers.SwitchSummaryPage, {
            companyName,
            networkSlug: network.slug,
            uuid: connectedVirtualDevice.UUID,
          });
        }
        if (connectedVirtualDevice.deviceType === VirtualDeviceType.PowerDistributionUnit) {
          return makeDrawerLink(window.location, paths.drawers.PowerDistributionUnitSummaryPage, {
            companyName,
            networkSlug: network.slug,
            uuid: connectedVirtualDevice.UUID,
          });
        }
      }
      return undefined;
    },
    [companyName, network?.slug],
  );

  const checkConnectionIsActive = useCallback(
    (outlet: OutletsQueryQuery['outletInterfacesForVirtualDevice'][number]) => {
      if (outlet.connectedVirtualDevice) {
        const device = outlet.connectedVirtualDevice;
        return Boolean(
          (switchParams && switchParams.uuid === device?.UUID) ||
            (saParams && saParams.uuid === device?.UUID) ||
            (pduParams && pduParams.uuid === device?.UUID),
        );
      }
      return false;
    },
    [switchParams, saParams, pduParams],
  );

  const outletProps: OutletProps &
    { active?: boolean; connection?: SlotConnectionProps; onClick?: (event: any) => void }[] =
    useMemo(
      () =>
        outlets?.map((outlet) => ({
          number: outlet.outletNumber,
          disabled: !outlet.isEnabled,
          status: outlet.isEnabled && outlet?.powerWatts ? 'connected' : 'disconnected',
          ...(outlet?.connectedVirtualDevice?.deviceType
            ? {
                connection: {
                  hardware: deviceTypeToHardwareIconPropHardware(
                    outlet.connectedVirtualDevice.deviceType,
                  ),
                  label: outlet.connectedVirtualDevice.label,
                  onClick: () => {
                    const link = getConnectedDeviceLink(outlet.connectedVirtualDevice);
                    if (link) {
                      navigate(link);
                    }
                  },
                  active: checkConnectionIsActive(outlet),
                },
              }
            : {}),
          onClick: () =>
            navigate(
              makeDrawerLink(window.location, paths.drawers.OutletDetailPage, {
                networkSlug: network.slug,
                companyName,
                uuid: virtualDeviceUUID,
                phyInterfaceUUID: outlet.UUID,
              }),
            ),
          active: detailParams?.phyInterfaceUUID === outlet.UUID,
        })) ?? [],
      [
        outlets,
        navigate,
        companyName,
        network.slug,
        virtualDeviceUUID,
        detailParams?.phyInterfaceUUID,
        getConnectedDeviceLink,
        checkConnectionIsActive,
      ],
    );

  return (
    <Outlets>
      {parsedOutlets?.length ? (
        <>
          <Visualizer
            visuals={
              <Device
                slots={
                  <Slots
                    start={1}
                    rows={1}
                    slots={outletProps.map(({ active, connection, onClick, ...outlet }) => ({
                      diagram: <Outlet orientation="right" {...outlet} />,
                      connection,
                      onClick,
                      active,
                    }))}
                  />
                }
              />
            }
            status={
              <Legends
                legends={[
                  { variant: 'neutral', label: 'Disconnected' },
                  { variant: 'positive', label: 'Connected' },
                  { disabled: true, label: 'Disabled' },
                ]}
              />
            }
          />
          <OutletTableContainer>
            <AutoTable
              columns={columns}
              data={parsedOutlets}
              getLinkTo={({ UUID }) =>
                makeDrawerLink(window.location, paths.drawers.OutletDetailPage, {
                  companyName,
                  networkSlug,
                  uuid: virtualDeviceUUID,
                  phyInterfaceUUID: UUID,
                })
              }
              isRowSelected={(phyInterface) => phyInterface.UUID === detailParams?.phyInterfaceUUID}
              enableMultiRowSelection={isOperator}
              getRowId={(r) => r.UUID}
            />
          </OutletTableContainer>
        </>
      ) : (
        <EmptyState heading="No outlets for selected power distribution unit" icon="pdu" />
      )}
    </Outlets>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import EditFirewallRuleDrawer, {
  firewallRuleQuery,
} from '../../../../../components/Firewall/EditFirewallRuleDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall-rules/:ruleUUID',
  title: 'Edit rule - Rules - Firewall',
});

export default function EditFirewallRulePage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditFirewallRulePage);

  const rule = checkDefinedOrThrow(
    useGraphQL(firewallRuleQuery, {
      uuid: drawerParams?.ruleUUID!,
    })?.data?.firewallRule,
    new ResourceNotFoundError('Firewall rule not found'),
  );

  return (
    <IsPermitted
      isPermitted={({ ldFlags, nosFlags, permissions }) =>
        Boolean(
          ldFlags['firewall-rules'] &&
            nosFlags[NosFeature.COS2] &&
            permissions.hasPermission(PermissionType.PermFirewallWrite),
        )
      }
    >
      <EditFirewallRuleDrawer rule={rule} />
    </IsPermitted>
  );
}

import type { SVGProps } from 'react';
import * as React from 'react';

function CloudflareSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="31"
      height="14"
      viewBox="0 0 31 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.1648 13.7963L21.3231 13.2487C21.511 12.5979 21.4415 11.9972 21.1249 11.5544C20.829 11.1471 20.3467 10.908 19.7564 10.8799L8.58222 10.7383C8.54716 10.7378 8.51274 10.7288 8.48189 10.7122C8.45105 10.6955 8.4247 10.6717 8.40508 10.6427C8.38546 10.6137 8.37316 10.5804 8.36923 10.5457C8.3653 10.5109 8.36985 10.4757 8.38249 10.4431C8.40148 10.3884 8.43614 10.3406 8.48217 10.3054C8.5282 10.2703 8.58357 10.2494 8.6414 10.2453L19.9236 10.0977C21.2551 10.0372 22.7108 8.95394 23.2183 7.63156L23.8603 5.95497C23.8786 5.90792 23.8876 5.85782 23.887 5.80738C23.8862 5.78062 23.8832 5.75396 23.8781 5.72768C23.5278 4.16822 22.6762 2.76568 21.4531 1.73361C20.2299 0.701547 18.7022 0.0965007 17.1025 0.0106012C15.5029 -0.0752984 13.9189 0.362655 12.5917 1.25778C11.2646 2.1529 10.2671 3.45615 9.75097 4.96909C9.20312 4.56286 8.54266 4.33573 7.86026 4.31888C7.17785 4.30204 6.50694 4.4963 5.93962 4.87501C5.3723 5.25371 4.93637 5.79829 4.69168 6.43401C4.44698 7.06972 4.40551 7.76541 4.57295 8.42557C3.90815 8.44272 3.2546 8.60057 2.65557 8.88869C2.05654 9.17681 1.52571 9.58861 1.09824 10.0968C0.67078 10.605 0.356434 11.198 0.176029 11.8366C-0.00437523 12.4751 -0.0467209 13.1446 0.0517972 13.8007C0.0589248 13.8525 0.0845506 13.8999 0.123963 13.9344C0.163375 13.9688 0.213924 13.9879 0.266315 13.9881H20.903C20.9615 13.9887 21.0187 13.9703 21.0659 13.9357C21.1132 13.9011 21.1478 13.8522 21.1648 13.7963Z"
        fill="#F78100"
      />
      <path
        d="M24.8886 6.08189C24.7899 6.08189 24.6864 6.08484 24.5779 6.09075C24.5616 6.0912 24.5455 6.09471 24.5305 6.10108C24.5035 6.11001 24.4791 6.12546 24.4595 6.14606C24.4399 6.16665 24.4257 6.19176 24.4181 6.21915L23.9743 7.73339C23.7849 8.38425 23.8544 8.98493 24.1725 9.42769C24.3332 9.6372 24.5401 9.807 24.7772 9.92396C25.0142 10.0409 25.2751 10.1019 25.5395 10.1022L27.9229 10.2497C27.9562 10.2508 27.9888 10.2596 28.0181 10.2755C28.0474 10.2914 28.0726 10.3139 28.0915 10.3413C28.1117 10.3705 28.1244 10.4041 28.1288 10.4393C28.1332 10.4745 28.129 10.5102 28.1167 10.5434C28.0977 10.5981 28.063 10.646 28.017 10.6811C27.971 10.7163 27.9156 10.7372 27.8578 10.7412L25.3827 10.8888C24.0379 10.9508 22.5895 12.0326 22.0821 13.3535L21.9031 13.8199C21.8956 13.8394 21.8928 13.8604 21.895 13.8812C21.8972 13.902 21.9043 13.922 21.9157 13.9395C21.9271 13.9571 21.9424 13.9717 21.9606 13.9822C21.9787 13.9927 21.999 13.9988 22.0199 13.9999H30.5459C30.5979 14.0012 30.6488 13.9848 30.6902 13.9534C30.7315 13.922 30.761 13.8775 30.7738 13.8273C31.0279 12.9211 31.0686 11.9687 30.8925 11.0443C30.7165 10.1199 30.3285 9.24868 29.7589 8.49871C29.1893 7.74873 28.4536 7.14033 27.6092 6.72107C26.7649 6.3018 25.8347 6.08305 24.8915 6.08189H24.8886Z"
        fill="#FCAD32"
      />
    </svg>
  );
}

export default CloudflareSVG;

import { Badge, Section, SectionContent, SectionHeader, space, SummaryList } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';

import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../../hooks/useNosFeatures';
import { NOSVersion } from '../../../NosVersions/EditNosUpgrade';
import { NosVersionQuery } from '../../../NosVersions/utils';
import { WorkbenchPaneContent } from '../WorkbenchPane';

function NetworkConfigVersionsBadge({ config2 }: { config2: boolean }) {
  return (
    <Badge ends="card" size="medium" variant={config2 ? 'positive' : 'neutral'}>
      {config2 ? 'Config 2' : 'Config 1'}
    </Badge>
  );
}

export function NetworkConfigVersions() {
  const [isCOS2Enabled, isWOS2Enabled, isSOS2Enabled, isPOS2Enabled] = useNosFeaturesEnabled([
    NosFeature.COS2,
    NosFeature.WOS2,
    NosFeature.SOS,
    NosFeature.POS,
  ]);

  return (
    <Section relation="stacked" size="x-small">
      <SectionHeader heading="Versions" />
      <SectionContent gutter="all">
        <SummaryList
          direction="row"
          gutter="none"
          pairs={[
            {
              label: 'COS',
              value: <NetworkConfigVersionsBadge config2={isCOS2Enabled} />,
            },
            {
              label: 'POS',
              value: <NetworkConfigVersionsBadge config2={isPOS2Enabled} />,
            },
            {
              label: 'SOS',
              value: <NetworkConfigVersionsBadge config2={isSOS2Enabled} />,
            },
            {
              label: 'WOS',
              value: <NetworkConfigVersionsBadge config2={isWOS2Enabled} />,
            },
          ]}
        />
      </SectionContent>
    </Section>
  );
}

export default function NetworkInsights() {
  const network = useNetwork();
  const nosVersion = useGraphQL(NosVersionQuery, { id: network.pinnedNOSVersionID }).data
    ?.nosVersions?.[0];

  return (
    <WorkbenchPaneContent gutter="vertical" spacing={space(12)}>
      {nosVersion && (
        <SummaryList
          pairs={[
            {
              label: 'NOS version',
              value: <NOSVersion nosVersion={nosVersion} />,
            },
          ]}
        />
      )}
      <NetworkConfigVersions />
    </WorkbenchPaneContent>
  );
}

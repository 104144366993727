import type { SortingState } from '@tanstack/react-table';
import {
  Button,
  Icon,
  Pane,
  PaneContent,
  PaneFooter,
  PaneHeader,
  styled,
  Text,
  ToggleInput,
  ToggleInputContainer,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import {
  AutoTable,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
  truthy,
} from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { VlaNsForMulticastQuery } from '../../../gql/graphql';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { ClientAssignmentProtocol, PermissionType } from '../../../gql/graphql';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useFirewallCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { NoValue } from '../../NoValue';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';

const vlansForMulticastQuery = graphql(`
  query VLANsForMulticast($networkUUID: UUID!) {
    vlans(networkUUID: $networkUUID) {
      UUID
      name
      vlanID
      isEnabled
      isDefault
      isInternal
      isMulticastReflectionEnabled

      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength
    }
  }
`);

const updateVLANsForMulticastMutation = graphql(`
  mutation UpdateVLANsForMulticast(
    $networkUUID: UUID!
    $inputs: [UpdateVLANsIndependentlyInput!]!
  ) {
    updateVLANsIndependently(networkUUID: $networkUUID, inputs: $inputs) {
      UUID
    }
  }
`);

type VLANForMulticast = VlaNsForMulticastQuery['vlans'][number];

const builder = createColumnBuilder<VLANForMulticast>();

const baseColumns = [
  builder.data((vlan) => vlan.vlanID.toString(), {
    id: 'vlan-id',
    header: 'VLAN ID',
    meta: {
      maxWidth: 80,
    },
  }),
  builder.data((vlan) => vlan.name, {
    id: 'vlan-name',
    header: 'VLAN',
  }),
  builder.data(
    (vlan) => {
      if (vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Dhcp) {
        return vlan.ipV4ClientAssignmentProtocol;
      }
      if (vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static) {
        return [vlan.ipV4ClientGateway, vlan.ipV4ClientPrefixLength].filter(truthy).join('/');
      }
      return '';
    },
    {
      id: 'vlan-subnet',
      header: 'IP configuration',
      meta: {
        alignment: 'start',
      },
      cell: ({ value }) =>
        value.length > 0 ? <Text family="monospace">{value}</Text> : <NoValue />,
    },
  ),
  builder.data((vlan) => (vlan.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'vlan-enabled',
    header: 'VLAN enabled',
    meta: { alignment: 'center' },
    enableGlobalFilter: false,
    cell: ({ row: vlan }) => {
      if (vlan.isEnabled) {
        return <Icon icon="checkmark" size={12} />;
      }

      return null;
    },
  }),
  builder.data((vlan) => (vlan.isDefault ? 'Default' : 'Not default'), {
    id: 'vlan-default',
    header: 'Default',
    meta: { alignment: 'center' },
    enableGlobalFilter: false,
    cell: ({ row: vlan }) => {
      if (vlan.isDefault) {
        return <Icon icon="checkmark" size={12} />;
      }

      return null;
    },
  }),
  builder.data((vlan) => (vlan.isInternal ? 'Internal' : 'External'), {
    id: 'vlan-read-only',
    header: 'Read-only',
    meta: { alignment: 'center' },
    enableGlobalFilter: false,
    cell: ({ row: vlan }) => {
      if (vlan.isInternal) {
        return <Icon icon="checkmark" size={12} />;
      }

      return null;
    },
  }),
];

const ToggleInputWrapper = styled('div', {
  [`& ${ToggleInputContainer}`]: {
    display: 'inline',
  },
});
ToggleInputWrapper.displayName = 'ToggleInputWrapper';

export default function Multicast() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const firewallCrumb = useFirewallCrumbs();
  const vlans = useGraphQL(vlansForMulticastQuery, { networkUUID: network.UUID }).data?.vlans;
  expectDefinedOrThrow(vlans, new ResourceNotFoundError('Unable to load VLANs'));
  const isOperator = useIsOperator();
  const flags = useFeatureFlags();

  const updateVLANsForMulticast = useGraphQLMutation(updateVLANsForMulticastMutation);

  const [isMulticastEnabledForVLANs, setMulticastEnabledForVLANs] = useState(
    () => new Map(vlans.map((vlan) => [vlan.UUID, vlan.isMulticastReflectionEnabled])),
  );

  const { hasPermission } = usePermissions();
  const canEditMulticastReflectionEnabled =
    hasPermission(PermissionType.PermFirewallWrite) && (flags['multicast-mdns'] || isOperator);

  const resetMulticastEnabledForVLANs = useCallback(() => {
    setMulticastEnabledForVLANs(
      new Map(vlans.map((vlan) => [vlan.UUID, vlan.isMulticastReflectionEnabled])),
    );
  }, [vlans]);

  useEffect(() => {
    resetMulticastEnabledForVLANs();
  }, [resetMulticastEnabledForVLANs]);

  const columns = useMemo(
    () => [
      builder.data((vlan) => (vlan.isMulticastReflectionEnabled ? 1 : 0), {
        id: 'mdns-reflection-enabled',
        header: 'mDNS reflection',
        enableGlobalFilter: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row: vlan }) => (
          <ToggleInputWrapper>
            <ToggleInput
              disabled={!canEditMulticastReflectionEnabled || (!isOperator && !!vlan.isInternal)}
              aria-label="mDNS reflection enabled"
              selected={vlan.isMulticastReflectionEnabled}
              onChange={(isSelected) => {
                if (!isOperator && !!vlan.isInternal) {
                  return;
                }

                setMulticastEnabledForVLANs((prev) => {
                  const next = new Map(prev);
                  next.set(vlan.UUID, isSelected);
                  return next;
                });
              }}
            />
          </ToggleInputWrapper>
        ),
      }),
      ...baseColumns,
    ],
    [canEditMulticastReflectionEnabled, isOperator],
  );

  const vlansWithMulticastEnabledState = useMemo(
    () =>
      vlans.map((vlan) => ({
        ...vlan,
        isMulticastReflectionEnabled: isMulticastEnabledForVLANs.get(vlan.UUID) ?? false,
      })),
    [vlans, isMulticastEnabledForVLANs],
  );

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(() => {
    updateVLANsForMulticast.mutate(
      {
        networkUUID: network.UUID,
        inputs: Array.from(isMulticastEnabledForVLANs.entries()).map(
          ([UUID, isMulticastReflectionEnabled]) => ({
            UUID,
            input: {
              isMulticastReflectionEnabled,
            },
          }),
        ),
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(vlansForMulticastQuery, { networkUUID: network.UUID }),
          );
          notify('Successfully updated mDNS reflection.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem updating mDNS reflection${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [network.UUID, updateVLANsForMulticast, isMulticastEnabledForVLANs, queryClient]);

  return (
    <Pane contentMode="fit" layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...firewallCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.MulticastPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'mDNS',
            },
          },
        ]}
        icon="multicast-dns"
        heading="mDNS"
      />
      <PaneContent>
        <AutoTable
          columns={columns}
          sortingState={sortingState}
          onChangeSortingState={setSortingState}
          data={vlansWithMulticastEnabledState}
        />
      </PaneContent>
      <IsPermitted permissions={PermissionType.PermFirewallWrite}>
        <PaneFooter
          actions={
            <>
              <Button type="button" onClick={resetMulticastEnabledForVLANs} variant="secondary">
                Reset
              </Button>
              <Button type="button" onClick={handleSubmit} variant="primary">
                Save
              </Button>
            </>
          }
        />
      </IsPermitted>
    </Pane>
  );
}

import type { ReactNode } from 'react';
import { Box, Pane, PaneContent, PaneHeader, Skeleton, space, VStack } from '@meterup/atto';
import { ErrorBoundary } from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import { Nav } from '../nav';
import { ErrorFallback } from './ErrorFallback/ErrorFallback';
import { DetailLoadingFallback } from './Placeholders/DetailLoadingFallback';

export function DetailPageLayout({
  header,
  footer,
  main = <Outlet />,
  background = 'default',
}: {
  header?: ReactNode;
  main?: ReactNode;
  footer?: ReactNode;
  background?: 'checkered' | 'default';
}) {
  const location = Nav.useRegionLocation('root');
  return (
    <Pane layoutMode="detailed" contentMode="stretch">
      <ErrorBoundary fallback={ErrorFallback}>
        <Suspense
          fallback={<PaneHeader heading={<Skeleton height={20} width={200} radius={6} />} />}
        >
          {header}
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary fallback={ErrorFallback}>
        <Suspense
          fallback={
            <Box style={{ padding: '16px 20px' }}>
              <VStack spacing={space(16)}>
                <Skeleton height={120} width="100%" radius={6} />
                <Skeleton height={120} width="100%" radius={6} />
                <Skeleton height={120} width="100%" radius={6} />
              </VStack>
            </Box>
          }
        >
          <PaneContent background={background}>
            <QueryErrorResetBoundary
              // TRICKY: Pass key to the error boundary to force re-render when route changes
              key={location.pathname}
            >
              {({ reset }) => (
                <ErrorBoundary onReset={reset} fallback={ErrorFallback}>
                  <Suspense fallback={<DetailLoadingFallback />}>{main}</Suspense>
                </ErrorBoundary>
              )}
            </QueryErrorResetBoundary>
          </PaneContent>
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary fallback={ErrorFallback}>
        <Suspense fallback={<Skeleton width="100%" height={20} radius={6} />}>{footer}</Suspense>
      </ErrorBoundary>
    </Pane>
  );
}

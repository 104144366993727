import {
  FieldContainer,
  Link as AttoLink,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  Select,
  SelectItem,
  space,
  Text,
  TextInput,
  VStack,
} from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../../../constants';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

export default function ReportForm() {
  const companyName = useCurrentCompany();
  return (
    <>
      <PrimaryToggleField label="Enabled" />
      <PrimaryField
        label="Report"
        element={
          <Select width="100%">
            <SelectItem>ISP uptime</SelectItem>
          </Select>
        }
      />
      <FieldContainer>
        <PrimaryField
          label="Frequency"
          element={
            <VStack spacing={space(8)}>
              <Select width="100%">
                <SelectItem textValue="hourly">Hourly</SelectItem>
                <SelectItem textValue="daily">Daily</SelectItem>
                <SelectItem textValue="weekly">Weekly</SelectItem>
                <SelectItem textValue="monthly">Monthly</SelectItem>
              </Select>
            </VStack>
          }
        />
        <SecondaryField
          label="Day"
          element={
            <Select width="100%">
              <SelectItem>1st</SelectItem>
              <SelectItem>2nd</SelectItem>
              <SelectItem>3rd</SelectItem>
              <SelectItem>4th</SelectItem>
              <SelectItem>5th</SelectItem>
              <SelectItem>6th</SelectItem>
              <SelectItem>7th</SelectItem>
              <SelectItem>8th</SelectItem>
              <SelectItem>9th</SelectItem>
              <SelectItem>10th</SelectItem>
              <SelectItem>11th</SelectItem>
              <SelectItem>12th</SelectItem>
              <SelectItem>13th</SelectItem>
              <SelectItem>14th</SelectItem>
              <SelectItem>15th</SelectItem>
              <SelectItem>16th</SelectItem>
              <SelectItem>17th</SelectItem>
              <SelectItem>18th</SelectItem>
              <SelectItem>19th</SelectItem>
              <SelectItem>20th</SelectItem>
              <SelectItem>21st</SelectItem>
              <SelectItem>22nd</SelectItem>
              <SelectItem>23rd</SelectItem>
              <SelectItem>24th</SelectItem>
              <SelectItem>25th</SelectItem>
              <SelectItem>26th</SelectItem>
              <SelectItem>27th</SelectItem>
              <SelectItem>28th</SelectItem>
              <SelectItem>29th</SelectItem>
              <SelectItem>30th</SelectItem>
              <SelectItem>31st</SelectItem>
            </Select>
          }
        />
        <SecondaryField
          label="Day"
          element={
            <Select width="100%">
              <SelectItem>Monday</SelectItem>
              <SelectItem>Tuesday</SelectItem>
              <SelectItem>Wednesday</SelectItem>
              <SelectItem>Thursday</SelectItem>
              <SelectItem>Friday</SelectItem>
              <SelectItem>Saturday</SelectItem>
              <SelectItem>Sunday</SelectItem>
            </Select>
          }
        />
        <SecondaryField
          label="Time"
          element={<TextInput width="100%" type="time" value="10:00" />}
        />
      </FieldContainer>
      <PrimaryField
        label="Receiver"
        element={
          <Select width="100%">
            <SelectItem>SF office</SelectItem>
            <SelectItem>UK office</SelectItem>
          </Select>
        }
        description={
          <Text>
            Receivers can be managed in the{' '}
            <AttoLink
              as={Link}
              to={makeLink(paths.pages.ReceiversPage, {
                companyName,
              })}
            >
              Integrations
            </AttoLink>{' '}
            settings.
          </Text>
        }
      />
    </>
  );
}

import React from 'react';

import { components, MultiValueGenericProps } from 'react-select';

export function NumberedItemLabel<T>(props: MultiValueGenericProps<T, true>) {
  const { data, selectProps } = props;
  let index = 1;
  if (selectProps.value) {
    // @ts-ignore
    index = selectProps.value.findIndex((value) => value.description === data.description) + 1;
  }
  return (
    <components.MultiValueLabel
      {...props}
      innerProps={{
        ...props.innerProps,
      }}
    >
      {index}. {data.description}
    </components.MultiValueLabel>
  );
}

import {
  Button,
  ComboBox,
  ComboBoxItem,
  ControlGroup,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  Select,
  SelectItem,
  TextInput,
} from '@meterup/atto';
import React from 'react';

import { IspProduct, IspStatus } from '../../../gql/graphql';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { FieldProvider } from '../../Form/FieldProvider';
import { humanize } from '../../Hardware/SecurityAppliance/utils2';
import { useProviders } from './utils';

type ISPFormSharedProps = {
  isUseDHCP: boolean;
  onCancel?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  setIsUseDHCP: (value: boolean) => void;
  submitButtonLabel: React.ReactNode;
};

export default function ISPFormShared({
  isUseDHCP,
  onCancel,
  setIsUseDHCP,
  submitButtonLabel,
}: ISPFormSharedProps) {
  const companyName = useCurrentCompany();
  const providers = useProviders({ companySlug: companyName });
  return (
    <>
      <DrawerContent>
        <FieldProvider name="input.connection.product">
          <PrimaryField
            element={
              <Select>
                {Object.values(IspProduct).map((v) => (
                  <SelectItem key={v}>{humanize(v)}</SelectItem>
                ))}
              </Select>
            }
            label="Service level"
          />
        </FieldProvider>
        <FieldProvider name="input.connection.status">
          <PrimaryField
            element={
              <ComboBox placeholder="Select priority">
                {Object.values(IspStatus).map((v) => (
                  <ComboBoxItem key={v}>{humanize(v)}</ComboBoxItem>
                ))}
              </ComboBox>
            }
            label="Priority"
          />
        </FieldProvider>
        <FieldProvider name="input.providerUUID">
          <PrimaryField
            element={
              <ComboBox>
                {providers.map((provider) => (
                  <ComboBoxItem key={provider.UUID}>{provider.name}</ComboBoxItem>
                ))}
              </ComboBox>
            }
            label="Provider"
          />
        </FieldProvider>
        <FieldContainer>
          <PrimaryToggleField label="Enable DHCP" selected={isUseDHCP} onChange={setIsUseDHCP} />
          {isUseDHCP ? null : (
            <>
              <FieldProvider name="input.ipConfiguration.gatewayAddr">
                <SecondaryField label="Gateway" element={<TextInput />} />
              </FieldProvider>
              <FieldProvider name="input.ipConfiguration.firstUsableIP">
                <SecondaryField label="First usable IP" element={<TextInput />} />
              </FieldProvider>
              <FieldProvider name="input.ipConfiguration.lastUsableIP">
                <SecondaryField label="Last usable IP" element={<TextInput />} />
              </FieldProvider>
              <FieldProvider name="input.ipConfiguration.staticIPRange">
                <SecondaryField label="Static IP Range" element={<TextInput />} />
              </FieldProvider>
            </>
          )}
        </FieldContainer>
      </DrawerContent>
      <DrawerFooter
        actions={
          <ControlGroup relation="separate">
            <Button type="button" variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit">{submitButtonLabel}</Button>
          </ControlGroup>
        }
      />
    </>
  );
}

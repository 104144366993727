import { useGraphQL } from '@meterup/graphql';
import { keyBy } from 'lodash-es';
import { useMemo } from 'react';

import type { NosVersionsQueryQuery } from '../../../gql/graphql';
import { nosVersionsQuery } from '../../NOS/utils';

export type NosFirmwareVersionsHookValues = {
  nosVersions: NosVersionsQueryQuery['nosVersions'];
  nosVersionIdMap: Record<number, NosVersionsQueryQuery['nosVersions'][number]>;
};

export function nosVersionToLabel(
  nos: NosVersionsQueryQuery['nosVersions'][number] | null | undefined,
) {
  return nos?.version ? `${nos.version} (${nos.major}.${nos.minor}.${nos.patch})` : '-';
}

export function useNosFirmwareVersions(): NosFirmwareVersionsHookValues {
  const { data } = useGraphQL(nosVersionsQuery, {}, { suspense: false });
  const retObj = useMemo(() => {
    const filteredNosVersions =
      data?.nosVersions
        .filter((nos) => !nos.isDeprecated)
        .sort((a, b) => a.version.localeCompare(b.version)) ?? [];
    return {
      nosVersions: filteredNosVersions,
      nosVersionIdMap: keyBy(filteredNosVersions, 'id') ?? {},
    };
  }, [data]);
  return retObj;
}

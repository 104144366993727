import {
  darkThemeSelector,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  selectors,
  space,
  styled,
  Text,
} from '@meterup/atto';
import { colors } from '@meterup/atto/src/stitches.config';
import { checkDefinedOrThrow, fontWeights } from '@meterup/common';
import { useMutation } from '@tanstack/react-query';
import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router';

import type { RackElevation, RackElevationDevice } from './utils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useDesignCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { ReactRouterLink } from '../../ReactRouterLink';
import { continuousRackElevationSegments, Rack, RackMount, Racks } from './Rack';
import RackElevationsActions from './RackElevationsActions';
import { manufacturerForType, typeforAtto } from './utils';

const RackMountAddIcon = styled(Icon, {
  width: '$10',
  height: '$10',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  [selectors.hover]: {
    color: colors.bodyNeutralLight,

    [darkThemeSelector]: {
      color: colors.bodyNeutralDark,
    },
  },

  variants: {
    selected: {
      true: {
        color: colors.iconBrandLight,

        [darkThemeSelector]: {
          color: colors.iconBrandDark,
        },

        [selectors.hover]: {
          color: colors.bodyBrandLight,

          [darkThemeSelector]: {
            color: colors.bodyBrandDark,
          },
        },
      },
      false: {},
    },
  },
});

const RackMountAddLabel = styled(Text, {
  fontWeight: fontWeights.bold,
  color: colors.bodyNeutralLight,
  fontSize: '$12',
  lineHeight: '$16',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  [selectors.hover]: {
    color: colors.headingNeutralLight,

    [darkThemeSelector]: {
      color: colors.headingNeutralDark,
    },
  },

  variants: {
    selected: {
      true: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },

        [selectors.hover]: {
          color: colors.headingBrandLight,

          [darkThemeSelector]: {
            color: colors.headingBrandDark,
          },
        },
      },
      false: {},
    },
  },
});

const RackMountAddContainer = styled('div', {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$2',
  width: '100%',
  height: '100%',
});

function RackElevationView({
  elevation,
  isExporting,
}: {
  elevation: RackElevation;
  isExporting?: boolean;
}) {
  const navigate = useNavigate();
  const network = useNetwork();
  const companyName = useCurrentCompany();

  const editDeviceParams = Nav.useRegionParams('drawer', paths.drawers.EditRackMountPage);
  const addDeviceParams = Nav.useRegionParams('drawer', paths.drawers.AttachDeviceDrawerPage);
  const editNoteParams = Nav.useRegionParams('drawer', paths.drawers.EditRackNotePage);

  const items: { last: number; first: number; item: RackElevationDevice | null }[] =
    elevation.devices.map((d) => ({
      last: Math.min(...d.rackMountUnitIndexes),
      first: Math.max(...d.rackMountUnitIndexes),
      item: d,
    }));

  const segments = continuousRackElevationSegments(items, elevation.rackMountUnitCount);

  const { hasPermission } = usePermissions();
  const canWriteRackElevation = hasPermission(PermissionType.PermRackElevationWrite);

  return (
    <Rack
      uuid={elevation.UUID}
      heading={elevation.label}
      mounts={elevation.rackMountUnitCount}
      notes={elevation.notes?.map((note) => ({
        first: note.rackMountUnitIndexEnd,
        last: note.rackMountUnitIndexStart,
        note: note.note,
        active:
          !!editNoteParams?.noteIndexStart &&
          Number(editNoteParams?.noteIndexStart) === note.rackMountUnitIndexStart,
        onClick: canWriteRackElevation
          ? () => {
              navigate(
                makeDrawerLink(window.location, paths.drawers.EditRackNotePage, {
                  companyName,
                  networkSlug: network.slug,
                  elevationUUID: elevation.UUID,
                  noteIndexStart: note.rackMountUnitIndexStart.toString(),
                }),
              );
            }
          : undefined,
      }))}
    >
      {segments.map((s) => {
        if (s.item) {
          return (
            <RackMount
              key={`row-${s.last}-${s.first}`}
              first={s.first}
              last={s.last}
              type={typeforAtto(s.item.type)}
              label={s.item.label ?? undefined}
              manufacturer={manufacturerForType(s.item.type)}
              onClick={
                canWriteRackElevation
                  ? () => {
                      if (!s.item?.UUID) return;

                      navigate(
                        makeDrawerLink(window.location, paths.drawers.EditRackMountPage, {
                          companyName,
                          networkSlug: network.slug,
                          elevationUUID: elevation.UUID,
                          deviceUUID: s.item.UUID,
                        }),
                      );
                    }
                  : undefined
              }
              active={
                editDeviceParams?.elevationUUID === elevation.UUID &&
                editDeviceParams?.deviceUUID === s.item.UUID
              }
              deviceInfo={
                s.item.virtualDevice?.__typename === 'SwitchVirtualDevice'
                  ? {
                      ports: s.item.virtualDevice.phyInterfaces.reduce(
                        (acc, x) => (x.isEthernet ? acc + 1 : acc),
                        0,
                      ),
                    }
                  : undefined
              }
            />
          );
        }

        return (
          <RackMount
            key={`row-${s.last}-${s.first}`}
            first={s.first}
            last={s.last}
            label="Attach device"
            onClick={
              canWriteRackElevation
                ? () => {
                    const searchParams = new URLSearchParams(window.location.search);
                    searchParams.set('endIndex', s.first.toString());
                    navigate(
                      makeDrawerLink(
                        {
                          ...window.location,
                          search: searchParams.toString(),
                        },
                        paths.drawers.AttachDeviceDrawerPage,
                        {
                          companyName,
                          networkSlug: network.slug,
                          elevationUUID: elevation.UUID,
                        },
                      ),
                    );
                  }
                : undefined
            }
            emptyPlaceholder={
              isExporting ? null : (
                <RackMountAddContainer>
                  <RackMountAddIcon
                    selected={addDeviceParams?.elevationUUID === elevation.UUID}
                    icon="attach"
                  />
                  <RackMountAddLabel selected={addDeviceParams?.elevationUUID === elevation.UUID}>
                    Attach device
                  </RackMountAddLabel>
                </RackMountAddContainer>
              )
            }
            active={addDeviceParams?.elevationUUID === elevation.UUID}
          />
        );
      })}
    </Rack>
  );
}

export default function RackElevationsView({ elevations }: { elevations: RackElevation[] }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const designCrumb = useDesignCrumbs();
  const paneContentRef = useRef<HTMLDivElement>();
  const { dark } = useContext(ThemeContext);
  const handleExport = useMutation(async () => {
    const element = checkDefinedOrThrow(paneContentRef.current);

    const { toBlob } = await import('html-to-image');

    // without this extra manual width the right padding is lost
    const width = element.scrollWidth + space(16);
    const height = element.scrollHeight;

    const blob = await toBlob(element, {
      backgroundColor: dark ? colors.bgApplicationDark.value : colors.bgApplicationLight.value,
      height,
      width,
      canvasHeight: height,
      canvasWidth: width,
      style: {
        overflow: 'unset',
        height: 'unset',
      },
    });

    if (!blob) throw new Error('Blob is null');
    return blob;
  });

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...designCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.RackElevationsPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'Rack elevations',
            },
          },
        ]}
        icon="rack"
        heading="Rack elevations"
        actions={<RackElevationsActions exportRef={paneContentRef} />}
      />
      <PaneContent background="checkered" ref={paneContentRef}>
        <Racks>
          {elevations.map((elevation) => (
            <RackElevationView
              key={elevation.UUID}
              elevation={elevation}
              isExporting={handleExport.isLoading}
            />
          ))}
        </Racks>
      </PaneContent>
    </Pane>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import EventsListTable from './EventsListTable';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/insights/logs/events',
});

export default function EventsListPage() {
  return <EventsListTable />;
}

import { graphql } from '../../../gql';

export const updateInterVLANCommunicationPermittedPairsMutation = graphql(`
  mutation UpdateInterVLANCommunicationPermittedPairs(
    $networkUUID: UUID!
    $vlanPairs: [VLANPairInput!]!
  ) {
    updateInterVLANCommunicationPermittedPairs(networkUUID: $networkUUID, vlanPairs: $vlanPairs) {
      UUID
    }
  }
`);

export const copyInterVLANCommunicationFromConfig1Mutation = graphql(`
  mutation CopyInterVLANCommunicationFromConfig1ToConfig2($networkUUID: UUID!) {
    copyInterVLANCommunicationFromConfig1ToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

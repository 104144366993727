import type { SortingState } from '@tanstack/react-table';
import type { RefObject } from 'react';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Badge, EmptyState, Pane, PaneContent, PaneHeader } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable, isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { capitalize } from 'lodash-es';
import { Suspense, useRef, useState } from 'react';

import type { CompaniesQueryResult } from './utils';
import { CopyableMonoOrNoValue } from '../../../../components/Devices/Insights';
import { KeyBoundSearchInput } from '../../../../components/KeyBoundSearchInput';
import { NoValue } from '../../../../components/NoValue';
import { DetailLoadingFallback } from '../../../../components/Placeholders/DetailLoadingFallback';
import { createColumnBuilder } from '../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../constants';
import { CompanySupportTier } from '../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../nav';
import { useSearchParamsState } from '../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';
import { companiesQuery, supportTierWeight } from './utils';

export const Meta: PagefileMetaFn = () => ({
  path: '/operator/companies',
  layout: 'OperatorLayout',
  title: 'Companies - Operator',
});

const builder = createColumnBuilder<CompaniesQueryResult>();

const columns = [
  builder.data((row) => row.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.uuid, {
    id: 'uuid',
    header: 'UUID',
    meta: {},
    cell: ({ value }) => <CopyableMonoOrNoValue label="uuid" value={value} wrap={false} />,
  }),
  builder.data((row) => row.slug, {
    id: 'slug',
    header: 'Slug',
    meta: {
      minWidth: 400,
    },
  }),
  builder.data((row) => row.networks.filter((network) => network.isActive).length.toString(), {
    id: 'active-networks',
    header: 'Active networks',
    meta: {
      width: 128,
    },
    cell: ({ value }) => (
      <Badge variant="neutral" size="small">
        {value}
      </Badge>
    ),
  }),
  builder.data((row) => (row.isCustomer ? 'customer' : 'internal'), {
    id: 'type',
    header: 'Type',
    meta: {
      width: 128,
    },
    cell: ({ row }) => {
      if (isDefinedAndNotEmpty(row.isCustomer)) {
        return (
          <Badge ends="pill" variant="neutral" size="small">
            Customer
          </Badge>
        );
      }

      return (
        <Badge ends="pill" variant="alternative" size="small">
          Internal
        </Badge>
      );
    },
  }),
  builder.data((row) => supportTierWeight(row.supportTier).toString(), {
    id: 'tier',
    header: 'Support tier',
    meta: {
      width: 128,
    },
    cell: ({ row }) => {
      if (isDefinedAndNotEmpty(row.supportTier)) {
        let variant: 'positive' | 'brand' | 'attention' | 'negative' = 'positive';
        switch (row.supportTier) {
          case CompanySupportTier.Low:
            variant = 'positive';
            break;
          case CompanySupportTier.Medium:
            variant = 'brand';
            break;
          case CompanySupportTier.High:
            variant = 'attention';
            break;
          case CompanySupportTier.Critical:
            variant = 'negative';
            break;
        }
        return (
          <Badge ends="pill" variant={variant} size="small">
            {capitalize(row.supportTier)}
          </Badge>
        );
      }

      return <NoValue />;
    },
  }),
];

function CompaniesActions() {
  return (
    <KeyBoundSearchInput
      placeholder="..."
      aria-label="Filter companies"
      scope="scoped"
      minWidth="56px"
      searchParamStateKey="filter"
    />
  );
}

function CompanyListView({
  companies,
  globalFilter,
  tableContainerRef,
}: {
  companies: CompaniesQueryResult[];
  globalFilter: string | undefined;
  tableContainerRef: RefObject<HTMLDivElement>;
}) {
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.CompanySummaryPage);
  const closeDrawer = useCloseDrawerCallback();

  if (companies.length === 0) {
    return <EmptyState icon="company" heading="No companies found" />;
  }

  return (
    <AutoTable
      isVirtual
      tableContainerRef={tableContainerRef}
      columns={columns}
      data={companies}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      isRowSelected={(s) => s.slug === drawerParams?.slug}
      onRowDeselect={closeDrawer}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.CompanySummaryPage, { slug: row.slug })
      }
    />
  );
}

export default function CompanyListPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const companies =
    useGraphQL(companiesQuery, { limit: 5000, offset: 0 }).data?.listCompanies ?? [];
  const tableContainerRef = useRef(null);

  return (
    <Pane>
      <PaneHeader
        icon="company"
        heading="Companies"
        count={companies.length}
        actions={<CompaniesActions />}
      />
      <PaneContent gutter="bottom" ref={tableContainerRef}>
        <Suspense fallback={<DetailLoadingFallback />}>
          <CompanyListView
            companies={companies}
            tableContainerRef={tableContainerRef}
            globalFilter={globalFilter}
          />
        </Suspense>
      </PaneContent>
    </Pane>
  );
}

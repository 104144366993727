import { Section, SectionContent, SectionHeader, SummaryList } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';

import { graphql } from '../../../../../gql';
import { CopyableMonoOrNoValue } from '../../../../Devices/Insights';
import { formatTimestamp } from '../../../../Devices/utils';

const DeviceConfigVersionQuery = graphql(`
  query DeviceConfigVersion($serialNumber: String!) {
    latestDeviceConfig(serialNumber: $serialNumber) {
      version
    }
    latestAcknowledgedDeviceConfig(serialNumber: $serialNumber) {
      version
      acknowledgedAt
    }
  }
`);

export function InspectorConfig({ serialNumber }: { serialNumber: string }) {
  const configVersion = useGraphQL(
    DeviceConfigVersionQuery,
    { serialNumber },
    { useErrorBoundary: false },
  );

  return (
    <Section relation="stacked" size="x-small">
      <SectionHeader heading="Config" />
      <SectionContent gutter="all">
        <SummaryList
          gutter="none"
          size="x-small"
          pairs={[
            {
              label: 'Backend version',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy backend version"
                  value={
                    configVersion.isError ||
                    !configVersion.data ||
                    !configVersion.data.latestDeviceConfig
                      ? null
                      : configVersion?.data?.latestDeviceConfig.version
                  }
                />
              ),
            },
            {
              label: 'Device version',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy device version"
                  value={
                    configVersion.isError ||
                    !configVersion.data ||
                    !configVersion.data.latestAcknowledgedDeviceConfig
                      ? null
                      : `${configVersion?.data?.latestAcknowledgedDeviceConfig.version} (received ${formatTimestamp(
                          configVersion?.data?.latestAcknowledgedDeviceConfig.acknowledgedAt ?? '',
                        )}`
                  }
                />
              ),
            },
          ]}
        />
      </SectionContent>
    </Section>
  );
}

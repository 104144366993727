import type { Selection } from '@react-types/shared';
import { Body, HStack, Icon, MultiComboBox, MultiComboBoxItem, space, VStack } from '@meterup/atto';
import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { useChartColorsById } from '../../utils/chart_utils';
import { LegendItemColor } from '../ChartLegend';

export default function SeriesFilter({
  seriesIds,
  selectionLabel,
}: {
  seriesIds: string[];
  selectionLabel: string;
}) {
  const [selectedSeries, setSelectedSeries] = useSearchParamsState<string>(
    'series',
    seriesIds.join(','),
  );
  const { colorMap } = useChartColorsById(seriesIds);
  const [localSelectedSeries, setLocalSelectedSeries] = useState(selectedSeries);
  const localSelectedSeriesVal = localSelectedSeries?.split(',');

  const debouncedSetSelectedSeries = useDebouncedCallback((val) => {
    if (!val) {
      // If the val is an empty string, useSearchParamsState treats it as the default value
      // instead of no series selected. Thus, a magic string must be used.
      setSelectedSeries('none');
    } else {
      setSelectedSeries(val);
    }
  }, 750);

  const handleSeriesSelect = useCallback(
    (val: Selection) => {
      if (val === 'all') {
        setLocalSelectedSeries(seriesIds.join(','));
        debouncedSetSelectedSeries(seriesIds.join(','));
      } else {
        const arrFromSet = [...val];
        const serializedArr = arrFromSet.join(',');
        setLocalSelectedSeries(serializedArr);
        debouncedSetSelectedSeries(serializedArr);
      }
    },
    [debouncedSetSelectedSeries, seriesIds],
  );

  return (
    <MultiComboBox
      defaultValue={[]}
      value={localSelectedSeriesVal}
      onValueChange={handleSeriesSelect}
      placeholder="Select items"
      aria-label="Filter by item"
      renderSelected={(selectedItems) => (
        <HStack align="center" spacing={space(6)}>
          <Icon icon="ethernet" color={{ dark: 'gray200', light: 'gray600' }} size={space(12)} />
          <Body>
            {selectedItems.length} {selectionLabel}
          </Body>
          <Icon icon="chevron-down" color={{ dark: 'gray200', light: 'gray600' }} size={space(8)} />
        </HStack>
      )}
      selectionMode="multiple"
      enableSelectAll
    >
      {seriesIds.map((seriesId) => (
        <MultiComboBoxItem key={seriesId} textValue={seriesId}>
          <HStack align="center" spacing={space(10)} wrap="wrap">
            <VStack>
              <LegendItemColor style={{ backgroundColor: colorMap[seriesId] }} />
            </VStack>
            <VStack>{seriesId}</VStack>
          </HStack>
        </MultiComboBoxItem>
      ))}
    </MultiComboBox>
  );
}

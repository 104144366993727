import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import AccessPointDrawer from '../../../../../components/Hardware/AccessPoints/AccessPointDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/:uuid/:tab',
  title: 'Access point - Access points - Hardware',
});

export enum ValidAPSummaryDrawerTabs {
  AccessPoint = 'access-point',
  Clients = 'clients',
  Edit = 'edit',
}

export default function AccessPointDrawerPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags, pathParams }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
            nosFlags[NosFeature.WOS2] &&
            Object.values(ValidAPSummaryDrawerTabs).includes(
              pathParams.tab as ValidAPSummaryDrawerTabs,
            ),
        )
      }
      path={Meta().path}
      should404OnAccessDenied
    >
      <AccessPointDrawer />
    </IsPermitted>
  );
}

import { backgrounds, darkThemeSelector, styled } from '@meterup/atto';
import { colors } from '@meterup/atto/src/stitches.config';

import { QRCode } from '../../QRCode';

const SSIDQRCodeOverlay = styled('div', {
  position: 'absolute',
  zIndex: 3,
  inset: '16px',
  left: '50%',
  marginLeft: '-60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '120px',
  backdropFilter: 'blur(4px)',
  borderRadius: '16px',
});

const SSIDQRCodeContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$16',
  background: backgrounds.dottedLight,
  backgroundColor: colors.bgNeutralLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    background: backgrounds.dottedDark,
    backgroundColor: colors.bgNeutralDark,
  },
});

export function SSIDQRCode({ data, isShown }: { data: string; isShown: boolean }) {
  return (
    <SSIDQRCodeContainer>
      {!isShown && <SSIDQRCodeOverlay />}
      <QRCode text={data} />
    </SSIDQRCodeContainer>
  );
}

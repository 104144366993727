import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import VirtualDeviceCreateDrawer from '../../../../../components/Devices/VirtualDeviceCreateDrawer';
import { VirtualDeviceType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/switches/create',
  title: 'Add switch - Switches - Hardware',
});

export default function SwitchCreatePage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  return <VirtualDeviceCreateDrawer deviceType={VirtualDeviceType.Switch} />;
}

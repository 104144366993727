import type {
  BadgeEnds,
  BadgeVariant,
  HardwareIconPropHardware,
  IconName,
  ManufacturerIconName,
  ProviderIconName,
} from '@meterup/atto';
import {
  Badge,
  BadgeGroup,
  Button,
  FramedIcon,
  HardwareIcon,
  Heading,
  ManufacturerIcon,
  ProviderIcon,
  sizing,
  space,
  styled,
  Subheading,
} from '@meterup/atto';
import { colors, darkThemeSelector } from '@meterup/atto/src/stitches.config';
import { shadows } from '@meterup/common';
import React from 'react';

import { ReactRouterLink } from '../ReactRouterLink';

type ObjectHeaderPropIcon =
  | HardwareIconPropHardware
  | IconName
  | ManufacturerIconName
  | ProviderIconName;
type ObjectHeaderPropStatus =
  | 'attention'
  | 'backup'
  | 'connected'
  | 'enabled'
  | 'error'
  | 'disabled'
  | 'disconnected'
  | 'draft'
  | 'high'
  | 'low'
  | 'medium'
  | 'offline'
  | 'online'
  | 'primary'
  | 'upgrading'
  | 'wired'
  | React.ReactNode;
type ObjectHeaderPropLifecycle =
  | 'active'
  | 'disabled'
  | 'disconnected'
  | 'error'
  | 'enabled'
  | 'hidden'
  | 'inactive'
  | 'pending'
  | 'waiting'
  | 'wired'
  | 'visible'
  | 'upgrading'
  | React.ReactNode;

export type ObjectHeaderProps = {
  /**
   * Boolean to determine if the element is collapsed or not.
   */
  collapsed?: boolean;
  /**
   * Provide a call to action.
   */
  cta?: React.ReactNode;
  /**
   * Provide a description for your object.
   */
  description?: React.ReactNode;
  /**
   * Provide the ends for the badges.
   */
  ends?: BadgeEnds;
  /**
   * Provide an icon for your pane.
   */
  icon?: ObjectHeaderPropIcon;
  /**
   * Provide an illustration as an alternative to icon for your pane.
   */
  illustration?: React.ReactNode;
  lifecycle?: ObjectHeaderPropLifecycle;
  /**
   * Provide a link to the detail view of the object.
   */
  link?: string | boolean;
  /**
   * `target` property of the link.
   */
  target?: string;
  /**
   * Provide a name for your object.
   */
  name?: React.ReactNode;
  /**
   * Provide any actions.
   */
  status?: ObjectHeaderPropStatus;
  /**
   * Boolean to determine if the element is stuck or not.
   */
  stuck?: boolean;
  /**
   * Boolean to determine if the badges are tied to each other.
   */
  tied?: boolean;
};

const ObjectHeaderIllustration = styled('div', {
  display: 'flex',
});

const ObjectHeaderIcon = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',

  variants: {
    collapsed: {
      true: {},
      false: {},
    },
    hasBadge: {
      true: {},
      false: {},
    },
    position: {
      'access-point': {},
      framed: {},
      hardware: {},
      isp: {},
      manufacturer: {},
    },
  },

  compoundVariants: [
    {
      collapsed: false,
      hasBadge: 'true',
      position: 'access-point',
      css: {
        marginTop: '8px',
        marginBottom: '-8px',
      },
    },
    {
      collapsed: false,
      hasBadge: 'true',
      position: 'framed',
      css: {
        marginTop: '8px',
        marginBottom: '-8px',
      },
    },
    {
      collapsed: false,
      hasBadge: 'true',
      position: 'hardware',
      css: {
        marginTop: '14px',
        marginBottom: '-14px',
      },
    },
    {
      collapsed: false,
      hasBadge: 'true',
      position: 'isp',
      css: {
        marginTop: '8px',
        marginBottom: '-8px',
      },
    },
    {
      collapsed: false,
      hasBadge: 'true',
      position: 'manufacturer',
      css: {
        marginTop: '8px',
        marginBottom: '-8px',
      },
    },
  ],
});

const ObjectHeaderBadge = styled(Badge, {
  position: 'relative',
  zIndex: 2,
});

const ObjectLockup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ObjectHeaderName = styled(Heading, {
  width: '100%',
  textAlign: 'center',

  variants: {
    collapsed: {
      true: {
        textAlign: 'left',
        fontSize: '$16',
      },
      false: {},
    },
    stuck: {
      true: {
        textAlign: 'left',
        truncate: true,
        fontSize: '$16',
      },
      false: {},
    },
  },
});

const ObjectHeaderDescription = styled(Subheading, {
  width: '100%',
  textAlign: 'center',
});

const ObjectHeaderMetadata = styled('div', {
  position: 'relative',
  display: 'flex',
  width: '100%',

  variants: {
    collapsed: {
      true: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: '$8',
      },
      false: {
        flexDirection: 'column',
        gap: '$8',
      },
    },
    link: {
      true: {},
      false: {},
    },
  },
});

const ObjectHeaderButton = styled('div', {
  padding: '0 1px',
});

const ObjectHeaderPosition = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',

  variants: {
    stuck: {
      true: {
        gap: '$8',
        padding: sizing.squish,
        background: colors.bgApplicationLight,
        boxShadow: shadows.stuckLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
          boxShadow: shadows.stuckDark,
        },

        '&::before': {
          content: '',
          position: 'absolute',
          top: 0,
          right: sizing.sides,
          left: sizing.sides,
          display: 'block',
          height: '1px',
          background: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            background: colors.strokeNeutralDark,
          },
        },
      },
      false: {
        gap: '$12',
        padding: sizing.squish,

        '&::before, &::after': {
          display: 'none',
        },
      },
    },
  },
});

const ObjectHeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const ZeroHeightStickyContainer = styled('div', {
  position: 'sticky',
  height: 0,
  top: 0,
  opacity: 0,
  zIndex: 2,
  transition: 'opacity 150ms ease-out',
  variants: {
    visible: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
});

const getObjectHeaderIcon = (
  icon: ObjectHeaderPropIcon,
  status: ObjectHeaderPropStatus,
  collapsed?: boolean,
) => {
  switch (icon) {
    case 'amazon':
    case 'apple':
    case 'asus':
    case 'dell':
    case 'ecobee':
    case 'google':
    case 'hp':
    case 'ibm':
    case 'intel':
    case 'lenovo':
    case 'lg':
    case 'logitech':
    case 'microsoft':
    case 'nest':
    case 'philips':
    case 'samsung':
    case 'shure':
    case 'sonos':
    case 'unknown':
      return (
        <ObjectHeaderIcon collapsed={collapsed} hasBadge={!!status} position="manufacturer">
          <ManufacturerIcon icon={icon} size={space(space(collapsed ? 32 : 60))} />
        </ObjectHeaderIcon>
      );
    case 'allstream':
    case 'att':
    case 'cogent':
    case 'comcastBusiness':
    case 'cox':
    case 'cradlePoint':
    case 'crownCastle':
    case 'cruzio':
    case 'earthLink':
    case 'fastmetrics':
    case 'for2Fi':
    case 'frontier':
    case 'gtt':
    case 'hughesNet':
    case 'lumen':
    case 'monkeybrains':
    case 'pilot':
    case 'rcn':
    case 'sonic':
    case 'spectrum':
    case 'tMobile':
    case 'verizon':
    case 'viasat':
    case 'wave':
    case 'webpass':
    case 'wiLine':
    case 'windstream':
    case 'wow':
    case 'xfinity':
    case 'zayo':
      return (
        <ObjectHeaderIcon collapsed={collapsed} hasBadge={!!status} position="isp">
          <ProviderIcon provider={icon} size={space(space(collapsed ? 32 : 60))} />
        </ObjectHeaderIcon>
      );
    case 'pdu':
    case 'security-appliance':
    case 'switch':
      return (
        <ObjectHeaderIcon collapsed={collapsed} hasBadge={!!status} position="hardware">
          <HardwareIcon hardware={icon} size={space(space(collapsed ? 32 : 60))} />
        </ObjectHeaderIcon>
      );
    case 'access-point':
      return (
        <ObjectHeaderIcon collapsed={collapsed} hasBadge={!!status} position="access-point">
          <HardwareIcon hardware={icon} size={space(collapsed ? 32 : 60)} />
        </ObjectHeaderIcon>
      );
    default:
      return (
        <ObjectHeaderIcon collapsed={collapsed} hasBadge={!!status} position="framed">
          <FramedIcon icon={icon} size={space(space(collapsed ? 32 : 60))} />
        </ObjectHeaderIcon>
      );
  }
};

const getObjectHeaderStatusVariant = (status: ObjectHeaderPropStatus): BadgeVariant => {
  switch (status) {
    case 'connected':
    case 'enabled':
    case 'online':
    case 'primary':
      return 'positive';
    case 'error':
    case 'disconnected':
    case 'high':
    case 'offline':
      return 'negative';
    case 'disabled':
      return 'disabled';
    case 'attention':
    case 'medium':
      return 'attention';
    default:
      return 'neutral';
  }
};

const getObjectHeaderLifecycleVariant = (lifecycle: ObjectHeaderPropStatus): BadgeVariant => {
  switch (lifecycle) {
    case 'active':
    case 'visible':
      return 'positive';
    default:
      return 'neutral';
  }
};

const getObjectHeaderStatusLabel = (status: ObjectHeaderPropStatus): string => {
  switch (status) {
    case 'online':
    case 'attention':
      return 'Online';
    case 'backup':
      return 'Backup';
    case 'connected':
      return 'Connected';
    case 'disabled':
      return 'Disabled';
    case 'disconnected':
      return 'Disconnected';
    case 'draft':
      return 'Draft';
    case 'enabled':
      return 'Enabled';
    case 'high':
      return 'High';
    case 'low':
      return 'Low';
    case 'medium':
      return 'Medium';
    case 'hidden':
      return 'Hidden';
    case 'offline':
      return 'Offline';
    case 'primary':
      return 'Primary';
    case 'upgrading':
      return 'Upgrading';
    case 'wired':
      return 'Wired';
    default:
      return 'Unknown';
  }
};

const getObjectHeaderLifecycleLabel = (lifecycle: ObjectHeaderPropLifecycle): string => {
  switch (lifecycle) {
    case 'active':
      return 'Active';
    case 'hidden':
      return 'Hidden';
    case 'inactive':
      return 'Inactive';
    case 'visible':
      return 'Visible';
    default:
      return 'Unknown';
  }
};

const ObjectHeaderBadgesContainer = styled(BadgeGroup);

type ObjectHeaderBadgesProps = {
  ends?: BadgeEnds;
  isCollapsed?: boolean;
  lifecycle?: ObjectHeaderPropLifecycle;
  status?: ObjectHeaderPropStatus;
  tied?: boolean;
};

export function ObjectHeaderBadges({
  ends = 'pill',
  isCollapsed,
  lifecycle,
  tied = true,
  status,
}: ObjectHeaderBadgesProps) {
  return (
    <ObjectHeaderBadgesContainer
      ends={ends}
      relation="joined"
      tied={tied}
      size={isCollapsed ? 'small' : 'medium'}
    >
      {status && (
        <ObjectHeaderBadge variant={getObjectHeaderStatusVariant(status)}>
          {getObjectHeaderStatusLabel(status)}
        </ObjectHeaderBadge>
      )}
      {lifecycle && (
        <ObjectHeaderBadge variant={getObjectHeaderLifecycleVariant(lifecycle)}>
          {getObjectHeaderLifecycleLabel(lifecycle)}
        </ObjectHeaderBadge>
      )}
    </ObjectHeaderBadgesContainer>
  );
}

export const ObjectHeader = React.forwardRef<HTMLDivElement, ObjectHeaderProps>(
  (
    {
      collapsed,
      cta,
      description,
      ends,
      icon,
      illustration,
      lifecycle,
      link,
      name,
      status,
      stuck,
      target,
      tied,
    },
    ref,
  ) => {
    const isCollapsed = collapsed || stuck;
    const hasBadge = status || lifecycle;
    return (
      <ObjectHeaderContainer ref={ref}>
        <ObjectHeaderPosition stuck={stuck}>
          <ObjectHeaderMetadata collapsed={isCollapsed} link={!!link}>
            <ObjectLockup>
              {!illustration && icon && getObjectHeaderIcon(icon, status, isCollapsed)}
              {!isCollapsed && <ObjectHeaderIllustration>{illustration}</ObjectHeaderIllustration>}
              {!isCollapsed && hasBadge && (
                <ObjectHeaderBadges
                  ends={ends}
                  lifecycle={lifecycle}
                  status={status}
                  isCollapsed={isCollapsed}
                />
              )}
            </ObjectLockup>
            <ObjectHeaderName collapsed={isCollapsed}>{name}</ObjectHeaderName>
            {isCollapsed && hasBadge && (
              <ObjectHeaderBadges
                lifecycle={lifecycle}
                status={status}
                tied={tied}
                isCollapsed={isCollapsed}
              />
            )}
            {description && <ObjectHeaderDescription>{description}</ObjectHeaderDescription>}
          </ObjectHeaderMetadata>
          {typeof link === 'string' && (
            <ObjectHeaderButton>
              <Button
                as={ReactRouterLink}
                to={link}
                replace={false}
                variant="secondary"
                size="large"
                icon="chevron-right"
                arrangement="leading-label"
                width="100%"
                target={target ?? ''}
              >
                {cta}
              </Button>
            </ObjectHeaderButton>
          )}
        </ObjectHeaderPosition>
      </ObjectHeaderContainer>
    );
  },
);

const useStickyHeader = () => {
  const [isOutOfView, setIsOutOfView] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  // Make the sticky header visible when 70% of the "real" header is out of view.
  React.useLayoutEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      // ensures that the element is rendered with content without display: none; (a result of AppSkeleton)
      if (ref.current?.clientHeight) {
        setIsOutOfView(!entries[0].isIntersecting);
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.3,
      rootMargin: '0px 0px 0px 0px',
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, []);

  return { ref, isOutOfView };
};

export function StickyObjectHeader(props: ObjectHeaderProps) {
  const { ref, isOutOfView } = useStickyHeader();

  return (
    <>
      <ZeroHeightStickyContainer visible={isOutOfView} aria-hidden="true">
        <ObjectHeader {...props} stuck />
      </ZeroHeightStickyContainer>
      <ObjectHeader {...props} stuck={false} ref={ref} />
    </>
  );
}

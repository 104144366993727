import { z } from 'zod';

import type { FloorPlanQuery, FloorPlansForNetworkQuery } from '../../gql/graphql';
import { graphql } from '../../gql';
import { CreateFloorPlanInputSchema } from '../../gql/zod-types';

export const floorPlansQuery = graphql(`
  query FloorPlansForNetwork($networkUUID: UUID!) {
    floorPlansForNetwork(networkUUID: $networkUUID) {
      UUID
      label
      description
      imageS3Key
      imageDownloadURL
    }
  }
`);

export const floorPlanQuery = graphql(`
  query FloorPlan($UUID: UUID!) {
    floorPlan(UUID: $UUID) {
      UUID
      label
      description
      imageS3Key
    }
  }
`);

export const createFloorPlanMutation = graphql(`
  mutation CreateFloorPlan($networkUUID: UUID!, $input: CreateFloorPlanInput!) {
    createFloorPlan(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateFloorPlanMutation = graphql(`
  mutation UpdateFloorPlan($UUID: UUID!, $input: UpdateFloorPlanInput!) {
    updateFloorPlan(UUID: $UUID, input: $input) {
      UUID
    }
  }
`);

export const deleteFloorPlanMutation = graphql(`
  mutation DeleteFloorPlan($UUID: UUID!) {
    deleteFloorPlan(UUID: $UUID) {
      UUID
    }
  }
`);

export type FloorPlans = FloorPlansForNetworkQuery['floorPlansForNetwork'];
export type FloorPlan = FloorPlanQuery['floorPlan'];

export const floorPlanInputSchema = CreateFloorPlanInputSchema.extend({
  imageS3Key: z.string().nonempty({ message: 'Please provide an image.' }),
});

export type FloorPlanInputValues = z.infer<typeof floorPlanInputSchema>;

import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';

import AttachDeviceDrawer from '../../../../../components/Design/RackElevations/AttachDeviceDrawer';
import { rackElevationQuery } from '../../../../../components/Design/RackElevations/utils';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { DrawerLoadingFallback } from '../../../../../components/Placeholders/DrawerLoadingFallback';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/attached-device/add',
  title: 'Attach device - Rack elevations - Design',
});

export default function AttachDeviceDrawerPage() {
  const { elevationUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.AttachDeviceDrawerPage),
    new ResourceNotFoundError('Page not found'),
  );
  const [searchParams] = useSearchParams();
  const rackElevation = checkDefinedOrThrow(
    useGraphQL(rackElevationQuery, { UUID: elevationUUID }).data?.rackElevation,
    new ResourceNotFoundError('Rack elevation not found'),
  );

  const closeDrawer = useCloseDrawerCallback();

  let endIndex: number | undefined;
  const endIndexStr = searchParams.get('endIndex');
  if (endIndexStr) {
    const endIndexNum = Number(endIndexStr);
    if (!Number.isNaN(endIndexNum) && Number.isInteger(endIndexNum)) {
      endIndex = endIndexNum;
    }
  }

  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermRackElevationWrite) &&
        (isOperator || !!ldFlags['rack-elevation'])
      }
      should404OnAccessDenied
      allowOperatorAlways={false}
    >
      <Suspense fallback={<DrawerLoadingFallback />}>
        <AttachDeviceDrawer
          elevation={rackElevation}
          stopEditing={closeDrawer}
          endIndex={endIndex}
        />
      </Suspense>
    </IsPermitted>
  );
}

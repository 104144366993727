import type { NotificationsValidTabs } from './NotificationsTabs';
import { TabbedLayout } from '../../../Layouts/TabbedLayout';
import NotificationsActions from './NotificationsActions';
import NotificationsTabs from './NotificationsTabs';

export default function NotificationsLayout({
  activeTab,
  main,
}: {
  activeTab: NotificationsValidTabs;
  main: React.ReactNode;
}) {
  return (
    <TabbedLayout
      header={{
        icon: 'bell',
        heading: 'Notifications',
        tabs: <NotificationsTabs activeTab={activeTab} />,
        contentActions: <NotificationsActions activeTab={activeTab} />,
      }}
      main={main}
    />
  );
}

import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import SSIDDrawer from '../../../../../components/Wireless/SSIDs/SSIDDrawer';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/ssids/:uuid',
  title: 'SSID - SSIDs - Wireless',
});

export default function SSIDDrawerPage() {
  const { uuid } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.SSIDDrawerPage));
  return <SSIDDrawer uuid={uuid} />;
}

import { Button } from '@meterup/atto';

import { paths } from '../../constants';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { ReactRouterLink } from '../ReactRouterLink';

export function AddAutoVPNGroupButton() {
  const companyName = useCurrentCompany();

  const createAutoVPNGroupCreateDrawerLink = makeDrawerLink(
    window.location,
    paths.drawers.AutoVPNGroupCreatePage,
    {
      companyName,
    },
  );
  return (
    <Button
      as={ReactRouterLink}
      to={createAutoVPNGroupCreateDrawerLink}
      variant="secondary"
      icon="plus"
      arrangement="leading-icon"
      size="small"
    >
      Add
    </Button>
  );
}

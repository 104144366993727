import { api } from '@meterup/proto';
import { RadioBand } from '@meterup/proto/esm/api';

import { apDB, clientDB } from './data';

export function demoControllerDevices(
  company_slug: string,
  controller: api.ControllerResponse,
): api.ControllerDeviceResponse {
  const response: api.ControllerDeviceResponse = {
    name: controller.name,
    serial_number: controller.name,
    mac_address: '',
    ip_address: '10.0.0.1',
    ip_addresses: ['10.0.0.1'],
    connected_status: 'online',
    lifecycle_status: api.LifecycleStatus.LIFECYCLE_STATUS_DEMO,
    switches: [],
    access_points: [],
    uses_dhcp: controller.uses_dhcp,
    wan_metrics: [],
  };

  const clientCounts: { [key: string]: number } = {};

  Object.values(clientDB).forEach((value) => {
    if (!clientCounts[value.apName]) {
      clientCounts[value.apName] = 1;
    } else {
      clientCounts[value.apName] += 1;
    }
  });

  Object.entries(apDB).forEach(([deviceName, apData]) => {
    const numClients = clientCounts[deviceName];

    response.access_points.push({
      configured_build_name: '',
      name: deviceName,
      serial_number: deviceName,
      mac_address: apData.macAddress,
      ip_address: apData.ipAddress,
      connected_status: 'online',
      location: apData.physicalLocation,
      radios: [
        {
          band: RadioBand.RB_2G,
          channel: apData.twoGChannel,
          channel_width_MHz: 20,
          tx_power_dBm: apData.twoGPower,
          enabled: true,
        },
        {
          band: RadioBand.RB_5G,
          channel: apData.fiveGChannel,
          channel_width_MHz: 40,
          tx_power_dBm: apData.fiveGPower,
          enabled: true,
        },
      ],
      connected_clients: numClients,
      firmware_version: '2.3.16',
      build_name: '',
    });
  });

  response.switches.push({
    serial_number: 'C000000000000000',
    location: 'Switch #1 4 Trunk',
    controller: controller.name,
    model: 'PSGS-1518AF',
    inventory_status: 'Meter Firmware - Assigned to Job',
    mac: '2B:78:57:9F:96:BC',
    poe_wattage: 22,
    port_info: '24x Gigabit RJ45 Ports, 2x SFP Ports',
    connected_status: 'online',
  });

  response.switches.push({
    serial_number: 'C000000000000001',
    location: 'Switch #2 4 Trunk',
    controller: controller.name,
    model: 'PSGS-1518AF',
    inventory_status: 'Meter Firmware - Assigned to Job',
    mac: '5D:46:93:35:53:44',
    poe_wattage: 22,
    port_info: '24x Gigabit RJ45 Ports, 2x SFP Ports',
    connected_status: 'online',
  });

  return response;
}

import type { api } from '@meterup/proto';
import { Badge, HStack, space, Tooltip } from '@meterup/atto';
import {
  DisabledBadge,
  getEnabledRadioForBandOrNull,
  isDefined,
  isDefinedAndNotEmpty,
} from '@meterup/common';

import type { AccessPointDeviceData } from '../api/types';
import type { NetworkClient } from '../hooks/networkClients/useNetworkClients';

export function RadioNumbers({ value }: { value: api.AccessPointRadioConfig }) {
  return (
    <HStack spacing={space(4)}>
      <Tooltip asChild={false} contents="Channel">
        <Badge size="small">{value.channel}</Badge>
      </Tooltip>
      <Tooltip asChild={false} contents="Channel width">
        <Badge size="small">{value.channel_width_MHz}</Badge>
      </Tooltip>
      <Tooltip asChild={false} contents="Power">
        <Badge size="small">{value.tx_power_dBm}</Badge>
      </Tooltip>
    </HStack>
  );
}

export function RadioNumbersOrDisabled({
  value,
  band,
}: {
  value: api.AccessPointRadioConfig[] | null | undefined;
  band: api.RadioBand;
}) {
  const radio = getEnabledRadioForBandOrNull(value, band);
  return isDefined(radio) ? (
    <RadioNumbers value={radio} />
  ) : (
    <DisabledBadge icon="cross" arrangement="leading-icon" />
  );
}

export const formatAPRadiosForBand = (
  accessPointRadios: api.AccessPointRadioConfig[] | null,
  band: api.RadioBand,
) => {
  const radio = getEnabledRadioForBandOrNull(accessPointRadios, band);
  return radio
    ? [
        radio.channel.toString(),
        radio.channel_width_MHz.toString(),
        radio.tx_power_dBm.toString(),
      ].join(', ')
    : null;
};

export enum DevicesFilterStrategy {
  All = 'all',
  Online = 'online',
  Offline = 'offline',
  Draft = 'draft',
}

const isDraft = (d: AccessPointDeviceData) => d.connected_status === DevicesFilterStrategy.Draft;

export const isOnline = (d: AccessPointDeviceData) =>
  d.connected_status === DevicesFilterStrategy.Online;

export const isOffline = (d: AccessPointDeviceData) =>
  d.connected_status === DevicesFilterStrategy.Offline;

export const getAPBadgeLabel = (d: AccessPointDeviceData) => {
  if (isOnline(d)) {
    return DevicesFilterStrategy.Online;
  }
  if (isDraft(d)) {
    return DevicesFilterStrategy.Draft;
  }
  return DevicesFilterStrategy.Offline;
};

export const getAPBadgeProps = (
  d: AccessPointDeviceData,
): {
  variant: 'positive' | 'neutral' | 'negative';
  icon: 'checkmark' | 'minus' | 'cross';
} => {
  if (isOnline(d)) {
    return {
      variant: 'positive',
      icon: 'checkmark',
    };
  }
  if (isDraft(d)) {
    return {
      variant: 'neutral',
      icon: 'minus',
    };
  }
  return {
    variant: 'negative',
    icon: 'cross',
  };
};

export const getAPName = (location: string, deviceName: string) => {
  if (isDefinedAndNotEmpty(location)) {
    return location;
  }
  return deviceName;
};

export const getAPNameForDevice = (device: api.AccessPointDeviceResponse) =>
  getAPName(device.location, device.name);

export const getAPNameForClient = (client: NetworkClient) =>
  getAPName(client.apLocation!, client.apSerialNumber!);

/* eslint-disable react-refresh/only-export-components */
/* eslint-disable import/no-cycle */
import React from 'react';

import { Small } from '@meterup/atto';
import { Optional } from '@meterup/connect-api';
import {
  BandwidthCell,
  StatusCell,
  StatusCellProps,
} from '@meterup/connect-ui/src/components/Table/CommonCells';
import {
  formatBandwidth,
  pbProductToReadable,
  quoteToTerm,
} from '@meterup/connect-ui/src/components/Table/formatters';
import { BandwidthProps } from '@meterup/connect-ui/src/components/Table/types';
import { EmDash } from '@meterup/connect-ui/src/constants';
import useFormatters from '@meterup/connect-ui/src/hooks/useFormatters';
import {
  aggregationFns,
  CellContext,
  ColumnDef,
  createColumnHelper,
  Row,
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import { ContractForDisplayType } from '../../utils/ContractForDisplayType';
import { defaultMakeRoute } from '../../utils/defaultMakeRoute';
import { ISPCell, NoISPsCell } from './ISPCells';
import LocationCell, { LocationCellProps } from './LocationCell';
import RegionCell, { RegionCellProps } from './RegionCell';
import { TotalCostCell } from './TotalCostCell';

enum QuoteSelectionStage {
  Error = 'error',
  NoQuotes = 'noQuotes',
  PreQuoteSelection = 'preQuoteSelection',
  QuoteSelected = 'quoteSelected',
}

type QuoteSelectionStageResultTypePreSelection = {
  stage: QuoteSelectionStage.PreQuoteSelection;
  quoteRequestSID: string;
};

type QuoteSelectionStageResultType =
  | {
      stage: QuoteSelectionStage;
    }
  | QuoteSelectionStageResultTypePreSelection;

export function isPreSelectionStage(
  stage: QuoteSelectionStageResultType,
): stage is QuoteSelectionStageResultTypePreSelection {
  return stage.stage === QuoteSelectionStage.PreQuoteSelection;
}

export function getQuoteSelectionStage({
  contract,
}: ContractForDisplayType): QuoteSelectionStageResultType {
  if (!contract) {
    return { stage: QuoteSelectionStage.Error };
  }

  const { internetServicePlan } = contract;
  if (!internetServicePlan) {
    return { stage: QuoteSelectionStage.Error };
  }
  const { quote, quoteRequest } = internetServicePlan;

  if (!quote && !quoteRequest) {
    return { stage: QuoteSelectionStage.NoQuotes };
  }

  if (quoteRequest && !quote) {
    return { stage: QuoteSelectionStage.PreQuoteSelection, quoteRequestSID: quoteRequest.sid };
  }

  return { stage: QuoteSelectionStage.QuoteSelected };
}

export const renderLocationCell = ({ address2, address1, name }: LocationCellProps) => (
  <LocationCell address1={address1} address2={address2} name={name} />
);

export const renderRegionCell = ({ city, state }: RegionCellProps) => (
  <RegionCell city={city} state={state} />
);

export const renderStatusCell = ({ connectionStatusChange }: StatusCellProps) => (
  <StatusCell connectionStatusChange={connectionStatusChange} />
);

const EM_DASH = <Small>—</Small>;

type GetValueProps<T> = {
  getValue: () => T | undefined;
};

export function RenderCentsCell({ value }: { value: Optional<number> }) {
  const { centsFormatter } = useFormatters();
  return <Small>{value ? centsFormatter(value) : EM_DASH}</Small>;
}

export function RenderDateCell({ value }: { value?: string }) {
  const { pbDateFormatter } = useFormatters();
  if (!value) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }
  const formattedValue = pbDateFormatter(value);
  return <Small>{formattedValue || EM_DASH}</Small>;
}

export function RenderStringCell({ getValue }: GetValueProps<string>) {
  const value = getValue();
  return <Small>{value || EM_DASH}</Small>;
}

export const renderBandwidthCell = ({ downloadKbps, uploadKbps }: BandwidthProps) => (
  <BandwidthCell downloadKbps={downloadKbps} uploadKbps={uploadKbps} />
);

function getRegionString(row: Row<ContractForDisplayType>) {
  const address = row.original.companyLocation.location?.address;
  if (!address) {
    return '';
  }

  return `${address.city}, ${address.state}`;
}

const colHelper = createColumnHelper<ContractForDisplayType>();
export const tableColumns: ColumnDef<ContractForDisplayType, any>[] = [
  // Address 1 & 2
  colHelper.accessor(
    ({ companyLocation }: ContractForDisplayType) => {
      const { address } = companyLocation.location || {};
      if (!address) {
        return null;
      }
      const { address1, address2, city, state } = address;

      return [address1, address2, city, state].filter(Boolean).join(', ');
    },
    {
      id: 'address',
      enableSorting: true,
      size: 300,
      // @ts-ignore
      header: <Small>Address</Small>,
      enableGrouping: true,
      cell: (props?: CellContext<ContractForDisplayType, any>) => {
        if (!props) {
          return EM_DASH;
        }
        const { companyLocation, contract } = props.row.original;
        const { location, name, sid } = companyLocation;
        const address = location?.address;
        if (!address) {
          return EM_DASH;
        }
        const { address1, address2, city, state } = address;
        const { internetServicePlan } = contract;
        const { quoteRequest } = internetServicePlan || {};

        const parts = {
          address1,
          address2,
          city,
          name,
          sid,
          state,
          ...(quoteRequest && { quoteRequestSid: quoteRequest.sid }),
        };
        return renderLocationCell(parts);
      },
    },
  ),
  // Total
  colHelper.accessor(
    ({ contract }: ContractForDisplayType) => {
      if (!contract) {
        return -1;
      }
      const { internetServicePlan } = contract;
      const { monthlyCostCents } = internetServicePlan || {};

      return monthlyCostCents;
    },
    {
      enableSorting: true,
      aggregationFn: aggregationFns.sum,
      aggregatedCell: (props) => {
        const {
          row: {
            subRows,
            original: { companyLocation, companySID, numCompanyLocations, routingFn },
          },
        } = props;
        const makeRoute = routingFn || defaultMakeRoute;
        if (subRows && subRows.length > 0) {
          const result = getQuoteSelectionStage(subRows[0].original);
          if (isPreSelectionStage(result)) {
            if (Number(numCompanyLocations ?? 0) > 50) {
              return <EmDash />;
            }
            return <TotalCostCell quoteRequestSID={result.quoteRequestSID} routingFn={makeRoute} />;
          }
        }
        return (
          <Link
            to={makeRoute({
              name: 'connection',
              params: { companySID: companySID!, companyLocationSID: companyLocation.sid },
            })}
          >
            <RenderCentsCell value={props.getValue()} />
          </Link>
        );
      },
      id: 'total',
      // @ts-ignore
      header: <Small>Total</Small>,
    },
  ),
  // "Region": City & State
  colHelper.accessor(
    ({ companyLocation, contract }: ContractForDisplayType) => {
      const { address } = companyLocation.location || {};
      const { internetServicePlan } = contract;
      if (!address || !internetServicePlan) {
        return null;
      }
      const { sid } = internetServicePlan;
      const { city, state } = address;

      return `region-cell-${sid}-${city}-${state}`;
    },
    {
      id: 'region',
      // @ts-ignore
      header: <Small>Region</Small>,
      enableSorting: true,
      enableGrouping: true,
      sortingFn: (a, b) => {
        const aRegion = getRegionString(a);
        const bRegion = getRegionString(b);

        return aRegion.localeCompare(bRegion);
      },
      cell: (props?: CellContext<ContractForDisplayType, any>) => {
        if (!props) {
          return EM_DASH;
        }
        const { companyLocation, contract } = props.row.original;
        const { location, sid } = companyLocation;
        const address = location?.address;
        if (!address) {
          return EM_DASH;
        }
        const { city, state } = address;
        const { internetServicePlan } = contract;
        const { quoteRequest } = internetServicePlan || {};

        const parts = {
          city,
          sid,
          state,
          ...(quoteRequest && { quoteRequestSid: quoteRequest.sid }),
        };
        return renderRegionCell(parts);
      },
    },
  ),
  // Status badge
  colHelper.accessor(
    (props: ContractForDisplayType) => {
      const { contract } = props;
      if (!contract) {
        return null;
      }
      const { latestStatus, internetServicePlan } = contract;
      let statusSID = latestStatus?.sid;
      if (statusSID) {
        // eslint-disable-next-line prefer-destructuring
        statusSID = statusSID.split('::')[0];
      }

      return `status-${statusSID}-${internetServicePlan?.sid}`;
    },
    {
      id: 'status',
      // @ts-ignore
      header: <Small>Status</Small>,
      cell: (props) => {
        const { contract } = props.row.original;
        if (!contract) {
          return null;
        }
        const { latestStatus } = contract;
        const val = { connectionStatusChange: latestStatus };
        return renderStatusCell(val);
      },
      aggregationFn: (...args) => aggregationFns.unique(...args),
    },
  ),
  // ISPs
  colHelper.accessor(
    ({ contract }: ContractForDisplayType) => {
      if (!contract) {
        return null;
      }
      const { internetServicePlan } = contract;
      if (!internetServicePlan) {
        return null;
      }
      const { sid: ispSid } = internetServicePlan;

      return `isp-cell-${ispSid}`;
    },
    {
      id: 'isp',
      // @ts-ignore
      header: <Small>ISPs</Small>,
      enableSorting: true,
      cell: (props) => {
        const { stage } = getQuoteSelectionStage(props.row.original);
        if (stage === QuoteSelectionStage.Error) {
          return <EmDash />;
        }
        const { contract, numCompanyLocations } = props.row.original;
        const { internetServicePlan } = contract!;
        const { provider, quoteRequest } = internetServicePlan!;

        if (stage === QuoteSelectionStage.PreQuoteSelection) {
          // For large number of locations in pre-quoting stage, we should not fetch information about quotes.
          if (Number(numCompanyLocations ?? 0) > 50) {
            return <EmDash />;
          }
          return <NoISPsCell quoteRequestSID={quoteRequest!.sid} />;
        }
        return <ISPCell title={provider}>{provider}</ISPCell>;
      },
    },
  ),
  // Product
  colHelper.accessor(
    ({ contract }: ContractForDisplayType) => {
      if (!contract) {
        return null;
      }
      const { connectionType, internetServicePlan } = contract;
      const sid = internetServicePlan?.sid;
      const product = connectionType?.name || pbProductToReadable(internetServicePlan?.product);

      return `prod-${product}-${sid}`;
    },
    {
      id: 'product',
      // @ts-ignore
      header: <Small>Product</Small>,
      enableSorting: true,
      cell: (props) => {
        const { contract } = props.row.original;
        const { stage } = getQuoteSelectionStage(props.row.original);
        if (!contract || stage === QuoteSelectionStage.PreQuoteSelection) {
          return EM_DASH;
        }
        const { connectionType, internetServicePlan } = contract;
        const product = connectionType?.name || pbProductToReadable(internetServicePlan?.product);
        if (!product) {
          return EM_DASH;
        }

        return <Small>{product}</Small>;
      },
    },
  ),
  // Bandwidth
  colHelper.accessor(
    ({ contract }: ContractForDisplayType) => {
      if (!contract) {
        return null;
      }
      const { internetServicePlan } = contract;
      const { downloadKbps, uploadKbps, sid } = internetServicePlan || {};

      return `bw-${downloadKbps}-${uploadKbps}-${sid}`;
    },
    {
      id: 'bandwidth',
      // @ts-ignore
      header: <Small>Speed (Mbps)</Small>,
      enableSorting: true,
      sortingFn: (a, b) => {
        const aDownload = a.original.contract.internetServicePlan?.downloadKbps || 0;
        const bDownload = b.original.contract.internetServicePlan?.downloadKbps || 0;

        if (aDownload === bDownload) {
          return 0;
        }

        return aDownload > bDownload ? 1 : -1;
      },
      cell: (props) => {
        const { contract } = props.row.original;
        if (!contract) {
          return renderBandwidthCell({});
        }
        const { internetServicePlan } = contract;
        return (
          <Small>
            {renderBandwidthCell(formatBandwidth({ value: internetServicePlan }) || {})}
          </Small>
        );
      },
    },
  ),
  // Monthly rate
  colHelper.accessor(
    ({ contract }: ContractForDisplayType) => {
      if (!contract) {
        return null;
      }
      const { internetServicePlan } = contract;
      const { monthlyCostCents, sid } = internetServicePlan || {};

      return `monthly-${monthlyCostCents}-${sid}`;
    },
    {
      id: 'monthlyRate',
      // @ts-ignore
      header: <Small>Price</Small>,
      enableSorting: true,
      cell: (props) => {
        const monthlyCostCents = props.row.original.contract?.internetServicePlan?.monthlyCostCents;
        if (!monthlyCostCents) {
          return <RenderCentsCell value={0} />;
        }
        return <RenderCentsCell value={monthlyCostCents} />;
      },
    },
  ),
  // Renewal date
  colHelper.accessor(
    ({ contract }: ContractForDisplayType) => {
      if (!contract) {
        return null;
      }
      const { internetServicePlan } = contract;
      const { renewalDate, sid } = internetServicePlan || {};

      return `renewalDate-${renewalDate}-${sid}`;
    },
    {
      id: 'renewalDate',
      // @ts-ignore
      header: <Small>Renewal</Small>,
      enableSorting: true,
      cell: (props) => {
        const { contract } = props.row.original;
        if (!contract) {
          return <RenderDateCell />;
        }
        const { internetServicePlan } = contract;
        const { renewalDate } = internetServicePlan || {};
        return <RenderDateCell value={renewalDate} />;
      },
    },
  ),
  // Contract term
  colHelper.accessor(
    ({ contract }: ContractForDisplayType) => {
      const isp = contract?.internetServicePlan;
      if (!isp) {
        return null;
      }
      const { sid } = isp;

      return `contract-term-${sid}`;
    },
    {
      id: 'contractTerm',
      // @ts-ignore
      header: <Small>Contract term</Small>,
      enableSorting: true,
      cell: (props) => {
        const isp = props.row.original.contract?.internetServicePlan;
        if (!isp) {
          return null;
        }
        const { quote } = isp;

        const term = quoteToTerm(quote);
        if (!term) {
          return <EmDash />;
        }

        return <div>{term}</div>;
      },
    },
  ),
  // Created
  colHelper.accessor(
    ({ contract }: ContractForDisplayType) => {
      const isp = contract?.internetServicePlan;
      if (!isp) {
        return null;
      }
      const { createdAt, sid } = isp;

      return `createdAt-${createdAt}-${sid}`;
    },
    {
      id: 'created',
      // @ts-ignore
      header: <Small>Created</Small>,
      enableSorting: true,
      cell: (props) => {
        const { contract } = props.row.original;
        if (!contract) {
          return <RenderDateCell />;
        }
        const { internetServicePlan } = contract;
        const { createdAt } = internetServicePlan || {};
        return <RenderDateCell value={createdAt} />;
      },
    },
  ),
];

export const tableColumnNames = tableColumns.flatMap((col) => (col.id ? [col.id] : []));

import {
  Badge,
  CopyBox,
  DeviceTarget,
  HoverCard,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
} from '@meterup/atto';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import type { VLANUUID } from './NetworkWide/VLANs/utils';
import { paths } from '../constants';
import { useNetwork } from '../hooks/useNetworkFromPath';
import { Nav } from '../nav';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { FindLLDPEntryOnSwitchQuery, FindMACOnSwitchQuery } from './Hardware/Switches/utils';
import { vlansQuery } from './NetworkWide/VLANs/utils';
import { ReactRouterLink } from './ReactRouterLink';

type SwitchLookupMethod = 'mac-table' | 'lldp';

export const linkToSwitch = ({
  networkSlug,
  companyName,
  switchUUID,
}: {
  networkSlug: string;
  companyName: string;
  switchUUID: string;
}) =>
  Nav.makeTo({
    root: {
      pathname: makeLink(paths.pages.SwitchDetailPage, {
        companyName,
        networkSlug,
        uuid: switchUUID,
        tab: 'insights',
      }),
    },
  });

export const linkToSwitchPort = ({
  networkSlug,
  companyName,
  switchUUID,
  portUUID,
}: {
  networkSlug: string;
  companyName: string;
  switchUUID: string;
  portUUID: string;
}) =>
  Nav.makeTo({
    root: {
      pathname: makeLink(paths.pages.SwitchDetailPage, {
        companyName,
        networkSlug,
        uuid: switchUUID,
        tab: 'ports',
      }),
    },
    drawer: makeLink(paths.drawers.SwitchPortDetailPage, {
      companyName,
      networkSlug,
      virtualDeviceUUID: switchUUID,
      phyInterfaceUUID: portUUID,
    }),
  });

function SwitchConnectionSummaryListRows({
  switchUUID,
  switchLabel,
  portUUID,
  portNumber,
}: {
  switchUUID: string;
  switchLabel: string;
  portUUID: string;
  portNumber: number;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <>
      <SummaryListRow>
        <SummaryListKey>Switch</SummaryListKey>
        <SummaryListValue>
          {network ? (
            <DeviceTarget
              as={ReactRouterLink}
              to={linkToSwitch({
                companyName,
                networkSlug: network.slug,
                switchUUID,
              })}
              replace={false}
              type="switch"
              aria-label={`Go to ${switchLabel}`}
            >
              {switchLabel}
            </DeviceTarget>
          ) : (
            switchLabel
          )}
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Port</SummaryListKey>
        <SummaryListValue>
          {network ? (
            <DeviceTarget
              as={ReactRouterLink}
              to={linkToSwitchPort({
                companyName,
                networkSlug: network.slug,
                switchUUID,
                portUUID,
              })}
              replace={false}
              type="ethernet"
              aria-label={`Go to port ${portNumber}`}
            >
              {portNumber}
            </DeviceTarget>
          ) : (
            portNumber
          )}
        </SummaryListValue>
      </SummaryListRow>
    </>
  );
}

export function SwitchLLDPConnectionSummaryListRows({ macAddress }: { macAddress: string }) {
  const network = useNetwork();

  const device = useGraphQL(
    FindLLDPEntryOnSwitchQuery,
    { networkUUID: network.UUID, mac: macAddress },
    { useErrorBoundary: false },
  ).data?.findSwitchLLDPEntryForMAC;

  if (!device) return null;

  return (
    <SwitchConnectionSummaryListRows
      switchUUID={device.virtualDevice.UUID}
      switchLabel={device.virtualDevice.label}
      portUUID={device.phyInterface.UUID}
      portNumber={device.phyInterface.portNumber}
    />
  );
}

function useVLAN({ networkUUID, UUID }: { networkUUID: string; UUID: string }) {
  const vlansData = checkDefinedOrThrow(
    useGraphQL(vlansQuery, { networkUUID }).data?.vlans.find((vlan) => vlan.UUID === UUID),
    new ResourceNotFoundError('No VLAN found'),
  );
  return vlansData;
}

export function VLANConnectionSummaryListRows({ uuid }: { uuid: VLANUUID }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const vlan = useVLAN({ networkUUID: network.UUID, UUID: uuid });

  return (
    <SummaryListRow>
      <SummaryListKey>VLAN</SummaryListKey>
      <SummaryListValue>
        <HoverCard
          icon="vlan"
          heading={vlan.name}
          link={{
            as: Link,
            to: makeLink(paths.pages.VLANDetailsPage, {
              companyName,
              networkSlug: network.slug,
              uuid,
              tab: 'details',
            }),
          }}
          summary={[
            { label: 'ID', value: vlan.vlanID },
            { label: 'Description', value: vlan.description },
            {
              label: 'IP configuration',
              value:
                vlan.ipV4ClientGateway !== undefined ? (
                  <Text family="monospace">
                    <CopyBox aria-label="Copy IP configuration">
                      {vlan.ipV4ClientGateway}/{vlan.ipV4ClientPrefixLength}
                    </CopyBox>
                  </Text>
                ) : (
                  <Badge size="small" variant="neutral">
                    Layer 2
                  </Badge>
                ),
            },
          ]}
        >
          <DeviceTarget
            as={Link}
            to={makeLink(paths.pages.VLANDetailsPage, {
              companyName,
              networkSlug: network.slug,
              uuid,
              tab: 'details',
            })}
            aria-label={`Go to VLAN ${vlan.name}`}
            type="vlan"
          >
            {vlan.name}
          </DeviceTarget>
        </HoverCard>
      </SummaryListValue>
    </SummaryListRow>
  );
}

export function SwitchMACTableConnectionSummaryListRows({ macAddress }: { macAddress: string }) {
  const network = useNetwork();

  const connectedSwitches = useGraphQL(
    FindMACOnSwitchQuery,
    { networkUUID: network.UUID, mac: macAddress },
    { useErrorBoundary: false },
  ).data?.findSwitchesForClientMAC;

  if (!connectedSwitches || connectedSwitches.length === 0) return null;
  const device = connectedSwitches[0];

  return (
    <SwitchConnectionSummaryListRows
      switchUUID={device.virtualDevice.UUID}
      switchLabel={device.virtualDevice.label}
      portUUID={device.phyInterface.UUID}
      portNumber={device.phyInterface.portNumber}
    />
  );
}

export function ConnectedSwitchSection({
  macAddress,
  lookupMethod,
  relation,
}: {
  macAddress: string;
  lookupMethod: SwitchLookupMethod;
  relation?: 'stacked' | 'standalone';
}) {
  const connection =
    lookupMethod === 'mac-table'
      ? SwitchMACTableConnectionSummaryListRows({ macAddress })
      : SwitchLLDPConnectionSummaryListRows({ macAddress });
  if (!connection) return null;

  return (
    <Section relation={relation}>
      <SectionHeader heading="Connection" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">{connection}</SummaryList>
      </SectionContent>
    </Section>
  );
}

import type { SummaryListProps } from '@meterup/atto';
import type { ResultItem } from '@meterup/graphql';
import {
  Badge,
  Button,
  CopyBox,
  DeviceTarget,
  HStack,
  Icon,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  Text,
  VStack,
} from '@meterup/atto';
import { Link } from 'react-router-dom';

import type { ListIsPsQueryQuery } from '../../../gql/graphql';
import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { screamingSnakeCaseToWords } from '../../../utils/strings';
import { CopyableMonoOrNoValue } from '../../Devices/Insights';
import useCurrentControllers from '../../Hardware/SecurityAppliance/useCurrentControllers';

type ISPMetadataProps = {
  isp: ResultItem<ListIsPsQueryQuery, 'internetServicePlans'>;
};

export function ISPMetadata({ isp }: ISPMetadataProps) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const { virtualDevices } = useCurrentControllers();
  const hasSecurityAppliance = virtualDevices.find(
    (device) => device.label === isp.controllerName,
  )?.UUID;
  const metadataPairs: SummaryListProps['pairs'] = [
    { label: 'Product', value: screamingSnakeCaseToWords(isp.connectionProduct) },
    ...(isp?.controllerName
      ? [
          {
            label: 'Security appliance',
            value: hasSecurityAppliance ? (
              <DeviceTarget
                as={Link}
                to={makeLink(paths.pages.SecurityApplianceDetailPage, {
                  companyName,
                  networkSlug: network.slug,
                  uuid: hasSecurityAppliance,
                  tab: 'insights',
                })}
                type="security-appliance"
                aria-label={`Go to ${isp.controllerName}`}
              >
                {isp.controllerName}
              </DeviceTarget>
            ) : (
              isp.controllerName
            ),
          },
        ]
      : []),
  ];

  return (
    <Section relation="stacked">
      <SectionHeader heading="Metadata" />
      <SectionContent gutter="all">
        <SummaryList gutter="none" pairs={metadataPairs} />
      </SectionContent>
    </Section>
  );
}

export function ISPConnect() {
  const pairs: SummaryListProps['pairs'] = [
    {
      label: 'Contract',
      value: (
        <Badge ends="pill" size="small" variant="positive">
          Active
        </Badge>
      ),
    },
    {
      label: 'Account number',
      value: (
        <Text family="monospace">
          <CopyBox aria-label="Copy account number">#MB-0293-9200</CopyBox>
        </Text>
      ),
    },
    {
      label: 'Speeds',
      value: (
        <VStack spacing={space(4)}>
          <HStack align="center" spacing={space(4)} wrap="no-wrap">
            <Icon icon="download" size={space(12)} />
            <Text>1,500 Mbps</Text>
          </HStack>
          <HStack align="center" spacing={space(4)} wrap="no-wrap">
            <Icon icon="upload" size={space(12)} />
            <Text>500 Mbps</Text>
          </HStack>
        </VStack>
      ),
    },
    { label: 'Monthly price', value: '$1,289.50 USD' },
  ];

  return (
    <Section relation="stacked">
      <SectionHeader
        heading="Connect"
        actions={
          <Button icon="arrow-right" variant="secondary">
            Go to Connect
          </Button>
        }
      />
      <SectionContent gutter="all">
        <SummaryList gutter="none" pairs={pairs} />
      </SectionContent>
    </Section>
  );
}

type ISPIPConfigurationProps = {
  isp: ResultItem<ListIsPsQueryQuery, 'internetServicePlans'>;
};

export function ISPIPConfiguration({ isp }: ISPIPConfigurationProps) {
  const ipPairs: SummaryListProps['pairs'] = [
    {
      label: 'Type',
      value: (
        <Badge size="small" variant="neutral">
          {isp.hasStaticIP ? 'Static' : 'Dynamic'}
        </Badge>
      ),
    },
  ];
  if (isp.gatewayAddr) {
    ipPairs.push({
      label: 'Gateway address',
      value: <CopyableMonoOrNoValue label={isp.gatewayAddr} value={isp.gatewayAddr} />,
    });
  }
  if (isp.staticIPRange) {
    ipPairs.push({
      label: 'Static IP range',
      value: <CopyableMonoOrNoValue label={isp.staticIPRange} value={isp.staticIPRange} />,
    });
  }
  if (isp.firstUsableIP) {
    ipPairs.push({
      label: 'First usable IP',
      value: <CopyableMonoOrNoValue label={isp.firstUsableIP} value={isp.firstUsableIP} />,
    });
  }
  if (isp.lastUsableIP) {
    ipPairs.push({
      label: 'Last usable IP',
      value: <CopyableMonoOrNoValue label={isp.lastUsableIP} value={isp.lastUsableIP} />,
    });
  }

  return (
    <Section relation="stacked">
      <SectionHeader heading="DHCP configuration" />
      <SectionContent gutter="all">
        <SummaryList gutter="none" pairs={ipPairs} />
      </SectionContent>
    </Section>
  );
}

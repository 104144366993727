import React from 'react';

import { Small } from '@meterup/atto';

export type RegionCellProps = {
  city: string;
  state: string;
};

export default function RegionCell({ city, state }: RegionCellProps) {
  return (
    <Small>
      {city}, {state}
    </Small>
  );
}

import { Pane, PaneContent, PaneHeader, SearchInput } from '@meterup/atto';

import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useNetworkWideCrumbs } from '../../../utils/routing';
import { ReactRouterLink } from '../../ReactRouterLink';
import CaptivePortalsList from './CaptivePortalsList';

export default function CaptivePortals() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const networkWideCrumb = useNetworkWideCrumbs();
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...networkWideCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.CaptivePortalsPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'Captive portals',
            },
          },
        ]}
        icon="captive-portal"
        heading="Captive portals"
        actions={
          <SearchInput
            placeholder="..."
            aria-label="Filter access points"
            scope="scoped"
            value={globalFilter}
            onChange={setGlobalFilter}
            minWidth="56px"
          />
        }
      />
      <PaneContent gutter="bottom">
        <CaptivePortalsList />
      </PaneContent>
    </Pane>
  );
}

import {
  Button,
  HStack,
  LoadingIcon,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { styled } from '@stitches/react';

import { graphql } from '../../../gql';
import { RpcCableTestTwistedPairStatus } from '../../../gql/graphql';

interface CableTestProps {
  serialNumber: string;
  portNumber: number;
}

const portCableTestMutationDoc = graphql(`
  mutation PortCableTestMutation($serialNumber: String!, $portNumber: Int!) {
    rpcPortCableTest(serialNumber: $serialNumber, portNumber: $portNumber) {
      pairA {
        status
      }
      pairB {
        status
      }
      pairC {
        status
      }
      pairD {
        status
      }
    }
  }
`);

const LoadingIconContainer = styled(HStack, {
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '$44',
});

const cableStatusToHumanStatus = (status: RpcCableTestTwistedPairStatus) => {
  switch (status) {
    case RpcCableTestTwistedPairStatus.Open:
      return 'Not connected';
    case RpcCableTestTwistedPairStatus.Short:
      return 'Short';
    case RpcCableTestTwistedPairStatus.Good:
      return 'Good';
    case RpcCableTestTwistedPairStatus.OpenShort:
      return 'Open, short';
    case RpcCableTestTwistedPairStatus.Crosstalk:
      return 'Crosstalk (bad cabling)';
    default:
      return 'Unknown';
  }
};

function CableTest({ serialNumber, portNumber }: CableTestProps) {
  const portCableTestMutation = useGraphQLMutation(portCableTestMutationDoc);

  const runTest = () => {
    portCableTestMutation.mutate(
      { serialNumber, portNumber },
      {
        onSuccess: async () => {},
        onError: () => {
          notify('There was an error running the cable test.', {
            variant: 'negative',
            icon: 'cross',
          });
        },
      },
    );
  };

  const pairResults = portCableTestMutation.data?.rpcPortCableTest
    ? [
        { pair: 'A', status: portCableTestMutation.data.rpcPortCableTest.pairA.status },
        { pair: 'B', status: portCableTestMutation.data.rpcPortCableTest.pairB.status },
        { pair: 'C', status: portCableTestMutation.data.rpcPortCableTest.pairC.status },
        { pair: 'D', status: portCableTestMutation.data.rpcPortCableTest.pairD.status },
      ]
    : [];

  return (
    <Section relation="stacked">
      <SectionHeader heading="Cable test" />
      <SectionContent gutter="all">
        {portCableTestMutation.isLoading ? (
          <HStack>
            <LoadingIconContainer>
              <LoadingIcon color={{ light: 'gray500', dark: 'gray300' }} size={space(24)} />
            </LoadingIconContainer>
          </HStack>
        ) : (
          <VStack spacing={space(12)}>
            {pairResults.length > 0 && (
              <SummaryList gutter="none">
                {pairResults.map((pairResult) => (
                  <SummaryListRow key={pairResult.pair}>
                    <SummaryListKey>Pair {pairResult.pair}</SummaryListKey>
                    <SummaryListValue>
                      {cableStatusToHumanStatus(pairResult.status)}
                    </SummaryListValue>
                  </SummaryListRow>
                ))}
              </SummaryList>
            )}
            <Button
              icon="play"
              arrangement="leading-icon"
              variant="secondary"
              size="large"
              onClick={runTest}
              width="100%"
            >
              Run a cable test
            </Button>
          </VStack>
        )}
      </SectionContent>
    </Section>
  );
}

export default CableTest;

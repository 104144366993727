import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Outlet } from 'react-router';

import { DockPageLayout } from '../../../../components/DockPageLayout';
import { MainAndDrawerLayout } from '../../../../components/MainAndDrawerLayout';
import { DrawerRoutes } from '../../../../components/route_elements';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/support',
  name: 'SupportLayout',
  layout: 'AppLayout',
});

export default function SupportLayout() {
  return (
    <DockPageLayout main={<MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />} />
  );
}

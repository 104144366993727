import type { ReactNode } from 'react';
import {
  Alert,
  BaseInput,
  Body,
  Button,
  colors,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  fontWeights,
  HStack,
  Link,
  Shortcut,
  space,
  styled,
} from '@meterup/atto';
import { notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { z } from 'zod';

import { CreateClientVpnClientInputSchema } from '../../gql/zod-types';
import { useIsCurrentCompanyAdmin } from '../../hooks/authorization';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../utils/withZodSchema';
import { FieldProvider } from '../Form/FieldProvider';
import { TextField } from '../Form/Fields';
import { DetailLoadingFallback } from '../Placeholders/DetailLoadingFallback';
import {
  clientVPNClientQuery,
  clientVPNClientsQuery,
  clientVPNServerQuery,
  clientVPNServersQuery,
  createClientVPNClientMutation,
  generateKeyPair,
  wireguardConfig,
} from './utils';

const createClientInputSchema = CreateClientVpnClientInputSchema.extend({
  publicKey: z.string().nonempty({ message: 'Please provide a public key.' }),
  name: z.string().nonempty({ message: 'Please provide a client name.' }),
  email: z.string().email({ message: 'Please provide a valid email address.' }),
});

type CreateClientInputValues = z.input<typeof createClientInputSchema>;

const StepTitle = styled(Body, {
  fontWeight: fontWeights.bold,
  color: colors.gray700,
});

function StepHeader({ stepNumber, title }: { stepNumber: ReactNode; title: ReactNode }) {
  return (
    <HStack align="center" spacing={space(6)}>
      <Shortcut keys={[stepNumber]} />
      <StepTitle>{title}</StepTitle>
    </HStack>
  );
}

type DownloadWGStepProps = {
  createdClientId: string;
  onDoneClick: () => void;
  serverUUID: string;
  privateKey: string;
};
function DownloadWGStep({
  createdClientId,
  onDoneClick,
  serverUUID,
  privateKey,
}: DownloadWGStepProps) {
  const selectedServer = useGraphQL(clientVPNServerQuery, { UUID: serverUUID }).data
    ?.clientVPNServer;
  const createdClient = useGraphQL(
    clientVPNClientQuery,
    { UUID: createdClientId },
    { enabled: !!createdClientId },
  ).data?.clientVPNClient;

  const [fileDownloaded, setFileDownloaded] = useState(false);
  const WGConfStr = useMemo(() => {
    if (selectedServer && createdClient) {
      return wireguardConfig({
        server: selectedServer,
        client: createdClient,
        privateKey,
      });
    }
    return '';
  }, [selectedServer, createdClient, privateKey]);

  const onDownloadFile = useCallback(() => {
    const blob = new Blob([WGConfStr], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    const filename = `meter-${createdClient?.name ?? 'client'}`
      .replace(/([^a-z0-9()_-]+)/gi, '')
      .replace(' ', '-')
      .substring(0, 100);

    link.download = `${filename}.conf`;
    link.href = url;
    link.click();
    setFileDownloaded(true);
  }, [WGConfStr, createdClient]);
  return (
    <>
      <DrawerContent>
        <StepHeader stepNumber="2" title="Setup" />
        <Body>
          Now download the unique configuration file for <strong>{createdClient?.userEmail}</strong>{' '}
          and securely send it to them along with the guide:{' '}
          <Link
            href="https://meterup.zendesk.com/hc/en-us/articles/11587611861005-How-to-connect-your-Meter-Network-remotely-Client-VPN-"
            target="_blank"
            rel="noreferrer"
          >
            How to connect to your Meter Network remotely.
          </Link>
        </Body>
        <Button
          size="large"
          variant="secondary"
          arrangement="leading-icon"
          icon="download"
          width="100%"
          onClick={onDownloadFile}
        >
          WireGuard config file
        </Button>
        <Alert
          icon="information"
          heading="We don't store private keys"
          copy={`This configuration file contains a unique private\n 
           key that we don't store. Once you leave this setup flow,\n
           you won't be able to re-download the same configuration file for ${createdClient?.userEmail}.`}
        />
      </DrawerContent>
      <DrawerFooter
        actions={
          <Button type="submit" variant="primary" onClick={onDoneClick} disabled={!fileDownloaded}>
            Done
          </Button>
        }
      />
    </>
  );
}

export default function CreateClientVPNClientDrawer({ serverUUID }: { serverUUID: string }) {
  const isCompanyAdmin = useIsCurrentCompanyAdmin();
  if (!isCompanyAdmin) {
    throw new ResourceNotFoundError('Page not found');
  }

  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();
  const [step, setStep] = useState(1);
  const [createdClientId, setCreatedClientId] = useState<string | null>(null);
  const keypair = useMemo(() => generateKeyPair(), []);

  const { mutate, isLoading } = useGraphQLMutation(createClientVPNClientMutation);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    (input: CreateClientInputValues) => {
      mutate(
        { serverUUID, input },
        {
          onSuccess(response) {
            if (response?.createClientVPNClient.UUID) {
              setCreatedClientId(response.createClientVPNClient.UUID);
            }
            setStep(2);
            queryClient.invalidateQueries(
              makeQueryKey(clientVPNServersQuery, { networkUUID: network.UUID }),
            );
            queryClient.invalidateQueries(makeQueryKey(clientVPNClientsQuery, { serverUUID }));
            notify('Successfully created client.', {
              variant: 'positive',
            });
          },
          onError(err) {
            notify(
              `There was a problem creating the client${getGraphQLErrorMessageOrEmpty(err)}.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [network.UUID, serverUUID, mutate, queryClient],
  );

  return (
    <Drawer>
      <DrawerHeader icon="client" heading="Add VPN client" onClose={closeDrawer} />
      {step === 1 && (
        <Formik<CreateClientInputValues>
          initialValues={{
            publicKey: keypair.publicKey,
            name: '',
            email: '',
          }}
          onSubmit={handleSubmit}
          validate={withZodSchema(createClientInputSchema)}
        >
          <Form>
            <DrawerContent gutter="all">
              <StepHeader stepNumber="1" title="VPN profile" />
              <TextField
                name="email"
                label="Email address"
                type="email"
                description="Who will be using this profile?"
              />
              <TextField
                name="name"
                label="Name"
                description="A distinct name used to identify this VPN profile in the dashboard."
              />
              <FieldProvider name="publicKey">
                <BaseInput
                  css={{ visibility: 'hidden' }}
                  inputProps={{
                    type: 'hidden',
                    value: keypair.publicKey,
                    'aria-label': 'Public key',
                  }}
                />
              </FieldProvider>
            </DrawerContent>
            <DrawerFooter
              actions={
                <>
                  <Button type="button" onClick={closeDrawer} variant="secondary">
                    Cancel
                  </Button>
                  <Button loading={isLoading} type="submit" variant="primary">
                    Add
                  </Button>
                </>
              }
            />
          </Form>
        </Formik>
      )}
      {step === 2 && createdClientId && (
        <Suspense fallback={<DetailLoadingFallback />}>
          <DownloadWGStep
            createdClientId={createdClientId}
            onDoneClick={closeDrawer}
            serverUUID={serverUUID}
            privateKey={keypair.privateKey}
          />
        </Suspense>
      )}
    </Drawer>
  );
}

import type { api } from '@meterup/proto';
import { isDefined } from '@meterup/common';
import { useMemo } from 'react';

import APIv2 from '../api/apiv2';
import { useControllerDataFromPathOrNull } from '../hooks/useControllerDataFromPath';
import { useFeatureFlags } from '../hooks/useFeatureFlags';

export const useAPIClient = (controller_data?: api.ControllerResponse) => {
  const { currentController } = useControllerDataFromPathOrNull();
  const flags = useFeatureFlags();

  if (!isDefined(controller_data) && !isDefined(currentController)) {
    throw new Error(
      'Must pass an `api.ControllerResponse` as the first argument to `useAPIClient` if being used outside of the context of a currentController.',
    );
  }

  const client = useMemo(
    () => new APIv2(controller_data ?? currentController!, flags),
    [controller_data, currentController, flags],
  );

  return client;
};

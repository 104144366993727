import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Text,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import type { AutoVPNGroup, AutoVPNMember } from './utils';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetworksForCompany } from '../../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { AutoVPNGroupsQuery, removeNetworkFromAutoVPNGroupMutation } from './utils';

type AutoVPNMemberDeleteDialogProps = {
  group: AutoVPNGroup;
  member: AutoVPNMember;
  state: OverlayTriggerState;
};

export default function AutoVPNMemberDeleteDialog({
  group,
  member,
  state,
}: AutoVPNMemberDeleteDialogProps) {
  const companySlug = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();

  const networks = useNetworksForCompany(companySlug);

  const selectedNetwork = useMemo(
    () => networks.find((network) => network.UUID === member.networkUUID),
    [networks, member.networkUUID],
  );

  const removeNetwork = useGraphQLMutation(removeNetworkFromAutoVPNGroupMutation);
  const { mutate, isError, error } = removeNetwork;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    if (!group.UUID) return;

    mutate(
      { groupUUID: group.UUID, networkUUID: member.networkUUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupsQuery, { companySlug }));
          closeDrawer();
          close();
          notify('Network removed from Auto VPN group successfully.', {
            variant: 'positive',
          });
        },
      },
    );
  }, [mutate, queryClient, close, closeDrawer, companySlug, group.UUID, member.networkUUID]);

  if (!group) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Remove network from Auto VPN group" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to remove the network{' '}
              <Text weight="bold">{selectedNetwork?.label ?? member.networkUUID}</Text> from the
              Auto VPN group <Text weight="bold">{group.name}</Text>.
            </>
          }
        />

        {isError && (
          <Alert
            icon="warning"
            variant="negative"
            heading="There was an error removing the Auto VPN member"
            copy={error && error instanceof Error ? error.message : undefined}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

export const apDB: {
  [key: string]: {
    ipAddress: string;
    macAddress: string;
    deviceID: string;
    physicalLocation: string;
    fiveGChannel: number;
    fiveGPower: number;
    twoGChannel: number;
    twoGPower: number;
  };
} = {
  'meter-mw08-000001': {
    macAddress: '27:9E:3C:2B:AA:9C',
    ipAddress: '10.100.0.1',
    deviceID: '000001',
    physicalLocation: 'AP 6.1',
    fiveGChannel: 140,
    fiveGPower: 5,
    twoGChannel: 1,
    twoGPower: 15,
  },
  'meter-mw08-000002': {
    macAddress: '42:7C:DF:AE:6A:3C',
    ipAddress: '10.100.0.2',
    deviceID: '000002',
    physicalLocation: 'AP 7.1',
    fiveGChannel: 108,
    fiveGPower: 18,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000003': {
    macAddress: '9A:6A:C2:9D:E5:B7',
    ipAddress: '10.100.0.3',
    deviceID: '000003',
    physicalLocation: 'AP 8.1',
    fiveGChannel: 157,
    fiveGPower: 9,
    twoGChannel: 11,
    twoGPower: 7,
  },
  'meter-mw08-000004': {
    macAddress: '44:DC:B7:79:2B:AF',
    ipAddress: '10.100.0.4',
    deviceID: '000004',
    physicalLocation: 'AP 1.1',
    fiveGChannel: 100,
    fiveGPower: 5,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000005': {
    macAddress: 'DA:A2:96:48:39:D0',
    ipAddress: '10.100.0.5',
    deviceID: '000005',
    physicalLocation: 'AP 2.1',
    fiveGChannel: 140,
    fiveGPower: 15,
    twoGChannel: 6,
    twoGPower: 7,
  },
  'meter-mw08-000006': {
    macAddress: 'F9:B5:A2:1F:B9:CB',
    ipAddress: '10.100.0.6',
    deviceID: '000006',
    physicalLocation: 'AP 3.1',
    fiveGChannel: 157,
    fiveGPower: 18,
    twoGChannel: 11,
    twoGPower: 7,
  },
  'meter-mw08-000007': {
    macAddress: '31:68:9C:EE:2F:8B',
    ipAddress: '10.100.0.7',
    deviceID: '000007',
    physicalLocation: 'AP 4.1',
    fiveGChannel: 108,
    fiveGPower: 9,
    twoGChannel: 11,
    twoGPower: 7,
  },
  'meter-mw08-000008': {
    macAddress: '38:9B:AC:48:9A:81',
    ipAddress: '10.100.0.8',
    deviceID: '000008',
    physicalLocation: 'AP 5.1',
    fiveGChannel: 40,
    fiveGPower: 18,
    twoGChannel: 1,
    twoGPower: 15,
  },
  'meter-mw08-000009': {
    macAddress: 'AC:9C:53:F9:17:25',
    ipAddress: '10.100.0.9',
    deviceID: '000009',
    physicalLocation: 'AP 6.2',
    fiveGChannel: 157,
    fiveGPower: 9,
    twoGChannel: 6,
    twoGPower: 7,
  },
  'meter-mw08-000010': {
    macAddress: '92:2C:17:70:5C:43',
    ipAddress: '10.100.0.10',
    deviceID: '000010',
    physicalLocation: 'AP 7.2',
    fiveGChannel: 36,
    fiveGPower: 5,
    twoGChannel: 1,
    twoGPower: 15,
  },
  'meter-mw08-000011': {
    macAddress: 'A2:A4:13:C6:1B:AD',
    ipAddress: '10.100.0.11',
    deviceID: '000011',
    physicalLocation: 'AP 8.2',
    fiveGChannel: 48,
    fiveGPower: 15,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000012': {
    macAddress: 'FA:73:98:8A:64:36',
    ipAddress: '10.100.0.12',
    deviceID: '000012',
    physicalLocation: 'AP 1.2',
    fiveGChannel: 149,
    fiveGPower: 5,
    twoGChannel: 6,
    twoGPower: 7,
  },
  'meter-mw08-000013': {
    macAddress: 'A4:50:56:B6:6A:32',
    ipAddress: '10.100.0.13',
    deviceID: '000013',
    physicalLocation: 'AP 2.2',
    fiveGChannel: 40,
    fiveGPower: 9,
    twoGChannel: 6,
    twoGPower: 15,
  },
  'meter-mw08-000014': {
    macAddress: '34:FF:D5:BF:82:6B',
    ipAddress: '10.100.0.14',
    deviceID: '000014',
    physicalLocation: 'AP 3.2',
    fiveGChannel: 149,
    fiveGPower: 1,
    twoGChannel: 6,
    twoGPower: 7,
  },
  'meter-mw08-000015': {
    macAddress: 'C0:45:2B:0A:4B:B9',
    ipAddress: '10.100.0.15',
    deviceID: '000015',
    physicalLocation: 'AP 4.2',
    fiveGChannel: 108,
    fiveGPower: 5,
    twoGChannel: 11,
    twoGPower: 7,
  },
  'meter-mw08-000016': {
    macAddress: 'DD:A9:2E:94:E6:5F',
    ipAddress: '10.100.0.16',
    deviceID: '000016',
    physicalLocation: 'AP 5.2',
    fiveGChannel: 161,
    fiveGPower: 1,
    twoGChannel: 6,
    twoGPower: 15,
  },
  'meter-mw08-000017': {
    macAddress: 'C5:70:23:ED:F6:95',
    ipAddress: '10.100.0.17',
    deviceID: '000017',
    physicalLocation: 'AP 6.3',
    fiveGChannel: 48,
    fiveGPower: 5,
    twoGChannel: 1,
    twoGPower: 15,
  },
  'meter-mw08-000018': {
    macAddress: '7E:B7:F5:67:C8:86',
    ipAddress: '10.100.0.18',
    deviceID: '000018',
    physicalLocation: 'AP 7.3',
    fiveGChannel: 60,
    fiveGPower: 1,
    twoGChannel: 6,
    twoGPower: 7,
  },
  'meter-mw08-000019': {
    macAddress: 'D8:E6:22:03:13:44',
    ipAddress: '10.100.0.19',
    deviceID: '000019',
    physicalLocation: 'AP 8.3',
    fiveGChannel: 157,
    fiveGPower: 18,
    twoGChannel: 1,
    twoGPower: 7,
  },
  'meter-mw08-000020': {
    macAddress: '4A:26:E8:EA:D3:DC',
    ipAddress: '10.100.0.20',
    deviceID: '000020',
    physicalLocation: 'AP 1.3',
    fiveGChannel: 44,
    fiveGPower: 15,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000021': {
    macAddress: '12:B9:A4:BF:41:1F',
    ipAddress: '10.100.0.21',
    deviceID: '000021',
    physicalLocation: 'AP 2.3',
    fiveGChannel: 100,
    fiveGPower: 9,
    twoGChannel: 11,
    twoGPower: 7,
  },
  'meter-mw08-000022': {
    macAddress: 'AA:ED:B8:08:D8:CE',
    ipAddress: '10.100.0.22',
    deviceID: '000022',
    physicalLocation: 'AP 3.3',
    fiveGChannel: 140,
    fiveGPower: 5,
    twoGChannel: 6,
    twoGPower: 15,
  },
  'meter-mw08-000023': {
    macAddress: 'CF:1F:B0:31:B6:A8',
    ipAddress: '10.100.0.23',
    deviceID: '000023',
    physicalLocation: 'AP 4.3',
    fiveGChannel: 157,
    fiveGPower: 9,
    twoGChannel: 1,
    twoGPower: 15,
  },
  'meter-mw08-000024': {
    macAddress: 'BC:5F:91:77:6F:56',
    ipAddress: '10.100.0.24',
    deviceID: '000024',
    physicalLocation: 'AP 5.3',
    fiveGChannel: 157,
    fiveGPower: 15,
    twoGChannel: 1,
    twoGPower: 15,
  },
  'meter-mw08-000025': {
    macAddress: '6C:38:FA:6E:68:14',
    ipAddress: '10.100.0.25',
    deviceID: '000025',
    physicalLocation: 'AP 6.4',
    fiveGChannel: 36,
    fiveGPower: 9,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000026': {
    macAddress: '01:A8:08:01:01:12',
    ipAddress: '10.100.0.26',
    deviceID: '000026',
    physicalLocation: 'AP 7.4',
    fiveGChannel: 36,
    fiveGPower: 15,
    twoGChannel: 6,
    twoGPower: 15,
  },
  'meter-mw08-000027': {
    macAddress: '49:DB:5B:82:CC:8F',
    ipAddress: '10.100.0.27',
    deviceID: '000027',
    physicalLocation: 'AP 8.4',
    fiveGChannel: 108,
    fiveGPower: 1,
    twoGChannel: 6,
    twoGPower: 15,
  },
  'meter-mw08-000028': {
    macAddress: '7C:14:C5:AB:63:6B',
    ipAddress: '10.100.0.28',
    deviceID: '000028',
    physicalLocation: 'AP 1.4',
    fiveGChannel: 40,
    fiveGPower: 5,
    twoGChannel: 6,
    twoGPower: 7,
  },
  'meter-mw08-000029': {
    macAddress: 'CD:95:36:F9:CE:C6',
    ipAddress: '10.100.0.29',
    deviceID: '000029',
    physicalLocation: 'AP 2.4',
    fiveGChannel: 44,
    fiveGPower: 15,
    twoGChannel: 6,
    twoGPower: 15,
  },
  'meter-mw08-000030': {
    macAddress: '5D:30:6C:5F:7A:CD',
    ipAddress: '10.100.0.30',
    deviceID: '000030',
    physicalLocation: 'AP 3.4',
    fiveGChannel: 40,
    fiveGPower: 15,
    twoGChannel: 6,
    twoGPower: 7,
  },
  'meter-mw08-000031': {
    macAddress: '70:4D:0C:DB:A3:42',
    ipAddress: '10.100.0.31',
    deviceID: '000031',
    physicalLocation: 'AP 4.4',
    fiveGChannel: 40,
    fiveGPower: 5,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000032': {
    macAddress: 'CB:5D:74:8B:0B:1A',
    ipAddress: '10.100.0.32',
    deviceID: '000032',
    physicalLocation: 'AP 5.4',
    fiveGChannel: 60,
    fiveGPower: 9,
    twoGChannel: 11,
    twoGPower: 7,
  },
  'meter-mw08-000033': {
    macAddress: '64:4D:6E:50:4E:5B',
    ipAddress: '10.100.0.33',
    deviceID: '000033',
    physicalLocation: 'AP 6.5',
    fiveGChannel: 52,
    fiveGPower: 1,
    twoGChannel: 11,
    twoGPower: 7,
  },
  'meter-mw08-000034': {
    macAddress: 'CC:B1:D1:FB:4B:60',
    ipAddress: '10.100.0.34',
    deviceID: '000034',
    physicalLocation: 'AP 7.5',
    fiveGChannel: 149,
    fiveGPower: 1,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000035': {
    macAddress: 'C2:C5:B3:8E:F6:79',
    ipAddress: '10.100.0.35',
    deviceID: '000035',
    physicalLocation: 'AP 8.5',
    fiveGChannel: 40,
    fiveGPower: 1,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000036': {
    macAddress: '94:E1:CA:82:4C:8F',
    ipAddress: '10.100.0.36',
    deviceID: '000036',
    physicalLocation: 'AP 1.5',
    fiveGChannel: 108,
    fiveGPower: 15,
    twoGChannel: 6,
    twoGPower: 15,
  },
  'meter-mw08-000037': {
    macAddress: '54:A2:94:44:96:BE',
    ipAddress: '10.100.0.37',
    deviceID: '000037',
    physicalLocation: 'AP 2.5',
    fiveGChannel: 161,
    fiveGPower: 5,
    twoGChannel: 6,
    twoGPower: 7,
  },
  'meter-mw08-000038': {
    macAddress: 'BC:05:67:31:53:B9',
    ipAddress: '10.100.0.38',
    deviceID: '000038',
    physicalLocation: 'AP 3.5',
    fiveGChannel: 36,
    fiveGPower: 18,
    twoGChannel: 11,
    twoGPower: 7,
  },
  'meter-mw08-000039': {
    macAddress: 'C2:5D:77:7C:93:EC',
    ipAddress: '10.100.0.39',
    deviceID: '000039',
    physicalLocation: 'AP 4.5',
    fiveGChannel: 48,
    fiveGPower: 1,
    twoGChannel: 11,
    twoGPower: 15,
  },
  'meter-mw08-000040': {
    macAddress: 'B2:7A:10:E0:59:B9',
    ipAddress: '10.100.0.40',
    deviceID: '000040',
    physicalLocation: 'AP 5.5',
    fiveGChannel: 157,
    fiveGPower: 1,
    twoGChannel: 11,
    twoGPower: 15,
  },
};

export const clientDB: {
  [key: string]: {
    ipAddress: string;
    apName: string;
    name: string;
    signal: number;
    history: string[];
    offsetInSeconds: number;
  };
} = {
  '3C:1E:B5:B5:7D:FA': {
    ipAddress: '10.1.171.194',
    apName: '',
    name: "Ling's MBP-2",
    signal: -55,
    history: [],
    offsetInSeconds: 148,
  },
  'EC:A9:07:63:B8:90': {
    ipAddress: '10.1.53.161',
    apName: 'meter-mw08-000004',
    name: "Yan Rodriguez's MBP-2",
    signal: -67,
    history: [
      'meter-mw08-000013',
      'meter-mw08-000002',
      'meter-mw08-000038',
      'meter-mw08-000014',
      'meter-mw08-000018',
      'meter-mw08-000020',
      'meter-mw08-000035',
    ],
    offsetInSeconds: 153,
  },
  'BF:9F:0C:CC:11:61': {
    ipAddress: '10.1.151.194',
    apName: '',
    name: '*',
    signal: -74,
    history: [],
    offsetInSeconds: 129,
  },
  '00:04:0F:F2:FD:01': {
    ipAddress: '10.1.95.21',
    apName: 'meter-mw08-000001',
    name: "Maxim Harris's ZenBook Flip",
    signal: -54,
    history: [
      'meter-mw08-000026',
      'meter-mw08-000003',
      'meter-mw08-000002',
      'meter-mw08-000021',
      'meter-mw08-000017',
      'meter-mw08-000023',
      'meter-mw08-000015',
      'meter-mw08-000016',
      'meter-mw08-000033',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 82,
  },
  'E8:71:8D:65:D8:EF': {
    ipAddress: '10.1.254.38',
    apName: 'meter-mw08-000025',
    name: 'Echo',
    signal: -70,
    history: [
      'meter-mw08-000032',
      'meter-mw08-000038',
      'meter-mw08-000033',
      'meter-mw08-000007',
      'meter-mw08-000001',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 143,
  },
  '28:18:78:03:A3:51': {
    ipAddress: '10.1.31.72',
    apName: 'meter-mw08-000022',
    name: "Emma's Surface Pro",
    signal: -61,
    history: ['meter-mw08-000035'],
    offsetInSeconds: 88,
  },
  '78:67:D7:F3:58:DD': {
    ipAddress: '10.1.168.80',
    apName: 'meter-mw08-000007',
    name: "Inès's iPad",
    signal: -53,
    history: ['meter-mw08-000023'],
    offsetInSeconds: 251,
  },
  '10:B0:A0:E0:B1:B0': {
    ipAddress: '10.1.44.196',
    apName: 'meter-mw08-000001',
    name: '*',
    signal: -74,
    history: ['meter-mw08-000024', 'meter-mw08-000014'],
    offsetInSeconds: 180,
  },
  '14:20:5E:F5:89:63': {
    ipAddress: '10.1.202.255',
    apName: 'meter-mw08-000022',
    name: "Divya White's MacBook",
    signal: -62,
    history: ['meter-mw08-000023'],
    offsetInSeconds: 264,
  },
  '40:E6:4B:0D:E1:82': {
    ipAddress: '10.1.231.198',
    apName: 'meter-mw08-000008',
    name: "Hugo Wright's iPad",
    signal: -72,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000016',
      'meter-mw08-000025',
      'meter-mw08-000009',
      'meter-mw08-000023',
      'meter-mw08-000002',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 29,
  },
  '18:E7:B0:0E:EB:98': {
    ipAddress: '10.1.15.79',
    apName: '',
    name: "Olivia's iPad",
    signal: -53,
    history: [],
    offsetInSeconds: 286,
  },
  '44:07:0B:F4:C2:E1': {
    ipAddress: '10.1.183.166',
    apName: 'meter-mw08-000019',
    name: "Raj's Chromebook",
    signal: -57,
    history: [
      'meter-mw08-000016',
      'meter-mw08-000023',
      'meter-mw08-000027',
      'meter-mw08-000030',
      'meter-mw08-000005',
      'meter-mw08-000010',
      'meter-mw08-000007',
    ],
    offsetInSeconds: 155,
  },
  '00:0A:95:A6:7C:56': {
    ipAddress: '10.1.86.91',
    apName: 'meter-mw08-000022',
    name: "Manon's iPad",
    signal: -46,
    history: ['meter-mw08-000025'],
    offsetInSeconds: 67,
  },
  'AC:4C:1E:60:10:3D': {
    ipAddress: '10.1.67.123',
    apName: 'meter-mw08-000016',
    name: '*',
    signal: -67,
    history: [
      'meter-mw08-000039',
      'meter-mw08-000005',
      'meter-mw08-000010',
      'meter-mw08-000012',
      'meter-mw08-000039',
      'meter-mw08-000001',
      'meter-mw08-000006',
      'meter-mw08-000014',
      'meter-mw08-000023',
    ],
    offsetInSeconds: 167,
  },
  '42:01:C4:A7:1D:90': {
    ipAddress: '10.1.19.12',
    apName: 'meter-mw08-000017',
    name: '*',
    signal: -75,
    history: ['meter-mw08-000040', 'meter-mw08-000001', 'meter-mw08-000005', 'meter-mw08-000016'],
    offsetInSeconds: 182,
  },
  'F0:72:EA:F6:83:D5': {
    ipAddress: '10.1.172.131',
    apName: 'meter-mw08-000017',
    name: "Daniel's Chromebook",
    signal: -57,
    history: ['meter-mw08-000012', 'meter-mw08-000018', 'meter-mw08-000023', 'meter-mw08-000039'],
    offsetInSeconds: 153,
  },
  '80:C5:E6:1D:DD:DA': {
    ipAddress: '10.1.89.128',
    apName: '',
    name: "Théo Gomez's Surface Pro",
    signal: -60,
    history: [],
    offsetInSeconds: 276,
  },
  '60:3E:5F:C4:75:BE': {
    ipAddress: '10.1.98.197',
    apName: 'meter-mw08-000003',
    name: "Yan's MBP",
    signal: -72,
    history: [
      'meter-mw08-000032',
      'meter-mw08-000037',
      'meter-mw08-000005',
      'meter-mw08-000027',
      'meter-mw08-000029',
      'meter-mw08-000035',
      'meter-mw08-000032',
      'meter-mw08-000008',
    ],
    offsetInSeconds: 187,
  },
  '04:72:95:FF:3B:BE': {
    ipAddress: '10.1.243.42',
    apName: 'meter-mw08-000007',
    name: "Mei's MacBook",
    signal: -45,
    history: ['meter-mw08-000032', 'meter-mw08-000004'],
    offsetInSeconds: 107,
  },
  'EC:59:E7:01:F9:76': {
    ipAddress: '10.1.235.181',
    apName: 'meter-mw08-000010',
    name: "Svetlana Yang's Surface Pro",
    signal: -48,
    history: [
      'meter-mw08-000026',
      'meter-mw08-000008',
      'meter-mw08-000017',
      'meter-mw08-000027',
      'meter-mw08-000033',
      'meter-mw08-000035',
      'meter-mw08-000017',
      'meter-mw08-000029',
      'meter-mw08-000002',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 26,
  },
  'D8:CE:8C:DD:DC:3A': {
    ipAddress: '10.1.144.99',
    apName: 'meter-mw08-000017',
    name: '*',
    signal: -46,
    history: [
      'meter-mw08-000038',
      'meter-mw08-000031',
      'meter-mw08-000006',
      'meter-mw08-000007',
      'meter-mw08-000039',
      'meter-mw08-000014',
      'meter-mw08-000003',
    ],
    offsetInSeconds: 283,
  },
  '68:FE:F7:FC:D5:F0': {
    ipAddress: '10.1.143.2',
    apName: '',
    name: "Daniel Wu's MBP-2",
    signal: -55,
    history: [],
    offsetInSeconds: 78,
  },
  '9C:4F:5F:A1:B3:CA': {
    ipAddress: '10.1.192.54',
    apName: 'meter-mw08-000008',
    name: "Sergei Choi's Chromebook",
    signal: -49,
    history: ['meter-mw08-000010', 'meter-mw08-000017', 'meter-mw08-000005', 'meter-mw08-000025'],
    offsetInSeconds: 155,
  },
  '53:BB:C7:26:35:33': {
    ipAddress: '10.1.89.226',
    apName: '',
    name: '*',
    signal: -74,
    history: [],
    offsetInSeconds: 276,
  },
  'FC:4E:99:D0:12:8D': {
    ipAddress: '10.1.22.241',
    apName: '',
    name: '*',
    signal: -67,
    history: [],
    offsetInSeconds: 231,
  },
  '58:73:D8:9C:25:F2': {
    ipAddress: '10.1.168.150',
    apName: 'meter-mw08-000003',
    name: "Michael Takahashi's MBP-2",
    signal: -67,
    history: ['meter-mw08-000016'],
    offsetInSeconds: 277,
  },
  '1C:36:BB:A4:47:29': {
    ipAddress: '10.1.168.43',
    apName: 'meter-mw08-000008',
    name: "Vladimir's iPad",
    signal: -51,
    history: [
      'meter-mw08-000022',
      'meter-mw08-000029',
      'meter-mw08-000036',
      'meter-mw08-000013',
      'meter-mw08-000036',
      'meter-mw08-000006',
      'meter-mw08-000040',
      'meter-mw08-000007',
      'meter-mw08-000002',
      'meter-mw08-000029',
    ],
    offsetInSeconds: 261,
  },
  'B6:A9:4E:98:57:52': {
    ipAddress: '10.1.194.195',
    apName: 'meter-mw08-000015',
    name: '*',
    signal: -60,
    history: [
      'meter-mw08-000028',
      'meter-mw08-000019',
      'meter-mw08-000030',
      'meter-mw08-000001',
      'meter-mw08-000036',
    ],
    offsetInSeconds: 209,
  },
  'E8:C1:D7:FA:C9:33': {
    ipAddress: '10.1.83.108',
    apName: 'meter-mw08-000008',
    name: 'Philips Hue',
    signal: -73,
    history: [
      'meter-mw08-000020',
      'meter-mw08-000011',
      'meter-mw08-000033',
      'meter-mw08-000027',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 256,
  },
  '46:75:B6:54:71:11': {
    ipAddress: '10.1.101.47',
    apName: '',
    name: '*',
    signal: -70,
    history: [],
    offsetInSeconds: 134,
  },
  '23:21:A2:FA:23:8B': {
    ipAddress: '10.1.92.3',
    apName: 'meter-mw08-000033',
    name: '*',
    signal: -48,
    history: [
      'meter-mw08-000023',
      'meter-mw08-000014',
      'meter-mw08-000031',
      'meter-mw08-000014',
      'meter-mw08-000014',
      'meter-mw08-000020',
      'meter-mw08-000008',
    ],
    offsetInSeconds: 195,
  },
  '78:4F:43:D5:01:E4': {
    ipAddress: '10.1.248.221',
    apName: 'meter-mw08-000004',
    name: "Martina Watanabe's MBP-2",
    signal: -59,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000015',
      'meter-mw08-000030',
      'meter-mw08-000018',
      'meter-mw08-000016',
      'meter-mw08-000022',
    ],
    offsetInSeconds: 245,
  },
  '3C:A6:F6:1D:77:73': {
    ipAddress: '10.1.221.59',
    apName: '',
    name: "Anton's iPad",
    signal: -58,
    history: [],
    offsetInSeconds: 199,
  },
  '98:10:E8:EA:18:E7': {
    ipAddress: '10.1.196.79',
    apName: '',
    name: "Yan Allen's MacBook",
    signal: -57,
    history: [],
    offsetInSeconds: 3,
  },
  '08:CC:27:8D:92:14': {
    ipAddress: '10.1.186.210',
    apName: 'meter-mw08-000030',
    name: "Théo Lopez's ThinkPad X1",
    signal: -55,
    history: ['meter-mw08-000003'],
    offsetInSeconds: 188,
  },
  '8C:86:1E:A1:12:13': {
    ipAddress: '10.1.206.64',
    apName: '',
    name: "Ravi Scott's MBP-2",
    signal: -61,
    history: [],
    offsetInSeconds: 40,
  },
  '00:04:0F:88:F6:55': {
    ipAddress: '10.1.101.182',
    apName: '',
    name: "Aishwarya Zhao's Laptop",
    signal: -70,
    history: [],
    offsetInSeconds: 150,
  },
  '0C:77:1A:EF:E3:3F': {
    ipAddress: '10.1.228.51',
    apName: 'meter-mw08-000016',
    name: "Camila's MBP",
    signal: -55,
    history: [
      'meter-mw08-000033',
      'meter-mw08-000022',
      'meter-mw08-000008',
      'meter-mw08-000005',
      'meter-mw08-000026',
      'meter-mw08-000028',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 101,
  },
  'F4:6A:D7:6E:0F:06': {
    ipAddress: '10.1.37.193',
    apName: 'meter-mw08-000018',
    name: "Vikram Thompson's Surface Pro",
    signal: -64,
    history: ['meter-mw08-000025', 'meter-mw08-000028', 'meter-mw08-000020', 'meter-mw08-000004'],
    offsetInSeconds: 90,
  },
  'D8:A2:5E:32:42:60': {
    ipAddress: '10.1.150.90',
    apName: '',
    name: "Joseph's iPhone",
    signal: -62,
    history: [],
    offsetInSeconds: 203,
  },
  '9C:6C:15:A0:D5:18': {
    ipAddress: '10.1.212.12',
    apName: 'meter-mw08-000022',
    name: "Christopher's Surface Pro",
    signal: -58,
    history: [
      'meter-mw08-000012',
      'meter-mw08-000031',
      'meter-mw08-000035',
      'meter-mw08-000015',
      'meter-mw08-000009',
    ],
    offsetInSeconds: 61,
  },
  '20:A6:CD:90:A0:88': {
    ipAddress: '10.1.116.87',
    apName: 'meter-mw08-000005',
    name: "Xin Choudhury's Dragonfly Pro",
    signal: -58,
    history: [
      'meter-mw08-000013',
      'meter-mw08-000013',
      'meter-mw08-000038',
      'meter-mw08-000029',
      'meter-mw08-000008',
      'meter-mw08-000031',
      'meter-mw08-000040',
      'meter-mw08-000039',
      'meter-mw08-000039',
    ],
    offsetInSeconds: 160,
  },
  '00:04:0F:1B:00:F6': {
    ipAddress: '10.1.157.193',
    apName: 'meter-mw08-000011',
    name: "Léa's Laptop",
    signal: -74,
    history: [
      'meter-mw08-000025',
      'meter-mw08-000031',
      'meter-mw08-000001',
      'meter-mw08-000024',
      'meter-mw08-000019',
    ],
    offsetInSeconds: 282,
  },
  'C4:98:80:09:32:AE': {
    ipAddress: '10.1.58.255',
    apName: 'meter-mw08-000017',
    name: "Clara's MacBook Pro",
    signal: -63,
    history: [
      'meter-mw08-000036',
      'meter-mw08-000022',
      'meter-mw08-000036',
      'meter-mw08-000008',
      'meter-mw08-000014',
      'meter-mw08-000034',
      'meter-mw08-000028',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 242,
  },
  '20:94:5F:05:E1:5F': {
    ipAddress: '10.1.246.121',
    apName: 'meter-mw08-000022',
    name: '*',
    signal: -59,
    history: [
      'meter-mw08-000033',
      'meter-mw08-000011',
      'meter-mw08-000036',
      'meter-mw08-000030',
      'meter-mw08-000033',
      'meter-mw08-000040',
      'meter-mw08-000010',
    ],
    offsetInSeconds: 289,
  },
  '00:04:0F:69:43:E8': {
    ipAddress: '10.1.159.185',
    apName: 'meter-mw08-000017',
    name: "Riya's Laptop",
    signal: -52,
    history: ['meter-mw08-000015', 'meter-mw08-000018', 'meter-mw08-000034'],
    offsetInSeconds: 260,
  },
  '28:CF:DA:75:99:93': {
    ipAddress: '10.1.160.33',
    apName: 'meter-mw08-000006',
    name: 'HomePod',
    signal: -60,
    history: ['meter-mw08-000004', 'meter-mw08-000028', 'meter-mw08-000017', 'meter-mw08-000028'],
    offsetInSeconds: 41,
  },
  '9C:86:93:D7:DB:09': {
    ipAddress: '10.1.192.219',
    apName: 'meter-mw08-000034',
    name: '*',
    signal: -67,
    history: ['meter-mw08-000001'],
    offsetInSeconds: 289,
  },
  'D0:4F:7E:09:2F:23': {
    ipAddress: '10.1.147.176',
    apName: 'meter-mw08-000021',
    name: "William Balogh's MBP",
    signal: -63,
    history: [
      'meter-mw08-000020',
      'meter-mw08-000022',
      'meter-mw08-000025',
      'meter-mw08-000001',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 228,
  },
  'B0:4A:B4:F8:05:F4': {
    ipAddress: '10.1.238.125',
    apName: 'meter-mw08-000003',
    name: "William Hwang's ThinkPad X1",
    signal: -70,
    history: ['meter-mw08-000028'],
    offsetInSeconds: 4,
  },
  '00:04:0F:D3:78:F8': {
    ipAddress: '10.1.55.178',
    apName: '',
    name: "Raj's ZenBook Flip",
    signal: -73,
    history: [],
    offsetInSeconds: 97,
  },
  '5C:AA:FD:63:AE:15': {
    ipAddress: '10.1.59.192',
    apName: '',
    name: 'Sonos',
    signal: -49,
    history: [],
    offsetInSeconds: 36,
  },
  '00:04:0F:9F:96:BC': {
    ipAddress: '10.1.87.85',
    apName: 'meter-mw08-000040',
    name: "Juan Hwang's ZenBook Flip",
    signal: -69,
    history: ['meter-mw08-000018'],
    offsetInSeconds: 291,
  },
  'AD:2F:C0:FB:D8:A3': {
    ipAddress: '10.1.130.159',
    apName: 'meter-mw08-000009',
    name: '*',
    signal: -59,
    history: ['meter-mw08-000015', 'meter-mw08-000032'],
    offsetInSeconds: 69,
  },
  'A0:4A:5E:23:CA:DA': {
    ipAddress: '10.1.77.43',
    apName: 'meter-mw08-000003',
    name: "Xuan Park's Surface Pro",
    signal: -65,
    history: [
      'meter-mw08-000015',
      'meter-mw08-000017',
      'meter-mw08-000020',
      'meter-mw08-000011',
      'meter-mw08-000029',
      'meter-mw08-000001',
      'meter-mw08-000023',
      'meter-mw08-000007',
      'meter-mw08-000035',
    ],
    offsetInSeconds: 31,
  },
  'E8:06:88:6B:6C:9B': {
    ipAddress: '10.1.174.128',
    apName: 'meter-mw08-000028',
    name: "Mei Ferreira's iPhone",
    signal: -71,
    history: [
      'meter-mw08-000015',
      'meter-mw08-000035',
      'meter-mw08-000011',
      'meter-mw08-000021',
      'meter-mw08-000027',
    ],
    offsetInSeconds: 105,
  },
  '0C:EC:8D:4A:D8:BA': {
    ipAddress: '10.1.162.175',
    apName: 'meter-mw08-000030',
    name: "Anastasia Gonzalez's ThinkPad X1",
    signal: -50,
    history: [
      'meter-mw08-000018',
      'meter-mw08-000007',
      'meter-mw08-000034',
      'meter-mw08-000014',
      'meter-mw08-000028',
      'meter-mw08-000027',
      'meter-mw08-000001',
    ],
    offsetInSeconds: 128,
  },
  'A0:25:D7:2A:AC:93': {
    ipAddress: '10.1.230.3',
    apName: '',
    name: "Emily Anderson's Dragonfly Pro",
    signal: -62,
    history: [],
    offsetInSeconds: 216,
  },
  '34:A3:95:10:26:71': {
    ipAddress: '10.1.97.80',
    apName: 'meter-mw08-000028',
    name: "Wen Johnson's MBP",
    signal: -45,
    history: [
      'meter-mw08-000032',
      'meter-mw08-000001',
      'meter-mw08-000016',
      'meter-mw08-000011',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 48,
  },
  '00:0E:B3:8A:12:AB': {
    ipAddress: '10.1.9.167',
    apName: 'meter-mw08-000030',
    name: "Isha Jones's Dragonfly Pro",
    signal: -47,
    history: ['meter-mw08-000010', 'meter-mw08-000013'],
    offsetInSeconds: 263,
  },
  'FC:3A:CC:1C:F7:16': {
    ipAddress: '10.1.77.59',
    apName: 'meter-mw08-000018',
    name: '*',
    signal: -54,
    history: ['meter-mw08-000002', 'meter-mw08-000007', 'meter-mw08-000032'],
    offsetInSeconds: 28,
  },
  '00:04:0F:8B:57:A6': {
    ipAddress: '10.1.48.209',
    apName: '',
    name: "Javier's ZenBook Flip",
    signal: -68,
    history: [],
    offsetInSeconds: 99,
  },
  '27:FD:B2:E3:FA:27': {
    ipAddress: '10.1.150.172',
    apName: '',
    name: '*',
    signal: -54,
    history: [],
    offsetInSeconds: 19,
  },
  'C4:B3:01:29:2C:68': {
    ipAddress: '10.1.9.4',
    apName: 'meter-mw08-000021',
    name: "Thomas Fernandes's MBP",
    signal: -61,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000020',
      'meter-mw08-000032',
      'meter-mw08-000026',
      'meter-mw08-000020',
      'meter-mw08-000025',
      'meter-mw08-000034',
      'meter-mw08-000033',
      'meter-mw08-000013',
    ],
    offsetInSeconds: 166,
  },
  'A8:9C:78:46:9E:3C': {
    ipAddress: '10.1.52.90',
    apName: 'meter-mw08-000004',
    name: "Dmitry's MacBook Pro",
    signal: -75,
    history: [
      'meter-mw08-000012',
      'meter-mw08-000021',
      'meter-mw08-000018',
      'meter-mw08-000033',
      'meter-mw08-000021',
      'meter-mw08-000030',
      'meter-mw08-000034',
      'meter-mw08-000029',
      'meter-mw08-000021',
      'meter-mw08-000023',
    ],
    offsetInSeconds: 7,
  },
  '00:04:0F:E7:B6:3C': {
    ipAddress: '10.1.18.178',
    apName: 'meter-mw08-000036',
    name: "Aishwarya's ZenBook Flip",
    signal: -46,
    history: [
      'meter-mw08-000003',
      'meter-mw08-000005',
      'meter-mw08-000020',
      'meter-mw08-000036',
      'meter-mw08-000033',
    ],
    offsetInSeconds: 129,
  },
  '9C:FC:01:79:93:0B': {
    ipAddress: '10.1.51.150',
    apName: 'meter-mw08-000024',
    name: "Arthur Robinson's MBP",
    signal: -69,
    history: [
      'meter-mw08-000027',
      'meter-mw08-000014',
      'meter-mw08-000032',
      'meter-mw08-000018',
      'meter-mw08-000037',
      'meter-mw08-000036',
      'meter-mw08-000036',
    ],
    offsetInSeconds: 190,
  },
  '5F:1B:F0:F9:1D:10': {
    ipAddress: '10.1.163.73',
    apName: '',
    name: '*',
    signal: -46,
    history: [],
    offsetInSeconds: 152,
  },
  'FD:93:B3:BA:3E:EF': {
    ipAddress: '10.1.205.76',
    apName: 'meter-mw08-000014',
    name: '*',
    signal: -71,
    history: [
      'meter-mw08-000035',
      'meter-mw08-000037',
      'meter-mw08-000013',
      'meter-mw08-000039',
      'meter-mw08-000026',
      'meter-mw08-000024',
      'meter-mw08-000020',
      'meter-mw08-000002',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 21,
  },
  '00:04:0F:B2:93:CE': {
    ipAddress: '10.1.122.59',
    apName: 'meter-mw08-000012',
    name: "Emma Kim's ZenBook Flip",
    signal: -62,
    history: [
      'meter-mw08-000015',
      'meter-mw08-000019',
      'meter-mw08-000038',
      'meter-mw08-000039',
      'meter-mw08-000029',
      'meter-mw08-000013',
      'meter-mw08-000027',
      'meter-mw08-000003',
      'meter-mw08-000006',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 134,
  },
  '2A:52:4E:74:32:1E': {
    ipAddress: '10.1.49.8',
    apName: 'meter-mw08-000033',
    name: '*',
    signal: -61,
    history: ['meter-mw08-000012', 'meter-mw08-000039', 'meter-mw08-000015'],
    offsetInSeconds: 130,
  },
  '00:04:0F:3B:A4:FF': {
    ipAddress: '10.1.152.28',
    apName: 'meter-mw08-000004',
    name: "Carlos's Laptop",
    signal: -56,
    history: ['meter-mw08-000033', 'meter-mw08-000030', 'meter-mw08-000014'],
    offsetInSeconds: 71,
  },
  'A8:52:D4:7E:02:A9': {
    ipAddress: '10.1.97.178',
    apName: '',
    name: "Anastasia's Dragonfly Pro",
    signal: -58,
    history: [],
    offsetInSeconds: 144,
  },
  '9C:13:62:2F:ED:C3': {
    ipAddress: '10.1.191.37',
    apName: 'meter-mw08-000039',
    name: '*',
    signal: -75,
    history: [
      'meter-mw08-000034',
      'meter-mw08-000031',
      'meter-mw08-000004',
      'meter-mw08-000037',
      'meter-mw08-000027',
    ],
    offsetInSeconds: 207,
  },
  '08:87:C7:93:3F:85': {
    ipAddress: '10.1.228.65',
    apName: '',
    name: "Xiao's MBP",
    signal: -72,
    history: [],
    offsetInSeconds: 170,
  },
  '00:04:0F:99:94:EE': {
    ipAddress: '10.1.3.132',
    apName: 'meter-mw08-000019',
    name: "Aarav's Laptop",
    signal: -61,
    history: ['meter-mw08-000018'],
    offsetInSeconds: 292,
  },
  '48:35:2B:C5:38:BC': {
    ipAddress: '10.1.67.82',
    apName: '',
    name: "Hong Zhao's iPhone",
    signal: -70,
    history: [],
    offsetInSeconds: 293,
  },
  '5C:AA:FD:0D:66:AA': {
    ipAddress: '10.1.102.72',
    apName: '',
    name: 'Sonos',
    signal: -47,
    history: [],
    offsetInSeconds: 51,
  },
  'F8:14:0B:F7:24:54': {
    ipAddress: '10.1.135.231',
    apName: '',
    name: '*',
    signal: -63,
    history: [],
    offsetInSeconds: 280,
  },
  '00:04:0F:97:D1:CE': {
    ipAddress: '10.1.241.202',
    apName: '',
    name: "Rahul's Laptop",
    signal: -50,
    history: [],
    offsetInSeconds: 209,
  },
  '00:04:0F:8E:B6:C6': {
    ipAddress: '10.1.83.114',
    apName: 'meter-mw08-000026',
    name: "Javier's Laptop",
    signal: -75,
    history: [
      'meter-mw08-000011',
      'meter-mw08-000006',
      'meter-mw08-000020',
      'meter-mw08-000008',
      'meter-mw08-000021',
      'meter-mw08-000025',
      'meter-mw08-000032',
      'meter-mw08-000028',
    ],
    offsetInSeconds: 261,
  },
  'D4:D1:71:AA:2B:5C': {
    ipAddress: '10.1.206.222',
    apName: 'meter-mw08-000008',
    name: '*',
    signal: -67,
    history: [
      'meter-mw08-000017',
      'meter-mw08-000012',
      'meter-mw08-000021',
      'meter-mw08-000038',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 112,
  },
  'F0:1D:BC:11:C3:EE': {
    ipAddress: '10.1.183.104',
    apName: '',
    name: "Ivan's Surface Pro",
    signal: -68,
    history: [],
    offsetInSeconds: 219,
  },
  '30:57:14:58:A4:A9': {
    ipAddress: '10.1.153.79',
    apName: 'meter-mw08-000017',
    name: "Isabella Suzuki's iPhone",
    signal: -73,
    history: ['meter-mw08-000013', 'meter-mw08-000033', 'meter-mw08-000003'],
    offsetInSeconds: 165,
  },
  '18:E7:B0:66:B2:85': {
    ipAddress: '10.1.145.175',
    apName: 'meter-mw08-000034',
    name: "Isha Ferreira's iPhone",
    signal: -58,
    history: ['meter-mw08-000027', 'meter-mw08-000010'],
    offsetInSeconds: 131,
  },
  '00:04:0F:E2:ED:50': {
    ipAddress: '10.1.198.126',
    apName: 'meter-mw08-000020',
    name: "Juan's ZenBook Flip",
    signal: -48,
    history: ['meter-mw08-000003', 'meter-mw08-000012', 'meter-mw08-000036'],
    offsetInSeconds: 155,
  },
  'A4:40:D1:15:0D:DC': {
    ipAddress: '10.1.218.155',
    apName: '',
    name: '*',
    signal: -46,
    history: [],
    offsetInSeconds: 126,
  },
  '29:1A:B1:05:05:8B': {
    ipAddress: '10.1.243.244',
    apName: 'meter-mw08-000020',
    name: '*',
    signal: -54,
    history: [
      'meter-mw08-000002',
      'meter-mw08-000010',
      'meter-mw08-000008',
      'meter-mw08-000040',
      'meter-mw08-000009',
      'meter-mw08-000008',
      'meter-mw08-000032',
      'meter-mw08-000039',
    ],
    offsetInSeconds: 196,
  },
  '34:7E:5C:90:4E:75': {
    ipAddress: '10.1.115.108',
    apName: 'meter-mw08-000004',
    name: 'Sonos',
    signal: -50,
    history: ['meter-mw08-000015', 'meter-mw08-000025', 'meter-mw08-000005', 'meter-mw08-000010'],
    offsetInSeconds: 117,
  },
  '41:9F:3F:8B:63:4A': {
    ipAddress: '10.1.164.13',
    apName: 'meter-mw08-000007',
    name: '*',
    signal: -63,
    history: ['meter-mw08-000033', 'meter-mw08-000031'],
    offsetInSeconds: 118,
  },
  'B8:B2:F8:22:0F:CC': {
    ipAddress: '10.1.179.59',
    apName: 'meter-mw08-000004',
    name: "Aditya's MBP-2",
    signal: -71,
    history: [
      'meter-mw08-000013',
      'meter-mw08-000038',
      'meter-mw08-000017',
      'meter-mw08-000034',
      'meter-mw08-000007',
      'meter-mw08-000034',
      'meter-mw08-000009',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 241,
  },
  '60:1D:91:6E:A1:AF': {
    ipAddress: '10.1.144.242',
    apName: 'meter-mw08-000023',
    name: "Chloé's ThinkPad X1",
    signal: -47,
    history: [
      'meter-mw08-000004',
      'meter-mw08-000006',
      'meter-mw08-000018',
      'meter-mw08-000014',
      'meter-mw08-000010',
      'meter-mw08-000039',
    ],
    offsetInSeconds: 45,
  },
  '25:37:79:7E:A0:95': {
    ipAddress: '10.1.177.78',
    apName: 'meter-mw08-000005',
    name: '*',
    signal: -62,
    history: ['meter-mw08-000016'],
    offsetInSeconds: 180,
  },
  'D8:EB:46:44:39:F7': {
    ipAddress: '10.1.81.52',
    apName: 'meter-mw08-000034',
    name: "Yan's Chromebook",
    signal: -67,
    history: ['meter-mw08-000014', 'meter-mw08-000028'],
    offsetInSeconds: 100,
  },
  '5E:6A:CF:AD:9D:0A': {
    ipAddress: '10.1.1.1',
    apName: 'meter-mw08-000008',
    name: '*',
    signal: -64,
    history: [
      'meter-mw08-000006',
      'meter-mw08-000019',
      'meter-mw08-000040',
      'meter-mw08-000020',
      'meter-mw08-000015',
    ],
    offsetInSeconds: 97,
  },
  'C8:4B:D6:E2:B6:AE': {
    ipAddress: '10.1.177.141',
    apName: 'meter-mw08-000020',
    name: "Rahul Nagy's Inspiron",
    signal: -56,
    history: [
      'meter-mw08-000015',
      'meter-mw08-000022',
      'meter-mw08-000018',
      'meter-mw08-000009',
      'meter-mw08-000034',
      'meter-mw08-000031',
      'meter-mw08-000033',
    ],
    offsetInSeconds: 70,
  },
  'DC:86:D8:53:D0:FF': {
    ipAddress: '10.1.149.172',
    apName: 'meter-mw08-000025',
    name: "Raj's MBP",
    signal: -55,
    history: [
      'meter-mw08-000007',
      'meter-mw08-000020',
      'meter-mw08-000012',
      'meter-mw08-000028',
      'meter-mw08-000023',
    ],
    offsetInSeconds: 194,
  },
  '08:AD:35:08:A7:6B': {
    ipAddress: '10.1.2.122',
    apName: 'meter-mw08-000003',
    name: '*',
    signal: -55,
    history: [
      'meter-mw08-000003',
      'meter-mw08-000010',
      'meter-mw08-000018',
      'meter-mw08-000015',
      'meter-mw08-000019',
      'meter-mw08-000038',
      'meter-mw08-000028',
    ],
    offsetInSeconds: 263,
  },
  '00:0B:AF:98:CE:6A': {
    ipAddress: '10.1.255.95',
    apName: 'meter-mw08-000006',
    name: '*',
    signal: -61,
    history: [
      'meter-mw08-000025',
      'meter-mw08-000024',
      'meter-mw08-000005',
      'meter-mw08-000030',
      'meter-mw08-000009',
    ],
    offsetInSeconds: 120,
  },
  '18:FA:B7:06:B2:A8': {
    ipAddress: '10.1.89.51',
    apName: 'meter-mw08-000012',
    name: "Yu's iPad",
    signal: -66,
    history: [
      'meter-mw08-000001',
      'meter-mw08-000016',
      'meter-mw08-000030',
      'meter-mw08-000010',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 162,
  },
  '20:F3:9B:A9:B9:D6': {
    ipAddress: '10.1.75.122',
    apName: '',
    name: '*',
    signal: -49,
    history: [],
    offsetInSeconds: 138,
  },
  '24:FD:55:58:4C:45': {
    ipAddress: '10.1.242.240',
    apName: 'meter-mw08-000017',
    name: '*',
    signal: -64,
    history: [
      'meter-mw08-000007',
      'meter-mw08-000014',
      'meter-mw08-000016',
      'meter-mw08-000034',
      'meter-mw08-000008',
      'meter-mw08-000038',
      'meter-mw08-000006',
      'meter-mw08-000020',
    ],
    offsetInSeconds: 196,
  },
  '00:04:0F:92:D2:03': {
    ipAddress: '10.1.143.197',
    apName: 'meter-mw08-000002',
    name: "Lucía King's ZenBook Flip",
    signal: -68,
    history: ['meter-mw08-000020', 'meter-mw08-000021'],
    offsetInSeconds: 290,
  },
  'AC:3E:B1:45:60:0F': {
    ipAddress: '10.1.11.127',
    apName: 'meter-mw08-000037',
    name: "Aditya's Chromebook",
    signal: -56,
    history: ['meter-mw08-000031'],
    offsetInSeconds: 181,
  },
  'F8:8F:CA:F7:C5:74': {
    ipAddress: '10.1.58.55',
    apName: 'meter-mw08-000003',
    name: "Gabriel Jones's Chromebook",
    signal: -55,
    history: [
      'meter-mw08-000004',
      'meter-mw08-000031',
      'meter-mw08-000002',
      'meter-mw08-000009',
      'meter-mw08-000005',
      'meter-mw08-000018',
      'meter-mw08-000018',
      'meter-mw08-000020',
      'meter-mw08-000007',
      'meter-mw08-000017',
    ],
    offsetInSeconds: 13,
  },
  '94:95:A0:04:46:6B': {
    ipAddress: '10.1.91.29',
    apName: '',
    name: "Juan's Chromebook",
    signal: -65,
    history: [],
    offsetInSeconds: 267,
  },
  '00:04:0F:E5:2F:29': {
    ipAddress: '10.1.175.13',
    apName: '',
    name: "Miguel Wright's ZenBook Flip",
    signal: -71,
    history: [],
    offsetInSeconds: 87,
  },
  'E8:DC:BB:1C:8A:66': {
    ipAddress: '10.1.237.78',
    apName: '',
    name: '*',
    signal: -56,
    history: [],
    offsetInSeconds: 47,
  },
  '00:04:0F:02:93:8D': {
    ipAddress: '10.1.25.191',
    apName: 'meter-mw08-000017',
    name: "Juliette's ZenBook Flip",
    signal: -60,
    history: [
      'meter-mw08-000037',
      'meter-mw08-000008',
      'meter-mw08-000038',
      'meter-mw08-000039',
      'meter-mw08-000005',
    ],
    offsetInSeconds: 17,
  },
  'A4:5D:36:FD:3F:AB': {
    ipAddress: '10.1.248.242',
    apName: 'meter-mw08-000018',
    name: "Sanjana's Dragonfly Pro",
    signal: -65,
    history: ['meter-mw08-000040', 'meter-mw08-000026', 'meter-mw08-000005'],
    offsetInSeconds: 116,
  },
  '04:41:6B:60:B5:66': {
    ipAddress: '10.1.74.57',
    apName: 'meter-mw08-000038',
    name: '*',
    signal: -68,
    history: ['meter-mw08-000004', 'meter-mw08-000005', 'meter-mw08-000026', 'meter-mw08-000009'],
    offsetInSeconds: 26,
  },
  'E0:BD:A0:6B:31:C8': {
    ipAddress: '10.1.18.14',
    apName: 'meter-mw08-000032',
    name: "Miguel Müller's MBP-2",
    signal: -53,
    history: [
      'meter-mw08-000007',
      'meter-mw08-000026',
      'meter-mw08-000010',
      'meter-mw08-000025',
      'meter-mw08-000030',
      'meter-mw08-000029',
      'meter-mw08-000033',
      'meter-mw08-000015',
      'meter-mw08-000018',
      'meter-mw08-000021',
    ],
    offsetInSeconds: 232,
  },
  'D8:9E:3F:1C:41:60': {
    ipAddress: '10.1.54.206',
    apName: 'meter-mw08-000001',
    name: "Emma Lee's iPad",
    signal: -45,
    history: [
      'meter-mw08-000018',
      'meter-mw08-000013',
      'meter-mw08-000023',
      'meter-mw08-000022',
      'meter-mw08-000014',
      'meter-mw08-000037',
    ],
    offsetInSeconds: 281,
  },
  'E0:B5:2D:80:D8:B2': {
    ipAddress: '10.1.106.48',
    apName: '',
    name: "Ava Jackson's iPad",
    signal: -66,
    history: [],
    offsetInSeconds: 249,
  },
  'F8:1E:DF:88:08:1C': {
    ipAddress: '10.1.68.156',
    apName: '',
    name: "Riya's MacBook",
    signal: -63,
    history: [],
    offsetInSeconds: 238,
  },
  '00:80:5F:FC:B8:8F': {
    ipAddress: '10.1.91.39',
    apName: 'meter-mw08-000024',
    name: "Ava's Dragonfly Pro",
    signal: -48,
    history: [
      'meter-mw08-000019',
      'meter-mw08-000028',
      'meter-mw08-000015',
      'meter-mw08-000033',
      'meter-mw08-000028',
      'meter-mw08-000002',
      'meter-mw08-000015',
      'meter-mw08-000036',
    ],
    offsetInSeconds: 4,
  },
  'C0:1A:DA:98:A5:D6': {
    ipAddress: '10.1.41.21',
    apName: '',
    name: "Akash's iPad",
    signal: -69,
    history: [],
    offsetInSeconds: 77,
  },
  '00:04:0F:D2:6E:B0': {
    ipAddress: '10.1.141.131',
    apName: 'meter-mw08-000031',
    name: "Sofía Lopez's Laptop",
    signal: -69,
    history: ['meter-mw08-000020', 'meter-mw08-000009'],
    offsetInSeconds: 104,
  },
  '96:DF:84:E2:29:83': {
    ipAddress: '10.1.53.94',
    apName: 'meter-mw08-000033',
    name: '*',
    signal: -46,
    history: [
      'meter-mw08-000013',
      'meter-mw08-000029',
      'meter-mw08-000016',
      'meter-mw08-000028',
      'meter-mw08-000038',
    ],
    offsetInSeconds: 135,
  },
  '00:0F:1F:33:D7:F0': {
    ipAddress: '10.1.241.53',
    apName: '',
    name: "William's Inspiron",
    signal: -49,
    history: [],
    offsetInSeconds: 34,
  },
  'C8:1F:66:E2:08:0B': {
    ipAddress: '10.1.160.43',
    apName: '',
    name: "Neha's Inspiron",
    signal: -73,
    history: [],
    offsetInSeconds: 265,
  },
  '1C:53:F9:D9:B3:26': {
    ipAddress: '10.1.161.91',
    apName: '',
    name: "Xiao Schmidt's Chromebook",
    signal: -53,
    history: [],
    offsetInSeconds: 293,
  },
  '50:A6:7F:8D:82:7C': {
    ipAddress: '10.1.143.236',
    apName: '',
    name: "Clara's iPad",
    signal: -55,
    history: [],
    offsetInSeconds: 212,
  },
  '00:04:0F:F8:DC:C5': {
    ipAddress: '10.1.213.44',
    apName: '',
    name: "Elena's ZenBook Flip",
    signal: -65,
    history: [],
    offsetInSeconds: 123,
  },
  '0C:E4:41:52:31:76': {
    ipAddress: '10.1.23.116',
    apName: 'meter-mw08-000030',
    name: "Kavya Rodriguez's MBP",
    signal: -54,
    history: [
      'meter-mw08-000002',
      'meter-mw08-000036',
      'meter-mw08-000005',
      'meter-mw08-000022',
      'meter-mw08-000020',
      'meter-mw08-000019',
      'meter-mw08-000024',
      'meter-mw08-000004',
    ],
    offsetInSeconds: 6,
  },
  '18:EE:69:95:39:2D': {
    ipAddress: '10.1.203.226',
    apName: 'meter-mw08-000028',
    name: "Manon's MacBook Pro",
    signal: -48,
    history: ['meter-mw08-000021', 'meter-mw08-000035', 'meter-mw08-000001'],
    offsetInSeconds: 89,
  },
  '8C:7B:9D:80:09:8A': {
    ipAddress: '10.1.116.204',
    apName: 'meter-mw08-000006',
    name: "Ivan's iPhone",
    signal: -50,
    history: ['meter-mw08-000024'],
    offsetInSeconds: 41,
  },
  'CB:AF:31:2F:65:7E': {
    ipAddress: '10.1.5.179',
    apName: '',
    name: '*',
    signal: -57,
    history: [],
    offsetInSeconds: 237,
  },
  '00:04:0F:85:5B:39': {
    ipAddress: '10.1.43.246',
    apName: 'meter-mw08-000012',
    name: "Neha's Laptop",
    signal: -69,
    history: ['meter-mw08-000032', 'meter-mw08-000011', 'meter-mw08-000030'],
    offsetInSeconds: 260,
  },
  '62:0C:77:67:E5:9C': {
    ipAddress: '10.1.155.47',
    apName: 'meter-mw08-000040',
    name: '*',
    signal: -58,
    history: [
      'meter-mw08-000001',
      'meter-mw08-000012',
      'meter-mw08-000031',
      'meter-mw08-000001',
      'meter-mw08-000040',
      'meter-mw08-000032',
      'meter-mw08-000036',
      'meter-mw08-000024',
      'meter-mw08-000031',
    ],
    offsetInSeconds: 268,
  },
  'C6:47:AD:84:6E:73': {
    ipAddress: '10.1.39.19',
    apName: 'meter-mw08-000029',
    name: '*',
    signal: -67,
    history: [
      'meter-mw08-000012',
      'meter-mw08-000038',
      'meter-mw08-000009',
      'meter-mw08-000003',
      'meter-mw08-000009',
      'meter-mw08-000001',
      'meter-mw08-000001',
      'meter-mw08-000040',
      'meter-mw08-000023',
      'meter-mw08-000027',
    ],
    offsetInSeconds: 98,
  },
  '00:04:0F:DE:58:92': {
    ipAddress: '10.1.98.162',
    apName: '',
    name: "Camila Hwang's Laptop",
    signal: -53,
    history: [],
    offsetInSeconds: 146,
  },
  '10:1F:74:C9:C4:49': {
    ipAddress: '10.1.243.64',
    apName: 'meter-mw08-000026',
    name: "Yu's Dragonfly Pro",
    signal: -58,
    history: ['meter-mw08-000027', 'meter-mw08-000038', 'meter-mw08-000031', 'meter-mw08-000019'],
    offsetInSeconds: 198,
  },
  '7D:0B:0E:EE:B7:FE': {
    ipAddress: '10.1.47.19',
    apName: 'meter-mw08-000039',
    name: '*',
    signal: -63,
    history: ['meter-mw08-000033', 'meter-mw08-000012', 'meter-mw08-000031'],
    offsetInSeconds: 83,
  },
  '10:29:59:0B:D6:19': {
    ipAddress: '10.1.42.194',
    apName: 'meter-mw08-000032',
    name: "Sarah King's MacBook Pro",
    signal: -62,
    history: ['meter-mw08-000027', 'meter-mw08-000014'],
    offsetInSeconds: 123,
  },
  '44:F2:1B:7A:3F:D0': {
    ipAddress: '10.1.196.4',
    apName: 'meter-mw08-000017',
    name: "Clara's iPhone",
    signal: -71,
    history: [
      'meter-mw08-000030',
      'meter-mw08-000026',
      'meter-mw08-000024',
      'meter-mw08-000040',
      'meter-mw08-000031',
      'meter-mw08-000039',
      'meter-mw08-000018',
      'meter-mw08-000032',
      'meter-mw08-000032',
      'meter-mw08-000038',
    ],
    offsetInSeconds: 57,
  },
  '20:16:42:A1:29:16': {
    ipAddress: '10.1.95.219',
    apName: 'meter-mw08-000009',
    name: "Thomas's Surface Pro",
    signal: -63,
    history: ['meter-mw08-000026', 'meter-mw08-000015', 'meter-mw08-000028', 'meter-mw08-000029'],
    offsetInSeconds: 84,
  },
  '40:D3:2D:22:35:30': {
    ipAddress: '10.1.119.174',
    apName: '',
    name: "Juliette's MacBook Pro",
    signal: -58,
    history: [],
    offsetInSeconds: 275,
  },
  '1C:72:1D:A2:52:3D': {
    ipAddress: '10.1.24.32',
    apName: 'meter-mw08-000001',
    name: "Arjun's Inspiron",
    signal: -47,
    history: ['meter-mw08-000022', 'meter-mw08-000013'],
    offsetInSeconds: 24,
  },
  'A8:86:DD:85:E6:BB': {
    ipAddress: '10.1.176.168',
    apName: 'meter-mw08-000010',
    name: "Amit's MacBook",
    signal: -65,
    history: [
      'meter-mw08-000006',
      'meter-mw08-000036',
      'meter-mw08-000040',
      'meter-mw08-000006',
      'meter-mw08-000015',
      'meter-mw08-000021',
      'meter-mw08-000018',
      'meter-mw08-000015',
      'meter-mw08-000027',
    ],
    offsetInSeconds: 259,
  },
  'D4:63:C6:1B:AD:B9': {
    ipAddress: '10.1.19.228',
    apName: 'meter-mw08-000017',
    name: "Svetlana Walker's ThinkPad X1",
    signal: -73,
    history: ['meter-mw08-000017', 'meter-mw08-000017', 'meter-mw08-000030'],
    offsetInSeconds: 102,
  },
  '4D:5C:8F:AB:BA:D9': {
    ipAddress: '10.1.4.52',
    apName: 'meter-mw08-000009',
    name: '*',
    signal: -66,
    history: ['meter-mw08-000038', 'meter-mw08-000037', 'meter-mw08-000034', 'meter-mw08-000002'],
    offsetInSeconds: 91,
  },
  'E7:FD:38:B3:37:7F': {
    ipAddress: '10.1.104.6',
    apName: 'meter-mw08-000037',
    name: '*',
    signal: -50,
    history: [
      'meter-mw08-000005',
      'meter-mw08-000030',
      'meter-mw08-000035',
      'meter-mw08-000032',
      'meter-mw08-000005',
      'meter-mw08-000007',
      'meter-mw08-000002',
      'meter-mw08-000010',
      'meter-mw08-000005',
      'meter-mw08-000035',
    ],
    offsetInSeconds: 122,
  },
  'AC:C9:06:D6:14:4B': {
    ipAddress: '10.1.235.120',
    apName: '',
    name: "Sarah Huang's MacBook",
    signal: -66,
    history: [],
    offsetInSeconds: 44,
  },
  '50:9A:4C:CA:05:D4': {
    ipAddress: '10.1.251.82',
    apName: 'meter-mw08-000040',
    name: "Vikram's Inspiron",
    signal: -69,
    history: [
      'meter-mw08-000002',
      'meter-mw08-000021',
      'meter-mw08-000004',
      'meter-mw08-000011',
      'meter-mw08-000033',
      'meter-mw08-000015',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 156,
  },
  '00:04:0F:D4:DF:2E': {
    ipAddress: '10.1.240.232',
    apName: 'meter-mw08-000001',
    name: "Valeria's Laptop",
    signal: -59,
    history: ['meter-mw08-000009', 'meter-mw08-000025', 'meter-mw08-000005', 'meter-mw08-000032'],
    offsetInSeconds: 188,
  },
  '4C:7C:5F:86:93:0F': {
    ipAddress: '10.1.211.181',
    apName: '',
    name: "Matthew's iPad",
    signal: -71,
    history: [],
    offsetInSeconds: 210,
  },
  '34:64:A9:50:5D:78': {
    ipAddress: '10.1.161.10',
    apName: '',
    name: "Romain's Dragonfly Pro",
    signal: -49,
    history: [],
    offsetInSeconds: 270,
  },
  'FC:49:2D:3F:93:66': {
    ipAddress: '10.1.22.243',
    apName: '',
    name: 'Echo',
    signal: -64,
    history: [],
    offsetInSeconds: 124,
  },
  '80:6C:1B:93:11:9E': {
    ipAddress: '10.1.2.150',
    apName: 'meter-mw08-000033',
    name: "Ivan Chowdhury's ThinkPad X1",
    signal: -70,
    history: [
      'meter-mw08-000029',
      'meter-mw08-000014',
      'meter-mw08-000025',
      'meter-mw08-000040',
      'meter-mw08-000014',
      'meter-mw08-000032',
      'meter-mw08-000018',
      'meter-mw08-000014',
      'meter-mw08-000004',
      'meter-mw08-000020',
    ],
    offsetInSeconds: 97,
  },
  '34:BB:26:F4:BA:A5': {
    ipAddress: '10.1.135.14',
    apName: 'meter-mw08-000031',
    name: "Nathan's ThinkPad X1",
    signal: -57,
    history: [
      'meter-mw08-000003',
      'meter-mw08-000023',
      'meter-mw08-000039',
      'meter-mw08-000023',
      'meter-mw08-000032',
      'meter-mw08-000017',
      'meter-mw08-000036',
      'meter-mw08-000040',
      'meter-mw08-000024',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 173,
  },
  'AB:5A:42:12:ED:F4': {
    ipAddress: '10.1.164.223',
    apName: 'meter-mw08-000016',
    name: '*',
    signal: -68,
    history: [
      'meter-mw08-000039',
      'meter-mw08-000029',
      'meter-mw08-000005',
      'meter-mw08-000032',
      'meter-mw08-000030',
      'meter-mw08-000040',
      'meter-mw08-000009',
    ],
    offsetInSeconds: 118,
  },
  'C4:38:75:72:44:BF': {
    ipAddress: '10.1.93.116',
    apName: '',
    name: 'Sonos',
    signal: -75,
    history: [],
    offsetInSeconds: 252,
  },
  'F8:0F:F9:EC:C6:B8': {
    ipAddress: '10.1.114.131',
    apName: 'meter-mw08-000040',
    name: "Tatiana Garcia's Chromebook",
    signal: -57,
    history: [
      'meter-mw08-000001',
      'meter-mw08-000008',
      'meter-mw08-000017',
      'meter-mw08-000004',
      'meter-mw08-000032',
      'meter-mw08-000014',
      'meter-mw08-000030',
      'meter-mw08-000025',
    ],
    offsetInSeconds: 102,
  },
  '97:9E:01:20:D5:F9': {
    ipAddress: '10.1.189.63',
    apName: '',
    name: '*',
    signal: -66,
    history: [],
    offsetInSeconds: 206,
  },
  'F0:F6:C1:27:9A:69': {
    ipAddress: '10.1.128.186',
    apName: 'meter-mw08-000027',
    name: 'Sonos',
    signal: -49,
    history: ['meter-mw08-000002'],
    offsetInSeconds: 256,
  },
  '00:04:0F:3F:7C:78': {
    ipAddress: '10.1.218.192',
    apName: 'meter-mw08-000003',
    name: "Riya Robinson's Laptop",
    signal: -48,
    history: [
      'meter-mw08-000011',
      'meter-mw08-000038',
      'meter-mw08-000039',
      'meter-mw08-000028',
      'meter-mw08-000024',
      'meter-mw08-000038',
    ],
    offsetInSeconds: 145,
  },
  'F4:8E:38:9A:14:E1': {
    ipAddress: '10.1.226.248',
    apName: '',
    name: "Divya's Inspiron",
    signal: -65,
    history: [],
    offsetInSeconds: 16,
  },
  '88:3D:24:1B:2B:EB': {
    ipAddress: '10.1.17.2',
    apName: '',
    name: "Gabriel's Chromebook",
    signal: -66,
    history: [],
    offsetInSeconds: 262,
  },
  '00:04:0F:6C:BE:DE': {
    ipAddress: '10.1.96.224',
    apName: 'meter-mw08-000020',
    name: "James Balogh's ZenBook Flip",
    signal: -71,
    history: [
      'meter-mw08-000039',
      'meter-mw08-000017',
      'meter-mw08-000019',
      'meter-mw08-000033',
      'meter-mw08-000028',
      'meter-mw08-000037',
      'meter-mw08-000017',
    ],
    offsetInSeconds: 236,
  },
  '00:61:71:F7:FB:DE': {
    ipAddress: '10.1.213.5',
    apName: 'meter-mw08-000010',
    name: "Arthur Balogh's MBP-2",
    signal: -56,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000001',
      'meter-mw08-000005',
      'meter-mw08-000023',
      'meter-mw08-000013',
      'meter-mw08-000012',
      'meter-mw08-000019',
      'meter-mw08-000009',
      'meter-mw08-000001',
    ],
    offsetInSeconds: 163,
  },
  'D4:C9:4B:EE:D1:5F': {
    ipAddress: '10.1.105.15',
    apName: 'meter-mw08-000011',
    name: "Sophia Ferreira's ThinkPad X1",
    signal: -65,
    history: [
      'meter-mw08-000010',
      'meter-mw08-000026',
      'meter-mw08-000004',
      'meter-mw08-000024',
      'meter-mw08-000017',
      'meter-mw08-000025',
      'meter-mw08-000005',
      'meter-mw08-000001',
      'meter-mw08-000014',
      'meter-mw08-000019',
    ],
    offsetInSeconds: 97,
  },
  '5B:01:B3:01:37:91': {
    ipAddress: '10.1.246.251',
    apName: 'meter-mw08-000001',
    name: '*',
    signal: -52,
    history: ['meter-mw08-000007', 'meter-mw08-000025'],
    offsetInSeconds: 27,
  },
  '26:09:16:9C:8D:C3': {
    ipAddress: '10.1.73.88',
    apName: 'meter-mw08-000040',
    name: '*',
    signal: -51,
    history: [
      'meter-mw08-000018',
      'meter-mw08-000025',
      'meter-mw08-000027',
      'meter-mw08-000026',
      'meter-mw08-000024',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 262,
  },
  '00:16:CB:67:DC:2E': {
    ipAddress: '10.1.116.108',
    apName: 'meter-mw08-000022',
    name: "Emma's MBP",
    signal: -74,
    history: ['meter-mw08-000009', 'meter-mw08-000030'],
    offsetInSeconds: 45,
  },
  'F0:D4:E2:AC:1C:34': {
    ipAddress: '10.1.26.58',
    apName: 'meter-mw08-000025',
    name: "Mariana Hall's Inspiron",
    signal: -68,
    history: ['meter-mw08-000025', 'meter-mw08-000001', 'meter-mw08-000028'],
    offsetInSeconds: 70,
  },
  '03:80:B9:31:1E:45': {
    ipAddress: '10.1.118.16',
    apName: 'meter-mw08-000012',
    name: '*',
    signal: -64,
    history: [
      'meter-mw08-000014',
      'meter-mw08-000023',
      'meter-mw08-000029',
      'meter-mw08-000022',
      'meter-mw08-000029',
      'meter-mw08-000036',
      'meter-mw08-000040',
      'meter-mw08-000013',
      'meter-mw08-000032',
      'meter-mw08-000019',
    ],
    offsetInSeconds: 210,
  },
  '00:04:0F:CF:99:D3': {
    ipAddress: '10.1.87.64',
    apName: 'meter-mw08-000004',
    name: "Aryan's Laptop",
    signal: -49,
    history: [
      'meter-mw08-000011',
      'meter-mw08-000011',
      'meter-mw08-000014',
      'meter-mw08-000023',
      'meter-mw08-000027',
      'meter-mw08-000014',
      'meter-mw08-000038',
      'meter-mw08-000003',
      'meter-mw08-000033',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 85,
  },
  'F8:6F:C1:07:05:91': {
    ipAddress: '10.1.245.118',
    apName: 'meter-mw08-000022',
    name: "Pavel Takahashi's MacBook Pro",
    signal: -65,
    history: [
      'meter-mw08-000028',
      'meter-mw08-000003',
      'meter-mw08-000039',
      'meter-mw08-000013',
      'meter-mw08-000024',
      'meter-mw08-000027',
    ],
    offsetInSeconds: 276,
  },
  '00:04:0F:D3:F6:1F': {
    ipAddress: '10.1.52.75',
    apName: 'meter-mw08-000029',
    name: "William Singh's Laptop",
    signal: -51,
    history: ['meter-mw08-000018'],
    offsetInSeconds: 113,
  },
  'B0:41:50:E6:FB:0A': {
    ipAddress: '10.1.47.214',
    apName: '',
    name: '*',
    signal: -73,
    history: [],
    offsetInSeconds: 209,
  },
  '70:BC:10:68:E5:6F': {
    ipAddress: '10.1.251.196',
    apName: '',
    name: "Tatiana Hall's Surface Pro",
    signal: -75,
    history: [],
    offsetInSeconds: 256,
  },
  '10:65:30:07:F6:AD': {
    ipAddress: '10.1.159.177',
    apName: 'meter-mw08-000025',
    name: "Diego's Inspiron",
    signal: -52,
    history: ['meter-mw08-000020', 'meter-mw08-000002'],
    offsetInSeconds: 104,
  },
  '00:04:0F:9A:66:01': {
    ipAddress: '10.1.174.213',
    apName: 'meter-mw08-000008',
    name: "Zhang Thompson's Laptop",
    signal: -54,
    history: [
      'meter-mw08-000035',
      'meter-mw08-000029',
      'meter-mw08-000038',
      'meter-mw08-000011',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 126,
  },
  'B8:5D:0A:D7:BB:AB': {
    ipAddress: '10.1.177.29',
    apName: '',
    name: "Neha Young's iPad",
    signal: -71,
    history: [],
    offsetInSeconds: 251,
  },
  'A0:B3:CC:C2:84:A5': {
    ipAddress: '10.1.111.105',
    apName: 'meter-mw08-000019',
    name: "Juan's Dragonfly Pro",
    signal: -73,
    history: [
      'meter-mw08-000035',
      'meter-mw08-000034',
      'meter-mw08-000003',
      'meter-mw08-000033',
      'meter-mw08-000034',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 228,
  },
  'C8:BC:C8:FE:A9:ED': {
    ipAddress: '10.1.76.10',
    apName: 'meter-mw08-000039',
    name: "Wen Zhao's MBP",
    signal: -65,
    history: ['meter-mw08-000031', 'meter-mw08-000021', 'meter-mw08-000020'],
    offsetInSeconds: 226,
  },
  '17:77:9A:B2:C0:8C': {
    ipAddress: '10.1.32.240',
    apName: 'meter-mw08-000003',
    name: '*',
    signal: -57,
    history: ['meter-mw08-000031', 'meter-mw08-000008', 'meter-mw08-000031'],
    offsetInSeconds: 294,
  },
  '08:AA:55:A7:FC:EF': {
    ipAddress: '10.1.113.45',
    apName: 'meter-mw08-000039',
    name: "Andrew Balogh's ThinkPad X1",
    signal: -58,
    history: ['meter-mw08-000012', 'meter-mw08-000017', 'meter-mw08-000033'],
    offsetInSeconds: 297,
  },
  '4C:79:75:AD:2A:4B': {
    ipAddress: '10.1.169.173',
    apName: 'meter-mw08-000005',
    name: "Valeria Lewis's MBP-2",
    signal: -73,
    history: ['meter-mw08-000040', 'meter-mw08-000036', 'meter-mw08-000033'],
    offsetInSeconds: 286,
  },
  'DC:86:D8:BA:A7:52': {
    ipAddress: '10.1.192.206',
    apName: 'meter-mw08-000025',
    name: "Christopher Gomez's MacBook",
    signal: -53,
    history: [
      'meter-mw08-000013',
      'meter-mw08-000030',
      'meter-mw08-000003',
      'meter-mw08-000030',
      'meter-mw08-000019',
      'meter-mw08-000007',
      'meter-mw08-000015',
      'meter-mw08-000004',
    ],
    offsetInSeconds: 21,
  },
  '34:BB:26:BA:F6:D3': {
    ipAddress: '10.1.49.115',
    apName: 'meter-mw08-000033',
    name: "William Wilson's ThinkPad X1",
    signal: -65,
    history: [
      'meter-mw08-000034',
      'meter-mw08-000034',
      'meter-mw08-000028',
      'meter-mw08-000039',
      'meter-mw08-000026',
      'meter-mw08-000031',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 296,
  },
  '00:04:0F:7F:CB:01': {
    ipAddress: '10.1.6.11',
    apName: 'meter-mw08-000023',
    name: "Clara Jones's ZenBook Flip",
    signal: -69,
    history: [
      'meter-mw08-000003',
      'meter-mw08-000009',
      'meter-mw08-000009',
      'meter-mw08-000001',
      'meter-mw08-000031',
      'meter-mw08-000031',
      'meter-mw08-000005',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 244,
  },
  '28:18:78:B8:5B:49': {
    ipAddress: '10.1.111.94',
    apName: 'meter-mw08-000036',
    name: "Alejandro's Surface Pro",
    signal: -60,
    history: [
      'meter-mw08-000036',
      'meter-mw08-000020',
      'meter-mw08-000016',
      'meter-mw08-000040',
      'meter-mw08-000010',
      'meter-mw08-000037',
      'meter-mw08-000022',
      'meter-mw08-000038',
    ],
    offsetInSeconds: 189,
  },
  'DD:4A:9F:4A:9C:0E': {
    ipAddress: '10.1.205.194',
    apName: 'meter-mw08-000025',
    name: '*',
    signal: -45,
    history: ['meter-mw08-000026', 'meter-mw08-000034', 'meter-mw08-000010', 'meter-mw08-000018'],
    offsetInSeconds: 85,
  },
  '00:04:0F:FE:18:6D': {
    ipAddress: '10.1.231.208',
    apName: '',
    name: "Inès Perez's ZenBook Flip",
    signal: -51,
    history: [],
    offsetInSeconds: 213,
  },
  '80:58:F8:6F:D0:10': {
    ipAddress: '10.1.136.41',
    apName: '',
    name: "Andrei Kim's ThinkPad X1",
    signal: -74,
    history: [],
    offsetInSeconds: 227,
  },
  '00:1A:1E:1D:06:22': {
    ipAddress: '10.1.220.125',
    apName: 'meter-mw08-000032',
    name: "Camille Schmidt's Dragonfly Pro",
    signal: -62,
    history: [
      'meter-mw08-000032',
      'meter-mw08-000022',
      'meter-mw08-000020',
      'meter-mw08-000024',
      'meter-mw08-000017',
      'meter-mw08-000006',
      'meter-mw08-000007',
      'meter-mw08-000006',
      'meter-mw08-000039',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 248,
  },
  'E4:2A:AC:82:45:2D': {
    ipAddress: '10.1.116.19',
    apName: '',
    name: "Clara's Surface Pro",
    signal: -50,
    history: [],
    offsetInSeconds: 201,
  },
  '60:C9:23:16:99:22': {
    ipAddress: '10.1.239.243',
    apName: 'meter-mw08-000031',
    name: '*',
    signal: -64,
    history: ['meter-mw08-000021', 'meter-mw08-000024', 'meter-mw08-000040'],
    offsetInSeconds: 226,
  },
  'A8:96:5A:78:91:0C': {
    ipAddress: '10.1.178.74',
    apName: '',
    name: '*',
    signal: -48,
    history: [],
    offsetInSeconds: 17,
  },
  '1A:E5:86:59:CA:D1': {
    ipAddress: '10.1.163.52',
    apName: '',
    name: '*',
    signal: -63,
    history: [],
    offsetInSeconds: 242,
  },
  'B8:41:A4:4F:E1:16': {
    ipAddress: '10.1.243.200',
    apName: '',
    name: "José Hill's MacBook Pro",
    signal: -45,
    history: [],
    offsetInSeconds: 121,
  },
  '94:9A:A9:5D:78:64': {
    ipAddress: '10.1.248.77',
    apName: 'meter-mw08-000023',
    name: "Rahul Davis's Surface Pro",
    signal: -68,
    history: ['meter-mw08-000037'],
    offsetInSeconds: 271,
  },
  'D8:6C:63:9C:E1:3F': {
    ipAddress: '10.1.172.233',
    apName: 'meter-mw08-000009',
    name: "Xiao's Chromebook",
    signal: -49,
    history: ['meter-mw08-000027', 'meter-mw08-000002', 'meter-mw08-000034'],
    offsetInSeconds: 75,
  },
  'F4:0E:01:18:6E:E8': {
    ipAddress: '10.1.129.252',
    apName: 'meter-mw08-000001',
    name: "Emma Patel's iPad",
    signal: -56,
    history: ['meter-mw08-000034', 'meter-mw08-000010', 'meter-mw08-000030'],
    offsetInSeconds: 126,
  },
  '1C:40:24:D2:69:50': {
    ipAddress: '10.1.11.59',
    apName: '',
    name: "Mariana Wilson's Inspiron",
    signal: -66,
    history: [],
    offsetInSeconds: 222,
  },
  'D8:14:BE:83:F2:2D': {
    ipAddress: '10.1.185.7',
    apName: 'meter-mw08-000010',
    name: '*',
    signal: -50,
    history: ['meter-mw08-000030', 'meter-mw08-000022', 'meter-mw08-000001'],
    offsetInSeconds: 100,
  },
  '00:04:0F:D3:33:2E': {
    ipAddress: '10.1.169.223',
    apName: '',
    name: "Yu's ZenBook Flip",
    signal: -73,
    history: [],
    offsetInSeconds: 279,
  },
  'AC:3E:B1:10:51:49': {
    ipAddress: '10.1.13.117',
    apName: 'meter-mw08-000038',
    name: "Hui Anderson's Chromebook",
    signal: -72,
    history: ['meter-mw08-000039'],
    offsetInSeconds: 227,
  },
  '44:16:22:47:76:AB': {
    ipAddress: '10.1.64.218',
    apName: '',
    name: "Camille's Surface Pro",
    signal: -72,
    history: [],
    offsetInSeconds: 150,
  },
  'C0:A6:00:53:A2:1E': {
    ipAddress: '10.1.98.12',
    apName: '',
    name: "Sarah Perez's MBP",
    signal: -63,
    history: [],
    offsetInSeconds: 265,
  },
  'E6:27:58:CA:A0:B8': {
    ipAddress: '10.1.69.89',
    apName: 'meter-mw08-000011',
    name: '*',
    signal: -72,
    history: ['meter-mw08-000004', 'meter-mw08-000006'],
    offsetInSeconds: 236,
  },
  '68:09:27:15:8B:CA': {
    ipAddress: '10.1.145.176',
    apName: '',
    name: "Pavel's iPad",
    signal: -75,
    history: [],
    offsetInSeconds: 85,
  },
  '00:59:07:DA:0F:53': {
    ipAddress: '10.1.161.216',
    apName: 'meter-mw08-000029',
    name: "Valentina's ThinkPad X1",
    signal: -45,
    history: [
      'meter-mw08-000010',
      'meter-mw08-000026',
      'meter-mw08-000009',
      'meter-mw08-000038',
      'meter-mw08-000019',
      'meter-mw08-000033',
      'meter-mw08-000030',
      'meter-mw08-000015',
      'meter-mw08-000014',
      'meter-mw08-000009',
    ],
    offsetInSeconds: 54,
  },
  '4C:E6:C0:C6:60:C6': {
    ipAddress: '10.1.32.169',
    apName: 'meter-mw08-000020',
    name: "Lucas's MacBook Pro",
    signal: -49,
    history: ['meter-mw08-000023', 'meter-mw08-000010', 'meter-mw08-000022'],
    offsetInSeconds: 142,
  },
  '04:79:45:02:3D:EC': {
    ipAddress: '10.1.17.223',
    apName: 'meter-mw08-000038',
    name: '*',
    signal: -62,
    history: ['meter-mw08-000035', 'meter-mw08-000011'],
    offsetInSeconds: 28,
  },
  '00:B3:62:53:63:22': {
    ipAddress: '10.1.228.191',
    apName: 'meter-mw08-000014',
    name: "Mariana's MacBook",
    signal: -74,
    history: [
      'meter-mw08-000040',
      'meter-mw08-000038',
      'meter-mw08-000028',
      'meter-mw08-000009',
      'meter-mw08-000036',
      'meter-mw08-000021',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 288,
  },
  '7B:B0:E7:2E:3C:9A': {
    ipAddress: '10.1.169.76',
    apName: 'meter-mw08-000014',
    name: '*',
    signal: -59,
    history: ['meter-mw08-000015'],
    offsetInSeconds: 81,
  },
  '00:0F:61:C2:39:37': {
    ipAddress: '10.1.201.87',
    apName: '',
    name: "Vladimir Hall's Dragonfly Pro",
    signal: -57,
    history: [],
    offsetInSeconds: 291,
  },
  'BC:98:DF:75:83:85': {
    ipAddress: '10.1.227.76',
    apName: 'meter-mw08-000016',
    name: "James's ThinkPad X1",
    signal: -74,
    history: [
      'meter-mw08-000026',
      'meter-mw08-000038',
      'meter-mw08-000021',
      'meter-mw08-000016',
      'meter-mw08-000027',
      'meter-mw08-000002',
      'meter-mw08-000033',
      'meter-mw08-000012',
      'meter-mw08-000028',
    ],
    offsetInSeconds: 179,
  },
  'F4:65:A6:E5:91:F3': {
    ipAddress: '10.1.243.210',
    apName: 'meter-mw08-000002',
    name: "Emily Thompson's MacBook Pro",
    signal: -66,
    history: [
      'meter-mw08-000039',
      'meter-mw08-000032',
      'meter-mw08-000005',
      'meter-mw08-000039',
      'meter-mw08-000035',
      'meter-mw08-000036',
      'meter-mw08-000034',
      'meter-mw08-000020',
    ],
    offsetInSeconds: 22,
  },
  '00:04:0F:67:07:A8': {
    ipAddress: '10.1.99.26',
    apName: 'meter-mw08-000038',
    name: "Thomas Robinson's Laptop",
    signal: -66,
    history: ['meter-mw08-000020', 'meter-mw08-000012', 'meter-mw08-000029', 'meter-mw08-000020'],
    offsetInSeconds: 215,
  },
  '30:09:C0:D9:69:44': {
    ipAddress: '10.1.97.194',
    apName: '',
    name: "Rahul's ThinkPad X1",
    signal: -56,
    history: [],
    offsetInSeconds: 105,
  },
  '14:9E:CF:78:E1:1C': {
    ipAddress: '10.1.78.179',
    apName: 'meter-mw08-000026',
    name: "Manon Zhang's Inspiron",
    signal: -49,
    history: [
      'meter-mw08-000015',
      'meter-mw08-000026',
      'meter-mw08-000024',
      'meter-mw08-000016',
      'meter-mw08-000017',
      'meter-mw08-000024',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 188,
  },
  '00:04:0F:C6:BC:9A': {
    ipAddress: '10.1.234.33',
    apName: 'meter-mw08-000013',
    name: "Alejandro's Laptop",
    signal: -61,
    history: [
      'meter-mw08-000029',
      'meter-mw08-000022',
      'meter-mw08-000024',
      'meter-mw08-000020',
      'meter-mw08-000022',
      'meter-mw08-000014',
      'meter-mw08-000036',
      'meter-mw08-000030',
      'meter-mw08-000029',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 68,
  },
  'A6:D6:E5:C7:9B:0C': {
    ipAddress: '10.1.238.239',
    apName: 'meter-mw08-000001',
    name: '*',
    signal: -47,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000033',
      'meter-mw08-000013',
      'meter-mw08-000025',
      'meter-mw08-000025',
      'meter-mw08-000019',
      'meter-mw08-000006',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 287,
  },
  '84:D3:28:64:2B:CD': {
    ipAddress: '10.1.235.231',
    apName: 'meter-mw08-000014',
    name: "Andrew's iPhone",
    signal: -74,
    history: [
      'meter-mw08-000020',
      'meter-mw08-000027',
      'meter-mw08-000026',
      'meter-mw08-000003',
      'meter-mw08-000005',
      'meter-mw08-000032',
      'meter-mw08-000020',
      'meter-mw08-000017',
    ],
    offsetInSeconds: 117,
  },
  'B8:FF:61:F2:87:4E': {
    ipAddress: '10.1.37.19',
    apName: 'meter-mw08-000012',
    name: "Kavya's MBP",
    signal: -52,
    history: [
      'meter-mw08-000026',
      'meter-mw08-000006',
      'meter-mw08-000040',
      'meter-mw08-000012',
      'meter-mw08-000006',
      'meter-mw08-000022',
      'meter-mw08-000015',
      'meter-mw08-000027',
      'meter-mw08-000035',
      'meter-mw08-000032',
    ],
    offsetInSeconds: 284,
  },
  '00:10:FA:DC:F9:42': {
    ipAddress: '10.1.82.4',
    apName: 'meter-mw08-000031',
    name: "Ivan's MacBook",
    signal: -70,
    history: ['meter-mw08-000022'],
    offsetInSeconds: 237,
  },
  '00:04:0F:BD:A0:17': {
    ipAddress: '10.1.30.130',
    apName: 'meter-mw08-000030',
    name: "Pavel Li's Laptop",
    signal: -57,
    history: [
      'meter-mw08-000029',
      'meter-mw08-000016',
      'meter-mw08-000024',
      'meter-mw08-000030',
      'meter-mw08-000025',
      'meter-mw08-000040',
      'meter-mw08-000040',
      'meter-mw08-000023',
    ],
    offsetInSeconds: 292,
  },
  '20:62:74:DA:8B:3C': {
    ipAddress: '10.1.82.78',
    apName: 'meter-mw08-000009',
    name: "Lucas Zhang's Surface Pro",
    signal: -52,
    history: ['meter-mw08-000012'],
    offsetInSeconds: 76,
  },
  'D4:C9:4B:76:1F:28': {
    ipAddress: '10.1.158.67',
    apName: '',
    name: "Yulia's ThinkPad X1",
    signal: -69,
    history: [],
    offsetInSeconds: 99,
  },
  '98:FE:94:5E:65:EA': {
    ipAddress: '10.1.209.155',
    apName: 'meter-mw08-000007',
    name: "Elizabeth Hill's MBP-2",
    signal: -57,
    history: [
      'meter-mw08-000006',
      'meter-mw08-000014',
      'meter-mw08-000024',
      'meter-mw08-000029',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 95,
  },
  '40:B3:95:A1:1F:EC': {
    ipAddress: '10.1.159.246',
    apName: '',
    name: "Zhang Alves's MacBook Pro",
    signal: -48,
    history: [],
    offsetInSeconds: 231,
  },
  '48:A6:B8:68:52:EA': {
    ipAddress: '10.1.156.242',
    apName: '',
    name: 'Sonos',
    signal: -57,
    history: [],
    offsetInSeconds: 155,
  },
  'F8:03:77:61:13:06': {
    ipAddress: '10.1.27.99',
    apName: 'meter-mw08-000018',
    name: "Léa Garcia's iPad",
    signal: -57,
    history: ['meter-mw08-000007', 'meter-mw08-000039', 'meter-mw08-000029'],
    offsetInSeconds: 222,
  },
  'EC:50:AA:AD:E1:5D': {
    ipAddress: '10.1.107.91',
    apName: 'meter-mw08-000009',
    name: "Elena Young's Dragonfly Pro",
    signal: -63,
    history: [
      'meter-mw08-000011',
      'meter-mw08-000004',
      'meter-mw08-000039',
      'meter-mw08-000018',
      'meter-mw08-000033',
      'meter-mw08-000018',
    ],
    offsetInSeconds: 258,
  },
  '28:DE:65:5E:DD:AE': {
    ipAddress: '10.1.26.137',
    apName: 'meter-mw08-000011',
    name: "Lola Zhang's Dragonfly Pro",
    signal: -72,
    history: ['meter-mw08-000020', 'meter-mw08-000031', 'meter-mw08-000021', 'meter-mw08-000016'],
    offsetInSeconds: 185,
  },
  '00:04:0F:F6:CF:56': {
    ipAddress: '10.1.116.64',
    apName: '',
    name: "Hong's ZenBook Flip",
    signal: -56,
    history: [],
    offsetInSeconds: 226,
  },
  '84:78:8B:E7:70:D9': {
    ipAddress: '10.1.91.50',
    apName: 'meter-mw08-000023',
    name: "Yu Kim's iPad",
    signal: -75,
    history: ['meter-mw08-000008', 'meter-mw08-000038'],
    offsetInSeconds: 232,
  },
  '5C:52:84:05:F3:37': {
    ipAddress: '10.1.223.40',
    apName: 'meter-mw08-000020',
    name: "Xin Kovacs's MBP",
    signal: -46,
    history: [
      'meter-mw08-000019',
      'meter-mw08-000005',
      'meter-mw08-000037',
      'meter-mw08-000014',
      'meter-mw08-000004',
      'meter-mw08-000013',
      'meter-mw08-000011',
      'meter-mw08-000029',
    ],
    offsetInSeconds: 139,
  },
  '00:04:0F:9F:4D:DA': {
    ipAddress: '10.1.158.133',
    apName: 'meter-mw08-000027',
    name: "Lucía Rahman's ZenBook Flip",
    signal: -54,
    history: [
      'meter-mw08-000029',
      'meter-mw08-000002',
      'meter-mw08-000032',
      'meter-mw08-000016',
      'meter-mw08-000025',
      'meter-mw08-000036',
      'meter-mw08-000039',
    ],
    offsetInSeconds: 45,
  },
  '70:F8:AE:EB:DE:D3': {
    ipAddress: '10.1.85.212',
    apName: 'meter-mw08-000033',
    name: "Svetlana Kim's Surface Pro",
    signal: -54,
    history: [
      'meter-mw08-000008',
      'meter-mw08-000034',
      'meter-mw08-000022',
      'meter-mw08-000022',
      'meter-mw08-000038',
      'meter-mw08-000035',
      'meter-mw08-000018',
    ],
    offsetInSeconds: 174,
  },
  '14:94:6C:BB:5B:2C': {
    ipAddress: '10.1.15.99',
    apName: 'meter-mw08-000040',
    name: "Romain's iPad",
    signal: -61,
    history: [
      'meter-mw08-000014',
      'meter-mw08-000030',
      'meter-mw08-000011',
      'meter-mw08-000037',
      'meter-mw08-000014',
      'meter-mw08-000026',
      'meter-mw08-000012',
    ],
    offsetInSeconds: 296,
  },
  '70:70:0D:5C:F1:33': {
    ipAddress: '10.1.173.75',
    apName: 'meter-mw08-000021',
    name: "Clara's iPad",
    signal: -46,
    history: [
      'meter-mw08-000006',
      'meter-mw08-000031',
      'meter-mw08-000038',
      'meter-mw08-000015',
      'meter-mw08-000011',
      'meter-mw08-000003',
    ],
    offsetInSeconds: 49,
  },
  '80:E6:50:5F:2A:16': {
    ipAddress: '10.1.31.133',
    apName: '',
    name: "Yulia Brown's MBP-2",
    signal: -66,
    history: [],
    offsetInSeconds: 256,
  },
  '2C:23:3A:DD:ED:74': {
    ipAddress: '10.1.179.160',
    apName: '',
    name: "Jia's Dragonfly Pro",
    signal: -46,
    history: [],
    offsetInSeconds: 2,
  },
  '48:D6:D5:A7:76:75': {
    ipAddress: '10.1.117.103',
    apName: 'meter-mw08-000033',
    name: "Romain Young's Chromebook",
    signal: -51,
    history: ['meter-mw08-000026'],
    offsetInSeconds: 293,
  },
  '58:D9:C3:FA:1B:D7': {
    ipAddress: '10.1.184.215',
    apName: 'meter-mw08-000033',
    name: "Vladimir Zhao's ThinkPad X1",
    signal: -49,
    history: [
      'meter-mw08-000017',
      'meter-mw08-000023',
      'meter-mw08-000002',
      'meter-mw08-000019',
      'meter-mw08-000005',
      'meter-mw08-000033',
      'meter-mw08-000021',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 267,
  },
  '00:F4:B9:DF:68:D6': {
    ipAddress: '10.1.236.70',
    apName: 'meter-mw08-000031',
    name: "Mateo's MacBook Pro",
    signal: -68,
    history: [
      'meter-mw08-000019',
      'meter-mw08-000025',
      'meter-mw08-000030',
      'meter-mw08-000004',
      'meter-mw08-000023',
      'meter-mw08-000023',
      'meter-mw08-000017',
      'meter-mw08-000034',
      'meter-mw08-000010',
      'meter-mw08-000032',
    ],
    offsetInSeconds: 21,
  },
  '90:EC:EA:D5:AC:6D': {
    ipAddress: '10.1.165.34',
    apName: 'meter-mw08-000013',
    name: "Alexander Johnson's iPhone",
    signal: -51,
    history: ['meter-mw08-000020', 'meter-mw08-000009', 'meter-mw08-000034', 'meter-mw08-000011'],
    offsetInSeconds: 220,
  },
  '00:22:64:1D:3A:AD': {
    ipAddress: '10.1.244.101',
    apName: 'meter-mw08-000037',
    name: "Priya Davis's Dragonfly Pro",
    signal: -50,
    history: [
      'meter-mw08-000035',
      'meter-mw08-000002',
      'meter-mw08-000021',
      'meter-mw08-000010',
      'meter-mw08-000035',
      'meter-mw08-000029',
    ],
    offsetInSeconds: 215,
  },
  '0C:74:C2:AF:BC:68': {
    ipAddress: '10.1.39.241',
    apName: 'meter-mw08-000007',
    name: "Diego's MacBook",
    signal: -53,
    history: [
      'meter-mw08-000007',
      'meter-mw08-000040',
      'meter-mw08-000013',
      'meter-mw08-000005',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 219,
  },
  '6A:D2:AF:A9:7D:34': {
    ipAddress: '10.1.208.127',
    apName: '',
    name: '*',
    signal: -58,
    history: [],
    offsetInSeconds: 101,
  },
  '44:BB:3B:43:B7:E8': {
    ipAddress: '10.1.8.39',
    apName: '',
    name: "Sofía Green's Chromebook",
    signal: -46,
    history: [],
    offsetInSeconds: 279,
  },
  '00:04:0F:8A:43:6B': {
    ipAddress: '10.1.249.118',
    apName: 'meter-mw08-000029',
    name: "Chloé's Laptop",
    signal: -53,
    history: ['meter-mw08-000038', 'meter-mw08-000022', 'meter-mw08-000004'],
    offsetInSeconds: 47,
  },
  '14:22:3B:F1:AC:A1': {
    ipAddress: '10.1.114.25',
    apName: '',
    name: "Raj Taylor's Chromebook",
    signal: -52,
    history: [],
    offsetInSeconds: 276,
  },
  '20:69:80:7F:CC:B1': {
    ipAddress: '10.1.92.253',
    apName: 'meter-mw08-000023',
    name: "Martina's MBP",
    signal: -64,
    history: [
      'meter-mw08-000028',
      'meter-mw08-000020',
      'meter-mw08-000023',
      'meter-mw08-000022',
      'meter-mw08-000012',
      'meter-mw08-000008',
      'meter-mw08-000039',
      'meter-mw08-000029',
      'meter-mw08-000023',
    ],
    offsetInSeconds: 43,
  },
  '94:9A:A9:9A:E2:D0': {
    ipAddress: '10.1.97.6',
    apName: 'meter-mw08-000039',
    name: "Raj Liu's Surface Pro",
    signal: -54,
    history: [
      'meter-mw08-000033',
      'meter-mw08-000027',
      'meter-mw08-000031',
      'meter-mw08-000025',
      'meter-mw08-000011',
      'meter-mw08-000027',
      'meter-mw08-000009',
      'meter-mw08-000014',
      'meter-mw08-000034',
    ],
    offsetInSeconds: 55,
  },
  '70:DE:E2:58:F9:17': {
    ipAddress: '10.1.229.32',
    apName: 'meter-mw08-000005',
    name: "Raj Yang's MacBook",
    signal: -74,
    history: [
      'meter-mw08-000016',
      'meter-mw08-000002',
      'meter-mw08-000023',
      'meter-mw08-000005',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 134,
  },
  'F9:AB:89:BD:E2:83': {
    ipAddress: '10.1.39.247',
    apName: 'meter-mw08-000007',
    name: '*',
    signal: -54,
    history: ['meter-mw08-000001', 'meter-mw08-000034', 'meter-mw08-000017'],
    offsetInSeconds: 19,
  },
  '34:E6:D7:1F:88:80': {
    ipAddress: '10.1.42.36',
    apName: 'meter-mw08-000015',
    name: "Andrew's Inspiron",
    signal: -61,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000033',
      'meter-mw08-000002',
      'meter-mw08-000002',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 83,
  },
  '58:73:D8:1E:CD:15': {
    ipAddress: '10.1.126.158',
    apName: 'meter-mw08-000018',
    name: 'HomePod',
    signal: -54,
    history: [
      'meter-mw08-000017',
      'meter-mw08-000004',
      'meter-mw08-000006',
      'meter-mw08-000033',
      'meter-mw08-000001',
      'meter-mw08-000039',
      'meter-mw08-000027',
    ],
    offsetInSeconds: 133,
  },
  '88:66:5A:55:05:48': {
    ipAddress: '10.1.235.210',
    apName: 'meter-mw08-000033',
    name: "Daniel Yoshida's MacBook Pro",
    signal: -45,
    history: [
      'meter-mw08-000007',
      'meter-mw08-000025',
      'meter-mw08-000024',
      'meter-mw08-000013',
      'meter-mw08-000035',
      'meter-mw08-000020',
      'meter-mw08-000039',
      'meter-mw08-000002',
      'meter-mw08-000005',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 251,
  },
  'A4:48:80:9D:32:F9': {
    ipAddress: '10.1.181.215',
    apName: 'meter-mw08-000019',
    name: '*',
    signal: -65,
    history: ['meter-mw08-000011', 'meter-mw08-000002', 'meter-mw08-000027', 'meter-mw08-000034'],
    offsetInSeconds: 173,
  },
  '1B:D6:91:A2:55:97': {
    ipAddress: '10.1.10.166',
    apName: 'meter-mw08-000018',
    name: '*',
    signal: -73,
    history: [
      'meter-mw08-000004',
      'meter-mw08-000007',
      'meter-mw08-000009',
      'meter-mw08-000002',
      'meter-mw08-000038',
      'meter-mw08-000015',
      'meter-mw08-000012',
      'meter-mw08-000015',
    ],
    offsetInSeconds: 186,
  },
  'AC:1A:3D:55:EE:B0': {
    ipAddress: '10.1.151.90',
    apName: 'meter-mw08-000020',
    name: "José Rodrigues's Inspiron",
    signal: -64,
    history: [
      'meter-mw08-000030',
      'meter-mw08-000015',
      'meter-mw08-000008',
      'meter-mw08-000008',
      'meter-mw08-000009',
      'meter-mw08-000002',
      'meter-mw08-000040',
      'meter-mw08-000005',
      'meter-mw08-000016',
      'meter-mw08-000007',
    ],
    offsetInSeconds: 55,
  },
  '28:EA:0B:9D:7D:2B': {
    ipAddress: '10.1.167.81',
    apName: 'meter-mw08-000009',
    name: "Rahul Chen's Surface Pro",
    signal: -65,
    history: [
      'meter-mw08-000022',
      'meter-mw08-000029',
      'meter-mw08-000011',
      'meter-mw08-000037',
      'meter-mw08-000038',
      'meter-mw08-000001',
      'meter-mw08-000019',
      'meter-mw08-000004',
      'meter-mw08-000021',
    ],
    offsetInSeconds: 234,
  },
  '44:33:26:0E:F2:9B': {
    ipAddress: '10.1.174.47',
    apName: 'meter-mw08-000023',
    name: '*',
    signal: -71,
    history: [
      'meter-mw08-000005',
      'meter-mw08-000031',
      'meter-mw08-000027',
      'meter-mw08-000028',
      'meter-mw08-000018',
      'meter-mw08-000014',
      'meter-mw08-000027',
      'meter-mw08-000004',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 76,
  },
  'E8:D5:2B:C3:A4:BD': {
    ipAddress: '10.1.152.99',
    apName: 'meter-mw08-000037',
    name: "Xiao Chowdhury's Chromebook",
    signal: -66,
    history: ['meter-mw08-000020'],
    offsetInSeconds: 204,
  },
  'B4:AE:C1:CC:AA:76': {
    ipAddress: '10.1.70.143',
    apName: 'meter-mw08-000012',
    name: "Lucía Perez's iPad",
    signal: -64,
    history: ['meter-mw08-000002', 'meter-mw08-000031'],
    offsetInSeconds: 96,
  },
  'A4:BA:DB:67:83:91': {
    ipAddress: '10.1.192.29',
    apName: 'meter-mw08-000033',
    name: "Mary's Inspiron",
    signal: -46,
    history: [
      'meter-mw08-000024',
      'meter-mw08-000030',
      'meter-mw08-000035',
      'meter-mw08-000023',
      'meter-mw08-000039',
      'meter-mw08-000038',
      'meter-mw08-000035',
      'meter-mw08-000003',
      'meter-mw08-000021',
    ],
    offsetInSeconds: 282,
  },
  'C0:17:54:7E:42:24': {
    ipAddress: '10.1.92.112',
    apName: 'meter-mw08-000032',
    name: "Emma's iPad",
    signal: -72,
    history: ['meter-mw08-000015', 'meter-mw08-000002', 'meter-mw08-000002'],
    offsetInSeconds: 18,
  },
  '98:F0:AB:0D:F5:22': {
    ipAddress: '10.1.98.208',
    apName: '',
    name: "Wen's iPhone",
    signal: -54,
    history: [],
    offsetInSeconds: 138,
  },
  '90:72:40:90:91:38': {
    ipAddress: '10.1.58.92',
    apName: '',
    name: "Maria Takahashi's iPhone",
    signal: -54,
    history: [],
    offsetInSeconds: 88,
  },
  'C8:58:95:F3:B7:E7': {
    ipAddress: '10.1.234.218',
    apName: 'meter-mw08-000039',
    name: "Mateo Kim's ThinkPad X1",
    signal: -66,
    history: ['meter-mw08-000024', 'meter-mw08-000017', 'meter-mw08-000009'],
    offsetInSeconds: 273,
  },
  'F4:F1:5A:B3:10:BF': {
    ipAddress: '10.1.121.209',
    apName: '',
    name: "Martina's MBP-2",
    signal: -67,
    history: [],
    offsetInSeconds: 104,
  },
  'C4:9D:ED:C4:B0:1B': {
    ipAddress: '10.1.32.220',
    apName: 'meter-mw08-000003',
    name: "Vladimir Gupta's Surface Pro",
    signal: -66,
    history: [
      'meter-mw08-000031',
      'meter-mw08-000027',
      'meter-mw08-000012',
      'meter-mw08-000028',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 76,
  },
  '00:04:0F:D6:04:B2': {
    ipAddress: '10.1.207.120',
    apName: 'meter-mw08-000003',
    name: "Ivan Kovacs's ZenBook Flip",
    signal: -48,
    history: [
      'meter-mw08-000035',
      'meter-mw08-000014',
      'meter-mw08-000021',
      'meter-mw08-000006',
      'meter-mw08-000032',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 82,
  },
  '00:B0:D0:0A:81:AC': {
    ipAddress: '10.1.80.245',
    apName: 'meter-mw08-000035',
    name: "Hui's Inspiron",
    signal: -54,
    history: [
      'meter-mw08-000025',
      'meter-mw08-000028',
      'meter-mw08-000007',
      'meter-mw08-000001',
      'meter-mw08-000027',
      'meter-mw08-000017',
      'meter-mw08-000012',
    ],
    offsetInSeconds: 194,
  },
  'A3:E4:41:34:6B:8F': {
    ipAddress: '10.1.46.84',
    apName: 'meter-mw08-000017',
    name: '*',
    signal: -48,
    history: [
      'meter-mw08-000027',
      'meter-mw08-000033',
      'meter-mw08-000021',
      'meter-mw08-000019',
      'meter-mw08-000016',
      'meter-mw08-000021',
      'meter-mw08-000023',
    ],
    offsetInSeconds: 107,
  },
  'D8:71:57:4E:D5:E9': {
    ipAddress: '10.1.84.203',
    apName: 'meter-mw08-000015',
    name: "Martina Schmidt's ThinkPad X1",
    signal: -60,
    history: [
      'meter-mw08-000025',
      'meter-mw08-000021',
      'meter-mw08-000025',
      'meter-mw08-000039',
      'meter-mw08-000015',
      'meter-mw08-000020',
      'meter-mw08-000039',
      'meter-mw08-000026',
      'meter-mw08-000033',
      'meter-mw08-000008',
    ],
    offsetInSeconds: 201,
  },
  'FC:9C:A7:53:8E:26': {
    ipAddress: '10.1.233.219',
    apName: 'meter-mw08-000010',
    name: "Vladimir's iPad",
    signal: -65,
    history: ['meter-mw08-000023', 'meter-mw08-000005', 'meter-mw08-000036', 'meter-mw08-000035'],
    offsetInSeconds: 147,
  },
  '00:09:5C:43:D0:5F': {
    ipAddress: '10.1.71.49',
    apName: 'meter-mw08-000021',
    name: 'Philips Hue',
    signal: -66,
    history: [
      'meter-mw08-000023',
      'meter-mw08-000018',
      'meter-mw08-000002',
      'meter-mw08-000034',
      'meter-mw08-000033',
    ],
    offsetInSeconds: 247,
  },
  '00:04:0F:4E:B1:17': {
    ipAddress: '10.1.218.102',
    apName: '',
    name: "Emily King's Laptop",
    signal: -58,
    history: [],
    offsetInSeconds: 213,
  },
  '84:63:D6:AB:86:E0': {
    ipAddress: '10.1.234.213',
    apName: 'meter-mw08-000013',
    name: "Aarav Varga's Surface Pro",
    signal: -52,
    history: ['meter-mw08-000015'],
    offsetInSeconds: 31,
  },
  'CC:48:3A:27:D7:02': {
    ipAddress: '10.1.255.38',
    apName: 'meter-mw08-000016',
    name: "Divya Miller's Inspiron",
    signal: -67,
    history: [
      'meter-mw08-000008',
      'meter-mw08-000004',
      'meter-mw08-000019',
      'meter-mw08-000033',
      'meter-mw08-000027',
      'meter-mw08-000023',
      'meter-mw08-000035',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 219,
  },
  '1C:E6:2B:7F:4B:D3': {
    ipAddress: '10.1.20.152',
    apName: 'meter-mw08-000030',
    name: "Amit Silva's MacBook Pro",
    signal: -50,
    history: [
      'meter-mw08-000022',
      'meter-mw08-000039',
      'meter-mw08-000015',
      'meter-mw08-000010',
      'meter-mw08-000021',
      'meter-mw08-000038',
      'meter-mw08-000008',
      'meter-mw08-000004',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 263,
  },
  'DC:2B:2A:F5:85:39': {
    ipAddress: '10.1.103.208',
    apName: '',
    name: "Sophia's MacBook",
    signal: -50,
    history: [],
    offsetInSeconds: 257,
  },
  '00:0B:CD:70:EE:5F': {
    ipAddress: '10.1.18.212',
    apName: 'meter-mw08-000027',
    name: "Anastasia's Dragonfly Pro",
    signal: -67,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000022',
      'meter-mw08-000023',
      'meter-mw08-000030',
      'meter-mw08-000038',
      'meter-mw08-000010',
      'meter-mw08-000036',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 141,
  },
  'EC:ED:73:31:7C:25': {
    ipAddress: '10.1.243.109',
    apName: '',
    name: "Manon's ThinkPad X1",
    signal: -62,
    history: [],
    offsetInSeconds: 103,
  },
  '0C:3E:9F:3A:B0:29': {
    ipAddress: '10.1.167.244',
    apName: 'meter-mw08-000026',
    name: "Sergei Yang's iPhone",
    signal: -47,
    history: ['meter-mw08-000005', 'meter-mw08-000006', 'meter-mw08-000027', 'meter-mw08-000036'],
    offsetInSeconds: 134,
  },
  '67:E7:FB:81:AB:46': {
    ipAddress: '10.1.8.172',
    apName: 'meter-mw08-000013',
    name: '*',
    signal: -63,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000028',
      'meter-mw08-000018',
      'meter-mw08-000022',
      'meter-mw08-000023',
      'meter-mw08-000032',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 13,
  },
  '0C:41:3E:A4:42:3D': {
    ipAddress: '10.1.30.3',
    apName: 'meter-mw08-000019',
    name: "Kavya Nagy's Surface Pro",
    signal: -75,
    history: [
      'meter-mw08-000001',
      'meter-mw08-000014',
      'meter-mw08-000017',
      'meter-mw08-000028',
      'meter-mw08-000016',
      'meter-mw08-000017',
      'meter-mw08-000011',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 261,
  },
  'FC:26:F7:BA:FA:66': {
    ipAddress: '10.1.209.20',
    apName: 'meter-mw08-000015',
    name: '*',
    signal: -72,
    history: [
      'meter-mw08-000011',
      'meter-mw08-000028',
      'meter-mw08-000018',
      'meter-mw08-000005',
      'meter-mw08-000018',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 239,
  },
  'F6:CD:08:EA:1F:49': {
    ipAddress: '10.1.128.133',
    apName: 'meter-mw08-000029',
    name: '*',
    signal: -51,
    history: [
      'meter-mw08-000020',
      'meter-mw08-000004',
      'meter-mw08-000023',
      'meter-mw08-000009',
      'meter-mw08-000015',
      'meter-mw08-000024',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 74,
  },
  '00:04:0F:24:20:DD': {
    ipAddress: '10.1.89.136',
    apName: '',
    name: "Wen's Laptop",
    signal: -48,
    history: [],
    offsetInSeconds: 107,
  },
  'F9:54:CE:ED:A1:EE': {
    ipAddress: '10.1.142.69',
    apName: 'meter-mw08-000013',
    name: '*',
    signal: -55,
    history: [
      'meter-mw08-000039',
      'meter-mw08-000030',
      'meter-mw08-000006',
      'meter-mw08-000023',
      'meter-mw08-000006',
    ],
    offsetInSeconds: 85,
  },
  '00:30:65:52:E3:23': {
    ipAddress: '10.1.241.16',
    apName: 'meter-mw08-000011',
    name: "Maxim Yamamoto's MBP-2",
    signal: -45,
    history: [
      'meter-mw08-000033',
      'meter-mw08-000036',
      'meter-mw08-000007',
      'meter-mw08-000034',
      'meter-mw08-000002',
      'meter-mw08-000007',
      'meter-mw08-000003',
      'meter-mw08-000004',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 102,
  },
  '58:20:B1:95:9B:6D': {
    ipAddress: '10.1.46.33',
    apName: 'meter-mw08-000025',
    name: "Kavya's Dragonfly Pro",
    signal: -48,
    history: ['meter-mw08-000038', 'meter-mw08-000021', 'meter-mw08-000017', 'meter-mw08-000008'],
    offsetInSeconds: 17,
  },
  '00:25:4F:17:99:E8': {
    ipAddress: '10.1.80.132',
    apName: 'meter-mw08-000030',
    name: "Xuan Singh's Inspiron",
    signal: -69,
    history: [
      'meter-mw08-000004',
      'meter-mw08-000021',
      'meter-mw08-000029',
      'meter-mw08-000013',
      'meter-mw08-000001',
    ],
    offsetInSeconds: 101,
  },
  'E2:2A:59:97:E5:FB': {
    ipAddress: '10.1.124.210',
    apName: '',
    name: '*',
    signal: -68,
    history: [],
    offsetInSeconds: 66,
  },
  'FC:FC:48:9D:51:29': {
    ipAddress: '10.1.129.52',
    apName: '',
    name: "Anastasia's iPhone",
    signal: -52,
    history: [],
    offsetInSeconds: 22,
  },
  '2C:32:6A:52:EC:DD': {
    ipAddress: '10.1.168.222',
    apName: 'meter-mw08-000024',
    name: "Pedro Anderson's MBP",
    signal: -61,
    history: ['meter-mw08-000015', 'meter-mw08-000001', 'meter-mw08-000015', 'meter-mw08-000023'],
    offsetInSeconds: 216,
  },
  '00:0E:7F:B7:3C:FC': {
    ipAddress: '10.1.200.127',
    apName: '',
    name: "Sergei's Dragonfly Pro",
    signal: -67,
    history: [],
    offsetInSeconds: 34,
  },
  'F4:65:A6:2C:54:50': {
    ipAddress: '10.1.201.30',
    apName: 'meter-mw08-000002',
    name: "Théo Martinez's MBP-2",
    signal: -74,
    history: [
      'meter-mw08-000007',
      'meter-mw08-000010',
      'meter-mw08-000003',
      'meter-mw08-000031',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 161,
  },
  '27:D2:E0:6E:A6:36': {
    ipAddress: '10.1.78.38',
    apName: 'meter-mw08-000020',
    name: '*',
    signal: -75,
    history: [
      'meter-mw08-000030',
      'meter-mw08-000011',
      'meter-mw08-000026',
      'meter-mw08-000018',
      'meter-mw08-000028',
      'meter-mw08-000020',
      'meter-mw08-000008',
      'meter-mw08-000019',
    ],
    offsetInSeconds: 141,
  },
  'B4:5D:50:8D:06:98': {
    ipAddress: '10.1.188.219',
    apName: '',
    name: "Carlos Szabo's Dragonfly Pro",
    signal: -62,
    history: [],
    offsetInSeconds: 185,
  },
  'D0:C5:F3:17:97:E2': {
    ipAddress: '10.1.9.105',
    apName: '',
    name: "Yan's iPad",
    signal: -50,
    history: [],
    offsetInSeconds: 50,
  },
  'C0:CC:F8:C9:41:5C': {
    ipAddress: '10.1.51.30',
    apName: 'meter-mw08-000002',
    name: "Mia Adams's iPhone",
    signal: -53,
    history: ['meter-mw08-000031'],
    offsetInSeconds: 297,
  },
  'EC:9B:8B:A3:1B:DB': {
    ipAddress: '10.1.102.37',
    apName: '',
    name: "Alejandro's Dragonfly Pro",
    signal: -74,
    history: [],
    offsetInSeconds: 272,
  },
  '60:B7:6E:01:50:1D': {
    ipAddress: '10.1.21.24',
    apName: 'meter-mw08-000008',
    name: "Emma Silva's Chromebook",
    signal: -54,
    history: [
      'meter-mw08-000036',
      'meter-mw08-000024',
      'meter-mw08-000021',
      'meter-mw08-000035',
      'meter-mw08-000004',
      'meter-mw08-000020',
      'meter-mw08-000018',
    ],
    offsetInSeconds: 156,
  },
  'A0:A3:09:C8:7D:2A': {
    ipAddress: '10.1.206.193',
    apName: '',
    name: "Anika Miller's MBP-2",
    signal: -50,
    history: [],
    offsetInSeconds: 37,
  },
  'AC:67:84:03:DB:5F': {
    ipAddress: '10.1.223.245',
    apName: 'meter-mw08-000028',
    name: "Nathan's Chromebook",
    signal: -66,
    history: [
      'meter-mw08-000013',
      'meter-mw08-000016',
      'meter-mw08-000038',
      'meter-mw08-000017',
      'meter-mw08-000004',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 79,
  },
  '4C:AB:4F:23:7C:62': {
    ipAddress: '10.1.134.117',
    apName: '',
    name: "Tao's iPhone",
    signal: -61,
    history: [],
    offsetInSeconds: 84,
  },
  '34:12:98:05:5A:02': {
    ipAddress: '10.1.231.81',
    apName: '',
    name: "Manon Kovacs's iPad",
    signal: -58,
    history: [],
    offsetInSeconds: 52,
  },
  '5C:BA:37:65:A0:9D': {
    ipAddress: '10.1.85.38',
    apName: 'meter-mw08-000007',
    name: "Svetlana Hill's Surface Pro",
    signal: -51,
    history: [
      'meter-mw08-000028',
      'meter-mw08-000036',
      'meter-mw08-000026',
      'meter-mw08-000022',
      'meter-mw08-000014',
      'meter-mw08-000008',
      'meter-mw08-000021',
      'meter-mw08-000028',
      'meter-mw08-000031',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 227,
  },
  '64:E6:82:18:95:85': {
    ipAddress: '10.1.201.43',
    apName: 'meter-mw08-000013',
    name: "Hugo's MacBook",
    signal: -56,
    history: [
      'meter-mw08-000018',
      'meter-mw08-000032',
      'meter-mw08-000040',
      'meter-mw08-000019',
      'meter-mw08-000007',
      'meter-mw08-000018',
      'meter-mw08-000031',
    ],
    offsetInSeconds: 154,
  },
  '50:32:37:20:14:5E': {
    ipAddress: '10.1.28.53',
    apName: 'meter-mw08-000032',
    name: "Anton Martinez's MacBook Pro",
    signal: -57,
    history: ['meter-mw08-000015'],
    offsetInSeconds: 63,
  },
  'CC:C7:60:16:DE:90': {
    ipAddress: '10.1.198.127',
    apName: 'meter-mw08-000026',
    name: "Javier's iPad",
    signal: -72,
    history: [
      'meter-mw08-000018',
      'meter-mw08-000038',
      'meter-mw08-000017',
      'meter-mw08-000004',
      'meter-mw08-000039',
      'meter-mw08-000032',
      'meter-mw08-000039',
      'meter-mw08-000007',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 257,
  },
  '00:22:41:3D:B2:48': {
    ipAddress: '10.1.158.227',
    apName: 'meter-mw08-000009',
    name: "Ekaterina's MacBook Pro",
    signal: -60,
    history: ['meter-mw08-000034'],
    offsetInSeconds: 246,
  },
  'AC:87:A3:5C:0E:45': {
    ipAddress: '10.1.152.250',
    apName: 'meter-mw08-000034',
    name: "Arjun Adams's MBP-2",
    signal: -72,
    history: [
      'meter-mw08-000017',
      'meter-mw08-000001',
      'meter-mw08-000037',
      'meter-mw08-000039',
      'meter-mw08-000025',
      'meter-mw08-000013',
      'meter-mw08-000003',
    ],
    offsetInSeconds: 89,
  },
  '10:29:59:96:8D:E6': {
    ipAddress: '10.1.50.161',
    apName: 'meter-mw08-000037',
    name: "Inès Rodrigues's MBP",
    signal: -72,
    history: ['meter-mw08-000031'],
    offsetInSeconds: 66,
  },
  'D0:92:9E:55:8F:A5': {
    ipAddress: '10.1.234.79',
    apName: 'meter-mw08-000014',
    name: "Akash Scott's Surface Pro",
    signal: -59,
    history: [
      'meter-mw08-000016',
      'meter-mw08-000010',
      'meter-mw08-000039',
      'meter-mw08-000020',
      'meter-mw08-000007',
      'meter-mw08-000009',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 25,
  },
  'D4:28:D9:96:59:19': {
    ipAddress: '10.1.171.240',
    apName: 'meter-mw08-000013',
    name: '*',
    signal: -50,
    history: ['meter-mw08-000037'],
    offsetInSeconds: 229,
  },
  'F0:C7:25:E2:A5:9B': {
    ipAddress: '10.1.250.67',
    apName: 'meter-mw08-000013',
    name: "Anika's MBP-2",
    signal: -72,
    history: ['meter-mw08-000018', 'meter-mw08-000020', 'meter-mw08-000013', 'meter-mw08-000014'],
    offsetInSeconds: 54,
  },
  'D8:A2:5E:E8:97:B2': {
    ipAddress: '10.1.7.15',
    apName: 'meter-mw08-000010',
    name: "Javier's iPad",
    signal: -51,
    history: ['meter-mw08-000018', 'meter-mw08-000025', 'meter-mw08-000021', 'meter-mw08-000008'],
    offsetInSeconds: 267,
  },
  'A4:C3:61:C5:57:A9': {
    ipAddress: '10.1.128.254',
    apName: 'meter-mw08-000025',
    name: "Mia Alves's MBP-2",
    signal: -68,
    history: [
      'meter-mw08-000024',
      'meter-mw08-000016',
      'meter-mw08-000036',
      'meter-mw08-000023',
      'meter-mw08-000023',
      'meter-mw08-000005',
    ],
    offsetInSeconds: 191,
  },
  'AA:16:7C:71:97:C1': {
    ipAddress: '10.1.158.216',
    apName: 'meter-mw08-000017',
    name: '*',
    signal: -54,
    history: ['meter-mw08-000026', 'meter-mw08-000005'],
    offsetInSeconds: 214,
  },
  'FC:8C:11:9E:7B:D1': {
    ipAddress: '10.1.101.167',
    apName: 'meter-mw08-000004',
    name: "Olga's Surface Pro",
    signal: -53,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000038',
      'meter-mw08-000010',
      'meter-mw08-000021',
      'meter-mw08-000022',
      'meter-mw08-000031',
      'meter-mw08-000039',
      'meter-mw08-000019',
    ],
    offsetInSeconds: 44,
  },
  'AC:90:85:8C:39:3E': {
    ipAddress: '10.1.209.39',
    apName: 'meter-mw08-000019',
    name: "Pavel's MacBook Pro",
    signal: -52,
    history: ['meter-mw08-000033', 'meter-mw08-000019', 'meter-mw08-000024', 'meter-mw08-000005'],
    offsetInSeconds: 253,
  },
  '14:9A:10:54:1E:52': {
    ipAddress: '10.1.12.213',
    apName: 'meter-mw08-000005',
    name: "David Toth's Surface Pro",
    signal: -68,
    history: [
      'meter-mw08-000023',
      'meter-mw08-000036',
      'meter-mw08-000031',
      'meter-mw08-000010',
      'meter-mw08-000027',
      'meter-mw08-000010',
      'meter-mw08-000012',
    ],
    offsetInSeconds: 211,
  },
  'F0:EF:86:43:D0:E6': {
    ipAddress: '10.1.88.212',
    apName: '',
    name: "Thomas Patel's Chromebook",
    signal: -69,
    history: [],
    offsetInSeconds: 178,
  },
  '3C:AB:8E:46:0D:36': {
    ipAddress: '10.1.29.136',
    apName: '',
    name: "Andrew's MacBook",
    signal: -68,
    history: [],
    offsetInSeconds: 141,
  },
  '90:B2:1F:FE:4B:F0': {
    ipAddress: '10.1.158.144',
    apName: 'meter-mw08-000032',
    name: "Luis's iPad",
    signal: -48,
    history: [
      'meter-mw08-000023',
      'meter-mw08-000005',
      'meter-mw08-000010',
      'meter-mw08-000002',
      'meter-mw08-000015',
      'meter-mw08-000007',
      'meter-mw08-000022',
      'meter-mw08-000027',
      'meter-mw08-000016',
      'meter-mw08-000008',
    ],
    offsetInSeconds: 140,
  },
  '00:17:08:96:1A:1B': {
    ipAddress: '10.1.99.252',
    apName: 'meter-mw08-000006',
    name: "Sanjana Silva's Dragonfly Pro",
    signal: -74,
    history: ['meter-mw08-000039', 'meter-mw08-000008'],
    offsetInSeconds: 59,
  },
  'F0:B4:79:61:5E:0D': {
    ipAddress: '10.1.168.94',
    apName: 'meter-mw08-000002',
    name: "Mateo Hwang's MacBook Pro",
    signal: -56,
    history: ['meter-mw08-000014'],
    offsetInSeconds: 55,
  },
  'B8:E6:0C:3B:66:C5': {
    ipAddress: '10.1.50.84',
    apName: '',
    name: "Inès's MBP-2",
    signal: -73,
    history: [],
    offsetInSeconds: 27,
  },
  'F0:72:EA:F9:A3:B6': {
    ipAddress: '10.1.150.147',
    apName: 'meter-mw08-000029',
    name: "Andrew's Chromebook",
    signal: -75,
    history: ['meter-mw08-000016', 'meter-mw08-000009', 'meter-mw08-000025'],
    offsetInSeconds: 98,
  },
  '94:9F:3E:99:E9:58': {
    ipAddress: '10.1.11.52',
    apName: 'meter-mw08-000006',
    name: 'Sonos',
    signal: -60,
    history: [
      'meter-mw08-000028',
      'meter-mw08-000038',
      'meter-mw08-000036',
      'meter-mw08-000004',
      'meter-mw08-000031',
      'meter-mw08-000018',
      'meter-mw08-000022',
    ],
    offsetInSeconds: 172,
  },
  '68:2F:67:80:8C:EB': {
    ipAddress: '10.1.123.26',
    apName: '',
    name: "Tatiana's MBP-2",
    signal: -49,
    history: [],
    offsetInSeconds: 0,
  },
  '18:86:EF:D3:71:EE': {
    ipAddress: '10.1.167.99',
    apName: 'meter-mw08-000024',
    name: '*',
    signal: -54,
    history: [
      'meter-mw08-000035',
      'meter-mw08-000017',
      'meter-mw08-000034',
      'meter-mw08-000030',
      'meter-mw08-000011',
    ],
    offsetInSeconds: 0,
  },
  '00:25:B3:8B:EB:55': {
    ipAddress: '10.1.58.7',
    apName: 'meter-mw08-000017',
    name: "Valeria Thompson's Dragonfly Pro",
    signal: -60,
    history: [
      'meter-mw08-000019',
      'meter-mw08-000012',
      'meter-mw08-000035',
      'meter-mw08-000017',
      'meter-mw08-000002',
      'meter-mw08-000023',
      'meter-mw08-000039',
    ],
    offsetInSeconds: 281,
  },
  '00:04:0F:AA:19:DF': {
    ipAddress: '10.1.25.96',
    apName: 'meter-mw08-000014',
    name: "Enzo Zhou's Laptop",
    signal: -71,
    history: ['meter-mw08-000035', 'meter-mw08-000032', 'meter-mw08-000037'],
    offsetInSeconds: 145,
  },
  '78:FD:94:F1:8C:DC': {
    ipAddress: '10.1.63.58',
    apName: 'meter-mw08-000025',
    name: "Camila Wright's MBP",
    signal: -45,
    history: [
      'meter-mw08-000030',
      'meter-mw08-000015',
      'meter-mw08-000040',
      'meter-mw08-000012',
      'meter-mw08-000015',
      'meter-mw08-000020',
    ],
    offsetInSeconds: 192,
  },
  '80:49:71:5D:4C:72': {
    ipAddress: '10.1.74.25',
    apName: 'meter-mw08-000025',
    name: "José Smith's iPad",
    signal: -60,
    history: [
      'meter-mw08-000028',
      'meter-mw08-000007',
      'meter-mw08-000013',
      'meter-mw08-000008',
      'meter-mw08-000025',
      'meter-mw08-000013',
      'meter-mw08-000007',
    ],
    offsetInSeconds: 227,
  },
  '00:04:0F:89:90:DE': {
    ipAddress: '10.1.112.211',
    apName: 'meter-mw08-000027',
    name: "Luis's ZenBook Flip",
    signal: -58,
    history: ['meter-mw08-000034'],
    offsetInSeconds: 55,
  },
  'BC:83:85:92:C3:82': {
    ipAddress: '10.1.157.97',
    apName: 'meter-mw08-000006',
    name: "Thomas's Surface Pro",
    signal: -74,
    history: [
      'meter-mw08-000017',
      'meter-mw08-000024',
      'meter-mw08-000029',
      'meter-mw08-000003',
      'meter-mw08-000018',
      'meter-mw08-000001',
      'meter-mw08-000005',
    ],
    offsetInSeconds: 79,
  },
  '00:04:0F:84:D0:05': {
    ipAddress: '10.1.62.251',
    apName: 'meter-mw08-000038',
    name: "James Walker's ZenBook Flip",
    signal: -52,
    history: [
      'meter-mw08-000038',
      'meter-mw08-000006',
      'meter-mw08-000001',
      'meter-mw08-000029',
      'meter-mw08-000022',
      'meter-mw08-000024',
      'meter-mw08-000038',
    ],
    offsetInSeconds: 119,
  },
  '00:04:0F:08:4B:CC': {
    ipAddress: '10.1.234.248',
    apName: 'meter-mw08-000035',
    name: "Wei Toth's Laptop",
    signal: -58,
    history: [
      'meter-mw08-000028',
      'meter-mw08-000005',
      'meter-mw08-000009',
      'meter-mw08-000039',
      'meter-mw08-000007',
      'meter-mw08-000006',
      'meter-mw08-000034',
    ],
    offsetInSeconds: 245,
  },
  '00:04:0F:20:0B:3C': {
    ipAddress: '10.1.146.110',
    apName: 'meter-mw08-000012',
    name: "Mateo's ZenBook Flip",
    signal: -47,
    history: ['meter-mw08-000011', 'meter-mw08-000033'],
    offsetInSeconds: 5,
  },
  '58:D3:49:06:87:A2': {
    ipAddress: '10.1.60.112',
    apName: 'meter-mw08-000034',
    name: "Chen's MBP-2",
    signal: -57,
    history: [
      'meter-mw08-000016',
      'meter-mw08-000032',
      'meter-mw08-000011',
      'meter-mw08-000001',
      'meter-mw08-000021',
      'meter-mw08-000030',
      'meter-mw08-000003',
      'meter-mw08-000001',
      'meter-mw08-000028',
    ],
    offsetInSeconds: 71,
  },
  'E4:54:E8:F2:8D:29': {
    ipAddress: '10.1.202.112',
    apName: 'meter-mw08-000014',
    name: "Riya Wilson's Inspiron",
    signal: -60,
    history: [
      'meter-mw08-000012',
      'meter-mw08-000001',
      'meter-mw08-000004',
      'meter-mw08-000019',
      'meter-mw08-000015',
      'meter-mw08-000012',
      'meter-mw08-000029',
      'meter-mw08-000029',
    ],
    offsetInSeconds: 192,
  },
  'A0:25:D7:51:DF:DA': {
    ipAddress: '10.1.97.168',
    apName: 'meter-mw08-000008',
    name: "Zhang's Dragonfly Pro",
    signal: -46,
    history: [
      'meter-mw08-000034',
      'meter-mw08-000038',
      'meter-mw08-000027',
      'meter-mw08-000016',
      'meter-mw08-000021',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 171,
  },
  '00:25:64:73:F0:08': {
    ipAddress: '10.1.87.56',
    apName: '',
    name: "Aryan Li's Inspiron",
    signal: -73,
    history: [],
    offsetInSeconds: 204,
  },
  'A4:8C:DB:F6:8D:22': {
    ipAddress: '10.1.75.69',
    apName: 'meter-mw08-000013',
    name: "Alejandro Martinez's ThinkPad X1",
    signal: -63,
    history: ['meter-mw08-000021', 'meter-mw08-000015'],
    offsetInSeconds: 122,
  },
  'E8:65:5F:72:A7:5A': {
    ipAddress: '10.1.173.142',
    apName: 'meter-mw08-000013',
    name: "Andrew's Inspiron",
    signal: -57,
    history: [
      'meter-mw08-000005',
      'meter-mw08-000016',
      'meter-mw08-000033',
      'meter-mw08-000007',
      'meter-mw08-000024',
      'meter-mw08-000004',
      'meter-mw08-000015',
      'meter-mw08-000023',
    ],
    offsetInSeconds: 269,
  },
  '98:7A:14:94:2D:CC': {
    ipAddress: '10.1.131.20',
    apName: 'meter-mw08-000015',
    name: "Wei Smith's Surface Pro",
    signal: -60,
    history: [
      'meter-mw08-000026',
      'meter-mw08-000027',
      'meter-mw08-000017',
      'meter-mw08-000011',
      'meter-mw08-000034',
      'meter-mw08-000032',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 65,
  },
  '8D:99:31:67:03:CA': {
    ipAddress: '10.1.151.132',
    apName: 'meter-mw08-000019',
    name: '*',
    signal: -58,
    history: [
      'meter-mw08-000014',
      'meter-mw08-000002',
      'meter-mw08-000005',
      'meter-mw08-000018',
      'meter-mw08-000028',
      'meter-mw08-000023',
      'meter-mw08-000005',
      'meter-mw08-000004',
      'meter-mw08-000028',
    ],
    offsetInSeconds: 246,
  },
  '5C:51:88:99:7C:FE': {
    ipAddress: '10.1.227.79',
    apName: '',
    name: "Anton's ThinkPad X1",
    signal: -64,
    history: [],
    offsetInSeconds: 139,
  },
  '00:04:0F:55:32:32': {
    ipAddress: '10.1.142.219',
    apName: 'meter-mw08-000016',
    name: "Xiao Huang's Laptop",
    signal: -71,
    history: ['meter-mw08-000038', 'meter-mw08-000010', 'meter-mw08-000028'],
    offsetInSeconds: 125,
  },
  '14:22:3B:06:B9:BE': {
    ipAddress: '10.1.184.234',
    apName: 'meter-mw08-000038',
    name: "Matthew's Chromebook",
    signal: -69,
    history: [
      'meter-mw08-000025',
      'meter-mw08-000027',
      'meter-mw08-000030',
      'meter-mw08-000022',
      'meter-mw08-000021',
      'meter-mw08-000021',
      'meter-mw08-000009',
      'meter-mw08-000009',
    ],
    offsetInSeconds: 1,
  },
  'F5:DA:36:1C:D7:8D': {
    ipAddress: '10.1.208.55',
    apName: 'meter-mw08-000017',
    name: '*',
    signal: -74,
    history: ['meter-mw08-000015', 'meter-mw08-000040', 'meter-mw08-000017'],
    offsetInSeconds: 227,
  },
  'B6:BE:16:03:2F:E0': {
    ipAddress: '10.1.141.180',
    apName: 'meter-mw08-000006',
    name: '*',
    signal: -69,
    history: ['meter-mw08-000027', 'meter-mw08-000038', 'meter-mw08-000030'],
    offsetInSeconds: 89,
  },
  '5C:8D:4E:CC:B8:81': {
    ipAddress: '10.1.102.110',
    apName: 'meter-mw08-000039',
    name: "Mary's MacBook",
    signal: -52,
    history: [
      'meter-mw08-000020',
      'meter-mw08-000004',
      'meter-mw08-000023',
      'meter-mw08-000014',
      'meter-mw08-000021',
      'meter-mw08-000016',
      'meter-mw08-000028',
      'meter-mw08-000040',
      'meter-mw08-000017',
      'meter-mw08-000005',
    ],
    offsetInSeconds: 68,
  },
  '7C:AB:60:FB:B3:95': {
    ipAddress: '10.1.221.158',
    apName: '',
    name: "Romain's MBP-2",
    signal: -54,
    history: [],
    offsetInSeconds: 258,
  },
  'C5:3A:40:8D:FB:F9': {
    ipAddress: '10.1.34.74',
    apName: 'meter-mw08-000010',
    name: '*',
    signal: -69,
    history: ['meter-mw08-000006', 'meter-mw08-000023', 'meter-mw08-000018', 'meter-mw08-000007'],
    offsetInSeconds: 215,
  },
  '30:EE:0D:9C:B0:C7': {
    ipAddress: '10.1.245.239',
    apName: '',
    name: '*',
    signal: -58,
    history: [],
    offsetInSeconds: 153,
  },
  '84:7B:EB:87:EC:45': {
    ipAddress: '10.1.110.156',
    apName: 'meter-mw08-000013',
    name: "Ava Rodrigues's Inspiron",
    signal: -53,
    history: [
      'meter-mw08-000034',
      'meter-mw08-000009',
      'meter-mw08-000023',
      'meter-mw08-000030',
      'meter-mw08-000035',
      'meter-mw08-000028',
      'meter-mw08-000003',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 138,
  },
  'F0:F6:C1:80:84:B9': {
    ipAddress: '10.1.226.150',
    apName: 'meter-mw08-000037',
    name: 'Sonos',
    signal: -47,
    history: [
      'meter-mw08-000038',
      'meter-mw08-000034',
      'meter-mw08-000011',
      'meter-mw08-000023',
      'meter-mw08-000005',
      'meter-mw08-000023',
      'meter-mw08-000010',
    ],
    offsetInSeconds: 265,
  },
  '00:04:0F:79:50:8A': {
    ipAddress: '10.1.65.239',
    apName: 'meter-mw08-000027',
    name: "Aishwarya's Laptop",
    signal: -53,
    history: [
      'meter-mw08-000019',
      'meter-mw08-000026',
      'meter-mw08-000025',
      'meter-mw08-000012',
      'meter-mw08-000037',
      'meter-mw08-000007',
      'meter-mw08-000035',
      'meter-mw08-000014',
      'meter-mw08-000012',
      'meter-mw08-000007',
    ],
    offsetInSeconds: 29,
  },
  'D1:C0:EF:C7:EF:A7': {
    ipAddress: '10.1.143.239',
    apName: 'meter-mw08-000011',
    name: '*',
    signal: -66,
    history: ['meter-mw08-000017', 'meter-mw08-000036', 'meter-mw08-000018', 'meter-mw08-000033'],
    offsetInSeconds: 126,
  },
  'A0:32:99:61:EA:01': {
    ipAddress: '10.1.66.178',
    apName: 'meter-mw08-000003',
    name: "Amit's ThinkPad X1",
    signal: -58,
    history: [
      'meter-mw08-000002',
      'meter-mw08-000037',
      'meter-mw08-000032',
      'meter-mw08-000003',
      'meter-mw08-000034',
      'meter-mw08-000002',
      'meter-mw08-000032',
      'meter-mw08-000002',
      'meter-mw08-000030',
    ],
    offsetInSeconds: 65,
  },
  'C0:6B:55:2F:C2:A3': {
    ipAddress: '10.1.106.156',
    apName: 'meter-mw08-000032',
    name: "Léa's ThinkPad X1",
    signal: -53,
    history: ['meter-mw08-000007', 'meter-mw08-000020'],
    offsetInSeconds: 265,
  },
  '00:04:0F:86:1F:28': {
    ipAddress: '10.1.32.197',
    apName: '',
    name: "Amit Wright's ZenBook Flip",
    signal: -72,
    history: [],
    offsetInSeconds: 195,
  },
  '18:9E:FC:EA:94:05': {
    ipAddress: '10.1.241.183',
    apName: 'meter-mw08-000013',
    name: "Yan Hwang's iPhone",
    signal: -65,
    history: [
      'meter-mw08-000018',
      'meter-mw08-000011',
      'meter-mw08-000014',
      'meter-mw08-000004',
      'meter-mw08-000008',
      'meter-mw08-000002',
      'meter-mw08-000012',
      'meter-mw08-000001',
    ],
    offsetInSeconds: 213,
  },
  '18:FB:7B:E3:33:0C': {
    ipAddress: '10.1.220.98',
    apName: 'meter-mw08-000017',
    name: "Anika's Inspiron",
    signal: -48,
    history: ['meter-mw08-000023', 'meter-mw08-000035'],
    offsetInSeconds: 154,
  },
  '00:04:0F:F7:CE:37': {
    ipAddress: '10.1.126.131',
    apName: '',
    name: "Thomas Szabo's Laptop",
    signal: -52,
    history: [],
    offsetInSeconds: 178,
  },
  '64:6D:2F:D1:B0:35': {
    ipAddress: '10.1.0.188',
    apName: 'meter-mw08-000035',
    name: "Akash Martin's MBP-2",
    signal: -51,
    history: ['meter-mw08-000009', 'meter-mw08-000009', 'meter-mw08-000030', 'meter-mw08-000038'],
    offsetInSeconds: 45,
  },
  'E4:8B:7F:67:4B:57': {
    ipAddress: '10.1.229.147',
    apName: '',
    name: "Aryan's iPad",
    signal: -48,
    history: [],
    offsetInSeconds: 10,
  },
  '00:17:FA:E3:E2:BE': {
    ipAddress: '10.1.105.224',
    apName: 'meter-mw08-000030',
    name: "Isabella's Surface Pro",
    signal: -71,
    history: ['meter-mw08-000016', 'meter-mw08-000030', 'meter-mw08-000039', 'meter-mw08-000005'],
    offsetInSeconds: 269,
  },
  'AC:88:FD:CB:AD:1D': {
    ipAddress: '10.1.55.62',
    apName: 'meter-mw08-000029',
    name: "Mariana Hall's iPad",
    signal: -57,
    history: ['meter-mw08-000019'],
    offsetInSeconds: 76,
  },
  '2C:3E:0B:3F:2D:46': {
    ipAddress: '10.1.199.116',
    apName: 'meter-mw08-000020',
    name: '*',
    signal: -59,
    history: ['meter-mw08-000008', 'meter-mw08-000007', 'meter-mw08-000022'],
    offsetInSeconds: 211,
  },
  'CC:08:8D:FC:C8:C7': {
    ipAddress: '10.1.40.62',
    apName: 'meter-mw08-000011',
    name: "Svetlana Li's iPad",
    signal: -59,
    history: ['meter-mw08-000028'],
    offsetInSeconds: 82,
  },
  '60:1D:91:45:2D:C1': {
    ipAddress: '10.1.34.30',
    apName: '',
    name: "Akash's ThinkPad X1",
    signal: -50,
    history: [],
    offsetInSeconds: 297,
  },
  '1C:6A:76:10:A2:70': {
    ipAddress: '10.1.224.167',
    apName: '',
    name: "Aryan's iPhone",
    signal: -57,
    history: [],
    offsetInSeconds: 39,
  },
  '28:18:78:4E:A0:BE': {
    ipAddress: '10.1.138.184',
    apName: 'meter-mw08-000021',
    name: "Natalia's Surface Pro",
    signal: -65,
    history: ['meter-mw08-000007', 'meter-mw08-000010'],
    offsetInSeconds: 234,
  },
  '7E:89:E6:F6:B6:39': {
    ipAddress: '10.1.201.225',
    apName: 'meter-mw08-000006',
    name: '*',
    signal: -58,
    history: ['meter-mw08-000031', 'meter-mw08-000022'],
    offsetInSeconds: 8,
  },
  '71:71:0C:BD:25:81': {
    ipAddress: '10.1.217.238',
    apName: '',
    name: '*',
    signal: -70,
    history: [],
    offsetInSeconds: 232,
  },
  '88:FE:5F:06:C7:17': {
    ipAddress: '10.1.124.15',
    apName: 'meter-mw08-000029',
    name: '*',
    signal: -69,
    history: ['meter-mw08-000038', 'meter-mw08-000039', 'meter-mw08-000020'],
    offsetInSeconds: 94,
  },
  '28:77:F1:82:8D:77': {
    ipAddress: '10.1.149.189',
    apName: 'meter-mw08-000026',
    name: "Qiang's MBP",
    signal: -51,
    history: ['meter-mw08-000011'],
    offsetInSeconds: 292,
  },
  '6C:94:F8:29:90:91': {
    ipAddress: '10.1.211.137',
    apName: 'meter-mw08-000026',
    name: "Chen Chen's MacBook Pro",
    signal: -74,
    history: ['meter-mw08-000020', 'meter-mw08-000007'],
    offsetInSeconds: 279,
  },
  '00:04:0F:E0:89:74': {
    ipAddress: '10.1.250.248',
    apName: 'meter-mw08-000006',
    name: "Riya's Laptop",
    signal: -51,
    history: [
      'meter-mw08-000030',
      'meter-mw08-000007',
      'meter-mw08-000027',
      'meter-mw08-000021',
      'meter-mw08-000024',
      'meter-mw08-000026',
    ],
    offsetInSeconds: 277,
  },
  '00:04:0F:EE:26:82': {
    ipAddress: '10.1.179.56',
    apName: 'meter-mw08-000013',
    name: "Yu's ZenBook Flip",
    signal: -55,
    history: [
      'meter-mw08-000007',
      'meter-mw08-000002',
      'meter-mw08-000020',
      'meter-mw08-000028',
      'meter-mw08-000017',
      'meter-mw08-000034',
      'meter-mw08-000033',
    ],
    offsetInSeconds: 256,
  },
  '00:04:0F:22:F0:AD': {
    ipAddress: '10.1.223.104',
    apName: 'meter-mw08-000012',
    name: "Emily's ZenBook Flip",
    signal: -50,
    history: [
      'meter-mw08-000032',
      'meter-mw08-000015',
      'meter-mw08-000020',
      'meter-mw08-000014',
      'meter-mw08-000015',
      'meter-mw08-000001',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 154,
  },
  'EC:B1:D7:65:C5:89': {
    ipAddress: '10.1.237.100',
    apName: '',
    name: "Manon Lee's Dragonfly Pro",
    signal: -49,
    history: [],
    offsetInSeconds: 54,
  },
  'DC:A9:04:18:78:9C': {
    ipAddress: '10.1.8.16',
    apName: '',
    name: "Emma's MacBook",
    signal: -53,
    history: [],
    offsetInSeconds: 126,
  },
  '70:CD:60:CE:B4:AA': {
    ipAddress: '10.1.195.91',
    apName: '',
    name: "Michael's MBP-2",
    signal: -47,
    history: [],
    offsetInSeconds: 115,
  },
  '2C:7C:F2:11:AD:56': {
    ipAddress: '10.1.40.222',
    apName: 'meter-mw08-000025',
    name: "Valentina Wright's iPad",
    signal: -75,
    history: [
      'meter-mw08-000003',
      'meter-mw08-000027',
      'meter-mw08-000033',
      'meter-mw08-000021',
      'meter-mw08-000007',
      'meter-mw08-000025',
      'meter-mw08-000035',
      'meter-mw08-000025',
    ],
    offsetInSeconds: 50,
  },
  'BB:6C:72:97:1D:9C': {
    ipAddress: '10.1.218.249',
    apName: 'meter-mw08-000030',
    name: '*',
    signal: -56,
    history: [
      'meter-mw08-000023',
      'meter-mw08-000018',
      'meter-mw08-000038',
      'meter-mw08-000020',
      'meter-mw08-000003',
      'meter-mw08-000035',
      'meter-mw08-000001',
      'meter-mw08-000002',
      'meter-mw08-000022',
      'meter-mw08-000029',
    ],
    offsetInSeconds: 274,
  },
  'BC:30:5B:60:BD:5D': {
    ipAddress: '10.1.253.59',
    apName: '',
    name: "Tao Takahashi's Inspiron",
    signal: -61,
    history: [],
    offsetInSeconds: 75,
  },
  '78:AC:C0:BE:F5:1F': {
    ipAddress: '10.1.115.107',
    apName: 'meter-mw08-000005',
    name: "Aishwarya Fernandes's Dragonfly Pro",
    signal: -49,
    history: ['meter-mw08-000022', 'meter-mw08-000008', 'meter-mw08-000029', 'meter-mw08-000024'],
    offsetInSeconds: 4,
  },
  '0B:87:C2:25:58:3B': {
    ipAddress: '10.1.66.10',
    apName: 'meter-mw08-000013',
    name: '*',
    signal: -50,
    history: [
      'meter-mw08-000017',
      'meter-mw08-000038',
      'meter-mw08-000026',
      'meter-mw08-000021',
      'meter-mw08-000015',
      'meter-mw08-000025',
      'meter-mw08-000012',
      'meter-mw08-000019',
    ],
    offsetInSeconds: 237,
  },
  '88:B2:91:A3:AB:D5': {
    ipAddress: '10.1.163.115',
    apName: 'meter-mw08-000028',
    name: "Camila's iPhone",
    signal: -46,
    history: [
      'meter-mw08-000019',
      'meter-mw08-000011',
      'meter-mw08-000030',
      'meter-mw08-000040',
      'meter-mw08-000024',
      'meter-mw08-000031',
      'meter-mw08-000025',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 175,
  },
  '8E:B8:BC:D2:7D:92': {
    ipAddress: '10.1.223.198',
    apName: 'meter-mw08-000002',
    name: '*',
    signal: -64,
    history: ['meter-mw08-000028', 'meter-mw08-000038', 'meter-mw08-000001'],
    offsetInSeconds: 29,
  },
  '17:47:73:B7:51:78': {
    ipAddress: '10.1.89.214',
    apName: 'meter-mw08-000026',
    name: '*',
    signal: -52,
    history: [
      'meter-mw08-000005',
      'meter-mw08-000039',
      'meter-mw08-000024',
      'meter-mw08-000012',
      'meter-mw08-000014',
      'meter-mw08-000024',
      'meter-mw08-000013',
    ],
    offsetInSeconds: 297,
  },
  '7E:40:8B:E7:0F:D0': {
    ipAddress: '10.1.44.212',
    apName: 'meter-mw08-000004',
    name: '*',
    signal: -55,
    history: [
      'meter-mw08-000015',
      'meter-mw08-000022',
      'meter-mw08-000018',
      'meter-mw08-000007',
      'meter-mw08-000039',
      'meter-mw08-000029',
      'meter-mw08-000021',
      'meter-mw08-000039',
      'meter-mw08-000013',
    ],
    offsetInSeconds: 108,
  },
  'E2:47:1C:AA:45:78': {
    ipAddress: '10.1.215.129',
    apName: 'meter-mw08-000018',
    name: '*',
    signal: -60,
    history: [
      'meter-mw08-000005',
      'meter-mw08-000031',
      'meter-mw08-000028',
      'meter-mw08-000005',
      'meter-mw08-000011',
      'meter-mw08-000038',
      'meter-mw08-000001',
    ],
    offsetInSeconds: 22,
  },
  'BC:67:78:C1:EB:7A': {
    ipAddress: '10.1.23.61',
    apName: '',
    name: "Arjun Harris's iPhone",
    signal: -49,
    history: [],
    offsetInSeconds: 254,
  },
  '2F:8F:1A:4E:4B:25': {
    ipAddress: '10.1.4.21',
    apName: 'meter-mw08-000030',
    name: '*',
    signal: -59,
    history: [
      'meter-mw08-000040',
      'meter-mw08-000003',
      'meter-mw08-000013',
      'meter-mw08-000009',
      'meter-mw08-000012',
      'meter-mw08-000031',
      'meter-mw08-000040',
      'meter-mw08-000022',
    ],
    offsetInSeconds: 249,
  },
  '3A:43:4D:6B:60:22': {
    ipAddress: '10.1.104.55',
    apName: 'meter-mw08-000016',
    name: '*',
    signal: -72,
    history: ['meter-mw08-000026', 'meter-mw08-000033'],
    offsetInSeconds: 59,
  },
  '00:04:0F:09:24:E5': {
    ipAddress: '10.1.153.244',
    apName: '',
    name: "Arjun Varga's ZenBook Flip",
    signal: -48,
    history: [],
    offsetInSeconds: 49,
  },
  '00:04:0F:11:84:DC': {
    ipAddress: '10.1.180.111',
    apName: 'meter-mw08-000014',
    name: "Sofía Fernandes's Laptop",
    signal: -72,
    history: ['meter-mw08-000035', 'meter-mw08-000005'],
    offsetInSeconds: 194,
  },
  '74:1B:B2:FC:E0:1B': {
    ipAddress: '10.1.43.183',
    apName: 'meter-mw08-000002',
    name: "Lin Wu's iPad",
    signal: -57,
    history: ['meter-mw08-000014', 'meter-mw08-000019'],
    offsetInSeconds: 79,
  },
  'EC:DB:00:DF:7A:89': {
    ipAddress: '10.1.248.15',
    apName: 'meter-mw08-000023',
    name: '*',
    signal: -63,
    history: [
      'meter-mw08-000027',
      'meter-mw08-000012',
      'meter-mw08-000017',
      'meter-mw08-000021',
      'meter-mw08-000020',
      'meter-mw08-000009',
      'meter-mw08-000004',
      'meter-mw08-000019',
    ],
    offsetInSeconds: 286,
  },
  'F8:CF:C5:11:58:F7': {
    ipAddress: '10.1.181.102',
    apName: '',
    name: "Thomas Thomas's ThinkPad X1",
    signal: -47,
    history: [],
    offsetInSeconds: 91,
  },
  '88:E9:A4:56:FC:1C': {
    ipAddress: '10.1.5.167',
    apName: 'meter-mw08-000016',
    name: "Andrew's Dragonfly Pro",
    signal: -65,
    history: [
      'meter-mw08-000011',
      'meter-mw08-000014',
      'meter-mw08-000038',
      'meter-mw08-000019',
      'meter-mw08-000017',
    ],
    offsetInSeconds: 23,
  },
  '58:24:29:3F:E3:FB': {
    ipAddress: '10.1.80.229',
    apName: 'meter-mw08-000026',
    name: "José's Chromebook",
    signal: -60,
    history: [
      'meter-mw08-000003',
      'meter-mw08-000009',
      'meter-mw08-000024',
      'meter-mw08-000002',
      'meter-mw08-000029',
      'meter-mw08-000019',
      'meter-mw08-000032',
      'meter-mw08-000006',
      'meter-mw08-000019',
      'meter-mw08-000034',
    ],
    offsetInSeconds: 74,
  },
  'A0:32:99:D5:C8:D6': {
    ipAddress: '10.1.81.32',
    apName: 'meter-mw08-000009',
    name: "Maria Chen's ThinkPad X1",
    signal: -61,
    history: [
      'meter-mw08-000007',
      'meter-mw08-000002',
      'meter-mw08-000001',
      'meter-mw08-000013',
      'meter-mw08-000036',
      'meter-mw08-000015',
      'meter-mw08-000017',
      'meter-mw08-000004',
    ],
    offsetInSeconds: 188,
  },
  '00:04:0F:23:DC:8B': {
    ipAddress: '10.1.240.169',
    apName: 'meter-mw08-000003',
    name: "Thomas's ZenBook Flip",
    signal: -52,
    history: ['meter-mw08-000040', 'meter-mw08-000017'],
    offsetInSeconds: 94,
  },
  '97:8E:36:EC:BE:8E': {
    ipAddress: '10.1.191.11',
    apName: 'meter-mw08-000023',
    name: '*',
    signal: -49,
    history: [
      'meter-mw08-000022',
      'meter-mw08-000029',
      'meter-mw08-000028',
      'meter-mw08-000029',
      'meter-mw08-000023',
      'meter-mw08-000014',
      'meter-mw08-000031',
    ],
    offsetInSeconds: 282,
  },
  '84:7B:EB:05:99:21': {
    ipAddress: '10.1.192.249',
    apName: 'meter-mw08-000035',
    name: "Mia Rodrigues's Inspiron",
    signal: -71,
    history: [
      'meter-mw08-000033',
      'meter-mw08-000021',
      'meter-mw08-000002',
      'meter-mw08-000015',
      'meter-mw08-000028',
      'meter-mw08-000021',
      'meter-mw08-000032',
      'meter-mw08-000005',
      'meter-mw08-000013',
      'meter-mw08-000002',
    ],
    offsetInSeconds: 173,
  },
  '54:4C:8A:D7:AA:6F': {
    ipAddress: '10.1.234.113',
    apName: '',
    name: "Javier's Surface Pro",
    signal: -60,
    history: [],
    offsetInSeconds: 29,
  },
  '3C:CD:36:BA:9C:08': {
    ipAddress: '10.1.26.200',
    apName: 'meter-mw08-000026',
    name: "Olga's iPad",
    signal: -46,
    history: [
      'meter-mw08-000006',
      'meter-mw08-000030',
      'meter-mw08-000011',
      'meter-mw08-000031',
      'meter-mw08-000033',
    ],
    offsetInSeconds: 290,
  },
  '48:A6:B8:75:EC:F1': {
    ipAddress: '10.1.77.43',
    apName: '',
    name: 'Sonos',
    signal: -75,
    history: [],
    offsetInSeconds: 106,
  },
  '00:50:E4:6A:B9:75': {
    ipAddress: '10.1.159.91',
    apName: 'meter-mw08-000017',
    name: "Mariana's MacBook",
    signal: -74,
    history: [
      'meter-mw08-000033',
      'meter-mw08-000024',
      'meter-mw08-000020',
      'meter-mw08-000003',
      'meter-mw08-000025',
      'meter-mw08-000003',
      'meter-mw08-000023',
      'meter-mw08-000018',
      'meter-mw08-000015',
    ],
    offsetInSeconds: 126,
  },
  'BC:EC:5D:01:E8:16': {
    ipAddress: '10.1.203.64',
    apName: 'meter-mw08-000013',
    name: "Lucía Schmidt's MacBook",
    signal: -60,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000001',
      'meter-mw08-000023',
      'meter-mw08-000025',
      'meter-mw08-000009',
      'meter-mw08-000031',
    ],
    offsetInSeconds: 264,
  },
  '9B:C6:A9:BE:9F:3B': {
    ipAddress: '10.1.206.27',
    apName: 'meter-mw08-000039',
    name: '*',
    signal: -49,
    history: ['meter-mw08-000013', 'meter-mw08-000010', 'meter-mw08-000006', 'meter-mw08-000010'],
    offsetInSeconds: 204,
  },
  'D4:F4:6F:FC:6F:5C': {
    ipAddress: '10.1.198.184',
    apName: 'meter-mw08-000036',
    name: "Sneha Rodrigues's iPhone",
    signal: -47,
    history: ['meter-mw08-000029', 'meter-mw08-000003'],
    offsetInSeconds: 109,
  },
  '24:C4:2F:F4:4B:19': {
    ipAddress: '10.1.194.210',
    apName: '',
    name: 'Philips Hue',
    signal: -54,
    history: [],
    offsetInSeconds: 153,
  },
  '00:04:0F:7D:51:43': {
    ipAddress: '10.1.22.59',
    apName: '',
    name: "Emily Varga's ZenBook Flip",
    signal: -60,
    history: [],
    offsetInSeconds: 225,
  },
  '83:3F:9E:10:70:25': {
    ipAddress: '10.1.167.244',
    apName: '',
    name: '*',
    signal: -53,
    history: [],
    offsetInSeconds: 126,
  },
  '50:3C:C4:1F:3D:92': {
    ipAddress: '10.1.124.235',
    apName: '',
    name: "Michael Li's ThinkPad X1",
    signal: -63,
    history: [],
    offsetInSeconds: 201,
  },
  '00:04:0F:DC:89:57': {
    ipAddress: '10.1.116.130',
    apName: '',
    name: "Maxim Johnson's Laptop",
    signal: -73,
    history: [],
    offsetInSeconds: 193,
  },
  '00:04:0F:AF:8B:3A': {
    ipAddress: '10.1.189.81',
    apName: '',
    name: "Aditya's Laptop",
    signal: -47,
    history: [],
    offsetInSeconds: 81,
  },
  '00:04:0F:F0:E4:5B': {
    ipAddress: '10.1.96.91',
    apName: 'meter-mw08-000027',
    name: "Olga's ZenBook Flip",
    signal: -75,
    history: ['meter-mw08-000018', 'meter-mw08-000024', 'meter-mw08-000021', 'meter-mw08-000033'],
    offsetInSeconds: 121,
  },
  'A4:B1:97:27:EE:20': {
    ipAddress: '10.1.104.154',
    apName: '',
    name: "Inès's MBP-2",
    signal: -51,
    history: [],
    offsetInSeconds: 54,
  },
  '5C:E9:1E:D7:C4:29': {
    ipAddress: '10.1.134.225',
    apName: 'meter-mw08-000010',
    name: "Louis Lewis's iPhone",
    signal: -57,
    history: [
      'meter-mw08-000027',
      'meter-mw08-000024',
      'meter-mw08-000020',
      'meter-mw08-000025',
      'meter-mw08-000038',
      'meter-mw08-000011',
      'meter-mw08-000034',
    ],
    offsetInSeconds: 42,
  },
  '60:3E:5F:DB:DD:87': {
    ipAddress: '10.1.21.230',
    apName: '',
    name: "Yong Hill's MBP-2",
    signal: -71,
    history: [],
    offsetInSeconds: 51,
  },
  '6C:97:6D:D5:18:97': {
    ipAddress: '10.1.110.201',
    apName: '',
    name: "Qiang's ThinkPad X1",
    signal: -55,
    history: [],
    offsetInSeconds: 158,
  },
  '94:57:A5:F1:65:DA': {
    ipAddress: '10.1.212.44',
    apName: 'meter-mw08-000007',
    name: "Anika's Dragonfly Pro",
    signal: -65,
    history: ['meter-mw08-000030'],
    offsetInSeconds: 184,
  },
  '00:04:0F:9F:2E:3B': {
    ipAddress: '10.1.254.148',
    apName: 'meter-mw08-000017',
    name: "Michael Watanabe's Laptop",
    signal: -75,
    history: ['meter-mw08-000018', 'meter-mw08-000021', 'meter-mw08-000002'],
    offsetInSeconds: 74,
  },
  '38:89:2C:E1:90:8D': {
    ipAddress: '10.1.172.196',
    apName: 'meter-mw08-000024',
    name: "Lola Chen's iPad",
    signal: -61,
    history: ['meter-mw08-000002', 'meter-mw08-000002'],
    offsetInSeconds: 151,
  },
  '00:1E:52:16:5A:E2': {
    ipAddress: '10.1.249.95',
    apName: '',
    name: "Kavya Young's MacBook",
    signal: -68,
    history: [],
    offsetInSeconds: 11,
  },
  '7F:0A:6D:63:32:76': {
    ipAddress: '10.1.231.181',
    apName: 'meter-mw08-000027',
    name: '*',
    signal: -46,
    history: ['meter-mw08-000034'],
    offsetInSeconds: 254,
  },
  '8C:00:6D:D4:39:BC': {
    ipAddress: '10.1.163.113',
    apName: 'meter-mw08-000031',
    name: "Svetlana's iPad",
    signal: -62,
    history: [
      'meter-mw08-000003',
      'meter-mw08-000005',
      'meter-mw08-000003',
      'meter-mw08-000017',
      'meter-mw08-000007',
      'meter-mw08-000017',
      'meter-mw08-000027',
    ],
    offsetInSeconds: 188,
  },
  '88:C6:63:8D:9F:55': {
    ipAddress: '10.1.166.123',
    apName: '',
    name: "Lin Robinson's MacBook Pro",
    signal: -56,
    history: [],
    offsetInSeconds: 36,
  },
  'F0:24:75:16:28:85': {
    ipAddress: '10.1.14.174',
    apName: '',
    name: "Nikolai's MacBook Pro",
    signal: -50,
    history: [],
    offsetInSeconds: 151,
  },
  'CA:EE:7C:08:05:E1': {
    ipAddress: '10.1.32.93',
    apName: '',
    name: '*',
    signal: -75,
    history: [],
    offsetInSeconds: 92,
  },
  'FA:6D:FE:AD:B6:4B': {
    ipAddress: '10.1.49.21',
    apName: 'meter-mw08-000031',
    name: '*',
    signal: -51,
    history: [
      'meter-mw08-000029',
      'meter-mw08-000012',
      'meter-mw08-000029',
      'meter-mw08-000005',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 277,
  },
  'B0:83:FE:12:33:71': {
    ipAddress: '10.1.59.138',
    apName: 'meter-mw08-000005',
    name: "Théo Adams's Inspiron",
    signal: -55,
    history: ['meter-mw08-000031', 'meter-mw08-000015', 'meter-mw08-000025'],
    offsetInSeconds: 13,
  },
  '70:B3:06:F4:1D:75': {
    ipAddress: '10.1.141.212',
    apName: 'meter-mw08-000040',
    name: "Valeria's MacBook",
    signal: -50,
    history: [
      'meter-mw08-000014',
      'meter-mw08-000017',
      'meter-mw08-000018',
      'meter-mw08-000028',
      'meter-mw08-000028',
      'meter-mw08-000037',
      'meter-mw08-000037',
      'meter-mw08-000010',
      'meter-mw08-000019',
      'meter-mw08-000033',
    ],
    offsetInSeconds: 283,
  },
  'DC:52:85:7C:23:9C': {
    ipAddress: '10.1.126.78',
    apName: '',
    name: "Juan Fernandez's MBP",
    signal: -68,
    history: [],
    offsetInSeconds: 57,
  },
  '8C:7A:AA:91:6C:27': {
    ipAddress: '10.1.223.47',
    apName: 'meter-mw08-000023',
    name: "Maria's MBP",
    signal: -73,
    history: [
      'meter-mw08-000038',
      'meter-mw08-000037',
      'meter-mw08-000035',
      'meter-mw08-000012',
      'meter-mw08-000028',
      'meter-mw08-000016',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 101,
  },
  '00:04:0F:0C:40:04': {
    ipAddress: '10.1.157.133',
    apName: '',
    name: "Chen's ZenBook Flip",
    signal: -54,
    history: [],
    offsetInSeconds: 129,
  },
  'AC:86:A3:C2:57:22': {
    ipAddress: '10.1.94.41',
    apName: 'meter-mw08-000023',
    name: "James Ito's MacBook",
    signal: -74,
    history: ['meter-mw08-000001'],
    offsetInSeconds: 109,
  },
  'A0:56:F3:07:C5:4A': {
    ipAddress: '10.1.188.122',
    apName: '',
    name: "Xiao's MBP",
    signal: -75,
    history: [],
    offsetInSeconds: 214,
  },
  '94:EB:2C:B1:E5:60': {
    ipAddress: '10.1.248.156',
    apName: 'meter-mw08-000008',
    name: "Luis's Chromebook",
    signal: -53,
    history: ['meter-mw08-000011'],
    offsetInSeconds: 204,
  },
  '00:04:0F:AB:70:E8': {
    ipAddress: '10.1.91.166',
    apName: '',
    name: "Léa Adams's ZenBook Flip",
    signal: -57,
    history: [],
    offsetInSeconds: 149,
  },
  '00:22:48:57:23:E5': {
    ipAddress: '10.1.70.243',
    apName: 'meter-mw08-000033',
    name: "Sophia's Surface Pro",
    signal: -72,
    history: [
      'meter-mw08-000012',
      'meter-mw08-000006',
      'meter-mw08-000009',
      'meter-mw08-000024',
      'meter-mw08-000038',
      'meter-mw08-000012',
      'meter-mw08-000032',
      'meter-mw08-000009',
      'meter-mw08-000025',
    ],
    offsetInSeconds: 183,
  },
  'F0:72:EA:DE:43:0E': {
    ipAddress: '10.1.31.68',
    apName: 'meter-mw08-000008',
    name: "Carlos Gonzalez's Chromebook",
    signal: -61,
    history: [
      'meter-mw08-000009',
      'meter-mw08-000004',
      'meter-mw08-000016',
      'meter-mw08-000032',
      'meter-mw08-000015',
      'meter-mw08-000038',
    ],
    offsetInSeconds: 211,
  },
  '08:45:B0:E8:C5:5B': {
    ipAddress: '10.1.31.152',
    apName: '',
    name: '*',
    signal: -75,
    history: [],
    offsetInSeconds: 163,
  },
  '78:67:D7:28:04:E6': {
    ipAddress: '10.1.221.183',
    apName: 'meter-mw08-000015',
    name: "Aryan's iPhone",
    signal: -70,
    history: [
      'meter-mw08-000004',
      'meter-mw08-000040',
      'meter-mw08-000021',
      'meter-mw08-000011',
      'meter-mw08-000010',
      'meter-mw08-000022',
    ],
    offsetInSeconds: 207,
  },
  '7B:A4:67:3D:B6:36': {
    ipAddress: '10.1.67.217',
    apName: 'meter-mw08-000007',
    name: '*',
    signal: -68,
    history: [
      'meter-mw08-000025',
      'meter-mw08-000004',
      'meter-mw08-000032',
      'meter-mw08-000012',
      'meter-mw08-000010',
      'meter-mw08-000031',
    ],
    offsetInSeconds: 217,
  },
  'EA:F4:B4:20:23:C1': {
    ipAddress: '10.1.163.145',
    apName: '',
    name: '*',
    signal: -66,
    history: [],
    offsetInSeconds: 19,
  },
  '98:41:27:A0:0D:FD': {
    ipAddress: '10.1.180.35',
    apName: 'meter-mw08-000036',
    name: '*',
    signal: -64,
    history: [
      'meter-mw08-000011',
      'meter-mw08-000039',
      'meter-mw08-000032',
      'meter-mw08-000023',
      'meter-mw08-000011',
      'meter-mw08-000008',
    ],
    offsetInSeconds: 86,
  },
  '42:0E:E0:86:ED:DA': {
    ipAddress: '10.1.121.221',
    apName: 'meter-mw08-000004',
    name: '*',
    signal: -73,
    history: [
      'meter-mw08-000020',
      'meter-mw08-000032',
      'meter-mw08-000009',
      'meter-mw08-000035',
      'meter-mw08-000006',
      'meter-mw08-000008',
      'meter-mw08-000034',
      'meter-mw08-000023',
      'meter-mw08-000015',
      'meter-mw08-000033',
    ],
    offsetInSeconds: 242,
  },
  '83:0E:54:C0:64:64': {
    ipAddress: '10.1.249.127',
    apName: '',
    name: '*',
    signal: -61,
    history: [],
    offsetInSeconds: 60,
  },
  '14:D0:0D:73:AE:1A': {
    ipAddress: '10.1.243.240',
    apName: 'meter-mw08-000014',
    name: "Gabriel White's iPhone",
    signal: -64,
    history: ['meter-mw08-000013', 'meter-mw08-000033'],
    offsetInSeconds: 80,
  },
  '84:AB:1A:A0:77:E2': {
    ipAddress: '10.1.167.174',
    apName: 'meter-mw08-000007',
    name: "Yulia's MBP-2",
    signal: -54,
    history: [
      'meter-mw08-000027',
      'meter-mw08-000032',
      'meter-mw08-000039',
      'meter-mw08-000019',
      'meter-mw08-000018',
      'meter-mw08-000031',
      'meter-mw08-000014',
    ],
    offsetInSeconds: 65,
  },
  'CC:A7:C1:1B:56:44': {
    ipAddress: '10.1.1.127',
    apName: 'meter-mw08-000007',
    name: "Léa's Chromebook",
    signal: -74,
    history: ['meter-mw08-000004', 'meter-mw08-000006'],
    offsetInSeconds: 139,
  },
  '68:FE:F7:1A:D6:C6': {
    ipAddress: '10.1.181.249',
    apName: 'meter-mw08-000018',
    name: "Xin Scott's iPhone",
    signal: -63,
    history: [
      'meter-mw08-000020',
      'meter-mw08-000035',
      'meter-mw08-000026',
      'meter-mw08-000018',
      'meter-mw08-000025',
      'meter-mw08-000020',
    ],
    offsetInSeconds: 262,
  },
  '3C:83:75:B8:DC:1E': {
    ipAddress: '10.1.169.213',
    apName: 'meter-mw08-000020',
    name: "Juliette Thompson's Surface Pro",
    signal: -74,
    history: [
      'meter-mw08-000035',
      'meter-mw08-000012',
      'meter-mw08-000007',
      'meter-mw08-000016',
      'meter-mw08-000020',
    ],
    offsetInSeconds: 28,
  },
  'B8:17:C2:9E:E9:63': {
    ipAddress: '10.1.7.45',
    apName: 'meter-mw08-000015',
    name: "David's MacBook Pro",
    signal: -64,
    history: [
      'meter-mw08-000025',
      'meter-mw08-000030',
      'meter-mw08-000033',
      'meter-mw08-000007',
      'meter-mw08-000005',
      'meter-mw08-000004',
      'meter-mw08-000017',
    ],
    offsetInSeconds: 39,
  },
  '6C:4A:85:37:EB:CA': {
    ipAddress: '10.1.54.237',
    apName: '',
    name: "Sofía Ramos's MBP-2",
    signal: -61,
    history: [],
    offsetInSeconds: 196,
  },
  'DC:08:0F:BE:A3:01': {
    ipAddress: '10.1.85.196',
    apName: 'meter-mw08-000027',
    name: "Clara Clark's MacBook",
    signal: -47,
    history: ['meter-mw08-000019', 'meter-mw08-000001', 'meter-mw08-000001'],
    offsetInSeconds: 44,
  },
  '96:7B:02:B6:3B:1D': {
    ipAddress: '10.1.234.191',
    apName: 'meter-mw08-000024',
    name: '*',
    signal: -67,
    history: ['meter-mw08-000013', 'meter-mw08-000023', 'meter-mw08-000035', 'meter-mw08-000017'],
    offsetInSeconds: 189,
  },
  'AC:97:23:B6:D1:EF': {
    ipAddress: '10.1.171.100',
    apName: 'meter-mw08-000039',
    name: '*',
    signal: -57,
    history: [
      'meter-mw08-000033',
      'meter-mw08-000021',
      'meter-mw08-000006',
      'meter-mw08-000017',
      'meter-mw08-000037',
      'meter-mw08-000010',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 247,
  },
  'F0:5C:D5:2A:CF:C6': {
    ipAddress: '10.1.34.235',
    apName: 'meter-mw08-000021',
    name: 'HomePod',
    signal: -50,
    history: [
      'meter-mw08-000027',
      'meter-mw08-000039',
      'meter-mw08-000010',
      'meter-mw08-000034',
      'meter-mw08-000020',
      'meter-mw08-000030',
    ],
    offsetInSeconds: 272,
  },
  '10:CE:E9:0A:7F:B0': {
    ipAddress: '10.1.68.65',
    apName: '',
    name: "Maria's MBP-2",
    signal: -50,
    history: [],
    offsetInSeconds: 72,
  },
  '00:04:0F:C1:7F:6C': {
    ipAddress: '10.1.148.34',
    apName: 'meter-mw08-000023',
    name: "Sneha's ZenBook Flip",
    signal: -62,
    history: ['meter-mw08-000006', 'meter-mw08-000016', 'meter-mw08-000026'],
    offsetInSeconds: 290,
  },
  'C4:9D:ED:36:CF:3C': {
    ipAddress: '10.1.50.33',
    apName: 'meter-mw08-000032',
    name: "Anika's Surface Pro",
    signal: -64,
    history: ['meter-mw08-000040'],
    offsetInSeconds: 165,
  },
  '60:8C:4A:55:E4:20': {
    ipAddress: '10.1.30.22',
    apName: 'meter-mw08-000033',
    name: "Hugo's MBP-2",
    signal: -74,
    history: [
      'meter-mw08-000006',
      'meter-mw08-000028',
      'meter-mw08-000038',
      'meter-mw08-000032',
      'meter-mw08-000018',
      'meter-mw08-000011',
      'meter-mw08-000024',
      'meter-mw08-000001',
      'meter-mw08-000029',
    ],
    offsetInSeconds: 58,
  },
  '15:A0:AB:FE:6B:E9': {
    ipAddress: '10.1.196.6',
    apName: 'meter-mw08-000003',
    name: '*',
    signal: -67,
    history: ['meter-mw08-000019', 'meter-mw08-000037', 'meter-mw08-000009'],
    offsetInSeconds: 259,
  },
  '2C:57:CE:BF:F2:C7': {
    ipAddress: '10.1.74.194',
    apName: 'meter-mw08-000001',
    name: "Mei Davis's MBP-2",
    signal: -71,
    history: ['meter-mw08-000018', 'meter-mw08-000024'],
    offsetInSeconds: 121,
  },
  '8C:7B:9D:21:FA:24': {
    ipAddress: '10.1.56.114',
    apName: 'meter-mw08-000024',
    name: "Michael's iPad",
    signal: -66,
    history: [
      'meter-mw08-000038',
      'meter-mw08-000013',
      'meter-mw08-000015',
      'meter-mw08-000024',
      'meter-mw08-000022',
      'meter-mw08-000007',
      'meter-mw08-000018',
    ],
    offsetInSeconds: 159,
  },
  '2C:27:D7:BD:08:10': {
    ipAddress: '10.1.115.63',
    apName: 'meter-mw08-000033',
    name: "Enzo Horvath's Dragonfly Pro",
    signal: -74,
    history: [
      'meter-mw08-000012',
      'meter-mw08-000028',
      'meter-mw08-000040',
      'meter-mw08-000018',
      'meter-mw08-000005',
      'meter-mw08-000010',
    ],
    offsetInSeconds: 293,
  },
  'F8:6F:C1:80:A0:78': {
    ipAddress: '10.1.55.118',
    apName: '',
    name: "Michael Liu's MBP",
    signal: -68,
    history: [],
    offsetInSeconds: 66,
  },
  '70:5A:0F:C5:66:61': {
    ipAddress: '10.1.213.71',
    apName: 'meter-mw08-000038',
    name: "William Silva's Dragonfly Pro",
    signal: -71,
    history: ['meter-mw08-000026', 'meter-mw08-000010', 'meter-mw08-000037', 'meter-mw08-000020'],
    offsetInSeconds: 285,
  },
  '40:07:7F:A0:E3:3D': {
    ipAddress: '10.1.95.23',
    apName: '',
    name: '*',
    signal: -68,
    history: [],
    offsetInSeconds: 177,
  },
  '00:04:0F:FD:77:51': {
    ipAddress: '10.1.115.56',
    apName: '',
    name: "Ximena's ZenBook Flip",
    signal: -62,
    history: [],
    offsetInSeconds: 236,
  },
  '74:1B:B2:EB:20:85': {
    ipAddress: '10.1.251.107',
    apName: 'meter-mw08-000026',
    name: "Lucía's MBP",
    signal: -70,
    history: [
      'meter-mw08-000025',
      'meter-mw08-000029',
      'meter-mw08-000039',
      'meter-mw08-000018',
      'meter-mw08-000003',
    ],
    offsetInSeconds: 172,
  },
  'B8:78:2E:0E:CC:7D': {
    ipAddress: '10.1.80.33',
    apName: '',
    name: "Ximena Gomez's MacBook",
    signal: -57,
    history: [],
    offsetInSeconds: 151,
  },
  'B8:BB:C6:A7:D2:0C': {
    ipAddress: '10.1.230.109',
    apName: '',
    name: '*',
    signal: -74,
    history: [],
    offsetInSeconds: 199,
  },
  '3F:2F:B4:84:CA:79': {
    ipAddress: '10.1.120.93',
    apName: 'meter-mw08-000036',
    name: '*',
    signal: -66,
    history: [
      'meter-mw08-000011',
      'meter-mw08-000013',
      'meter-mw08-000028',
      'meter-mw08-000028',
      'meter-mw08-000039',
      'meter-mw08-000033',
    ],
    offsetInSeconds: 273,
  },
  '38:8B:59:66:08:5F': {
    ipAddress: '10.1.125.186',
    apName: 'meter-mw08-000037',
    name: "Lola's Chromebook",
    signal: -61,
    history: ['meter-mw08-000029', 'meter-mw08-000036', 'meter-mw08-000033', 'meter-mw08-000012'],
    offsetInSeconds: 264,
  },
  'F0:98:9D:B3:C2:7B': {
    ipAddress: '10.1.78.163',
    apName: 'meter-mw08-000012',
    name: "Juan Takahashi's MacBook Pro",
    signal: -75,
    history: [
      'meter-mw08-000003',
      'meter-mw08-000018',
      'meter-mw08-000040',
      'meter-mw08-000037',
      'meter-mw08-000025',
      'meter-mw08-000026',
      'meter-mw08-000017',
      'meter-mw08-000024',
    ],
    offsetInSeconds: 123,
  },
  '3C:52:82:26:8E:22': {
    ipAddress: '10.1.4.77',
    apName: 'meter-mw08-000001',
    name: "Sergei Nagy's Dragonfly Pro",
    signal: -54,
    history: ['meter-mw08-000016', 'meter-mw08-000040', 'meter-mw08-000038', 'meter-mw08-000009'],
    offsetInSeconds: 60,
  },
  '80:58:F8:8C:14:EC': {
    ipAddress: '10.1.177.240',
    apName: '',
    name: "Ximena Thomas's ThinkPad X1",
    signal: -67,
    history: [],
    offsetInSeconds: 53,
  },
  '9C:3A:BA:7C:99:D9': {
    ipAddress: '10.1.154.65',
    apName: '',
    name: '*',
    signal: -58,
    history: [],
    offsetInSeconds: 102,
  },
  '9C:FC:01:CA:3B:DB': {
    ipAddress: '10.1.177.77',
    apName: 'meter-mw08-000030',
    name: 'HomePod',
    signal: -52,
    history: ['meter-mw08-000002', 'meter-mw08-000013'],
    offsetInSeconds: 292,
  },
  '68:5B:35:2A:34:46': {
    ipAddress: '10.1.235.176',
    apName: 'meter-mw08-000016',
    name: "Vikram Yang's MBP-2",
    signal: -74,
    history: ['meter-mw08-000031', 'meter-mw08-000004'],
    offsetInSeconds: 186,
  },
  'B4:F0:AB:0E:18:5D': {
    ipAddress: '10.1.253.250',
    apName: 'meter-mw08-000015',
    name: "Maxim Williams's iPad",
    signal: -49,
    history: [
      'meter-mw08-000005',
      'meter-mw08-000020',
      'meter-mw08-000010',
      'meter-mw08-000030',
      'meter-mw08-000026',
      'meter-mw08-000020',
    ],
    offsetInSeconds: 8,
  },
  '14:18:77:15:BA:60': {
    ipAddress: '10.1.229.177',
    apName: 'meter-mw08-000027',
    name: "Alejandro's Inspiron",
    signal: -68,
    history: ['meter-mw08-000011', 'meter-mw08-000024', 'meter-mw08-000027', 'meter-mw08-000010'],
    offsetInSeconds: 177,
  },
  'D0:13:0C:89:E2:60': {
    ipAddress: '10.1.159.138',
    apName: 'meter-mw08-000030',
    name: '*',
    signal: -71,
    history: ['meter-mw08-000006'],
    offsetInSeconds: 72,
  },
  '40:3C:FC:B5:FB:0D': {
    ipAddress: '10.1.169.255',
    apName: '',
    name: "Neha Davis's iPhone",
    signal: -71,
    history: [],
    offsetInSeconds: 272,
  },
  '80:C5:E6:35:53:44': {
    ipAddress: '10.1.140.215',
    apName: '',
    name: "Ekaterina's Surface Pro",
    signal: -63,
    history: [],
    offsetInSeconds: 159,
  },
  '59:BE:F8:6E:75:A7': {
    ipAddress: '10.1.201.54',
    apName: 'meter-mw08-000019',
    name: '*',
    signal: -53,
    history: ['meter-mw08-000010', 'meter-mw08-000007', 'meter-mw08-000036'],
    offsetInSeconds: 243,
  },
  'D8:9E:F3:44:41:25': {
    ipAddress: '10.1.133.153',
    apName: 'meter-mw08-000032',
    name: "Thomas's Inspiron",
    signal: -46,
    history: [
      'meter-mw08-000030',
      'meter-mw08-000013',
      'meter-mw08-000006',
      'meter-mw08-000007',
      'meter-mw08-000040',
    ],
    offsetInSeconds: 1,
  },
};

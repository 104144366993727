import type {
  BadgeVariant,
  PortPropOrientation,
  PortPropPort,
  PortProps,
  PortPropStatus,
} from '@meterup/atto';
import {
  Badge,
  colors,
  Column,
  Columns,
  ComboBox,
  ComboBoxItem,
  CopyBox,
  darkThemeSelector,
  Device,
  fontWeights,
  HardwareIcon,
  Label,
  Large,
  Legends,
  Pane,
  PaneContent,
  PaneHeader,
  Port,
  Section,
  SectionContent,
  SectionHeader,
  shadows,
  Slots,
  Small,
  space,
  styled,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Tab,
  Text,
  ToggleInput,
  useDialogState,
  Visualizer,
} from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { useIsOperator } from '@meterup/authorization';
import { expectDefinedOrThrow, lookupMACAddressOUI, ResourceNotFoundError } from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import type { VirtualDevice } from '../../../gql/graphql';
import type {
  ControllerHardwareDevice,
  ControllerVirtualDevice,
  HardwareDevice,
  PhyInterface,
} from './useCurrentControllers';
import type { HighAvailabilityConfig } from './utils';
import { paths } from '../../../constants';
import {
  DeviceType,
  HighAvailabilityControllerRole,
  HighAvailabilityStatus,
  PermissionType,
  VirtualDeviceType,
} from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { SwitchControls } from '../../../routes/pages/network/hardware/switches/SwitchLegends';
import DeviceConfigEditorDialog from '../../../routes/pages/network/operators/device_config/DeviceConfigEditorDialog';
import { getDurationSeconds, getStep } from '../../../utils/chart_utils';
import { deviceHasMismatchedRealm } from '../../../utils/devices';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useHardwareCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { ucfirst } from '../../../utils/strings';
import ChartFilter from '../../ChartFilter';
import { BootHistoryTable, DetailsWidgetListPair } from '../../Devices/Insights';
import { getPhyInterfaceLabel } from '../../Firewall/utils';
import { useSafeCloseDrawer } from '../../NetworkWide/CaptivePortals/utils';
import { NoValue } from '../../NoValue';
import { ObjectStatus } from '../../Object/ObjectStatus';
import IsPermitted from '../../permissions/IsPermitted';
import { StackedSkeletons } from '../../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../../ReactRouterLink';
import { portSpeedForAtto } from '../Switches/utils';
import { useSiblings } from '../utils';
import HostAvailability from './HostAvailability';
import PortsList from './Ports/PortsList';
import { SecurityApplianceActions } from './SecurityApplianceActions';
import SecurityApplianceInsights from './SecurityApplianceInsights';
import { SecurityApplianceRebootDialog } from './SecurityApplianceRebootDialog';
import useCurrentControllers from './useCurrentControllers';
import { ControllersForSecurityApplianceQuery, HAConfigForNetworkQuery } from './utils';
import {
  controllerMetricsQuery,
  isPrimaryController,
  mergeStatsAndPhyInterfaces,
  SecurityApplianceDetailsTab,
  SecurityAppliancePortStatsQuery,
} from './utils2';

type SecurityApplianceViewProps = {
  uuid: string;
  tab: SecurityApplianceDetailsTab;
};

function DetailTabs({ tab, uuid }: SecurityApplianceViewProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const isHostMonitoringEnabled = useNosFeatureEnabled(NosFeature.HOST_MONITORING);

  function navigateToTab(newTab: string) {
    navigate(
      makeLink(paths.pages.SecurityApplianceDetailPage, {
        companyName,
        networkSlug: network.slug,
        tab: newTab,
        uuid,
      }),
    );
  }

  return (
    <>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkMetricsRead) &&
              nosFlags[NosFeature.COS2],
          )
        }
      >
        <Tab
          icon="reporting"
          selected={tab === SecurityApplianceDetailsTab.Insights}
          onClick={() => navigateToTab(SecurityApplianceDetailsTab.Insights)}
        >
          Insights
        </Tab>
      </IsPermitted>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermPhyInterfaceRead) &&
              nosFlags[NosFeature.COS2],
          )
        }
      >
        <Tab
          icon="ethernet"
          selected={tab === SecurityApplianceDetailsTab.Ports}
          onClick={() => navigateToTab(SecurityApplianceDetailsTab.Ports)}
        >
          Ports
        </Tab>
      </IsPermitted>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermHostMonitoringRead) &&
              nosFlags[NosFeature.HOST_MONITORING] &&
              nosFlags[NosFeature.COS2],
          )
        }
      >
        <Tab
          icon="log"
          selected={tab === SecurityApplianceDetailsTab.HostMonitoring}
          onClick={() => navigateToTab(SecurityApplianceDetailsTab.HostMonitoring)}
          internal={!isHostMonitoringEnabled}
        >
          Host monitoring
        </Tab>
      </IsPermitted>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
              nosFlags[NosFeature.COS2],
          )
        }
      >
        <Tab
          icon="log"
          selected={tab === SecurityApplianceDetailsTab.BootHistory}
          onClick={() => navigateToTab(SecurityApplianceDetailsTab.BootHistory)}
          internal
        >
          Boot history
        </Tab>
      </IsPermitted>
    </>
  );
}

function controllerRoleLabel(
  device: Pick<ControllerVirtualDevice, 'highAvailability'> & {
    hardwareDevice?: Pick<ControllerHardwareDevice, 'isActive'> | null;
  },
) {
  return isPrimaryController(device) ? 'primary' : 'backup';
}

const statusBadge = ({ hardwareDevice }: { hardwareDevice?: HardwareDevice | null }) => {
  if (!hardwareDevice) {
    return 'unknown';
  }

  return hardwareDevice.isConnectedToBackend ? 'online' : 'offline';
};

const haStatusBadge = ({
  virtualDevice,
}: {
  virtualDevice: Pick<ControllerVirtualDevice, 'highAvailability'> & {
    hardwareDevice?: HardwareDevice | null;
  };
}) => {
  const { highAvailability } = virtualDevice;
  if (!highAvailability) {
    return statusBadge({ hardwareDevice: virtualDevice.hardwareDevice });
  }

  if (!virtualDevice.hardwareDevice?.isConnectedToBackend) {
    return 'offline';
  }

  switch (highAvailability.status) {
    case HighAvailabilityStatus.Ready:
      return 'ready';
    case HighAvailabilityStatus.Active:
      return 'active';
    case HighAvailabilityStatus.Unspecified:
      return 'unknown';
  }

  return 'unknown';
};

type WANInfo = {
  ipAddress?: string | null;
  name: string;
  status: 'active' | 'ready' | 'unknown' | 'offline';
  uplinkGatewayMACAddress: string | null | undefined;
};

function WANListValue({ wan }: { wan: WANInfo }) {
  const label = ucfirst(wan.status);
  let variant: BadgeVariant = 'neutral';
  switch (wan.status) {
    case 'unknown':
    case 'ready':
      variant = 'neutral';
      break;
    case 'active':
      variant = 'positive';
      break;
    case 'offline':
      variant = 'negative';
      break;
  }

  return (
    <Badge ends="pill" size="small" variant={variant}>
      {label}
    </Badge>
  );
}

export function WANSection({ virtualDevice }: { virtualDevice: ControllerVirtualDevice }) {
  const wans = useMemo(() => {
    if (!virtualDevice.phyInterfaces) return [];

    return virtualDevice.phyInterfaces
      .filter((pi) => pi.isEnabled && pi.isUplink)
      .map((wan) => {
        const name = getPhyInterfaceLabel(wan, false);
        let status: 'unknown' | 'active' | 'ready' | 'offline' = 'unknown';
        if (!virtualDevice?.hardwareDevice?.isConnectedToBackend) {
          status = 'offline';
        } else if (wan.isUplinkActive) {
          status = 'active';
        } else if (wan.isEnabled) {
          status = 'ready';
        }
        return {
          ipAddress: wan.uplinkExternalAddresses?.[0],
          name,
          status,
          UUID: wan.UUID,
          uplinkGatewayMACAddress: wan.uplinkGatewayMACAddress,
        };
      });
  }, [virtualDevice?.hardwareDevice?.isConnectedToBackend, virtualDevice.phyInterfaces]);

  if (wans.length === 0) {
    return null;
  }
  return wans.map((wan) => (
    <Section relation="stacked">
      <SectionHeader heading={wan.name} />
      <SectionContent gutter="all">
        <SummaryList
          gutter="none"
          key={wan.UUID}
          pairs={[
            {
              label: 'Status',
              value: <WANListValue wan={wan} />,
            },
            {
              label: 'IP address',
              value: wan.ipAddress ? (
                <CopyBox aria-label={`Copy ${wan.name}'s IP address`} value={wan.ipAddress}>
                  <Text family="monospace">{wan.ipAddress}</Text>
                </CopyBox>
              ) : (
                <NoValue />
              ),
            },
            {
              label: wan.uplinkGatewayMACAddress ? 'Gateway MAC address' : null,
              value: wan.uplinkGatewayMACAddress ? (
                <CopyBox
                  aria-label={`Copy ${wan.name}'s gateway MAC address`}
                  value={wan.uplinkGatewayMACAddress}
                >
                  <Text family="monospace">{wan.uplinkGatewayMACAddress}</Text>
                </CopyBox>
              ) : null,
            },
            {
              label: wan.uplinkGatewayMACAddress ? 'Gateway manufacturer' : null,
              value: wan.uplinkGatewayMACAddress ? (
                <Text>{lookupMACAddressOUI(wan.uplinkGatewayMACAddress)}</Text>
              ) : null,
            },
          ]}
        />
      </SectionContent>
    </Section>
  ));
}

type SlotProps = React.ComponentProps<typeof Slots>['slots'][number];

type PhyInterfaceConnectedHardwareDevice = NonNullable<
  NonNullable<PhyInterface['connectedDevices']>[number]['hardwareDevice']
>;

type SecurityAppliancePortSlotProps = {
  // active is whether or not the port is currently "selected".
  active?: boolean;
  onClick?: () => void;
  orientation?: PortPropOrientation;
  phyInterface: PhyInterface;
  onConnectionClick?: (vd: ConnectionVirtualDevice) => void;
  checkConnectionIsActive?: (vd: ConnectionVirtualDevice) => boolean;
  highAvailability: HighAvailabilityConfig | null | undefined;
};

function securityAppliancePortSlot({
  active = false,
  onClick,
  orientation,
  phyInterface,
  onConnectionClick,
  checkConnectionIsActive,
  highAvailability,
}: SecurityAppliancePortSlotProps): SlotProps {
  let portStatus: PortPropStatus = 'disconnected';
  const portType: PortPropPort = phyInterface.isSFP ? 'sfp' : 'ethernet';
  const side = orientation === 'up' ? 'top' : 'bottom';
  const { connectedDevices, hasWANActivity, internetServicePlan, isUplink, portSpeedMbps } =
    phyInterface;
  let connection: PortProps['connection'] | undefined;
  if (internetServicePlan || hasWANActivity) {
    connection = {
      hardware: 'isp',
      label: internetServicePlan?.provider?.name ?? 'ISP',
      side,
    };
    portStatus = 'connected';
  }

  if (connectedDevices && connectedDevices.length > 0) {
    const [{ hardwareDevice }] = connectedDevices;
    switch (hardwareDevice?.deviceType) {
      case DeviceType.Switch:
        connection = {
          active: checkConnectionIsActive?.(hardwareDevice.virtualDevice) ?? false,
          onClick: onConnectionClick
            ? () => {
                onConnectionClick(hardwareDevice.virtualDevice);
              }
            : undefined,
          hardware: 'switch',
          label: hardwareDevice.virtualDevice?.label ?? 'Switch',
          side,
        };
        break;
      case DeviceType.Controller:
        connection = {
          active: checkConnectionIsActive?.(hardwareDevice.virtualDevice) ?? false,
          onClick: onConnectionClick
            ? () => {
                onConnectionClick(hardwareDevice.virtualDevice);
              }
            : undefined,
          hardware: 'security-appliance',
          label: hardwareDevice.virtualDevice?.label ?? 'Security appliance',
          side,
        };
        break;
    }
  }

  let haRole: 'primary' | 'backup' | undefined;
  if (highAvailability) {
    let connectedHAPhyInterface: HighAvailabilityConfig['primaryPhyInterface'] | undefined;
    if (highAvailability.primaryPhyInterface.UUID === phyInterface.UUID) {
      haRole = 'primary';
      connectedHAPhyInterface = highAvailability.backupPhyInterface;
    } else if (highAvailability.backupPhyInterface.UUID === phyInterface.UUID) {
      haRole = 'backup';
      connectedHAPhyInterface = highAvailability.primaryPhyInterface;
    }
    if (connectedHAPhyInterface) {
      connection = {
        active: checkConnectionIsActive?.(connectedHAPhyInterface.virtualDevice) ?? false,
        onClick: onConnectionClick
          ? () => {
              onConnectionClick(connectedHAPhyInterface.virtualDevice);
            }
          : undefined,
        hardware: 'security-appliance',
        label: connectedHAPhyInterface.virtualDevice?.label ?? 'Security appliance',
        side,
      };
    }
  }

  if (portSpeedMbps && portSpeedMbps > 0) {
    portStatus = 'connected';
  }

  return {
    active,
    connection,
    number: phyInterface.portNumber,
    label: phyInterface.hardwareLabel,
    diagram: (
      <Port
        disabled={!phyInterface.isEnabled}
        number={phyInterface.portNumber}
        orientation={orientation}
        active={connection?.active ?? false}
        port={portType}
        speed={portSpeedForAtto(phyInterface)}
        uplink={isUplink}
        status={portStatus ?? portStatus}
        data-ha-role={haRole}
      />
    ),
    onClick,
  };
}

type ConnectionVirtualDevice =
  | Pick<NonNullable<PhyInterfaceConnectedHardwareDevice['virtualDevice']>, 'UUID' | 'deviceType'>
  | null
  | undefined;

type SecurityApplianceDeviceProps = {
  device: ControllerVirtualDevice;
  orientation?: PortPropOrientation;
  highAvailability: HighAvailabilityConfig | null | undefined;
};

function SecurityApplianceDevice({
  device,
  orientation,
  highAvailability,
}: SecurityApplianceDeviceProps) {
  const [phyInterfaces, uplinkPhyInterfaces] = useMemo(() => {
    const pi: PhyInterface[] = [];
    const upi: PhyInterface[] = [];
    device.phyInterfaces.forEach((phyInterface) => {
      if (phyInterface.isUplink) {
        upi.push(phyInterface);
      } else {
        pi.push(phyInterface);
      }
    });
    return [pi, upi];
  }, [device.phyInterfaces]);
  const deviceLabel = device.label.length > 15 ? `${device.label.slice(0, 12)}...` : device.label;
  const navigate = useNavigate();
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const params = Nav.useRegionParams('drawer', paths.drawers.SecurityAppliancePortDetailPage);

  const closeDrawer = useSafeCloseDrawer();
  const makeSappDrawerLink = useCallback(
    (phyInterfaceUUID: string) =>
      makeDrawerLink(window.location, paths.drawers.SecurityAppliancePortDetailPage, {
        companyName,
        networkSlug: network.slug,
        phyInterfaceUUID,
        virtualDeviceUUID: device.UUID,
      }),
    [companyName, device.UUID, network.slug],
  );
  const onClick = useCallback(
    (phyInterfaceUUID: string) => () => {
      if (params?.phyInterfaceUUID === phyInterfaceUUID) {
        closeDrawer();
        return;
      }
      navigate(makeSappDrawerLink(phyInterfaceUUID));
    },
    [closeDrawer, makeSappDrawerLink, navigate, params?.phyInterfaceUUID],
  );

  const switchParams = Nav.useRegionParams('drawer', paths.drawers.SwitchSummaryPage);
  const securityApplianceParams = Nav.useRegionParams(
    'drawer',
    paths.drawers.SecurityApplianceSummaryPage,
  );

  const checkConnectionIsActive = useCallback(
    (virtualDevice: ConnectionVirtualDevice) =>
      Boolean(
        (switchParams && switchParams.uuid === virtualDevice?.UUID) ||
          (securityApplianceParams && securityApplianceParams.uuid === virtualDevice?.UUID),
      ),
    [switchParams, securityApplianceParams],
  );

  const onConnectionClick = useCallback(
    (virtualDevice: ConnectionVirtualDevice) => {
      if (virtualDevice?.deviceType === VirtualDeviceType.Controller) {
        return navigate(
          makeDrawerLink(window.location, paths.drawers.SecurityApplianceSummaryPage, {
            companyName,
            networkSlug: network.slug,
            uuid: virtualDevice?.UUID,
          }),
        );
      }

      if (virtualDevice?.deviceType === VirtualDeviceType.Switch) {
        return navigate(
          makeDrawerLink(window.location, paths.drawers.SwitchSummaryPage, {
            companyName,
            networkSlug: network.slug,
            uuid: virtualDevice?.UUID,
          }),
        );
      }

      return null;
    },
    [network.slug, companyName, navigate],
  );

  return (
    <Device
      icon="security-appliance"
      name={deviceLabel}
      title={device.label}
      slots={
        <>
          <Slots
            align="bottom"
            orientation={orientation === 'up' ? 'top' : 'bottom'}
            slots={phyInterfaces.map((phyInterface) =>
              securityAppliancePortSlot({
                active: params?.phyInterfaceUUID === phyInterface.UUID,
                onClick: onClick(phyInterface.UUID),
                onConnectionClick,
                checkConnectionIsActive,
                orientation,
                phyInterface,
                highAvailability,
              }),
            )}
          />
          <Slots
            align="bottom"
            rows={2}
            slots={uplinkPhyInterfaces.map((phyInterface, idx) =>
              securityAppliancePortSlot({
                active: params?.phyInterfaceUUID === phyInterface.UUID,
                onClick: onClick(phyInterface.UUID),
                orientation: idx % 2 === 0 ? 'down' : 'up',
                phyInterface,
                highAvailability,
              }),
            )}
          />
        </>
      }
    />
  );
}

const SecurityApplianceTabIllustration = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const SecurityApplianceTabLabel = styled(Large, {
  fontWeight: fontWeights.bold,

  variants: {
    active: {
      true: {
        color: colors.headingBrandLight,
        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
    },
  },
});

const SecurityApplianceTabDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const SecurityApplianceTab = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$10',
  maxWidth: '$320',
  padding: '$6 $10',
  borderRadius: '$8',

  [selectors.hover]: {
    background: colors.bgNeutralLight,
    strokeAll: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.bgNeutralDark,
      strokeAll: colors.strokeNeutralDark,
    },
  },

  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    active: {
      true: {
        backgroundColor: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },

        [selectors.hover]: {
          background: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
    },
  },
});
SecurityApplianceTab.displayName = 'SecurityApplianceTab';

const SecurityApplianceTabs = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
});

const SecurityAppliancePosition = styled('div', {
  hStack: '$40',
  margin: '0 auto',
});
SecurityAppliancePosition.displayName = 'SecurityAppliancePosition';

export function DetailsWidget({
  virtualDevice,
  relation,
  highAvailability,
}: {
  virtualDevice: ControllerVirtualDevice;
  relation: 'stacked' | 'standalone';
  highAvailability: HighAvailabilityConfig | null | undefined;
}) {
  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'ControllerHardwareDevice') return null;
  if (!virtualDevice || virtualDevice.__typename !== 'ControllerVirtualDevice') return null;

  return (
    <Section relation={relation}>
      <SectionHeader heading="Metadata" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          {highAvailability && (
            <SummaryListRow>
              <SummaryListKey>Role</SummaryListKey>
              <SummaryListValue>
                <ObjectStatus
                  relation="joined"
                  size="small"
                  tied
                  lifecycle={controllerRoleLabel(virtualDevice)}
                />
              </SummaryListValue>
            </SummaryListRow>
          )}
          <SummaryListRow>
            <SummaryListKey>Status</SummaryListKey>
            <SummaryListValue>
              <ObjectStatus
                relation="joined"
                size="small"
                tied
                status={haStatusBadge({ virtualDevice })}
              />
            </SummaryListValue>
          </SummaryListRow>
          <DetailsWidgetListPair label="Model" value={virtualDevice.deviceModel} />
          <DetailsWidgetListPair label="MAC address" value={hardwareDevice?.macAddress} />
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

interface SecurityApplianceDetailProps extends SecurityApplianceViewProps {
  virtualDevice: ControllerVirtualDevice;
  virtualDevices: ControllerVirtualDevice[];
  refetch: () => void;
  isFetching: boolean;
  dataUpdatedAt: number;
  highAvailability: HighAvailabilityConfig | null | undefined;
}

function SecurityAppliancePortsView({
  uuid,
  virtualDevice,
  virtualDevices,
  refetch,
  isFetching,
  dataUpdatedAt,
}: Exclude<SecurityApplianceDetailProps, 'tab'>) {
  const network = useNetwork();
  const [showAll, setShowAll] = useSearchParamsState<boolean>('showAll', true);

  const highAvailability = useGraphQL(HAConfigForNetworkQuery, { networkUUID: network.UUID }).data
    ?.network?.highAvailabilityConfig;

  const portStats = useGraphQL(
    SecurityAppliancePortStatsQuery,
    {
      virtualDeviceUUID: uuid,
    },
    {
      useErrorBoundary: false,
    },
  ).data?.controllerPortStats;

  const phyInterfacesWithStats = useMemo(
    () => mergeStatsAndPhyInterfaces(portStats ?? [], virtualDevice),
    [portStats, virtualDevice],
  );

  let otherLabel = 'all';
  if (virtualDevice.highAvailability) {
    switch (virtualDevice.highAvailability.role) {
      case HighAvailabilityControllerRole.Primary:
        otherLabel = 'backup';
        break;
      case HighAvailabilityControllerRole.Backup:
        otherLabel = 'primary';
        break;
    }
  }

  return (
    <>
      <Visualizer
        state={
          <SwitchControls
            refetch={refetch}
            refreshDisabled={isFetching}
            updatedAt={dataUpdatedAt}
          />
        }
        tip={
          virtualDevices.length > 1 && (
            <Label>
              <ToggleInput
                controlSize="small"
                selected={showAll}
                onChange={(selected) => {
                  setShowAll(selected);
                }}
              />
              <Small weight="bold" whitespace="no-wrap">
                Show {otherLabel}
              </Small>
            </Label>
          )
        }
        visuals={
          <SecurityAppliancePosition>
            {showAll ? (
              virtualDevices.map((device) => (
                <SecurityApplianceDevice
                  key={`sapp-device-${device.UUID}`}
                  device={device}
                  highAvailability={highAvailability}
                />
              ))
            ) : (
              <SecurityApplianceDevice
                key={`sapp-device-${virtualDevice.UUID}`}
                device={virtualDevice}
                orientation="down"
                highAvailability={highAvailability}
              />
            )}
          </SecurityAppliancePosition>
        }
        status={
          <Legends
            legends={[
              { variant: 'neutral', label: 'Disconnected' },
              { variant: 'attention', label: '100 Mbps' },
              { variant: 'positive', label: '1 Gbps' },
              { variant: 'alternative', label: '10 Gbps' },
              { error: true, label: 'Error' },
              { disabled: true, label: 'Disabled' },
            ]}
          />
        }
        properties={
          <Legends
            legends={[
              { icon: 'arrow-up', label: 'Uplink' },
              { icon: 'block', label: 'Blocking' },
              { icon: 'lightning', label: 'PoE+' },
            ]}
          />
        }
      />
      <PortsList uuid={uuid} phyInterfacesWithStats={phyInterfacesWithStats} />
    </>
  );
}

function SecurityApplianceDetail({
  virtualDevice,
  virtualDevices,
  uuid,
  tab,
  highAvailability,
  ...rest
}: SecurityApplianceDetailProps) {
  const isOperator = useIsOperator();
  const isHostMonitoringEnabled = useNosFeatureEnabled(NosFeature.HOST_MONITORING);

  const { hardwareDevice } = virtualDevice;

  switch (tab) {
    case SecurityApplianceDetailsTab.BootHistory:
      return (
        <IsPermitted
          isPermitted={({ permissions, nosFlags }) =>
            Boolean(
              permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
                nosFlags[NosFeature.COS2],
            )
          }
          should404OnAccessDenied
        >
          <BootHistoryTable bootHistory={hardwareDevice?.bootHistory ?? []} />
        </IsPermitted>
      );
    case SecurityApplianceDetailsTab.Insights:
      return (
        <Columns template="object">
          <Column>
            <DetailsWidget
              virtualDevice={virtualDevice}
              relation="stacked"
              highAvailability={highAvailability}
            />
            <WANSection virtualDevice={virtualDevice} />
          </Column>
          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermNetworkMetricsRead) &&
                  nosFlags[NosFeature.COS2],
              )
            }
          >
            <Column>
              <SecurityApplianceInsights virtualDeviceUUID={uuid} />
            </Column>
          </IsPermitted>
        </Columns>
      );
    case SecurityApplianceDetailsTab.Ports:
      return (
        <SecurityAppliancePortsView
          {...rest}
          virtualDevice={virtualDevice}
          virtualDevices={virtualDevices}
          uuid={uuid}
          tab={tab}
          highAvailability={highAvailability}
        />
      );
    case SecurityApplianceDetailsTab.HostMonitoring:
      if (!isOperator && !isHostMonitoringEnabled) {
        throw new ResourceNotFoundError('Page not found');
      }
      return <HostAvailability virtualDevices={virtualDevices} />;
  }
}

export default function SecurityApplianceDetailWrapper({ uuid, tab }: SecurityApplianceViewProps) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const hardwareCrumb = useHardwareCrumbs();
  const sortedSiblings = useSiblings({
    query: ControllersForSecurityApplianceQuery,
    deviceType: DeviceType.Controller,
  });
  const rebootDialogProps = useDialogState();
  const configDialogProps = useDialogState();
  const { virtualDevices, virtualDevicesResponse } = useCurrentControllers();
  const virtualDevice = useMemo(
    () => virtualDevices.find((d) => d.UUID === uuid),
    [uuid, virtualDevices],
  );
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Security appliance not found'));

  const highAvailability = useGraphQL(HAConfigForNetworkQuery, { networkUUID: network.UUID }).data
    ?.network.highAvailabilityConfig;

  const { hardwareDevice } = virtualDevice;

  const navigateToSibling = useCallback(
    (newUUID: string) => {
      navigate(
        makeLink(paths.pages.SecurityApplianceDetailPage, {
          companyName,
          networkSlug: network.slug,
          uuid: newUUID,
          tab,
        }),
      );
    },
    [navigate, companyName, network.slug, tab],
  );

  const queryClient = useQueryClient();
  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const duration = useMemo(() => getDurationSeconds(currentTimePeriod), [currentTimePeriod]);
  const step = useMemo(() => getStep(currentTimePeriod), [currentTimePeriod]);

  const refetchMetrics = () => {
    queryClient.invalidateQueries(
      makeQueryKey(controllerMetricsQuery, {
        virtualDeviceUUID: virtualDevice.UUID,
        filter: {
          durationSeconds: duration,
          stepSeconds: step,
        },
      }),
    );
  };

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...hardwareCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.SecurityApplianceListPage, {
                companyName,
                networkSlug: network.slug,
                tab: 'list',
              }),
              label: 'Security appliances',
            },
          },
        ]}
        switcher={
          <ComboBox
            maxWidth="100%"
            icon="security-appliance"
            value={virtualDevice.UUID}
            onValueChange={navigateToSibling}
            size="small"
          >
            {sortedSiblings.map((s: VirtualDevice) => (
              <ComboBoxItem key={s.UUID} textValue={s.label}>
                {s.label}
              </ComboBoxItem>
            ))}
          </ComboBox>
        }
        heading={
          <SecurityApplianceTabs>
            {virtualDevices.map((device) => (
              <SecurityApplianceTab
                active={device.UUID === uuid}
                as={Link}
                key={`security-appliance-tab-${device.UUID}`}
                to={{
                  pathname: makeLink(paths.pages.SecurityApplianceDetailPage, {
                    companyName,
                    tab,
                    uuid: device.UUID,
                    networkSlug: network.slug,
                  }),
                  search: window.location.search,
                }}
              >
                <SecurityApplianceTabIllustration>
                  <HardwareIcon hardware="security-appliance" size={space(40)} />
                </SecurityApplianceTabIllustration>
                <SecurityApplianceTabDetails>
                  <SecurityApplianceTabLabel active={device.UUID === uuid} weight="bold">
                    {device.label}
                  </SecurityApplianceTabLabel>
                  <ObjectStatus
                    size="small"
                    status={haStatusBadge({ virtualDevice: device })}
                    lifecycle={device.highAvailability ? controllerRoleLabel(device) : undefined}
                    mismatchedRealm={deviceHasMismatchedRealm(device.hardwareDevice)}
                  />
                </SecurityApplianceTabDetails>
              </SecurityApplianceTab>
            ))}
          </SecurityApplianceTabs>
        }
        tabs={<DetailTabs uuid={uuid} tab={tab} />}
        contentActions={<SecurityApplianceActions virtualDeviceUUID={uuid} view="detail" />}
        views={
          tab === SecurityApplianceDetailsTab.Insights && (
            <ChartFilter
              sticky={false}
              currentTimePeriod={currentTimePeriod}
              setCurrentTimePeriod={setCurrentTimePeriod}
              refetchMetrics={refetchMetrics}
            />
          )
        }
      />
      <PaneContent>
        <Suspense
          fallback={
            <PaneContent gutter="all">
              <StackedSkeletons />
            </PaneContent>
          }
        >
          <SecurityApplianceDetail
            tab={tab}
            uuid={uuid}
            virtualDevice={virtualDevice}
            virtualDevices={virtualDevices}
            refetch={virtualDevicesResponse.refetch}
            isFetching={virtualDevicesResponse.isFetching}
            dataUpdatedAt={virtualDevicesResponse.dataUpdatedAt}
            highAvailability={highAvailability}
          />
        </Suspense>
        {hardwareDevice?.serialNumber && (
          <SecurityApplianceRebootDialog
            state={rebootDialogProps.state}
            serialNumber={hardwareDevice.serialNumber}
            label={virtualDevice.label}
          />
        )}
        <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
          {hardwareDevice?.serialNumber && (
            <DeviceConfigEditorDialog
              state={configDialogProps.state}
              serialNumber={hardwareDevice.serialNumber}
            />
          )}
        </IsPermitted>
      </PaneContent>
    </Pane>
  );
}

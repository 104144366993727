import type { SortingState } from '@tanstack/react-table';
import type { RefObject } from 'react';
import type { To } from 'react-router-dom';
import { CopyBox, DeviceTarget, HStack, Icon, space, Text } from '@meterup/atto';
import { AutoTable, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { MACTableQueryResult } from './utils';
import { paths } from '../../../constants';
import { useNetworkClients } from '../../../hooks/networkClients/useNetworkClients';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { formatTimestamp } from '../../Devices/utils';
import { NoValue } from '../../NoValue';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { MACTableQuery } from './utils';

const builder = createColumnBuilder<MACTableQueryResult>();

function MACCell({ value }: { value: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);

  const clients = useNetworkClients(network, {
    filter: {
      lookbackMinutes: 60, // arbitrary
      excludeMeterHardware: false,
    },
  });

  const clientMACSet = useMemo(
    () => new Set(clients.map((client) => client.macAddress)),
    [clients],
  );

  const to: To | undefined = useMemo(() => {
    if (isWOS2Enabled && clientMACSet.has(value)) {
      return makeDrawerLink(window.location, paths.drawers.ClientSummary2Page, {
        companyName,
        networkSlug: network.slug,
        macAddress: value,
      });
    }

    // Not adding links to WOS1 clients

    return undefined;
  }, [companyName, network.slug, isWOS2Enabled, value, clientMACSet]);

  const target = to ? (
    <DeviceTarget
      as={Link}
      to={to}
      aria-label={`Open ${value}'s client drawer`}
      type="client"
      wrap={false}
    >
      <Text family="monospace">{value}</Text>
    </DeviceTarget>
  ) : (
    <Text family="monospace">{value}</Text>
  );

  return (
    <HStack align="center" spacing={space(10)}>
      {target}
      <CopyBox aria-label="Copy MAC address" value={value}>
        <Icon icon="copy" size={14} />
      </CopyBox>
    </HStack>
  );
}

function VLANCell({ row, value }: { row: MACTableQueryResult; value: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  if (!row.vlan) {
    return <NoValue />;
  }

  return (
    <DeviceTarget
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.VLANEditPage, {
        companyName,
        networkSlug: network.slug,
        uuid: row.vlan.UUID,
      })}
      aria-label={`Open ${value}'s VLAN drawer`}
      type="vlan"
      wrap={false}
    >
      {value}
    </DeviceTarget>
  );
}

const columns = [
  builder.data((row) => row.port.toString(), {
    id: 'row-port',
    header: 'Port',
    meta: {
      alignment: 'end',
      width: 60,
    },
  }),
  builder.data((row) => row.macAddress, {
    id: 'row-mac-address',
    header: 'MAC',
    cell: MACCell,
  }),
  builder.data((row) => row.vlan?.name ?? '', {
    id: 'row-vlan',
    header: 'VLAN',
    cell: VLANCell,
  }),
  builder.data((row) => row.updatedAt, {
    id: 'row-updated-at',
    header: 'Updated at',
    meta: {
      alignment: 'end',
    },
    cell: (props) => <>{formatTimestamp(props.value)}</>,
  }),
];

function SwitchMACTableView({
  virtualDeviceUUID,
  globalFilter,
  tableContainerRef,
}: {
  virtualDeviceUUID: string;
  globalFilter: string | undefined;
  tableContainerRef: RefObject<HTMLDivElement>;
}) {
  const results = useGraphQL(MACTableQuery, { virtualDeviceUUID }).data?.switchMACTable;
  expectDefinedOrThrow(results, new ResourceNotFoundError('Unable to load MAC table'));
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return (
    <AutoTable
      isVirtual
      tableContainerRef={tableContainerRef}
      size="small"
      columns={columns}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      data={results ?? []}
    />
  );
}

export default SwitchMACTableView;

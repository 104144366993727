import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import ServiceEdit from '../../../../../../components/Settings/Organization/Integrations/Services/ServiceEdit';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/integrations/services/service/edit',
  title: 'Edit service - Services - Integrations - Org',
});

export default function ServiceEditPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  return <ServiceEdit />;
}

import {
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  CompositeField,
  FieldContainer,
  PrimaryField,
  PrimaryFieldComposite,
  Select,
  SelectItem,
  TextInput,
} from '@meterup/atto';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { partition } from 'lodash-es';
import React, { useMemo } from 'react';

import type { FixMeLater } from '../../../../../types/fixMeLater';
import type { ValidContentFilterRuleData } from './form_data';
import { getRuleCategories } from '../../../../../api/api';
import { Box } from '../../../../../components/Box';
import { createFieldProvider } from '../../../../../components/Form/FieldProvider';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';

const FieldProvider = createFieldProvider<ValidContentFilterRuleData>();

export const NO_CATEGORY_ID = -1;

const stable: FixMeLater = [];

interface VisualCategorization {
  header: string;
  rules: api.ContentFilterRuleCategory[];
}

function useSortedContentCategories() {
  const company = useCurrentCompany();

  const contentCategories =
    useQuery(['content_filter_rule_categories'], () => getRuleCategories(company), {
      suspense: true,
    }).data ?? stable;

  const sortedVisualContentCategories = useMemo(() => {
    const [categoriesWithChildren, categoriesWithoutChildren] = partition(
      contentCategories,
      (c) => c.subcategories.length > 0,
    );

    const allVisualCategories: VisualCategorization[] = [];

    categoriesWithChildren.forEach((c) => {
      allVisualCategories.push({ header: c.name, rules: [c, ...c.subcategories] });
    });

    allVisualCategories.sort((a, b) => a.header.localeCompare(b.header));

    // Insert parentless categories back at top after sorting
    allVisualCategories.splice(0, 0, {
      header: '',
      rules: categoriesWithoutChildren,
    });

    return allVisualCategories;
  }, [contentCategories]);

  return sortedVisualContentCategories;
}

function CategoryField({ contentCategories }: { contentCategories: VisualCategorization[] }) {
  const comboBoxSection = (category: VisualCategorization) => (
    <ComboBoxSection
      key={category.header}
      title={category.header || undefined}
      items={category.rules}
    >
      {(c) => (
        <ComboBoxItem key={c.id} textValue={`${c.name}`}>
          {c.name === category.header ? `All "${c.name}"` : c.name}
        </ComboBoxItem>
      )}
    </ComboBoxSection>
  );

  return (
    <FieldProvider name="content.categoryId">
      <CompositeField
        element={
          <ComboBox
            aria-label="Select category"
            placeholder="Choose category"
            defaultItems={contentCategories}
            maxWidth="100%"
          >
            {(category) => comboBoxSection(category)}
          </ComboBox>
        }
        label="Type"
      />
    </FieldProvider>
  );
}

export function ActionField() {
  return (
    <FieldProvider name="action">
      <CompositeField
        element={
          <Select width="100%">
            <SelectItem key={api.ContentFilterAction.CONTENTFILTER_ACTION_ALLOW}>Allow</SelectItem>
            <SelectItem key={api.ContentFilterAction.CONTENTFILTER_ACTION_BLOCK}>Block</SelectItem>
          </Select>
        }
        label="Action"
      />
    </FieldProvider>
  );
}
export function ContentField() {
  const contentCategories = useSortedContentCategories();
  return (
    <FieldContainer>
      <PrimaryFieldComposite
        label="Category"
        fields={
          <Box css={{ hStack: '$8', display: 'grid', gridTemplateColumns: '74px minmax(0, 1fr)' }}>
            <ActionField />
            <CategoryField contentCategories={contentCategories} />
          </Box>
        }
      />
    </FieldContainer>
  );
}

export function PriorityField() {
  return (
    <FieldContainer>
      <FieldProvider name="precedence">
        <PrimaryField
          label="Priority"
          description="Rules are checked in order of priority, with 0 being the highest and 100 being the lowest."
          element={<TextInput type="number" />}
        />
      </FieldProvider>
    </FieldContainer>
  );
}

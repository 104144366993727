/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useCallback, useMemo } from 'react';

import { Button, Title } from '@meterup/atto';
import { put, QuoteRequest, useApiResource } from '@meterup/connect-api';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Components
import SidebarLayout from '../../components/Layout/SidebarLayout';
import QuotesContractSidebar from '../../components/QuotesContract/QuotesContractSidebar/QuotesContractSidebar';
import useQuotes from '../../hooks/useQuotes';
import { route } from '../../routes';

function QuoteRequestsCompleted() {
  // Router
  const navigate = useNavigate();

  const { sid } = useParams<{ sid: string }>();
  const [loading, quoteRequest, reloadQuoteRequest, failed] = useApiResource<QuoteRequest>(
    `quote-requests/${sid}`,
    false,
    true,
  );

  const selectedQuoteSids = useMemo(
    () => (quoteRequest ? quoteRequest.selectedQuotes : []),
    [quoteRequest],
  );

  // Quotes
  const { loadingQuotes, selectedQuotes, primaryQuote, secondaryQuote } = useQuotes(
    // @ts-ignore
    sid,
    selectedQuoteSids,
  );

  const renderSidebar = () => {
    if (!primaryQuote) {
      return null;
    }
    if (!quoteRequest) {
      return null;
    }
    return (
      <div>
        <QuotesContractSidebar
          primaryQuote={primaryQuote}
          secondaryQuote={secondaryQuote}
          quoteRequest={quoteRequest}
        />
      </div>
    );
  };

  const onRequestNetwork = useCallback(async () => {
    if (!quoteRequest) {
      return;
    }
    if (!reloadQuoteRequest) {
      return;
    }
    const response = await put(`quote-requests/${quoteRequest.sid}`, {
      is_interested_in_network: !quoteRequest.isInterestedInNetwork,
      is_interested_in_network_changed: true,
    });

    reloadQuoteRequest();
  }, [quoteRequest]);

  if (loading) {
    return null;
  }
  if (!quoteRequest) {
    return null;
  }

  const address = [
    quoteRequest?.location?.address?.address1,
    quoteRequest?.location?.address?.address2,
    quoteRequest?.location?.address?.city,
    quoteRequest?.location?.address?.state,
    quoteRequest?.location?.address?.postalCode,
  ].join(', ');

  return (
    <SidebarLayout sidebarChildren={renderSidebar()} hideSidebarOnMobile={false} align="top">
      <div className="mx-auto max-w-2xl max-w-screen-xs w-full">
        <Title>Thank you {quoteRequest.contactName.split(' ')[0]}</Title>
        <section className="border p-6 text-left rounded-lg justify-between bg-white border-gray-100 mt-6 flex flex-col md:flex-row">
          <div className="flex-1">
            <p className="text-gray-400 text-sm">Contact information</p>
            <div className="mt-2 ">
              Name:
              <span className="ml-1 text-gray-600">{quoteRequest.contactName}</span>
            </div>
            <div className="mt-2 ">
              Company:
              <span className="ml-1 text-gray-600">{quoteRequest.companyName}</span>
            </div>
            <div className="mt-2 ">
              Email:
              <span className="ml-1 text-gray-600">{quoteRequest.contactEmail}</span>
            </div>
            <div className="mt-2 ">
              Phone:
              <span className="ml-1 text-gray-600">{quoteRequest.contactTelephone}</span>
            </div>
            <p className="text-gray-400 text-sm mt-6">Install location</p>
            <div className="mt-2 text-gray-600">{address}</div>
          </div>
          <div className="flex-1 flex flex-col mt-6 md:mt-0">
            <div className="flex-1">
              <p className="text-gray-400  text-sm">Next steps</p>
              <p className="mt-2 text-gray-600 mb-6">
                We will contact you to set up invoicing and confirm your installation date to
                lock-in your price.
              </p>
            </div>
            <div className="flex-1 flex md:items-end md:justify-end">
              <Link to={route('contact', { sid })}>
                <Button variant="secondary">Edit customer info</Button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </SidebarLayout>
  );
}

export default QuoteRequestsCompleted;

import type { OverlayTriggerState } from '@meterup/atto';
import type { GraphQLError } from 'graphql/error';
import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  space,
  Text,
  VStack,
} from '@meterup/atto';
import { isDefined, notify } from '@meterup/common';
import { getGraphQLError, useGraphQLMutation } from '@meterup/graphql';
import { useState } from 'react';

import { graphql } from '../../../gql';

const ReboootPowerDistributionUnitMutation = graphql(`
  mutation RebootPowerDistributionUnit($serialNumber: String!) {
    rpcRebootDevice(serialNumber: $serialNumber)
  }
`);

type PowerDistributionUnitRebootDialogProps = {
  state: OverlayTriggerState;
  serialNumber: string;
  label: string;
};

export function PowerDistributionUnitRebootDialog({
  state,
  serialNumber,
  label,
}: PowerDistributionUnitRebootDialogProps) {
  const mutation = useGraphQLMutation(ReboootPowerDistributionUnitMutation);
  const [error, setError] = useState<GraphQLError | undefined>();

  const handleOpenChange = () => {
    setError(undefined);
    state.close();
  };

  const reboot = () => {
    mutation.mutate(
      { serialNumber },
      {
        onSuccess: () => {
          notify('Request sent to reboot PowerDistributionUnit', { variant: 'positive' });
          handleOpenChange();
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          setError(gqlErr);
        },
      },
    );
  };

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader heading="Confirmation required" />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>
          {isDefined(error) && (
            <Alert
              icon="warning"
              variant="negative"
              heading="Failed to reboot power distribution unit"
              copy={`There was an error rebooting the power distribution unit: ${
                error.message ?? 'Unknown error'
              }`}
              relation="standalone"
            />
          )}
          <VStack align="stretch" spacing={space(8)}>
            <Body as="p">
              Rebooting <Text weight="bold">{label ?? serialNumber}</Text> will temporarily disrupt
              service to all connected devices until it’s back online.
            </Body>
          </VStack>
        </VStack>
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button variant="secondary" onClick={state.close}>
              Cancel
            </Button>
            <Button disabled={mutation.isLoading} onClick={() => reboot()} variant="destructive">
              Reboot
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

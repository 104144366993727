import type { OverlayTriggerState } from '@meterup/atto';
import type { ClientError } from 'graphql-request';
import {
  Alert,
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  CompositeField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  HStack,
  Icon,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  Select,
  SelectItem,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  TextInput,
  Tooltip,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  isIPWithinSubnet,
  isValidCIDR,
  safeParseAddress4,
  splitCIDR,
} from '@meterup/common/src/topics/ipv4';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import type { StaticRoute, VLANStaticMappingsRule } from './utils';
import { paths } from '../../constants';
import {
  type CreateStaticRouteInput,
  type UpdateStaticRouteInput,
  PermissionType,
} from '../../gql/graphql';
import { CreateStaticRouteInputSchema } from '../../gql/zod-types';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../providers/PermissionsProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { ucfirst } from '../../utils/strings';
import { withZodSchema } from '../../utils/withZodSchema';
import { FieldProvider, NumberFieldProvider } from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import {
  createStaticRouteMutation,
  deleteStaticRouteMutation,
  labelForStaticMapping,
  policyRoutingRulesForNetworkQuery,
  staticRouteQuery,
  staticRoutesForNetworkQuery,
  updateStaticRouteMutation,
  vlansStaticMappingsRulesQuery,
} from './utils';

const GATEWAY_IP_MESSAGE = 'Please provide a valid IP address.';

enum GatewayKind {
  IPAddress = 'ip',
  StaticMapping = 'static-mapping',
}

const staticRouteEditFormSchema = CreateStaticRouteInputSchema.omit({
  dstPrefix: true,
})
  .extend({
    name: z.string().nonempty({ message: 'Please provide a name.' }),
    dstIPAddress: z
      .string()
      .ip({ message: 'Invalid IP address' })
      .nonempty({ message: 'Please provide an IP address.' }),
    dstPrefixLength: z
      .number()
      .min(0, { message: 'Prefix length must be greater than or equal to 0.' })
      .max(128, { message: 'Prefix length must be less than or equal to 128.' })
      .nullish(),
    gatewayKind: z.nativeEnum(GatewayKind),
    gatewayIP: z.string().ip({ version: 'v4', message: GATEWAY_IP_MESSAGE }).nullish(),
    gatewayDHCPStaticMappingUUID: z.string().nullish(),
  })
  .superRefine(({ gatewayKind, gatewayIP, gatewayDHCPStaticMappingUUID }, ctx) => {
    switch (gatewayKind) {
      case GatewayKind.IPAddress:
        if (!gatewayIP) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: GATEWAY_IP_MESSAGE,
            path: ['gatewayIP'],
          });
        }
        break;
      case GatewayKind.StaticMapping:
        if (!gatewayDHCPStaticMappingUUID) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please select a static mapping.',
            path: ['gatewayDHCPStaticMappingUUID'],
          });
        }
        break;
    }
  })
  .superRefine(({ dstIPAddress, dstPrefixLength }, ctx) => {
    if (!dstIPAddress) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please provide an IP address.',
        path: ['dstIPAddress'],
      });
    }

    if (dstPrefixLength == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please provide a prefix length.',
        path: ['dstIPAddress'],
      });
    }
  })
  .refine(
    ({ dstIPAddress, dstPrefixLength }) =>
      !dstIPAddress || dstPrefixLength == null || isValidCIDR(dstIPAddress, dstPrefixLength),
    {
      message: 'Invalid prefix length for IP address.',
      path: ['dstPrefixLength'],
    },
  );

type StaticRouteEditFormValues = z.input<typeof staticRouteEditFormSchema>;

function DeleteStaticRouteDialog({
  route,
  state,
}: {
  route: StaticRoute;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const [error, setError] = useState<ClientError | null>(null);
  const closeDrawer = useCloseDrawerCallback();

  const deleteRoute = useGraphQLMutation(deleteStaticRouteMutation);
  const { mutate } = deleteRoute;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    setError(null);

    mutate(
      { uuid: route.UUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(policyRoutingRulesForNetworkQuery, { networkUUID: network.UUID }),
          );
          setError(null);
          closeDrawer();
          close();
          notify('Rule deleted successfully.', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          setError(err);
        },
      },
    );
  }, [mutate, route.UUID, close, network.UUID, queryClient, closeDrawer]);

  const graphqlError = useMemo(() => (error ? getGraphQLError(error) : undefined), [error]);

  if (!route) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete policy routing rule" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to remove the static route <Text weight="bold">{route.name}</Text> from
              your Meter network.
            </>
          }
        />
        <SummaryList gutter="vertical">
          <SummaryListRow>
            <SummaryListKey>Affected client</SummaryListKey>
            <SummaryListValue>
              {route.gatewayDHCPStaticMapping && (
                <Tooltip contents="DHCP static mapping" asChild={false}>
                  <Icon icon="ip-address" />
                </Tooltip>
              )}
              <Text family="monospace">
                {route.gatewayIP ??
                  route.gatewayDHCPStaticMapping?.name ??
                  route.gatewayDHCPStaticMapping?.macAddress}
              </Text>
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>

        {error && (
          <Alert
            icon="warning"
            variant="negative"
            heading="There was an error deleting this policy routing rule"
            copy={graphqlError?.message ? ucfirst(graphqlError.message) : undefined}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

function GatewayIPAlert({ vlans }: { vlans: VLANStaticMappingsRule[] | undefined }) {
  const { values } = useFormikContext<StaticRouteEditFormValues>();

  const hasGatewayIPNotInAnyVLAN = useMemo(() => {
    const addr = safeParseAddress4(values.gatewayIP);
    if (!addr) return false;

    if (!vlans?.length) return true;

    return !vlans.some(
      (vlan) =>
        vlan.ipV4ClientGateway &&
        vlan.ipV4ClientPrefixLength &&
        isIPWithinSubnet(addr, `${vlan.ipV4ClientGateway}/${vlan.ipV4ClientPrefixLength}`),
    );
  }, [values.gatewayIP, vlans]);

  if (hasGatewayIPNotInAnyVLAN) {
    return (
      <Alert
        variant="attention"
        icon="information"
        relation="stacked"
        heading="IP is not within any current VLAN subnet"
        copy="Static route gateway IP must be within a VLAN to take effect."
      />
    );
  }

  return null;
}

const schemaValidator = withZodSchema(staticRouteEditFormSchema);

export default function StaticRouteEditDrawer({ route }: { route?: StaticRoute }) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const canWriteNetworkRoutes = hasPermission(PermissionType.PermNetworkRouteWrite);

  const { state } = useDialogState();

  const vlans = useGraphQL(vlansStaticMappingsRulesQuery, { networkUUID: network.UUID }).data
    ?.vlans;

  const hasStaticMappings = useMemo(
    () => vlans?.some((vlan) => vlan?.dhcpRule?.staticMappings?.length) ?? false,
    [vlans],
  );

  const sortedVLANsWithRules = useMemo(
    () =>
      vlans
        ?.filter(
          (vlan): vlan is typeof vlan & { dhcpRule: NonNullable<(typeof vlan)['dhcpRule']> } =>
            !!vlan.dhcpRule,
        )
        ?.map((vlan) => ({
          ...vlan,
          dhcpRule: {
            ...vlan.dhcpRule,
            staticMappings: vlan.dhcpRule.staticMappings.sort((a, b) =>
              (a.name ?? a.macAddress).localeCompare(b.name ?? b.macAddress),
            ),
          },
        }))
        ?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [vlans],
  );

  const createMutation = useGraphQLMutation(createStaticRouteMutation);
  const updateMutation = useGraphQLMutation(updateStaticRouteMutation);

  const handleSubmit = useCallback(
    ({
      gatewayKind,
      gatewayIP,
      gatewayDHCPStaticMappingUUID,
      dstIPAddress,
      dstPrefixLength,
      ...values
    }: StaticRouteEditFormValues) => {
      if (route) {
        const input: UpdateStaticRouteInput = values;

        updateMutation.mutate(
          { uuid: route.UUID, input },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(staticRoutesForNetworkQuery, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(makeQueryKey(staticRouteQuery, { uuid: route.UUID }));
              notify('Successfully updated static route.', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              notify(
                `There was a problem updating the static route${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateStaticRouteInput = {
          ...values,
          dstPrefix: `${dstIPAddress}/${dstPrefixLength}`,
        };

        switch (gatewayKind) {
          case GatewayKind.IPAddress:
            input.gatewayIP = gatewayIP;
            break;
          case GatewayKind.StaticMapping:
            input.gatewayDHCPStaticMappingUUID = gatewayDHCPStaticMappingUUID;
            break;
        }

        createMutation.mutate(
          { networkUUID: network.UUID, input },
          {
            onSuccess: (result) => {
              queryClient.invalidateQueries(
                makeQueryKey(staticRoutesForNetworkQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully created static route.', {
                variant: 'positive',
              });
              navigate(
                makeDrawerLink(window.location, paths.drawers.StaticRouteEditPage, {
                  routeUUID: result.createStaticRoute.UUID,
                  companyName,
                  networkSlug: network.slug,
                }),
              );
            },
            onError: (err) => {
              notify(
                `There was a problem creating the static route${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      route,
      createMutation,
      updateMutation,
      network.UUID,
      network.slug,
      companyName,
      queryClient,
      navigate,
    ],
  );

  const handleValidate = useCallback(
    (values: StaticRouteEditFormValues) => schemaValidator(values),
    [],
  );

  const dstPrefixPieces = route ? splitCIDR(route.dstPrefix) : null;

  return (
    <Drawer>
      <Formik<StaticRouteEditFormValues>
        initialValues={{
          name: route?.name ?? '',
          isEnabled: route?.isEnabled ?? true,

          dstIPAddress: dstPrefixPieces?.[0] ?? '0.0.0.0',
          dstPrefixLength: dstPrefixPieces?.[1] ?? 0,

          gatewayKind:
            !hasStaticMappings || route?.gatewayIP
              ? GatewayKind.IPAddress
              : GatewayKind.StaticMapping,
          gatewayIP: route?.gatewayIP,
          gatewayDHCPStaticMappingUUID: route?.gatewayDHCPStaticMapping?.UUID,
        }}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            icon="ip-address"
            heading={`${route ? 'Edit' : 'Add'} static route`}
            actions={
              route && canWriteNetworkRoutes ? (
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuItem icon="trash-can" onSelect={state.open}>
                      Delete
                    </DropdownMenuItem>
                  </DropdownMenuPopover>
                </DropdownMenu>
              ) : undefined
            }
            onClose={closeDrawer}
          />
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enabled" disabled={!canWriteNetworkRoutes} />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="name">
                <PrimaryField
                  label="Name"
                  element={<TextInput disabled={!canWriteNetworkRoutes} />}
                />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <PrimaryField label="Destination" element={null} />

              <FieldProvider name="dstIPAddress">
                <SecondaryField
                  label="IP address"
                  element={<TextInput width="120px" disabled={!!route || !canWriteNetworkRoutes} />}
                />
              </FieldProvider>
              <NumberFieldProvider name="dstPrefixLength" defaultValue={0}>
                <SecondaryField
                  label="Prefix length"
                  element={<TextInput width="40px" disabled={!!route || !canWriteNetworkRoutes} />}
                />
              </NumberFieldProvider>
            </FieldContainer>

            <FieldContainer>
              <PrimaryField
                label="Gateway"
                element={null}
                controls={
                  hasStaticMappings && (
                    <FieldProvider name="gatewayKind">
                      <CompositeField
                        label="Gateway kind"
                        element={
                          <Select disabled={!!route || !canWriteNetworkRoutes}>
                            <SelectItem key={GatewayKind.IPAddress}>IP address</SelectItem>
                            <SelectItem key={GatewayKind.StaticMapping}>Static mapping</SelectItem>
                          </Select>
                        }
                      />
                    </FieldProvider>
                  )
                }
              />

              <FormikConditional<StaticRouteEditFormValues>
                condition={({ gatewayKind }) => gatewayKind === GatewayKind.StaticMapping}
              >
                <FieldProvider name="gatewayDHCPStaticMappingUUID">
                  <SecondaryField
                    label="Static mapping"
                    element={
                      <ComboBox
                        placeholder="Select a static mapping"
                        disabled={!!route || !canWriteNetworkRoutes}
                      >
                        {sortedVLANsWithRules.map((vlan) => (
                          <ComboBoxSection
                            title={
                              <HStack spacing={space(6)} wrap="no-wrap" align="center">
                                <Icon icon="vlan" />
                                {vlan.name}
                              </HStack>
                            }
                          >
                            {vlan.dhcpRule.staticMappings.map((staticMapping) => (
                              <ComboBoxItem
                                key={staticMapping.UUID}
                                textValue={labelForStaticMapping(staticMapping)}
                              >
                                {labelForStaticMapping(staticMapping)}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxSection>
                        ))}
                      </ComboBox>
                    }
                  />
                </FieldProvider>
              </FormikConditional>
              <FormikConditional<StaticRouteEditFormValues>
                condition={({ gatewayKind }) => gatewayKind === GatewayKind.IPAddress}
              >
                <FieldProvider name="gatewayIP">
                  <SecondaryField
                    label="IP address"
                    element={
                      <TextInput width="120px" disabled={!!route || !canWriteNetworkRoutes} />
                    }
                  />
                  <GatewayIPAlert vlans={vlans} />
                </FieldProvider>
              </FormikConditional>
            </FieldContainer>
          </DrawerContent>
          {canWriteNetworkRoutes && (
            <DrawerFooter
              actions={
                <>
                  <Button onClick={closeDrawer} variant="secondary">
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </>
              }
            />
          )}
        </Form>
      </Formik>
      {route && <DeleteStaticRouteDialog state={state} route={route} />}
    </Drawer>
  );
}

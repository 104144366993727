import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import NetworkLayout from './Network.layout';

// This is a bit of a hack.
// NetworkLayout is a layout that specifies a path that includes /:controller which does not exist for /network/:network routes.
// This lets us use the same layout as Network.layout without being on a /:controller route.
export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug',
  name: 'VirtualNetworkLayout',
  layout: 'AppLayout',
});

export default function VirtualNetworkLayout() {
  return <NetworkLayout />;
}

// This file is responsible for defining "Quick Views" for the Hub.
// A Quick View is a set of filter values + a group (by action item or location) + a view (list, map, or card)
// We define some preset Quick Views in the app and allow users to save their own.
import { DateTime } from 'luxon';

import type { ImmutableFilterValues } from './filters';

export type GroupAndViewInput =
  | {
      groupBy: 'location';
      view: 'list' | 'map' | 'card';
    }
  | {
      groupBy: 'action-item';
      view: 'list';
    };

export type QuickView = {
  name: string;
  id: string;
  filterValues: ImmutableFilterValues;
  groupAndView: GroupAndViewInput;
};

export const defaultQuickView: QuickView = {
  name: 'All networks',
  id: 'all_networks',
  groupAndView: {
    groupBy: 'location',
    view: 'card',
  },
  filterValues: {
    searchTerm: '',
    onlyShowNetworksWithActionItems: false,
    onlyShowNetworksWithIssues: false,
    jobStages: [],
    tags: [],
    targetGoLiveDate: {
      onOrAfter: null,
      onOrBefore: null,
    },
  },
};

function overdueActionItems(): ImmutableFilterValues {
  const today = DateTime.now();
  const tPlus4Weeks = today.plus({ weeks: 4 });
  return {
    searchTerm: '',
    onlyShowNetworksWithActionItems: true,
    onlyShowNetworksWithIssues: false,
    jobStages: ['install in progress', 'install prep', 'site survey', 'kickoff'],
    tags: [],
    targetGoLiveDate: {
      onOrAfter: today.toISODate(),
      onOrBefore: tPlus4Weeks.toISODate(),
    },
  };
}

export const quickViews = {
  allNetworks: defaultQuickView,
  overdueActionItems: {
    name: 'Overdue Action items',
    id: 'overdue_action_items',
    filterValues: overdueActionItems(),
    groupAndView: {
      groupBy: 'action-item',
      view: 'list',
    },
  },
} satisfies Record<string, QuickView>;

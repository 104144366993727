import { checkDefinedOrThrow } from '@meterup/common';

import Hotspot20NAIRealmEdit from '../../../../../components/Wireless/Hotspot20s/Hotspot20NAIRealmEdit';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/hotspot2.0/:uuid/nai-realm/edit',
  title: 'Edit NAI realm - Hotspot 2.0s - Wireless',
});

export default function Hotspot20NAIRealmEditPage() {
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.Hotspot20NAIRealmEditPage),
  );
  return <Hotspot20NAIRealmEdit uuid={uuid} />;
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import EditUserDrawer from '../../../../../components/Users/EditUserDrawer';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/org/users/:uuid/edit',
  title: 'Edit user - Users - Org',
});

export default function EditUserPage() {
  const { uuid } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.EditUserPage));

  return <EditUserDrawer uuid={uuid} />;
}

import {
  Body,
  Button,
  colors,
  ControlGroup,
  darkThemeSelector,
  fontWeights,
  shadows,
  styled,
} from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { useFormikContext } from 'formik';
import { chunk, difference } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';

import type { ValidRadioProfileParams } from './utils';
import { RadioBand } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useValidChannels } from '../../../hooks/useValidChannels';
import { DFS_CHANNELS } from '../utils';
import { radioBandToField } from './utils';

const ChannelTwenty = styled('div', Body, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '$2 $4',
  borderRadius: '99em',
  strokeAll: colors.strokeNeutralLight,
  color: colors.bodyNeutralLight,
  fontWeight: fontWeights.bold,
  cursor: 'pointer',

  [darkThemeSelector]: {
    strokeAll: colors.strokeNeutralDark,
    color: colors.bodyNeutralDark,
  },

  [selectors.hover]: {
    background: colors.bgApplicationLight,
    strokeAll: colors.strokeNeutralLight,
    color: colors.headingNeutralLight,

    [darkThemeSelector]: {
      background: colors.bgApplicationDark,
      strokeAll: colors.strokeNeutralDark,
      color: colors.headingNeutralDark,
    },
  },

  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    empty: {
      true: {
        opacity: 0,
        pointerEvents: 'none',
        userSelect: 'none',
      },
      false: {},
    },
    selected: {
      true: {
        background: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,
        color: colors.headingBrandLight,

        [darkThemeSelector]: {
          background: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
          color: colors.headingBrandDark,
        },

        [selectors.hover]: {
          background: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,
          color: colors.headingBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
            color: colors.headingBrandDark,
          },
        },
      },
      false: {},
    },
    primary: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      selected: true,
      primary: true,
      css: {
        background: colors.tokenBgBrandLight,
        strokeAll: colors.tokenStrokeBrandLight,
        color: colors.headingBrandLight,

        [darkThemeSelector]: {
          background: colors.tokenBgBrandDark,
          strokeAll: colors.tokenStrokeBrandDark,
          color: colors.headingBrandDark,
        },

        [selectors.hover]: {
          background: colors.tokenBgBrandLight,
          strokeAll: colors.tokenStrokeBrandLight,
          color: colors.headingBrandLight,

          [darkThemeSelector]: {
            background: colors.tokenBgBrandDark,
            strokeAll: colors.tokenStrokeBrandDark,
            color: colors.headingBrandDark,
          },
        },
      },
    },
  ],
});

const ChannelGroup = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  gap: '$4',
  width: '100%',
  borderRadius: '99em',

  variants: {
    enabled: {
      true: {
        strokeAll: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          strokeAll: colors.strokeNeutralDark,
        },

        [selectors.hover]: {
          background: colors.bgApplicationLight,

          [darkThemeSelector]: {
            background: colors.bgApplicationDark,
          },
        },

        [`& ${ChannelTwenty}:not([data-primary])`]: {
          background: colors.transparent,
          strokeAll: colors.transparent,

          [darkThemeSelector]: {
            background: colors.transparent,
            strokeAll: colors.transparent,
          },

          [selectors.focus]: {
            outline: 'none',
            boxShadow: shadows.focusRingLight,

            [darkThemeSelector]: {
              boxShadow: shadows.focusRingDark,
            },
          },
        },
      },
      false: {},
    },
    selected: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      enabled: true,
      selected: true,
      css: {
        background: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,

        [darkThemeSelector]: {
          background: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },

        [selectors.hover]: {
          background: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,
          color: colors.headingBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
            color: colors.headingBrandDark,
          },
        },
      },
    },
  ],
});

const ChannelForty = styled(ChannelGroup);
const ChannelEighty = styled(ChannelGroup);

const ChannelRows = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',
});

const ChannelSelector = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  gap: '$8',
  width: '100%',
});

type ChannelProps = {
  /**
   * The channel number.
   */
  children: React.ReactNode;
  /**
   * Whether the channel is disabled.
   */
  disabled?: boolean;
  /**
   * Whether the channel is empty.
   */
  empty?: boolean;
  /**
   * Whether the channel is the primary channel for the band.
   */
  primary?: boolean;
  /**
   * Whether the channel is selected.
   */
  selected?: boolean;
  /**
   * Callback when channel is clicked.
   */
  onClick?: () => void;
};

function Channel({
  children,
  disabled,
  empty,
  primary,
  selected,
  onClick,
  ...remaining
}: ChannelProps) {
  return (
    <ChannelTwenty
      tabIndex={!disabled ? 0 : undefined}
      data-primary={primary ? 'true' : undefined}
      empty={empty}
      primary={primary}
      selected={selected}
      {...remaining}
      onClick={onClick}
    >
      {children}
    </ChannelTwenty>
  );
}

export default function ChannelSelection({ band }: { band: RadioBand }) {
  const network = useNetwork();
  const validChannels = useValidChannels(network.UUID);
  const { values, setFieldValue } = useFormikContext<ValidRadioProfileParams>();

  const channelWidth = useMemo(() => {
    if (band === RadioBand.Band_2_4G || values.band5GAutoChannelWidthIsEnabled) {
      return 20;
    }

    return values.band5GChannelWidthMHz;
  }, [band, values]);

  const allValidChannels = useMemo(() => {
    if (band === RadioBand.Band_2_4G) return validChannels[RadioBand.Band_2_4G] ?? [];

    const fiveGChannels = validChannels[RadioBand.Band_5G] ?? [];

    return channelWidth === 20 ? fiveGChannels : fiveGChannels.filter((i) => i !== 165);
  }, [channelWidth, validChannels, band]);

  const channelsFieldName: 'band5GAllowedChannels' | 'band2_4GAllowedChannels' =
    `${radioBandToField(band)}AllowedChannels`;

  const [selectedChannels, setSelectedChannels] = React.useState<number[]>(
    values[channelsFieldName] ?? [],
  );

  const chunks = useMemo(
    () => chunk(allValidChannels, 4).map((c) => chunk(c, 2)),
    [allValidChannels],
  );

  const toggleChannels = (requiredChannelWidth: number, newChannels: number[]) => {
    if (channelWidth !== requiredChannelWidth) return;

    if (difference(newChannels, selectedChannels).length === 0) {
      setSelectedChannels((prev) => prev.filter((c) => !newChannels.includes(c)));
    } else {
      setSelectedChannels((prev) => [...prev, ...newChannels]);
    }
  };

  const channelsSelected = (channels: number[]) =>
    difference(channels, selectedChannels).length === 0;

  const deselectDFSChannels = () => {
    setSelectedChannels((prev) => prev.filter((c) => !DFS_CHANNELS.includes(c)));
  };

  const selectAllChannels = () => {
    setSelectedChannels(allValidChannels);
  };

  useEffect(() => {
    if (channelWidth !== 20) {
      setSelectedChannels((prev) => prev.filter((c) => c !== 165));
    }
  }, [channelWidth]);

  useEffect(() => {
    setFieldValue(channelsFieldName, selectedChannels);
  }, [setFieldValue, channelsFieldName, selectedChannels]);

  return (
    <ChannelSelector>
      <ChannelRows>
        {chunks.map((c) => (
          <ChannelEighty
            key={c.join('-')}
            enabled={channelWidth === 80}
            selected={channelsSelected(c.flat())}
            onClick={() => toggleChannels(80, c.flat())}
          >
            <ChannelForty
              enabled={channelWidth === 40}
              selected={channelsSelected(c[0].flat())}
              onClick={() => toggleChannels(40, c[0].flat())}
            >
              {c[0] && c[0][0] ? (
                <Channel
                  selected={channelsSelected([c[0][0]])}
                  onClick={() => toggleChannels(20, [c[0][0]])}
                >
                  {c[0][0]}
                </Channel>
              ) : (
                <Channel empty>Unused</Channel>
              )}
              {c[0] && c[0][1] ? (
                <Channel
                  selected={channelsSelected([c[0][1]])}
                  onClick={() => toggleChannels(20, [c[0][1]])}
                >
                  {c[0][1]}
                </Channel>
              ) : (
                <Channel empty>Unused</Channel>
              )}
            </ChannelForty>
            <ChannelForty
              enabled={channelWidth === 40}
              selected={channelsSelected((c[1] ?? []).flat())}
              onClick={() => toggleChannels(40, (c[1] ?? []).flat())}
            >
              {c[1] && c[1][0] ? (
                <Channel
                  selected={channelsSelected([c[1][0]])}
                  onClick={() => toggleChannels(20, [c[1][0]])}
                >
                  {c[1][0]}
                </Channel>
              ) : (
                <Channel empty>Unused</Channel>
              )}
              {c[1] && c[1][1] ? (
                <Channel
                  selected={channelsSelected([c[1][1]])}
                  onClick={() => toggleChannels(20, [c[1][1]])}
                >
                  {c[1][1]}
                </Channel>
              ) : (
                <Channel empty>Unused</Channel>
              )}
            </ChannelForty>
          </ChannelEighty>
        ))}
      </ChannelRows>
      <ControlGroup relation="separate" size="small" wrap="wrap">
        <Button variant="secondary" width="100%" onClick={() => selectAllChannels()}>
          Select all channels
        </Button>
        {band === RadioBand.Band_5G && (
          <Button variant="secondary" width="100%" onClick={() => deselectDFSChannels()}>
            Deselect DFS channels
          </Button>
        )}
      </ControlGroup>
    </ChannelSelector>
  );
}

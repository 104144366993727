import React from 'react';

import { AddClientDrawer } from '../../../../../components/Drawers/AddClientDrawer/AddClientDrawer';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/clients/add',
});

export default function AddClientPage() {
  return <AddClientDrawer />;
}

import {
  ComboBox,
  ComboBoxItem,
  EmptyState,
  Pane,
  PaneContent,
  PaneHeader,
  Tab,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { PowerDistributionUnitQueryQuery, VirtualDevice } from '../../../gql/graphql';
import { paths } from '../../../constants';
import { DeviceType, PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useHardwareCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { BootHistoryTable } from '../../Devices/Insights';
import { ObjectStatus } from '../../Object/ObjectStatus';
import IsPermitted from '../../permissions/IsPermitted';
import { StackedSkeletons } from '../../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../../ReactRouterLink';
import { useSiblings } from '../utils';
import PowerDistributionUnitActions from './PowerDistributionUnitActions';
import PowerDistributionUnitFilters from './PowerDistributionUnitFilters';
import PowerDistributionUnitInsights from './PowerDistributionUnitInsights';
import { PowerDistributionUnitOutlets } from './PowerDistributionUnitOutlets';
import {
  fallbackPowerDistributionUnitHardwareDeviceQuery,
  PowerDistributionUnitQuery,
  PowerDistributionUnitsQuery,
} from './utils';

type PowerDistributionUnitViewProps = {
  uuid: string;
  tab: string;
};

function PowerDistributionUnitTabs({
  activeTab,
  setActiveTab,
}: {
  activeTab: string;
  setActiveTab: (val: string) => void;
}) {
  return (
    <>
      <Tab
        icon="reporting"
        selected={activeTab === 'insights'}
        onClick={() => setActiveTab('insights')}
      >
        Insights
      </Tab>
      <Tab icon="outlet" selected={activeTab === 'outlets'} onClick={() => setActiveTab('outlets')}>
        Outlets
      </Tab>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
              !!nosFlags[NosFeature.POS],
          )
        }
      >
        <Tab
          selected={activeTab === 'boot-history'}
          onClick={() => setActiveTab('boot-history')}
          icon="log"
          internal
        >
          Boot history
        </Tab>
      </IsPermitted>
    </>
  );
}

function getPowerDistributionUnitStatus(
  virtualDevice: PowerDistributionUnitQueryQuery['virtualDevice'],
) {
  if (!virtualDevice.hardwareDevice) return undefined;

  return virtualDevice.hardwareDevice.isConnectedToBackend ? 'online' : 'offline';
}

function PowerDistributionUnitDetailView({ uuid, tab }: PowerDistributionUnitViewProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const hardwareCrumb = useHardwareCrumbs();
  const closeDrawer = useCloseDrawerCallback();
  const sortedSiblings = useSiblings({
    query: PowerDistributionUnitsQuery,
    deviceType: DeviceType.PowerDistributionUnit,
  });

  const navigateToSibling = useCallback(
    (newUUID: string) => {
      navigate(
        makeLink(paths.pages.PowerDistributionUnitDetailPage, {
          companyName,
          networkSlug: network.slug,
          uuid: newUUID,
          tab,
        }),
      );
    },
    [navigate, companyName, network.slug, tab],
  );

  const virtualDevice = useGraphQL(
    PowerDistributionUnitQuery,
    { uuid },
    { useErrorBoundary: false },
  ).data?.virtualDevice;
  const hardwareDeviceResponse = useGraphQL(
    fallbackPowerDistributionUnitHardwareDeviceQuery,
    { serialNumber: uuid },
    { useErrorBoundary: false, enabled: !virtualDevice },
  );

  if (!virtualDevice && hardwareDeviceResponse.isLoading) {
    // This won't be visible for more than an instant until the suspense boundary kicks in
    // (if at all)
    return null;
  }

  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Access point not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('Access point not found on this network');
  }
  const status = getPowerDistributionUnitStatus(virtualDevice);

  const navigateToTab = (newTab: string) => {
    if (newTab === 'insights') closeDrawer();
    navigate(
      makeLink(paths.pages.PowerDistributionUnitDetailPage, {
        companyName,
        networkSlug: network.slug,
        uuid,
        tab: newTab,
      }),
    );
  };

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...hardwareCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.PowerDistributionUnitListPage, {
                companyName,
                networkSlug: network.slug,
                tab: 'list',
              }),
              label: 'Power distribution units',
            },
          },
        ]}
        switcher={
          <ComboBox
            maxWidth="100%"
            icon="power-distribution-unit"
            value={virtualDevice.UUID}
            onValueChange={navigateToSibling}
            size="small"
          >
            {sortedSiblings.map((s: VirtualDevice) => (
              <ComboBoxItem key={s.UUID} textValue={s.label}>
                {s.label}
              </ComboBoxItem>
            ))}
          </ComboBox>
        }
        icon="power-distribution-unit"
        heading={virtualDevice.label || uuid}
        badges={<ObjectStatus size="small" status={status} />}
        tabs={<PowerDistributionUnitTabs activeTab={tab} setActiveTab={navigateToTab} />}
        contentActions={
          virtualDevice.hardwareDevice ? (
            <PowerDistributionUnitActions
              serialNumber={virtualDevice.hardwareDevice?.serialNumber}
              status={status}
              uuid={uuid}
              view="detail"
            />
          ) : undefined
        }
        views={tab === 'insights' ? <PowerDistributionUnitFilters /> : undefined}
      />
      <Suspense
        fallback={
          <PaneContent gutter="all">
            <StackedSkeletons />
          </PaneContent>
        }
      >
        <PaneContent>
          {tab === 'insights' &&
            (virtualDevice.hardwareDevice ? (
              <PowerDistributionUnitInsights
                serialNumber={virtualDevice.hardwareDevice.serialNumber}
              />
            ) : (
              <EmptyState heading="No insights available" />
            ))}
          {tab === 'outlets' && <PowerDistributionUnitOutlets virtualDeviceUUID={uuid} />}
          {tab === 'boot-history' &&
            (virtualDevice.hardwareDevice ? (
              <IsPermitted
                isPermitted={({ permissions, nosFlags }) =>
                  Boolean(
                    permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
                      !!nosFlags[NosFeature.POS],
                  )
                }
                should404OnAccessDenied
              >
                {virtualDevice.hardwareDevice?.bootHistory ? (
                  <BootHistoryTable bootHistory={virtualDevice.hardwareDevice.bootHistory} />
                ) : (
                  <EmptyState heading="No data available" />
                )}
              </IsPermitted>
            ) : (
              <EmptyState heading="No data available" />
            ))}
        </PaneContent>
      </Suspense>
    </Pane>
  );
}

export default PowerDistributionUnitDetailView;

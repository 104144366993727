import type { SortingState } from '@tanstack/react-table';
import {
  Box,
  Button,
  ControlGroup,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Icon,
  Pane,
  PaneContent,
  PaneFooter,
  PaneHeader,
  PrimaryField,
  space,
  styled,
  TextInput,
  ToggleInput,
  ToggleInputContainer,
  useDialogState,
} from '@meterup/atto';
import { AutoTable, expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';

import type { Network } from '../../../../hooks/useNetworkFromPath';
import { paths } from '../../../../constants';
import { graphql } from '../../../../gql';
import { ServiceType } from '../../../../gql/graphql';
import { useSearchParamsState } from '../../../../providers/SearchParamsStateProvider';
import DeleteDialog from '../../../Dialogs/DeleteDialog';
import { LocationSwitcher } from '../../../LocationSwitcher';
import { createColumnBuilder } from '../../../Table/createColumnBuilder';
import { networkQuery } from '../utils';

const updateServiceDiscoveryForNetworkMutation = graphql(`
  mutation updateServiceDiscoveryForNetwork($UUID: UUID!, $input: UpdateNetworkInput!) {
    updateNetwork(UUID: $UUID, input: $input) {
      UUID
    }
  }
`);

function getKeyByValue(value: string): string | undefined {
  // @ts-ignore
  return Object.keys(ServiceType).find((key) => ServiceType[key] === value);
}

const builder = createColumnBuilder<ServiceType>();

const ToggleInputWrapper = styled('div', {
  [`& ${ToggleInputContainer}`]: {
    display: 'inline',
  },
});
ToggleInputWrapper.displayName = 'ToggleInputWrapper';

export default function ServiceDiscovery({ network }: { network: Network }) {
  const addServiceDialog = useDialogState();
  const editServiceDialog = useDialogState();
  const deleteServiceDialog = useDialogState();

  const allServices = Object.values(ServiceType).filter((type) => type !== ServiceType.Unknown);
  expectDefinedOrThrow(
    network.enabledServiceDiscoveryTypes,
    new ResourceNotFoundError('Unable to load service types'),
  );

  const updateEnabledServices = useGraphQLMutation(updateServiceDiscoveryForNetworkMutation);

  const [servicesEnabled, setServicesEnabled] = useState({
    [ServiceType.Airplay]: network.enabledServiceDiscoveryTypes.includes(ServiceType.Airplay),
    [ServiceType.Printer]: network.enabledServiceDiscoveryTypes.includes(ServiceType.Printer),
    [ServiceType.Speaker]: network.enabledServiceDiscoveryTypes.includes(ServiceType.Speaker),
  });

  const resetServices = useCallback(() => {
    setServicesEnabled({
      [ServiceType.Airplay]: network.enabledServiceDiscoveryTypes.includes(ServiceType.Airplay),
      [ServiceType.Printer]: network.enabledServiceDiscoveryTypes.includes(ServiceType.Printer),
      [ServiceType.Speaker]: network.enabledServiceDiscoveryTypes.includes(ServiceType.Speaker),
    });
  }, [network.enabledServiceDiscoveryTypes]);

  const columns = useMemo(
    () => [
      // @ts-ignore
      builder.data((service) => (servicesEnabled[service] ? 1 : 0), {
        id: 'service-discovery-enabled',
        enableGlobalFilter: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        header: () => <Icon icon="question" size={space(16)} />,
        meta: {
          alignment: 'center',
          width: 40,
          tooltip: {
            contents: 'Discoverable',
          },
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <ToggleInputWrapper>
            <ToggleInput
              aria-label="Service type enabled"
              // @ts-ignore
              selected={servicesEnabled[row]}
              controlSize="small"
              onChange={(isSelected) => {
                setServicesEnabled((prev) => ({
                  ...prev,
                  [row]: isSelected,
                }));
              }}
            />
          </ToggleInputWrapper>
        ),
      }),
      builder.data((service) => getKeyByValue(service), {
        id: 'service-discovery-name',
        header: 'Name',
      }),
      builder.data((service) => getKeyByValue(service), {
        id: 'service-discovery-controls',
        header: 'Controls',
        meta: {
          alignment: 'end',
          width: space(80),
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: () => (
          <ControlGroup size="small" variant="secondary">
            <Button onClick={editServiceDialog.state.open} arrangement="hidden-label" icon="pencil">
              Edit
            </Button>
            <Button
              onClick={deleteServiceDialog.state.open}
              arrangement="hidden-label"
              icon="trash-can"
            >
              Delete
            </Button>
          </ControlGroup>
        ),
      }),
    ],
    [servicesEnabled, deleteServiceDialog.state.open, editServiceDialog.state.open],
  );

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(() => {
    updateEnabledServices.mutate(
      {
        UUID: network.UUID,
        input: {
          enabledServiceDiscoveryTypes: Object.keys(servicesEnabled)
            .filter((key) => servicesEnabled[key as keyof typeof servicesEnabled])
            .map((key) => key as ServiceType),
        },
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(makeQueryKey(networkQuery, { uuid: network.UUID }));
          notify('Successfully updated service discovery.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem updating service discovery${getGraphQLErrorMessageOrEmpty(err)}.`,
          );
        },
      },
    );
  }, [updateEnabledServices, network.UUID, servicesEnabled, queryClient]);

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  return (
    <>
      <Pane contentMode="fit" variant="adjusted">
        <PaneHeader
          icon="search-service"
          heading="Service discovery"
          actions={
            <Button
              onClick={addServiceDialog.state.open}
              arrangement="leading-icon"
              icon="plus"
              variant="secondary"
            >
              Add service
            </Button>
          }
        />
        <PaneContent>
          <Box padding={{ x: space(24), y: space(16) }}>
            <LocationSwitcher
              networkSlug={network.slug}
              shouldNotChangePages
              routePathsNotToChange={[paths.pages.SettingsNetworkServiceDiscoveryPage]}
            />
          </Box>
          <AutoTable
            columns={columns}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            data={allServices}
          />
        </PaneContent>
        <PaneFooter
          actions={
            <>
              <Button type="button" onClick={resetServices} variant="secondary">
                Reset
              </Button>
              <Button type="button" onClick={handleSubmit} variant="primary">
                Save
              </Button>
            </>
          }
        />
      </Pane>
      <Dialog preset="narrow" state={addServiceDialog.state}>
        <DialogHeader icon="plus" heading="Add service" />
        <DialogContent gutter="all">
          <PrimaryField label="Service name" element={<TextInput />} />
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button type="button" onClick={addServiceDialog.state.close} variant="secondary">
                Cancel
              </Button>
              <Button type="button" onClick={addServiceDialog.state.close} variant="primary">
                Add
              </Button>
            </>
          }
        />
      </Dialog>
      <Dialog preset="narrow" state={editServiceDialog.state}>
        <DialogHeader icon="pencil" heading="Edit service" />
        <DialogContent gutter="all">
          <PrimaryField label="Service name" element={<TextInput />} />
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button type="button" onClick={editServiceDialog.state.close} variant="secondary">
                Cancel
              </Button>
              <Button type="button" onClick={editServiceDialog.state.close} variant="primary">
                Save
              </Button>
            </>
          }
        />
      </Dialog>
      <DeleteDialog
        state={deleteServiceDialog.state}
        handleDelete={() => {}}
        label="SERVICE NAME HERE"
        alert={{
          heading: 'Delete service',
          copy: 'Are you sure you want to delete this service?',
        }}
      />
    </>
  );
}

import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  space,
  VStack,
} from '@meterup/atto';
import { checkDefinedOrThrow, notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { capitalize } from 'lodash-es';
import React, { useState } from 'react';

import type { ValidCreateRadioProfileParams } from './utils';
import { RadioBand } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetworkUUID } from '../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../utils/withZodSchema';
import { BandSection, NameField } from './fields';
import {
  createRadioProfileInputSchema,
  CreateRadioProfileMutation,
  RadioProfilesQuery,
} from './utils';

export default function RadioProfileCreateDrawer() {
  const closeDrawer = useCloseDrawerCallback();
  const networkUUID = checkDefinedOrThrow(useNetworkUUID());
  const [errorText, setErrorText] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const createRadioProfileMutation = useGraphQLMutation(CreateRadioProfileMutation);

  return (
    <Drawer>
      <Formik<ValidCreateRadioProfileParams>
        initialValues={{
          name: '',
          band2_4GIsEnabled: true,
          band2_4GAutoChannelIsEnabled: false,
          band2_4GAutoChannelWidthIsEnabled: false,
          band2_4GAutoTxPowerIsEnabled: false,
          band2_4GAutoTxPowerMindBm: undefined,
          band2_4GAutoTxPowerMaxdBm: undefined,
          band2_4GChannelWidthMHz: 20,
          band5GIsEnabled: false,
          band5GAutoChannelIsEnabled: false,
          band5GAutoChannelWidthIsEnabled: false,
          band5GAutoTxPowerIsEnabled: false,
          band5GAutoTxPowerMindBm: undefined,
          band5GAutoTxPowerMaxdBm: undefined,
          band5GChannelWidthMHz: 20,
          isDefault: false,
        }}
        validate={withZodSchema(createRadioProfileInputSchema)}
        onSubmit={(values) => {
          setErrorText(null);
          const variables = { networkUUID, input: values };
          createRadioProfileMutation.mutate(variables, {
            onError: (error) => {
              const gqlError = getGraphQLError(error);
              setErrorText(capitalize(gqlError?.message ?? 'Unknown error'));
            },
            onSuccess: () => {
              queryClient.invalidateQueries(makeQueryKey(RadioProfilesQuery, { networkUUID }));
              closeDrawer();
              notify(`Successfully created radio profile.`, {
                variant: 'positive',
              });
            },
          });
        }}
      >
        <Form>
          <DrawerHeader icon="radio-profile" heading="Add radio profile" onClose={closeDrawer} />
          <DrawerContent gutter="vertical">
            <Sections>
              <Section relation="stacked">
                <SectionHeader icon="information" heading="Details" />
                <SectionContent gutter="all">
                  <VStack spacing={space(16)}>
                    <NameField />
                  </VStack>
                </SectionContent>
              </Section>

              <BandSection band={RadioBand.Band_2_4G} />
              <BandSection band={RadioBand.Band_5G} />

              {errorText && (
                <Alert
                  heading="Error creating profile"
                  copy={errorText}
                  variant="negative"
                  relation="stacked"
                />
              )}
            </Sections>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={createRadioProfileMutation.isLoading}
                  loading={createRadioProfileMutation.isLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

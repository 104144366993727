import type { FallbackRenderer } from '@meterup/authorization';
import React from 'react';

import { useIsCurrentCompanyAdmin } from '../../hooks/authorization';

type IsCurrentCompanyAdminProps = {
  children: React.ReactNode;
} & FallbackRenderer;

export default function IsCurrentCompanyAdmin({ children, fallback }: IsCurrentCompanyAdminProps) {
  const hasRole = useIsCurrentCompanyAdmin();

  if (!hasRole) {
    if (fallback) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{fallback}</>;
    }
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

import type { RoutesType } from 'connect-frontend/src/dashboard/utils/routes';
import { PortalOrigin } from '@meterup/connect-api';
import { getConnectUrl } from '@meterup/connect-api/src/config';

import { pages } from '../generated/pages';
import { makeLink } from './main_and_drawer_navigation';

export function makeConnectLink(defaultParams: Record<string, any>) {
  return function buildLink(opts: RoutesType): string {
    const { name, params } = opts;

    if (name === 'results') {
      return getConnectUrl(
        document.location,
        PortalOrigin.Connect,
        `/quoteRequests/${params.quoteRequestSID}/results`,
      );
    }
    const { companySID, ...restParams } = params;

    let mappedName: string = name;
    let mappedParams: Record<string, any> = {
      companyName: companySID,
    };
    if (name === 'connection') {
      mappedName = pages.ConnectDashboardLocationPage;
    } else if (name === 'dashboard') {
      mappedName = pages.ConnectDashboardAllLocationsPage;
    }
    mappedParams = { ...defaultParams, ...mappedParams, ...restParams };

    return makeLink(mappedName, mappedParams);
  };
}

import type { FormikHelpers } from 'formik';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  PrimaryField,
  TextInput,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { Suspense, useCallback } from 'react';
import { z } from 'zod';

import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../utils/withZodSchema';
import { FieldProvider } from '../Form/FieldProvider';
import { HardwareDeviceInfoFetcher } from './Fields';
import { assignHardwareDeviceToNetwork, hardwareDeviceQuery } from './utils';

const assignHardwareDeviceFields = z.object({
  serialNumber: z.string(),
});

type AssignHardwareDeviceFields = z.infer<typeof assignHardwareDeviceFields>;

function HardwareDeviceInfoProvider() {
  const { values } = useFormikContext<{ serialNumber: string }>();

  return <HardwareDeviceInfoFetcher serialNumber={values.serialNumber} />;
}

export default function AssignHardwareDeviceToNetwork() {
  const network = useNetwork();
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();

  const assignHardwareDeviceToNetworkMutation = useGraphQLMutation(assignHardwareDeviceToNetwork);

  const handleSubmit = useCallback(
    (
      values: AssignHardwareDeviceFields,
      { resetForm }: FormikHelpers<AssignHardwareDeviceFields>,
    ) => {
      if (assignHardwareDeviceToNetworkMutation.isLoading) return;

      assignHardwareDeviceToNetworkMutation.mutate(
        {
          serialNumber: values.serialNumber,
          networkUUID: network.UUID,
        },
        {
          onError: (error) => {
            const graphqlError = getGraphQLError(error);
            notify(
              `There was a problem adding the hardware device${
                graphqlError ? `: ${graphqlError.message}` : ''
              }.`,
              { variant: 'negative' },
            );
          },
          onSuccess: (data) => {
            const { virtualDevice } = data.assignHardwareDeviceToNetwork;

            if (virtualDevice) {
              resetForm();
              queryClient.invalidateQueries(
                makeQueryKey(hardwareDeviceQuery, { serialNumber: values.serialNumber }),
              );
              notify(`Hardware assigned to virtual device ${virtualDevice.UUID}.`, {
                variant: 'positive',
              });
            } else {
              // This shouldn't happen
              notify('Hardware assigned but no virtual device found, something went wrong.', {
                variant: 'negative',
              });
            }
          },
        },
      );
    },
    [network.UUID, assignHardwareDeviceToNetworkMutation, queryClient],
  );

  return (
    <Drawer>
      <Formik<AssignHardwareDeviceFields>
        validate={withZodSchema(assignHardwareDeviceFields)}
        initialValues={{
          serialNumber: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            icon="virtual-device"
            heading="Assign hardware to network"
            onClose={closeDrawer}
          />
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="serialNumber">
                <PrimaryField label="Serial number" element={<TextInput />} />
              </FieldProvider>
            </FieldContainer>
            <Suspense fallback={null}>
              <HardwareDeviceInfoProvider />
            </Suspense>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button variant="secondary" onClick={closeDrawer}>
                  Cancel
                </Button>

                <Button type="submit">Save</Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import type { MouseEventHandler } from 'react';
import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  space,
  Text,
  VStack,
} from '@meterup/atto';
import React from 'react';

export default function DeleteDialog({
  alert,
  content,
  handleDelete,
  label,
  state,
}: {
  alert?: {
    heading: React.ReactNode;
    copy: React.ReactNode;
  };
  content?: React.ReactNode;
  handleDelete: MouseEventHandler<HTMLButtonElement>;
  label: React.ReactNode;
  state: OverlayTriggerState;
}) {
  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading={`Delete ${label}`} />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>
          {alert && (
            <Alert icon="warning" variant="attention" heading={alert.heading} copy={alert.copy} />
          )}
          <Body>
            Are you sure you want to delete <Text weight="bold">{label}</Text>? This action cannot
            be undone.
          </Body>
        </VStack>
        {content}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button variant="secondary" onClick={state.close}>
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

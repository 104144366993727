import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  space,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { enableContentFilterForController } from '../../../../../api/api';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/dns-security/enable',
});

export default function LegacyDNSSecurityEnablePage() {
  const companyName = useCurrentCompany();
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const queryClient = useQueryClient();

  const closeDrawer = useCloseDrawerCallback();

  const enableFilteringMutation = useMutation(
    () => enableContentFilterForController(controllerName),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['dns_security', companyName]);
        closeDrawer();
        notify('DNS security enabled', { variant: 'positive' });
      },
      onError: () => {
        notify('Failed to enable DNS security', { variant: 'negative' });
      },
    },
  );

  return (
    <Drawer>
      <DrawerHeader
        icon="dns-security"
        heading="Enable DNS security"
        onClose={useCloseDrawerCallback()}
      />
      <DrawerContent>
        <Alert
          icon="information"
          variant="alternative"
          heading="Changing network settings"
          copy={
            <VStack spacing={space(8)}>
              <div>
                Enabling DNS security will update DNS settings and cause the network to lose
                connectivity for approximately 30 seconds.
              </div>
              <div>Please confirm you would like to enable DNS security now.</div>
            </VStack>
          }
        />
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button onClick={closeDrawer} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => enableFilteringMutation.mutate()}>Enable</Button>
          </>
        }
      />
    </Drawer>
  );
}

import { Alert, Button, Column, Columns, sizing, styled } from '@meterup/atto';
import { formatBytes, useLocalStorage } from '@meterup/common';
import * as d3 from 'd3';

import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import TimeSeriesChart from '../../Charts/TimeSeriesChart';
import { ISPConnect, ISPIPConfiguration, ISPMetadata } from './ISPUnits';
import { useISP } from './utils';

const SingularISPAlert = styled('div', {
  padding: sizing.squish,
  width: '100%',

  variants: {
    isDismissed: {
      true: {
        display: 'none',
      },
    },
  },
});

export default function ISPInsights({ ispUUID }: { ispUUID: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isp = useISP({ companySlug: companyName, networkUUID: network.UUID, UUID: ispUUID });

  const [dismissSingularISPAlert, setDismissSingularISPAlert] = useLocalStorage(
    'dashboard.isps.singularAlert.dismissed',
    false,
  );

  return (
    <>
      <SingularISPAlert isDismissed={dismissSingularISPAlert}>
        <Alert
          icon="attention"
          copy="Your Meter Network currently does not have a backup ISP. For redundancy and optimal performance, we recommend having a backup connection."
          variant="attention"
          dismissOnClick={() => setDismissSingularISPAlert(true)}
          isDismissed={dismissSingularISPAlert}
          trailingButtons={
            <Button
              onClick={() =>
                window.open(
                  `mailto:connect@meter.com?subject=Backup ISP for ${companyName}`,
                  '_blank',
                )
              }
              arrangement="leading-icon"
              icon="email"
              variant="primary"
            >
              Get ISP quotes
            </Button>
          }
        />
      </SingularISPAlert>
      <Columns scroll="none" template="wide-narrow">
        <Column gutter="vertical">
          <TimeSeriesChart
            curve={d3.curveStep}
            title="Throughput"
            tooltipBody="Throughput"
            valueFormatter={formatBytes}
            series={[]}
            seriesType="line"
            seriesLabel="isp-insights-throughput"
            timePeriod="1h"
          />
          <TimeSeriesChart
            curve={d3.curveStep}
            title="Uptime"
            tooltipBody="Uptime"
            valueFormatter={formatBytes}
            series={[]}
            seriesType="line"
            seriesLabel="isp-insights-uptime"
            timePeriod="1h"
          />
        </Column>
        <Column gutter="vertical">
          <ISPMetadata isp={isp} />
          <ISPConnect />
          <ISPIPConfiguration isp={isp} />
        </Column>
      </Columns>
    </>
  );
}

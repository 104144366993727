import IsOperator from '@meterup/authorization/src/components/IsOperator';
import { Suspense } from 'react';

import AssignHardwareDeviceToNetwork from '../../../../../components/Devices/AssignHardwareDeviceToNetwork';
import { DrawerLoadingFallback } from '../../../../../components/Placeholders/DrawerLoadingFallback';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/rack-elevations/attach-hardware',
  title: 'Attach hardware - Rack elevations - Design',
});

export default function AssignHardwareToNetworkPage() {
  return (
    <IsOperator>
      <Suspense fallback={<DrawerLoadingFallback />}>
        <AssignHardwareDeviceToNetwork />
      </Suspense>
    </IsOperator>
  );
}

import {
  Alert,
  Button,
  CompositeField,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  PrimaryField,
  SecondaryField,
  SecondaryFieldComposite,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import type { CreateEncryption8021XInput } from '../../../gql/graphql';
import type { ValidCreateRadiusProfileParams } from '../../Wireless/SSIDs/SSIDsUtils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider, NumberFieldProvider } from '../../Form/FieldProvider';
import {
  CreateRadiusProfileInputSchema,
  CreateRadiusProfileMutation,
  RadiusProfilesQuery,
} from '../../Wireless/SSIDs/SSIDsUtils';

export default function RADIUSProfileCreateDrawer() {
  const closeDrawer = useCloseDrawerCallback();
  const network = useNetwork();
  const [showAuthPassword, setShowAuthPassword] = useState(false);
  const [showAcctPassword, setShowAcctPassword] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const createRadiusProfileMutation = useGraphQLMutation(CreateRadiusProfileMutation);

  return (
    <Drawer>
      <Formik<ValidCreateRadiusProfileParams>
        initialValues={{
          label: '',
          networkUUID: network.UUID,
          authServerIPAddress: '',
          authServerPort: 1812,
          authServerSecret: '',
          accountingServerIPAddress: '',
          accountingServerPort: 1813,
          accountingServerSecret: '',
        }}
        validate={withZodSchema(CreateRadiusProfileInputSchema)}
        onSubmit={(values) => {
          setErrorText(null);

          const input: CreateEncryption8021XInput = values;

          if (input.accountingServerIPAddress === '') {
            delete input.accountingServerIPAddress;
            delete input.accountingServerPort;
            delete input.accountingServerSecret;
          }

          createRadiusProfileMutation.mutate(
            { input },
            {
              onError: (error) => {
                const gqlError = getGraphQLError(error);
                setErrorText(gqlError?.message ?? 'Unknown error');
              },
              onSuccess: () => {
                queryClient.invalidateQueries(
                  makeQueryKey(RadiusProfilesQuery, { networkUUID: network.UUID }),
                );
                closeDrawer();
                notify(`Successfully created RADIUS profile "${values.label}".`, {
                  variant: 'positive',
                });
              },
            },
          );
        }}
      >
        <Form>
          <DrawerHeader icon="radius" heading="Add RADIUS profile" onClose={closeDrawer} />
          <DrawerContent>
            <VStack spacing={space(16)}>
              <FieldProvider name="label">
                <PrimaryField element={<TextInput />} label="Label" />
              </FieldProvider>

              <FieldContainer>
                <PrimaryField element={null} label="Authentication server" />
                <FieldProvider name="authServerIPAddress">
                  <SecondaryField element={<TextInput />} label="IP address" />
                </FieldProvider>

                <NumberFieldProvider name="authServerPort" defaultValue={null}>
                  <SecondaryField element={<TextInput type="number" />} label="Port" />
                </NumberFieldProvider>

                <SecondaryFieldComposite
                  label="Secret"
                  fields={
                    <>
                      <FieldProvider name="authServerSecret">
                        <CompositeField
                          element={<TextInput type={showAuthPassword ? 'text' : 'password'} />}
                          label="Secret"
                        />
                      </FieldProvider>
                      <Button
                        variant="secondary"
                        arrangement="hidden-label"
                        icon={showAuthPassword ? 'eye-closed' : 'eye-open'}
                        size="small"
                        onClick={() => setShowAuthPassword(!showAuthPassword)}
                      >
                        Show password
                      </Button>
                    </>
                  }
                />
              </FieldContainer>

              <FieldContainer>
                <PrimaryField element={null} label="Accounting server" />
                <FieldProvider name="accountingServerIPAddress">
                  <SecondaryField element={<TextInput />} label="IP address" />
                </FieldProvider>

                <NumberFieldProvider name="accountingServerPort" defaultValue={null}>
                  <SecondaryField element={<TextInput type="number" />} label="Port" />
                </NumberFieldProvider>

                <SecondaryFieldComposite
                  label="Secret"
                  fields={
                    <>
                      <FieldProvider name="accountingServerSecret">
                        <CompositeField
                          element={<TextInput type={showAcctPassword ? 'text' : 'password'} />}
                          label="Secret"
                        />
                      </FieldProvider>
                      <Button
                        variant="secondary"
                        arrangement="hidden-label"
                        icon={showAcctPassword ? 'eye-closed' : 'eye-open'}
                        size="small"
                        onClick={() => setShowAcctPassword(!showAcctPassword)}
                      >
                        Show password
                      </Button>
                    </>
                  }
                />
              </FieldContainer>

              {errorText && (
                <Alert
                  heading="Error creating RADIUS profile"
                  copy={errorText}
                  variant="negative"
                />
              )}
            </VStack>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={createRadiusProfileMutation.isLoading}
                  loading={createRadiusProfileMutation.isLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

/* eslint-disable import/no-cycle */
import React, { useCallback, useMemo } from 'react';

import { Body, Icon } from '@meterup/atto';
import type {
  Address,
  AxiosAPIError,
  CompanyLocationResponse,
  QuoteRequest,
  QuoteRequestCreateRequest,
  QuotesRequest,
} from '@meterup/connect-api';
import { get, post } from '@meterup/connect-api/src/axios';
import useFormatters from '@meterup/connect-ui/src/hooks/useFormatters';
import { logError } from '@meterup/connect-ui/src/Log.utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { route } from '../../../routes';
import useCompanySlug from '../../hooks/useCompanySlug';
import {
  DetailBoxWrapper,
  PlainSection,
  TitleBar,
  TitleBarComponentLeft,
  TitleBarComponentMain,
  TitleBarComponentRight,
} from './styles';

function genRoute(sid: string, { address1, address2, city, state, postalCode }: Address): string {
  if (sid && sid.length > 0) {
    return `/quoteRequests/${sid}/results`;
  }
  const params = new URLSearchParams();
  params.set('address', [address1, address2, city, state, postalCode].filter((x) => x).join(', '));
  return `${route('location')}?${params.toString()}`;
}

type SimpleQuoteType = { monthlyFeeCents: number; quoteRequestSid: string };

type EmptyContractDetailBoxProps = {
  companyLocation: CompanyLocationResponse;
  isPrimary?: boolean;
  quoteRequestSID: string;
};

type SimpleQuotesRequestType = {
  quotes: SimpleQuoteType[];
};

export function EmptyContractDetailBox({
  companyLocation,
  isPrimary = false,
  quoteRequestSID,
}: EmptyContractDetailBoxProps) {
  const { centsFormatter } = useFormatters();
  const companySlug = useCompanySlug();
  const { data } = useQuery<unknown, AxiosAPIError, QuotesRequest>(
    ['quotes', { sid: quoteRequestSID }],
    () => get<QuotesRequest>(`v1/connect/quote-requests/${quoteRequestSID}/quotes`),
    {
      enabled: !!quoteRequestSID,
    },
  );
  const minQuote = useMemo<SimpleQuoteType>(() => {
    const quotesLen = data?.quotes.length || 0;
    const emptyMinQuote = {
      monthlyFeeCents: -1,
      quoteRequestSid: '',
    };
    if (quotesLen <= 0 || !data) {
      return emptyMinQuote;
    }
    let currentMinQuote = { ...emptyMinQuote };
    data!.quotes.forEach((quote: SimpleQuoteType) => {
      if (
        currentMinQuote.monthlyFeeCents <= 0 ||
        (quote.monthlyFeeCents > 0 && quote.monthlyFeeCents < currentMinQuote.monthlyFeeCents)
      ) {
        currentMinQuote = quote;
      }
    });
    return currentMinQuote;
  }, [data]);
  const queryClient = useQueryClient();
  const createQuoteMutation = useMutation<QuoteRequest, AxiosAPIError, QuoteRequestCreateRequest>(
    (vars) => post('v1/connect/quote-requests', vars),
    {
      onSuccess: (result) => {
        const resultAsSimpleQuote: SimpleQuoteType = {
          monthlyFeeCents: 0,
          quoteRequestSid: result.sid,
        };
        queryClient.setQueryData<SimpleQuotesRequestType>(
          ['quotes', { sid: quoteRequestSID }],
          (old) => {
            if (!old) {
              return { quotes: [resultAsSimpleQuote] };
            }

            return {
              quotes: [...old.quotes, resultAsSimpleQuote],
            };
          },
        );
      },
    },
  );
  const { address, point } = companyLocation.location || {};

  const onClickAddISP = useCallback(async () => {
    if (minQuote.quoteRequestSid.length > 0) {
      return true;
    }

    if (!address || !point) {
      return true;
    }

    const { address1, address2, city, state, postalCode } = address;
    const { latitude, longitude } = point;

    try {
      const result = await createQuoteMutation.mutateAsync({
        // @ts-ignore
        companyName: companySlug,
        contactName: '',
        contactEmail: '',
        contactTelephone: '',
        requestedDownloadKbps: 0,
        requestedUploadKbps: 0,
        contractMinimumMonths: 0,
        notes: '',
        existingMonthlyFeeCents: 0,
        existingProviderSid: '',
        location: {
          address1,
          address2,
          city,
          state,
          postalCode,
          latitude,
          longitude,
        },
      });
      window.open(genRoute(result.sid, address));
      return false;
    } catch (err) {
      logError(err);
      return true;
    }
  }, [address, companySlug, createQuoteMutation, minQuote.quoteRequestSid.length, point]);
  const label = useMemo<string>(() => {
    const centsFormatted =
      minQuote.monthlyFeeCents > 0 ? centsFormatter(minQuote.monthlyFeeCents) : undefined;
    const statusLabel = isPrimary ? 'Primary' : 'Secondary';

    if (centsFormatted) {
      return `${statusLabel} connection, starting at ${centsFormatted}`;
    }

    return `Add a ${statusLabel.toLocaleLowerCase()} connection`;
  }, [centsFormatter, isPrimary, minQuote.monthlyFeeCents]);

  return (
    <DetailBoxWrapper>
      {/* @ts-ignore */}
      <Link to={genRoute(quoteRequestSID, address)} target="_blank" onClick={onClickAddISP}>
        <PlainSection>
          <TitleBar>
            <TitleBarComponentLeft>+</TitleBarComponentLeft>
            <TitleBarComponentMain>
              <Body weight="bold">Add ISP</Body>
              <Body>{label}</Body>
            </TitleBarComponentMain>
            <TitleBarComponentRight>
              <Body>
                Recommendations <Icon icon="chevron-right" size={12} />
              </Body>
            </TitleBarComponentRight>
          </TitleBar>
        </PlainSection>
      </Link>
    </DetailBoxWrapper>
  );
}

import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { range } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import type { RackElevationDeviceType, UpdateRackElevationDeviceInput } from '../../../gql/graphql';
import type { RackElevation, RackElevationDevice, ValidAttachmentParams } from './utils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { withZodSchema } from '../../../utils/withZodSchema';
import DeleteMountDialog from './DeleteMountDialog';
import { FieldTypeFields, LabelField, SlotsFields } from './Fields';
import {
  calculateAvailableIndexes,
  getInitialStartIndex,
  mutationErrorMessage,
  rackElevationQuery,
  rackElevationsQuery,
  updateRackElevationDeviceMutation,
  useAvailableDevices,
  validAttachmentParams,
} from './utils';

export default function EditMountDrawer({
  elevation,
  device,
}: {
  elevation: RackElevation;
  device: RackElevationDevice;
}) {
  const close = useCloseDrawerCallback();
  const queryClient = useQueryClient();
  const devices = useAvailableDevices(elevation.networkUUID, device.virtualDeviceUUID ?? '');
  const deleteDialogState = useDialogState();

  const deviceIndexes = useMemo(() => device.rackMountUnitIndexes, [device]);
  const availableIndexes = useMemo(
    () => calculateAvailableIndexes(elevation, deviceIndexes),
    [elevation, deviceIndexes],
  );
  const updateMutation = useGraphQLMutation(updateRackElevationDeviceMutation);

  const handleSubmit = useCallback(
    (v: ValidAttachmentParams) => {
      const rackMountUnitIndexes =
        v.startIndex === v.endIndex ? [v.startIndex] : range(v.startIndex, v.endIndex + 1);

      const input: UpdateRackElevationDeviceInput = {
        rackMountUnitIndexes,
        label: v.label,
      };

      if (!device.virtualDeviceUUID) {
        input.type = v.type as RackElevationDeviceType;
      }

      updateMutation.mutate(
        {
          UUID: device.UUID,
          input,
        },
        {
          onSuccess: () => {
            notify('Successfully updated rack elevation device.', { variant: 'positive' });
            queryClient.invalidateQueries(
              makeQueryKey(rackElevationsQuery, { networkUUID: elevation.networkUUID }),
            );
            queryClient.invalidateQueries(makeQueryKey(rackElevationQuery, { UUID: device.UUID }));
            close();
          },
          onError: (error) => {
            notify(
              mutationErrorMessage('There was an error updating this rack elevation device', error),
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [
      device.UUID,
      elevation.networkUUID,
      device.virtualDeviceUUID,
      queryClient,
      updateMutation,
      close,
    ],
  );

  return (
    <Drawer>
      <Formik<ValidAttachmentParams>
        validate={withZodSchema(validAttachmentParams)}
        enableReinitialize
        initialValues={{
          fieldType: device.virtualDeviceUUID ? 'meter_device' : 'external_device',
          endIndex: deviceIndexes[deviceIndexes.length - 1] ?? 1,
          startIndex:
            getInitialStartIndex(deviceIndexes[deviceIndexes.length - 1] ?? 1, deviceIndexes) ?? 1,
          type: (device.type as RackElevationDeviceType) ?? '',
          label: device.label ?? '',
          virtualDeviceUUID: device.virtualDeviceUUID ?? '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            icon="pencil"
            heading="Edit mount"
            actions={
              <>
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem onSelect={deleteDialogState.openFromMenu} icon="trash-can">
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
                <DeleteMountDialog
                  uuid={elevation.UUID}
                  device={device}
                  state={deleteDialogState.state}
                />
              </>
            }
            onClose={close}
          />
          <DrawerContent>
            <LabelField />
            <FieldTypeFields elevation={elevation} devices={devices} action="update" />
            <SlotsFields deviceIndexes={deviceIndexes} availableIndexes={availableIndexes} />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button variant="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

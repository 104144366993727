import type { BadgeGroupProps, BadgeVariant } from '@meterup/atto';
import { Badge, BadgeGroup } from '@meterup/atto';
import { Tooltip } from '@meterup/common';
import React from 'react';

type ObjectStatusPropStatus =
  | 'attention'
  | 'backup'
  | 'connected'
  | 'enabled'
  | 'error'
  | 'disabled'
  | 'disconnected'
  | 'draft'
  | 'high'
  | 'low'
  | 'medium'
  | 'offline'
  | 'online'
  | 'primary'
  | 'upgrading'
  | 'wired'
  | 'ready'
  | 'active'
  | React.ReactNode;

type ObjectStatusPropLifecycle =
  | 'active'
  | 'backup'
  | 'disabled'
  | 'disconnected'
  | 'error'
  | 'enabled'
  | 'hidden'
  | 'inactive'
  | 'pending'
  | 'primary'
  | 'waiting'
  | 'wired'
  | 'visible'
  | 'upgrading'
  | React.ReactNode;

const getObjectStatusVariant = (status: ObjectStatusPropStatus): BadgeVariant => {
  switch (status) {
    case 'connected':
    case 'enabled':
    case 'online':
    case 'primary':
    case 'active':
      return 'positive';
    case 'error':
    case 'disabled':
    case 'disconnected':
    case 'high':
    case 'offline':
      return 'negative';
    case 'attention':
    case 'medium':
      return 'attention';
    default:
      return 'neutral';
  }
};

const getObjectStatusLifecycleVariant = (lifecycle: ObjectStatusPropLifecycle): BadgeVariant => {
  switch (lifecycle) {
    case 'active':
    case 'visible':
    case 'primary':
      return 'brand';
    default:
      return 'neutral';
  }
};

const getObjectStatusStatusLabel = (status: ObjectStatusPropStatus): string => {
  switch (status) {
    case 'active':
      return 'Active';
    case 'online':
    case 'attention':
      return 'Online';
    case 'backup':
      return 'Backup';
    case 'connected':
      return 'Connected';
    case 'disabled':
      return 'Disabled';
    case 'disconnected':
      return 'Disconnected';
    case 'draft':
      return 'Draft';
    case 'enabled':
      return 'Enabled';
    case 'high':
      return 'High';
    case 'low':
      return 'Low';
    case 'medium':
      return 'Medium';
    case 'hidden':
      return 'Hidden';
    case 'offline':
      return 'Offline';
    case 'primary':
      return 'Primary';
    case 'ready':
      return 'Ready';
    case 'upgrading':
      return 'Upgrading';
    case 'wired':
      return 'Wired';
    default:
      return 'Unknown';
  }
};

const getObjectLifecycleLabel = (lifecycle: ObjectStatusPropLifecycle): string => {
  switch (lifecycle) {
    case 'active':
      return 'Active';
    case 'backup':
      return 'Backup';
    case 'hidden':
      return 'Hidden';
    case 'inactive':
      return 'Inactive';
    case 'primary':
      return 'Primary';
    case 'visible':
      return 'Visible';
    default:
      return 'Unknown';
  }
};

type ObjectStatusProps = Omit<BadgeGroupProps, 'children'> & {
  lifecycle?: ObjectStatusPropLifecycle;
  status?: ObjectStatusPropStatus;
  mismatchedRealm?: boolean;
};

export function ObjectStatus({
  ends = 'pill',
  lifecycle,
  status,
  mismatchedRealm,
  ...remaining
}: ObjectStatusProps) {
  return (
    <BadgeGroup ends={ends} {...remaining}>
      {status && (
        <Badge variant={getObjectStatusVariant(status)}>{getObjectStatusStatusLabel(status)}</Badge>
      )}
      {lifecycle && (
        <Badge variant={getObjectStatusLifecycleVariant(lifecycle)}>
          {getObjectLifecycleLabel(lifecycle)}
        </Badge>
      )}
      {mismatchedRealm && (
        <Tooltip asChild={false} content="Configured realm does not match device's IsDev">
          <Badge variant="negative">Mismatched Realm</Badge>
        </Tooltip>
      )}
    </BadgeGroup>
  );
}

import type { FormikHelpers } from 'formik';
import { Drawer, DrawerHeader } from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { NotificationAddSchema } from '../utils';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useIdentity } from '../../../../../providers/IdentityDataProvider';
import { useNetworkForSettingsPath } from '../../../../../routes/pages/settings/network/NetworkUtils';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import {
  createNotificationMutation,
  notificationAddSchema,
  useInvalidateNotificationSettings,
} from '../utils';
import AlertForm from './AlertForm';

// Converts the arguments from the form to the GQL format
function argumentsInputToGQL(args: NotificationAddSchema['input']['arguments']) {
  return args.map((arg) => ({
    parameterUUID: arg.parameterUUID,
    value: arg.value,
  }));
}

export default function AlertsAdd() {
  const closeDrawer = useCloseDrawerCallback();
  const mutation = useGraphQLMutation(createNotificationMutation);
  const network = useNetworkForSettingsPath({
    path: paths.pages.AlertsPage,
  });
  const navigate = useNavigate();
  const companyName = useCurrentCompany();
  const onCancel = useCloseDrawerCallback();
  const invalidateSettings = useInvalidateNotificationSettings({ networkUUID: network.UUID });
  const identity = useIdentity();

  const onSubmit = useCallback(
    (values: NotificationAddSchema, form: FormikHelpers<NotificationAddSchema>) => {
      const { input } = values;
      mutation.mutate(
        {
          networkUUID: network.UUID,
          input: {
            alertReceiverUUID: input.alertReceiverUUID,
            notificationDefinitionName: input.notificationDefinitionName,
            isEnabled: input.isEnabled,
            arguments: argumentsInputToGQL(input.arguments),
            createdBy: identity?.id,
          },
        },
        {
          onSuccess() {
            notify('Successfully created alert', {
              variant: 'positive',
            });
            form.resetForm();
            invalidateSettings();
            navigate(
              makeLink(paths.pages.AlertsPage, {
                companyName,
                networkSlug: network.slug,
              }),
            );
          },
          onError() {
            notify('Failed to create alert', {
              variant: 'negative',
            });
          },
        },
      );
    },
    [mutation, network.UUID, network.slug, identity?.id, invalidateSettings, navigate, companyName],
  );

  return (
    <Drawer>
      <DrawerHeader icon="plus" heading="Add alert" onClose={closeDrawer} />
      <Formik<NotificationAddSchema>
        initialValues={{
          input: {
            alertReceiverUUID: '',
            notificationDefinitionName: '',
            isEnabled: true,
            arguments: [],
          },
        }}
        validate={withZodSchema(notificationAddSchema)}
        onSubmit={onSubmit}
      >
        <Form>
          <AlertForm onCancel={onCancel} />
        </Form>
      </Formik>
    </Drawer>
  );
}

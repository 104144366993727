import React from 'react';

import { Badge, IconName } from '@meterup/atto';
import { ISPProduct } from '@meterup/connect-api/src/apiProtos';

type SpeedButtonProps = {
  product: ISPProduct;
};

export default function SpeedButton({ product }: SpeedButtonProps) {
  // slow coax
  // medium shared
  // fast dedicated
  let iconName: IconName = 'speed-low';
  let label = '';
  switch (product) {
    case ISPProduct.IR_BUSINESS_CABLE:
      label = 'Business coax';
      iconName = 'speed-low';
      break;
    case ISPProduct.IR_RESIDENTIAL_CABLE:
      label = 'Residential coax';
      iconName = 'speed-low';
      break;
    case ISPProduct.IR_DEDICATED_FIBER:
      label = 'Dedicated fiber';
      iconName = 'speed-high';
      break;
    case ISPProduct.IR_SHARED_FIBER:
      label = 'Shared fiber';
      iconName = 'speed-medium';
      break;
    case ISPProduct.IR_RESIDENTIAL_FIBER:
      label = 'Residential fiber';
      iconName = 'speed-medium';
      break;
    case ISPProduct.IR_POINT_TO_POINT_WIRELESS:
      label = 'Point-to-point';
      iconName = 'speed-medium';
      break;
    default:
      label = 'Unknown';
  }
  return (
    <Badge variant="neutral" size="small" icon={iconName} arrangement="leading-icon">
      {label}
    </Badge>
  );
}

import type { FormikHelpers } from 'formik';
import { Drawer, DrawerHeader } from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ReceiverUpdateSchema } from '../utils';
import { paths } from '../../../../../constants';
import { graphql } from '../../../../../gql';
import { AlertTargetType } from '../../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import {
  useCurrentCompany,
  useCurrentCompany2,
} from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import {
  receiverUpdateSchema,
  useAlertReceiver,
  useAlertTargetsAllByUUID,
  useInvalidateAlertReceivers,
} from '../utils';
import ReceiverActions from './ReceiverActions';
import ReceiverForm from './ReceiverForm';

const updateReceiverMutation = graphql(`
  mutation updateAlertReceiverWithTargets(
    $alertReceiverUUID: UUID!
    $input: UpdateAlertReceiverWithTargetsInput!
  ) {
    updateAlertReceiverWithTargets(alertReceiverUUID: $alertReceiverUUID, input: $input) {
      UUID
    }
  }
`);

export default function ReceiverEdit({ receiverUUID }: { receiverUUID: string }) {
  const closeDrawer = useCloseDrawerCallback();
  const mutation = useGraphQLMutation(updateReceiverMutation);
  const companyName = useCurrentCompany();
  const company = useCurrentCompany2();
  const navigate = useNavigate();
  const onCancel = useCloseDrawerCallback();
  const invalidateReceivers = useInvalidateAlertReceivers({ companyUUID: company?.uuid });
  const targets = useAlertTargetsAllByUUID({ companyUUID: company?.uuid });

  const buildTargetsInput = useCallback(
    ({ targetUUIDs, emailAddresses }: { targetUUIDs: string[]; emailAddresses: string[] }) => {
      const emailTargets = emailAddresses.map((emailAddress) => ({
        alertTargetType: AlertTargetType.Email,
        emailAddress,
      }));

      const otherTargets = targetUUIDs.map((uuid) => {
        const target = targets.get(uuid);
        expectDefinedOrThrow(target, new ResourceNotFoundError('Target not found'));
        return {
          alertTargetType: target.type,
          alertTargetUUID: target.UUID,
        };
      });

      return [...emailTargets, ...otherTargets];
    },
    [targets],
  );

  const onSubmit = useCallback(
    (values: ReceiverUpdateSchema, form: FormikHelpers<ReceiverUpdateSchema>) => {
      const { input } = values;
      mutation.mutate(
        {
          alertReceiverUUID: receiverUUID,
          input: {
            label: input.label,
            targets: buildTargetsInput({
              targetUUIDs: input.targetUUIDs,
              emailAddresses: input.emailAddresses,
            }),
          },
        },
        {
          onSuccess() {
            notify('Successfully updated receiver', {
              variant: 'positive',
            });
            form.resetForm();
            invalidateReceivers();
            navigate(
              makeLink(paths.pages.ReceiversPage, {
                companyName,
              }),
            );
          },
          onError() {
            notify('Failed to update receiver', {
              variant: 'negative',
            });
          },
        },
      );
    },
    [mutation, receiverUUID, buildTargetsInput, invalidateReceivers, navigate, companyName],
  );

  const receiver = useAlertReceiver({ uuid: receiverUUID, companyUUID: company?.uuid });
  const initialEmails = useMemo(
    () =>
      receiver.targets
        .filter((t) => t.__typename === 'AlertTargetEmail')
        .map((target) => target.emailAddress),
    [receiver],
  );
  const initialTargets = useMemo(
    () =>
      receiver.targets
        .filter((t) => t.type === AlertTargetType.Webhook)
        .map((target) => target.UUID),
    [receiver],
  );
  return (
    <Drawer>
      <DrawerHeader
        icon="plus"
        heading="Edit receiver"
        onClose={closeDrawer}
        actions={<ReceiverActions uuid={receiverUUID} />}
      />
      <Formik<ReceiverUpdateSchema>
        initialValues={{
          input: {
            label: receiver.label,
            targetUUIDs: initialTargets,
            emailAddresses: initialEmails,
          },
        }}
        validate={withZodSchema(receiverUpdateSchema)}
        onSubmit={onSubmit}
      >
        <Form>
          <ReceiverForm onCancel={onCancel} />
        </Form>
      </Formik>
    </Drawer>
  );
}

import { useCallback } from 'react';

import { Simplify } from '@meterup/atto/src/types/simplify';
import camelCaseKeys from 'camelcase-keys';

import { GeolocationMap } from '../types/gmaps';

export default function useFetchLocationPredictions() {
  return useCallback(async (query?: string) => {
    if (!query) {
      return [];
    }

    const { Place } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary;
    const results = await Place.searchByText({
      textQuery: query,
      fields: ['location', 'displayName', 'addressComponents', 'formattedAddress'],
      region: 'US',
    });
    if (!results?.places) {
      return [];
    }
    return results.places.map((result) => ({
      id: result.id,
      description: result.formattedAddress,
      formattedAddress: result.formattedAddress,
      latLng: result.location!,
      components: camelCaseKeys(
        result.addressComponents?.reduce(
          (memo, component) => ({
            ...memo,
            [component.types[0]]: component,
          }),
          {} as GeolocationMap,
        ) ?? {},
        { deep: true },
      ),
    }));
  }, []);
}

export type FetchLocationResults = Simplify<
  ReturnType<ReturnType<typeof useFetchLocationPredictions>>
>;

export type FetchLocationItem = Awaited<FetchLocationResults>[0];

export interface FLI extends FetchLocationItem {}

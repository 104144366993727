import type { GutterProp, PaneHeaderProps } from '@meterup/atto';
import type { ForwardedRef, ReactNode } from 'react';
import {
  Box,
  Pane,
  PaneContent,
  PaneFooter,
  PaneHeader,
  Skeleton,
  space,
  VStack,
} from '@meterup/atto';
import { ErrorBoundary } from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense } from 'react';
import { Outlet } from 'react-router';

import { Nav } from '../../nav';
import { ErrorFallback } from '../ErrorFallback/ErrorFallback';
import { DetailLoadingFallback } from '../Placeholders/DetailLoadingFallback';

export function TabbedLayout({
  footer,
  gutter,
  header,
  main = <Outlet />,
  contentContainerRef,
}: {
  footer?: ReactNode;
  gutter?: GutterProp;
  header?: PaneHeaderProps;
  main?: ReactNode;
  contentContainerRef?: ForwardedRef<HTMLDivElement> | null;
}) {
  const location = Nav.useRegionLocation('root');
  return (
    <Pane>
      <ErrorBoundary fallback={ErrorFallback}>
        <Suspense
          fallback={<PaneHeader heading={<Skeleton height={20} width={200} radius={6} />} />}
        >
          <PaneHeader {...header} />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary fallback={ErrorFallback}>
        <Suspense
          fallback={
            <Box style={{ padding: '16px 20px' }}>
              <VStack spacing={space(16)}>
                <Skeleton height={120} width="100%" radius={6} />
                <Skeleton height={120} width="100%" radius={6} />
                <Skeleton height={120} width="100%" radius={6} />
              </VStack>
            </Box>
          }
        >
          <PaneContent gutter={gutter} ref={contentContainerRef}>
            <QueryErrorResetBoundary key={location.pathname}>
              {({ reset }) => (
                <ErrorBoundary onReset={reset} fallback={ErrorFallback}>
                  <Suspense fallback={<DetailLoadingFallback />}>{main}</Suspense>
                </ErrorBoundary>
              )}
            </QueryErrorResetBoundary>
          </PaneContent>
        </Suspense>
      </ErrorBoundary>
      {footer && (
        <ErrorBoundary fallback={ErrorFallback}>
          <Suspense fallback={<Skeleton width="100%" height={20} radius={6} />}>
            <PaneFooter actions={footer} />
          </Suspense>
        </ErrorBoundary>
      )}
    </Pane>
  );
}

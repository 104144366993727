import React from 'react';

import { Body } from '@meterup/atto';
import { CompanyContractResponse } from '@meterup/connect-api';

import NetworkSummaryList from '../NetworkSummaryList/NetworkSummaryList';
import NetworkSummaryListItem from '../NetworkSummaryList/NetworkSummaryListItem';

export function DefaultRenderNetworkDetails({
  contractResponse,
}: {
  contractResponse: CompanyContractResponse;
}) {
  const { internetServicePlan } = contractResponse;
  const { staticIpRange } = internetServicePlan || {};

  if (!staticIpRange) {
    return null;
  }

  return (
    <NetworkSummaryList>
      <NetworkSummaryListItem label="IP">
        <Body family="monospace">{staticIpRange}</Body>
      </NetworkSummaryListItem>
    </NetworkSummaryList>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import type { ClientError } from 'graphql-request';
import {
  Alert,
  Badge,
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  CompositeField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  HStack,
  Label,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  Select,
  SelectItem,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  TextInput,
  ToggleInput,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { isValidCIDR, splitCIDR } from '@meterup/common/src/topics/ipv4';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import type { CreatePolicyRoutingRuleInput, UpdatePolicyRoutingRuleInput } from '../../gql/graphql';
import type { UplinkPhyInterface } from '../Firewall/utils';
import type { VLAN } from '../NetworkWide/VLANs/utils';
import type { PolicyRoutingRule, PolicyRoutingRuleBinding } from './utils';
import { MAX_PORT_NUMBER, paths } from '../../constants';
import { IpProtocol, PermissionType } from '../../gql/graphql';
import { CreatePolicyRoutingRuleInputSchema } from '../../gql/zod-types';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../providers/PermissionsProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { ucfirst } from '../../utils/strings';
import { withZodSchema } from '../../utils/withZodSchema';
import { PortRangeField } from '../Firewall/EditFirewallRuleDrawer';
import {
  displayIPProtocol,
  getPhyInterfaceLabel,
  PrefixKind,
  uplinkPhyInterfacesQuery,
  vlanSupportsFirewallRules,
} from '../Firewall/utils';
import {
  FieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
} from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import { vlansQuery } from '../NetworkWide/VLANs/utils';
import {
  createPolicyRoutingRuleMutation,
  deletePolicyRoutingRuleMutation,
  policyRoutingRuleQuery,
  policyRoutingRulesForNetworkQuery,
  updatePolicyRoutingRuleMutation,
} from './utils';

const policyRoutingRuleEditFormSchema = CreatePolicyRoutingRuleInputSchema.omit({
  srcPrefix: true,
  dstPrefix: true,
  srcPortRanges: true,
  dstPortRanges: true,
  bindings: true,
  boundPhyInterfaceUUIDs: true,
})
  .extend({
    protocols: z
      .array(z.nativeEnum(IpProtocol))
      .nonempty({ message: 'Please select at least one protocol' })
      .refine((protocols) => !protocols.includes(IpProtocol.All) || protocols.length === 1, {
        message: 'Cannot select specific protocols alongside All.',
      }),
    name: z.string().nonempty({ message: 'Please provide a name.' }),
    srcPrefixKind: z.nativeEnum(PrefixKind),
    srcVLANUUID: z.string().nullish(),
    srcIPAddress: z.string().ip({ message: 'Invalid IP address' }).nullish(),
    srcPrefixLength: z
      .number()
      .min(0, { message: 'Prefix length must be greater than or equal to 0.' })
      .max(128, { message: 'Prefix length must be less than or equal to 128.' })
      .nullish(),
    srcPortRange: z.object({
      lower: z.number().min(1).max(MAX_PORT_NUMBER),
      upper: z.number().min(1).max(MAX_PORT_NUMBER),
    }),
    dstIPAddress: z
      .string()
      .ip({ message: 'Invalid IP address' })
      .nonempty({ message: 'Please provide an IP address.' }),
    dstPrefixLength: z
      .number()
      .min(0, { message: 'Prefix length must be greater than or equal to 0.' })
      .max(128, { message: 'Prefix length must be less than or equal to 128.' })
      .nullish(),
    dstPortRange: z.object({
      lower: z.number().min(1).max(MAX_PORT_NUMBER),
      upper: z.number().min(1).max(MAX_PORT_NUMBER),
    }),
    boundPhyInterfaceUUID: z.string().nonempty({ message: 'Please select a WAN.' }),
  })
  .superRefine(({ srcPrefixKind, srcVLANUUID, srcIPAddress, srcPrefixLength }, ctx) => {
    switch (srcPrefixKind) {
      case PrefixKind.VLAN:
        if (!srcVLANUUID) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a VLAN source.',
            path: ['srcVLANUUID'],
          });
        }
        break;
      case PrefixKind.IPAddress:
        if (!srcIPAddress) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide an IP address.',
            path: ['srcIPAddress'],
          });
        }

        if (srcPrefixLength == null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a prefix length.',
            path: ['srcIPAddress'],
          });
        }
        break;
    }
  })
  .superRefine(({ dstIPAddress, dstPrefixLength }, ctx) => {
    if (!dstIPAddress) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please provide an IP address.',
        path: ['dstIPAddress'],
      });
    }

    if (dstPrefixLength == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please provide a prefix length.',
        path: ['dstIPAddress'],
      });
    }
  })
  .refine(
    ({ srcIPAddress, srcPrefixLength }) =>
      !srcIPAddress || srcPrefixLength == null || isValidCIDR(srcIPAddress, srcPrefixLength),
    {
      message: 'Invalid prefix length for IP address.',
      path: ['srcPrefixLength'],
    },
  )
  .refine(
    ({ dstIPAddress, dstPrefixLength }) =>
      !dstIPAddress || dstPrefixLength == null || isValidCIDR(dstIPAddress, dstPrefixLength),
    {
      message: 'Invalid prefix length for IP address.',
      path: ['dstPrefixLength'],
    },
  );

type PolicyRoutingRuleEditFormValues = z.input<typeof policyRoutingRuleEditFormSchema>;

function DeletePolicyRoutingRuleDialog({
  rule,
  state,
}: {
  rule: PolicyRoutingRule;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const [error, setError] = useState<ClientError | null>(null);
  const closeDrawer = useCloseDrawerCallback();

  const deleteRule = useGraphQLMutation(deletePolicyRoutingRuleMutation);
  const { mutate } = deleteRule;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    setError(null);

    mutate(
      { uuid: rule.UUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(policyRoutingRulesForNetworkQuery, { networkUUID: network.UUID }),
          );
          setError(null);
          closeDrawer();
          close();
          notify('Rule deleted successfully.', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          setError(err);
        },
      },
    );
  }, [mutate, rule?.UUID, close, network.UUID, queryClient, closeDrawer]);

  const graphqlError = useMemo(() => (error ? getGraphQLError(error) : undefined), [error]);

  const { affectedPhyInterfaces } = useMemo(() => {
    const phyInterfaces: NonNullable<PolicyRoutingRuleBinding['phyInterface']>[] = [];

    if (rule.bindings) {
      for (const binding of rule.bindings) {
        if (binding.phyInterface) {
          phyInterfaces.push(binding.phyInterface);
        }
      }
    }

    return {
      affectedPhyInterfaces: phyInterfaces,
    };
  }, [rule.bindings]);

  if (!rule) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete policy routing rule" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to remove the policy routing rule <Text weight="bold">{rule.name}</Text>{' '}
              from your Meter network.
            </>
          }
        />
        <SummaryList gutter="vertical">
          {!!affectedPhyInterfaces?.length && (
            <SummaryListRow>
              <SummaryListKey>Affected WAN{affectedPhyInterfaces.length > 1 && 's'}</SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  {affectedPhyInterfaces.map((phyInterface) => (
                    <Badge variant="neutral" size="small" icon="globe" arrangement="leading-icon">
                      {getPhyInterfaceLabel(phyInterface)}
                    </Badge>
                  ))}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>

        {error && (
          <Alert
            icon="warning"
            variant="negative"
            heading="There was an error deleting this policy routing rule"
            copy={graphqlError?.message ? ucfirst(graphqlError.message) : undefined}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

export function ProtocolsField() {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<PolicyRoutingRuleEditFormValues>();

  const handleAllChange = useCallback(
    (isSelected: boolean) => {
      setFieldValue('protocols', isSelected ? [IpProtocol.All] : []);
      if (!isSelected) {
        setFieldTouched('protocols', false);
      }
    },
    [setFieldValue, setFieldTouched],
  );

  const isAllSelected = useMemo(
    () => values.protocols.length === 1 && values.protocols[0] === IpProtocol.All,
    [values.protocols],
  );

  return (
    <FieldContainer>
      <MultiComboBoxFieldProvider name="protocols">
        <PrimaryField
          label="Protocols"
          element={
            isAllSelected ? null : (
              <MultiComboBox width="100%" placeholder="Select protocols">
                {Object.values(IpProtocol)
                  .filter((protocol) => protocol !== IpProtocol.All)
                  .map((protocol) => (
                    <MultiComboBoxItem key={protocol}>
                      {displayIPProtocol(protocol)}
                    </MultiComboBoxItem>
                  ))}
              </MultiComboBox>
            )
          }
          controls={
            <Label>
              All
              <ToggleInput onChange={handleAllChange} />
            </Label>
          }
        />
      </MultiComboBoxFieldProvider>
    </FieldContainer>
  );
}

const schemaValidator = withZodSchema(policyRoutingRuleEditFormSchema);

export default function PolicyRoutingRuleEditDrawer({ rule }: { rule?: PolicyRoutingRule }) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const canWriteNetworkRoutes = hasPermission(PermissionType.PermNetworkRouteWrite);

  const { state } = useDialogState();

  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;

  const uplinkPhyInterfaces = useGraphQL(uplinkPhyInterfacesQuery, { networkUUID: network.UUID })
    .data?.uplinkPhyInterfacesForNetwork;

  const groupedUplinkPhyInterfaces = useMemo(() => {
    const map = new Map<string, UplinkPhyInterface[]>();

    for (const pi of uplinkPhyInterfaces ?? []) {
      let arr = map.get(pi.virtualDevice.label);
      if (!arr) {
        arr = [];
        map.set(pi.virtualDevice.label, arr);
      }

      arr.push(pi);
    }

    return map;
  }, [uplinkPhyInterfaces]);

  const vlansWithPrefixes = useMemo(
    () => vlans?.filter((vlan) => vlanSupportsFirewallRules(vlan)) ?? [],
    [vlans],
  );

  const createMutation = useGraphQLMutation(createPolicyRoutingRuleMutation);
  const updateMutation = useGraphQLMutation(updatePolicyRoutingRuleMutation);

  const handleSubmit = useCallback(
    ({
      srcPrefixKind,
      srcVLANUUID,
      srcIPAddress,
      srcPrefixLength,
      dstIPAddress,
      dstPrefixLength,
      boundPhyInterfaceUUID,
      srcPortRange,
      dstPortRange,
      ...values
    }: PolicyRoutingRuleEditFormValues) => {
      const commonFields = {
        bindings: [{ phyInterfaceUUID: boundPhyInterfaceUUID }],
        // TODO(DASH-2722): Support multiple ranges
        srcPortRanges: [srcPortRange],
        dstPortRanges: [dstPortRange],
        dstPrefix: `${dstIPAddress}/${dstPrefixLength}`,
      } satisfies Partial<CreatePolicyRoutingRuleInput & UpdatePolicyRoutingRuleInput>;

      if (rule) {
        const input: UpdatePolicyRoutingRuleInput = {
          ...values,
          ...commonFields,
        };

        switch (srcPrefixKind) {
          case PrefixKind.VLAN:
            input.srcVLANUUID = srcVLANUUID;
            input.srcPrefix = null;
            break;
          case PrefixKind.IPAddress:
            input.srcPrefix = `${srcIPAddress}/${srcPrefixLength}`;
            input.srcVLANUUID = null;
            break;
        }

        updateMutation.mutate(
          { uuid: rule.UUID, input },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(policyRoutingRulesForNetworkQuery, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(
                makeQueryKey(policyRoutingRuleQuery, { uuid: rule.UUID }),
              );
              notify('Successfully updated policy routing rule.', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              notify(
                `There was a problem updating the policy routing rule${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreatePolicyRoutingRuleInput = {
          ...values,
          ...commonFields,
        };

        switch (srcPrefixKind) {
          case PrefixKind.VLAN:
            input.srcVLANUUID = srcVLANUUID;
            break;
          case PrefixKind.IPAddress:
            input.srcPrefix = `${srcIPAddress}/${srcPrefixLength}`;
            break;
        }

        createMutation.mutate(
          { networkUUID: network.UUID, input },
          {
            onSuccess: (result) => {
              queryClient.invalidateQueries(
                makeQueryKey(policyRoutingRulesForNetworkQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully created policy routing rule.', {
                variant: 'positive',
              });
              navigate(
                makeDrawerLink(window.location, paths.drawers.PolicyRoutingRuleEditPage, {
                  ruleUUID: result.createPolicyRoutingRule.UUID,
                  companyName,
                  networkSlug: network.slug,
                }),
              );
            },
            onError: (err) => {
              notify(
                `There was a problem creating the policy routing rule${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      rule,
      createMutation,
      updateMutation,
      network.UUID,
      network.slug,
      companyName,
      queryClient,
      navigate,
    ],
  );

  const vlanUUIDMap: Map<string, VLAN> = useMemo(
    () => new Map((vlans ?? []).map((vlan) => [vlan.UUID, vlan])),
    [vlans],
  );

  const handleValidate = useCallback(
    (values: PolicyRoutingRuleEditFormValues) => {
      const validation = schemaValidator(values);

      if (values.srcPrefixKind === PrefixKind.VLAN && values.srcVLANUUID) {
        const srcVLAN = vlanUUIDMap.get(values.srcVLANUUID);
        if (!srcVLAN) {
          validation.srcVLANUUID = 'Unrecognized VLAN.';
        } else if (!vlanSupportsFirewallRules(srcVLAN)) {
          validation.srcVLANUUID = 'Invalid VLAN.';
        }
      }

      return validation;
    },
    [vlanUUIDMap],
  );

  const srcPrefixPieces = rule?.srcPrefix ? splitCIDR(rule.srcPrefix) : null;
  const dstPrefixPieces = rule?.dstPrefix ? splitCIDR(rule.dstPrefix) : null;

  return (
    <Drawer>
      <Formik<PolicyRoutingRuleEditFormValues>
        initialValues={{
          name: rule?.name ?? '',
          isEnabled: rule?.isEnabled ?? true,
          srcPrefixKind: rule?.srcVLAN ? PrefixKind.VLAN : PrefixKind.IPAddress,
          srcVLANUUID: rule?.srcVLAN?.UUID ?? '',
          srcIPAddress: srcPrefixPieces?.[0] ?? '0.0.0.0',
          srcPrefixLength: srcPrefixPieces?.[1] ?? 0,

          dstIPAddress: dstPrefixPieces?.[0] ?? '0.0.0.0',
          dstPrefixLength: dstPrefixPieces?.[1] ?? 0,

          // TODO(DASH-2722): Support multiple port ranges
          srcPortRange: {
            lower: rule?.srcPortRanges?.[0]?.lower ?? 1,
            upper: rule?.srcPortRanges?.[0]?.upper ?? MAX_PORT_NUMBER,
          },
          dstPortRange: {
            lower: rule?.dstPortRanges?.[0]?.lower ?? 1,
            upper: rule?.dstPortRanges?.[0]?.upper ?? MAX_PORT_NUMBER,
          },
          protocols: (rule?.protocols as [IpProtocol, ...IpProtocol[]]) ?? [
            IpProtocol.Tcp,
            IpProtocol.Udp,
          ],

          boundPhyInterfaceUUID:
            rule?.bindings?.filter(
              (
                binding,
              ): binding is typeof binding & {
                phyInterface: NonNullable<(typeof binding)['phyInterface']>;
              } => !!binding.phyInterface,
            )?.[0]?.phyInterface.UUID ?? '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            icon="traffic-shaping"
            heading={`${rule ? 'Edit' : 'Add'} policy routing rule`}
            actions={
              rule ? (
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem icon="trash-can" onSelect={state.open}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
              ) : undefined
            }
            onClose={closeDrawer}
          />
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enabled" disabled={!canWriteNetworkRoutes} />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="name">
                <PrimaryField
                  label="Name"
                  element={<TextInput disabled={!canWriteNetworkRoutes} />}
                />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <FieldProvider name="boundPhyInterfaceUUID">
                <PrimaryField
                  label="WAN"
                  description="The WAN to route the matched traffic through."
                  element={
                    <ComboBox placeholder="Select WAN" disabled={!canWriteNetworkRoutes}>
                      {Array.from(groupedUplinkPhyInterfaces.entries())
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([virtualDevice, phyInterfaces]) => (
                          <ComboBoxSection title={virtualDevice}>
                            {phyInterfaces.map((pi) => (
                              <ComboBoxItem
                                key={pi.UUID}
                                textValue={getPhyInterfaceLabel(pi, false)}
                              >
                                {getPhyInterfaceLabel(pi, false)}
                              </ComboBoxItem>
                            ))}
                          </ComboBoxSection>
                        ))}
                    </ComboBox>
                  }
                />
              </FieldProvider>

              <Alert
                icon="information"
                heading="If this WAN uplink goes down, traffic will not fail over to any backup WANs."
                variant="neutral"
                relation="stacked"
              />
            </FieldContainer>

            <ProtocolsField />

            <FieldContainer>
              <PrimaryField
                label="Source"
                element={null}
                controls={
                  <FieldProvider name="srcPrefixKind">
                    <CompositeField
                      label="Source kind"
                      element={
                        <Select disabled={!canWriteNetworkRoutes}>
                          <SelectItem key={PrefixKind.IPAddress}>IP address</SelectItem>
                          <SelectItem key={PrefixKind.VLAN}>VLAN</SelectItem>
                        </Select>
                      }
                    />
                  </FieldProvider>
                }
              />

              <FormikConditional<PolicyRoutingRuleEditFormValues>
                condition={({ srcPrefixKind }) => srcPrefixKind === PrefixKind.VLAN}
              >
                <FieldProvider name="srcVLANUUID">
                  <SecondaryField
                    label="VLAN"
                    element={
                      <ComboBox disabled={!canWriteNetworkRoutes} placeholder="Select a VLAN">
                        {vlansWithPrefixes.map((vlan) => (
                          <ComboBoxItem key={vlan.UUID}>{vlan.name}</ComboBoxItem>
                        ))}
                      </ComboBox>
                    }
                  />
                </FieldProvider>
              </FormikConditional>
              <FormikConditional<PolicyRoutingRuleEditFormValues>
                condition={({ srcPrefixKind }) => srcPrefixKind === PrefixKind.IPAddress}
              >
                <FieldProvider name="srcIPAddress">
                  <SecondaryField
                    label="IP address"
                    element={<TextInput width="120px" disabled={!canWriteNetworkRoutes} />}
                  />
                </FieldProvider>
                <NumberFieldProvider name="srcPrefixLength" defaultValue={0}>
                  <SecondaryField
                    label="Prefix length"
                    element={<TextInput width="40px" disabled={!canWriteNetworkRoutes} />}
                  />
                </NumberFieldProvider>
                <PortRangeField name="srcPortRange" disabled={!canWriteNetworkRoutes} />
              </FormikConditional>
            </FieldContainer>

            <FieldContainer>
              <PrimaryField label="Destination" element={null} />

              <FieldProvider name="dstIPAddress">
                <SecondaryField
                  label="IP address"
                  element={<TextInput width="120px" disabled={!canWriteNetworkRoutes} />}
                />
              </FieldProvider>
              <NumberFieldProvider name="dstPrefixLength" defaultValue={0}>
                <SecondaryField
                  label="Prefix length"
                  element={<TextInput width="40px" disabled={!canWriteNetworkRoutes} />}
                />
              </NumberFieldProvider>
              <PortRangeField name="dstPortRange" disabled={!canWriteNetworkRoutes} />
            </FieldContainer>
          </DrawerContent>
          {canWriteNetworkRoutes && (
            <DrawerFooter
              actions={
                <>
                  <Button onClick={closeDrawer} variant="secondary">
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </>
              }
            />
          )}
        </Form>
      </Formik>
      {rule && <DeletePolicyRoutingRuleDialog state={state} rule={rule} />}
    </Drawer>
  );
}

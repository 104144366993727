import RADIUSProfiles from '../../../../../components/NetworkWide/RADIUSProfiles/RADIUSProfiles';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/radius-profiles',
  layout: 'VirtualNetworkLayout',
  title: 'RADIUS Profiles - Network-wide',
});

export default function RADIUSProfilesPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        permissions.hasPermission(PermissionType.PermNetworkSettingsRead)
      }
      should404OnAccessDenied
    >
      <RADIUSProfiles />
    </IsPermitted>
  );
}

import type { BadgeVariant } from '@meterup/atto';
import { z } from 'zod';

import type { OnboardingForNetworkQuery } from '../gql/graphql';

export type Onboarding = NonNullable<OnboardingForNetworkQuery['networkBySlug']['onboarding']>;
export type JobData = NonNullable<Onboarding['jobData']>;

export function getCustomerVisibleStatus(status: string): {
  label: string;
  sentiment: BadgeVariant;
} {
  // These are the possible statuses for a job in Airtable
  switch (status) {
    case 'DRAFT':
    case 'Ready to assign':
    case 'Assigned':
    case 'Kickoff':
    case 'On track':
      return {
        label: 'On track',
        sentiment: 'positive',
      };
    case 'Delayed':
    case 'On hold':
    case 'Blocked':
      return {
        label: 'Delayed',
        sentiment: 'negative',
      };
    case 'Canceled':
      return {
        label: 'Canceled',
        sentiment: 'negative',
      };
    case 'Complete':
      return {
        label: 'Complete',
        sentiment: 'positive',
      };
    default:
      return {
        label: 'Pending',
        sentiment: 'neutral',
      };
  }
}

export const jobStages = {
  0: 'Kickoff',
  1: 'Site survey',
  2: 'Installation preparation',
  3: 'Installation',
  4: 'Complete',
};

export function getJobStage(jobData: JobData) {
  // These are the possible job stages in Airtable
  switch (jobData.jobStage) {
    case 'Pending assignment':
    case 'Kickoff':
      return 0;
    case 'Site survey':
      return 1;
    case 'Installation preparation':
    case 'Installation scheduled':
      return 2;
    case 'Installation in progress':
    case 'Meter hardware installation complete':
      return 3;
    case 'Job Complete':
      return 4;
    default:
      return 0;
  }
}

export function formattedGoLiveDate(targetGoLiveDate?: string | null) {
  if (!targetGoLiveDate) return '-';

  // this field is in the format YYYY-MM-DD
  const [year, month, day] = targetGoLiveDate.split('-').map((s) => parseInt(s, 10));
  const date = new Date(year, month - 1, day);

  if (Number.isNaN(date.getTime())) return '-';

  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });
}

export function getNextEvent(jobData: JobData): { name: string; date: string } | null {
  if (jobData.jobStatus === 'Complete') {
    return null;
  }

  if (jobData.installationStartDate) {
    const start = new Date(jobData.installationStartDate);

    if (Number.isNaN(start.getTime())) {
      return null;
    }

    return {
      name: 'Installation',
      date: start.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      }),
    };
  }

  if (jobData.siteSurveyDate?.length) {
    const survey = new Date(jobData.siteSurveyDate[0]);

    if (Number.isNaN(survey.getTime())) {
      return null;
    }

    return {
      name: 'Site survey',
      date: survey.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      }),
    };
  }

  return null;
}

const jobOwnerUser = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
});

export function getJobOwnerUser(jobData: JobData): null | z.infer<typeof jobOwnerUser> {
  if (!jobData.jobOwnerUser) return null;

  try {
    const parsed = jobOwnerUser.parse(JSON.parse(jobData.jobOwnerUser));

    return parsed;
  } catch (error) {
    return null;
  }
}

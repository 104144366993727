import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useNavigate } from 'react-router';

import UsersList from '../../../../components/Users/UsersList';
import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';
import { makeDrawerLink, makeLink } from '../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/operator/companies/:slug/users',
  layout: 'OperatorLayout',
  title: 'Users - Companies - Operator',
});

export default function CompanyUsersPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  const { slug } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.CompanyUsersPage));
  const navigate = useNavigate();

  return (
    <UsersList
      companySlug={slug}
      back={() =>
        navigate(
          makeDrawerLink(
            {
              pathname: makeLink(paths.pages.CompanyListPage, {}),
              search: window.location.search,
            },
            paths.drawers.CompanySummaryPage,
            {
              slug,
            },
          ),
        )
      }
    />
  );
}

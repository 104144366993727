import { isValidCIDR, safeParseAddress4 } from '@meterup/common';
import { z } from 'zod';

import type {
  CreateIpSecTunnelInput,
  IpSecTunnelQueryQuery,
  UpdateIpSecTunnelInput,
  VlaNsQueryQuery,
} from '../../../../../gql/graphql';
import { graphql } from '../../../../../gql';
import {
  IpSecAuthenticationAlgorithm,
  IpSecDhGroup,
  IpSecEncryptionAlgorithm,
} from '../../../../../gql/graphql';
import {
  CreateIpSecTunnelInputSchema,
  UpdateIpSecTunnelInputSchema,
} from '../../../../../gql/zod-types';
import { isFQDN } from '../../../../../utils/fqdn';

export const IPSecTunnelsQuery = graphql(`
  query IPSecTunnelsQuery($networkUUID: UUID!) {
    ipSecTunnelsForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      isEnabled
      isInitiator
      authenticationAlgorithm
      encryptionAlgorithm
      keyExchangeDHGroup
      right
      rightPrefixes
      rightID
      leftID
      boundPhyInterface {
        UUID
        label
        portNumber
        virtualDevice {
          label
          hardwareDevice {
            isSpare
            isConnectedToBackend
            deviceType
          }
        }
      }
      boundVLANs {
        UUID
        vlanID
        name
      }
      status {
        observedAt
        saStatus
        uniqueID
        remoteHostIP4Address
        establishedAt
        rekeyAt
        reauthAt
        childSAStatus
        rxBytes
        txBytes
        rxPackets
        rxLast
        txLast
        childRekeyAt
        childLifetime
        latencyMs
      }
    }
  }
`);

export const IPSecTunnelQuery = graphql(`
  query IPSecTunnelQuery($UUID: UUID!) {
    ipSecTunnel(UUID: $UUID) {
      UUID
      name
      isEnabled
      right
      rightPrefixes
      rightID
      leftID
      isInitiator
      authenticationAlgorithm
      encryptionAlgorithm
      keyExchangeDHGroup
      boundPhyInterface {
        UUID
        portNumber
        virtualDevice {
          label
          hardwareDevice {
            isSpare
            isConnectedToBackend
            deviceType
            serialNumber
          }
        }
      }
      boundVLANs {
        UUID
        vlanID
        name
      }
      status {
        observedAt
        saStatus
        uniqueID
        remoteHostIP4Address
        establishedAt
        rekeyAt
        reauthAt
        childSAStatus
        rxBytes
        txBytes
        rxPackets
        rxLast
        txLast
        childRekeyAt
        childLifetime
        latencyMs
      }
    }
  }
`);

export const updateIPSecTunnelMutation = graphql(`
  mutation UpdateIPSecTunnel($uuid: UUID!, $input: UpdateIPSecTunnelInput!) {
    updateIPSecTunnel(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteIPSecTunnelMutation = graphql(`
  mutation DeleteIPSecTunnel($uuid: UUID!) {
    deleteIPSecTunnel(UUID: $uuid) {
      UUID
    }
  }
`);

export const createIPSecTunnelMutation = graphql(`
  mutation CreateIPSecTunnel($networkUUID: UUID!, $input: CreateIPSecTunnelInput!) {
    createIPSecTunnel(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const rpcRestartIPSecTunnelMutation = graphql(`
  mutation RpcRestartIPSecTunnel($serialNumber: String!, $ipSecTunnelUUID: UUID!) {
    rpcRestartIPSecTunnel(serialNumber: $serialNumber, ipSecTunnelUUID: $ipSecTunnelUUID)
  }
`);

function isValidIP(str: string): boolean {
  let isValid: boolean = true;
  if (!str.includes('/')) {
    isValid = false;
  }
  const splitStr = str?.split('/');
  const ipStr = splitStr?.[0];
  const lengthStr = splitStr?.[1];
  const lengthNum = Number.parseInt(lengthStr, 10);
  if (lengthStr) {
    if (
      Number.isInteger(lengthNum) &&
      !Number.isNaN(lengthNum) &&
      lengthNum >= 0 &&
      lengthNum <= 32
    ) {
      isValid = true;
    } else {
      isValid = false;
    }
  }
  if (!ipStr || !isValidCIDR(ipStr, lengthNum)) {
    isValid = false;
  }
  return isValid;
}

const sharedIPSecTunnelFormSchema = {
  name: z.string().nonempty({ message: 'Please provide a name.' }),
  leftID: z
    .string()
    .nonempty({ message: 'Please provide a Local IP' })
    .refine((val) => safeParseAddress4(val) || isFQDN(val), {
      message: 'Local IP must be a valid IP address or fully qualified domain name.',
    }),
  rightID: z
    .string()
    .nonempty({ message: 'Please provide a Remote IP' })
    .refine((val) => safeParseAddress4(val) || isFQDN(val), {
      message: 'Remote IP must be a valid IP address or fully qualified domain name.',
    }),
  right: z
    .string()
    .nullish()
    .refine(
      (val) => {
        if (val) {
          return safeParseAddress4(val) || isFQDN(val);
        }
        return true;
      },
      {
        message: 'Destination must be a valid IP address or fully qualified domain name.',
      },
    ),
  rightPrefixes: z.array(z.string()).refine(
    (prefixes) => {
      let isValid: boolean = true;
      if (prefixes?.length === 0) {
        return false;
      }
      prefixes?.forEach((prefix) => {
        isValid = isValidIP(prefix);
      });
      return isValid;
    },
    {
      message:
        'Remote networks must be a newline-separated list of valid subnets with the format: "192.168.108.0/24", where the prefix length is 0 - 32.',
    },
  ),
  phyInterfaceUUID: z.string().nonempty({
    message: 'Please provide a WAN port.',
  }),
  vlanUUIDs: z.array(z.string()).nonempty({
    message: 'Please select one or more VLANs.',
  }),
  isCreate: z.boolean(),
};

export const IPSecTunnelCreateFormSchema = CreateIpSecTunnelInputSchema.omit({
  rightPrefixes: true,
})
  .extend(sharedIPSecTunnelFormSchema)
  .refine(({ presharedKey, isCreate }) => !(isCreate && presharedKey === ''), {
    message: 'Please provide a preshared key.',
    path: ['presharedKey'],
  })
  .superRefine(
    (
      { isInitiator, right, authenticationAlgorithm, encryptionAlgorithm, keyExchangeDHGroup },
      ctx,
    ) => {
      if (isInitiator) {
        if (!right) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Destination address must be set if the source is the initiator.',
            path: ['right'],
          });
        }
        if (!authenticationAlgorithm) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Authentication algorithm must be set if the source is the initiator.',
            path: ['authenticationAlgorithm'],
          });
        }
        if (!encryptionAlgorithm) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Encryption algorithm must be set if the source is the initiator.',
            path: ['encryptionAlgorithm'],
          });
        }
        if (!keyExchangeDHGroup) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'DH key exchange must be set if the source is the initiator.',
            path: ['keyExchangeDHGroup'],
          });
        }
      }
    },
  );

export type IPSecTunnelCreateFormValues = z.input<typeof IPSecTunnelCreateFormSchema>;

export const IPSecTunnelEditFormSchema = UpdateIpSecTunnelInputSchema.omit({
  rightPrefixes: true,
}).extend(sharedIPSecTunnelFormSchema);

export type IPSecTunnelEditFormValues = z.input<typeof IPSecTunnelEditFormSchema>;

export type IPSecTunnel = IpSecTunnelQueryQuery['ipSecTunnel'];

export type EditIPSecTunnelDrawerProps = {
  tunnel?: IPSecTunnel;
  isInDrawer?: boolean;
};

export type DefaultIPSecTunnelVals = Omit<IPSecTunnelCreateFormValues, 'vlanUUIDs'> & {
  vlanUUIDs: string[];
};
export const defaultIPSecTunnelVals: DefaultIPSecTunnelVals = {
  name: '',
  isEnabled: false,
  isInitiator: false,
  authenticationAlgorithm: undefined,
  encryptionAlgorithm: undefined,
  keyExchangeDHGroup: undefined,
  right: '',
  rightID: '',
  leftID: '',
  rightPrefixes: [],
  phyInterfaceUUID: '',
  presharedKey: '',
  vlanUUIDs: [],
  isCreate: true,
};

export const authenticationAlgos = Object.values(IpSecAuthenticationAlgorithm);
export const encryptionAlgos = Object.values(IpSecEncryptionAlgorithm);
export const dhGroups = Object.values(IpSecDhGroup);

export function humanReadableDHKeyExchangeGroup(key: IpSecDhGroup): string {
  switch (key) {
    case IpSecDhGroup.Dh2:
      return '1024-bit MODP Group';
    case IpSecDhGroup.Dh14:
      return '2048-bit MODP Group';
    case IpSecDhGroup.Dh19:
      return '256-bit random ECP group';
    case IpSecDhGroup.Dh20:
      return '384-bit random ECP group';
    default:
      return key;
  }
}

export function vlanLabelWithSubnet(vlan: VlaNsQueryQuery['vlans'][number]): string {
  const vlanSubnetLabel =
    vlan.ipV4ClientGateway && vlan.ipV4ClientPrefixLength
      ? ` (${vlan.ipV4ClientGateway}/${vlan.ipV4ClientPrefixLength})`
      : '';
  return `${vlan.name}${vlanSubnetLabel}`;
}

export function parseCreateFormValsToIPSecTunnel(
  formVals: IPSecTunnelCreateFormValues,
): CreateIpSecTunnelInput {
  const {
    isInitiator,
    authenticationAlgorithm,
    encryptionAlgorithm,
    keyExchangeDHGroup,
    right,
    isCreate,
    ...rest
  } = formVals;
  const initiatorFields = {
    authenticationAlgorithm,
    encryptionAlgorithm,
    keyExchangeDHGroup,
    right,
  };
  return {
    isInitiator,
    ...(isInitiator ? initiatorFields : {}),
    ...rest,
  };
}

export function parseEditFormValsToIPSecTunnel(
  formVals: IPSecTunnelEditFormValues,
): UpdateIpSecTunnelInput {
  const {
    isInitiator,
    authenticationAlgorithm,
    encryptionAlgorithm,
    keyExchangeDHGroup,
    presharedKey,
    right,
    isCreate,
    ...rest
  } = formVals;

  const presharedKeyProperty = presharedKey === '' ? {} : { presharedKey };
  const initiatorFields = {
    authenticationAlgorithm,
    encryptionAlgorithm,
    keyExchangeDHGroup,
    right,
  };
  return {
    isInitiator,
    ...presharedKeyProperty,
    ...(isInitiator ? initiatorFields : {}),
    ...rest,
  };
}

export function parseIPSecTunnelToFormVals(
  tunnel: IPSecTunnel | undefined,
): DefaultIPSecTunnelVals {
  if (!tunnel) {
    return defaultIPSecTunnelVals;
  }
  const { rightPrefixes, boundVLANs, boundPhyInterface, UUID, status, ...rest } = tunnel;
  const vlanUUIDs = boundVLANs.map((vlan) => vlan.UUID);
  const presharedKey = '';
  return {
    ...defaultIPSecTunnelVals,
    rightPrefixes,
    phyInterfaceUUID: boundPhyInterface.UUID,
    presharedKey,
    vlanUUIDs,
    isCreate: false,
    ...rest,
  };
}

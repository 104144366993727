import type { GutterProp } from '@meterup/atto';

import type { IntegrationsValidTabs } from './IntegrationsTabs';
import { TabbedLayout } from '../../../Layouts/TabbedLayout';
import IntegrationsActions from './IntegrationsActions';
import IntegrationsTabs from './IntegrationsTabs';

export default function IntegrationsLayout({
  activeTab,
  gutter,
  main,
}: {
  activeTab: IntegrationsValidTabs;
  gutter?: GutterProp;
  main: React.ReactNode;
}) {
  return (
    <TabbedLayout
      header={{
        icon: 'integrations',
        heading: 'Integrations',
        tabs: <IntegrationsTabs activeTab={activeTab} />,
        contentActions: <IntegrationsActions activeTab={activeTab} />,
      }}
      gutter={gutter}
      main={main}
    />
  );
}

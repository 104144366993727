import { DrawerContent, DrawerFooter, Small } from '@meterup/atto';

import { styled } from '../../styled';

export const FieldDescription = styled('div', Small, {
  color: '$gray-600',
});

export const Form = styled('form', {
  [`& ${DrawerContent}`]: {
    padding: '$16 $20 $16 $20',
  },
  [`& ${DrawerFooter}`]: {
    paddingLeft: 0,
    paddingRight: 0,
    margin: '0 $20',
    justifyContent: 'flex-end',
  },
});

import { CompanyContractResponse } from '@meterup/connect-api';

export function calcContractSumValue(companyContracts: CompanyContractResponse[]) {
  return companyContracts.reduce((sum, contract) => {
    if (!contract.internetServicePlan) {
      return sum;
    }

    return sum + contract.internetServicePlan.monthlyCostCents;
  }, 0);
}

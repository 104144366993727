import { validIPv4String } from '@meterup/common';
import { MeterV1NetworkNATPortForwardProtocol } from '@meterup/config';
import { z } from 'zod';

export const validPortForwardingRule = z.object({
  stableId: z.string().optional(),
  internalIPAddress: validIPv4String(),
  internalPort: z.coerce.number().min(0).max(65535),
  externalPort: z.coerce.number().min(0).max(65535),
  protocol: z.nativeEnum(MeterV1NetworkNATPortForwardProtocol),
  description: z.string(),
});

export type ValidPortForwardingRule = z.infer<typeof validPortForwardingRule>;

import { checkDefinedOrThrow } from '@meterup/common';

import ISPDrawer from '../../../../../components/NetworkWide/ISPs/ISPDrawer';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/isps/:isp',
  title: 'ISP - ISPs - Network-wide',
});

export default function ISPDrawerPage() {
  const { isp } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.ISPDrawerPage));
  return <ISPDrawer ispUUID={isp} />;
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import PortForwarding from '../../../../../components/Firewall/PortForwarding/PortForwarding';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/port-forwarding',
  layout: 'VirtualNetworkLayout',
  title: 'Port forwarding - Firewall',
});

export default function PortForwardingPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPortForwardRead) &&
            ldFlags['port-forwarding'],
        )
      }
      should404OnAccessDenied
    >
      <PortForwarding />
    </IsPermitted>
  );
}

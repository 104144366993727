import SwitchPortEditDrawer from '../../../../../components/Hardware/Switches/SwitchPortEditDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID/edit',
  title: 'Edit port - Switch - Switches - Hardware',
});

export default function SwitchPortEditPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SwitchPortEditPage);

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags, ldFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPhyInterfaceWrite) &&
            nosFlags[NosFeature.SOS] &&
            ldFlags['sos-ui'],
        )
      }
    >
      <SwitchPortEditDrawer
        virtualDeviceUUID={drawerParams?.virtualDeviceUUID!}
        phyInterfaceUUID={drawerParams?.phyInterfaceUUID!}
      />
    </IsPermitted>
  );
}

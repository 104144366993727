import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import NotificationsLayout from '../../../../../components/Settings/Network/Notifications/NotificationsLayout';
import { NotificationsValidTabs } from '../../../../../components/Settings/Network/Notifications/NotificationsTabs';
import Reports from '../../../../../components/Settings/Network/Notifications/Reports/Reports';

export function Meta() {
  return {
    path: '/org/:companyName/settings/network/:networkSlug/notifications/reports',
    title: 'Reports - Notifications - Network',
  };
}

export default function ReportsPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  return <NotificationsLayout activeTab={NotificationsValidTabs.Reports} main={<Reports />} />;
}

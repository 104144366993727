import React from 'react';

export default function Cradlepoint() {
  return (
    <svg
      width="113"
      height="20"
      viewBox="0 0 113 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.5825 17.1925C30.1271 17.2052 29.6721 17.1545 29.2307 17.0419C28.9236 16.9603 28.6538 16.7758 28.4665 16.5192C28.2731 16.219 28.1571 15.8757 28.1288 15.5197C28.0734 15.001 28.0479 14.4795 28.0525 13.9578C28.047 13.4382 28.0725 12.9188 28.1288 12.4022C28.1583 12.0466 28.2758 11.7038 28.4707 11.4049C28.658 11.1482 28.9278 10.9637 29.2349 10.8821C29.676 10.768 30.1312 10.717 30.5866 10.7305C30.7895 10.7305 30.986 10.7389 31.1752 10.7535C31.3645 10.7682 31.5349 10.7839 31.6833 10.7995C31.8548 10.8225 32.0095 10.8414 32.1496 10.8591L32.1266 12.1189C32.0022 12.1116 31.8705 12.1001 31.7293 12.0844C31.6049 12.0771 31.4596 12.0698 31.2923 12.0624C31.1251 12.0551 30.9515 12.0509 30.7696 12.0509C30.5823 12.0445 30.3953 12.0681 30.2155 12.121C30.148 12.1441 30.0861 12.181 30.0337 12.2295C29.9813 12.2779 29.9396 12.3367 29.9113 12.4022C29.8352 12.5871 29.794 12.7846 29.79 12.9845C29.7743 13.2323 29.767 13.5595 29.767 13.9641C29.767 14.3687 29.7743 14.6959 29.79 14.9437C29.7934 15.1416 29.8342 15.337 29.9102 15.5197C29.9394 15.5839 29.9815 15.6415 30.0338 15.6888C30.0862 15.7361 30.1476 15.7721 30.2145 15.7947C30.3943 15.8471 30.5813 15.8707 30.7686 15.8647C30.9473 15.8647 31.1198 15.8647 31.2913 15.8595C31.4627 15.8543 31.6049 15.8459 31.7283 15.8313C31.8516 15.8166 32.0011 15.8114 32.1255 15.7968L32.1485 17.0565C32.0085 17.0795 31.8527 17.1025 31.6823 17.1276C31.5349 17.1433 31.3686 17.158 31.1742 17.1736C30.9797 17.1893 30.7884 17.1883 30.5856 17.1883"
        fill="#323E48"
      />
      <path
        d="M33.4397 10.8414H34.735L34.8991 11.3191L35.0747 11.1853C35.1763 11.1076 35.2836 11.0377 35.3957 10.9762C35.5386 10.9007 35.6889 10.8401 35.8442 10.7954C36.0299 10.743 36.2221 10.7173 36.415 10.719C36.4934 10.719 36.5666 10.719 36.6366 10.7243C36.7067 10.7295 36.7684 10.7337 36.8238 10.742C36.8821 10.7493 36.9407 10.7531 36.9994 10.7535L36.9775 12.1053C36.9149 12.1047 36.8525 12.1008 36.7903 12.0938C36.7266 12.0938 36.6617 12.0938 36.5917 12.0938C36.5216 12.0938 36.4443 12.0875 36.3586 12.0875C36.0732 12.0757 35.7884 12.123 35.5222 12.2266C35.3847 12.2857 35.2503 12.352 35.1197 12.4252V17.067H33.4397V10.8414Z"
        fill="#323E48"
      />
      <path
        d="M37.4406 15.1016C37.4361 14.8344 37.4677 14.5678 37.5347 14.3091C37.5904 14.0943 37.7031 13.8985 37.8609 13.7425C38.0384 13.5791 38.2541 13.463 38.4881 13.4048C38.8059 13.3232 39.1334 13.2859 39.4614 13.294C39.7111 13.2917 39.9606 13.3032 40.2089 13.3285L40.7452 13.387V13.0107C40.7481 12.8505 40.7348 12.6904 40.7055 12.5329C40.6845 12.4218 40.6298 12.32 40.5487 12.2412C40.4514 12.1624 40.3341 12.1121 40.21 12.0959C40.0173 12.0662 39.8225 12.0529 39.6277 12.0562C39.2388 12.0562 38.8959 12.0635 38.6084 12.0792L37.9497 12.1137V10.8435C38.1741 10.8281 38.4031 10.809 38.6366 10.786C38.8394 10.7703 39.0548 10.7577 39.2785 10.7462C39.5022 10.7347 39.7134 10.7285 39.9058 10.7285C40.3418 10.7173 40.7773 10.7662 41.2 10.8738C41.4884 10.9488 41.7504 11.1023 41.9569 11.317C42.1424 11.5279 42.2651 11.7865 42.3113 12.0635C42.3746 12.4118 42.404 12.7654 42.3991 13.1194V17.0733H41.108L40.9575 16.6875C40.9188 16.7021 40.8529 16.7387 40.7484 16.792C40.6236 16.8572 40.4948 16.9141 40.3626 16.9625C40.189 17.0257 40.0116 17.078 39.8315 17.1193C39.6188 17.1671 39.4014 17.1906 39.1834 17.1893C38.9629 17.1904 38.7433 17.1609 38.531 17.1015C38.3258 17.0462 38.1362 16.9443 37.9769 16.8035C37.8046 16.6472 37.6729 16.4513 37.5932 16.2327C37.4878 15.9418 37.4385 15.6335 37.4479 15.3242V15.1016H37.4406ZM39.0391 15.1131C39.0391 15.4267 39.0987 15.6515 39.2158 15.7717C39.3329 15.8919 39.5534 15.9536 39.8796 15.9536C40.063 15.9566 40.2458 15.9326 40.4222 15.8825C40.544 15.8497 40.6628 15.8071 40.7777 15.755V14.3196C40.6919 14.3039 40.5686 14.2882 40.417 14.2736C40.2654 14.2589 40.0824 14.2506 39.8796 14.2506C39.566 14.2506 39.3506 14.3039 39.2262 14.4147C39.1018 14.5255 39.0391 14.7283 39.0391 15.0221V15.1131Z"
        fill="#323E48"
      />
      <path
        d="M45.3421 17.1925C45.0415 17.2022 44.7421 17.151 44.4618 17.0419C44.234 16.9431 44.0423 16.7762 43.913 16.5641C43.7649 16.3069 43.6686 16.0231 43.6296 15.7288C43.5714 15.3311 43.5441 14.9295 43.5481 14.5276V13.4288C43.5429 13.0246 43.5722 12.6206 43.6359 12.2214C43.679 11.9256 43.7802 11.6413 43.9339 11.385C44.0658 11.1692 44.2586 10.9971 44.4879 10.8905C44.7617 10.7716 45.0585 10.7151 45.3567 10.7253C45.5539 10.7239 45.7507 10.7421 45.9443 10.7797C46.1082 10.8093 46.27 10.8501 46.4283 10.902C46.5673 10.947 46.6855 10.993 46.7827 11.0348C46.8453 11.0646 46.91 11.0901 46.9761 11.1111V8.91565H48.6321V17.0701H47.3337L47.1946 16.6844C47.1559 16.699 47.0796 16.7335 46.9667 16.7837C46.8538 16.8339 46.7168 16.8882 46.5485 16.953C46.3683 17.0216 46.1831 17.0762 45.9944 17.1161C45.7788 17.163 45.5586 17.1858 45.3379 17.1841L45.3421 17.1925ZM46.0363 15.8532C46.2302 15.8553 46.4238 15.8357 46.6133 15.7947C46.7385 15.7707 46.8613 15.7357 46.9803 15.6901V12.2402C46.8606 12.1936 46.738 12.1549 46.6133 12.1241C46.4248 12.0757 46.2309 12.0518 46.0363 12.053C45.8945 12.0491 45.7531 12.0693 45.6181 12.1126C45.5057 12.1502 45.4121 12.2295 45.3567 12.3343C45.2816 12.483 45.2362 12.6449 45.2229 12.811C45.1929 13.0824 45.1796 13.3555 45.1832 13.6285V14.3049C45.1792 14.578 45.1925 14.8511 45.2229 15.1225C45.2379 15.2849 45.2833 15.4431 45.3567 15.5887C45.3839 15.6401 45.4209 15.6857 45.4658 15.7226C45.5107 15.7596 45.5624 15.7873 45.6181 15.8041C45.7538 15.8435 45.8949 15.8615 46.0363 15.8574"
        fill="#323E48"
      />
      <path d="M51.7402 8.9167H50.0957V17.0701H51.7402V8.9167Z" fill="#323E48" />
      <path
        d="M61.0248 18.9948H59.3803V10.8403H60.6756L60.8147 11.2125C60.8534 11.1979 60.9276 11.1655 61.0363 11.1132C61.145 11.0609 61.2789 11.0086 61.4399 10.949C61.6209 10.8846 61.806 10.8322 61.9939 10.7922C62.2121 10.7454 62.4347 10.722 62.6578 10.7222C62.9655 10.7115 63.2718 10.7675 63.5558 10.8863C63.786 10.9918 63.979 11.1641 64.1099 11.3808C64.2574 11.6385 64.3526 11.9227 64.3901 12.2172C64.4446 12.6113 64.4701 13.009 64.4664 13.4069V14.5036C64.4706 14.9015 64.4451 15.2992 64.3901 15.6933C64.3538 15.988 64.2585 16.2725 64.1099 16.5296C63.9791 16.7469 63.7861 16.9199 63.5558 17.0262C63.2718 17.1447 62.9654 17.2003 62.6578 17.1893C62.4608 17.1904 62.264 17.1729 62.0703 17.137C61.907 17.1069 61.7454 17.0681 61.5862 17.021C61.4655 16.9857 61.3471 16.9428 61.2318 16.8924C61.1335 16.8506 61.0677 16.8203 61.0227 16.8046V19L61.0248 18.9948ZM61.0133 15.6713C61.131 15.7172 61.2519 15.7543 61.375 15.7821C61.5659 15.8269 61.7614 15.8487 61.9574 15.847C62.1011 15.8511 62.2446 15.8331 62.3829 15.7936C62.4967 15.7588 62.5926 15.6812 62.6505 15.5772C62.7289 15.4314 62.7763 15.2708 62.7895 15.1057C62.8203 14.8326 62.834 14.5578 62.8303 14.283V13.6066C62.8339 13.3317 62.8203 13.0569 62.7895 12.7838C62.7751 12.6189 62.7278 12.4586 62.6505 12.3123C62.5912 12.2095 62.4958 12.1324 62.3829 12.0959C62.2443 12.0583 62.1009 12.041 61.9574 12.0447C61.7627 12.0423 61.5686 12.0662 61.3803 12.1158C61.2046 12.1607 61.0823 12.1973 61.0133 12.2203V15.6713Z"
        fill="#323E48"
      />
      <path
        d="M68.2457 17.1925C67.7979 17.206 67.3509 17.1467 66.9222 17.0168C66.6056 16.9159 66.3286 16.7185 66.1297 16.4523C65.9235 16.1529 65.7897 15.8097 65.7387 15.4497C65.6621 14.9576 65.6271 14.46 65.6342 13.962C65.6273 13.462 65.6623 12.9623 65.7387 12.4681C65.788 12.1077 65.922 11.7641 66.1297 11.4655C66.3303 11.2022 66.6069 11.0069 66.9222 10.9062C67.3509 10.7763 67.7979 10.717 68.2457 10.7305C68.6956 10.7172 69.1446 10.7765 69.5755 10.9062C69.8932 11.0049 70.1721 11.2004 70.3732 11.4655C70.5804 11.7639 70.7127 12.1078 70.759 12.4681C70.8313 12.9627 70.8645 13.4622 70.8583 13.962C70.8646 14.4598 70.8314 14.9572 70.759 15.4497C70.7116 15.8097 70.5794 16.1533 70.3732 16.4523C70.1736 16.72 69.8944 16.9176 69.5755 17.0168C69.1446 17.1465 68.6956 17.2058 68.2457 17.1925ZM68.2457 15.8616C68.4223 15.867 68.5986 15.8452 68.7684 15.7968C68.8339 15.7757 68.8945 15.7418 68.9466 15.6969C68.9986 15.652 69.0412 15.5971 69.0716 15.5354C69.1545 15.354 69.2 15.1577 69.2054 14.9583C69.2243 14.7067 69.2337 14.3746 69.2337 13.962C69.2337 13.5494 69.2243 13.2187 69.2054 12.9699C69.1981 12.7711 69.1526 12.5756 69.0716 12.3939C69.0416 12.331 68.9993 12.2747 68.9473 12.2284C68.8952 12.182 68.8344 12.1466 68.7684 12.1241C68.5984 12.0767 68.4222 12.0548 68.2457 12.0593C68.0667 12.0528 67.8878 12.0747 67.7157 12.1241C67.6507 12.1461 67.5911 12.1815 67.5407 12.2279C67.4903 12.2744 67.4502 12.3309 67.423 12.3939C67.3454 12.5764 67.3021 12.7717 67.2954 12.9699C67.2759 13.2187 67.2661 13.5494 67.2661 13.962C67.2661 14.3746 67.2759 14.7067 67.2954 14.9583C67.3013 15.157 67.3446 15.3528 67.423 15.5354C67.4507 15.597 67.4912 15.6521 67.5416 15.6972C67.592 15.7422 67.6513 15.7761 67.7157 15.7968C67.8878 15.8462 68.0667 15.8681 68.2457 15.8616Z"
        fill="#323E48"
      />
      <path d="M71.9968 12.1053L73.6549 10.8414V17.0712H71.9968V12.1053Z" fill="#323E48" />
      <path
        d="M74.9481 10.8414H76.2476L76.3866 11.2386C76.4253 11.2229 76.5016 11.1895 76.6145 11.1341C76.7274 11.0787 76.8665 11.0212 77.0327 10.9574C77.2154 10.8906 77.4022 10.8358 77.592 10.7933C77.8084 10.7463 78.0292 10.7228 78.2506 10.7232C78.5544 10.7125 78.8569 10.7678 79.1372 10.8853C79.3636 10.9884 79.5543 11.1565 79.685 11.3683C79.828 11.6139 79.9215 11.8853 79.9599 12.167C80.0139 12.5335 80.0394 12.9037 80.0363 13.2741V17.066H78.3886V13.4142C78.3923 13.1804 78.3791 12.9467 78.3489 12.7148C78.3335 12.567 78.286 12.4243 78.2099 12.2966C78.1476 12.2025 78.055 12.1324 77.9475 12.098C77.8115 12.0593 77.6706 12.0413 77.5293 12.0447C77.3347 12.0425 77.1406 12.0661 76.9522 12.1147C76.8271 12.1445 76.7045 12.1836 76.5852 12.2318V17.0618H74.9512V10.8414H74.9481Z"
        fill="#323E48"
      />
      <path
        d="M84.9248 15.7759L84.5986 15.8219C84.374 15.846 84.1481 15.8576 83.9222 15.8564C83.8304 15.8582 83.7388 15.8487 83.6493 15.8281C83.5779 15.8099 83.5156 15.7665 83.4737 15.7058C83.4238 15.6236 83.392 15.5316 83.3807 15.4361C83.3586 15.2859 83.3489 15.1342 83.3514 14.9824V9.61715H82.1627L81.871 10.8414H80.973V12.0886H81.6964V14.9343C81.6926 15.278 81.7142 15.6215 81.7613 15.962C81.7899 16.211 81.8758 16.45 82.0122 16.6603C82.1414 16.8433 82.3239 16.9821 82.5349 17.0576C82.8166 17.1521 83.1129 17.1956 83.4099 17.1862C83.6044 17.1862 83.7936 17.1778 83.9755 17.1632C84.1574 17.1485 84.3226 17.1287 84.4721 17.1036C84.6436 17.0806 84.8025 17.0545 84.9488 17.022L84.9248 15.7759Z"
        fill="#323E48"
      />
      <path d="M84.8694 10.8414H83.366L84.1376 12.0907H84.8694V10.8414Z" fill="#323E48" />
      <path
        d="M57.9753 12.2893C57.9137 11.9748 57.7795 11.6791 57.5832 11.4258C57.3856 11.1856 57.1255 11.0047 56.8315 10.903C56.4518 10.7756 56.0527 10.7154 55.6523 10.7253C55.2258 10.7135 54.8007 10.7794 54.3977 10.9198C54.081 11.0357 53.8027 11.2373 53.5938 11.5021C53.376 11.7994 53.2283 12.1421 53.162 12.5047C53.072 12.9815 53.0306 13.4663 53.0387 13.9516C53.0303 14.4511 53.077 14.9499 53.1777 15.4392C53.251 15.8037 53.4065 16.1466 53.6325 16.4418C53.8439 16.7033 54.1252 16.8994 54.4437 17.0074C54.8298 17.1325 55.234 17.1918 55.6397 17.183C55.9659 17.183 56.2722 17.1715 56.5545 17.1485C56.8368 17.1255 57.0856 17.0984 57.2957 17.066C57.5193 17.0394 57.7413 17.0006 57.9606 16.9499L57.9355 15.7288L57.3407 15.7989C57.1539 15.8142 56.9382 15.8278 56.6935 15.8396C56.4489 15.8511 56.1854 15.8564 55.9074 15.8564C55.7092 15.8608 55.5113 15.839 55.3188 15.7916C55.1712 15.7571 55.0371 15.68 54.933 15.5699C54.8274 15.4506 54.7531 15.3069 54.7166 15.1517C54.6662 14.9416 54.6426 14.7259 54.6466 14.5098L54.5985 13.5533C54.5935 13.2733 54.6127 12.9935 54.656 12.7169C54.6798 12.5435 54.7439 12.3781 54.8431 12.2339C54.9247 12.1236 55.0423 12.0452 55.1755 12.0123C55.3338 11.9755 55.4961 11.9583 55.6585 11.961C55.8017 11.9586 55.9446 11.9737 56.084 12.006C56.1997 12.0345 56.3037 12.0981 56.382 12.1879C56.4689 12.2965 56.529 12.424 56.5576 12.5601C56.6017 12.769 56.6217 12.9823 56.6172 13.1957V13.3839C56.6173 13.4426 56.6134 13.5013 56.6057 13.5595H54.6068L55.4129 14.5161H58.0265C58.0338 14.422 58.0411 14.3206 58.0495 14.2119C58.0578 14.1032 58.0641 14.0101 58.0725 13.8857C58.0808 13.7613 58.084 13.6264 58.084 13.4769C58.0858 13.0782 58.0473 12.6803 57.969 12.2893"
        fill="#323E48"
      />
      <path
        d="M67.0348 7.43005L61.4793 3.19599C61.3402 3.09017 61.1701 3.03288 60.9953 3.03288C60.8205 3.03288 60.6504 3.09017 60.5113 3.19599C60.4133 3.2704 60.3339 3.36645 60.2793 3.47662C60.2246 3.58679 60.1962 3.70811 60.1962 3.8311C60.1962 3.95408 60.2246 4.0754 60.2793 4.18558C60.3339 4.29575 60.4133 4.3918 60.5113 4.46621L66.0668 8.70026C66.2058 8.8073 66.3764 8.86504 66.5519 8.8644C66.719 8.86426 66.8818 8.81171 67.0175 8.71416C67.1532 8.6166 67.2548 8.47894 67.3081 8.32057C67.3615 8.1622 67.3638 7.9911 67.3148 7.83134C67.2657 7.67159 67.1678 7.53124 67.0348 7.43005Z"
        fill="#F8AB14"
      />
      <path
        d="M61.6403 7.43005L58.7884 5.24925C58.6492 5.14343 58.4792 5.08614 58.3043 5.08614C58.1295 5.08614 57.9595 5.14343 57.8203 5.24925C57.7224 5.32366 57.643 5.4197 57.5883 5.52988C57.5337 5.64005 57.5052 5.76137 57.5052 5.88436C57.5052 6.00734 57.5337 6.12866 57.5883 6.23883C57.643 6.34901 57.7224 6.44505 57.8203 6.51946L60.6702 8.70026C60.8386 8.82878 61.0512 8.88512 61.2612 8.85689C61.4712 8.82865 61.6613 8.71816 61.7898 8.54972C61.9184 8.38128 61.9747 8.16869 61.9465 7.95871C61.9182 7.74873 61.8077 7.55856 61.6393 7.43005H61.6403Z"
        fill="#F8AB14"
      />
      <path
        d="M56.5982 7.43005L56.2636 7.17496C56.0953 7.04658 55.8829 6.99032 55.6731 7.01856C55.4633 7.04679 55.2734 7.1572 55.145 7.3255C55.0166 7.49381 54.9603 7.70621 54.9886 7.916C55.0168 8.12578 55.1272 8.31576 55.2955 8.44413L55.6301 8.70027C55.7691 8.8073 55.9397 8.86504 56.1152 8.8644C56.2823 8.86426 56.4451 8.81171 56.5808 8.71416C56.7165 8.6166 56.8181 8.47894 56.8714 8.32057C56.9248 8.1622 56.9271 7.9911 56.8781 7.83134C56.829 7.67159 56.7311 7.53124 56.5982 7.43005Z"
        fill="#F8AB14"
      />
      <path
        d="M77.8249 7.43005L71.1184 2.30736C70.9629 2.18833 70.7726 2.12383 70.5768 2.12383C70.381 2.12383 70.1907 2.18833 70.0353 2.30736L67.8733 3.95498L64.1682 1.15319C63.9994 1.0302 63.7892 0.978212 63.5825 1.00838C63.3758 1.03854 63.1892 1.14846 63.0626 1.31457C62.936 1.48068 62.8795 1.68977 62.9052 1.89704C62.9309 2.1043 63.0368 2.29326 63.2001 2.4234L71.4592 8.69609C71.5426 8.75979 71.6377 8.80644 71.7391 8.83337C71.8406 8.86031 71.9463 8.86701 72.0503 8.85307C72.1544 8.83914 72.2546 8.80486 72.3454 8.75218C72.4362 8.69951 72.5157 8.62947 72.5794 8.54606C72.6431 8.46266 72.6897 8.36753 72.7167 8.26609C72.7436 8.16466 72.7503 8.05891 72.7364 7.95489C72.7224 7.85087 72.6881 7.75062 72.6355 7.65985C72.5828 7.56908 72.5128 7.48957 72.4294 7.42587L69.1885 4.95548L70.5758 3.90271L76.8547 8.69817C76.9383 8.7616 77.0335 8.80796 77.1349 8.83459C77.2364 8.86122 77.3421 8.8676 77.446 8.85338C77.5499 8.83916 77.65 8.80461 77.7406 8.7517C77.8312 8.69879 77.9104 8.62856 77.9739 8.54502C78.0373 8.46148 78.0837 8.36626 78.1103 8.2648C78.1369 8.16335 78.1433 8.05764 78.1291 7.95372C78.1149 7.8498 78.0803 7.74969 78.0274 7.65913C77.9745 7.56856 77.9043 7.48929 77.8207 7.42587L77.8249 7.43005Z"
        fill="#F8AB14"
      />
    </svg>
  );
}

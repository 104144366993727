import {
  Body,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Tooltip,
} from '@meterup/atto';
import { DateTime } from 'luxon';
import React from 'react';

import type { DashboardEvent } from '../../../../../utils/events';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';

type EventSummaryDrawerProps = {
  event: DashboardEvent;
};

export default function EventSummaryDrawer({ event }: EventSummaryDrawerProps) {
  return (
    <Drawer>
      <DrawerHeader icon="log" heading="Event" onClose={useCloseDrawerCallback()} />
      <DrawerContent gutter="none" spacing={space(0)}>
        <Sections>
          <Section relation="stacked">
            <SectionHeader heading="Metadata" />
            <SectionContent gutter="all">
              <SummaryList gutter="none">
                <SummaryListRow>
                  <Tooltip sideOffset={0} contents={DateTime.local().zoneName}>
                    <SummaryListKey>Time</SummaryListKey>
                  </Tooltip>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy the event's timestamp"
                      relation="stacked"
                      size="small"
                      value={event.time}
                    >
                      <Body family="monospace">{event.time}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Event</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy the event's type"
                      relation="stacked"
                      size="small"
                      value={event.type}
                    >
                      {event.type}
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
          <Section relation="stacked">
            <SectionHeader heading="Description" />
            <SectionContent gutter="all">
              <CopyBox
                aria-label="Copy the event's description"
                relation="stacked"
                size="large"
                value={event.description}
              >
                <Body family="monospace">{event.description}</Body>
              </CopyBox>
            </SectionContent>
          </Section>
        </Sections>
      </DrawerContent>
    </Drawer>
  );
}

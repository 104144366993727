import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  space,
  Text,
  TextInput,
  VStack,
} from '@meterup/atto';
import { getErrorMessage, isDefined } from '@meterup/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { deleteVPNClient } from '../../../../../../api/vpn';
import { useCloseDrawerCallback } from '../../../../../../hooks/useCloseDrawerCallback';

export function DeleteVPNClientDialog({
  controllerName,
  vpnClientName,
  vpnClientSid,
  state,
}: {
  controllerName: string;
  vpnClientName: string;
  vpnClientSid: string;
  state: OverlayTriggerState;
}) {
  const [confirmDeleteString, setConfirmDeleteString] = useState<string>('');
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const doesConfirmDeleteStringMatch = confirmDeleteString === vpnClientName;

  const resetState = () => {
    setConfirmDeleteString('');
  };

  const deleteVPNClientMutation = useMutation(
    async () => {
      await deleteVPNClient(controllerName, vpnClientSid);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName, 'vpn-clients']);
        resetState();
        state.close();
        closeDrawer();
      },
    },
  );

  const actionDelete = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (doesConfirmDeleteStringMatch) {
      deleteVPNClientMutation.mutate();
    }
  };

  const errorMessage = isDefined(deleteVPNClientMutation.error)
    ? getErrorMessage(deleteVPNClientMutation.error)
    : null;

  useEffect(() => {
    if (!state.open) {
      resetState();
      deleteVPNClientMutation.reset();
    }
  }, [deleteVPNClientMutation, state]);

  return (
    <Dialog preset="narrow" state={state}>
      <form onSubmit={(e) => actionDelete(e)}>
        <DialogHeader heading="Delete VPN client" />
        <DialogContent gutter="all">
          <VStack align="stretch" spacing={space(12)}>
            {isDefined(errorMessage) && (
              <Alert
                icon="warning"
                variant="negative"
                heading="Failed to remove client"
                copy={errorMessage}
                relation="standalone"
              />
            )}
            <VStack align="stretch" spacing={space(8)}>
              <Body as="p">
                This action cannot be undone. This will permanently remove the VPN client from the
                network, as well as delete any data associated with client{' '}
                <Text weight="bold">{vpnClientName}</Text>.
              </Body>
              <Body as="p">
                Please type{' '}
                <Body as="code" family="monospace" weight="bold">
                  {vpnClientName}
                </Body>{' '}
                to confirm.
              </Body>
            </VStack>
            <TextInput aria-label="Confirm device removal" onChange={setConfirmDeleteString} />
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={state.close}>
                Cancel
              </Button>
              <Button
                variant="destructive"
                disabled={deleteVPNClientMutation.isLoading || !doesConfirmDeleteStringMatch}
                onClick={actionDelete}
              >
                Delete
              </Button>
            </>
          }
        />
      </form>
    </Dialog>
  );
}

import { Pane, PaneContent, PaneHeader } from '@meterup/atto';

import ComplianceDocs from './ComplianceDocs';

export const Meta = () => ({
  path: '/org/:companyName/settings/org/documents',
  title: 'Documents - Org',
});

export default function SettingsOrgDocumentsPage() {
  return (
    <Pane variant="adjusted">
      <PaneHeader icon="document" heading="Documents" />
      <PaneContent gutter="vertical">
        <ComplianceDocs />
      </PaneContent>
    </Pane>
  );
}

import { Sidebar, SidebarGroup, SidebarGroups, SidebarTarget } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { useMemo } from 'react';

import IsCurrentCompanyAdmin from '../../../components/authorization/IsCurrentCompanyAdmin';
import IsCurrentCompanyAndNetworkAdmin from '../../../components/authorization/IsCurrentCompanyAndNetworkAdmin';
import { ReactRouterLink } from '../../../components/ReactRouterLink';
import { paths } from '../../../constants';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { useNetworksForCompany } from '../../../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useDefaultNetwork } from '../../../providers/DefaultNetworkProvider';
import { useCurrentOrDefaultControllerData } from '../../../providers/hooks/useCurrentOrDefaultController';
import { makeLink } from '../../../utils/main_and_drawer_navigation';

export const SETTING_SIDEBAR_ID = 'settings-menu';
const SETTINGS_DESC_ID = 'settings-header';

export function SettingsSidebar() {
  const isActiveTest = useIsActiveMatcher();
  const companyName = useCurrentCompany();
  const defaultController = useCurrentOrDefaultControllerData();
  // defaultNetwork falls back to a "default" default, defaultNetworkUUID is specifically saved
  const { defaultNetwork, defaultNetworkUUID } = useDefaultNetwork();
  const featureFlags = useFeatureFlags();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const showRBAC = featureFlags['rbac-user-management'] || isOperator;

  const networks = useNetworksForCompany(companyName);
  const network = useMemo(() => {
    if (defaultNetworkUUID) {
      const found = networks.find((n) => n.UUID === defaultNetworkUUID) ?? null;
      if (found) return found;
    }

    if (defaultController?.network_uuid) {
      const found = networks.find((n) => n.UUID === defaultController.network_uuid) ?? null;
      if (found) return found;
    }

    return defaultNetwork;
  }, [networks, defaultController?.network_uuid, defaultNetworkUUID, defaultNetwork]);

  return (
    <Sidebar icon="cog" heading="Settings">
      <SidebarGroups id={SETTING_SIDEBAR_ID} aria-labelledby={SETTINGS_DESC_ID}>
        <SidebarGroup label="Organization" id="org">
          {showRBAC && (
            <IsCurrentCompanyAdmin>
              <SidebarTarget
                as={ReactRouterLink}
                to={makeLink(paths.pages.SettingsOrgAuthenticationPage, { companyName })}
                icon="padlock"
                label="Authentication"
                isSelected={isActiveTest({
                  path: paths.pages.SettingsOrgAuthenticationPage,
                  end: true,
                })}
              />
              <SidebarTarget
                as={ReactRouterLink}
                to={makeLink(paths.pages.SettingsOrgUsersPage, { companyName })}
                icon="user"
                label="Users"
                isSelected={isActiveTest({ path: paths.pages.SettingsOrgUsersPage, end: true })}
              />
              {featureFlags['notification-integrations'] && (
                <SidebarTarget
                  as={ReactRouterLink}
                  to={makeLink(paths.pages.WebhooksPage, { companyName })}
                  icon="integrations"
                  label="Integrations"
                  isSelected={isActiveTest({
                    path: paths.pages.SettingsOrgIntegrationsPage,
                    end: false,
                  })}
                />
              )}
            </IsCurrentCompanyAdmin>
          )}
          <SidebarTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.SettingsOrgDocumentsPage, { companyName })}
            icon="document"
            label="Documents"
            isSelected={isActiveTest({ path: paths.pages.SettingsOrgDocumentsPage, end: true })}
          />
        </SidebarGroup>
        <SidebarGroup label="User" id="user">
          <SidebarTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.SettingsUserPreferencesPage, { companyName })}
            icon="preferences"
            label="Preferences"
            data-testid="Settings_userConfigureLink"
            isSelected={isActiveTest({
              path: paths.pages.SettingsUserPreferencesPage,
              end: true,
            })}
          />
        </SidebarGroup>
        {network && (
          <SidebarGroup label="Network" id="network">
            <SidebarTarget
              as={ReactRouterLink}
              to={makeLink(paths.pages.SettingsNetworkGeneralPage, {
                companyName,
                networkSlug: network.slug,
              })}
              icon="information"
              label="General"
              isSelected={isActiveTest({
                path: paths.pages.SettingsNetworkGeneralPage,
                end: true,
              })}
            />
            <IsCurrentCompanyAndNetworkAdmin>
              {featureFlags['notification-settings'] && (
                <SidebarTarget
                  as={ReactRouterLink}
                  to={makeLink(paths.pages.AlertsPage, { companyName, networkSlug: network.slug })}
                  icon="bell"
                  label="Notifications"
                  isSelected={isActiveTest({
                    path: paths.pages.SettingsNetworkNotificationsPage,
                    end: false,
                  })}
                />
              )}
            </IsCurrentCompanyAndNetworkAdmin>
          </SidebarGroup>
        )}
      </SidebarGroups>
    </Sidebar>
  );
}

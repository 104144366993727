import { useMemo } from 'react';

import { size } from 'lodash-es';

import { CellSizingFn } from '../types';

export function useSimpleCellSizing<
  DeriveWidthTag extends keyof HTMLElementTagNameMap,
  DerivePaddingTag extends keyof HTMLElementTagNameMap,
>(deriveWidthTag: DeriveWidthTag, derivePaddingTag: DerivePaddingTag) {
  return useMemo<CellSizingFn>(
    () => (els: Element[]) => {
      if (size(els) === 0) {
        return 100;
      }
      const [firstNode] = els;

      const spanWidths = els.map((el) => {
        const spans = Array.from(el.querySelectorAll(deriveWidthTag));
        if (size(spans) === 0) {
          return 0;
        }
        const [span] = spans;
        return span.getBoundingClientRect().width;
      });

      const firstNodeChild = firstNode.querySelector(derivePaddingTag);
      const computedStyle = window.getComputedStyle(firstNodeChild || firstNode);
      const paddingLeft = parseInt(computedStyle.paddingLeft || '0', 10);
      const paddingRight = parseInt(computedStyle.paddingRight || '0', 10);
      return Math.max(100, ...spanWidths) + paddingLeft + paddingRight;
    },
    [derivePaddingTag, deriveWidthTag],
  );
}

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { UseGraphQLMutationResult } from '@meterup/graphql';
import type { ClientError, Variables } from 'graphql-request';
import {
  type OverlayTriggerState,
  Alert,
  Badge,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FieldContainer,
  HStack,
  Icon,
  Link,
  PrimaryField,
  Small,
  space,
  styled,
  ToggleInput,
  Tooltip,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { isDefined } from '@meterup/common';
import { METER_V1_NETWORK_VPN_IPSEC_PREFIX, MeterControllerConfig } from '@meterup/config';
import {
  getDocumentActions,
  getGraphQLError,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { ErrorBoundary } from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import type { Network } from '../../../hooks/useNetworksForCompany';
import { fetchControllerConfig, listEnabledContentFiltersForCompany } from '../../../api/api';
import { paths } from '../../../constants';
import { getActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { IPSecTunnelsQuery } from '../../../routes/drawers/network/secure_tunnels/ipsec/utils';
import {
  networkMutationAuditLogEntriesQuery,
  networkMutationAuditLogFilter,
  useNetworkMutationAuditLogForNetwork,
} from '../../../routes/pages/network/insights/logs/hooks/useNetworkMutationAuditLog';
import { IPSEC_KEY_PREFIX_REGEX } from '../../../routes/pages/network/secure_tunnels/ipsec/CopyIPSecTunnelDialog';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { pluralize, ucfirst } from '../../../utils/strings';
import { formatTimestamp, updatePhyInterfaceMutation } from '../../Devices/utils';
import { InterVLANCommunicationsCopyDescription } from '../../Firewall/InterVLANCommunication/InterVLANCommunication';
import {
  copyInterVLANCommunicationFromConfig1Mutation,
  updateInterVLANCommunicationPermittedPairsMutation,
} from '../../Firewall/InterVLANCommunication/utils';
import { portForwardingRulesForNetworkQuery } from '../../Firewall/PortForwarding/PortForwarding';
import {
  createPortForwardingRule,
  deletePortForwardingRule,
  updatePortForwardingRule,
} from '../../Firewall/PortForwarding/utils';
import {
  createFirewallRule,
  deleteFirewallRule,
  firewallRulesForNetwork,
  updateFirewallRule,
  uplinkPhyInterfacesQuery,
  vlansForFirewallQuery,
} from '../../Firewall/utils';
import { CopyNetworkUplinksDescription } from '../../Hardware/SecurityAppliance/SecurityApplianceActions';
import { DeviceLastDayCellularUsageQuery } from '../../Hardware/SecurityAppliance/utils';
import { InternetServicePlansCopyDescription } from '../../NetworkWide/ISPs/ISPsActions';
import { copyISPsToInternetServicePlansMutation } from '../../NetworkWide/ISPs/utils';
import {
  copyDNSHostMappingsMutation,
  copyVLANsMutation,
  createDHCPRuleMutation,
  createDNSHostMapping,
  deleteDHCPRuleMutation,
  deleteDNSHostMapping,
  updateDHCPRuleMutation,
  updateDNSHostMapping,
  updateVLANMutation,
  vlansQuery,
} from '../../NetworkWide/VLANs/utils';
import { VLANsCopyDescription } from '../../NetworkWide/VLANs/VLANList';
import {
  CopyRadioSettingsMutation,
  CreateRadioProfileMutation,
  DeleteRadioProfileMutation,
  RadiosCopyDescription,
  UpdateRadioProfileMutation,
} from '../../Wireless/RadioProfiles/utils';
import {
  copySSIDsMutation,
  CreateSSIDMutation,
  DeleteSSIDMutation,
  SSIDsCopyDescription,
  SSIDsQuery,
  UpdateSSIDMutation,
} from '../../Wireless/SSIDs/SSIDsUtils';
import {
  AccessPointLabelsCopyDescription,
  AccessPointsQuery,
  CopyAccessPointLabelsMutation,
} from '../../Wireless/utils';
import {
  copyFirewallRulesMutation,
  copyMultiWANConfigMutation,
  copyPortForwardingRulesMutation,
  copyUplinkPhyInterfacesMutation,
  Mono,
} from './utils';

type MutationState = 'error' | 'idle' | 'loading' | 'success';

function IconForMutationState({
  state,
  isSelected,
  setSelected,
}: {
  state: MutationState;
  isSelected: boolean;
  setSelected: (selected: boolean) => void;
}) {
  switch (state) {
    case 'success':
      return <Icon icon="checkmark" size={20} color={{ light: 'green800', dark: 'green50' }} />;
    case 'error':
      return <Icon icon="cross" size={20} color={{ light: 'red600', dark: 'red700' }} />;
    case 'loading':
    default:
      return <ToggleInput selected={isSelected} onChange={setSelected} aria-label="Run copier" />;
  }
}

const MigrationStateContents = styled(VStack, {
  flex: '1 1 auto',
  cursor: 'pointer',
});
MigrationStateContents.displayName = 'MigrationStateContents';

const SummaryChevronIcon = styled(Icon, {
  marginRight: '$8',
});
SummaryChevronIcon.displayName = 'SummaryChevronIcon';

const MigrationStateContainer = styled(HStack, {
  variants: {
    isSelected: {
      true: {},
      false: {
        opacity: 0.7,
      },
    },
  },
});
MigrationStateContainer.displayName = 'MigrationStateContainer';

function MigrationMutationStatus({
  networkUUID,
  mutationDocument,
  conflictingMutationDocuments,
}: {
  networkUUID: string;
  mutationDocument: TypedDocumentNode<any, any>;
  conflictingMutationDocuments?: DocumentWithVariables[];
}) {
  const mutations = useNetworkMutationAuditLogForNetwork(networkUUID);

  const mutationAction: string | undefined = useMemo(
    () => getDocumentActions(mutationDocument)?.[0],
    [mutationDocument],
  );

  const migrationLogs = useMemo(
    () => mutations.filter((log) => log.action === mutationAction),
    [mutations, mutationAction],
  );

  const conflictingLogs = useMemo(() => {
    if (!conflictingMutationDocuments) return [];

    return mutations.filter((log) =>
      conflictingMutationDocuments.some(({ document, variables }) => {
        const action = getDocumentActions(document)?.[0];
        if (!action) return false;

        return (
          log.action === action &&
          (!variables || Object.entries(variables).every(([key, value]) => log.args[key] === value))
        );
      }),
    );
  }, [mutations, conflictingMutationDocuments]);

  const mutationLogsSinceCopy = useMemo(() => {
    if (!migrationLogs?.length) return conflictingLogs;

    return conflictingLogs?.filter((log) => log.createdAt > migrationLogs[0].createdAt);
  }, [migrationLogs, conflictingLogs]);

  if (!migrationLogs?.length) {
    return (
      <Tooltip asChild={false} contents="Copier has not been run before.">
        <Badge icon="cross" arrangement="hidden-label" variant="neutral">
          Copier has not been run before.
        </Badge>
      </Tooltip>
    );
  }

  if (mutationLogsSinceCopy?.length) {
    return (
      <Tooltip
        asChild={false}
        contents={`${mutationLogsSinceCopy.length} ${pluralize(mutationLogsSinceCopy.length, 'mutation has', 'mutations have')} been run since the last time the copier was run on ${formatTimestamp(migrationLogs[0]?.createdAt)}.`}
      >
        <Badge icon="warning" arrangement="hidden-label" variant="attention">
          {mutationLogsSinceCopy.length} mutations have been run since the last time the copier was
          run.
        </Badge>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      asChild={false}
      contents={`Copier has been run successfully on ${formatTimestamp(migrationLogs[0]?.createdAt)}, no conflicting mutations found since.`}
    >
      <Badge icon="checkmark" arrangement="hidden-label" variant="positive">
        Copier has been run successfully, no conflicting mutations found since.
      </Badge>
    </Tooltip>
  );
}

function MigrationState({
  networkUUID,
  label,
  description,
  state,
  error,
  isSelected,
  setSelected,
  mutationDocument,
  conflictingMutationDocuments,
}: {
  networkUUID: string;
  label: string;
  description: React.ReactNode;
  state: MutationState;
  error: ClientError | null;
  isSelected: boolean;
  setSelected: (selected: boolean) => void;
  mutationDocument: TypedDocumentNode<any, any>;
  conflictingMutationDocuments?: DocumentWithVariables[];
}) {
  const [showDescription, setShowDescription] = useState(false);
  const graphqlError = error ? getGraphQLError(error) : null;
  const errorMessage = graphqlError ? ucfirst(graphqlError.message) : null;

  return (
    <MigrationStateContainer spacing={space(8)} align="start" isSelected={isSelected}>
      <IconForMutationState state={state} isSelected={isSelected} setSelected={setSelected} />

      <Tooltip contents="Press to toggle description">
        <MigrationStateContents
          spacing={space(8)}
          onClick={() => {
            setShowDescription((prev) => !prev);
          }}
        >
          <HStack justify="between" align="start">
            <Body>
              <HStack align="center">
                <SummaryChevronIcon icon={showDescription ? 'chevron-down' : 'chevron-right'} />
                {label}
              </HStack>
            </Body>
          </HStack>
          {showDescription && <Small>{description}</Small>}
          {error && (
            <Alert
              type="inline"
              variant="negative"
              heading={`Error copying ${label}`}
              copy={errorMessage}
            />
          )}
        </MigrationStateContents>
      </Tooltip>

      <MigrationMutationStatus
        networkUUID={networkUUID}
        mutationDocument={mutationDocument}
        conflictingMutationDocuments={conflictingMutationDocuments}
      />
    </MigrationStateContainer>
  );
}

function DNSSecurityStatus() {
  const companyName = useCurrentCompany();

  const dnsSecurityStatus = useQuery(
    ['dns_security', companyName, 'status'],
    async () => listEnabledContentFiltersForCompany(companyName),
    {
      suspense: true,
      useErrorBoundary: false,
      retry: false,
    },
  );

  if (dnsSecurityStatus.isError) {
    const error =
      dnsSecurityStatus.error && dnsSecurityStatus.error instanceof Error
        ? dnsSecurityStatus.error
        : null;

    return (
      <Alert
        icon="warning"
        variant="negative"
        heading="Error fetching DNS security status"
        copy={error?.message}
      />
    );
  }

  if (dnsSecurityStatus.data?.enabled?.length) {
    return (
      <Alert icon="warning" variant="attention" heading="DNS security enabled for controller" />
    );
  }

  return null;
}

function ClientVPNStatus({ controllerName }: { controllerName: string }) {
  const controllerConfig = useQuery(
    ['controller', controllerName, 'config'],
    async () => {
      const response = await fetchControllerConfig(controllerName!);
      return isDefined(response) ? MeterControllerConfig.fromJSON(response.config as any) : null;
    },
    {
      suspense: true,
      useErrorBoundary: false,
      retry: false,
    },
  );

  if (controllerConfig.isError) {
    const error =
      controllerConfig.error && controllerConfig.error instanceof Error
        ? controllerConfig.error
        : null;

    return (
      <Alert
        icon="warning"
        variant="negative"
        heading="Error fetching controller config"
        copy={error?.message}
      />
    );
  }

  if (controllerConfig.data?.hasVPNEnabled()) {
    return <Alert icon="warning" variant="attention" heading="Client VPN enabled for controller" />;
  }

  return null;
}

function VPPEpilogueStatus({ controllerName }: { controllerName: string }) {
  const controllerConfig = useQuery(
    ['controller', controllerName, 'config'],
    async () => {
      const response = await fetchControllerConfig(controllerName!);
      return isDefined(response) ? MeterControllerConfig.fromJSON(response.config as any) : null;
    },
    {
      suspense: true,
      useErrorBoundary: false,
      retry: false,
    },
  );

  if (controllerConfig.isError) {
    const error =
      controllerConfig.error && controllerConfig.error instanceof Error
        ? controllerConfig.error
        : null;

    return (
      <Alert
        icon="warning"
        variant="negative"
        heading="Error fetching controller config"
        copy={error?.message}
      />
    );
  }

  if (controllerConfig.data?.vppConf()?.epilogue) {
    return (
      <Alert icon="warning" variant="attention" heading="Controller contains VPP epilogue config" />
    );
  }

  return null;
}

function IPSecStatus({ network, controllerName }: { network: Network; controllerName: string }) {
  const companyName = useCurrentCompany();

  const controllerConfig = useQuery(
    ['controller', controllerName, 'config'],
    async () => {
      const response = await fetchControllerConfig(controllerName!);
      return isDefined(response) ? MeterControllerConfig.fromJSON(response.config as any) : null;
    },
    {
      suspense: true,
      useErrorBoundary: false,
      retry: false,
    },
  );

  const config1IPSecTunnelNames = useMemo(
    () =>
      controllerConfig?.data
        ? Object.keys(controllerConfig?.data.json)
            .filter((key) => key.startsWith(METER_V1_NETWORK_VPN_IPSEC_PREFIX))
            .map((key) => key.replace(IPSEC_KEY_PREFIX_REGEX, ''))
            .sort((a, b) => a.localeCompare(b))
        : [],
    [controllerConfig],
  );

  const ipSecTunnels = useGraphQL(IPSecTunnelsQuery, { networkUUID: network.UUID })?.data
    ?.ipSecTunnelsForNetwork;

  const existingIPSecTunnelNames = useMemo(
    () => new Set(ipSecTunnels?.map((ipsecTunnel) => ipsecTunnel.name)),
    [ipSecTunnels],
  );

  const uncopiedIPSecTunnelsExist = useMemo(
    () => config1IPSecTunnelNames.some((config1Name) => !existingIPSecTunnelNames.has(config1Name)),
    [config1IPSecTunnelNames, existingIPSecTunnelNames],
  );

  if (controllerConfig.isError) {
    const error =
      controllerConfig.error && controllerConfig.error instanceof Error
        ? controllerConfig.error
        : null;

    return (
      <Alert
        icon="warning"
        variant="negative"
        heading="Error fetching controller config"
        copy={error?.message}
      />
    );
  }

  if (controllerConfig.data?.hasIPSecVPNEnabled()) {
    if (!uncopiedIPSecTunnelsExist) {
      return (
        <Alert
          icon="checkmark-circle"
          variant="positive"
          heading="IPSec VPN tunnels all copied from controller"
        />
      );
    }

    return (
      <Alert
        icon="warning"
        variant="attention"
        heading="IPSec VPN enabled for controller"
        copy={
          <>
            Please copy the IPSec config from the overflow menu at the top of the{' '}
            <Link
              as={ReactRouterLink}
              to={makeLink(paths.pages.IPSecTunnelsPage, {
                companyName,
                networkSlug: network.slug,
              })}
            >
              IPSec tunnels page
            </Link>
            , copying IPSec tunnels requires input of the preshared key retreived from the
            controller's disk.
          </>
        }
      />
    );
  }

  return null;
}

function WANACLStatus({ controllerName }: { controllerName: string }) {
  const controllerConfig = useQuery(
    ['controller', controllerName, 'config'],
    async () => {
      const response = await fetchControllerConfig(controllerName!);
      return isDefined(response) ? MeterControllerConfig.fromJSON(response.config as any) : null;
    },
    {
      suspense: true,
      useErrorBoundary: false,
      retry: false,
    },
  );

  if (controllerConfig.isError) {
    const error =
      controllerConfig.error && controllerConfig.error instanceof Error
        ? controllerConfig.error
        : null;

    return (
      <Alert
        icon="warning"
        variant="negative"
        heading="Error fetching controller config"
        copy={error?.message}
      />
    );
  }

  if (controllerConfig.data?.hasWANACL()) {
    return <Alert icon="warning" variant="attention" heading="Controller contains WAN ACL rules" />;
  }

  return null;
}

function CellularStatusAlert({ serialNumber }: { serialNumber: string }) {
  const { data, isError, error } = useGraphQL(
    DeviceLastDayCellularUsageQuery,
    {
      serialNumber,
    },
    { useErrorBoundary: false },
  );

  if (isError || !data?.deviceLastDayCellularUsage) {
    return (
      <Alert
        icon="warning"
        variant="negative"
        heading="Error fetching cellular usage for security appliance"
        copy={error?.message}
      />
    );
  }

  if (data.deviceLastDayCellularUsage.downloadBytes === 0) {
    return (
      <Alert
        icon="warning"
        variant="attention"
        heading="Security appliance has 0 downloaded bytes over cellular in the last 24 hours"
      />
    );
  }

  if (data.deviceLastDayCellularUsage.uploadBytes === 0) {
    return (
      <Alert
        icon="warning"
        variant="attention"
        heading="Security appliance has 0 uploaded bytes over cellular in the last 24 hours"
      />
    );
  }

  return null;
}

function IncompatibleMigrationChecks({ network }: { network: Network }) {
  const controller = getActiveControllerForNetwork(network);
  const controllerName = controller?.hardwareDevice?.serialNumber;

  return (
    <>
      <DNSSecurityStatus />
      {controllerName ? (
        <>
          <ClientVPNStatus controllerName={controllerName} />
          <VPPEpilogueStatus controllerName={controllerName} />
          <IPSecStatus network={network} controllerName={controllerName} />
          <WANACLStatus controllerName={controllerName} />
          <CellularStatusAlert serialNumber={controllerName} />
        </>
      ) : (
        <Alert
          icon="warning"
          variant="negative"
          heading="Failed to find active config 1 controller for network."
        />
      )}
    </>
  );
}

interface DocumentWithVariables<TVariables extends Variables = any> {
  document: TypedDocumentNode<any, TVariables>;
  variables?: Partial<TVariables>;
}

interface MigrationDef<TData, TVariables extends Variables> {
  label: string;
  description: React.ReactNode;
  mutation: Pick<
    UseGraphQLMutationResult<TData, TVariables>,
    'mutateAsync' | 'reset' | 'error' | 'status'
  >;
  mutationDocument: TypedDocumentNode<any, TVariables>;
  queryDependencies?: TypedDocumentNode<any, TVariables>[];
  conflictingMutationDocuments?: DocumentWithVariables[];
}

function useMigrationMutations(networkUUID: string) {
  const {
    mutateAsync: copyMultiWAN,
    reset: resetMultiWAN,
    error: errorMultiWAN,
    status: statusMultiWAN,
  } = useGraphQLMutation(copyMultiWANConfigMutation, { useErrorBoundary: false });
  const {
    mutateAsync: copyFirewallRules,
    reset: resetFirewallRules,
    error: errorFirewallRules,
    status: statusFirewallRules,
  } = useGraphQLMutation(copyFirewallRulesMutation, {
    useErrorBoundary: false,
  });
  const {
    mutateAsync: copyPortForwards,
    reset: resetPortForwards,
    error: errorPortForwards,
    status: statusPortForwards,
  } = useGraphQLMutation(copyPortForwardingRulesMutation, {
    useErrorBoundary: false,
  });
  const {
    mutateAsync: copyUplinks,
    reset: resetUplinks,
    error: errorUplinks,
    status: statusUplinks,
  } = useGraphQLMutation(copyUplinkPhyInterfacesMutation, {
    useErrorBoundary: false,
  });
  const {
    mutateAsync: copySSIDs,
    reset: resetSSIDs,
    error: errorSSIDs,
    status: statusSSIDs,
  } = useGraphQLMutation(copySSIDsMutation, { useErrorBoundary: false });
  const {
    mutateAsync: copyVLANs,
    reset: resetVLANs,
    error: errorVLANs,
    status: statusVLANs,
  } = useGraphQLMutation(copyVLANsMutation, { useErrorBoundary: false });
  const {
    mutateAsync: copyDNSHostMappings,
    reset: resetDNSHostMappings,
    error: errorDNSHostMappings,
    status: statusDNSHostMappings,
  } = useGraphQLMutation(copyDNSHostMappingsMutation, {
    useErrorBoundary: false,
  });
  const {
    mutateAsync: copyInterVLANCommunication,
    reset: resetInterVLANCommunication,
    error: errorInterVLANCommunication,
    status: statusInterVLANCommunication,
  } = useGraphQLMutation(copyInterVLANCommunicationFromConfig1Mutation, {
    useErrorBoundary: false,
  });
  const {
    mutateAsync: copyRadios,
    reset: resetRadios,
    error: errorRadios,
    status: statusRadios,
  } = useGraphQLMutation(CopyRadioSettingsMutation, { useErrorBoundary: false });
  const {
    mutateAsync: copyLabels,
    reset: resetLabels,
    error: errorLabels,
    status: statusLabels,
  } = useGraphQLMutation(CopyAccessPointLabelsMutation, { useErrorBoundary: false });
  const {
    mutateAsync: copyISPs,
    reset: resetISPs,
    error: errorISPs,
    status: statusISPs,
  } = useGraphQLMutation(copyISPsToInternetServicePlansMutation, {
    useErrorBoundary: false,
  });

  const networkUplinks = useGraphQL(uplinkPhyInterfacesQuery, { networkUUID }).data
    ?.uplinkPhyInterfacesForNetwork;

  const migrationMutations: MigrationDef<any, { networkUUID: string; operator?: boolean }>[] =
    useMemo(
      () => [
        {
          label: 'MultiWAN',
          description: (
            <>
              Copies the <Mono>algorithm</Mono> value from the <Mono>wan-failover</Mono> section of
              the config.
            </>
          ),
          mutation: {
            mutateAsync: copyMultiWAN,
            reset: resetMultiWAN,
            error: errorMultiWAN,
            status: statusMultiWAN,
          },
          mutationDocument: copyMultiWANConfigMutation,
        },
        {
          label: 'Network uplinks',
          description: <CopyNetworkUplinksDescription />,
          mutation: {
            mutateAsync: copyUplinks,
            reset: resetUplinks,
            error: errorUplinks,
            status: statusUplinks,
          },
          mutationDocument: copyUplinkPhyInterfacesMutation,
          conflictingMutationDocuments: networkUplinks?.map((phyInterface) => ({
            document: updatePhyInterfaceMutation,
            variables: { UUID: phyInterface.UUID },
          })),
        },
        {
          label: 'VLANs',
          description: <VLANsCopyDescription />,
          mutation: {
            mutateAsync: copyVLANs,
            reset: resetVLANs,
            error: errorVLANs,
            status: statusVLANs,
          },
          queryDependencies: [vlansQuery],
          mutationDocument: copyVLANsMutation,
          conflictingMutationDocuments: [
            { document: updateVLANMutation },
            { document: createDHCPRuleMutation },
            { document: updateDHCPRuleMutation },
            { document: deleteDHCPRuleMutation },
          ],
        },
        {
          label: 'DNS host mappings',
          description: (
            <>
              Copies <Mono>dns.local-overrides</Mono> configs to DNS host mappings with destination
              IP addresses. Existing config 2 DNS host mappings are not updated or deleted, only
              missing mappings are created. The <Mono>all-vlan-tap-addresses</Mono> field is ignored
              as tap addresses are no longer used.
            </>
          ),
          mutation: {
            mutateAsync: copyDNSHostMappings,
            reset: resetDNSHostMappings,
            error: errorDNSHostMappings,
            status: statusDNSHostMappings,
          },
          mutationDocument: copyDNSHostMappingsMutation,
          conflictingMutationDocuments: [
            { document: createDNSHostMapping },
            { document: updateDNSHostMapping },
            { document: deleteDNSHostMapping },
          ],
        },
        {
          label: 'Firewall rules',
          description: (
            <>
              Copies <Mono>acl-rules</Mono> from <Mono>vlan</Mono> and <Mono>wan</Mono> sections of
              the config.
            </>
          ),
          mutation: {
            mutateAsync: copyFirewallRules,
            reset: resetFirewallRules,
            error: errorFirewallRules,
            status: statusFirewallRules,
          },
          mutationDocument: copyFirewallRulesMutation,
          queryDependencies: [firewallRulesForNetwork],
          conflictingMutationDocuments: [
            { document: createFirewallRule },
            {
              document: updateFirewallRule,
            },
            {
              document: deleteFirewallRule,
            },
          ],
        },
        {
          label: 'Inter-VLAN communication',
          description: <InterVLANCommunicationsCopyDescription />,
          mutation: {
            mutateAsync: copyInterVLANCommunication,
            reset: resetInterVLANCommunication,
            error: errorInterVLANCommunication,
            status: statusInterVLANCommunication,
          },
          mutationDocument: copyInterVLANCommunicationFromConfig1Mutation,
          queryDependencies: [vlansForFirewallQuery],
          conflictingMutationDocuments: [
            { document: updateInterVLANCommunicationPermittedPairsMutation },
            { document: updateVLANMutation },
          ],
        },
        {
          label: 'Port forwards',
          description: (
            <>
              Copies <Mono>port-forwards</Mono> from <Mono>nat</Mono> section of the config.
            </>
          ),
          mutation: {
            mutateAsync: copyPortForwards,
            reset: resetPortForwards,
            error: errorPortForwards,
            status: statusPortForwards,
          },
          mutationDocument: copyPortForwardingRulesMutation,
          queryDependencies: [portForwardingRulesForNetworkQuery],
          conflictingMutationDocuments: [
            { document: createPortForwardingRule },
            { document: updatePortForwardingRule },
            { document: deletePortForwardingRule },
          ],
        },
        {
          label: 'SSIDs',
          description: <SSIDsCopyDescription />,
          mutation: {
            mutateAsync: copySSIDs,
            reset: resetSSIDs,
            error: errorSSIDs,
            status: statusSSIDs,
          },
          mutationDocument: copySSIDsMutation,
          queryDependencies: [SSIDsQuery],
          conflictingMutationDocuments: [
            { document: CreateSSIDMutation },
            { document: UpdateSSIDMutation },
            { document: DeleteSSIDMutation },
          ],
        },
        {
          label: 'Access point radio settings and radio profiles',
          description: <RadiosCopyDescription />,
          mutation: {
            mutateAsync: copyRadios,
            reset: resetRadios,
            error: errorRadios,
            status: statusRadios,
          },
          mutationDocument: CopyRadioSettingsMutation,
          queryDependencies: [AccessPointsQuery],
          conflictingMutationDocuments: [
            { document: CreateRadioProfileMutation },
            { document: UpdateRadioProfileMutation },
            { document: DeleteRadioProfileMutation },
          ],
        },
        {
          label: 'Access point labels',
          description: <AccessPointLabelsCopyDescription />,
          mutation: {
            mutateAsync: copyLabels,
            reset: resetLabels,
            error: errorLabels,
            status: statusLabels,
          },
          mutationDocument: CopyAccessPointLabelsMutation,
          queryDependencies: [AccessPointsQuery],
        },
        {
          label: 'Internet service providers',
          description: <InternetServicePlansCopyDescription />,
          mutation: {
            mutateAsync: copyISPs,
            reset: resetISPs,
            error: errorISPs,
            status: statusISPs,
          },
          mutationDocument: copyISPsToInternetServicePlansMutation,
        },
      ],
      [
        copyMultiWAN,
        resetMultiWAN,
        errorMultiWAN,
        statusMultiWAN,
        copyFirewallRules,
        resetFirewallRules,
        errorFirewallRules,
        statusFirewallRules,
        copyInterVLANCommunication,
        resetInterVLANCommunication,
        errorInterVLANCommunication,
        statusInterVLANCommunication,
        copyPortForwards,
        resetPortForwards,
        errorPortForwards,
        statusPortForwards,
        copyUplinks,
        resetUplinks,
        errorUplinks,
        statusUplinks,
        copySSIDs,
        resetSSIDs,
        errorSSIDs,
        statusSSIDs,
        copyVLANs,
        resetVLANs,
        errorVLANs,
        statusVLANs,
        copyDNSHostMappings,
        resetDNSHostMappings,
        errorDNSHostMappings,
        statusDNSHostMappings,
        copyRadios,
        resetRadios,
        errorRadios,
        statusRadios,
        copyLabels,
        resetLabels,
        errorLabels,
        statusLabels,
        copyISPs,
        resetISPs,
        errorISPs,
        statusISPs,
        networkUplinks,
      ],
    );

  return migrationMutations;
}

function MigrateDialog({ network, state }: { network: Network; state: OverlayTriggerState }) {
  const networkUUID = network.UUID;
  const queryClient = useQueryClient();

  const migrationMutations = useMigrationMutations(networkUUID);
  const [selectedMigrations, setSelectedMigrations] = useState(
    () => new Set(migrationMutations.map((migration) => migration.label)),
  );

  useEffect(() => {
    if (!state.isOpen) {
      for (const { mutation } of migrationMutations) {
        mutation.reset();
      }
    }
  }, [migrationMutations, state.isOpen]);

  const handleCopy = useCallback(async () => {
    for (const { label, mutation, queryDependencies } of migrationMutations) {
      if (!selectedMigrations.has(label)) {
        continue;
      }

      try {
        // Explicitly want to do these in order
        // eslint-disable-next-line no-await-in-loop
        await mutation.mutateAsync(
          { networkUUID },
          {
            onSuccess: queryDependencies
              ? () => {
                  queryDependencies.forEach((query) => {
                    queryClient.invalidateQueries(makeQueryKey(query, { networkUUID }));
                  });
                }
              : undefined,
          },
        );
      } catch (err) {
        // Nothing to do, handled in mutation result
      }

      queryClient.invalidateQueries(
        makeQueryKey(networkMutationAuditLogEntriesQuery, {
          networkUUID,
          filter: networkMutationAuditLogFilter(),
        }),
      );
    }
  }, [migrationMutations, selectedMigrations, queryClient, networkUUID]);

  const handleReset = useCallback(() => {
    for (const migration of migrationMutations) {
      migration.mutation.reset();
    }
  }, [migrationMutations]);

  const migrationsLoading = migrationMutations.some(
    ({ mutation }) => mutation.status === 'loading',
  );
  const migrationsSuccessful = migrationMutations.every(
    ({ mutation }) => mutation.status === 'success',
  );

  const someSelected = migrationMutations.some(({ label }) => selectedMigrations.has(label));
  const allSelected = migrationMutations.every(({ label }) => selectedMigrations.has(label));

  return (
    <Dialog state={state}>
      <DialogHeader icon="upgrading" heading="Copy settings" />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>
          <HStack spacing={space(12)}>
            <ToggleInput
              selected={allSelected}
              indeterminate={!allSelected && someSelected}
              onChange={(selected) => {
                if (selected) {
                  setSelectedMigrations(new Set(migrationMutations.map(({ label }) => label)));
                } else {
                  setSelectedMigrations(new Set());
                }
              }}
            />
            <Body>
              Copy the following settings from config 1 to config 2. Selected copiers below will be
              run in order from top to bottom. Press a label to view details about the copier.
            </Body>
          </HStack>
          <VStack spacing={space(8)}>
            {migrationMutations.map((migration) => (
              <MigrationState
                key={migration.label}
                networkUUID={networkUUID}
                label={migration.label}
                isSelected={selectedMigrations.has(migration.label)}
                setSelected={(selected) => {
                  setSelectedMigrations((prev) => {
                    const next = new Set(prev);
                    if (selected) {
                      next.add(migration.label);
                    } else {
                      next.delete(migration.label);
                    }
                    return next;
                  });
                }}
                description={migration.description}
                state={migration.mutation.status}
                error={migration.mutation.error}
                mutationDocument={migration.mutationDocument}
                conflictingMutationDocuments={migration.conflictingMutationDocuments}
              />
            ))}
          </VStack>

          <Alert
            variant="neutral"
            icon="information"
            copy="If there are conflicts in config 2 (i.e. an SSID exists in config 2 that also exists in config 1, the settings from config 1 will overwrite the settings in config 2). If you have any questions, please reach out to someone in #team-eng-application."
          />
          <IncompatibleMigrationChecks network={network} />
        </VStack>
      </DialogContent>
      <DialogFooter
        actions={
          migrationsSuccessful ? (
            <Button variant="primary" onClick={state.close} size="medium">
              Done
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={state.close} size="medium">
                Cancel
              </Button>
              <Button variant="secondary" onClick={handleReset} size="medium">
                Reset
              </Button>
              <Button
                onClick={handleCopy}
                loading={migrationsLoading}
                disabled={migrationsLoading || !someSelected}
                size="medium"
              >
                Copy settings
              </Button>
            </>
          )
        }
      />
    </Dialog>
  );
}

export default function MigrateFromConfig1ToConfig2({ network }: { network: Network }) {
  const { state } = useDialogState();

  return (
    <FieldContainer>
      <PrimaryField
        label="Migrate to Config 2"
        element={
          <ErrorBoundary
            // This shouldn't be shown, we shouldn't use error boundaries in here. Just in case.
            fallback={
              <Alert
                variant="negative"
                icon="warning"
                heading="Error determining network incompatibilities."
              />
            }
          >
            <Suspense
              fallback={
                <Button variant="secondary" arrangement="leading-icon" size="medium" loading>
                  Start migration
                </Button>
              }
            >
              <Button
                onClick={state.open}
                variant="secondary"
                arrangement="leading-icon"
                size="medium"
              >
                Start migration
              </Button>
              <MigrateDialog network={network} state={state} />
            </Suspense>
          </ErrorBoundary>
        }
      />
    </FieldContainer>
  );
}

import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import PowerDistributionUnitDetailView from '../../../../../components/Hardware/PowerDistributionUnits/PowerDistributionUnitDetail';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Power distribution unit - Hardware',
});

const VALID_TABS = ['insights', 'outlets', 'boot-history'];

const checkValidTab = (tab: string) => {
  if (!VALID_TABS.includes(tab)) {
    throw new ResourceNotFoundError(`Invalid tab: ${tab}`);
  }
};

export default function PowerDistributionUnitDetailPage() {
  const { uuid, tab } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.PowerDistributionUnitDetailPage),
  );

  checkValidTab(tab);

  return <PowerDistributionUnitDetailView uuid={uuid} tab={tab} />;
}

import React from 'react';

import { components, MultiValueProps } from 'react-select';

export function NumberedItemOuter<T>(props: MultiValueProps<T, true>) {
  return (
    <components.MultiValue
      {...props}
      innerProps={{
        ...props.innerProps,
      }}
    >
      {props.children}
    </components.MultiValue>
  );
}

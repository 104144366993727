import { color } from 'd3-color';

/**
 * Sets the opacity of the given CSS color specifier string and returns the new color formatted as an rgba() string.
 * @param specifier
 * @param opacity
 */
export const fade = (specifier: string, opacity: number): string => {
  const c = color(specifier);

  if (c) {
    c.opacity = opacity;
    return c.formatRgb();
  }

  return 'rgba(0,0,0,0)';
};

import {
  Alert,
  ComboBox,
  ComboBoxItem,
  DialogContent,
  DialogFooter,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  Label,
  PaneContent,
  PaneFooter,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  Select,
  SelectItem,
  SkimmerContent,
  SkimmerFooter,
  Small,
  Text,
  TextInput,
  ToggleInput,
} from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';
import { notify } from '@meterup/common';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  GraphQLErrorBoundary,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import type {
  CreateNetworkCopyInput,
  CreateNetworkInput,
  UpdateNetworkInput,
} from '../../../../gql/graphql';
import type { Company } from '../../../../hooks/useCompany';
import type { EditNetworkFormValues, Network } from '../../../Settings/Network/utils';
import { paths } from '../../../../constants';
import { COUNTRIES } from '../../../../countries';
import { useCompany } from '../../../../hooks/useCompany';
import { networksForCompany, useNetworksForCompany } from '../../../../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';
import { sluggify, ucfirst } from '../../../../utils/strings';
import { withZodSchema } from '../../../../utils/withZodSchema';
import { CopyableMonoOrNoValue } from '../../../Devices/Insights';
import { FieldProvider } from '../../../Form/FieldProvider';
import { FormikConditional } from '../../../FormikConditional';
import { LocationSwitcherOption } from '../../../LocationSwitcher';
import {
  createNetworkFromCopyMutation,
  createNetworkMutation,
  createNetworkOnboardingMutation,
  editNetworkFormValues,
  jobTrackersQuery,
  networkQuery,
  updateNetworkMutation,
} from '../../../Settings/Network/utils';
import { WorkbenchPaneContent, WorkbenchPaneFooter } from '../WorkbenchPane';

function SlugField() {
  const { values, setFieldValue, setFieldTouched, touched, initialValues } =
    useFormikContext<EditNetworkFormValues>();

  const [isEditable, setEditable] = useState(false);

  useEffect(() => {
    if ((!touched.slug || !isEditable) && initialValues.slug === '') {
      setFieldValue('slug', sluggify(values.label));
      setFieldTouched('slug', false);
    }
  }, [values.label, touched.slug, isEditable, initialValues.slug, setFieldValue, setFieldTouched]);

  return (
    <FieldContainer>
      <FieldProvider name="slug">
        <PrimaryField
          label={
            <>
              Slug <Small>(URL identifier)</Small>
            </>
          }
          description="A unique idendifier for the network used in dashboard URLs. Based on label if unset."
          element={<TextInput disabled={!isEditable} />}
          controls={
            <Label>
              <Small>Customize</Small>
              <ToggleInput selected={isEditable} onChange={setEditable} controlSize="small" />
            </Label>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

function MailingAddressField({ company }: { company?: Company }) {
  return (
    <FieldContainer>
      <PrimaryField
        description={
          company?.isCustomer
            ? 'For customer networks, these fields are synced from Salesforce data.'
            : undefined
        }
        label="Mailing address"
        element={null}
      />
      <FieldProvider name="mailingAddressInput.line1">
        <SecondaryField
          label="Line 1"
          element={<TextInput width="100%" disabled={company?.isCustomer} />}
        />
      </FieldProvider>
      <FieldProvider name="mailingAddressInput.line2">
        <SecondaryField
          label="Line 2"
          element={<TextInput width="100%" disabled={company?.isCustomer} />}
        />
      </FieldProvider>
      <FieldProvider name="mailingAddressInput.city">
        <SecondaryField
          label="City"
          element={<TextInput width="100%" disabled={company?.isCustomer} />}
        />
      </FieldProvider>
      <FieldProvider name="mailingAddressInput.subdivisionCode">
        <SecondaryField
          label="State"
          element={<TextInput width="100%" disabled={company?.isCustomer} />}
        />
      </FieldProvider>
      <FieldProvider name="mailingAddressInput.postalCode">
        <SecondaryField
          label="Postal code"
          element={<TextInput width="100%" disabled={company?.isCustomer} />}
        />
      </FieldProvider>
      <FieldProvider name="mailingAddressInput.countryISO2">
        <SecondaryField
          label="Country"
          element={
            <Select width="100%" placeholder="Select a country" disabled={company?.isCustomer}>
              {COUNTRIES.map(({ name, code }) => (
                <SelectItem key={code}>{name}</SelectItem>
              ))}
            </Select>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

const schemaValidator = withZodSchema(editNetworkFormValues);

export function ErrorAlert({ error, heading }: { error: Error; heading: string }) {
  const graphqlError = getGraphQLError(error);

  return (
    <Alert
      variant="negative"
      icon="warning"
      heading={heading}
      copy={graphqlError ? ucfirst(graphqlError.message) : null}
    />
  );
}

function JobTrackerField({ network }: { network: Network }) {
  const jobTrackers = useGraphQL(jobTrackersQuery).data?.jobTrackers ?? [];

  const networkHasJobData = !!network.onboarding?.jobData;

  return (
    <FieldContainer>
      <FieldProvider name="jobTrackerID">
        <PrimaryField
          label="Onboarding job"
          description="From Airtable"
          element={
            <ComboBox canClearValue={!networkHasJobData} disabled={networkHasJobData}>
              {jobTrackers.map((jobTracker) => (
                <ComboBoxItem key={jobTracker.id} textValue={jobTracker.jobID ?? undefined}>
                  {jobTracker.jobID ?? `(${jobTracker.id})`}
                </ComboBoxItem>
              ))}
            </ComboBox>
          }
          optional={!networkHasJobData}
        />
      </FieldProvider>
    </FieldContainer>
  );
}

const getFramingContent = (framing: NetworkFormPropFraming) => {
  if (framing === 'drawer') {
    return DrawerContent;
  }
  if (framing === 'dialog') {
    return DialogContent;
  }
  if (framing === 'skimmer') {
    return SkimmerContent;
  }
  if (framing === 'workbench') {
    return WorkbenchPaneContent;
  }
  return PaneContent;
};

const getFramingFooter = (framing: NetworkFormPropFraming) => {
  if (framing === 'drawer') {
    return DrawerFooter;
  }
  if (framing === 'dialog') {
    return DialogFooter;
  }
  if (framing === 'skimmer') {
    return SkimmerFooter;
  }
  if (framing === 'workbench') {
    return WorkbenchPaneFooter;
  }
  return PaneFooter;
};

type NetworkFormPropFraming = 'dialog' | 'drawer' | 'pane' | 'skimmer' | 'workbench';

export default function NetworkForm({
  network,
  framing,
  actions,
  secondaryActions,
  showErrorsInline = false,
  onSuccess,
}: {
  network?: Network;
  framing: NetworkFormPropFraming;
  actions: React.ReactNode;
  secondaryActions?: React.ReactNode;
  showErrorsInline?: boolean;
  onSuccess?: (resp: { UUID: string; slug: string }) => void;
}) {
  const company = useCompany(useCurrentCompany())!;
  const companySlug = company!.slug;
  const companyNetworks = useNetworksForCompany(companySlug);

  const createNetwork = useGraphQLMutation(createNetworkMutation);
  const createNetworkFromCopy = useGraphQLMutation(createNetworkFromCopyMutation);
  const updateNetwork = useGraphQLMutation(updateNetworkMutation);
  const createNetworkOnboarding = useGraphQLMutation(createNetworkOnboardingMutation);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleValidate = useCallback(
    (values: EditNetworkFormValues) => {
      const validation = schemaValidator(values);

      if (
        !validation.slug &&
        companyNetworks.some((n) => n.UUID !== network?.UUID && n.slug === values.slug)
      ) {
        validation.slug =
          'Slug is already used by another company network, please provide another.';
      }

      if (
        !validation.label &&
        companyNetworks.some((n) => n.UUID !== network?.UUID && n.label === values.label)
      ) {
        validation.label =
          'Label is already used by another company network, please provide another.';
      }

      return validation;
    },
    [companyNetworks, network?.UUID],
  );

  const networkHasJob = !!network?.onboarding?.jobData?.id;

  const handleSubmit = useCallback(
    ({
      sourceNetworkUUID,
      isTemplate,
      isUpgradeSensitive,
      jobTrackerID,
      ...values
    }: EditNetworkFormValues) => {
      if (network?.UUID) {
        const input: UpdateNetworkInput = {
          ...values,
          isUpgradeSensitive,
        };

        if (network?.isCustomer) {
          // Don't allow these fields to be set for customer networks
          delete input.mailingAddressInput;
          delete input.isActive;
        }

        if (jobTrackerID && !networkHasJob) {
          createNetworkOnboarding.mutate(
            { networkUUID: network.UUID, jobTrackerID },
            {
              onError(err) {
                notify(
                  `There was a problem updating network job${getGraphQLErrorMessageOrEmpty(err)}`,
                  {
                    variant: 'negative',
                  },
                );
              },
            },
          );
        }

        updateNetwork.mutate(
          {
            uuid: network.UUID,
            input,
          },
          {
            onSuccess: (resp) => {
              onSuccess?.(resp.updateNetwork);
              notify('Successfully updated network.', {
                variant: 'positive',
              });

              queryClient.invalidateQueries(makeQueryKey(networkQuery, { uuid: network?.UUID }));
              queryClient
                .invalidateQueries(makeQueryKey(networksForCompany, { companySlug }))
                .then(() => {
                  if (resp.updateNetwork.slug !== network?.slug) {
                    navigate(
                      makeLink(paths.pages.SettingsNetworkGeneralPage, {
                        companyName: companySlug,
                        networkSlug: resp.updateNetwork.slug,
                      }),
                    );
                  }
                });
            },
            onError: (err) => {
              notify(
                `There was a problem updating this network${getGraphQLErrorMessageOrEmpty(err)}`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else if (sourceNetworkUUID) {
        const { label, slug, mailingAddressInput } = values;
        const input: CreateNetworkCopyInput = {
          label: label || null,
          slug: slug || null,
          mailingAddressInput,
        };

        createNetworkFromCopy.mutate(
          {
            sourceNetworkUUID,
            input,
          },
          {
            onSuccess: (resp) => {
              onSuccess?.(resp.createNetworkFromCopy);
              notify('Successfully created network.', {
                variant: 'positive',
              });
            },
            onError: showErrorsInline
              ? undefined
              : (err) => {
                  notify(
                    `There was a problem creating this network${getGraphQLErrorMessageOrEmpty(err)}`,
                    {
                      variant: 'negative',
                    },
                  );
                },
          },
        );
      } else {
        const input: CreateNetworkInput = {
          ...values,
          companySlug,
          isTemplate,
        };

        createNetwork.mutate(
          {
            input,
          },
          {
            onSuccess: (resp) => {
              notify('Successfully created network.', {
                variant: 'positive',
              });
              onSuccess?.(resp.createNetwork);
            },
            onError: showErrorsInline
              ? undefined
              : (err) => {
                  notify(
                    `There was a problem creating this network${getGraphQLErrorMessageOrEmpty(err)}`,
                    {
                      variant: 'negative',
                    },
                  );
                },
          },
        );
      }
    },
    [
      showErrorsInline,
      createNetwork,
      createNetworkFromCopy,
      createNetworkOnboarding,
      updateNetwork,
      network?.UUID,
      network?.isCustomer,
      network?.slug,
      networkHasJob,
      queryClient,
      companySlug,
      navigate,
      onSuccess,
    ],
  );

  const ContentFrame = getFramingContent(framing);
  const FooterFrame = getFramingFooter(framing);

  return (
    <Formik<EditNetworkFormValues>
      initialValues={{
        label: network?.label ?? '',
        slug: network?.slug ?? '',
        isActive: network?.isActive ?? true,
        isTemplate: network?.isTemplate ?? false,
        isConfig1WosUpgradesEnabled: network?.isConfig1WosUpgradesEnabled ?? true,
        isUpgradeSensitive: network?.isUpgradeSensitive ?? false,
        mailingAddressInput: {
          line1: network?.mailingAddress?.line1 ?? '',
          line2: network?.mailingAddress?.line2 ?? '',
          city: network?.mailingAddress?.city ?? '',
          subdivisionCode: network?.mailingAddress?.subdivisionCode ?? '',
          postalCode: network?.mailingAddress?.postalCode ?? '',
          countryISO2: network?.mailingAddress?.countryISO2 ?? 'US',
        },
        jobTrackerID: network?.onboarding?.jobData?.id ?? null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      <Form>
        <ContentFrame>
          {!network && (
            <>
              <FieldContainer>
                <FieldProvider name="sourceNetworkUUID">
                  <PrimaryField
                    label="Network to copy"
                    description="If selected, will copy this network's virtual configuration (excluding hardware) to the new network."
                    element={
                      <ComboBox placeholder="Select a network" canClearValue>
                        {companyNetworks.map((companyNetwork) => (
                          <ComboBoxItem key={companyNetwork.UUID} textValue={companyNetwork.label}>
                            <LocationSwitcherOption network={companyNetwork} />
                          </ComboBoxItem>
                        ))}
                      </ComboBox>
                    }
                    optional
                  />
                </FieldProvider>
              </FieldContainer>
              <FormikConditional<EditNetworkFormValues>
                condition={({ sourceNetworkUUID }) => !sourceNetworkUUID}
              >
                <FieldContainer>
                  <FieldProvider name="isTemplate">
                    <PrimaryToggleField
                      label="Template"
                      description="If set, this network will be treated as a template for future copies and hardware assignment will be disabled."
                    />
                  </FieldProvider>
                </FieldContainer>
              </FormikConditional>
            </>
          )}

          <FieldContainer>
            <FieldProvider name="label">
              <PrimaryField label="Label" element={<TextInput />} />
            </FieldProvider>
          </FieldContainer>

          <SlugField />

          <MailingAddressField company={company} />

          {network && (
            <GraphQLErrorBoundary fallback={() => null}>
              <Suspense fallback={null}>
                <JobTrackerField network={network} />
              </Suspense>
            </GraphQLErrorBoundary>
          )}

          <FormikConditional<EditNetworkFormValues>
            condition={({ sourceNetworkUUID }) => !sourceNetworkUUID}
          >
            <FieldContainer>
              <FieldProvider name="isActive">
                <PrimaryToggleField
                  disabled={company?.isCustomer}
                  label="Active"
                  description="If unset, network will not appear in the location selector by default and will be assumed to be unused. For customer networks, this field is auto-synced from Salesforce data."
                />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="isConfig1WosUpgradesEnabled">
                <PrimaryToggleField
                  label="Config 1 WOS upgrades enabled"
                  description={
                    <>
                      If set, config 2 controllers will perform legacy behavior to enable upgrades
                      for config 1 APs. Requires a VLAN with ID{' '}
                      <Text family="monospace" size={12}>
                        2
                      </Text>
                      , IP <CopyableMonoOrNoValue value="10.102.0.2" label="VLAN IP" size={12} />{' '}
                      and DHCP enabled.
                    </>
                  }
                />
              </FieldProvider>
            </FieldContainer>
            <IsOperator>
              <FieldContainer>
                <FieldProvider name="isUpgradeSensitive">
                  <PrimaryToggleField
                    label="Upgrade sensitive"
                    description={
                      <>
                        Able to be used as a filter tag for bulk upgrader
                        {/* TODO - depends on DASH-3401 */}
                        {/* <AttoLink
                            as={Link}
                            to={makeLink(window.location, paths.pages.FirmwareBulkUpgraderPage, {})}
                          >
                            bulk upgrader
                          </AttoLink> */}
                      </>
                    }
                  />
                </FieldProvider>
              </FieldContainer>
            </IsOperator>
          </FormikConditional>

          {showErrorsInline && createNetwork.isError && createNetwork.error && (
            <ErrorAlert
              error={createNetwork.error}
              heading="There was a problem creating this network"
            />
          )}
          {showErrorsInline && createNetworkFromCopy.isError && createNetworkFromCopy.error && (
            <ErrorAlert
              error={createNetworkFromCopy.error}
              heading="There was a problem creating this network"
            />
          )}
          {showErrorsInline && updateNetwork.isError && updateNetwork.error && (
            <ErrorAlert
              error={updateNetwork.error}
              heading="There was a problem creating this network"
            />
          )}
        </ContentFrame>
        <FooterFrame start={secondaryActions} end={actions} />
      </Form>
    </Formik>
  );
}

import type { ButtonSizeProp, ButtonVariantProp } from '@meterup/atto/src/controls/Button/Button';
import {
  Alert,
  Badge,
  Button,
  ControlGroup,
  EmptyState,
  HStack,
  Icon,
  Label,
  Pane,
  PaneContent,
  PaneHeader,
  sizing,
  space,
  styled,
  Tab,
  Text,
  ToggleInput,
  Tooltip,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable, notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import type { MeterLDFlags } from '../../feature_flags';
import type {
  FirewallRule,
  FirewallRuleBinding,
  FirewallRuleInterface,
  FirewallRulePhyInterface,
  FirewallRuleVLAN,
  InterfaceBindings,
  VLANBinding,
  VLANForFirewall,
} from './utils';
import { MAX_PORT_NUMBER, paths } from '../../constants';
import { FirewallRuleAction, IpProtocol, PermissionType } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useNetwork, useNetworkSlugFromPath } from '../../hooks/useNetworkFromPath';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../providers/PermissionsProvider';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import { useFirewallCrumbs, useNavigateBack, useNavigateHome } from '../../utils/routing';
import { NoValue } from '../NoValue';
import IsPermitted from '../permissions/IsPermitted';
import { PaneContentFallback } from '../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../ReactRouterLink';
import { RuleControls } from '../RuleControls';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import InterVLANCommunication, {
  InterVLANCommunicationActions,
} from './InterVLANCommunication/InterVLANCommunication';
import {
  displayIPProtocol,
  firewallRuleActionIcon,
  firewallRuleActionLabel,
  firewallRuleActionVariant,
  firewallRulesForNetwork,
  FirewallRulesTab,
  iconForRuleInterface,
  labelForRuleInterface,
  OrderButtons,
  PortRangeCell,
  prefixForRule,
  ReorderAction,
  sortBindings,
  updateBindingsForPhyInterfaceMutation,
  updateBindingsForVLANMutation,
  vlansForFirewallQuery,
  vlanSupportsFirewallRules,
} from './utils';

const builder = createColumnBuilder<{ isDefaultRule?: boolean; rule: FirewallRule }>();

const PrefixContainer = styled(HStack, {
  whiteSpace: 'nowrap',
});

const CellWrapper = styled('span', {
  variants: {
    default: {
      true: {
        opacity: 0.6,
      },
      false: {},
    },
  },
});

const baseColumns = [
  builder.data((row) => (row.rule.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Enabled',
      },
    },
    cell: ({ row }) => (
      <CellWrapper default={row.isDefaultRule}>
        {row.rule.isEnabled ? (
          <Tooltip contents="Enabled">
            <Icon
              icon="checkmark"
              color={
                row.isDefaultRule
                  ? {
                      light: 'iconNeutralLight',
                      dark: 'iconNeutralDark',
                    }
                  : {
                      light: 'iconPositiveLight',
                      dark: 'iconPositiveDark',
                    }
              }
              size={space(16)}
            />
          </Tooltip>
        ) : (
          <NoValue />
        )}
      </CellWrapper>
    ),
  }),
  builder.data((row) => (row.rule.isBidirectional ? 'Bidirectional' : ''), {
    id: 'bidirectional',
    header: () => <Icon icon="arrow-bidirectional" size={space(14)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Bidirectional',
      },
    },
    cell: ({ row }) => (
      <CellWrapper default={row.isDefaultRule}>
        {row.rule.isBidirectional ? (
          <Icon
            icon="arrow-bidirectional"
            color={
              row.isDefaultRule
                ? {
                    light: 'iconNeutralLight',
                    dark: 'iconNeutralDark',
                  }
                : {
                    light: 'iconPositiveLight',
                    dark: 'iconPositiveDark',
                  }
            }
            size={space(14)}
          />
        ) : (
          <NoValue />
        )}
      </CellWrapper>
    ),
  }),
  builder.data((row) => row.rule.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
    cell: ({ row, value }) => <CellWrapper default={row.isDefaultRule}>{value}</CellWrapper>,
  }),
  builder.data((row) => row.rule.action, {
    id: 'action',
    header: 'Action',
    meta: { width: 96 },
    cell: ({ row }) => (
      <CellWrapper default={row.isDefaultRule}>
        <Badge
          size="small"
          ends="card"
          variant={firewallRuleActionVariant(row.rule.action)}
          icon={firewallRuleActionIcon(row.rule.action)}
          arrangement="leading-icon"
        >
          {firewallRuleActionLabel(row.rule.action)}
        </Badge>
      </CellWrapper>
    ),
  }),
  builder.data((row) => row.rule.protocols.join(', '), {
    id: 'protocols',
    header: 'Protocols',
    meta: { width: 152 },
    cell: ({ row }) => (
      <CellWrapper default={row.isDefaultRule}>
        <HStack spacing={space(4)}>
          {row.rule.protocols.map((protocol) => (
            <Badge size="small" ends="card" variant="neutral">
              {displayIPProtocol(protocol)}
            </Badge>
          ))}
        </HStack>
      </CellWrapper>
    ),
  }),
  builder.data((row) => prefixForRule(row.rule, 'src') ?? '', {
    id: 'source-prefix',
    header: 'Source / mask',
    cell: ({ value, row }) => (
      <CellWrapper default={row.isDefaultRule}>
        {!value || value === '0.0.0.0/0' ? (
          <Badge size="small" ends="card" variant="neutral">
            Any
          </Badge>
        ) : (
          <PrefixContainer spacing={space(8)} align="center">
            {row.rule.srcVLAN && <Icon icon="vlan" />}
            <Text family="monospace">{value.replace('/', ' / ')}</Text>
          </PrefixContainer>
        )}
      </CellWrapper>
    ),
  }),
  builder.data((row) => `${row.rule.srcPortRange.lower}-${row.rule.srcPortRange.upper}`, {
    id: 'source-ports',
    header: 'Source ports',
    cell: ({ row }) => (
      <CellWrapper default={row.isDefaultRule}>
        <PortRangeCell row={row} portRange={row.rule.srcPortRange} />
      </CellWrapper>
    ),
  }),
  builder.data((row) => prefixForRule(row.rule, 'dst') ?? '', {
    id: 'dest-prefix',
    header: 'Destination / mask',
    cell: ({ value, row }) => (
      <CellWrapper default={row.isDefaultRule}>
        {!value || value === '0.0.0.0/0' ? (
          <Badge size="small" ends="card" variant="neutral">
            Any
          </Badge>
        ) : (
          <PrefixContainer spacing={space(8)} align="center">
            {row.rule.dstVLAN && <Icon icon="vlan" />}
            <Text family="monospace">{value.replace('/', ' / ')}</Text>
          </PrefixContainer>
        )}
      </CellWrapper>
    ),
  }),
  builder.data((row) => `${row.rule.dstPortRange.lower}-${row.rule.dstPortRange.upper}`, {
    id: 'dest-ports',
    header: 'Destination ports',
    cell: ({ row }) => (
      <CellWrapper default={row.isDefaultRule}>
        <PortRangeCell row={row} portRange={row.rule.dstPortRange} />
      </CellWrapper>
    ),
  }),
];

function FirewallRulesTabs({
  activeTab,
  setActiveTab,
}: {
  activeTab: FirewallRulesTab;
  setActiveTab: (val: FirewallRulesTab) => void;
}) {
  const isOperator = useIsOperator();
  const featureFlags = useFeatureFlags();

  return (
    <>
      <Tab
        icon="vlan"
        selected={activeTab === FirewallRulesTab.VLANs}
        onClick={() => setActiveTab(FirewallRulesTab.VLANs)}
        internal={!featureFlags['firewall-rules']}
      >
        VLANs
      </Tab>

      {(isOperator || featureFlags['firewall-rules-wan']) && (
        <Tab
          icon="globe"
          selected={activeTab === FirewallRulesTab.WANs}
          onClick={() => setActiveTab(FirewallRulesTab.WANs)}
          internal={!featureFlags['firewall-rules-wan']}
        >
          WANs
        </Tab>
      )}

      {(isOperator || featureFlags['firewall-rules-inter-vlan']) && (
        <Tab
          icon="arrow-bidirectional"
          selected={activeTab === FirewallRulesTab.InterVLANCommunication}
          onClick={() => setActiveTab(FirewallRulesTab.InterVLANCommunication)}
          internal={!featureFlags['firewall-rules-inter-vlan']}
        >
          Inter-VLAN communication
        </Tab>
      )}
    </>
  );
}

const RuleControlsDefaults = styled(Label, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$8',
});

const RuleControlsActions = styled(ControlGroup, {
  variants: {
    visible: {
      true: {},
      false: {
        visibility: 'hidden',
        pointerEvents: 'none',
      },
    },
  },
});

const firewallRulesColumnsBuilder = createColumnBuilder<[string, FirewallRuleInterface]>();

function InterfaceTable({
  ruleInterface,
  vlans,
}: {
  ruleInterface: FirewallRuleInterface;
  vlans: VLANForFirewall[];
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditFirewallRulePage);

  const [showDefaultFirewallRules, setShowDefaultFirewallRules] = useState<boolean>(false);

  const closeDrawer = useCloseDrawerCallback();
  const [bindings, setBindings] = useState<FirewallRuleBinding[]>(ruleInterface.bindings);
  const isDirty = useMemo(
    () =>
      bindings !== ruleInterface.bindings &&
      bindings.some((binding, i) => binding !== ruleInterface.bindings[i]),
    [bindings, ruleInterface.bindings],
  );
  useEffect(() => {
    setBindings(ruleInterface.bindings);
  }, [ruleInterface.bindings]);

  const handleReorder = useCallback(
    (index: number, action: ReorderAction) => {
      setBindings((prev) => {
        let nextIndex: number;
        switch (action) {
          case ReorderAction.Decrease:
            if (index === 0) return prev;
            nextIndex = index - 1;
            break;
          case ReorderAction.Increase:
            if (index === prev.length - 1) return prev;
            nextIndex = index + 1;
            break;
        }

        const next = [...prev];
        const tmp = next[nextIndex];
        next[nextIndex] = next[index];
        next[index] = tmp;
        return next;
      });
    },
    [setBindings],
  );

  const columns = useMemo(
    () => [
      builder.display({
        id: 'order',
        meta: { width: 68 },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row, table }) =>
          row.isDefaultRule ? null : (
            <OrderButtons
              key={
                `${row.id}-${row.index}` /* to prevent incorrect button enabled state flashing */
              }
              index={row.index}
              length={table.getRowModel().rows.length}
              handleReorder={handleReorder}
            />
          ),
      }),
      ...baseColumns,
    ],
    [handleReorder],
  );

  const handleCancel = useCallback(() => {
    setBindings(ruleInterface.bindings);
  }, [ruleInterface.bindings]);

  const updateBindingsForVLAN = useGraphQLMutation(updateBindingsForVLANMutation);
  const updateBindingsForPhyInterface = useGraphQLMutation(updateBindingsForPhyInterfaceMutation);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(() => {
    const orderedRuleUUIDs = bindings.map((b) => b.rule.UUID);
    switch (ruleInterface.__typename) {
      case 'VLAN':
        updateBindingsForVLAN.mutate(
          { vlanUUID: ruleInterface.UUID, orderedRuleUUIDs },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(firewallRulesForNetwork, { networkUUID: network.UUID }),
              );
              notify('Successfully updated bindings for VLAN.', {
                variant: 'positive',
              });
            },
            onError: (error) => {
              notify(
                `There was a problem updating bindings for VLAN${getGraphQLErrorMessageOrEmpty(error)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
        break;
      case 'PhyInterface':
        updateBindingsForPhyInterface.mutate(
          { phyInterfaceUUID: ruleInterface.UUID, orderedRuleUUIDs },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(firewallRulesForNetwork, { networkUUID: network.UUID }),
              );
              notify('Successfully updated bindings for WAN.', {
                variant: 'positive',
              });
            },
            onError: (error) => {
              notify(
                `There was a problem updating bindings for WAN${getGraphQLErrorMessageOrEmpty(error)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
        break;
    }
  }, [
    queryClient,
    bindings,
    ruleInterface.UUID,
    ruleInterface.__typename,
    updateBindingsForVLAN,
    updateBindingsForPhyInterface,
    network.UUID,
  ]);

  const vlansByUUID = useMemo(() => new Map(vlans.map((v) => [v.UUID, v])), [vlans]);

  const bindingsToShow = useMemo(() => {
    if (ruleInterface.__typename === 'VLAN' && showDefaultFirewallRules) {
      const newBindings = [...bindings] as (VLANBinding & { isDefaultRule?: boolean })[];

      // Add inter-VLAN allow rules
      const thisVLAN = vlansByUUID.get(ruleInterface.UUID);
      if (thisVLAN) {
        for (const interVLANPair of thisVLAN.permittedInterVLANCommunicationVLANs) {
          const interVLAN = vlansByUUID.get(interVLANPair.UUID);
          if (!interVLAN || !vlanSupportsFirewallRules(interVLAN)) continue;

          newBindings.push({
            vlan: thisVLAN,
            isDefaultRule: true,
            metric: 0,
            rule: {
              UUID: thisVLAN.UUID,
              isEnabled: true,
              isMeterInternal: true,
              isBidirectional: true,
              action: FirewallRuleAction.Permit,
              protocols: [IpProtocol.All],
              name: `Inter-VLAN communication to ${interVLAN.name} VLAN`,
              srcVLAN: thisVLAN,
              srcPortRange: { lower: 1, upper: MAX_PORT_NUMBER },
              dstVLAN: interVLAN,
              dstPortRange: { lower: 1, upper: MAX_PORT_NUMBER },
            },
          });
        }
      }

      // Add default deny rules
      for (const vlan of vlans) {
        if (vlan.UUID === ruleInterface.UUID || !vlanSupportsFirewallRules(vlan)) {
          continue;
        }

        newBindings.push({
          vlan,
          isDefaultRule: true,
          metric: 0,
          rule: {
            UUID: vlan.UUID,
            isEnabled: true,
            isMeterInternal: true,
            isBidirectional: false,
            action: FirewallRuleAction.Deny,
            protocols: [IpProtocol.All],
            name: `Default deny to ${vlan.name} VLAN`,
            srcPrefix: '0.0.0.0/0',
            srcPortRange: { lower: 1, upper: MAX_PORT_NUMBER },
            dstVLAN: vlan,
            dstPortRange: { lower: 1, upper: MAX_PORT_NUMBER },
          },
        });
      }

      if (thisVLAN?.dhcpRule?.applicationDNSFirewallRules?.length) {
        newBindings.push({
          vlan: thisVLAN,
          isDefaultRule: true,
          metric: 0,
          rule: {
            UUID: thisVLAN.UUID,
            isEnabled: true,
            isMeterInternal: true,
            isBidirectional: false,
            action: FirewallRuleAction.Permit,
            protocols: [IpProtocol.Udp],
            name: `Default allow DNS to ${thisVLAN.name} VLAN gateway`,
            srcPrefix: '0.0.0.0/0',
            srcPortRange: { lower: 1, upper: MAX_PORT_NUMBER },
            dstPrefix: `${thisVLAN.ipV4ClientGateway}/${thisVLAN.ipV4ClientPrefixLength}`,
            dstPortRange: { lower: 53, upper: 53 },
          },
        });
        newBindings.push({
          vlan: thisVLAN,
          isDefaultRule: true,
          metric: 0,
          rule: {
            UUID: thisVLAN.UUID,
            isEnabled: true,
            isMeterInternal: true,
            isBidirectional: false,
            action: FirewallRuleAction.Deny,
            protocols: [IpProtocol.Udp],
            name: `Default deny DNS`,
            srcPrefix: '0.0.0.0/0',
            srcPortRange: { lower: 1, upper: MAX_PORT_NUMBER },
            dstVLAN: thisVLAN,
            dstPortRange: { lower: 53, upper: 53 },
          },
        });
      }

      // Add default allow rule
      newBindings.push({
        vlan: ruleInterface,
        isDefaultRule: true,
        metric: 0,
        rule: {
          UUID: ruleInterface.UUID,
          isEnabled: true,
          isMeterInternal: true,
          isBidirectional: false,
          action: FirewallRuleAction.Permit,
          protocols: [IpProtocol.All],
          name: 'Default allow rule',
          srcPrefix: '0.0.0.0/0',
          srcPortRange: { lower: 1, upper: MAX_PORT_NUMBER },
          dstPrefix: '0.0.0.0/0',
          dstPortRange: { lower: 1, upper: MAX_PORT_NUMBER },
        },
      });

      return newBindings;
    }
    return bindings;
  }, [ruleInterface, bindings, showDefaultFirewallRules, vlans, vlansByUUID]);

  const { hasPermission } = usePermissions();
  const canEditFirewallRule = hasPermission(PermissionType.PermFirewallWrite);

  const getLinkTo = useMemo(() => {
    if (canEditFirewallRule) {
      return (row: { isDefaultRule?: boolean; rule: FirewallRule }) =>
        row.isDefaultRule
          ? null
          : makeDrawerLink(window.location, paths.drawers.EditFirewallRulePage, {
              ruleUUID: row.rule.UUID,
              companyName,
              networkSlug: network.slug,
            });
    }
    return undefined;
  }, [canEditFirewallRule, companyName, network]);

  return (
    <AutoTable
      isNested
      columns={columns}
      data={bindingsToShow}
      enableSorting={false}
      getRowId={(row) => row.rule.UUID}
      getLinkTo={getLinkTo}
      isRowSelected={(row) => row.rule.UUID === drawerParams?.ruleUUID}
      onRowDeselect={closeDrawer}
      actions={
        <RuleControls>
          <RuleControlsDefaults>
            Show default rules
            <ToggleInput
              selected={showDefaultFirewallRules}
              controlSize="small"
              onChange={setShowDefaultFirewallRules}
            />
          </RuleControlsDefaults>
          <RuleControlsActions relation="separate" size="small" visible={isDirty}>
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="primary">
              Save
            </Button>
          </RuleControlsActions>
        </RuleControls>
      }
    />
  );
}

function AddFirewallRuleButton({
  tab,
  variant,
  size,
}: {
  tab: FirewallRulesTab;
  variant?: ButtonVariantProp;
  size?: ButtonSizeProp;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const tabLabel = useMemo(() => {
    switch (tab) {
      case 'vlans':
        return 'VLAN firewall rule';
      case 'wans':
        return 'WAN firewall rule';
      case 'rules':
      default:
        return 'firewall rule';
    }
  }, [tab]);

  return (
    <Button
      condense
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.CreateFirewallRulePage, {
        companyName,
        networkSlug: network.slug,
      })}
      arrangement="leading-icon"
      icon="plus"
      variant={variant}
      size={size}
    >
      Add {tabLabel}
    </Button>
  );
}

function FirewallRulesBindingsList({
  tab,
  firewallRules,
  vlans,
}: {
  tab: FirewallRulesTab.VLANs | FirewallRulesTab.WANs;
  firewallRules: FirewallRule[];
  vlans: VLANForFirewall[];
}) {
  // Return is checked by types
  // eslint-disable-next-line consistent-return
  const interfacesToRules: InterfaceBindings = useMemo(() => {
    switch (tab) {
      case FirewallRulesTab.VLANs: {
        const dict: Record<string, FirewallRuleVLAN> = {};
        for (const rule of firewallRules ?? []) {
          if (!rule?.vlanBindings) continue;
          for (const binding of rule.vlanBindings) {
            const { vlan } = binding;
            if (!(vlan.UUID in dict)) {
              dict[vlan.UUID] = { ...binding.vlan, bindings: [] };
            }
            dict[vlan.UUID].bindings.push({ ...binding, rule });
          }
        }
        for (const i of Object.values(dict)) {
          i.bindings.sort(sortBindings);
        }

        // Add vlans without rules
        for (const vlan of vlans) {
          if (!vlanSupportsFirewallRules(vlan)) continue;

          if (vlan.UUID in dict) continue;

          dict[vlan.UUID] = { ...vlan, bindings: [] };
        }

        return dict;
      }
      case FirewallRulesTab.WANs: {
        const dict: Record<string, FirewallRulePhyInterface> = {};
        for (const rule of firewallRules ?? []) {
          if (!rule?.phyInterfaceBindings) continue;
          for (const binding of rule.phyInterfaceBindings) {
            const { phyInterface } = binding;
            if (!(phyInterface.UUID in dict)) {
              dict[phyInterface.UUID] = { ...binding.phyInterface, bindings: [] };
            }
            dict[phyInterface.UUID].bindings.push({ ...binding, rule });
          }
        }
        for (const i of Object.values(dict)) {
          i.bindings.sort(sortBindings);
        }
        return dict;
      }
    }
  }, [firewallRules, tab, vlans]);

  const sortedInterfaceBindingsEntries = useMemo(() => {
    const entries = Object.entries(interfacesToRules);
    entries.sort(([, a], [, b]) =>
      labelForRuleInterface(a).localeCompare(labelForRuleInterface(b)),
    );
    return entries;
  }, [interfacesToRules]);

  const firewallRulesColumns = useMemo(
    () => [
      firewallRulesColumnsBuilder.data((row) => labelForRuleInterface(row[1]), {
        header: tab === FirewallRulesTab.VLANs ? 'VLAN' : 'WAN',
        id: tab === FirewallRulesTab.VLANs ? 'vlan' : 'wan',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row }) => (
          <Badge
            arrangement="leading-icon"
            ends="card"
            icon={iconForRuleInterface(row[1])}
            size="small"
            variant="neutral"
          >
            {labelForRuleInterface(row[1])}
          </Badge>
        ),
      }),
    ],
    [tab],
  );

  if (!sortedInterfaceBindingsEntries?.length) {
    return (
      <EmptyState
        icon={tab === FirewallRulesTab.VLANs ? 'vlan' : 'globe'}
        heading="You have no firewall rules"
        action={<AddFirewallRuleButton tab={tab} />}
      />
    );
  }

  return (
    <>
      {tab === 'wans' && (
        <div style={{ padding: sizing.squish }}>
          <Alert
            variant="neutral"
            icon="information"
            heading="WAN firewall rules apply to the entire network"
            copy="All inbound and outbound connections may be impacted."
          />
        </div>
      )}
      <AutoTable
        columns={firewallRulesColumns}
        data={sortedInterfaceBindingsEntries}
        renderSubTable={(props) => <InterfaceTable vlans={vlans} ruleInterface={props.data[1]} />}
        subTableCollapsedByDefault={false}
      />
    </>
  );
}

function FirewallRulesList({ firewallRules }: { firewallRules: FirewallRule[] }) {
  const companyName = useCurrentCompany();
  const networkSlug = useNetworkSlugFromPath();

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditFirewallRulePage);
  const { hasPermission } = usePermissions();
  const canEditFirewallRule = hasPermission(PermissionType.PermFirewallWrite);

  const closeDrawer = useCloseDrawerCallback();
  const getLinkTo = useMemo(() => {
    if (canEditFirewallRule) {
      return (row: { isDefaultRule?: boolean; rule: FirewallRule }) =>
        makeDrawerLink(window.location, paths.drawers.EditFirewallRulePage, {
          ruleUUID: row.rule.UUID,
          companyName,
          networkSlug,
        });
    }
    return undefined;
  }, [canEditFirewallRule, companyName, networkSlug]);

  return (
    <AutoTable
      isNested
      columns={baseColumns}
      data={firewallRules.map((rule) => ({ rule }))}
      enableSorting={false}
      getRowId={(row) => row.rule.UUID}
      getLinkTo={getLinkTo}
      isRowSelected={(row) => row.rule.UUID === drawerParams?.ruleUUID}
      onRowDeselect={closeDrawer}
    />
  );
}

function RulesOrBindingsList({
  tab,
}: {
  tab: Exclude<FirewallRulesTab, FirewallRulesTab.InterVLANCommunication>;
}) {
  const network = useNetwork();
  const vlans = useGraphQL(vlansForFirewallQuery, { networkUUID: network.UUID }).data?.vlans;

  const vlanOptions = useMemo(
    () => vlans?.filter((vlan) => vlanSupportsFirewallRules(vlan)) ?? [],
    [vlans],
  );

  const firewallRules = useGraphQL(firewallRulesForNetwork, { networkUUID: network.UUID }).data
    ?.firewallRulesForNetwork;

  if (!firewallRules?.length) {
    return (
      <EmptyState
        icon={tab === FirewallRulesTab.VLANs ? 'vlan' : 'globe'}
        heading="You have no firewall rules"
        action={<AddFirewallRuleButton tab={tab} />}
      />
    );
  }

  return tab === FirewallRulesTab.Rules ? (
    <FirewallRulesList firewallRules={firewallRules} />
  ) : (
    <FirewallRulesBindingsList firewallRules={firewallRules} tab={tab} vlans={vlanOptions} />
  );
}

function FirewallActions({ tab }: { tab: FirewallRulesTab }) {
  switch (tab) {
    case FirewallRulesTab.InterVLANCommunication:
      return <InterVLANCommunicationActions />;
    case FirewallRulesTab.VLANs:
    case FirewallRulesTab.WANs:
    case FirewallRulesTab.Rules:
      return <AddFirewallRuleButton size="small" tab={tab} variant="secondary" />;
    default:
      return null;
  }
}

function FirewallRulesContent({ tab }: { tab: FirewallRulesTab }) {
  switch (tab) {
    case FirewallRulesTab.InterVLANCommunication:
      return <InterVLANCommunication />;
    default: {
      const flag: keyof MeterLDFlags =
        tab === FirewallRulesTab.WANs ? 'firewall-rules-wan' : 'firewall-rules';

      return (
        <IsPermitted
          should404OnAccessDenied
          isPermitted={({ isOperator, ldFlags }) => isOperator || !!ldFlags[flag]}
        >
          <PaneContent gutter="bottom">
            <RulesOrBindingsList tab={tab} />
          </PaneContent>
        </IsPermitted>
      );
    }
  }
}

export default function FirewallRules({ tab }: { tab: FirewallRulesTab }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const firewallCrumb = useFirewallCrumbs();
  const navigate = useNavigate();

  const setActiveTab = (newTab: FirewallRulesTab) => {
    navigate(
      makeLink(paths.pages.RulesPage, {
        companyName,
        networkSlug: network.slug,
        tab: newTab,
      }),
    );
  };

  return (
    <Pane
      contentMode={tab === FirewallRulesTab.InterVLANCommunication ? 'fit' : 'stretch'}
      layoutMode="detailed"
    >
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...firewallCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.RulesPage, {
                companyName,
                networkSlug: network.slug,
                tab,
              }),
              selected: true,
              label: 'Rules',
            },
          },
        ]}
        icon="rules"
        heading="Rules"
        tabs={<FirewallRulesTabs activeTab={tab} setActiveTab={setActiveTab} />}
        contentActions={
          <IsPermitted permissions={PermissionType.PermFirewallWrite}>
            <FirewallActions tab={tab} />
          </IsPermitted>
        }
      />
      <Suspense fallback={<PaneContentFallback />}>
        <FirewallRulesContent tab={tab} />
      </Suspense>
    </Pane>
  );
}

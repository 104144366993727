import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import AccessPointDevicesBatchEditDrawer from '../../../../../components/Hardware/AccessPoints/AccessPointsBatchEditDrawer';
import { paths } from '../../../../../constants';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/batch-edit/:accessPointUUIDs',
  title: 'Edit access points - Access points - Hardware',
});

export default function AccessPointBatchEditPage() {
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);
  if (!isWOS2Enabled) {
    throw new ResourceNotFoundError('Page not found');
  }

  const { accessPointUUIDs } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.AccessPointBatchEditPage),
  );

  return <AccessPointDevicesBatchEditDrawer uuids={accessPointUUIDs.split(',')} />;
}

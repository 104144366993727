import VirtualDeviceCreateDrawer from '../../../../../components/Devices/VirtualDeviceCreateDrawer';
import { VirtualDeviceType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/security-appliances/add',
  title: 'Add security appliance - Security appliances - Hardware',
});

export default function SecurityApplianceCreateDrawerPage() {
  return <VirtualDeviceCreateDrawer deviceType={VirtualDeviceType.Controller} />;
}

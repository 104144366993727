import { CSSProperties, useMemo } from 'react';

import { theme } from '@meterup/atto';
import { identity } from 'lodash-es';
import { ValueContainerProps } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { GroupBase } from 'react-select/dist/declarations/src/types';

import { stroke } from '../../../colors';
import { fade } from '../../../utils/stolen-from-frontends/fade';

type CustomStylesSize = 'small' | 'medium' | 'large';
type UseCustomStylesProps<Item, IsMulti extends boolean> = {
  controlPositioning: boolean;
  singleLine: boolean;
  capsuleStyle: boolean;
  custom?: (
    c: StylesConfig<Item, IsMulti, GroupBase<Item>>,
  ) => StylesConfig<Item, IsMulti, GroupBase<Item>>;
  plain?: boolean;
  showIndicator: boolean;
  size: CustomStylesSize;
  unstyled?: boolean;
};

export function useCustomStyles<Item, IsMulti extends boolean>({
  controlPositioning = true,
  custom = identity,
  singleLine = false,
  capsuleStyle = true,
  plain = false,
  showIndicator = false,
  size = 'large',
  unstyled = false,
}: UseCustomStylesProps<Item, IsMulti>): StylesConfig<Item, IsMulti, GroupBase<Item>> {
  return useMemo(() => {
    // @ts-ignore
    const styles: StylesConfig<Item, IsMulti, GroupBase<Item>> = {
      // @ts-ignore
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected || state.isFocused ? 'var(--colors-gray-500)' : 'var(--colors-gray-700)',
        backgroundColor:
          state.isSelected || state.isFocused ? 'var(--colors-brand-600)' : 'var(--colors-white)',
        fontFamily: 'var(--fonts-sans)',
        fontWeight: 'var(--fontWeights-medium)',
        padding: '0 var(--space-6)',
        fontSize: 'var(--fontSizes-14)',
        lineHeight: 'var(--lineHeights-20)',
        borderRadius: 'var(--radii-6)',
        display: 'flex',
        alignItems: 'center',
        // minHeight: "var(--sizes-28)",
        // position: "relative",
        outline: 'none',
        userSelect: 'none',
        cursor: 'pointer',
        '&:hover': {
          color: 'var(--colors-white)',
          backgroundColor: 'var(--colors-brand-600)',
        },
      }),
      // @ts-ignore
      control: (provided) => {
        if (unstyled) {
          return {};
        }
        const { minHeight, ...rest } = provided;
        // const heights = false && size !== 'small' ? { minHeight } : { minHeight: '0px' };
        const heights = { minHeight: '0px' };
        if (plain) {
          return heights;
        }
        return {
          // none of react-select's styles are passed to <Control />
          ...rest,
          border: '0 none',
          cursor: 'text',
          borderColor: 'none',
          borderRadius: 'unset',
          borderWidth: 0,
          boxShadow: 'unset',
          overflow: 'hidden',
          flexWrap: singleLine ? 'nowrap' : 'wrap',
          ...heights,
        };
      },
      // @ts-ignore
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        if (capsuleStyle) {
          return {
            ...provided,
            opacity,
            transition,
            borderRadius: 99999,
            width: 'fit-content',
            fontWeight: 'var(--fontWeights-medium)',
            fontSize: 'var(--fontSizes-12)',
            lineHeight: 'var(--lineHeights-16)',
            color: 'var(--colors-gray-700)',
            padding: 'var(--space-2) var(--space-6)',
            backgroundColor: fade(theme.theme.colors.gray100.value, 0.75),
            '---labelColor': 'var(--colors-gray-700)',
            '---iconColor': 'var(--colors-gray-600)',
          };
        }

        return provided;
      },
      // @ts-ignore
      multiValue: (provided) => {
        const base = {
          padding: '0 var(--space-2)',
        };
        if (plain) {
          return base;
        }
        return {
          ...provided,
          ...base,
          borderRadius: 'var(--radii-8)',
          // backgroundColor: "var(--colors-brand-600)",
          backgroundColor: 'var(--colors-brand-white)',
          boxShadow: 'var(--shadows-controlTertiaryInitialLight)',
          '---labelColor': 'var(--colors-gray-600)',
          '---iconColor': 'var(--colors-gray-500)',
          '&:hover': {
            boxShadow: 'var(--shadows-controlTertiaryHoveredLight)',
            '---labelColor': 'var(--colors-gray-700)',
            '---iconColor': 'var(--colors-gray-600)',
          },
        };
      },
      // @ts-ignore
      multiValueLabel: (provided) => ({
        ...provided,
        // color: "var(--colors-gray-500)",
        // color: "var(--colors-white)",
        color: 'var(--colors-gray-600)',
        fontSize: 'var(--fontSizes-14)',
        lineHeight: 'var(--lineHeights-20)',
        background: 'none',
        backgroundColor: 'unset',
        '&:hover': {
          color: 'var(--colors-gray-700)',
        },
      }),
      // @ts-ignore
      multiValueRemove: (provided) => ({
        ...provided,
        color: 'var(--colors-gray-500)',
        '&:hover': {
          color: 'var(--colors-gray-600)',
          backgroundColor: 'unset',
          '& > svg': {
            textShadow: 'var(--shadows-controlTertiaryHoveredLight)',
          },
        },
      }),
      // @ts-ignore
      indicatorsContainer: (provided) => {
        if (showIndicator) {
          return {
            ...provided,
            cursor: 'pointer',
            display: 'flex',
            gap: 'var(--space-8)',
            ...(singleLine
              ? {
                  paddingRight: 'var(--space-8)',
                }
              : {}),

            ...(size === 'small'
              ? {
                  '& > div': {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                }
              : {}),
          };
        }
        return {
          display: 'none',
        };
      },
      // @ts-ignore
      clearIndicator: (provided) => ({
        ...provided,
        padding: 0,
      }),
      // @ts-ignore
      dropdownIndicator: (provided) => ({
        ...provided,
        paddingLeft: 0,
      }),
      // @ts-ignore
      indicatorSeparator: () => ({
        display: 'none',
      }),
      // @ts-ignore
      input: (provided) => ({
        ...provided,
      }),
      // @ts-ignore
      container: (provided) => {
        if (unstyled) {
          return {};
        }
        let container = {
          ...provided,
          // borderRadius: "var(--radii-8) var(--radii-8) 0 0",
          borderRadius: 'var(--radii-8)',
          // boxShadow:
          //   "var(--shadows-fenceLeftLight), var(--shadows-fenceRightLight), var(--shadows-fenceTopLight)",
          // padding: "var(--space-8) var(--space-12)",
          padding: 'var(--space-4) var(--space-8)',
          border: `1px solid ${stroke.controlBase.initialLight}`,
          backgroundColor: 'white',
          position: 'unset',
        };
        if (size === 'medium' || size === 'small') {
          container = {
            ...container,
            padding: '0 var(--space-4)',
            // boxShadow: "var(--shadows-fenceAllLight)",
            boxShadow: 'var(--shadows-fieldInitialLight)',
            borderRadius: 'var(--radii-8)',
            overflow: 'hidden',
            // "&:focus-within": {
            //   boxShadow: "var(--shadows-fieldFocusedLight)",
            // },
          };
        }
        // if (!controlPositioning) {
        //   container.overflow = "hidden";
        // }
        return container;
      },
      // @ts-ignore
      placeholder: (provided, state) => ({
        ...provided,
        width: state.isMulti ? '500px' : '100%',
        fontSize: 'var(--fontSizes-14)',
        color: 'var(--colors-gray-500)',
      }),
      // @ts-ignore
      valueContainer: (provided, state: ValueContainerProps<Item, IsMulti, GroupBase<Item>>) => {
        if (state.isMulti) {
          return {
            ...provided,
            padding: 0,
          };
        }
        return {
          ...provided,
          paddingTop: 0,
          ...(size === 'medium' ? { paddingBottom: 0 } : {}),
        };
      },
      // @ts-ignore
      menu: (provided) => {
        const menu: CSSProperties = {
          // ...provided,
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--space-2)',
          borderRadius: 'var(--radii-8)',
          boxShadow: 'var(--shadows-overlayLight)',
          // left: 0,
          width: 'auto',
          // padding: 0,
          padding: 'var(--space-4)',
          minWidth: 250,
          background: 'var(--colors-white)',
        };

        if (controlPositioning) {
          return {
            ...menu,
            bottom: '100%',
            position: 'absolute',
          };
        }

        return {
          ...provided,
          ...menu,
        };
      },
      // @ts-ignore
      groupHeading: (provided) => ({
        ...provided,
        padding: '0 var(--space-6)',
        fontWeight: 'var(--fontWeights-regular)',
        color: 'var(--colors-gray-500)',
        fontSize: 'var(--fontSizes-12)',
        lineHeight: 'var(--lineHeights-16)',
        // height: 1,
        // margin: '$8 -4px',
      }),
      // @ts-ignore
      group: (provided) => ({
        ...provided,
        borderTop: '1px solid var(--colors-gray-100)',
        '&:first-of-type': {
          borderTop: '0 none',
        },
      }),
      // @ts-ignore
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    };
    // @ts-ignore
    return custom(styles);
  }, [capsuleStyle, controlPositioning, custom, plain, showIndicator, singleLine, size, unstyled]);
}

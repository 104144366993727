import React from 'react';

import type { FallbackRenderer } from '../types/types';
import { useIsOperator } from '../hooks/useIsOperator';

type IsOperatorParams = {
  children: React.ReactNode;
  respectDemoMode?: boolean;
} & FallbackRenderer;
/**
 * Returns true if the user is an Operator, considers demo mode.
 *
 * @deprecated Use `IsPermitted` instead. Do not check the user roles directly.
 */
export default function IsOperator({
  children,
  fallback,
  respectDemoMode = true,
}: IsOperatorParams) {
  const isOperator = useIsOperator({ respectDemoMode });
  if (isOperator) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  if (fallback) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{fallback}</>;
  }
  return null;
}

import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import CSVBulkUploadDrawer from '../../../../../components/Wireless/CSVBulkUpload';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/bulk-upload',
  title: 'Bulk upload - Access points - Hardware',
});

export default function AccessPointsBulkCSVUploadPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  return <CSVBulkUploadDrawer />;
}

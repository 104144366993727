import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import StaticRouteEditDrawer from '../../../../../components/Routing/StaticRouteEditDrawer';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/routing/static-route/create',
  title: 'Add static route - Static routes - Network-wide',
});

export default function StaticRouteCreatePage() {
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator && !isCOS2Enabled) {
    throw new ResourceNotFoundError('Page not found');
  }

  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPolicyRoutingWrite) &&
            nosFlags[NosFeature.POLICY_ROUTING] &&
            ldFlags['policy-routing'],
        )
      }
      path={Meta().path}
    >
      <StaticRouteEditDrawer />
    </IsPermitted>
  );
}

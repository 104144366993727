import {
  Alert,
  Button,
  FieldContainer,
  PrimaryFieldComposite,
  SecondaryFieldComposite,
  Select,
  SelectItem,
  SelectSection,
  Small,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import { getGraphQLError, useGraphQLMutation } from '@meterup/graphql';
import { useCallback, useState } from 'react';

import type { Uplink } from '../Insights/Network/utils';
import type { PingResponse } from './utils';
import { getPhyInterfaceLabel } from '../Firewall/utils';
import { NoValue } from '../NoValue';
import {
  isValidHost,
  PingTestAlert,
  PingTestFields,
  RPCPing2Mutation,
  StyledLoadingIcon,
} from './utils';

const DEFAULT_HOST = '8.8.8.8';

export default function WANPingTest({
  uplinkDevices,
}: {
  uplinkDevices: [string, [Uplink, ...Uplink[]]][];
}) {
  const { mutate, isLoading, isError } = useGraphQLMutation(RPCPing2Mutation);

  const uplinkPhyInterfaces = uplinkDevices.flatMap(([, uplinks]) =>
    uplinks.filter((uplink) => uplink.virtualDevice.__typename === 'ControllerVirtualDevice'),
  );

  const [pingTestResult, setPingTestResult] = useState<PingResponse | undefined>();
  const [isHostInputValid, setIsHostInputValid] = useState(true);
  const [host, setHost] = useState(DEFAULT_HOST);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [interfaceUUID, setInterfaceUUID] = useState(uplinkDevices[0]?.[1]?.[0].UUID);

  const handleRunPing = useCallback(() => {
    setPingTestResult(undefined);
    if (isValidHost(host)) {
      const selectedPort = uplinkPhyInterfaces?.find((port) => port.UUID === interfaceUUID) ?? null;

      setIsHostInputValid(true);
      mutate(
        {
          input: {
            serialNumber: selectedPort?.virtualDevice?.hardwareDevice?.serialNumber ?? '', // Empty string to let BE handle the error
            host,
            phyInterfaceUUID: selectedPort?.UUID,
          },
        },
        {
          onSuccess: (data) => {
            setPingTestResult(data.rpcPing);
          },
          onError(error) {
            const gqlError = getGraphQLError(error);
            setErrorText(gqlError?.message ?? 'Unknown error');
          },
        },
      );
    } else {
      setIsHostInputValid(false);
    }
  }, [host, interfaceUUID, uplinkPhyInterfaces, mutate]);

  return (
    <VStack spacing={space(16)}>
      <Small>
        Determine if the Meter security appliance can reach a remote host and measure the round-trip
        request time. The host must be an IP address.
      </Small>
      <FieldContainer>
        <PrimaryFieldComposite
          label="Run a test"
          fields={
            <VStack spacing={space(8)}>
              <PingTestFields>
                <Select value={interfaceUUID} onValueChange={setInterfaceUUID} width="100%">
                  {uplinkDevices.map(([, phyInterfaces]) => (
                    <SelectSection title={phyInterfaces[0]?.virtualDevice?.label}>
                      {phyInterfaces.map((iface) => (
                        <SelectItem key={iface.UUID}>
                          {getPhyInterfaceLabel(iface, false)}
                        </SelectItem>
                      ))}
                    </SelectSection>
                  ))}
                </Select>
                <TextInput
                  name="host"
                  value={host}
                  onChange={setHost}
                  placeholder="8.8.8.8"
                  aria-label="Host"
                />
                <Button
                  icon="play"
                  arrangement="hidden-label"
                  variant="secondary"
                  onClick={handleRunPing}
                  disabled={!interfaceUUID || isLoading}
                  width="100%"
                >
                  Run
                </Button>
              </PingTestFields>
              {!isHostInputValid && (
                <Alert
                  relation="standalone"
                  type="inline"
                  variant="negative"
                  icon="warning"
                  copy="Host must be a valid IP address."
                />
              )}
            </VStack>
          }
        />
        {!isError && (isLoading || pingTestResult) && (
          <SecondaryFieldComposite
            label="Success rate"
            fields={
              <>
                {pingTestResult && (
                  <Small family="monospace">
                    {pingTestResult.successRate >= 0 ? (
                      `${(pingTestResult.successRate * 100).toFixed(3)}%`
                    ) : (
                      <NoValue />
                    )}
                  </Small>
                )}
                {isLoading && <StyledLoadingIcon />}
              </>
            }
          />
        )}
        {!isError && (isLoading || pingTestResult) && (
          <SecondaryFieldComposite
            label="Round-trip average time"
            fields={
              <>
                {pingTestResult && (
                  <Small family="monospace">
                    {pingTestResult.roundTripTimeMs != null ? (
                      `${pingTestResult.roundTripTimeMs} ms`
                    ) : (
                      <NoValue />
                    )}
                  </Small>
                )}
                {isLoading && <StyledLoadingIcon />}
              </>
            }
          />
        )}
        {isError && (
          <Alert
            type="inline"
            relation="stacked"
            variant="negative"
            icon="warning"
            heading={errorText}
          />
        )}
        {pingTestResult && <PingTestAlert successRate={pingTestResult.successRate} />}
      </FieldContainer>
    </VStack>
  );
}

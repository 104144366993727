import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import OneToOneNAT from '../../../../../components/Firewall/OneToOneNAT/OneToOneNAT';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/one-to-one-nat',
  layout: 'VirtualNetworkLayout',
  title: '1:1 NAT - Firewall',
});

export default function OneToOneNATPage() {
  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, nosFlags, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermOneToOneNatRead) &&
        nosFlags[NosFeature.ONE_TO_ONE_NAT] &&
        (isOperator || !!ldFlags['one-to-one-nat'])
      }
      should404OnAccessDenied
      allowOperatorAlways={false}
    >
      <OneToOneNAT />
    </IsPermitted>
  );
}

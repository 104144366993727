import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Body,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  ISPStatusBadge,
  NeutralBadge,
  PositiveBadge,
  ResourceNotFoundError,
} from '@meterup/common';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { match } from 'ts-pattern';

import { StickyObjectHeader } from '../../../../components/Object/ObjectHeader';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../nav';
import { useAPIClient } from '../../../../providers/APIClientProvider';
import { useCurrentControllerData } from '../../../../providers/CurrentControllerProvider';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/isps/:ispSid',
});

export const formatISPProduct = (product: api.ISPProduct) =>
  match(product)
    .with(api.ISPProduct.IR_UNKNOWN, () => 'Unknown')
    .with(api.ISPProduct.IR_DEDICATED_FIBER, () => 'Dedicated fiber')
    .with(api.ISPProduct.IR_SHARED_FIBER, () => 'Shared fiber')
    .with(api.ISPProduct.IR_POINT_TO_POINT_WIRELESS, () => 'Point to point wireless')
    .with(api.ISPProduct.IR_RESIDENTIAL_FIBER, () => 'Residential fiber')
    .with(api.ISPProduct.IR_RESIDENTIAL_CABLE, () => 'Residential cable')
    .with(api.ISPProduct.IR_BUSINESS_CABLE, () => 'Business cable')
    .exhaustive();

export default function ISPDetailsPage() {
  const { ispSid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ISPDetailsPage),
  );

  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const apiV2 = useAPIClient();

  const plan = useQuery(['network_isp_info', controllerName, ispSid], () => apiV2.isp(ispSid), {
    suspense: true,
  }).data;

  const info = checkDefinedOrThrow(plan, new ResourceNotFoundError(`ISP ${ispSid} not found`));

  return (
    <Drawer>
      <DrawerHeader icon="globe" heading="ISP" onClose={useCloseDrawerCallback()} />
      <DrawerContent gutter="none">
        <StickyObjectHeader icon="globe" name={info.provider_name} />
        <Section relation="stacked">
          <SectionHeader icon="information" heading="Metadata" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <SummaryListRow>
                <SummaryListKey>Status</SummaryListKey>
                <SummaryListValue>
                  <ISPStatusBadge status={info.status} />
                </SummaryListValue>
              </SummaryListRow>
              {info.product && (
                <SummaryListRow>
                  <SummaryListKey>Product</SummaryListKey>
                  <SummaryListValue>{formatISPProduct(info.product)}</SummaryListValue>
                </SummaryListRow>
              )}
              {info.network_interface && (
                <SummaryListRow>
                  <SummaryListKey>Network interface</SummaryListKey>
                  <SummaryListValue>
                    <NeutralBadge ends="card">{info.network_interface}</NeutralBadge>
                  </SummaryListValue>
                </SummaryListRow>
              )}
            </SummaryList>
          </SectionContent>
        </Section>
        <Section relation="stacked">
          <SectionHeader icon="wifi" heading="Network" />
          <SectionContent gutter="all">
            {info.has_static_ip ? (
              <SummaryList gutter="none">
                <SummaryListRow>
                  <SummaryListKey>IP assignment</SummaryListKey>
                  <SummaryListValue>
                    <PositiveBadge ends="card">Static</PositiveBadge>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>IP range</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy IP address to clipboard"
                      relation="stacked"
                      size="small"
                      value={info.static_ip_range}
                    >
                      <Body family="monospace">{info.static_ip_range}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Gateway IP</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy IP address to clipboard"
                      relation="stacked"
                      size="small"
                      value={info.gateway_addr}
                    >
                      <Body family="monospace">{info.gateway_addr}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Controller IP</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy IP address to clipboard"
                      relation="stacked"
                      size="small"
                      value={info.controller_ip}
                    >
                      <Body family="monospace">{info.controller_ip}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>First usable IP</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy IP address to clipboard"
                      relation="stacked"
                      size="small"
                      value={info.first_usable_ip}
                    >
                      <Body family="monospace">{info.first_usable_ip}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Last usable IP</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy IP address to clipboard"
                      relation="stacked"
                      size="small"
                      value={info.last_usable_ip}
                    >
                      <Body family="monospace">{info.last_usable_ip}</Body>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            ) : (
              <SummaryList gutter="none">
                <SummaryListRow>
                  <SummaryListKey>IP assignment</SummaryListKey>
                  <SummaryListValue>
                    <PositiveBadge ends="card">Dynamic</PositiveBadge>
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            )}
          </SectionContent>
        </Section>
      </DrawerContent>
    </Drawer>
  );
}

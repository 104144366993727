import { EmptyState } from '@meterup/atto';
import { IsCompanyAdmin } from '@meterup/authorization';

import UsersList from '../../../../components/Users/UsersList';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';

export function Meta() {
  return {
    path: '/org/:companyName/settings/org/users',
    title: 'Users - Org',
  };
}

export default function SettingsOrgUsersPage() {
  const companySlug = useCurrentCompany();

  return (
    <IsCompanyAdmin
      companySlug={companySlug}
      fallback={
        <EmptyState
          icon="padlock"
          heading="Not authorized"
          subheading="You do not have access to this page"
        />
      }
    >
      <UsersList />
    </IsCompanyAdmin>
  );
}

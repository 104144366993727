import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';

import { fetchVPNClient } from '../../../../../../api/vpn';
import VPNClientSummaryContent from '../../../../../../components/VPNClientSummaryContent';
import { paths } from '../../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../../nav';
import { useCurrentController } from '../../../../../../providers/CurrentControllerProvider';
import { DeleteVPNClientDialog } from './VPNClientDelete';
import { VPNSummaryTabs } from './vpnSummary';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vpn/:sid',
});

export default function VPNClientSummaryPage() {
  const controllerName = useCurrentController();
  const { sid } = Nav.useRegionParams('drawer', paths.drawers.VPNClientSummaryPage)!;

  const vpnClientDetails = useQuery(
    ['controller', controllerName, 'vpn-clients', sid],
    () => fetchVPNClient(controllerName, sid),
    { suspense: true },
  ).data;

  const closeDrawer = useCloseDrawerCallback();
  const dialogProps = useDialogState();

  expectDefinedOrThrow(
    vpnClientDetails,
    new ResourceNotFoundError('VPN client response not found'),
  );
  expectDefinedOrThrow(vpnClientDetails.client, new ResourceNotFoundError('VPN client not found'));
  expectDefinedOrThrow(vpnClientDetails.server, new ResourceNotFoundError('VPN client not found'));

  return (
    <Drawer>
      <DrawerHeader
        icon="client"
        heading={vpnClientDetails.client.name}
        tabs={<VPNSummaryTabs sid={sid} />}
        actions={
          <DropdownMenu>
            <DropdownMenuButton
              arrangement="hidden-label"
              icon="overflow-vertical"
              variant="secondary"
            >
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover align="end">
              <DropdownMenuItem onSelect={dialogProps.openFromMenu} icon="trash-can">
                Delete
              </DropdownMenuItem>
            </DropdownMenuPopover>
          </DropdownMenu>
        }
        onClose={closeDrawer}
      />
      <DrawerContent>
        <VPNClientSummaryContent vpnClient={vpnClientDetails.client} />
      </DrawerContent>
      <DeleteVPNClientDialog
        controllerName={controllerName}
        vpnClientName={vpnClientDetails.client.name}
        vpnClientSid={vpnClientDetails.client.sid}
        state={dialogProps.state}
      />
    </Drawer>
  );
}

import { Pane, PaneContent, PaneHeader } from '@meterup/atto';
import { CaptivePortalDefaults } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';

import type { CaptivePortalInputSchemaType } from './schema';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../utils/withZodSchema';
import IsPermitted from '../../permissions/IsPermitted';
import CaptivePortalForm from './CaptivePortalForm';
import CaptivePortalPreview from './CaptivePortalPreview';
import { createCaptivePortalMutation } from './queries';
import { CaptivePortalInputSchema } from './schema';
import { parseAllowedHostsInputToRecord, useHandleUpdate } from './utils';

export default function CaptivePortalNew() {
  const network = useNetwork();
  const createCaptivePortal = useGraphQLMutation(createCaptivePortalMutation);

  const initialValues = useMemo(
    (): CaptivePortalInputSchemaType => ({
      ...CaptivePortalDefaults,
      name: 'default',
      vlanUUIDs: [],
    }),
    [],
  );
  const handleMutation = useHandleUpdate({
    action: 'create',
    networkUUID: network.UUID,
  });
  const onSubmit = useCallback(
    ({
      logoImageURL,
      allowedHosts,
      isExternal,
      externalPortalURL,
      ...values
    }: CaptivePortalInputSchemaType & { logoImageURL?: string }) => {
      handleMutation(
        createCaptivePortal.mutateAsync({
          networkUUID: network.UUID,
          input: {
            ...values,
            ...parseAllowedHostsInputToRecord(allowedHosts),
          },
        }),
      );
    },
    [handleMutation, createCaptivePortal, network.UUID],
  );

  return (
    <Formik<CaptivePortalInputSchemaType>
      validate={withZodSchema(CaptivePortalInputSchema)}
      initialValues={initialValues}
      isInitialValid={false}
      onSubmit={onSubmit}
    >
      <Form style={{ display: 'contents' }}>
        <Pane>
          <PaneHeader icon="captive-portal" heading="Captive portal" />
          <PaneContent>
            <CaptivePortalPreview />
          </PaneContent>
        </Pane>
        <IsPermitted permissions={PermissionType.PermCaptivePortalWrite}>
          <CaptivePortalForm networkUUID={network.UUID} />
        </IsPermitted>
      </Form>
    </Formik>
  );
}

import React, { HTMLAttributes, useCallback } from 'react';

import { useResolvedPath } from 'react-router-dom';

import useDrawerRouter from '../../hooks/useDrawerRouter';

type RestLinkProps = Omit<HTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick'>;
type CloseDrawerLinkProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClose?: () => void;
} & RestLinkProps;
type OpenDrawerLinkProps = {
  children: React.ReactNode;
  onOpen?: () => void;
  path: string;
} & RestLinkProps;
export function CloseDrawerLink({ children, disabled, onClose, ...rest }: CloseDrawerLinkProps) {
  const { handleOnClickClose } = useDrawerRouter();
  const parent = useResolvedPath('..');
  const doClose = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      const onCloseInner = onClose || (() => {});
      handleOnClickClose(e).finally(onCloseInner);
    },
    [disabled, handleOnClickClose, onClose],
  );
  return (
    <a {...rest} href={parent.pathname} onClick={doClose}>
      {children}
    </a>
  );
}
export function OpenDrawerLink({ children, onOpen, path, ...rest }: OpenDrawerLinkProps) {
  const { handleOnClickOpen } = useDrawerRouter();
  const parent = useResolvedPath(`./${path}`);
  const doOpen = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      const onCloseInner = onOpen || (() => {});
      handleOnClickOpen(e).finally(onCloseInner);
    },
    [handleOnClickOpen, onOpen],
  );
  return (
    <a {...rest} href={parent.pathname} onClick={doOpen}>
      {children}
    </a>
  );
}

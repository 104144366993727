import {
  Badge,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  HStack,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  useDialogState,
} from '@meterup/atto';
import {
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType, SsidEncryptionProtocol } from '../../../gql/graphql';
import { useNetworkClients } from '../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { EAPOLTestDialog } from './SSIDEAPOLTestDialog';
import { DeleteSSIDMutation, SSIDsQuery } from './SSIDsUtils';

export default function SSIDActions({
  uuid,
  view,
}: {
  uuid: string;
  view: 'edit' | 'detail' | 'drawer' | 'list';
}) {
  const network = useNetwork();
  const deleteDialog = useDialogState();
  const eapolTestDialog = useDialogState();
  const deleteSSIDMutation = useGraphQLMutation(DeleteSSIDMutation);
  const closeDrawer = useCloseDrawerCallback();
  const companyName = useCurrentCompany();
  const { hasPermission } = usePermissions();
  const canEditSSID = hasPermission(PermissionType.PermSsidsWrite);

  const [isEAPOLTestEnabled] = useNosFeaturesEnabled([NosFeature.WOS_EAPOL_TEST]);

  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork;
  expectDefinedOrThrow(ssids, new ResourceNotFoundError('Unable to load SSIDs'));

  const ssid = ssids.find((s) => s.UUID === uuid);
  if (!ssid) throw new ResourceNotFoundError('SSID not found');

  const canRunEAPOLTest =
    isEAPOLTestEnabled &&
    (ssid.encryptionProtocol === SsidEncryptionProtocol.Wpa2Enterprise ||
      ssid.encryptionProtocol === SsidEncryptionProtocol.Wpa3Enterprise) &&
    isDefinedAndNotEmpty(ssid.primaryEncryption8021X?.UUID);

  const queryClient = useQueryClient();

  const networkClients = useNetworkClients(network);
  const affectedNetworkClientsForSSID = useMemo(
    () => networkClients.filter((client) => client.ssid === ssid.ssid),
    [networkClients, ssid],
  );

  const handleDeleteSSID = useCallback(
    () =>
      deleteSSIDMutation.mutate(
        { uuid: ssid.UUID },
        {
          onSuccess() {
            queryClient.invalidateQueries(makeQueryKey(SSIDsQuery, { networkUUID: network.UUID }));
            closeDrawer();
            notify('SSID deleted successfully', { variant: 'positive' });
          },
          onError() {
            notify('There was an error deleting SSID!', { variant: 'negative' });
          },
          onSettled: closeDrawer,
        },
      ),
    [deleteSSIDMutation, ssid.UUID, queryClient, closeDrawer, network.UUID],
  );

  return (
    <>
      {view !== 'edit' && canEditSSID && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.SSIDEditPage, {
            companyName,
            networkSlug: network.slug,
            uuid: ssid.UUID,
          })}
          arrangement="leading-icon"
          icon="pencil"
          variant="secondary"
        >
          Edit
        </Button>
      )}

      {canEditSSID && (
        <DropdownMenu>
          <DropdownMenuButton
            variant="secondary"
            icon="overflow-horizontal"
            arrangement="hidden-label"
          >
            Actions
          </DropdownMenuButton>
          <DropdownMenuPopover align="end">
            {canRunEAPOLTest && (
              <DropdownMenuGroup>
                <DropdownMenuItem onSelect={eapolTestDialog.state.open} icon="radius" internal>
                  Run EAPOL test
                </DropdownMenuItem>
              </DropdownMenuGroup>
            )}
            <DropdownMenuGroup>
              <DropdownMenuItem onSelect={deleteDialog.state.open} icon="trash-can">
                Delete
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuPopover>
        </DropdownMenu>
      )}
      {canRunEAPOLTest && (
        <EAPOLTestDialog
          state={eapolTestDialog.state}
          ssidUUID={ssid.UUID}
          radiusProfileUUID={ssid.primaryEncryption8021X!.UUID}
        />
      )}
      <Dialog state={deleteDialog.state} preset="narrow">
        <DialogHeader icon="ssid" heading="Delete SSID" />
        <DialogContent gutter="all">
          <Body>
            Are you sure you want to delete <Text weight="bold">{ssid.ssid}</Text>?
          </Body>
          {(ssid.vlan || !!affectedNetworkClientsForSSID?.length) && (
            <SummaryList gutter="vertical">
              {ssid.vlan && (
                <SummaryListRow>
                  <SummaryListKey>Affected VLAN</SummaryListKey>
                  <SummaryListValue>
                    <HStack spacing={space(6)} wrap="wrap">
                      <Badge variant="neutral" size="small" icon="vlan" arrangement="leading-icon">
                        {ssid.vlan.name}
                      </Badge>
                    </HStack>
                  </SummaryListValue>
                </SummaryListRow>
              )}
              {!!affectedNetworkClientsForSSID?.length && (
                <SummaryListRow>
                  <SummaryListKey>
                    Affected client{affectedNetworkClientsForSSID.length > 1 && 's'}
                  </SummaryListKey>
                  <SummaryListValue>
                    <HStack spacing={space(6)} wrap="wrap">
                      <Badge
                        variant="neutral"
                        size="small"
                        icon="client"
                        arrangement="leading-icon"
                      >
                        {affectedNetworkClientsForSSID.length}
                      </Badge>
                    </HStack>
                  </SummaryListValue>
                </SummaryListRow>
              )}
            </SummaryList>
          )}
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={deleteDialog.state.close}>
                Cancel
              </Button>
              <Button onClick={handleDeleteSSID} variant="destructive">
                Delete SSID
              </Button>
            </>
          }
        />
      </Dialog>
    </>
  );
}

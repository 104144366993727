import { Button, EmptyState } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { Link } from 'react-router-dom';

import type { AlertTargetWebhook } from '../utils';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import {
  useCurrentCompany,
  useCurrentCompany2,
} from '../../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { createColumnBuilder } from '../../../../Table/createColumnBuilder';
import { useAlertTargetsWebhook } from '../utils';

const builder = createColumnBuilder<AlertTargetWebhook>();

const columns = [
  builder.data((row) => row.label, {
    id: 'label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.url, {
    id: 'url',
    header: 'URL',
  }),
  builder.data((row) => row.signingSecret, {
    id: 'secret',
    header: 'Secret',
  }),
];

export default function Webhooks() {
  const companyName = useCurrentCompany();
  const company = useCurrentCompany2();
  const webhooks = useAlertTargetsWebhook({ companyUUID: company?.uuid });
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.WebhookEditPage);

  return webhooks.length > 0 ? (
    <AutoTable
      key="webhooks-table"
      columns={columns}
      data={webhooks}
      isRowSelected={(row) => row.UUID === drawerParams?.uuid}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.WebhookEditPage, {
          companyName,
          uuid: row.UUID,
        })
      }
    />
  ) : (
    <EmptyState
      icon="webhook"
      heading="No webhooks"
      action={
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.WebhooksAddPage, {
            companyName,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add webhook
        </Button>
      }
    />
  );
}

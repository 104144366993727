import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Box, Pane, PaneContent, PaneHeader, Skeleton, space, VStack } from '@meterup/atto';
import { Suspense } from 'react';

import NotificationPreferences, {
  GridContainer,
} from '../../components/NotificationPreferences/NotificationPreferences';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/operators/notification-preferences',
});

export default function NotificationPreferencesPage() {
  return (
    <Pane>
      <PaneHeader heading="Notifications" icon="megaphone" />
      <PaneContent gutter="all">
        <VStack spacing={space(16)}>
          <Alert
            relation="standalone"
            internal
            icon="padlock"
            heading="This page is only available internally to Meter employees!"
          />
          <Suspense
            fallback={
              <Box>
                <GridContainer>
                  <Skeleton height={240} width="100%" radius={6} />
                  <Skeleton height={240} width="100%" radius={6} />
                </GridContainer>
              </Box>
            }
          >
            <NotificationPreferences />
          </Suspense>
        </VStack>
      </PaneContent>
    </Pane>
  );
}

import { Drawer } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';

import ConfigureHighAvailability from '../../../../../components/Hardware/SecurityAppliance/ConfigureHighAvailability';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/edit/high-availability',
  title: 'Edit high availability - Security appliance - Security appliances - Hardware',
});

export default function HighAvailabilityEditDrawerPage() {
  const drawerParams = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.HighAvailabilityEditDrawerPage),
  );

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        permissions.hasPermission(PermissionType.PermHighAvailabilityRead) &&
        nosFlags[NosFeature.COS2] &&
        !!drawerParams?.virtualDeviceUUID
      }
      allowOperatorAlways={false}
      should404OnAccessDenied
      path={Meta().path}
    >
      <Drawer>
        <ConfigureHighAvailability />
      </Drawer>
    </IsPermitted>
  );
}

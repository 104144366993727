import { Alert, Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, notify } from '@meterup/common';
import React from 'react';

import { CancelAndGoBackButton } from '../../../../../../components/form_buttons';
import { paths } from '../../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../../hooks/useControllerConfig';
import { useVLANConfigModel } from '../../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../../nav';
import { useCurrentController } from '../../../../../../providers/CurrentControllerProvider';
import { useRemoveStaticClientMutation } from './mutations';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/static/:macAddress/remove',
});

export default function VLANStaticIPRemovePage() {
  const { vlanName, macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VLANStaticIPRemovePage),
  );

  const controllerName = useCurrentController();

  const configModel = useControllerConfig(controllerName);
  const vlan = useVLANConfigModel(controllerName, vlanName);

  const staticClient = vlan.getStaticClientByMacAddress(macAddress);

  expectDefinedOrThrow(staticClient, new Error(`Static assignment for "${macAddress}" not found`));

  const mutation = useRemoveStaticClientMutation(controllerName, configModel, vlan, staticClient);
  const closeDrawer = useCloseDrawerCallback();

  return (
    <Drawer>
      <DrawerHeader
        icon="ip-address"
        heading="Delete static IP"
        onClose={useCloseDrawerCallback()}
      />
      <DrawerContent>
        <Alert
          icon="attention"
          heading="Confirmation required"
          copy={`Are you sure you wish to remove the static assignment for ${staticClient.macAddress}?`}
          variant="negative"
        />
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <CancelAndGoBackButton />
            <Button
              onClick={() =>
                mutation.mutate(undefined, {
                  onSuccess: () => {
                    closeDrawer();
                    notify('Removed', { variant: 'positive' });
                  },
                  onError: () => {
                    notify('Failed to save changes', { variant: 'negative' });
                  },
                })
              }
              variant="destructive"
            >
              Delete
            </Button>
          </>
        }
      />
    </Drawer>
  );
}

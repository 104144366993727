import type { Selection } from '@react-types/shared/src/selection';
import { ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import type {
  QueryStatsForVirtualDevicesArgs,
  StatsForVirtualDevicesQuery,
} from '../../../../../../gql/graphql';
import { graphql } from '../../../../../../gql';
import { StatType } from '../../../../../../gql/graphql';
import { useNetwork } from '../../../../../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../../../../../providers/SearchParamsStateProvider';
import { useVirtualDevices } from './useVirtualDevices';

export const defaultDurationSeconds = '600';
export const maxDuration = 7776000;

const statTypeKeys = Object.values(StatType);
const statTypeItems = statTypeKeys.map((elem) => ({
  label: elem,
  value: elem,
}));

export enum FilterKey {
  startDate = 'startDate',
  endDate = 'endDate',
  virtualDevices = 'virtualDevices',
  statTypes = 'statTypes',
  duration = 'duration',
}

type Updater<T> = T | ((next: T) => T);
type OnFilterChange<T> = (key: FilterKey, value: T) => Updater<T>;

// in seconds
export type TimePeriod =
  | '600'
  | '1800'
  | '3600'
  | '7200'
  | '10800'
  | '21600'
  | '43200'
  | '86400'
  | 'CUSTOM';
export const timePeriodLabel = (period: TimePeriod) => {
  switch (period) {
    case '600':
      return 'Last 10 minutes';
    case '1800':
      return 'Last 30 minutes';
    case '3600':
      return 'Last 1 hour';
    case '7200':
      return 'Last 2 hours';
    case '10800':
      return 'Last 3 hours';
    case '21600':
      return 'Last 6 hours';
    case '43200':
      return 'Last 12 hours';
    case '86400':
      return 'Last 24 hours';
    case 'CUSTOM':
      return 'CUSTOM';
    default:
      return '';
  }
};

export function useNetworkStatsFilter() {
  const defaultStartDate = DateTime.now()
    .minus({ days: 1 })
    .startOf('day')
    .toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true });
  const defaultEndDate = DateTime.now()
    .endOf('day')
    .set({ second: 0, millisecond: 0 })
    .toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true });
  const [queryDurationSeconds, setDurationSeconds] = useSearchParamsState<TimePeriod>(
    'durationSeconds',
    defaultDurationSeconds,
  );
  const durationSeconds = queryDurationSeconds || defaultDurationSeconds;

  const [queryStartDate, setStartDate] = useSearchParamsState<string>(
    'startDate',
    defaultStartDate,
  );
  const startDate = queryStartDate || defaultStartDate;

  const [queryEndDate, setEndDate] = useSearchParamsState<string>('endDate', defaultEndDate);
  const endDate = queryEndDate || defaultEndDate;

  const [selectedStatType, setSelectedStatType] = useSearchParamsState<string>('statTypes', '');

  const { virtualDevices: devices } = useVirtualDevices();
  const defaultSelectedVirtualDevices = devices?.length > 0 ? devices[0].UUID : '';
  const [selectedVirtualDevices, setSelectedVirtualDevices] = useSearchParamsState<string | null>(
    'virtualDevices',
    defaultSelectedVirtualDevices,
  );

  const handleStatSelect = useCallback(
    (val: Selection | string) => {
      if (val === 'all') {
        setSelectedStatType(statTypeItems.map((item) => item.value).join(','));
      } else {
        const arrFromSet = [...val];
        const serializedArr = arrFromSet.join(',');
        setSelectedStatType(serializedArr);
      }
    },
    [setSelectedStatType],
  );

  const handleVirtualDeviceSelect = useCallback(
    (val: Selection) => {
      setSelectedVirtualDevices(Array.from(val).join(','));
    },
    [setSelectedVirtualDevices],
  );

  const setterMap: Record<FilterKey, Updater<any>> = useMemo(
    () => ({
      startDate: setStartDate,
      endDate: setEndDate,
      virtualDevices: handleVirtualDeviceSelect,
      statTypes: handleStatSelect,
      duration: setDurationSeconds,
    }),
    [setStartDate, setEndDate, handleVirtualDeviceSelect, handleStatSelect, setDurationSeconds],
  );

  const onFilterChange = useCallback(
    (key: FilterKey, value: string | string[] | null) => {
      setterMap[key](value);
    },
    [setterMap],
  );

  const filter = useMemo(() => {
    const selectedStatTypeVal = selectedStatType?.length ? selectedStatType?.split(',') : [];
    if (selectedStatTypeVal.length) {
      selectedStatTypeVal?.forEach((statType: string) => {
        const enumVals = Object.values(StatType) as string[];
        if (!enumVals.includes(statType) && statType !== 'all') {
          throw new ResourceNotFoundError('Unable to load data for stat type');
        }
      });
    }

    const virtualDeviceUUIDs = selectedVirtualDevices
      ? selectedVirtualDevices?.split(',').filter((device) => device !== '')
      : [];

    let duration = 0;
    let endTime = null;
    if (durationSeconds === 'CUSTOM') {
      const end = DateTime.fromISO(endDate);
      const start = DateTime.fromISO(startDate);
      const diffInSeconds = end.diff(start, 'seconds');
      duration = Math.round(diffInSeconds.toMillis() / 1000);
      endTime = end.toISO();
    }

    return {
      types: selectedStatTypeVal as StatType[],
      ...(durationSeconds === 'CUSTOM'
        ? {
            durationSeconds: duration,
            endTime,
          }
        : { durationSeconds: Number(durationSeconds) }),
      virtualDeviceUUIDs,
      limit: 1000,
      offset: 0,
    };
  }, [durationSeconds, endDate, startDate, selectedVirtualDevices, selectedStatType]);

  const networkStatsFilter: {
    filter: QueryStatsForVirtualDevicesArgs['filter'];
    onFilterChange: OnFilterChange<any>;
    query: {
      durationSeconds: TimePeriod;
      startDate: string;
      endDate: string;
    };
  } = useMemo(
    () => ({
      filter,
      query: {
        durationSeconds,
        startDate,
        endDate,
      },
      onFilterChange,
    }),
    [onFilterChange, filter, durationSeconds, startDate, endDate],
  );

  return networkStatsFilter;
}

const statsForVirtualDevicesQuery = graphql(`
  query statsForVirtualDevices($networkUUID: UUID!, $filter: NetworkStatsFilter!) {
    statsForVirtualDevices(networkUUID: $networkUUID, filter: $filter) {
      observedAt
      insertedAt
      networkUUID
      virtualDeviceUUID
      type
      rawData
    }
  }
`);

export type Stat = StatsForVirtualDevicesQuery['statsForVirtualDevices'][number];

export function useNetworkStats({ filter }: Pick<QueryStatsForVirtualDevicesArgs, 'filter'>) {
  const network = useNetwork();
  const virtualDeviceStatEntries = useGraphQL(
    statsForVirtualDevicesQuery,
    {
      networkUUID: network.UUID,
      filter,
    },
    {
      enabled:
        filter.virtualDeviceUUIDs.length > 0 &&
        filter?.types.length > 0 &&
        filter.durationSeconds <= maxDuration,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    },
  ).data?.statsForVirtualDevices;
  return useMemo(() => virtualDeviceStatEntries ?? [], [virtualDeviceStatEntries]);
}

import type { IconName } from '@meterup/atto';
import {
  Body,
  darkThemeSelector,
  FramedIcon,
  HStack,
  keyframes,
  space,
  styled,
} from '@meterup/atto';
import { fade, palette } from '@meterup/atto/src/common/colors';
import { shadows } from '@meterup/atto/src/stitches.config';
import { colors } from '@meterup/common';
import { useMemo } from 'react';

import { VirtualDeviceType } from '../../../../gql/graphql';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';

const TimelineStepLabel = styled(Body, {
  width: '100%',
  fontWeight: '$medium',
  textAlign: 'center',
  truncate: true,
  variants: {
    state: {
      complete: {
        color: '$$successLabelColor',
      },
      active: {
        color: '$$activeLabelColor',
      },
      upcoming: {
        color: '$$upcomingLabelColor',
      },
    },
  },
});

const pulseLight = keyframes({
  '0%': {
    boxShadow: `${shadows.manufacturerLight}, 0 0 0 0 ${fade(palette.brand600, 0.4)}`,
  },
  '70%': {
    boxShadow: `${shadows.manufacturerLight}, 0 0 0 6px ${fade(palette.brand600, 0)}`,
  },
  '100%': {
    boxShadow: `${shadows.manufacturerLight}, 0 0 0 0 ${fade(palette.brand600, 0)}`,
  },
});

const pulseDark = keyframes({
  '0%': {
    boxShadow: `${shadows.manufacturerLight}, 0 0 0 0 ${fade(palette.brand400, 0.9)}`,
  },
  '70%': {
    boxShadow: `${shadows.manufacturerLight}, 0 0 0 6px ${fade(palette.brand400, 0)}`,
  },
  '100%': {
    boxShadow: `${shadows.manufacturerLight}, 0 0 0 0 ${fade(palette.brand400, 0)}`,
  },
});

const TimelineStepIcon = styled(FramedIcon, {
  variants: {
    state: {
      complete: {
        color: '$$successIconColor',
      },
      active: {
        color: '$$activeIconColor',
        boxShadow: `0 0 0 0 ${fade(palette.brand600, 0.4)}`,
        animation: `${pulseLight} 2s infinite`,

        [darkThemeSelector]: {
          boxShadow: `0 0 0 0 ${fade(palette.brand400, 0.9)}`,
          animation: `${pulseDark} 2s infinite`,
        },
      },
      upcoming: {
        color: '$$upcomingIconColor',
      },
    },
  },
});

const TimelineStepContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$8',
  width: '100%',
});

const TimelineDivider = styled('div', {
  position: 'relative',
  zIndex: 0,
  width: '12px',

  '&::before': {
    content: '',
    display: 'flex',
    height: '2px',
    width: '80px',
    marginTop: '12px',
    marginLeft: '-34px',
    marginRight: '-34px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  variants: {
    state: {
      complete: {
        '&::before': {
          backgroundColor: colors.strokePositiveLight,

          [darkThemeSelector]: {
            backgroundColor: colors.strokePositiveDark,
          },
        },
      },
    },
  },
});

const Timeline = styled(HStack, {
  minWidth: 0,
  justifyContent: 'stretch',
  width: '100%',
  padding: '$20 0',

  $$successLabelColor: colors.bodyPositiveLight,
  $$successIconColor: colors.green600,
  $$activeLabelColor: colors.bodyBrandLight,
  $$activeIconColor: colors.brand600,
  $$upcomingLabelColor: colors.bodyNeutralLight,
  $$upcomingIconColor: colors.gray600,

  [darkThemeSelector]: {
    $$successLabelColor: colors.bodyPositiveDark,
    $$activeLabelColor: colors.bodyBrandDark,
    $$upcomingLabelColor: colors.bodyNeutralDark,
  },
});

type TimelineStepState = 'complete' | 'active' | 'upcoming';

function TimelineStep({
  icon,
  label,
  state = 'upcoming',
}: {
  icon: IconName;
  label: string;
  state?: TimelineStepState;
}) {
  return (
    <TimelineStepContainer>
      <TimelineStepIcon
        icon={state === 'complete' ? 'checkmark' : icon}
        state={state}
        size={space(28)}
      />
      <TimelineStepLabel state={state}>{label}</TimelineStepLabel>
    </TimelineStepContainer>
  );
}

enum OnboardingState {
  Kickoff = 0,
  NetworkDesign = 1,
  Installation = 2,
  Active = 3,
}

function stepStateForOnboardingState(
  currentState: OnboardingState,
  targetState: OnboardingState,
): TimelineStepState {
  if (currentState === targetState) return 'active';
  if (currentState > targetState) return 'complete';
  return 'upcoming';
}

/*
 * This is pretty hacky for now. Current heuristics:
 *
 * Network live: marked as active from Salesforce sync
 * Installation: has hardware devices assigned to network
 * Network design: has at least one of each kind of virtual device (?)
 * Kickoff: None of the above met
 */
export function OnboardingTimeline() {
  const network = useNetwork();

  const currentState: OnboardingState = useMemo(() => {
    if (network.isActive) return OnboardingState.Active;

    if (network.virtualDevices?.some((vd) => !!vd.hardwareDevice))
      return OnboardingState.Installation;

    if (
      network.virtualDevices?.some((vd) => vd.deviceType === VirtualDeviceType.Controller) ||
      network.virtualDevices?.some((vd) => vd.deviceType === VirtualDeviceType.Switch) ||
      network.virtualDevices?.some((vd) => vd.deviceType === VirtualDeviceType.AccessPoint)
    )
      return OnboardingState.NetworkDesign;

    return OnboardingState.Kickoff;
  }, [network]);

  return (
    <Timeline>
      <TimelineStep
        icon="calendar"
        label="Kickoff"
        state={stepStateForOnboardingState(currentState, OnboardingState.Kickoff)}
      />
      <TimelineDivider state={currentState > OnboardingState.Kickoff ? 'complete' : undefined} />
      <TimelineStep
        icon="topology"
        label="Network design"
        state={stepStateForOnboardingState(currentState, OnboardingState.NetworkDesign)}
      />
      <TimelineDivider
        state={currentState > OnboardingState.NetworkDesign ? 'complete' : undefined}
      />
      <TimelineStep
        icon="wrench"
        label="Installation"
        state={stepStateForOnboardingState(currentState, OnboardingState.Installation)}
      />
      <TimelineDivider
        state={currentState > OnboardingState.Installation ? 'complete' : undefined}
      />
      <TimelineStep
        icon="wifi"
        label="Network live"
        state={stepStateForOnboardingState(currentState, OnboardingState.Active)}
      />
    </Timeline>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import PolicyRoutingRuleEditDrawer from '../../../../../components/Routing/PolicyRoutingEditDrawer';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/routing/policy-routing/create',
  title: 'Add policy routing rule - Policy routing rules - Network-wide',
});

export default function PolicyRoutingRuleCreatePage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPolicyRoutingWrite) &&
            nosFlags[NosFeature.POLICY_ROUTING] &&
            ldFlags['policy-routing'],
        )
      }
    >
      <PolicyRoutingRuleEditDrawer />
    </IsPermitted>
  );
}

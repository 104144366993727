import React from 'react';

import SSIDForm from '../../../../../components/Wireless/SSIDs/SSIDForm';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/ssids/create',
  title: 'Add SSID - SSIDs - Wireless',
});

export default function SSIDCreatePage() {
  return <SSIDForm />;
}

import SwitchPortDrawer from '../../../../../components/Hardware/Switches/SwitchPortDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID',
  title: 'Port - Switch - Switches - Hardware',
});

export default function SwitchPortDetailPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SwitchPortDetailPage);
  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags, ldFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPhyInterfaceRead) &&
            nosFlags[NosFeature.SOS] &&
            ldFlags['sos-ui'],
        )
      }
      should404OnAccessDenied
    >
      <SwitchPortDrawer
        virtualDeviceUUID={drawerParams?.virtualDeviceUUID!}
        phyInterfaceUUID={drawerParams?.phyInterfaceUUID!}
      />
    </IsPermitted>
  );
}

import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import FloorPlans from '../../../../../components/FloorPlans/FloorPlans';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/design/floor-plans/:floorPlanUUID',
  layout: 'VirtualNetworkLayout',
  title: 'Floor plan - Design',
});

export default function FloorPlanPage() {
  const { floorPlanUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.FloorPlanPage),
    new ResourceNotFoundError('Page not found'),
  );

  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermCompanyGet))
      }
      should404OnAccessDenied
    >
      <FloorPlans activeFloorPlanUUID={floorPlanUUID} />
    </IsPermitted>
  );
}

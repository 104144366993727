import React from 'react';

import {
  Badge,
  BadgeProps,
  BadgeVariant,
  HStack,
  Icon,
  IconName,
  Small,
  space,
  VStack,
} from '@meterup/atto';
import {
  Availability,
  ConnectionStatusChange,
  ConnectionType,
  InternetServicePlan,
  ISPStatus,
  Quote,
} from '@meterup/connect-api';

import { EmDash } from '../../constants';
import useFormatters from '../../hooks/useFormatters';
import { styled } from '../../styled';
import ViewContractButton from '../ViewContractButton';
import { quoteToTerm } from './formatters';
import { WidthMarker } from './styles';
import { BandwidthProps } from './types';

type OptionalValue<T> = {
  value?: T;
};
export function CentsCell({ value }: OptionalValue<number>) {
  const { centsFormatter } = useFormatters();
  if (!value) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }
  return <Small>{centsFormatter(value)}</Small>;
}
export function DateCell({ value }: OptionalValue<string>) {
  const { pbDateFormatter } = useFormatters();
  if (!value) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }
  return <Small>{pbDateFormatter(value)}</Small>;
}
export type StatusCellProps = {
  connectionStatusChange?: ConnectionStatusChange;
};
export function StatusCell({ connectionStatusChange }: StatusCellProps) {
  if (!connectionStatusChange) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }
  let variant: BadgeVariant = 'neutral';
  let icon: IconName | undefined;
  const { connectionStatus } = connectionStatusChange;
  if (!connectionStatus) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }
  const { category, display, status } = connectionStatus;
  switch (category) {
    case 'active':
      variant = 'positive';
      break;
    case 'in-progress':
      icon = 'clock';
      break;
    case 'needs-attention':
      icon = 'attention';
      variant = 'negative';
      break;
    case 'quotes':
      variant = 'brand';
      icon = 'globe';
      switch (status) {
        case 'confirm-quote':
          icon = 'arrow-right';
          break;
        default:
      }
      break;
    default:
  }
  return (
    <WidthMarker>
      <Badge
        variant={variant}
        size="small"
        arrangement="leading-icon"
        ends="pill"
        {...(icon
          ? {
              icon,
            }
          : {})}
      >
        {display}
      </Badge>
    </WidthMarker>
  );
}
export const CapitalizeFirstLetter = styled('div', {
  '&:first-letter': {
    textTransform: 'uppercase',
  },
});
export type AvailabilityProps = {
  availability: keyof typeof Availability;
  recommended: boolean;
};
const ACBadgeProps: Partial<BadgeProps> = {
  arrangement: 'leading-icon',
  size: 'small',
  ends: 'pill',
};
export function AvailabilityCell({ availability, recommended }: AvailabilityProps) {
  let variant: BadgeVariant = 'neutral';
  let label = availability.replace('location_', '').replace('_', '-');
  switch (availability) {
    case Availability.location_near_net:
      variant = 'alternative';
      break;
    case Availability.location_on_net:
      variant = 'positive';
      break;
    case Availability.location_off_net:
      variant = 'negative';
      break;
    default:
      label = 'Unknown';
  }
  return (
    <div>
      <Badge variant={variant} {...ACBadgeProps}>
        <CapitalizeFirstLetter>{label}</CapitalizeFirstLetter>
        {recommended ? (
          <Badge variant="brand" {...ACBadgeProps}>
            Recommended
          </Badge>
        ) : null}
      </Badge>
    </div>
  );
}
export type ProductCellProps = {
  connectionType?: ConnectionType;
};
export function ProductCell({ connectionType }: ProductCellProps) {
  const name = connectionType?.name;
  if (!connectionType || !name) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Small>{name}</Small>;
}
const Bandwidth = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$8',
});
export function BandwidthCell({ downloadKbps, uploadKbps }: BandwidthProps) {
  if (!downloadKbps && !uploadKbps) {
    return (
      <Bandwidth>
        <Small>
          <EmDash />
        </Small>
      </Bandwidth>
    );
  }
  return (
    <HStack spacing={space(4)} align="center">
      <HStack spacing={space(2)} align="center">
        <Icon icon="arrow-down" size={12} />
        <Small family="monospace">{downloadKbps || '—'}</Small>
      </HStack>
      <HStack spacing={space(2)} align="center">
        <Icon icon="arrow-up" size={12} />
        <Small family="monospace">{uploadKbps || '—'}</Small>
      </HStack>
    </HStack>
  );
}
type ContractTermCellProps = {
  quote?: Quote;
};
export function ContractTermCell({ quote }: ContractTermCellProps) {
  const term = quoteToTerm(quote);
  if (!term) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Small>{term}</Small>;
}
type ContractCellProps = {
  internetServicePlan?: InternetServicePlan;
  companySID?: string;
};
export function ContractCell({ internetServicePlan, companySID }: ContractCellProps) {
  if (!internetServicePlan) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }
  const { contractUrl, status } = internetServicePlan;
  if (!contractUrl && !status && status !== ISPStatus.IS_UNKNOWN) {
    return (
      <Small>
        <EmDash />
      </Small>
    );
  }
  return (
    <VStack spacing={space(4)} align="start">
      <ViewContractButton
        contractPath={contractUrl}
        companySID={companySID}
        variant="secondary"
        size="small"
        icon="document"
        ispSID={internetServicePlan?.sid}
      >
        View Contract
      </ViewContractButton>
      {status ? (
        <Badge variant="alternative" ends="pill" size="small">
          {status === ISPStatus.IS_PRIMARY ? 'Primary' : 'Secondary'}
        </Badge>
      ) : null}
    </VStack>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import EditRateLimitRuleDrawer from '../../../../../components/RateLimiting/EditRuleDrawer';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/rate-limiting/create',
  title: 'Add rule - Rate limiting - Firewall',
});

export default function CreateRateLimitRulePage() {
  const featureFlags = useFeatureFlags();
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator && !featureFlags['rate-limiting'])
    throw new ResourceNotFoundError('Page not found');

  return <EditRateLimitRuleDrawer />;
}

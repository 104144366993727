import {
  Alert,
  Body,
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  HStack,
  PrimaryField,
  Small,
  space,
  styled,
  VStack,
} from '@meterup/atto';
import { colors, isDefined, isDefinedAndNotEmpty, notify } from '@meterup/common';
import { MaintenanceWindowRecurrence } from '@meterup/common/src/gql/graphql';
import { getGraphQLErrorMessageOrEmpty, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useState } from 'react';

import type { UpdateMaintenanceWindowInput } from '../../../gql/graphql';
import type { Network } from '../../../hooks/useNetworksForCompany';
import { fetchPendingFirmwareUpdate } from '../../../api/api';
import { useActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { maintenanceWindowQuery, updateMaintenanceWindowMutation } from './utils';

export const FieldLabel = styled(Small, {
  color: colors.gray600,
});

interface WindowSelectorProps {
  disabled: boolean;
  startHour: number;
  setStartHour: (value: number) => void;
  endHour: number;
  setEndHour: (value: number) => void;
  day: number;
  setDay: (value: number) => void;
  recurrence: MaintenanceWindowRecurrence;
  setRecurrence: (value: MaintenanceWindowRecurrence) => void;
}
function WindowSelector({
  disabled,
  startHour,
  setStartHour,
  endHour,
  setEndHour,
  day,
  setDay,
  recurrence,
  setRecurrence,
}: WindowSelectorProps) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const recurrences = [
    { label: 'Daily', value: MaintenanceWindowRecurrence.Daily },
    { label: 'Weekly', value: MaintenanceWindowRecurrence.Weekly },
  ];

  const timeLabel = (s: number, e: number) => {
    let startMeridiem = 'AM';
    let endMeridiem = 'AM';
    if (s >= 12) startMeridiem = 'PM';
    if (e >= 12) endMeridiem = 'PM';

    return `${s % 12 || 12}${startMeridiem} - ${e % 12 || 12}${endMeridiem}`;
  };

  const timeValues = [
    { label: '12AM - 2AM', startHour: 0, endHour: 2 },
    { label: '2AM - 4AM', startHour: 2, endHour: 4 },
    { label: '4AM - 6AM', startHour: 4, endHour: 6 },
    { label: '6AM - 8AM', startHour: 6, endHour: 8 },
    { label: '8AM - 10AM', startHour: 8, endHour: 10 },
    { label: '10AM - 12PM', startHour: 10, endHour: 12 },
    { label: '12PM - 2PM', startHour: 12, endHour: 14 },
    { label: '2PM - 4PM', startHour: 14, endHour: 16 },
    { label: '4PM - 6PM', startHour: 16, endHour: 18 },
    { label: '6PM - 8PM', startHour: 18, endHour: 20 },
    { label: '8PM - 10PM', startHour: 20, endHour: 22 },
    { label: '10PM - 12AM', startHour: 22, endHour: 0 },
  ];

  const handleTimeValueChange = (val: string) => {
    const timeValue = timeValues[parseInt(val, 10)];
    setStartHour(timeValue.startHour);
    setEndHour(timeValue.endHour);
  };

  const handleRecurrenceChange = (val: string) => {
    setDay(0);

    switch (val) {
      case 'Daily':
        setRecurrence(MaintenanceWindowRecurrence.Daily);
        return;
      case 'Weekly':
        setRecurrence(MaintenanceWindowRecurrence.Weekly);
    }
  };

  return (
    <VStack spacing={space(12)}>
      <HStack spacing={space(8)} align="center">
        <DropdownMenu>
          <DropdownMenuButton variant="secondary" size="medium" disabled={disabled}>
            {recurrences[recurrences.findIndex((i) => i.value === recurrence)].label}
          </DropdownMenuButton>
          <DropdownMenuPopover>
            <DropdownMenuRadioGroup
              value={recurrences[recurrences.findIndex((i) => i.value === recurrence)].value}
              onValueChange={(val) => handleRecurrenceChange(val)}
            >
              {recurrences.map((v) => (
                <DropdownMenuRadioItem key={`recurrence-${v.label}`} value={v.label}>
                  {v.label}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuPopover>
        </DropdownMenu>
        {recurrence === MaintenanceWindowRecurrence.Weekly && (
          <>
            <Body>on</Body>
            <DropdownMenu>
              <DropdownMenuButton variant="secondary" size="medium" disabled={disabled}>
                {days[day]}
              </DropdownMenuButton>
              <DropdownMenuPopover>
                <DropdownMenuRadioGroup
                  value={day.toString()}
                  onValueChange={(val) => setDay(parseInt(val, 10))}
                >
                  {days.map((d, i) => (
                    <DropdownMenuRadioItem key={`day-${d}`} value={i.toString()}>
                      {days[i]}
                    </DropdownMenuRadioItem>
                  ))}
                </DropdownMenuRadioGroup>
              </DropdownMenuPopover>
            </DropdownMenu>
          </>
        )}
        <Body>from</Body>
        <DropdownMenu>
          <DropdownMenuButton variant="secondary" size="medium" disabled={disabled}>
            {timeLabel(startHour, endHour)}
          </DropdownMenuButton>
          <DropdownMenuPopover>
            <DropdownMenuRadioGroup
              value={timeValues
                .findIndex((i) => i.startHour === startHour && i.endHour === endHour)
                .toString()}
              onValueChange={handleTimeValueChange}
            >
              {timeValues.map((v, i) => (
                <DropdownMenuRadioItem key={`time-value-${v.label}`} value={i.toString()}>
                  {v.label}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuPopover>
        </DropdownMenu>
      </HStack>
    </VStack>
  );
}

export default function MaintenanceWindow({ network }: { network: Network }) {
  const controller = useActiveControllerForNetwork(network);
  const controllerName = controller?.hardwareDevice?.serialNumber;

  const maintenanceWindow = useGraphQL(maintenanceWindowQuery, { networkUUID: network.UUID }).data
    ?.maintenanceWindowForNetwork;

  const isMaintenanceWindowDefined = isDefinedAndNotEmpty(maintenanceWindow);
  const startDay = maintenanceWindow?.startDayOfWeekLocal || 0;
  const [startHour, setStartHour] = useState(maintenanceWindow?.startHourOfDayLocal);
  const [endHour, setEndHour] = useState(maintenanceWindow?.endHourOfDayLocal);
  const [day, setDay] = useState(startDay);
  const [recurrence, setRecurrence] = useState(maintenanceWindow?.recurrence);

  // Load pending firmware updates, if any exist.
  const { data: firmwareUpdates, isLoading: firmwareUpdatesLoading } = useQuery(
    ['devices', controllerName, 'firmware-updates'],
    () => fetchPendingFirmwareUpdate(controllerName!),
    {
      suspense: true,
    },
  );
  const allFirmwareUpdates = firmwareUpdates?.firmware_updates ?? [];
  const pendingFirmwareUpdate = allFirmwareUpdates.find(
    (update) => update.status === api.FirmwareUpdateStatus.FIRMWARE_UPDATE_STATUS_PENDING,
  );

  const mutation = useGraphQLMutation(updateMaintenanceWindowMutation);
  const handleMaintenanceWindowSubmit = () => {
    const input: UpdateMaintenanceWindowInput = {
      recurrence,
      startHourOfDayLocal: startHour,
      endHourOfDayLocal: endHour,
    };

    switch (recurrence) {
      case MaintenanceWindowRecurrence.Daily:
        input.startDayOfWeekLocal = null;
        input.endDayOfWeekLocal = null;
        break;
      case MaintenanceWindowRecurrence.Weekly:
        // TODO: Support windows that span a date boundary (eg 11PM Friday - 1AM Saturday) ?
        input.startDayOfWeekLocal = day;
        input.endDayOfWeekLocal = day;
        break;
      // TODO: monthly
    }

    mutation.mutate(
      {
        networkUUID: network.UUID,
        input,
      },
      {
        onSuccess: () => {
          notify('Successfully updated network maintenance window.', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          notify(
            `There was a problem updating network maintenance window${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  };

  const updatesDisabled =
    firmwareUpdatesLoading || isDefined(pendingFirmwareUpdate) || !isMaintenanceWindowDefined;
  return (
    <>
      <PrimaryField
        element={
          <Body>
            {DateTime.now()
              .setZone(maintenanceWindow?.timezone ?? 'America/Los_Angeles')
              .toFormat('ZZZZZ')}
          </Body>
        }
        label="Timezone"
      />
      <PrimaryField
        element={
          isMaintenanceWindowDefined ? (
            <HStack justify="between" align="end" spacing={space(8)}>
              <WindowSelector
                disabled={updatesDisabled}
                startHour={startHour ?? -1}
                setStartHour={setStartHour}
                endHour={endHour ?? -1}
                setEndHour={setEndHour}
                day={day}
                setDay={setDay}
                recurrence={recurrence ?? MaintenanceWindowRecurrence.Daily}
                setRecurrence={setRecurrence}
              />
              <Button
                variant="secondary"
                type="button"
                disabled={updatesDisabled}
                onClick={handleMaintenanceWindowSubmit}
              >
                Save
              </Button>
            </HStack>
          ) : (
            <Alert
              relation="standalone"
              type="inline"
              variant="negative"
              icon="warning"
              copy="Your network is not configured for a recurring maintenance window. Please contact Support to set it up."
            />
          )
        }
        label="Upgrade schedule"
      />
    </>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  PrimaryField,
  PrimaryToggleField,
  Select,
  SelectItem,
  Textarea,
  TextInput,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import type { CreatePortForwardingRuleInput } from '../../../gql/graphql';
import type { PortForwardingRule } from './utils';
import { MAX_PORT_NUMBER, paths } from '../../../constants';
import { graphql } from '../../../gql';
import { PortIpProtocol } from '../../../gql/graphql';
import { CreatePortForwardingRuleInputSchema } from '../../../gql/zod-types';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider, NumberFieldProvider } from '../../Form/FieldProvider';
import { getUplinkDevices } from '../../Insights/Network/utils';
import { getPhyInterfaceLabel, uplinkPhyInterfacesQuery } from '../utils';
import { portForwardingRulesForNetworkQuery } from './PortForwarding';
import {
  createPortForwardingRule,
  deletePortForwardingRule,
  updatePortForwardingRule,
} from './utils';

export const portForwardingRuleQuery = graphql(`
  query PortForwardingRule($uuid: UUID!) {
    portForwardingRule(UUID: $uuid) {
      UUID
      name
      description
      isEnabled
      protocol
      localIPAddress

      externalPhyInterfaceUUID
      externalIPAddress

      externalPort
      localPort
    }
  }
`);

function DeletePortForwardingRuleDialog({
  rule,
  state,
}: {
  rule: PortForwardingRule;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const deleteRule = useGraphQLMutation(deletePortForwardingRule);
  const { mutate } = deleteRule;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    mutate(
      { uuid: rule.UUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(portForwardingRulesForNetworkQuery, { networkUUID: network.UUID }),
          );
          closeDrawer();
          close();
          notify('Rule deleted successfully', {
            variant: 'positive',
          });
        },
      },
    );
  }, [mutate, rule?.UUID, close, network.UUID, queryClient, closeDrawer]);

  const graphqlError = useMemo(
    () => (deleteRule.error ? getGraphQLError(deleteRule.error) : undefined),
    [deleteRule.error],
  );

  if (!rule) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete port forwarding rule" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={<>You're about to remove a port forwarding rule from your Meter network.</>}
        />
        {deleteRule.error && (
          <Alert
            icon="warning"
            variant="negative"
            copy={
              <>
                There was an error deleting the port forwarding rule
                {graphqlError?.message && <>: {graphqlError.message}</>}.
              </>
            }
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

const portForwardingRuleInputSchema = CreatePortForwardingRuleInputSchema.extend({
  name: z.string().nonempty({ message: 'Please provide a name' }),
  localIPAddress: z.string().ip({ message: 'Invalid IP address' }),
  externalIPAddress: z
    .string()
    .nullish()
    .transform((ip) => (ip === '' ? null : ip))
    .pipe(z.string().ip({ message: 'Invalid IP address' }).nullish())
    .nullish(),
  externalPort: z.number().min(1).max(MAX_PORT_NUMBER),
  localPort: z.number().min(1).max(MAX_PORT_NUMBER),
  externalPhyInterfaceUUID: z.string().nonempty({ message: 'Please select a WAN' }),
});

type PortForwardingRuleInput = z.input<typeof portForwardingRuleInputSchema>;

export default function EditPortForwardingRuleDrawer({ rule }: { rule?: PortForwardingRule }) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const navigate = useNavigate();

  const { state } = useDialogState();

  const uplinkPhyInterfaces = useGraphQL(uplinkPhyInterfacesQuery, { networkUUID: network.UUID })
    .data?.uplinkPhyInterfacesForNetwork;

  const uplinkDevices = useMemo(
    () => getUplinkDevices(uplinkPhyInterfaces ?? []),
    [uplinkPhyInterfaces],
  );

  const createMutation = useGraphQLMutation(createPortForwardingRule);
  const updateMutation = useGraphQLMutation(updatePortForwardingRule);

  const portForwardSupportsWANAndIP = useNosFeatureEnabled(
    NosFeature.PORT_FORWARD_SUPPORTS_WAN_AND_IP,
  );

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    ({ externalIPAddress, externalPhyInterfaceUUID, ...rest }: PortForwardingRuleInput) => {
      const input: CreatePortForwardingRuleInput = {
        ...rest,
        externalPhyInterfaceUUID,
        externalIPAddress: portForwardSupportsWANAndIP ? externalIPAddress || null : null,
      };

      if (rule) {
        updateMutation.mutate(
          { uuid: rule.UUID, input },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(portForwardingRulesForNetworkQuery, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(
                makeQueryKey(portForwardingRuleQuery, { uuid: rule.UUID }),
              );
              notify('Successfully updated port forwarding rule', {
                variant: 'positive',
              });
              closeDrawer();
            },
            onError: (err) => {
              const gqlError = getGraphQLError(err);
              notify(
                `There was a problem updating the port forwarding rule${gqlError ? `: ${gqlError.message}` : ''}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        createMutation.mutate(
          { networkUUID: network.UUID, input },
          {
            onSuccess: (result) => {
              queryClient.invalidateQueries(
                makeQueryKey(portForwardingRulesForNetworkQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully created port forwarding rule', {
                variant: 'positive',
              });
              navigate(
                makeDrawerLink(window.location, paths.drawers.EditPortForwardingRulePage, {
                  ruleUUID: result.createPortForwardingRule.UUID,
                  companyName,
                  networkSlug: network.slug,
                }),
              );
              closeDrawer();
            },
            onError: (err) => {
              const gqlError = getGraphQLError(err);
              notify(
                `There was a problem creating the port forwarding rule${gqlError ? `: ${gqlError.message}` : ''}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      closeDrawer,
      network.UUID,
      network.slug,
      companyName,
      rule,
      queryClient,
      createMutation,
      updateMutation,
      navigate,
      portForwardSupportsWANAndIP,
    ],
  );

  return (
    <Drawer>
      <Formik<PortForwardingRuleInput>
        initialValues={{
          name: rule?.name ?? '',
          description: rule?.description ?? '',
          isEnabled: rule?.isEnabled ?? true,
          protocol: rule?.protocol ?? PortIpProtocol.Tcp,
          externalPhyInterfaceUUID: rule?.externalPhyInterfaceUUID ?? '',
          externalIPAddress: rule?.externalIPAddress ?? '',

          localIPAddress: rule?.localIPAddress ?? '',
          externalPort: rule?.externalPort ?? 0,
          localPort: rule?.localPort ?? 0,
        }}
        validate={withZodSchema(portForwardingRuleInputSchema)}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            heading={`${rule ? 'Edit' : 'Add'} port forwarding rule`}
            icon="port-forward"
            actions={
              rule && (
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem icon="trash-can" onSelect={state.open}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
              )
            }
            onClose={closeDrawer}
          />
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enable" />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="name">
                <PrimaryField label="Name" element={<TextInput />} />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="description">
                <PrimaryField optional label="Description" element={<Textarea />} />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <FieldProvider name="externalPhyInterfaceUUID">
                <PrimaryField
                  label="WAN"
                  element={
                    <ComboBox placeholder="Select WAN">
                      {uplinkDevices.map(([, uplinks]) => (
                        <ComboBoxSection title={uplinks[0].virtualDevice.label}>
                          {uplinks.map((pi) => (
                            <ComboBoxItem key={pi.UUID}>
                              {getPhyInterfaceLabel(pi, false, true)}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxSection>
                      ))}
                    </ComboBox>
                  }
                />
              </FieldProvider>
            </FieldContainer>

            {portForwardSupportsWANAndIP && (
              <FieldContainer>
                <FieldProvider name="externalIPAddress">
                  <PrimaryField
                    label="External IP address"
                    element={<TextInput width="100%" />}
                    optional
                    description="If omitted, will use the first address defined for the WAN."
                  />
                </FieldProvider>
              </FieldContainer>
            )}

            <FieldContainer>
              <FieldProvider name="localIPAddress">
                <PrimaryField label="Local IP address" element={<TextInput />} />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <FieldProvider name="protocol">
                <PrimaryField
                  label="Protocol"
                  element={
                    <Select width="100%">
                      <SelectItem key={PortIpProtocol.Tcp}>TCP</SelectItem>
                      <SelectItem key={PortIpProtocol.Udp}>UDP</SelectItem>
                    </Select>
                  }
                />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <NumberFieldProvider name="externalPort" defaultValue={0}>
                <PrimaryField label="External port" element={<TextInput />} />
              </NumberFieldProvider>
            </FieldContainer>
            <FieldContainer>
              <NumberFieldProvider name="localPort" defaultValue={0}>
                <PrimaryField label="Local port" element={<TextInput />} />
              </NumberFieldProvider>
            </FieldContainer>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
      {rule && <DeletePortForwardingRuleDialog state={state} rule={rule} />}
    </Drawer>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import SSIDDetails from '../../../../../components/Wireless/SSIDs/SSIDDetails';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/ssids/:uuid',
  layout: 'VirtualNetworkLayout',
  title: 'SSID - Wireless',
});

export default function SSIDDetailsPage() {
  const params = Nav.useRegionParams('root', paths.pages.SSIDDetailsPage);

  return (
    <IsPermitted
      should404OnAccessDenied
      isPermitted={({ permissions }) =>
        permissions.hasPermission(PermissionType.PermSsidsRead) && !!params?.uuid
      }
    >
      <SSIDDetails uuid={params?.uuid!} />
    </IsPermitted>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import AutoVPN from '../../../components/autoVPN/AutoVPN';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../gql/graphql';
import { NosFeature } from '../../../hooks/useNosFeatures';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/auto-vpn/groups',
  layout: 'AutoVPNLayout',
  title: 'Groups - Auto VPN',
});

export default function AutoVPNGroupsPage() {
  return (
    <IsPermitted
      should404OnAccessDenied
      allowOperatorAlways={false}
      isPermitted={({ isOperator, permissions, companyNosFlags, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermAutoVpnRead) &&
        companyNosFlags[NosFeature.SITE_TO_SITE_VPN] &&
        (isOperator || !!ldFlags['site-to-site-vpn'])
      }
    >
      <AutoVPN />
    </IsPermitted>
  );
}

import {
  Badge,
  Body,
  colors,
  darkThemeSelector,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  fontWeights,
  Icon,
  shadows,
  Shell,
  ShellContent,
  ShellHeader,
  sizing,
  Small,
  space,
  styled,
  Text,
  Tooltip,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { isDefined } from '@meterup/common';
import { FocusRing } from 'react-aria';
import { useNavigate } from 'react-router';

import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import DeleteRackDialog from './DeleteRackDialog';

const MOUNT_DEFAULT = 28;

function mountHeights(first: number, last: number) {
  const mountLast = last;
  const mountDiff = first - mountLast + 1;
  const mountHeight = mountDiff * MOUNT_DEFAULT;
  const mountMinHeight = mountDiff >= 2 ? MOUNT_DEFAULT * 2 : MOUNT_DEFAULT;

  return {
    height: first !== 1 ? mountHeight : MOUNT_DEFAULT,
    minHeight: first !== 1 ? mountMinHeight : MOUNT_DEFAULT,
  };
}

const RackMountIndexNumber = styled(Text, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: `$${MOUNT_DEFAULT}`,
  maxHeight: `$${MOUNT_DEFAULT}`,
  minHeight: `$${MOUNT_DEFAULT}`,
  color: colors.bodyNeutralLight,
  fontSize: '$11',
  lineHeight: '$12',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  variants: {
    hasNote: {
      true: {
        color: colors.bodyAlternativeLight,

        [darkThemeSelector]: {
          color: colors.bodyAlternativeDark,
        },
      },
    },
    selected: {
      true: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
      false: {},
    },
  },
});

const RackMountIndexDivider = styled('div', {
  display: 'flex',
  width: '$2',
  height: '100%',
  margin: '-$6 0',
  backgroundColor: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },

  variants: {
    hasNote: {
      true: {
        backgroundColor: colors.strokeAlternativeLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokeAlternativeDark,
        },
      },
    },
    selected: {
      true: {
        backgroundColor: colors.strokeBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.strokeBrandDark,
        },
      },
      false: {},
    },
  },
});

const RackMountIndexContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$32',
  maxWidth: '$32',
  minWidth: '$32',
  height: '100%',
  textAlign: 'center',

  variants: {
    areNeighbors: {
      true: {
        gap: 0,
      },
      false: {},
    },
  },
});

const RackMountContainer = styled('div', {
  display: 'flex',
  flex: '0 1 auto',
  width: '100%',
  minHeight: `$${MOUNT_DEFAULT}`,
});

function RackMountIndex({
  last,
  first,
  hasNote = false,
  selected = false,
}: {
  last: number;
  first?: number;
  hasNote?: boolean;
  selected?: boolean;
}) {
  if (!first || last === first) {
    return (
      <RackMountIndexContainer>
        <RackMountIndexNumber hasNote={hasNote} selected={selected} family="monospace">
          {first}
        </RackMountIndexNumber>
      </RackMountIndexContainer>
    );
  }

  const areNeighbors = last === first - 1;

  return (
    <RackMountIndexContainer areNeighbors={areNeighbors}>
      <RackMountIndexNumber hasNote={hasNote} selected={selected} family="monospace">
        {first}
      </RackMountIndexNumber>
      {!areNeighbors && <RackMountIndexDivider hasNote={hasNote} selected={selected} />}
      <RackMountIndexNumber hasNote={hasNote} selected={selected} family="monospace">
        {last}
      </RackMountIndexNumber>
    </RackMountIndexContainer>
  );
}

type RackNoteProps = {
  first: number;
  last: number;
  note: string | null;
  active?: boolean;
  onClick?: () => void;
};

type RackProps = RackHeaderProps & {
  notes?: RackNoteProps[];
  children: React.ReactNode;
};

const RackNoteIcon = styled(Icon, {
  color: '$$iconColor',
});

const RackNoteMarker = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  padding: '$4',
  background: colors.bgApplicationLight,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$6',
  $$iconColor: colors.iconNeutralLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeAll: colors.strokeNeutralDark,
    $$iconColor: colors.iconNeutralDark,
  },

  variants: {
    hasNote: {
      true: {
        background: colors.bgAlternativeLight,
        strokeAll: colors.strokeAlternativeLight,
        $$iconColor: colors.iconAlternativeLight,

        [darkThemeSelector]: {
          background: colors.bgAlternativeDark,
          strokeAll: colors.strokeAlternativeDark,
          $$iconColor: colors.iconAlternativeDark,
        },
      },
      false: {},
    },
    selected: {
      true: {
        background: colors.tokenBgBrandLight,
        strokeAll: colors.tokenStrokeBrandLight,
        $$iconColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          background: colors.tokenBgBrandDark,
          strokeAll: colors.tokenStrokeBrandDark,
          $$iconColor: colors.iconBrandDark,
        },
      },
      false: {},
    },
  },
});

const RackNoteContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'stretch',

  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
      },
      false: {},
    },
    selected: {
      true: {},
      false: {},
    },
  },
});

export function RackNote({ first, last, note, onClick, active = false }: RackNoteProps) {
  const heights = mountHeights(first, last);

  const singleUnit = first === last;

  return (
    <Tooltip
      contents={
        note ? (
          <VStack>
            <Small weight="bold">
              Unit{!singleUnit ? 's' : ''} {first}
              {!singleUnit && ` - ${last}`}
            </Small>
            <Body>{note}</Body>
          </VStack>
        ) : (
          <Small weight="bold">Add note</Small>
        )
      }
      side="right"
    >
      <RackNoteContainer css={heights} selected={active} clickable={!!onClick} onClick={onClick}>
        <RackMountIndex hasNote={!!note} first={first} last={last} selected={active} />
        <RackNoteMarker hasNote={!!note} selected={active}>
          <RackNoteIcon icon={note ? 'note' : 'plus'} size={note ? space(14) : space(10)} />
        </RackNoteMarker>
      </RackNoteContainer>
    </Tooltip>
  );
}

export type IndexedItem<T> = {
  first: number;
  last: number;
  item: T | null;
};

export const continuousRackElevationSegments = <T,>(
  items: IndexedItem<T>[],
  mounts: number,
): IndexedItem<T>[] => {
  if (items.length === 0) {
    return [
      {
        first: mounts,
        last: 1,
        item: null,
      },
    ];
  }

  const list = items.slice();

  // Rack elevation indices are displayed highest on top -> lowest on bottom
  list.sort((a, b) => b.last - a.last);

  // Fill gaps between devices with placeholder null devices to simplify rendering
  for (let i = 0; i < list.length - 1; i += 1) {
    if (list[i].last > list[i + 1].first + 1) {
      list.splice(i + 1, 0, {
        last: list[i + 1].first + 1,
        first: list[i].last - 1,
        item: null,
      });
      i += 1;
    }
  }

  // Add placeholder device at top (high end) if needed
  if (list[0].first < mounts) {
    list.splice(0, 0, {
      last: list[0].first + 1,
      first: mounts,
      item: null,
    });
  }

  // Add placeholder device on bottom (low end) if needed
  if (list[list.length - 1].last > 1) {
    list.push({
      last: 1,
      first: list[list.length - 1].last - 1,
      item: null,
    });
  }

  return list;
};

const RackNotesContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '$32',
  maxWidth: '$32',
  minWidth: '$32',
  height: '100%',
  minHeight: 0,
});

export function RackNotes({
  mounts,
  notes,
  uuid,
}: {
  mounts: number;
  notes?: RackNoteProps[];
  uuid: string;
}) {
  const navigate = useNavigate();
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const openAddNote = () => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.AddRackNotePage, {
        networkSlug: network.slug,
        companyName,
        elevationUUID: uuid,
      }),
    );
  };

  const addNoteParams = Nav.useRegionParams('drawer', paths.drawers.AddRackNotePage);

  const filledNotes = continuousRackElevationSegments(
    notes?.map((n) => ({ ...n, item: n })) ?? [],
    mounts,
  );

  return (
    <RackNotesContainer>
      {filledNotes.map((s) => (
        <RackNote
          key={`note-${s.first}-${s.last}`}
          first={s.first}
          last={s.last}
          note={s.item?.note ?? null}
          active={s.item?.active || (!s.item && addNoteParams?.elevationUUID === uuid)}
          onClick={s.item ? s.item.onClick : openAddNote}
        />
      ))}
    </RackNotesContainer>
  );
}

const RackHeaderContainer = styled(ShellHeader);

type RackHeaderProps = {
  heading: string;
  mounts: number;
  uuid: string;
};

function RackHeader({ heading, mounts, uuid }: RackHeaderProps) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();
  const deleteDialogState = useDialogState();

  const openEdit = () => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.EditRackDrawerPage, {
        networkSlug: network.slug,
        companyName,
        uuid,
      }),
    );
  };

  return (
    <RackHeaderContainer
      icon="rack"
      heading={heading}
      count={`${mounts}U`}
      actions={
        <>
          <DropdownMenu>
            <DropdownMenuButton arrangement="hidden-label" icon="overflow-horizontal">
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover align="end">
              <DropdownMenuGroup>
                <DropdownMenuItem icon="pencil" onSelect={openEdit}>
                  Edit
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuGroup>
                <DropdownMenuItem icon="trash-can" onSelect={deleteDialogState.openFromMenu}>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuPopover>
          </DropdownMenu>
          <DeleteRackDialog uuid={uuid} state={deleteDialogState.state} />
        </>
      }
    />
  );
}

const RackDeviceIcon = styled(Icon, {
  width: '$12',
  height: '$12',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  variants: {
    active: {
      true: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
      false: {},
    },
  },
});

const RackDeviceLabel = styled(Text, {
  overflow: 'hidden',
  color: colors.bodyNeutralLight,
  fontSize: '$12',
  fontWeight: fontWeights.bold,
  lineHeight: '$16',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  variants: {
    active: {
      true: {
        color: colors.headingBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
      false: {},
    },
  },
});

const RackDeviceStart = styled('div', {
  minWidth: 0,
  hStack: '$4',
});

const RackDeviceEnd = styled('div', {
  hStack: '$4',
});

const RackDeviceContainer = styled('div', FocusRing, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  minWidth: 0,
  margin: '$2',
  padding: '$4',
  borderRadius: '$6',
  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
      },
      false: {},
    },
    // variant styles in compoundVariants below
    active: {
      true: {},
      false: {},
    },
    empty: {
      true: {
        border: `2px dashed ${colors.strokeNeutralLight}`,
        [darkThemeSelector]: {
          borderColor: colors.strokeNeutralDark,
        },
      },
      false: {
        background: colors.bgApplicationLight,
        strokeAll: colors.strokeNeutralLight,
        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
          strokeAll: colors.strokeNeutralDark,
        },
      },
    },
  },
  compoundVariants: [
    {
      active: true,
      empty: true,
      css: {
        strokeAll: 'none',
        boxShadow: 'none',
        border: `2px dashed ${colors.strokeBrandLight}`,
        background: colors.bgBrandLight,
        [darkThemeSelector]: {
          borderColor: colors.strokeBrandDark,
          background: colors.bgBrandDark,
        },
      },
    },
    {
      active: true,
      empty: false,
      css: {
        strokeAll: colors.strokeBrandLight,
        background: colors.bgBrandLight,
        [darkThemeSelector]: {
          strokeAll: colors.strokeBrandDark,
          background: colors.bgBrandDark,
        },
      },
    },
  ],
  defaultVariants: {
    active: false,
    empty: false,
  },
});

export type RackDeviceType =
  | 'security-appliance'
  | 'access-point'
  | 'switch'
  | 'cable-management'
  | 'fiber'
  | 'isp'
  | 'patch-panel'
  | 'ups'
  | 'other';

export type RackDeviceManufacturer = '3rd-party' | 'meter';

interface DeviceInfo {
  ports?: number;
}

interface RackDeviceProps {
  active?: boolean;
  label?: string;
  manufacturer?: RackDeviceManufacturer;
  deviceInfo?: DeviceInfo;
  onClick?: (event: any) => void;
  type?: RackDeviceType;
  emptyPlaceholder?: React.ReactNode;
}

const typeLabel = (
  type: RackDeviceType,
  manufacturer?: '3rd-party' | 'meter',
  includeManufacturer = false,
) => {
  switch (type) {
    case 'security-appliance':
      if (includeManufacturer && manufacturer === 'meter') {
        return 'Meter security appliance';
      }
      return 'Security appliance';
    case 'access-point':
      if (includeManufacturer && manufacturer === 'meter') {
        return 'Meter access point';
      }
      return 'Access point';
    case 'cable-management':
      return 'Cable management';
    case 'fiber':
      return 'Fiber';
    case 'isp':
      return 'ISP';
    case 'patch-panel':
      return 'Patch panel';
    case 'switch':
      if (includeManufacturer && manufacturer === 'meter') {
        return 'Meter switch';
      }
      return 'Switch';
    case 'ups':
      return 'UPS';
    case 'other':
    default:
      return 'Other';
  }
};

function RackDevice({
  active,
  label,
  manufacturer,
  onClick,
  type,
  emptyPlaceholder,
  deviceInfo,
}: RackDeviceProps) {
  const meterDevice =
    manufacturer === 'meter' &&
    (type === 'security-appliance' || type === 'switch' || type === 'access-point');

  if (!type) {
    return (
      <RackDeviceContainer empty clickable={isDefined(onClick)} onClick={onClick} active={active}>
        {emptyPlaceholder}
      </RackDeviceContainer>
    );
  }

  return (
    <RackDeviceContainer clickable={isDefined(onClick)} onClick={onClick} active={active}>
      <RackDeviceStart>
        {meterDevice && <RackDeviceIcon active={active} icon={type} />}
        {(label || type) && (
          <RackDeviceLabel active={active}>
            {label || typeLabel(type, manufacturer, true)}
          </RackDeviceLabel>
        )}
      </RackDeviceStart>
      <RackDeviceEnd>
        {deviceInfo?.ports != null && (
          <Badge size="x-small" variant={active ? 'brand' : 'neutral'}>
            {deviceInfo.ports} ports
          </Badge>
        )}
        {type && (
          <Badge size="x-small" variant={active ? 'brand' : 'neutral'}>
            {typeLabel(type, manufacturer, false)}
          </Badge>
        )}
      </RackDeviceEnd>
    </RackDeviceContainer>
  );
}

type RackMountProps = RackDeviceProps & {
  first: number;
  last: number;
};

export function RackMount({
  active,
  first,
  label,
  last,
  manufacturer,
  onClick,
  type,
  emptyPlaceholder,
  deviceInfo,
}: RackMountProps) {
  const heights = mountHeights(first, last);

  return (
    <RackMountContainer css={heights}>
      <RackMountIndex selected={active} last={last} first={first} />
      <RackDevice
        active={active}
        label={label}
        manufacturer={manufacturer}
        deviceInfo={deviceInfo}
        onClick={onClick}
        type={type}
        emptyPlaceholder={emptyPlaceholder}
      />
    </RackMountContainer>
  );
}

const RackMounts = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '$328',
  maxWidth: '$328',
  minWidth: '$328',
});

const RackColumns = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  width: '100%',
  padding: '$6 0',
});

const RackContent = styled(ShellContent, {
  flexDirection: 'row',
});

export const RackContainer = styled(Shell, {
  width: '$360',
  maxWidth: '$360',
  minWidth: '$360',
  height: 'auto',
  maxHeight: '100%',
  background: colors.bgApplicationLight,
  boxShadow: shadows.deviceLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.deviceDark,
  },
});

export function Rack({ children, heading, mounts, notes, uuid }: RackProps) {
  return (
    <RackContainer size="x-small">
      <RackHeader heading={heading} mounts={mounts} uuid={uuid} />
      <RackContent gutter="none">
        <RackColumns>
          <RackMounts>{children}</RackMounts>
          <RackNotes mounts={mounts} notes={notes} uuid={uuid} />
        </RackColumns>
      </RackContent>
    </RackContainer>
  );
}

export const RacksPosition = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '$24',
  margin: 'auto',
});

export const RacksContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  height: '100%',
  padding: sizing.squish,
  overflow: 'auto',
});

export function Racks({ children }: { children: React.ReactNode }) {
  return (
    <RacksContainer>
      <RacksPosition>{children}</RacksPosition>
    </RacksContainer>
  );
}

import type { FieldArrayRenderProps } from 'formik';
import {
  Button,
  DrawerContent,
  DrawerFooter,
  Link as AttoLink,
  PrimaryField,
  PrimaryToggleField,
  Select,
  SelectItem,
  Text,
} from '@meterup/atto';
import { FieldArray, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import type { NotificationAddSchema } from '../utils';
import { paths } from '../../../../../constants';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useNetworkForSettingsPath } from '../../../../../routes/pages/settings/network/NetworkUtils';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { FieldProvider } from '../../../../Form/FieldProvider';
import { useAlertReceivers } from '../../../Organization/Integrations/utils';
import { ArgumentFields, generateArgumentsState } from '../NotificationArguments';
import { useNotificationDefinitions } from '../utils';

type FormProps = {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  isEdit?: boolean;
};

export default function AlertForm({ onCancel, isEdit = false }: FormProps) {
  const companyName = useCurrentCompany();
  const definitions = useNotificationDefinitions();
  const network = useNetworkForSettingsPath({ path: paths.pages.AlertsPage });
  const receivers = useAlertReceivers({ companyUUID: network?.company?.uuid });
  const form = useFormikContext<NotificationAddSchema>();
  const { values, setFieldValue } = form;
  const selectedDefinition = useMemo(
    () => definitions.find((d) => d.name === values.input.notificationDefinitionName),
    [definitions, values.input.notificationDefinitionName],
  );

  const arrayHelperRef = useRef<FieldArrayRenderProps | null>(null);

  // Fills in the field array that renders arguments field when an alert definition is selected
  useEffect(() => {
    if (arrayHelperRef.current) {
      // clear the arguments field array when the selected alert definition changes (only for the add form)
      if (!isEdit) {
        setFieldValue('input.arguments', []);
        generateArgumentsState(selectedDefinition?.parameters ?? [], arrayHelperRef.current);
      }
    }
  }, [isEdit, selectedDefinition, setFieldValue]);

  return (
    <>
      <DrawerContent>
        <FieldProvider name="input.isEnabled">
          <PrimaryToggleField label="Enabled" />
        </FieldProvider>
        {!isEdit && (
          <FieldProvider name="input.notificationDefinitionName">
            <PrimaryField
              label="Trigger"
              element={
                <Select width="100%">
                  {definitions.map((definition) => (
                    <SelectItem key={definition.name}>{definition.displayName}</SelectItem>
                  ))}
                </Select>
              }
            />
          </FieldProvider>
        )}
        <FieldArray
          name="input.arguments"
          render={(fieldArrayHelpers) => {
            arrayHelperRef.current = fieldArrayHelpers;
            return (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {selectedDefinition && (
                  <ArgumentFields params={selectedDefinition.parameters} form={form} />
                )}
              </>
            );
          }}
        />

        <FieldProvider name="input.alertReceiverUUID">
          <PrimaryField
            label="Receiver"
            element={
              <Select width="100%">
                {receivers.map((receiver) => (
                  <SelectItem key={receiver.UUID}>{receiver.label}</SelectItem>
                ))}
              </Select>
            }
            description={
              <Text>
                Receivers can be managed in the{' '}
                <AttoLink
                  as={Link}
                  to={makeLink(paths.pages.ReceiversPage, {
                    companyName,
                  })}
                >
                  Integrations
                </AttoLink>{' '}
                settings.
              </Text>
            }
          />
        </FieldProvider>
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button type="button" onClick={onCancel} variant="secondary">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </>
        }
      />
    </>
  );
}

import type { IconName } from '@meterup/atto';
import type { SortingState } from '@tanstack/react-table';
import type { RefObject } from 'react';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Badge,
  Button,
  EmptyState,
  HStack,
  Pane,
  PaneContent,
  PaneHeader,
  SearchInput,
  space,
  Tooltip,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import {
  AutoTable,
  checkDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import type { CompanyNetworksQueryResult } from './utils';
import { CopyableMonoOrNoValue } from '../../../../components/Devices/Insights';
import { DetailLoadingFallback } from '../../../../components/Placeholders/DetailLoadingFallback';
import { ReactRouterLink } from '../../../../components/ReactRouterLink';
import { createColumnBuilder } from '../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';
import { useSearchParamsState } from '../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../../../utils/main_and_drawer_navigation';
import { fromISOtoLocaleString } from '../../../../utils/time';
import { companyQuery } from './utils';

export const Meta: PagefileMetaFn = () => ({
  path: '/operator/companies/:slug/networks',
  layout: 'OperatorLayout',
  title: 'Networks - Companies - Operator',
});

function DevicesHStack({ network }: { network: CompanyNetworksQueryResult }) {
  const deviceCount: Record<string, number> = {};

  for (const device of network.virtualDevices) {
    if (device.deviceType in deviceCount) {
      deviceCount[device.deviceType] += 1;
    } else {
      deviceCount[device.deviceType] = 1;
    }
  }

  const deviceTypeIcon = (deviceType: string): IconName | undefined => {
    switch (deviceType) {
      case 'ACCESS_POINT':
        return 'access-point';
      case 'CONTROLLER':
        return 'security-appliance';
      case 'SWITCH':
        return 'switch';
      case 'POWER_DISTRIBUTION_UNIT':
        return 'power-distribution-unit';
      default:
        return undefined;
    }
  };

  return (
    <HStack align="center" spacing={space(4)}>
      {Object.entries(deviceCount).map(([deviceType, count]) => (
        <Tooltip key={`tooltip-${deviceType}`} contents={deviceType}>
          <Badge
            arrangement="leading-icon"
            size="small"
            key={deviceType}
            variant="neutral"
            icon={deviceTypeIcon(deviceType)}
          >
            {count}
          </Badge>
        </Tooltip>
      ))}
    </HStack>
  );
}

const builder = createColumnBuilder<CompanyNetworksQueryResult>();

const columns = [
  builder.data((row) => row.label ?? row.slug, {
    id: 'label',
    header: 'Label',
    meta: {},
  }),
  builder.data((row) => row.UUID, {
    id: 'uuid',
    header: 'UUID',
    cell: ({ value }) => <CopyableMonoOrNoValue label="uuid" value={value} wrap={false} />,
  }),
  builder.data((row) => row.virtualDevices.length.toString(), {
    id: 'devices',
    header: 'Devices',
    meta: {},
    cell: ({ row }) => <DevicesHStack network={row} />,
  }),
  builder.data(
    (row) => (row.lastUpgradedAt ? fromISOtoLocaleString({ iso: row.lastUpgradedAt }) : ''),
    {
      id: 'last-upgraded',
      header: 'Last upgraded',
    },
  ),
  builder.data((row) => row.isActive.toString(), {
    id: 'is-active',
    header: 'Active',
    meta: {
      width: 136,
      alignment: 'center',
    },
    cell: ({ row }) => {
      if (row.isActive) {
        return (
          <Badge
            size="small"
            ends="pill"
            variant="positive"
            icon="checkmark"
            arrangement="hidden-label"
          >
            Yes
          </Badge>
        );
      }
      return null;
    },
  }),
  builder.data((row) => row.isTemplate.toString(), {
    id: 'is-template',
    header: 'Template',
    meta: {
      width: 136,
      alignment: 'center',
    },
    cell: ({ row }) => {
      if (row.isTemplate) {
        return (
          <Badge
            size="small"
            ends="pill"
            variant="positive"
            icon="checkmark"
            arrangement="hidden-label"
          >
            Yes
          </Badge>
        );
      }
      return null;
    },
  }),
  builder.data((row) => row.isUpgradeSensitive.toString(), {
    id: 'is-upgrade-sensitive',
    header: 'Upgrade sensitive',
    meta: {
      width: 136,
      alignment: 'center',
    },
    cell: ({ row }) => {
      if (row.isUpgradeSensitive) {
        return (
          <Badge
            size="small"
            ends="pill"
            variant="positive"
            icon="checkmark"
            arrangement="hidden-label"
          >
            Yes
          </Badge>
        );
      }
      return null;
    },
  }),
  builder.display({
    id: 'view-dashboard-button',
    meta: {
      alignment: 'end',
    },
    cell: ({ row }) => (
      <Button
        as={ReactRouterLink}
        to={makeLink(paths.pages.IndividualNetworkRootPage, {
          companyName: row.companySlug!,
          networkSlug: row.slug,
        })}
        target="_blank"
        icon="chevron-right"
        size="small"
        variant="secondary"
        arrangement="leading-label"
      >
        Open dashboard
      </Button>
    ),
  }),
];

function NetworksActions({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string | undefined;
  setGlobalFilter: (filter: string) => void;
}) {
  return (
    <SearchInput
      placeholder="..."
      aria-label="Filter networks"
      scope="scoped"
      value={globalFilter}
      onChange={setGlobalFilter}
      minWidth="56px"
    />
  );
}

function NetworkListView({
  slug,
  globalFilter,
  tableContainerRef,
}: {
  slug: string;
  globalFilter: string | undefined;
  tableContainerRef: RefObject<HTMLDivElement>;
}) {
  const company = useGraphQL(companyQuery, { slug }).data?.getCompany!;
  const [sortingState, setSortingState] = useState<SortingState>([]);

  if (company.networks.length === 0) {
    return <EmptyState icon="network" heading="No networks found" />;
  }

  return (
    <AutoTable
      isVirtual
      tableContainerRef={tableContainerRef}
      key={JSON.stringify(company.networks)}
      columns={columns}
      data={company.networks}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
    />
  );
}

export default function CompanyNetworkListPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  const { slug } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.CompanyNetworkListPage),
  );
  const company = useGraphQL(companyQuery, { slug }).data?.getCompany;
  if (!isDefinedAndNotEmpty(company)) throw new ResourceNotFoundError('Company not found');

  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const tableContainerRef = useRef(null);

  return (
    <Pane>
      <PaneHeader
        icon="network"
        heading="Networks"
        back={() =>
          navigate(
            makeDrawerLink(
              {
                pathname: makeLink(paths.pages.CompanyListPage, {}),
                search: window.location.search,
              },
              paths.drawers.CompanySummaryPage,
              {
                slug,
              },
            ),
          )
        }
        count={company?.networks.length ?? 0}
        actions={<NetworksActions globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />}
      />
      <PaneContent gutter="bottom" ref={tableContainerRef}>
        <Suspense fallback={<DetailLoadingFallback />}>
          <NetworkListView
            slug={slug}
            tableContainerRef={tableContainerRef}
            globalFilter={globalFilter}
          />
        </Suspense>
      </PaneContent>
    </Pane>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader } from '@meterup/atto';
import { GraphQLErrorBoundary } from '@meterup/graphql';
import { Suspense } from 'react';

import { useHandleRerenderDeviceConfig } from '../../../../../components/DeviceConfigs/utils';
import { DeviceConfigContextProvider } from './DeviceConfigContext';
import DeviceConfigEditor, { DeviceConfigFooter } from './DeviceConfigEditor';

type DeviceConfigEditorDialogProps = {
  serialNumber: string;
  state: OverlayTriggerState;
};

export default function DeviceConfigEditorDialog({
  serialNumber,
  state,
}: DeviceConfigEditorDialogProps) {
  const { renderDeviceConfig, handleRerender } = useHandleRerenderDeviceConfig(serialNumber);

  return (
    <GraphQLErrorBoundary fallback={() => null}>
      <Suspense fallback={null}>
        <DeviceConfigContextProvider serialNumber={serialNumber}>
          <Dialog
            contentMode="stretch"
            state={state}
            width="99vw"
            maxWidth="99vw"
            height="99vh"
            maxHeight="99vh"
          >
            <DialogHeader
              icon="code"
              heading={serialNumber}
              actions={
                <Button
                  type="button"
                  onClick={handleRerender}
                  loading={renderDeviceConfig.isLoading}
                  variant="secondary"
                  icon="arrows-rotate"
                  arrangement="leading-icon"
                  title="Immediately rerenders a config with any configuration changes since the last render."
                >
                  Rerender device config
                </Button>
              }
            />
            <DialogContent gutter="none">
              <DeviceConfigEditor insideDialog />
            </DialogContent>
            <DialogFooter actions={<DeviceConfigFooter />} />
          </Dialog>
        </DeviceConfigContextProvider>
      </Suspense>
    </GraphQLErrorBoundary>
  );
}

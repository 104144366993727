/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Body, Button, darkThemeSelector, Icon, Shortcut } from '@meterup/atto';
import { colors } from '@meterup/atto/src/stitches.config';
import { styled } from '@meterup/common';
import type {
  AxiosAPIError,
  CompanyContractResponse,
  CompanyContractsResponse,
  InternetServicePlan,
  UpdateInternetServicePlanNotesRequest,
} from '@meterup/connect-api';
import { put } from '@meterup/connect-api/src/axios';
import { logError } from '@meterup/connect-ui/src/Log.utils';
import { EditorMessage } from '@meterup/connect-ui/src/styles/Modal';
import { isMac, useTextField } from '@meterup/react-aria';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import useCompanyPath from '../hooks/useCompanyPath';
import { EditorControlsWrapper } from '../styles';

// @ts-ignore
export const PaneFooter = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$12',
});

// @ts-ignore
export const Instructions = styled('div', {
  flex: 1,
});

const FloatingButton = styled('div', {
  position: 'absolute',
  // top: "$8",
  top: '$16',
  right: '$16',

  variants: {
    display: {
      none: {
        display: 'none',
      },
    },
  },
});

// @ts-ignore
export const EditorLabel = styled('label');

// @ts-ignore
export const EditableTextArea = styled('textarea', {
  whiteSpace: 'pre',
  height: '200px',
  maxHeight: '200px',
  minHeight: '200px',
  overflowY: 'scroll',
  // margin: "$8 $16",
  fontFamily: '$sans',
  fontWeight: '$bold',
  fontSize: '$14',
  lineHeight: '$20',
  backgroundColor: colors.white,
  border: `1px solid ${colors.controlStrokeBaseLight}`,
  [darkThemeSelector]: {
    backgroundColor: colors.gray900,
    borderColor: colors.controlStrokeBaseDark,
  },
  borderRadius: '$8',
  padding: '$8 $16',
  flex: 1,
  '&:focus-visible': {
    outline: 'none',
  },
  variants: {
    state: {
      'controls-open': {
        borderRadius: '$8 $8 0 0',
        paddingBottom: 0,
      },
      'controls-closed': {},
    },
  },
});

export const EditorWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
});

type NotesEditorProps = {
  content: string;
  ispSid: string;
  label?: React.ReactNode;
  labeledBy?: string;
  labelType?: React.ComponentType;
};

export default function NotesEditor({
  content,
  ispSid,
  label,
  labeledBy,
  labelType,
}: NotesEditorProps) {
  const contentRef = useRef<HTMLTextAreaElement>(null);
  const instructionsRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<string | undefined>(content);
  const [prevState, setPrevState] = useState<string | undefined>(content);
  const [isFocused, setIsFocused] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [editorMessage, setEditorMessage] = useState<React.ReactNode>('');
  const [editorMessageAria, setEditorMessageAria] = useState('');
  const { companyLocationSID, sid: sidInner } = useParams<{
    companyLocationSID: string;
    sid: string;
  }>();
  const sid = useMemo(() => sidInner || companyLocationSID, [sidInner, companyLocationSID]);
  const blurEditableDiv = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.blur();
    }
    setIsEditorOpen(false);
  }, []);
  const queryClient = useQueryClient();
  const ispEditPath = useCompanyPath(`internet-service-plans/${ispSid}`);
  const setNotesMutation = useMutation<
    InternetServicePlan,
    AxiosAPIError,
    UpdateInternetServicePlanNotesRequest
  >((vars) => put(ispEditPath, vars), {
    onSuccess(result) {
      setEditorMessage('Note saved.');
      setEditorMessageAria('Note saved.');
      blurEditableDiv();
      setTimeout(() => {
        setNotesMutation.reset();
      }, 1_500);
      queryClient.setQueryData<CompanyContractsResponse>(['connection', sid], (old) => {
        const companyContracts: CompanyContractResponse[] = (old?.companyContracts || []).map(
          (contract) => {
            if (contract.internetServicePlan?.sid === ispSid) {
              return {
                ...contract,
                internetServicePlan: {
                  ...contract.internetServicePlan,
                  notes: result.notes,
                },
              };
            }
            return contract;
          },
        );
        return {
          companyLocation: old!.companyLocation,
          companyContracts: companyContracts || [],
        };
      });
    },
    onError(err) {
      blurEditableDiv();
      logError(err);
      setEditorMessage(
        <>
          Error saving note, please try again.{' '}
          <a href="mailto:support@meter.com" target="_blank" rel="noreferrer">
            Contact support
          </a>{' '}
          if the issue persists.
        </>,
      );
      setEditorMessageAria(
        'Error saving note, please contact support via email at support@meter.com if the issue persists',
      );
      setTimeout(() => {
        setNotesMutation.reset();
      }, 5_000);
    },
  });
  const onBlur = useCallback(() => {
    setIsFocused(false);

    if (state === prevState) {
      setIsEditorOpen(false);
    }
  }, [state, prevState, setIsFocused, setIsEditorOpen]);
  const onFocus = useCallback((e: React.SyntheticEvent) => {
    if (!prevState) {
      setPrevState(e.currentTarget.textContent || undefined);
    }
    setIsFocused(true);
    setIsEditorOpen(true);
  }, []);
  const onClickEditButton = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if (contentRef.current) {
        contentRef.current.focus();
      }
    },
    [contentRef],
  );
  const onClickCancel = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      setState(prevState);
      setIsEditorOpen(false);
      blurEditableDiv();
    },
    [blurEditableDiv, prevState, state],
  );
  const onClickSave = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (state) {
        try {
          await setNotesMutation.mutateAsync({ notes: state });
          setPrevState(state);
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    },
    [onClickCancel, setNotesMutation, state],
  );
  const onKeyDown = useCallback(
    async (e: React.KeyboardEvent<'textarea'>) => {
      if ((e.metaKey || e.ctrlKey) && e.code === 'Enter') {
        await onClickSave(e as any);
      }
      if (e.code === 'Escape') {
        contentRef.current?.blur();
      }
    },
    [onClickSave],
  );

  useEffect(() => {
    if (state !== prevState) {
      if (contentRef.current) {
        contentRef.current.focus();
      }
    }
  }, [state, prevState]);
  const [scrollTop, setScrollTop] = useState(0);
  const { inputProps, errorMessageProps, labelProps } = useTextField(
    {
      inputElementType: 'textarea',
      label: 'Enter notes',
      description: 'Enter notes about connection',
      placeholder: 'Click to edit...',
      onKeyDown,
      onChange: setState,
      onBlur,
      onFocus,
      value: state,
      errorMessage: setNotesMutation.isError && editorMessage,
      'aria-errormessage': setNotesMutation.isError ? editorMessageAria : undefined,
      ...(labeledBy ? { 'aria-labeledby': labeledBy } : {}),
    },
    contentRef,
  );

  return (
    <div>
      {label || (
        <EditorLabel {...labelProps} {...(labelType ? { as: labelType } : {})}>
          Notes
        </EditorLabel>
      )}
      <EditorWrapper>
        {/* @ts-ignore */}
        <FloatingButton display={(isFocused || isEditorOpen) && 'none'}>
          <Button
            variant="secondary"
            alt="Edit notes"
            aria-label="Click to edit notes"
            size="small"
            onClick={onClickEditButton}
          >
            <Icon icon="pencil" size={16} />
          </Button>
        </FloatingButton>

        <EditableTextArea
          style={{ marginTop: `${Math.max(8 - scrollTop, 0)}px` }}
          state={isEditorOpen ? 'controls-open' : 'controls-closed'}
          onScroll={(e: React.UIEvent<HTMLTextAreaElement>) => {
            const { currentTarget } = e;
            const { scrollTop: elScrollTop } = currentTarget;
            setScrollTop(elScrollTop);
          }}
          ref={contentRef}
          {...inputProps}
        />

        <CSSTransition nodeRef={controlsRef} in={isEditorOpen} timeout={500}>
          {(variant) => (
            <EditorControlsWrapper ref={controlsRef} state={variant}>
              <PaneFooter>
                <Instructions>
                  <Body>
                    <Shortcut keys={[...(isMac() ? ['⌘'] : ['Ctrl']), 'Enter']} />
                    to save
                  </Body>
                </Instructions>
                <Button
                  size="medium"
                  variant="secondary"
                  onClick={onClickCancel}
                  loading={setNotesMutation.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  variant="primary"
                  onClick={onClickSave}
                  loading={setNotesMutation.isLoading}
                >
                  Save
                </Button>
              </PaneFooter>
            </EditorControlsWrapper>
          )}
        </CSSTransition>
        <CSSTransition
          nodeRef={instructionsRef}
          in={setNotesMutation.isError || setNotesMutation.isSuccess}
          unmountOnExit
          timeout={250}
        >
          {(variant) => (
            <EditorMessage
              state={variant}
              ref={instructionsRef}
              type={
                setNotesMutation.isSuccess
                  ? 'success'
                  : (setNotesMutation.isError && 'error') || undefined
              }
              {...(setNotesMutation.isError ? errorMessageProps : {})}
            >
              <Body>{editorMessage}</Body>
            </EditorMessage>
          )}
        </CSSTransition>
      </EditorWrapper>
    </div>
  );
}

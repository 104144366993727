import React from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { paths } from '../../constants';
import { useDefaultCompany } from '../../providers/CurrentCompanyProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/',
});

export default function RootPage() {
  const companyName = useDefaultCompany();
  const [params] = useSearchParams();

  const pathname = makeLink(paths.pages.CompanyRootPage, {
    companyName,
  });

  return <Navigate to={{ pathname, search: params.toString() }} replace />;
}

import React from 'react';

import RADIUSProfileCreateDrawer from '../../../../../components/NetworkWide/RADIUSProfiles/RADIUSProfileCreateDrawer';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/radius-profiles/create',
  title: 'Add RADIUS profile - RADIUS profiles - Network-wide',
});

export default function RADIUSProfileCreatePage() {
  return <RADIUSProfileCreateDrawer />;
}

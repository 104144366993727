import React from 'react';

export default function ComcastBusiness() {
  return (
    <svg
      width="113"
      height="20"
      viewBox="0 0 113 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.6009 7.7012C38.7117 7.7012 37.1696 6.15903 37.1696 4.26987C37.1696 2.38072 38.7117 0.838551 40.6009 0.838551C42.4901 0.838551 44.0322 2.38072 44.0322 4.26987C44.0226 6.16867 42.4901 7.7012 40.6009 7.7012ZM40.6009 0.00963711C38.2491 0.00963711 36.331 1.92771 36.331 4.27951C36.331 6.63132 38.2491 8.54939 40.6009 8.54939C42.9527 8.54939 44.8708 6.63132 44.8708 4.27951C44.8708 1.92771 42.9527 0.00963711 40.6009 0.00963711Z"
        fill="#000101"
      />
      <path
        d="M77.4009 4.32771C77.1695 4.21205 75.7816 3.48915 75.5792 3.38313C74.8466 3.00723 74.5864 2.67952 74.5864 2.12048C74.5864 1.33976 75.2225 0.838553 76.2153 0.838553C76.7936 0.838553 77.3623 1.08916 77.69 1.28193C77.7479 1.31084 77.825 1.33012 77.8924 1.33012C78.1238 1.33012 78.3165 1.14699 78.3165 0.915662C78.3165 0.761445 78.2298 0.626505 78.1045 0.549397C77.69 0.318072 76.9864 0 76.225 0C74.7695 0 73.7575 0.867469 73.7575 2.12048C73.7575 3.23855 74.49 3.76867 75.2129 4.13494C75.4153 4.24096 76.8226 4.96385 77.0346 5.07951C77.5936 5.36867 77.9021 5.81204 77.9021 6.31325C77.9021 6.98795 77.3334 7.7012 76.2732 7.7012C75.2418 7.7012 74.4418 7.00722 74.2298 6.79518L74.1623 6.7277L73.5358 7.29638L73.6129 7.36385C73.8924 7.63373 74.8948 8.53011 76.2732 8.53011C77.8153 8.53011 78.7406 7.39276 78.7406 6.29397C78.731 5.49397 78.2491 4.77108 77.4009 4.32771Z"
        fill="#000101"
      />
      <path
        d="M31.8491 0.84819C32.7648 0.84819 33.6226 1.20482 34.278 1.8506C34.4322 2.00481 34.7214 2.00481 34.8756 1.8506C34.9527 1.77349 35.0009 1.66747 35.0009 1.5518C35.0009 1.43614 34.9624 1.33012 34.8756 1.25301L34.8274 1.20482C34.0274 0.433732 32.9768 0.00963711 31.8587 0.00963711C29.5069 0.00963711 27.5889 1.92771 27.5889 4.27951C27.5889 6.63132 29.5069 8.54939 31.8587 8.54939C33.1021 8.54939 34.2202 8.01927 35.0009 7.16144L34.4033 6.56385C33.7768 7.25782 32.8612 7.7012 31.8587 7.7012C29.9696 7.7012 28.4274 6.15903 28.4274 4.26987C28.4178 2.39036 29.9599 0.84819 31.8491 0.84819Z"
        fill="#000101"
      />
      <path
        d="M60.784 0.84819C61.6997 0.84819 62.5575 1.20482 63.2033 1.8506C63.3575 2.00481 63.6467 2.00481 63.8009 1.8506C63.878 1.77349 63.9262 1.66747 63.9262 1.5518C63.9262 1.43614 63.878 1.33012 63.8009 1.25301L63.7527 1.20482C62.9527 0.433732 61.9021 0.00963711 60.784 0.00963711C58.4322 0.00963711 56.5141 1.92771 56.5141 4.27951C56.5141 6.63132 58.4322 8.54939 60.784 8.54939C62.0274 8.54939 63.1455 8.01927 63.9262 7.16144L63.3286 6.56385C62.7021 7.25782 61.7961 7.7012 60.784 7.7012C58.8949 7.7012 57.3527 6.15903 57.3527 4.26987C57.3527 2.39036 58.8949 0.84819 60.784 0.84819Z"
        fill="#000101"
      />
      <path
        d="M53.5551 0.00963711C53.4009 0.00963711 53.2756 0.115661 53.2081 0.240962C53.1407 0.375901 50.6925 6.40963 50.6925 6.40963C50.6925 6.40963 48.2443 0.366263 48.1768 0.240962C48.1093 0.106022 47.984 0.00963711 47.8298 0.00963711C47.6467 0.00963711 47.5021 0.144576 47.4539 0.31807C47.4057 0.491564 46.0274 8.09638 46.0274 8.09638C46.0274 8.12529 46.0178 8.14457 46.0178 8.17348C46.0178 8.38553 46.1913 8.54939 46.3937 8.54939C46.5768 8.54939 46.731 8.41445 46.7696 8.24096L47.9648 1.73494C47.9648 1.73494 50.2684 7.42168 50.3358 7.55662C50.4033 7.69156 50.5286 7.79758 50.6925 7.79758C50.8467 7.79758 50.9816 7.69156 51.0491 7.55662C51.1166 7.42168 53.4202 1.73494 53.4202 1.73494L54.6635 8.50119H55.4346C55.4346 8.50119 53.9792 0.491564 53.931 0.31807C53.8732 0.144576 53.7382 0.00963711 53.5551 0.00963711Z"
        fill="#000101"
      />
      <path
        d="M67.4635 4.51084L68.7358 1.38795L70.0081 4.51084H67.4635ZM68.7262 0.00963711C68.5623 0.00963711 68.4467 0.115661 68.3696 0.250601C68.3021 0.38554 65.2081 8.01927 65.2081 8.01927C65.1888 8.06746 65.1792 8.11565 65.1792 8.16385C65.1792 8.37589 65.3431 8.53975 65.5551 8.53975C65.719 8.53975 65.8539 8.44336 65.9117 8.29879L67.1165 5.34939H70.3455L71.6274 8.49156H72.4467C72.4467 8.49156 69.1599 0.375901 69.0924 0.240962C69.0153 0.115661 68.89 0.00963711 68.7262 0.00963711Z"
        fill="#000101"
      />
      <path
        d="M85.343 0.472293C85.343 0.240967 85.1503 0.0481956 84.9189 0.0481956H80.1768C79.9454 0.0481956 79.7527 0.231329 79.7527 0.472293C79.7527 0.703618 79.9358 0.886749 80.1768 0.886749H82.172V8.49156H82.943V0.886749H84.9286C85.1599 0.896387 85.343 0.703618 85.343 0.472293Z"
        fill="#000101"
      />
      <path
        d="M53.112 11.4121C52.6879 11.4121 52.4277 11.7109 52.4277 12.0772V19.9519H53.7964V12.0772C53.7964 11.7109 53.5361 11.4121 53.112 11.4121Z"
        fill="#008BCD"
      />
      <path
        d="M37.5843 16.5784V12.0772C37.5843 11.7109 37.3145 11.4121 36.9 11.4121C36.4759 11.4121 36.2157 11.7109 36.2157 12.0772V16.5302C36.2157 18.4386 37.5554 19.9904 39.7048 19.9904C41.8446 19.9904 43.194 18.4386 43.194 16.5302V11.4507H41.8157V16.5687C41.8157 17.6964 41.0639 18.6988 39.7048 18.6988C38.3361 18.7085 37.5843 17.7061 37.5843 16.5784Z"
        fill="#008BCD"
      />
      <path
        d="M70.7313 12.8001C71.1843 12.8001 71.4542 12.5109 71.4542 12.135C71.4542 11.7591 71.1747 11.4699 70.7313 11.4699H66.5385C66.153 11.4699 65.8735 11.7495 65.8735 12.135V19.3061C65.8735 19.682 66.153 19.9615 66.5385 19.9615H71.406V18.6314H67.2421V16.1639H70.3843C70.8566 16.1639 71.1265 15.8844 71.1265 15.4989C71.1265 15.1133 70.8662 14.8338 70.3843 14.8338H67.2421V12.8001H70.7313Z"
        fill="#008BCD"
      />
      <path
        d="M31.6373 18.641H29.4976V16.1061H31.6373C32.6205 16.1061 33.0928 16.6747 33.0928 17.3784C33.0928 18.0723 32.6012 18.641 31.6373 18.641ZM29.4976 12.7904H31.5506C32.3217 12.7904 32.6976 13.2531 32.6976 13.8217C32.6976 14.3904 32.2831 14.8531 31.5506 14.8531H29.4976V12.7904ZM33.2277 15.3543C33.459 15.2097 34.0855 14.7181 34.0855 13.6097C34.0855 12.3085 33.1506 11.4507 31.7241 11.4507H28.794C28.3988 11.4507 28.1289 11.7205 28.1289 12.1157V19.9422H31.8976C33.4205 19.9422 34.4807 18.8338 34.4807 17.4073C34.4807 15.9037 33.4687 15.4699 33.2277 15.3543Z"
        fill="#008BCD"
      />
      <path
        d="M57.1795 11.4121C56.765 11.4121 56.4952 11.7109 56.4952 12.0675V19.335C56.4952 19.7013 56.8132 19.9904 57.1795 19.9904C57.5361 19.9904 57.8542 19.7013 57.8542 19.335V14.0916C57.8542 14.0916 61.1891 18.3519 62.0662 19.4796C62.2879 19.7591 62.4421 20.0001 62.8373 20.0001C63.2325 20.0001 63.5024 19.7109 63.5024 19.3446V11.4699H62.153V17.3495C62.153 17.3495 58.4421 12.588 57.9217 11.923C57.6807 11.6145 57.4976 11.4121 57.1795 11.4121Z"
        fill="#008BCD"
      />
      <path
        d="M44.7843 18.7374C44.9578 18.9591 45.7578 20.0001 47.5217 20.0001C49.2566 20.0001 50.4325 19.0073 50.4325 17.6097C50.4325 16.694 50.0759 15.9615 48.7169 15.3061C48.3506 15.1326 47.3385 14.641 47.3385 14.641C46.7988 14.3808 46.5578 14.0723 46.5578 13.6482C46.5578 13.2049 46.9241 12.723 47.8205 12.723C48.3313 12.723 48.7265 12.8868 48.9289 12.9832C49.0349 13.0314 49.2759 13.147 49.5072 13.147C49.806 13.147 50.1337 12.935 50.1337 12.5013C50.1337 12.135 49.8735 11.9422 49.6421 11.8362C49.2759 11.6627 48.7072 11.4121 47.753 11.4121C46.2397 11.4121 45.1217 12.3663 45.1217 13.6964C45.1217 14.9109 45.941 15.5181 46.9241 15.9904C47.1072 16.0772 47.8397 16.4338 48.0422 16.5302C48.6687 16.829 48.9964 17.1181 48.9964 17.6386C48.9964 18.2073 48.4662 18.6796 47.6662 18.6796C46.5193 18.6796 45.9024 17.9278 45.7964 17.8025L44.7843 18.7374Z"
        fill="#008BCD"
      />
      <path
        d="M72.8132 18.7374C72.9867 18.9591 73.7963 20.0001 75.5602 20.0001C77.2855 20.0001 78.4614 19.0073 78.4614 17.6097C78.4614 16.694 78.1048 15.9615 76.7457 15.3061C76.3891 15.1326 75.3674 14.641 75.3674 14.641C74.8277 14.3808 74.5867 14.0723 74.5867 13.6482C74.5867 13.2049 74.953 12.723 75.8494 12.723C76.3602 12.723 76.7457 12.8868 76.9578 12.9832C77.0638 13.0314 77.3048 13.147 77.5361 13.147C77.8349 13.147 78.1626 12.935 78.1626 12.5013C78.1626 12.135 77.9024 11.9422 77.671 11.8362C77.3048 11.6627 76.7265 11.4121 75.7819 11.4121C74.2686 11.4121 73.1506 12.3663 73.1506 13.6964C73.1506 14.9109 73.9698 15.5181 74.953 15.9904C75.1457 16.0772 75.8783 16.4338 76.0807 16.5302C76.6976 16.829 77.0253 17.1181 77.0253 17.6386C77.0253 18.2073 76.4951 18.6796 75.6951 18.6796C74.5385 18.6796 73.9313 17.9278 73.8253 17.8025L72.8132 18.7374Z"
        fill="#008BCD"
      />
      <path
        d="M79.7626 18.7374C79.9361 18.9591 80.7361 20.0001 82.5 20.0001C84.2253 20.0001 85.4108 19.0073 85.4108 17.6097C85.4108 16.694 85.0445 15.9615 83.6951 15.3061C83.3289 15.1326 82.3072 14.641 82.3072 14.641C81.7771 14.3808 81.5361 14.0723 81.5361 13.6482C81.5361 13.2049 81.9024 12.723 82.7891 12.723C83.2999 12.723 83.6951 12.8868 83.9072 12.9832C84.0036 13.0314 84.2445 13.147 84.4759 13.147C84.7746 13.147 85.1024 12.935 85.1024 12.5013C85.1024 12.135 84.8421 11.9422 84.6108 11.8362C84.2445 11.6627 83.6759 11.4121 82.7216 11.4121C81.2084 11.4121 80.0903 12.3663 80.0903 13.6964C80.0903 14.9109 80.9 15.5181 81.8927 15.9904C82.0759 16.0772 82.8084 16.4338 83.0108 16.5302C83.6373 16.829 83.965 17.1181 83.965 17.6386C83.965 18.2073 83.4349 18.6796 82.6349 18.6796C81.4879 18.6796 80.871 17.9278 80.765 17.8025L79.7626 18.7374Z"
        fill="#008BCD"
      />
    </svg>
  );
}

import type { OverlayTriggerState } from 'react-stately';
import {
  Alert,
  Body,
  Button,
  ComboBox,
  ComboBoxItem,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FieldContainer,
  PrimaryField,
  Small,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { METER_V1_NETWORK_VPN_IPSEC_PREFIX } from '@meterup/config';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { ErrorBoundary } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { Suspense, useCallback, useMemo, useState } from 'react';

import type { CopyIpSecTunnelFromConfig1ToConfig2Input } from '../../../../../gql/graphql';
import { FieldProvider } from '../../../../../components/Form/FieldProvider';
import { StackedSkeletons } from '../../../../../components/Placeholders/AppLoadingFallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { IPSecTunnelsQuery } from '../../../../drawers/network/secure_tunnels/ipsec/utils';
import { copyIPSecTunnelFromConfig1Mutation, copyIPSecTunnelInputSchema } from './utils';

export const IPSEC_KEY_PREFIX_REGEX = new RegExp(`^${METER_V1_NETWORK_VPN_IPSEC_PREFIX}`);

function PresharedKeyDescription() {
  const { values } = useFormikContext<CopyIpSecTunnelFromConfig1ToConfig2Input>();
  if (!values.config1Name) {
    return null;
  }

  return (
    <>
      Should be obtained from the controller's disk at
      <Small family="monospace">/etc/ipsec.d/{values.config1Name}.secrets</Small>
    </>
  );
}

function CopyIPSecTunnelDialogContent({
  state,
  controllerName,
}: {
  state: OverlayTriggerState;
  controllerName: string;
}) {
  const network = useNetwork();
  const controllerConfig = useControllerConfig(controllerName);

  const config1IPSecTunnelNames = useMemo(
    () =>
      controllerConfig
        ? Object.keys(controllerConfig.json)
            .filter((key) => key.startsWith(METER_V1_NETWORK_VPN_IPSEC_PREFIX))
            .map((key) => key.replace(IPSEC_KEY_PREFIX_REGEX, ''))
            .sort((a, b) => a.localeCompare(b))
        : [],
    [controllerConfig],
  );

  const ipSecTunnels = useGraphQL(IPSecTunnelsQuery, { networkUUID: network.UUID })?.data
    ?.ipSecTunnelsForNetwork;

  const existingIPSecTunnelNames = useMemo(
    () => new Set(ipSecTunnels?.map((ipsecTunnel) => ipsecTunnel.name)),
    [ipSecTunnels],
  );

  const copyIPSecTunnel = useGraphQLMutation(copyIPSecTunnelFromConfig1Mutation);

  const queryClient = useQueryClient();

  const handleCopy = useCallback(
    (input: CopyIpSecTunnelFromConfig1ToConfig2Input) => {
      copyIPSecTunnel.mutate(
        { networkUUID: network.UUID, input },
        {
          onSuccess() {
            queryClient.invalidateQueries(
              makeQueryKey(IPSecTunnelsQuery, { networkUUID: network.UUID }),
            );
            notify('Successfully copied IPSec tunnel.', {
              variant: 'positive',
            });
          },
          onError(err) {
            notify(
              `There was a problem copying the IPSec tunnel${getGraphQLErrorMessageOrEmpty(err)}.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [network.UUID, copyIPSecTunnel, queryClient],
  );

  const [showKey, setShowKey] = useState(false);

  return (
    <Formik<CopyIpSecTunnelFromConfig1ToConfig2Input>
      initialValues={{
        config1Name: '',
        presharedKey: '',
      }}
      validate={withZodSchema(copyIPSecTunnelInputSchema)}
      onSubmit={handleCopy}
    >
      <Form>
        <DialogContent gutter="all">
          <VStack spacing={space(16)}>
            <Body>
              Copies the selected IPSec tunnel from the controller config, using the preshared key
              provided below. Attaches to the first enabled WAN found for the security appliance.
            </Body>

            <FieldContainer>
              <FieldProvider name="config1Name">
                <PrimaryField
                  label="Config 1 tunnel name"
                  element={
                    <ComboBox>
                      {config1IPSecTunnelNames.map((config1Name) => (
                        <ComboBoxItem key={config1Name}>
                          {config1Name}
                          {existingIPSecTunnelNames.has(config1Name) && ' (copied)'}
                        </ComboBoxItem>
                      ))}
                    </ComboBox>
                  }
                />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <FieldProvider name="presharedKey">
                <PrimaryField
                  label="Preshared key"
                  controls={
                    <Button
                      type="button"
                      arrangement="hidden-label"
                      icon={showKey ? 'eye-closed' : 'eye-open'}
                      variant="secondary"
                      onClick={() => {
                        setShowKey((prev) => !prev);
                      }}
                    >
                      Show key
                    </Button>
                  }
                  element={<TextInput type={showKey ? 'text' : 'password'} />}
                  description={<PresharedKeyDescription />}
                />
              </FieldProvider>
            </FieldContainer>
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={state.close}>
                Cancel
              </Button>
              <Button type="submit" loading={copyIPSecTunnel.isLoading}>
                Copy IPSec tunnel
              </Button>
            </>
          }
        />
      </Form>
    </Formik>
  );
}

export default function CopyIPSecTunnelDialog({
  state,
  controllerName,
}: {
  state: OverlayTriggerState;
  controllerName: string;
}) {
  return (
    <Dialog state={state}>
      <DialogHeader icon="copy" heading="Copy IPSec tunnels from config 1" />
      <ErrorBoundary
        fallback={<Alert heading="Failed to load IPSec controller config." variant="negative" />}
      >
        <Suspense fallback={<StackedSkeletons />}>
          <CopyIPSecTunnelDialogContent state={state} controllerName={controllerName} />
        </Suspense>
      </ErrorBoundary>
    </Dialog>
  );
}

import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useContext, useEffect, useMemo } from 'react';

import { useNetworksForCompany } from '../../../../hooks/useNetworksForCompany';
import { Nav } from '../../../../nav';
import { DefaultNetworkContext } from '../../../../providers/DefaultNetworkProvider';

export function useNetworkForSettingsPath<Path extends string>({ path }: { path: Path }) {
  const { setDefaultNetworkUUID } = useContext(DefaultNetworkContext);
  const params = Nav.useRegionParams('root', path);

  const companyName =
    params && 'companyName' in params && typeof params.companyName === 'string'
      ? params.companyName
      : null;
  const networkSlug =
    params && 'networkSlug' in params && typeof params.networkSlug === 'string'
      ? params.networkSlug
      : null;

  const networks = useNetworksForCompany(companyName);
  const selectedNetwork = checkDefinedOrThrow(
    useMemo(() => {
      if (networkSlug) return networks.find((network) => network.slug === networkSlug) ?? null;

      return null;
    }, [networks, networkSlug]),
    new ResourceNotFoundError('Network not found'),
  );

  useEffect(() => {
    setDefaultNetworkUUID(selectedNetwork.UUID);
  }, [setDefaultNetworkUUID, selectedNetwork.UUID]);

  return selectedNetwork;
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import VLANDetails, {
  VLAN_TABS_SCHEMA,
} from '../../../../../components/NetworkWide/VLANs/VLANDetails/VLANDetails';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid/:tab',
  layout: 'VirtualNetworkLayout',
});

export default function VLANDetailsPage() {
  const { uuid, tab } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANDetailsPage),
  );

  const vlanTab = VLAN_TABS_SCHEMA.safeParse(tab);
  if (!vlanTab.success) {
    throw new ResourceNotFoundError('Page not found');
  }

  return (
    <IsPermitted
      should404OnAccessDenied
      isPermitted={({ permissions }) =>
        permissions.hasPermission(PermissionType.PermVlanRead) && vlanTab.success && !!uuid
      }
    >
      <VLANDetails uuid={uuid} tab={vlanTab.data} />
    </IsPermitted>
  );
}

import { Button, HStack, Section, SectionContent, space, VStack } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';
import { FieldArray, Form, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { RuleFields } from './BulkUpgraderFields';

export function FormButtons() {
  const { resetForm } = useFormikContext();
  return (
    <Section relation="stacked">
      <SectionContent gutter="none">
        <HStack spacing={space(12)} justify="end">
          <Button variant="secondary" onClick={() => resetForm()}>
            Reset
          </Button>
          <Button type="submit" icon="arrows-rotate" arrangement="leading-icon" variant="primary">
            Refresh network list
          </Button>
        </HStack>
      </SectionContent>
    </Section>
  );
}

export function BulkUpgraderFilterForm() {
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  return (
    <Form>
      <FieldArray
        name="rules"
        // Such a shame that Formik makes you do this w/o a hook useFieldArray
        render={(fieldArrayHelpers) => (
          <VStack spacing={space(12)}>
            <RuleFields fieldArrayHelpers={fieldArrayHelpers} />
            <Button
              onClick={() => {
                fieldArrayHelpers.push({
                  id: uuidv4(),
                  variable: null,
                });
              }}
              variant="secondary"
              icon="plus"
              arrangement="leading-icon"
            >
              Add rule
            </Button>
            <FormButtons />
          </VStack>
        )}
      />
    </Form>
  );
}

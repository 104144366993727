import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { range } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import type { RackElevation, ValidNoteParams } from './utils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { withZodSchema } from '../../../utils/withZodSchema';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import { NoteField, SlotsFields } from './Fields';
import {
  calculateAvailableNoteIndexes,
  getInitialStartIndex,
  mutationErrorMessage,
  rackElevationQuery,
  rackElevationsQuery,
  updateRackNotesMutation,
  validNoteParams,
} from './utils';

type RackElevationNote = NonNullable<RackElevation['notes']>[number];

export default function RackElevationsNoteDrawer({
  elevation,
  note,
  stopEditing,
}: {
  elevation: RackElevation;
  note?: RackElevationNote;
  stopEditing: () => void;
}) {
  const deleteDialogState = useDialogState();
  const queryClient = useQueryClient();
  const availableIndexes = useMemo(
    () => calculateAvailableNoteIndexes(elevation, note),
    [elevation, note],
  );

  const mutation = useGraphQLMutation(updateRackNotesMutation);

  const handleSubmit = useCallback(
    (v: ValidNoteParams, { resetForm }: { resetForm: () => void }) => {
      let existingNotes = elevation.notes ?? [];
      if (note) {
        existingNotes = existingNotes.filter(
          (n) =>
            n.rackMountUnitIndexStart !== note.rackMountUnitIndexStart &&
            n.rackMountUnitIndexEnd !== note.rackMountUnitIndexEnd,
        );
      }

      mutation.mutate(
        {
          rackElevationUUID: elevation.UUID,
          notes: [
            ...existingNotes,
            {
              rackMountUnitIndexStart: v.startIndex,
              rackMountUnitIndexEnd: v.endIndex,
              note: v.note,
            },
          ],
        },
        {
          onSuccess: () => {
            notify(`Successfully ${note ? 'updated' : 'added'} rack note.`, {
              variant: 'positive',
            });
            resetForm();
            queryClient.invalidateQueries(
              makeQueryKey(rackElevationsQuery, { networkUUID: elevation.networkUUID }),
            );
            queryClient.resetQueries(makeQueryKey(rackElevationQuery, { UUID: elevation.UUID }));
            stopEditing();
          },
          onError: (error) => {
            notify(
              mutationErrorMessage(
                `There was an error ${note ? 'updating' : 'adding'} a note ${
                  note ? 'for' : 'to'
                } this rack elevation.`,
                error,
              ),
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [
      mutation,
      queryClient,
      elevation.UUID,
      elevation.networkUUID,
      elevation.notes,
      note,
      stopEditing,
    ],
  );

  const handleDelete = useCallback(() => {
    if (!note) return;

    const remainingNotes =
      elevation.notes?.filter(
        (n) =>
          n.rackMountUnitIndexStart !== note.rackMountUnitIndexStart &&
          n.rackMountUnitIndexEnd !== note.rackMountUnitIndexEnd,
      ) ?? [];

    mutation.mutate(
      {
        rackElevationUUID: elevation.UUID,
        notes: remainingNotes,
      },
      {
        onSuccess: () => {
          notify('Successfully removed rack note.', {
            variant: 'positive',
          });
          queryClient.invalidateQueries(
            makeQueryKey(rackElevationsQuery, { networkUUID: elevation.networkUUID }),
          );
          queryClient.resetQueries(makeQueryKey(rackElevationQuery, { UUID: elevation.UUID }));
          stopEditing();
        },
        onError: (error) => {
          notify(
            mutationErrorMessage('There was an error deleting this rack elevation note.', error),
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [
    mutation,
    queryClient,
    elevation.UUID,
    elevation.networkUUID,
    elevation.notes,
    note,
    stopEditing,
  ]);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Drawer>
      <Formik<ValidNoteParams>
        validate={withZodSchema(validNoteParams)}
        enableReinitialize
        initialValues={{
          endIndex:
            note?.rackMountUnitIndexEnd ?? availableIndexes[availableIndexes.length - 1] ?? 0,
          startIndex:
            note?.rackMountUnitIndexStart ??
            getInitialStartIndex(availableIndexes[availableIndexes.length - 1]) ??
            0,
          note: note?.note ?? '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            icon={note ? 'pencil' : 'plus'}
            heading={`${note ? 'Edit' : 'Add'} note`}
            actions={
              note && (
                <>
                  <DropdownMenu>
                    <DropdownMenuButton arrangement="hidden-label" icon="overflow-horizontal">
                      Actions
                    </DropdownMenuButton>
                    <DropdownMenuPopover align="end">
                      <DropdownMenuItem icon="trash-can" onSelect={deleteDialogState.openFromMenu}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuPopover>
                  </DropdownMenu>
                  <DeleteDialog
                    label="note"
                    state={deleteDialogState.state}
                    handleDelete={handleDelete}
                  />
                </>
              )
            }
            onClose={closeDrawer}
          />
          <DrawerContent>
            <NoteField />
            <SlotsFields
              availableIndexes={availableIndexes}
              deviceIndexes={
                note
                  ? range(note.rackMountUnitIndexStart, note.rackMountUnitIndexEnd + 1)
                  : undefined
              }
            />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button variant="secondary" onClick={stopEditing}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useMemo } from 'react';

import useCurrentControllers from '../../../Hardware/SecurityAppliance/useCurrentControllers';
import { InspectorCellular } from './Units/InspectorCellular';
import { InspectorConfig } from './Units/InspectorConfig';
import { InspectorMetadata } from './Units/InspectorMetadata';
import { InspectorMetricsStats } from './Units/InspectorMetricsStats';

export default function InspectorSecurityAppliance({ uuid }: { uuid: string }) {
  const { virtualDevices } = useCurrentControllers();
  const virtualDevice = useMemo(
    () => virtualDevices.find((d) => d.UUID === uuid),
    [uuid, virtualDevices],
  );
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Security appliance not found'));

  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'ControllerHardwareDevice') return null;
  if (!virtualDevice || virtualDevice.__typename !== 'ControllerVirtualDevice') return null;

  return (
    <>
      <InspectorMetadata
        virtualDevice={virtualDevice}
        hardwareDevice={hardwareDevice}
        lastBootHistoryBuildName={hardwareDevice?.bootHistory?.[0]?.buildName}
      />
      {hardwareDevice && (
        <>
          <InspectorMetricsStats virtualDeviceUUID={uuid} />
          <InspectorConfig serialNumber={hardwareDevice.serialNumber} />
          <InspectorCellular serialNumber={hardwareDevice.serialNumber} />
        </>
      )}
    </>
  );
}

import { checkDefinedOrThrow } from '@meterup/common';

import ISP, { ISPValidTabs } from '../../../../../components/NetworkWide/ISPs/ISP';
import ISPEvents from '../../../../../components/NetworkWide/ISPs/ISPEvents';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/events',
  layout: 'VirtualNetworkLayout',
});

export default function ISPEventsPage() {
  const { uuid } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.ISPEventsPage));

  return <ISP uuid={uuid} activeTab={ISPValidTabs.Events} content={<ISPEvents uuid={uuid} />} />;
}

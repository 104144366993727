import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import InsightsView from '../../../../../components/Insights/InsightsView';
import { paths } from '../../../../../constants';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/operators/network/:tab',
  layout: 'VirtualNetworkLayout',
});

export default function LegacyInsightsPage() {
  const { tab } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.LegacyInsightsPage));
  const flags = useFeatureFlags();
  const isOperator = useIsOperator();

  switch (tab) {
    case 'clients':
      if (!flags['clients-chart'] && !isOperator) {
        throw new ResourceNotFoundError('Page not found');
      }
      break;
    case 'ap':
      if (!flags['ap-charts'] && !isOperator) {
        throw new ResourceNotFoundError('Page not found');
      }
      break;
    case 'rf':
      if (!flags['rf-utilization-chart'] && !isOperator) {
        throw new ResourceNotFoundError('Page not found');
      }
      break;
    default:
      throw new ResourceNotFoundError('Page not found');
  }

  return <InsightsView />;
}

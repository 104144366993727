import type { IconName } from '@meterup/atto';
import {
  Button,
  colors,
  darkThemeSelector,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  EmptyState,
  HStack,
  Pane,
  PaneContent,
  PaneHeader,
  space,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { useEffect, useState } from 'react';

function DeleteButton({ onDelete, entityName }: { onDelete: () => void; entityName: string }) {
  const dialogProps = useDialogState();
  return (
    <>
      <Button
        size="small"
        variant="secondary"
        icon="trash-can"
        arrangement="hidden-label"
        onClick={dialogProps.state.open}
      >
        Delete
      </Button>
      <Dialog state={dialogProps.state} slot="title" aria-label="Clear response" preset="narrow">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation(); // required because we may be in a nested form
            dialogProps.state.close();
          }}
        >
          <DialogHeader heading={`Delete this ${entityName}?`} icon="information" />
          <DialogFooter
            actions={
              <>
                <Button variant="secondary" onClick={dialogProps.state.close}>
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  type="submit"
                  onClick={() => {
                    dialogProps.state.close();
                    onDelete();
                  }}
                >
                  Delete {entityName}
                </Button>
              </>
            }
          />
        </form>
      </Dialog>
    </>
  );
}

export function AddEditForm({
  isEditing,
  canSubmit,
  entityName,
  children,
  onSubmit,
  onCancel,
}: {
  canSubmit: boolean;
  isEditing: boolean;
  entityName: string;
  children: React.ReactNode;
  onSubmit: () => void;
  onCancel?: () => void;
}) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation(); // required because we may be in a nested form
        onSubmit();
      }}
    >
      <DialogHeader
        heading={isEditing ? `Edit ${entityName}` : `Add new ${entityName}`}
        icon={isEditing ? 'pencil' : 'plus-circle'}
      />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>{children}</VStack>
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!canSubmit}>
              {isEditing ? 'Save' : `Add ${entityName}`}
            </Button>
          </>
        }
      />
    </form>
  );
}

export type AddEditFormProps<T> = {
  defaultValue: T | undefined;
  onSubmit: (v: T) => void;
  onCancel: () => void;
};

function updateArray<T>(arr: T[], index: number, newValue: T): T[] {
  if (index < 0 || index >= arr.length) {
    throw new Error('Index out of bounds');
  }
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

function removeElementAtIndex<T>(arr: T[], index: number): T[] {
  if (index < 0 || index >= arr.length) {
    throw new Error('Index out of bounds');
  }
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

function SimpleTable({
  entityName,
  headers,
  data,
  onEdit,
  onDelete,
}: {
  entityName: string;
  headers: string[];
  data: string[][];
  onEdit: (idx: number) => void;
  onDelete: (idx: number) => void;
}) {
  return (
    <Table>
      <TableHead>
        <TableHeadRow>
          {headers.map((header) => (
            <TableHeadCell key={header}>{header}</TableHeadCell>
          ))}
          <TableHeadCell>{/* Column for edit/delete controls */}</TableHeadCell>
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {data.map((row, rowIdx) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={`row-${rowIdx}`}>
            {row.map((cell, cellIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableCell key={`cell-${rowIdx}-${cellIdx}`}>{cell}</TableCell>
            ))}
            <TableCell alignment="end">
              <HStack spacing={space(8)} align="end">
                <Button
                  size="small"
                  variant="secondary"
                  icon="pencil"
                  arrangement="hidden-label"
                  onClick={() => onEdit(rowIdx)}
                >
                  Edit
                </Button>
                <DeleteButton onDelete={() => onDelete(rowIdx)} entityName={entityName} />
              </HStack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const ListContainer = styled('div', {
  border: '1px solid',
  borderColor: colors.strokeNeutralLight,
  borderRadius: '$10',
  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    borderColor: colors.strokeNeutralDark,
  },
});

export function CRUDList<T>({
  entityName,
  paneTitle,
  icon,
  rows,
  displayData,
  onRowsChanged,
  renderForm,
  isInline,
}: {
  renderForm: (props: AddEditFormProps<T>) => React.ReactNode;
  rows: T[];
  displayData: {
    headers: string[];
    rows: string[][];
  };
  onRowsChanged: (v: T[]) => void;
  entityName: string;
  icon: IconName;
  paneTitle: string;
  isInline: boolean;
}) {
  const dialogProps = useDialogState();

  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const selectedItem = selectedIdx !== null ? rows[selectedIdx] : undefined;

  // Open the dialog when editing
  const { open } = dialogProps.state;
  useEffect(() => {
    if (!open) return;
    if (selectedIdx === null) return;

    open();
  }, [open, selectedIdx]);

  return (
    <>
      {rows.length === 0 && (
        <EmptyState
          icon={icon}
          heading={`Please add all ${entityName}s before submitting`}
          action={
            <Button
              variant="secondary"
              icon="plus"
              condense
              size="small"
              arrangement="leading-icon"
              onClick={dialogProps.state.open}
            >
              Add {entityName}
            </Button>
          }
        />
      )}
      {rows.length > 0 && (
        <Pane>
          {isInline && <PaneHeader heading={paneTitle} icon={icon} count={rows.length} />}
          <PaneContent gutter={isInline ? 'all' : 'none'}>
            <VStack spacing={space(8)}>
              <ListContainer style={{ overflow: 'hidden' }}>
                <SimpleTable
                  entityName={entityName}
                  headers={displayData.headers}
                  data={displayData.rows}
                  onEdit={(idx) => setSelectedIdx(idx)}
                  onDelete={(idx) => onRowsChanged(removeElementAtIndex(rows, idx))}
                />
              </ListContainer>
              <div>
                <Button
                  variant="secondary"
                  icon="plus"
                  condense
                  size="small"
                  arrangement="leading-icon"
                  onClick={dialogProps.state.open}
                >
                  Add {entityName}
                </Button>
              </div>
            </VStack>
          </PaneContent>
        </Pane>
      )}
      <Dialog state={dialogProps.state} slot="title" aria-label="Clear response">
        {renderForm({
          defaultValue: selectedItem,
          onCancel: () => {
            setSelectedIdx(null);
            dialogProps.state.close();
          },
          onSubmit: (v) => {
            if (selectedIdx !== null) {
              const newArr = updateArray(rows, selectedIdx, v);
              onRowsChanged(newArr);
            } else {
              onRowsChanged([...rows, v]);
            }
            setSelectedIdx(null);
            dialogProps.state.close();
          },
        })}
      </Dialog>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define,@typescript-eslint/naming-convention,@typescript-eslint/no-use-before-define */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

import * as types from './graphql';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation RunACSForAccessPointMutation($virtualDeviceUUID: UUID!) {\n    initializeAutoChannelSelectionForAccessPoint(\n      virtualDeviceUUID: $virtualDeviceUUID\n      input: { bands: BAND_5G }\n    ) {\n      UUID\n      scheduledAt\n      timeoutAt\n    }\n  }\n':
    types.RunAcsForAccessPointMutationDocument,
  '\n  mutation RunACSForNetworkMutation($networkUUID: UUID!) {\n    initializeAutoChannelSelectionForNetwork(networkUUID: $networkUUID, input: { bands: BAND_5G }) {\n      UUID\n      scheduledAt\n      timeoutAt\n    }\n  }\n':
    types.RunAcsForNetworkMutationDocument,
  '\n  query ACSJobsForNetworkQuery($networkUUID: UUID!) {\n    autoChannelSelectionJobsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      scheduledAt\n      timeoutAt\n      completedAt\n      virtualDeviceUUIDs\n      results {\n        timestamp\n        virtualDeviceUUID\n        resultType\n        previous5GHzChannel\n        new5GHzChannel\n      }\n    }\n  }\n':
    types.AcsJobsForNetworkQueryDocument,
  '\n  fragment ClientVPNServerFields on ClientVPNServer {\n    UUID\n    endpoint\n    publicKey\n    port\n    defaultClientAllowedIPs\n    address\n    addressMask\n    phyInterface {\n      ...PhyInterfaceLabelFields\n    }\n    dnsListenAddress\n    defaultClientAllowedIPs\n    isFailoverEnabled\n\n    clients {\n      ...ClientVPNClientFields\n    }\n  }\n':
    types.ClientVpnServerFieldsFragmentDoc,
  '\n  query ClientVPNServer($UUID: UUID!) {\n    clientVPNServer(UUID: $UUID) {\n      ...ClientVPNServerFields\n    }\n  }\n':
    types.ClientVpnServerDocument,
  '\n  query ClientVPNServers($networkUUID: UUID!) {\n    clientVPNServers(networkUUID: $networkUUID) {\n      ...ClientVPNServerFields\n    }\n  }\n':
    types.ClientVpnServersDocument,
  '\n  mutation CreateClientVPNServer($networkUUID: UUID!, $input: CreateClientVPNServerInput!) {\n    createClientVPNServer(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateClientVpnServerDocument,
  '\n  mutation UpdateClientVPNServer($uuid: UUID!, $input: UpdateClientVPNServerInput!) {\n    updateClientVPNServer(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateClientVpnServerDocument,
  '\n  mutation DeleteClientVPNServer($uuid: UUID!) {\n    deleteClientVPNServer(UUID: $uuid)\n  }\n':
    types.DeleteClientVpnServerDocument,
  '\n  fragment ClientVPNClientFields on ClientVPNClient {\n    UUID\n    createdAt\n    createdBy\n    ipAddress\n    name\n    publicKey\n    userEmail\n    userSID\n    vpnServerUUID\n\n    connectionInfo {\n      observedAt\n      lastHandshakeTx\n      lastHandshakeRx\n      lastPacketTx\n      lastPacketRx\n    }\n  }\n':
    types.ClientVpnClientFieldsFragmentDoc,
  '\n  query ClientVPNClients($serverUUID: UUID!) {\n    clientVPNClients(serverUUID: $serverUUID) {\n      ...ClientVPNClientFields\n    }\n  }\n':
    types.ClientVpnClientsDocument,
  '\n  query ClientVPNClient($UUID: UUID!) {\n    clientVPNClient(UUID: $UUID) {\n      ...ClientVPNClientFields\n    }\n  }\n':
    types.ClientVpnClientDocument,
  '\n  mutation CreateClientVPNClient($serverUUID: UUID!, $input: CreateClientVPNClientInput!) {\n    createClientVPNClient(serverUUID: $serverUUID, input: $input) {\n      UUID\n      createdAt\n      createdBy\n      ipAddress\n      name\n      publicKey\n      userEmail\n      userSID\n    }\n  }\n':
    types.CreateClientVpnClientDocument,
  '\n  mutation DeleteClientVPNClient($uuid: UUID!) {\n    deleteClientVPNClient(UUID: $uuid)\n  }\n':
    types.DeleteClientVpnClientDocument,
  '\n  query WirelessConnectionEventsQuery(\n    $networkUUID: UUID!\n    $mac: MacAddress!\n    $filter: ClientMetricsTimeseriesFilterInput!\n    $limit: Int\n    $offset: Int\n  ) {\n    wirelessClientConnectionEventsByClient(\n      networkUUID: $networkUUID\n      macAddress: $mac\n      filter: $filter\n      limit: $limit\n      offset: $offset\n    ) {\n      timestamp\n      band\n      eventType\n      failedStep\n      reasonCode\n      sessionDuration\n      timeToConnect\n      is80211rRoam\n      macAddress\n      ssid {\n        UUID\n        ssid\n      }\n      virtualDevice {\n        UUID\n        label\n      }\n      previousVirtualDeviceConnectedTo {\n        UUID\n        label\n      }\n    }\n  }\n':
    types.WirelessConnectionEventsQueryDocument,
  '\n  query WirelessClientMetricsByClientQuery(\n    $networkUUID: UUID!\n    $macAddress: MacAddress!\n    $filter: ClientMetricsTimeseriesFilterInput!\n  ) {\n    wirelessClientMetricsByClient(\n      macAddress: $macAddress\n      networkUUID: $networkUUID\n      filter: $filter\n    ) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        txBytes {\n          minValue\n          maxValue\n        }\n        rxBytes {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        signal\n        noise\n        txRate\n        rxRate\n        snr\n        txBytes\n        rxBytes\n        txMulticastBytes\n        rxMulticastBytes\n        txUnicastBytes\n        rxUnicastBytes\n        rxSuccessRatio\n        txSuccessRatio\n        rxRetryRatio\n        txRetryRatio\n        clientCount\n      }\n    }\n  }\n':
    types.WirelessClientMetricsByClientQueryDocument,
  '\n  query ClientChannelUtilizationQuery(\n    $networkUUID: UUID!\n    $macAddress: MacAddress!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByClient(\n      networkUUID: $networkUUID\n      macAddress: $macAddress\n      filter: $filter\n    ) {\n      timestamp\n      totalUtilization\n    }\n  }\n':
    types.ClientChannelUtilizationQueryDocument,
  '\n  mutation upsertMACAddressAlias($companySlug: String!, $macAddress: MacAddress!, $alias: String!) {\n    upsertMACAddressAlias(companySlug: $companySlug, macAddress: $macAddress, alias: $alias) {\n      alias\n    }\n  }\n':
    types.UpsertMacAddressAliasDocument,
  '\n  mutation rpcDisconnectClient($mac: String!, $serialNumber: String!, $ssid: String!) {\n    rpcWosDisconnectClient(mac: $mac, serialNumber: $serialNumber, ssid: $ssid)\n  }\n':
    types.RpcDisconnectClientDocument,
  '\n  query VLANsAndDHCPRules($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n      dhcpRule {\n        UUID\n        dnsUseGatewayProxy\n      }\n    }\n  }\n':
    types.VlaNsAndDhcpRulesDocument,
  '\n  query DNSFirewallRulesForNetwork($networkUUID: UUID!) {\n    applicationDNSFirewallRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      isEnabled\n      name\n      action\n      priority\n      domain\n      category {\n        id\n        name\n        description\n        group {\n          id\n          name\n        }\n      }\n      group {\n        id\n        name\n      }\n      application {\n        id\n        name\n      }\n      dhcpRule {\n        UUID\n        vlan {\n          UUID\n          name\n        }\n      }\n    }\n  }\n':
    types.DnsFirewallRulesForNetworkDocument,
  '\n  query DNSFirewallRule($uuid: UUID!) {\n    applicationDNSFirewallRule(UUID: $uuid) {\n      UUID\n      isEnabled\n      name\n      action\n      priority\n      domain\n      category {\n        id\n        name\n        description\n        group {\n          id\n          name\n        }\n      }\n      group {\n        id\n        name\n      }\n      application {\n        id\n        name\n      }\n      dhcpRule {\n        UUID\n        vlan {\n          UUID\n          name\n        }\n      }\n    }\n  }\n':
    types.DnsFirewallRuleDocument,
  '\n  mutation CreateDNSFirewallRule(\n    $dhcpRuleUUID: UUID!\n    $input: CreateApplicationDNSFirewallRuleInput!\n  ) {\n    createApplicationDNSFirewallRule(dhcpRuleUUID: $dhcpRuleUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateDnsFirewallRuleDocument,
  '\n  mutation UpdateDNSFirewallRule($uuid: UUID!, $input: UpdateApplicationDNSFirewallRuleInput!) {\n    updateApplicationDNSFirewallRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateDnsFirewallRuleDocument,
  '\n  mutation DeleteDNSFirewallRule($uuid: UUID!) {\n    deleteApplicationDNSFirewallRule(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteDnsFirewallRuleDocument,
  '\n  mutation UpdateDNSFirewallRulesPriorities(\n    $input: [UpdateApplicationDNSFirewallRulesPrioritiesInput!]!\n  ) {\n    updateApplicationDNSFirewallRulesPriorities(input: $input) {\n      UUID\n      priority\n    }\n  }\n':
    types.UpdateDnsFirewallRulesPrioritiesDocument,
  '\n  query DNSFirewallRuleCategories {\n    applicationDNSFirewallRuleCategories {\n      id\n      name\n      description\n      group {\n        id\n        name\n      }\n    }\n  }\n':
    types.DnsFirewallRuleCategoriesDocument,
  '\n  query DNSFirewallRuleGroups {\n    applicationDNSFirewallRuleGroups {\n      id\n      name\n    }\n  }\n':
    types.DnsFirewallRuleGroupsDocument,
  '\n  query DNSFirewallRuleApplications {\n    applicationDNSFirewallRuleApplications {\n      id\n      name\n    }\n  }\n':
    types.DnsFirewallRuleApplicationsDocument,
  '\n  query DNSDomainApplication($hostname: String!) {\n    applicationDNSFirewallRuleApplicationForHostname(hostname: $hostname) {\n      id\n      isVisible\n      name\n    }\n  }\n':
    types.DnsDomainApplicationDocument,
  '\n  query DNSDomainCategory($hostname: String!) {\n    applicationDNSFirewallRuleCategoryForHostname(hostname: $hostname) {\n      id\n      name\n      description\n      group {\n        id\n        name\n      }\n    }\n  }\n':
    types.DnsDomainCategoryDocument,
  '\n  mutation CreateRackElevation($networkUUID: UUID!, $label: String!, $rackMountUnitCount: Int!) {\n    createRackElevation(\n      networkUUID: $networkUUID\n      input: { label: $label, rackMountUnitCount: $rackMountUnitCount }\n    ) {\n      UUID\n      networkUUID\n      rackMountUnitCount\n      label\n    }\n  }\n':
    types.CreateRackElevationDocument,
  '\n  mutation AttachDeviceToRackElevation(\n    $rackElevationUUID: UUID!\n    $rackMountUnitIndexes: [Int!]!\n    $virtualDeviceUUID: UUID\n    $label: String\n    $type: RackElevationDeviceType\n  ) {\n    attachDeviceToRackElevation(\n      rackElevationUUID: $rackElevationUUID\n      input: {\n        rackMountUnitIndexes: $rackMountUnitIndexes\n        virtualDeviceUUID: $virtualDeviceUUID\n        label: $label\n        type: $type\n      }\n    ) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n':
    types.AttachDeviceToRackElevationDocument,
  '\n  mutation DetachRackElevationDevice($UUID: UUID!) {\n    detachDeviceFromRackElevation(UUID: $UUID)\n  }\n':
    types.DetachRackElevationDeviceDocument,
  '\n  query RackElevation($UUID: UUID!) {\n    rackElevation(UUID: $UUID) {\n      UUID\n      label\n      rackMountUnitCount\n      networkUUID\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n\n        virtualDevice {\n          UUID\n          label\n          deviceType\n          deviceModel\n          description\n\n          hardwareDevice {\n            serialNumber\n            deviceType\n            deviceModel\n          }\n\n          ... on SwitchVirtualDevice {\n            phyInterfaces {\n              portNumber\n              isEthernet\n              isSFP\n            }\n          }\n        }\n      }\n      notes {\n        rackMountUnitIndexStart\n        rackMountUnitIndexEnd\n        note\n      }\n    }\n  }\n':
    types.RackElevationDocument,
  '\n  query RackElevations($networkUUID: UUID!) {\n    rackElevations(networkUUID: $networkUUID) {\n      UUID\n      label\n      rackMountUnitCount\n      networkUUID\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n\n        virtualDevice {\n          __typename\n          UUID\n          label\n          deviceType\n          deviceModel\n          description\n          networkUUID\n\n          hardwareDevice {\n            serialNumber\n            deviceType\n            deviceModel\n            isActive\n          }\n\n          ... on SwitchVirtualDevice {\n            phyInterfaces {\n              portNumber\n              isEthernet\n              isSFP\n            }\n          }\n        }\n      }\n      notes {\n        rackMountUnitIndexStart\n        rackMountUnitIndexEnd\n        note\n      }\n    }\n  }\n':
    types.RackElevationsDocument,
  '\n  mutation UpdateRackNotes($rackElevationUUID: UUID!, $notes: [RackElevationNoteInput!]) {\n    updateRackElevation(UUID: $rackElevationUUID, input: { notes: $notes }) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n':
    types.UpdateRackNotesDocument,
  '\n  query VirtualDevicesForNetwork($networkUUID: UUID!, $filter: DevicesForNetworkFilter) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: $filter) {\n      UUID\n      networkUUID\n      description\n      label\n      deviceType\n      deviceModel\n\n      hardwareDevice {\n        serialNumber\n        deviceType\n        deviceModel\n        isActive\n      }\n    }\n  }\n':
    types.VirtualDevicesForNetworkDocument,
  '\n  mutation UpdateRackElevationDevice($UUID: UUID!, $input: UpdateRackElevationDeviceInput!) {\n    updateRackElevationDevice(UUID: $UUID, input: $input) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n':
    types.UpdateRackElevationDeviceDocument,
  '\n  mutation UpdateRackElevation($UUID: UUID!, $input: UpdateRackElevationInput!) {\n    updateRackElevation(UUID: $UUID, input: $input) {\n      UUID\n      label\n      rackMountUnitCount\n    }\n  }\n':
    types.UpdateRackElevationDocument,
  '\n  mutation DeleteRackElevationMutation($UUID: UUID!) {\n    deleteRackElevation(UUID: $UUID)\n  }\n':
    types.DeleteRackElevationMutationDocument,
  '\n  query DeviceConfigsQuery($serialNumber: String!) {\n    deviceConfigs(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n      createdAt\n    }\n  }\n':
    types.DeviceConfigsQueryDocument,
  '\n  query DeviceConfigByVersion($serialNumber: String!, $version: Int!) {\n    deviceConfigByVersion(serialNumber: $serialNumber, version: $version) {\n      version\n      acknowledgedAt\n      configJSON\n    }\n  }\n':
    types.DeviceConfigByVersionDocument,
  '\n  mutation RenderDeviceConfig($serialNumber: String!) {\n    renderDeviceConfig(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n      configJSON\n    }\n  }\n':
    types.RenderDeviceConfigDocument,
  '\n  mutation createVirtualDevice($networkUUID: UUID!, $input: CreateVirtualDeviceInput!) {\n    createVirtualDevice(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateVirtualDeviceDocument,
  '\n  mutation UpdateVirtualDevice($uuid: UUID!, $input: UpdateVirtualDeviceInput!) {\n    updateVirtualDevice(virtualDeviceUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateVirtualDeviceDocument,
  '\n  mutation DeleteVirtualDevice($uuid: UUID!) {\n    deleteVirtualDevice(virtualDeviceUUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteVirtualDeviceDocument,
  '\n  mutation createVirtualDevices($networkUUID: UUID!, $input: CreateVirtualDevicesInput!) {\n    createVirtualDevices(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateVirtualDevicesDocument,
  '\n  mutation UpdateVirtualDevicesIndependently(\n    $networkUUID: UUID!\n    $inputs: [UpdateVirtualDeviceIndependentlyInput!]!\n  ) {\n    updateVirtualDevicesIndependently(networkUUID: $networkUUID, inputs: $inputs) {\n      UUID\n    }\n  }\n':
    types.UpdateVirtualDevicesIndependentlyDocument,
  '\n  mutation AssignHardwareDeviceToVirtualDevice($serialNumber: String!, $virtualDeviceUUID: UUID!) {\n    assignHardwareDeviceToVirtualDevice(\n      serialNumber: $serialNumber\n      virtualDeviceUUID: $virtualDeviceUUID\n    ) {\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n':
    types.AssignHardwareDeviceToVirtualDeviceDocument,
  '\n  mutation CreateDevHardwareDevice($input: CreateDevHardwareDeviceInput!) {\n    createDevHardwareDevice(input: $input) {\n      serialNumber\n    }\n  }\n':
    types.CreateDevHardwareDeviceDocument,
  '\n  mutation AssignHardwareDeviceToNetwork($serialNumber: String!, $networkUUID: UUID!) {\n    assignHardwareDeviceToNetwork(serialNumber: $serialNumber, networkUUID: $networkUUID) {\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n':
    types.AssignHardwareDeviceToNetworkDocument,
  '\n  mutation UnassignHardwareDeviceFromVirtualDevice($serialNumber: String!) {\n    unassignHardwareDeviceFromVirtualDevice(serialNumber: $serialNumber) {\n      serialNumber\n    }\n  }\n':
    types.UnassignHardwareDeviceFromVirtualDeviceDocument,
  '\n  mutation AssignHardwareToVirtualDevices(\n    $networkUUID: UUID!\n    $inputs: [AssignHardwareDeviceToVirtualDeviceInput!]!\n  ) {\n    assignHardwareDevicesToVirtualDevices(networkUUID: $networkUUID, inputs: $inputs) {\n      serialNumber\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n':
    types.AssignHardwareToVirtualDevicesDocument,
  '\n  query HardwareDevice($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        description\n      }\n    }\n  }\n':
    types.HardwareDeviceDocument,
  '\n  mutation UpdateHardwareDevice($serialNumber: String!, $input: UpdateHardwareDeviceInput!) {\n    updateHardwareDevice(serialNumber: $serialNumber, input: $input) {\n      serialNumber\n    }\n  }\n':
    types.UpdateHardwareDeviceDocument,
  '\n  mutation BlinkLEDsMutation($serialNumber: String!) {\n    rpcBlinkLEDs(serialNumber: $serialNumber, durationSec: 10)\n  }\n':
    types.BlinkLeDsMutationDocument,
  '\n  mutation RefreshConfigMutation($serialNumber: String!) {\n    rpcRefreshConfig(serialNumber: $serialNumber)\n  }\n':
    types.RefreshConfigMutationDocument,
  '\n  mutation RpcPDUOutletCycleMutation($serialNumber: String!, $input: RPCPDUOutletCycleInput!) {\n    rpcPDUOutletCycle(serialNumber: $serialNumber, input: $input)\n  }\n':
    types.RpcPduOutletCycleMutationDocument,
  '\n  query LatestDeviceMemoryUsage($serialNumber: String!) {\n    latestDeviceMemoryUsage(serialNumber: $serialNumber) {\n      bytesUsed\n      bytesAvailable\n    }\n  }\n':
    types.LatestDeviceMemoryUsageDocument,
  '\n  query DeviceUptime($serialNumber: String!) {\n    deviceUptime(serialNumber: $serialNumber)\n  }\n':
    types.DeviceUptimeDocument,
  '\n  query LatestDeviceCPULoad($serialNumber: String!) {\n    latestDeviceCPULoad(serialNumber: $serialNumber) {\n      usedPercentage\n    }\n  }\n':
    types.LatestDeviceCpuLoadDocument,
  '\n  query SwitchPoEInfo($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      ... on SwitchHardwareDevice {\n        poeInfo {\n          max\n          used\n        }\n      }\n    }\n  }\n':
    types.SwitchPoEInfoDocument,
  '\n  query SwitchVirtualDevicePoEInfo($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      ... on SwitchVirtualDevice {\n        poeInfo {\n          max\n          used\n        }\n      }\n    }\n  }\n':
    types.SwitchVirtualDevicePoEInfoDocument,
  '\n  query SystemInfoStatQuery($uuid: UUID!) {\n    systemInfoStatForVirtualDevice(\n      virtualDeviceUUID: $uuid\n      filter: { durationSeconds: 300, limit: 1 }\n    ) {\n      uptime\n      memoryBytesAvailable\n      memoryBytesTotal\n      cpuUsedPercentage\n    }\n  }\n':
    types.SystemInfoStatQueryDocument,
  '\n  mutation UpdatePhyInterface($uuid: UUID!, $input: UpdatePhyInterfaceInput!) {\n    updatePhyInterface(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdatePhyInterfaceDocument,
  '\n  mutation UpdatePhyInterfaces(\n    $phyInterfaceUUIDs: [UUID!]!\n    $virtualDeviceUUID: UUID!\n    $input: UpdatePhyInterfaceInput!\n  ) {\n    updatePhyInterfaces(\n      phyInterfaceUUIDs: $phyInterfaceUUIDs\n      virtualDeviceUUID: $virtualDeviceUUID\n      input: $input\n    ) {\n      UUID\n    }\n  }\n':
    types.UpdatePhyInterfacesDocument,
  '\n  query FirewallRule($uuid: UUID!) {\n    firewallRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isMeterInternal\n      isEnabled\n      isBidirectional\n      action\n      srcPrefix\n      srcVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      dstPrefix\n      dstVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      srcPortRange {\n        lower\n        upper\n      }\n      dstPortRange {\n        lower\n        upper\n      }\n      protocols\n      tags\n\n      vlanBindings {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        metric\n      }\n      phyInterfaceBindings {\n        phyInterface {\n          __typename\n          UUID\n          label\n          portNumber\n          virtualDevice {\n            __typename\n            UUID\n            label\n            deviceModel\n          }\n        }\n        metric\n      }\n    }\n  }\n':
    types.FirewallRuleDocument,
  '\n  mutation UpdateInterVLANCommunicationPermittedPairs(\n    $networkUUID: UUID!\n    $vlanPairs: [VLANPairInput!]!\n  ) {\n    updateInterVLANCommunicationPermittedPairs(networkUUID: $networkUUID, vlanPairs: $vlanPairs) {\n      UUID\n    }\n  }\n':
    types.UpdateInterVlanCommunicationPermittedPairsDocument,
  '\n  mutation CopyInterVLANCommunicationFromConfig1ToConfig2($networkUUID: UUID!) {\n    copyInterVLANCommunicationFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyInterVlanCommunicationFromConfig1ToConfig2Document,
  '\n  query VLANsForMulticast($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n      vlanID\n      isEnabled\n      isDefault\n      isInternal\n      isMulticastReflectionEnabled\n\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n  }\n':
    types.VlaNsForMulticastDocument,
  '\n  mutation UpdateVLANsForMulticast(\n    $networkUUID: UUID!\n    $inputs: [UpdateVLANsIndependentlyInput!]!\n  ) {\n    updateVLANsIndependently(networkUUID: $networkUUID, inputs: $inputs) {\n      UUID\n    }\n  }\n':
    types.UpdateVlaNsForMulticastDocument,
  '\n  query DHCPRulesWithStaticMappingsForNetworkQuery($networkUUID: UUID!) {\n    dhcpRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      vlan {\n        name\n      }\n      staticMappings {\n        UUID\n        name\n        macAddress\n        ipAddress\n        hostname\n        createdAt\n      }\n    }\n  }\n':
    types.DhcpRulesWithStaticMappingsForNetworkQueryDocument,
  '\n  query OneToOneNATRulesForNetwork($networkUUID: UUID!) {\n    oneToOneNATRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isEnabled\n      networkUUID\n\n      internalIPAddr\n      externalIPAddr\n      externalPhyInterfaceUUID\n      externalPhyInterface {\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n':
    types.OneToOneNatRulesForNetworkDocument,
  '\n  query OneToOneNATRule($uuid: UUID!) {\n    oneToOneNATRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isEnabled\n      networkUUID\n\n      internalIPAddr\n      externalIPAddr\n      externalPhyInterfaceUUID\n      externalPhyInterface {\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n':
    types.OneToOneNatRuleDocument,
  '\n  mutation CreateOneToOneNATRule($networkUUID: UUID!, $input: CreateOneToOneNATRuleInput!) {\n    createOneToOneNATRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateOneToOneNatRuleDocument,
  '\n  mutation UpdateOneToOneNATRule($uuid: UUID!, $input: UpdateOneToOneNATRuleInput!) {\n    updateOneToOneNATRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateOneToOneNatRuleDocument,
  '\n  mutation DeleteOneToOneNATRule($uuid: UUID!) {\n    deleteOneToOneNATRule(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteOneToOneNatRuleDocument,
  '\n  query PortForwardingRule($uuid: UUID!) {\n    portForwardingRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isEnabled\n      protocol\n      localIPAddress\n\n      externalPhyInterfaceUUID\n      externalIPAddress\n\n      externalPort\n      localPort\n    }\n  }\n':
    types.PortForwardingRuleDocument,
  '\n  query PortForwardingRulesForNetwork($networkUUID: UUID!) {\n    portForwardingRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isEnabled\n      protocol\n      localIPAddress\n\n      externalPhyInterfaceUUID\n      externalIPAddress\n\n      externalPhyInterface {\n        __typename\n        UUID\n        label\n        hardwareLabel\n        portNumber\n        virtualDevice {\n          UUID\n          label\n          deviceModel\n        }\n        internetServicePlan {\n          provider {\n            name\n          }\n        }\n      }\n\n      externalPort\n      localPort\n    }\n  }\n':
    types.PortForwardingRulesForNetworkDocument,
  '\n  mutation CreatePortForwardingRule($networkUUID: UUID!, $input: CreatePortForwardingRuleInput!) {\n    createPortForwardingRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreatePortForwardingRuleDocument,
  '\n  mutation UpdatePortForwardingRule($uuid: UUID!, $input: UpdatePortForwardingRuleInput!) {\n    updatePortForwardingRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdatePortForwardingRuleDocument,
  '\n  mutation DeletePortForwardingRule($uuid: UUID!) {\n    deletePortForwardingRule(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeletePortForwardingRuleDocument,
  '\n  query VLANsForFirewall($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      permittedInterVLANCommunicationVLANs {\n        UUID\n        name\n      }\n\n      dhcpRule {\n        applicationDNSFirewallRules {\n          UUID\n        }\n      }\n    }\n  }\n':
    types.VlaNsForFirewallDocument,
  '\n  fragment PhyInterfaceLabelFields on PhyInterface {\n    __typename\n    UUID\n    portNumber\n    label\n    hardwareLabel\n    virtualDevice {\n      UUID\n      label\n      deviceModel\n    }\n    internetServicePlan {\n      provider {\n        name\n      }\n    }\n  }\n':
    types.PhyInterfaceLabelFieldsFragmentDoc,
  '\n  query FirewallRulesForNetwork($networkUUID: UUID!) {\n    firewallRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isMeterInternal\n      isEnabled\n      isBidirectional\n      action\n      srcPrefix\n      srcVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      dstPrefix\n      dstVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      srcPortRange {\n        lower\n        upper\n      }\n      dstPortRange {\n        lower\n        upper\n      }\n      protocols\n      tags\n\n      vlanBindings {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        metric\n      }\n      phyInterfaceBindings {\n        phyInterface {\n          ...PhyInterfaceLabelFields\n        }\n        metric\n      }\n    }\n  }\n':
    types.FirewallRulesForNetworkDocument,
  '\n  mutation UpdateFirewallRuleBindingsForVLAN($vlanUUID: UUID!, $orderedRuleUUIDs: [UUID!]!) {\n    bindFirewallRulesToVLAN(vlanUUID: $vlanUUID, orderedRuleUUIDs: $orderedRuleUUIDs) {\n      metric\n    }\n  }\n':
    types.UpdateFirewallRuleBindingsForVlanDocument,
  '\n  mutation UpdateFirewallRuleBindingsForPhyInterface(\n    $phyInterfaceUUID: UUID!\n    $orderedRuleUUIDs: [UUID!]!\n  ) {\n    bindFirewallRulesToPhyInterface(\n      phyInterfaceUUID: $phyInterfaceUUID\n      orderedRuleUUIDs: $orderedRuleUUIDs\n    ) {\n      metric\n    }\n  }\n':
    types.UpdateFirewallRuleBindingsForPhyInterfaceDocument,
  '\n  query UplinkPhyInterfacesForNetwork($networkUUID: UUID!) {\n    uplinkPhyInterfacesForNetwork(networkUUID: $networkUUID) {\n      __typename\n      ...PhyInterfaceLabelFields\n      UUID\n      label\n      isEnabled\n      portNumber\n      hardwareLabel\n      virtualDevice {\n        __typename\n        UUID\n        label\n        deviceModel\n        hardwareDevice {\n          isActive\n          isConnectedToBackend\n          serialNumber\n        }\n        ... on ControllerVirtualDevice {\n          highAvailability {\n            role\n            status\n          }\n        }\n      }\n      internetServicePlan {\n        provider {\n          name\n        }\n      }\n      ipv4ClientAddresses\n      isUplinkActive\n      hasWANActivity\n      uplinkExternalAddresses\n    }\n  }\n':
    types.UplinkPhyInterfacesForNetworkDocument,
  '\n  mutation CreateFirewallRule($networkUUID: UUID!, $input: CreateFirewallRuleInput!) {\n    createFirewallRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateFirewallRuleDocument,
  '\n  mutation UpdateFirewallRule($uuid: UUID!, $input: UpdateFirewallRuleInput!) {\n    updateFirewallRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateFirewallRuleDocument,
  '\n  mutation DeleteFirewallRule($uuid: UUID!) {\n    deleteFirewallRule(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteFirewallRuleDocument,
  '\n  query FloorPlansForNetwork($networkUUID: UUID!) {\n    floorPlansForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      description\n      imageS3Key\n      imageDownloadURL\n    }\n  }\n':
    types.FloorPlansForNetworkDocument,
  '\n  query FloorPlan($UUID: UUID!) {\n    floorPlan(UUID: $UUID) {\n      UUID\n      label\n      description\n      imageS3Key\n    }\n  }\n':
    types.FloorPlanDocument,
  '\n  mutation CreateFloorPlan($networkUUID: UUID!, $input: CreateFloorPlanInput!) {\n    createFloorPlan(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateFloorPlanDocument,
  '\n  mutation UpdateFloorPlan($UUID: UUID!, $input: UpdateFloorPlanInput!) {\n    updateFloorPlan(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateFloorPlanDocument,
  '\n  mutation DeleteFloorPlan($UUID: UUID!) {\n    deleteFloorPlan(UUID: $UUID) {\n      UUID\n    }\n  }\n':
    types.DeleteFloorPlanDocument,
  '\n  query APBSSIDsQuery($virtualDeviceUUID: UUID!) {\n    virtualDevice(UUID: $virtualDeviceUUID) {\n      hardwareDevice {\n        __typename\n\n        ... on AccessPointHardwareDevice {\n          BSSIDs {\n            SSID {\n              ssid\n            }\n            BSSID\n            isActive\n            radioBand\n          }\n        }\n      }\n    }\n  }\n':
    types.ApbssiDsQueryDocument,
  '\n  query APBootHistoryQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n    }\n  }\n':
    types.ApBootHistoryQueryDocument,
  '\n  mutation RebootAccessPoint($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n':
    types.RebootAccessPointDocument,
  '\n  query APScanDataQuery($networkUUID: UUID!, $virtualDeviceUUID: UUID!, $durationSeconds: Int!) {\n    statsForVirtualDevices(\n      networkUUID: $networkUUID\n      filter: {\n        virtualDeviceUUIDs: [$virtualDeviceUUID]\n        types: [WOS_SCAN_TABLE]\n        durationSeconds: $durationSeconds\n      }\n    ) {\n      observedAt\n      virtualDeviceUUID\n      rawData\n    }\n  }\n':
    types.ApScanDataQueryDocument,
  '\n  query PDUInsightsQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      serialNumber\n      macAddress\n      deviceModel\n      tunnelIPAddress\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      virtualDevice {\n        __typename\n        UUID\n        label\n        description\n        networkUUID\n        nosVersion {\n          id\n          version\n          buildName\n        }\n        pendingNosVersion {\n          nosVersion {\n            version\n          }\n          scheduledAt\n        }\n        ... on PowerDistributionUnitVirtualDevice {\n          ipAddress\n        }\n      }\n    }\n  }\n':
    types.PduInsightsQueryDocument,
  '\n  mutation RebootPowerDistributionUnit($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n':
    types.RebootPowerDistributionUnitDocument,
  '\n  query FallbackPowerDistributionUnitHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n    }\n  }\n':
    types.FallbackPowerDistributionUnitHardwareQueryDocument,
  '\n  query PowerDistributionUnitQuery($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      networkUUID\n      isConsoleEnabled\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        buildName\n        version\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      uptime\n\n      ... on PowerDistributionUnitVirtualDevice {\n        ipAddress\n      }\n\n      hardwareDevice {\n        __typename\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        serialNumber\n        deviceType\n        bootHistory(count: 20) {\n          bootCount\n          buildName\n          rebootReason\n          createdAt\n        }\n      }\n    }\n  }\n':
    types.PowerDistributionUnitQueryDocument,
  '\n  query PowerDistributionUnitsQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(\n      networkUUID: $networkUUID\n      filter: { deviceType: POWER_DISTRIBUTION_UNIT }\n    ) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      uptime\n\n      ... on PowerDistributionUnitVirtualDevice {\n        ipAddress\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n      }\n    }\n  }\n':
    types.PowerDistributionUnitsQueryDocument,
  '\n  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {\n    deviceHeartbeatForDevice(serialNumber: $serialNumber) {\n      token\n    }\n  }\n':
    types.DeviceHeartbeatForDeviceQueryDocument,
  '\n  query OutletsQuery($virtualDeviceUUID: UUID!) {\n    outletInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      label\n      outletNumber\n      isEnabled\n      powerCycleDelaySec\n      description\n      currentAmps\n      powerWatts\n      connectedVirtualDevice {\n        UUID\n        label\n        deviceType\n      }\n      connectedVirtualDeviceUUID\n    }\n  }\n':
    types.OutletsQueryDocument,
  '\n  mutation UpdateOutletInterfaceMutation($uuid: UUID!, $input: UpdateOutletInterfaceInput!) {\n    updateOutletInterface(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateOutletInterfaceMutationDocument,
  '\n  query hostMonitoring(\n    $networkUUID: UUID!\n    $hostIPs: [String!]!\n    $durationSeconds: Int!\n    $stepSeconds: Int!\n  ) {\n    hostMonitoringForNetwork(\n      networkUUID: $networkUUID\n      filter: { durationSeconds: $durationSeconds, stepSeconds: $stepSeconds }\n      hostIPs: $hostIPs\n    ) {\n      values {\n        hostIPAddr\n        virtualDeviceUUID\n        timestamp\n        value\n        status\n        srcEthPortNum\n      }\n    }\n  }\n':
    types.HostMonitoringDocument,
  '\n  mutation RebootSecurityAppliance($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n':
    types.RebootSecurityApplianceDocument,
  '\n  fragment ControllerFragment on VirtualDevice {\n    __typename\n    UUID\n    label\n    description\n    deviceType\n    deviceModel\n    isConsoleEnabled\n    networkUUID\n    uptime\n    nosVersion {\n      id\n      version\n      buildName\n    }\n    pendingNosVersion {\n      nosVersion {\n        version\n      }\n      scheduledAt\n    }\n\n    hardwareDevice {\n      __typename\n      deviceType\n      serialNumber\n      macAddress\n      tunnelIPAddress\n      deviceModel\n      isActive\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      uptime\n      bootHistory(count: 20) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      isDev @include(if: $includeIsDev)\n    }\n\n    ... on ControllerVirtualDevice {\n      highAvailability {\n        role\n        status\n      }\n    }\n  }\n':
    types.ControllerFragmentFragmentDoc,
  '\n  query ControllersQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: CONTROLLER }) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      uptime\n      nosVersion {\n        id\n        version\n        buildName\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n      }\n    }\n  }\n':
    types.ControllersQueryDocument,
  '\n  query ControllerQuery($uuid: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        phyInterfaces {\n          ...ControllerPhyInterfaceFields\n        }\n      }\n    }\n  }\n':
    types.ControllerQueryDocument,
  '\n  query HAConfigForNetworkQuery($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      highAvailabilityConfig {\n        primaryPhyInterface {\n          UUID\n          portNumber\n          label\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            hardwareDevice {\n              isConnectedToBackend\n            }\n          }\n        }\n        backupPhyInterface {\n          UUID\n          portNumber\n          label\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            hardwareDevice {\n              isConnectedToBackend\n            }\n          }\n        }\n        advertisementVLAN {\n          UUID\n          vlanID\n          name\n        }\n        preemption\n        advertisementIntervalMs\n      }\n    }\n  }\n':
    types.HaConfigForNetworkQueryDocument,
  '\n  fragment ControllerVirtualDeviceFragment on ControllerVirtualDevice {\n    highAvailability {\n      role\n      status\n    }\n\n    phyInterfaces {\n      UUID\n      isEnabled\n      isSFP\n      isTrunkPort\n      isUplink\n      label\n      portNumber\n      portSpeedMbps\n\n      connectedDevices {\n        hardwareDevice {\n          deviceModel\n          deviceType\n          virtualDevice {\n            UUID\n            label\n            deviceType\n          }\n        }\n      }\n\n      hasWANActivity\n      internetServicePlan {\n        controllerIP\n        provider {\n          name\n          logoURL\n        }\n      }\n    }\n  }\n':
    types.ControllerVirtualDeviceFragmentFragmentDoc,
  '\n  query ControllerForSecurityApplianceQuery($uuid: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        ...ControllerVirtualDeviceFragment\n      }\n    }\n  }\n':
    types.ControllerForSecurityApplianceQueryDocument,
  '\n  query ControllersForSecurityApplianceQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: CONTROLLER }) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        ...ControllerVirtualDeviceFragment\n        phyInterfaces {\n          ...ControllerPhyInterfaceFields\n        }\n      }\n    }\n  }\n':
    types.ControllersForSecurityApplianceQueryDocument,
  '\n  query DeviceConfigVersion($serialNumber: String!) {\n    latestDeviceConfig(serialNumber: $serialNumber) {\n      version\n    }\n    latestAcknowledgedDeviceConfig(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n    }\n  }\n':
    types.DeviceConfigVersionDocument,
  '\n  query FallbackSwitchHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n    }\n  }\n':
    types.FallbackSwitchHardwareQueryDocument,
  '\n  query PhyInterfacesForVirtualDevice($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      portNumber\n      label\n      isUplink\n    }\n  }\n':
    types.PhyInterfacesForVirtualDeviceDocument,
  '\n  mutation CreateHighAvailabilityConfig(\n    $networkUUID: UUID!\n    $primaryPhyInterfaceUUID: UUID!\n    $backupPhyInterfaceUUID: UUID!\n    $input: CreateHighAvailabilityPairInput!\n  ) {\n    createHighAvailabilityControllersConfiguration(\n      networkUUID: $networkUUID\n      primaryPhyInterfaceUUID: $primaryPhyInterfaceUUID\n      backupPhyInterfaceUUID: $backupPhyInterfaceUUID\n      input: $input\n    ) {\n      advertisementVLAN {\n        networkUUID\n      }\n    }\n  }\n':
    types.CreateHighAvailabilityConfigDocument,
  '\n  mutation DeleteHighAvailabilityConfig($networkUUID: UUID!) {\n    deleteHighAvailabilityControllersConfiguration(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.DeleteHighAvailabilityConfigDocument,
  '\n  query DeviceLastDayCellularUsage($serialNumber: String!) {\n    deviceLastDayCellularUsage(serialNumber: $serialNumber) {\n      uploadBytes\n      downloadBytes\n    }\n  }\n':
    types.DeviceLastDayCellularUsageDocument,
  '\n  query DeviceCellularStatus($serialNumber: String!) {\n    deviceCellularStatus(serialNumber: $serialNumber) {\n      managerModemState\n      observedAt\n    }\n  }\n':
    types.DeviceCellularStatusDocument,
  '\n  query DHCPRulesForNetworkQuery($networkUUID: UUID!) {\n    dhcpRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      vlan {\n        name\n      }\n    }\n  }\n':
    types.DhcpRulesForNetworkQueryDocument,
  '\n  query ControllerMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {\n    controllerPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        portNumber\n        dropsPerSecond\n        txErrPerSecond\n        rxErrPerSecond\n        totalRxBytesPerSecond\n        totalTxBytesPerSecond\n        multicastRxPacketsPerSecond\n        multicastTxPacketsPerSecond\n        broadcastRxPacketsPerSecond\n        broadcastTxPacketsPerSecond\n      }\n    }\n    controllerDNSRequestRates(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        uuid\n      }\n    }\n  }\n':
    types.ControllerMetricsQueryDocument,
  '\n  fragment ControllerPhyInterfaceFields on PhyInterface {\n    UUID\n    virtualDeviceUUID\n\n    virtualDevice {\n      UUID\n      label\n      deviceModel\n    }\n\n    portNumber\n    label\n    description\n    hardwareLabel\n    isEnabled\n    isTrunkPort\n    isUplink\n    isEthernet\n    isSFP\n    hasWANActivity\n    forcedPortSpeedMbps\n    portSpeedMbps\n    maxSpeedMbps\n    ipv4ClientAssignmentProtocol\n    ipv4ClientGateway\n    ipv4ClientPrefixLength\n    ipv4ClientAddresses\n    uplinkPriority\n    nativeVLAN {\n      UUID\n      vlanID\n      name\n    }\n    connectedDevices {\n      macAddress\n      hardwareDevice {\n        serialNumber\n        isConnectedToBackend\n        isActive\n        deviceType\n        deviceModel\n        virtualDevice {\n          __typename\n          deviceType\n          UUID\n          label\n        }\n      }\n    }\n\n    uplinkExternalAddresses\n    hasWANActivity\n    isUplinkActive\n    uplinkVLANID\n    internetServicePlan {\n      UUID\n      controllerIP\n      provider {\n        UUID\n        name\n        logoURL\n      }\n    }\n    uplinkGatewayMACAddress\n  }\n':
    types.ControllerPhyInterfaceFieldsFragmentDoc,
  '\n  query PhyInterfacesForSecurityAppliance($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      ...ControllerPhyInterfaceFields\n    }\n  }\n':
    types.PhyInterfacesForSecurityApplianceDocument,
  '\n  query SecurityAppliancePortStatsQuery($virtualDeviceUUID: UUID!) {\n    controllerPortStats(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n      drops\n      txErr\n      rxErr\n      ipv4\n      ipv6\n      punts\n      totalRxBytes\n      totalTxBytes\n      totalRxPackets\n      totalTxPackets\n      hwUp\n    }\n  }\n':
    types.SecurityAppliancePortStatsQueryDocument,
  '\n  query UptimeStatsForNetwork($networkUUID: UUID!, $lookBack: Int!, $stepSeconds: Int!) {\n    networkUplinkQuality(\n      networkUUID: $networkUUID\n      filter: { durationSeconds: $lookBack, stepSeconds: $stepSeconds }\n    ) {\n      values {\n        phyInterfaceUUID\n        timestamp\n        value\n      }\n    }\n  }\n':
    types.UptimeStatsForNetworkDocument,
  '\n  mutation PortCableTestMutation($serialNumber: String!, $portNumber: Int!) {\n    rpcPortCableTest(serialNumber: $serialNumber, portNumber: $portNumber) {\n      pairA {\n        status\n      }\n      pairB {\n        status\n      }\n      pairC {\n        status\n      }\n      pairD {\n        status\n      }\n    }\n  }\n':
    types.PortCableTestMutationDocument,
  '\n  query PatchPanelLayoutSwitches($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      label\n      company {\n        name\n      }\n      virtualDevices(filter: { deviceType: SWITCH }) {\n        UUID\n        __typename\n        ... on SwitchVirtualDevice {\n          label\n          phyInterfaces {\n            UUID\n            portNumber\n            label\n            description\n            isTrunkPort\n\n            allowedVLANs {\n              name\n              vlanID\n            }\n            nativeVLAN {\n              name\n              vlanID\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.PatchPanelLayoutSwitchesDocument,
  '\n  query SwitchBootHistoryQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n    }\n  }\n':
    types.SwitchBootHistoryQueryDocument,
  '\n  query InsightsQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      serialNumber\n      macAddress\n      deviceType\n      deviceModel\n      tunnelIPAddress\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      ... on SwitchHardwareDevice {\n        stpInfo {\n          isRootBridge\n          rootBridgeMACAddress\n        }\n        ipAddress\n      }\n      virtualDevice {\n        __typename\n        UUID\n        label\n        deviceType\n        deviceModel\n        description\n        networkUUID\n        nosVersion {\n          id\n          version\n          buildName\n        }\n        pendingNosVersion {\n          nosVersion {\n            version\n          }\n          scheduledAt\n        }\n        ... on SwitchVirtualDevice {\n          switchProfile {\n            stpBridgePriority\n          }\n        }\n      }\n    }\n  }\n':
    types.InsightsQueryDocument,
  '\n  query SwitchesRootQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      UUID\n      hardwareDevice {\n        __typename\n        serialNumber\n        ... on SwitchHardwareDevice {\n          stpInfo {\n            isRootBridge\n          }\n        }\n      }\n    }\n  }\n':
    types.SwitchesRootQueryDocument,
  '\n  query SwitchMetricsQuery(\n    $input: MetricsSerialNumberDirectionInput!\n    $filter: MetricsFilterInput!\n  ) {\n    switchThroughputPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n    switchDiscardRatesPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n    switchErrorRatesPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n  }\n':
    types.SwitchMetricsQueryDocument,
  '\n  query SwitchPortNumbers($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n    }\n  }\n':
    types.SwitchPortNumbersDocument,
  '\n  query UnassignedHardwareForNetwork($networkUUID: UUID!) {\n    unassignedHardwareDevicesForNetwork(networkUUID: $networkUUID) {\n      virtualDevice {\n        UUID\n        label\n      }\n      unassignedHardwareConnections {\n        phyInterface {\n          UUID\n          portNumber\n        }\n        unassignedHardwareDevice {\n          serialNumber\n          deviceType\n          deviceModel\n          macAddress\n        }\n      }\n    }\n  }\n':
    types.UnassignedHardwareForNetworkDocument,
  '\n  mutation RebootSwitch($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n':
    types.RebootSwitchDocument,
  '\n  query SwitchQuery($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      networkUUID\n      isConsoleEnabled\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        buildName\n        version\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on SwitchVirtualDevice {\n        switchProfile {\n          UUID\n          stpBridgePriority\n        }\n\n        phyInterfaces {\n          UUID\n          isEthernet\n          isSFP\n          isUplink\n          portSpeedMbps\n          maxSpeedMbps\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        serialNumber\n        deviceType\n        bootHistory(count: 1) {\n          buildName\n        }\n\n        ... on SwitchHardwareDevice {\n          ipAddress\n        }\n      }\n    }\n  }\n':
    types.SwitchQueryDocument,
  '\n  query SwitchesQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      __typename\n      UUID\n      uptime\n      label\n      description\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on SwitchVirtualDevice {\n        switchProfile {\n          stpBridgePriority\n        }\n\n        phyInterfaces {\n          UUID\n          isEthernet\n          isSFP\n          isUplink\n          portSpeedMbps\n          maxSpeedMbps\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n\n        ... on SwitchHardwareDevice {\n          stpInfo {\n            isRootBridge\n            rootBridgePortNumber\n          }\n          ipAddress\n          isInCurrentControllerMACTable\n          uptime\n        }\n      }\n    }\n  }\n':
    types.SwitchesQueryDocument,
  '\n  query PortsQuery($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      virtualDeviceUUID\n      description\n      forcedPortSpeedMbps\n      isBoundToAllVLANs\n      isEnabled\n      isPOEEnabled\n      isSTPEdgePortEnabled\n      isSTPEnabled\n      isTrunkPort\n      isUplink\n      isLoopbackDetected\n      isBlockedDueToFlaps\n      isSTPForwarding\n      isIngressFilteringEnabled\n      frameAcceptTypeFilter\n      label\n      portNumber\n      isEthernet\n      isSFP\n      powerDraw\n      portSpeedMbps\n      maxSpeedMbps\n      stpPortRole\n      isStormControlEnabled\n      stormControlBroadcastTrafficPercent\n      stormControlUnknownMulticastTrafficPercent\n      stormControlUnknownUnicastTrafficPercent\n      allowedVLANs {\n        UUID\n        name\n        vlanID\n      }\n      nativeVLAN {\n        UUID\n        name\n        vlanID\n      }\n      throughputLastDay {\n        direction\n        value\n      }\n      connectedDevices {\n        macAddress\n        hardwareDevice {\n          serialNumber\n          isConnectedToBackend\n          isActive\n          deviceType\n          virtualDevice {\n            __typename\n            deviceType\n            UUID\n            label\n          }\n        }\n      }\n      sfpModuleInfo {\n        vendor\n        serialNumber\n        partName\n        moduleSpeed\n        moduleType\n      }\n    }\n  }\n':
    types.PortsQueryDocument,
  '\n  query MACTableQuery($virtualDeviceUUID: UUID!) {\n    switchMACTable(virtualDeviceUUID: $virtualDeviceUUID) {\n      updatedAt\n      macAddress\n      port\n      vlan {\n        UUID\n        name\n        vlanID\n      }\n    }\n  }\n':
    types.MacTableQueryDocument,
  '\n  mutation UpdateSwitchProfile($uuid: UUID!, $input: UpdateSwitchProfileInput!) {\n    updateSwitchProfile(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateSwitchProfileDocument,
  '\n  query FindMACOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {\n    findSwitchesForClientMAC(networkUUID: $networkUUID, macAddress: $mac) {\n      virtualDevice {\n        UUID\n        label\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n      phyInterface {\n        UUID\n        portNumber\n      }\n    }\n  }\n':
    types.FindMacOnSwitchQueryDocument,
  '\n  query FindLLDPEntryOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {\n    findSwitchLLDPEntryForMAC(networkUUID: $networkUUID, macAddress: $mac) {\n      virtualDevice {\n        UUID\n        label\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n      phyInterface {\n        UUID\n        portNumber\n      }\n    }\n  }\n':
    types.FindLldpEntryOnSwitchQueryDocument,
  '\n  mutation PortCycleMutation(\n    $portNumber: Int!\n    $serialNumber: String!\n    $poeCycle: Boolean!\n    $portCycle: Boolean!\n  ) {\n    rpcSwitchPortCycle(\n      input: {\n        portNumber: $portNumber\n        serialNumber: $serialNumber\n        poeCycle: $poeCycle\n        portCycle: $portCycle\n      }\n    )\n  }\n':
    types.PortCycleMutationDocument,
  '\n  query SwitchPortMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {\n    switchPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        portNumber\n        dropsPerSecond\n        txErrPerSecond\n        rxErrPerSecond\n        totalRxBytesPerSecond\n        totalTxBytesPerSecond\n        multicastRxPacketsPerSecond\n        multicastTxPacketsPerSecond\n        broadcastRxPacketsPerSecond\n        broadcastTxPacketsPerSecond\n      }\n    }\n  }\n':
    types.SwitchPortMetricsQueryDocument,
  '\n  query switchPortStatsQuery($virtualDeviceUUID: UUID!) {\n    switchPortStats(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n      totalRxBytes\n      totalTxBytes\n    }\n  }\n':
    types.SwitchPortStatsQueryDocument,
  '\n  query HubNetworksOverview($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      slug\n      label\n      UUID\n      isActive\n      isTemplate\n      mailingAddress {\n        line1\n        line2\n        city\n        subdivisionCode\n        postalCode\n        countryISO2\n        timezone\n        coordinates {\n          latitude\n          longitude\n        }\n      }\n      virtualDevices {\n        UUID\n        deviceType\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n    }\n  }\n':
    types.HubNetworksOverviewDocument,
  '\n  query UptimeStatsForNetworks($networkUUIDs: [UUID!]!, $lookBack: Int!, $stepSeconds: Int!) {\n    networksUplinkQualities(\n      networkUUIDs: $networkUUIDs\n      filter: { durationSeconds: $lookBack, stepSeconds: $stepSeconds }\n    ) {\n      values {\n        phyInterfaceUUID\n        timestamp\n        value\n      }\n    }\n  }\n':
    types.UptimeStatsForNetworksDocument,
  '\n  query APUptime($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    apUptime(networkUUID: $networkUUID, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        apName\n      }\n    }\n  }\n':
    types.ApUptimeDocument,
  '\n  query ClientMetrics($serialNumber: String!, $filter: MetricsFilterInput!) {\n    clientMetrics(serialNumber: $serialNumber, filter: $filter) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        tx {\n          minValue\n          maxValue\n        }\n        rx {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        macAddress\n        signal\n        noise\n        txRate\n        rxRate\n      }\n    }\n  }\n':
    types.ClientMetricsDocument,
  '\n  query activeClients(\n    $networkUUID: UUID!\n    $filter: MetricsFilterInput!\n    $input: ActiveClientsInput\n  ) {\n    activeClients(networkUUID: $networkUUID, filter: $filter, input: $input) {\n      values {\n        timestamp\n        value\n      }\n    }\n  }\n':
    types.ActiveClientsDocument,
  '\n  query networkUplinkQuality($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    networkUplinkQuality(networkUUID: $networkUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        phyInterfaceUUID\n      }\n    }\n  }\n':
    types.NetworkUplinkQualityDocument,
  '\n  query networkUplinkThroughput($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    networkUplinkThroughput(networkUUID: $networkUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        direction\n        phyInterfaceUUID\n      }\n    }\n  }\n':
    types.NetworkUplinkThroughputDocument,
  '\n  query APChannelUtilization($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    band2G: apChannelUtilization(networkUUID: $networkUUID, filter: $filter, band: BAND_2_4G) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on APChannelUtilizationMetricsValue {\n          apName\n        }\n      }\n    }\n    band5G: apChannelUtilization(networkUUID: $networkUUID, filter: $filter, band: BAND_5G) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on APChannelUtilizationMetricsValue {\n          apName\n        }\n      }\n    }\n  }\n':
    types.ApChannelUtilizationDocument,
  '\n  query NetwoorksForNosVersionQuery {\n    networks(filter: { active: { eq: true } }) {\n      pinnedNOSVersionID\n    }\n  }\n':
    types.NetwoorksForNosVersionQueryDocument,
  '\n  query NosVersionsQuery {\n    nosVersions {\n      version\n      id\n      mc01Build\n      mw07Build\n      ms10Build\n      mp01Build\n      isDeprecated\n      isDefault\n      updatedAt\n      major\n      minor\n      patch\n    }\n  }\n':
    types.NosVersionsQueryDocument,
  '\n  query NosVersionByIDQuery($id: Int!) {\n    nosVersions(id: $id) {\n      id\n      version\n      isDeprecated\n      isGA\n      isDefault\n      isLocked\n      releaseNotes\n      major\n      minor\n      patch\n      updatedAt\n      # cos\n      mc01Build\n      # wos\n      mw07Build\n      mw08Build\n      mw09Build\n      # sos\n      ms10Build\n      ms11Build\n      # pos\n      mp01Build\n    }\n  }\n':
    types.NosVersionByIdQueryDocument,
  '\n  mutation UpdateNOSVersionMutation($id: Int!, $input: NosVersionInput!) {\n    updateNosVersion(id: $id, input: $input) {\n      id\n    }\n  }\n':
    types.UpdateNosVersionMutationDocument,
  '\n  mutation CreateNOSVersionMutation($input: NosVersionInput!) {\n    createNosVersion(input: $input) {\n      id\n    }\n  }\n':
    types.CreateNosVersionMutationDocument,
  '\n  query NosFeaturesQuery {\n    nosFeatures {\n      key\n      uuid\n      name\n      description\n      minMajorVersion\n      minMinorVersion\n      minPatchVersion\n      maxMajorVersion\n      maxMinorVersion\n      maxPatchVersion\n      featureFlag\n    }\n  }\n':
    types.NosFeaturesQueryDocument,
  '\n  query NosFeatureQuery($uuid: UUID!) {\n    nosFeature(UUID: $uuid) {\n      uuid\n      key\n      name\n      description\n      minMajorVersion\n      minMinorVersion\n      minPatchVersion\n      maxMajorVersion\n      maxMinorVersion\n      maxPatchVersion\n      featureFlag\n    }\n  }\n':
    types.NosFeatureQueryDocument,
  '\n  mutation UpdateNosFeatureMutation($uuid: UUID!, $input: UpdateNosFeatureInput!) {\n    updateNosFeature(UUID: $uuid, input: $input) {\n      uuid\n    }\n  }\n':
    types.UpdateNosFeatureMutationDocument,
  '\n  mutation CreateNosFeatureMutation($input: CreateNosFeatureInput!) {\n    createNosFeature(input: $input) {\n      uuid\n    }\n  }\n':
    types.CreateNosFeatureMutationDocument,
  '\n  query NetworkForNavigation($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      slug\n      label\n\n      companySlug\n    }\n  }\n':
    types.NetworkForNavigationDocument,
  '\n  query HardwareDeviceForNavigation($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        description\n\n        network {\n          UUID\n          slug\n\n          companySlug\n        }\n      }\n    }\n  }\n':
    types.HardwareDeviceForNavigationDocument,
  '\n  fragment CaptivePortalBody on CaptivePortal {\n    UUID\n    callToAction\n    logoImageBase64 {\n      data\n      imageType\n    }\n    logoImageS3Key\n    name\n    redirectURL\n    allowedHosts\n    externalPortalURL\n    authLifetimeSec\n    termsAndConditions\n    vlans {\n      UUID\n      name\n    }\n    displaySettings {\n      backgroundColor\n      fontColor\n      hidePoweredByMeter\n    }\n    webhookKey\n    isEnabled\n  }\n':
    types.CaptivePortalBodyFragmentDoc,
  '\n  query CaptivePortalForNetworkQuery($networkUUID: UUID!) {\n    captivePortalForNetwork(networkUUID: $networkUUID) {\n      UUID\n      ...CaptivePortalBody\n    }\n  }\n':
    types.CaptivePortalForNetworkQueryDocument,
  '\n  mutation UpdateCaptivePortal($uuid: UUID!, $input: CaptivePortalSettingsInput!) {\n    updateCaptivePortal(input: $input, uuid: $uuid) {\n      UUID\n      ...CaptivePortalBody\n    }\n  }\n':
    types.UpdateCaptivePortalDocument,
  '\n  mutation DeleteCaptivePortal($uuid: UUID!) {\n    deleteCaptivePortal(uuid: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteCaptivePortalDocument,
  '\n  mutation CreateCaptivePortal($networkUUID: UUID!, $input: CaptivePortalSettingsInput!) {\n    createCaptivePortal(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateCaptivePortalDocument,
  '\n  query capoActivityQuery($networkUUID: UUID!, $numHoursLookback: Int!) {\n    captivePortalAuthorizedClients(networkUUID: $networkUUID, numHoursLookback: $numHoursLookback) {\n      timestamp\n      isAuthorized\n      ipAddress\n      macAddress\n      rawUserAgent\n    }\n  }\n':
    types.CapoActivityQueryDocument,
  '\n  mutation DeleteInternetServicePlanMutation($internetServicePlanUUID: UUID!) {\n    deleteInternetServicePlan(internetServicePlanUUID: $internetServicePlanUUID)\n  }\n':
    types.DeleteInternetServicePlanMutationDocument,
  '\n  mutation CreateInternetServicePlanMutation(\n    $companySlug: String!\n    $networkUUID: UUID!\n    $input: CreateInternetServicePlanInput!\n  ) {\n    createInternetServicePlan(companySlug: $companySlug, networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateInternetServicePlanMutationDocument,
  '\n  mutation UpdateInternetServicePlanMutation(\n    $internetServicePlanUUID: UUID!\n    $input: UpdateInternetServicePlanInput!\n  ) {\n    updateInternetServicePlan(internetServicePlanUUID: $internetServicePlanUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateInternetServicePlanMutationDocument,
  '\n  mutation CopyISPsToInternetServicePlans($networkUUID: UUID!) {\n    copyInternetServicePlanIPsFromConfigToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyIsPsToInternetServicePlansDocument,
  '\n  query ProvidersQuery($companySlug: String!) {\n    internetServicePlanProviders(companySlug: $companySlug) {\n      UUID\n      logoURL\n      name\n    }\n  }\n':
    types.ProvidersQueryDocument,
  '\n  query ListISPsQuery($companySlug: String!, $networkUUID: UUID) {\n    internetServicePlans(companySlug: $companySlug, networkUUID: $networkUUID) {\n      UUID\n\n      address {\n        address1\n        address2\n        city\n        state\n        postalCode\n        country\n      }\n\n      connectionProduct\n      connectionStatus\n\n      provider {\n        UUID\n        logoURL\n        name\n      }\n\n      controllerIP\n      controllerName\n      firstUsableIP\n      gatewayAddr\n      hasStaticIP\n      lastUsableIP\n      networkUUID\n      staticIPRange\n    }\n  }\n':
    types.ListIsPsQueryDocument,
  '\n  mutation MoveInternetServicePlanMutation($ispUUID: UUID!, $networkUUID: UUID!) {\n    moveInternetServicePlanToNetwork(internetServicePlanUUID: $ispUUID, networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.MoveInternetServicePlanMutationDocument,
  '\n  query VLANsQuery($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n  }\n':
    types.VlaNsQueryDocument,
  '\n  query VLANQuery($uuid: UUID!) {\n    vlan(UUID: $uuid) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      dhcpRule {\n        UUID\n        isIPv6\n        startIPAddress\n        endIPAddress\n        gatewayIPAddress\n        gatewayPrefixLength\n        leaseDurationSeconds\n        dnsUseGatewayProxy\n        dnsUpstreamServers\n        dnsSearchDomains\n        dnsCacheIsEnabled\n        dnsCacheSize\n        dnsCacheMaxTTL\n\n        options {\n          UUID\n          code\n          data\n          description\n          createdAt\n        }\n\n        reservedRanges {\n          UUID\n          startIPAddress\n          endIPAddress\n          createdAt\n        }\n\n        staticMappings {\n          UUID\n          name\n          macAddress\n          ipAddress\n          hostname\n          createdAt\n        }\n\n        dnsHostMappings {\n          UUID\n          isEnabled\n          overrideDomain\n          destinationIPAddress\n          destinationDomain\n          createdAt\n        }\n\n        applicationDNSFirewallRules {\n          UUID\n        }\n      }\n    }\n  }\n':
    types.VlanQueryDocument,
  '\n  mutation CreateVLANMutation($networkUUID: UUID!, $input: CreateVLANInput!) {\n    createVLAN(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateVlanMutationDocument,
  '\n  mutation UpdateVLANMutation($uuid: UUID!, $input: UpdateVLANInput!) {\n    updateVLAN(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateVlanMutationDocument,
  '\n  mutation DeleteVLANMutation($uuid: UUID!) {\n    deleteVLAN(UUID: $uuid)\n  }\n':
    types.DeleteVlanMutationDocument,
  '\n  mutation CreateDHCPRule($vlanUUID: UUID!, $input: CreateDHCPRuleInput!) {\n    createDHCPRule(vlanUUID: $vlanUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateDhcpRuleDocument,
  '\n  mutation UpdateDHCPRule($uuid: UUID!, $input: UpdateDHCPRuleInput!) {\n    updateDHCPRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateDhcpRuleDocument,
  '\n  mutation DeleteDHCPRule($uuid: UUID!) {\n    deleteDHCPRule(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteDhcpRuleDocument,
  '\n  mutation CreateDHCPOption($ruleUUID: UUID!, $input: CreateDHCPOptionInput!) {\n    createDHCPOption(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateDhcpOptionDocument,
  '\n  mutation UpdateDHCPOption($uuid: UUID!, $input: UpdateDHCPOptionInput!) {\n    updateDHCPOption(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateDhcpOptionDocument,
  '\n  mutation DeleteDHCPOption($uuid: UUID!) {\n    deleteDHCPOption(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteDhcpOptionDocument,
  '\n  mutation CreateDHCPReservedRange($ruleUUID: UUID!, $input: CreateDHCPReservedRangeInput!) {\n    createDHCPReservedRange(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateDhcpReservedRangeDocument,
  '\n  mutation UpdateDHCPReservedRange($uuid: UUID!, $input: UpdateDHCPReservedRangeInput!) {\n    updateDHCPReservedRange(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateDhcpReservedRangeDocument,
  '\n  mutation DeleteDHCPReservedRange($uuid: UUID!) {\n    deleteDHCPReservedRange(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteDhcpReservedRangeDocument,
  '\n  mutation CreateDHCPStaticMapping($ruleUUID: UUID!, $input: CreateDHCPStaticMappingInput!) {\n    createDHCPStaticMapping(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateDhcpStaticMappingDocument,
  '\n  mutation UpdateDHCPStaticMapping($uuid: UUID!, $input: UpdateDHCPStaticMappingInput!) {\n    updateDHCPStaticMapping(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateDhcpStaticMappingDocument,
  '\n  mutation DeleteDHCPStaticMapping($uuid: UUID!) {\n    deleteDHCPStaticMapping(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteDhcpStaticMappingDocument,
  '\n  mutation CreateDNSHostMapping($ruleUUID: UUID!, $input: CreateDNSHostMappingInput!) {\n    createDNSHostMapping(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateDnsHostMappingDocument,
  '\n  mutation UpdateDNSHostMapping($uuid: UUID!, $input: UpdateDNSHostMappingInput!) {\n    updateDNSHostMapping(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateDnsHostMappingDocument,
  '\n  mutation DeleteDNSHostMapping($uuid: UUID!) {\n    deleteDNSHostMapping(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteDnsHostMappingDocument,
  '\n  mutation CopyVLANsMutation($networkUUID: UUID!) {\n    copyVLANsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyVlaNsMutationDocument,
  '\n  mutation CopyDNSHostMappingsFromConfig1($networkUUID: UUID!) {\n    copyDNSHostMappingsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyDnsHostMappingsFromConfig1Document,
  '\n  fragment NosVersionFields on NosVersion {\n    id\n    version\n    major\n    minor\n    patch\n    isDefault\n  }\n':
    types.NosVersionFieldsFragmentDoc,
  '\n  query NosVersionQuery($id: Int!) {\n    nosVersions(id: $id) {\n      ...NosVersionFields\n    }\n  }\n':
    types.NosVersionQueryDocument,
  '\n  query NosVersionsShortQuery {\n    nosVersions {\n      ...NosVersionFields\n    }\n  }\n':
    types.NosVersionsShortQueryDocument,
  '\n  mutation SetNetworkNosMutation($networkUUID: UUID!, $input: SetNetworkNosVersionInput!) {\n    setNetworkNosVersion(networkUUID: $networkUUID, input: $input)\n  }\n':
    types.SetNetworkNosMutationDocument,
  '\n  query NotificationPreferencesQuery($companySlug: String!, $controllerName: String!) {\n    notificationPreferences(input: { companySlug: $companySlug, controllerName: $controllerName }) {\n      companyPreferences {\n        companySID\n        emailAddresses\n        additionalSlackChannels\n        additionalEventTypes\n      }\n\n      networkPreferences {\n        networkUUID\n        emailAddresses\n        additionalSlackChannels\n        additionalEventTypes\n      }\n    }\n  }\n':
    types.NotificationPreferencesQueryDocument,
  '\n  mutation UpsertCompanyNotificationPreferenceMutation(\n    $companySlug: String!\n    $input: NotificationPreferenceInput\n  ) {\n    upsertCompanyNotificationPreference(companySlug: $companySlug, input: $input) {\n      uuid\n    }\n  }\n':
    types.UpsertCompanyNotificationPreferenceMutationDocument,
  '\n  mutation UpsertControllerNotificationPreferenceMutation(\n    $controllerName: String!\n    $input: NotificationPreferenceInput\n  ) {\n    upsertNetworkNotificationPreference(controllerName: $controllerName, input: $input) {\n      uuid\n    }\n  }\n':
    types.UpsertControllerNotificationPreferenceMutationDocument,
  '\n  fragment InternalNoteFields on InternalNote {\n    UUID\n    createdAt\n    createdByUser {\n      email\n      firstName\n      lastName\n    }\n    updatedAt\n    updatedByUser {\n      email\n      firstName\n      lastName\n    }\n    isImportant\n    note\n    photoURLs\n    photoS3Keys\n  }\n':
    types.InternalNoteFieldsFragmentDoc,
  '\n  query InternalNotesForNetwork($networkUUID: UUID!) {\n    internalNotesForNetwork(networkUUID: $networkUUID) {\n      ...InternalNoteFields\n    }\n  }\n':
    types.InternalNotesForNetworkDocument,
  '\n  query InternalNote($UUID: UUID!) {\n    internalNote(UUID: $UUID) {\n      ...InternalNoteFields\n    }\n  }\n':
    types.InternalNoteDocument,
  '\n  mutation CreateInternalNote($networkUUID: UUID!, $input: CreateInternalNoteInput!) {\n    createInternalNote(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateInternalNoteDocument,
  '\n  mutation UpdateInternalNote($UUID: UUID!, $input: UpdateInternalNoteInput!) {\n    updateInternalNote(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateInternalNoteDocument,
  '\n  mutation DeleteInternalNote($UUID: UUID!) {\n    deleteInternalNote(UUID: $UUID) {\n      UUID\n    }\n  }\n':
    types.DeleteInternalNoteDocument,
  '\n  mutation RPCPingMutation($input: RPCPingInput!) {\n    rpcPing(input: $input) {\n      successRate\n      roundTripTimeMs\n    }\n  }\n':
    types.RpcPingMutationDocument,
  '\n  query VlansForPing($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n    }\n  }\n':
    types.VlansForPingDocument,
  '\n  mutation RPCPing2Mutation($input: RPCPingInput!) {\n    rpcPing(input: $input) {\n      successRate\n      roundTripTimeMs\n    }\n  }\n':
    types.RpcPing2MutationDocument,
  '\n  query RateLimitRule($uuid: UUID!) {\n    rateLimitRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      rateLimitKbpsDownload\n      rateLimitKbpsUpload\n      isEnabled\n      protocol\n      srcMac\n      srcPrefix\n      srcPortRange {\n        lower\n        upper\n      }\n      dstMac\n      dstPrefix\n      dstPortRange {\n        lower\n        upper\n      }\n      isPerSource\n      vlanBindings {\n        __typename\n        UUID\n        name\n      }\n      phyInterfaceBindings {\n        __typename\n        UUID\n        label\n        portNumber\n        virtualDevice {\n          __typename\n          UUID\n          label\n          deviceModel\n        }\n      }\n    }\n  }\n':
    types.RateLimitRuleDocument,
  '\n  mutation CreateRateLimitRule($networkUUID: UUID!, $input: CreateRateLimitRuleInput!) {\n    createRateLimitRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateRateLimitRuleDocument,
  '\n  mutation UpdateRateLimitRule($uuid: UUID!, $input: UpdateRateLimitRuleInput!) {\n    updateRateLimitRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateRateLimitRuleDocument,
  '\n  mutation DeleteRateLimitRule($uuid: UUID!) {\n    deleteRateLimitRule(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteRateLimitRuleDocument,
  '\n  query RateLimitRulesForNetwork($networkUUID: UUID!) {\n    rateLimitRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      rateLimitKbpsDownload\n      rateLimitKbpsUpload\n      isEnabled\n      isPerSource\n      protocol\n      srcMac\n      srcPrefix\n      srcPortRange {\n        lower\n        upper\n      }\n      dstMac\n      dstPrefix\n      dstPortRange {\n        lower\n        upper\n      }\n      vlanBindings {\n        __typename\n        UUID\n        name\n      }\n      phyInterfaceBindings {\n        __typename\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n':
    types.RateLimitRulesForNetworkDocument,
  '\n  query RoutesForVirtualDevice($virtualDeviceUUID: UUID!) {\n    networkRoutesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      destination\n      gateway\n      attachment {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        phyInterface {\n          __typename\n          UUID\n          portNumber\n          label\n          hardwareLabel\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            deviceModel\n          }\n          internetServicePlan {\n            provider {\n              name\n            }\n          }\n        }\n        autoVPNGroup {\n          __typename\n          UUID\n          name\n        }\n        ipSecTunnel {\n          __typename\n          UUID\n          name\n        }\n        clientVPNServer {\n          __typename\n          UUID\n          endpoint\n        }\n      }\n    }\n  }\n':
    types.RoutesForVirtualDeviceDocument,
  '\n  fragment PolicyRoutingRulesFields on PolicyRoutingRule {\n    UUID\n    name\n    isEnabled\n    srcPrefix\n    srcVLAN {\n      __typename\n      UUID\n      name\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n    dstPrefix\n    srcPortRanges {\n      lower\n      upper\n    }\n    dstPortRanges {\n      lower\n      upper\n    }\n    protocols\n    bindings {\n      UUID\n      phyInterface {\n        __typename\n        ...PhyInterfaceLabelFields\n        isUplinkActive\n        hasWANActivity\n        isEnabled\n      }\n      metric\n    }\n  }\n':
    types.PolicyRoutingRulesFieldsFragmentDoc,
  '\n  query PolicyRoutingRulesForNetwork($networkUUID: UUID!) {\n    policyRoutingRulesForNetwork(networkUUID: $networkUUID) {\n      ...PolicyRoutingRulesFields\n    }\n  }\n':
    types.PolicyRoutingRulesForNetworkDocument,
  '\n  query PolicyRoutingRule($uuid: UUID!) {\n    policyRoutingRule(UUID: $uuid) {\n      ...PolicyRoutingRulesFields\n    }\n  }\n':
    types.PolicyRoutingRuleDocument,
  '\n  mutation CreatePolicyRoutingRule($networkUUID: UUID!, $input: CreatePolicyRoutingRuleInput!) {\n    createPolicyRoutingRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreatePolicyRoutingRuleDocument,
  '\n  mutation UpdatePolicyRoutingRule($uuid: UUID!, $input: UpdatePolicyRoutingRuleInput!) {\n    updatePolicyRoutingRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdatePolicyRoutingRuleDocument,
  '\n  mutation DeletePolicyRoutingRule($uuid: UUID!) {\n    deletePolicyRoutingRule(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeletePolicyRoutingRuleDocument,
  '\n  mutation UpdatePolicyRoutingRuleBindings(\n    $input: BindPolicyRoutingRulesInput!\n    $orderedRuleUUIDs: [UUID!]!\n  ) {\n    bindPolicyRoutingRules(input: $input, orderedRuleUUIDs: $orderedRuleUUIDs) {\n      UUID\n      metric\n    }\n  }\n':
    types.UpdatePolicyRoutingRuleBindingsDocument,
  '\n  fragment StaticRouteFields on StaticRoute {\n    UUID\n    name\n    isEnabled\n    dstPrefix\n\n    gatewayIP\n    gatewayDHCPStaticMapping {\n      UUID\n      name\n      macAddress\n      ipAddress\n      hostname\n    }\n  }\n':
    types.StaticRouteFieldsFragmentDoc,
  '\n  query StaticRoutesForNetwork($networkUUID: UUID!) {\n    staticRoutesForNetwork(networkUUID: $networkUUID) {\n      ...StaticRouteFields\n    }\n  }\n':
    types.StaticRoutesForNetworkDocument,
  '\n  query StaticRoute($uuid: UUID!) {\n    staticRoute(UUID: $uuid) {\n      ...StaticRouteFields\n    }\n  }\n':
    types.StaticRouteDocument,
  '\n  mutation CreateStaticRoute($networkUUID: UUID!, $input: CreateStaticRouteInput!) {\n    createStaticRoute(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateStaticRouteDocument,
  '\n  mutation UpdateStaticRoute($uuid: UUID!, $input: UpdateStaticRouteInput!) {\n    updateStaticRoute(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateStaticRouteDocument,
  '\n  mutation DeleteStaticRoute($uuid: UUID!) {\n    deleteStaticRoute(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteStaticRouteDocument,
  '\n  query VLANsStaticMappingsRules($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      dhcpRule {\n        UUID\n        staticMappings {\n          UUID\n          name\n          macAddress\n          ipAddress\n          hostname\n        }\n      }\n    }\n  }\n':
    types.VlaNsStaticMappingsRulesDocument,
  '\n  mutation updateNotificationSettingMutation(\n    $UUID: UUID!\n    $input: UpdateNotificationSettingInput!\n  ) {\n    updateNotificationSetting(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateNotificationSettingMutationDocument,
  '\n  query NotificationSettingsQuery($networkUUID: UUID!) {\n    notificationSettingsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      alertReceiverUUID\n      isEnabled\n      arguments {\n        parameterUUID\n        value\n      }\n      notificationDefinition {\n        name\n        displayName\n        description\n      }\n    }\n  }\n':
    types.NotificationSettingsQueryDocument,
  '\n  mutation CreateNotificationSettingMutation(\n    $networkUUID: UUID!\n    $input: CreateNotificationSettingInput!\n  ) {\n    createNotificationSetting(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateNotificationSettingMutationDocument,
  '\n  query NotificationDefinitionsQuery {\n    alertDefinitions {\n      name\n      displayName\n      description\n      parameters {\n        uuid\n        name\n        description\n        type\n        required\n      }\n    }\n  }\n':
    types.NotificationDefinitionsQueryDocument,
  '\n  mutation deleteNotificationSettingMutation($UUID: UUID!) {\n    deleteNotificationSetting(UUID: $UUID) {\n      UUID\n    }\n  }\n':
    types.DeleteNotificationSettingMutationDocument,
  '\n  mutation updateServiceDiscoveryForNetwork($UUID: UUID!, $input: UpdateNetworkInput!) {\n    updateNetwork(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateServiceDiscoveryForNetworkDocument,
  '\n  query UpdateNetworkNetwork($uuid: UUID!) {\n    network(UUID: $uuid) {\n      UUID\n      slug\n      label\n      isActive\n      isCustomer\n      isTemplate\n      isConfig1WosUpgradesEnabled\n      isUpgradeSensitive\n      multiWANAlgorithm\n      onboarding {\n        UUID\n        jobData {\n          id\n          jobID\n        }\n      }\n      mailingAddress {\n        UUID\n        line1\n        line2\n        city\n        subdivisionCode\n        postalCode\n        countryISO2\n        timezone\n      }\n    }\n  }\n':
    types.UpdateNetworkNetworkDocument,
  '\n  mutation CreateNetwork($input: CreateNetworkInput!) {\n    createNetwork(input: $input) {\n      UUID\n      slug\n    }\n  }\n':
    types.CreateNetworkDocument,
  '\n  mutation CreateNetworkFromCopy($sourceNetworkUUID: UUID!, $input: CreateNetworkCopyInput!) {\n    createNetworkFromCopy(sourceNetworkUUID: $sourceNetworkUUID, input: $input) {\n      UUID\n      slug\n    }\n  }\n':
    types.CreateNetworkFromCopyDocument,
  '\n  mutation UpdateNetwork($uuid: UUID!, $input: UpdateNetworkInput!) {\n    updateNetwork(UUID: $uuid, input: $input) {\n      UUID\n      slug\n    }\n  }\n':
    types.UpdateNetworkDocument,
  '\n  mutation DeleteNetwork($uuid: UUID!) {\n    deleteNetwork(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteNetworkDocument,
  '\n  query JobTrackers {\n    jobTrackers {\n      id\n      jobID\n      totalSqFt\n      fullAddress\n      locationCode\n    }\n  }\n':
    types.JobTrackersDocument,
  '\n  mutation CreateNetworkOnboarding($networkUUID: UUID!, $jobTrackerID: String!) {\n    createNetworkOnboarding(networkUUID: $networkUUID, jobTrackerID: $jobTrackerID) {\n      UUID\n    }\n  }\n':
    types.CreateNetworkOnboardingDocument,
  '\n  query MaintenanceWindowForNetwork($networkUUID: UUID!) {\n    maintenanceWindowForNetwork(networkUUID: $networkUUID) {\n      recurrence\n      timezone\n      isOptedOut\n      startHourOfDayLocal\n      endHourOfDayLocal\n      startDayOfWeekLocal\n      endDayOfWeekLocal\n    }\n  }\n':
    types.MaintenanceWindowForNetworkDocument,
  '\n  mutation UpdateMaintenanceWindow($networkUUID: UUID!, $input: UpdateMaintenanceWindowInput!) {\n    updateMaintenanceWindowForNetwork(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateMaintenanceWindowDocument,
  '\n  mutation CopyMultiWANFromConfig1($networkUUID: UUID!) {\n    copyNetworkMultiWANAlgorithmFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyMultiWanFromConfig1Document,
  '\n  mutation CopyFirewallRulesFromConfig1($networkUUID: UUID!) {\n    copyFirewallRulesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyFirewallRulesFromConfig1Document,
  '\n  mutation CopyPortForwardingRulesFromConfig1($networkUUID: UUID!) {\n    copyPortForwardingRulesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyPortForwardingRulesFromConfig1Document,
  '\n  mutation CopyUplinkPhyInterfacesFromConfig1($networkUUID: UUID!) {\n    copyUplinkPhyInterfacesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyUplinkPhyInterfacesFromConfig1Document,
  '\n  mutation updateAlertReceiverWithTargets(\n    $alertReceiverUUID: UUID!\n    $input: UpdateAlertReceiverWithTargetsInput!\n  ) {\n    updateAlertReceiverWithTargets(alertReceiverUUID: $alertReceiverUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateAlertReceiverWithTargetsDocument,
  '\n  mutation CreateAlertReceiverWithTargetsMutation(\n    $companyUUID: UUID!\n    $input: CreateAlertReceiverWithTargetsInput!\n  ) {\n    createAlertReceiverWithTargets(companyUUID: $companyUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateAlertReceiverWithTargetsMutationDocument,
  '\n  mutation UpdateWebhookMutation($UUID: UUID!, $input: UpdateAlertTargetWebhookInput!) {\n    updateAlertTargetWebhook(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateWebhookMutationDocument,
  '\n  mutation createAlertTargetWebhook($companyUUID: UUID!, $input: CreateAlertTargetWebhookInput!) {\n    createAlertTargetWebhook(companyUUID: $companyUUID, input: $input) {\n      UUID\n      companyUUID\n      signingSecret\n      type\n      url\n      label\n    }\n  }\n':
    types.CreateAlertTargetWebhookDocument,
  '\n  mutation DeleteAlertReceiverMutation($alertReceiverUUID: UUID!) {\n    deleteAlertReceiverWithTargets(alertReceiverUUID: $alertReceiverUUID) {\n      UUID\n    }\n  }\n':
    types.DeleteAlertReceiverMutationDocument,
  '\n  query AlertTargetsQuery($companyUUID: UUID!) {\n    alertTargets(companyUUID: $companyUUID) {\n      emails {\n        UUID\n        emailAddress\n        type\n      }\n      webhooks {\n        UUID\n        url\n        label\n        type\n        signingSecret\n      }\n    }\n  }\n':
    types.AlertTargetsQueryDocument,
  '\n  query alertTargetsWebhookQuery($companyUUID: UUID!) {\n    alertTargetsWebhook(companyUUID: $companyUUID) {\n      UUID\n      signingSecret\n      type\n      url\n      label\n    }\n  }\n':
    types.AlertTargetsWebhookQueryDocument,
  '\n  query AlertReceiversQuery($companyUUID: UUID!) {\n    alertReceiversForCompany(companyUUID: $companyUUID) {\n      __typename\n      UUID\n      companyUUID\n      label\n      targets {\n        __typename\n        UUID\n        type\n        ... on AlertTargetEmail {\n          emailAddress\n        }\n        ... on AlertTargetWebhook {\n          url\n          label\n        }\n      }\n    }\n  }\n':
    types.AlertReceiversQueryDocument,
  '\n  mutation DeleteAlertTargetWebhookMutation($UUID: UUID!) {\n    deleteAlertTargetWebhook(UUID: $UUID) {\n      UUID\n    }\n  }\n':
    types.DeleteAlertTargetWebhookMutationDocument,
  '\n  mutation WANSpeedTestMutation($input: RPCWANSpeedtestInput!) {\n    rpcWANSpeedtest(input: $input) {\n      downloadSpeedMbps\n      uploadSpeedMbps\n      latencyNs\n      jitterNs\n      targetName\n      targetHost\n      distance\n    }\n  }\n':
    types.WanSpeedTestMutationDocument,
  '\n  fragment CompanyUserBody on CompanyUser {\n    roles {\n      companySlug\n      name\n      networkUUID\n    }\n    createdAt\n    deletedAt\n    UUID\n    companyMembershipRole\n    user {\n      email\n      firstName\n      lastName\n    }\n  }\n':
    types.CompanyUserBodyFragmentDoc,
  '\n  query GetCompanyUsers($companySlug: String!, $input: GetCompanyUserInput) {\n    companyUsers(companySlug: $companySlug, input: $input) {\n      ...CompanyUserBody\n    }\n  }\n':
    types.GetCompanyUsersDocument,
  '\n  query GetCompanyUser($uuid: UUID!, $input: GetCompanyUserInput) {\n    companyUser(companyUserUUID: $uuid, input: $input) {\n      ...CompanyUserBody\n    }\n  }\n':
    types.GetCompanyUserDocument,
  '\n  mutation DeleteCompanyUser($uuid: UUID!) {\n    deleteCompanyUser(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteCompanyUserDocument,
  '\n  mutation ReactivateCompanyUser($uuid: UUID!) {\n    activateCompanyUser(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.ReactivateCompanyUserDocument,
  '\n  mutation CreateCompanyUser($companySlug: String!, $input: CreateCompanyUserInput!) {\n    createCompanyUser(companySlug: $companySlug, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateCompanyUserDocument,
  '\n  mutation UpdateCompanyUser($uuid: UUID!, $input: UpdateCompanyUserInput!) {\n    updateCompanyUser(companyUserUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateCompanyUserDocument,
  '\n  mutation UpdateUserRoles($uuid: UUID!, $input: [UserRoleInput!]!) {\n    updateUserRoles(companyUserUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateUserRolesDocument,
  '\n  mutation AssignOperatorRole($uuid: UUID!) {\n    assignOperatorRole(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.AssignOperatorRoleDocument,
  '\n  mutation RemoveOperatorRole($uuid: UUID!) {\n    removeOperatorRole(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.RemoveOperatorRoleDocument,
  '\n  query RadioProfilesQuery($networkUUID: UUID!) {\n    radioProfilesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      isDefault\n      band2_4GIsEnabled\n      band2_4GAutoChannelIsEnabled\n      band2_4GAllowedChannels\n      band2_4GAutoChannelWidthIsEnabled\n      band2_4GAutoTxPowerIsEnabled\n      band2_4GChannelWidthMHz\n      band2_4GAutoTxPowerMaxdBm\n      band2_4GAutoTxPowerMindBm\n      band5GIsEnabled\n      band5GAutoChannelIsEnabled\n      band5GAllowedChannels\n      band5GAutoChannelWidthIsEnabled\n      band5GAutoTxPowerIsEnabled\n      band5GChannelWidthMHz\n      band5GAutoTxPowerMaxdBm\n      band5GAutoTxPowerMindBm\n\n      virtualDevices {\n        UUID\n      }\n    }\n  }\n':
    types.RadioProfilesQueryDocument,
  '\n  mutation CreateRadioProfileMutation($networkUUID: UUID!, $input: CreateRadioProfileInput!) {\n    createRadioProfile(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateRadioProfileMutationDocument,
  '\n  mutation UpdateRadioProfileMutation($uuid: UUID!, $input: UpdateRadioProfileInput!) {\n    updateRadioProfile(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateRadioProfileMutationDocument,
  '\n  mutation DeleteRadioProfileMutation($uuid: UUID!) {\n    deleteRadioProfile(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteRadioProfileMutationDocument,
  '\n  mutation CopyRadioSettingsMutation($networkUUID: UUID!) {\n    copyRadioSettingsForNetworkFromConfig1(networkUUID: $networkUUID) {\n      __typename\n    }\n  }\n':
    types.CopyRadioSettingsMutationDocument,
  '\n  mutation RPCEAPOLTestMutation($input: RPCEapolTestForSerialsInput!) {\n    rpcWosEapolTestForSerials(input: $input) {\n      status\n      error_reason\n      serialNumber\n    }\n  }\n':
    types.RpceapolTestMutationDocument,
  '\n  query SSIDsQuery($networkUUID: UUID!) {\n    ssidsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      ssid\n      clientSteeringAssociationSteeringIsEnabled\n      clientSteeringPostassociationSteeringIsEnabled\n      description\n      is80211axEnabled\n      isEnabled\n      isGuest\n      isHidden\n      isIsolateClientsEnabled\n      encryptionProtocol\n      password\n      isBand2GAllowed\n      band2GMinimumBasicRateKbps\n      isBand5GAllowed\n      passwordRotationCadence\n      passwordRotationDayOfMonthLocal\n      passwordRotationDayOfWeekLocal\n      passwordRotationHourOfDayLocal\n      passwordLastRotatedAt\n      passwordNextRotationAt\n      band5GMinimumBasicRateKbps\n      isEnabledForAllAccessPoints\n      roaming80211rIsEnabled\n      roaming80211kIsEnabled\n      roaming80211vIsEnabled\n      encryption80211wProtectedManagementFramesMode\n      hs20HasProfile\n      hs20Enabled\n      hs20OperatorNames\n      hs20VenueNames\n      hs20VenueGroup\n      hs20VenueType\n      hs20AccessNetworkType\n      hs20RoamingConsortiumOIs\n      hs20DomainNames\n      hs20NaiRealms\n      hs20MccMncPairs\n      dynamicVLANMode\n      radiusCoaEnabled\n      radiusCoaPort\n      primaryEncryption8021X {\n        UUID\n      }\n      secondaryEncryption8021X {\n        UUID\n      }\n      enabledAccessPointVirtualDevices {\n        UUID\n      }\n      vlan {\n        UUID\n        name\n      }\n    }\n  }\n':
    types.SsiDsQueryDocument,
  '\n  query Hs20AccessNetworkTypes {\n    hs20ValidTypes {\n      accessNetworkTypes {\n        type\n        description\n      }\n      venueTypes {\n        group\n        type\n        description\n      }\n      eapMethods {\n        method\n        description\n        mostUsed\n      }\n      authParams {\n        param\n        description\n        validValues {\n          value\n          description\n        }\n      }\n    }\n  }\n':
    types.Hs20AccessNetworkTypesDocument,
  '\n  mutation CreateSSIDMutation($networkUUID: UUID!, $input: CreateSSIDInput!) {\n    createSSID(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateSsidMutationDocument,
  '\n  mutation UpdateSSIDMutation($uuid: UUID!, $input: UpdateSSIDInput!) {\n    updateSSID(uuid: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateSsidMutationDocument,
  '\n  mutation DeleteSSIDMutation($uuid: UUID!) {\n    deleteSSID(uuid: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteSsidMutationDocument,
  '\n  mutation CopySSIDsMutation($networkUUID: UUID!) {\n    copySSIDsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopySsiDsMutationDocument,
  '\n  query RadiusProfilesQuery($networkUUID: UUID!) {\n    encryption8021XsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      authServerIPAddress\n      authServerPort\n      authServerSecret\n      accountingServerIPAddress\n      accountingServerPort\n      accountingServerSecret\n    }\n  }\n':
    types.RadiusProfilesQueryDocument,
  '\n  mutation CreateRadiusProfileMutation($input: CreateEncryption8021XInput!) {\n    createEncryption8021X(input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateRadiusProfileMutationDocument,
  '\n  mutation UpdateRadiusProfileMutation($uuid: UUID!, $input: UpdateEncryption8021XInput!) {\n    updateEncryption8021X(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateRadiusProfileMutationDocument,
  '\n  mutation DeleteRadiusProfileMutation($uuid: UUID!) {\n    deleteEncryption8021X(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteRadiusProfileMutationDocument,
  '\n  query WirelessConnectionEventsByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ClientMetricsTimeseriesFilterInput!\n    $limit: Int\n    $offset: Int\n  ) {\n    wirelessClientConnectionEventsByAP(\n      networkUUID: $networkUUID\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      filter: $filter\n      limit: $limit\n      offset: $offset\n    ) {\n      timestamp\n      band\n      eventType\n      failedStep\n      reasonCode\n      sessionDuration\n      timeToConnect\n      macAddress\n      is80211rRoam\n      ssid {\n        UUID\n        ssid\n      }\n      virtualDevice {\n        UUID\n        label\n      }\n      previousVirtualDeviceConnectedTo {\n        UUID\n        label\n      }\n    }\n  }\n':
    types.WirelessConnectionEventsByAccessPointQueryDocument,
  '\n  query WirelessClientMetricsByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ClientMetricsTimeseriesFilterInput!\n  ) {\n    wirelessClientMetricsByAP(\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      networkUUID: $networkUUID\n      filter: $filter\n    ) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        tx {\n          minValue\n          maxValue\n        }\n        rx {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        signal\n        noise\n        txRate\n        rxRate\n        snr\n        txBytes\n        rxBytes\n        txMulticastBytes\n        rxMulticastBytes\n        txUnicastBytes\n        rxUnicastBytes\n        clientCount\n      }\n    }\n  }\n':
    types.WirelessClientMetricsByAccessPointQueryDocument,
  '\n  query APChannelUtilizationByNetworkQuery(\n    $networkUUID: UUID!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByNetwork(networkUUID: $networkUUID, filter: $filter) {\n      band\n      virtualDeviceUUID\n      values {\n        totalUtilization\n        timestamp\n      }\n    }\n  }\n':
    types.ApChannelUtilizationByNetworkQueryDocument,
  '\n  query APChannelUtilizationByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByAP(\n      networkUUID: $networkUUID\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      filter: $filter\n    ) {\n      band\n      values {\n        totalUtilization\n        timestamp\n      }\n    }\n  }\n':
    types.ApChannelUtilizationByAccessPointQueryDocument,
  '\n  query AccessPointsQuery(\n    $networkUUID: UUID!\n    $includeUptime: Boolean = false\n    $includeIsDev: Boolean = false\n  ) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: ACCESS_POINT }) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on AccessPointVirtualDevice {\n        ...AccessPointUptimeFragment @include(if: $includeUptime)\n        radioProfile {\n          name\n          UUID\n          band2_4GIsEnabled\n          band2_4GChannelWidthMHz\n          band2_4GAutoChannelIsEnabled\n          band5GIsEnabled\n          band5GChannelWidthMHz\n          band5GAutoChannelIsEnabled\n        }\n\n        radioSettings {\n          band2_4GPrimaryChannel\n          band2_4GTransmitPowerdBm\n          band5GPrimaryChannel\n          band5GTransmitPowerdBm\n        }\n\n        connectedClients {\n          macAddress\n          band\n          channel\n          ssidName\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n\n        ... on AccessPointHardwareDevice {\n          ipAddress\n          isInCurrentControllerMACTable\n          phyInterfaceConnectedTo {\n            UUID\n            portSpeedMbps\n            maxSpeedMbps\n            isEthernet\n            isSFP\n          }\n        }\n      }\n    }\n  }\n':
    types.AccessPointsQueryDocument,
  '\n  fragment AccessPointUptimeFragment on AccessPointVirtualDevice {\n    uptime\n  }\n':
    types.AccessPointUptimeFragmentFragmentDoc,
  '\n  query AccessPointQuery($uuid: UUID!, $includeUptime: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      isConsoleEnabled\n      networkUUID\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      ... on AccessPointVirtualDevice {\n        isLEDDarkModeEnabled\n        ...AccessPointUptimeFragment @include(if: $includeUptime)\n\n        radioProfile {\n          UUID\n          band2_4GIsEnabled\n          band2_4GChannelWidthMHz\n          band2_4GAutoChannelIsEnabled\n          band5GIsEnabled\n          band5GChannelWidthMHz\n          band5GAutoChannelIsEnabled\n        }\n\n        radioSettings {\n          band2_4GPrimaryChannel\n          band2_4GTransmitPowerdBm\n          band5GPrimaryChannel\n          band5GTransmitPowerdBm\n        }\n\n        connectedClients {\n          macAddress\n          band\n          channel\n          ssidName\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n\n        ... on AccessPointHardwareDevice {\n          ipAddress\n          phyInterfaceConnectedTo {\n            isSFP\n            portSpeedMbps\n            maxSpeedMbps\n          }\n        }\n      }\n    }\n  }\n':
    types.AccessPointQueryDocument,
  '\n  mutation CopyAccessPointLabelsMutation($networkUUID: UUID!) {\n    copyAccessPointConfig1LocationsToConfig2Labels(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n':
    types.CopyAccessPointLabelsMutationDocument,
  '\n  mutation UpdateVirtualDevicesMutation(\n    $virtualDeviceUUIDs: [UUID!]!\n    $networkUUID: UUID!\n    $input: UpdateVirtualDeviceInput!\n  ) {\n    updateVirtualDevices(\n      networkUUID: $networkUUID\n      virtualDeviceUUIDs: $virtualDeviceUUIDs\n      input: $input\n    ) {\n      UUID\n    }\n  }\n':
    types.UpdateVirtualDevicesMutationDocument,
  '\n  query FallbackAccessPointHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n      virtualDevice {\n        label\n      }\n    }\n  }\n':
    types.FallbackAccessPointHardwareQueryDocument,
  '\n  query BSSIDsForNetworkQuery($networkUUID: UUID!) {\n    bssidsForNetwork(networkUUID: $networkUUID) {\n      BSSID\n      SSID {\n        ssid\n      }\n      isActive\n      radioBand\n      accessPointHardwareDevice {\n        virtualDevice {\n          UUID\n          label\n        }\n      }\n    }\n  }\n':
    types.BssiDsForNetworkQueryDocument,
  '\n  fragment AutoVPNMemberFields on AutoVPNMember {\n    __typename\n    UUID\n    networkUUID\n    network {\n      UUID\n      slug\n      label\n    }\n    isFailoverEnabled\n    permittedVLANs {\n      __typename\n      UUID\n      name\n      vlanID\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n    uplink {\n      UUID\n      address\n      addressPrefixLength\n      hubUplinkUUID\n      publicKey\n      listenPort\n      phyInterfaceUUID\n      phyInterface {\n        ...PhyInterfaceLabelFields\n      }\n      connectionInfo {\n        observedAt\n        lastHandshakeRx\n        lastHandshakeTx\n        lastPacketTx\n        lastPacketRx\n      }\n    }\n  }\n':
    types.AutoVpnMemberFieldsFragmentDoc,
  '\n  fragment AutoVPNGroupFields on AutoVPNGroup {\n    __typename\n    UUID\n    name\n    isEnabled\n    hubNetworkUUID\n    hubNetwork {\n      UUID\n      slug\n      label\n    }\n    hubUplink {\n      address\n      addressPrefixLength\n      phyInterface {\n        UUID\n        ...PhyInterfaceLabelFields\n      }\n    }\n    isHubFailoverEnabled\n    members {\n      ...AutoVPNMemberFields\n    }\n    routes {\n      __typename\n      UUID\n      name\n      isEnabled\n      dstPrefixLength\n      dstGateway\n    }\n  }\n':
    types.AutoVpnGroupFieldsFragmentDoc,
  '\n  query AutoVPNGroupsForCompany($companySlug: String!) {\n    autoVPNGroupsForCompany(companySlug: $companySlug) {\n      ...AutoVPNGroupFields\n    }\n  }\n':
    types.AutoVpnGroupsForCompanyDocument,
  '\n  query AutoVPNGroup($uuid: UUID!) {\n    autoVPNGroup(UUID: $uuid) {\n      ...AutoVPNGroupFields\n    }\n  }\n':
    types.AutoVpnGroupDocument,
  '\n  query AutoVPNMemberQuery($uuid: UUID!) {\n    autoVPNMember(UUID: $uuid) {\n      ...AutoVPNMemberFields\n    }\n  }\n':
    types.AutoVpnMemberQueryDocument,
  '\n  mutation CreateAutoVPNGroup($companySlug: String!, $input: CreateAutoVPNGroupInput!) {\n    createAutoVPNGroup(companySlug: $companySlug, input: $input) {\n      UUID\n      companySlug\n      isEnabled\n      name\n      hubNetworkUUID\n    }\n  }\n':
    types.CreateAutoVpnGroupDocument,
  '\n  mutation UpdateAutoVPNGroup($UUID: UUID!, $input: UpdateAutoVPNGroupInput!) {\n    updateAutoVPNGroup(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateAutoVpnGroupDocument,
  '\n  mutation DeleteAutoVPNGroup($uuid: UUID!) {\n    deleteAutoVPNGroup(UUID: $uuid) {\n      UUID\n      companySlug\n      isEnabled\n      name\n      hubNetworkUUID\n    }\n  }\n':
    types.DeleteAutoVpnGroupDocument,
  '\n  mutation AddNetworkToAutoVPNGroup($groupUUID: UUID!, $input: AddNetworkToAutoVPNGroupInput!) {\n    addNetworkToAutoVPNGroup(groupUUID: $groupUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.AddNetworkToAutoVpnGroupDocument,
  '\n  mutation UpdateAutoVPNMember($uuid: UUID!, $input: UpdateAutoVPNMemberInput!) {\n    updateAutoVPNMember(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateAutoVpnMemberDocument,
  '\n  mutation RemoveNetworkFromAutoVPNGroup($groupUUID: UUID!, $networkUUID: UUID!) {\n    removeNetworkFromAutoVPNGroup(groupUUID: $groupUUID, networkUUID: $networkUUID)\n  }\n':
    types.RemoveNetworkFromAutoVpnGroupDocument,
  '\n  mutation CreateAutoVPNRoute($groupUUID: UUID!, $input: CreateAutoVPNRouteInput!) {\n    createAutoVPNRoute(groupUUID: $groupUUID, input: $input) {\n      UUID\n      createdAt\n      updatedAt\n      dstGateway\n      dstPrefixLength\n    }\n  }\n':
    types.CreateAutoVpnRouteDocument,
  '\n  mutation DeleteAutoVPNRoute($uuid: UUID!) {\n    deleteAutoVPNRoute(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteAutoVpnRouteDocument,
  '\n  query NetworksWithControllerPorts($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      UUID\n      label\n      isActive\n      isTemplate\n      virtualDevices(filter: { deviceType: CONTROLLER }) {\n        __typename\n        label\n        ... on ControllerVirtualDevice {\n          phyInterfaces {\n            UUID\n            isUplink\n            ...PhyInterfaceLabelFields\n          }\n        }\n      }\n    }\n  }\n':
    types.NetworksWithControllerPortsDocument,
  '\n  mutation CreateNOSFirmwareUpgradeMutation($input: CreateBulkNOSUpgradeInput!) {\n    createBulkNOSUpgrade(input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateNosFirmwareUpgradeMutationDocument,
  '\n  query BulkNOSUpgradeQuery($uuid: UUID!) {\n    bulkNOSUpgrade(UUID: $uuid) {\n      UUID\n      createdAt\n      updatedAt\n      updatedBy\n      nosVersionID\n      networks {\n        __typename\n        UUID\n        label\n        slug\n        companySlug\n        company {\n          name\n        }\n      }\n    }\n  }\n':
    types.BulkNosUpgradeQueryDocument,
  '\n  query BulkNOSUpgradesQuery($filter: BulkNOSUpgradesFilterInput!) {\n    bulkNOSUpgrades(filter: $filter) {\n      UUID\n      createdAt\n      updatedAt\n      updatedBy\n      nosVersionID\n      networks {\n        UUID\n        label\n      }\n    }\n  }\n':
    types.BulkNosUpgradesQueryDocument,
  '\n  query NetworksByFilterQuery($filter: NetworksFilter!) {\n    networks(filter: $filter) {\n      __typename\n      UUID\n      label\n      slug\n      companySlug\n      company {\n        name\n        supportTier\n      }\n      virtualDevices {\n        hardwareDevice {\n          deviceType\n          deviceModel\n        }\n      }\n      pinnedNOSVersionID\n      pendingNOSVersionID\n      lastUpgradedAt\n      isUpgradeSensitive\n      isActive\n    }\n  }\n':
    types.NetworksByFilterQueryDocument,
  '\n  query GetEventLogs($networkUUID: UUID!, $startTime: DateTime, $endTime: DateTime) {\n    recentEventLogEvents(\n      limit: 100\n      offset: 0\n      networkUUID: $networkUUID\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      eventType\n      generatedAt\n      networkUUID\n      properties {\n        ... on WANStatusChangeProperties {\n          virtualDevice {\n            __typename\n            UUID\n            ... on ControllerVirtualDevice {\n              phyInterfaces {\n                UUID\n                portNumber\n                uplinkPriority\n                isUplink\n              }\n            }\n          }\n          activeWANConnections {\n            externalAddresses\n            internetServicePlanProvider {\n              name\n            }\n            port\n          }\n        }\n      }\n    }\n  }\n':
    types.GetEventLogsDocument,
  '\n  query networkClients($networkUUID: UUID!, $filter: NetworkClientsFilter) {\n    networkClients(networkUUID: $networkUUID, filter: $filter) {\n      alias\n      apLocation\n      apSerialNumber\n      channel\n      clientName\n      ip\n      isWireless\n      lastSeen\n      dhcpLeaseExpiresAt\n      macAddress\n      noise\n      totalRxBytes\n      rxRate\n      signal\n      ssid\n      txRate\n      totalTxBytes\n      vlan\n      vlanUUID\n      connectedVLAN {\n        UUID\n        name\n        vlanID\n      }\n      associatedAt\n      throughput {\n        txRate\n        rxRate\n      }\n      serviceTypes\n      wirelessScore\n    }\n  }\n':
    types.NetworkClientsDocument,
  '\n  query GetCaptivePortal($vlanUUID: UUID!) {\n    captivePortalForVLAN(vlanUUID: $vlanUUID) {\n      displaySettings {\n        fontColor\n      }\n      logoImageURL\n      redirectURL\n      termsAndConditions\n      vlans {\n        UUID\n      }\n    }\n  }\n':
    types.GetCaptivePortalDocument,
  '\n  query getNetworkClientsTxRxPerDay($networkUUID: UUID!, $numDaysLookback: Int!) {\n    networkClientsTxRxPerDay(networkUUID: $networkUUID, numDaysLookback: $numDaysLookback) {\n      ip\n      macAddress\n      dateString\n      txBytes\n      rxBytes\n    }\n  }\n':
    types.GetNetworkClientsTxRxPerDayDocument,
  '\n  query Company($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      name\n      slug\n      isCustomer\n      supportGroup\n    }\n  }\n':
    types.CompanyDocument,
  '\n  query NetworkStatusQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      description\n      deviceModel\n      hardwareDevice {\n        serialNumber\n        macAddress\n        isConnectedToBackend\n      }\n    }\n  }\n':
    types.NetworkStatusQueryDocument,
  '\n  fragment NetworkForCompanyFields on Network {\n    UUID\n    label\n    slug\n    isActive\n    isCustomer\n    isTemplate\n    isTrial\n    company {\n      slug\n      name\n      uuid\n      supportGroup\n    }\n    virtualDevices(filter: { deviceType: CONTROLLER }) {\n      __typename\n      UUID\n      label\n      deviceType\n      hardwareDevice {\n        serialNumber\n        isActive\n        isConnectedToBackend\n      }\n      ... on ControllerVirtualDevice {\n        highAvailability {\n          role\n          status\n        }\n      }\n    }\n    mailingAddress {\n      line1\n      line2\n      city\n      subdivisionCode\n      postalCode\n      timezone\n      countryISO2\n    }\n    enabledServiceDiscoveryTypes\n    multiWANAlgorithm\n    pinnedNOSVersionID\n  }\n':
    types.NetworkForCompanyFieldsFragmentDoc,
  '\n  query NetworksForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      ...NetworkForCompanyFields\n    }\n  }\n':
    types.NetworksForCompanyDocument,
  '\n  query NetworkByUUIDQuery($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      ...NetworkForCompanyFields\n    }\n  }\n':
    types.NetworkByUuidQueryDocument,
  '\n  query IsNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKey: $nosFeatureKey)\n  }\n':
    types.IsNosFeatureEnabledForNetworkDocument,
  '\n  query AreNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKeys: [String!]!) {\n    areNosFeaturesEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKeys: $nosFeatureKeys) {\n      key\n      enabled\n    }\n  }\n':
    types.AreNosFeatureEnabledForNetworkDocument,
  '\n  query IsNosFeatureEnabledForCompany($companySlug: String!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForCompany(companySlug: $companySlug, nosFeatureKey: $nosFeatureKey)\n  }\n':
    types.IsNosFeatureEnabledForCompanyDocument,
  '\n  query AreNosFeatureEnabledForCompany($companySlug: String!, $nosFeatureKeys: [String!]!) {\n    areNosFeaturesEnabledForCompany(companySlug: $companySlug, nosFeatureKeys: $nosFeatureKeys) {\n      key\n      enabled\n    }\n  }\n':
    types.AreNosFeatureEnabledForCompanyDocument,
  '\n  query ValidChannelsQuery($networkUUID: UUID!) {\n    validChannels(networkUUID: $networkUUID) {\n      band\n      channels\n    }\n  }\n':
    types.ValidChannelsQueryDocument,
  '\n  query CompanyUUIDQuery($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      __typename\n    }\n  }\n':
    types.CompanyUuidQueryDocument,
  '\n  query Permissions($input: PermissionsInput!) {\n    permissions(input: $input) {\n      networkUUID\n      permissions\n    }\n  }\n':
    types.PermissionsDocument,
  '\n  query IPSecTunnelsQuery($networkUUID: UUID!) {\n    ipSecTunnelsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      isEnabled\n      isInitiator\n      authenticationAlgorithm\n      encryptionAlgorithm\n      keyExchangeDHGroup\n      right\n      rightPrefixes\n      rightID\n      leftID\n      boundPhyInterface {\n        UUID\n        label\n        portNumber\n        virtualDevice {\n          label\n          hardwareDevice {\n            isSpare\n            isConnectedToBackend\n            deviceType\n          }\n        }\n      }\n      boundVLANs {\n        UUID\n        vlanID\n        name\n      }\n      status {\n        observedAt\n        saStatus\n        uniqueID\n        remoteHostIP4Address\n        establishedAt\n        rekeyAt\n        reauthAt\n        childSAStatus\n        rxBytes\n        txBytes\n        rxPackets\n        rxLast\n        txLast\n        childRekeyAt\n        childLifetime\n        latencyMs\n      }\n    }\n  }\n':
    types.IpSecTunnelsQueryDocument,
  '\n  query IPSecTunnelQuery($UUID: UUID!) {\n    ipSecTunnel(UUID: $UUID) {\n      UUID\n      name\n      isEnabled\n      right\n      rightPrefixes\n      rightID\n      leftID\n      isInitiator\n      authenticationAlgorithm\n      encryptionAlgorithm\n      keyExchangeDHGroup\n      boundPhyInterface {\n        UUID\n        portNumber\n        virtualDevice {\n          label\n          hardwareDevice {\n            isSpare\n            isConnectedToBackend\n            deviceType\n            serialNumber\n          }\n        }\n      }\n      boundVLANs {\n        UUID\n        vlanID\n        name\n      }\n      status {\n        observedAt\n        saStatus\n        uniqueID\n        remoteHostIP4Address\n        establishedAt\n        rekeyAt\n        reauthAt\n        childSAStatus\n        rxBytes\n        txBytes\n        rxPackets\n        rxLast\n        txLast\n        childRekeyAt\n        childLifetime\n        latencyMs\n      }\n    }\n  }\n':
    types.IpSecTunnelQueryDocument,
  '\n  mutation UpdateIPSecTunnel($uuid: UUID!, $input: UpdateIPSecTunnelInput!) {\n    updateIPSecTunnel(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n':
    types.UpdateIpSecTunnelDocument,
  '\n  mutation DeleteIPSecTunnel($uuid: UUID!) {\n    deleteIPSecTunnel(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteIpSecTunnelDocument,
  '\n  mutation CreateIPSecTunnel($networkUUID: UUID!, $input: CreateIPSecTunnelInput!) {\n    createIPSecTunnel(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CreateIpSecTunnelDocument,
  '\n  mutation RpcRestartIPSecTunnel($serialNumber: String!, $ipSecTunnelUUID: UUID!) {\n    rpcRestartIPSecTunnel(serialNumber: $serialNumber, ipSecTunnelUUID: $ipSecTunnelUUID)\n  }\n':
    types.RpcRestartIpSecTunnelDocument,
  '\n  mutation DeleteNOSFirmwareUpgradeMutation($uuid: UUID!) {\n    deleteBulkNOSUpgrade(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteNosFirmwareUpgradeMutationDocument,
  '\n  query SwitchCountQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      UUID\n    }\n  }\n':
    types.SwitchCountQueryDocument,
  '\n  query VirtualDevicesForTopology($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      label\n      deviceType\n\n      hardwareDevice {\n        __typename\n        serialNumber\n        deviceType\n        isActive\n        isConnectedToBackend\n      }\n\n      ... on ControllerVirtualDevice {\n        phyInterfaces {\n          virtualDeviceUUID\n          isUplink\n          connectedDevices {\n            hardwareDevice {\n              serialNumber\n              deviceType\n              isActive\n              virtualDeviceUUID\n              isConnectedToBackend\n            }\n          }\n          internetServicePlan {\n            provider {\n              UUID\n              name\n            }\n          }\n          isUplinkActive\n        }\n      }\n\n      ... on SwitchVirtualDevice {\n        phyInterfaces {\n          isUplink\n          connectedDevices {\n            hardwareDevice {\n              serialNumber\n              deviceType\n              isActive\n              virtualDeviceUUID\n              isConnectedToBackend\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.VirtualDevicesForTopologyDocument,
  '\n  query MutationAuditLogEntriesForNetwork($networkUUID: UUID!, $filter: MutationAuditLogFilter) {\n    mutationAuditLogEntriesForNetwork(networkUUID: $networkUUID, filter: $filter) {\n      UUID\n      networkUUID\n      createdAt\n      username\n      action\n      args\n      reason\n    }\n  }\n':
    types.MutationAuditLogEntriesForNetworkDocument,
  '\n  query statsForVirtualDevices($networkUUID: UUID!, $filter: NetworkStatsFilter!) {\n    statsForVirtualDevices(networkUUID: $networkUUID, filter: $filter) {\n      observedAt\n      insertedAt\n      networkUUID\n      virtualDeviceUUID\n      type\n      rawData\n    }\n  }\n':
    types.StatsForVirtualDevicesDocument,
  '\n  query onboardingForNetwork($networkSlug: String!, $companySlug: String!) {\n    networkBySlug(companySlug: $companySlug, networkSlug: $networkSlug) {\n      UUID\n      onboarding {\n        networkUUID\n        jobData {\n          fullAddress\n          installationStartDate\n          installationEndDate\n          jobOwnerUser\n          jobStage\n          jobStatus\n          jobTechnicianName\n          jobTechnicianPhone\n          onsiteContactInformation\n          scheduleSiteSurveyLink\n          siteSurveyDate\n          siteSurveyStatus\n          targetGoLiveDate\n          totalSqFt\n        }\n      }\n    }\n  }\n':
    types.OnboardingForNetworkDocument,
  '\n  query NetworkForOnboardingDocument($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      onboardingDocument {\n        document\n        delegations {\n          UUID\n          fieldName\n          email\n          description\n        }\n      }\n    }\n  }\n':
    types.NetworkForOnboardingDocumentDocument,
  '\n  mutation UpsertNetworkOnboardingDocument($networkUUID: UUID!, $document: JSONObject!) {\n    upsertNetworkOnboardingDocument(networkUUID: $networkUUID, document: $document) {\n      UUID\n    }\n  }\n':
    types.UpsertNetworkOnboardingDocumentDocument,
  '\n  mutation UpsertNetworkOnboardingFieldDelegation(\n    $networkUUID: UUID!\n    $fieldName: String!\n    $input: UpsertNetworkOnboardingFieldDelegationInput!\n  ) {\n    upsertNetworkOnboardingFieldDelegation(\n      networkUUID: $networkUUID\n      fieldName: $fieldName\n      input: $input\n    ) {\n      UUID\n    }\n  }\n':
    types.UpsertNetworkOnboardingFieldDelegationDocument,
  '\n  mutation DeleteNetworkOnboardingFieldDelegation($uuid: UUID!) {\n    deleteNetworkOnboardingFieldDelegation(UUID: $uuid) {\n      UUID\n    }\n  }\n':
    types.DeleteNetworkOnboardingFieldDelegationDocument,
  '\n  query LatestDeviceConfig($serialNumber: String!) {\n    latestDeviceConfig(serialNumber: $serialNumber) {\n      configJSON\n    }\n  }\n':
    types.LatestDeviceConfigDocument,
  '\n  query DeviceConfigOverride($serialNumber: String!) {\n    deviceConfigOverride(serialNumber: $serialNumber) {\n      configJSON\n    }\n  }\n':
    types.DeviceConfigOverrideDocument,
  '\n  mutation UpdateDeviceConfigOverride($serialNumber: String!, $configJSON: String!) {\n    upsertDeviceConfigOverride(serialNumber: $serialNumber, configJSON: $configJSON)\n  }\n':
    types.UpdateDeviceConfigOverrideDocument,
  '\n  query DeviceForNetwork($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      label\n      hardwareDevice {\n        serialNumber\n      }\n    }\n  }\n':
    types.DeviceForNetworkDocument,
  '\n  mutation CopyIPSecTunnelFromConfig1ToConfig2(\n    $networkUUID: UUID!\n    $input: CopyIPSecTunnelFromConfig1ToConfig2Input!\n  ) {\n    copyIPSecTunnelFromConfig1ToConfig2(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n':
    types.CopyIpSecTunnelFromConfig1ToConfig2Document,
  '\n  fragment NetworkFields on Network {\n    UUID\n    slug\n    label\n    companySlug\n    isActive\n    isUpgradeSensitive\n    isTemplate\n    lastUpgradedAt\n    highAvailabilityConfig {\n      UUID\n    }\n    virtualDevices {\n      deviceType\n    }\n\n    mailingAddress {\n      line1\n      line2\n      city\n      subdivisionCode\n      postalCode\n      countryISO2\n    }\n  }\n':
    types.NetworkFieldsFragmentDoc,
  '\n  query CompaniesQuery($limit: Int!, $offset: Int!) {\n    listCompanies(limit: $limit, offset: $offset) {\n      uuid\n      isCustomer\n      supportTier\n      slug\n      name\n      networks {\n        ...NetworkFields\n      }\n    }\n  }\n':
    types.CompaniesQueryDocument,
  '\n  query CompanyQuery($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      isCustomer\n      supportTier\n      slug\n      name\n      networks {\n        ...NetworkFields\n      }\n    }\n  }\n':
    types.CompanyQueryDocument,
  '\n  query AllSecurityAppliances($limit: Int, $offset: Int) {\n    hardwareDevices(filter: { deviceType: CONTROLLER, limit: $limit, offset: $offset }) {\n      serialNumber\n      isActive\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        network {\n          UUID\n          label\n          slug\n\n          company {\n            slug\n            name\n          }\n        }\n      }\n    }\n  }\n':
    types.AllSecurityAppliancesDocument,
  '\n  query SSOOptionsQuery($companySlug: String!) {\n    ssoOptions(input: { companySlug: $companySlug }) {\n      connectedDirectory {\n        createdAt\n        state\n        type\n      }\n      connections {\n        connectedAt\n        state\n        type\n      }\n    }\n  }\n':
    types.SsoOptionsQueryDocument,
  '\n  mutation ConfigureSSOMutation($input: ConfigureSSOInput!) {\n    configureSSO(input: $input) {\n      portalURL\n    }\n  }\n':
    types.ConfigureSsoMutationDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RunACSForAccessPointMutation($virtualDeviceUUID: UUID!) {\n    initializeAutoChannelSelectionForAccessPoint(\n      virtualDeviceUUID: $virtualDeviceUUID\n      input: { bands: BAND_5G }\n    ) {\n      UUID\n      scheduledAt\n      timeoutAt\n    }\n  }\n',
): (typeof documents)['\n  mutation RunACSForAccessPointMutation($virtualDeviceUUID: UUID!) {\n    initializeAutoChannelSelectionForAccessPoint(\n      virtualDeviceUUID: $virtualDeviceUUID\n      input: { bands: BAND_5G }\n    ) {\n      UUID\n      scheduledAt\n      timeoutAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RunACSForNetworkMutation($networkUUID: UUID!) {\n    initializeAutoChannelSelectionForNetwork(networkUUID: $networkUUID, input: { bands: BAND_5G }) {\n      UUID\n      scheduledAt\n      timeoutAt\n    }\n  }\n',
): (typeof documents)['\n  mutation RunACSForNetworkMutation($networkUUID: UUID!) {\n    initializeAutoChannelSelectionForNetwork(networkUUID: $networkUUID, input: { bands: BAND_5G }) {\n      UUID\n      scheduledAt\n      timeoutAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ACSJobsForNetworkQuery($networkUUID: UUID!) {\n    autoChannelSelectionJobsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      scheduledAt\n      timeoutAt\n      completedAt\n      virtualDeviceUUIDs\n      results {\n        timestamp\n        virtualDeviceUUID\n        resultType\n        previous5GHzChannel\n        new5GHzChannel\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ACSJobsForNetworkQuery($networkUUID: UUID!) {\n    autoChannelSelectionJobsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      scheduledAt\n      timeoutAt\n      completedAt\n      virtualDeviceUUIDs\n      results {\n        timestamp\n        virtualDeviceUUID\n        resultType\n        previous5GHzChannel\n        new5GHzChannel\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ClientVPNServerFields on ClientVPNServer {\n    UUID\n    endpoint\n    publicKey\n    port\n    defaultClientAllowedIPs\n    address\n    addressMask\n    phyInterface {\n      ...PhyInterfaceLabelFields\n    }\n    dnsListenAddress\n    defaultClientAllowedIPs\n    isFailoverEnabled\n\n    clients {\n      ...ClientVPNClientFields\n    }\n  }\n',
): (typeof documents)['\n  fragment ClientVPNServerFields on ClientVPNServer {\n    UUID\n    endpoint\n    publicKey\n    port\n    defaultClientAllowedIPs\n    address\n    addressMask\n    phyInterface {\n      ...PhyInterfaceLabelFields\n    }\n    dnsListenAddress\n    defaultClientAllowedIPs\n    isFailoverEnabled\n\n    clients {\n      ...ClientVPNClientFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ClientVPNServer($UUID: UUID!) {\n    clientVPNServer(UUID: $UUID) {\n      ...ClientVPNServerFields\n    }\n  }\n',
): (typeof documents)['\n  query ClientVPNServer($UUID: UUID!) {\n    clientVPNServer(UUID: $UUID) {\n      ...ClientVPNServerFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ClientVPNServers($networkUUID: UUID!) {\n    clientVPNServers(networkUUID: $networkUUID) {\n      ...ClientVPNServerFields\n    }\n  }\n',
): (typeof documents)['\n  query ClientVPNServers($networkUUID: UUID!) {\n    clientVPNServers(networkUUID: $networkUUID) {\n      ...ClientVPNServerFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateClientVPNServer($networkUUID: UUID!, $input: CreateClientVPNServerInput!) {\n    createClientVPNServer(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateClientVPNServer($networkUUID: UUID!, $input: CreateClientVPNServerInput!) {\n    createClientVPNServer(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateClientVPNServer($uuid: UUID!, $input: UpdateClientVPNServerInput!) {\n    updateClientVPNServer(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateClientVPNServer($uuid: UUID!, $input: UpdateClientVPNServerInput!) {\n    updateClientVPNServer(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteClientVPNServer($uuid: UUID!) {\n    deleteClientVPNServer(UUID: $uuid)\n  }\n',
): (typeof documents)['\n  mutation DeleteClientVPNServer($uuid: UUID!) {\n    deleteClientVPNServer(UUID: $uuid)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ClientVPNClientFields on ClientVPNClient {\n    UUID\n    createdAt\n    createdBy\n    ipAddress\n    name\n    publicKey\n    userEmail\n    userSID\n    vpnServerUUID\n\n    connectionInfo {\n      observedAt\n      lastHandshakeTx\n      lastHandshakeRx\n      lastPacketTx\n      lastPacketRx\n    }\n  }\n',
): (typeof documents)['\n  fragment ClientVPNClientFields on ClientVPNClient {\n    UUID\n    createdAt\n    createdBy\n    ipAddress\n    name\n    publicKey\n    userEmail\n    userSID\n    vpnServerUUID\n\n    connectionInfo {\n      observedAt\n      lastHandshakeTx\n      lastHandshakeRx\n      lastPacketTx\n      lastPacketRx\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ClientVPNClients($serverUUID: UUID!) {\n    clientVPNClients(serverUUID: $serverUUID) {\n      ...ClientVPNClientFields\n    }\n  }\n',
): (typeof documents)['\n  query ClientVPNClients($serverUUID: UUID!) {\n    clientVPNClients(serverUUID: $serverUUID) {\n      ...ClientVPNClientFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ClientVPNClient($UUID: UUID!) {\n    clientVPNClient(UUID: $UUID) {\n      ...ClientVPNClientFields\n    }\n  }\n',
): (typeof documents)['\n  query ClientVPNClient($UUID: UUID!) {\n    clientVPNClient(UUID: $UUID) {\n      ...ClientVPNClientFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateClientVPNClient($serverUUID: UUID!, $input: CreateClientVPNClientInput!) {\n    createClientVPNClient(serverUUID: $serverUUID, input: $input) {\n      UUID\n      createdAt\n      createdBy\n      ipAddress\n      name\n      publicKey\n      userEmail\n      userSID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateClientVPNClient($serverUUID: UUID!, $input: CreateClientVPNClientInput!) {\n    createClientVPNClient(serverUUID: $serverUUID, input: $input) {\n      UUID\n      createdAt\n      createdBy\n      ipAddress\n      name\n      publicKey\n      userEmail\n      userSID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteClientVPNClient($uuid: UUID!) {\n    deleteClientVPNClient(UUID: $uuid)\n  }\n',
): (typeof documents)['\n  mutation DeleteClientVPNClient($uuid: UUID!) {\n    deleteClientVPNClient(UUID: $uuid)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query WirelessConnectionEventsQuery(\n    $networkUUID: UUID!\n    $mac: MacAddress!\n    $filter: ClientMetricsTimeseriesFilterInput!\n    $limit: Int\n    $offset: Int\n  ) {\n    wirelessClientConnectionEventsByClient(\n      networkUUID: $networkUUID\n      macAddress: $mac\n      filter: $filter\n      limit: $limit\n      offset: $offset\n    ) {\n      timestamp\n      band\n      eventType\n      failedStep\n      reasonCode\n      sessionDuration\n      timeToConnect\n      is80211rRoam\n      macAddress\n      ssid {\n        UUID\n        ssid\n      }\n      virtualDevice {\n        UUID\n        label\n      }\n      previousVirtualDeviceConnectedTo {\n        UUID\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  query WirelessConnectionEventsQuery(\n    $networkUUID: UUID!\n    $mac: MacAddress!\n    $filter: ClientMetricsTimeseriesFilterInput!\n    $limit: Int\n    $offset: Int\n  ) {\n    wirelessClientConnectionEventsByClient(\n      networkUUID: $networkUUID\n      macAddress: $mac\n      filter: $filter\n      limit: $limit\n      offset: $offset\n    ) {\n      timestamp\n      band\n      eventType\n      failedStep\n      reasonCode\n      sessionDuration\n      timeToConnect\n      is80211rRoam\n      macAddress\n      ssid {\n        UUID\n        ssid\n      }\n      virtualDevice {\n        UUID\n        label\n      }\n      previousVirtualDeviceConnectedTo {\n        UUID\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query WirelessClientMetricsByClientQuery(\n    $networkUUID: UUID!\n    $macAddress: MacAddress!\n    $filter: ClientMetricsTimeseriesFilterInput!\n  ) {\n    wirelessClientMetricsByClient(\n      macAddress: $macAddress\n      networkUUID: $networkUUID\n      filter: $filter\n    ) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        txBytes {\n          minValue\n          maxValue\n        }\n        rxBytes {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        signal\n        noise\n        txRate\n        rxRate\n        snr\n        txBytes\n        rxBytes\n        txMulticastBytes\n        rxMulticastBytes\n        txUnicastBytes\n        rxUnicastBytes\n        rxSuccessRatio\n        txSuccessRatio\n        rxRetryRatio\n        txRetryRatio\n        clientCount\n      }\n    }\n  }\n',
): (typeof documents)['\n  query WirelessClientMetricsByClientQuery(\n    $networkUUID: UUID!\n    $macAddress: MacAddress!\n    $filter: ClientMetricsTimeseriesFilterInput!\n  ) {\n    wirelessClientMetricsByClient(\n      macAddress: $macAddress\n      networkUUID: $networkUUID\n      filter: $filter\n    ) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        txBytes {\n          minValue\n          maxValue\n        }\n        rxBytes {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        signal\n        noise\n        txRate\n        rxRate\n        snr\n        txBytes\n        rxBytes\n        txMulticastBytes\n        rxMulticastBytes\n        txUnicastBytes\n        rxUnicastBytes\n        rxSuccessRatio\n        txSuccessRatio\n        rxRetryRatio\n        txRetryRatio\n        clientCount\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ClientChannelUtilizationQuery(\n    $networkUUID: UUID!\n    $macAddress: MacAddress!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByClient(\n      networkUUID: $networkUUID\n      macAddress: $macAddress\n      filter: $filter\n    ) {\n      timestamp\n      totalUtilization\n    }\n  }\n',
): (typeof documents)['\n  query ClientChannelUtilizationQuery(\n    $networkUUID: UUID!\n    $macAddress: MacAddress!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByClient(\n      networkUUID: $networkUUID\n      macAddress: $macAddress\n      filter: $filter\n    ) {\n      timestamp\n      totalUtilization\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation upsertMACAddressAlias($companySlug: String!, $macAddress: MacAddress!, $alias: String!) {\n    upsertMACAddressAlias(companySlug: $companySlug, macAddress: $macAddress, alias: $alias) {\n      alias\n    }\n  }\n',
): (typeof documents)['\n  mutation upsertMACAddressAlias($companySlug: String!, $macAddress: MacAddress!, $alias: String!) {\n    upsertMACAddressAlias(companySlug: $companySlug, macAddress: $macAddress, alias: $alias) {\n      alias\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation rpcDisconnectClient($mac: String!, $serialNumber: String!, $ssid: String!) {\n    rpcWosDisconnectClient(mac: $mac, serialNumber: $serialNumber, ssid: $ssid)\n  }\n',
): (typeof documents)['\n  mutation rpcDisconnectClient($mac: String!, $serialNumber: String!, $ssid: String!) {\n    rpcWosDisconnectClient(mac: $mac, serialNumber: $serialNumber, ssid: $ssid)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VLANsAndDHCPRules($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n      dhcpRule {\n        UUID\n        dnsUseGatewayProxy\n      }\n    }\n  }\n',
): (typeof documents)['\n  query VLANsAndDHCPRules($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n      dhcpRule {\n        UUID\n        dnsUseGatewayProxy\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DNSFirewallRulesForNetwork($networkUUID: UUID!) {\n    applicationDNSFirewallRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      isEnabled\n      name\n      action\n      priority\n      domain\n      category {\n        id\n        name\n        description\n        group {\n          id\n          name\n        }\n      }\n      group {\n        id\n        name\n      }\n      application {\n        id\n        name\n      }\n      dhcpRule {\n        UUID\n        vlan {\n          UUID\n          name\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DNSFirewallRulesForNetwork($networkUUID: UUID!) {\n    applicationDNSFirewallRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      isEnabled\n      name\n      action\n      priority\n      domain\n      category {\n        id\n        name\n        description\n        group {\n          id\n          name\n        }\n      }\n      group {\n        id\n        name\n      }\n      application {\n        id\n        name\n      }\n      dhcpRule {\n        UUID\n        vlan {\n          UUID\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DNSFirewallRule($uuid: UUID!) {\n    applicationDNSFirewallRule(UUID: $uuid) {\n      UUID\n      isEnabled\n      name\n      action\n      priority\n      domain\n      category {\n        id\n        name\n        description\n        group {\n          id\n          name\n        }\n      }\n      group {\n        id\n        name\n      }\n      application {\n        id\n        name\n      }\n      dhcpRule {\n        UUID\n        vlan {\n          UUID\n          name\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DNSFirewallRule($uuid: UUID!) {\n    applicationDNSFirewallRule(UUID: $uuid) {\n      UUID\n      isEnabled\n      name\n      action\n      priority\n      domain\n      category {\n        id\n        name\n        description\n        group {\n          id\n          name\n        }\n      }\n      group {\n        id\n        name\n      }\n      application {\n        id\n        name\n      }\n      dhcpRule {\n        UUID\n        vlan {\n          UUID\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateDNSFirewallRule(\n    $dhcpRuleUUID: UUID!\n    $input: CreateApplicationDNSFirewallRuleInput!\n  ) {\n    createApplicationDNSFirewallRule(dhcpRuleUUID: $dhcpRuleUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDNSFirewallRule(\n    $dhcpRuleUUID: UUID!\n    $input: CreateApplicationDNSFirewallRuleInput!\n  ) {\n    createApplicationDNSFirewallRule(dhcpRuleUUID: $dhcpRuleUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateDNSFirewallRule($uuid: UUID!, $input: UpdateApplicationDNSFirewallRuleInput!) {\n    updateApplicationDNSFirewallRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDNSFirewallRule($uuid: UUID!, $input: UpdateApplicationDNSFirewallRuleInput!) {\n    updateApplicationDNSFirewallRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDNSFirewallRule($uuid: UUID!) {\n    deleteApplicationDNSFirewallRule(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteDNSFirewallRule($uuid: UUID!) {\n    deleteApplicationDNSFirewallRule(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateDNSFirewallRulesPriorities(\n    $input: [UpdateApplicationDNSFirewallRulesPrioritiesInput!]!\n  ) {\n    updateApplicationDNSFirewallRulesPriorities(input: $input) {\n      UUID\n      priority\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDNSFirewallRulesPriorities(\n    $input: [UpdateApplicationDNSFirewallRulesPrioritiesInput!]!\n  ) {\n    updateApplicationDNSFirewallRulesPriorities(input: $input) {\n      UUID\n      priority\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DNSFirewallRuleCategories {\n    applicationDNSFirewallRuleCategories {\n      id\n      name\n      description\n      group {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DNSFirewallRuleCategories {\n    applicationDNSFirewallRuleCategories {\n      id\n      name\n      description\n      group {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DNSFirewallRuleGroups {\n    applicationDNSFirewallRuleGroups {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query DNSFirewallRuleGroups {\n    applicationDNSFirewallRuleGroups {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DNSFirewallRuleApplications {\n    applicationDNSFirewallRuleApplications {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query DNSFirewallRuleApplications {\n    applicationDNSFirewallRuleApplications {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DNSDomainApplication($hostname: String!) {\n    applicationDNSFirewallRuleApplicationForHostname(hostname: $hostname) {\n      id\n      isVisible\n      name\n    }\n  }\n',
): (typeof documents)['\n  query DNSDomainApplication($hostname: String!) {\n    applicationDNSFirewallRuleApplicationForHostname(hostname: $hostname) {\n      id\n      isVisible\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DNSDomainCategory($hostname: String!) {\n    applicationDNSFirewallRuleCategoryForHostname(hostname: $hostname) {\n      id\n      name\n      description\n      group {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DNSDomainCategory($hostname: String!) {\n    applicationDNSFirewallRuleCategoryForHostname(hostname: $hostname) {\n      id\n      name\n      description\n      group {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateRackElevation($networkUUID: UUID!, $label: String!, $rackMountUnitCount: Int!) {\n    createRackElevation(\n      networkUUID: $networkUUID\n      input: { label: $label, rackMountUnitCount: $rackMountUnitCount }\n    ) {\n      UUID\n      networkUUID\n      rackMountUnitCount\n      label\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateRackElevation($networkUUID: UUID!, $label: String!, $rackMountUnitCount: Int!) {\n    createRackElevation(\n      networkUUID: $networkUUID\n      input: { label: $label, rackMountUnitCount: $rackMountUnitCount }\n    ) {\n      UUID\n      networkUUID\n      rackMountUnitCount\n      label\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AttachDeviceToRackElevation(\n    $rackElevationUUID: UUID!\n    $rackMountUnitIndexes: [Int!]!\n    $virtualDeviceUUID: UUID\n    $label: String\n    $type: RackElevationDeviceType\n  ) {\n    attachDeviceToRackElevation(\n      rackElevationUUID: $rackElevationUUID\n      input: {\n        rackMountUnitIndexes: $rackMountUnitIndexes\n        virtualDeviceUUID: $virtualDeviceUUID\n        label: $label\n        type: $type\n      }\n    ) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AttachDeviceToRackElevation(\n    $rackElevationUUID: UUID!\n    $rackMountUnitIndexes: [Int!]!\n    $virtualDeviceUUID: UUID\n    $label: String\n    $type: RackElevationDeviceType\n  ) {\n    attachDeviceToRackElevation(\n      rackElevationUUID: $rackElevationUUID\n      input: {\n        rackMountUnitIndexes: $rackMountUnitIndexes\n        virtualDeviceUUID: $virtualDeviceUUID\n        label: $label\n        type: $type\n      }\n    ) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DetachRackElevationDevice($UUID: UUID!) {\n    detachDeviceFromRackElevation(UUID: $UUID)\n  }\n',
): (typeof documents)['\n  mutation DetachRackElevationDevice($UUID: UUID!) {\n    detachDeviceFromRackElevation(UUID: $UUID)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RackElevation($UUID: UUID!) {\n    rackElevation(UUID: $UUID) {\n      UUID\n      label\n      rackMountUnitCount\n      networkUUID\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n\n        virtualDevice {\n          UUID\n          label\n          deviceType\n          deviceModel\n          description\n\n          hardwareDevice {\n            serialNumber\n            deviceType\n            deviceModel\n          }\n\n          ... on SwitchVirtualDevice {\n            phyInterfaces {\n              portNumber\n              isEthernet\n              isSFP\n            }\n          }\n        }\n      }\n      notes {\n        rackMountUnitIndexStart\n        rackMountUnitIndexEnd\n        note\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RackElevation($UUID: UUID!) {\n    rackElevation(UUID: $UUID) {\n      UUID\n      label\n      rackMountUnitCount\n      networkUUID\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n\n        virtualDevice {\n          UUID\n          label\n          deviceType\n          deviceModel\n          description\n\n          hardwareDevice {\n            serialNumber\n            deviceType\n            deviceModel\n          }\n\n          ... on SwitchVirtualDevice {\n            phyInterfaces {\n              portNumber\n              isEthernet\n              isSFP\n            }\n          }\n        }\n      }\n      notes {\n        rackMountUnitIndexStart\n        rackMountUnitIndexEnd\n        note\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RackElevations($networkUUID: UUID!) {\n    rackElevations(networkUUID: $networkUUID) {\n      UUID\n      label\n      rackMountUnitCount\n      networkUUID\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n\n        virtualDevice {\n          __typename\n          UUID\n          label\n          deviceType\n          deviceModel\n          description\n          networkUUID\n\n          hardwareDevice {\n            serialNumber\n            deviceType\n            deviceModel\n            isActive\n          }\n\n          ... on SwitchVirtualDevice {\n            phyInterfaces {\n              portNumber\n              isEthernet\n              isSFP\n            }\n          }\n        }\n      }\n      notes {\n        rackMountUnitIndexStart\n        rackMountUnitIndexEnd\n        note\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RackElevations($networkUUID: UUID!) {\n    rackElevations(networkUUID: $networkUUID) {\n      UUID\n      label\n      rackMountUnitCount\n      networkUUID\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n\n        virtualDevice {\n          __typename\n          UUID\n          label\n          deviceType\n          deviceModel\n          description\n          networkUUID\n\n          hardwareDevice {\n            serialNumber\n            deviceType\n            deviceModel\n            isActive\n          }\n\n          ... on SwitchVirtualDevice {\n            phyInterfaces {\n              portNumber\n              isEthernet\n              isSFP\n            }\n          }\n        }\n      }\n      notes {\n        rackMountUnitIndexStart\n        rackMountUnitIndexEnd\n        note\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRackNotes($rackElevationUUID: UUID!, $notes: [RackElevationNoteInput!]) {\n    updateRackElevation(UUID: $rackElevationUUID, input: { notes: $notes }) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRackNotes($rackElevationUUID: UUID!, $notes: [RackElevationNoteInput!]) {\n    updateRackElevation(UUID: $rackElevationUUID, input: { notes: $notes }) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VirtualDevicesForNetwork($networkUUID: UUID!, $filter: DevicesForNetworkFilter) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: $filter) {\n      UUID\n      networkUUID\n      description\n      label\n      deviceType\n      deviceModel\n\n      hardwareDevice {\n        serialNumber\n        deviceType\n        deviceModel\n        isActive\n      }\n    }\n  }\n',
): (typeof documents)['\n  query VirtualDevicesForNetwork($networkUUID: UUID!, $filter: DevicesForNetworkFilter) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: $filter) {\n      UUID\n      networkUUID\n      description\n      label\n      deviceType\n      deviceModel\n\n      hardwareDevice {\n        serialNumber\n        deviceType\n        deviceModel\n        isActive\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRackElevationDevice($UUID: UUID!, $input: UpdateRackElevationDeviceInput!) {\n    updateRackElevationDevice(UUID: $UUID, input: $input) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRackElevationDevice($UUID: UUID!, $input: UpdateRackElevationDeviceInput!) {\n    updateRackElevationDevice(UUID: $UUID, input: $input) {\n      UUID\n      label\n      rackMountUnitCount\n      devices {\n        UUID\n        label\n        rackMountUnitIndexes\n        type\n        virtualDeviceUUID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRackElevation($UUID: UUID!, $input: UpdateRackElevationInput!) {\n    updateRackElevation(UUID: $UUID, input: $input) {\n      UUID\n      label\n      rackMountUnitCount\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRackElevation($UUID: UUID!, $input: UpdateRackElevationInput!) {\n    updateRackElevation(UUID: $UUID, input: $input) {\n      UUID\n      label\n      rackMountUnitCount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteRackElevationMutation($UUID: UUID!) {\n    deleteRackElevation(UUID: $UUID)\n  }\n',
): (typeof documents)['\n  mutation DeleteRackElevationMutation($UUID: UUID!) {\n    deleteRackElevation(UUID: $UUID)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceConfigsQuery($serialNumber: String!) {\n    deviceConfigs(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n      createdAt\n    }\n  }\n',
): (typeof documents)['\n  query DeviceConfigsQuery($serialNumber: String!) {\n    deviceConfigs(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n      createdAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceConfigByVersion($serialNumber: String!, $version: Int!) {\n    deviceConfigByVersion(serialNumber: $serialNumber, version: $version) {\n      version\n      acknowledgedAt\n      configJSON\n    }\n  }\n',
): (typeof documents)['\n  query DeviceConfigByVersion($serialNumber: String!, $version: Int!) {\n    deviceConfigByVersion(serialNumber: $serialNumber, version: $version) {\n      version\n      acknowledgedAt\n      configJSON\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RenderDeviceConfig($serialNumber: String!) {\n    renderDeviceConfig(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n      configJSON\n    }\n  }\n',
): (typeof documents)['\n  mutation RenderDeviceConfig($serialNumber: String!) {\n    renderDeviceConfig(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n      configJSON\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createVirtualDevice($networkUUID: UUID!, $input: CreateVirtualDeviceInput!) {\n    createVirtualDevice(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation createVirtualDevice($networkUUID: UUID!, $input: CreateVirtualDeviceInput!) {\n    createVirtualDevice(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateVirtualDevice($uuid: UUID!, $input: UpdateVirtualDeviceInput!) {\n    updateVirtualDevice(virtualDeviceUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateVirtualDevice($uuid: UUID!, $input: UpdateVirtualDeviceInput!) {\n    updateVirtualDevice(virtualDeviceUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteVirtualDevice($uuid: UUID!) {\n    deleteVirtualDevice(virtualDeviceUUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteVirtualDevice($uuid: UUID!) {\n    deleteVirtualDevice(virtualDeviceUUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createVirtualDevices($networkUUID: UUID!, $input: CreateVirtualDevicesInput!) {\n    createVirtualDevices(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation createVirtualDevices($networkUUID: UUID!, $input: CreateVirtualDevicesInput!) {\n    createVirtualDevices(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateVirtualDevicesIndependently(\n    $networkUUID: UUID!\n    $inputs: [UpdateVirtualDeviceIndependentlyInput!]!\n  ) {\n    updateVirtualDevicesIndependently(networkUUID: $networkUUID, inputs: $inputs) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateVirtualDevicesIndependently(\n    $networkUUID: UUID!\n    $inputs: [UpdateVirtualDeviceIndependentlyInput!]!\n  ) {\n    updateVirtualDevicesIndependently(networkUUID: $networkUUID, inputs: $inputs) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AssignHardwareDeviceToVirtualDevice($serialNumber: String!, $virtualDeviceUUID: UUID!) {\n    assignHardwareDeviceToVirtualDevice(\n      serialNumber: $serialNumber\n      virtualDeviceUUID: $virtualDeviceUUID\n    ) {\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AssignHardwareDeviceToVirtualDevice($serialNumber: String!, $virtualDeviceUUID: UUID!) {\n    assignHardwareDeviceToVirtualDevice(\n      serialNumber: $serialNumber\n      virtualDeviceUUID: $virtualDeviceUUID\n    ) {\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateDevHardwareDevice($input: CreateDevHardwareDeviceInput!) {\n    createDevHardwareDevice(input: $input) {\n      serialNumber\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDevHardwareDevice($input: CreateDevHardwareDeviceInput!) {\n    createDevHardwareDevice(input: $input) {\n      serialNumber\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AssignHardwareDeviceToNetwork($serialNumber: String!, $networkUUID: UUID!) {\n    assignHardwareDeviceToNetwork(serialNumber: $serialNumber, networkUUID: $networkUUID) {\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AssignHardwareDeviceToNetwork($serialNumber: String!, $networkUUID: UUID!) {\n    assignHardwareDeviceToNetwork(serialNumber: $serialNumber, networkUUID: $networkUUID) {\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UnassignHardwareDeviceFromVirtualDevice($serialNumber: String!) {\n    unassignHardwareDeviceFromVirtualDevice(serialNumber: $serialNumber) {\n      serialNumber\n    }\n  }\n',
): (typeof documents)['\n  mutation UnassignHardwareDeviceFromVirtualDevice($serialNumber: String!) {\n    unassignHardwareDeviceFromVirtualDevice(serialNumber: $serialNumber) {\n      serialNumber\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AssignHardwareToVirtualDevices(\n    $networkUUID: UUID!\n    $inputs: [AssignHardwareDeviceToVirtualDeviceInput!]!\n  ) {\n    assignHardwareDevicesToVirtualDevices(networkUUID: $networkUUID, inputs: $inputs) {\n      serialNumber\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AssignHardwareToVirtualDevices(\n    $networkUUID: UUID!\n    $inputs: [AssignHardwareDeviceToVirtualDeviceInput!]!\n  ) {\n    assignHardwareDevicesToVirtualDevices(networkUUID: $networkUUID, inputs: $inputs) {\n      serialNumber\n      virtualDevice {\n        UUID\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query HardwareDevice($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        description\n      }\n    }\n  }\n',
): (typeof documents)['\n  query HardwareDevice($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        description\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateHardwareDevice($serialNumber: String!, $input: UpdateHardwareDeviceInput!) {\n    updateHardwareDevice(serialNumber: $serialNumber, input: $input) {\n      serialNumber\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateHardwareDevice($serialNumber: String!, $input: UpdateHardwareDeviceInput!) {\n    updateHardwareDevice(serialNumber: $serialNumber, input: $input) {\n      serialNumber\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation BlinkLEDsMutation($serialNumber: String!) {\n    rpcBlinkLEDs(serialNumber: $serialNumber, durationSec: 10)\n  }\n',
): (typeof documents)['\n  mutation BlinkLEDsMutation($serialNumber: String!) {\n    rpcBlinkLEDs(serialNumber: $serialNumber, durationSec: 10)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RefreshConfigMutation($serialNumber: String!) {\n    rpcRefreshConfig(serialNumber: $serialNumber)\n  }\n',
): (typeof documents)['\n  mutation RefreshConfigMutation($serialNumber: String!) {\n    rpcRefreshConfig(serialNumber: $serialNumber)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RpcPDUOutletCycleMutation($serialNumber: String!, $input: RPCPDUOutletCycleInput!) {\n    rpcPDUOutletCycle(serialNumber: $serialNumber, input: $input)\n  }\n',
): (typeof documents)['\n  mutation RpcPDUOutletCycleMutation($serialNumber: String!, $input: RPCPDUOutletCycleInput!) {\n    rpcPDUOutletCycle(serialNumber: $serialNumber, input: $input)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LatestDeviceMemoryUsage($serialNumber: String!) {\n    latestDeviceMemoryUsage(serialNumber: $serialNumber) {\n      bytesUsed\n      bytesAvailable\n    }\n  }\n',
): (typeof documents)['\n  query LatestDeviceMemoryUsage($serialNumber: String!) {\n    latestDeviceMemoryUsage(serialNumber: $serialNumber) {\n      bytesUsed\n      bytesAvailable\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceUptime($serialNumber: String!) {\n    deviceUptime(serialNumber: $serialNumber)\n  }\n',
): (typeof documents)['\n  query DeviceUptime($serialNumber: String!) {\n    deviceUptime(serialNumber: $serialNumber)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LatestDeviceCPULoad($serialNumber: String!) {\n    latestDeviceCPULoad(serialNumber: $serialNumber) {\n      usedPercentage\n    }\n  }\n',
): (typeof documents)['\n  query LatestDeviceCPULoad($serialNumber: String!) {\n    latestDeviceCPULoad(serialNumber: $serialNumber) {\n      usedPercentage\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchPoEInfo($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      ... on SwitchHardwareDevice {\n        poeInfo {\n          max\n          used\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SwitchPoEInfo($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      ... on SwitchHardwareDevice {\n        poeInfo {\n          max\n          used\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchVirtualDevicePoEInfo($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      ... on SwitchVirtualDevice {\n        poeInfo {\n          max\n          used\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SwitchVirtualDevicePoEInfo($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      ... on SwitchVirtualDevice {\n        poeInfo {\n          max\n          used\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SystemInfoStatQuery($uuid: UUID!) {\n    systemInfoStatForVirtualDevice(\n      virtualDeviceUUID: $uuid\n      filter: { durationSeconds: 300, limit: 1 }\n    ) {\n      uptime\n      memoryBytesAvailable\n      memoryBytesTotal\n      cpuUsedPercentage\n    }\n  }\n',
): (typeof documents)['\n  query SystemInfoStatQuery($uuid: UUID!) {\n    systemInfoStatForVirtualDevice(\n      virtualDeviceUUID: $uuid\n      filter: { durationSeconds: 300, limit: 1 }\n    ) {\n      uptime\n      memoryBytesAvailable\n      memoryBytesTotal\n      cpuUsedPercentage\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdatePhyInterface($uuid: UUID!, $input: UpdatePhyInterfaceInput!) {\n    updatePhyInterface(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePhyInterface($uuid: UUID!, $input: UpdatePhyInterfaceInput!) {\n    updatePhyInterface(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdatePhyInterfaces(\n    $phyInterfaceUUIDs: [UUID!]!\n    $virtualDeviceUUID: UUID!\n    $input: UpdatePhyInterfaceInput!\n  ) {\n    updatePhyInterfaces(\n      phyInterfaceUUIDs: $phyInterfaceUUIDs\n      virtualDeviceUUID: $virtualDeviceUUID\n      input: $input\n    ) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePhyInterfaces(\n    $phyInterfaceUUIDs: [UUID!]!\n    $virtualDeviceUUID: UUID!\n    $input: UpdatePhyInterfaceInput!\n  ) {\n    updatePhyInterfaces(\n      phyInterfaceUUIDs: $phyInterfaceUUIDs\n      virtualDeviceUUID: $virtualDeviceUUID\n      input: $input\n    ) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FirewallRule($uuid: UUID!) {\n    firewallRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isMeterInternal\n      isEnabled\n      isBidirectional\n      action\n      srcPrefix\n      srcVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      dstPrefix\n      dstVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      srcPortRange {\n        lower\n        upper\n      }\n      dstPortRange {\n        lower\n        upper\n      }\n      protocols\n      tags\n\n      vlanBindings {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        metric\n      }\n      phyInterfaceBindings {\n        phyInterface {\n          __typename\n          UUID\n          label\n          portNumber\n          virtualDevice {\n            __typename\n            UUID\n            label\n            deviceModel\n          }\n        }\n        metric\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FirewallRule($uuid: UUID!) {\n    firewallRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isMeterInternal\n      isEnabled\n      isBidirectional\n      action\n      srcPrefix\n      srcVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      dstPrefix\n      dstVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      srcPortRange {\n        lower\n        upper\n      }\n      dstPortRange {\n        lower\n        upper\n      }\n      protocols\n      tags\n\n      vlanBindings {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        metric\n      }\n      phyInterfaceBindings {\n        phyInterface {\n          __typename\n          UUID\n          label\n          portNumber\n          virtualDevice {\n            __typename\n            UUID\n            label\n            deviceModel\n          }\n        }\n        metric\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateInterVLANCommunicationPermittedPairs(\n    $networkUUID: UUID!\n    $vlanPairs: [VLANPairInput!]!\n  ) {\n    updateInterVLANCommunicationPermittedPairs(networkUUID: $networkUUID, vlanPairs: $vlanPairs) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateInterVLANCommunicationPermittedPairs(\n    $networkUUID: UUID!\n    $vlanPairs: [VLANPairInput!]!\n  ) {\n    updateInterVLANCommunicationPermittedPairs(networkUUID: $networkUUID, vlanPairs: $vlanPairs) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyInterVLANCommunicationFromConfig1ToConfig2($networkUUID: UUID!) {\n    copyInterVLANCommunicationFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyInterVLANCommunicationFromConfig1ToConfig2($networkUUID: UUID!) {\n    copyInterVLANCommunicationFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VLANsForMulticast($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n      vlanID\n      isEnabled\n      isDefault\n      isInternal\n      isMulticastReflectionEnabled\n\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n  }\n',
): (typeof documents)['\n  query VLANsForMulticast($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n      vlanID\n      isEnabled\n      isDefault\n      isInternal\n      isMulticastReflectionEnabled\n\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateVLANsForMulticast(\n    $networkUUID: UUID!\n    $inputs: [UpdateVLANsIndependentlyInput!]!\n  ) {\n    updateVLANsIndependently(networkUUID: $networkUUID, inputs: $inputs) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateVLANsForMulticast(\n    $networkUUID: UUID!\n    $inputs: [UpdateVLANsIndependentlyInput!]!\n  ) {\n    updateVLANsIndependently(networkUUID: $networkUUID, inputs: $inputs) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DHCPRulesWithStaticMappingsForNetworkQuery($networkUUID: UUID!) {\n    dhcpRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      vlan {\n        name\n      }\n      staticMappings {\n        UUID\n        name\n        macAddress\n        ipAddress\n        hostname\n        createdAt\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DHCPRulesWithStaticMappingsForNetworkQuery($networkUUID: UUID!) {\n    dhcpRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      vlan {\n        name\n      }\n      staticMappings {\n        UUID\n        name\n        macAddress\n        ipAddress\n        hostname\n        createdAt\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query OneToOneNATRulesForNetwork($networkUUID: UUID!) {\n    oneToOneNATRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isEnabled\n      networkUUID\n\n      internalIPAddr\n      externalIPAddr\n      externalPhyInterfaceUUID\n      externalPhyInterface {\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query OneToOneNATRulesForNetwork($networkUUID: UUID!) {\n    oneToOneNATRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isEnabled\n      networkUUID\n\n      internalIPAddr\n      externalIPAddr\n      externalPhyInterfaceUUID\n      externalPhyInterface {\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query OneToOneNATRule($uuid: UUID!) {\n    oneToOneNATRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isEnabled\n      networkUUID\n\n      internalIPAddr\n      externalIPAddr\n      externalPhyInterfaceUUID\n      externalPhyInterface {\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query OneToOneNATRule($uuid: UUID!) {\n    oneToOneNATRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isEnabled\n      networkUUID\n\n      internalIPAddr\n      externalIPAddr\n      externalPhyInterfaceUUID\n      externalPhyInterface {\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateOneToOneNATRule($networkUUID: UUID!, $input: CreateOneToOneNATRuleInput!) {\n    createOneToOneNATRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateOneToOneNATRule($networkUUID: UUID!, $input: CreateOneToOneNATRuleInput!) {\n    createOneToOneNATRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateOneToOneNATRule($uuid: UUID!, $input: UpdateOneToOneNATRuleInput!) {\n    updateOneToOneNATRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateOneToOneNATRule($uuid: UUID!, $input: UpdateOneToOneNATRuleInput!) {\n    updateOneToOneNATRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteOneToOneNATRule($uuid: UUID!) {\n    deleteOneToOneNATRule(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteOneToOneNATRule($uuid: UUID!) {\n    deleteOneToOneNATRule(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PortForwardingRule($uuid: UUID!) {\n    portForwardingRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isEnabled\n      protocol\n      localIPAddress\n\n      externalPhyInterfaceUUID\n      externalIPAddress\n\n      externalPort\n      localPort\n    }\n  }\n',
): (typeof documents)['\n  query PortForwardingRule($uuid: UUID!) {\n    portForwardingRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      isEnabled\n      protocol\n      localIPAddress\n\n      externalPhyInterfaceUUID\n      externalIPAddress\n\n      externalPort\n      localPort\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PortForwardingRulesForNetwork($networkUUID: UUID!) {\n    portForwardingRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isEnabled\n      protocol\n      localIPAddress\n\n      externalPhyInterfaceUUID\n      externalIPAddress\n\n      externalPhyInterface {\n        __typename\n        UUID\n        label\n        hardwareLabel\n        portNumber\n        virtualDevice {\n          UUID\n          label\n          deviceModel\n        }\n        internetServicePlan {\n          provider {\n            name\n          }\n        }\n      }\n\n      externalPort\n      localPort\n    }\n  }\n',
): (typeof documents)['\n  query PortForwardingRulesForNetwork($networkUUID: UUID!) {\n    portForwardingRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isEnabled\n      protocol\n      localIPAddress\n\n      externalPhyInterfaceUUID\n      externalIPAddress\n\n      externalPhyInterface {\n        __typename\n        UUID\n        label\n        hardwareLabel\n        portNumber\n        virtualDevice {\n          UUID\n          label\n          deviceModel\n        }\n        internetServicePlan {\n          provider {\n            name\n          }\n        }\n      }\n\n      externalPort\n      localPort\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreatePortForwardingRule($networkUUID: UUID!, $input: CreatePortForwardingRuleInput!) {\n    createPortForwardingRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePortForwardingRule($networkUUID: UUID!, $input: CreatePortForwardingRuleInput!) {\n    createPortForwardingRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdatePortForwardingRule($uuid: UUID!, $input: UpdatePortForwardingRuleInput!) {\n    updatePortForwardingRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePortForwardingRule($uuid: UUID!, $input: UpdatePortForwardingRuleInput!) {\n    updatePortForwardingRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeletePortForwardingRule($uuid: UUID!) {\n    deletePortForwardingRule(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePortForwardingRule($uuid: UUID!) {\n    deletePortForwardingRule(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VLANsForFirewall($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      permittedInterVLANCommunicationVLANs {\n        UUID\n        name\n      }\n\n      dhcpRule {\n        applicationDNSFirewallRules {\n          UUID\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query VLANsForFirewall($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      permittedInterVLANCommunicationVLANs {\n        UUID\n        name\n      }\n\n      dhcpRule {\n        applicationDNSFirewallRules {\n          UUID\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PhyInterfaceLabelFields on PhyInterface {\n    __typename\n    UUID\n    portNumber\n    label\n    hardwareLabel\n    virtualDevice {\n      UUID\n      label\n      deviceModel\n    }\n    internetServicePlan {\n      provider {\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PhyInterfaceLabelFields on PhyInterface {\n    __typename\n    UUID\n    portNumber\n    label\n    hardwareLabel\n    virtualDevice {\n      UUID\n      label\n      deviceModel\n    }\n    internetServicePlan {\n      provider {\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FirewallRulesForNetwork($networkUUID: UUID!) {\n    firewallRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isMeterInternal\n      isEnabled\n      isBidirectional\n      action\n      srcPrefix\n      srcVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      dstPrefix\n      dstVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      srcPortRange {\n        lower\n        upper\n      }\n      dstPortRange {\n        lower\n        upper\n      }\n      protocols\n      tags\n\n      vlanBindings {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        metric\n      }\n      phyInterfaceBindings {\n        phyInterface {\n          ...PhyInterfaceLabelFields\n        }\n        metric\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FirewallRulesForNetwork($networkUUID: UUID!) {\n    firewallRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      isMeterInternal\n      isEnabled\n      isBidirectional\n      action\n      srcPrefix\n      srcVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      dstPrefix\n      dstVLAN {\n        __typename\n        UUID\n        name\n        ipV4ClientAssignmentProtocol\n        ipV4ClientGateway\n        ipV4ClientPrefixLength\n      }\n      srcPortRange {\n        lower\n        upper\n      }\n      dstPortRange {\n        lower\n        upper\n      }\n      protocols\n      tags\n\n      vlanBindings {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        metric\n      }\n      phyInterfaceBindings {\n        phyInterface {\n          ...PhyInterfaceLabelFields\n        }\n        metric\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateFirewallRuleBindingsForVLAN($vlanUUID: UUID!, $orderedRuleUUIDs: [UUID!]!) {\n    bindFirewallRulesToVLAN(vlanUUID: $vlanUUID, orderedRuleUUIDs: $orderedRuleUUIDs) {\n      metric\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateFirewallRuleBindingsForVLAN($vlanUUID: UUID!, $orderedRuleUUIDs: [UUID!]!) {\n    bindFirewallRulesToVLAN(vlanUUID: $vlanUUID, orderedRuleUUIDs: $orderedRuleUUIDs) {\n      metric\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateFirewallRuleBindingsForPhyInterface(\n    $phyInterfaceUUID: UUID!\n    $orderedRuleUUIDs: [UUID!]!\n  ) {\n    bindFirewallRulesToPhyInterface(\n      phyInterfaceUUID: $phyInterfaceUUID\n      orderedRuleUUIDs: $orderedRuleUUIDs\n    ) {\n      metric\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateFirewallRuleBindingsForPhyInterface(\n    $phyInterfaceUUID: UUID!\n    $orderedRuleUUIDs: [UUID!]!\n  ) {\n    bindFirewallRulesToPhyInterface(\n      phyInterfaceUUID: $phyInterfaceUUID\n      orderedRuleUUIDs: $orderedRuleUUIDs\n    ) {\n      metric\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UplinkPhyInterfacesForNetwork($networkUUID: UUID!) {\n    uplinkPhyInterfacesForNetwork(networkUUID: $networkUUID) {\n      __typename\n      ...PhyInterfaceLabelFields\n      UUID\n      label\n      isEnabled\n      portNumber\n      hardwareLabel\n      virtualDevice {\n        __typename\n        UUID\n        label\n        deviceModel\n        hardwareDevice {\n          isActive\n          isConnectedToBackend\n          serialNumber\n        }\n        ... on ControllerVirtualDevice {\n          highAvailability {\n            role\n            status\n          }\n        }\n      }\n      internetServicePlan {\n        provider {\n          name\n        }\n      }\n      ipv4ClientAddresses\n      isUplinkActive\n      hasWANActivity\n      uplinkExternalAddresses\n    }\n  }\n',
): (typeof documents)['\n  query UplinkPhyInterfacesForNetwork($networkUUID: UUID!) {\n    uplinkPhyInterfacesForNetwork(networkUUID: $networkUUID) {\n      __typename\n      ...PhyInterfaceLabelFields\n      UUID\n      label\n      isEnabled\n      portNumber\n      hardwareLabel\n      virtualDevice {\n        __typename\n        UUID\n        label\n        deviceModel\n        hardwareDevice {\n          isActive\n          isConnectedToBackend\n          serialNumber\n        }\n        ... on ControllerVirtualDevice {\n          highAvailability {\n            role\n            status\n          }\n        }\n      }\n      internetServicePlan {\n        provider {\n          name\n        }\n      }\n      ipv4ClientAddresses\n      isUplinkActive\n      hasWANActivity\n      uplinkExternalAddresses\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateFirewallRule($networkUUID: UUID!, $input: CreateFirewallRuleInput!) {\n    createFirewallRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateFirewallRule($networkUUID: UUID!, $input: CreateFirewallRuleInput!) {\n    createFirewallRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateFirewallRule($uuid: UUID!, $input: UpdateFirewallRuleInput!) {\n    updateFirewallRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateFirewallRule($uuid: UUID!, $input: UpdateFirewallRuleInput!) {\n    updateFirewallRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteFirewallRule($uuid: UUID!) {\n    deleteFirewallRule(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteFirewallRule($uuid: UUID!) {\n    deleteFirewallRule(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FloorPlansForNetwork($networkUUID: UUID!) {\n    floorPlansForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      description\n      imageS3Key\n      imageDownloadURL\n    }\n  }\n',
): (typeof documents)['\n  query FloorPlansForNetwork($networkUUID: UUID!) {\n    floorPlansForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      description\n      imageS3Key\n      imageDownloadURL\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FloorPlan($UUID: UUID!) {\n    floorPlan(UUID: $UUID) {\n      UUID\n      label\n      description\n      imageS3Key\n    }\n  }\n',
): (typeof documents)['\n  query FloorPlan($UUID: UUID!) {\n    floorPlan(UUID: $UUID) {\n      UUID\n      label\n      description\n      imageS3Key\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateFloorPlan($networkUUID: UUID!, $input: CreateFloorPlanInput!) {\n    createFloorPlan(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateFloorPlan($networkUUID: UUID!, $input: CreateFloorPlanInput!) {\n    createFloorPlan(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateFloorPlan($UUID: UUID!, $input: UpdateFloorPlanInput!) {\n    updateFloorPlan(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateFloorPlan($UUID: UUID!, $input: UpdateFloorPlanInput!) {\n    updateFloorPlan(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteFloorPlan($UUID: UUID!) {\n    deleteFloorPlan(UUID: $UUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteFloorPlan($UUID: UUID!) {\n    deleteFloorPlan(UUID: $UUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query APBSSIDsQuery($virtualDeviceUUID: UUID!) {\n    virtualDevice(UUID: $virtualDeviceUUID) {\n      hardwareDevice {\n        __typename\n\n        ... on AccessPointHardwareDevice {\n          BSSIDs {\n            SSID {\n              ssid\n            }\n            BSSID\n            isActive\n            radioBand\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query APBSSIDsQuery($virtualDeviceUUID: UUID!) {\n    virtualDevice(UUID: $virtualDeviceUUID) {\n      hardwareDevice {\n        __typename\n\n        ... on AccessPointHardwareDevice {\n          BSSIDs {\n            SSID {\n              ssid\n            }\n            BSSID\n            isActive\n            radioBand\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query APBootHistoryQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n    }\n  }\n',
): (typeof documents)['\n  query APBootHistoryQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RebootAccessPoint($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n',
): (typeof documents)['\n  mutation RebootAccessPoint($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query APScanDataQuery($networkUUID: UUID!, $virtualDeviceUUID: UUID!, $durationSeconds: Int!) {\n    statsForVirtualDevices(\n      networkUUID: $networkUUID\n      filter: {\n        virtualDeviceUUIDs: [$virtualDeviceUUID]\n        types: [WOS_SCAN_TABLE]\n        durationSeconds: $durationSeconds\n      }\n    ) {\n      observedAt\n      virtualDeviceUUID\n      rawData\n    }\n  }\n',
): (typeof documents)['\n  query APScanDataQuery($networkUUID: UUID!, $virtualDeviceUUID: UUID!, $durationSeconds: Int!) {\n    statsForVirtualDevices(\n      networkUUID: $networkUUID\n      filter: {\n        virtualDeviceUUIDs: [$virtualDeviceUUID]\n        types: [WOS_SCAN_TABLE]\n        durationSeconds: $durationSeconds\n      }\n    ) {\n      observedAt\n      virtualDeviceUUID\n      rawData\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PDUInsightsQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      serialNumber\n      macAddress\n      deviceModel\n      tunnelIPAddress\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      virtualDevice {\n        __typename\n        UUID\n        label\n        description\n        networkUUID\n        nosVersion {\n          id\n          version\n          buildName\n        }\n        pendingNosVersion {\n          nosVersion {\n            version\n          }\n          scheduledAt\n        }\n        ... on PowerDistributionUnitVirtualDevice {\n          ipAddress\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query PDUInsightsQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      serialNumber\n      macAddress\n      deviceModel\n      tunnelIPAddress\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      virtualDevice {\n        __typename\n        UUID\n        label\n        description\n        networkUUID\n        nosVersion {\n          id\n          version\n          buildName\n        }\n        pendingNosVersion {\n          nosVersion {\n            version\n          }\n          scheduledAt\n        }\n        ... on PowerDistributionUnitVirtualDevice {\n          ipAddress\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RebootPowerDistributionUnit($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n',
): (typeof documents)['\n  mutation RebootPowerDistributionUnit($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FallbackPowerDistributionUnitHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n    }\n  }\n',
): (typeof documents)['\n  query FallbackPowerDistributionUnitHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PowerDistributionUnitQuery($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      networkUUID\n      isConsoleEnabled\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        buildName\n        version\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      uptime\n\n      ... on PowerDistributionUnitVirtualDevice {\n        ipAddress\n      }\n\n      hardwareDevice {\n        __typename\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        serialNumber\n        deviceType\n        bootHistory(count: 20) {\n          bootCount\n          buildName\n          rebootReason\n          createdAt\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query PowerDistributionUnitQuery($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      networkUUID\n      isConsoleEnabled\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        buildName\n        version\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      uptime\n\n      ... on PowerDistributionUnitVirtualDevice {\n        ipAddress\n      }\n\n      hardwareDevice {\n        __typename\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        serialNumber\n        deviceType\n        bootHistory(count: 20) {\n          bootCount\n          buildName\n          rebootReason\n          createdAt\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PowerDistributionUnitsQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(\n      networkUUID: $networkUUID\n      filter: { deviceType: POWER_DISTRIBUTION_UNIT }\n    ) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      uptime\n\n      ... on PowerDistributionUnitVirtualDevice {\n        ipAddress\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n      }\n    }\n  }\n',
): (typeof documents)['\n  query PowerDistributionUnitsQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(\n      networkUUID: $networkUUID\n      filter: { deviceType: POWER_DISTRIBUTION_UNIT }\n    ) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      uptime\n\n      ... on PowerDistributionUnitVirtualDevice {\n        ipAddress\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {\n    deviceHeartbeatForDevice(serialNumber: $serialNumber) {\n      token\n    }\n  }\n',
): (typeof documents)['\n  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {\n    deviceHeartbeatForDevice(serialNumber: $serialNumber) {\n      token\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query OutletsQuery($virtualDeviceUUID: UUID!) {\n    outletInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      label\n      outletNumber\n      isEnabled\n      powerCycleDelaySec\n      description\n      currentAmps\n      powerWatts\n      connectedVirtualDevice {\n        UUID\n        label\n        deviceType\n      }\n      connectedVirtualDeviceUUID\n    }\n  }\n',
): (typeof documents)['\n  query OutletsQuery($virtualDeviceUUID: UUID!) {\n    outletInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      label\n      outletNumber\n      isEnabled\n      powerCycleDelaySec\n      description\n      currentAmps\n      powerWatts\n      connectedVirtualDevice {\n        UUID\n        label\n        deviceType\n      }\n      connectedVirtualDeviceUUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateOutletInterfaceMutation($uuid: UUID!, $input: UpdateOutletInterfaceInput!) {\n    updateOutletInterface(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateOutletInterfaceMutation($uuid: UUID!, $input: UpdateOutletInterfaceInput!) {\n    updateOutletInterface(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query hostMonitoring(\n    $networkUUID: UUID!\n    $hostIPs: [String!]!\n    $durationSeconds: Int!\n    $stepSeconds: Int!\n  ) {\n    hostMonitoringForNetwork(\n      networkUUID: $networkUUID\n      filter: { durationSeconds: $durationSeconds, stepSeconds: $stepSeconds }\n      hostIPs: $hostIPs\n    ) {\n      values {\n        hostIPAddr\n        virtualDeviceUUID\n        timestamp\n        value\n        status\n        srcEthPortNum\n      }\n    }\n  }\n',
): (typeof documents)['\n  query hostMonitoring(\n    $networkUUID: UUID!\n    $hostIPs: [String!]!\n    $durationSeconds: Int!\n    $stepSeconds: Int!\n  ) {\n    hostMonitoringForNetwork(\n      networkUUID: $networkUUID\n      filter: { durationSeconds: $durationSeconds, stepSeconds: $stepSeconds }\n      hostIPs: $hostIPs\n    ) {\n      values {\n        hostIPAddr\n        virtualDeviceUUID\n        timestamp\n        value\n        status\n        srcEthPortNum\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RebootSecurityAppliance($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n',
): (typeof documents)['\n  mutation RebootSecurityAppliance($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ControllerFragment on VirtualDevice {\n    __typename\n    UUID\n    label\n    description\n    deviceType\n    deviceModel\n    isConsoleEnabled\n    networkUUID\n    uptime\n    nosVersion {\n      id\n      version\n      buildName\n    }\n    pendingNosVersion {\n      nosVersion {\n        version\n      }\n      scheduledAt\n    }\n\n    hardwareDevice {\n      __typename\n      deviceType\n      serialNumber\n      macAddress\n      tunnelIPAddress\n      deviceModel\n      isActive\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      uptime\n      bootHistory(count: 20) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      isDev @include(if: $includeIsDev)\n    }\n\n    ... on ControllerVirtualDevice {\n      highAvailability {\n        role\n        status\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ControllerFragment on VirtualDevice {\n    __typename\n    UUID\n    label\n    description\n    deviceType\n    deviceModel\n    isConsoleEnabled\n    networkUUID\n    uptime\n    nosVersion {\n      id\n      version\n      buildName\n    }\n    pendingNosVersion {\n      nosVersion {\n        version\n      }\n      scheduledAt\n    }\n\n    hardwareDevice {\n      __typename\n      deviceType\n      serialNumber\n      macAddress\n      tunnelIPAddress\n      deviceModel\n      isActive\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      uptime\n      bootHistory(count: 20) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      isDev @include(if: $includeIsDev)\n    }\n\n    ... on ControllerVirtualDevice {\n      highAvailability {\n        role\n        status\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ControllersQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: CONTROLLER }) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      uptime\n      nosVersion {\n        id\n        version\n        buildName\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ControllersQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: CONTROLLER }) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      uptime\n      nosVersion {\n        id\n        version\n        buildName\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ControllerQuery($uuid: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        phyInterfaces {\n          ...ControllerPhyInterfaceFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ControllerQuery($uuid: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        phyInterfaces {\n          ...ControllerPhyInterfaceFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query HAConfigForNetworkQuery($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      highAvailabilityConfig {\n        primaryPhyInterface {\n          UUID\n          portNumber\n          label\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            hardwareDevice {\n              isConnectedToBackend\n            }\n          }\n        }\n        backupPhyInterface {\n          UUID\n          portNumber\n          label\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            hardwareDevice {\n              isConnectedToBackend\n            }\n          }\n        }\n        advertisementVLAN {\n          UUID\n          vlanID\n          name\n        }\n        preemption\n        advertisementIntervalMs\n      }\n    }\n  }\n',
): (typeof documents)['\n  query HAConfigForNetworkQuery($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      highAvailabilityConfig {\n        primaryPhyInterface {\n          UUID\n          portNumber\n          label\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            hardwareDevice {\n              isConnectedToBackend\n            }\n          }\n        }\n        backupPhyInterface {\n          UUID\n          portNumber\n          label\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            hardwareDevice {\n              isConnectedToBackend\n            }\n          }\n        }\n        advertisementVLAN {\n          UUID\n          vlanID\n          name\n        }\n        preemption\n        advertisementIntervalMs\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ControllerVirtualDeviceFragment on ControllerVirtualDevice {\n    highAvailability {\n      role\n      status\n    }\n\n    phyInterfaces {\n      UUID\n      isEnabled\n      isSFP\n      isTrunkPort\n      isUplink\n      label\n      portNumber\n      portSpeedMbps\n\n      connectedDevices {\n        hardwareDevice {\n          deviceModel\n          deviceType\n          virtualDevice {\n            UUID\n            label\n            deviceType\n          }\n        }\n      }\n\n      hasWANActivity\n      internetServicePlan {\n        controllerIP\n        provider {\n          name\n          logoURL\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ControllerVirtualDeviceFragment on ControllerVirtualDevice {\n    highAvailability {\n      role\n      status\n    }\n\n    phyInterfaces {\n      UUID\n      isEnabled\n      isSFP\n      isTrunkPort\n      isUplink\n      label\n      portNumber\n      portSpeedMbps\n\n      connectedDevices {\n        hardwareDevice {\n          deviceModel\n          deviceType\n          virtualDevice {\n            UUID\n            label\n            deviceType\n          }\n        }\n      }\n\n      hasWANActivity\n      internetServicePlan {\n        controllerIP\n        provider {\n          name\n          logoURL\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ControllerForSecurityApplianceQuery($uuid: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        ...ControllerVirtualDeviceFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ControllerForSecurityApplianceQuery($uuid: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        ...ControllerVirtualDeviceFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ControllersForSecurityApplianceQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: CONTROLLER }) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        ...ControllerVirtualDeviceFragment\n        phyInterfaces {\n          ...ControllerPhyInterfaceFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ControllersForSecurityApplianceQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: CONTROLLER }) {\n      ...ControllerFragment\n\n      ... on ControllerVirtualDevice {\n        ...ControllerVirtualDeviceFragment\n        phyInterfaces {\n          ...ControllerPhyInterfaceFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceConfigVersion($serialNumber: String!) {\n    latestDeviceConfig(serialNumber: $serialNumber) {\n      version\n    }\n    latestAcknowledgedDeviceConfig(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n    }\n  }\n',
): (typeof documents)['\n  query DeviceConfigVersion($serialNumber: String!) {\n    latestDeviceConfig(serialNumber: $serialNumber) {\n      version\n    }\n    latestAcknowledgedDeviceConfig(serialNumber: $serialNumber) {\n      version\n      acknowledgedAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FallbackSwitchHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n    }\n  }\n',
): (typeof documents)['\n  query FallbackSwitchHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PhyInterfacesForVirtualDevice($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      portNumber\n      label\n      isUplink\n    }\n  }\n',
): (typeof documents)['\n  query PhyInterfacesForVirtualDevice($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      portNumber\n      label\n      isUplink\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateHighAvailabilityConfig(\n    $networkUUID: UUID!\n    $primaryPhyInterfaceUUID: UUID!\n    $backupPhyInterfaceUUID: UUID!\n    $input: CreateHighAvailabilityPairInput!\n  ) {\n    createHighAvailabilityControllersConfiguration(\n      networkUUID: $networkUUID\n      primaryPhyInterfaceUUID: $primaryPhyInterfaceUUID\n      backupPhyInterfaceUUID: $backupPhyInterfaceUUID\n      input: $input\n    ) {\n      advertisementVLAN {\n        networkUUID\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateHighAvailabilityConfig(\n    $networkUUID: UUID!\n    $primaryPhyInterfaceUUID: UUID!\n    $backupPhyInterfaceUUID: UUID!\n    $input: CreateHighAvailabilityPairInput!\n  ) {\n    createHighAvailabilityControllersConfiguration(\n      networkUUID: $networkUUID\n      primaryPhyInterfaceUUID: $primaryPhyInterfaceUUID\n      backupPhyInterfaceUUID: $backupPhyInterfaceUUID\n      input: $input\n    ) {\n      advertisementVLAN {\n        networkUUID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteHighAvailabilityConfig($networkUUID: UUID!) {\n    deleteHighAvailabilityControllersConfiguration(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteHighAvailabilityConfig($networkUUID: UUID!) {\n    deleteHighAvailabilityControllersConfiguration(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceLastDayCellularUsage($serialNumber: String!) {\n    deviceLastDayCellularUsage(serialNumber: $serialNumber) {\n      uploadBytes\n      downloadBytes\n    }\n  }\n',
): (typeof documents)['\n  query DeviceLastDayCellularUsage($serialNumber: String!) {\n    deviceLastDayCellularUsage(serialNumber: $serialNumber) {\n      uploadBytes\n      downloadBytes\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceCellularStatus($serialNumber: String!) {\n    deviceCellularStatus(serialNumber: $serialNumber) {\n      managerModemState\n      observedAt\n    }\n  }\n',
): (typeof documents)['\n  query DeviceCellularStatus($serialNumber: String!) {\n    deviceCellularStatus(serialNumber: $serialNumber) {\n      managerModemState\n      observedAt\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DHCPRulesForNetworkQuery($networkUUID: UUID!) {\n    dhcpRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      vlan {\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DHCPRulesForNetworkQuery($networkUUID: UUID!) {\n    dhcpRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      vlan {\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ControllerMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {\n    controllerPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        portNumber\n        dropsPerSecond\n        txErrPerSecond\n        rxErrPerSecond\n        totalRxBytesPerSecond\n        totalTxBytesPerSecond\n        multicastRxPacketsPerSecond\n        multicastTxPacketsPerSecond\n        broadcastRxPacketsPerSecond\n        broadcastTxPacketsPerSecond\n      }\n    }\n    controllerDNSRequestRates(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        uuid\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ControllerMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {\n    controllerPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        portNumber\n        dropsPerSecond\n        txErrPerSecond\n        rxErrPerSecond\n        totalRxBytesPerSecond\n        totalTxBytesPerSecond\n        multicastRxPacketsPerSecond\n        multicastTxPacketsPerSecond\n        broadcastRxPacketsPerSecond\n        broadcastTxPacketsPerSecond\n      }\n    }\n    controllerDNSRequestRates(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        uuid\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ControllerPhyInterfaceFields on PhyInterface {\n    UUID\n    virtualDeviceUUID\n\n    virtualDevice {\n      UUID\n      label\n      deviceModel\n    }\n\n    portNumber\n    label\n    description\n    hardwareLabel\n    isEnabled\n    isTrunkPort\n    isUplink\n    isEthernet\n    isSFP\n    hasWANActivity\n    forcedPortSpeedMbps\n    portSpeedMbps\n    maxSpeedMbps\n    ipv4ClientAssignmentProtocol\n    ipv4ClientGateway\n    ipv4ClientPrefixLength\n    ipv4ClientAddresses\n    uplinkPriority\n    nativeVLAN {\n      UUID\n      vlanID\n      name\n    }\n    connectedDevices {\n      macAddress\n      hardwareDevice {\n        serialNumber\n        isConnectedToBackend\n        isActive\n        deviceType\n        deviceModel\n        virtualDevice {\n          __typename\n          deviceType\n          UUID\n          label\n        }\n      }\n    }\n\n    uplinkExternalAddresses\n    hasWANActivity\n    isUplinkActive\n    uplinkVLANID\n    internetServicePlan {\n      UUID\n      controllerIP\n      provider {\n        UUID\n        name\n        logoURL\n      }\n    }\n    uplinkGatewayMACAddress\n  }\n',
): (typeof documents)['\n  fragment ControllerPhyInterfaceFields on PhyInterface {\n    UUID\n    virtualDeviceUUID\n\n    virtualDevice {\n      UUID\n      label\n      deviceModel\n    }\n\n    portNumber\n    label\n    description\n    hardwareLabel\n    isEnabled\n    isTrunkPort\n    isUplink\n    isEthernet\n    isSFP\n    hasWANActivity\n    forcedPortSpeedMbps\n    portSpeedMbps\n    maxSpeedMbps\n    ipv4ClientAssignmentProtocol\n    ipv4ClientGateway\n    ipv4ClientPrefixLength\n    ipv4ClientAddresses\n    uplinkPriority\n    nativeVLAN {\n      UUID\n      vlanID\n      name\n    }\n    connectedDevices {\n      macAddress\n      hardwareDevice {\n        serialNumber\n        isConnectedToBackend\n        isActive\n        deviceType\n        deviceModel\n        virtualDevice {\n          __typename\n          deviceType\n          UUID\n          label\n        }\n      }\n    }\n\n    uplinkExternalAddresses\n    hasWANActivity\n    isUplinkActive\n    uplinkVLANID\n    internetServicePlan {\n      UUID\n      controllerIP\n      provider {\n        UUID\n        name\n        logoURL\n      }\n    }\n    uplinkGatewayMACAddress\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PhyInterfacesForSecurityAppliance($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      ...ControllerPhyInterfaceFields\n    }\n  }\n',
): (typeof documents)['\n  query PhyInterfacesForSecurityAppliance($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      ...ControllerPhyInterfaceFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SecurityAppliancePortStatsQuery($virtualDeviceUUID: UUID!) {\n    controllerPortStats(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n      drops\n      txErr\n      rxErr\n      ipv4\n      ipv6\n      punts\n      totalRxBytes\n      totalTxBytes\n      totalRxPackets\n      totalTxPackets\n      hwUp\n    }\n  }\n',
): (typeof documents)['\n  query SecurityAppliancePortStatsQuery($virtualDeviceUUID: UUID!) {\n    controllerPortStats(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n      drops\n      txErr\n      rxErr\n      ipv4\n      ipv6\n      punts\n      totalRxBytes\n      totalTxBytes\n      totalRxPackets\n      totalTxPackets\n      hwUp\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UptimeStatsForNetwork($networkUUID: UUID!, $lookBack: Int!, $stepSeconds: Int!) {\n    networkUplinkQuality(\n      networkUUID: $networkUUID\n      filter: { durationSeconds: $lookBack, stepSeconds: $stepSeconds }\n    ) {\n      values {\n        phyInterfaceUUID\n        timestamp\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UptimeStatsForNetwork($networkUUID: UUID!, $lookBack: Int!, $stepSeconds: Int!) {\n    networkUplinkQuality(\n      networkUUID: $networkUUID\n      filter: { durationSeconds: $lookBack, stepSeconds: $stepSeconds }\n    ) {\n      values {\n        phyInterfaceUUID\n        timestamp\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation PortCableTestMutation($serialNumber: String!, $portNumber: Int!) {\n    rpcPortCableTest(serialNumber: $serialNumber, portNumber: $portNumber) {\n      pairA {\n        status\n      }\n      pairB {\n        status\n      }\n      pairC {\n        status\n      }\n      pairD {\n        status\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation PortCableTestMutation($serialNumber: String!, $portNumber: Int!) {\n    rpcPortCableTest(serialNumber: $serialNumber, portNumber: $portNumber) {\n      pairA {\n        status\n      }\n      pairB {\n        status\n      }\n      pairC {\n        status\n      }\n      pairD {\n        status\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PatchPanelLayoutSwitches($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      label\n      company {\n        name\n      }\n      virtualDevices(filter: { deviceType: SWITCH }) {\n        UUID\n        __typename\n        ... on SwitchVirtualDevice {\n          label\n          phyInterfaces {\n            UUID\n            portNumber\n            label\n            description\n            isTrunkPort\n\n            allowedVLANs {\n              name\n              vlanID\n            }\n            nativeVLAN {\n              name\n              vlanID\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query PatchPanelLayoutSwitches($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      label\n      company {\n        name\n      }\n      virtualDevices(filter: { deviceType: SWITCH }) {\n        UUID\n        __typename\n        ... on SwitchVirtualDevice {\n          label\n          phyInterfaces {\n            UUID\n            portNumber\n            label\n            description\n            isTrunkPort\n\n            allowedVLANs {\n              name\n              vlanID\n            }\n            nativeVLAN {\n              name\n              vlanID\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchBootHistoryQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SwitchBootHistoryQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query InsightsQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      serialNumber\n      macAddress\n      deviceType\n      deviceModel\n      tunnelIPAddress\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      ... on SwitchHardwareDevice {\n        stpInfo {\n          isRootBridge\n          rootBridgeMACAddress\n        }\n        ipAddress\n      }\n      virtualDevice {\n        __typename\n        UUID\n        label\n        deviceType\n        deviceModel\n        description\n        networkUUID\n        nosVersion {\n          id\n          version\n          buildName\n        }\n        pendingNosVersion {\n          nosVersion {\n            version\n          }\n          scheduledAt\n        }\n        ... on SwitchVirtualDevice {\n          switchProfile {\n            stpBridgePriority\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query InsightsQuery($serialNumber: String!, $count: Int) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      __typename\n      serialNumber\n      macAddress\n      deviceType\n      deviceModel\n      tunnelIPAddress\n      isConnectedToBackend\n      disconnectedFromBackendAt\n      bootHistory(count: $count) {\n        bootCount\n        buildName\n        rebootReason\n        createdAt\n      }\n      ... on SwitchHardwareDevice {\n        stpInfo {\n          isRootBridge\n          rootBridgeMACAddress\n        }\n        ipAddress\n      }\n      virtualDevice {\n        __typename\n        UUID\n        label\n        deviceType\n        deviceModel\n        description\n        networkUUID\n        nosVersion {\n          id\n          version\n          buildName\n        }\n        pendingNosVersion {\n          nosVersion {\n            version\n          }\n          scheduledAt\n        }\n        ... on SwitchVirtualDevice {\n          switchProfile {\n            stpBridgePriority\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchesRootQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      UUID\n      hardwareDevice {\n        __typename\n        serialNumber\n        ... on SwitchHardwareDevice {\n          stpInfo {\n            isRootBridge\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SwitchesRootQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      UUID\n      hardwareDevice {\n        __typename\n        serialNumber\n        ... on SwitchHardwareDevice {\n          stpInfo {\n            isRootBridge\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchMetricsQuery(\n    $input: MetricsSerialNumberDirectionInput!\n    $filter: MetricsFilterInput!\n  ) {\n    switchThroughputPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n    switchDiscardRatesPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n    switchErrorRatesPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SwitchMetricsQuery(\n    $input: MetricsSerialNumberDirectionInput!\n    $filter: MetricsFilterInput!\n  ) {\n    switchThroughputPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n    switchDiscardRatesPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n    switchErrorRatesPerPort(input: $input, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on SwitchPortMetricsValue {\n          port\n          direction\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchPortNumbers($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n    }\n  }\n',
): (typeof documents)['\n  query SwitchPortNumbers($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UnassignedHardwareForNetwork($networkUUID: UUID!) {\n    unassignedHardwareDevicesForNetwork(networkUUID: $networkUUID) {\n      virtualDevice {\n        UUID\n        label\n      }\n      unassignedHardwareConnections {\n        phyInterface {\n          UUID\n          portNumber\n        }\n        unassignedHardwareDevice {\n          serialNumber\n          deviceType\n          deviceModel\n          macAddress\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UnassignedHardwareForNetwork($networkUUID: UUID!) {\n    unassignedHardwareDevicesForNetwork(networkUUID: $networkUUID) {\n      virtualDevice {\n        UUID\n        label\n      }\n      unassignedHardwareConnections {\n        phyInterface {\n          UUID\n          portNumber\n        }\n        unassignedHardwareDevice {\n          serialNumber\n          deviceType\n          deviceModel\n          macAddress\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RebootSwitch($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n',
): (typeof documents)['\n  mutation RebootSwitch($serialNumber: String!) {\n    rpcRebootDevice(serialNumber: $serialNumber)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchQuery($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      networkUUID\n      isConsoleEnabled\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        buildName\n        version\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on SwitchVirtualDevice {\n        switchProfile {\n          UUID\n          stpBridgePriority\n        }\n\n        phyInterfaces {\n          UUID\n          isEthernet\n          isSFP\n          isUplink\n          portSpeedMbps\n          maxSpeedMbps\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        serialNumber\n        deviceType\n        bootHistory(count: 1) {\n          buildName\n        }\n\n        ... on SwitchHardwareDevice {\n          ipAddress\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SwitchQuery($uuid: UUID!) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      networkUUID\n      isConsoleEnabled\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        buildName\n        version\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on SwitchVirtualDevice {\n        switchProfile {\n          UUID\n          stpBridgePriority\n        }\n\n        phyInterfaces {\n          UUID\n          isEthernet\n          isSFP\n          isUplink\n          portSpeedMbps\n          maxSpeedMbps\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        serialNumber\n        deviceType\n        bootHistory(count: 1) {\n          buildName\n        }\n\n        ... on SwitchHardwareDevice {\n          ipAddress\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchesQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      __typename\n      UUID\n      uptime\n      label\n      description\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on SwitchVirtualDevice {\n        switchProfile {\n          stpBridgePriority\n        }\n\n        phyInterfaces {\n          UUID\n          isEthernet\n          isSFP\n          isUplink\n          portSpeedMbps\n          maxSpeedMbps\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n\n        ... on SwitchHardwareDevice {\n          stpInfo {\n            isRootBridge\n            rootBridgePortNumber\n          }\n          ipAddress\n          isInCurrentControllerMACTable\n          uptime\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SwitchesQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      __typename\n      UUID\n      uptime\n      label\n      description\n      deviceType\n      deviceModel\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on SwitchVirtualDevice {\n        switchProfile {\n          stpBridgePriority\n        }\n\n        phyInterfaces {\n          UUID\n          isEthernet\n          isSFP\n          isUplink\n          portSpeedMbps\n          maxSpeedMbps\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n\n        ... on SwitchHardwareDevice {\n          stpInfo {\n            isRootBridge\n            rootBridgePortNumber\n          }\n          ipAddress\n          isInCurrentControllerMACTable\n          uptime\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PortsQuery($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      virtualDeviceUUID\n      description\n      forcedPortSpeedMbps\n      isBoundToAllVLANs\n      isEnabled\n      isPOEEnabled\n      isSTPEdgePortEnabled\n      isSTPEnabled\n      isTrunkPort\n      isUplink\n      isLoopbackDetected\n      isBlockedDueToFlaps\n      isSTPForwarding\n      isIngressFilteringEnabled\n      frameAcceptTypeFilter\n      label\n      portNumber\n      isEthernet\n      isSFP\n      powerDraw\n      portSpeedMbps\n      maxSpeedMbps\n      stpPortRole\n      isStormControlEnabled\n      stormControlBroadcastTrafficPercent\n      stormControlUnknownMulticastTrafficPercent\n      stormControlUnknownUnicastTrafficPercent\n      allowedVLANs {\n        UUID\n        name\n        vlanID\n      }\n      nativeVLAN {\n        UUID\n        name\n        vlanID\n      }\n      throughputLastDay {\n        direction\n        value\n      }\n      connectedDevices {\n        macAddress\n        hardwareDevice {\n          serialNumber\n          isConnectedToBackend\n          isActive\n          deviceType\n          virtualDevice {\n            __typename\n            deviceType\n            UUID\n            label\n          }\n        }\n      }\n      sfpModuleInfo {\n        vendor\n        serialNumber\n        partName\n        moduleSpeed\n        moduleType\n      }\n    }\n  }\n',
): (typeof documents)['\n  query PortsQuery($virtualDeviceUUID: UUID!) {\n    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      UUID\n      virtualDeviceUUID\n      description\n      forcedPortSpeedMbps\n      isBoundToAllVLANs\n      isEnabled\n      isPOEEnabled\n      isSTPEdgePortEnabled\n      isSTPEnabled\n      isTrunkPort\n      isUplink\n      isLoopbackDetected\n      isBlockedDueToFlaps\n      isSTPForwarding\n      isIngressFilteringEnabled\n      frameAcceptTypeFilter\n      label\n      portNumber\n      isEthernet\n      isSFP\n      powerDraw\n      portSpeedMbps\n      maxSpeedMbps\n      stpPortRole\n      isStormControlEnabled\n      stormControlBroadcastTrafficPercent\n      stormControlUnknownMulticastTrafficPercent\n      stormControlUnknownUnicastTrafficPercent\n      allowedVLANs {\n        UUID\n        name\n        vlanID\n      }\n      nativeVLAN {\n        UUID\n        name\n        vlanID\n      }\n      throughputLastDay {\n        direction\n        value\n      }\n      connectedDevices {\n        macAddress\n        hardwareDevice {\n          serialNumber\n          isConnectedToBackend\n          isActive\n          deviceType\n          virtualDevice {\n            __typename\n            deviceType\n            UUID\n            label\n          }\n        }\n      }\n      sfpModuleInfo {\n        vendor\n        serialNumber\n        partName\n        moduleSpeed\n        moduleType\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MACTableQuery($virtualDeviceUUID: UUID!) {\n    switchMACTable(virtualDeviceUUID: $virtualDeviceUUID) {\n      updatedAt\n      macAddress\n      port\n      vlan {\n        UUID\n        name\n        vlanID\n      }\n    }\n  }\n',
): (typeof documents)['\n  query MACTableQuery($virtualDeviceUUID: UUID!) {\n    switchMACTable(virtualDeviceUUID: $virtualDeviceUUID) {\n      updatedAt\n      macAddress\n      port\n      vlan {\n        UUID\n        name\n        vlanID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateSwitchProfile($uuid: UUID!, $input: UpdateSwitchProfileInput!) {\n    updateSwitchProfile(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateSwitchProfile($uuid: UUID!, $input: UpdateSwitchProfileInput!) {\n    updateSwitchProfile(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FindMACOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {\n    findSwitchesForClientMAC(networkUUID: $networkUUID, macAddress: $mac) {\n      virtualDevice {\n        UUID\n        label\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n      phyInterface {\n        UUID\n        portNumber\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FindMACOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {\n    findSwitchesForClientMAC(networkUUID: $networkUUID, macAddress: $mac) {\n      virtualDevice {\n        UUID\n        label\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n      phyInterface {\n        UUID\n        portNumber\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FindLLDPEntryOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {\n    findSwitchLLDPEntryForMAC(networkUUID: $networkUUID, macAddress: $mac) {\n      virtualDevice {\n        UUID\n        label\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n      phyInterface {\n        UUID\n        portNumber\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FindLLDPEntryOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {\n    findSwitchLLDPEntryForMAC(networkUUID: $networkUUID, macAddress: $mac) {\n      virtualDevice {\n        UUID\n        label\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n      phyInterface {\n        UUID\n        portNumber\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation PortCycleMutation(\n    $portNumber: Int!\n    $serialNumber: String!\n    $poeCycle: Boolean!\n    $portCycle: Boolean!\n  ) {\n    rpcSwitchPortCycle(\n      input: {\n        portNumber: $portNumber\n        serialNumber: $serialNumber\n        poeCycle: $poeCycle\n        portCycle: $portCycle\n      }\n    )\n  }\n',
): (typeof documents)['\n  mutation PortCycleMutation(\n    $portNumber: Int!\n    $serialNumber: String!\n    $poeCycle: Boolean!\n    $portCycle: Boolean!\n  ) {\n    rpcSwitchPortCycle(\n      input: {\n        portNumber: $portNumber\n        serialNumber: $serialNumber\n        poeCycle: $poeCycle\n        portCycle: $portCycle\n      }\n    )\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchPortMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {\n    switchPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        portNumber\n        dropsPerSecond\n        txErrPerSecond\n        rxErrPerSecond\n        totalRxBytesPerSecond\n        totalTxBytesPerSecond\n        multicastRxPacketsPerSecond\n        multicastTxPacketsPerSecond\n        broadcastRxPacketsPerSecond\n        broadcastTxPacketsPerSecond\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SwitchPortMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {\n    switchPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {\n      values {\n        timestamp\n        portNumber\n        dropsPerSecond\n        txErrPerSecond\n        rxErrPerSecond\n        totalRxBytesPerSecond\n        totalTxBytesPerSecond\n        multicastRxPacketsPerSecond\n        multicastTxPacketsPerSecond\n        broadcastRxPacketsPerSecond\n        broadcastTxPacketsPerSecond\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query switchPortStatsQuery($virtualDeviceUUID: UUID!) {\n    switchPortStats(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n      totalRxBytes\n      totalTxBytes\n    }\n  }\n',
): (typeof documents)['\n  query switchPortStatsQuery($virtualDeviceUUID: UUID!) {\n    switchPortStats(virtualDeviceUUID: $virtualDeviceUUID) {\n      portNumber\n      totalRxBytes\n      totalTxBytes\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query HubNetworksOverview($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      slug\n      label\n      UUID\n      isActive\n      isTemplate\n      mailingAddress {\n        line1\n        line2\n        city\n        subdivisionCode\n        postalCode\n        countryISO2\n        timezone\n        coordinates {\n          latitude\n          longitude\n        }\n      }\n      virtualDevices {\n        UUID\n        deviceType\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query HubNetworksOverview($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      slug\n      label\n      UUID\n      isActive\n      isTemplate\n      mailingAddress {\n        line1\n        line2\n        city\n        subdivisionCode\n        postalCode\n        countryISO2\n        timezone\n        coordinates {\n          latitude\n          longitude\n        }\n      }\n      virtualDevices {\n        UUID\n        deviceType\n        hardwareDevice {\n          isConnectedToBackend\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UptimeStatsForNetworks($networkUUIDs: [UUID!]!, $lookBack: Int!, $stepSeconds: Int!) {\n    networksUplinkQualities(\n      networkUUIDs: $networkUUIDs\n      filter: { durationSeconds: $lookBack, stepSeconds: $stepSeconds }\n    ) {\n      values {\n        phyInterfaceUUID\n        timestamp\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UptimeStatsForNetworks($networkUUIDs: [UUID!]!, $lookBack: Int!, $stepSeconds: Int!) {\n    networksUplinkQualities(\n      networkUUIDs: $networkUUIDs\n      filter: { durationSeconds: $lookBack, stepSeconds: $stepSeconds }\n    ) {\n      values {\n        phyInterfaceUUID\n        timestamp\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query APUptime($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    apUptime(networkUUID: $networkUUID, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        apName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query APUptime($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    apUptime(networkUUID: $networkUUID, filter: $filter) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        apName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ClientMetrics($serialNumber: String!, $filter: MetricsFilterInput!) {\n    clientMetrics(serialNumber: $serialNumber, filter: $filter) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        tx {\n          minValue\n          maxValue\n        }\n        rx {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        macAddress\n        signal\n        noise\n        txRate\n        rxRate\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ClientMetrics($serialNumber: String!, $filter: MetricsFilterInput!) {\n    clientMetrics(serialNumber: $serialNumber, filter: $filter) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        tx {\n          minValue\n          maxValue\n        }\n        rx {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        macAddress\n        signal\n        noise\n        txRate\n        rxRate\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query activeClients(\n    $networkUUID: UUID!\n    $filter: MetricsFilterInput!\n    $input: ActiveClientsInput\n  ) {\n    activeClients(networkUUID: $networkUUID, filter: $filter, input: $input) {\n      values {\n        timestamp\n        value\n      }\n    }\n  }\n',
): (typeof documents)['\n  query activeClients(\n    $networkUUID: UUID!\n    $filter: MetricsFilterInput!\n    $input: ActiveClientsInput\n  ) {\n    activeClients(networkUUID: $networkUUID, filter: $filter, input: $input) {\n      values {\n        timestamp\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query networkUplinkQuality($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    networkUplinkQuality(networkUUID: $networkUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        phyInterfaceUUID\n      }\n    }\n  }\n',
): (typeof documents)['\n  query networkUplinkQuality($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    networkUplinkQuality(networkUUID: $networkUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        phyInterfaceUUID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query networkUplinkThroughput($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    networkUplinkThroughput(networkUUID: $networkUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        direction\n        phyInterfaceUUID\n      }\n    }\n  }\n',
): (typeof documents)['\n  query networkUplinkThroughput($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    networkUplinkThroughput(networkUUID: $networkUUID, filter: $filter) {\n      values {\n        timestamp\n        value\n        direction\n        phyInterfaceUUID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query APChannelUtilization($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    band2G: apChannelUtilization(networkUUID: $networkUUID, filter: $filter, band: BAND_2_4G) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on APChannelUtilizationMetricsValue {\n          apName\n        }\n      }\n    }\n    band5G: apChannelUtilization(networkUUID: $networkUUID, filter: $filter, band: BAND_5G) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on APChannelUtilizationMetricsValue {\n          apName\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query APChannelUtilization($networkUUID: UUID!, $filter: MetricsFilterInput!) {\n    band2G: apChannelUtilization(networkUUID: $networkUUID, filter: $filter, band: BAND_2_4G) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on APChannelUtilizationMetricsValue {\n          apName\n        }\n      }\n    }\n    band5G: apChannelUtilization(networkUUID: $networkUUID, filter: $filter, band: BAND_5G) {\n      metadata {\n        minValue\n        maxValue\n      }\n      values {\n        timestamp\n        value\n        ... on APChannelUtilizationMetricsValue {\n          apName\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetwoorksForNosVersionQuery {\n    networks(filter: { active: { eq: true } }) {\n      pinnedNOSVersionID\n    }\n  }\n',
): (typeof documents)['\n  query NetwoorksForNosVersionQuery {\n    networks(filter: { active: { eq: true } }) {\n      pinnedNOSVersionID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NosVersionsQuery {\n    nosVersions {\n      version\n      id\n      mc01Build\n      mw07Build\n      ms10Build\n      mp01Build\n      isDeprecated\n      isDefault\n      updatedAt\n      major\n      minor\n      patch\n    }\n  }\n',
): (typeof documents)['\n  query NosVersionsQuery {\n    nosVersions {\n      version\n      id\n      mc01Build\n      mw07Build\n      ms10Build\n      mp01Build\n      isDeprecated\n      isDefault\n      updatedAt\n      major\n      minor\n      patch\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NosVersionByIDQuery($id: Int!) {\n    nosVersions(id: $id) {\n      id\n      version\n      isDeprecated\n      isGA\n      isDefault\n      isLocked\n      releaseNotes\n      major\n      minor\n      patch\n      updatedAt\n      # cos\n      mc01Build\n      # wos\n      mw07Build\n      mw08Build\n      mw09Build\n      # sos\n      ms10Build\n      ms11Build\n      # pos\n      mp01Build\n    }\n  }\n',
): (typeof documents)['\n  query NosVersionByIDQuery($id: Int!) {\n    nosVersions(id: $id) {\n      id\n      version\n      isDeprecated\n      isGA\n      isDefault\n      isLocked\n      releaseNotes\n      major\n      minor\n      patch\n      updatedAt\n      # cos\n      mc01Build\n      # wos\n      mw07Build\n      mw08Build\n      mw09Build\n      # sos\n      ms10Build\n      ms11Build\n      # pos\n      mp01Build\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateNOSVersionMutation($id: Int!, $input: NosVersionInput!) {\n    updateNosVersion(id: $id, input: $input) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateNOSVersionMutation($id: Int!, $input: NosVersionInput!) {\n    updateNosVersion(id: $id, input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNOSVersionMutation($input: NosVersionInput!) {\n    createNosVersion(input: $input) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNOSVersionMutation($input: NosVersionInput!) {\n    createNosVersion(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NosFeaturesQuery {\n    nosFeatures {\n      key\n      uuid\n      name\n      description\n      minMajorVersion\n      minMinorVersion\n      minPatchVersion\n      maxMajorVersion\n      maxMinorVersion\n      maxPatchVersion\n      featureFlag\n    }\n  }\n',
): (typeof documents)['\n  query NosFeaturesQuery {\n    nosFeatures {\n      key\n      uuid\n      name\n      description\n      minMajorVersion\n      minMinorVersion\n      minPatchVersion\n      maxMajorVersion\n      maxMinorVersion\n      maxPatchVersion\n      featureFlag\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NosFeatureQuery($uuid: UUID!) {\n    nosFeature(UUID: $uuid) {\n      uuid\n      key\n      name\n      description\n      minMajorVersion\n      minMinorVersion\n      minPatchVersion\n      maxMajorVersion\n      maxMinorVersion\n      maxPatchVersion\n      featureFlag\n    }\n  }\n',
): (typeof documents)['\n  query NosFeatureQuery($uuid: UUID!) {\n    nosFeature(UUID: $uuid) {\n      uuid\n      key\n      name\n      description\n      minMajorVersion\n      minMinorVersion\n      minPatchVersion\n      maxMajorVersion\n      maxMinorVersion\n      maxPatchVersion\n      featureFlag\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateNosFeatureMutation($uuid: UUID!, $input: UpdateNosFeatureInput!) {\n    updateNosFeature(UUID: $uuid, input: $input) {\n      uuid\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateNosFeatureMutation($uuid: UUID!, $input: UpdateNosFeatureInput!) {\n    updateNosFeature(UUID: $uuid, input: $input) {\n      uuid\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNosFeatureMutation($input: CreateNosFeatureInput!) {\n    createNosFeature(input: $input) {\n      uuid\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNosFeatureMutation($input: CreateNosFeatureInput!) {\n    createNosFeature(input: $input) {\n      uuid\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworkForNavigation($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      slug\n      label\n\n      companySlug\n    }\n  }\n',
): (typeof documents)['\n  query NetworkForNavigation($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      slug\n      label\n\n      companySlug\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query HardwareDeviceForNavigation($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        description\n\n        network {\n          UUID\n          slug\n\n          companySlug\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query HardwareDeviceForNavigation($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        description\n\n        network {\n          UUID\n          slug\n\n          companySlug\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CaptivePortalBody on CaptivePortal {\n    UUID\n    callToAction\n    logoImageBase64 {\n      data\n      imageType\n    }\n    logoImageS3Key\n    name\n    redirectURL\n    allowedHosts\n    externalPortalURL\n    authLifetimeSec\n    termsAndConditions\n    vlans {\n      UUID\n      name\n    }\n    displaySettings {\n      backgroundColor\n      fontColor\n      hidePoweredByMeter\n    }\n    webhookKey\n    isEnabled\n  }\n',
): (typeof documents)['\n  fragment CaptivePortalBody on CaptivePortal {\n    UUID\n    callToAction\n    logoImageBase64 {\n      data\n      imageType\n    }\n    logoImageS3Key\n    name\n    redirectURL\n    allowedHosts\n    externalPortalURL\n    authLifetimeSec\n    termsAndConditions\n    vlans {\n      UUID\n      name\n    }\n    displaySettings {\n      backgroundColor\n      fontColor\n      hidePoweredByMeter\n    }\n    webhookKey\n    isEnabled\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CaptivePortalForNetworkQuery($networkUUID: UUID!) {\n    captivePortalForNetwork(networkUUID: $networkUUID) {\n      UUID\n      ...CaptivePortalBody\n    }\n  }\n',
): (typeof documents)['\n  query CaptivePortalForNetworkQuery($networkUUID: UUID!) {\n    captivePortalForNetwork(networkUUID: $networkUUID) {\n      UUID\n      ...CaptivePortalBody\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateCaptivePortal($uuid: UUID!, $input: CaptivePortalSettingsInput!) {\n    updateCaptivePortal(input: $input, uuid: $uuid) {\n      UUID\n      ...CaptivePortalBody\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCaptivePortal($uuid: UUID!, $input: CaptivePortalSettingsInput!) {\n    updateCaptivePortal(input: $input, uuid: $uuid) {\n      UUID\n      ...CaptivePortalBody\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteCaptivePortal($uuid: UUID!) {\n    deleteCaptivePortal(uuid: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteCaptivePortal($uuid: UUID!) {\n    deleteCaptivePortal(uuid: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateCaptivePortal($networkUUID: UUID!, $input: CaptivePortalSettingsInput!) {\n    createCaptivePortal(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCaptivePortal($networkUUID: UUID!, $input: CaptivePortalSettingsInput!) {\n    createCaptivePortal(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query capoActivityQuery($networkUUID: UUID!, $numHoursLookback: Int!) {\n    captivePortalAuthorizedClients(networkUUID: $networkUUID, numHoursLookback: $numHoursLookback) {\n      timestamp\n      isAuthorized\n      ipAddress\n      macAddress\n      rawUserAgent\n    }\n  }\n',
): (typeof documents)['\n  query capoActivityQuery($networkUUID: UUID!, $numHoursLookback: Int!) {\n    captivePortalAuthorizedClients(networkUUID: $networkUUID, numHoursLookback: $numHoursLookback) {\n      timestamp\n      isAuthorized\n      ipAddress\n      macAddress\n      rawUserAgent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteInternetServicePlanMutation($internetServicePlanUUID: UUID!) {\n    deleteInternetServicePlan(internetServicePlanUUID: $internetServicePlanUUID)\n  }\n',
): (typeof documents)['\n  mutation DeleteInternetServicePlanMutation($internetServicePlanUUID: UUID!) {\n    deleteInternetServicePlan(internetServicePlanUUID: $internetServicePlanUUID)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateInternetServicePlanMutation(\n    $companySlug: String!\n    $networkUUID: UUID!\n    $input: CreateInternetServicePlanInput!\n  ) {\n    createInternetServicePlan(companySlug: $companySlug, networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateInternetServicePlanMutation(\n    $companySlug: String!\n    $networkUUID: UUID!\n    $input: CreateInternetServicePlanInput!\n  ) {\n    createInternetServicePlan(companySlug: $companySlug, networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateInternetServicePlanMutation(\n    $internetServicePlanUUID: UUID!\n    $input: UpdateInternetServicePlanInput!\n  ) {\n    updateInternetServicePlan(internetServicePlanUUID: $internetServicePlanUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateInternetServicePlanMutation(\n    $internetServicePlanUUID: UUID!\n    $input: UpdateInternetServicePlanInput!\n  ) {\n    updateInternetServicePlan(internetServicePlanUUID: $internetServicePlanUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyISPsToInternetServicePlans($networkUUID: UUID!) {\n    copyInternetServicePlanIPsFromConfigToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyISPsToInternetServicePlans($networkUUID: UUID!) {\n    copyInternetServicePlanIPsFromConfigToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ProvidersQuery($companySlug: String!) {\n    internetServicePlanProviders(companySlug: $companySlug) {\n      UUID\n      logoURL\n      name\n    }\n  }\n',
): (typeof documents)['\n  query ProvidersQuery($companySlug: String!) {\n    internetServicePlanProviders(companySlug: $companySlug) {\n      UUID\n      logoURL\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ListISPsQuery($companySlug: String!, $networkUUID: UUID) {\n    internetServicePlans(companySlug: $companySlug, networkUUID: $networkUUID) {\n      UUID\n\n      address {\n        address1\n        address2\n        city\n        state\n        postalCode\n        country\n      }\n\n      connectionProduct\n      connectionStatus\n\n      provider {\n        UUID\n        logoURL\n        name\n      }\n\n      controllerIP\n      controllerName\n      firstUsableIP\n      gatewayAddr\n      hasStaticIP\n      lastUsableIP\n      networkUUID\n      staticIPRange\n    }\n  }\n',
): (typeof documents)['\n  query ListISPsQuery($companySlug: String!, $networkUUID: UUID) {\n    internetServicePlans(companySlug: $companySlug, networkUUID: $networkUUID) {\n      UUID\n\n      address {\n        address1\n        address2\n        city\n        state\n        postalCode\n        country\n      }\n\n      connectionProduct\n      connectionStatus\n\n      provider {\n        UUID\n        logoURL\n        name\n      }\n\n      controllerIP\n      controllerName\n      firstUsableIP\n      gatewayAddr\n      hasStaticIP\n      lastUsableIP\n      networkUUID\n      staticIPRange\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation MoveInternetServicePlanMutation($ispUUID: UUID!, $networkUUID: UUID!) {\n    moveInternetServicePlanToNetwork(internetServicePlanUUID: $ispUUID, networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation MoveInternetServicePlanMutation($ispUUID: UUID!, $networkUUID: UUID!) {\n    moveInternetServicePlanToNetwork(internetServicePlanUUID: $ispUUID, networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VLANsQuery($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n  }\n',
): (typeof documents)['\n  query VLANsQuery($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VLANQuery($uuid: UUID!) {\n    vlan(UUID: $uuid) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      dhcpRule {\n        UUID\n        isIPv6\n        startIPAddress\n        endIPAddress\n        gatewayIPAddress\n        gatewayPrefixLength\n        leaseDurationSeconds\n        dnsUseGatewayProxy\n        dnsUpstreamServers\n        dnsSearchDomains\n        dnsCacheIsEnabled\n        dnsCacheSize\n        dnsCacheMaxTTL\n\n        options {\n          UUID\n          code\n          data\n          description\n          createdAt\n        }\n\n        reservedRanges {\n          UUID\n          startIPAddress\n          endIPAddress\n          createdAt\n        }\n\n        staticMappings {\n          UUID\n          name\n          macAddress\n          ipAddress\n          hostname\n          createdAt\n        }\n\n        dnsHostMappings {\n          UUID\n          isEnabled\n          overrideDomain\n          destinationIPAddress\n          destinationDomain\n          createdAt\n        }\n\n        applicationDNSFirewallRules {\n          UUID\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query VLANQuery($uuid: UUID!) {\n    vlan(UUID: $uuid) {\n      __typename\n      UUID\n      name\n      description\n      isEnabled\n      isInternal\n      isDefault\n      vlanID\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      dhcpRule {\n        UUID\n        isIPv6\n        startIPAddress\n        endIPAddress\n        gatewayIPAddress\n        gatewayPrefixLength\n        leaseDurationSeconds\n        dnsUseGatewayProxy\n        dnsUpstreamServers\n        dnsSearchDomains\n        dnsCacheIsEnabled\n        dnsCacheSize\n        dnsCacheMaxTTL\n\n        options {\n          UUID\n          code\n          data\n          description\n          createdAt\n        }\n\n        reservedRanges {\n          UUID\n          startIPAddress\n          endIPAddress\n          createdAt\n        }\n\n        staticMappings {\n          UUID\n          name\n          macAddress\n          ipAddress\n          hostname\n          createdAt\n        }\n\n        dnsHostMappings {\n          UUID\n          isEnabled\n          overrideDomain\n          destinationIPAddress\n          destinationDomain\n          createdAt\n        }\n\n        applicationDNSFirewallRules {\n          UUID\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateVLANMutation($networkUUID: UUID!, $input: CreateVLANInput!) {\n    createVLAN(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateVLANMutation($networkUUID: UUID!, $input: CreateVLANInput!) {\n    createVLAN(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateVLANMutation($uuid: UUID!, $input: UpdateVLANInput!) {\n    updateVLAN(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateVLANMutation($uuid: UUID!, $input: UpdateVLANInput!) {\n    updateVLAN(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteVLANMutation($uuid: UUID!) {\n    deleteVLAN(UUID: $uuid)\n  }\n',
): (typeof documents)['\n  mutation DeleteVLANMutation($uuid: UUID!) {\n    deleteVLAN(UUID: $uuid)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateDHCPRule($vlanUUID: UUID!, $input: CreateDHCPRuleInput!) {\n    createDHCPRule(vlanUUID: $vlanUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDHCPRule($vlanUUID: UUID!, $input: CreateDHCPRuleInput!) {\n    createDHCPRule(vlanUUID: $vlanUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateDHCPRule($uuid: UUID!, $input: UpdateDHCPRuleInput!) {\n    updateDHCPRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDHCPRule($uuid: UUID!, $input: UpdateDHCPRuleInput!) {\n    updateDHCPRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDHCPRule($uuid: UUID!) {\n    deleteDHCPRule(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteDHCPRule($uuid: UUID!) {\n    deleteDHCPRule(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateDHCPOption($ruleUUID: UUID!, $input: CreateDHCPOptionInput!) {\n    createDHCPOption(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDHCPOption($ruleUUID: UUID!, $input: CreateDHCPOptionInput!) {\n    createDHCPOption(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateDHCPOption($uuid: UUID!, $input: UpdateDHCPOptionInput!) {\n    updateDHCPOption(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDHCPOption($uuid: UUID!, $input: UpdateDHCPOptionInput!) {\n    updateDHCPOption(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDHCPOption($uuid: UUID!) {\n    deleteDHCPOption(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteDHCPOption($uuid: UUID!) {\n    deleteDHCPOption(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateDHCPReservedRange($ruleUUID: UUID!, $input: CreateDHCPReservedRangeInput!) {\n    createDHCPReservedRange(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDHCPReservedRange($ruleUUID: UUID!, $input: CreateDHCPReservedRangeInput!) {\n    createDHCPReservedRange(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateDHCPReservedRange($uuid: UUID!, $input: UpdateDHCPReservedRangeInput!) {\n    updateDHCPReservedRange(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDHCPReservedRange($uuid: UUID!, $input: UpdateDHCPReservedRangeInput!) {\n    updateDHCPReservedRange(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDHCPReservedRange($uuid: UUID!) {\n    deleteDHCPReservedRange(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteDHCPReservedRange($uuid: UUID!) {\n    deleteDHCPReservedRange(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateDHCPStaticMapping($ruleUUID: UUID!, $input: CreateDHCPStaticMappingInput!) {\n    createDHCPStaticMapping(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDHCPStaticMapping($ruleUUID: UUID!, $input: CreateDHCPStaticMappingInput!) {\n    createDHCPStaticMapping(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateDHCPStaticMapping($uuid: UUID!, $input: UpdateDHCPStaticMappingInput!) {\n    updateDHCPStaticMapping(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDHCPStaticMapping($uuid: UUID!, $input: UpdateDHCPStaticMappingInput!) {\n    updateDHCPStaticMapping(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDHCPStaticMapping($uuid: UUID!) {\n    deleteDHCPStaticMapping(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteDHCPStaticMapping($uuid: UUID!) {\n    deleteDHCPStaticMapping(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateDNSHostMapping($ruleUUID: UUID!, $input: CreateDNSHostMappingInput!) {\n    createDNSHostMapping(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDNSHostMapping($ruleUUID: UUID!, $input: CreateDNSHostMappingInput!) {\n    createDNSHostMapping(dhcpRuleUUID: $ruleUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateDNSHostMapping($uuid: UUID!, $input: UpdateDNSHostMappingInput!) {\n    updateDNSHostMapping(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDNSHostMapping($uuid: UUID!, $input: UpdateDNSHostMappingInput!) {\n    updateDNSHostMapping(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteDNSHostMapping($uuid: UUID!) {\n    deleteDNSHostMapping(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteDNSHostMapping($uuid: UUID!) {\n    deleteDNSHostMapping(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyVLANsMutation($networkUUID: UUID!) {\n    copyVLANsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyVLANsMutation($networkUUID: UUID!) {\n    copyVLANsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyDNSHostMappingsFromConfig1($networkUUID: UUID!) {\n    copyDNSHostMappingsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyDNSHostMappingsFromConfig1($networkUUID: UUID!) {\n    copyDNSHostMappingsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment NosVersionFields on NosVersion {\n    id\n    version\n    major\n    minor\n    patch\n    isDefault\n  }\n',
): (typeof documents)['\n  fragment NosVersionFields on NosVersion {\n    id\n    version\n    major\n    minor\n    patch\n    isDefault\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NosVersionQuery($id: Int!) {\n    nosVersions(id: $id) {\n      ...NosVersionFields\n    }\n  }\n',
): (typeof documents)['\n  query NosVersionQuery($id: Int!) {\n    nosVersions(id: $id) {\n      ...NosVersionFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NosVersionsShortQuery {\n    nosVersions {\n      ...NosVersionFields\n    }\n  }\n',
): (typeof documents)['\n  query NosVersionsShortQuery {\n    nosVersions {\n      ...NosVersionFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation SetNetworkNosMutation($networkUUID: UUID!, $input: SetNetworkNosVersionInput!) {\n    setNetworkNosVersion(networkUUID: $networkUUID, input: $input)\n  }\n',
): (typeof documents)['\n  mutation SetNetworkNosMutation($networkUUID: UUID!, $input: SetNetworkNosVersionInput!) {\n    setNetworkNosVersion(networkUUID: $networkUUID, input: $input)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NotificationPreferencesQuery($companySlug: String!, $controllerName: String!) {\n    notificationPreferences(input: { companySlug: $companySlug, controllerName: $controllerName }) {\n      companyPreferences {\n        companySID\n        emailAddresses\n        additionalSlackChannels\n        additionalEventTypes\n      }\n\n      networkPreferences {\n        networkUUID\n        emailAddresses\n        additionalSlackChannels\n        additionalEventTypes\n      }\n    }\n  }\n',
): (typeof documents)['\n  query NotificationPreferencesQuery($companySlug: String!, $controllerName: String!) {\n    notificationPreferences(input: { companySlug: $companySlug, controllerName: $controllerName }) {\n      companyPreferences {\n        companySID\n        emailAddresses\n        additionalSlackChannels\n        additionalEventTypes\n      }\n\n      networkPreferences {\n        networkUUID\n        emailAddresses\n        additionalSlackChannels\n        additionalEventTypes\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpsertCompanyNotificationPreferenceMutation(\n    $companySlug: String!\n    $input: NotificationPreferenceInput\n  ) {\n    upsertCompanyNotificationPreference(companySlug: $companySlug, input: $input) {\n      uuid\n    }\n  }\n',
): (typeof documents)['\n  mutation UpsertCompanyNotificationPreferenceMutation(\n    $companySlug: String!\n    $input: NotificationPreferenceInput\n  ) {\n    upsertCompanyNotificationPreference(companySlug: $companySlug, input: $input) {\n      uuid\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpsertControllerNotificationPreferenceMutation(\n    $controllerName: String!\n    $input: NotificationPreferenceInput\n  ) {\n    upsertNetworkNotificationPreference(controllerName: $controllerName, input: $input) {\n      uuid\n    }\n  }\n',
): (typeof documents)['\n  mutation UpsertControllerNotificationPreferenceMutation(\n    $controllerName: String!\n    $input: NotificationPreferenceInput\n  ) {\n    upsertNetworkNotificationPreference(controllerName: $controllerName, input: $input) {\n      uuid\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment InternalNoteFields on InternalNote {\n    UUID\n    createdAt\n    createdByUser {\n      email\n      firstName\n      lastName\n    }\n    updatedAt\n    updatedByUser {\n      email\n      firstName\n      lastName\n    }\n    isImportant\n    note\n    photoURLs\n    photoS3Keys\n  }\n',
): (typeof documents)['\n  fragment InternalNoteFields on InternalNote {\n    UUID\n    createdAt\n    createdByUser {\n      email\n      firstName\n      lastName\n    }\n    updatedAt\n    updatedByUser {\n      email\n      firstName\n      lastName\n    }\n    isImportant\n    note\n    photoURLs\n    photoS3Keys\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query InternalNotesForNetwork($networkUUID: UUID!) {\n    internalNotesForNetwork(networkUUID: $networkUUID) {\n      ...InternalNoteFields\n    }\n  }\n',
): (typeof documents)['\n  query InternalNotesForNetwork($networkUUID: UUID!) {\n    internalNotesForNetwork(networkUUID: $networkUUID) {\n      ...InternalNoteFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query InternalNote($UUID: UUID!) {\n    internalNote(UUID: $UUID) {\n      ...InternalNoteFields\n    }\n  }\n',
): (typeof documents)['\n  query InternalNote($UUID: UUID!) {\n    internalNote(UUID: $UUID) {\n      ...InternalNoteFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateInternalNote($networkUUID: UUID!, $input: CreateInternalNoteInput!) {\n    createInternalNote(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateInternalNote($networkUUID: UUID!, $input: CreateInternalNoteInput!) {\n    createInternalNote(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateInternalNote($UUID: UUID!, $input: UpdateInternalNoteInput!) {\n    updateInternalNote(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateInternalNote($UUID: UUID!, $input: UpdateInternalNoteInput!) {\n    updateInternalNote(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteInternalNote($UUID: UUID!) {\n    deleteInternalNote(UUID: $UUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteInternalNote($UUID: UUID!) {\n    deleteInternalNote(UUID: $UUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RPCPingMutation($input: RPCPingInput!) {\n    rpcPing(input: $input) {\n      successRate\n      roundTripTimeMs\n    }\n  }\n',
): (typeof documents)['\n  mutation RPCPingMutation($input: RPCPingInput!) {\n    rpcPing(input: $input) {\n      successRate\n      roundTripTimeMs\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VlansForPing($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n    }\n  }\n',
): (typeof documents)['\n  query VlansForPing($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RPCPing2Mutation($input: RPCPingInput!) {\n    rpcPing(input: $input) {\n      successRate\n      roundTripTimeMs\n    }\n  }\n',
): (typeof documents)['\n  mutation RPCPing2Mutation($input: RPCPingInput!) {\n    rpcPing(input: $input) {\n      successRate\n      roundTripTimeMs\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RateLimitRule($uuid: UUID!) {\n    rateLimitRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      rateLimitKbpsDownload\n      rateLimitKbpsUpload\n      isEnabled\n      protocol\n      srcMac\n      srcPrefix\n      srcPortRange {\n        lower\n        upper\n      }\n      dstMac\n      dstPrefix\n      dstPortRange {\n        lower\n        upper\n      }\n      isPerSource\n      vlanBindings {\n        __typename\n        UUID\n        name\n      }\n      phyInterfaceBindings {\n        __typename\n        UUID\n        label\n        portNumber\n        virtualDevice {\n          __typename\n          UUID\n          label\n          deviceModel\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RateLimitRule($uuid: UUID!) {\n    rateLimitRule(UUID: $uuid) {\n      UUID\n      name\n      description\n      rateLimitKbpsDownload\n      rateLimitKbpsUpload\n      isEnabled\n      protocol\n      srcMac\n      srcPrefix\n      srcPortRange {\n        lower\n        upper\n      }\n      dstMac\n      dstPrefix\n      dstPortRange {\n        lower\n        upper\n      }\n      isPerSource\n      vlanBindings {\n        __typename\n        UUID\n        name\n      }\n      phyInterfaceBindings {\n        __typename\n        UUID\n        label\n        portNumber\n        virtualDevice {\n          __typename\n          UUID\n          label\n          deviceModel\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateRateLimitRule($networkUUID: UUID!, $input: CreateRateLimitRuleInput!) {\n    createRateLimitRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateRateLimitRule($networkUUID: UUID!, $input: CreateRateLimitRuleInput!) {\n    createRateLimitRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRateLimitRule($uuid: UUID!, $input: UpdateRateLimitRuleInput!) {\n    updateRateLimitRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRateLimitRule($uuid: UUID!, $input: UpdateRateLimitRuleInput!) {\n    updateRateLimitRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteRateLimitRule($uuid: UUID!) {\n    deleteRateLimitRule(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteRateLimitRule($uuid: UUID!) {\n    deleteRateLimitRule(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RateLimitRulesForNetwork($networkUUID: UUID!) {\n    rateLimitRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      rateLimitKbpsDownload\n      rateLimitKbpsUpload\n      isEnabled\n      isPerSource\n      protocol\n      srcMac\n      srcPrefix\n      srcPortRange {\n        lower\n        upper\n      }\n      dstMac\n      dstPrefix\n      dstPortRange {\n        lower\n        upper\n      }\n      vlanBindings {\n        __typename\n        UUID\n        name\n      }\n      phyInterfaceBindings {\n        __typename\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RateLimitRulesForNetwork($networkUUID: UUID!) {\n    rateLimitRulesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      description\n      rateLimitKbpsDownload\n      rateLimitKbpsUpload\n      isEnabled\n      isPerSource\n      protocol\n      srcMac\n      srcPrefix\n      srcPortRange {\n        lower\n        upper\n      }\n      dstMac\n      dstPrefix\n      dstPortRange {\n        lower\n        upper\n      }\n      vlanBindings {\n        __typename\n        UUID\n        name\n      }\n      phyInterfaceBindings {\n        __typename\n        ...PhyInterfaceLabelFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RoutesForVirtualDevice($virtualDeviceUUID: UUID!) {\n    networkRoutesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      destination\n      gateway\n      attachment {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        phyInterface {\n          __typename\n          UUID\n          portNumber\n          label\n          hardwareLabel\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            deviceModel\n          }\n          internetServicePlan {\n            provider {\n              name\n            }\n          }\n        }\n        autoVPNGroup {\n          __typename\n          UUID\n          name\n        }\n        ipSecTunnel {\n          __typename\n          UUID\n          name\n        }\n        clientVPNServer {\n          __typename\n          UUID\n          endpoint\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RoutesForVirtualDevice($virtualDeviceUUID: UUID!) {\n    networkRoutesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {\n      destination\n      gateway\n      attachment {\n        vlan {\n          __typename\n          UUID\n          name\n        }\n        phyInterface {\n          __typename\n          UUID\n          portNumber\n          label\n          hardwareLabel\n          virtualDevice {\n            UUID\n            label\n            deviceType\n            deviceModel\n          }\n          internetServicePlan {\n            provider {\n              name\n            }\n          }\n        }\n        autoVPNGroup {\n          __typename\n          UUID\n          name\n        }\n        ipSecTunnel {\n          __typename\n          UUID\n          name\n        }\n        clientVPNServer {\n          __typename\n          UUID\n          endpoint\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PolicyRoutingRulesFields on PolicyRoutingRule {\n    UUID\n    name\n    isEnabled\n    srcPrefix\n    srcVLAN {\n      __typename\n      UUID\n      name\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n    dstPrefix\n    srcPortRanges {\n      lower\n      upper\n    }\n    dstPortRanges {\n      lower\n      upper\n    }\n    protocols\n    bindings {\n      UUID\n      phyInterface {\n        __typename\n        ...PhyInterfaceLabelFields\n        isUplinkActive\n        hasWANActivity\n        isEnabled\n      }\n      metric\n    }\n  }\n',
): (typeof documents)['\n  fragment PolicyRoutingRulesFields on PolicyRoutingRule {\n    UUID\n    name\n    isEnabled\n    srcPrefix\n    srcVLAN {\n      __typename\n      UUID\n      name\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n    dstPrefix\n    srcPortRanges {\n      lower\n      upper\n    }\n    dstPortRanges {\n      lower\n      upper\n    }\n    protocols\n    bindings {\n      UUID\n      phyInterface {\n        __typename\n        ...PhyInterfaceLabelFields\n        isUplinkActive\n        hasWANActivity\n        isEnabled\n      }\n      metric\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PolicyRoutingRulesForNetwork($networkUUID: UUID!) {\n    policyRoutingRulesForNetwork(networkUUID: $networkUUID) {\n      ...PolicyRoutingRulesFields\n    }\n  }\n',
): (typeof documents)['\n  query PolicyRoutingRulesForNetwork($networkUUID: UUID!) {\n    policyRoutingRulesForNetwork(networkUUID: $networkUUID) {\n      ...PolicyRoutingRulesFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PolicyRoutingRule($uuid: UUID!) {\n    policyRoutingRule(UUID: $uuid) {\n      ...PolicyRoutingRulesFields\n    }\n  }\n',
): (typeof documents)['\n  query PolicyRoutingRule($uuid: UUID!) {\n    policyRoutingRule(UUID: $uuid) {\n      ...PolicyRoutingRulesFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreatePolicyRoutingRule($networkUUID: UUID!, $input: CreatePolicyRoutingRuleInput!) {\n    createPolicyRoutingRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePolicyRoutingRule($networkUUID: UUID!, $input: CreatePolicyRoutingRuleInput!) {\n    createPolicyRoutingRule(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdatePolicyRoutingRule($uuid: UUID!, $input: UpdatePolicyRoutingRuleInput!) {\n    updatePolicyRoutingRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePolicyRoutingRule($uuid: UUID!, $input: UpdatePolicyRoutingRuleInput!) {\n    updatePolicyRoutingRule(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeletePolicyRoutingRule($uuid: UUID!) {\n    deletePolicyRoutingRule(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePolicyRoutingRule($uuid: UUID!) {\n    deletePolicyRoutingRule(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdatePolicyRoutingRuleBindings(\n    $input: BindPolicyRoutingRulesInput!\n    $orderedRuleUUIDs: [UUID!]!\n  ) {\n    bindPolicyRoutingRules(input: $input, orderedRuleUUIDs: $orderedRuleUUIDs) {\n      UUID\n      metric\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePolicyRoutingRuleBindings(\n    $input: BindPolicyRoutingRulesInput!\n    $orderedRuleUUIDs: [UUID!]!\n  ) {\n    bindPolicyRoutingRules(input: $input, orderedRuleUUIDs: $orderedRuleUUIDs) {\n      UUID\n      metric\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment StaticRouteFields on StaticRoute {\n    UUID\n    name\n    isEnabled\n    dstPrefix\n\n    gatewayIP\n    gatewayDHCPStaticMapping {\n      UUID\n      name\n      macAddress\n      ipAddress\n      hostname\n    }\n  }\n',
): (typeof documents)['\n  fragment StaticRouteFields on StaticRoute {\n    UUID\n    name\n    isEnabled\n    dstPrefix\n\n    gatewayIP\n    gatewayDHCPStaticMapping {\n      UUID\n      name\n      macAddress\n      ipAddress\n      hostname\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query StaticRoutesForNetwork($networkUUID: UUID!) {\n    staticRoutesForNetwork(networkUUID: $networkUUID) {\n      ...StaticRouteFields\n    }\n  }\n',
): (typeof documents)['\n  query StaticRoutesForNetwork($networkUUID: UUID!) {\n    staticRoutesForNetwork(networkUUID: $networkUUID) {\n      ...StaticRouteFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query StaticRoute($uuid: UUID!) {\n    staticRoute(UUID: $uuid) {\n      ...StaticRouteFields\n    }\n  }\n',
): (typeof documents)['\n  query StaticRoute($uuid: UUID!) {\n    staticRoute(UUID: $uuid) {\n      ...StaticRouteFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateStaticRoute($networkUUID: UUID!, $input: CreateStaticRouteInput!) {\n    createStaticRoute(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateStaticRoute($networkUUID: UUID!, $input: CreateStaticRouteInput!) {\n    createStaticRoute(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateStaticRoute($uuid: UUID!, $input: UpdateStaticRouteInput!) {\n    updateStaticRoute(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateStaticRoute($uuid: UUID!, $input: UpdateStaticRouteInput!) {\n    updateStaticRoute(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteStaticRoute($uuid: UUID!) {\n    deleteStaticRoute(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteStaticRoute($uuid: UUID!) {\n    deleteStaticRoute(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VLANsStaticMappingsRules($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      dhcpRule {\n        UUID\n        staticMappings {\n          UUID\n          name\n          macAddress\n          ipAddress\n          hostname\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query VLANsStaticMappingsRules($networkUUID: UUID!) {\n    vlans(networkUUID: $networkUUID) {\n      UUID\n      name\n      ipV4ClientAssignmentProtocol\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n\n      dhcpRule {\n        UUID\n        staticMappings {\n          UUID\n          name\n          macAddress\n          ipAddress\n          hostname\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateNotificationSettingMutation(\n    $UUID: UUID!\n    $input: UpdateNotificationSettingInput!\n  ) {\n    updateNotificationSetting(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation updateNotificationSettingMutation(\n    $UUID: UUID!\n    $input: UpdateNotificationSettingInput!\n  ) {\n    updateNotificationSetting(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NotificationSettingsQuery($networkUUID: UUID!) {\n    notificationSettingsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      alertReceiverUUID\n      isEnabled\n      arguments {\n        parameterUUID\n        value\n      }\n      notificationDefinition {\n        name\n        displayName\n        description\n      }\n    }\n  }\n',
): (typeof documents)['\n  query NotificationSettingsQuery($networkUUID: UUID!) {\n    notificationSettingsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      alertReceiverUUID\n      isEnabled\n      arguments {\n        parameterUUID\n        value\n      }\n      notificationDefinition {\n        name\n        displayName\n        description\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNotificationSettingMutation(\n    $networkUUID: UUID!\n    $input: CreateNotificationSettingInput!\n  ) {\n    createNotificationSetting(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNotificationSettingMutation(\n    $networkUUID: UUID!\n    $input: CreateNotificationSettingInput!\n  ) {\n    createNotificationSetting(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NotificationDefinitionsQuery {\n    alertDefinitions {\n      name\n      displayName\n      description\n      parameters {\n        uuid\n        name\n        description\n        type\n        required\n      }\n    }\n  }\n',
): (typeof documents)['\n  query NotificationDefinitionsQuery {\n    alertDefinitions {\n      name\n      displayName\n      description\n      parameters {\n        uuid\n        name\n        description\n        type\n        required\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteNotificationSettingMutation($UUID: UUID!) {\n    deleteNotificationSetting(UUID: $UUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation deleteNotificationSettingMutation($UUID: UUID!) {\n    deleteNotificationSetting(UUID: $UUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateServiceDiscoveryForNetwork($UUID: UUID!, $input: UpdateNetworkInput!) {\n    updateNetwork(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation updateServiceDiscoveryForNetwork($UUID: UUID!, $input: UpdateNetworkInput!) {\n    updateNetwork(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UpdateNetworkNetwork($uuid: UUID!) {\n    network(UUID: $uuid) {\n      UUID\n      slug\n      label\n      isActive\n      isCustomer\n      isTemplate\n      isConfig1WosUpgradesEnabled\n      isUpgradeSensitive\n      multiWANAlgorithm\n      onboarding {\n        UUID\n        jobData {\n          id\n          jobID\n        }\n      }\n      mailingAddress {\n        UUID\n        line1\n        line2\n        city\n        subdivisionCode\n        postalCode\n        countryISO2\n        timezone\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UpdateNetworkNetwork($uuid: UUID!) {\n    network(UUID: $uuid) {\n      UUID\n      slug\n      label\n      isActive\n      isCustomer\n      isTemplate\n      isConfig1WosUpgradesEnabled\n      isUpgradeSensitive\n      multiWANAlgorithm\n      onboarding {\n        UUID\n        jobData {\n          id\n          jobID\n        }\n      }\n      mailingAddress {\n        UUID\n        line1\n        line2\n        city\n        subdivisionCode\n        postalCode\n        countryISO2\n        timezone\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNetwork($input: CreateNetworkInput!) {\n    createNetwork(input: $input) {\n      UUID\n      slug\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNetwork($input: CreateNetworkInput!) {\n    createNetwork(input: $input) {\n      UUID\n      slug\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNetworkFromCopy($sourceNetworkUUID: UUID!, $input: CreateNetworkCopyInput!) {\n    createNetworkFromCopy(sourceNetworkUUID: $sourceNetworkUUID, input: $input) {\n      UUID\n      slug\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNetworkFromCopy($sourceNetworkUUID: UUID!, $input: CreateNetworkCopyInput!) {\n    createNetworkFromCopy(sourceNetworkUUID: $sourceNetworkUUID, input: $input) {\n      UUID\n      slug\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateNetwork($uuid: UUID!, $input: UpdateNetworkInput!) {\n    updateNetwork(UUID: $uuid, input: $input) {\n      UUID\n      slug\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateNetwork($uuid: UUID!, $input: UpdateNetworkInput!) {\n    updateNetwork(UUID: $uuid, input: $input) {\n      UUID\n      slug\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteNetwork($uuid: UUID!) {\n    deleteNetwork(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteNetwork($uuid: UUID!) {\n    deleteNetwork(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query JobTrackers {\n    jobTrackers {\n      id\n      jobID\n      totalSqFt\n      fullAddress\n      locationCode\n    }\n  }\n',
): (typeof documents)['\n  query JobTrackers {\n    jobTrackers {\n      id\n      jobID\n      totalSqFt\n      fullAddress\n      locationCode\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNetworkOnboarding($networkUUID: UUID!, $jobTrackerID: String!) {\n    createNetworkOnboarding(networkUUID: $networkUUID, jobTrackerID: $jobTrackerID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNetworkOnboarding($networkUUID: UUID!, $jobTrackerID: String!) {\n    createNetworkOnboarding(networkUUID: $networkUUID, jobTrackerID: $jobTrackerID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MaintenanceWindowForNetwork($networkUUID: UUID!) {\n    maintenanceWindowForNetwork(networkUUID: $networkUUID) {\n      recurrence\n      timezone\n      isOptedOut\n      startHourOfDayLocal\n      endHourOfDayLocal\n      startDayOfWeekLocal\n      endDayOfWeekLocal\n    }\n  }\n',
): (typeof documents)['\n  query MaintenanceWindowForNetwork($networkUUID: UUID!) {\n    maintenanceWindowForNetwork(networkUUID: $networkUUID) {\n      recurrence\n      timezone\n      isOptedOut\n      startHourOfDayLocal\n      endHourOfDayLocal\n      startDayOfWeekLocal\n      endDayOfWeekLocal\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateMaintenanceWindow($networkUUID: UUID!, $input: UpdateMaintenanceWindowInput!) {\n    updateMaintenanceWindowForNetwork(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateMaintenanceWindow($networkUUID: UUID!, $input: UpdateMaintenanceWindowInput!) {\n    updateMaintenanceWindowForNetwork(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyMultiWANFromConfig1($networkUUID: UUID!) {\n    copyNetworkMultiWANAlgorithmFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyMultiWANFromConfig1($networkUUID: UUID!) {\n    copyNetworkMultiWANAlgorithmFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyFirewallRulesFromConfig1($networkUUID: UUID!) {\n    copyFirewallRulesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyFirewallRulesFromConfig1($networkUUID: UUID!) {\n    copyFirewallRulesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyPortForwardingRulesFromConfig1($networkUUID: UUID!) {\n    copyPortForwardingRulesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyPortForwardingRulesFromConfig1($networkUUID: UUID!) {\n    copyPortForwardingRulesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyUplinkPhyInterfacesFromConfig1($networkUUID: UUID!) {\n    copyUplinkPhyInterfacesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyUplinkPhyInterfacesFromConfig1($networkUUID: UUID!) {\n    copyUplinkPhyInterfacesFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateAlertReceiverWithTargets(\n    $alertReceiverUUID: UUID!\n    $input: UpdateAlertReceiverWithTargetsInput!\n  ) {\n    updateAlertReceiverWithTargets(alertReceiverUUID: $alertReceiverUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation updateAlertReceiverWithTargets(\n    $alertReceiverUUID: UUID!\n    $input: UpdateAlertReceiverWithTargetsInput!\n  ) {\n    updateAlertReceiverWithTargets(alertReceiverUUID: $alertReceiverUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateAlertReceiverWithTargetsMutation(\n    $companyUUID: UUID!\n    $input: CreateAlertReceiverWithTargetsInput!\n  ) {\n    createAlertReceiverWithTargets(companyUUID: $companyUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateAlertReceiverWithTargetsMutation(\n    $companyUUID: UUID!\n    $input: CreateAlertReceiverWithTargetsInput!\n  ) {\n    createAlertReceiverWithTargets(companyUUID: $companyUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateWebhookMutation($UUID: UUID!, $input: UpdateAlertTargetWebhookInput!) {\n    updateAlertTargetWebhook(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateWebhookMutation($UUID: UUID!, $input: UpdateAlertTargetWebhookInput!) {\n    updateAlertTargetWebhook(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createAlertTargetWebhook($companyUUID: UUID!, $input: CreateAlertTargetWebhookInput!) {\n    createAlertTargetWebhook(companyUUID: $companyUUID, input: $input) {\n      UUID\n      companyUUID\n      signingSecret\n      type\n      url\n      label\n    }\n  }\n',
): (typeof documents)['\n  mutation createAlertTargetWebhook($companyUUID: UUID!, $input: CreateAlertTargetWebhookInput!) {\n    createAlertTargetWebhook(companyUUID: $companyUUID, input: $input) {\n      UUID\n      companyUUID\n      signingSecret\n      type\n      url\n      label\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteAlertReceiverMutation($alertReceiverUUID: UUID!) {\n    deleteAlertReceiverWithTargets(alertReceiverUUID: $alertReceiverUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteAlertReceiverMutation($alertReceiverUUID: UUID!) {\n    deleteAlertReceiverWithTargets(alertReceiverUUID: $alertReceiverUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AlertTargetsQuery($companyUUID: UUID!) {\n    alertTargets(companyUUID: $companyUUID) {\n      emails {\n        UUID\n        emailAddress\n        type\n      }\n      webhooks {\n        UUID\n        url\n        label\n        type\n        signingSecret\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AlertTargetsQuery($companyUUID: UUID!) {\n    alertTargets(companyUUID: $companyUUID) {\n      emails {\n        UUID\n        emailAddress\n        type\n      }\n      webhooks {\n        UUID\n        url\n        label\n        type\n        signingSecret\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query alertTargetsWebhookQuery($companyUUID: UUID!) {\n    alertTargetsWebhook(companyUUID: $companyUUID) {\n      UUID\n      signingSecret\n      type\n      url\n      label\n    }\n  }\n',
): (typeof documents)['\n  query alertTargetsWebhookQuery($companyUUID: UUID!) {\n    alertTargetsWebhook(companyUUID: $companyUUID) {\n      UUID\n      signingSecret\n      type\n      url\n      label\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AlertReceiversQuery($companyUUID: UUID!) {\n    alertReceiversForCompany(companyUUID: $companyUUID) {\n      __typename\n      UUID\n      companyUUID\n      label\n      targets {\n        __typename\n        UUID\n        type\n        ... on AlertTargetEmail {\n          emailAddress\n        }\n        ... on AlertTargetWebhook {\n          url\n          label\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AlertReceiversQuery($companyUUID: UUID!) {\n    alertReceiversForCompany(companyUUID: $companyUUID) {\n      __typename\n      UUID\n      companyUUID\n      label\n      targets {\n        __typename\n        UUID\n        type\n        ... on AlertTargetEmail {\n          emailAddress\n        }\n        ... on AlertTargetWebhook {\n          url\n          label\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteAlertTargetWebhookMutation($UUID: UUID!) {\n    deleteAlertTargetWebhook(UUID: $UUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteAlertTargetWebhookMutation($UUID: UUID!) {\n    deleteAlertTargetWebhook(UUID: $UUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation WANSpeedTestMutation($input: RPCWANSpeedtestInput!) {\n    rpcWANSpeedtest(input: $input) {\n      downloadSpeedMbps\n      uploadSpeedMbps\n      latencyNs\n      jitterNs\n      targetName\n      targetHost\n      distance\n    }\n  }\n',
): (typeof documents)['\n  mutation WANSpeedTestMutation($input: RPCWANSpeedtestInput!) {\n    rpcWANSpeedtest(input: $input) {\n      downloadSpeedMbps\n      uploadSpeedMbps\n      latencyNs\n      jitterNs\n      targetName\n      targetHost\n      distance\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CompanyUserBody on CompanyUser {\n    roles {\n      companySlug\n      name\n      networkUUID\n    }\n    createdAt\n    deletedAt\n    UUID\n    companyMembershipRole\n    user {\n      email\n      firstName\n      lastName\n    }\n  }\n',
): (typeof documents)['\n  fragment CompanyUserBody on CompanyUser {\n    roles {\n      companySlug\n      name\n      networkUUID\n    }\n    createdAt\n    deletedAt\n    UUID\n    companyMembershipRole\n    user {\n      email\n      firstName\n      lastName\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCompanyUsers($companySlug: String!, $input: GetCompanyUserInput) {\n    companyUsers(companySlug: $companySlug, input: $input) {\n      ...CompanyUserBody\n    }\n  }\n',
): (typeof documents)['\n  query GetCompanyUsers($companySlug: String!, $input: GetCompanyUserInput) {\n    companyUsers(companySlug: $companySlug, input: $input) {\n      ...CompanyUserBody\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCompanyUser($uuid: UUID!, $input: GetCompanyUserInput) {\n    companyUser(companyUserUUID: $uuid, input: $input) {\n      ...CompanyUserBody\n    }\n  }\n',
): (typeof documents)['\n  query GetCompanyUser($uuid: UUID!, $input: GetCompanyUserInput) {\n    companyUser(companyUserUUID: $uuid, input: $input) {\n      ...CompanyUserBody\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteCompanyUser($uuid: UUID!) {\n    deleteCompanyUser(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteCompanyUser($uuid: UUID!) {\n    deleteCompanyUser(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ReactivateCompanyUser($uuid: UUID!) {\n    activateCompanyUser(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation ReactivateCompanyUser($uuid: UUID!) {\n    activateCompanyUser(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateCompanyUser($companySlug: String!, $input: CreateCompanyUserInput!) {\n    createCompanyUser(companySlug: $companySlug, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCompanyUser($companySlug: String!, $input: CreateCompanyUserInput!) {\n    createCompanyUser(companySlug: $companySlug, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateCompanyUser($uuid: UUID!, $input: UpdateCompanyUserInput!) {\n    updateCompanyUser(companyUserUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCompanyUser($uuid: UUID!, $input: UpdateCompanyUserInput!) {\n    updateCompanyUser(companyUserUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateUserRoles($uuid: UUID!, $input: [UserRoleInput!]!) {\n    updateUserRoles(companyUserUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateUserRoles($uuid: UUID!, $input: [UserRoleInput!]!) {\n    updateUserRoles(companyUserUUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AssignOperatorRole($uuid: UUID!) {\n    assignOperatorRole(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation AssignOperatorRole($uuid: UUID!) {\n    assignOperatorRole(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveOperatorRole($uuid: UUID!) {\n    removeOperatorRole(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveOperatorRole($uuid: UUID!) {\n    removeOperatorRole(companyUserUUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RadioProfilesQuery($networkUUID: UUID!) {\n    radioProfilesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      isDefault\n      band2_4GIsEnabled\n      band2_4GAutoChannelIsEnabled\n      band2_4GAllowedChannels\n      band2_4GAutoChannelWidthIsEnabled\n      band2_4GAutoTxPowerIsEnabled\n      band2_4GChannelWidthMHz\n      band2_4GAutoTxPowerMaxdBm\n      band2_4GAutoTxPowerMindBm\n      band5GIsEnabled\n      band5GAutoChannelIsEnabled\n      band5GAllowedChannels\n      band5GAutoChannelWidthIsEnabled\n      band5GAutoTxPowerIsEnabled\n      band5GChannelWidthMHz\n      band5GAutoTxPowerMaxdBm\n      band5GAutoTxPowerMindBm\n\n      virtualDevices {\n        UUID\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RadioProfilesQuery($networkUUID: UUID!) {\n    radioProfilesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      isDefault\n      band2_4GIsEnabled\n      band2_4GAutoChannelIsEnabled\n      band2_4GAllowedChannels\n      band2_4GAutoChannelWidthIsEnabled\n      band2_4GAutoTxPowerIsEnabled\n      band2_4GChannelWidthMHz\n      band2_4GAutoTxPowerMaxdBm\n      band2_4GAutoTxPowerMindBm\n      band5GIsEnabled\n      band5GAutoChannelIsEnabled\n      band5GAllowedChannels\n      band5GAutoChannelWidthIsEnabled\n      band5GAutoTxPowerIsEnabled\n      band5GChannelWidthMHz\n      band5GAutoTxPowerMaxdBm\n      band5GAutoTxPowerMindBm\n\n      virtualDevices {\n        UUID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateRadioProfileMutation($networkUUID: UUID!, $input: CreateRadioProfileInput!) {\n    createRadioProfile(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateRadioProfileMutation($networkUUID: UUID!, $input: CreateRadioProfileInput!) {\n    createRadioProfile(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRadioProfileMutation($uuid: UUID!, $input: UpdateRadioProfileInput!) {\n    updateRadioProfile(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRadioProfileMutation($uuid: UUID!, $input: UpdateRadioProfileInput!) {\n    updateRadioProfile(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteRadioProfileMutation($uuid: UUID!) {\n    deleteRadioProfile(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteRadioProfileMutation($uuid: UUID!) {\n    deleteRadioProfile(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyRadioSettingsMutation($networkUUID: UUID!) {\n    copyRadioSettingsForNetworkFromConfig1(networkUUID: $networkUUID) {\n      __typename\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyRadioSettingsMutation($networkUUID: UUID!) {\n    copyRadioSettingsForNetworkFromConfig1(networkUUID: $networkUUID) {\n      __typename\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RPCEAPOLTestMutation($input: RPCEapolTestForSerialsInput!) {\n    rpcWosEapolTestForSerials(input: $input) {\n      status\n      error_reason\n      serialNumber\n    }\n  }\n',
): (typeof documents)['\n  mutation RPCEAPOLTestMutation($input: RPCEapolTestForSerialsInput!) {\n    rpcWosEapolTestForSerials(input: $input) {\n      status\n      error_reason\n      serialNumber\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SSIDsQuery($networkUUID: UUID!) {\n    ssidsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      ssid\n      clientSteeringAssociationSteeringIsEnabled\n      clientSteeringPostassociationSteeringIsEnabled\n      description\n      is80211axEnabled\n      isEnabled\n      isGuest\n      isHidden\n      isIsolateClientsEnabled\n      encryptionProtocol\n      password\n      isBand2GAllowed\n      band2GMinimumBasicRateKbps\n      isBand5GAllowed\n      passwordRotationCadence\n      passwordRotationDayOfMonthLocal\n      passwordRotationDayOfWeekLocal\n      passwordRotationHourOfDayLocal\n      passwordLastRotatedAt\n      passwordNextRotationAt\n      band5GMinimumBasicRateKbps\n      isEnabledForAllAccessPoints\n      roaming80211rIsEnabled\n      roaming80211kIsEnabled\n      roaming80211vIsEnabled\n      encryption80211wProtectedManagementFramesMode\n      hs20HasProfile\n      hs20Enabled\n      hs20OperatorNames\n      hs20VenueNames\n      hs20VenueGroup\n      hs20VenueType\n      hs20AccessNetworkType\n      hs20RoamingConsortiumOIs\n      hs20DomainNames\n      hs20NaiRealms\n      hs20MccMncPairs\n      dynamicVLANMode\n      radiusCoaEnabled\n      radiusCoaPort\n      primaryEncryption8021X {\n        UUID\n      }\n      secondaryEncryption8021X {\n        UUID\n      }\n      enabledAccessPointVirtualDevices {\n        UUID\n      }\n      vlan {\n        UUID\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SSIDsQuery($networkUUID: UUID!) {\n    ssidsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      ssid\n      clientSteeringAssociationSteeringIsEnabled\n      clientSteeringPostassociationSteeringIsEnabled\n      description\n      is80211axEnabled\n      isEnabled\n      isGuest\n      isHidden\n      isIsolateClientsEnabled\n      encryptionProtocol\n      password\n      isBand2GAllowed\n      band2GMinimumBasicRateKbps\n      isBand5GAllowed\n      passwordRotationCadence\n      passwordRotationDayOfMonthLocal\n      passwordRotationDayOfWeekLocal\n      passwordRotationHourOfDayLocal\n      passwordLastRotatedAt\n      passwordNextRotationAt\n      band5GMinimumBasicRateKbps\n      isEnabledForAllAccessPoints\n      roaming80211rIsEnabled\n      roaming80211kIsEnabled\n      roaming80211vIsEnabled\n      encryption80211wProtectedManagementFramesMode\n      hs20HasProfile\n      hs20Enabled\n      hs20OperatorNames\n      hs20VenueNames\n      hs20VenueGroup\n      hs20VenueType\n      hs20AccessNetworkType\n      hs20RoamingConsortiumOIs\n      hs20DomainNames\n      hs20NaiRealms\n      hs20MccMncPairs\n      dynamicVLANMode\n      radiusCoaEnabled\n      radiusCoaPort\n      primaryEncryption8021X {\n        UUID\n      }\n      secondaryEncryption8021X {\n        UUID\n      }\n      enabledAccessPointVirtualDevices {\n        UUID\n      }\n      vlan {\n        UUID\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Hs20AccessNetworkTypes {\n    hs20ValidTypes {\n      accessNetworkTypes {\n        type\n        description\n      }\n      venueTypes {\n        group\n        type\n        description\n      }\n      eapMethods {\n        method\n        description\n        mostUsed\n      }\n      authParams {\n        param\n        description\n        validValues {\n          value\n          description\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Hs20AccessNetworkTypes {\n    hs20ValidTypes {\n      accessNetworkTypes {\n        type\n        description\n      }\n      venueTypes {\n        group\n        type\n        description\n      }\n      eapMethods {\n        method\n        description\n        mostUsed\n      }\n      authParams {\n        param\n        description\n        validValues {\n          value\n          description\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateSSIDMutation($networkUUID: UUID!, $input: CreateSSIDInput!) {\n    createSSID(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateSSIDMutation($networkUUID: UUID!, $input: CreateSSIDInput!) {\n    createSSID(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateSSIDMutation($uuid: UUID!, $input: UpdateSSIDInput!) {\n    updateSSID(uuid: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateSSIDMutation($uuid: UUID!, $input: UpdateSSIDInput!) {\n    updateSSID(uuid: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteSSIDMutation($uuid: UUID!) {\n    deleteSSID(uuid: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteSSIDMutation($uuid: UUID!) {\n    deleteSSID(uuid: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopySSIDsMutation($networkUUID: UUID!) {\n    copySSIDsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopySSIDsMutation($networkUUID: UUID!) {\n    copySSIDsFromConfig1ToConfig2(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RadiusProfilesQuery($networkUUID: UUID!) {\n    encryption8021XsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      authServerIPAddress\n      authServerPort\n      authServerSecret\n      accountingServerIPAddress\n      accountingServerPort\n      accountingServerSecret\n    }\n  }\n',
): (typeof documents)['\n  query RadiusProfilesQuery($networkUUID: UUID!) {\n    encryption8021XsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      authServerIPAddress\n      authServerPort\n      authServerSecret\n      accountingServerIPAddress\n      accountingServerPort\n      accountingServerSecret\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateRadiusProfileMutation($input: CreateEncryption8021XInput!) {\n    createEncryption8021X(input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateRadiusProfileMutation($input: CreateEncryption8021XInput!) {\n    createEncryption8021X(input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateRadiusProfileMutation($uuid: UUID!, $input: UpdateEncryption8021XInput!) {\n    updateEncryption8021X(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateRadiusProfileMutation($uuid: UUID!, $input: UpdateEncryption8021XInput!) {\n    updateEncryption8021X(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteRadiusProfileMutation($uuid: UUID!) {\n    deleteEncryption8021X(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteRadiusProfileMutation($uuid: UUID!) {\n    deleteEncryption8021X(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query WirelessConnectionEventsByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ClientMetricsTimeseriesFilterInput!\n    $limit: Int\n    $offset: Int\n  ) {\n    wirelessClientConnectionEventsByAP(\n      networkUUID: $networkUUID\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      filter: $filter\n      limit: $limit\n      offset: $offset\n    ) {\n      timestamp\n      band\n      eventType\n      failedStep\n      reasonCode\n      sessionDuration\n      timeToConnect\n      macAddress\n      is80211rRoam\n      ssid {\n        UUID\n        ssid\n      }\n      virtualDevice {\n        UUID\n        label\n      }\n      previousVirtualDeviceConnectedTo {\n        UUID\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  query WirelessConnectionEventsByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ClientMetricsTimeseriesFilterInput!\n    $limit: Int\n    $offset: Int\n  ) {\n    wirelessClientConnectionEventsByAP(\n      networkUUID: $networkUUID\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      filter: $filter\n      limit: $limit\n      offset: $offset\n    ) {\n      timestamp\n      band\n      eventType\n      failedStep\n      reasonCode\n      sessionDuration\n      timeToConnect\n      macAddress\n      is80211rRoam\n      ssid {\n        UUID\n        ssid\n      }\n      virtualDevice {\n        UUID\n        label\n      }\n      previousVirtualDeviceConnectedTo {\n        UUID\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query WirelessClientMetricsByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ClientMetricsTimeseriesFilterInput!\n  ) {\n    wirelessClientMetricsByAP(\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      networkUUID: $networkUUID\n      filter: $filter\n    ) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        tx {\n          minValue\n          maxValue\n        }\n        rx {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        signal\n        noise\n        txRate\n        rxRate\n        snr\n        txBytes\n        rxBytes\n        txMulticastBytes\n        rxMulticastBytes\n        txUnicastBytes\n        rxUnicastBytes\n        clientCount\n      }\n    }\n  }\n',
): (typeof documents)['\n  query WirelessClientMetricsByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ClientMetricsTimeseriesFilterInput!\n  ) {\n    wirelessClientMetricsByAP(\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      networkUUID: $networkUUID\n      filter: $filter\n    ) {\n      metadata {\n        signal {\n          minValue\n          maxValue\n        }\n        noise {\n          minValue\n          maxValue\n        }\n        tx {\n          minValue\n          maxValue\n        }\n        rx {\n          minValue\n          maxValue\n        }\n      }\n      values {\n        timestamp\n        signal\n        noise\n        txRate\n        rxRate\n        snr\n        txBytes\n        rxBytes\n        txMulticastBytes\n        rxMulticastBytes\n        txUnicastBytes\n        rxUnicastBytes\n        clientCount\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query APChannelUtilizationByNetworkQuery(\n    $networkUUID: UUID!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByNetwork(networkUUID: $networkUUID, filter: $filter) {\n      band\n      virtualDeviceUUID\n      values {\n        totalUtilization\n        timestamp\n      }\n    }\n  }\n',
): (typeof documents)['\n  query APChannelUtilizationByNetworkQuery(\n    $networkUUID: UUID!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByNetwork(networkUUID: $networkUUID, filter: $filter) {\n      band\n      virtualDeviceUUID\n      values {\n        totalUtilization\n        timestamp\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query APChannelUtilizationByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByAP(\n      networkUUID: $networkUUID\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      filter: $filter\n    ) {\n      band\n      values {\n        totalUtilization\n        timestamp\n      }\n    }\n  }\n',
): (typeof documents)['\n  query APChannelUtilizationByAccessPointQuery(\n    $networkUUID: UUID!\n    $virtualDeviceUUID: UUID!\n    $filter: ChannelUtilizationTimeseriesFilterInput!\n  ) {\n    channelUtilizationByAP(\n      networkUUID: $networkUUID\n      apVirtualDeviceUUID: $virtualDeviceUUID\n      filter: $filter\n    ) {\n      band\n      values {\n        totalUtilization\n        timestamp\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccessPointsQuery(\n    $networkUUID: UUID!\n    $includeUptime: Boolean = false\n    $includeIsDev: Boolean = false\n  ) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: ACCESS_POINT }) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on AccessPointVirtualDevice {\n        ...AccessPointUptimeFragment @include(if: $includeUptime)\n        radioProfile {\n          name\n          UUID\n          band2_4GIsEnabled\n          band2_4GChannelWidthMHz\n          band2_4GAutoChannelIsEnabled\n          band5GIsEnabled\n          band5GChannelWidthMHz\n          band5GAutoChannelIsEnabled\n        }\n\n        radioSettings {\n          band2_4GPrimaryChannel\n          band2_4GTransmitPowerdBm\n          band5GPrimaryChannel\n          band5GTransmitPowerdBm\n        }\n\n        connectedClients {\n          macAddress\n          band\n          channel\n          ssidName\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n\n        ... on AccessPointHardwareDevice {\n          ipAddress\n          isInCurrentControllerMACTable\n          phyInterfaceConnectedTo {\n            UUID\n            portSpeedMbps\n            maxSpeedMbps\n            isEthernet\n            isSFP\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccessPointsQuery(\n    $networkUUID: UUID!\n    $includeUptime: Boolean = false\n    $includeIsDev: Boolean = false\n  ) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: ACCESS_POINT }) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n\n      ... on AccessPointVirtualDevice {\n        ...AccessPointUptimeFragment @include(if: $includeUptime)\n        radioProfile {\n          name\n          UUID\n          band2_4GIsEnabled\n          band2_4GChannelWidthMHz\n          band2_4GAutoChannelIsEnabled\n          band5GIsEnabled\n          band5GChannelWidthMHz\n          band5GAutoChannelIsEnabled\n        }\n\n        radioSettings {\n          band2_4GPrimaryChannel\n          band2_4GTransmitPowerdBm\n          band5GPrimaryChannel\n          band5GTransmitPowerdBm\n        }\n\n        connectedClients {\n          macAddress\n          band\n          channel\n          ssidName\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n        isDev @include(if: $includeIsDev)\n\n        ... on AccessPointHardwareDevice {\n          ipAddress\n          isInCurrentControllerMACTable\n          phyInterfaceConnectedTo {\n            UUID\n            portSpeedMbps\n            maxSpeedMbps\n            isEthernet\n            isSFP\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AccessPointUptimeFragment on AccessPointVirtualDevice {\n    uptime\n  }\n',
): (typeof documents)['\n  fragment AccessPointUptimeFragment on AccessPointVirtualDevice {\n    uptime\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccessPointQuery($uuid: UUID!, $includeUptime: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      isConsoleEnabled\n      networkUUID\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      ... on AccessPointVirtualDevice {\n        isLEDDarkModeEnabled\n        ...AccessPointUptimeFragment @include(if: $includeUptime)\n\n        radioProfile {\n          UUID\n          band2_4GIsEnabled\n          band2_4GChannelWidthMHz\n          band2_4GAutoChannelIsEnabled\n          band5GIsEnabled\n          band5GChannelWidthMHz\n          band5GAutoChannelIsEnabled\n        }\n\n        radioSettings {\n          band2_4GPrimaryChannel\n          band2_4GTransmitPowerdBm\n          band5GPrimaryChannel\n          band5GTransmitPowerdBm\n        }\n\n        connectedClients {\n          macAddress\n          band\n          channel\n          ssidName\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n\n        ... on AccessPointHardwareDevice {\n          ipAddress\n          phyInterfaceConnectedTo {\n            isSFP\n            portSpeedMbps\n            maxSpeedMbps\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccessPointQuery($uuid: UUID!, $includeUptime: Boolean = false) {\n    virtualDevice(UUID: $uuid) {\n      __typename\n      UUID\n      label\n      description\n      deviceType\n      deviceModel\n      isConsoleEnabled\n      networkUUID\n      nosVersion {\n        id\n        version\n        buildName\n      }\n      pendingNosVersion {\n        nosVersion {\n          version\n        }\n        scheduledAt\n      }\n      ... on AccessPointVirtualDevice {\n        isLEDDarkModeEnabled\n        ...AccessPointUptimeFragment @include(if: $includeUptime)\n\n        radioProfile {\n          UUID\n          band2_4GIsEnabled\n          band2_4GChannelWidthMHz\n          band2_4GAutoChannelIsEnabled\n          band5GIsEnabled\n          band5GChannelWidthMHz\n          band5GAutoChannelIsEnabled\n        }\n\n        radioSettings {\n          band2_4GPrimaryChannel\n          band2_4GTransmitPowerdBm\n          band5GPrimaryChannel\n          band5GTransmitPowerdBm\n        }\n\n        connectedClients {\n          macAddress\n          band\n          channel\n          ssidName\n        }\n      }\n\n      hardwareDevice {\n        __typename\n        deviceType\n        serialNumber\n        macAddress\n        tunnelIPAddress\n        deviceModel\n        isActive\n        isConnectedToBackend\n        disconnectedFromBackendAt\n        bootHistory(count: 1) {\n          buildName\n        }\n\n        ... on AccessPointHardwareDevice {\n          ipAddress\n          phyInterfaceConnectedTo {\n            isSFP\n            portSpeedMbps\n            maxSpeedMbps\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyAccessPointLabelsMutation($networkUUID: UUID!) {\n    copyAccessPointConfig1LocationsToConfig2Labels(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyAccessPointLabelsMutation($networkUUID: UUID!) {\n    copyAccessPointConfig1LocationsToConfig2Labels(networkUUID: $networkUUID) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateVirtualDevicesMutation(\n    $virtualDeviceUUIDs: [UUID!]!\n    $networkUUID: UUID!\n    $input: UpdateVirtualDeviceInput!\n  ) {\n    updateVirtualDevices(\n      networkUUID: $networkUUID\n      virtualDeviceUUIDs: $virtualDeviceUUIDs\n      input: $input\n    ) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateVirtualDevicesMutation(\n    $virtualDeviceUUIDs: [UUID!]!\n    $networkUUID: UUID!\n    $input: UpdateVirtualDeviceInput!\n  ) {\n    updateVirtualDevices(\n      networkUUID: $networkUUID\n      virtualDeviceUUIDs: $virtualDeviceUUIDs\n      input: $input\n    ) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FallbackAccessPointHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n      virtualDevice {\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FallbackAccessPointHardwareQuery($serialNumber: String!) {\n    hardwareDevice(serialNumber: $serialNumber) {\n      serialNumber\n      virtualDeviceUUID\n      virtualDevice {\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BSSIDsForNetworkQuery($networkUUID: UUID!) {\n    bssidsForNetwork(networkUUID: $networkUUID) {\n      BSSID\n      SSID {\n        ssid\n      }\n      isActive\n      radioBand\n      accessPointHardwareDevice {\n        virtualDevice {\n          UUID\n          label\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query BSSIDsForNetworkQuery($networkUUID: UUID!) {\n    bssidsForNetwork(networkUUID: $networkUUID) {\n      BSSID\n      SSID {\n        ssid\n      }\n      isActive\n      radioBand\n      accessPointHardwareDevice {\n        virtualDevice {\n          UUID\n          label\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AutoVPNMemberFields on AutoVPNMember {\n    __typename\n    UUID\n    networkUUID\n    network {\n      UUID\n      slug\n      label\n    }\n    isFailoverEnabled\n    permittedVLANs {\n      __typename\n      UUID\n      name\n      vlanID\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n    uplink {\n      UUID\n      address\n      addressPrefixLength\n      hubUplinkUUID\n      publicKey\n      listenPort\n      phyInterfaceUUID\n      phyInterface {\n        ...PhyInterfaceLabelFields\n      }\n      connectionInfo {\n        observedAt\n        lastHandshakeRx\n        lastHandshakeTx\n        lastPacketTx\n        lastPacketRx\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment AutoVPNMemberFields on AutoVPNMember {\n    __typename\n    UUID\n    networkUUID\n    network {\n      UUID\n      slug\n      label\n    }\n    isFailoverEnabled\n    permittedVLANs {\n      __typename\n      UUID\n      name\n      vlanID\n      ipV4ClientGateway\n      ipV4ClientPrefixLength\n    }\n    uplink {\n      UUID\n      address\n      addressPrefixLength\n      hubUplinkUUID\n      publicKey\n      listenPort\n      phyInterfaceUUID\n      phyInterface {\n        ...PhyInterfaceLabelFields\n      }\n      connectionInfo {\n        observedAt\n        lastHandshakeRx\n        lastHandshakeTx\n        lastPacketTx\n        lastPacketRx\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AutoVPNGroupFields on AutoVPNGroup {\n    __typename\n    UUID\n    name\n    isEnabled\n    hubNetworkUUID\n    hubNetwork {\n      UUID\n      slug\n      label\n    }\n    hubUplink {\n      address\n      addressPrefixLength\n      phyInterface {\n        UUID\n        ...PhyInterfaceLabelFields\n      }\n    }\n    isHubFailoverEnabled\n    members {\n      ...AutoVPNMemberFields\n    }\n    routes {\n      __typename\n      UUID\n      name\n      isEnabled\n      dstPrefixLength\n      dstGateway\n    }\n  }\n',
): (typeof documents)['\n  fragment AutoVPNGroupFields on AutoVPNGroup {\n    __typename\n    UUID\n    name\n    isEnabled\n    hubNetworkUUID\n    hubNetwork {\n      UUID\n      slug\n      label\n    }\n    hubUplink {\n      address\n      addressPrefixLength\n      phyInterface {\n        UUID\n        ...PhyInterfaceLabelFields\n      }\n    }\n    isHubFailoverEnabled\n    members {\n      ...AutoVPNMemberFields\n    }\n    routes {\n      __typename\n      UUID\n      name\n      isEnabled\n      dstPrefixLength\n      dstGateway\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutoVPNGroupsForCompany($companySlug: String!) {\n    autoVPNGroupsForCompany(companySlug: $companySlug) {\n      ...AutoVPNGroupFields\n    }\n  }\n',
): (typeof documents)['\n  query AutoVPNGroupsForCompany($companySlug: String!) {\n    autoVPNGroupsForCompany(companySlug: $companySlug) {\n      ...AutoVPNGroupFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutoVPNGroup($uuid: UUID!) {\n    autoVPNGroup(UUID: $uuid) {\n      ...AutoVPNGroupFields\n    }\n  }\n',
): (typeof documents)['\n  query AutoVPNGroup($uuid: UUID!) {\n    autoVPNGroup(UUID: $uuid) {\n      ...AutoVPNGroupFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutoVPNMemberQuery($uuid: UUID!) {\n    autoVPNMember(UUID: $uuid) {\n      ...AutoVPNMemberFields\n    }\n  }\n',
): (typeof documents)['\n  query AutoVPNMemberQuery($uuid: UUID!) {\n    autoVPNMember(UUID: $uuid) {\n      ...AutoVPNMemberFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateAutoVPNGroup($companySlug: String!, $input: CreateAutoVPNGroupInput!) {\n    createAutoVPNGroup(companySlug: $companySlug, input: $input) {\n      UUID\n      companySlug\n      isEnabled\n      name\n      hubNetworkUUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateAutoVPNGroup($companySlug: String!, $input: CreateAutoVPNGroupInput!) {\n    createAutoVPNGroup(companySlug: $companySlug, input: $input) {\n      UUID\n      companySlug\n      isEnabled\n      name\n      hubNetworkUUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateAutoVPNGroup($UUID: UUID!, $input: UpdateAutoVPNGroupInput!) {\n    updateAutoVPNGroup(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateAutoVPNGroup($UUID: UUID!, $input: UpdateAutoVPNGroupInput!) {\n    updateAutoVPNGroup(UUID: $UUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteAutoVPNGroup($uuid: UUID!) {\n    deleteAutoVPNGroup(UUID: $uuid) {\n      UUID\n      companySlug\n      isEnabled\n      name\n      hubNetworkUUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteAutoVPNGroup($uuid: UUID!) {\n    deleteAutoVPNGroup(UUID: $uuid) {\n      UUID\n      companySlug\n      isEnabled\n      name\n      hubNetworkUUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddNetworkToAutoVPNGroup($groupUUID: UUID!, $input: AddNetworkToAutoVPNGroupInput!) {\n    addNetworkToAutoVPNGroup(groupUUID: $groupUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation AddNetworkToAutoVPNGroup($groupUUID: UUID!, $input: AddNetworkToAutoVPNGroupInput!) {\n    addNetworkToAutoVPNGroup(groupUUID: $groupUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateAutoVPNMember($uuid: UUID!, $input: UpdateAutoVPNMemberInput!) {\n    updateAutoVPNMember(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateAutoVPNMember($uuid: UUID!, $input: UpdateAutoVPNMemberInput!) {\n    updateAutoVPNMember(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveNetworkFromAutoVPNGroup($groupUUID: UUID!, $networkUUID: UUID!) {\n    removeNetworkFromAutoVPNGroup(groupUUID: $groupUUID, networkUUID: $networkUUID)\n  }\n',
): (typeof documents)['\n  mutation RemoveNetworkFromAutoVPNGroup($groupUUID: UUID!, $networkUUID: UUID!) {\n    removeNetworkFromAutoVPNGroup(groupUUID: $groupUUID, networkUUID: $networkUUID)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateAutoVPNRoute($groupUUID: UUID!, $input: CreateAutoVPNRouteInput!) {\n    createAutoVPNRoute(groupUUID: $groupUUID, input: $input) {\n      UUID\n      createdAt\n      updatedAt\n      dstGateway\n      dstPrefixLength\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateAutoVPNRoute($groupUUID: UUID!, $input: CreateAutoVPNRouteInput!) {\n    createAutoVPNRoute(groupUUID: $groupUUID, input: $input) {\n      UUID\n      createdAt\n      updatedAt\n      dstGateway\n      dstPrefixLength\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteAutoVPNRoute($uuid: UUID!) {\n    deleteAutoVPNRoute(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteAutoVPNRoute($uuid: UUID!) {\n    deleteAutoVPNRoute(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworksWithControllerPorts($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      UUID\n      label\n      isActive\n      isTemplate\n      virtualDevices(filter: { deviceType: CONTROLLER }) {\n        __typename\n        label\n        ... on ControllerVirtualDevice {\n          phyInterfaces {\n            UUID\n            isUplink\n            ...PhyInterfaceLabelFields\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query NetworksWithControllerPorts($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      UUID\n      label\n      isActive\n      isTemplate\n      virtualDevices(filter: { deviceType: CONTROLLER }) {\n        __typename\n        label\n        ... on ControllerVirtualDevice {\n          phyInterfaces {\n            UUID\n            isUplink\n            ...PhyInterfaceLabelFields\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateNOSFirmwareUpgradeMutation($input: CreateBulkNOSUpgradeInput!) {\n    createBulkNOSUpgrade(input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateNOSFirmwareUpgradeMutation($input: CreateBulkNOSUpgradeInput!) {\n    createBulkNOSUpgrade(input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BulkNOSUpgradeQuery($uuid: UUID!) {\n    bulkNOSUpgrade(UUID: $uuid) {\n      UUID\n      createdAt\n      updatedAt\n      updatedBy\n      nosVersionID\n      networks {\n        __typename\n        UUID\n        label\n        slug\n        companySlug\n        company {\n          name\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query BulkNOSUpgradeQuery($uuid: UUID!) {\n    bulkNOSUpgrade(UUID: $uuid) {\n      UUID\n      createdAt\n      updatedAt\n      updatedBy\n      nosVersionID\n      networks {\n        __typename\n        UUID\n        label\n        slug\n        companySlug\n        company {\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BulkNOSUpgradesQuery($filter: BulkNOSUpgradesFilterInput!) {\n    bulkNOSUpgrades(filter: $filter) {\n      UUID\n      createdAt\n      updatedAt\n      updatedBy\n      nosVersionID\n      networks {\n        UUID\n        label\n      }\n    }\n  }\n',
): (typeof documents)['\n  query BulkNOSUpgradesQuery($filter: BulkNOSUpgradesFilterInput!) {\n    bulkNOSUpgrades(filter: $filter) {\n      UUID\n      createdAt\n      updatedAt\n      updatedBy\n      nosVersionID\n      networks {\n        UUID\n        label\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworksByFilterQuery($filter: NetworksFilter!) {\n    networks(filter: $filter) {\n      __typename\n      UUID\n      label\n      slug\n      companySlug\n      company {\n        name\n        supportTier\n      }\n      virtualDevices {\n        hardwareDevice {\n          deviceType\n          deviceModel\n        }\n      }\n      pinnedNOSVersionID\n      pendingNOSVersionID\n      lastUpgradedAt\n      isUpgradeSensitive\n      isActive\n    }\n  }\n',
): (typeof documents)['\n  query NetworksByFilterQuery($filter: NetworksFilter!) {\n    networks(filter: $filter) {\n      __typename\n      UUID\n      label\n      slug\n      companySlug\n      company {\n        name\n        supportTier\n      }\n      virtualDevices {\n        hardwareDevice {\n          deviceType\n          deviceModel\n        }\n      }\n      pinnedNOSVersionID\n      pendingNOSVersionID\n      lastUpgradedAt\n      isUpgradeSensitive\n      isActive\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetEventLogs($networkUUID: UUID!, $startTime: DateTime, $endTime: DateTime) {\n    recentEventLogEvents(\n      limit: 100\n      offset: 0\n      networkUUID: $networkUUID\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      eventType\n      generatedAt\n      networkUUID\n      properties {\n        ... on WANStatusChangeProperties {\n          virtualDevice {\n            __typename\n            UUID\n            ... on ControllerVirtualDevice {\n              phyInterfaces {\n                UUID\n                portNumber\n                uplinkPriority\n                isUplink\n              }\n            }\n          }\n          activeWANConnections {\n            externalAddresses\n            internetServicePlanProvider {\n              name\n            }\n            port\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetEventLogs($networkUUID: UUID!, $startTime: DateTime, $endTime: DateTime) {\n    recentEventLogEvents(\n      limit: 100\n      offset: 0\n      networkUUID: $networkUUID\n      startTime: $startTime\n      endTime: $endTime\n    ) {\n      eventType\n      generatedAt\n      networkUUID\n      properties {\n        ... on WANStatusChangeProperties {\n          virtualDevice {\n            __typename\n            UUID\n            ... on ControllerVirtualDevice {\n              phyInterfaces {\n                UUID\n                portNumber\n                uplinkPriority\n                isUplink\n              }\n            }\n          }\n          activeWANConnections {\n            externalAddresses\n            internetServicePlanProvider {\n              name\n            }\n            port\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query networkClients($networkUUID: UUID!, $filter: NetworkClientsFilter) {\n    networkClients(networkUUID: $networkUUID, filter: $filter) {\n      alias\n      apLocation\n      apSerialNumber\n      channel\n      clientName\n      ip\n      isWireless\n      lastSeen\n      dhcpLeaseExpiresAt\n      macAddress\n      noise\n      totalRxBytes\n      rxRate\n      signal\n      ssid\n      txRate\n      totalTxBytes\n      vlan\n      vlanUUID\n      connectedVLAN {\n        UUID\n        name\n        vlanID\n      }\n      associatedAt\n      throughput {\n        txRate\n        rxRate\n      }\n      serviceTypes\n      wirelessScore\n    }\n  }\n',
): (typeof documents)['\n  query networkClients($networkUUID: UUID!, $filter: NetworkClientsFilter) {\n    networkClients(networkUUID: $networkUUID, filter: $filter) {\n      alias\n      apLocation\n      apSerialNumber\n      channel\n      clientName\n      ip\n      isWireless\n      lastSeen\n      dhcpLeaseExpiresAt\n      macAddress\n      noise\n      totalRxBytes\n      rxRate\n      signal\n      ssid\n      txRate\n      totalTxBytes\n      vlan\n      vlanUUID\n      connectedVLAN {\n        UUID\n        name\n        vlanID\n      }\n      associatedAt\n      throughput {\n        txRate\n        rxRate\n      }\n      serviceTypes\n      wirelessScore\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetCaptivePortal($vlanUUID: UUID!) {\n    captivePortalForVLAN(vlanUUID: $vlanUUID) {\n      displaySettings {\n        fontColor\n      }\n      logoImageURL\n      redirectURL\n      termsAndConditions\n      vlans {\n        UUID\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetCaptivePortal($vlanUUID: UUID!) {\n    captivePortalForVLAN(vlanUUID: $vlanUUID) {\n      displaySettings {\n        fontColor\n      }\n      logoImageURL\n      redirectURL\n      termsAndConditions\n      vlans {\n        UUID\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getNetworkClientsTxRxPerDay($networkUUID: UUID!, $numDaysLookback: Int!) {\n    networkClientsTxRxPerDay(networkUUID: $networkUUID, numDaysLookback: $numDaysLookback) {\n      ip\n      macAddress\n      dateString\n      txBytes\n      rxBytes\n    }\n  }\n',
): (typeof documents)['\n  query getNetworkClientsTxRxPerDay($networkUUID: UUID!, $numDaysLookback: Int!) {\n    networkClientsTxRxPerDay(networkUUID: $networkUUID, numDaysLookback: $numDaysLookback) {\n      ip\n      macAddress\n      dateString\n      txBytes\n      rxBytes\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Company($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      name\n      slug\n      isCustomer\n      supportGroup\n    }\n  }\n',
): (typeof documents)['\n  query Company($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      name\n      slug\n      isCustomer\n      supportGroup\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworkStatusQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      description\n      deviceModel\n      hardwareDevice {\n        serialNumber\n        macAddress\n        isConnectedToBackend\n      }\n    }\n  }\n',
): (typeof documents)['\n  query NetworkStatusQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      UUID\n      label\n      description\n      deviceModel\n      hardwareDevice {\n        serialNumber\n        macAddress\n        isConnectedToBackend\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment NetworkForCompanyFields on Network {\n    UUID\n    label\n    slug\n    isActive\n    isCustomer\n    isTemplate\n    isTrial\n    company {\n      slug\n      name\n      uuid\n      supportGroup\n    }\n    virtualDevices(filter: { deviceType: CONTROLLER }) {\n      __typename\n      UUID\n      label\n      deviceType\n      hardwareDevice {\n        serialNumber\n        isActive\n        isConnectedToBackend\n      }\n      ... on ControllerVirtualDevice {\n        highAvailability {\n          role\n          status\n        }\n      }\n    }\n    mailingAddress {\n      line1\n      line2\n      city\n      subdivisionCode\n      postalCode\n      timezone\n      countryISO2\n    }\n    enabledServiceDiscoveryTypes\n    multiWANAlgorithm\n    pinnedNOSVersionID\n  }\n',
): (typeof documents)['\n  fragment NetworkForCompanyFields on Network {\n    UUID\n    label\n    slug\n    isActive\n    isCustomer\n    isTemplate\n    isTrial\n    company {\n      slug\n      name\n      uuid\n      supportGroup\n    }\n    virtualDevices(filter: { deviceType: CONTROLLER }) {\n      __typename\n      UUID\n      label\n      deviceType\n      hardwareDevice {\n        serialNumber\n        isActive\n        isConnectedToBackend\n      }\n      ... on ControllerVirtualDevice {\n        highAvailability {\n          role\n          status\n        }\n      }\n    }\n    mailingAddress {\n      line1\n      line2\n      city\n      subdivisionCode\n      postalCode\n      timezone\n      countryISO2\n    }\n    enabledServiceDiscoveryTypes\n    multiWANAlgorithm\n    pinnedNOSVersionID\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworksForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      ...NetworkForCompanyFields\n    }\n  }\n',
): (typeof documents)['\n  query NetworksForCompany($companySlug: String!) {\n    networksForCompany(companySlug: $companySlug) {\n      ...NetworkForCompanyFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworkByUUIDQuery($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      ...NetworkForCompanyFields\n    }\n  }\n',
): (typeof documents)['\n  query NetworkByUUIDQuery($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      ...NetworkForCompanyFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query IsNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKey: $nosFeatureKey)\n  }\n',
): (typeof documents)['\n  query IsNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKey: $nosFeatureKey)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AreNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKeys: [String!]!) {\n    areNosFeaturesEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKeys: $nosFeatureKeys) {\n      key\n      enabled\n    }\n  }\n',
): (typeof documents)['\n  query AreNosFeatureEnabledForNetwork($networkUUID: UUID!, $nosFeatureKeys: [String!]!) {\n    areNosFeaturesEnabledForNetwork(networkUUID: $networkUUID, nosFeatureKeys: $nosFeatureKeys) {\n      key\n      enabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query IsNosFeatureEnabledForCompany($companySlug: String!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForCompany(companySlug: $companySlug, nosFeatureKey: $nosFeatureKey)\n  }\n',
): (typeof documents)['\n  query IsNosFeatureEnabledForCompany($companySlug: String!, $nosFeatureKey: String!) {\n    isNosFeatureEnabledForCompany(companySlug: $companySlug, nosFeatureKey: $nosFeatureKey)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AreNosFeatureEnabledForCompany($companySlug: String!, $nosFeatureKeys: [String!]!) {\n    areNosFeaturesEnabledForCompany(companySlug: $companySlug, nosFeatureKeys: $nosFeatureKeys) {\n      key\n      enabled\n    }\n  }\n',
): (typeof documents)['\n  query AreNosFeatureEnabledForCompany($companySlug: String!, $nosFeatureKeys: [String!]!) {\n    areNosFeaturesEnabledForCompany(companySlug: $companySlug, nosFeatureKeys: $nosFeatureKeys) {\n      key\n      enabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ValidChannelsQuery($networkUUID: UUID!) {\n    validChannels(networkUUID: $networkUUID) {\n      band\n      channels\n    }\n  }\n',
): (typeof documents)['\n  query ValidChannelsQuery($networkUUID: UUID!) {\n    validChannels(networkUUID: $networkUUID) {\n      band\n      channels\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CompanyUUIDQuery($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      __typename\n    }\n  }\n',
): (typeof documents)['\n  query CompanyUUIDQuery($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      __typename\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Permissions($input: PermissionsInput!) {\n    permissions(input: $input) {\n      networkUUID\n      permissions\n    }\n  }\n',
): (typeof documents)['\n  query Permissions($input: PermissionsInput!) {\n    permissions(input: $input) {\n      networkUUID\n      permissions\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query IPSecTunnelsQuery($networkUUID: UUID!) {\n    ipSecTunnelsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      isEnabled\n      isInitiator\n      authenticationAlgorithm\n      encryptionAlgorithm\n      keyExchangeDHGroup\n      right\n      rightPrefixes\n      rightID\n      leftID\n      boundPhyInterface {\n        UUID\n        label\n        portNumber\n        virtualDevice {\n          label\n          hardwareDevice {\n            isSpare\n            isConnectedToBackend\n            deviceType\n          }\n        }\n      }\n      boundVLANs {\n        UUID\n        vlanID\n        name\n      }\n      status {\n        observedAt\n        saStatus\n        uniqueID\n        remoteHostIP4Address\n        establishedAt\n        rekeyAt\n        reauthAt\n        childSAStatus\n        rxBytes\n        txBytes\n        rxPackets\n        rxLast\n        txLast\n        childRekeyAt\n        childLifetime\n        latencyMs\n      }\n    }\n  }\n',
): (typeof documents)['\n  query IPSecTunnelsQuery($networkUUID: UUID!) {\n    ipSecTunnelsForNetwork(networkUUID: $networkUUID) {\n      UUID\n      name\n      isEnabled\n      isInitiator\n      authenticationAlgorithm\n      encryptionAlgorithm\n      keyExchangeDHGroup\n      right\n      rightPrefixes\n      rightID\n      leftID\n      boundPhyInterface {\n        UUID\n        label\n        portNumber\n        virtualDevice {\n          label\n          hardwareDevice {\n            isSpare\n            isConnectedToBackend\n            deviceType\n          }\n        }\n      }\n      boundVLANs {\n        UUID\n        vlanID\n        name\n      }\n      status {\n        observedAt\n        saStatus\n        uniqueID\n        remoteHostIP4Address\n        establishedAt\n        rekeyAt\n        reauthAt\n        childSAStatus\n        rxBytes\n        txBytes\n        rxPackets\n        rxLast\n        txLast\n        childRekeyAt\n        childLifetime\n        latencyMs\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query IPSecTunnelQuery($UUID: UUID!) {\n    ipSecTunnel(UUID: $UUID) {\n      UUID\n      name\n      isEnabled\n      right\n      rightPrefixes\n      rightID\n      leftID\n      isInitiator\n      authenticationAlgorithm\n      encryptionAlgorithm\n      keyExchangeDHGroup\n      boundPhyInterface {\n        UUID\n        portNumber\n        virtualDevice {\n          label\n          hardwareDevice {\n            isSpare\n            isConnectedToBackend\n            deviceType\n            serialNumber\n          }\n        }\n      }\n      boundVLANs {\n        UUID\n        vlanID\n        name\n      }\n      status {\n        observedAt\n        saStatus\n        uniqueID\n        remoteHostIP4Address\n        establishedAt\n        rekeyAt\n        reauthAt\n        childSAStatus\n        rxBytes\n        txBytes\n        rxPackets\n        rxLast\n        txLast\n        childRekeyAt\n        childLifetime\n        latencyMs\n      }\n    }\n  }\n',
): (typeof documents)['\n  query IPSecTunnelQuery($UUID: UUID!) {\n    ipSecTunnel(UUID: $UUID) {\n      UUID\n      name\n      isEnabled\n      right\n      rightPrefixes\n      rightID\n      leftID\n      isInitiator\n      authenticationAlgorithm\n      encryptionAlgorithm\n      keyExchangeDHGroup\n      boundPhyInterface {\n        UUID\n        portNumber\n        virtualDevice {\n          label\n          hardwareDevice {\n            isSpare\n            isConnectedToBackend\n            deviceType\n            serialNumber\n          }\n        }\n      }\n      boundVLANs {\n        UUID\n        vlanID\n        name\n      }\n      status {\n        observedAt\n        saStatus\n        uniqueID\n        remoteHostIP4Address\n        establishedAt\n        rekeyAt\n        reauthAt\n        childSAStatus\n        rxBytes\n        txBytes\n        rxPackets\n        rxLast\n        txLast\n        childRekeyAt\n        childLifetime\n        latencyMs\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateIPSecTunnel($uuid: UUID!, $input: UpdateIPSecTunnelInput!) {\n    updateIPSecTunnel(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateIPSecTunnel($uuid: UUID!, $input: UpdateIPSecTunnelInput!) {\n    updateIPSecTunnel(UUID: $uuid, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteIPSecTunnel($uuid: UUID!) {\n    deleteIPSecTunnel(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteIPSecTunnel($uuid: UUID!) {\n    deleteIPSecTunnel(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateIPSecTunnel($networkUUID: UUID!, $input: CreateIPSecTunnelInput!) {\n    createIPSecTunnel(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateIPSecTunnel($networkUUID: UUID!, $input: CreateIPSecTunnelInput!) {\n    createIPSecTunnel(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RpcRestartIPSecTunnel($serialNumber: String!, $ipSecTunnelUUID: UUID!) {\n    rpcRestartIPSecTunnel(serialNumber: $serialNumber, ipSecTunnelUUID: $ipSecTunnelUUID)\n  }\n',
): (typeof documents)['\n  mutation RpcRestartIPSecTunnel($serialNumber: String!, $ipSecTunnelUUID: UUID!) {\n    rpcRestartIPSecTunnel(serialNumber: $serialNumber, ipSecTunnelUUID: $ipSecTunnelUUID)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteNOSFirmwareUpgradeMutation($uuid: UUID!) {\n    deleteBulkNOSUpgrade(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteNOSFirmwareUpgradeMutation($uuid: UUID!) {\n    deleteBulkNOSUpgrade(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SwitchCountQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  query SwitchCountQuery($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VirtualDevicesForTopology($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      label\n      deviceType\n\n      hardwareDevice {\n        __typename\n        serialNumber\n        deviceType\n        isActive\n        isConnectedToBackend\n      }\n\n      ... on ControllerVirtualDevice {\n        phyInterfaces {\n          virtualDeviceUUID\n          isUplink\n          connectedDevices {\n            hardwareDevice {\n              serialNumber\n              deviceType\n              isActive\n              virtualDeviceUUID\n              isConnectedToBackend\n            }\n          }\n          internetServicePlan {\n            provider {\n              UUID\n              name\n            }\n          }\n          isUplinkActive\n        }\n      }\n\n      ... on SwitchVirtualDevice {\n        phyInterfaces {\n          isUplink\n          connectedDevices {\n            hardwareDevice {\n              serialNumber\n              deviceType\n              isActive\n              virtualDeviceUUID\n              isConnectedToBackend\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query VirtualDevicesForTopology($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      __typename\n      UUID\n      label\n      deviceType\n\n      hardwareDevice {\n        __typename\n        serialNumber\n        deviceType\n        isActive\n        isConnectedToBackend\n      }\n\n      ... on ControllerVirtualDevice {\n        phyInterfaces {\n          virtualDeviceUUID\n          isUplink\n          connectedDevices {\n            hardwareDevice {\n              serialNumber\n              deviceType\n              isActive\n              virtualDeviceUUID\n              isConnectedToBackend\n            }\n          }\n          internetServicePlan {\n            provider {\n              UUID\n              name\n            }\n          }\n          isUplinkActive\n        }\n      }\n\n      ... on SwitchVirtualDevice {\n        phyInterfaces {\n          isUplink\n          connectedDevices {\n            hardwareDevice {\n              serialNumber\n              deviceType\n              isActive\n              virtualDeviceUUID\n              isConnectedToBackend\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MutationAuditLogEntriesForNetwork($networkUUID: UUID!, $filter: MutationAuditLogFilter) {\n    mutationAuditLogEntriesForNetwork(networkUUID: $networkUUID, filter: $filter) {\n      UUID\n      networkUUID\n      createdAt\n      username\n      action\n      args\n      reason\n    }\n  }\n',
): (typeof documents)['\n  query MutationAuditLogEntriesForNetwork($networkUUID: UUID!, $filter: MutationAuditLogFilter) {\n    mutationAuditLogEntriesForNetwork(networkUUID: $networkUUID, filter: $filter) {\n      UUID\n      networkUUID\n      createdAt\n      username\n      action\n      args\n      reason\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query statsForVirtualDevices($networkUUID: UUID!, $filter: NetworkStatsFilter!) {\n    statsForVirtualDevices(networkUUID: $networkUUID, filter: $filter) {\n      observedAt\n      insertedAt\n      networkUUID\n      virtualDeviceUUID\n      type\n      rawData\n    }\n  }\n',
): (typeof documents)['\n  query statsForVirtualDevices($networkUUID: UUID!, $filter: NetworkStatsFilter!) {\n    statsForVirtualDevices(networkUUID: $networkUUID, filter: $filter) {\n      observedAt\n      insertedAt\n      networkUUID\n      virtualDeviceUUID\n      type\n      rawData\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query onboardingForNetwork($networkSlug: String!, $companySlug: String!) {\n    networkBySlug(companySlug: $companySlug, networkSlug: $networkSlug) {\n      UUID\n      onboarding {\n        networkUUID\n        jobData {\n          fullAddress\n          installationStartDate\n          installationEndDate\n          jobOwnerUser\n          jobStage\n          jobStatus\n          jobTechnicianName\n          jobTechnicianPhone\n          onsiteContactInformation\n          scheduleSiteSurveyLink\n          siteSurveyDate\n          siteSurveyStatus\n          targetGoLiveDate\n          totalSqFt\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query onboardingForNetwork($networkSlug: String!, $companySlug: String!) {\n    networkBySlug(companySlug: $companySlug, networkSlug: $networkSlug) {\n      UUID\n      onboarding {\n        networkUUID\n        jobData {\n          fullAddress\n          installationStartDate\n          installationEndDate\n          jobOwnerUser\n          jobStage\n          jobStatus\n          jobTechnicianName\n          jobTechnicianPhone\n          onsiteContactInformation\n          scheduleSiteSurveyLink\n          siteSurveyDate\n          siteSurveyStatus\n          targetGoLiveDate\n          totalSqFt\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query NetworkForOnboardingDocument($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      onboardingDocument {\n        document\n        delegations {\n          UUID\n          fieldName\n          email\n          description\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query NetworkForOnboardingDocument($networkUUID: UUID!) {\n    network(UUID: $networkUUID) {\n      UUID\n      onboardingDocument {\n        document\n        delegations {\n          UUID\n          fieldName\n          email\n          description\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpsertNetworkOnboardingDocument($networkUUID: UUID!, $document: JSONObject!) {\n    upsertNetworkOnboardingDocument(networkUUID: $networkUUID, document: $document) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpsertNetworkOnboardingDocument($networkUUID: UUID!, $document: JSONObject!) {\n    upsertNetworkOnboardingDocument(networkUUID: $networkUUID, document: $document) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpsertNetworkOnboardingFieldDelegation(\n    $networkUUID: UUID!\n    $fieldName: String!\n    $input: UpsertNetworkOnboardingFieldDelegationInput!\n  ) {\n    upsertNetworkOnboardingFieldDelegation(\n      networkUUID: $networkUUID\n      fieldName: $fieldName\n      input: $input\n    ) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation UpsertNetworkOnboardingFieldDelegation(\n    $networkUUID: UUID!\n    $fieldName: String!\n    $input: UpsertNetworkOnboardingFieldDelegationInput!\n  ) {\n    upsertNetworkOnboardingFieldDelegation(\n      networkUUID: $networkUUID\n      fieldName: $fieldName\n      input: $input\n    ) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteNetworkOnboardingFieldDelegation($uuid: UUID!) {\n    deleteNetworkOnboardingFieldDelegation(UUID: $uuid) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteNetworkOnboardingFieldDelegation($uuid: UUID!) {\n    deleteNetworkOnboardingFieldDelegation(UUID: $uuid) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query LatestDeviceConfig($serialNumber: String!) {\n    latestDeviceConfig(serialNumber: $serialNumber) {\n      configJSON\n    }\n  }\n',
): (typeof documents)['\n  query LatestDeviceConfig($serialNumber: String!) {\n    latestDeviceConfig(serialNumber: $serialNumber) {\n      configJSON\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceConfigOverride($serialNumber: String!) {\n    deviceConfigOverride(serialNumber: $serialNumber) {\n      configJSON\n    }\n  }\n',
): (typeof documents)['\n  query DeviceConfigOverride($serialNumber: String!) {\n    deviceConfigOverride(serialNumber: $serialNumber) {\n      configJSON\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateDeviceConfigOverride($serialNumber: String!, $configJSON: String!) {\n    upsertDeviceConfigOverride(serialNumber: $serialNumber, configJSON: $configJSON)\n  }\n',
): (typeof documents)['\n  mutation UpdateDeviceConfigOverride($serialNumber: String!, $configJSON: String!) {\n    upsertDeviceConfigOverride(serialNumber: $serialNumber, configJSON: $configJSON)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DeviceForNetwork($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      label\n      hardwareDevice {\n        serialNumber\n      }\n    }\n  }\n',
): (typeof documents)['\n  query DeviceForNetwork($networkUUID: UUID!) {\n    virtualDevicesForNetwork(networkUUID: $networkUUID) {\n      label\n      hardwareDevice {\n        serialNumber\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CopyIPSecTunnelFromConfig1ToConfig2(\n    $networkUUID: UUID!\n    $input: CopyIPSecTunnelFromConfig1ToConfig2Input!\n  ) {\n    copyIPSecTunnelFromConfig1ToConfig2(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n',
): (typeof documents)['\n  mutation CopyIPSecTunnelFromConfig1ToConfig2(\n    $networkUUID: UUID!\n    $input: CopyIPSecTunnelFromConfig1ToConfig2Input!\n  ) {\n    copyIPSecTunnelFromConfig1ToConfig2(networkUUID: $networkUUID, input: $input) {\n      UUID\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment NetworkFields on Network {\n    UUID\n    slug\n    label\n    companySlug\n    isActive\n    isUpgradeSensitive\n    isTemplate\n    lastUpgradedAt\n    highAvailabilityConfig {\n      UUID\n    }\n    virtualDevices {\n      deviceType\n    }\n\n    mailingAddress {\n      line1\n      line2\n      city\n      subdivisionCode\n      postalCode\n      countryISO2\n    }\n  }\n',
): (typeof documents)['\n  fragment NetworkFields on Network {\n    UUID\n    slug\n    label\n    companySlug\n    isActive\n    isUpgradeSensitive\n    isTemplate\n    lastUpgradedAt\n    highAvailabilityConfig {\n      UUID\n    }\n    virtualDevices {\n      deviceType\n    }\n\n    mailingAddress {\n      line1\n      line2\n      city\n      subdivisionCode\n      postalCode\n      countryISO2\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CompaniesQuery($limit: Int!, $offset: Int!) {\n    listCompanies(limit: $limit, offset: $offset) {\n      uuid\n      isCustomer\n      supportTier\n      slug\n      name\n      networks {\n        ...NetworkFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CompaniesQuery($limit: Int!, $offset: Int!) {\n    listCompanies(limit: $limit, offset: $offset) {\n      uuid\n      isCustomer\n      supportTier\n      slug\n      name\n      networks {\n        ...NetworkFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CompanyQuery($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      isCustomer\n      supportTier\n      slug\n      name\n      networks {\n        ...NetworkFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CompanyQuery($slug: String!) {\n    getCompany(slug: $slug) {\n      uuid\n      isCustomer\n      supportTier\n      slug\n      name\n      networks {\n        ...NetworkFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AllSecurityAppliances($limit: Int, $offset: Int) {\n    hardwareDevices(filter: { deviceType: CONTROLLER, limit: $limit, offset: $offset }) {\n      serialNumber\n      isActive\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        network {\n          UUID\n          label\n          slug\n\n          company {\n            slug\n            name\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AllSecurityAppliances($limit: Int, $offset: Int) {\n    hardwareDevices(filter: { deviceType: CONTROLLER, limit: $limit, offset: $offset }) {\n      serialNumber\n      isActive\n      deviceType\n      deviceModel\n\n      virtualDevice {\n        UUID\n        label\n        network {\n          UUID\n          label\n          slug\n\n          company {\n            slug\n            name\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SSOOptionsQuery($companySlug: String!) {\n    ssoOptions(input: { companySlug: $companySlug }) {\n      connectedDirectory {\n        createdAt\n        state\n        type\n      }\n      connections {\n        connectedAt\n        state\n        type\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SSOOptionsQuery($companySlug: String!) {\n    ssoOptions(input: { companySlug: $companySlug }) {\n      connectedDirectory {\n        createdAt\n        state\n        type\n      }\n      connections {\n        connectedAt\n        state\n        type\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ConfigureSSOMutation($input: ConfigureSSOInput!) {\n    configureSSO(input: $input) {\n      portalURL\n    }\n  }\n',
): (typeof documents)['\n  mutation ConfigureSSOMutation($input: ConfigureSSOInput!) {\n    configureSSO(input: $input) {\n      portalURL\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;

import type { OverlayTriggerState } from '@meterup/atto';
import type { ClientError } from 'graphql-request';
import {
  Alert,
  Badge,
  Button,
  colors,
  ComboBox,
  ComboBoxItem,
  CompositeField,
  darkThemeSelector,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  HStack,
  Icon,
  Label,
  MultiComboBox,
  MultiComboBoxItem,
  MultiComboBoxSection,
  PrimaryField,
  PrimaryFieldComposite,
  PrimaryToggleField,
  SecondaryField,
  SecondaryFieldComposite,
  Select,
  SelectItem,
  space,
  styled,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  Textarea,
  TextInput,
  ToggleInput,
  Tooltip,
  useDialogState,
} from '@meterup/atto';
import { IsOperator, useIsOperator } from '@meterup/authorization';
import { notify } from '@meterup/common';
import { isValidCIDR, splitCIDR } from '@meterup/common/src/topics/ipv4';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import type {
  CreateFirewallRuleInput,
  FirewallRuleQuery,
  UpdateFirewallRuleInput,
} from '../../gql/graphql';
import type { VLAN } from '../NetworkWide/VLANs/utils';
import type { RateLimitRuleFormValues } from '../RateLimiting/EditRuleDrawer';
import { MAX_PORT_NUMBER, paths } from '../../constants';
import { graphql } from '../../gql';
import { FirewallRuleAction, IpProtocol } from '../../gql/graphql';
import { CreateFirewallRuleInputSchema } from '../../gql/zod-types';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { ucfirst } from '../../utils/strings';
import { withZodSchema } from '../../utils/withZodSchema';
import {
  FieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
  ToggleOptionsFieldProvider,
} from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import { vlansQuery } from '../NetworkWide/VLANs/utils';
import {
  type UplinkPhyInterface,
  createFirewallRule,
  deleteFirewallRule,
  displayIPProtocol,
  firewallRulesForNetwork,
  FirewallRulesTab,
  getPhyInterfaceLabel,
  PrefixKind,
  protocolSupportsPorts,
  updateFirewallRule,
  uplinkPhyInterfacesQuery,
  vlanSupportsFirewallRules,
} from './utils';

export const firewallRuleQuery = graphql(`
  query FirewallRule($uuid: UUID!) {
    firewallRule(UUID: $uuid) {
      UUID
      name
      description
      isMeterInternal
      isEnabled
      isBidirectional
      action
      srcPrefix
      srcVLAN {
        __typename
        UUID
        name
        ipV4ClientAssignmentProtocol
        ipV4ClientGateway
        ipV4ClientPrefixLength
      }
      dstPrefix
      dstVLAN {
        __typename
        UUID
        name
        ipV4ClientAssignmentProtocol
        ipV4ClientGateway
        ipV4ClientPrefixLength
      }
      srcPortRange {
        lower
        upper
      }
      dstPortRange {
        lower
        upper
      }
      protocols
      tags

      vlanBindings {
        vlan {
          __typename
          UUID
          name
        }
        metric
      }
      phyInterfaceBindings {
        phyInterface {
          __typename
          UUID
          label
          portNumber
          virtualDevice {
            __typename
            UUID
            label
            deviceModel
          }
        }
        metric
      }
    }
  }
`);

type FirewallRule = FirewallRuleQuery['firewallRule'];

interface EditFirewallRuleDrawerProps {
  rule?: FirewallRule;
}

enum FirewallRuleBindingKind {
  VLAN = 'vlan',
  PhyInterface = 'wan',
}

const firewallRuleEditFormSchema = CreateFirewallRuleInputSchema.omit({
  srcPrefix: true,
  dstPrefix: true,
  protocol: true,
})
  .extend({
    protocols: z
      .array(z.nativeEnum(IpProtocol))
      .nonempty({ message: 'Please select at least one protocol' })
      .refine((protocols) => !protocols.includes(IpProtocol.All) || protocols.length === 1, {
        message: 'Cannot select specific protocols alongside All.',
      }),
    name: z.string().nonempty({ message: 'Please provide a name.' }),
    bindingKind: z.nativeEnum(FirewallRuleBindingKind),
    srcPrefixKind: z.nativeEnum(PrefixKind),
    srcVLANUUID: z.string().nullish(),
    srcIPAddress: z.string().ip({ message: 'Invalid IP address' }).nullish(),
    srcPrefixLength: z
      .number()
      .min(0, { message: 'Prefix length must be greater than or equal to 0.' })
      .max(128, { message: 'Prefix length must be less than or equal to 128.' })
      .nullish(),
    srcPortRange: z.object({
      lower: z.number().min(1).max(MAX_PORT_NUMBER),
      upper: z.number().min(1).max(MAX_PORT_NUMBER),
    }),
    dstPrefixKind: z.nativeEnum(PrefixKind),
    dstVLANUUID: z.string().nullish(),
    dstIPAddress: z.string().ip({ message: 'Invalid IP address' }).nullish(),
    dstPrefixLength: z
      .number()
      .min(0, { message: 'Prefix length must be greater than or equal to 0.' })
      .max(128, { message: 'Prefix length must be less than or equal to 128.' })
      .nullish(),
    dstPortRange: z.object({
      lower: z.number().min(1).max(MAX_PORT_NUMBER),
      upper: z.number().min(1).max(MAX_PORT_NUMBER),
    }),
  })
  .superRefine(({ srcPrefixKind, srcVLANUUID, srcIPAddress, srcPrefixLength }, ctx) => {
    switch (srcPrefixKind) {
      case PrefixKind.VLAN:
        if (!srcVLANUUID) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a VLAN source.',
            path: ['srcVLANUUID'],
          });
        }
        break;
      case PrefixKind.IPAddress:
        if (!srcIPAddress) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide an IP address.',
            path: ['srcIPAddress'],
          });
        }

        if (srcPrefixLength == null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a prefix length.',
            path: ['srcIPAddress'],
          });
        }
        break;
    }
  })
  .superRefine(({ dstPrefixKind, dstVLANUUID, dstIPAddress, dstPrefixLength }, ctx) => {
    switch (dstPrefixKind) {
      case PrefixKind.VLAN:
        if (!dstVLANUUID) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a VLAN destination.',
            path: ['dstVLANUUID'],
          });
        }
        break;
      case PrefixKind.IPAddress:
        if (!dstIPAddress) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide an IP address.',
            path: ['dstIPAddress'],
          });
        }

        if (dstPrefixLength == null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a prefix length.',
            path: ['dstIPAddress'],
          });
        }
        break;
    }
  })
  .superRefine(({ bindingKind, srcPrefixKind, dstPrefixKind }, ctx) => {
    if (
      bindingKind === FirewallRuleBindingKind.PhyInterface &&
      srcPrefixKind === PrefixKind.VLAN &&
      dstPrefixKind === PrefixKind.VLAN
    ) {
      for (const path of ['srcVLANUUID', 'dstVLANUUID']) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Cannot create a WAN firewall rule between two VLANs.',
          path: [path],
        });
      }
    }
  })
  .refine(
    ({ srcIPAddress, srcPrefixLength }) =>
      !srcIPAddress || srcPrefixLength == null || isValidCIDR(srcIPAddress, srcPrefixLength),
    {
      message: 'Invalid prefix length for IP address.',
      path: ['srcPrefixLength'],
    },
  )
  .refine(
    ({ dstIPAddress, dstPrefixLength }) =>
      !dstIPAddress || dstPrefixLength == null || isValidCIDR(dstIPAddress, dstPrefixLength),
    {
      message: 'Invalid prefix length for IP address.',
      path: ['dstPrefixLength'],
    },
  )
  .superRefine(
    (
      {
        action,
        srcPrefixKind,
        srcIPAddress,
        srcPrefixLength,
        srcPortRange,
        dstPrefixKind,
        dstIPAddress,
        dstPrefixLength,
        dstPortRange,
      },
      ctx,
    ) => {
      if (
        action === FirewallRuleAction.Deny &&
        srcPrefixKind === PrefixKind.IPAddress &&
        srcIPAddress === '0.0.0.0' &&
        srcPrefixLength === 0 &&
        srcPortRange.lower === 1 &&
        srcPortRange.upper === MAX_PORT_NUMBER &&
        dstPrefixKind === PrefixKind.IPAddress &&
        dstIPAddress === '0.0.0.0' &&
        dstPrefixLength === 0 &&
        dstPortRange.lower === 1 &&
        dstPortRange.upper === MAX_PORT_NUMBER
      ) {
        for (const path of [
          'srcIPAddress',
          'srcPrefixLength',
          'srcPortRange.lower',
          'srcPortRange.upper',
          'dstIPAddress',
          'dstPrefixLength',
          'dstPortRange.lower',
          'dstPortRange.upper',
        ]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              'Please provide a more specific prefix or port range, cannot deny all traffic.',
            path: [path],
          });
        }
      }
    },
  )
  .refine(
    ({ bindingKind, boundPhyInterfaceUUIDs }) =>
      bindingKind !== FirewallRuleBindingKind.PhyInterface || !!boundPhyInterfaceUUIDs.length,
    {
      message: 'Please select a WAN.',
      path: ['boundPhyInterfaceUUIDs'],
    },
  )
  .refine(
    ({ bindingKind, boundVLANUUIDs }) =>
      bindingKind !== FirewallRuleBindingKind.VLAN || !!boundVLANUUIDs.length,
    {
      message: 'Please select a VLAN.',
      path: ['boundVLANUUIDs'],
    },
  );

type FirewallRuleEditFormValues = z.input<typeof firewallRuleEditFormSchema>;

function DeleteFirewallRuleDialog({
  rule,
  state,
}: {
  rule: FirewallRule;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const [error, setError] = useState<ClientError | null>(null);
  const closeDrawer = useCloseDrawerCallback();

  const deleteRule = useGraphQLMutation(deleteFirewallRule);
  const { mutate } = deleteRule;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    setError(null);

    mutate(
      { uuid: rule.UUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(firewallRulesForNetwork, { networkUUID: network.UUID }),
          );
          setError(null);
          closeDrawer();
          close();
          notify('Rule deleted successfully.', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          setError(err);
        },
      },
    );
  }, [mutate, rule?.UUID, close, network.UUID, queryClient, closeDrawer]);

  const graphqlError = useMemo(() => (error ? getGraphQLError(error) : undefined), [error]);

  if (!rule) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete firewall rule" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to remove the firewall rule <Text weight="bold">{rule.name}</Text> from
              your Meter network.
            </>
          }
        />
        <SummaryList gutter="vertical">
          {!!rule.vlanBindings?.length && (
            <SummaryListRow>
              <SummaryListKey>Affected VLAN{rule.vlanBindings.length > 1 && 's'}</SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  {rule.vlanBindings.map((binding) => (
                    <Badge variant="neutral" size="small" icon="vlan" arrangement="leading-icon">
                      {binding.vlan.name}
                    </Badge>
                  ))}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          )}
          {!!rule.phyInterfaceBindings?.length && (
            <SummaryListRow>
              <SummaryListKey>
                Affected WAN{rule.phyInterfaceBindings.length > 1 && 's'}
              </SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  {rule.phyInterfaceBindings.map((binding) => (
                    <Badge variant="neutral" size="small" icon="globe" arrangement="leading-icon">
                      {getPhyInterfaceLabel(binding.phyInterface)}
                    </Badge>
                  ))}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>

        {error && (
          <Alert
            icon="warning"
            variant="negative"
            heading="There was an error deleting this firewall rule"
            copy={graphqlError?.message ? ucfirst(graphqlError.message) : undefined}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

const PortRangeFields = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

const PortRangeFieldsArrow = styled(Icon, {
  width: '$10',
  height: '$10',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },
});

export function PortRangeField({
  name,
  disabled = false,
}: {
  name: 'srcPortRange' | 'dstPortRange';
  disabled?: boolean;
}) {
  const { values } = useFormikContext<FirewallRuleEditFormValues | RateLimitRuleFormValues>();

  const supportsPorts = useMemo(() => {
    if ('protocols' in values) {
      return values.protocols?.every((protocol) => protocolSupportsPorts(protocol));
    }

    return !!values.protocol && protocolSupportsPorts(values.protocol);
  }, [values]);

  return (
    <SecondaryFieldComposite
      label={
        supportsPorts ? (
          'Ports'
        ) : (
          <Tooltip contents="Ports can only be specified with TCP and UDP protocols.">
            <HStack spacing={space(4)} align="center">
              Ports
              <Icon icon="information" size={12} />
            </HStack>
          </Tooltip>
        )
      }
      fields={
        <PortRangeFields>
          <NumberFieldProvider name={`${name}.lower`} defaultValue={0}>
            <CompositeField
              label="Start"
              element={<TextInput disabled={!supportsPorts && !disabled} width="52px" />}
            />
          </NumberFieldProvider>
          <PortRangeFieldsArrow icon="arrow-right" />
          <NumberFieldProvider name={`${name}.upper`} defaultValue={0}>
            <CompositeField
              label="End"
              element={<TextInput disabled={!supportsPorts && !disabled} width="52px" />}
            />
          </NumberFieldProvider>
        </PortRangeFields>
      }
    />
  );
}

function BindingField({
  rule,
  firewallRulesTab,
  vlans,
  uplinkPhyInterfaces,
}: {
  rule?: FirewallRule | null;
  firewallRulesTab: FirewallRulesTab | null;
  vlans: VLAN[];
  uplinkPhyInterfaces: UplinkPhyInterface[];
}) {
  const { values, setFieldValue } = useFormikContext<FirewallRuleEditFormValues>();

  const shouldShowBindingKindSelector = useMemo(
    () =>
      !rule?.vlanBindings?.length &&
      !rule?.phyInterfaceBindings?.length &&
      (!firewallRulesTab || firewallRulesTab === 'rules'),
    [rule?.vlanBindings?.length, rule?.phyInterfaceBindings?.length, firewallRulesTab],
  );

  useEffect(() => {
    if (
      values.bindingKind === FirewallRuleBindingKind.VLAN &&
      values.srcPrefixKind === PrefixKind.VLAN &&
      values.dstPrefixKind === PrefixKind.VLAN &&
      values.srcVLANUUID &&
      values.dstVLANUUID
    ) {
      setFieldValue('boundVLANUUIDs', [values.srcVLANUUID, values.dstVLANUUID]);
    }
  }, [
    values.bindingKind,
    values.srcPrefixKind,
    values.dstPrefixKind,
    values.srcVLANUUID,
    values.dstVLANUUID,
    setFieldValue,
  ]);

  // Specifically using typescript exhaustiveness checks here
  // eslint-disable-next-line consistent-return
  const label: string = useMemo(() => {
    if (shouldShowBindingKindSelector) return 'Bindings';
    switch (values.bindingKind) {
      case FirewallRuleBindingKind.PhyInterface:
        return 'WANs';
      case FirewallRuleBindingKind.VLAN:
        return 'VLANs';
    }
  }, [values.bindingKind, shouldShowBindingKindSelector]);

  const groupedUplinkPhyInterfaces = useMemo(() => {
    const map = new Map<string, UplinkPhyInterface[]>();
    for (const pi of uplinkPhyInterfaces) {
      let arr = map.get(pi.virtualDevice.label);
      if (!arr) {
        arr = [];
        map.set(pi.virtualDevice.label, arr);
      }

      arr.push(pi);
    }

    return map;
  }, [uplinkPhyInterfaces]);

  return (
    <PrimaryFieldComposite
      label={label}
      fields={
        <>
          <FormikConditional<FirewallRuleEditFormValues>
            condition={({ bindingKind }) => bindingKind === FirewallRuleBindingKind.PhyInterface}
          >
            <MultiComboBoxFieldProvider name="boundPhyInterfaceUUIDs">
              <CompositeField
                label="Bound WANs"
                element={
                  <MultiComboBox placeholder="Select WANs" enableSelectAll>
                    {Array.from(groupedUplinkPhyInterfaces.entries())
                      .sort(([a], [b]) => a.localeCompare(b))
                      .map(([virtualDevice, phyInterfaces]) => (
                        <MultiComboBoxSection title={virtualDevice}>
                          {phyInterfaces.map((pi) => (
                            <MultiComboBoxItem
                              key={pi.UUID}
                              textValue={getPhyInterfaceLabel(pi, false)}
                            >
                              {getPhyInterfaceLabel(pi, false)}
                            </MultiComboBoxItem>
                          ))}
                        </MultiComboBoxSection>
                      ))}
                  </MultiComboBox>
                }
              />
            </MultiComboBoxFieldProvider>
          </FormikConditional>
          <FormikConditional<FirewallRuleEditFormValues>
            condition={({ bindingKind }) => bindingKind === FirewallRuleBindingKind.VLAN}
          >
            <MultiComboBoxFieldProvider name="boundVLANUUIDs">
              <CompositeField
                label="Bound VLANs"
                element={
                  <MultiComboBox placeholder="Select VLANs" enableSelectAll>
                    {vlans.map((vlan) => (
                      <MultiComboBoxItem key={vlan.UUID} textValue={vlan.name}>
                        {vlan.name}
                        {vlan.isInternal && (
                          <>
                            {' '}
                            <Badge size="small">Internal</Badge>
                          </>
                        )}
                      </MultiComboBoxItem>
                    ))}
                  </MultiComboBox>
                }
              />
            </MultiComboBoxFieldProvider>
          </FormikConditional>
        </>
      }
      controls={
        <FormikConditional<FirewallRuleEditFormValues>
          condition={() => shouldShowBindingKindSelector}
        >
          <FieldProvider name="bindingKind">
            <CompositeField
              label="Binding"
              element={
                <Select>
                  <SelectItem key={FirewallRuleBindingKind.PhyInterface}>WAN</SelectItem>
                  <SelectItem key={FirewallRuleBindingKind.VLAN}>VLAN</SelectItem>
                </Select>
              }
            />
          </FieldProvider>
        </FormikConditional>
      }
    />
  );
}

function BidirectionalField({ rule }: EditFirewallRuleDrawerProps) {
  const { values, touched, setFieldValue } = useFormikContext<FirewallRuleEditFormValues>();

  const hasRule = !!rule;

  useEffect(() => {
    if (!hasRule && !touched.isBidirectional && values.action === FirewallRuleAction.Permit) {
      setFieldValue(
        'isBidirectional',
        values.srcPrefixKind === PrefixKind.VLAN &&
          values.dstPrefixKind === PrefixKind.VLAN &&
          !touched.isBidirectional,
      );
    }
  }, [
    hasRule,
    values.srcPrefixKind,
    values.dstPrefixKind,
    touched.isBidirectional,
    values.action,
    setFieldValue,
  ]);

  return (
    <FieldContainer>
      <FieldProvider name="isBidirectional">
        <PrimaryToggleField
          label="Bidirectional"
          description="If enabled, the rule will apply in both directions as if a duplicate rule were created with source and destination swapped."
        />
      </FieldProvider>
      <FormikConditional<FirewallRuleEditFormValues>
        condition={({ srcPrefixKind, dstPrefixKind, isBidirectional, action }) =>
          srcPrefixKind === PrefixKind.VLAN &&
          dstPrefixKind === PrefixKind.VLAN &&
          !isBidirectional &&
          action === FirewallRuleAction.Permit
        }
      >
        <Alert
          icon="information"
          variant="attention"
          heading="Traffic between VLANs is blocked by default"
          copy="You likely want to bidirectionally allow communication between the two VLANs."
          relation="stacked"
        />
      </FormikConditional>
    </FieldContainer>
  );
}

export function ProtocolsField() {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<FirewallRuleEditFormValues>();

  const handleAllChange = useCallback(
    (isSelected: boolean) => {
      setFieldValue('protocols', isSelected ? [IpProtocol.All] : []);
      if (!isSelected) {
        setFieldTouched('protocols', false);
      }
    },
    [setFieldValue, setFieldTouched],
  );

  const isAllSelected = useMemo(
    () => values.protocols.length === 1 && values.protocols[0] === IpProtocol.All,
    [values.protocols],
  );

  return (
    <FieldContainer>
      <MultiComboBoxFieldProvider name="protocols">
        <PrimaryField
          label="Protocols"
          element={
            isAllSelected ? null : (
              <MultiComboBox width="100%" placeholder="Select protocols">
                {Object.values(IpProtocol)
                  .filter((protocol) => protocol !== IpProtocol.All)
                  .map((protocol) => (
                    <MultiComboBoxItem key={protocol}>
                      {displayIPProtocol(protocol)}
                    </MultiComboBoxItem>
                  ))}
              </MultiComboBox>
            )
          }
          controls={
            <Label>
              All
              <ToggleInput selected={isAllSelected} onChange={handleAllChange} />
            </Label>
          }
        />
      </MultiComboBoxFieldProvider>
    </FieldContainer>
  );
}

const schemaValidator = withZodSchema(firewallRuleEditFormSchema);

export default function EditFirewallRuleDrawer({ rule }: EditFirewallRuleDrawerProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isOperator = useIsOperator();

  const { state } = useDialogState();

  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;
  const uplinkPhyInterfaces =
    useGraphQL(uplinkPhyInterfacesQuery, { networkUUID: network.UUID }).data
      ?.uplinkPhyInterfacesForNetwork ?? [];

  const vlanOptions = useMemo(
    () =>
      vlans
        ?.filter((vlan) => vlanSupportsFirewallRules(vlan) && (isOperator || !vlan.isInternal))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [vlans, isOperator],
  );

  const rootParams = Nav.useRegionParams('root', paths.pages.RulesPage);
  const firewallRulesTab: FirewallRulesTab | null =
    rootParams?.tab && Object.values(FirewallRulesTab).includes(rootParams.tab as FirewallRulesTab)
      ? (rootParams.tab as FirewallRulesTab)
      : null;

  const createMutation = useGraphQLMutation(createFirewallRule);
  const updateMutation = useGraphQLMutation(updateFirewallRule);

  const handleSubmit = useCallback(
    ({
      bindingKind,
      srcPrefixKind,
      srcVLANUUID,
      srcIPAddress,
      srcPrefixLength,
      dstPrefixKind,
      dstVLANUUID,
      dstIPAddress,
      dstPrefixLength,
      boundVLANUUIDs,
      boundPhyInterfaceUUIDs,
      ...values
    }: FirewallRuleEditFormValues) => {
      if (rule) {
        const input: UpdateFirewallRuleInput = values;

        switch (bindingKind) {
          case FirewallRuleBindingKind.VLAN:
            input.boundVLANUUIDs = boundVLANUUIDs;
            break;
          case FirewallRuleBindingKind.PhyInterface:
            input.boundPhyInterfaceUUIDs = boundPhyInterfaceUUIDs;
            break;
        }

        switch (srcPrefixKind) {
          case PrefixKind.VLAN:
            input.srcVLANUUID = srcVLANUUID;
            input.srcPrefix = null;
            input.srcIPSecTunnelUUID = null;
            break;
          case PrefixKind.IPAddress:
            input.srcPrefix = `${srcIPAddress}/${srcPrefixLength}`;
            input.srcVLANUUID = null;
            input.srcIPSecTunnelUUID = null;
            break;
        }

        switch (dstPrefixKind) {
          case PrefixKind.VLAN:
            input.dstVLANUUID = dstVLANUUID;
            input.dstPrefix = null;
            input.dstIPSecTunnelUUID = null;
            break;
          case PrefixKind.IPAddress:
            input.dstPrefix = `${dstIPAddress}/${dstPrefixLength}`;
            input.dstVLANUUID = null;
            input.dstIPSecTunnelUUID = null;
            break;
        }

        updateMutation.mutate(
          { uuid: rule.UUID, input },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(firewallRulesForNetwork, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(makeQueryKey(firewallRuleQuery, { uuid: rule.UUID }));
              notify('Successfully updated firewall rule.', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              notify(
                `There was a problem updating the firewall rule${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateFirewallRuleInput = {
          ...values,
          boundVLANUUIDs: [],
          boundPhyInterfaceUUIDs: [],
        };

        switch (bindingKind) {
          case FirewallRuleBindingKind.VLAN:
            input.boundVLANUUIDs = boundVLANUUIDs;
            break;
          case FirewallRuleBindingKind.PhyInterface:
            input.boundPhyInterfaceUUIDs = boundPhyInterfaceUUIDs;
            break;
        }

        switch (srcPrefixKind) {
          case PrefixKind.VLAN:
            input.srcVLANUUID = srcVLANUUID;
            break;
          case PrefixKind.IPAddress:
            input.srcPrefix = `${srcIPAddress}/${srcPrefixLength}`;
            break;
        }

        switch (dstPrefixKind) {
          case PrefixKind.VLAN:
            input.dstVLANUUID = dstVLANUUID;
            break;
          case PrefixKind.IPAddress:
            input.dstPrefix = `${dstIPAddress}/${dstPrefixLength}`;
            break;
        }

        createMutation.mutate(
          { networkUUID: network.UUID, input },
          {
            onSuccess: (result) => {
              queryClient.invalidateQueries(
                makeQueryKey(firewallRulesForNetwork, { networkUUID: network.UUID }),
              );
              notify('Successfully created firewall rule.', {
                variant: 'positive',
              });
              navigate(
                makeDrawerLink(window.location, paths.drawers.EditFirewallRulePage, {
                  ruleUUID: result.createFirewallRule.UUID,
                  companyName,
                  networkSlug: network.slug,
                }),
              );
            },
            onError: (err) => {
              notify(
                `There was a problem creating the firewall rule${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      rule,
      createMutation,
      updateMutation,
      network.UUID,
      network.slug,
      companyName,
      queryClient,
      navigate,
    ],
  );

  const initialBindingKind: FirewallRuleBindingKind = useMemo(() => {
    switch (firewallRulesTab) {
      case FirewallRulesTab.VLANs:
        return FirewallRuleBindingKind.VLAN;
      case FirewallRulesTab.WANs:
        return FirewallRuleBindingKind.PhyInterface;
    }

    return rule?.phyInterfaceBindings?.length
      ? FirewallRuleBindingKind.PhyInterface
      : FirewallRuleBindingKind.VLAN;
  }, [firewallRulesTab, rule?.phyInterfaceBindings?.length]);

  const vlanUUIDMap: Map<string, VLAN> = useMemo(
    () => new Map((vlans ?? []).map((vlan) => [vlan.UUID, vlan])),
    [vlans],
  );

  const handleValidate = useCallback(
    (values: FirewallRuleEditFormValues) => {
      const validation = schemaValidator(values);

      if (
        values.bindingKind === FirewallRuleBindingKind.VLAN &&
        values.srcPrefixKind === PrefixKind.VLAN &&
        values.dstPrefixKind === PrefixKind.VLAN &&
        values.srcVLANUUID &&
        values.dstVLANUUID &&
        (values.boundVLANUUIDs.length !== 2 ||
          !values.boundVLANUUIDs.includes(values.srcVLANUUID) ||
          !values.boundVLANUUIDs.includes(values.dstVLANUUID))
      ) {
        validation.boundVLANUUIDs =
          'Rules between two VLANs must be bound to only those two VLANs.';
      }

      if (values.srcPrefixKind === PrefixKind.VLAN && values.srcVLANUUID) {
        const srcVLAN = vlanUUIDMap.get(values.srcVLANUUID);
        if (!srcVLAN) {
          validation.srcVLANUUID = 'Unrecognized VLAN.';
        } else if (!vlanSupportsFirewallRules(srcVLAN)) {
          validation.srcVLANUUID = 'Invalid VLAN.';
        }
      }

      if (values.dstPrefixKind === PrefixKind.VLAN && values.dstVLANUUID) {
        const dstVLAN = vlanUUIDMap.get(values.dstVLANUUID);
        if (!dstVLAN) {
          validation.dstVLANUUID = 'Unrecognized VLAN.';
        } else if (!vlanSupportsFirewallRules(dstVLAN)) {
          validation.dstVLANUUID = 'Invalid VLAN.';
        }
      }

      return validation;
    },
    [vlanUUIDMap],
  );

  const srcPrefixPieces = rule?.srcPrefix ? splitCIDR(rule.srcPrefix) : null;
  const dstPrefixPieces = rule?.dstPrefix ? splitCIDR(rule.dstPrefix) : null;

  return (
    <Drawer>
      <Formik<FirewallRuleEditFormValues>
        initialValues={{
          name: rule?.name ?? '',
          description: rule?.description ?? '',
          isMeterInternal: rule?.isMeterInternal ?? true,
          isEnabled: rule?.isEnabled ?? true,
          isBidirectional: rule?.isBidirectional ?? false,
          action: rule?.action ?? FirewallRuleAction.Deny,
          srcPrefixKind:
            firewallRulesTab === FirewallRulesTab.VLANs && rule?.srcVLAN
              ? PrefixKind.VLAN
              : PrefixKind.IPAddress,
          srcVLANUUID: rule?.srcVLAN?.UUID ?? '',
          srcIPAddress: srcPrefixPieces?.[0] ?? '0.0.0.0',
          srcPrefixLength: srcPrefixPieces?.[1] ?? 0,

          dstPrefixKind:
            firewallRulesTab === FirewallRulesTab.VLANs && rule?.dstVLAN
              ? PrefixKind.VLAN
              : PrefixKind.IPAddress,
          dstVLANUUID: rule?.dstVLAN?.UUID ?? '',
          dstIPAddress: dstPrefixPieces?.[0] ?? '0.0.0.0',
          dstPrefixLength: dstPrefixPieces?.[1] ?? 0,

          srcPortRange: {
            lower: rule?.srcPortRange?.lower ?? 1,
            upper: rule?.srcPortRange?.upper ?? MAX_PORT_NUMBER,
          },
          dstPortRange: {
            lower: rule?.dstPortRange?.lower ?? 1,
            upper: rule?.dstPortRange?.upper ?? MAX_PORT_NUMBER,
          },
          protocols: (rule?.protocols as [IpProtocol, ...IpProtocol[]]) ?? [
            IpProtocol.Tcp,
            IpProtocol.Udp,
          ],

          bindingKind: initialBindingKind,
          boundVLANUUIDs: rule?.vlanBindings?.map((binding) => binding.vlan.UUID) ?? [],
          boundPhyInterfaceUUIDs:
            rule?.phyInterfaceBindings?.map((binding) => binding.phyInterface.UUID) ?? [],
        }}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            icon="traffic-shaping"
            heading={`${rule ? 'Edit' : 'Add'} firewall rule`}
            actions={
              rule ? (
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem icon="trash-can" onSelect={state.open}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
              ) : undefined
            }
            onClose={closeDrawer}
          />
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enabled" />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="name">
                <PrimaryField label="Name" element={<TextInput />} />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="description">
                <PrimaryField optional label="Description" element={<Textarea />} />
              </FieldProvider>
            </FieldContainer>

            <BindingField
              rule={rule}
              firewallRulesTab={firewallRulesTab}
              vlans={vlanOptions}
              uplinkPhyInterfaces={uplinkPhyInterfaces}
            />

            <FieldContainer>
              <ToggleOptionsFieldProvider
                name="action"
                negative={FirewallRuleAction.Deny}
                positive={FirewallRuleAction.Permit}
              >
                <PrimaryToggleField
                  label="Action"
                  variant="polarity"
                  negative={{
                    icon: 'block',
                    label: 'Deny',
                  }}
                  positive={{
                    icon: 'checkmark',
                    label: 'Allow',
                  }}
                />
              </ToggleOptionsFieldProvider>

              <FormikConditional<FirewallRuleEditFormValues>
                condition={({ srcPrefixKind, dstPrefixKind, action }) =>
                  srcPrefixKind === PrefixKind.VLAN &&
                  dstPrefixKind === PrefixKind.VLAN &&
                  action === FirewallRuleAction.Deny
                }
              >
                <Alert
                  icon="information"
                  variant="attention"
                  heading="Traffic between VLANs is blocked by default"
                  copy="You likely don't need to manually block traffic between two VLANs."
                  relation="stacked"
                />
              </FormikConditional>
            </FieldContainer>

            <ProtocolsField />

            <BidirectionalField rule={rule} />

            <FieldContainer>
              <PrimaryField
                label="Source"
                element={null}
                controls={
                  firewallRulesTab === FirewallRulesTab.VLANs && (
                    <FieldProvider name="srcPrefixKind">
                      <CompositeField
                        label="Source kind"
                        element={
                          <Select>
                            <SelectItem key={PrefixKind.IPAddress}>IP address</SelectItem>
                            <SelectItem key={PrefixKind.VLAN}>VLAN</SelectItem>
                          </Select>
                        }
                      />
                    </FieldProvider>
                  )
                }
              />

              <FormikConditional<FirewallRuleEditFormValues>
                condition={({ srcPrefixKind }) => srcPrefixKind === PrefixKind.VLAN}
              >
                <FieldProvider name="srcVLANUUID">
                  <SecondaryField
                    label="VLAN"
                    element={
                      <ComboBox placeholder="Select a VLAN">
                        {vlanOptions.map((vlan) => (
                          <ComboBoxItem key={vlan.UUID}>{vlan.name}</ComboBoxItem>
                        ))}
                      </ComboBox>
                    }
                  />
                </FieldProvider>
              </FormikConditional>
              <FormikConditional<FirewallRuleEditFormValues>
                condition={({ srcPrefixKind }) => srcPrefixKind === PrefixKind.IPAddress}
              >
                <FieldProvider name="srcIPAddress">
                  <SecondaryField label="IP address" element={<TextInput width="120px" />} />
                </FieldProvider>
                <NumberFieldProvider name="srcPrefixLength" defaultValue={0}>
                  <SecondaryField label="Prefix length" element={<TextInput width="40px" />} />
                </NumberFieldProvider>
                <PortRangeField name="srcPortRange" />
              </FormikConditional>
            </FieldContainer>

            <FieldContainer>
              <PrimaryField
                label="Destination"
                element={null}
                controls={
                  firewallRulesTab === FirewallRulesTab.VLANs && (
                    <FieldProvider name="dstPrefixKind">
                      <CompositeField
                        label="Destination kind"
                        element={
                          <Select>
                            <SelectItem key={PrefixKind.IPAddress}>IP address</SelectItem>
                            <SelectItem key={PrefixKind.VLAN}>VLAN</SelectItem>
                          </Select>
                        }
                      />
                    </FieldProvider>
                  )
                }
              />

              <FormikConditional<FirewallRuleEditFormValues>
                condition={({ dstPrefixKind }) => dstPrefixKind === PrefixKind.VLAN}
              >
                <FieldProvider name="dstVLANUUID">
                  <SecondaryField
                    label="VLAN"
                    element={
                      <ComboBox placeholder="Select a VLAN">
                        {vlanOptions.map((vlan) => (
                          <ComboBoxItem key={vlan.UUID}>{vlan.name}</ComboBoxItem>
                        ))}
                      </ComboBox>
                    }
                  />
                </FieldProvider>
              </FormikConditional>
              <FormikConditional<FirewallRuleEditFormValues>
                condition={({ dstPrefixKind }) => dstPrefixKind === PrefixKind.IPAddress}
              >
                <FieldProvider name="dstIPAddress">
                  <SecondaryField label="IP address" element={<TextInput width="120px" />} />
                </FieldProvider>
                <NumberFieldProvider name="dstPrefixLength" defaultValue={0}>
                  <SecondaryField label="Prefix length" element={<TextInput width="40px" />} />
                </NumberFieldProvider>
                <PortRangeField name="dstPortRange" />
              </FormikConditional>
            </FieldContainer>

            <IsOperator>
              <FieldContainer>
                <FieldProvider name="isMeterInternal">
                  <PrimaryToggleField label="Read-only" internal />
                </FieldProvider>
              </FieldContainer>
            </IsOperator>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
      {rule && <DeleteFirewallRuleDialog state={state} rule={rule} />}
    </Drawer>
  );
}

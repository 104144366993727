import FloorPlanEditDrawer from '../../../../../components/FloorPlans/FloorPlanEditDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/design/floor-plans/create',
  title: 'Add floor plan - Floor plans - Design',
});

export default function FloorPlanCreatePage() {
  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermCompanyUpdate))
      }
      should404OnAccessDenied
    >
      <FloorPlanEditDrawer />
    </IsPermitted>
  );
}

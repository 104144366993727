import React from 'react';

import { Icon, IconName } from '@meterup/atto';
import { Header } from '@tanstack/react-table';

type SortIconProps<TData, TValue> = {
  header: Header<TData, TValue>;
};
export default function SortIcon<TData, TValue = unknown>({
  header,
}: SortIconProps<TData, TValue>) {
  const isSorted = header.column.getIsSorted();
  if (!isSorted) {
    return null;
  }
  let iconName: IconName = 'chevron-down';
  if (isSorted === 'asc') {
    iconName = 'chevron-up';
  }
  return (
    <span>
      <Icon icon={iconName} size={8} />
    </span>
  );
}

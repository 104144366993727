import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  PaneHeader,
} from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';

import type { NetworkClientOptions } from '../../../../../hooks/networkClients/useNetworkClients';
import type { TimePeriod } from '.';
import { KeyBoundSearchInput } from '../../../../../components/KeyBoundSearchInput';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { paths } from '../../../../../constants';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { useInsightsCrumbs, useNavigateBack, useNavigateHome } from '../../../../../utils/routing';
import { Filters, timePeriodLabel } from './clients.constants';
import { useFilteredNetworkClients } from './clients.hooks';

export function ClientsListActions({
  excludeMeterHardware,
  setExcludeMeterHardware,
  currentTimePeriod,
  setCurrentTimePeriod,
  exportToCSV,
}: {
  excludeMeterHardware: boolean | undefined;
  setExcludeMeterHardware: (val: boolean) => void;
  currentTimePeriod: TimePeriod;
  setCurrentTimePeriod: (tp: TimePeriod) => void;
  exportToCSV?: () => void;
}) {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton variant="secondary" arrangement="leading-icon" icon="clock">
          {timePeriodLabel(currentTimePeriod as TimePeriod)}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentTimePeriod}
            onValueChange={(val) => setCurrentTimePeriod(val as TimePeriod)}
          >
            <DropdownMenuRadioItem value="5">{timePeriodLabel('5')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="10">{timePeriodLabel('10')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="30">{timePeriodLabel('30')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="60">{timePeriodLabel('60')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="120">{timePeriodLabel('120')}</DropdownMenuRadioItem>
            <IsOperator>
              <DropdownMenuRadioItem value="43200" internal>
                {timePeriodLabel('43200')}
              </DropdownMenuRadioItem>
            </IsOperator>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuCheckboxItem
              checked={!excludeMeterHardware}
              onCheckedChange={(checked) => {
                setExcludeMeterHardware(!checked);
              }}
              icon="virtual-device"
            >
              Show Meter hardware
            </DropdownMenuCheckboxItem>
            <IsOperator>
              {exportToCSV && (
                <DropdownMenuItem onClick={exportToCSV} icon="download" internal>
                  Export current view to CSV
                </DropdownMenuItem>
              )}
            </IsOperator>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <KeyBoundSearchInput
        placeholder="..."
        aria-label="Filter clients"
        scope="scoped"
        searchParamStateKey="filter"
        minWidth="56px"
        disabled={false}
      />
    </>
  );
}

export default function ClientsListHeader2({
  networkClientOptions = {},
  exportToCSV,
}: {
  networkClientOptions?: NetworkClientOptions;
  exportToCSV?: () => void;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const insightsCrumbs = useInsightsCrumbs();

  const { clients, ...rest } = useFilteredNetworkClients({
    networkClientOptions,
    network,
    useGraphQLOptions: { suspense: false },
  });

  const filterList = Filters();

  return (
    <PaneHeader
      back={back}
      home={home}
      crumbs={[
        ...insightsCrumbs,
        {
          type: 'page',
          page: {
            as: ReactRouterLink,
            to: makeLink(paths.pages.ClientsList2Page, {
              companyName,
              networkSlug: network.slug,
            }),
            selected: true,
            label: 'Clients',
          },
        },
      ]}
      icon="client"
      heading="Clients"
      count={clients.length}
      tabs={<filterList.TabSwitcher />}
      contentActions={<ClientsListActions exportToCSV={exportToCSV} {...rest} />}
    />
  );
}

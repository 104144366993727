import type { ClientError } from 'graphql-request';
import {
  Alert,
  Button,
  Column,
  Columns,
  ColumnsContainer,
  ComboBox,
  ComboBoxItem,
  PaneFooter,
  styled,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Editor } from '@monaco-editor/react';
import { useContext } from 'react';

import { graphql } from '../../../../../gql';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { ThemeContext } from '../../../../../providers/ThemeProvider';
import { useDeviceConfigContext } from './DeviceConfigContext';

const deviceForNetworkQuery = graphql(`
  query DeviceForNetwork($networkUUID: UUID!) {
    virtualDevicesForNetwork(networkUUID: $networkUUID) {
      label
      hardwareDevice {
        serialNumber
      }
    }
  }
`);

export const DeviceConfigPaneFooter = styled(PaneFooter, {
  gridArea: 'footer',
  justifyContent: 'end',
  gap: '$8',
});

export function DeviceConfigFooter() {
  const {
    currentOverrideJSONIsDirty,
    handleUpsertOverride,
    handleResetOverride,
    upsertOverrideIsLoading,
  } = useDeviceConfigContext();

  return (
    <>
      <Button
        onClick={handleResetOverride}
        variant="secondary"
        disabled={upsertOverrideIsLoading || !currentOverrideJSONIsDirty}
      >
        Reset override
      </Button>
      <Button
        onClick={handleUpsertOverride}
        variant="primary"
        loading={upsertOverrideIsLoading}
        disabled={upsertOverrideIsLoading || !currentOverrideJSONIsDirty}
      >
        Save override
      </Button>
    </>
  );
}

const EditorColumn = styled(Column, {
  '@mobile': {
    height: '50%',
  },

  '@notMobile': {
    height: '100%',
  },
});

const EditorColumns = styled(Columns, {
  gridArea: 'editor',
});

const EditorArea = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr fit-content(100%)',
  gridTemplateAreas: `
    "editor"
    "footer"
  `,
  width: '100%',
  height: '100%',

  [`& ${ColumnsContainer}`]: {
    height: '100%',
  },
});

interface DeviceConfigProps {
  insideDialog?: boolean;
}

function ConfigErrorAlert({ heading, error }: { heading: string; error: ClientError }) {
  if (error instanceof ResourceNotFoundError) {
    // If not found, just display empty object with no visible error
    return null;
  }

  return (
    <Alert
      relation="stacked"
      heading={heading}
      copy={`${error.message ?? 'Unknown error'}.`}
      variant="negative"
    />
  );
}

type SerialNumberComboBoxProp = {
  serialNumber?: string;
  serialNumberItems: { serialNumber: string; label: string }[] | undefined;
  setSerialNumber: (sn: string) => void;
};

export function SerialNumberComboBox({
  serialNumber,
  serialNumberItems,
  setSerialNumber,
}: SerialNumberComboBoxProp) {
  return (
    <ComboBox
      defaultItems={serialNumberItems}
      icon="location"
      onValueChange={(device_serial_number) => {
        setSerialNumber(device_serial_number.toString());
      }}
      value={serialNumber}
      aria-label="Serial number"
      canClearValue={false}
    >
      {(sn) => (
        <ComboBoxItem key={sn.serialNumber} textValue={sn.serialNumber}>
          {sn.serialNumber} ({sn.label})
        </ComboBoxItem>
      )}
    </ComboBox>
  );
}

export default function DeviceConfigEditor({ insideDialog = false }: DeviceConfigProps) {
  const network = useNetwork();

  const {
    currentConfigError,
    currentConfigJSON,
    currentOverrideError,
    currentOverrideJSON,
    setCurrentOverrideJSON,
    handleResetOverride,
  } = useDeviceConfigContext();

  const devicesJSON = useGraphQL(deviceForNetworkQuery, {
    networkUUID: network.UUID,
  }).data;
  expectDefinedOrThrow(devicesJSON, new ResourceNotFoundError('Config not found'));

  const serialNumberItems: { serialNumber: string; label: string }[] | undefined =
    devicesJSON.virtualDevicesForNetwork
      .filter((device) => device.hardwareDevice !== null)
      .map((device) => ({
        serialNumber: device.hardwareDevice!.serialNumber,
        label: device.label,
      }));

  serialNumberItems.sort((a, b) => a.label.localeCompare(b.label));

  const { dark } = useContext(ThemeContext);

  return (
    <EditorArea>
      <EditorColumns template="flex">
        <EditorColumn gutter="none" width="full" spacing={0}>
          <Editor
            language="json"
            theme={dark ? 'vs-dark' : 'light'}
            value={currentConfigJSON}
            options={{ minimap: { enabled: false }, readOnly: true }}
          />
          {currentConfigError && (
            <ConfigErrorAlert heading="Error fetching device config" error={currentConfigError} />
          )}
        </EditorColumn>
        <EditorColumn gutter="none" width="full" spacing={0}>
          <Editor
            language="json"
            theme={dark ? 'vs-dark' : 'light'}
            value={currentOverrideJSON}
            options={{ minimap: { enabled: false }, readOnly: false }}
            onChange={(value) => {
              if (value) {
                setCurrentOverrideJSON(value);
              } else {
                handleResetOverride();
              }
            }}
          />
          {currentOverrideError && (
            <ConfigErrorAlert
              heading="Error fetching device overrides"
              error={currentOverrideError}
            />
          )}
        </EditorColumn>
      </EditorColumns>
      {!insideDialog && (
        <DeviceConfigPaneFooter>
          <DeviceConfigFooter />
        </DeviceConfigPaneFooter>
      )}
    </EditorArea>
  );
}

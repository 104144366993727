import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';

import SecurityApplianceSummary from '../../../../../components/Hardware/SecurityAppliance/SecurityApplianceSummary';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/security-appliances/:uuid/summary',
  title: 'Security appliance - Security appliances - Hardware',
});

export default function SecurityApplianceSummaryPage() {
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.SecurityApplianceSummaryPage),
  );

  return <SecurityApplianceSummary virtualDeviceUUID={uuid} />;
}

import React from 'react';

export default function Cox() {
  return (
    <svg
      width="113"
      height="20"
      viewBox="0 0 113 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.5016 9.99904L77.277 5.73103C77.8181 5.1196 77.7602 4.18522 77.1488 3.64477C76.5374 3.10399 75.6024 3.16151 75.0616 3.77287L71.5282 7.76782L67.9949 3.77287C67.4538 3.16143 66.5197 3.10396 65.9077 3.64477C65.2963 4.18555 65.2386 5.11959 65.7795 5.73103L69.5545 9.99904L65.7791 14.2671C65.238 14.8785 65.2959 15.8129 65.9073 16.3533C66.5187 16.8941 67.4536 16.8366 67.9945 16.2252L71.5278 12.2303L75.0612 16.2252C75.6023 16.8366 76.5364 16.8941 77.1484 16.3533C77.7598 15.8125 77.8174 14.8785 77.2766 14.2671L73.5016 9.99904Z"
        fill="#00D258"
      />
      <path
        d="M55.964 17C52.1032 17 48.9619 13.8601 48.9619 9.99998C48.9619 6.13989 52.1032 3 55.964 3C59.8247 3 62.9661 6.13989 62.9661 9.99998C62.9661 13.8601 59.8248 17 55.964 17ZM55.964 5.95648C53.7338 5.95648 51.9193 7.7704 51.9193 9.99998C51.9193 12.2294 53.7338 14.0435 55.964 14.0435C58.1941 14.0435 60.0087 12.2295 60.0087 9.99998C60.0087 7.7704 58.1943 5.95648 55.964 5.95648V5.95648Z"
        fill="url(#paint0_linear_8_28066)"
      />
      <path
        d="M42.3546 17C38.4934 17 35.3525 13.8595 35.3525 9.99998C35.3525 6.14047 38.4934 3 42.3546 3C43.6091 3 44.8404 3.33612 45.9155 3.97165C46.6184 4.3873 46.851 5.29384 46.4355 5.99653C46.02 6.69927 45.1129 6.93183 44.41 6.51616C43.7907 6.15001 43.0798 5.95632 42.3549 5.95632C40.1247 5.95632 38.3102 7.77025 38.3102 9.99982C38.3102 12.2294 40.1247 14.0433 42.3549 14.0433C43.0799 14.0433 43.7908 13.8496 44.41 13.4835C45.1129 13.0678 46.0197 13.3004 46.4355 14.0031C46.851 14.7058 46.6189 15.6123 45.9155 16.028C44.8403 16.6635 43.6089 16.9996 42.3546 16.9996V17Z"
        fill="#00AAF4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_28066"
          x1="51.015"
          y1="5.05095"
          x2="60.9095"
          y2="14.9481"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#00AAF4" />
          <stop offset="0.88" stopColor="#00D258" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  PrimaryField,
  PrimaryToggleField,
  Select,
  SelectItem,
  TextInput,
  useDialogState,
} from '@meterup/atto';
import { checkDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import type { OneToOneNATRule } from './utils';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { CreateOneToOneNatRuleInputSchema } from '../../../gql/zod-types';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider } from '../../Form/FieldProvider';
import { TextareaField, TextField } from '../../Form/Fields';
import { getUplinkDevices } from '../../Insights/Network/utils';
import { getPhyInterfaceLabel, uplinkPhyInterfacesQuery } from '../utils';
import {
  createOneToOneNATRuleMutation,
  deleteOneToOneNATRuleMutation,
  oneToOneNATRuleQuery,
  oneToOneNATRulesForNetworkQuery,
  updateOneToOneNATRuleMutation,
} from './utils';

function DeleteOneToOneNATRuleDialog({
  rule,
  state,
}: {
  rule: OneToOneNATRule;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const deleteRule = useGraphQLMutation(deleteOneToOneNATRuleMutation);
  const { mutate } = deleteRule;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    mutate(
      { uuid: rule.UUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(oneToOneNATRulesForNetworkQuery, { networkUUID: network.UUID }),
          );
          closeDrawer();
          close();
          notify('Rule deleted successfully', {
            variant: 'positive',
          });
        },
      },
    );
  }, [mutate, rule?.UUID, close, network.UUID, queryClient, closeDrawer]);

  const graphqlError = useMemo(
    () => (deleteRule.error ? getGraphQLError(deleteRule.error) : undefined),
    [deleteRule.error],
  );

  if (!rule) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete 1:1 NAT rule" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy="You're about to remove a 1:1 NAT rule from your Meter network."
        />
        {deleteRule.error && (
          <Alert
            icon="warning"
            variant="negative"
            copy={
              <>
                There was an error deleting the 1:1 NAT rule
                {graphqlError?.message && <>: {graphqlError.message}</>}.
              </>
            }
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

const oneToOneNATRuleInputSchema = CreateOneToOneNatRuleInputSchema.extend({
  name: z
    .string({ required_error: 'Please enter a name.' })
    .nonempty({ message: 'Please enter a name.' }),
  internalIPAddr: z.string().ip({ message: 'Please enter a valid IP address.' }),
  externalIPAddr: z.string().ip({ message: 'Please enter a valid IP address.' }),
});

type OneToOneNATRuleInput = z.input<typeof oneToOneNATRuleInputSchema>;

const dhcpRulesWithStaticMappingsForNetworkQuery = graphql(`
  query DHCPRulesWithStaticMappingsForNetworkQuery($networkUUID: UUID!) {
    dhcpRulesForNetwork(networkUUID: $networkUUID) {
      UUID
      vlan {
        name
      }
      staticMappings {
        UUID
        name
        macAddress
        ipAddress
        hostname
        createdAt
      }
    }
  }
`);

enum SelectionKind {
  Options = 'options',
  Custom = 'custom',
}

function ExternalIPAddressField() {
  const network = useNetwork();
  const { values } = useFormikContext<OneToOneNATRuleInput>();

  const uplinks = useGraphQL(uplinkPhyInterfacesQuery, { networkUUID: network.UUID }).data
    ?.uplinkPhyInterfacesForNetwork;

  const selectedUplinkAddresses = useMemo(
    () => uplinks?.find((pi) => pi.UUID === values.externalPhyInterfaceUUID)?.ipv4ClientAddresses,
    [values.externalPhyInterfaceUUID, uplinks],
  );

  const [selectionKind, setSelectionKind] = useState(() =>
    selectedUplinkAddresses?.includes(values.externalIPAddr)
      ? SelectionKind.Options
      : SelectionKind.Custom,
  );

  useEffect(() => {
    if (!selectedUplinkAddresses?.length) {
      setSelectionKind(SelectionKind.Custom);
    }
  }, [selectedUplinkAddresses]);

  return (
    <FieldContainer>
      <FieldProvider name="externalIPAddr">
        <PrimaryField
          label="External IP address"
          element={
            selectedUplinkAddresses && selectionKind === SelectionKind.Options ? (
              <ComboBox>
                {selectedUplinkAddresses?.map((addr) => (
                  <ComboBoxItem key={addr}>{addr}</ComboBoxItem>
                ))}
              </ComboBox>
            ) : (
              <TextInput />
            )
          }
          controls={
            selectedUplinkAddresses?.length && (
              <Select
                value={selectionKind}
                onValueChange={(value) => {
                  if (Object.values(SelectionKind).includes(value as SelectionKind)) {
                    setSelectionKind(value as SelectionKind);
                  }
                }}
              >
                <SelectItem key={SelectionKind.Options}>Uplink address</SelectItem>
                <SelectItem key={SelectionKind.Custom}>Custom</SelectItem>
              </Select>
            )
          }
        />
      </FieldProvider>

      {selectionKind === SelectionKind.Options && (
        <Alert
          icon="information"
          variant="neutral"
          relation="stacked"
          copy="1:1 NAT rule will not be updated automatically if uplink address changes."
        />
      )}
    </FieldContainer>
  );
}

function InternalIPAddressField() {
  const network = useNetwork();
  const { values } = useFormikContext<OneToOneNATRuleInput>();

  const dhcpRules = useGraphQL(dhcpRulesWithStaticMappingsForNetworkQuery, {
    networkUUID: network.UUID,
  }).data?.dhcpRulesForNetwork;

  const sortedDHCPRules = useMemo(
    () =>
      dhcpRules
        ?.map((rule) => ({
          ...rule,
          staticMappings: rule.staticMappings
            .filter((staticMapping) => !!staticMapping.ipAddress)
            .sort((a, b) => {
              if (a.ipAddress === b.ipAddress) return 0;
              if (!a.ipAddress) return -1;
              if (!b.ipAddress) return 1;
              return a.ipAddress.localeCompare(b.ipAddress);
            }),
        }))
        .filter((rule) => rule.staticMappings.length > 0)
        .sort((a, b) => a.vlan.name.localeCompare(b.vlan.name)) ?? [],
    [dhcpRules],
  );

  const [selectionKind, setSelectionKind] = useState(() =>
    sortedDHCPRules?.some((dhcpRule) =>
      dhcpRule.staticMappings.some(
        (staticMapping) => staticMapping.ipAddress === values.internalIPAddr,
      ),
    )
      ? SelectionKind.Options
      : SelectionKind.Custom,
  );

  useEffect(() => {
    if (!sortedDHCPRules.length) {
      setSelectionKind(SelectionKind.Custom);
    }
  }, [sortedDHCPRules]);

  return (
    <FieldContainer>
      <FieldProvider name="internalIPAddr">
        <PrimaryField
          label="Internal IP address"
          element={
            selectionKind === SelectionKind.Options ? (
              <ComboBox>
                {sortedDHCPRules.map((dhcpRule) => (
                  <ComboBoxSection title={dhcpRule.vlan.name}>
                    {dhcpRule.staticMappings
                      .filter((staticMapping) => !!staticMapping.ipAddress)
                      .map((staticMapping) => (
                        <ComboBoxItem
                          key={staticMapping.ipAddress}
                          textValue={`${staticMapping.ipAddress} ${staticMapping.name}`}
                        >
                          {staticMapping.ipAddress}
                          {staticMapping.name && ` (${staticMapping.name})`}
                        </ComboBoxItem>
                      ))}
                  </ComboBoxSection>
                ))}
              </ComboBox>
            ) : (
              <TextInput />
            )
          }
          controls={
            sortedDHCPRules.length > 0 && (
              <Select
                value={selectionKind}
                onValueChange={(value) => {
                  if (Object.values(SelectionKind).includes(value as SelectionKind)) {
                    setSelectionKind(value as SelectionKind);
                  }
                }}
              >
                <SelectItem key={SelectionKind.Options}>Static binding</SelectItem>
                <SelectItem key={SelectionKind.Custom}>Custom</SelectItem>
              </Select>
            )
          }
        />
      </FieldProvider>

      {selectionKind === SelectionKind.Options && (
        <Alert
          icon="information"
          variant="neutral"
          relation="stacked"
          copy="1:1 NAT rule will not be updated automatically if static binding changes."
        />
      )}
    </FieldContainer>
  );
}

export default function EditOneToOneNATRuleDrawer({ uuid }: { uuid?: string }) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const navigate = useNavigate();

  const { state } = useDialogState();

  const ruleResult = useGraphQL(
    oneToOneNATRuleQuery,
    {
      uuid: uuid!,
    },
    { enabled: !!uuid },
  );

  let rule: OneToOneNATRule | undefined;
  if (uuid) {
    rule = checkDefinedOrThrow(
      ruleResult?.data?.oneToOneNATRule,
      new ResourceNotFoundError('1:1 NAT rule not found'),
    );
  }

  const uplinkPhyInterfaces = useGraphQL(uplinkPhyInterfacesQuery, { networkUUID: network.UUID })
    .data?.uplinkPhyInterfacesForNetwork;

  const uplinkDevices = useMemo(
    () => getUplinkDevices(uplinkPhyInterfaces ?? []),
    [uplinkPhyInterfaces],
  );

  const createMutation = useGraphQLMutation(createOneToOneNATRuleMutation);
  const updateMutation = useGraphQLMutation(updateOneToOneNATRuleMutation);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    (input: OneToOneNATRuleInput) => {
      if (rule) {
        updateMutation.mutate(
          { uuid: rule.UUID, input },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(oneToOneNATRulesForNetworkQuery, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(
                makeQueryKey(oneToOneNATRuleQuery, { uuid: rule.UUID }),
              );
              notify('Successfully updated 1:1 NAT rule', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              const gqlError = getGraphQLError(err);
              notify(
                `There was a problem updating the 1:1 NAT rule${gqlError ? `: ${gqlError.message}` : ''}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        createMutation.mutate(
          { networkUUID: network.UUID, input },
          {
            onSuccess: (result) => {
              queryClient.invalidateQueries(
                makeQueryKey(oneToOneNATRulesForNetworkQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully created 1:1 NAT rule', {
                variant: 'positive',
              });
              navigate(
                makeDrawerLink(window.location, paths.drawers.EditOneToOneNATRulePage, {
                  ruleUUID: result.createOneToOneNATRule.UUID,
                  companyName,
                  networkSlug: network.slug,
                }),
              );
            },
            onError: (err) => {
              const gqlError = getGraphQLError(err);
              notify(
                `There was a problem creating the 1:1 NAT rule${gqlError ? `: ${gqlError.message}` : ''}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      network.UUID,
      network.slug,
      companyName,
      rule,
      queryClient,
      createMutation,
      updateMutation,
      navigate,
    ],
  );

  return (
    <Drawer>
      <Formik<OneToOneNATRuleInput>
        initialValues={{
          name: rule?.name ?? '',
          description: rule?.description ?? '',
          isEnabled: rule?.isEnabled ?? true,

          externalPhyInterfaceUUID: rule?.externalPhyInterfaceUUID ?? '',
          externalIPAddr: rule?.externalIPAddr ?? '',
          internalIPAddr: rule?.internalIPAddr ?? '',
        }}
        validate={withZodSchema(oneToOneNATRuleInputSchema)}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            heading={`${rule ? 'Edit' : 'Add'} 1:1 NAT rule`}
            icon="nat"
            actions={
              rule && (
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem icon="trash-can" onSelect={state.open}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
              )
            }
            onClose={closeDrawer}
          />
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enabled" />
              </FieldProvider>
            </FieldContainer>
            <TextField name="name" label="Name" />
            <TextareaField name="description" label="Description" />

            <FieldContainer>
              <FieldProvider name="externalPhyInterfaceUUID">
                <PrimaryField
                  label="WAN uplink"
                  element={
                    <ComboBox placeholder="Select WAN">
                      {uplinkDevices.map(([, uplinks]) => (
                        <ComboBoxSection title={uplinks[0].virtualDevice.label}>
                          {uplinks.map((pi) => (
                            <ComboBoxItem key={pi.UUID}>
                              {getPhyInterfaceLabel(pi, false, true)}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxSection>
                      ))}
                    </ComboBox>
                  }
                />
              </FieldProvider>
            </FieldContainer>

            <ExternalIPAddressField />
            <InternalIPAddressField />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
      {rule && <DeleteOneToOneNATRuleDialog state={state} rule={rule} />}
    </Drawer>
  );
}

import type { ButtonSizeProp, ButtonVariantProp } from '@meterup/atto';
import type { SortingState } from '@meterup/common';
import {
  Badge,
  Button,
  EmptyState,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  space,
  Text,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import type { OneToOneNATRule } from './utils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useFirewallCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { NoValue } from '../../NoValue';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { getPhyInterfaceLabel } from '../utils';
import OneToOneNATActions from './OneToOneNATActions';
import { oneToOneNATRulesForNetworkQuery } from './utils';

const builder = createColumnBuilder<OneToOneNATRule>();

const columns = [
  builder.data((row) => (row.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Enabled',
      },
    },
    cell: ({ row }) =>
      row.isEnabled ? (
        <Icon
          icon="checkmark"
          color={{
            light: 'iconPositiveLight',
            dark: 'iconPositiveDark',
          }}
        />
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => getPhyInterfaceLabel(row.externalPhyInterface), {
    id: 'wan-uplink',
    header: 'WAN uplink',
    cell: ({ row }) => (
      <Badge variant="neutral" size="small" icon="globe" arrangement="leading-icon">
        {getPhyInterfaceLabel(row.externalPhyInterface)}
      </Badge>
    ),
  }),
  builder.data((row) => row.externalIPAddr, {
    id: 'external-ip-address',
    header: 'External IP address',
    cell: ({ row }) => <Text family="monospace">{row.externalIPAddr}</Text>,
  }),
  builder.data((row) => row.internalIPAddr, {
    id: 'internal-ip-address',
    header: 'Internal IP address',
    cell: ({ row }) => <Text family="monospace">{row.internalIPAddr}</Text>,
  }),
  builder.data((row) => row.description ?? '', {
    id: 'description',
    header: 'Description',
    // eslint-disable-next-line react/jsx-no-useless-fragment
    cell: ({ row }) => <>{row.description ?? <NoValue />}</>,
  }),
];

export function AddOneToOneNATRuleButton({
  variant,
  size,
}: {
  variant?: ButtonVariantProp;
  size?: ButtonSizeProp;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Button
      condense
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.CreateOneToOneNATRulePage, {
        companyName,
        networkSlug: network.slug,
      })}
      arrangement="leading-icon"
      icon="plus"
      variant={variant}
      size={size}
    >
      Add 1:1 NAT rule
    </Button>
  );
}

export function OneToOneNATList() {
  const network = useNetwork();
  const companyName = useCurrentCompany();

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditPortForwardingRulePage);

  const portForwardingRules = useGraphQL(oneToOneNATRulesForNetworkQuery, {
    networkUUID: network.UUID,
  }).data?.oneToOneNATRulesForNetwork;

  const closeDrawer = useCloseDrawerCallback();
  const { hasPermission } = usePermissions();
  const isOperator = useIsOperator();
  const ldflags = useFeatureFlags();
  const canEditNATRule =
    (ldflags['one-to-one-nat'] && hasPermission(PermissionType.PermOneToOneNatWrite)) || isOperator;

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  if (!portForwardingRules?.length) {
    return (
      <EmptyState
        icon="nat"
        heading="You have no 1:1 NAT rules"
        action={
          <IsPermitted
            isPermitted={({ permissions, ldFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermOneToOneNatWrite) &&
                  ldFlags['one-to-one-nat'],
              )
            }
          >
            <AddOneToOneNATRuleButton />
          </IsPermitted>
        }
      />
    );
  }

  return (
    <AutoTable
      columns={columns}
      data={portForwardingRules}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      getLinkTo={(row) =>
        canEditNATRule
          ? makeDrawerLink(window.location, paths.drawers.EditOneToOneNATRulePage, {
              ruleUUID: row.UUID,
              companyName,
              networkSlug: network.slug,
            })
          : undefined
      }
      isRowSelected={(row) => row.UUID === drawerParams?.ruleUUID}
      onRowDeselect={closeDrawer}
    />
  );
}

export default function OneToOneNAT() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const firewallCrumb = useFirewallCrumbs();

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...firewallCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.OneToOneNATPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: '1:1 NAT',
            },
          },
        ]}
        icon="nat"
        heading="1:1 NAT"
        actions={<OneToOneNATActions />}
      />
      <PaneContent>
        <OneToOneNATList />
      </PaneContent>
    </Pane>
  );
}

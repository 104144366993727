import { Body } from '@meterup/atto';

import { styled } from '../stitches';

export const BulletedList = styled('ul', {
  listStyle: 'none',
  margin: 0,
  padding: 0,
});
export const BulletedListItem = styled('li', Body, {
  listStyleType: 'disc',
  fontWeight: '$bold',
  marginLeft: '$20',
  paddingLeft: '$4',
});

import { Skeleton } from '@meterup/atto';
import * as d3 from 'd3';
import { useMemo } from 'react';

import type { UplinkThroughputProps } from './utils';
import { graphql } from '../../../gql';
import { TrafficDirection } from '../../../gql/graphql';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { TimeSeriesChart } from '../../TimeSeriesChart';
import { calculateRange, dataRateValueFormatter } from './utils';

export const networkUplinkThroughputQuery = graphql(`
  query networkUplinkThroughput($networkUUID: UUID!, $filter: MetricsFilterInput!) {
    networkUplinkThroughput(networkUUID: $networkUUID, filter: $filter) {
      values {
        timestamp
        value
        direction
        phyInterfaceUUID
      }
    }
  }
`);

export default function UplinkThroughputChart({
  data,
  isError,
  currentUplinkPhyInteface,
  uplinkName,
}: UplinkThroughputProps) {
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const [txSeries, rxSeries] = useMemo(() => {
    const tx = data?.networkUplinkThroughput.values
      .filter(
        (val) =>
          val.direction === TrafficDirection.Tx &&
          val.phyInterfaceUUID === currentUplinkPhyInteface,
      )
      .map(({ timestamp, ...rest }) => ({
        timestamp: new Date(timestamp),
        ...rest,
      }));
    const rx = data?.networkUplinkThroughput.values
      .filter(
        (val) =>
          val.direction === TrafficDirection.Rx &&
          val.phyInterfaceUUID === currentUplinkPhyInteface,
      )
      .map(({ timestamp, ...rest }) => ({
        timestamp: new Date(timestamp),
        ...rest,
      }));
    return [tx, rx];
  }, [data, currentUplinkPhyInteface]);
  if (txSeries && rxSeries) {
    return (
      <TimeSeriesChart
        title={uplinkName ? `${uplinkName} throughput` : 'ISP throughput'}
        tooltipBody="The number of bits sent to / received from the public internet."
        valueFormatter={dataRateValueFormatter}
        series={[
          {
            data: txSeries,
            curve: d3.curveStepBefore,
            range: calculateRange(txSeries),
            series_type: 'area',
            series_id: 'ISPThroughputTx',
            series_name: 'Tx',
          },
          {
            data: rxSeries,
            curve: d3.curveStepBefore,
            range: calculateRange(rxSeries),
            series_type: 'area',
            series_id: 'ISPThroughputRx',
            series_name: 'Rx',
          },
        ]}
        timePeriod={currentTimePeriod}
        showYAxis
        isError={isError}
      />
    );
  }
  return <Skeleton height={200} width="100%" radius={6} />;
}

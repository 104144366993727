import {
  Badge,
  Box,
  Column,
  Columns,
  HStack,
  Icon,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  Skeleton,
  space,
  SummaryList,
  Text,
  Tooltip,
  VStack,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import * as d3 from 'd3';
import { Suspense, useMemo } from 'react';

import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import {
  apChannelUtilizationV2MetricsToSeries,
  clientMetricsV2ToSeries,
  dataRateValueFormatter,
  dataRateValueFormatterShort,
  getDurationSeconds,
  getStep,
  nonZeroCountFormatter,
  percentValueFormatter,
  snrValueFormatter,
  snrValueFormatterShort,
  usageValueFormatter,
  usageValueFormatterShort,
} from '../../../utils/chart_utils';
import TimeSeriesChart from '../../Charts/TimeSeriesChart';
import { healthVariantIcon, healthVariantText } from '../../Clients/utils';
import {
  MetricBandFilter,
  statsFromAggregates,
  useAggregateMetricsAndConnectionEvents,
} from '../../Metrics/utils';
import { DetailsWidget } from '../../Wireless/insights';
import { radioBandFromHuman } from '../../Wireless/RadioProfiles/utils';
import {
  AccessPointQuery,
  APChannelUtilizationByAccessPointQuery,
  WirelessClientMetricsByAccessPointQuery,
} from '../../Wireless/utils';

function AccessPointCharts({
  virtualDeviceUUID,
  currentTimePeriod,
  currentSSIDUUID,
  currentBand,
}: {
  virtualDeviceUUID: string;
  currentTimePeriod: string;
  currentSSIDUUID: string;
  currentBand: MetricBandFilter;
}) {
  const network = useNetwork();

  const { data: metricsData } = useGraphQL(WirelessClientMetricsByAccessPointQuery, {
    networkUUID: network.UUID,
    virtualDeviceUUID,
    filter: {
      bands: currentBand === 'All' ? undefined : [radioBandFromHuman(currentBand)],
      ssidUUIDs: currentSSIDUUID === 'All' ? undefined : [currentSSIDUUID],
      timeFilter: {
        durationSeconds: getDurationSeconds(currentTimePeriod),
        stepSeconds: getStep(currentTimePeriod),
      },
    },
  });
  expectDefinedOrThrow(metricsData, new ResourceNotFoundError('Unable to load client metrics'));

  const series = useMemo(
    () =>
      clientMetricsV2ToSeries(
        metricsData?.wirelessClientMetricsByAP?.values,
        getStep(currentTimePeriod),
      ),
    [metricsData?.wirelessClientMetricsByAP?.values, currentTimePeriod],
  );

  const maxUsage = Math.max(series.rxUsage.max_value ?? 0, series.txUsage.max_value ?? 0);
  const usageYDomain = maxUsage > 0 ? [0, maxUsage * 2] : undefined;

  const snrYDomain =
    series.snr.min_value !== undefined && series.snr.max_value !== undefined
      ? [Math.min(series.snr.min_value, 0), Math.max(series.snr.max_value, 0) + 10]
      : undefined;

  const maxRate = Math.max(series.rxRate.max_value ?? 0, series.txRate.max_value ?? 0);
  const rateYDomain = maxRate > 0 ? [0, maxRate + 80000] : undefined;

  const maxTraffic = Math.max(
    series.unicastBytes.max_value ?? 0,
    series.multicastBytes.max_value ?? 0,
  );
  const trafficYDomain = maxTraffic > 0 ? [0, maxTraffic * 2] : undefined;

  const { data: channelData } = useGraphQL(APChannelUtilizationByAccessPointQuery, {
    networkUUID: network.UUID,
    virtualDeviceUUID,
    filter: {
      timeFilter: {
        durationSeconds: getDurationSeconds(currentTimePeriod),
        stepSeconds: getStep(currentTimePeriod),
      },
    },
  });

  const apSeries = useMemo(
    () =>
      apChannelUtilizationV2MetricsToSeries(
        channelData?.channelUtilizationByAP ?? [],
        getStep(currentTimePeriod),
      ),
    [channelData?.channelUtilizationByAP, currentTimePeriod],
  );

  const maxUtilization = Math.max(
    apSeries.twoGUtilization.max_value ?? 0,
    apSeries.fiveGUtilization.max_value ?? 0,
  );
  const utilizationYDomain = maxUtilization > 0 ? [0, maxUtilization * 2] : undefined;

  return (
    <>
      <TimeSeriesChart
        curve={d3.curveStep}
        series={[series.clientCount]}
        seriesType="line"
        showSeriesGlyphs
        showSeriesTooltips
        seriesLabel="access-point-client-count"
        timePeriod={currentTimePeriod}
        title="Client count"
        tooltipBody="The total number of clients connected to the access point."
        valueFormatter={(d) => d.toFixed(0)}
        yTickValueFormatter={nonZeroCountFormatter}
        multiSeries
        showLegend={false}
        yDomain={[0, (series.clientCount.max_value ?? 50) * 2]}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[series.rxUsage, series.txUsage]}
        seriesType="area"
        seriesLabel="access-point-usage"
        showSeriesGlyphs
        showSeriesTooltips
        timePeriod={currentTimePeriod}
        title="Usage"
        tooltipBody="The total amount of data sent (tx) or received (rx) by the clients connected to the access point in bytes."
        valueFormatter={usageValueFormatter}
        yTickValueFormatter={usageValueFormatterShort}
        multiSeries
        showLegend
        yDomain={usageYDomain}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[series.snr]}
        seriesType="area"
        seriesLabel="access-point-snr"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="SNR"
        tooltipBody="Measures the ratio of signal to noise."
        valueFormatter={snrValueFormatter}
        yTickValueFormatter={snrValueFormatterShort}
        multiSeries={false}
        showLegend={false}
        yDomain={snrYDomain}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[series.rxRate, series.txRate]}
        seriesType="area"
        seriesLabel="access-point-rate"
        showSeriesGlyphs
        showSeriesTooltips
        timePeriod={currentTimePeriod}
        title="Data rate"
        tooltipBody="The rate of bits sent (tx) and received (rx) by the clients connected to the access point."
        valueFormatter={dataRateValueFormatter}
        yTickValueFormatter={dataRateValueFormatterShort}
        multiSeries
        showLegend
        yDomain={rateYDomain}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[series.unicastBytes, series.multicastBytes]}
        seriesType="area"
        seriesLabel="access-point-traffic"
        showSeriesGlyphs
        showSeriesTooltips
        timePeriod={currentTimePeriod}
        title="Data traffic"
        tooltipBody="The number of unicast and multicast bytes (data) sent and received by the access point."
        valueFormatter={usageValueFormatter}
        yTickValueFormatter={usageValueFormatterShort}
        multiSeries
        showLegend
        yDomain={trafficYDomain}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[apSeries.twoGUtilization, apSeries.fiveGUtilization]}
        seriesType="area"
        seriesLabel="access-point-utilization"
        showSeriesGlyphs
        showSeriesTooltips
        timePeriod={currentTimePeriod}
        title="Channel utilization"
        tooltipBody="The percentage of time the channel used by the access point was utilized."
        valueFormatter={percentValueFormatter}
        yTickValueFormatter={percentValueFormatter}
        multiSeries
        showLegend
        yDomain={utilizationYDomain}
      />
    </>
  );
}

function AccessPointStats({ virtualDeviceUUID }: { virtualDeviceUUID: string }) {
  const aggregateValues = useAggregateMetricsAndConnectionEvents(
    virtualDeviceUUID,
    'access-point',
    30 * 60,
  );
  const aggregateStats = useMemo(
    () =>
      statsFromAggregates(aggregateValues, [
        'snr',
        'usage',
        'rxRate',
        'txRate',
        'connectionSuccess',
      ]),
    [aggregateValues],
  );

  if (aggregateStats.items.length === 0) return null;

  return (
    <Section relation="stacked">
      <SectionHeader
        heading={
          <HStack align="center" spacing={space(8)}>
            <Text>Heartbeat</Text>
            <HStack align="baseline" spacing={space(4)}>
              <Badge
                arrangement="leading-icon"
                ends="pill"
                icon={healthVariantIcon(aggregateStats.healthVariant)}
                size="small"
                variant={aggregateStats.healthVariant}
              >
                {healthVariantText(aggregateStats.healthVariant)}
              </Badge>
              <Tooltip asChild={false} contents="Aggregate statistics from the last 30 minutes.">
                <Icon icon="question" size={space(14)} />
              </Tooltip>
            </HStack>
          </HStack>
        }
      />
      <SectionContent gutter="all">
        <SummaryList direction="row" gutter="none" pairs={aggregateStats.items} />
      </SectionContent>
    </Section>
  );
}

function AccessPointInsights({ uuid }: { uuid: string }) {
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const accessPointData = useGraphQL(AccessPointQuery, {
    uuid,
    includeUptime,
  }).data;
  expectDefinedOrThrow(accessPointData, new ResourceNotFoundError('Access point not found'));
  expectDefinedOrThrow(
    accessPointData.virtualDevice,
    new ResourceNotFoundError('Virtual device not found'),
  );

  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const [currentBandOrUndefined] = useSearchParamsState<MetricBandFilter>(
    'band',
    MetricBandFilter.All,
  );
  const currentBand = currentBandOrUndefined ?? MetricBandFilter.All;

  const [currentSSIDOrUndefined] = useSearchParamsState<{
    UUID: string;
    ssid: string;
  }>('ssid', { UUID: 'All', ssid: 'All' });
  const currentSSID = currentSSIDOrUndefined ?? { UUID: 'All', ssid: 'All' };

  return (
    <Columns template="object">
      <Column>
        <Sections>
          <DetailsWidget
            hardwareDevice={accessPointData.virtualDevice.hardwareDevice}
            virtualDevice={accessPointData.virtualDevice}
            relation="stacked"
          />
        </Sections>
      </Column>
      <Column>
        <Sections>
          <Suspense
            fallback={
              <Box padding={{ x: space(16) }}>
                <VStack spacing={space(12)}>
                  <Skeleton height={200} width="100%" radius={6} />
                </VStack>
              </Box>
            }
          >
            <AccessPointStats virtualDeviceUUID={accessPointData.virtualDevice.UUID} />
            <AccessPointCharts
              virtualDeviceUUID={accessPointData.virtualDevice.UUID}
              currentSSIDUUID={currentSSID.UUID}
              currentBand={currentBand}
              currentTimePeriod={currentTimePeriod}
            />
          </Suspense>
        </Sections>
      </Column>
    </Columns>
  );
}

export default AccessPointInsights;

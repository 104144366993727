import {
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { ACSDialog, ACSJobType } from '../../ACS/ACSDialog';
import { KeyBoundSearchInput } from '../../KeyBoundSearchInput';
import IsPermitted from '../../permissions/IsPermitted';
import { useIsPermitted } from '../../permissions/useIsPermitted';
import {
  AccessPointLabelsCopyDescription,
  AccessPointsQuery,
  CopyAccessPointLabelsMutation,
} from '../../Wireless/utils';

export default function AccessPointsActions() {
  const network = useNetwork();
  const networkUUID = network.UUID;
  const companyName = useCurrentCompany();
  const copyLabelsMutation = useGraphQLMutation(CopyAccessPointLabelsMutation);
  const queryClient = useQueryClient();
  const { state } = useDialogState();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const { close } = state;

  const handleCopyLabels = useCallback(
    () =>
      copyLabelsMutation.mutate(
        { networkUUID },
        {
          onSuccess() {
            queryClient.invalidateQueries(
              makeQueryKey(AccessPointsQuery, { networkUUID, includeUptime }),
            );
            notify('Copied labels successfully', { variant: 'positive' });
          },
          onError(err) {
            const gqlErr = getGraphQLError(err);
            notify(`There was an error copying labels: ${gqlErr?.message ?? 'Unknown error'}`, {
              variant: 'negative',
            });
          },
          onSettled: close,
        },
      ),
    [copyLabelsMutation, networkUUID, includeUptime, queryClient, close],
  );

  const navigateToBulkAdd = useCallback(() => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.AccessPointsBulkCreatePage, {
        companyName,
        networkSlug: network.slug,
      }),
    );
  }, [navigate, network.slug, companyName]);

  const navigateToCSVUpload = useCallback(() => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.AccessPointsBulkCSVUploadPage, {
        companyName,
        networkSlug: network.slug,
      }),
    );
  }, [navigate, network.slug, companyName]);

  const acsDialogProps = useDialogState();

  const featureFlags = useFeatureFlags();
  const [isACSNOSEnabled] = useNosFeaturesEnabled([NosFeature.WOS_ACS]);
  const isACSEnabled = isACSNOSEnabled && featureFlags['wos-acs'];

  const canEditAPRestricted = useIsPermitted({
    isPermitted({ permissions, nosFlags }) {
      return Boolean(
        permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
          nosFlags[NosFeature.WOS2],
      );
    },
  });

  return (
    <>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
              permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
              nosFlags[NosFeature.WOS2],
          )
        }
      >
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.AccessPointCreatePage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
          internal
        >
          Add access point
        </Button>
      </IsPermitted>

      {/* Actions menu */}
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
              nosFlags[NosFeature.WOS2],
          )
        }
      >
        <DropdownMenu>
          <DropdownMenuButton
            variant="secondary"
            icon="overflow-horizontal"
            arrangement="hidden-label"
            internal
          >
            Actions
          </DropdownMenuButton>
          <DropdownMenuPopover align="end">
            <DropdownMenuGroup>
              <IsPermitted
                isPermitted={({ permissions, nosFlags }) =>
                  Boolean(
                    permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
                      nosFlags[NosFeature.WOS2],
                  )
                }
              >
                <DropdownMenuItem icon="plus" onClick={navigateToBulkAdd} internal>
                  Bulk add access points
                </DropdownMenuItem>
                <DropdownMenuItem icon="plus" onClick={navigateToCSVUpload} internal>
                  Bulk upload access point CSV
                </DropdownMenuItem>
              </IsPermitted>
              <DropdownMenuItem icon="copy" onClick={state.open} internal>
                Copy legacy access point labels
              </DropdownMenuItem>
            </DropdownMenuGroup>
            {isACSEnabled && (
              <DropdownMenuGroup>
                <DropdownMenuItem onSelect={acsDialogProps.state.open} icon="channel" internal>
                  Run ACS
                </DropdownMenuItem>
              </DropdownMenuGroup>
            )}
          </DropdownMenuPopover>
        </DropdownMenu>
      </IsPermitted>
      {/* /Actions menu */}

      <KeyBoundSearchInput
        searchParamStateKey="filter"
        placeholder="..."
        aria-label="Filter access points"
        scope="scoped"
        minWidth="56px"
      />
      {canEditAPRestricted && (
        <>
          <Dialog state={state}>
            <DialogHeader icon="access-point" heading="Copy legacy labels" />
            <DialogContent gutter="all">
              <Body>
                <AccessPointLabelsCopyDescription />
              </Body>
            </DialogContent>
            <DialogFooter
              actions={
                <>
                  <Button variant="secondary" onClick={state.close}>
                    Cancel
                  </Button>
                  <Button onClick={handleCopyLabels}>Copy labels</Button>
                </>
              }
            />
          </Dialog>

          <ACSDialog
            state={acsDialogProps.state}
            identifier={network.UUID}
            jobType={ACSJobType.Network}
          />
        </>
      )}
    </>
  );
}

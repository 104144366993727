import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { graphql } from '../../../gql';

export const captivePortalBodyFragment = graphql(`
  fragment CaptivePortalBody on CaptivePortal {
    UUID
    callToAction
    logoImageBase64 {
      data
      imageType
    }
    logoImageS3Key
    name
    redirectURL
    allowedHosts
    externalPortalURL
    authLifetimeSec
    termsAndConditions
    vlans {
      UUID
      name
    }
    displaySettings {
      backgroundColor
      fontColor
      hidePoweredByMeter
    }
    webhookKey
    isEnabled
  }
`);

const captivePortalForNetworkQuery = graphql(`
  query CaptivePortalForNetworkQuery($networkUUID: UUID!) {
    captivePortalForNetwork(networkUUID: $networkUUID) {
      UUID
      ...CaptivePortalBody
    }
  }
`);

export function useCaptivePortalForNetwork(networkUUID: string) {
  return useGraphQL(captivePortalForNetworkQuery, { networkUUID });
}

export function useInvalidateCaptivePortalForNetwork(networkUUID: string) {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: makeQueryKey(captivePortalForNetworkQuery, { networkUUID }),
      refetchType: 'all',
    });
  }, [networkUUID, queryClient]);
}

export const updateCaptivePortalMutation = graphql(`
  mutation UpdateCaptivePortal($uuid: UUID!, $input: CaptivePortalSettingsInput!) {
    updateCaptivePortal(input: $input, uuid: $uuid) {
      UUID
      ...CaptivePortalBody
    }
  }
`);

export const deleteCaptivePortalMutation = graphql(`
  mutation DeleteCaptivePortal($uuid: UUID!) {
    deleteCaptivePortal(uuid: $uuid) {
      UUID
    }
  }
`);
export const createCaptivePortalMutation = graphql(`
  mutation CreateCaptivePortal($networkUUID: UUID!, $input: CaptivePortalSettingsInput!) {
    createCaptivePortal(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const capoActivityQuery = graphql(`
  query capoActivityQuery($networkUUID: UUID!, $numHoursLookback: Int!) {
    captivePortalAuthorizedClients(networkUUID: $networkUUID, numHoursLookback: $numHoursLookback) {
      timestamp
      isAuthorized
      ipAddress
      macAddress
      rawUserAgent
    }
  }
`);

import { Button, EmptyState } from '@meterup/atto';
import { useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router';

import { fetchVPNClientsWithLogs, fetchVPNServer } from '../../../../../../api/vpn';
import { LegacyVPNClientsList } from '../../../../../../components/VPNClientsList';
import { paths } from '../../../../../../constants';
import { useNetworkOrNull } from '../../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import {
  useCurrentController,
  useCurrentControllerData,
} from '../../../../../../providers/CurrentControllerProvider';
import { useIdentity } from '../../../../../../providers/IdentityDataProvider';
import { makeLink } from '../../../../../../utils/main_and_drawer_navigation';
import { zendeskFeedbackURL } from '../../../../../../utils/zendesk';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/secure-tunnels/vpn',
  layout: 'NetworkLayout',
});

function VPNServerDisabledPage() {
  const controller = useCurrentControllerData();
  const identity = useIdentity();

  if (controller.uses_dhcp && import.meta.env.REALM === 'prod') {
    return (
      <EmptyState
        icon="secure"
        heading="VPN is not enabled"
        subheading="In order to enable the VPN, you must have a static IP address configured for all of your ISPs."
        action={
          <Button
            as="a"
            href={zendeskFeedbackURL(identity.username, 'Enabling the VPN on my network')}
            target="_blank"
            arrangement="leading-icon"
            icon="question"
          >
            Contact support
          </Button>
        }
      />
    );
  }

  return (
    <EmptyState
      icon="secure"
      heading="VPN is not enabled"
      subheading={
        controller.uses_dhcp
          ? 'In order to enable the VPN, you must have a static IP address configured for all of your ISPs.'
          : 'Enabling VPN requires a brief network restart. Please reach out to our team to coordinate times that work best for you.'
      }
      action={
        <Button
          as="a"
          href={zendeskFeedbackURL(identity.username, 'Enabling the VPN on my network')}
          target="_blank"
          arrangement="leading-icon"
          icon="question"
        >
          Contact support
        </Button>
      }
    />
  );
}

function VPNServerEnabledPage() {
  const controller = useCurrentController();

  const vpnClientsWithLogs =
    useQuery(['controller', controller, 'vpn-clients'], () => fetchVPNClientsWithLogs(controller), {
      suspense: true,
    }).data ?? [];

  return <LegacyVPNClientsList clients={vpnClientsWithLogs} />;
}

function LegacyVPNList() {
  const controller = useCurrentController();
  const vpnServer = useQuery(
    ['controller', controller, 'vpn-server'],
    () => fetchVPNServer(controller),
    {
      suspense: true,
    },
  ).data;

  if (vpnServer === null) {
    return <VPNServerDisabledPage />;
  }

  return <VPNServerEnabledPage />;
}

export default function LegacyVPNListPage() {
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const companyName = useCurrentCompany();
  const network = useNetworkOrNull();

  if (network && isCOS2Enabled) {
    return (
      <Navigate
        to={makeLink(paths.pages.ClientVPNPage, {
          companyName,
          networkSlug: network?.slug,
        })}
      />
    );
  }

  return <LegacyVPNList />;
}

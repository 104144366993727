import type { ForwardedRef } from 'react';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Pane, PaneContent, PaneHeader, Tab } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useHardwareCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { AccessPointsQuery, ValidAPListTabs } from '../../Wireless/utils';
import AccessPointsActions from './AccessPointsActions';
import AccessPointsInsightsFilter from './AccessPointsInsightsFilter';

function AccessPointsTabs({ activeTab }: { activeTab: ValidAPListTabs }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  return (
    <>
      <IsPermitted isPermitted={({ isOperator }) => isOperator}>
        <Tab
          as={Link}
          to={makeLink(paths.pages.AccessPointsInsightsPage, {
            companyName,
            networkSlug: network.slug,
          })}
          selected={activeTab === ValidAPListTabs.Insights}
          icon="reporting"
          internal
        >
          Insights
        </Tab>
      </IsPermitted>

      <Tab
        as={Link}
        to={makeLink(paths.pages.AccessPointsListPage, {
          companyName,
          networkSlug: network.slug,
        })}
        selected={activeTab === ValidAPListTabs.List}
        icon="list"
      >
        List
      </Tab>
      <Tab
        as={Link}
        to={makeLink(paths.pages.AccessPointsBSSIDsPage, {
          companyName,
          networkSlug: network.slug,
        })}
        selected={activeTab === ValidAPListTabs.BSSIDs}
        icon="mac-address"
      >
        BSSIDs
      </Tab>
    </>
  );
}

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points',
  layout: 'VirtualNetworkLayout',
});

export default function AccessPointsLayout({
  activeTab,
  content,
  contentContainerRef,
}: {
  activeTab: ValidAPListTabs;
  content: React.ReactNode;
  contentContainerRef?: ForwardedRef<HTMLDivElement> | null;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const hardwareCrumb = useHardwareCrumbs();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const accessPointsData = useGraphQL(AccessPointsQuery, {
    networkUUID: network.UUID,
    includeUptime,
  }).data;

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...hardwareCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.AccessPointsListPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'Access points',
            },
          },
        ]}
        icon="access-point"
        heading="Access points"
        contentActions={<AccessPointsActions />}
        tabs={<AccessPointsTabs activeTab={activeTab} />}
        count={accessPointsData?.virtualDevicesForNetwork.length}
        views={activeTab === ValidAPListTabs.Insights ? <AccessPointsInsightsFilter /> : undefined}
      />
      <PaneContent gutter="bottom" ref={contentContainerRef}>
        {content}
      </PaneContent>
    </Pane>
  );
}

import { NetworkJoinInstructions } from '../../../components/TabletJoinInstructions';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/join/:ssid',
  layout: null,
});

export default function NetworkTabletJoinInstructionsPage() {
  return <NetworkJoinInstructions />;
}

import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { NetworksForCompanyQuery } from '../gql/graphql';
import { graphql } from '../gql';

graphql(`
  fragment NetworkForCompanyFields on Network {
    UUID
    label
    slug
    isActive
    isCustomer
    isTemplate
    isTrial
    company {
      slug
      name
      uuid
      supportGroup
    }
    virtualDevices(filter: { deviceType: CONTROLLER }) {
      __typename
      UUID
      label
      deviceType
      hardwareDevice {
        serialNumber
        isActive
        isConnectedToBackend
      }
      ... on ControllerVirtualDevice {
        highAvailability {
          role
          status
        }
      }
    }
    mailingAddress {
      line1
      line2
      city
      subdivisionCode
      postalCode
      timezone
      countryISO2
    }
    enabledServiceDiscoveryTypes
    multiWANAlgorithm
    pinnedNOSVersionID
  }
`);

export const networksForCompany = graphql(`
  query NetworksForCompany($companySlug: String!) {
    networksForCompany(companySlug: $companySlug) {
      ...NetworkForCompanyFields
    }
  }
`);

export const networkQuery = graphql(`
  query NetworkByUUIDQuery($networkUUID: UUID!) {
    network(UUID: $networkUUID) {
      ...NetworkForCompanyFields
    }
  }
`);

export type Network = NetworksForCompanyQuery['networksForCompany'][number];

export function useNetworksForCompany(companySlug?: string | null): Network[] {
  const networks = useGraphQL(
    networksForCompany,
    { companySlug: companySlug as string },
    { enabled: !!companySlug },
  ).data?.networksForCompany;
  return useMemo(() => networks ?? [], [networks]);
}

export function useNetworkByUUID(networkUUID: string): Network | null {
  const network = useGraphQL(networkQuery, { networkUUID }).data?.network;
  return useMemo(() => network ?? null, [network]);
}

export function getDefaultNetwork(networks: Network[]): Network | null {
  return (
    // Prefer active networks
    networks.find((network) => network.isActive) ??
    // If no active networks, just use the first network
    networks[0] ??
    null
  );
}

export function useDefaultNetworkForCompany(companySlug: string): Network | null {
  const networks = useNetworksForCompany(companySlug);
  if (networks && networks.length > 0) {
    return getDefaultNetwork(networks);
  }
  return null;
}

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';
import { notify } from '@meterup/common';
import { getGraphQLError, useGraphQLMutation } from '@meterup/graphql';
import { capitalize } from 'lodash-es';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import SearchField from '../../Actions/SearchField';
import { copyISPsToInternetServicePlansMutation, useInvalidateISPs } from './utils';

export function InternetServicePlansCopyDescription() {
  return (
    <>
      Copies internet service provider information essentially one-to-one from the config 1
      controller to the config 2 network.
    </>
  );
}

function CopyISPsDialog({ state }: { state: OverlayTriggerState }) {
  const companySlug = useCurrentCompany();
  const network = useNetwork();
  const copyISPs = useGraphQLMutation(copyISPsToInternetServicePlansMutation, {
    useErrorBoundary: false,
  });

  const invalidateISPs = useInvalidateISPs({ companySlug, networkUUID: network.UUID });

  const { close } = state;

  const handleCopy = useCallback(() => {
    copyISPs.mutate(
      { networkUUID: network.UUID },
      {
        onSuccess() {
          invalidateISPs();
          close();
          notify('ISPs copied successfully.', {
            variant: 'positive',
          });
        },
        // Error is handled via the alert below
      },
    );
  }, [copyISPs, network.UUID, invalidateISPs, close]);

  const graphqlError = copyISPs.error ? getGraphQLError(copyISPs.error) : undefined;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader heading="Copy legacy ISPs" icon="copy" />
      <DialogContent gutter="all">
        <Body>
          <InternetServicePlansCopyDescription />
        </Body>
        {copyISPs.isError && (
          <Alert
            variant="negative"
            heading="There was a problem copying ISPs"
            copy={graphqlError ? capitalize(graphqlError.message) : undefined}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button type="button" onClick={state.close} variant="secondary">
              Cancel
            </Button>
            <Button type="button" onClick={handleCopy} variant="primary">
              Copy ISPs
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

export default function ISPsActions() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();

  const openMoveISP = () => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.ISPMoveDrawerPage, {
        networkSlug: network.slug,
        companyName,
      }),
    );
  };

  const copyLegacyISPsDialogState = useDialogState();

  return (
    <>
      <IsOperator>
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.ISPsAddDrawerPage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
          internal
        >
          Add ISP
        </Button>
        <DropdownMenu>
          <DropdownMenuButton
            arrangement="hidden-label"
            icon="overflow-horizontal"
            variant="secondary"
            internal
          >
            Actions
          </DropdownMenuButton>
          <DropdownMenuPopover align="end">
            <DropdownMenuGroup>
              <DropdownMenuItem onSelect={openMoveISP} icon="arrow-bidirectional" internal>
                Move ISP
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={copyLegacyISPsDialogState.openFromMenu}
                icon="copy"
                internal
              >
                Copy legacy ISPs
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuPopover>
        </DropdownMenu>
        <CopyISPsDialog state={copyLegacyISPsDialogState.state} />
      </IsOperator>
      <SearchField />
    </>
  );
}

import VLANDrawer from '../../../../../components/NetworkWide/VLANs/VLANDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid',
  title: 'VLAN - VLANs - Network-wide',
});

export default function VLANDrawerPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.VLANDrawerPage);

  return (
    <IsPermitted
      isPermitted={({ permissions, pathParams }) =>
        Boolean(permissions.hasPermission(PermissionType.PermVlanRead) && !!pathParams?.uuid)
      }
      path={Meta().path}
      should404OnAccessDenied
    >
      <VLANDrawer uuid={drawerParams?.uuid!} />
    </IsPermitted>
  );
}

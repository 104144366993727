import { PaneHeader, Tab } from '@meterup/atto';

import { paths } from '../../constants';
import { useIsActiveMatcher } from '../../hooks/useIsActive';
import { ReactRouterLink } from '../ReactRouterLink';

export default function BulkUpgraderHeader() {
  const isActiveTest = useIsActiveMatcher();

  const isFirmwareBulkUpgraderListPageActive = isActiveTest({
    path: paths.pages.FirmwareBulkUpgradesListPage,
    end: true,
  });
  const isAddBulkFirmwareUpgradeActive = isActiveTest({
    path: paths.pages.AddFirmwareBulkUpgradePage,
    end: true,
  });
  return (
    <PaneHeader
      icon="upgrading"
      heading="Firmware bulk upgrader"
      tabs={
        <>
          <Tab
            as={ReactRouterLink}
            to={paths.pages.AddFirmwareBulkUpgradePage}
            selected={isAddBulkFirmwareUpgradeActive}
          >
            Add bulk upgrade
          </Tab>
          <Tab
            as={ReactRouterLink}
            to={paths.pages.FirmwareBulkUpgradesListPage}
            selected={isFirmwareBulkUpgraderListPageActive}
          >
            Bulk upgrades
          </Tab>
        </>
      }
    />
  );
}

import {
  Alert,
  Body,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';

import type { FixMeLater } from '../../../../../../types/fixMeLater';
import { fetchVPNClient } from '../../../../../../api/vpn';
import { paths } from '../../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../../hooks/useControllerConfig';
import { Nav } from '../../../../../../nav';
import { useCurrentController } from '../../../../../../providers/CurrentControllerProvider';
import { createWGConf } from '../../../../../../utils/vpn';
import { DeleteVPNClientDialog } from './VPNClientDelete';
import { VPNSummaryTabs } from './vpnSummary';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vpn/:sid/config',
});

export default function VPNClientConfigSummary() {
  const controllerName = useCurrentController();
  const controllerConfig = useControllerConfig(controllerName);
  const { sid } = Nav.useRegionParams('drawer', paths.drawers.VPNClientSummaryPage)!;

  const vpnClientDetails = useQuery(
    ['controller', controllerName, 'vpn-clients', sid],
    () => fetchVPNClient(controllerName, sid),
    { suspense: true },
  ).data;

  const dnsServers = controllerConfig.vlans.find((vlan) => vlan.name === 'private')?.json.dhcp?.[
    'dns-servers'
  ];

  const closeDrawer = useCloseDrawerCallback();
  const dialogProps = useDialogState();
  const client = vpnClientDetails?.client;
  const server = vpnClientDetails?.server;

  expectDefinedOrThrow(
    vpnClientDetails,
    new ResourceNotFoundError('VPN client response not found'),
  );
  expectDefinedOrThrow(client, new ResourceNotFoundError('VPN client not found'));
  expectDefinedOrThrow(server, new ResourceNotFoundError('VPN client not found'));

  const wgCfg = createWGConf(
    client.ip_address,
    server.endpoint,
    server.port as FixMeLater,
    server.public_key as FixMeLater,
    '$YOUR_PRIVATE_KEY',
    server.default_client_allowed_ips,
    dnsServers,
  );

  return (
    <Drawer>
      <DrawerHeader
        icon="client"
        heading={client.name}
        tabs={<VPNSummaryTabs sid={sid} />}
        actions={
          <DropdownMenu>
            <DropdownMenuButton
              arrangement="hidden-label"
              icon="overflow-horizontal"
              variant="secondary"
            >
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover align="end">
              <DropdownMenuItem onSelect={dialogProps.state.open} icon="trash-can">
                Delete
              </DropdownMenuItem>
            </DropdownMenuPopover>
          </DropdownMenu>
        }
        onClose={closeDrawer}
      />
      <DrawerContent>
        <Alert
          icon="information"
          heading="Your WireGuard-compatible config"
          copy={
            'Make sure you replace "$YOUR_PRIVATE_KEY" with your private key when pasting into WireGuard.'
          }
        />
        <CopyBox
          aria-label="Copy your WireGuard config"
          relation="standalone"
          size="large"
          value={wgCfg}
        >
          <Body family="monospace">{wgCfg}</Body>
        </CopyBox>
      </DrawerContent>
      <DeleteVPNClientDialog
        controllerName={controllerName}
        vpnClientName={client.name}
        vpnClientSid={client.sid}
        {...dialogProps}
      />
    </Drawer>
  );
}

import RackElevations from '../../../../../components/Design/RackElevations/RackElevations';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/design/rack-elevations',
  layout: 'VirtualNetworkLayout',
  title: 'Rack elevations - Design',
});

export default function RackElevationsPage() {
  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermRackElevationRead) &&
        (isOperator || !!ldFlags['rack-elevation'])
      }
      should404OnAccessDenied
      allowOperatorAlways={false}
    >
      <RackElevations />
    </IsPermitted>
  );
}

import {
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import IsOperator from '@meterup/authorization/src/components/IsOperator';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { ACSDialog, ACSJobType } from '../../ACS/ACSDialog';
import { AccessPointsQuery } from '../utils';
import { CopyRadioSettingsMutation, RadiosCopyDescription } from './utils';

export default function RadioProfilesActions() {
  const network = useNetwork();
  const networkUUID = network.UUID;
  const companyName = useCurrentCompany();
  const queryClient = useQueryClient();

  const copyRadios = useGraphQLMutation(CopyRadioSettingsMutation);
  const copyLegacyDialogProps = useDialogState();
  const acsDialogProps = useDialogState();

  const featureFlags = useFeatureFlags();
  const [isACSNOSEnabled] = useNosFeaturesEnabled([NosFeature.WOS_ACS]);
  const isACSEnabled = isACSNOSEnabled && featureFlags['wos-acs'];
  const { hasPermission } = usePermissions();
  const canEditRadioProfiles = hasPermission(PermissionType.PermRadioProfileWrite);
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const handleCopyRadioSettings = useCallback(
    () =>
      copyRadios.mutate(
        { networkUUID },
        {
          onSuccess() {
            queryClient.invalidateQueries(
              makeQueryKey(AccessPointsQuery, { networkUUID, includeUptime }),
            );
            notify('Radio settings copied successfully', { variant: 'positive' });
          },
          onError(err) {
            const gqlErr = getGraphQLError(err);
            notify(
              `There was an error copying radio settings, please create them manually: ${gqlErr?.message}`,
              { variant: 'negative' },
            );
          },
          onSettled: copyLegacyDialogProps.state.close,
        },
      ),
    [copyRadios, networkUUID, includeUptime, queryClient, copyLegacyDialogProps.state.close],
  );

  return (
    <>
      {canEditRadioProfiles && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.RadioProfileCreatePage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add radio profile
        </Button>
      )}

      {/* Actions menu. This remains operator only */}
      <IsOperator>
        <DropdownMenu>
          <DropdownMenuButton
            variant="secondary"
            icon="overflow-horizontal"
            arrangement="hidden-label"
            internal
          >
            Actions
          </DropdownMenuButton>
          <DropdownMenuPopover align="end">
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={copyLegacyDialogProps.state.open} icon="copy" internal>
                Copy legacy radio settings
              </DropdownMenuItem>
            </DropdownMenuGroup>
            {isACSEnabled && (
              <DropdownMenuGroup>
                <DropdownMenuItem onSelect={acsDialogProps.state.open} icon="channel" internal>
                  Run ACS
                </DropdownMenuItem>
              </DropdownMenuGroup>
            )}
          </DropdownMenuPopover>
        </DropdownMenu>
      </IsOperator>
      {/* /Actions menu */}

      <ACSDialog
        state={acsDialogProps.state}
        identifier={network.UUID}
        jobType={ACSJobType.Network}
      />

      <Dialog state={copyLegacyDialogProps.state}>
        <DialogHeader icon="width" heading="Copy legacy radio settings" />
        <DialogContent gutter="all">
          <Body>
            <RadiosCopyDescription />
          </Body>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={copyLegacyDialogProps.state.close}>
                Cancel
              </Button>
              <Button onClick={handleCopyRadioSettings}>
                Copy radio settings and create radio profiles
              </Button>
            </>
          }
        />
      </Dialog>
    </>
  );
}

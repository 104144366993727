import {
  type SectionProps,
  Badge,
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  HStack,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  VStack,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import type { VLAN, VLANWithDHCPRule } from './utils';
import { paths } from '../../../constants';
import { ClientAssignmentProtocol } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { CopyableMonoOrNoValue, DetailsWidgetListPair } from '../../Devices/Insights';
import { NoValue } from '../../NoValue';
import { StickyObjectHeader } from '../../Object/ObjectHeader';
import { vlanHasDHCPRule, vlanQuery } from './utils';
import VLANActions from './VLANActions';

export function VLANDetailsSummary({
  vlan,
  relation = 'standalone',
  ...props
}: SectionProps & { vlan: VLAN }) {
  return (
    <Section relation={relation} {...props}>
      <SectionHeader heading="Metadata" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <DetailsWidgetListPair label="ID" value={vlan.vlanID} />
          <SummaryListRow>
            <SummaryListKey>Description</SummaryListKey>
            <SummaryListValue>{vlan.description || <NoValue />}</SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function VLANClientAssignmentSummary({
  vlan,
  relation = 'standalone',
  ...props
}: SectionProps & { vlan: VLAN }) {
  return (
    <Section relation={relation} {...props}>
      <SectionHeader heading="IP configuration" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          {vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Dhcp ? (
            <SummaryListRow>
              <SummaryListKey>Protocol</SummaryListKey>
              <SummaryListValue>{vlan.ipV4ClientAssignmentProtocol}</SummaryListValue>
            </SummaryListRow>
          ) : (
            <>
              {vlan.ipV4ClientGateway && vlan.ipV4ClientPrefixLength && (
                <DetailsWidgetListPair label="IP address" value={vlan.ipV4ClientGateway} />
              )}
              {vlan.ipV4ClientPrefixLength && (
                <DetailsWidgetListPair label="Prefix length" value={vlan.ipV4ClientPrefixLength} />
              )}
            </>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function VLANDHCPSummary({
  vlan,
  relation = 'standalone',
  view,
  ...props
}: SectionProps & { vlan: VLANWithDHCPRule; view: 'drawer' | 'detail' }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Section relation={relation} {...props}>
      <SectionHeader
        heading="DHCP"
        actions={
          view === 'drawer' && (
            <Button
              as={Link}
              to={makeLink(paths.pages.VLANDetailsPage, {
                companyName,
                networkSlug: network.slug,
                uuid: vlan.UUID,
                tab: 'dhcp',
              })}
              arrangement="leading-label"
              icon="chevron-right"
              variant="secondary"
            >
              View
            </Button>
          )
        }
      />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>IP range</SummaryListKey>
            <SummaryListValue>
              <VStack spacing={space(4)}>
                <HStack spacing={space(4)}>
                  <CopyableMonoOrNoValue
                    label="start IP address"
                    value={vlan.dhcpRule.startIPAddress}
                  />
                  <Text family="monospace"> - </Text>
                </HStack>
                <CopyableMonoOrNoValue label="end IP address" value={vlan.dhcpRule.endIPAddress} />
              </VStack>
            </SummaryListValue>
          </SummaryListRow>
          <DetailsWidgetListPair label="Gateway" value={vlan.dhcpRule.gatewayIPAddress} />
          <DetailsWidgetListPair label="Prefix length" value={vlan.dhcpRule.gatewayPrefixLength} />
          <DetailsWidgetListPair
            label="Lease duration"
            value={`${(vlan.dhcpRule.leaseDurationSeconds / 3600).toFixed(2).replace('.00', '').replace(/0$/, '')} hours`}
          />
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function VLANDNSSummary({
  vlan,
  relation = 'standalone',
  view,
  ...props
}: SectionProps & { vlan: VLANWithDHCPRule; view: 'drawer' | 'detail' }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Section relation={relation} {...props}>
      <SectionHeader
        heading="DNS"
        actions={
          view === 'drawer' && (
            <Button
              as={Link}
              to={makeLink(paths.pages.VLANDetailsPage, {
                companyName,
                networkSlug: network.slug,
                uuid: vlan.UUID,
                tab: 'dns',
              })}
              arrangement="leading-label"
              icon="chevron-right"
              variant="secondary"
            >
              View
            </Button>
          )
        }
      />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>Gateway proxy</SummaryListKey>
            <SummaryListValue>
              <Badge
                variant={vlan.dhcpRule.dnsUseGatewayProxy ? 'positive' : 'negative'}
                size="small"
              >
                {vlan.dhcpRule.dnsUseGatewayProxy ? 'Enabled' : 'Disabled'}
              </Badge>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>
              {vlan.dhcpRule.dnsUseGatewayProxy ? 'Upstream' : 'Custom'} DNS servers
            </SummaryListKey>
            <SummaryListValue>
              <VStack spacing={space(4)}>
                {vlan.dhcpRule.dnsUpstreamServers.map((server) => (
                  <CopyableMonoOrNoValue key={server} label="DNS server" value={server} />
                ))}
              </VStack>
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function VLANSummary({ vlan }: { vlan: VLAN }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <DrawerContent gutter="none" spacing={space(0)}>
      <StickyObjectHeader
        icon="vlan"
        name={vlan.name}
        status={vlan.isEnabled ? 'enabled' : 'disabled'}
        link={makeLink(paths.pages.VLANDetailsPage, {
          networkSlug: network.slug,
          companyName,
          uuid: vlan.UUID,
          tab: 'details',
        })}
        cta="View VLAN"
      />
      <Sections>
        <VLANDetailsSummary relation="stacked" vlan={vlan} />
        {vlan.ipV4ClientAssignmentProtocol && (
          <VLANClientAssignmentSummary relation="stacked" vlan={vlan} />
        )}
        {vlanHasDHCPRule(vlan) && (
          <>
            <VLANDHCPSummary relation="stacked" vlan={vlan} view="drawer" />
            <VLANDNSSummary relation="stacked" vlan={vlan} view="drawer" />
          </>
        )}
      </Sections>
    </DrawerContent>
  );
}

export default function VLANDrawer({ uuid }: { uuid?: string }) {
  const vlan = useGraphQL(vlanQuery, { uuid: uuid! }, { enabled: !!uuid }).data?.vlan;
  expectDefinedOrThrow(vlan, new ResourceNotFoundError('VLAN not found'));

  return (
    <Drawer>
      <DrawerHeader
        icon="vlan"
        heading="VLAN"
        onClose={useCloseDrawerCallback()}
        actions={<VLANActions vlan={vlan} view="drawer" />}
      />
      <VLANSummary vlan={vlan} />
    </Drawer>
  );
}

import type { FormikHelpers } from 'formik';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  HStack,
  PrimaryField,
  PrimaryToggleField,
  space,
  TextInput,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';

import type { AutoVpnGroupRouteFormValues } from '../../../components/autoVPN/utils';
import {
  AutoVPNGroupQuery,
  createAutoVPNRouteMutation,
  createAutoVpnRouteRouteValues,
} from '../../../components/autoVPN/utils';
import { FieldProvider, NumberFieldProvider } from '../../../components/Form/FieldProvider';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { paths } from '../../../constants';
import { type CreateAutoVpnRouteInput, PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { withZodSchema } from '../../../utils/withZodSchema';

export const Meta = () => ({
  path: '/org/:companyName/network/auto-vpn/groups/:groupUUID/routes',
  title: 'Routes - Group - Auto VPN - Network',
});

function RouteCreateFormButtons() {
  const { resetForm } = useFormikContext();
  return (
    <HStack spacing={space(8)}>
      <Button size="small" variant="secondary" onClick={() => resetForm()}>
        Cancel
      </Button>
      <Button size="small" type="submit" arrangement="leading-icon" icon="plus">
        Add route
      </Button>
    </HStack>
  );
}

function AutoVPNGroupRouteCreateForm({ groupUUID }: { groupUUID: string }) {
  const queryClient = useQueryClient();
  const createMutation = useGraphQLMutation(createAutoVPNRouteMutation);
  const handleSubmit = (
    vals: AutoVpnGroupRouteFormValues,
    { resetForm }: FormikHelpers<AutoVpnGroupRouteFormValues>,
  ) => {
    const { dstPrefixLength, ...restVals } = vals;
    const input: CreateAutoVpnRouteInput = {
      dstPrefixLength: Number(vals.dstPrefixLength),
      ...restVals,
    };
    createMutation.mutate(
      {
        groupUUID,
        input,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupQuery, { uuid: groupUUID }));
          resetForm();
          notify('Successfully created Auto VPN group route.', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          notify(
            `There was a problem creating Auto VPN group route${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  };
  return (
    <Formik<AutoVpnGroupRouteFormValues>
      initialValues={{
        dstGateway: '',
        dstPrefixLength: 16,
        isEnabled: true,
        name: '',
      }}
      onSubmit={handleSubmit}
      validate={withZodSchema(createAutoVpnRouteRouteValues)}
    >
      <Form>
        <DrawerContent gutter="all">
          <FieldProvider name="isEnabled">
            <PrimaryToggleField label="Enable" />
          </FieldProvider>
          <FieldProvider name="name">
            <PrimaryField label="Name" element={<TextInput />} />
          </FieldProvider>
          <FieldProvider name="dstGateway">
            <PrimaryField label="Destination gateway" element={<TextInput />} />
          </FieldProvider>
          <NumberFieldProvider name="dstPrefixLength" defaultValue={16}>
            <PrimaryField
              label="Destination prefix length"
              element={<TextInput inputProps={{ type: 'number' }} />}
            />
          </NumberFieldProvider>
        </DrawerContent>
        <DrawerFooter actions={<RouteCreateFormButtons />} />
      </Form>
    </Formik>
  );
}

export default function AutoVPNGroupRoutesAddPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AutoVPNGroupRoutesAddPage);
  const closeDrawer = useCloseDrawerCallback();
  const group = useGraphQL(
    AutoVPNGroupQuery,
    {
      uuid: drawerParams?.groupUUID!,
    },
    {
      enabled: !!drawerParams?.groupUUID,
    },
  )?.data?.autoVPNGroup;

  return (
    <IsPermitted
      should404OnAccessDenied
      allowOperatorAlways={false}
      path={Meta().path}
      isPermitted={({ isOperator, permissions, companyNosFlags, ldFlags }) =>
        Boolean(permissions.hasPermission(PermissionType.PermAutoVpnWrite)) &&
        companyNosFlags[NosFeature.SITE_TO_SITE_VPN] &&
        (isOperator || !!ldFlags['site-to-site-vpn']) &&
        !!drawerParams?.groupUUID &&
        !!group
      }
    >
      <Drawer>
        <DrawerHeader icon="plus" onClose={closeDrawer} heading="Add route" />
        {group && <AutoVPNGroupRouteCreateForm groupUUID={group.UUID!} />}
      </Drawer>
    </IsPermitted>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React, { Suspense } from 'react';

import { DrawerLoadingFallback } from '../../../../../components/Placeholders/DrawerLoadingFallback';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { parseEvent } from '../../../../../utils/events';
import EventSummaryDrawer from './EventSummaryDrawer';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/events/:eventSid',
});

function EventSummary() {
  const { eventSid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EventSummaryPage),
  );

  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const api = useAPIClient();

  const event = useQuery(
    ['controller', controllerName, 'logs', eventSid],
    () => api.controllerEvent(eventSid),
    {
      suspense: true,
    },
  ).data;

  const info = checkDefinedOrThrow(event, new ResourceNotFoundError(`Event ${eventSid} not found`));
  const parsedEvent = parseEvent(info);

  return <EventSummaryDrawer event={parsedEvent} />;
}

export default function EventSummaryPage() {
  return (
    <Suspense fallback={<DrawerLoadingFallback />}>
      <EventSummary />
    </Suspense>
  );
}

import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import IntegrationsLayout from '../../../../../components/Settings/Organization/Integrations/IntegrationsLayout';
import { IntegrationsValidTabs } from '../../../../../components/Settings/Organization/Integrations/IntegrationsTabs';
import Webhooks from '../../../../../components/Settings/Organization/Integrations/Webhooks/Webhooks';

export function Meta() {
  return {
    path: '/org/:companyName/settings/org/integrations/webhooks',
    title: 'Webhooks - Integrations - Org',
  };
}

export default function WebhooksPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  return <IntegrationsLayout activeTab={IntegrationsValidTabs.Webhooks} main={<Webhooks />} />;
}

import { Section, SectionContent, SectionHeader, SummaryList, Text, Tooltip } from '@meterup/atto';
import { formatBytes } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { DateTime } from 'luxon';

import { CopyableMonoOrNoValue } from '../../../../Devices/Insights';
import {
  DeviceCellularStatusQuery,
  DeviceLastDayCellularUsageQuery,
} from '../../../../Hardware/SecurityAppliance/utils';

export function InspectorCellular({ serialNumber }: { serialNumber: string }) {
  const { data: usageData, isError: usageIsError } = useGraphQL(
    DeviceLastDayCellularUsageQuery,
    {
      serialNumber,
    },
    { useErrorBoundary: false },
  );
  const { data: statusData, isError: statusIsError } = useGraphQL(
    DeviceCellularStatusQuery,
    {
      serialNumber,
    },
    { useErrorBoundary: false },
  );

  if (usageIsError && statusIsError) return null;

  return (
    <Section relation="stacked" size="x-small">
      <SectionHeader heading="Cellular" />
      <SectionContent gutter="all">
        <SummaryList
          gutter="none"
          size="x-small"
          pairs={[
            {
              label: 'Download bytes',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy download bytes"
                  value={
                    usageData?.deviceLastDayCellularUsage?.downloadBytes ||
                    usageData?.deviceLastDayCellularUsage?.downloadBytes === 0
                      ? formatBytes(usageData.deviceLastDayCellularUsage?.downloadBytes)
                      : null
                  }
                />
              ),
            },
            {
              label: 'Upload bytes',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy upload bytes"
                  value={
                    usageData?.deviceLastDayCellularUsage?.uploadBytes ||
                    usageData?.deviceLastDayCellularUsage?.uploadBytes === 0
                      ? formatBytes(usageData.deviceLastDayCellularUsage?.uploadBytes)
                      : null
                  }
                />
              ),
            },
            {
              label: 'Manager modem status',
              value:
                (statusData?.deviceCellularStatus?.managerModemState && (
                  <Tooltip
                    asChild
                    contents={`Observed at ${DateTime.fromISO(statusData.deviceCellularStatus.observedAt).toLocaleString(DateTime.DATETIME_MED)}`}
                  >
                    <Text transform="capitalize">
                      <CopyableMonoOrNoValue
                        label="Copy manager modem status"
                        value={statusData?.deviceCellularStatus?.managerModemState.toLowerCase()}
                      />
                    </Text>
                  </Tooltip>
                )) ||
                'Unknown',
            },
          ]}
        />
      </SectionContent>
    </Section>
  );
}

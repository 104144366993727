import type { SortingState } from '@tanstack/react-table';
import { Badge, Button, EmptyState, HStack, Icon, space } from '@meterup/atto';
import { AutoTable, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import React from 'react';
import { Link } from 'react-router-dom';

import type { RadioProfilesQueryResult } from './utils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { Box } from '../../Box';
import { NoValue } from '../../NoValue';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { Divider } from '../SSIDs/SSIDsUtils';
import RadioProfileListACSEnabled from './RadioProfileListACSEnabled';
import { RadioProfilesQuery } from './utils';

function ProfileBand({ enabled, bandText }: { enabled: boolean; bandText: string }) {
  return enabled ? (
    <Box css={{ hStack: '$4' }}>
      <Icon
        icon="checkmark"
        size={12}
        color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
      />
      {bandText}
    </Box>
  ) : (
    <Box css={{ opacity: '0.5', hStack: '$4' }}>
      <NoValue />
      {bandText}
    </Box>
  );
}

function ProfileBands({ profile }: { profile: RadioProfilesQueryResult }) {
  return (
    <HStack spacing={space(6)} align="center">
      <ProfileBand enabled={profile.band2_4GIsEnabled} bandText="2.4" />
      <Divider />
      <ProfileBand enabled={profile.band5GIsEnabled} bandText="5" />
    </HStack>
  );
}

const builder = createColumnBuilder<RadioProfilesQueryResult>();

const columns = [
  builder.data((profile) => profile.name, {
    id: 'profile-name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
    cell: ({ row: profile }) => {
      if (profile.isDefault) {
        return (
          <HStack align="center" spacing={space(6)}>
            {profile.name}
            <Badge size="small" variant="neutral">
              Default
            </Badge>
          </HStack>
        );
      }

      return <span>{profile.name}</span>;
    },
  }),
  builder.data(
    (profile) => {
      if (profile.band2_4GIsEnabled && profile.band5GIsEnabled) {
        return '2.4,5';
      }
      if (profile.band2_4GIsEnabled) {
        return '2.4';
      }
      if (profile.band5GIsEnabled) {
        return '5';
      }
      return '';
    },
    {
      id: 'profile-bands',
      header: 'Bands (GHz)',
      cell: ({ row: profile }) => <ProfileBands profile={profile} />,
    },
  ),
  builder.data((profile) => profile.virtualDevices.length?.toString() ?? '', {
    id: 'profile-devices',
    header: 'Devices',
    meta: {
      alignment: 'end',
    },
  }),
];

export default function RadioProfilesList() {
  const closeDrawer = useCloseDrawerCallback();
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const profiles = useGraphQL(RadioProfilesQuery, { networkUUID: network.UUID }).data
    ?.radioProfilesForNetwork;
  expectDefinedOrThrow(profiles, new ResourceNotFoundError('Unable to load radio profiles'));

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.RadioProfileEditPage);
  const { hasPermission } = usePermissions();
  const canEditRadioProfiles = hasPermission(PermissionType.PermRadioProfileWrite);

  const acsEnabled = useNosFeatureEnabled(NosFeature.WOS_ACS);
  if (acsEnabled) {
    // WOS_ACS is the first NosFeature that is available. If that is available, we'll load the new
    // page which will load what is available between the WOS features (acs, tx power, channel
    // width, etc).
    return <RadioProfileListACSEnabled profiles={profiles} />;
  }

  if (profiles.length === 0) {
    return (
      <EmptyState
        icon="width"
        heading="No radio profiles"
        action={
          canEditRadioProfiles ? (
            <Button
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.RadioProfileCreatePage, {
                companyName,
                networkSlug: network.slug,
              })}
              arrangement="leading-icon"
              icon="plus"
            >
              Add radio profile
            </Button>
          ) : undefined
        }
      />
    );
  }

  return (
    <AutoTable
      key="profiles-list"
      columns={columns}
      data={profiles}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      getLinkTo={(row) =>
        canEditRadioProfiles
          ? makeDrawerLink(window.location, paths.drawers.RadioProfileEditPage, {
              uuid: row.UUID,
              companyName,
              networkSlug: network.slug,
            })
          : undefined
      }
      isRowSelected={(row) => row.UUID === drawerParams?.uuid}
      onRowDeselect={closeDrawer}
    />
  );
}

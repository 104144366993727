import type { z } from 'zod';
import {
  Alert,
  Button,
  colors,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  darkThemeSelector,
  DialogContent,
  DialogFooter,
  FieldContainer,
  HStack,
  Icon,
  Label,
  PrimaryField,
  SecondaryField,
  Segment,
  Segments,
  Select,
  SelectItem,
  space,
  styled,
  Textarea,
  TextInput,
  ToggleInput,
  Tooltip,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';

import type {
  AvailableDevicesInfo,
  FieldType,
  RackElevation,
  ValidAttachmentParams,
} from './utils';
import {
  DeviceModel,
  DeviceType,
  RackElevationDeviceType,
  VirtualDeviceType,
} from '../../../gql/graphql';
import { pluralize, screamingSnakeCaseToWords } from '../../../utils/strings';
import { withZodSchema } from '../../../utils/withZodSchema';
import { HardwareDeviceField } from '../../Devices/Fields';
import { createVirtualDevice } from '../../Devices/utils';
import {
  createVirtualDeviceInputSchema,
  CreateVirtualDeviceModelField,
} from '../../Devices/VirtualDeviceCreateDrawer';
import { FieldProvider } from '../../Form/FieldProvider';
import {
  calculateAvailableStartIndexes,
  devicesForNetwork,
  getInitialStartIndex,
  rackElevationQuery,
} from './utils';

export function LabelField({ label = 'Label' }) {
  return (
    <FieldContainer>
      <FieldProvider name="label">
        <PrimaryField label={label} element={<TextInput />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function RackMountUnitCountField() {
  return (
    <FieldContainer>
      <FieldProvider name="rackMountUnitCount">
        <PrimaryField
          label={
            <Tooltip contents="A rack unit (U) is a standard way to measure and fit equipment like servers and switches into a network rack.">
              <HStack align="center" spacing={space(4)}>
                Units
                <Icon icon="question" size={space(14)} />
              </HStack>
            </Tooltip>
          }
          description="The number of total units in this rack."
          element={<TextInput type="number" />}
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export function CreateVirtualDeviceForm({
  networkUUID,
  close,
}: {
  networkUUID: string;
  close: () => void;
}) {
  const [errorText, setErrorText] = useState<string | null>(null);
  const outerFormikContext = useFormikContext<ValidAttachmentParams>();
  const queryClient = useQueryClient();
  const createVirtualDeviceMutation = useGraphQLMutation(createVirtualDevice);

  return (
    <Formik<z.infer<typeof createVirtualDeviceInputSchema>>
      initialValues={{
        label: '',
        description: '',
        deviceType: VirtualDeviceType.Switch,
        deviceModel: DeviceModel.Ms10,
      }}
      validate={withZodSchema(createVirtualDeviceInputSchema)}
      onSubmit={(values) => {
        if (createVirtualDeviceMutation.isLoading) return;

        setErrorText(null);

        const variables = { networkUUID, input: values };
        createVirtualDeviceMutation.mutate(variables, {
          onError: (error) => {
            const gqlError = getGraphQLError(error);
            setErrorText(gqlError?.message ?? 'Unknown error');
          },
          onSuccess: (response) => {
            queryClient.invalidateQueries(makeQueryKey(devicesForNetwork, { networkUUID }));
            outerFormikContext.setFieldValue(
              'virtualDeviceUUID',
              response.createVirtualDevice.UUID,
            );
            close();
            notify(`Successfully created virtual device "${values.label}".`, {
              variant: 'positive',
            });
          },
        });
      }}
    >
      <Form>
        <DialogContent>
          <FieldContainer>
            <FieldProvider name="label">
              <PrimaryField label="Label" element={<TextInput />} />
            </FieldProvider>
          </FieldContainer>
          <FieldContainer>
            <FieldProvider name="description">
              <PrimaryField optional label="Description" element={<Textarea />} />
            </FieldProvider>
          </FieldContainer>
          <FieldContainer>
            <FieldProvider name="deviceType">
              <PrimaryField
                label="Device"
                element={
                  <Select width="100%" placeholder="Select device type">
                    {Array.from(Object.values(DeviceType)).map((deviceType) => (
                      <SelectItem key={deviceType}>
                        {screamingSnakeCaseToWords(deviceType)}
                      </SelectItem>
                    ))}
                  </Select>
                }
              />
            </FieldProvider>
            <CreateVirtualDeviceModelField />
          </FieldContainer>
          {errorText && (
            <Alert heading="Error creating virtual device" copy={errorText} variant="negative" />
          )}
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit">Add</Button>
            </>
          }
        />
      </Form>
    </Formik>
  );
}

export function VirtualDeviceField({
  disabled = false,
  devices,
}: {
  disabled?: boolean;
  devices: AvailableDevicesInfo;
}) {
  const { values, setFieldValue, touched } = useFormikContext<ValidAttachmentParams>();
  const [showAPs, setShowAPs] = useState(false);

  const deviceEntries = useMemo(() => {
    let entries = Object.entries(devices.typeDevices);
    if (!showAPs) {
      entries = entries.filter(([deviceType]) => deviceType !== DeviceType.AccessPoint);
    }

    return entries;
  }, [devices.typeDevices, showAPs]);

  useEffect(() => {
    if (!disabled && values.virtualDeviceUUID && !touched.label) {
      const selectedDevice = devices.devicesByUUID[values.virtualDeviceUUID];
      if (selectedDevice) {
        setFieldValue('label', selectedDevice.label);
      }
    }
  }, [
    disabled,
    values.virtualDeviceUUID,
    setFieldValue,
    devices.devicesByUUID,
    touched.virtualDeviceUUID,
    touched.label,
  ]);

  const hasDevices = deviceEntries.length > 0;

  return (
    <FieldContainer>
      <FieldProvider name="virtualDeviceUUID">
        {devices && (
          <PrimaryField
            label="Meter equipment"
            element={
              <ComboBox
                disabled={disabled || !hasDevices}
                placeholder={hasDevices ? 'Select a device' : 'No network devices found'}
              >
                {deviceEntries.map(([deviceType, typeDevices]) => (
                  <ComboBoxSection
                    key={deviceType}
                    title={pluralize(
                      2,
                      screamingSnakeCaseToWords(deviceType),
                      deviceType === DeviceType.Switch
                        ? 'Switches'
                        : `${screamingSnakeCaseToWords(deviceType)}s`,
                    )}
                    items={typeDevices}
                  >
                    {(d) => (
                      <ComboBoxItem key={d.UUID} textValue={d.UUID}>
                        {d.label ? (
                          <>
                            {d.label} ({d.hardwareDevice?.serialNumber ?? d.deviceModel})
                          </>
                        ) : (
                          d.hardwareDevice?.serialNumber ?? d.deviceModel
                        )}
                      </ComboBoxItem>
                    )}
                  </ComboBoxSection>
                ))}
              </ComboBox>
            }
            controls={
              <Label disabled={disabled || !hasDevices}>
                Show APs
                <ToggleInput
                  selected={showAPs}
                  controlSize="small"
                  disabled={disabled || !hasDevices}
                  onChange={(selected) => {
                    setShowAPs(selected);
                  }}
                />
              </Label>
            }
          />
        )}
      </FieldProvider>
    </FieldContainer>
  );
}

export function TypeField() {
  return (
    <FieldContainer>
      <FieldProvider name="type">
        <PrimaryField
          label="Type"
          element={
            <Select width="100%" placeholder="Select a type">
              <SelectItem key={RackElevationDeviceType.CableManagement}>
                Cable management
              </SelectItem>
              <SelectItem key={RackElevationDeviceType.Fiber}>Fiber</SelectItem>
              <SelectItem key={RackElevationDeviceType.Isp}>ISP</SelectItem>
              <SelectItem key={RackElevationDeviceType.NonMeterSwitch}>Non-Meter switch</SelectItem>
              <SelectItem key={RackElevationDeviceType.PatchPanel}>Patch panel</SelectItem>
              <SelectItem key={RackElevationDeviceType.UniversalPowerSupply}>
                Universal Power Supply (UPS)
              </SelectItem>
              <SelectItem key={RackElevationDeviceType.Other}>Other</SelectItem>
            </Select>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export type SlotsFieldsProps = {
  endIndex?: number;
  availableIndexes: number[];
  deviceIndexes?: number[];
  disabled?: boolean;
};

function sortDescending(a: number, b: number) {
  return b - a;
}

const RackMountsRowsIcon = styled(Icon, {
  width: '$14',
  height: '$14',
  marginBottom: '$16',
  color: colors.gray400,

  [darkThemeSelector]: {
    color: colors.gray500,
  },
});

const RackMountsRows = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr min-content 1fr',
  alignItems: 'flex-end',
  gap: 0,
});

export function SlotsFields({
  availableIndexes,
  deviceIndexes,
  disabled,
  endIndex,
}: SlotsFieldsProps) {
  const { values, setFieldValue } = useFormikContext<{
    startIndex: number;
    endIndex: number;
    fieldType: FieldType;
  }>();

  const referenceIndexes = deviceIndexes ?? availableIndexes;

  useEffect(() => {
    setFieldValue(
      'endIndex',
      endIndex != null && referenceIndexes.includes(endIndex)
        ? endIndex
        : referenceIndexes[referenceIndexes.length - 1],
    );
  }, [referenceIndexes, setFieldValue, endIndex]);

  useEffect(() => {
    setFieldValue('startIndex', getInitialStartIndex(values.endIndex, deviceIndexes));
  }, [values.endIndex, deviceIndexes, setFieldValue]);

  const sortedAvailableEndIndexes = useMemo(() => {
    const indexes = availableIndexes.slice();
    indexes.sort(sortDescending);
    return indexes;
  }, [availableIndexes]);

  const sortedAvailableStartIndexes = useMemo(() => {
    const startIndexes = calculateAvailableStartIndexes({
      endIndex: values.endIndex,
      availableIndexes,
      fieldType: values.fieldType,
    });
    startIndexes.sort(sortDescending);
    return startIndexes;
  }, [values.endIndex, values.fieldType, availableIndexes]);

  if (values.fieldType === 'meter_device') {
    return (
      <PrimaryField
        label={
          <Tooltip contents="A rack unit (U) is a standard way to measure and fit equipment like servers and switches into a network rack.">
            <HStack align="center" spacing={space(4)}>
              Unit
              <Icon icon="question" size={space(14)} />
            </HStack>
          </Tooltip>
        }
        description="Where this device will be located in your rack."
        element={
          <Select
            width="100%"
            value={values.endIndex?.toString()}
            onValueChange={(val) => setFieldValue('endIndex', Number.parseInt(val, 10))}
            placeholder="Upper rack unit"
            disabled={disabled}
          >
            {sortedAvailableEndIndexes.map((i) => (
              <SelectItem key={i.toString()}>{i.toString()}</SelectItem>
            ))}
          </Select>
        }
      />
    );
  }

  return (
    <FieldContainer>
      <PrimaryField
        label={
          <Tooltip contents="A rack unit (U) is a standard way to measure and fit equipment like servers and switches into a network rack.">
            <HStack align="center" spacing={space(4)}>
              Units
              <Icon icon="question" size={space(14)} />
            </HStack>
          </Tooltip>
        }
        description="The first and last unit where this device will be located in your rack."
        element={null}
      />
      <RackMountsRows>
        <SecondaryField
          label="First row"
          element={
            <Select
              width="100%"
              value={values.endIndex?.toString()}
              onValueChange={(val) => setFieldValue('endIndex', Number.parseInt(val, 10))}
              placeholder="Upper rack unit"
              disabled={disabled}
            >
              {sortedAvailableEndIndexes.map((i) => (
                <SelectItem key={i.toString()}>{i.toString()}</SelectItem>
              ))}
            </Select>
          }
        />
        <RackMountsRowsIcon icon="arrow-right" />
        <SecondaryField
          label="Last row"
          element={
            <Select
              width="100%"
              value={values.startIndex?.toString()}
              onValueChange={(val) => setFieldValue('startIndex', Number.parseInt(val, 10))}
              placeholder="Lower rack unit"
              disabled={disabled}
            >
              {sortedAvailableStartIndexes.map((i) => (
                <SelectItem key={i.toString()}>{i.toString()}</SelectItem>
              ))}
            </Select>
          }
        />
      </RackMountsRows>
    </FieldContainer>
  );
}

export type FieldTypeToggleProps = {
  disabled?: boolean;
};

export function NoteField({ label = 'Note' }) {
  return (
    <FieldContainer>
      <FieldProvider name="note">
        <PrimaryField label={label} element={<Textarea rows={8} />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function FieldTypeToggleField({ disabled = false }: FieldTypeToggleProps) {
  const { values, setFieldValue } = useFormikContext<{ fieldType: FieldType }>();

  const handleClick = (val: FieldType) => {
    if (disabled) return;

    setFieldValue('fieldType', val);
  };

  return (
    <Segments aria-disabled={disabled}>
      <Segment
        active={values.fieldType === 'meter_device'}
        onClick={() => handleClick('meter_device')}
        disabled={disabled}
      >
        Meter device
      </Segment>
      <Segment
        active={values.fieldType === 'external_device'}
        onClick={() => handleClick('external_device')}
        disabled={disabled}
      >
        Third party
      </Segment>
    </Segments>
  );
}

export function FieldTypeFields({
  elevation,
  action,
  devices,
}: {
  elevation: RackElevation;
  action: 'create' | 'update';
  devices: AvailableDevicesInfo;
}) {
  const queryClient = useQueryClient();
  const { values } = useFormikContext<{ fieldType: FieldType; virtualDeviceUUID: string }>();
  const currentVirtualDevice = devices.devicesByUUID[values.virtualDeviceUUID];
  if (values.fieldType === 'meter_device') {
    return (
      <>
        <VirtualDeviceField devices={devices} disabled={action === 'update'} />
        {currentVirtualDevice && (
          <HardwareDeviceField
            virtualDevice={currentVirtualDevice}
            onSuccess={() => {
              queryClient.invalidateQueries(
                makeQueryKey(rackElevationQuery, { UUID: elevation.UUID }),
              );
              return queryClient.invalidateQueries(
                makeQueryKey(devicesForNetwork, { networkUUID: elevation.networkUUID }),
              );
            }}
          />
        )}
      </>
    );
  }
  return <TypeField />;
}

import { Alert, Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { ValidUpdateVirtualDeviceParams } from '../../Wireless/utils';
import { type MutationUpdateVirtualDevicesArgs, PermissionType } from '../../../gql/graphql';
import { UpdateVirtualDeviceInputSchema } from '../../../gql/zod-types';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { styled } from '../../../stitches';
import { pluralize } from '../../../utils/strings';
import { BandFields, RadioProfileUUIDField } from '../../Wireless/Fields';
import { RadioProfilesQuery } from '../../Wireless/RadioProfiles/utils';
import { AccessPointsQuery, UpdateVirtualDevicesMutation } from '../../Wireless/utils';

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

type AccessPointsBatchEditDrawerProps = {
  uuids: string[];
};

const updateVirtualDeviceInputSchema = UpdateVirtualDeviceInputSchema.extend({
  radioProfileUUID: z
    .string({ required_error: 'Please select a radio profile.' })
    .nonempty({ message: 'Please select a radio profile.' }),
});

function AccessPointsBatchEditBandsField() {
  const network = useNetwork();
  const { values } = useFormikContext<ValidUpdateVirtualDeviceParams>();
  const radioProfiles = useGraphQL(RadioProfilesQuery, { networkUUID: network.UUID }).data
    ?.radioProfilesForNetwork;
  const radioProfile = radioProfiles?.find((rp) => rp.UUID === values.radioProfileUUID);

  return <BandFields radioProfile={radioProfile} supportsIndeterminate />;
}

export default function AccessPointsBatchEditDrawer({ uuids }: AccessPointsBatchEditDrawerProps) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const mutation = useGraphQLMutation(UpdateVirtualDevicesMutation);
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const allAccessPoints = useGraphQL(AccessPointsQuery, {
    networkUUID: network.UUID,
    includeUptime,
  }).data?.virtualDevicesForNetwork;
  expectDefinedOrThrow(allAccessPoints, new ResourceNotFoundError('Unable to load access points'));

  const accessPoints = (allAccessPoints ?? []).filter((a) => uuids.includes(a.UUID));
  const profileUUIDs = new Set();

  accessPoints.forEach((a) => {
    if (a.__typename === 'AccessPointVirtualDevice' && a.radioProfile?.UUID) {
      profileUUIDs.add(a.radioProfile.UUID);
    }
  });

  const alertCopy =
    profileUUIDs.size === 1
      ? 'Changing a field below will overwrite all existing values for that field on the selected access points.'
      : 'The selected access points do not currently have the same radio profile. Selecting a new one will overwrite the radio profile for all of them.';

  const handleSubmit = ({ radioSettings, radioProfileUUID }: ValidUpdateVirtualDeviceParams) => {
    const params: MutationUpdateVirtualDevicesArgs = {
      virtualDeviceUUIDs: uuids,
      networkUUID: network.UUID,
      input: {},
    };

    if (radioProfileUUID) {
      params.input.radioProfileUUID = radioProfileUUID;
      params.input.radioSettings = {};

      if (radioSettings?.band2_4GPrimaryChannel)
        params.input.radioSettings.band2_4GPrimaryChannel = radioSettings.band2_4GPrimaryChannel;
      if (radioSettings?.band2_4GTransmitPowerdBm)
        params.input.radioSettings.band2_4GTransmitPowerdBm =
          radioSettings.band2_4GTransmitPowerdBm;

      if (radioSettings?.band5GPrimaryChannel)
        params.input.radioSettings.band5GPrimaryChannel = radioSettings.band5GPrimaryChannel;
      if (radioSettings?.band5GTransmitPowerdBm)
        params.input.radioSettings.band5GTransmitPowerdBm = radioSettings.band5GTransmitPowerdBm;
    }

    mutation.mutate(params, {
      onSuccess: () => {
        notify(
          `Successfully updated ${uuids.length} ${pluralize(uuids.length, 'access point', 'access points')}`,
          { variant: 'positive' },
        );
        queryClient.invalidateQueries(
          makeQueryKey(AccessPointsQuery, { networkUUID: network.UUID, includeUptime }),
        );
      },
      onError: (err) => {
        const gqlErr = getGraphQLError(err);
        notify(
          `There was an error updating ${pluralize(uuids.length, 'this access point', 'these access points')}: ${
            gqlErr?.message ?? 'Unknown error'
          }`,
          {
            variant: 'negative',
          },
        );
      },
    });
  };

  return (
    <Formik<ValidUpdateVirtualDeviceParams>
      validationSchema={toFormikValidationSchema(updateVirtualDeviceInputSchema)}
      initialValues={{
        radioProfileUUID: null,
        radioSettings: {
          band2_4GPrimaryChannel: null,
          band2_4GTransmitPowerdBm: null,
          band5GPrimaryChannel: null,
          band5GTransmitPowerdBm: null,
        },
      }}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader
            icon="access-point"
            heading={`Edit ${uuids.length} ${pluralize(uuids.length, 'access point', 'access points')}`}
            onClose={closeDrawer}
          />
          <DrawerContent>
            <Alert variant="alternative" icon="attention" copy={alertCopy} />
            <RadioProfileUUIDField networkUUID={network.UUID} supportsIndeterminate />
            <AccessPointsBatchEditBandsField />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </Drawer>
      </StyledForm>
    </Formik>
  );
}

import ISPEventDrawer from '../../../../../components/NetworkWide/ISPs/ISPEventDrawer';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/events/:eventUUID',
  title: 'ISP event - ISPs - Network-wide',
});

export default function ISPEventDrawerPage() {
  return <ISPEventDrawer />;
}

import { Drawer, DrawerContent, DrawerHeader, Skeleton, space, VStack } from '@meterup/atto';
import React from 'react';

export function DrawerContentLoadingFallback() {
  return (
    <DrawerContent>
      <VStack spacing={space(16)}>
        <Skeleton height={120} width="100%" radius={6} />
        <Skeleton height={120} width="100%" radius={6} />
        <Skeleton height={120} width="100%" radius={6} />
      </VStack>
    </DrawerContent>
  );
}

export function DrawerLoadingFallback() {
  return (
    <Drawer>
      <DrawerHeader heading={<Skeleton height={20} width={200} radius={6} />} />
      <DrawerContentLoadingFallback />
    </Drawer>
  );
}

import { Drawer } from '@meterup/atto';
import AddLocationsDrawer from 'connect-frontend/src/dashboard/pages/Locations/AddLocationsDrawer';
import React from 'react';

import { CloseDrawerButton } from '../../../components/CloseDrawerButton';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/connect/locations/add',
  title: 'Add locations - Locations - Connect',
});

export default function BulkAddLocationsPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermCompanyUpdate))
      }
      should404OnAccessDenied
    >
      <Drawer>
        <AddLocationsDrawer closeButton={<CloseDrawerButton />} />
      </Drawer>
    </IsPermitted>
  );
}

import type { FormikHelpers } from 'formik';
import {
  Body,
  Button,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  Section,
  SectionContent,
  SectionHeader,
  space,
  VStack,
} from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';
import { getErrorMessage, notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import type { FormSchemaType, UpdateRoleAssignmentsSchemaType } from './utils/schema';
import { paths } from '../../constants';
import { RoleName } from '../../gql/graphql';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../utils/withZodSchema';
import RoleAssignmentsFields from './RoleAssignmentsFields';
import {
  assignOperatorRole,
  removeOperatorRole,
  updateUserRoles,
  useInvalidateCompanyUserAndUsers,
} from './utils/queries';
import { RolesFormSchema } from './utils/schema';
import useUserRolesGroupedByName, { useUserHasRole } from './utils/useUserRolesGroupedByName';

function FormButtonsFooter() {
  const form = useFormikContext<FormSchemaType>();
  const onClickCancel = useCallback(() => {
    form.resetForm({ values: form.initialValues });
  }, [form]);
  return (
    <DrawerFooter
      actions={
        <>
          <Button
            type="button"
            variant="secondary"
            onClick={onClickCancel}
            disabled={form.isSubmitting}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" loading={form.isSubmitting}>
            Save
          </Button>
        </>
      }
    />
  );
}

type OperatorRolesSectionProps = {
  companySlug: string;
  uuid: string;
};

function OperatorRolesSection({ companySlug, uuid }: OperatorRolesSectionProps) {
  const assignOperatorMutation = useGraphQLMutation(assignOperatorRole);
  const removeOperatorMutation = useGraphQLMutation(removeOperatorRole);
  const invalidateCompanyUser = useInvalidateCompanyUserAndUsers(companySlug);
  const userHasOperator = useUserHasRole({ companySlug, roleName: RoleName.Operator, uuid });
  const onClickRemoveOperator = useCallback(() => {
    if (!userHasOperator) {
      return;
    }
    removeOperatorMutation.mutate(
      {
        uuid,
      },
      {
        onSuccess() {
          notify('Successfully removed Operator role', {
            icon: 'checkmark',
            variant: 'positive',
          });
          invalidateCompanyUser(uuid);
        },
        onError(err) {
          // eslint-disable-next-line no-console
          console.error('Failed to remove operator role', err);
          notify('Failed to remove Operator role', {
            icon: 'cross',
            variant: 'negative',
          });
        },
      },
    );
  }, [invalidateCompanyUser, removeOperatorMutation, uuid, userHasOperator]);
  const onClickAssignOperator = useCallback(() => {
    if (userHasOperator) {
      return;
    }
    assignOperatorMutation.mutate(
      {
        uuid,
      },
      {
        onSuccess() {
          notify('Successfully assigned Operator role', {
            icon: 'checkmark',
            variant: 'positive',
          });
          invalidateCompanyUser(uuid);
        },
        onError(err) {
          // eslint-disable-next-line no-console
          console.error('Failed to assign operator role', err);
          notify('Failed to assign Operator role', {
            icon: 'cross',
            variant: 'negative',
          });
        },
      },
    );
  }, [assignOperatorMutation, invalidateCompanyUser, uuid, userHasOperator]);

  return (
    <IsOperator>
      <Section internal>
        <SectionHeader heading="Operator role" />
        <SectionContent gutter="all">
          <VStack spacing={space(8)}>
            <Body>
              {userHasOperator
                ? 'This user has operator role'
                : 'This user does not have operator role'}
            </Body>
            {userHasOperator ? (
              <Button
                type="button"
                variant="secondary"
                size="large"
                width="100%"
                onClick={onClickRemoveOperator}
              >
                Remove operator role
              </Button>
            ) : (
              <Button
                type="button"
                variant="secondary"
                size="large"
                width="100%"
                onClick={onClickAssignOperator}
              >
                Assign operator role
              </Button>
            )}
          </VStack>
        </SectionContent>
      </Section>
    </IsOperator>
  );
}

export default function EditUserRoles({ uuid }: { uuid: string }) {
  const companySlug = useCurrentCompany();
  const roleToAssignments = useUserRolesGroupedByName({ companySlug, uuid });
  const updateUserRolesMutation = useGraphQLMutation(updateUserRoles);
  const invalidateCompanyUser = useInvalidateCompanyUserAndUsers(companySlug);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (values: FormSchemaType, form: FormikHelpers<FormSchemaType>) => {
      form.setSubmitting(true);
      const input = values.roleAssignments.flatMap((role) => {
        if (role.name === RoleName.Operator) {
          return [];
        }
        let networkUUID: string | undefined | null;
        if (role.name !== RoleName.CompanyGlobalAdmin) {
          networkUUID = role.networkUUID;
        }
        return [
          {
            networkUUID,
            roleName: role.name,
          },
        ];
      });
      updateUserRolesMutation.mutate(
        { uuid, input },
        {
          onSuccess() {
            notify('Successfully updated user roles', {
              icon: 'checkmark',
              variant: 'positive',
            });
            invalidateCompanyUser(uuid);
            navigate(
              makeDrawerLink(window.location, paths.drawers.UserDrawerPage, {
                companyName: companySlug,
                uuid,
              }),
            );
          },
          onError(err) {
            // eslint-disable-next-line no-console
            console.error('Failed to update user roles', err);
            const errorMessage = getErrorMessage(err) ?? 'Failed to update user roles';
            notify(errorMessage, {
              icon: 'cross',
              variant: 'negative',
            });
          },
          onSettled() {
            form.setSubmitting(false);
          },
        },
      );
    },
    [updateUserRolesMutation, uuid, invalidateCompanyUser, navigate, companySlug],
  );
  const userHasOperator = useUserHasRole({ companySlug, roleName: RoleName.Operator, uuid });
  const initialValues = useMemo(
    () => ({
      roleAssignments: Array.from(roleToAssignments).flatMap(([roleName, roles]) =>
        roles.map<UpdateRoleAssignmentsSchemaType>((role) => {
          if (roleName === RoleName.Operator) {
            return { name: RoleName.Operator, isNew: false };
          }
          if (roleName === RoleName.CompanyGlobalAdmin) {
            return {
              name: RoleName.CompanyGlobalAdmin,
              companySlug: role.companySlug!,
              isNew: false,
            };
          }
          return {
            name: roleName,
            companySlug: role.companySlug!,
            networkUUID: role.networkUUID!,
            isNew: false,
          };
        }),
      ),
    }),
    [roleToAssignments],
  );
  return (
    <Formik<FormSchemaType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validate={withZodSchema(RolesFormSchema)}
    >
      <Form>
        <DrawerContent>
          <OperatorRolesSection companySlug={companySlug} uuid={uuid} />
          <FieldContainer>
            <RoleAssignmentsFields disabled={userHasOperator} />
          </FieldContainer>
        </DrawerContent>
        <FormButtonsFooter />
      </Form>
    </Formik>
  );
}

import { Button, EmptyState, Pane, PaneContent, PaneHeader } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useIdentity } from '../../../providers/IdentityDataProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useDesignCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { zendeskFeedbackURL } from '../../../utils/zendesk';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import RackElevationsActions from './RackElevationsActions';
import RackElevationsView from './RackElevationsView';
import { rackElevationsQuery } from './utils';

export default function RackElevations() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const designCrumb = useDesignCrumbs();
  const identity = useIdentity();
  // const isOperator = useIsOperator();

  const { data } = useGraphQL(rackElevationsQuery, {
    networkUUID: network.UUID,
  });
  const result = data?.rackElevations ?? [];

  if (result.length === 0) {
    return (
      <Pane layoutMode="detailed">
        <PaneHeader
          back={back}
          home={home}
          crumbs={[
            ...designCrumb,
            {
              type: 'page',
              page: {
                as: ReactRouterLink,
                to: makeLink(paths.pages.RackElevationsPage, {
                  companyName,
                  networkSlug: network.slug,
                }),
                selected: true,
                label: 'Rack elevations',
              },
            },
          ]}
          icon="rack"
          heading="Rack elevations"
          actions={<RackElevationsActions />}
        />
        <PaneContent>
          <EmptyState
            icon="rack"
            heading="No rack elevations"
            action={
              <IsPermitted
                isPermitted={({ permissions, ldFlags }) =>
                  permissions.hasPermission(PermissionType.PermRackElevationWrite) &&
                  !!ldFlags['rack-elevation']
                }
                fallback={
                  <Button
                    as="a"
                    href={zendeskFeedbackURL(identity.username, 'Model my rack elevations')}
                    target="_blank"
                    variant="secondary"
                    arrangement="leading-icon"
                    icon="question"
                    size="medium"
                  >
                    Contact support
                  </Button>
                }
              >
                <Button
                  as={ReactRouterLink}
                  to={makeDrawerLink(window.location, paths.drawers.AddRackDrawerPage, {
                    companyName,
                    networkSlug: network.slug,
                  })}
                  arrangement="leading-icon"
                  icon="plus"
                  variant="secondary"
                >
                  Add rack
                </Button>
              </IsPermitted>
            }
          />
        </PaneContent>
      </Pane>
    );
  }

  return <RackElevationsView elevations={result} />;
}

import { checkDefinedOrThrow } from '@meterup/common';

import PortEditDrawer from '../../../../../components/Hardware/SecurityAppliance/Ports/PortEditDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/ports/:phyInterfaceUUID/edit',
  title: 'Edit port - Security appliance - Security appliances - Hardware',
});

export default function SecurityAppliancePortEditPage() {
  const { virtualDeviceUUID, phyInterfaceUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.SecurityAppliancePortEditPage),
  );

  return (
    <IsPermitted permissions={PermissionType.PermPhyInterfaceWrite} should404OnAccessDenied>
      <PortEditDrawer virtualDeviceUUID={virtualDeviceUUID} phyInterfaceUUID={phyInterfaceUUID} />
    </IsPermitted>
  );
}

import { Box, CopyBox, Section, SectionContent, SectionHeader, space } from '@meterup/atto';

import type { Stat } from '../../../../../gql/graphql';

type StatDetailsProps = {
  data: Stat;
};

export default function StatDetails({ data: stat }: StatDetailsProps) {
  return (
    <Box
      bg={{ light: 'bgNeutralLight', dark: 'bgNeutralDark' }}
      width="full"
      padding={{ all: space(16) }}
    >
      <Section>
        <SectionHeader
          icon="wrench"
          heading={
            <CopyBox
              aria-label="Copy stat data to clipboard"
              relation="standalone"
              size="small"
              value={JSON.stringify(stat, undefined, 2)}
            >
              Stat JSON data
            </CopyBox>
          }
        />
        <SectionContent
          css={{
            overflowX: 'auto',
            overflowY: 'auto',
            padding: space(16),
            width: '100%',
            maxWidth: space(700),
            maxHeight: space(420),
          }}
        >
          <pre>{JSON.stringify(stat, null, 2)}</pre>
        </SectionContent>
      </Section>
    </Box>
  );
}

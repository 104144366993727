import { darkThemeSelector, shadows, sizing, styled } from '@meterup/atto';
import { ColorScheme, Map as MapKitMap, Marker } from 'mapkit-react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import type { UptimeByNetwork } from '../useUptimeStats';
import type { NetworksOverviewData } from '../utils/aggregateStatsForNetworks';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { HubCard } from '../Units/HubCard';

function computeBoundingRegion(networks: NetworksOverviewData['networks']) {
  const coordinates = networks.map((network) => network.mailingAddressCoordinates);
  const latitudes = coordinates.map((c) => c.latitude);
  const longitudes = coordinates.map((c) => c.longitude);
  const padding = 2;
  const southLat = Math.min(...latitudes) - padding;
  const northLat = Math.max(...latitudes) + padding;
  const westLng = Math.min(...longitudes) - padding;
  const eastLng = Math.max(...longitudes) + padding;

  const br = new mapkit.BoundingRegion(northLat, eastLng, southLat, westLng);
  const region = br.toCoordinateRegion();

  return region;
}

const MapCard = styled('div', {
  position: 'absolute',
  top: 50,
  left: 50,
  boxShadow: shadows.overlayLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    boxShadow: shadows.overlayDark,
  },
});

const MapPosition = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  boxShadow: shadows.overlayLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    boxShadow: shadows.overlayDark,
  },
});

const MapWrapper = styled('div', {
  width: '100%',
  height: '100%',
  padding: sizing.squish,
});

export function HubMap({
  networks,
  company,
  uptimeStats,
}: {
  networks: NetworksOverviewData['networks'];
  company: string;
  uptimeStats: UptimeByNetwork;
}) {
  const [selected, setSelected] = useState<NetworksOverviewData['networks'][number] | null>(null);

  const { dark } = useContext(ThemeContext);

  const mapRef = useRef<mapkit.Map>(null);
  const [isReady, setIsReady] = useState(false);

  const ready = useCallback(() => {
    if (!mapRef.current) return;
    const center = new mapkit.Coordinate(38.175846642475676, -95.62310238392965);
    mapRef.current.setCenterAnimated(center, true);
    setIsReady(true);
  }, [mapRef]);

  useEffect(() => {
    if (!isReady) return;
    if (!mapRef.current) return;
    if (networks.length === 0) return;
    const region = computeBoundingRegion(networks);
    mapRef.current.setRegionAnimated(region, false);
    // If we don't wait a bit, the map will not be ready to set the region
    // Not sure if there's a MapKit property/event that could let us avoid this.
    const timeout = setTimeout(() => {
      if (!mapRef.current) return;
      mapRef.current.setRegionAnimated(region, true);
    }, 500);

    // Disabling bc we need a cleanup function to clear the timeout
    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };
  }, [isReady, networks, mapRef]);

  return (
    <MapWrapper>
      <MapPosition>
        <MapKitMap
          ref={mapRef}
          colorScheme={dark ? ColorScheme.Dark : ColorScheme.Light}
          /*
            Code reviewer: I know you're thinking... ahh!! a token in the code!
            This token gets sent to the client anyway so isn't really a secret.
            It's also scoped to our domains and can be rotated in the Apple dashboard if we like.
            It also doesn't let you do anything destructive.
            So I think it's fine to hardcode for now / forever.
            */
          token="eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik45M1JBUjU5VDYifQ.eyJpc3MiOiI3NTJNQUNUNTk5IiwiaWF0IjoxNzA4NjI2NDUxLCJleHAiOjE3NDAxODI0MDB9.s7XBfkrBQezN4IMhpcnttg2G6vDBWOZdOPKpiFJ3VSufjhsKDw08dF6w35sxIFcHWpcx009ahtcnVSRVK1aSvg"
          allowWheelToZoom
          onLoad={ready}
        >
          {networks.map((network) => (
            <Marker
              key={network.slug}
              latitude={network.mailingAddressCoordinates.latitude}
              longitude={network.mailingAddressCoordinates.longitude}
              selected={network.slug === selected?.slug || networks.length === 1}
              onSelect={() => {
                setSelected(network);
              }}
              onDeselect={() => {
                setSelected(null);
              }}
            />
          ))}
        </MapKitMap>
      </MapPosition>
      {(selected || networks.length === 1) && (
        <MapCard>
          <HubCard
            network={selected || networks[0]}
            company={company}
            uptimeStats={uptimeStats.get(selected?.uuid || networks[0].uuid)}
          />
        </MapCard>
      )}
    </MapWrapper>
  );
}

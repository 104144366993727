import type { QueryResultField } from '@meterup/graphql';
import { type Address4, gigabytes, safeParseAddress4 } from '@meterup/common';
import { bytes, Measure } from 'safe-units';
import { z } from 'zod';

import type { ControllerHardwareDevice, ControllerVirtualDevice } from './useCurrentControllers';
import { graphql } from '../../../gql';
import {
  type PhyInterfacesForSecurityApplianceQuery,
  type SecurityAppliancePortStatsQueryQuery,
  ClientAssignmentProtocol,
  HighAvailabilityControllerRole,
} from '../../../gql/graphql';
import {
  CreateHighAvailabilityPairInputSchema,
  UpdatePhyInterfaceInputSchema,
  UpdateVirtualDeviceInputSchema,
} from '../../../gql/zod-types';

export type SecurityAppliancePhyInterface = QueryResultField<
  PhyInterfacesForSecurityApplianceQuery,
  'phyInterfacesForVirtualDevice'
>;

export type SecurityAppliancePortStatsQueryResult = QueryResultField<
  SecurityAppliancePortStatsQueryQuery,
  'controllerPortStats'
>;

export type PhyInterfaceWithStats = {
  phyInterface: ControllerVirtualDevice['phyInterfaces'][number];
  portStats?: SecurityAppliancePortStatsQueryResult;
};

export function formatByteStat(value: number | undefined | null) {
  if (value === undefined || value === null) return 0;

  const gb = Measure.of(value, bytes).over(gigabytes).value;
  if (gb < 0.005) return 0;

  return gb;
}

export function formatPacketStat(value: number | undefined | null) {
  if (value === undefined || value === null) return 0;
  return value;
}

export const dhcpRulesForNetworkQuery = graphql(`
  query DHCPRulesForNetworkQuery($networkUUID: UUID!) {
    dhcpRulesForNetwork(networkUUID: $networkUUID) {
      UUID
      vlan {
        name
      }
    }
  }
`);

export const controllerMetricsQuery = graphql(`
  query ControllerMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {
    controllerPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {
      values {
        timestamp
        portNumber
        dropsPerSecond
        txErrPerSecond
        rxErrPerSecond
        totalRxBytesPerSecond
        totalTxBytesPerSecond
        multicastRxPacketsPerSecond
        multicastTxPacketsPerSecond
        broadcastRxPacketsPerSecond
        broadcastTxPacketsPerSecond
      }
    }
    controllerDNSRequestRates(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {
      values {
        timestamp
        value
        uuid
      }
    }
  }
`);

graphql(`
  fragment ControllerPhyInterfaceFields on PhyInterface {
    UUID
    virtualDeviceUUID

    virtualDevice {
      UUID
      label
      deviceModel
    }

    portNumber
    label
    description
    hardwareLabel
    isEnabled
    isTrunkPort
    isUplink
    isEthernet
    isSFP
    hasWANActivity
    forcedPortSpeedMbps
    portSpeedMbps
    maxSpeedMbps
    ipv4ClientAssignmentProtocol
    ipv4ClientGateway
    ipv4ClientPrefixLength
    ipv4ClientAddresses
    uplinkPriority
    nativeVLAN {
      UUID
      vlanID
      name
    }
    connectedDevices {
      macAddress
      hardwareDevice {
        serialNumber
        isConnectedToBackend
        isActive
        deviceType
        deviceModel
        virtualDevice {
          __typename
          deviceType
          UUID
          label
        }
      }
    }

    uplinkExternalAddresses
    hasWANActivity
    isUplinkActive
    uplinkVLANID
    internetServicePlan {
      UUID
      controllerIP
      provider {
        UUID
        name
        logoURL
      }
    }
    uplinkGatewayMACAddress
  }
`);

export const phyInterfacesForSecurityApplianceQuery = graphql(`
  query PhyInterfacesForSecurityAppliance($virtualDeviceUUID: UUID!) {
    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {
      ...ControllerPhyInterfaceFields
    }
  }
`);

export const SecurityAppliancePortStatsQuery = graphql(`
  query SecurityAppliancePortStatsQuery($virtualDeviceUUID: UUID!) {
    controllerPortStats(virtualDeviceUUID: $virtualDeviceUUID) {
      portNumber
      drops
      txErr
      rxErr
      ipv4
      ipv6
      punts
      totalRxBytes
      totalTxBytes
      totalRxPackets
      totalTxPackets
      hwUp
    }
  }
`);

export const uptimeStatsForNetworkQuery = graphql(`
  query UptimeStatsForNetwork($networkUUID: UUID!, $lookBack: Int!, $stepSeconds: Int!) {
    networkUplinkQuality(
      networkUUID: $networkUUID
      filter: { durationSeconds: $lookBack, stepSeconds: $stepSeconds }
    ) {
      values {
        phyInterfaceUUID
        timestamp
        value
      }
    }
  }
`);

// Dev code
export type PortStatQuery = {
  portNumber: number;
  drops: number;
  txErr: number;
  rxErr: number;
  ipv4: number;
  ipv6: number;
  punts: number;
  isUplink: boolean;
  totalRxBytes: number;
  totalTxBytes: number;
  totalRxPackets: number;
  totalTxPackets: number;
  multicastRxBytes: number;
  multicastTxBytes: number;
  multicastRxPackets: number;
  multicastTxPackets: number;
  broadcastRxBytes: number;
  broadcastTxBytes: number;
  broadcastRxPackets: number;
  broadcastTxPackets: number;
  unicastRxBytes: number;
  unicastTxBytes: number;
  unicastRxPackets: number;
  unicastTxPackets: number;
  UUID: string;
};

export enum SecurityApplianceDetailsTab {
  BootHistory = 'boot-history',
  HostMonitoring = 'host-monitoring',
  Insights = 'insights',
  Ports = 'ports',
}

export enum SecurityAppliancePortTab {
  Details = 'details',
  Config = 'config',
}

// TODO: Figure out what can cause an error status
export function portStatus(
  phyInterface: SecurityAppliancePhyInterface,
): 'connected' | 'disconnected' | 'error' {
  // if (port.isLoopbackDetected) return 'error';
  return phyInterface.portSpeedMbps ||
    phyInterface.connectedDevices?.length ||
    phyInterface.hasWANActivity
    ? 'connected'
    : 'disconnected';
}

export function portLifecycleStatus(
  phyInterface: SecurityAppliancePhyInterface,
): 'active' | 'inactive' | undefined {
  if (!phyInterface.isUplink || portStatus(phyInterface) === 'disconnected') return undefined;

  return phyInterface.isUplinkActive ? 'active' : 'inactive';
}

export function mergeStatsAndPhyInterfaces(
  stats: SecurityAppliancePortStatsQueryResult[],
  virtualDevice: ControllerVirtualDevice,
): PhyInterfaceWithStats[] {
  return virtualDevice.phyInterfaces.map((phyInterface) => {
    const portStats = stats.find((s) => s.portNumber === phyInterface.portNumber);
    return {
      phyInterface,
      portStats,
    };
  });
}

export const updateSecurityAppliancePhyInterfaceSchema = UpdatePhyInterfaceInputSchema.omit({
  frameAcceptTypeFilter: true,
  stormControlBroadcastTrafficPercent: true,
  stormControlUnknownUnicastTrafficPercent: true,
  stormControlUnknownMulticastTrafficPercent: true,
  isStormControlEnabled: true,
  isSTPEnabled: true,
  isSTPEdgePortEnabled: true,
  isTrunkPort: true,
  isPOEEnabled: true,
  isBoundToAllVLANs: true,
  isIngressFilteringEnabled: true,
  ipv6ClientAssignmentProtocol: true,
  ipv6ClientGateway: true,
  ipv6ClientPrefixLength: true,
})
  .extend({
    ipv4ClientAssignmentProtocol: z.nativeEnum(ClientAssignmentProtocol).nullish(),
    ipv4ClientGateway: z.string().nullish(),
    ipv4ClientPrefixLength: z.number().min(0).max(32).nullish(),
    uplinkVLANID: z.number().min(1).max(4096).nullish(),
  })
  .superRefine(
    (
      {
        ipv4ClientAssignmentProtocol,
        ipv4ClientAddresses,
        ipv4ClientGateway,
        ipv4ClientPrefixLength,
        isUplink,
        uplinkVLANID,
      },
      ctx,
    ) => {
      if (ipv4ClientAssignmentProtocol === ClientAssignmentProtocol.Static) {
        let gateway: Address4 | null = null;
        if (ipv4ClientGateway) {
          gateway = safeParseAddress4(ipv4ClientGateway);
          if (!gateway) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please provide a valid gateway address.',
              path: ['ipv4ClientGateway'],
            });
          }
        } else {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Static assignment protocol requires a gateway address.',
            path: ['ipv4ClientGateway'],
          });
        }

        let prefix: Address4 | null = null;
        if (ipv4ClientGateway && ipv4ClientPrefixLength) {
          prefix = safeParseAddress4(`${ipv4ClientGateway}/${ipv4ClientPrefixLength}`);
          if (!prefix) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid prefix length for IP address.',
              path: ['ipv4ClientPrefixLength'],
            });
          }
        } else if (!ipv4ClientPrefixLength) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Static assignment protocol requires a nonzero gateway prefix length.',
            path: ['ipv4ClientPrefixLength'],
          });
        }

        if (ipv4ClientAddresses?.length) {
          for (const ipv4ClientAddress of ipv4ClientAddresses) {
            const addr = safeParseAddress4(ipv4ClientAddress);
            if (addr && prefix && !addr.isInSubnet(prefix)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'IP addresses must be within gateway subnet.',
                path: ['ipv4ClientAddresses'],
              });
              break;
            } else if (addr && gateway && addr.address === gateway.address) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'IP address cannot be the same as the gateway address.',
                path: ['ipv4ClientAddresses'],
              });
              break;
            } else if (!addr) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Please provide valid IP addresses.',
                path: ['ipv4ClientAddresses'],
              });
              break;
            }
          }
        } else {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Static assignment protocol requires at least one client address.',
            path: ['ipv4ClientAddresses'],
          });
        }
      }

      if (!isUplink && uplinkVLANID) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'VLAN ID can only be set for uplink ports.',
          path: ['uplinkVLANID'],
        });
      }
    },
  );

export type UpdateSecurityAppliancePhyInterfaceValues = z.input<
  typeof updateSecurityAppliancePhyInterfaceSchema
>;

export const createHighAvailabilityConfigSchema = z
  .object({
    networkUUID: z.string(),
    primaryPhyInterfaceUUID: z
      .string({ required_error: 'Primary interface is required' })
      .min(1, 'Primary interface is required'),
    backupPhyInterfaceUUID: z
      .string({ required_error: 'Backup interface is required' })
      .min(1, 'Backup interface is required'),
    input: CreateHighAvailabilityPairInputSchema.extend({
      advertisementVLANID: z.coerce.number().int().min(1).max(4094).default(4040),
      advertisementIntervalMs: z.coerce.number().int().min(100).max(100_000).default(300),
    }),
  })
  .omit({ networkUUID: true })
  .superRefine(({ primaryPhyInterfaceUUID, backupPhyInterfaceUUID }, ctx) => {
    if (primaryPhyInterfaceUUID === backupPhyInterfaceUUID) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Primary and backup interfaces must be different',
        path: ['primaryPhyInterfaceUUID'],
      });
    }
  });

export enum SecurityApplianceISPTab {
  Create = 'create',
  List = 'list',
  Move = 'move',
}

export type CreateHighAvailabilityConfigSchema = z.infer<typeof createHighAvailabilityConfigSchema>;

export const updateSecurityApplianceVirtualDeviceSchema = UpdateVirtualDeviceInputSchema.pick({
  label: true,
  description: true,
  enableConsolePort: true,
}).extend({
  label: z.string().nonempty({ message: 'Please provide a label.' }),
});

export type UpdateSecurityApplianceVirtualDeviceFields = z.input<
  typeof updateSecurityApplianceVirtualDeviceSchema
>;

export function isPrimaryController(
  device: Pick<ControllerVirtualDevice, 'highAvailability'> & {
    hardwareDevice?: Pick<ControllerHardwareDevice, 'isActive'> | null;
  },
) {
  if (!device.highAvailability) return !!device.hardwareDevice?.isActive;

  return device.highAvailability.role === HighAvailabilityControllerRole.Primary;
}

type CapitalFirst<T extends String> = string extends T
  ? never
  : T extends `${infer F}${infer Rest}`
    ? `${Uppercase<F>}${Rest}`
    : T;

type RemoveUnderscore<T extends string, D extends string = '_'> = string extends T
  ? never
  : T extends `${infer F}${D}${infer R}`
    ? `${F} ${RemoveUnderscore<R, D>}`
    : T;

type TitleCase<T extends string> = CapitalFirst<RemoveUnderscore<Lowercase<T>>>;

export function humanize<const T extends string>(value: T): TitleCase<T> {
  return value
    .split('_')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ') as TitleCase<T>;
}

import type { SortingState } from '@tanstack/react-table';
import { Button, EmptyState, Pane, PaneContent, PaneHeader, SearchInput } from '@meterup/atto';
import {
  AutoTable,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import React from 'react';
import { Link } from 'react-router-dom';

import type { RadiusProfilesQueryResult } from '../../Wireless/SSIDs/SSIDsUtils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useNetworkWideCrumbs } from '../../../utils/routing';
import { NoValue } from '../../NoValue';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { RadiusProfilesQuery } from '../../Wireless/SSIDs/SSIDsUtils';

const builder = createColumnBuilder<RadiusProfilesQueryResult>();

const columns = [
  builder.data((profile) => profile.label, {
    id: 'profile-label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((profile) => profile.authServerIPAddress, {
    id: 'profile-auth-server-ip',
    header: 'Auth server IP',
  }),
  builder.data((profile) => profile.authServerPort.toString(), {
    id: 'profile-auth-server-port',
    header: 'Auth server port',
  }),
  builder.data((profile) => profile.accountingServerIPAddress?.toString() ?? '', {
    id: 'profile-acct-server-ip',
    header: 'Acct server IP',
    cell: ({ row: profile }) => {
      if (isDefinedAndNotEmpty(profile.accountingServerIPAddress)) {
        return <span>{profile.accountingServerIPAddress}</span>;
      }

      return <NoValue />;
    },
  }),
  builder.data((profile) => profile.accountingServerPort?.toString() ?? '', {
    id: 'profile-acct-server-port',
    header: 'Acct server port',
    cell: ({ row: profile }) => {
      if (isDefinedAndNotEmpty(profile.accountingServerPort)) {
        return <span>{profile.accountingServerPort}</span>;
      }

      return <NoValue />;
    },
  }),
];

function RADIUSProfiles() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const networkWideCrumb = useNetworkWideCrumbs();
  const closeDrawer = useCloseDrawerCallback();
  const profiles = useGraphQL(RadiusProfilesQuery, { networkUUID: network.UUID }).data
    ?.encryption8021XsForNetwork;
  expectDefinedOrThrow(profiles, new ResourceNotFoundError('Unable to load RADIUS profiles'));

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.RADIUSProfileEditPage);
  const { hasPermission } = usePermissions();

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...networkWideCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.RADIUSProfilesPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'RADIUS profiles',
            },
          },
        ]}
        icon="radius"
        heading="RADIUS profiles"
        actions={
          <>
            <IsPermitted
              isPermitted={({ permissions }) =>
                permissions.hasPermission(PermissionType.PermNetworkSettingsWrite)
              }
            >
              <Button
                variant="secondary"
                as={Link}
                to={makeDrawerLink(window.location, paths.drawers.RADIUSProfileCreatePage, {
                  companyName,
                  networkSlug: network.slug,
                })}
                arrangement="leading-icon"
                icon="plus"
              >
                Add RADIUS profile
              </Button>
            </IsPermitted>
            <SearchInput
              placeholder="..."
              aria-label="Filter RADIUS profiles"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent>
        {profiles.length === 0 ? (
          <EmptyState
            icon="radius"
            heading="No RADIUS profiles"
            action={
              <IsPermitted
                isPermitted={({ permissions }) =>
                  permissions.hasPermission(PermissionType.PermNetworkSettingsWrite)
                }
              >
                <Button
                  as={Link}
                  to={makeDrawerLink(window.location, paths.drawers.RADIUSProfileCreatePage, {
                    companyName,
                    networkSlug: network.slug,
                  })}
                  arrangement="leading-icon"
                  icon="plus"
                >
                  Add RADIUS profile
                </Button>
              </IsPermitted>
            }
          />
        ) : (
          <AutoTable
            key="profile-list"
            columns={columns}
            data={profiles}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            globalFilter={globalFilter}
            getLinkTo={(row) =>
              hasPermission(PermissionType.PermNetworkSettingsWrite)
                ? makeDrawerLink(window.location, paths.drawers.RADIUSProfileEditPage, {
                    uuid: row.UUID,
                    companyName,
                    networkSlug: network.slug,
                  })
                : undefined
            }
            isRowSelected={(row) => row.UUID === drawerParams?.uuid}
            onRowDeselect={closeDrawer}
          />
        )}
      </PaneContent>
    </Pane>
  );
}

export default RADIUSProfiles;

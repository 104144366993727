import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Pane, PaneContent } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';
import { Suspense } from 'react';

import BulkUpgradeList from '../../../../components/firmwareBulkUpgrader/BulkUpgradeList';
import { PaneContentSkeletons } from '../../../../components/Placeholders/AppLoadingFallback';

export const Meta: PagefileMetaFn = () => ({
  path: '/operator/firmware-bulk-upgrader/upgrades',
  layout: 'FirmwareBulkUpgraderLayout',
  title: 'Upgrades - Firmware bulk upgrader - Operator',
});

export default function FirmwareBulkUpgradesListPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  return (
    <Pane>
      <PaneContent>
        <Suspense fallback={<PaneContentSkeletons />}>
          <BulkUpgradeList />
        </Suspense>
      </PaneContent>
    </Pane>
  );
}

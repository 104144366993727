import { type z } from 'zod';

import type { InternalNotesForNetworkQuery } from '../../../../gql/graphql';
import { graphql } from '../../../../gql';
import { CreateInternalNoteInputSchema } from '../../../../gql/zod-types';

graphql(`
  fragment InternalNoteFields on InternalNote {
    UUID
    createdAt
    createdByUser {
      email
      firstName
      lastName
    }
    updatedAt
    updatedByUser {
      email
      firstName
      lastName
    }
    isImportant
    note
    photoURLs
    photoS3Keys
  }
`);

export const internalNotesForNetworkQuery = graphql(`
  query InternalNotesForNetwork($networkUUID: UUID!) {
    internalNotesForNetwork(networkUUID: $networkUUID) {
      ...InternalNoteFields
    }
  }
`);

export const internalNoteQuery = graphql(`
  query InternalNote($UUID: UUID!) {
    internalNote(UUID: $UUID) {
      ...InternalNoteFields
    }
  }
`);

export const createInternalNoteMutation = graphql(`
  mutation CreateInternalNote($networkUUID: UUID!, $input: CreateInternalNoteInput!) {
    createInternalNote(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateInternalNoteMutation = graphql(`
  mutation UpdateInternalNote($UUID: UUID!, $input: UpdateInternalNoteInput!) {
    updateInternalNote(UUID: $UUID, input: $input) {
      UUID
    }
  }
`);

export const deleteInternalNoteMutation = graphql(`
  mutation DeleteInternalNote($UUID: UUID!) {
    deleteInternalNote(UUID: $UUID) {
      UUID
    }
  }
`);

export type InternalNote = InternalNotesForNetworkQuery['internalNotesForNetwork'][number];

export const noteInputSchema = CreateInternalNoteInputSchema;
export type NoteInputValues = z.infer<typeof noteInputSchema>;

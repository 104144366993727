import type { OverlayTriggerState } from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { deleteVirtualDeviceMutation } from '../../Devices/utils';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import { AccessPointQuery, AccessPointsQuery } from '../../Wireless/utils';

export default function AccessPointDeleteDialog({
  state,
  uuid,
}: {
  state: OverlayTriggerState;
  uuid: string;
}) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const virtualDevice = useGraphQL(AccessPointQuery, { uuid, includeUptime }).data?.virtualDevice;

  const deleteMutation = useGraphQLMutation(deleteVirtualDeviceMutation);
  const handleDelete = useCallback(() => {
    if (deleteMutation.isLoading) return;

    deleteMutation.mutate(
      { uuid },
      {
        onSuccess: () => {
          notify('Successfully deleted access point.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(AccessPointsQuery, { networkUUID: network.UUID, includeUptime }),
          );
          closeDrawer();
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error deleting this access point ${
              gqlErr?.message ? `: ${gqlErr.message}` : ''
            }. Please make sure it is removed from any rack elevations.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [deleteMutation, closeDrawer, network.UUID, includeUptime, queryClient, uuid]);

  return (
    <DeleteDialog
      state={state}
      label={virtualDevice?.label}
      handleDelete={handleDelete}
      alert={{
        heading: 'Deleting an access point can impact the network',
        copy: 'Any connected devices will no longer use this access point.',
      }}
    />
  );
}

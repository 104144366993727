import { Button, PaneHeader, Tab } from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { useHandleRerenderDeviceConfig } from '../../../../../components/DeviceConfigs/utils';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { paths } from '../../../../../constants';
import { graphql } from '../../../../../gql';
import { useIsActiveMatcher } from '../../../../../hooks/useIsActive';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { SerialNumberComboBox } from './DeviceConfigEditor';

const deviceForNetworkQuery = graphql(`
  query DeviceForNetwork($networkUUID: UUID!) {
    virtualDevicesForNetwork(networkUUID: $networkUUID) {
      label
      hardwareDevice {
        serialNumber
      }
    }
  }
`);

export default function DeviceConfigPageHeader({
  serialNumber,
  setSerialNumber,
}: {
  serialNumber: string | undefined;
  setSerialNumber: (serialNumber: string) => void;
}) {
  const isActiveTest = useIsActiveMatcher();
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const networkSlug = network.slug;
  const { renderDeviceConfig, handleRerender } = useHandleRerenderDeviceConfig(serialNumber);
  const devicesJSON = useGraphQL(deviceForNetworkQuery, {
    networkUUID: network.UUID,
  }).data;
  expectDefinedOrThrow(devicesJSON, new ResourceNotFoundError('Config not found'));

  const serialNumberItems: { serialNumber: string; label: string }[] | undefined =
    devicesJSON.virtualDevicesForNetwork
      .filter((device) => device.hardwareDevice !== null)
      .map((device) => ({
        serialNumber: device.hardwareDevice!.serialNumber,
        label: device.label,
      }));

  serialNumberItems.sort((a, b) => a.label.localeCompare(b.label));

  const isDeviceConfigHistoryPageActive = isActiveTest({
    path: paths.pages.DeviceConfigHistoryPage,
    end: false,
  });
  const isDeviceConfigOverridesPageActive = isActiveTest({
    path: paths.pages.DeviceConfigOverridesPage,
    end: false,
  });

  return (
    <PaneHeader
      icon="code"
      heading="Config"
      tabs={
        <SerialNumberComboBox
          serialNumber={serialNumber}
          setSerialNumber={setSerialNumber}
          serialNumberItems={serialNumberItems}
        />
      }
      subtabs={
        <>
          <Tab
            icon="upgrading"
            as={ReactRouterLink}
            to={makeLink(paths.pages.DeviceConfigOverridesPage, {
              networkSlug,
              companyName,
              serialNumber: serialNumber!,
            })}
            selected={isDeviceConfigOverridesPageActive}
          >
            Overrides
          </Tab>
          <Tab
            icon="version"
            as={ReactRouterLink}
            to={makeLink(paths.pages.DeviceConfigHistoryPage, {
              networkSlug,
              companyName,
              serialNumber: serialNumber!,
            })}
            selected={isDeviceConfigHistoryPageActive}
          >
            History
          </Tab>
        </>
      }
      contentActions={
        serialNumber ? (
          <Button
            type="button"
            onClick={handleRerender}
            loading={renderDeviceConfig.isLoading}
            variant="secondary"
            icon="arrows-rotate"
            arrangement="leading-icon"
            title="Immediately rerenders a config with any configuration changes since the last render."
          >
            Rerender device config
          </Button>
        ) : null
      }
    />
  );
}

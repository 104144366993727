import type { api } from '@meterup/proto';
import { DateTime } from 'luxon';

import type { GetEventLogsQuery } from '../gql/graphql';
import { EventType } from '../gql/graphql';

export type DashboardEvent = {
  type: string;
  short_description: string;
  description: string;
  time: string;
  badge?: 'warning' | 'attention';
};

const parseAPStatusChange = (message: string, subtype: string | undefined): string => {
  // If we don't know what the change is, we just return a generic message.
  if (subtype === undefined) return 'One, or more, of your APs has had a status change';

  const regex = /(\d+) of (\d+)/;
  const matches = message.match(regex);
  const status = subtype === 'accessPointsOnline' ? 'back online' : 'offline';

  if (matches !== null) {
    const verb = matches[1] === '1' ? 'is' : 'are';
    return `${matches[1]} of your APs ${verb} ${status}`;
  }
  return `One, or more, of your APs is ${status}`;
};

const eventTitleWanFailover = 'WAN status change';

export const parseEvent = (event: api.AdhocControllerEvent): DashboardEvent => {
  // Fallback to defaults, will change some specific events.
  const resp: DashboardEvent = {
    type: event.event_type,
    short_description: event.message,
    description: event.message,
    time: DateTime.fromISO(event.created_at!).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
    badge: undefined,
  };

  const props = event.properties || {};

  switch (event.event_type) {
    case 'lte-failover':
      resp.type = 'LTE Failover';
      break;
    case 'Access points changed status':
      resp.short_description = parseAPStatusChange(event.message, event.subtype);
      resp.badge = event.subtype === 'accessPointsOffline' ? 'warning' : undefined;
      break;
    case 'High number of clients for AP':
      resp.short_description = `${props.count} clients are connected to AP ${props.apName}`;
      resp.badge = 'warning';
      break;
    case 'Controller changed status':
      resp.badge = event.subtype === 'offline' ? 'attention' : undefined;
      resp.short_description =
        event.subtype === 'offline'
          ? 'Network is offline due to offline Controller '
          : event.message;
      break;
  }

  return resp;
};

export type EventLog = NonNullable<GetEventLogsQuery['recentEventLogEvents']>[number];
type EventLogWANStatusChangeProperties = EventLog['properties'] & {
  __typename: 'WANStatusChangeProperties';
};
type EventLogWANStatusChange = EventLog & { properties: EventLogWANStatusChangeProperties };

type ActiveWanConnections = EventLogWANStatusChangeProperties['activeWANConnections'];

enum WANFailoverDirection {
  FailoverToBackup = 'failover-to-backup',
  FailoverToPrimary = 'failover-to-primary',
  FailoverDirectionUnknown = 'failover-direction-unknown',
}

type WANStatusChangeInfo = {
  activeConnections: ActiveWanConnections;
};

function parseWanStatusChangeInfo(event: EventLogWANStatusChange): WANStatusChangeInfo {
  const props = event.properties || {};
  const { activeWANConnections, virtualDevice } = props;
  if (
    virtualDevice.__typename !== 'ControllerVirtualDevice' ||
    !activeWANConnections ||
    activeWANConnections.length === 0
  ) {
    return { activeConnections: [] };
  }

  return {
    activeConnections: activeWANConnections,
  };
}

// returns true if all connections have an ISP name
function hasISPNames(connections: ActiveWanConnections): boolean {
  return connections.every(
    (connection) => connection && connection.internetServicePlanProvider?.name,
  );
}

function longWANLabels(connections: ActiveWanConnections): string[] {
  if (!hasISPNames(connections)) {
    return connections
      .map((connection) => `Port ${connection?.port}`)
      .filter((connection) => connection);
  }
  return connections
    .map(
      (connection) => `Port ${connection?.port} (${connection?.internetServicePlanProvider?.name})`,
    )
    .filter((connection) => connection);
}

function shortWANLabels(connections: ActiveWanConnections): string[] {
  if (!hasISPNames(connections)) {
    return connections
      .map((connection) => `Port ${connection?.port}`)
      .filter((connection) => connection);
  }
  return connections
    .map((connection) => ` ${connection?.internetServicePlanProvider?.name}`)
    .filter((connection) => connection);
}

function shortDescriptionWanStatusChange(info: WANStatusChangeInfo): string {
  const { activeConnections } = info;
  const labels = shortWANLabels(activeConnections);

  if (activeConnections.length === 0) {
    return 'No active WAN connections';
  }

  if (activeConnections.length === 1) {
    return `${labels[0]} is now active`;
  }

  if (activeConnections.length === 2) {
    return `${labels[0]} and ${labels[1]} are now active`;
  }
  const lastLabel = labels.pop();
  return `${labels.join(', ')}, and ${lastLabel} are now active`;
}

function longDescriptionWanStatusChange(info: WANStatusChangeInfo): string {
  const { activeConnections } = info;
  const labels = longWANLabels(activeConnections);

  if (labels.length === 0) {
    return 'No active WAN connections';
  }

  if (labels.length === 1) {
    return `Traffic on this network is now using ${labels[0]} as its active WAN connection.`;
  }

  if (labels.length === 2) {
    return `Traffic on this network is now using ${labels[0]} and ${labels[1]} as its active WAN connections.`;
  }

  const lastLabel = labels.pop();
  return `Traffic on this network is now using ${labels.join(', ')}, and ${lastLabel} as its active WAN connections.`;
}

export type EventLogEvent = DashboardEvent & {
  UUID: string;
  direction?: WANFailoverDirection; // Used for WAN status change (first active) events
};

export function parseEventLog({ event }: { event: EventLog }): EventLogEvent | null {
  const eventUUID = `${event.eventType}-${event.generatedAt}`;
  switch (event.eventType) {
    case EventType.WanStatusChange: {
      const info = parseWanStatusChangeInfo(event as EventLogWANStatusChange);
      return {
        type: eventTitleWanFailover,
        short_description: shortDescriptionWanStatusChange(info),
        description: longDescriptionWanStatusChange(info),
        time: DateTime.fromISO(event.generatedAt).toLocaleString(
          DateTime.DATETIME_MED_WITH_SECONDS,
        ),
        badge: 'warning',
        UUID: eventUUID,
      };
    }
  }

  return null;
}

import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { timePeriodLabel } from '../../../utils/chart_utils';
import { MetricBandFilter } from '../../Metrics/utils';
import { vlansForPingQuery } from '../../PingTest2/utils';
import { SSIDsQuery } from '../../Wireless/SSIDs/SSIDsUtils';
import { FilterBar } from '../FilterBar';

export default function ClientFilters() {
  const network = useNetwork();

  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const [currentBandOrUndefined, setCurrentBand] = useSearchParamsState<MetricBandFilter>(
    'band',
    MetricBandFilter.All,
  );
  const currentBand = currentBandOrUndefined ?? MetricBandFilter.All;

  const vlans = useGraphQL(vlansForPingQuery, {
    networkUUID: network.UUID,
  })?.data?.vlans;
  expectDefinedOrThrow(vlans, new ResourceNotFoundError('Unable to load vlans'));

  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork ?? [];

  const [currentSSIDOrUndefined, setCurrentSSID] = useSearchParamsState<{
    UUID: string;
    ssid: string;
  }>('ssid', { UUID: 'All', ssid: 'All' });
  const currentSSID = currentSSIDOrUndefined ?? { UUID: 'All', ssid: 'All' };

  const findSSIDByUUID = (uuid: string) =>
    ssids.find((s) => s.UUID === uuid) ?? { UUID: 'All', ssid: 'All' };

  return (
    <FilterBar>
      <DropdownMenu>
        <DropdownMenuButton variant="secondary" arrangement="leading-icon" size="small">
          SSID: {currentSSID.ssid}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentSSID.UUID}
            onValueChange={(val) => setCurrentSSID(findSSIDByUUID(val))}
          >
            {[{ UUID: 'All', ssid: 'All' }, ...ssids].map((s) => (
              <DropdownMenuRadioItem value={s.UUID} key={s.UUID}>
                {s.ssid}
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuButton variant="secondary" arrangement="leading-icon" size="small">
          Band: {currentBand}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentBand}
            onValueChange={(val) => setCurrentBand(val as MetricBandFilter)}
          >
            <DropdownMenuRadioItem value={MetricBandFilter.All}>All</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value={MetricBandFilter.Band2_4}>2.4 GHz</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value={MetricBandFilter.Band5}>5 GHz</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          arrangement="leading-icon"
          icon="clock"
          size="small"
        >
          {timePeriodLabel(currentTimePeriod)}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentTimePeriod}
            onValueChange={(val) => setCurrentTimePeriod(val)}
          >
            <DropdownMenuRadioItem value="1h">{timePeriodLabel('1h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="6h">{timePeriodLabel('6h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="24h">{timePeriodLabel('24h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="7d">{timePeriodLabel('7d')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="30d">{timePeriodLabel('30d')}</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Button variant="secondary" arrangement="hidden-label" icon="arrows-rotate" size="small">
        Refresh
      </Button>
    </FilterBar>
  );
}

import { FieldContainer, PrimaryField, Textarea, TextInput } from '@meterup/atto';

import { FieldProvider } from '../../Form/FieldProvider';
import { ToggleField } from '../../Form/Fields';

export function PowerDistributionUnitNameField() {
  return (
    <FieldContainer>
      <FieldProvider name="label">
        <PrimaryField label="Label" element={<TextInput />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function PowerDistributionUnitDescriptionField() {
  return (
    <FieldContainer>
      <FieldProvider name="description">
        <PrimaryField optional label="Description" element={<Textarea />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function PowerDistributionUnitIsConsoleEnabledField() {
  return (
    <FieldContainer>
      <ToggleField name="isConsoleEnabled" label="Enable console port" internal />
    </FieldContainer>
  );
}

import React from 'react';

import { components, MultiValueGenericProps } from 'react-select';

export function NumberedItemContainer<T>(props: MultiValueGenericProps<T, true>) {
  const { data, innerProps } = props;
  const { className, ...restInnerProps } = innerProps;
  return (
    <li>
      <components.MultiValueContainer
        {...props}
        innerProps={{
          ...restInnerProps,
        }}
      >
        {data.description}
      </components.MultiValueContainer>
    </li>
  );
}

import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import { paths } from '../../../../../constants';
import { useClientInVLANPredicate } from '../../../../../hooks/useVLANClients';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { ClientsList } from '../../insights/clients/ClientsList';
import ClientsListHeader from '../../insights/clients/ClientsListHeader';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/clients',
});

export default function VLANClientListPage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANClientListPage),
  );

  const controller = useCurrentControllerData();
  const controllerName = controller.name;

  useVLANConfigModel(controllerName, vlanName);
  const predicate = useClientInVLANPredicate(controller.name, vlanName);
  return (
    <DetailPageLayout
      header={<ClientsListHeader />}
      main={<ClientsList additionalClientPredicate={predicate} />}
    />
  );
}

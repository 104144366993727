import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FieldContainer,
  Link,
  PrimaryField,
  space,
  Textarea,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import UnifiedFileUploader from '@meterup/common/src/components/UnifiedFileUploader';
import { makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';

import { AllowedFolder } from '../../../../gql/graphql';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../../utils/withZodSchema';
import { FieldProvider, FilesUploadFieldProvider } from '../../../Form/FieldProvider';
import { ToggleField } from '../../../Form/Fields';
import {
  type InternalNote,
  type NoteInputValues,
  createInternalNoteMutation,
  internalNotesForNetworkQuery,
  noteInputSchema,
  updateInternalNoteMutation,
} from './utils';

type EditNoteDialogProps = {
  note?: InternalNote | undefined;
  state: OverlayTriggerState;
};

export function EditNoteDialog({ state, note }: EditNoteDialogProps) {
  const network = useNetwork();
  const createNote = useGraphQLMutation(createInternalNoteMutation);
  const updateNote = useGraphQLMutation(updateInternalNoteMutation);

  const queryClient = useQueryClient();
  const { mutate: doCreateNote, reset: resetCreateNote } = createNote;
  const { mutate: doUpdateNote, reset: resetUpdateNote } = updateNote;
  const { close: closeDialog } = state;
  const handleSubmit = useCallback(
    (values: NoteInputValues) => {
      if (note?.UUID) {
        resetCreateNote();
        doUpdateNote(
          { UUID: note.UUID, input: values },
          {
            onSuccess() {
              closeDialog();
              queryClient.invalidateQueries(
                makeQueryKey(internalNotesForNetworkQuery, { networkUUID: network.UUID }),
              );
              notify('Note updated successfully.', {
                variant: 'positive',
              });
            },
          },
        );
      } else {
        resetUpdateNote();
        doCreateNote(
          { networkUUID: network.UUID, input: values },
          {
            onSuccess() {
              closeDialog();
              queryClient.invalidateQueries(
                makeQueryKey(internalNotesForNetworkQuery, { networkUUID: network.UUID }),
              );
              notify('Note created successfully.', {
                variant: 'positive',
              });
            },
          },
        );
      }
    },
    [
      note?.UUID,
      network.UUID,
      doCreateNote,
      resetCreateNote,
      doUpdateNote,
      resetUpdateNote,
      closeDialog,
      queryClient,
    ],
  );

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="pencil" heading={`${note ? 'Edit' : 'Add'} note`} />
      <Formik<NoteInputValues>
        initialValues={{
          isImportant: note?.isImportant ?? false,
          note: note?.note ?? '',
          photoS3Keys: note?.photoS3Keys ?? [],
        }}
        validate={withZodSchema(noteInputSchema)}
        onSubmit={handleSubmit}
      >
        <Form>
          <DialogContent gutter="all">
            <VStack spacing={space(16)}>
              <FieldContainer>
                <ToggleField name="isImportant" label="Pinned" />
              </FieldContainer>
              <FieldContainer>
                <FieldProvider name="note">
                  <PrimaryField
                    label="Message"
                    description={
                      <>
                        Supports{' '}
                        <Link
                          href="https://en.wikipedia.org/wiki/Markdown#Examples"
                          target="_blank"
                          rel="noreferrer"
                        >
                          markdown
                        </Link>
                        .
                      </>
                    }
                    element={<Textarea rows={8} />}
                  />
                </FieldProvider>
              </FieldContainer>
              <FieldContainer>
                <FilesUploadFieldProvider name="photoS3Keys">
                  <PrimaryField
                    label="Photos"
                    element={
                      <UnifiedFileUploader
                        folder={AllowedFolder.InternalNotesAttachments}
                        allowedTypes={{ images: true }}
                      />
                    }
                  />
                </FilesUploadFieldProvider>
              </FieldContainer>

              {createNote.isError && (
                <Alert
                  icon="warning"
                  variant="negative"
                  heading="There was a problem creating the note."
                  copy={
                    createNote.error && createNote.error instanceof Error
                      ? createNote.error.message
                      : undefined
                  }
                />
              )}
              {updateNote.isError && (
                <Alert
                  icon="warning"
                  variant="negative"
                  heading="There was a problem updating the note."
                  copy={
                    updateNote.error && updateNote.error instanceof Error
                      ? updateNote.error.message
                      : undefined
                  }
                />
              )}
            </VStack>
          </DialogContent>

          <DialogFooter
            actions={
              <>
                <Button type="button" variant="secondary" onClick={state.close}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  {note ? 'Save' : 'Add'}
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

import { type AlertProps, Alert, Button } from '@meterup/atto';
import { useMemo } from 'react';

import type { AlertBannerSchema } from '../helpers/alertBanner';
import { useSessionStorage } from '../hooks/useSessionStorage';

export interface AlertBannerPropsParams {
  companyName?: string;
  controllerName?: string;
}

export interface AlertBannerProps {
  alertBanners?: AlertBannerSchema[];
  params: AlertBannerPropsParams;
}

// eslint-disable-next-line consistent-return
function severityToVariant(severity: AlertBannerSchema['severity']): AlertProps['variant'] {
  switch (severity) {
    case 'low':
      return 'neutral';
    case 'medium':
      return 'attention';
    case 'high':
      return 'negative';
  }
}

export default function AlertBanner({ alertBanners, params }: AlertBannerProps) {
  const [dismissedAlerts, setDismissedAlerts] = useSessionStorage<string[]>(
    'dismissed-banner-alerts',
    [],
  );

  const dismissedAlertsSet = useMemo(() => new Set(dismissedAlerts), [dismissedAlerts]);

  const filteredAlertBanners = useMemo(
    () =>
      alertBanners?.filter((alert) => {
        if (dismissedAlertsSet.has(alert.id)) return false;

        if (alert.companyName && alert.companyName !== params.companyName) return false;

        if (alert.controllerName && alert.controllerName !== params.controllerName) return false;

        return true;
      }) ?? [],
    [params, alertBanners, dismissedAlertsSet],
  );

  if (filteredAlertBanners?.length) {
    return (
      <>
        {filteredAlertBanners.map((alert) => (
          <Alert
            key={alert.id}
            id={alert.id}
            relation="stacked"
            copy={alert.message}
            icon={alert.severity === 'low' ? 'attention' : 'warning'}
            variant={severityToVariant(alert.severity)}
            dismissOnClick={() => {
              const newDismissedAlertsSet = new Set(dismissedAlertsSet);
              newDismissedAlertsSet.add(alert.id);
              setDismissedAlerts(Array.from(newDismissedAlertsSet.values()));
            }}
            shoulderButtons={
              alert.links && (
                <>
                  {alert.links.map((link) => (
                    <Button key={link.url} to={link.url} variant="secondary">
                      {link.text}
                    </Button>
                  ))}
                </>
              )
            }
          />
        ))}
      </>
    );
  }

  return null;
}

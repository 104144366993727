import { checkDefinedOrThrow } from '@meterup/common';

import ISP, { ISPValidTabs } from '../../../../../components/NetworkWide/ISPs/ISP';
import ISPInsights from '../../../../../components/NetworkWide/ISPs/ISPInsights';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/insights',
  layout: 'VirtualNetworkLayout',
});

export default function ISPInsightsPage() {
  const { uuid } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.ISPInsightsPage));

  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags }) =>
        Boolean(permissions.hasPermission(PermissionType.PermIspRead)) && Boolean(ldFlags.isp)
      }
      should404OnAccessDenied
    >
      <ISP uuid={uuid} activeTab={ISPValidTabs.Insights} content={<ISPInsights ispUUID={uuid} />} />
    </IsPermitted>
  );
}

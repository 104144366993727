import { checkDefinedOrThrow } from '@meterup/common';

import SecurityApplianceEditDrawer from '../../../../../components/Hardware/SecurityAppliance/SecurityApplianceEditDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/edit/general',
  title: 'Edit security appliance - Security appliance - Security appliances - Hardware',
});

export default function SecurityApplianceEditDrawerPage() {
  const drawerParams = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.SecurityApplianceEditDrawerPage),
  );

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
        nosFlags[NosFeature.COS2]
      }
      should404OnAccessDenied
      path={Meta().path}
    >
      <SecurityApplianceEditDrawer virtualDeviceUUID={drawerParams?.virtualDeviceUUID!} />
    </IsPermitted>
  );
}

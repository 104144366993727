import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import EditUserRoles from '../../../../../components/Users/EditUserRoles';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/org/users/:uuid/roles',
  title: 'Edit user roles - Users - Org',
});

export default function EditUserRolesPage() {
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditUserRolesPage),
  );

  return <EditUserRoles uuid={uuid} />;
}

import type { BadgeVariant } from '@meterup/atto';
import { Icon } from '@meterup/atto';
import { expectDefinedOrThrow, isDefined, ResourceNotFoundError } from '@meterup/common';
import { capitalize } from 'lodash-es';
import { Duration } from 'luxon';
import { useMemo } from 'react';

import type {
  ClientChannelUtilizationQueryQuery,
  WirelessConnectionEventsQueryQuery,
} from '../../gql/graphql';
import type { NetworkClient } from '../../hooks/networkClients/useNetworkClients';
import type { TimeSeries } from '../../utils/chart_utils';
import { graphql } from '../../gql';
import {
  WirelessClientConnectionEventType,
  WirelessClientConnectionFailedStep,
} from '../../gql/graphql';
import { useNetworkClients } from '../../hooks/networkClients/useNetworkClients';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { addValueToTimeSeries, getDurationMinutes } from '../../utils/chart_utils';
import { clientNameOrNull } from '../../utils/clientLists';

export type WirelessConnectionEventQueryResult = NonNullable<
  WirelessConnectionEventsQueryQuery['wirelessClientConnectionEventsByClient']
>[number];

export const WirelessConnectionEventsQuery = graphql(`
  query WirelessConnectionEventsQuery(
    $networkUUID: UUID!
    $mac: MacAddress!
    $filter: ClientMetricsTimeseriesFilterInput!
    $limit: Int
    $offset: Int
  ) {
    wirelessClientConnectionEventsByClient(
      networkUUID: $networkUUID
      macAddress: $mac
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      timestamp
      band
      eventType
      failedStep
      reasonCode
      sessionDuration
      timeToConnect
      is80211rRoam
      macAddress
      ssid {
        UUID
        ssid
      }
      virtualDevice {
        UUID
        label
      }
      previousVirtualDeviceConnectedTo {
        UUID
        label
      }
    }
  }
`);

export const WirelessClientMetricsByClientQuery = graphql(`
  query WirelessClientMetricsByClientQuery(
    $networkUUID: UUID!
    $macAddress: MacAddress!
    $filter: ClientMetricsTimeseriesFilterInput!
  ) {
    wirelessClientMetricsByClient(
      macAddress: $macAddress
      networkUUID: $networkUUID
      filter: $filter
    ) {
      metadata {
        signal {
          minValue
          maxValue
        }
        noise {
          minValue
          maxValue
        }
        txBytes {
          minValue
          maxValue
        }
        rxBytes {
          minValue
          maxValue
        }
      }
      values {
        timestamp
        signal
        noise
        txRate
        rxRate
        snr
        txBytes
        rxBytes
        txMulticastBytes
        rxMulticastBytes
        txUnicastBytes
        rxUnicastBytes
        rxSuccessRatio
        txSuccessRatio
        rxRetryRatio
        txRetryRatio
        clientCount
      }
    }
  }
`);

export type ClientChannelUtilizationQueryResult =
  NonNullable<ClientChannelUtilizationQueryQuery>['channelUtilizationByClient'][number];

export const ClientChannelUtilizationQuery = graphql(`
  query ClientChannelUtilizationQuery(
    $networkUUID: UUID!
    $macAddress: MacAddress!
    $filter: ChannelUtilizationTimeseriesFilterInput!
  ) {
    channelUtilizationByClient(
      networkUUID: $networkUUID
      macAddress: $macAddress
      filter: $filter
    ) {
      timestamp
      totalUtilization
    }
  }
`);

export function clientChannelUtilizationMetricsToSeries(
  values: ClientChannelUtilizationQueryResult[],
  step: number,
) {
  const series: TimeSeries = {
    series_id: 'Channel utilization',
    series_name: 'Channel utilization',
    data: [],
  };

  values.forEach((value, i) => {
    const currDate = new Date(value.timestamp);
    const prevDate = i > 0 ? new Date(values[i - 1].timestamp) : null;

    if (i === values.length - 1 && Date.now() - 5 * 60 * 1000 > currDate.getTime()) {
      // If the last element in the graph is older than 10 minutes, ad a null value for the current
      // time, so we see the graph up to the current time.
      addValueToTimeSeries(series, new Date(), null);
    }

    if (prevDate && currDate.getTime() - prevDate.getTime() > 1000 * step) {
      const midpoint = new Date((prevDate.getTime() + currDate.getTime()) / 2);
      addValueToTimeSeries(series, midpoint, null);
    }

    addValueToTimeSeries(series, currDate, value.totalUtilization);
  });

  return series;
}

export const upsertMACAddressAliasMutation = graphql(`
  mutation upsertMACAddressAlias($companySlug: String!, $macAddress: MacAddress!, $alias: String!) {
    upsertMACAddressAlias(companySlug: $companySlug, macAddress: $macAddress, alias: $alias) {
      alias
    }
  }
`);

export const rpcDisconnectClientMutation = graphql(`
  mutation rpcDisconnectClient($mac: String!, $serialNumber: String!, $ssid: String!) {
    rpcWosDisconnectClient(mac: $mac, serialNumber: $serialNumber, ssid: $ssid)
  }
`);

export const isRoam = (event: WirelessConnectionEventQueryResult) =>
  event.eventType === WirelessClientConnectionEventType.Connected &&
  event.previousVirtualDeviceConnectedTo &&
  event.previousVirtualDeviceConnectedTo.UUID !== event.virtualDevice.UUID;

export const isFastRoam = (event: WirelessConnectionEventQueryResult) =>
  isRoam(event) && event.is80211rRoam;

export const failedStepHuman = (
  failedStep: WirelessClientConnectionFailedStep | null | undefined,
) => {
  if (!isDefined(failedStep)) {
    return '';
  }
  switch (failedStep) {
    case WirelessClientConnectionFailedStep.FailedAuthentication:
      return 'Failed during authentication';
    case WirelessClientConnectionFailedStep.Failed_80211Association:
    case WirelessClientConnectionFailedStep.Failed_80211Authentication:
      return 'Failed during association';
    case WirelessClientConnectionFailedStep.FailedDhcpNak:
      return 'Failed with DHCP NAK';
    case WirelessClientConnectionFailedStep.FailedDhcpDecline:
      return 'Failed with DHCP decline';
    case WirelessClientConnectionFailedStep.FailedDhcpTimeout:
      return 'Failed with DHCP server timeout';
    default:
      return '';
  }
};

export const reasonCodeToHuman = (
  reasonCode: number | null | undefined,
  failedStep: WirelessClientConnectionFailedStep | null | undefined,
) => {
  if (!isDefined(reasonCode)) {
    // Could be zero, want to continue if it is to try to show the failed step
    return '';
  }
  switch (reasonCode) {
    case 2:
      return 'Invalid authentication credentials';
    case 3:
      return 'The client has disassociated from the access point';
    case 4:
      return 'Disassociated due to inactivity';
    case 5:
      return 'The access point is busy';
    case 8:
      return 'The client has disassociated from the access point';
    case 15:
      return 'Authentication failed during password exchange; password was likely incorrect';
    case 34:
      return 'Excess frame loss rates to the client; client has likely moved away';
  }
  return failedStepHuman(failedStep);
};

export function ConnectionEventIcon({
  event,
  size,
}: {
  event: WirelessConnectionEventQueryResult;
  size: number;
}) {
  if (isRoam(event)) {
    return (
      <Icon
        icon="arrow-right"
        size={size}
        color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
      />
    );
  }

  switch (event.eventType) {
    case WirelessClientConnectionEventType.DhcpOk:
      return (
        <Icon
          icon="dhcp"
          size={size}
          color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
        />
      );
    case WirelessClientConnectionEventType.DhcpFailed:
      return (
        <Icon
          icon="dhcp"
          size={size}
          color={{ light: 'iconNegativeLight', dark: 'iconNegativeDark' }}
        />
      );
    case WirelessClientConnectionEventType.Failed:
      return (
        <Icon
          icon="cross"
          size={size}
          color={{ light: 'iconNegativeLight', dark: 'iconNegativeDark' }}
        />
      );
    case WirelessClientConnectionEventType.Disassociated:
      return (
        <Icon
          icon="arrow-down"
          size={size}
          color={{ light: 'iconNeutralLight', dark: 'iconNeutralDark' }}
        />
      );
    case WirelessClientConnectionEventType.Connected:
      return (
        <Icon
          icon="arrow-up"
          size={size}
          color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
        />
      );
    default:
      return null;
  }
}

export const eventNameHuman = (event: WirelessConnectionEventQueryResult) => {
  if (isRoam(event)) {
    return 'Roam';
  }

  switch (event.eventType) {
    case WirelessClientConnectionEventType.DhcpOk:
      return 'DHCP Ok';
    case WirelessClientConnectionEventType.DhcpFailed:
      return 'DHCP Failed';
    default:
      return capitalize(event.eventType);
  }
};

export const eventDurationTitle = (event: WirelessConnectionEventQueryResult) => {
  if (event.eventType === WirelessClientConnectionEventType.Disassociated) {
    return 'Session duration';
  }

  if (event.eventType === WirelessClientConnectionEventType.Connected) {
    return 'Time to connect';
  }

  return 'Duration';
};

export const eventDuration = (event: WirelessConnectionEventQueryResult) => {
  if (
    event.eventType === WirelessClientConnectionEventType.Disassociated &&
    event.sessionDuration
  ) {
    const sessionDuration = Duration.fromISO(event.sessionDuration).toHuman({
      unitDisplay: 'narrow',
    });

    // This is a bit of a hack to remove the ms from the duration for session duration.
    return sessionDuration.replace(/, \d+ms/, '');
  }

  if (event.eventType === WirelessClientConnectionEventType.Connected && event.timeToConnect) {
    const timeToConnect = Duration.fromISO(event.timeToConnect).toHuman({ unitDisplay: 'narrow' });

    // This is a bit of a hack to remove the 0s from the time to connect (if it is less than 1 second).
    return timeToConnect.replace(/0s, /, '');
  }

  return '';
};

export function useClientByMAC(macAddress: string): NetworkClient {
  const network = useNetwork();
  const [excludeMeterHardware] = useSearchParamsState<boolean>('excludeMeterHardware');
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod');
  const currentTimePeriod = currentTimePeriodOrUndefined || '24h';
  const lookbackMinutes = useMemo(() => {
    if (!currentTimePeriod) return undefined;

    return getDurationMinutes(currentTimePeriod);
  }, [currentTimePeriod]);

  const clientHistory = useNetworkClients(network, {
    filter: { macAddress, lookbackMinutes, excludeMeterHardware },
  });

  const client = clientHistory[0];

  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));

  return client;
}

export function useClientNamesByMACForAccessPoint(apSerialNumber?: string) {
  const network = useNetwork();
  const allNetworkClients = useNetworkClients(network, {
    filter: {
      apSerialNumber,
      excludeMeterHardware: true,
    },
  });

  const nameMap = useMemo(
    () =>
      allNetworkClients.reduce<{ [key: string]: string }>(
        (obj, client) => ({
          ...obj,
          [client.macAddress]: clientNameOrNull(client) ?? client.macAddress,
        }),
        {},
      ),
    [allNetworkClients],
  );

  return nameMap;
}

export function wirelessScoreVariant(score: number): BadgeVariant {
  if (score < 25) return 'negative';
  if (score < 75) return 'attention';
  return 'positive';
}

export function healthVariantIcon(variant: BadgeVariant) {
  switch (variant) {
    case 'negative':
      return 'cross';
    case 'attention':
      return 'attention';
    default:
      return 'checkmark';
  }
}

export function healthVariantText(variant: BadgeVariant) {
  switch (variant) {
    case 'positive':
      return 'Healthy';
    case 'negative':
      return 'Unhealthy';
    case 'attention':
      return 'Fair';
    default:
      return capitalize(variant);
  }
}

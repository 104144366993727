import type { StatProps } from '@meterup/atto';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Badge,
  Column,
  Columns,
  Link,
  Pane,
  PaneContent,
  PaneHeader,
  Section,
  SectionContent,
  SectionHeader,
  space,
  Stats,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import type { JobData } from '../../../../utils/onboardings';
import { graphql } from '../../../../gql';
import { PermissionType } from '../../../../gql/graphql';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags';
import {
  useCompanyAndNetworkSlugsFromPath,
  useNetwork,
} from '../../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../../providers/PermissionsProvider';
import { getNetworkAddress } from '../../../../utils/network';
import {
  formattedGoLiveDate,
  getCustomerVisibleStatus,
  getJobOwnerUser,
  getNextEvent,
} from '../../../../utils/onboardings';
import OnboardingForm from './OnboardingForm';
import { OnboardingTimeline } from './OnboardingTimeline';
import { useOnboardingDocument } from './useOnboardingDocument';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/onboarding',
  layout: 'VirtualNetworkLayout',
  title: 'Onboarding',
});

const getOnboarding = graphql(`
  query onboardingForNetwork($networkSlug: String!, $companySlug: String!) {
    networkBySlug(companySlug: $companySlug, networkSlug: $networkSlug) {
      UUID
      onboarding {
        networkUUID
        jobData {
          fullAddress
          installationStartDate
          installationEndDate
          jobOwnerUser
          jobStage
          jobStatus
          jobTechnicianName
          jobTechnicianPhone
          onsiteContactInformation
          scheduleSiteSurveyLink
          siteSurveyDate
          siteSurveyStatus
          targetGoLiveDate
          totalSqFt
        }
      }
    }
  }
`);

function PendingBadge() {
  return (
    <Badge variant="neutral" ends="pill" size="small">
      Pending
    </Badge>
  );
}

function ContactsSection({ jobData }: { jobData?: JobData | null }) {
  const jobOwnerUser = jobData ? getJobOwnerUser(jobData) : null;

  if (!jobData) return null;

  return (
    <Section relation="stacked">
      <SectionHeader heading="Contacts" icon="email" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>Meter</SummaryListKey>
            <SummaryListValue>
              {jobOwnerUser ? (
                <p>
                  {jobOwnerUser.name}
                  <br />
                  <Link href={`mailto:${jobOwnerUser.email}`}>{jobOwnerUser.email}</Link>
                </p>
              ) : (
                <PendingBadge />
              )}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Install technician</SummaryListKey>
            <SummaryListValue>
              {jobData?.jobTechnicianName ? (
                <p>
                  {jobData.jobTechnicianName}
                  {jobData.jobTechnicianPhone && (
                    <>
                      <br />
                      <Link href={`tel:${jobData.jobTechnicianPhone}`}>
                        {jobData.jobTechnicianPhone}
                      </Link>
                    </>
                  )}
                </p>
              ) : (
                <PendingBadge />
              )}
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export default function OnboardingPage() {
  const network = useNetwork();
  const { hasPermission } = usePermissions();
  const companyAndNetworkSlugs = checkDefinedOrThrow(useCompanyAndNetworkSlugsFromPath());
  const featureFlags = useFeatureFlags();

  const { data } = useGraphQL(getOnboarding, companyAndNetworkSlugs);

  if (!hasPermission(PermissionType.PermNetworkRead) || !featureFlags['onboarding-form']) {
    throw new ResourceNotFoundError('Page not found');
  }

  const jobData = data?.networkBySlug.onboarding?.jobData;

  const status = getCustomerVisibleStatus(jobData?.jobStatus ?? 'Kickoff');
  const nextEvent = jobData ? getNextEvent(jobData) : null;
  const onboardingDocument = useOnboardingDocument();

  const stats: StatProps[] = [
    {
      label: 'Status',
      value: (
        <Badge variant={status.sentiment} ends="pill">
          {status.label}
        </Badge>
      ),
    },
  ];

  if (onboardingDocument?.state?.desired_go_live_date) {
    stats.push({
      label: 'Go-live target',
      value: formattedGoLiveDate(onboardingDocument?.state?.desired_go_live_date),
    });
  }

  if (nextEvent) {
    stats.push({
      label: 'Next event',
      value: Object.values(nextEvent).join(', '),
    });
  }

  return (
    <Pane layoutMode="detailed">
      <PaneHeader icon="upgrading" heading="Onboarding" />
      <PaneContent gutter="none">
        <OnboardingTimeline />
        <Columns scroll="none" template="wide-narrow">
          <Column gutter="vertical" spacing={space(0)}>
            <OnboardingForm />
          </Column>
          <Column gutter="vertical" spacing={space(16)}>
            <Stats stats={stats} />
            <ContactsSection jobData={jobData} />
            <Section relation="stacked">
              <SectionHeader heading="Location" icon="location" />
              <SectionContent gutter="all">
                <SummaryList gutter="none">
                  <SummaryListRow>
                    <SummaryListKey>Address</SummaryListKey>
                    <SummaryListValue>{getNetworkAddress(network)}</SummaryListValue>
                  </SummaryListRow>
                </SummaryList>
              </SectionContent>
            </Section>
          </Column>
        </Columns>
      </PaneContent>
    </Pane>
  );
}

/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';

import {
  darkThemeSelector,
  formatMoney,
  HStack,
  Icon,
  Large,
  Section,
  SectionContent,
  SectionHeader,
  space,
  styled,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  VStack,
} from '@meterup/atto';
import { colors } from '@meterup/atto/src/stitches.config';
import type { CompanyContractResponse, CompanyLocationResponse } from '@meterup/connect-api';
import { ISPProduct } from '@meterup/connect-api';
import { DefaultRenderNetworkDetails } from '@meterup/connect-ui/src/components/Connect/DefaultRenderNetworkDetails';
import { StatusCell } from '@meterup/connect-ui/src/components/Table/CommonCells';
import { formatKbps as formatKbpsExternal } from '@meterup/connect-ui/src/components/Table/formatters';
import { EM_DASH } from '@meterup/connect-ui/src/constants';
import useFormatters from '@meterup/connect-ui/src/hooks/useFormatters';
import { trimStart } from 'lodash-es';

import ContractLengthCell from '../Locations/ContractLengthCell';
import NotesEditor from '../NotesEditor';
import { EmptyContractDetailBox } from './EmptyContractDetailBox';
import SpeedButton from './SpeedButton';

function formatKbps(kbps: number) {
  if (kbps <= 0) {
    return EM_DASH;
  }
  return formatKbpsExternal(kbps);
}

const DetailBoxOuterWrapper = styled('div', {
  flex: 1,
  height: '100%',
  overflow: 'auto',

  '&:first-child': {
    borderRight: `1px solid ${colors.strokeNeutralLight}`,

    [darkThemeSelector]: {
      borderRightColor: colors.strokeNeutralDark,
    },
  },
});

const DetailBox = styled(VStack, {
  gap: '$16',
  maxWidth: '360px',
  padding: '0 $16',
  margin: '32px auto',
});

const Logo = styled('div', {
  margin: '0 auto',
  height: 48,
  display: 'flex',
  alignItems: 'center',
  '& > img': {
    width: 95,
  },
});

type DetailsBoxProps = {
  companyLocation: CompanyLocationResponse;
  contractResponse?: CompanyContractResponse;
  isPrimary?: boolean;
  quoteRequestSID?: string;
  renderNetworkDetails?: (contractResponse?: CompanyContractResponse) => React.ReactNode;
  isNetworkOnlyCustomer?: boolean;
};

export default function ContractDetailBox({
  companyLocation,
  contractResponse,
  isPrimary = false,
  quoteRequestSID,
  renderNetworkDetails,
  isNetworkOnlyCustomer,
}: DetailsBoxProps) {
  const { pbDateFormatter } = useFormatters();
  const internetServicePlan = contractResponse?.internetServicePlan;
  const networkDetailView = useMemo(() => {
    if (!contractResponse) {
      return null;
    }
    if (renderNetworkDetails) {
      return renderNetworkDetails(contractResponse);
    }
    return <DefaultRenderNetworkDetails contractResponse={contractResponse} />;
  }, [contractResponse, renderNetworkDetails]);

  if (!contractResponse || !internetServicePlan) {
    return (
      <EmptyContractDetailBox
        companyLocation={companyLocation}
        isPrimary={isPrimary}
        // @ts-ignore
        quoteRequestSID={quoteRequestSID}
      />
    );
  }

  const { latestStatus, provider } = contractResponse;
  const {
    accountNumber,
    downloadKbps,
    monthlyCostCents,
    notes,
    product,
    quote,
    renewalDate: renewalDateString,
    sid,
    uploadKbps,
  } = internetServicePlan;
  const { path, name } = provider!;
  const renewalDate = pbDateFormatter(renewalDateString);

  return (
    <DetailBoxOuterWrapper>
      <DetailBox>
        <Logo>
          {path ? (
            <img
              src={`https://public.meter.com/${trimStart(path, '/')}`}
              crossOrigin="anonymous"
              alt={`Logo for ${name}`}
            />
          ) : (
            <Large weight="bold">{name}</Large>
          )}
        </Logo>
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Type</SummaryListKey>
            <SummaryListValue>{isPrimary ? 'Primary' : 'Secondary'}</SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Speeds</SummaryListKey>
            <SummaryListValue>
              <VStack>
                <HStack spacing={space(4)} align="center">
                  <Icon icon="arrow-down" size={12} />
                  {formatKbps(downloadKbps)}
                </HStack>
                <HStack spacing={space(4)} align="center">
                  <Icon icon="arrow-up" size={12} />
                  {formatKbps(uploadKbps)}
                </HStack>
              </VStack>
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
        {networkDetailView}
        <Section relation="standalone">
          <SectionHeader icon="information" heading="Contract" />
          <SectionContent>
            <SummaryList>
              <SummaryListRow>
                <SummaryListKey>Provider</SummaryListKey>
                <SummaryListValue>{name}</SummaryListValue>
              </SummaryListRow>
              {accountNumber ? (
                <SummaryListRow>
                  <SummaryListKey>Account number</SummaryListKey>
                  <SummaryListValue>
                    <Text family="monospace">{accountNumber}</Text>
                  </SummaryListValue>
                </SummaryListRow>
              ) : null}
              {product && product !== ISPProduct.IR_UNKNOWN ? (
                <SummaryListRow>
                  <SummaryListKey>Product</SummaryListKey>
                  <SummaryListValue>
                    <SpeedButton product={product} />
                  </SummaryListValue>
                </SummaryListRow>
              ) : null}
              <SummaryListRow>
                <SummaryListKey>Status</SummaryListKey>
                <SummaryListValue>
                  <StatusCell connectionStatusChange={latestStatus} />
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Monthly price</SummaryListKey>
                <SummaryListValue>
                  {formatMoney({ amount: monthlyCostCents / 100, hideCentsIfZero: false })}
                </SummaryListValue>
              </SummaryListRow>
              {!isNetworkOnlyCustomer && quote ? (
                <SummaryListRow>
                  <SummaryListKey>Contract term</SummaryListKey>
                  <SummaryListValue>
                    <ContractLengthCell quote={quote} />
                  </SummaryListValue>
                </SummaryListRow>
              ) : null}
              {renewalDate ? (
                <SummaryListRow>
                  <SummaryListKey>Renewal date</SummaryListKey>
                  <SummaryListValue>{renewalDate}</SummaryListValue>
                </SummaryListRow>
              ) : null}
            </SummaryList>
          </SectionContent>
        </Section>
        {!isNetworkOnlyCustomer && (
          <Section relation="standalone">
            <SectionHeader icon="pencil" heading="Notes" />
            <SectionContent gutter="all">
              <NotesEditor content={notes} ispSid={sid} label={<span />} />
            </SectionContent>
          </Section>
        )}
      </DetailBox>
    </DetailBoxOuterWrapper>
  );
}

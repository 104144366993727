import {
  Badge,
  BadgeGroup,
  Body,
  Button,
  colors,
  darkThemeSelector,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  PaneContent,
  PaneFooter,
  Small,
  space,
  styled,
  ToggleInput,
  Tooltip,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { useIsOperator } from '@meterup/authorization';
import { notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { type VlanPairInput, PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { vlanHasStaticIP } from '../../NetworkWide/VLANs/utils';
import IsPermitted from '../../permissions/IsPermitted';
import { useIsPermitted } from '../../permissions/useIsPermitted';
import { type VLANForFirewall, vlansForFirewallQuery } from '../utils';
import {
  copyInterVLANCommunicationFromConfig1Mutation,
  updateInterVLANCommunicationPermittedPairsMutation,
} from './utils';

function VLANBadge({ vlan }: { vlan: VLANForFirewall }) {
  const supportsInterVLANCommunication = vlanHasStaticIP(vlan);
  return (
    <BadgeGroup relation="joined">
      {supportsInterVLANCommunication ? (
        <Badge arrangement="leading-icon" icon="vlan" size="small" variant="neutral">
          {vlan.name}
        </Badge>
      ) : (
        <Tooltip contents="VLAN requires static IP to allow inter-VLAN communication">
          <Badge arrangement="leading-icon" icon="cross" size="small" variant="negative">
            {vlan.name}
          </Badge>
        </Tooltip>
      )}
      <Badge size="small" variant={supportsInterVLANCommunication ? 'neutral' : 'negative'}>
        #{vlan.vlanID}
      </Badge>
    </BadgeGroup>
  );
}

export function InterVLANCommunicationsCopyDescription() {
  return (
    <>
      Syncs the <Small family="monospace">"dst-vlan-permits"</Small> settings from VLAN configs,
      overwriting any existing config 2 settings. If the controller does not have{' '}
      <Small family="monospace">"deny-inter-vlan-communication": true</Small> set in its config,
      this will automatically create permitted pairs between all non-management VLANs for backward
      compatibility.
    </>
  );
}

export function InterVLANCommunicationActions() {
  const network = useNetwork();
  const { state } = useDialogState();

  const copyInterVLANCommunication = useGraphQLMutation(
    copyInterVLANCommunicationFromConfig1Mutation,
  );

  const queryClient = useQueryClient();

  const handleCopy = useCallback(() => {
    copyInterVLANCommunication.mutate(
      { networkUUID: network.UUID },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(vlansForFirewallQuery, { networkUUID: network.UUID }),
          );
          notify('Successfully copied inter-VLAN communication.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem copying inter-VLAN communication${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [network.UUID, copyInterVLANCommunication, queryClient]);

  return (
    <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem icon="copy" onClick={state.open} internal>
              Copy inter-VLAN communication from config 1
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Dialog state={state}>
        <DialogHeader icon="arrow-bidirectional" heading="Copy inter-VLAN communication" />
        <DialogContent gutter="all">
          <VStack spacing={space(12)}>
            <Body>
              <p>
                <InterVLANCommunicationsCopyDescription />
              </p>
            </Body>
            <Body>
              <p>Are you sure you want to proceed?</p>
            </Body>
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={state.close}>
                Cancel
              </Button>
              <Button onClick={handleCopy}>Copy inter-VLAN communication</Button>
            </>
          }
        />
      </Dialog>
    </IsPermitted>
  );
}

const MatrixGrid = styled('div', {
  position: 'relative',
  display: 'grid',
  width: '100%',
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    strokeAll: colors.strokeNeutralDark,
  },
});
MatrixGrid.displayName = 'MatrixGrid';

const MatrixCell = styled('div', {
  position: 'relative',
  display: 'flex',
  background: colors.bgApplicationLight,
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeAll: colors.strokeNeutralDark,
  },

  variants: {
    end: {
      true: {
        zIndex: 5,
      },
      false: {},
    },
    side: {
      true: {
        zIndex: 10,
        position: 'sticky',
        left: 0,
      },
      false: {},
    },
    disabled: {
      false: {},
      true: {
        zIndex: 2,
        backgroundColor: colors.bgNeutralLight,

        [darkThemeSelector]: {
          backgroundColor: colors.bgNeutralDark,
        },

        '&::before, &::after': {
          display: 'none',
        },

        [selectors.hover]: {
          '&::before, &::after': {
            display: 'none',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      disabled: true,
      side: true,
      css: {
        zIndex: 10,
      },
    },
  ],
});
MatrixCell.displayName = 'MatrixCell';

const MatrixCellHeader = styled('div', {
  position: 'relative',
  zIndex: 5,
  display: 'flex',
  width: '100%',
  padding: '$6 $12',

  variants: {
    end: {
      true: {
        justifyContent: 'center',
      },
      false: {},
    },
  },
});
MatrixCellHeader.displayName = 'MatrixCellHeader';

const MatrixCellInput = styled('div', {
  zIndex: 1,
});

const MatrixCellToggle = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '$6 $12',

  '&::before, &::after': {
    content: '',
    position: 'absolute',
    zIndex: 0,
    right: '0.5px',
    bottom: '0.5px',
    display: 'none',
    background: colors.bgNeutralLight,
    pointerEvents: 'none',

    [darkThemeSelector]: {
      background: colors.bgNeutralDark,
    },
  },

  '&::before': {
    left: '0.5px',
    height: '100vw',
  },

  '&::after': {
    top: '0.5px',
    width: '100vw',
  },

  [selectors.hover]: {
    '&::before, &::after': {
      display: 'block',
    },
  },
});
MatrixCellToggle.displayName = 'MatrixCellToggle';

export default function InterVLANCommunication() {
  const network = useNetwork();
  const canEditInterVLANCommunication = useIsPermitted({
    isPermitted: ({ isOperator, permissions, ldFlags }) =>
      permissions.hasPermission(PermissionType.PermFirewallWrite) &&
      (isOperator || !!ldFlags['firewall-rules-inter-vlan']),
  });
  const isOperator = useIsOperator();
  const vlans = useGraphQL(vlansForFirewallQuery, { networkUUID: network.UUID }).data?.vlans;

  const sortedVLANs = useMemo(() => {
    if (!vlans) return [];

    return vlans.slice().sort((a, b) => a.name.localeCompare(b.name));
  }, [vlans]);

  const updateInterVLANCommunication = useGraphQLMutation(
    updateInterVLANCommunicationPermittedPairsMutation,
  );

  const initialPairsMap = useMemo(
    () =>
      new Map<string, Set<string>>(
        sortedVLANs.map((v) => [
          v.UUID,
          new Set(v.permittedInterVLANCommunicationVLANs.map(({ UUID }) => UUID)),
        ]),
      ),
    [sortedVLANs],
  );

  const [pairsMap, setPairsMap] = useState<Map<string, Set<string>>>(initialPairsMap);

  useEffect(() => {
    setPairsMap(initialPairsMap);
  }, [initialPairsMap]);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(() => {
    const flattenedPairsMap = new Map<string, VlanPairInput>(
      Array.from(pairsMap.entries()).flatMap(([vlan1UUID, dstVLANs]) =>
        Array.from(dstVLANs.values()).map((vlan2UUID) => [
          [vlan1UUID, vlan2UUID].sort().join(':'),
          { vlan1UUID, vlan2UUID },
        ]),
      ),
    );
    updateInterVLANCommunication.mutate(
      {
        networkUUID: network.UUID,
        vlanPairs: Array.from(flattenedPairsMap.values()),
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(vlansForFirewallQuery, { networkUUID: network.UUID }),
          );
          notify('Successfully updated inter-VLAN communication.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem updating inter-VLAN communication${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [network.UUID, updateInterVLANCommunication, pairsMap, queryClient]);

  return (
    <>
      <PaneContent>
        <MatrixGrid style={{ gridTemplateColumns: `repeat(${sortedVLANs.length + 1}, 1fr)` }}>
          <MatrixCell side />
          {sortedVLANs.map((colVLAN) => (
            <MatrixCell end key={`col-heading-${colVLAN.UUID}`}>
              <MatrixCellHeader end>
                <VLANBadge vlan={colVLAN} />
              </MatrixCellHeader>
            </MatrixCell>
          ))}
          {sortedVLANs.map((rowVLAN) => (
            <>
              <MatrixCell side>
                <MatrixCellHeader>
                  <VLANBadge vlan={rowVLAN} />
                </MatrixCellHeader>
              </MatrixCell>
              {sortedVLANs.map((colVLAN) =>
                colVLAN.UUID === rowVLAN.UUID ? (
                  <MatrixCell disabled />
                ) : (
                  <MatrixCell>
                    <MatrixCellToggle>
                      <MatrixCellInput>
                        <ToggleInput
                          aria-label={`Inter-VLAN communication between ${rowVLAN.name} and ${colVLAN.name}`}
                          controlSize="small"
                          disabled={
                            !canEditInterVLANCommunication ||
                            !vlanHasStaticIP(rowVLAN) ||
                            !vlanHasStaticIP(colVLAN) ||
                            (!isOperator && (!!rowVLAN.isInternal || !!colVLAN.isInternal))
                          }
                          onChange={(isSelected) => {
                            if (!isOperator && (!!rowVLAN.isInternal || !!colVLAN.isInternal))
                              return;
                            setPairsMap((prev) => {
                              const next = new Map(
                                Array.from(prev.entries()).map(([key, vals]) => [
                                  key,
                                  new Set(vals),
                                ]),
                              );

                              if (isSelected) {
                                next.get(rowVLAN.UUID)?.add(colVLAN.UUID);
                                next.get(colVLAN.UUID)?.add(rowVLAN.UUID);
                              } else {
                                next.get(rowVLAN.UUID)?.delete(colVLAN.UUID);
                                next.get(colVLAN.UUID)?.delete(rowVLAN.UUID);
                              }

                              return next;
                            });
                          }}
                          selected={pairsMap.get(rowVLAN.UUID)?.has(colVLAN.UUID)}
                        />
                      </MatrixCellInput>
                    </MatrixCellToggle>
                  </MatrixCell>
                ),
              )}
            </>
          ))}
        </MatrixGrid>
      </PaneContent>
      <IsPermitted permissions={PermissionType.PermFirewallWrite}>
        <PaneFooter
          actions={
            <>
              <Button
                type="button"
                onClick={() => {
                  setPairsMap(initialPairsMap);
                }}
                variant="secondary"
              >
                Reset
              </Button>
              <Button type="button" onClick={handleSubmit} variant="primary">
                Save
              </Button>
            </>
          }
        />
      </IsPermitted>
    </>
  );
}

import 'reactflow/dist/style.css';

import { EmptyState, Pane, PaneContent, PaneHeader, sizing, styled } from '@meterup/atto';
import { isDefined, PanAndZoomRegion, preloadImage } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';

import { fetchFloorPlan } from '../../../../../api/api';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/design/floor-plans',
  layout: 'NetworkLayout',
  title: 'Floor plans',
});

const PanningContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: sizing.squish,
  overflow: 'hidden',
});

export default function FloorPlansLegacyPage() {
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const floorPlanURL = useQuery(['floor_plan', controllerName], async () => {
    const url = await fetchFloorPlan(controllerName);
    return url ? preloadImage(url) : null;
  }).data;
  return (
    <Pane>
      <PaneHeader icon="floorplan" heading="Floor plans" />
      <PaneContent gutter="none">
        <PanningContainer>
          <PanAndZoomRegion>
            {isDefined(floorPlanURL) ? (
              <img
                src={floorPlanURL}
                crossOrigin="anonymous"
                alt="Floor plan"
                style={{ maxHeight: '50vh', userSelect: 'none' }}
              />
            ) : (
              <EmptyState icon="floorplan" heading="No floor plans" />
            )}
          </PanAndZoomRegion>
        </PanningContainer>
      </PaneContent>
    </Pane>
  );
}

import { useGraphQL } from '@meterup/graphql';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import type {
  MutationAuditLogEntriesForNetworkQuery,
  MutationAuditLogFilter,
} from '../../../../../../gql/graphql';
import { graphql } from '../../../../../../gql';
import { useNetworkUUID } from '../../../../../../hooks/useNetworkFromPath';

export type MutationAuditLogQueryResult = NonNullable<
  MutationAuditLogEntriesForNetworkQuery['mutationAuditLogEntriesForNetwork']
>[number];

export const networkMutationAuditLogEntriesQuery = graphql(`
  query MutationAuditLogEntriesForNetwork($networkUUID: UUID!, $filter: MutationAuditLogFilter) {
    mutationAuditLogEntriesForNetwork(networkUUID: $networkUUID, filter: $filter) {
      UUID
      networkUUID
      createdAt
      username
      action
      args
      reason
    }
  }
`);

export function networkMutationAuditLogFilter(filter?: MutationAuditLogFilter) {
  const defaultStartDate = DateTime.now().startOf('day').minus({ months: 3 }).toISO();
  const defaultEndDate = DateTime.now().endOf('day').toISO();
  return {
    minCreatedAt: defaultStartDate,
    maxCreatedAt: defaultEndDate,
    offset: 0,
    limit: 1000,
    ...filter,
  };
}

export function useNetworkMutationAuditLogForNetwork(
  networkUUID: string | null,
  filter?: MutationAuditLogFilter,
) {
  const networkMutationLogEntries = useGraphQL(
    networkMutationAuditLogEntriesQuery,
    {
      networkUUID: networkUUID!,
      filter: networkMutationAuditLogFilter(filter),
    },
    { enabled: !!networkUUID },
  ).data?.mutationAuditLogEntriesForNetwork;

  return useMemo(() => networkMutationLogEntries ?? [], [networkMutationLogEntries]);
}

export function useNetworkMutationAuditLog(filter?: MutationAuditLogFilter) {
  const networkUUID = useNetworkUUID();

  return useNetworkMutationAuditLogForNetwork(networkUUID, filter);
}

import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  Pane,
  PaneContent,
  PaneHeader,
  Sections,
  Skeleton,
  space,
  styled,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Suspense, useCallback, useMemo } from 'react';

import type { Uplink } from './utils';
import { paths } from '../../../constants';
import {
  type MetricsFilterInput,
  HighAvailabilityStatus,
  PermissionType,
} from '../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { getStep } from '../../../utils/chart_utils';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useInsightsCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { getPhyInterfaceLabel, uplinkPhyInterfacesQuery } from '../../Firewall/utils';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { FilterBar } from '../FilterBar';
import ActiveClientsChart, { activeClientsQuery } from './ActiveClientsChart';
import ISPQualityChart, { networkUplinkQualityQuery } from './ISPQualityChart';
import NetworkStats from './NetworkStats';
import PingTestDialog from './PingTestDialog';
import SpeedTestDialog from './SpeedTestDialog';
import UplinkThroughputChart, { networkUplinkThroughputQuery } from './UplinkThroughputChart';
import { getDurationSeconds, getUplinkDevices, timePeriodLabel } from './utils';

const ChartGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(328px, 1fr))',
  rowGap: '$16',
});

function UplinksQualityCharts({
  uplinkDevices,
  params,
}: {
  uplinkDevices: [string, [Uplink, ...Uplink[]]][];
  params: { networkUUID: string; filter: MetricsFilterInput };
}) {
  const { data, isError } = useGraphQL(networkUplinkQualityQuery, params, {
    useErrorBoundary: false,
  });

  if (uplinkDevices.length === 0) return null;

  return (
    <>
      {uplinkDevices.flatMap(([virtualDeviceUUID, uplinks]) => (
        <ChartGrid key={`uplink-quality-charts-${virtualDeviceUUID}`}>
          {uplinks
            .filter(
              // Doing this filtering here so we know how many total security appliances there are
              (uplink) =>
                uplink.virtualDevice.__typename === 'ControllerVirtualDevice' &&
                (!uplink.virtualDevice.highAvailability ||
                  uplink.virtualDevice.highAvailability.status === HighAvailabilityStatus.Active),
            )
            .map((uplink) => (
              <ISPQualityChart
                key={`${uplink.UUID}-quality`}
                currentUplinkPhyInteface={uplink.UUID}
                uplinkName={getPhyInterfaceLabel(uplink, uplinkDevices.length > 1)}
                data={data}
                isError={isError}
              />
            ))}
        </ChartGrid>
      ))}
    </>
  );
}

function UplinksThroughputCharts({
  uplinkDevices,
  params,
}: {
  uplinkDevices: [string, [Uplink, ...Uplink[]]][];
  params: { networkUUID: string; filter: MetricsFilterInput };
}) {
  const { data, isError } = useGraphQL(networkUplinkThroughputQuery, params, {
    useErrorBoundary: false,
  });

  if (uplinkDevices.length === 0) return null;

  return (
    <>
      {uplinkDevices.flatMap(([virtualDeviceUUID, uplinks]) => (
        <ChartGrid key={`uplink-throughput-chart-${virtualDeviceUUID}`}>
          {uplinks
            .filter(
              // Doing this filtering here so we know how many total security appliances there are
              (uplink) =>
                uplink.virtualDevice.__typename === 'ControllerVirtualDevice' &&
                (!uplink.virtualDevice.highAvailability ||
                  uplink.virtualDevice.highAvailability.status === HighAvailabilityStatus.Active),
            )
            .map((uplink) => (
              <UplinkThroughputChart
                key={`${uplink.UUID}-throughput`}
                currentUplinkPhyInteface={uplink.UUID}
                uplinkName={getPhyInterfaceLabel(uplink, uplinkDevices.length > 1)}
                data={data}
                isError={isError}
              />
            ))}
        </ChartGrid>
      ))}
    </>
  );
}

export default function InsightsNetwork() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const insightsCrumbs = useInsightsCrumbs();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const queryClient = useQueryClient();
  const activeController = useActiveControllerForNetwork(network);
  const flags = useFeatureFlags();
  const ispQualityEnabled = useNosFeatureEnabled(NosFeature.ISP_QUALITY);

  const hasSpeedTest =
    flags['dashboard-speed-test'] &&
    activeController &&
    activeController?.hardwareDevice?.serialNumber;
  const speedTestDialog = useDialogState();

  const hasPingTest = isOperator || flags['controller-ping-ui'];
  const pingTestDialog = useDialogState();

  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const params = useMemo(
    () => ({
      networkUUID: network.UUID,
      filter: {
        durationSeconds: getDurationSeconds(currentTimePeriod),
        stepSeconds: getStep(currentTimePeriod),
      },
    }),
    [currentTimePeriod, network],
  );

  const refreshQueries = useCallback(() => {
    queryClient.invalidateQueries(makeQueryKey(activeClientsQuery, params));
    queryClient.invalidateQueries(makeQueryKey(networkUplinkQualityQuery, params));
    queryClient.invalidateQueries(makeQueryKey(networkUplinkThroughputQuery, params));
  }, [params, queryClient]);

  const allUplinks = useGraphQL(uplinkPhyInterfacesQuery, {
    networkUUID: network.UUID,
  })?.data?.uplinkPhyInterfacesForNetwork;

  const uplinks = useMemo(
    () =>
      allUplinks?.filter(
        (phyInterface) =>
          phyInterface.isEnabled && phyInterface.virtualDevice.hardwareDevice?.isActive,
      ),
    [allUplinks],
  );

  const uplinkDevices = useMemo(() => getUplinkDevices(uplinks ?? []), [uplinks]);

  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermNetworkMetricsRead))
      }
      should404OnAccessDenied
    >
      <Pane layoutMode="detailed">
        <PaneHeader
          back={back}
          home={home}
          crumbs={[
            ...insightsCrumbs,
            {
              type: 'page',
              page: {
                as: ReactRouterLink,
                to: makeLink(paths.pages.InsightsNetworkPage, {
                  companyName,
                  networkSlug: network.slug,
                }),
                selected: true,
                label: 'Network',
              },
            },
          ]}
          icon="network"
          heading="Network"
          actions={
            (hasSpeedTest || hasPingTest) && (
              <>
                <DropdownMenu>
                  <DropdownMenuButton arrangement="leading-icon" icon="toolbox">
                    Tools
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      {hasSpeedTest && (
                        <DropdownMenuItem onSelect={speedTestDialog.state.open} icon="speed-medium">
                          Speed
                        </DropdownMenuItem>
                      )}
                      {hasPingTest && (
                        <DropdownMenuItem onSelect={pingTestDialog.state.open} icon="ping" internal>
                          Ping
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
                {hasSpeedTest && <SpeedTestDialog state={speedTestDialog.state} />}
                {hasPingTest && <PingTestDialog state={pingTestDialog.state} />}
              </>
            )
          }
          views={
            <FilterBar>
              <DropdownMenu>
                <DropdownMenuButton
                  variant="secondary"
                  arrangement="leading-icon"
                  icon="clock"
                  size="small"
                >
                  {timePeriodLabel(currentTimePeriod)}
                </DropdownMenuButton>
                <DropdownMenuPopover>
                  <DropdownMenuRadioGroup
                    value={currentTimePeriod}
                    onValueChange={(val) => setCurrentTimePeriod(val as string)}
                  >
                    <DropdownMenuRadioItem value="1h">
                      {timePeriodLabel('1h')}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="6h">
                      {timePeriodLabel('6h')}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="24h">
                      {timePeriodLabel('24h')}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="7d">
                      {timePeriodLabel('7d')}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="30d">
                      {timePeriodLabel('30d')}
                    </DropdownMenuRadioItem>
                    {isOperator && flags['wan-throughput-lookback'] && (
                      <>
                        <DropdownMenuRadioItem internal value="3m">
                          {timePeriodLabel('3m')}
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem internal value="6m">
                          {timePeriodLabel('6m')}
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem internal value="9m">
                          {timePeriodLabel('9m')}
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem internal value="1y">
                          {timePeriodLabel('1y')}
                        </DropdownMenuRadioItem>
                      </>
                    )}
                  </DropdownMenuRadioGroup>
                </DropdownMenuPopover>
              </DropdownMenu>
              <Button
                variant="secondary"
                arrangement="hidden-label"
                icon="arrows-rotate"
                onClick={refreshQueries}
                size="small"
              >
                Refresh
              </Button>
            </FilterBar>
          }
        />
        <PaneContent gutter="vertical">
          <Suspense
            fallback={
              <Box padding={{ x: space(16) }}>
                <VStack spacing={space(12)}>
                  <Skeleton height={24} width="100%" radius={6} />
                  <Skeleton height={200} width="100%" radius={6} />
                  <Skeleton height={200} width="100%" radius={6} />
                  <Skeleton height={200} width="100%" radius={6} />
                </VStack>
              </Box>
            }
          >
            <Sections>
              <NetworkStats />
              <ActiveClientsChart />
              {ispQualityEnabled && (
                <UplinksQualityCharts uplinkDevices={uplinkDevices} params={params} />
              )}
              <UplinksThroughputCharts uplinkDevices={uplinkDevices} params={params} />
            </Sections>
          </Suspense>
        </PaneContent>
      </Pane>
    </IsPermitted>
  );
}

import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  space,
  VStack,
} from '@meterup/atto';
import React from 'react';

import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { DrawerFormSaveButton } from '../../form_buttons';

export function ConfirmNetworkChangesDrawer({ onCancel }: { onCancel: () => void }) {
  return (
    <Drawer>
      <DrawerHeader heading="Confirm changes" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <Alert
          icon="information"
          variant="alternative"
          heading="Changing network settings"
          copy={
            <VStack spacing={space(8)}>
              <div>
                Changing this network setting will restart the network. All clients will lose
                connectivity for approximately 30 seconds.
              </div>
              <div>Please confirm you would like to apply these changes now.</div>
            </VStack>
          }
        />
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button type="button" onClick={onCancel} variant="secondary" autoFocus>
              Cancel
            </Button>
            <DrawerFormSaveButton />
          </>
        }
      />
    </Drawer>
  );
}

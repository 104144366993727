import type { IconName } from '@meterup/atto';
import {
  colors,
  darkThemeSelector,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FocusRing,
  fontWeights,
  HorizontalScrollShadow,
  Icon,
  Shortcut,
  sizing,
  Small,
  styled,
  useDialogState,
  useHorizontalShadowOnScroll,
  useViewport,
} from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { makeQueryKey } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { paths } from '../../../../constants';
import { useCompany } from '../../../../hooks/useCompany';
import { useNetworkOrNull } from '../../../../hooks/useNetworkFromPath';
import { networksForCompany } from '../../../../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';
import { CreateNetworkDialog } from '../Network/NetworkDetails';
import { useWorkbenchSide } from '../utils/useWorkbenchSide';
import { WorkbenchPane, WorkbenchPaneContent, WorkbenchPaneHeader } from '../WorkbenchPane';
import ToolbarSideToggle from './ToolbarSideToggle';

const WorkbenchToolbarTargetIcon = styled(Icon, {
  width: '$12',
  height: '$12',
  color: '$$iconColor',
});

const WorkbenchToolbarTargetLabel = styled(Small, {
  width: '100%',
  fontWeight: fontWeights.bold,
  color: '$$labelColor',
  whiteSpace: 'nowrap',
  textAlign: 'left',

  [darkThemeSelector]: {
    color: '$$labelColor',
  },
});

const WorkbenchToolbarTargetButton = styled('button', FocusRing, {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
  width: '100%',
  cursor: 'pointer',
  $$labelColor: colors.bodyNeutralLight,
  $$iconColor: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    $$labelColor: colors.bodyNeutralDark,
    $$iconColor: colors.bodyNeutralDark,
  },

  '@notDesktop': {
    padding: '$4 $6',
    borderRadius: '$8',
  },

  '@desktop': {
    padding: '$2 $4',
    borderRadius: '$6',
  },

  [selectors.hover]: {
    zIndex: 2,
    backgroundColor: colors.bgNeutralLight,
    $$labelColor: colors.headingNeutralLight,
    $$iconColor: colors.bodyNeutralLight,
    strokeAll: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.bgNeutralDark,
      $$labelColor: colors.headingNeutralDark,
      $$iconColor: colors.bodyNeutralDark,
      strokeAll: colors.strokeNeutralDark,
    },
  },

  [selectors.focus]: {
    zIndex: 4,
  },

  variants: {
    selected: {
      true: {
        zIndex: 3,
        [`&, ${selectors.hover}`]: {
          $$labelColor: colors.headingBrandLight,
          $$iconColor: colors.bodyBrandLight,
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,

          [darkThemeSelector]: {
            $$labelColor: colors.headingBrandDark,
            $$iconColor: colors.bodyBrandDark,
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
      false: {},
    },
  },
});

const WorkbenchToolbarTargetLI = styled('li', {
  variants: {
    side: {
      bottom: {
        width: '100%',
      },
      end: {},
    },
  },
});

export interface WorkbenchToolbarTargetProps {
  icon?: IconName;
  label: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  shortcut?: React.ReactNode[];
}

function WorkbenchToolbarTarget({
  icon,
  label,
  onClick,
  selected,
  shortcut,
}: WorkbenchToolbarTargetProps) {
  return (
    <WorkbenchToolbarTargetLI role="menu-item">
      <WorkbenchToolbarTargetButton aria-label={`${label}`} onClick={onClick} selected={selected}>
        {icon && <WorkbenchToolbarTargetIcon icon={icon} />}
        <WorkbenchToolbarTargetLabel>{label}</WorkbenchToolbarTargetLabel>
        {shortcut && <Shortcut keys={shortcut} />}
      </WorkbenchToolbarTargetButton>
    </WorkbenchToolbarTargetLI>
  );
}

const WorkbenchToolbarTargetsScroll = styled('ul', HorizontalScrollShadow, {
  minWidth: 0,
  display: 'flex',
  flex: 1,
  gap: '$2',
  height: '100%',
  padding: `$6 ${sizing.contentSides}`,

  variants: {
    side: {
      bottom: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
      end: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    },
  },
});

const WorkbenchToolbarTargets = styled('div', {
  minWidth: 0,
  position: 'relative',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
  overflow: 'hidden',
});

const WorkbenchToolbarContainer = styled('div', {
  display: 'flex',
  variants: {
    side: {
      bottom: {
        width: '$180',
        minWidth: '$180',
        strokeRight: colors.strokeApplicationLight,

        [darkThemeSelector]: {
          strokeRight: colors.strokeApplicationDark,
        },
      },
      end: {
        strokeBottom: colors.strokeApplicationLight,

        [darkThemeSelector]: {
          strokeBottom: colors.strokeApplicationDark,
        },
      },
    },
  },
});

export enum ValidWorkbenchToolbarTargets {
  Inspector = 'inspector',
  Network = 'network',
}

export default function WorkbenchToolbar({
  target,
  setTarget,
  sideToggle,
}: {
  target: ValidWorkbenchToolbarTargets;
  setTarget: (target: ValidWorkbenchToolbarTargets) => void;
  sideToggle: () => void;
}) {
  const { breakpoint } = useViewport();
  const companySlug = useCurrentCompany();
  const company = useCompany(companySlug);
  const network = useNetworkOrNull();

  const {
    getBoxShadow: getTargetsBoxShadow,
    onScrollHandler: onTargetsScrollHandler,
    handleTargetChange: handleTargetsTargetChange,
  } = useHorizontalShadowOnScroll('both');
  const targetsRef = useRef<HTMLUListElement>(null);

  const handleResizeBottom = useCallback(() => {
    if (targetsRef.current) {
      handleTargetsTargetChange(targetsRef.current);
    }
  }, [handleTargetsTargetChange]);

  useEffect(() => {
    handleResizeBottom();
    window.addEventListener('resize', handleResizeBottom);
    return () => {
      window.removeEventListener('resize', handleResizeBottom);
    };
  }, [handleResizeBottom]);

  const workbenchSide = useWorkbenchSide();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { state: createNetworkDialogState } = useDialogState();

  const handleCreate = useCallback(
    (resp: { slug: string }) => {
      queryClient.invalidateQueries(makeQueryKey(networksForCompany, { companySlug })).then(() => {
        navigate(
          makeLink(paths.pages.SettingsNetworkGeneralPage, {
            companyName: companySlug,
            networkSlug: resp.slug,
          }),
        );
      });
    },
    [companySlug, navigate, queryClient],
  );

  return (
    <>
      <WorkbenchToolbarContainer side={workbenchSide}>
        <WorkbenchPane>
          <WorkbenchPaneHeader
            icon="workbench"
            heading="Workbench"
            actions={
              <>
                {!company?.isCustomer && (
                  <DropdownMenu>
                    <DropdownMenuButton
                      arrangement="hidden-label"
                      icon="overflow-horizontal"
                      size="x-small"
                    >
                      Actions
                    </DropdownMenuButton>
                    <DropdownMenuPopover>
                      <DropdownMenuGroup>
                        <DropdownMenuItem icon="plus" onClick={createNetworkDialogState.open}>
                          Add network
                        </DropdownMenuItem>
                      </DropdownMenuGroup>
                    </DropdownMenuPopover>
                  </DropdownMenu>
                )}
                {breakpoint !== 'mobile' && <ToolbarSideToggle onClick={sideToggle} />}
              </>
            }
          />
          <WorkbenchPaneContent gutter="none">
            {network && (
              <WorkbenchToolbarTargets>
                <WorkbenchToolbarTargetsScroll
                  onScroll={onTargetsScrollHandler}
                  ref={targetsRef}
                  {...getTargetsBoxShadow}
                  side={workbenchSide}
                >
                  <WorkbenchToolbarTarget
                    onClick={() => setTarget(ValidWorkbenchToolbarTargets.Network)}
                    selected={target === ValidWorkbenchToolbarTargets.Network}
                    icon="network"
                    label="Network"
                  />
                  <WorkbenchToolbarTarget
                    onClick={() => setTarget(ValidWorkbenchToolbarTargets.Inspector)}
                    selected={target === ValidWorkbenchToolbarTargets.Inspector}
                    icon="inspector"
                    label="Inspector"
                  />
                </WorkbenchToolbarTargetsScroll>
              </WorkbenchToolbarTargets>
            )}
          </WorkbenchPaneContent>
        </WorkbenchPane>
      </WorkbenchToolbarContainer>
      <CreateNetworkDialog state={createNetworkDialogState} onSuccess={handleCreate} />
    </>
  );
}

import type { api } from '@meterup/proto';
import { uniq } from 'lodash-es';

export function formatRuleContentName(d: api.ContentFilterRule) {
  return d.category?.name ?? d.domain ?? '';
}

export function allowedDomainsRule(
  rules: api.ContentFilterRule[],
): api.ContentFilterRule | undefined {
  return rules.find((r) => r.precedence === 0);
}

export function blockedDomainsRule(
  rules: api.ContentFilterRule[],
): api.ContentFilterRule | undefined {
  return rules.find((r) => r.precedence === 1);
}

export function categoryRules(rules: api.ContentFilterRule[]): api.ContentFilterRule[] {
  const categories = rules.filter((r) => r.precedence >= 2);
  return categories ?? [];
}

export function processDomainList(list: string[], newDomains?: string): string[] {
  const newList = [...list];
  if (newDomains) {
    newList.push(
      ...newDomains
        .split(',')
        .map((d) => d.trim())
        .filter((d) => d !== ''),
    );
  }
  return uniq(newList.map((d) => d.toLowerCase()));
}

import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { PermissionType } from '../../../../gql/graphql';
import { usePermissions } from '../../../../providers/PermissionsProvider';
import { AccessPointQuery, DeadMansSnitchQuery } from '../../../Wireless/utils';
import { InspectorConfig } from './Units/InspectorConfig';
import { InspectorMetadata } from './Units/InspectorMetadata';
import { InspectorMetricsStats } from './Units/InspectorMetricsStats';

export default function InspectorAccessPoint({ uuid }: { uuid: string }) {
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const accessPointData = useGraphQL(AccessPointQuery, {
    uuid,
    includeUptime,
  }).data;
  expectDefinedOrThrow(accessPointData, new ResourceNotFoundError('Access point not found'));
  expectDefinedOrThrow(
    accessPointData.virtualDevice,
    new ResourceNotFoundError('Virtual device not found'),
  );

  const serialNumber = accessPointData.virtualDevice.hardwareDevice?.serialNumber || '';
  const deadMansToken = useGraphQL(
    DeadMansSnitchQuery,
    { serialNumber },
    { enabled: !!serialNumber, useErrorBoundary: false },
  ).data?.deviceHeartbeatForDevice?.token;
  if (
    accessPointData.virtualDevice.hardwareDevice &&
    accessPointData.virtualDevice.hardwareDevice.__typename !== 'AccessPointHardwareDevice'
  )
    return null;
  if (
    !accessPointData.virtualDevice ||
    accessPointData.virtualDevice.__typename !== 'AccessPointVirtualDevice'
  )
    return null;

  const lastBootHistoryBuildName =
    accessPointData.virtualDevice.hardwareDevice?.bootHistory?.[0]?.buildName;

  return (
    <>
      <InspectorMetadata
        hardwareDevice={accessPointData.virtualDevice.hardwareDevice}
        virtualDevice={accessPointData.virtualDevice}
        lastBootHistoryBuildName={lastBootHistoryBuildName}
        deadMansToken={deadMansToken}
      />
      {accessPointData.virtualDevice.hardwareDevice && (
        <>
          <InspectorMetricsStats virtualDeviceUUID={accessPointData.virtualDevice.UUID} />
          <InspectorConfig
            serialNumber={accessPointData.virtualDevice.hardwareDevice.serialNumber}
          />
        </>
      )}
    </>
  );
}

import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';

import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import ReportForm from './ReportForm';

export default function ReportsAdd() {
  const closeDrawer = useCloseDrawerCallback();

  return (
    <Drawer>
      <DrawerHeader icon="plus" heading="Add report" onClose={closeDrawer} />
      <DrawerContent>
        <ReportForm />
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </>
        }
      />
    </Drawer>
  );
}

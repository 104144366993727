import { Drawer, DrawerHeader } from '@meterup/atto';

import VLANForm from '../../../../../components/NetworkWide/VLANs/VLANForm';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/vlans/add',
  title: 'Add VLAN - VLANs - Network-wide',
});

export default function VLANsAddPage() {
  const closeDrawer = useCloseDrawerCallback();
  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermVlanWrite) &&
        (isOperator || !!ldFlags['edit-vlan'])
      }
      path={Meta().path}
      allowOperatorAlways={false}
      should404OnAccessDenied
    >
      <Drawer>
        <DrawerHeader icon="vlan" heading="Add VLAN" onClose={closeDrawer} />
        <VLANForm />
      </Drawer>
    </IsPermitted>
  );
}

import { ErrorBoundary } from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React, { Suspense } from 'react';

import type { ErrorFallbackProps } from './ErrorFallback/utils';
import { Nav } from '../nav';
import { ErrorFallback, ErrorFallbackDrawer } from './ErrorFallback/ErrorFallback';
import { DetailLoadingFallback } from './Placeholders/DetailLoadingFallback';
import { DrawerLoadingFallback } from './Placeholders/DrawerLoadingFallback';

function ErrorFallbackDrawerInContainer(props: ErrorFallbackProps) {
  return <ErrorFallbackDrawer {...props} />;
}

export function MainAndDrawerLayout({
  main,
  drawer,
}: {
  main: React.ReactNode;
  drawer?: React.ReactNode;
}) {
  const location = Nav.useRegionLocation('root');
  const drawerLocation = Nav.useRegionLocation('drawer');
  return (
    <>
      <QueryErrorResetBoundary
        // TRICKY: Pass key to the error boundary to force re-render when route changes
        key={location.pathname}
      >
        {({ reset }) => (
          <ErrorBoundary onReset={reset} fallback={ErrorFallback}>
            <Suspense fallback={<DetailLoadingFallback />}>{main}</Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
      <ErrorBoundary key={drawerLocation?.pathname ?? ''} fallback={ErrorFallbackDrawerInContainer}>
        <Suspense fallback={<DrawerLoadingFallback />}>{drawer}</Suspense>
      </ErrorBoundary>
    </>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { AccessPointSummaryDrawer } from '../../../../../components/Hardware/AccessPoints/AccessPointSummary';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/devices/:deviceName',
});

export default function AccessPointSummary() {
  return <AccessPointSummaryDrawer />;
}

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Button,
  ComboBox,
  ComboBoxItem,
  CompositeField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Pane,
  PaneContent,
  PaneHeader,
  PrimaryFieldComposite,
  space,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, useGraphQLMutation } from '@meterup/graphql';
import { useCallback, useEffect, useState } from 'react';

import type { NosFirmwareVersionsHookValues } from './hooks/useNosFirmwareVersions';
import type { NetworkByFilter } from './utils';
import { graphql } from '../../gql';
import { pluralize } from '../../utils/strings';
import { Box } from '../Box';
import { useNosFirmwareVersions } from './hooks/useNosFirmwareVersions';
import { NetworksPreviewList } from './NetworksPreviewList';

const CreateNOSFirmwareUpgradeMutation = graphql(`
  mutation CreateNOSFirmwareUpgradeMutation($input: CreateBulkNOSUpgradeInput!) {
    createBulkNOSUpgrade(input: $input) {
      UUID
    }
  }
`);

function ConfirmBulkUpgradeDialog({
  dialogState,
  selectedNetworks,
  nosVersionIdMap,
  nosVersionID,
}: {
  dialogState: OverlayTriggerState;
  selectedNetworks?: NetworkByFilter[];
  nosVersionIdMap: NosFirmwareVersionsHookValues['nosVersionIdMap'];
  nosVersionID?: string;
}) {
  const [confirm, setConfirm] = useState('');
  const nosVersionAsInt = parseInt(nosVersionID ?? '', 10);
  const selectedNosVersion = nosVersionID ? nosVersionIdMap[nosVersionAsInt] : null;
  const handleOnClose = useCallback(() => {
    dialogState.close();
  }, [dialogState]);
  useEffect(() => {
    if (!dialogState.isOpen) {
      setConfirm('');
    }
  }, [dialogState.isOpen]);
  const firmwareUpgrade = useGraphQLMutation(CreateNOSFirmwareUpgradeMutation);
  const handleSubmit = useCallback(() => {
    if (!nosVersionID) return;
    firmwareUpgrade.mutate(
      {
        input: {
          nosVersionID: nosVersionAsInt,
          networkUUIDs: selectedNetworks?.map((net) => net.UUID) ?? [],
        },
      },
      {
        onSuccess() {
          notify('Successfully created bulk upgrade.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem creating the bulk upgrade${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
        onSettled() {
          dialogState.close();
        },
      },
    );
  }, [firmwareUpgrade, nosVersionID, nosVersionAsInt, dialogState, selectedNetworks]);
  return (
    <Dialog maxWidth="800px" state={dialogState}>
      <DialogHeader heading="Network firmware upgrade bulk action" icon="warning" />
      <DialogContent gutter="all">
        {selectedNetworks && selectedNetworks?.length > 0 && selectedNosVersion ? (
          <Pane>
            <PaneHeader
              actions={
                <Box css={{ paddingBottom: space(16) }}>
                  <Alert
                    copy={`You have selected ${selectedNetworks?.length} ${pluralize(selectedNetworks?.length ?? 0, 'network', 'networks')}\n
                    to be upgraded to NOS version: ${selectedNosVersion?.version}(${selectedNosVersion.major}.${selectedNosVersion.minor}.${selectedNosVersion.patch}).\n
                     Performing this action will schedule an upgrade for each of the selected networks within their next available upgrade schedule window.\n
                     You will not be able to delete/cancel a bulk upgrade that has non-PENDING scheduled upgrades.
                     Are you sure you want to proceed?`}
                    icon="warning"
                    variant="negative"
                    internal
                  />
                </Box>
              }
            />
            <PaneContent gutter="none">
              <NetworksPreviewList networks={selectedNetworks} />
            </PaneContent>
          </Pane>
        ) : null}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <CompositeField
              label='Type "CONFIRM" to submit'
              element={
                <TextInput
                  aria-label='Type "CONFIRM" to submit'
                  onChange={setConfirm}
                  placeholder='Type "CONFIRM"'
                  value={confirm}
                />
              }
            />
            <Button disabled={confirm !== 'CONFIRM'} onClick={handleSubmit}>
              Submit bulk upgrade
            </Button>
            <Button variant="secondary" onClick={handleOnClose}>
              Cancel
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

export function BulkUpgraderConfirmForm({
  selectedNetworks,
}: {
  selectedNetworks: NetworkByFilter[];
}) {
  const { nosVersions, nosVersionIdMap } = useNosFirmwareVersions();
  const { state: dialogState } = useDialogState();
  const [nosVersionID, setNosVersionID] = useState<string>();
  return (
    <VStack spacing={space(16)}>
      <PrimaryFieldComposite
        label="Target firmware version"
        fields={
          <CompositeField
            label="Target firmware version"
            element={
              <ComboBox
                onValueChange={setNosVersionID}
                value={nosVersionID}
                size="large"
                placeholder="Select target firmware version"
              >
                {nosVersions.map((nos) => (
                  <ComboBoxItem key={`${nos.id}`}>{nos.version}</ComboBoxItem>
                ))}
              </ComboBox>
            }
          />
        }
      />
      <Button disabled={!nosVersionID || selectedNetworks.length === 0} onClick={dialogState.open}>
        Submit bulk upgrade
      </Button>
      <ConfirmBulkUpgradeDialog
        nosVersionID={nosVersionID}
        nosVersionIdMap={nosVersionIdMap}
        selectedNetworks={selectedNetworks}
        dialogState={dialogState}
      />
    </VStack>
  );
}

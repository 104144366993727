import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import LegacySwitchList from '../../../../../components/Hardware/Switches/LegacySwitchList';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/hardware/switches',
  layout: 'NetworkLayout',
});

export default function LegacySwitchListPage() {
  return <LegacySwitchList />;
}

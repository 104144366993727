import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';

export function useCurrentAccessPointParams() {
  const accessPointDetail = Nav.useRegionParams('root', paths.pages.AccessPointPage);
  const accessPointDrawer = Nav.useRegionParams('drawer', paths.drawers.AccessPointDrawerPage);
  return accessPointDetail ?? accessPointDrawer;
}

export function useCurrentPowerDistributionUnitParams() {
  const powerDistributionUnitDetail = Nav.useRegionParams(
    'root',
    paths.pages.PowerDistributionUnitDetailPage,
  );
  const powerDistributionUnitDrawer = Nav.useRegionParams(
    'drawer',
    paths.drawers.PowerDistributionUnitSummaryPage,
  );
  return powerDistributionUnitDetail ?? powerDistributionUnitDrawer;
}

export function useCurrentSecurityApplianceParams() {
  const securityApplianceDetail = Nav.useRegionParams(
    'root',
    paths.pages.SecurityApplianceDetailPage,
  );
  const securityApplianceDrawer = Nav.useRegionParams(
    'drawer',
    paths.drawers.SecurityApplianceSummaryPage,
  );
  return securityApplianceDetail ?? securityApplianceDrawer;
}

export function useCurrentSwitchParams() {
  const switchDetail = Nav.useRegionParams('root', paths.pages.SwitchDetailPage);
  const switchDrawer = Nav.useRegionParams('drawer', paths.drawers.SwitchSummaryPage);
  return switchDetail ?? switchDrawer;
}

export function useCurrentDeviceParams() {
  const isAccessPoint = useCurrentAccessPointParams();
  const isPowerDistributionUnit = useCurrentPowerDistributionUnitParams();
  const isSecurityAppliance = useCurrentSecurityApplianceParams();
  const isSwitch = useCurrentSwitchParams();

  return isAccessPoint ?? isPowerDistributionUnit ?? isSecurityAppliance ?? isSwitch;
}

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-cycle */
import React, { useCallback, useMemo, useState } from 'react';

import { Button, HStack, Small, space } from '@meterup/atto';
import { CompanyContractsResponse } from '@meterup/connect-api';
import { DataView, Drawer, OnClickRowProps, OpenDrawerLink } from '@meterup/connect-ui';
import { useSafeNavigate } from '@meterup/connect-ui/src/components/hooks/useSafeNavigate';
import { WidthMarker } from '@meterup/connect-ui/src/components/Table/styles';
import StatusTabBar from '@meterup/connect-ui/src/components/Tabs/StatusTabBar';
import { useSimpleCellSizing } from '@meterup/connect-ui/src/DataView/hooks/useCellSizings';
import useActiveStatusTab, {
  StatusTabItems,
  StatusTabItemValues,
} from '@meterup/connect-ui/src/hooks/useActiveStatusTab';
import useProcessedContracts from '@meterup/connect-ui/src/hooks/useProcessedContracts';
import { Cell, Column, Row } from '@tanstack/react-table';
import { isNaN, size } from 'lodash-es';
import { useOutlet, useParams } from 'react-router-dom';

import type { ContractForDisplayType } from '../../utils/ContractForDisplayType';
import { defaultMakeRoute } from '../../utils/defaultMakeRoute';
import type { RoutingFunction } from '../../utils/routes';
import AddLocationTableInput from './AddLocationTableInput';
import {
  getQuoteSelectionStage,
  isPreSelectionStage,
  RenderCentsCell,
  RenderDateCell,
  tableColumnNames,
  tableColumns,
} from './LocationsTableColumns';
import NoLocations from './NoLocations';

function statusCellSizingFn(els: Element[]) {
  if (size(els) === 0) {
    return 100;
  }

  const [firstNode] = els;

  const childWidths = els.map((el) => {
    const widthWrapper = el.querySelector(WidthMarker.toString());
    if (!widthWrapper) {
      return 0;
    }
    return widthWrapper.getBoundingClientRect().width;
  });

  const computedStyle = window.getComputedStyle(firstNode);
  const paddingLeft = parseInt(computedStyle.paddingLeft || '0', 10);
  const paddingRight = parseInt(computedStyle.paddingRight || '0', 10);
  return Math.max(100, ...childWidths) + paddingLeft + paddingRight;
}

type FormCellBuilderProps = {
  id: string;
  column: Column<ContractForDisplayType>;
  cell: () => React.ReactNode;
};

function formCellBuilder({ cell, column, id }: FormCellBuilderProps) {
  return {
    id: `final-row-form:cell:${id}`,
    column: {
      id: `final-row-form:col:${id}`,
      columnDef: {
        aggregatedCell: true,
        cell,
      },
      getSize() {
        return column.getSize();
      },
    },
    getContext() {
      return {};
    },
    getIsAggregated() {
      return false;
    },
  };
}

type LocationsDisplayPropsType = {
  buttons?: React.ReactNode;
  contracts: CompanyContractsResponse[];
  // makeURL?: <T extends Record<string, any>>(name: string, opts: MakeURLPropsType) => string;
  hidden?: string[];
  makeURL?: RoutingFunction;
};

export default function LocationsDisplay({
  buttons: buttonsProp,
  contracts,
  hidden,
  makeURL: makeURLProp,
}: LocationsDisplayPropsType) {
  const [searchValue, setSearchValue] = useState('');
  const makeURL = makeURLProp || defaultMakeRoute;
  const buttons = useMemo(
    () =>
      buttonsProp || (
        <OpenDrawerLink path="add">
          <Button
            icon="plus-circle"
            variant="secondary"
            arrangement="leading-icon"
            size="medium"
            type="button"
          >
            Bulk add
          </Button>
        </OpenDrawerLink>
      ),
    [buttonsProp],
  );

  const activeTab = useActiveStatusTab();
  const { companyName, companySID: companySIDInner } = useParams();
  const companySID = companySIDInner || companyName || '';
  const { counts, rawRows: originalRawRows } = useProcessedContracts({
    activeFilterType: activeTab,
    companySID,
    contracts,
    searchValue,
  });
  const safeNavigate = useSafeNavigate();
  const onClickRow = useCallback(
    (row: OnClickRowProps<ContractForDisplayType>) => {
      const result = getQuoteSelectionStage(row.record);
      const {
        record: { companyLocation },
      } = row;
      const navigateProps = {
        href: makeURL({
          name: 'connection',
          params: { companySID, companyLocationSID: companyLocation.sid },
        }),
      };
      if (isPreSelectionStage(result)) {
        navigateProps.href = makeURL({
          name: 'results',
          params: result,
        });
      }

      safeNavigate(navigateProps.href);
    },
    [companySID, makeURL, safeNavigate],
  );
  const addressCellSizingFn = useSimpleCellSizing('span', 'div');
  const children = useOutlet();
  const totals = useMemo(() => {
    const companyLocations = {};
    const providers = {};
    let nextRenewalDate = null;
    let totalCostCents = 0;
    const newRawRows = new Array<ContractForDisplayType>(originalRawRows.length);
    originalRawRows.forEach((row, i) => {
      const providerSID = row.contract.internetServicePlan?.providerName;
      const renewalDateStr = row.contract.internetServicePlan?.renewalDate;
      // @ts-ignore
      const renewalDate = new Date(renewalDateStr);
      const monthlyCost = row.contract.internetServicePlan?.monthlyCostCents;
      // @ts-ignore
      companyLocations[row.companyLocation.sid] = true;
      if (providerSID && providerSID.toLowerCase() !== 'provider') {
        // @ts-ignore
        providers[providerSID] = true;
      }
      if (renewalDate.getTime() > 0) {
        nextRenewalDate = renewalDateStr;
      }
      if (!isNaN(Number(monthlyCost))) {
        totalCostCents += monthlyCost || 0;
      }
      newRawRows[i] = {
        ...row,
        routingFn: makeURL,
      };
    });
    const numCompanyLocations = Object.keys(companyLocations).length;
    return {
      companyLocations: numCompanyLocations,
      nextRenewalDate,
      providers: Object.keys(providers).length,
      rawRows: newRawRows.map((row) => ({ ...row, numCompanyLocations })),
      totalCostCents,
    };
  }, [makeURL, originalRawRows]);
  const { rawRows } = totals;
  const finalRow = useMemo<Row<ContractForDisplayType>[]>(
    // @ts-ignore
    () => (columns?: Column<ContractForDisplayType>[]) => {
      if (!columns) {
        return null;
      }
      return {
        id: 'final-row-form',
        original: null,
        subRows: [],
        getVisibleCells(): Cell<ContractForDisplayType, unknown>[] {
          const finalRowCells = [
            {
              id: 'input',
              // @ts-ignore
              cell: AddLocationTableInput,
            },
            {
              id: 'total-locations',
              // @ts-ignore
              cell() {
                return <Small>Total locations: {totals.companyLocations}</Small>;
              },
            },
            {
              id: 'status',
              cell() {
                return null;
              },
            },
            {
              id: 'isps',
              cell() {
                return <Small>Unique: {totals.providers}</Small>;
              },
            },
            {
              id: 'product',
              cell() {
                return null;
              },
            },
            {
              id: 'speed',
              cell() {
                return null;
              },
            },
            {
              id: 'price',
              cell() {
                return null;
              },
            },
            {
              id: 'renewalDate',
              cell() {
                if (totals.nextRenewalDate) {
                  return <RenderDateCell value={totals.nextRenewalDate} />;
                }
                return null;
              },
            },
            {
              id: 'contractTerm',
              cell() {
                return null;
              },
            },
            {
              id: 'created',
              cell() {
                return null;
              },
            },
            // @ts-ignore
            {
              id: 'total',
              cell() {
                return (
                  <Small>
                    <HStack spacing={space(4)}>
                      Total: <RenderCentsCell value={totals.totalCostCents} />
                    </HStack>
                  </Small>
                );
              },
            },
          ];
          const result: Cell<ContractForDisplayType, unknown>[] = [];
          const h = hidden ?? [];
          finalRowCells.forEach((c) => {
            if (h.includes(c.id)) {
              return;
            }
            const i = result.length;
            result.push(
              // @ts-ignore
              formCellBuilder({
                id: c.id,
                column: columns[i],
                // @ts-ignore
                cell: c.cell,
              }),
            );
          });
          return result;
        },
      };
    },
    [hidden, totals],
  );

  if (!contracts || contracts.length === 0) {
    return <NoLocations />;
  }
  // const path = '.';
  const path = makeURL({
    name: 'dashboard',
    params: {
      companySID,
    },
  });

  return (
    <>
      <DataView
        cellSizing={{
          address: addressCellSizingFn,
          region: addressCellSizingFn,
          status: statusCellSizingFn,
        }}
        hidden={hidden}
        columns={tableColumns}
        data={rawRows}
        defaultSorting={[{ id: 'address', desc: false }]}
        onClickRow={onClickRow}
        grouping={[
          'address',
          'region',
          'status',
          'isp',
          'product',
          'bandwidth',
          'monthlyRate',
          'renewalDate',
          'contractTerm',
          'created',
        ]}
        lastColumnVariant="lastTdSticky"
        numLeadingStickyColumns={2}
        staticHeight
        noWrapColumns={tableColumnNames}
        lastColumnIsSticky
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        buttons={buttons}
        header={
          <StatusTabBar
            basePath={path}
            tabCount={Object.values(StatusTabItems).reduce(
              (acc, key) => ({ ...acc, [key]: counts[key as StatusTabItemValues] }),
              {} as Record<StatusTabItemValues, number>,
            )}
          />
        }
        // @ts-ignore
        finalRow={finalRow}
      >
        {/* <DataViewContent */}
        {/*  renderContent={(columnSizingState) => { */}
        {/*    console.log("RenderContent!", { columnSizingState, tableColumnNames }); */}
        {/*    return ( */}
        {/*      <HStack spacing={5} as={AddSingleLocation} key="add-single-loc-container"> */}
        {/*        {Object.entries(columnSizingState).map(([name, width]) => { */}
        {/*          return ( */}
        {/*            <AddSingleLocationElement */}
        {/*              key={`add-single-loc-${name}`} */}
        {/*              css={{ width, minWidth: width }}> */}
        {/*              <Text>Hello!</Text> */}
        {/*            </AddSingleLocationElement> */}
        {/*          ); */}
        {/*        })} */}
        {/*      </HStack> */}
        {/*    ); */}
        {/*  }} */}
        {/* /> */}
      </DataView>
      <Drawer>{children}</Drawer>
    </>
  );
}

LocationsDisplay.whyDidYouRender = true;

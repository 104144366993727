import { LoadingIcon, Sections, Small, space, styled } from '@meterup/atto';
import { GraphQLErrorBoundary } from '@meterup/graphql';
import { Suspense } from 'react';

import {
  useCurrentAccessPointParams,
  useCurrentDeviceParams,
  useCurrentPowerDistributionUnitParams,
  useCurrentSecurityApplianceParams,
  useCurrentSwitchParams,
} from '../utils/useCurrentDeviceParams';
import { useWorkbenchSide } from '../utils/useWorkbenchSide';
import { WorkbenchPane, WorkbenchPaneContent, WorkbenchPaneHeader } from '../WorkbenchPane';
import InspectorAccessPoint from './InspectorAccessPoint';
import InspectorPowerDistributionUnit from './InspectorPowerDistributionUnit';
import InspectorSecurityAppliance from './InspectorSecurityAppliance';
import InspectorSwitch from './InspectorSwitch';

const InspectorNoOp = styled(Small, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  fontWeight: '$bold',
});

function InspectorContent() {
  const accessPointDevice = useCurrentAccessPointParams();
  const powerDistributionUnitDevice = useCurrentPowerDistributionUnitParams();
  const securityApplianceDevice = useCurrentSecurityApplianceParams();
  const switchDevice = useCurrentSwitchParams();

  if (accessPointDevice) {
    return <InspectorAccessPoint uuid={accessPointDevice.uuid} />;
  }
  if (powerDistributionUnitDevice) {
    return <InspectorPowerDistributionUnit uuid={powerDistributionUnitDevice.uuid} />;
  }
  if (securityApplianceDevice) {
    return <InspectorSecurityAppliance uuid={securityApplianceDevice.uuid} />;
  }
  if (switchDevice) {
    return <InspectorSwitch uuid={switchDevice.uuid} />;
  }

  return <InspectorNoOp>Select a device in Dashboard</InspectorNoOp>;
}

function InspectorErrorFallback({ error }: { error: Error }) {
  return <InspectorNoOp>There was a problem loading device data: {error.message}</InspectorNoOp>;
}

export default function Inspector() {
  const device = useCurrentDeviceParams();
  const workbenchSide = useWorkbenchSide();

  return (
    <WorkbenchPane>
      {workbenchSide === 'bottom' && <WorkbenchPaneHeader icon="inspector" heading="Inspector" />}
      <GraphQLErrorBoundary fallback={InspectorErrorFallback} resetKey={device?.uuid}>
        <Suspense
          fallback={
            <WorkbenchPaneContent background="checkered" gutter="all">
              <InspectorNoOp>
                <LoadingIcon size={space(20)} />
              </InspectorNoOp>
            </WorkbenchPaneContent>
          }
        >
          <WorkbenchPaneContent
            background={device ? 'default' : 'checkered'}
            gutter={device ? 'vertical' : 'all'}
            spacing={space(12)}
          >
            <Sections>
              <InspectorContent />
            </Sections>
          </WorkbenchPaneContent>
        </Suspense>
      </GraphQLErrorBoundary>
    </WorkbenchPane>
  );
}

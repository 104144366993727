import {
  PrimaryField,
  Segment,
  Segments,
  Select,
  SelectItem,
  Textarea,
  TextInput,
} from '@meterup/atto';
import { useMemo, useState } from 'react';

import type { AddEditFormProps } from './CRUDList';
import type { DeviceType, Schemas } from './useOnboardingDocument';
import { AddEditForm } from './CRUDList';
import { deviceTypes, SCHEMAS } from './useOnboardingDocument';

const lst = [`IP address`, 'Network mask', 'Gateway', 'DNS server'].map((v) => `- ${v}`).join('\n');

const deviceNames = {
  camera: 'Camera',
  access_control: 'Access Control',
  conferencing_equipment: 'Conferencing Equipment',
  printer: 'Printer',
  phone: 'Phone',
  speaker: 'Speaker',
  other: 'Other',
};

type DeviceConfig = Schemas['DEVICE_CONFIG'];

type DeviceConfigState = {
  device_type: DeviceType | null;
  description: string;
  ip_assignment: 'dhcp' | 'static' | null;
  config_description: string;
  has_reservation: boolean | 'unsure' | null;
  ip_address: string;
  mac_address: string;
};

// converts DeviceConfig discriminated union to flat UI state
function configToState(config?: DeviceConfig): DeviceConfigState {
  const state: DeviceConfigState = {
    device_type: config?.device_type ?? null,
    description: config?.description ?? '',
    ip_assignment: null,
    // static
    config_description: '',
    // dhcp
    has_reservation: null,
    ip_address: '',
    mac_address: '',
  };

  if (!config) {
    return state;
  }

  if (config.ip_assignment === 'static') {
    state.ip_assignment = 'static';
    state.config_description = config.config_description;
    return state;
  }

  state.ip_assignment = 'dhcp';
  state.has_reservation = config.reservation.has_reservation;
  if (config.reservation.has_reservation === true) {
    state.ip_address = config.reservation.ip_address;
    state.mac_address = config.reservation.mac_address;
  }

  return state;
}

// converts flat UI state to DeviceConfig discriminated union
function stateToConfig(state: DeviceConfigState): DeviceConfig | null {
  if (state.ip_assignment === null) {
    return null;
  }
  if (state.device_type === null) {
    return null;
  }

  if (state.ip_assignment === 'static') {
    if (state.config_description.length === 0) {
      return null;
    }
    return {
      device_type: state.device_type,
      description: state.description,
      ip_assignment: 'static',
      config_description: state.config_description,
    };
  }

  if (!state.has_reservation) {
    return {
      device_type: state.device_type,
      description: state.description,
      ip_assignment: 'dhcp',
      reservation: {
        has_reservation: false,
      },
    };
  }

  if (state.ip_address.length === 0 || state.mac_address.length === 0) {
    return null;
  }

  return {
    device_type: state.device_type,
    description: state.description,
    ip_assignment: 'dhcp',
    reservation: {
      has_reservation: true,
      ip_address: state.ip_address,
      mac_address: state.mac_address,
    },
  };
}

export function AddEditDeviceForm({
  defaultValue,
  onSubmit,
  onCancel,
}: AddEditFormProps<DeviceConfig>) {
  const [state, setState] = useState<DeviceConfigState>(configToState(defaultValue));

  function patchState<Key extends keyof DeviceConfigState>(
    key: Key,
    value: DeviceConfigState[Key],
  ) {
    setState((prev) => ({ ...prev, [key]: value }));
  }

  const outputConfig = useMemo(() => stateToConfig(state), [state]);

  const isEditing = !!defaultValue;

  return (
    <AddEditForm
      isEditing={isEditing}
      canSubmit={outputConfig !== null}
      entityName="hardwired device"
      onSubmit={() => {
        if (outputConfig) {
          onSubmit(outputConfig);
        }
      }}
      onCancel={onCancel}
    >
      <PrimaryField
        label="Device type"
        element={
          <Select
            placeholder="Select device type"
            width="100%"
            value={state.device_type}
            onValueChange={(v) => {
              const parsed = SCHEMAS.DEVICE_TYPES.parse(v);
              patchState('device_type', parsed);
            }}
          >
            {deviceTypes.map((type) => (
              <SelectItem key={type}>{deviceNames[type]}</SelectItem>
            ))}
          </Select>
        }
      />
      <PrimaryField
        label="Description"
        element={
          <TextInput
            type="text"
            placeholder='e.g. "3rd floor printer" or "office Sonos"'
            value={state.description}
            onChange={(v) => patchState('description', v)}
          />
        }
      />
      <PrimaryField
        label="IP assignment"
        element={
          <Segments direction="column">
            <Segment
              active={state.ip_assignment === 'dhcp'}
              onClick={() => patchState('ip_assignment', 'dhcp')}
            >
              DHCP
            </Segment>
            <Segment
              active={state.ip_assignment === 'static'}
              onClick={() => patchState('ip_assignment', 'static')}
            >
              Static
            </Segment>
          </Segments>
        }
      />
      {state.ip_assignment === 'static' && (
        <PrimaryField
          label="Configuration details"
          description="Please provide IP address, network mask, gateway, and DNS server for all devices."
          element={
            <Textarea
              rows={5}
              placeholder={`Be sure to include:\n${lst}`}
              value={state.config_description}
              onChange={(e) => patchState('config_description', e.target.value)}
            />
          }
        />
      )}
      {state.ip_assignment === 'dhcp' && (
        <PrimaryField
          label="Is there a DHCP reservation?"
          element={
            <Segments direction="column">
              <Segment
                active={state.has_reservation === true}
                onClick={() => patchState('has_reservation', true)}
              >
                Yes
              </Segment>
              <Segment
                active={state.has_reservation === false}
                onClick={() => patchState('has_reservation', false)}
              >
                No
              </Segment>
            </Segments>
          }
        />
      )}
      {state.ip_assignment === 'dhcp' && state.has_reservation === true && (
        <>
          <PrimaryField
            label="IP address"
            element={
              <TextInput
                type="text"
                placeholder="192.168.0.0"
                value={state.ip_address}
                onChange={(v) => patchState('ip_address', v)}
              />
            }
          />
          <PrimaryField
            label="MAC address"
            element={
              <TextInput
                type="text"
                placeholder="00:00:00:00:00:00"
                value={state.mac_address}
                onChange={(v) => patchState('mac_address', v)}
              />
            }
          />
        </>
      )}
    </AddEditForm>
  );
}

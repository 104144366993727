// This file defines the filter functions for the Hub.
// A network is only included in the results if all of the filter functions pass (return true).
import type { AggregatedNetworkInfo } from '../utils/aggregateStatsForNetworks';

const PASS = true;
const FAIL = false;

type DateRange = {
  onOrAfter: string | null;
  onOrBefore: string | null;
};

function isDateInRange(dateStr: string, range: DateRange) {
  const date = new Date(dateStr).getTime();
  if (range.onOrAfter) {
    const onOrAfter = new Date(range.onOrAfter).getTime();
    if (date < onOrAfter) return FAIL;
  }
  if (range.onOrBefore) {
    const onOrBefore = new Date(range.onOrBefore).getTime();
    if (date > onOrBefore) return FAIL;
  }
  return PASS;
}

function doTagsPass(actualTags: string[], allowedTags: string[]) {
  for (const t of actualTags) {
    if (allowedTags.includes(t)) {
      return true;
    }
  }
  return false;
}

type FilterFn = (row: AggregatedNetworkInfo, ...inputs: any[]) => boolean;

// Every filter function takes a row and a filter value and returns a boolean
export const filters = {
  searchTerm: (network: AggregatedNetworkInfo, searchTerm: string) => {
    if (searchTerm.length === 0) {
      return PASS;
    }
    const searchTermLower = searchTerm.toLowerCase();
    const labelMatches = network.label.toLowerCase().includes(searchTermLower);
    const addressMatches = network.mailingAddress.toLowerCase().includes(searchTermLower);
    // this is a bit weird since owners are on action items, not networks
    const aiOwnerMatch = network.deployment?.actionItems
      ?.map((ai) => ai.ownerName)
      ?.some((ownerName) => ownerName?.toLowerCase().includes(searchTermLower));

    return labelMatches || addressMatches || Boolean(aiOwnerMatch);
  },
  onlyShowNetworksWithActionItems: (network: AggregatedNetworkInfo, enabled: boolean) => {
    if (!enabled) {
      return PASS;
    }

    if (!network.deployment) {
      return FAIL;
    }
    const hasActionItems = network.deployment.actionItems.length > 0;
    if (!hasActionItems) {
      return FAIL;
    }
    return PASS;
  },
  onlyShowNetworksWithIssues: (network: AggregatedNetworkInfo, enabled: boolean) => {
    if (enabled && !network.hasIssues) {
      return FAIL;
    }
    return PASS;
  },
  jobStages: (network: AggregatedNetworkInfo, stages: string[]) => {
    if (stages.length === 0) {
      return PASS;
    }
    if (!network.deployment) {
      // technically all real networks are "complete"
      if (stages.includes('complete')) {
        return PASS;
      }
      return FAIL;
    }
    const hasStage = stages.includes(network.deployment.jobStage);
    return hasStage;
  },
  tags: (network: AggregatedNetworkInfo, tags: { id: string; groupId: string }[]) => {
    if (tags.length === 0) {
      return PASS;
    }
    if (!network.deployment) {
      return FAIL;
    }

    const tagsByGroup: Record<string, string[]> = {};
    tags.forEach((t) => {
      if (!tagsByGroup[t.groupId]) {
        tagsByGroup[t.groupId] = [];
      }
      tagsByGroup[t.groupId].push(t.id);
    });

    for (const [groupId, allowedTagIds] of Object.entries(tagsByGroup)) {
      const tagsOnNetworkForGroup = network.deployment.tags
        .filter((t) => t.groupId === groupId)
        .map((t) => t.id);
      if (tagsOnNetworkForGroup.length === 0) {
        return FAIL;
      }

      if (!doTagsPass(tagsOnNetworkForGroup, allowedTagIds)) {
        return FAIL;
      }
    }

    return PASS;
  },
  targetGoLiveDate(network: AggregatedNetworkInfo, range: DateRange) {
    if (range.onOrAfter === null && range.onOrBefore === null) return PASS;
    if (!network.deployment) return FAIL;

    if (!network.deployment.targetGoLiveDate) return FAIL;

    const isInRange = isDateInRange(network.deployment.targetGoLiveDate, range);
    return isInRange;
  },
} satisfies Record<string, FilterFn>;

export const filterFns = Object.entries(filters);

export type Filters = typeof filters;

export type ImmutableFilterValues = {
  [Key in keyof Filters]: Parameters<Filters[Key]>[1];
};

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { OutletDetail } from '../../../../../components/Hardware/PowerDistributionUnits/OutletDetail';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/outlets/:phyInterfaceUUID',
  title: 'Outlet - Power distribution unit - Power distribution units - Hardware',
});

export default function OutletDetailPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.OutletDetailPage);
  return (
    <IsPermitted
      isPermitted={({ nosFlags, permissions }) =>
        !!nosFlags[NosFeature.POS] &&
        permissions.hasPermission(PermissionType.PermNetworkDevicesRead)
      }
      should404OnAccessDenied
    >
      <OutletDetail
        virtualDeviceUUID={drawerParams?.uuid!}
        phyInterfaceUUID={drawerParams?.phyInterfaceUUID!}
      />
    </IsPermitted>
  );
}

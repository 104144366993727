import {
  DockSigil,
  DockTarget,
  DropdownMenu,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  DropdownMenuTrigger,
  Icon,
  Small,
  space,
  styled,
  useViewport,
} from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../constants';
import { useLogoutHandler } from '../../hooks/useLogoutHandler';
import { useCurrentCompanyOrDefault } from '../../providers/CurrentCompanyProvider';
import { useIdentity } from '../../providers/IdentityDataProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';

const AccountDropdownInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '$400',
  padding: '$4 $8',
});

export function AccountDropdown() {
  const { breakpoint } = useViewport();
  const identity = useIdentity();
  const navigate = useNavigate();

  const logout = useLogoutHandler();

  const companyName = useCurrentCompanyOrDefault();

  const namesAreDefined = identity?.first_name && identity?.last_name;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DockTarget
          aria-label="Open the accounts menu"
          label={namesAreDefined ? `${identity.first_name} ${identity.last_name}` : 'Account'}
          arrow
        >
          <DockSigil
            initials={
              namesAreDefined ? (
                `${identity.first_name?.substring(0, 1).toUpperCase()}${identity.last_name?.substring(0, 1).toUpperCase()}`
              ) : (
                <Icon icon="user" color={{ light: 'brand50', dark: 'brand50' }} size={space(12)} />
              )
            }
          />
        </DockTarget>
      </DropdownMenuTrigger>
      <DropdownMenuPopover
        align="start"
        collisionPadding={12}
        side={breakpoint === 'mobile' ? 'bottom' : 'right'}
        sideOffset={6}
      >
        <DropdownMenuGroup>
          <AccountDropdownInfo>
            {namesAreDefined && (
              <Small weight="bold">
                {identity.first_name} {identity.last_name}
              </Small>
            )}
            <Small>{identity.username}</Small>
          </AccountDropdownInfo>
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuItem
            icon="preferences"
            onClick={() =>
              navigate(makeLink(paths.pages.SettingsUserPreferencesPage, { companyName }))
            }
          >
            Preferences
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuItem icon="power" onSelect={logout}>
            Sign out
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}

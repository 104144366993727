import {
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  CompositeField,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryField,
  PrimaryToggleField,
  Select,
  SelectItem,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';

import type { AddNetworkToAutoVpnGroupInput, UpdateAutoVpnMemberInput } from '../../gql/graphql';
import type { AutoVPNGroup, AutoVPNMember, CreateAutoVPNMemberInputValues, Network } from './utils';
import { ClientAssignmentProtocol } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { withZodSchema } from '../../utils/withZodSchema';
import { getPhyInterfaceLabel } from '../Firewall/utils';
import {
  FieldProvider,
  MultiComboBoxFieldProvider,
  ToggleOptionsFieldProvider,
} from '../Form/FieldProvider';
import { vlansQuery } from '../NetworkWide/VLANs/utils';
import {
  addNetworkToAutoVPNGroupMutation,
  AutoVPNGroupQuery,
  AutoVPNGroupsQuery,
  AutoVPNMemberQuery,
  createAutoVPNMemberInputSchema,
  updateAutoVPNMemberMutation,
  useNetworksWithControllerPorts,
} from './utils';

function AutoVPNGroupMemberInput({ networks }: { networks: Network[] }) {
  const { values, initialValues, setFieldValue } =
    useFormikContext<CreateAutoVPNMemberInputValues>();

  // Clear WAN and VLANs when network changes
  useEffect(() => {
    if (initialValues.networkUUID !== values.networkUUID) {
      setFieldValue('phyInterfaceUUID', '');
      setFieldValue('permittedVLANUUIDs', []);
    }
  }, [values.networkUUID, initialValues.networkUUID, setFieldValue]);

  const selectedNetwork = useMemo(
    () => networks.find((n) => n.UUID === values.networkUUID),
    [values.networkUUID, networks],
  );
  const UplinkPhyInterfaceItems = useMemo(
    () =>
      selectedNetwork?.virtualDevices
        .filter((vd) => vd.__typename === 'ControllerVirtualDevice')
        .filter((vd) => vd.phyInterfaces.some((pi) => pi.isUplink))
        .map((virtualDevice) => (
          <ComboBoxSection title={virtualDevice.label}>
            {virtualDevice.phyInterfaces
              .filter((pi) => pi.isUplink)
              .map((phyInterface) => (
                <SelectItem key={phyInterface.UUID}>
                  {getPhyInterfaceLabel(phyInterface, false)}
                </SelectItem>
              ))}
          </ComboBoxSection>
        )) ?? [],
    [selectedNetwork],
  );
  const vlans = useGraphQL(
    vlansQuery,
    {
      networkUUID: values.networkUUID,
    },
    { suspense: false, enabled: !!values.networkUUID },
  ).data?.vlans;

  const vlanOptions = useMemo(
    () =>
      vlans?.filter(
        (vlan) =>
          vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static &&
          !!vlan.ipV4ClientGateway,
      ) ?? [],
    [vlans],
  );
  return (
    <>
      <FieldContainer>
        <FieldProvider name="phyInterfaceUUID">
          <PrimaryField
            label="Bound WAN port"
            element={
              <Select width="100%" disabled={!values.networkUUID}>
                {UplinkPhyInterfaceItems}
              </Select>
            }
          />
        </FieldProvider>
      </FieldContainer>
      <FieldContainer>
        <PrimaryField
          label="Permitted VLANs"
          element={
            <MultiComboBoxFieldProvider name="permittedVLANUUIDs">
              <CompositeField
                label="Permitted VLANs"
                element={
                  <MultiComboBox disabled={!values.networkUUID}>
                    {vlanOptions.map((vlan) => (
                      <MultiComboBoxItem key={vlan.UUID} textValue={vlan.name}>
                        {vlan.name}
                      </MultiComboBoxItem>
                    ))}
                  </MultiComboBox>
                }
              />
            </MultiComboBoxFieldProvider>
          }
        />
      </FieldContainer>
      <FieldContainer>
        <ToggleOptionsFieldProvider
          name="isFailoverEnabled"
          // eslint-disable-next-line react/jsx-boolean-value
          positive={true}
          negative={false}
        >
          <PrimaryToggleField name="isFailoverEnabled" label="Failover enabled" />
        </ToggleOptionsFieldProvider>
      </FieldContainer>
    </>
  );
}

export default function AutoVPNMemberAddEditForm({
  group,
  member,
}: {
  group: AutoVPNGroup;
  member?: AutoVPNMember;
}) {
  const companySlug = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const queryClient = useQueryClient();
  const createMutation = useGraphQLMutation(addNetworkToAutoVPNGroupMutation);
  const updateMutation = useGraphQLMutation(updateAutoVPNMemberMutation);

  const handleSubmit = useCallback(
    ({ networkUUID, ...values }: CreateAutoVPNMemberInputValues) => {
      if (member?.UUID) {
        const input: UpdateAutoVpnMemberInput = values;
        updateMutation.mutate(
          { uuid: member.UUID, input },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupsQuery, { companySlug }));
              queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupQuery, { uuid: group.UUID! }));
              queryClient.invalidateQueries(
                makeQueryKey(AutoVPNMemberQuery, { uuid: member.UUID! }),
              );
              notify('Auto VPN member updated successfully.', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              notify(
                `There was a problem updating Auto VPN member${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: AddNetworkToAutoVpnGroupInput = {
          ...values,
          networkUUID,
        };
        createMutation.mutate(
          {
            groupUUID: group.UUID!,
            input,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupsQuery, { companySlug }));
              notify('Successfully add Auto VPN member.', {
                variant: 'positive',
              });
              closeDrawer();
            },
            onError: (err) => {
              notify(
                `There was a problem adding Auto VPN member${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      closeDrawer,
      companySlug,
      group.UUID,
      member?.UUID,
      createMutation,
      updateMutation,
      queryClient,
    ],
  );

  const networks = useNetworksWithControllerPorts();

  const networkOptions = useMemo(
    () =>
      networks.filter(
        (network) =>
          group.hubNetworkUUID !== network.UUID &&
          (!group.members?.some((groupMember) => groupMember.networkUUID === network.UUID) ||
            member?.networkUUID === network.UUID),
      ),
    [networks, group, member?.networkUUID],
  );

  return (
    <Formik<CreateAutoVPNMemberInputValues>
      initialValues={{
        networkUUID: member?.networkUUID ?? '',
        phyInterfaceUUID: member?.uplink.phyInterfaceUUID ?? '',
        permittedVLANUUIDs: member?.permittedVLANs?.map((vlan) => vlan?.UUID) ?? [],
        isFailoverEnabled: member?.isFailoverEnabled ?? true,
      }}
      validate={withZodSchema(createAutoVPNMemberInputSchema)}
      onSubmit={handleSubmit}
    >
      <Form>
        <DrawerContent>
          <FieldContainer>
            <FieldProvider name="networkUUID">
              <PrimaryField
                label="Network"
                element={
                  <ComboBox disabled={!!member}>
                    {networkOptions.map((network) => (
                      <ComboBoxItem key={network.UUID}>{network.label}</ComboBoxItem>
                    ))}
                  </ComboBox>
                }
              />
            </FieldProvider>
          </FieldContainer>
          <AutoVPNGroupMemberInput networks={networks} />
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={closeDrawer} variant="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </>
          }
        />
      </Form>
    </Formik>
  );
}

/* eslint-disable react/no-unstable-nested-components */
import { Badge, EmptyState, PaneContent, Text } from '@meterup/atto';
import { AutoTable, isDefinedAndNotEmpty } from '@meterup/common';
import { DateTime } from 'luxon';
import { useMemo, useRef } from 'react';

import { NoValue } from '../../../../../components/NoValue';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { deviceTypeToHardwareIconPropHardware } from '../../../../../utils/types';
import { type Stat, useNetworkStats, useNetworkStatsFilter } from './hooks/useNetworkStats';
import { useVirtualDevices } from './hooks/useVirtualDevices';
import StatDetails from './StatDetails';

const builder = createColumnBuilder<Stat>();

export default function StatsListTable() {
  const [querySearch] = useSearchParamsState<string>('search', '');
  const { uuidToVirtualDeviceMap } = useVirtualDevices();
  const { filter } = useNetworkStatsFilter();

  const stats = useNetworkStats({
    filter,
  });

  const tableContainerRef = useRef(null);
  const columns = useMemo(
    () => [
      builder.data((d) => d.virtualDeviceUUID, {
        id: 'device',
        header: 'Device label',
        cell: (d) =>
          isDefinedAndNotEmpty(d.value) ? (
            <Badge
              variant="neutral"
              size="small"
              icon={deviceTypeToHardwareIconPropHardware(
                uuidToVirtualDeviceMap[d.value].deviceType,
              )}
              arrangement="leading-icon"
            >
              {uuidToVirtualDeviceMap[d.value].label}
            </Badge>
          ) : (
            <NoValue />
          ),
      }),
      builder.data(
        (d) => uuidToVirtualDeviceMap[d.virtualDeviceUUID].hardwareDevice?.serialNumber || '',
        {
          id: 'deviceSerial',
          header: 'Device serial number',
          cell: (d) => <Text family="monospace">{d.value}</Text>,
        },
      ),
      builder.data((d) => d.type, {
        id: 'event',
        header: 'Stat',
        cell: (d) => <Text family="monospace">{d.value}</Text>,
      }),
      builder.data((d) => d.observedAt ?? '', {
        id: 'observed',
        header: `Observed (${DateTime.local().toFormat('ZZZZZ')})`,
        meta: {
          alignment: 'end',
        },
        cell: (d) =>
          isDefinedAndNotEmpty(d.value) ? (
            <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</>
          ) : (
            <NoValue />
          ),
      }),
      builder.data((d) => d.insertedAt ?? '', {
        id: 'inserted',
        header: `Inserted (${DateTime.local().toFormat('ZZZZZ')})`,
        meta: {
          alignment: 'end',
        },
        cell: (d) =>
          isDefinedAndNotEmpty(d.value) ? (
            <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</>
          ) : (
            <NoValue />
          ),
      }),
    ],
    [uuidToVirtualDeviceMap],
  );
  return (
    <PaneContent ref={tableContainerRef}>
      {stats.length > 0 ? (
        <AutoTable
          key={JSON.stringify(stats)}
          columns={columns}
          data={stats}
          globalFilter={querySearch}
          renderSubTable={StatDetails}
        />
      ) : (
        <EmptyState
          icon="log"
          heading="No stats for criteria. Ensure you've selected a device and stat type(s)"
        />
      )}
    </PaneContent>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define,@typescript-eslint/naming-convention,@typescript-eslint/no-use-before-define */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AlertParameterName: { input: string; output: string };
  Any: { input: unknown; output: unknown };
  DateTime: { input: string; output: string };
  Duration: { input: string; output: string };
  IP: { input: string; output: string };
  IPPrefix: { input: string; output: string };
  IPV4: { input: string; output: string };
  IPV6: { input: string; output: string };
  JSONObject: { input: Record<string, unknown>; output: Record<string, unknown> };
  MacAddress: { input: string; output: string };
  UUID: { input: string; output: string };
  UnmaskedIPPrefix: { input: string; output: string };
};

export type ApChannelUtilizationMetricsValue = MetricsValue & {
  __typename?: 'APChannelUtilizationMetricsValue';
  apName: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type ApChannelUtilizationResponse = MetricsResponse & {
  __typename?: 'APChannelUtilizationResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<ApChannelUtilizationMetricsValue>;
};

export type ApChannelUtilizationTimeseriesValue = {
  __typename?: 'APChannelUtilizationTimeseriesValue';
  /**
   * What channel the AP's radio was on at the time of the observation.
   * For single AP queries, this will be the most-seen channel during the bucket window.
   * This will be null for network / multiple-AP queries (because network-level is
   * an aggregation of all APs, which will likely be on a long list of channels).
   */
  channel?: Maybe<Scalars['Int']['output']>;
  /**
   * The channel width of the AP's radio at the time of the observation.
   * For single AP queries, this will be the most-seen channel width during the bucket window.
   * This will be null for network / multiple-AP queries (because network-level is
   * an aggregation of all APs, which will likely be on different channel widths).
   */
  channelWidth?: Maybe<Scalars['Int']['output']>;
  /** The maximum signal strength of interference sources detected on the channel. (in dBm) */
  interferenceSignalStrengthMaxdBm: Scalars['Int']['output'];
  /** The median signal strength of interference sources detected on the channel. (in dBm). This is a median of medians of observances within a bucket window, floored to the nearest integer. */
  interferenceSignalStrengthMediandBm: Scalars['Int']['output'];
  /** The number of sources of high interference detected on the channel. (An average of data observances, floored to the nearest integer). */
  interferenceSourceCountHigh: Scalars['Int']['output'];
  /** The number of sources of low interference detected on the channel. (An average of data observances, floored to the nearest integer). */
  interferenceSourceCountLow: Scalars['Int']['output'];
  /** The number of sources of medium interference detected on the channel. (An average of data observances, floored to the nearest integer). */
  interferenceSourceCountMedium: Scalars['Int']['output'];
  /** The amount of utilization contributed by non-802.11 interference in the environment, e.g. Bluetooth, cameras. (from 0 to 1) */
  non80211Utilization: Scalars['Float']['output'];
  /** The amount of utilization as a result of 802.11 interference by neighbors. (from 0 to 1) */
  obssUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from clients. (from 0 to 1) */
  rxUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized by the AP itself. (from 0 to 1) */
  selfUtilization: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** The percentage of time the channel was utilized. (from 0 to 1) */
  totalUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from the AP. (from 0 to 1) */
  txUtilization: Scalars['Float']['output'];
};

export type ApUptimeMetricsValue = MetricsValue & {
  __typename?: 'APUptimeMetricsValue';
  apName: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type ApUptimeResponse = MetricsResponse & {
  __typename?: 'APUptimeResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<ApUptimeMetricsValue>;
};

/** An access point hardware device represents a physical access point. */
export type AccessPointHardwareDevice = HardwareDevice & {
  __typename?: 'AccessPointHardwareDevice';
  /** BSSIDs are MAC addresses that represent a unique identifier for an AP's SSID + radio band pairs. */
  BSSIDs: Array<Bssid>;
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  /** True if the AP's MAC address exists in the most recent controller MAC table for the network. */
  isInCurrentControllerMACTable: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The phy interface (on a Meter Switch) that the AP is connected to, if applicable. */
  phyInterfaceConnectedTo?: Maybe<PhyInterface>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** An access point hardware device represents a physical access point. */
export type AccessPointHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** An access point virtual device is a logical representation of a physical access point. */
export type AccessPointVirtualDevice = VirtualDevice & {
  __typename?: 'AccessPointVirtualDevice';
  UUID: Scalars['UUID']['output'];
  /** The clients that are currently connected to the AP. */
  connectedClients: Array<WirelessClient>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Whether the device been configured to turn off all LED activity. */
  isLEDDarkModeEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  radioProfile?: Maybe<RadioProfile>;
  radioProfileUUID?: Maybe<Scalars['UUID']['output']>;
  radioSettings: RadioSettings;
  /** All of the enabled SSIDs that the AP is broadcasting. */
  ssids: Array<Ssid>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type ActiveClientsInput = {
  includeMeterHardware?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActiveClientsMetricsResponse = MetricsResponse & {
  __typename?: 'ActiveClientsMetricsResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<ActiveClientsMetricsValue>;
};

export type ActiveClientsMetricsValue = MetricsValue & {
  __typename?: 'ActiveClientsMetricsValue';
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type AddNetworkToAutoVpnGroupInput = {
  isFailoverEnabled?: Scalars['Boolean']['input'];
  networkUUID: Scalars['UUID']['input'];
  permittedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  phyInterfaceUUID: Scalars['UUID']['input'];
};

/**
 * Address represents a physical address, used with an ISP. It encompasses the Location and Sublocation.
 *
 * WARNING: These fields are currently different from the fields used in the MailingAddress type. There is a plan to
 * consolidate these types in the future.
 */
export type Address = {
  __typename?: 'Address';
  UUID: Scalars['UUID']['output'];
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company?: Maybe<Company>;
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  sublocationUUID: Scalars['UUID']['output'];
};

/** An AlertDefinition specifies parameters for different types of Alerts used to notify stakeholders of important events for a network. */
export type AlertDefinition = {
  __typename?: 'AlertDefinition';
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parameters: Array<AlertParameter>;
};

export type AlertParameter = {
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterBoolean = AlertParameter & {
  __typename?: 'AlertParameterBoolean';
  defaultValue?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterDate = AlertParameter & {
  __typename?: 'AlertParameterDate';
  defaultValue?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterDateTime = AlertParameter & {
  __typename?: 'AlertParameterDateTime';
  defaultValue?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterDuration = AlertParameter & {
  __typename?: 'AlertParameterDuration';
  defaultValue?: Maybe<Scalars['Duration']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['Duration']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterNumber = AlertParameter & {
  __typename?: 'AlertParameterNumber';
  defaultValue?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['Float']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterString = AlertParameter & {
  __typename?: 'AlertParameterString';
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export enum AlertParameterType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Duration = 'DURATION',
  Number = 'NUMBER',
  String = 'STRING',
}

export type AlertReceiver = {
  __typename?: 'AlertReceiver';
  UUID: Scalars['UUID']['output'];
  companyUUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  label: Scalars['String']['output'];
  targets: Array<AlertTarget>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertTarget = {
  UUID: Scalars['UUID']['output'];
  companyUUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  type: AlertTargetType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertTargetEmail = AlertTarget & {
  __typename?: 'AlertTargetEmail';
  UUID: Scalars['UUID']['output'];
  companyUUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailAddress: Scalars['String']['output'];
  type: AlertTargetType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertTargetInput = {
  alertTargetType: AlertTargetType;
  /** Required for all alert target types except email */
  alertTargetUUID?: InputMaybe<Scalars['String']['input']>;
  /** Required if creating a new email target */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
};

export enum AlertTargetType {
  Email = 'EMAIL',
  Webhook = 'WEBHOOK',
}

export type AlertTargetWebhook = AlertTarget & {
  __typename?: 'AlertTargetWebhook';
  UUID: Scalars['UUID']['output'];
  companyUUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  signingSecret?: Maybe<Scalars['String']['output']>;
  type: AlertTargetType;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type AlertTargetsResponse = {
  __typename?: 'AlertTargetsResponse';
  emails: Array<AlertTargetEmail>;
  webhooks: Array<AlertTargetWebhook>;
};

export enum AllowedFolder {
  AccessPointPhotos = 'access_point_photos',
  FloorPlanImages = 'floor_plan_images',
  InternalNotesAttachments = 'internal_notes_attachments',
  NetworkTopologyPhotos = 'network_topology_photos',
  OnboardingDocumentUploads = 'onboarding_document_uploads',
}

/** DNS firewall rules for a DHCP rule. These are powered by Netstar. */
export type ApplicationDnsFirewallRule = {
  __typename?: 'ApplicationDNSFirewallRule';
  UUID: Scalars['UUID']['output'];
  action: FirewallRuleAction;
  application?: Maybe<ApplicationDnsFirewallRuleApplication>;
  category?: Maybe<ApplicationDnsFirewallRuleCategory>;
  createdAt: Scalars['DateTime']['output'];
  dhcpRule: DhcpRule;
  domain?: Maybe<Scalars['String']['output']>;
  group?: Maybe<ApplicationDnsFirewallRuleGroup>;
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** The application associated with a DNS firewall rule as specified by Netstar. */
export type ApplicationDnsFirewallRuleApplication = {
  __typename?: 'ApplicationDNSFirewallRuleApplication';
  id: Scalars['Int']['output'];
  isVisible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** Categories of DNS firewall rules as specified by Netstar. */
export type ApplicationDnsFirewallRuleCategory = {
  __typename?: 'ApplicationDNSFirewallRuleCategory';
  description: Scalars['String']['output'];
  group: ApplicationDnsFirewallRuleGroup;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** Groups multiple DNS firewall rules together. */
export type ApplicationDnsFirewallRuleGroup = {
  __typename?: 'ApplicationDNSFirewallRuleGroup';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** Specifies the hardware device / virtual device pairing when assigning hardware to a virtual device. */
export type AssignHardwareDeviceToVirtualDeviceInput = {
  serialNumber: Scalars['String']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type AttachDeviceToRackElevationInput = {
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing?: InputMaybe<Scalars['Boolean']['input']>;
  /** For display purposes. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** For additional context. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Only for Switches and Patch panels; cannot be specified if it's a Meter device. */
  portCount?: InputMaybe<Scalars['Int']['input']>;
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes: Array<Scalars['Int']['input']>;
  /** Cannot be specified if it's a Meter device. */
  type?: InputMaybe<RackElevationDeviceType>;
  /** If it's a Meter device, the virtual device's UUID. */
  virtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type AuthenticationToken = {
  __typename?: 'AuthenticationToken';
  UUID: Scalars['UUID']['output'];
  hardwareDeviceSerial?: Maybe<Scalars['String']['output']>;
  issuedAt: Scalars['DateTime']['output'];
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  userID?: Maybe<Scalars['Int']['output']>;
};

/** Auto Channel Selection Jobs automatically select the best channel for APs in a network. */
export type AutoChannelSelectionJob = AutoSelectionJob & {
  __typename?: 'AutoChannelSelectionJob';
  UUID: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  networkUUID: Scalars['UUID']['output'];
  /**
   * If the process is still running (completedAt is in the future), then no results will be returned.
   * One result will be returned for every port that changed trunk/access status or native VLAN, or if there was a warning.
   * If the port did not change and there was no warning, it will not appear in the results.
   */
  results?: Maybe<Array<AutoChannelSelectionResult>>;
  scheduledAt: Scalars['DateTime']['output'];
  timeoutAt: Scalars['DateTime']['output'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

export type AutoChannelSelectionJobInput = {
  /**
   * Which radio bands to run ACS for.
   * For now, only the 5GHz band is supported, but we require this in the input for future-proofing.
   */
  bands: Array<RadioBand>;
  /** Only for network-level runs: If true, only run ACS on APs that are experiencing high external interference. */
  excludeLowInterferenceAPs?: Scalars['Boolean']['input'];
  /**
   * If true, the ACS job will run during the network's next maintenance window.
   * Else, it will run immediately.
   */
  scheduledAtNextMaintenanceWindow?: Scalars['Boolean']['input'];
};

export type AutoChannelSelectionResult = {
  __typename?: 'AutoChannelSelectionResult';
  new5GHzChannel?: Maybe<Scalars['Int']['output']>;
  previous5GHzChannel?: Maybe<Scalars['Int']['output']>;
  resultType: AutoChannelSelectionResultType;
  timestamp: Scalars['DateTime']['output'];
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export enum AutoChannelSelectionResultType {
  /** ACS ran and a channel was selected with the least number of BSSs (Basic Service Sets). */
  ChannelSelectedMinBss = 'CHANNEL_SELECTED_MIN_BSS',
  /** ACS ran but a random channel was selected. */
  ChannelSelectedRandom = 'CHANNEL_SELECTED_RANDOM',
  FailedUnknownReason = 'FAILED_UNKNOWN_REASON',
  Success = 'SUCCESS',
}

export type AutoSelectionJob = {
  UUID: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  networkUUID: Scalars['UUID']['output'];
  scheduledAt: Scalars['DateTime']['output'];
  timeoutAt: Scalars['DateTime']['output'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

export type AutoVpnGroup = {
  __typename?: 'AutoVPNGroup';
  UUID: Scalars['UUID']['output'];
  companySlug: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  hubNetwork: Network;
  hubNetworkUUID: Scalars['UUID']['output'];
  hubUplink: AutoVpnHubUplink;
  isEnabled: Scalars['Boolean']['output'];
  isHubFailoverEnabled: Scalars['Boolean']['output'];
  members?: Maybe<Array<AutoVpnMember>>;
  name: Scalars['String']['output'];
  routes?: Maybe<Array<AutoVpnRoute>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AutoVpnHubUplink = {
  __typename?: 'AutoVPNHubUplink';
  UUID: Scalars['UUID']['output'];
  address: Scalars['IP']['output'];
  addressPrefixLength: Scalars['Int']['output'];
  listenPort: Scalars['Int']['output'];
  phyInterface: PhyInterface;
  phyInterfaceUUID: Scalars['UUID']['output'];
  publicKey: Scalars['String']['output'];
};

export type AutoVpnMember = {
  __typename?: 'AutoVPNMember';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  isFailoverEnabled: Scalars['Boolean']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  permittedVLANs: Array<Vlan>;
  updatedAt: Scalars['DateTime']['output'];
  uplink: AutoVpnMemberUplink;
};

export type AutoVpnMemberUplink = {
  __typename?: 'AutoVPNMemberUplink';
  UUID: Scalars['UUID']['output'];
  address: Scalars['IP']['output'];
  addressPrefixLength: Scalars['Int']['output'];
  connectionInfo?: Maybe<AutoVpnMemberUplinkConnectionInfo>;
  hubUplinkUUID: Scalars['UUID']['output'];
  listenPort: Scalars['Int']['output'];
  phyInterface: PhyInterface;
  phyInterfaceUUID: Scalars['UUID']['output'];
  publicKey: Scalars['String']['output'];
};

export type AutoVpnMemberUplinkConnectionInfo = {
  __typename?: 'AutoVPNMemberUplinkConnectionInfo';
  /** The last time a handshake was received (from the hub's perspective). */
  lastHandshakeRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a handshake was transmitted (from the hub's perspective). */
  lastHandshakeTx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was received over the tunnel (from the hub's perspective). */
  lastPacketRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was transmitted over the tunnel (from the hub's perspective). */
  lastPacketTx?: Maybe<Scalars['DateTime']['output']>;
  observedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AutoVpnRoute = {
  __typename?: 'AutoVPNRoute';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dstGateway: Scalars['IPV4']['output'];
  dstPrefixLength: Scalars['Int']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * BSSID represents a unique identifier for a wireless network, in the form of a MAC address.
 * Every radio band / SSID pair for an AP has its own BSSID, derived from the hardware device's MAC address.
 */
export type Bssid = {
  __typename?: 'BSSID';
  BSSID: Scalars['MacAddress']['output'];
  SSID: Ssid;
  SSIDUUID: Scalars['UUID']['output'];
  accessPointHardwareDevice: AccessPointHardwareDevice;
  accessPointSerialNumber: Scalars['String']['output'];
  /** A BSSID is inactive if it is associated with a disabled SSID, or the SSID is not broadcast for its AP, or the SSID is not allowed for its radio band, or the radio band is disabled on its AP's radio profile. */
  isActive: Scalars['Boolean']['output'];
  radioBand: RadioBand;
};

export type Base64Image = {
  __typename?: 'Base64Image';
  /** The base64 encoded image. */
  data: Scalars['String']['output'];
  /** The type of the image. */
  imageType: ImageType;
};

export type BearerSession = {
  __typename?: 'BearerSession';
  UUID: Scalars['UUID']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type BindFirewallRuleToPhyInterface = {
  firewallRuleUUID: Scalars['UUID']['input'];
  metric: Scalars['Int']['input'];
  /** The phy interface must be an uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type BindFirewallRuleToVlan = {
  firewallRuleUUID: Scalars['UUID']['input'];
  metric: Scalars['Int']['input'];
  vlanUUID: Scalars['UUID']['input'];
};

export type BindPolicyRoutingRuleInput = {
  binding: PolicyRoutingRuleBindingInput;
  /** If not provided will be 1 greater than current greatest metric. */
  metric?: InputMaybe<Scalars['Int']['input']>;
};

export type BindPolicyRoutingRulesInput = {
  binding: PolicyRoutingRuleBindingInput;
};

export type BindVlanToPhyInterfaceInput = {
  /** If true and there is already a Native VLAN bound to this interface, the Native VLAN will be overwritten and set to this VLAN. */
  isTagged: Scalars['Boolean']['input'];
  phyInterfaceUUID: Scalars['UUID']['input'];
  vlanUUID: Scalars['UUID']['input'];
};

/** BootInfo captures logs every time a device boots up. */
export type BootInfo = {
  __typename?: 'BootInfo';
  /** The last reported boot counter which the device increments every time it boots */
  bootCount: Scalars['Int']['output'];
  /** The last reported build the device was running */
  buildName: Scalars['String']['output'];
  /** The time at which the device first reported the information */
  createdAt: Scalars['DateTime']['output'];
  /** If the device knows, this is the last reported reason that caused the device to reboot */
  rebootReason: Scalars['String']['output'];
  /** The time at which the device reported the information */
  updatedAt: Scalars['DateTime']['output'];
};

export type BulkNosUpgrade = {
  __typename?: 'BulkNOSUpgrade';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  networks: Array<Network>;
  nosVersionID: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type BulkNosUpgradesFilterInput = {
  limit?: Scalars['Int']['input'];
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  offset?: Scalars['Int']['input'];
};

export type CaptivePortal = {
  __typename?: 'CaptivePortal';
  UUID: Scalars['UUID']['output'];
  /** Whitelisted IPs/URLs that are allowed on the network by default. */
  allowedHosts: Array<Scalars['String']['output']>;
  /** Authentication session duration, defaults to 1 day (86400s) if not set. */
  authLifetimeSec?: Maybe<Scalars['Int']['output']>;
  /** The heading to display on the captive portal page. */
  callToAction?: Maybe<Scalars['String']['output']>;
  displaySettings?: Maybe<CaptivePortalDisplaySettings>;
  /** The URL to an external captive portal. If this is configured, it will override all configurations specific to internal captive portal. */
  externalPortalURL?: Maybe<Scalars['String']['output']>;
  /** HMAC secret to sign and verify signatures. */
  hmacSecret?: Maybe<Scalars['String']['output']>;
  /** If true, the captive portal is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** A base64 encoded image of the logo. This is used to limit the number of domains we need to allow for the logo image. */
  logoImageBase64?: Maybe<Base64Image>;
  /** The S3 key for where the logo image is stored. This is used for configuration, and should not be used to display the image. */
  logoImageS3Key?: Maybe<Scalars['String']['output']>;
  /** A URL to a logo image that will be displayed on the captive portal page. */
  logoImageURL?: Maybe<Scalars['String']['output']>;
  /** A human-friendly name. */
  name?: Maybe<Scalars['String']['output']>;
  networkUUID: Scalars['UUID']['output'];
  /** The URL to redirect to after a successful captive portal login. */
  redirectURL?: Maybe<Scalars['String']['output']>;
  /** The terms that a user must agree to before accessing the network. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** The VLANs that this captive portal is associated with. */
  vlans?: Maybe<Array<Vlan>>;
  /** UUID used to generate a unique URL for a webhook corresponding to this captive portal. */
  webhookKey: Scalars['UUID']['output'];
};

export type CaptivePortalClient = {
  __typename?: 'CaptivePortalClient';
  ipAddress: Scalars['IP']['output'];
  /** If true, client has accepted the terms and conditions and has been authorized. */
  isAuthorized: Scalars['Boolean']['output'];
  macAddress: Scalars['MacAddress']['output'];
  rawUserAgent: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type CaptivePortalDisplaySettings = {
  __typename?: 'CaptivePortalDisplaySettings';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
  hidePoweredByMeter?: Maybe<Scalars['Boolean']['output']>;
};

export type CaptivePortalDisplaySettingsInput = {
  /** A hex color code */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** A hex color code */
  fontColor?: InputMaybe<Scalars['String']['input']>;
  /** If true, the "Powered by Meter" text will be hidden. */
  hidePoweredByMeter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CaptivePortalSettingsInput = {
  /** The IPs/URLs that are whitelisted by default. */
  allowedHosts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Authentication session duration, defaults to 1 day (86400s) if not set. */
  authLifetimeSec?: InputMaybe<Scalars['Int']['input']>;
  /** The heading to display on the captive portal page. */
  callToAction?: InputMaybe<Scalars['String']['input']>;
  displaySettings?: InputMaybe<CaptivePortalDisplaySettingsInput>;
  /** Setting externalPortalURL will configure this captive portal to redirect users to an external portal and override any settings specific to internal captive portals. */
  externalPortalURL?: InputMaybe<Scalars['String']['input']>;
  /** If true, the captive portal is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Use the `createPreSignedUploadUrl` mutation to generate an S3 key and presigned URL for the logo image, and specify it here. */
  logoImageS3Key?: InputMaybe<Scalars['String']['input']>;
  /** A human-friendly name. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The URL to redirect to after a successful captive portal login. */
  redirectURL?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  /** Setting vlanUUIDs will assign this captive portal to the specified VLANs. */
  vlanUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export enum CellularFailoverEventRoute {
  Cellular = 'CELLULAR',
  Wan = 'WAN',
}

export enum CellularManagerModemState {
  Attached = 'ATTACHED',
  Detached = 'DETACHED',
  Unspecified = 'UNSPECIFIED',
}

export type CellularStatus = {
  __typename?: 'CellularStatus';
  managerModemState: CellularManagerModemState;
  observedAt: Scalars['DateTime']['output'];
};

export type CellularUsage = {
  __typename?: 'CellularUsage';
  downloadBytes?: Maybe<Scalars['Int']['output']>;
  uploadBytes?: Maybe<Scalars['Int']['output']>;
};

export type ChannelUtilizationResponseV2 = {
  __typename?: 'ChannelUtilizationResponseV2';
  band: RadioBand;
  values: Array<ApChannelUtilizationTimeseriesValue>;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type ChannelUtilizationTimeseriesFilterInput = {
  /** Specify the time range for the timeseries data. */
  timeFilter: MetricsFilterInput;
};

export type CheckRoleInput = {
  companySlug?: InputMaybe<Scalars['String']['input']>;
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  roleName: RoleName;
};

export enum ClientAssignmentProtocol {
  Dhcp = 'DHCP',
  Static = 'STATIC',
}

export type ClientChannelUtilizationTimeseriesValue = {
  __typename?: 'ClientChannelUtilizationTimeseriesValue';
  /** The amount of utilization contributed by non-802.11 interference in the environment, e.g. Bluetooth, cameras. (from 0 to 1) */
  non80211Utilization: Scalars['Float']['output'];
  /** The amount of utilization as a result of 802.11 interference by neighbors. (from 0 to 1) */
  obssUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from clients. (from 0 to 1) */
  rxUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized by the AP itself. (from 0 to 1) */
  selfUtilization: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** The percentage of time the channel was utilized. (from 0 to 1) */
  totalUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from the AP. (from 0 to 1) */
  txUtilization: Scalars['Float']['output'];
};

/** Aggregated values (like min/max) over the given timerange for all of the client metrics requested. */
export type ClientMetricsMetadata = {
  __typename?: 'ClientMetricsMetadata';
  associationTimeSeconds?: Maybe<MetricsMetadata>;
  clientCount?: Maybe<MetricsMetadata>;
  hwMode?: Maybe<MetricsMetadata>;
  noise?: Maybe<MetricsMetadata>;
  numSpatialStreamsRx?: Maybe<MetricsMetadata>;
  numSpatialStreamsTx?: Maybe<MetricsMetadata>;
  /** @deprecated Prefer rxBytes instead */
  rx?: Maybe<MetricsMetadata>;
  rxBytes?: Maybe<MetricsMetadata>;
  rxMulticastBytes?: Maybe<MetricsMetadata>;
  rxRate?: Maybe<MetricsMetadata>;
  rxUnicastBytes?: Maybe<MetricsMetadata>;
  signal?: Maybe<MetricsMetadata>;
  snr?: Maybe<MetricsMetadata>;
  /** @deprecated Prefer txBytes instead */
  tx?: Maybe<MetricsMetadata>;
  txBytes?: Maybe<MetricsMetadata>;
  txMulticastBytes?: Maybe<MetricsMetadata>;
  txRate?: Maybe<MetricsMetadata>;
  txUnicastBytes?: Maybe<MetricsMetadata>;
};

/** Deprecated in favor of `ClientMetricsTimeseriesResponse`. */
export type ClientMetricsResponse = {
  __typename?: 'ClientMetricsResponse';
  metadata?: Maybe<ClientMetricsMetadata>;
  values: Array<ClientMetricsValue>;
};

export type ClientMetricsTimeseriesFilterInput = {
  /** Specifying this field returns only metrics observed for the given radio band(s). */
  bands?: InputMaybe<Array<RadioBand>>;
  /** Specifying this field returns only metrics observed for the given radio channel(s). */
  channels?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Specifying this field returns only metrics observed for the given SSID(s), by UUID. */
  ssidUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Specify the time range for the timeseries data. */
  timeFilter: MetricsFilterInput;
};

export type ClientMetricsTimeseriesResponse = {
  __typename?: 'ClientMetricsTimeseriesResponse';
  metadata?: Maybe<ClientMetricsMetadata>;
  values: Array<ClientMetricsTimeseriesValue>;
};

/**
 * Time-series data for client connectivity metrics.
 * If requested at a per-AP level, the results will be aggregated over all clients connected to the AP.
 */
export type ClientMetricsTimeseriesValue = {
  __typename?: 'ClientMetricsTimeseriesValue';
  /**
   * Number of seconds that the client has been associated with an AP for.
   * @deprecated This isn't really timeseries data and a timestamp is more useful; refer to WirelessClient.associatedAt instead.
   */
  associationTimeSeconds?: Maybe<Scalars['Float']['output']>;
  /** The count of distinct clients (by MAC address) that connected to the AP during the time period. */
  clientCount: Scalars['Int']['output'];
  /** The wifi capabilities of the client device. */
  hwMode?: Maybe<Scalars['String']['output']>;
  /** Noise in dBm. */
  noise: Scalars['Float']['output'];
  /** The number of rx spatial streams */
  numSpatialStreamsRx?: Maybe<Scalars['Int']['output']>;
  /** The number of tx spatial streams. */
  numSpatialStreamsTx?: Maybe<Scalars['Int']['output']>;
  /** Total number of bytes received in the given time period. */
  rxBytes: Scalars['Float']['output'];
  /** Total number of multicast bytes received in the given time period; a subset of rxBytes. */
  rxMulticastBytes: Scalars['Float']['output'];
  /** The rate at which the client is receiving data in kilobits per second. */
  rxRate: Scalars['Float']['output'];
  /** The ratio of the number of times a packet was retried to the total number of success packets. Null if no packets succeeded. */
  rxRetryRatio?: Maybe<Scalars['Float']['output']>;
  /** @deprecated It's unreliable to determine this client stat from the AP's perspective. */
  rxSuccessRatio?: Maybe<Scalars['Float']['output']>;
  /** Total number of unicast bytes received in the given time period; a subset of rxBytes. */
  rxUnicastBytes: Scalars['Float']['output'];
  /** Received signal strength in dBm. */
  signal: Scalars['Float']['output'];
  /** Signal-to-noise ratio in dBm. A higher value is better. SNR = signal - noise. */
  snr: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes transmitted in the given time period. */
  txBytes: Scalars['Float']['output'];
  /** Total number of multicast bytes transmitted in the given time period; a subset of txBytes. */
  txMulticastBytes: Scalars['Float']['output'];
  /** The rate at which the client is transmitting data in kilobits per second. */
  txRate: Scalars['Float']['output'];
  /** The ratio of the number of times the client retried a packet transmission to the number of packets successfully transmitted. Null if no packets were transmitted. */
  txRetryRatio?: Maybe<Scalars['Float']['output']>;
  /** The ratio of the number of times the client successfully received a packet to the number of packets transmitted. Null if no packets were transmitted. */
  txSuccessRatio?: Maybe<Scalars['Float']['output']>;
  /** Total number of unicast bytes transmitted in the given time period; a subset of txBytes. */
  txUnicastBytes: Scalars['Float']['output'];
};

/** Deprecated in favor of `ClientMetricsTimeseriesValue`. */
export type ClientMetricsValue = {
  __typename?: 'ClientMetricsValue';
  macAddress: Scalars['MacAddress']['output'];
  noise: Scalars['Float']['output'];
  rxRate: Scalars['Float']['output'];
  signal: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  txRate: Scalars['Float']['output'];
};

export type ClientVpnClient = {
  __typename?: 'ClientVPNClient';
  UUID: Scalars['UUID']['output'];
  connectionInfo?: Maybe<ClientVpnClientConnectionInfo>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  ipAddress: Scalars['IP']['output'];
  name: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
  userSID: Scalars['UUID']['output'];
  vpnServerUUID: Scalars['UUID']['output'];
};

export type ClientVpnClientConnectionInfo = {
  __typename?: 'ClientVPNClientConnectionInfo';
  /** The last time a handshake was received (from the controller's perspective). */
  lastHandshakeRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a handshake was transmitted (from the controller's perspective). */
  lastHandshakeTx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was received over the tunnel (from the controller's perspective). */
  lastPacketRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was transmitted over the tunnel (from the controller's perspective). */
  lastPacketTx?: Maybe<Scalars['DateTime']['output']>;
  observedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ClientVpnServer = {
  __typename?: 'ClientVPNServer';
  UUID: Scalars['UUID']['output'];
  address?: Maybe<Scalars['IP']['output']>;
  addressMask?: Maybe<Scalars['Int']['output']>;
  clients: Array<ClientVpnClient>;
  defaultClientAllowedIPs?: Maybe<Array<Scalars['IPPrefix']['output']>>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: Maybe<Scalars['IPV4']['output']>;
  endpoint: Scalars['String']['output'];
  isFailoverEnabled: Scalars['Boolean']['output'];
  /** The WireGuard interface will listen on this interface. If null, the server is not active. */
  phyInterface?: Maybe<PhyInterface>;
  port: Scalars['Int']['output'];
  publicKey: Scalars['String']['output'];
};

/** A company is a core Meter entity used to represent customers. Networks belong to companies. */
export type Company = {
  __typename?: 'Company';
  /**
   * The Salesforce Company ID corresponding to this company.
   * @deprecated Use `isCustomer` instead.
   */
  externalID?: Maybe<Scalars['String']['output']>;
  /** A company representing a Meter customer as opposed to an internal (test) company. */
  isCustomer: Scalars['Boolean']['output'];
  /** A prospective company is used for sales purposes. */
  isProspective: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networks: Array<Network>;
  permittedEmailDomains?: Maybe<Array<PermittedEmailDomain>>;
  slug: Scalars['String']['output'];
  ssoEnabled?: Maybe<Scalars['Boolean']['output']>;
  supportGroup?: Maybe<Scalars['Int']['output']>;
  /** The Customer Success team segments customers by their tier, which dictates their level of support / sensitivity. */
  supportTier?: Maybe<CompanySupportTier>;
  uuid: Scalars['UUID']['output'];
};

export enum CompanyMembershipRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
}

/** The support tier indicates the customer's level of support / sensitivity. */
export enum CompanySupportTier {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

/** A company user provides access for a user to a company's networks. */
export type CompanyUser = {
  __typename?: 'CompanyUser';
  UUID: Scalars['UUID']['output'];
  company: Company;
  companyMembershipRole: CompanyMembershipRole;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  roles: Array<RoleAssignment>;
  user: User;
};

export type ConfigureSsoInput = {
  companySlug: Scalars['String']['input'];
  type: SsoAdminPortalType;
};

export type ConfigureSsoResult = {
  __typename?: 'ConfigureSSOResult';
  company: Company;
  portalURL: Scalars['String']['output'];
};

/** ConfiguredNOSVersion represents the NOS firmware version that a device is configured to run. */
export type ConfiguredNosVersion = {
  __typename?: 'ConfiguredNOSVersion';
  /** The build name specified in the NOS version (could be different from that reported by the device in bootinfo table) */
  buildName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  version: Scalars['String']['output'];
};

/** A console is a record of a user logging into a device via the console. */
export type ConsoleLogin = {
  __typename?: 'ConsoleLogin';
  UUID: Scalars['UUID']['output'];
  /** Timestamp of when the console login was recorded. */
  createdAt: Scalars['DateTime']['output'];
  /** Device that the user logged into. */
  hardwareDevice: HardwareDevice;
  /** Username of the user that logged into the device. */
  username: Scalars['String']['output'];
};

/** Time-series data for controller DHCP server uptime metrics. */
export type ControllerDhcpUptimeMetricsValue = MetricsValue & {
  __typename?: 'ControllerDHCPUptimeMetricsValue';
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type ControllerDhcpUptimeResponse = MetricsResponse & {
  __typename?: 'ControllerDHCPUptimeResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<ControllerDhcpUptimeMetricsValue>;
};

export type ControllerDnsRequestRatesResponse = {
  __typename?: 'ControllerDNSRequestRatesResponse';
  values: Array<ControllerDnsRequestRatesValue>;
};

export type ControllerDnsRequestRatesValue = MetricsValue & {
  __typename?: 'ControllerDNSRequestRatesValue';
  timestamp: Scalars['DateTime']['output'];
  /** UUID of the DNS server. */
  uuid: Scalars['UUID']['output'];
  /** Requests per second. */
  value: Scalars['Float']['output'];
};

/** A controller hardware device represents a physical controller (aka Security Appliance). */
export type ControllerHardwareDevice = HardwareDevice & {
  __typename?: 'ControllerHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A controller hardware device represents a physical controller (aka Security Appliance). */
export type ControllerHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type ControllerPortMetricsRateResponse = {
  __typename?: 'ControllerPortMetricsRateResponse';
  values: Array<ControllerPortMetricsRateValue>;
};

export type ControllerPortMetricsRateValue = {
  __typename?: 'ControllerPortMetricsRateValue';
  /** Total number of broadcast packets per second received in the given time period; a subset of totalRxPackets. */
  broadcastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of broadcast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  dropsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second received in the given time period; a subset of totalRxPackets. */
  multicastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets per second that encountered errors being received. */
  rxErrPerSecond: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes per second received in the given time period. */
  totalRxBytesPerSecond: Scalars['Float']['output'];
  /** Total number of bytes per second transmitted in the given time period. */
  totalTxBytesPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that encountered errors during transmission. */
  txErrPerSecond: Scalars['Float']['output'];
};

export type ControllerPortMetricsResponse = {
  __typename?: 'ControllerPortMetricsResponse';
  values: Array<ControllerPortMetricsValue>;
};

/** Counter stats per controller port over a lookback period. */
export type ControllerPortMetricsValue = {
  __typename?: 'ControllerPortMetricsValue';
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Float']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Float']['output'];
  /** The number of packets that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  drops: Scalars['Float']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Float']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets that encountered errors being received. */
  rxErr: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Float']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Float']['output'];
  /** The number of packets that encountered errors during transmission. */
  txErr: Scalars['Float']['output'];
};

/** Summed stats (unless otherwise specified) per controller port over a lookback period. */
export type ControllerPortStat = {
  __typename?: 'ControllerPortStat';
  /** Total number of broadcast bytes received in the given time period; a subset of totalRxBytes. */
  broadcastRxBytes: Scalars['Int']['output'];
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Int']['output'];
  /** Total number of broadcast bytes transmitted in the given time period; a subset of totalTxBytes. */
  broadcastTxBytes: Scalars['Int']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Int']['output'];
  /** The number of packets that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  drops: Scalars['Int']['output'];
  /** Most recent hardware up state. Indicates that a physical connection is established, typically meaning a cable is properly connected and active. */
  hwUp: Scalars['Boolean']['output'];
  /** The number of IPv4 packets transmit/received. */
  ipv4: Scalars['Int']['output'];
  /** The number of IPv6 packets transmit/received. */
  ipv6: Scalars['Int']['output'];
  /** Total number of multicast bytes received in the given time period; a subset of totalRxBytes. */
  multicastRxBytes: Scalars['Int']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Int']['output'];
  /** Total number of multicast bytes transmitted in the given time period; a subset of totalTxBytes. */
  multicastTxBytes: Scalars['Int']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Int']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** This field is not currently being sent by firmware. */
  punts: Scalars['Int']['output'];
  /** The number of packets that encountered errors being received. */
  rxErr: Scalars['Int']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Int']['output'];
  /** Total number of packets received in the given time period. */
  totalRxPackets: Scalars['Int']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Int']['output'];
  /** Total number of packets transmitted in the given time period. */
  totalTxPackets: Scalars['Int']['output'];
  /** The number of packets that encountered errors during transmission. */
  txErr: Scalars['Int']['output'];
  /** Total number of unicast bytes received in the given time period; a subset of totalRxBytes. */
  unicastRxBytes: Scalars['Int']['output'];
  /** Total number of unicast packets received in the given time period; a subset of totalRxPackets. */
  unicastRxPackets: Scalars['Int']['output'];
  /** Total number of unicast bytes transmitted in the given time period; a subset of totalTxBytes. */
  unicastTxBytes: Scalars['Int']['output'];
  /** Total number of unicast packets transmitted in the given time period; a subset of totalTxPackets. */
  unicastTxPackets: Scalars['Int']['output'];
};

/** A controller virtual device is a logical representation of a physical controller. */
export type ControllerVirtualDevice = VirtualDevice & {
  __typename?: 'ControllerVirtualDevice';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  /** Configuration for high availability associated with this controller, if configured in HA mode. */
  highAvailability?: Maybe<HighAvailabilityControllerVirtualDeviceInfo>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  phyInterfaces: Array<PhyInterface>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type CopyIpSecTunnelFromConfig1ToConfig2Input = {
  /** This is the name of the tunnel within the config 1 config. It is the portion after `meter.v1.network.vpn.ipsec.` */
  config1Name: Scalars['String']['input'];
  /** This preshared key is stored on disk on a controller at /etc/ipsec.d/$config1Name.secrets */
  presharedKey: Scalars['String']['input'];
};

export type CreateAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CreateAlertReceiverWithTargetsInput = {
  label: Scalars['String']['input'];
  targets: Array<AlertTargetInput>;
};

export type CreateAlertTargetWebhookInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  /** Optionally allow the customer to specify their signing secret */
  signingSecret?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type CreateApplicationDnsFirewallRuleInput = {
  action: FirewallRuleAction;
  /** This is a netstar application ID. */
  applicationID?: InputMaybe<Scalars['Int']['input']>;
  /** This is a netstar category ID. */
  categoryID?: InputMaybe<Scalars['Int']['input']>;
  /** domain is a FQDN that is matched, allowing for wildcards denoted by '*'. */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** This is a netstar group ID. */
  groupID?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  /** If not provided will default to 1 greater than the highest existing priority. */
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAutoVpnGroupHubUplinkInput = {
  address: Scalars['IP']['input'];
  addressPrefixLength: Scalars['Int']['input'];
  isFailoverEnabled?: Scalars['Boolean']['input'];
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type CreateAutoVpnGroupInput = {
  hubNetworkUUID: Scalars['UUID']['input'];
  isHubFailoverEnabled?: Scalars['Boolean']['input'];
  members?: InputMaybe<Array<AddNetworkToAutoVpnGroupInput>>;
  name: Scalars['String']['input'];
  uplink: CreateAutoVpnGroupHubUplinkInput;
};

export type CreateAutoVpnRouteInput = {
  dstGateway: Scalars['IPV4']['input'];
  dstPrefixLength: Scalars['Int']['input'];
  isEnabled?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateBulkNosUpgradeInput = {
  networkUUIDs: Array<Scalars['UUID']['input']>;
  nosVersionID: Scalars['Int']['input'];
};

export type CreateClientVpnClientInput = {
  /** end user's company email, associated with the VPN server's controller, e.g. angie@meter.com */
  email: Scalars['String']['input'];
  /** end user device name, e.g. paulmbp */
  name: Scalars['String']['input'];
  /** a Curve25519 public key - the end user should generate via wireguard and provide */
  publicKey: Scalars['String']['input'];
};

export type CreateClientVpnServerInput = {
  address: Scalars['IPV4']['input'];
  addressMask: Scalars['Int']['input'];
  defaultClientAllowedIPs?: Array<Scalars['IPPrefix']['input']>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: InputMaybe<Scalars['IPV4']['input']>;
  isFailoverEnabled?: Scalars['Boolean']['input'];
  listenPort?: InputMaybe<Scalars['Int']['input']>;
  /** Must be an uplink phy interface. The WireGuard interface will listen on this interface. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateCompanyInput = {
  /** A prospective company is used for sales purposes. */
  isProspective?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

/** Create a new user for the specified company, with the provided attributes. */
export type CreateCompanyUserInput = {
  /**
   * companyMembershipRole refers to the old style of granting permissions. This will be deprecated in the future,
   * but is currently required as only those with an admin `CompanyMembershipRole` can access dashboard.
   */
  companyMembershipRole: CompanyMembershipRole;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CreateDhcpOptionInput = {
  code: Scalars['Int']['input'];
  data: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDhcpReservedRangeInput = {
  endIPAddress: Scalars['IP']['input'];
  startIPAddress: Scalars['IP']['input'];
};

export type CreateDhcpRuleInput = {
  dnsCacheIsEnabled?: Scalars['Boolean']['input'];
  dnsCacheMaxTTL?: Scalars['Int']['input'];
  dnsCacheSize?: Scalars['Int']['input'];
  dnsSearchDomains?: Array<Scalars['String']['input']>;
  dnsUpstreamServers?: Array<Scalars['IP']['input']>;
  dnsUseGatewayProxy?: Scalars['Boolean']['input'];
  endIPAddress: Scalars['IP']['input'];
  gatewayIPAddress: Scalars['IP']['input'];
  gatewayPrefixLength: Scalars['Int']['input'];
  isIPv6: Scalars['Boolean']['input'];
  leaseDurationSeconds: Scalars['Int']['input'];
  startIPAddress: Scalars['IP']['input'];
};

export type CreateDhcpStaticMappingInput = {
  hostname?: InputMaybe<Scalars['String']['input']>;
  ipAddress: Scalars['IP']['input'];
  macAddress: Scalars['MacAddress']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDnsHostMappingInput = {
  destinationDomain?: InputMaybe<Scalars['String']['input']>;
  destinationIPAddress?: InputMaybe<Scalars['IP']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  overrideDomain: Scalars['String']['input'];
};

/** Used to mark a device as 'dev', moving it into the dev (staging) realm for testing purposes. */
export type CreateDevHardwareDeviceInput = {
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  serialNumber: Scalars['String']['input'];
};

export type CreateDeviceHeartbeatInput = {
  serialNumber: Scalars['String']['input'];
};

export type CreateEncryption8021XInput = {
  accountingServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  accountingServerPort?: InputMaybe<Scalars['Int']['input']>;
  accountingServerSecret?: InputMaybe<Scalars['String']['input']>;
  authServerIPAddress: Scalars['IP']['input'];
  authServerPort: Scalars['Int']['input'];
  authServerSecret: Scalars['String']['input'];
  label: Scalars['String']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type CreateFirewallRuleInput = {
  action: FirewallRuleAction;
  /** The phy interfaces must be uplinks. */
  boundPhyInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  boundVLANUUIDs: Array<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  dstIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** @deprecated Use `dstPortRanges` instead. */
  dstPortRange?: InputMaybe<PortRangeInput>;
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  dstVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  isBidirectional?: Scalars['Boolean']['input'];
  isEnabled: Scalars['Boolean']['input'];
  isMeterInternal: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  /** @deprecated Use `protocols` instead. */
  protocol?: InputMaybe<IpProtocol>;
  protocols?: InputMaybe<Array<IpProtocol>>;
  srcClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  srcIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** @deprecated Use `srcPortRanges` instead. */
  srcPortRange?: InputMaybe<PortRangeInput>;
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateFloorPlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageS3Key: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type CreateHighAvailabilityPairInput = {
  /** Interval at which the controllers will advertise to one another, in milliseconds. */
  advertisementIntervalMs?: Scalars['Int']['input'];
  /** VLAN ID to use for the advertisement VLAN. */
  advertisementVLANID?: Scalars['Int']['input'];
  /** Enable or disable preemption for the HA controllers in VRRP. */
  preempt: Scalars['Boolean']['input'];
};

export type CreateHostMonitoringTargetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  targetHost: Scalars['IPV4']['input'];
  type: HostMonitoringType;
};

export type CreateIpSecTunnelInput = {
  authenticationAlgorithm?: InputMaybe<IpSecAuthenticationAlgorithm>;
  encryptionAlgorithm?: InputMaybe<IpSecEncryptionAlgorithm>;
  isEnabled: Scalars['Boolean']['input'];
  isInitiator?: Scalars['Boolean']['input'];
  keyExchangeDHGroup?: InputMaybe<IpSecDhGroup>;
  leftID: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** The phy interface must be an uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
  /** presharedKey can be base64 or plaintext. */
  presharedKey: Scalars['String']['input'];
  right?: InputMaybe<Scalars['String']['input']>;
  rightID: Scalars['String']['input'];
  rightPrefixes: Array<Scalars['IPPrefix']['input']>;
  vlanUUIDs: Array<Scalars['UUID']['input']>;
};

export type CreateInternalNoteInput = {
  isImportant?: Scalars['Boolean']['input'];
  note: Scalars['String']['input'];
  /** Use the `createPreSignedUploadUrl` mutation to generate S3 keys and presigned URLs for the photos, and specify them here. */
  photoS3Keys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateInternetServicePlanInput = {
  address?: InputMaybe<CreateAddressInput>;
  connection: InternetServicePlanConnectionInput;
  ipConfiguration: InternetServicePlanIpInput;
  providerUUID: Scalars['UUID']['input'];
};

export type CreateMailingAddressInput = {
  city: Scalars['String']['input'];
  countryISO2: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  subdivisionCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNetworkCopyInput = {
  /** If not provided, the new label is "<old label> (Copy)". */
  label?: InputMaybe<Scalars['String']['input']>;
  /** If neither `mailingAddressUUID` or `mailingAddressInput` are provided, the mailing address will match the source network's. */
  mailingAddressInput?: InputMaybe<CreateMailingAddressInput>;
  /** If neither `mailingAddressUUID` or `mailingAddressInput` are provided, the mailing address will match the source network's. */
  mailingAddressUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** If not provided, the new slug is "<old slug>-copy". */
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNetworkInput = {
  companySlug?: InputMaybe<Scalars['String']['input']>;
  isActive?: Scalars['Boolean']['input'];
  /** Enables controller to upgrade config-1 APs deployed to the network */
  isConfig1WosUpgradesEnabled?: Scalars['Boolean']['input'];
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Not a network property anymore */
  isTest?: Scalars['Boolean']['input'];
  label: Scalars['String']['input'];
  /** @deprecated Use `mailingAddressInput` instead. */
  mailingAddress?: InputMaybe<Scalars['String']['input']>;
  mailingAddressInput?: InputMaybe<CreateMailingAddressInput>;
  mailingAddressUUID?: InputMaybe<Scalars['UUID']['input']>;
  multiWANAlgorithm?: InputMaybe<MultiWanAlgorithm>;
  patchPanelDiagramURL?: InputMaybe<Scalars['String']['input']>;
  /** A NOS version should be specified. If not, the default no-upgrades NOS version is assigned */
  pinnedNOSVersionID?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
  /** @deprecated Not a network property anymore */
  squareFeet?: InputMaybe<Scalars['Int']['input']>;
  staggeredUpgradeIntervalMin?: InputMaybe<Scalars['Int']['input']>;
  topologyImageS3Key?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNosFeatureInput = {
  description: Scalars['String']['input'];
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  maxMajorVersion?: InputMaybe<Scalars['Int']['input']>;
  maxMinorVersion?: InputMaybe<Scalars['Int']['input']>;
  maxPatchVersion?: InputMaybe<Scalars['Int']['input']>;
  minMajorVersion: Scalars['Int']['input'];
  minMinorVersion: Scalars['Int']['input'];
  minPatchVersion: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type CreateNotificationSettingInput = {
  alertReceiverUUID: Scalars['UUID']['input'];
  arguments: Array<NotificationSettingArgumentInput>;
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  /** Defaults to true */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDefinitionName: Scalars['String']['input'];
};

export type CreateOneToOneNatRuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalIPAddr: Scalars['IPV4']['input'];
  externalPhyInterfaceUUID: Scalars['UUID']['input'];
  internalIPAddr: Scalars['IPV4']['input'];
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreatePermittedEmailDomainInput = {
  companySlug: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

export type CreatePolicyRoutingRuleInput = {
  bindings: Array<PolicyRoutingRuleBindingInput>;
  dstPortRanges: Array<PortRangeInput>;
  dstPrefix: Scalars['IPPrefix']['input'];
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  protocols: Array<IpProtocol>;
  srcPortRanges: Array<PortRangeInput>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreatePortForwardingRuleInput = {
  allowedRemoteIPAddresses?: InputMaybe<Array<Scalars['IP']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** External uplink physical interface. Currently required despite schema being optional. */
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  externalPort: Scalars['Int']['input'];
  isEnabled: Scalars['Boolean']['input'];
  localIPAddress: Scalars['IP']['input'];
  localPort: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  protocol: PortIpProtocol;
};

export type CreatePreSignedUploadUrlInput = {
  fileExtension: Scalars['String']['input'];
  folder: AllowedFolder;
};

export type CreateRackElevationInput = {
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: InputMaybe<Scalars['Int']['input']>;
  label: Scalars['String']['input'];
  notes?: InputMaybe<Array<RackElevationNoteInput>>;
  rackMountUnitCount: Scalars['Int']['input'];
};

export type CreateRadioProfileInput = {
  /** Required if band2_4GAutoChannelIsEnabled is true. */
  band2_4GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  band2_4GAutoChannelIsEnabled: Scalars['Boolean']['input'];
  band2_4GAutoChannelWidthIsEnabled: Scalars['Boolean']['input'];
  band2_4GAutoTxPowerIsEnabled: Scalars['Boolean']['input'];
  /** Required if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band2_4GAutoChannelWidthIsEnabled is false. */
  band2_4GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  band2_4GIsEnabled: Scalars['Boolean']['input'];
  /** Required if band5GAutoChannelIsEnabled is true. */
  band5GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  band5GAutoChannelIsEnabled: Scalars['Boolean']['input'];
  band5GAutoChannelWidthIsEnabled: Scalars['Boolean']['input'];
  band5GAutoTxPowerIsEnabled: Scalars['Boolean']['input'];
  /** Required if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band5GAutoChannelWidthIsEnabled is false. */
  band5GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  band5GIsEnabled: Scalars['Boolean']['input'];
  /** Setting this to true will set all other profiles to false. */
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** The metadata required to create radio settings for an access point. */
export type CreateRadioSettingsInput = {
  band2_4GPrimaryChannel: Scalars['Int']['input'];
  band2_4GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band2_4GTransmitPowerdBm: Scalars['Int']['input'];
  band5GPrimaryChannel: Scalars['Int']['input'];
  band5GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band5GTransmitPowerdBm: Scalars['Int']['input'];
};

export type CreateRateLimitRuleInput = {
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstMac?: InputMaybe<Scalars['MacAddress']['input']>;
  dstPortRange?: InputMaybe<PortRangeInput>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  isPerSource?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  protocol?: InputMaybe<IpProtocol>;
  rateLimitKbpsDownload?: InputMaybe<Scalars['Int']['input']>;
  rateLimitKbpsUpload?: InputMaybe<Scalars['Int']['input']>;
  srcMac?: InputMaybe<Scalars['MacAddress']['input']>;
  srcPortRange?: InputMaybe<PortRangeInput>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
};

export type CreateSsidInput = {
  /**
   * The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second.
   * If not set, will be set to a default value.
   */
  band2GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second.
   * If not set, will be set to a default value.
   */
  band5GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  clientSteeringAssociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientSteeringLoadBalancingClientMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  clientSteeringPostassociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientSteeringPreassociationRSSIMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dtimPeriod?: InputMaybe<Scalars['Int']['input']>;
  dynamicVLANMode?: InputMaybe<SsidDynamicVlanMode>;
  /** Required if isEnabledForAllAccessPoints is false. */
  enabledAccessPointVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  encryption80211wProtectedManagementFramesMode?: InputMaybe<SsidEncryption80211wProtectedManagementFramesMode>;
  encryptionProtocol?: InputMaybe<SsidEncryptionProtocol>;
  is80211axEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isBand2GAllowed: Scalars['Boolean']['input'];
  isBand5GAllowed: Scalars['Boolean']['input'];
  isEnabled: Scalars['Boolean']['input'];
  isEnabledForAllAccessPoints: Scalars['Boolean']['input'];
  isGuest?: Scalars['Boolean']['input'];
  isHidden: Scalars['Boolean']['input'];
  isIsolateClientsEnabled: Scalars['Boolean']['input'];
  isRoaming80211kEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211rEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211vEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRotationCadence?: InputMaybe<SsidPasswordRotationCadence>;
  passwordRotationDayOfMonthLocal?: InputMaybe<Scalars['Int']['input']>;
  passwordRotationDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  passwordRotationHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the primary 8021x encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  primaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  radiusCoaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** If unset, default port will be 3799 as per RFC 3576. */
  radiusCoaPort?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the secondary 8021x encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  secondaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  ssid: Scalars['String']['input'];
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateSsoDashboardForCompanyResult = {
  __typename?: 'CreateSSODashboardForCompanyResult';
  company: Company;
  dashboardURL: Scalars['String']['output'];
};

export type CreateSsoRedirectUriResult = {
  __typename?: 'CreateSSORedirectURIResult';
  uri: Scalars['String']['output'];
};

export type CreateSsoVerifyInput = {
  code: Scalars['String']['input'];
  /** @deprecated Source is no longer important. */
  source?: InputMaybe<SsoVerificationSource>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSsoVerifyResult = {
  __typename?: 'CreateSSOVerifyResult';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** If the session was created via impersonation, this field will be set. */
  impersonator?: Maybe<Impersonator>;
  lastName: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
  preview?: Maybe<Scalars['Boolean']['output']>;
  via?: Maybe<Scalars['String']['output']>;
};

export type CreateSsoOAuthUriResult = {
  __typename?: 'CreateSsoOAuthURIResult';
  googleUri: Scalars['String']['output'];
  microsoftUri: Scalars['String']['output'];
};

export type CreateStaticRouteInput = {
  dstPrefix: Scalars['IPPrefix']['input'];
  gatewayDHCPStaticMappingUUID?: InputMaybe<Scalars['UUID']['input']>;
  gatewayIP?: InputMaybe<Scalars['IPV4']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateSwitchAutoConfigMacAddressVlanBindingInput = {
  OUI?: InputMaybe<Scalars['String']['input']>;
  VLANUUID: Scalars['UUID']['input'];
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
};

export type CreateSwitchProfileInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  networkUUID: Scalars['UUID']['input'];
  stpBridgePriority?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserNetworkAccessInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  networkUUID: Scalars['UUID']['input'];
};

export type CreateVlanInput = {
  createDHCPRules?: InputMaybe<Array<CreateDhcpRuleInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ipV4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipV4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  ipV4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  ipV6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipV6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  ipV6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  isInternal?: Scalars['Boolean']['input'];
  isMulticastReflectionEnabled?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  /** @deprecated Use `networkUUID` in `createVLAN` instead. */
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  vlanID: Scalars['Int']['input'];
};

/** The metadata required to create a virtual device. */
export type CreateVirtualDeviceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  label: Scalars['String']['input'];
  radioSettings?: InputMaybe<CreateRadioSettingsInput>;
  switchProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
};

/** Create many virtual devices at once, with the same type and model. */
export type CreateVirtualDevicesInput = {
  /** How many virtual devices to create, up to 100. */
  count: Scalars['Int']['input'];
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  /** The labels of the devices will all start with the prefix, followed by an increasing suffix number (e.g. "AP" -> "AP01", "AP02", "AP03", ...) */
  labelPrefix: Scalars['String']['input'];
  /** The starting number to suffix the labels with, defaults to 1. The numbers will be zero-padded at the front. */
  labelSuffixIndex?: Scalars['Int']['input'];
};

export type CreateWifiObserverSettingInput = {
  SSIDUUID: Scalars['UUID']['input'];
  band: RadioBand;
  commands?: InputMaybe<Array<WosObserverCommandInput>>;
  durationMsec: Scalars['Int']['input'];
  numSpatialStreams?: InputMaybe<Scalars['Int']['input']>;
  password8021X?: InputMaybe<Scalars['String']['input']>;
  targetVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  username8021X?: InputMaybe<Scalars['String']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** Arbitrary DHCP option values to pass to Kea configuration that we do not yet have dedicated fields for. */
export type DhcpOption = {
  __typename?: 'DHCPOption';
  UUID: Scalars['UUID']['output'];
  /** The DHCP option code (also called tag), see https://www.iana.org/assignments/bootp-dhcp-parameters/bootp-dhcp-parameters.xhtml for a list. */
  code: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dhcpRuleUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Sub ranges within the `DHCPRule` range to reserve for static IP assignment and not issue DHCP leases within. */
export type DhcpReservedRange = {
  __typename?: 'DHCPReservedRange';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dhcpRuleUUID: Scalars['UUID']['output'];
  /** Last reserved IP address in the range, ascending and inclusive. */
  endIPAddress: Scalars['IP']['output'];
  /** First reserved IP address in the range, ascending and inclusive. */
  startIPAddress: Scalars['IP']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Stores specific information about a DHCP server for a specific interface, equivalent to a Kea 'subnet'. */
export type DhcpRule = {
  __typename?: 'DHCPRule';
  UUID: Scalars['UUID']['output'];
  applicationDNSFirewallRules: Array<ApplicationDnsFirewallRule>;
  createdAt: Scalars['DateTime']['output'];
  /** Sets if DNS caching is enabled on the DHCP server. If false, other caching options are ignored. */
  dnsCacheIsEnabled: Scalars['Boolean']['output'];
  dnsCacheMaxTTL: Scalars['Int']['output'];
  dnsCacheSize: Scalars['Int']['output'];
  dnsHostMappings: Array<DnsHostMapping>;
  dnsSearchDomains?: Maybe<Array<Scalars['String']['output']>>;
  /** Upstream DNS servers to use when `dnsUseGatewayProxy` is true, or to use directly when disabled. */
  dnsUpstreamServers: Array<Scalars['IP']['output']>;
  dnsUseGatewayProxy: Scalars['Boolean']['output'];
  /** Last available IP address in the range, ascending and inclusive. */
  endIPAddress: Scalars['IP']['output'];
  /** The IP address to send to DHCP clients (routers, DHCP option 3). */
  gatewayIPAddress: Scalars['IP']['output'];
  gatewayPrefixLength: Scalars['Int']['output'];
  isIPv6: Scalars['Boolean']['output'];
  /** The length of the DHCP lease that clients receive */
  leaseDurationSeconds: Scalars['Int']['output'];
  options: Array<DhcpOption>;
  reservedRanges: Array<DhcpReservedRange>;
  /** First available IP address in the range, ascending and inclusive. */
  startIPAddress: Scalars['IP']['output'];
  staticMappings: Array<DhcpStaticMapping>;
  updatedAt: Scalars['DateTime']['output'];
  vlan: Vlan;
  vlanUUID: Scalars['UUID']['output'];
};

/** DHCP static mappings between MAC address and IP address to instruct DHCP servers to always assign a client a predefined IP address. */
export type DhcpStaticMapping = {
  __typename?: 'DHCPStaticMapping';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dhcpRuleUUID: Scalars['UUID']['output'];
  hostname?: Maybe<Scalars['String']['output']>;
  /** Assigned IP address for client device through static DHCP. Application logic will ensure this ip is within the associated `dhcp_rule.gateway_prefix`. */
  ipAddress?: Maybe<Scalars['IP']['output']>;
  /** MAC address of the client device. */
  macAddress: Scalars['MacAddress']['output'];
  /** 'A name for use as a label for this DHCP static mapping. It has no meaning for a Meter device. */
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Predefined mappings between a domain and IP address, overriding regular DNS lookups for the given domain. */
export type DnsHostMapping = {
  __typename?: 'DNSHostMapping';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The domain to which override_domain should be resolved. If specified, this mapping acts like a CNAME record. */
  destinationDomain?: Maybe<Scalars['String']['output']>;
  /** The IP address to which override_domain should be resolved. If specified, this mapping acts like an A record. */
  destinationIPAddress?: Maybe<Scalars['IP']['output']>;
  dhcpRuleUUID: Scalars['UUID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** The domain name to override. This can be a FQDN or a domain name. */
  overrideDomain: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DeleteDeviceHeartbeatInput = {
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type DeletePermittedEmailDomainInput = {
  companySlug: Scalars['String']['input'];
  permittedEmailDomainSID: Scalars['UUID']['input'];
};

export type DeletePermittedEmailDomainResult = {
  __typename?: 'DeletePermittedEmailDomainResult';
  numRowsDeleted: Scalars['Int']['output'];
};

export type DeleteWifiObserverSettingInput = {
  SSIDUUID: Scalars['UUID']['input'];
  band: RadioBand;
  targetVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** The union of a hardware device and a virtual device. */
export type Device = {
  __typename?: 'Device';
  hardwareDevice: HardwareDevice;
  virtualDevice: VirtualDevice;
};

export type DeviceConfig = {
  __typename?: 'DeviceConfig';
  /** If null, then the device has not acknowledged this version of config. */
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
  configJSON: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type DeviceConfigOverride = {
  __typename?: 'DeviceConfigOverride';
  configJSON: Scalars['JSONObject']['output'];
};

export type DeviceHeartbeat = {
  __typename?: 'DeviceHeartbeat';
  UUID: Scalars['UUID']['output'];
  heartbeatIntervalSeconds: Scalars['Int']['output'];
  heartbeatURL: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** DeviceModel enumerates the different model names of devices that Meter deploys. */
export enum DeviceModel {
  Mc01 = 'MC01',
  Mc05 = 'MC05',
  Mc06 = 'MC06',
  Mc11 = 'MC11',
  Mp01 = 'MP01',
  Ms07 = 'MS07',
  Ms10 = 'MS10',
  Ms11 = 'MS11',
  Ms12 = 'MS12',
  Mw02 = 'MW02',
  Mw03 = 'MW03',
  Mw04 = 'MW04',
  Mw05 = 'MW05',
  Mw06 = 'MW06',
  Mw07 = 'MW07',
  Mw08 = 'MW08',
  Mw09 = 'MW09',
  Psgs1518Af = 'PSGS1518AF',
  Psgs1526F = 'PSGS1526F',
  Psgs1626Gf = 'PSGS1626GF',
  Psgs2326Kf = 'PSGS2326KF',
  Psgs2852Bf = 'PSGS2852BF',
  Psgs2852Xbf = 'PSGS2852XBF',
  Psgs2852Xbl = 'PSGS2852XBL',
}

/** DeviceType enumerates the different types of hardware devices that Meter deploys. */
export enum DeviceType {
  AccessPoint = 'ACCESS_POINT',
  Controller = 'CONTROLLER',
  PowerDistributionUnit = 'POWER_DISTRIBUTION_UNIT',
  Switch = 'SWITCH',
}

export type DevicesForNetworkFilter = {
  deviceType?: InputMaybe<VirtualDeviceType>;
};

/** A discovered service advertising via mdns (e.g. printers, airplay, etc). */
export type DiscoveredService = {
  __typename?: 'DiscoveredService';
  ipAddress: Scalars['IP']['output'];
  macAddress: Scalars['MacAddress']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  serviceType: ServiceType;
  timestamp: Scalars['DateTime']['output'];
};

export type DiscoveredServicesInput = {
  /** Number of minutes to look back for available services. */
  numMinutesLookback: Scalars['Int']['input'];
  /** List of service types to return. */
  serviceTypes: Array<ServiceType>;
};

export type DownloadUrlInput = {
  s3Key: Scalars['String']['input'];
};

/** Represents the 802.1X encryption configuration for an SSID. */
export type Encryption8021X = {
  __typename?: 'Encryption8021X';
  UUID: Scalars['UUID']['output'];
  /** The IP address of the accounting server. */
  accountingServerIPAddress?: Maybe<Scalars['IP']['output']>;
  /** The port of the accounting server. */
  accountingServerPort?: Maybe<Scalars['Int']['output']>;
  /** The shared secret used to authenticate to the accounting server. */
  accountingServerSecret?: Maybe<Scalars['String']['output']>;
  /** The IP address of the authentication server. */
  authServerIPAddress: Scalars['IP']['output'];
  /** The port of the authentication server. */
  authServerPort: Scalars['Int']['output'];
  /** The shared secret used to authenticate to the authentication server. */
  authServerSecret: Scalars['String']['output'];
  label: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The SSIDs this 802.1X encryption configuration is associated with. */
  ssids?: Maybe<Array<Ssid>>;
};

export type EventLogEvent = {
  __typename?: 'EventLogEvent';
  eventType: EventType;
  eventTypeAPIName: Scalars['String']['output'];
  generatedAt: Scalars['DateTime']['output'];
  insertedAt: Scalars['DateTime']['output'];
  networkUUID: Scalars['UUID']['output'];
  properties: EventLogProperties;
};

export type EventLogProperties = WanDownProperties | WanStatusChangeProperties | WanUpProperties;

/** Enumerate the known event types that exist in the event log. */
export enum EventType {
  Unspecified = 'UNSPECIFIED',
  WanDown = 'WAN_DOWN',
  WanStatusChange = 'WAN_STATUS_CHANGE',
  WanUp = 'WAN_UP',
}

export type FileDownloadPayload = {
  __typename?: 'FileDownloadPayload';
  presignedUrl: Scalars['String']['output'];
};

export type FileUploadPayload = {
  __typename?: 'FileUploadPayload';
  /** Format: <folder>/<date_time_stamp>_<uuid>.<extension> */
  key: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

export type FirewallPhyInterfaceBinding = {
  __typename?: 'FirewallPhyInterfaceBinding';
  metric: Scalars['Int']['output'];
  phyInterface: PhyInterface;
};

export type FirewallRule = {
  __typename?: 'FirewallRule';
  UUID: Scalars['UUID']['output'];
  action: FirewallRuleAction;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dstClientVPNServer?: Maybe<ClientVpnServer>;
  dstIPSecTunnel?: Maybe<IpSecTunnel>;
  /** @deprecated Use `dstPortRanges` instead. */
  dstPortRange: PortRange;
  dstPortRanges: Array<PortRange>;
  dstPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  dstVLAN?: Maybe<Vlan>;
  /**
   * If a firewall rule is bidirectional then the config renderer will generate an additional rule(s)
   * where the src and dst options are swapped.
   */
  isBidirectional: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isMeterInternal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  phyInterfaceBindings?: Maybe<Array<FirewallPhyInterfaceBinding>>;
  /** @deprecated Use `protocols` instead. */
  protocol: IpProtocol;
  protocols: Array<IpProtocol>;
  srcClientVPNServer?: Maybe<ClientVpnServer>;
  srcIPSecTunnel?: Maybe<IpSecTunnel>;
  /** @deprecated Use `srcPortRanges` instead. */
  srcPortRange: PortRange;
  srcPortRanges: Array<PortRange>;
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  srcVLAN?: Maybe<Vlan>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  vlanBindings?: Maybe<Array<FirewallVlanBinding>>;
};

export enum FirewallRuleAction {
  Deny = 'DENY',
  Permit = 'PERMIT',
}

export type FirewallVlanBinding = {
  __typename?: 'FirewallVLANBinding';
  metric: Scalars['Int']['output'];
  vlan: Vlan;
};

/**
 * A floor plan is an overhead view of a physical space. A floor plan is one-to-many to floor plan locations,
 * which are x/y coordinates for Meter devices in the physical space.
 */
export type FloorPlan = {
  __typename?: 'FloorPlan';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** A pre-signed URL to download the floor plan image. */
  imageDownloadURL: Scalars['String']['output'];
  imageS3Key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GetCaptivePortalInput = {
  /** This field does not do anything; this is a placeholder for future use and will be deprecated once this input is actually used. */
  TODO?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Additional options that can be used to customize the results of a query for company users. */
export type GetCompanyUserInput = {
  /** If true, include company users that have been deleted. */
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetNotificationPreferencesInput = {
  companySlug: Scalars['String']['input'];
  controllerName: Scalars['String']['input'];
};

/** A hardware device represents a physical device. */
export type HardwareDevice = {
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  /** If the device is no longer connected to the backend, this is the time at which it was last connected. */
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** Spare hardware devices that are not affiliated with a virtual device will have this field populated. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A hardware device represents a physical device. */
export type HardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** Filters the response when fetching multiple hardware devices. */
export type HardwareDevicesFilter = {
  deviceModel?: InputMaybe<DeviceModel>;
  deviceType?: InputMaybe<DeviceType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum HighAvailabilityControllerRole {
  Backup = 'BACKUP',
  Primary = 'PRIMARY',
}

export type HighAvailabilityControllerVirtualDeviceInfo = {
  __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
  /** Configuration associated with the HA configuration of this virtual device. */
  config: HighAvailabilityControllersConfig;
  /** The role this device is configured to serve as. This does not indicate whether the device is currently serving traffic. */
  role: HighAvailabilityControllerRole;
  /** The status of the HA device, reported by the device. */
  status: HighAvailabilityStatus;
};

/** Configuration of the high availability controllers. */
export type HighAvailabilityControllersConfig = {
  __typename?: 'HighAvailabilityControllersConfig';
  UUID: Scalars['UUID']['output'];
  /** Interval at which the controllers advertise to one another, in milliseconds. */
  advertisementIntervalMs: Scalars['Int']['output'];
  /** Port the controllers use to communicate over. This is currently _required_ to be the same port on both controllers. */
  advertisementPort: Scalars['Int']['output'];
  /** VLAN whose sole purpose is for the HA controllers to communicate with each other. */
  advertisementVLAN: Vlan;
  /** PhyInterface for the controller configured as backup in the HA setup. */
  backupPhyInterface: PhyInterface;
  backupStatus: HighAvailabilityStatus;
  /** Network that is configured with HA controllers. */
  network: Network;
  /** Whether or not VRRP is configured for preemption between the controllers in the HA pair. */
  preemption: Scalars['Boolean']['output'];
  /** PhyInterface for the controller configured as primary in the HA setup. */
  primaryPhyInterface: PhyInterface;
  primaryStatus: HighAvailabilityStatus;
};

export enum HighAvailabilityStatus {
  Active = 'ACTIVE',
  Ready = 'READY',
  Unspecified = 'UNSPECIFIED',
}

export type HostMonitoringResponse = MetricsResponse & {
  __typename?: 'HostMonitoringResponse';
  metadata: MetricsMetadata;
  values: Array<HostMonitoringValue>;
};

export enum HostMonitoringStatus {
  Failed = 'FAILED',
  Ok = 'OK',
  Timeout = 'TIMEOUT',
}

/** Host monitoring targets are used to specify hosts for which to monitor availability / reachability on a network. */
export type HostMonitoringTarget = {
  __typename?: 'HostMonitoringTarget';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** targetHost for now is only permitted to be an IP address but will be a FQDN in the future. */
  targetHost: Scalars['IPV4']['output'];
  type: HostMonitoringType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum HostMonitoringType {
  Ping = 'PING',
  Traceroute = 'TRACEROUTE',
}

export type HostMonitoringValue = MetricsValue & {
  __typename?: 'HostMonitoringValue';
  hostIPAddr: Scalars['String']['output'];
  srcEthPortNum: Scalars['Int']['output'];
  status: HostMonitoringStatus;
  timestamp: Scalars['DateTime']['output'];
  /** value is the average RTT in nanoseconds */
  value: Scalars['Float']['output'];
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type Hs20AccessNetworkTypes = {
  __typename?: 'Hs20AccessNetworkTypes';
  description: Scalars['String']['output'];
  /** An access network type with description as defined in Table 9-214 of 802.11-2016 IEEE Standard */
  type: Scalars['Int']['output'];
};

export type Hs20AuthParam = {
  __typename?: 'Hs20AuthParam';
  description: Scalars['String']['output'];
  param: Scalars['Int']['output'];
  validValues: Array<Hs20AuthValue>;
};

/** Hs20AuthValue and Hs20AuthParam are the auth params/values as defined in Table 9-335 of 802.11-2020 IEEE Standard. */
export type Hs20AuthValue = {
  __typename?: 'Hs20AuthValue';
  description: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type Hs20EapMethod = {
  __typename?: 'Hs20EapMethod';
  description: Scalars['String']['output'];
  /**
   * An EAP method, as defined by IANA (http://www.iana.org/assignments/eap-numbers/eap-numbers.xhtml#eap-numbers-4)
   * mostUsed indicates methods that are more widely used, as a hint to the frontend
   */
  method: Scalars['Int']['output'];
  mostUsed: Scalars['Boolean']['output'];
};

export type Hs20ValidTypesResponse = {
  __typename?: 'Hs20ValidTypesResponse';
  accessNetworkTypes: Array<Hs20AccessNetworkTypes>;
  authParams: Array<Hs20AuthParam>;
  eapMethods: Array<Hs20EapMethod>;
  venueTypes: Array<Hs20VenueTypes>;
};

export type Hs20VenueTypes = {
  __typename?: 'Hs20VenueTypes';
  description: Scalars['String']['output'];
  /** A venue type with the group/type/description as defined in Table 9-62 of 802.11-2016 IEEE Standard */
  group: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
};

export enum IpProtocol {
  All = 'ALL',
  Icmp = 'ICMP',
  Tcp = 'TCP',
  Udp = 'UDP',
}

export enum IpSecAuthenticationAlgorithm {
  Cmac_96 = 'CMAC_96',
  Sha1_96 = 'SHA1_96',
  Sha2_256_128 = 'SHA2_256_128',
  Sha2_384_192 = 'SHA2_384_192',
  Sha2_512_256 = 'SHA2_512_256',
  Xcbc_96 = 'XCBC_96',
}

export enum IpSecChildSaStatus {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Deleting = 'DELETING',
  Destroying = 'DESTROYING',
  Installed = 'INSTALLED',
  Installing = 'INSTALLING',
  Rekeyed = 'REKEYED',
  Rekeying = 'REKEYING',
  Retrying = 'RETRYING',
  Routed = 'ROUTED',
  Unspecified = 'UNSPECIFIED',
  Updating = 'UPDATING',
}

export enum IpSecDhGroup {
  Dh2 = 'DH2',
  Dh14 = 'DH14',
  Dh19 = 'DH19',
  Dh20 = 'DH20',
}

export enum IpSecEncryptionAlgorithm {
  AesCbc_128 = 'AES_CBC_128',
  AesCbc_192 = 'AES_CBC_192',
  AesCbc_256 = 'AES_CBC_256',
  AesCtr_128 = 'AES_CTR_128',
  AesCtr_192 = 'AES_CTR_192',
  AesCtr_256 = 'AES_CTR_256',
  AesGcm_16128 = 'AES_GCM_16128',
  AesGcm_16192 = 'AES_GCM_16192',
  AesGcm_16256 = 'AES_GCM_16256',
  Algo_3Des_112 = 'ALGO_3DES_112',
  Algo_3Des_168 = 'ALGO_3DES_168',
}

export enum IpSecSaStatus {
  Connecting = 'CONNECTING',
  Created = 'CREATED',
  Deleting = 'DELETING',
  Destroying = 'DESTROYING',
  Established = 'ESTABLISHED',
  Passive = 'PASSIVE',
  Rekeyed = 'REKEYED',
  Rekeying = 'REKEYING',
  Unspecified = 'UNSPECIFIED',
}

/** Full documentation for IPSec tunnels are at https://libreswan.org/man/ipsec.conf.5.html */
export type IpSecTunnel = {
  __typename?: 'IPSecTunnel';
  UUID: Scalars['UUID']['output'];
  authenticationAlgorithm?: Maybe<IpSecAuthenticationAlgorithm>;
  boundPhyInterface: PhyInterface;
  boundVLANs: Array<Vlan>;
  createdAt: Scalars['DateTime']['output'];
  encryptionAlgorithm?: Maybe<IpSecEncryptionAlgorithm>;
  isEnabled: Scalars['Boolean']['output'];
  isInitiator: Scalars['Boolean']['output'];
  keyExchangeDHGroup?: Maybe<IpSecDhGroup>;
  /** How the left participant should be identified for authentication. Can be an IP address or FQDN which will be validated in application logic. If this is not set, defaults to `%defaultroute`. */
  leftID: Scalars['String']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The public IP address or DNS hostname of the right participant. Can be an IP address or FQDN which will be validated in application logic. */
  right?: Maybe<Scalars['String']['output']>;
  /** The public IP address or DNS hostname of the right participant. Can be an IP address or FQDN which will be validated in application logic. */
  rightID: Scalars['String']['output'];
  /** The private subnet behind the right participant. This is usually 0.0.0.0/0 but in some cases may be different. */
  rightPrefixes: Array<Scalars['IPPrefix']['output']>;
  status?: Maybe<IpSecTunnelStatus>;
  updatedAt: Scalars['DateTime']['output'];
};

export type IpSecTunnelStatus = {
  __typename?: 'IPSecTunnelStatus';
  /** when CHILD_SA expires */
  childLifetime: Scalars['DateTime']['output'];
  /** when CHILD_SA gets rekeyed */
  childRekeyAt: Scalars['DateTime']['output'];
  /** CHILD_SA status */
  childSAStatus: IpSecChildSaStatus;
  /** time IKE_SA got established */
  establishedAt: Scalars['DateTime']['output'];
  /** latency to the remote IKE */
  latencyMs: Scalars['Int']['output'];
  observedAt: Scalars['DateTime']['output'];
  /** time the IKE_SA gets re-authenticated */
  reauthAt: Scalars['DateTime']['output'];
  /** time IKE_SA gets rekeyed */
  rekeyAt: Scalars['DateTime']['output'];
  /** remote IKE endpoint address */
  remoteHostIP4Address: Scalars['String']['output'];
  /** number of input bytes processed */
  rxBytes: Scalars['Int']['output'];
  /** time of last inbound packet, if any */
  rxLast: Scalars['DateTime']['output'];
  /** number of input packets processed */
  rxPackets: Scalars['Int']['output'];
  /** IKA_SA state */
  saStatus: IpSecSaStatus;
  /** number of output bytes processed */
  txBytes: Scalars['Int']['output'];
  /** time of last outbound packet, if any */
  txLast: Scalars['DateTime']['output'];
  /** IKE_SA unique identifier */
  uniqueID: Scalars['String']['output'];
};

/** Category of service provided by the ISP connection. */
export type IspConnectionType = {
  __typename?: 'ISPConnectionType';
  UUID: Scalars['UUID']['output'];
  /** Name of the ISP connection type. Examples: "Dedicated Fiber", "Shared Fiber", "Coax", "Point to Point Wireless", etc. */
  name: Scalars['String']['output'];
};

/** The category of service provided by the ISP. */
export enum IspProduct {
  /** Business cable connection */
  BusinessCable = 'BUSINESS_CABLE',
  /** Dedicated fiber optic connection */
  DedicatedFiber = 'DEDICATED_FIBER',
  /** Point-to-point wireless connection */
  PointToPointWireless = 'POINT_TO_POINT_WIRELESS',
  /** Residential cable connection */
  ResidentialCable = 'RESIDENTIAL_CABLE',
  /** Residential fiber optic connection */
  ResidentialFiber = 'RESIDENTIAL_FIBER',
  /** Shared fiber optic connection */
  SharedFiber = 'SHARED_FIBER',
}

/** Whether the ISP is intended to function as the primary or backup connection. */
export enum IspStatus {
  /** The ISP is configured as the fallback ISP connection. */
  Backup = 'BACKUP',
  /** The ISP is configured as the preferred ISP connection. */
  Primary = 'PRIMARY',
}

export enum ImageType {
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Png = 'PNG',
  Svg = 'SVG',
}

export type Impersonator = {
  __typename?: 'Impersonator';
  email: Scalars['String']['output'];
};

/** Incidents are deprecated. */
export type Incident = {
  __typename?: 'Incident';
  UUID: Scalars['UUID']['output'];
  category?: Maybe<IncidentCategory>;
  endTime: Scalars['DateTime']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
};

export enum IncidentCategory {
  Deinstallation = 'DEINSTALLATION',
  Installation = 'INSTALLATION',
  Maintenance = 'MAINTENANCE',
  Outage = 'OUTAGE',
  Provisioning = 'PROVISIONING',
  Scheduled = 'SCHEDULED',
}

/** Provides the ability for communication between specified VLANs. */
export type InterVlanCommunicationPermittedPair = {
  __typename?: 'InterVLANCommunicationPermittedPair';
  UUID: Scalars['UUID']['output'];
  higherVLAN: Vlan;
  lowerVLAN: Vlan;
  networkUUID: Scalars['UUID']['output'];
};

/** An internal note is a note for Meter teams to share information about a network. */
export type InternalNote = {
  __typename?: 'InternalNote';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The email address of the user who created the note. */
  createdBy: Scalars['String']['output'];
  /** The user who created the note. */
  createdByUser: User;
  /** Important notes can be highlighted in the UI. */
  isImportant: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  note: Scalars['String']['output'];
  photoS3Keys?: Maybe<Array<Scalars['String']['output']>>;
  /** URLs for photos attached to the note. */
  photoURLs?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The email address of the user who updated the note most recently. */
  updatedBy?: Maybe<Scalars['String']['output']>;
  /** The user who updated the note most recently. */
  updatedByUser?: Maybe<User>;
};

/**
 * InternetServicePlan represents the configuration associated with an Internet Service Provider (ISP). This includes
 * information about the provider itself, as well as the IP address configurations for the network.
 */
export type InternetServicePlan = {
  __typename?: 'InternetServicePlan';
  UUID: Scalars['UUID']['output'];
  address?: Maybe<Address>;
  connectionProduct: IspProduct;
  connectionStatus: IspStatus;
  /**
   * ControllerIP is dynamically resolved from stats coming in to Clickhouse. These stats are reported
   * by the controller.
   */
  controllerIP?: Maybe<Scalars['IPV4']['output']>;
  controllerName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  firstUsableIP?: Maybe<Scalars['IPV4']['output']>;
  gatewayAddr?: Maybe<Scalars['IPV4']['output']>;
  hasStaticIP: Scalars['Boolean']['output'];
  lastUsableIP?: Maybe<Scalars['IPV4']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  provider?: Maybe<InternetServicePlanProvider>;
  staticIPRange?: Maybe<Scalars['IPPrefix']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type InternetServicePlanConnectionInput = {
  product: IspProduct;
  status: IspStatus;
};

export type InternetServicePlanIpInput = {
  controllerIP?: InputMaybe<Scalars['IPV4']['input']>;
  controllerName?: InputMaybe<Scalars['String']['input']>;
  firstUsableIP?: InputMaybe<Scalars['IPV4']['input']>;
  gatewayAddr?: InputMaybe<Scalars['IPV4']['input']>;
  lastUsableIP?: InputMaybe<Scalars['IPV4']['input']>;
  staticIPRange?: InputMaybe<Scalars['IPPrefix']['input']>;
};

/** InternetServicePlanProvider represents the Internet Service Provider (ISP) that services an InternetServicePlan. */
export type InternetServicePlanProvider = {
  __typename?: 'InternetServicePlanProvider';
  UUID: Scalars['UUID']['output'];
  /** The URL of the provider's logo. */
  logoURL?: Maybe<Scalars['String']['output']>;
  /** The name of the provider. */
  name: Scalars['String']['output'];
};

export type LatestDeviceCpuLoad = {
  __typename?: 'LatestDeviceCPULoad';
  timestamp: Scalars['DateTime']['output'];
  /**
   * The used CPU percentage represented as a value [0, 1]. This value is computed by adding the latest
   * percentages of user, system, soft_irq, and irq.
   */
  usedPercentage: Scalars['Float']['output'];
};

export type LatestDeviceMemoryUsage = {
  __typename?: 'LatestDeviceMemoryUsage';
  bytesAvailable: Scalars['Int']['output'];
  bytesTotal: Scalars['Int']['output'];
  bytesUsed: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
};

/** Allows users to give a human-friendly name to a MAC address. */
export type MacAddressAlias = {
  __typename?: 'MACAddressAlias';
  MACAddress: Scalars['MacAddress']['output'];
  alias: Scalars['String']['output'];
  companySlug: Scalars['String']['output'];
};

/** The Switch and PhyInterface that a client MAC address is associated with. */
export type MacAddressSwitchEntry = {
  __typename?: 'MACAddressSwitchEntry';
  phyInterface: PhyInterface;
  virtualDevice: VirtualDevice;
};

export type MailingAddress = {
  __typename?: 'MailingAddress';
  UUID: Scalars['UUID']['output'];
  city?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Coordinates>;
  countryISO2?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  subdivisionCode?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Defines a periodic window of time during which customers prefer that maintenance be performed on a network. */
export type MaintenanceWindow = {
  __typename?: 'MaintenanceWindow';
  UUID: Scalars['UUID']['output'];
  endDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  endHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  /** For DAILY recurrence windows only, allows for the exclusion of specific days of the week. */
  excludedDaysOfWeek?: Maybe<Array<Scalars['Int']['output']>>;
  isOptedOut: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  recurrence: MaintenanceWindowRecurrence;
  startDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  startHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export enum MaintenanceWindowRecurrence {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
}

export type MetricsFilterInput = {
  durationSeconds: Scalars['Int']['input'];
  /** If not provided, endTime defaults to now. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  stepSeconds: Scalars['Int']['input'];
};

export type MetricsMetadata = {
  __typename?: 'MetricsMetadata';
  maxValue: Scalars['Float']['output'];
  minValue: Scalars['Float']['output'];
};

export type MetricsResponse = {
  metadata?: Maybe<MetricsMetadata>;
  values: Array<MetricsValue>;
};

export type MetricsSerialNumberDirectionInput = {
  /** Specify which traffic directions are queried for. */
  directions: Array<TrafficDirection>;
  serialNumber: Scalars['String']['input'];
};

export type MetricsValue = {
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type MintTokenInput = {
  /** The permissions that this token should contain. Currently only `INVENTORY_APP` is valid. */
  permissions: Array<Permission>;
  /** The principal name for the token. For devices, the serial number. For machines, the machine name. For users, the user name. */
  principalName: Scalars['String']['input'];
  /** The principal type for the token. Currently only `MACHINE` is valid. */
  principalType: PrincipalType;
  /** How long from now the token is valid. If null, the token does not expire. */
  validityDuration?: InputMaybe<Scalars['Duration']['input']>;
};

export type MintedToken = {
  __typename?: 'MintedToken';
  encoded: Scalars['String']['output'];
  epoch: Scalars['String']['output'];
  permissions: Array<Permission>;
  principal: TokenPrincipal;
  realm: Scalars['String']['output'];
  tokenId: Scalars['String']['output'];
  tokenType: TokenType;
};

export enum MultiWanAlgorithm {
  FirstActive = 'FIRST_ACTIVE',
  RoundRobin = 'ROUND_ROBIN',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Re-activates a previously deleted company user, allowing them to now login and access the dashboard for that company as before. */
  activateCompanyUser: CompanyUser;
  /** Activates a Twilio Cellular SIM card. */
  activateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  addNetworkToAutoVPNGroup: AutoVpnMember;
  addNetworksToAutoVPNGroup: Array<AutoVpnMember>;
  /**
   * This assigns hardware to any virtual device in a network that matches the same type/model as the hardware.
   * Not to be confused with assignSpareHardwareDeviceToNetwork.
   * If the hardware was already associated to the network as a spare, then it will be removed from the spare list.
   */
  assignHardwareDeviceToNetwork: HardwareDevice;
  /**
   * This does not assign the hardware to a virtual device, but rather to the network as a spare device.
   * Need to call assignHardwareDeviceToVirtualDevice / assignHardwareDeviceToNetwork to actually put the hardware to use.
   */
  assignHardwareDeviceToNetworkAsSpare: HardwareDevice;
  /**
   * This assigns hardware to a specific virtual device.
   * Not to be confused with assignSpareHardwareDeviceToNetwork.
   * If the hardware was already associated to the network as a spare, then it will be removed from the spare list.
   */
  assignHardwareDeviceToVirtualDevice: HardwareDevice;
  /**
   * This assigns hardware to specific virtual devices, respectively.
   * If any hardware were already associated to the network as a spare, then they will be removed from the spare list.
   */
  assignHardwareDevicesToVirtualDevices: Array<HardwareDevice>;
  /** Assigns the role of operator to a the user represented by this company user. */
  assignOperatorRole: CompanyUser;
  /** Delete a switch profile. */
  assignSwitchProfleToVirtualDevice: Scalars['Boolean']['output'];
  /** Add a device to a rack elevation. */
  attachDeviceToRackElevation: RackElevation;
  /** Attaches an AP to a radio profile so that the AP can inherit the radio settings. */
  attachVirtualDeviceToRadioProfile: VirtualDevice;
  /** Binds a firewall rule to a phy interface with a given metric, subsequent calls act as an upsert. */
  bindFirewallRuleToPhyInterface: FirewallRule;
  /** Binds a firewall rule to a VLAN with a given metric, subsequent calls act as an upsert. */
  bindFirewallRuleToVLAN: FirewallRule;
  /**
   * Binds firewall rules to a phy interface, assigning metrics based on the UUID's order in `orderedRuleUUIDs`.
   * Any existing bound rules not present in `orderedRuleUUIDs` will be unbound.
   */
  bindFirewallRulesToPhyInterface: Array<FirewallPhyInterfaceBinding>;
  /**
   * Binds firewall rules to a VLAN, assigning metrics based on the UUID's order in `orderedRuleUUIDs`.
   * Any existing bound rules not present in `orderedRuleUUIDs` will be unbound.
   */
  bindFirewallRulesToVLAN: Array<FirewallVlanBinding>;
  bindPolicyRoutingRule: PolicyRoutingRule;
  /**
   * Binds `input` to `orderedRuleUUIDs` assigning metrics based on the order in `orderedRuleUUIDs`.
   * Any existing bound rules to `input` not present in `orderedRuleUUIDs` will be unbound.
   */
  bindPolicyRoutingRules: Array<PolicyRoutingRuleBinding>;
  /** Attach a rate limit rule to a physical interface (port). */
  bindRateLimitRuleToPhyInterface: RateLimitRule;
  /** Attach a rate limit rule to a VLAN. */
  bindRateLimitRuleToVLAN: RateLimitRule;
  /** Bind a VLAN to a physical interface to permit traffic for the VLAN to flow through the port. */
  bindVLANtoPhyInterface: Scalars['Boolean']['output'];
  /** Cancel a schedueld Auto Switch Configuration job that hasn't run yet. */
  cancelAutoSwitchConfigurationJob: SwitchAutoConfigurationJob;
  /**
   * User facing mutation that performs the full flow of doing the following:
   * 1. Enables SSO for the company, if not enabled.
   * 2. Creates a permitted email domain from the user's email, if not already created.
   * 3. Generates an admin portal URI for configuring SSO for this organization.
   */
  configureSSO: ConfigureSsoResult;
  /** A utility method used in the Config 1 -> Config 2 migration process to copy the access point's config 1 location names to the config 2 labels. */
  copyAccessPointConfig1LocationsToConfig2Labels: Array<VirtualDevice>;
  /** A config1->config2 migration utility to copy all DNS host mappings from one DHCP rule to another. */
  copyDNSHostMappingsFromConfig1ToConfig2: Array<DnsHostMapping>;
  /** A config1->config2 migration utility to copy all firewall rules. */
  copyFirewallRulesFromConfig1ToConfig2: Array<FirewallRule>;
  /** A config1->config2 migration helper for IPSec tunnels. */
  copyIPSecTunnelFromConfig1ToConfig2: IpSecTunnel;
  /** Syncs inter-VLAN communication with Config 1 controller config. This will remove any existing Config 2 inter-VLAN communication settings. */
  copyInterVLANCommunicationFromConfig1ToConfig2: Array<InterVlanCommunicationPermittedPair>;
  /**
   * Copies any IP configurations that existed only in the Config1 configuration to InternetServicePlan
   * fields which will be used in Config2.
   */
  copyInternetServicePlanIPsFromConfigToConfig2: Array<InternetServicePlan>;
  /** A config1->config2 migration helper. Copies the multiWAN algorithm from one network to another. */
  copyNetworkMultiWANAlgorithmFromConfig1ToConfig2: Network;
  /** A config1 -> config2 migration helper to copy port forwarding rules. */
  copyPortForwardingRulesFromConfig1ToConfig2: Array<PortForwardingRule>;
  /** A config 1 -> config 2 migration function that copies radio settings. */
  copyRadioSettingsForNetworkFromConfig1: Array<RadioSettings>;
  /** Copies any SSIDs that are not in the Config 2 SSIDs table from Config 1 and returns the newly created SSIDs. */
  copySSIDsFromConfig1ToConfig2: Array<Ssid>;
  /** A config1 -> config2 migration helper to copy uplink phy interfaces. */
  copyUplinkPhyInterfacesFromConfig1ToConfig2: Array<PhyInterface>;
  /** Copies any VLANs that are not in the Config 2 VLANs table from Config 1 and returns the newly created VLANs. */
  copyVLANsFromConfig1ToConfig2: Array<Vlan>;
  /** Creates an alert receiver and mappings between an alert receiver and one or more alert targets. Alert targets must be predefined unless the alert target type is EMAIL. In that case, the email address must be provided and alertTargetUUID can be left empty. An email target will be created automatically. */
  createAlertReceiverWithTargets: AlertReceiver;
  /** Create a new webhook alert target to notify users via webhook of network events. */
  createAlertTargetWebhook: AlertTargetWebhook;
  /** Creates a new DNS firewall rule for a DHCP rule. */
  createApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  createAutoVPNGroup: AutoVpnGroup;
  createAutoVPNRoute: AutoVpnRoute;
  createBearerSession: BearerSession;
  /** Update the NOS firmware version for a group of networks. */
  createBulkNOSUpgrade: BulkNosUpgrade;
  createCaptivePortal: CaptivePortal;
  createClientVPNClient: ClientVpnClient;
  createClientVPNServer: ClientVpnServer;
  /** Create a new internal-only company. Customer companies cannot be created; they are created by the Salesforce -> Backend data sync process. */
  createCompany: Company;
  /** Creates a company user with the specified fields. If a user with the specified email already exists, this will be a no-op. */
  createCompanyUser: CompanyUser;
  /** Create a DHCP option. */
  createDHCPOption: DhcpOption;
  /** Create a DHCP reserved range. */
  createDHCPReservedRange: DhcpReservedRange;
  /** Create a DHCP rule. */
  createDHCPRule: DhcpRule;
  /** Create a DHCP static mapping. */
  createDHCPStaticMapping: DhcpStaticMapping;
  /** Create a DNS host mapping. */
  createDNSHostMapping: DnsHostMapping;
  /** Invoke this method in dev to create a new device in the dev realm. This is done before invoking `setHardwareDeviceIsDev` above. */
  createDevHardwareDevice: HardwareDevice;
  createDeviceHeartbeat: DeviceHeartbeat;
  createEncryption8021X: Encryption8021X;
  /** Create a new firewall rule. */
  createFirewallRule: FirewallRule;
  /** Create a new floor plan. */
  createFloorPlan: FloorPlan;
  /** Combine two controllers on the same network into an HA pair using VRRP. */
  createHighAvailabilityControllersConfiguration: HighAvailabilityControllersConfig;
  /** Create a new host monitoring target. */
  createHostMonitoringTarget: HostMonitoringTarget;
  /** Create a new IPSec tunnel. */
  createIPSecTunnel: IpSecTunnel;
  /** Create a new internal note. */
  createInternalNote: InternalNote;
  /** Creates a new InternetServicePlan for the given network. */
  createInternetServicePlan: InternetServicePlan;
  /**
   * This is a deprecated mutation.
   * @deprecated We no longer support direct creation of mailing addresses.
   */
  createMailingAddress: MailingAddress;
  /** Create an internal network for a internal company. Users cannot create customer networks under customer companies; they are created by the Salesforce -> Backend data sync process. */
  createNetwork: Network;
  /**
   * Create a new network by copying the configuration from an existing network.
   * The network belongs to the same company as the source network; the mailing address/label/slug can be overridden (else they default to the same as the source network).
   * Doesn't copy any hardware or other network-specific data, like event logs, clients, ISP info, etc.
   * Once a network is copied, it is a completely separate entity from the source network.
   */
  createNetworkFromCopy: Network;
  /** Create a new network onboarding document. */
  createNetworkOnboarding: NetworkOnboarding;
  /** Create a new NOS feature. */
  createNosFeature: NosFeature;
  /** Create a new NOS version. */
  createNosVersion: NosVersion;
  /** Create a new notification setting. */
  createNotificationSetting: NotificationSetting;
  /** Create a new 1:1 NAT rule. */
  createOneToOneNATRule: OneToOneNatRule;
  /** Create a new permitted email domain for a company. This feature is largely out-of-use in favor of SSO. */
  createPermittedEmailDomain: PermittedEmailDomain;
  createPolicyRoutingRule: PolicyRoutingRule;
  /** Create a port forwarding rule for a network. */
  createPortForwardingRule: PortForwardingRule;
  /** Generates a pre-signed URL for uploading a file to S3. */
  createPreSignedUploadUrl: FileUploadPayload;
  /** Create a rack elevation for a network. */
  createRackElevation: RackElevation;
  /** Create a radio profile for a network. */
  createRadioProfile: RadioProfile;
  /** Create a rate limit rule for a network. */
  createRateLimitRule: RateLimitRule;
  /** Create an SSID for a network. */
  createSSID: Ssid;
  /** Create a WorkOS admin portal link for the specified company and purpose. */
  createSSOAdminPortalLink: ConfigureSsoResult;
  /**
   * Create a SSO dashboard for the specified company.
   * @deprecated Field no longer supported
   */
  createSSODashboardForCompany: CreateSsoDashboardForCompanyResult;
  /**
   * Create a redirect URI to use to login to WorkOS. Prefer to use login.meter.* instead.
   * @deprecated Field no longer supported
   */
  createSSORedirectURI: CreateSsoRedirectUriResult;
  /** Verify that the token provided via the WorkOS callback is valid. */
  createSSOVerify: CreateSsoVerifyResult;
  createSsoOAuthURI: CreateSsoOAuthUriResult;
  /** Create a new static route. */
  createStaticRoute: StaticRoute;
  /** Bind MAC addresses or OUIs to VLANs before running Switch Auto Config. */
  createSwitchAutoConfigMACAddressVLANBindings: Array<SwitchAutoConfigMacAddressVlanBinding>;
  /** Create a switch profile. */
  createSwitchProfile: SwitchProfile;
  /** Provides an internal Operator user access to a network to provide elevated permissions like SSH access. */
  createUserNetworkAccess: Scalars['Boolean']['output'];
  /** Create a new VLAN. */
  createVLAN: Vlan;
  /** Create a virtual device. */
  createVirtualDevice: VirtualDevice;
  /** Create many virtual devices of the same type and model. */
  createVirtualDevices: Array<VirtualDevice>;
  /** Create a new Wifi Observer Setting. */
  createWifiObserverSetting: WifiObserverSetting;
  /**
   * Careful! Please only do this if you know what you are doing.
   * This does the same thing as `createNetworkFromCopy`, but it allows you to create a new network for any company.
   * This can obviously be quite dangerous and could leak one company's configuration to another, so be careful.
   */
  dangerouslyCreateNetworkForNewCompanyFromCopy: Network;
  /** Deactivates a Twilio Cellular SIM card. */
  deactivateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  /** Deletes an alert receiver and all of its targets. */
  deleteAlertReceiverWithTargets: AlertReceiver;
  deleteAlertTargetWebhook: AlertTargetWebhook;
  deleteApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Deleting an auto VPN group deletes all group members. */
  deleteAutoVPNGroup: AutoVpnGroup;
  deleteAutoVPNRoute: AutoVpnRoute;
  /** Cannot delete a bulk upgrade that has non-PENDING scheduled nos upgrades. */
  deleteBulkNOSUpgrade: BulkNosUpgrade;
  deleteCaptivePortal: CaptivePortal;
  deleteClientVPNClient: Scalars['Boolean']['output'];
  deleteClientVPNServer: Scalars['Boolean']['output'];
  /** Deletes the specified company user, preventing them from logging in and accessing the dashboard for that company. */
  deleteCompanyUser: CompanyUser;
  /** Delete a DHCP option. */
  deleteDHCPOption: DhcpOption;
  /** Delete a DHCP reserved range. */
  deleteDHCPReservedRange: DhcpReservedRange;
  /** Delete a DHCP rule. */
  deleteDHCPRule: DhcpRule;
  /** Delete a DHCP static mapping. */
  deleteDHCPStaticMapping: DhcpStaticMapping;
  /** Delete a DNS host mapping. */
  deleteDNSHostMapping: DnsHostMapping;
  deleteDeviceHeartbeat: Scalars['Boolean']['output'];
  deleteEncryption8021X: Encryption8021X;
  /** Delete a firewall rule. */
  deleteFirewallRule: FirewallRule;
  /** Delete a floor plan. */
  deleteFloorPlan: FloorPlan;
  /** Delete the HA configuration for the given network. */
  deleteHighAvailabilityControllersConfiguration: HighAvailabilityControllersConfig;
  /** Delete a host monitoring target. */
  deleteHostMonitoringTarget: HostMonitoringTarget;
  /** Delete an IPSec tunnel. */
  deleteIPSecTunnel: IpSecTunnel;
  /** Delete an internal note. */
  deleteInternalNote: InternalNote;
  /** Deletes an existing InternetServicePlan. */
  deleteInternetServicePlan: Scalars['Boolean']['output'];
  /** Mark a network as soft-deleted. Customer networks cannot be deleted. Careful, this is a very dangerous operation! */
  deleteNetwork: Network;
  /** Delete a network onboarding document. */
  deleteNetworkOnboardingDocument: NetworkOnboardingDocument;
  /** Delete a field delegation. */
  deleteNetworkOnboardingFieldDelegation: NetworkOnboardingFieldDelegation;
  /** @deprecated No longer supported. */
  deleteNotificationPreference: NotificationPreferences;
  /** Delete a notification setting. */
  deleteNotificationSetting: NotificationSetting;
  /** Delete a 1:1 NAT rule. */
  deleteOneToOneNATRule: OneToOneNatRule;
  /** Delete a permitted email domain for a company. */
  deletePermittedEmailDomain: DeletePermittedEmailDomainResult;
  deletePolicyRoutingRule: PolicyRoutingRule;
  /**
   * Delete a port forwarding rule.
   * @deprecated Use deletePortForwardingRules instead
   */
  deletePortForwardingRule: PortForwardingRule;
  /** Delete multiple port forwarding rules. */
  deletePortForwardingRules: Array<PortForwardingRule>;
  /** Delete a rack elevation. */
  deleteRackElevation: Scalars['Boolean']['output'];
  /** Delete a radio profile. */
  deleteRadioProfile: RadioProfile;
  /** Delete a rate limit rule. */
  deleteRateLimitRule: RateLimitRule;
  /** Delete an SSID. */
  deleteSSID: Ssid;
  /** Delete a static route. */
  deleteStaticRoute: StaticRoute;
  /** Un-bind a MAC address or OUI from a VLAN before running Switch Auto Config. */
  deleteSwitchAutoConfigMACAddressVLANBinding: SwitchAutoConfigMacAddressVlanBinding;
  /** Delete a VLAN. */
  deleteVLAN: Scalars['Boolean']['output'];
  /** Delete a virtual device and all associated entities. Be sure you know what you're doing, this is a hard deletion! Returns an error if the virtual device is assigned to hardware. */
  deleteVirtualDevice: VirtualDevice;
  /** Delete multiple virtual devices and all associated entities. Be sure you know what you're doing! Returns an error if any of the virtual devices are assigned to hardware. */
  deleteVirtualDevices: Array<VirtualDevice>;
  /** Delete a Wifi Observer Setting. */
  deleteWifiObserverSetting: WifiObserverSetting;
  /** Remove a device from a rack elevation. */
  detachDeviceFromRackElevation: Scalars['Boolean']['output'];
  /** Detaches an AP from a radio profile. The AP will inherit its network's default radio profile settings. */
  detachVirtualDeviceFromRadioProfile: VirtualDevice;
  /** Specify a reason for why a mutation was made. */
  editMutationAuditLogEntryReason: Scalars['Boolean']['output'];
  enableSSOForCompany: Company;
  /**
   * Force a sync of the Salesforce -> Backend data sync process for all existing companies.
   * Used when backfilling a new synced field that needs to be populated for all companies.
   * Returns the River Job ID.
   * This should only be invoked by engineers who know what they are doing.
   */
  forceSalesforceSyncCompanies: Scalars['Int']['output'];
  /**
   * Force a sync of the Salesforce -> Backend data sync process for all existing networks and their mailing addresses.
   * Used when backfilling a new synced field that needs to be populated for all networks.
   * Returns the River Job ID.
   * This should only be invoked by engineers who know what they are doing.
   */
  forceSalesforceSyncNetworks: Scalars['Int']['output'];
  /**
   * Kicks off a job to run Auto Channel Selection for a single AP.
   * The process takes some time, so a jobUUID is returned to track the progress.
   */
  initializeAutoChannelSelectionForAccessPoint: AutoChannelSelectionJob;
  /**
   * Kicks off a job to run Auto Channel Selection for all APs in a network.
   * The process takes some time, so a jobUUID is returned to track the progress.
   */
  initializeAutoChannelSelectionForNetwork: AutoChannelSelectionJob;
  /** Kicks off a job to run Auto Switch Configuration for a network. */
  initializeAutoSwitchConfigurationForNetwork: SwitchAutoConfigurationJob;
  /** Add fake stats to a demo company for sales demonstration purposes. */
  insertStatsForDemo: Scalars['Boolean']['output'];
  /** Create a new authentication token. */
  mintAuthenticationToken: MintedToken;
  /** A utility method used for testing purposes to downgrade a device to Config 1; should not be used for customer devices, be very careful. */
  moveDeviceFromConfig2ToConfig1: Scalars['Boolean']['output'];
  /** Moves an internet service plan to a given network. The network must belong to the company currently associated with the ISP. */
  moveInternetServicePlanToNetwork: InternetServicePlan;
  /** Always returns true and does nothing. */
  noop: Scalars['Boolean']['output'];
  /** Pins the config for a device to the version at the given timestamp. Be very careful, use only in outage situations! */
  pinDeviceConfigsForNetwork: Network;
  /** Be very careful, use only in outage situations! Only pins networks that are not already pinned. */
  pinUnpinnedDeviceConfigsGlobally: Array<Network>;
  /** Reactivates a Twilio Cellular SIM card. */
  reactivateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  removeNetworkFromAutoVPNGroup: Scalars['Boolean']['output'];
  removeNetworksFromAutoVPNGroup: Scalars['Boolean']['output'];
  /** Remove networks that were previously added to a pending NOS upgrade. */
  removeNetworksFromBulkNOSUpgrade: BulkNosUpgrade;
  /** Removes the role of operator from the user represented by this company user. */
  removeOperatorRole: CompanyUser;
  /**
   * Invoke a rendering of the latest config for a device.
   * Does the same thing as the device-facing `GET /v2/devices/:serial_number/config endpoint.
   */
  renderDeviceConfig: DeviceConfig;
  /** Revoke an existing authentication token. */
  revokeAuthenticationToken: AuthenticationToken;
  /** Rotates HMAC secret for a given captive portal. */
  rotateCaptivePortalSecret: CaptivePortal;
  /** Blink the LEDs on a device. */
  rpcBlinkLEDs: Scalars['Boolean']['output'];
  /** Instruct a device to clear its list of configs that were marked as bad */
  rpcClearBlockedConfigs: Scalars['Boolean']['output'];
  /** Cycle an outlet on a power distribution unit. */
  rpcPDUOutletCycle: Scalars['Boolean']['output'];
  /** Instruct a device to ping out to a host */
  rpcPing: RpcPingResponse;
  /** Instruct a device to run a cable test on a port. */
  rpcPortCableTest: RpcCableTestResponse;
  /** Reboot a device. */
  rpcRebootDevice: Scalars['Boolean']['output'];
  /** Instruct a device to pull its latest config from the backend. */
  rpcRefreshConfig: Scalars['Boolean']['output'];
  /** Instruct a device to recreate a specified IPSec tunnel. */
  rpcRestartIPSecTunnel: Scalars['Boolean']['output'];
  /** Cycle a port on a switch. */
  rpcSwitchPortCycle: Scalars['Boolean']['output'];
  /**
   * Instruct a device to run a WAN speedtest.
   * @deprecated Unused due to issues with implementation. Use startWANSpeedtestJob on config 2 networks.
   */
  rpcWANSpeedtest: RpcwanSpeedtestResponse;
  /** Disconnect a client from an AP. */
  rpcWosDisconnectClient: Scalars['Boolean']['output'];
  /** Instruct a device to run an EAPOL test to check RADIUS connectivity. */
  rpcWosEapolTestForSerial: RpcEapolTestForSerialResponse;
  /** Instruct devices to run an EAPOL test to check RADIUS connectivity. */
  rpcWosEapolTestForSerials: Array<RpcEapolTestForSerialResponse>;
  /** Instruct devices to run/get results for an Observer test. */
  rpcWosObserver: RpcWosObserverResponse;
  /** Instruct APs to start a packet capture on specified monitor interfaces. */
  rpcWosPacketCaptureStart: RpcWosPacketCaptureResponse;
  /** Instruct APs to stop a packet capture on specified monitor interfaces. */
  rpcWosPacketCaptureStop: RpcWosPacketCaptureResponse;
  /** Reset the radio for this band. */
  rpcWosRadioReset: Scalars['Boolean']['output'];
  /** Reset DFS channels on an AP */
  rpcWosResetDfs: Scalars['Boolean']['output'];
  /** Invoke this method in prod to route a device to the dev realm. This clears the registration info and requires the device to re-register. */
  setHardwareDeviceIsDev: Scalars['Boolean']['output'];
  /** Set the NOS version for a network, which applies a firmware upgrade/downgrade to all devices. */
  setNetworkNosVersion: Scalars['Boolean']['output'];
  /**
   * Binds a policy routing rule to `input` assigning metrics based on the order in `input`.
   * Any existing bound rules not present in `input` will be unbound.
   */
  setPolicyRoutingRuleBindings: Array<PolicyRoutingRuleBinding>;
  /** Sign a console login challenge, allowing the user to log in to the device. */
  signConsoleLoginChallenge: SignedConsoleLoginChallenge;
  /** Begin WAN speedtest with the given input. */
  startWANSpeedtestJob: WanSpeedtestJob;
  /**
   * Test triggering is for testing with the CLI. It is not to intended for use by the frontend.
   * The API is unstable and may change or be removed at any time.
   * @deprecated Internal use only
   */
  testTriggerWebhookAlertTarget: AlertTargetWebhook;
  /** Unassign the given hardware from its virtual device, if any. */
  unassignHardwareDeviceFromVirtualDevice: HardwareDevice;
  /** Unassigns hardware from the list of virtual devices. Be sure you know what you're doing! */
  unassignHardwareFromVirtualDevices: Array<VirtualDevice>;
  /** Removes the association of an internet service plan from any network it was previously assigned to. */
  unassignInternetServicePlanFromNetwork: InternetServicePlan;
  /** Unassign the hardware from the network as a spare device. */
  unassignSpareHardwareDeviceFromNetwork: HardwareDevice;
  unbindFirewallRuleFromPhyInterface: FirewallRule;
  unbindFirewallRuleFromVLAN: FirewallRule;
  unbindPolicyRoutingRule: PolicyRoutingRule;
  /** Detach a rate limit rule from a physical interface (port). */
  unbindRateLimitRuleFromPhyInterface: RateLimitRule;
  /** Detach a rate limit rule from a VLAN. */
  unbindRateLimitRuleFromVLAN: RateLimitRule;
  /** Un-soft-delete a network. Should only be used in special circumstances. */
  undeleteNetwork: Network;
  unpinDeviceConfigsForNetwork: Network;
  /** Be very careful, use only in outage situations! Only unpins networks that are pinned by the given maxCreatedAt datetime (to avoid unpinning networks that were pinned by other processes). */
  unpinDeviceConfigsGlobally: Array<Network>;
  /** Invoke this method in prod to route a dev device back to the prod realm. This clears the registration info and requires the device to re-register. */
  unsetHardwareDeviceIsDev: Scalars['Boolean']['output'];
  /** Updates an alert receiver and its targets. */
  updateAlertReceiverWithTargets: AlertReceiver;
  /** Update an existing webhook alert target. */
  updateAlertTargetWebhook: AlertTargetWebhook;
  /** Updates an existing DNS firewall rule. */
  updateApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Updates the visibility of applications for DNS firewall rules. */
  updateApplicationDNSFirewallRuleApplicationVisibilities: Array<
    Maybe<ApplicationDnsFirewallRuleApplication>
  >;
  /**
   * Updates a list of rules to the specified priorities. Returns all updated rules.
   * Rules must be associated with the same network and DHCP rule.
   * If any provided priority is already taken, the request fails.
   */
  updateApplicationDNSFirewallRulesPriorities: Array<ApplicationDnsFirewallRule>;
  updateAutoVPNGroup: AutoVpnGroup;
  updateAutoVPNMember: AutoVpnMember;
  updateAutoVPNRoute: AutoVpnRoute;
  updateCaptivePortal: CaptivePortal;
  updateClientVPNServer: ClientVpnServer;
  /** Update a limited set of fields on a company. Many fields are owned by Salesforce and cannot be updated via this endpoint. */
  updateCompany: Company;
  /** Updates the specified company user with the provided fields. Note that the email field cannot be updated. */
  updateCompanyUser: CompanyUser;
  /** Update a DHCP option. */
  updateDHCPOption: DhcpOption;
  /** Update a DHCP reserved range. */
  updateDHCPReservedRange: DhcpReservedRange;
  /** Update a DHCP rule. */
  updateDHCPRule: DhcpRule;
  /** Update a DHCP static mapping. */
  updateDHCPStaticMapping: DhcpStaticMapping;
  /** Update a DNS host mapping. */
  updateDNSHostMapping: DnsHostMapping;
  updateEncryption8021X: Encryption8021X;
  /** Update a firewall rule. */
  updateFirewallRule: FirewallRule;
  /** Update a floor plan. */
  updateFloorPlan: FloorPlan;
  /** Consumes a cellular failover event in the stats pipeline and routes an alert to escalate the issue to the support team. */
  updateForCellularFailoverEvent: Scalars['Boolean']['output'];
  /** This is intended to only be called by the stat-consumer. */
  updateForHighAvailabilityEvent: Scalars['Boolean']['output'];
  /** Update the metadata of a hardware device. */
  updateHardwareDevice: HardwareDevice;
  /** Update a host monitoring target. */
  updateHostMonitoringTarget: HostMonitoringTarget;
  /** Update an IPSec tunnel. */
  updateIPSecTunnel: IpSecTunnel;
  /** Sets the permitted pairs for this network to these pairs, discarding others. Equivalent to PUT semantics. */
  updateInterVLANCommunicationPermittedPairs: Array<InterVlanCommunicationPermittedPair>;
  /** Update an internal note. */
  updateInternalNote: InternalNote;
  /** Updates an existing InternetServicePlan with the provided fields. */
  updateInternetServicePlan: InternetServicePlan;
  /**
   * This is a deprecated mutation.
   * @deprecated We no longer support direct updating of mailing addresses.
   */
  updateMailingAddress: MailingAddress;
  /** Update the maintenance window for a network. */
  updateMaintenanceWindowForNetwork: MaintenanceWindow;
  /** Update a network. */
  updateNetwork: Network;
  /** Update a NOS feature. */
  updateNosFeature: NosFeature;
  /** Update a NOS version. */
  updateNosVersion: NosVersion;
  /** @deprecated No longer supported. */
  updateNotificationPreference: NotificationPreferences;
  /** Update a notification setting. */
  updateNotificationSetting: NotificationSetting;
  /** Update a 1:1 NAT rule. */
  updateOneToOneNATRule: OneToOneNatRule;
  /** Update an outlet interface. */
  updateOutletInterface: OutletInterface;
  /** Update multiple outlet interfaces. */
  updateOutletInterfaces: Array<OutletInterface>;
  /** Update a phy interface. */
  updatePhyInterface: PhyInterface;
  /** This is intended to be called only by the kafka consumer. */
  updatePhyInterfaceForMultiWANEvent: Scalars['Boolean']['output'];
  /** Update many phy interfaces to the same values. */
  updatePhyInterfaces: Array<PhyInterface>;
  updatePolicyRoutingRule: PolicyRoutingRule;
  /** Update a port forwarding rule. */
  updatePortForwardingRule: PortForwardingRule;
  /** Update a rack elevation. */
  updateRackElevation: RackElevation;
  /** Update a device in a rack elevation. */
  updateRackElevationDevice: RackElevation;
  /** Update a radio profile. */
  updateRadioProfile: RadioProfile;
  /** Update a rate limit rule. */
  updateRateLimitRule: RateLimitRule;
  /** Update an SSID. */
  updateSSID: Ssid;
  /** Update a static route. */
  updateStaticRoute: StaticRoute;
  /** Update a switch profile. */
  updateSwitchProfile: SwitchProfile;
  /** This is intended to be called only by the kafka consumer. This is its own mutation until auth can be better figured out for individual fields. */
  updateUplinkPhyInterfaceExternalAddresses: Scalars['Boolean']['output'];
  /** Updates a user's roles to be only those specified by the input. */
  updateUserRoles: CompanyUser;
  /** Update an existing VLAN. */
  updateVLAN: Vlan;
  /** Sets the permitted pairs for this VLAN to these other VLANs, discarding others. Equivalent to PUT semantics. */
  updateVLANInterVLANCommunicationPermittedVLANs: Vlan;
  /** Update many VLANs at once, applying different settings to each. */
  updateVLANsIndependently: Array<Vlan>;
  /** Update the metadata of a virtual device. */
  updateVirtualDevice: VirtualDevice;
  /** Update the metadata of multiple virtual devices at once, to the same values for all devices. */
  updateVirtualDevices: Array<VirtualDevice>;
  /** Update the metadata of multiple virtual devices at once, to different values for each device. */
  updateVirtualDevicesIndependently: Array<VirtualDevice>;
  /** @deprecated No longer supported. */
  upsertCompanyNotificationPreference: NotificationPreferences;
  /** Only use this in development or extreme outage situations! Overrides the rendered config for a device with the given JSON. */
  upsertDeviceConfigOverride: Scalars['Boolean']['output'];
  /** Create or update a MAC address alias. */
  upsertMACAddressAlias: MacAddressAlias;
  /** @deprecated No longer supported. */
  upsertNetworkNotificationPreference: NotificationPreferences;
  /** Create or update the network onboarding document, updating the document using PUT semantics. Keyed by `networkUUID` instead of its own UUID for convenience. */
  upsertNetworkOnboardingDocument: NetworkOnboardingDocument;
  /** Delegates specific fields of the network onboarding document to a user. */
  upsertNetworkOnboardingFieldDelegation: NetworkOnboardingFieldDelegation;
  validateBearerSession: BearerSession;
};

export type MutationActivateCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationActivateDeviceTwilioCellularArgs = {
  input: TwilioCellularActivationInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationAddNetworkToAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  input: AddNetworkToAutoVpnGroupInput;
};

export type MutationAddNetworksToAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  inputs: Array<AddNetworkToAutoVpnGroupInput>;
};

export type MutationAssignHardwareDeviceToNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationAssignHardwareDeviceToNetworkAsSpareArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationAssignHardwareDeviceToVirtualDeviceArgs = {
  serialNumber: Scalars['String']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationAssignHardwareDevicesToVirtualDevicesArgs = {
  inputs: Array<AssignHardwareDeviceToVirtualDeviceInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationAssignOperatorRoleArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationAssignSwitchProfleToVirtualDeviceArgs = {
  UUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationAttachDeviceToRackElevationArgs = {
  input: AttachDeviceToRackElevationInput;
  rackElevationUUID: Scalars['UUID']['input'];
};

export type MutationAttachVirtualDeviceToRadioProfileArgs = {
  radioProfileUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationBindFirewallRuleToPhyInterfaceArgs = {
  input: BindFirewallRuleToPhyInterface;
};

export type MutationBindFirewallRuleToVlanArgs = {
  input: BindFirewallRuleToVlan;
};

export type MutationBindFirewallRulesToPhyInterfaceArgs = {
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type MutationBindFirewallRulesToVlanArgs = {
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationBindPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: BindPolicyRoutingRuleInput;
};

export type MutationBindPolicyRoutingRulesArgs = {
  input: BindPolicyRoutingRulesInput;
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationBindRateLimitRuleToPhyInterfaceArgs = {
  input: RateLimitPhyInterfaceBindingInput;
};

export type MutationBindRateLimitRuleToVlanArgs = {
  input: RateLimitVlanBindingInput;
};

export type MutationBindVlaNtoPhyInterfaceArgs = {
  input: BindVlanToPhyInterfaceInput;
};

export type MutationCancelAutoSwitchConfigurationJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type MutationConfigureSsoArgs = {
  input: ConfigureSsoInput;
};

export type MutationCopyAccessPointConfig1LocationsToConfig2LabelsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyDnsHostMappingsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyFirewallRulesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyIpSecTunnelFromConfig1ToConfig2Args = {
  input: CopyIpSecTunnelFromConfig1ToConfig2Input;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyInterVlanCommunicationFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyInternetServicePlanIPsFromConfigToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyNetworkMultiWanAlgorithmFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyPortForwardingRulesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyRadioSettingsForNetworkFromConfig1Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopySsiDsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyUplinkPhyInterfacesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyVlaNsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateAlertReceiverWithTargetsArgs = {
  companyUUID: Scalars['UUID']['input'];
  input: CreateAlertReceiverWithTargetsInput;
};

export type MutationCreateAlertTargetWebhookArgs = {
  companyUUID: Scalars['UUID']['input'];
  input: CreateAlertTargetWebhookInput;
};

export type MutationCreateApplicationDnsFirewallRuleArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateApplicationDnsFirewallRuleInput;
};

export type MutationCreateAutoVpnGroupArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateAutoVpnGroupInput;
};

export type MutationCreateAutoVpnRouteArgs = {
  groupUUID: Scalars['UUID']['input'];
  input: CreateAutoVpnRouteInput;
};

export type MutationCreateBulkNosUpgradeArgs = {
  input: CreateBulkNosUpgradeInput;
};

export type MutationCreateCaptivePortalArgs = {
  input: CaptivePortalSettingsInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateClientVpnClientArgs = {
  input: CreateClientVpnClientInput;
  serverUUID: Scalars['UUID']['input'];
};

export type MutationCreateClientVpnServerArgs = {
  input: CreateClientVpnServerInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateCompanyUserArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateCompanyUserInput;
};

export type MutationCreateDhcpOptionArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpOptionInput;
};

export type MutationCreateDhcpReservedRangeArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpReservedRangeInput;
};

export type MutationCreateDhcpRuleArgs = {
  input: CreateDhcpRuleInput;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationCreateDhcpStaticMappingArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpStaticMappingInput;
};

export type MutationCreateDnsHostMappingArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDnsHostMappingInput;
};

export type MutationCreateDevHardwareDeviceArgs = {
  input: CreateDevHardwareDeviceInput;
};

export type MutationCreateDeviceHeartbeatArgs = {
  input: CreateDeviceHeartbeatInput;
};

export type MutationCreateEncryption8021XArgs = {
  input: CreateEncryption8021XInput;
};

export type MutationCreateFirewallRuleArgs = {
  input: CreateFirewallRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateFloorPlanArgs = {
  input: CreateFloorPlanInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateHighAvailabilityControllersConfigurationArgs = {
  backupPhyInterfaceUUID: Scalars['UUID']['input'];
  input?: InputMaybe<CreateHighAvailabilityPairInput>;
  networkUUID: Scalars['UUID']['input'];
  primaryPhyInterfaceUUID: Scalars['UUID']['input'];
};

export type MutationCreateHostMonitoringTargetArgs = {
  input: CreateHostMonitoringTargetInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateIpSecTunnelArgs = {
  input: CreateIpSecTunnelInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateInternalNoteArgs = {
  input: CreateInternalNoteInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateInternetServicePlanArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateInternetServicePlanInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateMailingAddressArgs = {
  input: CreateMailingAddressInput;
};

export type MutationCreateNetworkArgs = {
  input: CreateNetworkInput;
};

export type MutationCreateNetworkFromCopyArgs = {
  input?: InputMaybe<CreateNetworkCopyInput>;
  sourceNetworkUUID: Scalars['UUID']['input'];
};

export type MutationCreateNetworkOnboardingArgs = {
  jobTrackerID: Scalars['String']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateNosFeatureArgs = {
  input: CreateNosFeatureInput;
};

export type MutationCreateNosVersionArgs = {
  input: NosVersionInput;
};

export type MutationCreateNotificationSettingArgs = {
  input: CreateNotificationSettingInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateOneToOneNatRuleArgs = {
  input: CreateOneToOneNatRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePermittedEmailDomainArgs = {
  input: CreatePermittedEmailDomainInput;
};

export type MutationCreatePolicyRoutingRuleArgs = {
  input: CreatePolicyRoutingRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePortForwardingRuleArgs = {
  input: CreatePortForwardingRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePreSignedUploadUrlArgs = {
  input: CreatePreSignedUploadUrlInput;
};

export type MutationCreateRackElevationArgs = {
  input: CreateRackElevationInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateRadioProfileArgs = {
  input: CreateRadioProfileInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateRateLimitRuleArgs = {
  input: CreateRateLimitRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSsidArgs = {
  input: CreateSsidInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSsoAdminPortalLinkArgs = {
  input?: InputMaybe<ConfigureSsoInput>;
};

export type MutationCreateSsoDashboardForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type MutationCreateSsoRedirectUriArgs = {
  targetApp: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type MutationCreateSsoVerifyArgs = {
  input: CreateSsoVerifyInput;
};

export type MutationCreateSsoOAuthUriArgs = {
  targetApp: Scalars['String']['input'];
};

export type MutationCreateStaticRouteArgs = {
  input: CreateStaticRouteInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSwitchAutoConfigMacAddressVlanBindingsArgs = {
  input: Array<CreateSwitchAutoConfigMacAddressVlanBindingInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSwitchProfileArgs = {
  input: CreateSwitchProfileInput;
};

export type MutationCreateUserNetworkAccessArgs = {
  input: CreateUserNetworkAccessInput;
};

export type MutationCreateVlanArgs = {
  input: CreateVlanInput;
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateVirtualDeviceArgs = {
  input: CreateVirtualDeviceInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateVirtualDevicesArgs = {
  input: CreateVirtualDevicesInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateWifiObserverSettingArgs = {
  input: CreateWifiObserverSettingInput;
};

export type MutationDangerouslyCreateNetworkForNewCompanyFromCopyArgs = {
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<CreateNetworkCopyInput>;
  sourceNetworkUUID: Scalars['UUID']['input'];
};

export type MutationDeactivateDeviceTwilioCellularArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationDeleteAlertReceiverWithTargetsArgs = {
  alertReceiverUUID: Scalars['UUID']['input'];
};

export type MutationDeleteAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteAutoVpnRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteCaptivePortalArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteClientVpnClientArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDeviceHeartbeatArgs = {
  input: DeleteDeviceHeartbeatInput;
};

export type MutationDeleteEncryption8021XArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteHighAvailabilityControllersConfigurationArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationDeleteHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteInternetServicePlanArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkOnboardingDocumentArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkOnboardingFieldDelegationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNotificationPreferenceArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePermittedEmailDomainArgs = {
  input: DeletePermittedEmailDomainInput;
};

export type MutationDeletePolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePortForwardingRulesArgs = {
  UUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationDeleteRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteRadioProfileArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteSsidArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteSwitchAutoConfigMacAddressVlanBindingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteVlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationDeleteVirtualDevicesArgs = {
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationDeleteWifiObserverSettingArgs = {
  input: DeleteWifiObserverSettingInput;
};

export type MutationDetachDeviceFromRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDetachVirtualDeviceFromRadioProfileArgs = {
  radioProfileUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationEditMutationAuditLogEntryReasonArgs = {
  UUID: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
};

export type MutationEnableSsoForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type MutationInitializeAutoChannelSelectionForAccessPointArgs = {
  input: AutoChannelSelectionJobInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationInitializeAutoChannelSelectionForNetworkArgs = {
  input: AutoChannelSelectionJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationInitializeAutoSwitchConfigurationForNetworkArgs = {
  input: SwitchAutoConfigurationJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationInsertStatsForDemoArgs = {
  companySlug: Scalars['String']['input'];
  duration?: InputMaybe<StatInjectionDuration>;
};

export type MutationMintAuthenticationTokenArgs = {
  input?: InputMaybe<MintTokenInput>;
};

export type MutationMoveDeviceFromConfig2ToConfig1Args = {
  serialNumber: Scalars['String']['input'];
};

export type MutationMoveInternetServicePlanToNetworkArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationPinDeviceConfigsForNetworkArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationPinUnpinnedDeviceConfigsGloballyArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
};

export type MutationReactivateDeviceTwilioCellularArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRemoveNetworkFromAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationRemoveNetworksFromAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveNetworksFromBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveOperatorRoleArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationRenderDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRevokeAuthenticationTokenArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationRotateCaptivePortalSecretArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationRpcBlinkLeDsArgs = {
  durationSec?: Scalars['Int']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcClearBlockedConfigsArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcPduOutletCycleArgs = {
  input: RpcpduOutletCycleInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcPingArgs = {
  input: RpcPingInput;
};

export type MutationRpcPortCableTestArgs = {
  portNumber: Scalars['Int']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRebootDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRefreshConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRestartIpSecTunnelArgs = {
  ipSecTunnelUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcSwitchPortCycleArgs = {
  input: RpcSwitchPortCycleInput;
};

export type MutationRpcWanSpeedtestArgs = {
  input: RpcwanSpeedtestInput;
};

export type MutationRpcWosDisconnectClientArgs = {
  mac: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  ssid: Scalars['String']['input'];
};

export type MutationRpcWosEapolTestForSerialArgs = {
  input?: InputMaybe<RpcEapolTestForSerialInput>;
};

export type MutationRpcWosEapolTestForSerialsArgs = {
  input: RpcEapolTestForSerialsInput;
};

export type MutationRpcWosObserverArgs = {
  input: RpcWosObserverInput;
};

export type MutationRpcWosPacketCaptureStartArgs = {
  input: RpcWosPacketCaptureStartInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosPacketCaptureStopArgs = {
  input: RpcWosPacketCaptureStopInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosRadioResetArgs = {
  band: RadioBand;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosResetDfsArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationSetHardwareDeviceIsDevArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationSetNetworkNosVersionArgs = {
  input: SetNetworkNosVersionInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationSetPolicyRoutingRuleBindingsArgs = {
  UUID: Scalars['UUID']['input'];
  input: SetPolicyRoutingRuleBindingsInput;
};

export type MutationSignConsoleLoginChallengeArgs = {
  challenge: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationStartWanSpeedtestJobArgs = {
  input: StartWanSpeedtestJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationTestTriggerWebhookAlertTargetArgs = {
  UUID: Scalars['UUID']['input'];
  input: TestTriggerAlertTargetWebhookInput;
};

export type MutationUnassignHardwareDeviceFromVirtualDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationUnassignHardwareFromVirtualDevicesArgs = {
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationUnassignInternetServicePlanFromNetworkArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationUnassignSpareHardwareDeviceFromNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationUnbindFirewallRuleFromPhyInterfaceArgs = {
  input: UnbindFirewallRuleFromPhyInterface;
};

export type MutationUnbindFirewallRuleFromVlanArgs = {
  input: UnbindFirewallRuleFromVlan;
};

export type MutationUnbindPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UnbindPolicyRoutingRuleInput;
};

export type MutationUnbindRateLimitRuleFromPhyInterfaceArgs = {
  input: RateLimitPhyInterfaceBindingInput;
};

export type MutationUnbindRateLimitRuleFromVlanArgs = {
  input: RateLimitVlanBindingInput;
};

export type MutationUndeleteNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationUnpinDeviceConfigsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUnpinDeviceConfigsGloballyArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
};

export type MutationUnsetHardwareDeviceIsDevArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationUpdateAlertReceiverWithTargetsArgs = {
  alertReceiverUUID: Scalars['UUID']['input'];
  input: UpdateAlertReceiverWithTargetsInput;
};

export type MutationUpdateAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAlertTargetWebhookInput;
};

export type MutationUpdateApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateApplicationDnsFirewallRuleInput;
};

export type MutationUpdateApplicationDnsFirewallRuleApplicationVisibilitiesArgs = {
  input: Array<UpdateApplicationDnsFirewallRuleApplicationVisibilities>;
};

export type MutationUpdateApplicationDnsFirewallRulesPrioritiesArgs = {
  input: Array<UpdateApplicationDnsFirewallRulesPrioritiesInput>;
};

export type MutationUpdateAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnGroupInput;
};

export type MutationUpdateAutoVpnMemberArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnMemberInput;
};

export type MutationUpdateAutoVpnRouteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnRouteInput;
};

export type MutationUpdateCaptivePortalArgs = {
  input: CaptivePortalSettingsInput;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateClientVpnServerInput;
};

export type MutationUpdateCompanyArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateCompanyInput;
};

export type MutationUpdateCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input: UpdateCompanyUserInput;
};

export type MutationUpdateDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpOptionInput;
};

export type MutationUpdateDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpReservedRangeInput;
};

export type MutationUpdateDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpRuleInput;
};

export type MutationUpdateDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpStaticMappingInput;
};

export type MutationUpdateDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDnsHostMappingInput;
};

export type MutationUpdateEncryption8021XArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateEncryption8021XInput;
};

export type MutationUpdateFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateFirewallRuleInput;
};

export type MutationUpdateFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateFloorPlanInput;
};

export type MutationUpdateForCellularFailoverEventArgs = {
  input: UpdateForCellularFailoverEventInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateForHighAvailabilityEventArgs = {
  input: UpdateForHighAvailabilityEventInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateHardwareDeviceArgs = {
  input: UpdateHardwareDeviceInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationUpdateHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateHostMonitoringTargetInput;
};

export type MutationUpdateIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateIpSecTunnelInput;
};

export type MutationUpdateInterVlanCommunicationPermittedPairsArgs = {
  networkUUID: Scalars['UUID']['input'];
  vlanPairs: Array<VlanPairInput>;
};

export type MutationUpdateInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateInternalNoteInput;
};

export type MutationUpdateInternetServicePlanArgs = {
  input: UpdateInternetServicePlanInput;
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationUpdateMailingAddressArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateMailingAddressInput;
};

export type MutationUpdateMaintenanceWindowForNetworkArgs = {
  input: UpdateMaintenanceWindowInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpdateNetworkArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNetworkInput;
};

export type MutationUpdateNosFeatureArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNosFeatureInput;
};

export type MutationUpdateNosVersionArgs = {
  id: Scalars['Int']['input'];
  input: NosVersionInput;
};

export type MutationUpdateNotificationPreferenceArgs = {
  input?: InputMaybe<NotificationPreferenceInput>;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNotificationSettingInput;
};

export type MutationUpdateOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateOneToOneNatRuleInput;
};

export type MutationUpdateOutletInterfaceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateOutletInterfaceInput;
};

export type MutationUpdateOutletInterfacesArgs = {
  input: UpdateOutletInterfaceInput;
  outletInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePhyInterfaceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePhyInterfaceInput;
};

export type MutationUpdatePhyInterfaceForMultiWanEventArgs = {
  input: Array<UpdatePhyInterfaceForMultiWanEventInput>;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePhyInterfacesArgs = {
  input: UpdatePhyInterfaceInput;
  phyInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePolicyRoutingRuleInput;
};

export type MutationUpdatePortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePortForwardingRuleInput;
};

export type MutationUpdateRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRackElevationInput;
};

export type MutationUpdateRackElevationDeviceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRackElevationDeviceInput;
};

export type MutationUpdateRadioProfileArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRadioProfileInput;
};

export type MutationUpdateRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRateLimitRuleInput;
};

export type MutationUpdateSsidArgs = {
  input: UpdateSsidInput;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateStaticRouteInput;
};

export type MutationUpdateSwitchProfileArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateSwitchProfileInput;
};

export type MutationUpdateUplinkPhyInterfaceExternalAddressesArgs = {
  input: UpdateUplinkPhyInterfaceExternalAddressesInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateUserRolesArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input: Array<UserRoleInput>;
};

export type MutationUpdateVlanArgs = {
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  input: UpdateVlanInput;
};

export type MutationUpdateVlanInterVlanCommunicationPermittedVlaNsArgs = {
  otherVLANUUIDs: Array<Scalars['UUID']['input']>;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVlaNsIndependentlyArgs = {
  inputs: Array<UpdateVlaNsIndependentlyInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVirtualDeviceArgs = {
  input: UpdateVirtualDeviceInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVirtualDevicesArgs = {
  input: UpdateVirtualDeviceInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationUpdateVirtualDevicesIndependentlyArgs = {
  inputs: Array<UpdateVirtualDeviceIndependentlyInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpsertCompanyNotificationPreferenceArgs = {
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<NotificationPreferenceInput>;
};

export type MutationUpsertDeviceConfigOverrideArgs = {
  configJSON: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationUpsertMacAddressAliasArgs = {
  alias: Scalars['String']['input'];
  companySlug: Scalars['String']['input'];
  macAddress: Scalars['MacAddress']['input'];
};

export type MutationUpsertNetworkNotificationPreferenceArgs = {
  controllerName: Scalars['String']['input'];
  input?: InputMaybe<NotificationPreferenceInput>;
};

export type MutationUpsertNetworkOnboardingDocumentArgs = {
  document: Scalars['JSONObject']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpsertNetworkOnboardingFieldDelegationArgs = {
  fieldName: Scalars['String']['input'];
  input: UpsertNetworkOnboardingFieldDelegationInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationValidateBearerSessionArgs = {
  UUID: Scalars['UUID']['input'];
};

/** Tracks the mutations that have been made to the network's configuration over time. */
export type MutationAuditLogEntry = {
  __typename?: 'MutationAuditLogEntry';
  UUID: Scalars['UUID']['output'];
  action: Scalars['String']['output'];
  args: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  identityType: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type MutationAuditLogFilter = {
  /** include 'machine' identityType */
  includeMachine?: Scalars['Boolean']['input'];
  limit?: Scalars['Int']['input'];
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  offset?: Scalars['Int']['input'];
};

/** A network is a grouping of devices in a single location belonging to a company. A core Meter entity. */
export type Network = {
  __typename?: 'Network';
  UUID: Scalars['UUID']['output'];
  company?: Maybe<Company>;
  companySlug?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The time when the network was deactivated, if applicable. Allows differentiation between pre-live and post-live networks. */
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  enabledServiceDiscoveryTypes: Array<ServiceType>;
  /** The date the network first started serving traffic. */
  goLiveAt?: Maybe<Scalars['DateTime']['output']>;
  /** Configuration of the HA controllers for this network. */
  highAvailabilityConfig?: Maybe<HighAvailabilityControllersConfig>;
  /** Whether or not the network is currently serving traffic. */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the controller can upgrade config-1 APs deployed to the network */
  isConfig1WosUpgradesEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the network services a customer (as opposed to being an internal / test network). */
  isCustomer: Scalars['Boolean']['output'];
  /**
   * A "template" network is one that does not represent a real physical network, but rather a template for creating new networks.
   * It is not associated with any physical devices, and is not intended to be used for any real-world operations.
   */
  isTemplate: Scalars['Boolean']['output'];
  /** Whether the network is participating in the Meter Trial Program. */
  isTrial: Scalars['Boolean']['output'];
  /** Indicates whether a network is sensitive to firmware updates. */
  isUpgradeSensitive: Scalars['Boolean']['output'];
  /** A short code to quickly identify the network. Useful for customers with many networks and a need to quickly identify them. */
  label: Scalars['String']['output'];
  /** The last time this network was upgraded. It is the time when pending NOS was copied into pinned NOS by the NOS scheduler */
  lastUpgradedAt?: Maybe<Scalars['DateTime']['output']>;
  mailingAddress?: Maybe<MailingAddress>;
  mailingAddressUUID?: Maybe<Scalars['UUID']['output']>;
  multiWANAlgorithm?: Maybe<MultiWanAlgorithm>;
  /** Somewhat legacy onboarding information from airtable job. Different from `onboardingDocument`. */
  onboarding?: Maybe<NetworkOnboarding>;
  /** Onboarding document form completed prior to network going live. Different from `onboarding`. */
  onboardingDocument?: Maybe<NetworkOnboardingDocument>;
  /** The URL of the patch panel diagram for the network. */
  patchPanelDiagramURL?: Maybe<Scalars['String']['output']>;
  /** The last time an operator set the NOS version for the network */
  pendingNOSLastSetAt?: Maybe<Scalars['DateTime']['output']>;
  /** The pending NOS version that this network is scheduled to be upgraded to */
  pendingNOSVersionID?: Maybe<Scalars['Int']['output']>;
  /** A way to pin a network's devices to a known version of config by the config's max created at time. */
  pinnedDeviceConfigMaxCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The current NOS version for all devices in the network. */
  pinnedNOSVersionID: Scalars['Int']['output'];
  rackElevations: Array<RackElevation>;
  /** A human-readable identifier for the network. Used in URLs to identify the network. */
  slug: Scalars['String']['output'];
  /** @deprecated Not a network property anymore */
  spaceCategory?: Maybe<Scalars['String']['output']>;
  staggeredUpgradeIntervalMin: Scalars['Int']['output'];
  topologyImageURL?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  virtualDevices: Array<VirtualDevice>;
};

/** A network is a grouping of devices in a single location belonging to a company. A core Meter entity. */
export type NetworkVirtualDevicesArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
};

/** A network client is a large container for all of the data that constitutes a client connected to a network. */
export type NetworkClient = {
  __typename?: 'NetworkClient';
  alias?: Maybe<Scalars['String']['output']>;
  apLocation?: Maybe<Scalars['String']['output']>;
  apSerialNumber?: Maybe<Scalars['String']['output']>;
  /** When the client associated with the AP, if applicable. */
  associatedAt?: Maybe<Scalars['DateTime']['output']>;
  channel?: Maybe<Scalars['Int']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  connectedVLAN?: Maybe<Vlan>;
  dhcpLeaseExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  ip: Scalars['String']['output'];
  /** If false, the client is connected via a wired connection. */
  isWireless: Scalars['Boolean']['output'];
  lastSeen: Scalars['DateTime']['output'];
  /** @deprecated Use `latencyNs` instead. */
  latencyMs?: Maybe<Scalars['Int']['output']>;
  latencyNs?: Maybe<Scalars['Int']['output']>;
  macAddress: Scalars['MacAddress']['output'];
  networkUUID: Scalars['UUID']['output'];
  noise?: Maybe<Scalars['Int']['output']>;
  rxRate?: Maybe<Scalars['Int']['output']>;
  /** List of mdns service types that this client offers (e.g. printer, airplay, etc) */
  serviceTypes?: Maybe<Array<ServiceType>>;
  signal?: Maybe<Scalars['Int']['output']>;
  ssid?: Maybe<Scalars['String']['output']>;
  throughput?: Maybe<NetworkClientThroughput>;
  /** The total number of bytes received by the client over the requested lookback period. */
  totalRxBytes?: Maybe<Scalars['Int']['output']>;
  /** The total number of bytes transmitted by the client over the requested lookback period. */
  totalTxBytes?: Maybe<Scalars['Int']['output']>;
  txRate?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `connectedVLAN` instead. */
  vlan?: Maybe<Scalars['String']['output']>;
  /** vlanUUID is only supported for Config 2 networks. */
  vlanUUID?: Maybe<Scalars['UUID']['output']>;
  /** Wireless score computed based on the current health of the client */
  wirelessScore?: Maybe<Scalars['Float']['output']>;
};

export type NetworkClientThroughput = {
  __typename?: 'NetworkClientThroughput';
  /** The rx rate of a client in bps. */
  rxRate?: Maybe<Scalars['Float']['output']>;
  /** The tx rate of a client in bps. */
  txRate?: Maybe<Scalars['Float']['output']>;
};

export type NetworkClientTxRxPerDay = {
  __typename?: 'NetworkClientTxRxPerDay';
  /** The date to which the data is grouped by. */
  dateString: Scalars['String']['output'];
  ip: Scalars['String']['output'];
  macAddress: Scalars['MacAddress']['output'];
  /** The total number of bytes received by the client on this date. */
  rxBytes: Scalars['Int']['output'];
  /** The total number of bytes transmitted by the client on this date. */
  txBytes: Scalars['Int']['output'];
};

export type NetworkClientsFilter = {
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given AP serial number.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  apSerialNumber?: InputMaybe<Scalars['String']['input']>;
  excludeMeterHardware?: Scalars['Boolean']['input'];
  /**
   * If provided, then the ping latency from controller to client is included
   * in the response.
   */
  includeLatency?: Scalars['Boolean']['input'];
  /**
   * If provided, the average usage for all returned clients in the last
   * 30 minutes will be included in the response. If macAddress is provided this field has no effect.
   */
  includeThroughput?: Scalars['Boolean']['input'];
  /**
   * When no macAddress and apSerialNumber are provided filters resulting clients' lastSeen
   * to this many minutes before now.
   */
  lookbackMinutes?: Scalars['Int']['input'];
  /**
   * If a mac address is provided, this will get the history of clients with this mac address.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given SSID.
   * Only supported for config 2 COS networks.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  ssid?: InputMaybe<Scalars['String']['input']>;
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given VLAN.
   * Only supported for config 2 COS networks.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  vlanID?: InputMaybe<Scalars['Int']['input']>;
};

export enum NetworkEventType {
  AccessPointChangeStatus = 'ACCESS_POINT_CHANGE_STATUS',
  ControllerChangeStatus = 'CONTROLLER_CHANGE_STATUS',
  ControllerOffline = 'CONTROLLER_OFFLINE',
  LteFailover = 'LTE_FAILOVER',
  UnexpectedAccessPointRegistration = 'UNEXPECTED_ACCESS_POINT_REGISTRATION',
  VpnFailover = 'VPN_FAILOVER',
  WanChangeStatus = 'WAN_CHANGE_STATUS',
  WanFailover = 'WAN_FAILOVER',
}

export type NetworkJob = {
  UUID: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  networkUUID: Scalars['UUID']['output'];
  scheduledAt: Scalars['DateTime']['output'];
};

export type NetworkOnboarding = {
  __typename?: 'NetworkOnboarding';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  jobData?: Maybe<NetworkOnboardingJobData>;
  jobDataID: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NetworkOnboardingDocument = {
  __typename?: 'NetworkOnboardingDocument';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  delegations: Array<NetworkOnboardingFieldDelegation>;
  document: Scalars['JSONObject']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NetworkOnboardingFieldDelegation = {
  __typename?: 'NetworkOnboardingFieldDelegation';
  UUID: Scalars['UUID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
};

/** Stores logistical data for tracking the installation and onboarding of a network. */
export type NetworkOnboardingJobData = {
  __typename?: 'NetworkOnboardingJobData';
  fullAddress?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  installationEndDate?: Maybe<Scalars['DateTime']['output']>;
  installationStartDate?: Maybe<Scalars['DateTime']['output']>;
  jobID?: Maybe<Scalars['String']['output']>;
  jobOwnerUser?: Maybe<Scalars['String']['output']>;
  jobStage?: Maybe<Scalars['String']['output']>;
  jobStatus?: Maybe<Scalars['String']['output']>;
  jobTechnicianName?: Maybe<Array<Scalars['String']['output']>>;
  jobTechnicianPhone?: Maybe<Array<Scalars['String']['output']>>;
  locationCode?: Maybe<Scalars['String']['output']>;
  onsiteContactInformation?: Maybe<Scalars['String']['output']>;
  scheduleSiteSurveyLink?: Maybe<Array<Scalars['String']['output']>>;
  siteSurveyDate?: Maybe<Array<Scalars['String']['output']>>;
  siteSurveyStatus?: Maybe<Array<Scalars['String']['output']>>;
  targetGoLiveDate?: Maybe<Scalars['DateTime']['output']>;
  totalSqFt?: Maybe<Array<Scalars['String']['output']>>;
};

/** A network route defines a route within the networking stack on a device. It can refer to either a physical interface, vlan interface, or another linux interface. */
export type NetworkRoute = {
  __typename?: 'NetworkRoute';
  attachment: NetworkRouteAttachment;
  destination: Scalars['IPPrefix']['output'];
  gateway?: Maybe<Scalars['IPV4']['output']>;
};

/** One field of NetworkRouteAttachment will be non-null */
export type NetworkRouteAttachment = {
  __typename?: 'NetworkRouteAttachment';
  autoVPNGroup?: Maybe<AutoVpnGroup>;
  clientVPNServer?: Maybe<ClientVpnServer>;
  ipSecTunnel?: Maybe<IpSecTunnel>;
  phyInterface?: Maybe<PhyInterface>;
  staticRoute?: Maybe<StaticRoute>;
  vlan?: Maybe<Vlan>;
};

export type NetworkStatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 7776000, 90 days. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /** The list of stat types to return. */
  types: Array<StatType>;
  /** The virtualDeviceUUIDs of the stats to return. */
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type NetworkUplinkQualityResponse = MetricsResponse & {
  __typename?: 'NetworkUplinkQualityResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<NetworkUplinkQualityValue>;
};

export type NetworkUplinkQualityValue = MetricsValue & {
  __typename?: 'NetworkUplinkQualityValue';
  /** There are a lot of data points so shouldn't embed the phyInterface type in this type. */
  phyInterfaceUUID: Scalars['UUID']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type NetworkUplinkThroughputMetricsResponse = MetricsResponse & {
  __typename?: 'NetworkUplinkThroughputMetricsResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<NetworkUplinkThroughputMetricsValue>;
};

export type NetworkUplinkThroughputMetricsValue = MetricsValue & {
  __typename?: 'NetworkUplinkThroughputMetricsValue';
  direction: TrafficDirection;
  /** There are a lot of data points so shouldn't embed the phyInterface type in this type. */
  phyInterfaceUUID: Scalars['UUID']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type NetworksBooleanFilter = {
  eq: Scalars['Boolean']['input'];
};

export type NetworksCompanySupportTierFilter = {
  in: Array<CompanySupportTier>;
  not?: Scalars['Boolean']['input'];
};

export type NetworksDateTimeFilter = {
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NetworksFilter = {
  active?: InputMaybe<NetworksBooleanFilter>;
  companySupportTier?: InputMaybe<NetworksCompanySupportTierFilter>;
  hardwareModel?: InputMaybe<NetworksHardwareModelFilter>;
  lastUpgradedAt?: InputMaybe<NetworksDateTimeFilter>;
  nosVersionID?: InputMaybe<NetworksNosVersionIdFilter>;
  pendingUpgrade?: InputMaybe<NetworksBooleanFilter>;
  upgradeSensitivity?: InputMaybe<NetworksBooleanFilter>;
};

export type NetworksHardwareModelFilter = {
  in: Array<DeviceModel>;
  not?: Scalars['Boolean']['input'];
};

export type NetworksNosVersionIdFilter = {
  in: Array<Scalars['Int']['input']>;
  not?: Scalars['Boolean']['input'];
};

/** A NOS feature is a feature flag that tells us what minimum/maximum version of NOS firmware is needed to be installed on the network to support a given feature. */
export type NosFeature = {
  __typename?: 'NosFeature';
  description: Scalars['String']['output'];
  /** If the feature is also behind a feature flag, this is the name of the feature flag. */
  featureFlag?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  maxMajorVersion?: Maybe<Scalars['Int']['output']>;
  maxMinorVersion?: Maybe<Scalars['Int']['output']>;
  maxPatchVersion?: Maybe<Scalars['Int']['output']>;
  minMajorVersion: Scalars['Int']['output'];
  minMinorVersion: Scalars['Int']['output'];
  minPatchVersion: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type NosFeatureEnabled = {
  __typename?: 'NosFeatureEnabled';
  enabled: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
};

/** A NOS (Network Operating System) version is a grouping of build strings for various device types. Together they constitute a network-wide firmware release. */
export type NosVersion = {
  __typename?: 'NosVersion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  isDeprecated: Scalars['Boolean']['output'];
  isGA: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  major: Scalars['Int']['output'];
  /** controllers */
  mc01Build?: Maybe<Scalars['String']['output']>;
  minor: Scalars['Int']['output'];
  /** power distribution unit */
  mp01Build?: Maybe<Scalars['String']['output']>;
  /** switches */
  ms10Build?: Maybe<Scalars['String']['output']>;
  ms11Build?: Maybe<Scalars['String']['output']>;
  ms12Build?: Maybe<Scalars['String']['output']>;
  /** wireless */
  mw03Build?: Maybe<Scalars['String']['output']>;
  mw04Build?: Maybe<Scalars['String']['output']>;
  mw06Build?: Maybe<Scalars['String']['output']>;
  mw07Build?: Maybe<Scalars['String']['output']>;
  mw08Build?: Maybe<Scalars['String']['output']>;
  mw09Build?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** observer */
  observerBuild?: Maybe<Scalars['String']['output']>;
  patch: Scalars['Int']['output'];
  releaseNotes?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['String']['output'];
};

export type NosVersionInput = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  isGA?: InputMaybe<Scalars['Boolean']['input']>;
  isLocked?: InputMaybe<Scalars['Boolean']['input']>;
  major?: InputMaybe<Scalars['Int']['input']>;
  /** controllers */
  mc01Build?: InputMaybe<Scalars['String']['input']>;
  minor?: InputMaybe<Scalars['Int']['input']>;
  /** power distribution unit */
  mp01Build?: InputMaybe<Scalars['String']['input']>;
  /** switches */
  ms10Build?: InputMaybe<Scalars['String']['input']>;
  ms11Build?: InputMaybe<Scalars['String']['input']>;
  ms12Build?: InputMaybe<Scalars['String']['input']>;
  /** wireless */
  mw03Build?: InputMaybe<Scalars['String']['input']>;
  mw04Build?: InputMaybe<Scalars['String']['input']>;
  mw06Build?: InputMaybe<Scalars['String']['input']>;
  mw07Build?: InputMaybe<Scalars['String']['input']>;
  mw08Build?: InputMaybe<Scalars['String']['input']>;
  mw09Build?: InputMaybe<Scalars['String']['input']>;
  /** observer */
  observerBuild?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<Scalars['Int']['input']>;
  releaseNotes?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
};

export type NotificationPreferenceInput = {
  additionalEventTypes?: InputMaybe<Array<NetworkEventType>>;
  additionalSlackChannels?: InputMaybe<Array<Scalars['String']['input']>>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** This feature is deprecated. */
export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  additionalEventTypes?: Maybe<Array<NetworkEventType>>;
  additionalSlackChannels?: Maybe<Array<Scalars['String']['output']>>;
  company: Company;
  companySID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type NotificationPreferencesResponse = {
  __typename?: 'NotificationPreferencesResponse';
  companyPreferences?: Maybe<NotificationPreferences>;
  networkPreferences?: Maybe<NotificationPreferences>;
};

/**
 * Notification settings contain preferences for how a user should be notified when events happen on a network.
 * They specify a receiver of the alert, the delivery mechanism, the type of alert, and alert-specific metadata to provide further context.
 */
export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  UUID: Scalars['UUID']['output'];
  alertReceiverUUID: Scalars['UUID']['output'];
  arguments: Array<NotificationSettingArgument>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  notificationDefinition?: Maybe<AlertDefinition>;
  notificationDefinitionName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type NotificationSettingArgument = {
  __typename?: 'NotificationSettingArgument';
  parameterUUID: Scalars['UUID']['output'];
  value: Scalars['Any']['output'];
};

export type NotificationSettingArgumentInput = {
  parameterUUID: Scalars['UUID']['input'];
  /** Value is required. Only marked as nullable as a workaround for client zod validation. */
  value?: InputMaybe<Scalars['Any']['input']>;
};

/** An observer virtual device is a logical representation of a physical observer. */
export type ObserverVirtualDevice = VirtualDevice & {
  __typename?: 'ObserverVirtualDevice';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Whether the device been configured to turn off all LED activity. */
  isLEDDarkModeEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

/** 1:1 NAT Rules are used to map a single external IP address to a single internal IP address. */
export type OneToOneNatRule = {
  __typename?: 'OneToOneNATRule';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalIPAddr: Scalars['IPV4']['output'];
  externalPhyInterface: PhyInterface;
  externalPhyInterfaceUUID: Scalars['UUID']['output'];
  internalIPAddr: Scalars['IPV4']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Specifies settings for power outlet on a power distribution unit. */
export type OutletInterface = {
  __typename?: 'OutletInterface';
  UUID: Scalars['UUID']['output'];
  connectedVirtualDevice?: Maybe<VirtualDevice>;
  /** UUID of the virtual device connected to the power outlet */
  connectedVirtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The current being drawn from the outlet in Amps */
  currentAmps?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Whether or not the interface is enabled */
  isEnabled: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  outletNumber: Scalars['Int']['output'];
  /**
   * This is the number of seconds this outlet will use when it receives a power cycle request.
   * It turns the outlet off, then waits for powerCycleDelaySec, and then turns the outlet back on
   */
  powerCycleDelaySec: Scalars['Int']['output'];
  /** The power being drawn from the outlet in Watts */
  powerWatts?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/** Specifies the NOS firmware version that a device is scheduled to upgrade to. */
export type PendingNosVersion = {
  __typename?: 'PendingNosVersion';
  nosVersion: NosVersion;
  scheduledAt: Scalars['DateTime']['output'];
};

export enum Permission {
  Admin = 'ADMIN',
  Api = 'API',
  Bastion = 'BASTION',
  Ci = 'CI',
  Cmd = 'CMD',
  Connect = 'CONNECT',
  ConnectAdmin = 'CONNECT_ADMIN',
  Controller = 'CONTROLLER',
  Dashboard = 'DASHBOARD',
  InventoryApp = 'INVENTORY_APP',
  Jumphost = 'JUMPHOST',
  Logs = 'LOGS',
  Noc = 'NOC',
  Ops = 'OPS',
  Portal = 'PORTAL',
  ProvisionController = 'PROVISION_CONTROLLER',
  Rpcbroker = 'RPCBROKER',
  Stats = 'STATS',
  StatConsumer = 'STAT_CONSUMER',
  StatUpload = 'STAT_UPLOAD',
  Worker = 'WORKER',
}

/**
 * A PermissionAssignment denotes the permissions of a user in context of a company or company & network.
 *
 * In the case of an Operator role, networkUUID field will be null.
 */
export type PermissionAssignment = {
  __typename?: 'PermissionAssignment';
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  permissions: Array<PermissionType>;
};

export type PermissionCheckResult = {
  __typename?: 'PermissionCheckResult';
  hasPermission: Scalars['Boolean']['output'];
};

/**
 * Code generated by mctl. DO NOT EDIT.
 * permissions are generated from src/auth/permissions/permissions.go
 */
export enum PermissionType {
  PermAlertReceiverRead = 'PERM_ALERT___RECEIVER_READ',
  PermAlertReceiverWrite = 'PERM_ALERT___RECEIVER_WRITE',
  PermAuditLogRead = 'PERM_AUDIT___LOG_READ',
  PermAuditLogWrite = 'PERM_AUDIT___LOG_WRITE',
  PermAuthenticationTokenRead = 'PERM_AUTHENTICATION___TOKEN_READ',
  PermAuthenticationTokenWrite = 'PERM_AUTHENTICATION___TOKEN_WRITE',
  PermAutoSelectionJobRead = 'PERM_AUTO___SELECTION___JOB_READ',
  PermAutoSelectionJobWrite = 'PERM_AUTO___SELECTION___JOB_WRITE',
  PermAutoVpnRead = 'PERM_AUTO___VPN_READ',
  PermAutoVpnWrite = 'PERM_AUTO___VPN_WRITE',
  PermBearerSessionWrite = 'PERM_BEARER___SESSION_WRITE',
  PermBulkNosUpgradeRead = 'PERM_BULK___NOS___UPGRADE_READ',
  PermBulkNosUpgradeWrite = 'PERM_BULK___NOS___UPGRADE_WRITE',
  PermCaptivePortalRead = 'PERM_CAPTIVE___PORTAL_READ',
  PermCaptivePortalWrite = 'PERM_CAPTIVE___PORTAL_WRITE',
  PermCellularWrite = 'PERM_CELLULAR_WRITE',
  PermClientVpnClientRead = 'PERM_CLIENT___VPN_CLIENT_READ',
  PermClientVpnClientWrite = 'PERM_CLIENT___VPN_CLIENT_WRITE',
  PermClientVpnRead = 'PERM_CLIENT___VPN_READ',
  PermClientVpnWrite = 'PERM_CLIENT___VPN_WRITE',
  PermCompanyAdminAssign = 'PERM_COMPANY_ADMIN_ASSIGN',
  PermCompanyGet = 'PERM_COMPANY_GET',
  PermCompanyGetRestricted = 'PERM_COMPANY_GET_RESTRICTED',
  PermCompanyList = 'PERM_COMPANY_LIST',
  PermCompanyNetworkAdminAssign = 'PERM_COMPANY_NETWORK___ADMIN_ASSIGN',
  PermCompanySsoRead = 'PERM_COMPANY_SSO_READ',
  PermCompanySsoWrite = 'PERM_COMPANY_SSO_WRITE',
  PermCompanySupportTierReadRestricted = 'PERM_COMPANY_SUPPORT___TIER_READ_RESTRICTED',
  PermCompanyUpdate = 'PERM_COMPANY_UPDATE',
  PermCompanyUsersViewMeterEmployees = 'PERM_COMPANY_USERS_VIEW__METER__EMPLOYEES',
  PermCompanyUserAssign = 'PERM_COMPANY_USER_ASSIGN',
  PermCompanyWriteRestricted = 'PERM_COMPANY_WRITE_RESTRICTED',
  /** Allows a user to access the dashboard. */
  PermDashboardAccess = 'PERM_DASHBOARD_ACCESS',
  PermDhcpDnsRead = 'PERM_DHCP___DNS_READ',
  PermDhcpDnsWrite = 'PERM_DHCP___DNS_WRITE',
  PermEventLogRead = 'PERM_EVENT___LOG_READ',
  PermFirewallRead = 'PERM_FIREWALL_READ',
  PermFirewallWrite = 'PERM_FIREWALL_WRITE',
  PermFirewallWriteRestricted = 'PERM_FIREWALL_WRITE_RESTRICTED',
  PermFloorPlanRead = 'PERM_FLOOR___PLAN_READ',
  PermFloorPlanWrite = 'PERM_FLOOR___PLAN_WRITE',
  PermHardwareDeviceAssign = 'PERM_HARDWARE___DEVICE_ASSIGN',
  PermHardwareDeviceDevWrite = 'PERM_HARDWARE___DEVICE_DEV_WRITE',
  PermHardwareDeviceLogin = 'PERM_HARDWARE___DEVICE_LOGIN',
  PermHardwareDeviceRead = 'PERM_HARDWARE___DEVICE_READ',
  PermHardwareDeviceReadLimited = 'PERM_HARDWARE___DEVICE_READ_LIMITED',
  PermHardwareDeviceRpcBlinkLeds = 'PERM_HARDWARE___DEVICE_RPC_BLINK___LEDS',
  PermHardwareDeviceRpcCableTest = 'PERM_HARDWARE___DEVICE_RPC_CABLE___TEST',
  PermHardwareDeviceRpcPortCycle = 'PERM_HARDWARE___DEVICE_RPC_PORT___CYCLE',
  PermHardwareDeviceRpcRefreshConfig = 'PERM_HARDWARE___DEVICE_RPC_REFRESH___CONFIG',
  PermHighAvailabilityRead = 'PERM_HIGH___AVAILABILITY_READ',
  PermHighAvailabilityWrite = 'PERM_HIGH___AVAILABILITY_WRITE',
  PermHostMonitoringRead = 'PERM_HOST___MONITORING_READ',
  PermHostMonitoringWrite = 'PERM_HOST___MONITORING_WRITE',
  PermIpsecTunnelRead = 'PERM_IPSEC___TUNNEL_READ',
  PermIpsecTunnelWrite = 'PERM_IPSEC___TUNNEL_WRITE',
  PermIspRead = 'PERM_ISP_READ',
  PermIspWrite = 'PERM_ISP_WRITE',
  PermIspWriteRestricted = 'PERM_ISP_WRITE_RESTRICTED',
  PermMacAddressAliasRead = 'PERM_MAC___ADDRESS___ALIAS_READ',
  PermMacAddressAliasWrite = 'PERM_MAC___ADDRESS___ALIAS_WRITE',
  PermMaintenanceWindowRead = 'PERM_MAINTENANCE___WINDOW_READ',
  PermMaintenanceWindowWrite = 'PERM_MAINTENANCE___WINDOW_WRITE',
  PermNetworkInternalNoteRead = 'PERM_NETWORK_INTERNAL___NOTE_READ',
  PermNetworkInternalNoteWrite = 'PERM_NETWORK_INTERNAL___NOTE_WRITE',
  PermNetworkMetricsRead = 'PERM_NETWORK_METRICS_READ',
  PermNetworkNosWrite = 'PERM_NETWORK_NOS_WRITE',
  PermNetworkOnboardingRead = 'PERM_NETWORK_ONBOARDING_READ',
  PermNetworkOnboardingWrite = 'PERM_NETWORK_ONBOARDING_WRITE',
  PermNetworkOnboardingWriteRestricted = 'PERM_NETWORK_ONBOARDING_WRITE_RESTRICTED',
  PermNetworkRead = 'PERM_NETWORK_READ',
  PermNetworkReadLimited = 'PERM_NETWORK_READ_LIMITED',
  PermNetworkSettingsRead = 'PERM_NETWORK_SETTINGS_READ',
  PermNetworkSettingsUpdate = 'PERM_NETWORK_SETTINGS_UPDATE',
  PermNetworkSettingsWrite = 'PERM_NETWORK_SETTINGS_WRITE',
  PermNetworkSshRead = 'PERM_NETWORK_SSH_READ',
  PermNetworkSshWrite = 'PERM_NETWORK_SSH_WRITE',
  PermNetworkWriteRestricted = 'PERM_NETWORK_WRITE_RESTRICTED',
  PermNetworkDevicesRead = 'PERM_NETWORK___DEVICES_READ',
  PermNetworkDevicesReadRestricted = 'PERM_NETWORK___DEVICES_READ_RESTRICTED',
  PermNetworkDevicesWrite = 'PERM_NETWORK___DEVICES_WRITE',
  PermNetworkDevicesWriteRestricted = 'PERM_NETWORK___DEVICES_WRITE_RESTRICTED',
  PermNetworkRouteRead = 'PERM_NETWORK___ROUTE_READ',
  PermNetworkRouteWrite = 'PERM_NETWORK___ROUTE_WRITE',
  PermNosRead = 'PERM_NOS_READ',
  PermNosWrite = 'PERM_NOS_WRITE',
  PermNosFeatureRead = 'PERM_NOS___FEATURE_READ',
  PermNosFeatureWrite = 'PERM_NOS___FEATURE_WRITE',
  PermNotificationPreferenceRead = 'PERM_NOTIFICATION___PREFERENCE_READ',
  PermNotificationPreferenceWrite = 'PERM_NOTIFICATION___PREFERENCE_WRITE',
  PermOneToOneNatRead = 'PERM_ONE___TO___ONE___NAT_READ',
  PermOneToOneNatWrite = 'PERM_ONE___TO___ONE___NAT_WRITE',
  PermOperatorAssign = 'PERM_OPERATOR_ASSIGN',
  PermPermittedEmailDomainRead = 'PERM_PERMITTED___EMAIL___DOMAIN_READ',
  PermPermittedEmailDomainWrite = 'PERM_PERMITTED___EMAIL___DOMAIN_WRITE',
  PermPhyInterfaceRead = 'PERM_PHY___INTERFACE_READ',
  PermPhyInterfaceReadLimited = 'PERM_PHY___INTERFACE_READ_LIMITED',
  PermPhyInterfaceWrite = 'PERM_PHY___INTERFACE_WRITE',
  PermPolicyRoutingRead = 'PERM_POLICY___ROUTING_READ',
  PermPolicyRoutingWrite = 'PERM_POLICY___ROUTING_WRITE',
  PermPortForwardRead = 'PERM_PORT___FORWARD_READ',
  PermPortForwardWrite = 'PERM_PORT___FORWARD_WRITE',
  PermRackElevationRead = 'PERM_RACK___ELEVATION_READ',
  PermRackElevationWrite = 'PERM_RACK___ELEVATION_WRITE',
  PermRadioProfileRead = 'PERM_RADIO___PROFILE_READ',
  PermRadioProfileWrite = 'PERM_RADIO___PROFILE_WRITE',
  PermRateLimitRead = 'PERM_RATE___LIMIT_READ',
  PermRateLimitWrite = 'PERM_RATE___LIMIT_WRITE',
  PermSsidsRead = 'PERM_SSIDS_READ',
  PermSsidsWrite = 'PERM_SSIDS_WRITE',
  PermStatEventWrite = 'PERM_STAT___EVENT_WRITE',
  PermSwitchProfileRead = 'PERM_SWITCH___PROFILE_READ',
  PermSwitchProfileWrite = 'PERM_SWITCH___PROFILE_WRITE',
  PermUserCreate = 'PERM_USER_CREATE',
  PermUserRead = 'PERM_USER_READ',
  PermUserUpdate = 'PERM_USER_UPDATE',
  PermVirtualDeviceCreate = 'PERM_VIRTUAL___DEVICE_CREATE',
  PermVirtualDeviceReadLimited = 'PERM_VIRTUAL___DEVICE_READ_LIMITED',
  PermVlanRead = 'PERM_VLAN_READ',
  PermVlanWrite = 'PERM_VLAN_WRITE',
  PermVlanWriteRestricted = 'PERM_VLAN_WRITE_RESTRICTED',
}

export type PermissionsInput = {
  companySlug?: InputMaybe<Scalars['String']['input']>;
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type PermittedEmailDomain = {
  __typename?: 'PermittedEmailDomain';
  createdAt: Scalars['DateTime']['output'];
  domain: Scalars['String']['output'];
  sid?: Maybe<Scalars['UUID']['output']>;
};

export type PhyInterface = {
  __typename?: 'PhyInterface';
  /** The maximum transmission unit - max size in bytes of frames that the port can tx/rx */
  MTU?: Maybe<Scalars['Int']['output']>;
  UUID: Scalars['UUID']['output'];
  /** The allowed (tagged) VLANs that are allowed to have traffic on this interface. */
  allowedVLANs?: Maybe<Array<Vlan>>;
  connectedDevices?: Maybe<Array<PhyInterfaceConnectedDevice>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** The maximum amount of data that can be transmitted through the interface, in Mbps. */
  forcedPortSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** An optional filter to only accept certain frame types on this port. If null, all frame types are accepted. */
  frameAcceptTypeFilter?: Maybe<PhyInterfaceFrameAcceptTypeFilter>;
  /** Static hardware port label, if present. */
  hardwareLabel?: Maybe<Scalars['String']['output']>;
  /** If this interface represents a WAN port, denotes if there is any recent activity which would represent a connection to an ISP. */
  hasWANActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Configuration for high availability associated with this interface, if on a controller configured in HA mode for this port. */
  highAvailability?: Maybe<HighAvailabilityControllerVirtualDeviceInfo>;
  /**
   * If this interface represents a WAN port, attempt to match the most recently reported IP for the port against the IP
   * address information given to us about the connection from the provider.
   * If there is not a corresponding ISP row in the database, but there is still activity on this WAN port, the result will
   * still be empty. Use `portSpeedMbps` to determine if the WAN port has activity regardless of us being able to match it to an ISP.
   */
  internetServicePlan?: Maybe<InternetServicePlan>;
  /** If assigning this interface as a DHCP client, this field indicates the IPv4 addresses to assign to the interface. These INET values should have no netmask. */
  ipv4ClientAddresses?: Maybe<Array<Scalars['IPV4']['output']>>;
  /** If assigning this interface as a DHCP client, this field indicates the protocol to use for IPv4 addresses. */
  ipv4ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a DHCP client, this field indicates the gateway IPv4 address. This INET value should have no netmask. */
  ipv4ClientGateway?: Maybe<Scalars['IPV4']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the subnet prefix length for the IPv4 addresses. */
  ipv4ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the IPv6 addresses to assign to the interface. These INET values should have no netmask. */
  ipv6ClientAddresses?: Maybe<Array<Scalars['IPV6']['output']>>;
  /** If assigning this interface as a DHCP client, this field indicates the protocol to use for IPv6 addresses. */
  ipv6ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a DHCP client, this field indicates the gateway IPv6 address. This INET value should have no netmask. */
  ipv6ClientGateway?: Maybe<Scalars['IPV6']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the subnet prefix length for the IPv6 addresses. */
  ipv6ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** is the port blocked due to too many ports flaps (link down/up) */
  isBlockedDueToFlaps: Scalars['Boolean']['output'];
  /** Whether or not the interface is implicitly bound to all VLANs, including any future VLANs that are created. If set to true, all VLAN bindings (excluding the native VLAN) will be tagged on this interface. */
  isBoundToAllVLANs: Scalars['Boolean']['output'];
  /** Whether or not the interface is enabled. Interfaces that are not enabled are stsrc/ill visible to the user. */
  isEnabled: Scalars['Boolean']['output'];
  isEthernet: Scalars['Boolean']['output'];
  /** If true, tagged frames are discarded when the VLAN ID of the frame does not match the VLAN ID of the port. */
  isIngressFilteringEnabled: Scalars['Boolean']['output'];
  /** If true, clients on isolated ports cannot communicate with other isolated ports. They can only talk to non-isolated ports */
  isIsolationEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** is the port blocked due to loopback detection */
  isLoopbackDetected?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the interface is enabled to serve Power over Ethernet. */
  isPOEEnabled: Scalars['Boolean']['output'];
  isSFP: Scalars['Boolean']['output'];
  /** If true, this interface will be configured as an edge port in STP (Spanning Tree Protocol). */
  isSTPEdgePortEnabled: Scalars['Boolean']['output'];
  /** Whether or not the interface is enabled to serve Spanning Tree Protocol. */
  isSTPEnabled: Scalars['Boolean']['output'];
  /** is the port forwarding (true) or blocking (false) due to STP */
  isSTPForwarding?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not storm control is enabled on the port. */
  isStormControlEnabled: Scalars['Boolean']['output'];
  /** A trunk port is a port that is assigned to carry traffic for multiple VLANs. */
  isTrunkPort: Scalars['Boolean']['output'];
  /** An uplink interface connects the device to other networks or network devices. */
  isUplink: Scalars['Boolean']['output'];
  /** Only populated if `isUplink` is true. Indicates if this uplink phy interface is currently active, this should usually not be set manually. */
  isUplinkActive?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /** For switch port security, the max number of MAC addresses allowed on the port */
  maxMACAddressesAllowed?: Maybe<Scalars['Int']['output']>;
  /** Static value from hardware specification. */
  maxSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** The native (untagged) VLAN that is assigned to this interface. */
  nativeVLAN?: Maybe<Vlan>;
  /** The physical port number on the device that the interface is located at. */
  portNumber: Scalars['Int']['output'];
  /** The link speed on the port and populated in our time series database. */
  portSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `isEthernet` or `isSFP` instead */
  portType: PortType;
  /** The power draw on the port and populated in our time series database for PoE. */
  powerDraw?: Maybe<Scalars['Float']['output']>;
  /** Receive rate limit for a port (in Kbps) */
  rxRateLimitKbps?: Maybe<Scalars['Int']['output']>;
  /** SFP module information (if a SFP module is plugged into the port) */
  sfpModuleInfo?: Maybe<SfpModuleInfo>;
  /** The percent of broadcast traffic (calculated as a percent of the max capability of the port) to drop if storm control is enabled. */
  stormControlBroadcastTrafficPercent: Scalars['Int']['output'];
  /** The percent of multicast traffic (calculated as a percent of the max capability of the port) to drop if storm control is enabled. */
  stormControlUnknownMulticastTrafficPercent: Scalars['Int']['output'];
  /** The percent of unicast traffic (calculated as a percent of the max capability of the port) to drop if storm control is enabled. */
  stormControlUnknownUnicastTrafficPercent: Scalars['Int']['output'];
  /** STP Path cost for the port. (1-200000000) */
  stpPathCost?: Maybe<Scalars['Int']['output']>;
  stpPortRole?: Maybe<StpPortRole>;
  /** STP Priority for the port. (0-240 in multiples of 16, default 128) */
  stpPriority?: Maybe<Scalars['Int']['output']>;
  /** The throughput (RX/TX) for the last 24h on the port populated in our time series database. */
  throughputLastDay?: Maybe<Array<ThroughputValueWithDirection>>;
  /** Transmit rate limit for a port (in Kbps) */
  txRateLimitKbps?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Only populated if `isUplink` is true. Holds the stats reported addresses of the physical interface, this should usually not be set manually. */
  uplinkExternalAddresses?: Maybe<Array<Scalars['UnmaskedIPPrefix']['output']>>;
  /** Can only be populated if `isUplink` is true. The MAC Address of the upstream device this phy interface is connected to. */
  uplinkGatewayMACAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** Can only be populated if `isUplink` is true. The order an uplink should be used. A lower priority takes precedent including over uplinks with a NULL priority. */
  uplinkPriority?: Maybe<Scalars['Int']['output']>;
  /** Can only be populated if `isUplink` is true. */
  uplinkVLANID?: Maybe<Scalars['Int']['output']>;
  virtualDevice: VirtualDevice;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type PhyInterfaceConnectedDevice = {
  __typename?: 'PhyInterfaceConnectedDevice';
  /** @deprecated Use `hardwareDevice` instead. */
  device?: Maybe<Device>;
  /** If the macAddress above belongs to a meter device, we will load it from the DB by the MAC and populate this field. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** MAC Addresses of device that is plugged into the port. Populated in our time series database via LLDP. */
  macAddress: Scalars['MacAddress']['output'];
  /** A pointer back to the PhyInterface, which we can use to access the original device it belonged to, etc. */
  phyInterface: PhyInterface;
};

export enum PhyInterfaceFrameAcceptTypeFilter {
  TaggedOnly = 'TAGGED_ONLY',
  UntaggedOnly = 'UNTAGGED_ONLY',
}

/** Contains info about the PoE (Power over Ethernet) usage of a hardware device. */
export type PoEInfo = {
  __typename?: 'PoEInfo';
  max?: Maybe<Scalars['Float']['output']>;
  maxCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  used?: Maybe<Scalars['Float']['output']>;
  usedCreatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PolicyRoutingRule = {
  __typename?: 'PolicyRoutingRule';
  UUID: Scalars['UUID']['output'];
  bindings?: Maybe<Array<PolicyRoutingRuleBinding>>;
  createdAt: Scalars['DateTime']['output'];
  dstPortRanges: Array<PortRange>;
  dstPrefix: Scalars['IPPrefix']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  protocols: Array<IpProtocol>;
  srcPortRanges: Array<PortRange>;
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  srcVLAN?: Maybe<Vlan>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PolicyRoutingRuleBinding = {
  __typename?: 'PolicyRoutingRuleBinding';
  UUID: Scalars['UUID']['output'];
  metric: Scalars['Int']['output'];
  phyInterface: PhyInterface;
};

/** One of vlanUUID or phyInterfaceUUID is required. */
export type PolicyRoutingRuleBindingInput = {
  /** The phy interface must be an uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

/** Port forwarding rules for a network define a how incoming traffic is forwarded to a local destination. */
export type PortForwardingRule = {
  __typename?: 'PortForwardingRule';
  UUID: Scalars['UUID']['output'];
  /** Restricts the port forward rule to only allow traffic to be forwarded if it originates from any of these IPs. */
  allowedRemoteIPAddresses?: Maybe<Array<Scalars['IP']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalIPAddress?: Maybe<Scalars['IP']['output']>;
  externalPhyInterface?: Maybe<PhyInterface>;
  /**
   * One of `externalPhyInterfaceUUID`, `externalVLANUUID`, or `externalIPAddress` must be set.
   * If provided, the port forwarding rule only applies for this external physical interface. Otherwise, the rule will apply for all external interfaces on a device. Application logic will ensure that this interface is an uplink interface.
   */
  externalPhyInterfaceUUID?: Maybe<Scalars['UUID']['output']>;
  /** The external port to forward to the local port. */
  externalPort: Scalars['Int']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** The local IP to forward traffic to. This INET must be a single IP. */
  localIPAddress: Scalars['IP']['output'];
  /** The local port that receives traffic from the port forward. */
  localPort: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The IP protocol to apply this rule on. These are the IANA defined protocol numbers. 6 = TCP, 17 = UDP. A value of 256 is reserved by IANA and will be used to denote all protocols. */
  protocol: PortIpProtocol;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PortIpProtocol {
  Tcp = 'TCP',
  Udp = 'UDP',
}

export type PortRange = {
  __typename?: 'PortRange';
  lower: Scalars['Int']['output'];
  upper: Scalars['Int']['output'];
};

export type PortRangeInput = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export enum PortType {
  Ethernet = 'ETHERNET',
  Sfp = 'SFP',
  Unknown = 'UNKNOWN',
}

/** A power distribution unit hardware device represents a physical power distribution unit. */
export type PowerDistributionUnitHardwareDevice = HardwareDevice & {
  __typename?: 'PowerDistributionUnitHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A power distribution unit hardware device represents a physical power distribution unit. */
export type PowerDistributionUnitHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** A power distribution unit virtual device is a logical representation of a physical power distribution unit. */
export type PowerDistributionUnitVirtualDevice = VirtualDevice & {
  __typename?: 'PowerDistributionUnitVirtualDevice';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceTemperatureCelsius?: Maybe<Scalars['Float']['output']>;
  deviceType: VirtualDeviceType;
  /** The ethernet interface speed. */
  ethernetSpeedMbps?: Maybe<Scalars['Int']['output']>;
  hardwareDevice?: Maybe<HardwareDevice>;
  /** The IP address from our time series database for the device. */
  ipAddress?: Maybe<Scalars['IPV4']['output']>;
  /** Circuit breaker good/bad */
  isCircuitBreakerGood?: Maybe<Scalars['Boolean']['output']>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the PDU is grounded properly */
  isGrounded?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the PDU is surge protected */
  isSurgeProtectionGood?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  maxPowerAllowedWatts?: Maybe<Scalars['Float']['output']>;
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  outletInterfaces: Array<OutletInterface>;
  pendingNosVersion?: Maybe<PendingNosVersion>;
  totalPowerUsedWatts?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export enum PrincipalType {
  Device = 'DEVICE',
  Machine = 'MACHINE',
  User = 'USER',
}

export type Query = {
  __typename?: 'Query';
  /** Active clients are clients that currently appear both in the MAC and ARP tables of a network. */
  activeClients: ActiveClientsMetricsResponse;
  /** An Operator-only query to get all active networks belonging to a company with the given company support tier. */
  activeNetworksByCompanySupportTier: Array<Network>;
  /** Fetch all of the known types of Alerts that notify stakeholders of important events for a network. */
  alertDefinitions: Array<AlertDefinition>;
  alertReceiver: AlertReceiver;
  /** Get all alert receivers and their targets for a company. */
  alertReceiversForCompany: Array<AlertReceiver>;
  alertTargetWebhook: AlertTargetWebhook;
  /** Get alert targets of all types for a company. */
  alertTargets: AlertTargetsResponse;
  alertTargetsWebhook: Array<AlertTargetWebhook>;
  /**
   * A Config1-only resolver. Band defaults to 2.4Ghz.
   * @deprecated Use channelUtilizationByAP for config 2 networks
   */
  apChannelUtilization: ApChannelUtilizationResponse;
  /** Returns the uptime (how long since a device last booted) of the APs in the network. */
  apUptime: ApUptimeResponse;
  applicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Given a hostname, returns the application that would be applied to it. */
  applicationDNSFirewallRuleApplicationForHostname: ApplicationDnsFirewallRuleApplication;
  /**
   * Fetch all applications that DNS firewall rules can be associated with.
   * This currently returns all applications – in the future this will only return visible applications for customers.
   */
  applicationDNSFirewallRuleApplications: Array<ApplicationDnsFirewallRuleApplication>;
  /** Fetch all available Categories of DNS firewall rules. */
  applicationDNSFirewallRuleCategories: Array<ApplicationDnsFirewallRuleCategory>;
  /** Given a hostname, returns the category of DNS firewall rule that would be applied to it. */
  applicationDNSFirewallRuleCategoryForHostname: ApplicationDnsFirewallRuleCategory;
  /** Fetch all groups of DNS firewall rules. */
  applicationDNSFirewallRuleGroups: Array<ApplicationDnsFirewallRuleGroup>;
  /** Fetch all application DNS firewall rules for a network. */
  applicationDNSFirewallRulesForNetwork: Array<ApplicationDnsFirewallRule>;
  /**
   * A bulk-version of isNosFeatureEnabledForCompany for multiple keys.
   * In dev, this always returns true for a key if it is not recognized on the backend.
   * This checks if *any* network for companySlug supports nosFeatureKeys.
   */
  areNosFeaturesEnabledForCompany: Array<NosFeatureEnabled>;
  /**
   * A bulk-version of isNosFeatureEnabledForNetwork for multiple keys.
   * In dev, this always returns true for a key if it is not recognized on the backend.
   */
  areNosFeaturesEnabledForNetwork: Array<NosFeatureEnabled>;
  /** Fetch an authentication token by UUID. */
  authenticationToken: AuthenticationToken;
  autoChannelSelectionJob: AutoChannelSelectionJob;
  autoChannelSelectionJobsForNetwork?: Maybe<Array<AutoChannelSelectionJob>>;
  /** Fetch a Switch Auto Configuration Job by its UUID. */
  autoSwitchConfigurationJob: SwitchAutoConfigurationJob;
  /** Fetch all Switch Auto Configuration Jobs for a network. */
  autoSwitchConfigurationJobsForNetwork?: Maybe<Array<SwitchAutoConfigurationJob>>;
  autoVPNGroup: AutoVpnGroup;
  autoVPNGroupsForCompany: Array<AutoVpnGroup>;
  autoVPNGroupsForNetwork: Array<AutoVpnGroup>;
  autoVPNMember: AutoVpnMember;
  /**
   * A CLI session creates a one time use bearer session that must be
   * verified before issuing a token. Once it is verified the session can
   * no longer be used.
   */
  bearerSession: BearerSession;
  /** Return all of the BSSIDs associated with hardware devices associated with virtual devices in a given network. */
  bssidsForNetwork: Array<Bssid>;
  bulkNOSUpgrade: BulkNosUpgrade;
  bulkNOSUpgrades: Array<BulkNosUpgrade>;
  captivePortal: CaptivePortal;
  /** Returns all clients that have seen or interacted with the captive portal in the last specified amount of hours. May contain duplicate clients. */
  captivePortalAllClients: Array<CaptivePortalClient>;
  /** Returns authorized clients to the captive portal in the last specified amount of hours. */
  captivePortalAuthorizedClients: Array<CaptivePortalClient>;
  captivePortalForNetwork?: Maybe<CaptivePortal>;
  captivePortalForVLAN?: Maybe<CaptivePortal>;
  captivePortalForWebhook?: Maybe<CaptivePortal>;
  /** @deprecated Use `captivePortalForNetwork` instead; there is only at-most-one per network. */
  captivePortalsForNetwork: Array<CaptivePortal>;
  /** Returns one timeseries per radio band for all of the radio bands broadcasted by the AP. */
  channelUtilizationByAP: Array<ChannelUtilizationResponseV2>;
  /** Returns a timeseries for channel utilization for the client, aggregated over the APs that the client has been connected to. */
  channelUtilizationByClient: Array<ClientChannelUtilizationTimeseriesValue>;
  /** Returns a time series for each radio band for the APs in the network. */
  channelUtilizationByNetwork: Array<ChannelUtilizationResponseV2>;
  /** @deprecated Prefer wirelessClientMetricsByX queries instead. */
  clientMetrics: ClientMetricsResponse;
  clientVPNClient: ClientVpnClient;
  clientVPNClients: Array<ClientVpnClient>;
  clientVPNServer: ClientVpnServer;
  clientVPNServers: Array<ClientVpnServer>;
  /** Get a company user by UUID. */
  companyUser: CompanyUser;
  /** List all company users for the specified company. */
  companyUsers: Array<CompanyUser>;
  /** Get recent console login challenges for a device. */
  consoleLogins: Array<ConsoleLogin>;
  /** Timeseries data of DHCP server uptime metrics for a single controller. */
  controllerDHCPUptime: ControllerDhcpUptimeResponse;
  /** Timeseries data of requests/sec per DNS server for a single controller. */
  controllerDNSRequestRates: ControllerDnsRequestRatesResponse;
  /** Per port timeseries metrics data for a single controller. */
  controllerPortMetrics: ControllerPortMetricsResponse;
  /** Per port timeseries metrics data as a rate per second for a single controller. */
  controllerPortMetricsRate: ControllerPortMetricsRateResponse;
  /** Per port stats for a specified controller over a lookback period. Encompasses receive/transmission data counters and hardware operational status. */
  controllerPortStats: Array<ControllerPortStat>;
  /** @deprecated Use `hardwareDevice` query instead, accessing `virtualDevice` within the hardware device if necessary. */
  device: Device;
  deviceCellularStatus?: Maybe<CellularStatus>;
  /** Fetch a specific config for a device by version. */
  deviceConfigByVersion: DeviceConfig;
  /** Fetch the config override for a device; should rarely be used if ever in production. */
  deviceConfigOverride: DeviceConfigOverride;
  /** Fetch all configs for a device over time ordered by version. */
  deviceConfigs?: Maybe<Array<DeviceConfig>>;
  deviceHeartbeatForDevice: DeviceHeartbeat;
  /** Retrieves the cellular usage of a controller aggregated in the last day. This is its own resolver so that it can be used for both config 1 and config 2. */
  deviceLastDayCellularUsage?: Maybe<CellularUsage>;
  /** Config 1 */
  deviceUptime: Scalars['Duration']['output'];
  /** @deprecated Use `virtualDevicesForNetwork` instead, accessing `hardwareDevice` within the virtual devices if necessary. */
  devicesForNetwork: Array<Device>;
  /** Get a DHCP option. */
  dhcpOption: DhcpOption;
  /** Get a DHCP reserved range. */
  dhcpReservedRange: DhcpReservedRange;
  /** Get a DHCP rule. */
  dhcpRule: DhcpRule;
  /** Get all DHCP rules for a Network. */
  dhcpRulesForNetwork: Array<DhcpRule>;
  /** Get a DHCP static mapping. */
  dhcpStaticMapping: DhcpStaticMapping;
  /** Returns list of discovered services on the network in the last specified amount of minutes. */
  discoveredServicesForNetwork: Array<DiscoveredService>;
  /** Get a DNS host mapping. */
  dnsHostMapping: DnsHostMapping;
  /** Generates a pre-signed URL for downloading a file from S3. */
  downloadUrl: FileDownloadPayload;
  encryption8021XsForNetwork: Array<Encryption8021X>;
  /** Return the Switch virtual device / phy interface that the given MAC address is associated with using LLDP stats. */
  findSwitchLLDPEntryForMAC?: Maybe<MacAddressSwitchEntry>;
  /** Return the Switch virtual device / phy interface that the given MAC address is associated with. */
  findSwitchesForClientMAC: Array<MacAddressSwitchEntry>;
  /** Get a firewall rule by UUID. */
  firewallRule: FirewallRule;
  /** Get all firewall rules for a network. */
  firewallRulesForNetwork: Array<FirewallRule>;
  /** Get a floor plan by UUID. */
  floorPlan: FloorPlan;
  /** List all floor plans for a network. */
  floorPlansForNetwork: Array<FloorPlan>;
  getCompany?: Maybe<Company>;
  /** Return a hardware device by its serial number. */
  hardwareDevice: HardwareDevice;
  /** Return a hardware device by its MAC address. */
  hardwareDeviceByMACAddress: HardwareDevice;
  /** Return a list of hardware devices that match the filter. */
  hardwareDevices: Array<HardwareDevice>;
  /** View host monitoring metrics for a network. */
  hostMonitoringForNetwork: Array<HostMonitoringResponse>;
  /** Get a host monitoring target. */
  hostMonitoringTarget: HostMonitoringTarget;
  /** List all hosts that are monitored for a network. */
  hostMonitoringTargetsForNetwork: Array<HostMonitoringTarget>;
  /** Get the valid types for Hotspot 2.0 (Passpoint) configuration. */
  hs20ValidTypes: Hs20ValidTypesResponse;
  /** @deprecated Incidents are no longer supported in Config 2. */
  incident: Incident;
  /** List all links between VLANs that are allowed to communicate with each other. */
  interVLANCommunicationPermittedPairs: Array<InterVlanCommunicationPermittedPair>;
  /** Get an internal note by UUID. */
  internalNote: InternalNote;
  /** List all internal notes for a network. */
  internalNotesForNetwork: Array<InternalNote>;
  /** Internet service plan for a given UUID. */
  internetServicePlan?: Maybe<InternetServicePlan>;
  /** Retrieves all providers applicable to the given company. At the moment, this returns ALL known providers. */
  internetServicePlanProviders: Array<InternetServicePlanProvider>;
  /** Internet service plans for a given company, and optionally scoped by a network. */
  internetServicePlans: Array<InternetServicePlan>;
  /** Get an IPSec tunnel by UUID. */
  ipSecTunnel: IpSecTunnel;
  /** List all IPSec tunnels for a network. */
  ipSecTunnelsForNetwork: Array<IpSecTunnel>;
  /**
   * In dev, this always returns true if the key is not recognized on the backend.
   * This checks if *any* network for companySlug supports nosFeatureKey.
   */
  isNosFeatureEnabledForCompany: Scalars['Boolean']['output'];
  /** In dev, this always returns true if the key is not recognized on the backend. */
  isNosFeatureEnabledForNetwork: Scalars['Boolean']['output'];
  jobTrackers: Array<NetworkOnboardingJobData>;
  /** Fetch the latest config that a device has acknowledged. */
  latestAcknowledgedDeviceConfig: DeviceConfig;
  /** Config 1 */
  latestDeviceCPULoad: LatestDeviceCpuLoad;
  /**
   * Fetches the latest config rendered for a device. Does not re-render the config;
   * use the `renderDeviceConfig` mutation for that.
   */
  latestDeviceConfig: DeviceConfig;
  /** Config 1 */
  latestDeviceMemoryUsage: LatestDeviceMemoryUsage;
  /** Returns all of the Companies, excluding Companies that only have Connect quotes. */
  listCompanies: Array<Company>;
  /** Get the maintenance window for a network (only one). */
  maintenanceWindowForNetwork: MaintenanceWindow;
  /** See the list of mutations that a network has undergone. */
  mutationAuditLogEntriesForNetwork: Array<MutationAuditLogEntry>;
  /** Get a network. */
  network: Network;
  /** Get a network by its slug. */
  networkBySlug: Network;
  /**
   * Get the latest connected clients for a network.
   * The values in filter can alter this behavior.
   */
  networkClients: Array<NetworkClient>;
  /** Get a report of the total number of bytes transmitted and received by each client connected to a network, on a per day basis. */
  networkClientsTxRxPerDay: Array<NetworkClientTxRxPerDay>;
  /**
   * Returns the network routes of a virtual device for the most recently ack'd config.
   * This reads from the rendered config so includes all implied routes from other features.
   */
  networkRoutesForVirtualDevice: Array<NetworkRoute>;
  /**
   * Get metrics about network's uplink's quality (i.e. the ISP quality).
   * Quality is the percentage of successful pings to common web servers over the ISP connection.
   */
  networkUplinkQuality: NetworkUplinkQualityResponse;
  /**
   * Get metrics about network's uplink's throughput (i.e. the ISP throughput).
   * Throughput is the amount of data transferred over the ISP connection.
   */
  networkUplinkThroughput: NetworkUplinkThroughputMetricsResponse;
  /** Get all networks that match the given filter. */
  networks: Array<Network>;
  /** Get all of a company's networks. */
  networksForCompany: Array<Network>;
  /** Get uplink quality metrics for multiple networks. */
  networksUplinkQualities: Array<NetworkUplinkQualityResponse>;
  /** Always returns true and does nothing. */
  noop: Scalars['Boolean']['output'];
  /** Get a NOS feature by UUID. */
  nosFeature: NosFeature;
  /** List all NOS features. */
  nosFeatures: Array<NosFeature>;
  /** Retrieves all NOS versions that were scheduled for a virtual device. */
  nosHistoryForVirtualDevice?: Maybe<Array<VirtualDeviceNosHistory>>;
  /** List all NOS versions with optional filters. */
  nosVersions: Array<NosVersion>;
  /** @deprecated No longer supported. */
  notificationPreference: NotificationPreferences;
  /** @deprecated No longer supported. */
  notificationPreferences: NotificationPreferencesResponse;
  /** Get a notification setting by UUID. */
  notificationSetting: NotificationSetting;
  /** List all notification settings for a network. */
  notificationSettingsForNetwork: Array<NotificationSetting>;
  /** Get a 1:1 NAT rule by UUID. */
  oneToOneNATRule: OneToOneNatRule;
  /** List all 1:1 NAT rules for a network. */
  oneToOneNATRulesForNetwork: Array<OneToOneNatRule>;
  /** Get all outlet interfaces for a Power Distribution Unit (PDU) virtual device. */
  outletInterfacesForVirtualDevice: Array<OutletInterface>;
  /** Retrieves the permissions that are assigned to the current user, in context of the provided params. */
  permissions: Array<PermissionAssignment>;
  /** Get the phy interfaces associated with a virtual device. */
  phyInterfacesForVirtualDevice: Array<PhyInterface>;
  policyRoutingRule: PolicyRoutingRule;
  policyRoutingRulesForNetwork: Array<PolicyRoutingRule>;
  /** Get a port forwarding rule by UUID. */
  portForwardingRule: PortForwardingRule;
  /** Get all port forwarding rules for a network. */
  portForwardingRulesForNetwork: Array<PortForwardingRule>;
  /** Get a rack elevation by UUID. */
  rackElevation: RackElevation;
  /** Get all rack elevations for a network. */
  rackElevations: Array<RackElevation>;
  /** Get the radio profiles for a network. */
  radioProfilesForNetwork: Array<RadioProfile>;
  /** Get a rate limit rule by UUID. */
  rateLimitRule: RateLimitRule;
  /** Get all rate limit rules for a network. */
  rateLimitRulesForNetwork: Array<RateLimitRule>;
  /** Events in the event log represent notable state changes in the state of the network. */
  recentEventLogEvents?: Maybe<Array<EventLogEvent>>;
  /** Retrieves the roles that are assigned to the current user, in context of the provided params. */
  roles: Array<RoleAssignment>;
  /** Return the hardware devices that are not associated with a virtual device; they're not in use but affiliated with the network for potential future use. */
  spareHardwareDevicesForNetwork: Array<HardwareDevice>;
  /** Get all speedtest servers for a network. */
  speedtestServersForNetwork: Array<SpeedtestServer>;
  /** List all SSIDs for a network. */
  ssidsForNetwork: Array<Ssid>;
  /**
   * Retrieves the options that can be configured for SSO, focusing on WorkOS. Fields include:
   * 1. Available groups
   * 2. Connected directories
   */
  ssoOptions: SsoOptions;
  /** Get a static route by UUID. */
  staticRoute: StaticRoute;
  /** List all static routes for a network. */
  staticRoutesForNetwork: Array<StaticRoute>;
  /**
   * Fetch stats (device-generated metrics) for a virtual device.
   * @deprecated Use StatsForVirtualDevices instead.
   */
  statsForVirtualDevice: Array<Stat>;
  /** Fetch stats (device-generated metrics) for multiple virtual devices. */
  statsForVirtualDevices: Array<Stat>;
  /** Fetch all MAC address or OUI to VLAN bindings for a network. */
  switchAutoConfigMACAddressVLANBindingsForNetwork: Array<SwitchAutoConfigMacAddressVlanBinding>;
  /** Return discard rates for a switch's ports. Discard rates are the number of packets that were dropped. */
  switchDiscardRatesPerPort: SwitchMetricsResponse;
  /** Return the error rates per port for a specified switch. */
  switchErrorRatesPerPort: SwitchMetricsResponse;
  /** Return the MAC table (all MAC addresses seen on its ports) for a specified switch. */
  switchMACTable: Array<SwitchMacTableEntry>;
  /**
   * Per port timeseries metrics data for a single switch.
   * @deprecated Use SwitchPortMetricsRate instead.
   */
  switchPortMetrics: SwitchPortMetricsResponse;
  /** Per port timeseries metrics data as a rate per second for a single switch. */
  switchPortMetricsRate: SwitchPortMetricsRateResponse;
  /** Per port stats for a specified switch over a lookback period */
  switchPortStats: Array<SwitchPortStat>;
  /** Return the throughput per port for a specified switch. Throughput is measured in bytes per second. */
  switchThroughputPerPort: SwitchMetricsResponse;
  /** Fetch system performance stats for a virtual device (as opposed to network-related data plan stats). */
  systemInfoStatForVirtualDevice: Array<SystemInfoStat>;
  /** A list of hardware devices that are detected as connected to virtual devices in the network without an associated virtual device of their own. */
  unassignedHardwareDevicesForNetwork: Array<UnassignedHardwareDeviceForNetworkResponse>;
  /** Returns network uplink (WAN) physical interfaces for the network (controllers interfaces only). */
  uplinkPhyInterfacesForNetwork: Array<PhyInterface>;
  /** Checks if the calling user has access to a network. Responds with the expiration of the access if found. */
  userNetworkAccessExpiry?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Return a list of valid channels for a radio, by band and network.
   * The list of valid channels is based on the regulatory domain of the network.
   */
  validChannels: Array<ValidChannelsResponse>;
  /** Return a virtual device by its UUID. */
  virtualDevice: VirtualDevice;
  /** Return a list of virtual devices for a network that match the given filter. */
  virtualDevicesForNetwork: Array<VirtualDevice>;
  /** Get a VLAN by UUID. */
  vlan: Vlan;
  /** Get all VLANs for a network. */
  vlans: Array<Vlan>;
  /** Retrieve the results or check the status of a WAN speedtest. */
  wanSpeedtestJob: WanSpeedtestJob;
  /** Retrieve the results for all WAN speedtest on a network. */
  wanSpeedtestJobsForNetwork: Array<WanSpeedtestJob>;
  /** Get all Wifi Observer Settings for a virtual device. */
  wifiObserverSettings?: Maybe<Array<Maybe<WifiObserverSetting>>>;
  /** A list of events that tell us when a client connected to an AP, disassociated from an AP, or failed to connect to an AP, for all clients interacting with an AP. */
  wirelessClientConnectionEventsByAP: Array<WirelessClientConnectionEvent>;
  /** A list of events that tell us when a client connected to an AP, disassociated from an AP, or failed to connect to an AP, for a single client. */
  wirelessClientConnectionEventsByClient: Array<WirelessClientConnectionEvent>;
  /**
   * Timeseries data of wireless connectivity metrics for all clients connected to an AP.
   * Returns a single aggregated timeseries, rather than one per client.
   */
  wirelessClientMetricsByAP: ClientMetricsTimeseriesResponse;
  /** Timeseries data of wireless connectivity metrics for a single client. */
  wirelessClientMetricsByClient: ClientMetricsTimeseriesResponse;
};

export type QueryActiveClientsArgs = {
  filter: MetricsFilterInput;
  input?: InputMaybe<ActiveClientsInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryActiveNetworksByCompanySupportTierArgs = {
  supportTier: CompanySupportTier;
};

export type QueryAlertReceiverArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAlertReceiversForCompanyArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetsArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetsWebhookArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryApChannelUtilizationArgs = {
  band?: InputMaybe<RadioBand>;
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryApUptimeArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryApplicationDnsFirewallRuleApplicationForHostnameArgs = {
  hostname: Scalars['String']['input'];
};

export type QueryApplicationDnsFirewallRuleCategoryForHostnameArgs = {
  hostname: Scalars['String']['input'];
};

export type QueryApplicationDnsFirewallRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAreNosFeaturesEnabledForCompanyArgs = {
  companySlug: Scalars['String']['input'];
  nosFeatureKeys: Array<Scalars['String']['input']>;
};

export type QueryAreNosFeaturesEnabledForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  nosFeatureKeys: Array<Scalars['String']['input']>;
};

export type QueryAuthenticationTokenArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAutoChannelSelectionJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type QueryAutoChannelSelectionJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoSwitchConfigurationJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type QueryAutoSwitchConfigurationJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnGroupsForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryAutoVpnGroupsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnMemberArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBearerSessionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBssidsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBulkNosUpgradesArgs = {
  filter: BulkNosUpgradesFilterInput;
};

export type QueryCaptivePortalArgs = {
  input?: InputMaybe<GetCaptivePortalInput>;
  uuid: Scalars['UUID']['input'];
};

export type QueryCaptivePortalAllClientsArgs = {
  networkUUID: Scalars['UUID']['input'];
  numHoursLookback: Scalars['Int']['input'];
};

export type QueryCaptivePortalAuthorizedClientsArgs = {
  networkUUID: Scalars['UUID']['input'];
  numHoursLookback: Scalars['Int']['input'];
};

export type QueryCaptivePortalForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryCaptivePortalForVlanArgs = {
  vlanUUID: Scalars['UUID']['input'];
};

export type QueryCaptivePortalForWebhookArgs = {
  webhookKey: Scalars['UUID']['input'];
};

export type QueryCaptivePortalsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ChannelUtilizationTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByClientArgs = {
  filter: ChannelUtilizationTimeseriesFilterInput;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByNetworkArgs = {
  filter: ChannelUtilizationTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryClientMetricsArgs = {
  filter: MetricsFilterInput;
  serialNumber: Scalars['String']['input'];
};

export type QueryClientVpnClientArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryClientVpnClientsArgs = {
  serverUUID: Scalars['UUID']['input'];
};

export type QueryClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryClientVpnServersArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input?: InputMaybe<GetCompanyUserInput>;
};

export type QueryCompanyUsersArgs = {
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<GetCompanyUserInput>;
};

export type QueryConsoleLoginsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  serialNumber: Scalars['String']['input'];
};

export type QueryControllerDhcpUptimeArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerDnsRequestRatesArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortMetricsArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortMetricsRateArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortStatsArgs = {
  lookbackHours?: InputMaybe<Scalars['Int']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceCellularStatusArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceConfigByVersionArgs = {
  serialNumber: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type QueryDeviceConfigOverrideArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceConfigsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceHeartbeatForDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceLastDayCellularUsageArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceUptimeArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDevicesForNetworkArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDiscoveredServicesForNetworkArgs = {
  input: DiscoveredServicesInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDownloadUrlArgs = {
  input: DownloadUrlInput;
};

export type QueryEncryption8021XsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFindSwitchLldpEntryForMacArgs = {
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFindSwitchesForClientMacArgs = {
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryFirewallRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryFloorPlansForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryGetCompanyArgs = {
  slug: Scalars['String']['input'];
};

export type QueryHardwareDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryHardwareDeviceByMacAddressArgs = {
  macAddress: Scalars['MacAddress']['input'];
};

export type QueryHardwareDevicesArgs = {
  filter?: InputMaybe<HardwareDevicesFilter>;
};

export type QueryHostMonitoringForNetworkArgs = {
  filter: MetricsFilterInput;
  hostIPs: Array<Scalars['String']['input']>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryHostMonitoringTargetsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryIncidentArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryInterVlanCommunicationPermittedPairsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryInternalNotesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryInternetServicePlanArgs = {
  uuid: Scalars['UUID']['input'];
};

export type QueryInternetServicePlanProvidersArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryInternetServicePlansArgs = {
  companySlug: Scalars['String']['input'];
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryIpSecTunnelsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryIsNosFeatureEnabledForCompanyArgs = {
  companySlug: Scalars['String']['input'];
  nosFeatureKey: Scalars['String']['input'];
};

export type QueryIsNosFeatureEnabledForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  nosFeatureKey: Scalars['String']['input'];
};

export type QueryLatestAcknowledgedDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceCpuLoadArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceMemoryUsageArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryListCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMaintenanceWindowForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryMutationAuditLogEntriesForNetworkArgs = {
  filter?: InputMaybe<MutationAuditLogFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNetworkBySlugArgs = {
  companySlug: Scalars['String']['input'];
  networkSlug: Scalars['String']['input'];
};

export type QueryNetworkClientsArgs = {
  filter?: InputMaybe<NetworkClientsFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkClientsTxRxPerDayArgs = {
  networkUUID: Scalars['UUID']['input'];
  numDaysLookback: Scalars['Int']['input'];
};

export type QueryNetworkRoutesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryNetworkUplinkQualityArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkUplinkThroughputArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworksArgs = {
  filter: NetworksFilter;
};

export type QueryNetworksForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryNetworksUplinkQualitiesArgs = {
  filter: MetricsFilterInput;
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type QueryNosFeatureArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNosHistoryForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryNosVersionsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isGA?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNotificationPreferenceArgs = {
  uuid: Scalars['UUID']['input'];
};

export type QueryNotificationPreferencesArgs = {
  input: GetNotificationPreferencesInput;
};

export type QueryNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNotificationSettingsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryOneToOneNatRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryOutletInterfacesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryPermissionsArgs = {
  input: PermissionsInput;
};

export type QueryPhyInterfacesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryPolicyRoutingRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryPortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryPortForwardingRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryRackElevationsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRadioProfilesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryRateLimitRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRecentEventLogEventsArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: Scalars['Int']['input'];
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryRolesArgs = {
  input?: InputMaybe<RolesInput>;
};

export type QuerySpareHardwareDevicesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySpeedtestServersForNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QuerySsidsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySsoOptionsArgs = {
  input: SsoOptionsInput;
};

export type QueryStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryStaticRoutesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryStatsForVirtualDeviceArgs = {
  filter: StatsFilter;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryStatsForVirtualDevicesArgs = {
  filter: NetworkStatsFilter;
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySwitchAutoConfigMacAddressVlanBindingsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySwitchDiscardRatesPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySwitchErrorRatesPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySwitchMacTableArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortMetricsArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortMetricsRateArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortStatsArgs = {
  lookbackHours?: InputMaybe<Scalars['Int']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchThroughputPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySystemInfoStatForVirtualDeviceArgs = {
  filter: SystemInfoStatsFilter;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryUnassignedHardwareDevicesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryUplinkPhyInterfacesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryUserNetworkAccessExpiryArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryValidChannelsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryVirtualDeviceArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryVirtualDevicesForNetworkArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryVlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryVlansArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWanSpeedtestJobArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryWanSpeedtestJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWifiObserverSettingsArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryWirelessClientConnectionEventsByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWirelessClientConnectionEventsByClientArgs = {
  filter: ClientMetricsTimeseriesFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWirelessClientMetricsByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWirelessClientMetricsByClientArgs = {
  filter: ClientMetricsTimeseriesFilterInput;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type RpcCableTestResponse = {
  __typename?: 'RPCCableTestResponse';
  pairA: RpcCableTestTwistedPairResult;
  pairB: RpcCableTestTwistedPairResult;
  pairC: RpcCableTestTwistedPairResult;
  pairD: RpcCableTestTwistedPairResult;
};

export type RpcCableTestTwistedPairResult = {
  __typename?: 'RPCCableTestTwistedPairResult';
  estimatedLengthInMeters: Scalars['Int']['output'];
  status: RpcCableTestTwistedPairStatus;
};

/**
 * Cable test checks each pair of wires (Ethernet cables have 4 pairs) to find if they are crimped correctly.
 * The wires can be good, open (not connected), short or crosstalk (bad cabling).
 * Emplus's implementation can also estimate the length of the ethernet cable (in meters).
 * Cable test can only be run on rj45 ethernet ports and not on SFP ports.
 */
export enum RpcCableTestTwistedPairStatus {
  Crosstalk = 'CROSSTALK',
  Good = 'GOOD',
  Open = 'OPEN',
  OpenShort = 'OPEN_SHORT',
  Short = 'SHORT',
  Unknown = 'UNKNOWN',
}

export type RpcEapolTestForSerialInput = {
  /** The UUID of the RADIUS server profile (either primary or secondary encryption 8021x) to use for the EAPOL test. */
  Encryption8021XUUID: Scalars['UUID']['input'];
  /** The password to use for the EAPOL test. */
  password: Scalars['String']['input'];
  /** The serial number of the device to run the EAPOL test on. */
  serialNumber: Scalars['String']['input'];
  /**
   * The UUID of the SSID to use for the EAPOL test. An Eapol test without ssid will omit sending this field to the auth
   * server.
   */
  ssidUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** The username to use for the EAPOL test. */
  user: Scalars['String']['input'];
};

export type RpcEapolTestForSerialResponse = {
  __typename?: 'RPCEapolTestForSerialResponse';
  /** A human-readable error message if the EAPOL test failed with an unknown error. */
  error_reason?: Maybe<Scalars['String']['output']>;
  /** The serial number this tests was run for. */
  serialNumber: Scalars['String']['output'];
  /** The status of EAPOL server response. */
  status: RpcEapolTestForSerialStatus;
};

/**
 * EAPOL (Extensible Authentication Protocol over LAN) is a network authentication protocol used in IEEE 802.1X to
 * authenticate users and computers in a network through a RADIUS server. It is used in wireless networks (WPA-Enterprise)
 * to secure access. The EAPOL test RPC runs directly on a WOS access point and simulates a client connecting wirelessly to
 * an AP performing RADIUS authentication. A successful EAPOL test means that the AP can authenticate a client with the
 * RADIUS server using the provided credentials and validates that a client can connect to the network.
 */
export enum RpcEapolTestForSerialStatus {
  /** The EAPOL test failed due to bad user/password credentials. */
  FailureBadCredentials = 'FAILURE_BAD_CREDENTIALS',
  /** The EAPOL test failed due to an incorrect RADIUS secret. */
  FailureIncorrectRadiusSecret = 'FAILURE_INCORRECT_RADIUS_SECRET',
  /** The EAPOL test failed due to the server not responding in time. */
  FailureRadiusServerTimeout = 'FAILURE_RADIUS_SERVER_TIMEOUT',
  /** The EAPOL test failed due to an unknown error. See `error_reason` for more details. */
  FailureUnknown = 'FAILURE_UNKNOWN',
  /** The EAPOL test was successful. */
  Success = 'SUCCESS',
}

export type RpcEapolTestForSerialsInput = {
  /** The UUID of the RADIUS server profile (either primary or secondary encryption 8021x) to use for the EAPOL test. */
  Encryption8021XUUID: Scalars['UUID']['input'];
  /** The password to use for the EAPOL test. */
  password: Scalars['String']['input'];
  /** The serial numbers of the devices to run the EAPOL test on. */
  serialNumbers: Array<Scalars['String']['input']>;
  /**
   * The UUID of the SSID to use for the EAPOL test. An Eapol test without ssid will omit sending this field to the auth
   * server.
   */
  ssidUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** The username to use for the EAPOL test. */
  user: Scalars['String']['input'];
};

/** A request to power-cycle an outlet on a PDU. */
export type RpcpduOutletCycleInput = {
  /** A list of outlet UUIDs to cycle */
  outletUUIDs: Array<Scalars['UUID']['input']>;
};

/** A request to ping to a device. */
export type RpcPingInput = {
  /** The number of pings to the devices sends at once */
  burstSize?: Scalars['Int']['input'];
  /** The host IP address to ping out to. */
  host: Scalars['String']['input'];
  /** The interval between burst's of pings. Defaults to 1sec */
  intervalSeconds?: Scalars['Int']['input'];
  /** The number of bursts to send */
  numberOfBursts?: Scalars['Int']['input'];
  /** The UUID of the physical interface if user selects ping out of a wan port. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The device issuing the ping. */
  serialNumber: Scalars['String']['input'];
  /** Max seconds, before ping exits regardless of how many packets have been sent or received */
  timeoutSeconds?: Scalars['Int']['input'];
  /** The UUID of the vlan if user selects ping out of a vlan interface (for both client ping and wan ping). */
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The WAN interface to use for the ping (`wan0` or `wan1`). Defaults to `wan0`. Config-1 only */
  wanInterface?: InputMaybe<Scalars['String']['input']>;
};

export type RpcPingResponse = {
  __typename?: 'RPCPingResponse';
  /** The mean round trip time in milliseconds, if any were successful. */
  roundTripTimeMs?: Maybe<Scalars['Int']['output']>;
  /** The number of successful pings divided by the total number of pings, if any were issued. */
  successRate: Scalars['Float']['output'];
};

/** A request to power-cycle a port on a Meter switch. */
export type RpcSwitchPortCycleInput = {
  /** The number of seconds between the down and up of the cycle (range 1-30s). */
  cycleDownSec?: InputMaybe<Scalars['Int']['input']>;
  /** Enable or disable PoE on the port. */
  poeCycle?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable the port. */
  portCycle?: InputMaybe<Scalars['Boolean']['input']>;
  /** The port number to cycle. */
  portNumber: Scalars['Int']['input'];
  /** The serial number of the switch whose port needs cycling. */
  serialNumber: Scalars['String']['input'];
};

/** A request to run a WAN speedtest on a Meter device. */
export type RpcwanSpeedtestInput = {
  /** Config 2 only. If omitted, will select currently active WAN. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The device to run the speedtest on. */
  serialNumber: Scalars['String']['input'];
  /** Config 2 only. Retrieved with a call to speedtestServersForNetwork. If omitted, controller will auto-select a server. */
  serverID?: InputMaybe<Scalars['String']['input']>;
  wan?: InputMaybe<Wan>;
};

export type RpcwanSpeedtestResponse = {
  __typename?: 'RPCWANSpeedtestResponse';
  /** Config 2 only. Distance to target server */
  distance?: Maybe<Scalars['Float']['output']>;
  downloadSpeedMbps: Scalars['Float']['output'];
  /** Config 2 only */
  jitterNs?: Maybe<Scalars['Int']['output']>;
  /** Config 2 only */
  latencyNs?: Maybe<Scalars['Int']['output']>;
  /** Config 2 only. Target server host test was run against */
  targetHost?: Maybe<Scalars['String']['output']>;
  /** Config 2 only. Target server name test was run against */
  targetName?: Maybe<Scalars['String']['output']>;
  uploadSpeedMbps: Scalars['Float']['output'];
};

export type RpcWosObserverCommandResults = {
  __typename?: 'RPCWosObserverCommandResults';
  /** Delay statistics: average, min, max, and jitter, in milliseconds. */
  avgDurationMsec: Scalars['Int']['output'];
  avgNumBytes: Scalars['Int']['output'];
  /** Index of the test, matches the request. */
  index: Scalars['Int']['output'];
  jitterMsec: Scalars['Int']['output'];
  maxDurationMsec: Scalars['Int']['output'];
  minDurationMsec: Scalars['Int']['output'];
  numAttempts: Scalars['Int']['output'];
  successRatio: Scalars['Float']['output'];
};

/** A request to run Observer tests on a Meter observer. */
export type RpcWosObserverInput = {
  /**
   * The UUID of the SSID to use for the Observer test. If empty with triggerTest true,
   * will simply cancel the previous test.
   */
  SSIDUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Band on which the test should be run. */
  band?: InputMaybe<RadioBand>;
  /** List of commands to run. */
  commands?: InputMaybe<Array<WosObserverCommandInput>>;
  debugMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Duration of the test, in msec. */
  durationMsec?: InputMaybe<Scalars['Int']['input']>;
  /** If this request is to get results from an Observer test. */
  getTestResults?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hostname to use with DHCP; if unset, firmware generates it */
  hostname?: InputMaybe<Scalars['String']['input']>;
  /** If set, number of spatial streams will be limited */
  numSpatialStreams?: InputMaybe<Scalars['Int']['input']>;
  /** 8021X password. */
  password8021X?: InputMaybe<Scalars['String']['input']>;
  /** The serial number of the device to run the Observer test on. */
  serialNumber: Scalars['String']['input'];
  /** The serial number of the device the Observer will target or get results for. */
  targetSerialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Timeout for the connection attempt, in msec. */
  timeoutMsec?: InputMaybe<Scalars['Int']['input']>;
  /** When WPA3 transition is enabled, use WPA2 if true (use WPA3 otherwise). */
  transitionUseWpa2?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this request is to trigger an Observer test. */
  triggerTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Use protected management frames (11w). With WPA3, 11w is used anyway. */
  use11w?: InputMaybe<Scalars['Boolean']['input']>;
  /** If scanning radio should be used for this test */
  useScanningRadio?: InputMaybe<Scalars['Boolean']['input']>;
  /** 8021X username. */
  username8021X?: InputMaybe<Scalars['String']['input']>;
};

export type RpcWosObserverResponse = {
  __typename?: 'RPCWosObserverResponse';
  /** A reason for a failure. */
  failureReason?: Maybe<Scalars['String']['output']>;
  /** Results for the tests. */
  testResults?: Maybe<Array<RpcWosObserverTestResults>>;
  /** The status of a trigger request. */
  triggerStatus: RpcWosObserverResponseStatus;
};

export enum RpcWosObserverResponseStatus {
  TriggerFail = 'TRIGGER_FAIL',
  TriggerOk = 'TRIGGER_OK',
  Unspecified = 'UNSPECIFIED',
}

export type RpcWosObserverTestResults = {
  __typename?: 'RPCWosObserverTestResults';
  /** Duration of DHCP, in msec. */
  DHCPDurationMsec: Scalars['Int']['output'];
  /** Duration of the assoc, in msec. */
  assocDurationMsec: Scalars['Int']['output'];
  /** Duration of the auth, in msec. */
  authDurationMsec: Scalars['Int']['output'];
  /** Band on which the test has run. */
  band: RadioBand;
  /** Results of all commands. */
  commandResults?: Maybe<Array<RpcWosObserverCommandResults>>;
  /** Duration of the scan, in msec. */
  scanDurationMsec: Scalars['Int']['output'];
  /** The UUID of the SSID used for the Observer test. */
  ssidUuid?: Maybe<Scalars['UUID']['output']>;
  /** Start time of the test. */
  startedAt: Scalars['DateTime']['output'];
  /** The serial number of the device the Observer we get results for. */
  targetSerialNumber: Scalars['String']['output'];
};

export type RpcWosPacketCaptureResponse = {
  __typename?: 'RPCWosPacketCaptureResponse';
  /** The job ID of the packet capture. */
  jobId: Scalars['String']['output'];
  /** Status of the packet capture request. */
  status: RpcWosPacketCaptureResponseStatus;
};

export enum RpcWosPacketCaptureResponseStatus {
  Fail = 'FAIL',
  Ok = 'OK',
  Unspecified = 'UNSPECIFIED',
}

/** Run a packet capture on a device. */
export type RpcWosPacketCaptureStartInput = {
  /**
   * The filter ID to be applied to the packet capture.
   * Not yet implemented in firmware.
   */
  filterID: Scalars['Int']['input'];
  /** The job ID of the packet capture. */
  jobId: Scalars['String']['input'];
  /** The monitor interfaces that we want to conduct the packet capture on. */
  radios: WosPacketCaptureCommandMonitor;
  /** The presigned public S3 url where the packet capture file needs to be uploaded. */
  resultUploadURL: Scalars['String']['input'];
  /** Configurable size limit of the packet capture. */
  sizeLimitMb: Scalars['Int']['input'];
  /** Configurable time limit of the packet capture. */
  timeLimitSec: Scalars['Int']['input'];
};

export type RpcWosPacketCaptureStopInput = {
  /** The job ID of the packet capture. */
  jobId: Scalars['String']['input'];
};

/** A rack elevation is a 2D representation of racks of hardware in a datacenter or supply closet. */
export type RackElevation = {
  __typename?: 'RackElevation';
  UUID: Scalars['UUID']['output'];
  devices: Array<RackElevationDevice>;
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: Maybe<Scalars['Int']['output']>;
  /** Human-readable name for the rack elevation. */
  label: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  notes?: Maybe<Array<RackElevationNote>>;
  /** The number of rack units (like slots) for the rack elevation. */
  rackMountUnitCount: Scalars['Int']['output'];
};

export type RackElevationDevice = {
  __typename?: 'RackElevationDevice';
  UUID: Scalars['UUID']['output'];
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing: Scalars['Boolean']['output'];
  /** If the device is a switch, whether it supports PoE. */
  isPoESupported?: Maybe<Scalars['Boolean']['output']>;
  /** A friendly name for display purposes. */
  label?: Maybe<Scalars['String']['output']>;
  /** The hardware device's model. */
  model?: Maybe<Scalars['String']['output']>;
  /** For additional context. */
  notes?: Maybe<Scalars['String']['output']>;
  /** The number of ports on the device. */
  portCount?: Maybe<Scalars['Int']['output']>;
  rackElevationUUID: Scalars['UUID']['output'];
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes: Array<Scalars['Int']['output']>;
  type: RackElevationDeviceType;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

export enum RackElevationDeviceType {
  CableManagement = 'CABLE_MANAGEMENT',
  ExistingEquipment = 'EXISTING_EQUIPMENT',
  Fiber = 'FIBER',
  Isp = 'ISP',
  MeterAccessPoint = 'METER_ACCESS_POINT',
  MeterController = 'METER_CONTROLLER',
  MeterSwitch = 'METER_SWITCH',
  NonMeterSwitch = 'NON_METER_SWITCH',
  Other = 'OTHER',
  PatchBox = 'PATCH_BOX',
  PatchPanel = 'PATCH_PANEL',
  UniversalPowerSupply = 'UNIVERSAL_POWER_SUPPLY',
  Unknown = 'UNKNOWN',
}

export type RackElevationNote = {
  __typename?: 'RackElevationNote';
  note: Scalars['String']['output'];
  rackMountUnitIndexEnd: Scalars['Int']['output'];
  rackMountUnitIndexStart: Scalars['Int']['output'];
};

export type RackElevationNoteInput = {
  note: Scalars['String']['input'];
  rackMountUnitIndexEnd: Scalars['Int']['input'];
  rackMountUnitIndexStart: Scalars['Int']['input'];
};

export enum RadioBand {
  Band_2_4G = 'BAND_2_4G',
  Band_5G = 'BAND_5G',
}

/** Contains configuration for wireless radios. A profile is a way to apply the same configuration to multiple APs. */
export type RadioProfile = {
  __typename?: 'RadioProfile';
  UUID: Scalars['UUID']['output'];
  /**
   * Only set if band2_4GAutoChannelIsEnabled is true.
   * This is the list of channels that are permitted for ACS (auto channel selection) to
   * select from and is user-configurable.
   */
  band2_4GAllowedChannels?: Maybe<Array<Scalars['Int']['output']>>;
  band2_4GAutoChannelIsEnabled: Scalars['Boolean']['output'];
  band2_4GAutoChannelWidthIsEnabled: Scalars['Boolean']['output'];
  band2_4GAutoTxPowerIsEnabled: Scalars['Boolean']['output'];
  /** Only set if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMaxdBm?: Maybe<Scalars['Int']['output']>;
  /** Only set if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMindBm?: Maybe<Scalars['Int']['output']>;
  /** Only set if band2_4GAutoChannelWidthIsEnabled is false. */
  band2_4GChannelWidthMHz?: Maybe<Scalars['Int']['output']>;
  band2_4GIsEnabled: Scalars['Boolean']['output'];
  /**
   * Only set if band5GAutoChannelIsEnabled is true.
   * This is the list of channels that are permitted for ACS (auto channel selection) to
   * select from and is user-configurable.
   */
  band5GAllowedChannels?: Maybe<Array<Scalars['Int']['output']>>;
  band5GAutoChannelIsEnabled: Scalars['Boolean']['output'];
  band5GAutoChannelWidthIsEnabled: Scalars['Boolean']['output'];
  band5GAutoTxPowerIsEnabled: Scalars['Boolean']['output'];
  /** Only set if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMaxdBm?: Maybe<Scalars['Int']['output']>;
  /** Only set if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMindBm?: Maybe<Scalars['Int']['output']>;
  /** Only set if band5GAutoChannelWidthIsEnabled is false. */
  band5GChannelWidthMHz?: Maybe<Scalars['Int']['output']>;
  band5GIsEnabled: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The ISO2 country code where the device is located. */
  regulatoryDomain: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  virtualDevices: Array<VirtualDevice>;
};

/** An individual Access Point's radio settings (channel, power). Other radio settings are configured in the radio profile and shared across multiple APs. */
export type RadioSettings = {
  __typename?: 'RadioSettings';
  band2_4GPrimaryChannel: Scalars['Int']['output'];
  band2_4GSecondaryChannel?: Maybe<Scalars['Int']['output']>;
  band2_4GTransmitPowerdBm: Scalars['Int']['output'];
  band5GPrimaryChannel: Scalars['Int']['output'];
  band5GSecondaryChannel?: Maybe<Scalars['Int']['output']>;
  band5GTransmitPowerdBm: Scalars['Int']['output'];
};

export type RateLimitPhyInterfaceBindingInput = {
  phyInterfaceUUID: Scalars['UUID']['input'];
  rateLimitRuleUUID: Scalars['UUID']['input'];
};

/** Defines L2 and L3 rate limit rules for a network. A rate limit rule can be applied to multiple interfaces. */
export type RateLimitRule = {
  __typename?: 'RateLimitRule';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dstMac?: Maybe<Scalars['MacAddress']['output']>;
  dstPortRange?: Maybe<PortRange>;
  dstPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  isPerSource: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** Creates a relationship between which physical interfaces a rate limit rule should be applied on. The same rate limit rule can be applied on any number of physical interfaces. */
  phyInterfaceBindings?: Maybe<Array<PhyInterface>>;
  /** The IP protocol to apply this rule on. These are the IANA defined protocol numbers. E.g. 1 = ICMP, 6 = TCP, 17 = UDP. A value of 256 is reserved by IANA and will be used to denote all protocols. */
  protocol?: Maybe<IpProtocol>;
  rateLimitKbpsDownload?: Maybe<Scalars['Int']['output']>;
  rateLimitKbpsUpload?: Maybe<Scalars['Int']['output']>;
  srcMac?: Maybe<Scalars['MacAddress']['output']>;
  srcPortRange?: Maybe<PortRange>;
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Creates a relationship between which vlan interfaces a rate limit rule should be applied on. The same rate limit rule can be applied on any number of VLANs. */
  vlanBindings?: Maybe<Array<Vlan>>;
};

export type RateLimitVlanBindingInput = {
  rateLimitRuleUUID: Scalars['UUID']['input'];
  vlanUUID: Scalars['UUID']['input'];
};

export enum Role {
  Operator = 'OPERATOR',
}

/**
 * A RoleAssignment denotes the role of a user in context of a company or company & network.
 *
 * In the case of an Operator role, the companySlug and networkUUID fields will be null.
 */
export type RoleAssignment = {
  __typename?: 'RoleAssignment';
  companySlug?: Maybe<Scalars['String']['output']>;
  name: RoleName;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
};

export type RoleCheckResult = {
  __typename?: 'RoleCheckResult';
  hasRole: Scalars['Boolean']['output'];
};

export enum RoleName {
  CompanyGlobalAdmin = 'COMPANY_GLOBAL_ADMIN',
  CompanyGuest = 'COMPANY_GUEST',
  CompanyNetworkAdmin = 'COMPANY_NETWORK_ADMIN',
  CompanyStandardUser = 'COMPANY_STANDARD_USER',
  Operator = 'OPERATOR',
}

/** RolesInput serves as the context for which to fetch the roles for a given user. */
export type RolesInput = {
  companySlug?: InputMaybe<Scalars['String']['input']>;
};

export type SfpModuleInfo = {
  __typename?: 'SFPModuleInfo';
  moduleSpeed: Scalars['String']['output'];
  moduleType: Scalars['String']['output'];
  partName: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
  vendor: Scalars['String']['output'];
};

/** Service set identifiers (SSIDs) for a network. */
export type Ssid = {
  __typename?: 'SSID';
  /** BSSIDs are MAC addresses that identify a radio band / SSID pair for an access point. */
  BSSIDs: Array<Bssid>;
  UUID: Scalars['UUID']['output'];
  /** The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second. */
  band2GMinimumBasicRateKbps?: Maybe<Scalars['Int']['output']>;
  /** The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second. */
  band5GMinimumBasicRateKbps?: Maybe<Scalars['Int']['output']>;
  /** Client steering allows devices to select an optimal access point for better connectivity and performance. */
  clientSteeringAssociationSteeringIsEnabled: Scalars['Boolean']['output'];
  /** The minimum number of clients on an SSID before client steering is attempted. */
  clientSteeringLoadBalancingClientMinThreshold?: Maybe<Scalars['Int']['output']>;
  /** Post association steering allows devices to move to a better access point after they have already connected. */
  clientSteeringPostassociationSteeringIsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** The minimum RSSI signal strength a client must have before pre-association client steering is attempted. */
  clientSteeringPreassociationRSSIMinThreshold?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** The DTIM (Delivery Traffic Indication Message) period is a value in beacon frames that indicates how often clients should wake up to receive multicast and broadcast traffic. */
  dtimPeriod?: Maybe<Scalars['Int']['output']>;
  /** Dynamic VLAN is a setting to use the VLAN specified by an external RADIUS server's response after authentication. If null, then the VLAN specified by `vlan` is used. */
  dynamicVLANMode?: Maybe<SsidDynamicVlanMode>;
  enabledAccessPointVirtualDevices?: Maybe<Array<AccessPointVirtualDevice>>;
  /**
   * 802.11w is a security standard that protects management frames from being forged by an attacker.
   * For WPA3 PSK, this field is required and must be REQUIRED.
   * For WPA3 Transition, this field is required but can be REQUIRED or OPTIONAL.
   * For WPA2, this field is optional.
   */
  encryption80211wProtectedManagementFramesMode?: Maybe<SsidEncryption80211wProtectedManagementFramesMode>;
  /** If null, this is an open network. */
  encryptionProtocol?: Maybe<SsidEncryptionProtocol>;
  /** An optional value for the Hotspot 2.0 Access network type, as defined in Table 9-214 of 802.11-2016. */
  hs20AccessNetworkType?: Maybe<Scalars['Int']['output']>;
  /** A list of Hotspot 2.0 domain names */
  hs20DomainNames?: Maybe<Array<Scalars['String']['output']>>;
  /** An option to enable/disable Hotspot 2.0, aka Passpoint */
  hs20Enabled: Scalars['Boolean']['output'];
  /** A boolean to represent whether a Hostpot 2.0 profile is attached to the SSID (has any fields set). */
  hs20HasProfile: Scalars['Boolean']['output'];
  /** A list of MCC/MNC (Mobile Country Codes and Mobile Network Codes) pairs, formatted as "MCC,MNC" */
  hs20MccMncPairs?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Hotspot 2.0 NAI (Network Access Identifier) realms; format follows hostapd format for nai_realm option (see hostapd documentation). */
  hs20NaiRealms?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Operator names for Hotspot 2.0 (one per language) */
  hs20OperatorNames?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Hotspot 2.0 Consortium OIs (Organization Identifiers). */
  hs20RoamingConsortiumOIs?: Maybe<Array<Scalars['String']['output']>>;
  /** An optional value for the Hotspot 2.0 Venue group, as defined in Table 9-62 of 802.11-2016. */
  hs20VenueGroup?: Maybe<Scalars['Int']['output']>;
  /**
   * A list of Venue names for Hotspot 2.0 (one per language); must be formatted with a two or three character language code separated by a colon from the venue name.
   * If at least one if set, hs20VenueGroup and hs20VenueType must be set
   */
  hs20VenueNames?: Maybe<Array<Scalars['String']['output']>>;
  /** An optional value for the Hotspot 2.0 Venue type, as defined in Table 9-62 of 802.11-2016. */
  hs20VenueType?: Maybe<Scalars['Int']['output']>;
  /** Whether or not the SSID is enabled for 802.11ax (Wi-Fi 6) clients. */
  is80211axEnabled: Scalars['Boolean']['output'];
  /** Whether or not AP debug logging is enabled for APs broadcasting the SSID. */
  isAPDebugLogEnabled: Scalars['Boolean']['output'];
  /** Whether or not the SSID is allowed to be broadcasted on the 2.4 GHz radio. */
  isBand2GAllowed: Scalars['Boolean']['output'];
  /** Whether or not the SSID is allowed to be broadcasted on the 5 GHz radio. */
  isBand5GAllowed: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** If true, the SSID is enabled for all access points in the network. If false, the SSID is enabled for only the access points specified in the enabledAccessPointVirtualDevices field. */
  isEnabledForAllAccessPoints: Scalars['Boolean']['output'];
  /** If true, the SSID is considered as guest and can be displayed on site for guest access, for example on a kiosk. */
  isGuest: Scalars['Boolean']['output'];
  /** Whether or not the broadcasted SSID is visible to clients; clients have to manually input the SSID to join the network. */
  isHidden: Scalars['Boolean']['output'];
  /** If true, clients on the same SSID cannot communicate with each other. */
  isIsolateClientsEnabled: Scalars['Boolean']['output'];
  /** True if multicast to unicast translation is enabled on the SSID. */
  isMulticastToUnicastEnabled: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The PSK for the SSID. If null, it is an open passwordless network or the encryption protocol does not use a PSK. */
  password?: Maybe<Scalars['String']['output']>;
  /** The last time the password was rotated, if password rotation is enabled. */
  passwordLastRotatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The next time the password will be rotated, if password rotation is enabled. */
  passwordNextRotationAt?: Maybe<Scalars['DateTime']['output']>;
  /** The period upon which the password is automatically rotated; a non-null value indicates that password rotation is enabled. */
  passwordRotationCadence?: Maybe<SsidPasswordRotationCadence>;
  /** The day of the month, in the network's local time zone, at which the password is automatically rotated. */
  passwordRotationDayOfMonthLocal?: Maybe<Scalars['Int']['output']>;
  /** The day of the week, in the network's local time zone, at which the password is automatically rotated (0 = Sunday, 1 = Monday, etc.) */
  passwordRotationDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  /** The hour of the day, in the network's local time zone, at which the password is automatically rotated. */
  passwordRotationHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  primaryEncryption8021X?: Maybe<Encryption8021X>;
  /** True if SSID supports Radius CoA (change of authorization) */
  radiusCoaEnabled: Scalars['Boolean']['output'];
  /** A port where Radius servers can send CoA requests */
  radiusCoaPort?: Maybe<Scalars['Int']['output']>;
  /** 802.11k is a standard that helps wireless devices quickly find and switch to the best available access point. */
  roaming80211kIsEnabled: Scalars['Boolean']['output'];
  /** 802.11r is a standard that allows clients to roam more effectively between access points. */
  roaming80211rIsEnabled: Scalars['Boolean']['output'];
  /**
   * Used to indicate a group of APs sharing the same SSID between which a client can use 802.11r to roam. A 2 byte hex string.
   * Currently we do not allow callers to set this field.
   */
  roaming80211rMobilityDomain?: Maybe<Scalars['String']['output']>;
  /**
   * A 32 byte hex string key used for encryption in 802.11r for r0 and r1 key holders.
   * Currently we do not allow callers to set this field.
   */
  roaming80211rR0khR1khKey?: Maybe<Scalars['String']['output']>;
  /** 802.11v is a standard that allows devices to communicate with the network for better connection performance, such as optimizing roaming and power efficiency. */
  roaming80211vIsEnabled: Scalars['Boolean']['output'];
  /** A value to configure RTS/CTS (see QCA doc "Wireless LAN Access Point Command Reference" for more details). */
  rtsCtsEnabledCode?: Maybe<Scalars['Int']['output']>;
  /** Size of the PPDU on which RTS would be sent out */
  rtsThreshold?: Maybe<Scalars['Int']['output']>;
  secondaryEncryption8021X?: Maybe<Encryption8021X>;
  ssid: Scalars['String']['output'];
  /** A number to uniquely identify the SSID within the network. Starts from zero. */
  ssidIndex: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** Must be non-null if the SSID is enabled. */
  vlan?: Maybe<Vlan>;
};

export enum SsidDynamicVlanMode {
  /**
   * The VLAN specified by an external RADIUS server's response after authentication is used.
   * The VLAN specified by `vlan` is used as a fallback if the RADIUS server does not specify a VLAN.
   */
  Enabled = 'ENABLED',
  /**
   * The VLAN specified by an external RADIUS server's response after authentication is used.
   * The VLAN specified by `vlan` is ignored.
   */
  Required = 'REQUIRED',
}

export enum SsidEncryption80211wProtectedManagementFramesMode {
  /** Can only be set as optional for WPA2 / WPA2 Enterprise / WPA3 Transition. */
  Optional = 'OPTIONAL',
  /** Must be set to this for WPA3; for other encryption protocols, it is also permitted. */
  Required = 'REQUIRED',
}

export enum SsidEncryptionProtocol {
  OpenMacAuthRadius = 'OPEN_MAC_AUTH_RADIUS',
  Wpa2 = 'WPA2',
  Wpa2Enterprise = 'WPA2_ENTERPRISE',
  Wpa3 = 'WPA3',
  Wpa3Enterprise = 'WPA3_ENTERPRISE',
  /** Supports clients that use WPA2 or WPA3. */
  Wpa3Transition = 'WPA3_TRANSITION',
}

export enum SsidPasswordRotationCadence {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export enum SsoAdminPortalType {
  /** WorkOS admin dashboard for viewing WorkOS Audit Logs. */
  AuditLogs = 'AUDIT_LOGS',
  /** WorkOS admin dashboard for configuring Directory Sync. */
  Dsync = 'DSYNC',
  /** WorkOS admin dashboard for configuring Log Streams. */
  LogStreams = 'LOG_STREAMS',
  /** WorkOS admin dashboard for configuring an SSO Provider. */
  SsoProvider = 'SSO_PROVIDER',
}

export type SsoConnectedDirectory = {
  __typename?: 'SSOConnectedDirectory';
  createdAt: Scalars['DateTime']['output'];
  state: SsoState;
  type: SsoDirectoryTypes;
};

export type SsoConnection = {
  __typename?: 'SSOConnection';
  connectedAt: Scalars['DateTime']['output'];
  state: SsoConnectionState;
  type: SsoConnectionType;
};

export enum SsoConnectionState {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
  None = 'None',
  Validating = 'Validating',
}

export enum SsoConnectionType {
  Adfssaml = 'ADFSSAML',
  AdpOidc = 'AdpOidc',
  Auth0Saml = 'Auth0SAML',
  AzureSaml = 'AzureSAML',
  CasSaml = 'CasSAML',
  ClassLinkSaml = 'ClassLinkSAML',
  CloudflareSaml = 'CloudflareSAML',
  CyberArkSaml = 'CyberArkSAML',
  DuoSaml = 'DuoSAML',
  GenericOidc = 'GenericOIDC',
  GenericSaml = 'GenericSAML',
  GoogleOAuth = 'GoogleOAuth',
  GoogleSaml = 'GoogleSAML',
  JumpCloudSaml = 'JumpCloudSAML',
  KeycloakSaml = 'KeycloakSAML',
  LastPassSaml = 'LastPassSAML',
  LoginGovOidc = 'LoginGovOidc',
  MagicLink = 'MagicLink',
  MicrosoftOAuth = 'MicrosoftOAuth',
  MiniOrangeSaml = 'MiniOrangeSAML',
  NetIqSaml = 'NetIqSAML',
  None = 'None',
  OktaSaml = 'OktaSAML',
  OneLoginSaml = 'OneLoginSAML',
  OracleSaml = 'OracleSAML',
  PingFederateSaml = 'PingFederateSAML',
  PingOneSaml = 'PingOneSAML',
  RipplingSaml = 'RipplingSAML',
  SalesforceSaml = 'SalesforceSAML',
  ShibbolethGenericSaml = 'ShibbolethGenericSAML',
  ShibbolethSaml = 'ShibbolethSAML',
  SimpleSamlPhpSaml = 'SimpleSamlPhpSAML',
  VMwareSaml = 'VMwareSAML',
}

export type SsoDirectory = {
  __typename?: 'SSODirectory';
  domain: Scalars['String']['output'];
  name: Scalars['String']['output'];
  state: SsoState;
  type: SsoDirectoryTypes;
};

export enum SsoDirectoryTypes {
  AzureScimv2_0 = 'AzureSCIMV2_0',
  BambooHr = 'BambooHr',
  BreatheHr = 'BreatheHr',
  CezanneHr = 'CezanneHr',
  CyberArk = 'CyberArk',
  FourthHr = 'FourthHr',
  GSuiteDirectory = 'GSuiteDirectory',
  GenericScimv2_0 = 'GenericSCIMV2_0',
  Hibob = 'Hibob',
  JumpCloud = 'JumpCloud',
  None = 'None',
  OktaScimv2_0 = 'OktaSCIMV2_0',
  OneLogin = 'OneLogin',
  PeopleHr = 'PeopleHr',
  Personio = 'Personio',
  PingFederate = 'PingFederate',
  Rippling = 'Rippling',
  Sftp = 'SFTP',
  SftpWorkday = 'SFTPWorkday',
  Workday = 'Workday',
}

export type SsoOptions = {
  __typename?: 'SSOOptions';
  connectedDirectory?: Maybe<SsoConnectedDirectory>;
  connections: Array<SsoConnection>;
  directories: Array<SsoDirectory>;
};

export type SsoOptionsInput = {
  companySlug: Scalars['String']['input'];
};

export enum SsoState {
  InvalidCredentials = 'InvalidCredentials',
  Linked = 'Linked',
  Unlinked = 'Unlinked',
}

export enum SsoVerificationSource {
  AuthKit = 'AUTH_KIT',
  Sso = 'SSO',
}

/** STP (Spanning Tree Protocol) configuration information for switches to ensure loop-free topologies. */
export type StpInfo = {
  __typename?: 'STPInfo';
  /** The root bridge is the switch in a network from which all paths are derived to prevent loops. */
  isRootBridge: Scalars['Boolean']['output'];
  rootBridgeMACAddress: Scalars['MacAddress']['output'];
  rootBridgePortNumber: Scalars['Int']['output'];
};

export enum StpPortRole {
  Alternate = 'ALTERNATE',
  Backup = 'BACKUP',
  Designated = 'DESIGNATED',
  Disabled = 'DISABLED',
  Root = 'ROOT',
}

export enum ScopeKind {
  AlertReceiverUuid = 'ALERT_RECEIVER_UUID',
  AlertTargetWebhookUuid = 'ALERT_TARGET_WEBHOOK_UUID',
  ApplicationDnsFirewallRuleUuid = 'APPLICATION_DNS_FIREWALL_RULE_UUID',
  AutoSelectionJobUuid = 'AUTO_SELECTION_JOB_UUID',
  AutoVpnGroupUuid = 'AUTO_VPN_GROUP_UUID',
  AutoVpnMemberUuid = 'AUTO_VPN_MEMBER_UUID',
  AutoVpnRouteUuid = 'AUTO_VPN_ROUTE_UUID',
  CaptivePortalUuid = 'CAPTIVE_PORTAL_UUID',
  CaptivePortalWebhookUuid = 'CAPTIVE_PORTAL_WEBHOOK_UUID',
  ClientVpnClientUuid = 'CLIENT_VPN_CLIENT_UUID',
  ClientVpnServerUuid = 'CLIENT_VPN_SERVER_UUID',
  CompanySlug = 'COMPANY_SLUG',
  CompanyUserUuid = 'COMPANY_USER_UUID',
  CompanyUuid = 'COMPANY_UUID',
  DhcpOptionUuid = 'DHCP_OPTION_UUID',
  DhcpReservedRangeUuid = 'DHCP_RESERVED_RANGE_UUID',
  DhcpRuleUuid = 'DHCP_RULE_UUID',
  DhcpStaticMappingUuid = 'DHCP_STATIC_MAPPING_UUID',
  DnsHostMappingsUuid = 'DNS_HOST_MAPPINGS_UUID',
  Encryption_8021XUuid = 'ENCRYPTION_8021X_UUID',
  FirewallRuleUuid = 'FIREWALL_RULE_UUID',
  FloorPlanUuid = 'FLOOR_PLAN_UUID',
  HostMonitoringTargetUuid = 'HOST_MONITORING_TARGET_UUID',
  InternalNoteUuid = 'INTERNAL_NOTE_UUID',
  InternetServicePlanUuid = 'INTERNET_SERVICE_PLAN_UUID',
  IpsecTunnelUuid = 'IPSEC_TUNNEL_UUID',
  NetworkJobUuid = 'NETWORK_JOB_UUID',
  NetworkOnboardingDocumentUuid = 'NETWORK_ONBOARDING_DOCUMENT_UUID',
  NetworkOnboardingFieldDelegationUuid = 'NETWORK_ONBOARDING_FIELD_DELEGATION_UUID',
  NetworkSlug = 'NETWORK_SLUG',
  NetworkUuid = 'NETWORK_UUID',
  NotNetworkScoped = 'NOT_NETWORK_SCOPED',
  OneToOneNatRuleUuid = 'ONE_TO_ONE_NAT_RULE_UUID',
  OutletInterfaceUuid = 'OUTLET_INTERFACE_UUID',
  PhyInterfaceUuid = 'PHY_INTERFACE_UUID',
  PolicyRoutingRuleUuid = 'POLICY_ROUTING_RULE_UUID',
  PortForwardingRuleUuid = 'PORT_FORWARDING_RULE_UUID',
  RackElevationDeviceUuid = 'RACK_ELEVATION_DEVICE_UUID',
  RackElevationUuid = 'RACK_ELEVATION_UUID',
  RadioProfileUuid = 'RADIO_PROFILE_UUID',
  RateLimitRuleUuid = 'RATE_LIMIT_RULE_UUID',
  SerialNumber = 'SERIAL_NUMBER',
  SsidUuid = 'SSID_UUID',
  StaticRouteUuid = 'STATIC_ROUTE_UUID',
  SwitchAutoConfigMacAddressVlanBindingUuid = 'SWITCH_AUTO_CONFIG_MAC_ADDRESS_VLAN_BINDING_UUID',
  SwitchProfileUuid = 'SWITCH_PROFILE_UUID',
  Unknown = 'UNKNOWN',
  VirtualDeviceUuid = 'VIRTUAL_DEVICE_UUID',
  VlanUuid = 'VLAN_UUID',
}

export enum ServiceType {
  Airplay = 'AIRPLAY',
  Printer = 'PRINTER',
  Speaker = 'SPEAKER',
  Unknown = 'UNKNOWN',
}

export type SetNetworkNosVersionInput = {
  force?: Scalars['Boolean']['input'];
  nosId: Scalars['Int']['input'];
  staggeredUpgradeIntervalMin?: Scalars['Int']['input'];
};

export type SetPolicyRoutingRuleBindingsInput = {
  bindings: Array<PolicyRoutingRuleBindingInput>;
};

export type SignedConsoleLoginChallenge = {
  __typename?: 'SignedConsoleLoginChallenge';
  /**
   * Key ID of the KMS key used to sign the challenge. This can be used to ensure
   * that the challenge was signed by the correct key.
   */
  keyID: Scalars['String']['output'];
  /**
   * The signed challenge.
   * This is a base64 encoded string with a PSS SHA 256 signed JSON payload.
   * The payload is a map with the following keys:
   * - challenge: the original challenge
   * - serialNumber: the serial number of the device to sign the challenge for
   * - user: the user that signed the challenge
   */
  signedChallenge: Scalars['String']['output'];
};

export type SpeedtestServer = {
  __typename?: 'SpeedtestServer';
  distance: Scalars['Int']['output'];
  host: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lat: Scalars['String']['output'];
  lon: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type StartWanSpeedtestJobInput = {
  baseURL?: Scalars['String']['input'];
  maxTransferBytes?: InputMaybe<Scalars['Int']['input']>;
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type Stat = {
  __typename?: 'Stat';
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  networkUUID: Scalars['UUID']['output'];
  observedAt: Scalars['DateTime']['output'];
  rawData: Scalars['JSONObject']['output'];
  type: StatType;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type StatInjectionDuration = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export enum StatType {
  Anomaly = 'ANOMALY',
  ArpTable = 'ARP_TABLE',
  CellularBootInfo = 'CELLULAR_BOOT_INFO',
  CellularStatus = 'CELLULAR_STATUS',
  ClientLatency = 'CLIENT_LATENCY',
  ConfigVersionInfo = 'CONFIG_VERSION_INFO',
  CosCapoAuthEvent = 'COS_CAPO_AUTH_EVENT',
  CosCapoRedirectEvent = 'COS_CAPO_REDIRECT_EVENT',
  CosClients = 'COS_CLIENTS',
  CosDhcpServerInfo = 'COS_DHCP_SERVER_INFO',
  CosDnsInfo = 'COS_DNS_INFO',
  CosFirewallCounters = 'COS_FIREWALL_COUNTERS',
  CosHaEvent = 'COS_HA_EVENT',
  CosIpsecTunnels = 'COS_IPSEC_TUNNELS',
  CosLteFailoverEvent = 'COS_LTE_FAILOVER_EVENT',
  CosMacTableEvent = 'COS_MAC_TABLE_EVENT',
  CosMultiwanEvent = 'COS_MULTIWAN_EVENT',
  CosNetworks = 'COS_NETWORKS',
  CosUsbPortEvent = 'COS_USB_PORT_EVENT',
  CosWanSpeedTestResult = 'COS_WAN_SPEED_TEST_RESULT',
  Heartbeat = 'HEARTBEAT',
  HostPing = 'HOST_PING',
  HostTraceroute = 'HOST_TRACEROUTE',
  LldpTable = 'LLDP_TABLE',
  /** @deprecated Use `MAC_TABLE` instead. */
  MacTable = 'MAC_TABLE',
  MdnsServiceDiscoveryEvent = 'MDNS_SERVICE_DISCOVERY_EVENT',
  PacketCapture = 'PACKET_CAPTURE',
  PduOutletAutoCycleEvent = 'PDU_OUTLET_AUTO_CYCLE_EVENT',
  PduOutletEvent = 'PDU_OUTLET_EVENT',
  PduStatusInfo = 'PDU_STATUS_INFO',
  PortEvent = 'PORT_EVENT',
  PortInfo = 'PORT_INFO',
  SecureAddressingAndMessagingSystemInfo = 'SECURE_ADDRESSING_AND_MESSAGING_SYSTEM_INFO',
  SfpPortsInfo = 'SFP_PORTS_INFO',
  SfpPortLoopbackInfo = 'SFP_PORT_LOOPBACK_INFO',
  Sos_8021XAuthenticatedHosts = 'SOS_8021X_AUTHENTICATED_HOSTS',
  Sos_8021XPortStatus = 'SOS_8021X_PORT_STATUS',
  SosFlapLinkStatus = 'SOS_FLAP_LINK_STATUS',
  SosIgmpSnoopingStatus = 'SOS_IGMP_SNOOPING_STATUS',
  /** @deprecated Use `MAC_TABLE` instead. */
  SosMacTable = 'SOS_MAC_TABLE',
  SosMldSnoopingStatus = 'SOS_MLD_SNOOPING_STATUS',
  SosPoeInfo = 'SOS_POE_INFO',
  SosPortCounters = 'SOS_PORT_COUNTERS',
  SosPortEvent = 'SOS_PORT_EVENT',
  SosPortLoopbackInfo = 'SOS_PORT_LOOPBACK_INFO',
  SosStpInfo = 'SOS_STP_INFO',
  SystemInfo = 'SYSTEM_INFO',
  Unspecified = 'UNSPECIFIED',
  UplinkInfo = 'UPLINK_INFO',
  WireguardPeerEvent = 'WIREGUARD_PEER_EVENT',
  WireguardTunnelInfo = 'WIREGUARD_TUNNEL_INFO',
  WosAcsResult = 'WOS_ACS_RESULT',
  WosAutoTransmitPowerSelectJobResults = 'WOS_AUTO_TRANSMIT_POWER_SELECT_JOB_RESULTS',
  WosClientTable = 'WOS_CLIENT_TABLE',
  WosConnectionEvent = 'WOS_CONNECTION_EVENT',
  WosEthStats = 'WOS_ETH_STATS',
  WosEvent = 'WOS_EVENT',
  WosObserverResult = 'WOS_OBSERVER_RESULT',
  WosObssStats = 'WOS_OBSS_STATS',
  WosPingTable = 'WOS_PING_TABLE',
  WosRfStats = 'WOS_RF_STATS',
  WosScanTable = 'WOS_SCAN_TABLE',
}

export type StaticRoute = {
  __typename?: 'StaticRoute';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dstPrefix: Scalars['IPPrefix']['output'];
  gatewayDHCPStaticMapping?: Maybe<DhcpStaticMapping>;
  gatewayIP?: Maybe<Scalars['IPV4']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 86400, one day. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /** The list of stat types to return. */
  types: Array<StatType>;
};

/** Allows users to specify which native VLAN to assign to the phy interface where a given MAC address or OUI is detected during a Switch Auto Config job. */
export type SwitchAutoConfigMacAddressVlanBinding = {
  __typename?: 'SwitchAutoConfigMACAddressVLANBinding';
  OUI?: Maybe<Scalars['String']['output']>;
  UUID: Scalars['UUID']['output'];
  VLAN: Vlan;
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID: Scalars['UUID']['output'];
};

/** Switch Auto Configuration Jobs automatically configure switch ports based on the connected clients and Meter devices. */
export type SwitchAutoConfigurationJob = AutoSelectionJob & {
  __typename?: 'SwitchAutoConfigurationJob';
  UUID: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Whether or not the job is a dry run. If true, no configuration changes are made. */
  isDryRun: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** Only ports that had their configuration changed will appear in the results. */
  results?: Maybe<Array<SwitchAutoConfigurationResult>>;
  scheduledAt: Scalars['DateTime']['output'];
  timeoutAt: Scalars['DateTime']['output'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

export type SwitchAutoConfigurationJobInput = {
  /** If true, the job will not make any configuration changes. It will only return the changes it would have made. */
  isDryRun?: Scalars['Boolean']['input'];
  /** The list of Switch virtual devices to run Auto Configuration on. If nil, all switches in the network will be auto-configured. */
  switchVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The Native VLAN to assign to the switch port if the clients on the port are not classified in the client MAC -> VLAN mappings for the network. */
  uncategorizedClientNativeVLANUUID: Scalars['UUID']['input'];
};

export type SwitchAutoConfigurationResult = {
  __typename?: 'SwitchAutoConfigurationResult';
  /** The new Native VLAN assigned to the port. Null only if the VLAN has since been deleted. */
  newNativeVLAN?: Maybe<Vlan>;
  /** The port that was configured. */
  phyInterface: PhyInterface;
  /** The previous Native VLAN assigned to the port. Null only if the VLAN has since been deleted. */
  previousNativeVLAN?: Maybe<Vlan>;
  /** The direction of the toggle between access <-> trunk (or no change). */
  toggleDirection: SwitchAutoConfigurationResultToggleDirection;
  /** The switch virtual device that was configured. */
  virtualDevice: SwitchVirtualDevice;
  warnings?: Maybe<Array<SwitchAutoConfigurationResultWarning>>;
};

export enum SwitchAutoConfigurationResultToggleDirection {
  AccessToTrunk = 'ACCESS_TO_TRUNK',
  NoChange = 'NO_CHANGE',
  TrunkToAccess = 'TRUNK_TO_ACCESS',
}

export enum SwitchAutoConfigurationResultWarning {
  /** Clients on the port had multiple mapped native VLANs based on their MAC addresses / OUIs. The uncategorizedClientNativeVLANUUID was selected. */
  MultipleClientMacVlanMappings = 'MULTIPLE_CLIENT_MAC_VLAN_MAPPINGS',
  /** The switch did not report any stats so no configuration took place. */
  NoStatsReported = 'NO_STATS_REPORTED',
}

/** A switch hardware device represents a physical switch. */
export type SwitchHardwareDevice = HardwareDevice & {
  __typename?: 'SwitchHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /** The IP address from our time series database for the device. */
  ipAddress?: Maybe<Scalars['String']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  /** True if the Switch's MAC address exists in the most recent controller MAC table for the network. */
  isInCurrentControllerMACTable: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** Information about total PoE power usage. */
  poeInfo?: Maybe<PoEInfo>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** The STP information pulled out of our time series database for the device. */
  stpInfo?: Maybe<StpInfo>;
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A switch hardware device represents a physical switch. */
export type SwitchHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type SwitchMacTableEntry = {
  __typename?: 'SwitchMACTableEntry';
  macAddress: Scalars['MacAddress']['output'];
  port: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vlan?: Maybe<Vlan>;
  vlanID: Scalars['Int']['output'];
};

export type SwitchMetricsResponse = MetricsResponse & {
  __typename?: 'SwitchMetricsResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<SwitchPortMetricsValue>;
};

export type SwitchPortMetricsRateResponse = {
  __typename?: 'SwitchPortMetricsRateResponse';
  values: Array<SwitchPortMetricsRateValue>;
};

export type SwitchPortMetricsRateValue = {
  __typename?: 'SwitchPortMetricsRateValue';
  /** Total number of broadcast packets per second received in the given time period; a subset of totalRxPackets. */
  broadcastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of broadcast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  dropsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second received in the given time period; a subset of totalRxPackets. */
  multicastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The physical port number on the switch. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets per second that encountered errors being received. */
  rxErrPerSecond: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes per second received in the given time period. */
  totalRxBytesPerSecond: Scalars['Float']['output'];
  /** Total number of bytes per second transmitted in the given time period. */
  totalTxBytesPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that encountered errors during transmission. */
  txErrPerSecond: Scalars['Float']['output'];
};

export type SwitchPortMetricsResponse = {
  __typename?: 'SwitchPortMetricsResponse';
  values: Array<SwitchPortMetricsStatValue>;
};

/** Counter stats per switch port over a lookback period. */
export type SwitchPortMetricsStatValue = {
  __typename?: 'SwitchPortMetricsStatValue';
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Float']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Float']['output'];
  /** The physical port number on the switch. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  rxDiscards: Scalars['Float']['output'];
  /** The number of packets that encountered errors being received. */
  rxErr: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Float']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Float']['output'];
  /** The number of packets that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  txDiscards: Scalars['Float']['output'];
  /** The number of packets that encountered errors during transmission. */
  txErr: Scalars['Float']['output'];
};

export type SwitchPortMetricsValue = MetricsValue & {
  __typename?: 'SwitchPortMetricsValue';
  direction: TrafficDirection;
  port: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

/** Summed stats (unless otherwise specified) per switch port over a lookback period. */
export type SwitchPortStat = {
  __typename?: 'SwitchPortStat';
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Int']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Int']['output'];
  /** Total number of packets discarded in the given time period; a subset of totalRxPackets. */
  discardRxPackets: Scalars['Int']['output'];
  /** Total number of packets discarded in the given time period; a subset of totalTxPackets. */
  discardTxPackets: Scalars['Int']['output'];
  /** Total number of packets with errors in the given time period; a subset of totalRxPackets. */
  errorRxPackets: Scalars['Int']['output'];
  /** Total number of packets with error in the given time period; a subset of totalTxPackets. */
  errorTxPackets: Scalars['Int']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Int']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Int']['output'];
  /** Total number of non-unicast packets received in the given time period; a subset of totalRxPackets. */
  nonUnicastRxPackets: Scalars['Int']['output'];
  /** Total number of non-unicast packets transmitted in the given time period; a subset of totalTxPackets. */
  nonUnicastTxPackets: Scalars['Int']['output'];
  /** The physical port number on the switch. */
  portNumber: Scalars['Int']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Int']['output'];
  /** Total number of packets received in the given time period. */
  totalRxPackets: Scalars['Int']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Int']['output'];
  /** Total number of packets transmitted in the given time period. */
  totalTxPackets: Scalars['Int']['output'];
  /** Total number of unicast packets received in the given time period; a subset of totalRxPackets. */
  unicastRxPackets: Scalars['Int']['output'];
  /** Total number of unicast packets transmitted in the given time period; a subset of totalTxPackets. */
  unicastTxPackets: Scalars['Int']['output'];
};

/** A switch profile is a bundle of settings that applies to a switch device. */
export type SwitchProfile = {
  __typename?: 'SwitchProfile';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The STP bridge priority for the switch. The lower the value, the more likely the switch is to be elected as the root bridge. The range is 0 to 61440. Valid values are multiples of 4096. */
  stpBridgePriority: Scalars['Int']['output'];
  /** STP forwarding delay in seconds */
  stpForwardingDelaySeconds: Scalars['Int']['output'];
  /** STP Hello interval in seconds */
  stpHelloIntervalSeconds: Scalars['Int']['output'];
  /** STP Max BPDU age in seconds */
  stpMaxAgeSeconds: Scalars['Int']['output'];
  /** STP BPDU transmit count per sec */
  stpTransmitHoldCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** A switch virtual device is a logical representation of a physical switch. */
export type SwitchVirtualDevice = VirtualDevice & {
  __typename?: 'SwitchVirtualDevice';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  phyInterfaces: Array<PhyInterface>;
  /** Information about total PoE power usage. */
  poeInfo?: Maybe<PoEInfo>;
  switchProfile: SwitchProfile;
  switchProfileUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type SystemInfoStat = {
  __typename?: 'SystemInfoStat';
  /** The used CPU percentage represented as a value [0, 1] */
  cpuUsedPercentage: Scalars['Float']['output'];
  memoryBytesAvailable: Scalars['Int']['output'];
  memoryBytesTotal: Scalars['Int']['output'];
  memoryBytesUsed: Scalars['Int']['output'];
  uptime: Scalars['Duration']['output'];
};

export type SystemInfoStatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 86400, one day. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
};

export type TestTriggerAlertTargetWebhookInput = {
  eventName: Scalars['String']['input'];
  payload: Scalars['String']['input'];
};

export type ThroughputValueWithDirection = {
  __typename?: 'ThroughputValueWithDirection';
  direction: TrafficDirection;
  value: Scalars['Int']['output'];
};

export type TokenPrincipal = {
  __typename?: 'TokenPrincipal';
  name: Scalars['String']['output'];
  type: PrincipalType;
};

export enum TokenType {
  Bearer = 'BEARER',
}

export enum TrafficDirection {
  Rx = 'RX',
  Tx = 'TX',
}

export type TwilioCellularActivationInput = {
  country: TwilioCellularCountry;
  iccid: Scalars['String']['input'];
};

export type TwilioCellularActivationResponse = {
  __typename?: 'TwilioCellularActivationResponse';
  accountSID?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fleetName?: Maybe<Scalars['String']['output']>;
  fleetURL?: Maybe<Scalars['String']['output']>;
  iccid?: Maybe<Scalars['String']['output']>;
  sid?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TwilioCellularActivationStatus>;
  uniqueName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum TwilioCellularActivationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  New = 'NEW',
  Ready = 'READY',
  Scheduled = 'SCHEDULED',
}

export enum TwilioCellularCountry {
  /** Canada */
  Ca = 'CA',
  /** Used for all other countries. */
  Other = 'OTHER',
  /** United States of America */
  Us = 'US',
}

export type UnassignedHardwareAndConnectedPhyInterface = {
  __typename?: 'UnassignedHardwareAndConnectedPhyInterface';
  /** The physical interface of the switch that the unassigned hardware device is connected to. */
  phyInterface: PhyInterface;
  /** The physically connected hardware device that is not associated with any virtual device in this network. */
  unassignedHardwareDevice: AccessPointHardwareDevice;
};

export type UnassignedHardwareDeviceForNetworkResponse = {
  __typename?: 'UnassignedHardwareDeviceForNetworkResponse';
  /** Tuples of unassigned hardware devices and the physical interfaces they are connected to. */
  unassignedHardwareConnections: Array<UnassignedHardwareAndConnectedPhyInterface>;
  /** The switch virtual device that the unassigned hardware device is connected to. */
  virtualDevice: SwitchVirtualDevice;
};

export enum UnauthenticatedReferer {
  Any = 'ANY',
  Captive = 'CAPTIVE',
  Install = 'INSTALL',
}

export type UnbindFirewallRuleFromPhyInterface = {
  firewallRuleUUID: Scalars['UUID']['input'];
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type UnbindFirewallRuleFromVlan = {
  firewallRuleUUID: Scalars['UUID']['input'];
  vlanUUID: Scalars['UUID']['input'];
};

export type UnbindPolicyRoutingRuleInput = {
  binding: PolicyRoutingRuleBindingInput;
};

/** The provided list of targets replaces the existing targets entirely. Any targets not included in this list will be removed. */
export type UpdateAlertReceiverWithTargetsInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  targets?: InputMaybe<Array<AlertTargetInput>>;
};

export type UpdateAlertTargetWebhookInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  signingSecret?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApplicationDnsFirewallRuleApplicationVisibilities = {
  id: Scalars['Int']['input'];
  isVisible: Scalars['Boolean']['input'];
};

export type UpdateApplicationDnsFirewallRuleInput = {
  action?: InputMaybe<FirewallRuleAction>;
  applicationID?: InputMaybe<Scalars['Int']['input']>;
  categoryID?: InputMaybe<Scalars['Int']['input']>;
  /** Updates the DHCP rule a rule is associated with. If priority is not specified will default to 1 greater than the highest existing priority. */
  dhcpRuleUUID?: InputMaybe<Scalars['UUID']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  groupID?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateApplicationDnsFirewallRulesPrioritiesInput = {
  applicationDNSFirewallRuleUUID: Scalars['UUID']['input'];
  priority: Scalars['Int']['input'];
};

export type UpdateAutoVpnGroupInput = {
  isHubFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uplink?: InputMaybe<CreateAutoVpnGroupHubUplinkInput>;
};

/** Acts as a PUT */
export type UpdateAutoVpnMemberInput = {
  isFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  permittedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateAutoVpnRouteInput = {
  dstGateway?: InputMaybe<Scalars['IPV4']['input']>;
  dstPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientVpnServerInput = {
  address?: InputMaybe<Scalars['IPV4']['input']>;
  addressMask?: InputMaybe<Scalars['Int']['input']>;
  defaultClientAllowedIPs?: InputMaybe<Array<Scalars['IPPrefix']['input']>>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: InputMaybe<Scalars['IPV4']['input']>;
  isFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  listenPort?: InputMaybe<Scalars['Int']['input']>;
  /** Must be an uplink phy interface. The WireGuard interface will listen on this interface. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateCompanyInput = {
  isProspective?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Fields that can be updated for a company user. */
export type UpdateCompanyUserInput = {
  companyMembershipRole?: InputMaybe<CompanyMembershipRole>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDhcpOptionInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDhcpReservedRangeInput = {
  endIPAddress?: InputMaybe<Scalars['IP']['input']>;
  startIPAddress?: InputMaybe<Scalars['IP']['input']>;
};

export type UpdateDhcpReservedRangeUuidInput = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpReservedRangeInput;
};

export type UpdateDhcpRuleInput = {
  dnsCacheIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dnsCacheMaxTTL?: InputMaybe<Scalars['Int']['input']>;
  dnsCacheSize?: InputMaybe<Scalars['Int']['input']>;
  dnsSearchDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  dnsUpstreamServers?: InputMaybe<Array<Scalars['IP']['input']>>;
  dnsUseGatewayProxy?: InputMaybe<Scalars['Boolean']['input']>;
  endIPAddress?: InputMaybe<Scalars['IP']['input']>;
  gatewayIPAddress?: InputMaybe<Scalars['IP']['input']>;
  gatewayPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isIPv6?: InputMaybe<Scalars['Boolean']['input']>;
  leaseDurationSeconds?: InputMaybe<Scalars['Int']['input']>;
  reservedRanges?: InputMaybe<Array<UpdateDhcpReservedRangeUuidInput>>;
  startIPAddress?: InputMaybe<Scalars['IP']['input']>;
  staticMappings?: InputMaybe<Array<UpdateDhcpStaticMappingUuidInput>>;
};

export type UpdateDhcpStaticMappingInput = {
  hostname?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['IP']['input']>;
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDhcpStaticMappingUuidInput = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpStaticMappingInput;
};

export type UpdateDnsHostMappingInput = {
  destinationDomain?: InputMaybe<Scalars['String']['input']>;
  destinationIPAddress?: InputMaybe<Scalars['IP']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  overrideDomain?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEncryption8021XInput = {
  accountingServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  accountingServerPort?: InputMaybe<Scalars['Int']['input']>;
  accountingServerSecret?: InputMaybe<Scalars['String']['input']>;
  authServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  authServerPort?: InputMaybe<Scalars['Int']['input']>;
  authServerSecret?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFirewallRuleInput = {
  action?: InputMaybe<FirewallRuleAction>;
  /** The phy interfaces must be uplinks. */
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  dstIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** @deprecated Use `dstPortRanges` instead. */
  dstPortRange?: InputMaybe<PortRangeInput>;
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  dstVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  isBidirectional?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isMeterInternal?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use `protocols` instead. */
  protocol?: InputMaybe<IpProtocol>;
  protocols?: InputMaybe<Array<IpProtocol>>;
  srcClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  srcIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** @deprecated Use `srcPortRanges` instead. */
  srcPortRange?: InputMaybe<PortRangeInput>;
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateFloorPlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageS3Key?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateForCellularFailoverEventInput = {
  route: CellularFailoverEventRoute;
};

export type UpdateForHighAvailabilityEventInput = {
  status: HighAvailabilityStatus;
};

/** Specifies the metadata that can be updated for a hardware device. */
export type UpdateHardwareDeviceInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  consoleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  factoryMode?: InputMaybe<Scalars['Boolean']['input']>;
  retired?: InputMaybe<Scalars['Boolean']['input']>;
  rma?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateHostMonitoringTargetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateIpSecTunnelInput = {
  authenticationAlgorithm?: InputMaybe<IpSecAuthenticationAlgorithm>;
  encryptionAlgorithm?: InputMaybe<IpSecEncryptionAlgorithm>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isInitiator?: InputMaybe<Scalars['Boolean']['input']>;
  keyExchangeDHGroup?: InputMaybe<IpSecDhGroup>;
  leftID?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** The phy interface must be an uplink. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** presharedKey can be base64 or plaintext. */
  presharedKey?: InputMaybe<Scalars['String']['input']>;
  right?: InputMaybe<Scalars['String']['input']>;
  rightID?: InputMaybe<Scalars['String']['input']>;
  rightPrefixes?: InputMaybe<Array<Scalars['IPPrefix']['input']>>;
  vlanUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UpdateInternalNoteInput = {
  isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** Use the `createPreSignedUploadUrl` mutation to generate S3 keys and presigned URLs for the photos, and specify them here. */
  photoS3Keys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateInternetServicePlanInput = {
  connection?: InputMaybe<InternetServicePlanConnectionInput>;
  ipConfiguration?: InputMaybe<InternetServicePlanIpInput>;
  providerUUID?: InputMaybe<Scalars['UUID']['input']>;
  sublocationUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateMailingAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryISO2?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  subdivisionCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMaintenanceWindowInput = {
  endDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  endHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  excludedDaysOfWeek?: InputMaybe<Array<Scalars['Int']['input']>>;
  isOptedOut?: InputMaybe<Scalars['Boolean']['input']>;
  recurrence?: InputMaybe<MaintenanceWindowRecurrence>;
  startDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  startHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateNetworkInput = {
  enabledServiceDiscoveryTypes?: InputMaybe<Array<ServiceType>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enables controller to upgrade config-1 APs deployed to the network */
  isConfig1WosUpgradesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Not a network property anymore */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isUpgradeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  mailingAddressInput?: InputMaybe<CreateMailingAddressInput>;
  mailingAddressUUID?: InputMaybe<Scalars['UUID']['input']>;
  multiWANAlgorithm?: InputMaybe<MultiWanAlgorithm>;
  patchPanelDiagramURL?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Not a network property anymore */
  squareFeet?: InputMaybe<Scalars['Int']['input']>;
  topologyImageS3Key?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNosFeatureInput = {
  description: Scalars['String']['input'];
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  maxMajorVersion?: InputMaybe<Scalars['Int']['input']>;
  maxMinorVersion?: InputMaybe<Scalars['Int']['input']>;
  maxPatchVersion?: InputMaybe<Scalars['Int']['input']>;
  minMajorVersion: Scalars['Int']['input'];
  minMinorVersion: Scalars['Int']['input'];
  minPatchVersion: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateNotificationSettingInput = {
  alertReceiverUUID?: InputMaybe<Scalars['UUID']['input']>;
  arguments?: InputMaybe<Array<NotificationSettingArgumentInput>>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the user who updated the notification setting */
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOneToOneNatRuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalIPAddr?: InputMaybe<Scalars['IPV4']['input']>;
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  internalIPAddr?: InputMaybe<Scalars['IPV4']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOutletInterfaceInput = {
  /** UUID of the virtual device connected to the power outlet */
  connectedVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  powerCycleDelaySec?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePhyInterfaceForMultiWanEventInput = {
  activePortNumber: Scalars['Int']['input'];
  activePortNumberUpdatedAt: Scalars['DateTime']['input'];
  uplinkExternalAddresses: Array<Scalars['UnmaskedIPPrefix']['input']>;
};

export type UpdatePhyInterfaceInput = {
  /** The maximum transmission unit - max size in bytes of frames that the port can tx/rx */
  MTU?: InputMaybe<Scalars['Int']['input']>;
  allowedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  forcedPortSpeedMbps?: InputMaybe<Scalars['Int']['input']>;
  frameAcceptTypeFilter?: InputMaybe<PhyInterfaceFrameAcceptTypeFilter>;
  ipv4ClientAddresses?: InputMaybe<Array<Scalars['IPV4']['input']>>;
  ipv4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipv4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  ipv4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  ipv6ClientAddresses?: InputMaybe<Array<Scalars['IPV6']['input']>>;
  ipv6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipv6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  ipv6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isBoundToAllVLANs?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isIngressFilteringEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPOEEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSTPEdgePortEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSTPEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isStormControlEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isTrunkPort?: InputMaybe<Scalars['Boolean']['input']>;
  isUplink?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** For switch port security, the max number of MAC addresses allowed on the port */
  maxMACAddressesAllowed?: InputMaybe<Scalars['Int']['input']>;
  nativeVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  rxRateLimitKbps?: InputMaybe<Scalars['Int']['input']>;
  stormControlBroadcastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  stormControlUnknownMulticastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  stormControlUnknownUnicastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  stpPathCost?: InputMaybe<Scalars['Int']['input']>;
  stpPriority?: InputMaybe<Scalars['Int']['input']>;
  txRateLimitKbps?: InputMaybe<Scalars['Int']['input']>;
  uplinkPriority?: InputMaybe<Scalars['Int']['input']>;
  uplinkVLANID?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePolicyRoutingRuleInput = {
  bindings?: InputMaybe<Array<PolicyRoutingRuleBindingInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protocols?: InputMaybe<Array<IpProtocol>>;
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdatePortForwardingRuleInput = {
  allowedRemoteIPAddresses?: InputMaybe<Array<Scalars['IP']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalIPAddress?: InputMaybe<Scalars['IP']['input']>;
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  externalPort?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  localIPAddress?: InputMaybe<Scalars['IP']['input']>;
  localPort?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protocol?: InputMaybe<PortIpProtocol>;
};

export type UpdateRackElevationDeviceInput = {
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing?: InputMaybe<Scalars['Boolean']['input']>;
  /** For display purposes. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** For additional context. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Only for Switches and Patch panels; cannot be specified if it's a Meter device. */
  portCount?: InputMaybe<Scalars['Int']['input']>;
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Cannot be specified if it's a Meter device. */
  type?: InputMaybe<RackElevationDeviceType>;
  /** If it's a Meter device, the virtual device's UUID. */
  virtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateRackElevationInput = {
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Array<RackElevationNoteInput>>;
  rackMountUnitCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRadioProfileInput = {
  /** Required if band2_4GAutoChannelIsEnabled is true. */
  band2_4GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  band2_4GAutoChannelIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  band2_4GAutoChannelWidthIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  band2_4GAutoTxPowerIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band2_4GAutoChannelWidthIsEnabled is false. */
  band2_4GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  band2_4GIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required if band5GAutoChannelIsEnabled is true. */
  band5GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  band5GAutoChannelIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  band5GAutoChannelWidthIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  band5GAutoTxPowerIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band5GAutoChannelWidthIsEnabled is false. */
  band5GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  band5GIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Setting this to true will set all other profiles to false. */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Specifies the metadata that can be updated for radio settings for an Access Point. */
export type UpdateRadioSettingsInput = {
  band2_4GPrimaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band2_4GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band2_4GTransmitPowerdBm?: InputMaybe<Scalars['Int']['input']>;
  band5GPrimaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band5GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band5GTransmitPowerdBm?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRateLimitRuleInput = {
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstMac?: InputMaybe<Scalars['MacAddress']['input']>;
  dstPortRange?: InputMaybe<PortRangeInput>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPerSource?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protocol?: InputMaybe<IpProtocol>;
  rateLimitKbpsDownload?: InputMaybe<Scalars['Int']['input']>;
  rateLimitKbpsUpload?: InputMaybe<Scalars['Int']['input']>;
  srcMac?: InputMaybe<Scalars['MacAddress']['input']>;
  srcPortRange?: InputMaybe<PortRangeInput>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
};

export type UpdateSsidInput = {
  /** The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second. */
  band2GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  /** The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second. */
  band5GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  clientSteeringAssociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientSteeringLoadBalancingClientMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  clientSteeringPostassociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientSteeringPreassociationRSSIMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dtimPeriod?: InputMaybe<Scalars['Int']['input']>;
  dynamicVLANMode?: InputMaybe<SsidDynamicVlanMode>;
  /** Required if isEnabledForAllAccessPoints is false. */
  enabledAccessPointVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  encryption80211wProtectedManagementFramesMode?: InputMaybe<SsidEncryption80211wProtectedManagementFramesMode>;
  encryptionProtocol?: InputMaybe<SsidEncryptionProtocol>;
  hs20AccessNetworkType?: InputMaybe<Scalars['Int']['input']>;
  /** When set to true, clears all hs20 data. */
  hs20ClearAll?: InputMaybe<Scalars['Boolean']['input']>;
  hs20DomainNames?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20Enabled?: InputMaybe<Scalars['Boolean']['input']>;
  hs20MccMncPairs?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20NaiRealms?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20OperatorNames?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20RoamingConsortiumOIs?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20VenueGroup?: InputMaybe<Scalars['Int']['input']>;
  hs20VenueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20VenueType?: InputMaybe<Scalars['Int']['input']>;
  is80211axEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isAPDebugLogEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isBand2GAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isBand5GAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabledForAllAccessPoints?: InputMaybe<Scalars['Boolean']['input']>;
  isGuest?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isIsolateClientsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isMulticastToUnicastEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211kEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211rEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211vEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRotationCadence?: InputMaybe<SsidPasswordRotationCadence>;
  passwordRotationDayOfMonthLocal?: InputMaybe<Scalars['Int']['input']>;
  passwordRotationDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  passwordRotationHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the primary 8021x encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  primaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  radiusCoaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  radiusCoaPort?: InputMaybe<Scalars['Int']['input']>;
  rtsCtsEnabledCode?: InputMaybe<Scalars['Int']['input']>;
  rtsThreshold?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the secondary 8021x encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  secondaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  ssid?: InputMaybe<Scalars['String']['input']>;
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateStaticRouteInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSwitchProfileInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  stpBridgePriority?: InputMaybe<Scalars['Int']['input']>;
  /** STP forwarding delay in seconds (4-30) */
  stpForwardingDelaySeconds?: InputMaybe<Scalars['Int']['input']>;
  /** STP Hello interval in seconds (1-10) */
  stpHelloIntervalSeconds?: InputMaybe<Scalars['Int']['input']>;
  /** STP Max BPDU age in seconds (6-40) */
  stpMaxAgeSeconds?: InputMaybe<Scalars['Int']['input']>;
  /** STP BPDU transmit count per sec (1-10) */
  stpTransmitHoldCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUplinkPhyInterfaceExternalAddressesInput = {
  portNumber: Scalars['Int']['input'];
  uplinkExternalAddresses: Array<Scalars['UnmaskedIPPrefix']['input']>;
  uplinkExternalAddressesUpdatedAt: Scalars['DateTime']['input'];
};

export type UpdateVlandhcpRuleInput = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: UpdateDhcpRuleInput;
};

export type UpdateVlanInput = {
  /** @deprecated Use `UUID` in `updateVLAN` instead. */
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  createDHCPRules?: InputMaybe<Array<CreateDhcpRuleInput>>;
  deleteDHCPRules?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ipV4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipV4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  ipV4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  ipV6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipV6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  ipV6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isMulticastReflectionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updateDHCPRules?: InputMaybe<Array<UpdateVlandhcpRuleInput>>;
  vlanID?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateVlaNsIndependentlyInput = {
  UUID: Scalars['UUID']['input'];
  input: UpdateVlaNsInput;
};

export type UpdateVlaNsInput = {
  isMulticastReflectionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Specifies the metadata that can be updated for a virtual device, used to update many virtual devices at once with different values. */
export type UpdateVirtualDeviceIndependentlyInput = {
  input: UpdateVirtualDeviceInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** Specifies the metadata that can be updated for a virtual device. */
export type UpdateVirtualDeviceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * For some devices, we will allow the user to set the device model.
   * This will be useful for hardware swaps where we want to retain the same configuration for the virtual device.
   * There will be application validation, since sometimes chanign the model is not valid (e.g. if the number of ports on the device differs,
   * or if the new model doesn't support features that the old model did).
   */
  deviceModel?: InputMaybe<DeviceModel>;
  enableConsolePort?: InputMaybe<Scalars['Boolean']['input']>;
  enableLEDDarkMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Currently we only support enabling debug logs on APs. */
  isDebugLogEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  radioProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
  radioSettings?: InputMaybe<UpdateRadioSettingsInput>;
  switchProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertNetworkOnboardingFieldDelegationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** A user is a person or machine that has access to Meter. */
export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

/**
 * Allows for adding a role assignment to a user, or removing a role assignment from a user. A role assignment
 * is defined in terms of global, company wide, or company & network wide.
 */
export type UserRoleInput = {
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  roleName: RoleName;
};

/** A VLAN (virtual LAN) is a logical network segment that allows network devices to communicate as if they were physically connected to the same network. */
export type Vlan = {
  __typename?: 'VLAN';
  UUID: Scalars['UUID']['output'];
  /** The captive portal associated with this VLAN. */
  captivePortal?: Maybe<CaptivePortal>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dhcpRule?: Maybe<DhcpRule>;
  /** If assigning this interface as a DHCP client, this field indicates the protocol to use for IPv4 addresses. */
  ipV4ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a DHCP client, this field indicates the gateway IPv4 address. This INET value should have no netmask. */
  ipV4ClientGateway?: Maybe<Scalars['IPV4']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the IPv4 prefixes to assign to the interface. */
  ipV4ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the protocol to use for IPv6 addresses. */
  ipV6ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a DHCP client, this field indicates the gateway IPv6 address. This INET value should have no netmask. */
  ipV6ClientGateway?: Maybe<Scalars['IPV6']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the IPv6 prefixes to assign to the interface. */
  ipV6ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** Only one VLAN per network can be the default VLAN. Untagged packets that don't belong to any configured VLANs are assigned to the default one. */
  isDefault: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** If true, this VLAN is internal to the network and should not be displayed to / editable by the customer. */
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  /** If true, this VLAN participates in multicast/mDNS reflection. */
  isMulticastReflectionEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name to identify the VLAN, like "guest". Unique per network. */
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** VLANs explicitly allowed to inter-communicate with this VLAN. Effectively equivalent to firewall rules allowing communication between this VLAN and all of these VLANs. */
  permittedInterVLANCommunicationVLANs: Array<Vlan>;
  updatedAt: Scalars['DateTime']['output'];
  /** The VLAN ID. Must be between 1 and 4094 and is unique per network. */
  vlanID: Scalars['Int']['output'];
};

export type VlanPairInput = {
  vlan1UUID: Scalars['UUID']['input'];
  vlan2UUID: Scalars['UUID']['input'];
};

export type ValidChannelsResponse = {
  __typename?: 'ValidChannelsResponse';
  band: RadioBand;
  channels: Array<Scalars['Int']['output']>;
};

/** A virtual device is a logical representation of a physical device; configuration is associated with the virtual device. */
export type VirtualDevice = {
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name for the device. */
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type VirtualDeviceNosHistory = {
  __typename?: 'VirtualDeviceNosHistory';
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  nosVersion: NosVersion;
  scheduledAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
};

/** VirtualDeviceType enumerates the different types of virtual devices that Meter deploys. */
export enum VirtualDeviceType {
  AccessPoint = 'ACCESS_POINT',
  Controller = 'CONTROLLER',
  Observer = 'OBSERVER',
  PowerDistributionUnit = 'POWER_DISTRIBUTION_UNIT',
  Switch = 'SWITCH',
}

export enum Wan {
  Wan0 = 'wan0',
  Wan1 = 'wan1',
}

export type WanConnection = {
  __typename?: 'WANConnection';
  externalAddresses: Array<Scalars['String']['output']>;
  internetServicePlanProvider?: Maybe<InternetServicePlanProvider>;
  port: Scalars['Int']['output'];
};

export type WanDownProperties = {
  __typename?: 'WANDownProperties';
  virtualDevice: VirtualDevice;
};

export enum WanSpeedtestDirection {
  Down = 'DOWN',
  /** For simplicity in schema, latency is unintuitively considered a direction */
  Latency = 'LATENCY',
  Unspecified = 'UNSPECIFIED',
  Up = 'UP',
}

export type WanSpeedtestJob = NetworkJob & {
  __typename?: 'WANSpeedtestJob';
  UUID: Scalars['UUID']['output'];
  baseURL: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  maxTransferBytes?: Maybe<Scalars['Int']['output']>;
  networkUUID: Scalars['UUID']['output'];
  phyInterface: PhyInterface;
  results?: Maybe<Array<WanSpeedtestJobResult>>;
  scheduledAt: Scalars['DateTime']['output'];
  status?: Maybe<WanSpeedtestStatus>;
};

export type WanSpeedtestJobResult = {
  __typename?: 'WANSpeedtestJobResult';
  /** The speedtest direction. */
  direction: WanSpeedtestDirection;
  /** The duration is nanoseconds this result took. Is null for LATENCY results. */
  durationNs?: Maybe<Scalars['Int']['output']>;
  failed: Scalars['Boolean']['output'];
  /** The RTT in nanoseconds each HTTP request in this result took. */
  httpRTTsNs: Array<Scalars['Int']['output']>;
  /** The mbps for this result. Is null for LATENCY results. */
  mbps?: Maybe<Scalars['Float']['output']>;
  /** The number of bytes transferred in this result. Is null for LATENCY results. */
  transferBytes?: Maybe<Scalars['Int']['output']>;
};

export enum WanSpeedtestStatus {
  /** Indicates the test is not running. */
  Ok = 'OK',
  /** Indicates the test is currently running. */
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export type WanStatusChangeProperties = {
  __typename?: 'WANStatusChangeProperties';
  activeWANConnections: Array<Maybe<WanConnection>>;
  virtualDevice: VirtualDevice;
};

export type WanUpProperties = {
  __typename?: 'WANUpProperties';
  virtualDevice: VirtualDevice;
};

/** Observer settings contain configuration for running wireless observer tests. */
export type WifiObserverSetting = {
  __typename?: 'WifiObserverSetting';
  /** The UUID of the SSID to use for the Observer test. */
  SSIDUUID: Scalars['UUID']['output'];
  /** Band on which the test should be run. */
  band: RadioBand;
  /** List of commands to run. */
  commands?: Maybe<Array<WosObserverCommand>>;
  /** Duration of the test, in msec. */
  durationMsec: Scalars['Int']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** 8021X password. */
  password8021X?: Maybe<Scalars['String']['output']>;
  /** The UUID of the virtual device (access point) the observer will target or get results for. */
  targetVirtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
  /** 8021X username. */
  username8021X?: Maybe<Scalars['String']['output']>;
  /** The UUID of the virtual device to run the observer test on. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/**
 * A single snapshot of client connectivity metrics for a single client.
 * Not an average over a time period or across multiple clients.
 */
export type WirelessClient = {
  __typename?: 'WirelessClient';
  /** When the client associated with the AP. */
  associatedAt?: Maybe<Scalars['DateTime']['output']>;
  band: RadioBand;
  channel: Scalars['Int']['output'];
  macAddress: Scalars['MacAddress']['output'];
  metrics: ClientMetricsTimeseriesValue;
  networkUUID: Scalars['UUID']['output'];
  /** List of mdns service types that this client offers (e.g. printer, airplay, etc) */
  serviceTypes?: Maybe<Array<ServiceType>>;
  /** The SSID that the client interacted with. */
  ssid: Ssid;
  /** The name of the SSID that the client interacted with. */
  ssidName: Scalars['String']['output'];
  /** The AP virtual device that the client interacted with. */
  virtualDevice: AccessPointVirtualDevice;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/** A stat that tells us when a client connected to an AP, disassociated from an AP, or failed to connect to an AP. */
export type WirelessClientConnectionEvent = {
  __typename?: 'WirelessClientConnectionEvent';
  /** The radio band that the client interacted with. */
  band: RadioBand;
  eventType: WirelessClientConnectionEventType;
  /** If the event type is `FAILED`, explains which step of the connection process failed. */
  failedStep?: Maybe<WirelessClientConnectionFailedStep>;
  /** Whether or not the connection event was part of a fast transition (802.11r) roam. */
  is80211rRoam: Scalars['Boolean']['output'];
  /** The MAC address of the client. */
  macAddress: Scalars['MacAddress']['output'];
  /** If the event type is `CONNECTED`, the AP virtual device that the client was previously connected to. */
  previousVirtualDeviceConnectedTo?: Maybe<AccessPointVirtualDevice>;
  /** If the event type is `CONNECTED`, which AP the client was previously connected to. */
  previousVirtualDeviceUUIDConnectedTo?: Maybe<Scalars['UUID']['output']>;
  /** If the event type is `DISASSOCIATED` or `FAILED`, the 802.11 reason code for the event. */
  reasonCode?: Maybe<Scalars['Int']['output']>;
  /** If the event type is `DISASSOCIATED`, how long the client was associated with the AP before disassociating. */
  sessionDuration?: Maybe<Scalars['Duration']['output']>;
  /** The SSID that the client interacted with. */
  ssid?: Maybe<Ssid>;
  /** The UUID of the SSID that the client interacted with. */
  ssidUUID?: Maybe<Scalars['UUID']['output']>;
  /** If the event type is `CONNECTED`, how long it took for the client to connect to the AP. */
  timeToConnect?: Maybe<Scalars['Duration']['output']>;
  timestamp: Scalars['DateTime']['output'];
  /** The AP virtual device that the client interacted with. */
  virtualDevice: AccessPointVirtualDevice;
  /** The UUID of the AP virtual device that the client interacted with. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export enum WirelessClientConnectionEventType {
  /** The client successfully connected to the AP. */
  Connected = 'CONNECTED',
  /** The client failed to receive an IP address from the DHCP server. */
  DhcpFailed = 'DHCP_FAILED',
  /** The client successfully received an IP address from the DHCP server. */
  DhcpOk = 'DHCP_OK',
  /** The client disassociated from the AP. */
  Disassociated = 'DISASSOCIATED',
  /** The client attempted to connect to the AP, but failed. */
  Failed = 'FAILED',
}

export enum WirelessClientConnectionFailedStep {
  /** The association packet exchange in the 802.11 handshake protocol failed. */
  Failed_80211Association = 'FAILED_80211_ASSOCIATION',
  /** The authentication packet exchange in the 802.11 handshake protocol failed. */
  Failed_80211Authentication = 'FAILED_80211_AUTHENTICATION',
  /** The 4-way EAPOL handshake failed. */
  FailedAuthentication = 'FAILED_AUTHENTICATION',
  /** The client declined the IP address from the DHCP server. */
  FailedDhcpDecline = 'FAILED_DHCP_DECLINE',
  /** The DHCP server denied the client's request for an IP address. */
  FailedDhcpNak = 'FAILED_DHCP_NAK',
  /** The DHCP server did not respond to the client's request for an IP address. */
  FailedDhcpTimeout = 'FAILED_DHCP_TIMEOUT',
  FailedOther = 'FAILED_OTHER',
}

export type WosObserverCommand = {
  __typename?: 'WosObserverCommand';
  /** The type of target (IPv4, IPv6, or hostname). */
  commandTargetType: WosObserverCommandTargetType;
  /** The type of command to run (e.g., ping, DNS, etc.). */
  commandType: WosObserverCommandType;
  /** An index to match with the test results. */
  index: Scalars['Int']['output'];
  /** Interval between tests, in msec. */
  intervalMsec: Scalars['Int']['output'];
  /** An index to run parallel tests (commands with same index run in parallel). */
  orderIndex: Scalars['Int']['output'];
  target: Scalars['String']['output'];
  /** Timeout between tests, in msec. */
  timeoutMsec: Scalars['Int']['output'];
};

export type WosObserverCommandInput = {
  commandTargetType: WosObserverCommandTargetType;
  commandType: WosObserverCommandType;
  index: Scalars['Int']['input'];
  intervalMsec: Scalars['Int']['input'];
  orderIndex: Scalars['Int']['input'];
  target: Scalars['String']['input'];
  timeoutMsec: Scalars['Int']['input'];
};

export enum WosObserverCommandTargetType {
  Hostname = 'HOSTNAME',
  Ipv4 = 'IPV4',
  Ipv6 = 'IPV6',
}

export enum WosObserverCommandType {
  Dns = 'DNS',
  Download = 'DOWNLOAD',
  Iperf = 'IPERF',
  Ping = 'PING',
}

export enum WosPacketCaptureCommandMonitor {
  Radio_2G = 'RADIO_2G',
  Radio_5G = 'RADIO_5G',
  Radio_6G = 'RADIO_6G',
  RadioScan = 'RADIO_SCAN',
  RadioUnspecified = 'RADIO_UNSPECIFIED',
}

export type CreatePreSignedUploadUrlMutationMutationVariables = Exact<{
  folder: AllowedFolder;
  fileExtension: Scalars['String']['input'];
}>;

export type CreatePreSignedUploadUrlMutationMutation = {
  __typename?: 'Mutation';
  createPreSignedUploadUrl: { __typename?: 'FileUploadPayload'; key: string; presignedUrl: string };
};

export type PresignedDownloadUrlQueryQueryVariables = Exact<{
  s3Key: Scalars['String']['input'];
}>;

export type PresignedDownloadUrlQueryQuery = {
  __typename?: 'Query';
  downloadUrl: { __typename?: 'FileDownloadPayload'; presignedUrl: string };
};

export const CreatePreSignedUploadUrlMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePreSignedUploadUrlMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'folder' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AllowedFolder' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileExtension' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPreSignedUploadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folder' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'folder' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileExtension' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileExtension' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'presignedUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePreSignedUploadUrlMutationMutation,
  CreatePreSignedUploadUrlMutationMutationVariables
>;
export const PresignedDownloadUrlQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PresignedDownloadUrlQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 's3Key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 's3Key' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 's3Key' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'presignedUrl' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PresignedDownloadUrlQueryQuery,
  PresignedDownloadUrlQueryQueryVariables
>;

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  HStack,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { type IPSecTunnel, deleteIPSecTunnelMutation, IPSecTunnelsQuery } from './utils';

type DeleteIPSecTunnelDialogProps = {
  tunnel: IPSecTunnel;
  state: OverlayTriggerState;
};

export default function DeleteIPSecTunnnelDialog({ tunnel, state }: DeleteIPSecTunnelDialogProps) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const deleteIPSecTunnel = useGraphQLMutation(deleteIPSecTunnelMutation);
  const { mutate, isError, error } = deleteIPSecTunnel;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    mutate(
      { uuid: tunnel.UUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(IPSecTunnelsQuery, { networkUUID: network.UUID }),
          );
          closeDrawer();
          close();
          notify('IPSec tunnel deleted successfully.', {
            variant: 'positive',
          });
        },
      },
    );
  }, [mutate, tunnel?.UUID, close, network.UUID, queryClient, closeDrawer]);

  if (!tunnel) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete IPSec tunnel" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to remove the IPSec tunnel <Text weight="bold">{tunnel.name}</Text> from
              your Meter network.
            </>
          }
        />

        {tunnel.boundVLANs?.length && (
          <SummaryList gutter="vertical">
            <SummaryListRow>
              <SummaryListKey>Affected VLAN{tunnel.boundVLANs.length > 1 && 's'}</SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  {tunnel.boundVLANs.map((binding) => (
                    <Badge
                      variant="neutral"
                      size="small"
                      icon="vlan"
                      arrangement="leading-icon"
                      key={binding.UUID}
                    >
                      {binding.name}
                    </Badge>
                  ))}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        )}

        {isError && (
          <Alert
            icon="warning"
            variant="negative"
            copy={
              <>
                `There was an error deleting the IPSec tunnel$
                {getGraphQLErrorMessageOrEmpty(error)}.`
              </>
            }
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

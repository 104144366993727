import type { ResultItem } from '@meterup/graphql';
import {
  checkDefinedOrThrow,
  refineIPV4Range,
  refineIPv4String,
  ResourceNotFoundError,
  truthy,
} from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import ip from 'ip';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';

import type { ListIsPsQueryQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';
import {
  CreateInternetServicePlanInputSchema,
  InternetServicePlanIpInputSchema,
  UpdateInternetServicePlanInputSchema,
} from '../../../gql/zod-types';

export const copyISPsToInternetServicePlansMutation = graphql(`
  mutation CopyISPsToInternetServicePlans($networkUUID: UUID!) {
    copyInternetServicePlanIPsFromConfigToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

function optionalIPv4String() {
  return z
    .string()
    .nullish()
    .superRefine((val, ctx) => {
      if (truthy(val)) {
        return refineIPv4String(val, ctx);
      }
      return true;
    });
}

function optionalIPv4Range() {
  return refineIPV4Range(optionalIPv4String(), false);
}

function safeParseAddress4(addr: string | null | undefined) {
  if (!truthy(addr)) {
    return null;
  }

  try {
    return ip.toBuffer(addr);
  } catch (e) {
    return null;
  }
}

function safeParseCIDR(addr: string | null | undefined) {
  if (!truthy(addr)) {
    return null;
  }

  try {
    return ip.cidrSubnet(addr);
  } catch (e) {
    return null;
  }
}

const ipConfigurationSchema = z.lazy(() =>
  InternetServicePlanIpInputSchema.extend({
    controllerIP: optionalIPv4String(),
    firstUsableIP: optionalIPv4String(),
    gatewayAddr: optionalIPv4String(),
    lastUsableIP: optionalIPv4String(),
    staticIPRange: optionalIPv4Range(),
  }).superRefine((data, ctx) => {
    const [firstUsableIP, gatewayAddr, lastUsableIP] = [
      'firstUsableIP',
      'gatewayAddr',
      'lastUsableIP',
    ].map((ipStr) =>
      safeParseAddress4(
        data[
          ipStr as keyof Omit<z.infer<typeof InternetServicePlanIpInputSchema>, 'controllerName'>
        ],
      ),
    );
    const staticIPRange = safeParseCIDR(data.staticIPRange);

    if (firstUsableIP && lastUsableIP && firstUsableIP > lastUsableIP) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'First usable IP must be less than last usable IP',
        path: ['firstUsableIP'],
      });
    }
    if (staticIPRange) {
      if (firstUsableIP && !staticIPRange.contains(ip.toString(firstUsableIP))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'First usable IP must be in the static IP range',
          path: ['firstUsableIP'],
        });
      }

      if (lastUsableIP && !staticIPRange.contains(ip.toString(lastUsableIP))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Last usable IP must be in the static IP range',
          path: ['lastUsableIP'],
        });
      }

      if (gatewayAddr && !staticIPRange.contains(ip.toString(gatewayAddr))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Gateway address must be in the static IP range',
          path: ['gatewayAddr'],
        });
      }
    }
  }),
);

export const ispsAddScheme = z
  .object({
    companySlug: z.string(),
    networkUUID: z.string(),
    input: CreateInternetServicePlanInputSchema.extend({
      ipConfiguration: ipConfigurationSchema,
    }),
  })
  .omit({ companySlug: true, networkUUID: true });

export type ISPAddSchema = z.infer<typeof ispsAddScheme>;

export const updateISPSchema = z
  .object({
    internetServicePlanUUID: z.string(),
    input: UpdateInternetServicePlanInputSchema.extend({
      ipConfiguration: ipConfigurationSchema,
    }),
  })
  .omit({ internetServicePlanUUID: true });

export type UpdateISPSchema = z.infer<typeof updateISPSchema>;

export const providersQuery = graphql(`
  query ProvidersQuery($companySlug: String!) {
    internetServicePlanProviders(companySlug: $companySlug) {
      UUID
      logoURL
      name
    }
  }
`);

export function useProviders({ companySlug }: { companySlug: string }) {
  const providers = checkDefinedOrThrow(
    useGraphQL(providersQuery, { companySlug }).data?.internetServicePlanProviders,
    new ResourceNotFoundError('No providers found'),
  );
  return useMemo(() => providers.sort((a, b) => a.name.localeCompare(b.name)), [providers]);
}

export const listISPsQuery = graphql(`
  query ListISPsQuery($companySlug: String!, $networkUUID: UUID) {
    internetServicePlans(companySlug: $companySlug, networkUUID: $networkUUID) {
      UUID

      address {
        address1
        address2
        city
        state
        postalCode
        country
      }

      connectionProduct
      connectionStatus

      provider {
        UUID
        logoURL
        name
      }

      controllerIP
      controllerName
      firstUsableIP
      gatewayAddr
      hasStaticIP
      lastUsableIP
      networkUUID
      staticIPRange
    }
  }
`);

function addressToSortable(
  address: ResultItem<ListIsPsQueryQuery, 'internetServicePlans'>['address'],
) {
  return [address?.country, address?.state, address?.city, address?.address1]
    .filter(truthy)
    .join(' ');
}

export function formatAddress(
  address: ResultItem<ListIsPsQueryQuery, 'internetServicePlans'>['address'],
) {
  return [address?.address1, address?.address2, address?.city, address?.state, address?.postalCode]
    .filter(truthy)
    .join(', ');
}

export function useISPs({
  companySlug,
  networkUUID,
  UUID,
}: {
  companySlug: string;
  networkUUID?: string;
  UUID?: string;
}) {
  const isps = checkDefinedOrThrow(
    useGraphQL(listISPsQuery, { companySlug, networkUUID, UUID }).data?.internetServicePlans,
    new ResourceNotFoundError('No ISPs found'),
  );
  return useMemo(
    () =>
      isps.sort((a, b) => {
        const { address: address1 } = a;
        const { address: address2 } = b;
        if (!address1) {
          return -1;
        }
        if (!address2) {
          return 1;
        }
        return addressToSortable(address1).localeCompare(addressToSortable(address2));
      }),
    [isps],
  );
}

export function useISPOrNull({
  companySlug,
  networkUUID,
  UUID,
}: {
  companySlug: string;
  networkUUID?: string;
  UUID?: string;
}) {
  return (
    useGraphQL(listISPsQuery, { companySlug, networkUUID, UUID }).data?.internetServicePlans.find(
      (plan) => plan.UUID === UUID,
    ) ?? null
  );
}

export function useISP(props: { companySlug: string; networkUUID?: string; UUID?: string }) {
  return checkDefinedOrThrow(useISPOrNull(props), new ResourceNotFoundError('No ISP found'));
}

export function useInvalidateISPs({
  companySlug,
  networkUUID,
}: {
  companySlug: string;
  networkUUID?: string;
}) {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries(makeQueryKey(listISPsQuery, { companySlug, networkUUID }));
  }, [companySlug, networkUUID, queryClient]);
}

export const ispMoveMutation = graphql(`
  mutation MoveInternetServicePlanMutation($ispUUID: UUID!, $networkUUID: UUID!) {
    moveInternetServicePlanToNetwork(internetServicePlanUUID: $ispUUID, networkUUID: $networkUUID) {
      UUID
    }
  }
`);

export function ISPProviderSlug({ providerName }: { providerName: string }) {
  switch (providerName) {
    case 'Allstream':
      return 'allstream';
    case 'AT&T':
      return 'att';
    case 'Cogent':
      return 'cogent';
    case 'Comcast Business':
      return 'comcastBusiness';
    case 'Cox':
      return 'cox';
    case 'CradlePoint':
      return 'cradlePoint';
    case 'Crown Castle':
      return 'crownCastle';
    case 'Cruzio':
      return 'cruzio';
    case 'EarthLink':
      return 'earthLink';
    case 'Fastmetrics':
      return 'fastmetrics';
    case 'For2Fi':
      return 'for2Fi';
    case 'Frontier':
      return 'frontier';
    case 'Google':
      return 'google';
    case 'GTT':
      return 'gtt';
    case 'HughesNet':
      return 'hughesNet';
    case 'Lumen':
      return 'lumen';
    case 'Monkeybrains':
      return 'monkeybrains';
    case 'Pilot':
      return 'pilot';
    case 'RCN':
      return 'rcn';
    case 'Sonic':
      return 'sonic';
    case 'Spectrum':
      return 'spectrum';
    case 'TMobile':
      return 'tMobile';
    case 'Verizon':
      return 'verizon';
    case 'Viasat':
      return 'viasat';
    case 'Wave':
      return 'wave';
    case 'Webpass':
      return 'webpass';
    case 'WiLine':
      return 'wiLine';
    case 'Windstream':
    case 'Windstream Communications':
      return 'windstream';
    case 'WOW':
      return 'wow';
    case 'Xfinity':
      return 'xfinity';
    case 'Zayo':
      return 'zayo';
    default:
      return 'unknown';
  }
}

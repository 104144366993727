import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import ClientVPN from '../../../../../components/ClientVPN/ClientVPN';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/secure-tunnels/vpn',
  layout: 'VirtualNetworkLayout',
  title: 'Client VPN - Secure tunnels',
});

export default function ClientVPNPage() {
  return (
    <IsPermitted
      permissions={PermissionType.PermClientVpnRead}
      should404OnAccessDenied
      path={Meta().path}
    >
      <ClientVPN />
    </IsPermitted>
  );
}

import { checkDefinedOrThrow } from '@meterup/common';

import { useClientByMAC } from '../../../../../components/Clients/utils';
import WiredClientDetail from '../../../../../components/Clients/WiredClientDetail';
import WirelessClientDetail from '../../../../../components/Clients/WirelessClientDetail';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import { isWired } from '../../../../../utils/clientLists';
import Client, { ValidClientDetailsTabs } from './Client';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/clients/:macAddress/insights',
  layout: 'VirtualNetworkLayout',
  title: 'Insights - Client - Insights',
});

export default function ClientInsightsPage() {
  const { macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ClientInsightsPage),
  );
  const client = useClientByMAC(macAddress);

  if (isWired(client)) {
    return (
      <Client
        macAddress={macAddress}
        main={<WiredClientDetail macAddress={macAddress} />}
        selectedTab={ValidClientDetailsTabs.Insights}
      />
    );
  }

  return (
    <Client
      macAddress={macAddress}
      main={<WirelessClientDetail macAddress={macAddress} />}
      selectedTab={ValidClientDetailsTabs.Insights}
    />
  );
}

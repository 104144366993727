import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import Routing from '../../../../../components/Routing/Routing';
import { RoutingTab } from '../../../../../components/Routing/utils';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/routing/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Routing - Network-wide',
});

export default function RoutingPage() {
  const params = Nav.useRegionParams('root', paths.pages.RoutingPage);
  return (
    <IsPermitted
      should404OnAccessDenied
      isPermitted={({ permissions, ldFlags, nosFlags, pathParams }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPolicyRoutingRead) &&
            nosFlags[NosFeature.POLICY_ROUTING] &&
            ldFlags['policy-routing'] &&
            Object.values(RoutingTab).includes(pathParams?.tab as RoutingTab),
        )
      }
      path={Meta().path}
    >
      <Routing tab={params?.tab as RoutingTab} />
    </IsPermitted>
  );
}

import type { BadgeVariant, IconName } from '@meterup/atto';
import { Badge, Button, ControlGroup, HStack, Icon, space, Text } from '@meterup/atto';
import { Address4 } from 'ip-address';

import type {
  FirewallRulesForNetworkQuery,
  PortRange,
  UplinkPhyInterfacesForNetworkQuery,
  VlaNsForFirewallQuery,
} from '../../gql/graphql';
import { MAX_PORT_NUMBER } from '../../constants';
import { graphql } from '../../gql';
import { FirewallRuleAction, IpProtocol } from '../../gql/graphql';
import { type VLAN, vlanHasStaticIP } from '../NetworkWide/VLANs/utils';

export const vlansForFirewallQuery = graphql(`
  query VLANsForFirewall($networkUUID: UUID!) {
    vlans(networkUUID: $networkUUID) {
      __typename
      UUID
      name
      description
      isEnabled
      isInternal
      isDefault
      vlanID
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength

      permittedInterVLANCommunicationVLANs {
        UUID
        name
      }

      dhcpRule {
        applicationDNSFirewallRules {
          UUID
        }
      }
    }
  }
`);

export type VLANForFirewall = VlaNsForFirewallQuery['vlans'][number];

graphql(`
  fragment PhyInterfaceLabelFields on PhyInterface {
    __typename
    UUID
    portNumber
    label
    hardwareLabel
    virtualDevice {
      UUID
      label
      deviceModel
    }
    internetServicePlan {
      provider {
        name
      }
    }
  }
`);

export const firewallRulesForNetwork = graphql(`
  query FirewallRulesForNetwork($networkUUID: UUID!) {
    firewallRulesForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      description
      isMeterInternal
      isEnabled
      isBidirectional
      action
      srcPrefix
      srcVLAN {
        __typename
        UUID
        name
        ipV4ClientAssignmentProtocol
        ipV4ClientGateway
        ipV4ClientPrefixLength
      }
      dstPrefix
      dstVLAN {
        __typename
        UUID
        name
        ipV4ClientAssignmentProtocol
        ipV4ClientGateway
        ipV4ClientPrefixLength
      }
      srcPortRange {
        lower
        upper
      }
      dstPortRange {
        lower
        upper
      }
      protocols
      tags

      vlanBindings {
        vlan {
          __typename
          UUID
          name
        }
        metric
      }
      phyInterfaceBindings {
        phyInterface {
          ...PhyInterfaceLabelFields
        }
        metric
      }
    }
  }
`);

export const updateBindingsForVLANMutation = graphql(`
  mutation UpdateFirewallRuleBindingsForVLAN($vlanUUID: UUID!, $orderedRuleUUIDs: [UUID!]!) {
    bindFirewallRulesToVLAN(vlanUUID: $vlanUUID, orderedRuleUUIDs: $orderedRuleUUIDs) {
      metric
    }
  }
`);

export const updateBindingsForPhyInterfaceMutation = graphql(`
  mutation UpdateFirewallRuleBindingsForPhyInterface(
    $phyInterfaceUUID: UUID!
    $orderedRuleUUIDs: [UUID!]!
  ) {
    bindFirewallRulesToPhyInterface(
      phyInterfaceUUID: $phyInterfaceUUID
      orderedRuleUUIDs: $orderedRuleUUIDs
    ) {
      metric
    }
  }
`);

export type FirewallRule = FirewallRulesForNetworkQuery['firewallRulesForNetwork'][number];
export type VLANBinding = NonNullable<FirewallRule['vlanBindings']>[number] & {
  rule: FirewallRule;
};
export type FirewallRuleVLAN = VLANBinding['vlan'] & {
  bindings: (VLANBinding & { isDefaultRule?: boolean })[];
};
export type PhyInterfaceBinding = NonNullable<FirewallRule['phyInterfaceBindings']>[number] & {
  rule: FirewallRule;
};
export type FirewallRulePhyInterface = PhyInterfaceBinding['phyInterface'] & {
  bindings: PhyInterfaceBinding[];
};
export type FirewallRuleBinding = VLANBinding | PhyInterfaceBinding;
export type FirewallRuleInterface = FirewallRuleVLAN | FirewallRulePhyInterface;

export type InterfaceBindings = Record<string, FirewallRuleInterface>;
export type BoundPhyInterface = PhyInterfaceBinding['phyInterface'];

export type FirewallRulePrefixVLAN = NonNullable<
  FirewallRulesForNetworkQuery['firewallRulesForNetwork'][number]['srcVLAN']
>;

// eslint-disable-next-line consistent-return
export function iconForRuleInterface(
  ruleInterface: Pick<FirewallRuleInterface, '__typename'>,
): IconName {
  switch (ruleInterface.__typename) {
    case 'VLAN':
      return 'vlan';
    case 'PhyInterface':
      return 'globe';
  }
}

export function vlanSupportsFirewallRules(
  vlan: Pick<VLAN, 'ipV4ClientAssignmentProtocol' | 'ipV4ClientGateway' | 'ipV4ClientPrefixLength'>,
): boolean {
  return vlanHasStaticIP(vlan);
}

export function prefixForVLAN(vlan: FirewallRulePrefixVLAN): string | undefined {
  if (!vlan.ipV4ClientGateway || vlan.ipV4ClientPrefixLength == null) return undefined;
  const addr = new Address4(`${vlan.ipV4ClientGateway}/${vlan.ipV4ClientPrefixLength}`);
  return `${addr.startAddress().address}/${addr.subnetMask}`;
}

export function prefixForRule(
  rule: Pick<FirewallRule, 'srcPrefix' | 'srcVLAN' | 'dstPrefix' | 'dstVLAN'>,
  prefix: 'src' | 'dst',
): string | undefined {
  switch (prefix) {
    case 'src':
      if (rule.srcPrefix) return rule.srcPrefix;
      if (rule.srcVLAN) return prefixForVLAN(rule.srcVLAN);
      break;
    case 'dst':
      if (rule.dstPrefix) return rule.dstPrefix;
      if (rule.dstVLAN) return prefixForVLAN(rule.dstVLAN);
      break;
  }

  return undefined;
}

export function sortBindings(
  a: Pick<FirewallRuleBinding, 'metric'>,
  b: Pick<FirewallRuleBinding, 'metric'>,
) {
  return a.metric - b.metric;
}

export function getPhyInterfaceLabel(
  pi: Pick<
    BoundPhyInterface,
    'label' | 'internetServicePlan' | 'hardwareLabel' | 'portNumber' | 'virtualDevice'
  >,
  includeVirtualDevice = true,
  includeISP = true,
): string {
  let portLabel = pi.label ?? pi.hardwareLabel ?? `Port ${pi.portNumber}`;

  if (includeISP && pi.internetServicePlan?.provider?.name) {
    portLabel += ` (${pi.internetServicePlan.provider.name})`;
  }

  return includeVirtualDevice ? `${pi.virtualDevice.label}: ${portLabel}` : portLabel;
}

// Return is checked by types
// eslint-disable-next-line consistent-return
export function labelForRuleInterface(
  ruleInterface: Omit<FirewallRuleVLAN, 'bindings'> | Omit<FirewallRulePhyInterface, 'bindings'>,
): string {
  switch (ruleInterface.__typename) {
    case 'VLAN':
      return ruleInterface.name;
    case 'PhyInterface':
      return getPhyInterfaceLabel(ruleInterface);
  }
}

export function protocolSupportsPorts(protocol: IpProtocol): boolean {
  return protocol === IpProtocol.Udp || protocol === IpProtocol.Tcp;
}

// Return is checked by types
// eslint-disable-next-line consistent-return
export function firewallRuleActionVariant(action: FirewallRuleAction): BadgeVariant {
  switch (action) {
    case FirewallRuleAction.Permit:
      return 'positive';
    case FirewallRuleAction.Deny:
      return 'negative';
  }
}

// Return is checked by types
// eslint-disable-next-line consistent-return
export function firewallRuleActionIcon(action: FirewallRuleAction): IconName {
  switch (action) {
    case FirewallRuleAction.Permit:
      return 'checkmark';
    case FirewallRuleAction.Deny:
      return 'block';
  }
}

// Return is checked by types
// eslint-disable-next-line consistent-return
export function firewallRuleActionLabel(action: FirewallRuleAction): string {
  switch (action) {
    case FirewallRuleAction.Permit:
      return 'Allow';
    case FirewallRuleAction.Deny:
      return 'Deny';
  }
}

export function displayIPProtocol(protocol: IpProtocol): string {
  switch (protocol) {
    case IpProtocol.All:
      return 'All';
    default:
      return protocol;
  }
}

export function isAllPorts(portRange: PortRange): boolean {
  return portRange.lower === 1 && portRange.upper === MAX_PORT_NUMBER;
}

export enum FirewallRulesTab {
  VLANs = 'vlans',
  WANs = 'wans',
  Rules = 'rules',
  InterVLANCommunication = 'inter-vlan-communication',
}

export enum ReorderAction {
  Increase,
  Decrease,
}

export function OrderButtons({
  index,
  length,
  handleReorder,
}: {
  index: number;
  length: number;
  handleReorder: (index: number, action: ReorderAction) => void;
}) {
  return (
    <ControlGroup size="small">
      <Button
        icon="arrow-up"
        arrangement="hidden-label"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleReorder(index, ReorderAction.Decrease);
        }}
        variant="secondary"
        disabled={index === 0}
      >
        Increase priority
      </Button>
      <Button
        icon="arrow-down"
        arrangement="hidden-label"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleReorder(index, ReorderAction.Increase);
        }}
        variant="secondary"
        disabled={index === length - 1}
      >
        Decrease priority
      </Button>
    </ControlGroup>
  );
}

export function PortRangeCell({
  portRange,
  row,
}: {
  portRange: PortRange;
  row: { rule: Pick<FirewallRule, 'protocols'> };
}) {
  if (
    isAllPorts(portRange) ||
    !row.rule.protocols?.every((protocol) => protocolSupportsPorts(protocol))
  )
    return (
      <Badge size="small" ends="card" variant="neutral">
        Any
      </Badge>
    );

  if (portRange.lower === portRange.upper) return <Text family="monospace">{portRange.lower}</Text>;

  return (
    <HStack spacing={space(4)} align="center">
      <Text family="monospace">{portRange.lower}</Text>
      <Icon icon="arrow-right" size={space(10)} />
      <Text family="monospace">{portRange.upper}</Text>
    </HStack>
  );
}

export enum PrefixKind {
  VLAN = 'vlan',
  IPAddress = 'ip',
}

export const uplinkPhyInterfacesQuery = graphql(`
  query UplinkPhyInterfacesForNetwork($networkUUID: UUID!) {
    uplinkPhyInterfacesForNetwork(networkUUID: $networkUUID) {
      __typename
      ...PhyInterfaceLabelFields
      UUID
      label
      isEnabled
      portNumber
      hardwareLabel
      virtualDevice {
        __typename
        UUID
        label
        deviceModel
        hardwareDevice {
          isActive
          isConnectedToBackend
          serialNumber
        }
        ... on ControllerVirtualDevice {
          highAvailability {
            role
            status
          }
        }
      }
      internetServicePlan {
        provider {
          name
        }
      }
      ipv4ClientAddresses
      isUplinkActive
      hasWANActivity
      uplinkExternalAddresses
    }
  }
`);

export type UplinkPhyInterface =
  UplinkPhyInterfacesForNetworkQuery['uplinkPhyInterfacesForNetwork'][number];

export const createFirewallRule = graphql(`
  mutation CreateFirewallRule($networkUUID: UUID!, $input: CreateFirewallRuleInput!) {
    createFirewallRule(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateFirewallRule = graphql(`
  mutation UpdateFirewallRule($uuid: UUID!, $input: UpdateFirewallRuleInput!) {
    updateFirewallRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteFirewallRule = graphql(`
  mutation DeleteFirewallRule($uuid: UUID!) {
    deleteFirewallRule(UUID: $uuid) {
      UUID
    }
  }
`);

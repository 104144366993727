import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import CreateClientVPNServerDrawer from '../../../../../components/ClientVPN/ServerDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/secure-tunnels/vpn/create',
  title: 'Add VPN server - Client VPN - Secure tunnels',
});

export default function ClientVPNServerCreatePage() {
  return (
    <IsPermitted
      permissions={PermissionType.PermClientVpnClientWrite}
      should404OnAccessDenied
      path={Meta().path}
    >
      <CreateClientVPNServerDrawer />
    </IsPermitted>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import ReceiverEdit from '../../../../../../components/Settings/Organization/Integrations/Receivers/ReceiverEdit';
import { paths } from '../../../../../../constants';
import { Nav } from '../../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/integrations/receivers/:uuid/edit',
  title: 'Edit receiver - Receivers - Integrations - Org',
});

export default function ReceiverEditPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ReceiverEditPage),
  );

  return <ReceiverEdit receiverUUID={uuid} />;
}

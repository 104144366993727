import type { ButtonSizeProp, ButtonVariantProp } from '@meterup/atto';
import {
  Button,
  EmptyState,
  Pane,
  PaneContent,
  PaneHeader,
  sizing,
  Skimmer,
  SkimmerContent,
  SkimmerHeader,
} from '@meterup/atto';
import { styled } from '@meterup/atto/src/stitches.config';
import { IsCompanyAdmin } from '@meterup/authorization';
import { AutoTable, isDefined, PanAndZoomRegion, preloadImage } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../providers/PermissionsProvider';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import { useDesignCrumbs, useNavigateBack, useNavigateHome } from '../../utils/routing';
import { ReactRouterLink } from '../ReactRouterLink';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { floorPlansQuery } from './utils';

const PanningContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: sizing.squish,
  overflow: 'hidden',
});

function AddFloorPlanButton({
  variant,
  size,
}: {
  variant?: ButtonVariantProp;
  size?: ButtonSizeProp;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <IsCompanyAdmin companySlug={companyName}>
      <Button
        condense
        as={Link}
        to={makeDrawerLink(window.location, paths.drawers.FloorPlanCreatePage, {
          companyName,
          networkSlug: network.slug,
        })}
        arrangement="leading-icon"
        icon="plus"
        variant={variant}
        size={size}
      >
        Add floor plan
      </Button>
    </IsCompanyAdmin>
  );
}

const builder = createColumnBuilder<any>();

const columns = [
  builder.data((row) => row.label, {
    id: 'label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
    cell: ({ row }) => row.label,
  }),
];

export default function FloorPlans({ activeFloorPlanUUID }: { activeFloorPlanUUID?: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const designCrumb = useDesignCrumbs();
  const { hasPermission } = usePermissions();

  const floorPlans = useGraphQL(floorPlansQuery, {
    networkUUID: network.UUID,
  }).data?.floorPlansForNetwork;

  const sortedFloorPlans = useMemo(
    () => floorPlans?.slice().sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [floorPlans],
  );

  const activeFloorPlan = useMemo(
    () =>
      (activeFloorPlanUUID
        ? sortedFloorPlans.find((fp) => fp.UUID === activeFloorPlanUUID)
        : sortedFloorPlans[0]) ?? null,
    [sortedFloorPlans, activeFloorPlanUUID],
  );

  useEffect(() => {
    for (const floorPlan of sortedFloorPlans) {
      preloadImage(floorPlan.imageDownloadURL);
    }
  }, [sortedFloorPlans]);

  return (
    <>
      <Skimmer>
        <SkimmerHeader
          icon="floorplan"
          heading="Floor plans"
          actions={
            hasPermission(PermissionType.PermCompanyUpdate) && (
              <AddFloorPlanButton size="small" variant="secondary" />
            )
          }
        />
        <SkimmerContent gutter="none">
          <AutoTable
            key="floor-plans-list"
            data={sortedFloorPlans}
            columns={columns}
            getLinkTo={(row) =>
              hasPermission(PermissionType.PermCompanyGet)
                ? makeLink(paths.pages.FloorPlanPage, {
                    companyName,
                    networkSlug: network.slug,
                    floorPlanUUID: row.UUID,
                  })
                : undefined
            }
            isRowSelected={(row) => row.UUID === activeFloorPlan?.UUID}
          />
        </SkimmerContent>
      </Skimmer>
      <Pane layoutMode="detailed">
        <PaneHeader
          back={back}
          home={home}
          crumbs={[
            ...designCrumb,
            ...(activeFloorPlan
              ? [
                  {
                    type: 'page' as const,
                    page: {
                      as: ReactRouterLink,
                      to: makeLink(paths.pages.FloorPlanPage, {
                        companyName,
                        networkSlug: network.slug,
                        floorPlanUUID: activeFloorPlan?.UUID,
                      }),
                      label: 'Floor plan',
                    },
                  },
                ]
              : []),
          ]}
          icon="floorplan"
          heading={activeFloorPlan?.label ?? 'Floor plan'}
          actions={
            activeFloorPlan &&
            hasPermission(PermissionType.PermCompanyUpdate) && (
              <Button
                condense
                as={Link}
                to={makeDrawerLink(window.location, paths.drawers.FloorPlanEditPage, {
                  companyName,
                  networkSlug: network.slug,
                  floorPlanUUID: activeFloorPlan.UUID,
                })}
                arrangement="leading-icon"
                icon="pencil"
                variant="secondary"
                size="small"
              >
                Edit floor plan
              </Button>
            )
          }
        />
        <PaneContent gutter="none">
          <PanningContainer>
            <PanAndZoomRegion>
              {isDefined(activeFloorPlan?.imageDownloadURL) ? (
                <img
                  src={activeFloorPlan?.imageDownloadURL}
                  alt="Floor plan"
                  crossOrigin="anonymous"
                  style={{ maxHeight: '50vh', userSelect: 'none' }}
                />
              ) : (
                <EmptyState
                  icon="floorplan"
                  heading="No floor plans"
                  action={<AddFloorPlanButton />}
                />
              )}
            </PanAndZoomRegion>
          </PanningContainer>
        </PaneContent>
      </Pane>
    </>
  );
}

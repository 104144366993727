import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import PatchPanelLayout from '../../../components/Hardware/Switches/PatchPanelLayout';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/patch-panel-layout',
  layout: 'VirtualNetworkLayout',
});

export default function PatchPanelLayoutPage() {
  return <PatchPanelLayout />;
}

import {
  Badge,
  Body,
  darkThemeSelector,
  HStack,
  Small,
  space,
  styled,
  Tooltip,
} from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { colors } from '@meterup/atto/src/stitches.config';
import React, { useContext } from 'react';

import { ThemeContext } from '../../providers/ThemeProvider';

const UptimeChartHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: 4,
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const UptimeChartBarValue = styled('div', {
  position: 'relative',
  zIndex: 1,
  width: '100%',
  height: '100%',
  borderRadius: '$8',
});

const UptimeChartBarTarget = styled('div', {
  content: '',
  position: 'absolute',
  zIndex: 0,
  top: '-2px',
  right: '0',
  bottom: '0',
  left: '0',
  borderRadius: '$8',
  opacity: 0,

  '&::before': {
    content: '',
    position: 'absolute',
    top: '-4px',
    right: '-4px',
    bottom: '-4px',
    left: '-4px',
  },

  [selectors.hover]: {
    zIndex: 2,
    opacity: 1,
  },
});

const UptimeChartBar = styled('div', {
  position: 'relative',
  width: '$2',
  minWidth: '$2',
  maxWidth: '$2',
  height: '100%',
  margin: '0 auto',
});

const UptimeChartBars = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '$4',
  width: '100%',
  height: '$28',
  padding: '$4 0',
  overflow: 'hidden',
});

const UptimeChartScroll = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '$4 $8',
  background: colors.bgNeutralLight,
  borderRadius: '$8',
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },
});

const UptimeChartContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$8',
});

const bgColorsLight = {
  good: colors.iconPositiveLight,
  medium: colors.iconAttentionLight,
  bad: colors.iconNegativeLight,
};

const bgColorsDark = {
  good: colors.iconPositiveDark,
  medium: colors.iconAttentionDark,
  bad: colors.iconNegativeDark,
};

const baseColorsLight = colors.tokenBgNeutralLight;

const baseColorsDark = colors.tokenBgNeutralDark;

const poorQualityWaterMark = 99.5;
const badQualityWaterMark = 98.0;

export function averageUptimeCalc(data: { timestamp: Date; value: number }[]) {
  let sum = 0.0;
  data.forEach((item) => {
    sum += item.value;
  });
  return ((sum * 100.0) / data.length).toFixed(2);
}

export function UptimeChart({
  average,
  badge,
  data,
  label,
}: {
  average?: boolean;
  badge?: React.ReactNode;
  data: { timestamp: Date; value: number }[];
  label?: React.ReactNode;
}) {
  const { dark } = useContext(ThemeContext);

  let averageVariant: 'positive' | 'attention' | 'negative' = 'positive';
  if (average) {
    const calculatedAverage = parseFloat(averageUptimeCalc(data));
    if (calculatedAverage > poorQualityWaterMark) {
      averageVariant = 'positive';
    } else if (
      calculatedAverage < poorQualityWaterMark &&
      calculatedAverage >= badQualityWaterMark
    ) {
      averageVariant = 'attention';
    } else if (calculatedAverage < badQualityWaterMark) {
      averageVariant = 'negative';
    }
  }

  return (
    <UptimeChartContainer>
      {(label || average) && (
        <UptimeChartHeader>
          {label && <Body weight="bold">{label}</Body>}
          {data && average && (
            <Badge size="small" variant={averageVariant}>
              {averageUptimeCalc(data)}%
            </Badge>
          )}
          {badge && <Badge size="small">{badge}</Badge>}
        </UptimeChartHeader>
      )}
      <UptimeChartScroll>
        {data ? (
          <UptimeChartBars>
            {data.map((item) => {
              const itemValue = item.value * 100.0;

              let quality: 'good' | 'medium' | 'bad' = 'good';
              if (itemValue < poorQualityWaterMark) {
                quality = 'medium';
              }
              if (itemValue < badQualityWaterMark) {
                quality = 'bad';
              }

              let badgeVariant: 'positive' | 'attention' | 'negative' = 'positive';
              if (itemValue > poorQualityWaterMark) {
                badgeVariant = 'positive';
              }
              if (itemValue < poorQualityWaterMark) {
                badgeVariant = 'attention';
              }
              if (itemValue < badQualityWaterMark) {
                badgeVariant = 'negative';
              }

              const time = item.timestamp.toLocaleString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              });

              const bgColor = dark ? bgColorsDark[quality] : bgColorsLight[quality];
              const baseColor = dark ? baseColorsDark : baseColorsLight;

              return (
                <Tooltip
                  delay={0}
                  contents={
                    <HStack align="center" spacing={space(6)}>
                      <Small weight="bold">{time}</Small>
                      <Badge size="small" variant={badgeVariant}>
                        {itemValue.toFixed(2)}%
                      </Badge>
                    </HStack>
                  }
                >
                  <UptimeChartBar key={item.timestamp.toISOString()}>
                    <UptimeChartBarValue
                      style={{
                        background: `linear-gradient(0deg, ${bgColor} ${itemValue}%, ${baseColor} ${itemValue}%)`,
                      }}
                    />
                    <UptimeChartBarTarget
                      style={{
                        background: `linear-gradient(0deg, ${bgColor} ${itemValue}%, ${baseColor} ${itemValue}%)`,
                      }}
                    />
                  </UptimeChartBar>
                </Tooltip>
              );
            })}
          </UptimeChartBars>
        ) : (
          <Small weight="bold">No ISP uptime data available</Small>
        )}
      </UptimeChartScroll>
    </UptimeChartContainer>
  );
}

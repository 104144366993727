import type { SortingState } from '@tanstack/react-table';
import { Badge, EmptyState, Icon, space, Tooltip } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useRef, useState } from 'react';

import type { BssiDsForNetworkQueryQuery } from '../../../gql/graphql';
import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { CopyableMonoOrNoValue } from '../../Devices/Insights';
import { APWOS2DeviceTarget, SSIDDeviceTarget } from '../../DeviceTargets';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { radioBandToHuman } from '../../Wireless/RadioProfiles/utils';
import { BSSIDsForNetworkQuery } from '../../Wireless/utils';

type BSSID = NonNullable<BssiDsForNetworkQueryQuery['bssidsForNetwork']>[number];

const bssidBuilder = createColumnBuilder<BSSID>();

const bssidColumns = [
  bssidBuilder.data((s) => (s.isActive ? 'Active' : 'Inactive'), {
    id: 'is-active',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Broadcast status',
      },
    },
    cell: ({ row }) => {
      if (row.isActive) {
        return (
          <Tooltip contents="BSSID is being broadcast">
            <Badge
              arrangement="hidden-label"
              size="small"
              ends="pill"
              icon="wifi"
              variant="positive"
            >
              Broadcast
            </Badge>
          </Tooltip>
        );
      }
      return (
        <Tooltip contents="BSSID is not being broadcast">
          <Badge arrangement="hidden-label" size="small" ends="pill" icon="wifi" variant="neutral">
            Not broadcast
          </Badge>
        </Tooltip>
      );
    },
  }),
  bssidBuilder.data((s) => s.BSSID, {
    id: 'bssid',
    header: 'BSSID',
    cell: ({ value }) => <CopyableMonoOrNoValue label="BSSID" value={value} wrap={false} />,
  }),
  bssidBuilder.data((s) => s.accessPointHardwareDevice?.virtualDevice?.label, {
    id: 'access-point',
    header: 'Access point',
    cell: ({ row }) => (
      <APWOS2DeviceTarget
        uuid={row.accessPointHardwareDevice.virtualDevice?.UUID}
        label={row.accessPointHardwareDevice.virtualDevice?.label ?? 'Access point'}
        wrap={false}
      />
    ),
  }),
  bssidBuilder.data((s) => s.SSID.ssid, {
    id: 'ssid',
    header: 'SSID',
    cell: ({ value }) => (
      <SSIDDeviceTarget
        rootPath={paths.pages.AccessPointsBSSIDsPage}
        ssidName={value}
        wrap={false}
      />
    ),
  }),
  bssidBuilder.data((s) => radioBandToHuman(s.radioBand), {
    id: 'band',
    header: 'Band',
  }),
];

export default function AccessPointsBSSIDs() {
  const network = useNetwork();
  const [globalFilter] = useSearchParamsState<string>('filter', '');

  const containerRef = useRef<HTMLDivElement>(null);

  const bssidsData = useGraphQL(BSSIDsForNetworkQuery, {
    networkUUID: network.UUID,
  }).data;

  const [bssidSortingState, setBssidSortingState] = useState<SortingState>([
    { id: 'is-active', desc: false },
    { id: 'access-point', desc: false },
    { id: 'ssid', desc: false },
  ]);

  if (bssidsData?.bssidsForNetwork?.length === 0) {
    return <EmptyState icon="mac-address" heading="No BSSIDs" />;
  }

  return (
    <div ref={containerRef}>
      <AutoTable
        isVirtual
        tableContainerRef={containerRef}
        columns={bssidColumns}
        data={bssidsData?.bssidsForNetwork ?? []}
        globalFilter={globalFilter}
        sortingState={bssidSortingState}
        onChangeSortingState={setBssidSortingState}
      />
    </div>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  DetailHeader,
  expectDefinedOrThrow,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import Apiv2 from '../../../../../../api/apiv2';
import { linkToSwitchPort } from '../../../../../../components/Connections';
import { DetailPageLayout } from '../../../../../../components/DetailPageLayout';
import { FindLLDPEntryOnSwitchQuery } from '../../../../../../components/Hardware/Switches/utils';
import { paths } from '../../../../../../constants';
import { useNetworkAndActiveControllerForUUID } from '../../../../../../hooks/useActiveControllerForNetwork';
import { Nav } from '../../../../../../nav';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../../providers/CurrentControllerProvider';
import { getAPNameForDevice } from '../../../../../../utils/access_point_utils';

function AccessPointActions({ macAddress }: { macAddress: string }) {
  const controller = useCurrentControllerData();
  const networkAndControllerSerial = useNetworkAndActiveControllerForUUID(controller.network_uuid);
  const companyName = useCurrentCompany();
  const navigate = useNavigate();

  const device = useGraphQL(
    FindLLDPEntryOnSwitchQuery,
    { networkUUID: controller.network_uuid, mac: macAddress },
    { useErrorBoundary: false },
  ).data?.findSwitchLLDPEntryForMAC;

  if (!device || !networkAndControllerSerial?.network) return null;

  const link = linkToSwitchPort({
    networkSlug: networkAndControllerSerial.network.slug,
    companyName,
    switchUUID: device.virtualDevice.UUID,
    portUUID: device.phyInterface.UUID,
  });

  return (
    <DropdownMenu>
      <DropdownMenuButton variant="secondary" icon="overflow-horizontal" arrangement="hidden-label">
        Actions
      </DropdownMenuButton>
      <DropdownMenuPopover align="end">
        <DropdownMenuItem onSelect={() => navigate(link)} icon="switch">
          View switch
        </DropdownMenuItem>
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}

function LegacyAccessPointDetailPageHeader() {
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const api = new Apiv2(controller);
  const { deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.LegacyAccessPointDetailPage),
  );

  const device =
    useQuery(['device', controllerName, deviceName], () => api.accessPoint(deviceName), {
      suspense: true,
    }).data ?? null;

  expectDefinedOrThrow(device, new ResourceNotFoundError('Client not found'));

  return (
    <DetailHeader
      icon="access-point"
      heading={getAPNameForDevice(device)}
      type="access-point"
      actions={<AccessPointActions macAddress={device.mac_address} />}
    />
  );
}

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/devices/:deviceName',
  layout: 'NetworkLayout',
});

export default function LegacyAccessPointDetailLayout() {
  return <DetailPageLayout header={<LegacyAccessPointDetailPageHeader />} />;
}

import type { MeterControllerConfig } from '@meterup/config';
import {
  Body,
  Button,
  CompositeField,
  FieldContainer,
  HStack,
  Icon,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  SecondaryToggleField,
  Select,
  SelectItem,
  space,
  styled,
  TextInput,
  ToggleInput,
  Tooltip,
} from '@meterup/atto';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { PSKRotationFrequency, PSKSchemeType } from '@meterup/config';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import type { ValidServiceSetData } from '../../../../../validations/validServiceSetData';
import { getSuggestedPassword } from '../../../../../api/api';
import { CopyToClipboardButtonMinimal } from '../../../../../components/CopyToClipboardButton';
import { createFieldProvider } from '../../../../../components/Form/FieldProvider';
import { FormikConditional } from '../../../../../components/FormikConditional';
import { PasswordStrengthIndicator } from '../../../../../components/PasswordStrengthIndicator';
import { logError } from '../../../../../utils/logError';

const FieldProvider = createFieldProvider<ValidServiceSetData>();

const NoPasswordDescription = styled('div', {
  marginLeft: space(24),
});

export function SSIDField() {
  return (
    <FieldContainer>
      <FieldProvider name="ssid">
        <PrimaryField label="SSID" element={<TextInput />} />
      </FieldProvider>
      <FieldProvider name="shouldBroadcastSSID">
        <SecondaryToggleField label="Broadcast SSID" />
      </FieldProvider>
    </FieldContainer>
  );
}

export function PasswordField({ currentValue }: { currentValue?: string | null }) {
  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormikContext<ValidServiceSetData>();

  const suggestPassword = async () => {
    try {
      const password = await getSuggestedPassword(3);
      formik.setFieldValue('pskValue', password);
    } catch (e) {
      logError(e);
    }
  };

  const copyablePassword =
    formik.values.pskSchemeType === PSKSchemeType.Static
      ? formik.values.pskValue
      : currentValue ?? '';

  const suggestPasswordButton = (
    <Tooltip contents="Generate password">
      <Button
        type="button"
        icon="arrows-rotate"
        arrangement="hidden-label"
        onClick={suggestPassword}
        variant="secondary"
      >
        Generate password
      </Button>
    </Tooltip>
  );

  return (
    <FieldContainer>
      <PrimaryFieldComposite
        label="Password"
        fields={
          <HStack spacing={space(6)}>
            <FieldProvider name="pskSchemeType">
              <div style={{ flexShrink: 0 }}>
                <CompositeField
                  label="Type"
                  element={
                    <Select>
                      <SelectItem key={PSKSchemeType.None}>None</SelectItem>
                      <SelectItem key={PSKSchemeType.Static}>Static</SelectItem>
                      <SelectItem key={PSKSchemeType.Rotating}>Rotating</SelectItem>
                    </Select>
                  }
                />
              </div>
            </FieldProvider>
            <FormikConditional<ValidServiceSetData>
              condition={(values) => values.pskSchemeType === PSKSchemeType.Static}
            >
              <FieldProvider name="pskValue">
                <CompositeField
                  label="Password"
                  element={<TextInput width="100%" type={showPassword ? 'text' : 'password'} />}
                />
              </FieldProvider>
            </FormikConditional>
            <FormikConditional<ValidServiceSetData>
              condition={(values) => values.pskSchemeType === PSKSchemeType.Rotating}
            >
              <div style={{ flexShrink: 0 }}>
                <FieldProvider name="pskRotationFrequency">
                  <CompositeField
                    label="Rotation frequency"
                    element={
                      <Select>
                        <SelectItem key={PSKRotationFrequency.Never}>Never</SelectItem>
                        <SelectItem key={PSKRotationFrequency.Daily}>Daily</SelectItem>
                        <SelectItem key={PSKRotationFrequency.Weekly}>Weekly</SelectItem>
                        <SelectItem key={PSKRotationFrequency.Monthly}>Monthly</SelectItem>
                      </Select>
                    }
                  />
                </FieldProvider>
              </div>
            </FormikConditional>
            <FormikConditional<ValidServiceSetData>
              condition={(v) => v.pskSchemeType === PSKSchemeType.Rotating && !!currentValue}
            >
              <TextInput
                width="100%"
                isReadOnly
                value={currentValue ?? ''}
                type={showPassword ? 'text' : 'password'}
              />
            </FormikConditional>
          </HStack>
        }
        controls={
          <>
            <FormikConditional<ValidServiceSetData>
              condition={(v) => v.pskSchemeType === PSKSchemeType.Static}
            >
              <PasswordStrengthIndicator password={formik.values.pskValue ?? ''} />
              {suggestPasswordButton}
            </FormikConditional>
            <FormikConditional<ValidServiceSetData>
              condition={(v) => v.pskSchemeType !== PSKSchemeType.None}
            >
              {isDefinedAndNotEmpty(copyablePassword) && (
                <>
                  <CopyToClipboardButtonMinimal text={copyablePassword} />
                  <Tooltip contents={showPassword ? 'Hide password' : 'Show password'}>
                    <Button
                      variant="secondary"
                      arrangement="hidden-label"
                      icon={showPassword ? 'eye-closed' : 'eye-open'}
                      size="small"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Hide password' : 'Show password'}
                    </Button>
                  </Tooltip>
                </>
              )}
            </FormikConditional>
          </>
        }
      />
    </FieldContainer>
  );
}

export function NoPasswordField() {
  return (
    <FieldContainer>
      <PrimaryField
        label={
          <HStack spacing={space(8)}>
            <Icon icon="padlock" />
            No password required
          </HStack>
        }
        element={
          <NoPasswordDescription>
            <Body>
              With the 802.1X standard you authenticate into this SSID by logging in with your
              credentials instead of a typical password.
            </Body>
          </NoPasswordDescription>
        }
      />
    </FieldContainer>
  );
}

export function BandsField() {
  return (
    <FieldContainer>
      <FieldProvider name="is5GEnabled">
        <SecondaryField label="5 GHz" element={<ToggleInput />} />
      </FieldProvider>
      <FieldProvider name="is2GEnabled">
        <SecondaryField label="2.4 GHz" element={<ToggleInput />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function VLANField({ model }: { model: MeterControllerConfig }) {
  return (
    <FieldProvider name="vlan">
      <PrimaryField
        label="VLAN"
        element={
          <Select placeholder="None">
            {model.getCustomerFacingVLANs().map((vlan) => (
              <SelectItem key={vlan.name}>{vlan.name}</SelectItem>
            ))}
          </Select>
        }
      />
    </FieldProvider>
  );
}

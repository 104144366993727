import React from 'react';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import { ClientsList } from './ClientsList';
import ClientsListHeader from './ClientsListHeader';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/insights/clients',
  layout: 'NetworkLayout',
});

export default function ClientsListPage() {
  return (
    <DetailPageLayout
      header={<ClientsListHeader networkClientOptions={{ filter: { includeThroughput: true } }} />}
      main={<ClientsList networkClientOptions={{ filter: { includeThroughput: true } }} />}
    />
  );
}

import type { ExportableToCSV } from '@meterup/common';
import type { SortingState, Updater } from '@tanstack/react-table';
import { Badge, Body, EmptyState, HStack, Icon, ManufacturerIcon, space } from '@meterup/atto';
import { AutoTable, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useCallback, useMemo, useRef } from 'react';

import type { AccessPoint, AccessPointConnectedClient } from '../../Wireless/utils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { ClientsList2 } from '../../../routes/pages/network/insights/clients/ClientsList2';
import { manufacturerIconName } from '../../../utils/clientLists';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useClientNamesByMACForAccessPoint } from '../../Clients/utils';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { AccessPointQuery } from '../../Wireless/utils';

const builder = createColumnBuilder<AccessPointConnectedClient>();

const useDrawerColumns = (serialNumber: string) => {
  const namesByMac = useClientNamesByMACForAccessPoint(serialNumber);

  return useMemo(() => {
    const columns = [
      builder.display({
        id: 'status',
        header: () => <Icon icon="question" size={space(16)} />,
        meta: {
          alignment: 'center',
          width: 48,
          tooltip: {
            contents: 'Status',
          },
        },
        cell: () => (
          <Badge arrangement="hidden-label" variant="positive" icon="wifi" size="small" ends="pill">
            Online
          </Badge>
        ),
      }),
      builder.data((row) => namesByMac[row.macAddress] ?? row.macAddress, {
        id: 'client',
        header: 'Client',
        meta: {
          minWidth: 44,
          isLeading: true,
        },
        cell: ({ row, value }) => {
          if (value === row.macAddress) {
            return (
              <HStack spacing={space(6)}>
                <ManufacturerIcon icon={manufacturerIconName(row)} size={20} />
                <Body family="monospace">{value}</Body>
              </HStack>
            );
          }

          return <span>{value}</span>;
        },
      }),
    ];

    return columns;
  }, [namesByMac]);
};

function AccessPointFullClientList({ virtualDevice }: { virtualDevice: AccessPoint }) {
  const tableRef = useRef<ExportableToCSV>(null);

  return (
    <ClientsList2
      networkClientOptions={{
        filter: { apSerialNumber: virtualDevice.hardwareDevice?.serialNumber },
      }}
      tableRef={tableRef}
      emptyStateHeading="No clients on this access point"
    />
  );
}

function AccessPointDrawerClientList({
  virtualDevice,
  globalFilter,
  sortingState,
  setSortingState,
}: {
  virtualDevice: AccessPoint;
  globalFilter?: string;
  sortingState?: SortingState | undefined;
  setSortingState?: (updater: Updater<SortingState>) => void;
}) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const columns = useDrawerColumns(virtualDevice.hardwareDevice?.serialNumber!);
  const containerRef = useRef<HTMLDivElement>(null);

  const clients =
    virtualDevice.__typename === 'AccessPointVirtualDevice' ? virtualDevice.connectedClients : [];

  const linkTo = useCallback(
    (row: AccessPointConnectedClient) => {
      if (row.macAddress) {
        return makeLink(paths.pages.ClientInsightsPage, {
          companyName,
          networkSlug: network.slug,
          macAddress: row.macAddress,
        });
      }

      return null;
    },
    [companyName, network.slug],
  );

  const params = Nav.useRegionParams('drawer', paths.drawers.ClientSummary2Page);
  const rowSelected = useCallback(
    (row: AccessPointConnectedClient) => params?.macAddress === row.macAddress,
    [params?.macAddress],
  );

  return (
    <div ref={containerRef}>
      {clients.length > 0 && (
        <AutoTable
          isVirtual
          tableContainerRef={containerRef}
          size="small"
          columns={columns}
          data={clients}
          getLinkTo={linkTo}
          sortingState={sortingState}
          onChangeSortingState={setSortingState}
          isRowSelected={rowSelected}
          globalFilter={globalFilter}
        />
      )}
      {clients.length === 0 && (
        <EmptyState icon="client" heading="No clients on this access point" />
      )}
    </div>
  );
}

export default function AccessPointClientList({
  uuid,
  view,
  globalFilter,
  sortingState,
  setSortingState,
}: {
  uuid: string;
  view: 'drawer' | 'full';
  globalFilter?: string;
  sortingState?: SortingState | undefined;
  setSortingState?: (updater: Updater<SortingState>) => void;
}) {
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const virtualDevice = useGraphQL(AccessPointQuery, { uuid, includeUptime }).data?.virtualDevice;
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Unable to load access point'));

  if (view === 'drawer') {
    return (
      <AccessPointDrawerClientList
        virtualDevice={virtualDevice}
        globalFilter={globalFilter}
        sortingState={sortingState}
        setSortingState={setSortingState}
      />
    );
  }

  return <AccessPointFullClientList virtualDevice={virtualDevice} />;
}

import type { ResultItem } from '@meterup/graphql';
import { Drawer, DrawerContent, DrawerHeader, Sections } from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';

import type { ListIsPsQueryQuery } from '../../../gql/graphql';
// import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
// import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { StickyObjectHeader } from '../../Object/ObjectHeader';
import { ISPActions } from './ISPActions';
import { ISPIPConfiguration, ISPMetadata } from './ISPUnits';
import { ISPProviderSlug, useISP } from './utils';

type ISPContentProps = {
  isp: ResultItem<ListIsPsQueryQuery, 'internetServicePlans'>;
};

function ISPContent({ isp }: ISPContentProps) {
  // const companyName = useCurrentCompany();
  // const network = useNetwork();
  return (
    <>
      <StickyObjectHeader
        icon={ISPProviderSlug({
          providerName: isp.provider ? isp.provider.name : 'unknown',
        })}
        name={isp.provider?.name}
        status={isp.connectionStatus.toLowerCase()}
        ends="card"
        // link={makeLink(paths.pages.ISPInsightsPage, {
        //   companyName,
        //   networkSlug: network.slug,
        //   uuid: isp.UUID,
        // })}
        // cta="View ISP"
      />
      <Sections>
        <ISPMetadata isp={isp} />
        <ISPIPConfiguration isp={isp} />
      </Sections>
    </>
  );
}

export default function ISPDrawer({ ispUUID }: { ispUUID: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const close = useCloseDrawerCallback();
  const isp = useISP({ companySlug: companyName, networkUUID: network.UUID, UUID: ispUUID });
  return (
    <Drawer>
      <DrawerHeader
        icon="globe"
        heading="ISP"
        actions={
          <IsOperator>
            <ISPActions ispUUID={ispUUID} view="drawer" />
          </IsOperator>
        }
        onClose={close}
      />
      <DrawerContent gutter="none">
        <ISPContent isp={isp} />
      </DrawerContent>
    </Drawer>
  );
}

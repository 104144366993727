import type { ButtonSizeProp, ButtonVariantProp } from '@meterup/atto';
import type { SortingState } from '@meterup/common';
import {
  Badge,
  Button,
  EmptyState,
  HStack,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  space,
  styled,
  Text,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { graphql } from '../../gql';
import {
  type PortRange,
  type RateLimitRulesForNetworkQuery,
  PermissionType,
} from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { NosFeature } from '../../hooks/useNosFeatures';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import { useFirewallCrumbs, useNavigateBack, useNavigateHome } from '../../utils/routing';
import {
  getPhyInterfaceLabel,
  isAllPorts,
  prefixForRule,
  protocolSupportsPorts,
} from '../Firewall/utils';
import { NoValue } from '../NoValue';
import IsPermitted from '../permissions/IsPermitted';
import { useIsPermitted } from '../permissions/useIsPermitted';
import { ReactRouterLink } from '../ReactRouterLink';
import { createColumnBuilder } from '../Table/createColumnBuilder';

export const rateLimitRulesForNetworkQuery = graphql(`
  query RateLimitRulesForNetwork($networkUUID: UUID!) {
    rateLimitRulesForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      description
      rateLimitKbpsDownload
      rateLimitKbpsUpload
      isEnabled
      isPerSource
      protocol
      srcMac
      srcPrefix
      srcPortRange {
        lower
        upper
      }
      dstMac
      dstPrefix
      dstPortRange {
        lower
        upper
      }
      vlanBindings {
        __typename
        UUID
        name
      }
      phyInterfaceBindings {
        __typename
        ...PhyInterfaceLabelFields
      }
    }
  }
`);

type RateLimitRule = RateLimitRulesForNetworkQuery['rateLimitRulesForNetwork'][number];

const PrefixContainer = styled(HStack, {
  whiteSpace: 'nowrap',
});

function PortRangeCell({ portRange, row }: { portRange?: PortRange | null; row: RateLimitRule }) {
  if (!portRange || isAllPorts(portRange) || (row.protocol && protocolSupportsPorts(row.protocol)))
    return (
      <Badge size="small" ends="card" variant="neutral">
        Any
      </Badge>
    );

  if (portRange.lower === portRange.upper) return <Text family="monospace">{portRange.lower}</Text>;

  return (
    <HStack spacing={space(4)} align="center">
      <Text family="monospace">{portRange.lower}</Text>
      <Icon icon="arrow-right" size={space(10)} />
      <Text family="monospace">{portRange.upper}</Text>
    </HStack>
  );
}

const builder = createColumnBuilder<RateLimitRule>();

const columns = [
  builder.data((row) => (row.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Enabled',
      },
    },
    cell: ({ row }) =>
      row.isEnabled ? (
        <Icon
          icon="checkmark"
          color={{
            light: 'iconPositiveLight',
            dark: 'iconPositiveDark',
          }}
        />
      ) : (
        <NoValue />
      ),
  }),
  // HACK(DASH-2848): Temporarily hide Per source option while firmware doesn't support it fully.
  // builder.data((row) => (row.isPerSource ? 'Per client' : ''), {
  //   id: 'per-source',
  //   header: 'Per client',
  //   meta: { width: 36 },
  //   cell: ({ row }) =>
  //     row.isPerSource ? (
  //       <Icon
  //         icon="checkmark"
  //         color={{
  //           light: 'iconPositiveLight',
  //           dark: 'iconPositiveDark',
  //         }}
  //       />
  //     ) : (
  //       <NoValue />
  //     ),
  // }),
  builder.data((row) => row.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data(
    (row) =>
      row.vlanBindings?.map((vlan) => `VLAN: ${vlan.name}`).join(', ') ??
      row?.phyInterfaceBindings?.map((pi) => `WAN: ${getPhyInterfaceLabel(pi)}`).join(', ') ??
      '',
    {
      id: 'bindings',
      header: 'Bindings',
      cell: ({ row }) => {
        if (row.vlanBindings) {
          return (
            <HStack spacing={space(6)}>
              {row.vlanBindings?.map((vlan) => (
                <Badge variant="neutral" size="small" icon="vlan" arrangement="leading-icon">
                  {vlan.name}
                </Badge>
              ))}
            </HStack>
          );
        }

        if (row.phyInterfaceBindings) {
          return (
            <HStack spacing={space(6)}>
              {row.phyInterfaceBindings?.map((pi) => (
                <Badge variant="neutral" size="small" icon="globe" arrangement="leading-icon">
                  {getPhyInterfaceLabel(pi)}
                </Badge>
              ))}
            </HStack>
          );
        }

        return null;
      },
    },
  ),
  builder.data((row) => row.rateLimitKbpsDownload?.toString(), {
    id: 'download-limit',
    header: 'Download (Kbps)',
    meta: {
      alignment: 'end',
    },
    cell: ({ row }) =>
      row.rateLimitKbpsDownload != null ? (
        <HStack spacing={space(8)} align="center">
          <Icon icon="download" size={12} />
          <Text family="monospace">{row.rateLimitKbpsDownload}</Text>
        </HStack>
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.rateLimitKbpsUpload?.toString(), {
    id: 'upload-limit',
    header: 'Upload (Kbps)',
    meta: {
      alignment: 'end',
    },
    cell: ({ row }) =>
      row.rateLimitKbpsUpload != null ? (
        <HStack spacing={space(8)} align="center">
          <Icon icon="upload" size={12} />
          <Text family="monospace">{row.rateLimitKbpsUpload}</Text>
        </HStack>
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => prefixForRule(row, 'src'), {
    id: 'source-prefix',
    header: 'Internal prefix / mask',
    cell: ({ value }) =>
      !value || value === '0.0.0.0/0' ? (
        <Badge size="small" ends="card" variant="neutral">
          Any
        </Badge>
      ) : (
        <PrefixContainer spacing={space(8)} align="center">
          <Text family="monospace">{value.replace('/', ' / ')}</Text>
        </PrefixContainer>
      ),
  }),
  builder.data(
    (row) => (row.srcPortRange ? `${row.srcPortRange.lower}-${row.srcPortRange.upper}` : undefined),
    {
      id: 'source-ports',
      header: 'Internal ports',
      cell: ({ row }) => <PortRangeCell row={row} portRange={row.srcPortRange} />,
    },
  ),
  builder.data((row) => prefixForRule(row, 'dst'), {
    id: 'destination-prefix',
    header: 'External prefix / mask',
    cell: ({ value }) =>
      !value || value === '0.0.0.0/0' ? (
        <Badge size="small" ends="card" variant="neutral">
          Any
        </Badge>
      ) : (
        <PrefixContainer spacing={space(8)} align="center">
          <Text family="monospace">{value.replace('/', ' / ')}</Text>
        </PrefixContainer>
      ),
  }),
  builder.data(
    (row) => (row.dstPortRange ? `${row.dstPortRange.lower}-${row.dstPortRange.upper}` : undefined),
    {
      id: 'destination-ports',
      header: 'External ports',
      cell: ({ row }) => <PortRangeCell row={row} portRange={row.dstPortRange} />,
    },
  ),
];

function AddRateLimitRuleButton({
  companyName,
  networkSlug,
  variant,
  size,
}: {
  companyName: string;
  networkSlug: string;
  variant?: ButtonVariantProp;
  size?: ButtonSizeProp;
}) {
  return (
    <Button
      condense
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.CreateRateLimitRulePage, {
        companyName,
        networkSlug,
      })}
      arrangement="leading-icon"
      icon="plus"
      variant={variant}
      size={size}
    >
      Add rule
    </Button>
  );
}

export default function RateLimiting() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const firewallCrumb = useFirewallCrumbs();
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditRateLimitRulePage);
  const isOperator = useIsOperator({ respectDemoMode: true });
  const isPermitted = useIsPermitted({
    isPermitted({ ldFlags, permissions, nosFlags }) {
      return Boolean(
        ldFlags['rate-limiting'] &&
          permissions.hasPermission(PermissionType.PermRateLimitWrite) &&
          nosFlags[NosFeature.RATE_LIMITING],
      );
    },
  });
  const canEditRateLimitRule = isPermitted || isOperator;

  const getLinkTo = useMemo(() => {
    if (canEditRateLimitRule) {
      return (row: RateLimitRule) =>
        makeDrawerLink(window.location, paths.drawers.EditRateLimitRulePage, {
          ruleUUID: row.UUID,
          companyName,
          networkSlug: network.slug,
        });
    }
    return undefined;
  }, [canEditRateLimitRule, companyName, network]);

  const rateLimitRules =
    useGraphQL(rateLimitRulesForNetworkQuery, {
      networkUUID: network.UUID,
    }).data?.rateLimitRulesForNetwork ?? [];

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...firewallCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.RateLimitingPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'Rate limiting',
            },
          },
        ]}
        icon="access-control"
        heading="Rate limiting"
        actions={
          <IsPermitted permissions={PermissionType.PermRateLimitWrite}>
            <AddRateLimitRuleButton
              companyName={companyName}
              networkSlug={network.slug}
              variant="secondary"
              size="small"
            />
          </IsPermitted>
        }
      />
      <PaneContent gutter="bottom">
        {rateLimitRules.length > 0 ? (
          <AutoTable
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            columns={columns}
            data={rateLimitRules}
            getLinkTo={getLinkTo}
            isRowSelected={(row) => row.UUID === drawerParams?.ruleUUID}
            onRowDeselect={closeDrawer}
          />
        ) : (
          <EmptyState
            icon="access-control"
            heading="You have no rate limit rules"
            action={
              <IsPermitted permissions={PermissionType.PermRateLimitWrite}>
                <AddRateLimitRuleButton companyName={companyName} networkSlug={network.slug} />
              </IsPermitted>
            }
          />
        )}
      </PaneContent>
    </Pane>
  );
}

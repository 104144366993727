import { Section, SectionContent, SectionHeader, Skeleton, space } from '@meterup/atto';

import { BASE_CHART_HEIGHT } from './utils';

export type ChartSkeletonProps = {
  height?: number;
};

export default function ChartSkeleton({ height = BASE_CHART_HEIGHT }: ChartSkeletonProps) {
  const calculatedHeight = height - space(32);

  return (
    <Section relation="stacked">
      <SectionHeader
        heading={<Skeleton width={space(120)} height={space(16)} radius={space(6)} />}
      />
      <SectionContent gutter="all">
        <Skeleton width="100%" height={`${calculatedHeight}px`} radius={space(10)} />
      </SectionContent>
    </Section>
  );
}

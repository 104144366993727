import {
  Button,
  colors,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  space,
} from '@meterup/atto';
import { checkDefinedOrThrow, notify } from '@meterup/common';
import { useMutation } from '@tanstack/react-query';
import { saveAs } from 'file-saver-es';
import { useContext } from 'react';
import { useNavigate } from 'react-router';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { logError } from '../../../utils/logError';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';

export default function RackElevationsActions({ exportRef }: { exportRef?: any }) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();

  const { dark } = useContext(ThemeContext);
  const handleExport = useMutation(async () => {
    const element = checkDefinedOrThrow(exportRef?.current);

    const { toBlob } = await import('html-to-image');

    // without this extra manual width the right padding is lost
    const width = element.scrollWidth + space(16);
    const height = element.scrollHeight;

    const blob = await toBlob(element, {
      backgroundColor: dark ? colors.bgApplicationDark.value : colors.bgApplicationLight.value,
      height,
      width,
      canvasHeight: height,
      canvasWidth: width,
      style: {
        overflow: 'unset',
        height: 'unset',
      },
    });

    if (!blob) throw new Error('Blob is null');
    return blob;
  });

  const openAttachHardware = () => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.AssignHardwareToNetworkPage, {
        networkSlug: network.slug,
        companyName,
      }),
    );
  };

  const openExportRacks = () => {
    handleExport.mutate(undefined, {
      onSuccess: (blob) => {
        let filename = 'Rack elevation.png';
        if (network) {
          filename = `${network.label} - ${filename}`;
          if (network.company?.name) {
            filename = `${network.company.name} - ${filename}`;
          }
        }

        saveAs(blob, filename);
      },
      onError: (err) => {
        logError(err, 'Failed saving blob');
        notify('Sorry, there was a problem exporting the diagram.', {
          variant: 'negative',
        });
      },
    });
  };

  return (
    <>
      <IsPermitted permissions={PermissionType.PermRackElevationWrite}>
        <Button
          as={ReactRouterLink}
          to={makeDrawerLink(window.location, paths.drawers.AddRackDrawerPage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add rack
        </Button>
      </IsPermitted>
      <DropdownMenu>
        <DropdownMenuButton arrangement="hidden-label" icon="overflow-horizontal">
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          {exportRef && (
            <DropdownMenuGroup>
              <DropdownMenuItem
                onSelect={openExportRacks}
                disabled={handleExport.isLoading}
                icon="download"
              >
                Export racks
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
          <IsPermitted
            anyPermissions={[
              PermissionType.PermNetworkDevicesWriteRestricted,
              PermissionType.PermHardwareDeviceAssign,
            ]}
          >
            <DropdownMenuGroup>
              <DropdownMenuItem onSelect={openAttachHardware} icon="attach">
                Attach hardware
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </IsPermitted>
        </DropdownMenuPopover>
      </DropdownMenu>
    </>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  HStack,
  space,
  VStack,
} from '@meterup/atto';
import React from 'react';

import { makePublicImageURL } from '../utils/makePublicImageURL';
import { BulletedList, BulletedListItem } from './BulletedList';

type GetNetworkDemoDialogProps = {
  state: OverlayTriggerState;
};

export default function GetNetworkDemoDialog({ state }: GetNetworkDemoDialogProps) {
  return (
    <Dialog state={state} width="40vw" maxWidth="450px" maxHeight="80vh">
      <DialogHeader heading="Meter Network" />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>
          <Alert
            variant="neutral"
            heading="Network as a Service for IT teams"
            icon="information"
            copy={
              <VStack spacing={space(6)}>
                <VStack spacing={space(2)}>
                  <p>
                    Meter Network is an end-to-end solution for internet, routing, switching, &
                    WiFi.
                  </p>
                  <BulletedList>
                    <BulletedListItem>Avoid expensive downtime</BulletedListItem>
                    <BulletedListItem>Reduce costs with a more efficient IT team</BulletedListItem>
                    <BulletedListItem>Save time with a fully-managed network</BulletedListItem>
                    <BulletedListItem>One unified dashboard</BulletedListItem>
                  </BulletedList>
                </VStack>
                <HStack spacing={space(8)}>
                  <Button
                    as="a"
                    target="_blank"
                    href="https://www.meter.com/schedule-demo"
                    icon="calendar"
                    variant="primary"
                    arrangement="leading-icon"
                  >
                    Get a Demo
                  </Button>
                  <Button
                    as="a"
                    target="_blank"
                    href="https://www.meter.com/network"
                    variant="secondary"
                  >
                    Learn More
                  </Button>
                </HStack>
              </VStack>
            }
          />
          <img
            src={makePublicImageURL('/dashboard/network-overview.png')}
            crossOrigin="anonymous"
            alt="Preview of the Network dashboard"
            title="Network Dashboard: Book a demo today!"
          />
        </VStack>
      </DialogContent>
    </Dialog>
  );
}

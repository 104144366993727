import { isDefined } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { fetchControllerSSIDs } from '../../../api/api';
import { useCurrentController } from '../../../providers/CurrentControllerProvider';
import { styled } from '../../../stitches';
import { NetworkJoinWidget } from '../../NetworkJoinWidget';

const DrawerContentStack = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
});

export function NetworkInfo() {
  const controller = useCurrentController();

  const { data: networkInfo } = useQuery(
    ['controller', controller, 'ssids'],
    () => fetchControllerSSIDs(controller),
    {
      suspense: true,
      retry: false,
    },
  );

  return isDefined(networkInfo) ? (
    <DrawerContentStack>
      {networkInfo.map((ssid) => (
        <NetworkJoinWidget ssid={ssid.ssid} password={ssid.password?.value ?? ''} key={ssid.ssid} />
      ))}
    </DrawerContentStack>
  ) : null;
}

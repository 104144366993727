import React, { useCallback } from 'react';

import { Body, HStack } from '@meterup/atto';
import { size } from 'lodash-es';
import { components, InputProps } from 'react-select';

import { styled } from '../../styled';

const InputContainer = styled(HStack, {
  '& > div > input': {
    fontSize: '$14 !important',
    lineHeight: '$14 !important',
    color: '$gray-500 !important',
    marginTop: -5,
    height: '$20 !important',
    flex: 1,
  },
  '& > div': {
    marginTop: '0 !important',
    marginBottom: '0 !important',
    paddingY: '0 !important',
    height: '$20 !important',
    display: 'flex',
  },
  marginLeft: -10,
});

const Label = styled('div', {
  marginRight: '$10',
  height: '$20',
});

export function NumberedListInput<T extends string | object | null | undefined>(
  props: InputProps<T, true>,
) {
  const [hasFocus, setHasFocus] = React.useState(false);
  const { value } = props.selectProps;
  const { onFocus: defaultOnFocus, onBlur: defaultOnBlur, ...restProps } = props;
  const nextListNumber = size(value) + 1;
  let label = `${nextListNumber}.`;
  if (size(value) === 0 && !hasFocus) {
    label = '1. Enter location here';
  }
  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!hasFocus) {
        setHasFocus(true);
      }
      if (defaultOnFocus) {
        defaultOnFocus(e);
      }
    },
    [defaultOnFocus, hasFocus],
  );
  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (hasFocus) {
        setHasFocus(false);
      }
      if (defaultOnBlur) {
        defaultOnBlur(e);
      }
    },
    [defaultOnBlur, hasFocus],
  );
  return (
    <InputContainer data-value={props.value || ''} justify="center" align="center">
      <Body as={Label}>{label}</Body>
      <components.Input {...restProps} onFocus={onFocus} onBlur={onBlur} autoComplete="off">
        {props.children}
      </components.Input>
    </InputContainer>
  );
}

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  Section,
  SectionContent,
  SectionHeader,
  useDialogState,
} from '@meterup/atto';
import {
  expectDefinedOrThrow,
  isDefined,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { TimePeriod } from '../../../../pages/network/insights/clients';
import {
  ClientHardwareWidget,
  WiredConnectionWidget,
  WirelessConnectionWidget,
  WirelessQualityWidget,
} from '../../../../../components/clients';
import { StickyObjectHeader } from '../../../../../components/Object/ObjectHeader';
import { fallbackAccessPointHardwareDeviceQuery } from '../../../../../components/Wireless/utils';
import { paths } from '../../../../../constants';
import { useClientDataByMAC } from '../../../../../hooks/useClientDataByMAC';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import { useVLANSFromClientMatcher } from '../../../../../hooks/useVLANClients';
import { Nav } from '../../../../../nav';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import {
  clientNameOrNull,
  getClientConnectionStatus,
  isWired,
  isWireless,
  manufacturerIconName,
} from '../../../../../utils/clientLists';
import { makeDrawerLink, makeLink } from '../../../../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/clients/:macAddress',
});

export default function ClientSummaryPage() {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const pingTestDialogProps = useDialogState();
  const { macAddress } = Nav.useRegionParams('drawer', paths.drawers.ClientSummaryPage)!;
  const api = useAPIClient();

  const navigate = useNavigate();
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);

  const [excludeMeterHardware] = useSearchParamsState<boolean>('excludeMeterHardware', true);
  const [currentTimePeriodOrUndefined] = useSearchParamsState<TimePeriod>('timePeriod');
  const lookbackMinutes = useMemo(() => {
    if (!currentTimePeriodOrUndefined) return undefined;

    const num = Number(currentTimePeriodOrUndefined);
    if (Number.isNaN(num) || !Number.isInteger(num)) return undefined;

    return num;
  }, [currentTimePeriodOrUndefined]);

  const network = useNetwork();
  const client = useClientDataByMAC(network, {
    filter: { macAddress, lookbackMinutes, excludeMeterHardware },
  });
  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));

  const { data: device } = useQuery(
    ['device', controllerName, client.apSerialNumber],
    () => api.accessPoint(client.apSerialNumber),
    {
      suspense: true,
      enabled: !isWOS2Enabled && isDefinedAndNotEmpty(client.apSerialNumber),
    },
  );

  const wos2Device = useGraphQL(
    fallbackAccessPointHardwareDeviceQuery,
    { serialNumber: client.apSerialNumber ?? '' },
    { enabled: isWOS2Enabled && isDefinedAndNotEmpty(client.apSerialNumber) },
  ).data?.hardwareDevice;

  const vlanMatcher = useVLANSFromClientMatcher(controllerName);

  const vlans = vlanMatcher(client);
  const vlan = vlans.length === 1 ? vlans?.[0] : null;

  return (
    <Drawer>
      <DrawerHeader
        icon="client"
        heading="Client"
        actions={
          <DropdownMenu>
            <DropdownMenuButton
              variant="secondary"
              icon="overflow-horizontal"
              arrangement="hidden-label"
            >
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover align="end">
              <DropdownMenuItem
                onSelect={() =>
                  navigate(
                    makeDrawerLink(window.location, paths.drawers.ClientRenamePage, {
                      macAddress,
                      controllerName,
                      companyName,
                    }),
                  )
                }
                icon="pencil"
              >
                Rename
              </DropdownMenuItem>
              {isDefined(vlan) && (
                <DropdownMenuItem
                  onSelect={() => {
                    navigate(
                      Nav.makeTo({
                        root: {
                          pathname: window.location.pathname,
                          search: `mac=${macAddress}&name=${client.clientName}&ip=${client.ip}`,
                        },
                        drawer: makeLink(paths.drawers.VLANStaticIPCreatePage, {
                          vlanName: vlan.name,
                          controllerName,
                          companyName,
                        }),
                      }),
                    );
                  }}
                  icon="ip-address"
                >
                  Pin IP address
                </DropdownMenuItem>
              )}
            </DropdownMenuPopover>
          </DropdownMenu>
        }
        onClose={useCloseDrawerCallback()}
      />
      <DrawerContent gutter="none">
        <StickyObjectHeader
          icon={manufacturerIconName(client)}
          name={clientNameOrNull(client) ?? '-'}
          status={getClientConnectionStatus(client)}
          link={makeLink(paths.pages.ClientDetailPage, {
            macAddress: client.macAddress!,
            controllerName,
            companyName,
          })}
          cta="View client"
        />
        <Section relation="stacked">
          <SectionHeader icon="information" heading="Metadata" />
          <SectionContent gutter="all">
            <ClientHardwareWidget client={client} />
          </SectionContent>
        </Section>
        {isWired(client) && (
          <WiredConnectionWidget
            relation="stacked"
            client={client}
            openPingTestDialog={pingTestDialogProps.state.open}
          />
        )}
        {isWireless(client) && (
          <>
            <WirelessConnectionWidget
              relation="stacked"
              client={client}
              device={device}
              virtualDeviceUUID={wos2Device?.virtualDeviceUUID}
              virtualDeviceLabel={wos2Device?.virtualDevice?.label ?? 'Access point'}
              openPingTestDialog={pingTestDialogProps.state.open}
            />
            <WirelessQualityWidget relation="stacked" client={client} />
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}

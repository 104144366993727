import type { MutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { AxiosAPIError, QuoteRequest, QuoteRequestCreateRequest } from '../index';
import { post } from '../axios';
import useCompanyNameFromParams from './useCompanyNameFromParams';
import useConnectionsQueryKey from './useConnectionsQueryKey';

export default function useCreateQuote(
  options: MutationOptions<QuoteRequest, AxiosAPIError, QuoteRequestCreateRequest> = {},
) {
  const companySID = useCompanyNameFromParams();
  const queryClient = useQueryClient();
  const queryKey = useConnectionsQueryKey();
  return useMutation<QuoteRequest, AxiosAPIError, QuoteRequestCreateRequest>(
    (vars) => post(`v1/companies/${companySID}/connect/quote-requests`, vars),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(queryKey);
      },
      ...options,
    },
  );
}

import { Drawer, DrawerHeader } from '@meterup/atto';

import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import RackElevationForm from './RackElevationForm';

export default function AddRackDrawer() {
  const close = useCloseDrawerCallback();

  return (
    <Drawer>
      <DrawerHeader icon="plus" heading="Add rack" onClose={close} />
      <RackElevationForm />
    </Drawer>
  );
}

/* eslint-disable react/no-unstable-nested-components */
import { Badge, Button, EmptyState, HStack, space } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable } from '@meterup/common';
import { isNumber } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import type { AddEditNaiRealmInput } from './utils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { NoValue } from '../../NoValue';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { type SSIDsQueryResult } from '../SSIDs/SSIDsUtils';
import { AddHotspot20Button } from './AddHotspot20Button';
import { Hotspot20Actions } from './Hotspot20Edit';
import { parseHs20RecordToNAIRealmFormVals, useHs20ValidTypes } from './utils';

const builder = createColumnBuilder<SSIDsQueryResult>();
const naiRealmsBuilder = createColumnBuilder<AddEditNaiRealmInput>();

const nestedColumns = [
  naiRealmsBuilder.data((row) => row.hs20NaiRealmName, {
    id: 'naiRealm',
    header: 'NAI realm',
    meta: {
      isLeading: true,
    },
    /* eslint-disable react/jsx-no-useless-fragment */
    cell: ({ row }) => <>{row.hs20NaiRealmName}</>,
  }),
  naiRealmsBuilder.data((row) => row.hs20EapMethods.join(','), {
    id: 'methods',
    header: 'Methods',
    cell: ({ row }) => (
      <HStack align="center" spacing={space(4)}>
        {row.hs20EapMethods.map((method) => (
          <Badge key={method.hs20EapMethodId} size="small" variant="neutral">
            {method.hs20EapMethodId}
          </Badge>
        ))}
      </HStack>
    ),
  }),
];

function Hotspot20sListNested({ data: ssid }: { data: SSIDsQueryResult }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const naiRealms = parseHs20RecordToNAIRealmFormVals(ssid.hs20NaiRealms);
  const hsNaiRealmsEditParams = Nav.useRegionParams(
    'drawer',
    paths.drawers.Hotspot20NAIRealmEditPage,
  );
  const isRowActive = useCallback(
    () => ssid.UUID === hsNaiRealmsEditParams?.uuid,
    [hsNaiRealmsEditParams?.uuid, ssid.UUID],
  );
  const { hasPermission } = usePermissions();
  const canEditCaptivePortal = hasPermission(PermissionType.PermCaptivePortalWrite);

  if (naiRealms?.length) {
    return (
      <AutoTable
        isNested
        columns={nestedColumns}
        data={naiRealms}
        isRowSelected={isRowActive}
        getLinkTo={() =>
          canEditCaptivePortal
            ? makeDrawerLink(window.location, paths.drawers.Hotspot20NAIRealmEditPage, {
                companyName,
                networkSlug: network.slug,
                uuid: ssid.UUID,
              })
            : undefined
        }
      />
    );
  }
  return (
    <EmptyState
      icon="hotspot-2.0"
      heading="You have no NAI Realms for this Hotspot 2.0 profile"
      action={
        canEditCaptivePortal || isOperator ? (
          <Button
            condense
            size="small"
            as={ReactRouterLink}
            to={makeDrawerLink(window.location, paths.drawers.Hotspot20NAIRealmEditPage, {
              companyName,
              networkSlug: network.slug,
              uuid: ssid.UUID,
            })}
            variant="secondary"
            arrangement="leading-icon"
            icon="plus"
          >
            Add NAI realm
          </Button>
        ) : undefined
      }
    />
  );
}

export default function Hotspot20sList({ ssids }: { ssids: SSIDsQueryResult[] }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const hsEditdrawerParams = Nav.useRegionParams('drawer', paths.drawers.Hotspot20EditPage);

  const isRowActive = useCallback(
    (row: SSIDsQueryResult) => row.UUID === hsEditdrawerParams?.uuid,
    [hsEditdrawerParams?.uuid],
  );

  const { venueGroupsToTypesMap, accessNetworkTypesMap } = useHs20ValidTypes();
  const { hasPermission } = usePermissions();
  const canEditCaptivePortal = hasPermission(PermissionType.PermCaptivePortalWrite);

  const columns = useMemo(
    () => [
      builder.data((row) => row.ssid, {
        id: 'ssid',
        header: 'SSID',
        cell: ({ row }) => (
          <Badge
            key={row.ssid}
            arrangement="leading-icon"
            icon="ssid"
            size="small"
            variant="neutral"
          >
            {row.ssid}
          </Badge>
        ),
      }),
      builder.data((row) => (row?.hs20OperatorNames?.length ? row.hs20OperatorNames[0] : ''), {
        id: 'operatorName',
        header: 'Operator name',
        meta: {
          isLeading: true,
        },
        cell: ({ row }) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {row?.hs20OperatorNames?.length ? (
              row.hs20OperatorNames[0].replace('eng:', '')
            ) : (
              <NoValue />
            )}
          </>
        ),
      }),
      builder.data((row) => (row?.hs20VenueNames?.length ? row.hs20VenueNames[0] : ''), {
        id: 'venueName',
        header: 'Venue name',
        cell: ({ row }) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {row?.hs20VenueNames?.length ? row.hs20VenueNames[0].replace('eng:', '') : <NoValue />}
          </>
        ),
      }),
      builder.data((row) => row.hs20VenueType, {
        id: 'venueType',
        header: 'Venue type',
        cell: ({ row }) => {
          let venueType = null;
          if (isNumber(row?.hs20VenueType) && isNumber(row?.hs20VenueGroup)) {
            venueType = venueGroupsToTypesMap
              .get(row.hs20VenueGroup)
              ?.types.find((type) => type.type === row.hs20VenueType)?.description;
          }
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return venueType ? (
            <Badge key={row.hs20VenueType} size="small" variant="neutral">
              {venueType}
            </Badge>
          ) : (
            <NoValue />
          );
        },
      }),
      builder.data((row) => row.hs20AccessNetworkType, {
        id: 'networkType',
        header: 'Network type',
        cell: ({ row }) => {
          let networkType = null;
          if (
            isNumber(row?.hs20AccessNetworkType) &&
            accessNetworkTypesMap[row.hs20AccessNetworkType]
          ) {
            networkType = accessNetworkTypesMap[row.hs20AccessNetworkType].description;
          }
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return networkType ? (
            <Badge key={row.hs20AccessNetworkType} size="small" variant="neutral">
              {networkType}
            </Badge>
          ) : (
            <NoValue />
          );
        },
      }),
      builder.data((row) => (row?.hs20DomainNames ? row.hs20DomainNames.join(',') : ''), {
        id: 'domainList',
        header: 'Domain list',
        cell: ({ row }) => (
          <HStack align="center" spacing={space(4)}>
            {row?.hs20DomainNames?.map((domain) => (
              <Badge key={domain} size="small" variant="neutral">
                {domain}
              </Badge>
            ))}
            {!row?.hs20DomainNames?.length && <NoValue />}
          </HStack>
        ),
      }),
      builder.data(
        (row) => (row?.hs20RoamingConsortiumOIs ? row.hs20RoamingConsortiumOIs.join(',') : ''),
        {
          id: 'roamingConsortiumOIs',
          header: 'Roaming consortium OIs',
          cell: ({ row }) => (
            <HStack align="center" spacing={space(4)}>
              {row?.hs20RoamingConsortiumOIs?.map((domain) => (
                <Badge key={domain} arrangement="leading-icon" size="small" variant="neutral">
                  {domain}
                </Badge>
              ))}
              {!row?.hs20RoamingConsortiumOIs?.length && <NoValue />}
            </HStack>
          ),
        },
      ),
      builder.data((row) => (row?.hs20MccMncPairs ? row.hs20MccMncPairs.join(',') : ''), {
        id: 'mccMncs',
        header: 'MCC/MNCs',
        cell: ({ row }) => (
          <HStack align="center" spacing={space(4)}>
            {row?.hs20MccMncPairs?.map((domain) => (
              <Badge key={domain} arrangement="leading-icon" size="small" variant="neutral">
                {domain}
              </Badge>
            ))}
            {!row?.hs20MccMncPairs?.length && <NoValue />}
          </HStack>
        ),
      }),
      builder.display({
        id: 'actions',
        meta: {
          alignment: 'end',
        },
        cell: ({ row }) => <Hotspot20Actions ssid={row} />,
      }),
    ],
    [venueGroupsToTypesMap, accessNetworkTypesMap],
  );

  if (ssids.length) {
    return (
      <AutoTable
        columns={columns}
        data={ssids}
        isRowSelected={isRowActive}
        getLinkTo={(row) =>
          canEditCaptivePortal
            ? makeDrawerLink(window.location, paths.drawers.Hotspot20EditPage, {
                companyName,
                networkSlug: network.slug,
                uuid: row.UUID,
              })
            : undefined
        }
        renderSubTable={Hotspot20sListNested}
      />
    );
  }
  return (
    <EmptyState
      icon="hotspot-2.0"
      heading="You have no Hotspot 2.0 profiles"
      action={isOperator ? <AddHotspot20Button /> : undefined}
    />
  );
}

import { Button } from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../../constants';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';
import { IntegrationsValidTabs } from './IntegrationsTabs';

export default function IntegrationsActions({ activeTab }: { activeTab: IntegrationsValidTabs }) {
  const companyName = useCurrentCompany();
  return (
    <>
      {activeTab === IntegrationsValidTabs.Services && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.ServicesAddPage, {
            companyName,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add service
        </Button>
      )}
      {activeTab === IntegrationsValidTabs.Webhooks && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.WebhooksAddPage, {
            companyName,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add webhook
        </Button>
      )}
      {activeTab === IntegrationsValidTabs.Receivers && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.ReceiversAddPage, {
            companyName,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add receiver
        </Button>
      )}
    </>
  );
}

import { backgrounds, Button, darkThemeSelector, styled } from '@meterup/atto';
import { colors } from '@meterup/atto/src/stitches.config';
import React, { useState } from 'react';

import { QRCode } from '../../QRCode';

const QRCodeListItemOverlay = styled('div', {
  position: 'absolute',
  zIndex: 3,
  inset: '16px',
  left: '50%',
  marginLeft: '-60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '120px',
  backdropFilter: 'blur(4px)',
  borderRadius: '16px',
});

const QRCodeListItemContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$16',
  background: backgrounds.dottedLight,
  backgroundColor: colors.bgNeutralLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    background: backgrounds.dottedDark,
    backgroundColor: colors.bgNeutralDark,
  },
});

export function QRCodeListItem({ text }: { text: string }) {
  const [showQRCode, setShowQRCode] = useState(false);

  return (
    <QRCodeListItemContainer>
      {!showQRCode && (
        <QRCodeListItemOverlay>
          <Button
            variant="secondary"
            arrangement="leading-icon"
            icon="eye-open"
            size="medium"
            onClick={() => setShowQRCode(!showQRCode)}
          >
            Show
          </Button>
        </QRCodeListItemOverlay>
      )}
      <QRCode text={text} />
    </QRCodeListItemContainer>
  );
}

import React, { useMemo } from 'react';

import { ColumnSizingState } from '@tanstack/react-table';

type DataViewContentProps = {
  children?: React.ReactNode;
  columnSizingState?: ColumnSizingState;
  renderContent?: (columnSizingState: ColumnSizingState) => React.ReactNode;
};
export default function DataViewContent({
  children: childrenProp,
  columnSizingState,
  renderContent,
}: DataViewContentProps) {
  const children = useMemo(() => {
    if (renderContent) {
      // @ts-ignore
      return renderContent(columnSizingState);
    }
    return childrenProp;
  }, [childrenProp, columnSizingState, renderContent]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

import type { AdhocControllerEvent } from '@meterup/proto/esm/api';
import { Badge, EmptyState, Icon, space } from '@meterup/atto';
import { AutoTable, isDefinedAndNotEmpty } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';

import { NoValue } from '../../../../../components/NoValue';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { parseEvent } from '../../../../../utils/events';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

const builder = createColumnBuilder<AdhocControllerEvent>();

export const columns = [
  builder.data((d) => parseEvent(d).badge ?? '', {
    id: 'badge',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Type of event',
      },
    },
    cell: (props) => {
      if (props.value === 'warning') {
        return (
          <Badge
            arrangement="hidden-label"
            size="small"
            icon="attention"
            ends="pill"
            variant="neutral"
          >
            Warning
          </Badge>
        );
      }
      if (props.value === 'attention') {
        return (
          <Badge
            arrangement="hidden-label"
            size="small"
            icon="warning"
            ends="pill"
            variant="negative"
          >
            Attention
          </Badge>
        );
      }
      return null;
    },
  }),
  builder.data((d) => parseEvent(d).type, {
    id: 'event',
    header: 'Event',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => parseEvent(d).short_description, {
    id: 'message',
    header: 'Message',
  }),
  builder.data((d) => d.created_at ?? '', {
    id: 'time',
    header: `Time (${DateTime.local().toFormat('ZZZZZ')})`,
    meta: {
      alignment: 'end',
    },
    cell: (d) =>
      isDefinedAndNotEmpty(d.value) ? (
        <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</>
      ) : (
        <NoValue />
      ),
  }),
];

export default function EventsListTable() {
  const controller = useCurrentControllerData();
  const companyName = useCurrentCompany();
  const controllerName = controller.name;
  const api = useAPIClient();

  const params = Nav.useRegionParams('drawer', paths.drawers.EventSummaryPage);

  const [querySearch] = useSearchParamsState<string>('search', '');

  const eventsData = useQuery(
    ['controller', controller.name, 'logs'],
    async () => api.controllerEvents(),
    {
      suspense: true,
    },
  );

  const events = eventsData.data ?? [];

  const linkTo = (row: AdhocControllerEvent) =>
    makeDrawerLink(window.location, paths.drawers.EventSummaryPage, {
      companyName,
      controllerName,
      eventSid: row.sid,
    });

  const rowSelected = useCallback(
    (row: AdhocControllerEvent) => params?.eventSid === row.sid,
    [params?.eventSid],
  );

  return events.length > 0 ? (
    <AutoTable
      key={JSON.stringify(events)}
      columns={columns}
      data={events}
      globalFilter={querySearch}
      getLinkTo={linkTo}
      isRowSelected={rowSelected}
    />
  ) : (
    <EmptyState icon="log" heading="No recent events" />
  );
}

import { useCallback } from 'react';

import { isNumber, isString } from 'lodash-es';
import { NavigateFunction, NavigateOptions, To, useNavigate } from 'react-router';

type Brand<K, T> = K & { __brand: T };

type AbsoluteURL = Brand<string, 'AbsoluteURL'>;

export function isAbsoluteURL(url: any): url is AbsoluteURL {
  if (!isString(url)) {
    return false;
  }
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

export function isAbsoluteTo(to: To | number): to is AbsoluteURL {
  if (isString(to)) {
    return isAbsoluteURL(to);
  }
  return false;
}

export function useSafeNavigate(): NavigateFunction {
  const navigate = useNavigate();

  return useCallback<NavigateFunction>(
    (to, options?: NavigateOptions): void => {
      if (isNumber(to)) {
        return navigate(to);
      }
      if (isAbsoluteTo(to)) {
        window.location.href = to;
        return undefined as void;
      }
      return navigate(to, options);
    },
    [navigate],
  );
}

import {
  Alert,
  Badge,
  FieldContainer,
  HStack,
  PrimaryField,
  SecondaryField,
  Section,
  SectionHeader,
  Select,
  SelectItem,
  space,
  Textarea,
  TextInput,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useFormikContext } from 'formik';

import type { AccessPoint, ValidAccessPointParams, ValidUpdateVirtualDeviceParams } from './utils';
import { RadioBand } from '../../gql/graphql';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useValidChannels } from '../../hooks/useValidChannels';
import { FieldProvider, NumberFieldProvider } from '../Form/FieldProvider';
import { ToggleField } from '../Form/Fields';
import { radioBandToField, radioBandToHuman, RadioProfilesQuery } from './RadioProfiles/utils';

export function AccessPointNameField() {
  return (
    <FieldContainer>
      <FieldProvider name="label">
        <PrimaryField label="Label" element={<TextInput />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function AccessPointDescriptionField() {
  return (
    <FieldContainer>
      <FieldProvider name="description">
        <PrimaryField optional label="Description" element={<Textarea />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function AccessPointConsoleEnabledField() {
  return (
    <FieldContainer>
      <ToggleField name="isConsoleEnabled" label="Enable console port" internal />
    </FieldContainer>
  );
}

export function AccessPointLEDField() {
  return (
    <FieldContainer>
      <ToggleField name="areLEDsEnabled" label="Enable LEDs" />
    </FieldContainer>
  );
}

export function RadioProfileUUIDField({
  networkUUID,
  supportsIndeterminate,
}: {
  networkUUID: string;
  supportsIndeterminate?: boolean;
}) {
  const radioProfiles = useGraphQL(RadioProfilesQuery, { networkUUID }).data
    ?.radioProfilesForNetwork;
  expectDefinedOrThrow(radioProfiles, new ResourceNotFoundError('Unable to load radio profiles'));

  const { values } = useFormikContext<ValidUpdateVirtualDeviceParams>();
  const indeterminate = supportsIndeterminate && values.radioProfileUUID == null;

  return (
    <FieldContainer>
      <FieldProvider name="radioProfileUUID">
        <PrimaryField
          label="Radio profile"
          element={
            <Select
              width="100%"
              indeterminate={indeterminate}
              placeholder="Select a radio profile..."
              showPlaceholderForIndeterminate
              canClearValue={supportsIndeterminate}
            >
              {radioProfiles.map((r) => (
                <SelectItem key={r.UUID}>{r.name}</SelectItem>
              ))}
            </Select>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export function BandField({
  band,
  channelWidth,
  autoChannelEnabled,
  supportsIndeterminate,
}: {
  band: RadioBand;
  channelWidth: number | null | undefined;
  autoChannelEnabled: boolean;
  supportsIndeterminate?: boolean;
}) {
  const { values } = useFormikContext<ValidAccessPointParams>();
  const network = useNetwork();
  const validChannels = useValidChannels(network.UUID);
  let availableChannels: number[];

  if (band === RadioBand.Band_2_4G) {
    availableChannels = validChannels[RadioBand.Band_2_4G];
  } else {
    availableChannels = validChannels[RadioBand.Band_5G];

    if (channelWidth && channelWidth === 80) {
      availableChannels = availableChannels.filter((i) => i !== 165);
    }
  }

  const availableTransmitPower = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  ];

  return (
    <Section>
      <FieldContainer>
        <SectionHeader heading={`${radioBandToHuman(band)}`} />
        <NumberFieldProvider
          name={`radioSettings.${radioBandToField(band)}PrimaryChannel`}
          defaultValue={null}
        >
          <SecondaryField
            label={
              <HStack align="center" spacing={space(6)}>
                Primary channel{' '}
                {autoChannelEnabled && (
                  <Badge variant="neutral" size="small">
                    Auto
                  </Badge>
                )}
              </HStack>
            }
            element={
              <Select
                width="100%"
                disabled={autoChannelEnabled}
                indeterminate={
                  supportsIndeterminate
                    ? values.radioSettings?.[
                        `${radioBandToField(band)}PrimaryChannel` as keyof NonNullable<
                          typeof values
                        >['radioSettings']
                      ] == null
                    : undefined
                }
                canClearValue={supportsIndeterminate && !autoChannelEnabled}
              >
                {availableChannels.map((i) => (
                  <SelectItem key={i.toString()}>{i.toString()}</SelectItem>
                ))}
              </Select>
            }
          />
        </NumberFieldProvider>
        <NumberFieldProvider
          name={`radioSettings.${radioBandToField(band)}TransmitPowerdBm`}
          defaultValue={null}
        >
          <SecondaryField
            label="Transmit power"
            element={
              <Select
                width="100%"
                indeterminate={
                  supportsIndeterminate
                    ? values.radioSettings?.[
                        `${radioBandToField(band)}TransmitPowerdBm` as keyof NonNullable<
                          typeof values
                        >['radioSettings']
                      ] == null
                    : undefined
                }
                canClearValue={supportsIndeterminate}
              >
                {availableTransmitPower.map((i) => (
                  <SelectItem key={i.toString()}>{i.toString()} dBm</SelectItem>
                ))}
              </Select>
            }
          />
        </NumberFieldProvider>
      </FieldContainer>
    </Section>
  );
}

export function BandFields({
  radioProfile,
  supportsIndeterminate,
}: {
  radioProfile: (AccessPoint & { __typename: 'AccessPointVirtualDevice' })['radioProfile'];
  supportsIndeterminate?: boolean;
}) {
  const { values } = useFormikContext<ValidAccessPointParams>();

  if (radioProfile && values.radioProfileUUID !== radioProfile.UUID) {
    return (
      <Alert
        icon="warning"
        variant="attention"
        heading="Radio profile updated"
        copy="You can modify specific radio settings after the new radio profile is applied to the access point."
      />
    );
  }

  return (
    <>
      {radioProfile?.band2_4GIsEnabled && (
        <BandField
          band={RadioBand.Band_2_4G}
          channelWidth={radioProfile.band2_4GChannelWidthMHz}
          autoChannelEnabled={radioProfile.band2_4GAutoChannelIsEnabled}
          supportsIndeterminate={supportsIndeterminate}
        />
      )}
      {radioProfile?.band5GIsEnabled && (
        <BandField
          band={RadioBand.Band_5G}
          channelWidth={radioProfile.band5GChannelWidthMHz}
          autoChannelEnabled={radioProfile.band5GAutoChannelIsEnabled}
          supportsIndeterminate={supportsIndeterminate}
        />
      )}
    </>
  );
}

import React from 'react';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import RadioProfileCreateDrawer from '../../../../../components/Wireless/RadioProfiles/RadioProfileCreateDrawer';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/radio-profiles/create',
  title: 'Add radio profile - Radio profiles - Wireless',
});

export default function RadioProfileCreatePage() {
  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        permissions.hasPermission(PermissionType.PermRadioProfileWrite)
      }
      should404OnAccessDenied
    >
      <RadioProfileCreateDrawer />
    </IsPermitted>
  );
}

import { Support } from '../../../../components/Tools/Support/Support';

export const Meta = () => ({
  path: '/org/:companyName/support',
  title: 'Support',
});

export default function SupportPage() {
  return <Support />;
}

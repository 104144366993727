import { Button, DrawerContent, DrawerFooter } from '@meterup/atto';
import { notify } from '@meterup/common';
import { makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { RackElevation } from './utils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { LabelField, RackMountUnitCountField } from './Fields';
import {
  createRackElevationMutation,
  mutationErrorMessage,
  rackElevationsQuery,
  updateRackElevationMutation,
} from './utils';

export const validElevationParams = z.object({
  label: z.string().nonempty(),
  rackMountUnitCount: z.coerce.number().positive(),
});

export type ValidElevationParams = z.infer<typeof validElevationParams>;

export default function RackElevationForm({ elevation }: { elevation?: RackElevation }) {
  const network = useNetwork();
  const close = useCloseDrawerCallback();
  const mutation = useGraphQLMutation(createRackElevationMutation);
  const queryClient = useQueryClient();

  const handleCreate = (v: ValidElevationParams) => {
    mutation.mutate(
      {
        networkUUID: network.UUID,
        label: v.label,
        rackMountUnitCount: v.rackMountUnitCount,
      },
      {
        onSuccess: () => {
          notify('Successfully created rack elevation.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(rackElevationsQuery, { networkUUID: network.UUID }),
          );
          close();
        },
        onError: () => {
          notify('There was an error creating this rack elevation. Please try again.', {
            variant: 'negative',
          });
        },
      },
    );
  };

  const queryKey = useMemo(
    () => makeQueryKey(rackElevationsQuery, { networkUUID: network.UUID }),
    [network.UUID],
  );

  const updateMutation = useGraphQLMutation(updateRackElevationMutation);
  const handleUpdate = (v: ValidElevationParams) => {
    if (!elevation || updateMutation.isLoading) return;
    updateMutation.mutate(
      {
        UUID: elevation.UUID,
        input: {
          label: v.label,
          rackMountUnitCount: v.rackMountUnitCount,
        },
      },
      {
        onSuccess: () => {
          notify('Successfully updated rack elevation.', { variant: 'positive' });
          queryClient.invalidateQueries(queryKey);
          close();
        },
        onError: (error) => {
          notify(mutationErrorMessage('There was an error updating this rack elevation', error), {
            variant: 'negative',
          });
        },
      },
    );
  };

  return (
    <Formik<ValidElevationParams>
      validationSchema={toFormikValidationSchema(validElevationParams)}
      initialValues={{
        label: elevation?.label ?? '',
        rackMountUnitCount: elevation?.rackMountUnitCount ?? 45,
      }}
      onSubmit={elevation ? handleUpdate : handleCreate}
    >
      <Form>
        <DrawerContent>
          <LabelField />
          <RackMountUnitCountField />
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" variant="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </>
          }
        />
      </Form>
    </Formik>
  );
}

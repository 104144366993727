import type { OverlayTriggerState } from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { deleteVirtualDeviceMutation } from '../../Devices/utils';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import { SwitchesQuery, SwitchQuery } from './utils';

export default function SwitchDeleteDialog({
  state,
  uuid,
}: {
  state: OverlayTriggerState;
  uuid: string;
}) {
  const network = useNetwork();
  const queryClient = useQueryClient();

  const closeDrawer = useCloseDrawerCallback();

  const virtualDevice = useGraphQL(SwitchQuery, { uuid }).data?.virtualDevice;
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Switch not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('Switch not found');
  }

  const deleteMutation = useGraphQLMutation(deleteVirtualDeviceMutation);
  const handleDelete = useCallback(() => {
    if (deleteMutation.isLoading) return;

    deleteMutation.mutate(
      { uuid },
      {
        onSuccess: () => {
          notify('Successfully deleted switch.', { variant: 'positive' });
          queryClient.invalidateQueries(makeQueryKey(SwitchesQuery, { networkUUID: network.UUID }));
          closeDrawer();
        },
        onError: (err) => {
          notify(
            `There was an error deleting this switch ${getGraphQLErrorMessageOrEmpty(
              err,
            )}. Please make sure it is removed from any rack elevations.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [deleteMutation, closeDrawer, network.UUID, queryClient, uuid]);

  return (
    <DeleteDialog
      state={state}
      label={virtualDevice?.label}
      handleDelete={handleDelete}
      alert={{
        heading: 'Deleting a switch can impact the network',
        copy: 'Any connected devices will no longer use this switch.',
      }}
    />
  );
}

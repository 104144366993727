import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, isDefined, notify } from '@meterup/common';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ValidStaticClient } from './form_data';
import { ClientHardwareWidget } from '../../../../../../components/clients';
import {
  DiscardChangesButton,
  DrawerFormSaveButton,
} from '../../../../../../components/form_buttons';
// import { FormikConditional } from '../../../../../components/FormikConditional';
import { paths } from '../../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../../hooks/useControllerConfig';
import { useVLANClientByMAC } from '../../../../../../hooks/useVLANClients';
import { useVLANConfigModel } from '../../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../../nav';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../../stitches';
import { makeDrawerLink } from '../../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../../utils/withZodSchema';
import { validStaticClient } from './form_data';
import { IPField, MACField, NameField } from './form_fields';
import { useUpsertStaticClientMutation } from './mutations';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/static/:macAddress/edit',
});

export default function VLANStaticIPEditPage() {
  const { vlanName, macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VLANStaticIPEditPage),
  );

  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const vlan = useVLANConfigModel(controllerName, vlanName);
  const staticClient = vlan.getStaticClientByMacAddress(macAddress);

  expectDefinedOrThrow(staticClient, new Error(`Static assignment for "${macAddress}" not found`));

  const companyName = useCurrentCompany();
  const configModel = useControllerConfig(controllerName);
  const client = useVLANClientByMAC(controller, vlanName, macAddress);
  const navigate = useNavigate();
  const mutation = useUpsertStaticClientMutation(controllerName, configModel, vlan);

  return (
    <Formik<ValidStaticClient>
      validate={withZodSchema(validStaticClient)}
      initialValues={{
        existingIPs: vlan.staticClients
          .filter((d) => d.macAddress !== macAddress)
          .map((d) => d.ipAddress),
        existingNames: vlan.staticClients
          .filter((d) => d.macAddress !== macAddress)
          .map((d) => d.name),
        subnet: vlan.subnetString,
        name: staticClient.name,
        mac: staticClient.macAddress,
        ip: staticClient.ipAddress,
      }}
      onSubmit={(values) => {
        mutation.mutate(values, {
          onSuccess: () => {
            notify('Saved', { variant: 'positive' });
          },
          onError: () => {
            notify('Failed to save changes', { variant: 'negative' });
          },
        });
      }}
      enableReinitialize
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader
            icon="ip-address"
            heading="Edit static IP"
            actions={
              <DropdownMenu>
                <DropdownMenuButton
                  variant="secondary"
                  icon="overflow-horizontal"
                  arrangement="hidden-label"
                >
                  Actions
                </DropdownMenuButton>

                <DropdownMenuPopover align="end">
                  <DropdownMenuItem
                    onSelect={() =>
                      navigate(
                        makeDrawerLink(window.location, paths.drawers.VLANStaticIPRemovePage, {
                          controllerName,
                          companyName,
                          vlanName,
                          macAddress,
                        }),
                      )
                    }
                    icon="trash-can"
                  >
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuPopover>
              </DropdownMenu>
            }
            onClose={useCloseDrawerCallback()}
          />
          <DrawerContent>
            {isDefined(client) && <ClientHardwareWidget client={client} />}
            <NameField />
            <MACField />
            <IPField vlan={vlan} />
          </DrawerContent>
          {/* <FormikConditional<ValidStaticClient> condition={(values, ctx) => ctx.dirty}> */}
          <DrawerFooter
            actions={
              <>
                <DiscardChangesButton />
                <DrawerFormSaveButton />
              </>
            }
          />
          {/* </FormikConditional> */}
        </Drawer>
      </StyledForm>
    </Formik>
  );
}

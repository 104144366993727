import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { Navigate } from 'react-router-dom';

import SwitchList from '../../../../../components/Hardware/Switches/SwitchList';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/switches/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Switches - Hardware',
});

export default function SwitchListPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const activeController = useActiveControllerForNetwork(network);
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);

  const controllerSerialNumber = activeController?.hardwareDevice?.serialNumber;

  if (!isOperator && !isSOSEnabled) {
    if (controllerSerialNumber) {
      return (
        <Navigate
          to={makeLink(paths.pages.LegacySwitchListPage, {
            companyName,
            controllerName: controllerSerialNumber,
          })}
        />
      );
    }

    return (
      <Navigate
        to={makeLink(paths.pages.IndividualNetworkRootPage, {
          companyName,
          networkSlug: network.slug,
        })}
      />
    );
  }

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
            nosFlags[NosFeature.SOS],
        )
      }
      should404OnAccessDenied
      path={Meta().path}
    >
      <SwitchList />
    </IsPermitted>
  );
}

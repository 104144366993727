import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { toFormikValidate } from 'zod-formik-adapter';

import type { ValidAccessPointParams } from '../../Wireless/utils';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { styled } from '../../../stitches';
import { HardwareDeviceField } from '../../Devices/Fields';
import { updateVirtualDeviceMutation } from '../../Devices/utils';
import IsPermitted from '../../permissions/IsPermitted';
import {
  AccessPointConsoleEnabledField,
  AccessPointDescriptionField,
  AccessPointLEDField,
  AccessPointNameField,
  BandFields,
  RadioProfileUUIDField,
} from '../../Wireless/Fields';
import {
  AccessPointQuery,
  AccessPointsQuery,
  UpdateAccessPointInputSchema,
} from '../../Wireless/utils';
import { getAccessPointStatus } from './AccessPoint';
import { AccessPointActions } from './AccessPointActions';

type AccessPointEditDrawerProps = {
  uuid: string;
};

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

function AccessPointEdit({ uuid }: AccessPointEditDrawerProps) {
  const network = useNetwork();
  const virtualDeviceMutation = useGraphQLMutation(updateVirtualDeviceMutation);
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const virtualDevice = useGraphQL(AccessPointQuery, { uuid, includeUptime }).data?.virtualDevice;
  const closeDrawer = useCloseDrawerCallback();
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Access point not found'));
  if (
    virtualDevice.networkUUID !== network.UUID ||
    virtualDevice.__typename !== 'AccessPointVirtualDevice'
  ) {
    throw new ResourceNotFoundError('Access point not found on this network');
  }

  const handleSubmit = (v: ValidAccessPointParams) => {
    virtualDeviceMutation.mutate(
      {
        uuid: virtualDevice.UUID,
        input: {
          label: v.label,
          description: v.description,
          enableConsolePort: v.isConsoleEnabled,
          enableLEDDarkMode: !v.areLEDsEnabled,
          radioProfileUUID: v.radioProfileUUID,
          radioSettings: {
            band2_4GPrimaryChannel: v.radioSettings!.band2_4GPrimaryChannel,
            band2_4GTransmitPowerdBm: v.radioSettings!.band2_4GTransmitPowerdBm,
            band5GPrimaryChannel: v.radioSettings!.band5GPrimaryChannel,
            band5GTransmitPowerdBm: v.radioSettings!.band5GTransmitPowerdBm,
          },
        },
      },
      {
        onSuccess: () => {
          notify('Successfully updated access point.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(AccessPointsQuery, { networkUUID: network.UUID, includeUptime }),
          );
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(`There was an error updating this access point: ${gqlErr?.message}`, {
            variant: 'negative',
          });
        },
      },
    );
  };

  return (
    <Formik<ValidAccessPointParams>
      validate={toFormikValidate(UpdateAccessPointInputSchema)}
      initialValues={{
        label: virtualDevice.label,
        description: virtualDevice.description,
        isConsoleEnabled: virtualDevice.isConsoleEnabled,
        areLEDsEnabled: !virtualDevice.isLEDDarkModeEnabled,
        radioProfileUUID: virtualDevice.radioProfile?.UUID ?? '',
        radioSettings: {
          band2_4GPrimaryChannel: virtualDevice.radioSettings.band2_4GPrimaryChannel,
          band2_4GTransmitPowerdBm: virtualDevice.radioSettings.band2_4GTransmitPowerdBm,
          band5GPrimaryChannel: virtualDevice.radioSettings.band5GPrimaryChannel,
          band5GTransmitPowerdBm: virtualDevice.radioSettings.band5GTransmitPowerdBm,
        },
      }}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <DrawerContent>
          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
                  nosFlags[NosFeature.WOS2],
              )
            }
          >
            <AccessPointNameField />
            <AccessPointDescriptionField />
            <AccessPointLEDField />
            <RadioProfileUUIDField networkUUID={network.UUID} />
            <BandFields radioProfile={virtualDevice.radioProfile} />
          </IsPermitted>

          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
                  nosFlags[NosFeature.WOS2],
              )
            }
          >
            <HardwareDeviceField
              virtualDevice={virtualDevice}
              onSuccess={async () => {
                queryClient.invalidateQueries(
                  makeQueryKey(AccessPointsQuery, {
                    networkUUID: network.UUID,
                    includeUptime,
                  }),
                );
                return queryClient.invalidateQueries(
                  makeQueryKey(AccessPointQuery, { uuid, includeUptime }),
                );
              }}
              internal
            />
            <AccessPointConsoleEnabledField />
          </IsPermitted>
        </DrawerContent>
        <IsPermitted
          isPermitted={({ permissions, nosFlags }) =>
            Boolean(
              permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
                nosFlags[NosFeature.WOS2],
            )
          }
        >
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </IsPermitted>
      </StyledForm>
    </Formik>
  );
}

export default function AccessPointEditDrawer({ uuid }: AccessPointEditDrawerProps) {
  const closeDrawer = useCloseDrawerCallback();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const virtualDevice = useGraphQL(
    AccessPointQuery,
    { uuid, includeUptime },
    { useErrorBoundary: false },
  ).data?.virtualDevice;
  const status = virtualDevice && getAccessPointStatus(virtualDevice);

  return (
    <Drawer>
      <DrawerHeader
        icon="pencil"
        heading="Edit access point"
        actions={
          <AccessPointActions
            view="edit"
            uuid={uuid}
            serialNumber={
              !virtualDevice?.hardwareDevice?.serialNumber
                ? 'Unknown serial number'
                : virtualDevice.hardwareDevice.serialNumber
            }
            status={status === 'online' ? 'online' : 'offline'}
          />
        }
        onClose={closeDrawer}
      />
      <AccessPointEdit uuid={uuid} />
    </Drawer>
  );
}

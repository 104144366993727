import type { SectionHeaderProps } from '@meterup/atto';
import { Section, SectionContent, SectionHeader, styled } from '@meterup/atto';
import { Suspense } from 'react';

import type { ChartSkeletonProps } from './ChartSkeleton';
import ChartSkeleton from './ChartSkeleton';

export type ChartSectionProps = ChartSkeletonProps & {
  header?: SectionHeaderProps;
};

type InternalChartSectionProps = ChartSectionProps & {
  children: React.ReactNode;
  internal?: boolean;
};

const ChartSectionHeader = styled(SectionHeader);

export default function ChartSection({
  children,
  header,
  internal,
  ...props
}: InternalChartSectionProps) {
  return (
    <Suspense fallback={<ChartSkeleton height={props.height} />}>
      <Section relation="stacked" internal={internal}>
        {header && <ChartSectionHeader {...header} />}
        <SectionContent gutter="none">{children}</SectionContent>
      </Section>
    </Suspense>
  );
}

import type { SortingState } from '@tanstack/react-table';
import { Badge, EmptyState, Text, Tooltip } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import type { NosFeaturesQueryResult } from './utils';
import { paths } from '../../constants';
import { Nav } from '../../nav';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { NoValue } from '../NoValue';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { nosFeaturesQuery, nosVersionsQuery } from './utils';

const builder = createColumnBuilder<NosFeaturesQueryResult>();

function useColumns() {
  const nosVersions = useGraphQL(nosVersionsQuery).data?.nosVersions ?? [];
  const defaultNOS = nosVersions.find((version) => version.isDefault);

  const versionString = (
    major: number | null | undefined,
    minor: number | null | undefined,
    patch: number | null | undefined,
  ) => {
    if (major == null) return '';
    return `${major}.${minor ?? 0}.${patch ?? 0}`;
  };

  const isDefault = (feature: NosFeaturesQueryResult) => {
    if (defaultNOS == null) return false;
    if (feature.minMajorVersion < defaultNOS.major) {
      return true;
    }
    if (feature.minMajorVersion > defaultNOS.major) {
      return false;
    }
    if (feature.minMinorVersion < defaultNOS.minor) {
      return true;
    }
    if (feature.minMinorVersion > defaultNOS.minor) {
      return false;
    }
    return feature.minPatchVersion <= defaultNOS.patch;
  };

  return [
    builder.data((feature) => feature.key, {
      id: 'key',
      header: 'Key',
      meta: {
        isLeading: true,
      },
      cell: ({ value }) => {
        if (value) {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <>{value}</>;
        }

        return <NoValue />;
      },
    }),
    builder.data((feature) => feature.name, {
      id: 'name',
      header: 'Name',
    }),
    builder.data((feature) => (isDefault(feature) ? 'Default' : ''), {
      id: 'is-default',
      header: 'Default',
      meta: {
        tooltip: {
          contents:
            'A checkmark indicates that this feature is available on the default NOS version for new networks.',
        },
      },
      cell: ({ value }) => {
        if (value) {
          return (
            <Badge size="small" icon="checkmark" variant="positive" arrangement="hidden-label">
              Default
            </Badge>
          );
        }
        return <NoValue />;
      },
    }),
    builder.data((feature) => feature.featureFlag, {
      id: 'has-feature-flag',
      header: 'Feature flag',
      meta: {
        tooltip: {
          contents:
            'A checkmark indicates that this feature is also behind a feature flag, so it might not be visible to users even if their network is on a valid NOS version.',
        },
      },
      cell: ({ value }) => {
        if (value) {
          return (
            <Tooltip contents={value}>
              <Badge size="small" icon="checkmark" variant="positive" arrangement="hidden-label">
                {value}
              </Badge>
            </Tooltip>
          );
        }
        return <NoValue />;
      },
    }),
    builder.data(
      (feature) =>
        versionString(feature.minMajorVersion, feature.minMinorVersion, feature.minPatchVersion),
      {
        id: 'min-version',
        header: 'Min. NOS',
        cell: ({ value }) => {
          if (value === '') return <NoValue />;
          return <Text family="monospace">{value}</Text>;
        },
      },
    ),
    builder.data(
      (feature) =>
        versionString(feature.maxMajorVersion, feature.maxMinorVersion, feature.maxPatchVersion),
      {
        id: 'max-version',
        header: 'Max. NOS',
        cell: ({ value }) => {
          if (value === '') return <NoValue />;
          return <Text family="monospace">{value}</Text>;
        },
      },
    ),
    builder.data((feature) => feature.description, {
      id: 'description',
      header: 'Description',
    }),
  ];
}

function NOSFeaturesList({
  globalFilter,
  containerRef,
}: {
  globalFilter: string | undefined;
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const columns = useColumns();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.NOSFeatureDetailPage);
  const nosFeatures = useGraphQL(nosFeaturesQuery).data?.nosFeatures ?? [];
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort', [
    {
      id: 'min-version',
      desc: false,
    },
  ]);

  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) {
    return <EmptyState icon="upgrading" heading="No firmware features found" />;
  }

  return (
    <AutoTable
      isVirtual
      tableContainerRef={containerRef}
      columns={columns}
      data={nosFeatures}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      isRowSelected={(row) => row.uuid === drawerParams?.uuid}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.NOSFeatureDetailPage, {
          uuid: row.uuid,
        })
      }
    />
  );
}

export default NOSFeaturesList;

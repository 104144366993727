import { checkDefinedOrThrow } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import EditRackDrawer from '../../../../../components/Design/RackElevations/EditRackDrawer';
import { rackElevationQuery } from '../../../../../components/Design/RackElevations/utils';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/rack-elevations/:uuid/edit',
  title: 'Edit rack - Rack elevations - Design',
});

export default function EditRackDrawerPage() {
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditRackDrawerPage),
  );
  const elevation = useGraphQL(rackElevationQuery, { UUID: uuid }).data?.rackElevation;

  if (!elevation) return null;

  return <EditRackDrawer elevation={elevation} />;
}

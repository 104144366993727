import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import WebhookEdit from '../../../../../../components/Settings/Organization/Integrations/Webhooks/WebhookEdit';
import { paths } from '../../../../../../constants';
import { Nav } from '../../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/integrations/webhooks/:uuid/edit',
  title: 'Edit webhook - Webhooks - Integrations - Org',
});

export default function WebhookEditPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.WebhookEditPage),
  );

  return <WebhookEdit webhookUUID={uuid} />;
}

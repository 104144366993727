import {
  Alert,
  Button,
  Column,
  Columns,
  ControlGroup,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DrawerContent,
  EmptyState,
  FieldContainer,
  FloatingField,
  Section,
  SectionContent,
  SectionHeader,
  space,
  Table,
  TableBody,
  TableCell,
  TableCellBuffer,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  Text,
  TextInput,
  ToggleInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';

import type { DHCPRule, DNSHostMapping, VLAN, VLANWithDHCPRule } from '../utils';
import { DEFAULT_DNS_UPSTREAM_SERVERS } from '../../../../constants';
import {
  type CreateDnsHostMappingInput,
  type UpdateDhcpRuleInput,
  type UpdateDnsHostMappingInput,
  PermissionType,
} from '../../../../gql/graphql';
import {
  CreateDnsHostMappingInputSchema,
  UpdateDhcpRuleInputSchema,
} from '../../../../gql/zod-types';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../../utils/withZodSchema';
import { FieldProvider } from '../../../Form/FieldProvider';
import { useIsPermitted } from '../../../permissions/useIsPermitted';
import {
  createDNSHostMapping,
  deleteDNSHostMapping,
  dnsCacheSizeSchema,
  updateDHCPRuleMutation,
  updateDNSHostMapping,
  vlanHasDHCPRule,
  vlanHasStaticIP,
  vlanQuery,
  vlansQuery,
} from '../utils';
import { VLANDNSSummary } from '../VLANDrawer';
import { VLANDNSFields } from '../VLANForm';
import { FormlessResetButton, FormlessSubmitButton, VLANDetailsDrawerFooter } from './Common';

const dnsHostMappingValuesSchema = CreateDnsHostMappingInputSchema.extend({
  overrideDomain: z.string().nonempty({ message: 'Please specify an override domain. ' }),
  destinationIPAddress: z
    .string()
    .ip({ message: 'Please enter a valid IP address.' })
    .nullish()
    .or(z.literal('')), // TODO validate IP in DHCP range
}).superRefine((values, ctx) => {
  if (
    (!!values.destinationIPAddress && !!values.destinationDomain) ||
    (!values.destinationIPAddress && !values.destinationDomain)
  ) {
    for (const path of ['destinationIPAddress', 'destinationDomain']) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please specify one of either destination IP address or destination domain.',
        path: [path],
      });
    }
  }
});

type DNSHostMappingValues = z.input<typeof dnsHostMappingValuesSchema>;

function DNSHostMappingRow({
  rule,
  hostMapping,
  onSuccess,
}: {
  rule: DHCPRule;
  hostMapping?: DNSHostMapping;
  onSuccess: () => void;
}) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });
  const createHostMapping = useGraphQLMutation(createDNSHostMapping);
  const updateHostMapping = useGraphQLMutation(updateDNSHostMapping);
  const deleteHostMapping = useGraphQLMutation(deleteDNSHostMapping);

  const { state } = useDialogState();
  const { close: closeDialog } = state;

  const handleDelete = useCallback(() => {
    if (!hostMapping?.UUID) return;

    deleteHostMapping.mutate(
      { uuid: hostMapping.UUID },
      {
        onSuccess: () => {
          notify('Successfully deleted DNS host mapping.', {
            variant: 'positive',
          });
          closeDialog();
          onSuccess();
        },
        onError: (err) => {
          notify(
            `There was a problem deleting this DNS host mapping${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [hostMapping?.UUID, deleteHostMapping, onSuccess, closeDialog]);

  const handleSubmit = useCallback(
    ({ destinationIPAddress, destinationDomain, ...values }: DNSHostMappingValues) => {
      if (hostMapping?.UUID) {
        const input: UpdateDnsHostMappingInput = {
          ...values,
          destinationIPAddress: destinationIPAddress || null,
          destinationDomain: (destinationIPAddress ? null : destinationDomain) || null,
        };

        updateHostMapping.mutate(
          { uuid: hostMapping.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully updated DNS host mapping.', {
                variant: 'positive',
              });
              onSuccess();
            },
            onError: (err) => {
              notify(
                `There was a problem updating this DNS host mapping${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateDnsHostMappingInput = {
          ...values,
          destinationIPAddress: destinationIPAddress || null,
          destinationDomain: (destinationIPAddress ? null : destinationDomain) || null,
        };

        createHostMapping.mutate(
          { ruleUUID: rule.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully created DNS host mapping.', {
                variant: 'positive',
              });
              onSuccess();
            },
            onError: (err) => {
              notify(
                `There was a problem creating this DNS host mapping${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [rule.UUID, hostMapping?.UUID, updateHostMapping, createHostMapping, onSuccess],
  );

  return (
    <>
      <Formik<DNSHostMappingValues>
        initialValues={{
          isEnabled: hostMapping?.isEnabled ?? true,
          overrideDomain: hostMapping?.overrideDomain ?? '',
          destinationIPAddress: hostMapping?.destinationIPAddress ?? '',
          destinationDomain: hostMapping?.destinationDomain ?? '',
        }}
        validate={withZodSchema(dnsHostMappingValuesSchema)}
        onSubmit={handleSubmit}
      >
        <TableRow role="form" aria-label="Edit DNS host mapping">
          <TableCellBuffer side="leading" />
          <TableCell>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <FloatingField
                  label="Enabled"
                  element={<ToggleInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell style={{ paddingRight: 32 }}>
            <FieldContainer>
              <FieldProvider name="overrideDomain">
                <FloatingField
                  label="Override domain"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell>
            <FieldContainer>
              <FieldProvider name="destinationIPAddress">
                <FloatingField
                  label="Destination IP"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell>or</TableCell>
          <TableCell>
            <FieldContainer>
              <FieldProvider name="destinationDomain">
                <FloatingField
                  label="Destination domain"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          {canEditDHCPDNS && (
            <TableCell>
              <ControlGroup size="small">
                <FormlessResetButton
                  variant="secondary"
                  arrangement="hidden-label"
                  icon="arrows-rotate"
                  disabledIfClean
                >
                  Reset host mapping
                </FormlessResetButton>
                <FormlessSubmitButton
                  variant="primary"
                  arrangement="hidden-label"
                  icon="checkmark"
                  disabledIfClean
                >
                  Save host mapping
                </FormlessSubmitButton>
                {hostMapping && (
                  <Button
                    onClick={state.open}
                    variant="destructive"
                    icon="trash-can"
                    arrangement="hidden-label"
                  >
                    Delete host mapping
                  </Button>
                )}
              </ControlGroup>
            </TableCell>
          )}
          <TableCellBuffer side="trailing" />
        </TableRow>
      </Formik>
      {hostMapping && canEditDHCPDNS && (
        <Dialog state={state} preset="narrow">
          <DialogHeader icon="trash-can" heading="Delete DNS host mapping" />
          <DialogContent gutter="all">
            <Alert
              icon="information"
              variant="neutral"
              copy={
                <p>
                  You're about to remove the DNS host mapping for domain{' '}
                  <Text family="monospace">{hostMapping.overrideDomain}</Text>.
                </p>
              }
            />
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button onClick={state.close} variant="secondary">
                  Cancel
                </Button>
                <Button onClick={handleDelete} variant="destructive">
                  Delete
                </Button>
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
}

function VLANDNSHostMappingsSection({
  vlan,
  onSuccess,
}: {
  vlan: VLANWithDHCPRule;
  onSuccess: () => void;
}) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });
  const sortedHostMappings = useMemo(() => {
    const ranges = vlan.dhcpRule.dnsHostMappings.slice();
    ranges.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    return ranges;
  }, [vlan.dhcpRule.dnsHostMappings]);

  return (
    <Section relation="stacked">
      <SectionHeader heading="Host mappings" />
      <SectionContent gutter="none">
        {canEditDHCPDNS || sortedHostMappings.length > 0 ? (
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableCellBuffer side="leading" head />
                <TableHeadCell style={{ width: '64px' }}>Enabled</TableHeadCell>
                <TableHeadCell>Override domain</TableHeadCell>
                <TableHeadCell>Destination IP</TableHeadCell>
                <TableHeadCell aria-label="" style={{ width: '30px' }} />
                <TableHeadCell>Destination domain</TableHeadCell>
                {canEditDHCPDNS && <TableHeadCell aria-label="Actions" style={{ width: '82px' }} />}
                <TableCellBuffer side="trailing" head />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {sortedHostMappings.map((hostMapping) => (
                <DNSHostMappingRow
                  key={hostMapping.UUID}
                  rule={vlan.dhcpRule}
                  hostMapping={hostMapping}
                  onSuccess={onSuccess}
                />
              ))}
              {canEditDHCPDNS && (
                <DNSHostMappingRow
                  key={sortedHostMappings.length}
                  rule={vlan.dhcpRule}
                  onSuccess={onSuccess}
                />
              )}
            </TableBody>
          </Table>
        ) : (
          <EmptyState heading="No host mappings configured" />
        )}
      </SectionContent>
    </Section>
  );
}

function VLANDNSDetailsContent({ vlan }: { vlan: VLANWithDHCPRule }) {
  const network = useNetwork();
  const queryClient = useQueryClient();
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(makeQueryKey(vlansQuery, { networkUUID: network.UUID }));
    queryClient.invalidateQueries(makeQueryKey(vlanQuery, { uuid: vlan.UUID }));
  }, [queryClient, vlan.UUID, network.UUID]);

  return (
    <VStack spacing={space(12)}>
      <VLANDNSHostMappingsSection vlan={vlan} onSuccess={onSuccess} />
    </VStack>
  );
}

const updateVLANDNSSchema = UpdateDhcpRuleInputSchema.pick({
  dnsCacheIsEnabled: true,
  dnsCacheSize: true,
  dnsCacheMaxTTL: true,
  dnsUpstreamServers: true,
  dnsUseGatewayProxy: true,
}).extend({
  dnsUpstreamServers: z
    .array(z.string().ip({ message: 'Please enter at least one valid IP address.' }))
    .nonempty({ message: 'Please enter at least one valid IP address.' }),
  dnsCacheSize: dnsCacheSizeSchema,
});

type EditVLANDNSValues = z.input<typeof updateVLANDNSSchema>;

const dnsConfigurationSchemaValidator = withZodSchema(updateVLANDNSSchema);

function VLANDNSDetailsDrawer({ vlan }: { vlan: VLANWithDHCPRule }) {
  const network = useNetwork();
  const updateMutation = useGraphQLMutation(updateDHCPRuleMutation);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    (values: EditVLANDNSValues) => {
      if (!vlan.dhcpRule?.UUID) return;

      const input: UpdateDhcpRuleInput = {
        ...values,
      };

      updateMutation.mutate(
        { uuid: vlan.dhcpRule.UUID, input },
        {
          onSuccess: () => {
            notify("Successfully updated VLAN's DHCP settings.", { variant: 'positive' });
            queryClient.invalidateQueries(makeQueryKey(vlansQuery, { networkUUID: network.UUID }));
            queryClient.invalidateQueries(makeQueryKey(vlanQuery, { uuid: vlan.UUID }));
          },
          onError: (err) => {
            notify(
              `There was an error updating this VLAN's DHCP settings${getGraphQLErrorMessageOrEmpty(err)}.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [updateMutation, queryClient, network.UUID, vlan.UUID, vlan.dhcpRule?.UUID],
  );

  const handleValidate = useCallback(
    (values: EditVLANDNSValues) => {
      const validation = dnsConfigurationSchemaValidator(values);

      if (!values.dnsUseGatewayProxy && vlan.dhcpRule?.applicationDNSFirewallRules?.length) {
        validation.dnsUseGatewayProxy =
          'DNS security is enabled for this VLAN, which requires using the gateway as DNS server.';
      }

      return validation;
    },
    [vlan.dhcpRule?.applicationDNSFirewallRules?.length],
  );

  return (
    <Section relation="stacked">
      <SectionHeader heading="DNS configuration" />
      <SectionContent>
        <Formik<EditVLANDNSValues>
          validate={handleValidate}
          initialValues={{
            dnsUseGatewayProxy: vlan.dhcpRule.dnsUseGatewayProxy ?? true,
            dnsUpstreamServers: (vlan.dhcpRule.dnsUpstreamServers as [string, ...string[]]) ?? [
              ...DEFAULT_DNS_UPSTREAM_SERVERS,
            ],
            dnsCacheIsEnabled: vlan.dhcpRule.dnsCacheIsEnabled,
            dnsCacheSize: vlan.dhcpRule.dnsCacheSize,
            dnsCacheMaxTTL: vlan.dhcpRule.dnsCacheMaxTTL,
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <DrawerContent>
              <VLANDNSFields rule={vlan?.dhcpRule} />
            </DrawerContent>
            <VLANDetailsDrawerFooter />
          </Form>
        </Formik>
      </SectionContent>
    </Section>
  );
}

export function VLANDNSDetails({ vlan }: { vlan: VLAN }) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });

  if (!vlanHasStaticIP(vlan)) {
    return <EmptyState heading="Static subnet required on VLAN to configure DHCP" />;
  }

  if (!vlanHasDHCPRule(vlan)) {
    return (
      <EmptyState
        heading="DHCP disabled"
        subheading="Please enable DHCP to configure advanced settings."
      />
    );
  }

  return (
    <Columns scroll="none" template="wide-narrow">
      <Column gutter="vertical">
        <VLANDNSDetailsContent vlan={vlan as VLANWithDHCPRule} />
      </Column>
      <Column gutter="vertical">
        {canEditDHCPDNS ? (
          <VLANDNSDetailsDrawer vlan={vlan as VLANWithDHCPRule} />
        ) : (
          <VLANDNSSummary relation="stacked" vlan={vlan} view="detail" />
        )}
      </Column>
    </Columns>
  );
}

import { Pane, PaneContent, PaneHeader, space, VStack } from '@meterup/atto';
import IsOperator from '@meterup/authorization/src/components/IsOperator';

import DemoModeToggle from './DemoModeToggle';
import SkipFeatureChecksForOperatorsToggle from './SkipFeatureChecksForOperatorsToggle';
import { ThemeSelect } from './ThemeSelect';

export const Meta = () => ({
  path: '/org/:companyName/settings/user/preferences',
  title: 'Preferences - User',
});

export default function SettingsUserPreferencesPage() {
  return (
    <Pane variant="adjusted">
      <PaneHeader icon="preferences" heading="Preferences" />
      <PaneContent gutter="all">
        <VStack spacing={space(16)}>
          <IsOperator respectDemoMode={false}>
            <DemoModeToggle />
            <SkipFeatureChecksForOperatorsToggle />
          </IsOperator>
          <ThemeSelect />
        </VStack>
      </PaneContent>
    </Pane>
  );
}

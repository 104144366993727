import { Alert, LoadingIcon, space, styled } from '@meterup/atto';

import type { RpcPing2MutationMutation } from '../../gql/graphql';
import type { VLAN } from '../NetworkWide/VLANs/utils';
import { graphql } from '../../gql';

export type PingResponse = RpcPing2MutationMutation['rpcPing'];

export const vlansForPingQuery = graphql(`
  query VlansForPing($networkUUID: UUID!) {
    vlans(networkUUID: $networkUUID) {
      UUID
      name
    }
  }
`);

export const RPCPing2Mutation = graphql(`
  mutation RPCPing2Mutation($input: RPCPingInput!) {
    rpcPing(input: $input) {
      successRate
      roundTripTimeMs
    }
  }
`);

type PingTestAlertProps = {
  successRate: number;
};
export function PingTestAlert({ successRate }: PingTestAlertProps) {
  if (successRate === 1) {
    return (
      <Alert
        type="inline"
        relation="stacked"
        variant="positive"
        icon="ping"
        heading="The ping test was successful!"
      />
    );
  }
  if (successRate > 0.001 && successRate < 1) {
    return (
      <Alert
        type="inline"
        relation="stacked"
        variant="neutral"
        icon="attention"
        heading="Not all pings succeeded. Please check your connection."
      />
    );
  }
  return (
    <Alert
      type="inline"
      relation="stacked"
      variant="negative"
      icon="warning"
      heading="Ping test failed. Please check your connection."
    />
  );
}

export function StyledLoadingIcon() {
  return <LoadingIcon color={{ light: 'gray500', dark: 'gray300' }} size={space(14)} />;
}

export const PingTestFields = styled('div', {
  vStack: '$6',
  alignItems: 'stretch',
});

export function isValidHost(host: string): boolean {
  return Boolean(
    // IPv4 regex
    host.match(
      /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    ),
  );
}

export function getVLANUUID(vlanName: string, vlans: VLAN[]) {
  return vlans.find((vlan) => vlan.name === vlanName)?.UUID;
}

import { get } from 'lodash-es';
import { DateTime } from 'luxon';

import type { VPNClientWithPeerInfo } from '../api/types';
import { timestampToISO } from './timestamp';

export const isActive = (c: VPNClientWithPeerInfo): boolean => {
  const lastHandshake = get(c.peerInfo, 'last_handshake_time');
  if (lastHandshake) {
    const handshakeTime = DateTime.fromISO(timestampToISO(lastHandshake));
    const fiveMinsAgo = DateTime.now();
    fiveMinsAgo.minus({ minutes: 3 });
    return fiveMinsAgo.diff(handshakeTime, 'minutes').minutes <= 5;
  }

  return false;
};

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { EventLogView } from '../../../../../components/EventLogView';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/logs',
  title: 'Logs - Insights',
});

export default function EventLogPage() {
  return <EventLogView />;
}

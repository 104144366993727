import { Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';

import {
  VLANClientsWidget,
  VLANDHCPWidget,
  VLANTaggingWidget,
} from '../../../../../components/vlans';
import { paths } from '../../../../../constants';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName',
});

export default function LegacyVLANSummaryPage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacyVLANSummaryPage),
  );
  const controllerName = useCurrentController();
  const vlan = useVLANConfigModel(controllerName, vlanName);

  return (
    <Drawer>
      <DrawerHeader icon="vlan" heading="VLAN" />
      <DrawerContent gutter="none">
        <VLANTaggingWidget vlan={vlan} shouldLinkToDetails />
        <VLANDHCPWidget relation="stacked" vlan={vlan} />
        <VLANClientsWidget relation="stacked" vlan={vlan} />
      </DrawerContent>
    </Drawer>
  );
}

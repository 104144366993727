import { Drawer, DrawerHeader } from '@meterup/atto';

import AutoVPNGroupAddEditForm from '../../../components/autoVPN/AutoVPNGroupAddEditForm';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { NosFeature } from '../../../hooks/useNosFeatures';

export const Meta = () => ({
  path: '/org/:companyName/auto-vpn/groups/add',
  title: 'Add group - Auto VPN - Network',
});

export default function AutoVPNGroupCreatePage() {
  const closeDrawer = useCloseDrawerCallback();

  return (
    <IsPermitted
      should404OnAccessDenied
      allowOperatorAlways={false}
      isPermitted={({ isOperator, permissions, companyNosFlags, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermAutoVpnWrite) &&
        companyNosFlags[NosFeature.SITE_TO_SITE_VPN] &&
        (isOperator || !!ldFlags['site-to-site-vpn'])
      }
    >
      <Drawer>
        <DrawerHeader icon="plus" heading="Add group" onClose={closeDrawer} />
        <AutoVPNGroupAddEditForm />
      </Drawer>
    </IsPermitted>
  );
}

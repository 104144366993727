import { Badge, Button, EmptyState, space, ToggleInput } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { Link } from 'react-router-dom';

import { paths } from '../../../../../constants';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useNetworkForSettingsPath } from '../../../../../routes/pages/settings/network/NetworkUtils';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { createColumnBuilder } from '../../../../Table/createColumnBuilder';

const builder = createColumnBuilder<any>();

const columns = [
  builder.data((row) => row.report, {
    id: 'report',
    header: 'Report',
    meta: {
      isLeading: true,
    },
    cell: ({ row }) => row.report,
  }),
  builder.data((row) => row.frequency, {
    id: 'frequency',
    header: 'Frequency',
    cell: ({ row }) => row.frequency,
  }),
  builder.data((row) => row.receivers, {
    id: 'receivers',
    header: 'Receivers',
    cell: ({ row }) => (
      <Badge arrangement="leading-icon" icon="tag" size="small" variant="brand">
        {row.receivers}
      </Badge>
    ),
  }),
  builder.data((row) => row.enabled, {
    id: 'enable',
    header: 'Enable',
    meta: {
      width: space(68),
      alignment: 'center',
      hideSortIcon: true,
    },
    cell: ({ row }) => <ToggleInput selected={row.enabled ?? false} controlSize="small" />,
  }),
];

const data = [
  {
    report: 'ISP uptime',
    frequency: 'Monthly, 24th, 10AM',
    receivers: 'SF office',
    enabled: true,
  },
  {
    report: 'New client associations',
    frequency: 'Daily, 4PM',
    receivers: 'UK office',
    enabled: false,
  },
];

export default function Reports() {
  const companyName = useCurrentCompany();
  const network = useNetworkForSettingsPath({
    path: paths.pages.SettingsNetworkNotificationsPage,
  });

  return data.length > 0 ? (
    <AutoTable
      key="alerts-table"
      columns={columns}
      data={data}
      getLinkTo={() =>
        makeDrawerLink(window.location, paths.drawers.ReportEditPage, {
          companyName,
          networkSlug: network.slug,
        })
      }
    />
  ) : (
    <EmptyState
      icon="email"
      heading="No reports"
      action={
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.ReportsAddPage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add report
        </Button>
      }
    />
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import type { ExportableToCSV } from '@meterup/common';
import type { SortingState } from '@tanstack/react-table';
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  HStack,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  SearchInput,
  space,
  Text,
  Tooltip,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable, expectDefinedOrThrow, isDefined, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Duration } from 'luxon';
import { Suspense, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import type { UnassignedHardwareForNetworkQuery } from '../../../gql/graphql';
import type { SwitchesQueryResult } from './utils';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { PermissionType, VirtualDeviceType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { deviceHasMismatchedRealm } from '../../../utils/devices';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useHardwareCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { CopyableMonoOrNoValue } from '../../Devices/Insights';
import { formatTimestamp } from '../../Devices/utils';
import { NoValue } from '../../NoValue';
import IsPermitted from '../../permissions/IsPermitted';
import { DetailLoadingFallback } from '../../Placeholders/DetailLoadingFallback';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { portSpeedToLabel, SwitchesQuery, SwitchSTPInfo } from './utils';

const builder = createColumnBuilder<SwitchesQueryResult>();

const commonColumns = [
  builder.data((row) => (row.hardwareDevice?.isConnectedToBackend ? 'Online' : 'Offline'), {
    id: 'status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: ({ row }) => {
      if (row.hardwareDevice?.isConnectedToBackend) {
        const uplinkPhyInterface =
          row.__typename === 'SwitchVirtualDevice'
            ? row.phyInterfaces.find((pi) => pi.isUplink)
            : undefined;

        // TODO(DASH-3200): Reliably determine switch link speed and check this again
        if (uplinkPhyInterface?.portSpeedMbps) {
          return (
            <Tooltip
              contents={`Link speed is ${portSpeedToLabel(uplinkPhyInterface.portSpeedMbps)}`}
            >
              <Badge
                arrangement="hidden-label"
                size="small"
                ends="pill"
                icon="checkmark"
                variant="positive"
              >
                Online
              </Badge>
            </Tooltip>
          );
        }

        // Separate case so it's easier to revert to handle the above
        // separately when we can reliably determine whether the port is slow.
        return (
          <Tooltip contents="Unable to determine link speed">
            <Badge
              arrangement="hidden-label"
              size="small"
              ends="pill"
              icon="checkmark"
              variant="positive"
            >
              Online
            </Badge>
          </Tooltip>
        );
      }

      if (row.hardwareDevice?.disconnectedFromBackendAt) {
        return (
          <Tooltip
            contents={`Offline since ${formatTimestamp(row.hardwareDevice.disconnectedFromBackendAt)}`}
          >
            <Badge
              arrangement="hidden-label"
              size="small"
              ends="pill"
              icon="cross"
              variant="negative"
            >
              Offline
            </Badge>
          </Tooltip>
        );
      }

      return (
        <Tooltip contents="Never connected to backend">
          <Badge arrangement="hidden-label" size="small" ends="pill" icon="minus" variant="neutral">
            Offline
          </Badge>
        </Tooltip>
      );
    },
  }),
  builder.data((device) => device.label ?? '', {
    id: 'switch-label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
    cell: (props) => {
      if (props.row.label) {
        return <span>{props.row.label}</span>;
      }

      return <NoValue />;
    },
  }),
  builder.data((device) => device.hardwareDevice?.macAddress ?? '', {
    id: 'switch-mac-address',
    header: 'MAC',
    cell: (props) => {
      if (props.row.hardwareDevice?.macAddress) {
        return <Text family="monospace">{props.row.hardwareDevice.macAddress}</Text>;
      }

      return <NoValue />;
    },
  }),
  builder.data(
    (device) =>
      device.hardwareDevice?.__typename === 'SwitchHardwareDevice'
        ? device.hardwareDevice.ipAddress ?? ''
        : '',
    {
      id: 'switch-ip-address',
      header: 'IP address',
      cell: (props) => {
        const hwDevice = props.row.hardwareDevice;

        if (hwDevice?.__typename !== 'SwitchHardwareDevice' || !isDefined(hwDevice.ipAddress)) {
          return <NoValue />;
        }

        return <Text family="monospace">{hwDevice.ipAddress}</Text>;
      },
    },
  ),
  builder.data(
    (virtualDevice) => {
      if (
        virtualDevice.hardwareDevice &&
        virtualDevice.hardwareDevice.__typename !== 'SwitchHardwareDevice'
      ) {
        return '-1';
      }
      if (virtualDevice.__typename !== 'SwitchVirtualDevice') {
        return '-1';
      }
      return String(virtualDevice.switchProfile.stpBridgePriority);
    },
    {
      id: 'switch-stp-bridge-priority',
      header: 'STP bridge priority',
      meta: {
        alignment: 'end',
      },
      cell: (props) => <SwitchSTPInfo virtualDevice={props.row} />,
    },
  ),
  builder.data(
    (row) =>
      row.__typename === 'SwitchVirtualDevice'
        ? row.phyInterfaces
            .reduce((acc, x) => (x.isEthernet && !x.portSpeedMbps ? acc + 1 : acc), 0)
            .toString()
        : '0',
    {
      id: 'switch-open-port-count',
      header: 'Open ports',
      meta: {
        alignment: 'end',
      },
    },
  ),
];

const macTableColumn = builder.data(
  (row) =>
    String(
      row.hardwareDevice?.__typename === 'SwitchHardwareDevice' &&
        row.hardwareDevice?.isInCurrentControllerMACTable,
    ),
  {
    id: 'is-in-security-appliance-mac-table',
    header: () => <Icon icon="mac-address" size={space(16)} />,
    meta: {
      alignment: 'center',
      internal: true,
      width: 48,
      tooltip: {
        contents: (
          <VStack align="center">
            <Text display="block">MAC address in</Text>
            <Text display="block">security appliance MAC table</Text>
          </VStack>
        ),
      },
    },
    cell: (props) => {
      if (
        props.row.hardwareDevice?.__typename === 'SwitchHardwareDevice' &&
        props.row.hardwareDevice?.isInCurrentControllerMACTable
      ) {
        return (
          <Tooltip asChild={false} contents="Available in security appliance MAC table">
            <Icon
              icon="checkmark"
              color={{
                light: 'iconPositiveLight',
                dark: 'iconPositiveDark',
              }}
              size={space(16)}
            />
          </Tooltip>
        );
      }
      return (
        <Tooltip asChild={false} contents="Not available in security appliance MAC table">
          <NoValue />
        </Tooltip>
      );
    },
  },
);

const operatorColumns = [
  ...commonColumns,
  builder.data((device) => device.hardwareDevice?.serialNumber ?? '', {
    id: 'switch-serial-number',
    header: 'Serial number',
    meta: {
      internal: true,
    },
    cell: (props) => {
      if (props.row.hardwareDevice?.serialNumber) {
        return <Text family="monospace">{props.row.hardwareDevice.serialNumber}</Text>;
      }

      return <NoValue internal />;
    },
  }),
  builder.data(
    (row) => {
      const nos = row.nosVersion;
      const boot = row.hardwareDevice?.bootHistory;

      if (!nos || !boot || boot.length === 0) {
        return '0';
      }

      return nos.version;
    },
    {
      id: 'switch-nos-version',
      header: 'NOS version',
      meta: {
        internal: true,
      },
      cell: (props) => {
        if (props.value === '0') {
          return <NoValue internal />;
        }

        const nos = props.row.nosVersion;
        const boot = props.row.hardwareDevice?.bootHistory;
        const pendingNos = props.row.pendingNosVersion;

        // A little hacky, but NOS version 2 (which is stable) is `no-upgrades` so whatever build is on the
        // device when its on NOS version 2 is valid.
        if (nos && boot && nos.id !== 2 && nos.buildName !== boot[0].buildName) {
          return (
            <Tooltip
              asChild={false}
              contents={`NOS version ${nos.version} expected build ${nos.buildName} but device is running ${boot[0].buildName}`}
            >
              <HStack spacing={space(8)} align="center">
                <Text family="monospace">{nos.version}</Text>
                <Icon
                  icon="warning"
                  size={12}
                  color={{ light: 'internalBodyLight', dark: 'internalBodyDark' }}
                />
              </HStack>
            </Tooltip>
          );
        }
        if (isDefined(pendingNos) && isDefined(pendingNos.nosVersion)) {
          return (
            <Tooltip
              asChild={false}
              contents={`Pending upgrade to ${
                pendingNos.nosVersion.version
              } on ${formatTimestamp(pendingNos.scheduledAt)}`}
            >
              <HStack spacing={space(8)} align="center">
                {nos?.version}
                <Icon
                  icon="information"
                  size={12}
                  color={{ light: 'internalBodyLight', dark: 'internalBodyDark' }}
                />
              </HStack>
            </Tooltip>
          );
        }

        return <span>{props.value}</span>;
      },
    },
  ),
];

const useColumns = () => {
  const isOperator = useIsOperator({ respectDemoMode: true });
  const doesSwitchUseStat = useNosFeatureEnabled(NosFeature.SOSUsesStat);

  return useMemo(() => {
    const uptimeColumn = builder.data(
      (row) => {
        if (doesSwitchUseStat) {
          if (!isDefined(row.uptime)) {
            return '0';
          }
          return Duration.fromISO(row.uptime).toHuman({ unitDisplay: 'narrow' });
        }

        const hwDevice = row.hardwareDevice;
        if (hwDevice?.__typename !== 'SwitchHardwareDevice' || !isDefined(hwDevice.uptime)) {
          return '0';
        }
        return Duration.fromISO(hwDevice.uptime).toHuman({ unitDisplay: 'narrow' });
      },
      {
        id: 'switch-uptime',
        header: 'Uptime',
        meta: {
          alignment: 'end',
          internal: true,
        },
        enableGlobalFilter: false,
        cell: (props) => {
          if (props.value === '0') {
            return <NoValue internal />;
          }

          return <span>{props.value}</span>;
        },
      },
    );
    const newOperatorColumns = [...operatorColumns];
    const UPTIME_COL_INDEX = 0;
    newOperatorColumns.splice(commonColumns.length, UPTIME_COL_INDEX, uptimeColumn);
    return isOperator ? newOperatorColumns : commonColumns;
  }, [doesSwitchUseStat, isOperator]);
};

const unassignedHardwareQuery = graphql(`
  query UnassignedHardwareForNetwork($networkUUID: UUID!) {
    unassignedHardwareDevicesForNetwork(networkUUID: $networkUUID) {
      virtualDevice {
        UUID
        label
      }
      unassignedHardwareConnections {
        phyInterface {
          UUID
          portNumber
        }
        unassignedHardwareDevice {
          serialNumber
          deviceType
          deviceModel
          macAddress
        }
      }
    }
  }
`);

type UnassignedEntry =
  UnassignedHardwareForNetworkQuery['unassignedHardwareDevicesForNetwork'][number];

type UnassignedConnection = UnassignedEntry['unassignedHardwareConnections'][number];

interface UnassignedHardwareRow {
  virtualDevice: UnassignedEntry['virtualDevice'];
  phyInterface: UnassignedConnection['phyInterface'];
  unassignedHardwareDevice: UnassignedConnection['unassignedHardwareDevice'];
}

const unassignedHardwareBuilder = createColumnBuilder<UnassignedHardwareRow>();

const unassignedHardwareColumns = [
  unassignedHardwareBuilder.data((row) => row.virtualDevice.label, {
    id: 'switch-label',
    header: 'Switch',
  }),
  unassignedHardwareBuilder.data((row) => row.phyInterface.portNumber.toString(), {
    id: 'port-number',
    header: 'Port number',
  }),
  unassignedHardwareBuilder.data((row) => row.unassignedHardwareDevice.deviceModel.toString(), {
    id: 'device-model',
    header: 'Model',
    cell: ({ value }) => <CopyableMonoOrNoValue label="model" value={value} />,
  }),
  unassignedHardwareBuilder.data((row) => row.unassignedHardwareDevice.macAddress ?? '', {
    id: 'mac-address',
    header: 'MAC address',
    cell: ({ value }) => <CopyableMonoOrNoValue label="MAC address" value={value} />,
  }),
  unassignedHardwareBuilder.data((row) => row.unassignedHardwareDevice.serialNumber, {
    id: 'serial-number',
    header: 'Serial number',
    cell: ({ value }) => <CopyableMonoOrNoValue label="serial number" value={value} />,
  }),
];

function UnassignedHardwareDialog({ state }: { state: OverlayTriggerState }) {
  const network = useNetwork();

  const autoTableRef = useRef<ExportableToCSV>(null);

  const unassignedHardware = useGraphQL(
    unassignedHardwareQuery,
    { networkUUID: network.UUID },
    {
      enabled: state.isOpen,
    },
  ).data?.unassignedHardwareDevicesForNetwork;

  const [sortingState, setSortingState] = useState<SortingState>([]);

  const flattenedRows: UnassignedHardwareRow[] = useMemo(
    () =>
      unassignedHardware?.flatMap(({ virtualDevice, unassignedHardwareConnections }) =>
        unassignedHardwareConnections
          .map(({ phyInterface, unassignedHardwareDevice }) => ({
            virtualDevice,
            phyInterface,
            unassignedHardwareDevice,
          }))
          .sort((a, b) => a.phyInterface.portNumber - b.phyInterface.portNumber),
      ) ?? [],
    [unassignedHardware],
  );

  return (
    <Dialog state={state} preset="wide">
      <DialogHeader
        heading="Unassigned hardware"
        actions={
          <Button
            type="button"
            icon="download"
            arrangement="leading-icon"
            onClick={() => {
              autoTableRef.current?.exportToCSV();
            }}
            variant="secondary"
          >
            Export to CSV
          </Button>
        }
      />
      <DialogContent gutter="all">
        {flattenedRows.length > 0 ? (
          <AutoTable
            ref={autoTableRef}
            columns={unassignedHardwareColumns}
            data={flattenedRows}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            exportToCSVFilename={`Unassigned hardware - ${network.label}`}
          />
        ) : (
          <EmptyState icon="access-point" heading="No unassigned devices found on this network" />
        )}
      </DialogContent>
    </Dialog>
  );
}

function SwitchesActions({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter: string | undefined;
  setGlobalFilter: (filter: string) => void;
}) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();

  const { state: showUnassignedDevicesDialogState } = useDialogState();
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);

  return (
    <>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
              permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
              nosFlags[NosFeature.SOS],
          )
        }
      >
        <Button
          as={ReactRouterLink}
          to={makeDrawerLink(window.location, paths.drawers.SwitchCreatePage, {
            companyName,
            networkSlug: network.slug,
          })}
          variant="secondary"
          arrangement="leading-icon"
          icon="plus"
          condense
          size="small"
          internal
        >
          Add switch
        </Button>
      </IsPermitted>

      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
                    permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
                    nosFlags[NosFeature.SOS],
                )
              }
            >
              <DropdownMenuItem
                onClick={() =>
                  navigate(
                    makeDrawerLink(window.location, paths.drawers.SwitchBulkCreatePage, {
                      companyName,
                      networkSlug: network.slug,
                    }),
                  )
                }
                icon="plus"
                internal
              >
                Bulk add switches
              </DropdownMenuItem>
            </IsPermitted>
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
                    nosFlags[NosFeature.SOS],
                )
              }
            >
              <DropdownMenuItem
                onClick={() =>
                  navigate(
                    makeLink(paths.pages.PatchPanelLayoutPage, {
                      companyName,
                      networkSlug: network.slug,
                    }),
                  )
                }
                internal={!isSOSEnabled}
              >
                View patch panel layout
              </DropdownMenuItem>
            </IsPermitted>
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
                    nosFlags[NosFeature.SOS],
                )
              }
            >
              <DropdownMenuItem onSelect={showUnassignedDevicesDialogState.open} internal>
                View unassigned devices
              </DropdownMenuItem>
            </IsPermitted>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <SearchInput
        placeholder="..."
        aria-label="Filter switches"
        scope="scoped"
        value={globalFilter}
        onChange={setGlobalFilter}
        minWidth="56px"
      />
      <UnassignedHardwareDialog state={showUnassignedDevicesDialogState} />
    </>
  );
}

function SwitchListView({ globalFilter }: { globalFilter: string | undefined }) {
  const columns = useColumns();
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SwitchSummaryPage);
  const { hasPermission } = usePermissions();
  const switchData = useGraphQL(SwitchesQuery, {
    networkUUID: network.UUID,
    includeIsDev: hasPermission(PermissionType.PermNetworkDevicesReadRestricted),
  }).data;
  const isOperator = useIsOperator({ respectDemoMode: true });
  const isCOSEnabled = useNosFeatureEnabled(NosFeature.COS2);

  const featureFlags = useFeatureFlags();
  expectDefinedOrThrow(switchData, new ResourceNotFoundError('Unable to load switches'));

  const shouldShowSOSUI = isOperator || featureFlags['sos-ui'];

  const switches = useMemo(
    () =>
      switchData.virtualDevicesForNetwork.filter(
        (s) =>
          s.deviceType === VirtualDeviceType.Switch && (isOperator || s.hardwareDevice?.isActive),
      ),
    [switchData, isOperator],
  );
  const hasMismatchedRealm = useMemo(
    () =>
      switchData.virtualDevicesForNetwork.some((d) => deviceHasMismatchedRealm(d.hardwareDevice)),
    [switchData],
  );

  const allColumns = useMemo(() => {
    if (isCOSEnabled && isOperator) {
      const newColumns = [...columns];
      newColumns.splice(1, 0, macTableColumn);
      if (hasMismatchedRealm) {
        newColumns.push(
          builder.data((device) => device.hardwareDevice?.serialNumber ?? '', {
            id: 'mismatched-realm',
            header: 'Mismatched Realm',
            meta: {
              internal: true,
            },
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (props) => {
              if (deviceHasMismatchedRealm(props.row.hardwareDevice)) {
                return (
                  <Tooltip
                    asChild={false}
                    contents="Configured realm does not match device's IsDev"
                  >
                    <Badge
                      arrangement="hidden-label"
                      size="small"
                      ends="pill"
                      variant="negative"
                      icon="checkmark"
                    >
                      Mismatched Realm
                    </Badge>
                  </Tooltip>
                );
              }
              return (
                <Badge
                  arrangement="hidden-label"
                  size="small"
                  ends="pill"
                  variant="positive"
                  icon="checkmark"
                >
                  Valid Realm
                </Badge>
              );
            },
          }),
        );
      }
      return newColumns;
    }
    return columns;
  }, [isCOSEnabled, isOperator, columns, hasMismatchedRealm]);

  const [sortingState, setSortingState] = useState<SortingState>([
    { id: 'switch-label', desc: false },
  ]);
  const closeDrawer = useCloseDrawerCallback();

  if (switches.length > 0) {
    return (
      <AutoTable
        key={JSON.stringify(switches)}
        columns={allColumns}
        data={switches}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        isRowSelected={(s) => s.UUID === drawerParams?.uuid}
        globalFilter={globalFilter}
        onRowDeselect={closeDrawer}
        getLinkTo={
          shouldShowSOSUI
            ? (row) =>
                makeDrawerLink(window.location, paths.drawers.SwitchSummaryPage, {
                  networkSlug: network.slug,
                  companyName,
                  uuid: row.UUID,
                })
            : undefined
        }
      />
    );
  }

  return (
    <EmptyState
      icon="switch"
      heading="No switches found for this network"
      action={
        <IsPermitted
          isPermitted={({ permissions, nosFlags }) =>
            Boolean(
              permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
                permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
                nosFlags[NosFeature.SOS],
            )
          }
        >
          <Button
            condense
            size="small"
            as={ReactRouterLink}
            to={makeDrawerLink(window.location, paths.drawers.SwitchCreatePage, {
              companyName,
              networkSlug: network.slug,
            })}
            variant="secondary"
            arrangement="leading-icon"
            icon="plus"
            internal
          >
            Add switch
          </Button>
        </IsPermitted>
      }
    />
  );
}

function SwitchList() {
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const hardwareCrumb = useHardwareCrumbs();
  const switchData = useGraphQL(SwitchesQuery, { networkUUID: network.UUID }).data;
  const isOperator = useIsOperator({ respectDemoMode: true });

  const switches = useMemo(() => {
    if (!switchData) return [];
    return switchData.virtualDevicesForNetwork.filter(
      (s) =>
        s.deviceType === VirtualDeviceType.Switch && (isOperator || s.hardwareDevice?.isActive),
    );
  }, [switchData, isOperator]);
  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...hardwareCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.SwitchListPage, {
                companyName,
                networkSlug: network.slug,
                tab: 'list',
              }),
              selected: true,
              label: 'Switches',
            },
          },
        ]}
        icon="switch"
        heading="Switches"
        count={switches.length}
        actions={<SwitchesActions globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />}
      />
      <PaneContent gutter="bottom">
        <Suspense fallback={<DetailLoadingFallback />}>
          <SwitchListView globalFilter={globalFilter} />
        </Suspense>
      </PaneContent>
    </Pane>
  );
}

export default SwitchList;

import { Body, Button, CopyBox, HStack, space } from '@meterup/atto';
import React from 'react';

type AdminPortalCopyBoxProps = {
  portalURL: string;
};

export default function AdminPortalCopyBox({ portalURL }: AdminPortalCopyBoxProps) {
  return (
    <HStack spacing={space(8)} width="full">
      <CopyBox
        // @ts-ignore
        css={{ whiteSpace: 'nowrap', flex: 1, maxWidth: '90%' }}
        size="small"
        value={portalURL}
        aria-label={portalURL}
        relation="standalone"
        wrap={false}
      >
        <Body whitespace="no-wrap" css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {portalURL}
        </Body>
      </CopyBox>
      <Button as="a" rel="noreferrer" target="_blank" variant="secondary" href={portalURL}>
        Open
      </Button>
    </HStack>
  );
}

import { Drawer, DrawerContent, DrawerHeader, Sections } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import type { ControllerVirtualDevice } from './useCurrentControllers';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { StickyObjectHeader } from '../../Object/ObjectHeader';
import { SecurityApplianceActions } from './SecurityApplianceActions';
import { DetailsWidget, WANSection } from './SecurityApplianceDetail';
import { type HighAvailabilityConfig, ControllerQuery, HAConfigForNetworkQuery } from './utils';
import { SecurityApplianceDetailsTab } from './utils2';

function SecurityApplianceSummaryDetails({
  virtualDevice,
  highAvailability,
}: {
  virtualDevice: ControllerVirtualDevice;
  highAvailability: HighAvailabilityConfig | undefined | null;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const detailParams = Nav.useRegionParams('root', paths.pages.SecurityApplianceDetailPage);

  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'ControllerHardwareDevice') {
    throw new ResourceNotFoundError('Security appliance not found.');
  }

  const isNotViewingDetailPage = !detailParams || detailParams.uuid !== virtualDevice.UUID;

  return (
    <DrawerContent gutter={isNotViewingDetailPage ? 'none' : 'vertical'}>
      {isNotViewingDetailPage && (
        <StickyObjectHeader
          icon="security-appliance"
          name={virtualDevice.label}
          status={
            // eslint-disable-next-line no-nested-ternary
            virtualDevice.hardwareDevice
              ? virtualDevice.hardwareDevice.isConnectedToBackend
                ? 'online'
                : 'offline'
              : undefined
          }
          link={makeLink(paths.pages.SecurityApplianceDetailPage, {
            companyName,
            networkSlug: network.slug,
            uuid: virtualDevice.UUID,
            tab: SecurityApplianceDetailsTab.Insights,
          })}
          cta="View security appliance"
        />
      )}
      <Sections>
        <DetailsWidget
          virtualDevice={{ ...virtualDevice, hardwareDevice }}
          relation="stacked"
          highAvailability={highAvailability}
        />
        <WANSection virtualDevice={virtualDevice} />
      </Sections>
    </DrawerContent>
  );
}

export default function SecurityApplianceSummary({
  virtualDeviceUUID,
}: {
  virtualDeviceUUID: string;
}) {
  const network = useNetwork();
  const isOperator = useIsOperator();
  const closeDrawer = useCloseDrawerCallback();
  const highAvailabilityConfigQuery = useGraphQL(HAConfigForNetworkQuery, {
    networkUUID: network.UUID,
  });
  const highAvailabilityConfig = highAvailabilityConfigQuery.data?.network?.highAvailabilityConfig;

  const virtualDevice = useGraphQL(ControllerQuery, { uuid: virtualDeviceUUID }).data
    ?.virtualDevice;
  if (virtualDevice?.__typename !== 'ControllerVirtualDevice') {
    throw new ResourceNotFoundError('Security appliance not found.');
  }

  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'ControllerHardwareDevice') {
    throw new ResourceNotFoundError('Security appliance not found.');
  }

  return (
    <Drawer>
      <DrawerHeader
        icon="security-appliance"
        heading="Security appliance"
        actions={
          isOperator && (
            <SecurityApplianceActions virtualDeviceUUID={virtualDeviceUUID} view="drawer" />
          )
        }
        onClose={closeDrawer}
      />
      <SecurityApplianceSummaryDetails
        virtualDevice={{ ...virtualDevice, hardwareDevice }}
        highAvailability={highAvailabilityConfig}
      />
    </Drawer>
  );
}

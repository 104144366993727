import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';

import type { ControllerVirtualDevice } from './useCurrentControllers';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { styled } from '../../../stitches';
import { withZodSchema } from '../../../utils/withZodSchema';
import { HardwareDeviceField } from '../../Devices/Fields';
import { updateVirtualDeviceMutation } from '../../Devices/utils';
import { TextareaField, TextField, ToggleField } from '../../Form/Fields';
import IsPermitted from '../../permissions/IsPermitted';
import { SecurityApplianceActions } from './SecurityApplianceActions';
import { ControllerQuery, ControllersForSecurityApplianceQuery } from './utils';
import {
  type UpdateSecurityApplianceVirtualDeviceFields,
  updateSecurityApplianceVirtualDeviceSchema,
} from './utils2';

type SecurityApplianceEditDrawerProps = {
  virtualDeviceUUID: string;
};

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

function SecurityApplianceEditGeneral({
  virtualDevice,
}: {
  virtualDevice: ControllerVirtualDevice;
}) {
  const network = useNetwork();

  const queryClient = useQueryClient();
  const virtualDeviceMutation = useGraphQLMutation(updateVirtualDeviceMutation);

  const closeDrawer = useCloseDrawerCallback();

  const handleSubmit = (v: UpdateSecurityApplianceVirtualDeviceFields) => {
    virtualDeviceMutation.mutate(
      {
        uuid: virtualDevice.UUID,
        input: {
          label: v.label,
          description: v.description,
          enableConsolePort: v.enableConsolePort,
        },
      },
      {
        onSuccess: () => {
          notify('Successfully updated security appliance.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(ControllersForSecurityApplianceQuery, { networkUUID: network.UUID }),
          );
        },
        onError: (err) => {
          notify(
            `There was an error updating this security appliance${getGraphQLErrorMessageOrEmpty(err)}`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  };

  return (
    <Formik<UpdateSecurityApplianceVirtualDeviceFields>
      validate={withZodSchema(updateSecurityApplianceVirtualDeviceSchema)}
      initialValues={{
        label: virtualDevice.label,
        description: virtualDevice.description,
        enableConsolePort: virtualDevice.isConsoleEnabled,
      }}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <DrawerContent gutter="all">
          <TextField name="label" label="Label" />
          <TextareaField name="Description" label="Description" optional />

          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
                  nosFlags[NosFeature.COS2],
              )
            }
          >
            <HardwareDeviceField
              virtualDevice={virtualDevice}
              onSuccess={async () => {
                queryClient.invalidateQueries(
                  makeQueryKey(ControllersForSecurityApplianceQuery, {
                    networkUUID: network.UUID,
                  }),
                );
                // TODO: maybe more queries in here there are so many
              }}
              internal
            />
            <ToggleField name="enableConsolePort" label="Enable console port" internal />
          </IsPermitted>
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={closeDrawer} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </>
          }
        />
      </StyledForm>
    </Formik>
  );
}

export default function SecurityApplianceEditDrawer({
  virtualDeviceUUID,
}: SecurityApplianceEditDrawerProps) {
  const closeDrawer = useCloseDrawerCallback();

  const virtualDevice = useGraphQL(ControllerQuery, { uuid: virtualDeviceUUID }).data
    ?.virtualDevice;
  if (virtualDevice?.__typename !== 'ControllerVirtualDevice') {
    throw new ResourceNotFoundError('Security appliance not found.');
  }

  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'ControllerHardwareDevice') {
    throw new ResourceNotFoundError('Security appliance not found.');
  }

  return (
    <Drawer>
      <DrawerHeader
        icon="pencil"
        heading="Edit security appliance"
        actions={<SecurityApplianceActions virtualDeviceUUID={virtualDeviceUUID} view="edit" />}
        onClose={closeDrawer}
      />
      <SecurityApplianceEditGeneral virtualDevice={{ ...virtualDevice, hardwareDevice }} />
    </Drawer>
  );
}

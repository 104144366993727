import type { UseGraphQLOptions } from '@meterup/graphql';
import { useMemo } from 'react';

import type { NetworkClientsQuery } from '../../../../../gql/graphql';
import type { NetworkClientOptions } from '../../../../../hooks/networkClients/useNetworkClients';
import type { Network } from '../../../../../hooks/useNetworksForCompany';
import type { TimePeriod } from '.';
import { useNetworkClients } from '../../../../../hooks/networkClients/useNetworkClients';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';

type UseFilteredNetworkClientsOptions = {
  networkClientOptions?: NetworkClientOptions;
  useGraphQLOptions?: UseGraphQLOptions<NetworkClientsQuery>;
  network: Network;
};

export function useFilteredNetworkClients({
  networkClientOptions = {},
  useGraphQLOptions = {},
  network,
}: UseFilteredNetworkClientsOptions) {
  const [excludeMeterHardware, setExcludeMeterHardware] = useSearchParamsState<boolean>(
    'excludeMeterHardware',
    true,
  );
  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<TimePeriod>(
    'timePeriod',
    '5',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '5';
  const { filter, ...restOpts } = networkClientOptions;
  const clients = useNetworkClients(
    network,
    {
      filter: {
        lookbackMinutes: parseInt(currentTimePeriod, 10) ?? 5,
        excludeMeterHardware,
        ...networkClientOptions?.filter,
      },
      ...restOpts,
    },
    {
      suspense: true,
      ...useGraphQLOptions,
    },
  );

  return useMemo(
    () => ({
      clients,
      excludeMeterHardware,
      currentTimePeriod,
      setExcludeMeterHardware,
      setCurrentTimePeriod,
    }),
    [
      clients,
      excludeMeterHardware,
      setExcludeMeterHardware,
      currentTimePeriod,
      setCurrentTimePeriod,
    ],
  );
}

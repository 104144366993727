import { checkDefinedOrThrow } from '@meterup/common';

import ClientConnectionEvents from '../../../../../components/Insights/Clients/ClientConnectionEvents';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import Client, { ValidClientDetailsTabs } from './Client';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/clients/:macAddress/connection-events',
  layout: 'VirtualNetworkLayout',
  title: 'Connection events - Client - Insights',
});

export default function ClientConnectionEventsPage() {
  const { macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ClientConnectionEventsPage),
  );

  return (
    <Client
      macAddress={macAddress}
      main={<ClientConnectionEvents macAddress={macAddress} />}
      selectedTab={ValidClientDetailsTabs.ConnectionEvents}
    />
  );
}

import {
  Button,
  FieldContainer,
  HStack,
  LoadingIcon,
  PrimaryFieldComposite,
  SecondaryFieldComposite,
  Select,
  SelectItem,
  SelectSection,
  Small,
  space,
  styled,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import type { Uplink } from '../Insights/Network/utils';
import { graphql } from '../../gql';
import { getPhyInterfaceLabel } from '../Firewall/utils';
import { NoValue } from '../NoValue';
import { StyledLoadingIcon } from '../PingTest2/utils';

interface SpeedTestsProps {
  uplinkDevices: [string, [Uplink, ...Uplink[]]][];
}

const WANSpeedTestMutationDoc = graphql(`
  mutation WANSpeedTestMutation($input: RPCWANSpeedtestInput!) {
    rpcWANSpeedtest(input: $input) {
      downloadSpeedMbps
      uploadSpeedMbps
      latencyNs
      jitterNs
      targetName
      targetHost
      distance
    }
  }
`);

const OverflowSmall = styled(Small, {
  overflow: 'auto',
  whiteSpace: 'nowrap',
});

const LoadingIconContainer = styled(HStack, {
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minHeight: '$44',
});

function nanoToMilli(nano: number): string {
  return (nano / 1000000).toFixed(2);
}

interface SpeedTestResult {
  download: number | null | undefined | undefined;
  upload: number | null | undefined;
  latency: number | null | undefined;
  jitter: number | null | undefined;
  targetName: string | null | undefined;
  targetHost: string | null | undefined;
}

function SpeedTests({ uplinkDevices }: SpeedTestsProps) {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useGraphQLMutation(WANSpeedTestMutationDoc);
  const [result, setResult] = useState<SpeedTestResult>({
    download: null,
    upload: null,
    latency: null,
    jitter: null,
    targetName: null,
    targetHost: null,
  });
  const [interfaceUUID, setInterfaceUUID] = useState(uplinkDevices[0]?.[1]?.[0].UUID);

  const uplinkPhyInterfaces = useMemo(
    () =>
      uplinkDevices.flatMap(([, uplinks]) =>
        uplinks.filter((uplink) => uplink.virtualDevice.__typename === 'ControllerVirtualDevice'),
      ),
    [uplinkDevices],
  );

  const handleRunSpeedTest = () => {
    setResult({
      download: null,
      upload: null,
      latency: null,
      jitter: null,
      targetName: null,
      targetHost: null,
    });

    const selectedPhyInterface =
      uplinkPhyInterfaces?.find((pi) => pi.UUID === interfaceUUID) ?? null;

    mutate(
      {
        input: {
          serialNumber: selectedPhyInterface?.virtualDevice?.hardwareDevice?.serialNumber ?? '',
          phyInterfaceUUID: selectedPhyInterface?.UUID,
        },
      },
      {
        onSuccess: async (data) => {
          setResult({
            download: data.rpcWANSpeedtest.downloadSpeedMbps,
            upload: data.rpcWANSpeedtest.uploadSpeedMbps,
            latency: data.rpcWANSpeedtest.latencyNs,
            jitter: data.rpcWANSpeedtest.jitterNs,
            targetName: data.rpcWANSpeedtest.targetName,
            targetHost: data.rpcWANSpeedtest.targetHost,
          });
          await queryClient.invalidateQueries([
            'speedtests',
            selectedPhyInterface?.virtualDevice?.hardwareDevice?.serialNumber,
          ]);
        },
        onError: () => {
          notify('There was an error running the speed test.', {
            variant: 'negative',
            icon: 'cross',
          });
        },
      },
    );
  };

  return (
    <FieldContainer>
      <PrimaryFieldComposite
        label="Run a test"
        fields={
          <VStack spacing={space(8)}>
            <VStack spacing={space(6)} align="stretch">
              <Select value={interfaceUUID} onValueChange={setInterfaceUUID} width="100%">
                {uplinkDevices.map(([, phyInterfaces]) => (
                  <SelectSection title={phyInterfaces[0]?.virtualDevice?.label}>
                    {phyInterfaces.map((iface) => (
                      <SelectItem key={iface.UUID}>{getPhyInterfaceLabel(iface, false)}</SelectItem>
                    ))}
                  </SelectSection>
                ))}
              </Select>

              <Button
                icon="play"
                arrangement="hidden-label"
                variant="secondary"
                onClick={handleRunSpeedTest}
                disabled={isLoading}
              >
                Run
              </Button>
            </VStack>
          </VStack>
        }
      />
      {isLoading && (
        <HStack>
          <LoadingIconContainer>
            <LoadingIcon color={{ light: 'gray500', dark: 'gray300' }} size={space(24)} />
          </LoadingIconContainer>
        </HStack>
      )}
      {isSuccess && (
        <>
          <SecondaryFieldComposite
            label="Download (Mbps)"
            fields={
              isLoading ? (
                <StyledLoadingIcon />
              ) : (
                <Small family="monospace">
                  {result.download ? `${result.download.toFixed(2)}` : <NoValue />}
                </Small>
              )
            }
          />
          <SecondaryFieldComposite
            label="Upload (Mbps)"
            fields={
              isLoading ? (
                <StyledLoadingIcon />
              ) : (
                <Small family="monospace">
                  {result.upload ? `${result.upload.toFixed(2)}` : <NoValue />}
                </Small>
              )
            }
          />
          <SecondaryFieldComposite
            label="Latency (ms)"
            fields={
              isLoading ? (
                <StyledLoadingIcon />
              ) : (
                <Small family="monospace">
                  {result.latency ? nanoToMilli(result.latency) : <NoValue />}
                </Small>
              )
            }
          />
          <SecondaryFieldComposite
            label="Jitter (ms)"
            fields={
              isLoading ? (
                <StyledLoadingIcon />
              ) : (
                <Small family="monospace">
                  {result.jitter ? nanoToMilli(result.jitter) : <NoValue />}
                </Small>
              )
            }
          />
          <SecondaryFieldComposite
            label="Server name"
            fields={
              isLoading ? (
                <StyledLoadingIcon />
              ) : (
                <Small family="monospace">
                  {result.targetName ? `${result.targetName}` : <NoValue />}
                </Small>
              )
            }
          />
          <SecondaryFieldComposite
            label="Server host"
            fields={
              isLoading ? (
                <StyledLoadingIcon />
              ) : (
                <OverflowSmall family="monospace">
                  {result.targetHost ? `${result.targetHost}` : <NoValue />}
                </OverflowSmall>
              )
            }
          />
        </>
      )}
    </FieldContainer>
  );
}
export default SpeedTests;

import type { Row, Table } from '@tanstack/table-core';
import { CheckboxInput, styled } from '@meterup/atto';
import React from 'react';

import type { RowWithHelpers } from './createColumnBuilder';

const BaseCheckboxContainer = styled('label', {
  display: 'flex',
  cursor: 'pointer',
  width: '$14',
});

const HeaderCheckboxContainer = styled(BaseCheckboxContainer);

const RowCheckboxContainer = styled(BaseCheckboxContainer);

const getRowRange = <T extends any>(
  rows: Row<T>[],
  startIndex: number,
  endIndex: number,
): Row<T>[] => {
  const rangeStart = Math.min(startIndex, endIndex);
  const rangeEnd = Math.max(startIndex, endIndex);
  return rows.filter((row) => {
    const rowIndex = rows.indexOf(row);
    return rowIndex >= rangeStart && rowIndex <= rangeEnd;
  });
};

export function createMultiRowSelector<RowType>() {
  let lastSelectedID: any;

  return {
    id: 'select-col',
    meta: {
      hideSortIcon: true,
    },
    header: ({ table }: { table: Table<RowType> }) => {
      const isSomeOrAllSelected = table.getIsSomeRowsSelected() || table.getIsAllRowsSelected();

      return (
        <HeaderCheckboxContainer>
          <CheckboxInput
            aria-label={isSomeOrAllSelected ? 'Deselect all rows' : 'Select all rows'}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={() => {
              table.getToggleAllRowsSelectedHandler()({
                target: { checked: !isSomeOrAllSelected },
              });
            }}
          />
        </HeaderCheckboxContainer>
      );
    },
    cell: ({ row, table }: { row: RowWithHelpers<RowType>; table: Table<RowType> }) => (
      <RowCheckboxContainer
        onClick={(e) => {
          // prevent click from propagating to row
          if (e.currentTarget.tagName === 'INPUT') return;
          e.stopPropagation();
        }}
      >
        <CheckboxInput
          aria-label={row.getIsSelected() ? 'Deselect row' : 'Select row'}
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onClick={(e: React.MouseEvent<HTMLInputElement>): void => {
            e.preventDefault();
            e.stopPropagation();

            if (e.shiftKey) {
              const { rows } = table.getSortedRowModel();

              const startIndex = rows.findIndex((r) => r.index === row.index);
              const endIndex = rows.findIndex((r) => r.index === Number(lastSelectedID));

              const rowsToToggle = getRowRange(rows, startIndex, endIndex);
              const isCellSelected = rows.find((r) => r.index === row.index)?.getIsSelected();

              table.setRowSelection((old) => ({
                ...old,
                ...Object.fromEntries(rowsToToggle.map((r) => [r.id, !isCellSelected])),
              }));
            } else {
              row.toggleSelected();
            }

            lastSelectedID = row.index;
          }}
        />
      </RowCheckboxContainer>
    ),
  };
}

import type { ResultItem } from '@meterup/graphql';
import { truthy } from '@meterup/common';

import type { GetCompanyUsersQuery } from '../../../gql/graphql';

type User = ResultItem<GetCompanyUsersQuery, 'companyUsers'>['user'];

export function userDisplay(user: User) {
  const nameFields = [user.firstName, user.lastName].filter(truthy);
  const { email } = user;
  if (nameFields.length === 0) {
    return email;
  }
  return `${nameFields.join(' ')}`;
}

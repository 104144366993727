import type { GutterProp } from '@meterup/atto';
import type { DetailHeaderProps } from '@meterup/common';
import type { ReactNode } from 'react';
import { Box, Pane, PaneContent, PaneFooter, Skeleton, space, VStack } from '@meterup/atto';
import { DetailHeader } from '@meterup/common';
import { ErrorBoundary } from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router';

import { Nav } from '../../nav';
import { ErrorFallback } from '../ErrorFallback/ErrorFallback';
import { DetailLoadingFallback } from '../Placeholders/DetailLoadingFallback';

export default function DetailLayout({
  footer,
  gutter,
  header,
  main = <Outlet />,
}: {
  footer?: ReactNode;
  gutter?: GutterProp;
  header?: DetailHeaderProps;
  main?: ReactNode;
}) {
  const location = Nav.useRegionLocation('root');
  return (
    <>
      <Pane layoutMode="detailed">
        <ErrorBoundary fallback={ErrorFallback}>
          <Suspense
            fallback={<DetailHeader heading={<Skeleton height={20} width={200} radius={6} />} />}
          >
            <DetailHeader {...header} />
          </Suspense>
        </ErrorBoundary>
        <PaneContent>
          <ErrorBoundary fallback={ErrorFallback}>
            <Suspense
              fallback={
                <Box style={{ padding: '16px 20px' }}>
                  <VStack spacing={space(16)}>
                    <Skeleton height={120} width="100%" radius={6} />
                    <Skeleton height={120} width="100%" radius={6} />
                    <Skeleton height={120} width="100%" radius={6} />
                  </VStack>
                </Box>
              }
            >
              <QueryErrorResetBoundary key={location.pathname}>
                {({ reset }) => (
                  <ErrorBoundary onReset={reset} fallback={ErrorFallback}>
                    <Suspense fallback={<DetailLoadingFallback />}>
                      <PaneContent gutter={gutter}>{main}</PaneContent>
                    </Suspense>
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
            </Suspense>
          </ErrorBoundary>
        </PaneContent>
      </Pane>
      {footer && (
        <ErrorBoundary fallback={ErrorFallback}>
          <Suspense fallback={<Skeleton width="100%" height={20} radius={6} />}>
            <PaneFooter actions={footer} />
          </Suspense>
        </ErrorBoundary>
      )}
    </>
  );
}

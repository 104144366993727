import type { api } from '@meterup/proto';
import { Body, Button, darkThemeSelector, HStack, space, TextInput } from '@meterup/atto';
import { colors, notify, styled } from '@meterup/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { without } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { updateRuleForCompany } from '../../../../../../api/api';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { processDomainList } from '../../../../../../utils/content_filters';

const List = styled('ul');
const ListItem = styled('li', {
  width: '100%',
  minHeight: '$40',
  padding: '0 $16',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$8',
  strokeAll: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    strokeAll: colors.strokeNeutralDark,
  },
});
const EditableBody = styled(Body, {
  width: '100%',
});
const InlineFieldContainer = styled('div', {
  width: '100%',
  marginLeft: '-8px',
});

function DNSSecurityDomainListItem({
  rule,
  domain,
}: {
  rule: api.ContentFilterRule;
  domain: string;
}) {
  const companyName = useCurrentCompany();
  const queryClient = useQueryClient();
  const [showInput, setShowInput] = useState(false);
  const [editing, setEditing] = useState(false);
  const [updatedDomain, setUpdatedDomain] = useState(domain);
  const ref = useRef<HTMLLIElement>(null);

  const { mutate: deleteDomainMutation, isLoading: deleting } = useMutation(
    ['dns_security', companyName, 'rules', rule.id, 'deleting_domain'],
    () => {
      const apiData = {
        description: rule.description,
        action: rule.action,
        precedence: rule.precedence,
        domains: rule.domains.filter((d) => d !== domain),
      };

      return updateRuleForCompany(companyName, rule.id, apiData);
    },
    {
      onSuccess: async () => {
        notify('Domain removed successfully', { variant: 'positive' });
        await queryClient.invalidateQueries(['dns_security', companyName, 'rules']);
      },
      onError: () => {
        notify('Failed to remove domain', { variant: 'negative' });
      },
    },
  );

  const { mutate: updateRuleMutation, isLoading: saving } = useMutation(
    ['dns_security', companyName, 'rules', rule.id, 'updating'],
    () =>
      updateRuleForCompany(companyName, rule.id, {
        description: rule.description,
        action: rule.action,
        precedence: rule.precedence,
        domains: processDomainList(without(rule.domains, domain), updatedDomain),
      }),
    {
      onSuccess: async () => {
        notify('Domain updated successfully', { variant: 'positive' });
        await queryClient.invalidateQueries(['dns_security', companyName, 'rules']);
        setEditing(false);
        setShowInput(false);
      },
      onError: () => {
        notify('Failed to update domain', { variant: 'negative' });
      },
    },
  );

  const disableDelete =
    queryClient.isMutating({
      mutationKey: ['dns_security', companyName, 'rules', rule.id, 'deleting_domain'],
    }) > 0 ||
    queryClient.isMutating({
      mutationKey: ['dns_security', companyName, 'rules', rule.id, 'updating'],
    }) > 0;

  const disableForRefetching =
    queryClient.isFetching(['dns_security', companyName, 'rules']) > 0 && editing;

  const saveRule = () => {
    updateRuleMutation();
  };

  const reset = useCallback(() => {
    setEditing(false);
    setShowInput(false);
    setUpdatedDomain(domain);
  }, [domain]);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') saveRule();
    if (e.code === 'Escape') reset();
  };

  const handleChange = (val: string) => {
    setUpdatedDomain(val);
  };

  const handleMouseOut = () => {
    if (!editing) setShowInput(false);
  };

  useEffect(() => {
    function handleDocumentClick(event: MouseEvent) {
      if (!editing && ref.current && !ref.current.contains(event.target as Node)) {
        reset();
      }
    }
    function handleDocumentKeyUp(event: KeyboardEvent) {
      if (event.code === 'Escape') reset();
    }
    document.addEventListener('mousedown', handleDocumentClick);
    document.addEventListener('keyup', handleDocumentKeyUp);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
      document.removeEventListener('keyup', handleDocumentKeyUp);
    };
  }, [reset, ref, editing]);

  return (
    <ListItem ref={ref}>
      {showInput ? (
        <InlineFieldContainer onMouseOut={() => handleMouseOut()}>
          <TextInput
            width="100%"
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            onFocus={() => setEditing(true)}
            disabled={saving || disableForRefetching}
            value={updatedDomain}
            aria-label="Domain"
            suffix={
              updatedDomain !== domain && (
                <HStack spacing={space(6)}>
                  <Button
                    variant="secondary"
                    size="small"
                    arrangement="leading-icon"
                    icon="cross"
                    disabled={saving || disableForRefetching}
                    onClick={() => reset()}
                  >
                    ESC
                  </Button>
                  <Button
                    variant="primary"
                    size="small"
                    arrangement="leading-icon"
                    icon="checkmark"
                    loading={saving}
                    disabled={disableForRefetching}
                    onClick={() => saveRule()}
                  >
                    ⏎
                  </Button>
                </HStack>
              )
            }
          />
        </InlineFieldContainer>
      ) : (
        <EditableBody onClick={() => setShowInput(true)} onMouseMove={() => setShowInput(true)}>
          {domain}
        </EditableBody>
      )}
      <Button
        variant="secondary"
        arrangement="hidden-label"
        icon="trash-can"
        size="small"
        onClick={() => deleteDomainMutation()}
        loading={deleting}
        disabled={disableDelete}
      >
        Delete
      </Button>
    </ListItem>
  );
}

export default function DNSSecurityDomainList({ rule }: { rule: api.ContentFilterRule }) {
  return (
    <List>
      {(rule.domains ?? []).sort().map((d) => (
        <DNSSecurityDomainListItem key={d} rule={rule} domain={d} />
      ))}
    </List>
  );
}

import {
  Alert,
  Body,
  Button,
  colors,
  ControlGroup,
  darkThemeSelector,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  fontWeights,
  HStack,
  Icon,
  Link,
  PrimaryField,
  Segment,
  Segments,
  space,
  styled,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import UnifiedFileUploader, {
  type UnifiedFileUploaderProps,
  usePresignedFileUrl,
} from '@meterup/common/src/components/UnifiedFileUploader';
import { makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { type ReactNode, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import type { OnboardingFieldDelegation } from './utils';
import { TextareaField, TextField } from '../../../../components/Form/Fields';
import { RadiusProfilesQuery, SSIDsQuery } from '../../../../components/Wireless/SSIDs/SSIDsUtils';
import { paths } from '../../../../constants';
import { AllowedFolder } from '../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';
import { pluralize } from '../../../../utils/strings';
import { withZodSchema } from '../../../../utils/withZodSchema';
import { AddEditISPForm } from './AddEditISPForm';
import { CRUDList } from './CRUDList';
import { DateTimeWindowForm } from './DateTimeWindowForm';
import {
  ContactForm,
  PrimaryBooleanSegmentToggle,
  PrimaryDateInput,
  PrimaryMultiSelectCheckboxes,
  PrimaryNumericInput,
  PrimarySegmentToggle,
  PrimaryTextInput,
  useContactFormState,
  useMultiSelectCheckboxes,
} from './FormFields';
import { AddEditDeviceForm } from './HardwiredDevices';
import {
  type DocState,
  type Schemas,
  type SubmitValues,
  networkOnboardingDocumentQuery,
} from './useOnboardingDocument';
import {
  deleteOnboardingFieldDelegationMutation,
  fmtISODateString,
  upsertOnboardingFieldDelegationMutation,
} from './utils';

// ⬇️ Shared building blocks for sections

function ClearResponseButton({ onClear }: { onClear: () => void }) {
  const dialogProps = useDialogState();
  return (
    <>
      <Button onClick={dialogProps.state.open} variant="destructive">
        Clear
      </Button>
      <Dialog state={dialogProps.state} slot="title" aria-label="Clear response">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dialogProps.state.close();
          }}
        >
          <DialogHeader
            heading="Are you sure you want to clear your response?"
            icon="information"
          />
          <DialogContent gutter="all">
            <VStack spacing={space(12)}>
              <Body>
                This will clear your response and you will need to re-enter information for this
                form.
              </Body>
            </VStack>
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button variant="secondary" onClick={dialogProps.state.close}>
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  type="submit"
                  onClick={() => {
                    dialogProps.state.close();
                    onClear();
                  }}
                >
                  Clear response
                </Button>
              </>
            }
          />
        </form>
      </Dialog>
    </>
  );
}

const OnboardingSectionMarkIcon = styled(Icon, {
  width: '$10',
  height: '$10',
});

const OnboardingSectionMark = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$14',
  minWidth: '$14',
  height: '$14',
  minHeight: '$14',
  background: colors.tokenBgNeutralLight,
  borderRadius: '99em',

  [darkThemeSelector]: {
    background: colors.tokenBgNeutralDark,
  },

  variants: {
    hasSubmitted: {
      true: {
        background: colors.tokenBgPositiveLight,

        [darkThemeSelector]: {
          background: colors.tokenBgPositiveDark,
        },
      },
    },
  },
});

const OnboardingSectionHeading = styled(Body, {
  fontWeight: fontWeights.bold,
});

const OnboardingSectionCopy = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const OnboardingSectionStart = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$12',
  width: '100%',
});

const OnboardingSectionEnd = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  minWidth: '$120',
  gap: '$8',
});

const OnboardingSectionTop = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$12',
  width: '100%',
});

const OnboardingSectionBottom = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$12',
  padding: '$6 $12',
  marginLeft: '$24',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },
});

const OnboardingSectionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  width: '100%',
  padding: '$8 $12',
  borderRadius: '$10',

  [selectors.hover]: {
    background: colors.bgNeutralLight,
    strokeAll: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.bgNeutralDark,
      strokeAll: colors.strokeNeutralDark,
    },
  },
});

const delegationFormValues = z.object({
  email: z
    .string()
    .nonempty({ message: 'Please enter an email address.' })
    .email({ message: 'Please enter an email address.' }),
  description: z.string().nullish(),
});

type DelegationFormValues = z.infer<typeof delegationFormValues>;

function OnboardingSectionDelegation({
  fieldName,
  delegation,
}: {
  fieldName: string;
  delegation: OnboardingFieldDelegation | null | undefined;
}) {
  const network = useNetwork();
  const upsertDelegation = useGraphQLMutation(upsertOnboardingFieldDelegationMutation);
  const deleteDelegation = useGraphQLMutation(deleteOnboardingFieldDelegationMutation);

  const { state } = useDialogState();
  const queryClient = useQueryClient();

  const { close } = state;

  const handleUpsert = useCallback(
    (values: DelegationFormValues) => {
      upsertDelegation.mutate(
        { networkUUID: network.UUID, fieldName, input: values },
        {
          onSuccess() {
            queryClient.invalidateQueries(
              makeQueryKey(networkOnboardingDocumentQuery, { networkUUID: network.UUID }),
            );
            close();
          },
        },
      );
    },
    [fieldName, upsertDelegation, network.UUID, queryClient, close],
  );

  const handleDelete = useCallback(() => {
    if (!delegation?.UUID) return;
    deleteDelegation.mutate(
      { uuid: delegation.UUID },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(networkOnboardingDocumentQuery, { networkUUID: network.UUID }),
          );
          close();
        },
      },
    );
  }, [delegation?.UUID, deleteDelegation, close, queryClient, network.UUID]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton arrangement="hidden-label" icon="overflow-horizontal">
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuGroup>
            <DropdownMenuItem icon={delegation ? 'checkmark' : 'user'} onSelect={state.open}>
              {delegation ? `Delegated to ${delegation.email}` : 'Delegate'}
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Dialog state={state} preset="narrow">
        <DialogHeader icon="user" heading="Delegate" />
        <Formik<DelegationFormValues>
          initialValues={{
            email: delegation?.email ?? '',
            description: delegation?.description ?? '',
          }}
          onSubmit={handleUpsert}
          validate={withZodSchema(delegationFormValues)}
        >
          <Form>
            <DialogContent gutter="all">
              <VStack spacing={space(16)}>
                <Body>
                  If someone else should be completing this field, please enter their email address
                  and optionally any background information and we can follow up with them directly.
                </Body>
                <TextField name="email" label="Email address" />
                <TextareaField name="description" label="Description" optional />
              </VStack>
            </DialogContent>
            <DialogFooter
              actions={
                <>
                  {delegation && (
                    <Button
                      type="button"
                      onClick={handleDelete}
                      variant="destructive"
                      size="medium"
                    >
                      Remove delegation
                    </Button>
                  )}
                  <Button type="button" onClick={state.close} variant="secondary" size="medium">
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" size="medium">
                    {delegation ? 'Update' : 'Delegate'}
                  </Button>
                </>
              }
            />
          </Form>
        </Formik>
      </Dialog>
    </>
  );
}

function OnboardingSectionView({
  title,
  description,
  previewValue,
  children,
  dialogFooterElements,
  dialogProps,
  onSubmit,
  fieldName,
  delegation,
}: {
  title: string;
  description: ReactNode;
  previewValue: ReactNode;
  children: ReactNode;
  dialogFooterElements: ReactNode | null;
  dialogProps: ReturnType<typeof useDialogState>;
  onSubmit: () => void;
  fieldName: string;
  delegation?: OnboardingFieldDelegation | null;
}) {
  const hasSubmitted = previewValue !== null && previewValue !== undefined;

  return (
    <>
      <OnboardingSectionContainer>
        <OnboardingSectionTop>
          <OnboardingSectionStart>
            <OnboardingSectionMark hasSubmitted={hasSubmitted}>
              {hasSubmitted && (
                <OnboardingSectionMarkIcon
                  icon="checkmark"
                  color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
                />
              )}
            </OnboardingSectionMark>
            <OnboardingSectionCopy>
              <OnboardingSectionHeading>{title}</OnboardingSectionHeading>
            </OnboardingSectionCopy>
          </OnboardingSectionStart>
          <OnboardingSectionEnd>
            <ControlGroup relation="joined" size="small">
              {previewValue ? (
                <Button
                  onClick={dialogProps.state.open}
                  arrangement="leading-icon"
                  icon="pencil"
                  size="small"
                  variant="secondary"
                >
                  Edit
                </Button>
              ) : (
                <Button
                  onClick={dialogProps.state.open}
                  arrangement="leading-icon"
                  icon="pencil"
                  variant="secondary"
                >
                  Fill out
                </Button>
              )}
              <OnboardingSectionDelegation fieldName={fieldName} delegation={delegation} />
            </ControlGroup>
          </OnboardingSectionEnd>
        </OnboardingSectionTop>
        {previewValue && (
          <OnboardingSectionBottom>
            {typeof previewValue === 'string' ? (
              <Body weight="bold">{previewValue}</Body>
            ) : (
              previewValue
            )}
          </OnboardingSectionBottom>
        )}
      </OnboardingSectionContainer>

      <Dialog state={dialogProps.state} slot="title" aria-label={title}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <DialogHeader icon="pencil" heading={title} />
          <DialogContent gutter="all">
            <VStack spacing={space(12)}>
              <Alert variant="neutral" icon="information" copy={description} />
              {children}
            </VStack>
          </DialogContent>
          {dialogFooterElements !== null && <DialogFooter actions={dialogFooterElements} />}
        </form>
      </Dialog>
    </>
  );
}

export function OnboardingDrawerView({
  title,
  children,
  actions,
  secondaryActions,
  onSubmit,
}: {
  title: string;
  children: ReactNode;
  actions: ReactNode | null;
  secondaryActions: ReactNode | null;
  onSubmit: () => void;
}) {
  return (
    <Drawer>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <DrawerHeader icon="information" heading={title} onClose={useCloseDrawerCallback()} />
        <DrawerContent gutter="all">
          <VStack spacing={space(12)}>{children}</VStack>
        </DrawerContent>
        {(actions || secondaryActions) && (
          <DrawerFooter secondaryActions={secondaryActions} actions={actions} />
        )}
      </form>
    </Drawer>
  );
}

function DocumentBasedOnboardingSection(props: {
  title: string;
  description: ReactNode;
  canSubmit: boolean;
  onClearSubmission: () => void;
  onSubmit: () => void;
  previewValue: ReactNode;
  children: ReactNode;
  fieldName: keyof DocState;
  delegation?: OnboardingFieldDelegation | null;
}) {
  const dialogProps = useDialogState();

  const hasSubmitted = props.previewValue !== null && props.previewValue !== undefined;

  const showClearButton = hasSubmitted && dialogProps.state.isOpen;

  const { onClearSubmission, onSubmit, canSubmit, ...rest } = props;

  return (
    <OnboardingSectionView
      {...rest}
      dialogFooterElements={
        <>
          {showClearButton && (
            <ClearResponseButton
              onClear={() => {
                onClearSubmission();
                dialogProps.state.close();
              }}
            />
          )}
          <Button disabled={!canSubmit} type="submit">
            Submit
          </Button>
        </>
      }
      onSubmit={() => {
        if (!canSubmit) {
          return;
        }

        onSubmit();
        dialogProps.state.close();
      }}
      dialogProps={dialogProps}
    />
  );
}

function DashboardBasedOnboardingSection(props: {
  title: string;
  description: ReactNode;
  previewValue: ReactNode;
  children: ReactNode;
  fieldName: string;
  delegation?: OnboardingFieldDelegation | null;
}) {
  const dialogProps = useDialogState();

  return (
    <OnboardingSectionView
      {...props}
      dialogFooterElements={null}
      onSubmit={() => {
        // no-op
      }}
      dialogProps={dialogProps}
    />
  );
}

function Conditional<T extends boolean>({
  children,
  allowUnsure: allowsUnsure,
  question,
  value,
  onSelectYes,
  onSelectNo,
  onSelectUnsure,
}: {
  question: ReactNode;
  children?: ReactNode;
  allowUnsure: T;
  value: T extends true ? boolean | 'unsure' | null : boolean | null;
  onSelectYes: () => void;
  onSelectNo: () => void;
  onSelectUnsure?: () => void;
}) {
  return (
    <VStack spacing={space(12)}>
      <PrimaryField
        label={question}
        element={
          <Segments direction="column">
            <Segment active={value === true} icon="checkmark-circle" onClick={onSelectYes}>
              Yes
            </Segment>
            <Segment active={value === false} icon="minus-circle" onClick={onSelectNo}>
              No
            </Segment>
            {allowsUnsure && onSelectUnsure && (
              <Segment active={value === 'unsure'} icon="question" onClick={onSelectUnsure}>
                Unsure
              </Segment>
            )}
          </Segments>
        }
      />
      {value === true && children}
    </VStack>
  );
}

type SectionProps<T> = {
  title: string;
  description: ReactNode;
  submittedValues: T | null | undefined;
  onSubmit: (values: T) => void;
  onClearSubmission: () => void;
  delegation: OnboardingFieldDelegation | null | undefined;
};

const UploadPreviewHStack = styled(HStack, {
  flexGrow: 1,
});
UploadPreviewHStack.displayName = 'UploadPreviewHStack';

function ViewFileLink({ s3Key }: { s3Key: string }) {
  const { url, status } = usePresignedFileUrl({ s3Key });

  return (
    <Button
      as={Link}
      href={url}
      target="_blank"
      variant="secondary"
      icon={status === 'error' ? 'warning' : 'arrow-up-right'}
      arrangement={status === 'error' ? 'leading-icon' : 'leading-label'}
      size="small"
      loading={status === 'loading'}
      disabled={status === 'error'}
    >
      {status === 'error' ? 'Error loading file' : 'View file'}
    </Button>
  );
}

// ⬇️ Section components that we use in OnboardingForm

export function CertificateOfInsuranceSection(props: SectionProps<SubmitValues<'coi'>>) {
  const [state, setState] = useState<{
    is_coi_required: boolean | 'unsure' | null;
    coi_file_s3_key: string | null;
  }>({
    is_coi_required: props.submittedValues?.is_required ?? null,
    coi_file_s3_key:
      props.submittedValues?.is_required === true ? props.submittedValues.file.s3_key : null,
  });

  let canSubmit = false;
  if (state.is_coi_required === true && state.coi_file_s3_key !== null) {
    canSubmit = true;
  } else if (state.is_coi_required === false || state.is_coi_required === 'unsure') {
    canSubmit = true;
  }

  let previewValue: React.ReactNode = null;
  switch (props.submittedValues?.is_required) {
    case 'unsure':
      previewValue = 'Unsure if COI is required';
      break;
    case true:
      previewValue = (
        <UploadPreviewHStack spacing={space(8)} align="center" justify="between">
          <Body weight="bold">COI uploaded</Body>
          <ViewFileLink s3Key={props.submittedValues.file.s3_key} />
        </UploadPreviewHStack>
      );
      break;
    case false:
      previewValue = 'COI not required';
      break;
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={canSubmit}
      previewValue={previewValue}
      fieldName="coi"
      delegation={props.delegation}
      onClearSubmission={() => {
        setState({
          is_coi_required: null,
          coi_file_s3_key: null,
        });
        props.onClearSubmission();
      }}
      onSubmit={() => {
        if (state.is_coi_required === false || state.is_coi_required === 'unsure') {
          props.onSubmit({
            is_required: state.is_coi_required,
          });
          return;
        }

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { coi_file_s3_key } = state;
        if (!coi_file_s3_key) {
          // shouldn't be possible
          return;
        }

        props.onSubmit({
          is_required: true,
          file: { s3_key: coi_file_s3_key, file_name: 'COI', extension: '.pdf' },
        });
      }}
    >
      <Conditional
        allowUnsure
        value={state.is_coi_required === null ? null : state.is_coi_required}
        question="Does your building require a Certificate of Insurance?"
        onSelectYes={() => setState((prev) => ({ ...prev, is_coi_required: true }))}
        onSelectNo={() => setState({ is_coi_required: false, coi_file_s3_key: null })}
        onSelectUnsure={() => setState({ is_coi_required: 'unsure', coi_file_s3_key: null })}
      >
        <PrimaryField
          label="Upload a Certificate of Insurance"
          element={
            <UnifiedFileUploader
              folder={AllowedFolder.OnboardingDocumentUploads}
              onFileRemoved={() => setState((prev) => ({ ...prev, coi_file_s3_key: null }))}
              onFileUploaded={(fileDetails) =>
                setState((prev) => ({ ...prev, coi_file_s3_key: fileDetails.s3Key }))
              }
              allowedTypes={{
                pdf: true,
              }}
            />
          }
        />
      </Conditional>
    </DocumentBasedOnboardingSection>
  );
}

export function AvgWifiUsersSection(props: SectionProps<SubmitValues<'avg_wifi_users'>>) {
  const [willIncrease, setWillIncrease] = useState<boolean | 'unsure' | null>(
    props.submittedValues?.will_increase ?? null,
  );
  const [currentUsers, setCurrentUsers] = useState<number | null>(
    props.submittedValues?.current_users ?? null,
  );

  let defaultFutureUsers: number | null = null;
  if (props.submittedValues?.will_increase === true) {
    defaultFutureUsers = props.submittedValues?.future_users;
  }
  const [futureUsers, setFutureUsers] = useState<number | null>(defaultFutureUsers);

  const submittableValue = useMemo(() => {
    if (willIncrease === null || currentUsers === null) return null;
    if (!willIncrease) {
      return {
        will_increase: false as const,
        current_users: currentUsers,
      };
    }
    if (futureUsers === null) return null;
    if (futureUsers <= 0) return null;
    return {
      will_increase: true as const,
      current_users: currentUsers,
      future_users: futureUsers,
    };
  }, [willIncrease, currentUsers, futureUsers]);

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      onClearSubmission={() => {
        setWillIncrease(null);
        setCurrentUsers(null);
        setFutureUsers(null);
        props.onClearSubmission();
      }}
      onSubmit={() => {
        if (submittableValue == null) {
          return; // shouldn't be possible
        }
        props.onSubmit(submittableValue);
      }}
      canSubmit={submittableValue !== null}
      previewValue={props.submittedValues ? `${props.submittedValues.current_users} users` : null}
      fieldName="avg_wifi_users"
      delegation={props.delegation}
    >
      <PrimaryNumericInput
        label="Current average users"
        description="The current daily average number of users on your network."
        value={currentUsers ?? 0}
        onChange={(v) => setCurrentUsers(v)}
      />
      <PrimaryBooleanSegmentToggle
        label="Do you expect the number of users to increase?"
        value={willIncrease}
        onChange={(v) => setWillIncrease(v)}
      />
      {willIncrease && (
        <PrimaryNumericInput
          label="Future average users"
          description="The expected future daily average number of users on your network."
          value={futureUsers ?? 0}
          onChange={(v) => setFutureUsers(v)}
        />
      )}
    </DocumentBasedOnboardingSection>
  );
}

function defaultGoLiveDate() {
  const dt = DateTime.now().startOf('day').plus({ months: 3 }).startOf('month');
  return dt.toISODate();
}

export function GoLiveDateSection(props: SectionProps<SubmitValues<'desired_go_live_date'>>) {
  const [date, setDate] = useState(props.submittedValues ?? defaultGoLiveDate());

  const { submittedValues } = props;
  const previewValue = useMemo(() => {
    if (!submittedValues) return null;
    return fmtISODateString(submittedValues);
  }, [submittedValues]);

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      onClearSubmission={() => {
        setDate(defaultGoLiveDate());
        props.onClearSubmission();
      }}
      onSubmit={() => props.onSubmit(date)}
      canSubmit
      previewValue={previewValue}
      fieldName="desired_go_live_date"
      delegation={props.delegation}
    >
      <PrimaryDateInput label={props.title} value={date} onChange={(v) => setDate(v)} />
    </DocumentBasedOnboardingSection>
  );
}

export function ISPSection(props: SectionProps<SubmitValues<'isp_details'>>) {
  const [state, setState] = useState<NonNullable<DocState['isp_details']>>(
    props.submittedValues ?? { procured_by: 'meter_connect' },
  );

  const canSubmit = state?.procured_by === 'meter_connect' || !!state?.details.length;

  let previewValue: string | null = null;
  if (props.submittedValues) {
    if (props.submittedValues.procured_by === 'meter_connect') {
      previewValue = 'Procured by Meter Connect';
    } else {
      const count = props.submittedValues.details.length;
      previewValue = `${count} ${pluralize(count, 'ISP')}`;
    }
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      onSubmit={() => {
        if (!canSubmit) return;
        props.onSubmit(state);
      }}
      canSubmit={canSubmit}
      previewValue={previewValue}
      onClearSubmission={() => {
        setState({ procured_by: 'meter_connect' });
        props.onClearSubmission();
      }}
      fieldName="isp_details"
      delegation={props.delegation}
    >
      <PrimarySegmentToggle
        unSlugLabels
        label="ISPs procured by"
        value={state.procured_by}
        options={['meter_connect', 'customer']}
        onChange={(procuredBy) => {
          if (procuredBy === 'meter_connect') {
            setState({ procured_by: 'meter_connect' });
          } else {
            setState({ procured_by: 'customer', details: [] });
          }
        }}
      />

      {state.procured_by === 'customer' && (
        <CRUDList
          entityName="ISP"
          paneTitle="ISPs"
          icon="ip-address"
          rows={state.details}
          isInline={false}
          onRowsChanged={(v) => {
            setState((prev) => ({ ...prev, details: v }));
          }}
          displayData={{
            headers: ['ISP name', 'Account number'],
            rows: state.details.map((isp) => [isp.provider, isp.account_number]),
          }}
          renderForm={(formProps) => <AddEditISPForm {...formProps} />}
        />
      )}
    </DocumentBasedOnboardingSection>
  );
}

function formatTimeWindowStr(window: Schemas['DATE_TIME_WINDOWS'][number]['start_time']) {
  let minute = String(window.minute);
  if (minute.length === 1) {
    minute = `0${minute}`;
  }
  return `${window.hour}:${minute} ${window.meridiem.toUpperCase()}`;
}

export function SiteSurveyWindowsSection(props: SectionProps<SubmitValues<'site_survey_windows'>>) {
  const [windows, setWindows] = useState<Schemas['DATE_TIME_WINDOWS']>(props.submittedValues ?? []);

  const canSubmit = windows.length > 0;

  let previewValue: string | null = null;
  if (props.submittedValues) {
    const count = props.submittedValues.length;
    previewValue = `${count} ${pluralize(count, 'window')}`;
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      onSubmit={() => {
        if (!canSubmit) return;
        props.onSubmit(windows);
      }}
      canSubmit={canSubmit}
      previewValue={previewValue}
      onClearSubmission={() => {
        setWindows([]);
        props.onClearSubmission();
      }}
      fieldName="site_survey_windows"
    >
      <CRUDList
        entityName="window"
        paneTitle="Site survey windows"
        icon="calendar"
        rows={windows}
        onRowsChanged={(v) => setWindows(v)}
        isInline={false}
        displayData={{
          headers: ['Date', 'Start time', 'End time'],
          rows: windows.map((window) => [
            fmtISODateString(window.date),
            formatTimeWindowStr(window.start_time),
            formatTimeWindowStr(window.end_time),
          ]),
        }}
        renderForm={(formProps) => <DateTimeWindowForm {...formProps} />}
      />
    </DocumentBasedOnboardingSection>
  );
}

export function HardwiredDevicesSection(props: SectionProps<SubmitValues<'hardwired_devices'>>) {
  const [state, setState] = useState<SubmitValues<'hardwired_devices'> | null>(
    props.submittedValues ?? null,
  );

  const canSubmit =
    state?.has_hardwired_devices === false ||
    state?.has_hardwired_devices === 'unsure' ||
    (state?.has_hardwired_devices === true &&
      (state.devices.length > 0 || state.uploaded_device_configuration != null));

  let previewValue: string | null = null;
  if (props.submittedValues) {
    if (props.submittedValues.has_hardwired_devices === false) {
      previewValue = 'No hardwired devices';
    }
    if (props.submittedValues.has_hardwired_devices === true) {
      const count = props.submittedValues.devices.length;

      if (count > 0) {
        previewValue = `${count} ${pluralize(count, 'device')}`;
        if (props.submittedValues.uploaded_device_configuration) {
          previewValue += ', file uploaded';
        }
      } else if (props.submittedValues.uploaded_device_configuration) {
        previewValue = 'File uploaded';
      }
    }
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      onSubmit={() => {
        if (!canSubmit) return;

        props.onSubmit(state);
      }}
      canSubmit={canSubmit}
      previewValue={previewValue}
      onClearSubmission={() => {
        setState(null);
        props.onClearSubmission();
      }}
      fieldName="hardwired_devices"
      delegation={props.delegation}
    >
      <Conditional
        question="Do you have any hardwired devices?"
        value={state?.has_hardwired_devices ?? null}
        allowUnsure
        onSelectYes={() =>
          setState({
            has_hardwired_devices: true,
            devices: [],
            uploaded_device_configuration: null,
          })
        }
        onSelectNo={() => setState({ has_hardwired_devices: false })}
        onSelectUnsure={() => setState({ has_hardwired_devices: 'unsure' })}
      >
        {state?.has_hardwired_devices === true && (
          <>
            <CRUDList
              entityName="device"
              paneTitle="Hardwired devices"
              icon="client"
              rows={state.devices}
              onRowsChanged={(v) =>
                setState((prev) => {
                  if (prev?.has_hardwired_devices === true) {
                    return { ...prev, devices: v };
                  }

                  return prev;
                })
              }
              isInline
              displayData={{
                headers: ['Description', 'IP assignment'],
                rows: state.devices.map((device) => [
                  device.description,
                  device.ip_assignment === 'dhcp' ? 'DHCP' : 'Static',
                ]),
              }}
              renderForm={(formProps) => <AddEditDeviceForm {...formProps} />}
            />

            <PrimaryField
              label="Upload an export of your existing configuration"
              description="A CSV or JSON export from your previous platform, like Meraki."
              optional
              element={
                <UnifiedFileUploader
                  folder={AllowedFolder.OnboardingDocumentUploads}
                  onFileRemoved={() =>
                    setState((prev) => {
                      if (prev?.has_hardwired_devices === true) {
                        return {
                          ...prev,
                          uploaded_device_configuration: null,
                        };
                      }

                      return prev;
                    })
                  }
                  onFileUploaded={(fileDetails) =>
                    setState((prev) => {
                      if (prev?.has_hardwired_devices) {
                        return {
                          ...prev,
                          uploaded_device_configuration: {
                            // TODO: Add file name and extension
                            s3_key: fileDetails.s3Key,
                          },
                        };
                      }

                      return prev;
                    })
                  }
                  allowedTypes={{
                    csv: true,
                    json: true,
                  }}
                />
              }
            />
          </>
        )}
      </Conditional>
    </DocumentBasedOnboardingSection>
  );
}

export function AlertingEmailSection(props: SectionProps<SubmitValues<'alerting_email'>>) {
  const [state, setState] = useState<string | null>(props.submittedValues ?? null);
  return (
    <DocumentBasedOnboardingSection
      {...props}
      previewValue={props.submittedValues}
      canSubmit={state !== null}
      onSubmit={() => {
        if (state === null) {
          return; // shouldn't be possible
        }
        props.onSubmit(state.trim());
      }}
      onClearSubmission={() => {
        setState(null);
        props.onClearSubmission();
      }}
      fieldName="alerting_email"
      delegation={props.delegation}
    >
      <PrimaryTextInput
        type="email"
        placeholder="you@example.com"
        description={props.description}
        label={props.title}
        value={state ?? ''}
        onChange={(v) => setState(v)}
      />
    </DocumentBasedOnboardingSection>
  );
}

export function PortForwardingRequirementsSection(
  props: SectionProps<SubmitValues<'port_forwarding_requirements'>>,
) {
  const [state, setState] = useState<{
    has_requirements: boolean | 'unsure' | null;
    details: string | undefined;
  }>({
    has_requirements: props.submittedValues?.has_requirements ?? null,
    details: props.submittedValues?.details ?? undefined,
  });

  const valuesToSubmit = useMemo(() => {
    if (state.has_requirements === null) {
      return null;
    }

    if (state.has_requirements === false) {
      return {
        has_requirements: false,
        details: undefined,
      };
    }

    if (state.details === undefined) {
      return null;
    }

    return {
      has_requirements: true,
      details: state.details,
    };
  }, [state]);

  let previewValue: string | null = null;
  if (props.submittedValues != null) {
    previewValue = props.submittedValues.has_requirements
      ? 'Requirements specified'
      : 'No requirements specified';
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      previewValue={previewValue}
      canSubmit={valuesToSubmit !== null}
      onSubmit={() => {
        if (valuesToSubmit === null) {
          return; // shouldn't be possible
        }
        props.onSubmit(valuesToSubmit);
      }}
      onClearSubmission={() => {
        setState({
          has_requirements: null,
          details: undefined,
        });
        props.onClearSubmission();
      }}
      fieldName="port_forwarding_requirements"
      delegation={props.delegation}
    >
      <Conditional
        question="Do you have any port forwarding requirements?"
        value={state.has_requirements}
        allowUnsure
        onSelectYes={() => setState((prev) => ({ ...prev, has_requirements: true }))}
        onSelectNo={() => setState((prev) => ({ ...prev, has_requirements: false }))}
        onSelectUnsure={() => setState((prev) => ({ ...prev, has_requirements: 'unsure' }))}
      >
        <PrimaryTextInput
          multiline
          label="Port forwarding details"
          description="Please provide details about remote access requirements."
          placeholder="IP: 80 1.2.3.34:80 -> 192.168.0.20:80"
          value={state.details ?? ''}
          onChange={(v) => setState((prev) => ({ ...prev, details: v }))}
        />
      </Conditional>
    </DocumentBasedOnboardingSection>
  );
}

export function OtherRequirementsSection(props: SectionProps<SubmitValues<'other_requirements'>>) {
  const [state, setState] = useState<{
    has_requirements: boolean | 'unsure' | null;
    details: string | undefined;
  }>({
    has_requirements: props.submittedValues?.has_requirements ?? null,
    details: props.submittedValues?.details ?? undefined,
  });

  const valuesToSubmit = useMemo(() => {
    if (state.has_requirements === null) {
      return null;
    }

    if (state.has_requirements === false) {
      return {
        has_requirements: false,
        details: undefined,
      };
    }

    if (state.details === undefined) {
      return null;
    }

    return {
      has_requirements: true,
      details: state.details,
    };
  }, [state]);

  let previewValue: string | null = null;
  if (props.submittedValues != null) {
    previewValue = props.submittedValues.has_requirements
      ? 'Requirements specified'
      : 'No requirements specified';
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      previewValue={previewValue}
      canSubmit={valuesToSubmit !== null}
      onSubmit={() => {
        if (valuesToSubmit === null) {
          return; // shouldn't be possible
        }
        props.onSubmit(valuesToSubmit);
      }}
      onClearSubmission={() => {
        setState({
          has_requirements: null,
          details: undefined,
        });
        props.onClearSubmission();
      }}
      fieldName="other_requirements"
      delegation={props.delegation}
    >
      <Conditional
        question="Are there any other configuration requirements that you would like to discuss?"
        value={state.has_requirements}
        allowUnsure
        onSelectYes={() => setState((prev) => ({ ...prev, has_requirements: true }))}
        onSelectNo={() => setState((prev) => ({ ...prev, has_requirements: false }))}
        onSelectUnsure={() => setState((prev) => ({ ...prev, has_requirements: 'unsure' }))}
      >
        <PrimaryTextInput
          multiline
          label="Requirement details"
          description="Please provide details about any other requirements that you would like to discuss."
          value={state.details ?? ''}
          onChange={(v) => setState((prev) => ({ ...prev, details: v }))}
        />
      </Conditional>
    </DocumentBasedOnboardingSection>
  );
}

export function ConditionalWithDetailsSection(
  props: SectionProps<Schemas['CONDITIONAL_WITH_DETAILS']> & {
    previewValue: {
      selected: string;
      unselected: string;
    };
    conditionalQuestion?: string;
    details: {
      label: string;
      description: string;
    };
    fieldName: keyof DocState;
    delegation: OnboardingFieldDelegation | null | undefined;
  },
) {
  const [state, setState] = useState<{
    is_selected: boolean | null;
    details: string | undefined;
  }>({
    is_selected: props.submittedValues?.is_selected ?? null,
    details: props.submittedValues?.details ?? undefined,
  });

  const valuesToSubmit = useMemo(() => {
    if (state.is_selected === null) {
      return null;
    }

    if (state.is_selected === false) {
      return {
        is_selected: false,
        details: undefined,
      };
    }

    if (state.details === undefined) {
      return null;
    }

    return {
      is_selected: true,
      details: state.details,
    };
  }, [state]);

  let previewValue: string | null = null;
  if (props.submittedValues != null) {
    previewValue = props.submittedValues.is_selected
      ? props.previewValue.selected
      : props.previewValue.unselected;
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      previewValue={previewValue}
      canSubmit={valuesToSubmit !== null}
      onSubmit={() => {
        if (valuesToSubmit === null) {
          return; // shouldn't be possible
        }
        props.onSubmit(valuesToSubmit);
      }}
      onClearSubmission={() => {
        setState({
          is_selected: null,
          details: undefined,
        });
        props.onClearSubmission();
      }}
      fieldName={props.fieldName}
      delegation={props.delegation}
    >
      <Conditional
        question={props.conditionalQuestion ?? props.description}
        value={state.is_selected}
        allowUnsure={false}
        onSelectYes={() => setState((prev) => ({ ...prev, is_selected: true }))}
        onSelectNo={() => setState((prev) => ({ ...prev, is_selected: false }))}
      >
        <PrimaryTextInput
          multiline
          label={props.details.label}
          description={props.details.description}
          value={state.details ?? ''}
          onChange={(v) => setState((prev) => ({ ...prev, details: v }))}
        />
      </Conditional>
    </DocumentBasedOnboardingSection>
  );
}

export function AccessDetailsSection(props: SectionProps<SubmitValues<'access_details'>>) {
  const [state, setState] = useState<string | undefined>(props.submittedValues ?? undefined);

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      previewValue={state ?? null}
      canSubmit={!!state}
      onSubmit={() => {
        if (!state) {
          return; // shouldn't be possible
        }

        props.onSubmit(state);
      }}
      onClearSubmission={() => {
        setState(undefined);
        props.onClearSubmission();
      }}
      fieldName="access_details"
      delegation={props.delegation}
    >
      <PrimaryTextInput
        multiline
        label="Access details"
        description="Please provide details about access requirements."
        value={state ?? ''}
        onChange={setState}
      />
    </DocumentBasedOnboardingSection>
  );
}

export function UnionContractorsSection(
  props: SectionProps<SubmitValues<'are_union_contractors_required'>>,
) {
  const [state, setState] = useState<boolean | 'unsure' | null>(props.submittedValues ?? null);

  let previewValue: string | null = null;
  if (props.submittedValues !== null) {
    if (props.submittedValues === 'unsure') {
      previewValue = 'Unsure about union contractors';
    } else {
      previewValue = props.submittedValues
        ? 'Union contractors required'
        : `Union contractors not required`;
    }
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={state !== null}
      onSubmit={() => {
        if (state === null) {
          return; // shouldn't be possible
        }
        props.onSubmit(state);
      }}
      onClearSubmission={() => {
        setState(null);
        props.onClearSubmission();
      }}
      previewValue={previewValue}
      fieldName="are_union_contractors_required"
      delegation={props.delegation}
    >
      <PrimaryBooleanSegmentToggle
        label="Are union contractors required?"
        value={state}
        onChange={setState}
      />
    </DocumentBasedOnboardingSection>
  );
}

export function SiteToSiteVPNSection(props: SectionProps<SubmitValues<'has_site_to_site_vpn'>>) {
  const [state, setState] = useState<boolean | 'unsure' | null>(props.submittedValues ?? null);

  let previewValue: string | null = null;
  if (props.submittedValues !== null) {
    if (props.submittedValues === 'unsure') {
      previewValue = 'Unsure about site-to-site VPN';
    } else {
      previewValue = props.submittedValues
        ? 'Has site-to-site VPN'
        : `Does not have site-to-site VPN`;
    }
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={state !== null}
      onSubmit={() => {
        if (state === null) {
          return; // shouldn't be possible
        }
        props.onSubmit(state);
      }}
      onClearSubmission={() => {
        setState(null);
        props.onClearSubmission();
      }}
      previewValue={previewValue}
      fieldName="has_site_to_site_vpn"
      delegation={props.delegation}
    >
      <PrimaryBooleanSegmentToggle
        label="Does your network have a site-to-site VPN?"
        value={state}
        onChange={(v) => setState(v)}
      />
    </DocumentBasedOnboardingSection>
  );
}

export function ClientVPNSection(props: SectionProps<SubmitValues<'has_client_vpn'>>) {
  const [state, setState] = useState<boolean | 'unsure' | null>(props.submittedValues ?? null);

  let previewValue: string | null = null;
  if (props.submittedValues !== null) {
    previewValue = props.submittedValues ? 'Has client VPN' : `Does not have client VPN`;
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={state !== null}
      onSubmit={() => {
        if (state === null) {
          return; // shouldn't be possible
        }
        props.onSubmit(state);
      }}
      onClearSubmission={() => {
        setState(null);
        props.onClearSubmission();
      }}
      previewValue={previewValue}
      fieldName="has_client_vpn"
      delegation={props.delegation}
    >
      <PrimaryBooleanSegmentToggle
        label="Does your network have a client VPN?"
        value={state}
        onChange={(v) => setState(v)}
      />
    </DocumentBasedOnboardingSection>
  );
}

export function BooleanSection(
  props: SectionProps<boolean | 'unsure'> & {
    previewValue: {
      selected: string;
      unselected: string;
    };
    fieldName: keyof DocState;
  },
) {
  const [state, setState] = useState<boolean | 'unsure' | null>(props.submittedValues ?? null);

  let previewValue: string | null = null;
  if (props.submittedValues !== null) {
    previewValue = props.submittedValues
      ? props.previewValue.selected
      : props.previewValue.unselected;
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={state !== null}
      onSubmit={() => {
        if (state === null) {
          return; // shouldn't be possible
        }
        props.onSubmit(state);
      }}
      onClearSubmission={() => {
        setState(null);
        props.onClearSubmission();
      }}
      previewValue={previewValue}
      fieldName={props.fieldName}
      delegation={props.delegation}
    >
      <PrimaryBooleanSegmentToggle
        label={props.description}
        value={state}
        onChange={(v) => setState(v)}
      />
    </DocumentBasedOnboardingSection>
  );
}

export function UploadSection(
  props: SectionProps<Schemas['FILE']> &
    Pick<UnifiedFileUploaderProps, 'allowedTypes' | 'accept'> & {
      fieldName: keyof DocState;
    },
) {
  const [s3Key, setS3Key] = useState<string | null>(props.submittedValues?.s3_key ?? null);

  const canSubmit = s3Key !== null;

  const previewValue = props.submittedValues ? (
    <UploadPreviewHStack spacing={space(8)} align="center" justify="between">
      <Body weight="bold">File uploaded</Body>
      <ViewFileLink s3Key={props.submittedValues.s3_key} />
    </UploadPreviewHStack>
  ) : null;

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={canSubmit}
      previewValue={previewValue}
      onClearSubmission={() => {
        setS3Key(null);
        props.onClearSubmission();
      }}
      onSubmit={() => {
        if (!s3Key) {
          // shouldn't be possible
          return;
        }

        // TODO: Add file file name and extension
        props.onSubmit({ s3_key: s3Key });
      }}
      fieldName={props.fieldName}
      delegation={props.delegation}
    >
      <PrimaryField
        label="Upload file"
        element={
          <UnifiedFileUploader
            folder={AllowedFolder.OnboardingDocumentUploads}
            onFileRemoved={() => setS3Key(null)}
            onFileUploaded={(fileDetails) => setS3Key(fileDetails.s3Key)}
            allowedTypes={props.allowedTypes}
            accept={props.accept}
          />
        }
      />
    </DocumentBasedOnboardingSection>
  );
}

export function RiserManagerContactSection(
  props: SectionProps<SubmitValues<'riser_manager_contact'>>,
) {
  const [hasRiserManager, setHasRiserManager] = useState<boolean | 'unsure' | null>(
    props.submittedValues?.has_riser_manager ?? null,
  );

  let defaultContactInfo: Schemas['CONTACT_INFO'] | undefined;
  if (props.submittedValues?.has_riser_manager === true) {
    defaultContactInfo = props.submittedValues.contact_info;
  }

  const contactForm = useContactFormState(defaultContactInfo);

  const valuesToSubmit = useMemo(() => {
    if (hasRiserManager === null) {
      return null;
    }
    if (hasRiserManager === false) {
      return {
        has_riser_manager: false as const,
      };
    }
    if (hasRiserManager === 'unsure') {
      return {
        has_riser_manager: 'unsure' as const,
      };
    }

    if (contactForm.valuesToSubmit === null) {
      return null;
    }

    return {
      has_riser_manager: true as const,
      contact_info: contactForm.valuesToSubmit,
    };
  }, [contactForm.valuesToSubmit, hasRiserManager]);

  let previewValue: string | null = null;
  if (props.submittedValues != null) {
    if (props.submittedValues.has_riser_manager === 'unsure') {
      previewValue = 'Unknown riser manager';
    } else {
      previewValue =
        props.submittedValues.has_riser_manager === true
          ? props.submittedValues.contact_info.name
          : 'No riser manager';
    }
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={valuesToSubmit !== null}
      onClearSubmission={() => {
        setHasRiserManager(null);
        contactForm.clearState();
        props.onClearSubmission();
      }}
      onSubmit={() => {
        if (valuesToSubmit === null) {
          return;
        }
        props.onSubmit(valuesToSubmit);
      }}
      previewValue={previewValue}
      fieldName="riser_manager_contact"
      delegation={props.delegation}
    >
      <Conditional
        allowUnsure
        value={hasRiserManager}
        question="Does your building have a riser manager?"
        onSelectYes={() => setHasRiserManager(true)}
        onSelectNo={() => setHasRiserManager(false)}
        onSelectUnsure={() => setHasRiserManager('unsure')}
      >
        <ContactForm {...contactForm} />
      </Conditional>
    </DocumentBasedOnboardingSection>
  );
}

export function ExistingNetworkLoginSection(
  props: SectionProps<SubmitValues<'existing_network_login_details'>>,
) {
  const [state, setState] = useState<DocState['existing_network_login_details']>(
    props.submittedValues ?? null,
  );
  const [showPassword, setShowPassword] = useState(false);

  const canSubmit =
    !!state &&
    (state?.willing_to_share !== true ||
      !!(state.platform_name && state.username && state.password));

  // eslint-disable-next-line consistent-return
  const previewValue: string | null = useMemo(() => {
    if (!props.submittedValues) return null;

    switch (props.submittedValues.willing_to_share) {
      case true:
        return `${props.submittedValues.platform_name} information shared`;
      case false:
        return 'Not willing or able to share';
      case 'unsure':
        return 'Unsure if able to share';
    }
  }, [props.submittedValues]);

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      onSubmit={() => {
        if (!canSubmit) return;
        props.onSubmit(state);
      }}
      canSubmit={canSubmit}
      previewValue={previewValue}
      onClearSubmission={() => {
        setState(null);
        props.onClearSubmission();
      }}
      fieldName="existing_network_login_details"
      delegation={props.delegation}
    >
      <Conditional
        allowUnsure
        question="Are you willing and able to share login information for your existing network platform?"
        value={state?.willing_to_share ?? null}
        onSelectYes={() =>
          setState({ willing_to_share: true, platform_name: '', username: '', password: '' })
        }
        onSelectNo={() => setState({ willing_to_share: false })}
        onSelectUnsure={() => setState({ willing_to_share: 'unsure' })}
      >
        {state?.willing_to_share === true && (
          <VStack spacing={space(12)}>
            <PrimaryField
              label="Platform name"
              description="The platform that you used prior to Meter"
              element={
                <TextInput
                  type="text"
                  value={state.platform_name}
                  onChange={(value) => {
                    setState((prev) => {
                      if (prev?.willing_to_share === true) {
                        return { ...prev, platform_name: value };
                      }

                      return prev;
                    });
                  }}
                />
              }
            />
            <PrimaryField
              label="Login username"
              element={
                <TextInput
                  type="text"
                  value={state.username}
                  onChange={(value) => {
                    setState((prev) => {
                      if (prev?.willing_to_share === true) {
                        return { ...prev, username: value };
                      }

                      return prev;
                    });
                  }}
                />
              }
            />
            <PrimaryField
              label="Login password"
              element={
                <TextInput
                  type={showPassword ? 'text' : 'password'}
                  value={state.password}
                  onChange={(value) => {
                    setState((prev) => {
                      if (prev?.willing_to_share === true) {
                        return { ...prev, password: value };
                      }

                      return prev;
                    });
                  }}
                />
              }
              controls={
                <Button
                  icon={showPassword ? 'eye-closed' : 'eye-open'}
                  arrangement="hidden-label"
                  variant="secondary"
                  size="small"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                >
                  {showPassword ? 'Hide' : 'Show'} password
                </Button>
              }
            />
          </VStack>
        )}
      </Conditional>
    </DocumentBasedOnboardingSection>
  );
}

// making this a const so we can re-use without typos
const OTHER = 'Other';

export function WifiDeviceTypesSection(props: SectionProps<SubmitValues<'wifi_device_types'>>) {
  const [otherDescription, setOtherDescription] = useState<string | null>(
    props.submittedValues?.otherDescription ?? null,
  );
  const checkboxState = useMultiSelectCheckboxes({
    options: ['Macs', 'PCs', 'Smartphones', 'Speakers', 'Robots', 'Printers', 'Scanners', OTHER],
    defaultCheckedValues: props.submittedValues?.types ?? [],
  });

  const hasOther = checkboxState.checked.includes(OTHER);

  const valuesToSubmit = useMemo(() => {
    if (!hasOther) {
      return {
        types: checkboxState.checked,
        otherDescription: undefined,
      };
    }
    if (otherDescription === null || otherDescription === '') {
      return null;
    }

    return {
      types: checkboxState.checked,
      otherDescription,
    };
  }, [checkboxState.checked, hasOther, otherDescription]);

  let previewValue: string | null = null;
  if (props.submittedValues) {
    const count = props.submittedValues.types.length;
    previewValue = `${count} ${pluralize(count, 'device type')}`;
  }

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={valuesToSubmit !== null}
      previewValue={previewValue}
      onSubmit={() => {
        if (valuesToSubmit === null) {
          return;
        }
        props.onSubmit(valuesToSubmit);
      }}
      onClearSubmission={() => {
        checkboxState.clearValues();
        setOtherDescription(null);
        props.onClearSubmission();
      }}
      fieldName="wifi_device_types"
      delegation={props.delegation}
    >
      <PrimaryMultiSelectCheckboxes
        label="Select all device types that you expect to be used on your Wi-Fi"
        state={checkboxState}
      />
      {hasOther && (
        <PrimaryTextInput
          multiline
          label="Please describe the other device types"
          description="Please provide a description of the other device types that you expect to be used on your Wi-Fi."
          value={otherDescription ?? ''}
          onChange={(v) => setOtherDescription(v)}
        />
      )}
    </DocumentBasedOnboardingSection>
  );
}

export function ContactInfoSection(
  props: SectionProps<Schemas['CONTACT_INFO']> & { fieldName: keyof DocState },
) {
  const contactForm = useContactFormState(props.submittedValues ?? undefined);
  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      canSubmit={contactForm.valuesToSubmit !== null}
      onClearSubmission={() => {
        contactForm.clearState();
        props.onClearSubmission();
      }}
      onSubmit={() => {
        if (contactForm.valuesToSubmit === null) {
          return;
        }
        props.onSubmit(contactForm.valuesToSubmit);
      }}
      previewValue={props.submittedValues?.name}
      fieldName={props.fieldName}
      delegation={props.delegation}
    >
      <ContactForm {...contactForm} />
    </DocumentBasedOnboardingSection>
  );
}

export function SSIDSection(props: {
  title: string;
  description: string;
  delegation: OnboardingFieldDelegation | null | undefined;
}) {
  const currentCompany = useCurrentCompany();
  const network = useNetwork();

  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork;

  const navigate = useNavigate();

  const networkSlug = network.slug;

  const href = useMemo(() => {
    if (!currentCompany || !networkSlug) return null;
    return makeLink(paths.pages.SSIDsPage, {
      companyName: currentCompany,
      networkSlug,
    });
  }, [currentCompany, networkSlug]);

  let ssidCount = 0;
  if (ssids) {
    ssidCount = ssids.length;
  }

  const hasSSIDs = ssidCount > 0;

  return (
    <DashboardBasedOnboardingSection
      title={props.title}
      description={props.description}
      previewValue={hasSSIDs ? `${ssidCount} ${pluralize(ssidCount, 'SSID')}` : null}
      fieldName="ssids"
      delegation={props.delegation}
    >
      {href && (
        <EmptyState
          heading="Configure SSIDs"
          subheading={
            hasSSIDs
              ? 'You have already added at least one SSID. You may modify your SSID configuration using the link below.'
              : 'Visit the SSIDs page to complete this task. Once at least one SSID has been added, this section will automatically be marked as complete.'
          }
          action={
            <Button
              onClick={() => navigate(href)}
              variant="secondary"
              icon="chevron-right"
              size="large"
            >
              Go to SSIDs page
            </Button>
          }
        />
      )}
      {!href && (
        <EmptyState
          icon="attention"
          heading="An unexpected error occurred"
          subheading="Please contact Meter support for assistance."
        />
      )}
    </DashboardBasedOnboardingSection>
  );
}

export function RADIUSSection(props: SectionProps<SubmitValues<'radius_profile_requirements'>>) {
  const [hasRADIUSProfiles, setHasRADIUSProfiles] = useState<boolean | 'unsure' | null>(
    props.submittedValues ?? null,
  );
  const currentCompany = useCurrentCompany();
  const network = useNetwork();

  const radiusProfiles = useGraphQL(RadiusProfilesQuery, { networkUUID: network.UUID }).data
    ?.encryption8021XsForNetwork;

  const navigate = useNavigate();

  const networkSlug = network.slug;

  const href = useMemo(() => {
    if (!currentCompany || !networkSlug) return null;
    return makeLink(paths.pages.RADIUSProfilesPage, {
      companyName: currentCompany,
      networkSlug,
    });
  }, [currentCompany, networkSlug]);

  let radiusProfilesCount = 0;
  if (radiusProfiles) {
    radiusProfilesCount = radiusProfiles.length;
  }

  const areRADIUSProfilesConfigured = radiusProfilesCount > 0;

  const previewValue = useMemo(() => {
    if (props.submittedValues === 'unsure') return 'Unsure about RADIUS accounting';
    if (props.submittedValues === false) return 'RADIUS accounting not used';

    if (props.submittedValues === true && areRADIUSProfilesConfigured) {
      return `${radiusProfilesCount} ${pluralize(radiusProfilesCount, 'RADIUS profile')}`;
    }

    return null;
  }, [props.submittedValues, radiusProfilesCount, areRADIUSProfilesConfigured]);

  return (
    <DocumentBasedOnboardingSection
      title={props.title}
      description={props.description}
      previewValue={previewValue}
      canSubmit={hasRADIUSProfiles != null}
      onSubmit={() => {
        if (hasRADIUSProfiles == null) return;
        props.onSubmit(hasRADIUSProfiles);
      }}
      onClearSubmission={() => {
        setHasRADIUSProfiles(null);
        props.onClearSubmission();
      }}
      fieldName="radius_profile_requirements"
      delegation={props.delegation}
    >
      <Conditional
        allowUnsure
        question="Does your network use RADIUS accounting?"
        value={hasRADIUSProfiles}
        onSelectYes={() => setHasRADIUSProfiles(true)}
        onSelectNo={() => setHasRADIUSProfiles(false)}
        onSelectUnsure={() => setHasRADIUSProfiles('unsure')}
      >
        {href && (
          <EmptyState
            heading="Configure RADIUS profiles"
            subheading={
              areRADIUSProfilesConfigured
                ? 'You have already added at least one RADIUS profile. You may modify your RADIUS configuration using the link below.'
                : 'Visit the RADIUS profiles page to complete this task. Once at least one RADIUS profile has been added, this section will automatically be marked as complete.'
            }
            action={
              <Button
                onClick={() => navigate(href)}
                variant="secondary"
                icon="chevron-right"
                size="large"
              >
                Go to RADIUS profiles page
              </Button>
            }
          />
        )}
        {!href && (
          <EmptyState
            icon="attention"
            heading="An unexpected error occurred"
            subheading="Please contact Meter support for assistance."
          />
        )}
      </Conditional>
    </DocumentBasedOnboardingSection>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  space,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/connect-ui/src/components/stolenFromFrontends/notify';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { RpcpduOutletCycleInput } from '../../../gql/graphql';
import { rpcPDUOutletCycleMutation } from '../../Devices/utils';
import { PowerDistributionUnitOutletsQuery } from './utils';

type OutletCyclePowerDialogProps = {
  state: OverlayTriggerState;
  // the serialNumber and virtualDeviceUUID of the PDU from which the outlet's power is cycled
  serialNumber: string;
  virtualDeviceUUID: string;
  // label describing the selected outlet(s)
  label?: string | null;
  outletUUIDs: RpcpduOutletCycleInput['outletUUIDs'];
};

export function OutletCyclePowerDialog({
  state,
  label,
  serialNumber,
  virtualDeviceUUID,
  outletUUIDs,
}: OutletCyclePowerDialogProps) {
  const queryClient = useQueryClient();
  const cyclePowerMutation = useGraphQLMutation(rpcPDUOutletCycleMutation);
  const handleCyclePower = useCallback(() => {
    if (cyclePowerMutation.isLoading) return;

    cyclePowerMutation.mutate(
      { serialNumber, input: { outletUUIDs } },
      {
        onSuccess: () => {
          notify('Successfully initiated the power cycle for the selected outlet.', {
            variant: 'positive',
          });
          queryClient.invalidateQueries(
            makeQueryKey(PowerDistributionUnitOutletsQuery, { virtualDeviceUUID }),
          );
        },
        onError: (err) => {
          notify(
            `There was an error cycling the power for the selected outlet${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
        onSettled: state.close,
      },
    );
  }, [cyclePowerMutation, queryClient, serialNumber, outletUUIDs, virtualDeviceUUID, state]);
  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader heading="Cycle outlet power" />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>
          <Alert
            icon="attention"
            variant="negative"
            heading="Cycling the power of this outlet in the PDU can affect the network."
            copy="Any device(s) connected to this oulet will not have power."
          />
          <Body>
            Are you sure you want to cycle the power to <strong>{label ?? 'this outlet'}</strong>?
            This action cannot be undone.
          </Body>
        </VStack>
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button variant="secondary" onClick={state.close}>
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleCyclePower}
              variant="destructive"
              disabled={cyclePowerMutation.isLoading}
              loading={cyclePowerMutation.isLoading}
            >
              Cycle power
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

import { Alert, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow, isDefined, notify } from '@meterup/common';
import { Form, Formik } from 'formik';
import React from 'react';
import { Navigate } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';

import type { ValidStaticClient } from './form_data';
import {
  CancelAndGoBackButton,
  DrawerFormSaveButton,
} from '../../../../../../components/form_buttons';
import { paths } from '../../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../../hooks/useControllerConfig';
import { useVLANConfigModel } from '../../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../../nav';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../../stitches';
import { makeLink } from '../../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../../utils/withZodSchema';
import { validStaticClient } from './form_data';
import { IPField, MACField, NameField } from './form_fields';
import { useUpsertStaticClientMutation } from './mutations';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/static/create',
});

export default function VLANStaticIPCreatePage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VLANStaticIPCreatePage),
  );
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const [params] = useSearchParams();

  const closeDrawer = useCloseDrawerCallback();

  const configModel = useControllerConfig(controllerName);
  const vlan = useVLANConfigModel(controllerName, vlanName);

  const navigate = useNavigate();
  const mutation = useUpsertStaticClientMutation(controllerName, configModel, vlan);

  const macAddress = params.get('mac');

  if (isDefined(macAddress) && vlan.staticClients.find((d) => d.macAddress === macAddress)) {
    return (
      <Navigate
        to={Nav.makeTo({
          root: makeLink(paths.pages.VLANStaticIPListPage, {
            vlanName,
            controllerName,
            companyName,
          }),
          drawer: makeLink(paths.drawers.VLANStaticIPEditPage, {
            vlanName,
            macAddress,
            companyName,
            controllerName,
          }),
        })}
        replace
      />
    );
  }

  return (
    <Formik<ValidStaticClient>
      validate={withZodSchema(validStaticClient)}
      initialValues={{
        existingIPs: vlan.staticClients.map((d) => d.ipAddress),
        existingNames: vlan.staticClients.map((d) => d.name),
        subnet: vlan.subnetString,
        name: params.get('name') ?? '',
        mac: macAddress ?? '',
        ip: params.get('ip') ?? '',
      }}
      onSubmit={(values) => {
        mutation.mutate(values, {
          onSuccess: () => {
            notify('Created', { variant: 'positive' });
            navigate(
              Nav.makeTo({
                root: makeLink(paths.pages.VLANStaticIPListPage, {
                  vlanName,
                  controllerName,
                  companyName,
                }),
                drawer: makeLink(paths.drawers.VLANStaticIPEditPage, {
                  vlanName,
                  macAddress: values.mac,
                  controllerName,
                  companyName,
                }),
              }),
            );
          },
          onError: () => {
            notify('Failed to save changes', { variant: 'negative' });
          },
        });
      }}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader icon="ip-address" heading="Add static IP" onClose={closeDrawer} />
          <DrawerContent>
            <Alert
              icon="information"
              variant="alternative"
              copy="Adding this static IP will restart the network. All clients will lose connectivity for approximately 30 seconds."
            />
            <NameField />
            <MACField />
            <IPField vlan={vlan} />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <CancelAndGoBackButton />
                <DrawerFormSaveButton />
              </>
            }
          />
        </Drawer>
      </StyledForm>
    </Formik>
  );
}

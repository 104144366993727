import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Outlet } from 'react-router';

import { DockPageLayout } from '../../../components/DockPageLayout';
import { MainAndDrawerLayout } from '../../../components/MainAndDrawerLayout';
import { DrawerRoutes } from '../../../components/route_elements';
import { SettingsSidebar } from './SettingsSidebar';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings',
  name: 'SettingsLayout',
  layout: 'AppLayout',
  title: 'Settings',
});

export default function SettingsLayout() {
  return (
    <DockPageLayout
      sidebar={<SettingsSidebar />}
      main={<MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />}
    />
  );
}

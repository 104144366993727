import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Sidebar, SidebarGroup, SidebarGroups, SidebarTarget } from '@meterup/atto';
import { Outlet } from 'react-router';

import { DockPageLayout } from '../../../components/DockPageLayout';
import { MainAndDrawerLayout } from '../../../components/MainAndDrawerLayout';
import { ReactRouterLink } from '../../../components/ReactRouterLink';
import { DrawerRoutes } from '../../../components/route_elements';
import { paths } from '../../../constants';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { ValidNOSTabs } from './nos/NOS.page';

function OperatorSidebarEntries({ onNavItemClick }: { onNavItemClick?: () => void }) {
  const isActiveTest = useIsActiveMatcher();

  const isFirmwareBulkUpgraderActive =
    isActiveTest({
      path: paths.pages.FirmwareBulkUpgradesListPage,
      end: false,
    }) ||
    isActiveTest({
      path: paths.pages.AddFirmwareBulkUpgradePage,
      end: false,
    });

  return (
    <Sidebar product="operator" heading="Operator">
      <SidebarGroups>
        <SidebarGroup>
          <SidebarTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.CompanyListPage, {})}
            label="Companies"
            isSelected={isActiveTest({ path: paths.pages.CompanyListPage, end: false })}
            icon="company"
            onClick={onNavItemClick}
          />
          <SidebarTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.SecurityApplianceListPage, {})}
            label="Security appliances"
            isSelected={isActiveTest({ path: paths.pages.SecurityApplianceListPage, end: false })}
            icon="security-appliance"
            onClick={onNavItemClick}
          />
          <SidebarTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.NOSPage, { tab: ValidNOSTabs.Versions })}
            label="NOS config"
            isSelected={isActiveTest({ path: paths.pages.NOSPage, end: false })}
            icon="version"
            onClick={onNavItemClick}
          />
          <SidebarTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.AddFirmwareBulkUpgradePage, {})}
            label="Firmware bulk upgrader"
            isSelected={isFirmwareBulkUpgraderActive}
            icon="upgrading"
            onClick={onNavItemClick}
          />
        </SidebarGroup>
      </SidebarGroups>
    </Sidebar>
  );
}

export const Meta: PagefileMetaFn = () => ({
  name: 'OperatorLayout',
  layout: 'AppLayout',
  title: 'Operator',
});

function OperatorSidebar() {
  return <OperatorSidebarEntries />;
}

export default function OperatorLayout() {
  return (
    <DockPageLayout
      sidebar={<OperatorSidebar />}
      main={<MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />}
    />
  );
}

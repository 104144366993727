import { DetailPageLayout } from '../../../components/DetailPageLayout';
import HubHeader, { ValidHubTabs } from '../../../components/Hub/HubHeader';
import { HubMap } from '../../../components/Hub/Map/HubMap';
import { useHubNetworksOverview } from '../../../components/Hub/useHubNetworksOverview';
import { useUptimeStats } from '../../../components/Hub/useUptimeStats';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../gql/graphql';
import { NosFeature, useNosFeatureEnabledForCompany } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';

export const Meta = () => ({
  path: '/org/:companyName/hub/map',
  title: 'Map - Hub',
  layout: 'HubLayout',
});

export default function HubMapPage() {
  const companyName = useCurrentCompany();
  const networksOverview = useHubNetworksOverview(companyName);
  const uptimeStats = useUptimeStats(networksOverview);
  const companyHasConfig2COS = useNosFeatureEnabledForCompany(NosFeature.COS2, companyName);

  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        companyHasConfig2COS &&
        (permissions.hasPermission(PermissionType.PermCompanyGet) ||
          permissions.hasPermission(PermissionType.PermNetworkReadLimited))
      }
      should404OnAccessDenied
    >
      <DetailPageLayout
        header={<HubHeader activeTab={ValidHubTabs.Map} />}
        background="checkered"
        main={
          <HubMap
            networks={networksOverview.networks}
            company={companyName}
            uptimeStats={uptimeStats}
          />
        }
      />
    </IsPermitted>
  );
}

import React from 'react';

import { components, ValueContainerProps } from 'react-select';

import { styled } from '../../styled';

const Ol = styled('ol', {
  listStylePosition: 'outside',
  paddingLeft: 15,
  counterReset: 'list-counter',
  '& > li': {
    // textIndent: "-1em",
    paddingLeft: '$8',
    // marginLeft: "$16",
    position: 'relative',
    fontColor: '$gray-500',
    fontSize: '$14',
    lineHeight: '$20',
    '& > div': {
      marginLeft: 5,
    },
    '&::before': {
      content: "counter(list-counter) '.'",
      counterIncrement: 'list-counter',
      position: 'absolute',
      left: -10,
    },
  },
});
const Wrapper = styled('div', {
  maxWidth: 276,
});
export function NumberedListValueContainer<T>(props: ValueContainerProps<T, true>) {
  return (
    <components.ValueContainer {...props}>
      <Wrapper>
        <Ol>{props.children}</Ol>
        {/* {input} */}
      </Wrapper>
    </components.ValueContainer>
  );
}

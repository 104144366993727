import type { ButtonSizeProp, ButtonVariantProp } from '@meterup/atto';
import {
  Badge,
  Button,
  EmptyState,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  space,
  Tooltip,
} from '@meterup/atto';
import { AutoTable, notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import type { DNSFirewallRule } from './utils';
import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../providers/PermissionsProvider';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import { useFirewallCrumbs, useNavigateBack, useNavigateHome } from '../../utils/routing';
import { ucfirst } from '../../utils/strings';
import {
  firewallRuleActionIcon,
  firewallRuleActionLabel,
  firewallRuleActionVariant,
  OrderButtons,
  ReorderAction,
} from '../Firewall/utils';
import { NoValue } from '../NoValue';
import IsPermitted from '../permissions/IsPermitted';
import { PaneContentSkeletons } from '../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../ReactRouterLink';
import { RuleControls } from '../RuleControls';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import {
  dnsFirewallRuleKindDisplay,
  dnsFirewallRuleKindFromRule,
  dnsFirewallRulesQuery,
  DNSSecurityTab,
  updateDNSFirewallRulesPrioritiesMutation,
} from './utils';

function AddDNSSecurityRuleButton({
  variant,
  size,
}: {
  variant?: ButtonVariantProp;
  size?: ButtonSizeProp;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Button
      condense
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.DNSSecurityRuleCreatePage, {
        companyName,
        networkSlug: network.slug,
      })}
      arrangement="leading-icon"
      icon="plus"
      variant={variant}
      size={size}
    >
      Add DNS security rule
    </Button>
  );
}

type NonEmptyDNSFirewallRulesList = [DNSFirewallRule, ...DNSFirewallRule[]];

const builder = createColumnBuilder<DNSFirewallRule>();

const baseColumns = [
  builder.data(() => 'Enabled', {
    id: 'enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      tooltip: {
        contents: 'Enabled',
      },
      width: 40,
    },
    cell: ({ row }) =>
      row.isEnabled ? (
        <Tooltip contents="Enabled">
          <Icon
            icon="checkmark"
            color={{
              light: 'iconPositiveLight',
              dark: 'iconPositiveDark',
            }}
            size={space(16)}
          />
        </Tooltip>
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.action, {
    id: 'action',
    header: 'Action',
    meta: { width: 96 },
    cell: ({ row }) => (
      <Badge
        size="small"
        ends="card"
        variant={firewallRuleActionVariant(row.action)}
        icon={firewallRuleActionIcon(row.action)}
        arrangement="leading-icon"
      >
        {firewallRuleActionLabel(row.action)}
      </Badge>
    ),
  }),
  builder.data((row) => dnsFirewallRuleKindFromRule(row) ?? '', {
    id: 'kind',
    header: 'Type',
    cell: ({ value }) => (
      <Badge size="small" ends="card">
        {ucfirst(value)}
      </Badge>
    ),
  }),
  builder.data((row) => dnsFirewallRuleKindDisplay(row), {
    id: 'kind-value',
    header: 'Value',
  }),
];

type VlanToRulesTuple = [
  string,
  {
    vlan: NonEmptyDNSFirewallRulesList[number]['dhcpRule']['vlan'];
    vlanRules: NonEmptyDNSFirewallRulesList;
  },
];

function VLANRulesTable({ data }: { data: VlanToRulesTuple }) {
  const initialRules = data[1].vlanRules;
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.DNSSecurityRuleEditPage);

  const queryClient = useQueryClient();

  const updatePriorities = useGraphQLMutation(updateDNSFirewallRulesPrioritiesMutation);

  const closeDrawer = useCloseDrawerCallback();
  const [rules, setRules] = useState<NonEmptyDNSFirewallRulesList>(initialRules);
  const isDirty = useMemo(
    () => rules !== initialRules && rules.some((rule, i) => rule !== initialRules[i]),
    [rules, initialRules],
  );
  useEffect(() => {
    setRules(initialRules);
  }, [initialRules]);

  const handleSubmit = useCallback(() => {
    const input = rules.map((rule, index) => ({
      applicationDNSFirewallRuleUUID: rule.UUID,
      priority: index + 1,
    }));
    updatePriorities.mutate(
      { input },
      {
        onSuccess() {
          notify('Successfully updated rule priorities.', {
            variant: 'positive',
          });
          queryClient.invalidateQueries(
            makeQueryKey(dnsFirewallRulesQuery, { networkUUID: network.UUID }),
          );
        },
        onError(err) {
          notify(
            `There was a problem updating rule priorities${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [updatePriorities, queryClient, rules, network.UUID]);

  const handleCancel = useCallback(() => {
    setRules(initialRules);
  }, [initialRules]);

  const handleReorder = useCallback(
    (index: number, action: ReorderAction) => {
      setRules((prev) => {
        let nextIndex: number;
        switch (action) {
          case ReorderAction.Decrease:
            if (index === 0) return prev;
            nextIndex = index - 1;
            break;
          case ReorderAction.Increase:
            if (index === prev.length - 1) return prev;
            nextIndex = index + 1;
            break;
        }

        const next = [...prev] as NonEmptyDNSFirewallRulesList;
        const tmp = next[nextIndex];
        next[nextIndex] = next[index];
        next[index] = tmp;
        return next;
      });
    },
    [setRules],
  );

  const columns = useMemo(
    () => [
      builder.display({
        id: 'order',
        meta: { width: 84 },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row, table }) => (
          <OrderButtons
            key={`${row.id}-${row.index}` /* to prevent incorrect button enabled state flashing */}
            index={row.index}
            length={table.getRowModel().rows.length}
            handleReorder={handleReorder}
          />
        ),
      }),
      ...baseColumns,
    ],
    [handleReorder],
  );
  const { hasPermission } = usePermissions();
  const canEditDNSFirewallRule = hasPermission(PermissionType.PermFirewallWrite);
  const getLinkTo = useMemo(() => {
    if (canEditDNSFirewallRule) {
      return (row: DNSFirewallRule) =>
        makeDrawerLink(window.location, paths.drawers.DNSSecurityRuleEditPage, {
          ruleUUID: row.UUID,
          companyName,
          networkSlug: network.slug,
        });
    }
    return undefined;
  }, [canEditDNSFirewallRule, companyName, network]);

  return (
    <AutoTable
      isNested
      columns={columns}
      data={rules}
      enableSorting={false}
      getRowId={(row) => row.UUID}
      getLinkTo={getLinkTo}
      isRowSelected={(row) => row.UUID === drawerParams?.ruleUUID}
      onRowDeselect={closeDrawer}
      actions={
        isDirty &&
        canEditDNSFirewallRule && (
          <RuleControls relation="separate" size="small">
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="primary">
              Save
            </Button>
          </RuleControls>
        )
      }
    />
  );
}

const vlanColumnBuilder = createColumnBuilder<VlanToRulesTuple>();
const vlanColumns = [
  vlanColumnBuilder.data((row) => row[1].vlan.name, {
    header: 'VLAN',
    id: 'vlan',
    meta: {
      stuck: 'left',
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: ({ row }) => (
      <Badge arrangement="leading-icon" icon="vlan" size="small" variant="neutral">
        {row[1].vlan.name}
      </Badge>
    ),
  }),
];

type VLANToRulesMap = Record<
  NonEmptyDNSFirewallRulesList[number]['dhcpRule']['vlan']['UUID'],
  {
    vlan: NonEmptyDNSFirewallRulesList[number]['dhcpRule']['vlan'];
    vlanRules: NonEmptyDNSFirewallRulesList;
  }
>;
function VLANRulesList() {
  const network = useNetwork();
  const rules = useGraphQL(dnsFirewallRulesQuery, { networkUUID: network.UUID }).data
    ?.applicationDNSFirewallRulesForNetwork;

  const vlansToRules: VLANToRulesMap = useMemo(() => {
    const map: VLANToRulesMap = {};

    for (const rule of rules ?? []) {
      if (rule.dhcpRule.vlan.UUID in map) {
        map[rule.dhcpRule.vlan.UUID].vlanRules.push(rule);
      } else {
        map[rule.dhcpRule.vlan.UUID] = {
          vlan: rule.dhcpRule.vlan,
          vlanRules: [rule],
        };
      }
    }

    for (const obj of Object.values(map)) {
      obj.vlanRules.sort((a, b) => a.priority - b.priority);
    }

    return map;
  }, [rules]);

  const sortedVLANRuleEntries: [
    string,
    {
      vlan: NonEmptyDNSFirewallRulesList[number]['dhcpRule']['vlan'];
      vlanRules: NonEmptyDNSFirewallRulesList;
    },
  ][] = useMemo(
    () =>
      Object.entries(vlansToRules).sort(([, a], [, b]) =>
        a.vlanRules[0].dhcpRule.vlan.name.localeCompare(b.vlanRules[0].dhcpRule.vlan.name),
      ),
    [vlansToRules],
  );

  if (sortedVLANRuleEntries.length === 0) {
    return (
      <EmptyState
        icon="dns-security"
        heading="You have no DNS security rules"
        action={
          <IsPermitted permissions={PermissionType.PermFirewallWrite}>
            <AddDNSSecurityRuleButton />
          </IsPermitted>
        }
      />
    );
  }

  return (
    <AutoTable
      columns={vlanColumns}
      data={sortedVLANRuleEntries}
      renderSubTable={VLANRulesTable}
      subTableCollapsedByDefault={false}
    />
  );
}

function DNSSecurityContent({ tab }: { tab: DNSSecurityTab }) {
  switch (tab) {
    case DNSSecurityTab.VLANs:
      return <VLANRulesList />;
  }
}

export default function DNSSecurity({ tab }: { tab: DNSSecurityTab }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const firewallCrumb = useFirewallCrumbs();
  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...firewallCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.DNSSecurityPage, {
                companyName,
                networkSlug: network.slug,
                tab,
              }),
              selected: true,
              label: 'DNS security',
            },
          },
        ]}
        icon="dns-security"
        heading="DNS security"
        actions={
          <IsPermitted permissions={PermissionType.PermFirewallWrite}>
            <AddDNSSecurityRuleButton size="small" variant="secondary" />
          </IsPermitted>
        }
      />
      <PaneContent gutter="bottom">
        <Suspense fallback={<PaneContentSkeletons />}>
          <DNSSecurityContent tab={tab} />
        </Suspense>
      </PaneContent>
    </Pane>
  );
}

import type { FormikHelpers } from 'formik';
import { notify } from '@meterup/common';
import { useCallback } from 'react';

import type {
  CreateCaptivePortalMutation,
  DeleteCaptivePortalMutation,
  UpdateCaptivePortalMutation,
} from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { isFQDN } from '../../../utils/fqdn';
import { useInvalidateCaptivePortalForNetwork } from './queries';
import { type CaptivePortalInputSchemaType, captivePortalToInput } from './schema';

export function useSafeCloseDrawer() {
  const closeDrawerCallback = useCloseDrawerCallback();
  return useCallback(() => {
    setTimeout(() => closeDrawerCallback(), 0);
  }, [closeDrawerCallback]);
}

type UseHandleUpdateProps = {
  action: 'update' | 'create' | 'delete';
  networkUUID: string;
};

export function useHandleUpdate(props: UseHandleUpdateProps) {
  const invalidateCaptivePortals = useInvalidateCaptivePortalForNetwork(props.networkUUID);
  const closeDrawer = useSafeCloseDrawer();
  return useCallback(
    (
      mutation: Promise<
        UpdateCaptivePortalMutation | CreateCaptivePortalMutation | DeleteCaptivePortalMutation
      >,
      form?: FormikHelpers<CaptivePortalInputSchemaType>,
    ) =>
      mutation
        .then((data) => {
          notify(`Captive portal successfully ${props.action}d`, {
            variant: 'positive',
          });
          invalidateCaptivePortals();
          if (props.action === 'update' && 'updateCaptivePortal' in data) {
            form?.resetForm({ values: captivePortalToInput(data?.updateCaptivePortal) });
          }
          closeDrawer();
          return true;
        })
        .catch(() => {
          notify(`Failed to ${props.action} captive portal`, {
            variant: 'negative',
          });
          return false;
        }),
    [closeDrawer, invalidateCaptivePortals, props.action],
  );
}

export function captivePortalURL(captivePortalUUID: string, preview = true) {
  let domain = 'https://captive.meter.com';
  switch (import.meta.env.REALM) {
    case 'prod':
      break;
    case 'dev':
    case 'test':
      domain = 'https://captive.meter.dev';
      break;
    case 'local':
      domain = 'https://captive.meter.website:8777';
      break;
  }
  return `${domain}/${captivePortalUUID}${preview ? '?preview=1' : ''}`;
}

export function webhookURL(webhookKey: string) {
  let domain = 'https://meterapi.com';
  switch (import.meta.env.REALM) {
    case 'prod':
      break;
    case 'dev':
    case 'test':
      domain = 'https://meterapi.dev';
      break;
    case 'local':
      domain = 'https://meterapi.website:8777';
      break;
  }
  return `${domain}/v2/captive-portals/webhook/${webhookKey}`;
}

export function parseAllowedHostsInputToRecord(
  allowedHosts: CaptivePortalInputSchemaType['allowedHosts'],
) {
  return allowedHosts
    ? {
        allowedHosts: allowedHosts
          ?.filter((host) => host !== '')
          .map((host) => {
            if (isFQDN(host)) {
              return `https://${host}`;
            }
            return host;
          }),
      }
    : {};
}

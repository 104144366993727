import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Badge,
  Body,
  Button,
  EmptyState,
  HStack,
  Pane,
  PaneContent,
  PaneHeader,
  Segment,
  Segments,
  Select,
  SelectItem,
  space,
  styled,
} from '@meterup/atto';
import {
  AutoTable,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { DiffEditor, Editor } from '@monaco-editor/react';
import { DateTime } from 'luxon';
import { Suspense, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import type { DeviceConfigsQueryResult } from '../../../../../components/DeviceConfigs/utils';
import type { MutationAuditLogQueryResult } from '../../insights/logs/hooks/useNetworkMutationAuditLog';
import {
  DeviceConfigByVersionQuery,
  DeviceConfigsQuery,
} from '../../../../../components/DeviceConfigs/utils';
import { NoValue } from '../../../../../components/NoValue';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { ThemeContext } from '../../../../../providers/ThemeProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { fromISOtoLocaleString } from '../../../../../utils/time';
import { useNetworkMutationAuditLog } from '../../insights/logs/hooks/useNetworkMutationAuditLog';

type DiffActionProps = {
  deviceConfigs: DeviceConfigsQueryResult[];
  acknowledgedAt: string | null | undefined;
  version: number;
  setVersion: (version: number) => void;
};

function DiffActions({ version, acknowledgedAt, setVersion, deviceConfigs }: DiffActionProps) {
  return (
    <HStack spacing={space(8)} align="center">
      {acknowledgedAt ? (
        <Badge size="medium" variant="neutral">
          Acknowledged: {fromISOtoLocaleString({ iso: acknowledgedAt })}
        </Badge>
      ) : (
        <Badge size="medium" variant="neutral">
          Unacknowledged
        </Badge>
      )}

      <Button
        condense
        size="small"
        arrangement="hidden-label"
        icon="chevron-left"
        variant="secondary"
        onClick={() => setVersion(version - 1)}
        disabled={version <= 1}
      >
        Previous
      </Button>

      <Select
        onValueChange={(val) => setVersion(parseInt(val, 10))}
        value={version.toString()}
        placeholder="Select a version"
      >
        {deviceConfigs.map((c) => (
          <SelectItem key={c.version.toString()}>
            {c.version} -{' '}
            {fromISOtoLocaleString({ iso: c.createdAt, format: DateTime.DATETIME_SHORT })}
          </SelectItem>
        ))}
      </Select>

      <Button
        condense
        size="small"
        arrangement="hidden-label"
        icon="chevron-right"
        variant="secondary"
        onClick={() => setVersion(version + 1)}
        disabled={version >= deviceConfigs[0].version}
      >
        Next
      </Button>
    </HStack>
  );
}

type DiffHeadingProps = {
  deviceConfigs: DeviceConfigsQueryResult[];
  version: number;
  setPreviousVersion: (version: number) => void;
  previousVersion: number;
  tab: string;
  setTab: (tab: string) => void;
};

const SegmentsWrapper = styled('div', {
  width: '200px',
});

function DiffHeading({
  version,
  previousVersion,
  setPreviousVersion,
  deviceConfigs,
  tab,
  setTab,
}: DiffHeadingProps) {
  return (
    <HStack spacing={space(8)} align="center">
      <SegmentsWrapper>
        <Segments>
          <Segment active={tab === 'diff'} onClick={() => setTab('diff')}>
            Diff
          </Segment>
          <Segment active={tab === 'mutations'} onClick={() => setTab('mutations')}>
            Mutations
          </Segment>
        </Segments>
      </SegmentsWrapper>

      {version > 1 && (
        <Select
          onValueChange={(key) => setPreviousVersion(parseInt(key, 10))}
          value={previousVersion.toString()}
          placeholder="Select a version"
          disabled={version === 1}
        >
          {deviceConfigs
            .filter((c) => c.version < version)
            .map((c) => (
              <SelectItem key={c.version.toString()}>
                {c.version} -{' '}
                {fromISOtoLocaleString({ iso: c.createdAt, format: DateTime.DATETIME_SHORT })}
              </SelectItem>
            ))}
        </Select>
      )}
    </HStack>
  );
}

type DiffInternalProps = {
  serialNumber: string;
  version: number;
  previousVersion: number;
};

function DiffInternal({ serialNumber, version, previousVersion }: DiffInternalProps) {
  const currentVersionConfig = useGraphQL(DeviceConfigByVersionQuery, { serialNumber, version })
    .data?.deviceConfigByVersion;

  const previousVersionConfig = useGraphQL(
    DeviceConfigByVersionQuery,
    {
      serialNumber,
      version: previousVersion,
    },
    { enabled: previousVersion >= 1 },
  ).data?.deviceConfigByVersion;

  const { dark } = useContext(ThemeContext);

  return (
    <DiffEditor
      originalLanguage="json"
      modifiedLanguage="json"
      theme={dark ? 'vs-dark' : 'light'}
      original={JSON.stringify(previousVersionConfig?.configJSON ?? {}, null, 2)}
      modified={JSON.stringify(currentVersionConfig?.configJSON ?? {}, null, 2)}
      options={{ minimap: { enabled: false }, readOnly: true }}
    />
  );
}

const builder = createColumnBuilder<MutationAuditLogQueryResult>();

const columns = [
  builder.data((d) => d.action, {
    id: 'event',
    header: 'Event',
    meta: { width: 220 },
    cell: (d) => <Body family="monospace">{d.value}</Body>,
  }),
  builder.data((d) => d.username, {
    id: 'username',
    header: 'Username',
  }),
  builder.data((d) => d.createdAt ?? '', {
    id: 'time',
    header: `Time (${DateTime.local().toFormat('ZZZZZ')})`,
    meta: {
      alignment: 'end',
    },
    cell: (d) =>
      isDefinedAndNotEmpty(d.value) ? (
        <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</>
      ) : (
        <NoValue />
      ),
  }),
];

const MutationsWrapper = styled('div', {
  hStack: 0,
  width: '100%',
  height: '100%',
});

const MutationsTableWrapper = styled('div', {
  flexBasis: '60%',
  height: '100%',
  overflow: 'scroll',
});

const MutationsEditorWrapper = styled('div', {
  flexBasis: '40%',
  height: '100%',
});

type DiffMutationsProps = {
  serialNumber: string;
  deviceConfigs: DeviceConfigsQueryResult[];
  version: number;
  previousVersion: number;
};

function DiffMutations({
  serialNumber,
  version,
  previousVersion,
  deviceConfigs,
}: DiffMutationsProps) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const startTime = deviceConfigs.find((c) => c.version === previousVersion)?.createdAt;
  const endTime = deviceConfigs.find((c) => c.version === version)?.createdAt;
  const mutations = useNetworkMutationAuditLog({
    minCreatedAt: startTime,
    maxCreatedAt: endTime,
    includeMachine: true,
  });
  const location = Nav.useRegionLocation('root');

  const [searchParams] = useSearchParams();
  const mutationUUID = searchParams.get('mutationUUID');

  const selectedMutation = mutations.find((m) => m.UUID === mutationUUID) ?? mutations[0];

  const navigate = useNavigate();
  const setSelectedMutationUUID = useCallback(
    (newMutationUUID: string) => {
      const newSearch = new URLSearchParams(location.search);
      newSearch.delete('mutationUUID');
      newSearch.set('mutationUUID', newMutationUUID);
      navigate(
        `${makeLink(paths.pages.DeviceConfigHistoryVersionPage, {
          companyName,
          networkSlug: network.slug,
          serialNumber,
        })}?${newSearch.toString()}`,
      );
    },
    [navigate, companyName, network, serialNumber, location],
  );

  const { dark } = useContext(ThemeContext);

  if (mutations.length === 0) {
    return (
      <EmptyState
        icon="log"
        heading={`No mutations found from version ${previousVersion} to ${version}`}
      />
    );
  }

  return (
    <MutationsWrapper>
      <MutationsTableWrapper>
        <AutoTable
          key={JSON.stringify(mutations)}
          columns={columns}
          data={mutations}
          onRowClick={(r) => setSelectedMutationUUID(r.UUID)}
          isRowSelected={(r) => r.UUID === selectedMutation?.UUID}
        />
      </MutationsTableWrapper>
      <MutationsEditorWrapper>
        <Editor
          language="json"
          height="100%"
          theme={dark ? 'vs-dark' : 'light'}
          value={JSON.stringify(selectedMutation ?? {}, null, 2)}
          options={{ minimap: { enabled: false }, readOnly: true }}
        />
      </MutationsEditorWrapper>
    </MutationsWrapper>
  );
}

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/config/:serialNumber/history/compare',
});

export default function DeviceConfigHistoryVersionPage() {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const { dark } = useContext(ThemeContext);

  const historyParams = Nav.useRegionParams('root', paths.pages.DeviceConfigHistoryVersionPage);
  const serialNumber = historyParams?.serialNumber;
  const location = Nav.useRegionLocation('root');
  expectDefinedOrThrow(
    serialNumber,
    new ResourceNotFoundError('Serial number of device not present.'),
  );

  const [searchParams] = useSearchParams();
  const rightVersion = searchParams.get('rightVersion');
  const leftVersion = searchParams.get('leftVersion');
  const tab = searchParams.get('tab');

  expectDefinedOrThrow(
    leftVersion,
    new ResourceNotFoundError('Left comparision version of device history not present.'),
  );
  expectDefinedOrThrow(
    rightVersion,
    new ResourceNotFoundError('Right comparision version of device history not present.'),
  );
  expectDefinedOrThrow(tab, new ResourceNotFoundError('Tab of device history not present.'));

  const deviceConfigs = useGraphQL(DeviceConfigsQuery, { serialNumber }).data?.deviceConfigs;
  const currentVersionConfig = useGraphQL(DeviceConfigByVersionQuery, {
    serialNumber,
    version: parseInt(rightVersion, 10),
  }).data?.deviceConfigByVersion;

  const navigate = useNavigate();
  const setVersion = useCallback(
    (nextVersion: number) => {
      const newSearch = new URLSearchParams(location.search);
      newSearch.delete('leftVersion');
      newSearch.delete('rightVersion');
      newSearch.set('rightVersion', String(nextVersion));
      newSearch.set('leftVersion', String(nextVersion - 1));
      const config = deviceConfigs?.find((c) => c.version === nextVersion);
      if (config) {
        navigate(
          `${makeLink(paths.pages.DeviceConfigHistoryVersionPage, {
            companyName,
            networkSlug: network.slug,
            serialNumber,
          })}?${newSearch.toString()}`,
        );
      }
    },
    [deviceConfigs, navigate, companyName, network, serialNumber, location],
  );

  const setTab = useCallback(
    (newTab: string) => {
      const newSearch = new URLSearchParams(location.search);
      newSearch.set('tab', newTab);
      navigate(
        `${makeLink(paths.pages.DeviceConfigHistoryVersionPage, {
          companyName,
          networkSlug: network.slug,
          serialNumber,
        })}?${newSearch.toString()}`,
      );
    },
    [navigate, companyName, network, serialNumber, location],
  );

  const setPreviousVersion = useCallback(
    (prevVersion: number) => {
      const newSearch = new URLSearchParams(location.search);
      newSearch.delete('leftVersion');
      newSearch.set('leftVersion', String(prevVersion));
      const config = deviceConfigs?.find((c) => c.version === prevVersion);
      if (config) {
        navigate(
          `${makeLink(paths.pages.DeviceConfigHistoryVersionPage, {
            companyName,
            networkSlug: network.slug,
            serialNumber,
          })}?${newSearch.toString()}`,
        );
      }
    },
    [deviceConfigs, navigate, companyName, network, serialNumber, location],
  );

  const previousVersion = parseInt(leftVersion, 10);
  const version = parseInt(rightVersion, 10);

  return (
    deviceConfigs &&
    currentVersionConfig && (
      <Pane>
        <PaneHeader
          heading={
            <DiffHeading
              deviceConfigs={deviceConfigs}
              version={version}
              previousVersion={previousVersion}
              setPreviousVersion={setPreviousVersion}
              tab={tab}
              setTab={setTab}
            />
          }
          actions={
            <DiffActions
              deviceConfigs={deviceConfigs}
              version={version}
              acknowledgedAt={currentVersionConfig.acknowledgedAt}
              setVersion={setVersion}
            />
          }
        />
        <PaneContent>
          {tab === 'diff' && (
            <Suspense
              fallback={
                <DiffEditor
                  originalLanguage="json"
                  modifiedLanguage="json"
                  theme={dark ? 'vs-dark' : 'light'}
                  original="{}"
                  modified="{}"
                  options={{ minimap: { enabled: false }, readOnly: true }}
                />
              }
            >
              <DiffInternal
                serialNumber={serialNumber}
                version={version}
                previousVersion={previousVersion}
              />
            </Suspense>
          )}
          {tab === 'mutations' && (
            <DiffMutations
              serialNumber={serialNumber}
              version={version}
              previousVersion={previousVersion}
              deviceConfigs={deviceConfigs}
            />
          )}
        </PaneContent>
      </Pane>
    )
  );
}

import { Tab } from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../../constants';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useNetworkForSettingsPath } from '../../../../routes/pages/settings/network/NetworkUtils';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';

export enum NotificationsValidTabs {
  Alerts = 'alerts',
  Reports = 'reports',
}

export default function NotificationsTabs({ activeTab }: { activeTab: NotificationsValidTabs }) {
  const companyName = useCurrentCompany();
  const network = useNetworkForSettingsPath({
    path: paths.pages.SettingsNetworkNotificationsPage,
  });

  return (
    <>
      <Tab
        as={Link}
        to={makeLink(paths.pages.AlertsPage, {
          companyName,
          networkSlug: network.slug,
        })}
        selected={activeTab === NotificationsValidTabs.Alerts}
        icon="attention"
      >
        Alerts
      </Tab>
      {/* <Tab
        as={Link}
        to={makeLink(paths.pages.ReportsPage, {
          companyName,
          networkSlug: network.slug,
        })}
        selected={activeTab === NotificationsValidTabs.Reports}
        icon="email"
      >
        Reports
      </Tab> */}
    </>
  );
}

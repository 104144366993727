import { useMemo } from 'react';

import { HasRole } from '../core/typesHelpers';
import { RoleName } from '../gql/graphql';
import { useAuthorization } from '../providers/AuthorizationProvider';
import { useIsTrueOperator } from './useIsTrueOperator';

type UseIsCompanyAdminProps = {
  companySlug: string;
};

/**
 * @deprecated Use `usePermissions` instead. Do not check the user roles directly.
 */
export function useIsCompanyAdmin({ companySlug }: UseIsCompanyAdminProps) {
  const authz = useAuthorization();
  const hasRole = useMemo(
    () =>
      authz.check(
        HasRole({
          roleName: RoleName.CompanyGlobalAdmin,
          companySlug,
        }),
      ),
    [authz, companySlug],
  );
  const isOperator = useIsTrueOperator();
  return useMemo(() => hasRole || isOperator, [hasRole, isOperator]);
}

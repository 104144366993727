import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  space,
  useDialogState,
  VStack,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
  Tooltip,
} from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { capitalize } from 'lodash-es';
import { useCallback, useState } from 'react';

import type { ValidUpdateRadioProfileParams } from './utils';
import { RadioBand } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetworkUUID } from '../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../utils/withZodSchema';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import { BandSection, NameField } from './fields';
import {
  DeleteRadioProfileMutation,
  RadioProfilesQuery,
  updateRadioProfileInputSchema,
  UpdateRadioProfileMutation,
} from './utils';

export default function RadioProfileEditDrawer({ uuid }: { uuid: string }) {
  const networkUUID = checkDefinedOrThrow(useNetworkUUID());

  const profiles = useGraphQL(RadioProfilesQuery, { networkUUID }).data?.radioProfilesForNetwork;
  expectDefinedOrThrow(profiles, new ResourceNotFoundError('Unable to load radio profiles'));

  const profile = profiles.find((p) => p.UUID === uuid);
  if (!profile) throw new ResourceNotFoundError('Radio profile not found');

  const closeDrawer = useCloseDrawerCallback();
  const [errorText, setErrorText] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const updatedRadioProfileMutation = useGraphQLMutation(UpdateRadioProfileMutation);
  const deleteRadioProfileMutation = useGraphQLMutation(DeleteRadioProfileMutation);
  const deleteDialog = useDialogState();

  const handleDeleteProfile = useCallback(
    () =>
      deleteRadioProfileMutation.mutate(
        { uuid },
        {
          onSuccess() {
            queryClient.invalidateQueries(makeQueryKey(RadioProfilesQuery, { networkUUID }));
            closeDrawer();
            notify('Radio profile deleted successfully.', { variant: 'positive' });
          },
          onError(err) {
            const gqlErr = getGraphQLError(err);
            notify(
              `There was an error deleting this profile${
                gqlErr?.message ? `: ${gqlErr.message}` : ''
              }.`,
              { variant: 'negative' },
            );
          },
        },
      ),
    [deleteRadioProfileMutation, uuid, queryClient, closeDrawer, networkUUID],
  );

  return (
    <Drawer>
      <Formik<ValidUpdateRadioProfileParams>
        initialValues={{
          name: profile.name,
          band2_4GIsEnabled: profile.band2_4GIsEnabled,
          band2_4GAutoChannelIsEnabled: profile.band2_4GAutoChannelIsEnabled,
          band2_4GAllowedChannels: profile.band2_4GAllowedChannels,
          band2_4GAutoChannelWidthIsEnabled: profile.band2_4GAutoChannelWidthIsEnabled,
          band2_4GAutoTxPowerIsEnabled: profile.band2_4GAutoTxPowerIsEnabled,
          band2_4GAutoTxPowerMindBm: profile.band2_4GAutoTxPowerMindBm,
          band2_4GAutoTxPowerMaxdBm: profile.band2_4GAutoTxPowerMaxdBm,
          band2_4GChannelWidthMHz: profile.band2_4GChannelWidthMHz,
          band5GIsEnabled: profile.band5GIsEnabled,
          band5GAutoChannelIsEnabled: profile.band5GAutoChannelIsEnabled,
          band5GAllowedChannels: profile.band5GAllowedChannels,
          band5GAutoChannelWidthIsEnabled: profile.band5GAutoChannelWidthIsEnabled,
          band5GAutoTxPowerIsEnabled: profile.band5GAutoTxPowerIsEnabled,
          band5GAutoTxPowerMindBm: profile.band5GAutoTxPowerMindBm,
          band5GAutoTxPowerMaxdBm: profile.band5GAutoTxPowerMaxdBm,
          band5GChannelWidthMHz: profile.band5GChannelWidthMHz,
          isDefault: profile.isDefault,
        }}
        validate={withZodSchema(updateRadioProfileInputSchema)}
        onSubmit={(values) => {
          setErrorText(null);
          const { ...input } = values;

          if (input.band2_4GAutoChannelWidthIsEnabled) {
            input.band2_4GChannelWidthMHz = null;
          }

          if (!input.band2_4GAutoTxPowerIsEnabled) {
            input.band2_4GAutoTxPowerMindBm = null;
            input.band2_4GAutoTxPowerMaxdBm = null;
          }

          if (!input.band2_4GAutoChannelIsEnabled) {
            input.band2_4GAllowedChannels = [];
          }

          if (input.band5GAutoChannelWidthIsEnabled) {
            input.band5GChannelWidthMHz = null;
          }

          if (!input.band5GAutoTxPowerIsEnabled) {
            input.band5GAutoTxPowerMindBm = null;
            input.band5GAutoTxPowerMaxdBm = null;
          }

          if (!input.band5GAutoChannelIsEnabled) {
            input.band5GAllowedChannels = [];
          }

          const variables = { uuid, input };
          updatedRadioProfileMutation.mutate(variables, {
            onError: (error) => {
              const gqlError = getGraphQLError(error);
              setErrorText(capitalize(gqlError?.message ?? 'Unknown error'));
            },
            onSuccess: () => {
              queryClient.invalidateQueries(makeQueryKey(RadioProfilesQuery, { networkUUID }));
              closeDrawer();
              notify(`Successfully updated radio profile.`, {
                variant: 'positive',
              });
            },
          });
        }}
      >
        <Form>
          <DrawerHeader
            icon="radio-profile"
            heading="Edit radio profile"
            onClose={closeDrawer}
            actions={
              !profile.isDefault && (
                <>
                  <Tooltip
                    content="You must remove devices from the profile in order to delete it."
                    side="right"
                    sideOffset={6}
                    align="start"
                    showHint
                  />
                  <DropdownMenu>
                    <DropdownMenuButton
                      variant="secondary"
                      icon="overflow-horizontal"
                      arrangement="hidden-label"
                    >
                      Actions
                    </DropdownMenuButton>
                    <DropdownMenuPopover align="end">
                      <DropdownMenuItem
                        disabled={profile.virtualDevices.length !== 0}
                        onSelect={deleteDialog.openFromMenu}
                        icon="trash-can"
                      >
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuPopover>
                  </DropdownMenu>
                  <DeleteDialog
                    state={deleteDialog.state}
                    label={profile?.name}
                    handleDelete={handleDeleteProfile}
                  />
                </>
              )
            }
          />
          <DrawerContent gutter="vertical">
            <Sections>
              <Section relation="stacked">
                <SectionHeader icon="information" heading="Details" />
                <SectionContent gutter="all">
                  <VStack spacing={space(16)}>
                    <NameField />
                  </VStack>
                </SectionContent>
              </Section>

              <BandSection band={RadioBand.Band_2_4G} />
              <BandSection band={RadioBand.Band_5G} />

              {errorText && (
                <Alert
                  heading="Error updating profile"
                  copy={errorText}
                  variant="negative"
                  relation="stacked"
                />
              )}
            </Sections>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={updatedRadioProfileMutation.isLoading}
                  loading={updatedRadioProfileMutation.isLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

import type { Selection } from 'react-stately';
import {
  Button,
  ComboBox,
  ComboBoxSection,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryField,
  SecondaryField,
  SecondaryToggleField,
  Select,
  SelectItem,
  TextInput,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';

import type { AutoVpnGroupFormValues, Network } from './utils';
import { type AutoVpnGroupQuery, ClientAssignmentProtocol } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { withZodSchema } from '../../utils/withZodSchema';
import { getPhyInterfaceLabel } from '../Firewall/utils';
import {
  FieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
  ToggleOptionsFieldProvider,
} from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import { vlansQuery } from '../NetworkWide/VLANs/utils';
import {
  AutoVPNGroupQuery,
  AutoVPNGroupsQuery,
  createAutoVpnGroupInputModifiedSchema,
  createAutoVPNGroupMutation,
  parseAutoVPNFormValsToCreateMutationInput,
  parseAutoVPNFormValsToUpdateMutationInput,
  updateAutoVPNGroupMutation,
  useNetworksWithControllerPorts,
} from './utils';

function AutoVPNInitAddMembersSelect({ networks }: { networks: Network[] }) {
  const { setFieldValue, values } = useFormikContext<AutoVpnGroupFormValues>();

  const handleChange = useCallback(
    (keys: Selection) => {
      const oldMembers = values.members;
      // eslint-disable-next-line prefer-destructuring
      const hubNetworkUUID = values.hubNetworkUUID;
      const newMembers: AutoVpnGroupFormValues['members'] = Object.fromEntries(
        Array.from(keys)
          .filter((networkUUID) => networkUUID !== hubNetworkUUID)
          .map((networkUUID) => [
            String(networkUUID),
            {
              networkUUID: String(networkUUID),
              phyInterfaceUUID: oldMembers[networkUUID]?.phyInterfaceUUID ?? '',
              isFailoverEnabled: oldMembers[networkUUID]?.isFailoverEnabled ?? true,
              permittedVLANUUIDs: oldMembers[networkUUID]?.permittedVLANUUIDs ?? [],
            },
          ]),
      );
      setFieldValue('members', newMembers);
    },
    [setFieldValue, values.members, values.hubNetworkUUID],
  );

  return (
    <FieldContainer>
      <MultiComboBoxFieldProvider name="memberUUIDs">
        <PrimaryField
          label="Member locations"
          element={
            <MultiComboBox onValueChange={handleChange}>
              {networks.map((network) => (
                <MultiComboBoxItem key={network.UUID} textValue={network.label}>
                  {network.label}
                </MultiComboBoxItem>
              ))}
            </MultiComboBox>
          }
        />
      </MultiComboBoxFieldProvider>
    </FieldContainer>
  );
}

function AutoVPNGroupMemberInput({
  networkUUID,
  networks,
}: {
  networkUUID: string;
  networks: Network[];
}) {
  const selectedNetwork = useMemo(
    () => networks.find((n) => n.UUID === networkUUID),
    [networkUUID, networks],
  );
  const UplinkPhyInterfaceItems = useMemo(
    () =>
      selectedNetwork?.virtualDevices
        .filter((vd) => vd.__typename === 'ControllerVirtualDevice')
        .filter((vd) => vd.phyInterfaces.some((pi) => pi.isUplink))
        .map((virtualDevice) => (
          <ComboBoxSection title={virtualDevice.label}>
            {virtualDevice.phyInterfaces
              .filter((pi) => pi.isUplink)
              .map((phyInterface) => (
                <SelectItem key={phyInterface.UUID}>
                  {getPhyInterfaceLabel(phyInterface, false)}
                </SelectItem>
              ))}
          </ComboBoxSection>
        )) ?? [],
    [selectedNetwork],
  );
  const vlans = useGraphQL(
    vlansQuery,
    {
      networkUUID,
    },
    { suspense: false },
  ).data?.vlans;
  const vlanOptions = useMemo(
    () =>
      vlans?.filter(
        (vlan) =>
          vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static &&
          !!vlan.ipV4ClientGateway,
      ) ?? [],
    [vlans],
  );
  const { registerField, unregisterField } = useFormikContext<AutoVpnGroupFormValues>();
  // Normall you don't need to manually register fields in this way,
  // However, formik will not register these fields implicitly b/c they are not
  // known on initialization (in initialValues)
  // This is the only method of dynamic forms in Formik that I could find.
  useEffect(() => {
    const field = `members.${selectedNetwork?.UUID}.phyInterfaceUUID`;
    registerField(field, {});
    return () => {
      unregisterField(field);
    };
  }, [selectedNetwork?.UUID, registerField, unregisterField]);
  return (
    <FieldContainer>
      <PrimaryField label={`Member: ${selectedNetwork?.label}`} element={null} />
      <FieldProvider name={`members.${selectedNetwork?.UUID}.phyInterfaceUUID`}>
        <SecondaryField
          label="Bound WAN port"
          element={<Select width="100%">{UplinkPhyInterfaceItems}</Select>}
        />
      </FieldProvider>

      <MultiComboBoxFieldProvider name={`members.${selectedNetwork?.UUID}.permittedVLANUUIDs`}>
        <SecondaryField
          label="Permitted VLANs"
          optional
          element={
            <MultiComboBox placeholder="Select VLANs">
              {vlanOptions.map((vlan) => (
                <MultiComboBoxItem key={vlan.UUID} textValue={vlan.name}>
                  {vlan.name}
                </MultiComboBoxItem>
              ))}
            </MultiComboBox>
          }
        />
      </MultiComboBoxFieldProvider>
      <ToggleOptionsFieldProvider
        name={`members.${selectedNetwork?.UUID}.isFailoverEnabled`}
        // eslint-disable-next-line react/jsx-boolean-value
        positive={true}
        negative={false}
      >
        <SecondaryToggleField
          name={`members.${selectedNetwork?.UUID}.isFailoverEnabled`}
          label="Failover enabled"
        />
      </ToggleOptionsFieldProvider>
    </FieldContainer>
  );
}

function AutoVPNGroupHubUplinkInputs() {
  return (
    <FieldContainer>
      <PrimaryField label="Group subnet" element={null} />
      <FieldProvider name="uplink.address">
        <SecondaryField label="Address (IP)" element={<TextInput />} />
      </FieldProvider>
      <NumberFieldProvider name="uplink.addressPrefixLength" defaultValue={16}>
        <SecondaryField
          label="Address prefix length"
          element={<TextInput inputProps={{ type: 'number', min: 16, max: 32 }} />}
        />
      </NumberFieldProvider>
    </FieldContainer>
  );
}

function AutoVPNHubInputs({ networks }: { networks: Network[] }) {
  const { values } = useFormikContext<AutoVpnGroupFormValues>();
  const UplinkPhyInterfaceItems = useMemo(
    () =>
      networks
        .find((network) => network.UUID === values.hubNetworkUUID)
        ?.virtualDevices.filter((vd) => vd.__typename === 'ControllerVirtualDevice')
        .filter((vd) => vd.phyInterfaces.some((pi) => pi.isUplink))
        .map((virtualDevice) => (
          <ComboBoxSection title={virtualDevice.label}>
            {virtualDevice.phyInterfaces
              .filter((pi) => pi.isUplink)
              .map((phyInterface) => (
                <SelectItem key={phyInterface.UUID}>
                  {getPhyInterfaceLabel(phyInterface, false)}
                </SelectItem>
              ))}
          </ComboBoxSection>
        )) ?? [],
    [values.hubNetworkUUID, networks],
  );

  return (
    <>
      <FieldProvider name="uplink.phyInterfaceUUID">
        <SecondaryField
          label="Bound WAN port"
          element={<ComboBox>{UplinkPhyInterfaceItems}</ComboBox>}
        />
      </FieldProvider>
      <ToggleOptionsFieldProvider
        name="isHubFailoverEnabled"
        // eslint-disable-next-line react/jsx-boolean-value
        positive={true}
        negative={false}
      >
        <SecondaryToggleField name="isHubFailoverEnabled" label="Failover enabled" />
      </ToggleOptionsFieldProvider>
    </>
  );
}

function AddNetworkToAutoVPNGroupInput({ networks }: { networks: Network[] }) {
  const { values, setFieldValue } = useFormikContext<AutoVpnGroupFormValues>();

  useEffect(() => {
    if (!values.memberUUIDs.includes(values.hubNetworkUUID)) {
      setFieldValue('hubNetworkUUID', '');
    }
  }, [values.hubNetworkUUID, values.memberUUIDs, setFieldValue]);

  const HubLocationOptions = useMemo(() => {
    const selectedMemberNetworks = networks.filter((net) => values?.memberUUIDs.includes(net.UUID));

    return (
      selectedMemberNetworks?.map((net) => <SelectItem key={net.UUID}>{net.label}</SelectItem>) ??
      []
    );
  }, [networks, values.memberUUIDs]);

  const handleHubSelectChange = useCallback(
    (hubNetworkUUID: string) => {
      const newMembers: AutoVpnGroupFormValues['members'] = Object.fromEntries(
        Object.entries(values.members)
          .filter(([networkUUID]) => networkUUID !== hubNetworkUUID)
          .map(([networkUUID, member]) => [
            String(networkUUID),
            {
              networkUUID: String(networkUUID),
              phyInterfaceUUID: member?.phyInterfaceUUID ?? '',
              isFailoverEnabled: member?.isFailoverEnabled ?? true,
              permittedVLANUUIDs: member?.permittedVLANUUIDs ?? [],
            },
          ]),
      );
      setFieldValue('members', newMembers);
    },
    [setFieldValue, values.members],
  );

  return (
    <FormikConditional<AutoVpnGroupFormValues>
      condition={({ memberUUIDs }) => !!memberUUIDs.length}
    >
      <FieldContainer>
        <PrimaryField label="Hub" element={null} />
        <FieldProvider name="hubNetworkUUID">
          <SecondaryField
            label="Select a hub"
            element={
              <Select onValueChange={handleHubSelectChange} width="100%">
                {HubLocationOptions}
              </Select>
            }
          />
        </FieldProvider>
        <FormikConditional<AutoVpnGroupFormValues>
          condition={({ hubNetworkUUID }) => !!hubNetworkUUID}
        >
          <AutoVPNHubInputs networks={networks} />
        </FormikConditional>
      </FieldContainer>

      <FormikConditional<AutoVpnGroupFormValues>
        condition={({ hubNetworkUUID }) => !!hubNetworkUUID}
      >
        {values.memberUUIDs
          ?.filter((memberId: string) => memberId !== values.hubNetworkUUID)
          ?.map((uuid) => (
            <AutoVPNGroupMemberInput key={uuid} networkUUID={uuid} networks={networks} />
          ))}
      </FormikConditional>
    </FormikConditional>
  );
}

export default function AutoVPNGroupAddEditForm({
  group,
}: {
  group?: AutoVpnGroupQuery['autoVPNGroup'];
}) {
  const companySlug = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const queryClient = useQueryClient();
  const createMutation = useGraphQLMutation(createAutoVPNGroupMutation);
  const updateMutation = useGraphQLMutation(updateAutoVPNGroupMutation);
  const handleSubmit = useCallback(
    (vals: AutoVpnGroupFormValues) => {
      if (group?.UUID) {
        updateMutation.mutate(
          { UUID: group.UUID, input: parseAutoVPNFormValsToUpdateMutationInput(vals) },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupsQuery, { companySlug }));
              queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupQuery, { uuid: group.UUID! }));
              notify('Auto VPN group updated successfully.', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              notify(
                `There was a problem updating Auto VPN Group configuration${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        createMutation.mutate(
          {
            companySlug,
            input: parseAutoVPNFormValsToCreateMutationInput(vals),
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupsQuery, { companySlug }));
              notify('Successfully created Auto VPN Group configuration.', {
                variant: 'positive',
              });
              closeDrawer();
            },
            onError: (err) => {
              notify(
                `There was a problem creating Auto VPN Group configuration${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [closeDrawer, companySlug, group?.UUID, createMutation, updateMutation, queryClient],
  );
  const networks = useNetworksWithControllerPorts();

  return (
    <Formik<AutoVpnGroupFormValues>
      initialValues={{
        name: group?.name ?? '',
        memberUUIDs: (group?.members ? group.members.map((member) => member.networkUUID) : []) as [
          string,
          ...string[],
        ],
        members: group?.members
          ? Object.fromEntries(
              group?.members.map((member) => [
                member.networkUUID as string,
                {
                  networkUUID: member.networkUUID,
                  phyInterfaceUUID: member.uplink.phyInterfaceUUID,
                  permittedVLANUUIDs: member.permittedVLANs?.map((vlan) => vlan?.UUID!) ?? [],
                  isFailoverEnabled: member.isFailoverEnabled,
                },
              ]),
            )
          : {},
        hubNetworkUUID: group?.hubNetworkUUID ?? '',
        uplink: {
          address: group?.hubUplink.address ?? '',
          addressPrefixLength: group?.hubUplink.addressPrefixLength ?? 16,
          phyInterfaceUUID: group?.hubUplink.phyInterface.UUID ?? '',
        },
        isHubFailoverEnabled: group?.isHubFailoverEnabled ?? true,
      }}
      validate={withZodSchema(createAutoVpnGroupInputModifiedSchema)}
      onSubmit={handleSubmit}
    >
      <Form>
        <DrawerContent gutter="all">
          <FieldContainer>
            <FieldProvider name="name">
              <PrimaryField label="Name" element={<TextInput />} />
            </FieldProvider>
          </FieldContainer>
          <AutoVPNGroupHubUplinkInputs />
          {!group && <AutoVPNInitAddMembersSelect networks={networks} />}
          {!group && <AddNetworkToAutoVPNGroupInput networks={networks} />}
          {group && (
            <FieldContainer>
              <PrimaryField label="Hub" element={null} />
              <AutoVPNHubInputs networks={networks} />
            </FieldContainer>
          )}
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={closeDrawer} variant="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </>
          }
        />
      </Form>
    </Formik>
  );
}

// eslint-disable-next-line simple-import-sort/imports
import type { StatusTabItemValues } from '@meterup/connect-ui/src/hooks/useActiveStatusTab';
import { EmptyState, Pane, PaneContent, PaneHeader } from '@meterup/atto';
import StatusTabBar from '@meterup/connect-ui/src/components/Tabs/StatusTabBar';
import { StatusTabItems } from '@meterup/connect-ui/src/hooks/useActiveStatusTab';
import React, { useCallback } from 'react';
import { useHref } from 'react-router';

import AddLocationTableInput from 'connect-frontend/src/dashboard/components/Locations/AddLocationTableInput';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { BulkAddLocationsButton } from './BulkAddLocationsButton';
import { useInvalidateCompanyContracts } from '../../hooks/useContracts';

const counts = Object.keys(StatusTabItems).reduce(
  (acc, key) => ({ ...acc, [key]: 0 }),
  {} as Record<StatusTabItemValues, number>,
);

export default function NoLocations() {
  const basePath = useHref('.');
  const companySID = useCurrentCompany();
  const invalidate = useInvalidateCompanyContracts(companySID);
  const onQuoteRequestCreated = useCallback(async () => {
    await invalidate();
  }, [invalidate]);

  return (
    <Pane>
      <PaneHeader
        icon="location"
        heading="Locations"
        tabs={
          <StatusTabBar
            basePath={basePath}
            tabCount={Object.values(StatusTabItems).reduce(
              (acc, key) => ({ ...acc, [key]: counts[key as StatusTabItemValues] }),
              {} as Record<StatusTabItemValues, number>,
            )}
          />
        }
        actions={<BulkAddLocationsButton companySID={companySID} />}
      />
      <PaneContent>
        <EmptyState
          icon="company"
          heading="Instant business internet quotes"
          action={<AddLocationTableInput onCreatedQuoteRequest={onQuoteRequestCreated} />}
        />
      </PaneContent>
    </Pane>
  );
}

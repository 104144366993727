import type { BreadcrumbProps, PaneHeaderProps } from '@meterup/atto';
import type { DetailHeaderProps } from '@meterup/common';
import { Pane, PaneHeader, Tab } from '@meterup/atto';

import { useClientByMAC } from '../../../../../components/Clients/utils';
import ClientActions from '../../../../../components/Insights/Clients/ClientActions';
import ClientFilters from '../../../../../components/Insights/Clients/ClientFilters';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { clientNameOrNull, manufacturerIconName } from '../../../../../utils/clientLists';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { useInsightsCrumbs, useNavigateBack, useNavigateHome } from '../../../../../utils/routing';

export enum ValidClientDetailsTabs {
  ConnectionEvents = 'connection-events',
  Insights = 'insights',
}

function ClientHeader({
  selectedTab,
  macAddress,
}: {
  selectedTab: ValidClientDetailsTabs;
  macAddress: string;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const insightsCrumb = useInsightsCrumbs();

  const client = useClientByMAC(macAddress);
  const [isWOS2Enabled] = useNosFeaturesEnabled([NosFeature.WOS2]);

  return {
    back,
    home,
    crumbs: [
      ...insightsCrumb,
      {
        type: 'page',
        page: {
          as: ReactRouterLink,
          to: makeLink(paths.pages.ClientsList2Page, { companyName, networkSlug: network.slug }),
          label: 'Clients',
        },
      } as BreadcrumbProps,
    ],
    // TODO: Include a client switcher here.
    icon: manufacturerIconName(client),
    heading: clientNameOrNull(client) ?? client.macAddress ?? '-',
    type: 'client' as DetailHeaderProps['type'],
    tabs: (
      <>
        <Tab
          as={ReactRouterLink}
          to={makeLink(paths.pages.ClientInsightsPage, {
            companyName,
            networkSlug: network.slug,
            macAddress,
          })}
          selected={selectedTab === ValidClientDetailsTabs.Insights}
          icon="reporting"
        >
          Insights
        </Tab>
        <Tab
          as={ReactRouterLink}
          to={makeLink(paths.pages.ClientConnectionEventsPage, {
            companyName,
            networkSlug: network.slug,
            macAddress,
          })}
          selected={selectedTab === ValidClientDetailsTabs.ConnectionEvents}
          icon="connection-events"
        >
          Connection events
        </Tab>
      </>
    ) as PaneHeaderProps['tabs'],
    actions: (
      <IsPermitted
        isPermitted={({ permissions }) =>
          Boolean(permissions.hasPermission(PermissionType.PermNetworkDevicesWrite)) &&
          isWOS2Enabled
        }
      >
        <ClientActions client={client} />
      </IsPermitted>
    ) as PaneHeaderProps['actions'],
    views: <ClientFilters />,
  };
}

export default function Client({
  macAddress,
  main,
  selectedTab,
}: {
  macAddress: string;
  main: React.ReactNode;
  selectedTab: ValidClientDetailsTabs;
}) {
  return (
    <Pane layoutMode="detailed">
      <PaneHeader {...ClientHeader({ selectedTab, macAddress })} />
      {main}
    </Pane>
  );
}

import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import { VirtualDevicesCreateDrawer } from '../../../../../components/Devices/VirtualDeviceCreateDrawer';
import { VirtualDeviceType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/switches/create-bulk',
  title: 'Add switches - Switches - Hardware',
});

export default function SwitchBulkCreatePage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  return <VirtualDevicesCreateDrawer deviceType={VirtualDeviceType.Switch} />;
}

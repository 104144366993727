import IsPermitted from '../../../../../components/permissions/IsPermitted';
import SSIDs from '../../../../../components/Wireless/SSIDs/SSIDs';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/ssids',
  layout: 'VirtualNetworkLayout',
  title: 'SSIDs - Wireless',
});

export default function SSIDsPage() {
  return (
    <IsPermitted permissions={PermissionType.PermSsidsRead}>
      <SSIDs />
    </IsPermitted>
  );
}

import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  Label,
  PaneHeader,
  SearchInput,
  Small,
  ToggleInput,
  Tooltip,
} from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';

import type { NetworkClientOptions } from '../../../../../hooks/networkClients/useNetworkClients';
import type { TimePeriod } from '.';
import { useClientsExporter } from '../../../../../hooks/useClientsExporter';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { Filters, timePeriodLabel } from './clients.constants';
import { useFilteredNetworkClients } from './clients.hooks';

export default function ClientsListHeader({
  networkClientOptions = {},
}: {
  networkClientOptions?: NetworkClientOptions;
}) {
  const controller = useCurrentControllerData();

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');

  const { downloadCSVHandler } = useClientsExporter({
    controllerAddress: controller.address,
    networkUUID: controller.network_uuid,
    numDaysLookback: 7,
  });

  const network = useNetwork();

  const {
    clients,
    excludeMeterHardware,
    currentTimePeriod,
    setExcludeMeterHardware,
    setCurrentTimePeriod,
  } = useFilteredNetworkClients({
    networkClientOptions,
    network,
    useGraphQLOptions: { suspense: false },
  });

  const filterList = Filters();

  return (
    <PaneHeader
      icon="client"
      heading="Clients"
      count={clients.length}
      actions={
        <Tooltip contents="Download connected clients history over the past 7 days as CSV.">
          <Button
            condense
            size="small"
            icon="download"
            arrangement="leading-icon"
            variant="secondary"
            onClick={downloadCSVHandler}
          >
            Export
          </Button>
        </Tooltip>
      }
      tabs={<filterList.TabSwitcher />}
      contentActions={
        <>
          <div style={{ width: 'fit-content', paddingRight: '8px' }}>
            <Label>
              <ToggleInput
                selected={excludeMeterHardware}
                controlSize="small"
                onChange={(selected) => {
                  setExcludeMeterHardware(selected);
                }}
              />
              <Small>Hide Meter hardware</Small>
            </Label>
          </div>
          <DropdownMenu>
            <DropdownMenuButton variant="secondary" arrangement="leading-icon" icon="clock">
              {timePeriodLabel(currentTimePeriod as TimePeriod)}
            </DropdownMenuButton>
            <DropdownMenuPopover>
              <DropdownMenuRadioGroup
                value={currentTimePeriod}
                onValueChange={(val) => setCurrentTimePeriod(val as TimePeriod)}
              >
                <DropdownMenuRadioItem value="5">{timePeriodLabel('5')}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="10">{timePeriodLabel('10')}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="30">{timePeriodLabel('30')}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="60">{timePeriodLabel('60')}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="120">{timePeriodLabel('120')}</DropdownMenuRadioItem>
                <IsOperator>
                  <DropdownMenuRadioItem value="43200">
                    {timePeriodLabel('43200')}
                  </DropdownMenuRadioItem>
                </IsOperator>
              </DropdownMenuRadioGroup>
            </DropdownMenuPopover>
          </DropdownMenu>
          <SearchInput
            placeholder="..."
            aria-label="Filter clients"
            scope="scoped"
            value={globalFilter}
            onChange={setGlobalFilter}
            minWidth="56px"
            disabled={false}
          />
        </>
      }
    />
  );
}

import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { graphql } from '../../../../gql';
import {
  DeadMansSnitchQuery,
  PowerDistributionUnitQuery,
} from '../../../Hardware/PowerDistributionUnits/utils';
import { InspectorConfig } from './Units/InspectorConfig';
import { InspectorMetadata } from './Units/InspectorMetadata';
import { InspectorMetricsStats } from './Units/InspectorMetricsStats';

const PDUInsightsQuery = graphql(`
  query PDUInsightsQuery($serialNumber: String!, $count: Int) {
    hardwareDevice(serialNumber: $serialNumber) {
      __typename
      serialNumber
      macAddress
      deviceModel
      tunnelIPAddress
      bootHistory(count: $count) {
        bootCount
        buildName
        rebootReason
        createdAt
      }
      isConnectedToBackend
      disconnectedFromBackendAt
      virtualDevice {
        __typename
        UUID
        label
        description
        networkUUID
        nosVersion {
          id
          version
          buildName
        }
        pendingNosVersion {
          nosVersion {
            version
          }
          scheduledAt
        }
        ... on PowerDistributionUnitVirtualDevice {
          ipAddress
        }
      }
    }
  }
`);

const BOOT_INFO_COUNT = 5;

export default function InspectorPowerDistributionUnit({ uuid }: { uuid: string }) {
  const virtualDevice = useGraphQL(
    PowerDistributionUnitQuery,
    { uuid },
    { useErrorBoundary: false },
  ).data?.virtualDevice;
  const serialNumber = virtualDevice?.hardwareDevice?.serialNumber;
  const PowerDistributionUnitData = useGraphQL(PDUInsightsQuery, {
    serialNumber: serialNumber ?? '',
    count: BOOT_INFO_COUNT,
  }).data;
  expectDefinedOrThrow(
    PowerDistributionUnitData,
    new ResourceNotFoundError('power distribution unit not found'),
  );

  const deadMansToken = useGraphQL(
    DeadMansSnitchQuery,
    { serialNumber: serialNumber ?? '' },
    { enabled: !!serialNumber, useErrorBoundary: false },
  ).data?.deviceHeartbeatForDevice?.token;
  if (!PowerDistributionUnitData.hardwareDevice) return null;
  if (
    PowerDistributionUnitData.hardwareDevice &&
    PowerDistributionUnitData.hardwareDevice.__typename !== 'PowerDistributionUnitHardwareDevice'
  )
    return null;
  if (!virtualDevice || virtualDevice.__typename !== 'PowerDistributionUnitVirtualDevice')
    return null;

  return (
    <>
      <InspectorMetadata
        hardwareDevice={PowerDistributionUnitData.hardwareDevice}
        virtualDevice={PowerDistributionUnitData.hardwareDevice.virtualDevice}
        lastBootHistoryBuildName={
          PowerDistributionUnitData.hardwareDevice.bootHistory?.[0]?.buildName
        }
        deadMansToken={deadMansToken}
      />
      {PowerDistributionUnitData.hardwareDevice.virtualDevice && (
        <InspectorMetricsStats
          virtualDeviceUUID={PowerDistributionUnitData.hardwareDevice.virtualDevice.UUID}
        />
      )}
      <InspectorConfig serialNumber={PowerDistributionUnitData.hardwareDevice.serialNumber} />
    </>
  );
}

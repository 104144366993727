import { Button } from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../../../constants';
import { PermissionType } from '../../../../gql/graphql';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';
import IsPermitted from '../../../permissions/IsPermitted';

type PortActionsProps = {
  virtualDeviceUUID: string;
  phyInterfaceUUID: string;
  view: 'detail' | 'drawer' | 'edit' | 'list';
};

export function PortActions({ virtualDeviceUUID, phyInterfaceUUID, view }: PortActionsProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();

  const openEdit = () => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.SecurityAppliancePortEditPage, {
        virtualDeviceUUID,
        phyInterfaceUUID,
        networkSlug: network.slug,
        companyName,
      }),
    );
  };

  return (
    <IsPermitted permissions={PermissionType.PermPhyInterfaceWrite}>
      {view !== 'edit' && (
        <Button arrangement="leading-icon" icon="pencil" onClick={openEdit} variant="secondary">
          Edit
        </Button>
      )}
    </IsPermitted>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import CaptivePortals from '../../../../../components/NetworkWide/CaptivePortals/CaptivePortals';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/captive-portals',
  layout: 'VirtualNetworkLayout',
  title: 'Captive portals - Network-wide',
});

export default function CaptivePortalsPage() {
  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, ldFlags, nosFlags }) =>
        permissions.hasPermission(PermissionType.PermCaptivePortalWrite) &&
        (!!ldFlags['captive-portal'] || isOperator) &&
        nosFlags[NosFeature.COS2] &&
        nosFlags[NosFeature.CAPTIVE_PORTAL]
      }
      should404OnAccessDenied
      allowOperatorAlways={false}
    >
      <CaptivePortals />
    </IsPermitted>
  );
}

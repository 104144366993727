import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Body, darkThemeSelector, Icon, Pane, PaneContent, PaneHeader, Small } from '@meterup/atto';
import { isDefinedAndNotEmpty, ISPStatusBadge } from '@meterup/common';
import { api as oldAPI } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import { fetchProviders } from '../../../../../api/api';
import { UploadDownloadStat } from '../../../../../components/Settings/InternetWiFi/UploadDownloadStat';
import { paths } from '../../../../../constants';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { colors, fontWeights, styled } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

export const formatISPProduct = (product: oldAPI.ISPProduct) =>
  match(product)
    .with(oldAPI.ISPProduct.IR_UNKNOWN, () => 'Unknown')
    .with(oldAPI.ISPProduct.IR_DEDICATED_FIBER, () => 'Dedicated fiber')
    .with(oldAPI.ISPProduct.IR_SHARED_FIBER, () => 'Shared fiber')
    .with(oldAPI.ISPProduct.IR_POINT_TO_POINT_WIRELESS, () => 'Point to point wireless')
    .with(oldAPI.ISPProduct.IR_RESIDENTIAL_FIBER, () => 'Residential fiber')
    .with(oldAPI.ISPProduct.IR_RESIDENTIAL_CABLE, () => 'Residential cable')
    .with(oldAPI.ISPProduct.IR_BUSINESS_CABLE, () => 'Business cable')
    .exhaustive();

const ConnectionGrid = styled('div', {
  display: 'table',
  width: '100%',
});

const ConnectionRow = styled('div', {
  display: 'table-row',
  strokeTopBottom: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    strokeTopBottom: colors.strokeNeutralDark,
  },
});

const ConnectionCell = styled('div', Body, {
  display: 'table-cell',
  verticalAlign: 'middle',
  padding: '$12 $8',
  cursor: 'pointer',

  '&:first-child': {
    paddingLeft: '$16',
  },
  '&:last-child': {
    width: '32px',
    textAlign: 'right',
    paddingRight: '$16',
  },
});

const Logo = styled('img', {
  maxWidth: '80px',
  height: 'auto',
  padding: '$6 $4',
  backgroundColor: colors.white,
  borderRadius: '$6',
});

const Stat = styled('div', {
  vStack: '$4',
  alignItems: 'stretch',
});

const PrimaryStatLabel = styled('div', Body, {
  fontWeight: fontWeights.bold,
  color: colors.gray700,
});

const StatLabel = styled('div', Small);

const StatValue = styled('div', {
  hStack: 0,
});

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/network-wide/isps',
});

export default function LegacyISPListPage() {
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const api = useAPIClient();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();

  const providers =
    useQuery(['providers'], fetchProviders, {
      suspense: true,
    }).data ?? [];

  const connections =
    useQuery(['network_isp_info', controllerName], () => api.isps(), {
      suspense: true,
    }).data ?? [];

  return (
    <Pane>
      <PaneHeader icon="globe" heading="ISPs" />
      {connections.length > 0 && (
        <PaneContent>
          <ConnectionGrid>
            {connections.map((plan) => {
              const path = providers.find((p) => p.sid === plan.provider)?.path;
              return (
                <ConnectionRow
                  key={plan.sid}
                  onClick={() => {
                    navigate(
                      makeDrawerLink(window.location, paths.drawers.ISPDetailsPage, {
                        controllerName,
                        companyName,
                        ispSid: plan.sid,
                      }),
                    );
                  }}
                >
                  {isDefinedAndNotEmpty(path) && (
                    <ConnectionCell style={{ width: '1%' }}>
                      <Logo
                        src={`//public.meter.com${path}`}
                        crossOrigin="anonymous"
                        alt={plan.provider_name}
                      />
                    </ConnectionCell>
                  )}
                  <ConnectionCell>
                    <Stat>
                      <PrimaryStatLabel>{plan.provider_name}</PrimaryStatLabel>
                      <StatValue>
                        <ISPStatusBadge status={plan.status} />
                      </StatValue>
                    </Stat>
                  </ConnectionCell>
                  {plan.product && (
                    <ConnectionCell>
                      <Stat>
                        <StatLabel>Product</StatLabel>
                        <StatValue>{formatISPProduct(plan.product)}</StatValue>
                      </Stat>
                    </ConnectionCell>
                  )}
                  <ConnectionCell>
                    <Stat>
                      <StatLabel>Bandwidth</StatLabel>
                      <StatValue>
                        <UploadDownloadStat
                          downloadKbps={plan.download_kbps}
                          uploadKbps={plan.upload_kbps}
                        />
                      </StatValue>
                    </Stat>
                  </ConnectionCell>
                  <ConnectionCell>
                    <Icon
                      color={{ light: 'gray400', dark: 'gray300' }}
                      icon="chevron-right"
                      size={8}
                    />
                  </ConnectionCell>
                </ConnectionRow>
              );
            })}
          </ConnectionGrid>
        </PaneContent>
      )}
    </Pane>
  );
}

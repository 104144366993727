import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import React from 'react';

import InternetAndWireless from '../../../../../components/Settings/InternetWiFi/InternetAndWireless';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/internet-wifi',
});

export default function InternetAndWirelessPage() {
  return <InternetAndWireless />;
}

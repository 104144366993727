import type { OverlayTriggerState } from '@meterup/atto';
import { Button, Dialog, DialogHeader, useDialogState } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useCallback } from 'react';

import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { useVirtualDevices } from '../../../../routes/pages/network/insights/logs/hooks/useVirtualDevices';
import { networkQuery } from '../../../Settings/Network/utils';
import NetworkForm from './NetworkForm';

type RespType = { UUID: string; slug: string };

export function CreateNetworkDialog({
  state,
  onSuccess,
}: {
  state: OverlayTriggerState;
  onSuccess?: (resp: RespType) => void;
}) {
  const { close } = state;
  const handleSuccess = useCallback(
    (resp: RespType) => {
      onSuccess?.(resp);
      close();
    },
    [close, onSuccess],
  );

  return (
    <Dialog state={state}>
      <DialogHeader icon="plus" heading="Add network" />
      <NetworkForm
        framing="dialog"
        showErrorsInline
        onSuccess={handleSuccess}
        actions={
          <>
            <Button type="button" variant="secondary" onClick={state.close}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Add network
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

export default function NetworkDetails() {
  const network = useNetwork();
  const { virtualDevices } = useVirtualDevices();

  const { network: queriedNetwork } = checkDefinedOrThrow(
    useGraphQL(networkQuery, { uuid: network.UUID }).data,
  );

  const { state: deleteNetworkDialogState } = useDialogState();

  return (
    <NetworkForm
      framing="workbench"
      network={queriedNetwork}
      key={queriedNetwork.UUID}
      secondaryActions={
        virtualDevices.length === 0 && (
          <Button
            type="button"
            variant="destructive"
            onClick={() => {
              deleteNetworkDialogState.open();
            }}
          >
            Delete network
          </Button>
        )
      }
      actions={
        <>
          <Button type="reset" variant="secondary">
            Reset
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </>
      }
    />
  );
}

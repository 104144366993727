import type { OverlayTriggerState } from 'react-stately';
import {
  Alert,
  Body,
  Button,
  CopyBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  space,
  SummaryList,
  Tab,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { ClientVPNClient, ClientVPNServer } from './utils';
import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { CopyableDateOrNoValue, CopyableMonoOrNoValue } from '../Devices/Insights';
import IsPermitted from '../permissions/IsPermitted';
import {
  ClientTab,
  clientVPNClientQuery,
  clientVPNClientsQuery,
  clientVPNServersQuery,
  deleteClientVPNClientMutation,
  wireguardConfig,
} from './utils';

function DeleteClientDialog({
  client,
  state,
}: {
  client: ClientVPNClient;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();

  const deleteClient = useGraphQLMutation(deleteClientVPNClientMutation);

  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();

  const handleDelete = useCallback(() => {
    deleteClient.mutate(
      { uuid: client.UUID },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(clientVPNServersQuery, { networkUUID: network.UUID }),
          );
          queryClient.invalidateQueries(
            makeQueryKey(clientVPNClientsQuery, { serverUUID: client.vpnServerUUID }),
          );
          notify('Successfully deleted VPN client.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem deleting the VPN server${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
        onSettled() {
          state.toggle();
          closeDrawer();
        },
      },
    );
  }, [
    network.UUID,
    client.UUID,
    client.vpnServerUUID,
    deleteClient,
    queryClient,
    state,
    closeDrawer,
  ]);

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader heading="Delete VPN client" icon="trash-can" />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>
          <Body>Are you sure you want to delete this VPN client?</Body>
          <Body>This cannot be undone.</Body>
        </VStack>
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button type="button" onClick={state.close} variant="secondary">
              Cancel
            </Button>
            <Button type="button" onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

function ClientVPNTabs({ client, activeTab }: { client: ClientVPNClient; activeTab: ClientTab }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const makeTabLink = useCallback(
    (tab: ClientTab) =>
      makeDrawerLink(window.location, paths.drawers.ClientVPNClientPage, {
        companyName,
        networkSlug: network.slug,
        serverUUID: client.vpnServerUUID,
        clientUUID: client.UUID,
        tab,
      }),
    [companyName, network.slug, client.vpnServerUUID, client.UUID],
  );

  return (
    <>
      <Tab
        as={Link}
        to={makeTabLink(ClientTab.Details)}
        selected={activeTab === ClientTab.Details}
        icon="information"
      >
        Details
      </Tab>
      <Tab
        as={Link}
        to={makeTabLink(ClientTab.WireGuardConfig)}
        selected={activeTab === ClientTab.WireGuardConfig}
        icon="wireguard"
      >
        WireGuard config
      </Tab>
    </>
  );
}

function ClientDetails({ client, server }: { client: ClientVPNClient; server: ClientVPNServer }) {
  return (
    <DrawerContent gutter="vertical" spacing={space(0)}>
      <Sections>
        <Section relation="stacked">
          <SectionHeader heading="Metadata" />
          <SectionContent gutter="all">
            <SummaryList
              gutter="none"
              pairs={[
                {
                  label: 'Name',
                  value: <CopyableMonoOrNoValue value={client.name} label="name" />,
                },
                {
                  label: 'IP address',
                  value: <CopyableMonoOrNoValue value={client.ipAddress} label="IP address" />,
                },
                {
                  label: 'Public key',
                  value: <CopyableMonoOrNoValue value={client.publicKey} label="public key" />,
                },
                {
                  label: 'User email',
                  value: <CopyableMonoOrNoValue value={client.userEmail} label="user email" />,
                },
              ]}
            />
          </SectionContent>
        </Section>
        <Section relation="stacked">
          <SectionHeader heading="Connection" />
          <SectionContent gutter="all">
            <SummaryList
              gutter="none"
              pairs={[
                {
                  label: 'Last handshake received from client',
                  value: (
                    <CopyableDateOrNoValue
                      value={client.connectionInfo?.lastHandshakeRx}
                      label="last handshake received from client"
                    />
                  ),
                },
                {
                  label: 'Last handshake sent to client',
                  value: (
                    <CopyableDateOrNoValue
                      value={client.connectionInfo?.lastHandshakeTx}
                      label="last handshake sent to client"
                    />
                  ),
                },
                {
                  label: 'Last packet received from client',
                  value: (
                    <CopyableDateOrNoValue
                      value={client.connectionInfo?.lastPacketRx}
                      label="last packet received from client"
                    />
                  ),
                },
                {
                  label: 'Last packet sent to client',
                  value: (
                    <CopyableDateOrNoValue
                      value={client.connectionInfo?.lastPacketTx}
                      label="last packet sent to client"
                    />
                  ),
                },
                {
                  label: 'Observed at',
                  value: (
                    <CopyableDateOrNoValue
                      value={client.connectionInfo?.observedAt}
                      label="observed at"
                    />
                  ),
                },
              ]}
            />
          </SectionContent>
        </Section>
        <Section relation="stacked">
          <SectionHeader heading="Server" />
          <SectionContent gutter="all">
            <SummaryList
              gutter="none"
              pairs={[
                {
                  label: 'Endpoint',
                  value: <CopyableMonoOrNoValue value={server.endpoint} label="endpoint" />,
                },
                {
                  label: 'Port',
                  value: <CopyableMonoOrNoValue value={server.port} label="port" />,
                },
                {
                  label: 'Public key',
                  value: <CopyableMonoOrNoValue value={server.publicKey} label="public key" />,
                },
                {
                  label: 'Default client allowed IPs',
                  value: (
                    <CopyableMonoOrNoValue
                      value={server.defaultClientAllowedIPs?.join(', ')}
                      label="default client allowed IPs"
                    />
                  ),
                },
                {
                  label: 'IP address',
                  value: <CopyableMonoOrNoValue value={server.address} label="IP address" />,
                },
                {
                  label: 'Address mask',
                  value: <CopyableMonoOrNoValue value={server.addressMask} label="address mask" />,
                },
              ]}
            />
          </SectionContent>
        </Section>
      </Sections>
    </DrawerContent>
  );
}

function ClientWireGuardConfig({
  client,
  server,
}: {
  client: ClientVPNClient;
  server: ClientVPNServer;
}) {
  if (!server.address) {
    return <EmptyState heading="Could not render Wireguard config for client" />;
  }

  const config = wireguardConfig({ client, server });

  return (
    <DrawerContent gutter="all">
      <Alert
        icon="information"
        heading="Your WireGuard-compatible config"
        copy={
          'Make sure you replace "$YOUR_PRIVATE_KEY" with your private key when pasting into WireGuard.'
        }
      />
      <CopyBox
        aria-label="Copy your WireGuard config"
        relation="standalone"
        size="large"
        value={config}
      >
        <Body family="monospace">{config}</Body>
      </CopyBox>
    </DrawerContent>
  );
}

function ClientContent({
  client,
  server,
  tab,
}: {
  client: ClientVPNClient;
  server: ClientVPNServer;
  tab: ClientTab;
}) {
  switch (tab) {
    case ClientTab.Details:
      return <ClientDetails client={client} server={server} />;
    case ClientTab.WireGuardConfig:
      return <ClientWireGuardConfig client={client} server={server} />;
  }
}

function ClientActions({ client }: { client: ClientVPNClient }) {
  const { state } = useDialogState();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={state.open} icon="trash-can" internal>
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DeleteClientDialog client={client} state={state} />
    </>
  );
}

export default function ClientVPNClientDrawer({
  clientUUID,
  tab,
}: {
  clientUUID: string;
  tab: ClientTab;
}) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const client = useGraphQL(clientVPNClientQuery, { UUID: clientUUID }).data?.clientVPNClient;
  expectDefinedOrThrow(client, new ResourceNotFoundError('VPN client not found.'));

  // TODO: Get this as a sub-field of client
  const servers = useGraphQL(clientVPNServersQuery, { networkUUID: network.UUID }).data
    ?.clientVPNServers;

  const server = useMemo(
    () => servers?.find((s) => s.UUID === client.vpnServerUUID),
    [servers, client.vpnServerUUID],
  );
  expectDefinedOrThrow(server, new ResourceNotFoundError('VPN server not found.'));

  return (
    <Drawer>
      <DrawerHeader
        icon="client"
        heading="Client"
        onClose={closeDrawer}
        tabs={<ClientVPNTabs client={client} activeTab={tab} />}
        actions={
          <IsPermitted permissions={PermissionType.PermClientVpnWrite}>
            <ClientActions client={client} />
          </IsPermitted>
        }
      />
      <ClientContent client={client} server={server} tab={tab} />
    </Drawer>
  );
}

import type { ClientError } from 'graphql-request';
import { expectDefinedOrThrow, notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { graphql } from '../../../../../gql';

const latestDeviceConfigQuery = graphql(`
  query LatestDeviceConfig($serialNumber: String!) {
    latestDeviceConfig(serialNumber: $serialNumber) {
      configJSON
    }
  }
`);

const deviceConfigOverrideQuery = graphql(`
  query DeviceConfigOverride($serialNumber: String!) {
    deviceConfigOverride(serialNumber: $serialNumber) {
      configJSON
    }
  }
`);

const upsertDeviceConfigOverride = graphql(`
  mutation UpdateDeviceConfigOverride($serialNumber: String!, $configJSON: String!) {
    upsertDeviceConfigOverride(serialNumber: $serialNumber, configJSON: $configJSON)
  }
`);

interface DeviceConfigContextValue {
  serialNumber: string | undefined;
  setSerialNumber?: (value: string) => void;

  currentConfigError: ClientError | null;
  currentConfigJSON: string;

  currentOverrideError: ClientError | null;
  currentOverrideJSON: string;
  setCurrentOverrideJSON: (value: string) => void;
  currentOverrideJSONIsDirty: boolean;

  handleResetOverride: () => void;
  handleUpsertOverride: () => void;
  upsertOverrideIsLoading: boolean;
}

const DeviceConfigContext = createContext<DeviceConfigContextValue>(null as any);

export function useDeviceConfigContext() {
  const value = useContext(DeviceConfigContext);

  expectDefinedOrThrow(
    value,
    new Error('useDeviceConfigContext must be used within a DeviceConfigContextProvider'),
  );

  return value;
}

export function DeviceConfigContextProvider({
  serialNumber,
  setSerialNumber,
  children,
}: {
  serialNumber: string | undefined;
  setSerialNumber?: (serialNumber: string) => void;
  children: React.ReactNode;
}) {
  const latestConfig = useGraphQL(
    latestDeviceConfigQuery,
    {
      serialNumber: serialNumber!,
    },
    {
      enabled: !!serialNumber,
      useErrorBoundary: false,
    },
  );
  const currentConfigJSON = useMemo(
    () => JSON.stringify(latestConfig.data?.latestDeviceConfig?.configJSON ?? {}, null, 2),
    [latestConfig.data?.latestDeviceConfig?.configJSON],
  );

  const latestOverride = useGraphQL(
    deviceConfigOverrideQuery,
    {
      serialNumber: serialNumber!,
    },
    {
      enabled: !!serialNumber,
      useErrorBoundary: false,
    },
  );

  const currentSavedOverrideJSON = useMemo(
    () => JSON.stringify(latestOverride.data?.deviceConfigOverride?.configJSON ?? {}, null, 2),
    [latestOverride.data?.deviceConfigOverride?.configJSON],
  );
  const [currentOverrideJSON, setCurrentOverrideJSON] = useState(() => currentSavedOverrideJSON);

  useEffect(() => {
    setCurrentOverrideJSON(currentSavedOverrideJSON);
  }, [setCurrentOverrideJSON, currentSavedOverrideJSON]);

  const handleResetOverride = useCallback(() => {
    setCurrentOverrideJSON(currentSavedOverrideJSON);
  }, [setCurrentOverrideJSON, currentSavedOverrideJSON]);

  const upsertOverride = useGraphQLMutation(upsertDeviceConfigOverride);
  const { mutate } = upsertOverride;

  const queryClient = useQueryClient();

  const handleUpsertOverride = useCallback(() => {
    if (!serialNumber) return;

    mutate(
      { serialNumber, configJSON: currentOverrideJSON },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(makeQueryKey(deviceConfigOverrideQuery, { serialNumber }));

          notify('Device config updated successfully.', { variant: 'positive' });
        },
        onError: (error) => {
          const gqlerr = getGraphQLError(error);

          notify(
            `There was an error updating the device override${gqlerr?.message ? `: ${gqlerr.message}` : ''}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [mutate, serialNumber, currentOverrideJSON, queryClient]);

  const value = useMemo(
    () => ({
      serialNumber,
      setSerialNumber,
      currentConfigError: latestConfig.error,
      currentConfigJSON,
      currentOverrideError: latestOverride.error,
      currentOverrideJSONIsDirty: currentOverrideJSON?.trim() !== currentSavedOverrideJSON?.trim(),
      currentOverrideJSON,
      setCurrentOverrideJSON,
      handleResetOverride,
      handleUpsertOverride,
      upsertOverrideIsLoading: upsertOverride.isLoading,
    }),
    [
      serialNumber,
      setSerialNumber,
      latestConfig.error,
      currentConfigJSON,
      latestOverride.error,
      currentSavedOverrideJSON,
      currentOverrideJSON,
      setCurrentOverrideJSON,
      handleResetOverride,
      handleUpsertOverride,
      upsertOverride.isLoading,
    ],
  );

  return <DeviceConfigContext.Provider value={value}>{children}</DeviceConfigContext.Provider>;
}

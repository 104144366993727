import ISPMove from '../../../../../components/NetworkWide/ISPs/ISPMove';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/isps/move',
  title: 'Move ISP - ISPs - Network-wide',
});

export default function ISPMoveDrawerPage() {
  return <ISPMove />;
}

import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import { DeviceType } from '../../gql/graphql';
import { useNetwork } from '../../hooks/useNetworkFromPath';

export const useSiblings = ({ query, deviceType }: { query: any; deviceType: any }) => {
  const network = useNetwork();

  const plainTextLabel = () => {
    switch (deviceType) {
      case DeviceType.AccessPoint:
        return 'access points';
      case DeviceType.Controller:
        return 'security appliance';
      case DeviceType.PowerDistributionUnit:
        return 'power distribution units';
      case DeviceType.Switch:
        return 'switches';
      default:
        return null;
    }
  };

  const siblingData = useGraphQL(query, { networkUUID: network.UUID }).data;
  expectDefinedOrThrow(siblingData, new ResourceNotFoundError(`Unable to load ${plainTextLabel}s`));

  const siblings = useMemo(
    () =>
      (siblingData as any).virtualDevicesForNetwork.filter((s: any) => s.deviceType === deviceType),
    [siblingData, deviceType],
  );

  return siblings.sort((a: any, b: any) => (a.label || a.UUID).localeCompare(b.label || b.UUID));
};

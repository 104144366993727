import {
  Button,
  ComboBox,
  ComboBoxItem,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  PrimaryField,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { notify, ResourceNotFoundError, truthy } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, useGraphQLMutation } from '@meterup/graphql';
import { type FormikHelpers, Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider } from '../../Form/FieldProvider';
import { formatAddress, ispMoveMutation, useInvalidateISPs, useISPs } from './utils';

const ispMoveSchema = z.object({
  sourceISP: z.string().uuid().nullish(),
});

type ISPMoveSchema = z.infer<typeof ispMoveSchema>;

export default function ISPMove() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const close = useCloseDrawerCallback();
  const isps = useISPs({ companySlug: companyName });
  const invalidateISPs = useInvalidateISPs({ companySlug: companyName });
  const ispsToShow = useMemo(() => isps.filter((isp) => !truthy(isp.networkUUID)), [isps]);
  const navigate = useNavigate();
  const mutation = useGraphQLMutation(ispMoveMutation);
  const onSubmitCopy = useCallback(
    (values: ISPMoveSchema, form: FormikHelpers<ISPMoveSchema>) => {
      if (!values.sourceISP) return;
      mutation.mutate(
        {
          ispUUID: values.sourceISP,
          networkUUID: network.UUID,
        },
        {
          onSuccess() {
            notify(`Successfully moved ISP`, {
              variant: 'positive',
            });
            form.resetForm();
            invalidateISPs();
            navigate(
              makeLink(paths.pages.ISPsPage, {
                companyName,
                networkSlug: network.slug,
              }),
            );
          },
          onError(err) {
            notify(`Failed to move ISP${getGraphQLErrorMessageOrEmpty(err)}`, {
              variant: 'negative',
            });
          },
        },
      );
    },
    [companyName, invalidateISPs, mutation, navigate, network.UUID, network.slug],
  );

  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  return (
    <Drawer>
      <DrawerHeader icon="globe" heading="Move ISP" onClose={close} />
      <Formik<ISPMoveSchema>
        initialValues={{}}
        onSubmit={onSubmitCopy}
        validate={withZodSchema(ispMoveSchema)}
      >
        <Form style={{ display: 'contents' }}>
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="sourceISP">
                <PrimaryField
                  element={
                    <ComboBox placeholder="Select ISP" canClearValue>
                      {ispsToShow.map((isp) => (
                        <ComboBoxItem key={isp.UUID}>
                          {formatAddress(isp.address)}
                          {isp.provider ? ` - ${isp.provider?.name}` : ''}
                        </ComboBoxItem>
                      ))}
                    </ComboBox>
                  }
                  label="Select ISP to move to this network"
                />
              </FieldProvider>
            </FieldContainer>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button variant="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit">Move</Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';

import type { UpdateISPSchema } from './utils';
import { graphql } from '../../../gql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { withZodSchema } from '../../../utils/withZodSchema';
import ISPFormShared from './ISPFormShared';
import { listISPsQuery, updateISPSchema, useISP } from './utils';

const updateISPMutation = graphql(`
  mutation UpdateInternetServicePlanMutation(
    $internetServicePlanUUID: UUID!
    $input: UpdateInternetServicePlanInput!
  ) {
    updateInternetServicePlan(internetServicePlanUUID: $internetServicePlanUUID, input: $input) {
      UUID
    }
  }
`);

export default function ISPEditForm({ ispUUID }: { ispUUID: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const mutation = useGraphQLMutation(updateISPMutation);
  const queryClient = useQueryClient();
  const onCancel = useCloseDrawerCallback();
  const onSubmit = useCallback(
    (values: UpdateISPSchema) => {
      mutation.mutate(
        {
          internetServicePlanUUID: ispUUID,
          input: values.input,
        },
        {
          onSuccess() {
            notify(`Successfully created ISP`, {
              variant: 'positive',
            });
            queryClient.invalidateQueries(
              makeQueryKey(listISPsQuery, {
                companySlug: companyName,
                networkUUID: network.UUID,
              }),
            );
          },
          onError(err) {
            notify(`Failed to updateISP${getGraphQLErrorMessageOrEmpty(err)}`, {
              variant: 'negative',
            });
          },
        },
      );
    },
    [companyName, ispUUID, network.UUID, mutation, queryClient],
  );
  const [isUseDHCP, setIsUseDHCPBase] = useState(false);
  const setIsUseDHCP = useCallback((val: boolean) => setIsUseDHCPBase(val), [setIsUseDHCPBase]);

  const isp = useISP({ companySlug: companyName, networkUUID: network.UUID, UUID: ispUUID });

  return (
    <Formik<UpdateISPSchema>
      initialValues={{
        input: {
          connection: {
            product: isp.connectionProduct,
            status: isp.connectionStatus,
          },
          ipConfiguration: {
            controllerIP: isp.controllerIP,
            controllerName: isp.controllerName,
            firstUsableIP: isp.firstUsableIP,
            gatewayAddr: isp.gatewayAddr,
            lastUsableIP: isp.lastUsableIP,
            staticIPRange: isp.staticIPRange,
          },
          providerUUID: isp.provider?.UUID,
        },
      }}
      validate={withZodSchema(updateISPSchema)}
      onSubmit={onSubmit}
    >
      <Form>
        <ISPFormShared
          onCancel={onCancel}
          isUseDHCP={isUseDHCP}
          setIsUseDHCP={setIsUseDHCP}
          submitButtonLabel="Update"
        />
      </Form>
    </Formik>
  );
}

import type { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';

import APIv2 from '../api/apiv2';

export const useNetworkInterfaces = (
  controller: api.ControllerResponse,
  options: { enabled?: boolean } = { enabled: true },
) => {
  const apiClient = new APIv2(controller);
  return (
    useQuery(
      ['controller', controller.name, 'interfaces', 'active'],
      () => apiClient.getNetworkInterfaces(controller.name),
      {
        suspense: true,
        ...options,
      },
    )
      .data?.filter((network) => network.ip_addresses?.length > 0)
      .map((network) => network.interface)
      .sort((a, b) => (a > b ? -1 : 1)) ?? []
  );
};

import { getRealm, Realm } from './realm';

export const deviceHasMismatchedRealm = (
  d: undefined | null | { isDev?: boolean | undefined },
): boolean => {
  const realm = getRealm();
  if (!d) {
    return false;
  }
  if (d.isDev == null) {
    return false;
  }
  if (d.isDev && (realm === Realm.STAGING || realm === Realm.LOCAL)) {
    return false;
  }
  return !(!d.isDev && realm === Realm.PRODUCTION);
};

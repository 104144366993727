/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
import type { SortingState } from '@tanstack/react-table';
import {
  Badge,
  EmptyState,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  SearchInput,
  Small,
  space,
} from '@meterup/atto';
import { observer } from '@meterup/command';
import { AutoTable } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import type { SwitchData } from '../../../api/types';
import { paths } from '../../../constants';
import { useNetworkClients } from '../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useAPIClient } from '../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { createColumnBuilder } from '../../Table/createColumnBuilder';

const LegacySwitchList = observer(() => {
  const companyName = useCurrentCompany();
  const controller = useCurrentControllerData();
  const api = useAPIClient();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SwitchSummaryPage);
  const closeDrawer = useCloseDrawerCallback();

  const devices = useQuery(
    ['controller', controller.name, 'switches'],
    () => api.devicesForController(),
    {
      suspense: true,
    },
  ).data;

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const switches = devices?.switches.sort((a, b) => a.location.localeCompare(b.location)) ?? [];
  const network = useNetwork();
  const connectedClients = useNetworkClients(network);
  const [macAddrsToIPAddresses, setMacAddrsToIPAddresses] = useState(new Map());
  const [clientsLoaded, setClientsLoaded] = useState(false);

  useEffect(() => {
    const updatedMap = new Map();
    connectedClients.forEach((client) => {
      updatedMap.set(client.macAddress, client.ip);
    });
    setMacAddrsToIPAddresses(updatedMap);
    setClientsLoaded(true);
  }, [connectedClients]);

  const builder = createColumnBuilder<SwitchData>();

  const columns = [
    builder.data((s) => s.location, {
      enableGlobalFilter: false,
      id: 'status',
      header: () => <Icon icon="question" size={space(16)} />,
      meta: {
        alignment: 'center',
        width: 48,
        tooltip: {
          contents: 'Status',
        },
      },
      cell: (p) => (
        <Badge
          arrangement="hidden-label"
          variant={macAddrsToIPAddresses.get(p.row.mac) ? 'positive' : 'neutral'}
          icon={macAddrsToIPAddresses.get(p.row.mac) ? 'checkmark' : 'cross'}
          size="small"
          ends="pill"
        >
          {macAddrsToIPAddresses.get(p.row.mac) ? 'Online' : 'Offline'}
        </Badge>
      ),
    }),
    builder.data((s) => s.location, {
      id: 'icon',
      header: '',
      meta: { alignment: 'center', width: 40 },
      enableGlobalFilter: false,
      cell: () => <Icon icon="switch" size={space(16)} />,
    }),
    builder.data((s) => s.location ?? '-', {
      id: 'name',
      header: 'Name',
      meta: {
        isLeading: true,
      },
    }),
    builder.data((s) => macAddrsToIPAddresses.get(s.mac) ?? '-', {
      id: 'ip',
      header: 'IP',
      meta: { width: 120 },
      cell: (p) => <Small family="monospace">{p.value}</Small>,
    }),
    builder.data((s) => s.mac ?? '-', {
      id: 'mac',
      header: 'MAC',
      meta: { width: 136 },
      cell: (p) => <Small family="monospace">{p.value}</Small>,
    }),
    builder.data((s) => (s.poe_wattage ? `${s.poe_wattage.toFixed(0)}W` : ''), {
      id: 'poe-wattage',
      header: 'PoE wattage',
      enableGlobalFilter: false,
      cell: (p) => <Small family="monospace">{p.value}</Small>,
    }),
    builder.data((s) => s.port_info ?? '-', {
      id: 'ports',
      header: 'Ports',
      cell: (p) => <Small family="monospace">{p.value}</Small>,
    }),
  ];

  return (
    <Pane>
      <PaneHeader
        icon="switch"
        heading="Switches"
        actions={
          <SearchInput
            placeholder="..."
            aria-label="Filter switches"
            scope="scoped"
            value={globalFilter}
            onChange={(value) => setGlobalFilter(value)}
            minWidth="56px"
          />
        }
      />
      <PaneContent>
        {switches.length > 0 ? (
          clientsLoaded && (
            <AutoTable
              key={JSON.stringify(switches)}
              columns={columns}
              data={switches}
              globalFilter={globalFilter}
              sortingState={sortingState}
              onChangeSortingState={setSortingState}
              isRowSelected={(s) => s.mac === drawerParams?.uuid}
              onRowDeselect={closeDrawer}
              getLinkTo={(row) =>
                makeDrawerLink(window.location, paths.drawers.LegacySwitchSummaryPage, {
                  id: row.mac,
                  controllerName: controller.name,
                  companyName,
                })
              }
            />
          )
        ) : (
          <EmptyState icon="switch" heading="No switches found for this network" />
        )}
      </PaneContent>
    </Pane>
  );
});

export default LegacySwitchList;

import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import IntegrationsLayout from '../../../../../components/Settings/Organization/Integrations/IntegrationsLayout';
import { IntegrationsValidTabs } from '../../../../../components/Settings/Organization/Integrations/IntegrationsTabs';
import Services from '../../../../../components/Settings/Organization/Integrations/Services/Services';

export function Meta() {
  return {
    path: '/org/:companyName/settings/org/integrations/services',
    title: 'Services - Integrations - Org',
  };
}

export default function ServicesPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  return (
    <IntegrationsLayout
      activeTab={IntegrationsValidTabs.Services}
      gutter="all"
      main={<Services />}
    />
  );
}

import { expectDefinedOrThrow, isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { TabletQRCode } from 'tablet-qr-code';

import { fetchControllerSSIDs } from '../api/api';
import { SsidEncryptionProtocol } from '../gql/graphql';
import { useNetwork } from '../hooks/useNetworkFromPath';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { SSIDsQuery } from './Wireless/SSIDs/SSIDsUtils';

export function ControllerJoinInstructions() {
  const { ssid: ssidParam } = useParams<{ ssid: string }>();
  const controller = useCurrentController();
  const networkInfo =
    useQuery(['controller_ssids', controller], () => fetchControllerSSIDs(controller), {
      suspense: true,
      refetchInterval: 1000 * 60 * 15,
    }).data ?? [];

  const queryClient = useQueryClient();

  const refreshSSID = () => {
    queryClient.invalidateQueries(['controller_ssids']);
  };

  const ssidData = networkInfo.find((d) => d.ssid === ssidParam);

  const password = ssidData?.password?.value ?? '';
  const ssid = ssidData?.ssid ?? '';

  return (
    <TabletQRCode
      type="WPA"
      ssid={ssid}
      password={password}
      hiddenSSID={false}
      onRefresh={refreshSSID}
    />
  );
}

export function NetworkJoinInstructions() {
  const { ssid: ssidParam } = useParams<{ ssid: string }>();
  const network = useNetwork();

  const queryClient = useQueryClient();
  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork;
  expectDefinedOrThrow(ssids, new ResourceNotFoundError('Unable to load SSIDs'));

  const ssid = ssids.find((s) => s.ssid === ssidParam);
  expectDefinedOrThrow(ssid, new ResourceNotFoundError(`SSID ${ssidParam} does not exist`));

  const hasPassword =
    isDefinedAndNotEmpty(ssid.encryptionProtocol) &&
    ssid.encryptionProtocol !== SsidEncryptionProtocol.Wpa2Enterprise &&
    ssid.encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise;
  const password = hasPassword ? ssid.password ?? '' : '';

  const refreshSSID = () => {
    queryClient.invalidateQueries(makeQueryKey(SSIDsQuery, { networkUUID: network.UUID }));
  };

  return (
    <TabletQRCode
      type={hasPassword ? 'WPA' : 'nopass'}
      ssid={ssid.ssid}
      password={password}
      hiddenSSID={false}
      onRefresh={refreshSSID}
    />
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import type { Node } from '@react-types/shared/src/collections';
import {
  Alert,
  Body,
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  Dialog,
  DialogContent,
  DialogHeader,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  HStack,
  PrimaryField,
  PrimaryToggleField,
  space,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';

import type { PhyInterface } from './useCurrentControllers';
import type { HighAvailabilityConfig } from './utils';
import type { CreateHighAvailabilityConfigSchema } from './utils2';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider } from '../../Form/FieldProvider';
import IsPermitted from '../../permissions/IsPermitted';
import { useIsPermitted } from '../../permissions/useIsPermitted';
import useCurrentControllers from './useCurrentControllers';
import {
  ControllersForSecurityApplianceQuery,
  CreateHighAvailabilityConfigMutation,
  DeleteHighAvailabilityConfigMutation,
  HAConfigForNetworkQuery,
} from './utils';
import { createHighAvailabilityConfigSchema } from './utils2';

function toTitleCase(str: string): string {
  return str.replace(
    /\b\w+/g,
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
}

function truncate(str: string, numChars = 5): string {
  if (str.length <= numChars) {
    return str;
  }
  return `${str.slice(0, numChars)}...`;
}

type PhyInterfaceComboBoxProps = {
  disabled?: boolean;
  name: 'primary' | 'backup';
};

function PhyInterfaceComboBox({ disabled = false, name }: PhyInterfaceComboBoxProps) {
  const fieldName =
    `${name}PhyInterfaceUUID` as `${PhyInterfaceComboBoxProps['name']}PhyInterfaceUUID`;
  const form = useFormikContext<CreateHighAvailabilityConfigSchema>();
  const { virtualDevices } = useCurrentControllers();
  const ours =
    name === 'primary' ? form.values.primaryPhyInterfaceUUID : form.values.backupPhyInterfaceUUID;
  const other =
    name === 'primary' ? form.values.backupPhyInterfaceUUID : form.values.primaryPhyInterfaceUUID;
  const phyInterfaceUUIDs = useMemo(() => {
    const phyInterfaceUUIDsVD1: string[] = [];
    const phyInterfaceUUIDsVD2: string[] = [];
    const phyInterfaceMap: Record<string, PhyInterface> = {};

    virtualDevices.forEach((virtualDevice, vdIdx) => {
      virtualDevice.phyInterfaces.forEach((phyInterface) => {
        if (vdIdx === 0) {
          phyInterfaceUUIDsVD1.push(phyInterface.UUID);
        } else {
          phyInterfaceUUIDsVD2.push(phyInterface.UUID);
        }
        phyInterfaceMap[phyInterface.UUID] = phyInterface;
      });
    });

    return {
      allPhyInterfaceUUIDs: [...phyInterfaceUUIDsVD1, ...phyInterfaceUUIDsVD2],
      phyInterfaceMap,
      phyInterfaceUUIDsVD1,
      phyInterfaceUUIDsVD2,
    };
  }, [virtualDevices]);

  const filteredComboBoxItems = useMemo(() => {
    const { phyInterfaceUUIDsVD1, phyInterfaceUUIDsVD2 } = phyInterfaceUUIDs;
    const vd1Name = virtualDevices[0].UUID;
    const vd2Name = virtualDevices[1].UUID;
    if (!ours && !!other) {
      if (phyInterfaceUUIDsVD1.includes(other)) {
        return { [vd2Name]: phyInterfaceUUIDsVD2 };
      }
      return { [vd1Name]: phyInterfaceUUIDsVD1 };
    }
    return {
      [vd1Name]: phyInterfaceUUIDsVD1,
      [vd2Name]: phyInterfaceUUIDsVD2,
    };
  }, [other, ours, phyInterfaceUUIDs, virtualDevices]);

  const phyInterfaceLabel = (phyInterfaceUUID: string) => {
    const phyInterface = phyInterfaceUUIDs.phyInterfaceMap[phyInterfaceUUID];
    return `Port ${phyInterface.portNumber}${phyInterface.label ? `: ${phyInterface.label}` : ''}`;
  };
  const renderSelectedLabel = useCallback(
    (result: Node<object>) => {
      const key = result.key as string;
      const vdUUID =
        Object.keys(filteredComboBoxItems).find((vd) => filteredComboBoxItems[vd].includes(key)) ??
        '';
      const vdLabel = virtualDevices.find((vd) => vd.UUID === vdUUID);
      const label = vdLabel?.label ? `${truncate(vdLabel.label, 12)}: ` : vdUUID;

      return `${label}${result.textValue}`;
    },
    [filteredComboBoxItems, virtualDevices],
  );
  return (
    <FieldProvider name={fieldName}>
      <PrimaryField
        label={`${toTitleCase(name)} interface`}
        element={
          <ComboBox
            disabled={disabled}
            placeholder={`Select ${name} interface`}
            maxWidth="100%"
            canClearValue={!disabled}
            renderSelected={renderSelectedLabel}
          >
            {virtualDevices.flatMap((vd) => {
              const filtered = filteredComboBoxItems;
              if (!filtered[vd.UUID]) return [];
              return [
                <ComboBoxSection key={vd.UUID} title={vd.label}>
                  {filtered[vd.UUID].map((phyInterfaceUUID) => (
                    <ComboBoxItem
                      key={phyInterfaceUUID}
                      textValue={phyInterfaceLabel(phyInterfaceUUID)}
                    >
                      {phyInterfaceLabel(phyInterfaceUUID)}
                    </ComboBoxItem>
                  ))}
                </ComboBoxSection>,
              ];
            })}
          </ComboBox>
        }
      />
    </FieldProvider>
  );
}

function DeleteHADialog({ state }: { state: OverlayTriggerState }) {
  const network = useNetwork();
  const deleteHAMutation = useGraphQLMutation(DeleteHighAvailabilityConfigMutation);

  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();

  const onConfirmDeleteHA = useCallback(() => {
    deleteHAMutation.mutate(
      {
        networkUUID: network.UUID,
      },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(ControllersForSecurityApplianceQuery, { networkUUID: network.UUID }),
          );
          queryClient.invalidateQueries(
            makeQueryKey(HAConfigForNetworkQuery, { networkUUID: network.UUID }),
          );
          notify('High availability configuration deleted', { variant: 'positive' });
          state.close();
          closeDrawer();
        },
        onError(err) {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error deleting the high availability configuration${
              gqlErr?.message ? `: ${gqlErr.message}` : ''
            }.`,
            { variant: 'negative' },
          );
        },
      },
    );
  }, [closeDrawer, state, deleteHAMutation, network.UUID, queryClient]);

  return (
    <Dialog state={state}>
      <DialogHeader heading="Remove high availability" />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>
          <Alert
            icon="attention"
            variant="negative"
            heading="Disabling high availability can impact the network"
            copy={
              <>
                Your security appliances will no longer operate in high availability mode. If one
                appliance fails, the other will not automatically take over. This could result in
                downtime for your network.
              </>
            }
          />
          <Body>
            Are you sure you want to disable high availability for your network? This action cannot
            be undone.
          </Body>
          <HStack justify="end" spacing={space(8)}>
            <Button type="button" variant="secondary" onClick={state.close}>
              Cancel
            </Button>
            <Button type="button" variant="destructive" onClick={onConfirmDeleteHA}>
              Disable
            </Button>
          </HStack>
        </VStack>
      </DialogContent>
    </Dialog>
  );
}

function HighAvailabilityActions({
  highAvailabilityConfig,
  deleteDialogState,
}: {
  highAvailabilityConfig: HighAvailabilityConfig;
  deleteDialogState: OverlayTriggerState;
}) {
  return (
    <IsPermitted permissions={PermissionType.PermHighAvailabilityWrite}>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem
              icon="security-appliance"
              onSelect={deleteDialogState.open}
              disabled={!highAvailabilityConfig}
            >
              Disable high availability
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
    </IsPermitted>
  );
}

export default function ConfigureHighAvailability() {
  const queryClient = useQueryClient();
  const network = useNetwork();
  const mutation = useGraphQLMutation(CreateHighAvailabilityConfigMutation);
  const closeDrawer = useCloseDrawerCallback();
  const { virtualDevices } = useCurrentControllers();
  const highAvailabilityConfig = useGraphQL(HAConfigForNetworkQuery, {
    networkUUID: network.UUID,
  }).data?.network?.highAvailabilityConfig;

  if (virtualDevices.length < 2) {
    throw new ResourceNotFoundError(
      'Network must have at least 2 security appliances to enable high availability',
    );
  }

  const canEdit = useIsPermitted({ permissions: PermissionType.PermHighAvailabilityWrite });

  const [selected, setSelected] = useState(!!highAvailabilityConfig);
  const networkSupportsHA = virtualDevices.length >= 2;

  const { state: deleteHADialogState } = useDialogState();

  const onSubmit = useCallback(
    (values: CreateHighAvailabilityConfigSchema) => {
      if (highAvailabilityConfig) return;
      mutation.mutate(
        {
          networkUUID: network.UUID,
          input: values.input,
          backupPhyInterfaceUUID: values.backupPhyInterfaceUUID,
          primaryPhyInterfaceUUID: values.primaryPhyInterfaceUUID,
        },
        {
          onSuccess() {
            queryClient.invalidateQueries(
              makeQueryKey(ControllersForSecurityApplianceQuery, { networkUUID: network.UUID }),
            );
            queryClient.invalidateQueries(
              makeQueryKey(HAConfigForNetworkQuery, { networkUUID: network.UUID }),
            );
            notify('High availability enabled', { variant: 'positive' });
            closeDrawer();
          },
          onError(err) {
            notify(
              `There was an error enabling high availability${getGraphQLErrorMessageOrEmpty(err)}.`,
              { variant: 'negative' },
            );
          },
        },
      );
    },
    [closeDrawer, highAvailabilityConfig, mutation, network.UUID, queryClient],
  );

  return (
    <>
      <DrawerHeader
        icon="security-appliance"
        heading={`${highAvailabilityConfig ? 'View' : 'Edit'} high availability`}
        actions={
          !!highAvailabilityConfig && (
            <HighAvailabilityActions
              highAvailabilityConfig={highAvailabilityConfig}
              deleteDialogState={deleteHADialogState}
            />
          )
        }
        onClose={closeDrawer}
      />
      <Formik<CreateHighAvailabilityConfigSchema>
        initialValues={{
          primaryPhyInterfaceUUID: highAvailabilityConfig?.primaryPhyInterface.UUID ?? '',
          backupPhyInterfaceUUID: highAvailabilityConfig?.backupPhyInterface.UUID ?? '',
          input: {
            preempt: highAvailabilityConfig?.preemption ?? true,
            advertisementIntervalMs: highAvailabilityConfig?.advertisementIntervalMs ?? 300,
            advertisementVLANID: highAvailabilityConfig?.advertisementVLAN.vlanID ?? 4040,
          },
        }}
        onSubmit={onSubmit}
        validate={withZodSchema(createHighAvailabilityConfigSchema)}
      >
        <Form>
          <DrawerContent gutter="all">
            {!highAvailabilityConfig && (
              <PrimaryToggleField
                label="Enable high availability"
                selected={selected}
                disabled={!!highAvailabilityConfig || !networkSupportsHA || !canEdit}
                onChange={setSelected}
              />
            )}

            {selected && networkSupportsHA && (
              <>
                {!!highAvailabilityConfig && (
                  <Alert
                    icon="information"
                    variant="neutral"
                    heading="High availability is already enabled"
                    copy={
                      canEdit
                        ? 'To update the configuration, please disable it using the dropdown menu above and recreate it.'
                        : undefined
                    }
                  />
                )}
                <PhyInterfaceComboBox
                  name="primary"
                  disabled={!!highAvailabilityConfig || !canEdit}
                />
                <PhyInterfaceComboBox
                  name="backup"
                  disabled={!!highAvailabilityConfig || !canEdit}
                />
                {/*
                TODO: Reenable this once this field works on the devices
                <FieldProvider name="input.preempt">
                  <PrimaryToggleField disabled={!highAvailabilityConfig} label="Preemption" />
                </FieldProvider>
                */}

                <FieldProvider name="input.advertisementIntervalMs">
                  <PrimaryField
                    label="Advertisement interval (ms)"
                    element={
                      <TextInput
                        inputProps={{ type: 'number' }}
                        disabled={!!highAvailabilityConfig || !canEdit}
                      />
                    }
                  />
                </FieldProvider>

                <FieldProvider name="input.advertisementVLANID">
                  <PrimaryField
                    label="Advertisement VLAN ID"
                    element={
                      <TextInput
                        inputProps={{ type: 'number' }}
                        disabled={!!highAvailabilityConfig || !canEdit}
                      />
                    }
                  />
                </FieldProvider>
              </>
            )}
          </DrawerContent>

          {selected && networkSupportsHA && !highAvailabilityConfig && canEdit && (
            <DrawerFooter
              actions={
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={closeDrawer}
                    disabled={mutation.isLoading}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" loading={mutation.isLoading}>
                    Save
                  </Button>
                </>
              }
            />
          )}
        </Form>
      </Formik>
      <DeleteHADialog state={deleteHADialogState} />
    </>
  );
}

import {
  Badge,
  Column,
  Columns,
  ComboBox,
  ComboBoxItem,
  Pane,
  PaneContent,
  PaneHeader,
  Section,
  SectionContent,
  SectionHeader,
} from '@meterup/atto';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { SSID } from './SSIDsUtils';
import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useFilteredNetworkClients } from '../../../routes/pages/network/insights/clients/clients.hooks';
import { ClientsList2 } from '../../../routes/pages/network/insights/clients/ClientsList2';
import { ClientsListActions } from '../../../routes/pages/network/insights/clients/ClientsListHeader2';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useWirelessCrumbs } from '../../../utils/routing';
import { PaneContentSkeletons } from '../../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../../ReactRouterLink';
import SSIDActions from './SSIDActions';
import { SSIDDrawerContent } from './SSIDDrawer';
import { SSIDsQuery } from './SSIDsUtils';

function SSIDDetailsContent({ ssid }: { ssid: SSID }) {
  const network = useNetwork();
  const networkClientOptions = { filter: { ssid: ssid.ssid } };

  const { clients, ...rest } = useFilteredNetworkClients({
    networkClientOptions,
    network,
    useGraphQLOptions: { suspense: false },
  });

  return (
    <Columns template="object">
      <Column>
        <SSIDDrawerContent ssid={ssid} />
      </Column>
      <Column>
        <Section relation="stacked">
          <SectionHeader
            heading="Clients"
            icon="client"
            actions={<ClientsListActions {...rest} />}
          />
          <SectionContent>
            <ClientsList2
              networkClientOptions={networkClientOptions}
              emptyStateHeading="No clients found on this SSID"
            />
          </SectionContent>
        </Section>
      </Column>
    </Columns>
  );
}

export default function SSIDDetails({ uuid }: { uuid: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const wirelessCrumbs = useWirelessCrumbs();
  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork;
  const ssid = checkDefinedOrThrow(
    useMemo(() => ssids?.find((s) => s.UUID === uuid), [ssids, uuid]),
    new ResourceNotFoundError('SSID not found'),
  );
  const navigate = useNavigate();

  const navigateToVLAN = useCallback(
    (newUUID: string) =>
      navigate(
        makeLink(paths.pages.SSIDDetailsPage, {
          companyName,
          networkSlug: network.slug,
          uuid: newUUID,
        }),
      ),
    [navigate, companyName, network.slug],
  );

  const sortedSSIDs = useMemo(
    () => ssids?.sort((a, b) => a.ssid.localeCompare(b.ssid)) ?? [],
    [ssids],
  );

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...wirelessCrumbs,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.SSIDsPage, {
                companyName,
                networkSlug: network.slug,
              }),
              label: 'SSIDs',
            },
          },
        ]}
        switcher={
          <ComboBox
            size="small"
            maxWidth="100%"
            icon="vlan"
            value={ssid.UUID}
            onValueChange={navigateToVLAN}
          >
            {sortedSSIDs.map((s) => (
              <ComboBoxItem key={s.UUID} textValue={s.ssid}>
                {s.ssid}
              </ComboBoxItem>
            ))}
          </ComboBox>
        }
        icon="vlan"
        heading={ssid.ssid}
        badges={
          <Badge
            variant={ssid.isEnabled ? 'positive' : 'negative'}
            ends="pill"
            arrangement="leading-icon"
          >
            {ssid.isEnabled ? 'Enabled' : 'Disabled'}
          </Badge>
        }
        actions={<SSIDActions uuid={uuid} view="detail" />}
      />
      <PaneContent>
        <Suspense fallback={<PaneContentSkeletons />}>
          <SSIDDetailsContent ssid={ssid} />
        </Suspense>
      </PaneContent>
    </Pane>
  );
}

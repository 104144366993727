import {
  colors,
  darkThemeSelector,
  Icon,
  LayoutWorkbenchBottom,
  LayoutWorkbenchEnd,
  shadows,
  Small,
  styled,
} from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { useResizable } from 'react-resizable-layout';

import type { WorkbenchSideProp } from './utils/useWorkbenchSide';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useNetworkOrNull } from '../../../hooks/useNetworkFromPath';
import Inspector from './Inspector/Inspector';
import Network from './Network/Network';
import Notes from './Notes/Notes';
import WorkbenchToolbar, { ValidWorkbenchToolbarTargets } from './Toolbar/Toolbar';
import { WorkbenchSideProvider } from './utils/useWorkbenchSide';
import WorkbenchControls from './WorkbenchControls';

const WorkbenchContentStart = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const WorkbenchContentEndResizerIcon = styled(Icon, {
  width: '$16',
  height: '$16',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  variants: {
    side: {
      bottom: {
        marginRight: '-$2',
        marginLeft: '-$2',
      },
      end: {
        marginTop: '-$2',
        marginBottom: '-$2',
      },
    },
  },
});

const WorkbenchContentEndResizer = styled('div', {
  position: 'absolute',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeApplicationDark,
  },

  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    side: {
      bottom: {
        top: 0,
        left: 0,
        bottom: 0,
        strokeLeftRight: colors.strokeApplicationLight,
        cursor: 'col-resize',

        [darkThemeSelector]: {
          strokeLeftRight: colors.strokeApplicationDark,
        },
      },
      end: {
        top: 0,
        right: 0,
        left: 0,
        strokeTopBottom: colors.strokeApplicationLight,
        cursor: 'row-resize',

        [darkThemeSelector]: {
          strokeTopBottom: colors.strokeApplicationDark,
        },
      },
    },
  },
});

const WorkBenchContentEndPane = styled('div', {
  position: 'relative',

  variants: {
    side: {
      bottom: {
        height: '100%',
      },
      end: {
        width: '100%',
      },
    },
  },
});

const WorkbenchContentEnd = styled('div', {
  position: 'relative',

  variants: {
    side: {
      bottom: {
        height: '100%',
        paddingLeft: '$12',
      },
      end: {
        width: '100%',
        paddingTop: '$12',
      },
    },
  },
});

const WorkbenchContent = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  variants: {
    side: {
      bottom: {
        flexDirection: 'row',
      },
      end: {
        flexDirection: 'column',
      },
    },
  },
});

const WorkbenchNoOp = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  background: colors.bgNeutralLight,
  borderRadius: '$10 0 0 $10',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
  },
});

const WorkbenchContainer = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  background: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },

  variants: {
    side: {
      bottom: {
        flexDirection: 'row',
      },
      end: {
        flexDirection: 'column',
      },
    },
  },
});

const WorkbenchArea = styled('div', {
  height: '100%',
  backgroundColor: colors.internalBgLight,

  [darkThemeSelector]: {
    backgroundColor: colors.internalBgDark,
  },

  variants: {
    isOpen: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
    side: {
      bottom: {
        paddingTop: '$12',
        strokeTop: colors.internalStrokeLight,

        [darkThemeSelector]: {
          strokeTop: colors.internalStrokeDark,
        },
      },
      end: {
        paddingLeft: '$12',
        strokeLeft: colors.internalStrokeLight,

        [darkThemeSelector]: {
          strokeLeft: colors.internalStrokeDark,
        },
      },
    },
  },
});

const WorkbenchResizerIcon = styled(Icon, {
  width: '$16',
  height: '$16',
  color: colors.internalIconLight,

  [darkThemeSelector]: {
    color: colors.internalIconDark,
  },

  variants: {
    side: {
      bottom: {
        marginTop: '-$2',
        marginBottom: '-$2',
      },
      end: {
        marginRight: '-$2',
        marginLeft: '-$2',
      },
    },
  },
});

const WorkbenchResizer = styled('div', {
  position: 'absolute',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'row-resize',

  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    side: {
      bottom: {
        top: 0,
        right: 0,
        left: 0,
        cursor: 'row-resize',
      },
      end: {
        top: 0,
        left: 0,
        bottom: 0,
        cursor: 'col-resize',
      },
    },
  },
});

const WorkbenchWrapper = styled('div', {
  position: 'relative',
  zIndex: 1000,
  height: '100%',
});

export default function Workbench() {
  const network = useNetworkOrNull();

  const [showWorkbench, setShowWorkbench] = useLocalStorage('dashboard.workbench', false);
  const [workbenchSide, setWorkbenchSide] = useLocalStorage(
    'dashboard.workbench.side',
    'end' as WorkbenchSideProp,
  );

  const [workbenchBottomSize, setWorkbenchBottomSize] = useLocalStorage(
    'dashboard.workbench.bottom',
    480,
  );
  const [workbenchEndSize, setWorkbenchEndSize] = useLocalStorage('dashboard.workbench.end', 360);

  const { position: positionBottom, separatorProps: separatorPropsBottom } = useResizable({
    axis: 'y',
    initial: workbenchBottomSize,
    min: 200,
    max: Infinity,
    reverse: true,
    onResizeEnd: ({ position: newPosition }) => setWorkbenchBottomSize(newPosition),
  });

  const { position: positionEnd, separatorProps: separatorPropsEnd } = useResizable({
    axis: 'x',
    initial: workbenchEndSize,
    min: 360,
    max: Infinity,
    reverse: true,
    onResizeEnd: ({ position: newPosition }) => setWorkbenchEndSize(newPosition),
  });

  const [workbenchContentBottomSize, setWorkbenchContentBottomSize] = useLocalStorage(
    'dashboard.workbench.content.bottom',
    360,
  );
  const [workbenchContentEndSize, setWorkbenchContentEndSize] = useLocalStorage(
    'dashboard.workbench.content.end',
    360,
  );

  const { position: positionContentBottom, separatorProps: separatorPropsContentBottom } =
    useResizable({
      axis: 'x',
      initial: workbenchContentBottomSize,
      min: 360,
      max: Infinity,
      reverse: true,
      onResizeEnd: ({ position: newPosition }) => setWorkbenchContentBottomSize(newPosition),
    });

  const { position: positionContentEnd, separatorProps: separatorPropsContentEnd } = useResizable({
    axis: 'y',
    initial: workbenchContentEndSize,
    min: 200,
    max: Infinity,
    reverse: true,
    onResizeEnd: ({ position: newPosition }) => setWorkbenchContentEndSize(newPosition),
  });

  const [target, setTarget] = useLocalStorage(
    'dashboard.workbench.target',
    ValidWorkbenchToolbarTargets.Inspector,
  );

  const El = workbenchSide === 'bottom' ? LayoutWorkbenchBottom : LayoutWorkbenchEnd;

  return (
    <WorkbenchSideProvider value={workbenchSide}>
      <El>
        <WorkbenchWrapper>
          <WorkbenchControls onClick={() => setShowWorkbench(!showWorkbench)} />
          <WorkbenchArea
            isOpen={showWorkbench}
            side={workbenchSide}
            style={workbenchSide === 'bottom' ? { height: positionBottom } : { width: positionEnd }}
          >
            <WorkbenchResizer
              tabIndex={0}
              side={workbenchSide}
              {...(workbenchSide === 'bottom' ? separatorPropsBottom : separatorPropsEnd)}
            >
              <WorkbenchResizerIcon
                side={workbenchSide}
                icon={workbenchSide === 'bottom' ? 'overflow-horizontal' : 'overflow-vertical'}
              />
            </WorkbenchResizer>
            <WorkbenchContainer side={workbenchSide}>
              <WorkbenchToolbar
                target={target}
                setTarget={setTarget}
                sideToggle={() => setWorkbenchSide(workbenchSide === 'bottom' ? 'end' : 'bottom')}
              />
              {!network && (
                <WorkbenchNoOp>
                  <Small weight="bold">No configurable options for this area of Dashboard</Small>
                </WorkbenchNoOp>
              )}
              {network && (
                <WorkbenchContent side={workbenchSide}>
                  <WorkbenchContentStart>
                    {target === ValidWorkbenchToolbarTargets.Network && <Network />}
                    {target === ValidWorkbenchToolbarTargets.Inspector && <Inspector />}
                  </WorkbenchContentStart>
                  <WorkbenchContentEnd side={workbenchSide}>
                    <WorkbenchContentEndResizer
                      tabIndex={0}
                      side={workbenchSide}
                      {...(workbenchSide === 'bottom'
                        ? separatorPropsContentBottom
                        : separatorPropsContentEnd)}
                    >
                      <WorkbenchContentEndResizerIcon
                        side={workbenchSide}
                        icon={
                          workbenchSide === 'bottom' ? 'overflow-vertical' : 'overflow-horizontal'
                        }
                      />
                    </WorkbenchContentEndResizer>
                    <WorkBenchContentEndPane
                      side={workbenchSide}
                      style={
                        workbenchSide === 'bottom'
                          ? { width: positionContentBottom }
                          : { height: positionContentEnd }
                      }
                    >
                      <Notes />
                    </WorkBenchContentEndPane>
                  </WorkbenchContentEnd>
                </WorkbenchContent>
              )}
            </WorkbenchContainer>
          </WorkbenchArea>
        </WorkbenchWrapper>
      </El>
    </WorkbenchSideProvider>
  );
}

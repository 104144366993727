import React from 'react';

import { Icon } from '@meterup/atto';
import { ClearIndicatorProps, components } from 'react-select';

export function ClearIndicator<T>(props: ClearIndicatorProps<T, false>) {
  return (
    <components.ClearIndicator {...props}>
      <Icon icon="cross" size={12} />
    </components.ClearIndicator>
  );
}

/* eslint-disable react/no-unstable-nested-components */
import { Badge, EmptyState, HStack, space } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { useCallback, useMemo } from 'react';

import type { BulkNosUpgrade } from './hooks/useBulkNOSUpgrades';
import { paths } from '../../constants';
import { Nav } from '../../nav';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { NoValue } from '../NoValue';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { useBulkNOSUpgrades } from './hooks/useBulkNOSUpgrades';
import { useNosFirmwareVersions } from './hooks/useNosFirmwareVersions';
import { NosVersionCell, TimestampCell } from './NetworksPreviewList';

const builder = createColumnBuilder<BulkNosUpgrade>();

export default function BulkUpgradeList() {
  const upgrades = useBulkNOSUpgrades({ limit: 100, offset: 0 });
  const { nosVersionIdMap } = useNosFirmwareVersions();
  const columns = useMemo(
    () => [
      builder.data((row) => row.networks.map((network) => network.label).join(', '), {
        id: 'networks',
        header: 'Networks scheduled for upgrade',
        meta: {
          isLeading: true,
        },
        cell: ({ row }) => {
          if (row.networks.length) {
            return (
              <HStack spacing={space(4)}>
                <Badge icon="ip-address" arrangement="leading-icon" size="small" variant="neutral">
                  Networks: {row.networks.length}
                </Badge>
              </HStack>
            );
          }
          return <NoValue />;
        },
      }),
      builder.data((row) => nosVersionIdMap[row.nosVersionID]?.version || '', {
        id: 'nos-version',
        header: 'Target NOS version',
        cell: ({ row }) => (
          <NosVersionCell value={row.nosVersionID} nosVersionIdMap={nosVersionIdMap} />
        ),
      }),
      builder.data((row) => row.updatedBy, {
        id: 'updated-by',
        header: 'Updated by (user)',
      }),
      builder.data((row) => row.createdAt, {
        id: 'updated-at',
        header: 'Updated',
        cell: TimestampCell,
      }),
      builder.data((row) => row.createdAt, {
        id: 'created-at',
        header: 'Created',
        cell: TimestampCell,
      }),
    ],
    [nosVersionIdMap],
  );
  const linkTo = useCallback(
    (row: BulkNosUpgrade) =>
      makeDrawerLink(window.location, paths.drawers.FirmwareBulkUpgradeDetailsPage, {
        uuid: `${row.UUID}`,
      }),
    [],
  );
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.FirmwareBulkUpgradeDetailsPage);
  return upgrades?.length ? (
    <AutoTable
      columns={columns}
      data={upgrades}
      getLinkTo={linkTo}
      isRowSelected={({ UUID }) => drawerParams?.uuid === UUID}
    />
  ) : (
    <EmptyState icon="upgrading" heading="You have no bulk upgrades" />
  );
}

import {
  Alert,
  Badge,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  PrimaryField,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  TextInput,
  VStack,
} from '@meterup/atto';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import { getRuleCategories, getRulesForCompany } from '../../../../../api/api';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/dns-security/inspect-domain',
  title: 'Inspect domain - DNS security - Firewall',
});

function RuleSummaryListRow({
  domainToCheck,
  categories,
}: {
  domainToCheck: string;
  categories: api.ContentFilterRuleCategory[] | undefined;
}) {
  const company = useCurrentCompany();
  const outcome: { action: api.ContentFilterAction; text: string } = {
    action: api.ContentFilterAction.CONTENTFILTER_ACTION_UNKNOWN,
    text: '',
  };

  const rules =
    useQuery(['dns_security', company, 'rules'], async () => getRulesForCompany(company), {
      suspense: true,
    }).data ?? [];

  if (categories === undefined) return null;
  const categoryIds = categories.map((c) => c.id);

  if (rules.length === 0) return null;

  for (let i = 0; i < rules.length; i += 1) {
    const rule = rules[i];

    if (rule.domains && rule.domains.length > 0 && rule.domains.includes(domainToCheck)) {
      outcome.action = rule.action;
      outcome.text = domainToCheck;
      break;
    }

    if (rule.category && categoryIds.includes(rule.category.id)) {
      outcome.action = rule.action;
      outcome.text = rule.category.name;
      break;
    }
  }

  if (outcome.action === api.ContentFilterAction.CONTENTFILTER_ACTION_ALLOW) {
    return (
      <SummaryListRow>
        <SummaryListKey>Allowed by</SummaryListKey>
        <SummaryListValue>
          <Badge variant="positive" size="small">
            {outcome.text}
          </Badge>
        </SummaryListValue>
      </SummaryListRow>
    );
  }

  if (outcome.action === api.ContentFilterAction.CONTENTFILTER_ACTION_BLOCK) {
    return (
      <SummaryListRow>
        <SummaryListKey>Blocked by</SummaryListKey>
        <SummaryListValue>
          <Badge variant="negative" size="small">
            {outcome.text}
          </Badge>
        </SummaryListValue>
      </SummaryListRow>
    );
  }

  return null;
}

export default function LegacyDNSSecurityDomainInspectorPage() {
  const company = useCurrentCompany();
  const [errorMessage, setErrorMessage] = useState('');
  const [domainToCheck, setDomainToCheck] = useState('');
  const [categories, setCategories] = useState<api.ContentFilterRuleCategory[]>();

  const { refetch, isFetching } = useQuery(
    ['content_filter_rule_category', domainToCheck],
    () => getRuleCategories(company, domainToCheck),
    {
      enabled: false,
      onSuccess: (data) => {
        setCategories(data);
      },
    },
  );

  const reset = () => {
    setDomainToCheck('');
    setCategories(undefined);
  };

  const checkCategory = () => {
    if (domainToCheck === '') {
      setErrorMessage('Please enter a valid domain name');
      return;
    }

    refetch();
  };

  const handleInputChange = (val: string) => {
    setCategories(undefined);
    setErrorMessage('');
    setDomainToCheck(val);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') refetch();
  };

  return (
    <Drawer>
      <DrawerHeader
        icon="search-scoped"
        heading="Inspect domain"
        onClose={useCloseDrawerCallback()}
      />
      <DrawerContent>
        <PrimaryField
          label="Domain"
          errorMessage={errorMessage}
          description="Look up the rule categorization for a domain."
          element={
            <TextInput
              invalid={errorMessage !== ''}
              value={domainToCheck}
              onKeyUp={handleKeyUp}
              onChange={handleInputChange}
              autoFocus
              disabled={false}
            />
          }
        />

        {categories && (
          <Section relation="standalone">
            <SectionHeader icon="information" heading="Details" />
            <SectionContent gutter="all">
              <SummaryList gutter="none">
                <SummaryListRow>
                  <SummaryListKey>Categorization</SummaryListKey>
                  <SummaryListValue>
                    <VStack spacing={space(6)}>
                      {categories.length > 0 && (
                        <VStack spacing={space(6)}>
                          {categories.map((c) => (
                            <Badge size="small" key={c.id}>
                              {c.name}
                            </Badge>
                          ))}
                        </VStack>
                      )}
                    </VStack>
                  </SummaryListValue>
                </SummaryListRow>
                <RuleSummaryListRow categories={categories} domainToCheck={domainToCheck} />
                <SummaryListRow>
                  <SummaryListValue>
                    <VStack spacing={space(8)}>
                      <Alert
                        type="inline"
                        variant="neutral"
                        relation="standalone"
                        copy="Cloudflare's categorization of domains changes frequently; check the link below if domains are being blocked or allowed unexpectedly."
                      />
                      <Button
                        as="a"
                        href={`https://radar.cloudflare.com/domains/feedback/${domainToCheck}`}
                        target="_blank"
                        variant="secondary"
                        icon="chevron-right"
                        width="100%"
                      >
                        Check categorization on Cloudflare
                      </Button>
                    </VStack>
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
        )}
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button type="button" variant="secondary" onClick={() => reset()} disabled={isFetching}>
              Reset
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => checkCategory()}
              loading={isFetching}
            >
              Check category
            </Button>
          </>
        }
      />
    </Drawer>
  );
}

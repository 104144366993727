import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';

import { LegacySwitchSummaryDrawer } from '../../../../../components/Hardware/Switches/LegacySwitchSummary';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/switches/:id',
});

export default function LegacySwitchSummaryPage() {
  const { id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacySwitchSummaryPage),
  );

  return <LegacySwitchSummaryDrawer macAddress={id} />;
}

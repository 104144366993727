import {
  Alert,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  HStack,
  ManufacturerIcon,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  Small,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Table,
  TableBody,
  TableCell,
  TableCellBuffer,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  Text,
  VStack,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense, useMemo } from 'react';

import type { SecurityAppliancePhyInterface } from '../utils2';
import { ClientAssignmentProtocol } from '../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { CopyableMonoOrNoValue } from '../../../Devices/Insights';
import { NoValue } from '../../../NoValue';
import { StickyObjectHeader } from '../../../Object/ObjectHeader';
import { DrawerContentLoadingFallback } from '../../../Placeholders/DrawerLoadingFallback';
import {
  MACTableDeviceDetailsFallback,
  SingleDeviceDetails,
} from '../../Switches/SwitchPortDrawer';
import {
  isPortConnectedToMeterDevice,
  isPortSlow,
  portMaxSpeed,
  portSpeedToLabel,
  portSpeedVariant,
} from '../../Switches/utils';
import { phyInterfacesForSecurityApplianceQuery, portLifecycleStatus, portStatus } from '../utils2';
import { PortActions } from './PortActions';

interface PortSummaryProps {
  phyInterface: SecurityAppliancePhyInterface;
}

function PortSummary({ phyInterface }: PortSummaryProps) {
  const devices = useMemo(
    () => phyInterface.connectedDevices ?? [],
    [phyInterface.connectedDevices],
  );

  const ipAddresses = phyInterface.uplinkExternalAddresses ?? phyInterface.ipv4ClientAddresses;

  return (
    <DrawerContent gutter="none">
      <StickyObjectHeader
        icon={phyInterface.isUplink ? 'globe' : 'ethernet'}
        name={phyInterface.label ?? `Port ${phyInterface.portNumber}`}
        status={portStatus(phyInterface)}
        lifecycle={portLifecycleStatus(phyInterface)}
      />
      <Sections>
        <Section relation="stacked">
          <SectionHeader heading="Metadata" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <SummaryListRow>
                <SummaryListKey>Port #</SummaryListKey>
                <SummaryListValue>{phyInterface.portNumber}</SummaryListValue>
              </SummaryListRow>
              <SummaryListRow variant={portSpeedVariant(phyInterface)}>
                <SummaryListKey>Speed</SummaryListKey>
                <SummaryListValue>
                  {phyInterface.portSpeedMbps ? (
                    portSpeedToLabel(phyInterface.portSpeedMbps)
                  ) : (
                    <NoValue />
                  )}
                </SummaryListValue>
              </SummaryListRow>
              {isPortConnectedToMeterDevice(phyInterface) && isPortSlow(phyInterface) && (
                <Alert
                  variant="negative"
                  type="inline"
                  icon="attention"
                  copy={`Port's configuration is set to ${portSpeedToLabel(
                    phyInterface.forcedPortSpeedMbps ?? portMaxSpeed(phyInterface),
                  )} but last throughput speed detected was ${
                    phyInterface.portSpeedMbps
                      ? portSpeedToLabel(phyInterface.portSpeedMbps)
                      : 'unknown'
                  }`}
                />
              )}
              {phyInterface.isUplink && (
                <>
                  {phyInterface.ipv4ClientAssignmentProtocol ===
                    ClientAssignmentProtocol.Static && (
                    <>
                      <SummaryListRow>
                        <SummaryListKey>Gateway IP</SummaryListKey>
                        <SummaryListValue>
                          <CopyableMonoOrNoValue
                            label="Gateway IP"
                            value={phyInterface.ipv4ClientGateway}
                          />
                        </SummaryListValue>
                      </SummaryListRow>
                      <SummaryListRow>
                        <SummaryListKey>Prefix length</SummaryListKey>
                        <SummaryListValue>
                          <CopyableMonoOrNoValue
                            label="Prefix length"
                            value={phyInterface.ipv4ClientPrefixLength}
                          />
                        </SummaryListValue>
                      </SummaryListRow>
                    </>
                  )}
                  <SummaryListRow>
                    <SummaryListKey>
                      IP {ipAddresses && ipAddresses.length > 1 ? 'addresses' : 'address'}
                    </SummaryListKey>
                    <SummaryListValue>
                      {ipAddresses?.length ? (
                        <VStack spacing={space(8)}>
                          {ipAddresses.map((addr) => (
                            <CopyBox aria-label="Copy IP address" value={addr}>
                              <Text family="monospace">{addr}</Text>
                            </CopyBox>
                          ))}
                        </VStack>
                      ) : (
                        <NoValue />
                      )}
                    </SummaryListValue>
                  </SummaryListRow>
                </>
              )}
            </SummaryList>
          </SectionContent>
        </Section>
        <Section relation="stacked">
          <SectionHeader heading="Connection" />
          <SectionContent gutter="all">
            {devices.length > 0 ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {devices.length === 1 ? (
                  <SingleDeviceDetails
                    deviceType={devices[0].hardwareDevice?.virtualDevice?.deviceType}
                    uuid={devices[0].hardwareDevice?.virtualDevice?.UUID}
                    clientName={devices[0].hardwareDevice?.virtualDevice?.label}
                    macAddress={devices[0].macAddress}
                    serialNumber={devices[0].hardwareDevice?.serialNumber}
                    isOnline={devices[0].hardwareDevice?.isConnectedToBackend}
                  />
                ) : (
                  <Table>
                    <TableHead>
                      <TableHeadRow>
                        <TableCellBuffer side="leading" head />
                        <TableHeadCell>Device</TableHeadCell>
                        <TableCellBuffer side="trailing" head />
                      </TableHeadRow>
                    </TableHead>
                    <TableBody>
                      {devices.map((device) => (
                        <TableRow>
                          <TableCellBuffer side="leading" />
                          <TableCell>
                            <HStack spacing={space(8)}>
                              <ManufacturerIcon size={16} icon="unknown" />
                              <Small family="monospace">{device.macAddress}</Small>
                            </HStack>
                          </TableCell>
                          <TableCellBuffer side="trailing" />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </>
            ) : (
              <MACTableDeviceDetailsFallback port={phyInterface} />
            )}
          </SectionContent>
        </Section>
      </Sections>
    </DrawerContent>
  );
}

interface PortDrawerProps {
  phyInterfaceUUID: string;
  virtualDeviceUUID: string;
}

export default function PortDrawer(props: PortDrawerProps) {
  const closeDrawer = useCloseDrawerCallback();

  const phyInterfaces = useGraphQL(phyInterfacesForSecurityApplianceQuery, {
    virtualDeviceUUID: props.virtualDeviceUUID,
  }).data?.phyInterfacesForVirtualDevice;
  expectDefinedOrThrow(phyInterfaces, new ResourceNotFoundError('Ports not found for device.'));

  const phyInterface = phyInterfaces.find((p) => p.UUID === props.phyInterfaceUUID);
  expectDefinedOrThrow(phyInterface, new ResourceNotFoundError('Port not found.'));

  return (
    <Drawer>
      <DrawerHeader
        onClose={closeDrawer}
        icon="ethernet"
        heading={phyInterface.label ?? `Port ${phyInterface.portNumber}`}
        actions={
          <PortActions
            virtualDeviceUUID={props.virtualDeviceUUID}
            phyInterfaceUUID={props.phyInterfaceUUID}
            view="drawer"
          />
        }
      />
      <Suspense fallback={<DrawerContentLoadingFallback />}>
        <PortSummary phyInterface={phyInterface} />
      </Suspense>
    </Drawer>
  );
}

import {
  Column,
  Columns,
  PaneContent,
  Section,
  SectionContent,
  SectionHeader,
} from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import {
  VLANClientsWidget,
  VLANDHCPWidget,
  VLANMetadataListWidget,
} from '../../../../../components/vlans';
import { paths } from '../../../../../constants';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName',
});

export default function VLANDetailPage() {
  const controllerName = useCurrentController();
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANDetailPage),
  )!;

  const vlan = useVLANConfigModel(controllerName, vlanName);

  return (
    <PaneContent>
      <Columns scroll="none" template="wide-narrow">
        <Column gutter="vertical">
          <VLANClientsWidget relation="stacked" vlan={vlan} />
        </Column>
        <Column gutter="vertical">
          <Section relation="stacked">
            <SectionHeader icon="information" heading="Metadata" />
            <SectionContent gutter="all">
              <VLANMetadataListWidget vlan={vlan} />
            </SectionContent>
          </Section>
          <VLANDHCPWidget relation="stacked" vlan={vlan} />
        </Column>
      </Columns>
    </PaneContent>
  );
}

import { Body, Button, Section, SectionContent, SectionHeader } from '@meterup/atto';
import { AutoTable, presignedDownloadUrlQuery } from '@meterup/common';
import { graphqlClient } from '@meterup/graphql';

import { createColumnBuilder } from '../../../../components/Table/createColumnBuilder';

async function fetchAndDownloadUrl(s3Key: string) {
  const res = await graphqlClient.request(presignedDownloadUrlQuery, {
    s3Key,
  });
  if (res?.downloadUrl.presignedUrl) {
    window.open(res?.downloadUrl.presignedUrl, '_blank');
  }
}

type ComplainceDocument = {
  name: string;
  s3Key: string;
  buttonLabel: string;
};

const complainceDocsData: ComplainceDocument[] = [
  {
    name: 'SOC 2 Type II',
    s3Key: 'files/compliance_docs/2023_Meter_SOC_2_Type_2_Report.pdf',
    buttonLabel: 'Download report',
  },
  {
    name: 'Penetration Test Report',
    s3Key: 'files/compliance_docs/2023 Meter Penetration Test Report.pdf',
    buttonLabel: 'Download report',
  },
];

const builder = createColumnBuilder<ComplainceDocument>();
const complainceDocsColumns = [
  builder.data((s) => s.name, {
    id: 'name',
    header: 'Name',
    cell: ({ row }) => <Body>{row.name}</Body>,
  }),
  builder.display({
    id: 'reportDownloadButton',
    meta: {
      alignment: 'end',
    },
    cell: ({ row }) => (
      <Button
        size="small"
        icon="download"
        variant="secondary"
        onClick={() => fetchAndDownloadUrl(row.s3Key)}
      >
        {row.buttonLabel}
      </Button>
    ),
  }),
];

export default function ComplianceDocs() {
  return (
    <Section relation="stacked">
      <SectionHeader
        heading="Compliance documents"
        description="Access to Meter’s compliance documents is governed by the terms of your MSA. Please keep this confidential."
      />
      <SectionContent gutter="none">
        <AutoTable data={complainceDocsData} columns={complainceDocsColumns} />
      </SectionContent>
    </Section>
  );
}

import { darkThemeSelector, styled } from '@meterup/atto';
import { colors } from '@meterup/common';
import QRCodeSVG from 'pretty-qrcode';
import React from 'react';

const QRCodeContainer = styled('div', {
  width: 120,
  padding: '$8',
  backgroundColor: colors.bgApplicationLight,
  borderRadius: '16px',

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
  },
});

export function QRCode({ text }: { text: string }) {
  return (
    <QRCodeContainer>
      <QRCodeSVG value={text} foregroundColor={colors.brand500.toString()} />
    </QRCodeContainer>
  );
}

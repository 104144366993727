import { PrimaryField, Select, SelectItem } from '@meterup/atto';
import { useContext } from 'react';

import { ThemeContext } from '../../../../providers/ThemeProvider';

export function ThemeSelect() {
  const { theme, setTheme } = useContext(ThemeContext);
  return (
    <PrimaryField
      element={
        <Select value={theme} onValueChange={setTheme as (theme: string) => void}>
          <SelectItem key="light">Light</SelectItem>
          <SelectItem key="dark">Dark</SelectItem>
          <SelectItem key="system">Match system OS</SelectItem>
        </Select>
      }
      label="Theme"
    />
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import { Dialog, DialogContent, DialogHeader, styled } from '@meterup/atto';

const NoteAttachmentSizer = styled('div', {
  display: 'block',
  width: '100%',
  height: '100%',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderRadius: '$4',
});

const NoteAttachmentContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

type NoteAttachmentDialogProps = {
  state: OverlayTriggerState;
  url: string;
};

export function NoteAttachmentDialog({ state, url }: NoteAttachmentDialogProps) {
  return (
    <Dialog state={state} width="90vw" maxWidth="90vw" height="90vh" maxHeight="90vh">
      <DialogHeader icon="document" heading="Note attachment" />
      <DialogContent gutter="all">
        <NoteAttachmentContainer>
          <NoteAttachmentSizer style={{ backgroundImage: `url(${url})` }} />
        </NoteAttachmentContainer>
      </DialogContent>
    </Dialog>
  );
}

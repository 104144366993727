import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  HStack,
  Icon,
  MultiComboBox,
  MultiComboBoxItem,
  Small,
  space,
  TextInput,
} from '@meterup/atto';
import { DateTime } from 'luxon';

import { StatType } from '../../../../../gql/graphql';
import { pluralize } from '../../../../../utils/strings';
import { stripDateTimeForInput } from '../../../../../utils/time';
import { deviceTypeToHardwareIconPropHardware } from '../../../../../utils/types';
import { FilterKey, timePeriodLabel, useNetworkStatsFilter } from './hooks/useNetworkStats';
import { useVirtualDevices } from './hooks/useVirtualDevices';

const statTypeKeys = Object.values(StatType);
const statTypeItems = statTypeKeys.map((elem) => ({
  label: elem,
  value: elem,
}));

export default function StatsFilters() {
  const { virtualDevices: devices } = useVirtualDevices();
  const { filter, onFilterChange, query } = useNetworkStatsFilter();

  return (
    <>
      <div>
        <MultiComboBox
          defaultValue={[]}
          value={filter.virtualDeviceUUIDs}
          onValueChange={(keys) => {
            onFilterChange(FilterKey.virtualDevices, keys);
          }}
          placeholder="Select a device"
          aria-label="Filter by network device"
          renderSelected={(selectedItems) => (
            <HStack align="center" spacing={space(6)}>
              <Icon
                icon="virtual-device"
                color={{ dark: 'gray200', light: 'gray600' }}
                size={space(12)}
              />
              <Small>
                {selectedItems.length} {pluralize(selectedItems.length, 'device')}
              </Small>
              <Icon
                icon="chevron-down"
                color={{ dark: 'gray200', light: 'gray600' }}
                size={space(8)}
              />
            </HStack>
          )}
          selectionMode="multiple"
        >
          {devices.map((device) => (
            <MultiComboBoxItem key={device.UUID} textValue={device.label}>
              <HStack align="center" spacing={space(4)}>
                <Icon icon={deviceTypeToHardwareIconPropHardware(device.deviceType)} size={12} />
                <Small>{device.label || device.UUID}</Small>
              </HStack>
            </MultiComboBoxItem>
          ))}
        </MultiComboBox>
      </div>
      <div>
        <MultiComboBox
          defaultValue={[]}
          value={filter.types}
          onValueChange={(val) => {
            onFilterChange(FilterKey.statTypes, val);
          }}
          placeholder="Select stat type(s)"
          aria-label="Filter by stat type(s)"
          enableSelectAll
          renderSelected={(selectedItems) => (
            <HStack align="center" spacing={space(4)}>
              <Icon icon="log" size={12} />
              <Small>
                {selectedItems.length} {pluralize(selectedItems.length, 'stat')}
              </Small>
              <Icon
                icon="chevron-down"
                color={{ dark: 'gray200', light: 'gray600' }}
                size={space(8)}
              />
            </HStack>
          )}
          size="small"
          selectionMode="multiple"
        >
          {statTypeItems.map(({ value, label }) => (
            <MultiComboBoxItem key={value} textValue={value}>
              {label}
            </MultiComboBoxItem>
          ))}
        </MultiComboBox>
      </div>
      <DropdownMenu>
        <DropdownMenuButton variant="secondary" arrangement="leading-icon" icon="clock">
          {query.durationSeconds === 'CUSTOM' ? 'Custom' : timePeriodLabel(query.durationSeconds)}
        </DropdownMenuButton>
        <DropdownMenuPopover sideOffset={8} side="bottom" align="end">
          <DropdownMenuRadioGroup
            value={query.durationSeconds}
            onValueChange={(val: string) => onFilterChange(FilterKey.duration, val)}
          >
            <DropdownMenuRadioItem value="600">{timePeriodLabel('600')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="1800">{timePeriodLabel('1800')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="3600">{timePeriodLabel('3600')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="7200">{timePeriodLabel('7200')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="10800">{timePeriodLabel('10800')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="21600">{timePeriodLabel('21600')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="43200">{timePeriodLabel('43200')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="86400">{timePeriodLabel('86400')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="CUSTOM">Custom date range</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      {query.durationSeconds === 'CUSTOM' && (
        <>
          <TextInput
            icon="calendar"
            type="datetime-local"
            value={stripDateTimeForInput(DateTime.fromISO(query.startDate))}
            onChange={(value) => {
              onFilterChange(FilterKey.startDate, DateTime.fromISO(value).toISO());
            }}
            inputProps={{
              max: query.endDate,
            }}
            aria-label="Start Date"
          />
          <Icon color={{ light: 'gray500', dark: 'gray300' }} icon="arrow-right" size={space(12)} />
          <TextInput
            icon="calendar"
            type="datetime-local"
            inputProps={{
              min: query.startDate,
              max: DateTime.now().endOf('day').set({ second: 0, millisecond: 0 }).toISO({
                includeOffset: false,
                suppressSeconds: true,
                suppressMilliseconds: true,
              }),
            }}
            value={stripDateTimeForInput(DateTime.fromISO(query.endDate))}
            onChange={(value) => {
              onFilterChange(FilterKey.endDate, DateTime.fromISO(value).toISO());
            }}
            aria-label="End Date"
          />
        </>
      )}
    </>
  );
}

import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { timePeriodLabel } from '../../../utils/chart_utils';
import { FilterBar } from '../../Insights/FilterBar';
import { MetricBandFilter } from '../../Metrics/utils';
import { SSIDsQuery } from '../../Wireless/SSIDs/SSIDsUtils';
import { AccessPointQuery } from '../../Wireless/utils';

export default function AccessPointFilters({ uuid }: { uuid: string }) {
  const network = useNetwork();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const accessPointData = useGraphQL(AccessPointQuery, {
    uuid,
    includeUptime,
  }).data;
  expectDefinedOrThrow(accessPointData, new ResourceNotFoundError('Access point not found'));
  expectDefinedOrThrow(
    accessPointData.virtualDevice,
    new ResourceNotFoundError('Virtual device not found'),
  );

  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const [currentBandOrUndefined, setCurrentBand] = useSearchParamsState<MetricBandFilter>(
    'band',
    MetricBandFilter.All,
  );
  const currentBand = currentBandOrUndefined ?? MetricBandFilter.All;

  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork ?? [];

  const [currentSSIDOrUndefined, setCurrentSSID] = useSearchParamsState<{
    UUID: string;
    ssid: string;
  }>('ssid', { UUID: 'All', ssid: 'All' });
  const currentSSID = currentSSIDOrUndefined ?? { UUID: 'All', ssid: 'All' };

  const findSSIDByUUID = (u: string) =>
    ssids.find((s) => s.UUID === u) ?? { UUID: 'All', ssid: 'All' };

  return (
    <FilterBar>
      <DropdownMenu>
        <DropdownMenuButton variant="secondary" arrangement="leading-icon" size="small">
          SSID: {currentSSID.ssid}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentSSID.UUID}
            onValueChange={(val) => setCurrentSSID(findSSIDByUUID(val))}
          >
            {[{ UUID: 'All', ssid: 'All' }, ...ssids].map((s) => (
              <DropdownMenuRadioItem value={s.UUID} key={s.UUID} label={s.ssid} />
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuButton variant="secondary" arrangement="leading-icon" size="small">
          Band: {currentBand}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentBand}
            onValueChange={(val) => setCurrentBand(val as MetricBandFilter)}
          >
            <DropdownMenuRadioItem value={MetricBandFilter.All} label="All" />
            <DropdownMenuRadioItem value={MetricBandFilter.Band2_4} label="2.4 GHz" />
            <DropdownMenuRadioItem value={MetricBandFilter.Band5} label="5 GHz" />
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          arrangement="leading-icon"
          icon="clock"
          size="small"
        >
          {timePeriodLabel(currentTimePeriod)}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentTimePeriod}
            onValueChange={(val) => setCurrentTimePeriod(val)}
          >
            <DropdownMenuRadioItem value="1h" label={timePeriodLabel('1h')} />
            <DropdownMenuRadioItem value="6h" label={timePeriodLabel('6h')} />
            <DropdownMenuRadioItem value="24h" label={timePeriodLabel('24h')} />
            <DropdownMenuRadioItem value="7d" label={timePeriodLabel('7d')} />
            <DropdownMenuRadioItem value="30d" label={timePeriodLabel('30d')} />
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Button variant="secondary" arrangement="hidden-label" icon="arrows-rotate" size="small">
        Refresh
      </Button>
    </FilterBar>
  );
}

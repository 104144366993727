import {
  CompositeField,
  Icon,
  PrimaryFieldComposite,
  Select,
  SelectItem,
  space,
} from '@meterup/atto';
import { DateTime } from 'luxon';
import { useState } from 'react';

import type { AddEditFormProps } from './CRUDList';
import type { Schemas } from './useOnboardingDocument';
import { AddEditForm } from './CRUDList';
import { PrimaryDateInput } from './FormFields';

function defaultDate() {
  const dt = DateTime.now().startOf('day').plus({ weeks: 2 }).startOf('week');
  return dt.toISODate();
}

type DTWindow = Schemas['DATE_TIME_WINDOWS'][number];

const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minutes = [0, 15, 30, 45];

function TimeWindowField({
  label,
  value,
  onChange,
}: {
  label: string;
  value: DTWindow['start_time'] | DTWindow['end_time'];
  onChange: (v: DTWindow['start_time'] | DTWindow['end_time']) => void;
}) {
  return (
    <div style={{ display: 'inline-flex' }}>
      <PrimaryFieldComposite
        label={label}
        fields={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              width: '100%',
            }}
          >
            <CompositeField
              label="Hour"
              element={
                <Select
                  placeholder=""
                  width="60px"
                  value={String(value.hour)}
                  onValueChange={(v) => {
                    onChange({ ...value, hour: Number(v) });
                  }}
                >
                  {hours.map((option) => (
                    <SelectItem key={option}>{option}</SelectItem>
                  ))}
                </Select>
              }
            />
            <Select
              placeholder="a"
              width="60px"
              value={String(value.minute)}
              onValueChange={(v) => {
                onChange({ ...value, minute: Number(v) });
              }}
            >
              {minutes.map((option) => (
                <SelectItem key={option}>{option === 0 ? '00' : option}</SelectItem>
              ))}
            </Select>

            <CompositeField
              label="AM/PM"
              element={
                <Select
                  placeholder="AM"
                  width="60px"
                  value={String(value.meridiem).toUpperCase()}
                  onValueChange={(v) => {
                    if (v === 'PM') {
                      onChange({ ...value, meridiem: 'pm' as const });
                    }
                    if (v === 'AM') {
                      onChange({ ...value, meridiem: 'am' as const });
                    }
                  }}
                >
                  {['AM', 'PM'].map((option) => (
                    <SelectItem key={option}>{option}</SelectItem>
                  ))}
                </Select>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export function DateTimeWindowForm({
  defaultValue,
  onSubmit,
  onCancel,
}: AddEditFormProps<DTWindow>) {
  const [date, setDate] = useState(defaultValue?.date ?? defaultDate());
  const [startTime, setStartTime] = useState(
    defaultValue?.start_time ?? {
      hour: 9,
      minute: 0,
      meridiem: 'am' as const,
    },
  );
  const [endTime, setEndTime] = useState(
    defaultValue?.end_time ?? {
      hour: 5,
      minute: 0,
      meridiem: 'pm' as const,
    },
  );

  const isEditing = !!defaultValue;

  return (
    <AddEditForm
      isEditing={isEditing}
      canSubmit
      entityName="window"
      onSubmit={() => {
        onSubmit({
          date,
          start_time: startTime,
          end_time: endTime,
        });
      }}
      onCancel={onCancel}
    >
      <PrimaryDateInput
        label="Date"
        description="Enter all dates and times in the timezone of the installation location."
        value={date}
        onChange={(v) => setDate(v)}
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <TimeWindowField label="Start time" value={startTime} onChange={(v) => setStartTime(v)} />
        <Icon icon="arrow-right" size={space(16)} color={{ light: 'gray500', dark: 'gray300' }} />
        <TimeWindowField label="End time" value={endTime} onChange={(v) => setEndTime(v)} />
      </div>
    </AddEditForm>
  );
}

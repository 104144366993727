import type { api } from '@meterup/proto';
import { DateTime } from 'luxon';

export function demoEvents(controller: string): api.AdhocControllerEventListResponse {
  const now = DateTime.now();
  // We do this so the events can be static on page refresh, but updated each day.
  const date = DateTime.local(now.year, now.month, now.day, 8, 22, 15);

  return {
    events: [
      {
        controller,
        event_type: 'wan-failover',
        created_at: date.minus({ days: 0, hours: 22, minutes: 7 }).toISO(),
        message: 'Failover to primary - Fail over from wan1 to wan0 due to BETTER SERVICE',
        subtype: 'wanFailoverToPrimary',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b9b',
        properties: { 'failover-to': 'wan0' },
      },
      {
        controller,
        event_type: 'wan-failover',
        created_at: date.minus({ days: 1, hours: 0, minutes: 0 }).toISO(),
        message: 'Fail over from wan0 to wan1 due to HARD DOWN',
        subtype: 'wanFailoverDirectionUnknown',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b9c',
        properties: { 'failover-to': 'wan1' },
      },
      {
        controller,
        event_type: 'High number of clients for AP',
        created_at: date.minus({ days: 1, hours: 6, minutes: 0 }).toISO(),
        message: 'AP hostname: meter-mw08-000012\nNumber of clients: 60',
        subtype: 'highNumberOfClientsForAP',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b9d',
        properties: { count: 60, apName: 'meter-mw08-000012' },
      },
      {
        controller,
        event_type: 'Access points changed status',
        created_at: date.minus({ days: 2, hours: 1, minutes: 22 }).toISO(),
        message: `Access points online - ${date
          .minus({ days: 2, hours: 1, minutes: 22 })
          .toISO()}\nUp APs: meter-mw08-000019, meter-mw08-000007\n2 of 40 are back online, 40 total online`,
        subtype: 'accessPointsOnline',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b9f',
        properties: { accessPointStatus: 'accessPointsOnline' },
      },
      {
        controller,
        event_type: 'Access points changed status',
        created_at: date.minus({ days: 2, hours: 1, minutes: 25 }).toISO(),
        message: `Access points offline - ${date
          .minus({ days: 2, hours: 1, minutes: 25 })
          .toISO()}\nDown APs: meter-mw08-000019, meter-mw08-000007\n2 of 40 just went offline, 2 total offline`,
        subtype: 'accessPointsOffline',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b9e',
        properties: { accessPointStatus: 'accessPointsOffline' },
      },
      {
        controller,
        event_type: 'High number of clients for AP',
        created_at: date.minus({ days: 4, hours: 1, minutes: 17 }).toISO(),
        message: 'AP hostname: meter-mw08-000005\nNumber of clients: 60\n',
        subtype: 'highNumberOfClientsForAP',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b10',
        properties: { count: 60, apName: 'meter-mw08-000005' },
      },
      {
        controller,
        event_type: 'wan-failover',
        created_at: date.minus({ days: 7, hours: 5, minutes: 41 }).toISO(),
        message: 'Failover to primary - Fail over from wan1 to wan0 due to BETTER SERVICE',
        subtype: 'wanFailoverToPrimary',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b11',
        properties: { 'failover-to': 'wan0' },
      },
      {
        controller,
        event_type: 'High number of clients for AP',
        created_at: date.minus({ days: 7, hours: 6, minutes: 17 }).toISO(),
        message: 'AP hostname: meter-mw08-000011\nNumber of clients: 60',
        subtype: 'highNumberOfClientsForAP',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b12',
        properties: { count: 60, apName: 'meter-mw08-000011' },
      },
      {
        controller,
        event_type: 'wan-failover',
        created_at: date.minus({ days: 7, hours: 8, minutes: 38 }).toISO(),
        message: 'Fail over from wan0 to wan1 due to HARD DOWN',
        subtype: 'wanFailoverDirectionUnknown',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b13',
        properties: { 'failover-to': 'wan1' },
      },
      {
        controller,
        event_type: 'Access points changed status',
        created_at: date.minus({ days: 11, hours: 4, minutes: 57 }).toISO(),
        message: `Access points online - ${date
          .minus({ days: 11, hours: 4, minutes: 57 })
          .toISO()}\nUp APs: meter-mw08-000002\n1 of 40 are back online, 40 total online`,
        subtype: 'accessPointsOnline',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b15',
        properties: { accessPointStatus: 'accessPointsOnline' },
      },
      {
        controller,
        event_type: 'Access points changed status',
        created_at: date.minus({ days: 11, hours: 5, minutes: 6 }).toISO(),
        message: `Access points offline - ${date
          .minus({ days: 11, hours: 5, minutes: 6 })
          .toISO()}\nDown APs: meter-mw08-000002\n1 of 40 just went offline, 1 total offline`,
        subtype: 'accessPointsOffline',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b6b9b6b14',
        properties: { accessPointStatus: 'accessPointsOffline' },
      },
      {
        controller,
        event_type: 'Controller changed status',
        created_at: date.minus({ days: 22, hours: 3, minutes: 18 }).toISO(),
        message: 'Controller offline',
        subtype: 'offline',
        sid: 'b3b0c9a0-9c1a-4e0a-8b1a-0b9b7b9b6b16',
        properties: { controllerStatus: 'offline' },
      },
    ],
  };
}

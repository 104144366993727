import React from 'react';

import { Small, Text } from '@meterup/atto';

export type LocationCellProps = {
  address1: string;
  address2: string;
  name?: string;
};

export default function LocationCell({ address1, address2, name }: LocationCellProps) {
  const streetAddress = `${name || address1}${address2 ? `, ${address2}` : ''}`;

  return (
    <Small title={streetAddress}>
      <Text weight="bold">
        {name || address1}
        {address2 && ', '}
      </Text>
      {address2}
    </Small>
  );
}

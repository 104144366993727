import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  EmptyState,
  Icon,
  Text,
  useDialogState,
} from '@meterup/atto';
import { AutoTable, notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { AutoVpnGroupQuery } from '../../gql/graphql';
import type { AutoVPNGroup } from './utils';
import { paths } from '../../constants';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { NoValue } from '../NoValue';
import { ReactRouterLink } from '../ReactRouterLink';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { AutoVPNGroupQuery, AutoVPNGroupsQuery, deleteAutoVPNRouteMutation } from './utils';

type AutoVPNGroupRoute = NonNullable<AutoVpnGroupQuery['autoVPNGroup']['routes']>[number];
type VPNRoute = NonNullable<
  NonNullable<AutoVpnGroupQuery['autoVPNGroup']['members']>[number]['permittedVLANs']
>[number];
type ColumnData = AutoVPNGroupRoute | NonNullable<VPNRoute>;

const builder = createColumnBuilder<ColumnData>();

function RouteRemoveDialog({ groupUUID, route }: { groupUUID: string; route: AutoVPNGroupRoute }) {
  const { state: deleteDialogState } = useDialogState();
  const companySlug = useCurrentCompany();
  const deleteAutoVPNGroup = useGraphQLMutation(deleteAutoVPNRouteMutation);
  const { mutate, isError, error } = deleteAutoVPNGroup;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    mutate(
      { uuid: route.UUID! },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupsQuery, { companySlug }));
          queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupQuery, { uuid: groupUUID }));
          deleteDialogState.close();
          notify('Auto VPN group route deleted successfully.', {
            variant: 'positive',
          });
        },
      },
    );
  }, [mutate, queryClient, deleteDialogState, companySlug, route.UUID, groupUUID]);
  return (
    <>
      <Dialog state={deleteDialogState} preset="narrow">
        <DialogHeader icon="trash-can" heading="Delete Auto VPN route" />
        <DialogContent gutter="all">
          <Alert
            icon="information"
            variant="neutral"
            copy={
              <>
                You're about to remove the route <Text weight="bold">{route.name}</Text> from your
                Auto VPN group.
              </>
            }
          />

          {isError && (
            <Alert
              icon="warning"
              variant="negative"
              copy={`There was an error deleting the Auto VPN group route${getGraphQLErrorMessageOrEmpty(error)}.`}
            />
          )}
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button onClick={deleteDialogState.close} variant="secondary">
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="destructive">
                Delete
              </Button>
            </>
          }
        />
      </Dialog>
      <Button
        type="button"
        variant="secondary"
        arrangement="hidden-label"
        aria-label="Remove Auto VPN route"
        icon="trash-can"
        size="small"
        onClick={deleteDialogState.open}
      >
        Remove
      </Button>
    </>
  );
}

const columns = [
  builder.data((row) => row.name, {
    id: 'route-name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
    cell: ({ row }) => {
      if (row?.name) {
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <Body>{row.name}</Body>
        );
      }
      return <NoValue />;
    },
  }),
  builder.data(
    (row) => {
      if (row.__typename === 'VLAN') {
        return `${row?.ipV4ClientGateway}-${row?.ipV4ClientPrefixLength}`;
      }

      if (row.__typename === 'AutoVPNRoute') {
        return `${row?.dstGateway}-${row?.dstPrefixLength}`;
      }
      return '';
    },
    {
      id: 'route-dstGateway-dstPrefixLength',
      header: 'Destination/prefix',
      cell: ({ row }) => {
        let dest = null;
        if (row.__typename === 'VLAN') {
          dest = `${row?.ipV4ClientGateway}/${row?.ipV4ClientPrefixLength}`;
        }
        if (row.__typename === 'AutoVPNRoute') {
          dest = `${row?.dstGateway}/${row?.dstPrefixLength}`;
        }
        if (dest) {
          return <Body family="monospace">{dest}</Body>;
        }
        return <NoValue />;
      },
    },
  ),
  builder.display({
    id: 'route-remove',
    header: () => <Icon icon="question" />,
    meta: {
      alignment: 'center',
      tooltip: {
        contents:
          'Remove - Only Auto VPN routes can be added/removed here. Subnets of the participating VLANs are also listed.',
      },
      width: 0,
    },
    cell: ({ row }) => {
      if (row.__typename === 'AutoVPNRoute') {
        return <RouteRemoveDialog groupUUID={row.UUID!} route={row} />;
      }
      return <NoValue />;
    },
  }),
];

export default function AutoVPNGroupRoutes({ group }: { group: AutoVPNGroup }) {
  const companyName = useCurrentCompany();

  const vpnRoutes =
    group?.members?.reduce((acc: NonNullable<VPNRoute[]>, curr) => {
      if (curr?.permittedVLANs) {
        return [...acc, ...curr.permittedVLANs];
      }
      return [];
    }, []) ?? [];
  const routes = group?.routes || [];
  const finalRoutes = [...(vpnRoutes as NonNullable<VPNRoute>[]), ...routes];

  if (!finalRoutes.length) {
    return (
      <EmptyState
        icon="connection"
        heading="No Auto VPN group routes"
        action={
          <Button
            as={ReactRouterLink}
            to={makeDrawerLink(window.location, paths.drawers.AutoVPNGroupRoutesAddPage, {
              companyName,
              groupUUID: group.UUID,
            })}
            arrangement="leading-icon"
            icon="plus"
          >
            Add route
          </Button>
        }
      />
    );
  }

  return (
    <AutoTable size="small" key={JSON.stringify(routes)} columns={columns} data={finalRoutes} />
  );
}

import type { MailingAddress } from '@meterup/common/src/gql/graphql';

import type { HubNetworksOverviewQuery } from '../../../gql/graphql';
import type { SandboxNetwork } from '../sandboxData/sandboxNetworkType';

type SingleNetwork = HubNetworksOverviewQuery['networksForCompany'][number];
type VirtualDevice = SingleNetwork['virtualDevices'][number];

function devicesByType(
  allDevices: VirtualDevice[],
  type: 'ACCESS_POINT' | 'CONTROLLER' | 'SWITCH',
) {
  const devices = allDevices.filter((vd) => vd.deviceType === type);

  let online = 0;
  let offline = 0;
  let total = 0;

  devices.forEach((vd) => {
    if (!vd.hardwareDevice) return;
    total += 1;
    if (vd.hardwareDevice.isConnectedToBackend) {
      online += 1;
    } else {
      offline += 1;
    }
  });

  return {
    online,
    offline,
    total,
  };
}

export function mailingAddressToString(mailingAddress: Omit<MailingAddress, 'UUID'>) {
  let line1And2 = mailingAddress.line1;
  if (mailingAddress.line2) {
    line1And2 += ` ${mailingAddress.line2}`;
  }
  line1And2 += ',';

  return [line1And2, `${mailingAddress.city},`, mailingAddress.subdivisionCode]
    .filter(Boolean)
    .join(' ');
}

export function aggregateStatsForNetworks(inputs: {
  locations: HubNetworksOverviewQuery['networksForCompany'] | SandboxNetwork['locations'];
  tagGroups: SandboxNetwork['tagGroups'];
}) {
  const totalDevicesByType = {
    aps: 0,
    controllers: 0,
    switches: 0,
  };

  const jobStages = new Set<string>();

  const networks = inputs.locations;

  const aggregated = networks
    .filter((network) => network.isActive && !network.isTemplate)
    .map((network) => {
      const devices = {
        aps: devicesByType(network.virtualDevices, 'ACCESS_POINT'),
        controllers: devicesByType(network.virtualDevices, 'CONTROLLER'),
        switches: devicesByType(network.virtualDevices, 'SWITCH'),
      } as const;

      let totalDevicesForNetwork = 0;

      (['aps', 'controllers', 'switches'] as const).forEach((type) => {
        const totalForType = devices[type].total;
        totalDevicesByType[type] += totalForType;
        totalDevicesForNetwork += totalForType;
      });

      let hasIssues = false;
      if (devices.aps.offline > 0 || devices.switches.offline > 0) {
        hasIssues = true;
      }
      // this logic is a bit weird, but it accounts for us sending backup controllers
      if (devices.controllers.offline > 0 && devices.controllers.online === 0) {
        hasIssues = true;
      }

      let deployment;
      if ('deployment' in network) {
        deployment = network.deployment;
        jobStages.add(network.deployment.jobStage);
      }

      return {
        uuid: network.UUID,
        slug: network.slug,
        label: network.label,
        mailingAddress: network.mailingAddress
          ? mailingAddressToString(network.mailingAddress)
          : '-',
        mailingAddressCoordinates: {
          latitude: network.mailingAddress?.coordinates?.latitude ?? 0,
          longitude: network.mailingAddress?.coordinates?.longitude ?? 0,
        },
        devices,
        hasIssues,
        hasOnlineController: network.virtualDevices.some(
          (vd) => vd.deviceType === 'CONTROLLER' && vd.hardwareDevice?.isConnectedToBackend,
        ),
        totalDevicesForNetwork,
        deployment,
        isSandbox: !!deployment,
      };
    });

  return {
    networks: aggregated,
    totalDevicesByType,
    jobStages: Array.from(jobStages),
    hasIssues: aggregated.some((n) => n.hasIssues),
    tagGroups: inputs.tagGroups,
  };
}

export type NetworksOverviewData = ReturnType<typeof aggregateStatsForNetworks>;

export type AggregatedNetworkInfo = NetworksOverviewData['networks'][number];

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';
import IPSecTunnelDrawer from './IPSecTunnelDrawer';
import { IPSecTunnelQuery } from './utils';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/ipsec-tunnels/:IPSecUUID',
  title: 'Edit IPSec tunnel - IPSec tunnels - Secure tunnels',
});

export default function EditIPSecTunnelPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditIPSecTunnelPage);

  const tunnel = checkDefinedOrThrow(
    useGraphQL(IPSecTunnelQuery, {
      UUID: drawerParams?.IPSecUUID!,
    })?.data?.ipSecTunnel,
    new ResourceNotFoundError('IPSec tunnel not found'),
  );

  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermIpsecTunnelWrite) &&
            ldFlags['ipsec-tunnels'] &&
            nosFlags[NosFeature.IPSEC_TUNNELS] &&
            nosFlags[NosFeature.COS2],
        )
      }
      should404OnAccessDenied
    >
      <IPSecTunnelDrawer tunnel={tunnel} />
    </IsPermitted>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import LogsPageHeader from './LogsPageHeader';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/insights/logs',
  layout: 'NetworkLayout',
});

export default function ControllerLogsLayout() {
  return <DetailPageLayout header={<LogsPageHeader />} />;
}

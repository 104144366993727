import React, { useCallback, useState } from 'react';

import { Column, ColumnDef } from '@tanstack/react-table';
import { sum, sumBy } from 'lodash-es';

import { log } from '../../Log.utils';
import { get, isFunction } from '../../utils';
import { InnerCellWrapper } from '../components/CellWrapper';

export function portionOfTotal(total: number, weight: number, sumOfWeights: number) {
  return Math.ceil(total * (weight / sumOfWeights));
}

export type Portions = {
  initial?: Record<string, number>;
  minValues?: Record<string, number>;
  total: number;
  weights: Record<string, number>;
};
export type PortionsRet = {
  totalAssigned: number;
  values: Record<string, number>;
};
export type HeightProps =
  | {
      table: {
        height: number;
        paddingBottom: number;
      };
      tableParentDiv: {
        height: number;
      };
    }
  | {
      table: {};
      tableParentDiv: {};
    }
  | {
      table: {};
      tableParentDiv: {
        height: number;
      };
    };

class PortionsResult implements PortionsRet {
  constructor(
    public totalAssigned: number,
    public values: Record<string, number>,
  ) {}
}

export function portions({ initial, minValues, total, weights }: Portions): PortionsRet {
  const keys = Object.keys(weights);
  const values = keys.map((key) => weights[key]);
  const weightSum = sum(values);
  let totalAssigned = 0;
  const allocations = keys.reduce(
    (acc, key, i) => {
      const minValue = get(minValues, key) || 0;
      const initialValue = get(initial, key) || 0;
      const allocated =
        initialValue + Math.ceil(Math.max(minValue, portionOfTotal(total, values[i], weightSum)));
      totalAssigned += allocated;
      return { ...acc, [key]: allocated };
    },
    {} as Record<string, number>,
  );

  return new PortionsResult(totalAssigned, allocations);
}

export function distributeTotal(
  total: number,
  weights: Record<string, number>,
  minValues: Record<string, number>,
) {
  // const keys = Object.keys(weights);
  // const values = keys.map((key) => weights[key]);
  // const weightSum = sum(values);
  // let remainingTotal = total;
  // let allocatedTotal = 0;
  // const firstPass = keys.reduce((acc, key, i) => {
  //   const minValue = minValues[key] || 0;
  //   const allocated = Math.ceil(Math.max(minValue, portionOfTotal(total, values[i], weightSum)));
  //   allocatedTotal += allocated;
  //   remainingTotal -= allocated;
  //   return { ...acc, [key]: allocated };
  // }, {} as Record<string, number>);
  const { totalAssigned, values: initialValues } = portions({
    minValues,
    total,
    weights,
  });
  if (totalAssigned < total) {
    log(
      'totalAssigned < total',
      'totAss',
      totalAssigned,
      'tot',
      total,
      'initialValues',
      initialValues,
      'weights',
      weights,
      'minValues',
      minValues,
    );
  }

  return initialValues;
}

function getColumnWeights(
  // @ts-ignore
  columns: Column[],
  sizeWeights?: Record<string, number>,
): Record<string, number> {
  return columns.reduce(
    (acc, column) => {
      const { id } = column;
      if (!id) return acc;
      return { ...acc, [id]: get(sizeWeights, id) || 1 };
    },
    {} as Record<string, number>,
  );
}

type CellSizingFn = (columnNode: HTMLElement[]) => number;

function getMinWidthsFromNoWrapColumns(
  noWrapColumns: string[],
  tableEl: HTMLElement | null,
  cellSizing?: Record<string, CellSizingFn>,
): Record<string, number> {
  const minWidths: Record<string, number> = {};
  if (!noWrapColumns) return minWidths;

  noWrapColumns.forEach((id) => {
    if (!tableEl) return;

    const columnNode = Array.from(tableEl.querySelectorAll(`td[data-column-id='${id}']`));
    const cellSizingFn = get(cellSizing, id);
    let newWidth = 0;
    if (isFunction(cellSizingFn)) {
      // @ts-ignore
      newWidth = cellSizingFn(columnNode);
    } else {
      newWidth = Math.max(
        ...columnNode.map((node) => {
          const innerWrapper = node.querySelector(InnerCellWrapper.toString());
          return innerWrapper
            ? Math.ceil(innerWrapper.getBoundingClientRect().width)
            : Math.ceil(node.getBoundingClientRect().width);
        }),
      );
    }

    minWidths[id] = newWidth;
  });

  return minWidths;
}

export function widthsOfFirstRow(table: HTMLTableElement) {
  const tbody = table.querySelector('tbody');
  if (!tbody) {
    return null;
  }

  const firstRow = tbody.querySelector('tr');
  if (!firstRow) {
    return null;
  }

  const tdElements = firstRow.querySelectorAll('td');

  return Array.from(tdElements).map((td) => td.clientWidth);
}

function sumPaddingOfFirstRow(table: HTMLTableElement): number {
  const tbody = table.querySelector('tbody');
  if (!tbody) {
    return 0;
  }

  const firstRow = tbody.querySelector('tr');
  if (!firstRow) {
    return 0;
  }

  const tdElements = firstRow.querySelectorAll('td');
  let sumOfPadding = 0;

  tdElements.forEach((td) => {
    const computedStyle = window.getComputedStyle(td);

    const paddingLeft = parseFloat(computedStyle.paddingLeft);
    const paddingRight = parseFloat(computedStyle.paddingRight);

    sumOfPadding += paddingLeft + paddingRight;
  });

  return sumOfPadding;
}

type UseRecalculateDimensionsParams<RecordType> = {
  cellSizing?: Record<string, CellSizingFn>;
  columns: ColumnDef<RecordType, any>[];
  minColumnWidth: number | undefined;
  debug?: boolean;
  dimensions?: {
    joinedHeight: number;
    innerTableHeight: number;
    tableHeight: number;
  };
  noWrapColumns: string[] | undefined;
  sizeWeights: Record<string, number> | undefined;
  tableRef: React.RefObject<HTMLTableElement>;
};

export default function useRecalculateDimensions<RecordType>({
  cellSizing,
  columns,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  debug,
  // dimensions,
  minColumnWidth,
  noWrapColumns,
  sizeWeights,
  tableRef,
}: UseRecalculateDimensionsParams<RecordType>) {
  const [dimensions, setDimensions] = useState({
    tableHeight: 0,
    joinedHeight: 0,
    innerTableHeight: 0,
  });
  const recalculate = useCallback(
    () => () => {
      // Calculate initial column widths based on the specified size.
      const columnWidths = columns.reduce(
        (acc, column) => (column.id && column.size ? { ...acc, [column.id]: column.size } : acc),
        {} as Record<string, number>,
      );

      const newWidths = { ...columnWidths };

      // Ensure columns have at least the minimum width.
      columns.forEach((column) => {
        const { id, minSize } = column;
        if (id && !newWidths[id]) {
          newWidths[id] = minSize || minColumnWidth || 0;
        }
      });

      const tableEl = tableRef.current;

      // Calculate minimum widths for noWrap columns based on their contents.
      const minWidths = getMinWidthsFromNoWrapColumns(noWrapColumns || [], tableEl, cellSizing);

      if (tableEl) {
        const { clientWidth } = tableEl;
        const tableHeight = document.body.scrollHeight - tableEl.getBoundingClientRect().top;
        const innerTableHeight = sumBy(
          Array.from(tableEl.children),
          (child) => child.getBoundingClientRect().height,
        );

        // Update dimensions if they have changed.
        if (
          dimensions.tableHeight !== tableHeight ||
          dimensions.innerTableHeight !== innerTableHeight
        ) {
          setDimensions({
            tableHeight,
            joinedHeight: tableHeight,
            innerTableHeight,
          });
        }

        const paddingSum = sumPaddingOfFirstRow(tableEl);
        const availableWidth = clientWidth - paddingSum;
        // Distribute remaining width among columns based on their weights.
        const weights = getColumnWeights(columns, sizeWeights);
        const distributedWidths = distributeTotal(availableWidth, weights, minWidths);

        Object.keys(distributedWidths).forEach((id) => {
          newWidths[id] = distributedWidths[id];
        });
      }

      return newWidths;
    },
    [
      columns,
      tableRef,
      noWrapColumns,
      cellSizing,
      minColumnWidth,
      dimensions.tableHeight,
      dimensions.innerTableHeight,
      sizeWeights,
    ],
  );

  return { dimensions, recalculate };
}

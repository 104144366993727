import { Drawer, DrawerHeader } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import AutoVPNMemberAddEditForm from '../../../components/autoVPN/AutoVPNMemberAddEditForm';
import { AutoVPNGroupQuery } from '../../../components/autoVPN/utils';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetworksForCompany } from '../../../hooks/useNetworksForCompany';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';

export const Meta = () => ({
  path: '/org/:companyName/network/auto-vpn/groups/:groupUUID/members/:memberUUID/edit',
  title: 'Edit member - Group - Auto VPN - Network',
});

export default function AutoVPNMemberEditPage() {
  const companySlug = useCurrentCompany();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AutoVPNMemberEditPage);

  const group = useGraphQL(
    AutoVPNGroupQuery,
    {
      uuid: drawerParams?.groupUUID!,
    },
    {
      enabled: !!drawerParams?.groupUUID,
    },
  )?.data?.autoVPNGroup;

  const member = useMemo(
    () => group?.members?.find((m) => m.UUID === drawerParams?.memberUUID),
    [group, drawerParams?.memberUUID],
  );

  const networks = useNetworksForCompany(companySlug);
  const memberNetwork = useMemo(
    () => networks.find((network) => network.UUID === member?.networkUUID),
    [networks, member],
  );

  const closeDrawer = useCloseDrawerCallback();

  return (
    <IsPermitted
      should404OnAccessDenied
      allowOperatorAlways={false}
      path={Meta().path}
      isPermitted={({ isOperator, permissions, companyNosFlags, ldFlags }) =>
        Boolean(permissions.hasPermission(PermissionType.PermAutoVpnWrite)) &&
        companyNosFlags[NosFeature.SITE_TO_SITE_VPN] &&
        (isOperator || !!ldFlags['site-to-site-vpn']) &&
        !!drawerParams?.groupUUID &&
        !!drawerParams?.memberUUID &&
        !!group &&
        !!member &&
        !!memberNetwork
      }
    >
      <Drawer>
        <DrawerHeader icon="pencil" heading="Edit member" onClose={closeDrawer} />
        <AutoVPNMemberAddEditForm group={group!} member={member} />
      </Drawer>
    </IsPermitted>
  );
}

import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { RadioBand, ValidChannelsQueryQuery } from '../gql/graphql';
import { graphql } from '../gql';

export type ValidChannelsQueryResult = NonNullable<
  ValidChannelsQueryQuery['validChannels']
>[number];

export const ValidChannelsQuery = graphql(`
  query ValidChannelsQuery($networkUUID: UUID!) {
    validChannels(networkUUID: $networkUUID) {
      band
      channels
    }
  }
`);

export function useValidChannels(networkUUID: string) {
  const validChannels = useGraphQL(ValidChannelsQuery, { networkUUID }).data?.validChannels;

  return useMemo(
    () =>
      Object.fromEntries(
        (validChannels ?? []).map(({ band, channels }) => [band, channels]),
      ) as Record<RadioBand, number[]>,
    [validChannels],
  );
}

import {
  Box,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  HStack,
  Icon,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  Tooltip,
} from '@meterup/atto';
import { expectDefinedOrThrow, isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { truncate } from 'lodash-es';
import { DateTime } from 'luxon';

import type { WirelessConnectionEventQueryResult } from '../../../../../../components/Clients/utils';
import {
  ConnectionEventIcon,
  eventDuration,
  eventDurationTitle,
  eventNameHuman,
  isFastRoam,
  isRoam,
  reasonCodeToHuman,
  WirelessConnectionEventsQuery,
} from '../../../../../../components/Clients/utils';
import { APWOS2DeviceTarget, SSIDDeviceTarget } from '../../../../../../components/DeviceTargets';
import { MetricsDeviceType } from '../../../../../../components/Metrics/utils';
import { StickyObjectHeader } from '../../../../../../components/Object/ObjectHeader';
import IsPermitted from '../../../../../../components/permissions/IsPermitted';
import { radioBandToHuman } from '../../../../../../components/Wireless/RadioProfiles/utils';
import { WirelessConnectionEventsByAccessPointQuery } from '../../../../../../components/Wireless/utils';
import { paths } from '../../../../../../constants';
import { PermissionType } from '../../../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../../nav';
import { useSearchParamsState } from '../../../../../../providers/SearchParamsStateProvider';
import { getDurationSeconds, getStep } from '../../../../../../utils/chart_utils';
import { fromISOtoLocaleString } from '../../../../../../utils/time';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/connection-events/:deviceType/:identifier/:timestamp',
  title: 'Connection event - Client - Clients - Insights',
});

function ObjectHeaderIcon({ event }: { event: WirelessConnectionEventQueryResult }) {
  if (isRoam(event)) {
    return (
      <HStack align="center" spacing={space(16)}>
        <APWOS2DeviceTarget
          uuid={event.previousVirtualDeviceConnectedTo!.UUID}
          label={truncate(event.previousVirtualDeviceConnectedTo!.label, { length: 10 })}
        />
        <ConnectionEventIcon event={event} size={16} />
        <APWOS2DeviceTarget
          uuid={event.virtualDevice.UUID}
          label={truncate(event.virtualDevice.label, { length: 10 })}
        />
      </HStack>
    );
  }

  return <ConnectionEventIcon event={event} size={24} />;
}

export default function ConnectionEventDetailPage() {
  const { identifier, deviceType, timestamp } = Nav.useRegionParams(
    'drawer',
    paths.drawers.ConnectionEventDetailPage,
  )!;
  const network = useNetwork();
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '30d');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '30d';
  const [offset] = useSearchParamsState<number>('offset', 0);
  const currentOffset = offset ?? 0;
  const limit = 26;

  if (deviceType !== MetricsDeviceType.AccessPoint && deviceType !== MetricsDeviceType.Client) {
    throw new Error(`Invalid device type: ${deviceType}`);
  }

  const clientConnectionEvents =
    useGraphQL(
      WirelessConnectionEventsQuery,
      {
        networkUUID: network.UUID,
        mac: identifier,
        filter: {
          timeFilter: {
            durationSeconds: getDurationSeconds(currentTimePeriod),
            stepSeconds: getStep(currentTimePeriod),
          },
        },
        offset: currentOffset,
        limit,
      },
      {
        enabled: deviceType === MetricsDeviceType.Client,
      },
    ).data?.wirelessClientConnectionEventsByClient ?? [];

  const apConnectionEvents =
    useGraphQL(
      WirelessConnectionEventsByAccessPointQuery,
      {
        networkUUID: network.UUID,
        virtualDeviceUUID: identifier,
        filter: {
          timeFilter: {
            durationSeconds: getDurationSeconds(currentTimePeriod),
            stepSeconds: getStep(currentTimePeriod),
          },
        },
        offset: currentOffset,
        limit,
      },
      {
        enabled: deviceType === MetricsDeviceType.AccessPoint,
      },
    ).data?.wirelessClientConnectionEventsByAP ?? [];

  const connectionEvents =
    deviceType === MetricsDeviceType.Client ? clientConnectionEvents : apConnectionEvents;

  const event = connectionEvents.find((e) => e.timestamp === timestamp);
  expectDefinedOrThrow(event, new ResourceNotFoundError('Connection event not found'));

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkMetricsRead) &&
            nosFlags[NosFeature.WOS2],
        )
      }
      path={Meta().path}
      should404OnAccessDenied
    >
      <Drawer>
        <DrawerHeader icon="log" heading="Connection event" onClose={useCloseDrawerCallback()} />
        <DrawerContent gutter="none">
          <StickyObjectHeader
            illustration={<ObjectHeaderIcon event={event} />}
            name={eventNameHuman(event)}
          />
          <Section relation="stacked">
            <SectionHeader icon="information" heading="Information" />
            <SectionContent gutter="all">
              <SummaryList gutter="none">
                <SummaryListRow>
                  <SummaryListKey>Client MAC address</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox
                      aria-label="Copy MAC address"
                      relation="stacked"
                      size="small"
                      value={event.macAddress}
                    >
                      <Text family="monospace">{event.macAddress}</Text>
                    </CopyBox>
                  </SummaryListValue>
                </SummaryListRow>

                {event.ssid && (
                  <SummaryListRow>
                    <SummaryListKey>SSID</SummaryListKey>
                    <SummaryListValue>
                      <SSIDDeviceTarget ssidName={event.ssid.ssid} />
                    </SummaryListValue>
                  </SummaryListRow>
                )}

                <SummaryListRow>
                  <SummaryListKey>Band</SummaryListKey>
                  <SummaryListValue>{radioBandToHuman(event.band)}</SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>AP</SummaryListKey>
                  <SummaryListValue>
                    <APWOS2DeviceTarget
                      uuid={event.virtualDevice.UUID}
                      label={event.virtualDevice.label}
                    />
                  </SummaryListValue>
                </SummaryListRow>

                {event.previousVirtualDeviceConnectedTo && (
                  <SummaryListRow>
                    <SummaryListKey>Previous AP</SummaryListKey>
                    <SummaryListValue>
                      <APWOS2DeviceTarget
                        uuid={event.previousVirtualDeviceConnectedTo.UUID}
                        label={event.previousVirtualDeviceConnectedTo.label}
                      />
                    </SummaryListValue>
                  </SummaryListRow>
                )}

                {eventDuration(event) && (
                  <SummaryListRow>
                    <SummaryListKey>{eventDurationTitle(event)}</SummaryListKey>
                    <SummaryListValue>{eventDuration(event)}</SummaryListValue>
                  </SummaryListRow>
                )}

                {isDefinedAndNotEmpty(reasonCodeToHuman(event.reasonCode, event.failedStep)) && (
                  <SummaryListRow>
                    <SummaryListKey>Reason</SummaryListKey>
                    <SummaryListValue>
                      <Text display="inline">
                        {reasonCodeToHuman(event.reasonCode, event.failedStep)}
                        {isDefinedAndNotEmpty(event.reasonCode) && (
                          <Tooltip asChild contents={`802.11 reason code: ${event.reasonCode}`}>
                            <Box style={{ display: 'inline', paddingLeft: '4px' }}>
                              <Icon icon="information" size={space(16)} />
                            </Box>
                          </Tooltip>
                        )}
                      </Text>
                    </SummaryListValue>
                  </SummaryListRow>
                )}

                {isRoam(event) && (
                  <SummaryListRow>
                    <SummaryListKey>Roam method</SummaryListKey>
                    <SummaryListValue>
                      {isFastRoam(event) ? '802.11r' : 'Standard'}
                    </SummaryListValue>
                  </SummaryListRow>
                )}

                <SummaryListRow>
                  <SummaryListKey>Timestamp</SummaryListKey>
                  <SummaryListValue>
                    {fromISOtoLocaleString({
                      iso: event.timestamp,
                      format: DateTime.DATETIME_SHORT,
                    })}
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
        </DrawerContent>
      </Drawer>
    </IsPermitted>
  );
}

import type { SortingState } from '@tanstack/react-table';
import { DevicePlaceholder, DeviceTarget, Icon, Port, space, styled } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import type { PhyInterfaceWithStats } from '../utils2';
import { paths } from '../../../../constants';
import { PermissionType } from '../../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../hooks/useNosFeatures';
import { Nav } from '../../../../nav';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../../../utils/types';
import { getPhyInterfaceLabel } from '../../../Firewall/utils';
import { useISPOrNull } from '../../../NetworkWide/ISPs/utils';
import { NoValue } from '../../../NoValue';
import IsPermitted from '../../../permissions/IsPermitted';
import { createColumnBuilder } from '../../../Table/createColumnBuilder';
import { getConnectionTo, portSpeedForAtto } from '../../Switches/utils';
import { formatByteStat, formatPacketStat, portStatus } from '../utils2';

const PortTableContainer = styled('div', {
  width: '100%',
  overflow: 'auto',
});
PortTableContainer.displayName = 'PortTableContainer';

function renderByteStatCell(stat: number | null | undefined) {
  const val = formatByteStat(stat);
  if (val === 0) {
    return <NoValue />;
  }
  return <>{val.toFixed(2)}</>;
}

function renderPacketStatCell(stat: number | null | undefined) {
  const val = formatPacketStat(stat);
  if (val === 0) {
    return <NoValue />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{val}</>;
}

// Should be kept in sync with PortConnection component below
function portConnection({ phyInterface }: PhyInterfaceWithStats): string | undefined {
  return (
    phyInterface.internetServicePlan?.provider?.name ??
    phyInterface.connectedDevices
      ?.map(
        (connectedDevice) =>
          connectedDevice.hardwareDevice?.virtualDevice?.label ??
          connectedDevice?.hardwareDevice?.serialNumber ??
          connectedDevice?.macAddress,
      )
      .join(', ')
  );
}

function PortConnectionISP({ row }: { row: PhyInterfaceWithStats }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const isp = useISPOrNull({
    companySlug: companyName,
    networkUUID: network.UUID,
    UUID: row.phyInterface.internetServicePlan?.UUID,
  });

  if (!row.phyInterface.internetServicePlan?.provider?.name) {
    return <DevicePlaceholder aria-label="Internet service provider">ISP</DevicePlaceholder>;
  }

  if (isp) {
    return (
      <IsPermitted
        isPermitted={({ ldFlags, permissions }) =>
          Boolean(permissions.hasPermission(PermissionType.PermIspRead)) && Boolean(ldFlags.isp)
        }
      >
        <DeviceTarget
          as={ReactRouterLink}
          to={makeDrawerLink(window.location, paths.drawers.ISPDrawerPage, {
            companyName,
            networkSlug: network.slug,
            isp: isp.UUID,
          })}
          type="globe"
          aria-label="Internet service provider"
          wrap={false}
        >
          {row.phyInterface.internetServicePlan?.provider?.name}
        </DeviceTarget>
      </IsPermitted>
    );
  }

  return (
    <DevicePlaceholder type="globe" aria-label="Internet service provider" wrap={false}>
      {row.phyInterface.internetServicePlan?.provider?.name}
    </DevicePlaceholder>
  );
}

// Should be kept in sync with portConnection function above
function PortConnection({ row }: { row: PhyInterfaceWithStats }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const activeController = useActiveControllerForNetwork(network);
  const controllerSerialNumber = activeController?.hardwareDevice?.serialNumber;
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);

  if (row.phyInterface.internetServicePlan) {
    return <PortConnectionISP row={row} />;
  }

  if (row.phyInterface.connectedDevices?.length) {
    if (row.phyInterface.connectedDevices.length === 1) {
      const connectedDevice = row.phyInterface.connectedDevices[0];
      if (connectedDevice.hardwareDevice) {
        const connectionTo = getConnectionTo(connectedDevice, {
          networkSlug: network.slug,
          companyName,
          controllerSerialNumber,
          isWOS2Enabled,
        });

        const label =
          connectedDevice.hardwareDevice?.virtualDevice?.label ??
          connectedDevice.hardwareDevice.serialNumber;

        const type = deviceTypeToHardwareIconPropHardware(
          connectedDevice.hardwareDevice.deviceType,
        );

        if (connectionTo) {
          return (
            <DeviceTarget
              as={ReactRouterLink}
              to={connectionTo}
              aria-label="View connected device"
              type={type}
              wrap={false}
            >
              {label}
            </DeviceTarget>
          );
        }

        return (
          <DevicePlaceholder type={type} aria-label="Connected device" wrap={false}>
            {label}
          </DevicePlaceholder>
        );
      }
    }

    return <>{row.phyInterface.connectedDevices.length} devices</>;
  }

  return <NoValue />;
}

const builder = createColumnBuilder<PhyInterfaceWithStats>();
const columns = [
  builder.data((row) => row.phyInterface.portNumber.toString(), {
    id: 'port-number',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status and port number',
      },
    },
    cell: ({ row }) => (
      <Port
        port={row.phyInterface.isSFP ? 'sfp' : 'ethernet'}
        variant="simple"
        number={row.phyInterface.portNumber}
        speed={portSpeedForAtto(row.phyInterface)}
        status={portStatus(row.phyInterface)}
        disabled={!row.phyInterface.isEnabled}
      />
    ),
  }),
  builder.data((row) => getPhyInterfaceLabel(row.phyInterface, false, false), {
    id: 'label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => portConnection(row), {
    id: 'connection',
    header: 'Connection',
    cell: PortConnection,
  }),
  builder.data((row) => row.portStats?.totalRxBytes?.toFixed(), {
    id: 'port-total-rx-bytes',
    header: 'Total RX (GB)',
    meta: {
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row }) => renderByteStatCell(row.portStats?.totalRxBytes),
  }),
  builder.data((row) => row.portStats?.totalTxBytes?.toFixed(), {
    id: 'port-total-tx-bytes',
    header: 'Total TX (GB)',
    meta: {
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row }) => renderByteStatCell(row.portStats?.totalTxBytes),
  }),
  // builder.data((row) => row.totalRxPackets.toFixed(), {
  //   id: 'port-total-rx-packets',
  //   header: 'Total downloaded (packets)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderPacketStatCell(props.row.totalRxPackets),
  // }),
  // builder.data((row) => row.totalTxPackets.toFixed(), {
  //   id: 'port-total-tx-packets',
  //   header: 'Total uploaded (packets)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderPacketStatCell(props.row.totalTxPackets),
  // }),
  // builder.data((row) => row.multicastRxBytes.toFixed(), {
  //   id: 'port-multicast-rx-bytes',
  //   header: 'Multicast downloaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderByteStatCell(props.row.multicastRxBytes),
  // }),
  // builder.data((row) => row.multicastTxBytes.toFixed(), {
  //   id: 'port-multicast-tx-bytes',
  //   header: 'Multicast uploaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderByteStatCell(props.row.multicastTxBytes),
  // }),
  // builder.data((row) => row.multicastRxPackets.toFixed(), {
  //   id: 'port-multicast-rx-packets',
  //   header: 'Multicast downloaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderPacketStatCell(props.row.multicastRxPackets),
  // }),
  // builder.data((row) => row.multicastTxPackets.toFixed(), {
  //   id: 'port-multicast-tx-packets',
  //   header: 'Multicast uploaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderPacketStatCell(props.row.multicastTxPackets),
  // }),
  // builder.data((row) => row.broadcastRxBytes.toFixed(), {
  //   id: 'port-broadcast-rx-bytes',
  //   header: 'Broadcast downloaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderByteStatCell(props.row.broadcastRxBytes),
  // }),
  // builder.data((row) => row.broadcastTxBytes.toFixed(), {
  //   id: 'port-broadcast-tx-bytes',
  //   header: 'Broadcast uploaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderByteStatCell(props.row.broadcastTxBytes),
  // }),
  // builder.data((row) => row.broadcastRxPackets.toFixed(), {
  //   id: 'port-broadcast-rx-packets',
  //   header: 'Broadcast downloaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderPacketStatCell(props.row.broadcastRxPackets),
  // }),
  // builder.data((row) => row.broadcastTxPackets.toFixed(), {
  //   id: 'port-broadcast-tx-packets',
  //   header: 'Broadcast uploaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderPacketStatCell(props.row.broadcastTxPackets),
  // }),
  // builder.data((row) => row.unicastRxBytes.toFixed(), {
  //   id: 'port-unicast-rx-bytes',
  //   header: 'Unicast downloaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderByteStatCell(props.row.unicastRxBytes),
  // }),
  // builder.data((row) => row.unicastTxBytes.toFixed(), {
  //   id: 'port-unicast-tx-bytes',
  //   header: 'Unicast uploaded (GB)',
  //   size: 180,
  //   minSize: 180,
  //   maxSize: 180,
  //   meta: {
  //     condense: true,
  //   },
  //   cell: (props) => renderByteStatCell(props.row.unicastTxBytes),
  // }),
  // builder.data((row) => row.unicastRxPackets.toFixed(), {
  //   id: 'port-unicast-rx-packets',
  //   header: 'Unicast downloaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderPacketStatCell(props.row.unicastRxPackets),
  // }),
  // builder.data((row) => row.unicastTxPackets.toFixed(), {
  //   id: 'port-unicast-tx-packets',
  //   header: 'Unicast uploaded (GB)',
  //   meta: {
  //     minWidth: 0,
  //     tooltip: { contents: 'Aggregate over the past 24 hours' },
  //   },
  //   cell: (props) => renderPacketStatCell(props.row.unicastTxPackets),
  // }),
  builder.data((row) => row.portStats?.ipv4.toFixed(), {
    id: 'port-ipv4',
    header: 'IPv4 packets',
    meta: {
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row }) => renderPacketStatCell(row.portStats?.ipv4),
  }),
  builder.data((row) => row.portStats?.ipv6?.toFixed(), {
    id: 'port-ipv6',
    header: 'IPv6 packets',
    meta: {
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row }) => renderPacketStatCell(row.portStats?.ipv6),
  }),
  builder.data((row) => row.portStats?.drops?.toFixed(), {
    id: 'port-drops',
    header: 'Dropped packets',
    meta: {
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row }) => renderPacketStatCell(row.portStats?.drops),
  }),
  builder.data((row) => row.portStats?.rxErr?.toFixed(), {
    id: 'port-rxErr',
    header: 'RX error packets',
    meta: {
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row }) => renderPacketStatCell(row.portStats?.rxErr),
  }),
  builder.data((row) => row.portStats?.txErr?.toFixed(), {
    id: 'port-txErr',
    header: 'TX error packets',
    meta: {
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row }) => renderPacketStatCell(row.portStats?.txErr),
  }),
];

type PortsListProps = {
  uuid: string;
  phyInterfacesWithStats: PhyInterfaceWithStats[];
};

export default function PortsList({ uuid, phyInterfacesWithStats }: PortsListProps) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const detailParams = Nav.useRegionParams('drawer', paths.drawers.SecurityAppliancePortDetailPage);

  const portDrawerLink = useCallback(
    (phyInterfaceUUID: string) =>
      makeDrawerLink(window.location, paths.drawers.SecurityAppliancePortDetailPage, {
        phyInterfaceUUID,
        virtualDeviceUUID: uuid,
        networkSlug: network.slug,
        companyName,
      }),
    [uuid, network.slug, companyName],
  );

  return (
    <PortTableContainer>
      <AutoTable
        size="small"
        columns={columns}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        data={phyInterfacesWithStats ?? []}
        isRowSelected={({ phyInterface }) => phyInterface.UUID === detailParams?.phyInterfaceUUID}
        getLinkTo={({ phyInterface }) => portDrawerLink(phyInterface.UUID)}
      />
    </PortTableContainer>
  );
}

import type { BreadcrumbProps, PaneHeaderProps } from '@meterup/atto';
import { ComboBox, ComboBoxItem, Tab } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { type DetailHeaderProps, ResourceNotFoundError } from '@meterup/common';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNetworkWideCrumbs } from '../../../utils/routing';
import DetailLayout from '../../Layouts/DetailedLayout';
import { ReactRouterLink } from '../../ReactRouterLink';
import { ISPActions } from './ISPActions';
import { useISP, useISPs } from './utils';

export enum ISPValidTabs {
  Insights = 'insights',
  Events = 'events',
}

function ISPTabs({ activeTab, uuid }: { activeTab: ISPValidTabs; uuid: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  return (
    <>
      <Tab
        as={Link}
        to={makeLink(paths.pages.ISPInsightsPage, {
          companyName,
          networkSlug: network.slug,
          uuid,
        })}
        selected={activeTab === ISPValidTabs.Insights}
        icon="reporting"
      >
        Insights
      </Tab>
      <Tab
        as={Link}
        to={makeLink(paths.pages.ISPEventsPage, {
          companyName,
          networkSlug: network.slug,
          uuid,
        })}
        selected={activeTab === ISPValidTabs.Events}
        icon="log"
      >
        Events
      </Tab>
    </>
  );
}

function ISPHeader({ activeTab, uuid }: { activeTab: ISPValidTabs; uuid: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const networkWideCrumb = useNetworkWideCrumbs();
  const navigate = useNavigate();

  const isps = useISPs({ companySlug: companyName, networkUUID: network.UUID, UUID: uuid });
  const isp = useISP({ companySlug: companyName, networkUUID: network.UUID, UUID: uuid });

  const navigateToISP = useCallback(
    (newUUID: string) => {
      navigate(
        makeLink(paths.pages.ISPInsightsPage, {
          companyName,
          networkSlug: network.slug,
          uuid: newUUID,
        }),
      );
    },
    [navigate, companyName, network.slug],
  );

  const sortedISPs = isps.sort((a, b) =>
    (a.provider?.name || a.UUID).localeCompare(b.provider?.name || b.UUID),
  );

  return {
    back,
    crumbs: [
      ...networkWideCrumb,
      {
        type: 'page',
        page: {
          as: ReactRouterLink,
          to: makeLink(paths.pages.ISPsPage, { companyName, networkSlug: network.slug }),
          label: 'ISPs',
        },
      },
    ] as BreadcrumbProps[],
    switcher: (
      <ComboBox maxWidth="100%" icon="globe" value={uuid} onValueChange={navigateToISP}>
        {sortedISPs.map((s) => (
          <ComboBoxItem key={s.UUID} textValue={s.provider?.name || s.UUID}>
            {s.provider?.name || s.UUID}
          </ComboBoxItem>
        ))}
      </ComboBox>
    ) as PaneHeaderProps['switcher'],
    type: 'isp' as DetailHeaderProps['type'],
    heading: isp.provider?.name as PaneHeaderProps['heading'],
    actions: (<ISPActions ispUUID={uuid} view="detail" />) as PaneHeaderProps['actions'],
    tabs: (<ISPTabs activeTab={activeTab} uuid={uuid} />) as PaneHeaderProps['tabs'],
  };
}

export default function ISP({
  activeTab,
  content,
  uuid,
}: {
  activeTab: ISPValidTabs;
  content: React.ReactNode;
  uuid: string;
}) {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  return <DetailLayout header={ISPHeader({ activeTab, uuid })} main={content} />;
}

import type { BreadcrumbPageProps, BreadcrumbPropType } from '@meterup/atto';
import { useNavigate } from 'react-router';

import { ReactRouterLink } from '../components/ReactRouterLink';
import { paths } from '../constants';
import { useNetwork } from '../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { makeLink } from './main_and_drawer_navigation';

export const useNavigateBack = () => {
  const navigate = useNavigate();
  return () => navigate(-1);
};

export const useNavigateHome = () => {
  const companyName = useCurrentCompany();

  return {
    as: ReactRouterLink as BreadcrumbPageProps['as'],
    to: makeLink(paths.pages.HubListPage, {
      companyName,
    }) as BreadcrumbPageProps['to'],
  };
};

const useNetworkCrumb = () => {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return {
    type: 'page' as BreadcrumbPropType,
    page: {
      as: ReactRouterLink,
      to: makeLink(paths.pages.InsightsPage, {
        companyName,
        networkSlug: network.slug,
      }),
      label: network.label ?? network.slug,
    } as BreadcrumbPageProps,
  };
};

export const useInsightsCrumbs = () => {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    useNetworkCrumb(),
    {
      type: 'page' as BreadcrumbPropType,
      page: {
        as: ReactRouterLink,
        to: makeLink(paths.pages.InsightsPage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Insights',
      } as BreadcrumbPageProps,
    },
  ];
};

export const useHardwareCrumbs = () => {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    useNetworkCrumb(),
    {
      type: 'page' as BreadcrumbPropType,
      page: {
        as: ReactRouterLink,
        to: makeLink(paths.pages.HardwarePage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Hardware',
      } as BreadcrumbPageProps,
    },
  ];
};

export const useNetworkWideCrumbs = () => {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    useNetworkCrumb(),
    {
      type: 'page' as BreadcrumbPropType,
      page: {
        as: ReactRouterLink,
        to: makeLink(paths.pages.NetworkWidePage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Network-wide',
      } as BreadcrumbPageProps,
    },
  ];
};

export const useWirelessCrumbs = () => {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    useNetworkCrumb(),
    {
      type: 'page' as BreadcrumbPropType,
      page: {
        as: ReactRouterLink,
        to: makeLink(paths.pages.WirelessPage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Wireless',
      } as BreadcrumbPageProps,
    },
  ];
};

export const useFirewallCrumbs = () => {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    useNetworkCrumb(),
    {
      type: 'page' as BreadcrumbPropType,
      page: {
        as: ReactRouterLink,
        to: makeLink(paths.pages.FirewallPage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Firewall',
      } as BreadcrumbPageProps,
    },
  ];
};

export const useDesignCrumbs = () => {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    useNetworkCrumb(),
    {
      type: 'page' as BreadcrumbPropType,
      page: {
        as: ReactRouterLink,
        to: makeLink(paths.pages.DesignPage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Design',
      } as BreadcrumbPageProps,
    },
  ];
};

export const useSecureTunnelsCrumbs = () => {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return [
    useNetworkCrumb(),
    {
      type: 'page' as BreadcrumbPropType,
      page: {
        as: ReactRouterLink,
        to: makeLink(paths.pages.SecureTunnelsPage, {
          companyName,
          networkSlug: network.slug,
        }),
        label: 'Secure tunnels',
      } as BreadcrumbPageProps,
    },
  ];
};

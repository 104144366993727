import { Pane, PaneContent, PaneHeader, SearchInput } from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useWirelessCrumbs } from '../../../utils/routing';
import { ReactRouterLink } from '../../ReactRouterLink';
import { useAccessPointsQuery } from '../utilities/query';
import RadioProfilesActions from './RadioProfilesActions';
import RadioProfilesList from './RadioProfilesList';
import { RadioProfilesQuery } from './utils';

export default function RadioProfiles() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const wirelessCrumb = useWirelessCrumbs();
  const profiles = useGraphQL(RadioProfilesQuery, { networkUUID: network.UUID }).data
    ?.radioProfilesForNetwork;
  expectDefinedOrThrow(profiles, new ResourceNotFoundError('Unable to load radio profiles'));

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...wirelessCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.RadioProfilesPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'Radio profiles',
            },
          },
        ]}
        icon="radio-profile"
        heading="Radio profiles"
        count={
          useAccessPointsQuery({
            dataQuery: RadioProfilesQuery,
            dataPoint: 'radioProfilesForNetwork',
          }).length
        }
        actions={
          <>
            <RadioProfilesActions />
            <SearchInput
              placeholder="..."
              aria-label="Filter access points"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent gutter="bottom">
        <RadioProfilesList />
      </PaneContent>
    </Pane>
  );
}

import { api } from '@meterup/proto';
import { z } from 'zod';

import type { FixMeLater } from '../../../../../types/fixMeLater';
import { NO_CATEGORY_ID } from './fields';

const URLPattern =
  /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const validContentFilterRuleData = z.object({
  content: z.union([
    z.object({
      type: z.literal('domain'),
      domain: z.string().regex(URLPattern, 'Invalid URL'),
      categoryId: z.coerce.number().optional(),
    }),
    z.object({
      type: z.literal('category'),
      domain: z.string().optional(),
      categoryId: z.coerce.number().min(0, 'Required'),
    }),
  ]),
  precedence: z.coerce.number().min(0).max(100),
  action: z.nativeEnum(api.ContentFilterAction),
});

export type ValidContentFilterRuleData = z.infer<typeof validContentFilterRuleData>;

export const toAPIData = (
  data: ValidContentFilterRuleData,
  { precedenceOffset }: { precedenceOffset: number },
): api.UpsertContentFilterRuleRequest => {
  const {
    action,
    content: { categoryId, domain, type },
    precedence,
  } = validContentFilterRuleData.parse(data);

  return {
    description: '',
    action,
    domains: [],
    domain: type === 'domain' ? domain : undefined,
    category_id: type === 'category' ? categoryId : undefined,
    precedence: precedence + precedenceOffset,
  };
};

export const fromAPIData = (
  rule: api.ContentFilterRule,
  { precedenceOffset }: { precedenceOffset: number },
): ValidContentFilterRuleData => ({
  content: {
    type: rule.domain ? 'domain' : 'category',
    domain: rule.domain ?? '',
    categoryId: (rule.category?.id.toFixed() as FixMeLater) ?? NO_CATEGORY_ID,
  },
  action: rule.action,
  precedence: rule.precedence + precedenceOffset,
});

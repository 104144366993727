import AccessPoint from '../../../../../../components/Hardware/AccessPoints/AccessPoint';
import IsPermitted from '../../../../../../components/permissions/IsPermitted';
import { ValidAPDetailsTabs } from '../../../../../../components/Wireless/utils';
import { paths } from '../../../../../../constants';
import { PermissionType } from '../../../../../../gql/graphql';
import { NosFeature } from '../../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/:uuid/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Access point - Hardware',
});

export default function AccessPointPage() {
  const drawerParams = Nav.useRegionParams('root', paths.pages.AccessPointPage);

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags, pathParams }) => {
        if (nosFlags[NosFeature.WOS2]) {
          if (
            (pathParams?.tab === ValidAPDetailsTabs.BootHistory ||
              pathParams?.tab === ValidAPDetailsTabs.ScanData) &&
            permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted)
          ) {
            return true;
          }
          if (
            pathParams?.tab === ValidAPDetailsTabs.BSSIDs &&
            permissions.hasPermission(PermissionType.PermSsidsRead)
          ) {
            return true;
          }
          if (
            (pathParams?.tab === ValidAPDetailsTabs.Insights ||
              pathParams?.tab === ValidAPDetailsTabs.Clients) &&
            permissions.hasPermission(PermissionType.PermNetworkMetricsRead)
          ) {
            return true;
          }
          return false;
        }
        return false;
      }}
      path={Meta().path}
      should404OnAccessDenied
    >
      <AccessPoint uuid={drawerParams?.uuid!} tab={drawerParams?.tab as ValidAPDetailsTabs} />
    </IsPermitted>
  );
}

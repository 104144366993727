import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  space,
  Text,
  useDialogStateCallback,
  VStack,
} from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { listISPsQuery, useISP } from './utils';

const deleteISPMutation = graphql(`
  mutation DeleteInternetServicePlanMutation($internetServicePlanUUID: UUID!) {
    deleteInternetServicePlan(internetServicePlanUUID: $internetServicePlanUUID)
  }
`);

export function ISPActions({
  ispUUID,
  view,
}: {
  ispUUID: string;
  view: 'drawer' | 'list' | 'edit' | 'detail';
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const dialogProps = useDialogStateCallback();
  const queryClient = useQueryClient();
  const mutation = useGraphQLMutation(deleteISPMutation);
  const closeDrawer = useCloseDrawerCallback();
  const onDelete = useCallback(() => {
    mutation.mutate(
      { internetServicePlanUUID: ispUUID },
      {
        onSuccess() {
          notify(`Successfully created ISP`, {
            variant: 'positive',
          });
          queryClient.invalidateQueries(
            makeQueryKey(listISPsQuery, {
              companySlug: companyName,
              networkUUID: network.UUID,
            }),
          );
          closeDrawer();
        },
        onError(err) {
          notify(`Failed to updateISP${getGraphQLErrorMessageOrEmpty(err)}`, {
            variant: 'negative',
          });
        },
        onSettled() {
          dialogProps.state.close();
        },
      },
    );
  }, [companyName, dialogProps.state, ispUUID, network.UUID, mutation, queryClient, closeDrawer]);

  const isp = useISP({ companySlug: companyName, networkUUID: network.UUID, UUID: ispUUID });

  return (
    <IsOperator>
      {view !== 'edit' && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.ISPEditDrawerPage, {
            companyName,
            networkSlug: network.slug,
            isp: isp.UUID,
          })}
          arrangement="leading-icon"
          icon="pencil"
          variant="secondary"
          internal
        >
          Edit
        </Button>
      )}
      <DropdownMenu>
        <DropdownMenuButton
          arrangement="hidden-label"
          icon="overflow-horizontal"
          variant="secondary"
          internal
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onSelect={dialogProps.openFromMenu} icon="trash-can" internal>
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Dialog state={dialogProps.state} preset="narrow">
        <form onSubmit={onDelete}>
          <DialogHeader icon="trash-can" heading={`Delete ${isp.provider?.name}`} />
          <DialogContent gutter="all">
            <VStack spacing={space(12)}>
              <Alert
                icon="attention"
                variant="negative"
                heading="Deleting an ISP is permanent"
                copy="All ISP information will be deleted and all devices will be disconnected that are currently utilizing this ISP."
              />
              <Body>
                Are you sure you want to delete <Text weight="bold">{isp.provider?.name}</Text> as
                an ISP?
              </Body>
            </VStack>
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button variant="secondary" onClick={dialogProps.state.close}>
                  Cancel
                </Button>
                <Button variant="destructive" type="submit">
                  Delete
                </Button>
              </>
            }
          />
        </form>
      </Dialog>
    </IsOperator>
  );
}

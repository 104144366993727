import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNGroupConfigEdit.page.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNGroupCreate.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNGroupMember.page.tsx";
import PagefilesImport3 from "/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNGroupRoutesAdd.page.tsx";
import PagefilesImport4 from "/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNMemberAdd.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/dashboard/src/routes/drawers/autoVPN/AutoVPNMemberEdit.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/dashboard/src/routes/drawers/connect/BulkAddLocations.page.tsx";
import PagefilesImport7 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/Controller.page.tsx";
import PagefilesImport8 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/ISPDetails.page.tsx";
import PagefilesImport9 from "/vercel/path0/apps/dashboard/src/routes/drawers/operator/companies/CompanySummary.page.tsx";
import PagefilesImport10 from "/vercel/path0/apps/dashboard/src/routes/drawers/operator/firmwareBulkUpgrader/FirmwareBulkUpgradeDetails.page.tsx";
import PagefilesImport11 from "/vercel/path0/apps/dashboard/src/routes/drawers/operator/nos/NosFeatureDetail.page.tsx";
import PagefilesImport12 from "/vercel/path0/apps/dashboard/src/routes/drawers/operator/nos/NosVersionDetail.page.tsx";
import PagefilesImport13 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/floor_plans/FloorPlanCreate.page.tsx";
import PagefilesImport14 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/floor_plans/FloorPlanEdit.page.tsx";
import PagefilesImport15 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/AddRackDrawer.page.tsx";
import PagefilesImport16 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/AddRackNote.page.tsx";
import PagefilesImport17 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/AssignHardwareToNetwork.page.tsx";
import PagefilesImport18 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/AttachDeviceDrawer.page.tsx";
import PagefilesImport19 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/EditRackDrawer.page.tsx";
import PagefilesImport20 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/EditRackMount.page.tsx";
import PagefilesImport21 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/design/rack_elevation/EditRackNote.page.tsx";
import PagefilesImport22 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityCategoryRuleCreate.page.tsx";
import PagefilesImport23 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityCategoryRuleEdit.page.tsx";
import PagefilesImport24 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityCategoryRuleRemove.page.tsx";
import PagefilesImport25 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityDomainInspector.page.tsx";
import PagefilesImport26 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityEnable.page.tsx";
import PagefilesImport27 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityRuleCreate.page.tsx";
import PagefilesImport28 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/dns_security/DNSSecurityRuleEdit.page.tsx";
import PagefilesImport29 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/one_to_one_nat/CreateOneToOneNATRule.page.tsx";
import PagefilesImport30 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/one_to_one_nat/EditOneToOneNATRule.page.tsx";
import PagefilesImport31 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/port_forwarding/CreatePortForwardingRule.page.tsx";
import PagefilesImport32 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/port_forwarding/EditPortForwardingRule.page.tsx";
import PagefilesImport33 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/port_forwarding/NATPortForwardingRuleCreate.page.tsx";
import PagefilesImport34 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/port_forwarding/NATPortForwardingRuleEdit.page.tsx";
import PagefilesImport35 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/rate_limiting/CreateRateLimitRule.page.tsx";
import PagefilesImport36 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/rate_limiting/EditRateLimitRule.page.tsx";
import PagefilesImport37 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/rules/CreateFirewallRule.page.tsx";
import PagefilesImport38 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/firewall/rules/EditFirewallRule.page.tsx";
import PagefilesImport39 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointBatchEdit.page.tsx";
import PagefilesImport40 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointClientsSummary.page.tsx";
import PagefilesImport41 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointCreate.page.tsx";
import PagefilesImport42 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointEditDrawer.page.tsx";
import PagefilesImport43 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointSummary.page.tsx";
import PagefilesImport44 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/AccessPointsBulkCreate.page.tsx";
import PagefilesImport45 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/BulkUploadLegacyCSV.page.tsx";
import PagefilesImport46 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/access_points/LegacyAccessPointSummary.page.tsx";
import PagefilesImport47 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/power_distrubution_units/OutletDetail.page.tsx";
import PagefilesImport48 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/power_distrubution_units/PowerDistributionUnitCreate.page.tsx";
import PagefilesImport49 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/power_distrubution_units/PowerDistributionUnitEdit.page.tsx";
import PagefilesImport50 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/power_distrubution_units/PowerDistributionUnitSummary.page.tsx";
import PagefilesImport51 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/HighAvailabilityEdit.page.tsx";
import PagefilesImport52 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityApplianceCreateDrawer.page.tsx";
import PagefilesImport53 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityApplianceEditDrawer.page.tsx";
import PagefilesImport54 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityAppliancePortDetail.page.tsx";
import PagefilesImport55 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityAppliancePortEditDrawer.page.tsx";
import PagefilesImport56 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/security_appliances/SecurityApplianceSummary.page.tsx";
import PagefilesImport57 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/LegacySwitchSummary.page.tsx";
import PagefilesImport58 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/PortBatchEdit.page.tsx";
import PagefilesImport59 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchBulkCreate.page.tsx";
import PagefilesImport60 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchCreate.page.tsx";
import PagefilesImport61 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchEdit.page.tsx";
import PagefilesImport62 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchPortDetail.page.tsx";
import PagefilesImport63 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchPortEdit.page.tsx";
import PagefilesImport64 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/switches/SwitchSummary.page.tsx";
import PagefilesImport65 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/AddClient.page.tsx";
import PagefilesImport66 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientRename.page.tsx";
import PagefilesImport67 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientRename2.page.tsx";
import PagefilesImport68 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientSummary.page.tsx";
import PagefilesImport69 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientSummary2.page.tsx";
import PagefilesImport70 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/ClientsList.page.tsx";
import PagefilesImport71 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/events/EventLogSummary.page.tsx";
import PagefilesImport72 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/events/EventSummary.page.tsx";
import PagefilesImport73 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/events/NetworkMutationSummary.page.tsx";
import PagefilesImport74 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPAddDrawer.page.tsx";
import PagefilesImport75 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPDrawer.page.tsx";
import PagefilesImport76 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPEditDrawer.page.tsx";
import PagefilesImport77 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPEventDrawer.page.tsx";
import PagefilesImport78 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/isps/ISPMoveDrawer.page.tsx";
import PagefilesImport79 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/radius_profiles/RADIUSProfileCreate.page.tsx";
import PagefilesImport80 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/radius_profiles/RADIUSProfileEdit.page.tsx";
import PagefilesImport81 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/PolicyRoutingRuleCreate.page.tsx";
import PagefilesImport82 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/PolicyRoutingRuleEdit.page.tsx";
import PagefilesImport83 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/StaticRouteCreate.page.tsx";
import PagefilesImport84 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/routing/StaticRouteEdit.page.tsx";
import PagefilesImport85 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/LegacyVLANSummary.page.tsx";
import PagefilesImport86 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/VLANDrawer.page.tsx";
import PagefilesImport87 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/VLANEdit.page.tsx";
import PagefilesImport88 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/VLANsAdd.page.tsx";
import PagefilesImport89 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/ipsec/CreateIPSecTunnel.page.tsx";
import PagefilesImport90 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/ipsec/EditIPSecTunnel.page.tsx";
import PagefilesImport91 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/Client.page.tsx";
import PagefilesImport92 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/CreateClient.page.tsx";
import PagefilesImport93 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/CreateServer.page.tsx";
import PagefilesImport94 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/UpdateServer.page.tsx";
import PagefilesImport95 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/hotspot20/Hotspot20Add.page.tsx";
import PagefilesImport96 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/hotspot20/Hotspot20Edit.page.tsx";
import PagefilesImport97 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/hotspot20/Hotspot20NAIRealmEdit.page.tsx";
import PagefilesImport98 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/radio_profiles/RadioProfileCreate.page.tsx";
import PagefilesImport99 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/radio_profiles/RadioProfileEdit.page.tsx";
import PagefilesImport100 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/:egacySSIDRemove.page.tsx";
import PagefilesImport101 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/LegacySSIDCreate.page.tsx";
import PagefilesImport102 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/LegacySSIDEdit.page.tsx";
import PagefilesImport103 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/SSIDCreate.page.tsx";
import PagefilesImport104 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/SSIDDrawer.page.tsx";
import PagefilesImport105 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/wireless/ssids/SSIDEdit.page.tsx";
import PagefilesImport106 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/users/AddUser.page.tsx";
import PagefilesImport107 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/users/EditUser.page.tsx";
import PagefilesImport108 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/users/EditUserRoles.page.tsx";
import PagefilesImport109 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/users/UserDrawer.page.tsx";
import PagefilesImport110 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/insights/clients/connection_events/ConnectionEventDetail.page.tsx";
import PagefilesImport111 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/static_clients/VLANStaticIPCreate.page.tsx";
import PagefilesImport112 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/static_clients/VLANStaticIPEdit.page.tsx";
import PagefilesImport113 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/network_wide/vlans/static_clients/VLANStaticIPRemove.page.tsx";
import PagefilesImport114 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/legacy/VPNClientConfigSummary.page.tsx";
import PagefilesImport115 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/legacy/VPNClientSummary.page.tsx";
import PagefilesImport116 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/network/notifications/alerts/AlertEdit.page.tsx";
import PagefilesImport117 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/network/notifications/alerts/AlertsAdd.page.tsx";
import PagefilesImport118 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/network/notifications/reports/ReportEdit.page.tsx";
import PagefilesImport119 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/network/notifications/reports/ReportsAdd.page.tsx";
import PagefilesImport120 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/receivers/ReceiverEdit.page.tsx";
import PagefilesImport121 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/receivers/ReceiversAdd.page.tsx";
import PagefilesImport122 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/services/ServiceEdit.page.tsx";
import PagefilesImport123 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/services/ServicesAdd.page.tsx";
import PagefilesImport124 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/webhooks/WebhookEdit.page.tsx";
import PagefilesImport125 from "/vercel/path0/apps/dashboard/src/routes/drawers/settings/org/integrations/webhooks/WebhooksAdd.page.tsx";
import PagefilesImport126 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/secure_tunnels/vpn/legacy/onboarding/AddClientForm.page.tsx";

const routes = [
{path: "/operator/companies/:slug",element: React.createElement(PagefilesImport9)},
{path: "/operator/firmware-bulk-upgrader/upgrades/:uuid",element: React.createElement(PagefilesImport10)},
{path: "/operator/nos/features/:uuid",element: React.createElement(PagefilesImport11)},
{path: "/operator/nos/versions/:nosVersionID",element: React.createElement(PagefilesImport12)},
{path: "/org/:companyName/auto-vpn/groups/:groupUUID/members/:memberUUID",element: React.createElement(PagefilesImport2)},
{path: "/org/:companyName/auto-vpn/groups/add",element: React.createElement(PagefilesImport1)},
{path: "/org/:companyName/connect/locations/add",element: React.createElement(PagefilesImport6)},
{path: "/org/:companyName/controller/:controllerName/clients/:macAddress",element: React.createElement(PagefilesImport68)},
{path: "/org/:companyName/controller/:controllerName/clients/:macAddress/rename",element: React.createElement(PagefilesImport66)},
{path: "/org/:companyName/controller/:controllerName/clients/add",element: React.createElement(PagefilesImport65)},
{path: "/org/:companyName/controller/:controllerName/details",element: React.createElement(PagefilesImport7)},
{path: "/org/:companyName/controller/:controllerName/devices/:deviceName",element: React.createElement(PagefilesImport46)},
{path: "/org/:companyName/controller/:controllerName/devices/:deviceName/clients",element: React.createElement(PagefilesImport70)},
{path: "/org/:companyName/controller/:controllerName/dns-security/enable",element: React.createElement(PagefilesImport26)},
{path: "/org/:companyName/controller/:controllerName/dns-security/inspect-domain",element: React.createElement(PagefilesImport25)},
{path: "/org/:companyName/controller/:controllerName/dns-security/rules/:id",element: React.createElement(PagefilesImport23)},
{path: "/org/:companyName/controller/:controllerName/dns-security/rules/:id/remove",element: React.createElement(PagefilesImport24)},
{path: "/org/:companyName/controller/:controllerName/dns-security/rules/create",element: React.createElement(PagefilesImport22)},
{path: "/org/:companyName/controller/:controllerName/events/:eventSid",element: React.createElement(PagefilesImport72)},
{path: "/org/:companyName/controller/:controllerName/isps/:ispSid",element: React.createElement(PagefilesImport8)},
{path: "/org/:companyName/controller/:controllerName/port-forwarding/:ruleId/edit",element: React.createElement(PagefilesImport34)},
{path: "/org/:companyName/controller/:controllerName/port-forwarding/create",element: React.createElement(PagefilesImport33)},
{path: "/org/:companyName/controller/:controllerName/ssids/:id/edit",element: React.createElement(PagefilesImport102)},
{path: "/org/:companyName/controller/:controllerName/ssids/:id/remove",element: React.createElement(PagefilesImport100)},
{path: "/org/:companyName/controller/:controllerName/ssids/create",element: React.createElement(PagefilesImport101)},
{path: "/org/:companyName/controller/:controllerName/switches/:id",element: React.createElement(PagefilesImport57)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName",element: React.createElement(PagefilesImport85)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/static/:macAddress/edit",element: React.createElement(PagefilesImport112)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/static/:macAddress/remove",element: React.createElement(PagefilesImport113)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/static/create",element: React.createElement(PagefilesImport111)},
{path: "/org/:companyName/controller/:controllerName/vpn/:sid",element: React.createElement(PagefilesImport115)},
{path: "/org/:companyName/controller/:controllerName/vpn/:sid/config",element: React.createElement(PagefilesImport114)},
{path: "/org/:companyName/controller/:controllerName/vpn/onboarding/add",element: React.createElement(PagefilesImport126)},
{path: "/org/:companyName/network/:networkSlug/connection-events/:deviceType/:identifier/:timestamp",element: React.createElement(PagefilesImport110)},
{path: "/org/:companyName/network/:networkSlug/design/floor-plans/:floorPlanUUID",element: React.createElement(PagefilesImport14)},
{path: "/org/:companyName/network/:networkSlug/design/floor-plans/create",element: React.createElement(PagefilesImport13)},
{path: "/org/:companyName/network/:networkSlug/devices/:uuid",element: React.createElement(PagefilesImport40)},
{path: "/org/:companyName/network/:networkSlug/dns-security/:ruleUUID",element: React.createElement(PagefilesImport28)},
{path: "/org/:companyName/network/:networkSlug/dns-security/create",element: React.createElement(PagefilesImport27)},
{path: "/org/:companyName/network/:networkSlug/firewall-rules/:ruleUUID",element: React.createElement(PagefilesImport38)},
{path: "/org/:companyName/network/:networkSlug/firewall-rules/create",element: React.createElement(PagefilesImport37)},
{path: "/org/:companyName/network/:networkSlug/firewall-rules/port-forwarding/:ruleUUID",element: React.createElement(PagefilesImport32)},
{path: "/org/:companyName/network/:networkSlug/firewall/one-to-one-nat/:ruleUUID",element: React.createElement(PagefilesImport30)},
{path: "/org/:companyName/network/:networkSlug/firewall/one-to-one-nat/create",element: React.createElement(PagefilesImport29)},
{path: "/org/:companyName/network/:networkSlug/firewall/rate-limiting/:ruleUUID",element: React.createElement(PagefilesImport36)},
{path: "/org/:companyName/network/:networkSlug/firewall/rate-limiting/create",element: React.createElement(PagefilesImport35)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/:id/edit",element: React.createElement(PagefilesImport42)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/:uuid/:tab",element: React.createElement(PagefilesImport43)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/batch-edit/:accessPointUUIDs",element: React.createElement(PagefilesImport39)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/bulk-upload",element: React.createElement(PagefilesImport45)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/create",element: React.createElement(PagefilesImport41)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/create-bulk",element: React.createElement(PagefilesImport44)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid",element: React.createElement(PagefilesImport50)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/edit",element: React.createElement(PagefilesImport49)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/outlets/:phyInterfaceUUID",element: React.createElement(PagefilesImport47)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/create",element: React.createElement(PagefilesImport48)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID",element: React.createElement(PagefilesImport62)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID/edit",element: React.createElement(PagefilesImport63)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/create-bulk",element: React.createElement(PagefilesImport59)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress",element: React.createElement(PagefilesImport69)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress/rename",element: React.createElement(PagefilesImport67)},
{path: "/org/:companyName/network/:networkSlug/insights/events/:uuid",element: React.createElement(PagefilesImport71)},
{path: "/org/:companyName/network/:networkSlug/ipsec-tunnels/:IPSecUUID",element: React.createElement(PagefilesImport90)},
{path: "/org/:companyName/network/:networkSlug/ipsec-tunnels/create",element: React.createElement(PagefilesImport89)},
{path: "/org/:companyName/network/:networkSlug/logs/mutations/:mutationAuditLogEntryUUID",element: React.createElement(PagefilesImport73)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:isp",element: React.createElement(PagefilesImport75)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:isp/edit",element: React.createElement(PagefilesImport76)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/events/:eventUUID",element: React.createElement(PagefilesImport77)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/add",element: React.createElement(PagefilesImport74)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/move",element: React.createElement(PagefilesImport78)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid",element: React.createElement(PagefilesImport86)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid/edit",element: React.createElement(PagefilesImport87)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans/add",element: React.createElement(PagefilesImport88)},
{path: "/org/:companyName/network/:networkSlug/port-forwarding/create",element: React.createElement(PagefilesImport31)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/attached-device/:deviceUUID",element: React.createElement(PagefilesImport20)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/attached-device/add",element: React.createElement(PagefilesImport18)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/notes/:noteIndexStart",element: React.createElement(PagefilesImport21)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/notes/add",element: React.createElement(PagefilesImport16)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/:uuid/edit",element: React.createElement(PagefilesImport19)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/add",element: React.createElement(PagefilesImport15)},
{path: "/org/:companyName/network/:networkSlug/rack-elevations/attach-hardware",element: React.createElement(PagefilesImport17)},
{path: "/org/:companyName/network/:networkSlug/radius-profiles/:uuid",element: React.createElement(PagefilesImport80)},
{path: "/org/:companyName/network/:networkSlug/radius-profiles/create",element: React.createElement(PagefilesImport79)},
{path: "/org/:companyName/network/:networkSlug/routing/policy-routing/:ruleUUID",element: React.createElement(PagefilesImport82)},
{path: "/org/:companyName/network/:networkSlug/routing/policy-routing/create",element: React.createElement(PagefilesImport81)},
{path: "/org/:companyName/network/:networkSlug/routing/static-route/:routeUUID",element: React.createElement(PagefilesImport84)},
{path: "/org/:companyName/network/:networkSlug/routing/static-route/create",element: React.createElement(PagefilesImport83)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/vpn/:serverUUID",element: React.createElement(PagefilesImport94)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/vpn/create",element: React.createElement(PagefilesImport93)},
{path: "/org/:companyName/network/:networkSlug/security-appliances/:uuid/summary",element: React.createElement(PagefilesImport56)},
{path: "/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/edit/general",element: React.createElement(PagefilesImport53)},
{path: "/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/edit/high-availability",element: React.createElement(PagefilesImport51)},
{path: "/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/ports/:phyInterfaceUUID",element: React.createElement(PagefilesImport54)},
{path: "/org/:companyName/network/:networkSlug/security-appliances/:virtualDeviceUUID/ports/:phyInterfaceUUID/edit",element: React.createElement(PagefilesImport55)},
{path: "/org/:companyName/network/:networkSlug/security-appliances/add",element: React.createElement(PagefilesImport52)},
{path: "/org/:companyName/network/:networkSlug/switches/:id/edit",element: React.createElement(PagefilesImport61)},
{path: "/org/:companyName/network/:networkSlug/switches/:uuid",element: React.createElement(PagefilesImport64)},
{path: "/org/:companyName/network/:networkSlug/switches/:virtualDeviceUUID/ports/batch-edit/:phyInterfaceUUIDs",element: React.createElement(PagefilesImport58)},
{path: "/org/:companyName/network/:networkSlug/switches/create",element: React.createElement(PagefilesImport60)},
{path: "/org/:companyName/network/:networkSlug/vpn/:serverUUID/clients/:clientUUID/:tab",element: React.createElement(PagefilesImport91)},
{path: "/org/:companyName/network/:networkSlug/vpn/:serverUUID/clients/create",element: React.createElement(PagefilesImport92)},
{path: "/org/:companyName/network/:networkSlug/wireless/hotspot2.0/:uuid/edit",element: React.createElement(PagefilesImport96)},
{path: "/org/:companyName/network/:networkSlug/wireless/hotspot2.0/:uuid/nai-realm/edit",element: React.createElement(PagefilesImport97)},
{path: "/org/:companyName/network/:networkSlug/wireless/hotspot2.0/add",element: React.createElement(PagefilesImport95)},
{path: "/org/:companyName/network/:networkSlug/wireless/radio-profiles/:uuid",element: React.createElement(PagefilesImport99)},
{path: "/org/:companyName/network/:networkSlug/wireless/radio-profiles/create",element: React.createElement(PagefilesImport98)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:uuid",element: React.createElement(PagefilesImport104)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:uuid/edit",element: React.createElement(PagefilesImport105)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/create",element: React.createElement(PagefilesImport103)},
{path: "/org/:companyName/network/auto-vpn/groups/:groupUUID/edit",element: React.createElement(PagefilesImport0)},
{path: "/org/:companyName/network/auto-vpn/groups/:groupUUID/members/:memberUUID/edit",element: React.createElement(PagefilesImport5)},
{path: "/org/:companyName/network/auto-vpn/groups/:groupUUID/members/add",element: React.createElement(PagefilesImport4)},
{path: "/org/:companyName/network/auto-vpn/groups/:groupUUID/routes",element: React.createElement(PagefilesImport3)},
{path: "/org/:companyName/settings/integrations/receivers/:uuid/edit",element: React.createElement(PagefilesImport120)},
{path: "/org/:companyName/settings/integrations/services/service/edit",element: React.createElement(PagefilesImport122)},
{path: "/org/:companyName/settings/integrations/webhooks/:uuid/edit",element: React.createElement(PagefilesImport124)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/alert/:uuid/edit",element: React.createElement(PagefilesImport116)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/alerts/add",element: React.createElement(PagefilesImport117)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/reports/add",element: React.createElement(PagefilesImport119)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/reports/edit",element: React.createElement(PagefilesImport118)},
{path: "/org/:companyName/settings/org/integrations/receivers/add",element: React.createElement(PagefilesImport121)},
{path: "/org/:companyName/settings/org/integrations/services/add",element: React.createElement(PagefilesImport123)},
{path: "/org/:companyName/settings/org/integrations/webhooks/add",element: React.createElement(PagefilesImport125)},
{path: "/org/:companyName/settings/org/users/:uuid",element: React.createElement(PagefilesImport109)},
{path: "/org/:companyName/settings/org/users/:uuid/edit",element: React.createElement(PagefilesImport107)},
{path: "/org/:companyName/settings/org/users/:uuid/roles",element: React.createElement(PagefilesImport108)},
{path: "/org/:companyName/settings/org/users/add",element: React.createElement(PagefilesImport106)},
];

export default routes;
import { Box, Column, Columns, Sections, Skeleton, space, styled, VStack } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import * as d3 from 'd3';
import { Suspense, useCallback, useMemo } from 'react';

import type { TimeSeries } from '../../../utils/chart_utils';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { TrafficDirection } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import {
  calculateXTickValues,
  calculateXTickValuesSwitches,
  dataRateBytesValueFormatter,
  dataRateValueFormatter,
  getDurationSeconds,
  getStep,
  packetRateValueFormatter,
  packetValueFormatter,
  switchMetricsToSeries,
  switchPortMetricsToSeries,
  tickLabelProps,
  useChartColorsById,
} from '../../../utils/chart_utils';
import TimeSeriesChart from '../../Charts/TimeSeriesChart';
import { StackedSkeletons } from '../../Placeholders/AppLoadingFallback';
import { DetailsWidget, STPWidget } from './insights';
import { switchPortMetricsQuery } from './utils';

const BOOT_INFO_COUNT = 5;

type InsightsProps = {
  serialNumber: string;
};

const InsightsQuery = graphql(`
  query InsightsQuery($serialNumber: String!, $count: Int) {
    hardwareDevice(serialNumber: $serialNumber) {
      __typename
      serialNumber
      macAddress
      deviceType
      deviceModel
      tunnelIPAddress
      isConnectedToBackend
      disconnectedFromBackendAt
      bootHistory(count: $count) {
        bootCount
        buildName
        rebootReason
        createdAt
      }
      ... on SwitchHardwareDevice {
        stpInfo {
          isRootBridge
          rootBridgeMACAddress
        }
        ipAddress
      }
      virtualDevice {
        __typename
        UUID
        label
        deviceType
        deviceModel
        description
        networkUUID
        nosVersion {
          id
          version
          buildName
        }
        pendingNosVersion {
          nosVersion {
            version
          }
          scheduledAt
        }
        ... on SwitchVirtualDevice {
          switchProfile {
            stpBridgePriority
          }
        }
      }
    }
  }
`);

const SwitchesRootQuery = graphql(`
  query SwitchesRootQuery($networkUUID: UUID!) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {
      UUID
      hardwareDevice {
        __typename
        serialNumber
        ... on SwitchHardwareDevice {
          stpInfo {
            isRootBridge
          }
        }
      }
    }
  }
`);

const SwitchMetricsQuery = graphql(`
  query SwitchMetricsQuery(
    $input: MetricsSerialNumberDirectionInput!
    $filter: MetricsFilterInput!
  ) {
    switchThroughputPerPort(input: $input, filter: $filter) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        ... on SwitchPortMetricsValue {
          port
          direction
        }
      }
    }
    switchDiscardRatesPerPort(input: $input, filter: $filter) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        ... on SwitchPortMetricsValue {
          port
          direction
        }
      }
    }
    switchErrorRatesPerPort(input: $input, filter: $filter) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        ... on SwitchPortMetricsValue {
          port
          direction
        }
      }
    }
  }
`);

export const SwitchPortNumbersQuery = graphql(`
  query SwitchPortNumbers($virtualDeviceUUID: UUID!) {
    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {
      portNumber
    }
  }
`);

const SwitchMetricGraphsFallbackContainer = styled('div', {
  padding: '$16 $20',
});
const switchThresholdFn = (series: TimeSeries) =>
  series.data.some((d) => d.value && d.value > 1000000);

function SwitchMetricGraphs({
  serialNumber,
  duration,
  step,
  currentTimePeriod,
  seriesIds,
}: {
  serialNumber: string;
  duration: number;
  step: number;
  currentTimePeriod: string;
  seriesIds: string[];
}) {
  const { data: metricsData } = useGraphQL(SwitchMetricsQuery, {
    input: {
      serialNumber,
      directions: [TrafficDirection.Rx, TrafficDirection.Tx],
    },
    filter: {
      durationSeconds: duration,
      stepSeconds: step,
    },
  });

  expectDefinedOrThrow(metricsData, new ResourceNotFoundError('Unable to load metrics'));

  const throughputSeries = useMemo(
    () => switchMetricsToSeries(metricsData?.switchThroughputPerPort?.values),
    [metricsData?.switchThroughputPerPort?.values],
  );
  const errorSeries = useMemo(
    () => switchMetricsToSeries(metricsData?.switchErrorRatesPerPort?.values),
    [metricsData?.switchErrorRatesPerPort?.values],
  );
  const discardSeries = useMemo(
    () => switchMetricsToSeries(metricsData?.switchDiscardRatesPerPort?.values),
    [metricsData?.switchDiscardRatesPerPort?.values],
  );
  const xTickValues = useMemo(
    () => calculateXTickValuesSwitches(throughputSeries.rx),
    [throughputSeries.rx],
  );

  const selectedSeries = useSearchParamsState<string>('series', seriesIds.join(','))[0];
  const selectedSeriesArr = selectedSeries?.split(',').filter((s) => s !== '');
  const filterSelectedSeries = useCallback(
    (s: TimeSeries) => selectedSeriesArr?.includes(s.series_id),
    [selectedSeriesArr],
  );

  const rxThroughputSeries = useMemo(
    () => throughputSeries.rx.filter(filterSelectedSeries),
    [filterSelectedSeries, throughputSeries.rx],
  );
  const txThroughputSeries = useMemo(
    () => throughputSeries.tx.filter(filterSelectedSeries),
    [filterSelectedSeries, throughputSeries.tx],
  );
  const rxErrorSeries = useMemo(
    () => errorSeries.rx.filter(filterSelectedSeries),
    [filterSelectedSeries, errorSeries.rx],
  );
  const txErrorSeries = useMemo(
    () => errorSeries.tx.filter(filterSelectedSeries),
    [filterSelectedSeries, errorSeries.tx],
  );
  const rxDiscardSeries = useMemo(
    () => discardSeries.rx.filter(filterSelectedSeries),
    [filterSelectedSeries, discardSeries.rx],
  );
  const txDiscardSeries = useMemo(
    () => discardSeries.tx.filter(filterSelectedSeries),
    [filterSelectedSeries, discardSeries.tx],
  );

  const { chartColors, colorMap } = useChartColorsById(seriesIds);

  return (
    <>
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={rxThroughputSeries}
        seriesType="area"
        seriesLabel="switch-insights-rx-throughput"
        showSeriesGlyphs={false}
        thresholdFn={switchThresholdFn}
        timePeriod={currentTimePeriod}
        title="Switch RX throughput"
        tooltipBody="The rate of bits received by the switch."
        valueFormatter={dataRateValueFormatter}
        xTickValues={xTickValues}
        tickLabelProps={tickLabelProps}
        showLegend={false}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={txThroughputSeries}
        seriesType="area"
        seriesLabel="switch-insights-tx-throughput"
        showSeriesGlyphs={false}
        thresholdFn={switchThresholdFn}
        timePeriod={currentTimePeriod}
        title="Switch TX throughput"
        tooltipBody="The rate of bits sent by the switch."
        valueFormatter={dataRateValueFormatter}
        xTickValues={xTickValues}
        tickLabelProps={tickLabelProps}
        showLegend={false}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={rxErrorSeries}
        seriesType="area"
        seriesLabel="switch-insights-rx-error"
        showSeriesGlyphs={false}
        thresholdFn={switchThresholdFn}
        timePeriod={currentTimePeriod}
        title="Switch RX error rates"
        tooltipBody="The rate of error packets received by the switch."
        valueFormatter={packetValueFormatter}
        xTickValues={xTickValues}
        tickLabelProps={tickLabelProps}
        showLegend={false}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={txErrorSeries}
        seriesType="area"
        seriesLabel="switch-insights-tx-error"
        showSeriesGlyphs={false}
        thresholdFn={switchThresholdFn}
        timePeriod={currentTimePeriod}
        title="Switch TX error rates"
        tooltipBody="The rate of error packets sent by the switch."
        valueFormatter={packetValueFormatter}
        xTickValues={xTickValues}
        tickLabelProps={tickLabelProps}
        showLegend={false}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={rxDiscardSeries}
        seriesType="area"
        seriesLabel="switch-insights-rx-discard"
        showSeriesGlyphs={false}
        thresholdFn={switchThresholdFn}
        timePeriod={currentTimePeriod}
        title="Switch RX discard rates"
        tooltipBody="The rate of discarded packets received by the switch."
        valueFormatter={packetValueFormatter}
        xTickValues={xTickValues}
        tickLabelProps={tickLabelProps}
        showLegend={false}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={txDiscardSeries}
        seriesType="area"
        seriesLabel="switch-insights-tx-discard"
        showSeriesGlyphs={false}
        thresholdFn={switchThresholdFn}
        timePeriod={currentTimePeriod}
        title="Switch TX discard rates"
        tooltipBody="The rate of packets discarded by the switch."
        valueFormatter={packetValueFormatter}
        xTickValues={xTickValues}
        tickLabelProps={tickLabelProps}
        showLegend={false}
        chartColors={chartColors}
        colorMap={colorMap}
      />
    </>
  );
}

function SwitchMetricGraphsFromStat({
  virtualDeviceUUID,
  duration,
  step,
  currentTimePeriod,
  seriesIds,
}: {
  virtualDeviceUUID: string;
  duration: number;
  step: number;
  currentTimePeriod: string;
  seriesIds: string[];
}) {
  const { data: metricsData } = useGraphQL(switchPortMetricsQuery, {
    virtualDeviceUUID,
    filter: {
      durationSeconds: duration,
      stepSeconds: step,
    },
  });

  expectDefinedOrThrow(metricsData, new ResourceNotFoundError('Unable to load metrics'));

  const series = useMemo(
    () => switchPortMetricsToSeries(metricsData?.switchPortMetricsRate?.values),
    [metricsData?.switchPortMetricsRate?.values],
  );

  const xTickValues = useMemo(() => calculateXTickValues(duration, 3), [duration]);

  const selectedSeries = useSearchParamsState<string>('series', seriesIds.join(','))[0];
  const selectedSeriesArr = selectedSeries?.split(',').filter((s) => s !== '');
  const filterSelectedSeries = useCallback(
    (s: TimeSeries) => selectedSeriesArr?.includes(s.series_id),
    [selectedSeriesArr],
  );

  const rxThroughputSeries = useMemo(
    () => series.totalRxBytes.filter(filterSelectedSeries),
    [filterSelectedSeries, series.totalRxBytes],
  );
  const txThroughputSeries = useMemo(
    () => series.totalTxBytes.filter(filterSelectedSeries),
    [filterSelectedSeries, series.totalTxBytes],
  );
  const rxErrorSeries = useMemo(
    () => series.rxErr.filter(filterSelectedSeries),
    [filterSelectedSeries, series.rxErr],
  );
  const txErrorSeries = useMemo(
    () => series.txErr.filter(filterSelectedSeries),
    [filterSelectedSeries, series.txErr],
  );
  const rxBroadcastSeries = useMemo(
    () => series.rxBroadcasts.filter(filterSelectedSeries),
    [filterSelectedSeries, series.rxBroadcasts],
  );
  const txBroadcastSeries = useMemo(
    () => series.txBroadcasts.filter(filterSelectedSeries),
    [filterSelectedSeries, series.txBroadcasts],
  );
  const rxMulticastSeries = useMemo(
    () => series.rxMulticasts.filter(filterSelectedSeries),
    [filterSelectedSeries, series.rxMulticasts],
  );
  const txMulticastSeries = useMemo(
    () => series.txMulticasts.filter(filterSelectedSeries),
    [filterSelectedSeries, series.txMulticasts],
  );

  const { chartColors, colorMap } = useChartColorsById(seriesIds);

  return (
    <>
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={rxThroughputSeries}
        seriesType="area"
        seriesLabel="switch-insights-rx-throughput"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Total received rate"
        tooltipBody="The rate of data received (rx) through a port."
        valueFormatter={dataRateBytesValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={txThroughputSeries}
        seriesType="area"
        seriesLabel="switch-insights-tx-throughput"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Total transmitted rate"
        tooltipBody="The rate of data sent (tx) through a port."
        valueFormatter={dataRateBytesValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={rxErrorSeries}
        seriesType="area"
        seriesLabel="switch-insights-rx-error"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Receive error rate"
        tooltipBody="The number of packets per second (pps) that encountered errors being received."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={txErrorSeries}
        seriesType="area"
        seriesLabel="switch-insights-tx-error"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Transmit error rate"
        tooltipBody="The number of packets per second (pps) that encountered errors being sent."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={rxBroadcastSeries}
        seriesType="area"
        seriesLabel="switch-insights-rx-broadcast"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Receive broadcast rate"
        tooltipBody="The number of broadcast packets per second (pps) received (rx) through a port."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={txBroadcastSeries}
        seriesType="area"
        seriesLabel="switch-insights-tx-broadcast"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Transmit broadcast rate"
        tooltipBody="The number of broadcast packets per second (pps) sent (tx) through a port."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={rxMulticastSeries}
        seriesType="area"
        seriesLabel="switch-insights-rx-multicast"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Receive multicast rate"
        tooltipBody="The number of multicast packets per second (pps) received (rx) through a port."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
        chartColors={chartColors}
        colorMap={colorMap}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={txMulticastSeries}
        seriesType="area"
        seriesLabel="switch-insights-tx-multicast"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Transmit multicast rate"
        tooltipBody="The number of multicast packets per second (pps) sent (tx) through a port."
        valueFormatter={packetRateValueFormatter}
        tickLabelProps={tickLabelProps}
        xTickValues={xTickValues}
        chartColors={chartColors}
        colorMap={colorMap}
      />
    </>
  );
}

function SwitchInsights({ serialNumber }: InsightsProps) {
  const network = useNetwork();
  const { uuid } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.SwitchDetailPage));
  const doesSwitchUseStat = useNosFeatureEnabled(NosFeature.SOSUsesStat);

  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const duration = useMemo(() => getDurationSeconds(currentTimePeriod), [currentTimePeriod]);
  const step = useMemo(() => getStep(currentTimePeriod), [currentTimePeriod]);

  const switchData = useGraphQL(InsightsQuery, { serialNumber, count: BOOT_INFO_COUNT }).data;
  expectDefinedOrThrow(switchData, new ResourceNotFoundError('Switch not found'));

  const switchesData = useGraphQL(SwitchesRootQuery, {
    networkUUID: network.UUID,
  }).data?.virtualDevicesForNetwork;
  expectDefinedOrThrow(switchesData, new ResourceNotFoundError('Unable to load switches'));

  const switchSeriesIds = useGraphQL(SwitchPortNumbersQuery, {
    virtualDeviceUUID: uuid,
  }).data?.phyInterfacesForVirtualDevice.map((d) => `Port ${d.portNumber}`);
  expectDefinedOrThrow(switchSeriesIds);

  return (
    <Columns template="object">
      <Column>
        <DetailsWidget
          hardwareDevice={switchData.hardwareDevice}
          virtualDevice={switchData.hardwareDevice.virtualDevice}
          relation="stacked"
        />
        <STPWidget switchData={switchData} switchesData={switchesData} />
      </Column>
      <Column>
        <Suspense
          fallback={
            <Box padding={{ x: space(16) }}>
              <VStack spacing={space(12)}>
                <Skeleton height={200} width="100%" radius={6} />
                <Skeleton height={200} width="100%" radius={6} />
                <Skeleton height={200} width="100%" radius={6} />
              </VStack>
            </Box>
          }
        >
          <Suspense
            fallback={
              <SwitchMetricGraphsFallbackContainer>
                <StackedSkeletons />
              </SwitchMetricGraphsFallbackContainer>
            }
          >
            <Sections>
              {(doesSwitchUseStat && switchData.hardwareDevice.virtualDevice && (
                <SwitchMetricGraphsFromStat
                  virtualDeviceUUID={switchData.hardwareDevice.virtualDevice.UUID}
                  duration={duration}
                  step={step}
                  currentTimePeriod={currentTimePeriod}
                  seriesIds={switchSeriesIds}
                />
              )) || (
                <SwitchMetricGraphs
                  serialNumber={serialNumber}
                  duration={duration}
                  step={step}
                  currentTimePeriod={currentTimePeriod}
                  seriesIds={switchSeriesIds}
                />
              )}
            </Sections>
          </Suspense>
        </Suspense>
      </Column>
    </Columns>
  );
}

export default SwitchInsights;

import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  space,
  styled,
  VStack,
} from '@meterup/atto';
import { Form } from 'formik';
import React from 'react';

import { DrawerFormSaveButton } from '../../../../../components/form_buttons';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';

const StyledForm = styled(Form, {
  display: 'contents',
});

export function NATPortForwardingConfirmChanges({ onCancel }: { onCancel: () => void }) {
  return (
    <Drawer>
      <StyledForm>
        <DrawerHeader
          icon="port-forward"
          heading="Confirm changes"
          onClose={useCloseDrawerCallback()}
        />
        <DrawerContent>
          <Alert
            icon="information"
            variant="alternative"
            heading="Changing network settings"
            copy={
              <VStack spacing={space(8)}>
                <div>
                  Changing this network setting will restart the network. All clients will lose
                  connectivity for approximately 30 seconds.
                </div>
                <div>Please confirm you would like to apply these changes now.</div>
              </VStack>
            }
          />
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={onCancel} variant="secondary" autoFocus>
                Cancel
              </Button>
              <DrawerFormSaveButton />
            </>
          }
        />
      </StyledForm>
    </Drawer>
  );
}

import { colors, darkThemeSelector, sizing, space, styled } from '@meterup/atto';
import { DataContext } from '@visx/xychart';
import { useContext, useId } from 'react';

import { BASE_CHART_HEIGHT } from './utils';

const ChartBackgroundColor = styled('rect', {
  fill: colors.bgNeutralLight,
  borderRadius: '40px',

  [darkThemeSelector]: {
    fill: colors.bgNeutralDark,
  },
});

export default function ChartBackground({
  height = BASE_CHART_HEIGHT,
  bottom,
  left,
}: {
  height?: number;
  bottom: number;
  left: number;
}) {
  const id = useId();
  const { width = 0 } = useContext(DataContext);
  const calcWidth = width - left - sizing.primary;
  return (
    <>
      <ChartBackgroundColor
        x={left}
        width={calcWidth}
        height={height - bottom}
        rx={space(6)}
        ry={space(6)}
      />
      <pattern id={id} x="0" y="0" width="8" height="8" patternUnits="userSpaceOnUse">
        <rect x="0" width="4" height="4" y="0" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="0" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="4" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="0" width="4" height="4" y="4" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
      </pattern>
      <rect
        x={left}
        width={calcWidth}
        height={height - bottom}
        fill={`url(#${id})`}
        rx={space(6)}
        ry={space(6)}
      />
    </>
  );
}

import { Section, SectionContent, SectionHeader, SummaryList } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { Duration } from 'luxon';

import { CopyableMonoOrNoValue } from '../../../../Devices/Insights';
import {
  formatCPULoad,
  formatMemoryUsageWithTotal,
  formatPoEInfo,
  switchVirtualDevicePoEInfoQuery,
  SystemInfoStatQuery,
} from '../../../../Devices/utils';

export function InspectorMetricsStats({ virtualDeviceUUID }: { virtualDeviceUUID: string }) {
  const sysInfo = useGraphQL(
    SystemInfoStatQuery,
    { uuid: virtualDeviceUUID },
    { useErrorBoundary: false, suspense: false },
  );

  const poeInfo = useGraphQL(
    switchVirtualDevicePoEInfoQuery,
    { uuid: virtualDeviceUUID },
    { useErrorBoundary: false, suspense: false },
  );

  return (
    <Section relation="stacked" size="x-small">
      <SectionHeader heading="Metrics" />
      <SectionContent gutter="all">
        <SummaryList
          gutter="none"
          size="x-small"
          pairs={[
            {
              label: 'Uptime',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy uptime"
                  value={
                    sysInfo.isError ||
                    !sysInfo.data ||
                    sysInfo.data?.systemInfoStatForVirtualDevice.length === 0
                      ? null
                      : Duration.fromISO(
                          sysInfo.data?.systemInfoStatForVirtualDevice[0].uptime,
                        ).toHuman({
                          unitDisplay: 'long',
                        })
                  }
                />
              ),
            },
            {
              label: 'CPU load',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy CPU load"
                  value={
                    sysInfo.isError ||
                    !sysInfo.data ||
                    sysInfo.data?.systemInfoStatForVirtualDevice.length === 0
                      ? null
                      : formatCPULoad(
                          sysInfo.data?.systemInfoStatForVirtualDevice[0].cpuUsedPercentage,
                        )
                  }
                />
              ),
            },
            {
              label: 'Memory available',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy memory available"
                  value={
                    sysInfo.isError ||
                    !sysInfo.data ||
                    sysInfo.data?.systemInfoStatForVirtualDevice.length === 0
                      ? null
                      : formatMemoryUsageWithTotal({
                          bytestTotal:
                            sysInfo.data?.systemInfoStatForVirtualDevice[0].memoryBytesTotal,
                          bytesAvailable:
                            sysInfo.data?.systemInfoStatForVirtualDevice[0].memoryBytesAvailable,
                        })
                  }
                />
              ),
            },
            ...(!poeInfo.isError &&
            poeInfo.data &&
            poeInfo.data?.virtualDevice.__typename === 'SwitchVirtualDevice'
              ? [
                  {
                    label: 'PoE power used',
                    value: (
                      <CopyableMonoOrNoValue
                        label="Copy PoE power used"
                        value={formatPoEInfo(poeInfo.data?.virtualDevice?.poeInfo)}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </SectionContent>
    </Section>
  );
}

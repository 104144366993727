import {
  Badge,
  Button,
  Column,
  Columns,
  EmptyState,
  HStack,
  Icon,
  PaneContent,
  Section,
  SectionContent,
  SectionHeader,
  Skeleton,
  space,
  SummaryList,
  Text,
  Tooltip,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { clientNameOrNull, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import * as d3 from 'd3';
import { Suspense, useMemo } from 'react';

import { useNetwork } from '../../hooks/useNetworkFromPath';
import {
  NosFeature,
  useNosFeatureEnabled,
  useNosFeaturesEnabled,
} from '../../hooks/useNosFeatures';
import { useIdentity } from '../../providers/IdentityDataProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import {
  clientMetricsV2ToSeries,
  dataRateValueFormatter,
  dataRateValueFormatterShort,
  getDurationSeconds,
  getStep,
  percentValueFormatter,
  snrValueFormatter,
  snrValueFormatterShort,
  usageValueFormatter,
  usageValueFormatterShort,
} from '../../utils/chart_utils';
import { zendeskFeedbackURL } from '../../utils/zendesk';
import TimeSeriesChart from '../Charts/TimeSeriesChart';
import { ClientHardwareWidget, WirelessConnectionWidget } from '../clients';
import {
  MetricBandFilter,
  statsFromAggregates,
  useAggregateMetricsAndConnectionEvents,
} from '../Metrics/utils';
import LANPingTestDialog from '../PingTest2/LANPingTestDialog';
import { vlansForPingQuery } from '../PingTest2/utils';
import { radioBandFromHuman } from '../Wireless/RadioProfiles/utils';
import { fallbackAccessPointHardwareDeviceQuery } from '../Wireless/utils';
import {
  clientChannelUtilizationMetricsToSeries,
  ClientChannelUtilizationQuery,
  healthVariantIcon,
  healthVariantText,
  useClientByMAC,
  WirelessClientMetricsByClientQuery,
} from './utils';

function WirelessStats({ macAddress }: { macAddress: string }) {
  const aggregateValues = useAggregateMetricsAndConnectionEvents(macAddress, 'client', 30 * 60);
  const aggregateStats = useMemo(
    () =>
      statsFromAggregates(aggregateValues, [
        'snr',
        'rxRate',
        'txRate',
        'usage',
        'connectionSuccess',
        'connectionTime',
      ]),
    [aggregateValues],
  );

  return (
    <Section relation="stacked">
      <SectionHeader
        heading={
          <HStack align="center" spacing={space(8)}>
            <Text>Heartbeat</Text>
            <HStack align="baseline" spacing={space(4)}>
              <Badge
                arrangement="leading-icon"
                ends="pill"
                icon={healthVariantIcon(aggregateStats.healthVariant)}
                size="small"
                variant={aggregateStats.healthVariant}
              >
                {healthVariantText(aggregateStats.healthVariant)}
              </Badge>
              <Tooltip asChild={false} contents="Aggregate statistics from the last 30 minutes.">
                <Icon icon="question" size={space(14)} />
              </Tooltip>
            </HStack>
          </HStack>
        }
      />
      <SectionContent gutter="all">
        <SummaryList direction="row" gutter="none" pairs={aggregateStats.items} />
      </SectionContent>
    </Section>
  );
}

function WirelessChannelUtilizationGraph({
  macAddress,
  timePeriod,
}: {
  macAddress: string;
  timePeriod: string;
}) {
  const network = useNetwork();

  const { data: channelData } = useGraphQL(ClientChannelUtilizationQuery, {
    networkUUID: network.UUID,
    macAddress,
    filter: {
      timeFilter: {
        durationSeconds: getDurationSeconds(timePeriod),
        stepSeconds: getStep(timePeriod),
      },
    },
  });
  expectDefinedOrThrow(
    channelData,
    new ResourceNotFoundError('Unable to load channel utilization'),
  );

  const series = useMemo(
    () =>
      clientChannelUtilizationMetricsToSeries(
        channelData?.channelUtilizationByClient,
        getStep(timePeriod),
      ),
    [channelData?.channelUtilizationByClient, timePeriod],
  );

  const maxUtilization = series.max_value ?? 0;
  const utilizationYDomain = maxUtilization > 0 ? [0, maxUtilization * 2] : undefined;

  return (
    <TimeSeriesChart
      curve={d3.curveLinear}
      series={[series]}
      seriesType="area"
      seriesLabel="client-channel-utilization"
      showSeriesGlyphs
      showSeriesTooltips
      timePeriod={timePeriod}
      title="Channel utilization"
      tooltipBody="The percentage of time the channel used by the client was utilized."
      valueFormatter={percentValueFormatter}
      yTickValueFormatter={percentValueFormatter}
      multiSeries
      showLegend
      yDomain={utilizationYDomain}
    />
  );
}

function WirelessInsights({
  macAddress,
  timePeriod,
  ssidUUID,
  band,
}: {
  macAddress: string;
  timePeriod: string;
  ssidUUID: string;
  band: MetricBandFilter;
}) {
  const network = useNetwork();
  const [clientChannelUtilizationEnabled] = useNosFeaturesEnabled([
    NosFeature.WOS_CLIENT_CHANNEL_UTILIZATION,
  ]);

  const { data: metricsData } = useGraphQL(WirelessClientMetricsByClientQuery, {
    networkUUID: network.UUID,
    macAddress,
    filter: {
      bands: band === 'All' ? undefined : [radioBandFromHuman(band)],
      ssidUUIDs: ssidUUID === 'All' ? undefined : [ssidUUID],
      timeFilter: {
        durationSeconds: getDurationSeconds(timePeriod),
        stepSeconds: getStep(timePeriod),
      },
    },
  });
  expectDefinedOrThrow(metricsData, new ResourceNotFoundError('Unable to load metrics'));

  const series = useMemo(
    () =>
      clientMetricsV2ToSeries(
        metricsData?.wirelessClientMetricsByClient?.values,
        getStep(timePeriod),
      ),
    [metricsData?.wirelessClientMetricsByClient?.values, timePeriod],
  );

  const maxUsage = Math.max(series.rxUsage.max_value ?? 0, series.txUsage.max_value ?? 0);
  const usageYDomain = maxUsage > 0 ? [0, maxUsage * 2] : undefined;

  const snrYDomain =
    series.snr.min_value !== undefined && series.snr.max_value !== undefined
      ? [Math.min(series.snr.min_value, 0), Math.max(series.snr.max_value, 0) + 10]
      : undefined;

  const maxRate = Math.max(series.rxRate.max_value ?? 0, series.txRate.max_value ?? 0);
  const rateYDomain = maxRate > 0 ? [0, maxRate + 80000] : undefined;

  return (
    <>
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[series.rxUsage, series.txUsage]}
        seriesType="area"
        seriesLabel="wireless-insights-usage"
        showSeriesGlyphs
        showSeriesTooltips
        timePeriod={timePeriod}
        title="Usage"
        tooltipBody="The total amount of data sent (tx) or received (rx) by the client in bytes."
        valueFormatter={usageValueFormatter}
        yTickValueFormatter={usageValueFormatterShort}
        multiSeries
        showLegend
        yDomain={usageYDomain}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[series.snr]}
        seriesType="area"
        seriesLabel="wireless-insights-snr"
        showSeriesGlyphs={false}
        timePeriod={timePeriod}
        title="SNR"
        tooltipBody="Measures the ratio of signal to noise."
        valueFormatter={snrValueFormatter}
        yTickValueFormatter={snrValueFormatterShort}
        multiSeries={false}
        showLegend={false}
        yDomain={snrYDomain}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[series.rxRate, series.txRate]}
        seriesType="area"
        seriesLabel="wireless-insights-rate"
        showSeriesGlyphs
        showSeriesTooltips
        timePeriod={timePeriod}
        title="Data rate"
        tooltipBody="The rate of bits sent (tx) and received (rx) by the client."
        valueFormatter={dataRateValueFormatter}
        yTickValueFormatter={dataRateValueFormatterShort}
        multiSeries
        showLegend
        yDomain={rateYDomain}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={[series.rxRetryRatio, series.txRetryRatio]}
        seriesType="area"
        seriesLabel="wireless-insights-retry-ratio"
        showSeriesGlyphs
        showSeriesTooltips
        timePeriod={timePeriod}
        title="Packet retry ratio"
        tooltipBody="The percent of packets sent (tx) and received (rx) that have to be retried."
        valueFormatter={percentValueFormatter}
        multiSeries
        showLegend
        yDomain={[0, 1]}
      />
      {clientChannelUtilizationEnabled && (
        <WirelessChannelUtilizationGraph macAddress={macAddress} timePeriod={timePeriod} />
      )}
    </>
  );
}

export default function WirelessClientDetail({ macAddress }: { macAddress: string }) {
  const network = useNetwork();
  const wos2Enabled = useNosFeatureEnabled(NosFeature.WOS2);
  const identity = useIdentity();
  const pingTestDialogProps = useDialogState();

  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const [currentBandOrUndefined] = useSearchParamsState<MetricBandFilter>(
    'band',
    MetricBandFilter.All,
  );
  const currentBand = currentBandOrUndefined ?? MetricBandFilter.All;

  const client = useClientByMAC(macAddress);

  const vlans = useGraphQL(vlansForPingQuery, {
    networkUUID: network.UUID,
  })?.data?.vlans;
  expectDefinedOrThrow(vlans, new ResourceNotFoundError('Unable to load vlans'));

  const wos2Device = useGraphQL(
    fallbackAccessPointHardwareDeviceQuery,
    {
      serialNumber: client.apSerialNumber!,
    },
    {
      enabled: !!client.apSerialNumber,
    },
  ).data?.hardwareDevice;

  const [currentSSIDOrUndefined] = useSearchParamsState<{
    UUID: string;
    ssid: string;
  }>('ssid', { UUID: 'All', ssid: 'All' });
  const currentSSID = currentSSIDOrUndefined ?? { UUID: 'All', ssid: 'All' };

  return (
    <PaneContent gutter="none">
      <Columns template="object">
        <Column>
          <Section relation="stacked">
            <SectionHeader heading="Metadata" />
            <SectionContent gutter="all">
              <ClientHardwareWidget client={client} />
            </SectionContent>
          </Section>
          <WirelessConnectionWidget
            relation="stacked"
            client={client}
            virtualDeviceUUID={wos2Device?.virtualDeviceUUID}
            virtualDeviceLabel={wos2Device?.virtualDevice?.label ?? 'Access point'}
            openPingTestDialog={pingTestDialogProps.state.open}
          />
        </Column>
        <Column>
          <WirelessStats macAddress={macAddress} />
          {wos2Enabled ? (
            <Suspense
              fallback={
                <VStack spacing={space(16)}>
                  <Skeleton height={120} width="100%" radius={6} />
                  <Skeleton height={120} width="100%" radius={6} />
                  <Skeleton height={120} width="100%" radius={6} />
                </VStack>
              }
            >
              <WirelessInsights
                macAddress={macAddress}
                timePeriod={currentTimePeriod}
                ssidUUID={currentSSID.UUID}
                band={currentBand}
              />
            </Suspense>
          ) : (
            <EmptyState
              heading="Upgrade network"
              icon="upgrading"
              subheading="In order to see wireless insights, an upgrade to your network is required."
              action={
                <Button
                  as="a"
                  href={zendeskFeedbackURL(
                    identity.username,
                    'Enabling wireless insights on my network',
                  )}
                  target="_blank"
                  arrangement="leading-icon"
                  icon="question"
                  size="medium"
                >
                  Contact support
                </Button>
              }
            />
          )}
        </Column>
      </Columns>
      {client.connectedVLAN && (
        <LANPingTestDialog
          state={pingTestDialogProps.state}
          hostIp={client.ip}
          hostName={clientNameOrNull(client) ?? '-'}
          vlan={client.connectedVLAN}
        />
      )}
    </PaneContent>
  );
}

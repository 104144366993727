import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import CreateClientVPNClientDrawer from '../../../../../components/ClientVPN/CreateClientDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/vpn/:serverUUID/clients/create',
  title: 'Add client - Client VPN - Secure tunnels',
});

export default function ClientVPNClientCreatePage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ClientVPNClientCreatePage);
  return (
    <IsPermitted
      permissions={PermissionType.PermClientVpnClientWrite}
      isPermitted={({ pathParams }) => !!pathParams?.serverUUID}
      should404OnAccessDenied
      path={Meta().path}
    >
      <CreateClientVPNClientDrawer serverUUID={drawerParams?.serverUUID!} />
    </IsPermitted>
  );
}

import type { api } from '@meterup/proto';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, Button, EmptyState, PaneContent, PaneHeader } from '@meterup/atto';
import { AutoTable, ContentFilterActionBadge, isDefinedAndNotEmpty } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React from 'react';

import { getRulesForCompany } from '../../../../../../api/api';
import { NoValue } from '../../../../../../components/NoValue';
import { ReactRouterLink } from '../../../../../../components/ReactRouterLink';
import { createColumnBuilder } from '../../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../../nav';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../../providers/CurrentControllerProvider';
import { categoryRules, formatRuleContentName } from '../../../../../../utils/content_filters';
import { makeDrawerLink } from '../../../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/dns-security/categories',
  layout: 'LegacyDNSSecurityLayout',
});

const builder = createColumnBuilder<api.ContentFilterRule>();

const columns = [
  builder.data((d) => formatRuleContentName(d), {
    id: 'content',
    header: 'Content',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.action, {
    id: 'action',
    header: 'Action',
    meta: { width: 68 },
    cell: ContentFilterActionBadge,
  }),
  builder.data((d) => (d.precedence - 2).toFixed(0), {
    id: 'priority',
    header: 'Priority',
    meta: { width: 104 },
  }),
  builder.data((d) => d.created_at ?? '', {
    id: 'created',
    header: `Created`,
    cell: (d) =>
      isDefinedAndNotEmpty(d.value) ? (
        <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED)}</>
      ) : (
        <NoValue />
      ),
  }),
];

export default function DNSSecurityCategoriesPage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const closeDrawer = useCloseDrawerCallback();

  const ruleData =
    useQuery(['dns_security', companyName, 'rules'], async () => getRulesForCompany(companyName), {
      suspense: true,
    }).data ?? [];
  const rules = categoryRules(ruleData);
  const drawerParams = Nav.useRegionParams(
    'drawer',
    paths.drawers.LegacyDNSSecurityCategoryRuleEditPage,
  );

  const createRuleDrawerLink = makeDrawerLink(
    window.location,
    paths.drawers.LegacyDNSSecurityCategoryRuleCreatePage,
    {
      companyName,
      controllerName,
    },
  );

  return (
    <PaneContent>
      <Alert
        variant="neutral"
        copy="Priority for the rules are applied in the order of allowed domains, then blocked domains, and finally categories."
        relation="stacked"
        icon="information"
      />
      <PaneHeader
        actions={
          <>
            <Button
              condense
              size="small"
              as={ReactRouterLink}
              to={makeDrawerLink(
                window.location,
                paths.drawers.LegacyDNSSecurityCategoryRuleCreatePage,
                {
                  controllerName,
                  companyName,
                },
              )}
              variant="secondary"
              icon="plus"
              arrangement="leading-icon"
            >
              Add rule
            </Button>
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(
                window.location,
                paths.drawers.LegacyDNSSecurityDomainInspectorPage,
                {
                  companyName,
                  controllerName,
                },
              )}
              variant="secondary"
              icon="search"
              condense
              size="small"
              arrangement="leading-icon"
            >
              Inspect domain
            </Button>
          </>
        }
      />
      <PaneContent>
        {rules.length > 0 ? (
          <AutoTable
            columns={columns}
            data={rules}
            getLinkTo={(row) =>
              makeDrawerLink(window.location, paths.drawers.LegacyDNSSecurityCategoryRuleEditPage, {
                id: row.id,
                companyName,
                controllerName,
              })
            }
            isRowSelected={(row) => row.id === drawerParams?.id}
            onRowDeselect={closeDrawer}
          />
        ) : (
          <EmptyState
            icon="dns-security"
            heading="No filter rules"
            action={
              <Button
                as={ReactRouterLink}
                to={createRuleDrawerLink}
                icon="plus"
                arrangement="leading-icon"
              >
                Add rule
              </Button>
            }
          />
        )}
      </PaneContent>
    </PaneContent>
  );
}

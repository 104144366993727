import '@meterup/atto/src/styles/fonts.css';

import { Badge, Caption, darkThemeSelector, Heading, Logo, Title } from '@meterup/atto';
import QRCodeSVG from 'pretty-qrcode';
import React from 'react';
import { match } from 'ts-pattern';

import { colors, fonts, fontWeights, styled } from './stitches';

function isNumeric(str: string) {
  return str.match(/^\d+$/) !== null;
}

function isAlpha(str: string) {
  return str.match(/^[a-zA-Z]+$/) !== null;
}

const Box = styled('div');

const NumericChar = styled('span', {
  color: colors.headingBrandLight,

  [darkThemeSelector]: {
    color: colors.headingBrandDark,
  },
});

const AlphaChar = styled('span');

const SymbolChar = styled('span', {
  color: colors.headingNegativeLight,

  [darkThemeSelector]: {
    color: colors.headingNegativeDark,
  },
});

const PasswordText = styled(Title, {
  color: colors.headingNeutralLight,
  fontFamily: fonts.mono,

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },
});

function LargePasswordViewer({ password }: { password: string }) {
  return (
    <PasswordText>
      {password.split('').map((c, i) =>
        match(c)
          .when(isNumeric, () => (
            // eslint-disable-next-line react/no-array-index-key
            <NumericChar key={i}>{c}</NumericChar>
          ))
          .when(isAlpha, () => (
            // eslint-disable-next-line react/no-array-index-key
            <AlphaChar key={i}>{c}</AlphaChar>
          ))
          .otherwise(() => (
            // eslint-disable-next-line react/no-array-index-key
            <SymbolChar key={i}>{c}</SymbolChar>
          )),
      )}
    </PasswordText>
  );
}

const PageContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr minmax(0, 400px) 1fr',
  gridTemplateRows: '2fr min-content 2fr min-content',
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const Content = styled('div', {
  vStack: 0,
  gridColumn: '2 / 2',
  gridRow: '2 / 2',
  alignItems: 'stretch',
  maxWidth: 400,
  paddingX: 20,
  backgroundColor: colors.bgApplicationLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
  },
});

const ContentItem = styled('div', {
  vStack: '$8',
  padding: 20,
  strokeTopBottom: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    strokeTopBottom: colors.strokeNeutralDark,
  },
});

const KeyValues = styled('div', {
  vStack: 0,
  alignItems: 'stretch',
  overflow: 'hidden',
  strokeAll: colors.strokeNeutralLight,
  borderRadius: 16,

  [darkThemeSelector]: {
    strokeAll: colors.strokeNeutralDark,
  },
});

const QRCodeContentItem = styled('div', { vStack: '$20', paddingY: 48 });

const ContentItemKey = styled(Heading, {
  color: colors.headingBrandLight,

  [darkThemeSelector]: {
    color: colors.headingBrandDark,
  },
});

const ContentItemValue = styled(Title, {
  color: colors.headingNeutralLight,

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },
});

const PoweredByContainer = styled('div', {
  gridColumn: '1 / 4',
  gridRow: '4 / 4',
  paddingTop: 40,
  paddingBottom: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PoweredByContent = styled('div', { hStack: '$4', alignItems: 'center' });

const PoweredByText = styled(Caption, {
  color: colors.bodyNeutralLight,
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

export type AuthenticationType = 'WEP' | 'WPA' | 'nopass';

interface TabletQRCodeProps {
  type: AuthenticationType;
  ssid: string;
  password: string;
  hiddenSSID: boolean;
  includeMeterLogo?: boolean;
  onRefresh?: () => void;
}

const getWiFiQRCodeConnectionString = (
  type: 'WEP' | 'WPA' | 'nopass',
  ssid: string,
  password: string,
  hiddenSSID: boolean,
) =>
  [
    `WIFI:`,
    type !== 'nopass' && `T:${type};`,
    `S:${ssid};`,
    password && type !== 'nopass' && `P:${password};`,
    hiddenSSID && `H:true;`,
  ]
    .filter(Boolean)
    .join('');

const StyledQRCode = styled(QRCodeSVG, {
  '& > rect[width="0.75"]': {
    fill: colors.brand600,
  },

  '& > path[fill="#000000"]': {
    fill: colors.brand600,
  },

  '& > path[fill="none"]': {
    stroke: colors.brand600,
  },
});

export function TabletQRCode({
  type,
  ssid,
  password,
  hiddenSSID,
  includeMeterLogo = true,
  onRefresh,
}: TabletQRCodeProps) {
  return (
    <PageContainer onClick={onRefresh}>
      <Content>
        <QRCodeContentItem>
          <Box
            css={{
              padding: '$20',
              backgroundColor: colors.bgNeutralLight,
              borderRadius: 24,
              [darkThemeSelector]: { backgroundColor: colors.bgNeutralDark },
            }}
          >
            <StyledQRCode
              value={getWiFiQRCodeConnectionString(type, ssid, password, hiddenSSID)}
              size={240}
            />
          </Box>
          <Badge size="large" icon="phone" arrangement="leading-icon">
            Scan me!
          </Badge>
        </QRCodeContentItem>
        <KeyValues>
          <ContentItem>
            <ContentItemKey>Network name</ContentItemKey>
            <ContentItemValue>{ssid}</ContentItemValue>
          </ContentItem>
          {type !== 'nopass' && (
            <ContentItem>
              <ContentItemKey>Password</ContentItemKey>
              <LargePasswordViewer password={password} />
            </ContentItem>
          )}
        </KeyValues>
      </Content>
      {includeMeterLogo && (
        <PoweredByContainer>
          <PoweredByContent as="a" href="https://www.meter.com/" target="_blank">
            <Box>
              <PoweredByText>Powered by</PoweredByText>
            </Box>
            <Logo height={3} />
          </PoweredByContent>
        </PoweredByContainer>
      )}
    </PageContainer>
  );
}

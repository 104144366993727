import React from 'react';

import { Section, SectionContent, SectionHeader, SummaryList } from '@meterup/atto';

type NetworkSummaryListProps = {
  children?: React.ReactNode;
};

export default function NetworkSummaryList({ children }: NetworkSummaryListProps) {
  return (
    <Section relation="standalone">
      <SectionHeader icon="wifi" heading="Network" />
      <SectionContent>
        <SummaryList>{children}</SummaryList>
      </SectionContent>
    </Section>
  );
}

import { LocationsCreateRequest } from '@meterup/connect-api';
import * as Sentry from '@sentry/react';

import { PredictionFormatterResult } from '../hooks/usePredictionFormatter';
import type { GeolocationMap } from '../types/gmaps';

function mapToAddressObject(geolocationMap: GeolocationMap) {
  const streetNumber = geolocationMap.streetNumber?.longName
    ? geolocationMap.streetNumber?.longName
    : geolocationMap.streetNumber?.gg ?? '';
  const route = geolocationMap.route?.longName
    ? geolocationMap.route?.longName
    : geolocationMap.route?.gg ?? '';
  const premise = geolocationMap.premise?.longName
    ? geolocationMap.premise?.longName
    : geolocationMap.premise?.gg ?? '';
  const subpremise = geolocationMap.subpremise?.longName
    ? geolocationMap.subpremise?.longName
    : geolocationMap.subpremise?.gg ?? '';
  let city = geolocationMap.locality?.longName ?? geolocationMap.political?.longName ?? '';
  const state = geolocationMap.administrativeAreaLevel1?.shortName
    ? geolocationMap.administrativeAreaLevel1?.shortName
    : geolocationMap.administrativeAreaLevel1?.gg ?? '';
  const postalCode = geolocationMap.postalCode?.longName
    ? geolocationMap.postalCode?.longName
    : geolocationMap.postalCode?.gg ?? '';
  const establishment = geolocationMap.establishment?.longName
    ? geolocationMap.establishment?.longName
    : geolocationMap.establishment?.gg ?? '';

  let address1 = '';
  let address2 = '';

  if (city === '') {
    city = geolocationMap.locality?.gg ?? geolocationMap.political?.gg ?? '';
  }

  if (streetNumber || route) {
    address1 = `${streetNumber} ${route}`.trim();
  } else if (premise) {
    address1 = premise;
  } else if (establishment) {
    address1 = establishment;
  } else {
    Sentry.captureException(new Error('No address1 found'), {
      extra: {
        ...geolocationMap,
      },
    });
  }

  if (subpremise) {
    address2 = subpremise;
  }

  return {
    address1,
    address2,
    city,
    state,
    postalCode,
  };
}

export default function formatLocationParts(
  result: PredictionFormatterResult,
): LocationsCreateRequest {
  const { components, latLng } = result;

  return {
    ...mapToAddressObject(components),
    latitude: latLng?.lat(),
    longitude: latLng?.lng(),
  };
}

import {
  type IconName,
  Body,
  colors,
  darkThemeSelector,
  fontWeights,
  FramedIcon,
  sizing,
  space,
  styled,
  Text,
  VStack,
} from '@meterup/atto';

import {
  AccessDetailsSection,
  AlertingEmailSection,
  AvgWifiUsersSection,
  BooleanSection,
  CertificateOfInsuranceSection,
  ClientVPNSection,
  ConditionalWithDetailsSection,
  ContactInfoSection,
  ExistingNetworkLoginSection,
  GoLiveDateSection,
  HardwiredDevicesSection,
  ISPSection,
  OtherRequirementsSection,
  PortForwardingRequirementsSection,
  RADIUSSection,
  RiserManagerContactSection,
  SiteSurveyWindowsSection,
  SiteToSiteVPNSection,
  SSIDSection,
  UnionContractorsSection,
  UploadSection,
  WifiDeviceTypesSection,
} from './Sections';
import { useOnboardingDocument } from './useOnboardingDocument';

const SectionGroupHeaderIcon = styled(FramedIcon, {
  margin: '$2 0',
});

const SectionGroupHeading = styled(Body, {
  fontWeight: fontWeights.bold,
});

const SectionGroupDescription = styled(Body);

const SectionGroupCopy = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const SectionGroupHeader = styled('div', {
  display: 'flex',
  gap: '$12',
  padding: sizing.squish,
});

const SectionGroupTasks = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '$12',

  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    right: sizing.sides,
    left: sizing.sides,
    display: 'block',
    height: '$1',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

const SectionGroupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

function SectionGroup({
  title,
  description,
  tasks,
  icon,
}: {
  title: string;
  description: string;
  tasks: React.ReactNode[];
  icon: IconName;
}) {
  return (
    <SectionGroupContainer>
      <SectionGroupHeader>
        <SectionGroupHeaderIcon icon={icon} size={space(36)} />
        <SectionGroupCopy>
          <SectionGroupHeading>{title}</SectionGroupHeading>
          <SectionGroupDescription>{description}</SectionGroupDescription>
        </SectionGroupCopy>
      </SectionGroupHeader>
      <SectionGroupTasks>{tasks}</SectionGroupTasks>
    </SectionGroupContainer>
  );
}

export default function OnboardingForm() {
  const doc = useOnboardingDocument();

  return (
    <>
      <SectionGroup
        title="Required for site survey"
        icon="floorplan"
        description="In order for Meter to complete your site survey, you must complete the tasks in this section."
        tasks={[
          <GoLiveDateSection
            title="Desired go-live date"
            description={
              <VStack spacing={space(8)}>
                <Text>
                  This is the date by which you expect the network to be fully functioning.
                </Text>
                <Text>
                  We generally cannot promise network install until 4 weeks after a site survey is
                  completed.
                </Text>
              </VStack>
            }
            onClearSubmission={() => doc.patch('desired_go_live_date', null)}
            onSubmit={(v) => doc.patch('desired_go_live_date', v)}
            submittedValues={doc.state.desired_go_live_date}
            delegation={doc.delegationsFieldNameMap.get('desired_go_live_date')}
          />,
          <ContactInfoSection
            title="On-site point of contact"
            description="The person that helps provide access for site survey and install, and potentially receive equipment."
            onSubmit={(v) => doc.patch('on_site_point_of_contact', v)}
            onClearSubmission={() => doc.patch('on_site_point_of_contact', null)}
            submittedValues={doc.state.on_site_point_of_contact}
            fieldName="on_site_point_of_contact"
            delegation={doc.delegationsFieldNameMap.get('on_site_point_of_contact')}
          />,
          <UnionContractorsSection
            title="Are on-site contractors required to be union-licensed?"
            description="Meter works with both union and non-union install partners."
            onClearSubmission={() => doc.patch('are_union_contractors_required', null)}
            onSubmit={(v) => doc.patch('are_union_contractors_required', v)}
            submittedValues={doc.state.are_union_contractors_required}
            delegation={doc.delegationsFieldNameMap.get('are_union_contractors_required')}
          />,
          <CertificateOfInsuranceSection
            title="Certificate of insurance"
            description="Some buildings require contractors to provide a Certificate of Insurance (COI). Please ask your landlord or property manager what their requirements are and upload them so that we can ensure that our contractors are compliant."
            onClearSubmission={() => doc.patch('coi', null)}
            onSubmit={(v) => doc.patch('coi', v)}
            submittedValues={doc.state.coi}
            delegation={doc.delegationsFieldNameMap.get('coi')}
          />,
          <ContactInfoSection
            title="Technical point of contact"
            description="This person will have insight into how the network is configured and any technical requirements."
            onSubmit={(v) => doc.patch('technical_point_of_contact', v)}
            onClearSubmission={() => doc.patch('technical_point_of_contact', null)}
            submittedValues={doc.state.technical_point_of_contact}
            fieldName="technical_point_of_contact"
            delegation={doc.delegationsFieldNameMap.get('technical_point_of_contact')}
          />,
          <ContactInfoSection
            title="Property manager contact"
            description="Property Manager information is required to ensure that they have visibility into the different vendors that will be working in their building."
            onClearSubmission={() => doc.patch('billing_contact', null)}
            onSubmit={(v) => doc.patch('billing_contact', v)}
            submittedValues={doc.state.billing_contact}
            fieldName="billing_contact"
            delegation={doc.delegationsFieldNameMap.get('billing_contact')}
          />,
          <RiserManagerContactSection
            title="Riser manager contact"
            description="If your building has a Riser Manager it would be helpful to be introduced to them to facilitate a smooth ISP install, but not all buildings have them."
            onClearSubmission={() => doc.patch('riser_manager_contact', null)}
            onSubmit={(v) => doc.patch('riser_manager_contact', v)}
            submittedValues={doc.state.riser_manager_contact}
            delegation={doc.delegationsFieldNameMap.get('riser_manager_contact')}
          />,
          <SiteSurveyWindowsSection
            title="Site survey times"
            description={
              <VStack spacing={space(8)}>
                <Text>Please provide a list of dates and times for the site survey.</Text>
                <Text>
                  The site survey takes no more than 2 hours and our installers will need access to
                  the MDF as well as the rest of the space to document and take photos.
                </Text>
              </VStack>
            }
            onClearSubmission={() => doc.patch('site_survey_windows', null)}
            onSubmit={(v) => doc.patch('site_survey_windows', v)}
            submittedValues={doc.state.site_survey_windows}
            delegation={doc.delegationsFieldNameMap.get('site_survey_windows')}
          />,
          <AccessDetailsSection
            title="Access details"
            description="Please provide details about access requirements."
            onClearSubmission={() => doc.patch('access_details', null)}
            onSubmit={(v) => doc.patch('access_details', v)}
            submittedValues={doc.state.access_details}
            delegation={doc.delegationsFieldNameMap.get('access_details')}
          />,
          <UploadSection
            title="Blank floor plan"
            description="A floor plan is required before we schedule a site survey so that we can direct the installers and begin your network design. The preferred methods are CAD or DWG files but a PDF will work. Please indicate if there are any high traffic and bandwidth areas such as workstations, all hands spaces, phone booths, etc."
            onClearSubmission={() => doc.patch('blank_floor_plan', null)}
            onSubmit={(v) => doc.patch('blank_floor_plan', v)}
            submittedValues={doc.state.blank_floor_plan}
            accept={{
              'application/pdf': ['.pdf'],
              'application/acad': ['.cad'],
              'application/x-acad': ['.cad'],
              'application/x-autocad': ['.cad'],
              'drawing/x-dwg': ['.dwg'],
              'application/dwg': ['.dwg'],
              'application/x-dwg': ['.dwg'],
              'image/vnd.dwg': ['.dwg'],
              'image/x-dwg': ['.dwg'],
            }}
            fieldName="blank_floor_plan"
            delegation={doc.delegationsFieldNameMap.get('blank_floor_plan')}
          />,
          <UploadSection
            title="Marked up floor plan"
            description="Please include a marked up floor plan to indicate spaces where there are large congregations of people and devices such as All Hands Spaces, Cafeterias, etc. If there are any priority spaces such as Executive Meeting Rooms/Offices, Phone Booths, etc. please indicate those as well. This floor plan can be a PDF, JPEG, or PNG."
            onClearSubmission={() => doc.patch('marked_up_floor_plan', null)}
            onSubmit={(v) => doc.patch('marked_up_floor_plan', v)}
            submittedValues={doc.state.marked_up_floor_plan}
            accept={{
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/png': ['.png'],
              'application/pdf': ['.pdf'],
            }}
            fieldName="marked_up_floor_plan"
            delegation={doc.delegationsFieldNameMap.get('marked_up_floor_plan')}
          />,
          <WifiDeviceTypesSection
            title="Wi-Fi device types"
            description="Please provide a list of wi-fi device types that will be used in your network."
            onClearSubmission={() => doc.patch('wifi_device_types', null)}
            onSubmit={(v) => doc.patch('wifi_device_types', v)}
            submittedValues={doc.state.wifi_device_types}
            delegation={doc.delegationsFieldNameMap.get('wifi_device_types')}
          />,
          <AvgWifiUsersSection
            title="How many wifi users do you expect to be in the office on a regular basis?"
            description="We will base the network design on the amount of predicted users in the space. Please indicate the range of wireless users that will be using your space each day."
            onClearSubmission={() => doc.patch('avg_wifi_users', null)}
            onSubmit={(v) => doc.patch('avg_wifi_users', v)}
            submittedValues={doc.state.avg_wifi_users}
            delegation={doc.delegationsFieldNameMap.get('avg_wifi_users')}
          />,
        ]}
      />
      <SectionGroup
        title="Required for network installation"
        icon="wrench"
        description="In order for Meter to complete your network installation, you must complete the tasks in this
        section."
        tasks={[
          <ExistingNetworkLoginSection
            title="Existing network platform login"
            description="A login to your existing network platform will assist us during network design and migration. This is not required."
            onClearSubmission={() => doc.patch('existing_network_login_details', null)}
            onSubmit={(v) => doc.patch('existing_network_login_details', v)}
            submittedValues={doc.state.existing_network_login_details}
            delegation={doc.delegationsFieldNameMap.get('existing_network_login_details')}
          />,
          <HardwiredDevicesSection
            title="Hardwired devices"
            description="If you have hardwired device (cameras, access control systems, conferencing equipment, etc.) on your network, add them here."
            onClearSubmission={() => doc.patch('hardwired_devices', null)}
            onSubmit={(v) => doc.patch('hardwired_devices', v)}
            submittedValues={doc.state.hardwired_devices}
            delegation={doc.delegationsFieldNameMap.get('hardwired_devices')}
          />,
          // TODO: Do you need Meter's install partner to run additional data drops for your devices?
          <PortForwardingRequirementsSection
            title="Port forwarding requirements"
            description="Please specify any port forwarding requirements for your network."
            onClearSubmission={() => doc.patch('port_forwarding_requirements', null)}
            onSubmit={(v) => doc.patch('port_forwarding_requirements', v)}
            submittedValues={doc.state.port_forwarding_requirements}
            delegation={doc.delegationsFieldNameMap.get('port_forwarding_requirements')}
          />,
          <BooleanSection
            title="Captive portal"
            description="Does your network have a captive portal?"
            onClearSubmission={() => doc.patch('has_captive_portal', null)}
            onSubmit={(v) => doc.patch('has_captive_portal', v)}
            submittedValues={doc.state.has_captive_portal}
            previewValue={{
              selected: 'Has captive portal',
              unselected: 'No captive portal',
            }}
            fieldName="has_captive_portal"
            delegation={doc.delegationsFieldNameMap.get('has_captive_portal')}
          />,
          <ConditionalWithDetailsSection
            title="Servers on-site"
            description="Does your network have servers on-site?"
            onClearSubmission={() => doc.patch('servers_onsite', null)}
            onSubmit={(v) => doc.patch('servers_onsite', v)}
            submittedValues={doc.state.servers_onsite}
            previewValue={{
              selected: 'Servers on-site',
              unselected: 'No servers on-site',
            }}
            details={{
              label: 'Server details',
              description: 'Please provide details about any servers on-site.',
            }}
            fieldName="servers_onsite"
            delegation={doc.delegationsFieldNameMap.get('servers_onsite')}
          />,
          <ClientVPNSection
            title="Client VPN"
            description="Does your network have a client VPN?"
            onClearSubmission={() => doc.patch('has_client_vpn', null)}
            onSubmit={(v) => doc.patch('has_client_vpn', v)}
            submittedValues={doc.state.has_client_vpn}
            delegation={doc.delegationsFieldNameMap.get('has_client_vpn')}
          />,
          <SiteToSiteVPNSection
            title="Site-to-site VPN"
            description="Does your network have a site-to-site VPN?"
            onClearSubmission={() => doc.patch('has_site_to_site_vpn', null)}
            onSubmit={(v) => doc.patch('has_site_to_site_vpn', v)}
            submittedValues={doc.state.has_site_to_site_vpn}
            delegation={doc.delegationsFieldNameMap.get('has_site_to_site_vpn')}
          />,
          <SSIDSection
            title="Configure SSIDs"
            description="Before your network is live, you must configure the SSIDs for your Wi-Fi."
            delegation={doc.delegationsFieldNameMap.get('ssids')}
          />,
          <RADIUSSection
            title="RADIUS accounting"
            description="If your network uses RADIUS accounting, you will be asked to configure it."
            onClearSubmission={() => doc.patch('radius_profile_requirements', null)}
            onSubmit={(v) => doc.patch('radius_profile_requirements', v)}
            submittedValues={doc.state.radius_profile_requirements}
            delegation={doc.delegationsFieldNameMap.get('radius_profile_requirements')}
          />,
          <ISPSection
            title="ISPs"
            description="If procuring your own ISP uplinks, please provide details about them."
            onClearSubmission={() => doc.patch('isp_details', null)}
            onSubmit={(v) => doc.patch('isp_details', v)}
            submittedValues={doc.state.isp_details}
            delegation={doc.delegationsFieldNameMap.get('isp_details')}
          />,
          <OtherRequirementsSection
            title="Other configuration requirements"
            description="Please provide details about any other requirements that you would like to discuss."
            onClearSubmission={() => doc.patch('other_requirements', null)}
            onSubmit={(v) => doc.patch('other_requirements', v)}
            submittedValues={doc.state.other_requirements}
            delegation={doc.delegationsFieldNameMap.get('other_requirements')}
          />,
        ]}
      />
      <SectionGroup
        title="Required for network go-live"
        icon="lightning"
        description="Before your network goes live, you must complete the tasks in this
        section."
        tasks={[
          <AlertingEmailSection
            title="Alerting email"
            description="Meter will proactively send notifications about your network. Please provide an email address (list serves work best) so that we can alert the proper teams if there are network updates."
            onClearSubmission={() => doc.patch('alerting_email', null)}
            onSubmit={(v) => doc.patch('alerting_email', v)}
            submittedValues={doc.state.alerting_email}
            delegation={doc.delegationsFieldNameMap.get('alerting_email')}
          />,
          <ContactInfoSection
            title="Billing contact"
            description="Please indicate where Meter should send invoices."
            onClearSubmission={() => doc.patch('property_manager_contact', null)}
            onSubmit={(v) => doc.patch('property_manager_contact', v)}
            submittedValues={doc.state.property_manager_contact}
            fieldName="property_manager_contact"
            delegation={doc.delegationsFieldNameMap.get('property_manager_contact')}
          />,
          <ContactInfoSection
            title="Authorized administrator contact"
            description="This is the person or group of people authorized to make any changes to your account & add users"
            onClearSubmission={() => doc.patch('authorized_administrators_contact', null)}
            onSubmit={(v) => doc.patch('authorized_administrators_contact', v)}
            submittedValues={doc.state.authorized_administrators_contact}
            fieldName="authorized_administrators_contact"
            delegation={doc.delegationsFieldNameMap.get('authorized_administrators_contact')}
          />,
        ]}
      />
    </>
  );
}

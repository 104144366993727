import { CompanySupportTier } from '@meterup/common/src/gql/graphql';

import type { CompaniesQueryQuery, CompanyQueryQuery } from '../../../../gql/graphql';
import { graphql } from '../../../../gql';

export type CompaniesQueryResult = CompaniesQueryQuery['listCompanies'][number];

export type CompanyQueryResult = NonNullable<CompanyQueryQuery['getCompany']>;
export type CompanyNetworksQueryResult = NonNullable<CompanyQueryResult['networks']>[number];

graphql(`
  fragment NetworkFields on Network {
    UUID
    slug
    label
    companySlug
    isActive
    isUpgradeSensitive
    isTemplate
    lastUpgradedAt
    highAvailabilityConfig {
      UUID
    }
    virtualDevices {
      deviceType
    }

    mailingAddress {
      line1
      line2
      city
      subdivisionCode
      postalCode
      countryISO2
    }
  }
`);

export const companiesQuery = graphql(`
  query CompaniesQuery($limit: Int!, $offset: Int!) {
    listCompanies(limit: $limit, offset: $offset) {
      uuid
      isCustomer
      supportTier
      slug
      name
      networks {
        ...NetworkFields
      }
    }
  }
`);

export const companyQuery = graphql(`
  query CompanyQuery($slug: String!) {
    getCompany(slug: $slug) {
      uuid
      isCustomer
      supportTier
      slug
      name
      networks {
        ...NetworkFields
      }
    }
  }
`);

export function supportTierWeight(tier: CompanySupportTier | null | undefined): number {
  if (tier === null || tier === undefined) {
    return -1;
  }

  switch (tier) {
    case CompanySupportTier.Low:
      return 0;
    case CompanySupportTier.Medium:
      return 1;
    case CompanySupportTier.High:
      return 2;
    case CompanySupportTier.Critical:
      return 3;
    default:
      throw new Error(`Unhandled support tier: ${tier}`);
  }
}

import {
  colors,
  darkThemeSelector,
  fontWeights,
  Icon,
  Shortcut,
  Small,
  SpecialKey,
  styled,
  Tooltip,
} from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { useUsersOS } from '@meterup/atto/src/utilities/useUsersOS';
import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { useWorkbenchSide, WorkbenchSideProvider } from './utils/useWorkbenchSide';

const WorkbenchToggleIcon = styled(Icon, {
  width: '$12',
  height: '$12',
  color: '$$iconColor',

  variants: {
    side: {
      bottom: {},
      end: {
        rotate: '270deg',
      },
    },
  },
});

const WorkbenchToggleLabel = styled(Small, {
  color: '$$labelColor !important',
  fontWeight: fontWeights.bold,

  variants: {
    side: {
      bottom: {},
      end: {
        rotate: '180deg',
        writingMode: 'vertical-lr',
      },
    },
  },
});

const WorkbenchControlsButton = styled('button', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$2',
  background: colors.internalBgLight,
  $$labelColor: colors.internalBodyLight,
  $$iconColor: colors.internalIconLight,
  fontWeight: fontWeights.bold,

  [darkThemeSelector]: {
    background: colors.internalBgDark,
    strokeNoBottom: colors.internalStrokeDark,
    $$labelColor: colors.internalBodyDark,
    $$iconColor: colors.internalIconDark,
  },

  [selectors.hover]: {
    $$labelColor: colors.internalHeadingLight,
    $$iconColor: colors.internalBodyLight,

    [darkThemeSelector]: {
      $$labelColor: colors.internalHeadingDark,
      $$iconColor: colors.internalBodyDark,
    },
  },

  [selectors.focus]: {
    outline: 'none',
    $$labelColor: colors.internalHeadingLight,
    $$iconColor: colors.internalBodyLight,

    [darkThemeSelector]: {
      $$labelColor: colors.internalHeadingDark,
      $$iconColor: colors.internalBodyDark,
    },
  },

  variants: {
    side: {
      bottom: {
        flexDirection: 'row',
        strokeNoBottom: colors.internalStrokeLight,

        [darkThemeSelector]: {
          strokeNoBottom: colors.internalStrokeDark,
        },
      },
      end: {
        flexDirection: 'column-reverse',
        strokeNoRight: colors.internalStrokeLight,

        [darkThemeSelector]: {
          strokeNoRight: colors.internalStrokeDark,
        },
      },
    },
  },
});

const WorkbenchToggleContainer = styled(WorkbenchControlsButton, {
  variants: {
    side: {
      bottom: {
        padding: '$4 $8',
        borderRadius: '$8 $8 0 0',
      },
      end: {
        padding: '$8 $4',
        borderRadius: '$8 0 0 $8',
      },
    },
  },
});

const WorkBenchControlsContainer = styled('div', {
  position: 'absolute',
  display: 'flex',

  variants: {
    side: {
      bottom: {
        top: '0.5px',
        left: '50%',
        transform: 'translate(-50%, -100%)',
        flexDirection: 'row',
      },
      end: {
        top: '50%',
        left: '0.5px',
        transform: 'translate(-100%, -50%)',
        flexDirection: 'column',
      },
    },
  },
});

export interface WorkbenchControlsProps {
  onClick: () => void;
}

export default function WorkbenchControls({ onClick }: WorkbenchControlsProps) {
  const platform = useUsersOS();

  const toggleRef = useRef<HTMLButtonElement>(null);
  useHotkeys(
    [platform === 'mac' ? 'meta + .' : 'ctrl + .'],
    () => {
      if (toggleRef?.current) {
        toggleRef.current.click();
      }
    },
    { preventDefault: true },
  );

  const workbenchSide = useWorkbenchSide();

  return (
    <WorkbenchSideProvider value={workbenchSide}>
      <WorkBenchControlsContainer side={workbenchSide}>
        <Tooltip
          side={workbenchSide === 'bottom' ? 'top' : 'left'}
          contents={<Shortcut keys={[SpecialKey.CmdOrCtrl, '.']} />}
        >
          <WorkbenchToggleContainer
            ref={toggleRef}
            type="button"
            tabIndex={0}
            onClick={onClick}
            side={workbenchSide}
          >
            <WorkbenchToggleIcon side={workbenchSide} icon="workbench" />
            <WorkbenchToggleLabel side={workbenchSide}>Workbench</WorkbenchToggleLabel>
          </WorkbenchToggleContainer>
        </Tooltip>
      </WorkBenchControlsContainer>
    </WorkbenchSideProvider>
  );
}

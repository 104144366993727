import type { QueryResultField } from '@meterup/graphql';
import { Small } from '@meterup/atto';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { z } from 'zod';

import type { RadioProfilesQueryQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';
import { RadioBand } from '../../../gql/graphql';
import {
  CreateRadioProfileInputSchema,
  UpdateRadioProfileInputSchema,
} from '../../../gql/zod-types';

export const createRadioProfileInputSchema = CreateRadioProfileInputSchema.extend({
  name: z.string().nonempty({ message: 'Name cannot be blank' }),
}).superRefine((values, ctx) => {
  if (values.band2_4GAutoChannelIsEnabled && (values.band2_4GAllowedChannels ?? []).length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'You must select atleast one allowed channel when auto channel selection is enabled.',
      path: ['band2_4GAutoChannelIsEnabled'],
    });
  }

  if (values.band5GAutoChannelIsEnabled && (values.band5GAllowedChannels ?? []).length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'You must select atleast one allowed channel when auto channel selection is enabled.',
      path: ['band5GAutoChannelIsEnabled'],
    });
  }

  if (values.band2_4GAutoTxPowerIsEnabled) {
    const min = values.band2_4GAutoTxPowerMindBm;
    const max = values.band2_4GAutoTxPowerMaxdBm;

    if (!isDefinedAndNotEmpty(min)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must select a min tx power when auto tx power is enabled.',
        path: ['band2_4GAutoTxPowerMindBm'],
      });
    }

    if (!isDefinedAndNotEmpty(max)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must select a max tx power when auto tx power is enabled.',
        path: ['band2_4GAutoTxPowerMaxdBm'],
      });
    }

    if (isDefinedAndNotEmpty(min) && isDefinedAndNotEmpty(max) && min > max) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Min tx power cannot be greater than max tx power.',
        path: ['band2_4GAutoTxPowerMindBm'],
      });
    }
  }

  if (values.band5GAutoTxPowerIsEnabled) {
    const min = values.band5GAutoTxPowerMindBm;
    const max = values.band5GAutoTxPowerMaxdBm;

    if (!isDefinedAndNotEmpty(min)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must select a min tx power when auto tx power is enabled.',
        path: ['band5GAutoTxPowerMindBm'],
      });
    }

    if (!isDefinedAndNotEmpty(max)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must select a max tx power when auto tx power is enabled.',
        path: ['band5GAutoTxPowerMaxdBm'],
      });
    }

    if (min && max && min > max) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Min tx power cannot be greater than max tx power.',
        path: ['band5GAutoTxPowerMindBm'],
      });
    }
  }
});

export const updateRadioProfileInputSchema = UpdateRadioProfileInputSchema.extend({
  name: z.string().nonempty({ message: 'Name cannot be blank' }),
}).superRefine((values, ctx) => {
  if (values.band2_4GAutoChannelIsEnabled && (values.band2_4GAllowedChannels ?? []).length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'You must select atleast one allowed channel when auto channel selection is enabled.',
      path: ['band2_4GAutoChannelIsEnabled'],
    });
  }

  if (values.band5GAutoChannelIsEnabled && (values.band5GAllowedChannels ?? []).length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        'You must select atleast one allowed channel when auto channel selection is enabled.',
      path: ['band5GAutoChannelIsEnabled'],
    });
  }

  if (values.band2_4GAutoTxPowerIsEnabled) {
    const min = values.band2_4GAutoTxPowerMindBm;
    const max = values.band2_4GAutoTxPowerMaxdBm;

    if (!isDefinedAndNotEmpty(min)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must select a min tx power when auto tx power is enabled.',
        path: ['band2_4GAutoTxPowerMindBm'],
      });
    }

    if (!isDefinedAndNotEmpty(max)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must select a max tx power when auto tx power is enabled.',
        path: ['band2_4GAutoTxPowerMaxdBm'],
      });
    }

    if (min && max && min > max) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Min tx power cannot be greater than max tx power.',
        path: ['band2_4GAutoTxPowerMindBm'],
      });
    }
  }

  if (values.band5GAutoTxPowerIsEnabled) {
    const min = values.band5GAutoTxPowerMindBm;
    const max = values.band5GAutoTxPowerMaxdBm;

    if (!isDefinedAndNotEmpty(min)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must select a min tx power when auto tx power is enabled.',
        path: ['band5GAutoTxPowerMindBm'],
      });
    }

    if (!isDefinedAndNotEmpty(max)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must select a max tx power when auto tx power is enabled.',
        path: ['band5GAutoTxPowerMaxdBm'],
      });
    }

    if (min && max && min > max) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Min tx power cannot be greater than max tx power.',
        path: ['band5GAutoTxPowerMindBm'],
      });
    }
  }
});

export type ValidCreateRadioProfileParams = z.infer<typeof createRadioProfileInputSchema>;
export type ValidUpdateRadioProfileParams = z.infer<typeof updateRadioProfileInputSchema>;
export type ValidRadioProfileParams = ValidCreateRadioProfileParams | ValidUpdateRadioProfileParams;

export type RadioProfilesQueryResult = QueryResultField<
  RadioProfilesQueryQuery,
  'radioProfilesForNetwork'
>;

export const RadioProfilesQuery = graphql(`
  query RadioProfilesQuery($networkUUID: UUID!) {
    radioProfilesForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      isDefault
      band2_4GIsEnabled
      band2_4GAutoChannelIsEnabled
      band2_4GAllowedChannels
      band2_4GAutoChannelWidthIsEnabled
      band2_4GAutoTxPowerIsEnabled
      band2_4GChannelWidthMHz
      band2_4GAutoTxPowerMaxdBm
      band2_4GAutoTxPowerMindBm
      band5GIsEnabled
      band5GAutoChannelIsEnabled
      band5GAllowedChannels
      band5GAutoChannelWidthIsEnabled
      band5GAutoTxPowerIsEnabled
      band5GChannelWidthMHz
      band5GAutoTxPowerMaxdBm
      band5GAutoTxPowerMindBm

      virtualDevices {
        UUID
      }
    }
  }
`);

export const CreateRadioProfileMutation = graphql(`
  mutation CreateRadioProfileMutation($networkUUID: UUID!, $input: CreateRadioProfileInput!) {
    createRadioProfile(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const UpdateRadioProfileMutation = graphql(`
  mutation UpdateRadioProfileMutation($uuid: UUID!, $input: UpdateRadioProfileInput!) {
    updateRadioProfile(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const DeleteRadioProfileMutation = graphql(`
  mutation DeleteRadioProfileMutation($uuid: UUID!) {
    deleteRadioProfile(UUID: $uuid) {
      UUID
    }
  }
`);

export const CopyRadioSettingsMutation = graphql(`
  mutation CopyRadioSettingsMutation($networkUUID: UUID!) {
    copyRadioSettingsForNetworkFromConfig1(networkUUID: $networkUUID) {
      __typename
    }
  }
`);

// Using return type to check exhaustiveness
// eslint-disable-next-line consistent-return
export const radioBandToHuman = (band: RadioBand): string => {
  switch (band) {
    case RadioBand.Band_2_4G:
      return '2.4 GHz';
    case RadioBand.Band_5G:
      return '5 GHz';
  }
};

export const radioBandFromHuman = (band: '2.4' | '5' | '2.4 GHz' | '5 GHz') => {
  if (band === '2.4' || band === '2.4 GHz') {
    return RadioBand.Band_2_4G;
  }

  return RadioBand.Band_5G;
};

// Using return type to check exhaustiveness
// eslint-disable-next-line consistent-return
export const radioBandToField = (band: RadioBand): 'band2_4G' | 'band5G' => {
  switch (band) {
    case RadioBand.Band_2_4G:
      return 'band2_4G';
    case RadioBand.Band_5G:
      return 'band5G';
  }
};

export function RadiosCopyDescription() {
  return (
    <>
      Copies <Small family="monospace">radios</Small> configs from config 1 APs into config 2 radio
      profiles. The <Small family="monospace">regulatory-domain</Small> field is not copied as it is
      no longer used. Auto-channel selection and auto-TX are not enabled during the copy. Invalid
      channels in config 2 (such as <Small family="monospace">0</Small>) will be set to the default
      value (<Small family="monospace">6</Small> for 2.4 GHz and{' '}
      <Small family="monospace">36</Small> for 5 GHz). Transmit powers over 23 dBm will be clamped
      to <Small family="monospace">23</Small>. 5 GHz bands will have their secondary channels set to{' '}
      <Small family="monospace">36</Small>.
    </>
  );
}

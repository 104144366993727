import type { OverlayTriggerState } from '@meterup/atto';
import type { Paths } from '@meterup/common';
import type { FieldProps, FormikProps } from 'formik';
import type { FocusEvent } from 'react';
import {
  Badge,
  Body,
  Button,
  colors,
  CopyBox,
  darkThemeSelector,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  HStack,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  space,
  styled,
  Textarea,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { fade, palette } from '@meterup/atto/src/common/colors';
import { CaptivePortalDefaults } from '@meterup/common';
import UnifiedFileUploader, {
  PresignedImage,
} from '@meterup/common/src/components/UnifiedFileUploader';
import { useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { Field, useField, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';

import type { CaptivePortal as TCaptivePortal, CaptivePortalInputSchemaType } from './schema';
import { AllowedFolder } from '../../../gql/graphql';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import {
  FieldProvider,
  ListFieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
} from '../../Form/FieldProvider';
import { FormikConditional } from '../../FormikConditional';
import { SSIDsQuery } from '../../Wireless/SSIDs/SSIDsUtils';
import { vlanHasStaticIP, vlansQuery } from '../VLANs/utils';
import { deleteCaptivePortalMutation } from './queries';
import { captivePortalURL, useHandleUpdate, webhookURL } from './utils';

function URLField({
  form,
  name,
  label,
  description,
}: {
  form: FormikProps<CaptivePortalInputSchemaType>;
  name: Paths<CaptivePortalInputSchemaType>;
  label: string;
  description?: string;
}) {
  const onBlur = useCallback(
    (e: React.FocusEvent) => {
      const { value } = form.getFieldMeta<string>(name);
      if (!!value && !value.startsWith('http') && !value.startsWith('https')) {
        form.setFieldValue(name, `https://${value}`);
        setTimeout(() => form.handleBlur(e), 0);
      }
    },
    [form, name],
  );
  return (
    <FieldProvider name={name}>
      <PrimaryField
        element={<TextInput onBlur={onBlur} />}
        label={label}
        description={description}
      />
    </FieldProvider>
  );
}

const ColorFieldPreview = styled('div', {
  width: '$16',
  height: '$16',
  borderRadius: '$6',
  boxShadow: `inset 0 0 0 1px ${fade(palette.black, 0.2)}`,

  [darkThemeSelector]: {
    boxShadow: `inset 0 0 0 1px ${fade(palette.black, 0.7)}`,
  },
});

function ColorField({
  label,
  name,
  placeholder,
}: {
  name: Paths<CaptivePortalInputSchemaType>;
  label: string;
  placeholder?: string;
}) {
  const [input, , meta] = useField<string>(name);
  const colorCode = input.value ?? placeholder ?? 'transparent';
  const onBlur = useCallback(() => {
    const { value: innerValue } = input;
    if (innerValue) {
      let newValue = innerValue.startsWith('#') ? innerValue.substring(1) : innerValue;
      if (newValue.length === 3) {
        const [i, j, k] = newValue.split('');
        newValue = [i, i, j, j, k, k].join('');
      }
      newValue = `#${newValue}`;
      if (newValue !== innerValue) {
        meta.setValue(newValue);
        meta.setTouched(true);
      }
    }
  }, [input, meta]);
  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };
  return (
    <FieldProvider name={name}>
      <PrimaryField
        element={
          <TextInput
            disabled={false}
            prefix={<ColorFieldPreview css={{ backgroundColor: colorCode }} />}
            onBlur={onBlur}
            inputProps={{
              onFocus,
            }}
            placeholder={placeholder}
          />
        }
        label={label}
      />
    </FieldProvider>
  );
}
const LogoPreviewImage = styled(PresignedImage, {
  maxWidth: '100%',
  maxHeight: '120px',
});

const LogoPreviewArea = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '$20',
  backgroundColor: colors.white,
  borderRadius: '$8',
});

const LogoPreviewContainer = styled('div', {
  vStack: '$8',
  alignItems: 'flex-start',
});

function LogoPreview({
  fieldProps,
  backgroundColor,
}: {
  fieldProps: FieldProps<string, CaptivePortalInputSchemaType>;
  backgroundColor?: string | null;
}) {
  return (
    <LogoPreviewContainer>
      <Button
        type="button"
        variant="secondary"
        size="small"
        icon="trash-can"
        arrangement="leading-icon"
        onClick={() => fieldProps.form.setFieldValue('logoImageS3Key', null)}
      >
        Delete
      </Button>
      <LogoPreviewArea css={{ backgroundColor: backgroundColor ?? 'transparent' }}>
        <LogoPreviewImage s3Key={fieldProps.field.value} crossOrigin="anonymous" />
      </LogoPreviewArea>
    </LogoPreviewContainer>
  );
}

type CaptivePortalFormProps = {
  networkUUID: string;
  captivePortal?: TCaptivePortal;
};

const CaptivePortalFormDrawer = styled(Drawer);

function DeleteCaptivePortalDialog({
  captivePortal,
  state,
}: {
  captivePortal: TCaptivePortal;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const deleteCaptivePortal = useGraphQLMutation(deleteCaptivePortalMutation);
  const handleDeleteMutation = useHandleUpdate({
    action: 'delete',
    networkUUID: network.UUID,
  });
  const onDelete = useCallback(() => {
    handleDeleteMutation(deleteCaptivePortal.mutateAsync({ uuid: captivePortal?.UUID ?? '' }));
  }, [captivePortal?.UUID, deleteCaptivePortal, handleDeleteMutation]);

  return (
    <DeleteDialog
      state={state}
      label="the captive portal"
      handleDelete={onDelete}
      alert={{
        heading: 'Deleting a captive portal is permanent.',
        copy: 'Any VLANs that were using this captive portal will no longer be accessable via a captive portal.',
      }}
    />
  );
}

function CaptivePortalActions({ captivePortal }: { captivePortal: TCaptivePortal }) {
  const { state } = useDialogState();
  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem
              onClick={() =>
                window.open(captivePortalURL(captivePortal.UUID), '_blank', 'noreferrer, noopener')
              }
              icon="eye-open"
            >
              Preview
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={state.open} icon="trash-can">
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DeleteCaptivePortalDialog captivePortal={captivePortal} state={state} />
    </>
  );
}

export default function CaptivePortalForm({ networkUUID, captivePortal }: CaptivePortalFormProps) {
  const form = useFormikContext<CaptivePortalInputSchemaType>();
  const allVLANs = useGraphQL(vlansQuery, { networkUUID }).data?.vlans;
  const vlans = useMemo(
    () =>
      allVLANs
        ?.filter((vlan) => vlanHasStaticIP(vlan))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [allVLANs],
  );

  const ssids = useGraphQL(SSIDsQuery, { networkUUID });
  const affectedSSIDs = useMemo(() => {
    if (!ssids.data) return [];
    const { ssidsForNetwork } = ssids.data;
    const vlanUUIDs = new Set(form.values.vlanUUIDs ?? []);
    return ssidsForNetwork.filter((ssid) => {
      if (!ssid.vlan) return false;
      return vlanUUIDs.has(ssid.vlan.UUID);
    });
  }, [form, ssids.data]);
  const ldFlags = useFeatureFlags();
  return (
    <CaptivePortalFormDrawer>
      <DrawerHeader
        heading={`${captivePortal ? 'Edit' : 'Add'} captive portal`}
        actions={captivePortal ? <CaptivePortalActions captivePortal={captivePortal} /> : undefined}
      />
      <DrawerContent>
        <VStack spacing={space(12)}>
          {captivePortal && (
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enabled" />
              </FieldProvider>
            </FieldContainer>
          )}

          <FieldContainer>
            {!!ldFlags['external-captive-portal'] && (
              <FieldProvider name="isExternal">
                <PrimaryToggleField
                  onChange={(val: boolean) => {
                    if (!val) {
                      form.setFieldValue('externalPortalURL', null);
                    }
                  }}
                  label="External"
                  description="Enable if using a custom portal hosted by a third-party service. If disabled,
                  Meter's built-in captive portal will be used."
                />
              </FieldProvider>
            )}
            {form.values.isExternal && captivePortal && !!ldFlags['external-captive-portal'] && (
              <SecondaryField
                label="Webhook URL"
                description="The webhook API URL corresponding to this external captive portal."
                element={
                  <HStack align="center" spacing={space(16)} wrap="wrap">
                    <CopyBox
                      aria-label="Copy URL to clipboard"
                      relation="stacked"
                      value={webhookURL(captivePortal.webhookKey)}
                    >
                      <Body family="monospace">{webhookURL(captivePortal.webhookKey)}</Body>
                    </CopyBox>
                  </HStack>
                }
              />
            )}
          </FieldContainer>
          <FieldContainer>
            <MultiComboBoxFieldProvider name="vlanUUIDs">
              <PrimaryField
                label="VLANs"
                description="Which VLANs will use this captive portal."
                element={
                  <MultiComboBox placeholder="Select VLANs">
                    {vlans.map((vlan) => (
                      <MultiComboBoxItem key={vlan.UUID}>
                        {vlan.vlanID} - {vlan.name}
                      </MultiComboBoxItem>
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                    )) ?? <></>}
                  </MultiComboBox>
                }
              />
            </MultiComboBoxFieldProvider>
            <FormikConditional<CaptivePortalInputSchemaType>
              condition={(values) =>
                !!values.vlanUUIDs && values.vlanUUIDs.length > 0 && affectedSSIDs.length > 0
              }
            >
              <SecondaryField
                label="Included SSIDs"
                description="Which SSIDs will use this captive portal."
                element={
                  <HStack align="center" spacing={space(8)} wrap="wrap">
                    {affectedSSIDs.map((ssid) => (
                      <Badge
                        key={ssid.UUID}
                        arrangement="leading-icon"
                        icon="ssid"
                        size="small"
                        variant="neutral"
                      >
                        {ssid.ssid}
                      </Badge>
                    ))}
                  </HStack>
                }
              />
            </FormikConditional>
          </FieldContainer>
          <FieldContainer>
            <URLField
              form={form}
              name="redirectURL"
              label="Redirect URL"
              description="The page that will be shown after successfully signing into the captive portal."
            />
          </FieldContainer>
          <FieldContainer>
            <ListFieldProvider name="allowedHosts">
              <PrimaryField
                label="Allowed hosts"
                description='A list of URLs that can be accessed without signing into the captive portal, suitable for a walled garden scenario. Please list one URL per line, e.g., "https://meter.com".'
                element={<Textarea />}
              />
            </ListFieldProvider>
          </FieldContainer>
          <FieldContainer>
            <NumberFieldProvider name="authLifetimeSec" defaultValue={86400}>
              <PrimaryField
                label="Authorization lifetime (seconds)"
                description="How long before the user must sign into the captive portal again."
                element={<TextInput inputProps={{ type: 'number' }} />}
              />
            </NumberFieldProvider>
          </FieldContainer>

          <FormikConditional<CaptivePortalInputSchemaType>
            condition={(values) => values.isExternal && !!ldFlags['external-captive-portal']}
          >
            <FieldContainer>
              <URLField form={form} name="externalPortalURL" label="External portal URL" />
            </FieldContainer>
          </FormikConditional>
          <FormikConditional<CaptivePortalInputSchemaType>
            condition={(values) => !values.isExternal}
          >
            <FieldContainer>
              <ColorField
                name="displaySettings.backgroundColor"
                label="Background color"
                placeholder={CaptivePortalDefaults.displaySettings.backgroundColor}
              />
            </FieldContainer>
            <FieldContainer>
              <ColorField
                name="displaySettings.fontColor"
                label="Font color"
                placeholder={CaptivePortalDefaults.displaySettings.fontColor}
              />
            </FieldContainer>
            <FieldContainer>
              <Field name="logoImageS3Key">
                {(props: FieldProps<string, CaptivePortalInputSchemaType>) => (
                  <PrimaryField
                    element={
                      !props.field.value || props.field.value === '' ? (
                        <UnifiedFileUploader
                          folder={AllowedFolder.NetworkTopologyPhotos}
                          allowedTypes={{
                            images: true,
                          }}
                          maxFiles={1}
                          maxFileSizeBytes={5_000_000}
                          onFileUploaded={({ s3Key }) => {
                            form.setFieldValue('logoImageS3Key', s3Key);
                          }}
                          compact
                        />
                      ) : (
                        <LogoPreview
                          fieldProps={props}
                          backgroundColor={form.values.displaySettings?.backgroundColor}
                        />
                      )
                    }
                    label="Logo"
                    description="Image must be PNG, JPG, or SVG and under 5MB. Recommended to be at least 240px by 240px. If not specified, will default to Meter's logo."
                  />
                )}
              </Field>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="callToAction">
                <PrimaryField
                  element={
                    <TextInput disabled={false} placeholder={CaptivePortalDefaults.callToAction} />
                  }
                  label="Call to action"
                  description="The label that appears on the button for the user to agree to your terms & conditions."
                />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="termsAndConditions">
                <PrimaryField element={<Textarea disabled={false} />} label="Terms & conditions" />
              </FieldProvider>
            </FieldContainer>
          </FormikConditional>
          {/* Hiding for now until we decide what we want to do about showing and hiding the branding.
      <FieldContainer>
        <FieldProvider name="displaySettings.hidePoweredByMeter">
          <PrimaryToggleField label={`Hide "Powered by" footer`} />
        </FieldProvider>
      </FieldContainer> */}
        </VStack>
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button type="button" onClick={() => form.resetForm()} variant="secondary">
              Reset
            </Button>
            <Button
              type="submit"
              variant="primary"
              loading={form.isValidating || form.isSubmitting}
            >
              Save
            </Button>
          </>
        }
      />
    </CaptivePortalFormDrawer>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { PermissionType } from '@meterup/common/src/gql/graphql';

import DNSSecurity from '../../../../../components/DNSSecurity/DNSSecurity';
import { DNSSecurityTab } from '../../../../../components/DNSSecurity/utils';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/dns-security/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'DNS security - Firewall',
});

export default function DNSSecurityPage() {
  const pageParams = Nav.useRegionParams('root', paths.pages.DNSSecurityPage);

  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags, pathParams }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermFirewallRead) &&
            ldFlags['dns-security'] &&
            nosFlags[NosFeature.COS2] &&
            nosFlags[NosFeature.DNS_SECURITY] &&
            Object.values(DNSSecurityTab).includes(pathParams?.tab as DNSSecurityTab),
        )
      }
      path={Meta().path}
      should404OnAccessDenied
    >
      <DNSSecurity tab={pageParams?.tab as DNSSecurityTab} />
    </IsPermitted>
  );
}

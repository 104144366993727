import type { RoleName } from '@meterup/authorization/src/gql/graphql';
import type { Result, ResultItem } from '@meterup/graphql';
import { useMemo } from 'react';

import type { NetworksForCompanyQuery, RoleAssignment } from '../../../gql/graphql';
import { useNetworksForCompany } from '../../../hooks/useNetworksForCompany';
import { useCompanyUser } from './queries';

type RoleAssignmentWithNetwork = Result<RoleAssignment> & {
  network?: ResultItem<NetworksForCompanyQuery, 'networksForCompany'> | null;
};

type UseUserRolesGroupedByNameProps = {
  companySlug: string;
  uuid: string;
};

export default function useUserRolesGroupedByName({
  companySlug,
  uuid,
}: UseUserRolesGroupedByNameProps) {
  const data = useCompanyUser(uuid);
  const networks = useNetworksForCompany(companySlug);
  return useMemo(() => {
    if (!data.data) {
      return new Map<RoleName, RoleAssignmentWithNetwork[]>();
    }
    const base = new Map<RoleName, RoleAssignmentWithNetwork[]>();
    for (const role of data.data.companyUser.roles) {
      if (!base.has(role.name)) {
        base.set(role.name, []);
      }

      const network = networks.find((x) => x.UUID === role.networkUUID);
      const item: RoleAssignmentWithNetwork = {
        ...role,
        network,
      };
      const entry = base.get(role.name);
      if (entry) {
        entry.push(item);
      }
    }
    return base;
  }, [data.data, networks]);
}

type UseUserHasRoleProps = UseUserRolesGroupedByNameProps & {
  roleName: RoleName;
};

export function useUserHasRole({ companySlug, roleName, uuid }: UseUserHasRoleProps) {
  const roles = useUserRolesGroupedByName({ companySlug, uuid });
  return useMemo(() => (roles.get(roleName)?.length ?? 0) > 0, [roles, roleName]);
}

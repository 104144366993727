import { useGraphQLMutation } from '@meterup/graphql';
import produce from 'immer';
import { useCallback, useEffect, useReducer, useRef } from 'react';

import type { SsoAdminPortalType } from '../../../../gql/graphql';
import { ConfigureSSOMutation } from './configureSSOMutation';

export type SSOPortalState = {
  errorMessage?: string;
  portalURL?: string;
};

export type SSOPortalAction =
  | { type: 'setPortalURL'; portalURL: string }
  | { type: 'setPortalURLError'; errorMessage: string }
  | { type: 'clearPortalURL' };

function ssoPortalReducer(state: SSOPortalState, action: SSOPortalAction): SSOPortalState {
  switch (action.type) {
    case 'setPortalURL':
      return produce(state, (draft) => {
        draft.errorMessage = undefined;
        draft.portalURL = action.portalURL;
      });
    case 'setPortalURLError':
      return produce(state, (draft) => {
        draft.errorMessage = action.errorMessage;
        draft.portalURL = undefined;
      });
    case 'clearPortalURL':
      return produce(state, (draft) => {
        draft.portalURL = undefined;
      });
    default:
      return state;
  }
}

type UseCreatePortalProps = {
  companySlug?: string;
  portalType: SsoAdminPortalType;
};

export default function useCreatePortalURL({ companySlug, portalType }: UseCreatePortalProps) {
  const mutation = useGraphQLMutation(ConfigureSSOMutation);
  const [state, dispatch] = useReducer(ssoPortalReducer, {});
  const timerRef = useRef<number>();
  useEffect(() => {
    if (!state.portalURL) {
      return;
    }
    timerRef.current = window.setTimeout(
      () => {
        dispatch({
          type: 'clearPortalURL',
        });
      },
      1000 * 60 * 4,
    );
    // eslint-disable-next-line consistent-return
    return () => {
      const timer = timerRef.current;
      if (!timer) {
        return;
      }
      window.clearTimeout(timerRef.current);
    };
  }, [state.portalURL]);
  const onSubmit = useCallback(() => {
    if (!companySlug) {
      return;
    }
    mutation.mutate(
      {
        input: {
          companySlug,
          type: portalType,
        },
      },
      {
        onSuccess(data) {
          const { portalURL } = data.configureSSO;
          dispatch({
            type: 'setPortalURL',
            portalURL,
          });
          // window.open(portalURL, '_blank', 'noopener,noreferrer');
        },
        onError(error) {
          const errors = error.response.errors ?? [];
          const message = errors.length > 0 ? `\n${errors[0].message}` : '';
          const errorMessage = `There was an error configuring SSO.${message}`;
          dispatch({
            type: 'setPortalURLError',
            errorMessage,
          });
        },
      },
    );
  }, [companySlug, mutation, portalType]);
  return [state, dispatch, onSubmit, mutation.isLoading] as const;
}

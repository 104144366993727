import type { SortingState } from '@tanstack/react-table';
import type { RefObject } from 'react';
import {
  Badge,
  Button,
  DeviceTarget,
  EmptyState,
  HStack,
  Icon,
  space,
  Text,
  Tooltip,
  VStack,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable, expectDefinedOrThrow, isDefined, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Duration } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import type { AccessPointsQueryResult } from '../../Wireless/utils';
import { paths } from '../../../constants';
import { PermissionType, VirtualDeviceType } from '../../../gql/graphql';
import { useBatchEdit } from '../../../hooks/useBatchEdit';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { deviceHasMismatchedRealm } from '../../../utils/devices';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { CopyableMonoOrNoValue } from '../../Devices/Insights';
import { formatTimestamp } from '../../Devices/utils';
import { NoValue } from '../../NoValue';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { createMultiRowSelector } from '../../Table/createMultiRowSelector';
import { AccessPointsQuery, ValidAPDrawerTabs } from '../../Wireless/utils';
import { isPortSlow, portSpeedToLabel } from '../Switches/utils';

const connectedClientCount = (device: AccessPointsQueryResult) => {
  if (device.__typename !== 'AccessPointVirtualDevice') return '';
  return device.connectedClients.length.toString();
};

enum APStatusValue {
  Online = 'Online',
  OnlineNeedsAttention = 'Online (needs attention)',
  Offline = 'Offline',
  OfflineNeverConnected = 'Offline (never connected)',
}

const APStatusValueValues = Object.values(APStatusValue);

// This should follow the same general logic as APStatusBadge below
function apStatusValue(row: AccessPointsQueryResult): APStatusValue {
  if (row.hardwareDevice?.isConnectedToBackend) {
    if (
      row.hardwareDevice?.__typename === 'AccessPointHardwareDevice' &&
      row.hardwareDevice.phyInterfaceConnectedTo?.portSpeedMbps
    ) {
      return isPortSlow(row.hardwareDevice.phyInterfaceConnectedTo)
        ? APStatusValue.OnlineNeedsAttention
        : APStatusValue.Online;
    }

    return APStatusValue.OnlineNeedsAttention;
  }

  if (row.hardwareDevice?.disconnectedFromBackendAt) {
    return APStatusValue.Offline;
  }

  return APStatusValue.OfflineNeverConnected;
}

// This should follow the same general logic as apStatusValue below
function APStatusBadge({ row }: { row: AccessPointsQueryResult }) {
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);

  if (row.hardwareDevice?.isConnectedToBackend) {
    if (
      row.hardwareDevice?.__typename === 'AccessPointHardwareDevice' &&
      row.hardwareDevice.phyInterfaceConnectedTo?.portSpeedMbps
    ) {
      return (
        <Tooltip
          contents={`Link speed is ${portSpeedToLabel(row.hardwareDevice.phyInterfaceConnectedTo.portSpeedMbps)}`}
        >
          <Badge
            arrangement="hidden-label"
            size="small"
            ends="pill"
            icon="checkmark"
            variant={
              isPortSlow(row.hardwareDevice.phyInterfaceConnectedTo) ? 'attention' : 'positive'
            }
          >
            Online
          </Badge>
        </Tooltip>
      );
    }

    return (
      <Tooltip contents="Unable to determine link speed">
        <Badge
          arrangement="hidden-label"
          size="small"
          ends="pill"
          icon="checkmark"
          // If we don't have SOS meter switches, we can't know link speed
          variant={isSOSEnabled ? 'attention' : 'positive'}
        >
          Online
        </Badge>
      </Tooltip>
    );
  }

  if (row.hardwareDevice?.disconnectedFromBackendAt) {
    return (
      <Tooltip
        contents={`Offline since ${formatTimestamp(row.hardwareDevice.disconnectedFromBackendAt)}`}
      >
        <Badge arrangement="hidden-label" size="small" ends="pill" icon="cross" variant="negative">
          Offline
        </Badge>
      </Tooltip>
    );
  }

  return (
    <Tooltip contents="Never connected to backend">
      <Badge arrangement="hidden-label" size="small" ends="pill" icon="minus" variant="neutral">
        Offline
      </Badge>
    </Tooltip>
  );
}

const builder = createColumnBuilder<AccessPointsQueryResult>();
const checkboxColumn = createMultiRowSelector<AccessPointsQueryResult>();

const commonColumns = [
  builder.display(checkboxColumn),
  builder.data(apStatusValue, {
    id: 'status',
    sortingFn: (a, b, columnId): number => {
      const aVal: APStatusValue = a.getValue(columnId);
      const bVal: APStatusValue = b.getValue(columnId);

      return APStatusValueValues.indexOf(aVal) - APStatusValueValues.indexOf(bVal);
    },
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: APStatusBadge,
  }),

  builder.data((device) => device.label ?? '', {
    id: 'ap-label',
    header: 'Label',
    meta: {
      minWidth: 100,
      isLeading: true,
    },
    cell: (props) => {
      if (props.row.label) {
        return <span>{props.row.label}</span>;
      }

      return <NoValue />;
    },
  }),
  builder.data((device) => device.hardwareDevice?.macAddress ?? '', {
    id: 'ap-mac-address',
    header: 'MAC',
    cell: (props) => {
      if (props.row.hardwareDevice?.macAddress) {
        return (
          <CopyableMonoOrNoValue
            wrap={false}
            label="MAC address"
            value={props.row.hardwareDevice.macAddress}
          />
        );
      }

      return <NoValue />;
    },
  }),
  builder.data(
    (device) =>
      device.hardwareDevice?.__typename === 'AccessPointHardwareDevice'
        ? device.hardwareDevice.ipAddress ?? ''
        : '',
    {
      id: 'ap-ip-address',
      header: 'IP address',
      cell: (props) => {
        const hwDevice = props.row.hardwareDevice;

        if (
          hwDevice?.__typename !== 'AccessPointHardwareDevice' ||
          !isDefined(hwDevice.ipAddress)
        ) {
          return <NoValue />;
        }

        return <CopyableMonoOrNoValue wrap={false} label="IP address" value={hwDevice.ipAddress} />;
      },
    },
  ),
  builder.data((device) => connectedClientCount(device), {
    id: 'ap-connected-clients',
    header: 'Clients',
    meta: {
      alignment: 'end',
    },
  }),
  builder.data(
    (device) =>
      device.__typename === 'AccessPointVirtualDevice' ? device.radioProfile?.name ?? '' : '-1',
    {
      id: 'ap-radio-profile',
      header: 'Profile',
      meta: { minWidth: 100 },
      cell: function Cell(props) {
        const companyName = useCurrentCompany();
        const network = useNetwork();

        if (props.row.__typename !== 'AccessPointVirtualDevice' || !props.row.radioProfile?.name) {
          return <NoValue />;
        }

        return (
          <DeviceTarget
            as={Link}
            to={makeDrawerLink(window.location, paths.drawers.RadioProfileEditPage, {
              companyName,
              networkSlug: network.slug,
              uuid: props.row.radioProfile.UUID,
            })}
            aria-label={`Go to ${props.row.radioProfile.name}`}
            type="radio-profile"
            wrap={false}
          >
            {props.row.radioProfile.name}
          </DeviceTarget>
        );
      },
    },
  ),
  builder.data(
    (device) =>
      device.__typename === 'AccessPointVirtualDevice'
        ? device.radioSettings?.band2_4GPrimaryChannel ?? null
        : null,
    {
      id: '2-ghz-channel',
      header: '2.4 GHz channel',
      cell: (props) => {
        if (props.row.__typename !== 'AccessPointVirtualDevice') {
          return <NoValue />;
        }
        if (!props.row.radioProfile?.band2_4GIsEnabled) {
          return <NoValue />;
        }
        return (
          <Badge arrangement="leading-icon" icon="channel" size="small" variant="neutral">
            {props.row.radioSettings.band2_4GPrimaryChannel}
          </Badge>
        );
      },
    },
  ),
  builder.data(
    (device) =>
      device.__typename === 'AccessPointVirtualDevice'
        ? device.radioProfile?.band2_4GChannelWidthMHz ?? undefined
        : undefined,
    {
      id: '2-ghz-width',
      header: '2.4 GHz width',
      cell: (props) => {
        if (props.row.__typename !== 'AccessPointVirtualDevice') {
          return <NoValue />;
        }

        if (!props.row.radioProfile?.band2_4GIsEnabled) {
          return <NoValue />;
        }
        return (
          <Badge arrangement="leading-icon" icon="width" size="small" variant="neutral">
            {props.row.radioProfile?.band2_4GChannelWidthMHz}
          </Badge>
        );
      },
    },
  ),
  builder.data(
    (device) =>
      device.__typename === 'AccessPointVirtualDevice'
        ? device.radioSettings?.band2_4GTransmitPowerdBm ?? undefined
        : undefined,
    {
      id: '2-ghz-power',
      header: '2.4 GHz power',
      cell: (props) => {
        if (props.row.__typename !== 'AccessPointVirtualDevice') {
          return <NoValue />;
        }

        if (!props.row.radioProfile?.band2_4GIsEnabled) {
          return <NoValue />;
        }
        return (
          <Badge arrangement="leading-icon" icon="area" size="small" variant="neutral">
            {props.row.radioSettings.band2_4GTransmitPowerdBm}
          </Badge>
        );
      },
    },
  ),
  builder.data(
    (device) =>
      device.__typename === 'AccessPointVirtualDevice'
        ? device.radioSettings?.band5GPrimaryChannel ?? null
        : null,
    {
      id: '5-ghz-channel',
      header: '5 GHz channel',
      cell: (props) => {
        if (props.row.__typename !== 'AccessPointVirtualDevice') {
          return <NoValue />;
        }
        if (!props.row.radioProfile?.band5GIsEnabled) {
          return <NoValue />;
        }
        return (
          <Badge arrangement="leading-icon" icon="channel" size="small" variant="neutral">
            {props.row.radioSettings.band5GPrimaryChannel}
          </Badge>
        );
      },
    },
  ),
  builder.data(
    (device) =>
      device.__typename === 'AccessPointVirtualDevice'
        ? device.radioProfile?.band5GChannelWidthMHz ?? undefined
        : undefined,
    {
      id: '5-ghz-width',
      header: '5 GHz width',
      cell: (props) => {
        if (props.row.__typename !== 'AccessPointVirtualDevice') {
          return <NoValue />;
        }

        if (!props.row.radioProfile?.band5GIsEnabled) {
          return <NoValue />;
        }
        return (
          <Badge arrangement="leading-icon" icon="width" size="small" variant="neutral">
            {props.row.radioProfile?.band5GChannelWidthMHz}
          </Badge>
        );
      },
    },
  ),
  builder.data(
    (device) =>
      device.__typename === 'AccessPointVirtualDevice'
        ? device.radioSettings?.band5GTransmitPowerdBm ?? undefined
        : undefined,
    {
      id: '5-ghz-power',
      header: '5 GHz power',
      cell: (props) => {
        if (props.row.__typename !== 'AccessPointVirtualDevice') {
          return <NoValue />;
        }

        if (!props.row.radioProfile?.band5GIsEnabled) {
          return <NoValue />;
        }
        return (
          <Badge arrangement="leading-icon" icon="area" size="small" variant="neutral">
            {props.row.radioSettings.band5GTransmitPowerdBm}
          </Badge>
        );
      },
    },
  ),
];

const operatorColumns = [
  ...commonColumns,
  builder.data(
    (device) => {
      if (device?.__typename !== 'AccessPointVirtualDevice' || !isDefined(device?.uptime)) {
        return '0';
      }

      return Duration.fromISO(device.uptime).toHuman({ unitDisplay: 'narrow' });
    },
    {
      id: 'ap-uptime',
      header: 'Uptime',
      meta: {
        alignment: 'end',
        internal: true,
      },
      enableGlobalFilter: false,
      cell: (props) => {
        if (props.value === '0') {
          return <NoValue internal />;
        }

        return <span>{props.value}</span>;
      },
    },
  ),
  builder.data((device) => device.hardwareDevice?.serialNumber ?? '', {
    id: 'ap-serial-number',
    header: 'Serial number',
    meta: {
      internal: true,
    },
    cell: (props) => {
      if (props.row.hardwareDevice?.serialNumber) {
        return (
          <CopyableMonoOrNoValue
            wrap={false}
            label="serial number"
            value={props.row.hardwareDevice.serialNumber}
          />
        );
      }

      return <NoValue internal />;
    },
  }),
  builder.data(
    (row) => {
      const nos = row.nosVersion;
      const boot = row.hardwareDevice?.bootHistory;

      if (!nos || !boot || boot.length === 0) {
        return '0';
      }

      return nos.version;
    },
    {
      id: 'ap-nos-version',
      header: 'NOS version',
      meta: {
        internal: true,
      },
      cell: (props) => {
        if (props.value === '0') {
          return <NoValue internal />;
        }

        const nos = props.row.nosVersion;
        const boot = props.row.hardwareDevice?.bootHistory;
        const pendingNos = props.row.pendingNosVersion;

        // A little hacky, but NOS version 2 (which is stable) is `no-upgrades` so whatever build is on the
        // device when its on NOS version 2 is valid.
        if (nos && boot && nos.id !== 2 && nos.buildName !== boot[0].buildName) {
          return (
            <Tooltip
              asChild={false}
              contents={`NOS version ${nos.version} expected build ${nos.buildName} but device is running ${boot[0].buildName}`}
            >
              <HStack spacing={space(8)} align="center">
                <CopyableMonoOrNoValue wrap={false} label="NOS version" value={nos.version} />
                <Icon
                  icon="warning"
                  size={12}
                  color={{ light: 'internalBodyLight', dark: 'internalBodyDark' }}
                />
              </HStack>
            </Tooltip>
          );
        }
        if (isDefined(pendingNos) && isDefined(pendingNos.nosVersion)) {
          return (
            <Tooltip
              asChild={false}
              contents={`Pending upgrade to ${
                pendingNos.nosVersion.version
              } on ${formatTimestamp(pendingNos.scheduledAt)}`}
            >
              <HStack spacing={space(8)} align="center">
                <CopyableMonoOrNoValue wrap={false} label="NOS version" value={nos?.version} />
                <Icon
                  icon="information"
                  size={12}
                  color={{ light: 'internalBodyLight', dark: 'internalBodyDark' }}
                />
              </HStack>
            </Tooltip>
          );
        }

        return <CopyableMonoOrNoValue wrap={false} label="NOS version" value={props.value} />;
      },
    },
  ),
];

const macTableColumn = builder.data(
  (device) =>
    String(
      device.hardwareDevice?.__typename === 'AccessPointHardwareDevice' &&
        device.hardwareDevice.isInCurrentControllerMACTable,
    ),
  {
    id: 'is-in-security-appliance-mac-table',
    header: () => <Icon icon="mac-address" size={space(16)} />,
    meta: {
      alignment: 'center',
      internal: true,
      width: 48,
      tooltip: {
        contents: (
          <VStack align="center">
            <Text display="block">MAC address in</Text>
            <Text display="block">security appliance MAC table</Text>
          </VStack>
        ),
      },
    },
    cell: (props) => {
      if (
        props.row.hardwareDevice?.__typename === 'AccessPointHardwareDevice' &&
        props.row.hardwareDevice?.isInCurrentControllerMACTable
      ) {
        return (
          <Tooltip asChild={false} contents="Available in security appliance MAC table">
            <Icon
              icon="checkmark"
              color={{
                light: 'iconPositiveLight',
                dark: 'iconPositiveDark',
              }}
              size={space(16)}
            />
          </Tooltip>
        );
      }
      return (
        <Tooltip asChild={false} contents="Not available in security appliance MAC table">
          <NoValue />
        </Tooltip>
      );
    },
  },
);

const useColumns = () => {
  const isOperator = useIsOperator({ respectDemoMode: true });
  return useMemo(() => (isOperator ? operatorColumns : commonColumns), [isOperator]);
};

export default function AccessPointsList({
  containerRef,
}: {
  containerRef: RefObject<HTMLDivElement>;
}) {
  const columns = useColumns();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const isCOSEnabled = useNosFeatureEnabled(NosFeature.COS2);
  const closeDrawer = useCloseDrawerCallback();
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const accessPointsData = useGraphQL(AccessPointsQuery, {
    networkUUID: network.UUID,
    includeUptime,
    includeIsDev: hasPermission(PermissionType.PermNetworkDevicesReadRestricted),
  }).data;
  expectDefinedOrThrow(accessPointsData, new ResourceNotFoundError('Unable to load access points'));

  const accessPoints = useMemo(
    () =>
      accessPointsData.virtualDevicesForNetwork.filter(
        (s) => s.deviceType === VirtualDeviceType.AccessPoint,
      ),
    [accessPointsData],
  );
  const hasMismatchedRealm = useMemo(
    () =>
      accessPointsData.virtualDevicesForNetwork.some((d) =>
        deviceHasMismatchedRealm(d.hardwareDevice),
      ),
    [accessPointsData],
  );

  const allColumns = useMemo(() => {
    if (isCOSEnabled && isOperator) {
      const newColumns = [...columns];
      newColumns.splice(2, 0, macTableColumn);
      if (hasMismatchedRealm) {
        newColumns.push(
          builder.data((device) => device.hardwareDevice?.serialNumber ?? '', {
            id: 'mismatched-realm',
            header: 'Mismatched Realm',
            meta: {
              internal: true,
            },
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: (props) => {
              if (deviceHasMismatchedRealm(props.row.hardwareDevice)) {
                return (
                  <Tooltip
                    asChild={false}
                    contents="Configured realm does not match device's IsDev"
                  >
                    <Badge
                      arrangement="hidden-label"
                      size="small"
                      ends="pill"
                      variant="negative"
                      icon="checkmark"
                    >
                      Mismatched Realm
                    </Badge>
                  </Tooltip>
                );
              }
              return (
                <Badge
                  arrangement="hidden-label"
                  size="small"
                  ends="pill"
                  variant="positive"
                  icon="checkmark"
                >
                  Valid Realm
                </Badge>
              );
            },
          }),
        );
      }
      return newColumns;
    }
    return columns;
  }, [isCOSEnabled, isOperator, columns, hasMismatchedRealm]);

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useState<SortingState>([{ id: 'ap-label', desc: false }]);
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointDrawerPage);
  const batchParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointBatchEditPage);

  const { multiSelectedRows, onRowMultiSelectionChange } = useBatchEdit({
    initialState: batchParams?.accessPointUUIDs.split(','),
    onChange: (rows) => {
      const ids = Object.keys(rows);

      if (!ids.length) {
        closeDrawer();
      } else {
        navigate(
          makeDrawerLink(window.location, paths.drawers.AccessPointBatchEditPage, {
            accessPointUUIDs: ids.join(','),
            networkSlug: network.slug,
            companyName,
          }),
        );
      }
    },
  });

  // clear batch selection if drawer is closed
  useEffect(() => {
    if (batchParams === null) {
      onRowMultiSelectionChange({});
    }
  }, [batchParams, onRowMultiSelectionChange]);

  if (accessPoints.length === 0) {
    return (
      <EmptyState
        icon="access-point"
        heading="No access points"
        action={
          isOperator && (
            <Button
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.AccessPointCreatePage, {
                companyName,
                networkSlug: network.slug,
              })}
              arrangement="leading-icon"
              icon="plus"
              internal
            >
              Add access point
            </Button>
          )
        }
      />
    );
  }

  return (
    <AutoTable
      key="profiles-list"
      isVirtual
      tableContainerRef={containerRef}
      columns={allColumns}
      data={accessPoints}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.AccessPointDrawerPage, {
          companyName,
          networkSlug: network.slug,
          uuid: row.UUID,
          tab: ValidAPDrawerTabs.AccessPoint,
        })
      }
      isRowSelected={(row) => row.UUID === drawerParams?.uuid}
      onRowDeselect={closeDrawer}
      enableMultiRowSelection
      multiSelectedRows={multiSelectedRows}
      onRowMultiSelectionChange={onRowMultiSelectionChange}
      getRowId={(r) => r.UUID}
    />
  );
}

import {
  Alert,
  Badge,
  Body,
  darkThemeSelector,
  HardwareIcon,
  Icon,
  Large,
  Small,
  space,
  styled,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { fade, palette } from '@meterup/atto/src/common/colors';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { colors, shadows } from '@meterup/atto/src/stitches.config';
import { useIsOperator } from '@meterup/authorization';
import { DateTime } from 'luxon';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { UptimeForInterface } from '../useUptimeStats';
import type { AggregatedNetworkInfo } from '../utils/aggregateStatsForNetworks';
import { paths } from '../../../constants';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { ucfirst } from '../../../utils/strings';
import { ControllerBadge, DeviceBadge } from '../../Overview/StatsList';
import { LocationUptimeGraphs } from '../views/LocationUptimeGraph';

const HubCardNoDevices = styled(Small, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  color: colors.bodyNeutralLight,
  textAlign: 'center',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

function DevicesOverview({ network }: { network: AggregatedNetworkInfo }) {
  if (network.totalDevicesForNetwork > 0) {
    return (
      <SummaryList gutter="none">
        {network.devices.controllers.total > 0 && (
          <SummaryListRow>
            <SummaryListKey>Security appliance</SummaryListKey>
            <SummaryListValue>
              <ControllerBadge isOnline={network.devices.controllers.online >= 1} />
            </SummaryListValue>
          </SummaryListRow>
        )}

        {network.devices.aps.total > 0 && (
          <SummaryListRow>
            <SummaryListKey>Access points</SummaryListKey>
            <SummaryListValue>
              <DeviceBadge
                type="access-point"
                offlineCount={network.devices.aps.offline}
                onlineCount={network.devices.aps.online}
              />
            </SummaryListValue>
          </SummaryListRow>
        )}
        {network.devices.switches.total > 0 && (
          <SummaryListRow>
            <SummaryListKey>Switches</SummaryListKey>
            <SummaryListValue>
              <DeviceBadge
                type="switch"
                offlineCount={network.devices.switches.offline}
                onlineCount={network.devices.switches.online}
              />
            </SummaryListValue>
          </SummaryListRow>
        )}
      </SummaryList>
    );
  }

  return <HubCardNoDevices>No devices assigned yet.</HubCardNoDevices>;
}

function PreLiveBody({
  deployment,
}: {
  deployment: Exclude<AggregatedNetworkInfo['deployment'], undefined>;
}) {
  return (
    <SummaryList gutter="none">
      <SummaryListRow>
        <SummaryListKey>Job stage</SummaryListKey>
        <SummaryListValue>
          <Body>{ucfirst(deployment.jobStage)}</Body>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Target go-live date</SummaryListKey>
        <SummaryListValue>
          <Body>
            {deployment.targetGoLiveDate
              ? DateTime.fromISO(deployment.targetGoLiveDate).toLocaleString()
              : null}
          </Body>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>On-site contact</SummaryListKey>
        <SummaryListValue>
          <Body>{deployment.onSiteContact?.name || 'Not assigned'}</Body>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Open action items</SummaryListKey>
        <SummaryListValue>
          <Body>{deployment.actionItems.length}</Body>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Tags</SummaryListKey>
        <SummaryListValue>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: space(4) }}>
            {deployment.tags.map((t) => (
              <Badge size="small" key={t.id}>
                {t.label}
              </Badge>
            ))}
          </div>
        </SummaryListValue>
      </SummaryListRow>
    </SummaryList>
  );
}

const HubCardHeading = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const HubCardChevron = styled(Icon, {
  color: colors.gray300,

  [darkThemeSelector]: {
    color: colors.gray500,
  },
});

const dividerStyles = {
  content: '',
  position: 'absolute',
  zIndex: 1,
  left: '16px',
  right: '16px',
  display: 'block',
  height: '1px',
  backgroundColor: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
};

const HubCardHeader = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$12',
  padding: '$12 $16',

  '&:after': {
    ...dividerStyles,
    bottom: '-0.5px',
  },
});

const HubCardStat = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

const HubCardStats = styled('div', {
  display: 'flex',
  gap: '$12',
  padding: '$12 $16',
});

const HubAlertContainer = styled('div', {
  paddingLeft: '$12',
  paddingRight: '$12',
  paddingTop: '$12',
  paddingBottom: '0',
});

const HubCardMetrics = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$8',
  padding: '$12 $16',

  '&:before': {
    ...dividerStyles,
    top: '-0.5px',
  },
});

const HubCardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: '320px',
  height: 'auto',
  backgroundColor: fade(palette.bgApplicationLight, 0.9),
  boxShadow: shadows.overlayLight,
  borderRadius: '10px',

  [darkThemeSelector]: {
    backgroundColor: fade(palette.bgApplicationDark, 0.9),
    boxShadow: shadows.overlayDark,
  },

  [selectors.hover]: {
    backgroundColor: colors.bgApplicationLight,

    [darkThemeSelector]: {
      backgroundColor: colors.bgApplicationDark,
    },
  },

  [selectors.focus]: {
    boxShadow: shadows.focusRingLight,
    outline: 'none',

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },
});

function HubCardContents(props: {
  label: string;
  address: string;
  isLinked?: boolean;
  stats?: React.ReactNode;
  body: React.ReactNode;
  alertText?: string;
  link?: string;
}) {
  return (
    <>
      <HubCardHeader as={props.link ? Link : 'div'} to={props.link}>
        <HubCardHeading>
          <Large weight="bold">{props.label}</Large>
          <Small>{props.address}</Small>
        </HubCardHeading>
        {props.isLinked && <HubCardChevron icon="chevron-right" size={space(12)} />}
      </HubCardHeader>
      {props.alertText && (
        <HubAlertContainer>
          <Alert icon="attention" variant="attention" heading={props.alertText} type="inline" />
        </HubAlertContainer>
      )}
      {props.stats && <HubCardStats>{props.stats}</HubCardStats>}
      <HubCardMetrics>{props.body}</HubCardMetrics>
    </>
  );
}

let cachedSandboxUptimeStats: UptimeForInterface[] | null = null;
function calcSandboxUptimeStats({ simulateDowntime }: { simulateDowntime?: boolean }) {
  if (cachedSandboxUptimeStats) {
    if (simulateDowntime === false) return cachedSandboxUptimeStats;
  }

  const wan0: UptimeForInterface = {
    label: 'wan0',
    phyInterfaceUUID: 'd9e5b9dc-6264-4795-b286-0cf79b3be099',
    stats: [],
  };

  const wan1: UptimeForInterface = {
    label: 'wan1',
    phyInterfaceUUID: '710c8ed8-d683-4593-bb90-39f1a0eb7cfb',
    stats: [],
  };

  const dt = new Date();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 82; i++) {
    let quality = 1;

    // Simulates downtime in a deterministic way such that the same downtime is always the same even across page refreshes
    if (simulateDowntime && i > 55 && i < 70) {
      if (i % 2 === 0) {
        quality = 0.1 + i * 0.005;
      } else {
        quality = 0.1 + i * 0.008;
      }
    }

    dt.setSeconds(dt.getSeconds() - 1000);
    const timestamp = new Date(dt);
    wan0.stats.push({
      value: quality,
      timestamp,
    });
    wan1.stats.push({
      value: 1,
      timestamp,
    });
  }
  if (simulateDowntime === false) {
    cachedSandboxUptimeStats = [wan0, wan1];
    return cachedSandboxUptimeStats;
  }
  return [wan0, wan1];
}

const DEMO_COMPANIES = ['higher-ground', 'discount-tire', 'meter'];

function useIsDemoCompany(companySlug: string) {
  const isOperator = useIsOperator();
  const isDemoCo = useMemo(() => DEMO_COMPANIES.includes(companySlug), [companySlug]);
  return isOperator && isDemoCo;
}

const DEMO_CARD_IDX = 1;

function HubCardNotMemo({
  network,
  company,
  uptimeStats,
  idx,
}: {
  idx?: number;
  network: AggregatedNetworkInfo;
  company: string;
  uptimeStats?: UptimeForInterface[];
}) {
  const isUptimeFlagEnabled = useFeatureFlags()['config-2-hub-uptime-graphs'];
  // Only sandbox data has deployments, so if there's no deployment, we can assume the network is live
  const isNetworkLive = !network.deployment || network.deployment.jobStage === 'complete';
  const isDemoCompany = useIsDemoCompany(company);
  const shouldShowUptime = (isUptimeFlagEnabled && isNetworkLive) || isDemoCompany;

  const sandboxUptimeStats = useMemo(() => {
    // For WWT demo
    if (isDemoCompany) {
      // Simulate downtime on the second card
      return calcSandboxUptimeStats({ simulateDowntime: idx === DEMO_CARD_IDX });
    }

    if (!network.deployment) return null;
    if (network.deployment.jobStage !== 'complete') return null;

    return calcSandboxUptimeStats({ simulateDowntime: false });
  }, [network.deployment, idx, isDemoCompany]);

  if (network.deployment && network.deployment.jobStage !== 'complete') {
    return (
      <HubCardContainer as="div">
        <HubCardContents
          label={network.label}
          address={network.mailingAddress}
          body={<PreLiveBody deployment={network.deployment} />}
        />
      </HubCardContainer>
    );
  }

  const link = makeLink(paths.pages.IndividualNetworkRootPage, {
    companyName: company,
    networkSlug: network.slug,
  });

  const sandboxApCount = network.deployment ? 7 : undefined;
  const sandboxSwitchCount = network.deployment ? 2 : undefined;
  const sandboxControllerCount = network.deployment ? 2 : undefined;

  if (shouldShowUptime) {
    return (
      <HubCardContainer>
        <HubCardContents
          link={link}
          isLinked
          label={network.label}
          address={network.mailingAddress}
          stats={
            <>
              <HubCardStat>
                <DeviceBadge
                  variant="condensed"
                  type="security-appliance"
                  offlineCount={network.devices.controllers.offline}
                  onlineCount={sandboxControllerCount ?? network.devices.controllers.online}
                />
              </HubCardStat>
              <HubCardStat>
                <DeviceBadge
                  variant="condensed"
                  type="access-point"
                  offlineCount={network.devices.aps.offline}
                  onlineCount={sandboxApCount ?? network.devices.aps.online}
                />
              </HubCardStat>
              <HubCardStat>
                <DeviceBadge
                  type="switch"
                  variant="condensed"
                  offlineCount={network.devices.switches.offline}
                  onlineCount={sandboxSwitchCount ?? network.devices.switches.online}
                />
              </HubCardStat>
            </>
          }
          body={<LocationUptimeGraphs stats={sandboxUptimeStats || uptimeStats} />}
          alertText={idx === DEMO_CARD_IDX && isDemoCompany ? 'WAN quality degraded' : undefined}
        />
      </HubCardContainer>
    );
  }

  return (
    <HubCardContainer>
      <HubCardContents
        link={link}
        isLinked
        label={network.label}
        address={network.mailingAddress}
        stats={
          <>
            <HubCardStat>
              <HardwareIcon variant="simple" size={space(20)} hardware="security-appliance" />
              <Large weight="bold">{network.devices.controllers.total}</Large>
            </HubCardStat>
            <HubCardStat>
              <HardwareIcon variant="simple" size={space(20)} hardware="access-point" />
              <Large weight="bold">{network.devices.aps.total}</Large>
            </HubCardStat>
            <HubCardStat>
              <HardwareIcon variant="simple" size={space(20)} hardware="switch" />
              <Large weight="bold">{network.devices.switches.total}</Large>
            </HubCardStat>
          </>
        }
        body={<DevicesOverview network={network} />}
      />
    </HubCardContainer>
  );
}

export const HubCard = memo(HubCardNotMemo);

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import PowerDistributionUnitEdit from '../../../../../components/Hardware/PowerDistributionUnits/PowerDistributionUnitEdit';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/edit',
  title: 'Edit power distribution unit - Power distribution units - Hardware',
});

export default function PowerDistributionUnitEditPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.PowerDistributionUnitEditPage);
  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
        !!nosFlags[NosFeature.POS]
      }
      should404OnAccessDenied
    >
      <PowerDistributionUnitEdit uuid={drawerParams?.uuid!} />
    </IsPermitted>
  );
}

import { Skeleton } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import * as d3 from 'd3';
import React, { useMemo } from 'react';

import { graphql } from '../../../gql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { getStep } from '../../../utils/chart_utils';
import { TimeSeriesChart } from '../../TimeSeriesChart';
import { calculateRange, getDurationSeconds } from './utils';

export const activeClientsQuery = graphql(`
  query activeClients(
    $networkUUID: UUID!
    $filter: MetricsFilterInput!
    $input: ActiveClientsInput
  ) {
    activeClients(networkUUID: $networkUUID, filter: $filter, input: $input) {
      values {
        timestamp
        value
      }
    }
  }
`);

export default function ActiveClientsChart() {
  const network = useNetwork();
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const params = useMemo(
    () => ({
      networkUUID: network.UUID,
      filter: {
        durationSeconds: getDurationSeconds(currentTimePeriod),
        stepSeconds: getStep(currentTimePeriod),
      },
      input: {
        includeMeterHardware: false,
      },
    }),
    [currentTimePeriod, network],
  );

  const { data: activeClientsData, isError: activeClientsQueryError } = useGraphQL(
    activeClientsQuery,
    params,
    { useErrorBoundary: false },
  );

  const parsedDataWithDate = useMemo(
    () =>
      activeClientsData?.activeClients.values.map(({ timestamp, ...rest }) => ({
        timestamp: new Date(timestamp),
        ...rest,
      })),
    [activeClientsData],
  );
  if (parsedDataWithDate) {
    return (
      <TimeSeriesChart
        title="Connected clients"
        series={[
          {
            data: parsedDataWithDate,
            curve: d3.curveStepBefore,
            range: calculateRange(parsedDataWithDate),
            series_type: 'area',
            series_id: 'activeClients',
            series_name: 'Connected clients',
          },
        ]}
        tooltipBody="The number of clients currently connected to the network. Includes wired and wireless clients and excludes Meter devices."
        valueFormatter={(d) => d.toFixed(0)}
        timePeriod={currentTimePeriod}
        showYAxis
        isError={activeClientsQueryError}
      />
    );
  }
  return <Skeleton height={200} width="100%" radius={6} />;
}

import { Pane, PaneContent, PaneHeader } from '@meterup/atto';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useNetworkWideCrumbs } from '../../../utils/routing';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import ISPsActions from './ISPsActions';
import ISPsList from './ISPsList';

export default function ISPs() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const networkWideCrumb = useNetworkWideCrumbs();

  return (
    <IsPermitted
      isPermitted={({ ldFlags, permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermIspRead)) && Boolean(ldFlags.isp)
      }
    >
      <Pane layoutMode="detailed">
        <PaneHeader
          back={back}
          home={home}
          crumbs={[
            ...networkWideCrumb,
            {
              type: 'page',
              page: {
                as: ReactRouterLink,
                to: makeLink(paths.pages.ISPsPage, {
                  companyName,
                  networkSlug: network.slug,
                }),
                selected: true,
                label: 'ISPs',
              },
            },
          ]}
          icon="globe"
          heading="ISPs"
          actions={
            <IsPermitted
              isPermitted={({ permissions }) =>
                permissions.hasPermission(PermissionType.PermIspWriteRestricted)
              }
            >
              <ISPsActions />
            </IsPermitted>
          }
        />
        <PaneContent>
          <ISPsList />
        </PaneContent>
      </Pane>
    </IsPermitted>
  );
}

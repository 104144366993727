import type { ReactNode } from 'react';
import React from 'react';

import type { FallbackRenderer } from '../types/types';
import { useIsCompanyAdmin } from '../hooks/useIsCompanyAdmin';

export type IsCompanyAdminProps = {
  companySlug: string;
  children: ReactNode;
} & FallbackRenderer;

/**
 * Checks if the user is _at least_ a company admin for the specified company. This means more
 *  highly permissioned roles, such as `Operator`, will also pass.
 *
 * @deprecated Use `IsPermitted` instead. Do not check the user roles directly.
 *
 * @param {Object} props - The properties required to check company admin.
 * @param {string} props.companySlug - The slug of the company.
 * @param {ReactNode} props.children - The content to render if user is a company admin.
 * @param {ReactNode} props.fallback - The content to render if user is not a company admin.
 * @return Returns the content specified in `children` if user is a company admin,
 * otherwise returns the content specified in `renderOnFailure`. Returns null if the user's role cannot be determined.
 */
export default function IsCompanyAdmin({ companySlug, children, fallback }: IsCompanyAdminProps) {
  const hasRole = useIsCompanyAdmin({ companySlug });
  if (!hasRole) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{fallback}</>;
  }
  if (hasRole) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return null;
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { HStack, PaneHeader, Small, space, styled, Tab } from '@meterup/atto';
import { colors, darkThemeSelector } from '@meterup/atto/src/stitches.config';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate } from 'react-router';
import { Link as ReactRouterLink } from 'react-router-dom';

import { listEnabledContentFiltersForCompany } from '../../../../../../api/api';
import CloudflareSVG from '../../../../../../assets/images/icons/Cloudflare';
import { DetailPageLayout } from '../../../../../../components/DetailPageLayout';
import { paths } from '../../../../../../constants';
import { useIsActiveMatcher } from '../../../../../../hooks/useIsActive';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../../providers/CurrentControllerProvider';
import { makeLink } from '../../../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  layout: 'NetworkLayout',
  name: 'LegacyDNSSecurityLayout',
});

const DNSSecurityHeading = styled('div', {
  hStack: '$10',
  paddingRight: '$6',
});

const DNSSecurityPoweredBy = styled(Small, {
  color: colors.gray500,
  [darkThemeSelector]: {
    color: colors.gray300,
  },
});

function DNSSecurityPageHeader() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const isActiveTest = useIsActiveMatcher();

  return (
    <PaneHeader
      icon="dns-security"
      heading={
        <DNSSecurityHeading>
          <span>DNS security</span>
          <HStack align="center" spacing={space(4)}>
            <DNSSecurityPoweredBy>Powered by</DNSSecurityPoweredBy>
            <CloudflareSVG
              style={{ cursor: 'pointer' }}
              onClick={() => window.open('https://www.cloudflare.com', '_blank')}
            />
          </HStack>
        </DNSSecurityHeading>
      }
      tabs={
        <>
          <Tab
            as={ReactRouterLink}
            to={makeLink(paths.pages.LegacyDNSSecurityDomainsPage, { companyName, controllerName })}
            selected={isActiveTest(paths.pages.LegacyDNSSecurityDomainsPage)}
          >
            Domains
          </Tab>
          <Tab
            as={ReactRouterLink}
            to={makeLink(paths.pages.DNSSecurityCategoriesPage, {
              companyName,
              controllerName,
            })}
            selected={isActiveTest(paths.pages.DNSSecurityCategoriesPage)}
          >
            Categories
          </Tab>
        </>
      }
    />
  );
}

export default function LegacyDNSSecurityLayout() {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();

  const status = useQuery(
    ['dns_security', companyName, 'status'],
    async () => listEnabledContentFiltersForCompany(companyName),
    {
      suspense: true,
    },
  ).data;

  const isEnabled = status?.enabled.some((d) => d.controller_name === controllerName) ?? false;

  if (!isEnabled) {
    return (
      <Navigate
        to={makeLink(paths.pages.LegacyDNSSecurityPage, {
          companyName,
          controllerName,
        })}
        replace
      />
    );
  }

  return <DetailPageLayout header={<DNSSecurityPageHeader />} />;
}

import type { ButtonSizeProp, ButtonVariantProp } from '@meterup/atto';
import type { SortingState } from '@meterup/common';
import {
  Badge,
  Button,
  EmptyState,
  HStack,
  Icon,
  PaneContent,
  space,
  styled,
  Text,
  Tooltip,
} from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import type { StaticRoute } from './utils';
import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { NoValue } from '../NoValue';
import IsPermitted from '../permissions/IsPermitted';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { staticRoutesForNetworkQuery } from './utils';

const PrefixContainer = styled(HStack, {
  whiteSpace: 'nowrap',
});

const builder = createColumnBuilder<StaticRoute>();

const columns = [
  builder.data((row) => (row.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      width: 40,
      tooltip: {
        contents: 'Enabled',
      },
    },
    cell: ({ row }) =>
      row.isEnabled ? (
        <Tooltip contents="Enabled">
          <Icon
            icon="checkmark"
            color={{
              light: 'iconPositiveLight',
              dark: 'iconPositiveDark',
            }}
            size={space(16)}
          />
        </Tooltip>
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.dstPrefix, {
    id: 'dest-prefix',
    header: 'Destination / mask',
    cell: ({ value }) =>
      value === '0.0.0.0/0' ? (
        <Badge size="small" ends="card" variant="neutral">
          Any
        </Badge>
      ) : (
        <PrefixContainer spacing={space(8)} align="center">
          <Text family="monospace">{value.replace('/', ' / ')}</Text>
        </PrefixContainer>
      ),
  }),
  builder.data(
    (row) =>
      row.gatewayIP ??
      row.gatewayDHCPStaticMapping?.ipAddress ??
      row.gatewayDHCPStaticMapping?.hostname,
    {
      id: 'gateway',
      header: 'Gateway',
      cell: ({ value, row }) =>
        value ? (
          <PrefixContainer spacing={space(8)} align="center">
            {row.gatewayDHCPStaticMapping && (
              <Tooltip contents="DHCP static mapping" asChild={false}>
                <Icon icon="ip-address" />
              </Tooltip>
            )}
            <Text family="monospace">{value}</Text>
          </PrefixContainer>
        ) : (
          <NoValue />
        ),
    },
  ),
];

export function AddStaticRouteButton({
  variant = 'secondary',
  size,
}: {
  variant?: ButtonVariantProp;
  size?: ButtonSizeProp;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Button
      condense
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.StaticRouteCreatePage, {
        companyName,
        networkSlug: network.slug,
      })}
      arrangement="leading-icon"
      icon="plus"
      variant={variant}
      size={size}
    >
      Add static route
    </Button>
  );
}

export default function StaticRoutes() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const staticRoutes = useGraphQL(staticRoutesForNetworkQuery, {
    networkUUID: network.UUID,
  }).data?.staticRoutesForNetwork;

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const params = Nav.useRegionParams('drawer', paths.drawers.VLANEditPage);
  const onRowDeselect = useCloseDrawerCallback();

  if (!staticRoutes?.length) {
    return (
      <EmptyState
        icon="connection"
        heading="You have no static routes"
        action={
          <IsPermitted permissions={PermissionType.PermNetworkRouteWrite}>
            <AddStaticRouteButton />
          </IsPermitted>
        }
      />
    );
  }

  return (
    <PaneContent>
      <AutoTable
        columns={columns}
        data={staticRoutes ?? []}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        globalFilter={globalFilter}
        onRowDeselect={onRowDeselect}
        isRowSelected={(row) => row.UUID === params?.uuid}
        getLinkTo={(row) =>
          makeDrawerLink(window.location, paths.drawers.StaticRouteEditPage, {
            networkSlug: network.slug,
            companyName,
            routeUUID: row.UUID,
          })
        }
      />
    </PaneContent>
  );
}

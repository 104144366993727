import type { AxiosAPIError, CompanyContractsResponse } from '@meterup/connect-api';
import { get } from '@meterup/connect-api/src/axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useCurrentCompany } from '../providers/CurrentCompanyProvider';

function makeQueryKey(companyLocationSID: string) {
  return ['connection', companyLocationSID];
}

export function useCurrentCompanyLocation() {
  const params = useParams<{ companyLocationSID: string }>();
  return params.companyLocationSID;
}

export function useCompanyLocationContract(companySID: string, companyLocationSID: string) {
  const queryKey = makeQueryKey(companyLocationSID);
  const results = useQuery<unknown, AxiosAPIError, CompanyContractsResponse>({
    queryKey,
    queryFn: () =>
      get<CompanyContractsResponse>(
        `v1/companies/${companySID}/connect/connections/${companyLocationSID}`,
      ),
    // @ts-ignore: 2769
    refetchInterval: false,
    suspense: true,
  });
  return useMemo(() => results.data, [results]);
}

export function useInvalidateCompanyLocationContract(companyLocationSID: string) {
  const queryClient = useQueryClient();
  const queryKey = makeQueryKey(companyLocationSID);
  return useCallback(() => queryClient.invalidateQueries(queryKey), [queryClient, queryKey]);
}

export function useCurrentCompanyLocationContract() {
  const companySID = useCurrentCompany();
  const companyName = useCurrentCompanyLocation();
  return useCompanyLocationContract(companySID, companyName || '');
}

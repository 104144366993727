import type { HardwareIconPropHardware } from '@meterup/atto';

import { DeviceType, VirtualDeviceType } from '../gql/graphql';

// We explicitly don't want to error or return something else at the end
// of this because we want TypeScript to yell at us if this enum changes.
export const deviceTypeToHardwareIconPropHardware = (
  deviceType: DeviceType | VirtualDeviceType,
  // eslint-disable-next-line consistent-return
): HardwareIconPropHardware => {
  switch (deviceType) {
    case DeviceType.AccessPoint:
    case VirtualDeviceType.AccessPoint:
    case VirtualDeviceType.Observer: // TODO: distinct icon for observers ?
      return 'access-point';
    case DeviceType.Controller:
    case VirtualDeviceType.Controller:
      return 'security-appliance';
    case DeviceType.Switch:
    case VirtualDeviceType.Switch:
      return 'switch';
    case DeviceType.PowerDistributionUnit:
    case VirtualDeviceType.PowerDistributionUnit:
      return 'pdu';
  }
};

import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import RADIUSProfileEditDrawer from '../../../../../components/NetworkWide/RADIUSProfiles/RADIUSProfileEditDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/radius-profiles/:uuid',
  title: 'Edit RADIUS profile - RADIUS profiles - Network-wide',
});

export default function RADIUSProfileEditPage() {
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.RADIUSProfileEditPage),
  );

  return (
    <IsPermitted
      isPermitted={({ permissions, pathParams }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkSettingsWrite) && !!pathParams?.uuid,
        )
      }
      should404OnAccessDenied
      path={Meta().path}
    >
      <RADIUSProfileEditDrawer uuid={uuid} />
    </IsPermitted>
  );
}

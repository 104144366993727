import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefined,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { AUTH_IEEE8021X } from '@meterup/config';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ValidServiceSetData } from '../../../../../validations/validServiceSetData';
import { fetchControllerSSIDs } from '../../../../../api/api';
import { ConfirmNetworkChangesDrawer } from '../../../../../components/Drawers/ConfirmNetworkChangesDrawer/ConfirmNetworkChangesDrawer';
import { DiscardChangesButton } from '../../../../../components/form_buttons';
// import { FormikConditional } from '../../../../../components/FormikConditional';
import { NetworkJoinWidget } from '../../../../../components/NetworkJoinWidget';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useToggle } from '../../../../../hooks/useToggle';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { validServiceSetData } from '../../../../../validations/validServiceSetData';
import { modelToFormData } from './form_data';
import { useUpdateServiceSetMutation } from './hooks';
import { BandsField, NoPasswordField, PasswordField, SSIDField, VLANField } from './ssid_fields';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/ssids/:id/edit',
});

export default function LegacySSIDEditPage() {
  const { id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacySSIDEditPage),
  );
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();

  const closeDrawer = useCloseDrawerCallback();
  const configModel = useControllerConfig(controllerName);

  const serviceSetModel = configModel.getServiceSetByStableId(id);

  expectDefinedOrThrow(serviceSetModel, new ResourceNotFoundError('SSID not found'));

  const currentSSIDs =
    useQuery(['controller', controllerName, 'ssids'], () => fetchControllerSSIDs(controllerName), {
      suspense: true,
    }).data ?? [];

  const navigate = useNavigate();

  const isConfirmingChanges = useToggle();

  const updateConfigMutation = useUpdateServiceSetMutation(
    controllerName,
    configModel,
    serviceSetModel,
    () => {
      notify('Changes saved successfully', {
        variant: 'positive',
        icon: 'checkmark',
      });
      isConfirmingChanges.toggleOff();
    },
    () => {
      notify('Something went wrong. Please try again', {
        variant: 'negative',
        icon: 'cross',
      });
    },
  );

  const currentSSID = currentSSIDs.find((d) => d.ssid === serviceSetModel?.ssid);
  const currentPassword = currentSSID?.password?.value ?? null;

  const initialValues = useMemo(() => modelToFormData(serviceSetModel), [serviceSetModel]);

  return (
    <Formik<ValidServiceSetData>
      validate={withZodSchema(validServiceSetData)}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (isConfirmingChanges.value) {
          updateConfigMutation.mutate(values);
        } else {
          isConfirmingChanges.toggleOn();
        }
      }}
      enableReinitialize
    >
      <StyledForm>
        {isConfirmingChanges.value ? (
          <ConfirmNetworkChangesDrawer onCancel={isConfirmingChanges.toggleOff} />
        ) : (
          <Drawer>
            <DrawerHeader
              icon="ssid"
              heading="Edit SSID"
              actions={
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>

                  <DropdownMenuPopover align="end">
                    <DropdownMenuItem
                      onSelect={() =>
                        navigate(
                          makeDrawerLink(window.location, paths.drawers.LegacySSIDRemovePage, {
                            id,
                            controllerName,
                            companyName,
                          }),
                        )
                      }
                      icon="trash-can"
                    >
                      Remove
                    </DropdownMenuItem>
                  </DropdownMenuPopover>
                </DropdownMenu>
              }
              onClose={closeDrawer}
            />
            <DrawerContent>
              {isDefined(currentSSID) && (
                <NetworkJoinWidget
                  ssid={currentSSID.ssid}
                  password={currentSSID.password?.value ?? ''}
                />
              )}
              <SSIDField />
              {currentSSID?.authentication === AUTH_IEEE8021X ? (
                <NoPasswordField />
              ) : (
                <PasswordField currentValue={currentPassword} />
              )}

              <BandsField />
              <VLANField model={configModel} />
            </DrawerContent>
            {/* <FormikConditional<ValidServiceSetData> condition={(values, ctx) => ctx.dirty}> */}
            <DrawerFooter
              actions={
                <>
                  <DiscardChangesButton />
                  <Button type="submit">Save</Button>
                </>
              }
            />
            {/* </FormikConditional> */}
          </Drawer>
        )}
      </StyledForm>
    </Formik>
  );
}

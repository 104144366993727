import {
  Alert,
  Button,
  CopyBox,
  darkThemeSelector,
  Icon,
  Select,
  SelectItem,
  Skeleton,
  Small,
  space,
  styled,
  Tooltip,
} from '@meterup/atto';
import {
  colors,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
  Tooltip as CommonTooltip,
} from '@meterup/common';
import { AUTH_IEEE8021X, PSKSchemeType } from '@meterup/config';
import { useGraphQL } from '@meterup/graphql';
import { ErrorBoundary } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { fetchControllerSSIDs } from '../api/api';
import { paths } from '../constants';
import { SsidEncryptionProtocol } from '../gql/graphql';
import { useCurrentControllerOrNull } from '../hooks/useCurrentControllerOrNull';
import { useNetwork } from '../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../hooks/useNosFeatures';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { getWiFiQRCodeConnectionString } from '../utils/getWiFiQRCodeConnectionString';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { QRCode } from './QRCode';
import { SSIDsQuery } from './Wireless/SSIDs/SSIDsUtils';

const WIFIDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  width: '100%',
});

const WIFIDetailsActions = styled('div', {
  hStack: '$6',
});

const WIFIDetailsPasswordDisplay = styled('div', {
  position: 'relative',
  width: '100%',
});

const WIFIDetailsPassword = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const WIFIDetailsPasswordOverlay = styled('div', {
  position: 'absolute',
  zIndex: 3,
  inset: '2px',
  display: 'flex',
  padding: '3px 0 0 10px',
  backgroundColor: colors.bgNeutralLight,
  pointerEvents: 'none',

  [darkThemeSelector]: {
    backgroundColor: colors.bgNeutralDark,
  },

  '&::before': {
    content: '••••••••••••••',
    display: 'block',
    fontSize: '$14',
    color: colors.bodyNeutralLight,

    [darkThemeSelector]: {
      color: colors.bodyNeutralDark,
    },
  },
});

type SSID = {
  ssid: string;
  password: string;
  hasPassword: boolean;
  wpa2: boolean;
};

const WIFIDetailsPasswordCopier = styled(CopyBox, {
  width: '100%',
});

const WIFIDetailsNoPassword = styled(Small, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$4',
  width: '100%',
  padding: '$8 $12',
  backgroundColor: colors.bgNeutralLight,
  borderRadius: '$6',
  strokeAll: colors.strokeNeutralLight,
  color: colors.gray500,
  fontWeight: '$bold',

  [darkThemeSelector]: {
    backgroundColor: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
    color: colors.gray300,
  },
});

const WIFIDetailsSkeleton = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
  padding: '5px 0',
});

const WIFIDetailsError = styled('div', {
  width: '100%',
  height: '100%',
});

export function WIFIDetailsNoPasswordInner({ ssid }: { ssid: SSID }) {
  if (ssid.wpa2) {
    return (
      <Tooltip contents="Authentication for this SSID is done by logging in with your credentials.">
        <WIFIDetailsNoPassword>
          <Icon icon="padlock" size={space(10)} />
          <Small weight="bold">WPA2-Enterprise</Small>
        </WIFIDetailsNoPassword>
      </Tooltip>
    );
  }

  return <WIFIDetailsNoPassword>No password required</WIFIDetailsNoPassword>;
}

function WIFIDetailsInner({
  controllerName,
  ssids,
}: {
  controllerName?: string | null;
  ssids: SSID[];
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const [currentSSID, setCurrentSSID] = useState<SSID>(ssids[0]);
  const [showPassword, setShowPassword] = useState(false);
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);

  useEffect(() => {
    setCurrentSSID(ssids[0]);
  }, [ssids]);

  const ssidSelectionChangeHandler = (val: string) => {
    const ssid = ssids.find((s) => s.ssid === val);
    if (ssid) setCurrentSSID(ssid);
  };

  if (ssids.length === 0 || !currentSSID) return null;

  return (
    <WIFIDetailsContainer>
      <Select
        aria-label="SSID"
        value={currentSSID.ssid}
        onValueChange={ssidSelectionChangeHandler}
        controlSize="medium"
        width="100%"
        icon="ssid"
      >
        {ssids.map((ssid) => (
          <SelectItem key={ssid.ssid}>{ssid.ssid}</SelectItem>
        ))}
      </Select>
      <WIFIDetailsPassword>
        {currentSSID.hasPassword ? (
          <WIFIDetailsPasswordDisplay>
            {!showPassword && <WIFIDetailsPasswordOverlay />}
            <Small family="monospace" weight="bold">
              <WIFIDetailsPasswordCopier
                aria-label={`Copy the password for ${currentSSID.ssid}`}
                relation="standalone"
                size="large"
                value={currentSSID.password}
              >
                {currentSSID.password}
              </WIFIDetailsPasswordCopier>
            </Small>
          </WIFIDetailsPasswordDisplay>
        ) : (
          <WIFIDetailsNoPasswordInner ssid={currentSSID} />
        )}
      </WIFIDetailsPassword>
      <WIFIDetailsActions>
        {currentSSID.hasPassword && (
          <Tooltip contents={showPassword ? 'Hide password' : 'Show password'}>
            <Button
              variant="secondary"
              arrangement="hidden-label"
              icon={showPassword ? 'eye-closed' : 'eye-open'}
              size="medium"
              width="100%"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide password' : 'Show password'}
            </Button>
          </Tooltip>
        )}
        <CommonTooltip
          content={
            <QRCode text={getWiFiQRCodeConnectionString(currentSSID.ssid, currentSSID.password)} />
          }
          toggle
        >
          <Button
            variant="secondary"
            arrangement="hidden-label"
            icon="qrcode"
            size="medium"
            width="100%"
          >
            Show QR code
          </Button>
        </CommonTooltip>
        {isWOS2Enabled && network.slug && (
          <Tooltip contents="Open tablet view">
            <Button
              as={Link}
              to={makeLink(paths.pages.NetworkTabletJoinInstructionsPage, {
                ssid: currentSSID.ssid,
                companyName,
                networkSlug: network.slug,
              })}
              target="_blank"
              variant="secondary"
              icon="phone"
              size="medium"
              width="100%"
              arrangement="hidden-label"
            >
              Open tablet view
            </Button>
          </Tooltip>
        )}
        {!isWOS2Enabled && controllerName && (
          <Tooltip contents="Open tablet view">
            <Button
              as={Link}
              to={makeLink(paths.pages.ControllerTabletJoinInstructionsPage, {
                ssid: currentSSID.ssid,
                companyName,
                controllerName,
              })}
              target="_blank"
              variant="secondary"
              icon="phone"
              size="medium"
              width="100%"
              arrangement="hidden-label"
            >
              Open tablet view
            </Button>
          </Tooltip>
        )}
      </WIFIDetailsActions>
    </WIFIDetailsContainer>
  );
}

function WIFIDetailsLoader() {
  const controllerName = useCurrentControllerOrNull();
  const network = useNetwork();
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);

  const ssidData = useQuery(
    ['controller', controllerName!, 'ssids'],
    () => fetchControllerSSIDs(controllerName as string),
    {
      enabled: !!controllerName && !isWOS2Enabled,
      suspense: true,
    },
  ).data;

  const config2SSIDData = useGraphQL(
    SSIDsQuery,
    { networkUUID: network.UUID },
    { enabled: isWOS2Enabled },
  )?.data?.ssidsForNetwork;

  let ssids: SSID[];

  if (isWOS2Enabled) {
    expectDefinedOrThrow(config2SSIDData, new ResourceNotFoundError("Couldn't load SSIDs"));
    ssids = config2SSIDData.map((ssid) => ({
      ssid: ssid.ssid,
      password: ssid.password ?? '',
      hasPassword:
        isDefinedAndNotEmpty(ssid.encryptionProtocol) &&
        ssid.encryptionProtocol !== SsidEncryptionProtocol.Wpa2Enterprise &&
        ssid.encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise,
      wpa2:
        ssid.encryptionProtocol === SsidEncryptionProtocol.Wpa2Enterprise ||
        ssid.encryptionProtocol === SsidEncryptionProtocol.Wpa3Enterprise,
    }));
  } else {
    expectDefinedOrThrow(ssidData, new ResourceNotFoundError("Couldn't load SSIDS"));
    ssids = ssidData.map((ssid) => ({
      ssid: ssid.ssid,
      password: ssid.password?.value ?? '',
      hasPassword:
        ssid.authentication !== AUTH_IEEE8021X && ssid.password?.type !== PSKSchemeType.None,
      wpa2: ssid.authentication === AUTH_IEEE8021X,
    }));
  }

  return <WIFIDetailsInner controllerName={controllerName} ssids={ssids} />;
}

function WIFIDetailsErrorState() {
  return (
    <WIFIDetailsContainer>
      <WIFIDetailsError>
        <Alert
          type="inline"
          relation="standalone"
          variant="neutral"
          icon="warning"
          heading="SSIDs failed to load"
        />
      </WIFIDetailsError>
    </WIFIDetailsContainer>
  );
}

function WIFIDetailsLoadingState() {
  return (
    <WIFIDetailsContainer>
      <WIFIDetailsSkeleton>
        <Skeleton width="100%" height="17px" radius={6} />
      </WIFIDetailsSkeleton>
      <WIFIDetailsSkeleton>
        <Skeleton width="100%" height="24px" radius={6} />
      </WIFIDetailsSkeleton>
      <WIFIDetailsSkeleton>
        <Skeleton width="100%" height="17px" radius={6} />
        <Skeleton width="100%" height="17px" radius={6} />
        <Skeleton width="100%" height="17px" radius={6} />
      </WIFIDetailsSkeleton>
    </WIFIDetailsContainer>
  );
}

export default function WIFIDetails() {
  return (
    <ErrorBoundary fallback={<WIFIDetailsErrorState />}>
      <Suspense fallback={<WIFIDetailsLoadingState />}>
        <WIFIDetailsLoader />
      </Suspense>
    </ErrorBoundary>
  );
}

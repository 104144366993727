import React from 'react';

import { Quote } from '@meterup/connect-api';

export type ContractLengthCellProps = {
  quote?: Quote;
};

function ContractLengthCell({ quote }: ContractLengthCellProps) {
  if (!quote) {
    return null;
  }

  const { contractLengthMonths } = quote;

  if (contractLengthMonths === 1) {
    return <>Month-to-month</>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{`${contractLengthMonths}-Month`}</>;
}

export default ContractLengthCell;

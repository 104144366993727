import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import EditNosUpgrade from '../../../NosVersions/EditNosUpgrade';
import MigrateFromConfig1ToConfig2 from '../../../Settings/Network/MigrateFromConfig1ToConfig2';
import { WorkbenchPaneContent } from '../WorkbenchPane';

export const Meta = () => ({
  path: '/org/:companyName/settings/network/:networkSlug/general',
  title: 'Network / General',
});

export default function NetworkFirmware() {
  const selectedNetwork = useNetwork();
  return (
    <WorkbenchPaneContent gutter="all">
      <EditNosUpgrade network={selectedNetwork} />
      <MigrateFromConfig1ToConfig2 network={selectedNetwork} />
    </WorkbenchPaneContent>
  );
}

import React from 'react';

import { SafeLink as Link } from '@meterup/connect-ui/src/components/SafeLink';
import { EmDash } from '@meterup/connect-ui/src/constants';
import { logError } from '@meterup/connect-ui/src/Log.utils';

import useQuotesRequest from '../../hooks/useQuotesRequest';
import { RoutingFunction } from '../../utils/routes';
import { ISPCell } from './ISPCell';

type TotalCostCellProps = {
  quoteRequestSID: string;
  routingFn: RoutingFunction;
};

export function TotalCostCell({ quoteRequestSID, routingFn: route }: TotalCostCellProps) {
  const { data, isLoading, isError, error } = useQuotesRequest(quoteRequestSID);
  if (isLoading || isError) {
    if (isError) {
      logError(error);
    }
    return <EmDash />;
  }

  return (
    <Link to={route({ name: 'results', params: { quoteRequestSID } })} newWindow>
      <ISPCell>{data.quotes.length} quotes</ISPCell>
    </Link>
  );
}

import { DateTime, Duration } from 'luxon';

export const secToHuman = (sec: number) =>
  Duration.fromObject({ seconds: sec }).rescale().toHuman({ unitDisplay: 'short' });

export const fromISOtoLocaleString = ({
  iso,
  format = DateTime.DATETIME_MED_WITH_SECONDS,
}: {
  iso: string;
  format?: Intl.DateTimeFormatOptions;
}) => DateTime.fromISO(iso).toLocaleString(format);

export function timestampToRelativeTime(timestamp: number) {
  return DateTime.fromMillis(timestamp).toRelative();
}

export function isoStringToRelativeTime(isoString: string) {
  return DateTime.fromISO(isoString).toRelative();
}

export function stripDateTimeForInput(dateTime: DateTime): string {
  return dateTime
    .set({
      second: 0,
      millisecond: 0,
    })
    .toISO({
      includeOffset: false,
      suppressSeconds: true,
      suppressMilliseconds: true,
    });
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import AccessPointDrawer from '../../../../../components/Hardware/AccessPoints/AccessPointDrawer';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/devices/:uuid',
  title: 'Clients - Access point - Access points - Hardware',
});

export default function AccessPointClientsSummaryPage() {
  return <AccessPointDrawer />;
}

import type { ShellContentProps, ShellFooterProps, ShellHeaderProps } from '@meterup/atto';
import { Shell, ShellContent, ShellFooter, ShellHeader, styled } from '@meterup/atto';
import React from 'react';

const WorkbenchPaneHeaderContainer = styled(ShellHeader);

type WorkbenchPaneHeaderProps = ShellHeaderProps;

export function WorkbenchPaneHeader({ ...remaining }: WorkbenchPaneHeaderProps) {
  return <WorkbenchPaneHeaderContainer {...remaining} />;
}

const WorkbenchPaneContentContainer = styled(ShellContent);

type WorkbenchPaneContentProps = ShellContentProps;

export function WorkbenchPaneContent({ children, ...remaining }: WorkbenchPaneContentProps) {
  return <WorkbenchPaneContentContainer {...remaining}>{children}</WorkbenchPaneContentContainer>;
}

const WorkbenchPaneFooterContainer = styled(ShellFooter);

type WorkbenchPaneFooterProps = ShellFooterProps;

export function WorkbenchPaneFooter({ ...remaining }: WorkbenchPaneFooterProps) {
  return <WorkbenchPaneFooterContainer {...remaining} />;
}

const WorkbenchPaneContainer = styled(Shell);

type WorkbenchPaneProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
};

export function WorkbenchPane({ children, ...remaining }: WorkbenchPaneProps) {
  return (
    <WorkbenchPaneContainer size="x-small" {...remaining}>
      {children}
    </WorkbenchPaneContainer>
  );
}

import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  space,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { type VLAN, deleteVLANMutation, vlansQuery } from './utils';

export default function VLANActions({
  vlan,
  view,
}: {
  vlan: VLAN;
  view: 'detail' | 'drawer' | 'edit' | 'list';
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const dialogProps = useDialogState();

  const deleteMutation = useGraphQLMutation(deleteVLANMutation);
  const closeDrawer = useCloseDrawerCallback();
  const { hasPermission } = usePermissions();
  const canEditVLAN = hasPermission(PermissionType.PermVlanWrite);
  const ldflags = useFeatureFlags();
  const isOperator = useIsOperator();
  const shouldShowEdit = canEditVLAN && (isOperator || (ldflags['edit-vlan'] && !vlan.isInternal));

  const { close: closeDialog } = dialogProps.state;

  const handleDelete = useCallback(() => {
    if (!vlan?.UUID) return;
    deleteMutation.mutate(
      { uuid: vlan.UUID },
      {
        onSuccess: () => {
          notify(`Successfully deleted VLAN: ${vlan.name}`, { variant: 'positive' });
          closeDialog();
          queryClient.invalidateQueries(makeQueryKey(vlansQuery, { networkUUID: network.UUID }));
          closeDrawer();
        },
        onError: (err) => {
          notify(`Failed to delete VLAN:${getGraphQLErrorMessageOrEmpty(err)}`, {
            variant: 'negative',
          });
        },
      },
    );
  }, [vlan.UUID, vlan.name, network.UUID, queryClient, closeDrawer, deleteMutation, closeDialog]);

  const openDetails = () => {
    navigate(
      makeLink(paths.pages.VLANDetailsPage, {
        companyName,
        networkSlug: network.slug,
        uuid: vlan.UUID,
        tab: 'details',
      }),
    );
  };

  return (
    <>
      {view !== 'edit' && shouldShowEdit && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.VLANEditPage, {
            companyName,
            networkSlug: network.slug,
            uuid: vlan.UUID,
          })}
          arrangement="leading-icon"
          icon="pencil"
          variant="secondary"
          internal={isOperator && (!ldflags['edit-vlan'] || !!vlan.isInternal)}
        >
          Edit
        </Button>
      )}
      {(view !== 'detail' || !vlan.isDefault) && shouldShowEdit && (
        <DropdownMenu>
          <DropdownMenuButton
            variant="secondary"
            icon="overflow-horizontal"
            arrangement="hidden-label"
          >
            Actions
          </DropdownMenuButton>
          <DropdownMenuPopover align="end">
            {view !== 'detail' && (
              <DropdownMenuGroup>
                <DropdownMenuItem onSelect={openDetails} icon="vlan">
                  View details
                </DropdownMenuItem>
              </DropdownMenuGroup>
            )}
            {!vlan.isDefault && (
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onSelect={dialogProps.openFromMenu}
                  icon="trash-can"
                  internal={isOperator && !ldflags['edit-vlan']}
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuGroup>
            )}
          </DropdownMenuPopover>
        </DropdownMenu>
      )}

      <Dialog state={dialogProps.state} preset="narrow">
        <DialogHeader heading="Delete VLAN" />
        <DialogContent gutter="all">
          <VStack spacing={space(12)}>
            <Alert
              icon="attention"
              variant="negative"
              heading="Deleting a VLAN can impact the network"
              copy="Any ports that use this VLAN will no longer use this VLAN. If the VLAN was the native VLAN on the port, the port will not have a native VLAN."
            />
            <Body>
              Are you sure you want to delete <strong>{vlan.name}</strong>? This action cannot be
              undone.
            </Body>
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={dialogProps.state.close}>
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleDelete}>
                Delete
              </Button>
            </>
          }
        />
      </Dialog>
    </>
  );
}

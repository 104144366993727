import 'reactflow/dist/style.css';

import type { Node, NodeProps, ReactFlowInstance } from 'reactflow';
import dagre from '@dagrejs/dagre';
import {
  backgrounds,
  Badge,
  darkThemeSelector,
  Icon,
  keyframes,
  Pane,
  PaneContent,
  PaneHeader,
  Small,
  space,
  styled,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { colors, useSessionStorage } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ReactFlow, {
  Controls,
  Handle,
  MiniMap,
  Position,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import type { VirtualDevicesForTopologyQuery } from '../../../../../gql/graphql';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { ValidAPDrawerTabs } from '../../../../../components/Wireless/utils';
import { paths } from '../../../../../constants';
import { graphql } from '../../../../../gql';
import { DeviceType, PermissionType, VirtualDeviceType } from '../../../../../gql/graphql';
import { useNetworkClients } from '../../../../../hooks/networkClients/useNetworkClients';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { logError } from '../../../../../utils/logError';
import { makeDrawerLink, makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { useDesignCrumbs, useNavigateBack, useNavigateHome } from '../../../../../utils/routing';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/design/topology',
  layout: 'VirtualNetworkLayout',
  title: 'Topology - Design',
});

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
dagreGraph.setGraph({ rankdir: 'LR', nodesep: 12, edgesep: 12 });

export const TopologyNodeContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  gap: '$6',
  padding: '$6 $8',
  borderRadius: '$8',
  backgroundColor: colors.bgNeutralLight,
  color: colors.headingNeutralLight,
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgNeutralDark,
    color: colors.headingNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },

  variants: {
    active: {
      true: {
        backgroundColor: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },
      },
    },
  },
});

export const TopologyMiniMap = styled(MiniMap, {
  backgroundColor: colors.bgApplicationLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
  },

  '& > svg': {
    fill: colors.bgApplicationLight,

    [darkThemeSelector]: {
      fill: colors.bgApplicationDark,
    },
  },

  '& > svg > path': {
    fill: colors.bgNeutralLight,

    [darkThemeSelector]: {
      fill: colors.bgNeutralDark,
    },
  },

  '& > svg > rect': {
    fill: colors.gray200,

    [darkThemeSelector]: {
      fill: colors.gray600,
    },
  },
});

export const nodeLine = {
  stroke: colors.gray400.toString(),
};

export const nodeHandle = {
  background: colors.transparent.toString(),
  borderColor: colors.transparent.toString(),
};

type NodeData = {
  label: string;
  hardware: string;
  UUID: string;
};

function useIsActiveNode(node: NodeProps<NodeData>) {
  const securityApplianceParams = Nav.useRegionParams(
    'drawer',
    paths.drawers.SecurityApplianceSummaryPage,
  );
  const switchParams = Nav.useRegionParams('drawer', paths.drawers.SwitchSummaryPage);
  const accessPointParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointDrawerPage);
  if (securityApplianceParams) {
    return securityApplianceParams?.uuid === node.id;
  }
  if (switchParams) {
    return switchParams?.uuid === node.id;
  }
  if (accessPointParams) {
    if (accessPointParams?.tab === 'clients' && accessPointParams?.uuid === node.data?.UUID) {
      return node.data.hardware === 'client';
    }
    return (
      accessPointParams?.uuid === node.id &&
      node.data.hardware === 'access-point' &&
      accessPointParams.tab !== 'clients'
    );
  }
  return false;
}

function GenericNode({ data, sourcePosition = Position.Right }: NodeProps) {
  return (
    <>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
      <TopologyNodeContainer>
        <Small>{data.label}</Small>
      </TopologyNodeContainer>
    </>
  );
}

enum ISPStatus {
  Active = 'Active',
  Ready = 'Ready',
}

function ISPNode({ data, sourcePosition = Position.Bottom }: NodeProps) {
  return (
    <>
      <TopologyNodeContainer>
        <Icon icon="globe" size={space(16)} />
        <Small>{data.label}</Small>
        {data.status && (
          <Badge
            variant={data.status === ISPStatus.Active ? 'positive' : 'neutral'}
            ends="pill"
            size="small"
          >
            {data.status}
          </Badge>
        )}
      </TopologyNodeContainer>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
    </>
  );
}

function HardwareNode({
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
  ...node
}: NodeProps) {
  const isActive = useIsActiveNode(node);
  return (
    <>
      <Handle
        type="target"
        position={targetPosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
      <TopologyNodeContainer active={isActive}>
        <Icon icon={node.data.hardware} size={space(16)} />
        <Small>{node.data.label}</Small>
      </TopologyNodeContainer>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
    </>
  );
}

function ClientNode({ targetPosition = Position.Top, ...node }: NodeProps) {
  const isActive = useIsActiveNode(node);
  return (
    <>
      <Handle
        type="target"
        position={targetPosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
      <TopologyNodeContainer active={isActive}>
        <Icon icon="client" size={space(16)} />
        <Small>{node.data.label}</Small>
      </TopologyNodeContainer>
    </>
  );
}

const nodeTypes = {
  genericNode: GenericNode,
  ispNode: ISPNode,
  hardwareNode: HardwareNode,
  clientNode: ClientNode,
};

const dashedAnimation = keyframes({
  '0%': { left: '-$12' },
  '100%': { left: '$12' },
});

const TopologyLegendItemType = styled('div', {
  width: '$12',
  height: '$2',
  overflow: 'hidden',
  borderRadius: '99em',

  variants: {
    type: {
      active: {
        position: 'relative',

        '&::after': {
          content: '',
          position: 'absolute',
          display: 'block',
          width: '$12',
          height: '$2',
          background: colors.gray400,
          borderRadius: '99em',
          animation: `${dashedAnimation} 1s linear infinite`,
        },
      },
      inactive: {
        background: colors.gray400,
      },
    },
  },
});

const TopologyLegendItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

// These following styled elements are "export" for the sakes of using them
// inside the previous version of Topology. The export should be dropped when
// the original Toplogy has been removed from the codebase.

export function TopologyLegendItem({
  type,
  label,
}: {
  type: 'active' | 'inactive';
  label: string;
}) {
  return (
    <TopologyLegendItemContainer>
      <TopologyLegendItemType type={type} />
      <Small weight="bold">{label}</Small>
    </TopologyLegendItemContainer>
  );
}

export const TopologyLegend = styled('div', {
  position: 'absolute',
  zIndex: 5,
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: '$12',
  padding: '$6 $8',
  background: colors.bgApplicationLight,
  borderRadiusTop: '$8',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },
});

export const TopologyControls = styled(Controls, {
  '& > button': {
    backgroundColor: colors.white,
    borderColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.gray900,
      borderColor: colors.strokeNeutralDark,
    },

    '& > svg': {
      fill: colors.gray700,
      [darkThemeSelector]: {
        fill: colors.gray50,
      },
    },

    '&:first-child': {
      borderRadius: '4px 4px 0 0',
    },

    '&:last-child': {
      borderRadius: '0 0 4px 4px',
      borderBottom: 0,
    },
  },
});

const virtualDevicesForTopology = graphql(`
  query VirtualDevicesForTopology($networkUUID: UUID!) {
    virtualDevicesForNetwork(networkUUID: $networkUUID) {
      __typename
      UUID
      label
      deviceType

      hardwareDevice {
        __typename
        serialNumber
        deviceType
        isActive
        isConnectedToBackend
      }

      ... on ControllerVirtualDevice {
        phyInterfaces {
          virtualDeviceUUID
          isUplink
          connectedDevices {
            hardwareDevice {
              serialNumber
              deviceType
              isActive
              virtualDeviceUUID
              isConnectedToBackend
            }
          }
          internetServicePlan {
            provider {
              UUID
              name
            }
          }
          isUplinkActive
        }
      }

      ... on SwitchVirtualDevice {
        phyInterfaces {
          isUplink
          connectedDevices {
            hardwareDevice {
              serialNumber
              deviceType
              isActive
              virtualDeviceUUID
              isConnectedToBackend
            }
          }
        }
      }
    }
  }
`);

type VirtualDevice = VirtualDevicesForTopologyQuery['virtualDevicesForNetwork'][number];

type ControllerVirtualDevice = Extract<VirtualDevice, { __typename: 'ControllerVirtualDevice' }>;

type ControllerPhyInterface = ControllerVirtualDevice['phyInterfaces'][number];

type InternetServicePlan = NonNullable<ControllerPhyInterface['internetServicePlan']> & {
  provider: NonNullable<NonNullable<ControllerPhyInterface['internetServicePlan']>['provider']>;
};

const createEdgeID = (sourceId: string, targetId: string) => `edge-${sourceId}-${targetId}`;

// eslint-disable-next-line consistent-return
function getNetworkHierarchyOrder(deviceType: DeviceType | VirtualDeviceType): number {
  switch (deviceType) {
    case VirtualDeviceType.Controller:
    case DeviceType.Controller:
      return 0;
    case VirtualDeviceType.Switch:
    case DeviceType.Switch:
      return 1;
    case VirtualDeviceType.AccessPoint:
    case DeviceType.AccessPoint:
    case VirtualDeviceType.Observer:
      return 2;
    case VirtualDeviceType.PowerDistributionUnit:
    case DeviceType.PowerDistributionUnit:
      return -1;
  }
}

function getNodeLength(node: { type: string; data: { label: string } }): number {
  const factor = node.type === 'ispNode' ? 15 : 10;
  return node.data.label.length * factor;
}

const FLOW_KEY_PREFIX = 'topology-flow';
const NODE_HEIGHT = 36;

export const TopologyArea = styled('div', {
  width: '100%',
  height: '100%',
  background: backgrounds.dottedLight,
  backgroundColor: colors.bgApplicationLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    background: backgrounds.dottedDark,
    backgroundColor: colors.bgApplicationDark,
  },
});

export default function Topology2Page() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const designCrumb = useDesignCrumbs();
  const navigate = useNavigate();

  const pageOptions = { hideAttribution: true };

  const virtualDevices = useGraphQL(virtualDevicesForTopology, { networkUUID: network.UUID }).data
    ?.virtualDevicesForNetwork;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const controllers = useMemo(
    () =>
      virtualDevices
        ?.filter(
          (
            vd,
          ): vd is typeof vd & {
            __typename: 'ControllerVirtualDevice';
            hardwareDevice: NonNullable<(typeof vd)['hardwareDevice']>;
          } => vd.deviceType === VirtualDeviceType.Controller && !!vd.hardwareDevice?.isActive,
        )
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [virtualDevices],
  );

  const switches = useMemo(
    () =>
      virtualDevices
        ?.filter(
          (vd): vd is typeof vd & { __typename: 'SwitchVirtualDevice' } =>
            vd.deviceType === VirtualDeviceType.Switch && !!vd?.hardwareDevice?.isActive,
        )
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [virtualDevices],
  );

  const aps = useMemo(
    () =>
      virtualDevices
        ?.filter(
          (vd): vd is typeof vd & { __typename: 'AccessPointVirtualDevice' } =>
            vd.deviceType === VirtualDeviceType.AccessPoint && !!vd.hardwareDevice?.isActive,
        )
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [virtualDevices],
  );

  const networkClients = useNetworkClients(network);

  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);
  const isOperator = useIsOperator({ respectDemoMode: true });

  const [flowState, setFlowState] = useSessionStorage<string | null>(
    `${FLOW_KEY_PREFIX}-${network.UUID}`,
    null,
  );

  const location = useLocation();

  // Save function to save the topology to local storage
  const onSave = useCallback(() => {
    if (reactFlowInstance.current) {
      try {
        const flow = reactFlowInstance.current.toObject();
        setFlowState(JSON.stringify(flow));
      } catch (err) {
        logError(err);
      }
    }
  }, [setFlowState, reactFlowInstance]);

  // Save flow viewport state on the following events:
  // when location changes (Topology clicks),
  // when unloading (navigating away from dashboard),
  // and when component unmounts (navigating away from Topology)
  useEffect(() => {
    onSave();
    return onSave;
  }, [location, onSave]);
  useEffect(() => {
    window.addEventListener('unload', onSave);
    return () => {
      window.removeEventListener('unload', onSave);
    };
  }, [onSave]);

  // Restore function to restore the topology from session storage
  const onRestore = useCallback(() => {
    if (flowState && reactFlowInstance.current) {
      try {
        const flow = JSON.parse(flowState);
        if (flow?.viewport) {
          const { x = 0, y = 0, zoom = 1 } = flow.viewport;
          reactFlowInstance.current.setViewport({ x, y, zoom });
        } else {
          reactFlowInstance.current.fitView({ padding: 0.1, minZoom: 1 });
        }
      } catch (err) {
        logError(err);
      }
    }
  }, [flowState]);

  const onLoad = (_reactFlowInstance: ReactFlowInstance) => {
    reactFlowInstance.current = _reactFlowInstance;
    if (flowState) {
      onRestore();
    }
  };

  useEffect(() => {
    const genericISPNodes = [
      {
        id: 'generic-isp-node',
        type: 'ispNode',
        sourcePosition: Position.Right,
        data: {
          label: 'ISP',
        },
      },
    ];

    const controllerISPNodes = controllers.flatMap((controller) =>
      controller.phyInterfaces
        .filter(
          (
            pi,
          ): pi is typeof pi & {
            internetServicePlan: InternetServicePlan;
          } => !!pi.internetServicePlan?.provider,
        )
        .map((pi) => ({
          id: pi.internetServicePlan.provider.UUID,
          type: 'ispNode',
          sourcePosition: Position.Right,
          data: {
            label: pi.internetServicePlan.provider.name,
            status: pi.isUplinkActive ? ISPStatus.Active : ISPStatus.Ready,
            phyInterface: pi,
          },
        })),
    );

    const controllerNodes =
      controllers.map((controller) => ({
        id: controller.UUID,
        type: 'hardwareNode',
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: {
          label: controller.label ?? controller.hardwareDevice.serialNumber,
          hardware: 'security-appliance',
        },
      })) ?? [];

    const controllersWithISPNodes = new Map<string, typeof controllerISPNodes>();
    for (const node of controllerISPNodes) {
      const controllerUUID = node.data.phyInterface.virtualDeviceUUID;
      let arr = controllersWithISPNodes.get(controllerUUID);
      if (!arr) {
        arr = [];
        controllersWithISPNodes.set(controllerUUID, arr);
      }
      arr.push(node);
    }

    let shouldShowGenericISPNode = false;

    const ispToControllerEdges = controllers.flatMap((controller) => {
      const connectedISPNodes = controllersWithISPNodes.get(controller.UUID) ?? genericISPNodes;
      if (connectedISPNodes === genericISPNodes) {
        shouldShowGenericISPNode = true;
      }

      return connectedISPNodes.map((ispNode) => ({
        id: createEdgeID(ispNode.id, controller.UUID),
        source: ispNode.id,
        target: controller.UUID,
        type: 'default',
        animated:
          controller.hardwareDevice.isConnectedToBackend &&
          'status' in ispNode.data &&
          ispNode.data.status === 'Active',
        style: { ...nodeLine },
      }));
    });

    const ispNodes = [...controllerISPNodes, ...(shouldShowGenericISPNode ? genericISPNodes : [])];

    const switchNodes = switches.map((device) => ({
      id: device.UUID,
      type: 'hardwareNode',
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      data: { label: device.label, hardware: 'switch' },
    }));

    const apNodes = aps.map((ap) => ({
      id: ap.UUID,
      type: 'hardwareNode',
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      data: {
        label: ap.label ?? '0',
        hardware: 'access-point',
        UUID: ap?.UUID,
      },
    }));

    const deviceEdges = (virtualDevices ?? [])
      .filter(
        (vd): vd is typeof vd & { __typename: 'SwitchVirtualDevice' | 'ControllerVirtualDevice' } =>
          vd.__typename === 'SwitchVirtualDevice' || vd.__typename === 'ControllerVirtualDevice',
      )
      .flatMap((baseDevice) =>
        baseDevice.phyInterfaces.flatMap((pi) =>
          (pi.connectedDevices ?? [])
            .filter(
              (
                connectedDevice,
              ): connectedDevice is typeof connectedDevice & {
                hardwareDevice: NonNullable<(typeof connectedDevice)['hardwareDevice']> & {
                  virtualDeviceUUID: string;
                };
              } => !!connectedDevice?.hardwareDevice?.virtualDeviceUUID,
            )
            .map((connectedDevice) => {
              const [src, dst] =
                pi.isUplink ||
                getNetworkHierarchyOrder(connectedDevice.hardwareDevice.deviceType) <
                  getNetworkHierarchyOrder(baseDevice.deviceType)
                  ? [connectedDevice.hardwareDevice.virtualDeviceUUID, baseDevice.UUID]
                  : [baseDevice.UUID, connectedDevice.hardwareDevice.virtualDeviceUUID];

              return {
                id: createEdgeID(src, dst),
                source: src,
                target: dst,
                type: 'default',
                animated:
                  pi.isUplink ||
                  getNetworkHierarchyOrder(connectedDevice.hardwareDevice.deviceType) <
                    getNetworkHierarchyOrder(baseDevice.deviceType)
                    ? !!baseDevice.hardwareDevice?.isConnectedToBackend
                    : connectedDevice.hardwareDevice.isConnectedToBackend,
                style: { ...nodeLine },
              };
            }),
        ),
      );

    const clientNodes = aps
      ?.filter((ap) => ap?.hardwareDevice?.serialNumber)
      ?.map((ap) => {
        const clientsForAp = networkClients.filter(
          (client) => ap?.hardwareDevice?.serialNumber === client.apSerialNumber,
        );
        const relatedAp = apNodes.find((relatedApNode) => relatedApNode.id === ap.UUID);
        const clientsCountForAp = clientsForAp?.length ?? 0;
        return {
          id: `${ap.UUID}-clients`,
          type: 'clientNode',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          data: {
            label: `${clientsCountForAp} clients`,
            hardware: 'client',
            UUID: relatedAp?.data.UUID,
          },
        };
      });

    const apToClientEdges = aps.map((ap) => {
      const relatedAp = apNodes.find((relatedApNode) => relatedApNode.id === ap.UUID);

      return {
        id: createEdgeID(relatedAp?.id ?? '0', `${relatedAp?.id}-clients`),
        source: relatedAp?.id ?? '0',
        target: `${relatedAp?.id}-clients`,
        type: 'default',
        style: { ...nodeLine },
      };
    });

    const allNodes = [
      ...ispNodes,
      ...controllerNodes,
      ...(switchNodes ?? []),
      ...(apNodes ?? []),
      ...(clientNodes ?? []),
    ];
    const allEdges = [...ispToControllerEdges, ...deviceEdges, ...apToClientEdges];

    for (const node of allNodes) {
      dagreGraph.setNode(node.id, { width: getNodeLength(node), height: NODE_HEIGHT });
    }

    for (const edge of allEdges) {
      dagreGraph.setEdge(edge.source, edge.target);
    }

    dagre.layout(dagreGraph);

    const graphedNodes = allNodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);

      return {
        ...node,
        position: {
          x: nodeWithPosition.x - getNodeLength(node) / 2,
          y: nodeWithPosition.y - NODE_HEIGHT / 2,
        },
      };
    });

    setNodes(graphedNodes);
    setEdges(allEdges);
  }, [
    virtualDevices,
    setEdges,
    setNodes,
    controllers,
    switches,
    aps,
    networkClients,
    reactFlowInstance,
    onRestore,
  ]);

  const handleNodeClick = (_: React.MouseEvent, node: Node<NodeData>) => {
    if (node.data.hardware === 'access-point') {
      navigate(
        makeDrawerLink(window.location, paths.drawers.AccessPointDrawerPage, {
          networkSlug: network.slug,
          companyName,
          uuid: node.id,
          tab: ValidAPDrawerTabs.AccessPoint,
        }),
      );
    } else if (isOperator && node.data.hardware === 'security-appliance') {
      navigate(
        makeDrawerLink(window.location, paths.drawers.SecurityApplianceSummaryPage, {
          companyName,
          networkSlug: network.slug,
          uuid: node.id,
        }),
      );
    } else if (node.data.hardware === 'switch') {
      navigate(
        makeDrawerLink(window.location, paths.drawers.SwitchSummaryPage, {
          companyName,
          networkSlug: network.slug,
          uuid: node.id,
        }),
      );
    } else if (node.data.hardware === 'client') {
      navigate(
        makeDrawerLink(window.location, paths.drawers.AccessPointDrawerPage, {
          companyName,
          networkSlug: network.slug,
          uuid: node.data.UUID,
          tab: 'clients',
        }),
      );
    }
  };

  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermNetworkDevicesRead))
      }
      should404OnAccessDenied
    >
      <Pane layoutMode="detailed">
        <PaneHeader
          back={back}
          home={home}
          crumbs={[
            ...designCrumb,
            {
              type: 'page',
              page: {
                as: ReactRouterLink,
                to: makeLink(paths.pages.Topology2Page, {
                  companyName,
                  networkSlug: network.slug,
                }),
                selected: true,
                label: 'Topology',
              },
            },
          ]}
          icon="topology"
          heading="Topology"
        />
        <PaneContent gutter="all">
          <TopologyArea>
            <ReactFlow
              nodeTypes={nodeTypes}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              fitView
              minZoom={0.2}
              onNodeClick={handleNodeClick}
              nodesDraggable={false}
              nodesConnectable={false}
              elementsSelectable={false}
              proOptions={pageOptions}
              onInit={onLoad}
            >
              <TopologyMiniMap />
              <TopologyLegend>
                <TopologyLegendItem type="inactive" label="Not in use" />
                <TopologyLegendItem type="active" label="In use" />
              </TopologyLegend>
              <TopologyControls showInteractive={false} />
            </ReactFlow>
          </TopologyArea>
        </PaneContent>
      </Pane>
    </IsPermitted>
  );
}

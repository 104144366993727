import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  space,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, notify } from '@meterup/common';
import { Form, Formik } from 'formik';
import React from 'react';

import type { ValidPortForwardingRule } from './form_data';
import { DiscardChangesButton, DrawerFormSaveButton } from '../../../../../components/form_buttons';
// import { FormikConditional } from '../../../../../components/FormikConditional';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useToggle } from '../../../../../hooks/useToggle';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { validPortForwardingRule } from './form_data';
import { FormFields } from './form_fields';
import {
  useRemovePortForwardingRuleMutation,
  useUpsertPortForwardingRuleMutation,
} from './mutations';
import { NATPortForwardingConfirmChanges } from './NATPortForwardingConfirmChanges';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/port-forwarding/:ruleId/edit',
});

export default function NATPortForwardingRuleEditPage() {
  const { ruleId } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.NATPortForwardingRuleEditPage),
  );

  const controllerName = useCurrentController();
  const configModel = useControllerConfig(controllerName);
  const closeDrawer = useCloseDrawerCallback();
  const isConfirmingChanges = useToggle();

  const dialogProps = useDialogState();

  const rule = configModel.nat.portForwards.find((d) => d.stableId === ruleId);

  expectDefinedOrThrow(rule, new Error(`Port forwarding rule not found`));

  const upsertMutation = useUpsertPortForwardingRuleMutation(controllerName, configModel);
  const removeMutation = useRemovePortForwardingRuleMutation(controllerName, configModel, rule);

  return (
    <>
      <Formik<ValidPortForwardingRule>
        validate={withZodSchema(validPortForwardingRule)}
        initialValues={rule}
        onSubmit={(values) => {
          if (isConfirmingChanges.value) {
            upsertMutation.mutate(values, {
              onSuccess: () => {
                notify('Saved', { variant: 'positive' });
                isConfirmingChanges.toggleOff();
              },
              onError: () => {
                notify('Failed to save changes', { variant: 'negative' });
              },
            });
          } else {
            isConfirmingChanges.toggleOn();
          }
        }}
        enableReinitialize
      >
        <StyledForm>
          {isConfirmingChanges.value ? (
            <NATPortForwardingConfirmChanges onCancel={isConfirmingChanges.toggleOff} />
          ) : (
            <Drawer>
              <DrawerHeader
                icon="port-forward"
                heading="Edit port forwarding rule"
                actions={
                  <DropdownMenu>
                    <DropdownMenuButton
                      variant="secondary"
                      icon="overflow-horizontal"
                      arrangement="hidden-label"
                    >
                      Actions
                    </DropdownMenuButton>

                    <DropdownMenuPopover align="end">
                      <DropdownMenuItem onSelect={dialogProps.openFromMenu} icon="trash-can">
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuPopover>
                  </DropdownMenu>
                }
                onClose={closeDrawer}
              />
              <DrawerContent>
                <FormFields />
              </DrawerContent>
              {/* <FormikConditional<ValidPortForwardingRule> condition={(values, ctx) => ctx.dirty}> */}
              <DrawerFooter
                actions={
                  <>
                    <DiscardChangesButton />
                    <DrawerFormSaveButton />
                  </>
                }
              />
              {/* </FormikConditional> */}
            </Drawer>
          )}
        </StyledForm>
      </Formik>
      <Dialog state={dialogProps.state} preset="narrow">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            removeMutation.mutate(undefined, {
              onSuccess: () => {
                notify('Removed', { variant: 'positive' });
                dialogProps.state.close();
                closeDrawer();
              },
              onError: () => {
                notify('Failed to remove rule', { variant: 'negative' });
              },
            });
          }}
        >
          <DialogHeader heading="Remove rule" />
          <DialogContent gutter="all">
            <VStack spacing={space(12)}>
              <Alert
                icon="attention"
                variant="negative"
                heading="Removing a rule requires a network restart"
                copy="Your network will restart in order for the rule to be removed. All clients will lose connectivity for approximately 30 seconds."
              />
              <Body>
                Are you sure you want to remove{' '}
                {rule.description ? (
                  <>
                    the rule <Body weight="bold">{rule.description}</Body>
                  </>
                ) : (
                  'this port forwarding rule'
                )}
                ? This action cannot be undone.
              </Body>
            </VStack>
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button variant="secondary" onClick={dialogProps.state.close}>
                  Cancel
                </Button>
                <Button variant="destructive" type="submit">
                  Remove
                </Button>
              </>
            }
          />
        </form>
      </Dialog>
    </>
  );
}

import { useGraphQL } from '@meterup/graphql';
import { isNumber, keyBy } from 'lodash-es';
import { useMemo } from 'react';
import { z } from 'zod';

import type { Hs20VenueTypes } from '../../../gql/graphql';
import type { SSIDsQueryResult } from '../SSIDs/SSIDsUtils';
import { UpdateSsidInputSchema } from '../../../gql/zod-types';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { isFQDN } from '../../../utils/fqdn';
import { Hs20AccessNetworkTypes, SSIDsQuery } from '../SSIDs/SSIDsUtils';

export const AddEditHotspot20InputSchema = z
  .object({
    hs20Ssid: z.string().nonempty({ message: 'Please select an SSID.' }),
    hs20Enabled: z.boolean(),
    hs20OperatorName: z.string().optional(),
    hs20VenueName: z.string().optional(),
    hs20VenueTypeTuple: z.string().optional(),
    hs20AccessNetworkType: z.string().nullish(),
    hs20DomainNames: z
      .array(z.string())
      .optional()
      .refine(
        (domains) => {
          let isValid: boolean = true;
          // handle edge case where the user adds a bunch of newline characters
          // or types and then deletes characters in the field resulting in empty string(s).
          if (domains?.every((domain) => domain === '')) {
            return isValid;
          }
          domains?.forEach((domain) => {
            isValid = isFQDN(domain.trim());
          });
          return isValid;
        },
        {
          message: 'Domain list must be a newline-separated list of valid domains.',
        },
      ),
    hs20RoamingConsortiumOIs: z.array(z.string()).optional(),
    hs20MccMncPairs: z
      .array(z.string())
      .optional()
      .refine(
        (pairs) => {
          let isValid: boolean = true;
          if (pairs?.every((pair) => pair === '')) {
            return isValid;
          }
          pairs?.forEach((codePair) => {
            codePair.split(',').forEach((code) => {
              const numCode = parseInt(code, 10);
              if (!isNumber(numCode) || code.length < 2 || code.length > 3) {
                isValid = false;
              }
            });
          });
          return isValid;
        },
        {
          message:
            'MCC/MNCs must be a newline-separated list of comma-separated MCC/MNC code pairs.',
        },
      ),
  })
  .superRefine(({ hs20VenueName, hs20VenueTypeTuple }, ctx) => {
    if (hs20VenueName && !hs20VenueTypeTuple) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please provide a Venue type.',
        path: ['hs20VenueTypeTuple'],
      });
    }

    if (!hs20VenueName && hs20VenueTypeTuple) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please provide a Venue name.',
        path: ['hs20VenueName'],
      });
    }
  });

export type AddEditHotspot20Input = z.input<typeof AddEditHotspot20InputSchema>;

export const EapMethodSchema = z.object({
  id: z.string(),
  hs20EapMethodId: z.string().nonempty({ message: 'Please select an EAP method.' }),
  hs20AuthParamTuples: z
    .array(z.string())
    .nonempty({ message: 'Please select at least one Authentication method.' }),
});

export type EapMethod = z.infer<typeof EapMethodSchema>;

export const AddEditNaiRealmInputSchema = z.object({
  hs20NaiRealmName: z.string().nonempty({ message: 'Please provide an NAI realm name.' }),
  hs20EapMethods: z.array(EapMethodSchema),
});

export type AddEditNaiRealmInput = z.input<typeof AddEditNaiRealmInputSchema>;

export const UpdateSsidHotspot20InputSchema = UpdateSsidInputSchema.pick({
  hs20AccessNetworkType: true,
  hs20DomainNames: true,
  hs20Enabled: true,
  hs20MccMncPairs: true,
  hs20OperatorNames: true,
  hs20RoamingConsortiumOIs: true,
  hs20VenueGroup: true,
  hs20VenueNames: true,
  hs20VenueType: true,
});

export type UpdateSsidHotspot20Input = z.input<typeof UpdateSsidHotspot20InputSchema>;

export const UpdateNaiRealmInputSchema = UpdateSsidInputSchema.pick({
  hs20NaiRealms: true,
});

export type UpdateNaiRealmInput = z.input<typeof UpdateNaiRealmInputSchema>;

type UseSSIDsOpts = {
  filter?: (ssids: SSIDsQueryResult | undefined) => boolean;
};

export function useSsids(opts?: UseSSIDsOpts) {
  const network = useNetwork();
  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork;
  return useMemo(() => {
    if (!ssids) {
      return [];
    }
    if (opts?.filter) {
      return ssids.filter(opts.filter);
    }
    return ssids;
  }, [ssids, opts]);
}

export function useHs20ValidTypes() {
  const typesResponse = useGraphQL(Hs20AccessNetworkTypes).data?.hs20ValidTypes;
  const accessNetworkTypesMap = useMemo(
    () => keyBy(typesResponse?.accessNetworkTypes, 'type'),
    [typesResponse],
  );
  const venueGroupsToTypesMap = useMemo(() => {
    const retMap = new Map<number, { description: string; types: Hs20VenueTypes[] }>();
    // generate groups map
    typesResponse?.venueTypes.forEach((venue) => {
      // The venue type 0 will always be the Unspecified for that group, containing the group description, when "Unspecified" is ommited.
      if (venue.type === 0) {
        retMap.set(venue.group, {
          description: venue.description.replace('Unspecified ', ''),
          types: [],
        });
      }
    });
    typesResponse?.venueTypes.forEach((venue) => {
      const existingGroup = retMap.get(venue.group);
      if (existingGroup) {
        retMap.set(venue.group, {
          ...existingGroup,
          types: [...existingGroup.types, venue],
        });
      }
    });
    return retMap;
  }, [typesResponse]);
  return useMemo(
    () => ({
      venueTypes: typesResponse?.venueTypes ?? [],
      venueGroupsToTypesMap,
      accessNetworkTypes: typesResponse?.accessNetworkTypes ?? [],
      accessNetworkTypesMap,
      eapMethods: typesResponse?.eapMethods ?? [],
      authParams: typesResponse?.authParams ?? [],
    }),
    [typesResponse, venueGroupsToTypesMap, accessNetworkTypesMap],
  );
}

export function parseHs20RecordToFormVals(
  hs20Record: SSIDsQueryResult,
): Omit<AddEditHotspot20Input, 'hs20Ssid'> {
  const {
    hs20Enabled,
    hs20OperatorNames,
    hs20VenueNames,
    hs20VenueGroup,
    hs20VenueType,
    hs20AccessNetworkType: hs20AccessNetworkTypeNum,
    hs20DomainNames: hs20DomainNamesArr,
    hs20RoamingConsortiumOIs: hs20RoamingConsortiumOIsArr,
    hs20MccMncPairs: hs20MccMncPairsArr,
  } = hs20Record;
  const hs20OperatorName = hs20OperatorNames ? hs20OperatorNames[0].replace('eng:', '') : undefined;
  const hs20VenueName = hs20VenueNames ? hs20VenueNames[0].replace('eng:', '') : undefined;
  const hs20VenueTypeTuple =
    isNumber(hs20VenueGroup) && isNumber(hs20VenueType)
      ? `${hs20VenueGroup}-${hs20VenueType}`
      : undefined;
  return {
    hs20Enabled,
    hs20OperatorName,
    hs20VenueName,
    hs20VenueTypeTuple,
    hs20AccessNetworkType: String(hs20AccessNetworkTypeNum),
    hs20DomainNames: hs20DomainNamesArr ?? undefined,
    hs20RoamingConsortiumOIs: hs20RoamingConsortiumOIsArr ?? undefined,
    hs20MccMncPairs: hs20MccMncPairsArr ?? undefined,
  };
}
// handles sitation where users input newline characters or empty string into a text area
// either by mistake or through excessive newline characters
export function multiStrTextAreaInputFormatHelper(arr: string[]) {
  const filteredArr = arr.filter((entry) => entry !== '');
  return filteredArr.length ? filteredArr : null;
}

export function parseFormValsToHs20Record(input: AddEditHotspot20Input): UpdateSsidHotspot20Input {
  const {
    hs20Enabled,
    hs20OperatorName,
    hs20VenueName,
    hs20VenueTypeTuple,
    hs20AccessNetworkType,
    hs20DomainNames: rawDomainNamesStr,
    hs20RoamingConsortiumOIs: rawRoamingConsortiumOIs,
    hs20MccMncPairs: rawMccMncPairs,
  } = input;
  const venueTypeAndGroupArr: number[] | undefined = hs20VenueTypeTuple
    ?.split('-')
    .map((tupleVal) => parseInt(tupleVal, 10));
  return {
    hs20Enabled,
    ...(hs20OperatorName ? { hs20OperatorNames: [`eng:${hs20OperatorName}`] } : {}),
    ...(hs20VenueName ? { hs20VenueNames: [`eng:${hs20VenueName}`] } : {}),
    ...(venueTypeAndGroupArr?.length === 2
      ? { hs20VenueGroup: venueTypeAndGroupArr[0], hs20VenueType: venueTypeAndGroupArr[1] }
      : {}),
    ...(rawDomainNamesStr
      ? { hs20DomainNames: multiStrTextAreaInputFormatHelper(rawDomainNamesStr) }
      : {}),
    ...(rawRoamingConsortiumOIs
      ? {
          hs20RoamingConsortiumOIs: multiStrTextAreaInputFormatHelper(rawRoamingConsortiumOIs),
        }
      : {}),
    ...(rawMccMncPairs
      ? { hs20MccMncPairs: multiStrTextAreaInputFormatHelper(rawMccMncPairs) }
      : {}),
    ...(hs20AccessNetworkType
      ? { hs20AccessNetworkType: parseInt(hs20AccessNetworkType, 10) }
      : {}),
  };
}

export function parseNAIRealmFormValsToHs20Record(
  input: AddEditNaiRealmInput,
): UpdateNaiRealmInput {
  const formatAndName = `0,${input.hs20NaiRealmName}`;
  const eapMethods = input.hs20EapMethods
    .map((method) => `${method.hs20EapMethodId}${method.hs20AuthParamTuples.join('')}`)
    .join(',');
  return { hs20NaiRealms: [`${formatAndName},${eapMethods}`] };
}

export function parseHs20RecordToNAIRealmFormVals(
  hs20NaiRealms: UpdateNaiRealmInput['hs20NaiRealms'],
) {
  return hs20NaiRealms?.map((realm) => {
    const naiRealmComponentsArr = realm ? realm.split(',') : [];
    const eapMethods = naiRealmComponentsArr.slice(2);
    return {
      hs20NaiRealmName: naiRealmComponentsArr[1],
      // @ts-ignore
      hs20EapMethods: eapMethods.map((method) => {
        const hs20AuthParamTuples = method.match(/(\[\d+:\d+\])/g) as [string, ...string[]];
        const hs20EapMethodId = method.match(/^\d+/);
        return {
          id: hs20EapMethodId ? hs20EapMethodId[0] : '',
          hs20EapMethodId: hs20EapMethodId ? hs20EapMethodId[0] : '',
          hs20AuthParamTuples: hs20AuthParamTuples ?? [],
        };
      }),
    };
  });
}

import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import { VirtualDevicesCreateDrawer } from '../../../../../components/Devices/VirtualDeviceCreateDrawer';
import { VirtualDeviceType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/create-bulk',
  title: 'Bulk add - Access points - Hardware',
});

export default function AccessPointsBulkCreatePage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  return <VirtualDevicesCreateDrawer deviceType={VirtualDeviceType.AccessPoint} />;
}

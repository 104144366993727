import { DetailPageLayout } from '../../../components/DetailPageLayout';
import { useFilterAndGroup } from '../../../components/Hub/filtering/useFilterAndGroup';
import HubHeader, { ValidHubTabs } from '../../../components/Hub/HubHeader';
import HubPagination from '../../../components/Hub/HubPagination';
import HubList from '../../../components/Hub/List/HubList';
import { useHubNetworksOverview } from '../../../components/Hub/useHubNetworksOverview';
import { usePagination } from '../../../components/Hub/utils/usePagination';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../gql/graphql';
import { NosFeature, useNosFeatureEnabledForCompany } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';

export const Meta = () => ({
  path: '/org/:companyName/hub/list',
  title: 'List - Hub',
  layout: 'HubLayout',
});

export default function HubListPage() {
  const companyName = useCurrentCompany();
  const networksOverview = useHubNetworksOverview(companyName);
  const filter = useFilterAndGroup(networksOverview.networks);
  const pagination = usePagination(filter.data.rows.length);
  const companyHasConfig2COS = useNosFeatureEnabledForCompany(NosFeature.COS2, companyName);
  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        companyHasConfig2COS &&
        (permissions.hasPermission(PermissionType.PermCompanyGet) ||
          permissions.hasPermission(PermissionType.PermNetworkReadLimited))
      }
      should404OnAccessDenied
    >
      <DetailPageLayout
        header={<HubHeader activeTab={ValidHubTabs.List} />}
        main={<HubList data={filter.data} pagination={pagination} />}
        footer={<HubPagination pagination={pagination} />}
      />
    </IsPermitted>
  );
}

import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { isEmpty, range } from 'lodash-es';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ValidContentFilterRuleData } from './form_data';
import {
  getRuleForCompany,
  getRulesForCompany,
  updateRuleForCompany,
} from '../../../../../api/api';
// import { FormikConditional } from '../../../../../components/FormikConditional';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { categoryRules } from '../../../../../utils/content_filters';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { ContentField, PriorityField } from './fields';
import { fromAPIData, toAPIData, validContentFilterRuleData } from './form_data';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/dns-security/rules/:id',
});

export default function LegacyDNSSecurityCategoryRuleEditPage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const { id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacyDNSSecurityCategoryRuleEditPage),
  );

  const rule =
    useQuery(['dns_security', companyName, id], () => getRuleForCompany(companyName, id), {
      suspense: true,
    }).data ?? null;

  expectDefinedOrThrow(rule, new ResourceNotFoundError('Rule not found'));

  const ruleData =
    useQuery(['dns_security', companyName, 'rules'], async () => getRulesForCompany(companyName), {
      suspense: true,
    }).data ?? [];

  const rules = categoryRules(ruleData);
  const usedPrecedence = rules.map((r) => r.precedence - 2);
  const availablePrecedences = range(0, 99).filter(
    (i) => rule.precedence - 2 === i || !usedPrecedence.includes(i),
  );

  const queryClient = useQueryClient();

  const { mutate: updateRuleMutation, isLoading: mutating } = useMutation(
    (data: ValidContentFilterRuleData) => {
      const apiData = toAPIData(data, { precedenceOffset: 2 });
      return updateRuleForCompany(companyName, id, apiData);
    },
    {
      onSuccess: async () => {
        notify('Rule updated successfully', { variant: 'positive' });
        await queryClient.invalidateQueries(['dns_security', companyName]);
      },
      onError: () => {
        notify('Failed to update rule', { variant: 'negative' });
      },
    },
  );

  const navigate = useNavigate();
  const closeDrawer = useCloseDrawerCallback();

  const validateForm = (values: any) => {
    const validation = withZodSchema(validContentFilterRuleData)(values);
    if (!isEmpty(validation)) return validation;

    if (!availablePrecedences.includes(parseInt(values.precedence, 10))) {
      return {
        precedence: `Precedence ${values.precedence} is already in use.`,
      };
    }

    return {};
  };

  return (
    <Formik<ValidContentFilterRuleData>
      validate={validateForm}
      initialValues={fromAPIData(rule, { precedenceOffset: -2 })}
      onSubmit={(values) => updateRuleMutation(values)}
      enableReinitialize
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader
            icon="dns-security"
            heading="Edit rule"
            actions={
              <DropdownMenu>
                <DropdownMenuButton
                  variant="secondary"
                  icon="overflow-horizontal"
                  arrangement="hidden-label"
                >
                  Actions
                </DropdownMenuButton>
                <DropdownMenuPopover align="end">
                  <DropdownMenuItem
                    onSelect={() =>
                      navigate(
                        makeDrawerLink(
                          window.location,
                          paths.drawers.LegacyDNSSecurityCategoryRuleRemovePage,
                          {
                            id,
                            companyName,
                            controllerName,
                          },
                        ),
                      )
                    }
                    icon="trash-can"
                  >
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuPopover>
              </DropdownMenu>
            }
            onClose={useCloseDrawerCallback()}
          />
          <DrawerContent>
            <ContentField />
            <PriorityField />
          </DrawerContent>
          {/* <FormikConditional condition={(v, ctx) => ctx.dirty}> */}
          <DrawerFooter
            actions={
              <>
                <Button type="button" variant="secondary" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button type="submit" loading={mutating}>
                  Save
                </Button>
              </>
            }
          />
          {/* </FormikConditional> */}
        </Drawer>
      </StyledForm>
    </Formik>
  );
}

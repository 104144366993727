/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FieldContainer,
  OverlayTriggerState,
  PrimaryField,
  TextInput,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import type {
  AxiosAPIError,
  CompanyContractsResponse,
  CompanyLocationPutRequest,
  CompanyLocationResponse,
} from '@meterup/connect-api';
import { put } from '@meterup/connect-api/src/axios';
import { logError } from '@meterup/connect-ui/src/Log.utils';
import { Form } from '@meterup/connect-ui/src/styles/Form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { isString } from 'underscore';

import useCompanyPath from '../../hooks/useCompanyPath';

type RenameLocationProps = {
  state: OverlayTriggerState;
  contract: CompanyContractsResponse;
};

export default function RenameLocation({ state: dialogState, contract }: RenameLocationProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const { companyLocationSID, sid: sidInner } = useParams<{
    companyLocationSID: string;
    sid: string;
  }>();
  const sid = useMemo(() => companyLocationSID || sidInner, [companyLocationSID, sidInner]);
  const [locationName, setLocationName] = useState<string | undefined>();
  const locName = useMemo(() => {
    if (isString(locationName)) {
      return locationName;
    }

    return contract?.companyLocation?.name;
  }, [locationName, contract]);
  const queryClient = useQueryClient();
  const setNamePath = useCompanyPath(`connect/locations/${sid}`);
  const setNameMutation = useMutation<
    CompanyLocationResponse,
    AxiosAPIError,
    CompanyLocationPutRequest
  >(
    (vars) => {
      if (isString(vars.name) && vars.name && vars.name.length < 2) {
        throw new Error('Name must be at least 1 character long.');
      }
      return put(setNamePath, vars);
    },
    {
      onSuccess(result) {
        dialogState.close();
        setTimeout(() => setNameMutation.reset(), 1_500);
        notify('Name saved.', { variant: 'positive' });
        queryClient.setQueryData<CompanyContractsResponse>(['connection', sid], (old) => {
          if (!old) {
            return old;
          }
          let companyLocation = result;
          if (old && old.companyLocation) {
            companyLocation = old.companyLocation;
          }
          return {
            ...old,
            companyLocation: {
              ...companyLocation,
              name: result.name,
            },
          };
        });
      },
      onError(err) {
        logError(err);
        setErrorMessage(err.message);
        setTimeout(() => {
          setNameMutation.reset();
        }, 5_000);
      },
    },
  );
  const submitEditName = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        await setNameMutation.mutateAsync({ name: locName });
      } catch (err) {
        logError(err);
      }
    },
    [setNameMutation, locName],
  );
  const onClickResetName = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      try {
        await setNameMutation.mutateAsync({ name: undefined });
        setLocationName('');
      } catch (err) {
        logError(err);
      }
    },
    [setNameMutation, setLocationName],
  );

  const { location } = contract.companyLocation ?? {};
  if (!location) {
    return null;
  }
  const { address } = location;
  const { address1, city, state } = address || {};

  return (
    <Dialog state={dialogState} preset="narrow">
      <DialogHeader heading="Rename location" />
      <Form onSubmit={submitEditName}>
        <DialogContent gutter="all">
          <FieldContainer>
            <PrimaryField
              label="Name"
              errorMessage={setNameMutation.isError && errorMessage}
              element={
                <TextInput
                  controlSize="large"
                  value={locName}
                  onChange={setLocationName}
                  aria-label="Change name for location"
                  invalid={setNameMutation.isError}
                  disabled={setNameMutation.isLoading}
                />
              }
              description={
                <>
                  Address: {address1}, {city}, {state}
                </>
              }
            />
          </FieldContainer>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button
                variant="secondary"
                onClick={onClickResetName}
                loading={setNameMutation.isLoading}
                type="button"
              >
                Reset
              </Button>
              <Button variant="primary" loading={setNameMutation.isLoading} type="submit">
                Save
              </Button>
            </>
          }
        />
      </Form>
    </Dialog>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { deleteVirtualDeviceMutation } from '../../Devices/utils';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import { PowerDistributionUnitQuery, PowerDistributionUnitsQuery } from './utils';

export default function PowerDistributionUnitDeleteDialog({
  state,
  uuid,
}: {
  state: OverlayTriggerState;
  uuid: string;
}) {
  const network = useNetwork();
  const virtualDevice = useGraphQL(
    PowerDistributionUnitQuery,
    { uuid },
    { useErrorBoundary: false },
  ).data?.virtualDevice;
  const queryClient = useQueryClient();
  const deleteMutation = useGraphQLMutation(deleteVirtualDeviceMutation);
  const closeDrawer = useCloseDrawerCallback();
  const handleDelete = useCallback(() => {
    if (deleteMutation.isLoading) return;

    deleteMutation.mutate(
      { uuid },
      {
        onSuccess: () => {
          notify('Successfully deleted power distribution unit.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(PowerDistributionUnitsQuery, { networkUUID: network.UUID }),
          );
          closeDrawer();
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error deleting this power distribution unit ${
              gqlErr?.message ? `: ${gqlErr.message}` : ''
            }. Please make sure it is removed from any rack elevations.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [deleteMutation, closeDrawer, network.UUID, queryClient, uuid]);

  return (
    <DeleteDialog
      state={state}
      handleDelete={handleDelete}
      label={virtualDevice?.label}
      alert={{
        heading: 'Deleting a power distribution unit can impact the network',
        copy: 'Any connected devices will no longer use this power distribution unit.',
      }}
    />
  );
}

import type { ResultItem } from '@meterup/graphql';
import {
  Alert,
  FieldContainer,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryFieldComposite,
  Select,
  SelectItem,
  space,
  VStack,
} from '@meterup/atto';
import { AllRoles, roleToName, useIsOperator } from '@meterup/authorization';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';

import type { FormSchemaType } from './utils/schema';
import { type NetworksForCompanyQuery, RoleName } from '../../gql/graphql';
import { useIsCurrentCompanyAdmin } from '../../hooks/authorization';
import { useNetworksForCompany } from '../../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { getNetworkAddress } from '../../utils/network';

const visibleRolesForCompanyAdmin = AllRoles.filter((roleName) => roleName !== RoleName.Operator);
const visibleRolesForNetworkAdmin = visibleRolesForCompanyAdmin.filter(
  (roleName) => roleName !== RoleName.CompanyGlobalAdmin,
);

type Network = ResultItem<NetworksForCompanyQuery, 'networksForCompany'>;

type GroupedFormValues = Partial<{
  [roleName in RoleName]: {
    idx: number;
    network?: Network | null;
    roleAssignment: FormSchemaType['roleAssignments'][number];
  }[];
}>;

function labelForNetwork(network: ResultItem<NetworksForCompanyQuery, 'networksForCompany'>) {
  const label = getNetworkAddress(network);
  if (network.label) {
    return `${network.label} - ${label}`;
  }
  return label;
}

type RoleAssignmentsFieldsProps = {
  disabled?: boolean;
};

export default function RoleAssignmentsFields({ disabled }: RoleAssignmentsFieldsProps) {
  const companySlug = useCurrentCompany();
  const companyNetworks = useNetworksForCompany(companySlug);
  const [inputProps, , helper] = useField<FormSchemaType['roleAssignments']>('roleAssignments');
  const isOperator = useIsOperator({ respectDemoMode: true });
  const isCompanyAdmin = useIsCurrentCompanyAdmin();
  const visibleRoleList = useMemo(() => {
    if (isCompanyAdmin || isOperator) {
      return visibleRolesForCompanyAdmin;
    }
    return visibleRolesForNetworkAdmin;
  }, [isCompanyAdmin, isOperator]);
  const groupedRoles = useMemo(() => {
    const grouped: GroupedFormValues = {};
    inputProps.value.forEach((roleAssignment, idx) => {
      if (!grouped[roleAssignment.name]) {
        grouped[roleAssignment.name] = [];
      }
      if (
        roleAssignment.name === RoleName.CompanyNetworkAdmin ||
        roleAssignment.name === RoleName.CompanyStandardUser ||
        roleAssignment.name === RoleName.CompanyGuest
      ) {
        const network = companyNetworks.find((n) => n.UUID === roleAssignment.networkUUID);
        grouped[roleAssignment.name]?.push({ idx, network, roleAssignment });
        return;
      }
      grouped[roleAssignment.name]?.push({ idx, roleAssignment });
    });
    return grouped;
  }, [companyNetworks, inputProps]);
  const [selectedUserIsOperator, selectedUserIsCompanyAdmin] = useMemo(() => {
    if ((groupedRoles[RoleName.Operator]?.length ?? 0) > 0) {
      return [true, false];
    }
    if ((groupedRoles[RoleName.CompanyGlobalAdmin]?.length ?? 0) > 0) {
      return [false, true];
    }
    return [false, false];
  }, [groupedRoles]);
  const selectedUserHasHigherPermissionsThanCurrentUser = useMemo(() => {
    if (isOperator) {
      return false;
    }
    if (selectedUserIsOperator) {
      return true;
    }
    if (isCompanyAdmin) {
      return false;
    }
    return selectedUserIsCompanyAdmin;
  }, [isCompanyAdmin, isOperator, selectedUserIsCompanyAdmin, selectedUserIsOperator]);
  const activeRoleName = useMemo(() => {
    if ((inputProps.value?.length ?? 0) > 0) {
      return inputProps.value[0].name;
    }
    return RoleName.CompanyStandardUser;
  }, [inputProps]);
  const activeRole = useMemo(
    () => groupedRoles[activeRoleName] ?? [],
    [activeRoleName, groupedRoles],
  );
  const activeRoleNetworks = useMemo(() => {
    if (!selectedUserIsCompanyAdmin && !selectedUserIsOperator) {
      return activeRole.flatMap((r) => (r.network?.UUID ? [r.network.UUID] : []));
    }
    return [];
  }, [activeRole, selectedUserIsCompanyAdmin, selectedUserIsOperator]);
  const onRoleValueChange = useCallback(
    (val: RoleName) => {
      if (val === RoleName.CompanyGlobalAdmin) {
        helper.setValue([
          {
            companySlug,
            name: RoleName.CompanyGlobalAdmin,
            isNew: true,
          },
        ]);
      }
      if (
        val === RoleName.CompanyNetworkAdmin ||
        val === RoleName.CompanyStandardUser ||
        val === RoleName.CompanyGuest
      ) {
        helper.setValue(
          inputProps.value.map((role) => ({
            ...role,
            companySlug,
            name: val,
          })),
        );
      }
    },
    [companySlug, helper, inputProps],
  );
  const onNetworksValueChange = useCallback(
    (vals: Set<string>) => {
      const networks = [...vals.values()];
      const roleAssignments = networks.map((networkUUID) => ({
        companySlug,
        isNew: false,
        name: activeRoleName,
        networkUUID,
      }));
      helper.setValue(roleAssignments);
    },
    [companySlug, activeRoleName, helper],
  );

  if (selectedUserHasHigherPermissionsThanCurrentUser) {
    return (
      <Alert
        variant="neutral"
        icon="warning"
        heading="Unable to modify"
        copy="This user has a higher level of access. You will be unable to update this user's role."
      />
    );
  }
  return (
    <FieldContainer>
      <PrimaryFieldComposite
        label="Role"
        fields={
          <VStack spacing={space(8)}>
            <Select
              width="100%"
              value={activeRoleName}
              onValueChange={(val) => onRoleValueChange(val as RoleName)}
              disabled={selectedUserHasHigherPermissionsThanCurrentUser || disabled}
            >
              {visibleRoleList.map((roleName) => (
                <SelectItem key={roleName}>{roleToName(roleName)}</SelectItem>
              ))}
            </Select>
            {activeRoleName !== RoleName.Operator &&
              activeRoleName !== RoleName.CompanyGlobalAdmin && (
                <MultiComboBox
                  label="Networks"
                  value={activeRoleNetworks}
                  placeholder="Select networks"
                  disabled={selectedUserHasHigherPermissionsThanCurrentUser || disabled}
                  onValueChange={(vals) => onNetworksValueChange(vals as Set<string>)}
                >
                  {companyNetworks.map((network) => (
                    <MultiComboBoxItem textValue={labelForNetwork(network)} key={network.UUID}>
                      {labelForNetwork(network)}
                    </MultiComboBoxItem>
                  ))}
                </MultiComboBox>
              )}
          </VStack>
        }
      />
    </FieldContainer>
  );
}

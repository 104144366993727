import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Alert, EmptyState, Pane, PaneContent, PaneHeader } from '@meterup/atto';
import { styled } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import { getRulesForCompany } from '../../../../../../api/api';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { allowedDomainsRule, blockedDomainsRule } from '../../../../../../utils/content_filters';
import DNSSecurityAddDomainForm from './DNSSecurityAddDomainForm';
import DNSSecurityDomainList from './DNSSecurityDomainList';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/dns-security/domains',
  layout: 'LegacyDNSSecurityLayout',
});

const Container = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',

  '@maxSm': {
    flexDirection: 'column',
  },

  '@sm': {
    flexDirection: 'row',
  },
});

const Column = styled('div', {
  display: 'block',

  '@maxSm': {
    width: '100%',
    height: '100%',
  },

  '@sm': {
    width: '50%',
  },
});

export default function LegacyDNSSecurityDomainsPage() {
  const companyName = useCurrentCompany();
  const ruleData = useQuery(
    ['dns_security', companyName, 'rules'],
    async () => getRulesForCompany(companyName),
    {
      suspense: true,
    },
  );

  const rules = useMemo(() => ruleData.data ?? [], [ruleData]);
  const allowedRule = useMemo(() => allowedDomainsRule(rules), [rules]);
  const blockedRule = useMemo(() => blockedDomainsRule(rules), [rules]);

  return (
    <Pane>
      <Alert
        variant="neutral"
        copy="Priority for the rules are applied in the order of allowed domains, then blocked domains, and finally categories."
        relation="stacked"
        icon="information"
      />
      <Container>
        <Column>
          <Pane>
            <PaneHeader icon="checkmark" heading="Allow" />
            <PaneContent>
              <DNSSecurityAddDomainForm precedence={0} />
              {allowedRule && allowedRule.domains.length > 0 ? (
                <DNSSecurityDomainList rule={allowedRule} />
              ) : (
                <EmptyState heading="No domains are being allowed" icon="checkmark" />
              )}
            </PaneContent>
          </Pane>
        </Column>
        <Column>
          <Pane>
            <PaneHeader icon="cross" heading="Block" />
            <PaneContent>
              <DNSSecurityAddDomainForm precedence={1} />
              <PaneContent>
                {blockedRule && blockedRule.domains.length > 0 ? (
                  <DNSSecurityDomainList rule={blockedRule} />
                ) : (
                  <EmptyState heading="No domains are being blocked" icon="cross" />
                )}
              </PaneContent>
            </PaneContent>
          </Pane>
        </Column>
      </Container>
    </Pane>
  );
}

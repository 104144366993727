import { formatBytes } from '@meterup/common';
import { DateTime } from 'luxon';

import type { PoEInfo } from '../../gql/graphql';
import { graphql } from '../../gql';
import { DeviceModel, DeviceType, VirtualDeviceType } from '../../gql/graphql';

export const createVirtualDevice = graphql(`
  mutation createVirtualDevice($networkUUID: UUID!, $input: CreateVirtualDeviceInput!) {
    createVirtualDevice(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateVirtualDeviceMutation = graphql(`
  mutation UpdateVirtualDevice($uuid: UUID!, $input: UpdateVirtualDeviceInput!) {
    updateVirtualDevice(virtualDeviceUUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteVirtualDeviceMutation = graphql(`
  mutation DeleteVirtualDevice($uuid: UUID!) {
    deleteVirtualDevice(virtualDeviceUUID: $uuid) {
      UUID
    }
  }
`);

export const createVirtualDevicesMutation = graphql(`
  mutation createVirtualDevices($networkUUID: UUID!, $input: CreateVirtualDevicesInput!) {
    createVirtualDevices(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateVirtualDevicesIndependentlyMutation = graphql(`
  mutation UpdateVirtualDevicesIndependently(
    $networkUUID: UUID!
    $inputs: [UpdateVirtualDeviceIndependentlyInput!]!
  ) {
    updateVirtualDevicesIndependently(networkUUID: $networkUUID, inputs: $inputs) {
      UUID
    }
  }
`);

export const deviceTypeForVirtualDeviceType: Record<VirtualDeviceType, DeviceType> = {
  [VirtualDeviceType.Controller]: DeviceType.Controller,
  [VirtualDeviceType.Switch]: DeviceType.Switch,
  [VirtualDeviceType.AccessPoint]: DeviceType.AccessPoint,
  [VirtualDeviceType.Observer]: DeviceType.AccessPoint,
  [VirtualDeviceType.PowerDistributionUnit]: DeviceType.PowerDistributionUnit,
};

export const deviceTypeModels: Record<DeviceType, DeviceModel[]> = {
  [DeviceType.Controller]: [DeviceModel.Mc05, DeviceModel.Mc06, DeviceModel.Mc11],
  [DeviceType.AccessPoint]: [
    DeviceModel.Mw03,
    DeviceModel.Mw04,
    DeviceModel.Mw05,
    DeviceModel.Mw06,
    DeviceModel.Mw07,
    DeviceModel.Mw08,
    DeviceModel.Mw09,
  ],
  [DeviceType.Switch]: [DeviceModel.Ms10, DeviceModel.Ms11, DeviceModel.Ms12],
  [DeviceType.PowerDistributionUnit]: [DeviceModel.Mp01],
} as const;

export const defaultDeviceTypeModels: Record<DeviceType, DeviceModel> = {
  [DeviceType.Controller]: DeviceModel.Mc06,
  [DeviceType.Switch]: DeviceModel.Ms10,
  [DeviceType.AccessPoint]: DeviceModel.Mw08,
  [DeviceType.PowerDistributionUnit]: DeviceModel.Mp01,
} as const;

export const deviceTypeToLabelMap: Record<DeviceType, string> = {
  [DeviceType.AccessPoint]: 'Access point',
  [DeviceType.Controller]: 'Security appliance',
  [DeviceType.Switch]: 'Switch',
  [DeviceType.PowerDistributionUnit]: 'Power distribution unit',
} as const;

export const deviceTypeToLabelPrefix: Record<DeviceType, string> = {
  [DeviceType.AccessPoint]: 'AP',
  [DeviceType.Controller]: '',
  [DeviceType.Switch]: '',
  [DeviceType.PowerDistributionUnit]: 'PDU',
} as const;

export function deviceTypeLabelPlural(
  maybeVirtualDeviceType: DeviceType | VirtualDeviceType,
): string {
  const deviceType = deviceTypeForVirtualDeviceType[maybeVirtualDeviceType];

  switch (deviceType) {
    case DeviceType.Switch:
      return 'Switches';
  }
  return `${deviceTypeToLabelMap[deviceType]}s`;
}

export const assignHardwareDeviceToVirtualDevice = graphql(`
  mutation AssignHardwareDeviceToVirtualDevice($serialNumber: String!, $virtualDeviceUUID: UUID!) {
    assignHardwareDeviceToVirtualDevice(
      serialNumber: $serialNumber
      virtualDeviceUUID: $virtualDeviceUUID
    ) {
      virtualDevice {
        UUID
        label
      }
    }
  }
`);

export const createDevHardwareDeviceMutation = graphql(`
  mutation CreateDevHardwareDevice($input: CreateDevHardwareDeviceInput!) {
    createDevHardwareDevice(input: $input) {
      serialNumber
    }
  }
`);

export const assignHardwareDeviceToNetwork = graphql(`
  mutation AssignHardwareDeviceToNetwork($serialNumber: String!, $networkUUID: UUID!) {
    assignHardwareDeviceToNetwork(serialNumber: $serialNumber, networkUUID: $networkUUID) {
      virtualDevice {
        UUID
        label
      }
    }
  }
`);

export const unassignHardwareDeviceFromVirtualDevice = graphql(`
  mutation UnassignHardwareDeviceFromVirtualDevice($serialNumber: String!) {
    unassignHardwareDeviceFromVirtualDevice(serialNumber: $serialNumber) {
      serialNumber
    }
  }
`);

export const assignHardwareToVirtualDevicesMutation = graphql(`
  mutation AssignHardwareToVirtualDevices(
    $networkUUID: UUID!
    $inputs: [AssignHardwareDeviceToVirtualDeviceInput!]!
  ) {
    assignHardwareDevicesToVirtualDevices(networkUUID: $networkUUID, inputs: $inputs) {
      serialNumber
      virtualDevice {
        UUID
        label
      }
    }
  }
`);

export const hardwareDeviceQuery = graphql(`
  query HardwareDevice($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      deviceType
      deviceModel

      virtualDevice {
        UUID
        label
        description
      }
    }
  }
`);

export const updateHardwareDeviceMutation = graphql(`
  mutation UpdateHardwareDevice($serialNumber: String!, $input: UpdateHardwareDeviceInput!) {
    updateHardwareDevice(serialNumber: $serialNumber, input: $input) {
      serialNumber
    }
  }
`);

export const blinkLEDsMutation = graphql(`
  mutation BlinkLEDsMutation($serialNumber: String!) {
    rpcBlinkLEDs(serialNumber: $serialNumber, durationSec: 10)
  }
`);

export const refreshConfigMutation = graphql(`
  mutation RefreshConfigMutation($serialNumber: String!) {
    rpcRefreshConfig(serialNumber: $serialNumber)
  }
`);

export const rpcPDUOutletCycleMutation = graphql(`
  mutation RpcPDUOutletCycleMutation($serialNumber: String!, $input: RPCPDUOutletCycleInput!) {
    rpcPDUOutletCycle(serialNumber: $serialNumber, input: $input)
  }
`);

export function formatCPULoad(usedPercentage: number) {
  return `${(usedPercentage * 100).toFixed(2)}%`;
}

export function formatMemoryUsage(usage: { bytesUsed: number; bytesAvailable: number }) {
  const memoryCapacity = usage.bytesUsed + usage.bytesAvailable;

  const percentageFree = Math.floor((usage.bytesAvailable / memoryCapacity) * 100);

  const bytesAvailable = formatBytes(usage.bytesAvailable);
  const bytesMemoryCapacity = formatBytes(memoryCapacity);
  const res = `${bytesAvailable} of ${bytesMemoryCapacity} (${percentageFree}% free)`;

  return res;
}

export function formatMemoryUsageWithTotal(usage: { bytesAvailable: number; bytestTotal: number }) {
  const percentageFree = Math.floor((usage.bytesAvailable / usage.bytestTotal) * 100);
  const bytesAvailable = formatBytes(usage.bytesAvailable);
  const bytesMemoryCapacity = formatBytes(usage.bytestTotal);
  const res = `${bytesAvailable} of ${bytesMemoryCapacity} (${percentageFree}% free)`;

  return res;
}

export function formatPoEInfo(poeInfo: Pick<PoEInfo, 'max' | 'used'> | null | undefined) {
  if (poeInfo?.used == null || poeInfo?.max == null) return 'N/A';
  const percentageUsed = poeInfo.max ? Math.floor((poeInfo.used / poeInfo.max) * 100) : 0;
  const res = `${poeInfo.used.toFixed(2)} of ${poeInfo.max} Watts (${percentageUsed}%)`;

  return res;
}

export function formatTimestamp(isoTimestamp: string) {
  const localTime = DateTime.fromISO(isoTimestamp).toLocal();
  return `${localTime.toLocaleString(DateTime.DATETIME_FULL)}`;
}

export const LatestDeviceMemoryUsageQuery = graphql(`
  query LatestDeviceMemoryUsage($serialNumber: String!) {
    latestDeviceMemoryUsage(serialNumber: $serialNumber) {
      bytesUsed
      bytesAvailable
    }
  }
`);

export const DeviceUptimeQuery = graphql(`
  query DeviceUptime($serialNumber: String!) {
    deviceUptime(serialNumber: $serialNumber)
  }
`);

export const LatestDeviceCPULoadQuery = graphql(`
  query LatestDeviceCPULoad($serialNumber: String!) {
    latestDeviceCPULoad(serialNumber: $serialNumber) {
      usedPercentage
    }
  }
`);

export const switchPoEInfoQuery = graphql(`
  query SwitchPoEInfo($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      __typename
      ... on SwitchHardwareDevice {
        poeInfo {
          max
          used
        }
      }
    }
  }
`);

export const switchVirtualDevicePoEInfoQuery = graphql(`
  query SwitchVirtualDevicePoEInfo($uuid: UUID!) {
    virtualDevice(UUID: $uuid) {
      __typename
      ... on SwitchVirtualDevice {
        poeInfo {
          max
          used
        }
      }
    }
  }
`);

export const SystemInfoStatQuery = graphql(`
  query SystemInfoStatQuery($uuid: UUID!) {
    systemInfoStatForVirtualDevice(
      virtualDeviceUUID: $uuid
      filter: { durationSeconds: 300, limit: 1 }
    ) {
      uptime
      memoryBytesAvailable
      memoryBytesTotal
      cpuUsedPercentage
    }
  }
`);

export const updatePhyInterfaceMutation = graphql(`
  mutation UpdatePhyInterface($uuid: UUID!, $input: UpdatePhyInterfaceInput!) {
    updatePhyInterface(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const updatePhyInterfacesMutation = graphql(`
  mutation UpdatePhyInterfaces(
    $phyInterfaceUUIDs: [UUID!]!
    $virtualDeviceUUID: UUID!
    $input: UpdatePhyInterfaceInput!
  ) {
    updatePhyInterfaces(
      phyInterfaceUUIDs: $phyInterfaceUUIDs
      virtualDeviceUUID: $virtualDeviceUUID
      input: $input
    ) {
      UUID
    }
  }
`);

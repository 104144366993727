import type { FileInfo } from '@meterup/atto';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropzoneUploader,
  FieldContainer,
  HStack,
  LoadingIcon,
  PrimaryField,
  Section,
  SectionContent,
  SectionHeader,
  space,
  styled,
} from '@meterup/atto';
import { AutoTable, notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Papa from 'papaparse';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

import type {
  AssignHardwareDeviceToVirtualDeviceInput,
  UpdateVirtualDeviceIndependentlyInput,
} from '../../gql/graphql';
import type { Device } from '../../routes/pages/network/insights/logs/hooks/useVirtualDevices';
import { PermissionType, VirtualDeviceType } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { usePermissions } from '../../providers/PermissionsProvider';
import { useVirtualDevices } from '../../routes/pages/network/insights/logs/hooks/useVirtualDevices';
import { devicesForNetwork } from '../Design/RackElevations/utils';
import { CopyableMonoOrNoValue } from '../Devices/Insights';
import {
  assignHardwareToVirtualDevicesMutation,
  updateVirtualDevicesIndependentlyMutation,
} from '../Devices/utils';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { AccessPointsQuery } from './utils';

const csvRowSchema = z.object({
  name: z.string().optional(),
  location: z.string(),
  index: z.enum(['0', '1']).transform((val) => Number(val) as RadioType),
  channel: z.number({ coerce: true }).optional(),
  power: z.number({ coerce: true }).optional(),
  channel_width: z.number({ coerce: true }).optional(),
  disabled: z.enum(['TRUE', 'FALSE']).transform((val) => val === 'TRUE'),
  serial_number: z.string().optional(),
});
const csvSchema = z.array(csvRowSchema);

enum RadioType {
  Radio5G = 0,
  Radio2G = 1,
}

interface Radio {
  radioType: RadioType;
  channel: number;
  power: number;
  channelWidth: number;
  enabled: boolean;
}

interface AP {
  label: string;
  serialNumber: string | undefined;
  radios: Record<RadioType, Radio>;
}

function defaultRadios(): Record<RadioType, Radio> {
  return {
    [RadioType.Radio5G]: {
      radioType: RadioType.Radio5G,
      channel: 36,
      power: 7,
      channelWidth: 40,
      enabled: true,
    },
    [RadioType.Radio2G]: {
      radioType: RadioType.Radio2G,
      channel: 11,
      power: 7,
      channelWidth: 20,
      enabled: true,
    },
  };
}

async function parseAPsCSV(csv: string): Promise<Map<string, AP>> {
  const results = Papa.parse(csv, { header: true });
  if (results.errors?.length) {
    // eslint-disable-next-line no-console
    console.debug(results);
    throw new Error(`CSV failed to process ${results.errors.length} rows`);
  }
  const rows = await csvSchema.parseAsync(results.data);

  const map = new Map<string, AP>();
  for (const row of rows) {
    let ap = map.get(row.location);
    if (!ap) {
      ap = {
        label: row.location,
        serialNumber: row.serial_number,
        radios: defaultRadios(),
      };
      map.set(row.location, ap);
    }

    if (row.channel) {
      ap.radios[row.index].channel = row.channel;
    }
    if (row.power) {
      ap.radios[row.index].power = row.power;
    }
    if (row.channel_width) {
      ap.radios[row.index].channelWidth = row.channel_width;
    }
    ap.radios[row.index].enabled = !row.disabled;
  }

  return map;
}

const ListSectionContent = styled(SectionContent, {
  maxHeight: '$300',
  overflow: 'auto',
});

const apColumnBuilder = createColumnBuilder<AP>();

const missingAPsColumns = [
  apColumnBuilder.data((row) => row.label, {
    id: 'label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
  }),
];

const hardwareToAssignColumnBuilder = createColumnBuilder<{
  input: AssignHardwareDeviceToVirtualDeviceInput;
  virtualDevice: Device;
}>();

const hardwareToAssignColumns = [
  hardwareToAssignColumnBuilder.data((row) => row.virtualDevice.label, {
    id: 'label',
    header: 'Label',
  }),
  hardwareToAssignColumnBuilder.data((row) => row.input.serialNumber, {
    id: 'serial-number',
    header: 'Serial number',
    cell: ({ value }) => <CopyableMonoOrNoValue label="serial number" value={value} />,
  }),
];

const updateVirtualDeviceInputBuilder = createColumnBuilder<{
  input: UpdateVirtualDeviceIndependentlyInput;
  virtualDevice: Device;
}>();

const virtualDevicesToUpdateColumns = [
  updateVirtualDeviceInputBuilder.data((row) => row.virtualDevice.label, {
    id: 'label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
  }),
  updateVirtualDeviceInputBuilder.data(
    (row) => row.input.input.radioSettings?.band2_4GPrimaryChannel,
    {
      id: '2-ghz-channel',
      header: '2.4GHz channel',
    },
  ),
  updateVirtualDeviceInputBuilder.data(
    (row) => row.input.input.radioSettings?.band2_4GTransmitPowerdBm,
    {
      id: '2-tx-dbm',
      header: '2.4GHz Tx dBm',
    },
  ),
  updateVirtualDeviceInputBuilder.data(
    (row) => row.input.input.radioSettings?.band5GPrimaryChannel,
    {
      id: '5-ghz-channel',
      header: '5GHz channel',
    },
  ),
  updateVirtualDeviceInputBuilder.data(
    (row) => row.input.input.radioSettings?.band5GTransmitPowerdBm,
    {
      id: '5-tx-dbm',
      header: '5GHz Tx dBm',
    },
  ),
];

export default function CSVBulkUploadDrawer() {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [showMissingAPs, setShowMissingAPs] = useState(false);
  const [showHardwareInputs, setShowHardwareInputs] = useState(false);
  const [showRadioInputs, setShowRadioInputs] = useState(false);

  const currentAPs = useVirtualDevices({ deviceType: VirtualDeviceType.AccessPoint });
  const labelsToVirtualDevices = useMemo(() => {
    const map = new Map<string, Device>();
    for (const device of currentAPs.virtualDevices) {
      map.set(device.label, device);
    }
    return map;
  }, [currentAPs.virtualDevices]);

  const parseCSV = useMutation(async (inputFiles: File[]) => {
    if (inputFiles.length !== 1) throw new Error('Invalid number of files');
    const [file] = inputFiles;

    const text = await file.text();

    return parseAPsCSV(text);
  });
  const { mutate, reset } = parseCSV;

  useEffect(() => {
    if (!files.length) {
      reset();
      return;
    }

    mutate(files.map((f) => f.fileObject));
  }, [files, mutate, reset]);

  const { assignHardwareInputs, updateVirtualDeviceInputs, apsWithMissingVirtualDevices } =
    useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const assignHardwareInputs: AssignHardwareDeviceToVirtualDeviceInput[] = [];
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const updateVirtualDeviceInputs: UpdateVirtualDeviceIndependentlyInput[] = [];
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const apsWithMissingVirtualDevices: AP[] = [];
      if (!parseCSV.data?.size)
        return { assignHardwareInputs, updateVirtualDeviceInputs, apsWithMissingVirtualDevices };

      for (const ap of parseCSV.data.values()) {
        const device = labelsToVirtualDevices.get(ap.label);
        if (device) {
          updateVirtualDeviceInputs.push({
            virtualDeviceUUID: device.UUID,
            input: {
              radioSettings: {
                band2_4GPrimaryChannel: ap.radios[RadioType.Radio2G].channel,
                band2_4GTransmitPowerdBm: ap.radios[RadioType.Radio2G].power,
                band5GPrimaryChannel: ap.radios[RadioType.Radio5G].channel,
                band5GTransmitPowerdBm: ap.radios[RadioType.Radio5G].power,
              },
            },
          });

          if (ap.serialNumber && !device.hardwareDevice) {
            assignHardwareInputs.push({
              serialNumber: ap.serialNumber,
              virtualDeviceUUID: device.UUID,
            });
          }
        } else {
          apsWithMissingVirtualDevices.push(ap);
        }
      }

      return { assignHardwareInputs, updateVirtualDeviceInputs, apsWithMissingVirtualDevices };
    }, [parseCSV.data, labelsToVirtualDevices]);

  const assignHardware = useGraphQLMutation(assignHardwareToVirtualDevicesMutation);
  const updateVirtualDevices = useGraphQLMutation(updateVirtualDevicesIndependentlyMutation);

  const { mutate: doAssignHardware } = assignHardware;
  const { mutate: doUpdateRadioSettings } = updateVirtualDevices;
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const handleAssignHardware = useCallback(() => {
    if (!assignHardwareInputs?.length) return;

    doAssignHardware(
      {
        networkUUID: network.UUID,
        inputs: assignHardwareInputs,
      },
      {
        onSuccess(data) {
          notify(
            `Successfully assigned ${data.assignHardwareDevicesToVirtualDevices.length} hardware devices.`,
            {
              variant: 'positive',
            },
          );
          queryClient.invalidateQueries(
            makeQueryKey(AccessPointsQuery, { networkUUID: network.UUID, includeUptime }),
          );
          queryClient.invalidateQueries(
            makeQueryKey(devicesForNetwork, {
              networkUUID: network.UUID,
              filter: { deviceType: VirtualDeviceType.AccessPoint },
            }),
          );
        },
        onError(err) {
          notify(
            `There was a problem assigning hardware devices${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [network.UUID, includeUptime, doAssignHardware, assignHardwareInputs, queryClient]);

  const handleUpdateVirtualDevices = useCallback(() => {
    if (!updateVirtualDeviceInputs?.length) return;

    doUpdateRadioSettings(
      {
        networkUUID: network.UUID,
        inputs: updateVirtualDeviceInputs,
      },
      {
        onSuccess(data) {
          notify(
            `Successfully updated radio channel and transmit power for ${data.updateVirtualDevicesIndependently.length} devices.`,
            {
              variant: 'positive',
            },
          );
          queryClient.invalidateQueries(
            makeQueryKey(AccessPointsQuery, { networkUUID: network.UUID, includeUptime }),
          );
          queryClient.invalidateQueries(
            makeQueryKey(devicesForNetwork, {
              networkUUID: network.UUID,
              filter: { deviceType: VirtualDeviceType.AccessPoint },
            }),
          );
        },
        onError(err) {
          notify(
            `There was a problem updating radio channel and transmit power${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [network.UUID, includeUptime, doUpdateRadioSettings, updateVirtualDeviceInputs, queryClient]);

  return (
    <Drawer>
      <DrawerHeader heading="Bulk CSV upload" onClose={closeDrawer} />
      <DrawerContent>
        <FieldContainer>
          <PrimaryField
            label="CSV"
            element={
              <DropzoneUploader
                files={files}
                onFileRemoved={(id) => {
                  setFiles((prev) => prev.filter((file) => file.id !== id));
                }}
                allowedTypes={{ csv: true }}
                onDrop={(acceptedFiles) => {
                  setFiles(
                    acceptedFiles.map((file, index) => ({
                      id: `${file.name}:${index}`,
                      fileObject: file,
                      uploadPercentage: 100,
                      error: null,
                    })),
                  );
                }}
                callToAction="Drop CSV here to process..."
                maxFiles={1}
                compact
              />
            }
          />
        </FieldContainer>

        {parseCSV.isLoading && (
          <HStack justify="center">
            <LoadingIcon size={space(24)} />
          </HStack>
        )}

        {parseCSV.isError && (
          <Alert
            variant="negative"
            icon="attention"
            heading="Failed to parse CSV"
            copy={
              parseCSV.error && parseCSV.error instanceof Error ? parseCSV.error.message : undefined
            }
          />
        )}

        {parseCSV.data && (
          <>
            {!!apsWithMissingVirtualDevices.length && (
              <Section>
                <SectionHeader
                  heading={`${apsWithMissingVirtualDevices.length} virtual devices not found`}
                  actions={
                    <Button
                      type="button"
                      variant="secondary"
                      size="x-small"
                      onClick={() => {
                        setShowMissingAPs((prev) => !prev);
                      }}
                      icon={showMissingAPs ? 'eye-closed' : 'eye-open'}
                      arrangement="hidden-label"
                    >
                      {showMissingAPs ? 'Hide' : 'Show'} APs
                    </Button>
                  }
                />
                {showMissingAPs && (
                  <ListSectionContent>
                    <AutoTable columns={missingAPsColumns} data={apsWithMissingVirtualDevices} />
                  </ListSectionContent>
                )}
              </Section>
            )}

            {!!assignHardwareInputs.length && (
              <Section>
                <SectionHeader
                  heading={`${assignHardwareInputs.length} hardware assignments`}
                  actions={
                    <Button
                      type="button"
                      variant="secondary"
                      size="x-small"
                      onClick={() => {
                        setShowHardwareInputs((prev) => !prev);
                      }}
                      icon={showHardwareInputs ? 'eye-closed' : 'eye-open'}
                      arrangement="hidden-label"
                    >
                      {showHardwareInputs ? 'Hide' : 'Show'} assignments
                    </Button>
                  }
                />
                {showHardwareInputs && (
                  <ListSectionContent>
                    <AutoTable
                      columns={hardwareToAssignColumns}
                      data={assignHardwareInputs.map((input) => ({
                        virtualDevice: currentAPs.uuidToVirtualDeviceMap[input.virtualDeviceUUID]!,
                        input,
                      }))}
                    />
                  </ListSectionContent>
                )}
              </Section>
            )}

            {!!updateVirtualDeviceInputs.length && (
              <>
                <Section>
                  <SectionHeader
                    heading={`${updateVirtualDeviceInputs.length} radio settings updates`}
                    actions={
                      <Button
                        type="button"
                        variant="secondary"
                        size="x-small"
                        onClick={() => {
                          setShowRadioInputs((prev) => !prev);
                        }}
                        icon={showRadioInputs ? 'eye-closed' : 'eye-open'}
                        arrangement="hidden-label"
                      >
                        {showRadioInputs ? 'Hide' : 'Show'} settings
                      </Button>
                    }
                  />
                  {showRadioInputs && (
                    <ListSectionContent>
                      <AutoTable
                        columns={virtualDevicesToUpdateColumns}
                        data={updateVirtualDeviceInputs.map((input) => ({
                          virtualDevice:
                            currentAPs.uuidToVirtualDeviceMap[input.virtualDeviceUUID]!,
                          input,
                        }))}
                      />
                    </ListSectionContent>
                  )}
                </Section>
                <Alert
                  icon="information"
                  heading="This will not alter radio profiles or assign APs to radio profiles"
                  copy="Please update radio profiles separately to disable particular radios for this device."
                />
              </>
            )}
          </>
        )}
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button
              type="button"
              variant="primary"
              size="medium"
              onClick={handleAssignHardware}
              loading={assignHardware.isLoading}
              disabled={!assignHardwareInputs?.length}
            >
              Assign hardware
            </Button>
            <Button
              type="button"
              variant="primary"
              size="medium"
              onClick={handleUpdateVirtualDevices}
              loading={updateVirtualDevices.isLoading}
              disabled={!updateVirtualDeviceInputs?.length}
            >
              Update radio settings
            </Button>
          </>
        }
      />
    </Drawer>
  );
}

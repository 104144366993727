import type { AllSecurityAppliancesQuery } from '../../../../gql/graphql';
import { graphql } from '../../../../gql';

export const securityAppliancesQuery = graphql(`
  query AllSecurityAppliances($limit: Int, $offset: Int) {
    hardwareDevices(filter: { deviceType: CONTROLLER, limit: $limit, offset: $offset }) {
      serialNumber
      isActive
      deviceType
      deviceModel

      virtualDevice {
        UUID
        label
        network {
          UUID
          label
          slug

          company {
            slug
            name
          }
        }
      }
    }
  }
`);

export type SecurityApplianceForList = AllSecurityAppliancesQuery['hardwareDevices'][number];

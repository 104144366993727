/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { LoadingIcon, space, TextInput } from '@meterup/atto';
import { styled } from '@meterup/common';
import { ConnectionType, QuoteRequest } from '@meterup/connect-api';
import useCreateQuote from '@meterup/connect-api/src/hooks/useCreateQuote';
import ClearInputButton from '@meterup/connect-ui/src/components/ClearInputButton';
import { useCustomStyles } from '@meterup/connect-ui/src/components/Select/hooks/useCustomStyles';
import { notify } from '@meterup/connect-ui/src/components/stolenFromFrontends/notify';
import useFetchLocationPredictions, {
  FetchLocationItem,
} from '@meterup/connect-ui/src/hooks/useFetchLocationPredictions';
import { useIdentity, useIdentityName } from '@meterup/connect-ui/src/hooks/useIdentity';
import usePredictionFormatter from '@meterup/connect-ui/src/hooks/usePredictionFormatter';
import { FormRowInputCell } from '@meterup/connect-ui/src/styles/Table/table-styles';
import formatLocationParts from '@meterup/connect-ui/src/utils/formatLocationParts';
import { isFunction } from 'lodash-es';
import { InputProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import Select from 'react-select/base';
import {
  CSSObjectWithLabel,
  GroupBase,
  SingleValue,
} from 'react-select/dist/declarations/src/types';

import useCompanySlug from '../../hooks/useCompanySlug';

const InputWrapper = styled('div', {
  '& > label': {
    width: '100%',
  },
});

type HasDescription = {
  description?: string | null;
};

function Input<T extends HasDescription>(props: InputProps<T, false>) {
  // const value = props.selectProps.value || [];
  const { onChange: onChangeProp, getStyles, getValue, selectProps, ...restProps } = props;
  const styles = getStyles('input', props);
  const onChange = useCallback(
    (val: string) => {
      if (!onChangeProp) {
        return;
      }
      onChangeProp({
        // @ts-ignore
        currentTarget: {
          value: val,
        },
      });
    },
    [onChangeProp],
  );
  const labelRef = useRef<HTMLLabelElement>(null);
  const value = useMemo(() => {
    const val = getValue();
    if (val && val.length > 0) {
      return val[0].description;
    }
    if (restProps.value) {
      return restProps.value;
    }

    return '';
  }, [getValue, selectProps.value, restProps.value]);
  return (
    <InputWrapper>
      <TextInput
        controlSize="small"
        icon="plus-circle"
        placeholder="Enter address"
        {...restProps}
        width="100%"
        // @ts-ignore
        style={styles}
        onChange={onChange}
        // onKeyDown={onKeyDown}
        ref={labelRef}
        suffix={
          selectProps.isLoading ? (
            <LoadingIcon size={space(12)} />
          ) : (
            <ClearInputButton onClick={props.clearValue} visible={getValue().length > 0} />
          )
        }
        data-1p-ignore
        // @ts-ignore
        value={value}
      />
    </InputWrapper>
  );
}

type AddLocationTableInputProps = {
  onCreatedQuoteRequest?: (quoteRequest: QuoteRequest) => void;
  onFailedToCreateQuoteRequest?: (err: unknown) => void;
};

export default function AddLocationTableInput({
  onCreatedQuoteRequest,
  onFailedToCreateQuoteRequest,
}: AddLocationTableInputProps) {
  const selectRef = useRef<Select<FetchLocationItem, false, GroupBase<FetchLocationItem>> | null>(
    null,
  );
  const customStyles = useCustomStyles<ConnectionType, false>({
    showIndicator: true,
    controlPositioning: false,
    size: 'small',
    unstyled: true,
    capsuleStyle: false,
    // @ts-ignore
    custom: (styles: CSSObjectWithLabel) => ({
      ...styles,
      singleValue: () => ({
        display: 'none',
        // ...styles.singleValue(...args),
        // marginLeft: 18,
        // zIndex: 5,
      }),
      valueContainer: (...args) => ({
        ...(isFunction(styles.valueContainer) ? styles.valueContainer(...args) : {}),
        padding: '2px 0',
        overflow: 'visible',
      }),
      indicatorsContainer: () => ({
        display: 'none',
      }),
    }),
  });
  const [selectedOption, setSelectedOption] = useState<FetchLocationItem>();
  const optionSelected = useCallback((newValue: SingleValue<FetchLocationItem>) => {
    setSelectedOption(newValue as FetchLocationItem);
  }, []);
  const fetchPredictions = useFetchLocationPredictions();
  const formatPrediction = usePredictionFormatter();
  const createQuoteMutation = useCreateQuote();
  const companySlug = useCompanySlug();
  const identity = useIdentity();
  const userRealName = useIdentityName();
  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!selectedOption) {
        return false;
      }
      const formatted = await formatPrediction(selectedOption);
      if (!formatted) {
        return false;
      }

      try {
        const result = await createQuoteMutation.mutateAsync({
          companyName: companySlug!,
          contactName: userRealName,
          contactEmail: identity?.username ?? '',
          contactTelephone: '',
          requestedDownloadKbps: 0,
          requestedUploadKbps: 0,
          contractMinimumMonths: 0,
          notes: '',
          existingMonthlyFeeCents: 0,
          existingProviderSid: '',
          location: formatLocationParts(formatted),
        });
        const address = result.location?.address;
        if (!address) {
          return false;
        }
        const { address1, address2, city, state, postalCode } = address;
        const parts = [address1, [address2, city, state, postalCode].filter(Boolean).join(', ')]
          .filter(Boolean)
          .join(', ');

        notify(`Added ${parts}`, { variant: 'positive' });
        if (isFunction(onCreatedQuoteRequest)) {
          onCreatedQuoteRequest(result);
        }
        selectRef.current?.clearValue();
      } catch (err) {
        notify('Error adding location', { variant: 'negative' });
        if (isFunction(onFailedToCreateQuoteRequest)) {
          onFailedToCreateQuoteRequest(err);
        }
        console.error(err);
      }

      return false;
    },
    [
      companySlug,
      createQuoteMutation,
      formatPrediction,
      identity?.username,
      selectedOption,
      userRealName,
    ],
  );
  return (
    <FormRowInputCell onSubmit={onSubmit}>
      <AsyncSelect<FetchLocationItem>
        cacheOptions
        openMenuOnFocus
        isClearable={false}
        isMulti={false}
        isLoading={createQuoteMutation.isLoading}
        closeMenuOnScroll={false}
        getOptionLabel={(o) => o?.description ?? ''}
        getOptionValue={(o) => o?.description ?? ''}
        loadOptions={fetchPredictions}
        noOptionsMessage={() => null}
        isDisabled={createQuoteMutation.isLoading}
        onChange={optionSelected}
        // onKeyDown={onKeyDown}
        // @ts-ignore
        options={selectedOption}
        placeholder={false}
        ref={selectRef}
        // @ts-ignore
        styles={customStyles}
        components={{
          DropdownIndicator: null,
          Input,
        }}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        menuPosition="fixed"
      />
    </FormRowInputCell>
  );
}

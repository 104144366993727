import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import FloorPlanEditDrawer from '../../../../../components/FloorPlans/FloorPlanEditDrawer';
import { floorPlanQuery } from '../../../../../components/FloorPlans/utils';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/design/floor-plans/:floorPlanUUID',
  title: 'Edit floor plan - Floor plan - Floor plans - Design',
});

export default function FloorPlanEditPage() {
  const { floorPlanUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.FloorPlanEditPage),
    new ResourceNotFoundError('Page not found'),
  );

  // TODO put this behind perm check and refactor FloorPlanEditDrawer
  // to prop drill UUID and not a floorplan
  const floorPlan = checkDefinedOrThrow(
    useGraphQL(floorPlanQuery, { UUID: floorPlanUUID }).data?.floorPlan,
    new ResourceNotFoundError('Floor plan not found'),
  );

  return <FloorPlanEditDrawer floorPlan={floorPlan} />;
}

import type { MeterV1NetworkVLAN } from '@meterup/config';
import { PrimaryField, TextInput } from '@meterup/atto';
import { useFormikContext } from 'formik';
import React from 'react';

import type { ValidStaticClient } from './form_data';
import { CopyToClipboardButtonMinimal } from '../../../../../../components/CopyToClipboardButton';
import { FieldProvider } from '../../../../../../components/Form/FieldProvider';

function NamePreview() {
  const form = useFormikContext<ValidStaticClient>();
  return <span>{`${form.values.name ? form.values.name : '<name>'}.local.meter`}</span>;
}

export function NameField() {
  const form = useFormikContext<ValidStaticClient>();
  return (
    <FieldProvider name="name">
      <PrimaryField
        label="Name"
        element={<TextInput />}
        controls={<CopyToClipboardButtonMinimal text={`${form.values.name}.local.meter`} />}
        description={<NamePreview />}
      />
    </FieldProvider>
  );
}

export function MACField() {
  return (
    <FieldProvider name="mac">
      <PrimaryField label="MAC address" element={<TextInput />} />
    </FieldProvider>
  );
}

export function IPField({ vlan }: { vlan: MeterV1NetworkVLAN }) {
  return (
    <FieldProvider name="ip">
      <PrimaryField
        label="IP assignment"
        element={<TextInput />}
        description={
          vlan.usableRange ? (
            <div>
              Must be in the range {vlan.usableRange.start.address} to{' '}
              {vlan.usableRange.end.address}
            </div>
          ) : undefined
        }
      />
    </FieldProvider>
  );
}

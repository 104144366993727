import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { DetailPageLayout } from '../../../../components/DetailPageLayout';
import BulkUpgraderHeader from '../../../../components/firmwareBulkUpgrader/BulkUpgraderHeader';

export const Meta: PagefileMetaFn = () => ({
  path: '/operator/firmware-bulk-upgrader',
  layout: 'OperatorLayout',
  title: 'Firmware bulk upgrader - Operator',
});

export default function FirmwareBulkUpgraderLayout() {
  return <DetailPageLayout header={<BulkUpgraderHeader />} />;
}

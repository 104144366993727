import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import StaticRouteEditDrawer from '../../../../../components/Routing/StaticRouteEditDrawer';
import { staticRouteQuery } from '../../../../../components/Routing/utils';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/routing/static-route/:routeUUID',
  title: 'Edit static route - Static routes - Network-wide',
});

export default function StaticRouteEditPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.StaticRouteEditPage);
  const route = checkDefinedOrThrow(
    useGraphQL(staticRouteQuery, {
      uuid: drawerParams?.routeUUID!,
    })?.data?.staticRoute,
    new ResourceNotFoundError('Policy routing rule not found'),
  );

  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags, pathParams }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPolicyRoutingWrite) &&
            nosFlags[NosFeature.POLICY_ROUTING] &&
            ldFlags['policy-routing'] &&
            !!pathParams?.routeUUID,
        )
      }
      path={Meta().path}
    >
      <StaticRouteEditDrawer route={route} />
    </IsPermitted>
  );
}

import {
  Badge,
  Button,
  HStack,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';

import type { InsightsQueryQuery, SwitchQueryQuery } from '../../../gql/graphql';
import type { SwitchesRootQueryResult } from './utils';
import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { DetailsWidgetListPair } from '../../Devices/Insights';
import { ReactRouterLink } from '../../ReactRouterLink';
import { getRootBridgeUUID } from './utils';

// Unification types for sharing this component between two different queries
type SwitchDetailsHardwareDevice =
  | SwitchQueryQuery['virtualDevice']['hardwareDevice']
  | InsightsQueryQuery['hardwareDevice'];

type SwitchDetailsVirtualDevice =
  | SwitchQueryQuery['virtualDevice']
  | InsightsQueryQuery['hardwareDevice']['virtualDevice'];

export function DetailsWidget({
  hardwareDevice,
  virtualDevice,
  relation,
}: {
  hardwareDevice?: SwitchDetailsHardwareDevice;
  virtualDevice?: SwitchDetailsVirtualDevice;
  relation: 'stacked' | 'standalone';
}) {
  if (hardwareDevice && hardwareDevice.__typename !== 'SwitchHardwareDevice') return null;
  if (virtualDevice?.__typename !== 'SwitchVirtualDevice') return null;

  return virtualDevice || hardwareDevice ? (
    <Section relation={relation}>
      <SectionHeader heading="Metadata" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          {virtualDevice && (
            <DetailsWidgetListPair label="Model" value={virtualDevice.deviceModel} />
          )}
          {hardwareDevice && (
            <>
              <DetailsWidgetListPair label="MAC address" value={hardwareDevice.macAddress} />
              <DetailsWidgetListPair label="DHCP IP address" value={hardwareDevice.ipAddress} />
            </>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  ) : null;
}

export function STPWidget({
  switchData,
  switchesData,
}: {
  switchData: InsightsQueryQuery;
  switchesData: SwitchesRootQueryResult[];
}) {
  const { hardwareDevice } = switchData || {};
  const { virtualDevice } = hardwareDevice || {};
  const network = useNetwork();
  const companyName = useCurrentCompany();

  // eslint-disable-next-line no-underscore-dangle
  if (hardwareDevice.__typename !== 'SwitchHardwareDevice') return null;
  // eslint-disable-next-line no-underscore-dangle
  if (!virtualDevice || virtualDevice.__typename !== 'SwitchVirtualDevice') return null;

  const isRootBridge = hardwareDevice.stpInfo?.isRootBridge === true;
  const rootBridgeUUID = getRootBridgeUUID(switchesData);

  return (
    <Section relation="stacked">
      <SectionHeader heading="STP" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>Bridge priority</SummaryListKey>
            <SummaryListValue>
              <HStack spacing={space(8)} align="center">
                {virtualDevice.switchProfile?.stpBridgePriority}
                {isRootBridge && (
                  <Badge variant="neutral" size="small">
                    Root
                  </Badge>
                )}
              </HStack>
            </SummaryListValue>
          </SummaryListRow>
          {!isRootBridge && rootBridgeUUID && (
            <Button
              as={ReactRouterLink}
              to={makeLink(paths.pages.SwitchDetailPage, {
                companyName,
                networkSlug: network.slug,
                uuid: rootBridgeUUID,
                tab: 'ports',
              })}
              replace={false}
              variant="secondary"
              icon="chevron-right"
              arrangement="leading-label"
              size="large"
              width="100%"
            >
              View root switch
            </Button>
          )}
          {!isRootBridge && !rootBridgeUUID && (
            <SummaryListRow>
              <SummaryListKey>Root Bridge MAC</SummaryListKey>
              <SummaryListValue>{hardwareDevice.stpInfo?.rootBridgeMACAddress}</SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import IPSecTunnelDrawer from './IPSecTunnelDrawer';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/ipsec-tunnels/create',
  title: 'Add IPSec tunnel - IPSec tunnels - Secure tunnels',
});

export default function CreateIPSecTunnelPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermIpsecTunnelWrite) &&
            ldFlags['ipsec-tunnels'] &&
            nosFlags[NosFeature.IPSEC_TUNNELS] &&
            nosFlags[NosFeature.COS2],
        )
      }
      should404OnAccessDenied
    >
      <IPSecTunnelDrawer />
    </IsPermitted>
  );
}

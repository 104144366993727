import { Badge, EmptyState, Icon, space } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable, isDefinedAndNotEmpty } from '@meterup/common';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';

import type { EventLogEvent } from '../utils/events';
import type { EventLogTimeFilter } from './useEventLogEvents';
import { paths } from '../constants';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { useNetwork } from '../hooks/useNetworkFromPath';
import { Nav } from '../nav';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../utils/main_and_drawer_navigation';
import { NoValue } from './NoValue';
import { createColumnBuilder } from './Table/createColumnBuilder';
import { useParsedEventLogEvents } from './useEventLogEvents';

const builder = createColumnBuilder<EventLogEvent>();

export const columns = [
  builder.data((d) => d.badge ?? '', {
    id: 'badge',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Type of event',
      },
    },
    cell: (props) => {
      if (props.value === 'warning') {
        return (
          <Badge
            arrangement="hidden-label"
            size="small"
            icon="attention"
            ends="pill"
            variant="neutral"
          >
            Warning
          </Badge>
        );
      }
      if (props.value === 'attention') {
        return (
          <Badge
            arrangement="hidden-label"
            size="small"
            icon="warning"
            ends="pill"
            variant="negative"
          >
            Attention
          </Badge>
        );
      }
      return null;
    },
  }),
  builder.data((d) => d.type, {
    id: 'event',
    header: 'Event',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.short_description, {
    id: 'message',
    header: 'Message',
  }),
  builder.data((d) => d.time ?? '', {
    id: 'time',
    header: `Time (${DateTime.local().toFormat('ZZZZZ')})`,
    meta: {
      alignment: 'end',
    },
    cell: (d) => (isDefinedAndNotEmpty(d.value) ? <span>{d.value}</span> : <NoValue />),
  }),
];

export const defaultStartDate = DateTime.now().startOf('day').minus({ months: 3 }).toISO();
export const defaultEndDate = DateTime.now().endOf('day').toISO();

export function EventLogView() {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const [startDate] = useSearchParamsState<string>('startDate', defaultStartDate);
  const [endDate] = useSearchParamsState<string>('endDate', defaultEndDate);

  const timeFilter: EventLogTimeFilter = useMemo(
    () => ({
      startTime: startDate,
      endTime: endDate,
    }),
    [startDate, endDate],
  );
  const events = useParsedEventLogEvents({ networkUUID: network.UUID, timeFilter });
  const [querySearch] = useSearchParamsState<string>('search', '');
  const linkTo = (row: EventLogEvent) =>
    makeDrawerLink(window.location, paths.drawers.EventLogSummaryPage, {
      companyName,
      networkSlug: network.slug,
      uuid: row.UUID,
    });

  const params = Nav.useRegionParams('drawer', paths.drawers.EventLogSummaryPage);
  const isOperator = useIsOperator();
  const ldflags = useFeatureFlags();

  const rowSelected = useCallback(
    (row: EventLogEvent) => params?.uuid === row.UUID,
    [params?.uuid],
  );

  return events.length > 0 && (isOperator || ldflags['event-logs']) ? (
    <AutoTable
      key={JSON.stringify(events)}
      columns={columns}
      data={events}
      globalFilter={querySearch}
      getLinkTo={linkTo}
      isRowSelected={rowSelected}
    />
  ) : (
    <EmptyState icon="log" heading="No recent events" />
  );
}

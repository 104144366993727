import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPopover,
  Icon,
  space,
  TextInput,
} from '@meterup/atto';
import { DateTime } from 'luxon';

import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { stripDateTimeForInput } from '../../../../../utils/time';

export default function NetworkMutationsFilters() {
  const defaultStartDate = stripDateTimeForInput(
    DateTime.now().startOf('day').minus({ months: 3 }),
  );
  const defaultEndDate = stripDateTimeForInput(DateTime.now().endOf('day'));
  const [queryStartDate, setStartDate] = useSearchParamsState<string>(
    'startDate',
    defaultStartDate,
  );
  const [queryEndDate, setEndDate] = useSearchParamsState<string>('endDate', defaultEndDate);
  const [includeMachine, setIncludeMachine] = useSearchParamsState<boolean>(
    'includeMachine',
    false,
  );

  const startDate = queryStartDate || defaultStartDate;
  const endDate = queryEndDate || defaultEndDate;

  return (
    <>
      <TextInput
        icon="calendar"
        type="datetime-local"
        value={stripDateTimeForInput(DateTime.fromISO(startDate))}
        onChange={(value) => {
          setStartDate(DateTime.fromISO(value).toISO());
        }}
        inputProps={{
          max: endDate,
        }}
        aria-label="Start Date"
      />
      <Icon color={{ light: 'gray500', dark: 'gray300' }} icon="arrow-right" size={space(12)} />
      <TextInput
        icon="calendar"
        type="datetime-local"
        inputProps={{
          min: startDate,
          max: DateTime.now()
            .endOf('day')
            .set({
              second: 0,
              millisecond: 0,
            })
            .toISO({
              includeOffset: false,
              suppressSeconds: true,
              suppressMilliseconds: true,
            }),
        }}
        value={stripDateTimeForInput(DateTime.fromISO(endDate))}
        onChange={(value) => {
          setEndDate(DateTime.fromISO(value).toISO());
        }}
        aria-label="End Date"
      />
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
          aria-label="Options"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuCheckboxItem
              checked={includeMachine}
              onCheckedChange={(checked) => {
                setIncludeMachine(checked);
              }}
              aria-label="Include Machine Mutations"
            >
              Include machine user mutations
            </DropdownMenuCheckboxItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
    </>
  );
}

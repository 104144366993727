import { orderBy } from 'lodash-es';

import type { NetworkClientOptions } from './networkClients/useNetworkClients';
import type { Network } from './useNetworksForCompany';
import { useNetworkClients } from './networkClients/useNetworkClients';

export const useClientDataByMAC = (network: Network, options: NetworkClientOptions) => {
  const clientHistory = useNetworkClients(network, options);

  return orderBy(clientHistory, (d) => d.lastSeen, 'desc')[0] ?? null;
};

import type { MeterV2WirelessAccessPoint, MeterV2WirelessServiceSet } from '@meterup/config';
import { Badge, Button, EmptyState, Icon, PaneContent, space } from '@meterup/atto';
import { AutoTable, checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import { Box } from '../../../../../components/Box';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

interface ServiceSetAndAPs {
  serviceSet: MeterV2WirelessServiceSet;
  aps: MeterV2WirelessAccessPoint[];
  countOfAllAPs: number;
  countOfAPsBroadcastingSSID: number;
}

const builder = createColumnBuilder<ServiceSetAndAPs>();

const columns = [
  builder.data((row) => (row.serviceSet.hidden ? 'SSID hidden' : 'SSID visible'), {
    enableGlobalFilter: false,
    id: 'status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: (d) => (
      <Badge
        arrangement="hidden-label"
        icon={d.row.serviceSet.hidden ? 'eye-closed' : 'eye-open'}
        variant={d.row.serviceSet.hidden ? 'neutral' : 'positive'}
        size="small"
        ends="pill"
      >
        {d.row.serviceSet.hidden ? 'SSID hidden' : 'SSID visible'}
      </Badge>
    ),
  }),
  builder.data((d) => d.serviceSet.ssid, {
    id: 'ssid',
    header: 'SSID',
    meta: {
      minWidth: 44,
      isLeading: true,
    },
  }),
  builder.data(() => 'WPA2', {
    id: 'standard',
    header: 'Standard',
    cell: () => (
      <Badge size="small" ends="card">
        WPA2
      </Badge>
    ),
  }),
  builder.data((d) => Array.from(d.serviceSet.getSortedBands()).join(', '), {
    id: 'bands',
    header: 'Bands (GHz)',
    cell: (d) => (
      <Box css={{ hStack: '$4' }}>
        {d.row.serviceSet
          .getSortedBands()
          .map((b) => b.replace(' GHz', ''))
          .map((b) => (
            <Badge key={b} size="small" ends="card">
              {b}
            </Badge>
          ))}
      </Box>
    ),
  }),
  builder.data(
    (d) =>
      d.countOfAPsBroadcastingSSID === d.countOfAllAPs
        ? 'All'
        : `${d.countOfAPsBroadcastingSSID} / ${d.countOfAllAPs}`,
    {
      id: 'aps',
      header: 'Access points',
      enableGlobalFilter: false,
      cell: (d) => (
        <Badge size="small" ends="card">
          {d.value}
        </Badge>
      ),
    },
  ),
];

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/ssids',
});

export default function VLANSSIDListPage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANSSIDListPage),
  );

  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();

  const model = useControllerConfig(controllerName);

  const params = Nav.useRegionParams('drawer', paths.drawers.LegacySSIDEditPage);
  const onRowDeselect = useCloseDrawerCallback();

  const createSSIDLink = makeDrawerLink(window.location, paths.drawers.LegacySSIDCreatePage, {
    controllerName,
    companyName,
  });

  return (
    <PaneContent>
      {model.getServiceSetsByVLAN(vlanName).length > 0 ? (
        <AutoTable
          data={model.getServiceSetsByVLAN(vlanName).map((serviceSet) => {
            const aps = model.getAPsBroadcastingServiceSet(serviceSet);
            return {
              serviceSet,
              aps,
              countOfAllAPs: model.accessPoints.length,
              countOfAPsBroadcastingSSID: aps.length,
            };
          })}
          columns={columns}
          onRowDeselect={onRowDeselect}
          isRowSelected={(d) => d.serviceSet.stableId === params?.id}
          getLinkTo={(d) =>
            makeDrawerLink(window.location, paths.drawers.LegacySSIDEditPage, {
              id: d.serviceSet.stableId,
              controllerName,
              companyName,
            })
          }
        />
      ) : (
        <EmptyState
          icon="ssid"
          heading="No SSIDs"
          action={
            <Button as={ReactRouterLink} to={createSSIDLink} icon="plus" arrangement="leading-icon">
              Add SSID
            </Button>
          }
        />
      )}
    </PaneContent>
  );
}

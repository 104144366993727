import React from 'react';

import { IconName } from '@meterup/atto';

export default function withIconValueContainer<T>(
  icon: IconName,
  Component: React.ComponentType<T>,
): React.ComponentType<T> {
  function WithIconValueContainer(props: T) {
    return <Component icon={icon} {...props} />;
  }
  WithIconValueContainer.displayName = `WithIconValueContainer(${
    Component.displayName || Component.name
  })`;
  return WithIconValueContainer;
}

import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import Alerts from '../../../../../components/Settings/Network/Notifications/Alerts/Alerts';

export function Meta() {
  return {
    path: '/org/:companyName/settings/network/:networkSlug/notifications/alerts',
    title: 'Alerts - Notifications - Network',
  };
}

export default function AlertsPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }
  return <Alerts />;
}

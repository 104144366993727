import type { MeterV1NetworkNATPortForward } from '@meterup/config';
import type { SortingState } from '@tanstack/react-table';
import {
  Button,
  EmptyState,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  SearchInput,
  Small,
  space,
} from '@meterup/atto';
import { AutoTable, isDefined, NeutralBadge, PositiveBadge, TCPOrUDPBadge } from '@meterup/common';

import { NoValue } from '../../../../../components/NoValue';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import {
  type NetworkClient,
  useNetworkClients,
} from '../../../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

interface PortForwardAndClient {
  model: MeterV1NetworkNATPortForward;
  client?: NetworkClient;
}

const builder = createColumnBuilder<PortForwardAndClient>();

const columns = [
  builder.data((row) => (isDefined(row.client) ? 'Connected' : 'No connection'), {
    id: 'status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: (p) =>
      isDefined(p.row.client) ? (
        <PositiveBadge arrangement="hidden-label" icon="client">
          Connected
        </PositiveBadge>
      ) : (
        <NeutralBadge arrangement="hidden-label" icon="client">
          No connection
        </NeutralBadge>
      ),
  }),
  builder.data((d) => d.model.description, {
    id: 'desc',
    header: 'Description',
    meta: {
      isLeading: true,
    },
    // eslint-disable-next-line react/jsx-no-useless-fragment
    cell: (props) => <>{props.value || <NoValue />}</>,
  }),
  builder.data((d) => d.model.externalPort.toString(), {
    id: 'external-port',
    header: 'External port',
    meta: { width: 88 },
    cell: (props) => <Small family="monospace">{props.value}</Small>,
  }),
  builder.data((d) => d.model.protocol, {
    id: 'protocol',
    header: 'Protocol',
    meta: { width: 68 },
    cell: (props) => <TCPOrUDPBadge value={props.value} />,
  }),
  builder.data((d) => d.model.internalIPAddress, {
    id: 'internal-ip',
    header: 'Internal IP',
    cell: (props) => <Small family="monospace">{props.value}</Small>,
  }),
  builder.data((d) => d.model.internalPort.toString(), {
    id: 'internal-port',
    header: 'Internal port',
    meta: { width: 88 },
    cell: (props) => <Small family="monospace">{props.value}</Small>,
  }),
];

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/firewall/port-forwarding',
  layout: 'NetworkLayout',
});

export default function LegacyPortForwardingRuleListPage() {
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const companyName = useCurrentCompany();
  const model = useControllerConfig(controllerName);
  const onRowDeselect = useCloseDrawerCallback();
  const network = useNetwork();

  const clients = useNetworkClients(network);

  const { portForwards } = model.nat;

  const portForwardsWithClients = portForwards.map((portForward) => {
    const client = clients.find((c) => c.ip === portForward.internalIPAddress);
    return {
      model: portForward,
      client,
    };
  });

  const params = Nav.useRegionParams('drawer', paths.drawers.NATPortForwardingRuleEditPage);
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const createRulePath = makeDrawerLink(
    window.location,
    paths.drawers.NATPortForwardingRuleCreatePage,
    {
      controllerName,
      companyName,
    },
  );
  return (
    <Pane>
      <PaneHeader
        icon="port-forward"
        heading="Port forwarding"
        actions={
          <>
            <Button
              as={ReactRouterLink}
              to={createRulePath}
              variant="secondary"
              icon="plus"
              condense
              size="small"
              arrangement="leading-icon"
            >
              Add rule
            </Button>
            <SearchInput
              placeholder="..."
              aria-label="Filter VLANs"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent>
        {portForwards.length > 0 ? (
          <AutoTable
            data={portForwardsWithClients}
            columns={columns}
            sortingState={sortingState}
            globalFilter={globalFilter}
            onChangeSortingState={setSortingState}
            onRowDeselect={onRowDeselect}
            isRowSelected={(d) => d.model.stableId === params?.ruleId}
            getLinkTo={(d) =>
              makeDrawerLink(window.location, paths.drawers.NATPortForwardingRuleEditPage, {
                ruleId: d.model.stableId,
                controllerName,
                companyName,
              })
            }
          />
        ) : (
          <EmptyState
            icon="port-forward"
            heading="No port forwarding rules"
            action={
              <Button
                icon="plus"
                arrangement="leading-icon"
                as={ReactRouterLink}
                to={createRulePath}
              >
                Add rule
              </Button>
            }
          />
        )}
      </PaneContent>
    </Pane>
  );
}

import {
  CheckboxInput,
  colors,
  darkThemeSelector,
  Label,
  PrimaryField,
  Segment,
  Segments,
  Select,
  SelectItem,
  space,
  styled,
  Textarea,
  TextInput,
} from '@meterup/atto';
import { DateTime } from 'luxon';
import { type ReactNode, useId, useMemo, useState } from 'react';

export function PrimaryTextInput({
  label,
  value,
  onChange,
  description,
  placeholder,
  type,
  multiline,
  rows,
}: {
  multiline?: boolean;
  rows?: number;
  type?: 'text' | 'email';
  label: string;
  value: string;
  description?: ReactNode;
  placeholder?: string;
  onChange: (v: string) => void;
}) {
  const sharedProps = {
    value,
    placeholder,
  };

  return (
    <PrimaryField
      label={label}
      description={description}
      element={
        multiline ? (
          <Textarea {...sharedProps} rows={rows ?? 4} onChange={(e) => onChange(e.target.value)} />
        ) : (
          <TextInput {...sharedProps} type={type ?? 'text'} onChange={(v) => onChange(v)} />
        )
      }
    />
  );
}

export function PrimaryNumericInput({
  label,
  value,
  onChange,
  description,
  placeholder,
}: {
  label: string;
  value: number;
  description?: ReactNode;
  placeholder?: string;
  onChange: (v: number) => void;
}) {
  return (
    <PrimaryField
      label={label}
      description={description}
      element={
        <TextInput
          type="number"
          value={String(value)}
          placeholder={placeholder}
          onChange={(v) => onChange(Number(v))}
        />
      }
    />
  );
}

function unSlug(s: string) {
  return s
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

export function PrimarySegmentToggle<Option extends string, Options extends readonly Option[]>({
  label,
  options,
  value,
  onChange,
  unSlugLabels,
}: {
  unSlugLabels?: boolean;
  label: string;
  options: Options;
  value: Options[number] | null;
  onChange: (v: Options[number]) => void;
}) {
  return (
    <PrimaryField
      label={label}
      element={
        <Segments direction="column">
          {options.map((option) => (
            <Segment active={value === option} onClick={() => onChange(option)}>
              {unSlugLabels ? unSlug(option) : option}
            </Segment>
          ))}
        </Segments>
      }
    />
  );
}

export function PrimaryBooleanSegmentToggle({
  label,
  onChange,
  value,
}: {
  label: ReactNode;
  onChange: (v: boolean | 'unsure') => void;
  value: boolean | 'unsure' | null;
}) {
  return (
    <PrimaryField
      label={label}
      element={
        <Segments direction="column">
          <Segment active={value === true} icon="checkmark-circle" onClick={() => onChange(true)}>
            Yes
          </Segment>
          <Segment active={value === false} icon="minus-circle" onClick={() => onChange(false)}>
            No
          </Segment>
          <Segment active={value === 'unsure'} icon="question" onClick={() => onChange('unsure')}>
            Unsure
          </Segment>
        </Segments>
      }
    />
  );
}

export function PrimarySelect<Options extends readonly string[]>({
  label,
  options,
  value,
  onChange,
  placeholder,
  unSlugLabels,
}: {
  unSlugLabels?: boolean;
  placeholder?: string;
  label: string;
  options: Options;
  value: Options[number] | null;
  onChange: (v: Options[number]) => void;
}) {
  return (
    <PrimaryField
      label={label}
      element={
        <Select
          placeholder={placeholder}
          width="100%"
          value={value}
          onValueChange={(v) => {
            onChange(v as Options[number]);
          }}
        >
          {options.map((option) => (
            <SelectItem key={option}>{unSlugLabels ? unSlug(option) : option}</SelectItem>
          ))}
        </Select>
      }
    />
  );
}

type ContactFormState = {
  name: string | null;
  phone_number: string | null;
  email: string | null;
};

export function useContactFormState(defaultValues?: ContactFormState) {
  const [state, setState] = useState<ContactFormState>(
    defaultValues ?? {
      name: null,
      phone_number: null,
      email: null,
    },
  );

  function patchState<T extends keyof ContactFormState>(key: T, value: ContactFormState[T]) {
    setState((prevState) => ({ ...prevState, [key]: value }));
  }

  function clearState() {
    setState({ name: null, phone_number: null, email: null });
  }

  const valuesToSubmit = useMemo(() => {
    if (state.name === null) return null;
    return { name: state.name, phone_number: state.phone_number, email: state.email };
  }, [state]);

  return {
    state,
    valuesToSubmit,
    patchState,
    clearState,
  };
}

export function ContactForm({ state, patchState }: ReturnType<typeof useContactFormState>) {
  return (
    <>
      <PrimaryField
        label="Name"
        element={
          <TextInput
            placeholder="Johnny Appleseed"
            type="text"
            value={state?.name || ''}
            onChange={(v) => patchState('name', v.length === 0 ? null : v)}
          />
        }
      />
      <PrimaryField
        label="Phone Number"
        element={
          <TextInput
            placeholder="(555) 555-5555"
            type="tel"
            value={state?.phone_number || ''}
            onChange={(v) => {
              patchState('phone_number', v.length === 0 ? null : v);
            }}
          />
        }
      />
      <PrimaryField
        label="Email"
        element={
          <TextInput
            placeholder="johnny@appleseed.com"
            type="email"
            value={state?.email || ''}
            onChange={(v) => {
              patchState('email', v.length === 0 ? null : v);
            }}
          />
        }
      />
    </>
  );
}

function CheckboxWithLabel({
  label,
  value,
  onChange,
}: {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  const id = useId();
  return (
    <div style={{ display: 'flex', gap: space(4), alignItems: 'center' }}>
      <CheckboxInput id={id} onChange={() => onChange(!value)} checked={value}>
        {label}
      </CheckboxInput>
      <Label htmlFor={id} aria-label={label}>
        {label}
      </Label>
    </div>
  );
}

export function useMultiSelectCheckboxes<Options extends readonly string[]>({
  options,
  defaultCheckedValues,
}: {
  options: Options;
  defaultCheckedValues: Options[number][];
}) {
  const [checked, setChecked] = useState<Options[number][]>(defaultCheckedValues);

  function toggle(option: Options[number]) {
    setChecked((prev) => {
      if (prev.includes(option)) {
        return prev.filter((v) => v !== option);
      }
      return [...prev, option];
    });
  }

  function clearValues() {
    setChecked([]);
  }

  return { checked, toggle, clearValues, options };
}

export type MultiSelectCheckboxesState = ReturnType<typeof useMultiSelectCheckboxes>;

const ListContainer = styled('div', {
  border: '1px solid',
  borderColor: colors.strokeNeutralLight,
  borderRadius: '$10',
  padding: '$space$4 $space$8',
  background: colors.white,
  [darkThemeSelector]: {
    background: colors.gray800,
    borderColor: colors.strokeNeutralDark,
  },
});

export function PrimaryMultiSelectCheckboxes({
  label,
  state,
}: {
  label: string;
  state: MultiSelectCheckboxesState;
}) {
  return (
    <PrimaryField
      label={label}
      element={
        <ListContainer>
          {state.options.map((option) => (
            <CheckboxWithLabel
              key={option}
              label={option}
              value={state.checked.includes(option)}
              onChange={() => state.toggle(option)}
            />
          ))}
        </ListContainer>
      }
    />
  );
}

export function PrimaryDateInput({
  label,
  value,
  onChange,
  description,
}: {
  label: string;
  value: string;
  description?: ReactNode;
  onChange: (v: string) => void;
}) {
  const date = useMemo(() => {
    const dt = DateTime.fromISO(value).startOf('day');
    return dt.toISODate();
  }, [value]);

  return (
    <PrimaryField
      label={label}
      description={description}
      element={
        <TextInput
          type="date"
          placeholder="YYYY-MM-DD"
          value={date}
          onChange={(v) => onChange(v)}
        />
      }
    />
  );
}

import { Button, Dock, EmptyState, Layout, LayoutPage } from '@meterup/atto';
import React from 'react';
import { useLocation } from 'react-router';

import { ReactRouterLink } from '../../components/ReactRouterLink';
import { paths } from '../../constants';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '*',
  layout: null,
});

export function NotFoundPage() {
  const location = useLocation();
  return (
    <Layout>
      <Dock location={location} />
      <LayoutPage>
        <EmptyState
          icon="attention"
          heading="Page not found"
          action={
            <Button
              as={ReactRouterLink}
              to={makeLink(paths.pages.RootPage, {})}
              arrangement="leading-icon"
              icon="home"
            >
              Back to the Meter Dashboard
            </Button>
          }
        />
      </LayoutPage>
    </Layout>
  );
}

export default NotFoundPage;

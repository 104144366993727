import type { MouseEvent } from 'react';
import {
  Dock,
  DockTarget,
  LayoutPage,
  ProductIcon,
  useDialogState,
  useViewport,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import IsOperator from '@meterup/authorization/src/components/IsOperator';
import { useLocalStorage } from '@meterup/common';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import { paths } from '../constants';
import { PermissionType } from '../gql/graphql';
import { useControllerDataFromPathOrNull } from '../hooks/useControllerDataFromPath';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../hooks/useIsActive';
import { useNetworkOrNull } from '../hooks/useNetworkFromPath';
import { useNetworksForCompany } from '../hooks/useNetworksForCompany';
import { NosFeature, useNosFeatureEnabledForCompany } from '../hooks/useNosFeatures';
import { ProductTypes, useUserProductsAccess } from '../hooks/useUserProductsAccess';
import { useCurrentCompanyOrDefault } from '../providers/CurrentCompanyProvider';
import { useDefaultController } from '../providers/DefaultControllerProvider';
import { useDefaultNetwork } from '../providers/DefaultNetworkProvider';
import { usePermissions } from '../providers/PermissionsProvider';
import { makeLink } from '../utils/main_and_drawer_navigation';
import GetNetworkDemoDialog from './GetNetworkDemoDialog';
import { AccountDropdown } from './Navigation/AccountDropdown';
import { CommandBarDialog } from './Navigation/CommandBarDialog';
import { FeedbackDropdown } from './Navigation/FeedbackDropdown';
import { OrgDropdown } from './Navigation/OrgDropdown';
import Workbench from './Operator/Workbench/Workbench';
import { ReactRouterLink } from './ReactRouterLink';

export interface DockPageLayoutProps {
  main?: React.ReactNode;
  sidebar?: React.ReactNode;
}

export function DockPageLayout({ main, sidebar }: DockPageLayoutProps) {
  const [minimized, setMinimized] = useLocalStorage('dashboard.dock.minimized', true);
  const { breakpoint } = useViewport();
  const location = useLocation();
  const companyName = useCurrentCompanyOrDefault();
  const networks = useNetworksForCompany(companyName);
  const { currentController } = useControllerDataFromPathOrNull();
  const currentNetwork = useNetworkOrNull();
  const defaultController = useDefaultController();
  const isOperator = useIsOperator();

  // defaultNetworkUUID is explicitly set, defaultNetwork defaults to a fallback if unset
  const { defaultNetwork, defaultNetworkUUID } = useDefaultNetwork();

  const network = useMemo(() => {
    // current network if present in path
    if (currentNetwork) return currentNetwork;

    // if controller is present in path, that controller's network
    if (currentController) {
      const controllerNameNetwork = networks.find((n) =>
        n.virtualDevices.some((vd) => vd.hardwareDevice?.serialNumber === currentController.name),
      );
      if (controllerNameNetwork) return controllerNameNetwork;
    }

    // if neither present in path, look for last visited config 2 network
    if (defaultNetworkUUID) {
      const defaultNetworkUUIDNetwork = networks.find((n) => n.UUID === defaultNetworkUUID);
      if (defaultNetworkUUIDNetwork) return defaultNetworkUUIDNetwork;
    }

    // look for last visited config 1 network
    if (defaultController) {
      const defaultControllerNetwork = networks.find((n) =>
        n.virtualDevices.some((vd) => vd.hardwareDevice?.serialNumber === defaultController),
      );
      if (defaultControllerNetwork) return defaultControllerNetwork;
    }

    // use fallback network if none other found
    return defaultNetwork;
  }, [
    currentNetwork,
    networks,
    defaultNetwork,
    defaultNetworkUUID,
    currentController,
    defaultController,
  ]);

  const isActiveTest = useIsActiveMatcher();
  const isConnectActive = isActiveTest({
    path: '/org/:companyName/connect',
    end: false,
  });
  const isVPNActive = isActiveTest({
    path: paths.pages.LegacyVPNListPage,
    end: false,
  });
  const isSettingsActive = isActiveTest({
    path: '/org/:companyName/settings',
    end: false,
  });
  const isHubActive = isActiveTest({
    path: '/org/:companyName/hub',
    end: false,
  });
  const isSupportActive = isActiveTest({
    path: '/support',
    end: false,
  });
  const isOperatorPageActive = isActiveTest({
    path: '/operator',
    end: false,
  });
  const isAutoVPNActive = isActiveTest({
    path: paths.pages.AutoVPNGroupsPage,
    end: false,
  });
  const isNetworkActive =
    !isAutoVPNActive &&
    !isConnectActive &&
    !isVPNActive &&
    !isSettingsActive &&
    !isHubActive &&
    !isSupportActive &&
    !isOperatorPageActive;
  const access = useUserProductsAccess();
  const dialogProps = useDialogState();
  const onClickNetworkTab = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (!access.isCustomerOfProduct(ProductTypes.NETWORK)) {
        dialogProps.state.open();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [access, dialogProps.state],
  );
  const getProductIconSize = (value: any) => {
    switch (value) {
      case 'mobile':
        return 28;
      case 'tablet':
        return 22;
      default:
        return 18;
    }
  };

  const controllerName = currentController?.name ?? defaultController;

  let networkLink = controllerName
    ? makeLink(paths.pages.OverviewPage, {
        controllerName,
        companyName,
      })
    : makeLink(paths.pages.CompanyRootPage, { companyName });

  if (network?.slug) {
    networkLink = makeLink(paths.pages.IndividualNetworkRootPage, {
      companyName,
      networkSlug: network.slug,
    });
  }

  const openProductNewsletter = () => {
    window.open('https://www.meter.com/product-newsletter', '_blank');
  };

  const companyHasConfig2COS = useNosFeatureEnabledForCompany(NosFeature.COS2, companyName);

  const { hasPermission } = usePermissions();
  const hasHub =
    isOperator ||
    (companyHasConfig2COS &&
      (hasPermission(PermissionType.PermCompanyGet) ||
        hasPermission(PermissionType.PermNetworkReadLimited)));

  const { isNosFeatureEnabledForCompany: isAutoVpnEnabled = false } =
    useNosFeatureEnabledForCompany(NosFeature.SITE_TO_SITE_VPN, companyName);
  const ldFlags = useFeatureFlags();

  // Can't use IsPermitted HOC for this b/c pages which do not have a company slug in path will break, e.g. Operators
  const hasAutoVpn =
    isAutoVpnEnabled &&
    hasPermission(PermissionType.PermAutoVpnRead) &&
    (isOperator || !!ldFlags['site-to-site-vpn']);

  return (
    <>
      <Dock
        minimized={minimized}
        setMinimized={setMinimized}
        location={location}
        search={<CommandBarDialog />}
        org={<OrgDropdown />}
        account={<AccountDropdown />}
        settings={
          <DockTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.SettingsUserPreferencesPage, { companyName })}
            aria-label="Go to settings"
            label="Settings"
            icon="cog"
            selected={isSettingsActive}
          />
        }
        products={
          <>
            {hasHub && (
              <DockTarget
                as={ReactRouterLink}
                to={makeLink(paths.pages.HubCardsPage, { companyName })}
                aria-label="Go to Meter Hub"
                label="Hub"
                selected={isHubActive}
              >
                <ProductIcon product="hub" size={getProductIconSize(breakpoint)} />
              </DockTarget>
            )}
            {access.hasAccessToProduct(ProductTypes.CONNECT) ? (
              <DockTarget
                as={ReactRouterLink}
                to={makeLink(paths.pages.ConnectDashboardAllLocationsPage, {
                  companyName,
                })}
                aria-label="Go to Meter Connect"
                label="Connect"
                selected={isConnectActive}
              >
                <ProductIcon product="connect" size={getProductIconSize(breakpoint)} />
              </DockTarget>
            ) : null}
            <DockTarget
              onClick={onClickNetworkTab}
              as={ReactRouterLink}
              to={networkLink}
              aria-label="Go to Meter Network"
              label="Network"
              selected={isNetworkActive}
            >
              <ProductIcon product="network" size={getProductIconSize(breakpoint)} />
            </DockTarget>
            {hasAutoVpn && (
              <DockTarget
                as={ReactRouterLink}
                to={makeLink(paths.pages.AutoVPNGroupsPage, {
                  companyName,
                })}
                aria-label="Go to Auto VPN"
                label="Auto VPN"
                selected={isAutoVPNActive}
              >
                <ProductIcon product="auto-vpn" size={getProductIconSize(breakpoint)} />
              </DockTarget>
            )}
            <IsOperator>
              <DockTarget
                as={ReactRouterLink}
                to={makeLink(paths.pages.CompanyListPage, {
                  companyName,
                })}
                aria-label="Go to Meter Operator"
                label="Operator"
                selected={isOperatorPageActive}
              >
                <ProductIcon product="operator" size={getProductIconSize(breakpoint)} />
              </DockTarget>
            </IsOperator>
          </>
        }
        resources={
          <>
            {ldFlags['support-form'] ? (
              <DockTarget
                as={ReactRouterLink}
                to={makeLink(paths.pages.SupportPage, { companyName })}
                aria-label="Open the support form"
                label="Support"
                icon="life-ring"
                selected={isSupportActive}
              />
            ) : (
              <DockTarget
                as="a"
                href="https://meter.com/support"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Open the support form"
                label="Support"
                icon="life-ring"
                selected={isSupportActive}
              />
            )}

            <DockTarget
              aria-label="Open the changelog"
              label="Changelog"
              icon="changelog"
              onClick={openProductNewsletter}
            />
            <FeedbackDropdown />
          </>
        }
        sidebar={sidebar}
      />
      <LayoutPage>{main}</LayoutPage>
      <GetNetworkDemoDialog {...dialogProps} />
      {!isOperatorPageActive && (
        <IsOperator>
          <Workbench />
        </IsOperator>
      )}
    </>
  );
}

import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  PrimaryField,
  SecondaryField,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  space,
  TextInput,
  ToggleInput,
  VStack,
} from '@meterup/atto';
import { isDefinedAndNotEmpty, notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { NosFeatureQueryResult } from './utils';
import { paths } from '../../constants';
import { CreateNosFeatureInputSchema } from '../../gql/zod-types';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { FieldProvider, NumberFieldProvider } from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import {
  createNosFeatureMutation,
  nosFeatureQuery,
  nosFeaturesQuery,
  updateNosFeatureMutation,
} from './utils';

const nosFeatureSchema = CreateNosFeatureInputSchema.extend({
  hasMaximumNosVersion: z.boolean(),
});

type ValidNosFeatureInput = z.input<typeof nosFeatureSchema>;

function MaxNosVersionToggleField() {
  const { values, setFieldValue } = useFormikContext<ValidNosFeatureInput>();

  const handleChange = (selected: Boolean) => {
    setFieldValue('hasMaximumNosVersion', selected);
  };

  return (
    <ToggleInput
      aria-label="Toggle maximum NOS version"
      selected={values.hasMaximumNosVersion === true}
      onChange={handleChange}
    />
  );
}

function NOSFeatureDetailDrawerContents({
  feature,
}: {
  feature?: NosFeatureQueryResult | null | undefined;
}) {
  const closeDrawer = useCloseDrawerCallback();
  const updateMutation = useGraphQLMutation(updateNosFeatureMutation);
  const createMutation = useGraphQLMutation(createNosFeatureMutation);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (inputValues: ValidNosFeatureInput) => {
      const { hasMaximumNosVersion, key, ...input }: ValidNosFeatureInput = inputValues;

      if (!hasMaximumNosVersion) {
        input.maxMajorVersion = null;
        input.maxMinorVersion = null;
        input.maxPatchVersion = null;
      }

      if (feature) {
        updateMutation.mutate(
          { uuid: feature.uuid, input },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(makeQueryKey(nosFeaturesQuery));
              queryClient.invalidateQueries(makeQueryKey(nosFeatureQuery, { uuid: feature.uuid }));
              notify('Successfully updated NOS feature', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              const gqlError = getGraphQLError(err);
              notify(
                `There was a problem updating the NOS feature${gqlError ? `: ${gqlError.message}` : ''}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        createMutation.mutate(
          { input: { key, ...input } },
          {
            onSuccess: (result) => {
              queryClient.invalidateQueries(makeQueryKey(nosFeaturesQuery));
              notify('Successfully created NOS feature', {
                variant: 'positive',
              });
              navigate(
                makeDrawerLink(window.location, paths.drawers.NOSFeatureDetailPage, {
                  uuid: result.createNosFeature.uuid,
                }),
              );
            },
            onError: (err) => {
              const gqlError = getGraphQLError(err);
              notify(
                `There was a problem creating the NOS feature${gqlError ? `: ${gqlError.message}` : ''}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [createMutation, updateMutation, feature, navigate, queryClient],
  );

  return (
    <Drawer>
      <Formik<ValidNosFeatureInput>
        validationSchema={toFormikValidationSchema(nosFeatureSchema)}
        initialValues={{
          key: feature?.key ?? '',
          name: feature?.name ?? '',
          description: feature?.description ?? '',
          minMajorVersion: feature?.minMajorVersion ?? 0,
          minMinorVersion: feature?.minMinorVersion ?? 0,
          minPatchVersion: feature?.minPatchVersion ?? 0,
          maxMajorVersion: feature?.maxMajorVersion,
          maxMinorVersion: feature?.maxMinorVersion,
          maxPatchVersion: feature?.maxPatchVersion,
          hasMaximumNosVersion: isDefinedAndNotEmpty(feature?.maxMajorVersion),
          featureFlag: feature?.featureFlag ?? '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            heading={`${feature ? 'Edit' : 'Add'} NOS feature`}
            icon="flag"
            onClose={closeDrawer}
          />
          <DrawerContent gutter="vertical">
            <Sections>
              <Section relation="stacked">
                <SectionContent gutter="all">
                  <VStack spacing={space(16)}>
                    <FieldContainer>
                      <FieldProvider name="key">
                        <PrimaryField
                          label="Key"
                          element={<TextInput disabled={isDefinedAndNotEmpty(feature)} />}
                        />
                      </FieldProvider>
                    </FieldContainer>
                    <FieldContainer>
                      <FieldProvider name="name">
                        <PrimaryField label="Name" element={<TextInput />} />
                      </FieldProvider>
                    </FieldContainer>
                    <FieldContainer>
                      <FieldProvider name="description">
                        <PrimaryField label="Description" element={<TextInput />} />
                      </FieldProvider>
                    </FieldContainer>
                    <FieldContainer>
                      <FieldProvider name="featureFlag">
                        <PrimaryField
                          label="Feature flag"
                          description="Launch darkly feature flag used to hide this feature in the dashboard"
                          optional
                          element={<TextInput />}
                        />
                      </FieldProvider>
                    </FieldContainer>
                  </VStack>
                </SectionContent>
              </Section>

              <Section relation="stacked">
                <SectionHeader icon="version" heading="Minimum NOS version" />
                <SectionContent gutter="all">
                  <FieldContainer>
                    <NumberFieldProvider name="minMajorVersion" defaultValue={0}>
                      <SecondaryField label="Major" element={<TextInput width="40px" />} />
                    </NumberFieldProvider>
                    <NumberFieldProvider name="minMinorVersion" defaultValue={0}>
                      <SecondaryField label="Minor" element={<TextInput width="40px" />} />
                    </NumberFieldProvider>
                    <NumberFieldProvider name="minPatchVersion" defaultValue={0}>
                      <SecondaryField label="Patch" element={<TextInput width="40px" />} />
                    </NumberFieldProvider>
                  </FieldContainer>
                </SectionContent>
              </Section>

              <Section relation="stacked">
                <SectionHeader
                  icon="version"
                  heading="Maximum NOS version"
                  actions={<MaxNosVersionToggleField />}
                />
                <FormikConditional<ValidNosFeatureInput>
                  condition={(v) => v.hasMaximumNosVersion === true}
                >
                  <SectionContent gutter="all">
                    <FieldContainer>
                      <NumberFieldProvider name="maxMajorVersion" defaultValue={null}>
                        <SecondaryField label="Major" element={<TextInput width="40px" />} />
                      </NumberFieldProvider>
                      <NumberFieldProvider name="maxMinorVersion" defaultValue={null}>
                        <SecondaryField label="Minor" element={<TextInput width="40px" />} />
                      </NumberFieldProvider>
                      <NumberFieldProvider name="maxPatchVersion" defaultValue={null}>
                        <SecondaryField label="Patch" element={<TextInput width="40px" />} />
                      </NumberFieldProvider>
                    </FieldContainer>
                  </SectionContent>
                </FormikConditional>
              </Section>
            </Sections>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={updateMutation.isLoading || createMutation.isLoading}
                  loading={updateMutation.isLoading || createMutation.isLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

function UpdateNOSFeature({ uuid }: { uuid: string }) {
  const feature = useGraphQL(nosFeatureQuery, { uuid }).data?.nosFeature;
  return <NOSFeatureDetailDrawerContents feature={feature} />;
}

export default function NOSFeatureDetailDrawer({ uuid }: { uuid: string }) {
  if (uuid === 'create') {
    return <NOSFeatureDetailDrawerContents />;
  }

  return <UpdateNOSFeature uuid={uuid} />;
}

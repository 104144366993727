import { isDefined } from '@meterup/common';
import { getGraphQLError, useGraphQL } from '@meterup/graphql';
import { indexOf, isEmpty, range, slice } from 'lodash-es';
import { z } from 'zod';

import type {
  DeviceType,
  RackElevationQuery,
  VirtualDevicesForNetworkQuery,
} from '../../../gql/graphql';
import type { RackDeviceManufacturer, RackDeviceType } from './Rack';
import { graphql } from '../../../gql';
import { RackElevationDeviceType } from '../../../gql/graphql';

export const createRackElevationMutation = graphql(`
  mutation CreateRackElevation($networkUUID: UUID!, $label: String!, $rackMountUnitCount: Int!) {
    createRackElevation(
      networkUUID: $networkUUID
      input: { label: $label, rackMountUnitCount: $rackMountUnitCount }
    ) {
      UUID
      networkUUID
      rackMountUnitCount
      label
    }
  }
`);

export const attachDeviceToRackElevationMutation = graphql(`
  mutation AttachDeviceToRackElevation(
    $rackElevationUUID: UUID!
    $rackMountUnitIndexes: [Int!]!
    $virtualDeviceUUID: UUID
    $label: String
    $type: RackElevationDeviceType
  ) {
    attachDeviceToRackElevation(
      rackElevationUUID: $rackElevationUUID
      input: {
        rackMountUnitIndexes: $rackMountUnitIndexes
        virtualDeviceUUID: $virtualDeviceUUID
        label: $label
        type: $type
      }
    ) {
      UUID
      label
      rackMountUnitCount
      devices {
        UUID
        label
        rackMountUnitIndexes
        type
        virtualDeviceUUID
      }
    }
  }
`);

export const detachRackElevationDeviceMutation = graphql(`
  mutation DetachRackElevationDevice($UUID: UUID!) {
    detachDeviceFromRackElevation(UUID: $UUID)
  }
`);

// TODO: Use a shared fragment for these fields
export const rackElevationQuery = graphql(`
  query RackElevation($UUID: UUID!) {
    rackElevation(UUID: $UUID) {
      UUID
      label
      rackMountUnitCount
      networkUUID
      devices {
        UUID
        label
        rackMountUnitIndexes
        type
        virtualDeviceUUID

        virtualDevice {
          UUID
          label
          deviceType
          deviceModel
          description

          hardwareDevice {
            serialNumber
            deviceType
            deviceModel
          }

          ... on SwitchVirtualDevice {
            phyInterfaces {
              portNumber
              isEthernet
              isSFP
            }
          }
        }
      }
      notes {
        rackMountUnitIndexStart
        rackMountUnitIndexEnd
        note
      }
    }
  }
`);

export const rackElevationsQuery = graphql(`
  query RackElevations($networkUUID: UUID!) {
    rackElevations(networkUUID: $networkUUID) {
      UUID
      label
      rackMountUnitCount
      networkUUID
      devices {
        UUID
        label
        rackMountUnitIndexes
        type
        virtualDeviceUUID

        virtualDevice {
          __typename
          UUID
          label
          deviceType
          deviceModel
          description
          networkUUID

          hardwareDevice {
            serialNumber
            deviceType
            deviceModel
            isActive
          }

          ... on SwitchVirtualDevice {
            phyInterfaces {
              portNumber
              isEthernet
              isSFP
            }
          }
        }
      }
      notes {
        rackMountUnitIndexStart
        rackMountUnitIndexEnd
        note
      }
    }
  }
`);

export const updateRackNotesMutation = graphql(`
  mutation UpdateRackNotes($rackElevationUUID: UUID!, $notes: [RackElevationNoteInput!]) {
    updateRackElevation(UUID: $rackElevationUUID, input: { notes: $notes }) {
      UUID
      label
      rackMountUnitCount
      devices {
        UUID
        label
        rackMountUnitIndexes
        type
        virtualDeviceUUID
      }
    }
  }
`);

export type RackElevation = RackElevationQuery['rackElevation'];
export type RackElevationDevice = RackElevation['devices'][number];

export const devicesForNetwork = graphql(`
  query VirtualDevicesForNetwork($networkUUID: UUID!, $filter: DevicesForNetworkFilter) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: $filter) {
      UUID
      networkUUID
      description
      label
      deviceType
      deviceModel

      hardwareDevice {
        serialNumber
        deviceType
        deviceModel
        isActive
      }
    }
  }
`);

export const updateRackElevationDeviceMutation = graphql(`
  mutation UpdateRackElevationDevice($UUID: UUID!, $input: UpdateRackElevationDeviceInput!) {
    updateRackElevationDevice(UUID: $UUID, input: $input) {
      UUID
      label
      rackMountUnitCount
      devices {
        UUID
        label
        rackMountUnitIndexes
        type
        virtualDeviceUUID
      }
    }
  }
`);

export const updateRackElevationMutation = graphql(`
  mutation UpdateRackElevation($UUID: UUID!, $input: UpdateRackElevationInput!) {
    updateRackElevation(UUID: $UUID, input: $input) {
      UUID
      label
      rackMountUnitCount
    }
  }
`);

export const deleteRackElevationMutation = graphql(`
  mutation DeleteRackElevationMutation($UUID: UUID!) {
    deleteRackElevation(UUID: $UUID)
  }
`);

export const fieldTypeSchema = z.enum(['meter_device', 'external_device']);

export type FieldType = z.infer<typeof fieldTypeSchema>;

export const validAttachmentParams = z
  .object({
    startIndex: z.number(),
    endIndex: z.number(),
    fieldType: fieldTypeSchema,
    label: z.string().optional(),
    type: z.string().optional(),
    // `.uuid()` validation doesn't work: https://github.com/colinhacks/zod/issues/2122
    virtualDeviceUUID: z.string().optional(),
  })
  .refine(
    (values) => {
      if (values.fieldType === 'meter_device') {
        return !isEmpty(values.virtualDeviceUUID);
      }

      return true;
    },
    { message: 'Virtual device required for meter devices', path: ['virtualDeviceUUID'] },
  )
  .refine(
    (values) => {
      if (values.fieldType === 'external_device') {
        return !isEmpty(values.type);
      }

      return true;
    },
    {
      message: 'Device type required for external devices',
      path: ['type'],
    },
  );

export type ValidAttachmentParams = z.infer<typeof validAttachmentParams>;

export const validNoteParams = z.object({
  startIndex: z.number(),
  endIndex: z.number(),
  note: z.string().nonempty('Note cannot be empty.'),
});

export type ValidNoteParams = z.infer<typeof validNoteParams>;

/* eslint-disable no-param-reassign */

export const virtualDeviceUUIDToIndexes = (
  elevation: RackElevation,
): { [key: string]: number[] } => {
  const result: { [key: string]: number[] } = {};
  return elevation.devices.reduce((m, device) => {
    if (device.virtualDeviceUUID) {
      m[device.virtualDeviceUUID] = device.rackMountUnitIndexes;
    } else if (isDefined(m.none)) {
      m.none = m.none.concat(...device.rackMountUnitIndexes);
    } else {
      m.none = device.rackMountUnitIndexes;
    }
    return m;
  }, result);
};

/* eslint-enable no-param-reassign */

export const calculateAvailableIndexes = (elevation: RackElevation, deviceIndexes: number[]) => {
  const map = virtualDeviceUUIDToIndexes(elevation);
  const usedIndexes = new Set(
    Object.values(map)
      .flat()
      .filter((i) => !deviceIndexes.includes(i)),
  );
  const availableIndexes = [];

  for (let i = 1; i <= elevation.rackMountUnitCount; i += 1) {
    if (!usedIndexes.has(i)) {
      availableIndexes.push(i);
    }
  }

  return availableIndexes;
};

export const calculateAvailableNoteIndexes = (
  elevation: RackElevation,
  note?: NonNullable<RackElevation['notes']>[number],
): number[] => {
  const availableIndexes: number[] = [];
  const noteIndexes = new Set(
    elevation.notes?.flatMap((n) =>
      n.rackMountUnitIndexStart !== note?.rackMountUnitIndexStart &&
      n.rackMountUnitIndexEnd !== note?.rackMountUnitIndexEnd
        ? range(n.rackMountUnitIndexStart, n.rackMountUnitIndexEnd + 1)
        : [],
    ),
  );

  for (let i = 1; i <= elevation.rackMountUnitCount; i += 1) {
    if (!noteIndexes.has(i)) {
      availableIndexes.push(i);
    }
  }

  return availableIndexes;
};

export const manufacturerForType = (type: RackElevationDeviceType): RackDeviceManufacturer => {
  switch (type) {
    case RackElevationDeviceType.MeterSwitch:
    case RackElevationDeviceType.MeterController:
    case RackElevationDeviceType.MeterAccessPoint:
      return 'meter';
  }

  return '3rd-party';
};

export const getInitialStartIndex = (endIndex: number, deviceIndexes?: number[]) =>
  deviceIndexes && endIndex === deviceIndexes[deviceIndexes.length - 1]
    ? deviceIndexes[0]
    : endIndex;

export const calculateAvailableStartIndexes = ({
  endIndex,
  availableIndexes,
  fieldType,
}: {
  endIndex: number;
  availableIndexes: number[];
  fieldType: FieldType;
}) => {
  if (fieldType === 'meter_device') {
    return [endIndex];
  }
  const endIndexIndex = indexOf(availableIndexes, endIndex);
  if (endIndexIndex === 0) {
    return [endIndex];
  }

  let startIndexIndex = endIndexIndex;
  for (let i = endIndexIndex - 1; i >= 0; i -= 1) {
    if (availableIndexes[i] !== availableIndexes[i + 1] - 1) {
      break;
    }
    startIndexIndex = i;
  }
  return slice(availableIndexes, startIndexIndex, endIndexIndex + 1);
};

export type AvailableDevice = VirtualDevicesForNetworkQuery['virtualDevicesForNetwork'][number];

export interface AvailableDevicesInfo {
  typeDevices: Record<DeviceType, AvailableDevice[]>;
  devicesByUUID: Record<string, AvailableDevice>;
}

export const useAvailableDevices = (
  networkUUID: string,
  currentDeviceUUID?: string,
): AvailableDevicesInfo => {
  const virtualDevices = useGraphQL(devicesForNetwork, { networkUUID }).data;
  const rackElevations = useGraphQL(rackElevationsQuery, { networkUUID }).data;
  const typeDevices: Record<string, AvailableDevice[]> = {};
  const devicesByUUID: Record<string, AvailableDevice> = {};

  if (!virtualDevices) {
    return { typeDevices, devicesByUUID };
  }

  const unavailablevirtualDeviceUUIDs = new Set(
    rackElevations?.rackElevations.flatMap((elevation) =>
      elevation.devices
        .filter((d) => d.virtualDeviceUUID && d.virtualDeviceUUID !== currentDeviceUUID)
        .map((d) => d.virtualDeviceUUID),
    ) ?? [],
  );

  virtualDevices.virtualDevicesForNetwork
    .filter((d) => !unavailablevirtualDeviceUUIDs.has(d.UUID))
    .forEach((vd) => {
      if (!typeDevices[vd.deviceType]) {
        typeDevices[vd.deviceType] = [];
      }

      devicesByUUID[vd.UUID] = vd;
      typeDevices[vd.deviceType].push(vd);
    });

  return { typeDevices, devicesByUUID };
};

export const typeforAtto = (type: RackElevationDeviceType): RackDeviceType => {
  switch (type) {
    case RackElevationDeviceType.MeterController:
      return 'security-appliance';
    case RackElevationDeviceType.MeterSwitch:
    case RackElevationDeviceType.NonMeterSwitch:
      return 'switch';
    case RackElevationDeviceType.MeterAccessPoint:
      return 'access-point';

    case RackElevationDeviceType.CableManagement:
      return 'cable-management';
    case RackElevationDeviceType.Fiber:
      return 'fiber';
    case RackElevationDeviceType.Isp:
      return 'isp';
    case RackElevationDeviceType.PatchPanel:
      return 'patch-panel';
    case RackElevationDeviceType.UniversalPowerSupply:
      return 'ups';
    case RackElevationDeviceType.Unknown:
    default:
      return 'other';
  }
};

export const mutationErrorMessage = (baseMessage: string, error: Error) => {
  const gqlError = getGraphQLError(error);

  return `${baseMessage}${gqlError ? `: ${gqlError.message}` : '. Please try again'}.`;
};

import type { OneToOneNatRulesForNetworkQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';

export const oneToOneNATRulesForNetworkQuery = graphql(`
  query OneToOneNATRulesForNetwork($networkUUID: UUID!) {
    oneToOneNATRulesForNetwork(networkUUID: $networkUUID) {
      UUID
      name
      description
      isEnabled
      networkUUID

      internalIPAddr
      externalIPAddr
      externalPhyInterfaceUUID
      externalPhyInterface {
        ...PhyInterfaceLabelFields
      }
    }
  }
`);

export const oneToOneNATRuleQuery = graphql(`
  query OneToOneNATRule($uuid: UUID!) {
    oneToOneNATRule(UUID: $uuid) {
      UUID
      name
      description
      isEnabled
      networkUUID

      internalIPAddr
      externalIPAddr
      externalPhyInterfaceUUID
      externalPhyInterface {
        ...PhyInterfaceLabelFields
      }
    }
  }
`);

export type OneToOneNATRule = OneToOneNatRulesForNetworkQuery['oneToOneNATRulesForNetwork'][number];

export const createOneToOneNATRuleMutation = graphql(`
  mutation CreateOneToOneNATRule($networkUUID: UUID!, $input: CreateOneToOneNATRuleInput!) {
    createOneToOneNATRule(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateOneToOneNATRuleMutation = graphql(`
  mutation UpdateOneToOneNATRule($uuid: UUID!, $input: UpdateOneToOneNATRuleInput!) {
    updateOneToOneNATRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteOneToOneNATRuleMutation = graphql(`
  mutation DeleteOneToOneNATRule($uuid: UUID!) {
    deleteOneToOneNATRule(UUID: $uuid) {
      UUID
    }
  }
`);

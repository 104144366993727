import type { PortProps } from '@meterup/atto';
import type { SortingState } from '@tanstack/react-table';
import {
  Button,
  ComboBox,
  ComboBoxItem,
  EmptyState,
  HStack,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  Port,
  space,
  styled,
  Switch,
  Tab,
  Visualizer,
} from '@meterup/atto';
import { AutoTable, expectDefinedOrThrow, ResourceNotFoundError, Tooltip } from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash-es';
import { Suspense, useCallback, useEffect, useMemo, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import type { SwitchQueryQuery, VirtualDevice } from '../../../gql/graphql';
import type { PhyInterfaceQueryResult, SwitchVirtualDevice, ValidPortSpeed } from './utils';
import { paths } from '../../../constants';
import { DeviceModel, DeviceType, PermissionType } from '../../../gql/graphql';
import { useBatchEdit } from '../../../hooks/useBatchEdit';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import {
  SwitchControls,
  SwitchPropertiesLegend,
  SwitchSpeedLegend,
} from '../../../routes/pages/network/hardware/switches/SwitchLegends';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useHardwareCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { KeyBoundSearchInput } from '../../KeyBoundSearchInput';
import { NoValue } from '../../NoValue';
import { ObjectStatus } from '../../Object/ObjectStatus';
import IsPermitted from '../../permissions/IsPermitted';
import { useIsPermitted } from '../../permissions/useIsPermitted';
import { StackedSkeletons } from '../../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { createMultiRowSelector } from '../../Table/createMultiRowSelector';
import { useSiblings } from '../utils';
import SwitchActions from './SwitchActions';
import SwitchBootHistory from './SwitchBootHistory';
import SwitchFilters from './SwitchFilters';
import SwitchInsights from './SwitchInsights';
import SwitchMACTableView from './SwitchMACTable';
import {
  fallbackSwitchHardwareDeviceQuery,
  getSwitchStatus,
  isPortBlocking,
  MACTableQuery,
  mergeStatsAndPhyInterfaces,
  portConnectedValue,
  portConnection,
  PortDevice,
  PortName,
  portPowerDraw,
  portSpeedForAtto,
  portSpeedToLabel,
  PortsQuery,
  portStatus,
  portThroughput,
  SwitchesQuery,
  SwitchPortStatsQuery,
  SwitchQuery,
  useOnConnectionClick,
} from './utils';

type SwitchViewProps = {
  uuid: string;
  tab: string;
};

const SwitchPorts = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

const SwitchTableContainer = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const builder = createColumnBuilder<PhyInterfaceQueryResult>();
const checkboxColumn = createMultiRowSelector<PhyInterfaceQueryResult>();

const baseColumns = [
  builder.data((row) => row.portNumber.toString(), {
    id: 'port-number',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status and port number',
      },
    },
    cell: ({ row }) => (
      <Port
        port={row.isSFP ? 'sfp' : 'ethernet'}
        orientation={row.portNumber % 2 === 0 || row.isSFP ? 'down' : 'up'}
        variant="simple"
        number={row.portNumber}
        speed={portSpeedForAtto(row)}
        status={portStatus(row)}
        disabled={!row.isEnabled}
        blocking={isPortBlocking(row)}
      />
    ),
  }),
  builder.data((row) => row.label ?? `Port: ${row.portNumber}`, {
    id: 'port-name',
    header: 'Name',
    meta: {
      minWidth: 44,
      isLeading: true,
    },
    cell: (props) => <PortName port={props.row} />,
  }),
  builder.data(
    (row) => {
      // these strings are only for sorting purposes
      if (row.isSFP) {
        return 'n/a';
      }
      if (row.isPOEEnabled) {
        const draw = portPowerDraw(row);
        return `enabled at ${draw > 0 ? draw.toFixed(2) : '0'}`;
      }
      return 'disabled';
    },
    {
      id: 'port-power-draw',
      header: 'PoE+ (W)',
      cell: (props) => {
        if (props.row.isSFP) {
          return <NoValue />;
        }
        if (props.row.isPOEEnabled) {
          const draw = portPowerDraw(props.row);
          return (
            <HStack spacing={space(8)} align="center" wrap="no-wrap">
              <Icon
                icon="lightning"
                size={12}
                color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
              />
              {draw > 0 && draw.toFixed(2)}
            </HStack>
          );
        }
        return <Icon icon="cross" size={12} />;
      },
    },
  ),
  builder.data(
    // this value is just for sorting purposes
    (row) => (row.portSpeedMbps ? portSpeedToLabel(row.portSpeedMbps as ValidPortSpeed) : '0'),
    {
      id: 'port-reported-speed',
      header: 'Reported speed',
      cell: (props) => {
        if (props.row.portSpeedMbps) {
          return <>{portSpeedToLabel(props.row.portSpeedMbps as ValidPortSpeed)}</>;
        }

        return <NoValue />;
      },
    },
  ),
  builder.data((row) => capitalize(row.stpPortRole ?? ''), {
    id: 'port-stp-role',
    header: 'STP role',
    cell: (props) => {
      if (props.row.stpPortRole && portStatus(props.row) !== 'disconnected') {
        return <span>{capitalize(props.row.stpPortRole)}</span>;
      }

      return <NoValue />;
    },
  }),
  builder.data((row) => portThroughput(row, 'RX'), {
    id: 'port-downloaded',
    header: 'Total RX (GB)',
    meta: {
      condense: true,
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row: port }) => {
      const throughput = portThroughput(port, 'RX');

      if (throughput === 0) {
        return <NoValue />;
      }

      return <>{throughput.toFixed(2)}</>;
    },
  }),
  builder.data((row) => portThroughput(row, 'TX'), {
    id: 'port-uploaded',
    header: 'Total TX (GB)',
    meta: {
      condense: true,
      tooltip: { contents: 'Aggregate over the past 24 hours' },
    },
    cell: ({ row: port }) => {
      const throughput = portThroughput(port, 'TX');

      if (throughput === 0) {
        return <NoValue />;
      }

      return <>{throughput.toFixed(2)}</>;
    },
  }),
  builder.data((row) => (row.isTrunkPort ? 'Trunk' : 'Access'), {
    id: 'port-mode',
    header: 'Mode',
  }),
  builder.data((row) => row.nativeVLAN?.vlanID.toString() ?? 'None', {
    id: 'port-native-vlan',
    header: 'VLAN',
    meta: {
      alignment: 'end',
    },
    cell: ({ row: port }) => {
      if (port.nativeVLAN) {
        return <span>{port.nativeVLAN.vlanID}</span>;
      }

      return <NoValue />;
    },
  }),
  builder.data(
    (row) => (row.isBoundToAllVLANs ? 'All' : (row.allowedVLANs ?? []).length.toString()),
    {
      id: 'port-allowed-vlans',
      header: 'Allowed VLAN IDs',
      meta: { width: 144 },
      cell: ({ row: port }) => {
        if (port.isBoundToAllVLANs) {
          return (
            <Tooltip
              asChild={false}
              showHint
              content="This port is bound to all VLANs. Any VLANs created on the network are allowed on this port."
            >
              <span>All</span>
            </Tooltip>
          );
        }

        const vlanIds = port.allowedVLANs?.map((v) => v.vlanID).sort((a, b) => a - b) ?? [];

        if (vlanIds.length === 0) {
          return <NoValue />;
        }

        if (vlanIds.length > 5) {
          return (
            <Tooltip showHint asChild={false} content={vlanIds.join(', ')}>
              <span>{vlanIds.length} VLANs</span>
            </Tooltip>
          );
        }

        return <span>{vlanIds.join(', ')}</span>;
      },
    },
  ),
];

const searchParamStateKey = 'filter';

function SwitchTabs({
  activeTab,
  setActiveTab,
}: {
  activeTab: string;
  setActiveTab: (val: string) => void;
}) {
  return (
    <>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkMetricsRead) &&
              nosFlags[NosFeature.SOS],
          )
        }
      >
        <Tab
          icon="reporting"
          selected={activeTab === 'insights'}
          onClick={() => setActiveTab('insights')}
        >
          Insights
        </Tab>
      </IsPermitted>

      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermPhyInterfaceRead) &&
              nosFlags[NosFeature.SOS],
          )
        }
      >
        <Tab icon="ethernet" selected={activeTab === 'ports'} onClick={() => setActiveTab('ports')}>
          Ports
        </Tab>
      </IsPermitted>

      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkMetricsRead) &&
              nosFlags[NosFeature.SOS],
          )
        }
      >
        <Tab
          icon="mac-address"
          selected={activeTab === 'mac-table'}
          onClick={() => setActiveTab('mac-table')}
        >
          MAC table
        </Tab>
      </IsPermitted>

      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
              nosFlags[NosFeature.SOS],
          )
        }
      >
        <Tab
          selected={activeTab === 'boot-history'}
          onClick={() => setActiveTab('boot-history')}
          icon="log"
          internal
        >
          Boot history
        </Tab>
      </IsPermitted>
    </>
  );
}

type SwitchDevice = SwitchQueryQuery['virtualDevice'];

function getSwitchSFPRows(virtualDevice: SwitchDevice): 1 | 2 {
  switch (virtualDevice.deviceModel) {
    case DeviceModel.Ms10:
      return 1;
    case DeviceModel.Ms11:
    case DeviceModel.Ms12:
    default:
      return 2;
  }
}

function SwitchPortsView({
  virtualDevice,
  globalFilter,
  sfpRows,
}: {
  virtualDevice: SwitchVirtualDevice;
  globalFilter: string | undefined;
  sfpRows: 1 | 2;
}) {
  const virtualDeviceUUID = virtualDevice.UUID;
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const canUseMultiselect = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions, nosFlags }) =>
      permissions.hasPermission(PermissionType.PermPhyInterfaceWrite) &&
      nosFlags[NosFeature.SOS] &&
      (isOperator || !!ldFlags['sos-ui']),
  });

  const {
    data: portData,
    isRefetching,
    dataUpdatedAt,
  } = useGraphQL(PortsQuery, { virtualDeviceUUID }, { refetchInterval: 30 * 1000 });
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const onRowDeselect = useCloseDrawerCallback();

  const detailParams = Nav.useRegionParams('drawer', paths.drawers.SwitchPortDetailPage, {
    end: false,
  });
  const batchParams = Nav.useRegionParams('drawer', paths.drawers.PortBatchEditPage);
  const legacyAPParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointSummary);
  const apParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointDrawerPage);
  const switchParams = Nav.useRegionParams('drawer', paths.drawers.SwitchSummaryPage);
  const controllerParams = Nav.useRegionParams(
    'drawer',
    paths.drawers.SecurityApplianceSummaryPage,
  );
  const macTable = useGraphQL(MACTableQuery, { virtualDeviceUUID }).data?.switchMACTable;
  const doesSwitchUseStat = useNosFeatureEnabled(NosFeature.SOSUsesStat);

  const portStats = useGraphQL(
    SwitchPortStatsQuery,
    {
      virtualDeviceUUID,
    },
    {
      useErrorBoundary: false,
      enabled: !!virtualDevice.hardwareDevice,
    },
  ).data;

  useMemo(
    () => mergeStatsAndPhyInterfaces(portStats, portData, doesSwitchUseStat),
    [portStats, portData, doesSwitchUseStat],
  );

  const columns = useMemo(() => {
    const cols = [builder.display(checkboxColumn), ...baseColumns];

    // Bit of a hack, add connected column after Name
    // 0. Checkbox column from above
    // 1. port-number
    // 2. port-name
    cols.splice(
      3,
      0,
      builder.data((row) => portConnectedValue({ port: row, macTable }), {
        id: 'port-devices',
        header: 'Connected',
        sortUndefined: 1, // always sort empty values at the end
        meta: {
          minWidth: 44,
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: ({ row: port }) => <PortDevice port={port} macTable={macTable} />,
      }),
    );

    if (!canUseMultiselect) {
      cols.shift();
    }

    return cols;
  }, [canUseMultiselect, macTable]);

  const refetch = () => {
    queryClient.invalidateQueries(makeQueryKey(PortsQuery, { virtualDeviceUUID }));
    queryClient.invalidateQueries(makeQueryKey(SwitchPortStatsQuery, { virtualDeviceUUID }));
  };

  const checkConnectionIsActive = useCallback(
    (port: PhyInterfaceQueryResult) => {
      if (port.connectedDevices && port.connectedDevices.length > 0) {
        const { hardwareDevice } = port.connectedDevices[0];
        return Boolean(
          (switchParams && switchParams.uuid === hardwareDevice?.virtualDevice?.UUID) ||
            (legacyAPParams && legacyAPParams.deviceName === hardwareDevice?.serialNumber) ||
            (apParams && apParams.uuid === hardwareDevice?.virtualDevice?.UUID) ||
            (controllerParams && controllerParams.uuid === hardwareDevice?.virtualDevice?.UUID),
        );
      }

      return false;
    },
    [switchParams, legacyAPParams, apParams, controllerParams],
  );

  const portDrawerLink = useCallback(
    (phyInterfaceUUID: string) =>
      makeDrawerLink(window.location, paths.drawers.SwitchPortDetailPage, {
        phyInterfaceUUID,
        virtualDeviceUUID,
        networkSlug: network.slug,
        companyName,
      }),
    [companyName, network.slug, virtualDeviceUUID],
  );

  const handleConnectionOnClick = useOnConnectionClick();

  const portProps: PortProps[] = useMemo(
    () =>
      portData?.phyInterfacesForVirtualDevice?.map((p) => ({
        number: p.portNumber,
        poe: portPowerDraw(p) > 0,
        speed: portSpeedForAtto(p),
        port: p.isSFP ? 'sfp' : 'ethernet',
        connection:
          portConnection(p, true, handleConnectionOnClick, checkConnectionIsActive) ?? undefined,
        status: portStatus(p),
        uplink: p.isUplink,
        onClick: () => navigate(portDrawerLink(p.UUID)),
        active: detailParams?.phyInterfaceUUID === p.UUID,
        disabled: !p.isEnabled,
        blocking: isPortBlocking(p),
      })) ?? [],
    [
      portData,
      navigate,
      portDrawerLink,
      detailParams?.phyInterfaceUUID,
      handleConnectionOnClick,
      checkConnectionIsActive,
    ],
  );

  const { multiSelectedRows, onRowMultiSelectionChange } = useBatchEdit({
    initialState: batchParams?.phyInterfaceUUIDs.split(','),
    onChange: (rows) => {
      const ids = Object.keys(rows);

      if (!ids.length) {
        onRowDeselect();
      } else {
        navigate(
          makeDrawerLink(window.location, paths.drawers.PortBatchEditPage, {
            phyInterfaceUUIDs: ids.join(','),
            virtualDeviceUUID,
            networkSlug: network.slug,
            companyName,
          }),
        );
      }
    },
  });

  // clear batch selection if drawer is closed
  useEffect(() => {
    if (batchParams === null) {
      onRowMultiSelectionChange({});
    }
  }, [batchParams, onRowMultiSelectionChange]);

  return (
    <SwitchPorts>
      <Visualizer
        state={
          <SwitchControls
            refetch={refetch}
            refreshDisabled={isRefetching}
            updatedAt={dataUpdatedAt}
          />
        }
        visuals={<Switch ports={portProps} sfpRows={sfpRows} />}
        status={<SwitchSpeedLegend />}
        properties={<SwitchPropertiesLegend />}
      />
      <SwitchTableContainer>
        <AutoTable
          size="small"
          columns={columns}
          sortingState={sortingState}
          onChangeSortingState={setSortingState}
          data={portData?.phyInterfacesForVirtualDevice ?? []}
          onRowDeselect={onRowDeselect}
          isRowSelected={(phyInterface) => phyInterface.UUID === detailParams?.phyInterfaceUUID}
          getLinkTo={(phyInterface) => portDrawerLink(phyInterface.UUID)}
          globalFilter={globalFilter}
          enableMultiRowSelection={canUseMultiselect}
          multiSelectedRows={canUseMultiselect ? multiSelectedRows : undefined}
          onRowMultiSelectionChange={canUseMultiselect ? onRowMultiSelectionChange : undefined}
          getRowId={(r) => r.UUID}
        />
      </SwitchTableContainer>
    </SwitchPorts>
  );
}

export default function SwitchDetailView({ uuid, tab }: SwitchViewProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const hardwareCrumb = useHardwareCrumbs();
  const closeDrawer = useCloseDrawerCallback();
  const virtualDevice = useGraphQL(SwitchQuery, { uuid }, { useErrorBoundary: false }).data
    ?.virtualDevice;
  const hardwareDeviceResponse = useGraphQL(
    fallbackSwitchHardwareDeviceQuery,
    { serialNumber: uuid },
    { useErrorBoundary: false, enabled: !virtualDevice },
  );

  const sortedSiblings = useSiblings({
    query: SwitchesQuery,
    deviceType: DeviceType.Switch,
  });

  const navigateToTab = useCallback(
    (newTab: string) => {
      if (newTab === 'insights') closeDrawer();
      navigate(
        makeLink(paths.pages.SwitchDetailPage, {
          companyName,
          networkSlug: network.slug,
          uuid,
          tab: newTab,
        }),
      );
    },
    [closeDrawer, navigate, companyName, network.slug, uuid],
  );

  const navigateToSwitch = useCallback(
    (newUUID: string) => {
      navigate(
        makeLink(paths.pages.SwitchDetailPage, {
          companyName,
          networkSlug: network.slug,
          uuid: newUUID,
          tab,
        }),
      );
    },
    [navigate, companyName, network.slug, tab],
  );

  const [globalFilter] = useSearchParamsState<string>(searchParamStateKey, '');
  const tableContainerRef = useRef(null);

  if (!virtualDevice && hardwareDeviceResponse.isLoading) {
    // This won't be visible for more than an instant until the suspense boundary kicks in
    // (if at all)
    return null;
  }

  const hardwareDevice = hardwareDeviceResponse?.data?.hardwareDevice;

  // Redirect legacy serial-based URL to virtual device UUID URL
  if (!virtualDevice && hardwareDevice?.virtualDeviceUUID) {
    return (
      <Navigate
        to={makeLink(paths.pages.SwitchDetailPage, {
          companyName,
          networkSlug: network.slug,
          uuid: hardwareDevice.virtualDeviceUUID,
          tab,
        })}
      />
    );
  }

  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Switch not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('Switch not found on this network');
  }

  const status = getSwitchStatus(virtualDevice);

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...hardwareCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.SwitchListPage, {
                companyName,
                networkSlug: network.slug,
                tab: 'list',
              }),
              label: 'Switches',
            },
          },
        ]}
        switcher={
          <ComboBox
            maxWidth="100%"
            icon="switch"
            value={virtualDevice.UUID}
            onValueChange={navigateToSwitch}
            size="small"
          >
            {sortedSiblings.map((s: VirtualDevice) => (
              <ComboBoxItem key={s.UUID} textValue={s.label}>
                {s.label}
              </ComboBoxItem>
            ))}
          </ComboBox>
        }
        icon="switch"
        heading={virtualDevice.label}
        badges={<ObjectStatus size="small" status={status} />}
        tabs={<SwitchTabs activeTab={tab} setActiveTab={navigateToTab} />}
        contentActions={
          <>
            {virtualDevice.hardwareDevice ? (
              <SwitchActions view="detail" virtualDevice={virtualDevice} />
            ) : undefined}
            {['ports', 'mac-table'].includes(tab) && (
              <KeyBoundSearchInput
                searchParamStateKey={searchParamStateKey}
                placeholder="..."
                aria-label="Filter table"
                scope="scoped"
                minWidth="56px"
              />
            )}
          </>
        }
        views={
          tab === 'insights' &&
          virtualDevice.hardwareDevice && (
            <SwitchFilters
              serialNumber={virtualDevice.hardwareDevice?.serialNumber}
              uuid={virtualDevice.UUID}
            />
          )
        }
      />
      <Suspense
        fallback={
          <PaneContent gutter="all">
            <StackedSkeletons />
          </PaneContent>
        }
      >
        <PaneContent ref={tableContainerRef}>
          {tab === 'insights' && (
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermNetworkMetricsRead) &&
                    nosFlags[NosFeature.SOS],
                )
              }
              should404OnAccessDenied
            >
              {virtualDevice?.hardwareDevice ? (
                <SwitchInsights serialNumber={virtualDevice.hardwareDevice.serialNumber} />
              ) : (
                <EmptyState
                  icon="reporting"
                  heading="No insights available"
                  action={
                    <Button
                      icon="arrow-right"
                      onClick={() => {
                        navigateToTab('ports');
                      }}
                    >
                      View ports
                    </Button>
                  }
                />
              )}
            </IsPermitted>
          )}
          {tab === 'ports' && (
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermPhyInterfaceRead) &&
                    nosFlags[NosFeature.SOS],
                )
              }
              should404OnAccessDenied
            >
              <SwitchPortsView
                virtualDevice={virtualDevice}
                globalFilter={globalFilter}
                sfpRows={getSwitchSFPRows(virtualDevice)}
              />
            </IsPermitted>
          )}
          {tab === 'mac-table' && (
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermNetworkMetricsRead) &&
                    nosFlags[NosFeature.SOS],
                )
              }
              should404OnAccessDenied
            >
              <SwitchMACTableView
                virtualDeviceUUID={uuid}
                globalFilter={globalFilter}
                tableContainerRef={tableContainerRef}
              />
            </IsPermitted>
          )}
          {tab === 'boot-history' &&
            (virtualDevice.hardwareDevice ? (
              <IsPermitted
                isPermitted={({ permissions, nosFlags }) =>
                  Boolean(
                    permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
                      nosFlags[NosFeature.SOS],
                  )
                }
                should404OnAccessDenied
              >
                <SwitchBootHistory
                  serialNumber={virtualDevice.hardwareDevice.serialNumber}
                  globalFilter={globalFilter}
                />
              </IsPermitted>
            ) : (
              <EmptyState heading="No data available" />
            ))}
        </PaneContent>
      </Suspense>
    </Pane>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import DNSSecurityRuleDrawer from '../../../../../components/DNSSecurity/DNSSecurityRuleDrawer';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/dns-security/create',
  title: 'Add rule - DNS security - Firewall',
});

export default function DNSSecurityRuleCreatePage() {
  const featureFlags = useFeatureFlags();
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator && !featureFlags['content-filtering-ui'])
    throw new ResourceNotFoundError('Page not found');

  return <DNSSecurityRuleDrawer />;
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import EditPortForwardingRuleDrawer, {
  portForwardingRuleQuery,
} from '../../../../../components/Firewall/PortForwarding/EditPortForwardingRuleDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall-rules/port-forwarding/:ruleUUID',
  title: 'Edit rule - Port forwarding - Firewall',
});

export default function EditPortForwardingRulePage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditPortForwardingRulePage);
  const rule = checkDefinedOrThrow(
    useGraphQL(portForwardingRuleQuery, {
      uuid: drawerParams?.ruleUUID!,
    })?.data?.portForwardingRule,
    new ResourceNotFoundError('Port forwarding rule not found'),
  );
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPortForwardWrite) &&
            ldFlags['port-forwarding'],
        )
      }
      should404OnAccessDenied
    >
      <EditPortForwardingRuleDrawer rule={rule} />
    </IsPermitted>
  );
}

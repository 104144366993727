import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import * as d3 from 'd3';
import { Suspense, useMemo } from 'react';

import { graphql } from '../../gql';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import {
  apChannelUtilizationMetricsToSeries,
  ChartsFallbackContainer,
  getDurationSeconds,
  getStep,
  percentValueFormatter,
} from '../../utils/chart_utils';
import ChartFilter from '../ChartFilter';
import TimeSeriesChart from '../Charts/TimeSeriesChart';
import { StackedSkeletons } from '../Placeholders/AppLoadingFallback';

const APChannelUtilizationQuery = graphql(`
  query APChannelUtilization($networkUUID: UUID!, $filter: MetricsFilterInput!) {
    band2G: apChannelUtilization(networkUUID: $networkUUID, filter: $filter, band: BAND_2_4G) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        ... on APChannelUtilizationMetricsValue {
          apName
        }
      }
    }
    band5G: apChannelUtilization(networkUUID: $networkUUID, filter: $filter, band: BAND_5G) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        ... on APChannelUtilizationMetricsValue {
          apName
        }
      }
    }
  }
`);

function RFMetricGraphs({
  networkUUID,
  duration,
  step,
  currentTimePeriod,
}: {
  networkUUID: string;
  duration: number;
  step: number;
  currentTimePeriod: string;
}) {
  const { data: metricsData } = useGraphQL(APChannelUtilizationQuery, {
    networkUUID,
    filter: {
      durationSeconds: duration,
      stepSeconds: step,
    },
  });

  expectDefinedOrThrow(metricsData, new ResourceNotFoundError('Unable to load metrics'));

  const series2G = useMemo(
    () => apChannelUtilizationMetricsToSeries(metricsData?.band2G?.values),
    [metricsData?.band2G?.values],
  );

  const series5G = useMemo(
    () => apChannelUtilizationMetricsToSeries(metricsData?.band5G?.values),
    [metricsData?.band5G?.values],
  );

  return (
    <>
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series2G}
        seriesType="line"
        seriesLabel="access-point-channel-utilization-2-4-ghz"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="(Per AP) Total channel utilization for 2.4 GHz Band"
        tooltipBody="Measures how much of the network bandwidth is being used for an access point."
        valueFormatter={percentValueFormatter}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series5G}
        seriesType="line"
        seriesLabel="access-point-channel-utilization-5-ghz"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="(Per AP) Total channel utilization for 5 GHz Band"
        tooltipBody="Measures how much of the network bandwidth is being used for an access point."
        valueFormatter={percentValueFormatter}
      />
    </>
  );
}

function RFInsights() {
  const network = useNetwork();
  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const queryClient = useQueryClient();

  const duration = useMemo(() => getDurationSeconds(currentTimePeriod), [currentTimePeriod]);
  const step = useMemo(() => getStep(currentTimePeriod), [currentTimePeriod]);

  const refetchMetrics = () => {
    queryClient.invalidateQueries(
      makeQueryKey(APChannelUtilizationQuery, {
        networkUUID: network.UUID,
        filter: {
          durationSeconds: duration,
          stepSeconds: step,
        },
      }),
    );
  };

  return (
    <>
      <ChartFilter
        currentTimePeriod={currentTimePeriod}
        setCurrentTimePeriod={setCurrentTimePeriod}
        refetchMetrics={refetchMetrics}
      />
      <Suspense
        fallback={
          <ChartsFallbackContainer>
            <StackedSkeletons />
          </ChartsFallbackContainer>
        }
      >
        <RFMetricGraphs
          networkUUID={network.UUID}
          duration={duration}
          step={step}
          currentTimePeriod={currentTimePeriod}
        />
      </Suspense>
    </>
  );
}

export default RFInsights;

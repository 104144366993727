export const exceedsLimit = 'exceeds-limit';

type AnchorInfo = {
  parentAnchor: HTMLAnchorElement | null;
  levelsUp: number | typeof exceedsLimit;
};

export function getParentAnchor(element: HTMLElement, maxLevels = 10): AnchorInfo {
  let parent = element.parentNode;
  let levels = 0;
  let anchor: HTMLAnchorElement | null = null;

  while (parent !== null && levels < maxLevels) {
    if (parent instanceof HTMLAnchorElement) {
      anchor = parent;
      break;
    }
    parent = parent.parentNode;
    levels += 1;
  }

  return { parentAnchor: anchor, levelsUp: levels === maxLevels ? exceedsLimit : levels };
}

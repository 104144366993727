import { Button } from '@meterup/atto';
import { IsOperator } from '@meterup/authorization';
import React from 'react';

import type { PathAndSearch } from '../../utils/main_and_drawer_navigation';
import { paths } from '../../constants';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { ReactRouterLink } from '../ReactRouterLink';

type BulkAddLocationsButtonProps = {
  companySID: string;
  location?: PathAndSearch;
};

export function BulkAddLocationsButton({ companySID, location }: BulkAddLocationsButtonProps) {
  const loc = location ?? window.location;
  return (
    <IsOperator>
      <Button
        icon="plus"
        variant="secondary"
        arrangement="leading-icon"
        type="button"
        as={ReactRouterLink}
        to={makeDrawerLink(loc, paths.drawers.BulkAddLocationsPage, {
          companyName: companySID,
        })}
        condense
        size="small"
        internal
      >
        Bulk add
      </Button>
    </IsOperator>
  );
}

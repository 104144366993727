import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { notify } from '@meterup/common';
import { MeterV1NetworkNATPortForwardProtocol } from '@meterup/config';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ValidPortForwardingRule } from './form_data';
import { DiscardChangesButton, DrawerFormSaveButton } from '../../../../../components/form_buttons';
// import { FormikConditional } from '../../../../../components/FormikConditional';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useToggle } from '../../../../../hooks/useToggle';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { validPortForwardingRule } from './form_data';
import { FormFields } from './form_fields';
import { useUpsertPortForwardingRuleMutation } from './mutations';
import { NATPortForwardingConfirmChanges } from './NATPortForwardingConfirmChanges';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/port-forwarding/create',
});

export default function NATPortForwardingRuleCreatePage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const configModel = useControllerConfig(controllerName);
  const navigate = useNavigate();
  const upsertMutation = useUpsertPortForwardingRuleMutation(controllerName, configModel);
  const isConfirmingChanges = useToggle();
  const closeDrawer = useCloseDrawerCallback();

  return (
    <Formik<ValidPortForwardingRule>
      validate={withZodSchema(validPortForwardingRule)}
      initialValues={{
        description: '',
        externalPort: 80,
        internalIPAddress: '',
        internalPort: 80,
        protocol: MeterV1NetworkNATPortForwardProtocol.TCP,
      }}
      onSubmit={(values) => {
        if (isConfirmingChanges.value) {
          upsertMutation.mutate(values, {
            onSuccess: (rule) => {
              notify('Saved', { variant: 'positive' });
              navigate(
                makeDrawerLink(window.location, paths.drawers.NATPortForwardingRuleEditPage, {
                  companyName,
                  controllerName,
                  ruleId: rule.stableId,
                }),
              );
            },
            onError: () => {
              notify('Failed to save changes', { variant: 'negative' });
            },
          });
        } else {
          isConfirmingChanges.toggleOn();
        }
      }}
      enableReinitialize
    >
      {isConfirmingChanges.value ? (
        <NATPortForwardingConfirmChanges onCancel={isConfirmingChanges.toggleOff} />
      ) : (
        <Drawer>
          <StyledForm>
            <DrawerHeader
              icon="port-forward"
              heading="Add port forwarding rule"
              onClose={closeDrawer}
            />
            <DrawerContent>
              <FormFields />
            </DrawerContent>
            {/* <FormikConditional<ValidPortForwardingRule> condition={(values, ctx) => ctx.dirty}> */}
            <DrawerFooter
              actions={
                <>
                  <DiscardChangesButton />
                  <DrawerFormSaveButton />
                </>
              }
            />
            {/* </FormikConditional> */}
          </StyledForm>
        </Drawer>
      )}
    </Formik>
  );
}

import React from 'react';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import RadioProfiles from '../../../../../components/Wireless/RadioProfiles/RadioProfiles';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/radio-profiles',
  layout: 'VirtualNetworkLayout',
  title: 'Radio profiles - Wireless',
});

export default function RadioProfilesPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        permissions.hasPermission(PermissionType.PermRadioProfileRead)
      }
      should404OnAccessDenied
    >
      <RadioProfiles />
    </IsPermitted>
  );
}

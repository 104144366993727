import {
  Address4,
  BigInteger,
  getFirstUsableAddress,
  getLastUsableAddress,
  safeParseAddress4,
} from '@meterup/common';
import { z } from 'zod';

import { DURATION_24H_SECONDS, MAX_VLAN_ID, NUM_RESERVED_IPS_IN_VLAN } from '../../../constants';
import { graphql } from '../../../gql';
import { type VlanQueryQuery, ClientAssignmentProtocol } from '../../../gql/graphql';
import { CreateDhcpRuleInputSchema, CreateVlanInputSchema } from '../../../gql/zod-types';

export const DEFAULT_DHCP_LEASE_DURATION_SECONDS = DURATION_24H_SECONDS;

export const vlansQuery = graphql(`
  query VLANsQuery($networkUUID: UUID!) {
    vlans(networkUUID: $networkUUID) {
      __typename
      UUID
      name
      description
      isEnabled
      isInternal
      isDefault
      vlanID
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength
    }
  }
`);

export const vlanQuery = graphql(`
  query VLANQuery($uuid: UUID!) {
    vlan(UUID: $uuid) {
      __typename
      UUID
      name
      description
      isEnabled
      isInternal
      isDefault
      vlanID
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength

      dhcpRule {
        UUID
        isIPv6
        startIPAddress
        endIPAddress
        gatewayIPAddress
        gatewayPrefixLength
        leaseDurationSeconds
        dnsUseGatewayProxy
        dnsUpstreamServers
        dnsSearchDomains
        dnsCacheIsEnabled
        dnsCacheSize
        dnsCacheMaxTTL

        options {
          UUID
          code
          data
          description
          createdAt
        }

        reservedRanges {
          UUID
          startIPAddress
          endIPAddress
          createdAt
        }

        staticMappings {
          UUID
          name
          macAddress
          ipAddress
          hostname
          createdAt
        }

        dnsHostMappings {
          UUID
          isEnabled
          overrideDomain
          destinationIPAddress
          destinationDomain
          createdAt
        }

        applicationDNSFirewallRules {
          UUID
        }
      }
    }
  }
`);

export const createVLANMutation = graphql(`
  mutation CreateVLANMutation($networkUUID: UUID!, $input: CreateVLANInput!) {
    createVLAN(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateVLANMutation = graphql(`
  mutation UpdateVLANMutation($uuid: UUID!, $input: UpdateVLANInput!) {
    updateVLAN(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteVLANMutation = graphql(`
  mutation DeleteVLANMutation($uuid: UUID!) {
    deleteVLAN(UUID: $uuid)
  }
`);

export const createDHCPRuleMutation = graphql(`
  mutation CreateDHCPRule($vlanUUID: UUID!, $input: CreateDHCPRuleInput!) {
    createDHCPRule(vlanUUID: $vlanUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDHCPRuleMutation = graphql(`
  mutation UpdateDHCPRule($uuid: UUID!, $input: UpdateDHCPRuleInput!) {
    updateDHCPRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPRuleMutation = graphql(`
  mutation DeleteDHCPRule($uuid: UUID!) {
    deleteDHCPRule(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDHCPOption = graphql(`
  mutation CreateDHCPOption($ruleUUID: UUID!, $input: CreateDHCPOptionInput!) {
    createDHCPOption(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDHCPOption = graphql(`
  mutation UpdateDHCPOption($uuid: UUID!, $input: UpdateDHCPOptionInput!) {
    updateDHCPOption(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPOption = graphql(`
  mutation DeleteDHCPOption($uuid: UUID!) {
    deleteDHCPOption(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDHCPReservedRange = graphql(`
  mutation CreateDHCPReservedRange($ruleUUID: UUID!, $input: CreateDHCPReservedRangeInput!) {
    createDHCPReservedRange(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDHCPReservedRange = graphql(`
  mutation UpdateDHCPReservedRange($uuid: UUID!, $input: UpdateDHCPReservedRangeInput!) {
    updateDHCPReservedRange(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPReservedRange = graphql(`
  mutation DeleteDHCPReservedRange($uuid: UUID!) {
    deleteDHCPReservedRange(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDHCPStaticMapping = graphql(`
  mutation CreateDHCPStaticMapping($ruleUUID: UUID!, $input: CreateDHCPStaticMappingInput!) {
    createDHCPStaticMapping(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDHCPStaticMapping = graphql(`
  mutation UpdateDHCPStaticMapping($uuid: UUID!, $input: UpdateDHCPStaticMappingInput!) {
    updateDHCPStaticMapping(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPStaticMapping = graphql(`
  mutation DeleteDHCPStaticMapping($uuid: UUID!) {
    deleteDHCPStaticMapping(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDNSHostMapping = graphql(`
  mutation CreateDNSHostMapping($ruleUUID: UUID!, $input: CreateDNSHostMappingInput!) {
    createDNSHostMapping(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDNSHostMapping = graphql(`
  mutation UpdateDNSHostMapping($uuid: UUID!, $input: UpdateDNSHostMappingInput!) {
    updateDNSHostMapping(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDNSHostMapping = graphql(`
  mutation DeleteDNSHostMapping($uuid: UUID!) {
    deleteDNSHostMapping(UUID: $uuid) {
      UUID
    }
  }
`);

export const copyVLANsMutation = graphql(`
  mutation CopyVLANsMutation($networkUUID: UUID!) {
    copyVLANsFromConfig1ToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

export const copyDNSHostMappingsMutation = graphql(`
  mutation CopyDNSHostMappingsFromConfig1($networkUUID: UUID!) {
    copyDNSHostMappingsFromConfig1ToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

export const leaseDurationHoursSchema = z
  .number({ invalid_type_error: 'Please provide a number.' })
  .min(1, { message: 'Please provide a lease duration of at least 1 hour.' });

export const dnsCacheSizeSchema = z
  .number()
  .min(1024, { message: 'DNS cache size must be at least 1024.' });

export const dnsSearchDomainsSchema = z
  .array(
    z
      .string()
      .nonempty({ message: 'Please ensure domains are valid and remove any trailing commas.' }),
  )
  .optional();

export const dnsUpstreamServersSchema = z
  .array(
    z.string().ip({
      message: 'Please ensure all IP addresses are valid and remove any trailing commas.',
    }),
  )
  .nonempty({ message: 'Please provide at least one upstream DNS server.' });

export const vlanFormSchema = CreateVlanInputSchema.omit({
  isMulticastReflectionEnabled: true,
})
  .extend({
    name: z.string().nonempty({ message: 'Please provide a name.' }),
    ipV4ClientAssignmentProtocol: z
      .nativeEnum(ClientAssignmentProtocol)
      .nullish()
      .or(z.literal('')),
    vlanID: z
      .number()
      .min(1, { message: `VLAN ID must be between 1 and ${MAX_VLAN_ID}.` })
      .max(MAX_VLAN_ID, { message: `VLAN ID must be between 1 and ${MAX_VLAN_ID}.` }),
    isEnabled: z.boolean(),
    isInternal: z.boolean(),
    ipV4ClientGateway: z
      .string()
      .ip({ message: 'Gateway must be a valid IP address.' })
      .nullish()
      .or(z.literal('')),
    ipV4ClientPrefixLength: z
      .number()
      .min(1, { message: 'Prefix length must be 1 or greater.' })
      .nullish()
      .or(z.literal('')),

    dhcpRule: CreateDhcpRuleInputSchema.omit({
      gatewayIPAddress: true,
      gatewayPrefixLength: true,
      leaseDurationSeconds: true,
    }).extend({
      dhcpIsEnabled: z.boolean(),
      leaseDurationHours: leaseDurationHoursSchema,
      dnsSearchDomains: dnsSearchDomainsSchema,
      dnsUpstreamServers: dnsUpstreamServersSchema,
      dnsCacheSize: dnsCacheSizeSchema,
    }),
  })
  .superRefine(
    (
      { dhcpRule, ipV4ClientAssignmentProtocol, ipV4ClientGateway, ipV4ClientPrefixLength },
      ctx,
    ) => {
      if (
        dhcpRule.dhcpIsEnabled &&
        ipV4ClientAssignmentProtocol !== ClientAssignmentProtocol.Static
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'DHCP configuration requires static VLAN IP.',
          path: ['ipV4ClientGateway'],
        });
      }

      if (ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static) {
        if (!ipV4ClientGateway && ipV4ClientPrefixLength != null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide an IP address.',
            path: ['ipV4ClientGateway'],
          });
        } else if (ipV4ClientPrefixLength == null && !!ipV4ClientPrefixLength) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a subnet prefix length.',
            path: ['ipV4ClientPrefixLength'],
          });
        }
      }

      if (
        dhcpRule.dhcpIsEnabled &&
        ipV4ClientGateway &&
        ipV4ClientPrefixLength != null &&
        dhcpRule.startIPAddress &&
        dhcpRule.endIPAddress
      ) {
        const subnet = safeParseAddress4(`${ipV4ClientGateway}/${ipV4ClientPrefixLength}`);
        const gatewayIP = safeParseAddress4(ipV4ClientGateway);
        const startIP = safeParseAddress4(dhcpRule.startIPAddress);
        const endIP = safeParseAddress4(dhcpRule.endIPAddress);

        if (!gatewayIP) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a valid IP address.',
            path: ['ipV4ClientGateway'],
          });
        }

        if (!startIP) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a valid DHCP start IP address.',
            path: ['dhcpRule.startIPAddress'],
          });
        } else if (subnet && !startIP.isInSubnet(subnet)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'DHCP start IP address must be within subnet.',
            path: ['dhcpRule.startIPAddress'],
          });
        }

        if (!endIP) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a valid DHCP end IP address.',
            path: ['dhcpRule.endIPAddress'],
          });
        } else if (subnet && !endIP.isInSubnet(subnet)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'DHCP end IP address must be within subnet.',
            path: ['dhcpRule.endIPAddress'],
          });
        }
      }
    },
  );

export function deriveDefaultDHCPIPRangeFromSubnet(
  ipAddress: string,
  prefix: number,
): [string, string] {
  const addr = new Address4(`${ipAddress}/${prefix}`);
  const firstUsable = getFirstUsableAddress(addr);
  const startAddress = Address4.fromBigInteger(
    firstUsable.bigInteger().add(new BigInteger((NUM_RESERVED_IPS_IN_VLAN + 1).toString())),
  );
  const endAddress = getLastUsableAddress(addr);

  return [startAddress.address, endAddress.address];
}

export type VLANUUID = VlanQueryQuery['vlan']['UUID'];
export type VLAN = VlanQueryQuery['vlan'];
export type VLANWithStaticSubnet = VLAN & {
  ipV4ClientAssignmentProtocol: ClientAssignmentProtocol.Static;
  ipV4ClientGateway: string;
  ipV4ClientPrefixLength: number;
};
export type DHCPRule = NonNullable<VLAN['dhcpRule']>;
export type VLANWithDHCPRule = VLANWithStaticSubnet & { dhcpRule: DHCPRule };
export type DHCPOption = DHCPRule['options'][number];
export type DHCPStaticMapping = DHCPRule['staticMappings'][number];
export type DHCPReservedRange = DHCPRule['reservedRanges'][number];
export type DNSHostMapping = DHCPRule['dnsHostMappings'][number];

export function vlanHasStaticIP<
  V extends Pick<
    VLAN,
    'ipV4ClientAssignmentProtocol' | 'ipV4ClientGateway' | 'ipV4ClientPrefixLength'
  >,
>(
  vlan: V,
): vlan is V & {
  ipV4ClientAssignmentProtocol: ClientAssignmentProtocol.Static;
  ipV4ClientGateway: string;
  ipV4ClientPrefixLength: number;
} {
  return (
    vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static &&
    !!vlan.ipV4ClientGateway &&
    vlan.ipV4ClientPrefixLength != null
  );
}

export function vlanHasDHCPRule<
  V extends Pick<
    VLAN,
    'dhcpRule' | 'ipV4ClientAssignmentProtocol' | 'ipV4ClientGateway' | 'ipV4ClientPrefixLength'
  >,
>(
  vlan: V,
): vlan is V & {
  dhcpRule: DHCPRule;
  ipV4ClientAssignmentProtocol: ClientAssignmentProtocol.Static;
  ipV4ClientGateway: string;
  ipV4ClientPrefixLength: number;
} {
  return vlanHasStaticIP(vlan) && !!vlan.dhcpRule;
}

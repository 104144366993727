import { useCallback } from 'react';

import { Optional } from '@meterup/connect-api';
import camelCaseKeys from 'camelcase-keys';

import usePlacesServices from '../components/hooks/usePlacesServices';
import { GeocoderResult, GeolocationMap } from '../types/gmaps';
import { FetchLocationItem } from './useFetchLocationPredictions';

export type PredictionFormatterResult = {
  latLng: FetchLocationItem['latLng'];
  components: GeolocationMap;
  entry: GeocoderResult;
};

export default function usePredictionFormatter() {
  const { getServicesPromise } = usePlacesServices();
  return useCallback(
    async (prediction: FetchLocationItem): Promise<Optional<PredictionFormatterResult>> => {
      const services = await getServicesPromise();
      if (!services) {
        return undefined;
      }

      const { geocoderService } = services;
      const geocoded = await geocoderService.geocode({ address: prediction.description });
      const geocodedEntries = Array.from(geocoded.results.entries()).map((entry) => entry[1]);
      if (geocodedEntries.length < 1) {
        return undefined;
      }

      const [entry] = geocodedEntries;

      const mappedComponents = entry.address_components.reduce(
        (memo: GeolocationMap, component) => ({
          ...memo,
          [component.types[0]]: component,
        }),
        {} as GeolocationMap,
      );

      return {
        latLng: prediction.latLng,
        components: camelCaseKeys(mappedComponents as GeolocationMap, { deep: true }),
        entry: entry as GeocoderResult,
      };
    },
    [getServicesPromise],
  );
}

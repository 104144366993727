import { Box, Column, Columns, Skeleton, space, VStack } from '@meterup/atto';
import IsOperator from '@meterup/authorization/src/components/IsOperator';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense } from 'react';

import { graphql } from '../../../gql';
import { BootHistoryWidget } from '../../Devices/Insights';
import { DetailsWidget } from './insights';

const BOOT_INFO_COUNT = 5;

type InsightsProps = {
  serialNumber: string;
};

const PDUInsightsQuery = graphql(`
  query PDUInsightsQuery($serialNumber: String!, $count: Int) {
    hardwareDevice(serialNumber: $serialNumber) {
      __typename
      serialNumber
      macAddress
      deviceModel
      tunnelIPAddress
      bootHistory(count: $count) {
        bootCount
        buildName
        rebootReason
        createdAt
      }
      isConnectedToBackend
      disconnectedFromBackendAt
      virtualDevice {
        __typename
        UUID
        label
        description
        networkUUID
        nosVersion {
          id
          version
          buildName
        }
        pendingNosVersion {
          nosVersion {
            version
          }
          scheduledAt
        }
        ... on PowerDistributionUnitVirtualDevice {
          ipAddress
        }
      }
    }
  }
`);

function PowerDistributionUnitInsights({ serialNumber }: InsightsProps) {
  const PowerDistributionUnitData = useGraphQL(PDUInsightsQuery, {
    serialNumber,
    count: BOOT_INFO_COUNT,
  }).data;
  expectDefinedOrThrow(
    PowerDistributionUnitData,
    new ResourceNotFoundError('power distribution unit not found'),
  );

  return (
    <Columns template="object">
      <Column>
        <DetailsWidget
          hardwareDevice={PowerDistributionUnitData.hardwareDevice}
          virtualDevice={PowerDistributionUnitData.hardwareDevice.virtualDevice}
          relation="stacked"
        />
      </Column>
      <IsOperator>
        <Column>
          <Suspense
            fallback={
              <Box padding={{ x: space(16) }}>
                <VStack spacing={space(12)}>
                  <Skeleton height={200} width="100%" radius={6} />
                  <Skeleton height={200} width="100%" radius={6} />
                  <Skeleton height={200} width="100%" radius={6} />
                </VStack>
              </Box>
            }
          >
            <BootHistoryWidget bootHistory={PowerDistributionUnitData.hardwareDevice.bootHistory} />
          </Suspense>
        </Column>
      </IsOperator>
    </Columns>
  );
}

export default PowerDistributionUnitInsights;

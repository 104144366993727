import type { AxiosAPIError, QuotesRequest } from '@meterup/connect-api';
import { get } from '@meterup/connect-api/src/axios';
import { useQuery } from '@tanstack/react-query';

export default function useQuotesRequest(quoteRequestSid: string) {
  return useQuery<unknown, AxiosAPIError, QuotesRequest>(
    ['quoteRequest', quoteRequestSid],
    () => get(`v1/connect/quote-requests/${quoteRequestSid}/quotes`),
    {
      enabled: !!quoteRequestSid,
    },
  );
}

import type { ControlSize } from '@meterup/atto/src/controls/shared/types';
import type { SortingState } from '@meterup/common';
import { Badge, Button, EmptyState, Tooltip } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { formatTimestamp } from '../Devices/utils';
import IsPermitted from '../permissions/IsPermitted';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { type ClientVPNClient, ClientTab, isClientActive } from './utils';

export function AddClientButton({ serverUUID, size }: { serverUUID: string; size?: ControlSize }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Button
      as={Link}
      to={makeDrawerLink(window.location, paths.drawers.ClientVPNClientCreatePage, {
        companyName,
        networkSlug: network.slug,
        serverUUID,
      })}
      icon="plus"
      arrangement="leading-icon"
      variant="secondary"
      size={size}
    >
      Add VPN client
    </Button>
  );
}

const builder = createColumnBuilder<ClientVPNClient>();

function ClientStatusCell({ row, value }: { row: ClientVPNClient; value: string }) {
  const isActive = isClientActive(row);

  return (
    <Tooltip
      contents={
        row.connectionInfo?.observedAt
          ? `Last observed at ${formatTimestamp(row.connectionInfo?.observedAt)}.`
          : 'Client not yet seen.'
      }
    >
      <Badge
        arrangement="hidden-label"
        ends="pill"
        icon={isActive ? 'checkmark' : 'cross'}
        size="small"
        variant={isActive ? 'positive' : 'neutral'}
      >
        {value}
      </Badge>
    </Tooltip>
  );
}

function RowDateTime({ value }: { value: string }) {
  return <>{formatTimestamp(value)}</>;
}

const columns = [
  builder.data((row) => (isClientActive(row) ? 'Active' : 'Inactive'), {
    id: 'status',
    header: 'Status',
    cell: ClientStatusCell,
  }),
  builder.data((row) => row.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.userEmail, {
    id: 'user-email',
    header: 'User',
  }),
  builder.data((row) => row.ipAddress, {
    id: 'ip-address',
    header: 'IP',
  }),
  builder.data((row) => row.createdAt, {
    id: 'created-at',
    header: 'Created',
    cell: RowDateTime,
  }),
];

export default function VPNClientsList({
  serverUUID,
  clients,
  isNested,
}: {
  serverUUID: string;
  clients: ClientVPNClient[];
  isNested?: boolean;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const closeDrawer = useCloseDrawerCallback();

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  if (!clients?.length) {
    return (
      <EmptyState
        icon="vpn"
        heading="No clients found"
        action={
          <IsPermitted permissions={PermissionType.PermClientVpnClientWrite}>
            <AddClientButton serverUUID={serverUUID} />
          </IsPermitted>
        }
      />
    );
  }

  return (
    <AutoTable
      columns={columns}
      data={clients}
      globalFilter={globalFilter}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      enableSorting
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.ClientVPNClientPage, {
          companyName,
          networkSlug: network.slug,
          serverUUID,
          clientUUID: row.UUID,
          tab: ClientTab.Details,
        })
      }
      onRowDeselect={closeDrawer}
      isNested={isNested}
    />
  );
}

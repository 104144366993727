import { checkDefinedOrThrow } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { DetailPageLayout } from '../../../../../../components/DetailPageLayout';
import { paths } from '../../../../../../constants';
import { Nav } from '../../../../../../nav';
import { useAPIClient } from '../../../../../../providers/APIClientProvider';
import { useCurrentControllerData } from '../../../../../../providers/CurrentControllerProvider';
import { ClientsList } from '../../../insights/clients/ClientsList';
import ClientsListHeader from '../../../insights/clients/ClientsListHeader';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/devices/:deviceName',
});

export default function LegacyAccessPointDetailPage() {
  const { deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.LegacyAccessPointDetailPage),
  );

  const controller = useCurrentControllerData();
  const api = useAPIClient();

  const { data: ap } = useQuery(
    ['device_with_radio', controller.name, deviceName],
    () => api.accessPoint(deviceName),
    {
      suspense: true,
    },
  );

  return (
    <DetailPageLayout
      header={
        <ClientsListHeader
          networkClientOptions={{
            filter: {
              apSerialNumber: ap?.serial_number,
            },
          }}
        />
      }
      main={
        <ClientsList
          networkClientOptions={{
            filter: {
              apSerialNumber: ap?.serial_number,
            },
          }}
        />
      }
    />
  );
}

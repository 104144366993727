import type { metrics } from '@meterup/proto';
import { bitsPerSecond, formatDataRateBits } from '@meterup/common';
import * as d3 from 'd3';

import type { TimeSeriesDataValue } from '../../../api/types';
import type {
  NetworkUplinkQualityQuery,
  NetworkUplinkThroughputQuery,
  UplinkPhyInterfacesForNetworkQuery,
} from '../../../gql/graphql';

export type UplinkThroughputProps = {
  data: NetworkUplinkThroughputQuery | undefined | null;
  isError: boolean;
  currentUplinkPhyInteface?: string;
  uplinkName: string;
};

export type UplinkQualityProps = {
  data: NetworkUplinkQualityQuery | undefined | null;
  isError: boolean;
  currentUplinkPhyInteface?: string;
  uplinkName: string;
};

export type Uplink = UplinkPhyInterfacesForNetworkQuery['uplinkPhyInterfacesForNetwork'][number];

export function dataRateValueFormatter(value: number): string {
  return formatDataRateBits(value, bitsPerSecond);
}
export const percentValueFormatter = d3.format('.2~%');
export function timePeriodLabel(period: string): string {
  switch (period) {
    case '1h':
      return 'Last hour';
    case '6h':
      return 'Last 6 hours';
    case '7d':
      return 'Last 7 days';
    case '30d':
      return 'Last 30 days';
    case '3m':
      return 'Last 3 months';
    case '6m':
      return 'Last 6 months';
    case '9m':
      return 'Last 9 months';
    case '1y':
      return 'Last year';
    case '24h':
    default:
      return 'Last 24 hours';
  }
}

export function getDurationSeconds(timePeriod: string) {
  const min = 60;
  const hour = 60 * min;
  const day = 24 * hour;
  const month = 30 * day;

  switch (timePeriod) {
    case '1h':
      return 1 * hour;
    case '6h':
      return 6 * hour;
    case '7d':
      return 7 * day;
    case '30d':
      return 30 * day;
    case '3m':
      return 3 * month;
    case '6m':
      return 6 * month;
    case '9m':
      return 9 * month;
    case '1y':
      return 365 * day;
    case '24h':
    default:
      return 24 * hour;
  }
}

export function getDurationHours(timePeriod: string): number {
  const hour = 1;
  const day = 24 * hour;

  switch (timePeriod) {
    case '1h':
      return 1;
    case '6h':
      return 6;
    case '7d':
      return 7 * day;
    case '30d':
      return 30 * day;
    case '24h':
    default:
      return 24;
  }
}
export function calculateRange(values: TimeSeriesDataValue[]): metrics.Metric2Range {
  const vals = values.map((data) => data.value);

  return {
    min: Math.min(...vals),
    max: Math.max(...vals),
  };
}

export function getUplinkDevices(uplinksList: Uplink[]) {
  const map = new Map<string, [Uplink, ...Uplink[]]>();
  if (!uplinksList) return [];

  for (const uplink of uplinksList) {
    const arr = map.get(uplink.virtualDevice.UUID);
    if (arr) {
      arr.push(uplink);
    } else {
      map.set(uplink.virtualDevice.UUID, [uplink]);
    }
  }

  for (const arr of map.values()) {
    arr.sort((a, b) => a.portNumber - b.portNumber);
  }

  return Array.from(map.entries()).sort(([, [a]], [, [b]]) =>
    a.virtualDevice.label.localeCompare(b.virtualDevice.label),
  );
}

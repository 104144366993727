import { ControlGroup, styled } from '@meterup/atto';

export const RuleControls = styled(ControlGroup, {
  position: 'sticky',
  left: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$12',
  padding: '0 $12',
  marginLeft: '-12px',
});

import type { api } from '@meterup/proto';
import {
  Body,
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { formatBytes, isDefinedAndNotEmpty, Tooltip } from '@meterup/common';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';

import { timestampToISO } from '../utils/timestamp';

export function VPNClientDetailWidget({ vpnClient }: { vpnClient: api.VPNClient }) {
  return (
    <Section relation="standalone">
      <SectionHeader icon="information" heading="Details" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>IP address</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy VPN client IP address"
                relation="stacked"
                size="small"
                value={vpnClient.ip_address?.toString() ?? ''}
              >
                <Body family="monospace">{vpnClient.ip_address}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Public key</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy VPN client public key"
                relation="stacked"
                size="small"
                value={vpnClient.public_key?.toString() ?? ''}
              >
                <Body family="monospace">{vpnClient.public_key}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          {isDefinedAndNotEmpty(vpnClient.created_at) && (
            <SummaryListRow>
              <SummaryListKey>Created at</SummaryListKey>
              <SummaryListValue>
                {DateTime.fromISO(vpnClient.created_at).toLocaleString(DateTime.DATETIME_MED)}
              </SummaryListValue>
            </SummaryListRow>
          )}
          {isDefinedAndNotEmpty(vpnClient.created_by) && (
            <SummaryListRow>
              <SummaryListKey>Created by</SummaryListKey>
              <SummaryListValue>
                <Body family="monospace" wordBreak="break-all">
                  {vpnClient.created_by}
                </Body>
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function VPNClientConfigWidget({ wgConfig }: { wgConfig: string }) {
  return (
    <Section relation="standalone">
      <SectionHeader icon="wrench" heading="WireGuard config" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy your WireGuard config"
                relation="standalone"
                size="large"
                value={wgConfig}
              >
                <Body family="monospace">{wgConfig}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function VPNClientLogsWidget({ peers, logs }: { peers: any; logs: any }) {
  const observedAt = get(peers, ['observed_at']);
  const lastHandshake = get(logs, ['last_handshake_time']);
  return (
    <Section relation="standalone">
      <SectionHeader icon="reporting" heading="Session" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>Rx bytes</SummaryListKey>
            <SummaryListValue>
              <Body family="monospace" wordBreak="break-all">
                {formatBytes(logs.rx_bytes)}
              </Body>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Tx bytes</SummaryListKey>
            <SummaryListValue>
              <SummaryListValue>
                <Body family="monospace" wordBreak="break-all">
                  {formatBytes(logs.tx_bytes)}
                </Body>
              </SummaryListValue>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Last handshake time</SummaryListKey>
            <SummaryListValue>
              {lastHandshake !== 0 &&
                DateTime.fromISO(timestampToISO(lastHandshake)).toLocaleString(
                  DateTime.DATETIME_MED,
                )}
              {lastHandshake === 0 && (
                <Tooltip content="This peer has not completed a handshake since the VPN service was last restarted.">
                  Not since last restart
                </Tooltip>
              )}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Observed at</SummaryListKey>
            <SummaryListValue>
              {observedAt !== 0 &&
                DateTime.fromISO(observedAt).toLocaleString(DateTime.DATETIME_MED)}
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

import type { PaneHeaderProps } from '@meterup/atto';
import { Badge, PaneHeader } from '@meterup/atto';
import React from 'react';

type DetailHeaderPropStatus = 'draft' | 'online' | 'offline' | 'upgrading' | 'wired' | 'unknown';
type DetailHeaderPropType =
  | 'access-point'
  | 'client'
  | 'isp'
  | 'power-distribution-unit'
  | 'security-appliance'
  | 'switch'
  | 'vlan';

export type DetailHeaderProps = PaneHeaderProps & {
  status?: DetailHeaderPropStatus;
  type?: DetailHeaderPropType;
};

const getTypeName = (type: DetailHeaderPropType) => {
  switch (type) {
    case 'access-point':
      return 'Access point';
    case 'client':
      return 'Client';
    case 'isp':
      return 'ISP';
    case 'power-distribution-unit':
      return 'Power distribution unit';
    case 'security-appliance':
      return 'Security appliance';
    case 'switch':
      return 'Switch';
    case 'vlan':
      return 'VLAN';
    default:
      return 'Unknown';
  }
};

const getDetailHeaderStatusVariant = (status: DetailHeaderPropStatus) => {
  switch (status) {
    case 'online':
      return 'positive';
    default:
      return 'neutral';
  }
};

const getDetailHeaderStatusLabel = (status: DetailHeaderPropStatus) => {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'online':
      return 'Online';
    case 'offline':
      return 'Offline';
    case 'upgrading':
      return 'Upgrading';
    case 'wired':
      return 'Wired';
    default:
      return 'Unknown';
  }
};

export function DetailHeader({ status, type, ...remaining }: DetailHeaderProps) {
  return (
    <PaneHeader
      badges={
        <>
          {type && (
            <Badge variant="neutral" size="small" ends="card">
              {getTypeName(type)}
            </Badge>
          )}
          {status && (
            <Badge variant={getDetailHeaderStatusVariant(status)} size="small" ends="pill">
              {getDetailHeaderStatusLabel(status)}
            </Badge>
          )}
        </>
      }
      {...remaining}
    />
  );
}

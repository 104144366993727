import { Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { StickyObjectHeader } from '../../Object/ObjectHeader';
import { DetailsWidget } from './insights';
import PowerDistributionUnitActions from './PowerDistributionUnitActions';
import { PowerDistributionUnitQuery } from './utils';

type PowerDistributionUnitSummaryProps = {
  uuid: string;
};

function PowerDistributionUnitSummary({ uuid }: PowerDistributionUnitSummaryProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const virtualDevice = useGraphQL(PowerDistributionUnitQuery, { uuid }).data?.virtualDevice;
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('PowerDistributionUnit not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('PowerDistributionUnit not found on this network');
  }

  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'PowerDistributionUnitHardwareDevice')
    return null;
  if (virtualDevice.__typename !== 'PowerDistributionUnitVirtualDevice') return null;

  return (
    <DrawerContent gutter="none">
      <StickyObjectHeader
        icon="pdu"
        name={virtualDevice.label}
        status={hardwareDevice?.isConnectedToBackend ? 'online' : 'offline'}
        link={makeLink(paths.pages.PowerDistributionUnitDetailPage, {
          networkSlug: network.slug,
          companyName,
          uuid,
          tab: 'outlets',
        })}
        cta="View power distribution unit"
      />
      <DetailsWidget
        hardwareDevice={hardwareDevice}
        virtualDevice={virtualDevice}
        relation="stacked"
      />
    </DrawerContent>
  );
}

export default function PowerDistributionUnitDrawer({ uuid }: PowerDistributionUnitSummaryProps) {
  const virtualDevice = useGraphQL(PowerDistributionUnitQuery, { uuid }).data?.virtualDevice;

  return (
    <Drawer>
      <DrawerHeader
        icon="pdu"
        heading="Power distribution unit"
        onClose={useCloseDrawerCallback()}
        actions={
          <PowerDistributionUnitActions
            serialNumber={
              virtualDevice && virtualDevice.hardwareDevice
                ? virtualDevice.hardwareDevice.serialNumber
                : ''
            }
            uuid={uuid}
            view="drawer"
          />
        }
      />
      <PowerDistributionUnitSummary uuid={uuid} />
    </Drawer>
  );
}

import { useGraphQL } from '@meterup/graphql';
import { Suspense } from 'react';

import RackElevationsNoteDrawer from '../../../../../components/Design/RackElevations/RackElevationsNoteDrawer';
import { rackElevationQuery } from '../../../../../components/Design/RackElevations/utils';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { DrawerLoadingFallback } from '../../../../../components/Placeholders/DrawerLoadingFallback';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/notes/:noteIndexStart',
  title: 'Edit note - Rack - Rack elevations - Design',
});

export default function EditRackNotePage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditRackNotePage);
  const rackElevation = useGraphQL(rackElevationQuery, { UUID: drawerParams?.elevationUUID! }).data
    ?.rackElevation;

  const note = drawerParams?.noteIndexStart
    ? rackElevation?.notes?.find(
        (n) => n.rackMountUnitIndexStart === Number.parseInt(drawerParams?.noteIndexStart, 10),
      )
    : undefined;

  const closeDrawer = useCloseDrawerCallback();

  if (!rackElevation || !note) return null;

  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermRackElevationWrite) &&
        (isOperator || !!ldFlags['rack-elevation'])
      }
      should404OnAccessDenied
      allowOperatorAlways={false}
    >
      <Suspense fallback={<DrawerLoadingFallback />}>
        <RackElevationsNoteDrawer elevation={rackElevation} note={note} stopEditing={closeDrawer} />
      </Suspense>
    </IsPermitted>
  );
}

import {
  Box,
  Dock,
  HStack,
  Layout,
  LayoutPage,
  Pane,
  PaneContent,
  PaneHeader,
  Sidebar,
  SidebarGroup,
  SidebarGroups,
  Skeleton,
  space,
  styled,
  VStack,
} from '@meterup/atto';
import { useLocalStorage } from '@meterup/common';

export function StackedSkeletons({ count = 3, height = 120 }: { count?: number; height?: number }) {
  return (
    <VStack spacing={space(16)}>
      {Array(count)
        .fill(null)
        .map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={i} height={height} width="100%" radius={6} />
        ))}
    </VStack>
  );
}

const SkeletonDockTarget = styled(Skeleton, {
  '@mobile': {
    width: '100%',
    height: '24px',
    borderRadius: '10px',
  },

  '@tablet': {
    width: '100%',
    height: '28px',
    margin: '4px 0',
    borderRadius: '10px',
  },

  '@desktop': {
    width: '100%',
    height: '20px',
    margin: '4px 0',
    borderRadius: '$8',
  },
});

const SkeletonSearchSkeletons = styled('div', {
  '@tablet': {
    padding: '$4 0',
  },

  '@desktop': {
    padding: '$6 0 $4',
  },
});

const SkeletonProductsSkeletons = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@tablet': {
    gap: '$8',
  },

  '@desktop': {
    gap: '$4',
  },
});

const SkeletonDockReposition = styled('div', {
  '@mobile': {
    position: 'absolute',
    inset: '0 $16 0 $56',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
});

const SkeletonSidebarTarget = styled(Skeleton, {
  width: '100%',

  '@notDesktop': {
    height: '$28',
    borderRadius: '10px',
  },

  '@desktop': {
    height: '$20',
    borderRadius: '$8',
  },
});

export function PaneContentSkeletons() {
  return (
    <VStack align="center" spacing={space(16)}>
      <Skeleton height="40px" width="100%" radius={8} />
      <Skeleton height="40px" width="100%" radius={8} />
      <Skeleton height="40px" width="100%" radius={8} />
    </VStack>
  );
}

export function PaneContentFallback() {
  return (
    <PaneContent>
      <PaneContentSkeletons />
    </PaneContent>
  );
}

export function AppLoadingFallback() {
  const [minimized, setMinimized] = useLocalStorage('dashboard.dock.minimized', true);
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Layout>
        <Dock
          minimized={minimized}
          setMinimized={setMinimized}
          search={
            <SkeletonSearchSkeletons>
              <SkeletonDockTarget />
            </SkeletonSearchSkeletons>
          }
          account={
            <SkeletonDockReposition>
              <SkeletonDockTarget />
            </SkeletonDockReposition>
          }
          settings={<SkeletonDockTarget />}
          products={
            <SkeletonProductsSkeletons>
              <SkeletonDockTarget />
              <SkeletonDockTarget />
              <SkeletonDockTarget />
              <SkeletonDockTarget />
            </SkeletonProductsSkeletons>
          }
          resources={
            <>
              <SkeletonDockTarget />
              <SkeletonDockTarget />
            </>
          }
          sidebar={
            <Sidebar>
              <SidebarGroups>
                <SidebarGroup>
                  <Box padding={{ right: space(8), bottom: space(8), left: space(4) }}>
                    <VStack align="center" spacing={space(16)}>
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                    </VStack>
                  </Box>
                </SidebarGroup>
                <SidebarGroup>
                  <Box padding={{ all: space(8) }}>
                    <VStack align="center" spacing={space(16)}>
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                    </VStack>
                  </Box>
                </SidebarGroup>
                <SidebarGroup>
                  <Box padding={{ all: space(8) }}>
                    <VStack align="center" spacing={space(16)}>
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                    </VStack>
                  </Box>
                </SidebarGroup>
              </SidebarGroups>
            </Sidebar>
          }
        />
        <LayoutPage>
          <Pane>
            <PaneHeader
              heading={
                <HStack align="center" spacing={space(6)}>
                  <Skeleton height="20px" width="20px" radius={6} />
                  <Skeleton height="20px" width={200} radius={8} />
                </HStack>
              }
            />
            <PaneContent gutter="all">
              <PaneContentSkeletons />
            </PaneContent>
          </Pane>
        </LayoutPage>
      </Layout>
    </div>
  );
}

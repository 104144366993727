import type { OverlayTriggerState } from '@meterup/atto';
import { Dialog, DialogContent, DialogHeader } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { uplinkPhyInterfacesQuery } from '../../Firewall/utils';
import WANPingTest from '../../PingTest2/WANPingTest';
import { type Uplink } from './utils';

export default function PingTestDialog({ state }: { state: OverlayTriggerState }) {
  const network = useNetwork();

  const allUplinks = useGraphQL(uplinkPhyInterfacesQuery, {
    networkUUID: network.UUID,
  })?.data?.uplinkPhyInterfacesForNetwork;

  function getUplinkDevices(uplinksList: Uplink[]) {
    const map = new Map<string, [Uplink, ...Uplink[]]>();
    if (!uplinksList) return [];

    for (const uplink of uplinksList) {
      const arr = map.get(uplink.virtualDevice.UUID);
      if (arr) {
        arr.push(uplink);
      } else {
        map.set(uplink.virtualDevice.UUID, [uplink]);
      }
    }

    for (const arr of map.values()) {
      arr.sort((a, b) => a.portNumber - b.portNumber);
    }

    return Array.from(map.entries()).sort(([, [a]], [, [b]]) =>
      a.virtualDevice.UUID.localeCompare(b.virtualDevice.label),
    );
  }

  const allUplinkDevices = useMemo(() => getUplinkDevices(allUplinks ?? []), [allUplinks]);

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="ping" heading="Ping test" />
      <DialogContent gutter="all">
        <WANPingTest uplinkDevices={allUplinkDevices} />
      </DialogContent>
    </Dialog>
  );
}

import {
  Alert,
  Button,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import IsOperator from '@meterup/authorization/src/components/IsOperator';
import { useGraphQL } from '@meterup/graphql';

import type { AccessPointQueryQuery } from '../../gql/graphql';
import { Box } from '../Box';
import { ConnectedSwitchSection } from '../Connections';
import { DetailsWidgetListPair } from '../Devices/Insights';
import { formatTimestamp } from '../Devices/utils';
import { DeadMansSnitchQuery } from './utils';

type AccessPointDetailsHardwareDevice = AccessPointQueryQuery['virtualDevice']['hardwareDevice'];
type AccessPointDetailsVirtualDevice = AccessPointQueryQuery['virtualDevice'];

export function InternalDetailsWidget({
  hardwareDevice,
  virtualDevice,
}: {
  hardwareDevice: AccessPointDetailsHardwareDevice;
  virtualDevice: AccessPointDetailsVirtualDevice;
}) {
  const serialNumber = hardwareDevice?.serialNumber || '';
  const deadMansToken = useGraphQL(
    DeadMansSnitchQuery,
    { serialNumber },
    { enabled: !!serialNumber, useErrorBoundary: false },
  ).data?.deviceHeartbeatForDevice?.token;
  if (hardwareDevice && hardwareDevice.__typename !== 'AccessPointHardwareDevice') return null;
  if (!virtualDevice || virtualDevice.__typename !== 'AccessPointVirtualDevice') return null;

  const lastBootHistoryBuildName = hardwareDevice?.bootHistory?.[0]?.buildName;

  return (
    <IsOperator>
      <Section relation="stacked">
        <SectionHeader icon="information" heading="Metadata" />
        <SectionContent gutter="horizontal">
          <SummaryList gutter="none">
            <DetailsWidgetListPair label="Virtual device" value={virtualDevice.UUID} />
            {hardwareDevice && (
              <>
                <DetailsWidgetListPair label="Serial number" value={hardwareDevice.serialNumber} />
                <DetailsWidgetListPair
                  label="WG tunnel IP"
                  value={hardwareDevice.tunnelIPAddress}
                />
                {!hardwareDevice.isConnectedToBackend &&
                  hardwareDevice.disconnectedFromBackendAt && (
                    <DetailsWidgetListPair
                      label="Offline since"
                      value={formatTimestamp(hardwareDevice.disconnectedFromBackendAt)}
                    />
                  )}
                <DetailsWidgetListPair
                  label="NOS version"
                  value={virtualDevice.nosVersion?.version}
                />
                <DetailsWidgetListPair
                  label="NOS build name"
                  value={virtualDevice.nosVersion?.buildName}
                />
                {virtualDevice?.pendingNosVersion && (
                  <Box css={{ marginLeft: -6, marginRight: -6, marginTop: -2, marginBottom: -2 }}>
                    <Alert
                      copy={`Pending upgrade to ${
                        virtualDevice?.pendingNosVersion?.nosVersion?.version
                      } on ${formatTimestamp(virtualDevice?.pendingNosVersion?.scheduledAt)}`}
                      icon="information"
                      variant="neutral"
                      internal
                    />
                  </Box>
                )}
                <DetailsWidgetListPair
                  label="Booted build name"
                  value={lastBootHistoryBuildName ?? 'Unknown'}
                />
                {lastBootHistoryBuildName &&
                  virtualDevice?.nosVersion?.id !== 2 &&
                  virtualDevice?.nosVersion?.buildName !== lastBootHistoryBuildName && (
                    <Box css={{ marginLeft: -6, marginRight: -6, marginTop: -2, marginBottom: -2 }}>
                      <Alert
                        copy={`NOS version ${virtualDevice?.nosVersion?.version} expected build ${virtualDevice?.nosVersion?.buildName} but device is running ${lastBootHistoryBuildName ?? 'Unknown'}`}
                        icon="warning"
                        variant="negative"
                        internal
                      />
                    </Box>
                  )}
                {deadMansToken && (
                  <SummaryListRow>
                    <SummaryListKey>DeadMansSnitch</SummaryListKey>
                    <SummaryListValue>
                      <Button
                        as="a"
                        href={`https://deadmanssnitch.com/snitches/${deadMansToken}`}
                        target="_blank"
                        variant="secondary"
                        size="small"
                        arrangement="leading-label"
                      >
                        View
                      </Button>
                    </SummaryListValue>
                  </SummaryListRow>
                )}
              </>
            )}
          </SummaryList>
        </SectionContent>
      </Section>
    </IsOperator>
  );
}

export function DetailsWidget({
  hardwareDevice,
  virtualDevice,
  relation,
}: {
  hardwareDevice: AccessPointDetailsHardwareDevice;
  virtualDevice: AccessPointDetailsVirtualDevice;
  relation: 'stacked' | 'standalone';
}) {
  if (hardwareDevice && hardwareDevice.__typename !== 'AccessPointHardwareDevice') return null;
  if (!virtualDevice || virtualDevice.__typename !== 'AccessPointVirtualDevice') return null;

  return (
    <Sections>
      <Section relation={relation}>
        <SectionHeader heading="Metadata" />
        <SectionContent gutter="all">
          <SummaryList gutter="none">
            <DetailsWidgetListPair label="Model" value={virtualDevice.deviceModel} />
            {hardwareDevice && (
              <>
                <DetailsWidgetListPair label="MAC address" value={hardwareDevice.macAddress} />
                <DetailsWidgetListPair label="DHCP IP address" value={hardwareDevice.ipAddress} />
              </>
            )}
          </SummaryList>
        </SectionContent>
      </Section>

      {hardwareDevice?.macAddress && (
        <ConnectedSwitchSection
          relation={relation}
          lookupMethod="lldp"
          macAddress={hardwareDevice.macAddress}
        />
      )}

      {virtualDevice.radioProfile?.band2_4GIsEnabled && (
        <Section relation={relation}>
          <SectionHeader heading="2.4 GHz" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <DetailsWidgetListPair
                label="Channel width"
                value={`${virtualDevice.radioProfile.band2_4GChannelWidthMHz} MHz`}
              />
              <DetailsWidgetListPair
                label="Primary channel"
                value={virtualDevice.radioSettings.band2_4GPrimaryChannel.toString()}
              />
              <DetailsWidgetListPair
                label="Transmit power"
                value={`${virtualDevice.radioSettings.band2_4GTransmitPowerdBm} dBm`}
              />
            </SummaryList>
          </SectionContent>
        </Section>
      )}

      {virtualDevice.radioProfile?.band5GIsEnabled && (
        <Section relation={relation}>
          <SectionHeader heading="5 GHz" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <DetailsWidgetListPair
                label="Channel width"
                value={`${virtualDevice.radioProfile.band5GChannelWidthMHz} MHz`}
              />
              <DetailsWidgetListPair
                label="Primary channel"
                value={virtualDevice.radioSettings.band5GPrimaryChannel.toString()}
              />
              <DetailsWidgetListPair
                label="Transmit power"
                value={`${virtualDevice.radioSettings.band5GTransmitPowerdBm} dBm`}
              />
            </SummaryList>
          </SectionContent>
        </Section>
      )}
    </Sections>
  );
}

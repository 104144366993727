import AccessPointsLayout from '../../../../../../components/Hardware/AccessPoints/AccessPoints';
import AccessPointsBSSIDs from '../../../../../../components/Hardware/AccessPoints/AccessPointsBSSIDs';
import { ValidAPListTabs } from '../../../../../../components/Wireless/utils';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/bssids',
  layout: 'VirtualNetworkLayout',
});

export default function AccessPointsBSSIDsPage() {
  return <AccessPointsLayout activeTab={ValidAPListTabs.BSSIDs} content={<AccessPointsBSSIDs />} />;
}

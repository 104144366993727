import { darkThemeSelector, Small, styled } from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import { colors, shadows } from '@meterup/common';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';

import type { TimeSeries } from '../utils/chart_utils';

export const LegendItemColor = styled('div', {
  display: 'flex',
  width: '10px',
  height: '10px',
  borderRadius: '$2',
});

const LegendItemLabel = styled(Small, LegendLabel, {
  variants: {
    active: {
      true: {
        color: colors.headingBrandLight,
        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
      false: {},
    },
  },
});

const StyledLegendItem = styled(LegendItem, {
  hStack: '$4',
  padding: '$2 $4',
  borderRadius: '$6',
  strokeAll: colors.transparent,
  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },
  [selectors.hover]: {
    cursor: 'pointer',
  },
  variants: {
    active: {
      true: {
        backgroundColor: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,
        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },
      },
    },
  },
});

const LegendsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '$4',
  padding: '$6 $12',
  maxHeight: '$80',
  overflow: 'auto',
  strokeTopBottom: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    strokeTopBottom: colors.strokeNeutralDark,
  },
});

export function ChartLegend({
  handleSelectItem,
  series,
  chartColors,
  selectedItem,
}: {
  handleSelectItem: (item: string) => void;

  selectedItem: string;
  series: TimeSeries[];
  chartColors: string[];
}) {
  const legendDomain = series.map((s) => s.series_id);

  const ordinalColorScale = scaleOrdinal({
    domain: legendDomain,
    range: chartColors,
  });

  return (
    <LegendOrdinal direction="row" scale={ordinalColorScale}>
      {(labels) => (
        <LegendsContainer>
          {labels.map((label) => (
            <StyledLegendItem
              active={selectedItem === label.datum}
              key={label.text}
              tabIndex={0}
              onClick={() => {
                handleSelectItem(label.datum);
              }}
            >
              <LegendItemColor style={{ backgroundColor: label.value as string }} />
              <LegendItemLabel weight="bold">{label.text}</LegendItemLabel>
            </StyledLegendItem>
          ))}
        </LegendsContainer>
      )}
    </LegendOrdinal>
  );
}

import type { api } from '@meterup/proto';
import {
  Body,
  CopyBox,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { isDefinedAndNotEmpty } from '@meterup/common';

export function UserDetailWidget({ user }: { user: api.UserResponse }) {
  return (
    <SummaryList gutter="none">
      {isDefinedAndNotEmpty(user.first_name) && (
        <SummaryListRow>
          <SummaryListKey>Name</SummaryListKey>
          <SummaryListValue>
            {user.first_name} {user.last_name}
          </SummaryListValue>
        </SummaryListRow>
      )}
      <SummaryListRow>
        <SummaryListKey>Email</SummaryListKey>
        <SummaryListValue>
          <CopyBox aria-label="Copy user email" relation="stacked" size="small" value={user.email}>
            <Body family="monospace">{user.email}</Body>
          </CopyBox>
        </SummaryListValue>
      </SummaryListRow>
    </SummaryList>
  );
}

import { Section, SectionContent, SectionHeader, Sections, SummaryList } from '@meterup/atto';

import type { PduInsightsQueryQuery, PowerDistributionUnitQueryQuery } from '../../../gql/graphql';
import { DetailsWidgetListPair } from '../../Devices/Insights';

export function DetailsWidget({
  hardwareDevice,
  virtualDevice,
  relation,
}: {
  hardwareDevice:
    | PduInsightsQueryQuery['hardwareDevice']
    | PowerDistributionUnitQueryQuery['virtualDevice']['hardwareDevice'];
  virtualDevice: PduInsightsQueryQuery['hardwareDevice']['virtualDevice'];
  relation: 'stacked' | 'standalone';
}) {
  if (!hardwareDevice) return null;
  if (hardwareDevice && hardwareDevice.__typename !== 'PowerDistributionUnitHardwareDevice')
    return null;
  if (!virtualDevice || virtualDevice.__typename !== 'PowerDistributionUnitVirtualDevice')
    return null;

  return (
    <Sections>
      <Section relation={relation} internal={!hardwareDevice}>
        <SectionHeader heading="Metadata" />
        <SectionContent gutter="all">
          <SummaryList gutter="none">
            {hardwareDevice && (
              <>
                <DetailsWidgetListPair label="Model" value={hardwareDevice.deviceModel} />
                <DetailsWidgetListPair label="MAC address" value={hardwareDevice.macAddress} />
                <DetailsWidgetListPair label="DHCP IP address" value={virtualDevice.ipAddress} />
              </>
            )}
          </SummaryList>
        </SectionContent>
      </Section>
    </Sections>
  );
}

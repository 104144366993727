/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-use-before-define,@typescript-eslint/naming-convention,@typescript-eslint/no-use-before-define */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AlertParameterName: { input: string; output: string };
  Any: { input: unknown; output: unknown };
  DateTime: { input: string; output: string };
  Duration: { input: string; output: string };
  IP: { input: string; output: string };
  IPPrefix: { input: string; output: string };
  IPV4: { input: string; output: string };
  IPV6: { input: string; output: string };
  JSONObject: { input: Record<string, unknown>; output: Record<string, unknown> };
  MacAddress: { input: string; output: string };
  UUID: { input: string; output: string };
  UnmaskedIPPrefix: { input: string; output: string };
};

export type ApChannelUtilizationMetricsValue = MetricsValue & {
  __typename?: 'APChannelUtilizationMetricsValue';
  apName: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type ApChannelUtilizationResponse = MetricsResponse & {
  __typename?: 'APChannelUtilizationResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<ApChannelUtilizationMetricsValue>;
};

export type ApChannelUtilizationTimeseriesValue = {
  __typename?: 'APChannelUtilizationTimeseriesValue';
  /**
   * What channel the AP's radio was on at the time of the observation.
   * For single AP queries, this will be the most-seen channel during the bucket window.
   * This will be null for network / multiple-AP queries (because network-level is
   * an aggregation of all APs, which will likely be on a long list of channels).
   */
  channel?: Maybe<Scalars['Int']['output']>;
  /**
   * The channel width of the AP's radio at the time of the observation.
   * For single AP queries, this will be the most-seen channel width during the bucket window.
   * This will be null for network / multiple-AP queries (because network-level is
   * an aggregation of all APs, which will likely be on different channel widths).
   */
  channelWidth?: Maybe<Scalars['Int']['output']>;
  /** The maximum signal strength of interference sources detected on the channel. (in dBm) */
  interferenceSignalStrengthMaxdBm: Scalars['Int']['output'];
  /** The median signal strength of interference sources detected on the channel. (in dBm). This is a median of medians of observances within a bucket window, floored to the nearest integer. */
  interferenceSignalStrengthMediandBm: Scalars['Int']['output'];
  /** The number of sources of high interference detected on the channel. (An average of data observances, floored to the nearest integer). */
  interferenceSourceCountHigh: Scalars['Int']['output'];
  /** The number of sources of low interference detected on the channel. (An average of data observances, floored to the nearest integer). */
  interferenceSourceCountLow: Scalars['Int']['output'];
  /** The number of sources of medium interference detected on the channel. (An average of data observances, floored to the nearest integer). */
  interferenceSourceCountMedium: Scalars['Int']['output'];
  /** The amount of utilization contributed by non-802.11 interference in the environment, e.g. Bluetooth, cameras. (from 0 to 1) */
  non80211Utilization: Scalars['Float']['output'];
  /** The amount of utilization as a result of 802.11 interference by neighbors. (from 0 to 1) */
  obssUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from clients. (from 0 to 1) */
  rxUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized by the AP itself. (from 0 to 1) */
  selfUtilization: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** The percentage of time the channel was utilized. (from 0 to 1) */
  totalUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from the AP. (from 0 to 1) */
  txUtilization: Scalars['Float']['output'];
};

export type ApUptimeMetricsValue = MetricsValue & {
  __typename?: 'APUptimeMetricsValue';
  apName: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type ApUptimeResponse = MetricsResponse & {
  __typename?: 'APUptimeResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<ApUptimeMetricsValue>;
};

/** An access point hardware device represents a physical access point. */
export type AccessPointHardwareDevice = HardwareDevice & {
  __typename?: 'AccessPointHardwareDevice';
  /** BSSIDs are MAC addresses that represent a unique identifier for an AP's SSID + radio band pairs. */
  BSSIDs: Array<Bssid>;
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  /** True if the AP's MAC address exists in the most recent controller MAC table for the network. */
  isInCurrentControllerMACTable: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The phy interface (on a Meter Switch) that the AP is connected to, if applicable. */
  phyInterfaceConnectedTo?: Maybe<PhyInterface>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** An access point hardware device represents a physical access point. */
export type AccessPointHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** An access point virtual device is a logical representation of a physical access point. */
export type AccessPointVirtualDevice = VirtualDevice & {
  __typename?: 'AccessPointVirtualDevice';
  UUID: Scalars['UUID']['output'];
  /** The clients that are currently connected to the AP. */
  connectedClients: Array<WirelessClient>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Whether the device been configured to turn off all LED activity. */
  isLEDDarkModeEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  radioProfile?: Maybe<RadioProfile>;
  radioProfileUUID?: Maybe<Scalars['UUID']['output']>;
  radioSettings: RadioSettings;
  /** All of the enabled SSIDs that the AP is broadcasting. */
  ssids: Array<Ssid>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type ActiveClientsInput = {
  includeMeterHardware?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActiveClientsMetricsResponse = MetricsResponse & {
  __typename?: 'ActiveClientsMetricsResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<ActiveClientsMetricsValue>;
};

export type ActiveClientsMetricsValue = MetricsValue & {
  __typename?: 'ActiveClientsMetricsValue';
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type AddNetworkToAutoVpnGroupInput = {
  isFailoverEnabled?: Scalars['Boolean']['input'];
  networkUUID: Scalars['UUID']['input'];
  permittedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  phyInterfaceUUID: Scalars['UUID']['input'];
};

/**
 * Address represents a physical address, used with an ISP. It encompasses the Location and Sublocation.
 *
 * WARNING: These fields are currently different from the fields used in the MailingAddress type. There is a plan to
 * consolidate these types in the future.
 */
export type Address = {
  __typename?: 'Address';
  UUID: Scalars['UUID']['output'];
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company?: Maybe<Company>;
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  sublocationUUID: Scalars['UUID']['output'];
};

/** An AlertDefinition specifies parameters for different types of Alerts used to notify stakeholders of important events for a network. */
export type AlertDefinition = {
  __typename?: 'AlertDefinition';
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parameters: Array<AlertParameter>;
};

export type AlertParameter = {
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterBoolean = AlertParameter & {
  __typename?: 'AlertParameterBoolean';
  defaultValue?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterDate = AlertParameter & {
  __typename?: 'AlertParameterDate';
  defaultValue?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterDateTime = AlertParameter & {
  __typename?: 'AlertParameterDateTime';
  defaultValue?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterDuration = AlertParameter & {
  __typename?: 'AlertParameterDuration';
  defaultValue?: Maybe<Scalars['Duration']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['Duration']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterNumber = AlertParameter & {
  __typename?: 'AlertParameterNumber';
  defaultValue?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['Float']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export type AlertParameterString = AlertParameter & {
  __typename?: 'AlertParameterString';
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exampleValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['AlertParameterName']['output'];
  required: Scalars['Boolean']['output'];
  type: AlertParameterType;
  uuid: Scalars['UUID']['output'];
};

export enum AlertParameterType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Duration = 'DURATION',
  Number = 'NUMBER',
  String = 'STRING',
}

export type AlertReceiver = {
  __typename?: 'AlertReceiver';
  UUID: Scalars['UUID']['output'];
  companyUUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  label: Scalars['String']['output'];
  targets: Array<AlertTarget>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertTarget = {
  UUID: Scalars['UUID']['output'];
  companyUUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  type: AlertTargetType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertTargetEmail = AlertTarget & {
  __typename?: 'AlertTargetEmail';
  UUID: Scalars['UUID']['output'];
  companyUUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailAddress: Scalars['String']['output'];
  type: AlertTargetType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertTargetInput = {
  alertTargetType: AlertTargetType;
  /** Required for all alert target types except email */
  alertTargetUUID?: InputMaybe<Scalars['String']['input']>;
  /** Required if creating a new email target */
  emailAddress?: InputMaybe<Scalars['String']['input']>;
};

export enum AlertTargetType {
  Email = 'EMAIL',
  Webhook = 'WEBHOOK',
}

export type AlertTargetWebhook = AlertTarget & {
  __typename?: 'AlertTargetWebhook';
  UUID: Scalars['UUID']['output'];
  companyUUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  signingSecret?: Maybe<Scalars['String']['output']>;
  type: AlertTargetType;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type AlertTargetsResponse = {
  __typename?: 'AlertTargetsResponse';
  emails: Array<AlertTargetEmail>;
  webhooks: Array<AlertTargetWebhook>;
};

export enum AllowedFolder {
  AccessPointPhotos = 'access_point_photos',
  FloorPlanImages = 'floor_plan_images',
  InternalNotesAttachments = 'internal_notes_attachments',
  NetworkTopologyPhotos = 'network_topology_photos',
  OnboardingDocumentUploads = 'onboarding_document_uploads',
}

/** DNS firewall rules for a DHCP rule. These are powered by Netstar. */
export type ApplicationDnsFirewallRule = {
  __typename?: 'ApplicationDNSFirewallRule';
  UUID: Scalars['UUID']['output'];
  action: FirewallRuleAction;
  application?: Maybe<ApplicationDnsFirewallRuleApplication>;
  category?: Maybe<ApplicationDnsFirewallRuleCategory>;
  createdAt: Scalars['DateTime']['output'];
  dhcpRule: DhcpRule;
  domain?: Maybe<Scalars['String']['output']>;
  group?: Maybe<ApplicationDnsFirewallRuleGroup>;
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** The application associated with a DNS firewall rule as specified by Netstar. */
export type ApplicationDnsFirewallRuleApplication = {
  __typename?: 'ApplicationDNSFirewallRuleApplication';
  id: Scalars['Int']['output'];
  isVisible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** Categories of DNS firewall rules as specified by Netstar. */
export type ApplicationDnsFirewallRuleCategory = {
  __typename?: 'ApplicationDNSFirewallRuleCategory';
  description: Scalars['String']['output'];
  group: ApplicationDnsFirewallRuleGroup;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** Groups multiple DNS firewall rules together. */
export type ApplicationDnsFirewallRuleGroup = {
  __typename?: 'ApplicationDNSFirewallRuleGroup';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** Specifies the hardware device / virtual device pairing when assigning hardware to a virtual device. */
export type AssignHardwareDeviceToVirtualDeviceInput = {
  serialNumber: Scalars['String']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type AttachDeviceToRackElevationInput = {
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing?: InputMaybe<Scalars['Boolean']['input']>;
  /** For display purposes. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** For additional context. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Only for Switches and Patch panels; cannot be specified if it's a Meter device. */
  portCount?: InputMaybe<Scalars['Int']['input']>;
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes: Array<Scalars['Int']['input']>;
  /** Cannot be specified if it's a Meter device. */
  type?: InputMaybe<RackElevationDeviceType>;
  /** If it's a Meter device, the virtual device's UUID. */
  virtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type AuthenticationToken = {
  __typename?: 'AuthenticationToken';
  UUID: Scalars['UUID']['output'];
  hardwareDeviceSerial?: Maybe<Scalars['String']['output']>;
  issuedAt: Scalars['DateTime']['output'];
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  userID?: Maybe<Scalars['Int']['output']>;
};

/** Auto Channel Selection Jobs automatically select the best channel for APs in a network. */
export type AutoChannelSelectionJob = AutoSelectionJob & {
  __typename?: 'AutoChannelSelectionJob';
  UUID: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  networkUUID: Scalars['UUID']['output'];
  /**
   * If the process is still running (completedAt is in the future), then no results will be returned.
   * One result will be returned for every port that changed trunk/access status or native VLAN, or if there was a warning.
   * If the port did not change and there was no warning, it will not appear in the results.
   */
  results?: Maybe<Array<AutoChannelSelectionResult>>;
  scheduledAt: Scalars['DateTime']['output'];
  timeoutAt: Scalars['DateTime']['output'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

export type AutoChannelSelectionJobInput = {
  /**
   * Which radio bands to run ACS for.
   * For now, only the 5GHz band is supported, but we require this in the input for future-proofing.
   */
  bands: Array<RadioBand>;
  /** Only for network-level runs: If true, only run ACS on APs that are experiencing high external interference. */
  excludeLowInterferenceAPs?: Scalars['Boolean']['input'];
  /**
   * If true, the ACS job will run during the network's next maintenance window.
   * Else, it will run immediately.
   */
  scheduledAtNextMaintenanceWindow?: Scalars['Boolean']['input'];
};

export type AutoChannelSelectionResult = {
  __typename?: 'AutoChannelSelectionResult';
  new5GHzChannel?: Maybe<Scalars['Int']['output']>;
  previous5GHzChannel?: Maybe<Scalars['Int']['output']>;
  resultType: AutoChannelSelectionResultType;
  timestamp: Scalars['DateTime']['output'];
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export enum AutoChannelSelectionResultType {
  /** ACS ran and a channel was selected with the least number of BSSs (Basic Service Sets). */
  ChannelSelectedMinBss = 'CHANNEL_SELECTED_MIN_BSS',
  /** ACS ran but a random channel was selected. */
  ChannelSelectedRandom = 'CHANNEL_SELECTED_RANDOM',
  FailedUnknownReason = 'FAILED_UNKNOWN_REASON',
  Success = 'SUCCESS',
}

export type AutoSelectionJob = {
  UUID: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  networkUUID: Scalars['UUID']['output'];
  scheduledAt: Scalars['DateTime']['output'];
  timeoutAt: Scalars['DateTime']['output'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

export type AutoVpnGroup = {
  __typename?: 'AutoVPNGroup';
  UUID: Scalars['UUID']['output'];
  companySlug: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  hubNetwork: Network;
  hubNetworkUUID: Scalars['UUID']['output'];
  hubUplink: AutoVpnHubUplink;
  isEnabled: Scalars['Boolean']['output'];
  isHubFailoverEnabled: Scalars['Boolean']['output'];
  members?: Maybe<Array<AutoVpnMember>>;
  name: Scalars['String']['output'];
  routes?: Maybe<Array<AutoVpnRoute>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AutoVpnHubUplink = {
  __typename?: 'AutoVPNHubUplink';
  UUID: Scalars['UUID']['output'];
  address: Scalars['IP']['output'];
  addressPrefixLength: Scalars['Int']['output'];
  listenPort: Scalars['Int']['output'];
  phyInterface: PhyInterface;
  phyInterfaceUUID: Scalars['UUID']['output'];
  publicKey: Scalars['String']['output'];
};

export type AutoVpnMember = {
  __typename?: 'AutoVPNMember';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  isFailoverEnabled: Scalars['Boolean']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  permittedVLANs: Array<Vlan>;
  updatedAt: Scalars['DateTime']['output'];
  uplink: AutoVpnMemberUplink;
};

export type AutoVpnMemberUplink = {
  __typename?: 'AutoVPNMemberUplink';
  UUID: Scalars['UUID']['output'];
  address: Scalars['IP']['output'];
  addressPrefixLength: Scalars['Int']['output'];
  connectionInfo?: Maybe<AutoVpnMemberUplinkConnectionInfo>;
  hubUplinkUUID: Scalars['UUID']['output'];
  listenPort: Scalars['Int']['output'];
  phyInterface: PhyInterface;
  phyInterfaceUUID: Scalars['UUID']['output'];
  publicKey: Scalars['String']['output'];
};

export type AutoVpnMemberUplinkConnectionInfo = {
  __typename?: 'AutoVPNMemberUplinkConnectionInfo';
  /** The last time a handshake was received (from the hub's perspective). */
  lastHandshakeRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a handshake was transmitted (from the hub's perspective). */
  lastHandshakeTx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was received over the tunnel (from the hub's perspective). */
  lastPacketRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was transmitted over the tunnel (from the hub's perspective). */
  lastPacketTx?: Maybe<Scalars['DateTime']['output']>;
  observedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AutoVpnRoute = {
  __typename?: 'AutoVPNRoute';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dstGateway: Scalars['IPV4']['output'];
  dstPrefixLength: Scalars['Int']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/**
 * BSSID represents a unique identifier for a wireless network, in the form of a MAC address.
 * Every radio band / SSID pair for an AP has its own BSSID, derived from the hardware device's MAC address.
 */
export type Bssid = {
  __typename?: 'BSSID';
  BSSID: Scalars['MacAddress']['output'];
  SSID: Ssid;
  SSIDUUID: Scalars['UUID']['output'];
  accessPointHardwareDevice: AccessPointHardwareDevice;
  accessPointSerialNumber: Scalars['String']['output'];
  /** A BSSID is inactive if it is associated with a disabled SSID, or the SSID is not broadcast for its AP, or the SSID is not allowed for its radio band, or the radio band is disabled on its AP's radio profile. */
  isActive: Scalars['Boolean']['output'];
  radioBand: RadioBand;
};

export type Base64Image = {
  __typename?: 'Base64Image';
  /** The base64 encoded image. */
  data: Scalars['String']['output'];
  /** The type of the image. */
  imageType: ImageType;
};

export type BearerSession = {
  __typename?: 'BearerSession';
  UUID: Scalars['UUID']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type BindFirewallRuleToPhyInterface = {
  firewallRuleUUID: Scalars['UUID']['input'];
  metric: Scalars['Int']['input'];
  /** The phy interface must be an uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type BindFirewallRuleToVlan = {
  firewallRuleUUID: Scalars['UUID']['input'];
  metric: Scalars['Int']['input'];
  vlanUUID: Scalars['UUID']['input'];
};

export type BindPolicyRoutingRuleInput = {
  binding: PolicyRoutingRuleBindingInput;
  /** If not provided will be 1 greater than current greatest metric. */
  metric?: InputMaybe<Scalars['Int']['input']>;
};

export type BindPolicyRoutingRulesInput = {
  binding: PolicyRoutingRuleBindingInput;
};

export type BindVlanToPhyInterfaceInput = {
  /** If true and there is already a Native VLAN bound to this interface, the Native VLAN will be overwritten and set to this VLAN. */
  isTagged: Scalars['Boolean']['input'];
  phyInterfaceUUID: Scalars['UUID']['input'];
  vlanUUID: Scalars['UUID']['input'];
};

/** BootInfo captures logs every time a device boots up. */
export type BootInfo = {
  __typename?: 'BootInfo';
  /** The last reported boot counter which the device increments every time it boots */
  bootCount: Scalars['Int']['output'];
  /** The last reported build the device was running */
  buildName: Scalars['String']['output'];
  /** The time at which the device first reported the information */
  createdAt: Scalars['DateTime']['output'];
  /** If the device knows, this is the last reported reason that caused the device to reboot */
  rebootReason: Scalars['String']['output'];
  /** The time at which the device reported the information */
  updatedAt: Scalars['DateTime']['output'];
};

export type BulkNosUpgrade = {
  __typename?: 'BulkNOSUpgrade';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  networks: Array<Network>;
  nosVersionID: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type BulkNosUpgradesFilterInput = {
  limit?: Scalars['Int']['input'];
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  offset?: Scalars['Int']['input'];
};

export type CaptivePortal = {
  __typename?: 'CaptivePortal';
  UUID: Scalars['UUID']['output'];
  /** Whitelisted IPs/URLs that are allowed on the network by default. */
  allowedHosts: Array<Scalars['String']['output']>;
  /** Authentication session duration, defaults to 1 day (86400s) if not set. */
  authLifetimeSec?: Maybe<Scalars['Int']['output']>;
  /** The heading to display on the captive portal page. */
  callToAction?: Maybe<Scalars['String']['output']>;
  displaySettings?: Maybe<CaptivePortalDisplaySettings>;
  /** The URL to an external captive portal. If this is configured, it will override all configurations specific to internal captive portal. */
  externalPortalURL?: Maybe<Scalars['String']['output']>;
  /** HMAC secret to sign and verify signatures. */
  hmacSecret?: Maybe<Scalars['String']['output']>;
  /** If true, the captive portal is enabled. */
  isEnabled: Scalars['Boolean']['output'];
  /** A base64 encoded image of the logo. This is used to limit the number of domains we need to allow for the logo image. */
  logoImageBase64?: Maybe<Base64Image>;
  /** The S3 key for where the logo image is stored. This is used for configuration, and should not be used to display the image. */
  logoImageS3Key?: Maybe<Scalars['String']['output']>;
  /** A URL to a logo image that will be displayed on the captive portal page. */
  logoImageURL?: Maybe<Scalars['String']['output']>;
  /** A human-friendly name. */
  name?: Maybe<Scalars['String']['output']>;
  networkUUID: Scalars['UUID']['output'];
  /** The URL to redirect to after a successful captive portal login. */
  redirectURL?: Maybe<Scalars['String']['output']>;
  /** The terms that a user must agree to before accessing the network. */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  /** The VLANs that this captive portal is associated with. */
  vlans?: Maybe<Array<Vlan>>;
  /** UUID used to generate a unique URL for a webhook corresponding to this captive portal. */
  webhookKey: Scalars['UUID']['output'];
};

export type CaptivePortalClient = {
  __typename?: 'CaptivePortalClient';
  ipAddress: Scalars['IP']['output'];
  /** If true, client has accepted the terms and conditions and has been authorized. */
  isAuthorized: Scalars['Boolean']['output'];
  macAddress: Scalars['MacAddress']['output'];
  rawUserAgent: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type CaptivePortalDisplaySettings = {
  __typename?: 'CaptivePortalDisplaySettings';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
  hidePoweredByMeter?: Maybe<Scalars['Boolean']['output']>;
};

export type CaptivePortalDisplaySettingsInput = {
  /** A hex color code */
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  /** A hex color code */
  fontColor?: InputMaybe<Scalars['String']['input']>;
  /** If true, the "Powered by Meter" text will be hidden. */
  hidePoweredByMeter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CaptivePortalSettingsInput = {
  /** The IPs/URLs that are whitelisted by default. */
  allowedHosts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Authentication session duration, defaults to 1 day (86400s) if not set. */
  authLifetimeSec?: InputMaybe<Scalars['Int']['input']>;
  /** The heading to display on the captive portal page. */
  callToAction?: InputMaybe<Scalars['String']['input']>;
  displaySettings?: InputMaybe<CaptivePortalDisplaySettingsInput>;
  /** Setting externalPortalURL will configure this captive portal to redirect users to an external portal and override any settings specific to internal captive portals. */
  externalPortalURL?: InputMaybe<Scalars['String']['input']>;
  /** If true, the captive portal is enabled. */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Use the `createPreSignedUploadUrl` mutation to generate an S3 key and presigned URL for the logo image, and specify it here. */
  logoImageS3Key?: InputMaybe<Scalars['String']['input']>;
  /** A human-friendly name. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The URL to redirect to after a successful captive portal login. */
  redirectURL?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  /** Setting vlanUUIDs will assign this captive portal to the specified VLANs. */
  vlanUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export enum CellularFailoverEventRoute {
  Cellular = 'CELLULAR',
  Wan = 'WAN',
}

export enum CellularManagerModemState {
  Attached = 'ATTACHED',
  Detached = 'DETACHED',
  Unspecified = 'UNSPECIFIED',
}

export type CellularStatus = {
  __typename?: 'CellularStatus';
  managerModemState: CellularManagerModemState;
  observedAt: Scalars['DateTime']['output'];
};

export type CellularUsage = {
  __typename?: 'CellularUsage';
  downloadBytes?: Maybe<Scalars['Int']['output']>;
  uploadBytes?: Maybe<Scalars['Int']['output']>;
};

export type ChannelUtilizationResponseV2 = {
  __typename?: 'ChannelUtilizationResponseV2';
  band: RadioBand;
  values: Array<ApChannelUtilizationTimeseriesValue>;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type ChannelUtilizationTimeseriesFilterInput = {
  /** Specify the time range for the timeseries data. */
  timeFilter: MetricsFilterInput;
};

export type CheckRoleInput = {
  companySlug?: InputMaybe<Scalars['String']['input']>;
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  roleName: RoleName;
};

export enum ClientAssignmentProtocol {
  Dhcp = 'DHCP',
  Static = 'STATIC',
}

export type ClientChannelUtilizationTimeseriesValue = {
  __typename?: 'ClientChannelUtilizationTimeseriesValue';
  /** The amount of utilization contributed by non-802.11 interference in the environment, e.g. Bluetooth, cameras. (from 0 to 1) */
  non80211Utilization: Scalars['Float']['output'];
  /** The amount of utilization as a result of 802.11 interference by neighbors. (from 0 to 1) */
  obssUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from clients. (from 0 to 1) */
  rxUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized by the AP itself. (from 0 to 1) */
  selfUtilization: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** The percentage of time the channel was utilized. (from 0 to 1) */
  totalUtilization: Scalars['Float']['output'];
  /** The percentage of time the channel was utilized due to transmissions from the AP. (from 0 to 1) */
  txUtilization: Scalars['Float']['output'];
};

/** Aggregated values (like min/max) over the given timerange for all of the client metrics requested. */
export type ClientMetricsMetadata = {
  __typename?: 'ClientMetricsMetadata';
  associationTimeSeconds?: Maybe<MetricsMetadata>;
  clientCount?: Maybe<MetricsMetadata>;
  hwMode?: Maybe<MetricsMetadata>;
  noise?: Maybe<MetricsMetadata>;
  numSpatialStreamsRx?: Maybe<MetricsMetadata>;
  numSpatialStreamsTx?: Maybe<MetricsMetadata>;
  /** @deprecated Prefer rxBytes instead */
  rx?: Maybe<MetricsMetadata>;
  rxBytes?: Maybe<MetricsMetadata>;
  rxMulticastBytes?: Maybe<MetricsMetadata>;
  rxRate?: Maybe<MetricsMetadata>;
  rxUnicastBytes?: Maybe<MetricsMetadata>;
  signal?: Maybe<MetricsMetadata>;
  snr?: Maybe<MetricsMetadata>;
  /** @deprecated Prefer txBytes instead */
  tx?: Maybe<MetricsMetadata>;
  txBytes?: Maybe<MetricsMetadata>;
  txMulticastBytes?: Maybe<MetricsMetadata>;
  txRate?: Maybe<MetricsMetadata>;
  txUnicastBytes?: Maybe<MetricsMetadata>;
};

/** Deprecated in favor of `ClientMetricsTimeseriesResponse`. */
export type ClientMetricsResponse = {
  __typename?: 'ClientMetricsResponse';
  metadata?: Maybe<ClientMetricsMetadata>;
  values: Array<ClientMetricsValue>;
};

export type ClientMetricsTimeseriesFilterInput = {
  /** Specifying this field returns only metrics observed for the given radio band(s). */
  bands?: InputMaybe<Array<RadioBand>>;
  /** Specifying this field returns only metrics observed for the given radio channel(s). */
  channels?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Specifying this field returns only metrics observed for the given SSID(s), by UUID. */
  ssidUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Specify the time range for the timeseries data. */
  timeFilter: MetricsFilterInput;
};

export type ClientMetricsTimeseriesResponse = {
  __typename?: 'ClientMetricsTimeseriesResponse';
  metadata?: Maybe<ClientMetricsMetadata>;
  values: Array<ClientMetricsTimeseriesValue>;
};

/**
 * Time-series data for client connectivity metrics.
 * If requested at a per-AP level, the results will be aggregated over all clients connected to the AP.
 */
export type ClientMetricsTimeseriesValue = {
  __typename?: 'ClientMetricsTimeseriesValue';
  /**
   * Number of seconds that the client has been associated with an AP for.
   * @deprecated This isn't really timeseries data and a timestamp is more useful; refer to WirelessClient.associatedAt instead.
   */
  associationTimeSeconds?: Maybe<Scalars['Float']['output']>;
  /** The count of distinct clients (by MAC address) that connected to the AP during the time period. */
  clientCount: Scalars['Int']['output'];
  /** The wifi capabilities of the client device. */
  hwMode?: Maybe<Scalars['String']['output']>;
  /** Noise in dBm. */
  noise: Scalars['Float']['output'];
  /** The number of rx spatial streams */
  numSpatialStreamsRx?: Maybe<Scalars['Int']['output']>;
  /** The number of tx spatial streams. */
  numSpatialStreamsTx?: Maybe<Scalars['Int']['output']>;
  /** Total number of bytes received in the given time period. */
  rxBytes: Scalars['Float']['output'];
  /** Total number of multicast bytes received in the given time period; a subset of rxBytes. */
  rxMulticastBytes: Scalars['Float']['output'];
  /** The rate at which the client is receiving data in kilobits per second. */
  rxRate: Scalars['Float']['output'];
  /** The ratio of the number of times a packet was retried to the total number of success packets. Null if no packets succeeded. */
  rxRetryRatio?: Maybe<Scalars['Float']['output']>;
  /** @deprecated It's unreliable to determine this client stat from the AP's perspective. */
  rxSuccessRatio?: Maybe<Scalars['Float']['output']>;
  /** Total number of unicast bytes received in the given time period; a subset of rxBytes. */
  rxUnicastBytes: Scalars['Float']['output'];
  /** Received signal strength in dBm. */
  signal: Scalars['Float']['output'];
  /** Signal-to-noise ratio in dBm. A higher value is better. SNR = signal - noise. */
  snr: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes transmitted in the given time period. */
  txBytes: Scalars['Float']['output'];
  /** Total number of multicast bytes transmitted in the given time period; a subset of txBytes. */
  txMulticastBytes: Scalars['Float']['output'];
  /** The rate at which the client is transmitting data in kilobits per second. */
  txRate: Scalars['Float']['output'];
  /** The ratio of the number of times the client retried a packet transmission to the number of packets successfully transmitted. Null if no packets were transmitted. */
  txRetryRatio?: Maybe<Scalars['Float']['output']>;
  /** The ratio of the number of times the client successfully received a packet to the number of packets transmitted. Null if no packets were transmitted. */
  txSuccessRatio?: Maybe<Scalars['Float']['output']>;
  /** Total number of unicast bytes transmitted in the given time period; a subset of txBytes. */
  txUnicastBytes: Scalars['Float']['output'];
};

/** Deprecated in favor of `ClientMetricsTimeseriesValue`. */
export type ClientMetricsValue = {
  __typename?: 'ClientMetricsValue';
  macAddress: Scalars['MacAddress']['output'];
  noise: Scalars['Float']['output'];
  rxRate: Scalars['Float']['output'];
  signal: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  txRate: Scalars['Float']['output'];
};

export type ClientVpnClient = {
  __typename?: 'ClientVPNClient';
  UUID: Scalars['UUID']['output'];
  connectionInfo?: Maybe<ClientVpnClientConnectionInfo>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  ipAddress: Scalars['IP']['output'];
  name: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
  userSID: Scalars['UUID']['output'];
  vpnServerUUID: Scalars['UUID']['output'];
};

export type ClientVpnClientConnectionInfo = {
  __typename?: 'ClientVPNClientConnectionInfo';
  /** The last time a handshake was received (from the controller's perspective). */
  lastHandshakeRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a handshake was transmitted (from the controller's perspective). */
  lastHandshakeTx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was received over the tunnel (from the controller's perspective). */
  lastPacketRx?: Maybe<Scalars['DateTime']['output']>;
  /** The last time a packet was transmitted over the tunnel (from the controller's perspective). */
  lastPacketTx?: Maybe<Scalars['DateTime']['output']>;
  observedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ClientVpnServer = {
  __typename?: 'ClientVPNServer';
  UUID: Scalars['UUID']['output'];
  address?: Maybe<Scalars['IP']['output']>;
  addressMask?: Maybe<Scalars['Int']['output']>;
  clients: Array<ClientVpnClient>;
  defaultClientAllowedIPs?: Maybe<Array<Scalars['IPPrefix']['output']>>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: Maybe<Scalars['IPV4']['output']>;
  endpoint: Scalars['String']['output'];
  isFailoverEnabled: Scalars['Boolean']['output'];
  /** The WireGuard interface will listen on this interface. If null, the server is not active. */
  phyInterface?: Maybe<PhyInterface>;
  port: Scalars['Int']['output'];
  publicKey: Scalars['String']['output'];
};

/** A company is a core Meter entity used to represent customers. Networks belong to companies. */
export type Company = {
  __typename?: 'Company';
  /**
   * The Salesforce Company ID corresponding to this company.
   * @deprecated Use `isCustomer` instead.
   */
  externalID?: Maybe<Scalars['String']['output']>;
  /** A company representing a Meter customer as opposed to an internal (test) company. */
  isCustomer: Scalars['Boolean']['output'];
  /** A prospective company is used for sales purposes. */
  isProspective: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networks: Array<Network>;
  permittedEmailDomains?: Maybe<Array<PermittedEmailDomain>>;
  slug: Scalars['String']['output'];
  ssoEnabled?: Maybe<Scalars['Boolean']['output']>;
  supportGroup?: Maybe<Scalars['Int']['output']>;
  /** The Customer Success team segments customers by their tier, which dictates their level of support / sensitivity. */
  supportTier?: Maybe<CompanySupportTier>;
  uuid: Scalars['UUID']['output'];
};

export enum CompanyMembershipRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
}

/** The support tier indicates the customer's level of support / sensitivity. */
export enum CompanySupportTier {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

/** A company user provides access for a user to a company's networks. */
export type CompanyUser = {
  __typename?: 'CompanyUser';
  UUID: Scalars['UUID']['output'];
  company: Company;
  companyMembershipRole: CompanyMembershipRole;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  roles: Array<RoleAssignment>;
  user: User;
};

export type ConfigureSsoInput = {
  companySlug: Scalars['String']['input'];
  type: SsoAdminPortalType;
};

export type ConfigureSsoResult = {
  __typename?: 'ConfigureSSOResult';
  company: Company;
  portalURL: Scalars['String']['output'];
};

/** ConfiguredNOSVersion represents the NOS firmware version that a device is configured to run. */
export type ConfiguredNosVersion = {
  __typename?: 'ConfiguredNOSVersion';
  /** The build name specified in the NOS version (could be different from that reported by the device in bootinfo table) */
  buildName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  version: Scalars['String']['output'];
};

/** A console is a record of a user logging into a device via the console. */
export type ConsoleLogin = {
  __typename?: 'ConsoleLogin';
  UUID: Scalars['UUID']['output'];
  /** Timestamp of when the console login was recorded. */
  createdAt: Scalars['DateTime']['output'];
  /** Device that the user logged into. */
  hardwareDevice: HardwareDevice;
  /** Username of the user that logged into the device. */
  username: Scalars['String']['output'];
};

/** Time-series data for controller DHCP server uptime metrics. */
export type ControllerDhcpUptimeMetricsValue = MetricsValue & {
  __typename?: 'ControllerDHCPUptimeMetricsValue';
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type ControllerDhcpUptimeResponse = MetricsResponse & {
  __typename?: 'ControllerDHCPUptimeResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<ControllerDhcpUptimeMetricsValue>;
};

export type ControllerDnsRequestRatesResponse = {
  __typename?: 'ControllerDNSRequestRatesResponse';
  values: Array<ControllerDnsRequestRatesValue>;
};

export type ControllerDnsRequestRatesValue = MetricsValue & {
  __typename?: 'ControllerDNSRequestRatesValue';
  timestamp: Scalars['DateTime']['output'];
  /** UUID of the DNS server. */
  uuid: Scalars['UUID']['output'];
  /** Requests per second. */
  value: Scalars['Float']['output'];
};

/** A controller hardware device represents a physical controller (aka Security Appliance). */
export type ControllerHardwareDevice = HardwareDevice & {
  __typename?: 'ControllerHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A controller hardware device represents a physical controller (aka Security Appliance). */
export type ControllerHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type ControllerPortMetricsRateResponse = {
  __typename?: 'ControllerPortMetricsRateResponse';
  values: Array<ControllerPortMetricsRateValue>;
};

export type ControllerPortMetricsRateValue = {
  __typename?: 'ControllerPortMetricsRateValue';
  /** Total number of broadcast packets per second received in the given time period; a subset of totalRxPackets. */
  broadcastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of broadcast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  dropsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second received in the given time period; a subset of totalRxPackets. */
  multicastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets per second that encountered errors being received. */
  rxErrPerSecond: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes per second received in the given time period. */
  totalRxBytesPerSecond: Scalars['Float']['output'];
  /** Total number of bytes per second transmitted in the given time period. */
  totalTxBytesPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that encountered errors during transmission. */
  txErrPerSecond: Scalars['Float']['output'];
};

export type ControllerPortMetricsResponse = {
  __typename?: 'ControllerPortMetricsResponse';
  values: Array<ControllerPortMetricsValue>;
};

/** Counter stats per controller port over a lookback period. */
export type ControllerPortMetricsValue = {
  __typename?: 'ControllerPortMetricsValue';
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Float']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Float']['output'];
  /** The number of packets that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  drops: Scalars['Float']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Float']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets that encountered errors being received. */
  rxErr: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Float']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Float']['output'];
  /** The number of packets that encountered errors during transmission. */
  txErr: Scalars['Float']['output'];
};

/** Summed stats (unless otherwise specified) per controller port over a lookback period. */
export type ControllerPortStat = {
  __typename?: 'ControllerPortStat';
  /** Total number of broadcast bytes received in the given time period; a subset of totalRxBytes. */
  broadcastRxBytes: Scalars['Int']['output'];
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Int']['output'];
  /** Total number of broadcast bytes transmitted in the given time period; a subset of totalTxBytes. */
  broadcastTxBytes: Scalars['Int']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Int']['output'];
  /** The number of packets that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  drops: Scalars['Int']['output'];
  /** Most recent hardware up state. Indicates that a physical connection is established, typically meaning a cable is properly connected and active. */
  hwUp: Scalars['Boolean']['output'];
  /** The number of IPv4 packets transmit/received. */
  ipv4: Scalars['Int']['output'];
  /** The number of IPv6 packets transmit/received. */
  ipv6: Scalars['Int']['output'];
  /** Total number of multicast bytes received in the given time period; a subset of totalRxBytes. */
  multicastRxBytes: Scalars['Int']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Int']['output'];
  /** Total number of multicast bytes transmitted in the given time period; a subset of totalTxBytes. */
  multicastTxBytes: Scalars['Int']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Int']['output'];
  /** The physical port number on the controller. */
  portNumber: Scalars['Int']['output'];
  /** This field is not currently being sent by firmware. */
  punts: Scalars['Int']['output'];
  /** The number of packets that encountered errors being received. */
  rxErr: Scalars['Int']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Int']['output'];
  /** Total number of packets received in the given time period. */
  totalRxPackets: Scalars['Int']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Int']['output'];
  /** Total number of packets transmitted in the given time period. */
  totalTxPackets: Scalars['Int']['output'];
  /** The number of packets that encountered errors during transmission. */
  txErr: Scalars['Int']['output'];
  /** Total number of unicast bytes received in the given time period; a subset of totalRxBytes. */
  unicastRxBytes: Scalars['Int']['output'];
  /** Total number of unicast packets received in the given time period; a subset of totalRxPackets. */
  unicastRxPackets: Scalars['Int']['output'];
  /** Total number of unicast bytes transmitted in the given time period; a subset of totalTxBytes. */
  unicastTxBytes: Scalars['Int']['output'];
  /** Total number of unicast packets transmitted in the given time period; a subset of totalTxPackets. */
  unicastTxPackets: Scalars['Int']['output'];
};

/** A controller virtual device is a logical representation of a physical controller. */
export type ControllerVirtualDevice = VirtualDevice & {
  __typename?: 'ControllerVirtualDevice';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  /** Configuration for high availability associated with this controller, if configured in HA mode. */
  highAvailability?: Maybe<HighAvailabilityControllerVirtualDeviceInfo>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  phyInterfaces: Array<PhyInterface>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type CopyIpSecTunnelFromConfig1ToConfig2Input = {
  /** This is the name of the tunnel within the config 1 config. It is the portion after `meter.v1.network.vpn.ipsec.` */
  config1Name: Scalars['String']['input'];
  /** This preshared key is stored on disk on a controller at /etc/ipsec.d/$config1Name.secrets */
  presharedKey: Scalars['String']['input'];
};

export type CreateAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CreateAlertReceiverWithTargetsInput = {
  label: Scalars['String']['input'];
  targets: Array<AlertTargetInput>;
};

export type CreateAlertTargetWebhookInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  /** Optionally allow the customer to specify their signing secret */
  signingSecret?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type CreateApplicationDnsFirewallRuleInput = {
  action: FirewallRuleAction;
  /** This is a netstar application ID. */
  applicationID?: InputMaybe<Scalars['Int']['input']>;
  /** This is a netstar category ID. */
  categoryID?: InputMaybe<Scalars['Int']['input']>;
  /** domain is a FQDN that is matched, allowing for wildcards denoted by '*'. */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** This is a netstar group ID. */
  groupID?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  /** If not provided will default to 1 greater than the highest existing priority. */
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAutoVpnGroupHubUplinkInput = {
  address: Scalars['IP']['input'];
  addressPrefixLength: Scalars['Int']['input'];
  isFailoverEnabled?: Scalars['Boolean']['input'];
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type CreateAutoVpnGroupInput = {
  hubNetworkUUID: Scalars['UUID']['input'];
  isHubFailoverEnabled?: Scalars['Boolean']['input'];
  members?: InputMaybe<Array<AddNetworkToAutoVpnGroupInput>>;
  name: Scalars['String']['input'];
  uplink: CreateAutoVpnGroupHubUplinkInput;
};

export type CreateAutoVpnRouteInput = {
  dstGateway: Scalars['IPV4']['input'];
  dstPrefixLength: Scalars['Int']['input'];
  isEnabled?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateBulkNosUpgradeInput = {
  networkUUIDs: Array<Scalars['UUID']['input']>;
  nosVersionID: Scalars['Int']['input'];
};

export type CreateClientVpnClientInput = {
  /** end user's company email, associated with the VPN server's controller, e.g. angie@meter.com */
  email: Scalars['String']['input'];
  /** end user device name, e.g. paulmbp */
  name: Scalars['String']['input'];
  /** a Curve25519 public key - the end user should generate via wireguard and provide */
  publicKey: Scalars['String']['input'];
};

export type CreateClientVpnServerInput = {
  address: Scalars['IPV4']['input'];
  addressMask: Scalars['Int']['input'];
  defaultClientAllowedIPs?: Array<Scalars['IPPrefix']['input']>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: InputMaybe<Scalars['IPV4']['input']>;
  isFailoverEnabled?: Scalars['Boolean']['input'];
  listenPort?: InputMaybe<Scalars['Int']['input']>;
  /** Must be an uplink phy interface. The WireGuard interface will listen on this interface. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateCompanyInput = {
  /** A prospective company is used for sales purposes. */
  isProspective?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

/** Create a new user for the specified company, with the provided attributes. */
export type CreateCompanyUserInput = {
  /**
   * companyMembershipRole refers to the old style of granting permissions. This will be deprecated in the future,
   * but is currently required as only those with an admin `CompanyMembershipRole` can access dashboard.
   */
  companyMembershipRole: CompanyMembershipRole;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CreateDhcpOptionInput = {
  code: Scalars['Int']['input'];
  data: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDhcpReservedRangeInput = {
  endIPAddress: Scalars['IP']['input'];
  startIPAddress: Scalars['IP']['input'];
};

export type CreateDhcpRuleInput = {
  dnsCacheIsEnabled?: Scalars['Boolean']['input'];
  dnsCacheMaxTTL?: Scalars['Int']['input'];
  dnsCacheSize?: Scalars['Int']['input'];
  dnsSearchDomains?: Array<Scalars['String']['input']>;
  dnsUpstreamServers?: Array<Scalars['IP']['input']>;
  dnsUseGatewayProxy?: Scalars['Boolean']['input'];
  endIPAddress: Scalars['IP']['input'];
  gatewayIPAddress: Scalars['IP']['input'];
  gatewayPrefixLength: Scalars['Int']['input'];
  isIPv6: Scalars['Boolean']['input'];
  leaseDurationSeconds: Scalars['Int']['input'];
  startIPAddress: Scalars['IP']['input'];
};

export type CreateDhcpStaticMappingInput = {
  hostname?: InputMaybe<Scalars['String']['input']>;
  ipAddress: Scalars['IP']['input'];
  macAddress: Scalars['MacAddress']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDnsHostMappingInput = {
  destinationDomain?: InputMaybe<Scalars['String']['input']>;
  destinationIPAddress?: InputMaybe<Scalars['IP']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  overrideDomain: Scalars['String']['input'];
};

/** Used to mark a device as 'dev', moving it into the dev (staging) realm for testing purposes. */
export type CreateDevHardwareDeviceInput = {
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  serialNumber: Scalars['String']['input'];
};

export type CreateDeviceHeartbeatInput = {
  serialNumber: Scalars['String']['input'];
};

export type CreateEncryption8021XInput = {
  accountingServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  accountingServerPort?: InputMaybe<Scalars['Int']['input']>;
  accountingServerSecret?: InputMaybe<Scalars['String']['input']>;
  authServerIPAddress: Scalars['IP']['input'];
  authServerPort: Scalars['Int']['input'];
  authServerSecret: Scalars['String']['input'];
  label: Scalars['String']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type CreateFirewallRuleInput = {
  action: FirewallRuleAction;
  /** The phy interfaces must be uplinks. */
  boundPhyInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  boundVLANUUIDs: Array<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  dstIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** @deprecated Use `dstPortRanges` instead. */
  dstPortRange?: InputMaybe<PortRangeInput>;
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  dstVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  isBidirectional?: Scalars['Boolean']['input'];
  isEnabled: Scalars['Boolean']['input'];
  isMeterInternal: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  /** @deprecated Use `protocols` instead. */
  protocol?: InputMaybe<IpProtocol>;
  protocols?: InputMaybe<Array<IpProtocol>>;
  srcClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  srcIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** @deprecated Use `srcPortRanges` instead. */
  srcPortRange?: InputMaybe<PortRangeInput>;
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateFloorPlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageS3Key: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type CreateHighAvailabilityPairInput = {
  /** Interval at which the controllers will advertise to one another, in milliseconds. */
  advertisementIntervalMs?: Scalars['Int']['input'];
  /** VLAN ID to use for the advertisement VLAN. */
  advertisementVLANID?: Scalars['Int']['input'];
  /** Enable or disable preemption for the HA controllers in VRRP. */
  preempt: Scalars['Boolean']['input'];
};

export type CreateHostMonitoringTargetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  targetHost: Scalars['IPV4']['input'];
  type: HostMonitoringType;
};

export type CreateIpSecTunnelInput = {
  authenticationAlgorithm?: InputMaybe<IpSecAuthenticationAlgorithm>;
  encryptionAlgorithm?: InputMaybe<IpSecEncryptionAlgorithm>;
  isEnabled: Scalars['Boolean']['input'];
  isInitiator?: Scalars['Boolean']['input'];
  keyExchangeDHGroup?: InputMaybe<IpSecDhGroup>;
  leftID: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** The phy interface must be an uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
  /** presharedKey can be base64 or plaintext. */
  presharedKey: Scalars['String']['input'];
  right?: InputMaybe<Scalars['String']['input']>;
  rightID: Scalars['String']['input'];
  rightPrefixes: Array<Scalars['IPPrefix']['input']>;
  vlanUUIDs: Array<Scalars['UUID']['input']>;
};

export type CreateInternalNoteInput = {
  isImportant?: Scalars['Boolean']['input'];
  note: Scalars['String']['input'];
  /** Use the `createPreSignedUploadUrl` mutation to generate S3 keys and presigned URLs for the photos, and specify them here. */
  photoS3Keys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateInternetServicePlanInput = {
  address?: InputMaybe<CreateAddressInput>;
  connection: InternetServicePlanConnectionInput;
  ipConfiguration: InternetServicePlanIpInput;
  providerUUID: Scalars['UUID']['input'];
};

export type CreateMailingAddressInput = {
  city: Scalars['String']['input'];
  countryISO2: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  subdivisionCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNetworkCopyInput = {
  /** If not provided, the new label is "<old label> (Copy)". */
  label?: InputMaybe<Scalars['String']['input']>;
  /** If neither `mailingAddressUUID` or `mailingAddressInput` are provided, the mailing address will match the source network's. */
  mailingAddressInput?: InputMaybe<CreateMailingAddressInput>;
  /** If neither `mailingAddressUUID` or `mailingAddressInput` are provided, the mailing address will match the source network's. */
  mailingAddressUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** If not provided, the new slug is "<old slug>-copy". */
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNetworkInput = {
  companySlug?: InputMaybe<Scalars['String']['input']>;
  isActive?: Scalars['Boolean']['input'];
  /** Enables controller to upgrade config-1 APs deployed to the network */
  isConfig1WosUpgradesEnabled?: Scalars['Boolean']['input'];
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Not a network property anymore */
  isTest?: Scalars['Boolean']['input'];
  label: Scalars['String']['input'];
  /** @deprecated Use `mailingAddressInput` instead. */
  mailingAddress?: InputMaybe<Scalars['String']['input']>;
  mailingAddressInput?: InputMaybe<CreateMailingAddressInput>;
  mailingAddressUUID?: InputMaybe<Scalars['UUID']['input']>;
  multiWANAlgorithm?: InputMaybe<MultiWanAlgorithm>;
  patchPanelDiagramURL?: InputMaybe<Scalars['String']['input']>;
  /** A NOS version should be specified. If not, the default no-upgrades NOS version is assigned */
  pinnedNOSVersionID?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
  /** @deprecated Not a network property anymore */
  squareFeet?: InputMaybe<Scalars['Int']['input']>;
  staggeredUpgradeIntervalMin?: InputMaybe<Scalars['Int']['input']>;
  topologyImageS3Key?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNosFeatureInput = {
  description: Scalars['String']['input'];
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  maxMajorVersion?: InputMaybe<Scalars['Int']['input']>;
  maxMinorVersion?: InputMaybe<Scalars['Int']['input']>;
  maxPatchVersion?: InputMaybe<Scalars['Int']['input']>;
  minMajorVersion: Scalars['Int']['input'];
  minMinorVersion: Scalars['Int']['input'];
  minPatchVersion: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type CreateNotificationSettingInput = {
  alertReceiverUUID: Scalars['UUID']['input'];
  arguments: Array<NotificationSettingArgumentInput>;
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  /** Defaults to true */
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  notificationDefinitionName: Scalars['String']['input'];
};

export type CreateOneToOneNatRuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalIPAddr: Scalars['IPV4']['input'];
  externalPhyInterfaceUUID: Scalars['UUID']['input'];
  internalIPAddr: Scalars['IPV4']['input'];
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreatePermittedEmailDomainInput = {
  companySlug: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

export type CreatePolicyRoutingRuleInput = {
  bindings: Array<PolicyRoutingRuleBindingInput>;
  dstPortRanges: Array<PortRangeInput>;
  dstPrefix: Scalars['IPPrefix']['input'];
  isEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  protocols: Array<IpProtocol>;
  srcPortRanges: Array<PortRangeInput>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreatePortForwardingRuleInput = {
  allowedRemoteIPAddresses?: InputMaybe<Array<Scalars['IP']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalIPAddress?: InputMaybe<Scalars['IP']['input']>;
  /** External uplink physical interface. Currently required despite schema being optional. */
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  externalPort: Scalars['Int']['input'];
  isEnabled: Scalars['Boolean']['input'];
  localIPAddress: Scalars['IP']['input'];
  localPort: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  protocol: PortIpProtocol;
};

export type CreatePreSignedUploadUrlInput = {
  fileExtension: Scalars['String']['input'];
  folder: AllowedFolder;
};

export type CreateRackElevationInput = {
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: InputMaybe<Scalars['Int']['input']>;
  label: Scalars['String']['input'];
  notes?: InputMaybe<Array<RackElevationNoteInput>>;
  rackMountUnitCount: Scalars['Int']['input'];
};

export type CreateRadioProfileInput = {
  /** Required if band2_4GAutoChannelIsEnabled is true. */
  band2_4GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  band2_4GAutoChannelIsEnabled: Scalars['Boolean']['input'];
  band2_4GAutoChannelWidthIsEnabled: Scalars['Boolean']['input'];
  band2_4GAutoTxPowerIsEnabled: Scalars['Boolean']['input'];
  /** Required if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band2_4GAutoChannelWidthIsEnabled is false. */
  band2_4GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  band2_4GIsEnabled: Scalars['Boolean']['input'];
  /** Required if band5GAutoChannelIsEnabled is true. */
  band5GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  band5GAutoChannelIsEnabled: Scalars['Boolean']['input'];
  band5GAutoChannelWidthIsEnabled: Scalars['Boolean']['input'];
  band5GAutoTxPowerIsEnabled: Scalars['Boolean']['input'];
  /** Required if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band5GAutoChannelWidthIsEnabled is false. */
  band5GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  band5GIsEnabled: Scalars['Boolean']['input'];
  /** Setting this to true will set all other profiles to false. */
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

/** The metadata required to create radio settings for an access point. */
export type CreateRadioSettingsInput = {
  band2_4GPrimaryChannel: Scalars['Int']['input'];
  band2_4GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band2_4GTransmitPowerdBm: Scalars['Int']['input'];
  band5GPrimaryChannel: Scalars['Int']['input'];
  band5GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band5GTransmitPowerdBm: Scalars['Int']['input'];
};

export type CreateRateLimitRuleInput = {
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstMac?: InputMaybe<Scalars['MacAddress']['input']>;
  dstPortRange?: InputMaybe<PortRangeInput>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  isPerSource?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  protocol?: InputMaybe<IpProtocol>;
  rateLimitKbpsDownload?: InputMaybe<Scalars['Int']['input']>;
  rateLimitKbpsUpload?: InputMaybe<Scalars['Int']['input']>;
  srcMac?: InputMaybe<Scalars['MacAddress']['input']>;
  srcPortRange?: InputMaybe<PortRangeInput>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
};

export type CreateSsidInput = {
  /**
   * The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second.
   * If not set, will be set to a default value.
   */
  band2GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second.
   * If not set, will be set to a default value.
   */
  band5GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  clientSteeringAssociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientSteeringLoadBalancingClientMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  clientSteeringPostassociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientSteeringPreassociationRSSIMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dtimPeriod?: InputMaybe<Scalars['Int']['input']>;
  dynamicVLANMode?: InputMaybe<SsidDynamicVlanMode>;
  /** Required if isEnabledForAllAccessPoints is false. */
  enabledAccessPointVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  encryption80211wProtectedManagementFramesMode?: InputMaybe<SsidEncryption80211wProtectedManagementFramesMode>;
  encryptionProtocol?: InputMaybe<SsidEncryptionProtocol>;
  is80211axEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isBand2GAllowed: Scalars['Boolean']['input'];
  isBand5GAllowed: Scalars['Boolean']['input'];
  isEnabled: Scalars['Boolean']['input'];
  isEnabledForAllAccessPoints: Scalars['Boolean']['input'];
  isGuest?: Scalars['Boolean']['input'];
  isHidden: Scalars['Boolean']['input'];
  isIsolateClientsEnabled: Scalars['Boolean']['input'];
  isRoaming80211kEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211rEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211vEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRotationCadence?: InputMaybe<SsidPasswordRotationCadence>;
  passwordRotationDayOfMonthLocal?: InputMaybe<Scalars['Int']['input']>;
  passwordRotationDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  passwordRotationHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the primary 8021x encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  primaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  radiusCoaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** If unset, default port will be 3799 as per RFC 3576. */
  radiusCoaPort?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the secondary 8021x encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  secondaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  ssid: Scalars['String']['input'];
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateSsoDashboardForCompanyResult = {
  __typename?: 'CreateSSODashboardForCompanyResult';
  company: Company;
  dashboardURL: Scalars['String']['output'];
};

export type CreateSsoRedirectUriResult = {
  __typename?: 'CreateSSORedirectURIResult';
  uri: Scalars['String']['output'];
};

export type CreateSsoVerifyInput = {
  code: Scalars['String']['input'];
  /** @deprecated Source is no longer important. */
  source?: InputMaybe<SsoVerificationSource>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSsoVerifyResult = {
  __typename?: 'CreateSSOVerifyResult';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** If the session was created via impersonation, this field will be set. */
  impersonator?: Maybe<Impersonator>;
  lastName: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
  preview?: Maybe<Scalars['Boolean']['output']>;
  via?: Maybe<Scalars['String']['output']>;
};

export type CreateSsoOAuthUriResult = {
  __typename?: 'CreateSsoOAuthURIResult';
  googleUri: Scalars['String']['output'];
  microsoftUri: Scalars['String']['output'];
};

export type CreateStaticRouteInput = {
  dstPrefix: Scalars['IPPrefix']['input'];
  gatewayDHCPStaticMappingUUID?: InputMaybe<Scalars['UUID']['input']>;
  gatewayIP?: InputMaybe<Scalars['IPV4']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateSwitchAutoConfigMacAddressVlanBindingInput = {
  OUI?: InputMaybe<Scalars['String']['input']>;
  VLANUUID: Scalars['UUID']['input'];
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
};

export type CreateSwitchProfileInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  networkUUID: Scalars['UUID']['input'];
  stpBridgePriority?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserNetworkAccessInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  networkUUID: Scalars['UUID']['input'];
};

export type CreateVlanInput = {
  createDHCPRules?: InputMaybe<Array<CreateDhcpRuleInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ipV4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipV4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  ipV4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  ipV6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipV6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  ipV6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: Scalars['Boolean']['input'];
  isInternal?: Scalars['Boolean']['input'];
  isMulticastReflectionEnabled?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  /** @deprecated Use `networkUUID` in `createVLAN` instead. */
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  vlanID: Scalars['Int']['input'];
};

/** The metadata required to create a virtual device. */
export type CreateVirtualDeviceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  label: Scalars['String']['input'];
  radioSettings?: InputMaybe<CreateRadioSettingsInput>;
  switchProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
};

/** Create many virtual devices at once, with the same type and model. */
export type CreateVirtualDevicesInput = {
  /** How many virtual devices to create, up to 100. */
  count: Scalars['Int']['input'];
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  /** The labels of the devices will all start with the prefix, followed by an increasing suffix number (e.g. "AP" -> "AP01", "AP02", "AP03", ...) */
  labelPrefix: Scalars['String']['input'];
  /** The starting number to suffix the labels with, defaults to 1. The numbers will be zero-padded at the front. */
  labelSuffixIndex?: Scalars['Int']['input'];
};

export type CreateWifiObserverSettingInput = {
  SSIDUUID: Scalars['UUID']['input'];
  band: RadioBand;
  commands?: InputMaybe<Array<WosObserverCommandInput>>;
  durationMsec: Scalars['Int']['input'];
  numSpatialStreams?: InputMaybe<Scalars['Int']['input']>;
  password8021X?: InputMaybe<Scalars['String']['input']>;
  targetVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  username8021X?: InputMaybe<Scalars['String']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** Arbitrary DHCP option values to pass to Kea configuration that we do not yet have dedicated fields for. */
export type DhcpOption = {
  __typename?: 'DHCPOption';
  UUID: Scalars['UUID']['output'];
  /** The DHCP option code (also called tag), see https://www.iana.org/assignments/bootp-dhcp-parameters/bootp-dhcp-parameters.xhtml for a list. */
  code: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dhcpRuleUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Sub ranges within the `DHCPRule` range to reserve for static IP assignment and not issue DHCP leases within. */
export type DhcpReservedRange = {
  __typename?: 'DHCPReservedRange';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dhcpRuleUUID: Scalars['UUID']['output'];
  /** Last reserved IP address in the range, ascending and inclusive. */
  endIPAddress: Scalars['IP']['output'];
  /** First reserved IP address in the range, ascending and inclusive. */
  startIPAddress: Scalars['IP']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Stores specific information about a DHCP server for a specific interface, equivalent to a Kea 'subnet'. */
export type DhcpRule = {
  __typename?: 'DHCPRule';
  UUID: Scalars['UUID']['output'];
  applicationDNSFirewallRules: Array<ApplicationDnsFirewallRule>;
  createdAt: Scalars['DateTime']['output'];
  /** Sets if DNS caching is enabled on the DHCP server. If false, other caching options are ignored. */
  dnsCacheIsEnabled: Scalars['Boolean']['output'];
  dnsCacheMaxTTL: Scalars['Int']['output'];
  dnsCacheSize: Scalars['Int']['output'];
  dnsHostMappings: Array<DnsHostMapping>;
  dnsSearchDomains?: Maybe<Array<Scalars['String']['output']>>;
  /** Upstream DNS servers to use when `dnsUseGatewayProxy` is true, or to use directly when disabled. */
  dnsUpstreamServers: Array<Scalars['IP']['output']>;
  dnsUseGatewayProxy: Scalars['Boolean']['output'];
  /** Last available IP address in the range, ascending and inclusive. */
  endIPAddress: Scalars['IP']['output'];
  /** The IP address to send to DHCP clients (routers, DHCP option 3). */
  gatewayIPAddress: Scalars['IP']['output'];
  gatewayPrefixLength: Scalars['Int']['output'];
  isIPv6: Scalars['Boolean']['output'];
  /** The length of the DHCP lease that clients receive */
  leaseDurationSeconds: Scalars['Int']['output'];
  options: Array<DhcpOption>;
  reservedRanges: Array<DhcpReservedRange>;
  /** First available IP address in the range, ascending and inclusive. */
  startIPAddress: Scalars['IP']['output'];
  staticMappings: Array<DhcpStaticMapping>;
  updatedAt: Scalars['DateTime']['output'];
  vlan: Vlan;
  vlanUUID: Scalars['UUID']['output'];
};

/** DHCP static mappings between MAC address and IP address to instruct DHCP servers to always assign a client a predefined IP address. */
export type DhcpStaticMapping = {
  __typename?: 'DHCPStaticMapping';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dhcpRuleUUID: Scalars['UUID']['output'];
  hostname?: Maybe<Scalars['String']['output']>;
  /** Assigned IP address for client device through static DHCP. Application logic will ensure this ip is within the associated `dhcp_rule.gateway_prefix`. */
  ipAddress?: Maybe<Scalars['IP']['output']>;
  /** MAC address of the client device. */
  macAddress: Scalars['MacAddress']['output'];
  /** 'A name for use as a label for this DHCP static mapping. It has no meaning for a Meter device. */
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Predefined mappings between a domain and IP address, overriding regular DNS lookups for the given domain. */
export type DnsHostMapping = {
  __typename?: 'DNSHostMapping';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The domain to which override_domain should be resolved. If specified, this mapping acts like a CNAME record. */
  destinationDomain?: Maybe<Scalars['String']['output']>;
  /** The IP address to which override_domain should be resolved. If specified, this mapping acts like an A record. */
  destinationIPAddress?: Maybe<Scalars['IP']['output']>;
  dhcpRuleUUID: Scalars['UUID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** The domain name to override. This can be a FQDN or a domain name. */
  overrideDomain: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DeleteDeviceHeartbeatInput = {
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type DeletePermittedEmailDomainInput = {
  companySlug: Scalars['String']['input'];
  permittedEmailDomainSID: Scalars['UUID']['input'];
};

export type DeletePermittedEmailDomainResult = {
  __typename?: 'DeletePermittedEmailDomainResult';
  numRowsDeleted: Scalars['Int']['output'];
};

export type DeleteWifiObserverSettingInput = {
  SSIDUUID: Scalars['UUID']['input'];
  band: RadioBand;
  targetVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** The union of a hardware device and a virtual device. */
export type Device = {
  __typename?: 'Device';
  hardwareDevice: HardwareDevice;
  virtualDevice: VirtualDevice;
};

export type DeviceConfig = {
  __typename?: 'DeviceConfig';
  /** If null, then the device has not acknowledged this version of config. */
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
  configJSON: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type DeviceConfigOverride = {
  __typename?: 'DeviceConfigOverride';
  configJSON: Scalars['JSONObject']['output'];
};

export type DeviceHeartbeat = {
  __typename?: 'DeviceHeartbeat';
  UUID: Scalars['UUID']['output'];
  heartbeatIntervalSeconds: Scalars['Int']['output'];
  heartbeatURL: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** DeviceModel enumerates the different model names of devices that Meter deploys. */
export enum DeviceModel {
  Mc01 = 'MC01',
  Mc05 = 'MC05',
  Mc06 = 'MC06',
  Mc11 = 'MC11',
  Mp01 = 'MP01',
  Ms07 = 'MS07',
  Ms10 = 'MS10',
  Ms11 = 'MS11',
  Ms12 = 'MS12',
  Mw02 = 'MW02',
  Mw03 = 'MW03',
  Mw04 = 'MW04',
  Mw05 = 'MW05',
  Mw06 = 'MW06',
  Mw07 = 'MW07',
  Mw08 = 'MW08',
  Mw09 = 'MW09',
  Psgs1518Af = 'PSGS1518AF',
  Psgs1526F = 'PSGS1526F',
  Psgs1626Gf = 'PSGS1626GF',
  Psgs2326Kf = 'PSGS2326KF',
  Psgs2852Bf = 'PSGS2852BF',
  Psgs2852Xbf = 'PSGS2852XBF',
  Psgs2852Xbl = 'PSGS2852XBL',
}

/** DeviceType enumerates the different types of hardware devices that Meter deploys. */
export enum DeviceType {
  AccessPoint = 'ACCESS_POINT',
  Controller = 'CONTROLLER',
  PowerDistributionUnit = 'POWER_DISTRIBUTION_UNIT',
  Switch = 'SWITCH',
}

export type DevicesForNetworkFilter = {
  deviceType?: InputMaybe<VirtualDeviceType>;
};

/** A discovered service advertising via mdns (e.g. printers, airplay, etc). */
export type DiscoveredService = {
  __typename?: 'DiscoveredService';
  ipAddress: Scalars['IP']['output'];
  macAddress: Scalars['MacAddress']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  serviceType: ServiceType;
  timestamp: Scalars['DateTime']['output'];
};

export type DiscoveredServicesInput = {
  /** Number of minutes to look back for available services. */
  numMinutesLookback: Scalars['Int']['input'];
  /** List of service types to return. */
  serviceTypes: Array<ServiceType>;
};

export type DownloadUrlInput = {
  s3Key: Scalars['String']['input'];
};

/** Represents the 802.1X encryption configuration for an SSID. */
export type Encryption8021X = {
  __typename?: 'Encryption8021X';
  UUID: Scalars['UUID']['output'];
  /** The IP address of the accounting server. */
  accountingServerIPAddress?: Maybe<Scalars['IP']['output']>;
  /** The port of the accounting server. */
  accountingServerPort?: Maybe<Scalars['Int']['output']>;
  /** The shared secret used to authenticate to the accounting server. */
  accountingServerSecret?: Maybe<Scalars['String']['output']>;
  /** The IP address of the authentication server. */
  authServerIPAddress: Scalars['IP']['output'];
  /** The port of the authentication server. */
  authServerPort: Scalars['Int']['output'];
  /** The shared secret used to authenticate to the authentication server. */
  authServerSecret: Scalars['String']['output'];
  label: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The SSIDs this 802.1X encryption configuration is associated with. */
  ssids?: Maybe<Array<Ssid>>;
};

export type EventLogEvent = {
  __typename?: 'EventLogEvent';
  eventType: EventType;
  eventTypeAPIName: Scalars['String']['output'];
  generatedAt: Scalars['DateTime']['output'];
  insertedAt: Scalars['DateTime']['output'];
  networkUUID: Scalars['UUID']['output'];
  properties: EventLogProperties;
};

export type EventLogProperties = WanDownProperties | WanStatusChangeProperties | WanUpProperties;

/** Enumerate the known event types that exist in the event log. */
export enum EventType {
  Unspecified = 'UNSPECIFIED',
  WanDown = 'WAN_DOWN',
  WanStatusChange = 'WAN_STATUS_CHANGE',
  WanUp = 'WAN_UP',
}

export type FileDownloadPayload = {
  __typename?: 'FileDownloadPayload';
  presignedUrl: Scalars['String']['output'];
};

export type FileUploadPayload = {
  __typename?: 'FileUploadPayload';
  /** Format: <folder>/<date_time_stamp>_<uuid>.<extension> */
  key: Scalars['String']['output'];
  presignedUrl: Scalars['String']['output'];
};

export type FirewallPhyInterfaceBinding = {
  __typename?: 'FirewallPhyInterfaceBinding';
  metric: Scalars['Int']['output'];
  phyInterface: PhyInterface;
};

export type FirewallRule = {
  __typename?: 'FirewallRule';
  UUID: Scalars['UUID']['output'];
  action: FirewallRuleAction;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dstClientVPNServer?: Maybe<ClientVpnServer>;
  dstIPSecTunnel?: Maybe<IpSecTunnel>;
  /** @deprecated Use `dstPortRanges` instead. */
  dstPortRange: PortRange;
  dstPortRanges: Array<PortRange>;
  dstPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  dstVLAN?: Maybe<Vlan>;
  /**
   * If a firewall rule is bidirectional then the config renderer will generate an additional rule(s)
   * where the src and dst options are swapped.
   */
  isBidirectional: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isMeterInternal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  phyInterfaceBindings?: Maybe<Array<FirewallPhyInterfaceBinding>>;
  /** @deprecated Use `protocols` instead. */
  protocol: IpProtocol;
  protocols: Array<IpProtocol>;
  srcClientVPNServer?: Maybe<ClientVpnServer>;
  srcIPSecTunnel?: Maybe<IpSecTunnel>;
  /** @deprecated Use `srcPortRanges` instead. */
  srcPortRange: PortRange;
  srcPortRanges: Array<PortRange>;
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  srcVLAN?: Maybe<Vlan>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
  vlanBindings?: Maybe<Array<FirewallVlanBinding>>;
};

export enum FirewallRuleAction {
  Deny = 'DENY',
  Permit = 'PERMIT',
}

export type FirewallVlanBinding = {
  __typename?: 'FirewallVLANBinding';
  metric: Scalars['Int']['output'];
  vlan: Vlan;
};

/**
 * A floor plan is an overhead view of a physical space. A floor plan is one-to-many to floor plan locations,
 * which are x/y coordinates for Meter devices in the physical space.
 */
export type FloorPlan = {
  __typename?: 'FloorPlan';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** A pre-signed URL to download the floor plan image. */
  imageDownloadURL: Scalars['String']['output'];
  imageS3Key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GetCaptivePortalInput = {
  /** This field does not do anything; this is a placeholder for future use and will be deprecated once this input is actually used. */
  TODO?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Additional options that can be used to customize the results of a query for company users. */
export type GetCompanyUserInput = {
  /** If true, include company users that have been deleted. */
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetNotificationPreferencesInput = {
  companySlug: Scalars['String']['input'];
  controllerName: Scalars['String']['input'];
};

/** A hardware device represents a physical device. */
export type HardwareDevice = {
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  /** If the device is no longer connected to the backend, this is the time at which it was last connected. */
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** Spare hardware devices that are not affiliated with a virtual device will have this field populated. */
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A hardware device represents a physical device. */
export type HardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** Filters the response when fetching multiple hardware devices. */
export type HardwareDevicesFilter = {
  deviceModel?: InputMaybe<DeviceModel>;
  deviceType?: InputMaybe<DeviceType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum HighAvailabilityControllerRole {
  Backup = 'BACKUP',
  Primary = 'PRIMARY',
}

export type HighAvailabilityControllerVirtualDeviceInfo = {
  __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
  /** Configuration associated with the HA configuration of this virtual device. */
  config: HighAvailabilityControllersConfig;
  /** The role this device is configured to serve as. This does not indicate whether the device is currently serving traffic. */
  role: HighAvailabilityControllerRole;
  /** The status of the HA device, reported by the device. */
  status: HighAvailabilityStatus;
};

/** Configuration of the high availability controllers. */
export type HighAvailabilityControllersConfig = {
  __typename?: 'HighAvailabilityControllersConfig';
  UUID: Scalars['UUID']['output'];
  /** Interval at which the controllers advertise to one another, in milliseconds. */
  advertisementIntervalMs: Scalars['Int']['output'];
  /** Port the controllers use to communicate over. This is currently _required_ to be the same port on both controllers. */
  advertisementPort: Scalars['Int']['output'];
  /** VLAN whose sole purpose is for the HA controllers to communicate with each other. */
  advertisementVLAN: Vlan;
  /** PhyInterface for the controller configured as backup in the HA setup. */
  backupPhyInterface: PhyInterface;
  backupStatus: HighAvailabilityStatus;
  /** Network that is configured with HA controllers. */
  network: Network;
  /** Whether or not VRRP is configured for preemption between the controllers in the HA pair. */
  preemption: Scalars['Boolean']['output'];
  /** PhyInterface for the controller configured as primary in the HA setup. */
  primaryPhyInterface: PhyInterface;
  primaryStatus: HighAvailabilityStatus;
};

export enum HighAvailabilityStatus {
  Active = 'ACTIVE',
  Ready = 'READY',
  Unspecified = 'UNSPECIFIED',
}

export type HostMonitoringResponse = MetricsResponse & {
  __typename?: 'HostMonitoringResponse';
  metadata: MetricsMetadata;
  values: Array<HostMonitoringValue>;
};

export enum HostMonitoringStatus {
  Failed = 'FAILED',
  Ok = 'OK',
  Timeout = 'TIMEOUT',
}

/** Host monitoring targets are used to specify hosts for which to monitor availability / reachability on a network. */
export type HostMonitoringTarget = {
  __typename?: 'HostMonitoringTarget';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** targetHost for now is only permitted to be an IP address but will be a FQDN in the future. */
  targetHost: Scalars['IPV4']['output'];
  type: HostMonitoringType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum HostMonitoringType {
  Ping = 'PING',
  Traceroute = 'TRACEROUTE',
}

export type HostMonitoringValue = MetricsValue & {
  __typename?: 'HostMonitoringValue';
  hostIPAddr: Scalars['String']['output'];
  srcEthPortNum: Scalars['Int']['output'];
  status: HostMonitoringStatus;
  timestamp: Scalars['DateTime']['output'];
  /** value is the average RTT in nanoseconds */
  value: Scalars['Float']['output'];
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type Hs20AccessNetworkTypes = {
  __typename?: 'Hs20AccessNetworkTypes';
  description: Scalars['String']['output'];
  /** An access network type with description as defined in Table 9-214 of 802.11-2016 IEEE Standard */
  type: Scalars['Int']['output'];
};

export type Hs20AuthParam = {
  __typename?: 'Hs20AuthParam';
  description: Scalars['String']['output'];
  param: Scalars['Int']['output'];
  validValues: Array<Hs20AuthValue>;
};

/** Hs20AuthValue and Hs20AuthParam are the auth params/values as defined in Table 9-335 of 802.11-2020 IEEE Standard. */
export type Hs20AuthValue = {
  __typename?: 'Hs20AuthValue';
  description: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type Hs20EapMethod = {
  __typename?: 'Hs20EapMethod';
  description: Scalars['String']['output'];
  /**
   * An EAP method, as defined by IANA (http://www.iana.org/assignments/eap-numbers/eap-numbers.xhtml#eap-numbers-4)
   * mostUsed indicates methods that are more widely used, as a hint to the frontend
   */
  method: Scalars['Int']['output'];
  mostUsed: Scalars['Boolean']['output'];
};

export type Hs20ValidTypesResponse = {
  __typename?: 'Hs20ValidTypesResponse';
  accessNetworkTypes: Array<Hs20AccessNetworkTypes>;
  authParams: Array<Hs20AuthParam>;
  eapMethods: Array<Hs20EapMethod>;
  venueTypes: Array<Hs20VenueTypes>;
};

export type Hs20VenueTypes = {
  __typename?: 'Hs20VenueTypes';
  description: Scalars['String']['output'];
  /** A venue type with the group/type/description as defined in Table 9-62 of 802.11-2016 IEEE Standard */
  group: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
};

export enum IpProtocol {
  All = 'ALL',
  Icmp = 'ICMP',
  Tcp = 'TCP',
  Udp = 'UDP',
}

export enum IpSecAuthenticationAlgorithm {
  Cmac_96 = 'CMAC_96',
  Sha1_96 = 'SHA1_96',
  Sha2_256_128 = 'SHA2_256_128',
  Sha2_384_192 = 'SHA2_384_192',
  Sha2_512_256 = 'SHA2_512_256',
  Xcbc_96 = 'XCBC_96',
}

export enum IpSecChildSaStatus {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Deleting = 'DELETING',
  Destroying = 'DESTROYING',
  Installed = 'INSTALLED',
  Installing = 'INSTALLING',
  Rekeyed = 'REKEYED',
  Rekeying = 'REKEYING',
  Retrying = 'RETRYING',
  Routed = 'ROUTED',
  Unspecified = 'UNSPECIFIED',
  Updating = 'UPDATING',
}

export enum IpSecDhGroup {
  Dh2 = 'DH2',
  Dh14 = 'DH14',
  Dh19 = 'DH19',
  Dh20 = 'DH20',
}

export enum IpSecEncryptionAlgorithm {
  AesCbc_128 = 'AES_CBC_128',
  AesCbc_192 = 'AES_CBC_192',
  AesCbc_256 = 'AES_CBC_256',
  AesCtr_128 = 'AES_CTR_128',
  AesCtr_192 = 'AES_CTR_192',
  AesCtr_256 = 'AES_CTR_256',
  AesGcm_16128 = 'AES_GCM_16128',
  AesGcm_16192 = 'AES_GCM_16192',
  AesGcm_16256 = 'AES_GCM_16256',
  Algo_3Des_112 = 'ALGO_3DES_112',
  Algo_3Des_168 = 'ALGO_3DES_168',
}

export enum IpSecSaStatus {
  Connecting = 'CONNECTING',
  Created = 'CREATED',
  Deleting = 'DELETING',
  Destroying = 'DESTROYING',
  Established = 'ESTABLISHED',
  Passive = 'PASSIVE',
  Rekeyed = 'REKEYED',
  Rekeying = 'REKEYING',
  Unspecified = 'UNSPECIFIED',
}

/** Full documentation for IPSec tunnels are at https://libreswan.org/man/ipsec.conf.5.html */
export type IpSecTunnel = {
  __typename?: 'IPSecTunnel';
  UUID: Scalars['UUID']['output'];
  authenticationAlgorithm?: Maybe<IpSecAuthenticationAlgorithm>;
  boundPhyInterface: PhyInterface;
  boundVLANs: Array<Vlan>;
  createdAt: Scalars['DateTime']['output'];
  encryptionAlgorithm?: Maybe<IpSecEncryptionAlgorithm>;
  isEnabled: Scalars['Boolean']['output'];
  isInitiator: Scalars['Boolean']['output'];
  keyExchangeDHGroup?: Maybe<IpSecDhGroup>;
  /** How the left participant should be identified for authentication. Can be an IP address or FQDN which will be validated in application logic. If this is not set, defaults to `%defaultroute`. */
  leftID: Scalars['String']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The public IP address or DNS hostname of the right participant. Can be an IP address or FQDN which will be validated in application logic. */
  right?: Maybe<Scalars['String']['output']>;
  /** The public IP address or DNS hostname of the right participant. Can be an IP address or FQDN which will be validated in application logic. */
  rightID: Scalars['String']['output'];
  /** The private subnet behind the right participant. This is usually 0.0.0.0/0 but in some cases may be different. */
  rightPrefixes: Array<Scalars['IPPrefix']['output']>;
  status?: Maybe<IpSecTunnelStatus>;
  updatedAt: Scalars['DateTime']['output'];
};

export type IpSecTunnelStatus = {
  __typename?: 'IPSecTunnelStatus';
  /** when CHILD_SA expires */
  childLifetime: Scalars['DateTime']['output'];
  /** when CHILD_SA gets rekeyed */
  childRekeyAt: Scalars['DateTime']['output'];
  /** CHILD_SA status */
  childSAStatus: IpSecChildSaStatus;
  /** time IKE_SA got established */
  establishedAt: Scalars['DateTime']['output'];
  /** latency to the remote IKE */
  latencyMs: Scalars['Int']['output'];
  observedAt: Scalars['DateTime']['output'];
  /** time the IKE_SA gets re-authenticated */
  reauthAt: Scalars['DateTime']['output'];
  /** time IKE_SA gets rekeyed */
  rekeyAt: Scalars['DateTime']['output'];
  /** remote IKE endpoint address */
  remoteHostIP4Address: Scalars['String']['output'];
  /** number of input bytes processed */
  rxBytes: Scalars['Int']['output'];
  /** time of last inbound packet, if any */
  rxLast: Scalars['DateTime']['output'];
  /** number of input packets processed */
  rxPackets: Scalars['Int']['output'];
  /** IKA_SA state */
  saStatus: IpSecSaStatus;
  /** number of output bytes processed */
  txBytes: Scalars['Int']['output'];
  /** time of last outbound packet, if any */
  txLast: Scalars['DateTime']['output'];
  /** IKE_SA unique identifier */
  uniqueID: Scalars['String']['output'];
};

/** Category of service provided by the ISP connection. */
export type IspConnectionType = {
  __typename?: 'ISPConnectionType';
  UUID: Scalars['UUID']['output'];
  /** Name of the ISP connection type. Examples: "Dedicated Fiber", "Shared Fiber", "Coax", "Point to Point Wireless", etc. */
  name: Scalars['String']['output'];
};

/** The category of service provided by the ISP. */
export enum IspProduct {
  /** Business cable connection */
  BusinessCable = 'BUSINESS_CABLE',
  /** Dedicated fiber optic connection */
  DedicatedFiber = 'DEDICATED_FIBER',
  /** Point-to-point wireless connection */
  PointToPointWireless = 'POINT_TO_POINT_WIRELESS',
  /** Residential cable connection */
  ResidentialCable = 'RESIDENTIAL_CABLE',
  /** Residential fiber optic connection */
  ResidentialFiber = 'RESIDENTIAL_FIBER',
  /** Shared fiber optic connection */
  SharedFiber = 'SHARED_FIBER',
}

/** Whether the ISP is intended to function as the primary or backup connection. */
export enum IspStatus {
  /** The ISP is configured as the fallback ISP connection. */
  Backup = 'BACKUP',
  /** The ISP is configured as the preferred ISP connection. */
  Primary = 'PRIMARY',
}

export enum ImageType {
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Png = 'PNG',
  Svg = 'SVG',
}

export type Impersonator = {
  __typename?: 'Impersonator';
  email: Scalars['String']['output'];
};

/** Incidents are deprecated. */
export type Incident = {
  __typename?: 'Incident';
  UUID: Scalars['UUID']['output'];
  category?: Maybe<IncidentCategory>;
  endTime: Scalars['DateTime']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
};

export enum IncidentCategory {
  Deinstallation = 'DEINSTALLATION',
  Installation = 'INSTALLATION',
  Maintenance = 'MAINTENANCE',
  Outage = 'OUTAGE',
  Provisioning = 'PROVISIONING',
  Scheduled = 'SCHEDULED',
}

/** Provides the ability for communication between specified VLANs. */
export type InterVlanCommunicationPermittedPair = {
  __typename?: 'InterVLANCommunicationPermittedPair';
  UUID: Scalars['UUID']['output'];
  higherVLAN: Vlan;
  lowerVLAN: Vlan;
  networkUUID: Scalars['UUID']['output'];
};

/** An internal note is a note for Meter teams to share information about a network. */
export type InternalNote = {
  __typename?: 'InternalNote';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The email address of the user who created the note. */
  createdBy: Scalars['String']['output'];
  /** The user who created the note. */
  createdByUser: User;
  /** Important notes can be highlighted in the UI. */
  isImportant: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  note: Scalars['String']['output'];
  photoS3Keys?: Maybe<Array<Scalars['String']['output']>>;
  /** URLs for photos attached to the note. */
  photoURLs?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The email address of the user who updated the note most recently. */
  updatedBy?: Maybe<Scalars['String']['output']>;
  /** The user who updated the note most recently. */
  updatedByUser?: Maybe<User>;
};

/**
 * InternetServicePlan represents the configuration associated with an Internet Service Provider (ISP). This includes
 * information about the provider itself, as well as the IP address configurations for the network.
 */
export type InternetServicePlan = {
  __typename?: 'InternetServicePlan';
  UUID: Scalars['UUID']['output'];
  address?: Maybe<Address>;
  connectionProduct: IspProduct;
  connectionStatus: IspStatus;
  /**
   * ControllerIP is dynamically resolved from stats coming in to Clickhouse. These stats are reported
   * by the controller.
   */
  controllerIP?: Maybe<Scalars['IPV4']['output']>;
  controllerName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  firstUsableIP?: Maybe<Scalars['IPV4']['output']>;
  gatewayAddr?: Maybe<Scalars['IPV4']['output']>;
  hasStaticIP: Scalars['Boolean']['output'];
  lastUsableIP?: Maybe<Scalars['IPV4']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  provider?: Maybe<InternetServicePlanProvider>;
  staticIPRange?: Maybe<Scalars['IPPrefix']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type InternetServicePlanConnectionInput = {
  product: IspProduct;
  status: IspStatus;
};

export type InternetServicePlanIpInput = {
  controllerIP?: InputMaybe<Scalars['IPV4']['input']>;
  controllerName?: InputMaybe<Scalars['String']['input']>;
  firstUsableIP?: InputMaybe<Scalars['IPV4']['input']>;
  gatewayAddr?: InputMaybe<Scalars['IPV4']['input']>;
  lastUsableIP?: InputMaybe<Scalars['IPV4']['input']>;
  staticIPRange?: InputMaybe<Scalars['IPPrefix']['input']>;
};

/** InternetServicePlanProvider represents the Internet Service Provider (ISP) that services an InternetServicePlan. */
export type InternetServicePlanProvider = {
  __typename?: 'InternetServicePlanProvider';
  UUID: Scalars['UUID']['output'];
  /** The URL of the provider's logo. */
  logoURL?: Maybe<Scalars['String']['output']>;
  /** The name of the provider. */
  name: Scalars['String']['output'];
};

export type LatestDeviceCpuLoad = {
  __typename?: 'LatestDeviceCPULoad';
  timestamp: Scalars['DateTime']['output'];
  /**
   * The used CPU percentage represented as a value [0, 1]. This value is computed by adding the latest
   * percentages of user, system, soft_irq, and irq.
   */
  usedPercentage: Scalars['Float']['output'];
};

export type LatestDeviceMemoryUsage = {
  __typename?: 'LatestDeviceMemoryUsage';
  bytesAvailable: Scalars['Int']['output'];
  bytesTotal: Scalars['Int']['output'];
  bytesUsed: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
};

/** Allows users to give a human-friendly name to a MAC address. */
export type MacAddressAlias = {
  __typename?: 'MACAddressAlias';
  MACAddress: Scalars['MacAddress']['output'];
  alias: Scalars['String']['output'];
  companySlug: Scalars['String']['output'];
};

/** The Switch and PhyInterface that a client MAC address is associated with. */
export type MacAddressSwitchEntry = {
  __typename?: 'MACAddressSwitchEntry';
  phyInterface: PhyInterface;
  virtualDevice: VirtualDevice;
};

export type MailingAddress = {
  __typename?: 'MailingAddress';
  UUID: Scalars['UUID']['output'];
  city?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Coordinates>;
  countryISO2?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  subdivisionCode?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Defines a periodic window of time during which customers prefer that maintenance be performed on a network. */
export type MaintenanceWindow = {
  __typename?: 'MaintenanceWindow';
  UUID: Scalars['UUID']['output'];
  endDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  endHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  /** For DAILY recurrence windows only, allows for the exclusion of specific days of the week. */
  excludedDaysOfWeek?: Maybe<Array<Scalars['Int']['output']>>;
  isOptedOut: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  recurrence: MaintenanceWindowRecurrence;
  startDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  startHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export enum MaintenanceWindowRecurrence {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
}

export type MetricsFilterInput = {
  durationSeconds: Scalars['Int']['input'];
  /** If not provided, endTime defaults to now. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  stepSeconds: Scalars['Int']['input'];
};

export type MetricsMetadata = {
  __typename?: 'MetricsMetadata';
  maxValue: Scalars['Float']['output'];
  minValue: Scalars['Float']['output'];
};

export type MetricsResponse = {
  metadata?: Maybe<MetricsMetadata>;
  values: Array<MetricsValue>;
};

export type MetricsSerialNumberDirectionInput = {
  /** Specify which traffic directions are queried for. */
  directions: Array<TrafficDirection>;
  serialNumber: Scalars['String']['input'];
};

export type MetricsValue = {
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type MintTokenInput = {
  /** The permissions that this token should contain. Currently only `INVENTORY_APP` is valid. */
  permissions: Array<Permission>;
  /** The principal name for the token. For devices, the serial number. For machines, the machine name. For users, the user name. */
  principalName: Scalars['String']['input'];
  /** The principal type for the token. Currently only `MACHINE` is valid. */
  principalType: PrincipalType;
  /** How long from now the token is valid. If null, the token does not expire. */
  validityDuration?: InputMaybe<Scalars['Duration']['input']>;
};

export type MintedToken = {
  __typename?: 'MintedToken';
  encoded: Scalars['String']['output'];
  epoch: Scalars['String']['output'];
  permissions: Array<Permission>;
  principal: TokenPrincipal;
  realm: Scalars['String']['output'];
  tokenId: Scalars['String']['output'];
  tokenType: TokenType;
};

export enum MultiWanAlgorithm {
  FirstActive = 'FIRST_ACTIVE',
  RoundRobin = 'ROUND_ROBIN',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Re-activates a previously deleted company user, allowing them to now login and access the dashboard for that company as before. */
  activateCompanyUser: CompanyUser;
  /** Activates a Twilio Cellular SIM card. */
  activateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  addNetworkToAutoVPNGroup: AutoVpnMember;
  addNetworksToAutoVPNGroup: Array<AutoVpnMember>;
  /**
   * This assigns hardware to any virtual device in a network that matches the same type/model as the hardware.
   * Not to be confused with assignSpareHardwareDeviceToNetwork.
   * If the hardware was already associated to the network as a spare, then it will be removed from the spare list.
   */
  assignHardwareDeviceToNetwork: HardwareDevice;
  /**
   * This does not assign the hardware to a virtual device, but rather to the network as a spare device.
   * Need to call assignHardwareDeviceToVirtualDevice / assignHardwareDeviceToNetwork to actually put the hardware to use.
   */
  assignHardwareDeviceToNetworkAsSpare: HardwareDevice;
  /**
   * This assigns hardware to a specific virtual device.
   * Not to be confused with assignSpareHardwareDeviceToNetwork.
   * If the hardware was already associated to the network as a spare, then it will be removed from the spare list.
   */
  assignHardwareDeviceToVirtualDevice: HardwareDevice;
  /**
   * This assigns hardware to specific virtual devices, respectively.
   * If any hardware were already associated to the network as a spare, then they will be removed from the spare list.
   */
  assignHardwareDevicesToVirtualDevices: Array<HardwareDevice>;
  /** Assigns the role of operator to a the user represented by this company user. */
  assignOperatorRole: CompanyUser;
  /** Delete a switch profile. */
  assignSwitchProfleToVirtualDevice: Scalars['Boolean']['output'];
  /** Add a device to a rack elevation. */
  attachDeviceToRackElevation: RackElevation;
  /** Attaches an AP to a radio profile so that the AP can inherit the radio settings. */
  attachVirtualDeviceToRadioProfile: VirtualDevice;
  /** Binds a firewall rule to a phy interface with a given metric, subsequent calls act as an upsert. */
  bindFirewallRuleToPhyInterface: FirewallRule;
  /** Binds a firewall rule to a VLAN with a given metric, subsequent calls act as an upsert. */
  bindFirewallRuleToVLAN: FirewallRule;
  /**
   * Binds firewall rules to a phy interface, assigning metrics based on the UUID's order in `orderedRuleUUIDs`.
   * Any existing bound rules not present in `orderedRuleUUIDs` will be unbound.
   */
  bindFirewallRulesToPhyInterface: Array<FirewallPhyInterfaceBinding>;
  /**
   * Binds firewall rules to a VLAN, assigning metrics based on the UUID's order in `orderedRuleUUIDs`.
   * Any existing bound rules not present in `orderedRuleUUIDs` will be unbound.
   */
  bindFirewallRulesToVLAN: Array<FirewallVlanBinding>;
  bindPolicyRoutingRule: PolicyRoutingRule;
  /**
   * Binds `input` to `orderedRuleUUIDs` assigning metrics based on the order in `orderedRuleUUIDs`.
   * Any existing bound rules to `input` not present in `orderedRuleUUIDs` will be unbound.
   */
  bindPolicyRoutingRules: Array<PolicyRoutingRuleBinding>;
  /** Attach a rate limit rule to a physical interface (port). */
  bindRateLimitRuleToPhyInterface: RateLimitRule;
  /** Attach a rate limit rule to a VLAN. */
  bindRateLimitRuleToVLAN: RateLimitRule;
  /** Bind a VLAN to a physical interface to permit traffic for the VLAN to flow through the port. */
  bindVLANtoPhyInterface: Scalars['Boolean']['output'];
  /** Cancel a schedueld Auto Switch Configuration job that hasn't run yet. */
  cancelAutoSwitchConfigurationJob: SwitchAutoConfigurationJob;
  /**
   * User facing mutation that performs the full flow of doing the following:
   * 1. Enables SSO for the company, if not enabled.
   * 2. Creates a permitted email domain from the user's email, if not already created.
   * 3. Generates an admin portal URI for configuring SSO for this organization.
   */
  configureSSO: ConfigureSsoResult;
  /** A utility method used in the Config 1 -> Config 2 migration process to copy the access point's config 1 location names to the config 2 labels. */
  copyAccessPointConfig1LocationsToConfig2Labels: Array<VirtualDevice>;
  /** A config1->config2 migration utility to copy all DNS host mappings from one DHCP rule to another. */
  copyDNSHostMappingsFromConfig1ToConfig2: Array<DnsHostMapping>;
  /** A config1->config2 migration utility to copy all firewall rules. */
  copyFirewallRulesFromConfig1ToConfig2: Array<FirewallRule>;
  /** A config1->config2 migration helper for IPSec tunnels. */
  copyIPSecTunnelFromConfig1ToConfig2: IpSecTunnel;
  /** Syncs inter-VLAN communication with Config 1 controller config. This will remove any existing Config 2 inter-VLAN communication settings. */
  copyInterVLANCommunicationFromConfig1ToConfig2: Array<InterVlanCommunicationPermittedPair>;
  /**
   * Copies any IP configurations that existed only in the Config1 configuration to InternetServicePlan
   * fields which will be used in Config2.
   */
  copyInternetServicePlanIPsFromConfigToConfig2: Array<InternetServicePlan>;
  /** A config1->config2 migration helper. Copies the multiWAN algorithm from one network to another. */
  copyNetworkMultiWANAlgorithmFromConfig1ToConfig2: Network;
  /** A config1 -> config2 migration helper to copy port forwarding rules. */
  copyPortForwardingRulesFromConfig1ToConfig2: Array<PortForwardingRule>;
  /** A config 1 -> config 2 migration function that copies radio settings. */
  copyRadioSettingsForNetworkFromConfig1: Array<RadioSettings>;
  /** Copies any SSIDs that are not in the Config 2 SSIDs table from Config 1 and returns the newly created SSIDs. */
  copySSIDsFromConfig1ToConfig2: Array<Ssid>;
  /** A config1 -> config2 migration helper to copy uplink phy interfaces. */
  copyUplinkPhyInterfacesFromConfig1ToConfig2: Array<PhyInterface>;
  /** Copies any VLANs that are not in the Config 2 VLANs table from Config 1 and returns the newly created VLANs. */
  copyVLANsFromConfig1ToConfig2: Array<Vlan>;
  /** Creates an alert receiver and mappings between an alert receiver and one or more alert targets. Alert targets must be predefined unless the alert target type is EMAIL. In that case, the email address must be provided and alertTargetUUID can be left empty. An email target will be created automatically. */
  createAlertReceiverWithTargets: AlertReceiver;
  /** Create a new webhook alert target to notify users via webhook of network events. */
  createAlertTargetWebhook: AlertTargetWebhook;
  /** Creates a new DNS firewall rule for a DHCP rule. */
  createApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  createAutoVPNGroup: AutoVpnGroup;
  createAutoVPNRoute: AutoVpnRoute;
  createBearerSession: BearerSession;
  /** Update the NOS firmware version for a group of networks. */
  createBulkNOSUpgrade: BulkNosUpgrade;
  createCaptivePortal: CaptivePortal;
  createClientVPNClient: ClientVpnClient;
  createClientVPNServer: ClientVpnServer;
  /** Create a new internal-only company. Customer companies cannot be created; they are created by the Salesforce -> Backend data sync process. */
  createCompany: Company;
  /** Creates a company user with the specified fields. If a user with the specified email already exists, this will be a no-op. */
  createCompanyUser: CompanyUser;
  /** Create a DHCP option. */
  createDHCPOption: DhcpOption;
  /** Create a DHCP reserved range. */
  createDHCPReservedRange: DhcpReservedRange;
  /** Create a DHCP rule. */
  createDHCPRule: DhcpRule;
  /** Create a DHCP static mapping. */
  createDHCPStaticMapping: DhcpStaticMapping;
  /** Create a DNS host mapping. */
  createDNSHostMapping: DnsHostMapping;
  /** Invoke this method in dev to create a new device in the dev realm. This is done before invoking `setHardwareDeviceIsDev` above. */
  createDevHardwareDevice: HardwareDevice;
  createDeviceHeartbeat: DeviceHeartbeat;
  createEncryption8021X: Encryption8021X;
  /** Create a new firewall rule. */
  createFirewallRule: FirewallRule;
  /** Create a new floor plan. */
  createFloorPlan: FloorPlan;
  /** Combine two controllers on the same network into an HA pair using VRRP. */
  createHighAvailabilityControllersConfiguration: HighAvailabilityControllersConfig;
  /** Create a new host monitoring target. */
  createHostMonitoringTarget: HostMonitoringTarget;
  /** Create a new IPSec tunnel. */
  createIPSecTunnel: IpSecTunnel;
  /** Create a new internal note. */
  createInternalNote: InternalNote;
  /** Creates a new InternetServicePlan for the given network. */
  createInternetServicePlan: InternetServicePlan;
  /**
   * This is a deprecated mutation.
   * @deprecated We no longer support direct creation of mailing addresses.
   */
  createMailingAddress: MailingAddress;
  /** Create an internal network for a internal company. Users cannot create customer networks under customer companies; they are created by the Salesforce -> Backend data sync process. */
  createNetwork: Network;
  /**
   * Create a new network by copying the configuration from an existing network.
   * The network belongs to the same company as the source network; the mailing address/label/slug can be overridden (else they default to the same as the source network).
   * Doesn't copy any hardware or other network-specific data, like event logs, clients, ISP info, etc.
   * Once a network is copied, it is a completely separate entity from the source network.
   */
  createNetworkFromCopy: Network;
  /** Create a new network onboarding document. */
  createNetworkOnboarding: NetworkOnboarding;
  /** Create a new NOS feature. */
  createNosFeature: NosFeature;
  /** Create a new NOS version. */
  createNosVersion: NosVersion;
  /** Create a new notification setting. */
  createNotificationSetting: NotificationSetting;
  /** Create a new 1:1 NAT rule. */
  createOneToOneNATRule: OneToOneNatRule;
  /** Create a new permitted email domain for a company. This feature is largely out-of-use in favor of SSO. */
  createPermittedEmailDomain: PermittedEmailDomain;
  createPolicyRoutingRule: PolicyRoutingRule;
  /** Create a port forwarding rule for a network. */
  createPortForwardingRule: PortForwardingRule;
  /** Generates a pre-signed URL for uploading a file to S3. */
  createPreSignedUploadUrl: FileUploadPayload;
  /** Create a rack elevation for a network. */
  createRackElevation: RackElevation;
  /** Create a radio profile for a network. */
  createRadioProfile: RadioProfile;
  /** Create a rate limit rule for a network. */
  createRateLimitRule: RateLimitRule;
  /** Create an SSID for a network. */
  createSSID: Ssid;
  /** Create a WorkOS admin portal link for the specified company and purpose. */
  createSSOAdminPortalLink: ConfigureSsoResult;
  /**
   * Create a SSO dashboard for the specified company.
   * @deprecated Field no longer supported
   */
  createSSODashboardForCompany: CreateSsoDashboardForCompanyResult;
  /**
   * Create a redirect URI to use to login to WorkOS. Prefer to use login.meter.* instead.
   * @deprecated Field no longer supported
   */
  createSSORedirectURI: CreateSsoRedirectUriResult;
  /** Verify that the token provided via the WorkOS callback is valid. */
  createSSOVerify: CreateSsoVerifyResult;
  createSsoOAuthURI: CreateSsoOAuthUriResult;
  /** Create a new static route. */
  createStaticRoute: StaticRoute;
  /** Bind MAC addresses or OUIs to VLANs before running Switch Auto Config. */
  createSwitchAutoConfigMACAddressVLANBindings: Array<SwitchAutoConfigMacAddressVlanBinding>;
  /** Create a switch profile. */
  createSwitchProfile: SwitchProfile;
  /** Provides an internal Operator user access to a network to provide elevated permissions like SSH access. */
  createUserNetworkAccess: Scalars['Boolean']['output'];
  /** Create a new VLAN. */
  createVLAN: Vlan;
  /** Create a virtual device. */
  createVirtualDevice: VirtualDevice;
  /** Create many virtual devices of the same type and model. */
  createVirtualDevices: Array<VirtualDevice>;
  /** Create a new Wifi Observer Setting. */
  createWifiObserverSetting: WifiObserverSetting;
  /**
   * Careful! Please only do this if you know what you are doing.
   * This does the same thing as `createNetworkFromCopy`, but it allows you to create a new network for any company.
   * This can obviously be quite dangerous and could leak one company's configuration to another, so be careful.
   */
  dangerouslyCreateNetworkForNewCompanyFromCopy: Network;
  /** Deactivates a Twilio Cellular SIM card. */
  deactivateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  /** Deletes an alert receiver and all of its targets. */
  deleteAlertReceiverWithTargets: AlertReceiver;
  deleteAlertTargetWebhook: AlertTargetWebhook;
  deleteApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Deleting an auto VPN group deletes all group members. */
  deleteAutoVPNGroup: AutoVpnGroup;
  deleteAutoVPNRoute: AutoVpnRoute;
  /** Cannot delete a bulk upgrade that has non-PENDING scheduled nos upgrades. */
  deleteBulkNOSUpgrade: BulkNosUpgrade;
  deleteCaptivePortal: CaptivePortal;
  deleteClientVPNClient: Scalars['Boolean']['output'];
  deleteClientVPNServer: Scalars['Boolean']['output'];
  /** Deletes the specified company user, preventing them from logging in and accessing the dashboard for that company. */
  deleteCompanyUser: CompanyUser;
  /** Delete a DHCP option. */
  deleteDHCPOption: DhcpOption;
  /** Delete a DHCP reserved range. */
  deleteDHCPReservedRange: DhcpReservedRange;
  /** Delete a DHCP rule. */
  deleteDHCPRule: DhcpRule;
  /** Delete a DHCP static mapping. */
  deleteDHCPStaticMapping: DhcpStaticMapping;
  /** Delete a DNS host mapping. */
  deleteDNSHostMapping: DnsHostMapping;
  deleteDeviceHeartbeat: Scalars['Boolean']['output'];
  deleteEncryption8021X: Encryption8021X;
  /** Delete a firewall rule. */
  deleteFirewallRule: FirewallRule;
  /** Delete a floor plan. */
  deleteFloorPlan: FloorPlan;
  /** Delete the HA configuration for the given network. */
  deleteHighAvailabilityControllersConfiguration: HighAvailabilityControllersConfig;
  /** Delete a host monitoring target. */
  deleteHostMonitoringTarget: HostMonitoringTarget;
  /** Delete an IPSec tunnel. */
  deleteIPSecTunnel: IpSecTunnel;
  /** Delete an internal note. */
  deleteInternalNote: InternalNote;
  /** Deletes an existing InternetServicePlan. */
  deleteInternetServicePlan: Scalars['Boolean']['output'];
  /** Mark a network as soft-deleted. Customer networks cannot be deleted. Careful, this is a very dangerous operation! */
  deleteNetwork: Network;
  /** Delete a network onboarding document. */
  deleteNetworkOnboardingDocument: NetworkOnboardingDocument;
  /** Delete a field delegation. */
  deleteNetworkOnboardingFieldDelegation: NetworkOnboardingFieldDelegation;
  /** @deprecated No longer supported. */
  deleteNotificationPreference: NotificationPreferences;
  /** Delete a notification setting. */
  deleteNotificationSetting: NotificationSetting;
  /** Delete a 1:1 NAT rule. */
  deleteOneToOneNATRule: OneToOneNatRule;
  /** Delete a permitted email domain for a company. */
  deletePermittedEmailDomain: DeletePermittedEmailDomainResult;
  deletePolicyRoutingRule: PolicyRoutingRule;
  /**
   * Delete a port forwarding rule.
   * @deprecated Use deletePortForwardingRules instead
   */
  deletePortForwardingRule: PortForwardingRule;
  /** Delete multiple port forwarding rules. */
  deletePortForwardingRules: Array<PortForwardingRule>;
  /** Delete a rack elevation. */
  deleteRackElevation: Scalars['Boolean']['output'];
  /** Delete a radio profile. */
  deleteRadioProfile: RadioProfile;
  /** Delete a rate limit rule. */
  deleteRateLimitRule: RateLimitRule;
  /** Delete an SSID. */
  deleteSSID: Ssid;
  /** Delete a static route. */
  deleteStaticRoute: StaticRoute;
  /** Un-bind a MAC address or OUI from a VLAN before running Switch Auto Config. */
  deleteSwitchAutoConfigMACAddressVLANBinding: SwitchAutoConfigMacAddressVlanBinding;
  /** Delete a VLAN. */
  deleteVLAN: Scalars['Boolean']['output'];
  /** Delete a virtual device and all associated entities. Be sure you know what you're doing, this is a hard deletion! Returns an error if the virtual device is assigned to hardware. */
  deleteVirtualDevice: VirtualDevice;
  /** Delete multiple virtual devices and all associated entities. Be sure you know what you're doing! Returns an error if any of the virtual devices are assigned to hardware. */
  deleteVirtualDevices: Array<VirtualDevice>;
  /** Delete a Wifi Observer Setting. */
  deleteWifiObserverSetting: WifiObserverSetting;
  /** Remove a device from a rack elevation. */
  detachDeviceFromRackElevation: Scalars['Boolean']['output'];
  /** Detaches an AP from a radio profile. The AP will inherit its network's default radio profile settings. */
  detachVirtualDeviceFromRadioProfile: VirtualDevice;
  /** Specify a reason for why a mutation was made. */
  editMutationAuditLogEntryReason: Scalars['Boolean']['output'];
  enableSSOForCompany: Company;
  /**
   * Force a sync of the Salesforce -> Backend data sync process for all existing companies.
   * Used when backfilling a new synced field that needs to be populated for all companies.
   * Returns the River Job ID.
   * This should only be invoked by engineers who know what they are doing.
   */
  forceSalesforceSyncCompanies: Scalars['Int']['output'];
  /**
   * Force a sync of the Salesforce -> Backend data sync process for all existing networks and their mailing addresses.
   * Used when backfilling a new synced field that needs to be populated for all networks.
   * Returns the River Job ID.
   * This should only be invoked by engineers who know what they are doing.
   */
  forceSalesforceSyncNetworks: Scalars['Int']['output'];
  /**
   * Kicks off a job to run Auto Channel Selection for a single AP.
   * The process takes some time, so a jobUUID is returned to track the progress.
   */
  initializeAutoChannelSelectionForAccessPoint: AutoChannelSelectionJob;
  /**
   * Kicks off a job to run Auto Channel Selection for all APs in a network.
   * The process takes some time, so a jobUUID is returned to track the progress.
   */
  initializeAutoChannelSelectionForNetwork: AutoChannelSelectionJob;
  /** Kicks off a job to run Auto Switch Configuration for a network. */
  initializeAutoSwitchConfigurationForNetwork: SwitchAutoConfigurationJob;
  /** Add fake stats to a demo company for sales demonstration purposes. */
  insertStatsForDemo: Scalars['Boolean']['output'];
  /** Create a new authentication token. */
  mintAuthenticationToken: MintedToken;
  /** A utility method used for testing purposes to downgrade a device to Config 1; should not be used for customer devices, be very careful. */
  moveDeviceFromConfig2ToConfig1: Scalars['Boolean']['output'];
  /** Moves an internet service plan to a given network. The network must belong to the company currently associated with the ISP. */
  moveInternetServicePlanToNetwork: InternetServicePlan;
  /** Always returns true and does nothing. */
  noop: Scalars['Boolean']['output'];
  /** Pins the config for a device to the version at the given timestamp. Be very careful, use only in outage situations! */
  pinDeviceConfigsForNetwork: Network;
  /** Be very careful, use only in outage situations! Only pins networks that are not already pinned. */
  pinUnpinnedDeviceConfigsGlobally: Array<Network>;
  /** Reactivates a Twilio Cellular SIM card. */
  reactivateDeviceTwilioCellular?: Maybe<TwilioCellularActivationResponse>;
  removeNetworkFromAutoVPNGroup: Scalars['Boolean']['output'];
  removeNetworksFromAutoVPNGroup: Scalars['Boolean']['output'];
  /** Remove networks that were previously added to a pending NOS upgrade. */
  removeNetworksFromBulkNOSUpgrade: BulkNosUpgrade;
  /** Removes the role of operator from the user represented by this company user. */
  removeOperatorRole: CompanyUser;
  /**
   * Invoke a rendering of the latest config for a device.
   * Does the same thing as the device-facing `GET /v2/devices/:serial_number/config endpoint.
   */
  renderDeviceConfig: DeviceConfig;
  /** Revoke an existing authentication token. */
  revokeAuthenticationToken: AuthenticationToken;
  /** Rotates HMAC secret for a given captive portal. */
  rotateCaptivePortalSecret: CaptivePortal;
  /** Blink the LEDs on a device. */
  rpcBlinkLEDs: Scalars['Boolean']['output'];
  /** Instruct a device to clear its list of configs that were marked as bad */
  rpcClearBlockedConfigs: Scalars['Boolean']['output'];
  /** Cycle an outlet on a power distribution unit. */
  rpcPDUOutletCycle: Scalars['Boolean']['output'];
  /** Instruct a device to ping out to a host */
  rpcPing: RpcPingResponse;
  /** Instruct a device to run a cable test on a port. */
  rpcPortCableTest: RpcCableTestResponse;
  /** Reboot a device. */
  rpcRebootDevice: Scalars['Boolean']['output'];
  /** Instruct a device to pull its latest config from the backend. */
  rpcRefreshConfig: Scalars['Boolean']['output'];
  /** Instruct a device to recreate a specified IPSec tunnel. */
  rpcRestartIPSecTunnel: Scalars['Boolean']['output'];
  /** Cycle a port on a switch. */
  rpcSwitchPortCycle: Scalars['Boolean']['output'];
  /**
   * Instruct a device to run a WAN speedtest.
   * @deprecated Unused due to issues with implementation. Use startWANSpeedtestJob on config 2 networks.
   */
  rpcWANSpeedtest: RpcwanSpeedtestResponse;
  /** Disconnect a client from an AP. */
  rpcWosDisconnectClient: Scalars['Boolean']['output'];
  /** Instruct a device to run an EAPOL test to check RADIUS connectivity. */
  rpcWosEapolTestForSerial: RpcEapolTestForSerialResponse;
  /** Instruct devices to run an EAPOL test to check RADIUS connectivity. */
  rpcWosEapolTestForSerials: Array<RpcEapolTestForSerialResponse>;
  /** Instruct devices to run/get results for an Observer test. */
  rpcWosObserver: RpcWosObserverResponse;
  /** Instruct APs to start a packet capture on specified monitor interfaces. */
  rpcWosPacketCaptureStart: RpcWosPacketCaptureResponse;
  /** Instruct APs to stop a packet capture on specified monitor interfaces. */
  rpcWosPacketCaptureStop: RpcWosPacketCaptureResponse;
  /** Reset the radio for this band. */
  rpcWosRadioReset: Scalars['Boolean']['output'];
  /** Reset DFS channels on an AP */
  rpcWosResetDfs: Scalars['Boolean']['output'];
  /** Invoke this method in prod to route a device to the dev realm. This clears the registration info and requires the device to re-register. */
  setHardwareDeviceIsDev: Scalars['Boolean']['output'];
  /** Set the NOS version for a network, which applies a firmware upgrade/downgrade to all devices. */
  setNetworkNosVersion: Scalars['Boolean']['output'];
  /**
   * Binds a policy routing rule to `input` assigning metrics based on the order in `input`.
   * Any existing bound rules not present in `input` will be unbound.
   */
  setPolicyRoutingRuleBindings: Array<PolicyRoutingRuleBinding>;
  /** Sign a console login challenge, allowing the user to log in to the device. */
  signConsoleLoginChallenge: SignedConsoleLoginChallenge;
  /** Begin WAN speedtest with the given input. */
  startWANSpeedtestJob: WanSpeedtestJob;
  /**
   * Test triggering is for testing with the CLI. It is not to intended for use by the frontend.
   * The API is unstable and may change or be removed at any time.
   * @deprecated Internal use only
   */
  testTriggerWebhookAlertTarget: AlertTargetWebhook;
  /** Unassign the given hardware from its virtual device, if any. */
  unassignHardwareDeviceFromVirtualDevice: HardwareDevice;
  /** Unassigns hardware from the list of virtual devices. Be sure you know what you're doing! */
  unassignHardwareFromVirtualDevices: Array<VirtualDevice>;
  /** Removes the association of an internet service plan from any network it was previously assigned to. */
  unassignInternetServicePlanFromNetwork: InternetServicePlan;
  /** Unassign the hardware from the network as a spare device. */
  unassignSpareHardwareDeviceFromNetwork: HardwareDevice;
  unbindFirewallRuleFromPhyInterface: FirewallRule;
  unbindFirewallRuleFromVLAN: FirewallRule;
  unbindPolicyRoutingRule: PolicyRoutingRule;
  /** Detach a rate limit rule from a physical interface (port). */
  unbindRateLimitRuleFromPhyInterface: RateLimitRule;
  /** Detach a rate limit rule from a VLAN. */
  unbindRateLimitRuleFromVLAN: RateLimitRule;
  /** Un-soft-delete a network. Should only be used in special circumstances. */
  undeleteNetwork: Network;
  unpinDeviceConfigsForNetwork: Network;
  /** Be very careful, use only in outage situations! Only unpins networks that are pinned by the given maxCreatedAt datetime (to avoid unpinning networks that were pinned by other processes). */
  unpinDeviceConfigsGlobally: Array<Network>;
  /** Invoke this method in prod to route a dev device back to the prod realm. This clears the registration info and requires the device to re-register. */
  unsetHardwareDeviceIsDev: Scalars['Boolean']['output'];
  /** Updates an alert receiver and its targets. */
  updateAlertReceiverWithTargets: AlertReceiver;
  /** Update an existing webhook alert target. */
  updateAlertTargetWebhook: AlertTargetWebhook;
  /** Updates an existing DNS firewall rule. */
  updateApplicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Updates the visibility of applications for DNS firewall rules. */
  updateApplicationDNSFirewallRuleApplicationVisibilities: Array<
    Maybe<ApplicationDnsFirewallRuleApplication>
  >;
  /**
   * Updates a list of rules to the specified priorities. Returns all updated rules.
   * Rules must be associated with the same network and DHCP rule.
   * If any provided priority is already taken, the request fails.
   */
  updateApplicationDNSFirewallRulesPriorities: Array<ApplicationDnsFirewallRule>;
  updateAutoVPNGroup: AutoVpnGroup;
  updateAutoVPNMember: AutoVpnMember;
  updateAutoVPNRoute: AutoVpnRoute;
  updateCaptivePortal: CaptivePortal;
  updateClientVPNServer: ClientVpnServer;
  /** Update a limited set of fields on a company. Many fields are owned by Salesforce and cannot be updated via this endpoint. */
  updateCompany: Company;
  /** Updates the specified company user with the provided fields. Note that the email field cannot be updated. */
  updateCompanyUser: CompanyUser;
  /** Update a DHCP option. */
  updateDHCPOption: DhcpOption;
  /** Update a DHCP reserved range. */
  updateDHCPReservedRange: DhcpReservedRange;
  /** Update a DHCP rule. */
  updateDHCPRule: DhcpRule;
  /** Update a DHCP static mapping. */
  updateDHCPStaticMapping: DhcpStaticMapping;
  /** Update a DNS host mapping. */
  updateDNSHostMapping: DnsHostMapping;
  updateEncryption8021X: Encryption8021X;
  /** Update a firewall rule. */
  updateFirewallRule: FirewallRule;
  /** Update a floor plan. */
  updateFloorPlan: FloorPlan;
  /** Consumes a cellular failover event in the stats pipeline and routes an alert to escalate the issue to the support team. */
  updateForCellularFailoverEvent: Scalars['Boolean']['output'];
  /** This is intended to only be called by the stat-consumer. */
  updateForHighAvailabilityEvent: Scalars['Boolean']['output'];
  /** Update the metadata of a hardware device. */
  updateHardwareDevice: HardwareDevice;
  /** Update a host monitoring target. */
  updateHostMonitoringTarget: HostMonitoringTarget;
  /** Update an IPSec tunnel. */
  updateIPSecTunnel: IpSecTunnel;
  /** Sets the permitted pairs for this network to these pairs, discarding others. Equivalent to PUT semantics. */
  updateInterVLANCommunicationPermittedPairs: Array<InterVlanCommunicationPermittedPair>;
  /** Update an internal note. */
  updateInternalNote: InternalNote;
  /** Updates an existing InternetServicePlan with the provided fields. */
  updateInternetServicePlan: InternetServicePlan;
  /**
   * This is a deprecated mutation.
   * @deprecated We no longer support direct updating of mailing addresses.
   */
  updateMailingAddress: MailingAddress;
  /** Update the maintenance window for a network. */
  updateMaintenanceWindowForNetwork: MaintenanceWindow;
  /** Update a network. */
  updateNetwork: Network;
  /** Update a NOS feature. */
  updateNosFeature: NosFeature;
  /** Update a NOS version. */
  updateNosVersion: NosVersion;
  /** @deprecated No longer supported. */
  updateNotificationPreference: NotificationPreferences;
  /** Update a notification setting. */
  updateNotificationSetting: NotificationSetting;
  /** Update a 1:1 NAT rule. */
  updateOneToOneNATRule: OneToOneNatRule;
  /** Update an outlet interface. */
  updateOutletInterface: OutletInterface;
  /** Update multiple outlet interfaces. */
  updateOutletInterfaces: Array<OutletInterface>;
  /** Update a phy interface. */
  updatePhyInterface: PhyInterface;
  /** This is intended to be called only by the kafka consumer. */
  updatePhyInterfaceForMultiWANEvent: Scalars['Boolean']['output'];
  /** Update many phy interfaces to the same values. */
  updatePhyInterfaces: Array<PhyInterface>;
  updatePolicyRoutingRule: PolicyRoutingRule;
  /** Update a port forwarding rule. */
  updatePortForwardingRule: PortForwardingRule;
  /** Update a rack elevation. */
  updateRackElevation: RackElevation;
  /** Update a device in a rack elevation. */
  updateRackElevationDevice: RackElevation;
  /** Update a radio profile. */
  updateRadioProfile: RadioProfile;
  /** Update a rate limit rule. */
  updateRateLimitRule: RateLimitRule;
  /** Update an SSID. */
  updateSSID: Ssid;
  /** Update a static route. */
  updateStaticRoute: StaticRoute;
  /** Update a switch profile. */
  updateSwitchProfile: SwitchProfile;
  /** This is intended to be called only by the kafka consumer. This is its own mutation until auth can be better figured out for individual fields. */
  updateUplinkPhyInterfaceExternalAddresses: Scalars['Boolean']['output'];
  /** Updates a user's roles to be only those specified by the input. */
  updateUserRoles: CompanyUser;
  /** Update an existing VLAN. */
  updateVLAN: Vlan;
  /** Sets the permitted pairs for this VLAN to these other VLANs, discarding others. Equivalent to PUT semantics. */
  updateVLANInterVLANCommunicationPermittedVLANs: Vlan;
  /** Update many VLANs at once, applying different settings to each. */
  updateVLANsIndependently: Array<Vlan>;
  /** Update the metadata of a virtual device. */
  updateVirtualDevice: VirtualDevice;
  /** Update the metadata of multiple virtual devices at once, to the same values for all devices. */
  updateVirtualDevices: Array<VirtualDevice>;
  /** Update the metadata of multiple virtual devices at once, to different values for each device. */
  updateVirtualDevicesIndependently: Array<VirtualDevice>;
  /** @deprecated No longer supported. */
  upsertCompanyNotificationPreference: NotificationPreferences;
  /** Only use this in development or extreme outage situations! Overrides the rendered config for a device with the given JSON. */
  upsertDeviceConfigOverride: Scalars['Boolean']['output'];
  /** Create or update a MAC address alias. */
  upsertMACAddressAlias: MacAddressAlias;
  /** @deprecated No longer supported. */
  upsertNetworkNotificationPreference: NotificationPreferences;
  /** Create or update the network onboarding document, updating the document using PUT semantics. Keyed by `networkUUID` instead of its own UUID for convenience. */
  upsertNetworkOnboardingDocument: NetworkOnboardingDocument;
  /** Delegates specific fields of the network onboarding document to a user. */
  upsertNetworkOnboardingFieldDelegation: NetworkOnboardingFieldDelegation;
  validateBearerSession: BearerSession;
};

export type MutationActivateCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationActivateDeviceTwilioCellularArgs = {
  input: TwilioCellularActivationInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationAddNetworkToAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  input: AddNetworkToAutoVpnGroupInput;
};

export type MutationAddNetworksToAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  inputs: Array<AddNetworkToAutoVpnGroupInput>;
};

export type MutationAssignHardwareDeviceToNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationAssignHardwareDeviceToNetworkAsSpareArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationAssignHardwareDeviceToVirtualDeviceArgs = {
  serialNumber: Scalars['String']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationAssignHardwareDevicesToVirtualDevicesArgs = {
  inputs: Array<AssignHardwareDeviceToVirtualDeviceInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationAssignOperatorRoleArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationAssignSwitchProfleToVirtualDeviceArgs = {
  UUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationAttachDeviceToRackElevationArgs = {
  input: AttachDeviceToRackElevationInput;
  rackElevationUUID: Scalars['UUID']['input'];
};

export type MutationAttachVirtualDeviceToRadioProfileArgs = {
  radioProfileUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationBindFirewallRuleToPhyInterfaceArgs = {
  input: BindFirewallRuleToPhyInterface;
};

export type MutationBindFirewallRuleToVlanArgs = {
  input: BindFirewallRuleToVlan;
};

export type MutationBindFirewallRulesToPhyInterfaceArgs = {
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type MutationBindFirewallRulesToVlanArgs = {
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationBindPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: BindPolicyRoutingRuleInput;
};

export type MutationBindPolicyRoutingRulesArgs = {
  input: BindPolicyRoutingRulesInput;
  orderedRuleUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationBindRateLimitRuleToPhyInterfaceArgs = {
  input: RateLimitPhyInterfaceBindingInput;
};

export type MutationBindRateLimitRuleToVlanArgs = {
  input: RateLimitVlanBindingInput;
};

export type MutationBindVlaNtoPhyInterfaceArgs = {
  input: BindVlanToPhyInterfaceInput;
};

export type MutationCancelAutoSwitchConfigurationJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type MutationConfigureSsoArgs = {
  input: ConfigureSsoInput;
};

export type MutationCopyAccessPointConfig1LocationsToConfig2LabelsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyDnsHostMappingsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyFirewallRulesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyIpSecTunnelFromConfig1ToConfig2Args = {
  input: CopyIpSecTunnelFromConfig1ToConfig2Input;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyInterVlanCommunicationFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyInternetServicePlanIPsFromConfigToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyNetworkMultiWanAlgorithmFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyPortForwardingRulesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyRadioSettingsForNetworkFromConfig1Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopySsiDsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyUplinkPhyInterfacesFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCopyVlaNsFromConfig1ToConfig2Args = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateAlertReceiverWithTargetsArgs = {
  companyUUID: Scalars['UUID']['input'];
  input: CreateAlertReceiverWithTargetsInput;
};

export type MutationCreateAlertTargetWebhookArgs = {
  companyUUID: Scalars['UUID']['input'];
  input: CreateAlertTargetWebhookInput;
};

export type MutationCreateApplicationDnsFirewallRuleArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateApplicationDnsFirewallRuleInput;
};

export type MutationCreateAutoVpnGroupArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateAutoVpnGroupInput;
};

export type MutationCreateAutoVpnRouteArgs = {
  groupUUID: Scalars['UUID']['input'];
  input: CreateAutoVpnRouteInput;
};

export type MutationCreateBulkNosUpgradeArgs = {
  input: CreateBulkNosUpgradeInput;
};

export type MutationCreateCaptivePortalArgs = {
  input: CaptivePortalSettingsInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateClientVpnClientArgs = {
  input: CreateClientVpnClientInput;
  serverUUID: Scalars['UUID']['input'];
};

export type MutationCreateClientVpnServerArgs = {
  input: CreateClientVpnServerInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateCompanyUserArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateCompanyUserInput;
};

export type MutationCreateDhcpOptionArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpOptionInput;
};

export type MutationCreateDhcpReservedRangeArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpReservedRangeInput;
};

export type MutationCreateDhcpRuleArgs = {
  input: CreateDhcpRuleInput;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationCreateDhcpStaticMappingArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDhcpStaticMappingInput;
};

export type MutationCreateDnsHostMappingArgs = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateDnsHostMappingInput;
};

export type MutationCreateDevHardwareDeviceArgs = {
  input: CreateDevHardwareDeviceInput;
};

export type MutationCreateDeviceHeartbeatArgs = {
  input: CreateDeviceHeartbeatInput;
};

export type MutationCreateEncryption8021XArgs = {
  input: CreateEncryption8021XInput;
};

export type MutationCreateFirewallRuleArgs = {
  input: CreateFirewallRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateFloorPlanArgs = {
  input: CreateFloorPlanInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateHighAvailabilityControllersConfigurationArgs = {
  backupPhyInterfaceUUID: Scalars['UUID']['input'];
  input?: InputMaybe<CreateHighAvailabilityPairInput>;
  networkUUID: Scalars['UUID']['input'];
  primaryPhyInterfaceUUID: Scalars['UUID']['input'];
};

export type MutationCreateHostMonitoringTargetArgs = {
  input: CreateHostMonitoringTargetInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateIpSecTunnelArgs = {
  input: CreateIpSecTunnelInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateInternalNoteArgs = {
  input: CreateInternalNoteInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateInternetServicePlanArgs = {
  companySlug: Scalars['String']['input'];
  input: CreateInternetServicePlanInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateMailingAddressArgs = {
  input: CreateMailingAddressInput;
};

export type MutationCreateNetworkArgs = {
  input: CreateNetworkInput;
};

export type MutationCreateNetworkFromCopyArgs = {
  input?: InputMaybe<CreateNetworkCopyInput>;
  sourceNetworkUUID: Scalars['UUID']['input'];
};

export type MutationCreateNetworkOnboardingArgs = {
  jobTrackerID: Scalars['String']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateNosFeatureArgs = {
  input: CreateNosFeatureInput;
};

export type MutationCreateNosVersionArgs = {
  input: NosVersionInput;
};

export type MutationCreateNotificationSettingArgs = {
  input: CreateNotificationSettingInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateOneToOneNatRuleArgs = {
  input: CreateOneToOneNatRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePermittedEmailDomainArgs = {
  input: CreatePermittedEmailDomainInput;
};

export type MutationCreatePolicyRoutingRuleArgs = {
  input: CreatePolicyRoutingRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePortForwardingRuleArgs = {
  input: CreatePortForwardingRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreatePreSignedUploadUrlArgs = {
  input: CreatePreSignedUploadUrlInput;
};

export type MutationCreateRackElevationArgs = {
  input: CreateRackElevationInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateRadioProfileArgs = {
  input: CreateRadioProfileInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateRateLimitRuleArgs = {
  input: CreateRateLimitRuleInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSsidArgs = {
  input: CreateSsidInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSsoAdminPortalLinkArgs = {
  input?: InputMaybe<ConfigureSsoInput>;
};

export type MutationCreateSsoDashboardForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type MutationCreateSsoRedirectUriArgs = {
  targetApp: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type MutationCreateSsoVerifyArgs = {
  input: CreateSsoVerifyInput;
};

export type MutationCreateSsoOAuthUriArgs = {
  targetApp: Scalars['String']['input'];
};

export type MutationCreateStaticRouteArgs = {
  input: CreateStaticRouteInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSwitchAutoConfigMacAddressVlanBindingsArgs = {
  input: Array<CreateSwitchAutoConfigMacAddressVlanBindingInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateSwitchProfileArgs = {
  input: CreateSwitchProfileInput;
};

export type MutationCreateUserNetworkAccessArgs = {
  input: CreateUserNetworkAccessInput;
};

export type MutationCreateVlanArgs = {
  input: CreateVlanInput;
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationCreateVirtualDeviceArgs = {
  input: CreateVirtualDeviceInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateVirtualDevicesArgs = {
  input: CreateVirtualDevicesInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationCreateWifiObserverSettingArgs = {
  input: CreateWifiObserverSettingInput;
};

export type MutationDangerouslyCreateNetworkForNewCompanyFromCopyArgs = {
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<CreateNetworkCopyInput>;
  sourceNetworkUUID: Scalars['UUID']['input'];
};

export type MutationDeactivateDeviceTwilioCellularArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationDeleteAlertReceiverWithTargetsArgs = {
  alertReceiverUUID: Scalars['UUID']['input'];
};

export type MutationDeleteAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteAutoVpnRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteCaptivePortalArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteClientVpnClientArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteDeviceHeartbeatArgs = {
  input: DeleteDeviceHeartbeatInput;
};

export type MutationDeleteEncryption8021XArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteHighAvailabilityControllersConfigurationArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationDeleteHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteInternetServicePlanArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkOnboardingDocumentArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNetworkOnboardingFieldDelegationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteNotificationPreferenceArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePermittedEmailDomainArgs = {
  input: DeletePermittedEmailDomainInput;
};

export type MutationDeletePolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeletePortForwardingRulesArgs = {
  UUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationDeleteRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteRadioProfileArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteSsidArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationDeleteStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteSwitchAutoConfigMacAddressVlanBindingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteVlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDeleteVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationDeleteVirtualDevicesArgs = {
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationDeleteWifiObserverSettingArgs = {
  input: DeleteWifiObserverSettingInput;
};

export type MutationDetachDeviceFromRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationDetachVirtualDeviceFromRadioProfileArgs = {
  radioProfileUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationEditMutationAuditLogEntryReasonArgs = {
  UUID: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
};

export type MutationEnableSsoForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type MutationInitializeAutoChannelSelectionForAccessPointArgs = {
  input: AutoChannelSelectionJobInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationInitializeAutoChannelSelectionForNetworkArgs = {
  input: AutoChannelSelectionJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationInitializeAutoSwitchConfigurationForNetworkArgs = {
  input: SwitchAutoConfigurationJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationInsertStatsForDemoArgs = {
  companySlug: Scalars['String']['input'];
  duration?: InputMaybe<StatInjectionDuration>;
};

export type MutationMintAuthenticationTokenArgs = {
  input?: InputMaybe<MintTokenInput>;
};

export type MutationMoveDeviceFromConfig2ToConfig1Args = {
  serialNumber: Scalars['String']['input'];
};

export type MutationMoveInternetServicePlanToNetworkArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationPinDeviceConfigsForNetworkArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationPinUnpinnedDeviceConfigsGloballyArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
};

export type MutationReactivateDeviceTwilioCellularArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRemoveNetworkFromAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationRemoveNetworksFromAutoVpnGroupArgs = {
  groupUUID: Scalars['UUID']['input'];
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveNetworksFromBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveOperatorRoleArgs = {
  companyUserUUID: Scalars['UUID']['input'];
};

export type MutationRenderDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRevokeAuthenticationTokenArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationRotateCaptivePortalSecretArgs = {
  uuid: Scalars['UUID']['input'];
};

export type MutationRpcBlinkLeDsArgs = {
  durationSec?: Scalars['Int']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcClearBlockedConfigsArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcPduOutletCycleArgs = {
  input: RpcpduOutletCycleInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcPingArgs = {
  input: RpcPingInput;
};

export type MutationRpcPortCableTestArgs = {
  portNumber: Scalars['Int']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRebootDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRefreshConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcRestartIpSecTunnelArgs = {
  ipSecTunnelUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcSwitchPortCycleArgs = {
  input: RpcSwitchPortCycleInput;
};

export type MutationRpcWanSpeedtestArgs = {
  input: RpcwanSpeedtestInput;
};

export type MutationRpcWosDisconnectClientArgs = {
  mac: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  ssid: Scalars['String']['input'];
};

export type MutationRpcWosEapolTestForSerialArgs = {
  input?: InputMaybe<RpcEapolTestForSerialInput>;
};

export type MutationRpcWosEapolTestForSerialsArgs = {
  input: RpcEapolTestForSerialsInput;
};

export type MutationRpcWosObserverArgs = {
  input: RpcWosObserverInput;
};

export type MutationRpcWosPacketCaptureStartArgs = {
  input: RpcWosPacketCaptureStartInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosPacketCaptureStopArgs = {
  input: RpcWosPacketCaptureStopInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosRadioResetArgs = {
  band: RadioBand;
  serialNumber: Scalars['String']['input'];
};

export type MutationRpcWosResetDfsArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationSetHardwareDeviceIsDevArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationSetNetworkNosVersionArgs = {
  input: SetNetworkNosVersionInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationSetPolicyRoutingRuleBindingsArgs = {
  UUID: Scalars['UUID']['input'];
  input: SetPolicyRoutingRuleBindingsInput;
};

export type MutationSignConsoleLoginChallengeArgs = {
  challenge: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationStartWanSpeedtestJobArgs = {
  input: StartWanSpeedtestJobInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationTestTriggerWebhookAlertTargetArgs = {
  UUID: Scalars['UUID']['input'];
  input: TestTriggerAlertTargetWebhookInput;
};

export type MutationUnassignHardwareDeviceFromVirtualDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationUnassignHardwareFromVirtualDevicesArgs = {
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationUnassignInternetServicePlanFromNetworkArgs = {
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationUnassignSpareHardwareDeviceFromNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationUnbindFirewallRuleFromPhyInterfaceArgs = {
  input: UnbindFirewallRuleFromPhyInterface;
};

export type MutationUnbindFirewallRuleFromVlanArgs = {
  input: UnbindFirewallRuleFromVlan;
};

export type MutationUnbindPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UnbindPolicyRoutingRuleInput;
};

export type MutationUnbindRateLimitRuleFromPhyInterfaceArgs = {
  input: RateLimitPhyInterfaceBindingInput;
};

export type MutationUnbindRateLimitRuleFromVlanArgs = {
  input: RateLimitVlanBindingInput;
};

export type MutationUndeleteNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type MutationUnpinDeviceConfigsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUnpinDeviceConfigsGloballyArgs = {
  maxDeviceConfigCreatedAt: Scalars['DateTime']['input'];
};

export type MutationUnsetHardwareDeviceIsDevArgs = {
  serialNumber: Scalars['String']['input'];
};

export type MutationUpdateAlertReceiverWithTargetsArgs = {
  alertReceiverUUID: Scalars['UUID']['input'];
  input: UpdateAlertReceiverWithTargetsInput;
};

export type MutationUpdateAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAlertTargetWebhookInput;
};

export type MutationUpdateApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateApplicationDnsFirewallRuleInput;
};

export type MutationUpdateApplicationDnsFirewallRuleApplicationVisibilitiesArgs = {
  input: Array<UpdateApplicationDnsFirewallRuleApplicationVisibilities>;
};

export type MutationUpdateApplicationDnsFirewallRulesPrioritiesArgs = {
  input: Array<UpdateApplicationDnsFirewallRulesPrioritiesInput>;
};

export type MutationUpdateAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnGroupInput;
};

export type MutationUpdateAutoVpnMemberArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnMemberInput;
};

export type MutationUpdateAutoVpnRouteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnRouteInput;
};

export type MutationUpdateCaptivePortalArgs = {
  input: CaptivePortalSettingsInput;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateClientVpnServerInput;
};

export type MutationUpdateCompanyArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateCompanyInput;
};

export type MutationUpdateCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input: UpdateCompanyUserInput;
};

export type MutationUpdateDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpOptionInput;
};

export type MutationUpdateDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpReservedRangeInput;
};

export type MutationUpdateDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpRuleInput;
};

export type MutationUpdateDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpStaticMappingInput;
};

export type MutationUpdateDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDnsHostMappingInput;
};

export type MutationUpdateEncryption8021XArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateEncryption8021XInput;
};

export type MutationUpdateFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateFirewallRuleInput;
};

export type MutationUpdateFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateFloorPlanInput;
};

export type MutationUpdateForCellularFailoverEventArgs = {
  input: UpdateForCellularFailoverEventInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateForHighAvailabilityEventArgs = {
  input: UpdateForHighAvailabilityEventInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateHardwareDeviceArgs = {
  input: UpdateHardwareDeviceInput;
  serialNumber: Scalars['String']['input'];
};

export type MutationUpdateHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateHostMonitoringTargetInput;
};

export type MutationUpdateIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateIpSecTunnelInput;
};

export type MutationUpdateInterVlanCommunicationPermittedPairsArgs = {
  networkUUID: Scalars['UUID']['input'];
  vlanPairs: Array<VlanPairInput>;
};

export type MutationUpdateInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateInternalNoteInput;
};

export type MutationUpdateInternetServicePlanArgs = {
  input: UpdateInternetServicePlanInput;
  internetServicePlanUUID: Scalars['UUID']['input'];
};

export type MutationUpdateMailingAddressArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateMailingAddressInput;
};

export type MutationUpdateMaintenanceWindowForNetworkArgs = {
  input: UpdateMaintenanceWindowInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpdateNetworkArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNetworkInput;
};

export type MutationUpdateNosFeatureArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNosFeatureInput;
};

export type MutationUpdateNosVersionArgs = {
  id: Scalars['Int']['input'];
  input: NosVersionInput;
};

export type MutationUpdateNotificationPreferenceArgs = {
  input?: InputMaybe<NotificationPreferenceInput>;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateNotificationSettingInput;
};

export type MutationUpdateOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateOneToOneNatRuleInput;
};

export type MutationUpdateOutletInterfaceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateOutletInterfaceInput;
};

export type MutationUpdateOutletInterfacesArgs = {
  input: UpdateOutletInterfaceInput;
  outletInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePhyInterfaceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePhyInterfaceInput;
};

export type MutationUpdatePhyInterfaceForMultiWanEventArgs = {
  input: Array<UpdatePhyInterfaceForMultiWanEventInput>;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePhyInterfacesArgs = {
  input: UpdatePhyInterfaceInput;
  phyInterfaceUUIDs: Array<Scalars['UUID']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdatePolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePolicyRoutingRuleInput;
};

export type MutationUpdatePortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdatePortForwardingRuleInput;
};

export type MutationUpdateRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRackElevationInput;
};

export type MutationUpdateRackElevationDeviceArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRackElevationDeviceInput;
};

export type MutationUpdateRadioProfileArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRadioProfileInput;
};

export type MutationUpdateRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateRateLimitRuleInput;
};

export type MutationUpdateSsidArgs = {
  input: UpdateSsidInput;
  uuid: Scalars['UUID']['input'];
};

export type MutationUpdateStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateStaticRouteInput;
};

export type MutationUpdateSwitchProfileArgs = {
  UUID: Scalars['UUID']['input'];
  input: UpdateSwitchProfileInput;
};

export type MutationUpdateUplinkPhyInterfaceExternalAddressesArgs = {
  input: UpdateUplinkPhyInterfaceExternalAddressesInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateUserRolesArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input: Array<UserRoleInput>;
};

export type MutationUpdateVlanArgs = {
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  input: UpdateVlanInput;
};

export type MutationUpdateVlanInterVlanCommunicationPermittedVlaNsArgs = {
  otherVLANUUIDs: Array<Scalars['UUID']['input']>;
  vlanUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVlaNsIndependentlyArgs = {
  inputs: Array<UpdateVlaNsIndependentlyInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVirtualDeviceArgs = {
  input: UpdateVirtualDeviceInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type MutationUpdateVirtualDevicesArgs = {
  input: UpdateVirtualDeviceInput;
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type MutationUpdateVirtualDevicesIndependentlyArgs = {
  inputs: Array<UpdateVirtualDeviceIndependentlyInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpsertCompanyNotificationPreferenceArgs = {
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<NotificationPreferenceInput>;
};

export type MutationUpsertDeviceConfigOverrideArgs = {
  configJSON: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type MutationUpsertMacAddressAliasArgs = {
  alias: Scalars['String']['input'];
  companySlug: Scalars['String']['input'];
  macAddress: Scalars['MacAddress']['input'];
};

export type MutationUpsertNetworkNotificationPreferenceArgs = {
  controllerName: Scalars['String']['input'];
  input?: InputMaybe<NotificationPreferenceInput>;
};

export type MutationUpsertNetworkOnboardingDocumentArgs = {
  document: Scalars['JSONObject']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type MutationUpsertNetworkOnboardingFieldDelegationArgs = {
  fieldName: Scalars['String']['input'];
  input: UpsertNetworkOnboardingFieldDelegationInput;
  networkUUID: Scalars['UUID']['input'];
};

export type MutationValidateBearerSessionArgs = {
  UUID: Scalars['UUID']['input'];
};

/** Tracks the mutations that have been made to the network's configuration over time. */
export type MutationAuditLogEntry = {
  __typename?: 'MutationAuditLogEntry';
  UUID: Scalars['UUID']['output'];
  action: Scalars['String']['output'];
  args: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  identityType: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type MutationAuditLogFilter = {
  /** include 'machine' identityType */
  includeMachine?: Scalars['Boolean']['input'];
  limit?: Scalars['Int']['input'];
  maxCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  minCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  offset?: Scalars['Int']['input'];
};

/** A network is a grouping of devices in a single location belonging to a company. A core Meter entity. */
export type Network = {
  __typename?: 'Network';
  UUID: Scalars['UUID']['output'];
  company?: Maybe<Company>;
  companySlug?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The time when the network was deactivated, if applicable. Allows differentiation between pre-live and post-live networks. */
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  enabledServiceDiscoveryTypes: Array<ServiceType>;
  /** The date the network first started serving traffic. */
  goLiveAt?: Maybe<Scalars['DateTime']['output']>;
  /** Configuration of the HA controllers for this network. */
  highAvailabilityConfig?: Maybe<HighAvailabilityControllersConfig>;
  /** Whether or not the network is currently serving traffic. */
  isActive: Scalars['Boolean']['output'];
  /** Indicates whether the controller can upgrade config-1 APs deployed to the network */
  isConfig1WosUpgradesEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the network services a customer (as opposed to being an internal / test network). */
  isCustomer: Scalars['Boolean']['output'];
  /**
   * A "template" network is one that does not represent a real physical network, but rather a template for creating new networks.
   * It is not associated with any physical devices, and is not intended to be used for any real-world operations.
   */
  isTemplate: Scalars['Boolean']['output'];
  /** Whether the network is participating in the Meter Trial Program. */
  isTrial: Scalars['Boolean']['output'];
  /** Indicates whether a network is sensitive to firmware updates. */
  isUpgradeSensitive: Scalars['Boolean']['output'];
  /** A short code to quickly identify the network. Useful for customers with many networks and a need to quickly identify them. */
  label: Scalars['String']['output'];
  /** The last time this network was upgraded. It is the time when pending NOS was copied into pinned NOS by the NOS scheduler */
  lastUpgradedAt?: Maybe<Scalars['DateTime']['output']>;
  mailingAddress?: Maybe<MailingAddress>;
  mailingAddressUUID?: Maybe<Scalars['UUID']['output']>;
  multiWANAlgorithm?: Maybe<MultiWanAlgorithm>;
  /** Somewhat legacy onboarding information from airtable job. Different from `onboardingDocument`. */
  onboarding?: Maybe<NetworkOnboarding>;
  /** Onboarding document form completed prior to network going live. Different from `onboarding`. */
  onboardingDocument?: Maybe<NetworkOnboardingDocument>;
  /** The URL of the patch panel diagram for the network. */
  patchPanelDiagramURL?: Maybe<Scalars['String']['output']>;
  /** The last time an operator set the NOS version for the network */
  pendingNOSLastSetAt?: Maybe<Scalars['DateTime']['output']>;
  /** The pending NOS version that this network is scheduled to be upgraded to */
  pendingNOSVersionID?: Maybe<Scalars['Int']['output']>;
  /** A way to pin a network's devices to a known version of config by the config's max created at time. */
  pinnedDeviceConfigMaxCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The current NOS version for all devices in the network. */
  pinnedNOSVersionID: Scalars['Int']['output'];
  rackElevations: Array<RackElevation>;
  /** A human-readable identifier for the network. Used in URLs to identify the network. */
  slug: Scalars['String']['output'];
  /** @deprecated Not a network property anymore */
  spaceCategory?: Maybe<Scalars['String']['output']>;
  staggeredUpgradeIntervalMin: Scalars['Int']['output'];
  topologyImageURL?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  virtualDevices: Array<VirtualDevice>;
};

/** A network is a grouping of devices in a single location belonging to a company. A core Meter entity. */
export type NetworkVirtualDevicesArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
};

/** A network client is a large container for all of the data that constitutes a client connected to a network. */
export type NetworkClient = {
  __typename?: 'NetworkClient';
  alias?: Maybe<Scalars['String']['output']>;
  apLocation?: Maybe<Scalars['String']['output']>;
  apSerialNumber?: Maybe<Scalars['String']['output']>;
  /** When the client associated with the AP, if applicable. */
  associatedAt?: Maybe<Scalars['DateTime']['output']>;
  channel?: Maybe<Scalars['Int']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  connectedVLAN?: Maybe<Vlan>;
  dhcpLeaseExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  ip: Scalars['String']['output'];
  /** If false, the client is connected via a wired connection. */
  isWireless: Scalars['Boolean']['output'];
  lastSeen: Scalars['DateTime']['output'];
  /** @deprecated Use `latencyNs` instead. */
  latencyMs?: Maybe<Scalars['Int']['output']>;
  latencyNs?: Maybe<Scalars['Int']['output']>;
  macAddress: Scalars['MacAddress']['output'];
  networkUUID: Scalars['UUID']['output'];
  noise?: Maybe<Scalars['Int']['output']>;
  rxRate?: Maybe<Scalars['Int']['output']>;
  /** List of mdns service types that this client offers (e.g. printer, airplay, etc) */
  serviceTypes?: Maybe<Array<ServiceType>>;
  signal?: Maybe<Scalars['Int']['output']>;
  ssid?: Maybe<Scalars['String']['output']>;
  throughput?: Maybe<NetworkClientThroughput>;
  /** The total number of bytes received by the client over the requested lookback period. */
  totalRxBytes?: Maybe<Scalars['Int']['output']>;
  /** The total number of bytes transmitted by the client over the requested lookback period. */
  totalTxBytes?: Maybe<Scalars['Int']['output']>;
  txRate?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `connectedVLAN` instead. */
  vlan?: Maybe<Scalars['String']['output']>;
  /** vlanUUID is only supported for Config 2 networks. */
  vlanUUID?: Maybe<Scalars['UUID']['output']>;
  /** Wireless score computed based on the current health of the client */
  wirelessScore?: Maybe<Scalars['Float']['output']>;
};

export type NetworkClientThroughput = {
  __typename?: 'NetworkClientThroughput';
  /** The rx rate of a client in bps. */
  rxRate?: Maybe<Scalars['Float']['output']>;
  /** The tx rate of a client in bps. */
  txRate?: Maybe<Scalars['Float']['output']>;
};

export type NetworkClientTxRxPerDay = {
  __typename?: 'NetworkClientTxRxPerDay';
  /** The date to which the data is grouped by. */
  dateString: Scalars['String']['output'];
  ip: Scalars['String']['output'];
  macAddress: Scalars['MacAddress']['output'];
  /** The total number of bytes received by the client on this date. */
  rxBytes: Scalars['Int']['output'];
  /** The total number of bytes transmitted by the client on this date. */
  txBytes: Scalars['Int']['output'];
};

export type NetworkClientsFilter = {
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given AP serial number.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  apSerialNumber?: InputMaybe<Scalars['String']['input']>;
  excludeMeterHardware?: Scalars['Boolean']['input'];
  /**
   * If provided, then the ping latency from controller to client is included
   * in the response.
   */
  includeLatency?: Scalars['Boolean']['input'];
  /**
   * If provided, the average usage for all returned clients in the last
   * 30 minutes will be included in the response. If macAddress is provided this field has no effect.
   */
  includeThroughput?: Scalars['Boolean']['input'];
  /**
   * When no macAddress and apSerialNumber are provided filters resulting clients' lastSeen
   * to this many minutes before now.
   */
  lookbackMinutes?: Scalars['Int']['input'];
  /**
   * If a mac address is provided, this will get the history of clients with this mac address.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given SSID.
   * Only supported for config 2 COS networks.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  ssid?: InputMaybe<Scalars['String']['input']>;
  /**
   * If provided, the latest connected clients for a network are returned
   * that were reported to be connected to the given VLAN.
   * Only supported for config 2 COS networks.
   * At most one of `macAddress`, `apSerialNumber`, `vlanID`, or `ssid` can be provided.
   */
  vlanID?: InputMaybe<Scalars['Int']['input']>;
};

export enum NetworkEventType {
  AccessPointChangeStatus = 'ACCESS_POINT_CHANGE_STATUS',
  ControllerChangeStatus = 'CONTROLLER_CHANGE_STATUS',
  ControllerOffline = 'CONTROLLER_OFFLINE',
  LteFailover = 'LTE_FAILOVER',
  UnexpectedAccessPointRegistration = 'UNEXPECTED_ACCESS_POINT_REGISTRATION',
  VpnFailover = 'VPN_FAILOVER',
  WanChangeStatus = 'WAN_CHANGE_STATUS',
  WanFailover = 'WAN_FAILOVER',
}

export type NetworkJob = {
  UUID: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  networkUUID: Scalars['UUID']['output'];
  scheduledAt: Scalars['DateTime']['output'];
};

export type NetworkOnboarding = {
  __typename?: 'NetworkOnboarding';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  jobData?: Maybe<NetworkOnboardingJobData>;
  jobDataID: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NetworkOnboardingDocument = {
  __typename?: 'NetworkOnboardingDocument';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  delegations: Array<NetworkOnboardingFieldDelegation>;
  document: Scalars['JSONObject']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NetworkOnboardingFieldDelegation = {
  __typename?: 'NetworkOnboardingFieldDelegation';
  UUID: Scalars['UUID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
};

/** Stores logistical data for tracking the installation and onboarding of a network. */
export type NetworkOnboardingJobData = {
  __typename?: 'NetworkOnboardingJobData';
  fullAddress?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  installationEndDate?: Maybe<Scalars['DateTime']['output']>;
  installationStartDate?: Maybe<Scalars['DateTime']['output']>;
  jobID?: Maybe<Scalars['String']['output']>;
  jobOwnerUser?: Maybe<Scalars['String']['output']>;
  jobStage?: Maybe<Scalars['String']['output']>;
  jobStatus?: Maybe<Scalars['String']['output']>;
  jobTechnicianName?: Maybe<Array<Scalars['String']['output']>>;
  jobTechnicianPhone?: Maybe<Array<Scalars['String']['output']>>;
  locationCode?: Maybe<Scalars['String']['output']>;
  onsiteContactInformation?: Maybe<Scalars['String']['output']>;
  scheduleSiteSurveyLink?: Maybe<Array<Scalars['String']['output']>>;
  siteSurveyDate?: Maybe<Array<Scalars['String']['output']>>;
  siteSurveyStatus?: Maybe<Array<Scalars['String']['output']>>;
  targetGoLiveDate?: Maybe<Scalars['DateTime']['output']>;
  totalSqFt?: Maybe<Array<Scalars['String']['output']>>;
};

/** A network route defines a route within the networking stack on a device. It can refer to either a physical interface, vlan interface, or another linux interface. */
export type NetworkRoute = {
  __typename?: 'NetworkRoute';
  attachment: NetworkRouteAttachment;
  destination: Scalars['IPPrefix']['output'];
  gateway?: Maybe<Scalars['IPV4']['output']>;
};

/** One field of NetworkRouteAttachment will be non-null */
export type NetworkRouteAttachment = {
  __typename?: 'NetworkRouteAttachment';
  autoVPNGroup?: Maybe<AutoVpnGroup>;
  clientVPNServer?: Maybe<ClientVpnServer>;
  ipSecTunnel?: Maybe<IpSecTunnel>;
  phyInterface?: Maybe<PhyInterface>;
  staticRoute?: Maybe<StaticRoute>;
  vlan?: Maybe<Vlan>;
};

export type NetworkStatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 7776000, 90 days. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /** The list of stat types to return. */
  types: Array<StatType>;
  /** The virtualDeviceUUIDs of the stats to return. */
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']>;
};

export type NetworkUplinkQualityResponse = MetricsResponse & {
  __typename?: 'NetworkUplinkQualityResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<NetworkUplinkQualityValue>;
};

export type NetworkUplinkQualityValue = MetricsValue & {
  __typename?: 'NetworkUplinkQualityValue';
  /** There are a lot of data points so shouldn't embed the phyInterface type in this type. */
  phyInterfaceUUID: Scalars['UUID']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type NetworkUplinkThroughputMetricsResponse = MetricsResponse & {
  __typename?: 'NetworkUplinkThroughputMetricsResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<NetworkUplinkThroughputMetricsValue>;
};

export type NetworkUplinkThroughputMetricsValue = MetricsValue & {
  __typename?: 'NetworkUplinkThroughputMetricsValue';
  direction: TrafficDirection;
  /** There are a lot of data points so shouldn't embed the phyInterface type in this type. */
  phyInterfaceUUID: Scalars['UUID']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type NetworksBooleanFilter = {
  eq: Scalars['Boolean']['input'];
};

export type NetworksCompanySupportTierFilter = {
  in: Array<CompanySupportTier>;
  not?: Scalars['Boolean']['input'];
};

export type NetworksDateTimeFilter = {
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NetworksFilter = {
  active?: InputMaybe<NetworksBooleanFilter>;
  companySupportTier?: InputMaybe<NetworksCompanySupportTierFilter>;
  hardwareModel?: InputMaybe<NetworksHardwareModelFilter>;
  lastUpgradedAt?: InputMaybe<NetworksDateTimeFilter>;
  nosVersionID?: InputMaybe<NetworksNosVersionIdFilter>;
  pendingUpgrade?: InputMaybe<NetworksBooleanFilter>;
  upgradeSensitivity?: InputMaybe<NetworksBooleanFilter>;
};

export type NetworksHardwareModelFilter = {
  in: Array<DeviceModel>;
  not?: Scalars['Boolean']['input'];
};

export type NetworksNosVersionIdFilter = {
  in: Array<Scalars['Int']['input']>;
  not?: Scalars['Boolean']['input'];
};

/** A NOS feature is a feature flag that tells us what minimum/maximum version of NOS firmware is needed to be installed on the network to support a given feature. */
export type NosFeature = {
  __typename?: 'NosFeature';
  description: Scalars['String']['output'];
  /** If the feature is also behind a feature flag, this is the name of the feature flag. */
  featureFlag?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  maxMajorVersion?: Maybe<Scalars['Int']['output']>;
  maxMinorVersion?: Maybe<Scalars['Int']['output']>;
  maxPatchVersion?: Maybe<Scalars['Int']['output']>;
  minMajorVersion: Scalars['Int']['output'];
  minMinorVersion: Scalars['Int']['output'];
  minPatchVersion: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type NosFeatureEnabled = {
  __typename?: 'NosFeatureEnabled';
  enabled: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
};

/** A NOS (Network Operating System) version is a grouping of build strings for various device types. Together they constitute a network-wide firmware release. */
export type NosVersion = {
  __typename?: 'NosVersion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  isDeprecated: Scalars['Boolean']['output'];
  isGA: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  major: Scalars['Int']['output'];
  /** controllers */
  mc01Build?: Maybe<Scalars['String']['output']>;
  minor: Scalars['Int']['output'];
  /** power distribution unit */
  mp01Build?: Maybe<Scalars['String']['output']>;
  /** switches */
  ms10Build?: Maybe<Scalars['String']['output']>;
  ms11Build?: Maybe<Scalars['String']['output']>;
  ms12Build?: Maybe<Scalars['String']['output']>;
  /** wireless */
  mw03Build?: Maybe<Scalars['String']['output']>;
  mw04Build?: Maybe<Scalars['String']['output']>;
  mw06Build?: Maybe<Scalars['String']['output']>;
  mw07Build?: Maybe<Scalars['String']['output']>;
  mw08Build?: Maybe<Scalars['String']['output']>;
  mw09Build?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** observer */
  observerBuild?: Maybe<Scalars['String']['output']>;
  patch: Scalars['Int']['output'];
  releaseNotes?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['String']['output'];
};

export type NosVersionInput = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  isGA?: InputMaybe<Scalars['Boolean']['input']>;
  isLocked?: InputMaybe<Scalars['Boolean']['input']>;
  major?: InputMaybe<Scalars['Int']['input']>;
  /** controllers */
  mc01Build?: InputMaybe<Scalars['String']['input']>;
  minor?: InputMaybe<Scalars['Int']['input']>;
  /** power distribution unit */
  mp01Build?: InputMaybe<Scalars['String']['input']>;
  /** switches */
  ms10Build?: InputMaybe<Scalars['String']['input']>;
  ms11Build?: InputMaybe<Scalars['String']['input']>;
  ms12Build?: InputMaybe<Scalars['String']['input']>;
  /** wireless */
  mw03Build?: InputMaybe<Scalars['String']['input']>;
  mw04Build?: InputMaybe<Scalars['String']['input']>;
  mw06Build?: InputMaybe<Scalars['String']['input']>;
  mw07Build?: InputMaybe<Scalars['String']['input']>;
  mw08Build?: InputMaybe<Scalars['String']['input']>;
  mw09Build?: InputMaybe<Scalars['String']['input']>;
  /** observer */
  observerBuild?: InputMaybe<Scalars['String']['input']>;
  patch?: InputMaybe<Scalars['Int']['input']>;
  releaseNotes?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
};

export type NotificationPreferenceInput = {
  additionalEventTypes?: InputMaybe<Array<NetworkEventType>>;
  additionalSlackChannels?: InputMaybe<Array<Scalars['String']['input']>>;
  emailAddresses?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** This feature is deprecated. */
export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  additionalEventTypes?: Maybe<Array<NetworkEventType>>;
  additionalSlackChannels?: Maybe<Array<Scalars['String']['output']>>;
  company: Company;
  companySID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  emailAddresses?: Maybe<Array<Scalars['String']['output']>>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type NotificationPreferencesResponse = {
  __typename?: 'NotificationPreferencesResponse';
  companyPreferences?: Maybe<NotificationPreferences>;
  networkPreferences?: Maybe<NotificationPreferences>;
};

/**
 * Notification settings contain preferences for how a user should be notified when events happen on a network.
 * They specify a receiver of the alert, the delivery mechanism, the type of alert, and alert-specific metadata to provide further context.
 */
export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  UUID: Scalars['UUID']['output'];
  alertReceiverUUID: Scalars['UUID']['output'];
  arguments: Array<NotificationSettingArgument>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  notificationDefinition?: Maybe<AlertDefinition>;
  notificationDefinitionName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type NotificationSettingArgument = {
  __typename?: 'NotificationSettingArgument';
  parameterUUID: Scalars['UUID']['output'];
  value: Scalars['Any']['output'];
};

export type NotificationSettingArgumentInput = {
  parameterUUID: Scalars['UUID']['input'];
  /** Value is required. Only marked as nullable as a workaround for client zod validation. */
  value?: InputMaybe<Scalars['Any']['input']>;
};

/** An observer virtual device is a logical representation of a physical observer. */
export type ObserverVirtualDevice = VirtualDevice & {
  __typename?: 'ObserverVirtualDevice';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Whether the device been configured to turn off all LED activity. */
  isLEDDarkModeEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

/** 1:1 NAT Rules are used to map a single external IP address to a single internal IP address. */
export type OneToOneNatRule = {
  __typename?: 'OneToOneNATRule';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalIPAddr: Scalars['IPV4']['output'];
  externalPhyInterface: PhyInterface;
  externalPhyInterfaceUUID: Scalars['UUID']['output'];
  internalIPAddr: Scalars['IPV4']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Specifies settings for power outlet on a power distribution unit. */
export type OutletInterface = {
  __typename?: 'OutletInterface';
  UUID: Scalars['UUID']['output'];
  connectedVirtualDevice?: Maybe<VirtualDevice>;
  /** UUID of the virtual device connected to the power outlet */
  connectedVirtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The current being drawn from the outlet in Amps */
  currentAmps?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** Whether or not the interface is enabled */
  isEnabled: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  outletNumber: Scalars['Int']['output'];
  /**
   * This is the number of seconds this outlet will use when it receives a power cycle request.
   * It turns the outlet off, then waits for powerCycleDelaySec, and then turns the outlet back on
   */
  powerCycleDelaySec: Scalars['Int']['output'];
  /** The power being drawn from the outlet in Watts */
  powerWatts?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/** Specifies the NOS firmware version that a device is scheduled to upgrade to. */
export type PendingNosVersion = {
  __typename?: 'PendingNosVersion';
  nosVersion: NosVersion;
  scheduledAt: Scalars['DateTime']['output'];
};

export enum Permission {
  Admin = 'ADMIN',
  Api = 'API',
  Bastion = 'BASTION',
  Ci = 'CI',
  Cmd = 'CMD',
  Connect = 'CONNECT',
  ConnectAdmin = 'CONNECT_ADMIN',
  Controller = 'CONTROLLER',
  Dashboard = 'DASHBOARD',
  InventoryApp = 'INVENTORY_APP',
  Jumphost = 'JUMPHOST',
  Logs = 'LOGS',
  Noc = 'NOC',
  Ops = 'OPS',
  Portal = 'PORTAL',
  ProvisionController = 'PROVISION_CONTROLLER',
  Rpcbroker = 'RPCBROKER',
  Stats = 'STATS',
  StatConsumer = 'STAT_CONSUMER',
  StatUpload = 'STAT_UPLOAD',
  Worker = 'WORKER',
}

/**
 * A PermissionAssignment denotes the permissions of a user in context of a company or company & network.
 *
 * In the case of an Operator role, networkUUID field will be null.
 */
export type PermissionAssignment = {
  __typename?: 'PermissionAssignment';
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  permissions: Array<PermissionType>;
};

export type PermissionCheckResult = {
  __typename?: 'PermissionCheckResult';
  hasPermission: Scalars['Boolean']['output'];
};

/**
 * Code generated by mctl. DO NOT EDIT.
 * permissions are generated from src/auth/permissions/permissions.go
 */
export enum PermissionType {
  PermAlertReceiverRead = 'PERM_ALERT___RECEIVER_READ',
  PermAlertReceiverWrite = 'PERM_ALERT___RECEIVER_WRITE',
  PermAuditLogRead = 'PERM_AUDIT___LOG_READ',
  PermAuditLogWrite = 'PERM_AUDIT___LOG_WRITE',
  PermAuthenticationTokenRead = 'PERM_AUTHENTICATION___TOKEN_READ',
  PermAuthenticationTokenWrite = 'PERM_AUTHENTICATION___TOKEN_WRITE',
  PermAutoSelectionJobRead = 'PERM_AUTO___SELECTION___JOB_READ',
  PermAutoSelectionJobWrite = 'PERM_AUTO___SELECTION___JOB_WRITE',
  PermAutoVpnRead = 'PERM_AUTO___VPN_READ',
  PermAutoVpnWrite = 'PERM_AUTO___VPN_WRITE',
  PermBearerSessionWrite = 'PERM_BEARER___SESSION_WRITE',
  PermBulkNosUpgradeRead = 'PERM_BULK___NOS___UPGRADE_READ',
  PermBulkNosUpgradeWrite = 'PERM_BULK___NOS___UPGRADE_WRITE',
  PermCaptivePortalRead = 'PERM_CAPTIVE___PORTAL_READ',
  PermCaptivePortalWrite = 'PERM_CAPTIVE___PORTAL_WRITE',
  PermCellularWrite = 'PERM_CELLULAR_WRITE',
  PermClientVpnClientRead = 'PERM_CLIENT___VPN_CLIENT_READ',
  PermClientVpnClientWrite = 'PERM_CLIENT___VPN_CLIENT_WRITE',
  PermClientVpnRead = 'PERM_CLIENT___VPN_READ',
  PermClientVpnWrite = 'PERM_CLIENT___VPN_WRITE',
  PermCompanyAdminAssign = 'PERM_COMPANY_ADMIN_ASSIGN',
  PermCompanyGet = 'PERM_COMPANY_GET',
  PermCompanyGetRestricted = 'PERM_COMPANY_GET_RESTRICTED',
  PermCompanyList = 'PERM_COMPANY_LIST',
  PermCompanyNetworkAdminAssign = 'PERM_COMPANY_NETWORK___ADMIN_ASSIGN',
  PermCompanySsoRead = 'PERM_COMPANY_SSO_READ',
  PermCompanySsoWrite = 'PERM_COMPANY_SSO_WRITE',
  PermCompanySupportTierReadRestricted = 'PERM_COMPANY_SUPPORT___TIER_READ_RESTRICTED',
  PermCompanyUpdate = 'PERM_COMPANY_UPDATE',
  PermCompanyUsersViewMeterEmployees = 'PERM_COMPANY_USERS_VIEW__METER__EMPLOYEES',
  PermCompanyUserAssign = 'PERM_COMPANY_USER_ASSIGN',
  PermCompanyWriteRestricted = 'PERM_COMPANY_WRITE_RESTRICTED',
  /** Allows a user to access the dashboard. */
  PermDashboardAccess = 'PERM_DASHBOARD_ACCESS',
  PermDhcpDnsRead = 'PERM_DHCP___DNS_READ',
  PermDhcpDnsWrite = 'PERM_DHCP___DNS_WRITE',
  PermEventLogRead = 'PERM_EVENT___LOG_READ',
  PermFirewallRead = 'PERM_FIREWALL_READ',
  PermFirewallWrite = 'PERM_FIREWALL_WRITE',
  PermFirewallWriteRestricted = 'PERM_FIREWALL_WRITE_RESTRICTED',
  PermFloorPlanRead = 'PERM_FLOOR___PLAN_READ',
  PermFloorPlanWrite = 'PERM_FLOOR___PLAN_WRITE',
  PermHardwareDeviceAssign = 'PERM_HARDWARE___DEVICE_ASSIGN',
  PermHardwareDeviceDevWrite = 'PERM_HARDWARE___DEVICE_DEV_WRITE',
  PermHardwareDeviceLogin = 'PERM_HARDWARE___DEVICE_LOGIN',
  PermHardwareDeviceRead = 'PERM_HARDWARE___DEVICE_READ',
  PermHardwareDeviceReadLimited = 'PERM_HARDWARE___DEVICE_READ_LIMITED',
  PermHardwareDeviceRpcBlinkLeds = 'PERM_HARDWARE___DEVICE_RPC_BLINK___LEDS',
  PermHardwareDeviceRpcCableTest = 'PERM_HARDWARE___DEVICE_RPC_CABLE___TEST',
  PermHardwareDeviceRpcPortCycle = 'PERM_HARDWARE___DEVICE_RPC_PORT___CYCLE',
  PermHardwareDeviceRpcRefreshConfig = 'PERM_HARDWARE___DEVICE_RPC_REFRESH___CONFIG',
  PermHighAvailabilityRead = 'PERM_HIGH___AVAILABILITY_READ',
  PermHighAvailabilityWrite = 'PERM_HIGH___AVAILABILITY_WRITE',
  PermHostMonitoringRead = 'PERM_HOST___MONITORING_READ',
  PermHostMonitoringWrite = 'PERM_HOST___MONITORING_WRITE',
  PermIpsecTunnelRead = 'PERM_IPSEC___TUNNEL_READ',
  PermIpsecTunnelWrite = 'PERM_IPSEC___TUNNEL_WRITE',
  PermIspRead = 'PERM_ISP_READ',
  PermIspWrite = 'PERM_ISP_WRITE',
  PermIspWriteRestricted = 'PERM_ISP_WRITE_RESTRICTED',
  PermMacAddressAliasRead = 'PERM_MAC___ADDRESS___ALIAS_READ',
  PermMacAddressAliasWrite = 'PERM_MAC___ADDRESS___ALIAS_WRITE',
  PermMaintenanceWindowRead = 'PERM_MAINTENANCE___WINDOW_READ',
  PermMaintenanceWindowWrite = 'PERM_MAINTENANCE___WINDOW_WRITE',
  PermNetworkInternalNoteRead = 'PERM_NETWORK_INTERNAL___NOTE_READ',
  PermNetworkInternalNoteWrite = 'PERM_NETWORK_INTERNAL___NOTE_WRITE',
  PermNetworkMetricsRead = 'PERM_NETWORK_METRICS_READ',
  PermNetworkNosWrite = 'PERM_NETWORK_NOS_WRITE',
  PermNetworkOnboardingRead = 'PERM_NETWORK_ONBOARDING_READ',
  PermNetworkOnboardingWrite = 'PERM_NETWORK_ONBOARDING_WRITE',
  PermNetworkOnboardingWriteRestricted = 'PERM_NETWORK_ONBOARDING_WRITE_RESTRICTED',
  PermNetworkRead = 'PERM_NETWORK_READ',
  PermNetworkReadLimited = 'PERM_NETWORK_READ_LIMITED',
  PermNetworkSettingsRead = 'PERM_NETWORK_SETTINGS_READ',
  PermNetworkSettingsUpdate = 'PERM_NETWORK_SETTINGS_UPDATE',
  PermNetworkSettingsWrite = 'PERM_NETWORK_SETTINGS_WRITE',
  PermNetworkSshRead = 'PERM_NETWORK_SSH_READ',
  PermNetworkSshWrite = 'PERM_NETWORK_SSH_WRITE',
  PermNetworkWriteRestricted = 'PERM_NETWORK_WRITE_RESTRICTED',
  PermNetworkDevicesRead = 'PERM_NETWORK___DEVICES_READ',
  PermNetworkDevicesReadRestricted = 'PERM_NETWORK___DEVICES_READ_RESTRICTED',
  PermNetworkDevicesWrite = 'PERM_NETWORK___DEVICES_WRITE',
  PermNetworkDevicesWriteRestricted = 'PERM_NETWORK___DEVICES_WRITE_RESTRICTED',
  PermNetworkRouteRead = 'PERM_NETWORK___ROUTE_READ',
  PermNetworkRouteWrite = 'PERM_NETWORK___ROUTE_WRITE',
  PermNosRead = 'PERM_NOS_READ',
  PermNosWrite = 'PERM_NOS_WRITE',
  PermNosFeatureRead = 'PERM_NOS___FEATURE_READ',
  PermNosFeatureWrite = 'PERM_NOS___FEATURE_WRITE',
  PermNotificationPreferenceRead = 'PERM_NOTIFICATION___PREFERENCE_READ',
  PermNotificationPreferenceWrite = 'PERM_NOTIFICATION___PREFERENCE_WRITE',
  PermOneToOneNatRead = 'PERM_ONE___TO___ONE___NAT_READ',
  PermOneToOneNatWrite = 'PERM_ONE___TO___ONE___NAT_WRITE',
  PermOperatorAssign = 'PERM_OPERATOR_ASSIGN',
  PermPermittedEmailDomainRead = 'PERM_PERMITTED___EMAIL___DOMAIN_READ',
  PermPermittedEmailDomainWrite = 'PERM_PERMITTED___EMAIL___DOMAIN_WRITE',
  PermPhyInterfaceRead = 'PERM_PHY___INTERFACE_READ',
  PermPhyInterfaceReadLimited = 'PERM_PHY___INTERFACE_READ_LIMITED',
  PermPhyInterfaceWrite = 'PERM_PHY___INTERFACE_WRITE',
  PermPolicyRoutingRead = 'PERM_POLICY___ROUTING_READ',
  PermPolicyRoutingWrite = 'PERM_POLICY___ROUTING_WRITE',
  PermPortForwardRead = 'PERM_PORT___FORWARD_READ',
  PermPortForwardWrite = 'PERM_PORT___FORWARD_WRITE',
  PermRackElevationRead = 'PERM_RACK___ELEVATION_READ',
  PermRackElevationWrite = 'PERM_RACK___ELEVATION_WRITE',
  PermRadioProfileRead = 'PERM_RADIO___PROFILE_READ',
  PermRadioProfileWrite = 'PERM_RADIO___PROFILE_WRITE',
  PermRateLimitRead = 'PERM_RATE___LIMIT_READ',
  PermRateLimitWrite = 'PERM_RATE___LIMIT_WRITE',
  PermSsidsRead = 'PERM_SSIDS_READ',
  PermSsidsWrite = 'PERM_SSIDS_WRITE',
  PermStatEventWrite = 'PERM_STAT___EVENT_WRITE',
  PermSwitchProfileRead = 'PERM_SWITCH___PROFILE_READ',
  PermSwitchProfileWrite = 'PERM_SWITCH___PROFILE_WRITE',
  PermUserCreate = 'PERM_USER_CREATE',
  PermUserRead = 'PERM_USER_READ',
  PermUserUpdate = 'PERM_USER_UPDATE',
  PermVirtualDeviceCreate = 'PERM_VIRTUAL___DEVICE_CREATE',
  PermVirtualDeviceReadLimited = 'PERM_VIRTUAL___DEVICE_READ_LIMITED',
  PermVlanRead = 'PERM_VLAN_READ',
  PermVlanWrite = 'PERM_VLAN_WRITE',
  PermVlanWriteRestricted = 'PERM_VLAN_WRITE_RESTRICTED',
}

export type PermissionsInput = {
  companySlug?: InputMaybe<Scalars['String']['input']>;
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type PermittedEmailDomain = {
  __typename?: 'PermittedEmailDomain';
  createdAt: Scalars['DateTime']['output'];
  domain: Scalars['String']['output'];
  sid?: Maybe<Scalars['UUID']['output']>;
};

export type PhyInterface = {
  __typename?: 'PhyInterface';
  /** The maximum transmission unit - max size in bytes of frames that the port can tx/rx */
  MTU?: Maybe<Scalars['Int']['output']>;
  UUID: Scalars['UUID']['output'];
  /** The allowed (tagged) VLANs that are allowed to have traffic on this interface. */
  allowedVLANs?: Maybe<Array<Vlan>>;
  connectedDevices?: Maybe<Array<PhyInterfaceConnectedDevice>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** The maximum amount of data that can be transmitted through the interface, in Mbps. */
  forcedPortSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** An optional filter to only accept certain frame types on this port. If null, all frame types are accepted. */
  frameAcceptTypeFilter?: Maybe<PhyInterfaceFrameAcceptTypeFilter>;
  /** Static hardware port label, if present. */
  hardwareLabel?: Maybe<Scalars['String']['output']>;
  /** If this interface represents a WAN port, denotes if there is any recent activity which would represent a connection to an ISP. */
  hasWANActivity?: Maybe<Scalars['Boolean']['output']>;
  /** Configuration for high availability associated with this interface, if on a controller configured in HA mode for this port. */
  highAvailability?: Maybe<HighAvailabilityControllerVirtualDeviceInfo>;
  /**
   * If this interface represents a WAN port, attempt to match the most recently reported IP for the port against the IP
   * address information given to us about the connection from the provider.
   * If there is not a corresponding ISP row in the database, but there is still activity on this WAN port, the result will
   * still be empty. Use `portSpeedMbps` to determine if the WAN port has activity regardless of us being able to match it to an ISP.
   */
  internetServicePlan?: Maybe<InternetServicePlan>;
  /** If assigning this interface as a DHCP client, this field indicates the IPv4 addresses to assign to the interface. These INET values should have no netmask. */
  ipv4ClientAddresses?: Maybe<Array<Scalars['IPV4']['output']>>;
  /** If assigning this interface as a DHCP client, this field indicates the protocol to use for IPv4 addresses. */
  ipv4ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a DHCP client, this field indicates the gateway IPv4 address. This INET value should have no netmask. */
  ipv4ClientGateway?: Maybe<Scalars['IPV4']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the subnet prefix length for the IPv4 addresses. */
  ipv4ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the IPv6 addresses to assign to the interface. These INET values should have no netmask. */
  ipv6ClientAddresses?: Maybe<Array<Scalars['IPV6']['output']>>;
  /** If assigning this interface as a DHCP client, this field indicates the protocol to use for IPv6 addresses. */
  ipv6ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a DHCP client, this field indicates the gateway IPv6 address. This INET value should have no netmask. */
  ipv6ClientGateway?: Maybe<Scalars['IPV6']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the subnet prefix length for the IPv6 addresses. */
  ipv6ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** is the port blocked due to too many ports flaps (link down/up) */
  isBlockedDueToFlaps: Scalars['Boolean']['output'];
  /** Whether or not the interface is implicitly bound to all VLANs, including any future VLANs that are created. If set to true, all VLAN bindings (excluding the native VLAN) will be tagged on this interface. */
  isBoundToAllVLANs: Scalars['Boolean']['output'];
  /** Whether or not the interface is enabled. Interfaces that are not enabled are stsrc/ill visible to the user. */
  isEnabled: Scalars['Boolean']['output'];
  isEthernet: Scalars['Boolean']['output'];
  /** If true, tagged frames are discarded when the VLAN ID of the frame does not match the VLAN ID of the port. */
  isIngressFilteringEnabled: Scalars['Boolean']['output'];
  /** If true, clients on isolated ports cannot communicate with other isolated ports. They can only talk to non-isolated ports */
  isIsolationEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** is the port blocked due to loopback detection */
  isLoopbackDetected?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not the interface is enabled to serve Power over Ethernet. */
  isPOEEnabled: Scalars['Boolean']['output'];
  isSFP: Scalars['Boolean']['output'];
  /** If true, this interface will be configured as an edge port in STP (Spanning Tree Protocol). */
  isSTPEdgePortEnabled: Scalars['Boolean']['output'];
  /** Whether or not the interface is enabled to serve Spanning Tree Protocol. */
  isSTPEnabled: Scalars['Boolean']['output'];
  /** is the port forwarding (true) or blocking (false) due to STP */
  isSTPForwarding?: Maybe<Scalars['Boolean']['output']>;
  /** Whether or not storm control is enabled on the port. */
  isStormControlEnabled: Scalars['Boolean']['output'];
  /** A trunk port is a port that is assigned to carry traffic for multiple VLANs. */
  isTrunkPort: Scalars['Boolean']['output'];
  /** An uplink interface connects the device to other networks or network devices. */
  isUplink: Scalars['Boolean']['output'];
  /** Only populated if `isUplink` is true. Indicates if this uplink phy interface is currently active, this should usually not be set manually. */
  isUplinkActive?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /** For switch port security, the max number of MAC addresses allowed on the port */
  maxMACAddressesAllowed?: Maybe<Scalars['Int']['output']>;
  /** Static value from hardware specification. */
  maxSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** The native (untagged) VLAN that is assigned to this interface. */
  nativeVLAN?: Maybe<Vlan>;
  /** The physical port number on the device that the interface is located at. */
  portNumber: Scalars['Int']['output'];
  /** The link speed on the port and populated in our time series database. */
  portSpeedMbps?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `isEthernet` or `isSFP` instead */
  portType: PortType;
  /** The power draw on the port and populated in our time series database for PoE. */
  powerDraw?: Maybe<Scalars['Float']['output']>;
  /** Receive rate limit for a port (in Kbps) */
  rxRateLimitKbps?: Maybe<Scalars['Int']['output']>;
  /** SFP module information (if a SFP module is plugged into the port) */
  sfpModuleInfo?: Maybe<SfpModuleInfo>;
  /** The percent of broadcast traffic (calculated as a percent of the max capability of the port) to drop if storm control is enabled. */
  stormControlBroadcastTrafficPercent: Scalars['Int']['output'];
  /** The percent of multicast traffic (calculated as a percent of the max capability of the port) to drop if storm control is enabled. */
  stormControlUnknownMulticastTrafficPercent: Scalars['Int']['output'];
  /** The percent of unicast traffic (calculated as a percent of the max capability of the port) to drop if storm control is enabled. */
  stormControlUnknownUnicastTrafficPercent: Scalars['Int']['output'];
  /** STP Path cost for the port. (1-200000000) */
  stpPathCost?: Maybe<Scalars['Int']['output']>;
  stpPortRole?: Maybe<StpPortRole>;
  /** STP Priority for the port. (0-240 in multiples of 16, default 128) */
  stpPriority?: Maybe<Scalars['Int']['output']>;
  /** The throughput (RX/TX) for the last 24h on the port populated in our time series database. */
  throughputLastDay?: Maybe<Array<ThroughputValueWithDirection>>;
  /** Transmit rate limit for a port (in Kbps) */
  txRateLimitKbps?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Only populated if `isUplink` is true. Holds the stats reported addresses of the physical interface, this should usually not be set manually. */
  uplinkExternalAddresses?: Maybe<Array<Scalars['UnmaskedIPPrefix']['output']>>;
  /** Can only be populated if `isUplink` is true. The MAC Address of the upstream device this phy interface is connected to. */
  uplinkGatewayMACAddress?: Maybe<Scalars['MacAddress']['output']>;
  /** Can only be populated if `isUplink` is true. The order an uplink should be used. A lower priority takes precedent including over uplinks with a NULL priority. */
  uplinkPriority?: Maybe<Scalars['Int']['output']>;
  /** Can only be populated if `isUplink` is true. */
  uplinkVLANID?: Maybe<Scalars['Int']['output']>;
  virtualDevice: VirtualDevice;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type PhyInterfaceConnectedDevice = {
  __typename?: 'PhyInterfaceConnectedDevice';
  /** @deprecated Use `hardwareDevice` instead. */
  device?: Maybe<Device>;
  /** If the macAddress above belongs to a meter device, we will load it from the DB by the MAC and populate this field. */
  hardwareDevice?: Maybe<HardwareDevice>;
  /** MAC Addresses of device that is plugged into the port. Populated in our time series database via LLDP. */
  macAddress: Scalars['MacAddress']['output'];
  /** A pointer back to the PhyInterface, which we can use to access the original device it belonged to, etc. */
  phyInterface: PhyInterface;
};

export enum PhyInterfaceFrameAcceptTypeFilter {
  TaggedOnly = 'TAGGED_ONLY',
  UntaggedOnly = 'UNTAGGED_ONLY',
}

/** Contains info about the PoE (Power over Ethernet) usage of a hardware device. */
export type PoEInfo = {
  __typename?: 'PoEInfo';
  max?: Maybe<Scalars['Float']['output']>;
  maxCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  used?: Maybe<Scalars['Float']['output']>;
  usedCreatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PolicyRoutingRule = {
  __typename?: 'PolicyRoutingRule';
  UUID: Scalars['UUID']['output'];
  bindings?: Maybe<Array<PolicyRoutingRuleBinding>>;
  createdAt: Scalars['DateTime']['output'];
  dstPortRanges: Array<PortRange>;
  dstPrefix: Scalars['IPPrefix']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  protocols: Array<IpProtocol>;
  srcPortRanges: Array<PortRange>;
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  srcVLAN?: Maybe<Vlan>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PolicyRoutingRuleBinding = {
  __typename?: 'PolicyRoutingRuleBinding';
  UUID: Scalars['UUID']['output'];
  metric: Scalars['Int']['output'];
  phyInterface: PhyInterface;
};

/** One of vlanUUID or phyInterfaceUUID is required. */
export type PolicyRoutingRuleBindingInput = {
  /** The phy interface must be an uplink. */
  phyInterfaceUUID: Scalars['UUID']['input'];
};

/** Port forwarding rules for a network define a how incoming traffic is forwarded to a local destination. */
export type PortForwardingRule = {
  __typename?: 'PortForwardingRule';
  UUID: Scalars['UUID']['output'];
  /** Restricts the port forward rule to only allow traffic to be forwarded if it originates from any of these IPs. */
  allowedRemoteIPAddresses?: Maybe<Array<Scalars['IP']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalIPAddress?: Maybe<Scalars['IP']['output']>;
  externalPhyInterface?: Maybe<PhyInterface>;
  /**
   * One of `externalPhyInterfaceUUID`, `externalVLANUUID`, or `externalIPAddress` must be set.
   * If provided, the port forwarding rule only applies for this external physical interface. Otherwise, the rule will apply for all external interfaces on a device. Application logic will ensure that this interface is an uplink interface.
   */
  externalPhyInterfaceUUID?: Maybe<Scalars['UUID']['output']>;
  /** The external port to forward to the local port. */
  externalPort: Scalars['Int']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** The local IP to forward traffic to. This INET must be a single IP. */
  localIPAddress: Scalars['IP']['output'];
  /** The local port that receives traffic from the port forward. */
  localPort: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The IP protocol to apply this rule on. These are the IANA defined protocol numbers. 6 = TCP, 17 = UDP. A value of 256 is reserved by IANA and will be used to denote all protocols. */
  protocol: PortIpProtocol;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PortIpProtocol {
  Tcp = 'TCP',
  Udp = 'UDP',
}

export type PortRange = {
  __typename?: 'PortRange';
  lower: Scalars['Int']['output'];
  upper: Scalars['Int']['output'];
};

export type PortRangeInput = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export enum PortType {
  Ethernet = 'ETHERNET',
  Sfp = 'SFP',
  Unknown = 'UNKNOWN',
}

/** A power distribution unit hardware device represents a physical power distribution unit. */
export type PowerDistributionUnitHardwareDevice = HardwareDevice & {
  __typename?: 'PowerDistributionUnitHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime metrics from the time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A power distribution unit hardware device represents a physical power distribution unit. */
export type PowerDistributionUnitHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** A power distribution unit virtual device is a logical representation of a physical power distribution unit. */
export type PowerDistributionUnitVirtualDevice = VirtualDevice & {
  __typename?: 'PowerDistributionUnitVirtualDevice';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceTemperatureCelsius?: Maybe<Scalars['Float']['output']>;
  deviceType: VirtualDeviceType;
  /** The ethernet interface speed. */
  ethernetSpeedMbps?: Maybe<Scalars['Int']['output']>;
  hardwareDevice?: Maybe<HardwareDevice>;
  /** The IP address from our time series database for the device. */
  ipAddress?: Maybe<Scalars['IPV4']['output']>;
  /** Circuit breaker good/bad */
  isCircuitBreakerGood?: Maybe<Scalars['Boolean']['output']>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** Indicates whether the PDU is grounded properly */
  isGrounded?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the PDU is surge protected */
  isSurgeProtectionGood?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  maxPowerAllowedWatts?: Maybe<Scalars['Float']['output']>;
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  outletInterfaces: Array<OutletInterface>;
  pendingNosVersion?: Maybe<PendingNosVersion>;
  totalPowerUsedWatts?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export enum PrincipalType {
  Device = 'DEVICE',
  Machine = 'MACHINE',
  User = 'USER',
}

export type Query = {
  __typename?: 'Query';
  /** Active clients are clients that currently appear both in the MAC and ARP tables of a network. */
  activeClients: ActiveClientsMetricsResponse;
  /** An Operator-only query to get all active networks belonging to a company with the given company support tier. */
  activeNetworksByCompanySupportTier: Array<Network>;
  /** Fetch all of the known types of Alerts that notify stakeholders of important events for a network. */
  alertDefinitions: Array<AlertDefinition>;
  alertReceiver: AlertReceiver;
  /** Get all alert receivers and their targets for a company. */
  alertReceiversForCompany: Array<AlertReceiver>;
  alertTargetWebhook: AlertTargetWebhook;
  /** Get alert targets of all types for a company. */
  alertTargets: AlertTargetsResponse;
  alertTargetsWebhook: Array<AlertTargetWebhook>;
  /**
   * A Config1-only resolver. Band defaults to 2.4Ghz.
   * @deprecated Use channelUtilizationByAP for config 2 networks
   */
  apChannelUtilization: ApChannelUtilizationResponse;
  /** Returns the uptime (how long since a device last booted) of the APs in the network. */
  apUptime: ApUptimeResponse;
  applicationDNSFirewallRule: ApplicationDnsFirewallRule;
  /** Given a hostname, returns the application that would be applied to it. */
  applicationDNSFirewallRuleApplicationForHostname: ApplicationDnsFirewallRuleApplication;
  /**
   * Fetch all applications that DNS firewall rules can be associated with.
   * This currently returns all applications – in the future this will only return visible applications for customers.
   */
  applicationDNSFirewallRuleApplications: Array<ApplicationDnsFirewallRuleApplication>;
  /** Fetch all available Categories of DNS firewall rules. */
  applicationDNSFirewallRuleCategories: Array<ApplicationDnsFirewallRuleCategory>;
  /** Given a hostname, returns the category of DNS firewall rule that would be applied to it. */
  applicationDNSFirewallRuleCategoryForHostname: ApplicationDnsFirewallRuleCategory;
  /** Fetch all groups of DNS firewall rules. */
  applicationDNSFirewallRuleGroups: Array<ApplicationDnsFirewallRuleGroup>;
  /** Fetch all application DNS firewall rules for a network. */
  applicationDNSFirewallRulesForNetwork: Array<ApplicationDnsFirewallRule>;
  /**
   * A bulk-version of isNosFeatureEnabledForCompany for multiple keys.
   * In dev, this always returns true for a key if it is not recognized on the backend.
   * This checks if *any* network for companySlug supports nosFeatureKeys.
   */
  areNosFeaturesEnabledForCompany: Array<NosFeatureEnabled>;
  /**
   * A bulk-version of isNosFeatureEnabledForNetwork for multiple keys.
   * In dev, this always returns true for a key if it is not recognized on the backend.
   */
  areNosFeaturesEnabledForNetwork: Array<NosFeatureEnabled>;
  /** Fetch an authentication token by UUID. */
  authenticationToken: AuthenticationToken;
  autoChannelSelectionJob: AutoChannelSelectionJob;
  autoChannelSelectionJobsForNetwork?: Maybe<Array<AutoChannelSelectionJob>>;
  /** Fetch a Switch Auto Configuration Job by its UUID. */
  autoSwitchConfigurationJob: SwitchAutoConfigurationJob;
  /** Fetch all Switch Auto Configuration Jobs for a network. */
  autoSwitchConfigurationJobsForNetwork?: Maybe<Array<SwitchAutoConfigurationJob>>;
  autoVPNGroup: AutoVpnGroup;
  autoVPNGroupsForCompany: Array<AutoVpnGroup>;
  autoVPNGroupsForNetwork: Array<AutoVpnGroup>;
  autoVPNMember: AutoVpnMember;
  /**
   * A CLI session creates a one time use bearer session that must be
   * verified before issuing a token. Once it is verified the session can
   * no longer be used.
   */
  bearerSession: BearerSession;
  /** Return all of the BSSIDs associated with hardware devices associated with virtual devices in a given network. */
  bssidsForNetwork: Array<Bssid>;
  bulkNOSUpgrade: BulkNosUpgrade;
  bulkNOSUpgrades: Array<BulkNosUpgrade>;
  captivePortal: CaptivePortal;
  /** Returns all clients that have seen or interacted with the captive portal in the last specified amount of hours. May contain duplicate clients. */
  captivePortalAllClients: Array<CaptivePortalClient>;
  /** Returns authorized clients to the captive portal in the last specified amount of hours. */
  captivePortalAuthorizedClients: Array<CaptivePortalClient>;
  captivePortalForNetwork?: Maybe<CaptivePortal>;
  captivePortalForVLAN?: Maybe<CaptivePortal>;
  captivePortalForWebhook?: Maybe<CaptivePortal>;
  /** @deprecated Use `captivePortalForNetwork` instead; there is only at-most-one per network. */
  captivePortalsForNetwork: Array<CaptivePortal>;
  /** Returns one timeseries per radio band for all of the radio bands broadcasted by the AP. */
  channelUtilizationByAP: Array<ChannelUtilizationResponseV2>;
  /** Returns a timeseries for channel utilization for the client, aggregated over the APs that the client has been connected to. */
  channelUtilizationByClient: Array<ClientChannelUtilizationTimeseriesValue>;
  /** Returns a time series for each radio band for the APs in the network. */
  channelUtilizationByNetwork: Array<ChannelUtilizationResponseV2>;
  /** @deprecated Prefer wirelessClientMetricsByX queries instead. */
  clientMetrics: ClientMetricsResponse;
  clientVPNClient: ClientVpnClient;
  clientVPNClients: Array<ClientVpnClient>;
  clientVPNServer: ClientVpnServer;
  clientVPNServers: Array<ClientVpnServer>;
  /** Get a company user by UUID. */
  companyUser: CompanyUser;
  /** List all company users for the specified company. */
  companyUsers: Array<CompanyUser>;
  /** Get recent console login challenges for a device. */
  consoleLogins: Array<ConsoleLogin>;
  /** Timeseries data of DHCP server uptime metrics for a single controller. */
  controllerDHCPUptime: ControllerDhcpUptimeResponse;
  /** Timeseries data of requests/sec per DNS server for a single controller. */
  controllerDNSRequestRates: ControllerDnsRequestRatesResponse;
  /** Per port timeseries metrics data for a single controller. */
  controllerPortMetrics: ControllerPortMetricsResponse;
  /** Per port timeseries metrics data as a rate per second for a single controller. */
  controllerPortMetricsRate: ControllerPortMetricsRateResponse;
  /** Per port stats for a specified controller over a lookback period. Encompasses receive/transmission data counters and hardware operational status. */
  controllerPortStats: Array<ControllerPortStat>;
  /** @deprecated Use `hardwareDevice` query instead, accessing `virtualDevice` within the hardware device if necessary. */
  device: Device;
  deviceCellularStatus?: Maybe<CellularStatus>;
  /** Fetch a specific config for a device by version. */
  deviceConfigByVersion: DeviceConfig;
  /** Fetch the config override for a device; should rarely be used if ever in production. */
  deviceConfigOverride: DeviceConfigOverride;
  /** Fetch all configs for a device over time ordered by version. */
  deviceConfigs?: Maybe<Array<DeviceConfig>>;
  deviceHeartbeatForDevice: DeviceHeartbeat;
  /** Retrieves the cellular usage of a controller aggregated in the last day. This is its own resolver so that it can be used for both config 1 and config 2. */
  deviceLastDayCellularUsage?: Maybe<CellularUsage>;
  /** Config 1 */
  deviceUptime: Scalars['Duration']['output'];
  /** @deprecated Use `virtualDevicesForNetwork` instead, accessing `hardwareDevice` within the virtual devices if necessary. */
  devicesForNetwork: Array<Device>;
  /** Get a DHCP option. */
  dhcpOption: DhcpOption;
  /** Get a DHCP reserved range. */
  dhcpReservedRange: DhcpReservedRange;
  /** Get a DHCP rule. */
  dhcpRule: DhcpRule;
  /** Get all DHCP rules for a Network. */
  dhcpRulesForNetwork: Array<DhcpRule>;
  /** Get a DHCP static mapping. */
  dhcpStaticMapping: DhcpStaticMapping;
  /** Returns list of discovered services on the network in the last specified amount of minutes. */
  discoveredServicesForNetwork: Array<DiscoveredService>;
  /** Get a DNS host mapping. */
  dnsHostMapping: DnsHostMapping;
  /** Generates a pre-signed URL for downloading a file from S3. */
  downloadUrl: FileDownloadPayload;
  encryption8021XsForNetwork: Array<Encryption8021X>;
  /** Return the Switch virtual device / phy interface that the given MAC address is associated with using LLDP stats. */
  findSwitchLLDPEntryForMAC?: Maybe<MacAddressSwitchEntry>;
  /** Return the Switch virtual device / phy interface that the given MAC address is associated with. */
  findSwitchesForClientMAC: Array<MacAddressSwitchEntry>;
  /** Get a firewall rule by UUID. */
  firewallRule: FirewallRule;
  /** Get all firewall rules for a network. */
  firewallRulesForNetwork: Array<FirewallRule>;
  /** Get a floor plan by UUID. */
  floorPlan: FloorPlan;
  /** List all floor plans for a network. */
  floorPlansForNetwork: Array<FloorPlan>;
  getCompany?: Maybe<Company>;
  /** Return a hardware device by its serial number. */
  hardwareDevice: HardwareDevice;
  /** Return a hardware device by its MAC address. */
  hardwareDeviceByMACAddress: HardwareDevice;
  /** Return a list of hardware devices that match the filter. */
  hardwareDevices: Array<HardwareDevice>;
  /** View host monitoring metrics for a network. */
  hostMonitoringForNetwork: Array<HostMonitoringResponse>;
  /** Get a host monitoring target. */
  hostMonitoringTarget: HostMonitoringTarget;
  /** List all hosts that are monitored for a network. */
  hostMonitoringTargetsForNetwork: Array<HostMonitoringTarget>;
  /** Get the valid types for Hotspot 2.0 (Passpoint) configuration. */
  hs20ValidTypes: Hs20ValidTypesResponse;
  /** @deprecated Incidents are no longer supported in Config 2. */
  incident: Incident;
  /** List all links between VLANs that are allowed to communicate with each other. */
  interVLANCommunicationPermittedPairs: Array<InterVlanCommunicationPermittedPair>;
  /** Get an internal note by UUID. */
  internalNote: InternalNote;
  /** List all internal notes for a network. */
  internalNotesForNetwork: Array<InternalNote>;
  /** Internet service plan for a given UUID. */
  internetServicePlan?: Maybe<InternetServicePlan>;
  /** Retrieves all providers applicable to the given company. At the moment, this returns ALL known providers. */
  internetServicePlanProviders: Array<InternetServicePlanProvider>;
  /** Internet service plans for a given company, and optionally scoped by a network. */
  internetServicePlans: Array<InternetServicePlan>;
  /** Get an IPSec tunnel by UUID. */
  ipSecTunnel: IpSecTunnel;
  /** List all IPSec tunnels for a network. */
  ipSecTunnelsForNetwork: Array<IpSecTunnel>;
  /**
   * In dev, this always returns true if the key is not recognized on the backend.
   * This checks if *any* network for companySlug supports nosFeatureKey.
   */
  isNosFeatureEnabledForCompany: Scalars['Boolean']['output'];
  /** In dev, this always returns true if the key is not recognized on the backend. */
  isNosFeatureEnabledForNetwork: Scalars['Boolean']['output'];
  jobTrackers: Array<NetworkOnboardingJobData>;
  /** Fetch the latest config that a device has acknowledged. */
  latestAcknowledgedDeviceConfig: DeviceConfig;
  /** Config 1 */
  latestDeviceCPULoad: LatestDeviceCpuLoad;
  /**
   * Fetches the latest config rendered for a device. Does not re-render the config;
   * use the `renderDeviceConfig` mutation for that.
   */
  latestDeviceConfig: DeviceConfig;
  /** Config 1 */
  latestDeviceMemoryUsage: LatestDeviceMemoryUsage;
  /** Returns all of the Companies, excluding Companies that only have Connect quotes. */
  listCompanies: Array<Company>;
  /** Get the maintenance window for a network (only one). */
  maintenanceWindowForNetwork: MaintenanceWindow;
  /** See the list of mutations that a network has undergone. */
  mutationAuditLogEntriesForNetwork: Array<MutationAuditLogEntry>;
  /** Get a network. */
  network: Network;
  /** Get a network by its slug. */
  networkBySlug: Network;
  /**
   * Get the latest connected clients for a network.
   * The values in filter can alter this behavior.
   */
  networkClients: Array<NetworkClient>;
  /** Get a report of the total number of bytes transmitted and received by each client connected to a network, on a per day basis. */
  networkClientsTxRxPerDay: Array<NetworkClientTxRxPerDay>;
  /**
   * Returns the network routes of a virtual device for the most recently ack'd config.
   * This reads from the rendered config so includes all implied routes from other features.
   */
  networkRoutesForVirtualDevice: Array<NetworkRoute>;
  /**
   * Get metrics about network's uplink's quality (i.e. the ISP quality).
   * Quality is the percentage of successful pings to common web servers over the ISP connection.
   */
  networkUplinkQuality: NetworkUplinkQualityResponse;
  /**
   * Get metrics about network's uplink's throughput (i.e. the ISP throughput).
   * Throughput is the amount of data transferred over the ISP connection.
   */
  networkUplinkThroughput: NetworkUplinkThroughputMetricsResponse;
  /** Get all networks that match the given filter. */
  networks: Array<Network>;
  /** Get all of a company's networks. */
  networksForCompany: Array<Network>;
  /** Get uplink quality metrics for multiple networks. */
  networksUplinkQualities: Array<NetworkUplinkQualityResponse>;
  /** Always returns true and does nothing. */
  noop: Scalars['Boolean']['output'];
  /** Get a NOS feature by UUID. */
  nosFeature: NosFeature;
  /** List all NOS features. */
  nosFeatures: Array<NosFeature>;
  /** Retrieves all NOS versions that were scheduled for a virtual device. */
  nosHistoryForVirtualDevice?: Maybe<Array<VirtualDeviceNosHistory>>;
  /** List all NOS versions with optional filters. */
  nosVersions: Array<NosVersion>;
  /** @deprecated No longer supported. */
  notificationPreference: NotificationPreferences;
  /** @deprecated No longer supported. */
  notificationPreferences: NotificationPreferencesResponse;
  /** Get a notification setting by UUID. */
  notificationSetting: NotificationSetting;
  /** List all notification settings for a network. */
  notificationSettingsForNetwork: Array<NotificationSetting>;
  /** Get a 1:1 NAT rule by UUID. */
  oneToOneNATRule: OneToOneNatRule;
  /** List all 1:1 NAT rules for a network. */
  oneToOneNATRulesForNetwork: Array<OneToOneNatRule>;
  /** Get all outlet interfaces for a Power Distribution Unit (PDU) virtual device. */
  outletInterfacesForVirtualDevice: Array<OutletInterface>;
  /** Retrieves the permissions that are assigned to the current user, in context of the provided params. */
  permissions: Array<PermissionAssignment>;
  /** Get the phy interfaces associated with a virtual device. */
  phyInterfacesForVirtualDevice: Array<PhyInterface>;
  policyRoutingRule: PolicyRoutingRule;
  policyRoutingRulesForNetwork: Array<PolicyRoutingRule>;
  /** Get a port forwarding rule by UUID. */
  portForwardingRule: PortForwardingRule;
  /** Get all port forwarding rules for a network. */
  portForwardingRulesForNetwork: Array<PortForwardingRule>;
  /** Get a rack elevation by UUID. */
  rackElevation: RackElevation;
  /** Get all rack elevations for a network. */
  rackElevations: Array<RackElevation>;
  /** Get the radio profiles for a network. */
  radioProfilesForNetwork: Array<RadioProfile>;
  /** Get a rate limit rule by UUID. */
  rateLimitRule: RateLimitRule;
  /** Get all rate limit rules for a network. */
  rateLimitRulesForNetwork: Array<RateLimitRule>;
  /** Events in the event log represent notable state changes in the state of the network. */
  recentEventLogEvents?: Maybe<Array<EventLogEvent>>;
  /** Retrieves the roles that are assigned to the current user, in context of the provided params. */
  roles: Array<RoleAssignment>;
  /** Return the hardware devices that are not associated with a virtual device; they're not in use but affiliated with the network for potential future use. */
  spareHardwareDevicesForNetwork: Array<HardwareDevice>;
  /** Get all speedtest servers for a network. */
  speedtestServersForNetwork: Array<SpeedtestServer>;
  /** List all SSIDs for a network. */
  ssidsForNetwork: Array<Ssid>;
  /**
   * Retrieves the options that can be configured for SSO, focusing on WorkOS. Fields include:
   * 1. Available groups
   * 2. Connected directories
   */
  ssoOptions: SsoOptions;
  /** Get a static route by UUID. */
  staticRoute: StaticRoute;
  /** List all static routes for a network. */
  staticRoutesForNetwork: Array<StaticRoute>;
  /**
   * Fetch stats (device-generated metrics) for a virtual device.
   * @deprecated Use StatsForVirtualDevices instead.
   */
  statsForVirtualDevice: Array<Stat>;
  /** Fetch stats (device-generated metrics) for multiple virtual devices. */
  statsForVirtualDevices: Array<Stat>;
  /** Fetch all MAC address or OUI to VLAN bindings for a network. */
  switchAutoConfigMACAddressVLANBindingsForNetwork: Array<SwitchAutoConfigMacAddressVlanBinding>;
  /** Return discard rates for a switch's ports. Discard rates are the number of packets that were dropped. */
  switchDiscardRatesPerPort: SwitchMetricsResponse;
  /** Return the error rates per port for a specified switch. */
  switchErrorRatesPerPort: SwitchMetricsResponse;
  /** Return the MAC table (all MAC addresses seen on its ports) for a specified switch. */
  switchMACTable: Array<SwitchMacTableEntry>;
  /**
   * Per port timeseries metrics data for a single switch.
   * @deprecated Use SwitchPortMetricsRate instead.
   */
  switchPortMetrics: SwitchPortMetricsResponse;
  /** Per port timeseries metrics data as a rate per second for a single switch. */
  switchPortMetricsRate: SwitchPortMetricsRateResponse;
  /** Per port stats for a specified switch over a lookback period */
  switchPortStats: Array<SwitchPortStat>;
  /** Return the throughput per port for a specified switch. Throughput is measured in bytes per second. */
  switchThroughputPerPort: SwitchMetricsResponse;
  /** Fetch system performance stats for a virtual device (as opposed to network-related data plan stats). */
  systemInfoStatForVirtualDevice: Array<SystemInfoStat>;
  /** A list of hardware devices that are detected as connected to virtual devices in the network without an associated virtual device of their own. */
  unassignedHardwareDevicesForNetwork: Array<UnassignedHardwareDeviceForNetworkResponse>;
  /** Returns network uplink (WAN) physical interfaces for the network (controllers interfaces only). */
  uplinkPhyInterfacesForNetwork: Array<PhyInterface>;
  /** Checks if the calling user has access to a network. Responds with the expiration of the access if found. */
  userNetworkAccessExpiry?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Return a list of valid channels for a radio, by band and network.
   * The list of valid channels is based on the regulatory domain of the network.
   */
  validChannels: Array<ValidChannelsResponse>;
  /** Return a virtual device by its UUID. */
  virtualDevice: VirtualDevice;
  /** Return a list of virtual devices for a network that match the given filter. */
  virtualDevicesForNetwork: Array<VirtualDevice>;
  /** Get a VLAN by UUID. */
  vlan: Vlan;
  /** Get all VLANs for a network. */
  vlans: Array<Vlan>;
  /** Retrieve the results or check the status of a WAN speedtest. */
  wanSpeedtestJob: WanSpeedtestJob;
  /** Retrieve the results for all WAN speedtest on a network. */
  wanSpeedtestJobsForNetwork: Array<WanSpeedtestJob>;
  /** Get all Wifi Observer Settings for a virtual device. */
  wifiObserverSettings?: Maybe<Array<Maybe<WifiObserverSetting>>>;
  /** A list of events that tell us when a client connected to an AP, disassociated from an AP, or failed to connect to an AP, for all clients interacting with an AP. */
  wirelessClientConnectionEventsByAP: Array<WirelessClientConnectionEvent>;
  /** A list of events that tell us when a client connected to an AP, disassociated from an AP, or failed to connect to an AP, for a single client. */
  wirelessClientConnectionEventsByClient: Array<WirelessClientConnectionEvent>;
  /**
   * Timeseries data of wireless connectivity metrics for all clients connected to an AP.
   * Returns a single aggregated timeseries, rather than one per client.
   */
  wirelessClientMetricsByAP: ClientMetricsTimeseriesResponse;
  /** Timeseries data of wireless connectivity metrics for a single client. */
  wirelessClientMetricsByClient: ClientMetricsTimeseriesResponse;
};

export type QueryActiveClientsArgs = {
  filter: MetricsFilterInput;
  input?: InputMaybe<ActiveClientsInput>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryActiveNetworksByCompanySupportTierArgs = {
  supportTier: CompanySupportTier;
};

export type QueryAlertReceiverArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAlertReceiversForCompanyArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetWebhookArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetsArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryAlertTargetsWebhookArgs = {
  companyUUID: Scalars['UUID']['input'];
};

export type QueryApChannelUtilizationArgs = {
  band?: InputMaybe<RadioBand>;
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryApUptimeArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryApplicationDnsFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryApplicationDnsFirewallRuleApplicationForHostnameArgs = {
  hostname: Scalars['String']['input'];
};

export type QueryApplicationDnsFirewallRuleCategoryForHostnameArgs = {
  hostname: Scalars['String']['input'];
};

export type QueryApplicationDnsFirewallRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAreNosFeaturesEnabledForCompanyArgs = {
  companySlug: Scalars['String']['input'];
  nosFeatureKeys: Array<Scalars['String']['input']>;
};

export type QueryAreNosFeaturesEnabledForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  nosFeatureKeys: Array<Scalars['String']['input']>;
};

export type QueryAuthenticationTokenArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAutoChannelSelectionJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type QueryAutoChannelSelectionJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoSwitchConfigurationJobArgs = {
  jobUUID: Scalars['UUID']['input'];
};

export type QueryAutoSwitchConfigurationJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnGroupArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnGroupsForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryAutoVpnGroupsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryAutoVpnMemberArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBearerSessionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBssidsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryBulkNosUpgradeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryBulkNosUpgradesArgs = {
  filter: BulkNosUpgradesFilterInput;
};

export type QueryCaptivePortalArgs = {
  input?: InputMaybe<GetCaptivePortalInput>;
  uuid: Scalars['UUID']['input'];
};

export type QueryCaptivePortalAllClientsArgs = {
  networkUUID: Scalars['UUID']['input'];
  numHoursLookback: Scalars['Int']['input'];
};

export type QueryCaptivePortalAuthorizedClientsArgs = {
  networkUUID: Scalars['UUID']['input'];
  numHoursLookback: Scalars['Int']['input'];
};

export type QueryCaptivePortalForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryCaptivePortalForVlanArgs = {
  vlanUUID: Scalars['UUID']['input'];
};

export type QueryCaptivePortalForWebhookArgs = {
  webhookKey: Scalars['UUID']['input'];
};

export type QueryCaptivePortalsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ChannelUtilizationTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByClientArgs = {
  filter: ChannelUtilizationTimeseriesFilterInput;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryChannelUtilizationByNetworkArgs = {
  filter: ChannelUtilizationTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryClientMetricsArgs = {
  filter: MetricsFilterInput;
  serialNumber: Scalars['String']['input'];
};

export type QueryClientVpnClientArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryClientVpnClientsArgs = {
  serverUUID: Scalars['UUID']['input'];
};

export type QueryClientVpnServerArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryClientVpnServersArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryCompanyUserArgs = {
  companyUserUUID: Scalars['UUID']['input'];
  input?: InputMaybe<GetCompanyUserInput>;
};

export type QueryCompanyUsersArgs = {
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<GetCompanyUserInput>;
};

export type QueryConsoleLoginsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  serialNumber: Scalars['String']['input'];
};

export type QueryControllerDhcpUptimeArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerDnsRequestRatesArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortMetricsArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortMetricsRateArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryControllerPortStatsArgs = {
  lookbackHours?: InputMaybe<Scalars['Int']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceCellularStatusArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceConfigByVersionArgs = {
  serialNumber: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type QueryDeviceConfigOverrideArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceConfigsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceHeartbeatForDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceLastDayCellularUsageArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDeviceUptimeArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryDevicesForNetworkArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDhcpOptionArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpReservedRangeArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDhcpRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDhcpStaticMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDiscoveredServicesForNetworkArgs = {
  input: DiscoveredServicesInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryDnsHostMappingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryDownloadUrlArgs = {
  input: DownloadUrlInput;
};

export type QueryEncryption8021XsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFindSwitchLldpEntryForMacArgs = {
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFindSwitchesForClientMacArgs = {
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFirewallRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryFirewallRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryFloorPlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryFloorPlansForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryGetCompanyArgs = {
  slug: Scalars['String']['input'];
};

export type QueryHardwareDeviceArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryHardwareDeviceByMacAddressArgs = {
  macAddress: Scalars['MacAddress']['input'];
};

export type QueryHardwareDevicesArgs = {
  filter?: InputMaybe<HardwareDevicesFilter>;
};

export type QueryHostMonitoringForNetworkArgs = {
  filter: MetricsFilterInput;
  hostIPs: Array<Scalars['String']['input']>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryHostMonitoringTargetArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryHostMonitoringTargetsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryIncidentArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryInterVlanCommunicationPermittedPairsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryInternalNoteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryInternalNotesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryInternetServicePlanArgs = {
  uuid: Scalars['UUID']['input'];
};

export type QueryInternetServicePlanProvidersArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryInternetServicePlansArgs = {
  companySlug: Scalars['String']['input'];
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryIpSecTunnelArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryIpSecTunnelsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryIsNosFeatureEnabledForCompanyArgs = {
  companySlug: Scalars['String']['input'];
  nosFeatureKey: Scalars['String']['input'];
};

export type QueryIsNosFeatureEnabledForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
  nosFeatureKey: Scalars['String']['input'];
};

export type QueryLatestAcknowledgedDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceCpuLoadArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceConfigArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryLatestDeviceMemoryUsageArgs = {
  serialNumber: Scalars['String']['input'];
};

export type QueryListCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMaintenanceWindowForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryMutationAuditLogEntriesForNetworkArgs = {
  filter?: InputMaybe<MutationAuditLogFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNetworkBySlugArgs = {
  companySlug: Scalars['String']['input'];
  networkSlug: Scalars['String']['input'];
};

export type QueryNetworkClientsArgs = {
  filter?: InputMaybe<NetworkClientsFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkClientsTxRxPerDayArgs = {
  networkUUID: Scalars['UUID']['input'];
  numDaysLookback: Scalars['Int']['input'];
};

export type QueryNetworkRoutesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryNetworkUplinkQualityArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworkUplinkThroughputArgs = {
  filter: MetricsFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryNetworksArgs = {
  filter: NetworksFilter;
};

export type QueryNetworksForCompanyArgs = {
  companySlug: Scalars['String']['input'];
};

export type QueryNetworksUplinkQualitiesArgs = {
  filter: MetricsFilterInput;
  networkUUIDs: Array<Scalars['UUID']['input']>;
};

export type QueryNosFeatureArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNosHistoryForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryNosVersionsArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isGA?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNotificationPreferenceArgs = {
  uuid: Scalars['UUID']['input'];
};

export type QueryNotificationPreferencesArgs = {
  input: GetNotificationPreferencesInput;
};

export type QueryNotificationSettingArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryNotificationSettingsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryOneToOneNatRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryOneToOneNatRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryOutletInterfacesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryPermissionsArgs = {
  input: PermissionsInput;
};

export type QueryPhyInterfacesForVirtualDeviceArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryPolicyRoutingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryPolicyRoutingRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryPortForwardingRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryPortForwardingRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRackElevationArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryRackElevationsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRadioProfilesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRateLimitRuleArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryRateLimitRulesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryRecentEventLogEventsArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: Scalars['Int']['input'];
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryRolesArgs = {
  input?: InputMaybe<RolesInput>;
};

export type QuerySpareHardwareDevicesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySpeedtestServersForNetworkArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QuerySsidsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySsoOptionsArgs = {
  input: SsoOptionsInput;
};

export type QueryStaticRouteArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryStaticRoutesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryStatsForVirtualDeviceArgs = {
  filter: StatsFilter;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryStatsForVirtualDevicesArgs = {
  filter: NetworkStatsFilter;
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySwitchAutoConfigMacAddressVlanBindingsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QuerySwitchDiscardRatesPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySwitchErrorRatesPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySwitchMacTableArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortMetricsArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortMetricsRateArgs = {
  filter: MetricsFilterInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchPortStatsArgs = {
  lookbackHours?: InputMaybe<Scalars['Int']['input']>;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QuerySwitchThroughputPerPortArgs = {
  filter: MetricsFilterInput;
  input: MetricsSerialNumberDirectionInput;
};

export type QuerySystemInfoStatForVirtualDeviceArgs = {
  filter: SystemInfoStatsFilter;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryUnassignedHardwareDevicesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryUplinkPhyInterfacesForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryUserNetworkAccessExpiryArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryValidChannelsArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryVirtualDeviceArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryVirtualDevicesForNetworkArgs = {
  filter?: InputMaybe<DevicesForNetworkFilter>;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryVlanArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryVlansArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWanSpeedtestJobArgs = {
  UUID: Scalars['UUID']['input'];
};

export type QueryWanSpeedtestJobsForNetworkArgs = {
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWifiObserverSettingsArgs = {
  virtualDeviceUUID: Scalars['UUID']['input'];
};

export type QueryWirelessClientConnectionEventsByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWirelessClientConnectionEventsByClientArgs = {
  filter: ClientMetricsTimeseriesFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWirelessClientMetricsByApArgs = {
  apVirtualDeviceUUID: Scalars['UUID']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
  networkUUID: Scalars['UUID']['input'];
};

export type QueryWirelessClientMetricsByClientArgs = {
  filter: ClientMetricsTimeseriesFilterInput;
  macAddress: Scalars['MacAddress']['input'];
  networkUUID: Scalars['UUID']['input'];
};

export type RpcCableTestResponse = {
  __typename?: 'RPCCableTestResponse';
  pairA: RpcCableTestTwistedPairResult;
  pairB: RpcCableTestTwistedPairResult;
  pairC: RpcCableTestTwistedPairResult;
  pairD: RpcCableTestTwistedPairResult;
};

export type RpcCableTestTwistedPairResult = {
  __typename?: 'RPCCableTestTwistedPairResult';
  estimatedLengthInMeters: Scalars['Int']['output'];
  status: RpcCableTestTwistedPairStatus;
};

/**
 * Cable test checks each pair of wires (Ethernet cables have 4 pairs) to find if they are crimped correctly.
 * The wires can be good, open (not connected), short or crosstalk (bad cabling).
 * Emplus's implementation can also estimate the length of the ethernet cable (in meters).
 * Cable test can only be run on rj45 ethernet ports and not on SFP ports.
 */
export enum RpcCableTestTwistedPairStatus {
  Crosstalk = 'CROSSTALK',
  Good = 'GOOD',
  Open = 'OPEN',
  OpenShort = 'OPEN_SHORT',
  Short = 'SHORT',
  Unknown = 'UNKNOWN',
}

export type RpcEapolTestForSerialInput = {
  /** The UUID of the RADIUS server profile (either primary or secondary encryption 8021x) to use for the EAPOL test. */
  Encryption8021XUUID: Scalars['UUID']['input'];
  /** The password to use for the EAPOL test. */
  password: Scalars['String']['input'];
  /** The serial number of the device to run the EAPOL test on. */
  serialNumber: Scalars['String']['input'];
  /**
   * The UUID of the SSID to use for the EAPOL test. An Eapol test without ssid will omit sending this field to the auth
   * server.
   */
  ssidUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** The username to use for the EAPOL test. */
  user: Scalars['String']['input'];
};

export type RpcEapolTestForSerialResponse = {
  __typename?: 'RPCEapolTestForSerialResponse';
  /** A human-readable error message if the EAPOL test failed with an unknown error. */
  error_reason?: Maybe<Scalars['String']['output']>;
  /** The serial number this tests was run for. */
  serialNumber: Scalars['String']['output'];
  /** The status of EAPOL server response. */
  status: RpcEapolTestForSerialStatus;
};

/**
 * EAPOL (Extensible Authentication Protocol over LAN) is a network authentication protocol used in IEEE 802.1X to
 * authenticate users and computers in a network through a RADIUS server. It is used in wireless networks (WPA-Enterprise)
 * to secure access. The EAPOL test RPC runs directly on a WOS access point and simulates a client connecting wirelessly to
 * an AP performing RADIUS authentication. A successful EAPOL test means that the AP can authenticate a client with the
 * RADIUS server using the provided credentials and validates that a client can connect to the network.
 */
export enum RpcEapolTestForSerialStatus {
  /** The EAPOL test failed due to bad user/password credentials. */
  FailureBadCredentials = 'FAILURE_BAD_CREDENTIALS',
  /** The EAPOL test failed due to an incorrect RADIUS secret. */
  FailureIncorrectRadiusSecret = 'FAILURE_INCORRECT_RADIUS_SECRET',
  /** The EAPOL test failed due to the server not responding in time. */
  FailureRadiusServerTimeout = 'FAILURE_RADIUS_SERVER_TIMEOUT',
  /** The EAPOL test failed due to an unknown error. See `error_reason` for more details. */
  FailureUnknown = 'FAILURE_UNKNOWN',
  /** The EAPOL test was successful. */
  Success = 'SUCCESS',
}

export type RpcEapolTestForSerialsInput = {
  /** The UUID of the RADIUS server profile (either primary or secondary encryption 8021x) to use for the EAPOL test. */
  Encryption8021XUUID: Scalars['UUID']['input'];
  /** The password to use for the EAPOL test. */
  password: Scalars['String']['input'];
  /** The serial numbers of the devices to run the EAPOL test on. */
  serialNumbers: Array<Scalars['String']['input']>;
  /**
   * The UUID of the SSID to use for the EAPOL test. An Eapol test without ssid will omit sending this field to the auth
   * server.
   */
  ssidUuid?: InputMaybe<Scalars['UUID']['input']>;
  /** The username to use for the EAPOL test. */
  user: Scalars['String']['input'];
};

/** A request to power-cycle an outlet on a PDU. */
export type RpcpduOutletCycleInput = {
  /** A list of outlet UUIDs to cycle */
  outletUUIDs: Array<Scalars['UUID']['input']>;
};

/** A request to ping to a device. */
export type RpcPingInput = {
  /** The number of pings to the devices sends at once */
  burstSize?: Scalars['Int']['input'];
  /** The host IP address to ping out to. */
  host: Scalars['String']['input'];
  /** The interval between burst's of pings. Defaults to 1sec */
  intervalSeconds?: Scalars['Int']['input'];
  /** The number of bursts to send */
  numberOfBursts?: Scalars['Int']['input'];
  /** The UUID of the physical interface if user selects ping out of a wan port. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The device issuing the ping. */
  serialNumber: Scalars['String']['input'];
  /** Max seconds, before ping exits regardless of how many packets have been sent or received */
  timeoutSeconds?: Scalars['Int']['input'];
  /** The UUID of the vlan if user selects ping out of a vlan interface (for both client ping and wan ping). */
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The WAN interface to use for the ping (`wan0` or `wan1`). Defaults to `wan0`. Config-1 only */
  wanInterface?: InputMaybe<Scalars['String']['input']>;
};

export type RpcPingResponse = {
  __typename?: 'RPCPingResponse';
  /** The mean round trip time in milliseconds, if any were successful. */
  roundTripTimeMs?: Maybe<Scalars['Int']['output']>;
  /** The number of successful pings divided by the total number of pings, if any were issued. */
  successRate: Scalars['Float']['output'];
};

/** A request to power-cycle a port on a Meter switch. */
export type RpcSwitchPortCycleInput = {
  /** The number of seconds between the down and up of the cycle (range 1-30s). */
  cycleDownSec?: InputMaybe<Scalars['Int']['input']>;
  /** Enable or disable PoE on the port. */
  poeCycle?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enable or disable the port. */
  portCycle?: InputMaybe<Scalars['Boolean']['input']>;
  /** The port number to cycle. */
  portNumber: Scalars['Int']['input'];
  /** The serial number of the switch whose port needs cycling. */
  serialNumber: Scalars['String']['input'];
};

/** A request to run a WAN speedtest on a Meter device. */
export type RpcwanSpeedtestInput = {
  /** Config 2 only. If omitted, will select currently active WAN. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** The device to run the speedtest on. */
  serialNumber: Scalars['String']['input'];
  /** Config 2 only. Retrieved with a call to speedtestServersForNetwork. If omitted, controller will auto-select a server. */
  serverID?: InputMaybe<Scalars['String']['input']>;
  wan?: InputMaybe<Wan>;
};

export type RpcwanSpeedtestResponse = {
  __typename?: 'RPCWANSpeedtestResponse';
  /** Config 2 only. Distance to target server */
  distance?: Maybe<Scalars['Float']['output']>;
  downloadSpeedMbps: Scalars['Float']['output'];
  /** Config 2 only */
  jitterNs?: Maybe<Scalars['Int']['output']>;
  /** Config 2 only */
  latencyNs?: Maybe<Scalars['Int']['output']>;
  /** Config 2 only. Target server host test was run against */
  targetHost?: Maybe<Scalars['String']['output']>;
  /** Config 2 only. Target server name test was run against */
  targetName?: Maybe<Scalars['String']['output']>;
  uploadSpeedMbps: Scalars['Float']['output'];
};

export type RpcWosObserverCommandResults = {
  __typename?: 'RPCWosObserverCommandResults';
  /** Delay statistics: average, min, max, and jitter, in milliseconds. */
  avgDurationMsec: Scalars['Int']['output'];
  avgNumBytes: Scalars['Int']['output'];
  /** Index of the test, matches the request. */
  index: Scalars['Int']['output'];
  jitterMsec: Scalars['Int']['output'];
  maxDurationMsec: Scalars['Int']['output'];
  minDurationMsec: Scalars['Int']['output'];
  numAttempts: Scalars['Int']['output'];
  successRatio: Scalars['Float']['output'];
};

/** A request to run Observer tests on a Meter observer. */
export type RpcWosObserverInput = {
  /**
   * The UUID of the SSID to use for the Observer test. If empty with triggerTest true,
   * will simply cancel the previous test.
   */
  SSIDUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** Band on which the test should be run. */
  band?: InputMaybe<RadioBand>;
  /** List of commands to run. */
  commands?: InputMaybe<Array<WosObserverCommandInput>>;
  debugMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Duration of the test, in msec. */
  durationMsec?: InputMaybe<Scalars['Int']['input']>;
  /** If this request is to get results from an Observer test. */
  getTestResults?: InputMaybe<Scalars['Boolean']['input']>;
  /** Hostname to use with DHCP; if unset, firmware generates it */
  hostname?: InputMaybe<Scalars['String']['input']>;
  /** If set, number of spatial streams will be limited */
  numSpatialStreams?: InputMaybe<Scalars['Int']['input']>;
  /** 8021X password. */
  password8021X?: InputMaybe<Scalars['String']['input']>;
  /** The serial number of the device to run the Observer test on. */
  serialNumber: Scalars['String']['input'];
  /** The serial number of the device the Observer will target or get results for. */
  targetSerialNumber?: InputMaybe<Scalars['String']['input']>;
  /** Timeout for the connection attempt, in msec. */
  timeoutMsec?: InputMaybe<Scalars['Int']['input']>;
  /** When WPA3 transition is enabled, use WPA2 if true (use WPA3 otherwise). */
  transitionUseWpa2?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this request is to trigger an Observer test. */
  triggerTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Use protected management frames (11w). With WPA3, 11w is used anyway. */
  use11w?: InputMaybe<Scalars['Boolean']['input']>;
  /** If scanning radio should be used for this test */
  useScanningRadio?: InputMaybe<Scalars['Boolean']['input']>;
  /** 8021X username. */
  username8021X?: InputMaybe<Scalars['String']['input']>;
};

export type RpcWosObserverResponse = {
  __typename?: 'RPCWosObserverResponse';
  /** A reason for a failure. */
  failureReason?: Maybe<Scalars['String']['output']>;
  /** Results for the tests. */
  testResults?: Maybe<Array<RpcWosObserverTestResults>>;
  /** The status of a trigger request. */
  triggerStatus: RpcWosObserverResponseStatus;
};

export enum RpcWosObserverResponseStatus {
  TriggerFail = 'TRIGGER_FAIL',
  TriggerOk = 'TRIGGER_OK',
  Unspecified = 'UNSPECIFIED',
}

export type RpcWosObserverTestResults = {
  __typename?: 'RPCWosObserverTestResults';
  /** Duration of DHCP, in msec. */
  DHCPDurationMsec: Scalars['Int']['output'];
  /** Duration of the assoc, in msec. */
  assocDurationMsec: Scalars['Int']['output'];
  /** Duration of the auth, in msec. */
  authDurationMsec: Scalars['Int']['output'];
  /** Band on which the test has run. */
  band: RadioBand;
  /** Results of all commands. */
  commandResults?: Maybe<Array<RpcWosObserverCommandResults>>;
  /** Duration of the scan, in msec. */
  scanDurationMsec: Scalars['Int']['output'];
  /** The UUID of the SSID used for the Observer test. */
  ssidUuid?: Maybe<Scalars['UUID']['output']>;
  /** Start time of the test. */
  startedAt: Scalars['DateTime']['output'];
  /** The serial number of the device the Observer we get results for. */
  targetSerialNumber: Scalars['String']['output'];
};

export type RpcWosPacketCaptureResponse = {
  __typename?: 'RPCWosPacketCaptureResponse';
  /** The job ID of the packet capture. */
  jobId: Scalars['String']['output'];
  /** Status of the packet capture request. */
  status: RpcWosPacketCaptureResponseStatus;
};

export enum RpcWosPacketCaptureResponseStatus {
  Fail = 'FAIL',
  Ok = 'OK',
  Unspecified = 'UNSPECIFIED',
}

/** Run a packet capture on a device. */
export type RpcWosPacketCaptureStartInput = {
  /**
   * The filter ID to be applied to the packet capture.
   * Not yet implemented in firmware.
   */
  filterID: Scalars['Int']['input'];
  /** The job ID of the packet capture. */
  jobId: Scalars['String']['input'];
  /** The monitor interfaces that we want to conduct the packet capture on. */
  radios: WosPacketCaptureCommandMonitor;
  /** The presigned public S3 url where the packet capture file needs to be uploaded. */
  resultUploadURL: Scalars['String']['input'];
  /** Configurable size limit of the packet capture. */
  sizeLimitMb: Scalars['Int']['input'];
  /** Configurable time limit of the packet capture. */
  timeLimitSec: Scalars['Int']['input'];
};

export type RpcWosPacketCaptureStopInput = {
  /** The job ID of the packet capture. */
  jobId: Scalars['String']['input'];
};

/** A rack elevation is a 2D representation of racks of hardware in a datacenter or supply closet. */
export type RackElevation = {
  __typename?: 'RackElevation';
  UUID: Scalars['UUID']['output'];
  devices: Array<RackElevationDevice>;
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: Maybe<Scalars['Int']['output']>;
  /** Human-readable name for the rack elevation. */
  label: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  notes?: Maybe<Array<RackElevationNote>>;
  /** The number of rack units (like slots) for the rack elevation. */
  rackMountUnitCount: Scalars['Int']['output'];
};

export type RackElevationDevice = {
  __typename?: 'RackElevationDevice';
  UUID: Scalars['UUID']['output'];
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing: Scalars['Boolean']['output'];
  /** If the device is a switch, whether it supports PoE. */
  isPoESupported?: Maybe<Scalars['Boolean']['output']>;
  /** A friendly name for display purposes. */
  label?: Maybe<Scalars['String']['output']>;
  /** The hardware device's model. */
  model?: Maybe<Scalars['String']['output']>;
  /** For additional context. */
  notes?: Maybe<Scalars['String']['output']>;
  /** The number of ports on the device. */
  portCount?: Maybe<Scalars['Int']['output']>;
  rackElevationUUID: Scalars['UUID']['output'];
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes: Array<Scalars['Int']['output']>;
  type: RackElevationDeviceType;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

export enum RackElevationDeviceType {
  CableManagement = 'CABLE_MANAGEMENT',
  ExistingEquipment = 'EXISTING_EQUIPMENT',
  Fiber = 'FIBER',
  Isp = 'ISP',
  MeterAccessPoint = 'METER_ACCESS_POINT',
  MeterController = 'METER_CONTROLLER',
  MeterSwitch = 'METER_SWITCH',
  NonMeterSwitch = 'NON_METER_SWITCH',
  Other = 'OTHER',
  PatchBox = 'PATCH_BOX',
  PatchPanel = 'PATCH_PANEL',
  UniversalPowerSupply = 'UNIVERSAL_POWER_SUPPLY',
  Unknown = 'UNKNOWN',
}

export type RackElevationNote = {
  __typename?: 'RackElevationNote';
  note: Scalars['String']['output'];
  rackMountUnitIndexEnd: Scalars['Int']['output'];
  rackMountUnitIndexStart: Scalars['Int']['output'];
};

export type RackElevationNoteInput = {
  note: Scalars['String']['input'];
  rackMountUnitIndexEnd: Scalars['Int']['input'];
  rackMountUnitIndexStart: Scalars['Int']['input'];
};

export enum RadioBand {
  Band_2_4G = 'BAND_2_4G',
  Band_5G = 'BAND_5G',
}

/** Contains configuration for wireless radios. A profile is a way to apply the same configuration to multiple APs. */
export type RadioProfile = {
  __typename?: 'RadioProfile';
  UUID: Scalars['UUID']['output'];
  /**
   * Only set if band2_4GAutoChannelIsEnabled is true.
   * This is the list of channels that are permitted for ACS (auto channel selection) to
   * select from and is user-configurable.
   */
  band2_4GAllowedChannels?: Maybe<Array<Scalars['Int']['output']>>;
  band2_4GAutoChannelIsEnabled: Scalars['Boolean']['output'];
  band2_4GAutoChannelWidthIsEnabled: Scalars['Boolean']['output'];
  band2_4GAutoTxPowerIsEnabled: Scalars['Boolean']['output'];
  /** Only set if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMaxdBm?: Maybe<Scalars['Int']['output']>;
  /** Only set if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMindBm?: Maybe<Scalars['Int']['output']>;
  /** Only set if band2_4GAutoChannelWidthIsEnabled is false. */
  band2_4GChannelWidthMHz?: Maybe<Scalars['Int']['output']>;
  band2_4GIsEnabled: Scalars['Boolean']['output'];
  /**
   * Only set if band5GAutoChannelIsEnabled is true.
   * This is the list of channels that are permitted for ACS (auto channel selection) to
   * select from and is user-configurable.
   */
  band5GAllowedChannels?: Maybe<Array<Scalars['Int']['output']>>;
  band5GAutoChannelIsEnabled: Scalars['Boolean']['output'];
  band5GAutoChannelWidthIsEnabled: Scalars['Boolean']['output'];
  band5GAutoTxPowerIsEnabled: Scalars['Boolean']['output'];
  /** Only set if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMaxdBm?: Maybe<Scalars['Int']['output']>;
  /** Only set if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMindBm?: Maybe<Scalars['Int']['output']>;
  /** Only set if band5GAutoChannelWidthIsEnabled is false. */
  band5GChannelWidthMHz?: Maybe<Scalars['Int']['output']>;
  band5GIsEnabled: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The ISO2 country code where the device is located. */
  regulatoryDomain: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  virtualDevices: Array<VirtualDevice>;
};

/** An individual Access Point's radio settings (channel, power). Other radio settings are configured in the radio profile and shared across multiple APs. */
export type RadioSettings = {
  __typename?: 'RadioSettings';
  band2_4GPrimaryChannel: Scalars['Int']['output'];
  band2_4GSecondaryChannel?: Maybe<Scalars['Int']['output']>;
  band2_4GTransmitPowerdBm: Scalars['Int']['output'];
  band5GPrimaryChannel: Scalars['Int']['output'];
  band5GSecondaryChannel?: Maybe<Scalars['Int']['output']>;
  band5GTransmitPowerdBm: Scalars['Int']['output'];
};

export type RateLimitPhyInterfaceBindingInput = {
  phyInterfaceUUID: Scalars['UUID']['input'];
  rateLimitRuleUUID: Scalars['UUID']['input'];
};

/** Defines L2 and L3 rate limit rules for a network. A rate limit rule can be applied to multiple interfaces. */
export type RateLimitRule = {
  __typename?: 'RateLimitRule';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dstMac?: Maybe<Scalars['MacAddress']['output']>;
  dstPortRange?: Maybe<PortRange>;
  dstPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  isPerSource: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** Creates a relationship between which physical interfaces a rate limit rule should be applied on. The same rate limit rule can be applied on any number of physical interfaces. */
  phyInterfaceBindings?: Maybe<Array<PhyInterface>>;
  /** The IP protocol to apply this rule on. These are the IANA defined protocol numbers. E.g. 1 = ICMP, 6 = TCP, 17 = UDP. A value of 256 is reserved by IANA and will be used to denote all protocols. */
  protocol?: Maybe<IpProtocol>;
  rateLimitKbpsDownload?: Maybe<Scalars['Int']['output']>;
  rateLimitKbpsUpload?: Maybe<Scalars['Int']['output']>;
  srcMac?: Maybe<Scalars['MacAddress']['output']>;
  srcPortRange?: Maybe<PortRange>;
  srcPrefix?: Maybe<Scalars['IPPrefix']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Creates a relationship between which vlan interfaces a rate limit rule should be applied on. The same rate limit rule can be applied on any number of VLANs. */
  vlanBindings?: Maybe<Array<Vlan>>;
};

export type RateLimitVlanBindingInput = {
  rateLimitRuleUUID: Scalars['UUID']['input'];
  vlanUUID: Scalars['UUID']['input'];
};

export enum Role {
  Operator = 'OPERATOR',
}

/**
 * A RoleAssignment denotes the role of a user in context of a company or company & network.
 *
 * In the case of an Operator role, the companySlug and networkUUID fields will be null.
 */
export type RoleAssignment = {
  __typename?: 'RoleAssignment';
  companySlug?: Maybe<Scalars['String']['output']>;
  name: RoleName;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
};

export type RoleCheckResult = {
  __typename?: 'RoleCheckResult';
  hasRole: Scalars['Boolean']['output'];
};

export enum RoleName {
  CompanyGlobalAdmin = 'COMPANY_GLOBAL_ADMIN',
  CompanyGuest = 'COMPANY_GUEST',
  CompanyNetworkAdmin = 'COMPANY_NETWORK_ADMIN',
  CompanyStandardUser = 'COMPANY_STANDARD_USER',
  Operator = 'OPERATOR',
}

/** RolesInput serves as the context for which to fetch the roles for a given user. */
export type RolesInput = {
  companySlug?: InputMaybe<Scalars['String']['input']>;
};

export type SfpModuleInfo = {
  __typename?: 'SFPModuleInfo';
  moduleSpeed: Scalars['String']['output'];
  moduleType: Scalars['String']['output'];
  partName: Scalars['String']['output'];
  serialNumber: Scalars['String']['output'];
  vendor: Scalars['String']['output'];
};

/** Service set identifiers (SSIDs) for a network. */
export type Ssid = {
  __typename?: 'SSID';
  /** BSSIDs are MAC addresses that identify a radio band / SSID pair for an access point. */
  BSSIDs: Array<Bssid>;
  UUID: Scalars['UUID']['output'];
  /** The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second. */
  band2GMinimumBasicRateKbps?: Maybe<Scalars['Int']['output']>;
  /** The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second. */
  band5GMinimumBasicRateKbps?: Maybe<Scalars['Int']['output']>;
  /** Client steering allows devices to select an optimal access point for better connectivity and performance. */
  clientSteeringAssociationSteeringIsEnabled: Scalars['Boolean']['output'];
  /** The minimum number of clients on an SSID before client steering is attempted. */
  clientSteeringLoadBalancingClientMinThreshold?: Maybe<Scalars['Int']['output']>;
  /** Post association steering allows devices to move to a better access point after they have already connected. */
  clientSteeringPostassociationSteeringIsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** The minimum RSSI signal strength a client must have before pre-association client steering is attempted. */
  clientSteeringPreassociationRSSIMinThreshold?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** The DTIM (Delivery Traffic Indication Message) period is a value in beacon frames that indicates how often clients should wake up to receive multicast and broadcast traffic. */
  dtimPeriod?: Maybe<Scalars['Int']['output']>;
  /** Dynamic VLAN is a setting to use the VLAN specified by an external RADIUS server's response after authentication. If null, then the VLAN specified by `vlan` is used. */
  dynamicVLANMode?: Maybe<SsidDynamicVlanMode>;
  enabledAccessPointVirtualDevices?: Maybe<Array<AccessPointVirtualDevice>>;
  /**
   * 802.11w is a security standard that protects management frames from being forged by an attacker.
   * For WPA3 PSK, this field is required and must be REQUIRED.
   * For WPA3 Transition, this field is required but can be REQUIRED or OPTIONAL.
   * For WPA2, this field is optional.
   */
  encryption80211wProtectedManagementFramesMode?: Maybe<SsidEncryption80211wProtectedManagementFramesMode>;
  /** If null, this is an open network. */
  encryptionProtocol?: Maybe<SsidEncryptionProtocol>;
  /** An optional value for the Hotspot 2.0 Access network type, as defined in Table 9-214 of 802.11-2016. */
  hs20AccessNetworkType?: Maybe<Scalars['Int']['output']>;
  /** A list of Hotspot 2.0 domain names */
  hs20DomainNames?: Maybe<Array<Scalars['String']['output']>>;
  /** An option to enable/disable Hotspot 2.0, aka Passpoint */
  hs20Enabled: Scalars['Boolean']['output'];
  /** A boolean to represent whether a Hostpot 2.0 profile is attached to the SSID (has any fields set). */
  hs20HasProfile: Scalars['Boolean']['output'];
  /** A list of MCC/MNC (Mobile Country Codes and Mobile Network Codes) pairs, formatted as "MCC,MNC" */
  hs20MccMncPairs?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Hotspot 2.0 NAI (Network Access Identifier) realms; format follows hostapd format for nai_realm option (see hostapd documentation). */
  hs20NaiRealms?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Operator names for Hotspot 2.0 (one per language) */
  hs20OperatorNames?: Maybe<Array<Scalars['String']['output']>>;
  /** A list of Hotspot 2.0 Consortium OIs (Organization Identifiers). */
  hs20RoamingConsortiumOIs?: Maybe<Array<Scalars['String']['output']>>;
  /** An optional value for the Hotspot 2.0 Venue group, as defined in Table 9-62 of 802.11-2016. */
  hs20VenueGroup?: Maybe<Scalars['Int']['output']>;
  /**
   * A list of Venue names for Hotspot 2.0 (one per language); must be formatted with a two or three character language code separated by a colon from the venue name.
   * If at least one if set, hs20VenueGroup and hs20VenueType must be set
   */
  hs20VenueNames?: Maybe<Array<Scalars['String']['output']>>;
  /** An optional value for the Hotspot 2.0 Venue type, as defined in Table 9-62 of 802.11-2016. */
  hs20VenueType?: Maybe<Scalars['Int']['output']>;
  /** Whether or not the SSID is enabled for 802.11ax (Wi-Fi 6) clients. */
  is80211axEnabled: Scalars['Boolean']['output'];
  /** Whether or not AP debug logging is enabled for APs broadcasting the SSID. */
  isAPDebugLogEnabled: Scalars['Boolean']['output'];
  /** Whether or not the SSID is allowed to be broadcasted on the 2.4 GHz radio. */
  isBand2GAllowed: Scalars['Boolean']['output'];
  /** Whether or not the SSID is allowed to be broadcasted on the 5 GHz radio. */
  isBand5GAllowed: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** If true, the SSID is enabled for all access points in the network. If false, the SSID is enabled for only the access points specified in the enabledAccessPointVirtualDevices field. */
  isEnabledForAllAccessPoints: Scalars['Boolean']['output'];
  /** If true, the SSID is considered as guest and can be displayed on site for guest access, for example on a kiosk. */
  isGuest: Scalars['Boolean']['output'];
  /** Whether or not the broadcasted SSID is visible to clients; clients have to manually input the SSID to join the network. */
  isHidden: Scalars['Boolean']['output'];
  /** If true, clients on the same SSID cannot communicate with each other. */
  isIsolateClientsEnabled: Scalars['Boolean']['output'];
  /** True if multicast to unicast translation is enabled on the SSID. */
  isMulticastToUnicastEnabled: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The PSK for the SSID. If null, it is an open passwordless network or the encryption protocol does not use a PSK. */
  password?: Maybe<Scalars['String']['output']>;
  /** The last time the password was rotated, if password rotation is enabled. */
  passwordLastRotatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The next time the password will be rotated, if password rotation is enabled. */
  passwordNextRotationAt?: Maybe<Scalars['DateTime']['output']>;
  /** The period upon which the password is automatically rotated; a non-null value indicates that password rotation is enabled. */
  passwordRotationCadence?: Maybe<SsidPasswordRotationCadence>;
  /** The day of the month, in the network's local time zone, at which the password is automatically rotated. */
  passwordRotationDayOfMonthLocal?: Maybe<Scalars['Int']['output']>;
  /** The day of the week, in the network's local time zone, at which the password is automatically rotated (0 = Sunday, 1 = Monday, etc.) */
  passwordRotationDayOfWeekLocal?: Maybe<Scalars['Int']['output']>;
  /** The hour of the day, in the network's local time zone, at which the password is automatically rotated. */
  passwordRotationHourOfDayLocal?: Maybe<Scalars['Int']['output']>;
  primaryEncryption8021X?: Maybe<Encryption8021X>;
  /** True if SSID supports Radius CoA (change of authorization) */
  radiusCoaEnabled: Scalars['Boolean']['output'];
  /** A port where Radius servers can send CoA requests */
  radiusCoaPort?: Maybe<Scalars['Int']['output']>;
  /** 802.11k is a standard that helps wireless devices quickly find and switch to the best available access point. */
  roaming80211kIsEnabled: Scalars['Boolean']['output'];
  /** 802.11r is a standard that allows clients to roam more effectively between access points. */
  roaming80211rIsEnabled: Scalars['Boolean']['output'];
  /**
   * Used to indicate a group of APs sharing the same SSID between which a client can use 802.11r to roam. A 2 byte hex string.
   * Currently we do not allow callers to set this field.
   */
  roaming80211rMobilityDomain?: Maybe<Scalars['String']['output']>;
  /**
   * A 32 byte hex string key used for encryption in 802.11r for r0 and r1 key holders.
   * Currently we do not allow callers to set this field.
   */
  roaming80211rR0khR1khKey?: Maybe<Scalars['String']['output']>;
  /** 802.11v is a standard that allows devices to communicate with the network for better connection performance, such as optimizing roaming and power efficiency. */
  roaming80211vIsEnabled: Scalars['Boolean']['output'];
  /** A value to configure RTS/CTS (see QCA doc "Wireless LAN Access Point Command Reference" for more details). */
  rtsCtsEnabledCode?: Maybe<Scalars['Int']['output']>;
  /** Size of the PPDU on which RTS would be sent out */
  rtsThreshold?: Maybe<Scalars['Int']['output']>;
  secondaryEncryption8021X?: Maybe<Encryption8021X>;
  ssid: Scalars['String']['output'];
  /** A number to uniquely identify the SSID within the network. Starts from zero. */
  ssidIndex: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** Must be non-null if the SSID is enabled. */
  vlan?: Maybe<Vlan>;
};

export enum SsidDynamicVlanMode {
  /**
   * The VLAN specified by an external RADIUS server's response after authentication is used.
   * The VLAN specified by `vlan` is used as a fallback if the RADIUS server does not specify a VLAN.
   */
  Enabled = 'ENABLED',
  /**
   * The VLAN specified by an external RADIUS server's response after authentication is used.
   * The VLAN specified by `vlan` is ignored.
   */
  Required = 'REQUIRED',
}

export enum SsidEncryption80211wProtectedManagementFramesMode {
  /** Can only be set as optional for WPA2 / WPA2 Enterprise / WPA3 Transition. */
  Optional = 'OPTIONAL',
  /** Must be set to this for WPA3; for other encryption protocols, it is also permitted. */
  Required = 'REQUIRED',
}

export enum SsidEncryptionProtocol {
  OpenMacAuthRadius = 'OPEN_MAC_AUTH_RADIUS',
  Wpa2 = 'WPA2',
  Wpa2Enterprise = 'WPA2_ENTERPRISE',
  Wpa3 = 'WPA3',
  Wpa3Enterprise = 'WPA3_ENTERPRISE',
  /** Supports clients that use WPA2 or WPA3. */
  Wpa3Transition = 'WPA3_TRANSITION',
}

export enum SsidPasswordRotationCadence {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export enum SsoAdminPortalType {
  /** WorkOS admin dashboard for viewing WorkOS Audit Logs. */
  AuditLogs = 'AUDIT_LOGS',
  /** WorkOS admin dashboard for configuring Directory Sync. */
  Dsync = 'DSYNC',
  /** WorkOS admin dashboard for configuring Log Streams. */
  LogStreams = 'LOG_STREAMS',
  /** WorkOS admin dashboard for configuring an SSO Provider. */
  SsoProvider = 'SSO_PROVIDER',
}

export type SsoConnectedDirectory = {
  __typename?: 'SSOConnectedDirectory';
  createdAt: Scalars['DateTime']['output'];
  state: SsoState;
  type: SsoDirectoryTypes;
};

export type SsoConnection = {
  __typename?: 'SSOConnection';
  connectedAt: Scalars['DateTime']['output'];
  state: SsoConnectionState;
  type: SsoConnectionType;
};

export enum SsoConnectionState {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
  None = 'None',
  Validating = 'Validating',
}

export enum SsoConnectionType {
  Adfssaml = 'ADFSSAML',
  AdpOidc = 'AdpOidc',
  Auth0Saml = 'Auth0SAML',
  AzureSaml = 'AzureSAML',
  CasSaml = 'CasSAML',
  ClassLinkSaml = 'ClassLinkSAML',
  CloudflareSaml = 'CloudflareSAML',
  CyberArkSaml = 'CyberArkSAML',
  DuoSaml = 'DuoSAML',
  GenericOidc = 'GenericOIDC',
  GenericSaml = 'GenericSAML',
  GoogleOAuth = 'GoogleOAuth',
  GoogleSaml = 'GoogleSAML',
  JumpCloudSaml = 'JumpCloudSAML',
  KeycloakSaml = 'KeycloakSAML',
  LastPassSaml = 'LastPassSAML',
  LoginGovOidc = 'LoginGovOidc',
  MagicLink = 'MagicLink',
  MicrosoftOAuth = 'MicrosoftOAuth',
  MiniOrangeSaml = 'MiniOrangeSAML',
  NetIqSaml = 'NetIqSAML',
  None = 'None',
  OktaSaml = 'OktaSAML',
  OneLoginSaml = 'OneLoginSAML',
  OracleSaml = 'OracleSAML',
  PingFederateSaml = 'PingFederateSAML',
  PingOneSaml = 'PingOneSAML',
  RipplingSaml = 'RipplingSAML',
  SalesforceSaml = 'SalesforceSAML',
  ShibbolethGenericSaml = 'ShibbolethGenericSAML',
  ShibbolethSaml = 'ShibbolethSAML',
  SimpleSamlPhpSaml = 'SimpleSamlPhpSAML',
  VMwareSaml = 'VMwareSAML',
}

export type SsoDirectory = {
  __typename?: 'SSODirectory';
  domain: Scalars['String']['output'];
  name: Scalars['String']['output'];
  state: SsoState;
  type: SsoDirectoryTypes;
};

export enum SsoDirectoryTypes {
  AzureScimv2_0 = 'AzureSCIMV2_0',
  BambooHr = 'BambooHr',
  BreatheHr = 'BreatheHr',
  CezanneHr = 'CezanneHr',
  CyberArk = 'CyberArk',
  FourthHr = 'FourthHr',
  GSuiteDirectory = 'GSuiteDirectory',
  GenericScimv2_0 = 'GenericSCIMV2_0',
  Hibob = 'Hibob',
  JumpCloud = 'JumpCloud',
  None = 'None',
  OktaScimv2_0 = 'OktaSCIMV2_0',
  OneLogin = 'OneLogin',
  PeopleHr = 'PeopleHr',
  Personio = 'Personio',
  PingFederate = 'PingFederate',
  Rippling = 'Rippling',
  Sftp = 'SFTP',
  SftpWorkday = 'SFTPWorkday',
  Workday = 'Workday',
}

export type SsoOptions = {
  __typename?: 'SSOOptions';
  connectedDirectory?: Maybe<SsoConnectedDirectory>;
  connections: Array<SsoConnection>;
  directories: Array<SsoDirectory>;
};

export type SsoOptionsInput = {
  companySlug: Scalars['String']['input'];
};

export enum SsoState {
  InvalidCredentials = 'InvalidCredentials',
  Linked = 'Linked',
  Unlinked = 'Unlinked',
}

export enum SsoVerificationSource {
  AuthKit = 'AUTH_KIT',
  Sso = 'SSO',
}

/** STP (Spanning Tree Protocol) configuration information for switches to ensure loop-free topologies. */
export type StpInfo = {
  __typename?: 'STPInfo';
  /** The root bridge is the switch in a network from which all paths are derived to prevent loops. */
  isRootBridge: Scalars['Boolean']['output'];
  rootBridgeMACAddress: Scalars['MacAddress']['output'];
  rootBridgePortNumber: Scalars['Int']['output'];
};

export enum StpPortRole {
  Alternate = 'ALTERNATE',
  Backup = 'BACKUP',
  Designated = 'DESIGNATED',
  Disabled = 'DISABLED',
  Root = 'ROOT',
}

export enum ScopeKind {
  AlertReceiverUuid = 'ALERT_RECEIVER_UUID',
  AlertTargetWebhookUuid = 'ALERT_TARGET_WEBHOOK_UUID',
  ApplicationDnsFirewallRuleUuid = 'APPLICATION_DNS_FIREWALL_RULE_UUID',
  AutoSelectionJobUuid = 'AUTO_SELECTION_JOB_UUID',
  AutoVpnGroupUuid = 'AUTO_VPN_GROUP_UUID',
  AutoVpnMemberUuid = 'AUTO_VPN_MEMBER_UUID',
  AutoVpnRouteUuid = 'AUTO_VPN_ROUTE_UUID',
  CaptivePortalUuid = 'CAPTIVE_PORTAL_UUID',
  CaptivePortalWebhookUuid = 'CAPTIVE_PORTAL_WEBHOOK_UUID',
  ClientVpnClientUuid = 'CLIENT_VPN_CLIENT_UUID',
  ClientVpnServerUuid = 'CLIENT_VPN_SERVER_UUID',
  CompanySlug = 'COMPANY_SLUG',
  CompanyUserUuid = 'COMPANY_USER_UUID',
  CompanyUuid = 'COMPANY_UUID',
  DhcpOptionUuid = 'DHCP_OPTION_UUID',
  DhcpReservedRangeUuid = 'DHCP_RESERVED_RANGE_UUID',
  DhcpRuleUuid = 'DHCP_RULE_UUID',
  DhcpStaticMappingUuid = 'DHCP_STATIC_MAPPING_UUID',
  DnsHostMappingsUuid = 'DNS_HOST_MAPPINGS_UUID',
  Encryption_8021XUuid = 'ENCRYPTION_8021X_UUID',
  FirewallRuleUuid = 'FIREWALL_RULE_UUID',
  FloorPlanUuid = 'FLOOR_PLAN_UUID',
  HostMonitoringTargetUuid = 'HOST_MONITORING_TARGET_UUID',
  InternalNoteUuid = 'INTERNAL_NOTE_UUID',
  InternetServicePlanUuid = 'INTERNET_SERVICE_PLAN_UUID',
  IpsecTunnelUuid = 'IPSEC_TUNNEL_UUID',
  NetworkJobUuid = 'NETWORK_JOB_UUID',
  NetworkOnboardingDocumentUuid = 'NETWORK_ONBOARDING_DOCUMENT_UUID',
  NetworkOnboardingFieldDelegationUuid = 'NETWORK_ONBOARDING_FIELD_DELEGATION_UUID',
  NetworkSlug = 'NETWORK_SLUG',
  NetworkUuid = 'NETWORK_UUID',
  NotNetworkScoped = 'NOT_NETWORK_SCOPED',
  OneToOneNatRuleUuid = 'ONE_TO_ONE_NAT_RULE_UUID',
  OutletInterfaceUuid = 'OUTLET_INTERFACE_UUID',
  PhyInterfaceUuid = 'PHY_INTERFACE_UUID',
  PolicyRoutingRuleUuid = 'POLICY_ROUTING_RULE_UUID',
  PortForwardingRuleUuid = 'PORT_FORWARDING_RULE_UUID',
  RackElevationDeviceUuid = 'RACK_ELEVATION_DEVICE_UUID',
  RackElevationUuid = 'RACK_ELEVATION_UUID',
  RadioProfileUuid = 'RADIO_PROFILE_UUID',
  RateLimitRuleUuid = 'RATE_LIMIT_RULE_UUID',
  SerialNumber = 'SERIAL_NUMBER',
  SsidUuid = 'SSID_UUID',
  StaticRouteUuid = 'STATIC_ROUTE_UUID',
  SwitchAutoConfigMacAddressVlanBindingUuid = 'SWITCH_AUTO_CONFIG_MAC_ADDRESS_VLAN_BINDING_UUID',
  SwitchProfileUuid = 'SWITCH_PROFILE_UUID',
  Unknown = 'UNKNOWN',
  VirtualDeviceUuid = 'VIRTUAL_DEVICE_UUID',
  VlanUuid = 'VLAN_UUID',
}

export enum ServiceType {
  Airplay = 'AIRPLAY',
  Printer = 'PRINTER',
  Speaker = 'SPEAKER',
  Unknown = 'UNKNOWN',
}

export type SetNetworkNosVersionInput = {
  force?: Scalars['Boolean']['input'];
  nosId: Scalars['Int']['input'];
  staggeredUpgradeIntervalMin?: Scalars['Int']['input'];
};

export type SetPolicyRoutingRuleBindingsInput = {
  bindings: Array<PolicyRoutingRuleBindingInput>;
};

export type SignedConsoleLoginChallenge = {
  __typename?: 'SignedConsoleLoginChallenge';
  /**
   * Key ID of the KMS key used to sign the challenge. This can be used to ensure
   * that the challenge was signed by the correct key.
   */
  keyID: Scalars['String']['output'];
  /**
   * The signed challenge.
   * This is a base64 encoded string with a PSS SHA 256 signed JSON payload.
   * The payload is a map with the following keys:
   * - challenge: the original challenge
   * - serialNumber: the serial number of the device to sign the challenge for
   * - user: the user that signed the challenge
   */
  signedChallenge: Scalars['String']['output'];
};

export type SpeedtestServer = {
  __typename?: 'SpeedtestServer';
  distance: Scalars['Int']['output'];
  host: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lat: Scalars['String']['output'];
  lon: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type StartWanSpeedtestJobInput = {
  baseURL?: Scalars['String']['input'];
  maxTransferBytes?: InputMaybe<Scalars['Int']['input']>;
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type Stat = {
  __typename?: 'Stat';
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  networkUUID: Scalars['UUID']['output'];
  observedAt: Scalars['DateTime']['output'];
  rawData: Scalars['JSONObject']['output'];
  type: StatType;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export type StatInjectionDuration = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export enum StatType {
  Anomaly = 'ANOMALY',
  ArpTable = 'ARP_TABLE',
  CellularBootInfo = 'CELLULAR_BOOT_INFO',
  CellularStatus = 'CELLULAR_STATUS',
  ClientLatency = 'CLIENT_LATENCY',
  ConfigVersionInfo = 'CONFIG_VERSION_INFO',
  CosCapoAuthEvent = 'COS_CAPO_AUTH_EVENT',
  CosCapoRedirectEvent = 'COS_CAPO_REDIRECT_EVENT',
  CosClients = 'COS_CLIENTS',
  CosDhcpServerInfo = 'COS_DHCP_SERVER_INFO',
  CosDnsInfo = 'COS_DNS_INFO',
  CosFirewallCounters = 'COS_FIREWALL_COUNTERS',
  CosHaEvent = 'COS_HA_EVENT',
  CosIpsecTunnels = 'COS_IPSEC_TUNNELS',
  CosLteFailoverEvent = 'COS_LTE_FAILOVER_EVENT',
  CosMacTableEvent = 'COS_MAC_TABLE_EVENT',
  CosMultiwanEvent = 'COS_MULTIWAN_EVENT',
  CosNetworks = 'COS_NETWORKS',
  CosUsbPortEvent = 'COS_USB_PORT_EVENT',
  CosWanSpeedTestResult = 'COS_WAN_SPEED_TEST_RESULT',
  Heartbeat = 'HEARTBEAT',
  HostPing = 'HOST_PING',
  HostTraceroute = 'HOST_TRACEROUTE',
  LldpTable = 'LLDP_TABLE',
  /** @deprecated Use `MAC_TABLE` instead. */
  MacTable = 'MAC_TABLE',
  MdnsServiceDiscoveryEvent = 'MDNS_SERVICE_DISCOVERY_EVENT',
  PacketCapture = 'PACKET_CAPTURE',
  PduOutletAutoCycleEvent = 'PDU_OUTLET_AUTO_CYCLE_EVENT',
  PduOutletEvent = 'PDU_OUTLET_EVENT',
  PduStatusInfo = 'PDU_STATUS_INFO',
  PortEvent = 'PORT_EVENT',
  PortInfo = 'PORT_INFO',
  SecureAddressingAndMessagingSystemInfo = 'SECURE_ADDRESSING_AND_MESSAGING_SYSTEM_INFO',
  SfpPortsInfo = 'SFP_PORTS_INFO',
  SfpPortLoopbackInfo = 'SFP_PORT_LOOPBACK_INFO',
  Sos_8021XAuthenticatedHosts = 'SOS_8021X_AUTHENTICATED_HOSTS',
  Sos_8021XPortStatus = 'SOS_8021X_PORT_STATUS',
  SosFlapLinkStatus = 'SOS_FLAP_LINK_STATUS',
  SosIgmpSnoopingStatus = 'SOS_IGMP_SNOOPING_STATUS',
  /** @deprecated Use `MAC_TABLE` instead. */
  SosMacTable = 'SOS_MAC_TABLE',
  SosMldSnoopingStatus = 'SOS_MLD_SNOOPING_STATUS',
  SosPoeInfo = 'SOS_POE_INFO',
  SosPortCounters = 'SOS_PORT_COUNTERS',
  SosPortEvent = 'SOS_PORT_EVENT',
  SosPortLoopbackInfo = 'SOS_PORT_LOOPBACK_INFO',
  SosStpInfo = 'SOS_STP_INFO',
  SystemInfo = 'SYSTEM_INFO',
  Unspecified = 'UNSPECIFIED',
  UplinkInfo = 'UPLINK_INFO',
  WireguardPeerEvent = 'WIREGUARD_PEER_EVENT',
  WireguardTunnelInfo = 'WIREGUARD_TUNNEL_INFO',
  WosAcsResult = 'WOS_ACS_RESULT',
  WosAutoTransmitPowerSelectJobResults = 'WOS_AUTO_TRANSMIT_POWER_SELECT_JOB_RESULTS',
  WosClientTable = 'WOS_CLIENT_TABLE',
  WosConnectionEvent = 'WOS_CONNECTION_EVENT',
  WosEthStats = 'WOS_ETH_STATS',
  WosEvent = 'WOS_EVENT',
  WosObserverResult = 'WOS_OBSERVER_RESULT',
  WosObssStats = 'WOS_OBSS_STATS',
  WosPingTable = 'WOS_PING_TABLE',
  WosRfStats = 'WOS_RF_STATS',
  WosScanTable = 'WOS_SCAN_TABLE',
}

export type StaticRoute = {
  __typename?: 'StaticRoute';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dstPrefix: Scalars['IPPrefix']['output'];
  gatewayDHCPStaticMapping?: Maybe<DhcpStaticMapping>;
  gatewayIP?: Maybe<Scalars['IPV4']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 86400, one day. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /** The list of stat types to return. */
  types: Array<StatType>;
};

/** Allows users to specify which native VLAN to assign to the phy interface where a given MAC address or OUI is detected during a Switch Auto Config job. */
export type SwitchAutoConfigMacAddressVlanBinding = {
  __typename?: 'SwitchAutoConfigMACAddressVLANBinding';
  OUI?: Maybe<Scalars['String']['output']>;
  UUID: Scalars['UUID']['output'];
  VLAN: Vlan;
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID: Scalars['UUID']['output'];
};

/** Switch Auto Configuration Jobs automatically configure switch ports based on the connected clients and Meter devices. */
export type SwitchAutoConfigurationJob = AutoSelectionJob & {
  __typename?: 'SwitchAutoConfigurationJob';
  UUID: Scalars['UUID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Whether or not the job is a dry run. If true, no configuration changes are made. */
  isDryRun: Scalars['Boolean']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** Only ports that had their configuration changed will appear in the results. */
  results?: Maybe<Array<SwitchAutoConfigurationResult>>;
  scheduledAt: Scalars['DateTime']['output'];
  timeoutAt: Scalars['DateTime']['output'];
  virtualDeviceUUIDs: Array<Scalars['UUID']['output']>;
};

export type SwitchAutoConfigurationJobInput = {
  /** If true, the job will not make any configuration changes. It will only return the changes it would have made. */
  isDryRun?: Scalars['Boolean']['input'];
  /** The list of Switch virtual devices to run Auto Configuration on. If nil, all switches in the network will be auto-configured. */
  switchVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** The Native VLAN to assign to the switch port if the clients on the port are not classified in the client MAC -> VLAN mappings for the network. */
  uncategorizedClientNativeVLANUUID: Scalars['UUID']['input'];
};

export type SwitchAutoConfigurationResult = {
  __typename?: 'SwitchAutoConfigurationResult';
  /** The new Native VLAN assigned to the port. Null only if the VLAN has since been deleted. */
  newNativeVLAN?: Maybe<Vlan>;
  /** The port that was configured. */
  phyInterface: PhyInterface;
  /** The previous Native VLAN assigned to the port. Null only if the VLAN has since been deleted. */
  previousNativeVLAN?: Maybe<Vlan>;
  /** The direction of the toggle between access <-> trunk (or no change). */
  toggleDirection: SwitchAutoConfigurationResultToggleDirection;
  /** The switch virtual device that was configured. */
  virtualDevice: SwitchVirtualDevice;
  warnings?: Maybe<Array<SwitchAutoConfigurationResultWarning>>;
};

export enum SwitchAutoConfigurationResultToggleDirection {
  AccessToTrunk = 'ACCESS_TO_TRUNK',
  NoChange = 'NO_CHANGE',
  TrunkToAccess = 'TRUNK_TO_ACCESS',
}

export enum SwitchAutoConfigurationResultWarning {
  /** Clients on the port had multiple mapped native VLANs based on their MAC addresses / OUIs. The uncategorizedClientNativeVLANUUID was selected. */
  MultipleClientMacVlanMappings = 'MULTIPLE_CLIENT_MAC_VLAN_MAPPINGS',
  /** The switch did not report any stats so no configuration took place. */
  NoStatsReported = 'NO_STATS_REPORTED',
}

/** A switch hardware device represents a physical switch. */
export type SwitchHardwareDevice = HardwareDevice & {
  __typename?: 'SwitchHardwareDevice';
  /** Shows the most recent boot history of a device (buildName, bootCount). */
  bootHistory?: Maybe<Array<BootInfo>>;
  createdAt: Scalars['DateTime']['output'];
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  disconnectedFromBackendAt?: Maybe<Scalars['DateTime']['output']>;
  /** The IP address from our time series database for the device. */
  ipAddress?: Maybe<Scalars['String']['output']>;
  /**
   * True if the device has an active websocket connection to the backend. Note that this only works if the device has registered via the /v2/register endpoint (ie. is a Config 2 device) - otherwise is always false.
   * @deprecated Refer to `isSpare` instead.
   */
  isActive: Scalars['Boolean']['output'];
  isConnectedToBackend: Scalars['Boolean']['output'];
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDev: Scalars['Boolean']['output'];
  isFactoryMode: Scalars['Boolean']['output'];
  /** True if the Switch's MAC address exists in the most recent controller MAC table for the network. */
  isInCurrentControllerMACTable: Scalars['Boolean']['output'];
  isRMA: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  /** A spare hardware device belongs to a network but not a virtual device. Used most often to keep track of spare hardware we ship to a customer but don't install immediately. */
  isSpare: Scalars['Boolean']['output'];
  macAddress?: Maybe<Scalars['MacAddress']['output']>;
  networkUUID?: Maybe<Scalars['UUID']['output']>;
  /** Information about total PoE power usage. */
  poeInfo?: Maybe<PoEInfo>;
  /** The key used to add the device to the jumphost's WireGuard interface. */
  publicKey?: Maybe<Scalars['String']['output']>;
  serialNumber: Scalars['String']['output'];
  /** The STP information pulled out of our time series database for the device. */
  stpInfo?: Maybe<StpInfo>;
  /** IPv6 address on the jumphost subnet. Used to send requests to the device. */
  tunnelIPAddress?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
  virtualDevice?: Maybe<VirtualDevice>;
  virtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
};

/** A switch hardware device represents a physical switch. */
export type SwitchHardwareDeviceBootHistoryArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type SwitchMacTableEntry = {
  __typename?: 'SwitchMACTableEntry';
  macAddress: Scalars['MacAddress']['output'];
  port: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vlan?: Maybe<Vlan>;
  vlanID: Scalars['Int']['output'];
};

export type SwitchMetricsResponse = MetricsResponse & {
  __typename?: 'SwitchMetricsResponse';
  metadata?: Maybe<MetricsMetadata>;
  values: Array<SwitchPortMetricsValue>;
};

export type SwitchPortMetricsRateResponse = {
  __typename?: 'SwitchPortMetricsRateResponse';
  values: Array<SwitchPortMetricsRateValue>;
};

export type SwitchPortMetricsRateValue = {
  __typename?: 'SwitchPortMetricsRateValue';
  /** Total number of broadcast packets per second received in the given time period; a subset of totalRxPackets. */
  broadcastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of broadcast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  dropsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second received in the given time period; a subset of totalRxPackets. */
  multicastRxPacketsPerSecond: Scalars['Float']['output'];
  /** Total number of multicast packets per second transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPacketsPerSecond: Scalars['Float']['output'];
  /** The physical port number on the switch. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets per second that encountered errors being received. */
  rxErrPerSecond: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes per second received in the given time period. */
  totalRxBytesPerSecond: Scalars['Float']['output'];
  /** Total number of bytes per second transmitted in the given time period. */
  totalTxBytesPerSecond: Scalars['Float']['output'];
  /** The number of packets per second that encountered errors during transmission. */
  txErrPerSecond: Scalars['Float']['output'];
};

export type SwitchPortMetricsResponse = {
  __typename?: 'SwitchPortMetricsResponse';
  values: Array<SwitchPortMetricsStatValue>;
};

/** Counter stats per switch port over a lookback period. */
export type SwitchPortMetricsStatValue = {
  __typename?: 'SwitchPortMetricsStatValue';
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Float']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Float']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Float']['output'];
  /** The physical port number on the switch. */
  portNumber: Scalars['Int']['output'];
  /** The number of packets that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  rxDiscards: Scalars['Float']['output'];
  /** The number of packets that encountered errors being received. */
  rxErr: Scalars['Float']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Float']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Float']['output'];
  /** The number of packets that were dropped. Packet drops occur when a packet is discarded (and thus not forwarded or processed). */
  txDiscards: Scalars['Float']['output'];
  /** The number of packets that encountered errors during transmission. */
  txErr: Scalars['Float']['output'];
};

export type SwitchPortMetricsValue = MetricsValue & {
  __typename?: 'SwitchPortMetricsValue';
  direction: TrafficDirection;
  port: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

/** Summed stats (unless otherwise specified) per switch port over a lookback period. */
export type SwitchPortStat = {
  __typename?: 'SwitchPortStat';
  /** Total number of broadcast packets received in the given time period; a subset of totalRxPackets. */
  broadcastRxPackets: Scalars['Int']['output'];
  /** Total number of broadcast packets transmitted in the given time period; a subset of totalTxPackets. */
  broadcastTxPackets: Scalars['Int']['output'];
  /** Total number of packets discarded in the given time period; a subset of totalRxPackets. */
  discardRxPackets: Scalars['Int']['output'];
  /** Total number of packets discarded in the given time period; a subset of totalTxPackets. */
  discardTxPackets: Scalars['Int']['output'];
  /** Total number of packets with errors in the given time period; a subset of totalRxPackets. */
  errorRxPackets: Scalars['Int']['output'];
  /** Total number of packets with error in the given time period; a subset of totalTxPackets. */
  errorTxPackets: Scalars['Int']['output'];
  /** Total number of multicast packets received in the given time period; a subset of totalRxPackets. */
  multicastRxPackets: Scalars['Int']['output'];
  /** Total number of multicast packets transmitted in the given time period; a subset of totalTxPackets. */
  multicastTxPackets: Scalars['Int']['output'];
  /** Total number of non-unicast packets received in the given time period; a subset of totalRxPackets. */
  nonUnicastRxPackets: Scalars['Int']['output'];
  /** Total number of non-unicast packets transmitted in the given time period; a subset of totalTxPackets. */
  nonUnicastTxPackets: Scalars['Int']['output'];
  /** The physical port number on the switch. */
  portNumber: Scalars['Int']['output'];
  /** Total number of bytes received in the given time period. */
  totalRxBytes: Scalars['Int']['output'];
  /** Total number of packets received in the given time period. */
  totalRxPackets: Scalars['Int']['output'];
  /** Total number of bytes transmitted in the given time period. */
  totalTxBytes: Scalars['Int']['output'];
  /** Total number of packets transmitted in the given time period. */
  totalTxPackets: Scalars['Int']['output'];
  /** Total number of unicast packets received in the given time period; a subset of totalRxPackets. */
  unicastRxPackets: Scalars['Int']['output'];
  /** Total number of unicast packets transmitted in the given time period; a subset of totalTxPackets. */
  unicastTxPackets: Scalars['Int']['output'];
};

/** A switch profile is a bundle of settings that applies to a switch device. */
export type SwitchProfile = {
  __typename?: 'SwitchProfile';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** The STP bridge priority for the switch. The lower the value, the more likely the switch is to be elected as the root bridge. The range is 0 to 61440. Valid values are multiples of 4096. */
  stpBridgePriority: Scalars['Int']['output'];
  /** STP forwarding delay in seconds */
  stpForwardingDelaySeconds: Scalars['Int']['output'];
  /** STP Hello interval in seconds */
  stpHelloIntervalSeconds: Scalars['Int']['output'];
  /** STP Max BPDU age in seconds */
  stpMaxAgeSeconds: Scalars['Int']['output'];
  /** STP BPDU transmit count per sec */
  stpTransmitHoldCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** A switch virtual device is a logical representation of a physical switch. */
export type SwitchVirtualDevice = VirtualDevice & {
  __typename?: 'SwitchVirtualDevice';
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  phyInterfaces: Array<PhyInterface>;
  /** Information about total PoE power usage. */
  poeInfo?: Maybe<PoEInfo>;
  switchProfile: SwitchProfile;
  switchProfileUUID: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type SystemInfoStat = {
  __typename?: 'SystemInfoStat';
  /** The used CPU percentage represented as a value [0, 1] */
  cpuUsedPercentage: Scalars['Float']['output'];
  memoryBytesAvailable: Scalars['Int']['output'];
  memoryBytesTotal: Scalars['Int']['output'];
  memoryBytesUsed: Scalars['Int']['output'];
  uptime: Scalars['Duration']['output'];
};

export type SystemInfoStatsFilter = {
  /** The lookback time to consider stats for, starting at `endTime`. Maximum allowed value is 86400, one day. */
  durationSeconds: Scalars['Int']['input'];
  /** The latest observation time to consider stats for, inclusive. Defaults to the current time if not set. */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** The maximum number of results to return, between 1 and 1000. Defaults to 100 if not set. */
  limit?: Scalars['Int']['input'];
};

export type TestTriggerAlertTargetWebhookInput = {
  eventName: Scalars['String']['input'];
  payload: Scalars['String']['input'];
};

export type ThroughputValueWithDirection = {
  __typename?: 'ThroughputValueWithDirection';
  direction: TrafficDirection;
  value: Scalars['Int']['output'];
};

export type TokenPrincipal = {
  __typename?: 'TokenPrincipal';
  name: Scalars['String']['output'];
  type: PrincipalType;
};

export enum TokenType {
  Bearer = 'BEARER',
}

export enum TrafficDirection {
  Rx = 'RX',
  Tx = 'TX',
}

export type TwilioCellularActivationInput = {
  country: TwilioCellularCountry;
  iccid: Scalars['String']['input'];
};

export type TwilioCellularActivationResponse = {
  __typename?: 'TwilioCellularActivationResponse';
  accountSID?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fleetName?: Maybe<Scalars['String']['output']>;
  fleetURL?: Maybe<Scalars['String']['output']>;
  iccid?: Maybe<Scalars['String']['output']>;
  sid?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TwilioCellularActivationStatus>;
  uniqueName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum TwilioCellularActivationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  New = 'NEW',
  Ready = 'READY',
  Scheduled = 'SCHEDULED',
}

export enum TwilioCellularCountry {
  /** Canada */
  Ca = 'CA',
  /** Used for all other countries. */
  Other = 'OTHER',
  /** United States of America */
  Us = 'US',
}

export type UnassignedHardwareAndConnectedPhyInterface = {
  __typename?: 'UnassignedHardwareAndConnectedPhyInterface';
  /** The physical interface of the switch that the unassigned hardware device is connected to. */
  phyInterface: PhyInterface;
  /** The physically connected hardware device that is not associated with any virtual device in this network. */
  unassignedHardwareDevice: AccessPointHardwareDevice;
};

export type UnassignedHardwareDeviceForNetworkResponse = {
  __typename?: 'UnassignedHardwareDeviceForNetworkResponse';
  /** Tuples of unassigned hardware devices and the physical interfaces they are connected to. */
  unassignedHardwareConnections: Array<UnassignedHardwareAndConnectedPhyInterface>;
  /** The switch virtual device that the unassigned hardware device is connected to. */
  virtualDevice: SwitchVirtualDevice;
};

export enum UnauthenticatedReferer {
  Any = 'ANY',
  Captive = 'CAPTIVE',
  Install = 'INSTALL',
}

export type UnbindFirewallRuleFromPhyInterface = {
  firewallRuleUUID: Scalars['UUID']['input'];
  phyInterfaceUUID: Scalars['UUID']['input'];
};

export type UnbindFirewallRuleFromVlan = {
  firewallRuleUUID: Scalars['UUID']['input'];
  vlanUUID: Scalars['UUID']['input'];
};

export type UnbindPolicyRoutingRuleInput = {
  binding: PolicyRoutingRuleBindingInput;
};

/** The provided list of targets replaces the existing targets entirely. Any targets not included in this list will be removed. */
export type UpdateAlertReceiverWithTargetsInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  targets?: InputMaybe<Array<AlertTargetInput>>;
};

export type UpdateAlertTargetWebhookInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  signingSecret?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApplicationDnsFirewallRuleApplicationVisibilities = {
  id: Scalars['Int']['input'];
  isVisible: Scalars['Boolean']['input'];
};

export type UpdateApplicationDnsFirewallRuleInput = {
  action?: InputMaybe<FirewallRuleAction>;
  applicationID?: InputMaybe<Scalars['Int']['input']>;
  categoryID?: InputMaybe<Scalars['Int']['input']>;
  /** Updates the DHCP rule a rule is associated with. If priority is not specified will default to 1 greater than the highest existing priority. */
  dhcpRuleUUID?: InputMaybe<Scalars['UUID']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  groupID?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateApplicationDnsFirewallRulesPrioritiesInput = {
  applicationDNSFirewallRuleUUID: Scalars['UUID']['input'];
  priority: Scalars['Int']['input'];
};

export type UpdateAutoVpnGroupInput = {
  isHubFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uplink?: InputMaybe<CreateAutoVpnGroupHubUplinkInput>;
};

/** Acts as a PUT */
export type UpdateAutoVpnMemberInput = {
  isFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  permittedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateAutoVpnRouteInput = {
  dstGateway?: InputMaybe<Scalars['IPV4']['input']>;
  dstPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientVpnServerInput = {
  address?: InputMaybe<Scalars['IPV4']['input']>;
  addressMask?: InputMaybe<Scalars['Int']['input']>;
  defaultClientAllowedIPs?: InputMaybe<Array<Scalars['IPPrefix']['input']>>;
  /** An additional listen address other than `address` this server serves DNS traffic on. It must be a private IP. */
  dnsListenAddress?: InputMaybe<Scalars['IPV4']['input']>;
  isFailoverEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  listenPort?: InputMaybe<Scalars['Int']['input']>;
  /** Must be an uplink phy interface. The WireGuard interface will listen on this interface. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateCompanyInput = {
  isProspective?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Fields that can be updated for a company user. */
export type UpdateCompanyUserInput = {
  companyMembershipRole?: InputMaybe<CompanyMembershipRole>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDhcpOptionInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDhcpReservedRangeInput = {
  endIPAddress?: InputMaybe<Scalars['IP']['input']>;
  startIPAddress?: InputMaybe<Scalars['IP']['input']>;
};

export type UpdateDhcpReservedRangeUuidInput = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpReservedRangeInput;
};

export type UpdateDhcpRuleInput = {
  dnsCacheIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dnsCacheMaxTTL?: InputMaybe<Scalars['Int']['input']>;
  dnsCacheSize?: InputMaybe<Scalars['Int']['input']>;
  dnsSearchDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  dnsUpstreamServers?: InputMaybe<Array<Scalars['IP']['input']>>;
  dnsUseGatewayProxy?: InputMaybe<Scalars['Boolean']['input']>;
  endIPAddress?: InputMaybe<Scalars['IP']['input']>;
  gatewayIPAddress?: InputMaybe<Scalars['IP']['input']>;
  gatewayPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isIPv6?: InputMaybe<Scalars['Boolean']['input']>;
  leaseDurationSeconds?: InputMaybe<Scalars['Int']['input']>;
  reservedRanges?: InputMaybe<Array<UpdateDhcpReservedRangeUuidInput>>;
  startIPAddress?: InputMaybe<Scalars['IP']['input']>;
  staticMappings?: InputMaybe<Array<UpdateDhcpStaticMappingUuidInput>>;
};

export type UpdateDhcpStaticMappingInput = {
  hostname?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['IP']['input']>;
  macAddress?: InputMaybe<Scalars['MacAddress']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDhcpStaticMappingUuidInput = {
  UUID: Scalars['UUID']['input'];
  input: UpdateDhcpStaticMappingInput;
};

export type UpdateDnsHostMappingInput = {
  destinationDomain?: InputMaybe<Scalars['String']['input']>;
  destinationIPAddress?: InputMaybe<Scalars['IP']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  overrideDomain?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEncryption8021XInput = {
  accountingServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  accountingServerPort?: InputMaybe<Scalars['Int']['input']>;
  accountingServerSecret?: InputMaybe<Scalars['String']['input']>;
  authServerIPAddress?: InputMaybe<Scalars['IP']['input']>;
  authServerPort?: InputMaybe<Scalars['Int']['input']>;
  authServerSecret?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFirewallRuleInput = {
  action?: InputMaybe<FirewallRuleAction>;
  /** The phy interfaces must be uplinks. */
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  dstIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** @deprecated Use `dstPortRanges` instead. */
  dstPortRange?: InputMaybe<PortRangeInput>;
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  dstVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  isBidirectional?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isMeterInternal?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use `protocols` instead. */
  protocol?: InputMaybe<IpProtocol>;
  protocols?: InputMaybe<Array<IpProtocol>>;
  srcClientVPNServerUUID?: InputMaybe<Scalars['UUID']['input']>;
  srcIPSecTunnelUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** @deprecated Use `srcPortRanges` instead. */
  srcPortRange?: InputMaybe<PortRangeInput>;
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateFloorPlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageS3Key?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateForCellularFailoverEventInput = {
  route: CellularFailoverEventRoute;
};

export type UpdateForHighAvailabilityEventInput = {
  status: HighAvailabilityStatus;
};

/** Specifies the metadata that can be updated for a hardware device. */
export type UpdateHardwareDeviceInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  consoleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  factoryMode?: InputMaybe<Scalars['Boolean']['input']>;
  retired?: InputMaybe<Scalars['Boolean']['input']>;
  rma?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateHostMonitoringTargetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateIpSecTunnelInput = {
  authenticationAlgorithm?: InputMaybe<IpSecAuthenticationAlgorithm>;
  encryptionAlgorithm?: InputMaybe<IpSecEncryptionAlgorithm>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isInitiator?: InputMaybe<Scalars['Boolean']['input']>;
  keyExchangeDHGroup?: InputMaybe<IpSecDhGroup>;
  leftID?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** The phy interface must be an uplink. */
  phyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  /** presharedKey can be base64 or plaintext. */
  presharedKey?: InputMaybe<Scalars['String']['input']>;
  right?: InputMaybe<Scalars['String']['input']>;
  rightID?: InputMaybe<Scalars['String']['input']>;
  rightPrefixes?: InputMaybe<Array<Scalars['IPPrefix']['input']>>;
  vlanUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UpdateInternalNoteInput = {
  isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** Use the `createPreSignedUploadUrl` mutation to generate S3 keys and presigned URLs for the photos, and specify them here. */
  photoS3Keys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateInternetServicePlanInput = {
  connection?: InputMaybe<InternetServicePlanConnectionInput>;
  ipConfiguration?: InputMaybe<InternetServicePlanIpInput>;
  providerUUID?: InputMaybe<Scalars['UUID']['input']>;
  sublocationUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateMailingAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryISO2?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  subdivisionCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMaintenanceWindowInput = {
  endDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  endHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  excludedDaysOfWeek?: InputMaybe<Array<Scalars['Int']['input']>>;
  isOptedOut?: InputMaybe<Scalars['Boolean']['input']>;
  recurrence?: InputMaybe<MaintenanceWindowRecurrence>;
  startDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  startHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateNetworkInput = {
  enabledServiceDiscoveryTypes?: InputMaybe<Array<ServiceType>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Enables controller to upgrade config-1 APs deployed to the network */
  isConfig1WosUpgradesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Not a network property anymore */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isUpgradeSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  mailingAddressInput?: InputMaybe<CreateMailingAddressInput>;
  mailingAddressUUID?: InputMaybe<Scalars['UUID']['input']>;
  multiWANAlgorithm?: InputMaybe<MultiWanAlgorithm>;
  patchPanelDiagramURL?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Not a network property anymore */
  squareFeet?: InputMaybe<Scalars['Int']['input']>;
  topologyImageS3Key?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNosFeatureInput = {
  description: Scalars['String']['input'];
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  maxMajorVersion?: InputMaybe<Scalars['Int']['input']>;
  maxMinorVersion?: InputMaybe<Scalars['Int']['input']>;
  maxPatchVersion?: InputMaybe<Scalars['Int']['input']>;
  minMajorVersion: Scalars['Int']['input'];
  minMinorVersion: Scalars['Int']['input'];
  minPatchVersion: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateNotificationSettingInput = {
  alertReceiverUUID?: InputMaybe<Scalars['UUID']['input']>;
  arguments?: InputMaybe<Array<NotificationSettingArgumentInput>>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the user who updated the notification setting */
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOneToOneNatRuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalIPAddr?: InputMaybe<Scalars['IPV4']['input']>;
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  internalIPAddr?: InputMaybe<Scalars['IPV4']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOutletInterfaceInput = {
  /** UUID of the virtual device connected to the power outlet */
  connectedVirtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  powerCycleDelaySec?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePhyInterfaceForMultiWanEventInput = {
  activePortNumber: Scalars['Int']['input'];
  activePortNumberUpdatedAt: Scalars['DateTime']['input'];
  uplinkExternalAddresses: Array<Scalars['UnmaskedIPPrefix']['input']>;
};

export type UpdatePhyInterfaceInput = {
  /** The maximum transmission unit - max size in bytes of frames that the port can tx/rx */
  MTU?: InputMaybe<Scalars['Int']['input']>;
  allowedVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  forcedPortSpeedMbps?: InputMaybe<Scalars['Int']['input']>;
  frameAcceptTypeFilter?: InputMaybe<PhyInterfaceFrameAcceptTypeFilter>;
  ipv4ClientAddresses?: InputMaybe<Array<Scalars['IPV4']['input']>>;
  ipv4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipv4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  ipv4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  ipv6ClientAddresses?: InputMaybe<Array<Scalars['IPV6']['input']>>;
  ipv6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipv6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  ipv6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isBoundToAllVLANs?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isIngressFilteringEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPOEEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSTPEdgePortEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSTPEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isStormControlEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isTrunkPort?: InputMaybe<Scalars['Boolean']['input']>;
  isUplink?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** For switch port security, the max number of MAC addresses allowed on the port */
  maxMACAddressesAllowed?: InputMaybe<Scalars['Int']['input']>;
  nativeVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
  rxRateLimitKbps?: InputMaybe<Scalars['Int']['input']>;
  stormControlBroadcastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  stormControlUnknownMulticastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  stormControlUnknownUnicastTrafficPercent?: InputMaybe<Scalars['Int']['input']>;
  stpPathCost?: InputMaybe<Scalars['Int']['input']>;
  stpPriority?: InputMaybe<Scalars['Int']['input']>;
  txRateLimitKbps?: InputMaybe<Scalars['Int']['input']>;
  uplinkPriority?: InputMaybe<Scalars['Int']['input']>;
  uplinkVLANID?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePolicyRoutingRuleInput = {
  bindings?: InputMaybe<Array<PolicyRoutingRuleBindingInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstPortRanges?: InputMaybe<Array<PortRangeInput>>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protocols?: InputMaybe<Array<IpProtocol>>;
  srcPortRanges?: InputMaybe<Array<PortRangeInput>>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  srcVLANUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdatePortForwardingRuleInput = {
  allowedRemoteIPAddresses?: InputMaybe<Array<Scalars['IP']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalIPAddress?: InputMaybe<Scalars['IP']['input']>;
  externalPhyInterfaceUUID?: InputMaybe<Scalars['UUID']['input']>;
  externalPort?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  localIPAddress?: InputMaybe<Scalars['IP']['input']>;
  localPort?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protocol?: InputMaybe<PortIpProtocol>;
};

export type UpdateRackElevationDeviceInput = {
  /** Whether the device is front-facing or rear-facing. */
  isFrontFacing?: InputMaybe<Scalars['Boolean']['input']>;
  /** For display purposes. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** For additional context. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Only for Switches and Patch panels; cannot be specified if it's a Meter device. */
  portCount?: InputMaybe<Scalars['Int']['input']>;
  /** The indexes of the rack units that the device occupies. */
  rackMountUnitIndexes?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Cannot be specified if it's a Meter device. */
  type?: InputMaybe<RackElevationDeviceType>;
  /** If it's a Meter device, the virtual device's UUID. */
  virtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateRackElevationInput = {
  /** The left-to-right position of the rack relative to others. */
  horizontalIndex?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Array<RackElevationNoteInput>>;
  rackMountUnitCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRadioProfileInput = {
  /** Required if band2_4GAutoChannelIsEnabled is true. */
  band2_4GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  band2_4GAutoChannelIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  band2_4GAutoChannelWidthIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  band2_4GAutoTxPowerIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band2_4GAutoTxPowerIsEnabled is true. */
  band2_4GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band2_4GAutoChannelWidthIsEnabled is false. */
  band2_4GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  band2_4GIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required if band5GAutoChannelIsEnabled is true. */
  band5GAllowedChannels?: InputMaybe<Array<Scalars['Int']['input']>>;
  band5GAutoChannelIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  band5GAutoChannelWidthIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  band5GAutoTxPowerIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Required if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMaxdBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band5GAutoTxPowerIsEnabled is true. */
  band5GAutoTxPowerMindBm?: InputMaybe<Scalars['Int']['input']>;
  /** Required if band5GAutoChannelWidthIsEnabled is false. */
  band5GChannelWidthMHz?: InputMaybe<Scalars['Int']['input']>;
  band5GIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Setting this to true will set all other profiles to false. */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Specifies the metadata that can be updated for radio settings for an Access Point. */
export type UpdateRadioSettingsInput = {
  band2_4GPrimaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band2_4GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band2_4GTransmitPowerdBm?: InputMaybe<Scalars['Int']['input']>;
  band5GPrimaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band5GSecondaryChannel?: InputMaybe<Scalars['Int']['input']>;
  band5GTransmitPowerdBm?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateRateLimitRuleInput = {
  boundPhyInterfaceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  boundVLANUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dstMac?: InputMaybe<Scalars['MacAddress']['input']>;
  dstPortRange?: InputMaybe<PortRangeInput>;
  dstPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPerSource?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  protocol?: InputMaybe<IpProtocol>;
  rateLimitKbpsDownload?: InputMaybe<Scalars['Int']['input']>;
  rateLimitKbpsUpload?: InputMaybe<Scalars['Int']['input']>;
  srcMac?: InputMaybe<Scalars['MacAddress']['input']>;
  srcPortRange?: InputMaybe<PortRangeInput>;
  srcPrefix?: InputMaybe<Scalars['IPPrefix']['input']>;
};

export type UpdateSsidInput = {
  /** The lowest rate at which a client can connect to the 2.4 GHz radio, in kilobits per second. */
  band2GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  /** The lowest rate at which a client can connect to the 5 GHz radio, in kilobits per second. */
  band5GMinimumBasicRateKbps?: InputMaybe<Scalars['Int']['input']>;
  clientSteeringAssociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientSteeringLoadBalancingClientMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  clientSteeringPostassociationSteeringIsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clientSteeringPreassociationRSSIMinThreshold?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dtimPeriod?: InputMaybe<Scalars['Int']['input']>;
  dynamicVLANMode?: InputMaybe<SsidDynamicVlanMode>;
  /** Required if isEnabledForAllAccessPoints is false. */
  enabledAccessPointVirtualDeviceUUIDs?: InputMaybe<Array<Scalars['UUID']['input']>>;
  encryption80211wProtectedManagementFramesMode?: InputMaybe<SsidEncryption80211wProtectedManagementFramesMode>;
  encryptionProtocol?: InputMaybe<SsidEncryptionProtocol>;
  hs20AccessNetworkType?: InputMaybe<Scalars['Int']['input']>;
  /** When set to true, clears all hs20 data. */
  hs20ClearAll?: InputMaybe<Scalars['Boolean']['input']>;
  hs20DomainNames?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20Enabled?: InputMaybe<Scalars['Boolean']['input']>;
  hs20MccMncPairs?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20NaiRealms?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20OperatorNames?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20RoamingConsortiumOIs?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20VenueGroup?: InputMaybe<Scalars['Int']['input']>;
  hs20VenueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  hs20VenueType?: InputMaybe<Scalars['Int']['input']>;
  is80211axEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isAPDebugLogEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isBand2GAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isBand5GAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabledForAllAccessPoints?: InputMaybe<Scalars['Boolean']['input']>;
  isGuest?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isIsolateClientsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isMulticastToUnicastEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211kEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211rEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRoaming80211vEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRotationCadence?: InputMaybe<SsidPasswordRotationCadence>;
  passwordRotationDayOfMonthLocal?: InputMaybe<Scalars['Int']['input']>;
  passwordRotationDayOfWeekLocal?: InputMaybe<Scalars['Int']['input']>;
  passwordRotationHourOfDayLocal?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the primary 8021x encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  primaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  radiusCoaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  radiusCoaPort?: InputMaybe<Scalars['Int']['input']>;
  rtsCtsEnabledCode?: InputMaybe<Scalars['Int']['input']>;
  rtsThreshold?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Set the secondary 8021x encryption configuration.
   * Required if encryptionProtocol is WPA2_ENTERPRISE.
   */
  secondaryEncryption8021XUUID?: InputMaybe<Scalars['UUID']['input']>;
  ssid?: InputMaybe<Scalars['String']['input']>;
  vlanUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateStaticRouteInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSwitchProfileInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  stpBridgePriority?: InputMaybe<Scalars['Int']['input']>;
  /** STP forwarding delay in seconds (4-30) */
  stpForwardingDelaySeconds?: InputMaybe<Scalars['Int']['input']>;
  /** STP Hello interval in seconds (1-10) */
  stpHelloIntervalSeconds?: InputMaybe<Scalars['Int']['input']>;
  /** STP Max BPDU age in seconds (6-40) */
  stpMaxAgeSeconds?: InputMaybe<Scalars['Int']['input']>;
  /** STP BPDU transmit count per sec (1-10) */
  stpTransmitHoldCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUplinkPhyInterfaceExternalAddressesInput = {
  portNumber: Scalars['Int']['input'];
  uplinkExternalAddresses: Array<Scalars['UnmaskedIPPrefix']['input']>;
  uplinkExternalAddressesUpdatedAt: Scalars['DateTime']['input'];
};

export type UpdateVlandhcpRuleInput = {
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: UpdateDhcpRuleInput;
};

export type UpdateVlanInput = {
  /** @deprecated Use `UUID` in `updateVLAN` instead. */
  UUID?: InputMaybe<Scalars['UUID']['input']>;
  createDHCPRules?: InputMaybe<Array<CreateDhcpRuleInput>>;
  deleteDHCPRules?: InputMaybe<Array<Scalars['UUID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  ipV4ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipV4ClientGateway?: InputMaybe<Scalars['IPV4']['input']>;
  ipV4ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  ipV6ClientAssignmentProtocol?: InputMaybe<ClientAssignmentProtocol>;
  ipV6ClientGateway?: InputMaybe<Scalars['IPV6']['input']>;
  ipV6ClientPrefixLength?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isMulticastReflectionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updateDHCPRules?: InputMaybe<Array<UpdateVlandhcpRuleInput>>;
  vlanID?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateVlaNsIndependentlyInput = {
  UUID: Scalars['UUID']['input'];
  input: UpdateVlaNsInput;
};

export type UpdateVlaNsInput = {
  isMulticastReflectionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Specifies the metadata that can be updated for a virtual device, used to update many virtual devices at once with different values. */
export type UpdateVirtualDeviceIndependentlyInput = {
  input: UpdateVirtualDeviceInput;
  virtualDeviceUUID: Scalars['UUID']['input'];
};

/** Specifies the metadata that can be updated for a virtual device. */
export type UpdateVirtualDeviceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * For some devices, we will allow the user to set the device model.
   * This will be useful for hardware swaps where we want to retain the same configuration for the virtual device.
   * There will be application validation, since sometimes chanign the model is not valid (e.g. if the number of ports on the device differs,
   * or if the new model doesn't support features that the old model did).
   */
  deviceModel?: InputMaybe<DeviceModel>;
  enableConsolePort?: InputMaybe<Scalars['Boolean']['input']>;
  enableLEDDarkMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Currently we only support enabling debug logs on APs. */
  isDebugLogEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  radioProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
  radioSettings?: InputMaybe<UpdateRadioSettingsInput>;
  switchProfileUUID?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertNetworkOnboardingFieldDelegationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** A user is a person or machine that has access to Meter. */
export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

/**
 * Allows for adding a role assignment to a user, or removing a role assignment from a user. A role assignment
 * is defined in terms of global, company wide, or company & network wide.
 */
export type UserRoleInput = {
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
  roleName: RoleName;
};

/** A VLAN (virtual LAN) is a logical network segment that allows network devices to communicate as if they were physically connected to the same network. */
export type Vlan = {
  __typename?: 'VLAN';
  UUID: Scalars['UUID']['output'];
  /** The captive portal associated with this VLAN. */
  captivePortal?: Maybe<CaptivePortal>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dhcpRule?: Maybe<DhcpRule>;
  /** If assigning this interface as a DHCP client, this field indicates the protocol to use for IPv4 addresses. */
  ipV4ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a DHCP client, this field indicates the gateway IPv4 address. This INET value should have no netmask. */
  ipV4ClientGateway?: Maybe<Scalars['IPV4']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the IPv4 prefixes to assign to the interface. */
  ipV4ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the protocol to use for IPv6 addresses. */
  ipV6ClientAssignmentProtocol?: Maybe<ClientAssignmentProtocol>;
  /** If assigning this interface as a DHCP client, this field indicates the gateway IPv6 address. This INET value should have no netmask. */
  ipV6ClientGateway?: Maybe<Scalars['IPV6']['output']>;
  /** If assigning this interface as a DHCP client, this field indicates the IPv6 prefixes to assign to the interface. */
  ipV6ClientPrefixLength?: Maybe<Scalars['Int']['output']>;
  /** Only one VLAN per network can be the default VLAN. Untagged packets that don't belong to any configured VLANs are assigned to the default one. */
  isDefault: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** If true, this VLAN is internal to the network and should not be displayed to / editable by the customer. */
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  /** If true, this VLAN participates in multicast/mDNS reflection. */
  isMulticastReflectionEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name to identify the VLAN, like "guest". Unique per network. */
  name: Scalars['String']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** VLANs explicitly allowed to inter-communicate with this VLAN. Effectively equivalent to firewall rules allowing communication between this VLAN and all of these VLANs. */
  permittedInterVLANCommunicationVLANs: Array<Vlan>;
  updatedAt: Scalars['DateTime']['output'];
  /** The VLAN ID. Must be between 1 and 4094 and is unique per network. */
  vlanID: Scalars['Int']['output'];
};

export type VlanPairInput = {
  vlan1UUID: Scalars['UUID']['input'];
  vlan2UUID: Scalars['UUID']['input'];
};

export type ValidChannelsResponse = {
  __typename?: 'ValidChannelsResponse';
  band: RadioBand;
  channels: Array<Scalars['Int']['output']>;
};

/** A virtual device is a logical representation of a physical device; configuration is associated with the virtual device. */
export type VirtualDevice = {
  UUID: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceModel: DeviceModel;
  deviceType: VirtualDeviceType;
  hardwareDevice?: Maybe<HardwareDevice>;
  isConsoleEnabled: Scalars['Boolean']['output'];
  isDebugLogEnabled: Scalars['Boolean']['output'];
  /** A human-friendly name for the device. */
  label: Scalars['String']['output'];
  network: Network;
  networkUUID: Scalars['UUID']['output'];
  nosVersion?: Maybe<ConfiguredNosVersion>;
  nosVersionID: Scalars['Int']['output'];
  pendingNosVersion?: Maybe<PendingNosVersion>;
  updatedAt: Scalars['DateTime']['output'];
  /** The uptime from our time series database for the device. */
  uptime?: Maybe<Scalars['Duration']['output']>;
};

export type VirtualDeviceNosHistory = {
  __typename?: 'VirtualDeviceNosHistory';
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  nosVersion: NosVersion;
  scheduledAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
};

/** VirtualDeviceType enumerates the different types of virtual devices that Meter deploys. */
export enum VirtualDeviceType {
  AccessPoint = 'ACCESS_POINT',
  Controller = 'CONTROLLER',
  Observer = 'OBSERVER',
  PowerDistributionUnit = 'POWER_DISTRIBUTION_UNIT',
  Switch = 'SWITCH',
}

export enum Wan {
  Wan0 = 'wan0',
  Wan1 = 'wan1',
}

export type WanConnection = {
  __typename?: 'WANConnection';
  externalAddresses: Array<Scalars['String']['output']>;
  internetServicePlanProvider?: Maybe<InternetServicePlanProvider>;
  port: Scalars['Int']['output'];
};

export type WanDownProperties = {
  __typename?: 'WANDownProperties';
  virtualDevice: VirtualDevice;
};

export enum WanSpeedtestDirection {
  Down = 'DOWN',
  /** For simplicity in schema, latency is unintuitively considered a direction */
  Latency = 'LATENCY',
  Unspecified = 'UNSPECIFIED',
  Up = 'UP',
}

export type WanSpeedtestJob = NetworkJob & {
  __typename?: 'WANSpeedtestJob';
  UUID: Scalars['UUID']['output'];
  baseURL: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  maxTransferBytes?: Maybe<Scalars['Int']['output']>;
  networkUUID: Scalars['UUID']['output'];
  phyInterface: PhyInterface;
  results?: Maybe<Array<WanSpeedtestJobResult>>;
  scheduledAt: Scalars['DateTime']['output'];
  status?: Maybe<WanSpeedtestStatus>;
};

export type WanSpeedtestJobResult = {
  __typename?: 'WANSpeedtestJobResult';
  /** The speedtest direction. */
  direction: WanSpeedtestDirection;
  /** The duration is nanoseconds this result took. Is null for LATENCY results. */
  durationNs?: Maybe<Scalars['Int']['output']>;
  failed: Scalars['Boolean']['output'];
  /** The RTT in nanoseconds each HTTP request in this result took. */
  httpRTTsNs: Array<Scalars['Int']['output']>;
  /** The mbps for this result. Is null for LATENCY results. */
  mbps?: Maybe<Scalars['Float']['output']>;
  /** The number of bytes transferred in this result. Is null for LATENCY results. */
  transferBytes?: Maybe<Scalars['Int']['output']>;
};

export enum WanSpeedtestStatus {
  /** Indicates the test is not running. */
  Ok = 'OK',
  /** Indicates the test is currently running. */
  Running = 'RUNNING',
  Unknown = 'UNKNOWN',
}

export type WanStatusChangeProperties = {
  __typename?: 'WANStatusChangeProperties';
  activeWANConnections: Array<Maybe<WanConnection>>;
  virtualDevice: VirtualDevice;
};

export type WanUpProperties = {
  __typename?: 'WANUpProperties';
  virtualDevice: VirtualDevice;
};

/** Observer settings contain configuration for running wireless observer tests. */
export type WifiObserverSetting = {
  __typename?: 'WifiObserverSetting';
  /** The UUID of the SSID to use for the Observer test. */
  SSIDUUID: Scalars['UUID']['output'];
  /** Band on which the test should be run. */
  band: RadioBand;
  /** List of commands to run. */
  commands?: Maybe<Array<WosObserverCommand>>;
  /** Duration of the test, in msec. */
  durationMsec: Scalars['Int']['output'];
  networkUUID: Scalars['UUID']['output'];
  /** 8021X password. */
  password8021X?: Maybe<Scalars['String']['output']>;
  /** The UUID of the virtual device (access point) the observer will target or get results for. */
  targetVirtualDeviceUUID?: Maybe<Scalars['UUID']['output']>;
  /** 8021X username. */
  username8021X?: Maybe<Scalars['String']['output']>;
  /** The UUID of the virtual device to run the observer test on. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/**
 * A single snapshot of client connectivity metrics for a single client.
 * Not an average over a time period or across multiple clients.
 */
export type WirelessClient = {
  __typename?: 'WirelessClient';
  /** When the client associated with the AP. */
  associatedAt?: Maybe<Scalars['DateTime']['output']>;
  band: RadioBand;
  channel: Scalars['Int']['output'];
  macAddress: Scalars['MacAddress']['output'];
  metrics: ClientMetricsTimeseriesValue;
  networkUUID: Scalars['UUID']['output'];
  /** List of mdns service types that this client offers (e.g. printer, airplay, etc) */
  serviceTypes?: Maybe<Array<ServiceType>>;
  /** The SSID that the client interacted with. */
  ssid: Ssid;
  /** The name of the SSID that the client interacted with. */
  ssidName: Scalars['String']['output'];
  /** The AP virtual device that the client interacted with. */
  virtualDevice: AccessPointVirtualDevice;
  virtualDeviceUUID: Scalars['UUID']['output'];
};

/** A stat that tells us when a client connected to an AP, disassociated from an AP, or failed to connect to an AP. */
export type WirelessClientConnectionEvent = {
  __typename?: 'WirelessClientConnectionEvent';
  /** The radio band that the client interacted with. */
  band: RadioBand;
  eventType: WirelessClientConnectionEventType;
  /** If the event type is `FAILED`, explains which step of the connection process failed. */
  failedStep?: Maybe<WirelessClientConnectionFailedStep>;
  /** Whether or not the connection event was part of a fast transition (802.11r) roam. */
  is80211rRoam: Scalars['Boolean']['output'];
  /** The MAC address of the client. */
  macAddress: Scalars['MacAddress']['output'];
  /** If the event type is `CONNECTED`, the AP virtual device that the client was previously connected to. */
  previousVirtualDeviceConnectedTo?: Maybe<AccessPointVirtualDevice>;
  /** If the event type is `CONNECTED`, which AP the client was previously connected to. */
  previousVirtualDeviceUUIDConnectedTo?: Maybe<Scalars['UUID']['output']>;
  /** If the event type is `DISASSOCIATED` or `FAILED`, the 802.11 reason code for the event. */
  reasonCode?: Maybe<Scalars['Int']['output']>;
  /** If the event type is `DISASSOCIATED`, how long the client was associated with the AP before disassociating. */
  sessionDuration?: Maybe<Scalars['Duration']['output']>;
  /** The SSID that the client interacted with. */
  ssid?: Maybe<Ssid>;
  /** The UUID of the SSID that the client interacted with. */
  ssidUUID?: Maybe<Scalars['UUID']['output']>;
  /** If the event type is `CONNECTED`, how long it took for the client to connect to the AP. */
  timeToConnect?: Maybe<Scalars['Duration']['output']>;
  timestamp: Scalars['DateTime']['output'];
  /** The AP virtual device that the client interacted with. */
  virtualDevice: AccessPointVirtualDevice;
  /** The UUID of the AP virtual device that the client interacted with. */
  virtualDeviceUUID: Scalars['UUID']['output'];
};

export enum WirelessClientConnectionEventType {
  /** The client successfully connected to the AP. */
  Connected = 'CONNECTED',
  /** The client failed to receive an IP address from the DHCP server. */
  DhcpFailed = 'DHCP_FAILED',
  /** The client successfully received an IP address from the DHCP server. */
  DhcpOk = 'DHCP_OK',
  /** The client disassociated from the AP. */
  Disassociated = 'DISASSOCIATED',
  /** The client attempted to connect to the AP, but failed. */
  Failed = 'FAILED',
}

export enum WirelessClientConnectionFailedStep {
  /** The association packet exchange in the 802.11 handshake protocol failed. */
  Failed_80211Association = 'FAILED_80211_ASSOCIATION',
  /** The authentication packet exchange in the 802.11 handshake protocol failed. */
  Failed_80211Authentication = 'FAILED_80211_AUTHENTICATION',
  /** The 4-way EAPOL handshake failed. */
  FailedAuthentication = 'FAILED_AUTHENTICATION',
  /** The client declined the IP address from the DHCP server. */
  FailedDhcpDecline = 'FAILED_DHCP_DECLINE',
  /** The DHCP server denied the client's request for an IP address. */
  FailedDhcpNak = 'FAILED_DHCP_NAK',
  /** The DHCP server did not respond to the client's request for an IP address. */
  FailedDhcpTimeout = 'FAILED_DHCP_TIMEOUT',
  FailedOther = 'FAILED_OTHER',
}

export type WosObserverCommand = {
  __typename?: 'WosObserverCommand';
  /** The type of target (IPv4, IPv6, or hostname). */
  commandTargetType: WosObserverCommandTargetType;
  /** The type of command to run (e.g., ping, DNS, etc.). */
  commandType: WosObserverCommandType;
  /** An index to match with the test results. */
  index: Scalars['Int']['output'];
  /** Interval between tests, in msec. */
  intervalMsec: Scalars['Int']['output'];
  /** An index to run parallel tests (commands with same index run in parallel). */
  orderIndex: Scalars['Int']['output'];
  target: Scalars['String']['output'];
  /** Timeout between tests, in msec. */
  timeoutMsec: Scalars['Int']['output'];
};

export type WosObserverCommandInput = {
  commandTargetType: WosObserverCommandTargetType;
  commandType: WosObserverCommandType;
  index: Scalars['Int']['input'];
  intervalMsec: Scalars['Int']['input'];
  orderIndex: Scalars['Int']['input'];
  target: Scalars['String']['input'];
  timeoutMsec: Scalars['Int']['input'];
};

export enum WosObserverCommandTargetType {
  Hostname = 'HOSTNAME',
  Ipv4 = 'IPV4',
  Ipv6 = 'IPV6',
}

export enum WosObserverCommandType {
  Dns = 'DNS',
  Download = 'DOWNLOAD',
  Iperf = 'IPERF',
  Ping = 'PING',
}

export enum WosPacketCaptureCommandMonitor {
  Radio_2G = 'RADIO_2G',
  Radio_5G = 'RADIO_5G',
  Radio_6G = 'RADIO_6G',
  RadioScan = 'RADIO_SCAN',
  RadioUnspecified = 'RADIO_UNSPECIFIED',
}

export type RunAcsForAccessPointMutationMutationVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type RunAcsForAccessPointMutationMutation = {
  __typename?: 'Mutation';
  initializeAutoChannelSelectionForAccessPoint: {
    __typename?: 'AutoChannelSelectionJob';
    UUID: string;
    scheduledAt: string;
    timeoutAt: string;
  };
};

export type RunAcsForNetworkMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type RunAcsForNetworkMutationMutation = {
  __typename?: 'Mutation';
  initializeAutoChannelSelectionForNetwork: {
    __typename?: 'AutoChannelSelectionJob';
    UUID: string;
    scheduledAt: string;
    timeoutAt: string;
  };
};

export type AcsJobsForNetworkQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type AcsJobsForNetworkQueryQuery = {
  __typename?: 'Query';
  autoChannelSelectionJobsForNetwork?: Array<{
    __typename?: 'AutoChannelSelectionJob';
    UUID: string;
    scheduledAt: string;
    timeoutAt: string;
    completedAt?: string | null;
    virtualDeviceUUIDs: Array<string>;
    results?: Array<{
      __typename?: 'AutoChannelSelectionResult';
      timestamp: string;
      virtualDeviceUUID: string;
      resultType: AutoChannelSelectionResultType;
      previous5GHzChannel?: number | null;
      new5GHzChannel?: number | null;
    }> | null;
  }> | null;
};

export type ClientVpnServerFieldsFragment = {
  __typename?: 'ClientVPNServer';
  UUID: string;
  endpoint: string;
  publicKey: string;
  port: number;
  defaultClientAllowedIPs?: Array<string> | null;
  address?: string | null;
  addressMask?: number | null;
  dnsListenAddress?: string | null;
  isFailoverEnabled: boolean;
  phyInterface?: {
    __typename: 'PhyInterface';
    UUID: string;
    portNumber: number;
    label?: string | null;
    hardwareLabel?: string | null;
    virtualDevice:
      | {
          __typename?: 'AccessPointVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        }
      | {
          __typename?: 'ControllerVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        }
      | {
          __typename?: 'ObserverVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        }
      | {
          __typename?: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        }
      | {
          __typename?: 'SwitchVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        };
    internetServicePlan?: {
      __typename?: 'InternetServicePlan';
      provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
    } | null;
  } | null;
  clients: Array<{
    __typename?: 'ClientVPNClient';
    UUID: string;
    createdAt: string;
    createdBy: string;
    ipAddress: string;
    name: string;
    publicKey: string;
    userEmail: string;
    userSID: string;
    vpnServerUUID: string;
    connectionInfo?: {
      __typename?: 'ClientVPNClientConnectionInfo';
      observedAt?: string | null;
      lastHandshakeTx?: string | null;
      lastHandshakeRx?: string | null;
      lastPacketTx?: string | null;
      lastPacketRx?: string | null;
    } | null;
  }>;
};

export type ClientVpnServerQueryVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type ClientVpnServerQuery = {
  __typename?: 'Query';
  clientVPNServer: {
    __typename?: 'ClientVPNServer';
    UUID: string;
    endpoint: string;
    publicKey: string;
    port: number;
    defaultClientAllowedIPs?: Array<string> | null;
    address?: string | null;
    addressMask?: number | null;
    dnsListenAddress?: string | null;
    isFailoverEnabled: boolean;
    phyInterface?: {
      __typename: 'PhyInterface';
      UUID: string;
      portNumber: number;
      label?: string | null;
      hardwareLabel?: string | null;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    } | null;
    clients: Array<{
      __typename?: 'ClientVPNClient';
      UUID: string;
      createdAt: string;
      createdBy: string;
      ipAddress: string;
      name: string;
      publicKey: string;
      userEmail: string;
      userSID: string;
      vpnServerUUID: string;
      connectionInfo?: {
        __typename?: 'ClientVPNClientConnectionInfo';
        observedAt?: string | null;
        lastHandshakeTx?: string | null;
        lastHandshakeRx?: string | null;
        lastPacketTx?: string | null;
        lastPacketRx?: string | null;
      } | null;
    }>;
  };
};

export type ClientVpnServersQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type ClientVpnServersQuery = {
  __typename?: 'Query';
  clientVPNServers: Array<{
    __typename?: 'ClientVPNServer';
    UUID: string;
    endpoint: string;
    publicKey: string;
    port: number;
    defaultClientAllowedIPs?: Array<string> | null;
    address?: string | null;
    addressMask?: number | null;
    dnsListenAddress?: string | null;
    isFailoverEnabled: boolean;
    phyInterface?: {
      __typename: 'PhyInterface';
      UUID: string;
      portNumber: number;
      label?: string | null;
      hardwareLabel?: string | null;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    } | null;
    clients: Array<{
      __typename?: 'ClientVPNClient';
      UUID: string;
      createdAt: string;
      createdBy: string;
      ipAddress: string;
      name: string;
      publicKey: string;
      userEmail: string;
      userSID: string;
      vpnServerUUID: string;
      connectionInfo?: {
        __typename?: 'ClientVPNClientConnectionInfo';
        observedAt?: string | null;
        lastHandshakeTx?: string | null;
        lastHandshakeRx?: string | null;
        lastPacketTx?: string | null;
        lastPacketRx?: string | null;
      } | null;
    }>;
  }>;
};

export type CreateClientVpnServerMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateClientVpnServerInput;
}>;

export type CreateClientVpnServerMutation = {
  __typename?: 'Mutation';
  createClientVPNServer: { __typename?: 'ClientVPNServer'; UUID: string };
};

export type UpdateClientVpnServerMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateClientVpnServerInput;
}>;

export type UpdateClientVpnServerMutation = {
  __typename?: 'Mutation';
  updateClientVPNServer: { __typename?: 'ClientVPNServer'; UUID: string };
};

export type DeleteClientVpnServerMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteClientVpnServerMutation = {
  __typename?: 'Mutation';
  deleteClientVPNServer: boolean;
};

export type ClientVpnClientFieldsFragment = {
  __typename?: 'ClientVPNClient';
  UUID: string;
  createdAt: string;
  createdBy: string;
  ipAddress: string;
  name: string;
  publicKey: string;
  userEmail: string;
  userSID: string;
  vpnServerUUID: string;
  connectionInfo?: {
    __typename?: 'ClientVPNClientConnectionInfo';
    observedAt?: string | null;
    lastHandshakeTx?: string | null;
    lastHandshakeRx?: string | null;
    lastPacketTx?: string | null;
    lastPacketRx?: string | null;
  } | null;
};

export type ClientVpnClientsQueryVariables = Exact<{
  serverUUID: Scalars['UUID']['input'];
}>;

export type ClientVpnClientsQuery = {
  __typename?: 'Query';
  clientVPNClients: Array<{
    __typename?: 'ClientVPNClient';
    UUID: string;
    createdAt: string;
    createdBy: string;
    ipAddress: string;
    name: string;
    publicKey: string;
    userEmail: string;
    userSID: string;
    vpnServerUUID: string;
    connectionInfo?: {
      __typename?: 'ClientVPNClientConnectionInfo';
      observedAt?: string | null;
      lastHandshakeTx?: string | null;
      lastHandshakeRx?: string | null;
      lastPacketTx?: string | null;
      lastPacketRx?: string | null;
    } | null;
  }>;
};

export type ClientVpnClientQueryVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type ClientVpnClientQuery = {
  __typename?: 'Query';
  clientVPNClient: {
    __typename?: 'ClientVPNClient';
    UUID: string;
    createdAt: string;
    createdBy: string;
    ipAddress: string;
    name: string;
    publicKey: string;
    userEmail: string;
    userSID: string;
    vpnServerUUID: string;
    connectionInfo?: {
      __typename?: 'ClientVPNClientConnectionInfo';
      observedAt?: string | null;
      lastHandshakeTx?: string | null;
      lastHandshakeRx?: string | null;
      lastPacketTx?: string | null;
      lastPacketRx?: string | null;
    } | null;
  };
};

export type CreateClientVpnClientMutationVariables = Exact<{
  serverUUID: Scalars['UUID']['input'];
  input: CreateClientVpnClientInput;
}>;

export type CreateClientVpnClientMutation = {
  __typename?: 'Mutation';
  createClientVPNClient: {
    __typename?: 'ClientVPNClient';
    UUID: string;
    createdAt: string;
    createdBy: string;
    ipAddress: string;
    name: string;
    publicKey: string;
    userEmail: string;
    userSID: string;
  };
};

export type DeleteClientVpnClientMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteClientVpnClientMutation = {
  __typename?: 'Mutation';
  deleteClientVPNClient: boolean;
};

export type WirelessConnectionEventsQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  mac: Scalars['MacAddress']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type WirelessConnectionEventsQueryQuery = {
  __typename?: 'Query';
  wirelessClientConnectionEventsByClient: Array<{
    __typename?: 'WirelessClientConnectionEvent';
    timestamp: string;
    band: RadioBand;
    eventType: WirelessClientConnectionEventType;
    failedStep?: WirelessClientConnectionFailedStep | null;
    reasonCode?: number | null;
    sessionDuration?: string | null;
    timeToConnect?: string | null;
    is80211rRoam: boolean;
    macAddress: string;
    ssid?: { __typename?: 'SSID'; UUID: string; ssid: string } | null;
    virtualDevice: { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string };
    previousVirtualDeviceConnectedTo?: {
      __typename?: 'AccessPointVirtualDevice';
      UUID: string;
      label: string;
    } | null;
  }>;
};

export type WirelessClientMetricsByClientQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  macAddress: Scalars['MacAddress']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
}>;

export type WirelessClientMetricsByClientQueryQuery = {
  __typename?: 'Query';
  wirelessClientMetricsByClient: {
    __typename?: 'ClientMetricsTimeseriesResponse';
    metadata?: {
      __typename?: 'ClientMetricsMetadata';
      signal?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      noise?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      txBytes?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      rxBytes?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    } | null;
    values: Array<{
      __typename?: 'ClientMetricsTimeseriesValue';
      timestamp: string;
      signal: number;
      noise: number;
      txRate: number;
      rxRate: number;
      snr: number;
      txBytes: number;
      rxBytes: number;
      txMulticastBytes: number;
      rxMulticastBytes: number;
      txUnicastBytes: number;
      rxUnicastBytes: number;
      rxSuccessRatio?: number | null;
      txSuccessRatio?: number | null;
      rxRetryRatio?: number | null;
      txRetryRatio?: number | null;
      clientCount: number;
    }>;
  };
};

export type ClientChannelUtilizationQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  macAddress: Scalars['MacAddress']['input'];
  filter: ChannelUtilizationTimeseriesFilterInput;
}>;

export type ClientChannelUtilizationQueryQuery = {
  __typename?: 'Query';
  channelUtilizationByClient: Array<{
    __typename?: 'ClientChannelUtilizationTimeseriesValue';
    timestamp: string;
    totalUtilization: number;
  }>;
};

export type UpsertMacAddressAliasMutationVariables = Exact<{
  companySlug: Scalars['String']['input'];
  macAddress: Scalars['MacAddress']['input'];
  alias: Scalars['String']['input'];
}>;

export type UpsertMacAddressAliasMutation = {
  __typename?: 'Mutation';
  upsertMACAddressAlias: { __typename?: 'MACAddressAlias'; alias: string };
};

export type RpcDisconnectClientMutationVariables = Exact<{
  mac: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
  ssid: Scalars['String']['input'];
}>;

export type RpcDisconnectClientMutation = {
  __typename?: 'Mutation';
  rpcWosDisconnectClient: boolean;
};

export type VlaNsAndDhcpRulesQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type VlaNsAndDhcpRulesQuery = {
  __typename?: 'Query';
  vlans: Array<{
    __typename: 'VLAN';
    UUID: string;
    name: string;
    description?: string | null;
    isEnabled: boolean;
    isInternal?: boolean | null;
    isDefault: boolean;
    vlanID: number;
    ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
    ipV4ClientGateway?: string | null;
    ipV4ClientPrefixLength?: number | null;
    dhcpRule?: { __typename?: 'DHCPRule'; UUID: string; dnsUseGatewayProxy: boolean } | null;
  }>;
};

export type DnsFirewallRulesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type DnsFirewallRulesForNetworkQuery = {
  __typename?: 'Query';
  applicationDNSFirewallRulesForNetwork: Array<{
    __typename?: 'ApplicationDNSFirewallRule';
    UUID: string;
    isEnabled: boolean;
    name: string;
    action: FirewallRuleAction;
    priority: number;
    domain?: string | null;
    category?: {
      __typename?: 'ApplicationDNSFirewallRuleCategory';
      id: number;
      name: string;
      description: string;
      group: { __typename?: 'ApplicationDNSFirewallRuleGroup'; id: number; name: string };
    } | null;
    group?: { __typename?: 'ApplicationDNSFirewallRuleGroup'; id: number; name: string } | null;
    application?: {
      __typename?: 'ApplicationDNSFirewallRuleApplication';
      id: number;
      name: string;
    } | null;
    dhcpRule: {
      __typename?: 'DHCPRule';
      UUID: string;
      vlan: { __typename?: 'VLAN'; UUID: string; name: string };
    };
  }>;
};

export type DnsFirewallRuleQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DnsFirewallRuleQuery = {
  __typename?: 'Query';
  applicationDNSFirewallRule: {
    __typename?: 'ApplicationDNSFirewallRule';
    UUID: string;
    isEnabled: boolean;
    name: string;
    action: FirewallRuleAction;
    priority: number;
    domain?: string | null;
    category?: {
      __typename?: 'ApplicationDNSFirewallRuleCategory';
      id: number;
      name: string;
      description: string;
      group: { __typename?: 'ApplicationDNSFirewallRuleGroup'; id: number; name: string };
    } | null;
    group?: { __typename?: 'ApplicationDNSFirewallRuleGroup'; id: number; name: string } | null;
    application?: {
      __typename?: 'ApplicationDNSFirewallRuleApplication';
      id: number;
      name: string;
    } | null;
    dhcpRule: {
      __typename?: 'DHCPRule';
      UUID: string;
      vlan: { __typename?: 'VLAN'; UUID: string; name: string };
    };
  };
};

export type CreateDnsFirewallRuleMutationVariables = Exact<{
  dhcpRuleUUID: Scalars['UUID']['input'];
  input: CreateApplicationDnsFirewallRuleInput;
}>;

export type CreateDnsFirewallRuleMutation = {
  __typename?: 'Mutation';
  createApplicationDNSFirewallRule: { __typename?: 'ApplicationDNSFirewallRule'; UUID: string };
};

export type UpdateDnsFirewallRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateApplicationDnsFirewallRuleInput;
}>;

export type UpdateDnsFirewallRuleMutation = {
  __typename?: 'Mutation';
  updateApplicationDNSFirewallRule: { __typename?: 'ApplicationDNSFirewallRule'; UUID: string };
};

export type DeleteDnsFirewallRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteDnsFirewallRuleMutation = {
  __typename?: 'Mutation';
  deleteApplicationDNSFirewallRule: { __typename?: 'ApplicationDNSFirewallRule'; UUID: string };
};

export type UpdateDnsFirewallRulesPrioritiesMutationVariables = Exact<{
  input:
    | Array<UpdateApplicationDnsFirewallRulesPrioritiesInput>
    | UpdateApplicationDnsFirewallRulesPrioritiesInput;
}>;

export type UpdateDnsFirewallRulesPrioritiesMutation = {
  __typename?: 'Mutation';
  updateApplicationDNSFirewallRulesPriorities: Array<{
    __typename?: 'ApplicationDNSFirewallRule';
    UUID: string;
    priority: number;
  }>;
};

export type DnsFirewallRuleCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type DnsFirewallRuleCategoriesQuery = {
  __typename?: 'Query';
  applicationDNSFirewallRuleCategories: Array<{
    __typename?: 'ApplicationDNSFirewallRuleCategory';
    id: number;
    name: string;
    description: string;
    group: { __typename?: 'ApplicationDNSFirewallRuleGroup'; id: number; name: string };
  }>;
};

export type DnsFirewallRuleGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type DnsFirewallRuleGroupsQuery = {
  __typename?: 'Query';
  applicationDNSFirewallRuleGroups: Array<{
    __typename?: 'ApplicationDNSFirewallRuleGroup';
    id: number;
    name: string;
  }>;
};

export type DnsFirewallRuleApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type DnsFirewallRuleApplicationsQuery = {
  __typename?: 'Query';
  applicationDNSFirewallRuleApplications: Array<{
    __typename?: 'ApplicationDNSFirewallRuleApplication';
    id: number;
    name: string;
  }>;
};

export type DnsDomainApplicationQueryVariables = Exact<{
  hostname: Scalars['String']['input'];
}>;

export type DnsDomainApplicationQuery = {
  __typename?: 'Query';
  applicationDNSFirewallRuleApplicationForHostname: {
    __typename?: 'ApplicationDNSFirewallRuleApplication';
    id: number;
    isVisible: boolean;
    name: string;
  };
};

export type DnsDomainCategoryQueryVariables = Exact<{
  hostname: Scalars['String']['input'];
}>;

export type DnsDomainCategoryQuery = {
  __typename?: 'Query';
  applicationDNSFirewallRuleCategoryForHostname: {
    __typename?: 'ApplicationDNSFirewallRuleCategory';
    id: number;
    name: string;
    description: string;
    group: { __typename?: 'ApplicationDNSFirewallRuleGroup'; id: number; name: string };
  };
};

export type CreateRackElevationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  label: Scalars['String']['input'];
  rackMountUnitCount: Scalars['Int']['input'];
}>;

export type CreateRackElevationMutation = {
  __typename?: 'Mutation';
  createRackElevation: {
    __typename?: 'RackElevation';
    UUID: string;
    networkUUID: string;
    rackMountUnitCount: number;
    label: string;
  };
};

export type AttachDeviceToRackElevationMutationVariables = Exact<{
  rackElevationUUID: Scalars['UUID']['input'];
  rackMountUnitIndexes: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  virtualDeviceUUID?: InputMaybe<Scalars['UUID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<RackElevationDeviceType>;
}>;

export type AttachDeviceToRackElevationMutation = {
  __typename?: 'Mutation';
  attachDeviceToRackElevation: {
    __typename?: 'RackElevation';
    UUID: string;
    label: string;
    rackMountUnitCount: number;
    devices: Array<{
      __typename?: 'RackElevationDevice';
      UUID: string;
      label?: string | null;
      rackMountUnitIndexes: Array<number>;
      type: RackElevationDeviceType;
      virtualDeviceUUID?: string | null;
    }>;
  };
};

export type DetachRackElevationDeviceMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type DetachRackElevationDeviceMutation = {
  __typename?: 'Mutation';
  detachDeviceFromRackElevation: boolean;
};

export type RackElevationQueryVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type RackElevationQuery = {
  __typename?: 'Query';
  rackElevation: {
    __typename?: 'RackElevation';
    UUID: string;
    label: string;
    rackMountUnitCount: number;
    networkUUID: string;
    devices: Array<{
      __typename?: 'RackElevationDevice';
      UUID: string;
      label?: string | null;
      rackMountUnitIndexes: Array<number>;
      type: RackElevationDeviceType;
      virtualDeviceUUID?: string | null;
      virtualDevice?:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | null;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | null;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | null;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | null;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            phyInterfaces: Array<{
              __typename?: 'PhyInterface';
              portNumber: number;
              isEthernet: boolean;
              isSFP: boolean;
            }>;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                }
              | null;
          }
        | null;
    }>;
    notes?: Array<{
      __typename?: 'RackElevationNote';
      rackMountUnitIndexStart: number;
      rackMountUnitIndexEnd: number;
      note: string;
    }> | null;
  };
};

export type RackElevationsQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type RackElevationsQuery = {
  __typename?: 'Query';
  rackElevations: Array<{
    __typename?: 'RackElevation';
    UUID: string;
    label: string;
    rackMountUnitCount: number;
    networkUUID: string;
    devices: Array<{
      __typename?: 'RackElevationDevice';
      UUID: string;
      label?: string | null;
      rackMountUnitIndexes: Array<number>;
      type: RackElevationDeviceType;
      virtualDeviceUUID?: string | null;
      virtualDevice?:
        | {
            __typename: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            networkUUID: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | null;
          }
        | {
            __typename: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            networkUUID: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | null;
          }
        | {
            __typename: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            networkUUID: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | null;
          }
        | {
            __typename: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            networkUUID: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | null;
          }
        | {
            __typename: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceType: VirtualDeviceType;
            deviceModel: DeviceModel;
            description?: string | null;
            networkUUID: string;
            phyInterfaces: Array<{
              __typename?: 'PhyInterface';
              portNumber: number;
              isEthernet: boolean;
              isSFP: boolean;
            }>;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  isActive: boolean;
                }
              | null;
          }
        | null;
    }>;
    notes?: Array<{
      __typename?: 'RackElevationNote';
      rackMountUnitIndexStart: number;
      rackMountUnitIndexEnd: number;
      note: string;
    }> | null;
  }>;
};

export type UpdateRackNotesMutationVariables = Exact<{
  rackElevationUUID: Scalars['UUID']['input'];
  notes?: InputMaybe<Array<RackElevationNoteInput> | RackElevationNoteInput>;
}>;

export type UpdateRackNotesMutation = {
  __typename?: 'Mutation';
  updateRackElevation: {
    __typename?: 'RackElevation';
    UUID: string;
    label: string;
    rackMountUnitCount: number;
    devices: Array<{
      __typename?: 'RackElevationDevice';
      UUID: string;
      label?: string | null;
      rackMountUnitIndexes: Array<number>;
      type: RackElevationDeviceType;
      virtualDeviceUUID?: string | null;
    }>;
  };
};

export type VirtualDevicesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter?: InputMaybe<DevicesForNetworkFilter>;
}>;

export type VirtualDevicesForNetworkQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename?: 'AccessPointVirtualDevice';
        UUID: string;
        networkUUID: string;
        description?: string | null;
        label: string;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | null;
      }
    | {
        __typename?: 'ControllerVirtualDevice';
        UUID: string;
        networkUUID: string;
        description?: string | null;
        label: string;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | null;
      }
    | {
        __typename?: 'ObserverVirtualDevice';
        UUID: string;
        networkUUID: string;
        description?: string | null;
        label: string;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        networkUUID: string;
        description?: string | null;
        label: string;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | null;
      }
    | {
        __typename?: 'SwitchVirtualDevice';
        UUID: string;
        networkUUID: string;
        description?: string | null;
        label: string;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              deviceModel: DeviceModel;
              isActive: boolean;
            }
          | null;
      }
  >;
};

export type UpdateRackElevationDeviceMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
  input: UpdateRackElevationDeviceInput;
}>;

export type UpdateRackElevationDeviceMutation = {
  __typename?: 'Mutation';
  updateRackElevationDevice: {
    __typename?: 'RackElevation';
    UUID: string;
    label: string;
    rackMountUnitCount: number;
    devices: Array<{
      __typename?: 'RackElevationDevice';
      UUID: string;
      label?: string | null;
      rackMountUnitIndexes: Array<number>;
      type: RackElevationDeviceType;
      virtualDeviceUUID?: string | null;
    }>;
  };
};

export type UpdateRackElevationMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
  input: UpdateRackElevationInput;
}>;

export type UpdateRackElevationMutation = {
  __typename?: 'Mutation';
  updateRackElevation: {
    __typename?: 'RackElevation';
    UUID: string;
    label: string;
    rackMountUnitCount: number;
  };
};

export type DeleteRackElevationMutationMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type DeleteRackElevationMutationMutation = {
  __typename?: 'Mutation';
  deleteRackElevation: boolean;
};

export type DeviceConfigsQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type DeviceConfigsQueryQuery = {
  __typename?: 'Query';
  deviceConfigs?: Array<{
    __typename?: 'DeviceConfig';
    version: number;
    acknowledgedAt?: string | null;
    createdAt: string;
  }> | null;
};

export type DeviceConfigByVersionQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  version: Scalars['Int']['input'];
}>;

export type DeviceConfigByVersionQuery = {
  __typename?: 'Query';
  deviceConfigByVersion: {
    __typename?: 'DeviceConfig';
    version: number;
    acknowledgedAt?: string | null;
    configJSON: Record<string, unknown>;
  };
};

export type RenderDeviceConfigMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type RenderDeviceConfigMutation = {
  __typename?: 'Mutation';
  renderDeviceConfig: {
    __typename?: 'DeviceConfig';
    version: number;
    acknowledgedAt?: string | null;
    configJSON: Record<string, unknown>;
  };
};

export type CreateVirtualDeviceMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateVirtualDeviceInput;
}>;

export type CreateVirtualDeviceMutation = {
  __typename?: 'Mutation';
  createVirtualDevice:
    | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
    | { __typename?: 'ControllerVirtualDevice'; UUID: string }
    | { __typename?: 'ObserverVirtualDevice'; UUID: string }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string };
};

export type UpdateVirtualDeviceMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateVirtualDeviceInput;
}>;

export type UpdateVirtualDeviceMutation = {
  __typename?: 'Mutation';
  updateVirtualDevice:
    | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
    | { __typename?: 'ControllerVirtualDevice'; UUID: string }
    | { __typename?: 'ObserverVirtualDevice'; UUID: string }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string };
};

export type DeleteVirtualDeviceMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteVirtualDeviceMutation = {
  __typename?: 'Mutation';
  deleteVirtualDevice:
    | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
    | { __typename?: 'ControllerVirtualDevice'; UUID: string }
    | { __typename?: 'ObserverVirtualDevice'; UUID: string }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string };
};

export type CreateVirtualDevicesMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateVirtualDevicesInput;
}>;

export type CreateVirtualDevicesMutation = {
  __typename?: 'Mutation';
  createVirtualDevices: Array<
    | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
    | { __typename?: 'ControllerVirtualDevice'; UUID: string }
    | { __typename?: 'ObserverVirtualDevice'; UUID: string }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string }
  >;
};

export type UpdateVirtualDevicesIndependentlyMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  inputs: Array<UpdateVirtualDeviceIndependentlyInput> | UpdateVirtualDeviceIndependentlyInput;
}>;

export type UpdateVirtualDevicesIndependentlyMutation = {
  __typename?: 'Mutation';
  updateVirtualDevicesIndependently: Array<
    | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
    | { __typename?: 'ControllerVirtualDevice'; UUID: string }
    | { __typename?: 'ObserverVirtualDevice'; UUID: string }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string }
  >;
};

export type AssignHardwareDeviceToVirtualDeviceMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type AssignHardwareDeviceToVirtualDeviceMutation = {
  __typename?: 'Mutation';
  assignHardwareDeviceToVirtualDevice:
    | {
        __typename?: 'AccessPointHardwareDevice';
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      };
};

export type CreateDevHardwareDeviceMutationVariables = Exact<{
  input: CreateDevHardwareDeviceInput;
}>;

export type CreateDevHardwareDeviceMutation = {
  __typename?: 'Mutation';
  createDevHardwareDevice:
    | { __typename?: 'AccessPointHardwareDevice'; serialNumber: string }
    | { __typename?: 'ControllerHardwareDevice'; serialNumber: string }
    | { __typename?: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
    | { __typename?: 'SwitchHardwareDevice'; serialNumber: string };
};

export type AssignHardwareDeviceToNetworkMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  networkUUID: Scalars['UUID']['input'];
}>;

export type AssignHardwareDeviceToNetworkMutation = {
  __typename?: 'Mutation';
  assignHardwareDeviceToNetwork:
    | {
        __typename?: 'AccessPointHardwareDevice';
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      };
};

export type UnassignHardwareDeviceFromVirtualDeviceMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type UnassignHardwareDeviceFromVirtualDeviceMutation = {
  __typename?: 'Mutation';
  unassignHardwareDeviceFromVirtualDevice:
    | { __typename?: 'AccessPointHardwareDevice'; serialNumber: string }
    | { __typename?: 'ControllerHardwareDevice'; serialNumber: string }
    | { __typename?: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
    | { __typename?: 'SwitchHardwareDevice'; serialNumber: string };
};

export type AssignHardwareToVirtualDevicesMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  inputs:
    | Array<AssignHardwareDeviceToVirtualDeviceInput>
    | AssignHardwareDeviceToVirtualDeviceInput;
}>;

export type AssignHardwareToVirtualDevicesMutation = {
  __typename?: 'Mutation';
  assignHardwareDevicesToVirtualDevices: Array<
    | {
        __typename?: 'AccessPointHardwareDevice';
        serialNumber: string;
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        serialNumber: string;
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        serialNumber: string;
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
          | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
          | null;
      }
  >;
};

export type HardwareDeviceQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type HardwareDeviceQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename?: 'AccessPointHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
            }
          | null;
      };
};

export type UpdateHardwareDeviceMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  input: UpdateHardwareDeviceInput;
}>;

export type UpdateHardwareDeviceMutation = {
  __typename?: 'Mutation';
  updateHardwareDevice:
    | { __typename?: 'AccessPointHardwareDevice'; serialNumber: string }
    | { __typename?: 'ControllerHardwareDevice'; serialNumber: string }
    | { __typename?: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
    | { __typename?: 'SwitchHardwareDevice'; serialNumber: string };
};

export type BlinkLeDsMutationMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type BlinkLeDsMutationMutation = { __typename?: 'Mutation'; rpcBlinkLEDs: boolean };

export type RefreshConfigMutationMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type RefreshConfigMutationMutation = { __typename?: 'Mutation'; rpcRefreshConfig: boolean };

export type RpcPduOutletCycleMutationMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  input: RpcpduOutletCycleInput;
}>;

export type RpcPduOutletCycleMutationMutation = {
  __typename?: 'Mutation';
  rpcPDUOutletCycle: boolean;
};

export type LatestDeviceMemoryUsageQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type LatestDeviceMemoryUsageQuery = {
  __typename?: 'Query';
  latestDeviceMemoryUsage: {
    __typename?: 'LatestDeviceMemoryUsage';
    bytesUsed: number;
    bytesAvailable: number;
  };
};

export type DeviceUptimeQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type DeviceUptimeQuery = { __typename?: 'Query'; deviceUptime: string };

export type LatestDeviceCpuLoadQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type LatestDeviceCpuLoadQuery = {
  __typename?: 'Query';
  latestDeviceCPULoad: { __typename?: 'LatestDeviceCPULoad'; usedPercentage: number };
};

export type SwitchPoEInfoQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type SwitchPoEInfoQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | { __typename: 'AccessPointHardwareDevice' }
    | { __typename: 'ControllerHardwareDevice' }
    | { __typename: 'PowerDistributionUnitHardwareDevice' }
    | {
        __typename: 'SwitchHardwareDevice';
        poeInfo?: { __typename?: 'PoEInfo'; max?: number | null; used?: number | null } | null;
      };
};

export type SwitchVirtualDevicePoEInfoQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type SwitchVirtualDevicePoEInfoQuery = {
  __typename?: 'Query';
  virtualDevice:
    | { __typename: 'AccessPointVirtualDevice' }
    | { __typename: 'ControllerVirtualDevice' }
    | { __typename: 'ObserverVirtualDevice' }
    | { __typename: 'PowerDistributionUnitVirtualDevice' }
    | {
        __typename: 'SwitchVirtualDevice';
        poeInfo?: { __typename?: 'PoEInfo'; max?: number | null; used?: number | null } | null;
      };
};

export type SystemInfoStatQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type SystemInfoStatQueryQuery = {
  __typename?: 'Query';
  systemInfoStatForVirtualDevice: Array<{
    __typename?: 'SystemInfoStat';
    uptime: string;
    memoryBytesAvailable: number;
    memoryBytesTotal: number;
    cpuUsedPercentage: number;
  }>;
};

export type UpdatePhyInterfaceMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdatePhyInterfaceInput;
}>;

export type UpdatePhyInterfaceMutation = {
  __typename?: 'Mutation';
  updatePhyInterface: { __typename?: 'PhyInterface'; UUID: string };
};

export type UpdatePhyInterfacesMutationVariables = Exact<{
  phyInterfaceUUIDs: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
  input: UpdatePhyInterfaceInput;
}>;

export type UpdatePhyInterfacesMutation = {
  __typename?: 'Mutation';
  updatePhyInterfaces: Array<{ __typename?: 'PhyInterface'; UUID: string }>;
};

export type FirewallRuleQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type FirewallRuleQuery = {
  __typename?: 'Query';
  firewallRule: {
    __typename?: 'FirewallRule';
    UUID: string;
    name: string;
    description?: string | null;
    isMeterInternal: boolean;
    isEnabled: boolean;
    isBidirectional: boolean;
    action: FirewallRuleAction;
    srcPrefix?: string | null;
    dstPrefix?: string | null;
    protocols: Array<IpProtocol>;
    tags?: Array<string> | null;
    srcVLAN?: {
      __typename: 'VLAN';
      UUID: string;
      name: string;
      ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
      ipV4ClientGateway?: string | null;
      ipV4ClientPrefixLength?: number | null;
    } | null;
    dstVLAN?: {
      __typename: 'VLAN';
      UUID: string;
      name: string;
      ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
      ipV4ClientGateway?: string | null;
      ipV4ClientPrefixLength?: number | null;
    } | null;
    srcPortRange: { __typename?: 'PortRange'; lower: number; upper: number };
    dstPortRange: { __typename?: 'PortRange'; lower: number; upper: number };
    vlanBindings?: Array<{
      __typename?: 'FirewallVLANBinding';
      metric: number;
      vlan: { __typename: 'VLAN'; UUID: string; name: string };
    }> | null;
    phyInterfaceBindings?: Array<{
      __typename?: 'FirewallPhyInterfaceBinding';
      metric: number;
      phyInterface: {
        __typename: 'PhyInterface';
        UUID: string;
        label?: string | null;
        portNumber: number;
        virtualDevice:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            };
      };
    }> | null;
  };
};

export type UpdateInterVlanCommunicationPermittedPairsMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  vlanPairs: Array<VlanPairInput> | VlanPairInput;
}>;

export type UpdateInterVlanCommunicationPermittedPairsMutation = {
  __typename?: 'Mutation';
  updateInterVLANCommunicationPermittedPairs: Array<{
    __typename?: 'InterVLANCommunicationPermittedPair';
    UUID: string;
  }>;
};

export type CopyInterVlanCommunicationFromConfig1ToConfig2MutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyInterVlanCommunicationFromConfig1ToConfig2Mutation = {
  __typename?: 'Mutation';
  copyInterVLANCommunicationFromConfig1ToConfig2: Array<{
    __typename?: 'InterVLANCommunicationPermittedPair';
    UUID: string;
  }>;
};

export type VlaNsForMulticastQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type VlaNsForMulticastQuery = {
  __typename?: 'Query';
  vlans: Array<{
    __typename?: 'VLAN';
    UUID: string;
    name: string;
    vlanID: number;
    isEnabled: boolean;
    isDefault: boolean;
    isInternal?: boolean | null;
    isMulticastReflectionEnabled: boolean;
    ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
    ipV4ClientGateway?: string | null;
    ipV4ClientPrefixLength?: number | null;
  }>;
};

export type UpdateVlaNsForMulticastMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  inputs: Array<UpdateVlaNsIndependentlyInput> | UpdateVlaNsIndependentlyInput;
}>;

export type UpdateVlaNsForMulticastMutation = {
  __typename?: 'Mutation';
  updateVLANsIndependently: Array<{ __typename?: 'VLAN'; UUID: string }>;
};

export type DhcpRulesWithStaticMappingsForNetworkQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type DhcpRulesWithStaticMappingsForNetworkQueryQuery = {
  __typename?: 'Query';
  dhcpRulesForNetwork: Array<{
    __typename?: 'DHCPRule';
    UUID: string;
    vlan: { __typename?: 'VLAN'; name: string };
    staticMappings: Array<{
      __typename?: 'DHCPStaticMapping';
      UUID: string;
      name?: string | null;
      macAddress: string;
      ipAddress?: string | null;
      hostname?: string | null;
      createdAt: string;
    }>;
  }>;
};

export type OneToOneNatRulesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type OneToOneNatRulesForNetworkQuery = {
  __typename?: 'Query';
  oneToOneNATRulesForNetwork: Array<{
    __typename?: 'OneToOneNATRule';
    UUID: string;
    name: string;
    description?: string | null;
    isEnabled: boolean;
    networkUUID: string;
    internalIPAddr: string;
    externalIPAddr: string;
    externalPhyInterfaceUUID: string;
    externalPhyInterface: {
      __typename: 'PhyInterface';
      UUID: string;
      portNumber: number;
      label?: string | null;
      hardwareLabel?: string | null;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    };
  }>;
};

export type OneToOneNatRuleQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type OneToOneNatRuleQuery = {
  __typename?: 'Query';
  oneToOneNATRule: {
    __typename?: 'OneToOneNATRule';
    UUID: string;
    name: string;
    description?: string | null;
    isEnabled: boolean;
    networkUUID: string;
    internalIPAddr: string;
    externalIPAddr: string;
    externalPhyInterfaceUUID: string;
    externalPhyInterface: {
      __typename: 'PhyInterface';
      UUID: string;
      portNumber: number;
      label?: string | null;
      hardwareLabel?: string | null;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    };
  };
};

export type CreateOneToOneNatRuleMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateOneToOneNatRuleInput;
}>;

export type CreateOneToOneNatRuleMutation = {
  __typename?: 'Mutation';
  createOneToOneNATRule: { __typename?: 'OneToOneNATRule'; UUID: string };
};

export type UpdateOneToOneNatRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateOneToOneNatRuleInput;
}>;

export type UpdateOneToOneNatRuleMutation = {
  __typename?: 'Mutation';
  updateOneToOneNATRule: { __typename?: 'OneToOneNATRule'; UUID: string };
};

export type DeleteOneToOneNatRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteOneToOneNatRuleMutation = {
  __typename?: 'Mutation';
  deleteOneToOneNATRule: { __typename?: 'OneToOneNATRule'; UUID: string };
};

export type PortForwardingRuleQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type PortForwardingRuleQuery = {
  __typename?: 'Query';
  portForwardingRule: {
    __typename?: 'PortForwardingRule';
    UUID: string;
    name: string;
    description?: string | null;
    isEnabled: boolean;
    protocol: PortIpProtocol;
    localIPAddress: string;
    externalPhyInterfaceUUID?: string | null;
    externalIPAddress?: string | null;
    externalPort: number;
    localPort: number;
  };
};

export type PortForwardingRulesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type PortForwardingRulesForNetworkQuery = {
  __typename?: 'Query';
  portForwardingRulesForNetwork: Array<{
    __typename?: 'PortForwardingRule';
    UUID: string;
    name: string;
    description?: string | null;
    isEnabled: boolean;
    protocol: PortIpProtocol;
    localIPAddress: string;
    externalPhyInterfaceUUID?: string | null;
    externalIPAddress?: string | null;
    externalPort: number;
    localPort: number;
    externalPhyInterface?: {
      __typename: 'PhyInterface';
      UUID: string;
      label?: string | null;
      hardwareLabel?: string | null;
      portNumber: number;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    } | null;
  }>;
};

export type CreatePortForwardingRuleMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreatePortForwardingRuleInput;
}>;

export type CreatePortForwardingRuleMutation = {
  __typename?: 'Mutation';
  createPortForwardingRule: { __typename?: 'PortForwardingRule'; UUID: string };
};

export type UpdatePortForwardingRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdatePortForwardingRuleInput;
}>;

export type UpdatePortForwardingRuleMutation = {
  __typename?: 'Mutation';
  updatePortForwardingRule: { __typename?: 'PortForwardingRule'; UUID: string };
};

export type DeletePortForwardingRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeletePortForwardingRuleMutation = {
  __typename?: 'Mutation';
  deletePortForwardingRule: { __typename?: 'PortForwardingRule'; UUID: string };
};

export type VlaNsForFirewallQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type VlaNsForFirewallQuery = {
  __typename?: 'Query';
  vlans: Array<{
    __typename: 'VLAN';
    UUID: string;
    name: string;
    description?: string | null;
    isEnabled: boolean;
    isInternal?: boolean | null;
    isDefault: boolean;
    vlanID: number;
    ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
    ipV4ClientGateway?: string | null;
    ipV4ClientPrefixLength?: number | null;
    permittedInterVLANCommunicationVLANs: Array<{
      __typename?: 'VLAN';
      UUID: string;
      name: string;
    }>;
    dhcpRule?: {
      __typename?: 'DHCPRule';
      applicationDNSFirewallRules: Array<{
        __typename?: 'ApplicationDNSFirewallRule';
        UUID: string;
      }>;
    } | null;
  }>;
};

export type PhyInterfaceLabelFieldsFragment = {
  __typename: 'PhyInterface';
  UUID: string;
  portNumber: number;
  label?: string | null;
  hardwareLabel?: string | null;
  virtualDevice:
    | {
        __typename?: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        deviceModel: DeviceModel;
      }
    | {
        __typename?: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        deviceModel: DeviceModel;
      }
    | {
        __typename?: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        deviceModel: DeviceModel;
      }
    | {
        __typename?: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        deviceModel: DeviceModel;
      }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string; deviceModel: DeviceModel };
  internetServicePlan?: {
    __typename?: 'InternetServicePlan';
    provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
  } | null;
};

export type FirewallRulesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type FirewallRulesForNetworkQuery = {
  __typename?: 'Query';
  firewallRulesForNetwork: Array<{
    __typename?: 'FirewallRule';
    UUID: string;
    name: string;
    description?: string | null;
    isMeterInternal: boolean;
    isEnabled: boolean;
    isBidirectional: boolean;
    action: FirewallRuleAction;
    srcPrefix?: string | null;
    dstPrefix?: string | null;
    protocols: Array<IpProtocol>;
    tags?: Array<string> | null;
    srcVLAN?: {
      __typename: 'VLAN';
      UUID: string;
      name: string;
      ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
      ipV4ClientGateway?: string | null;
      ipV4ClientPrefixLength?: number | null;
    } | null;
    dstVLAN?: {
      __typename: 'VLAN';
      UUID: string;
      name: string;
      ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
      ipV4ClientGateway?: string | null;
      ipV4ClientPrefixLength?: number | null;
    } | null;
    srcPortRange: { __typename?: 'PortRange'; lower: number; upper: number };
    dstPortRange: { __typename?: 'PortRange'; lower: number; upper: number };
    vlanBindings?: Array<{
      __typename?: 'FirewallVLANBinding';
      metric: number;
      vlan: { __typename: 'VLAN'; UUID: string; name: string };
    }> | null;
    phyInterfaceBindings?: Array<{
      __typename?: 'FirewallPhyInterfaceBinding';
      metric: number;
      phyInterface: {
        __typename: 'PhyInterface';
        UUID: string;
        portNumber: number;
        label?: string | null;
        hardwareLabel?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            };
        internetServicePlan?: {
          __typename?: 'InternetServicePlan';
          provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
        } | null;
      };
    }> | null;
  }>;
};

export type UpdateFirewallRuleBindingsForVlanMutationVariables = Exact<{
  vlanUUID: Scalars['UUID']['input'];
  orderedRuleUUIDs: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type UpdateFirewallRuleBindingsForVlanMutation = {
  __typename?: 'Mutation';
  bindFirewallRulesToVLAN: Array<{ __typename?: 'FirewallVLANBinding'; metric: number }>;
};

export type UpdateFirewallRuleBindingsForPhyInterfaceMutationVariables = Exact<{
  phyInterfaceUUID: Scalars['UUID']['input'];
  orderedRuleUUIDs: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type UpdateFirewallRuleBindingsForPhyInterfaceMutation = {
  __typename?: 'Mutation';
  bindFirewallRulesToPhyInterface: Array<{
    __typename?: 'FirewallPhyInterfaceBinding';
    metric: number;
  }>;
};

export type UplinkPhyInterfacesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type UplinkPhyInterfacesForNetworkQuery = {
  __typename?: 'Query';
  uplinkPhyInterfacesForNetwork: Array<{
    __typename: 'PhyInterface';
    UUID: string;
    label?: string | null;
    isEnabled: boolean;
    portNumber: number;
    hardwareLabel?: string | null;
    ipv4ClientAddresses?: Array<string> | null;
    isUplinkActive?: boolean | null;
    hasWANActivity?: boolean | null;
    uplinkExternalAddresses?: Array<string> | null;
    virtualDevice:
      | {
          __typename: 'AccessPointVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | null;
        }
      | {
          __typename: 'ControllerVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
          highAvailability?: {
            __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
            role: HighAvailabilityControllerRole;
            status: HighAvailabilityStatus;
          } | null;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | null;
        }
      | {
          __typename: 'ObserverVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | null;
        }
      | {
          __typename: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | null;
        }
      | {
          __typename: 'SwitchVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                isActive: boolean;
                isConnectedToBackend: boolean;
                serialNumber: string;
              }
            | null;
        };
    internetServicePlan?: {
      __typename?: 'InternetServicePlan';
      provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
    } | null;
  }>;
};

export type CreateFirewallRuleMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateFirewallRuleInput;
}>;

export type CreateFirewallRuleMutation = {
  __typename?: 'Mutation';
  createFirewallRule: { __typename?: 'FirewallRule'; UUID: string };
};

export type UpdateFirewallRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateFirewallRuleInput;
}>;

export type UpdateFirewallRuleMutation = {
  __typename?: 'Mutation';
  updateFirewallRule: { __typename?: 'FirewallRule'; UUID: string };
};

export type DeleteFirewallRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteFirewallRuleMutation = {
  __typename?: 'Mutation';
  deleteFirewallRule: { __typename?: 'FirewallRule'; UUID: string };
};

export type FloorPlansForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type FloorPlansForNetworkQuery = {
  __typename?: 'Query';
  floorPlansForNetwork: Array<{
    __typename?: 'FloorPlan';
    UUID: string;
    label: string;
    description?: string | null;
    imageS3Key: string;
    imageDownloadURL: string;
  }>;
};

export type FloorPlanQueryVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type FloorPlanQuery = {
  __typename?: 'Query';
  floorPlan: {
    __typename?: 'FloorPlan';
    UUID: string;
    label: string;
    description?: string | null;
    imageS3Key: string;
  };
};

export type CreateFloorPlanMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateFloorPlanInput;
}>;

export type CreateFloorPlanMutation = {
  __typename?: 'Mutation';
  createFloorPlan: { __typename?: 'FloorPlan'; UUID: string };
};

export type UpdateFloorPlanMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
  input: UpdateFloorPlanInput;
}>;

export type UpdateFloorPlanMutation = {
  __typename?: 'Mutation';
  updateFloorPlan: { __typename?: 'FloorPlan'; UUID: string };
};

export type DeleteFloorPlanMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type DeleteFloorPlanMutation = {
  __typename?: 'Mutation';
  deleteFloorPlan: { __typename?: 'FloorPlan'; UUID: string };
};

export type ApbssiDsQueryQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type ApbssiDsQueryQuery = {
  __typename?: 'Query';
  virtualDevice:
    | {
        __typename?: 'AccessPointVirtualDevice';
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              BSSIDs: Array<{
                __typename?: 'BSSID';
                BSSID: string;
                isActive: boolean;
                radioBand: RadioBand;
                SSID: { __typename?: 'SSID'; ssid: string };
              }>;
            }
          | { __typename: 'ControllerHardwareDevice' }
          | { __typename: 'PowerDistributionUnitHardwareDevice' }
          | { __typename: 'SwitchHardwareDevice' }
          | null;
      }
    | {
        __typename?: 'ControllerVirtualDevice';
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              BSSIDs: Array<{
                __typename?: 'BSSID';
                BSSID: string;
                isActive: boolean;
                radioBand: RadioBand;
                SSID: { __typename?: 'SSID'; ssid: string };
              }>;
            }
          | { __typename: 'ControllerHardwareDevice' }
          | { __typename: 'PowerDistributionUnitHardwareDevice' }
          | { __typename: 'SwitchHardwareDevice' }
          | null;
      }
    | {
        __typename?: 'ObserverVirtualDevice';
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              BSSIDs: Array<{
                __typename?: 'BSSID';
                BSSID: string;
                isActive: boolean;
                radioBand: RadioBand;
                SSID: { __typename?: 'SSID'; ssid: string };
              }>;
            }
          | { __typename: 'ControllerHardwareDevice' }
          | { __typename: 'PowerDistributionUnitHardwareDevice' }
          | { __typename: 'SwitchHardwareDevice' }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitVirtualDevice';
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              BSSIDs: Array<{
                __typename?: 'BSSID';
                BSSID: string;
                isActive: boolean;
                radioBand: RadioBand;
                SSID: { __typename?: 'SSID'; ssid: string };
              }>;
            }
          | { __typename: 'ControllerHardwareDevice' }
          | { __typename: 'PowerDistributionUnitHardwareDevice' }
          | { __typename: 'SwitchHardwareDevice' }
          | null;
      }
    | {
        __typename?: 'SwitchVirtualDevice';
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              BSSIDs: Array<{
                __typename?: 'BSSID';
                BSSID: string;
                isActive: boolean;
                radioBand: RadioBand;
                SSID: { __typename?: 'SSID'; ssid: string };
              }>;
            }
          | { __typename: 'ControllerHardwareDevice' }
          | { __typename: 'PowerDistributionUnitHardwareDevice' }
          | { __typename: 'SwitchHardwareDevice' }
          | null;
      };
};

export type ApBootHistoryQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ApBootHistoryQueryQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename?: 'AccessPointHardwareDevice';
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      };
};

export type RebootAccessPointMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type RebootAccessPointMutation = { __typename?: 'Mutation'; rpcRebootDevice: boolean };

export type ApScanDataQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
  durationSeconds: Scalars['Int']['input'];
}>;

export type ApScanDataQueryQuery = {
  __typename?: 'Query';
  statsForVirtualDevices: Array<{
    __typename?: 'Stat';
    observedAt: string;
    virtualDeviceUUID: string;
    rawData: Record<string, unknown>;
  }>;
};

export type PduInsightsQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PduInsightsQueryQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename: 'AccessPointHardwareDevice';
        serialNumber: string;
        macAddress?: string | null;
        deviceModel: DeviceModel;
        tunnelIPAddress?: string | null;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
        virtualDevice?:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              ipAddress?: string | null;
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerHardwareDevice';
        serialNumber: string;
        macAddress?: string | null;
        deviceModel: DeviceModel;
        tunnelIPAddress?: string | null;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
        virtualDevice?:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              ipAddress?: string | null;
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        macAddress?: string | null;
        deviceModel: DeviceModel;
        tunnelIPAddress?: string | null;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
        virtualDevice?:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              ipAddress?: string | null;
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchHardwareDevice';
        serialNumber: string;
        macAddress?: string | null;
        deviceModel: DeviceModel;
        tunnelIPAddress?: string | null;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
        virtualDevice?:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              ipAddress?: string | null;
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | null;
      };
};

export type RebootPowerDistributionUnitMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type RebootPowerDistributionUnitMutation = {
  __typename?: 'Mutation';
  rpcRebootDevice: boolean;
};

export type FallbackPowerDistributionUnitHardwareQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type FallbackPowerDistributionUnitHardwareQueryQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename?: 'AccessPointHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
      };
};

export type PowerDistributionUnitQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type PowerDistributionUnitQueryQuery = {
  __typename?: 'Query';
  virtualDevice:
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        ipAddress?: string | null;
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      };
};

export type PowerDistributionUnitsQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  includeIsDev?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PowerDistributionUnitsQueryQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        ipAddress?: string | null;
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
  >;
};

export type DeviceHeartbeatForDeviceQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type DeviceHeartbeatForDeviceQueryQuery = {
  __typename?: 'Query';
  deviceHeartbeatForDevice: { __typename?: 'DeviceHeartbeat'; token: string };
};

export type OutletsQueryQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type OutletsQueryQuery = {
  __typename?: 'Query';
  outletInterfacesForVirtualDevice: Array<{
    __typename?: 'OutletInterface';
    UUID: string;
    label?: string | null;
    outletNumber: number;
    isEnabled: boolean;
    powerCycleDelaySec: number;
    description?: string | null;
    currentAmps?: number | null;
    powerWatts?: number | null;
    connectedVirtualDeviceUUID?: string | null;
    connectedVirtualDevice?:
      | {
          __typename?: 'AccessPointVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
        }
      | {
          __typename?: 'ControllerVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
        }
      | {
          __typename?: 'ObserverVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
        }
      | {
          __typename?: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
        }
      | {
          __typename?: 'SwitchVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
        }
      | null;
  }>;
};

export type UpdateOutletInterfaceMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateOutletInterfaceInput;
}>;

export type UpdateOutletInterfaceMutationMutation = {
  __typename?: 'Mutation';
  updateOutletInterface: { __typename?: 'OutletInterface'; UUID: string };
};

export type HostMonitoringQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  hostIPs: Array<Scalars['String']['input']> | Scalars['String']['input'];
  durationSeconds: Scalars['Int']['input'];
  stepSeconds: Scalars['Int']['input'];
}>;

export type HostMonitoringQuery = {
  __typename?: 'Query';
  hostMonitoringForNetwork: Array<{
    __typename?: 'HostMonitoringResponse';
    values: Array<{
      __typename?: 'HostMonitoringValue';
      hostIPAddr: string;
      virtualDeviceUUID: string;
      timestamp: string;
      value: number;
      status: HostMonitoringStatus;
      srcEthPortNum: number;
    }>;
  }>;
};

export type RebootSecurityApplianceMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type RebootSecurityApplianceMutation = { __typename?: 'Mutation'; rpcRebootDevice: boolean };

type ControllerFragment_AccessPointVirtualDevice_Fragment = {
  __typename: 'AccessPointVirtualDevice';
  UUID: string;
  label: string;
  description?: string | null;
  deviceType: VirtualDeviceType;
  deviceModel: DeviceModel;
  isConsoleEnabled: boolean;
  networkUUID: string;
  uptime?: string | null;
  nosVersion?: {
    __typename?: 'ConfiguredNOSVersion';
    id: number;
    version: string;
    buildName: string;
  } | null;
  pendingNosVersion?: {
    __typename?: 'PendingNosVersion';
    scheduledAt: string;
    nosVersion: { __typename?: 'NosVersion'; version: string };
  } | null;
  hardwareDevice?:
    | {
        __typename: 'AccessPointHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'ControllerHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'PowerDistributionUnitHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'SwitchHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | null;
};

type ControllerFragment_ControllerVirtualDevice_Fragment = {
  __typename: 'ControllerVirtualDevice';
  UUID: string;
  label: string;
  description?: string | null;
  deviceType: VirtualDeviceType;
  deviceModel: DeviceModel;
  isConsoleEnabled: boolean;
  networkUUID: string;
  uptime?: string | null;
  highAvailability?: {
    __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
    role: HighAvailabilityControllerRole;
    status: HighAvailabilityStatus;
  } | null;
  nosVersion?: {
    __typename?: 'ConfiguredNOSVersion';
    id: number;
    version: string;
    buildName: string;
  } | null;
  pendingNosVersion?: {
    __typename?: 'PendingNosVersion';
    scheduledAt: string;
    nosVersion: { __typename?: 'NosVersion'; version: string };
  } | null;
  hardwareDevice?:
    | {
        __typename: 'AccessPointHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'ControllerHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'PowerDistributionUnitHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'SwitchHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | null;
};

type ControllerFragment_ObserverVirtualDevice_Fragment = {
  __typename: 'ObserverVirtualDevice';
  UUID: string;
  label: string;
  description?: string | null;
  deviceType: VirtualDeviceType;
  deviceModel: DeviceModel;
  isConsoleEnabled: boolean;
  networkUUID: string;
  uptime?: string | null;
  nosVersion?: {
    __typename?: 'ConfiguredNOSVersion';
    id: number;
    version: string;
    buildName: string;
  } | null;
  pendingNosVersion?: {
    __typename?: 'PendingNosVersion';
    scheduledAt: string;
    nosVersion: { __typename?: 'NosVersion'; version: string };
  } | null;
  hardwareDevice?:
    | {
        __typename: 'AccessPointHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'ControllerHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'PowerDistributionUnitHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'SwitchHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | null;
};

type ControllerFragment_PowerDistributionUnitVirtualDevice_Fragment = {
  __typename: 'PowerDistributionUnitVirtualDevice';
  UUID: string;
  label: string;
  description?: string | null;
  deviceType: VirtualDeviceType;
  deviceModel: DeviceModel;
  isConsoleEnabled: boolean;
  networkUUID: string;
  uptime?: string | null;
  nosVersion?: {
    __typename?: 'ConfiguredNOSVersion';
    id: number;
    version: string;
    buildName: string;
  } | null;
  pendingNosVersion?: {
    __typename?: 'PendingNosVersion';
    scheduledAt: string;
    nosVersion: { __typename?: 'NosVersion'; version: string };
  } | null;
  hardwareDevice?:
    | {
        __typename: 'AccessPointHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'ControllerHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'PowerDistributionUnitHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'SwitchHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | null;
};

type ControllerFragment_SwitchVirtualDevice_Fragment = {
  __typename: 'SwitchVirtualDevice';
  UUID: string;
  label: string;
  description?: string | null;
  deviceType: VirtualDeviceType;
  deviceModel: DeviceModel;
  isConsoleEnabled: boolean;
  networkUUID: string;
  uptime?: string | null;
  nosVersion?: {
    __typename?: 'ConfiguredNOSVersion';
    id: number;
    version: string;
    buildName: string;
  } | null;
  pendingNosVersion?: {
    __typename?: 'PendingNosVersion';
    scheduledAt: string;
    nosVersion: { __typename?: 'NosVersion'; version: string };
  } | null;
  hardwareDevice?:
    | {
        __typename: 'AccessPointHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'ControllerHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'PowerDistributionUnitHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename: 'SwitchHardwareDevice';
        deviceType: DeviceType;
        serialNumber: string;
        macAddress?: string | null;
        tunnelIPAddress?: string | null;
        deviceModel: DeviceModel;
        isActive: boolean;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        uptime?: string | null;
        isDev?: boolean;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | null;
};

export type ControllerFragmentFragment =
  | ControllerFragment_AccessPointVirtualDevice_Fragment
  | ControllerFragment_ControllerVirtualDevice_Fragment
  | ControllerFragment_ObserverVirtualDevice_Fragment
  | ControllerFragment_PowerDistributionUnitVirtualDevice_Fragment
  | ControllerFragment_SwitchVirtualDevice_Fragment;

export type ControllersQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type ControllersQueryQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
  >;
};

export type ControllerQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  includeIsDev?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ControllerQueryQuery = {
  __typename?: 'Query';
  virtualDevice:
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        phyInterfaces: Array<{
          __typename?: 'PhyInterface';
          UUID: string;
          virtualDeviceUUID: string;
          portNumber: number;
          label?: string | null;
          description?: string | null;
          hardwareLabel?: string | null;
          isEnabled: boolean;
          isTrunkPort: boolean;
          isUplink: boolean;
          isEthernet: boolean;
          isSFP: boolean;
          hasWANActivity?: boolean | null;
          forcedPortSpeedMbps?: number | null;
          portSpeedMbps?: number | null;
          maxSpeedMbps?: number | null;
          ipv4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
          ipv4ClientGateway?: string | null;
          ipv4ClientPrefixLength?: number | null;
          ipv4ClientAddresses?: Array<string> | null;
          uplinkPriority?: number | null;
          uplinkExternalAddresses?: Array<string> | null;
          isUplinkActive?: boolean | null;
          uplinkVLANID?: number | null;
          uplinkGatewayMACAddress?: string | null;
          virtualDevice:
            | {
                __typename?: 'AccessPointVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ObserverVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              };
          nativeVLAN?: { __typename?: 'VLAN'; UUID: string; vlanID: number; name: string } | null;
          connectedDevices?: Array<{
            __typename?: 'PhyInterfaceConnectedDevice';
            macAddress: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  isConnectedToBackend: boolean;
                  isActive: boolean;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  virtualDevice?:
                    | {
                        __typename: 'AccessPointVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'ControllerVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'ObserverVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'PowerDistributionUnitVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'SwitchVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | null;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  isConnectedToBackend: boolean;
                  isActive: boolean;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  virtualDevice?:
                    | {
                        __typename: 'AccessPointVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'ControllerVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'ObserverVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'PowerDistributionUnitVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'SwitchVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | null;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  isConnectedToBackend: boolean;
                  isActive: boolean;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  virtualDevice?:
                    | {
                        __typename: 'AccessPointVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'ControllerVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'ObserverVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'PowerDistributionUnitVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'SwitchVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | null;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  isConnectedToBackend: boolean;
                  isActive: boolean;
                  deviceType: DeviceType;
                  deviceModel: DeviceModel;
                  virtualDevice?:
                    | {
                        __typename: 'AccessPointVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'ControllerVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'ObserverVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'PowerDistributionUnitVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | {
                        __typename: 'SwitchVirtualDevice';
                        deviceType: VirtualDeviceType;
                        UUID: string;
                        label: string;
                      }
                    | null;
                }
              | null;
          }> | null;
          internetServicePlan?: {
            __typename?: 'InternetServicePlan';
            UUID: string;
            controllerIP?: string | null;
            provider?: {
              __typename?: 'InternetServicePlanProvider';
              UUID: string;
              name: string;
              logoURL?: string | null;
            } | null;
          } | null;
        }>;
        highAvailability?: {
          __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
          role: HighAvailabilityControllerRole;
          status: HighAvailabilityStatus;
        } | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      };
};

export type HaConfigForNetworkQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type HaConfigForNetworkQueryQuery = {
  __typename?: 'Query';
  network: {
    __typename?: 'Network';
    UUID: string;
    highAvailabilityConfig?: {
      __typename?: 'HighAvailabilityControllersConfig';
      preemption: boolean;
      advertisementIntervalMs: number;
      primaryPhyInterface: {
        __typename?: 'PhyInterface';
        UUID: string;
        portNumber: number;
        label?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            };
      };
      backupPhyInterface: {
        __typename?: 'PhyInterface';
        UUID: string;
        portNumber: number;
        label?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              hardwareDevice?:
                | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
                | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
                | {
                    __typename?: 'PowerDistributionUnitHardwareDevice';
                    isConnectedToBackend: boolean;
                  }
                | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
                | null;
            };
      };
      advertisementVLAN: { __typename?: 'VLAN'; UUID: string; vlanID: number; name: string };
    } | null;
  };
};

export type ControllerVirtualDeviceFragmentFragment = {
  __typename?: 'ControllerVirtualDevice';
  highAvailability?: {
    __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
    role: HighAvailabilityControllerRole;
    status: HighAvailabilityStatus;
  } | null;
  phyInterfaces: Array<{
    __typename?: 'PhyInterface';
    UUID: string;
    isEnabled: boolean;
    isSFP: boolean;
    isTrunkPort: boolean;
    isUplink: boolean;
    label?: string | null;
    portNumber: number;
    portSpeedMbps?: number | null;
    hasWANActivity?: boolean | null;
    connectedDevices?: Array<{
      __typename?: 'PhyInterfaceConnectedDevice';
      hardwareDevice?:
        | {
            __typename?: 'AccessPointHardwareDevice';
            deviceModel: DeviceModel;
            deviceType: DeviceType;
            virtualDevice?:
              | {
                  __typename?: 'AccessPointVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'ControllerVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'ObserverVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'SwitchVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | null;
          }
        | {
            __typename?: 'ControllerHardwareDevice';
            deviceModel: DeviceModel;
            deviceType: DeviceType;
            virtualDevice?:
              | {
                  __typename?: 'AccessPointVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'ControllerVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'ObserverVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'SwitchVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | null;
          }
        | {
            __typename?: 'PowerDistributionUnitHardwareDevice';
            deviceModel: DeviceModel;
            deviceType: DeviceType;
            virtualDevice?:
              | {
                  __typename?: 'AccessPointVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'ControllerVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'ObserverVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'SwitchVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | null;
          }
        | {
            __typename?: 'SwitchHardwareDevice';
            deviceModel: DeviceModel;
            deviceType: DeviceType;
            virtualDevice?:
              | {
                  __typename?: 'AccessPointVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'ControllerVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'ObserverVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | {
                  __typename?: 'SwitchVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceType: VirtualDeviceType;
                }
              | null;
          }
        | null;
    }> | null;
    internetServicePlan?: {
      __typename?: 'InternetServicePlan';
      controllerIP?: string | null;
      provider?: {
        __typename?: 'InternetServicePlanProvider';
        name: string;
        logoURL?: string | null;
      } | null;
    } | null;
  }>;
};

export type ControllerForSecurityApplianceQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  includeIsDev?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ControllerForSecurityApplianceQueryQuery = {
  __typename?: 'Query';
  virtualDevice:
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        highAvailability?: {
          __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
          role: HighAvailabilityControllerRole;
          status: HighAvailabilityStatus;
        } | null;
        phyInterfaces: Array<{
          __typename?: 'PhyInterface';
          UUID: string;
          isEnabled: boolean;
          isSFP: boolean;
          isTrunkPort: boolean;
          isUplink: boolean;
          label?: string | null;
          portNumber: number;
          portSpeedMbps?: number | null;
          hasWANActivity?: boolean | null;
          connectedDevices?: Array<{
            __typename?: 'PhyInterfaceConnectedDevice';
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  deviceModel: DeviceModel;
                  deviceType: DeviceType;
                  virtualDevice?:
                    | {
                        __typename?: 'AccessPointVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'ControllerVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'ObserverVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'PowerDistributionUnitVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'SwitchVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | null;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  deviceModel: DeviceModel;
                  deviceType: DeviceType;
                  virtualDevice?:
                    | {
                        __typename?: 'AccessPointVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'ControllerVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'ObserverVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'PowerDistributionUnitVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'SwitchVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | null;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  deviceModel: DeviceModel;
                  deviceType: DeviceType;
                  virtualDevice?:
                    | {
                        __typename?: 'AccessPointVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'ControllerVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'ObserverVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'PowerDistributionUnitVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'SwitchVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | null;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  deviceModel: DeviceModel;
                  deviceType: DeviceType;
                  virtualDevice?:
                    | {
                        __typename?: 'AccessPointVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'ControllerVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'ObserverVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'PowerDistributionUnitVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename?: 'SwitchVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | null;
                }
              | null;
          }> | null;
          internetServicePlan?: {
            __typename?: 'InternetServicePlan';
            controllerIP?: string | null;
            provider?: {
              __typename?: 'InternetServicePlanProvider';
              name: string;
              logoURL?: string | null;
            } | null;
          } | null;
        }>;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      };
};

export type ControllersForSecurityApplianceQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  includeIsDev?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ControllersForSecurityApplianceQueryQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        phyInterfaces: Array<{
          __typename?: 'PhyInterface';
          UUID: string;
          isEnabled: boolean;
          isSFP: boolean;
          isTrunkPort: boolean;
          isUplink: boolean;
          label?: string | null;
          portNumber: number;
          portSpeedMbps?: number | null;
          hasWANActivity?: boolean | null;
          virtualDeviceUUID: string;
          description?: string | null;
          hardwareLabel?: string | null;
          isEthernet: boolean;
          forcedPortSpeedMbps?: number | null;
          maxSpeedMbps?: number | null;
          ipv4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
          ipv4ClientGateway?: string | null;
          ipv4ClientPrefixLength?: number | null;
          ipv4ClientAddresses?: Array<string> | null;
          uplinkPriority?: number | null;
          uplinkExternalAddresses?: Array<string> | null;
          isUplinkActive?: boolean | null;
          uplinkVLANID?: number | null;
          uplinkGatewayMACAddress?: string | null;
          connectedDevices?: Array<{
            __typename?: 'PhyInterfaceConnectedDevice';
            macAddress: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  deviceModel: DeviceModel;
                  deviceType: DeviceType;
                  serialNumber: string;
                  isConnectedToBackend: boolean;
                  isActive: boolean;
                  virtualDevice?:
                    | {
                        __typename: 'AccessPointVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'ControllerVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'ObserverVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'PowerDistributionUnitVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'SwitchVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | null;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  deviceModel: DeviceModel;
                  deviceType: DeviceType;
                  serialNumber: string;
                  isConnectedToBackend: boolean;
                  isActive: boolean;
                  virtualDevice?:
                    | {
                        __typename: 'AccessPointVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'ControllerVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'ObserverVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'PowerDistributionUnitVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'SwitchVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | null;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  deviceModel: DeviceModel;
                  deviceType: DeviceType;
                  serialNumber: string;
                  isConnectedToBackend: boolean;
                  isActive: boolean;
                  virtualDevice?:
                    | {
                        __typename: 'AccessPointVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'ControllerVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'ObserverVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'PowerDistributionUnitVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'SwitchVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | null;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  deviceModel: DeviceModel;
                  deviceType: DeviceType;
                  serialNumber: string;
                  isConnectedToBackend: boolean;
                  isActive: boolean;
                  virtualDevice?:
                    | {
                        __typename: 'AccessPointVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'ControllerVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'ObserverVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'PowerDistributionUnitVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | {
                        __typename: 'SwitchVirtualDevice';
                        UUID: string;
                        label: string;
                        deviceType: VirtualDeviceType;
                      }
                    | null;
                }
              | null;
          }> | null;
          internetServicePlan?: {
            __typename?: 'InternetServicePlan';
            controllerIP?: string | null;
            UUID: string;
            provider?: {
              __typename?: 'InternetServicePlanProvider';
              name: string;
              logoURL?: string | null;
              UUID: string;
            } | null;
          } | null;
          virtualDevice:
            | {
                __typename?: 'AccessPointVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ObserverVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              };
          nativeVLAN?: { __typename?: 'VLAN'; UUID: string; vlanID: number; name: string } | null;
        }>;
        highAvailability?: {
          __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
          role: HighAvailabilityControllerRole;
          status: HighAvailabilityStatus;
        } | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              uptime?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{
                __typename?: 'BootInfo';
                bootCount: number;
                buildName: string;
                rebootReason: string;
                createdAt: string;
              }> | null;
            }
          | null;
      }
  >;
};

export type DeviceConfigVersionQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type DeviceConfigVersionQuery = {
  __typename?: 'Query';
  latestDeviceConfig: { __typename?: 'DeviceConfig'; version: number };
  latestAcknowledgedDeviceConfig: {
    __typename?: 'DeviceConfig';
    version: number;
    acknowledgedAt?: string | null;
  };
};

export type FallbackSwitchHardwareQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type FallbackSwitchHardwareQueryQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename?: 'AccessPointHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
      };
};

export type PhyInterfacesForVirtualDeviceQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type PhyInterfacesForVirtualDeviceQuery = {
  __typename?: 'Query';
  phyInterfacesForVirtualDevice: Array<{
    __typename?: 'PhyInterface';
    UUID: string;
    portNumber: number;
    label?: string | null;
    isUplink: boolean;
  }>;
};

export type CreateHighAvailabilityConfigMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  primaryPhyInterfaceUUID: Scalars['UUID']['input'];
  backupPhyInterfaceUUID: Scalars['UUID']['input'];
  input: CreateHighAvailabilityPairInput;
}>;

export type CreateHighAvailabilityConfigMutation = {
  __typename?: 'Mutation';
  createHighAvailabilityControllersConfiguration: {
    __typename?: 'HighAvailabilityControllersConfig';
    advertisementVLAN: { __typename?: 'VLAN'; networkUUID: string };
  };
};

export type DeleteHighAvailabilityConfigMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type DeleteHighAvailabilityConfigMutation = {
  __typename?: 'Mutation';
  deleteHighAvailabilityControllersConfiguration: {
    __typename?: 'HighAvailabilityControllersConfig';
    UUID: string;
  };
};

export type DeviceLastDayCellularUsageQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type DeviceLastDayCellularUsageQuery = {
  __typename?: 'Query';
  deviceLastDayCellularUsage?: {
    __typename?: 'CellularUsage';
    uploadBytes?: number | null;
    downloadBytes?: number | null;
  } | null;
};

export type DeviceCellularStatusQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type DeviceCellularStatusQuery = {
  __typename?: 'Query';
  deviceCellularStatus?: {
    __typename?: 'CellularStatus';
    managerModemState: CellularManagerModemState;
    observedAt: string;
  } | null;
};

export type DhcpRulesForNetworkQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type DhcpRulesForNetworkQueryQuery = {
  __typename?: 'Query';
  dhcpRulesForNetwork: Array<{
    __typename?: 'DHCPRule';
    UUID: string;
    vlan: { __typename?: 'VLAN'; name: string };
  }>;
};

export type ControllerMetricsQueryQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
  filter: MetricsFilterInput;
}>;

export type ControllerMetricsQueryQuery = {
  __typename?: 'Query';
  controllerPortMetricsRate: {
    __typename?: 'ControllerPortMetricsRateResponse';
    values: Array<{
      __typename?: 'ControllerPortMetricsRateValue';
      timestamp: string;
      portNumber: number;
      dropsPerSecond: number;
      txErrPerSecond: number;
      rxErrPerSecond: number;
      totalRxBytesPerSecond: number;
      totalTxBytesPerSecond: number;
      multicastRxPacketsPerSecond: number;
      multicastTxPacketsPerSecond: number;
      broadcastRxPacketsPerSecond: number;
      broadcastTxPacketsPerSecond: number;
    }>;
  };
  controllerDNSRequestRates: {
    __typename?: 'ControllerDNSRequestRatesResponse';
    values: Array<{
      __typename?: 'ControllerDNSRequestRatesValue';
      timestamp: string;
      value: number;
      uuid: string;
    }>;
  };
};

export type ControllerPhyInterfaceFieldsFragment = {
  __typename?: 'PhyInterface';
  UUID: string;
  virtualDeviceUUID: string;
  portNumber: number;
  label?: string | null;
  description?: string | null;
  hardwareLabel?: string | null;
  isEnabled: boolean;
  isTrunkPort: boolean;
  isUplink: boolean;
  isEthernet: boolean;
  isSFP: boolean;
  hasWANActivity?: boolean | null;
  forcedPortSpeedMbps?: number | null;
  portSpeedMbps?: number | null;
  maxSpeedMbps?: number | null;
  ipv4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
  ipv4ClientGateway?: string | null;
  ipv4ClientPrefixLength?: number | null;
  ipv4ClientAddresses?: Array<string> | null;
  uplinkPriority?: number | null;
  uplinkExternalAddresses?: Array<string> | null;
  isUplinkActive?: boolean | null;
  uplinkVLANID?: number | null;
  uplinkGatewayMACAddress?: string | null;
  virtualDevice:
    | {
        __typename?: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        deviceModel: DeviceModel;
      }
    | {
        __typename?: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        deviceModel: DeviceModel;
      }
    | {
        __typename?: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        deviceModel: DeviceModel;
      }
    | {
        __typename?: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        deviceModel: DeviceModel;
      }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string; deviceModel: DeviceModel };
  nativeVLAN?: { __typename?: 'VLAN'; UUID: string; vlanID: number; name: string } | null;
  connectedDevices?: Array<{
    __typename?: 'PhyInterfaceConnectedDevice';
    macAddress: string;
    hardwareDevice?:
      | {
          __typename?: 'AccessPointHardwareDevice';
          serialNumber: string;
          isConnectedToBackend: boolean;
          isActive: boolean;
          deviceType: DeviceType;
          deviceModel: DeviceModel;
          virtualDevice?:
            | {
                __typename: 'AccessPointVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'ControllerVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'ObserverVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'PowerDistributionUnitVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'SwitchVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | null;
        }
      | {
          __typename?: 'ControllerHardwareDevice';
          serialNumber: string;
          isConnectedToBackend: boolean;
          isActive: boolean;
          deviceType: DeviceType;
          deviceModel: DeviceModel;
          virtualDevice?:
            | {
                __typename: 'AccessPointVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'ControllerVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'ObserverVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'PowerDistributionUnitVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'SwitchVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | null;
        }
      | {
          __typename?: 'PowerDistributionUnitHardwareDevice';
          serialNumber: string;
          isConnectedToBackend: boolean;
          isActive: boolean;
          deviceType: DeviceType;
          deviceModel: DeviceModel;
          virtualDevice?:
            | {
                __typename: 'AccessPointVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'ControllerVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'ObserverVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'PowerDistributionUnitVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'SwitchVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | null;
        }
      | {
          __typename?: 'SwitchHardwareDevice';
          serialNumber: string;
          isConnectedToBackend: boolean;
          isActive: boolean;
          deviceType: DeviceType;
          deviceModel: DeviceModel;
          virtualDevice?:
            | {
                __typename: 'AccessPointVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'ControllerVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'ObserverVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'PowerDistributionUnitVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | {
                __typename: 'SwitchVirtualDevice';
                deviceType: VirtualDeviceType;
                UUID: string;
                label: string;
              }
            | null;
        }
      | null;
  }> | null;
  internetServicePlan?: {
    __typename?: 'InternetServicePlan';
    UUID: string;
    controllerIP?: string | null;
    provider?: {
      __typename?: 'InternetServicePlanProvider';
      UUID: string;
      name: string;
      logoURL?: string | null;
    } | null;
  } | null;
};

export type PhyInterfacesForSecurityApplianceQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type PhyInterfacesForSecurityApplianceQuery = {
  __typename?: 'Query';
  phyInterfacesForVirtualDevice: Array<{
    __typename?: 'PhyInterface';
    UUID: string;
    virtualDeviceUUID: string;
    portNumber: number;
    label?: string | null;
    description?: string | null;
    hardwareLabel?: string | null;
    isEnabled: boolean;
    isTrunkPort: boolean;
    isUplink: boolean;
    isEthernet: boolean;
    isSFP: boolean;
    hasWANActivity?: boolean | null;
    forcedPortSpeedMbps?: number | null;
    portSpeedMbps?: number | null;
    maxSpeedMbps?: number | null;
    ipv4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
    ipv4ClientGateway?: string | null;
    ipv4ClientPrefixLength?: number | null;
    ipv4ClientAddresses?: Array<string> | null;
    uplinkPriority?: number | null;
    uplinkExternalAddresses?: Array<string> | null;
    isUplinkActive?: boolean | null;
    uplinkVLANID?: number | null;
    uplinkGatewayMACAddress?: string | null;
    virtualDevice:
      | {
          __typename?: 'AccessPointVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        }
      | {
          __typename?: 'ControllerVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        }
      | {
          __typename?: 'ObserverVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        }
      | {
          __typename?: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        }
      | {
          __typename?: 'SwitchVirtualDevice';
          UUID: string;
          label: string;
          deviceModel: DeviceModel;
        };
    nativeVLAN?: { __typename?: 'VLAN'; UUID: string; vlanID: number; name: string } | null;
    connectedDevices?: Array<{
      __typename?: 'PhyInterfaceConnectedDevice';
      macAddress: string;
      hardwareDevice?:
        | {
            __typename?: 'AccessPointHardwareDevice';
            serialNumber: string;
            isConnectedToBackend: boolean;
            isActive: boolean;
            deviceType: DeviceType;
            deviceModel: DeviceModel;
            virtualDevice?:
              | {
                  __typename: 'AccessPointVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ControllerVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ObserverVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'PowerDistributionUnitVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'SwitchVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | null;
          }
        | {
            __typename?: 'ControllerHardwareDevice';
            serialNumber: string;
            isConnectedToBackend: boolean;
            isActive: boolean;
            deviceType: DeviceType;
            deviceModel: DeviceModel;
            virtualDevice?:
              | {
                  __typename: 'AccessPointVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ControllerVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ObserverVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'PowerDistributionUnitVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'SwitchVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | null;
          }
        | {
            __typename?: 'PowerDistributionUnitHardwareDevice';
            serialNumber: string;
            isConnectedToBackend: boolean;
            isActive: boolean;
            deviceType: DeviceType;
            deviceModel: DeviceModel;
            virtualDevice?:
              | {
                  __typename: 'AccessPointVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ControllerVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ObserverVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'PowerDistributionUnitVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'SwitchVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | null;
          }
        | {
            __typename?: 'SwitchHardwareDevice';
            serialNumber: string;
            isConnectedToBackend: boolean;
            isActive: boolean;
            deviceType: DeviceType;
            deviceModel: DeviceModel;
            virtualDevice?:
              | {
                  __typename: 'AccessPointVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ControllerVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ObserverVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'PowerDistributionUnitVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'SwitchVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | null;
          }
        | null;
    }> | null;
    internetServicePlan?: {
      __typename?: 'InternetServicePlan';
      UUID: string;
      controllerIP?: string | null;
      provider?: {
        __typename?: 'InternetServicePlanProvider';
        UUID: string;
        name: string;
        logoURL?: string | null;
      } | null;
    } | null;
  }>;
};

export type SecurityAppliancePortStatsQueryQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type SecurityAppliancePortStatsQueryQuery = {
  __typename?: 'Query';
  controllerPortStats: Array<{
    __typename?: 'ControllerPortStat';
    portNumber: number;
    drops: number;
    txErr: number;
    rxErr: number;
    ipv4: number;
    ipv6: number;
    punts: number;
    totalRxBytes: number;
    totalTxBytes: number;
    totalRxPackets: number;
    totalTxPackets: number;
    hwUp: boolean;
  }>;
};

export type UptimeStatsForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  lookBack: Scalars['Int']['input'];
  stepSeconds: Scalars['Int']['input'];
}>;

export type UptimeStatsForNetworkQuery = {
  __typename?: 'Query';
  networkUplinkQuality: {
    __typename?: 'NetworkUplinkQualityResponse';
    values: Array<{
      __typename?: 'NetworkUplinkQualityValue';
      phyInterfaceUUID: string;
      timestamp: string;
      value: number;
    }>;
  };
};

export type PortCableTestMutationMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  portNumber: Scalars['Int']['input'];
}>;

export type PortCableTestMutationMutation = {
  __typename?: 'Mutation';
  rpcPortCableTest: {
    __typename?: 'RPCCableTestResponse';
    pairA: { __typename?: 'RPCCableTestTwistedPairResult'; status: RpcCableTestTwistedPairStatus };
    pairB: { __typename?: 'RPCCableTestTwistedPairResult'; status: RpcCableTestTwistedPairStatus };
    pairC: { __typename?: 'RPCCableTestTwistedPairResult'; status: RpcCableTestTwistedPairStatus };
    pairD: { __typename?: 'RPCCableTestTwistedPairResult'; status: RpcCableTestTwistedPairStatus };
  };
};

export type PatchPanelLayoutSwitchesQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type PatchPanelLayoutSwitchesQuery = {
  __typename?: 'Query';
  network: {
    __typename?: 'Network';
    label: string;
    company?: { __typename?: 'Company'; name: string } | null;
    virtualDevices: Array<
      | { __typename: 'AccessPointVirtualDevice'; UUID: string }
      | { __typename: 'ControllerVirtualDevice'; UUID: string }
      | { __typename: 'ObserverVirtualDevice'; UUID: string }
      | { __typename: 'PowerDistributionUnitVirtualDevice'; UUID: string }
      | {
          __typename: 'SwitchVirtualDevice';
          label: string;
          UUID: string;
          phyInterfaces: Array<{
            __typename?: 'PhyInterface';
            UUID: string;
            portNumber: number;
            label?: string | null;
            description?: string | null;
            isTrunkPort: boolean;
            allowedVLANs?: Array<{ __typename?: 'VLAN'; name: string; vlanID: number }> | null;
            nativeVLAN?: { __typename?: 'VLAN'; name: string; vlanID: number } | null;
          }>;
        }
    >;
  };
};

export type SwitchBootHistoryQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SwitchBootHistoryQueryQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename?: 'AccessPointHardwareDevice';
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
      };
};

export type InsightsQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
}>;

export type InsightsQueryQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename: 'AccessPointHardwareDevice';
        serialNumber: string;
        macAddress?: string | null;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        tunnelIPAddress?: string | null;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
        virtualDevice?:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              switchProfile: { __typename?: 'SwitchProfile'; stpBridgePriority: number };
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerHardwareDevice';
        serialNumber: string;
        macAddress?: string | null;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        tunnelIPAddress?: string | null;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
        virtualDevice?:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              switchProfile: { __typename?: 'SwitchProfile'; stpBridgePriority: number };
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        macAddress?: string | null;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        tunnelIPAddress?: string | null;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
        virtualDevice?:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              switchProfile: { __typename?: 'SwitchProfile'; stpBridgePriority: number };
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchHardwareDevice';
        ipAddress?: string | null;
        serialNumber: string;
        macAddress?: string | null;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        tunnelIPAddress?: string | null;
        isConnectedToBackend: boolean;
        disconnectedFromBackendAt?: string | null;
        stpInfo?: {
          __typename?: 'STPInfo';
          isRootBridge: boolean;
          rootBridgeMACAddress: string;
        } | null;
        bootHistory?: Array<{
          __typename?: 'BootInfo';
          bootCount: number;
          buildName: string;
          rebootReason: string;
          createdAt: string;
        }> | null;
        virtualDevice?:
          | {
              __typename: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | {
              __typename: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
              description?: string | null;
              networkUUID: string;
              switchProfile: { __typename?: 'SwitchProfile'; stpBridgePriority: number };
              nosVersion?: {
                __typename?: 'ConfiguredNOSVersion';
                id: number;
                version: string;
                buildName: string;
              } | null;
              pendingNosVersion?: {
                __typename?: 'PendingNosVersion';
                scheduledAt: string;
                nosVersion: { __typename?: 'NosVersion'; version: string };
              } | null;
            }
          | null;
      };
};

export type SwitchesRootQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type SwitchesRootQueryQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename?: 'AccessPointVirtualDevice';
        UUID: string;
        hardwareDevice?:
          | { __typename: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              stpInfo?: { __typename?: 'STPInfo'; isRootBridge: boolean } | null;
            }
          | null;
      }
    | {
        __typename?: 'ControllerVirtualDevice';
        UUID: string;
        hardwareDevice?:
          | { __typename: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              stpInfo?: { __typename?: 'STPInfo'; isRootBridge: boolean } | null;
            }
          | null;
      }
    | {
        __typename?: 'ObserverVirtualDevice';
        UUID: string;
        hardwareDevice?:
          | { __typename: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              stpInfo?: { __typename?: 'STPInfo'; isRootBridge: boolean } | null;
            }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        hardwareDevice?:
          | { __typename: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              stpInfo?: { __typename?: 'STPInfo'; isRootBridge: boolean } | null;
            }
          | null;
      }
    | {
        __typename?: 'SwitchVirtualDevice';
        UUID: string;
        hardwareDevice?:
          | { __typename: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              stpInfo?: { __typename?: 'STPInfo'; isRootBridge: boolean } | null;
            }
          | null;
      }
  >;
};

export type SwitchMetricsQueryQueryVariables = Exact<{
  input: MetricsSerialNumberDirectionInput;
  filter: MetricsFilterInput;
}>;

export type SwitchMetricsQueryQuery = {
  __typename?: 'Query';
  switchThroughputPerPort: {
    __typename?: 'SwitchMetricsResponse';
    metadata?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    values: Array<{
      __typename?: 'SwitchPortMetricsValue';
      port: string;
      direction: TrafficDirection;
      timestamp: string;
      value: number;
    }>;
  };
  switchDiscardRatesPerPort: {
    __typename?: 'SwitchMetricsResponse';
    metadata?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    values: Array<{
      __typename?: 'SwitchPortMetricsValue';
      port: string;
      direction: TrafficDirection;
      timestamp: string;
      value: number;
    }>;
  };
  switchErrorRatesPerPort: {
    __typename?: 'SwitchMetricsResponse';
    metadata?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    values: Array<{
      __typename?: 'SwitchPortMetricsValue';
      port: string;
      direction: TrafficDirection;
      timestamp: string;
      value: number;
    }>;
  };
};

export type SwitchPortNumbersQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type SwitchPortNumbersQuery = {
  __typename?: 'Query';
  phyInterfacesForVirtualDevice: Array<{ __typename?: 'PhyInterface'; portNumber: number }>;
};

export type UnassignedHardwareForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type UnassignedHardwareForNetworkQuery = {
  __typename?: 'Query';
  unassignedHardwareDevicesForNetwork: Array<{
    __typename?: 'UnassignedHardwareDeviceForNetworkResponse';
    virtualDevice: { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string };
    unassignedHardwareConnections: Array<{
      __typename?: 'UnassignedHardwareAndConnectedPhyInterface';
      phyInterface: { __typename?: 'PhyInterface'; UUID: string; portNumber: number };
      unassignedHardwareDevice: {
        __typename?: 'AccessPointHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        macAddress?: string | null;
      };
    }>;
  }>;
};

export type RebootSwitchMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type RebootSwitchMutation = { __typename?: 'Mutation'; rpcRebootDevice: boolean };

export type SwitchQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type SwitchQueryQuery = {
  __typename?: 'Query';
  virtualDevice:
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        networkUUID: string;
        isConsoleEnabled: boolean;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        switchProfile: { __typename?: 'SwitchProfile'; UUID: string; stpBridgePriority: number };
        phyInterfaces: Array<{
          __typename?: 'PhyInterface';
          UUID: string;
          isEthernet: boolean;
          isSFP: boolean;
          isUplink: boolean;
          portSpeedMbps?: number | null;
          maxSpeedMbps?: number | null;
        }>;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          buildName: string;
          version: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              serialNumber: string;
              deviceType: DeviceType;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      };
};

export type SwitchesQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  includeIsDev?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SwitchesQueryQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        uptime?: string | null;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              uptime?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              stpInfo?: {
                __typename?: 'STPInfo';
                isRootBridge: boolean;
                rootBridgePortNumber: number;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        uptime?: string | null;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              uptime?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              stpInfo?: {
                __typename?: 'STPInfo';
                isRootBridge: boolean;
                rootBridgePortNumber: number;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        uptime?: string | null;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              uptime?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              stpInfo?: {
                __typename?: 'STPInfo';
                isRootBridge: boolean;
                rootBridgePortNumber: number;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        uptime?: string | null;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              uptime?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              stpInfo?: {
                __typename?: 'STPInfo';
                isRootBridge: boolean;
                rootBridgePortNumber: number;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        uptime?: string | null;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        switchProfile: { __typename?: 'SwitchProfile'; stpBridgePriority: number };
        phyInterfaces: Array<{
          __typename?: 'PhyInterface';
          UUID: string;
          isEthernet: boolean;
          isSFP: boolean;
          isUplink: boolean;
          portSpeedMbps?: number | null;
          maxSpeedMbps?: number | null;
        }>;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              uptime?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              stpInfo?: {
                __typename?: 'STPInfo';
                isRootBridge: boolean;
                rootBridgePortNumber: number;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
  >;
};

export type PortsQueryQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type PortsQueryQuery = {
  __typename?: 'Query';
  phyInterfacesForVirtualDevice: Array<{
    __typename?: 'PhyInterface';
    UUID: string;
    virtualDeviceUUID: string;
    description?: string | null;
    forcedPortSpeedMbps?: number | null;
    isBoundToAllVLANs: boolean;
    isEnabled: boolean;
    isPOEEnabled: boolean;
    isSTPEdgePortEnabled: boolean;
    isSTPEnabled: boolean;
    isTrunkPort: boolean;
    isUplink: boolean;
    isLoopbackDetected?: boolean | null;
    isBlockedDueToFlaps: boolean;
    isSTPForwarding?: boolean | null;
    isIngressFilteringEnabled: boolean;
    frameAcceptTypeFilter?: PhyInterfaceFrameAcceptTypeFilter | null;
    label?: string | null;
    portNumber: number;
    isEthernet: boolean;
    isSFP: boolean;
    powerDraw?: number | null;
    portSpeedMbps?: number | null;
    maxSpeedMbps?: number | null;
    stpPortRole?: StpPortRole | null;
    isStormControlEnabled: boolean;
    stormControlBroadcastTrafficPercent: number;
    stormControlUnknownMulticastTrafficPercent: number;
    stormControlUnknownUnicastTrafficPercent: number;
    allowedVLANs?: Array<{
      __typename?: 'VLAN';
      UUID: string;
      name: string;
      vlanID: number;
    }> | null;
    nativeVLAN?: { __typename?: 'VLAN'; UUID: string; name: string; vlanID: number } | null;
    throughputLastDay?: Array<{
      __typename?: 'ThroughputValueWithDirection';
      direction: TrafficDirection;
      value: number;
    }> | null;
    connectedDevices?: Array<{
      __typename?: 'PhyInterfaceConnectedDevice';
      macAddress: string;
      hardwareDevice?:
        | {
            __typename?: 'AccessPointHardwareDevice';
            serialNumber: string;
            isConnectedToBackend: boolean;
            isActive: boolean;
            deviceType: DeviceType;
            virtualDevice?:
              | {
                  __typename: 'AccessPointVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ControllerVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ObserverVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'PowerDistributionUnitVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'SwitchVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | null;
          }
        | {
            __typename?: 'ControllerHardwareDevice';
            serialNumber: string;
            isConnectedToBackend: boolean;
            isActive: boolean;
            deviceType: DeviceType;
            virtualDevice?:
              | {
                  __typename: 'AccessPointVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ControllerVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ObserverVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'PowerDistributionUnitVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'SwitchVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | null;
          }
        | {
            __typename?: 'PowerDistributionUnitHardwareDevice';
            serialNumber: string;
            isConnectedToBackend: boolean;
            isActive: boolean;
            deviceType: DeviceType;
            virtualDevice?:
              | {
                  __typename: 'AccessPointVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ControllerVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ObserverVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'PowerDistributionUnitVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'SwitchVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | null;
          }
        | {
            __typename?: 'SwitchHardwareDevice';
            serialNumber: string;
            isConnectedToBackend: boolean;
            isActive: boolean;
            deviceType: DeviceType;
            virtualDevice?:
              | {
                  __typename: 'AccessPointVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ControllerVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'ObserverVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'PowerDistributionUnitVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | {
                  __typename: 'SwitchVirtualDevice';
                  deviceType: VirtualDeviceType;
                  UUID: string;
                  label: string;
                }
              | null;
          }
        | null;
    }> | null;
    sfpModuleInfo?: {
      __typename?: 'SFPModuleInfo';
      vendor: string;
      serialNumber: string;
      partName: string;
      moduleSpeed: string;
      moduleType: string;
    } | null;
  }>;
};

export type MacTableQueryQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type MacTableQueryQuery = {
  __typename?: 'Query';
  switchMACTable: Array<{
    __typename?: 'SwitchMACTableEntry';
    updatedAt: string;
    macAddress: string;
    port: number;
    vlan?: { __typename?: 'VLAN'; UUID: string; name: string; vlanID: number } | null;
  }>;
};

export type UpdateSwitchProfileMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateSwitchProfileInput;
}>;

export type UpdateSwitchProfileMutation = {
  __typename?: 'Mutation';
  updateSwitchProfile: { __typename?: 'SwitchProfile'; UUID: string };
};

export type FindMacOnSwitchQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  mac: Scalars['MacAddress']['input'];
}>;

export type FindMacOnSwitchQueryQuery = {
  __typename?: 'Query';
  findSwitchesForClientMAC: Array<{
    __typename?: 'MACAddressSwitchEntry';
    virtualDevice:
      | {
          __typename?: 'AccessPointVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'ControllerVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'ObserverVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'SwitchVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        };
    phyInterface: { __typename?: 'PhyInterface'; UUID: string; portNumber: number };
  }>;
};

export type FindLldpEntryOnSwitchQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  mac: Scalars['MacAddress']['input'];
}>;

export type FindLldpEntryOnSwitchQueryQuery = {
  __typename?: 'Query';
  findSwitchLLDPEntryForMAC?: {
    __typename?: 'MACAddressSwitchEntry';
    virtualDevice:
      | {
          __typename?: 'AccessPointVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'ControllerVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'ObserverVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'SwitchVirtualDevice';
          UUID: string;
          label: string;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        };
    phyInterface: { __typename?: 'PhyInterface'; UUID: string; portNumber: number };
  } | null;
};

export type PortCycleMutationMutationVariables = Exact<{
  portNumber: Scalars['Int']['input'];
  serialNumber: Scalars['String']['input'];
  poeCycle: Scalars['Boolean']['input'];
  portCycle: Scalars['Boolean']['input'];
}>;

export type PortCycleMutationMutation = { __typename?: 'Mutation'; rpcSwitchPortCycle: boolean };

export type SwitchPortMetricsQueryQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
  filter: MetricsFilterInput;
}>;

export type SwitchPortMetricsQueryQuery = {
  __typename?: 'Query';
  switchPortMetricsRate: {
    __typename?: 'SwitchPortMetricsRateResponse';
    values: Array<{
      __typename?: 'SwitchPortMetricsRateValue';
      timestamp: string;
      portNumber: number;
      dropsPerSecond: number;
      txErrPerSecond: number;
      rxErrPerSecond: number;
      totalRxBytesPerSecond: number;
      totalTxBytesPerSecond: number;
      multicastRxPacketsPerSecond: number;
      multicastTxPacketsPerSecond: number;
      broadcastRxPacketsPerSecond: number;
      broadcastTxPacketsPerSecond: number;
    }>;
  };
};

export type SwitchPortStatsQueryQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type SwitchPortStatsQueryQuery = {
  __typename?: 'Query';
  switchPortStats: Array<{
    __typename?: 'SwitchPortStat';
    portNumber: number;
    totalRxBytes: number;
    totalTxBytes: number;
  }>;
};

export type HubNetworksOverviewQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
}>;

export type HubNetworksOverviewQuery = {
  __typename?: 'Query';
  networksForCompany: Array<{
    __typename?: 'Network';
    slug: string;
    label: string;
    UUID: string;
    isActive: boolean;
    isTemplate: boolean;
    mailingAddress?: {
      __typename?: 'MailingAddress';
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      subdivisionCode?: string | null;
      postalCode?: string | null;
      countryISO2?: string | null;
      timezone?: string | null;
      coordinates?: { __typename?: 'Coordinates'; latitude: number; longitude: number } | null;
    } | null;
    virtualDevices: Array<
      | {
          __typename?: 'AccessPointVirtualDevice';
          UUID: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'ControllerVirtualDevice';
          UUID: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'ObserverVirtualDevice';
          UUID: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
      | {
          __typename?: 'SwitchVirtualDevice';
          UUID: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | { __typename?: 'AccessPointHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'ControllerHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'PowerDistributionUnitHardwareDevice'; isConnectedToBackend: boolean }
            | { __typename?: 'SwitchHardwareDevice'; isConnectedToBackend: boolean }
            | null;
        }
    >;
  }>;
};

export type UptimeStatsForNetworksQueryVariables = Exact<{
  networkUUIDs: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  lookBack: Scalars['Int']['input'];
  stepSeconds: Scalars['Int']['input'];
}>;

export type UptimeStatsForNetworksQuery = {
  __typename?: 'Query';
  networksUplinkQualities: Array<{
    __typename?: 'NetworkUplinkQualityResponse';
    values: Array<{
      __typename?: 'NetworkUplinkQualityValue';
      phyInterfaceUUID: string;
      timestamp: string;
      value: number;
    }>;
  }>;
};

export type ApUptimeQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter: MetricsFilterInput;
}>;

export type ApUptimeQuery = {
  __typename?: 'Query';
  apUptime: {
    __typename?: 'APUptimeResponse';
    metadata?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    values: Array<{
      __typename?: 'APUptimeMetricsValue';
      timestamp: string;
      value: number;
      apName: string;
    }>;
  };
};

export type ClientMetricsQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  filter: MetricsFilterInput;
}>;

export type ClientMetricsQuery = {
  __typename?: 'Query';
  clientMetrics: {
    __typename?: 'ClientMetricsResponse';
    metadata?: {
      __typename?: 'ClientMetricsMetadata';
      signal?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      noise?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      tx?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      rx?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    } | null;
    values: Array<{
      __typename?: 'ClientMetricsValue';
      timestamp: string;
      macAddress: string;
      signal: number;
      noise: number;
      txRate: number;
      rxRate: number;
    }>;
  };
};

export type ActiveClientsQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter: MetricsFilterInput;
  input?: InputMaybe<ActiveClientsInput>;
}>;

export type ActiveClientsQuery = {
  __typename?: 'Query';
  activeClients: {
    __typename?: 'ActiveClientsMetricsResponse';
    values: Array<{ __typename?: 'ActiveClientsMetricsValue'; timestamp: string; value: number }>;
  };
};

export type NetworkUplinkQualityQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter: MetricsFilterInput;
}>;

export type NetworkUplinkQualityQuery = {
  __typename?: 'Query';
  networkUplinkQuality: {
    __typename?: 'NetworkUplinkQualityResponse';
    values: Array<{
      __typename?: 'NetworkUplinkQualityValue';
      timestamp: string;
      value: number;
      phyInterfaceUUID: string;
    }>;
  };
};

export type NetworkUplinkThroughputQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter: MetricsFilterInput;
}>;

export type NetworkUplinkThroughputQuery = {
  __typename?: 'Query';
  networkUplinkThroughput: {
    __typename?: 'NetworkUplinkThroughputMetricsResponse';
    values: Array<{
      __typename?: 'NetworkUplinkThroughputMetricsValue';
      timestamp: string;
      value: number;
      direction: TrafficDirection;
      phyInterfaceUUID: string;
    }>;
  };
};

export type ApChannelUtilizationQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter: MetricsFilterInput;
}>;

export type ApChannelUtilizationQuery = {
  __typename?: 'Query';
  band2G: {
    __typename?: 'APChannelUtilizationResponse';
    metadata?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    values: Array<{
      __typename?: 'APChannelUtilizationMetricsValue';
      apName: string;
      timestamp: string;
      value: number;
    }>;
  };
  band5G: {
    __typename?: 'APChannelUtilizationResponse';
    metadata?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    values: Array<{
      __typename?: 'APChannelUtilizationMetricsValue';
      apName: string;
      timestamp: string;
      value: number;
    }>;
  };
};

export type NetwoorksForNosVersionQueryQueryVariables = Exact<{ [key: string]: never }>;

export type NetwoorksForNosVersionQueryQuery = {
  __typename?: 'Query';
  networks: Array<{ __typename?: 'Network'; pinnedNOSVersionID: number }>;
};

export type NosVersionsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type NosVersionsQueryQuery = {
  __typename?: 'Query';
  nosVersions: Array<{
    __typename?: 'NosVersion';
    version: string;
    id: number;
    mc01Build?: string | null;
    mw07Build?: string | null;
    ms10Build?: string | null;
    mp01Build?: string | null;
    isDeprecated: boolean;
    isDefault: boolean;
    updatedAt: string;
    major: number;
    minor: number;
    patch: number;
  }>;
};

export type NosVersionByIdQueryQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type NosVersionByIdQueryQuery = {
  __typename?: 'Query';
  nosVersions: Array<{
    __typename?: 'NosVersion';
    id: number;
    version: string;
    isDeprecated: boolean;
    isGA: boolean;
    isDefault: boolean;
    isLocked: boolean;
    releaseNotes?: string | null;
    major: number;
    minor: number;
    patch: number;
    updatedAt: string;
    mc01Build?: string | null;
    mw07Build?: string | null;
    mw08Build?: string | null;
    mw09Build?: string | null;
    ms10Build?: string | null;
    ms11Build?: string | null;
    mp01Build?: string | null;
  }>;
};

export type UpdateNosVersionMutationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: NosVersionInput;
}>;

export type UpdateNosVersionMutationMutation = {
  __typename?: 'Mutation';
  updateNosVersion: { __typename?: 'NosVersion'; id: number };
};

export type CreateNosVersionMutationMutationVariables = Exact<{
  input: NosVersionInput;
}>;

export type CreateNosVersionMutationMutation = {
  __typename?: 'Mutation';
  createNosVersion: { __typename?: 'NosVersion'; id: number };
};

export type NosFeaturesQueryQueryVariables = Exact<{ [key: string]: never }>;

export type NosFeaturesQueryQuery = {
  __typename?: 'Query';
  nosFeatures: Array<{
    __typename?: 'NosFeature';
    key: string;
    uuid: string;
    name: string;
    description: string;
    minMajorVersion: number;
    minMinorVersion: number;
    minPatchVersion: number;
    maxMajorVersion?: number | null;
    maxMinorVersion?: number | null;
    maxPatchVersion?: number | null;
    featureFlag?: string | null;
  }>;
};

export type NosFeatureQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type NosFeatureQueryQuery = {
  __typename?: 'Query';
  nosFeature: {
    __typename?: 'NosFeature';
    uuid: string;
    key: string;
    name: string;
    description: string;
    minMajorVersion: number;
    minMinorVersion: number;
    minPatchVersion: number;
    maxMajorVersion?: number | null;
    maxMinorVersion?: number | null;
    maxPatchVersion?: number | null;
    featureFlag?: string | null;
  };
};

export type UpdateNosFeatureMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateNosFeatureInput;
}>;

export type UpdateNosFeatureMutationMutation = {
  __typename?: 'Mutation';
  updateNosFeature: { __typename?: 'NosFeature'; uuid: string };
};

export type CreateNosFeatureMutationMutationVariables = Exact<{
  input: CreateNosFeatureInput;
}>;

export type CreateNosFeatureMutationMutation = {
  __typename?: 'Mutation';
  createNosFeature: { __typename?: 'NosFeature'; uuid: string };
};

export type NetworkForNavigationQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type NetworkForNavigationQuery = {
  __typename?: 'Query';
  network: {
    __typename?: 'Network';
    UUID: string;
    slug: string;
    label: string;
    companySlug?: string | null;
  };
};

export type HardwareDeviceForNavigationQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type HardwareDeviceForNavigationQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename?: 'AccessPointHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        serialNumber: string;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              description?: string | null;
              network: {
                __typename?: 'Network';
                UUID: string;
                slug: string;
                companySlug?: string | null;
              };
            }
          | null;
      };
};

export type CaptivePortalBodyFragment = {
  __typename?: 'CaptivePortal';
  UUID: string;
  callToAction?: string | null;
  logoImageS3Key?: string | null;
  name?: string | null;
  redirectURL?: string | null;
  allowedHosts: Array<string>;
  externalPortalURL?: string | null;
  authLifetimeSec?: number | null;
  termsAndConditions?: string | null;
  webhookKey: string;
  isEnabled: boolean;
  logoImageBase64?: { __typename?: 'Base64Image'; data: string; imageType: ImageType } | null;
  vlans?: Array<{ __typename?: 'VLAN'; UUID: string; name: string }> | null;
  displaySettings?: {
    __typename?: 'CaptivePortalDisplaySettings';
    backgroundColor?: string | null;
    fontColor?: string | null;
    hidePoweredByMeter?: boolean | null;
  } | null;
};

export type CaptivePortalForNetworkQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CaptivePortalForNetworkQueryQuery = {
  __typename?: 'Query';
  captivePortalForNetwork?: {
    __typename?: 'CaptivePortal';
    UUID: string;
    callToAction?: string | null;
    logoImageS3Key?: string | null;
    name?: string | null;
    redirectURL?: string | null;
    allowedHosts: Array<string>;
    externalPortalURL?: string | null;
    authLifetimeSec?: number | null;
    termsAndConditions?: string | null;
    webhookKey: string;
    isEnabled: boolean;
    logoImageBase64?: { __typename?: 'Base64Image'; data: string; imageType: ImageType } | null;
    vlans?: Array<{ __typename?: 'VLAN'; UUID: string; name: string }> | null;
    displaySettings?: {
      __typename?: 'CaptivePortalDisplaySettings';
      backgroundColor?: string | null;
      fontColor?: string | null;
      hidePoweredByMeter?: boolean | null;
    } | null;
  } | null;
};

export type UpdateCaptivePortalMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: CaptivePortalSettingsInput;
}>;

export type UpdateCaptivePortalMutation = {
  __typename?: 'Mutation';
  updateCaptivePortal: {
    __typename?: 'CaptivePortal';
    UUID: string;
    callToAction?: string | null;
    logoImageS3Key?: string | null;
    name?: string | null;
    redirectURL?: string | null;
    allowedHosts: Array<string>;
    externalPortalURL?: string | null;
    authLifetimeSec?: number | null;
    termsAndConditions?: string | null;
    webhookKey: string;
    isEnabled: boolean;
    logoImageBase64?: { __typename?: 'Base64Image'; data: string; imageType: ImageType } | null;
    vlans?: Array<{ __typename?: 'VLAN'; UUID: string; name: string }> | null;
    displaySettings?: {
      __typename?: 'CaptivePortalDisplaySettings';
      backgroundColor?: string | null;
      fontColor?: string | null;
      hidePoweredByMeter?: boolean | null;
    } | null;
  };
};

export type DeleteCaptivePortalMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteCaptivePortalMutation = {
  __typename?: 'Mutation';
  deleteCaptivePortal: { __typename?: 'CaptivePortal'; UUID: string };
};

export type CreateCaptivePortalMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CaptivePortalSettingsInput;
}>;

export type CreateCaptivePortalMutation = {
  __typename?: 'Mutation';
  createCaptivePortal: { __typename?: 'CaptivePortal'; UUID: string };
};

export type CapoActivityQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  numHoursLookback: Scalars['Int']['input'];
}>;

export type CapoActivityQueryQuery = {
  __typename?: 'Query';
  captivePortalAuthorizedClients: Array<{
    __typename?: 'CaptivePortalClient';
    timestamp: string;
    isAuthorized: boolean;
    ipAddress: string;
    macAddress: string;
    rawUserAgent: string;
  }>;
};

export type DeleteInternetServicePlanMutationMutationVariables = Exact<{
  internetServicePlanUUID: Scalars['UUID']['input'];
}>;

export type DeleteInternetServicePlanMutationMutation = {
  __typename?: 'Mutation';
  deleteInternetServicePlan: boolean;
};

export type CreateInternetServicePlanMutationMutationVariables = Exact<{
  companySlug: Scalars['String']['input'];
  networkUUID: Scalars['UUID']['input'];
  input: CreateInternetServicePlanInput;
}>;

export type CreateInternetServicePlanMutationMutation = {
  __typename?: 'Mutation';
  createInternetServicePlan: { __typename?: 'InternetServicePlan'; UUID: string };
};

export type UpdateInternetServicePlanMutationMutationVariables = Exact<{
  internetServicePlanUUID: Scalars['UUID']['input'];
  input: UpdateInternetServicePlanInput;
}>;

export type UpdateInternetServicePlanMutationMutation = {
  __typename?: 'Mutation';
  updateInternetServicePlan: { __typename?: 'InternetServicePlan'; UUID: string };
};

export type CopyIsPsToInternetServicePlansMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyIsPsToInternetServicePlansMutation = {
  __typename?: 'Mutation';
  copyInternetServicePlanIPsFromConfigToConfig2: Array<{
    __typename?: 'InternetServicePlan';
    UUID: string;
  }>;
};

export type ProvidersQueryQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
}>;

export type ProvidersQueryQuery = {
  __typename?: 'Query';
  internetServicePlanProviders: Array<{
    __typename?: 'InternetServicePlanProvider';
    UUID: string;
    logoURL?: string | null;
    name: string;
  }>;
};

export type ListIsPsQueryQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
  networkUUID?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type ListIsPsQueryQuery = {
  __typename?: 'Query';
  internetServicePlans: Array<{
    __typename?: 'InternetServicePlan';
    UUID: string;
    connectionProduct: IspProduct;
    connectionStatus: IspStatus;
    controllerIP?: string | null;
    controllerName?: string | null;
    firstUsableIP?: string | null;
    gatewayAddr?: string | null;
    hasStaticIP: boolean;
    lastUsableIP?: string | null;
    networkUUID?: string | null;
    staticIPRange?: string | null;
    address?: {
      __typename?: 'Address';
      address1: string;
      address2: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    } | null;
    provider?: {
      __typename?: 'InternetServicePlanProvider';
      UUID: string;
      logoURL?: string | null;
      name: string;
    } | null;
  }>;
};

export type MoveInternetServicePlanMutationMutationVariables = Exact<{
  ispUUID: Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
}>;

export type MoveInternetServicePlanMutationMutation = {
  __typename?: 'Mutation';
  moveInternetServicePlanToNetwork: { __typename?: 'InternetServicePlan'; UUID: string };
};

export type VlaNsQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type VlaNsQueryQuery = {
  __typename?: 'Query';
  vlans: Array<{
    __typename: 'VLAN';
    UUID: string;
    name: string;
    description?: string | null;
    isEnabled: boolean;
    isInternal?: boolean | null;
    isDefault: boolean;
    vlanID: number;
    ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
    ipV4ClientGateway?: string | null;
    ipV4ClientPrefixLength?: number | null;
  }>;
};

export type VlanQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type VlanQueryQuery = {
  __typename?: 'Query';
  vlan: {
    __typename: 'VLAN';
    UUID: string;
    name: string;
    description?: string | null;
    isEnabled: boolean;
    isInternal?: boolean | null;
    isDefault: boolean;
    vlanID: number;
    ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
    ipV4ClientGateway?: string | null;
    ipV4ClientPrefixLength?: number | null;
    dhcpRule?: {
      __typename?: 'DHCPRule';
      UUID: string;
      isIPv6: boolean;
      startIPAddress: string;
      endIPAddress: string;
      gatewayIPAddress: string;
      gatewayPrefixLength: number;
      leaseDurationSeconds: number;
      dnsUseGatewayProxy: boolean;
      dnsUpstreamServers: Array<string>;
      dnsSearchDomains?: Array<string> | null;
      dnsCacheIsEnabled: boolean;
      dnsCacheSize: number;
      dnsCacheMaxTTL: number;
      options: Array<{
        __typename?: 'DHCPOption';
        UUID: string;
        code: number;
        data: string;
        description?: string | null;
        createdAt: string;
      }>;
      reservedRanges: Array<{
        __typename?: 'DHCPReservedRange';
        UUID: string;
        startIPAddress: string;
        endIPAddress: string;
        createdAt: string;
      }>;
      staticMappings: Array<{
        __typename?: 'DHCPStaticMapping';
        UUID: string;
        name?: string | null;
        macAddress: string;
        ipAddress?: string | null;
        hostname?: string | null;
        createdAt: string;
      }>;
      dnsHostMappings: Array<{
        __typename?: 'DNSHostMapping';
        UUID: string;
        isEnabled: boolean;
        overrideDomain: string;
        destinationIPAddress?: string | null;
        destinationDomain?: string | null;
        createdAt: string;
      }>;
      applicationDNSFirewallRules: Array<{
        __typename?: 'ApplicationDNSFirewallRule';
        UUID: string;
      }>;
    } | null;
  };
};

export type CreateVlanMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateVlanInput;
}>;

export type CreateVlanMutationMutation = {
  __typename?: 'Mutation';
  createVLAN: { __typename?: 'VLAN'; UUID: string };
};

export type UpdateVlanMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateVlanInput;
}>;

export type UpdateVlanMutationMutation = {
  __typename?: 'Mutation';
  updateVLAN: { __typename?: 'VLAN'; UUID: string };
};

export type DeleteVlanMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteVlanMutationMutation = { __typename?: 'Mutation'; deleteVLAN: boolean };

export type CreateDhcpRuleMutationVariables = Exact<{
  vlanUUID: Scalars['UUID']['input'];
  input: CreateDhcpRuleInput;
}>;

export type CreateDhcpRuleMutation = {
  __typename?: 'Mutation';
  createDHCPRule: { __typename?: 'DHCPRule'; UUID: string };
};

export type UpdateDhcpRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateDhcpRuleInput;
}>;

export type UpdateDhcpRuleMutation = {
  __typename?: 'Mutation';
  updateDHCPRule: { __typename?: 'DHCPRule'; UUID: string };
};

export type DeleteDhcpRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteDhcpRuleMutation = {
  __typename?: 'Mutation';
  deleteDHCPRule: { __typename?: 'DHCPRule'; UUID: string };
};

export type CreateDhcpOptionMutationVariables = Exact<{
  ruleUUID: Scalars['UUID']['input'];
  input: CreateDhcpOptionInput;
}>;

export type CreateDhcpOptionMutation = {
  __typename?: 'Mutation';
  createDHCPOption: { __typename?: 'DHCPOption'; UUID: string };
};

export type UpdateDhcpOptionMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateDhcpOptionInput;
}>;

export type UpdateDhcpOptionMutation = {
  __typename?: 'Mutation';
  updateDHCPOption: { __typename?: 'DHCPOption'; UUID: string };
};

export type DeleteDhcpOptionMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteDhcpOptionMutation = {
  __typename?: 'Mutation';
  deleteDHCPOption: { __typename?: 'DHCPOption'; UUID: string };
};

export type CreateDhcpReservedRangeMutationVariables = Exact<{
  ruleUUID: Scalars['UUID']['input'];
  input: CreateDhcpReservedRangeInput;
}>;

export type CreateDhcpReservedRangeMutation = {
  __typename?: 'Mutation';
  createDHCPReservedRange: { __typename?: 'DHCPReservedRange'; UUID: string };
};

export type UpdateDhcpReservedRangeMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateDhcpReservedRangeInput;
}>;

export type UpdateDhcpReservedRangeMutation = {
  __typename?: 'Mutation';
  updateDHCPReservedRange: { __typename?: 'DHCPReservedRange'; UUID: string };
};

export type DeleteDhcpReservedRangeMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteDhcpReservedRangeMutation = {
  __typename?: 'Mutation';
  deleteDHCPReservedRange: { __typename?: 'DHCPReservedRange'; UUID: string };
};

export type CreateDhcpStaticMappingMutationVariables = Exact<{
  ruleUUID: Scalars['UUID']['input'];
  input: CreateDhcpStaticMappingInput;
}>;

export type CreateDhcpStaticMappingMutation = {
  __typename?: 'Mutation';
  createDHCPStaticMapping: { __typename?: 'DHCPStaticMapping'; UUID: string };
};

export type UpdateDhcpStaticMappingMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateDhcpStaticMappingInput;
}>;

export type UpdateDhcpStaticMappingMutation = {
  __typename?: 'Mutation';
  updateDHCPStaticMapping: { __typename?: 'DHCPStaticMapping'; UUID: string };
};

export type DeleteDhcpStaticMappingMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteDhcpStaticMappingMutation = {
  __typename?: 'Mutation';
  deleteDHCPStaticMapping: { __typename?: 'DHCPStaticMapping'; UUID: string };
};

export type CreateDnsHostMappingMutationVariables = Exact<{
  ruleUUID: Scalars['UUID']['input'];
  input: CreateDnsHostMappingInput;
}>;

export type CreateDnsHostMappingMutation = {
  __typename?: 'Mutation';
  createDNSHostMapping: { __typename?: 'DNSHostMapping'; UUID: string };
};

export type UpdateDnsHostMappingMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateDnsHostMappingInput;
}>;

export type UpdateDnsHostMappingMutation = {
  __typename?: 'Mutation';
  updateDNSHostMapping: { __typename?: 'DNSHostMapping'; UUID: string };
};

export type DeleteDnsHostMappingMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteDnsHostMappingMutation = {
  __typename?: 'Mutation';
  deleteDNSHostMapping: { __typename?: 'DNSHostMapping'; UUID: string };
};

export type CopyVlaNsMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyVlaNsMutationMutation = {
  __typename?: 'Mutation';
  copyVLANsFromConfig1ToConfig2: Array<{ __typename?: 'VLAN'; UUID: string }>;
};

export type CopyDnsHostMappingsFromConfig1MutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyDnsHostMappingsFromConfig1Mutation = {
  __typename?: 'Mutation';
  copyDNSHostMappingsFromConfig1ToConfig2: Array<{ __typename?: 'DNSHostMapping'; UUID: string }>;
};

export type NosVersionFieldsFragment = {
  __typename?: 'NosVersion';
  id: number;
  version: string;
  major: number;
  minor: number;
  patch: number;
  isDefault: boolean;
};

export type NosVersionQueryQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type NosVersionQueryQuery = {
  __typename?: 'Query';
  nosVersions: Array<{
    __typename?: 'NosVersion';
    id: number;
    version: string;
    major: number;
    minor: number;
    patch: number;
    isDefault: boolean;
  }>;
};

export type NosVersionsShortQueryQueryVariables = Exact<{ [key: string]: never }>;

export type NosVersionsShortQueryQuery = {
  __typename?: 'Query';
  nosVersions: Array<{
    __typename?: 'NosVersion';
    id: number;
    version: string;
    major: number;
    minor: number;
    patch: number;
    isDefault: boolean;
  }>;
};

export type SetNetworkNosMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: SetNetworkNosVersionInput;
}>;

export type SetNetworkNosMutationMutation = {
  __typename?: 'Mutation';
  setNetworkNosVersion: boolean;
};

export type NotificationPreferencesQueryQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
  controllerName: Scalars['String']['input'];
}>;

export type NotificationPreferencesQueryQuery = {
  __typename?: 'Query';
  notificationPreferences: {
    __typename?: 'NotificationPreferencesResponse';
    companyPreferences?: {
      __typename?: 'NotificationPreferences';
      companySID: string;
      emailAddresses?: Array<string> | null;
      additionalSlackChannels?: Array<string> | null;
      additionalEventTypes?: Array<NetworkEventType> | null;
    } | null;
    networkPreferences?: {
      __typename?: 'NotificationPreferences';
      networkUUID?: string | null;
      emailAddresses?: Array<string> | null;
      additionalSlackChannels?: Array<string> | null;
      additionalEventTypes?: Array<NetworkEventType> | null;
    } | null;
  };
};

export type UpsertCompanyNotificationPreferenceMutationMutationVariables = Exact<{
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<NotificationPreferenceInput>;
}>;

export type UpsertCompanyNotificationPreferenceMutationMutation = {
  __typename?: 'Mutation';
  upsertCompanyNotificationPreference: { __typename?: 'NotificationPreferences'; uuid: string };
};

export type UpsertControllerNotificationPreferenceMutationMutationVariables = Exact<{
  controllerName: Scalars['String']['input'];
  input?: InputMaybe<NotificationPreferenceInput>;
}>;

export type UpsertControllerNotificationPreferenceMutationMutation = {
  __typename?: 'Mutation';
  upsertNetworkNotificationPreference: { __typename?: 'NotificationPreferences'; uuid: string };
};

export type InternalNoteFieldsFragment = {
  __typename?: 'InternalNote';
  UUID: string;
  createdAt: string;
  updatedAt?: string | null;
  isImportant: boolean;
  note: string;
  photoURLs?: Array<string> | null;
  photoS3Keys?: Array<string> | null;
  createdByUser: {
    __typename?: 'User';
    email: string;
    firstName?: string | null;
    lastName?: string | null;
  };
  updatedByUser?: {
    __typename?: 'User';
    email: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type InternalNotesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type InternalNotesForNetworkQuery = {
  __typename?: 'Query';
  internalNotesForNetwork: Array<{
    __typename?: 'InternalNote';
    UUID: string;
    createdAt: string;
    updatedAt?: string | null;
    isImportant: boolean;
    note: string;
    photoURLs?: Array<string> | null;
    photoS3Keys?: Array<string> | null;
    createdByUser: {
      __typename?: 'User';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
    updatedByUser?: {
      __typename?: 'User';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }>;
};

export type InternalNoteQueryVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type InternalNoteQuery = {
  __typename?: 'Query';
  internalNote: {
    __typename?: 'InternalNote';
    UUID: string;
    createdAt: string;
    updatedAt?: string | null;
    isImportant: boolean;
    note: string;
    photoURLs?: Array<string> | null;
    photoS3Keys?: Array<string> | null;
    createdByUser: {
      __typename?: 'User';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
    updatedByUser?: {
      __typename?: 'User';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  };
};

export type CreateInternalNoteMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateInternalNoteInput;
}>;

export type CreateInternalNoteMutation = {
  __typename?: 'Mutation';
  createInternalNote: { __typename?: 'InternalNote'; UUID: string };
};

export type UpdateInternalNoteMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
  input: UpdateInternalNoteInput;
}>;

export type UpdateInternalNoteMutation = {
  __typename?: 'Mutation';
  updateInternalNote: { __typename?: 'InternalNote'; UUID: string };
};

export type DeleteInternalNoteMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type DeleteInternalNoteMutation = {
  __typename?: 'Mutation';
  deleteInternalNote: { __typename?: 'InternalNote'; UUID: string };
};

export type RpcPingMutationMutationVariables = Exact<{
  input: RpcPingInput;
}>;

export type RpcPingMutationMutation = {
  __typename?: 'Mutation';
  rpcPing: { __typename?: 'RPCPingResponse'; successRate: number; roundTripTimeMs?: number | null };
};

export type VlansForPingQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type VlansForPingQuery = {
  __typename?: 'Query';
  vlans: Array<{ __typename?: 'VLAN'; UUID: string; name: string }>;
};

export type RpcPing2MutationMutationVariables = Exact<{
  input: RpcPingInput;
}>;

export type RpcPing2MutationMutation = {
  __typename?: 'Mutation';
  rpcPing: { __typename?: 'RPCPingResponse'; successRate: number; roundTripTimeMs?: number | null };
};

export type RateLimitRuleQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type RateLimitRuleQuery = {
  __typename?: 'Query';
  rateLimitRule: {
    __typename?: 'RateLimitRule';
    UUID: string;
    name: string;
    description?: string | null;
    rateLimitKbpsDownload?: number | null;
    rateLimitKbpsUpload?: number | null;
    isEnabled: boolean;
    protocol?: IpProtocol | null;
    srcMac?: string | null;
    srcPrefix?: string | null;
    dstMac?: string | null;
    dstPrefix?: string | null;
    isPerSource: boolean;
    srcPortRange?: { __typename?: 'PortRange'; lower: number; upper: number } | null;
    dstPortRange?: { __typename?: 'PortRange'; lower: number; upper: number } | null;
    vlanBindings?: Array<{ __typename: 'VLAN'; UUID: string; name: string }> | null;
    phyInterfaceBindings?: Array<{
      __typename: 'PhyInterface';
      UUID: string;
      label?: string | null;
      portNumber: number;
      virtualDevice:
        | {
            __typename: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
    }> | null;
  };
};

export type CreateRateLimitRuleMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateRateLimitRuleInput;
}>;

export type CreateRateLimitRuleMutation = {
  __typename?: 'Mutation';
  createRateLimitRule: { __typename?: 'RateLimitRule'; UUID: string };
};

export type UpdateRateLimitRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateRateLimitRuleInput;
}>;

export type UpdateRateLimitRuleMutation = {
  __typename?: 'Mutation';
  updateRateLimitRule: { __typename?: 'RateLimitRule'; UUID: string };
};

export type DeleteRateLimitRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteRateLimitRuleMutation = {
  __typename?: 'Mutation';
  deleteRateLimitRule: { __typename?: 'RateLimitRule'; UUID: string };
};

export type RateLimitRulesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type RateLimitRulesForNetworkQuery = {
  __typename?: 'Query';
  rateLimitRulesForNetwork: Array<{
    __typename?: 'RateLimitRule';
    UUID: string;
    name: string;
    description?: string | null;
    rateLimitKbpsDownload?: number | null;
    rateLimitKbpsUpload?: number | null;
    isEnabled: boolean;
    isPerSource: boolean;
    protocol?: IpProtocol | null;
    srcMac?: string | null;
    srcPrefix?: string | null;
    dstMac?: string | null;
    dstPrefix?: string | null;
    srcPortRange?: { __typename?: 'PortRange'; lower: number; upper: number } | null;
    dstPortRange?: { __typename?: 'PortRange'; lower: number; upper: number } | null;
    vlanBindings?: Array<{ __typename: 'VLAN'; UUID: string; name: string }> | null;
    phyInterfaceBindings?: Array<{
      __typename: 'PhyInterface';
      UUID: string;
      portNumber: number;
      label?: string | null;
      hardwareLabel?: string | null;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    }> | null;
  }>;
};

export type RoutesForVirtualDeviceQueryVariables = Exact<{
  virtualDeviceUUID: Scalars['UUID']['input'];
}>;

export type RoutesForVirtualDeviceQuery = {
  __typename?: 'Query';
  networkRoutesForVirtualDevice: Array<{
    __typename?: 'NetworkRoute';
    destination: string;
    gateway?: string | null;
    attachment: {
      __typename?: 'NetworkRouteAttachment';
      vlan?: { __typename: 'VLAN'; UUID: string; name: string } | null;
      phyInterface?: {
        __typename: 'PhyInterface';
        UUID: string;
        portNumber: number;
        label?: string | null;
        hardwareLabel?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceType: VirtualDeviceType;
              deviceModel: DeviceModel;
            };
        internetServicePlan?: {
          __typename?: 'InternetServicePlan';
          provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
        } | null;
      } | null;
      autoVPNGroup?: { __typename: 'AutoVPNGroup'; UUID: string; name: string } | null;
      ipSecTunnel?: { __typename: 'IPSecTunnel'; UUID: string; name: string } | null;
      clientVPNServer?: { __typename: 'ClientVPNServer'; UUID: string; endpoint: string } | null;
    };
  }>;
};

export type PolicyRoutingRulesFieldsFragment = {
  __typename?: 'PolicyRoutingRule';
  UUID: string;
  name: string;
  isEnabled: boolean;
  srcPrefix?: string | null;
  dstPrefix: string;
  protocols: Array<IpProtocol>;
  srcVLAN?: {
    __typename: 'VLAN';
    UUID: string;
    name: string;
    ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
    ipV4ClientGateway?: string | null;
    ipV4ClientPrefixLength?: number | null;
  } | null;
  srcPortRanges: Array<{ __typename?: 'PortRange'; lower: number; upper: number }>;
  dstPortRanges: Array<{ __typename?: 'PortRange'; lower: number; upper: number }>;
  bindings?: Array<{
    __typename?: 'PolicyRoutingRuleBinding';
    UUID: string;
    metric: number;
    phyInterface: {
      __typename: 'PhyInterface';
      isUplinkActive?: boolean | null;
      hasWANActivity?: boolean | null;
      isEnabled: boolean;
      UUID: string;
      portNumber: number;
      label?: string | null;
      hardwareLabel?: string | null;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    };
  }> | null;
};

export type PolicyRoutingRulesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type PolicyRoutingRulesForNetworkQuery = {
  __typename?: 'Query';
  policyRoutingRulesForNetwork: Array<{
    __typename?: 'PolicyRoutingRule';
    UUID: string;
    name: string;
    isEnabled: boolean;
    srcPrefix?: string | null;
    dstPrefix: string;
    protocols: Array<IpProtocol>;
    srcVLAN?: {
      __typename: 'VLAN';
      UUID: string;
      name: string;
      ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
      ipV4ClientGateway?: string | null;
      ipV4ClientPrefixLength?: number | null;
    } | null;
    srcPortRanges: Array<{ __typename?: 'PortRange'; lower: number; upper: number }>;
    dstPortRanges: Array<{ __typename?: 'PortRange'; lower: number; upper: number }>;
    bindings?: Array<{
      __typename?: 'PolicyRoutingRuleBinding';
      UUID: string;
      metric: number;
      phyInterface: {
        __typename: 'PhyInterface';
        isUplinkActive?: boolean | null;
        hasWANActivity?: boolean | null;
        isEnabled: boolean;
        UUID: string;
        portNumber: number;
        label?: string | null;
        hardwareLabel?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            };
        internetServicePlan?: {
          __typename?: 'InternetServicePlan';
          provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
        } | null;
      };
    }> | null;
  }>;
};

export type PolicyRoutingRuleQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type PolicyRoutingRuleQuery = {
  __typename?: 'Query';
  policyRoutingRule: {
    __typename?: 'PolicyRoutingRule';
    UUID: string;
    name: string;
    isEnabled: boolean;
    srcPrefix?: string | null;
    dstPrefix: string;
    protocols: Array<IpProtocol>;
    srcVLAN?: {
      __typename: 'VLAN';
      UUID: string;
      name: string;
      ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
      ipV4ClientGateway?: string | null;
      ipV4ClientPrefixLength?: number | null;
    } | null;
    srcPortRanges: Array<{ __typename?: 'PortRange'; lower: number; upper: number }>;
    dstPortRanges: Array<{ __typename?: 'PortRange'; lower: number; upper: number }>;
    bindings?: Array<{
      __typename?: 'PolicyRoutingRuleBinding';
      UUID: string;
      metric: number;
      phyInterface: {
        __typename: 'PhyInterface';
        isUplinkActive?: boolean | null;
        hasWANActivity?: boolean | null;
        isEnabled: boolean;
        UUID: string;
        portNumber: number;
        label?: string | null;
        hardwareLabel?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            };
        internetServicePlan?: {
          __typename?: 'InternetServicePlan';
          provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
        } | null;
      };
    }> | null;
  };
};

export type CreatePolicyRoutingRuleMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreatePolicyRoutingRuleInput;
}>;

export type CreatePolicyRoutingRuleMutation = {
  __typename?: 'Mutation';
  createPolicyRoutingRule: { __typename?: 'PolicyRoutingRule'; UUID: string };
};

export type UpdatePolicyRoutingRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdatePolicyRoutingRuleInput;
}>;

export type UpdatePolicyRoutingRuleMutation = {
  __typename?: 'Mutation';
  updatePolicyRoutingRule: { __typename?: 'PolicyRoutingRule'; UUID: string };
};

export type DeletePolicyRoutingRuleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeletePolicyRoutingRuleMutation = {
  __typename?: 'Mutation';
  deletePolicyRoutingRule: { __typename?: 'PolicyRoutingRule'; UUID: string };
};

export type UpdatePolicyRoutingRuleBindingsMutationVariables = Exact<{
  input: BindPolicyRoutingRulesInput;
  orderedRuleUUIDs: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type UpdatePolicyRoutingRuleBindingsMutation = {
  __typename?: 'Mutation';
  bindPolicyRoutingRules: Array<{
    __typename?: 'PolicyRoutingRuleBinding';
    UUID: string;
    metric: number;
  }>;
};

export type StaticRouteFieldsFragment = {
  __typename?: 'StaticRoute';
  UUID: string;
  name: string;
  isEnabled: boolean;
  dstPrefix: string;
  gatewayIP?: string | null;
  gatewayDHCPStaticMapping?: {
    __typename?: 'DHCPStaticMapping';
    UUID: string;
    name?: string | null;
    macAddress: string;
    ipAddress?: string | null;
    hostname?: string | null;
  } | null;
};

export type StaticRoutesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type StaticRoutesForNetworkQuery = {
  __typename?: 'Query';
  staticRoutesForNetwork: Array<{
    __typename?: 'StaticRoute';
    UUID: string;
    name: string;
    isEnabled: boolean;
    dstPrefix: string;
    gatewayIP?: string | null;
    gatewayDHCPStaticMapping?: {
      __typename?: 'DHCPStaticMapping';
      UUID: string;
      name?: string | null;
      macAddress: string;
      ipAddress?: string | null;
      hostname?: string | null;
    } | null;
  }>;
};

export type StaticRouteQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type StaticRouteQuery = {
  __typename?: 'Query';
  staticRoute: {
    __typename?: 'StaticRoute';
    UUID: string;
    name: string;
    isEnabled: boolean;
    dstPrefix: string;
    gatewayIP?: string | null;
    gatewayDHCPStaticMapping?: {
      __typename?: 'DHCPStaticMapping';
      UUID: string;
      name?: string | null;
      macAddress: string;
      ipAddress?: string | null;
      hostname?: string | null;
    } | null;
  };
};

export type CreateStaticRouteMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateStaticRouteInput;
}>;

export type CreateStaticRouteMutation = {
  __typename?: 'Mutation';
  createStaticRoute: { __typename?: 'StaticRoute'; UUID: string };
};

export type UpdateStaticRouteMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateStaticRouteInput;
}>;

export type UpdateStaticRouteMutation = {
  __typename?: 'Mutation';
  updateStaticRoute: { __typename?: 'StaticRoute'; UUID: string };
};

export type DeleteStaticRouteMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteStaticRouteMutation = {
  __typename?: 'Mutation';
  deleteStaticRoute: { __typename?: 'StaticRoute'; UUID: string };
};

export type VlaNsStaticMappingsRulesQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type VlaNsStaticMappingsRulesQuery = {
  __typename?: 'Query';
  vlans: Array<{
    __typename?: 'VLAN';
    UUID: string;
    name: string;
    ipV4ClientAssignmentProtocol?: ClientAssignmentProtocol | null;
    ipV4ClientGateway?: string | null;
    ipV4ClientPrefixLength?: number | null;
    dhcpRule?: {
      __typename?: 'DHCPRule';
      UUID: string;
      staticMappings: Array<{
        __typename?: 'DHCPStaticMapping';
        UUID: string;
        name?: string | null;
        macAddress: string;
        ipAddress?: string | null;
        hostname?: string | null;
      }>;
    } | null;
  }>;
};

export type UpdateNotificationSettingMutationMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
  input: UpdateNotificationSettingInput;
}>;

export type UpdateNotificationSettingMutationMutation = {
  __typename?: 'Mutation';
  updateNotificationSetting: { __typename?: 'NotificationSetting'; UUID: string };
};

export type NotificationSettingsQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type NotificationSettingsQueryQuery = {
  __typename?: 'Query';
  notificationSettingsForNetwork: Array<{
    __typename?: 'NotificationSetting';
    UUID: string;
    alertReceiverUUID: string;
    isEnabled: boolean;
    arguments: Array<{
      __typename?: 'NotificationSettingArgument';
      parameterUUID: string;
      value: unknown;
    }>;
    notificationDefinition?: {
      __typename?: 'AlertDefinition';
      name: string;
      displayName: string;
      description?: string | null;
    } | null;
  }>;
};

export type CreateNotificationSettingMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateNotificationSettingInput;
}>;

export type CreateNotificationSettingMutationMutation = {
  __typename?: 'Mutation';
  createNotificationSetting: { __typename?: 'NotificationSetting'; UUID: string };
};

export type NotificationDefinitionsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationDefinitionsQueryQuery = {
  __typename?: 'Query';
  alertDefinitions: Array<{
    __typename?: 'AlertDefinition';
    name: string;
    displayName: string;
    description?: string | null;
    parameters: Array<
      | {
          __typename?: 'AlertParameterBoolean';
          uuid: string;
          name: string;
          description?: string | null;
          type: AlertParameterType;
          required: boolean;
        }
      | {
          __typename?: 'AlertParameterDate';
          uuid: string;
          name: string;
          description?: string | null;
          type: AlertParameterType;
          required: boolean;
        }
      | {
          __typename?: 'AlertParameterDateTime';
          uuid: string;
          name: string;
          description?: string | null;
          type: AlertParameterType;
          required: boolean;
        }
      | {
          __typename?: 'AlertParameterDuration';
          uuid: string;
          name: string;
          description?: string | null;
          type: AlertParameterType;
          required: boolean;
        }
      | {
          __typename?: 'AlertParameterNumber';
          uuid: string;
          name: string;
          description?: string | null;
          type: AlertParameterType;
          required: boolean;
        }
      | {
          __typename?: 'AlertParameterString';
          uuid: string;
          name: string;
          description?: string | null;
          type: AlertParameterType;
          required: boolean;
        }
    >;
  }>;
};

export type DeleteNotificationSettingMutationMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type DeleteNotificationSettingMutationMutation = {
  __typename?: 'Mutation';
  deleteNotificationSetting: { __typename?: 'NotificationSetting'; UUID: string };
};

export type UpdateServiceDiscoveryForNetworkMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
  input: UpdateNetworkInput;
}>;

export type UpdateServiceDiscoveryForNetworkMutation = {
  __typename?: 'Mutation';
  updateNetwork: { __typename?: 'Network'; UUID: string };
};

export type UpdateNetworkNetworkQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type UpdateNetworkNetworkQuery = {
  __typename?: 'Query';
  network: {
    __typename?: 'Network';
    UUID: string;
    slug: string;
    label: string;
    isActive: boolean;
    isCustomer: boolean;
    isTemplate: boolean;
    isConfig1WosUpgradesEnabled?: boolean | null;
    isUpgradeSensitive: boolean;
    multiWANAlgorithm?: MultiWanAlgorithm | null;
    onboarding?: {
      __typename?: 'NetworkOnboarding';
      UUID: string;
      jobData?: {
        __typename?: 'NetworkOnboardingJobData';
        id: string;
        jobID?: string | null;
      } | null;
    } | null;
    mailingAddress?: {
      __typename?: 'MailingAddress';
      UUID: string;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      subdivisionCode?: string | null;
      postalCode?: string | null;
      countryISO2?: string | null;
      timezone?: string | null;
    } | null;
  };
};

export type CreateNetworkMutationVariables = Exact<{
  input: CreateNetworkInput;
}>;

export type CreateNetworkMutation = {
  __typename?: 'Mutation';
  createNetwork: { __typename?: 'Network'; UUID: string; slug: string };
};

export type CreateNetworkFromCopyMutationVariables = Exact<{
  sourceNetworkUUID: Scalars['UUID']['input'];
  input: CreateNetworkCopyInput;
}>;

export type CreateNetworkFromCopyMutation = {
  __typename?: 'Mutation';
  createNetworkFromCopy: { __typename?: 'Network'; UUID: string; slug: string };
};

export type UpdateNetworkMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateNetworkInput;
}>;

export type UpdateNetworkMutation = {
  __typename?: 'Mutation';
  updateNetwork: { __typename?: 'Network'; UUID: string; slug: string };
};

export type DeleteNetworkMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteNetworkMutation = {
  __typename?: 'Mutation';
  deleteNetwork: { __typename?: 'Network'; UUID: string };
};

export type JobTrackersQueryVariables = Exact<{ [key: string]: never }>;

export type JobTrackersQuery = {
  __typename?: 'Query';
  jobTrackers: Array<{
    __typename?: 'NetworkOnboardingJobData';
    id: string;
    jobID?: string | null;
    totalSqFt?: Array<string> | null;
    fullAddress?: Array<string> | null;
    locationCode?: string | null;
  }>;
};

export type CreateNetworkOnboardingMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  jobTrackerID: Scalars['String']['input'];
}>;

export type CreateNetworkOnboardingMutation = {
  __typename?: 'Mutation';
  createNetworkOnboarding: { __typename?: 'NetworkOnboarding'; UUID: string };
};

export type MaintenanceWindowForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type MaintenanceWindowForNetworkQuery = {
  __typename?: 'Query';
  maintenanceWindowForNetwork: {
    __typename?: 'MaintenanceWindow';
    recurrence: MaintenanceWindowRecurrence;
    timezone?: string | null;
    isOptedOut: boolean;
    startHourOfDayLocal?: number | null;
    endHourOfDayLocal?: number | null;
    startDayOfWeekLocal?: number | null;
    endDayOfWeekLocal?: number | null;
  };
};

export type UpdateMaintenanceWindowMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: UpdateMaintenanceWindowInput;
}>;

export type UpdateMaintenanceWindowMutation = {
  __typename?: 'Mutation';
  updateMaintenanceWindowForNetwork: { __typename?: 'MaintenanceWindow'; UUID: string };
};

export type CopyMultiWanFromConfig1MutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyMultiWanFromConfig1Mutation = {
  __typename?: 'Mutation';
  copyNetworkMultiWANAlgorithmFromConfig1ToConfig2: { __typename?: 'Network'; UUID: string };
};

export type CopyFirewallRulesFromConfig1MutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyFirewallRulesFromConfig1Mutation = {
  __typename?: 'Mutation';
  copyFirewallRulesFromConfig1ToConfig2: Array<{ __typename?: 'FirewallRule'; UUID: string }>;
};

export type CopyPortForwardingRulesFromConfig1MutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyPortForwardingRulesFromConfig1Mutation = {
  __typename?: 'Mutation';
  copyPortForwardingRulesFromConfig1ToConfig2: Array<{
    __typename?: 'PortForwardingRule';
    UUID: string;
  }>;
};

export type CopyUplinkPhyInterfacesFromConfig1MutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyUplinkPhyInterfacesFromConfig1Mutation = {
  __typename?: 'Mutation';
  copyUplinkPhyInterfacesFromConfig1ToConfig2: Array<{ __typename?: 'PhyInterface'; UUID: string }>;
};

export type UpdateAlertReceiverWithTargetsMutationVariables = Exact<{
  alertReceiverUUID: Scalars['UUID']['input'];
  input: UpdateAlertReceiverWithTargetsInput;
}>;

export type UpdateAlertReceiverWithTargetsMutation = {
  __typename?: 'Mutation';
  updateAlertReceiverWithTargets: { __typename?: 'AlertReceiver'; UUID: string };
};

export type CreateAlertReceiverWithTargetsMutationMutationVariables = Exact<{
  companyUUID: Scalars['UUID']['input'];
  input: CreateAlertReceiverWithTargetsInput;
}>;

export type CreateAlertReceiverWithTargetsMutationMutation = {
  __typename?: 'Mutation';
  createAlertReceiverWithTargets: { __typename?: 'AlertReceiver'; UUID: string };
};

export type UpdateWebhookMutationMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
  input: UpdateAlertTargetWebhookInput;
}>;

export type UpdateWebhookMutationMutation = {
  __typename?: 'Mutation';
  updateAlertTargetWebhook: { __typename?: 'AlertTargetWebhook'; UUID: string };
};

export type CreateAlertTargetWebhookMutationVariables = Exact<{
  companyUUID: Scalars['UUID']['input'];
  input: CreateAlertTargetWebhookInput;
}>;

export type CreateAlertTargetWebhookMutation = {
  __typename?: 'Mutation';
  createAlertTargetWebhook: {
    __typename?: 'AlertTargetWebhook';
    UUID: string;
    companyUUID: string;
    signingSecret?: string | null;
    type: AlertTargetType;
    url: string;
    label?: string | null;
  };
};

export type DeleteAlertReceiverMutationMutationVariables = Exact<{
  alertReceiverUUID: Scalars['UUID']['input'];
}>;

export type DeleteAlertReceiverMutationMutation = {
  __typename?: 'Mutation';
  deleteAlertReceiverWithTargets: { __typename?: 'AlertReceiver'; UUID: string };
};

export type AlertTargetsQueryQueryVariables = Exact<{
  companyUUID: Scalars['UUID']['input'];
}>;

export type AlertTargetsQueryQuery = {
  __typename?: 'Query';
  alertTargets: {
    __typename?: 'AlertTargetsResponse';
    emails: Array<{
      __typename?: 'AlertTargetEmail';
      UUID: string;
      emailAddress: string;
      type: AlertTargetType;
    }>;
    webhooks: Array<{
      __typename?: 'AlertTargetWebhook';
      UUID: string;
      url: string;
      label?: string | null;
      type: AlertTargetType;
      signingSecret?: string | null;
    }>;
  };
};

export type AlertTargetsWebhookQueryQueryVariables = Exact<{
  companyUUID: Scalars['UUID']['input'];
}>;

export type AlertTargetsWebhookQueryQuery = {
  __typename?: 'Query';
  alertTargetsWebhook: Array<{
    __typename?: 'AlertTargetWebhook';
    UUID: string;
    signingSecret?: string | null;
    type: AlertTargetType;
    url: string;
    label?: string | null;
  }>;
};

export type AlertReceiversQueryQueryVariables = Exact<{
  companyUUID: Scalars['UUID']['input'];
}>;

export type AlertReceiversQueryQuery = {
  __typename?: 'Query';
  alertReceiversForCompany: Array<{
    __typename: 'AlertReceiver';
    UUID: string;
    companyUUID: string;
    label: string;
    targets: Array<
      | {
          __typename: 'AlertTargetEmail';
          emailAddress: string;
          UUID: string;
          type: AlertTargetType;
        }
      | {
          __typename: 'AlertTargetWebhook';
          url: string;
          label?: string | null;
          UUID: string;
          type: AlertTargetType;
        }
    >;
  }>;
};

export type DeleteAlertTargetWebhookMutationMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type DeleteAlertTargetWebhookMutationMutation = {
  __typename?: 'Mutation';
  deleteAlertTargetWebhook: { __typename?: 'AlertTargetWebhook'; UUID: string };
};

export type WanSpeedTestMutationMutationVariables = Exact<{
  input: RpcwanSpeedtestInput;
}>;

export type WanSpeedTestMutationMutation = {
  __typename?: 'Mutation';
  rpcWANSpeedtest: {
    __typename?: 'RPCWANSpeedtestResponse';
    downloadSpeedMbps: number;
    uploadSpeedMbps: number;
    latencyNs?: number | null;
    jitterNs?: number | null;
    targetName?: string | null;
    targetHost?: string | null;
    distance?: number | null;
  };
};

export type CompanyUserBodyFragment = {
  __typename?: 'CompanyUser';
  createdAt: string;
  deletedAt?: string | null;
  UUID: string;
  companyMembershipRole: CompanyMembershipRole;
  roles: Array<{
    __typename?: 'RoleAssignment';
    companySlug?: string | null;
    name: RoleName;
    networkUUID?: string | null;
  }>;
  user: { __typename?: 'User'; email: string; firstName?: string | null; lastName?: string | null };
};

export type GetCompanyUsersQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
  input?: InputMaybe<GetCompanyUserInput>;
}>;

export type GetCompanyUsersQuery = {
  __typename?: 'Query';
  companyUsers: Array<{
    __typename?: 'CompanyUser';
    createdAt: string;
    deletedAt?: string | null;
    UUID: string;
    companyMembershipRole: CompanyMembershipRole;
    roles: Array<{
      __typename?: 'RoleAssignment';
      companySlug?: string | null;
      name: RoleName;
      networkUUID?: string | null;
    }>;
    user: {
      __typename?: 'User';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  }>;
};

export type GetCompanyUserQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input?: InputMaybe<GetCompanyUserInput>;
}>;

export type GetCompanyUserQuery = {
  __typename?: 'Query';
  companyUser: {
    __typename?: 'CompanyUser';
    createdAt: string;
    deletedAt?: string | null;
    UUID: string;
    companyMembershipRole: CompanyMembershipRole;
    roles: Array<{
      __typename?: 'RoleAssignment';
      companySlug?: string | null;
      name: RoleName;
      networkUUID?: string | null;
    }>;
    user: {
      __typename?: 'User';
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  };
};

export type DeleteCompanyUserMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteCompanyUserMutation = {
  __typename?: 'Mutation';
  deleteCompanyUser: { __typename?: 'CompanyUser'; UUID: string };
};

export type ReactivateCompanyUserMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type ReactivateCompanyUserMutation = {
  __typename?: 'Mutation';
  activateCompanyUser: { __typename?: 'CompanyUser'; UUID: string };
};

export type CreateCompanyUserMutationVariables = Exact<{
  companySlug: Scalars['String']['input'];
  input: CreateCompanyUserInput;
}>;

export type CreateCompanyUserMutation = {
  __typename?: 'Mutation';
  createCompanyUser: { __typename?: 'CompanyUser'; UUID: string };
};

export type UpdateCompanyUserMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateCompanyUserInput;
}>;

export type UpdateCompanyUserMutation = {
  __typename?: 'Mutation';
  updateCompanyUser: { __typename?: 'CompanyUser'; UUID: string };
};

export type UpdateUserRolesMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: Array<UserRoleInput> | UserRoleInput;
}>;

export type UpdateUserRolesMutation = {
  __typename?: 'Mutation';
  updateUserRoles: { __typename?: 'CompanyUser'; UUID: string };
};

export type AssignOperatorRoleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type AssignOperatorRoleMutation = {
  __typename?: 'Mutation';
  assignOperatorRole: { __typename?: 'CompanyUser'; UUID: string };
};

export type RemoveOperatorRoleMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type RemoveOperatorRoleMutation = {
  __typename?: 'Mutation';
  removeOperatorRole: { __typename?: 'CompanyUser'; UUID: string };
};

export type RadioProfilesQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type RadioProfilesQueryQuery = {
  __typename?: 'Query';
  radioProfilesForNetwork: Array<{
    __typename?: 'RadioProfile';
    UUID: string;
    name: string;
    isDefault: boolean;
    band2_4GIsEnabled: boolean;
    band2_4GAutoChannelIsEnabled: boolean;
    band2_4GAllowedChannels?: Array<number> | null;
    band2_4GAutoChannelWidthIsEnabled: boolean;
    band2_4GAutoTxPowerIsEnabled: boolean;
    band2_4GChannelWidthMHz?: number | null;
    band2_4GAutoTxPowerMaxdBm?: number | null;
    band2_4GAutoTxPowerMindBm?: number | null;
    band5GIsEnabled: boolean;
    band5GAutoChannelIsEnabled: boolean;
    band5GAllowedChannels?: Array<number> | null;
    band5GAutoChannelWidthIsEnabled: boolean;
    band5GAutoTxPowerIsEnabled: boolean;
    band5GChannelWidthMHz?: number | null;
    band5GAutoTxPowerMaxdBm?: number | null;
    band5GAutoTxPowerMindBm?: number | null;
    virtualDevices: Array<
      | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
      | { __typename?: 'ControllerVirtualDevice'; UUID: string }
      | { __typename?: 'ObserverVirtualDevice'; UUID: string }
      | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
      | { __typename?: 'SwitchVirtualDevice'; UUID: string }
    >;
  }>;
};

export type CreateRadioProfileMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateRadioProfileInput;
}>;

export type CreateRadioProfileMutationMutation = {
  __typename?: 'Mutation';
  createRadioProfile: { __typename?: 'RadioProfile'; UUID: string };
};

export type UpdateRadioProfileMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateRadioProfileInput;
}>;

export type UpdateRadioProfileMutationMutation = {
  __typename?: 'Mutation';
  updateRadioProfile: { __typename?: 'RadioProfile'; UUID: string };
};

export type DeleteRadioProfileMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteRadioProfileMutationMutation = {
  __typename?: 'Mutation';
  deleteRadioProfile: { __typename?: 'RadioProfile'; UUID: string };
};

export type CopyRadioSettingsMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyRadioSettingsMutationMutation = {
  __typename?: 'Mutation';
  copyRadioSettingsForNetworkFromConfig1: Array<{ __typename: 'RadioSettings' }>;
};

export type RpceapolTestMutationMutationVariables = Exact<{
  input: RpcEapolTestForSerialsInput;
}>;

export type RpceapolTestMutationMutation = {
  __typename?: 'Mutation';
  rpcWosEapolTestForSerials: Array<{
    __typename?: 'RPCEapolTestForSerialResponse';
    status: RpcEapolTestForSerialStatus;
    error_reason?: string | null;
    serialNumber: string;
  }>;
};

export type SsiDsQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type SsiDsQueryQuery = {
  __typename?: 'Query';
  ssidsForNetwork: Array<{
    __typename?: 'SSID';
    UUID: string;
    ssid: string;
    clientSteeringAssociationSteeringIsEnabled: boolean;
    clientSteeringPostassociationSteeringIsEnabled?: boolean | null;
    description?: string | null;
    is80211axEnabled: boolean;
    isEnabled: boolean;
    isGuest: boolean;
    isHidden: boolean;
    isIsolateClientsEnabled: boolean;
    encryptionProtocol?: SsidEncryptionProtocol | null;
    password?: string | null;
    isBand2GAllowed: boolean;
    band2GMinimumBasicRateKbps?: number | null;
    isBand5GAllowed: boolean;
    passwordRotationCadence?: SsidPasswordRotationCadence | null;
    passwordRotationDayOfMonthLocal?: number | null;
    passwordRotationDayOfWeekLocal?: number | null;
    passwordRotationHourOfDayLocal?: number | null;
    passwordLastRotatedAt?: string | null;
    passwordNextRotationAt?: string | null;
    band5GMinimumBasicRateKbps?: number | null;
    isEnabledForAllAccessPoints: boolean;
    roaming80211rIsEnabled: boolean;
    roaming80211kIsEnabled: boolean;
    roaming80211vIsEnabled: boolean;
    encryption80211wProtectedManagementFramesMode?: SsidEncryption80211wProtectedManagementFramesMode | null;
    hs20HasProfile: boolean;
    hs20Enabled: boolean;
    hs20OperatorNames?: Array<string> | null;
    hs20VenueNames?: Array<string> | null;
    hs20VenueGroup?: number | null;
    hs20VenueType?: number | null;
    hs20AccessNetworkType?: number | null;
    hs20RoamingConsortiumOIs?: Array<string> | null;
    hs20DomainNames?: Array<string> | null;
    hs20NaiRealms?: Array<string> | null;
    hs20MccMncPairs?: Array<string> | null;
    dynamicVLANMode?: SsidDynamicVlanMode | null;
    radiusCoaEnabled: boolean;
    radiusCoaPort?: number | null;
    primaryEncryption8021X?: { __typename?: 'Encryption8021X'; UUID: string } | null;
    secondaryEncryption8021X?: { __typename?: 'Encryption8021X'; UUID: string } | null;
    enabledAccessPointVirtualDevices?: Array<{
      __typename?: 'AccessPointVirtualDevice';
      UUID: string;
    }> | null;
    vlan?: { __typename?: 'VLAN'; UUID: string; name: string } | null;
  }>;
};

export type Hs20AccessNetworkTypesQueryVariables = Exact<{ [key: string]: never }>;

export type Hs20AccessNetworkTypesQuery = {
  __typename?: 'Query';
  hs20ValidTypes: {
    __typename?: 'Hs20ValidTypesResponse';
    accessNetworkTypes: Array<{
      __typename?: 'Hs20AccessNetworkTypes';
      type: number;
      description: string;
    }>;
    venueTypes: Array<{
      __typename?: 'Hs20VenueTypes';
      group: number;
      type: number;
      description: string;
    }>;
    eapMethods: Array<{
      __typename?: 'Hs20EapMethod';
      method: number;
      description: string;
      mostUsed: boolean;
    }>;
    authParams: Array<{
      __typename?: 'Hs20AuthParam';
      param: number;
      description: string;
      validValues: Array<{ __typename?: 'Hs20AuthValue'; value: number; description: string }>;
    }>;
  };
};

export type CreateSsidMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateSsidInput;
}>;

export type CreateSsidMutationMutation = {
  __typename?: 'Mutation';
  createSSID: { __typename?: 'SSID'; UUID: string };
};

export type UpdateSsidMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateSsidInput;
}>;

export type UpdateSsidMutationMutation = {
  __typename?: 'Mutation';
  updateSSID: { __typename?: 'SSID'; UUID: string };
};

export type DeleteSsidMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteSsidMutationMutation = {
  __typename?: 'Mutation';
  deleteSSID: { __typename?: 'SSID'; UUID: string };
};

export type CopySsiDsMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopySsiDsMutationMutation = {
  __typename?: 'Mutation';
  copySSIDsFromConfig1ToConfig2: Array<{ __typename?: 'SSID'; UUID: string }>;
};

export type RadiusProfilesQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type RadiusProfilesQueryQuery = {
  __typename?: 'Query';
  encryption8021XsForNetwork: Array<{
    __typename?: 'Encryption8021X';
    UUID: string;
    label: string;
    authServerIPAddress: string;
    authServerPort: number;
    authServerSecret: string;
    accountingServerIPAddress?: string | null;
    accountingServerPort?: number | null;
    accountingServerSecret?: string | null;
  }>;
};

export type CreateRadiusProfileMutationMutationVariables = Exact<{
  input: CreateEncryption8021XInput;
}>;

export type CreateRadiusProfileMutationMutation = {
  __typename?: 'Mutation';
  createEncryption8021X: { __typename?: 'Encryption8021X'; UUID: string };
};

export type UpdateRadiusProfileMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateEncryption8021XInput;
}>;

export type UpdateRadiusProfileMutationMutation = {
  __typename?: 'Mutation';
  updateEncryption8021X: { __typename?: 'Encryption8021X'; UUID: string };
};

export type DeleteRadiusProfileMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteRadiusProfileMutationMutation = {
  __typename?: 'Mutation';
  deleteEncryption8021X: { __typename?: 'Encryption8021X'; UUID: string };
};

export type WirelessConnectionEventsByAccessPointQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type WirelessConnectionEventsByAccessPointQueryQuery = {
  __typename?: 'Query';
  wirelessClientConnectionEventsByAP: Array<{
    __typename?: 'WirelessClientConnectionEvent';
    timestamp: string;
    band: RadioBand;
    eventType: WirelessClientConnectionEventType;
    failedStep?: WirelessClientConnectionFailedStep | null;
    reasonCode?: number | null;
    sessionDuration?: string | null;
    timeToConnect?: string | null;
    macAddress: string;
    is80211rRoam: boolean;
    ssid?: { __typename?: 'SSID'; UUID: string; ssid: string } | null;
    virtualDevice: { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string };
    previousVirtualDeviceConnectedTo?: {
      __typename?: 'AccessPointVirtualDevice';
      UUID: string;
      label: string;
    } | null;
  }>;
};

export type WirelessClientMetricsByAccessPointQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
  filter: ClientMetricsTimeseriesFilterInput;
}>;

export type WirelessClientMetricsByAccessPointQueryQuery = {
  __typename?: 'Query';
  wirelessClientMetricsByAP: {
    __typename?: 'ClientMetricsTimeseriesResponse';
    metadata?: {
      __typename?: 'ClientMetricsMetadata';
      signal?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      noise?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      tx?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
      rx?: { __typename?: 'MetricsMetadata'; minValue: number; maxValue: number } | null;
    } | null;
    values: Array<{
      __typename?: 'ClientMetricsTimeseriesValue';
      timestamp: string;
      signal: number;
      noise: number;
      txRate: number;
      rxRate: number;
      snr: number;
      txBytes: number;
      rxBytes: number;
      txMulticastBytes: number;
      rxMulticastBytes: number;
      txUnicastBytes: number;
      rxUnicastBytes: number;
      clientCount: number;
    }>;
  };
};

export type ApChannelUtilizationByNetworkQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter: ChannelUtilizationTimeseriesFilterInput;
}>;

export type ApChannelUtilizationByNetworkQueryQuery = {
  __typename?: 'Query';
  channelUtilizationByNetwork: Array<{
    __typename?: 'ChannelUtilizationResponseV2';
    band: RadioBand;
    virtualDeviceUUID: string;
    values: Array<{
      __typename?: 'APChannelUtilizationTimeseriesValue';
      totalUtilization: number;
      timestamp: string;
    }>;
  }>;
};

export type ApChannelUtilizationByAccessPointQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  virtualDeviceUUID: Scalars['UUID']['input'];
  filter: ChannelUtilizationTimeseriesFilterInput;
}>;

export type ApChannelUtilizationByAccessPointQueryQuery = {
  __typename?: 'Query';
  channelUtilizationByAP: Array<{
    __typename?: 'ChannelUtilizationResponseV2';
    band: RadioBand;
    values: Array<{
      __typename?: 'APChannelUtilizationTimeseriesValue';
      totalUtilization: number;
      timestamp: string;
    }>;
  }>;
};

export type AccessPointsQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  includeUptime?: InputMaybe<Scalars['Boolean']['input']>;
  includeIsDev?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AccessPointsQueryQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        uptime?: string | null;
        radioProfile?: {
          __typename?: 'RadioProfile';
          name: string;
          UUID: string;
          band2_4GIsEnabled: boolean;
          band2_4GChannelWidthMHz?: number | null;
          band2_4GAutoChannelIsEnabled: boolean;
          band5GIsEnabled: boolean;
          band5GChannelWidthMHz?: number | null;
          band5GAutoChannelIsEnabled: boolean;
        } | null;
        radioSettings: {
          __typename?: 'RadioSettings';
          band2_4GPrimaryChannel: number;
          band2_4GTransmitPowerdBm: number;
          band5GPrimaryChannel: number;
          band5GTransmitPowerdBm: number;
        };
        connectedClients: Array<{
          __typename?: 'WirelessClient';
          macAddress: string;
          band: RadioBand;
          channel: number;
          ssidName: string;
        }>;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                UUID: string;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
                isEthernet: boolean;
                isSFP: boolean;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                UUID: string;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
                isEthernet: boolean;
                isSFP: boolean;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                UUID: string;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
                isEthernet: boolean;
                isSFP: boolean;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                UUID: string;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
                isEthernet: boolean;
                isSFP: boolean;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              isInCurrentControllerMACTable: boolean;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                UUID: string;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
                isEthernet: boolean;
                isSFP: boolean;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              isDev?: boolean;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
  >;
};

export type AccessPointUptimeFragmentFragment = {
  __typename?: 'AccessPointVirtualDevice';
  uptime?: string | null;
};

export type AccessPointQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  includeUptime?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AccessPointQueryQuery = {
  __typename?: 'Query';
  virtualDevice:
    | {
        __typename: 'AccessPointVirtualDevice';
        isLEDDarkModeEnabled: boolean;
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        uptime?: string | null;
        radioProfile?: {
          __typename?: 'RadioProfile';
          UUID: string;
          band2_4GIsEnabled: boolean;
          band2_4GChannelWidthMHz?: number | null;
          band2_4GAutoChannelIsEnabled: boolean;
          band5GIsEnabled: boolean;
          band5GChannelWidthMHz?: number | null;
          band5GAutoChannelIsEnabled: boolean;
        } | null;
        radioSettings: {
          __typename?: 'RadioSettings';
          band2_4GPrimaryChannel: number;
          band2_4GTransmitPowerdBm: number;
          band5GPrimaryChannel: number;
          band5GTransmitPowerdBm: number;
        };
        connectedClients: Array<{
          __typename?: 'WirelessClient';
          macAddress: string;
          band: RadioBand;
          channel: number;
          ssidName: string;
        }>;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                isSFP: boolean;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                isSFP: boolean;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                isSFP: boolean;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                isSFP: boolean;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceType: VirtualDeviceType;
        deviceModel: DeviceModel;
        isConsoleEnabled: boolean;
        networkUUID: string;
        nosVersion?: {
          __typename?: 'ConfiguredNOSVersion';
          id: number;
          version: string;
          buildName: string;
        } | null;
        pendingNosVersion?: {
          __typename?: 'PendingNosVersion';
          scheduledAt: string;
          nosVersion: { __typename?: 'NosVersion'; version: string };
        } | null;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              ipAddress?: string | null;
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              phyInterfaceConnectedTo?: {
                __typename?: 'PhyInterface';
                isSFP: boolean;
                portSpeedMbps?: number | null;
                maxSpeedMbps?: number | null;
              } | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              deviceType: DeviceType;
              serialNumber: string;
              macAddress?: string | null;
              tunnelIPAddress?: string | null;
              deviceModel: DeviceModel;
              isActive: boolean;
              isConnectedToBackend: boolean;
              disconnectedFromBackendAt?: string | null;
              bootHistory?: Array<{ __typename?: 'BootInfo'; buildName: string }> | null;
            }
          | null;
      };
};

export type CopyAccessPointLabelsMutationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type CopyAccessPointLabelsMutationMutation = {
  __typename?: 'Mutation';
  copyAccessPointConfig1LocationsToConfig2Labels: Array<
    | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
    | { __typename?: 'ControllerVirtualDevice'; UUID: string }
    | { __typename?: 'ObserverVirtualDevice'; UUID: string }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string }
  >;
};

export type UpdateVirtualDevicesMutationMutationVariables = Exact<{
  virtualDeviceUUIDs: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
  input: UpdateVirtualDeviceInput;
}>;

export type UpdateVirtualDevicesMutationMutation = {
  __typename?: 'Mutation';
  updateVirtualDevices: Array<
    | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
    | { __typename?: 'ControllerVirtualDevice'; UUID: string }
    | { __typename?: 'ObserverVirtualDevice'; UUID: string }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string }
  >;
};

export type FallbackAccessPointHardwareQueryQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type FallbackAccessPointHardwareQueryQuery = {
  __typename?: 'Query';
  hardwareDevice:
    | {
        __typename?: 'AccessPointHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; label: string }
          | { __typename?: 'ControllerVirtualDevice'; label: string }
          | { __typename?: 'ObserverVirtualDevice'; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; label: string }
          | { __typename?: 'SwitchVirtualDevice'; label: string }
          | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; label: string }
          | { __typename?: 'ControllerVirtualDevice'; label: string }
          | { __typename?: 'ObserverVirtualDevice'; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; label: string }
          | { __typename?: 'SwitchVirtualDevice'; label: string }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; label: string }
          | { __typename?: 'ControllerVirtualDevice'; label: string }
          | { __typename?: 'ObserverVirtualDevice'; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; label: string }
          | { __typename?: 'SwitchVirtualDevice'; label: string }
          | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        serialNumber: string;
        virtualDeviceUUID?: string | null;
        virtualDevice?:
          | { __typename?: 'AccessPointVirtualDevice'; label: string }
          | { __typename?: 'ControllerVirtualDevice'; label: string }
          | { __typename?: 'ObserverVirtualDevice'; label: string }
          | { __typename?: 'PowerDistributionUnitVirtualDevice'; label: string }
          | { __typename?: 'SwitchVirtualDevice'; label: string }
          | null;
      };
};

export type BssiDsForNetworkQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type BssiDsForNetworkQueryQuery = {
  __typename?: 'Query';
  bssidsForNetwork: Array<{
    __typename?: 'BSSID';
    BSSID: string;
    isActive: boolean;
    radioBand: RadioBand;
    SSID: { __typename?: 'SSID'; ssid: string };
    accessPointHardwareDevice: {
      __typename?: 'AccessPointHardwareDevice';
      virtualDevice?:
        | { __typename?: 'AccessPointVirtualDevice'; UUID: string; label: string }
        | { __typename?: 'ControllerVirtualDevice'; UUID: string; label: string }
        | { __typename?: 'ObserverVirtualDevice'; UUID: string; label: string }
        | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string; label: string }
        | { __typename?: 'SwitchVirtualDevice'; UUID: string; label: string }
        | null;
    };
  }>;
};

export type AutoVpnMemberFieldsFragment = {
  __typename: 'AutoVPNMember';
  UUID: string;
  networkUUID: string;
  isFailoverEnabled: boolean;
  network: { __typename?: 'Network'; UUID: string; slug: string; label: string };
  permittedVLANs: Array<{
    __typename: 'VLAN';
    UUID: string;
    name: string;
    vlanID: number;
    ipV4ClientGateway?: string | null;
    ipV4ClientPrefixLength?: number | null;
  }>;
  uplink: {
    __typename?: 'AutoVPNMemberUplink';
    UUID: string;
    address: string;
    addressPrefixLength: number;
    hubUplinkUUID: string;
    publicKey: string;
    listenPort: number;
    phyInterfaceUUID: string;
    phyInterface: {
      __typename: 'PhyInterface';
      UUID: string;
      portNumber: number;
      label?: string | null;
      hardwareLabel?: string | null;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    };
    connectionInfo?: {
      __typename?: 'AutoVPNMemberUplinkConnectionInfo';
      observedAt?: string | null;
      lastHandshakeRx?: string | null;
      lastHandshakeTx?: string | null;
      lastPacketTx?: string | null;
      lastPacketRx?: string | null;
    } | null;
  };
};

export type AutoVpnGroupFieldsFragment = {
  __typename: 'AutoVPNGroup';
  UUID: string;
  name: string;
  isEnabled: boolean;
  hubNetworkUUID: string;
  isHubFailoverEnabled: boolean;
  hubNetwork: { __typename?: 'Network'; UUID: string; slug: string; label: string };
  hubUplink: {
    __typename?: 'AutoVPNHubUplink';
    address: string;
    addressPrefixLength: number;
    phyInterface: {
      __typename: 'PhyInterface';
      UUID: string;
      portNumber: number;
      label?: string | null;
      hardwareLabel?: string | null;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            UUID: string;
            label: string;
            deviceModel: DeviceModel;
          };
      internetServicePlan?: {
        __typename?: 'InternetServicePlan';
        provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
      } | null;
    };
  };
  members?: Array<{
    __typename: 'AutoVPNMember';
    UUID: string;
    networkUUID: string;
    isFailoverEnabled: boolean;
    network: { __typename?: 'Network'; UUID: string; slug: string; label: string };
    permittedVLANs: Array<{
      __typename: 'VLAN';
      UUID: string;
      name: string;
      vlanID: number;
      ipV4ClientGateway?: string | null;
      ipV4ClientPrefixLength?: number | null;
    }>;
    uplink: {
      __typename?: 'AutoVPNMemberUplink';
      UUID: string;
      address: string;
      addressPrefixLength: number;
      hubUplinkUUID: string;
      publicKey: string;
      listenPort: number;
      phyInterfaceUUID: string;
      phyInterface: {
        __typename: 'PhyInterface';
        UUID: string;
        portNumber: number;
        label?: string | null;
        hardwareLabel?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            };
        internetServicePlan?: {
          __typename?: 'InternetServicePlan';
          provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
        } | null;
      };
      connectionInfo?: {
        __typename?: 'AutoVPNMemberUplinkConnectionInfo';
        observedAt?: string | null;
        lastHandshakeRx?: string | null;
        lastHandshakeTx?: string | null;
        lastPacketTx?: string | null;
        lastPacketRx?: string | null;
      } | null;
    };
  }> | null;
  routes?: Array<{
    __typename: 'AutoVPNRoute';
    UUID: string;
    name: string;
    isEnabled: boolean;
    dstPrefixLength: number;
    dstGateway: string;
  }> | null;
};

export type AutoVpnGroupsForCompanyQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
}>;

export type AutoVpnGroupsForCompanyQuery = {
  __typename?: 'Query';
  autoVPNGroupsForCompany: Array<{
    __typename: 'AutoVPNGroup';
    UUID: string;
    name: string;
    isEnabled: boolean;
    hubNetworkUUID: string;
    isHubFailoverEnabled: boolean;
    hubNetwork: { __typename?: 'Network'; UUID: string; slug: string; label: string };
    hubUplink: {
      __typename?: 'AutoVPNHubUplink';
      address: string;
      addressPrefixLength: number;
      phyInterface: {
        __typename: 'PhyInterface';
        UUID: string;
        portNumber: number;
        label?: string | null;
        hardwareLabel?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            };
        internetServicePlan?: {
          __typename?: 'InternetServicePlan';
          provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
        } | null;
      };
    };
    members?: Array<{
      __typename: 'AutoVPNMember';
      UUID: string;
      networkUUID: string;
      isFailoverEnabled: boolean;
      network: { __typename?: 'Network'; UUID: string; slug: string; label: string };
      permittedVLANs: Array<{
        __typename: 'VLAN';
        UUID: string;
        name: string;
        vlanID: number;
        ipV4ClientGateway?: string | null;
        ipV4ClientPrefixLength?: number | null;
      }>;
      uplink: {
        __typename?: 'AutoVPNMemberUplink';
        UUID: string;
        address: string;
        addressPrefixLength: number;
        hubUplinkUUID: string;
        publicKey: string;
        listenPort: number;
        phyInterfaceUUID: string;
        phyInterface: {
          __typename: 'PhyInterface';
          UUID: string;
          portNumber: number;
          label?: string | null;
          hardwareLabel?: string | null;
          virtualDevice:
            | {
                __typename?: 'AccessPointVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ObserverVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              };
          internetServicePlan?: {
            __typename?: 'InternetServicePlan';
            provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
          } | null;
        };
        connectionInfo?: {
          __typename?: 'AutoVPNMemberUplinkConnectionInfo';
          observedAt?: string | null;
          lastHandshakeRx?: string | null;
          lastHandshakeTx?: string | null;
          lastPacketTx?: string | null;
          lastPacketRx?: string | null;
        } | null;
      };
    }> | null;
    routes?: Array<{
      __typename: 'AutoVPNRoute';
      UUID: string;
      name: string;
      isEnabled: boolean;
      dstPrefixLength: number;
      dstGateway: string;
    }> | null;
  }>;
};

export type AutoVpnGroupQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type AutoVpnGroupQuery = {
  __typename?: 'Query';
  autoVPNGroup: {
    __typename: 'AutoVPNGroup';
    UUID: string;
    name: string;
    isEnabled: boolean;
    hubNetworkUUID: string;
    isHubFailoverEnabled: boolean;
    hubNetwork: { __typename?: 'Network'; UUID: string; slug: string; label: string };
    hubUplink: {
      __typename?: 'AutoVPNHubUplink';
      address: string;
      addressPrefixLength: number;
      phyInterface: {
        __typename: 'PhyInterface';
        UUID: string;
        portNumber: number;
        label?: string | null;
        hardwareLabel?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            };
        internetServicePlan?: {
          __typename?: 'InternetServicePlan';
          provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
        } | null;
      };
    };
    members?: Array<{
      __typename: 'AutoVPNMember';
      UUID: string;
      networkUUID: string;
      isFailoverEnabled: boolean;
      network: { __typename?: 'Network'; UUID: string; slug: string; label: string };
      permittedVLANs: Array<{
        __typename: 'VLAN';
        UUID: string;
        name: string;
        vlanID: number;
        ipV4ClientGateway?: string | null;
        ipV4ClientPrefixLength?: number | null;
      }>;
      uplink: {
        __typename?: 'AutoVPNMemberUplink';
        UUID: string;
        address: string;
        addressPrefixLength: number;
        hubUplinkUUID: string;
        publicKey: string;
        listenPort: number;
        phyInterfaceUUID: string;
        phyInterface: {
          __typename: 'PhyInterface';
          UUID: string;
          portNumber: number;
          label?: string | null;
          hardwareLabel?: string | null;
          virtualDevice:
            | {
                __typename?: 'AccessPointVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ObserverVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchVirtualDevice';
                UUID: string;
                label: string;
                deviceModel: DeviceModel;
              };
          internetServicePlan?: {
            __typename?: 'InternetServicePlan';
            provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
          } | null;
        };
        connectionInfo?: {
          __typename?: 'AutoVPNMemberUplinkConnectionInfo';
          observedAt?: string | null;
          lastHandshakeRx?: string | null;
          lastHandshakeTx?: string | null;
          lastPacketTx?: string | null;
          lastPacketRx?: string | null;
        } | null;
      };
    }> | null;
    routes?: Array<{
      __typename: 'AutoVPNRoute';
      UUID: string;
      name: string;
      isEnabled: boolean;
      dstPrefixLength: number;
      dstGateway: string;
    }> | null;
  };
};

export type AutoVpnMemberQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type AutoVpnMemberQueryQuery = {
  __typename?: 'Query';
  autoVPNMember: {
    __typename: 'AutoVPNMember';
    UUID: string;
    networkUUID: string;
    isFailoverEnabled: boolean;
    network: { __typename?: 'Network'; UUID: string; slug: string; label: string };
    permittedVLANs: Array<{
      __typename: 'VLAN';
      UUID: string;
      name: string;
      vlanID: number;
      ipV4ClientGateway?: string | null;
      ipV4ClientPrefixLength?: number | null;
    }>;
    uplink: {
      __typename?: 'AutoVPNMemberUplink';
      UUID: string;
      address: string;
      addressPrefixLength: number;
      hubUplinkUUID: string;
      publicKey: string;
      listenPort: number;
      phyInterfaceUUID: string;
      phyInterface: {
        __typename: 'PhyInterface';
        UUID: string;
        portNumber: number;
        label?: string | null;
        hardwareLabel?: string | null;
        virtualDevice:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              deviceModel: DeviceModel;
            };
        internetServicePlan?: {
          __typename?: 'InternetServicePlan';
          provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
        } | null;
      };
      connectionInfo?: {
        __typename?: 'AutoVPNMemberUplinkConnectionInfo';
        observedAt?: string | null;
        lastHandshakeRx?: string | null;
        lastHandshakeTx?: string | null;
        lastPacketTx?: string | null;
        lastPacketRx?: string | null;
      } | null;
    };
  };
};

export type CreateAutoVpnGroupMutationVariables = Exact<{
  companySlug: Scalars['String']['input'];
  input: CreateAutoVpnGroupInput;
}>;

export type CreateAutoVpnGroupMutation = {
  __typename?: 'Mutation';
  createAutoVPNGroup: {
    __typename?: 'AutoVPNGroup';
    UUID: string;
    companySlug: string;
    isEnabled: boolean;
    name: string;
    hubNetworkUUID: string;
  };
};

export type UpdateAutoVpnGroupMutationVariables = Exact<{
  UUID: Scalars['UUID']['input'];
  input: UpdateAutoVpnGroupInput;
}>;

export type UpdateAutoVpnGroupMutation = {
  __typename?: 'Mutation';
  updateAutoVPNGroup: { __typename?: 'AutoVPNGroup'; UUID: string };
};

export type DeleteAutoVpnGroupMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteAutoVpnGroupMutation = {
  __typename?: 'Mutation';
  deleteAutoVPNGroup: {
    __typename?: 'AutoVPNGroup';
    UUID: string;
    companySlug: string;
    isEnabled: boolean;
    name: string;
    hubNetworkUUID: string;
  };
};

export type AddNetworkToAutoVpnGroupMutationVariables = Exact<{
  groupUUID: Scalars['UUID']['input'];
  input: AddNetworkToAutoVpnGroupInput;
}>;

export type AddNetworkToAutoVpnGroupMutation = {
  __typename?: 'Mutation';
  addNetworkToAutoVPNGroup: { __typename?: 'AutoVPNMember'; UUID: string };
};

export type UpdateAutoVpnMemberMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateAutoVpnMemberInput;
}>;

export type UpdateAutoVpnMemberMutation = {
  __typename?: 'Mutation';
  updateAutoVPNMember: { __typename?: 'AutoVPNMember'; UUID: string };
};

export type RemoveNetworkFromAutoVpnGroupMutationVariables = Exact<{
  groupUUID: Scalars['UUID']['input'];
  networkUUID: Scalars['UUID']['input'];
}>;

export type RemoveNetworkFromAutoVpnGroupMutation = {
  __typename?: 'Mutation';
  removeNetworkFromAutoVPNGroup: boolean;
};

export type CreateAutoVpnRouteMutationVariables = Exact<{
  groupUUID: Scalars['UUID']['input'];
  input: CreateAutoVpnRouteInput;
}>;

export type CreateAutoVpnRouteMutation = {
  __typename?: 'Mutation';
  createAutoVPNRoute: {
    __typename?: 'AutoVPNRoute';
    UUID: string;
    createdAt: string;
    updatedAt: string;
    dstGateway: string;
    dstPrefixLength: number;
  };
};

export type DeleteAutoVpnRouteMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteAutoVpnRouteMutation = {
  __typename?: 'Mutation';
  deleteAutoVPNRoute: { __typename?: 'AutoVPNRoute'; UUID: string };
};

export type NetworksWithControllerPortsQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
}>;

export type NetworksWithControllerPortsQuery = {
  __typename?: 'Query';
  networksForCompany: Array<{
    __typename?: 'Network';
    UUID: string;
    label: string;
    isActive: boolean;
    isTemplate: boolean;
    virtualDevices: Array<
      | { __typename: 'AccessPointVirtualDevice'; label: string }
      | {
          __typename: 'ControllerVirtualDevice';
          label: string;
          phyInterfaces: Array<{
            __typename: 'PhyInterface';
            UUID: string;
            isUplink: boolean;
            portNumber: number;
            label?: string | null;
            hardwareLabel?: string | null;
            virtualDevice:
              | {
                  __typename?: 'AccessPointVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'ControllerVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'ObserverVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'PowerDistributionUnitVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceModel: DeviceModel;
                }
              | {
                  __typename?: 'SwitchVirtualDevice';
                  UUID: string;
                  label: string;
                  deviceModel: DeviceModel;
                };
            internetServicePlan?: {
              __typename?: 'InternetServicePlan';
              provider?: { __typename?: 'InternetServicePlanProvider'; name: string } | null;
            } | null;
          }>;
        }
      | { __typename: 'ObserverVirtualDevice'; label: string }
      | { __typename: 'PowerDistributionUnitVirtualDevice'; label: string }
      | { __typename: 'SwitchVirtualDevice'; label: string }
    >;
  }>;
};

export type CreateNosFirmwareUpgradeMutationMutationVariables = Exact<{
  input: CreateBulkNosUpgradeInput;
}>;

export type CreateNosFirmwareUpgradeMutationMutation = {
  __typename?: 'Mutation';
  createBulkNOSUpgrade: { __typename?: 'BulkNOSUpgrade'; UUID: string };
};

export type BulkNosUpgradeQueryQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type BulkNosUpgradeQueryQuery = {
  __typename?: 'Query';
  bulkNOSUpgrade: {
    __typename?: 'BulkNOSUpgrade';
    UUID: string;
    createdAt: string;
    updatedAt: string;
    updatedBy: string;
    nosVersionID: number;
    networks: Array<{
      __typename: 'Network';
      UUID: string;
      label: string;
      slug: string;
      companySlug?: string | null;
      company?: { __typename?: 'Company'; name: string } | null;
    }>;
  };
};

export type BulkNosUpgradesQueryQueryVariables = Exact<{
  filter: BulkNosUpgradesFilterInput;
}>;

export type BulkNosUpgradesQueryQuery = {
  __typename?: 'Query';
  bulkNOSUpgrades: Array<{
    __typename?: 'BulkNOSUpgrade';
    UUID: string;
    createdAt: string;
    updatedAt: string;
    updatedBy: string;
    nosVersionID: number;
    networks: Array<{ __typename?: 'Network'; UUID: string; label: string }>;
  }>;
};

export type NetworksByFilterQueryQueryVariables = Exact<{
  filter: NetworksFilter;
}>;

export type NetworksByFilterQueryQuery = {
  __typename?: 'Query';
  networks: Array<{
    __typename: 'Network';
    UUID: string;
    label: string;
    slug: string;
    companySlug?: string | null;
    pinnedNOSVersionID: number;
    pendingNOSVersionID?: number | null;
    lastUpgradedAt?: string | null;
    isUpgradeSensitive: boolean;
    isActive: boolean;
    company?: {
      __typename?: 'Company';
      name: string;
      supportTier?: CompanySupportTier | null;
    } | null;
    virtualDevices: Array<
      | {
          __typename?: 'AccessPointVirtualDevice';
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | null;
        }
      | {
          __typename?: 'ControllerVirtualDevice';
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | null;
        }
      | {
          __typename?: 'ObserverVirtualDevice';
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | null;
        }
      | {
          __typename?: 'PowerDistributionUnitVirtualDevice';
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | null;
        }
      | {
          __typename?: 'SwitchVirtualDevice';
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                deviceType: DeviceType;
                deviceModel: DeviceModel;
              }
            | null;
        }
    >;
  }>;
};

export type GetEventLogsQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type GetEventLogsQuery = {
  __typename?: 'Query';
  recentEventLogEvents?: Array<{
    __typename?: 'EventLogEvent';
    eventType: EventType;
    generatedAt: string;
    networkUUID: string;
    properties:
      | { __typename?: 'WANDownProperties' }
      | {
          __typename?: 'WANStatusChangeProperties';
          virtualDevice:
            | { __typename: 'AccessPointVirtualDevice'; UUID: string }
            | {
                __typename: 'ControllerVirtualDevice';
                UUID: string;
                phyInterfaces: Array<{
                  __typename?: 'PhyInterface';
                  UUID: string;
                  portNumber: number;
                  uplinkPriority?: number | null;
                  isUplink: boolean;
                }>;
              }
            | { __typename: 'ObserverVirtualDevice'; UUID: string }
            | { __typename: 'PowerDistributionUnitVirtualDevice'; UUID: string }
            | { __typename: 'SwitchVirtualDevice'; UUID: string };
          activeWANConnections: Array<{
            __typename?: 'WANConnection';
            externalAddresses: Array<string>;
            port: number;
            internetServicePlanProvider?: {
              __typename?: 'InternetServicePlanProvider';
              name: string;
            } | null;
          } | null>;
        }
      | { __typename?: 'WANUpProperties' };
  }> | null;
};

export type NetworkClientsQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter?: InputMaybe<NetworkClientsFilter>;
}>;

export type NetworkClientsQuery = {
  __typename?: 'Query';
  networkClients: Array<{
    __typename?: 'NetworkClient';
    alias?: string | null;
    apLocation?: string | null;
    apSerialNumber?: string | null;
    channel?: number | null;
    clientName?: string | null;
    ip: string;
    isWireless: boolean;
    lastSeen: string;
    dhcpLeaseExpiresAt?: string | null;
    macAddress: string;
    noise?: number | null;
    totalRxBytes?: number | null;
    rxRate?: number | null;
    signal?: number | null;
    ssid?: string | null;
    txRate?: number | null;
    totalTxBytes?: number | null;
    vlan?: string | null;
    vlanUUID?: string | null;
    associatedAt?: string | null;
    serviceTypes?: Array<ServiceType> | null;
    wirelessScore?: number | null;
    connectedVLAN?: { __typename?: 'VLAN'; UUID: string; name: string; vlanID: number } | null;
    throughput?: {
      __typename?: 'NetworkClientThroughput';
      txRate?: number | null;
      rxRate?: number | null;
    } | null;
  }>;
};

export type GetCaptivePortalQueryVariables = Exact<{
  vlanUUID: Scalars['UUID']['input'];
}>;

export type GetCaptivePortalQuery = {
  __typename?: 'Query';
  captivePortalForVLAN?: {
    __typename?: 'CaptivePortal';
    logoImageURL?: string | null;
    redirectURL?: string | null;
    termsAndConditions?: string | null;
    displaySettings?: {
      __typename?: 'CaptivePortalDisplaySettings';
      fontColor?: string | null;
    } | null;
    vlans?: Array<{ __typename?: 'VLAN'; UUID: string }> | null;
  } | null;
};

export type GetNetworkClientsTxRxPerDayQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  numDaysLookback: Scalars['Int']['input'];
}>;

export type GetNetworkClientsTxRxPerDayQuery = {
  __typename?: 'Query';
  networkClientsTxRxPerDay: Array<{
    __typename?: 'NetworkClientTxRxPerDay';
    ip: string;
    macAddress: string;
    dateString: string;
    txBytes: number;
    rxBytes: number;
  }>;
};

export type CompanyQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type CompanyQuery = {
  __typename?: 'Query';
  getCompany?: {
    __typename?: 'Company';
    uuid: string;
    name: string;
    slug: string;
    isCustomer: boolean;
    supportGroup?: number | null;
  } | null;
};

export type NetworkStatusQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type NetworkStatusQueryQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename?: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename?: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename?: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename?: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        description?: string | null;
        deviceModel: DeviceModel;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              macAddress?: string | null;
              isConnectedToBackend: boolean;
            }
          | null;
      }
  >;
};

export type NetworkForCompanyFieldsFragment = {
  __typename?: 'Network';
  UUID: string;
  label: string;
  slug: string;
  isActive: boolean;
  isCustomer: boolean;
  isTemplate: boolean;
  isTrial: boolean;
  enabledServiceDiscoveryTypes: Array<ServiceType>;
  multiWANAlgorithm?: MultiWanAlgorithm | null;
  pinnedNOSVersionID: number;
  company?: {
    __typename?: 'Company';
    slug: string;
    name: string;
    uuid: string;
    supportGroup?: number | null;
  } | null;
  virtualDevices: Array<
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        highAvailability?: {
          __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
          role: HighAvailabilityControllerRole;
          status: HighAvailabilityStatus;
        } | null;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        hardwareDevice?:
          | {
              __typename?: 'AccessPointHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'ControllerHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename?: 'SwitchHardwareDevice';
              serialNumber: string;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
  >;
  mailingAddress?: {
    __typename?: 'MailingAddress';
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    subdivisionCode?: string | null;
    postalCode?: string | null;
    timezone?: string | null;
    countryISO2?: string | null;
  } | null;
};

export type NetworksForCompanyQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
}>;

export type NetworksForCompanyQuery = {
  __typename?: 'Query';
  networksForCompany: Array<{
    __typename?: 'Network';
    UUID: string;
    label: string;
    slug: string;
    isActive: boolean;
    isCustomer: boolean;
    isTemplate: boolean;
    isTrial: boolean;
    enabledServiceDiscoveryTypes: Array<ServiceType>;
    multiWANAlgorithm?: MultiWanAlgorithm | null;
    pinnedNOSVersionID: number;
    company?: {
      __typename?: 'Company';
      slug: string;
      name: string;
      uuid: string;
      supportGroup?: number | null;
    } | null;
    virtualDevices: Array<
      | {
          __typename: 'AccessPointVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
      | {
          __typename: 'ControllerVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          highAvailability?: {
            __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
            role: HighAvailabilityControllerRole;
            status: HighAvailabilityStatus;
          } | null;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
      | {
          __typename: 'ObserverVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
      | {
          __typename: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
      | {
          __typename: 'SwitchVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
    >;
    mailingAddress?: {
      __typename?: 'MailingAddress';
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      subdivisionCode?: string | null;
      postalCode?: string | null;
      timezone?: string | null;
      countryISO2?: string | null;
    } | null;
  }>;
};

export type NetworkByUuidQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type NetworkByUuidQueryQuery = {
  __typename?: 'Query';
  network: {
    __typename?: 'Network';
    UUID: string;
    label: string;
    slug: string;
    isActive: boolean;
    isCustomer: boolean;
    isTemplate: boolean;
    isTrial: boolean;
    enabledServiceDiscoveryTypes: Array<ServiceType>;
    multiWANAlgorithm?: MultiWanAlgorithm | null;
    pinnedNOSVersionID: number;
    company?: {
      __typename?: 'Company';
      slug: string;
      name: string;
      uuid: string;
      supportGroup?: number | null;
    } | null;
    virtualDevices: Array<
      | {
          __typename: 'AccessPointVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
      | {
          __typename: 'ControllerVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          highAvailability?: {
            __typename?: 'HighAvailabilityControllerVirtualDeviceInfo';
            role: HighAvailabilityControllerRole;
            status: HighAvailabilityStatus;
          } | null;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
      | {
          __typename: 'ObserverVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
      | {
          __typename: 'PowerDistributionUnitVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
      | {
          __typename: 'SwitchVirtualDevice';
          UUID: string;
          label: string;
          deviceType: VirtualDeviceType;
          hardwareDevice?:
            | {
                __typename?: 'AccessPointHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'ControllerHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'PowerDistributionUnitHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | {
                __typename?: 'SwitchHardwareDevice';
                serialNumber: string;
                isActive: boolean;
                isConnectedToBackend: boolean;
              }
            | null;
        }
    >;
    mailingAddress?: {
      __typename?: 'MailingAddress';
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      subdivisionCode?: string | null;
      postalCode?: string | null;
      timezone?: string | null;
      countryISO2?: string | null;
    } | null;
  };
};

export type IsNosFeatureEnabledForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  nosFeatureKey: Scalars['String']['input'];
}>;

export type IsNosFeatureEnabledForNetworkQuery = {
  __typename?: 'Query';
  isNosFeatureEnabledForNetwork: boolean;
};

export type AreNosFeatureEnabledForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  nosFeatureKeys: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AreNosFeatureEnabledForNetworkQuery = {
  __typename?: 'Query';
  areNosFeaturesEnabledForNetwork: Array<{
    __typename?: 'NosFeatureEnabled';
    key: string;
    enabled: boolean;
  }>;
};

export type IsNosFeatureEnabledForCompanyQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
  nosFeatureKey: Scalars['String']['input'];
}>;

export type IsNosFeatureEnabledForCompanyQuery = {
  __typename?: 'Query';
  isNosFeatureEnabledForCompany: boolean;
};

export type AreNosFeatureEnabledForCompanyQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
  nosFeatureKeys: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AreNosFeatureEnabledForCompanyQuery = {
  __typename?: 'Query';
  areNosFeaturesEnabledForCompany: Array<{
    __typename?: 'NosFeatureEnabled';
    key: string;
    enabled: boolean;
  }>;
};

export type ValidChannelsQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type ValidChannelsQueryQuery = {
  __typename?: 'Query';
  validChannels: Array<{
    __typename?: 'ValidChannelsResponse';
    band: RadioBand;
    channels: Array<number>;
  }>;
};

export type CompanyUuidQueryQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type CompanyUuidQueryQuery = {
  __typename?: 'Query';
  getCompany?: { __typename: 'Company'; uuid: string } | null;
};

export type PermissionsQueryVariables = Exact<{
  input: PermissionsInput;
}>;

export type PermissionsQuery = {
  __typename?: 'Query';
  permissions: Array<{
    __typename?: 'PermissionAssignment';
    networkUUID?: string | null;
    permissions: Array<PermissionType>;
  }>;
};

export type IpSecTunnelsQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type IpSecTunnelsQueryQuery = {
  __typename?: 'Query';
  ipSecTunnelsForNetwork: Array<{
    __typename?: 'IPSecTunnel';
    UUID: string;
    name: string;
    isEnabled: boolean;
    isInitiator: boolean;
    authenticationAlgorithm?: IpSecAuthenticationAlgorithm | null;
    encryptionAlgorithm?: IpSecEncryptionAlgorithm | null;
    keyExchangeDHGroup?: IpSecDhGroup | null;
    right?: string | null;
    rightPrefixes: Array<string>;
    rightID: string;
    leftID: string;
    boundPhyInterface: {
      __typename?: 'PhyInterface';
      UUID: string;
      label?: string | null;
      portNumber: number;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | null;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | null;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | null;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | null;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                }
              | null;
          };
    };
    boundVLANs: Array<{ __typename?: 'VLAN'; UUID: string; vlanID: number; name: string }>;
    status?: {
      __typename?: 'IPSecTunnelStatus';
      observedAt: string;
      saStatus: IpSecSaStatus;
      uniqueID: string;
      remoteHostIP4Address: string;
      establishedAt: string;
      rekeyAt: string;
      reauthAt: string;
      childSAStatus: IpSecChildSaStatus;
      rxBytes: number;
      txBytes: number;
      rxPackets: number;
      rxLast: string;
      txLast: string;
      childRekeyAt: string;
      childLifetime: string;
      latencyMs: number;
    } | null;
  }>;
};

export type IpSecTunnelQueryQueryVariables = Exact<{
  UUID: Scalars['UUID']['input'];
}>;

export type IpSecTunnelQueryQuery = {
  __typename?: 'Query';
  ipSecTunnel: {
    __typename?: 'IPSecTunnel';
    UUID: string;
    name: string;
    isEnabled: boolean;
    right?: string | null;
    rightPrefixes: Array<string>;
    rightID: string;
    leftID: string;
    isInitiator: boolean;
    authenticationAlgorithm?: IpSecAuthenticationAlgorithm | null;
    encryptionAlgorithm?: IpSecEncryptionAlgorithm | null;
    keyExchangeDHGroup?: IpSecDhGroup | null;
    boundPhyInterface: {
      __typename?: 'PhyInterface';
      UUID: string;
      portNumber: number;
      virtualDevice:
        | {
            __typename?: 'AccessPointVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | null;
          }
        | {
            __typename?: 'ControllerVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | null;
          }
        | {
            __typename?: 'ObserverVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | null;
          }
        | {
            __typename?: 'PowerDistributionUnitVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | null;
          }
        | {
            __typename?: 'SwitchVirtualDevice';
            label: string;
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  isSpare: boolean;
                  isConnectedToBackend: boolean;
                  deviceType: DeviceType;
                  serialNumber: string;
                }
              | null;
          };
    };
    boundVLANs: Array<{ __typename?: 'VLAN'; UUID: string; vlanID: number; name: string }>;
    status?: {
      __typename?: 'IPSecTunnelStatus';
      observedAt: string;
      saStatus: IpSecSaStatus;
      uniqueID: string;
      remoteHostIP4Address: string;
      establishedAt: string;
      rekeyAt: string;
      reauthAt: string;
      childSAStatus: IpSecChildSaStatus;
      rxBytes: number;
      txBytes: number;
      rxPackets: number;
      rxLast: string;
      txLast: string;
      childRekeyAt: string;
      childLifetime: string;
      latencyMs: number;
    } | null;
  };
};

export type UpdateIpSecTunnelMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateIpSecTunnelInput;
}>;

export type UpdateIpSecTunnelMutation = {
  __typename?: 'Mutation';
  updateIPSecTunnel: { __typename?: 'IPSecTunnel'; UUID: string };
};

export type DeleteIpSecTunnelMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteIpSecTunnelMutation = {
  __typename?: 'Mutation';
  deleteIPSecTunnel: { __typename?: 'IPSecTunnel'; UUID: string };
};

export type CreateIpSecTunnelMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CreateIpSecTunnelInput;
}>;

export type CreateIpSecTunnelMutation = {
  __typename?: 'Mutation';
  createIPSecTunnel: { __typename?: 'IPSecTunnel'; UUID: string };
};

export type RpcRestartIpSecTunnelMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  ipSecTunnelUUID: Scalars['UUID']['input'];
}>;

export type RpcRestartIpSecTunnelMutation = {
  __typename?: 'Mutation';
  rpcRestartIPSecTunnel: boolean;
};

export type DeleteNosFirmwareUpgradeMutationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteNosFirmwareUpgradeMutationMutation = {
  __typename?: 'Mutation';
  deleteBulkNOSUpgrade: { __typename?: 'BulkNOSUpgrade'; UUID: string };
};

export type SwitchCountQueryQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type SwitchCountQueryQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | { __typename?: 'AccessPointVirtualDevice'; UUID: string }
    | { __typename?: 'ControllerVirtualDevice'; UUID: string }
    | { __typename?: 'ObserverVirtualDevice'; UUID: string }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; UUID: string }
    | { __typename?: 'SwitchVirtualDevice'; UUID: string }
  >;
};

export type VirtualDevicesForTopologyQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type VirtualDevicesForTopologyQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename: 'AccessPointVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename: 'ControllerVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        phyInterfaces: Array<{
          __typename?: 'PhyInterface';
          virtualDeviceUUID: string;
          isUplink: boolean;
          isUplinkActive?: boolean | null;
          connectedDevices?: Array<{
            __typename?: 'PhyInterfaceConnectedDevice';
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  isActive: boolean;
                  virtualDeviceUUID?: string | null;
                  isConnectedToBackend: boolean;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  isActive: boolean;
                  virtualDeviceUUID?: string | null;
                  isConnectedToBackend: boolean;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  isActive: boolean;
                  virtualDeviceUUID?: string | null;
                  isConnectedToBackend: boolean;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  isActive: boolean;
                  virtualDeviceUUID?: string | null;
                  isConnectedToBackend: boolean;
                }
              | null;
          }> | null;
          internetServicePlan?: {
            __typename?: 'InternetServicePlan';
            provider?: {
              __typename?: 'InternetServicePlanProvider';
              UUID: string;
              name: string;
            } | null;
          } | null;
        }>;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename: 'ObserverVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename: 'PowerDistributionUnitVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
    | {
        __typename: 'SwitchVirtualDevice';
        UUID: string;
        label: string;
        deviceType: VirtualDeviceType;
        phyInterfaces: Array<{
          __typename?: 'PhyInterface';
          isUplink: boolean;
          connectedDevices?: Array<{
            __typename?: 'PhyInterfaceConnectedDevice';
            hardwareDevice?:
              | {
                  __typename?: 'AccessPointHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  isActive: boolean;
                  virtualDeviceUUID?: string | null;
                  isConnectedToBackend: boolean;
                }
              | {
                  __typename?: 'ControllerHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  isActive: boolean;
                  virtualDeviceUUID?: string | null;
                  isConnectedToBackend: boolean;
                }
              | {
                  __typename?: 'PowerDistributionUnitHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  isActive: boolean;
                  virtualDeviceUUID?: string | null;
                  isConnectedToBackend: boolean;
                }
              | {
                  __typename?: 'SwitchHardwareDevice';
                  serialNumber: string;
                  deviceType: DeviceType;
                  isActive: boolean;
                  virtualDeviceUUID?: string | null;
                  isConnectedToBackend: boolean;
                }
              | null;
          }> | null;
        }>;
        hardwareDevice?:
          | {
              __typename: 'AccessPointHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'ControllerHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'PowerDistributionUnitHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | {
              __typename: 'SwitchHardwareDevice';
              serialNumber: string;
              deviceType: DeviceType;
              isActive: boolean;
              isConnectedToBackend: boolean;
            }
          | null;
      }
  >;
};

export type MutationAuditLogEntriesForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter?: InputMaybe<MutationAuditLogFilter>;
}>;

export type MutationAuditLogEntriesForNetworkQuery = {
  __typename?: 'Query';
  mutationAuditLogEntriesForNetwork: Array<{
    __typename?: 'MutationAuditLogEntry';
    UUID: string;
    networkUUID: string;
    createdAt: string;
    username: string;
    action: string;
    args: Record<string, unknown>;
    reason?: string | null;
  }>;
};

export type StatsForVirtualDevicesQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  filter: NetworkStatsFilter;
}>;

export type StatsForVirtualDevicesQuery = {
  __typename?: 'Query';
  statsForVirtualDevices: Array<{
    __typename?: 'Stat';
    observedAt: string;
    insertedAt?: string | null;
    networkUUID: string;
    virtualDeviceUUID: string;
    type: StatType;
    rawData: Record<string, unknown>;
  }>;
};

export type OnboardingForNetworkQueryVariables = Exact<{
  networkSlug: Scalars['String']['input'];
  companySlug: Scalars['String']['input'];
}>;

export type OnboardingForNetworkQuery = {
  __typename?: 'Query';
  networkBySlug: {
    __typename?: 'Network';
    UUID: string;
    onboarding?: {
      __typename?: 'NetworkOnboarding';
      networkUUID: string;
      jobData?: {
        __typename?: 'NetworkOnboardingJobData';
        fullAddress?: Array<string> | null;
        installationStartDate?: string | null;
        installationEndDate?: string | null;
        jobOwnerUser?: string | null;
        jobStage?: string | null;
        jobStatus?: string | null;
        jobTechnicianName?: Array<string> | null;
        jobTechnicianPhone?: Array<string> | null;
        onsiteContactInformation?: string | null;
        scheduleSiteSurveyLink?: Array<string> | null;
        siteSurveyDate?: Array<string> | null;
        siteSurveyStatus?: Array<string> | null;
        targetGoLiveDate?: string | null;
        totalSqFt?: Array<string> | null;
      } | null;
    } | null;
  };
};

export type NetworkForOnboardingDocumentQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type NetworkForOnboardingDocumentQuery = {
  __typename?: 'Query';
  network: {
    __typename?: 'Network';
    UUID: string;
    onboardingDocument?: {
      __typename?: 'NetworkOnboardingDocument';
      document: Record<string, unknown>;
      delegations: Array<{
        __typename?: 'NetworkOnboardingFieldDelegation';
        UUID: string;
        fieldName: string;
        email: string;
        description?: string | null;
      }>;
    } | null;
  };
};

export type UpsertNetworkOnboardingDocumentMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  document: Scalars['JSONObject']['input'];
}>;

export type UpsertNetworkOnboardingDocumentMutation = {
  __typename?: 'Mutation';
  upsertNetworkOnboardingDocument: { __typename?: 'NetworkOnboardingDocument'; UUID: string };
};

export type UpsertNetworkOnboardingFieldDelegationMutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  fieldName: Scalars['String']['input'];
  input: UpsertNetworkOnboardingFieldDelegationInput;
}>;

export type UpsertNetworkOnboardingFieldDelegationMutation = {
  __typename?: 'Mutation';
  upsertNetworkOnboardingFieldDelegation: {
    __typename?: 'NetworkOnboardingFieldDelegation';
    UUID: string;
  };
};

export type DeleteNetworkOnboardingFieldDelegationMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type DeleteNetworkOnboardingFieldDelegationMutation = {
  __typename?: 'Mutation';
  deleteNetworkOnboardingFieldDelegation: {
    __typename?: 'NetworkOnboardingFieldDelegation';
    UUID: string;
  };
};

export type LatestDeviceConfigQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type LatestDeviceConfigQuery = {
  __typename?: 'Query';
  latestDeviceConfig: { __typename?: 'DeviceConfig'; configJSON: Record<string, unknown> };
};

export type DeviceConfigOverrideQueryVariables = Exact<{
  serialNumber: Scalars['String']['input'];
}>;

export type DeviceConfigOverrideQuery = {
  __typename?: 'Query';
  deviceConfigOverride: {
    __typename?: 'DeviceConfigOverride';
    configJSON: Record<string, unknown>;
  };
};

export type UpdateDeviceConfigOverrideMutationVariables = Exact<{
  serialNumber: Scalars['String']['input'];
  configJSON: Scalars['String']['input'];
}>;

export type UpdateDeviceConfigOverrideMutation = {
  __typename?: 'Mutation';
  upsertDeviceConfigOverride: boolean;
};

export type DeviceForNetworkQueryVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
}>;

export type DeviceForNetworkQuery = {
  __typename?: 'Query';
  virtualDevicesForNetwork: Array<
    | {
        __typename?: 'AccessPointVirtualDevice';
        label: string;
        hardwareDevice?:
          | { __typename?: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename?: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename?: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | { __typename?: 'SwitchHardwareDevice'; serialNumber: string }
          | null;
      }
    | {
        __typename?: 'ControllerVirtualDevice';
        label: string;
        hardwareDevice?:
          | { __typename?: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename?: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename?: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | { __typename?: 'SwitchHardwareDevice'; serialNumber: string }
          | null;
      }
    | {
        __typename?: 'ObserverVirtualDevice';
        label: string;
        hardwareDevice?:
          | { __typename?: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename?: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename?: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | { __typename?: 'SwitchHardwareDevice'; serialNumber: string }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitVirtualDevice';
        label: string;
        hardwareDevice?:
          | { __typename?: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename?: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename?: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | { __typename?: 'SwitchHardwareDevice'; serialNumber: string }
          | null;
      }
    | {
        __typename?: 'SwitchVirtualDevice';
        label: string;
        hardwareDevice?:
          | { __typename?: 'AccessPointHardwareDevice'; serialNumber: string }
          | { __typename?: 'ControllerHardwareDevice'; serialNumber: string }
          | { __typename?: 'PowerDistributionUnitHardwareDevice'; serialNumber: string }
          | { __typename?: 'SwitchHardwareDevice'; serialNumber: string }
          | null;
      }
  >;
};

export type CopyIpSecTunnelFromConfig1ToConfig2MutationVariables = Exact<{
  networkUUID: Scalars['UUID']['input'];
  input: CopyIpSecTunnelFromConfig1ToConfig2Input;
}>;

export type CopyIpSecTunnelFromConfig1ToConfig2Mutation = {
  __typename?: 'Mutation';
  copyIPSecTunnelFromConfig1ToConfig2: { __typename?: 'IPSecTunnel'; UUID: string };
};

export type NetworkFieldsFragment = {
  __typename?: 'Network';
  UUID: string;
  slug: string;
  label: string;
  companySlug?: string | null;
  isActive: boolean;
  isUpgradeSensitive: boolean;
  isTemplate: boolean;
  lastUpgradedAt?: string | null;
  highAvailabilityConfig?: {
    __typename?: 'HighAvailabilityControllersConfig';
    UUID: string;
  } | null;
  virtualDevices: Array<
    | { __typename?: 'AccessPointVirtualDevice'; deviceType: VirtualDeviceType }
    | { __typename?: 'ControllerVirtualDevice'; deviceType: VirtualDeviceType }
    | { __typename?: 'ObserverVirtualDevice'; deviceType: VirtualDeviceType }
    | { __typename?: 'PowerDistributionUnitVirtualDevice'; deviceType: VirtualDeviceType }
    | { __typename?: 'SwitchVirtualDevice'; deviceType: VirtualDeviceType }
  >;
  mailingAddress?: {
    __typename?: 'MailingAddress';
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    subdivisionCode?: string | null;
    postalCode?: string | null;
    countryISO2?: string | null;
  } | null;
};

export type CompaniesQueryQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;

export type CompaniesQueryQuery = {
  __typename?: 'Query';
  listCompanies: Array<{
    __typename?: 'Company';
    uuid: string;
    isCustomer: boolean;
    supportTier?: CompanySupportTier | null;
    slug: string;
    name: string;
    networks: Array<{
      __typename?: 'Network';
      UUID: string;
      slug: string;
      label: string;
      companySlug?: string | null;
      isActive: boolean;
      isUpgradeSensitive: boolean;
      isTemplate: boolean;
      lastUpgradedAt?: string | null;
      highAvailabilityConfig?: {
        __typename?: 'HighAvailabilityControllersConfig';
        UUID: string;
      } | null;
      virtualDevices: Array<
        | { __typename?: 'AccessPointVirtualDevice'; deviceType: VirtualDeviceType }
        | { __typename?: 'ControllerVirtualDevice'; deviceType: VirtualDeviceType }
        | { __typename?: 'ObserverVirtualDevice'; deviceType: VirtualDeviceType }
        | { __typename?: 'PowerDistributionUnitVirtualDevice'; deviceType: VirtualDeviceType }
        | { __typename?: 'SwitchVirtualDevice'; deviceType: VirtualDeviceType }
      >;
      mailingAddress?: {
        __typename?: 'MailingAddress';
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        subdivisionCode?: string | null;
        postalCode?: string | null;
        countryISO2?: string | null;
      } | null;
    }>;
  }>;
};

export type CompanyQueryQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type CompanyQueryQuery = {
  __typename?: 'Query';
  getCompany?: {
    __typename?: 'Company';
    uuid: string;
    isCustomer: boolean;
    supportTier?: CompanySupportTier | null;
    slug: string;
    name: string;
    networks: Array<{
      __typename?: 'Network';
      UUID: string;
      slug: string;
      label: string;
      companySlug?: string | null;
      isActive: boolean;
      isUpgradeSensitive: boolean;
      isTemplate: boolean;
      lastUpgradedAt?: string | null;
      highAvailabilityConfig?: {
        __typename?: 'HighAvailabilityControllersConfig';
        UUID: string;
      } | null;
      virtualDevices: Array<
        | { __typename?: 'AccessPointVirtualDevice'; deviceType: VirtualDeviceType }
        | { __typename?: 'ControllerVirtualDevice'; deviceType: VirtualDeviceType }
        | { __typename?: 'ObserverVirtualDevice'; deviceType: VirtualDeviceType }
        | { __typename?: 'PowerDistributionUnitVirtualDevice'; deviceType: VirtualDeviceType }
        | { __typename?: 'SwitchVirtualDevice'; deviceType: VirtualDeviceType }
      >;
      mailingAddress?: {
        __typename?: 'MailingAddress';
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        subdivisionCode?: string | null;
        postalCode?: string | null;
        countryISO2?: string | null;
      } | null;
    }>;
  } | null;
};

export type AllSecurityAppliancesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AllSecurityAppliancesQuery = {
  __typename?: 'Query';
  hardwareDevices: Array<
    | {
        __typename?: 'AccessPointHardwareDevice';
        serialNumber: string;
        isActive: boolean;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | null;
      }
    | {
        __typename?: 'ControllerHardwareDevice';
        serialNumber: string;
        isActive: boolean;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | null;
      }
    | {
        __typename?: 'PowerDistributionUnitHardwareDevice';
        serialNumber: string;
        isActive: boolean;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | null;
      }
    | {
        __typename?: 'SwitchHardwareDevice';
        serialNumber: string;
        isActive: boolean;
        deviceType: DeviceType;
        deviceModel: DeviceModel;
        virtualDevice?:
          | {
              __typename?: 'AccessPointVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'ControllerVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'ObserverVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'PowerDistributionUnitVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | {
              __typename?: 'SwitchVirtualDevice';
              UUID: string;
              label: string;
              network: {
                __typename?: 'Network';
                UUID: string;
                label: string;
                slug: string;
                company?: { __typename?: 'Company'; slug: string; name: string } | null;
              };
            }
          | null;
      }
  >;
};

export type SsoOptionsQueryQueryVariables = Exact<{
  companySlug: Scalars['String']['input'];
}>;

export type SsoOptionsQueryQuery = {
  __typename?: 'Query';
  ssoOptions: {
    __typename?: 'SSOOptions';
    connectedDirectory?: {
      __typename?: 'SSOConnectedDirectory';
      createdAt: string;
      state: SsoState;
      type: SsoDirectoryTypes;
    } | null;
    connections: Array<{
      __typename?: 'SSOConnection';
      connectedAt: string;
      state: SsoConnectionState;
      type: SsoConnectionType;
    }>;
  };
};

export type ConfigureSsoMutationMutationVariables = Exact<{
  input: ConfigureSsoInput;
}>;

export type ConfigureSsoMutationMutation = {
  __typename?: 'Mutation';
  configureSSO: { __typename?: 'ConfigureSSOResult'; portalURL: string };
};

export const PhyInterfaceLabelFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhyInterfaceLabelFieldsFragment, unknown>;
export const ClientVpnClientFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNClientFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNClient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userSID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpnServerUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectionInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientVpnClientFieldsFragment, unknown>;
export const ClientVpnServerFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNServerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNServer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'port' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultClientAllowedIPs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressMask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterface' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dnsListenAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultClientAllowedIPs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientVPNClientFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNClientFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNClient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userSID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpnServerUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectionInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientVpnServerFieldsFragment, unknown>;
export const ControllerFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VirtualDevice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConsoleEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingNosVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bootHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'count' },
                      value: { kind: 'IntValue', value: '20' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isDev' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'includeIsDev' },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ControllerVirtualDevice' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highAvailability' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ControllerFragmentFragment, unknown>;
export const ControllerVirtualDeviceFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerVirtualDeviceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ControllerVirtualDevice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highAvailability' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterfaces' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectedDevices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internetServicePlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'controllerIP' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ControllerVirtualDeviceFragmentFragment, unknown>;
export const ControllerPhyInterfaceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerPhyInterfaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEthernet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcedPortSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAssignmentProtocol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientGateway' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientPrefixLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkPriority' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nativeVLAN' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectedDevices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkExternalAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkVLANID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'controllerIP' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkGatewayMACAddress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ControllerPhyInterfaceFieldsFragment, unknown>;
export const CaptivePortalBodyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaptivePortalBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaptivePortal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callToAction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImageBase64' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'logoImageS3Key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirectURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedHosts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalPortalURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authLifetimeSec' } },
          { kind: 'Field', name: { kind: 'Name', value: 'termsAndConditions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displaySettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidePoweredByMeter' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'webhookKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaptivePortalBodyFragment, unknown>;
export const NosVersionFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NosVersionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NosVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'major' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NosVersionFieldsFragment, unknown>;
export const InternalNoteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InternalNoteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InternalNote' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isImportant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoURLs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoS3Keys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InternalNoteFieldsFragment, unknown>;
export const PolicyRoutingRulesFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyRoutingRulesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyRoutingRule' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'srcPrefix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srcVLAN' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srcPortRanges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dstPortRanges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'protocols' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PolicyRoutingRulesFieldsFragment, unknown>;
export const StaticRouteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StaticRouteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StaticRoute' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gatewayIP' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gatewayDHCPStaticMapping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hostname' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StaticRouteFieldsFragment, unknown>;
export const CompanyUserBodyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyUserBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyMembershipRole' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyUserBodyFragment, unknown>;
export const AccessPointUptimeFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccessPointUptimeFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AccessPointVirtualDevice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uptime' } }],
      },
    },
  ],
} as unknown as DocumentNode<AccessPointUptimeFragmentFragment, unknown>;
export const AutoVpnMemberFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoVPNMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AutoVPNMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permittedVLANs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uplink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPrefixLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hubUplinkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'listenPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectionInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoVpnMemberFieldsFragment, unknown>;
export const AutoVpnGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoVPNGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AutoVPNGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubNetworkUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubNetwork' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubUplink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPrefixLength' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHubFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AutoVPNMemberFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstPrefixLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstGateway' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoVPNMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AutoVPNMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permittedVLANs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uplink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPrefixLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hubUplinkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'listenPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectionInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoVpnGroupFieldsFragment, unknown>;
export const NetworkForCompanyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetworkForCompanyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Network' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportGroup' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'CONTROLLER' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highAvailability' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subdivisionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryISO2' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enabledServiceDiscoveryTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'multiWANAlgorithm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedNOSVersionID' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkForCompanyFieldsFragment, unknown>;
export const NetworkFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetworkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Network' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUpgradeSensitive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpgradedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highAvailabilityConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deviceType' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subdivisionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryISO2' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkFieldsFragment, unknown>;
export const RunAcsForAccessPointMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RunACSForAccessPointMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initializeAutoChannelSelectionForAccessPoint' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bands' },
                      value: { kind: 'EnumValue', value: 'BAND_5G' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeoutAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RunAcsForAccessPointMutationMutation,
  RunAcsForAccessPointMutationMutationVariables
>;
export const RunAcsForNetworkMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RunACSForNetworkMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'initializeAutoChannelSelectionForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'bands' },
                      value: { kind: 'EnumValue', value: 'BAND_5G' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeoutAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RunAcsForNetworkMutationMutation,
  RunAcsForNetworkMutationMutationVariables
>;
export const AcsJobsForNetworkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ACSJobsForNetworkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoChannelSelectionJobsForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeoutAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUIDs' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'resultType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previous5GHzChannel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'new5GHzChannel' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcsJobsForNetworkQueryQuery, AcsJobsForNetworkQueryQueryVariables>;
export const ClientVpnServerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientVPNServer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientVPNServer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientVPNServerFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNServerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNServer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'port' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultClientAllowedIPs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressMask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterface' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dnsListenAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultClientAllowedIPs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientVPNClientFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNClientFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNClient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userSID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpnServerUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectionInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientVpnServerQuery, ClientVpnServerQueryVariables>;
export const ClientVpnServersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientVPNServers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientVPNServers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientVPNServerFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNServerFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNServer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'port' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultClientAllowedIPs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressMask' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterface' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dnsListenAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultClientAllowedIPs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientVPNClientFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNClientFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNClient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userSID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpnServerUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectionInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientVpnServersQuery, ClientVpnServersQueryVariables>;
export const CreateClientVpnServerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateClientVPNServer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateClientVPNServerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createClientVPNServer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateClientVpnServerMutation, CreateClientVpnServerMutationVariables>;
export const UpdateClientVpnServerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClientVPNServer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateClientVPNServerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateClientVPNServer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClientVpnServerMutation, UpdateClientVpnServerMutationVariables>;
export const DeleteClientVpnServerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteClientVPNServer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteClientVPNServer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteClientVpnServerMutation, DeleteClientVpnServerMutationVariables>;
export const ClientVpnClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientVPNClients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serverUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientVPNClients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serverUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serverUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientVPNClientFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNClientFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNClient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userSID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpnServerUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectionInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientVpnClientsQuery, ClientVpnClientsQueryVariables>;
export const ClientVpnClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientVPNClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientVPNClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientVPNClientFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientVPNClientFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientVPNClient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userSID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpnServerUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectionInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientVpnClientQuery, ClientVpnClientQueryVariables>;
export const CreateClientVpnClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateClientVPNClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serverUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateClientVPNClientInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createClientVPNClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serverUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serverUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userSID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateClientVpnClientMutation, CreateClientVpnClientMutationVariables>;
export const DeleteClientVpnClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteClientVPNClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteClientVPNClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteClientVpnClientMutation, DeleteClientVpnClientMutationVariables>;
export const WirelessConnectionEventsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WirelessConnectionEventsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mac' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MacAddress' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClientMetricsTimeseriesFilterInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wirelessClientConnectionEventsByClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'macAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mac' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failedStep' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasonCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeToConnect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is80211rRoam' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ssid' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ssid' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousVirtualDeviceConnectedTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WirelessConnectionEventsQueryQuery,
  WirelessConnectionEventsQueryQueryVariables
>;
export const WirelessClientMetricsByClientQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WirelessClientMetricsByClientQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'macAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MacAddress' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClientMetricsTimeseriesFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wirelessClientMetricsByClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'macAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'macAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noise' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'txBytes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rxBytes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'noise' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txMulticastBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxMulticastBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txUnicastBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxUnicastBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxSuccessRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txSuccessRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxRetryRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txRetryRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WirelessClientMetricsByClientQueryQuery,
  WirelessClientMetricsByClientQueryQueryVariables
>;
export const ClientChannelUtilizationQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientChannelUtilizationQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'macAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MacAddress' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChannelUtilizationTimeseriesFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelUtilizationByClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'macAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'macAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalUtilization' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientChannelUtilizationQueryQuery,
  ClientChannelUtilizationQueryQueryVariables
>;
export const UpsertMacAddressAliasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'upsertMACAddressAlias' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'macAddress' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MacAddress' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'alias' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertMACAddressAlias' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'macAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'macAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'alias' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'alias' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'alias' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertMacAddressAliasMutation, UpsertMacAddressAliasMutationVariables>;
export const RpcDisconnectClientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'rpcDisconnectClient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mac' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ssid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcWosDisconnectClient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mac' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mac' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ssid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ssid' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RpcDisconnectClientMutation, RpcDisconnectClientMutationVariables>;
export const VlaNsAndDhcpRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VLANsAndDHCPRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dhcpRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dnsUseGatewayProxy' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VlaNsAndDhcpRulesQuery, VlaNsAndDhcpRulesQueryVariables>;
export const DnsFirewallRulesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DNSFirewallRulesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDNSFirewallRulesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dhcpRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DnsFirewallRulesForNetworkQuery,
  DnsFirewallRulesForNetworkQueryVariables
>;
export const DnsFirewallRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DNSFirewallRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDNSFirewallRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'application' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dhcpRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DnsFirewallRuleQuery, DnsFirewallRuleQueryVariables>;
export const CreateDnsFirewallRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDNSFirewallRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dhcpRuleUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateApplicationDNSFirewallRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApplicationDNSFirewallRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dhcpRuleUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dhcpRuleUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDnsFirewallRuleMutation, CreateDnsFirewallRuleMutationVariables>;
export const UpdateDnsFirewallRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDNSFirewallRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateApplicationDNSFirewallRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateApplicationDNSFirewallRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDnsFirewallRuleMutation, UpdateDnsFirewallRuleMutationVariables>;
export const DeleteDnsFirewallRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDNSFirewallRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteApplicationDNSFirewallRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDnsFirewallRuleMutation, DeleteDnsFirewallRuleMutationVariables>;
export const UpdateDnsFirewallRulesPrioritiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDNSFirewallRulesPriorities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdateApplicationDNSFirewallRulesPrioritiesInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateApplicationDNSFirewallRulesPriorities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDnsFirewallRulesPrioritiesMutation,
  UpdateDnsFirewallRulesPrioritiesMutationVariables
>;
export const DnsFirewallRuleCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DNSFirewallRuleCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDNSFirewallRuleCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DnsFirewallRuleCategoriesQuery,
  DnsFirewallRuleCategoriesQueryVariables
>;
export const DnsFirewallRuleGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DNSFirewallRuleGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDNSFirewallRuleGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DnsFirewallRuleGroupsQuery, DnsFirewallRuleGroupsQueryVariables>;
export const DnsFirewallRuleApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DNSFirewallRuleApplications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDNSFirewallRuleApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DnsFirewallRuleApplicationsQuery,
  DnsFirewallRuleApplicationsQueryVariables
>;
export const DnsDomainApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DNSDomainApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hostname' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDNSFirewallRuleApplicationForHostname' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hostname' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hostname' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DnsDomainApplicationQuery, DnsDomainApplicationQueryVariables>;
export const DnsDomainCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DNSDomainCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hostname' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationDNSFirewallRuleCategoryForHostname' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hostname' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hostname' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DnsDomainCategoryQuery, DnsDomainCategoryQueryVariables>;
export const CreateRackElevationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRackElevation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'label' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rackMountUnitCount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRackElevation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'label' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'label' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rackMountUnitCount' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'rackMountUnitCount' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRackElevationMutation, CreateRackElevationMutationVariables>;
export const AttachDeviceToRackElevationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AttachDeviceToRackElevation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rackElevationUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rackMountUnitIndexes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'label' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'RackElevationDeviceType' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachDeviceToRackElevation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rackElevationUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rackElevationUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rackMountUnitIndexes' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'rackMountUnitIndexes' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'virtualDeviceUUID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'virtualDeviceUUID' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'label' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'label' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'devices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AttachDeviceToRackElevationMutation,
  AttachDeviceToRackElevationMutationVariables
>;
export const DetachRackElevationDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DetachRackElevationDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'detachDeviceFromRackElevation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DetachRackElevationDeviceMutation,
  DetachRackElevationDeviceMutationVariables
>;
export const RackElevationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RackElevation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rackElevation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'devices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hardwareDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SwitchVirtualDevice' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phyInterfaces' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'portNumber' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isEthernet' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexStart' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexEnd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RackElevationQuery, RackElevationQueryVariables>;
export const RackElevationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RackElevations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rackElevations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'devices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hardwareDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SwitchVirtualDevice' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phyInterfaces' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'portNumber' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isEthernet' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexStart' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexEnd' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RackElevationsQuery, RackElevationsQueryVariables>;
export const UpdateRackNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRackNotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rackElevationUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'RackElevationNoteInput' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRackElevation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'rackElevationUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'notes' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'notes' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'devices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRackNotesMutation, UpdateRackNotesMutationVariables>;
export const VirtualDevicesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VirtualDevicesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DevicesForNetworkFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VirtualDevicesForNetworkQuery, VirtualDevicesForNetworkQueryVariables>;
export const UpdateRackElevationDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRackElevationDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateRackElevationDeviceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRackElevationDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'devices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitIndexes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRackElevationDeviceMutation,
  UpdateRackElevationDeviceMutationVariables
>;
export const UpdateRackElevationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRackElevation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateRackElevationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRackElevation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rackMountUnitCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRackElevationMutation, UpdateRackElevationMutationVariables>;
export const DeleteRackElevationMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRackElevationMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRackElevation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRackElevationMutationMutation,
  DeleteRackElevationMutationMutationVariables
>;
export const DeviceConfigsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeviceConfigsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deviceConfigs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeviceConfigsQueryQuery, DeviceConfigsQueryQueryVariables>;
export const DeviceConfigByVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeviceConfigByVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deviceConfigByVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configJSON' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeviceConfigByVersionQuery, DeviceConfigByVersionQueryVariables>;
export const RenderDeviceConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenderDeviceConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renderDeviceConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configJSON' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenderDeviceConfigMutation, RenderDeviceConfigMutationVariables>;
export const CreateVirtualDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createVirtualDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateVirtualDeviceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateVirtualDeviceMutation, CreateVirtualDeviceMutationVariables>;
export const UpdateVirtualDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateVirtualDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateVirtualDeviceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateVirtualDeviceMutation, UpdateVirtualDeviceMutationVariables>;
export const DeleteVirtualDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteVirtualDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteVirtualDeviceMutation, DeleteVirtualDeviceMutationVariables>;
export const CreateVirtualDevicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createVirtualDevices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateVirtualDevicesInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVirtualDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateVirtualDevicesMutation, CreateVirtualDevicesMutationVariables>;
export const UpdateVirtualDevicesIndependentlyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateVirtualDevicesIndependently' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdateVirtualDeviceIndependentlyInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVirtualDevicesIndependently' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVirtualDevicesIndependentlyMutation,
  UpdateVirtualDevicesIndependentlyMutationVariables
>;
export const AssignHardwareDeviceToVirtualDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AssignHardwareDeviceToVirtualDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignHardwareDeviceToVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignHardwareDeviceToVirtualDeviceMutation,
  AssignHardwareDeviceToVirtualDeviceMutationVariables
>;
export const CreateDevHardwareDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDevHardwareDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDevHardwareDeviceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDevHardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDevHardwareDeviceMutation,
  CreateDevHardwareDeviceMutationVariables
>;
export const AssignHardwareDeviceToNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AssignHardwareDeviceToNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignHardwareDeviceToNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignHardwareDeviceToNetworkMutation,
  AssignHardwareDeviceToNetworkMutationVariables
>;
export const UnassignHardwareDeviceFromVirtualDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UnassignHardwareDeviceFromVirtualDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unassignHardwareDeviceFromVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnassignHardwareDeviceFromVirtualDeviceMutation,
  UnassignHardwareDeviceFromVirtualDeviceMutationVariables
>;
export const AssignHardwareToVirtualDevicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AssignHardwareToVirtualDevices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AssignHardwareDeviceToVirtualDeviceInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignHardwareDevicesToVirtualDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignHardwareToVirtualDevicesMutation,
  AssignHardwareToVirtualDevicesMutationVariables
>;
export const HardwareDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HardwareDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HardwareDeviceQuery, HardwareDeviceQueryVariables>;
export const UpdateHardwareDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateHardwareDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateHardwareDeviceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateHardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateHardwareDeviceMutation, UpdateHardwareDeviceMutationVariables>;
export const BlinkLeDsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BlinkLEDsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcBlinkLEDs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'durationSec' },
                value: { kind: 'IntValue', value: '10' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BlinkLeDsMutationMutation, BlinkLeDsMutationMutationVariables>;
export const RefreshConfigMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshConfigMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcRefreshConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshConfigMutationMutation, RefreshConfigMutationMutationVariables>;
export const RpcPduOutletCycleMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RpcPDUOutletCycleMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RPCPDUOutletCycleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcPDUOutletCycle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RpcPduOutletCycleMutationMutation,
  RpcPduOutletCycleMutationMutationVariables
>;
export const LatestDeviceMemoryUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LatestDeviceMemoryUsage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestDeviceMemoryUsage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bytesUsed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bytesAvailable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LatestDeviceMemoryUsageQuery, LatestDeviceMemoryUsageQueryVariables>;
export const DeviceUptimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeviceUptime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deviceUptime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeviceUptimeQuery, DeviceUptimeQueryVariables>;
export const LatestDeviceCpuLoadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LatestDeviceCPULoad' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestDeviceCPULoad' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'usedPercentage' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LatestDeviceCpuLoadQuery, LatestDeviceCpuLoadQueryVariables>;
export const SwitchPoEInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchPoEInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SwitchHardwareDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'poeInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'used' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchPoEInfoQuery, SwitchPoEInfoQueryVariables>;
export const SwitchVirtualDevicePoEInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchVirtualDevicePoEInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SwitchVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'poeInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'max' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'used' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SwitchVirtualDevicePoEInfoQuery,
  SwitchVirtualDevicePoEInfoQueryVariables
>;
export const SystemInfoStatQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SystemInfoStatQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'systemInfoStatForVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'durationSeconds' },
                      value: { kind: 'IntValue', value: '300' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memoryBytesAvailable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'memoryBytesTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cpuUsedPercentage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SystemInfoStatQueryQuery, SystemInfoStatQueryQueryVariables>;
export const UpdatePhyInterfaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePhyInterface' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePhyInterfaceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePhyInterface' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePhyInterfaceMutation, UpdatePhyInterfaceMutationVariables>;
export const UpdatePhyInterfacesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePhyInterfaces' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phyInterfaceUUIDs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePhyInterfaceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePhyInterfaces' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phyInterfaceUUIDs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phyInterfaceUUIDs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePhyInterfacesMutation, UpdatePhyInterfacesMutationVariables>;
export const FirewallRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FirewallRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firewallRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMeterInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBidirectional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'srcPrefix' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'srcVLAN' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dstVLAN' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'srcPortRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dstPortRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'protocols' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlanBindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterfaceBindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterface' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FirewallRuleQuery, FirewallRuleQueryVariables>;
export const UpdateInterVlanCommunicationPermittedPairsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInterVLANCommunicationPermittedPairs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'vlanPairs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'VLANPairInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInterVLANCommunicationPermittedPairs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vlanPairs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'vlanPairs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateInterVlanCommunicationPermittedPairsMutation,
  UpdateInterVlanCommunicationPermittedPairsMutationVariables
>;
export const CopyInterVlanCommunicationFromConfig1ToConfig2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyInterVLANCommunicationFromConfig1ToConfig2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyInterVLANCommunicationFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyInterVlanCommunicationFromConfig1ToConfig2Mutation,
  CopyInterVlanCommunicationFromConfig1ToConfig2MutationVariables
>;
export const VlaNsForMulticastDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VLANsForMulticast' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMulticastReflectionEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VlaNsForMulticastQuery, VlaNsForMulticastQueryVariables>;
export const UpdateVlaNsForMulticastDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateVLANsForMulticast' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'UpdateVLANsIndependentlyInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVLANsIndependently' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'inputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'inputs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVlaNsForMulticastMutation,
  UpdateVlaNsForMulticastMutationVariables
>;
export const DhcpRulesWithStaticMappingsForNetworkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DHCPRulesWithStaticMappingsForNetworkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dhcpRulesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'staticMappings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hostname' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DhcpRulesWithStaticMappingsForNetworkQueryQuery,
  DhcpRulesWithStaticMappingsForNetworkQueryQueryVariables
>;
export const OneToOneNatRulesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OneToOneNATRulesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oneToOneNATRulesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalIPAddr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalIPAddr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalPhyInterfaceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalPhyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OneToOneNatRulesForNetworkQuery,
  OneToOneNatRulesForNetworkQueryVariables
>;
export const OneToOneNatRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OneToOneNATRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'oneToOneNATRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalIPAddr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalIPAddr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalPhyInterfaceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalPhyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OneToOneNatRuleQuery, OneToOneNatRuleQueryVariables>;
export const CreateOneToOneNatRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOneToOneNATRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateOneToOneNATRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOneToOneNATRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOneToOneNatRuleMutation, CreateOneToOneNatRuleMutationVariables>;
export const UpdateOneToOneNatRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOneToOneNATRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOneToOneNATRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOneToOneNATRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOneToOneNatRuleMutation, UpdateOneToOneNatRuleMutationVariables>;
export const DeleteOneToOneNatRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOneToOneNATRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOneToOneNATRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteOneToOneNatRuleMutation, DeleteOneToOneNatRuleMutationVariables>;
export const PortForwardingRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PortForwardingRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portForwardingRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'protocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalPhyInterfaceUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localPort' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortForwardingRuleQuery, PortForwardingRuleQueryVariables>;
export const PortForwardingRulesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PortForwardingRulesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'portForwardingRulesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'protocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalPhyInterfaceUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalIPAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'externalPhyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internetServicePlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'provider' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localPort' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PortForwardingRulesForNetworkQuery,
  PortForwardingRulesForNetworkQueryVariables
>;
export const CreatePortForwardingRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePortForwardingRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePortForwardingRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPortForwardingRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePortForwardingRuleMutation,
  CreatePortForwardingRuleMutationVariables
>;
export const UpdatePortForwardingRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePortForwardingRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePortForwardingRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePortForwardingRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePortForwardingRuleMutation,
  UpdatePortForwardingRuleMutationVariables
>;
export const DeletePortForwardingRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePortForwardingRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePortForwardingRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePortForwardingRuleMutation,
  DeletePortForwardingRuleMutationVariables
>;
export const VlaNsForFirewallDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VLANsForFirewall' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permittedInterVLANCommunicationVLANs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dhcpRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicationDNSFirewallRules' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VlaNsForFirewallQuery, VlaNsForFirewallQueryVariables>;
export const FirewallRulesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FirewallRulesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'firewallRulesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMeterInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBidirectional' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'srcPrefix' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'srcVLAN' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dstVLAN' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'srcPortRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dstPortRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'protocols' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlanBindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterfaceBindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterface' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FirewallRulesForNetworkQuery, FirewallRulesForNetworkQueryVariables>;
export const UpdateFirewallRuleBindingsForVlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFirewallRuleBindingsForVLAN' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'vlanUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderedRuleUUIDs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindFirewallRulesToVLAN' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vlanUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'vlanUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderedRuleUUIDs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderedRuleUUIDs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'metric' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFirewallRuleBindingsForVlanMutation,
  UpdateFirewallRuleBindingsForVlanMutationVariables
>;
export const UpdateFirewallRuleBindingsForPhyInterfaceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFirewallRuleBindingsForPhyInterface' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderedRuleUUIDs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindFirewallRulesToPhyInterface' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phyInterfaceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderedRuleUUIDs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderedRuleUUIDs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'metric' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFirewallRuleBindingsForPhyInterfaceMutation,
  UpdateFirewallRuleBindingsForPhyInterfaceMutationVariables
>;
export const UplinkPhyInterfacesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UplinkPhyInterfacesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uplinkPhyInterfacesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isConnectedToBackend' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'highAvailability' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internetServicePlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAddresses' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uplinkExternalAddresses' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UplinkPhyInterfacesForNetworkQuery,
  UplinkPhyInterfacesForNetworkQueryVariables
>;
export const CreateFirewallRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFirewallRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateFirewallRuleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFirewallRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFirewallRuleMutation, CreateFirewallRuleMutationVariables>;
export const UpdateFirewallRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFirewallRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateFirewallRuleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFirewallRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFirewallRuleMutation, UpdateFirewallRuleMutationVariables>;
export const DeleteFirewallRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFirewallRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFirewallRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFirewallRuleMutation, DeleteFirewallRuleMutationVariables>;
export const FloorPlansForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FloorPlansForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'floorPlansForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageS3Key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageDownloadURL' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FloorPlansForNetworkQuery, FloorPlansForNetworkQueryVariables>;
export const FloorPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FloorPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'floorPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageS3Key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FloorPlanQuery, FloorPlanQueryVariables>;
export const CreateFloorPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFloorPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateFloorPlanInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFloorPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateFloorPlanMutation, CreateFloorPlanMutationVariables>;
export const UpdateFloorPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFloorPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateFloorPlanInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFloorPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFloorPlanMutation, UpdateFloorPlanMutationVariables>;
export const DeleteFloorPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFloorPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFloorPlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFloorPlanMutation, DeleteFloorPlanMutationVariables>;
export const ApbssiDsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'APBSSIDsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AccessPointHardwareDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BSSIDs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'SSID' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'ssid' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'BSSID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'radioBand' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApbssiDsQueryQuery, ApbssiDsQueryQueryVariables>;
export const ApBootHistoryQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'APBootHistoryQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bootHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'count' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApBootHistoryQueryQuery, ApBootHistoryQueryQueryVariables>;
export const RebootAccessPointDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RebootAccessPoint' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcRebootDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RebootAccessPointMutation, RebootAccessPointMutationVariables>;
export const ApScanDataQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'APScanDataQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'durationSeconds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statsForVirtualDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'virtualDeviceUUIDs' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'types' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'WOS_SCAN_TABLE' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'durationSeconds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'durationSeconds' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApScanDataQueryQuery, ApScanDataQueryQueryVariables>;
export const PduInsightsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PDUInsightsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bootHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'count' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pendingNosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nosVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'PowerDistributionUnitVirtualDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PduInsightsQueryQuery, PduInsightsQueryQueryVariables>;
export const RebootPowerDistributionUnitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RebootPowerDistributionUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcRebootDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RebootPowerDistributionUnitMutation,
  RebootPowerDistributionUnitMutationVariables
>;
export const FallbackPowerDistributionUnitHardwareQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FallbackPowerDistributionUnitHardwareQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FallbackPowerDistributionUnitHardwareQueryQuery,
  FallbackPowerDistributionUnitHardwareQueryQueryVariables
>;
export const PowerDistributionUnitQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PowerDistributionUnitQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConsoleEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingNosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PowerDistributionUnitVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bootHistory' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'IntValue', value: '20' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PowerDistributionUnitQueryQuery,
  PowerDistributionUnitQueryQueryVariables
>;
export const PowerDistributionUnitsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PowerDistributionUnitsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeIsDev' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'POWER_DISTRIBUTION_UNIT' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingNosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'PowerDistributionUnitVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bootHistory' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isDev' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeIsDev' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PowerDistributionUnitsQueryQuery,
  PowerDistributionUnitsQueryQueryVariables
>;
export const DeviceHeartbeatForDeviceQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'deviceHeartbeatForDeviceQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deviceHeartbeatForDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'token' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeviceHeartbeatForDeviceQueryQuery,
  DeviceHeartbeatForDeviceQueryQueryVariables
>;
export const OutletsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OutletsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletInterfacesForVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outletNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'powerCycleDelaySec' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentAmps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'powerWatts' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectedVirtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'connectedVirtualDeviceUUID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OutletsQueryQuery, OutletsQueryQueryVariables>;
export const UpdateOutletInterfaceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOutletInterfaceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOutletInterfaceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOutletInterface' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOutletInterfaceMutationMutation,
  UpdateOutletInterfaceMutationMutationVariables
>;
export const HostMonitoringDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'hostMonitoring' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hostIPs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'durationSeconds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stepSeconds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hostMonitoringForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'durationSeconds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'durationSeconds' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stepSeconds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'stepSeconds' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hostIPs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hostIPs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hostIPAddr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'srcEthPortNum' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HostMonitoringQuery, HostMonitoringQueryVariables>;
export const RebootSecurityApplianceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RebootSecurityAppliance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcRebootDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RebootSecurityApplianceMutation,
  RebootSecurityApplianceMutationVariables
>;
export const ControllersQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ControllersQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'CONTROLLER' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bootHistory' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ControllersQueryQuery, ControllersQueryQueryVariables>;
export const ControllerQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ControllerQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeIsDev' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ControllerFragment' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterfaces' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ControllerPhyInterfaceFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VirtualDevice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConsoleEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingNosVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bootHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'count' },
                      value: { kind: 'IntValue', value: '20' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isDev' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'includeIsDev' },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ControllerVirtualDevice' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highAvailability' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerPhyInterfaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEthernet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcedPortSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAssignmentProtocol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientGateway' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientPrefixLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkPriority' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nativeVLAN' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectedDevices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkExternalAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkVLANID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'controllerIP' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkGatewayMACAddress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ControllerQueryQuery, ControllerQueryQueryVariables>;
export const HaConfigForNetworkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HAConfigForNetworkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highAvailabilityConfig' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'primaryPhyInterface' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hardwareDevice' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isConnectedToBackend' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'backupPhyInterface' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hardwareDevice' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isConnectedToBackend' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'advertisementVLAN' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'preemption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'advertisementIntervalMs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HaConfigForNetworkQueryQuery, HaConfigForNetworkQueryQueryVariables>;
export const ControllerForSecurityApplianceQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ControllerForSecurityApplianceQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeIsDev' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ControllerFragment' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ControllerVirtualDeviceFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VirtualDevice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConsoleEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingNosVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bootHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'count' },
                      value: { kind: 'IntValue', value: '20' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isDev' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'includeIsDev' },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ControllerVirtualDevice' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highAvailability' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerVirtualDeviceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ControllerVirtualDevice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highAvailability' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterfaces' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectedDevices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internetServicePlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'controllerIP' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ControllerForSecurityApplianceQueryQuery,
  ControllerForSecurityApplianceQueryQueryVariables
>;
export const ControllersForSecurityApplianceQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ControllersForSecurityApplianceQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeIsDev' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'CONTROLLER' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ControllerFragment' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ControllerVirtualDeviceFragment' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterfaces' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ControllerPhyInterfaceFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VirtualDevice' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConsoleEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pendingNosVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bootHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'count' },
                      value: { kind: 'IntValue', value: '20' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isDev' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'includeIsDev' },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ControllerVirtualDevice' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highAvailability' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerVirtualDeviceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ControllerVirtualDevice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highAvailability' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterfaces' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectedDevices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internetServicePlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'controllerIP' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'provider' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerPhyInterfaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEthernet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcedPortSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAssignmentProtocol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientGateway' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientPrefixLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkPriority' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nativeVLAN' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectedDevices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkExternalAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkVLANID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'controllerIP' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkGatewayMACAddress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ControllersForSecurityApplianceQueryQuery,
  ControllersForSecurityApplianceQueryQueryVariables
>;
export const DeviceConfigVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeviceConfigVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestDeviceConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestAcknowledgedDeviceConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acknowledgedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeviceConfigVersionQuery, DeviceConfigVersionQueryVariables>;
export const FallbackSwitchHardwareQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FallbackSwitchHardwareQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FallbackSwitchHardwareQueryQuery,
  FallbackSwitchHardwareQueryQueryVariables
>;
export const PhyInterfacesForVirtualDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PhyInterfacesForVirtualDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterfacesForVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhyInterfacesForVirtualDeviceQuery,
  PhyInterfacesForVirtualDeviceQueryVariables
>;
export const CreateHighAvailabilityConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateHighAvailabilityConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'primaryPhyInterfaceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'backupPhyInterfaceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateHighAvailabilityPairInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createHighAvailabilityControllersConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'primaryPhyInterfaceUUID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'primaryPhyInterfaceUUID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'backupPhyInterfaceUUID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'backupPhyInterfaceUUID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advertisementVLAN' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateHighAvailabilityConfigMutation,
  CreateHighAvailabilityConfigMutationVariables
>;
export const DeleteHighAvailabilityConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteHighAvailabilityConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteHighAvailabilityControllersConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteHighAvailabilityConfigMutation,
  DeleteHighAvailabilityConfigMutationVariables
>;
export const DeviceLastDayCellularUsageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeviceLastDayCellularUsage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deviceLastDayCellularUsage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uploadBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'downloadBytes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeviceLastDayCellularUsageQuery,
  DeviceLastDayCellularUsageQueryVariables
>;
export const DeviceCellularStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeviceCellularStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deviceCellularStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'managerModemState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeviceCellularStatusQuery, DeviceCellularStatusQueryVariables>;
export const DhcpRulesForNetworkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DHCPRulesForNetworkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dhcpRulesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DhcpRulesForNetworkQueryQuery, DhcpRulesForNetworkQueryQueryVariables>;
export const ControllerMetricsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ControllerMetricsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'controllerPortMetricsRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dropsPerSecond' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txErrPerSecond' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxErrPerSecond' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRxBytesPerSecond' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTxBytesPerSecond' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multicastRxPacketsPerSecond' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multicastTxPacketsPerSecond' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'broadcastRxPacketsPerSecond' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'broadcastTxPacketsPerSecond' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'controllerDNSRequestRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ControllerMetricsQueryQuery, ControllerMetricsQueryQueryVariables>;
export const PhyInterfacesForSecurityApplianceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PhyInterfacesForSecurityAppliance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterfacesForVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ControllerPhyInterfaceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ControllerPhyInterfaceFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEthernet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'forcedPortSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAssignmentProtocol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientGateway' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientPrefixLength' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ipv4ClientAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkPriority' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nativeVLAN' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectedDevices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkExternalAddresses' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkVLANID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'controllerIP' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'uplinkGatewayMACAddress' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhyInterfacesForSecurityApplianceQuery,
  PhyInterfacesForSecurityApplianceQueryVariables
>;
export const SecurityAppliancePortStatsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SecurityAppliancePortStatsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'controllerPortStats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'drops' } },
                { kind: 'Field', name: { kind: 'Name', value: 'txErr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rxErr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv6' } },
                { kind: 'Field', name: { kind: 'Name', value: 'punts' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRxBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalTxBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRxPackets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalTxPackets' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hwUp' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SecurityAppliancePortStatsQueryQuery,
  SecurityAppliancePortStatsQueryQueryVariables
>;
export const UptimeStatsForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UptimeStatsForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lookBack' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stepSeconds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkUplinkQuality' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'durationSeconds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lookBack' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stepSeconds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'stepSeconds' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UptimeStatsForNetworkQuery, UptimeStatsForNetworkQueryVariables>;
export const PortCableTestMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PortCableTestMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcPortCableTest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'portNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'portNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pairA' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pairB' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pairC' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pairD' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortCableTestMutationMutation, PortCableTestMutationMutationVariables>;
export const PatchPanelLayoutSwitchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatchPanelLayoutSwitches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevices' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deviceType' },
                            value: { kind: 'EnumValue', value: 'SWITCH' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SwitchVirtualDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phyInterfaces' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowedVLANs' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nativeVLAN' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PatchPanelLayoutSwitchesQuery, PatchPanelLayoutSwitchesQueryVariables>;
export const SwitchBootHistoryQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchBootHistoryQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bootHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'count' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchBootHistoryQueryQuery, SwitchBootHistoryQueryQueryVariables>;
export const InsightsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InsightsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bootHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'count' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'count' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bootCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rebootReason' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SwitchHardwareDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stpInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isRootBridge' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rootBridgeMACAddress' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pendingNosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nosVersion' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SwitchVirtualDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'switchProfile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stpBridgePriority' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsightsQueryQuery, InsightsQueryQueryVariables>;
export const SwitchesRootQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchesRootQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'SWITCH' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SwitchHardwareDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stpInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isRootBridge' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchesRootQueryQuery, SwitchesRootQueryQueryVariables>;
export const SwitchMetricsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchMetricsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MetricsSerialNumberDirectionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchThroughputPerPort' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SwitchPortMetricsValue' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'port' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchDiscardRatesPerPort' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SwitchPortMetricsValue' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'port' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchErrorRatesPerPort' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SwitchPortMetricsValue' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'port' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchMetricsQueryQuery, SwitchMetricsQueryQueryVariables>;
export const SwitchPortNumbersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchPortNumbers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterfacesForVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'portNumber' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchPortNumbersQuery, SwitchPortNumbersQueryVariables>;
export const UnassignedHardwareForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UnassignedHardwareForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unassignedHardwareDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unassignedHardwareConnections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterface' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unassignedHardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnassignedHardwareForNetworkQuery,
  UnassignedHardwareForNetworkQueryVariables
>;
export const RebootSwitchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RebootSwitch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcRebootDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RebootSwitchMutation, RebootSwitchMutationVariables>;
export const SwitchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConsoleEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingNosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SwitchVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'switchProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stpBridgePriority' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterfaces' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEthernet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bootHistory' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SwitchHardwareDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchQueryQuery, SwitchQueryQueryVariables>;
export const SwitchesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeIsDev' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'SWITCH' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingNosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SwitchVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'switchProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'stpBridgePriority' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterfaces' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEthernet' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bootHistory' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isDev' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeIsDev' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'SwitchHardwareDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stpInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isRootBridge' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rootBridgePortNumber' },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isInCurrentControllerMACTable' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'uptime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchesQueryQuery, SwitchesQueryQueryVariables>;
export const PortsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PortsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phyInterfacesForVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forcedPortSpeedMbps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBoundToAllVLANs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPOEEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSTPEdgePortEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSTPEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrunkPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLoopbackDetected' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBlockedDueToFlaps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSTPForwarding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isIngressFilteringEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frameAcceptTypeFilter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEthernet' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'powerDraw' } },
                { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stpPortRole' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isStormControlEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stormControlBroadcastTrafficPercent' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stormControlUnknownMulticastTrafficPercent' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stormControlUnknownUnicastTrafficPercent' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowedVLANs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nativeVLAN' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'throughputLastDay' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectedDevices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isConnectedToBackend' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sfpModuleInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'vendor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'partName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'moduleSpeed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'moduleType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortsQueryQuery, PortsQueryQueryVariables>;
export const MacTableQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MACTableQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchMACTable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'port' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MacTableQueryQuery, MacTableQueryQueryVariables>;
export const UpdateSwitchProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSwitchProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSwitchProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSwitchProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSwitchProfileMutation, UpdateSwitchProfileMutationVariables>;
export const FindMacOnSwitchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindMACOnSwitchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mac' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MacAddress' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findSwitchesForClientMAC' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'macAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mac' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isConnectedToBackend' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindMacOnSwitchQueryQuery, FindMacOnSwitchQueryQueryVariables>;
export const FindLldpEntryOnSwitchQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindLLDPEntryOnSwitchQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mac' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MacAddress' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findSwitchLLDPEntryForMAC' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'macAddress' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mac' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isConnectedToBackend' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindLldpEntryOnSwitchQueryQuery,
  FindLldpEntryOnSwitchQueryQueryVariables
>;
export const PortCycleMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PortCycleMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'poeCycle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'portCycle' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcSwitchPortCycle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'portNumber' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'serialNumber' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'poeCycle' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'poeCycle' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'portCycle' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'portCycle' } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PortCycleMutationMutation, PortCycleMutationMutationVariables>;
export const SwitchPortMetricsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchPortMetricsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchPortMetricsRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dropsPerSecond' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txErrPerSecond' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxErrPerSecond' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRxBytesPerSecond' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalTxBytesPerSecond' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multicastRxPacketsPerSecond' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multicastTxPacketsPerSecond' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'broadcastRxPacketsPerSecond' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'broadcastTxPacketsPerSecond' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchPortMetricsQueryQuery, SwitchPortMetricsQueryQueryVariables>;
export const SwitchPortStatsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'switchPortStatsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchPortStats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRxBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalTxBytes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchPortStatsQueryQuery, SwitchPortStatsQueryQueryVariables>;
export const HubNetworksOverviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HubNetworksOverview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networksForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subdivisionCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryISO2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isConnectedToBackend' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HubNetworksOverviewQuery, HubNetworksOverviewQueryVariables>;
export const UptimeStatsForNetworksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UptimeStatsForNetworks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUIDs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lookBack' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stepSeconds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networksUplinkQualities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUIDs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUIDs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'durationSeconds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'lookBack' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stepSeconds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'stepSeconds' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UptimeStatsForNetworksQuery, UptimeStatsForNetworksQueryVariables>;
export const ApUptimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'APUptime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apUptime' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'apName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApUptimeQuery, ApUptimeQueryVariables>;
export const ClientMetricsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClientMetrics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientMetrics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noise' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tx' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rx' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'noise' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxRate' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientMetricsQuery, ClientMetricsQueryVariables>;
export const ActiveClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'activeClients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActiveClientsInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activeClients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActiveClientsQuery, ActiveClientsQueryVariables>;
export const NetworkUplinkQualityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'networkUplinkQuality' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkUplinkQuality' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkUplinkQualityQuery, NetworkUplinkQualityQueryVariables>;
export const NetworkUplinkThroughputDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'networkUplinkThroughput' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkUplinkThroughput' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkUplinkThroughputQuery, NetworkUplinkThroughputQueryVariables>;
export const ApChannelUtilizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'APChannelUtilization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MetricsFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'band2G' },
            name: { kind: 'Name', value: 'apChannelUtilization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'band' },
                value: { kind: 'EnumValue', value: 'BAND_2_4G' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'APChannelUtilizationMetricsValue' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'apName' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'band5G' },
            name: { kind: 'Name', value: 'apChannelUtilization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'band' },
                value: { kind: 'EnumValue', value: 'BAND_5G' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'APChannelUtilizationMetricsValue' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'apName' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApChannelUtilizationQuery, ApChannelUtilizationQueryVariables>;
export const NetwoorksForNosVersionQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetwoorksForNosVersionQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pinnedNOSVersionID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NetwoorksForNosVersionQueryQuery,
  NetwoorksForNosVersionQueryQueryVariables
>;
export const NosVersionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NosVersionsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosVersions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mc01Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mw07Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ms10Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mp01Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeprecated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'major' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patch' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NosVersionsQueryQuery, NosVersionsQueryQueryVariables>;
export const NosVersionByIdQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NosVersionByIDQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeprecated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isGA' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'releaseNotes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'major' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patch' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mc01Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mw07Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mw08Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mw09Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ms10Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ms11Build' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mp01Build' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NosVersionByIdQueryQuery, NosVersionByIdQueryQueryVariables>;
export const UpdateNosVersionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNOSVersionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NosVersionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNosVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNosVersionMutationMutation,
  UpdateNosVersionMutationMutationVariables
>;
export const CreateNosVersionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNOSVersionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NosVersionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNosVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNosVersionMutationMutation,
  CreateNosVersionMutationMutationVariables
>;
export const NosFeaturesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NosFeaturesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosFeatures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMajorVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMinorVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPatchVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMajorVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMinorVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxPatchVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featureFlag' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NosFeaturesQueryQuery, NosFeaturesQueryQueryVariables>;
export const NosFeatureQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NosFeatureQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosFeature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMajorVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minMinorVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minPatchVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMajorVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMinorVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxPatchVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featureFlag' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NosFeatureQueryQuery, NosFeatureQueryQueryVariables>;
export const UpdateNosFeatureMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNosFeatureMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateNosFeatureInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNosFeature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNosFeatureMutationMutation,
  UpdateNosFeatureMutationMutationVariables
>;
export const CreateNosFeatureMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNosFeatureMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateNosFeatureInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNosFeature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNosFeatureMutationMutation,
  CreateNosFeatureMutationMutationVariables
>;
export const NetworkForNavigationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworkForNavigation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkForNavigationQuery, NetworkForNavigationQueryVariables>;
export const HardwareDeviceForNavigationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HardwareDeviceForNavigation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'network' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HardwareDeviceForNavigationQuery,
  HardwareDeviceForNavigationQueryVariables
>;
export const CaptivePortalForNetworkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaptivePortalForNetworkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captivePortalForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaptivePortalBody' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaptivePortalBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaptivePortal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callToAction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImageBase64' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'logoImageS3Key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirectURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedHosts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalPortalURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authLifetimeSec' } },
          { kind: 'Field', name: { kind: 'Name', value: 'termsAndConditions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displaySettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidePoweredByMeter' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'webhookKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CaptivePortalForNetworkQueryQuery,
  CaptivePortalForNetworkQueryQueryVariables
>;
export const UpdateCaptivePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCaptivePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CaptivePortalSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCaptivePortal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CaptivePortalBody' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CaptivePortalBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CaptivePortal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callToAction' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImageBase64' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'logoImageS3Key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'redirectURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedHosts' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalPortalURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authLifetimeSec' } },
          { kind: 'Field', name: { kind: 'Name', value: 'termsAndConditions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displaySettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidePoweredByMeter' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'webhookKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCaptivePortalMutation, UpdateCaptivePortalMutationVariables>;
export const DeleteCaptivePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCaptivePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCaptivePortal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCaptivePortalMutation, DeleteCaptivePortalMutationVariables>;
export const CreateCaptivePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCaptivePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CaptivePortalSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCaptivePortal' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCaptivePortalMutation, CreateCaptivePortalMutationVariables>;
export const CapoActivityQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'capoActivityQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'numHoursLookback' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captivePortalAuthorizedClients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numHoursLookback' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'numHoursLookback' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthorized' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawUserAgent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CapoActivityQueryQuery, CapoActivityQueryQueryVariables>;
export const DeleteInternetServicePlanMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInternetServicePlanMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'internetServicePlanUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInternetServicePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internetServicePlanUUID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internetServicePlanUUID' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteInternetServicePlanMutationMutation,
  DeleteInternetServicePlanMutationMutationVariables
>;
export const CreateInternetServicePlanMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInternetServicePlanMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateInternetServicePlanInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInternetServicePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateInternetServicePlanMutationMutation,
  CreateInternetServicePlanMutationMutationVariables
>;
export const UpdateInternetServicePlanMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInternetServicePlanMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'internetServicePlanUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateInternetServicePlanInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInternetServicePlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internetServicePlanUUID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internetServicePlanUUID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateInternetServicePlanMutationMutation,
  UpdateInternetServicePlanMutationMutationVariables
>;
export const CopyIsPsToInternetServicePlansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyISPsToInternetServicePlans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyInternetServicePlanIPsFromConfigToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyIsPsToInternetServicePlansMutation,
  CopyIsPsToInternetServicePlansMutationVariables
>;
export const ProvidersQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProvidersQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlanProviders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProvidersQueryQuery, ProvidersQueryQueryVariables>;
export const ListIsPsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListISPsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionProduct' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'controllerIP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'controllerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstUsableIP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gatewayAddr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasStaticIP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUsableIP' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'staticIPRange' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListIsPsQueryQuery, ListIsPsQueryQueryVariables>;
export const MoveInternetServicePlanMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveInternetServicePlanMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ispUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveInternetServicePlanToNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internetServicePlanUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ispUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MoveInternetServicePlanMutationMutation,
  MoveInternetServicePlanMutationMutationVariables
>;
export const VlaNsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VLANsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VlaNsQueryQuery, VlaNsQueryQueryVariables>;
export const VlanQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VLANQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlan' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInternal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dhcpRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isIPv6' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startIPAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endIPAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gatewayIPAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gatewayPrefixLength' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'leaseDurationSeconds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dnsUseGatewayProxy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dnsUpstreamServers' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dnsSearchDomains' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dnsCacheIsEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dnsCacheSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dnsCacheMaxTTL' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'options' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reservedRanges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startIPAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endIPAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staticMappings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hostname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dnsHostMappings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'overrideDomain' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'destinationIPAddress' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'destinationDomain' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applicationDNSFirewallRules' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VlanQueryQuery, VlanQueryQueryVariables>;
export const CreateVlanMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateVLANMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateVLANInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVLAN' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateVlanMutationMutation, CreateVlanMutationMutationVariables>;
export const UpdateVlanMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateVLANMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateVLANInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVLAN' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateVlanMutationMutation, UpdateVlanMutationMutationVariables>;
export const DeleteVlanMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteVLANMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteVLAN' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteVlanMutationMutation, DeleteVlanMutationMutationVariables>;
export const CreateDhcpRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDHCPRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'vlanUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDHCPRuleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDHCPRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vlanUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'vlanUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDhcpRuleMutation, CreateDhcpRuleMutationVariables>;
export const UpdateDhcpRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDHCPRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDHCPRuleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDHCPRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDhcpRuleMutation, UpdateDhcpRuleMutationVariables>;
export const DeleteDhcpRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDHCPRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDHCPRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDhcpRuleMutation, DeleteDhcpRuleMutationVariables>;
export const CreateDhcpOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDHCPOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ruleUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDHCPOptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDHCPOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dhcpRuleUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ruleUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDhcpOptionMutation, CreateDhcpOptionMutationVariables>;
export const UpdateDhcpOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDHCPOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDHCPOptionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDHCPOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDhcpOptionMutation, UpdateDhcpOptionMutationVariables>;
export const DeleteDhcpOptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDHCPOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDHCPOption' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDhcpOptionMutation, DeleteDhcpOptionMutationVariables>;
export const CreateDhcpReservedRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDHCPReservedRange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ruleUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDHCPReservedRangeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDHCPReservedRange' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dhcpRuleUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ruleUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDhcpReservedRangeMutation,
  CreateDhcpReservedRangeMutationVariables
>;
export const UpdateDhcpReservedRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDHCPReservedRange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateDHCPReservedRangeInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDHCPReservedRange' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDhcpReservedRangeMutation,
  UpdateDhcpReservedRangeMutationVariables
>;
export const DeleteDhcpReservedRangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDHCPReservedRange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDHCPReservedRange' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDhcpReservedRangeMutation,
  DeleteDhcpReservedRangeMutationVariables
>;
export const CreateDhcpStaticMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDHCPStaticMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ruleUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateDHCPStaticMappingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDHCPStaticMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dhcpRuleUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ruleUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDhcpStaticMappingMutation,
  CreateDhcpStaticMappingMutationVariables
>;
export const UpdateDhcpStaticMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDHCPStaticMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateDHCPStaticMappingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDHCPStaticMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDhcpStaticMappingMutation,
  UpdateDhcpStaticMappingMutationVariables
>;
export const DeleteDhcpStaticMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDHCPStaticMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDHCPStaticMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteDhcpStaticMappingMutation,
  DeleteDhcpStaticMappingMutationVariables
>;
export const CreateDnsHostMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDNSHostMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ruleUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDNSHostMappingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDNSHostMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dhcpRuleUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ruleUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateDnsHostMappingMutation, CreateDnsHostMappingMutationVariables>;
export const UpdateDnsHostMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDNSHostMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDNSHostMappingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDNSHostMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDnsHostMappingMutation, UpdateDnsHostMappingMutationVariables>;
export const DeleteDnsHostMappingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDNSHostMapping' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDNSHostMapping' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteDnsHostMappingMutation, DeleteDnsHostMappingMutationVariables>;
export const CopyVlaNsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyVLANsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyVLANsFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CopyVlaNsMutationMutation, CopyVlaNsMutationMutationVariables>;
export const CopyDnsHostMappingsFromConfig1Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyDNSHostMappingsFromConfig1' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyDNSHostMappingsFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyDnsHostMappingsFromConfig1Mutation,
  CopyDnsHostMappingsFromConfig1MutationVariables
>;
export const NosVersionQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NosVersionQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NosVersionFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NosVersionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NosVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'major' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NosVersionQueryQuery, NosVersionQueryQueryVariables>;
export const NosVersionsShortQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NosVersionsShortQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nosVersions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NosVersionFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NosVersionFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NosVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'major' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patch' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NosVersionsShortQueryQuery, NosVersionsShortQueryQueryVariables>;
export const SetNetworkNosMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetNetworkNosMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SetNetworkNosVersionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setNetworkNosVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetNetworkNosMutationMutation, SetNetworkNosMutationMutationVariables>;
export const NotificationPreferencesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NotificationPreferencesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'controllerName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationPreferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companySlug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'controllerName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'controllerName' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyPreferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'companySID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalSlackChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalEventTypes' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networkPreferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAddresses' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalSlackChannels' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalEventTypes' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NotificationPreferencesQueryQuery,
  NotificationPreferencesQueryQueryVariables
>;
export const UpsertCompanyNotificationPreferenceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertCompanyNotificationPreferenceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationPreferenceInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertCompanyNotificationPreference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertCompanyNotificationPreferenceMutationMutation,
  UpsertCompanyNotificationPreferenceMutationMutationVariables
>;
export const UpsertControllerNotificationPreferenceMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertControllerNotificationPreferenceMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'controllerName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationPreferenceInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertNetworkNotificationPreference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'controllerName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'controllerName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uuid' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertControllerNotificationPreferenceMutationMutation,
  UpsertControllerNotificationPreferenceMutationMutationVariables
>;
export const InternalNotesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InternalNotesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalNotesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InternalNoteFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InternalNoteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InternalNote' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isImportant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoURLs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoS3Keys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InternalNotesForNetworkQuery, InternalNotesForNetworkQueryVariables>;
export const InternalNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InternalNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InternalNoteFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InternalNoteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InternalNote' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatedByUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isImportant' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoURLs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'photoS3Keys' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InternalNoteQuery, InternalNoteQueryVariables>;
export const CreateInternalNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInternalNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateInternalNoteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInternalNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateInternalNoteMutation, CreateInternalNoteMutationVariables>;
export const UpdateInternalNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateInternalNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateInternalNoteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateInternalNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateInternalNoteMutation, UpdateInternalNoteMutationVariables>;
export const DeleteInternalNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteInternalNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInternalNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteInternalNoteMutation, DeleteInternalNoteMutationVariables>;
export const RpcPingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RPCPingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RPCPingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcPing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'successRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundTripTimeMs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RpcPingMutationMutation, RpcPingMutationMutationVariables>;
export const VlansForPingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VlansForPing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VlansForPingQuery, VlansForPingQueryVariables>;
export const RpcPing2MutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RPCPing2Mutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RPCPingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcPing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'successRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roundTripTimeMs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RpcPing2MutationMutation, RpcPing2MutationMutationVariables>;
export const RateLimitRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RateLimitRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateLimitRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateLimitKbpsDownload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateLimitKbpsUpload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'protocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'srcMac' } },
                { kind: 'Field', name: { kind: 'Name', value: 'srcPrefix' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'srcPortRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dstMac' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dstPortRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isPerSource' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlanBindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterfaceBindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RateLimitRuleQuery, RateLimitRuleQueryVariables>;
export const CreateRateLimitRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRateLimitRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRateLimitRuleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRateLimitRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRateLimitRuleMutation, CreateRateLimitRuleMutationVariables>;
export const UpdateRateLimitRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRateLimitRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateRateLimitRuleInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRateLimitRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRateLimitRuleMutation, UpdateRateLimitRuleMutationVariables>;
export const DeleteRateLimitRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRateLimitRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRateLimitRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRateLimitRuleMutation, DeleteRateLimitRuleMutationVariables>;
export const RateLimitRulesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RateLimitRulesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateLimitRulesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateLimitKbpsDownload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rateLimitKbpsUpload' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPerSource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'protocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'srcMac' } },
                { kind: 'Field', name: { kind: 'Name', value: 'srcPrefix' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'srcPortRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dstMac' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dstPortRange' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlanBindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterfaceBindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RateLimitRulesForNetworkQuery, RateLimitRulesForNetworkQueryVariables>;
export const RoutesForVirtualDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RoutesForVirtualDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkRoutesForVirtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'destination' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gateway' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'vlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterface' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'internetServicePlan' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'provider' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoVPNGroup' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ipSecTunnel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientVPNServer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endpoint' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RoutesForVirtualDeviceQuery, RoutesForVirtualDeviceQueryVariables>;
export const PolicyRoutingRulesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PolicyRoutingRulesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyRoutingRulesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PolicyRoutingRulesFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyRoutingRulesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyRoutingRule' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'srcPrefix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srcVLAN' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srcPortRanges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dstPortRanges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'protocols' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PolicyRoutingRulesForNetworkQuery,
  PolicyRoutingRulesForNetworkQueryVariables
>;
export const PolicyRoutingRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PolicyRoutingRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyRoutingRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PolicyRoutingRulesFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyRoutingRulesFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PolicyRoutingRule' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'srcPrefix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srcVLAN' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srcPortRanges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dstPortRanges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lower' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upper' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'protocols' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasWANActivity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PolicyRoutingRuleQuery, PolicyRoutingRuleQueryVariables>;
export const CreatePolicyRoutingRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePolicyRoutingRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePolicyRoutingRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPolicyRoutingRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePolicyRoutingRuleMutation,
  CreatePolicyRoutingRuleMutationVariables
>;
export const UpdatePolicyRoutingRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePolicyRoutingRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePolicyRoutingRuleInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePolicyRoutingRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePolicyRoutingRuleMutation,
  UpdatePolicyRoutingRuleMutationVariables
>;
export const DeletePolicyRoutingRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePolicyRoutingRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePolicyRoutingRule' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePolicyRoutingRuleMutation,
  DeletePolicyRoutingRuleMutationVariables
>;
export const UpdatePolicyRoutingRuleBindingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePolicyRoutingRuleBindings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BindPolicyRoutingRulesInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderedRuleUUIDs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindPolicyRoutingRules' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderedRuleUUIDs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderedRuleUUIDs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePolicyRoutingRuleBindingsMutation,
  UpdatePolicyRoutingRuleBindingsMutationVariables
>;
export const StaticRoutesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StaticRoutesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staticRoutesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StaticRouteFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StaticRouteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StaticRoute' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gatewayIP' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gatewayDHCPStaticMapping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hostname' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StaticRoutesForNetworkQuery, StaticRoutesForNetworkQueryVariables>;
export const StaticRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StaticRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'staticRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StaticRouteFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StaticRouteFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StaticRoute' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dstPrefix' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gatewayIP' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gatewayDHCPStaticMapping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hostname' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StaticRouteQuery, StaticRouteQueryVariables>;
export const CreateStaticRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateStaticRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateStaticRouteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStaticRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateStaticRouteMutation, CreateStaticRouteMutationVariables>;
export const UpdateStaticRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateStaticRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateStaticRouteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStaticRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateStaticRouteMutation, UpdateStaticRouteMutationVariables>;
export const DeleteStaticRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteStaticRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStaticRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteStaticRouteMutation, DeleteStaticRouteMutationVariables>;
export const VlaNsStaticMappingsRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VLANsStaticMappingsRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vlans' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientAssignmentProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dhcpRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'staticMappings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hostname' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VlaNsStaticMappingsRulesQuery, VlaNsStaticMappingsRulesQueryVariables>;
export const UpdateNotificationSettingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateNotificationSettingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateNotificationSettingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNotificationSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateNotificationSettingMutationMutation,
  UpdateNotificationSettingMutationMutationVariables
>;
export const NotificationSettingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NotificationSettingsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationSettingsForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alertReceiverUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'arguments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'parameterUUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationDefinition' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NotificationSettingsQueryQuery,
  NotificationSettingsQueryQueryVariables
>;
export const CreateNotificationSettingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNotificationSettingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateNotificationSettingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNotificationSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNotificationSettingMutationMutation,
  CreateNotificationSettingMutationMutationVariables
>;
export const NotificationDefinitionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NotificationDefinitionsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alertDefinitions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NotificationDefinitionsQueryQuery,
  NotificationDefinitionsQueryQueryVariables
>;
export const DeleteNotificationSettingMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteNotificationSettingMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNotificationSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteNotificationSettingMutationMutation,
  DeleteNotificationSettingMutationMutationVariables
>;
export const UpdateServiceDiscoveryForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateServiceDiscoveryForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateNetworkInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateServiceDiscoveryForNetworkMutation,
  UpdateServiceDiscoveryForNetworkMutationVariables
>;
export const UpdateNetworkNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpdateNetworkNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustomer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConfig1WosUpgradesEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUpgradeSensitive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiWANAlgorithm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboarding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'jobID' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subdivisionCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryISO2' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateNetworkNetworkQuery, UpdateNetworkNetworkQueryVariables>;
export const CreateNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateNetworkInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateNetworkMutation, CreateNetworkMutationVariables>;
export const CreateNetworkFromCopyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNetworkFromCopy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sourceNetworkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateNetworkCopyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNetworkFromCopy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sourceNetworkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sourceNetworkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateNetworkFromCopyMutation, CreateNetworkFromCopyMutationVariables>;
export const UpdateNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateNetworkInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateNetworkMutation, UpdateNetworkMutationVariables>;
export const DeleteNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteNetworkMutation, DeleteNetworkMutationVariables>;
export const JobTrackersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'JobTrackers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobTrackers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSqFt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locationCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobTrackersQuery, JobTrackersQueryVariables>;
export const CreateNetworkOnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNetworkOnboarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobTrackerID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNetworkOnboarding' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobTrackerID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'jobTrackerID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNetworkOnboardingMutation,
  CreateNetworkOnboardingMutationVariables
>;
export const MaintenanceWindowForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MaintenanceWindowForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maintenanceWindowForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'recurrence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOptedOut' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startHourOfDayLocal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endHourOfDayLocal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDayOfWeekLocal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDayOfWeekLocal' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MaintenanceWindowForNetworkQuery,
  MaintenanceWindowForNetworkQueryVariables
>;
export const UpdateMaintenanceWindowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMaintenanceWindow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateMaintenanceWindowInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMaintenanceWindowForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMaintenanceWindowMutation,
  UpdateMaintenanceWindowMutationVariables
>;
export const CopyMultiWanFromConfig1Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyMultiWANFromConfig1' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyNetworkMultiWANAlgorithmFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyMultiWanFromConfig1Mutation,
  CopyMultiWanFromConfig1MutationVariables
>;
export const CopyFirewallRulesFromConfig1Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyFirewallRulesFromConfig1' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyFirewallRulesFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyFirewallRulesFromConfig1Mutation,
  CopyFirewallRulesFromConfig1MutationVariables
>;
export const CopyPortForwardingRulesFromConfig1Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyPortForwardingRulesFromConfig1' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyPortForwardingRulesFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyPortForwardingRulesFromConfig1Mutation,
  CopyPortForwardingRulesFromConfig1MutationVariables
>;
export const CopyUplinkPhyInterfacesFromConfig1Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyUplinkPhyInterfacesFromConfig1' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyUplinkPhyInterfacesFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyUplinkPhyInterfacesFromConfig1Mutation,
  CopyUplinkPhyInterfacesFromConfig1MutationVariables
>;
export const UpdateAlertReceiverWithTargetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAlertReceiverWithTargets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'alertReceiverUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAlertReceiverWithTargetsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAlertReceiverWithTargets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'alertReceiverUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'alertReceiverUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAlertReceiverWithTargetsMutation,
  UpdateAlertReceiverWithTargetsMutationVariables
>;
export const CreateAlertReceiverWithTargetsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAlertReceiverWithTargetsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAlertReceiverWithTargetsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlertReceiverWithTargets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAlertReceiverWithTargetsMutationMutation,
  CreateAlertReceiverWithTargetsMutationMutationVariables
>;
export const UpdateWebhookMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateWebhookMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAlertTargetWebhookInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAlertTargetWebhook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateWebhookMutationMutation, UpdateWebhookMutationMutationVariables>;
export const CreateAlertTargetWebhookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAlertTargetWebhook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAlertTargetWebhookInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAlertTargetWebhook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signingSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAlertTargetWebhookMutation,
  CreateAlertTargetWebhookMutationVariables
>;
export const DeleteAlertReceiverMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAlertReceiverMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'alertReceiverUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAlertReceiverWithTargets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'alertReceiverUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'alertReceiverUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAlertReceiverMutationMutation,
  DeleteAlertReceiverMutationMutationVariables
>;
export const AlertTargetsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AlertTargetsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alertTargets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webhooks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signingSecret' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AlertTargetsQueryQuery, AlertTargetsQueryQueryVariables>;
export const AlertTargetsWebhookQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'alertTargetsWebhookQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alertTargetsWebhook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signingSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AlertTargetsWebhookQueryQuery, AlertTargetsWebhookQueryQueryVariables>;
export const AlertReceiversQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AlertReceiversQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alertReceiversForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'targets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AlertTargetEmail' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AlertTargetWebhook' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AlertReceiversQueryQuery, AlertReceiversQueryQueryVariables>;
export const DeleteAlertTargetWebhookMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAlertTargetWebhookMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAlertTargetWebhook' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAlertTargetWebhookMutationMutation,
  DeleteAlertTargetWebhookMutationMutationVariables
>;
export const WanSpeedTestMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WANSpeedTestMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RPCWANSpeedtestInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcWANSpeedtest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'downloadSpeedMbps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadSpeedMbps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latencyNs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jitterNs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'targetHost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WanSpeedTestMutationMutation, WanSpeedTestMutationMutationVariables>;
export const GetCompanyUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetCompanyUserInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyUserBody' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyUserBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyMembershipRole' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>;
export const GetCompanyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetCompanyUserInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUserUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CompanyUserBody' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyUserBody' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyMembershipRole' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCompanyUserQuery, GetCompanyUserQueryVariables>;
export const DeleteCompanyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCompanyUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCompanyUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUserUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCompanyUserMutation, DeleteCompanyUserMutationVariables>;
export const ReactivateCompanyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReactivateCompanyUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateCompanyUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUserUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReactivateCompanyUserMutation, ReactivateCompanyUserMutationVariables>;
export const CreateCompanyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCompanyUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCompanyUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCompanyUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>;
export const UpdateCompanyUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateCompanyUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUserUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>;
export const UpdateUserRolesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserRoles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserRoleInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserRoles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUserUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>;
export const AssignOperatorRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AssignOperatorRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignOperatorRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUserUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssignOperatorRoleMutation, AssignOperatorRoleMutationVariables>;
export const RemoveOperatorRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveOperatorRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeOperatorRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyUserUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveOperatorRoleMutation, RemoveOperatorRoleMutationVariables>;
export const RadioProfilesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RadioProfilesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'radioProfilesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefault' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band2_4GIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band2_4GAutoChannelIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band2_4GAllowedChannels' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'band2_4GAutoChannelWidthIsEnabled' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'band2_4GAutoTxPowerIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band2_4GChannelWidthMHz' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band2_4GAutoTxPowerMaxdBm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band2_4GAutoTxPowerMindBm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GAutoChannelIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GAllowedChannels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GAutoChannelWidthIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GAutoTxPowerIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GChannelWidthMHz' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GAutoTxPowerMaxdBm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GAutoTxPowerMindBm' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RadioProfilesQueryQuery, RadioProfilesQueryQueryVariables>;
export const CreateRadioProfileMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRadioProfileMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateRadioProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createRadioProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRadioProfileMutationMutation,
  CreateRadioProfileMutationMutationVariables
>;
export const UpdateRadioProfileMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRadioProfileMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateRadioProfileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateRadioProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRadioProfileMutationMutation,
  UpdateRadioProfileMutationMutationVariables
>;
export const DeleteRadioProfileMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRadioProfileMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRadioProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRadioProfileMutationMutation,
  DeleteRadioProfileMutationMutationVariables
>;
export const CopyRadioSettingsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyRadioSettingsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyRadioSettingsForNetworkFromConfig1' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: '__typename' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyRadioSettingsMutationMutation,
  CopyRadioSettingsMutationMutationVariables
>;
export const RpceapolTestMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RPCEAPOLTestMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RPCEapolTestForSerialsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcWosEapolTestForSerials' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error_reason' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RpceapolTestMutationMutation, RpceapolTestMutationMutationVariables>;
export const SsiDsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SSIDsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ssidsForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ssid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientSteeringAssociationSteeringIsEnabled' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientSteeringPostassociationSteeringIsEnabled' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is80211axEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isGuest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isHidden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isIsolateClientsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'encryptionProtocol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'password' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBand2GAllowed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band2GMinimumBasicRateKbps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isBand5GAllowed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'passwordRotationCadence' } },
                { kind: 'Field', name: { kind: 'Name', value: 'passwordRotationDayOfMonthLocal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'passwordRotationDayOfWeekLocal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'passwordRotationHourOfDayLocal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'passwordLastRotatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'passwordNextRotationAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band5GMinimumBasicRateKbps' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabledForAllAccessPoints' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roaming80211rIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roaming80211kIsEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roaming80211vIsEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'encryption80211wProtectedManagementFramesMode' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20HasProfile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20Enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20OperatorNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20VenueNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20VenueGroup' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20VenueType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20AccessNetworkType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20RoamingConsortiumOIs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20DomainNames' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20NaiRealms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hs20MccMncPairs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dynamicVLANMode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'radiusCoaEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'radiusCoaPort' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'primaryEncryption8021X' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondaryEncryption8021X' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enabledAccessPointVirtualDevices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SsiDsQueryQuery, SsiDsQueryQueryVariables>;
export const Hs20AccessNetworkTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Hs20AccessNetworkTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hs20ValidTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accessNetworkTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'venueTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eapMethods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'method' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mostUsed' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'authParams' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'param' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'validValues' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Hs20AccessNetworkTypesQuery, Hs20AccessNetworkTypesQueryVariables>;
export const CreateSsidMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSSIDMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSSIDInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSSID' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSsidMutationMutation, CreateSsidMutationMutationVariables>;
export const UpdateSsidMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSSIDMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSSIDInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSSID' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSsidMutationMutation, UpdateSsidMutationMutationVariables>;
export const DeleteSsidMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSSIDMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSSID' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteSsidMutationMutation, DeleteSsidMutationMutationVariables>;
export const CopySsiDsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopySSIDsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copySSIDsFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CopySsiDsMutationMutation, CopySsiDsMutationMutationVariables>;
export const RadiusProfilesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RadiusProfilesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'encryption8021XsForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authServerIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authServerPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authServerSecret' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountingServerIPAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountingServerPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountingServerSecret' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RadiusProfilesQueryQuery, RadiusProfilesQueryQueryVariables>;
export const CreateRadiusProfileMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateRadiusProfileMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateEncryption8021XInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEncryption8021X' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateRadiusProfileMutationMutation,
  CreateRadiusProfileMutationMutationVariables
>;
export const UpdateRadiusProfileMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRadiusProfileMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateEncryption8021XInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEncryption8021X' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRadiusProfileMutationMutation,
  UpdateRadiusProfileMutationMutationVariables
>;
export const DeleteRadiusProfileMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRadiusProfileMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEncryption8021X' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteRadiusProfileMutationMutation,
  DeleteRadiusProfileMutationMutationVariables
>;
export const WirelessConnectionEventsByAccessPointQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WirelessConnectionEventsByAccessPointQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClientMetricsTimeseriesFilterInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wirelessClientConnectionEventsByAP' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apVirtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'band' } },
                { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'failedStep' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reasonCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeToConnect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is80211rRoam' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ssid' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ssid' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousVirtualDeviceConnectedTo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WirelessConnectionEventsByAccessPointQueryQuery,
  WirelessConnectionEventsByAccessPointQueryQueryVariables
>;
export const WirelessClientMetricsByAccessPointQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WirelessClientMetricsByAccessPointQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ClientMetricsTimeseriesFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wirelessClientMetricsByAP' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apVirtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'signal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noise' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tx' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rx' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'noise' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snr' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txMulticastBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxMulticastBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txUnicastBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxUnicastBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'clientCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WirelessClientMetricsByAccessPointQueryQuery,
  WirelessClientMetricsByAccessPointQueryQueryVariables
>;
export const ApChannelUtilizationByNetworkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'APChannelUtilizationByNetworkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChannelUtilizationTimeseriesFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelUtilizationByNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'band' } },
                { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalUtilization' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApChannelUtilizationByNetworkQueryQuery,
  ApChannelUtilizationByNetworkQueryQueryVariables
>;
export const ApChannelUtilizationByAccessPointQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'APChannelUtilizationByAccessPointQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChannelUtilizationTimeseriesFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'channelUtilizationByAP' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apVirtualDeviceUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'band' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalUtilization' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApChannelUtilizationByAccessPointQueryQuery,
  ApChannelUtilizationByAccessPointQueryQueryVariables
>;
export const AccessPointsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccessPointsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUptime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeIsDev' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'ACCESS_POINT' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingNosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AccessPointVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccessPointUptimeFragment' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeUptime' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radioProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'band2_4GIsEnabled' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band2_4GChannelWidthMHz' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band2_4GAutoChannelIsEnabled' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'band5GIsEnabled' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band5GChannelWidthMHz' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band5GAutoChannelIsEnabled' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radioSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band2_4GPrimaryChannel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band2_4GTransmitPowerdBm' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band5GPrimaryChannel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band5GTransmitPowerdBm' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'connectedClients' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'band' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ssidName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bootHistory' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isDev' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeIsDev' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AccessPointHardwareDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isInCurrentControllerMACTable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phyInterfaceConnectedTo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isEthernet' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccessPointUptimeFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AccessPointVirtualDevice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uptime' } }],
      },
    },
  ],
} as unknown as DocumentNode<AccessPointsQueryQuery, AccessPointsQueryQueryVariables>;
export const AccessPointQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccessPointQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUptime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isConsoleEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pendingNosVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nosVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'version' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduledAt' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'AccessPointVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isLEDDarkModeEnabled' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccessPointUptimeFragment' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'include' },
                            arguments: [
                              {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'if' },
                                value: {
                                  kind: 'Variable',
                                  name: { kind: 'Name', value: 'includeUptime' },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radioProfile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'band2_4GIsEnabled' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band2_4GChannelWidthMHz' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band2_4GAutoChannelIsEnabled' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'band5GIsEnabled' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band5GChannelWidthMHz' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band5GAutoChannelIsEnabled' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'radioSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band2_4GPrimaryChannel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band2_4GTransmitPowerdBm' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band5GPrimaryChannel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'band5GTransmitPowerdBm' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'connectedClients' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'band' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ssidName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tunnelIPAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'disconnectedFromBackendAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bootHistory' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'buildName' } },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'AccessPointHardwareDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phyInterfaceConnectedTo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isSFP' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'portSpeedMbps' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'maxSpeedMbps' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccessPointUptimeFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AccessPointVirtualDevice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uptime' } }],
      },
    },
  ],
} as unknown as DocumentNode<AccessPointQueryQuery, AccessPointQueryQueryVariables>;
export const CopyAccessPointLabelsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyAccessPointLabelsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyAccessPointConfig1LocationsToConfig2Labels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyAccessPointLabelsMutationMutation,
  CopyAccessPointLabelsMutationMutationVariables
>;
export const UpdateVirtualDevicesMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateVirtualDevicesMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUIDs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateVirtualDeviceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVirtualDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'virtualDeviceUUIDs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'virtualDeviceUUIDs' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVirtualDevicesMutationMutation,
  UpdateVirtualDevicesMutationMutationVariables
>;
export const FallbackAccessPointHardwareQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FallbackAccessPointHardwareQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'label' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FallbackAccessPointHardwareQueryQuery,
  FallbackAccessPointHardwareQueryQueryVariables
>;
export const BssiDsForNetworkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BSSIDsForNetworkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bssidsForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'BSSID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SSID' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ssid' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'radioBand' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accessPointHardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BssiDsForNetworkQueryQuery, BssiDsForNetworkQueryQueryVariables>;
export const AutoVpnGroupsForCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutoVPNGroupsForCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoVPNGroupsForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AutoVPNGroupFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoVPNMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AutoVPNMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permittedVLANs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uplink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPrefixLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hubUplinkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'listenPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectionInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoVPNGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AutoVPNGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubNetworkUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubNetwork' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubUplink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPrefixLength' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHubFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AutoVPNMemberFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstPrefixLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstGateway' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoVpnGroupsForCompanyQuery, AutoVpnGroupsForCompanyQueryVariables>;
export const AutoVpnGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutoVPNGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoVPNGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AutoVPNGroupFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoVPNMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AutoVPNMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permittedVLANs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uplink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPrefixLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hubUplinkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'listenPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectionInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoVPNGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AutoVPNGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hubNetworkUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubNetwork' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubUplink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPrefixLength' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isHubFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'members' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AutoVPNMemberFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'routes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstPrefixLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstGateway' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoVpnGroupQuery, AutoVpnGroupQueryVariables>;
export const AutoVpnMemberQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutoVPNMemberQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autoVPNMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AutoVPNMemberFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutoVPNMemberFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AutoVPNMember' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isFailoverEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permittedVLANs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipV4ClientPrefixLength' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uplink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'addressPrefixLength' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hubUplinkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'listenPort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phyInterfaceUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectionInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeRx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastHandshakeTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketTx' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastPacketRx' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoVpnMemberQueryQuery, AutoVpnMemberQueryQueryVariables>;
export const CreateAutoVpnGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAutoVPNGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAutoVPNGroupInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAutoVPNGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hubNetworkUUID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAutoVpnGroupMutation, CreateAutoVpnGroupMutationVariables>;
export const UpdateAutoVpnGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAutoVPNGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAutoVPNGroupInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAutoVPNGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAutoVpnGroupMutation, UpdateAutoVpnGroupMutationVariables>;
export const DeleteAutoVpnGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAutoVPNGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAutoVPNGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hubNetworkUUID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAutoVpnGroupMutation, DeleteAutoVpnGroupMutationVariables>;
export const AddNetworkToAutoVpnGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddNetworkToAutoVPNGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddNetworkToAutoVPNGroupInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addNetworkToAutoVPNGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddNetworkToAutoVpnGroupMutation,
  AddNetworkToAutoVpnGroupMutationVariables
>;
export const UpdateAutoVpnMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAutoVPNMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAutoVPNMemberInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAutoVPNMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAutoVpnMemberMutation, UpdateAutoVpnMemberMutationVariables>;
export const RemoveNetworkFromAutoVpnGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveNetworkFromAutoVPNGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeNetworkFromAutoVPNGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveNetworkFromAutoVpnGroupMutation,
  RemoveNetworkFromAutoVpnGroupMutationVariables
>;
export const CreateAutoVpnRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAutoVPNRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAutoVPNRouteInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAutoVPNRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'groupUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstGateway' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dstPrefixLength' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAutoVpnRouteMutation, CreateAutoVpnRouteMutationVariables>;
export const DeleteAutoVpnRouteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAutoVPNRoute' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAutoVPNRoute' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAutoVpnRouteMutation, DeleteAutoVpnRouteMutationVariables>;
export const NetworksWithControllerPortsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworksWithControllerPorts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networksForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevices' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'deviceType' },
                            value: { kind: 'EnumValue', value: 'CONTROLLER' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phyInterfaces' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhyInterfaceLabelFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhyInterface' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hardwareLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetServicePlan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'provider' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NetworksWithControllerPortsQuery,
  NetworksWithControllerPortsQueryVariables
>;
export const CreateNosFirmwareUpgradeMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNOSFirmwareUpgradeMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateBulkNOSUpgradeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBulkNOSUpgrade' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNosFirmwareUpgradeMutationMutation,
  CreateNosFirmwareUpgradeMutationMutationVariables
>;
export const BulkNosUpgradeQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BulkNOSUpgradeQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkNOSUpgrade' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nosVersionID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkNosUpgradeQueryQuery, BulkNosUpgradeQueryQueryVariables>;
export const BulkNosUpgradesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BulkNOSUpgradesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BulkNOSUpgradesFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkNOSUpgrades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nosVersionID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkNosUpgradesQueryQuery, BulkNosUpgradesQueryQueryVariables>;
export const NetworksByFilterQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworksByFilterQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NetworksFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportTier' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hardwareDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pinnedNOSVersionID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pendingNOSVersionID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUpgradedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUpgradeSensitive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworksByFilterQueryQuery, NetworksByFilterQueryQueryVariables>;
export const GetEventLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startTime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endTime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recentEventLogEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '100' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'IntValue', value: '0' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startTime' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startTime' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endTime' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endTime' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'eventType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'generatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'properties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'WANStatusChangeProperties' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'virtualDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'phyInterfaces' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'UUID' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'portNumber' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uplinkPriority' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isUplink' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activeWANConnections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'externalAddresses' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'internetServicePlanProvider' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'port' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEventLogsQuery, GetEventLogsQueryVariables>;
export const NetworkClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'networkClients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'NetworkClientsFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkClients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alias' } },
                { kind: 'Field', name: { kind: 'Name', value: 'apLocation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'apSerialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isWireless' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSeen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dhcpLeaseExpiresAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'noise' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalRxBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rxRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ssid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'txRate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalTxBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vlanUUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectedVLAN' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'associatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'throughput' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'txRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxRate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'serviceTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wirelessScore' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkClientsQuery, NetworkClientsQueryVariables>;
export const GetCaptivePortalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCaptivePortal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'vlanUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captivePortalForVLAN' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vlanUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'vlanUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'displaySettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fontColor' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'logoImageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'redirectURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'termsAndConditions' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'vlans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCaptivePortalQuery, GetCaptivePortalQueryVariables>;
export const GetNetworkClientsTxRxPerDayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getNetworkClientsTxRxPerDay' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'numDaysLookback' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkClientsTxRxPerDay' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numDaysLookback' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'numDaysLookback' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateString' } },
                { kind: 'Field', name: { kind: 'Name', value: 'txBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rxBytes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNetworkClientsTxRxPerDayQuery,
  GetNetworkClientsTxRxPerDayQueryVariables
>;
export const CompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Company' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustomer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportGroup' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyQuery, CompanyQueryVariables>;
export const NetworkStatusQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworkStatusQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'macAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkStatusQueryQuery, NetworkStatusQueryQueryVariables>;
export const NetworksForCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworksForCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networksForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'NetworkForCompanyFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetworkForCompanyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Network' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportGroup' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'CONTROLLER' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highAvailability' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subdivisionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryISO2' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enabledServiceDiscoveryTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'multiWANAlgorithm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedNOSVersionID' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworksForCompanyQuery, NetworksForCompanyQueryVariables>;
export const NetworkByUuidQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworkByUUIDQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'NetworkForCompanyFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetworkForCompanyFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Network' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustomer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'company' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportGroup' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'CONTROLLER' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highAvailability' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subdivisionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryISO2' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'enabledServiceDiscoveryTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'multiWANAlgorithm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pinnedNOSVersionID' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NetworkByUuidQueryQuery, NetworkByUuidQueryQueryVariables>;
export const IsNosFeatureEnabledForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsNosFeatureEnabledForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nosFeatureKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isNosFeatureEnabledForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nosFeatureKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nosFeatureKey' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IsNosFeatureEnabledForNetworkQuery,
  IsNosFeatureEnabledForNetworkQueryVariables
>;
export const AreNosFeatureEnabledForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AreNosFeatureEnabledForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nosFeatureKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areNosFeaturesEnabledForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nosFeatureKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nosFeatureKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AreNosFeatureEnabledForNetworkQuery,
  AreNosFeatureEnabledForNetworkQueryVariables
>;
export const IsNosFeatureEnabledForCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsNosFeatureEnabledForCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nosFeatureKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isNosFeatureEnabledForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nosFeatureKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nosFeatureKey' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IsNosFeatureEnabledForCompanyQuery,
  IsNosFeatureEnabledForCompanyQueryVariables
>;
export const AreNosFeatureEnabledForCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AreNosFeatureEnabledForCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nosFeatureKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'areNosFeaturesEnabledForCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nosFeatureKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nosFeatureKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AreNosFeatureEnabledForCompanyQuery,
  AreNosFeatureEnabledForCompanyQueryVariables
>;
export const ValidChannelsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidChannelsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validChannels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'band' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channels' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidChannelsQueryQuery, ValidChannelsQueryQueryVariables>;
export const CompanyUuidQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyUUIDQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyUuidQueryQuery, CompanyUuidQueryQueryVariables>;
export const PermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Permissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PermissionsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PermissionsQuery, PermissionsQueryVariables>;
export const IpSecTunnelsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IPSecTunnelsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ipSecTunnelsForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInitiator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationAlgorithm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'encryptionAlgorithm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keyExchangeDHGroup' } },
                { kind: 'Field', name: { kind: 'Name', value: 'right' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rightPrefixes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rightID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leftID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boundPhyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hardwareDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isSpare' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isConnectedToBackend' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boundVLANs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'saStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remoteHostIP4Address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'establishedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rekeyAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reauthAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'childSAStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxPackets' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxLast' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txLast' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'childRekeyAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'childLifetime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'latencyMs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IpSecTunnelsQueryQuery, IpSecTunnelsQueryQueryVariables>;
export const IpSecTunnelQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IPSecTunnelQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ipSecTunnel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'UUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'right' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rightPrefixes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rightID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leftID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isInitiator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authenticationAlgorithm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'encryptionAlgorithm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keyExchangeDHGroup' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boundPhyInterface' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'portNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'virtualDevice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hardwareDevice' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'isSpare' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isConnectedToBackend' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boundVLANs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vlanID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'saStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'remoteHostIP4Address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'establishedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rekeyAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reauthAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'childSAStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxPackets' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rxLast' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'txLast' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'childRekeyAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'childLifetime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'latencyMs' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IpSecTunnelQueryQuery, IpSecTunnelQueryQueryVariables>;
export const UpdateIpSecTunnelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateIPSecTunnel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateIPSecTunnelInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIPSecTunnel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateIpSecTunnelMutation, UpdateIpSecTunnelMutationVariables>;
export const DeleteIpSecTunnelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteIPSecTunnel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIPSecTunnel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteIpSecTunnelMutation, DeleteIpSecTunnelMutationVariables>;
export const CreateIpSecTunnelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateIPSecTunnel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateIPSecTunnelInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createIPSecTunnel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIpSecTunnelMutation, CreateIpSecTunnelMutationVariables>;
export const RpcRestartIpSecTunnelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RpcRestartIPSecTunnel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ipSecTunnelUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rpcRestartIPSecTunnel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ipSecTunnelUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ipSecTunnelUUID' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RpcRestartIpSecTunnelMutation, RpcRestartIpSecTunnelMutationVariables>;
export const DeleteNosFirmwareUpgradeMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteNOSFirmwareUpgradeMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBulkNOSUpgrade' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteNosFirmwareUpgradeMutationMutation,
  DeleteNosFirmwareUpgradeMutationMutationVariables
>;
export const SwitchCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SwitchCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'SWITCH' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SwitchCountQueryQuery, SwitchCountQueryQueryVariables>;
export const VirtualDevicesForTopologyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VirtualDevicesForTopology' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isConnectedToBackend' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ControllerVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterfaces' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'connectedDevices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hardwareDevice' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'serialNumber' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'deviceType' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isActive' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'virtualDeviceUUID' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isConnectedToBackend' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'internetServicePlan' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'provider' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isUplinkActive' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'SwitchVirtualDevice' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phyInterfaces' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isUplink' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'connectedDevices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hardwareDevice' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'serialNumber' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'deviceType' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isActive' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'virtualDeviceUUID' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'isConnectedToBackend' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VirtualDevicesForTopologyQuery,
  VirtualDevicesForTopologyQueryVariables
>;
export const MutationAuditLogEntriesForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MutationAuditLogEntriesForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MutationAuditLogFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mutationAuditLogEntriesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'args' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MutationAuditLogEntriesForNetworkQuery,
  MutationAuditLogEntriesForNetworkQueryVariables
>;
export const StatsForVirtualDevicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'statsForVirtualDevices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'NetworkStatsFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statsForVirtualDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'observedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'insertedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'virtualDeviceUUID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rawData' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatsForVirtualDevicesQuery, StatsForVirtualDevicesQueryVariables>;
export const OnboardingForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'onboardingForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkSlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'networkBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companySlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkSlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkSlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboarding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'networkUUID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'jobData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'installationStartDate' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'installationEndDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'jobOwnerUser' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'jobStage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'jobStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'jobTechnicianName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'jobTechnicianPhone' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onsiteContactInformation' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scheduleSiteSurveyLink' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'siteSurveyDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'siteSurveyStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'targetGoLiveDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalSqFt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OnboardingForNetworkQuery, OnboardingForNetworkQueryVariables>;
export const NetworkForOnboardingDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NetworkForOnboardingDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'network' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onboardingDocument' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'document' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'delegations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NetworkForOnboardingDocumentQuery,
  NetworkForOnboardingDocumentQueryVariables
>;
export const UpsertNetworkOnboardingDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertNetworkOnboardingDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'document' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSONObject' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertNetworkOnboardingDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'document' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'document' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertNetworkOnboardingDocumentMutation,
  UpsertNetworkOnboardingDocumentMutationVariables
>;
export const UpsertNetworkOnboardingFieldDelegationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertNetworkOnboardingFieldDelegation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fieldName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpsertNetworkOnboardingFieldDelegationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertNetworkOnboardingFieldDelegation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fieldName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fieldName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpsertNetworkOnboardingFieldDelegationMutation,
  UpsertNetworkOnboardingFieldDelegationMutationVariables
>;
export const DeleteNetworkOnboardingFieldDelegationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteNetworkOnboardingFieldDelegation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNetworkOnboardingFieldDelegation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'UUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteNetworkOnboardingFieldDelegationMutation,
  DeleteNetworkOnboardingFieldDelegationMutationVariables
>;
export const LatestDeviceConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LatestDeviceConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestDeviceConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'configJSON' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LatestDeviceConfigQuery, LatestDeviceConfigQueryVariables>;
export const DeviceConfigOverrideDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeviceConfigOverride' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deviceConfigOverride' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'configJSON' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeviceConfigOverrideQuery, DeviceConfigOverrideQueryVariables>;
export const UpdateDeviceConfigOverrideDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDeviceConfigOverride' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'configJSON' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'upsertDeviceConfigOverride' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serialNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serialNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'configJSON' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'configJSON' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateDeviceConfigOverrideMutation,
  UpdateDeviceConfigOverrideMutationVariables
>;
export const DeviceForNetworkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeviceForNetwork' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevicesForNetwork' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hardwareDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeviceForNetworkQuery, DeviceForNetworkQueryVariables>;
export const CopyIpSecTunnelFromConfig1ToConfig2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CopyIPSecTunnelFromConfig1ToConfig2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CopyIPSecTunnelFromConfig1ToConfig2Input' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'copyIPSecTunnelFromConfig1ToConfig2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'networkUUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'networkUUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyIpSecTunnelFromConfig1ToConfig2Mutation,
  CopyIpSecTunnelFromConfig1ToConfig2MutationVariables
>;
export const CompaniesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompaniesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listCompanies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustomer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportTier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NetworkFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetworkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Network' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUpgradeSensitive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpgradedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highAvailabilityConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deviceType' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subdivisionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryISO2' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompaniesQueryQuery, CompaniesQueryQueryVariables>;
export const CompanyQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustomer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportTier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'networks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'NetworkFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NetworkFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Network' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companySlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUpgradeSensitive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUpgradedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highAvailabilityConfig' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'UUID' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'virtualDevices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deviceType' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mailingAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'line2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subdivisionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryISO2' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompanyQueryQuery, CompanyQueryQueryVariables>;
export const AllSecurityAppliancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllSecurityAppliances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hardwareDevices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deviceType' },
                      value: { kind: 'EnumValue', value: 'CONTROLLER' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'offset' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'serialNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deviceModel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'virtualDevice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'network' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'UUID' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllSecurityAppliancesQuery, AllSecurityAppliancesQueryVariables>;
export const SsoOptionsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SSOOptionsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ssoOptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'companySlug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'companySlug' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectedDirectory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connections' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'connectedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SsoOptionsQueryQuery, SsoOptionsQueryQueryVariables>;
export const ConfigureSsoMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfigureSSOMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConfigureSSOInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'configureSSO' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'portalURL' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfigureSsoMutationMutation, ConfigureSsoMutationMutationVariables>;

import type { IconName } from '@meterup/atto';
import { HStack, Icon, MultiComboBox, MultiComboBoxItem, space } from '@meterup/atto';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { useCallback, useMemo } from 'react';

import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';

export interface FilterDef<Data extends object, Key extends string = string> {
  key: Key;
  label: string;
  icon?: IconName;
  predicate?: (row: Data) => boolean;
}

export function createFilterEnumeration<Data extends object, Key extends string = string>(
  filters: FilterDef<Data, Key>[],
  {
    defaultKey,
    urlKey = 'filter',
  }: {
    defaultKey?: Key;
    urlKey?: string;
  } = {},
) {
  function useCurrentFilterKeys(): Key[] {
    const [currentFilters] = useSearchParamsState<string>(urlKey, defaultKey);
    return (currentFilters?.split(',').filter((s) => filters.some((f) => f.key === s)) ??
      []) as Key[];
  }

  function useCurrentFilters(): FilterDef<Data, Key>[] {
    const filterKeys = useCurrentFilterKeys();
    return filters.filter((f) => filterKeys.includes(f.key));
  }

  function useCurrentPredicates(data: Data[]) {
    const currentFilters = useCurrentFilters();
    return useMemo(
      () =>
        currentFilters?.length
          ? data.filter((row) => currentFilters.some((filter) => filter.predicate?.(row) ?? true))
          : data,
      [data, currentFilters],
    );
  }

  const useResetCallback = () => {
    const [, setSearch] = useSearchParamsState<string>(urlKey, defaultKey);
    return useCallback(() => {
      setSearch(null);
    }, [setSearch]);
  };

  function TabSwitcher() {
    const currentKeys = useCurrentFilterKeys();
    const [, setSearch] = useSearchParamsState<string>(urlKey, defaultKey);

    const { state } = useCommand();

    useRegisterCommands([
      state.nodeFactory.directory({
        id: `filter-by-${urlKey}`,
        display: `Filter by ${urlKey}…`,
        label: `Filter by ${urlKey}…`,
        priority: Priority.High,
        icon: 'filter',
        children: filters.map((filter) =>
          state.nodeFactory.action({
            id: filter.key,
            label: filter.label?.toString() || filter.key,
            display: filter.label,
            priority: Priority.High,
            onSelect() {
              setSearch(filter.key);
            },
          }),
        ),
      }),
    ]);

    return (
      <MultiComboBox
        icon="filter"
        id="filter"
        size="small"
        value={currentKeys}
        onValueChange={(keys) => {
          setSearch(Array.from(keys).join(','));
        }}
        placeholder="Select a filter..."
        showAdd
      >
        {filters.map((filterOption) => (
          <MultiComboBoxItem key={filterOption.key} textValue={filterOption.label}>
            <HStack spacing={space(4)} align="center" wrap="no-wrap">
              {filterOption.icon && <Icon icon={filterOption.icon} size={space(12)} />}
              {filterOption.label}
            </HStack>
          </MultiComboBoxItem>
        ))}
      </MultiComboBox>
    );
  }

  return {
    TabSwitcher,
    useCurrentFilterKeys,
    useCurrentFilters,
    useCurrentPredicate: useCurrentPredicates,
    useResetCallback,
  };
}

import {
  Button,
  darkThemeSelector,
  HStack,
  Pane,
  PaneContent,
  PaneHeader,
  Section,
  SectionContent,
  SectionHeader,
  sizing,
  space,
  Table,
  TableBody,
  TableCell,
  TableCellBuffer,
  TableCellState,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '@meterup/atto';
import { checkDefinedOrThrow, notify } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMutation } from '@tanstack/react-query';
import { saveAs } from 'file-saver-es';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { PatchPanelLayoutSwitchesQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';
import { useNetworkUUID } from '../../../hooks/useNetworkFromPath';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { colors, styled } from '../../../stitches';
import { logError } from '../../../utils/logError';

const switchesQuery = graphql(`
  query PatchPanelLayoutSwitches($networkUUID: UUID!) {
    network(UUID: $networkUUID) {
      label
      company {
        name
      }
      virtualDevices(filter: { deviceType: SWITCH }) {
        UUID
        __typename
        ... on SwitchVirtualDevice {
          label
          phyInterfaces {
            UUID
            portNumber
            label
            description
            isTrunkPort

            allowedVLANs {
              name
              vlanID
            }
            nativeVLAN {
              name
              vlanID
            }
          }
        }
      }
    }
  }
`);

const PatchPanelStack = styled(HStack, {
  padding: sizing.sidesOnly,

  variants: {
    expanded: {
      true: {},
      false: {
        overflow: 'auto',
        height: '100%',
      },
    },
  },
});

const PatchPanelSection = styled(Section, {
  margin: sizing.endsOnly,
  border: `1px solid ${colors.strokeNeutralLight}`,
  boxShadow: 'none !important',

  [darkThemeSelector]: {
    borderColor: colors.strokeNeutralDark,
    boxShadow: 'none !important',
  },

  variants: {
    expanded: {
      true: {},
      false: {
        width: '240px',
      },
    },
  },
});

const BorderedCell = {
  boxShadow: 'none',
  borderBottom: `1px solid ${colors.strokeNeutralLight}`,

  [darkThemeSelector]: {
    borderBottomColor: colors.strokeNeutralDark,
  },

  '&:not(:nth-last-child(3))': {
    borderRight: `1px solid ${colors.strokeNeutralLight}`,

    [darkThemeSelector]: {
      borderRightColor: colors.strokeNeutralDark,
    },
  },
};

const HeadRow = styled(TableHeadRow);

const HeadCell = styled(TableHeadCell, BorderedCell, {
  fontSize: '$14',
  lineHeight: '$20',
});

const Cell = styled(TableCell, BorderedCell);

const Row = styled(TableRow, {
  [`&:last-child ${Cell}`]: {
    borderBottom: 'none',
  },
});

const PatchPanelTable = styled(Table);

type PatchPanelVirtualDevice = PatchPanelLayoutSwitchesQuery['network']['virtualDevices'][number];
type PatchPanelLayoutSwitch = PatchPanelVirtualDevice & { __typename: 'SwitchVirtualDevice' };

function displayPhyInterfaceVLAN(
  pi: PatchPanelLayoutSwitch['phyInterfaces'][number],
): string | null {
  if (pi.isTrunkPort) return 'Trunk';

  if (pi.nativeVLAN) return `${pi.nativeVLAN?.name} (${pi.nativeVLAN?.vlanID})`;

  return null;
}

const SHOW_EXPANDED_KEY = 'expanded';

export default function PatchPanelLayout() {
  const networkUUID = checkDefinedOrThrow(useNetworkUUID());
  const [searchParams, setSearchParams] = useSearchParams();

  const network = useGraphQL(switchesQuery, { networkUUID }).data?.network;

  const switches = useMemo(() => {
    if (!network?.virtualDevices) return [];

    const filtered = network.virtualDevices.filter(
      (
        vd,
      ): vd is PatchPanelLayoutSwitchesQuery['network']['virtualDevices'][number] & {
        __typename: 'SwitchVirtualDevice';
      } => vd.__typename === 'SwitchVirtualDevice',
    );

    filtered.sort((a, b) => a.label.localeCompare(b.label));

    for (const sw of filtered) {
      sw.phyInterfaces.sort((a, b) => a.portNumber - b.portNumber);
    }

    return filtered;
  }, [network?.virtualDevices]);

  const showExpanded = searchParams.has(SHOW_EXPANDED_KEY);
  const toggleExpanded = useCallback(() => {
    setSearchParams((prev) => {
      if (prev.has(SHOW_EXPANDED_KEY)) {
        prev.delete(SHOW_EXPANDED_KEY);
      } else {
        prev.set(SHOW_EXPANDED_KEY, '1');
      }

      return prev;
    });
  }, [setSearchParams]);

  const paneContentRef = useRef<HTMLDivElement>();
  const { dark } = useContext(ThemeContext);

  const handleExport = useMutation(async () => {
    const element = checkDefinedOrThrow(paneContentRef.current);

    const { toBlob } = await import('html-to-image');

    // without this extra manual width the right padding is lost
    const width = element.scrollWidth + space(16);
    const height = element.scrollHeight;

    const blob = await toBlob(element, {
      backgroundColor: dark ? colors.bgApplicationDark.value : colors.bgApplicationLight.value,
      height,
      width,
      canvasHeight: height,
      canvasWidth: width,
      style: {
        overflow: 'unset',
        height: 'unset',
      },
    });

    if (!blob) throw new Error('Blob is null');
    return blob;
  });

  return (
    <Pane>
      <PaneHeader
        heading="Patch panel layout"
        actions={
          <>
            <Button
              onClick={() => {
                handleExport.mutate(undefined, {
                  onSuccess: (blob) => {
                    let filename = 'Patch panel layout.png';
                    if (network) {
                      filename = `${network.label} - ${filename}`;
                      if (network.company?.name) {
                        filename = `${network.company.name} - ${filename}`;
                      }
                    }

                    saveAs(blob, filename);
                  },
                  onError: (err) => {
                    logError(err, 'Failed saving blob');
                    notify('Sorry, there was a problem exporting the diagram.', {
                      variant: 'negative',
                    });
                  },
                });
              }}
              variant="secondary"
              icon="download"
              arrangement="leading-icon"
              loading={handleExport.isLoading}
              disabled={handleExport.isLoading}
            >
              Export
            </Button>
            <Button
              onClick={toggleExpanded}
              variant="secondary"
              icon={showExpanded ? 'eye-closed' : 'eye-open'}
              arrangement="leading-icon"
            >
              {showExpanded ? 'Hide' : 'Show'} expanded layout
            </Button>
          </>
        }
      />
      <PaneContent gutter="none" ref={paneContentRef}>
        <PatchPanelStack spacing={space(16)} align="start" expanded={handleExport.isLoading}>
          {switches.map((vd) => (
            <PatchPanelSection expanded={showExpanded} key={vd.UUID}>
              <SectionHeader heading={vd.label} icon="switch" />
              <SectionContent gutter="none">
                <PatchPanelTable key={vd.UUID}>
                  <TableHead>
                    <HeadRow>
                      <TableCellBuffer side="leading" head />
                      <TableCellState side="leading" head />
                      <HeadCell hideSortIcon>Panel / device</HeadCell>
                      <HeadCell hideSortIcon>Port #</HeadCell>
                      {showExpanded && (
                        <>
                          <HeadCell hideSortIcon>VLAN</HeadCell>
                          <HeadCell hideSortIcon>Description</HeadCell>
                        </>
                      )}
                      <TableCellState side="trailing" head />
                      <TableCellBuffer side="trailing" head />
                    </HeadRow>
                  </TableHead>
                  <TableBody>
                    {vd.phyInterfaces
                      .slice()
                      .sort()
                      .map((pi) => (
                        <Row key={pi.UUID}>
                          <TableCellBuffer side="leading" />
                          <TableCellState side="leading" />
                          <Cell isLeading>
                            <span style={{ whiteSpace: 'normal', maxWidth: '160px' }}>
                              {pi.label ?? ''}
                            </span>
                          </Cell>
                          <Cell>{pi.portNumber}</Cell>
                          {showExpanded && (
                            <>
                              <Cell>{displayPhyInterfaceVLAN(pi)} </Cell>
                              <Cell>{pi.description ?? ''}</Cell>
                            </>
                          )}
                          <TableCellState side="trailing" />
                          <TableCellBuffer side="trailing" />
                        </Row>
                      ))}
                  </TableBody>
                </PatchPanelTable>
              </SectionContent>
            </PatchPanelSection>
          ))}
        </PatchPanelStack>
      </PaneContent>
    </Pane>
  );
}

import {
  Alert,
  Button,
  FieldContainer,
  PrimaryFieldComposite,
  SecondaryFieldComposite,
  Section,
  SectionContent,
  Small,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, useGraphQLMutation } from '@meterup/graphql';
import { useCallback, useState } from 'react';

import type { NetworkClient } from '../../hooks/networkClients/useNetworkClients';
import type { PingResponse } from './utils';
import { useActiveControllerForNetwork } from '../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { NoValue } from '../NoValue';
import { PingTestAlert, PingTestFields, RPCPing2Mutation, StyledLoadingIcon } from './utils';

export type LANPingProps = {
  internal?: boolean;
  hostIp: string; // IP of the client to ping out to
  hostName: string; // Name/label of the client to ping out to
  vlan: NonNullable<NetworkClient['connectedVLAN']>;
};

export default function LANPingTest({ internal = false, hostIp, hostName, vlan }: LANPingProps) {
  const network = useNetwork();
  const activeController = useActiveControllerForNetwork(network);
  const serialNumber = activeController?.hardwareDevice?.serialNumber;
  expectDefinedOrThrow(serialNumber, new ResourceNotFoundError('Controller not found'));

  const { mutate, isLoading, isError } = useGraphQLMutation(RPCPing2Mutation);

  const [pingTestResult, setPingTestResult] = useState<PingResponse | undefined>();
  const [errorText, setErrorText] = useState<string | null>(null);

  const handleRunPing = useCallback(() => {
    setPingTestResult(undefined);
    mutate(
      {
        input: {
          serialNumber,
          host: hostIp,
          vlanUUID: vlan.UUID,
        },
      },
      {
        onSuccess: (data) => {
          setPingTestResult(data.rpcPing);
        },
        onError(error) {
          const gqlError = getGraphQLError(error);
          setErrorText(gqlError?.message ?? 'Unknown error');
        },
      },
    );
  }, [hostIp, mutate, serialNumber, vlan.UUID]);

  return (
    <Section internal={internal} relation="stacked">
      <SectionContent gutter="all">
        <VStack spacing={space(8)}>
          <Small>
            Determine if the Meter security appliance can reach a local client and measure the
            round-trip request time.
          </Small>
          <FieldContainer>
            <PrimaryFieldComposite
              label="Run a test"
              fields={
                <VStack spacing={space(8)}>
                  <PingTestFields>
                    <TextInput name="host" value={hostName} aria-label="Host" disabled />
                    <Button
                      icon="play"
                      arrangement="hidden-label"
                      variant="secondary"
                      onClick={handleRunPing}
                      disabled={isLoading}
                      width="100%"
                    >
                      Run
                    </Button>
                  </PingTestFields>
                </VStack>
              }
            />
            {!isError && (isLoading || pingTestResult) && (
              <SecondaryFieldComposite
                label="Success rate"
                fields={
                  <>
                    {pingTestResult && (
                      <Small family="monospace">
                        {pingTestResult.successRate >= 0 ? (
                          `${(pingTestResult.successRate * 100).toFixed(3)}%`
                        ) : (
                          <NoValue />
                        )}
                      </Small>
                    )}
                    {isLoading && <StyledLoadingIcon />}
                  </>
                }
              />
            )}
            {!isError && (isLoading || pingTestResult) && (
              <SecondaryFieldComposite
                label="Round-trip average time"
                fields={
                  <>
                    {pingTestResult && (
                      <Small family="monospace">
                        {pingTestResult.roundTripTimeMs != null ? (
                          `${pingTestResult.roundTripTimeMs} ms`
                        ) : (
                          <NoValue />
                        )}
                      </Small>
                    )}
                    {isLoading && <StyledLoadingIcon />}
                  </>
                }
              />
            )}
            {isError && (
              <Alert
                type="inline"
                relation="stacked"
                variant="negative"
                icon="warning"
                heading={errorText}
              />
            )}
            {pingTestResult && <PingTestAlert successRate={pingTestResult.successRate} />}
          </FieldContainer>
        </VStack>
      </SectionContent>
    </Section>
  );
}

import type { NetworksForCompanyQuery } from '../gql/graphql';

export function getNetworkAddress({
  mailingAddress,
}: {
  mailingAddress?:
    | Pick<
        NonNullable<NetworksForCompanyQuery['networksForCompany'][number]['mailingAddress']>,
        'line1' | 'line2' | 'city' | 'subdivisionCode' | 'postalCode' | 'countryISO2'
      >
    | null
    | undefined;
}): string | undefined {
  if (mailingAddress) {
    return [
      mailingAddress.line1,
      mailingAddress.line2,
      mailingAddress.city,
      mailingAddress.subdivisionCode,
      mailingAddress.postalCode,
    ]
      .filter(Boolean)
      .join(' ');
  }

  return undefined;
}

import { SearchInput } from '@meterup/atto';

import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';

export default function SearchField() {
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  return (
    <SearchInput
      placeholder="..."
      aria-label="Filter results"
      scope="scoped"
      value={globalFilter}
      onChange={setGlobalFilter}
      minWidth="56px"
    />
  );
}

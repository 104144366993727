import { Drawer, DrawerHeader } from '@meterup/atto';

import type { RackElevation } from './utils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import RackElevationForm from './RackElevationForm';

export default function EditRackDrawer({ elevation }: { elevation: RackElevation }) {
  const close = useCloseDrawerCallback();

  return (
    <Drawer>
      <DrawerHeader icon="pencil" heading="Edit rack" onClose={close} />
      <RackElevationForm elevation={elevation} />
    </Drawer>
  );
}

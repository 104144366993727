import type { ResultItem } from '@meterup/graphql';
import {
  Badge,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  HStack,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { deletedAtToStatus, IsOperator, roleToName } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import { type NetworksForCompanyQuery, RoleName } from '../../gql/graphql';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { getNetworkAddress } from '../../utils/network';
import { useSafeCloseDrawer } from '../NetworkWide/CaptivePortals/utils';
import { StickyObjectHeader } from '../Object/ObjectHeader';
import UserActions from './UserActions';
import { useCompanyUser } from './utils/queries';
import useUserRolesGroupedByName from './utils/useUserRolesGroupedByName';

type Network = ResultItem<NetworksForCompanyQuery, 'networksForCompany'>;

function networkLabel(network?: Network | null) {
  if (!network) {
    return 'All';
  }
  return getNetworkAddress(network);
}

function getRoleVariant(role: RoleName) {
  switch (role) {
    case RoleName.CompanyGlobalAdmin:
      return 'negative';
    case RoleName.CompanyNetworkAdmin:
      return 'negative';
    case RoleName.CompanyGuest:
      return 'neutral';
    default:
      return 'neutral';
  }
}

export default function UserDrawer({ uuid }: { uuid: string }) {
  const companySlug = useCurrentCompany();
  const roleToAssignments = useUserRolesGroupedByName({ companySlug, uuid });
  const data = useCompanyUser(uuid);
  const closeDrawer = useSafeCloseDrawer();
  const companyUser = checkDefinedOrThrow(
    data.data?.companyUser,
    new ResourceNotFoundError('User not found'),
  );
  const user = checkDefinedOrThrow(companyUser.user, new ResourceNotFoundError('User not found'));
  const { email, firstName, lastName } = user || {};

  return (
    <Drawer>
      <DrawerHeader
        icon="user"
        heading="User"
        onClose={closeDrawer}
        actions={<UserActions uuid={uuid} />}
      />
      <DrawerContent gutter="none">
        <StickyObjectHeader
          icon="user"
          name={firstName || lastName ? [firstName, lastName].join(' ') : email}
        />
        <Sections>
          <Section relation="stacked">
            <SectionHeader heading="Details" />
            <SectionContent gutter="all">
              <SummaryList gutter="none">
                <SummaryListRow>
                  <SummaryListKey>Email</SummaryListKey>
                  <SummaryListValue>
                    <CopyBox aria-label="Copy email address">{email}</CopyBox>
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>First name</SummaryListKey>
                  <SummaryListValue>{firstName}</SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Last name</SummaryListKey>
                  <SummaryListValue>{lastName}</SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Status</SummaryListKey>
                  <SummaryListValue>
                    {deletedAtToStatus(data.data?.companyUser?.deletedAt)}
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
          {roleToAssignments.size > 0 && (
            <Section relation="stacked">
              <SectionHeader heading="Roles" />
              <SectionContent gutter="all" spacing={space(12)}>
                {Array.from(roleToAssignments).map(([roleName, roles]) =>
                  roles.map((role) => (
                    <SummaryList
                      gutter="none"
                      pairs={[
                        {
                          label: 'Role',
                          value: (
                            <HStack align="center" spacing={space(4)} wrap="wrap">
                              <Badge
                                ends="card"
                                internal={roleName === RoleName.Operator}
                                size="small"
                                variant={getRoleVariant(roleName)}
                              >
                                {roleToName(roleName)}
                              </Badge>
                              <IsOperator>at {role.companySlug || 'all companies'}</IsOperator>
                            </HStack>
                          ),
                        },
                        {
                          label: 'Networks',
                          value: networkLabel(role.network),
                        },
                      ]}
                    />
                  )),
                )}
              </SectionContent>
            </Section>
          )}
        </Sections>
      </DrawerContent>
    </Drawer>
  );
}

import {
  Badge,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useEffect, useState } from 'react';

import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { timePeriodLabel } from '../../../utils/chart_utils';
import { timeAgo } from '../../../utils/formatTime';
import { FilterBar } from '../../Insights/FilterBar';
import { getDurationHours } from '../../Insights/Network/utils';
import { capoActivityQuery } from './queries';

export default function CaptivePortalFilters() {
  const network = useNetwork();
  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const { data: capoStats, dataUpdatedAt } = useGraphQL(
    capoActivityQuery,
    {
      networkUUID: network.UUID,
      numHoursLookback: getDurationHours(currentTimePeriod),
    },
    { refetchInterval: 30 * 1000 },
  );

  expectDefinedOrThrow(
    capoStats?.captivePortalAuthorizedClients,
    new ResourceNotFoundError('No activity'),
  );

  const [updatedString, setUpdatedString] = useState(timeAgo.format(dataUpdatedAt, 'round'));

  useEffect(() => {
    const handle = setInterval(
      () => setUpdatedString(timeAgo.format(dataUpdatedAt, 'round')),
      1000,
    );
    return () => {
      clearInterval(handle);
    };
  }, [dataUpdatedAt]);

  return (
    <FilterBar>
      <Badge size="small" variant="neutral">
        Updated {updatedString}
      </Badge>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          arrangement="leading-icon"
          icon="clock"
          size="small"
        >
          {timePeriodLabel(currentTimePeriod)}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentTimePeriod}
            onValueChange={(val) => setCurrentTimePeriod(val as string)}
          >
            <DropdownMenuRadioItem value="1h">{timePeriodLabel('1h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="6h">{timePeriodLabel('6h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="24h">{timePeriodLabel('24h')}</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
    </FilterBar>
  );
}

import type { UseGraphQLOptions } from '@meterup/graphql';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { NetworkStatusQueryQuery } from '../gql/graphql';
import type { Network } from './useNetworksForCompany';
import { graphql } from '../gql';

const NetworkStatusQuery = graphql(`
  query NetworkStatusQuery($networkUUID: UUID!) {
    virtualDevicesForNetwork(networkUUID: $networkUUID) {
      UUID
      label
      description
      deviceModel
      hardwareDevice {
        serialNumber
        macAddress
        isConnectedToBackend
      }
    }
  }
`);

function formatNetworkStatus(network: NetworkStatusQueryQuery | undefined): string {
  if (!network || !network.virtualDevicesForNetwork) return 'Network status not available';

  const status = {
    controllerStatus: { total: 0, online: 0, offline: 0 },
    switchStatus: { total: 0, online: 0, offline: 0 },
    apStatus: { total: 0, online: 0, offline: 0 },
  };

  network.virtualDevicesForNetwork.forEach((vd) => {
    if (!vd.hardwareDevice) return;

    let category: keyof typeof status;
    if (vd.deviceModel.startsWith('MC')) {
      category = 'controllerStatus';
    } else if (vd.deviceModel.startsWith('MS')) {
      category = 'switchStatus';
    } else if (vd.deviceModel.startsWith('MW') || vd.deviceModel.startsWith('AP')) {
      category = 'apStatus';
    } else {
      return;
    }

    status[category].total += 1;
    if (vd.hardwareDevice.isConnectedToBackend) {
      status[category].online += 1;
    } else {
      status[category].offline += 1;
    }
  });

  return `Security Appliance: ${status.controllerStatus.online} online, ${status.controllerStatus.offline} offline
Switches: ${status.switchStatus.online} online, ${status.switchStatus.offline} offline
Access Points: ${status.apStatus.online} online, ${status.apStatus.offline} offline`;
}

export const useNetworkStatus = (
  network?: Network,
  useGraphQLOptions?: UseGraphQLOptions<NetworkStatusQueryQuery>,
) => {
  const { data } = useGraphQL(
    NetworkStatusQuery,
    {
      // not to worry, this will never happen b/c enabled is false below.
      networkUUID: network?.UUID ?? '',
    },
    { enabled: !!network?.UUID, ...useGraphQLOptions },
  );

  return useMemo(() => formatNetworkStatus(data), [data]);
};

export enum Realm {
  CI = 'ci',
  LOCAL = 'local',
  STAGING = 'dev',
  PRODUCTION = 'prod',
}
export function getRealm(): Realm {
  const realm = import.meta.env.REALM;
  switch (realm) {
    case Realm.CI:
    case Realm.LOCAL:
    case Realm.STAGING:
    case Realm.PRODUCTION:
      return realm;
    default:
      throw new Error(`Realm ${realm} does not exist`);
  }
}

import { EmptyState, Logo } from '@meterup/atto';
import React from 'react';

import { OrgDropdown } from '../../../components/Navigation/OrgDropdown';
import { styled } from '../../../stitches';

const LogoContainer = styled('div', { marginBottom: '$16' });
const AccountsDropdownContainer = styled('div', {
  margin: '0 auto',
  maxWidth: '360px',
});

export default function UserBoardCompanyRoot() {
  return (
    <EmptyState
      heading={
        <LogoContainer>
          <Logo variant="full" height={10} />
        </LogoContainer>
      }
      action={
        <AccountsDropdownContainer>
          <OrgDropdown />
        </AccountsDropdownContainer>
      }
    />
  );
}

import { safeParseAddress4 } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { z } from 'zod';

import type {
  AutoVpnGroupsForCompanyQuery,
  CreateAutoVpnGroupInput,
  NetworksWithControllerPortsQuery,
  UpdateAutoVpnGroupInput,
} from '../../gql/graphql';
import { graphql } from '../../gql';
import {
  AddNetworkToAutoVpnGroupInputSchema,
  CreateAutoVpnGroupInputSchema,
  CreateAutoVpnRouteInputSchema,
} from '../../gql/zod-types';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';

export enum AutoVPNGroupTab {
  Members = 'members',
  Routes = 'routes',
}

export const createAutoVPNMemberInputSchema = AddNetworkToAutoVpnGroupInputSchema.extend({
  networkUUID: z.string().nonempty({ message: 'Please select a network.' }),
  phyInterfaceUUID: z
    .string()
    .nonempty({ message: 'Please select a WAN port for the member network.' }),
});

export type CreateAutoVPNMemberInputValues = z.infer<typeof createAutoVPNMemberInputSchema>;

export const createAutoVpnGroupInputModifiedSchema = CreateAutoVpnGroupInputSchema.extend({
  hubNetworkUUID: z.string().nonempty({ message: 'Please select a hub.' }),
  memberUUIDs: z
    .array(z.string())
    .nonempty({ message: 'Please select at least one group member.' }),
  members: z.record(z.string(), createAutoVPNMemberInputSchema),
  name: z.string().nonempty({
    message: 'Please provide a group name.',
  }),
  uplink: z
    .object({
      address: z
        .string()
        .nonempty({ message: 'Please supply an uplink address for the hub.' })
        .refine((val) => safeParseAddress4(val), {
          message: 'Address must be a valid IP.',
        }),
      addressPrefixLength: z.coerce
        .number()
        .min(16, { message: 'Must be greater than or equal to 16' })
        .max(32, {
          message: 'Must be less than or equal to 32',
        }),
      phyInterfaceUUID: z
        .string()
        .nonempty({ message: 'Please supply a WAN port for the uplink.' }),
    })
    .required(),
});

export type AutoVpnGroupFormValues = z.input<typeof createAutoVpnGroupInputModifiedSchema>;

export const createAutoVpnRouteRouteValues = CreateAutoVpnRouteInputSchema.extend({
  name: z.string().nonempty({
    message: 'Please provide a route name.',
  }),
  dstGateway: z
    .string()
    .nonempty({ message: 'Please provide a destination gateway.' })
    .refine((val) => safeParseAddress4(val), {
      message: 'Must be a valid IP.',
    }),
  dstPrefixLength: z.number().min(1, { message: 'Must be greater than or equal to 1' }).max(32, {
    message: 'Must be less than or equal to 32',
  }),
});
export type AutoVpnGroupRouteFormValues = z.input<typeof createAutoVpnRouteRouteValues>;

graphql(`
  fragment AutoVPNMemberFields on AutoVPNMember {
    __typename
    UUID
    networkUUID
    network {
      UUID
      slug
      label
    }
    isFailoverEnabled
    permittedVLANs {
      __typename
      UUID
      name
      vlanID
      ipV4ClientGateway
      ipV4ClientPrefixLength
    }
    uplink {
      UUID
      address
      addressPrefixLength
      hubUplinkUUID
      publicKey
      listenPort
      phyInterfaceUUID
      phyInterface {
        ...PhyInterfaceLabelFields
      }
      connectionInfo {
        observedAt
        lastHandshakeRx
        lastHandshakeTx
        lastPacketTx
        lastPacketRx
      }
    }
  }
`);

graphql(`
  fragment AutoVPNGroupFields on AutoVPNGroup {
    __typename
    UUID
    name
    isEnabled
    hubNetworkUUID
    hubNetwork {
      UUID
      slug
      label
    }
    hubUplink {
      address
      addressPrefixLength
      phyInterface {
        UUID
        ...PhyInterfaceLabelFields
      }
    }
    isHubFailoverEnabled
    members {
      ...AutoVPNMemberFields
    }
    routes {
      __typename
      UUID
      name
      isEnabled
      dstPrefixLength
      dstGateway
    }
  }
`);

export const AutoVPNGroupsQuery = graphql(`
  query AutoVPNGroupsForCompany($companySlug: String!) {
    autoVPNGroupsForCompany(companySlug: $companySlug) {
      ...AutoVPNGroupFields
    }
  }
`);

export const AutoVPNGroupQuery = graphql(`
  query AutoVPNGroup($uuid: UUID!) {
    autoVPNGroup(UUID: $uuid) {
      ...AutoVPNGroupFields
    }
  }
`);

export const AutoVPNMemberQuery = graphql(`
  query AutoVPNMemberQuery($uuid: UUID!) {
    autoVPNMember(UUID: $uuid) {
      ...AutoVPNMemberFields
    }
  }
`);

export const createAutoVPNGroupMutation = graphql(`
  mutation CreateAutoVPNGroup($companySlug: String!, $input: CreateAutoVPNGroupInput!) {
    createAutoVPNGroup(companySlug: $companySlug, input: $input) {
      UUID
      companySlug
      isEnabled
      name
      hubNetworkUUID
    }
  }
`);

export const updateAutoVPNGroupMutation = graphql(`
  mutation UpdateAutoVPNGroup($UUID: UUID!, $input: UpdateAutoVPNGroupInput!) {
    updateAutoVPNGroup(UUID: $UUID, input: $input) {
      UUID
    }
  }
`);

export const deleteAutoVPNGroupMutation = graphql(`
  mutation DeleteAutoVPNGroup($uuid: UUID!) {
    deleteAutoVPNGroup(UUID: $uuid) {
      UUID
      companySlug
      isEnabled
      name
      hubNetworkUUID
    }
  }
`);

export const addNetworkToAutoVPNGroupMutation = graphql(`
  mutation AddNetworkToAutoVPNGroup($groupUUID: UUID!, $input: AddNetworkToAutoVPNGroupInput!) {
    addNetworkToAutoVPNGroup(groupUUID: $groupUUID, input: $input) {
      UUID
    }
  }
`);

export const updateAutoVPNMemberMutation = graphql(`
  mutation UpdateAutoVPNMember($uuid: UUID!, $input: UpdateAutoVPNMemberInput!) {
    updateAutoVPNMember(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const removeNetworkFromAutoVPNGroupMutation = graphql(`
  mutation RemoveNetworkFromAutoVPNGroup($groupUUID: UUID!, $networkUUID: UUID!) {
    removeNetworkFromAutoVPNGroup(groupUUID: $groupUUID, networkUUID: $networkUUID)
  }
`);

export const createAutoVPNRouteMutation = graphql(`
  mutation CreateAutoVPNRoute($groupUUID: UUID!, $input: CreateAutoVPNRouteInput!) {
    createAutoVPNRoute(groupUUID: $groupUUID, input: $input) {
      UUID
      createdAt
      updatedAt
      dstGateway
      dstPrefixLength
    }
  }
`);

export const deleteAutoVPNRouteMutation = graphql(`
  mutation DeleteAutoVPNRoute($uuid: UUID!) {
    deleteAutoVPNRoute(UUID: $uuid) {
      UUID
    }
  }
`);

export type AutoVPNGroup = AutoVpnGroupsForCompanyQuery['autoVPNGroupsForCompany'][number];

export type AutoVPNMember = NonNullable<AutoVPNGroup['members']>[number];

export const networksWithControllerPortsQuery = graphql(`
  query NetworksWithControllerPorts($companySlug: String!) {
    networksForCompany(companySlug: $companySlug) {
      UUID
      label
      isActive
      isTemplate
      virtualDevices(filter: { deviceType: CONTROLLER }) {
        __typename
        label
        ... on ControllerVirtualDevice {
          phyInterfaces {
            UUID
            isUplink
            ...PhyInterfaceLabelFields
          }
        }
      }
    }
  }
`);

export type Network = NetworksWithControllerPortsQuery['networksForCompany'][number];

export function useNetworksWithControllerPorts(): Network[] {
  const companySlug = useCurrentCompany();

  const networksWithControllerPorts = useGraphQL(networksWithControllerPortsQuery, {
    companySlug,
  }).data?.networksForCompany;

  return useMemo(
    () =>
      networksWithControllerPorts
        ?.filter(
          (network) =>
            network.isActive &&
            !network.isTemplate &&
            network.virtualDevices.some(
              (virtualDevice) =>
                virtualDevice.__typename === 'ControllerVirtualDevice' &&
                virtualDevice.phyInterfaces.some((phyInterface) => phyInterface.isUplink),
            ),
        )
        .sort((a, b) => {
          const strippedA = a.label.replace(/\W/g, '');
          const strippedB = b.label.replace(/\W/g, '');
          return strippedA.localeCompare(strippedB);
        }) ?? [],
    [networksWithControllerPorts],
  );
}

export function parseAutoVPNFormValsToCreateMutationInput(
  vals: AutoVpnGroupFormValues,
): CreateAutoVpnGroupInput {
  const { members, memberUUIDs, uplink, ...rest } = vals;
  const { addressPrefixLength, ...restUplink } = uplink;
  return {
    members: Object.values(members),
    uplink: {
      addressPrefixLength: Number(addressPrefixLength),
      ...restUplink,
    },
    ...rest,
  };
}

export function parseAutoVPNFormValsToUpdateMutationInput(
  vals: AutoVpnGroupFormValues,
): UpdateAutoVpnGroupInput {
  const { members, memberUUIDs, uplink, hubNetworkUUID, ...rest } = vals;
  const { addressPrefixLength, ...restUplink } = uplink;
  return {
    uplink: {
      addressPrefixLength: Number(addressPrefixLength),
      ...restUplink,
    },
    ...rest,
  };
}

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { type ObjectHeaderProps, StickyObjectHeader } from '../../Object/ObjectHeader';
import { useIsPermitted } from '../../permissions/useIsPermitted';
import { DetailsWidget } from './insights';
import SwitchActions from './SwitchActions';
import { type SwitchVirtualDevice, SwitchQuery } from './utils';

function switchStatus(
  virtualDevice: Extract<NonNullable<SwitchVirtualDevice>, { __typename: 'SwitchVirtualDevice' }>,
): ObjectHeaderProps['status'] {
  if (virtualDevice.hardwareDevice?.isConnectedToBackend) {
    // TODO(DASH-3200): Reliably determine switch link speed and check this again
    // const uplinkPhyInterface = virtualDevice.phyInterfaces.find((pi) => pi.isUplink);
    // if (uplinkPhyInterface?.portSpeedMbps) {
    //   return isPortSlow(uplinkPhyInterface) ? 'attention' : 'online';
    // }

    return 'online';
  }

  if (virtualDevice.hardwareDevice?.disconnectedFromBackendAt) {
    return 'offline';
  }

  return undefined;
}

function SwitchSummary({ virtualDevice }: { virtualDevice: SwitchVirtualDevice }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isOperator = useIsOperator();
  const canViewPortsPage = useIsPermitted({
    isPermitted: ({ permissions, nosFlags }) =>
      permissions.hasPermission(PermissionType.PermPhyInterfaceRead) && nosFlags[NosFeature.SOS],
  });
  const canViewInsightsPage = useIsPermitted({
    isPermitted: ({ permissions, nosFlags }) =>
      permissions.hasPermission(PermissionType.PermNetworkMetricsRead) && nosFlags[NosFeature.SOS],
  });

  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'SwitchHardwareDevice') return null;
  if (virtualDevice.__typename !== 'SwitchVirtualDevice') return null;

  const permissionBasedTab = isOperator ? 'insights' : 'ports';

  return (
    <>
      <StickyObjectHeader
        icon="switch"
        name={virtualDevice.label}
        status={switchStatus(virtualDevice)}
        {...(canViewPortsPage || canViewInsightsPage
          ? {
              link: makeLink(paths.pages.SwitchDetailPage, {
                networkSlug: network.slug,
                companyName,
                uuid: virtualDevice.UUID,
                tab: permissionBasedTab,
              }),
              cta: 'View switch',
            }
          : {})}
      />
      <Sections>
        <DetailsWidget
          hardwareDevice={hardwareDevice}
          virtualDevice={virtualDevice}
          relation="stacked"
        />
        <Section relation="stacked">
          <SectionHeader heading="STP" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <SummaryListRow>
                <SummaryListKey>Bridge priority</SummaryListKey>
                <SummaryListValue>
                  {virtualDevice.switchProfile.stpBridgePriority.toString()}
                </SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
        </Section>
      </Sections>
    </>
  );
}

export default function SwitchDrawer({ uuid }: { uuid: string }) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const virtualDevice = useGraphQL(SwitchQuery, { uuid }).data?.virtualDevice;
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Switch not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('Switch not found');
  }

  return (
    <Drawer>
      <DrawerHeader
        icon="switch"
        heading="Switch"
        actions={<SwitchActions view="drawer" virtualDevice={virtualDevice} />}
        onClose={closeDrawer}
      />
      <DrawerContent gutter="none">
        <SwitchSummary virtualDevice={virtualDevice} />
      </DrawerContent>
    </Drawer>
  );
}

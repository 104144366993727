import type { ClientError } from 'graphql-request';
import {
  type OverlayTriggerState,
  Alert,
  Body,
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  CompositeField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  PrimaryField,
  PrimaryFieldComposite,
  PrimaryToggleField,
  Section,
  SectionContent,
  SectionHeader,
  Select,
  SelectItem,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  TextInput,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { capitalize } from 'lodash-es';
import { type ClipboardEvent, Suspense, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

import type {
  CreateApplicationDnsFirewallRuleInput,
  UpdateApplicationDnsFirewallRuleInput,
  VlaNsAndDhcpRulesQuery,
} from '../../gql/graphql';
import type {
  DNSFirewallRule,
  DNSFirewallRuleApplication,
  DNSFirewallRuleCategory,
  DNSFirewallRuleFormValues,
  DNSFirewallRuleGroup,
} from './utils';
import { paths } from '../../constants';
import { FirewallRuleAction } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { isFQDN } from '../../utils/fqdn';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { ucfirst } from '../../utils/strings';
import { withZodSchema } from '../../utils/withZodSchema';
import {
  FieldProvider,
  NumberFieldProvider,
  ToggleOptionsFieldProvider,
} from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import { vlanHasStaticIP } from '../NetworkWide/VLANs/utils';
import { StackedSkeletons } from '../Placeholders/AppLoadingFallback';
import {
  createDNSFirewallRuleMutation,
  deleteDNSFirewallRuleMutation,
  dnsDomainApplicationQuery,
  dnsDomainCategoryQuery,
  DNSFirewallRuleKind,
  dnsFirewallRuleKindFromRule,
  dnsFirewallRuleQuery,
  dnsFirewallRulesQuery,
  editDNSFirewallRuleInputSchema,
  updateDNSFirewallRuleMutation,
  useDNSFirewallRuleApplications,
  useDNSFirewallRuleCategories,
  useDNSFirewallRuleGroups,
  vlansAndDHCPRulesQuery,
} from './utils';

function DeleteDNSFirewallRuleDialog({
  rule,
  state,
}: {
  rule: DNSFirewallRule;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const [error, setError] = useState<ClientError | null>(null);
  const closeDrawer = useCloseDrawerCallback();

  const deleteRule = useGraphQLMutation(deleteDNSFirewallRuleMutation);
  const { mutate } = deleteRule;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    setError(null);

    mutate(
      { uuid: rule.UUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(dnsFirewallRulesQuery, { networkUUID: network.UUID }),
          );
          setError(null);
          closeDrawer();
          close();
          notify('Rule deleted successfully.', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          setError(err);
        },
      },
    );
  }, [mutate, rule?.UUID, close, network.UUID, queryClient, closeDrawer]);

  const graphqlError = useMemo(() => (error ? getGraphQLError(error) : undefined), [error]);

  if (!rule) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete firewall rule" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to remove the firewall rule <Text weight="bold">{rule.name}</Text> from
              your Meter network.
            </>
          }
        />
        <SummaryList gutter="vertical">
          {rule.dhcpRule.vlan && (
            <SummaryListRow>
              <SummaryListKey>Affected VLAN</SummaryListKey>
              <SummaryListValue>{rule.dhcpRule.vlan.name}</SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>

        {error && (
          <Alert
            icon="warning"
            variant="negative"
            heading="There was an error deleting this DNS security rule"
            copy={graphqlError?.message ? ucfirst(graphqlError.message) : undefined}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

const schemaValidator = withZodSchema(editDNSFirewallRuleInputSchema);

type CategoryGroup = { group: DNSFirewallRuleGroup; categories: DNSFirewallRuleCategory[] };

function DNSSecurityDomainCategorization({ domain }: { domain: string }) {
  const {
    data: application,
    error: applicationError,
    isError: applicationIsError,
  } = useGraphQL(
    dnsDomainApplicationQuery,
    { hostname: domain },
    {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const {
    data: categorization,
    error: categorizationError,
    isError: categorizationIsError,
  } = useGraphQL(
    dnsDomainCategoryQuery,
    { hostname: domain },
    {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  if (applicationError && applicationIsError && categorizationError && categorizationIsError) {
    const graphqlError = getGraphQLError(applicationError);

    return (
      <Alert
        icon="information"
        variant="neutral"
        heading="Categorization unavailable"
        copy={graphqlError?.message ? capitalize(graphqlError.message) : undefined}
      />
    );
  }

  return (
    <Section>
      <SectionHeader heading="Details" icon="information" />
      <SectionContent>
        {categorization?.applicationDNSFirewallRuleCategoryForHostname && (
          <SummaryList>
            {categorization.applicationDNSFirewallRuleCategoryForHostname.group && (
              <SummaryListRow>
                <SummaryListKey>Group</SummaryListKey>
                <SummaryListValue>
                  {categorization.applicationDNSFirewallRuleCategoryForHostname.group.name}
                </SummaryListValue>
              </SummaryListRow>
            )}
            <SummaryListRow>
              <SummaryListKey>Category</SummaryListKey>
              <SummaryListValue>
                {categorization.applicationDNSFirewallRuleCategoryForHostname.name}
              </SummaryListValue>
            </SummaryListRow>
            <Body>{categorization.applicationDNSFirewallRuleCategoryForHostname.description} </Body>
          </SummaryList>
        )}
        {application?.applicationDNSFirewallRuleApplicationForHostname && (
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Application</SummaryListKey>
              <SummaryListValue>
                {application.applicationDNSFirewallRuleApplicationForHostname.name}
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        )}
      </SectionContent>
    </Section>
  );
}

function DNSSecurityDomainCategorizationLoadingFallback() {
  return (
    <Section>
      <SectionHeader heading="Details" icon="information" />
      <SectionContent>
        <StackedSkeletons count={3} height={40} />
      </SectionContent>
    </Section>
  );
}

export function DNSSecurityDomainCategorizationField() {
  const { values, errors } = useFormikContext<DNSFirewallRuleFormValues>();

  const [hostname] = useDebounce(errors.domain ? null : values.domain, 250);

  if (!hostname || !isFQDN(hostname)) return null;

  return (
    <Suspense fallback={<DNSSecurityDomainCategorizationLoadingFallback />}>
      <DNSSecurityDomainCategorization domain={hostname} />
    </Suspense>
  );
}

function RuleTypeField({
  applications: allApplications,
  categoryGroupMap,
}: {
  applications: DNSFirewallRuleApplication[];
  categoryGroupMap: Map<number, CategoryGroup>;
}) {
  const { values, touched, setFieldValue, validateField, setFieldTouched, setFieldError } =
    useFormikContext<DNSFirewallRuleFormValues>();

  const handleStripDomainURL = useCallback(
    async (domain: string) => {
      if (domain) {
        if (!isFQDN(domain)) {
          try {
            const url = new URL(domain);
            await setFieldValue('domain', url.hostname);
            const error = (await validateField('domain')) ?? undefined;
            setFieldError('domain', error);
            await setFieldTouched('domain', true);
            return;
          } catch (err) {
            window.console.error('Failed to strip URL to FQDN, skipping');
          }
        }

        await setFieldValue('domain', domain);
      }
    },
    [setFieldValue, validateField, setFieldError, setFieldTouched],
  );

  const handleDomainPaste = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      try {
        event.preventDefault();
        const domain = event.clipboardData.getData('text');
        handleStripDomainURL(domain);
      } catch (err) {
        window.console.error('Failed to strip URL to FQDN, skipping');
      }
    },
    [handleStripDomainURL],
  );

  const handleDomainBlur = useCallback(() => {
    if (touched.domain && values.domain && !isFQDN(values.domain)) {
      handleStripDomainURL(values.domain);
    }
  }, [values.domain, touched.domain, handleStripDomainURL]);

  const categoryGroups: CategoryGroup[] = useMemo(() => {
    const sorted = Array.from(categoryGroupMap.values()).map(({ group, categories }) => {
      categories.sort((a, b) => a.name.localeCompare(b.name));
      return {
        group,
        categories: [
          {
            id: group.id,
            name: `All "${group.name}"`,
            description: '',
            group,
          },
          ...categories,
        ],
      };
    });
    sorted.sort((a, b) => a.group.name.localeCompare(b.group.name));

    return sorted;
  }, [categoryGroupMap]);

  return (
    <PrimaryFieldComposite
      label="Rule type"
      controls={
        <FieldProvider name="ruleKind">
          <CompositeField
            label="Rule type"
            element={
              <Select>
                <SelectItem key={DNSFirewallRuleKind.Application}>Application</SelectItem>
                <SelectItem key={DNSFirewallRuleKind.Category}>Category</SelectItem>
                <SelectItem key={DNSFirewallRuleKind.Domain}>Domain</SelectItem>
              </Select>
            }
          />
        </FieldProvider>
      }
      fields={
        <>
          <FormikConditional<DNSFirewallRuleFormValues>
            condition={({ ruleKind }) => ruleKind === DNSFirewallRuleKind.Application}
          >
            <NumberFieldProvider name="applicationID" defaultValue={0}>
              <CompositeField
                label="Application"
                element={
                  <ComboBox placeholder="Select application" minSearchLength={3}>
                    {allApplications.map((app) => (
                      <ComboBoxItem key={app.id} textValue={app.name}>
                        {app.name}
                      </ComboBoxItem>
                    ))}
                  </ComboBox>
                }
              />
            </NumberFieldProvider>
          </FormikConditional>
          <FormikConditional<DNSFirewallRuleFormValues>
            condition={({ ruleKind }) => ruleKind === DNSFirewallRuleKind.Category}
          >
            <NumberFieldProvider name="categoryID" defaultValue={0}>
              <CompositeField
                label="Category"
                element={
                  <ComboBox placeholder="Select category">
                    {categoryGroups.map((categoryGroup) => (
                      <ComboBoxSection title={categoryGroup.group.name}>
                        {categoryGroup.categories.map((category) => (
                          <ComboBoxItem key={category.id} textValue={category.name}>
                            {category.name}
                          </ComboBoxItem>
                        ))}
                      </ComboBoxSection>
                    ))}
                  </ComboBox>
                }
              />
            </NumberFieldProvider>
          </FormikConditional>
          <FormikConditional<DNSFirewallRuleFormValues>
            condition={({ ruleKind }) => ruleKind === DNSFirewallRuleKind.Domain}
          >
            <FieldProvider name="domain">
              <CompositeField
                label="Domain"
                description="Can contain non-repeating * wildcards for glob matching, must contain at least one alphanumeric character."
                element={<TextInput onPaste={handleDomainPaste} onBlur={handleDomainBlur} />}
              />
            </FieldProvider>

            <DNSSecurityDomainCategorizationField />
          </FormikConditional>
        </>
      }
    />
  );
}

type DHCPRuleOption = NonNullable<VlaNsAndDhcpRulesQuery['vlans'][number]['dhcpRule']>;

function DHCPRuleDNSUseGatewayProxyWarning({
  uuidToDHCPRuleMap,
}: {
  uuidToDHCPRuleMap: Map<string, DHCPRuleOption>;
}) {
  const { values } = useFormikContext<DNSFirewallRuleFormValues>();

  const selectedDHCPRule = useMemo(
    () => uuidToDHCPRuleMap.get(values.dhcpRuleUUID),
    [uuidToDHCPRuleMap, values.dhcpRuleUUID],
  );

  if (selectedDHCPRule && !selectedDHCPRule.dnsUseGatewayProxy) {
    return (
      <Alert
        variant="neutral"
        heading="VLAN does not use gateway DNS server"
        copy="DNS security requires using the gateway as DNS server, creating this rule will enable it on this VLAN."
      />
    );
  }

  return null;
}

export default function DNSSecurityRuleDrawer({ rule }: { rule?: DNSFirewallRule }) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const vlansAndDHCPRules = useGraphQL(vlansAndDHCPRulesQuery, { networkUUID: network.UUID })?.data
    ?.vlans;

  const vlansWithDHCPRules = useMemo(
    () =>
      vlansAndDHCPRules?.filter(
        (vlan): vlan is typeof vlan & { dhcpRule: DHCPRuleOption } =>
          vlanHasStaticIP(vlan) && !!vlan.dhcpRule,
      ) ?? [],
    [vlansAndDHCPRules],
  );

  const uuidToDHCPRuleMap = useMemo(
    () => new Map(vlansWithDHCPRules.map((vlan) => [vlan.dhcpRule.UUID, vlan.dhcpRule])),
    [vlansWithDHCPRules],
  );

  const createRule = useGraphQLMutation(createDNSFirewallRuleMutation);
  const updateRule = useGraphQLMutation(updateDNSFirewallRuleMutation);

  const { state } = useDialogState();

  const applications = useDNSFirewallRuleApplications();
  const categories = useDNSFirewallRuleCategories();
  const groups = useDNSFirewallRuleGroups();

  const sortedApplications = useMemo(() => {
    if (!applications) return [];

    const sorted = applications.slice();
    sorted.sort((a, b) => a.name.localeCompare(b.name));
    return sorted;
  }, [applications]);

  const categoryGroupMap: Map<number, CategoryGroup> = useMemo(() => {
    const map = new Map<number, CategoryGroup>(
      groups.map((group) => [group.id, { group, categories: [] }]),
    );

    for (const category of categories) {
      let categoryGroup = map.get(category.group.id);
      if (!categoryGroup) {
        categoryGroup = { group: category.group, categories: [] };
        map.set(category.group.id, categoryGroup);
      }
      categoryGroup.categories.push(category);
    }

    return map;
  }, [groups, categories]);

  const handleSubmit = useCallback(
    ({
      dhcpRuleUUID,
      ruleKind,
      applicationID,
      categoryID,
      domain,
      ...values
    }: DNSFirewallRuleFormValues) => {
      if (rule) {
        const input: UpdateApplicationDnsFirewallRuleInput = {
          ...values,
          dhcpRuleUUID,
        };
        switch (ruleKind) {
          case DNSFirewallRuleKind.Application:
            input.applicationID = applicationID;
            break;
          case DNSFirewallRuleKind.Category:
            if (categoryID && categoryGroupMap.has(categoryID)) {
              input.groupID = categoryID;
            } else {
              input.categoryID = categoryID;
            }
            break;
          case DNSFirewallRuleKind.Domain:
            input.domain = domain;
            break;
        }

        updateRule.mutate(
          { uuid: rule.UUID, input },
          {
            onSuccess() {
              queryClient.invalidateQueries(
                makeQueryKey(dnsFirewallRuleQuery, { uuid: rule.UUID }),
              );
              queryClient.invalidateQueries(
                makeQueryKey(dnsFirewallRulesQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully updated DNS security rule.', {
                variant: 'positive',
              });
            },
            onError(err) {
              notify(
                `There was a problem updating this DNS security rule${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateApplicationDnsFirewallRuleInput = values;
        switch (ruleKind) {
          case DNSFirewallRuleKind.Application:
            input.applicationID = applicationID;
            break;
          case DNSFirewallRuleKind.Category:
            if (categoryID && categoryGroupMap.has(categoryID)) {
              input.groupID = categoryID;
            } else {
              input.categoryID = categoryID;
            }
            break;
          case DNSFirewallRuleKind.Domain:
            input.domain = domain;
            break;
        }

        createRule.mutate(
          { dhcpRuleUUID, input },
          {
            onSuccess(response) {
              queryClient.invalidateQueries(
                makeQueryKey(dnsFirewallRulesQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully created DNS security rule.', {
                variant: 'positive',
              });
              navigate(
                makeDrawerLink(window.location, paths.drawers.DNSSecurityRuleEditPage, {
                  ruleUUID: response.createApplicationDNSFirewallRule.UUID,
                  companyName,
                  networkSlug: network.slug,
                }),
              );
            },
            onError(err) {
              notify(
                `There was a problem creating this DNS security rule${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      rule,
      createRule,
      updateRule,
      companyName,
      categoryGroupMap,
      network.UUID,
      network.slug,
      navigate,
      queryClient,
    ],
  );

  const handleValidate = useCallback(
    (values: DNSFirewallRuleFormValues) => {
      const validation = schemaValidator(values);

      if (
        !validation.applicationID &&
        values.applicationID &&
        !applications.some((app) => app.id === values.applicationID)
      ) {
        validation.applicationID = 'Unrecognized application.';
      }

      if (
        !validation.categoryID &&
        values.categoryID &&
        !categories.some((app) => app.id === values.categoryID) &&
        !groups.some((app) => app.id === values.categoryID)
      ) {
        validation.categoryID = 'Unrecognized category.';
      }

      if (!validation.dhcpRuleUUID && !uuidToDHCPRuleMap.has(values.dhcpRuleUUID)) {
        validation.dhcpRuleUUID = 'Invalid VLAN.';
      }

      return validation;
    },
    [applications, categories, groups, uuidToDHCPRuleMap],
  );

  return (
    <Drawer>
      <Formik<DNSFirewallRuleFormValues>
        initialValues={{
          dhcpRuleUUID: rule?.dhcpRule.UUID ?? '',
          ruleKind: rule
            ? dnsFirewallRuleKindFromRule(rule) ?? DNSFirewallRuleKind.Category
            : DNSFirewallRuleKind.Category,
          name: rule?.name ?? '',
          isEnabled: rule?.isEnabled ?? true,
          action: rule?.action ?? FirewallRuleAction.Deny,
          categoryID: rule?.group?.id ?? rule?.category?.id ?? 0,
          applicationID: rule?.application?.id ?? 0,
          domain: rule?.domain ?? '',
        }}
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        <Form>
          <DrawerHeader
            icon="dns-security"
            heading={`${rule ? 'Edit' : 'Add'} DNS security rule`}
            onClose={closeDrawer}
            actions={
              rule ? (
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem icon="trash-can" onSelect={state.open}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
              ) : undefined
            }
          />
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enabled" />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="name">
                <PrimaryField label="Name" element={<TextInput />} />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <ToggleOptionsFieldProvider
                name="action"
                negative={FirewallRuleAction.Deny}
                positive={FirewallRuleAction.Permit}
              >
                <PrimaryToggleField
                  label="Action"
                  variant="polarity"
                  negative={{
                    icon: 'block',
                    label: 'Deny',
                  }}
                  positive={{
                    icon: 'checkmark',
                    label: 'Allow',
                  }}
                />
              </ToggleOptionsFieldProvider>
            </FieldContainer>

            <FieldContainer>
              <FieldProvider name="dhcpRuleUUID">
                <PrimaryField
                  label="VLAN"
                  element={
                    <ComboBox placeholder="Select VLAN">
                      {vlansWithDHCPRules.map((vlan) => (
                        <ComboBoxItem key={vlan.dhcpRule.UUID} textValue={vlan.name}>
                          {vlan.name}
                        </ComboBoxItem>
                      ))}
                    </ComboBox>
                  }
                />
              </FieldProvider>
            </FieldContainer>

            <RuleTypeField applications={sortedApplications} categoryGroupMap={categoryGroupMap} />

            <DHCPRuleDNSUseGatewayProxyWarning uuidToDHCPRuleMap={uuidToDHCPRuleMap} />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
      {rule && <DeleteDNSFirewallRuleDialog state={state} rule={rule} />}
    </Drawer>
  );
}

import { Badge, Body, Button, HStack, PrimaryField, space, VStack } from '@meterup/atto';
import useCompanySlug from 'connect-frontend/src/dashboard/hooks/useCompanySlug';
import React, { useMemo } from 'react';

import type { Maybe, SsoConnectedDirectory } from '../../../../gql/graphql';
import { SsoAdminPortalType } from '../../../../gql/graphql';
import AdminPortalCopyBox from './AdminPortalCopyBox';
import { DirectoryTypeLogo } from './DirectoryTypeLogo';
import useCreatePortalURL from './useCreatePortalURL';

type ConfigureDirectorySyncProps = {
  connectedDirectory?: Maybe<SsoConnectedDirectory>;
};

export default function ConfigureDirectorySync({
  connectedDirectory,
}: ConfigureDirectorySyncProps) {
  const companySlug = useCompanySlug();
  const [state, , onSubmit, mutationIsLoading] = useCreatePortalURL({
    companySlug,
    portalType: SsoAdminPortalType.Dsync,
  });
  const isLoading = useMemo(
    () => !companySlug || mutationIsLoading,
    [companySlug, mutationIsLoading],
  );

  return (
    <PrimaryField
      label="Configure company directory"
      badges={
        connectedDirectory ? (
          <Badge variant="positive" icon="checkmark" arrangement="leading-icon">
            Configured
          </Badge>
        ) : null
      }
      element={
        <VStack spacing={space(12)}>
          <HStack justify="between">
            {connectedDirectory ? (
              <HStack spacing={space(8)}>
                <DirectoryTypeLogo type={connectedDirectory.type} />
              </HStack>
            ) : (
              <Body>Not connected</Body>
            )}
            <Button variant="primary" type="button" onClick={onSubmit} disabled={isLoading}>
              Configure directory
            </Button>
          </HStack>
          {state.portalURL ? <AdminPortalCopyBox portalURL={state.portalURL} /> : null}
        </VStack>
      }
    />
  );
}

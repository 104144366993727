import { Button, SearchInput } from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';

export default function PortForwardingActions() {
  const network = useNetwork();
  const companyName = useCurrentCompany();

  return (
    <>
      <Button
        as={Link}
        to={makeDrawerLink(window.location, paths.drawers.CreatePortForwardingRulePage, {
          companyName,
          networkSlug: network.slug,
        })}
        arrangement="leading-icon"
        icon="plus"
        variant="secondary"
      >
        Add port forward rule
      </Button>
      <SearchInput />
    </>
  );
}

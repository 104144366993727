import {
  Alert,
  Button,
  ComboBox,
  ComboBoxItem,
  FieldContainer,
  Pane,
  PaneContent,
  PaneFooter,
  PaneHeader,
  PrimaryField,
  space,
  styled,
  TextInput,
  VStack,
} from '@meterup/atto';
import { bitsPerSecond, formatBytes, formatDataRateBits, notify } from '@meterup/common';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

import type { NetworkClient } from '../../../hooks/networkClients/useNetworkClients';
import { useNetworkClients } from '../../../hooks/networkClients/useNetworkClients';
import { useNetworksForCompany } from '../../../hooks/useNetworksForCompany';
import { useNetworkStatus } from '../../../hooks/useNetworkStatus';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useIdentity } from '../../../providers/IdentityDataProvider';
import { withZodSchema } from '../../../utils/withZodSchema';
import { createZendeskTicket, usePrefillZendeskFields } from '../../../utils/zendesk';
import { FieldProvider } from '../../Form/FieldProvider';
import { TextareaField, TextField } from '../../Form/Fields';
import { FormikConditional } from '../../FormikConditional';
import { LocationSwitcherOption } from '../../LocationSwitcher';

const StyledForm = styled(Form, {
  display: 'contents',
});

const supportFormSchema = z.object({
  subject: z.string().nonempty({ message: 'Please provide a subject.' }),
  description: z.string().nonempty({ message: 'Please provide a description.' }),
  severity: z.string(),
  networkType: z.string(),
  issueType: z.string(),
  deviceMacAddress: z.string(),
  issueLocation: z.string(),
  location: z.string(),
  networkStatus: z.string(),
  clientDetails: z.string(),
});

export type SupportFormData = z.infer<typeof supportFormSchema>;

function formatClientDetails(client: NetworkClient): string {
  const formatDate = (dateString: any): string => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    });
  };

  const formatValue = (value: any, unit: string = ''): string => {
    if (value == null) return 'N/A';
    return `${value}${unit}`;
  };

  return `
    Client Details:
    Name: ${formatValue(client.clientName)}
    Last Seen: ${formatDate(client.lastSeen)}
    DHCP Expiry: ${formatDate(client.dhcpLeaseExpiresAt)}

    Wireless Score: ${formatValue(client.wirelessScore, '/100')}
    Signal: ${formatValue(client.signal, ' dBm')}
    SSID: ${formatValue(client.ssid)}
    Noise: ${formatValue(client.noise, ' dBm')}

    Total Rx: ${client.totalRxBytes != null ? formatBytes(client.totalRxBytes) : 'N/A'}
    Rx Rate: ${client.rxRate != null ? formatDataRateBits(client.rxRate, bitsPerSecond) : 'N/A'}

    Total Tx: ${client.totalTxBytes != null ? formatBytes(client.totalTxBytes) : 'N/A'}
    Tx Rate: ${client.txRate != null ? formatDataRateBits(client.txRate, bitsPerSecond) : 'N/A'}

    Average Tx Speed: ${client.throughput?.txRate != null ? formatDataRateBits(client.throughput.txRate, bitsPerSecond) : 'N/A'}
    Average Rx Speed: ${client.throughput?.rxRate != null ? formatDataRateBits(client.throughput.rxRate, bitsPerSecond) : 'N/A'}
  `.trim();
}

function ClientDropdown() {
  const { values, setFieldValue } = useFormikContext<SupportFormData>();
  const companySlug = useCurrentCompany();
  const companyNetworks = useNetworksForCompany(companySlug);
  const network = companyNetworks.find((n) => n.UUID === values.location);

  const networkClients = useNetworkClients(network, {
    filter: {
      excludeMeterHardware: true,
    },
  });

  useEffect(() => {
    if (!networkClients.length) {
      setFieldValue('deviceMacAddress', '');
    }
  }, [networkClients.length, setFieldValue]);

  if (!networkClients.length) return null;

  return (
    <FieldContainer>
      <FieldProvider name="deviceMacAddress">
        <PrimaryField
          label="Client"
          element={
            <ComboBox>
              {networkClients.map((client) => (
                <ComboBoxItem key={client.macAddress}>
                  {client.clientName || client.macAddress}
                </ComboBoxItem>
              ))}
            </ComboBox>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

function ClientDetailsField() {
  const { values, setFieldValue } = useFormikContext<SupportFormData>();
  const companySlug = useCurrentCompany();
  const companyNetworks = useNetworksForCompany(companySlug);
  const network = companyNetworks.find((n) => n.UUID === values.location);

  const networkClients = useNetworkClients(network, {
    filter: {
      excludeMeterHardware: true,
    },
  });

  const selectedClient = useMemo(
    () => networkClients.find((client) => client.macAddress === values.deviceMacAddress),
    [networkClients, values.deviceMacAddress],
  );

  const clientDetails = useMemo(
    () => (selectedClient ? formatClientDetails(selectedClient) : ''),
    [selectedClient],
  );

  useEffect(() => {
    setFieldValue('clientDetails', clientDetails);
  }, [clientDetails, setFieldValue]);

  return null;
}

function NetworkStatusField() {
  const { values } = useFormikContext<SupportFormData>();
  const companySlug = useCurrentCompany();
  const companyNetworks = useNetworksForCompany(companySlug);
  const network = companyNetworks.find((n) => n.UUID === values.location);

  const networkStatus = useNetworkStatus(network);

  const { setFieldValue } = useFormikContext<SupportFormData>();

  useEffect(() => {
    setFieldValue('networkStatus', networkStatus);
  }, [networkStatus, setFieldValue]);

  return null;
}

export function Support() {
  const [error, setError] = useState<string | null>(null);
  const identity = useIdentity();
  usePrefillZendeskFields();

  const companySlug = useCurrentCompany();
  const companyNetworks = useNetworksForCompany(companySlug);

  // Filter active networks
  const activeNetworks = useMemo(
    () => companyNetworks.filter((network) => network.isActive),
    [companyNetworks],
  );

  const initialValues: SupportFormData = {
    subject: '',
    description: '',
    severity: '',
    networkType: '',
    issueType: '',
    deviceMacAddress: '',
    issueLocation: '',
    location: '',
    networkStatus: '',
    clientDetails: '',
  };

  const handleSubmit = useCallback(
    async (values: SupportFormData, { resetForm }: { resetForm: () => void }) => {
      try {
        let label = 'No network selected';
        let uuid = 'No network selected';
        const network = activeNetworks.find((n) => n.UUID === values.location);
        if (network) {
          label = network.label;
          uuid = network.UUID;
        }

        await createZendeskTicket({
          subject: values.subject,
          description: `${values.description}
            \n\nCompany: ${companySlug}
            \n\nNetwork: ${label}
            \n\nUUID: ${uuid}
            \n\nNetwork Status:\n${values.networkStatus}
            \n\n${values.clientDetails}`,
          severity: values.severity,
          networkType: values.networkType,
          identity: {
            first_name: identity.first_name || identity.username,
            last_name: identity.last_name || identity.username,
            username: identity.username,
          },
          locationName: label,
          issueType: values.issueType,
          deviceMacAddress: values.deviceMacAddress,
          issueLocation: values.issueLocation,
        });

        setError(null);

        notify('Ticket created successfully.', {
          variant: 'positive',
        });

        resetForm();
      } catch (err) {
        setError(`Error creating ticket: ${err instanceof Error ? err.message : String(err)}`);
      }
    },
    [identity, activeNetworks, companySlug],
  );

  return (
    <Pane variant="adjusted" contentMode="fit">
      <PaneHeader icon="life-ring" heading="Send a support request" />
      <Formik
        initialValues={initialValues}
        validate={withZodSchema(supportFormSchema)}
        onSubmit={handleSubmit}
      >
        <StyledForm>
          <PaneContent gutter="all">
            <VStack spacing={space(12)}>
              {error && <Alert variant="negative" copy={error} />}
              <TextField
                name="subject"
                label="Subject"
                placeholder="Enter the subject of your request"
              />

              <TextareaField
                name="description"
                label="Description"
                placeholder="Describe your issue or request"
              />

              <FieldContainer>
                <FieldProvider name="severity">
                  <PrimaryField
                    label="Severity"
                    element={
                      <ComboBox>
                        <ComboBoxItem key="sev1">Critical - Network down</ComboBoxItem>
                        <ComboBoxItem key="sev2">Major - Major functionality impacted</ComboBoxItem>
                        <ComboBoxItem key="sev3">
                          Medium - Partial, non-critical functionality impacted
                        </ComboBoxItem>
                        <ComboBoxItem key="sev4">Low - No impact on network operation</ComboBoxItem>
                      </ComboBox>
                    }
                  />
                </FieldProvider>
              </FieldContainer>

              <FieldContainer>
                <FieldProvider name="location">
                  <PrimaryField
                    label="Location"
                    element={
                      <ComboBox placeholder="Select a location">
                        {activeNetworks.map((network) => (
                          <ComboBoxItem key={network.UUID} textValue={network.label}>
                            <LocationSwitcherOption network={network} />
                          </ComboBoxItem>
                        ))}
                      </ComboBox>
                    }
                  />
                </FieldProvider>
              </FieldContainer>

              <FieldContainer>
                <FieldProvider name="networkType">
                  <PrimaryField
                    label="Technology type"
                    element={
                      <ComboBox>
                        <ComboBoxItem key="wireless">Wireless</ComboBoxItem>
                        <ComboBoxItem key="wired">Wired</ComboBoxItem>
                        <ComboBoxItem key="both">Wireless & Wired</ComboBoxItem>
                        <ComboBoxItem key="meter_dashboard">Dashboard</ComboBoxItem>
                        <ComboBoxItem key="unsure">Unsure</ComboBoxItem>
                      </ComboBox>
                    }
                  />
                </FieldProvider>
              </FieldContainer>

              <FormikConditional<SupportFormData> condition={({ location }) => !!location}>
                <Suspense fallback={null}>
                  <NetworkStatusField />

                  <ClientDropdown />
                </Suspense>
              </FormikConditional>

              <FormikConditional<SupportFormData>
                condition={({ deviceMacAddress }) => !!deviceMacAddress}
              >
                <Suspense fallback={null}>
                  <ClientDetailsField />
                </Suspense>
              </FormikConditional>

              <FieldContainer>
                <FieldProvider name="issueType">
                  <PrimaryField
                    label="Issue type"
                    element={
                      <ComboBox>
                        <ComboBoxItem key="slow_speed_issue_type">Slow Speed</ComboBoxItem>
                        <ComboBoxItem key="intermittent_connectivity_issue_type">
                          Intermittent Connectivity
                        </ComboBoxItem>
                        <ComboBoxItem key="unable_to_connect_issue_type">
                          Unable to connect
                        </ComboBoxItem>
                        <ComboBoxItem key="add_or_change_config_issue_type">
                          Add or change config
                        </ComboBoxItem>
                        <ComboBoxItem key="feature_not_working_as_expected_issue_type">
                          Feature not working as expected
                        </ComboBoxItem>
                      </ComboBox>
                    }
                  />
                </FieldProvider>
              </FieldContainer>

              <FieldContainer>
                <FieldProvider name="issueLocation">
                  <PrimaryField
                    label="Where did the issue occur?"
                    element={
                      <TextInput placeholder="If applicable, provide block number, floor number, room number, etc." />
                    }
                  />
                </FieldProvider>
              </FieldContainer>

              <Field name="networkStatus" type="hidden" />
              <Field name="clientDetails" type="hidden" />
            </VStack>
          </PaneContent>
          <PaneFooter>
            <span />
            <Button type="submit" variant="primary">
              Send
            </Button>
          </PaneFooter>
        </StyledForm>
      </Formik>
    </Pane>
  );
}

import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import RadioProfileEditDrawer from '../../../../../components/Wireless/RadioProfiles/RadioProfileEditDrawer';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/radio-profiles/:uuid',
  title: 'Edit radio profile - Radio profiles - Wireless',
});

export default function RadioProfileEditPage() {
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.RadioProfileEditPage),
  );
  return <RadioProfileEditDrawer uuid={uuid} />;
}

import {
  Badge,
  BaseInput,
  Body,
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  FieldContainer,
  PrimaryField,
  Select,
  SelectItem,
  Skeleton,
  space,
  Textarea,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router';

import type { UpdateSsidMutationMutationVariables } from '../../../gql/graphql';
import type { SSIDsQueryResult } from '../SSIDs/SSIDsUtils';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider, ListFieldProvider } from '../../Form/FieldProvider';
import { SSIDsQuery, UpdateSSIDMutation } from '../SSIDs/SSIDsUtils';
import {
  type AddEditHotspot20Input,
  AddEditHotspot20InputSchema,
  parseFormValsToHs20Record,
  parseHs20RecordToFormVals,
  useHs20ValidTypes,
  useSsids,
} from './utils';

function SSIDsField({ ssids }: { ssids: SSIDsQueryResult[] }) {
  if (ssids.length === 0) {
    return (
      <EmptyState
        icon="ssid"
        heading="There are no SSIDs available to apply a Hotspot 2.0 profile."
      />
    );
  }
  return (
    <FieldContainer>
      <FieldProvider name="hs20Ssid">
        <PrimaryField
          element={
            <Select>
              {Object.values(ssids).map((ssid) => (
                <SelectItem key={ssid.UUID}>{ssid.ssid}</SelectItem>
              ))}
            </Select>
          }
          label="SSID"
        />
      </FieldProvider>
    </FieldContainer>
  );
}

function VenueTypeField() {
  const { venueGroupsToTypesMap } = useHs20ValidTypes();
  return (
    <FieldContainer>
      <FieldProvider name="hs20VenueTypeTuple">
        <PrimaryField
          element={
            <ComboBox maxWidth="100%" canClearValue>
              {Array.from(venueGroupsToTypesMap.entries()).map((group) => (
                <ComboBoxSection key={group[0]} title={group[1].description}>
                  {group[1].types.map((type) => (
                    <ComboBoxItem key={`${type.group}-${type.type}`} textValue={type.description}>
                      {type.description}
                    </ComboBoxItem>
                  ))}
                </ComboBoxSection>
              ))}
            </ComboBox>
          }
          label="Venue type"
        />
      </FieldProvider>
    </FieldContainer>
  );
}

function AccessNetworkTypeField() {
  const { accessNetworkTypes } = useHs20ValidTypes();
  return (
    <FieldProvider name="hs20AccessNetworkType">
      <PrimaryField
        label="Network type"
        element={
          <Select width="100%">
            {accessNetworkTypes.map((type) => (
              <SelectItem key={type.type}>{type.description}</SelectItem>
            ))}
          </Select>
        }
      />
    </FieldProvider>
  );
}

export function Hotspot20Actions({ ssid }: { ssid: SSIDsQueryResult }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const updateSSIDMutation = useGraphQLMutation(UpdateSSIDMutation);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const { state } = useDialogState();
  const handleDelete = useCallback(() => {
    const variables: UpdateSsidMutationMutationVariables = {
      uuid: ssid.UUID,
      input: {
        isEnabled: false,
        hs20ClearAll: true,
      },
    };
    updateSSIDMutation.mutate(variables, {
      onSuccess() {
        closeDrawer();
        queryClient.invalidateQueries(makeQueryKey(SSIDsQuery, { networkUUID: network.UUID }));
        notify('Successfully deleted Hotspot 2.0 profile.', {
          variant: 'positive',
        });
      },
      onError(err) {
        notify(
          `There was a problem deleting the Hotspot 2.0 profile${getGraphQLErrorMessageOrEmpty(err)}.`,
          {
            variant: 'negative',
          },
        );
      },
    });
  }, [ssid, updateSSIDMutation, queryClient, network, closeDrawer]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
          size="small"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem
              onClick={() => {
                navigate(
                  makeDrawerLink(window.location, paths.drawers.Hotspot20NAIRealmEditPage, {
                    companyName,
                    networkSlug: network.slug,
                    uuid: ssid.UUID,
                  }),
                );
              }}
              icon="pencil"
              internal
            >
              Edit NAI Realm
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={state.open} icon="trash-can" internal>
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Dialog state={state} preset="narrow">
        <DialogHeader heading="Delete Hotspot 2.0 profile" icon="trash-can" />
        <DialogContent gutter="all">
          <VStack spacing={space(12)}>
            <Body>
              Are you sure you want to delete this Hotspot 2.0 profile? All clients connected to{' '}
              <Badge arrangement="leading-icon" icon="ssid" size="small" variant="neutral">
                {ssid.ssid}
              </Badge>{' '}
              will be disconnected and this SSID will have to be re-enabled.
            </Body>
            <Body>This cannot be undone.</Body>
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button type="button" onClick={state.close} variant="secondary">
                Cancel
              </Button>
              <Button type="button" onClick={handleDelete} variant="destructive">
                Delete
              </Button>
            </>
          }
        />
      </Dialog>
    </>
  );
}

export default function Hotspot20Edit({ uuid }: { uuid?: string }) {
  const closeDrawer = useCloseDrawerCallback();
  const updateSSIDMutation = useGraphQLMutation(UpdateSSIDMutation);
  const queryClient = useQueryClient();
  const network = useNetwork();
  const ssids = useSsids();
  const ssidsWithoutProfile = ssids.filter((ssid) => !ssid.hs20HasProfile);
  const ssid = uuid ? ssids.find((s) => s.UUID === uuid) : null;
  return (
    <Drawer>
      <Formik<AddEditHotspot20Input>
        initialValues={{
          hs20Ssid: uuid ?? '',
          hs20Enabled: false,
          hs20VenueName: undefined,
          hs20VenueTypeTuple: undefined,
          ...(uuid && ssid ? parseHs20RecordToFormVals(ssid) : {}),
        }}
        onSubmit={(vals) => {
          if (updateSSIDMutation.isLoading) return;
          const input = parseFormValsToHs20Record(vals);
          const variables: UpdateSsidMutationMutationVariables = {
            uuid: vals.hs20Ssid,
            input,
          };
          updateSSIDMutation.mutate(variables, {
            onSuccess() {
              closeDrawer();
              queryClient.invalidateQueries(
                makeQueryKey(SSIDsQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully updated Hotspot 2.0 profile.', {
                variant: 'positive',
              });
            },
            onError(err) {
              notify(
                `There was a problem updating the Hotspot 2.0 profile${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          });
        }}
        validate={withZodSchema(AddEditHotspot20InputSchema)}
      >
        <Form>
          <DrawerHeader
            icon="hotspot-2.0"
            heading={`${ssid ? 'Edit ' : 'Add '}Hotspot 2.0 profile`}
            onClose={closeDrawer}
            actions={ssid ? <Hotspot20Actions ssid={ssid} /> : undefined}
          />
          <DrawerContent>
            {ssid && (
              <FieldProvider name="hs20Ssid">
                <BaseInput
                  css={{ visibility: 'hidden' }}
                  inputProps={{
                    type: 'hidden',
                    value: ssid.ssid,
                    'aria-label': 'Public key',
                  }}
                />
              </FieldProvider>
            )}
            {!ssid && <SSIDsField ssids={ssidsWithoutProfile} />}
            <VStack spacing={space(16)}>
              <FieldProvider name="hs20OperatorName">
                <PrimaryField label="Operator name" element={<TextInput />} />
              </FieldProvider>
              <FieldProvider name="hs20VenueName">
                <PrimaryField label="Venue name" element={<TextInput />} />
              </FieldProvider>
              <Suspense fallback={<Skeleton />}>
                <VenueTypeField />
              </Suspense>
              <Suspense fallback={<Skeleton />}>
                <AccessNetworkTypeField />
              </Suspense>
              <FieldContainer>
                <ListFieldProvider name="hs20DomainNames">
                  <PrimaryField
                    label="Domain list"
                    description="One domain per line."
                    element={<Textarea />}
                  />
                </ListFieldProvider>
              </FieldContainer>
              <FieldContainer>
                <ListFieldProvider name="hs20RoamingConsortiumOIs">
                  <PrimaryField
                    label="Roaming consortium OIs"
                    description="One OI per line."
                    element={<Textarea />}
                  />
                </ListFieldProvider>
              </FieldContainer>
              <FieldContainer>
                <ListFieldProvider name="hs20MccMncPairs">
                  <PrimaryField
                    label="MCC/MNCs"
                    description='One MCC/MNC pair per line, e.g., "289,68".'
                    element={<Textarea />}
                  />
                </ListFieldProvider>
              </FieldContainer>
            </VStack>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

import React from 'react';

export default function Cogent() {
  return (
    <svg
      width="113"
      height="20"
      viewBox="0 0 113 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.1069 3.68591C36.6947 3.68591 37.9778 5.20697 37.9778 5.20697L37.2416 6.28388C37.2416 6.28388 36.0635 4.99584 34.1493 4.99584C31.8353 4.99584 30.0262 6.72761 30.0262 9.26221C30.0262 11.7549 31.8565 13.529 34.1914 13.529C36.3368 13.529 37.5783 12.0298 37.5783 12.0298L38.2305 13.1493C38.2305 13.1493 36.7999 14.8177 34.1069 14.8177C30.9727 14.8177 28.5537 12.5578 28.5537 9.26221C28.5537 5.98813 30.9939 3.68591 34.1069 3.68591Z"
        fill="#808285"
      />
      <path
        d="M48.6924 9.21454C48.6924 12.3065 46.1966 14.8125 43.118 14.8125C40.0387 14.8125 37.543 12.3065 37.543 9.21454C37.543 6.12324 40.0387 3.61719 43.118 3.61719C46.1966 3.61719 48.6924 6.12324 48.6924 9.21454Z"
        fill="#0071BC"
      />
      <path
        d="M57.1001 9.05087C57.1001 5.94624 55.6276 4.99584 53.7556 4.99584C51.6943 4.99584 50.4951 6.49501 50.4951 9.02982C50.4951 11.5648 51.8624 13.2126 53.9659 13.2126C55.6276 13.2126 57.1001 12.1774 57.1001 9.05087ZM53.3772 17.712C55.4383 17.712 57.0796 16.7404 57.0796 14.3746V13.4029C57.0796 13.0011 57.1213 12.6415 57.1213 12.6415H57.0796C56.4486 13.8254 55.3754 14.5007 53.7348 14.5007C50.8947 14.5007 49.0225 12.241 49.0225 9.05087C49.0225 5.86183 50.7683 3.68591 53.6086 3.68591C56.4068 3.68591 57.1001 5.4602 57.1001 5.4602H57.1425C57.1425 5.4602 57.1213 5.33327 57.1213 5.1013V3.93914H58.5103V14.3108C58.5103 17.6905 56.0272 19.0001 53.4401 19.0001C52.2202 19.0001 51.0004 18.6833 49.9692 18.1553L50.5163 16.9723C50.5163 16.9723 51.82 17.712 53.3772 17.712Z"
        fill="#808285"
      />
      <path
        d="M67.3034 8.14299C67.2192 5.92519 65.8731 4.89017 64.295 4.89017C62.5286 4.89017 60.9926 6.00939 60.6559 8.14299H67.3034ZM64.3373 3.68591C67.2615 3.68591 68.7547 5.94624 68.7547 8.58651C68.7547 8.83974 68.7124 9.32578 68.7124 9.32578H60.5926C60.6559 12.0505 62.5074 13.529 64.6949 13.529C66.6301 13.529 67.9133 12.241 67.9133 12.241L68.5654 13.3398C68.5654 13.3398 67.0301 14.8177 64.6528 14.8177C61.4974 14.8177 59.1201 12.5365 59.1201 9.26221C59.1201 5.777 61.4762 3.68591 64.3373 3.68591Z"
        fill="#808285"
      />
      <path
        d="M69.3818 3.93914H70.7908V5.52335C70.7908 5.98813 70.7491 6.36871 70.7491 6.36871H70.7908C71.1698 5.39705 72.5159 3.68591 75.0191 3.68591C77.6278 3.68591 78.5534 5.18571 78.5534 7.80493V14.5646H77.1232V8.18489C77.1232 6.51627 76.8706 5.03711 74.8298 5.03711C73.0207 5.03711 71.4641 6.28388 70.9801 8.03732C70.8543 8.48105 70.8118 8.96646 70.8118 9.47397V14.5646H69.3818V3.93914Z"
        fill="#808285"
      />
      <path
        d="M80.1554 5.267H78.7676V4.06275H80.1767V1H81.5867V4.06275H84.2363V5.267H81.5867V10.548C81.5867 13.0401 83.1634 13.3569 83.963 13.3569C84.2575 13.3569 84.4466 13.3143 84.4466 13.3143V14.6032C84.4466 14.6032 84.2158 14.6455 83.8368 14.6455C82.6167 14.6455 80.1554 14.2437 80.1554 10.6955V5.267Z"
        fill="#808285"
      />
    </svg>
  );
}

import React, { useMemo, useState } from 'react';

import { Pane, PaneContent, PaneHeader } from '@meterup/atto';

import { isFunction } from '../utils';
import { BaseDataViewProps } from './types';

// Current variation works as the plain Companies view
//
// To make companies view work, we need to:
// - Add drawer (as Context-able?)
//
// Need to add the variation that involves nested tabs.
//   I think to do that we make this either take a Heading or Tabs. Then we just pick one or the other
//   Then to display nested tabs, we just nest this thing inside a parent component with tabs. Can probably re-use similar Box as VStack then HStack as LabelRow stuff
export default function BaseDataView({ buttons, header, children }: BaseDataViewProps) {
  // If we only support the use case of plumbing search through flat tables, I think it's
  // fine to just use the local state and not bubble it up?
  const [, setDrawerIsOpen] = useState(false);
  const derivedButtons = useMemo(() => {
    if (isFunction(buttons)) {
      return buttons({
        openDrawer: () => setDrawerIsOpen(true),
      });
    }
    return buttons;
  }, [buttons]);
  return (
    <Pane>
      <PaneHeader icon="location" heading="Locations" tabs={header} actions={derivedButtons} />
      <PaneContent>{children}</PaneContent>
    </Pane>
  );
}
BaseDataView.whyDidYouRender = true;

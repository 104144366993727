import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import NetworkMutationListTable from './NetworkMutationsListTable';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/logs/mutations',
});

export default function NetworkMutationsListPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  return <NetworkMutationListTable />;
}

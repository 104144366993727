import Hotspot20s from '../../../../../components/Wireless/Hotspot20s/Hotspot20s';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/hotspot2.0',
  layout: 'VirtualNetworkLayout',
  title: 'Hotspot 2.0 - Wireless',
});

export default function Hotspot20sPage() {
  return <Hotspot20s />;
}

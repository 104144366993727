import { useGraphQL } from '@meterup/graphql';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { graphql } from '../gql';
import { parseEventLog } from '../utils/events';

const eventLogQuery = graphql(`
  query GetEventLogs($networkUUID: UUID!, $startTime: DateTime, $endTime: DateTime) {
    recentEventLogEvents(
      limit: 100
      offset: 0
      networkUUID: $networkUUID
      startTime: $startTime
      endTime: $endTime
    ) {
      eventType
      generatedAt
      networkUUID
      properties {
        ... on WANStatusChangeProperties {
          virtualDevice {
            __typename
            UUID
            ... on ControllerVirtualDevice {
              phyInterfaces {
                UUID
                portNumber
                uplinkPriority
                isUplink
              }
            }
          }
          activeWANConnections {
            externalAddresses
            internetServicePlanProvider {
              name
            }
            port
          }
        }
      }
    }
  }
`);

function eventLogTimeFilter(filter?: EventLogTimeFilter) {
  const defaultStartDate = DateTime.now().startOf('day').minus({ months: 3 }).toISO();
  const defaultEndDate = DateTime.now().endOf('day').toISO();
  return {
    startTime: filter?.startTime ?? defaultStartDate,
    endTime: filter?.endTime ?? defaultEndDate,
  };
}

function useEventLogEvents({
  networkUUID,
  timeFilter,
}: {
  networkUUID: string;
  timeFilter?: EventLogTimeFilter;
}) {
  return useGraphQL(eventLogQuery, { networkUUID, ...eventLogTimeFilter(timeFilter) });
}

export function useParsedEventLogEvents({
  networkUUID,
  timeFilter,
}: {
  networkUUID: string;
  timeFilter?: EventLogTimeFilter;
}) {
  const data = useEventLogEvents({ networkUUID, timeFilter });
  return useMemo(() => {
    const results = data.data?.recentEventLogEvents;
    if (!results) return [];

    return results.flatMap((event) => {
      const parsedEvent = parseEventLog({ event });
      if (parsedEvent) {
        return [parsedEvent];
      }
      return [];
    });
  }, [data.data?.recentEventLogEvents]);
}

export type EventLogTimeFilter = {
  startTime?: string;
  endTime?: string;
};

import { ControllerJoinInstructions } from '../../../components/TabletJoinInstructions';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/join/:ssid',
  layout: null,
});

export default function ControllerTabletJoinInstructionsPage() {
  return <ControllerJoinInstructions />;
}

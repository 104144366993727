import type { FieldArrayRenderProps } from 'formik';
import {
  Body,
  Button,
  CompositeField,
  FieldContainer,
  HStack,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryFieldComposite,
  PrimaryToggleField,
  SecondaryFieldComposite,
  Select,
  SelectItem,
  Skeleton,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import { useFormikContext } from 'formik';
import { Suspense, useCallback } from 'react';

import type { RulesInput } from './utils';
import { Box } from '../Box';
import {
  FieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
} from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import { useNosFirmwareVersions } from './hooks/useNosFirmwareVersions';
import {
  conditionFieldsForRuleVariable,
  containsNotContainsVals,
  customerTierOptions,
  deviceModelOptions,
  isIsNotVals,
  ltGtVals,
  RuleVariable,
  ruleVariableOptions,
  ruleVarToLabel,
} from './utils';

function IsIsNotBooleanOperatorField({ name }: { name: string }) {
  return (
    <FieldProvider name={name}>
      <CompositeField
        label="Select is / is not boolean operator"
        element={
          <Select width="100%" label="is / is not">
            {isIsNotVals.map((v) => (
              <SelectItem key={v.value}>{v.label}</SelectItem>
            ))}
          </Select>
        }
      />
    </FieldProvider>
  );
}

function LtGtBooleanOperatorField({ name }: { name: string }) {
  return (
    <FieldProvider name={name}>
      <CompositeField
        label="Select less than / greater than operator"
        element={
          <Select placeholder="less than / greater than" showPlaceholderForIndeterminate>
            {ltGtVals.map((v) => (
              <SelectItem key={v.value}>{v.label}</SelectItem>
            ))}
          </Select>
        }
      />
    </FieldProvider>
  );
}

function IncludesExcludesBooleanOperatorField({ name }: { name: string }) {
  return (
    <FieldProvider name={name}>
      <CompositeField
        label="contains / does not contain"
        element={
          <Select
            width="100%"
            placeholder="contains / does not contain"
            showPlaceholderForIndeterminate
          >
            {containsNotContainsVals.map((v) => (
              <SelectItem key={v.value}>{v.label}</SelectItem>
            ))}
          </Select>
        }
      />
    </FieldProvider>
  );
}

function SquareFootageOperandField({ name }: { name: string }) {
  return (
    <NumberFieldProvider name={name} defaultValue={0}>
      <CompositeField
        label="sq ft"
        element={<TextInput width="70px" inputProps={{ type: 'number' }} />}
      />
    </NumberFieldProvider>
  );
}

function TrueFalseOperandField({ name }: { name: string }) {
  return (
    <FieldProvider name={name}>
      <CompositeField
        label="is/is not equal to"
        element={
          <PrimaryToggleField
            containerProps={{ css: { padding: 0 } }}
            label={<Body css={{ paddingRight: space(12) }}>is</Body>}
            variant="polarity"
            negative={{
              icon: 'block',
              label: 'false',
            }}
            positive={{
              icon: 'checkmark',
              label: 'true',
            }}
          />
        }
      />
    </FieldProvider>
  );
}

function CustomTierField({ name }: { name: string }) {
  return (
    <MultiComboBoxFieldProvider name={name}>
      <CompositeField
        label="Company support tier"
        element={
          <MultiComboBox label="Company support tier" size="large" width="100%">
            {customerTierOptions.map((r) => (
              <MultiComboBoxItem key={r.value}>{r.label}</MultiComboBoxItem>
            ))}
          </MultiComboBox>
        }
      />
    </MultiComboBoxFieldProvider>
  );
}

function DateField({ name }: { name: string }) {
  return (
    <FieldProvider name={name}>
      <CompositeField label="Date" element={<TextInput icon="calendar" type="date" />} />
    </FieldProvider>
  );
}

function HardwareModelField({ name }: { name: string }) {
  return (
    <MultiComboBoxFieldProvider name={name}>
      <CompositeField
        label="Hardware model"
        element={
          <MultiComboBox
            size="large"
            width="100%"
            label="Select hardware models"
            placeholder="Select hardware models"
          >
            {deviceModelOptions.map((r) => (
              <MultiComboBoxItem key={r.value}>{r.label}</MultiComboBoxItem>
            ))}
          </MultiComboBox>
        }
      />
    </MultiComboBoxFieldProvider>
  );
}

function NOSFirmwareVersionField({ name }: { name: string }) {
  const { nosVersions } = useNosFirmwareVersions();
  return (
    <MultiComboBoxFieldProvider name={name}>
      <CompositeField
        label="Firmware verson"
        element={
          <MultiComboBox
            aria-label="Firmware version"
            size="large"
            placeholder="Select firmware versions"
          >
            {nosVersions.map((nos) => (
              <MultiComboBoxItem key={nos.id}>{nos.version}</MultiComboBoxItem>
            ))}
          </MultiComboBox>
        }
      />
    </MultiComboBoxFieldProvider>
  );
}

function RuleLogicFields({ index }: { index: number }) {
  const eqOperandName = `rules[${index}].eq`;
  const notOperatorName = `rules[${index}].not`;
  const multiInName = `rules[${index}].in`;
  const ltGtOperatorName = `rules[${index}].ltGtOperator`;
  const valName = `rules[${index}].value`;
  return (
    <>
      <FormikConditional<RulesInput>
        condition={(values) => values.rules[index].variable === RuleVariable.UpgradeSensitive}
      >
        <SecondaryFieldComposite
          label="Condition"
          fields={<TrueFalseOperandField name={eqOperandName} />}
        />
      </FormikConditional>
      <FormikConditional<RulesInput>
        condition={(values) => values.rules[index].variable === RuleVariable.CompanySupportTier}
      >
        <SecondaryFieldComposite
          label="Condition"
          fields={
            <Box css={{ width: '100%' }}>
              <VStack spacing={space(12)}>
                <IsIsNotBooleanOperatorField name={notOperatorName} />
                <Box css={{ width: '100%' }}>
                  <CustomTierField name={multiInName} />
                </Box>
              </VStack>
            </Box>
          }
        />
      </FormikConditional>
      <FormikConditional<RulesInput>
        condition={(values) => values.rules[index].variable === RuleVariable.SquareFootage}
      >
        <SecondaryFieldComposite
          label="Condition"
          fields={
            <HStack spacing={space(4)}>
              <LtGtBooleanOperatorField name={ltGtOperatorName} />
              <SquareFootageOperandField name={valName} />
            </HStack>
          }
        />
      </FormikConditional>
      <FormikConditional<RulesInput>
        condition={(values) => values.rules[index].variable === RuleVariable.NetworkHWModel}
      >
        <SecondaryFieldComposite
          label="Condition"
          fields={
            <Box css={{ width: '100%' }}>
              <VStack spacing={space(12)}>
                <IncludesExcludesBooleanOperatorField name={notOperatorName} />
                <Box css={{ width: '100%' }}>
                  <HardwareModelField name={multiInName} />
                </Box>
              </VStack>
            </Box>
          }
        />
      </FormikConditional>
      <FormikConditional<RulesInput>
        condition={(values) => values.rules[index].variable === RuleVariable.CurrentFirmwareVersion}
      >
        <SecondaryFieldComposite
          label="Condition"
          fields={
            <Suspense
              fallback={
                <VStack spacing={space(12)}>
                  <Skeleton height={space(24)} width="100%" radius={6} />
                  <Skeleton height={space(36)} width="100%" radius={6} />
                </VStack>
              }
            >
              <Box css={{ width: '100%' }}>
                <VStack spacing={space(12)}>
                  <IsIsNotBooleanOperatorField name={notOperatorName} />
                  <Box css={{ width: '100%' }}>
                    <NOSFirmwareVersionField name={multiInName} />
                  </Box>
                </VStack>
              </Box>
            </Suspense>
          }
        />
      </FormikConditional>
      <FormikConditional<RulesInput>
        condition={(values) => values.rules[index].variable === RuleVariable.Active}
      >
        <SecondaryFieldComposite
          label="Condition"
          fields={<TrueFalseOperandField name={eqOperandName} />}
        />
      </FormikConditional>
      <FormikConditional<RulesInput>
        condition={(values) => values.rules[index].variable === RuleVariable.PendingUpgrade}
      >
        <SecondaryFieldComposite
          label="Condition"
          fields={<TrueFalseOperandField name={eqOperandName} />}
        />
      </FormikConditional>
      <FormikConditional<RulesInput>
        condition={(values) => values.rules[index].variable === RuleVariable.LastUpgradedAt}
      >
        <SecondaryFieldComposite
          label="Condition"
          fields={
            <VStack spacing={space(12)}>
              <LtGtBooleanOperatorField name={ltGtOperatorName} />
              <DateField name={valName} />
            </VStack>
          }
        />
      </FormikConditional>
    </>
  );
}

export function RuleFields({ fieldArrayHelpers }: { fieldArrayHelpers: FieldArrayRenderProps }) {
  const { values } = useFormikContext<RulesInput>();
  // This is the least messy of the methods I've tried to register conditional fields in a FieldArray
  // As explained in other usages of registerField in this codebase,
  // Dynamic forms are tricky, for validation/touched/dirty, other states, etc. The field(s) relies on having the initialValues
  // present for these meta properties to kick in.
  // registerField just flat out doesn't work for arrays, touched does not contain the field that was registered or mounted on submit.
  // and I don't want to deal with managing the discriminated union shapes if we supply all possible initialValues.
  const onRuleVariableChange = useCallback(
    (index: number, val: RuleVariable) => {
      const { id } = values.rules[index];
      const defualtConditionValuesForVariable = conditionFieldsForRuleVariable[val];
      fieldArrayHelpers.replace(index, { id, ...defualtConditionValuesForVariable });
    },
    [fieldArrayHelpers, values.rules],
  );
  return (
    <VStack spacing={space(12)}>
      {values.rules.map((p, index: number) => {
        const variableName = `rules[${index}].variable`;
        return (
          <FieldContainer key={p.id}>
            <PrimaryFieldComposite
              label="Rule"
              controls={
                <Button
                  icon="cross"
                  variant="secondary"
                  size="small"
                  arrangement="hidden-label"
                  onClick={() => fieldArrayHelpers.remove(index)}
                >
                  Delete rule
                </Button>
              }
              fields={
                <FieldProvider name={variableName}>
                  <CompositeField
                    label="Rule variable"
                    element={
                      <Select
                        data-field-array-index={index}
                        label="Select variable"
                        placeholder="Select variable"
                        onValueChange={(val: string) => {
                          onRuleVariableChange(index, val as RuleVariable);
                        }}
                      >
                        {ruleVariableOptions
                          .filter((rule) => rule.value !== RuleVariable.SquareFootage)
                          .map((r) => (
                            <SelectItem key={r.value}>{ruleVarToLabel(r.value)}</SelectItem>
                          ))}
                      </Select>
                    }
                  />
                </FieldProvider>
              }
            />
            <RuleLogicFields index={index} />
          </FieldContainer>
        );
      })}
    </VStack>
  );
}

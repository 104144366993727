import {
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
} from '@meterup/atto';
import {
  expectDefinedOrThrow,
  getManufacturerIconName,
  lookupMACAddressOUI,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { UpdatePhyInterfaceMutationVariables } from '../../../gql/graphql';
import type { ValidPhyInterfaceParams } from './utils';
import { paths } from '../../../constants';
import {
  DeviceType,
  PermissionType,
  PhyInterfaceFrameAcceptTypeFilter,
} from '../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { styled } from '../../../stitches';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../../utils/types';
import { ClientHardwareWidget } from '../../clients';
import { updatePhyInterfaceMutation } from '../../Devices/utils';
import { TextareaField, TextField, ToggleField } from '../../Form/Fields';
import { FormikConditional } from '../../FormikConditional';
import { vlansQuery } from '../../NetworkWide/VLANs/utils';
import { ObjectHeader } from '../../Object/ObjectHeader';
import IsPermitted from '../../permissions/IsPermitted';
import { DrawerContentLoadingFallback } from '../../Placeholders/DrawerLoadingFallback';
import {
  AllowedVLANField,
  ForcedPortSpeedField,
  FrameAcceptTypeField,
  NativeVLANField,
  PortTypeField,
  StormControlBroadcastField,
  StormControlUnknownMulticastField,
  StormControlUnknownUnicastField,
} from './Fields';
import SwitchPortActions from './SwitchPortActions';
import { portMaxSpeed, PortsQuery, SwitchQuery, UpdatePhyInterfaceInputSchema } from './utils';

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

interface PortConfigProps {
  phyInterfaceUUID: string;
  virtualDeviceUUID: string;
}

interface SwitchPortEditDrawerProps extends PortConfigProps {}

function PortConfig({ virtualDeviceUUID, phyInterfaceUUID }: PortConfigProps) {
  const network = useNetwork();
  const portData = useGraphQL(PortsQuery, { virtualDeviceUUID }).data
    ?.phyInterfacesForVirtualDevice;
  expectDefinedOrThrow(portData, new ResourceNotFoundError('Device not found.'));
  const port = portData.find((p) => p.UUID === phyInterfaceUUID);
  expectDefinedOrThrow(port, new ResourceNotFoundError('Port not found.'));
  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;
  expectDefinedOrThrow(vlans, new ResourceNotFoundError('VLANs not found for network.'));

  const mutation = useGraphQLMutation(updatePhyInterfaceMutation);
  const queryClient = useQueryClient();

  const handleSubmit = (v: ValidPhyInterfaceParams) => {
    const { description, isTrunkPort, isBoundToAllVLANs, frameAcceptTypeFilter, ...rest } = v;

    const params: UpdatePhyInterfaceMutationVariables = {
      uuid: port.UUID,
      input: {
        ...rest,
        description: description || null, // don't send empty string
        isTrunkPort,
        isBoundToAllVLANs,
      },
    };

    if (frameAcceptTypeFilter === 'UNTAGGED_ONLY') {
      params.input.frameAcceptTypeFilter = PhyInterfaceFrameAcceptTypeFilter.UntaggedOnly;
    } else if (frameAcceptTypeFilter === 'TAGGED_ONLY') {
      params.input.frameAcceptTypeFilter = PhyInterfaceFrameAcceptTypeFilter.TaggedOnly;
    } else if (frameAcceptTypeFilter === 'ALL') {
      params.input.frameAcceptTypeFilter = null;
    }

    if (!isTrunkPort) {
      params.input.allowedVLANUUIDs = [];
      params.input.isBoundToAllVLANs = false;
    }

    if (isTrunkPort && isBoundToAllVLANs) {
      params.input.allowedVLANUUIDs = [];
    }

    mutation.mutate(params, {
      onSuccess: () => {
        notify('Successfully updated port', { variant: 'positive' });
        queryClient.invalidateQueries(makeQueryKey(PortsQuery, { virtualDeviceUUID }));
      },
      onError: (err) => {
        const gqlErr = getGraphQLError(err);
        notify(
          `There was an error updating this port${gqlErr?.message ? `: ${gqlErr.message}` : ''}`,
          {
            variant: 'negative',
          },
        );
      },
    });
  };

  return (
    <Formik<ValidPhyInterfaceParams>
      validationSchema={toFormikValidationSchema(UpdatePhyInterfaceInputSchema)}
      initialValues={{
        label: port.label ?? '',
        description: port.description,
        forcedPortSpeedMbps: port.forcedPortSpeedMbps,
        isBoundToAllVLANs: port.isBoundToAllVLANs,
        isEnabled: port.isEnabled,
        isIngressFilteringEnabled: port.isIngressFilteringEnabled,
        isPOEEnabled: port.isPOEEnabled,
        isSTPEdgePortEnabled: port.isSTPEdgePortEnabled,
        isSTPEnabled: port.isSTPEnabled,
        isTrunkPort: port.isTrunkPort,
        frameAcceptTypeFilter: port.frameAcceptTypeFilter ? port.frameAcceptTypeFilter : 'ALL',
        nativeVLANUUID: port.nativeVLAN?.UUID,
        allowedVLANUUIDs: port.allowedVLANs?.map((vlan) => vlan.UUID) ?? [],
        isStormControlEnabled: port.isStormControlEnabled,
        stormControlBroadcastTrafficPercent: port.stormControlBroadcastTrafficPercent,
        stormControlUnknownMulticastTrafficPercent: port.stormControlUnknownMulticastTrafficPercent,
        stormControlUnknownUnicastTrafficPercent: port.stormControlUnknownUnicastTrafficPercent,
      }}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <DrawerContent>
          <ToggleField name="isEnabled" label="Enable" />
          <TextField
            optional
            name="label"
            label="Label"
            description="Overrides the port's default hardware label"
          />
          <TextareaField optional name="description" label="Description" />
          {!port.isSFP && <ToggleField name="isPOEEnabled" label="PoE+" />}
          <ForcedPortSpeedField maxSpeed={portMaxSpeed(port)} />
          <PortTypeField />
          <NativeVLANField vlans={vlans} />

          <FormikConditional<ValidPhyInterfaceParams> condition={(v) => v.isTrunkPort === true}>
            <AllowedVLANField vlans={vlans} />
          </FormikConditional>

          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
                  permissions.hasPermission(PermissionType.PermPhyInterfaceWrite) &&
                  nosFlags[NosFeature.SOS],
              )
            }
          >
            <ToggleField name="isIngressFilteringEnabled" label="Ingress filtering" internal />
            <FrameAcceptTypeField />

            <FieldContainer>
              <ToggleField name="isStormControlEnabled" label="Enable storm control" internal />

              <FormikConditional<ValidPhyInterfaceParams>
                condition={(v) => v.isStormControlEnabled === true}
              >
                <StormControlBroadcastField internal />
                <StormControlUnknownMulticastField internal />
                <StormControlUnknownUnicastField internal />
              </FormikConditional>
            </FieldContainer>
          </IsPermitted>
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </>
          }
        />
      </StyledForm>
    </Formik>
  );
}

export function SingleDeviceDetails({
  deviceType,
  uuid,
  macAddress,
  clientName,
  serialNumber,
  // config 2 APs will have isOnline defined and we use its value to display online/offline.
  // config 1 APs will not have it defined. So assume online since it is sending lldp
  isOnline = true,
}: {
  deviceType?: DeviceType;
  uuid?: string;
  macAddress: string;
  clientName?: string;
  serialNumber?: string;
  isOnline?: boolean;
}) {
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const activeController = useActiveControllerForNetwork(network);
  const controllerName = activeController?.hardwareDevice?.serialNumber;
  const onlineStatus = isOnline ? 'online' : 'offline';

  return (
    <>
      <ObjectHeader
        collapsed
        icon={
          deviceType
            ? deviceTypeToHardwareIconPropHardware(deviceType)
            : getManufacturerIconName(lookupMACAddressOUI(macAddress) ?? '')
        }
        name={clientName}
        status={deviceType ? onlineStatus : undefined}
      />
      <ClientHardwareWidget gutter="vertical" client={{ macAddress, clientName }} />
      {!deviceType && (
        <>
          {isWOS2Enabled && (
            <Button
              as={Link}
              variant="secondary"
              width="100%"
              size="large"
              icon="chevron-right"
              arrangement="leading-label"
              to={makeDrawerLink(
                {
                  pathname: makeLink(paths.pages.ClientsList2Page, {
                    networkSlug: network.slug,
                    companyName,
                  }),
                  search: window.location.search,
                },
                paths.drawers.ClientSummary2Page,
                {
                  networkSlug: network.slug,
                  companyName,
                  macAddress,
                },
              )}
            >
              View client
            </Button>
          )}
          {!isWOS2Enabled && controllerName && (
            <Button
              as={Link}
              variant="secondary"
              width="100%"
              size="large"
              icon="chevron-right"
              arrangement="leading-label"
              to={makeDrawerLink(
                {
                  pathname: makeLink(paths.pages.ClientsListPage, {
                    controllerName,
                    companyName,
                  }),
                  search: window.location.search,
                },
                paths.drawers.ClientSummaryPage,
                {
                  controllerName,
                  companyName,
                  macAddress,
                },
              )}
            >
              View client
            </Button>
          )}
        </>
      )}
      {deviceType === DeviceType.AccessPoint && (
        <>
          {isWOS2Enabled && uuid && (
            <Button
              as={Link}
              variant="secondary"
              width="100%"
              size="large"
              icon="chevron-right"
              arrangement="leading-label"
              to={makeLink(paths.pages.AccessPointPage, {
                networkSlug: network.slug,
                companyName,
                uuid,
                tab: 'insights',
              })}
            >
              View access point
            </Button>
          )}
          {!isWOS2Enabled && serialNumber && controllerName && (
            <Button
              as={Link}
              variant="secondary"
              width="100%"
              size="large"
              icon="chevron-right"
              arrangement="leading-label"
              to={makeLink(paths.pages.LegacyAccessPointDetailPage, {
                controllerName,
                companyName,
                deviceName: serialNumber,
              })}
            >
              View access point
            </Button>
          )}
        </>
      )}
      {isSOSEnabled && deviceType === DeviceType.Switch && uuid && (
        <Button
          as={Link}
          variant="secondary"
          width="100%"
          size="large"
          icon="chevron-right"
          arrangement="leading-label"
          to={makeLink(paths.pages.SwitchDetailPage, {
            networkSlug: network.slug,
            companyName,
            uuid,
            tab: 'ports',
          })}
        >
          View switch
        </Button>
      )}
      {isCOS2Enabled && deviceType === DeviceType.Controller && uuid && (
        <Button
          as={Link}
          variant="secondary"
          width="100%"
          size="large"
          icon="chevron-right"
          arrangement="leading-label"
          to={makeLink(paths.pages.SecurityApplianceDetailPage, {
            networkSlug: network.slug,
            companyName,
            uuid,
            tab: 'insights',
          })}
        >
          View security appliance
        </Button>
      )}
    </>
  );
}

export default function SwitchPortEditDrawer(props: SwitchPortEditDrawerProps) {
  const { phyInterfaceUUID, virtualDeviceUUID } = props;

  const portData = useGraphQL(PortsQuery, { virtualDeviceUUID }).data
    ?.phyInterfacesForVirtualDevice;
  expectDefinedOrThrow(
    portData,
    new ResourceNotFoundError(`PhyInterfaces not found for device ${virtualDeviceUUID}`),
  );

  const port = portData.find((p) => p.UUID === phyInterfaceUUID);
  expectDefinedOrThrow(
    port,
    new ResourceNotFoundError(`No PhyInterface found for UUID ${phyInterfaceUUID}`),
  );

  const virtualDevice = useGraphQL(SwitchQuery, { uuid: virtualDeviceUUID })?.data?.virtualDevice;
  expectDefinedOrThrow(
    virtualDevice,
    new ResourceNotFoundError(`No VirtualDevice found for switch UUID ${virtualDeviceUUID}`),
  );

  return (
    <Drawer>
      <DrawerHeader
        icon="ethernet"
        heading={port.label ?? `Port ${port.portNumber}`}
        onClose={useCloseDrawerCallback()}
        actions={<SwitchPortActions view="edit" port={port} virtualDevice={virtualDevice} />}
      />
      <Suspense fallback={<DrawerContentLoadingFallback />}>
        <PortConfig {...props} />
      </Suspense>
    </Drawer>
  );
}

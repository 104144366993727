import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import RateLimiting from '../../../../../components/RateLimiting/RateLimiting';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/rate-limiting',
  layout: 'VirtualNetworkLayout',
  title: 'Rate limiting - Firewall',
});

export default function RateLimitingPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermRateLimitRead) &&
            ldFlags['rate-limiting'] &&
            nosFlags[NosFeature.COS2] &&
            nosFlags[NosFeature.RATE_LIMITING],
        )
      }
      should404OnAccessDenied
    >
      <RateLimiting />
    </IsPermitted>
  );
}

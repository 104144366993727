import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { useCallback } from 'react';

import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany2 } from '../../../../../providers/CurrentCompanyProvider';
import DeleteDialog from '../../../../Dialogs/DeleteDialog';
import {
  deleteAlertTargetWebhookMutation,
  useInvalidateWebhookTargets,
  useWebhookTarget,
} from '../utils';

export default function WebhookActions({ uuid }: { uuid: string }) {
  const deleteDialog = useDialogState();
  const deleteWebhookMutation = useGraphQLMutation(deleteAlertTargetWebhookMutation);
  const closeDrawer = useCloseDrawerCallback();
  const company = useCurrentCompany2();
  const invalidateWebhooks = useInvalidateWebhookTargets({ companyUUID: company?.uuid });
  const webhook = useWebhookTarget({ uuid, companyUUID: company?.uuid });

  const handleDeleteWebhook = useCallback(
    () =>
      deleteWebhookMutation.mutate(
        { UUID: uuid },
        {
          onSuccess() {
            invalidateWebhooks();
            closeDrawer();
            notify('Successfully deleted webhook', {
              variant: 'positive',
            });
          },
          onError() {
            notify('Failed to delete webhook', {
              variant: 'negative',
            });
          },
          onSettled: closeDrawer,
        },
      ),
    [deleteWebhookMutation, uuid, closeDrawer, invalidateWebhooks],
  );

  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onSelect={deleteDialog.state.open} icon="trash-can">
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>

      <DeleteDialog
        state={deleteDialog.state}
        label={webhook.label ?? webhook.url}
        handleDelete={handleDeleteWebhook}
      />
    </>
  );
}

import type { SortingState } from '@tanstack/react-table';
import {
  Button,
  EmptyState,
  HStack,
  Icon,
  PaneContent,
  PaneFooter,
  Skeleton,
  space,
  Tooltip,
  VStack,
} from '@meterup/atto';
import { AutoTable, isDefinedAndNotEmpty } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { truncate } from 'lodash-es';
import { DateTime } from 'luxon';
import { Suspense, useCallback, useMemo, useState } from 'react';

import type { WirelessConnectionEventQueryResult } from '../Clients/utils';
import type { WirelessConnectionEventByAccessPointQueryResult } from '../Wireless/utils';
import { paths } from '../../constants';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { getDurationSeconds, getStep, timePeriodLabel } from '../../utils/chart_utils';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { fromISOtoLocaleString } from '../../utils/time';
import {
  ConnectionEventIcon,
  eventNameHuman,
  isFastRoam,
  isRoam,
  useClientNamesByMACForAccessPoint,
  WirelessConnectionEventsQuery,
} from '../Clients/utils';
import { APWOS2DeviceTarget, ClientDeviceTarget, SSIDDeviceTarget } from '../DeviceTargets';
import { NoValue } from '../NoValue';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { radioBandFromHuman, radioBandToHuman } from '../Wireless/RadioProfiles/utils';
import { WirelessConnectionEventsByAccessPointQuery } from '../Wireless/utils';
import { MetricBandFilter, MetricsDeviceType } from './utils';

type ConnectionEvent =
  | WirelessConnectionEventQueryResult
  | WirelessConnectionEventByAccessPointQueryResult;

const builder = createColumnBuilder<ConnectionEvent>();

const useColumns = () => {
  const namesByMac = useClientNamesByMACForAccessPoint();

  return useMemo(
    () => [
      builder.display({
        id: 'wireless-connection-event-icon',
        meta: { width: 36 },
        cell: (r) => <ConnectionEventIcon event={r.row} size={12} />,
      }),
      builder.data((r) => eventNameHuman(r), {
        id: 'wireless-connection-event-type',
        header: 'Event',
        meta: {
          isLeading: true,
        },
        cell: ({ value, row }) => {
          if (isFastRoam(row)) {
            return (
              <Tooltip contents="This was an 802.11r fast roam.">
                <HStack spacing={space(4)} align="center">
                  {value}
                  <Icon
                    icon="lightning"
                    size={12}
                    color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
                  />
                </HStack>
              </Tooltip>
            );
          }

          return <span>{value}</span>;
        },
      }),
      builder.data((r) => radioBandToHuman(r.band), {
        id: 'wireless-connection-event-band',
        header: 'Band',
      }),
      builder.data((r) => r.ssid?.ssid ?? '', {
        id: 'wireless-connection-event-ssid',
        header: 'SSID',
        cell: ({ value }) =>
          isDefinedAndNotEmpty(value) ? (
            <SSIDDeviceTarget ssidName={value} wrap={false} />
          ) : (
            <NoValue />
          ),
      }),

      builder.data((r) => r.virtualDevice.label, {
        id: 'wireless-connection-event-virtual-device-uuid',
        header: 'Access point',
        cell: ({ row }) => {
          if (isRoam(row)) {
            return (
              <HStack spacing={space(8)} align="center" wrap="no-wrap">
                <APWOS2DeviceTarget
                  uuid={row.previousVirtualDeviceConnectedTo!.UUID}
                  label={truncate(row.previousVirtualDeviceConnectedTo!.label, { length: 12 })}
                />
                <ConnectionEventIcon event={row} size={12} />
                <APWOS2DeviceTarget
                  uuid={row.virtualDevice.UUID}
                  label={truncate(row.virtualDevice.label, { length: 12 })}
                />
              </HStack>
            );
          }
          return (
            <APWOS2DeviceTarget uuid={row.virtualDevice.UUID} label={row.virtualDevice.label} />
          );
        },
      }),
      builder.data((r) => namesByMac[r.macAddress] ?? r.macAddress, {
        id: 'wireless-connection-event-client',
        header: 'Client',
        cell: (props) => (
          <ClientDeviceTarget
            clientName={namesByMac[props.row.macAddress]}
            macAddress={props.row.macAddress}
          />
        ),
      }),
      builder.data((r) => r.timestamp, {
        id: 'wireless-connection-event-timestamp',
        header: 'Timestamp',
        meta: {
          alignment: 'end',
        },
        cell: ({ row }) => (
          <span>
            {fromISOtoLocaleString({
              iso: row.timestamp,
              format: DateTime.DATETIME_SHORT_WITH_SECONDS,
            })}
          </span>
        ),
      }),
    ],
    [namesByMac],
  );
};

function ConnectionEventActions({
  loading,
  offset,
  setOffset,
  limit,
  hasMore,
}: {
  loading: boolean;
  offset: number;
  setOffset: (n: number) => void;
  limit: number;
  hasMore: boolean;
}) {
  return (
    <HStack spacing={space(4)}>
      <Button
        onClick={() => setOffset(offset - limit)}
        disabled={offset === 0}
        loading={loading}
        variant="secondary"
      >
        Previous
      </Button>
      <Button
        onClick={() => setOffset(offset + limit)}
        loading={loading}
        disabled={!hasMore}
        variant="secondary"
      >
        Next
      </Button>
    </HStack>
  );
}

function ConnectionEventsLoadingState() {
  return (
    <>
      <PaneContent gutter="all">
        <VStack spacing={space(16)}>
          <Skeleton height={120} width="100%" radius={6} />
          <Skeleton height={120} width="100%" radius={6} />
          <Skeleton height={120} width="100%" radius={6} />
        </VStack>
      </PaneContent>
      <PaneFooter
        actions={
          <ConnectionEventActions
            loading
            offset={0}
            setOffset={() => {}}
            limit={0}
            hasMore={false}
          />
        }
      />
    </>
  );
}

function ConnectionEventsTable({
  identifier,
  deviceType,
  band,
  ssidUUID,
  timePeriod,
  globalFilter,
  offset,
  limit,
  setHasMore,
}: {
  identifier: string;
  deviceType: MetricsDeviceType;
  band: MetricBandFilter;
  ssidUUID: string;
  timePeriod: string;
  globalFilter: string | undefined;
  offset: number;
  limit: number;
  setHasMore: (val: boolean) => void;
}) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const columns = useColumns();

  const clientConnectionEvents =
    useGraphQL(
      WirelessConnectionEventsQuery,
      {
        networkUUID: network.UUID,
        mac: identifier,
        filter: {
          bands: band === MetricBandFilter.All ? undefined : [radioBandFromHuman(band)],
          ssidUUIDs: ssidUUID === 'All' ? undefined : [ssidUUID],
          timeFilter: {
            durationSeconds: getDurationSeconds(timePeriod),
            stepSeconds: getStep(timePeriod),
          },
        },
        limit,
        offset,
      },
      {
        enabled: deviceType === MetricsDeviceType.Client,
      },
    ).data?.wirelessClientConnectionEventsByClient ?? [];

  const apConnectionEvents =
    useGraphQL(
      WirelessConnectionEventsByAccessPointQuery,
      {
        networkUUID: network.UUID,
        virtualDeviceUUID: identifier,
        filter: {
          bands: band === MetricBandFilter.All ? undefined : [radioBandFromHuman(band)],
          ssidUUIDs: ssidUUID === 'All' ? undefined : [ssidUUID],
          timeFilter: {
            durationSeconds: getDurationSeconds(timePeriod),
            stepSeconds: getStep(timePeriod),
          },
        },
        limit,
        offset,
      },
      {
        enabled: deviceType === MetricsDeviceType.AccessPoint,
      },
    ).data?.wirelessClientConnectionEventsByAP ?? [];

  const connectionEvents =
    deviceType === MetricsDeviceType.Client ? clientConnectionEvents : apConnectionEvents;

  setHasMore(connectionEvents.length >= limit);

  const params = Nav.useRegionParams('drawer', paths.drawers.ConnectionEventDetailPage)!;
  const rowSelected = useCallback(
    (row: ConnectionEvent) => params?.timestamp === row.timestamp,
    [params?.timestamp],
  );

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return connectionEvents.length > 0 ? (
    <AutoTable
      key="wireless-connection-events"
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      data={connectionEvents.slice(0, limit)}
      columns={columns}
      globalFilter={globalFilter}
      isRowSelected={rowSelected}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.ConnectionEventDetailPage, {
          companyName,
          networkSlug: network.slug,
          identifier,
          deviceType,
          timestamp: row.timestamp,
        })
      }
    />
  ) : (
    <EmptyState
      heading={`No connection events in the ${timePeriodLabel(timePeriod).toLowerCase()}`}
      icon="log"
    />
  );
}

export default function ConnectionEvents({
  identifier,
  deviceType,
  band,
  ssidUUID,
  timePeriod,
  globalFilter,
}: {
  identifier: string;
  deviceType: MetricsDeviceType;
  band: MetricBandFilter;
  ssidUUID: string;
  timePeriod: string;
  globalFilter: string | undefined;
}) {
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useSearchParamsState<number>('offset', 0);
  const currentOffset = offset ?? 0;
  const limit = 20;

  return (
    <Suspense fallback={<ConnectionEventsLoadingState />}>
      <PaneContent gutter="none">
        <ConnectionEventsTable
          setHasMore={setHasMore}
          identifier={identifier}
          deviceType={deviceType}
          band={band}
          ssidUUID={ssidUUID}
          timePeriod={timePeriod}
          globalFilter={globalFilter}
          offset={currentOffset}
          limit={limit}
        />
      </PaneContent>
      {(hasMore || currentOffset > 0) && (
        <PaneFooter
          actions={
            <ConnectionEventActions
              loading={false}
              offset={currentOffset}
              setOffset={setOffset}
              limit={limit}
              hasMore={hasMore}
            />
          }
        />
      )}
    </Suspense>
  );
}

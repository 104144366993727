import {
  Button,
  ComboBox,
  ComboBoxItem,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  HStack,
  Icon,
  PrimaryField,
  PrimaryToggleField,
  space,
  Textarea,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';

import type { UpdateOutletInterfaceFormValues } from './utils';
import { VirtualDeviceType } from '../../../gql/graphql';
import { UpdateOutletInterfaceInputSchema } from '../../../gql/zod-types';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useVirtualDevices } from '../../../routes/pages/network/insights/logs/hooks/useVirtualDevices';
import { deviceTypeToHardwareIconPropHardware } from '../../../utils/types';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider, NumberFieldProvider } from '../../Form/FieldProvider';
import { OutletCyclePowerDialog } from './OutletCyclePowerDialog';
import {
  PowerDistributionUnitOutletsQuery,
  PowerDistributionUnitQuery,
  updateOutletInterfaceMutation,
} from './utils';

export function OutletDetail({
  virtualDeviceUUID,
  phyInterfaceUUID,
}: {
  virtualDeviceUUID: string;
  phyInterfaceUUID: string;
}) {
  const virtualDevice = useGraphQL(PowerDistributionUnitQuery, { uuid: virtualDeviceUUID }).data
    ?.virtualDevice;
  const outlets = useGraphQL(PowerDistributionUnitOutletsQuery, {
    virtualDeviceUUID,
  })?.data?.outletInterfacesForVirtualDevice;
  expectDefinedOrThrow(
    outlets,
    new ResourceNotFoundError(`Outlets not found for virtual device UUID ${virtualDeviceUUID}`),
  );
  const selectedOutlet = outlets?.find((p) => p.UUID === phyInterfaceUUID);
  expectDefinedOrThrow(
    selectedOutlet,
    new ResourceNotFoundError(`No outlet PhyInterface found for UUID ${phyInterfaceUUID}`),
  );
  const { virtualDevices: devices } = useVirtualDevices();
  const filteredDevices = useMemo(
    () =>
      devices.filter(
        (device) =>
          device.deviceType !== VirtualDeviceType.AccessPoint && device.UUID !== virtualDeviceUUID,
      ),
    [devices, virtualDeviceUUID],
  );

  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const dialogProps = useDialogState();
  const updateMutation = useGraphQLMutation(updateOutletInterfaceMutation);
  const handleSubmit = useCallback(
    ({
      label,
      description,
      isEnabled,
      powerCycleDelaySec,
      connectedVirtualDeviceUUID,
    }: UpdateOutletInterfaceFormValues) => {
      updateMutation.mutate(
        {
          uuid: selectedOutlet.UUID,
          input: {
            label,
            description,
            isEnabled,
            powerCycleDelaySec,
            connectedVirtualDeviceUUID: connectedVirtualDeviceUUID || null,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              makeQueryKey(PowerDistributionUnitOutletsQuery, { virtualDeviceUUID }),
            );
            notify('Successfully updated outlet configuration.', {
              variant: 'positive',
            });
          },
          onError: (err) => {
            notify(
              `There was a problem updating the outlet configuration${getGraphQLErrorMessageOrEmpty(err)}.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [queryClient, selectedOutlet, updateMutation, virtualDeviceUUID],
  );

  return (
    <Drawer>
      <DrawerHeader
        icon="outlet"
        heading={selectedOutlet.label}
        onClose={useCloseDrawerCallback()}
        actions={
          virtualDevice?.hardwareDevice?.serialNumber && selectedOutlet.isEnabled ? (
            <DropdownMenu>
              <DropdownMenuButton
                variant="secondary"
                icon="overflow-horizontal"
                arrangement="hidden-label"
              >
                Actions
              </DropdownMenuButton>
              <DropdownMenuPopover align="end">
                <DropdownMenuGroup>
                  <DropdownMenuItem onSelect={dialogProps.openFromMenu} icon="power-cycle">
                    Cycle power
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuPopover>
            </DropdownMenu>
          ) : undefined
        }
      />
      <Formik<UpdateOutletInterfaceFormValues>
        onSubmit={handleSubmit}
        initialValues={selectedOutlet}
        validate={withZodSchema(UpdateOutletInterfaceInputSchema)}
      >
        <Form>
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="label">
                <PrimaryField label="Label" element={<TextInput />} />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enable" />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="description">
                <PrimaryField optional label="Description" element={<Textarea />} />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <NumberFieldProvider defaultValue={null} name="powerCycleDelaySec">
                <PrimaryField
                  label="Power cycle delay (seconds)"
                  element={<TextInput type="number" inputProps={{ max: 60, min: 1 }} />}
                />
              </NumberFieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="connectedVirtualDeviceUUID">
                <PrimaryField
                  label="Connected device"
                  element={
                    <ComboBox
                      defaultItems={filteredDevices}
                      placeholder="Select a device"
                      canClearValue
                    >
                      {(item) => (
                        <ComboBoxItem key={item.UUID} textValue={item.label}>
                          <HStack align="center" spacing={space(10)} wrap="wrap">
                            <VStack>
                              <Icon
                                icon={deviceTypeToHardwareIconPropHardware(item.deviceType)}
                                color={{ dark: 'gray200', light: 'gray600' }}
                                size={16}
                              />
                            </VStack>
                            <VStack>{item.label || item.UUID}</VStack>
                          </HStack>
                        </ComboBoxItem>
                      )}
                    </ComboBox>
                  }
                />
              </FieldProvider>
            </FieldContainer>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </Form>
      </Formik>
      {virtualDevice?.hardwareDevice?.serialNumber && selectedOutlet?.isEnabled && (
        <OutletCyclePowerDialog
          state={dialogProps.state}
          label={selectedOutlet?.label}
          serialNumber={virtualDevice.hardwareDevice.serialNumber}
          virtualDeviceUUID={virtualDeviceUUID}
          outletUUIDs={[selectedOutlet.UUID]}
        />
      )}
    </Drawer>
  );
}

import {
  Alert,
  Badge,
  Button,
  ComboBox,
  ComboBoxItem,
  CompositeField,
  FieldContainer,
  HStack,
  Link as AttoLink,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryField,
  PrimaryFieldComposite,
  PrimaryToggleField,
  SecondaryField,
  SecondaryToggleField,
  Select,
  SelectItem,
  Small,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import IsOperator from '@meterup/authorization/src/components/IsOperator';
import { expectDefinedOrThrow, isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { SsidDynamicVlanMode } from '@meterup/common/src/gql/graphql';
import { useGraphQL } from '@meterup/graphql';
import { useFormikContext } from 'formik';
import { capitalize } from 'lodash-es';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import type { VLANQueryResult } from '../../Hardware/Switches/utils';
import type {
  SSID,
  SSIDsQueryResult,
  ValidCreateSSIDParams,
  ValidSSIDParams,
  ValidUpdateSSIDParams,
} from './SSIDsUtils';
import { getSuggestedPassword } from '../../../api/api';
import { paths } from '../../../constants';
import {
  PermissionType,
  SsidEncryption80211wProtectedManagementFramesMode,
  SsidEncryptionProtocol,
  SsidPasswordRotationCadence,
} from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useToggle } from '../../../hooks/useToggle';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { logError } from '../../../utils/logError';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { dayOfWeek, hourOfDay, numberToOrdinal } from '../../../utils/strings';
import { CopyToClipboardButtonMinimal } from '../../CopyToClipboardButton';
import {
  FieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
} from '../../Form/FieldProvider';
import { NumberField } from '../../Form/Fields';
import { FormikConditional } from '../../FormikConditional';
import { vlanHasStaticIP } from '../../NetworkWide/VLANs/utils';
import { PasswordStrengthIndicator } from '../../PasswordStrengthIndicator';
import { AccessPointsQuery } from '../utils';
import {
  encryptionProtocolHuman,
  enterpriseEncryptionProtocol,
  RadiusProfilesQuery,
  SSID_NO_VLAN_MESSAGE,
} from './SSIDsUtils';

export function EnabledField({ ssid }: { ssid?: SSID }) {
  const hasSSID = !!ssid;
  const { values, touched, setFieldValue } = useFormikContext<
    ValidCreateSSIDParams | ValidUpdateSSIDParams
  >();

  useEffect(() => {
    if (!hasSSID && !touched.isEnabled) {
      if (!values.isEnabled && values.vlanUUID) {
        setFieldValue('isEnabled', true);
      } else if (!values.vlanUUID && values.isEnabled) {
        setFieldValue('isEnabled', false);
      }
    }
  }, [hasSSID, values.vlanUUID, values.isEnabled, touched.isEnabled, setFieldValue]);

  return (
    <FieldContainer>
      <FieldProvider name="isEnabled">
        <PrimaryToggleField label="Enabled" />
      </FieldProvider>
    </FieldContainer>
  );
}

function HiddenField() {
  const formik = useFormikContext<ValidSSIDParams>();

  const handleChange = (broadcast: boolean) => {
    formik.setFieldValue('isHidden', !broadcast);
  };

  return (
    <SecondaryToggleField
      label="Broadcast"
      selected={!formik.values.isHidden}
      onChange={handleChange}
      variant="positive"
      neutral={{
        label: 'Hidden',
        icon: 'eye-closed',
      }}
      positive={{
        label: 'Visible',
        icon: 'wifi',
      }}
    />
  );
}

export function SSIDField() {
  const formik = useFormikContext<ValidSSIDParams>();
  const handleChange = (val: string) => {
    if (val.toLowerCase().includes('guest')) {
      formik.setFieldValue('isGuest', true);
    } else {
      formik.setFieldValue('isGuest', false);
    }
  };

  return (
    <FieldContainer>
      <FieldProvider name="ssid">
        <PrimaryField label="SSID" element={<TextInput onChange={handleChange} />} />
      </FieldProvider>
      <HiddenField />
      <FieldProvider name="isGuest">
        <SecondaryToggleField
          label="Guest SSID"
          description={
            <>
              Show on{' '}
              <AttoLink href="http://guest.meter.network" target="_blank" rel="noopener noreferrer">
                guest.meter.network
              </AttoLink>
            </>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export function PasswordField({
  nextRotation,
  timezone,
  isNOSEnabledWPA3PSK,
  isNOSEnabledWPA3Enterprise,
  isNOSEnabledOpenMACAuthRadius,
}: {
  nextRotation: string | undefined | null;
  timezone: string | undefined | null;
  isNOSEnabledWPA3PSK: boolean;
  isNOSEnabledWPA3Enterprise: boolean;
  isNOSEnabledOpenMACAuthRadius: boolean;
}) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const radiusProfiles =
    useGraphQL(RadiusProfilesQuery, { networkUUID: network.UUID }).data
      ?.encryption8021XsForNetwork ?? [];

  const formik = useFormikContext<ValidSSIDParams>();

  const suggestPassword = async () => {
    try {
      const password = await getSuggestedPassword(3);
      formik.setFieldValue('password', password);
    } catch (e) {
      logError(e);
    }
  };

  const copyablePassword = formik.values.password ? formik.values.password : '';

  const suggestPasswordButton = (
    <Button
      type="button"
      icon="arrows-rotate"
      arrangement="hidden-label"
      onClick={suggestPassword}
      variant="secondary"
    >
      Suggest password
    </Button>
  );

  const nextRotationStr = isDefinedAndNotEmpty(nextRotation)
    ? `Next rotation at ${DateTime.fromISO(nextRotation).toLocaleString(DateTime.DATETIME_MED)}`
    : null;

  const timezoneStr = isDefinedAndNotEmpty(timezone)
    ? ` (${DateTime.local({ zone: timezone }).toFormat('ZZZZ')})`
    : '';

  const handleTogglePasswordRotation = (val: boolean) => {
    if (val) {
      suggestPassword();
    }
  };

  return (
    <>
      <FieldContainer>
        <FieldProvider name="hasPassword">
          <PrimaryToggleField label="Enable security" />
        </FieldProvider>
        <FormikConditional<ValidSSIDParams> condition={(values) => values.hasPassword}>
          <PrimaryFieldComposite
            label="Authentication" // https://meterup.slack.com/archives/C01KPCQ98UR/p1712786191751609
            fields={
              <HStack spacing={space(6)}>
                <FieldProvider name="encryptionProtocol">
                  <div style={{ flexShrink: 0 }}>
                    <CompositeField
                      label="Protocol"
                      element={
                        <Select
                          width="100%"
                          onValueChange={() => formik.setFieldTouched('encryptionProtocol', true)}
                        >
                          {Object.values(SsidEncryptionProtocol)
                            .filter(
                              (encryptionProtocol) =>
                                (isNOSEnabledWPA3PSK ||
                                  (encryptionProtocol !== SsidEncryptionProtocol.Wpa3 &&
                                    encryptionProtocol !==
                                      SsidEncryptionProtocol.Wpa3Transition)) &&
                                (isNOSEnabledWPA3Enterprise ||
                                  encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise) &&
                                (isNOSEnabledOpenMACAuthRadius ||
                                  encryptionProtocol !== SsidEncryptionProtocol.OpenMacAuthRadius),
                            )
                            .map((encryptionProtocol) => (
                              <SelectItem key={encryptionProtocol}>
                                {encryptionProtocolHuman(encryptionProtocol)}
                              </SelectItem>
                            ))}
                        </Select>
                      }
                    />
                  </div>
                </FieldProvider>

                <FormikConditional<ValidSSIDParams>
                  condition={(values) => !enterpriseEncryptionProtocol(values.encryptionProtocol)}
                >
                  <FieldProvider name="password">
                    <CompositeField
                      label="Password"
                      element={<TextInput width="100%" type="text" />}
                    />
                  </FieldProvider>
                </FormikConditional>
              </HStack>
            }
            controls={
              <FormikConditional<ValidSSIDParams>
                condition={(values) => !enterpriseEncryptionProtocol(values.encryptionProtocol)}
              >
                <PasswordStrengthIndicator password={formik.values.password ?? ''} />
                {suggestPasswordButton}
                {isDefinedAndNotEmpty(copyablePassword) && (
                  <CopyToClipboardButtonMinimal text={copyablePassword} />
                )}
              </FormikConditional>
            }
          />

          <FormikConditional<ValidSSIDParams>
            condition={(values) =>
              enterpriseEncryptionProtocol(values.encryptionProtocol) && radiusProfiles.length > 0
            }
          >
            <FieldProvider name="primaryEncryption8021XUUID">
              <SecondaryField
                label="Primary RADIUS profile"
                element={
                  <Select width="100%" placeholder="Select profile">
                    {radiusProfiles.map((r) => (
                      <SelectItem key={r.UUID}>{r.label}</SelectItem>
                    ))}
                  </Select>
                }
              />
            </FieldProvider>
            <FieldProvider name="secondaryEncryption8021XUUID">
              <SecondaryField
                label="Secondary RADIUS profile"
                element={
                  <Select width="100%" placeholder="Select profile">
                    {[{ UUID: '', label: 'None' }, ...radiusProfiles].map((r) => (
                      <SelectItem key={r.UUID}>{r.label}</SelectItem>
                    ))}
                  </Select>
                }
              />
            </FieldProvider>
          </FormikConditional>

          <FormikConditional<ValidSSIDParams>
            condition={(values) => !enterpriseEncryptionProtocol(values.encryptionProtocol)}
          >
            <FieldProvider name="passwordRotationEnabled">
              <PrimaryToggleField
                label="Auto-rotate password"
                onChange={handleTogglePasswordRotation}
              />
            </FieldProvider>

            <FormikConditional<ValidSSIDParams>
              condition={(values) => values.passwordRotationEnabled}
            >
              <PrimaryFieldComposite
                label="Rotation cadence"
                description={nextRotationStr}
                fields={
                  <VStack spacing={space(6)}>
                    <FieldProvider name="passwordRotationCadence">
                      <div style={{ flexShrink: 0 }}>
                        <CompositeField
                          label="Cadence"
                          element={
                            <Select width="100%" placeholder="Select rotation cadence...">
                              <SelectItem key={SsidPasswordRotationCadence.Daily}>
                                Every Day
                              </SelectItem>
                              <SelectItem key={SsidPasswordRotationCadence.Weekly}>
                                Every Week
                              </SelectItem>
                              <SelectItem key={SsidPasswordRotationCadence.Monthly}>
                                Every Month
                              </SelectItem>
                            </Select>
                          }
                        />
                      </div>
                    </FieldProvider>
                    <FormikConditional<ValidSSIDParams>
                      condition={(values) =>
                        values.passwordRotationCadence === SsidPasswordRotationCadence.Weekly
                      }
                    >
                      <NumberFieldProvider
                        name="passwordRotationDayOfWeekLocal"
                        defaultValue={null}
                      >
                        <CompositeField
                          label="Day of week"
                          element={
                            <Select width="100%" placeholder="Select day of the week...">
                              {[...Array(7).keys()].map((p) => (
                                <SelectItem key={p.toString()}>On {dayOfWeek(p)}</SelectItem>
                              ))}
                            </Select>
                          }
                        />
                      </NumberFieldProvider>
                    </FormikConditional>
                    <FormikConditional<ValidSSIDParams>
                      condition={(values) =>
                        values.passwordRotationCadence === SsidPasswordRotationCadence.Monthly
                      }
                    >
                      <NumberFieldProvider
                        name="passwordRotationDayOfMonthLocal"
                        defaultValue={null}
                      >
                        <CompositeField
                          label="Day of month"
                          element={
                            <CompositeField
                              label="Day of month"
                              element={
                                <Select width="100%" placeholder="Select day of the month...">
                                  {[...Array(31).keys()].map((p) => (
                                    <SelectItem key={(p + 1).toString()}>
                                      On the {numberToOrdinal(p + 1)}
                                    </SelectItem>
                                  ))}
                                </Select>
                              }
                            />
                          }
                        />
                      </NumberFieldProvider>
                    </FormikConditional>
                    <FormikConditional<ValidSSIDParams>
                      condition={(values) => isDefinedAndNotEmpty(values.passwordRotationCadence)}
                    >
                      <NumberFieldProvider
                        name="passwordRotationHourOfDayLocal"
                        defaultValue={null}
                      >
                        <CompositeField
                          label="Hour of day"
                          element={
                            <Select width="100%" placeholder="Select hour of the day...">
                              {[...Array(24).keys()].map((p) => (
                                <SelectItem key={p.toString()}>
                                  At {hourOfDay(p)}
                                  {timezoneStr}
                                </SelectItem>
                              ))}
                            </Select>
                          }
                        />
                      </NumberFieldProvider>
                    </FormikConditional>
                  </VStack>
                }
              />
            </FormikConditional>
          </FormikConditional>
        </FormikConditional>
      </FieldContainer>

      <FormikConditional<ValidSSIDParams>
        condition={(values) =>
          values.passwordRotationEnabled && (values.passwordRotationDayOfMonthLocal ?? 0) > 28
        }
      >
        <Alert
          icon="attention"
          variant="alternative"
          copy={`For months that don't have ${formik.values.passwordRotationDayOfMonthLocal} days, the password will be rotated on the first day of the following month.`}
        />
      </FormikConditional>

      <FormikConditional<ValidSSIDParams>
        condition={(values) =>
          enterpriseEncryptionProtocol(values.encryptionProtocol) && radiusProfiles.length === 0
        }
      >
        <Alert
          icon="globe"
          variant="brand"
          heading="No RADIUS profiles"
          copy={`At least one RADIUS profile is required to use ${encryptionProtocolHuman(formik.values.encryptionProtocol)}.`}
          trailingButtons={
            <Button
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.RADIUSProfileCreatePage, {
                companyName,
                networkSlug: network.slug,
              })}
              arrangement="leading-icon"
              icon="plus"
              variant="primary"
            >
              Add RADIUS profile
            </Button>
          }
        />
      </FormikConditional>
    </>
  );
}

export function FrameProtectionField({ isNOSEnabled }: { isNOSEnabled: boolean }) {
  const { values, setFieldValue, touched } = useFormikContext<ValidSSIDParams>();

  const modes: { key: string; label: string }[] = [];

  if (
    values.encryptionProtocol &&
    [
      SsidEncryptionProtocol.Wpa2,
      SsidEncryptionProtocol.Wpa2Enterprise,
      SsidEncryptionProtocol.Wpa3Transition,
    ].includes(values.encryptionProtocol)
  ) {
    modes.push({
      key: SsidEncryption80211wProtectedManagementFramesMode.Optional,
      label: 'Optional',
    });
  }

  modes.push({
    key: SsidEncryption80211wProtectedManagementFramesMode.Required,
    label: 'Required',
  });

  useEffect(() => {
    if (!touched.encryptionProtocol) return;
    if (!values.encryptionProtocol) return;

    switch (values.encryptionProtocol) {
      case SsidEncryptionProtocol.Wpa2:
      case SsidEncryptionProtocol.Wpa2Enterprise:
        setFieldValue('frameProtectionEnabled', false);
        setFieldValue('encryption80211wProtectedManagementFramesMode', null);
        break;

      case SsidEncryptionProtocol.Wpa3:
      case SsidEncryptionProtocol.Wpa3Enterprise:
        setFieldValue('frameProtectionEnabled', true);
        setFieldValue(
          'encryption80211wProtectedManagementFramesMode',
          SsidEncryption80211wProtectedManagementFramesMode.Required,
        );
        break;

      case SsidEncryptionProtocol.Wpa3Transition:
        setFieldValue('frameProtectionEnabled', true);
        if (
          values.encryption80211wProtectedManagementFramesMode !==
          SsidEncryption80211wProtectedManagementFramesMode.Required
        ) {
          setFieldValue(
            'encryption80211wProtectedManagementFramesMode',
            SsidEncryption80211wProtectedManagementFramesMode.Optional,
          );
        }
        break;
    }
  }, [
    setFieldValue,
    values.encryptionProtocol,
    values.encryption80211wProtectedManagementFramesMode,
    touched.encryptionProtocol,
  ]);

  if (!isNOSEnabled) {
    return null;
  }

  return (
    <FieldContainer>
      <FieldProvider name="frameProtectionEnabled">
        <PrimaryToggleField label="802.11w" description="Management frame protection" />
      </FieldProvider>

      <FormikConditional<ValidSSIDParams> condition={(v) => v.frameProtectionEnabled}>
        <FieldProvider name="encryption80211wProtectedManagementFramesMode">
          <PrimaryField
            label="Mode"
            element={
              <Select width="100%" placeholder="Select mode">
                {modes.map((m) => (
                  <SelectItem key={m.key}>{m.label}</SelectItem>
                ))}
              </Select>
            }
          />
        </FieldProvider>
      </FormikConditional>
    </FieldContainer>
  );
}

export function Enable80211axField({ isNOSEnabled }: { isNOSEnabled: boolean }) {
  const isConfirmingChanges = useToggle();

  return isNOSEnabled ? (
    <FieldProvider name="is80211axEnabled">
      {isConfirmingChanges.value && (
        <Alert
          copy="Toggling 802.11ax will force clients connected to this SSID off of the network momentarily."
          icon="warning"
          variant="attention"
        />
      )}
      <PrimaryToggleField
        label="802.11ax"
        description="Wi-Fi 6"
        onChange={() => {
          isConfirmingChanges.toggle();
        }}
      />
    </FieldProvider>
  ) : null;
}

export function RadiusChangeOfAuthorizationField({ isNOSEnabled }: { isNOSEnabled: boolean }) {
  const { values, setFieldValue } = useFormikContext<ValidSSIDParams>();
  const supported =
    isNOSEnabled && values.hasPassword && enterpriseEncryptionProtocol(values.encryptionProtocol);

  const handleChange = (val: boolean) => {
    if (val) {
      setFieldValue('isRoaming80211rEnabled', false);
    }
  };

  return supported ? (
    <FieldContainer>
      <FieldProvider name="radiusCoaEnabled">
        <PrimaryToggleField
          label="Radius CoA"
          description="Change of Authorization"
          onChange={handleChange}
        />
      </FieldProvider>

      <FormikConditional<ValidSSIDParams> condition={(v) => v.radiusCoaEnabled === true}>
        <NumberField label="CoA Port" name="radiusCoaPort" placeholder="3799" />
      </FormikConditional>
    </FieldContainer>
  ) : null;
}

export function FastRoamField({ isNOSEnabled }: { isNOSEnabled: boolean }) {
  const { values, setFieldValue } = useFormikContext<ValidSSIDParams>();
  const supported =
    isNOSEnabled && values.encryptionProtocol !== SsidEncryptionProtocol.OpenMacAuthRadius;

  const handleChange = (val: boolean) => {
    if (val) {
      setFieldValue('radiusCoaEnabled', false);
    }
  };

  return supported ? (
    <FieldProvider name="isRoaming80211rEnabled">
      <PrimaryToggleField
        label="802.11r"
        description="Fast BSS transition"
        onChange={handleChange}
      />
    </FieldProvider>
  ) : null;
}

export function ClientIsolationField({ isNOSEnabled }: { isNOSEnabled: boolean }) {
  return isNOSEnabled ? (
    <FieldProvider name="isIsolateClientsEnabled">
      <PrimaryToggleField
        label="Client isolation"
        description="Clients on this SSID cannot communicate"
      />
    </FieldProvider>
  ) : null;
}

export function ClientSteeringField({ isNOSEnabled }: { isNOSEnabled: boolean }) {
  return isNOSEnabled ? (
    <FieldProvider name="clientSteeringAssociationSteeringIsEnabled">
      <PrimaryToggleField label="Client steering" />
    </FieldProvider>
  ) : null;
}

export function Enable80211kField({ isNOSEnabled }: { isNOSEnabled: boolean }) {
  return isNOSEnabled ? (
    <FieldContainer>
      <FieldProvider name="isRoaming80211kEnabled">
        <PrimaryToggleField label="802.11k" description="Radio resource management" />{' '}
      </FieldProvider>
      <FormikConditional<ValidSSIDParams> condition={(v) => v.isRoaming80211kEnabled === true}>
        <FieldProvider name="clientSteeringPostassociationSteeringIsEnabled">
          <SecondaryToggleField
            label="Client post association steering"
            description="Client steering after association with an access point"
          />
        </FieldProvider>
      </FormikConditional>
    </FieldContainer>
  ) : null;
}

export function Enable80211vField({ isNOSEnabled }: { isNOSEnabled: boolean }) {
  return isNOSEnabled ? (
    <FieldProvider name="isRoaming80211vEnabled">
      <PrimaryToggleField label="802.11v" description="BSS transition management" />
    </FieldProvider>
  ) : null;
}

export function BandsField() {
  const valid2GMinRates = [
    { value: 1000, label: '1 Mbps' },
    { value: 2000, label: '2 Mbps' },
    { value: 5500, label: '5.5 Mbps' },
    { value: 6000, label: '6 Mbps' },
    { value: 11000, label: '11 Mbps' },
    { value: 12000, label: '12 Mbps' },
    { value: 24000, label: '24 Mbps' },
  ];
  const valid5GMinRates = [
    { value: 6000, label: '6 Mbps' },
    { value: 12000, label: '12 Mbps' },
    { value: 24000, label: '24 Mbps' },
  ];

  return (
    <FieldContainer>
      <FieldProvider name="isBand2GAllowed">
        <PrimaryToggleField label="2.4 GHz" />
      </FieldProvider>
      <IsOperator>
        <FormikConditional<ValidSSIDParams> condition={(values) => values.isBand2GAllowed === true}>
          <NumberFieldProvider
            name="band2GMinimumBasicRateKbps"
            defaultValue={valid2GMinRates.find(({ label }) => label === '11 Mbps')?.value ?? null}
          >
            <SecondaryField
              internal
              element={
                <Select width="100%">
                  {valid2GMinRates.map((s) => (
                    <SelectItem key={s.value.toString()}>{s.label}</SelectItem>
                  ))}
                </Select>
              }
              label="2.4 GHz minimum basic rate"
            />
          </NumberFieldProvider>
        </FormikConditional>
      </IsOperator>
      <FieldProvider name="isBand5GAllowed">
        <PrimaryToggleField label="5 GHz" />
      </FieldProvider>
      <IsOperator>
        <FormikConditional<ValidSSIDParams> condition={(values) => values.isBand5GAllowed === true}>
          <NumberFieldProvider
            name="band5GMinimumBasicRateKbps"
            defaultValue={valid5GMinRates.find(({ label }) => label === '12 Mbps')?.value ?? null}
          >
            <SecondaryField
              internal
              element={
                <Select width="100%">
                  {valid5GMinRates.map((s) => (
                    <SelectItem key={s.value.toString()}>{s.label}</SelectItem>
                  ))}
                </Select>
              }
              label="5 GHz minimum basic rate"
            />
          </NumberFieldProvider>
        </FormikConditional>
      </IsOperator>
    </FieldContainer>
  );
}

export function VLANField({
  isNOSEnabledDynamicVLAN,
  vlans,
}: {
  isNOSEnabledDynamicVLAN: boolean;
  vlans: VLANQueryResult[];
}) {
  const { values } = useFormikContext<ValidSSIDParams>();
  const dynamicVLANEnabled = isNOSEnabledDynamicVLAN && values.dynamicVLANEnabled;
  const enterprise = values.hasPassword && enterpriseEncryptionProtocol(values.encryptionProtocol);
  const vlanSelection =
    !dynamicVLANEnabled || values.dynamicVLANMode === SsidDynamicVlanMode.Enabled;

  return (
    <FieldContainer>
      {isNOSEnabledDynamicVLAN && enterprise && (
        <FieldProvider name="dynamicVLANEnabled">
          <PrimaryToggleField label="Dynamic VLAN" />
        </FieldProvider>
      )}
      {dynamicVLANEnabled && (
        <FieldProvider name="dynamicVLANMode">
          <PrimaryField
            label="Dynamic VLAN Mode"
            element={
              <Select width="100%">
                {Object.values(SsidDynamicVlanMode).map((mode) => (
                  <SelectItem key={mode}>{capitalize(mode)}</SelectItem>
                ))}
              </Select>
            }
          />
        </FieldProvider>
      )}
      {vlanSelection && (
        <FieldProvider name="vlanUUID">
          <PrimaryField
            label={dynamicVLANEnabled ? 'Fallback VLAN' : 'VLAN'}
            optional={!dynamicVLANEnabled}
            description={
              dynamicVLANEnabled
                ? 'VLAN to use if Radius server does not provide one for the user.'
                : SSID_NO_VLAN_MESSAGE
            }
            element={
              <ComboBox placeholder="Select VLAN" canClearValue>
                {vlans.map((vlan) => (
                  <ComboBoxItem key={vlan.UUID}>
                    {vlan.name}
                    {!vlanHasStaticIP(vlan) && (
                      <>
                        {' '}
                        <Badge size="small">Layer 2 only</Badge>
                      </>
                    )}
                  </ComboBoxItem>
                ))}
              </ComboBox>
            }
          />
        </FieldProvider>
      )}
    </FieldContainer>
  );
}

export function AccessPointsField({
  networkUUID,
  supportsIndeterminate,
}: {
  networkUUID: string;
  supportsIndeterminate?: boolean;
}) {
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const accessPoints = useGraphQL(AccessPointsQuery, { networkUUID, includeUptime }).data
    ?.virtualDevicesForNetwork;
  expectDefinedOrThrow(accessPoints, new ResourceNotFoundError('Unable to load access points'));

  const { values } = useFormikContext<ValidSSIDParams>();
  const indeterminate =
    supportsIndeterminate &&
    values.isEnabledForAllAccessPoints === undefined &&
    values.enabledAccessPointVirtualDeviceUUIDs === undefined;

  return (
    <FieldContainer>
      <FieldProvider name="isEnabledForAllAccessPoints">
        <PrimaryToggleField label="Enable on all access points" />
      </FieldProvider>

      <FormikConditional<ValidSSIDParams> condition={(v) => !v.isEnabledForAllAccessPoints}>
        <MultiComboBoxFieldProvider name="enabledAccessPointVirtualDeviceUUIDs">
          <PrimaryField
            label="Access points"
            description="List any access points which you would like this SSID to be applied to"
            element={
              <MultiComboBox
                aria-label="Access points to broadcast on"
                indeterminate={indeterminate}
              >
                {accessPoints.map((ap) => (
                  <MultiComboBoxItem key={ap.UUID}>{ap.label}</MultiComboBoxItem>
                ))}
              </MultiComboBox>
            }
          />
        </MultiComboBoxFieldProvider>
      </FormikConditional>
    </FieldContainer>
  );
}

export function Hotspot20LinkField({ ssid }: { ssid: SSIDsQueryResult }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const drawerLink = ssid.hs20HasProfile
    ? paths.drawers.Hotspot20EditPage
    : paths.drawers.Hotspot20AddPage;
  return (
    <PrimaryField
      label="Hotspot 2.0 profile"
      optional
      element={
        <Small>
          To configure a Hotspot 2.0 profile on{' '}
          <Badge
            key={ssid.ssid}
            arrangement="leading-icon"
            icon="ssid"
            size="small"
            variant="neutral"
          >
            {ssid.ssid}
          </Badge>
          , go to{' '}
          <AttoLink
            as={Link}
            to={Nav.makeTo({
              root: {
                pathname: makeLink(paths.pages.Hotspot20sPage, {
                  networkSlug: network.slug,
                  companyName,
                }),
              },
              drawer: makeLink(drawerLink, {
                networkSlug: network.slug,
                companyName,
                ...(ssid.hs20HasProfile ? { uuid: ssid.UUID } : {}),
              }),
            })}
          >
            {`${ssid.hs20HasProfile ? 'Edit ' : 'Add '}Hotspot 2.0 profile.`}
          </AttoLink>
        </Small>
      }
    />
  );
}

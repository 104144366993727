import { useRef } from 'react';

import AccessPointsLayout from '../../../../../../components/Hardware/AccessPoints/AccessPoints';
import AccessPointsList from '../../../../../../components/Hardware/AccessPoints/AccessPointsList';
import IsPermitted from '../../../../../../components/permissions/IsPermitted';
import { ValidAPListTabs } from '../../../../../../components/Wireless/utils';
import { PermissionType } from '../../../../../../gql/graphql';
import { NosFeature } from '../../../../../../hooks/useNosFeatures';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/list',
  layout: 'VirtualNetworkLayout',
});

export default function AccessPointsListPage() {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
            nosFlags[NosFeature.WOS2],
        )
      }
      should404OnAccessDenied
    >
      <AccessPointsLayout
        activeTab={ValidAPListTabs.List}
        contentContainerRef={containerRef}
        content={<AccessPointsList containerRef={containerRef} />}
      />
    </IsPermitted>
  );
}

import { Alert, Button, darkThemeSelector, Small, TextInput } from '@meterup/atto';
import { colors, notify, styled } from '@meterup/common';
import { api } from '@meterup/proto';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { difference } from 'lodash-es';
import React, { useState } from 'react';

import {
  createRuleForCompany,
  getRulesForCompany,
  updateRuleForCompany,
} from '../../../../../../api/api';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { processDomainList } from '../../../../../../utils/content_filters';

const FormContainer = styled('div', {
  padding: '$8 $12',
  backgroundColor: colors.gray50,
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.gray900,
    strokeAll: colors.strokeNeutralDark,
  },
});

const Form = styled('div', {
  vStack: '$8',
  alignItems: 'stretch',
  width: 'full',
  padding: '$8 $8',
  borderRadius: '$8',
  strokeAll: colors.strokeNeutralLight,
  backgroundColor: colors.bgApplicationLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    strokeAll: colors.strokeNeutralDark,
  },
});

const FormFooter = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '$2',
});

const FormFooterText = styled(Small, {
  color: colors.gray600,
});

const AlertContainer = styled('div', {
  marginTop: '$8',
});

export default function DNSSecurityAddDomainForm({ precedence }: { precedence: number }) {
  const [domainsToAdd, setDomainsToAdd] = useState('');
  const companyName = useCurrentCompany();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState('');

  const rules =
    useQuery(['dns_security', companyName, 'rules'], async () => getRulesForCompany(companyName), {
      suspense: true,
    }).data ?? [];
  const existingRule = rules.find((r) => r.precedence === precedence);

  const { mutate: updateRuleMutation, isLoading: updating } = useMutation(
    (rule: api.ContentFilterRule) =>
      updateRuleForCompany(companyName, rule.id, {
        description: rule.description,
        action: rule.action,
        precedence: rule.precedence,
        domains: processDomainList(rule.domains, domainsToAdd),
      }),
    {
      onSuccess: async () => {
        notify('Domain(s) added successfully', { variant: 'positive' });
        setDomainsToAdd('');
        await queryClient.invalidateQueries(['dns_security', companyName, 'rules']);
      },
      onError: () => {
        notify('Failed to add domain(s)', { variant: 'negative' });
      },
    },
  );

  const { mutate: createRuleMutation, isLoading: creating } = useMutation(
    () =>
      createRuleForCompany(companyName, {
        description: '',
        action:
          precedence === 0
            ? api.ContentFilterAction.CONTENTFILTER_ACTION_ALLOW
            : api.ContentFilterAction.CONTENTFILTER_ACTION_BLOCK,
        precedence,
        domains: processDomainList([], domainsToAdd),
      }),
    {
      onSuccess: async () => {
        notify('Domain(s) added successfully', { variant: 'positive' });
        setDomainsToAdd('');
        await queryClient.invalidateQueries(['dns_security', companyName, 'rules']);
      },
      onError: () => {
        notify('Failed to add domain(s)', { variant: 'negative' });
      },
    },
  );

  const saveRule = () => {
    if (existingRule) {
      if (difference(processDomainList([], domainsToAdd), existingRule.domains).length === 0) {
        setErrorMessage('You must provide at least one new domain in order to add a rule.');
        setDomainsToAdd('');
      } else {
        updateRuleMutation(existingRule);
      }
    } else {
      createRuleMutation();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') saveRule();
  };

  const handleChange = (val: string) => {
    setErrorMessage('');
    setDomainsToAdd(val);
  };

  return (
    <FormContainer>
      <Form>
        <TextInput
          placeholder="Add a domain..."
          disabled={creating || updating}
          value={domainsToAdd}
          onKeyUp={handleKeyUp}
          onChange={handleChange}
          aria-label="Domain to add"
        />

        <FormFooter>
          <FormFooterText>Separate multiple domains with a comma (,).</FormFooterText>
          <Button
            size="small"
            variant="secondary"
            onClick={() => saveRule()}
            loading={creating || updating}
          >
            Add ⏎
          </Button>
        </FormFooter>
      </Form>

      {errorMessage && (
        <AlertContainer>
          <Alert variant="negative" copy={errorMessage} icon="warning" type="inline" />
        </AlertContainer>
      )}
    </FormContainer>
  );
}

import SecurityApplianceEmptyState from '../../../../../components/Hardware/SecurityAppliance/SecurityApplianceEmptyState';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/security-appliances',
  layout: 'VirtualNetworkLayout',
});

export default function SecurityApplianceEmptyStatePage() {
  return <SecurityApplianceEmptyState />;
}

import type { OnChangeFn, RowSelectionState } from '@tanstack/table-core';
import { useCallback, useMemo, useState } from 'react';

export const useBatchEdit = ({
  initialState = [],
  onChange,
}: {
  initialState?: string[];
  onChange: (selectedRows: RowSelectionState) => void;
}) => {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>(
    Object.fromEntries(initialState.map((id) => [id, true])),
  );

  const onRowMultiSelectionChange = useCallback<OnChangeFn<RowSelectionState>>(
    (newSelectedRows) => {
      let newSelection: RowSelectionState;

      if (typeof newSelectedRows === 'function') {
        newSelection = newSelectedRows(selectedRows);
      } else {
        newSelection = newSelectedRows;
      }

      // don't call onChange if selection hasn't changed
      if (JSON.stringify(newSelection) === JSON.stringify(selectedRows)) {
        return;
      }

      setSelectedRows(newSelection);
      onChange(newSelection);
    },
    [selectedRows, onChange],
  );

  const retObj = useMemo(
    () => ({ multiSelectedRows: selectedRows, onRowMultiSelectionChange }),
    [selectedRows, onRowMultiSelectionChange],
  );

  return retObj;
};

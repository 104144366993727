import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import AccessPointEditDrawer from '../../../../../components/Hardware/AccessPoints/AccessPointEditDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/:id/edit',
  title: 'Edit access point - Access points - Hardware',
});

export default function AccessPointEditDrawerPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointEditDrawerPage);
  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
            nosFlags[NosFeature.WOS2],
        )
      }
      should404OnAccessDenied
    >
      <AccessPointEditDrawer uuid={drawerParams?.id!} />
    </IsPermitted>
  );
}

import { Badge, darkThemeSelector, HStack, Icon, Small, space } from '@meterup/atto';
import { selectors } from '@meterup/atto/src/controls/shared/styles';
import {
  bitsPerSecond,
  formatDataRateBits,
  shadows,
  Tooltip as MeterTooltip,
} from '@meterup/common';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { buildChartTheme, DataContext } from '@visx/xychart';
import * as d3 from 'd3';
import { flatMap } from 'lodash-es';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useId } from 'react-aria';

import type { SwitchMetricsQueryQuery, SwitchPortMetricsValue } from '../gql/graphql';
import { graphql } from '../gql';
import { ThemeContext } from '../providers/ThemeProvider';
import { colors, fonts, fontWeights, styled } from '../stitches';

/** Graphql types */

export type SwitchPortMetrics = SwitchMetricsQueryQuery['switchThroughputPerPort'];

export type MetricsType = { id: string };

export type DataPointWithDate = Omit<SwitchPortMetricsValue, 'timestamp'> & { timestamp: Date };

type DataByPort = Record<string, DataPointWithDate[]>;

type SeriesData = {
  RX: DataPointWithDate[];
  TX: DataPointWithDate[];
};

export type PortSeries = {
  data: SeriesData;
  curve?: d3.CurveFactory;
  series_type: string;
  series_id: string;
  series_name: string;
};

export const SwitchMetricsQuery = graphql(`
  query SwitchMetricsQuery(
    $input: MetricsSerialNumberDirectionInput!
    $filter: MetricsFilterInput!
  ) {
    switchThroughputPerPort(input: $input, filter: $filter) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        ... on SwitchPortMetricsValue {
          port
          direction
        }
      }
    }
    switchDiscardRatesPerPort(input: $input, filter: $filter) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        ... on SwitchPortMetricsValue {
          port
          direction
        }
      }
    }
    switchErrorRatesPerPort(input: $input, filter: $filter) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        ... on SwitchPortMetricsValue {
          port
          direction
        }
      }
    }
  }
`);

/** Chart components */

export function CheckeredBackground() {
  const id = useId();
  const {
    width = 0,
    height = 0,
    margin = { left: 0, right: 0, top: 0, bottom: 0 },
  } = useContext(DataContext);

  const backgroundWidth = width > 0 ? width - margin.left - margin.right : 0;

  return (
    <>
      <pattern id={id} x="0" y="0" width="8" height="8" patternUnits="userSpaceOnUse">
        <rect x="0" width="4" height="4" y="0" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="0" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="4" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="0" width="4" height="4" y="4" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
      </pattern>

      <rect
        x={margin.left}
        y={margin.top}
        width={backgroundWidth}
        height={height - margin.top - margin.bottom}
        fill={`url(#${id})`}
      />

      <line
        x1={margin.left}
        x2={width - margin.right}
        y1={height - margin.bottom}
        y2={height - margin.bottom}
        stroke={colors.gray100.toString()}
      />
    </>
  );
}

interface MetricToolTipProps {
  content: React.ReactNode;
}

export function MetricTooltip({ content }: MetricToolTipProps) {
  const StyledIcon = styled(Icon, {
    marginTop: '$2',
  });

  return (
    <HStack spacing={space(6)}>
      <StyledIcon icon="information" size={12} />
      {content}
    </HStack>
  );
}

export function StatusBadge({ series }: { series: PortSeries[] }) {
  const seriesData = series.map((data) => data.data);
  const allValues = flatMap(seriesData, (obj) => [...obj.RX, ...obj.TX]);

  if (allValues.length === 0) {
    return (
      <MeterTooltip
        side="right"
        sideOffset={6}
        content="An unexpected error occurred. Please contact support for help resolving this."
      >
        <Badge arrangement="leading-icon" ends="pill" icon="minus" size="small">
          No data
        </Badge>
      </MeterTooltip>
    );
  }

  return null;
}

const SwitchChartLegendColor = styled('div', {
  display: 'flex',
  width: '10px',
  height: '10px',
  borderRadius: '$2',
});

const SwitchChartLegendLabel = styled(Small, LegendLabel, {
  variants: {
    active: {
      true: {
        color: colors.headingBrandLight,
        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
      false: {},
    },
  },
});

const SwitchChartLegend = styled(LegendItem, {
  hStack: '$4',
  padding: '$2 $4',
  borderRadius: '$6',
  strokeAll: colors.transparent,
  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },
  [selectors.hover]: {
    cursor: 'pointer',
  },
  variants: {
    active: {
      true: {
        backgroundColor: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,
        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },
      },
    },
  },
});

const SwitchChartLegends = styled('div', {
  hStack: '$4',
  flexWrap: 'wrap',
  paddingTop: '$12',
});

export function ChartLegend({
  handleSelectPort,
  series,
  chartColors,
  selectedPortFilter,
}: {
  handleSelectPort: (port: string) => void;

  selectedPortFilter: string;
  series: PortSeries[];
  chartColors: string[];
}) {
  const legendDomain = series.map((s) => s.series_name);

  const ordinalColorScale = scaleOrdinal({
    domain: legendDomain,
    range: chartColors,
  });

  return (
    <LegendOrdinal direction="row" scale={ordinalColorScale}>
      {(labels) => (
        <SwitchChartLegends>
          {labels.map((label) => (
            <SwitchChartLegend
              active={selectedPortFilter === label.datum}
              key={label.text}
              tabIndex={0}
              onClick={() => {
                handleSelectPort(label.datum);
              }}
            >
              <SwitchChartLegendColor style={{ backgroundColor: label.value as string }} />
              <SwitchChartLegendLabel weight="bold">{label.text}</SwitchChartLegendLabel>
            </SwitchChartLegend>
          ))}
        </SwitchChartLegends>
      )}
    </LegendOrdinal>
  );
}

/** Chart rendering utils */
export const lightColors = [
  '#DC143C',
  '#4169E1',
  '#228B22',
  '#6A5ACD',
  '#FFA500',
  '#1E90FF',
  '#B22222',
  '#9ACD32',
  '#DB7093',
  '#00008B',
  '#D2691E',
  '#9932CC',
  '#8B0000',
  '#6B8E23',
  '#191970',
  '#800000',
  '#4B0082',
  '#FFD700',
  '#C71585',
  '#800080',
  // dupicates below
  '#DC143C',
  '#4169E1',
  '#228B22',
  '#6A5ACD',
  '#FFA500',
  '#1E90FF',
  '#B22222',
  '#9ACD32',
  '#DB7093',
  '#00008B',
  '#D2691E',
  '#9932CC',
  '#8B0000',
  '#6B8E23',
  '#191970',
  '#800000',
  '#4B0082',
  '#FFD700',
  '#C71585',
  '#800080',
  '#DC143C',
  '#4169E1',
  '#228B22',
  '#6A5ACD',
  '#FFA500',
  '#1E90FF',
  '#B22222',
  '#9ACD32',
  '#DB7093',
  '#00008B',
  '#D2691E',
  '#9932CC',
  '#8B0000',
  '#6B8E23',
  '#191970',
  '#800000',
  '#4B0082',
  '#FFD700',
  '#C71585',
  '#800080',
];

export const darkColors = [
  '#FFA07A',
  '#B0E0E6',
  '#98FB98',
  '#DDA0DD',
  '#CD5C5C',
  '#40E0D0',
  '#F08080',
  '#32CD32',
  '#FFC0CB',
  '#E0FFFF',
  '#FFE4C4',
  '#D8BFD8',
  '#E9967A',
  '#7CFC00',
  '#7FFFD4',
  '#BC8F8F',
  '#EE82EE',
  '#FFFACD',
  '#FFB6C1',
  '#DA70D6',
  // dupicates below
  '#FFA07A',
  '#B0E0E6',
  '#98FB98',
  '#DDA0DD',
  '#CD5C5C',
  '#40E0D0',
  '#F08080',
  '#32CD32',
  '#FFC0CB',
  '#E0FFFF',
  '#FFE4C4',
  '#D8BFD8',
  '#E9967A',
  '#7CFC00',
  '#7FFFD4',
  '#BC8F8F',
  '#EE82EE',
  '#FFFACD',
  '#FFB6C1',
  '#DA70D6',
  '#FFA07A',
  '#B0E0E6',
  '#98FB98',
  '#DDA0DD',
  '#CD5C5C',
  '#40E0D0',
  '#F08080',
  '#32CD32',
  '#FFC0CB',
  '#E0FFFF',
  '#FFE4C4',
  '#D8BFD8',
  '#E9967A',
  '#7CFC00',
  '#7FFFD4',
  '#BC8F8F',
  '#EE82EE',
  '#FFFACD',
  '#FFB6C1',
  '#DA70D6',
];

export const useChartColors = () => {
  const { theme: appTheme } = useContext(ThemeContext);
  const chartColors = appTheme === 'light' ? lightColors : darkColors;
  return chartColors;
};

export const buildChartThemeImpl = (chartColors: string[]) =>
  buildChartTheme({
    svgLabelSmall: {
      fontFamily: fonts.sans.toString(),
      fontWeight: fontWeights.regular.toString(),
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: 0,
      fill: colors.gray500.toString(),
    },
    xAxisLineStyles: {
      stroke: colors.gray300.toString(),
    },
    xTickLineStyles: {
      stroke: colors.gray300.toString(),
    },
    yTickLineStyles: {
      stroke: colors.gray300.toString(),
    },
    colors: chartColors,
    gridColor: colors.gray100.toString(),
    backgroundColor: colors.white.toString(),
    gridColorDark: colors.gray700.toString(),
    tickLength: 4,
  });

// We'll treat any tick as `diffNow` within this range to be considered "now".
export const DELTA_MINUTES_FOR_NOW = 10;

export const dateFormatter = (date: Date, timePeriod: string, format?: string, allowNow = true) => {
  const d = DateTime.fromJSDate(date);

  if (allowNow && Math.abs(d.diffNow('minutes').minutes) < DELTA_MINUTES_FOR_NOW) {
    return '• Now';
  }

  if (format) {
    return d.toFormat(format);
  }

  if (['1h', '6h', '24h'].includes(timePeriod)) {
    return d.toFormat('t');
  }

  return d.toFormat('LLL d');
};

/** Chart data utils */

export const dataRateValueFormatter = (value: number) => formatDataRateBits(value, bitsPerSecond);
export const packetValueFormatter = (value: number) => `${d3.format(`,.2~f`)(value)} pps`;

export const dataToDataByPort = (dataPoints: SwitchPortMetricsValue[]) => {
  const dataByPort: DataByPort = {};
  dataPoints.forEach((dataPoint) => {
    const value = {
      timestamp: new Date(dataPoint.timestamp),
      value: dataPoint.value,
      direction: dataPoint.direction,
      port: dataPoint.port,
    };
    if (!Object.hasOwn(dataByPort, dataPoint.port)) {
      dataByPort[dataPoint.port] = [value];
    } else {
      dataByPort[dataPoint.port].push(value);
    }
  });
  return dataByPort;
};

export const dataByPortToSeries = (dataByPort: DataByPort) => {
  const series = Object.entries(dataByPort).map(([port, data]) => {
    const seriesData: SeriesData = {
      RX: [],
      TX: [],
    };

    data.forEach((d) => {
      if (d.direction === 'TX') {
        seriesData.TX.push(d);
      } else {
        seriesData.RX.push(d);
      }
    });

    const portSeries: PortSeries = {
      curve: d3.curveCatmullRom,
      data: seriesData,
      series_type: 'line',
      series_id: `port${port}`,
      series_name: `Port ${port}`,
    };

    return portSeries;
  });
  return series;
};

export const dataToSeries = (dataPoints: SwitchPortMetricsValue[]) => {
  const dataByPort = dataToDataByPort(dataPoints);
  const series = dataByPortToSeries(dataByPort);
  return series;
};

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import AutoVPN from '../../../components/autoVPN/AutoVPN';
import { AutoVPNGroupTab } from '../../../components/autoVPN/utils';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/auto-vpn/groups/:groupUUID/:activeTab',
  layout: 'AutoVPNLayout',
  title: 'Group - Auto VPN',
});

export default function AutoVPNGroupPage() {
  const params = Nav.useRegionParams('root', paths.pages.AutoVPNGroupPage);

  return (
    <IsPermitted
      should404OnAccessDenied
      allowOperatorAlways={false}
      isPermitted={({ isOperator, permissions, companyNosFlags, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermAutoVpnRead) &&
        companyNosFlags[NosFeature.SITE_TO_SITE_VPN] &&
        (isOperator || !!ldFlags['site-to-site-vpn']) &&
        !!params?.groupUUID &&
        Object.values(AutoVPNGroupTab).includes(params.activeTab as AutoVPNGroupTab)
      }
    >
      <AutoVPN groupUUID={params?.groupUUID!} activeTab={params?.activeTab as AutoVPNGroupTab} />
    </IsPermitted>
  );
}

import { Layout, LayoutDock, LayoutPage } from '@meterup/atto';
import { expectDefinedOrThrow } from '@meterup/common';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { FatalErrorFallback } from '../../components/ErrorFallback/ErrorFallback';
import { paths } from '../../constants';
import { UnauthenticatedError } from '../../errors';
import { useNetworkUUIDFromPathOrNull } from '../../hooks/useNetworkFromPath';
import { useNetworkByUUID } from '../../hooks/useNetworksForCompany';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/network/:networkUUID',
});

function NetworkNotFoundError() {
  const error = new UnauthenticatedError('Network not found');

  return (
    <Layout>
      <LayoutDock />
      <LayoutPage>
        <FatalErrorFallback
          error={error}
          componentStack={null}
          eventId=""
          resetError={() => null}
        />
      </LayoutPage>
    </Layout>
  );
}

export default function NetworkRootPage() {
  const networkUUID = useNetworkUUIDFromPathOrNull();
  expectDefinedOrThrow(networkUUID, new UnauthenticatedError('Network not found'));
  const network = useNetworkByUUID(networkUUID!);
  const [params] = useSearchParams();
  if (!network || !network.company) {
    return <NetworkNotFoundError />;
  }
  const companySlug = network.company?.slug;
  const networkSlug = network.slug;

  return (
    <Navigate
      to={{
        pathname: makeLink(paths.pages.IndividualNetworkRootPage, {
          companyName: companySlug!,
          networkSlug,
        }),
        search: params.toString(),
      }}
      replace
    />
  );
}

import { PrimaryField, TextInput } from '@meterup/atto';

import { FieldProvider } from '../Form/FieldProvider';

type EditUserFormProps = {
  emailIsEditable: boolean;
};

export default function EditUserForm({ emailIsEditable }: EditUserFormProps) {
  return (
    <>
      <FieldProvider name="email">
        <PrimaryField element={<TextInput disabled={!emailIsEditable} />} label="Email" />
      </FieldProvider>
      <FieldProvider name="firstName">
        <PrimaryField element={<TextInput />} label="First name" />
      </FieldProvider>
      <FieldProvider name="lastName">
        <PrimaryField element={<TextInput />} label="Last name" />
      </FieldProvider>
    </>
  );
}

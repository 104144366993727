import {
  Alert,
  Button,
  FieldContainer,
  LoadingIcon,
  PrimaryFieldComposite,
  SecondaryFieldComposite,
  Section,
  SectionContent,
  SectionHeader,
  Select,
  SelectItem,
  Small,
  space,
  styled,
  TextInput,
  VStack,
} from '@meterup/atto';
import { getGraphQLError, useGraphQLMutation } from '@meterup/graphql';
import { useCallback, useEffect, useState } from 'react';

import type { RpcPingResponse } from '../gql/graphql';
import { graphql } from '../gql';
import { useNetworkInterfaces } from '../hooks/useNetworkInterfaces';
import { useCurrentControllerData } from '../providers/CurrentControllerProvider';

type PingTestAlertProps = {
  successRate: number;
};

const RPCPingMutation = graphql(`
  mutation RPCPingMutation($input: RPCPingInput!) {
    rpcPing(input: $input) {
      successRate
      roundTripTimeMs
    }
  }
`);

function PingTestAlert({ successRate }: PingTestAlertProps) {
  if (successRate === 1) {
    return (
      <Alert
        type="inline"
        relation="stacked"
        variant="positive"
        icon="ping"
        heading="The ping test was successful!"
      />
    );
  }
  if (successRate > 0 && successRate < 1) {
    return (
      <Alert
        type="inline"
        relation="stacked"
        variant="neutral"
        icon="attention"
        heading="Not all pings succeeded. Please check your connection."
      />
    );
  }

  return (
    <Alert
      type="inline"
      relation="stacked"
      variant="negative"
      icon="warning"
      heading="Ping test failed. Please check your connection."
    />
  );
}

function StyledLoadingIcon() {
  return <LoadingIcon color={{ light: 'gray500', dark: 'gray300' }} size={space(14)} />;
}

const PingTestEnd = styled('div', {
  '@maxSm': {
    width: '100%',
  },
  '@sm': {
    flex: 1,
  },
});

const PingTestCenter = styled('div', {
  '@maxSm': {
    width: '100%',
  },
  '@sm': {
    flex: 3,
  },
});

const PingTestFields = styled('div', {
  '@maxSm': {
    vStack: '$6',
    alignItems: 'stretch',
  },
  '@sm': {
    hStack: '$8',
  },
});

const DEFAULT_HOST = '8.8.8.8';

function isValidHost(host: string): boolean {
  return Boolean(
    // IPv4 regex
    host.match(
      /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    ),
  );
}

type PingTestProps = {
  internal?: boolean;
  hostIP?: string; // Specify a host to run a ping test immediately to.
};

export default function PingTest({ internal = false, hostIP }: PingTestProps) {
  const { mutate, isLoading, isError } = useGraphQLMutation(RPCPingMutation);
  const controller = useCurrentControllerData();
  const interfaces = useNetworkInterfaces(controller, { enabled: !hostIP });

  const [pingTestResult, setPingTestResult] = useState<RpcPingResponse | undefined>();
  const [isHostInputValid, setIsHostInputValid] = useState(true);
  const [host, setHost] = useState(DEFAULT_HOST);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [networkInterface, setNetworkInterface] = useState(interfaces[0]);

  // LAN ping
  const handleRunClientPing = useCallback(() => {
    if (!hostIP) {
      return;
    }
    setPingTestResult(undefined);
    mutate(
      { input: { serialNumber: controller.name, host: hostIP } },
      {
        onSuccess: (data) => {
          setPingTestResult(data.rpcPing);
        },
        onError() {
          setErrorText('Error issuing ping test');
        },
      },
    );
  }, [hostIP, mutate, controller.name]);

  // WAN ping
  const handleRunPing = useCallback(() => {
    setPingTestResult(undefined);
    if (isValidHost(host)) {
      setIsHostInputValid(true);
      mutate(
        { input: { serialNumber: controller.name, host, wanInterface: networkInterface } },
        {
          onSuccess: (data) => {
            setPingTestResult(data.rpcPing);
          },
          onError(error) {
            const gqlError = getGraphQLError(error);
            setErrorText(gqlError?.message ?? 'Unknown error');
          },
        },
      );
    } else {
      setIsHostInputValid(false);
    }
  }, [controller.name, host, networkInterface, mutate]);

  useEffect(() => {
    if (hostIP) {
      handleRunClientPing();
    }
  }, [hostIP, handleRunClientPing]);

  return (
    <Section internal={internal} relation="stacked">
      <SectionHeader icon="ping" heading="Ping test" />
      <SectionContent gutter="all">
        <VStack spacing={space(8)}>
          <Small>
            {hostIP
              ? `Determine if the Meter security appliance can reach a local client and measure the round-trip request
            time.`
              : `Determine if the Meter security appliance can reach a remote host and measure the round-trip request
            time. The host must be an IP address.`}
          </Small>
          <FieldContainer>
            <PrimaryFieldComposite
              label={!hostIP && 'Run a test'}
              fields={
                <VStack spacing={space(8)}>
                  <PingTestFields>
                    {hostIP ? (
                      <Button
                        icon="play"
                        variant="secondary"
                        onClick={handleRunClientPing}
                        disabled={isLoading}
                        width="100%"
                      >
                        Run again
                      </Button>
                    ) : (
                      <>
                        {' '}
                        <PingTestEnd>
                          <Select
                            value={networkInterface}
                            onValueChange={setNetworkInterface}
                            width="100%"
                          >
                            {interfaces.map((interfaceName) => (
                              <SelectItem key={interfaceName}>{interfaceName}</SelectItem>
                            ))}
                          </Select>
                        </PingTestEnd>
                        <PingTestCenter>
                          <TextInput
                            name="host"
                            value={host}
                            onChange={setHost}
                            placeholder="8.8.8.8"
                            aria-label="Host"
                          />
                        </PingTestCenter>
                        <PingTestEnd>
                          <Button
                            icon="play"
                            arrangement="hidden-label"
                            variant="secondary"
                            onClick={handleRunPing}
                            disabled={!networkInterface || isLoading}
                            width="100%"
                          >
                            Run
                          </Button>
                        </PingTestEnd>
                      </>
                    )}
                  </PingTestFields>
                  {!isHostInputValid && (
                    <Alert
                      relation="standalone"
                      type="inline"
                      variant="negative"
                      icon="warning"
                      copy="Host must be a valid IP address."
                    />
                  )}
                </VStack>
              }
            />
            {!isError && (isLoading || pingTestResult) && (
              <SecondaryFieldComposite
                label="Success rate"
                fields={
                  <>
                    {pingTestResult && (
                      <Small family="monospace">
                        {pingTestResult.successRate >= 0
                          ? `${pingTestResult.successRate * 100}%`
                          : 'n/a'}
                      </Small>
                    )}
                    {isLoading && <StyledLoadingIcon />}
                  </>
                }
              />
            )}
            {!isError && (isLoading || pingTestResult) && (
              <SecondaryFieldComposite
                label="Round-trip average time"
                fields={
                  <>
                    {pingTestResult && (
                      <Small family="monospace">
                        {pingTestResult.roundTripTimeMs
                          ? `${pingTestResult.roundTripTimeMs}ms`
                          : 'n/a'}
                      </Small>
                    )}
                    {isLoading && <StyledLoadingIcon />}
                  </>
                }
              />
            )}
            {isError && (
              <Alert
                type="inline"
                relation="stacked"
                variant="negative"
                icon="warning"
                heading={errorText}
              />
            )}
            {pingTestResult && <PingTestAlert successRate={pingTestResult.successRate} />}
          </FieldContainer>
        </VStack>
      </SectionContent>
    </Section>
  );
}

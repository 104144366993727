import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Button, EmptyState, Pane, PaneContent } from '@meterup/atto';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate } from 'react-router';

import { listEnabledContentFiltersForCompany } from '../../../../../../api/api';
import { ReactRouterLink } from '../../../../../../components/ReactRouterLink';
import { paths } from '../../../../../../constants';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../../providers/CurrentControllerProvider';
import { useIdentity } from '../../../../../../providers/IdentityDataProvider';
import { makeDrawerLink, makeLink } from '../../../../../../utils/main_and_drawer_navigation';
import { zendeskFeedbackURL } from '../../../../../../utils/zendesk';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/firewall/dns-security',
});

function DNSSecurityDisabledState({ isEligible }: { isEligible: boolean }) {
  const identity = useIdentity();
  const companyName = useCurrentCompany();
  const controller = useCurrentControllerData();
  const controllerName = controller.name;

  const enableFilteringDrawerLink = makeDrawerLink(
    window.location,
    paths.drawers.LegacyDNSSecurityEnablePage,
    {
      companyName,
      controllerName,
    },
  );

  if (!isEligible) {
    return (
      <EmptyState
        icon="dns-security"
        heading="DNS security is not enabled"
        subheading="There was an error enabling DNS Security for your network. Please contact support for assistance."
        action={
          <Button
            as="a"
            href={zendeskFeedbackURL(identity.username, 'Enabling DNS security on my network')}
            target="_blank"
            arrangement="leading-icon"
            icon="question"
          >
            Contact support
          </Button>
        }
      />
    );
  }

  return (
    <EmptyState
      icon="dns-security"
      heading="DNS security not enabled"
      action={
        <Button
          as={ReactRouterLink}
          to={enableFilteringDrawerLink}
          variant="primary"
          arrangement="leading-icon"
        >
          Enable DNS security
        </Button>
      }
    />
  );
}

export default function LegacyDNSSecurityPage() {
  const companyName = useCurrentCompany();
  const controller = useCurrentControllerData();
  const controllerName = controller.name;

  const status = useQuery(
    ['dns_security', companyName, 'status'],
    async () => listEnabledContentFiltersForCompany(companyName),
    {
      suspense: true,
    },
  ).data;

  const isEnabled = status?.enabled.some((d) => d.controller_name === controllerName) ?? false;
  const isEligible =
    status?.eligible_controllers.some(
      (eligibleControllerName) => eligibleControllerName === controllerName,
    ) ?? false;

  if (isEnabled) {
    return (
      <Navigate
        to={makeLink(paths.pages.LegacyDNSSecurityDomainsPage, {
          companyName,
          controllerName,
        })}
        replace
      />
    );
  }

  return (
    <Pane>
      <PaneContent>
        <DNSSecurityDisabledState isEligible={isEligible} />
      </PaneContent>
    </Pane>
  );
}

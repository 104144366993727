import React from 'react';

import { SummaryListKey, SummaryListRow, SummaryListValue } from '@meterup/atto';

type NetworkSummaryListItemProps = {
  children?: React.ReactNode;
  label: string;
};

export default function NetworkSummaryListItem({ children, label }: NetworkSummaryListItemProps) {
  return (
    <SummaryListRow>
      <SummaryListKey>{label}</SummaryListKey>
      <SummaryListValue>{children}</SummaryListValue>
    </SummaryListRow>
  );
}

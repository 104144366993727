import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  Body,
  Box,
  Button,
  DeprecatedCloseDrawerButton,
  DrawerContent,
  DrawerHeader,
  HStack,
  Small,
  space,
  styled,
  VStack,
} from '@meterup/atto';
import { colors, darkThemeSelector } from '@meterup/atto/src/stitches.config';
import { QuoteRequest } from '@meterup/connect-api';
import useCreateQuote from '@meterup/connect-api/src/hooks/useCreateQuote';
import { CloseDrawerLink } from '@meterup/connect-ui';
import { useCustomStyles } from '@meterup/connect-ui/src/components/Select/hooks/useCustomStyles';
import { NumberedItemContainer } from '@meterup/connect-ui/src/components/Select/NumberedItemContainer';
import { NumberedItemLabel } from '@meterup/connect-ui/src/components/Select/NumberedItemLabel';
import { NumberedItemOuter } from '@meterup/connect-ui/src/components/Select/NumberedItemOuter';
import { NumberedListInput } from '@meterup/connect-ui/src/components/Select/NumberedListInput';
import { NumberedListValueContainer } from '@meterup/connect-ui/src/components/Select/NumberedListValueContainer';
import { notify } from '@meterup/connect-ui/src/components/stolenFromFrontends/notify';
import useFetchLocationPredictions, {
  FetchLocationItem,
} from '@meterup/connect-ui/src/hooks/useFetchLocationPredictions';
import { useIdentity } from '@meterup/connect-ui/src/hooks/useIdentity';
import usePredictionFormatter from '@meterup/connect-ui/src/hooks/usePredictionFormatter';
import { logError } from '@meterup/connect-ui/src/Log.utils';
import formatLocationParts from '@meterup/connect-ui/src/utils/formatLocationParts';
import AsyncSelect from 'react-select/async';
import Select from 'react-select/base';
import { GroupBase, MultiValue } from 'react-select/dist/declarations/src/types';

import { TopDivider } from '../../components/Lists';
import useCompanySlug from '../../hooks/useCompanySlug';

const SurfaceHeader = styled(VStack, {
  gap: '$2',
  paddingBottom: '$10',
  borderBottom: `1px solid ${colors.strokeNeutralLight}`,
  [darkThemeSelector]: {
    borderColor: colors.strokeNeutralDark,
  },
});

const SurfaceChild = styled(VStack, {
  '& > div': {
    paddingX: '$12',
  },
});

const SurfaceInner = styled(Box, {
  paddingY: '$10',
  background: colors.gray50,
  border: `1px solid ${colors.strokeNeutralLight}`,
  boxShadow: 'unset',

  [darkThemeSelector]: {
    background: colors.gray900,
    borderColor: colors.strokeNeutralDark,
  },

  '& > div': {
    boxShadow: 'unset',
  },
  '& textarea': {},
});

enum MutationResultType {
  Success = 'success',
  Failure = 'failure',
}

type MutationResult = {
  option: FetchLocationItem;
  result?: QuoteRequest;
  status: MutationResultType;
};

type AddLocationsDrawerProps = {
  closeButton?: React.ReactNode;
};

export default function AddLocationsDrawer({
  closeButton: closeButtonProp,
}: AddLocationsDrawerProps) {
  const identity = useIdentity();
  const multiSelectRef = useRef<Select<
    FetchLocationItem,
    true,
    GroupBase<FetchLocationItem>
  > | null>(null);
  const closeButton = useMemo(() => {
    if (closeButtonProp) {
      return closeButtonProp;
    }
    return (
      <CloseDrawerLink>
        <DeprecatedCloseDrawerButton />
      </CloseDrawerLink>
    );
  }, [closeButtonProp]);
  const [selectedOptions, setSelectedOptions] = useState<FetchLocationItem[]>([]);
  // @ts-ignore
  const customStyles = useCustomStyles<FetchLocationItem, true>({
    // @ts-ignore
    plain: true,
    // @ts-ignore
    controlPositioning: false,
  });
  const fetchPredictions = useFetchLocationPredictions();
  const optionSelected = useCallback((newValue: MultiValue<FetchLocationItem>) => {
    setSelectedOptions(newValue as FetchLocationItem[]);
  }, []);
  const [, setMutationResults] = useState<Record<MutationResultType, MutationResult[]>>();
  const formatPrediction = usePredictionFormatter();
  const companySlug = useCompanySlug();
  const createQuoteMutation = useCreateQuote();
  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      if (e) {
        e.preventDefault();
      }
      const mutations: Promise<MutationResult | undefined>[] = selectedOptions.map(
        async (selectedOption) => {
          const formatted = await formatPrediction(selectedOption);
          if (!formatted) {
            return undefined;
          }

          try {
            const result = await createQuoteMutation.mutateAsync({
              companyName: companySlug!,
              contactName: '',
              contactEmail: identity?.username ?? '',
              contactTelephone: '',
              requestedDownloadKbps: 0,
              requestedUploadKbps: 0,
              contractMinimumMonths: 0,
              notes: '',
              existingMonthlyFeeCents: 0,
              existingProviderSid: '',
              location: formatLocationParts(formatted),
            });

            return { status: MutationResultType.Success, result, option: selectedOption };
          } catch (err) {
            logError(err);
          }
          return { status: MutationResultType.Failure, option: selectedOption };
        },
      );
      const mutationsResults = (await Promise.all(mutations)).reduce(
        (prev, mutationResult) => {
          const { [MutationResultType.Success]: success, [MutationResultType.Failure]: failure } =
            prev;
          if (!mutationResult) {
            return prev;
          }
          return {
            [MutationResultType.Success]: success.concat(
              mutationResult.status === MutationResultType.Success ? [mutationResult] : [],
            ),
            [MutationResultType.Failure]: failure.concat(
              mutationResult.status === MutationResultType.Failure ? [mutationResult] : [],
            ),
          };
        },
        { [MutationResultType.Success]: [], [MutationResultType.Failure]: [] } as Record<
          MutationResultType,
          MutationResult[]
        >,
      );
      if (mutationsResults[MutationResultType.Failure].length > 0) {
        notify(`Failed to add ${mutationsResults[MutationResultType.Failure].length} locations`, {
          variant: 'negative',
        });
        return;
      }

      notify(`Added ${mutationsResults[MutationResultType.Success].length} locations`, {
        variant: 'positive',
      });

      setMutationResults(mutationsResults);
      setSelectedOptions([]);
      multiSelectRef.current?.clearValue();
    },
    [companySlug, createQuoteMutation, formatPrediction, identity?.username, selectedOptions],
  );
  const onKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLDivElement>) => {
      // debugger;
      if ((e.metaKey || e.ctrlKey) && e.code === 'Enter' && selectedOptions.length > 0) {
        // @ts-ignore
        await onSubmit();
        e.preventDefault();
      }

      return true;
    },
    [onSubmit, selectedOptions.length],
  );
  return (
    <form onSubmit={onSubmit}>
      <DrawerHeader heading="Bulk add" actions={closeButton} />
      <DrawerContent>
        <VStack spacing={space(12)}>
          <SurfaceInner radius={{ all: 8 }}>
            <SurfaceChild spacing={space(10)}>
              <SurfaceHeader>
                <Body>Type to add locations</Body>
                <Small>Get quotes and availability for each location.</Small>
              </SurfaceHeader>
              <div>
                <AsyncSelect
                  cacheOptions
                  isMulti
                  openMenuOnFocus
                  closeMenuOnScroll={false}
                  getOptionLabel={(o) => o?.description ?? ''}
                  getOptionValue={(o) => o?.description ?? ''}
                  loadOptions={fetchPredictions}
                  noOptionsMessage={() => null}
                  onChange={optionSelected}
                  onKeyDown={onKeyDown}
                  options={selectedOptions}
                  placeholder={false}
                  ref={multiSelectRef}
                  styles={customStyles}
                  components={{
                    Input: NumberedListInput,
                    MultiValue: NumberedItemOuter,
                    MultiValueLabel: NumberedItemLabel,
                    MultiValueContainer: NumberedItemContainer,
                    ValueContainer: NumberedListValueContainer,
                  }}
                  menuPortalTarget={document.body}
                  menuPlacement="bottom"
                  menuPosition="fixed"
                />
              </div>
            </SurfaceChild>
          </SurfaceInner>
          <TopDivider>&nbsp;</TopDivider>
          <HStack justify="end">
            <Button type="submit">Add locations</Button>
          </HStack>
        </VStack>
      </DrawerContent>
    </form>
  );
}

import { RoleName } from '../gql/graphql';

export const AllRoles = [
  RoleName.Operator,
  RoleName.CompanyGlobalAdmin,
  RoleName.CompanyNetworkAdmin,
  RoleName.CompanyStandardUser,
];

export function roleToName(role: RoleName | null) {
  switch (role) {
    case RoleName.CompanyGuest:
      return 'Guest';
    case RoleName.CompanyGlobalAdmin:
      return 'Company admin';
    case RoleName.Operator:
      return 'Operator';
    case RoleName.CompanyNetworkAdmin:
      return 'Network admin';
    case RoleName.CompanyStandardUser:
      return 'User';
  }

  return 'None';
}

export function isHigherRole(role: RoleName, than: RoleName) {
  if (role === RoleName.Operator) {
    return true;
  }
  if (role === RoleName.CompanyGlobalAdmin) {
    return than !== RoleName.Operator;
  }
  if (role === RoleName.CompanyNetworkAdmin) {
    return than !== RoleName.Operator && than !== RoleName.CompanyGlobalAdmin;
  }
  if (role === RoleName.CompanyStandardUser) {
    return than === RoleName.CompanyGuest;
  }
  return false;
}

export function highestRole(roles: RoleName[]) {
  let highest: RoleName | null = null;
  for (const role of roles) {
    if (isHigherRole(role, highest ?? RoleName.CompanyGuest)) {
      highest = role;
    }
  }
  return highest;
}

export function deletedAtToStatus(deletedAt?: string | null) {
  return deletedAt ? 'Deleted' : 'Active';
}

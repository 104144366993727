import { useMemo } from 'react';

import { CompanyContractResponse, InternetServicePlan } from '@meterup/connect-api';

import { isPrimary } from '../utils/InternetServiceProvider.utils';

function isValidContract(contract: CompanyContractResponse) {
  const { provider } = contract;
  const providerName = provider?.name;
  return providerName && providerName !== 'Provider';
}

type UsePrimarySecondaryProps = {
  contracts?: CompanyContractResponse[];
};

type UsePrimarySecondaryContractsValue = {
  primary?: CompanyContractResponse;
  secondary?: CompanyContractResponse;
};

type UsePrimarySecondaryISPsValue = {
  primary?: InternetServicePlan;
  secondary?: InternetServicePlan;
};

export function usePrimarySecondaryContracts({
  contracts,
}: UsePrimarySecondaryProps): UsePrimarySecondaryContractsValue {
  return useMemo<UsePrimarySecondaryContractsValue>(() => {
    if (!contracts) {
      return {};
    }

    let primaryContract: CompanyContractResponse | undefined;
    let secondaryContract: CompanyContractResponse | undefined;
    contracts.forEach((contract) => {
      if (!isValidContract(contract)) {
        return;
      }
      if (contract.internetServicePlan && isPrimary(contract.internetServicePlan)) {
        primaryContract = contract;
      } else {
        secondaryContract = contract;
      }
    });

    return {
      primary: primaryContract,
      secondary: secondaryContract,
    };
  }, [contracts]);
}

export default function usePrimarySecondaryISPs({
  contracts,
}: UsePrimarySecondaryProps): UsePrimarySecondaryISPsValue {
  const { primary, secondary } = usePrimarySecondaryContracts({ contracts });
  return useMemo(
    () => ({
      primary: primary?.internetServicePlan,
      secondary: secondary?.internetServicePlan,
    }),
    [primary, secondary],
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import EditRateLimitRuleDrawer, {
  rateLimitRuleQuery,
} from '../../../../../components/RateLimiting/EditRuleDrawer';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/rate-limiting/:ruleUUID',
  title: 'Edit rule - Rate limiting - Firewall',
});

export default function EditRateLimitRulePage() {
  const drawerParams = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditRateLimitRulePage),
  );

  const rule = checkDefinedOrThrow(
    useGraphQL(rateLimitRuleQuery, {
      uuid: drawerParams?.ruleUUID!,
    })?.data?.rateLimitRule,
    new ResourceNotFoundError('Firewall rule not found'),
  );

  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermRateLimitWrite) &&
            ldFlags['rate-limiting'] &&
            nosFlags[NosFeature.COS2] &&
            nosFlags[NosFeature.RATE_LIMITING],
        )
      }
      should404OnAccessDenied
    >
      <EditRateLimitRuleDrawer rule={rule} />
    </IsPermitted>
  );
}

import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { useCallback } from 'react';

import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany2 } from '../../../../../providers/CurrentCompanyProvider';
import DeleteDialog from '../../../../Dialogs/DeleteDialog';
import {
  deleteAlertReceiverMutation,
  useAlertReceiver,
  useInvalidateAlertReceivers,
} from '../utils';

export default function ReceiverActions({ uuid }: { uuid: string }) {
  const deleteDialog = useDialogState();
  const deleteReceiverMutation = useGraphQLMutation(deleteAlertReceiverMutation);
  const closeDrawer = useCloseDrawerCallback();
  const company = useCurrentCompany2();
  const invalidateReceivers = useInvalidateAlertReceivers({ companyUUID: company?.uuid });
  const receiver = useAlertReceiver({ uuid, companyUUID: company?.uuid });

  const handleDeleteReceiver = useCallback(
    () =>
      deleteReceiverMutation.mutate(
        { alertReceiverUUID: receiver.UUID },
        {
          onSuccess() {
            invalidateReceivers();
            closeDrawer();
            notify('Successfully deleted receiver', {
              variant: 'positive',
            });
          },
          onError() {
            notify('Failed to delete receiver', {
              variant: 'negative',
            });
          },
          onSettled: closeDrawer,
        },
      ),
    [deleteReceiverMutation, receiver.UUID, closeDrawer, invalidateReceivers],
  );

  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onSelect={deleteDialog.state.open} icon="trash-can">
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>

      <DeleteDialog
        state={deleteDialog.state}
        label={receiver.label}
        handleDelete={handleDeleteReceiver}
      />
    </>
  );
}

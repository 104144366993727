import { PermissionType } from '@meterup/common/src/gql/graphql';

import type { NetworksOverviewData } from '../../../components/Hub/utils/aggregateStatsForNetworks';
import { DetailPageLayout } from '../../../components/DetailPageLayout';
import { HubCards } from '../../../components/Hub/Cards/HubCards';
import { useFilterAndGroup } from '../../../components/Hub/filtering/useFilterAndGroup';
import HubHeader, { ValidHubTabs } from '../../../components/Hub/HubHeader';
import HubPagination from '../../../components/Hub/HubPagination';
import { useHubNetworksOverview } from '../../../components/Hub/useHubNetworksOverview';
import { useUptimeStats } from '../../../components/Hub/useUptimeStats';
import { usePagination } from '../../../components/Hub/utils/usePagination';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { NosFeature, useNosFeatureEnabledForCompany } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';

export const Meta = () => ({
  path: '/org/:companyName/hub/cards',
  title: 'Cards - Hub',
  layout: 'HubLayout',
});

export default function HubCardsPage() {
  const companyName = useCurrentCompany();
  const networksOverview = useHubNetworksOverview(companyName);
  const filter = useFilterAndGroup(networksOverview.networks);
  const pagination = usePagination(filter.data.rows.length);
  const uptimeStats = useUptimeStats(networksOverview);
  const companyHasConfig2COS = useNosFeatureEnabledForCompany(NosFeature.COS2, companyName);

  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        companyHasConfig2COS &&
        (permissions.hasPermission(PermissionType.PermCompanyGet) ||
          permissions.hasPermission(PermissionType.PermNetworkReadLimited))
      }
      should404OnAccessDenied
    >
      <DetailPageLayout
        header={<HubHeader activeTab={ValidHubTabs.Cards} />}
        background="checkered"
        main={
          <HubCards
            networks={filter.data.rows as NetworksOverviewData['networks']}
            pagination={pagination}
            uptimeStats={uptimeStats}
          />
        }
        footer={<HubPagination pagination={pagination} />}
      />
    </IsPermitted>
  );
}

import { checkDefinedOrThrow } from '@meterup/common';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import Hotspot20Edit from '../../../../../components/Wireless/Hotspot20s/Hotspot20Edit';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/hotspot2.0/:uuid/edit',
  title: 'Edit hotspot 2.0 - Hotspot 2.0s - Wireless',
});

export default function Hotspot20EditPage() {
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.Hotspot20EditPage),
  );

  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermCaptivePortalWrite) &&
            ldFlags['captive-portal'],
        )
      }
      path={Meta().path}
    >
      <Hotspot20Edit uuid={uuid} />
    </IsPermitted>
  );
}

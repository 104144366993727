import { Badge, darkThemeSelector, Small, styled } from '@meterup/atto';
import { colors } from '@meterup/atto/src/stitches.config';
import { useEffect, useMemo, useRef, useState } from 'react';
import useIsInViewport from 'use-is-in-viewport';

import type { UptimeForInterface } from '../useUptimeStats';
import { CanvasChart, poorQualityWaterMark } from './CanvasChart';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
  gap: 4,
});

const ChartContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  height: 20,
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  position: 'relative',
});

const ISPName = styled('div', {
  fontSize: 12,
  fontWeight: 500,
});

const TitleContainer = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: 4,
});

const TitleAvgUptimeContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: 4,
  color: colors.bodyNeutralLight,
  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const AvgUptime = styled('div', {
  fontSize: 14,
  fontWeight: 400,
});

const NoStatsNotice = styled(Small, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  color: colors.bodyNeutralLight,
  textAlign: 'center',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

export function calcAvgUptime(data: { timestamp: Date; value: number }[]) {
  let sum = 0.0;
  let belowWaterMarkOnce = false;
  data.forEach((item) => {
    sum += item.value;
    if (item.value < poorQualityWaterMark) {
      belowWaterMarkOnce = true;
    }
  });
  const fractionDigit = belowWaterMarkOnce ? 4 : 2;
  return ((sum * 100.0) / data.length).toFixed(fractionDigit);
}

function useElementWidth() {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    updateWidth(); // Set initial width
    window.addEventListener('resize', updateWidth); // Adjusts width on window resize

    return () => window.removeEventListener('resize', updateWidth); // Cleanup on unmount
  }, []);

  return [width, ref] as const;
}

function LocationUptimeGraph({
  data,
  label,
  isPrimary,
  hidePrimary = false,
  hideAverage = false,
}: {
  hideAverage?: boolean;
  hidePrimary: boolean;
  isPrimary: boolean;
  label?: string;
  data: { timestamp: Date; value: number }[];
}) {
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 0 });
  const [elWidth, elRef] = useElementWidth();

  return (
    <Container ref={targetRef}>
      <TitleAvgUptimeContainer>
        <TitleContainer>
          {!hidePrimary && <Badge size="small">{isPrimary ? 'Primary' : 'Backup'}</Badge>}
          {label && <ISPName>{label}</ISPName>}
        </TitleContainer>
        {!hideAverage && <AvgUptime>{calcAvgUptime(data)}%</AvgUptime>}
      </TitleAvgUptimeContainer>
      <ChartContainer ref={elRef}>
        {isInViewport && <CanvasChart stats={data} width={elWidth} />}
      </ChartContainer>
    </Container>
  );
}

export function LocationUptimeGraphs({ stats }: { stats?: UptimeForInterface[] }) {
  const statsToShow = useMemo(() => {
    if (!stats) return undefined;
    // only show the two WANs w/ the most data
    return stats
      .sort((a, b) => b.stats.length - a.stats.length)
      .filter((wan) => wan.stats.some((s) => s.value > 0))
      .slice(0, 2);
  }, [stats]);

  if (!statsToShow || statsToShow.length === 0) {
    return <NoStatsNotice>No uptime data available</NoStatsNotice>;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      {statsToShow.map((item) => (
        <LocationUptimeGraph
          key={item.phyInterfaceUUID}
          data={item.stats}
          label={item.label}
          isPrimary={item.label === 'wan0'}
          hidePrimary={false}
        />
      ))}
    </div>
  );
}

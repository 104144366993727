import { Tab } from '@meterup/atto';

import { ReactRouterLink } from '../../../../../../components/ReactRouterLink';
import { paths } from '../../../../../../constants';
import { useIsActiveMatcher } from '../../../../../../hooks/useIsActive';
import { useCurrentCompany } from '../../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../../providers/CurrentControllerProvider';
import { makeDrawerLink } from '../../../../../../utils/main_and_drawer_navigation';

export function VPNSummaryTabs({ sid }: { sid: string }) {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const isActiveTest = useIsActiveMatcher();
  return (
    <>
      <Tab
        icon="information"
        as={ReactRouterLink}
        to={makeDrawerLink(window.location, paths.drawers.VPNClientSummaryPage, {
          sid,
          controllerName,
          companyName,
        })}
        selected={isActiveTest({ path: paths.drawers.VPNClientSummaryPage, end: true })}
      >
        Details
      </Tab>
      <Tab
        icon="wrench"
        as={ReactRouterLink}
        to={makeDrawerLink(window.location, paths.drawers.VPNClientConfigSummary, {
          sid,
          controllerName,
          companyName,
        })}
        selected={isActiveTest({ path: paths.drawers.VPNClientConfigSummary, end: true })}
      >
        Config
      </Tab>
    </>
  );
}

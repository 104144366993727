import type { OverlayTriggerState } from '@meterup/atto';
import { Body, Button, Dialog, DialogContent, DialogFooter, DialogHeader } from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { paths } from '../../../constants';
import { networksForCompany, useNetworksForCompany } from '../../../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useVirtualDevices } from '../../../routes/pages/network/insights/logs/hooks/useVirtualDevices';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { ErrorAlert } from './EditNetwork';
import { type Network, deleteNetworkMutation, networkQuery } from './utils';

export function DeleteNetworkDialog({
  network,
  state,
}: {
  network: Network;
  state: OverlayTriggerState;
}) {
  const companySlug = useCurrentCompany();
  const companyNetworks = useNetworksForCompany(companySlug);
  const { virtualDevices } = useVirtualDevices();

  const deleteNetwork = useGraphQLMutation(deleteNetworkMutation);

  const canDeleteNetwork = virtualDevices.length === 0 && companyNetworks.length > 1;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { close } = state;

  const handleDelete = useCallback(() => {
    if (!network?.UUID || !canDeleteNetwork) return;

    deleteNetwork.mutate(
      { uuid: network.UUID },
      {
        onSuccess: () => {
          close();
          notify('Network deleted successfully.', {
            variant: 'positive',
          });

          const nextNetwork =
            companyNetworks.find((n) => n.isActive && n.UUID !== network.UUID) ??
            companyNetworks.find((n) => n.UUID !== network.UUID);

          if (nextNetwork) {
            navigate(
              makeLink(paths.pages.SettingsNetworkGeneralPage, {
                companyName: companySlug,
                networkSlug: nextNetwork.slug,
              }),
            );
          } else {
            // This shouldn't happen
            navigate(
              makeLink(paths.pages.SettingsOrgAuthenticationPage, {
                companyName: companySlug,
              }),
            );
          }

          queryClient.invalidateQueries(makeQueryKey(networkQuery, { uuid: network.UUID }));
          queryClient.invalidateQueries(makeQueryKey(networksForCompany, { companySlug }));
        },
        onError: (err) => {
          notify(`There was a problem deleting this network${getGraphQLErrorMessageOrEmpty(err)}`, {
            variant: 'negative',
          });
        },
      },
    );
  }, [
    close,
    companySlug,
    network?.UUID,
    canDeleteNetwork,
    deleteNetwork,
    queryClient,
    companyNetworks,
    navigate,
  ]);

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete network" />
      <DialogContent gutter="all">
        <Body>
          Are you sure you want to delete this network? This can be undone, but only by Engineering.
        </Body>

        {deleteNetwork.isError && deleteNetwork.error && (
          <ErrorAlert
            error={deleteNetwork.error}
            heading="There was a problem deleting this network"
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button type="button" variant="secondary" onClick={state.close}>
              Cancel
            </Button>
            <Button type="button" variant="destructive" onClick={handleDelete}>
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

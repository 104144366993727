import type { ReactNode } from 'react';
import { useGraphQL } from '@meterup/graphql';
import { createContext, useCallback, useContext, useMemo } from 'react';

import type { PermissionType } from '../gql/graphql';
import { graphql } from '../gql';
import { useNetworkUUID } from '../hooks/useNetworkFromPath';
import { useCurrentCompanyOrDefault } from './CurrentCompanyProvider';

export type PermissionsContextValue = {
  hasPermission: (permissionType: PermissionType) => boolean;
};

export const PermissionsContext = createContext<PermissionsContextValue>(null as any);

export function usePermissions() {
  return useContext(PermissionsContext);
}

type PermissionsProviderProps = {
  children: ReactNode;
};

const permissionsQuery = graphql(`
  query Permissions($input: PermissionsInput!) {
    permissions(input: $input) {
      networkUUID
      permissions
    }
  }
`);

export function PermissionsProvider({ children }: PermissionsProviderProps) {
  const networkUUID = useNetworkUUID();
  const companyName = useCurrentCompanyOrDefault();
  const permissionsData = useGraphQL(permissionsQuery, {
    input: { networkUUID, companySlug: companyName },
  })?.data;
  const permissionSet = useMemo(() => {
    const permissionSetForNetwork = permissionsData ? permissionsData?.permissions : null;
    return new Set(permissionSetForNetwork ? permissionSetForNetwork[0]?.permissions : []);
  }, [permissionsData]);
  const hasPermission = useCallback(
    (key: PermissionType) => permissionSet.has(key),
    [permissionSet],
  );
  const permissionsContextVal = useMemo(
    () => ({
      hasPermission,
    }),
    [hasPermission],
  );
  return (
    <PermissionsContext.Provider value={permissionsContextVal}>
      {children}
    </PermissionsContext.Provider>
  );
}

import { Box, Skeleton, space, VStack } from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense } from 'react';

import { PermissionType } from '../../../gql/graphql';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import ConnectionEvents from '../../Metrics/ConnectionEvents';
import { MetricBandFilter, MetricsDeviceType } from '../../Metrics/utils';
import { AccessPointQuery } from '../../Wireless/utils';

export default function AccessPointConnectionEvents({
  uuid,
  globalFilter,
}: {
  uuid: string;
  globalFilter: string | undefined;
}) {
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const accessPointData = useGraphQL(AccessPointQuery, {
    uuid,
    includeUptime,
  }).data;
  expectDefinedOrThrow(accessPointData, new ResourceNotFoundError('Access point not found'));
  expectDefinedOrThrow(
    accessPointData.virtualDevice,
    new ResourceNotFoundError('Virtual device not found'),
  );

  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const [currentBandOrUndefined] = useSearchParamsState<MetricBandFilter>(
    'band',
    MetricBandFilter.All,
  );
  const currentBand = currentBandOrUndefined ?? MetricBandFilter.All;

  const [currentSSIDOrUndefined] = useSearchParamsState<{
    UUID: string;
    ssid: string;
  }>('ssid', { UUID: 'All', ssid: 'All' });
  const currentSSID = currentSSIDOrUndefined ?? { UUID: 'All', ssid: 'All' };

  return (
    <Suspense
      fallback={
        <Box padding={{ x: space(16) }}>
          <VStack spacing={space(12)}>
            <Skeleton height={200} width="100%" radius={6} />
          </VStack>
        </Box>
      }
    >
      <ConnectionEvents
        identifier={accessPointData.virtualDevice.UUID}
        deviceType={MetricsDeviceType.AccessPoint}
        band={currentBand}
        ssidUUID={currentSSID.UUID}
        timePeriod={currentTimePeriod}
        globalFilter={globalFilter}
      />
    </Suspense>
  );
}

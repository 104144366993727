import { Button, EmptyState } from '@meterup/atto';

export default function CaptivePortalsList() {
  return (
    <EmptyState
      icon="captive-portal"
      heading="No captive portals"
      action={
        <Button arrangement="leading-icon" icon="plus">
          Add captive portal
        </Button>
      }
    />
  );
}

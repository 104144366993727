import {
  Badge,
  Button,
  DeviceTarget,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  HStack,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  useDialogState,
} from '@meterup/atto';

import type { AutoVPNGroup, AutoVPNMember } from './utils';
import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import { formatTimestamp } from '../Devices/utils';
import { VLANTab } from '../NetworkWide/VLANs/VLANDetails/VLANDetails';
import IsPermitted from '../permissions/IsPermitted';
import { ReactRouterLink } from '../ReactRouterLink';
import AutoVPNMemberDeleteDialog from './AutoVPNMemberDeleteDialog';

export function AutoVPNMemberActions({
  group,
  member,
}: {
  group: AutoVPNGroup;
  member: AutoVPNMember;
}) {
  const { state: deleteDialogState } = useDialogState();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AutoVPNGroupMemberPage);

  return (
    <IsPermitted permissions={PermissionType.PermAutoVpnWrite}>
      <Button
        as={ReactRouterLink}
        to={makeDrawerLink(window.location, paths.drawers.AutoVPNMemberEditPage, {
          ...drawerParams!,
        })}
        icon="pencil"
        variant="secondary"
        arrangement="leading-icon"
      >
        Edit
      </Button>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem icon="trash-can" onSelect={deleteDialogState.open}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      {group && member && (
        <AutoVPNMemberDeleteDialog group={group} member={member} state={deleteDialogState} />
      )}
    </IsPermitted>
  );
}

export function AutoVPNMemberDetails({ member }: { member: AutoVPNMember }) {
  const companyName = useCurrentCompany();
  return (
    <Section relation="stacked">
      <SectionHeader heading="Metadata" />
      <SectionContent gutter="all">
        <SummaryList
          gutter="none"
          pairs={[
            {
              label: 'Network',
              value: (
                <IsPermitted
                  isPermitted={({ permissions }) =>
                    permissions.hasPermission(PermissionType.PermNetworkRead)
                  }
                  fallback={member.network.label}
                >
                  <DeviceTarget
                    type="network"
                    aria-label={member.network.label}
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.IndividualNetworkRootPage, {
                      companyName,
                      networkSlug: member.network.slug,
                    })}
                  >
                    {member.network.label}
                  </DeviceTarget>
                </IsPermitted>
              ),
            },
            {
              label: 'Member address',
              value: member.uplink.address,
            },
            {
              label: 'VLANs',
              value: (
                <HStack wrap="wrap" spacing={space(4)}>
                  {member.permittedVLANs?.map((vlan) => (
                    <IsPermitted
                      isPermitted={({ permissions }) =>
                        permissions.hasPermission(PermissionType.PermVlanRead)
                      }
                      fallback={vlan.name}
                    >
                      <DeviceTarget
                        type="vlan"
                        aria-label={vlan.name}
                        as={ReactRouterLink}
                        to={makeLink(paths.pages.VLANDetailsPage, {
                          companyName,
                          networkSlug: member.network.slug,
                          uuid: vlan.UUID,
                          tab: VLANTab.Details,
                        })}
                      >
                        {vlan.name}
                      </DeviceTarget>
                    </IsPermitted>
                  ))}
                </HStack>
              ),
            },
            {
              label: 'Failover',
              value: member.isFailoverEnabled ? (
                <Badge icon="checkmark" variant="positive" arrangement="leading-icon" size="small">
                  Enabled
                </Badge>
              ) : (
                <Badge icon="cross" variant="negative" arrangement="leading-icon" size="small">
                  Disabled
                </Badge>
              ),
            },
          ]}
        />
      </SectionContent>
    </Section>
  );
}

export function AutoVPNConnectionInfo({ member }: { member: AutoVPNMember }) {
  const connectionInfo = member?.uplink?.connectionInfo;

  if (!connectionInfo) return null;

  return (
    <Section relation="stacked">
      <SectionHeader heading="Connection" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          {connectionInfo.observedAt && (
            <SummaryListRow>
              <SummaryListKey>Observed at</SummaryListKey>
              <SummaryListValue>
                <Text size={12}>{formatTimestamp(connectionInfo.observedAt)}</Text>
              </SummaryListValue>
            </SummaryListRow>
          )}
          {connectionInfo.lastHandshakeRx && (
            <SummaryListRow>
              <SummaryListKey>Last handshake (RX)</SummaryListKey>
              <SummaryListValue>
                <Text size={12}>{formatTimestamp(connectionInfo.lastHandshakeRx)}</Text>
              </SummaryListValue>
            </SummaryListRow>
          )}
          {connectionInfo.lastHandshakeTx && (
            <SummaryListRow>
              <SummaryListKey>Last handshake (TX)</SummaryListKey>
              <SummaryListValue>
                <Text size={12}>{formatTimestamp(connectionInfo.lastHandshakeTx)}</Text>
              </SummaryListValue>
            </SummaryListRow>
          )}
          {connectionInfo.lastPacketRx && (
            <SummaryListRow>
              <SummaryListKey>Last packet (RX)</SummaryListKey>
              <SummaryListValue>
                <Text size={12}>{formatTimestamp(connectionInfo.lastPacketRx)}</Text>
              </SummaryListValue>
            </SummaryListRow>
          )}
          {connectionInfo.lastPacketTx && (
            <SummaryListRow>
              <SummaryListKey>Last packet (TX)</SummaryListKey>
              <SummaryListValue>
                <Text size={12}>{formatTimestamp(connectionInfo.lastPacketTx)}</Text>
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import StatsListTable from './StatsListTable';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/logs/stats',
});

export default function StatsListPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  return <StatsListTable />;
}

import { Button } from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../../constants';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useNetworkForSettingsPath } from '../../../../routes/pages/settings/network/NetworkUtils';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';
import { NotificationsValidTabs } from './NotificationsTabs';

export default function NotificationsActions({ activeTab }: { activeTab: NotificationsValidTabs }) {
  const companyName = useCurrentCompany();
  const network = useNetworkForSettingsPath({
    path: paths.pages.SettingsNetworkNotificationsPage,
  });

  return (
    <>
      {activeTab === NotificationsValidTabs.Alerts && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.AlertsAddPage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add alert
        </Button>
      )}
      {activeTab === NotificationsValidTabs.Reports && (
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.ReportsAddPage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add report
        </Button>
      )}
    </>
  );
}

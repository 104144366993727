/* eslint-disable react/no-unstable-nested-components */
import type { SortingState } from '@meterup/common';
import {
  Badge,
  Body,
  Button,
  EmptyState,
  HStack,
  Icon,
  ManufacturerIcon,
  PaneContent,
  space,
  styled,
  Text,
  Tooltip,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { AutoTable, getManufacturerIconName } from '@meterup/common';
import { capitalize } from 'lodash-es';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import type {
  NetworkClient,
  NetworkClientOptions,
} from '../../../../../hooks/networkClients/useNetworkClients';
import { NoValue } from '../../../../../components/NoValue';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import {
  clientManufacturer,
  clientNameOrNull,
  defaultSort,
  formatUsage,
  getClientConnectionStatus,
  getClientSortKey,
  isOnline,
  isWireless,
} from '../../../../../utils/clientLists';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { Filters } from './clients.constants';
import { useFilteredNetworkClients } from './clients.hooks';

const SpeedIcon = styled(Icon, {
  width: '$12',
  height: '$12',
});

const SpeedLockup = styled('div', {
  hStack: '$4',
  alignItems: 'center',
  width: '$72',
});

function MbpsCell({ row }: { row: NetworkClient }) {
  return (
    <HStack>
      <SpeedLockup>
        <SpeedIcon icon="arrow-down" />
        <Body family="monospace">
          {row.throughput?.rxRate ? formatUsage(row.throughput?.rxRate) : '0.00'}
        </Body>
      </SpeedLockup>
      <SpeedLockup>
        <SpeedIcon icon="arrow-up" />
        <Body family="monospace">
          {row.throughput?.txRate ? formatUsage(row.throughput?.txRate) : '0.00'}
        </Body>
      </SpeedLockup>
    </HStack>
  );
}

const builder = createColumnBuilder<NetworkClient>();

function EmptyStates({
  clientCount,
  filteredClientCount,
  onResetFilters,
}: {
  clientCount: number;
  filteredClientCount: number;
  onResetFilters: () => void;
}) {
  if (clientCount === 0) {
    return <EmptyState icon="client" heading="No clients on this network" />;
  }

  if (filteredClientCount === 0) {
    return (
      <EmptyState
        icon="filter"
        heading="Your filter returned no results"
        action={
          <Button icon="minus" arrangement="leading-icon" onClick={onResetFilters}>
            Reset filters
          </Button>
        }
      />
    );
  }
  return null;
}

export function ClientsList({
  additionalClientPredicate = () => true,
  networkClientOptions = {},
}: {
  additionalClientPredicate?: (networkClients: NetworkClient) => boolean;
  networkClientOptions?: NetworkClientOptions;
}) {
  const params = Nav.useRegionParams('drawer', paths.drawers.ClientSummaryPage);

  const controller = useCurrentControllerData();
  const closeDrawer = useCloseDrawerCallback();
  const network = useNetwork();
  const { clients } = useFilteredNetworkClients({
    networkClientOptions,
    network,
  });
  const filteredClients = Filters()
    .useCurrentPredicate(clients)
    .filter(additionalClientPredicate) as NetworkClient[];
  const companyName = useCurrentCompany();
  const isOperator = useIsOperator({ respectDemoMode: true });

  const sortedClients = useMemo(() => filteredClients.toSorted(defaultSort), [filteredClients]);

  const navigate = useNavigate();

  const { state } = useCommand();
  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const resetFilter = Filters().useResetCallback();

  useRegisterCommands([
    state.nodeFactory.action({
      id: 'view-wireless-info',
      display: 'View wireless info',
      label: 'View wireless info',
      priority: Priority.Low,
      icon: 'qrcode',
      onSelect() {
        navigate(
          makeDrawerLink(window.location, paths.drawers.AddClientPage, {
            companyName,
            controllerName: controller.name,
          }),
        );
      },
    }),
  ]);

  const linkTo = useCallback(
    (row: NetworkClient) =>
      makeDrawerLink(window.location, paths.drawers.ClientSummaryPage, {
        macAddress: row.macAddress,
        companyName,
        controllerName: controller.name,
      }),
    [companyName, controller.name],
  );

  const rowSelected = useCallback(
    (row: NetworkClient) => params?.macAddress === row.macAddress,
    [params?.macAddress],
  );

  const columns = useMemo(
    () => [
      builder.data(
        (d) => `${getClientConnectionStatus(d)} ${isWireless(d) ? 'wireless' : 'wired'}`,
        {
          id: 'status',
          header: () => <Icon icon="question" size={space(16)} />,
          meta: {
            alignment: 'center',
            width: 48,
            tooltip: {
              contents: 'Status',
            },
          },
          cell: (p) => (
            <Badge
              arrangement="hidden-label"
              variant={isOnline(p.row) ? 'positive' : 'neutral'}
              icon={isWireless(p.row) ? 'wifi' : 'wired'}
              size="small"
              ends="pill"
            >
              {isOnline(p.row) ? 'Online' : 'Offline'}
            </Badge>
          ),
        },
      ),
      builder.data((row) => clientManufacturer(row), {
        id: 'manufacturer',
        header: () => <Icon icon="question" size={space(16)} />,
        meta: {
          alignment: 'center',
          width: 48,
          tooltip: {
            contents: 'Manufacturer',
          },
        },
        cell: ({ value }) => (
          <Tooltip contents={capitalize(value)}>
            {/* This div (or some DOM element) necessary for the tooltip to work (?) */}
            <div>
              <ManufacturerIcon icon={getManufacturerIconName(value ?? '')} size={space(16)} />
            </div>
          </Tooltip>
        ),
      }),
      builder.data((row) => getClientSortKey(row), {
        id: 'name',
        header: 'Name',
        meta: {
          isLeading: true,
        },
        cell: (p) => <>{clientNameOrNull(p.row)}</> ?? <NoValue />,
      }),
      ...(isOperator
        ? [
            builder.data((row) => (row?.throughput?.rxRate || 0).toFixed(0)!, {
              id: 'throughput',
              header: 'Throughput (Mbps)',
              meta: {
                minWidth: 44,
                tooltip: {
                  contents:
                    'The number of megabits sent to / received from the public internet over the last 30 minutes.',
                },
              },
              cell: (props) => <MbpsCell row={props.row} />,
            }),
          ]
        : []),
      builder.data((row) => row.ip!, {
        id: 'ip',
        header: 'IP',
        cell: (p) => <Text family="monospace">{p.value}</Text>,
      }),

      builder.data((row) => row.macAddress!, {
        id: 'mac',
        header: 'MAC',
        cell: (p) => <Text family="monospace">{p.value}</Text>,
      }),
      builder.data((d) => (d?.signal || 0).toFixed(0), {
        id: 'dbm',
        header: 'dBm',
        cell: (p) =>
          isWireless(p.row) ? (
            <Badge
              arrangement="leading-icon"
              variant={(p.row?.signal || 0) > -74 ? 'positive' : 'negative'}
              size="small"
              ends="pill"
            >
              {p.value}
            </Badge>
          ) : (
            <NoValue />
          ),
      }),
      builder.data((row) => (isOnline(row) ? row.lastSeen || '-' : '-'), {
        id: 'last-seen',
        header: 'Last seen',
        meta: {
          alignment: 'end',
        },
        cell: (p) => (
          <Text family="monospace" css={{ truncate: true }}>
            {DateTime.fromISO(p.value).toRelative()}
          </Text>
        ),
      }),
    ],
    [isOperator],
  );
  return (
    <PaneContent>
      {filteredClients.length > 0 && (
        <AutoTable
          columns={columns}
          data={sortedClients}
          sortingState={sortingState}
          onChangeSortingState={setSortingState}
          globalFilter={globalFilter}
          getLinkTo={linkTo}
          isRowSelected={rowSelected}
          onRowDeselect={closeDrawer}
        />
      )}
      <EmptyStates
        clientCount={clients.length}
        filteredClientCount={filteredClients.length}
        onResetFilters={resetFilter}
      />
    </PaneContent>
  );
}

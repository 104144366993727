import type { FallbackRenderer } from '@meterup/authorization';
import React from 'react';

import { useIsCurrentCompanyAndNetworkAdmin } from '../../hooks/authorization';

type IsCurrentCompanyAndNetworkAdminProps = {
  children: React.ReactNode;
} & FallbackRenderer;

export default function IsCurrentCompanyAndNetworkAdmin({
  children,
  fallback,
}: IsCurrentCompanyAndNetworkAdminProps) {
  const hasRole = useIsCurrentCompanyAndNetworkAdmin();

  if (!hasRole) {
    if (fallback) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{fallback}</>;
    }
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import AlertEdit from '../../../../../../components/Settings/Network/Notifications/Alerts/AlertEdit';
import { paths } from '../../../../../../constants';
import { Nav } from '../../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/network/:networkSlug/notifications/alert/:uuid/edit',
  title: 'Edit alert - Alerts - Notifications - Network',
});

export default function AlertEditPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  const { uuid } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.AlertEditPage));

  return <AlertEdit alertUUID={uuid} />;
}

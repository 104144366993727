import type { CompanyContractResponse } from '@meterup/connect-api';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Body, Box, Button, CopyBox, space } from '@meterup/atto';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { NeutralBadge, PositiveBadge } from '@meterup/common';
import { DefaultRenderNetworkDetails } from '@meterup/connect-ui/src/components/Connect/DefaultRenderNetworkDetails';
import { useSafeNavigate } from '@meterup/connect-ui/src/components/hooks/useSafeNavigate';
import NetworkSummaryList from '@meterup/connect-ui/src/components/NetworkSummaryList/NetworkSummaryList';
import NetworkSummaryListItem from '@meterup/connect-ui/src/components/NetworkSummaryList/NetworkSummaryListItem';
import LocationDisplay from 'connect-frontend/src/dashboard/components/Location/LocationDisplay';
import { compact } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import { ReactRouterLink } from '../../../../components/ReactRouterLink';
import { paths } from '../../../../constants';
import {
  useCurrentCompanyLocation,
  useCurrentCompanyLocationContract,
} from '../../../../hooks/useContract';
import { useControllersOnCurrentCompany } from '../../../../hooks/useControllersOnCurrentCompany';
import { ProductTypes, useUserProductsAccess } from '../../../../hooks/useUserProductsAccess';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/connect/locations/:companyLocationSID',
  layout: 'ConnectLayout',
  title: 'Location - Connect',
});

export default function ConnectDashboardLocationPage() {
  const companySID = useCurrentCompany();
  const { state } = useCommand();
  const safeNavigate = useSafeNavigate();
  const companyContract = useCurrentCompanyLocationContract();
  const companyLocationSID = useCurrentCompanyLocation();
  const controllers = useControllersOnCurrentCompany();
  const access = useUserProductsAccess();
  const matchingController = useMemo(() => {
    if (!companyContract) {
      return null;
    }

    const controllerNames = compact(
      companyContract.companyContracts.map((contract) => contract.internetServicePlan?.controller),
    );

    if (controllerNames.length === 0) {
      return null;
    }

    return controllerNames[0];
  }, [companyContract]);
  const leftPane = useMemo(() => {
    if (!matchingController) {
      return null;
    }

    return (
      <Box padding={{ y: space(12) }}>
        <Button
          as={ReactRouterLink}
          to={makeLink(paths.pages.OverviewPage, {
            companyName: companySID,
            controllerName: matchingController,
          })}
          variant="secondary"
          size="large"
          type="button"
          width="100%"
          icon="chevron-right"
          arrangement="leading-label"
        >
          View network
        </Button>
      </Box>
    );
  }, [companySID, matchingController]);
  const renderNetworkDetails = useCallback(
    (contractResponse?: CompanyContractResponse) => {
      const internetServicePlan = contractResponse?.internetServicePlan;
      if (!internetServicePlan) {
        return null;
      }

      const {
        controller: controllerName,
        controllerIp,
        gatewayAddr,
        hasStaticIp,
        firstUsableIp,
        lastUsableIp,
        networkInterface,
        staticIpRange,
      } = internetServicePlan;
      const controller = controllers?.find((c) => c.name === controllerName);
      if (!controller) {
        return <DefaultRenderNetworkDetails contractResponse={contractResponse} />;
      }

      return (
        <NetworkSummaryList>
          {networkInterface ? (
            <NetworkSummaryListItem label="Network interface">
              <NeutralBadge ends="card">{networkInterface}</NeutralBadge>
            </NetworkSummaryListItem>
          ) : null}
          {hasStaticIp ? (
            <>
              <NetworkSummaryListItem label="IP assignment">
                <PositiveBadge ends="card">Static</PositiveBadge>
              </NetworkSummaryListItem>
              {staticIpRange ? (
                <NetworkSummaryListItem label="IP">
                  <CopyBox
                    aria-label="Copy IP address to clipboard"
                    relation="stacked"
                    size="small"
                    value={staticIpRange}
                  >
                    <Body family="monospace">{staticIpRange}</Body>
                  </CopyBox>
                </NetworkSummaryListItem>
              ) : null}
              {gatewayAddr ? (
                <NetworkSummaryListItem label="Gateway IP">
                  <CopyBox
                    aria-label="Copy IP address to clipboard"
                    relation="stacked"
                    size="small"
                    value={gatewayAddr}
                  >
                    <Body family="monospace">{gatewayAddr}</Body>
                  </CopyBox>
                </NetworkSummaryListItem>
              ) : null}
              {controllerIp ? (
                <NetworkSummaryListItem label="Controller IP">
                  <CopyBox
                    aria-label="Copy IP address to clipboard"
                    relation="stacked"
                    size="small"
                    value={controllerIp}
                  >
                    <Body family="monospace">{controllerIp}</Body>
                  </CopyBox>
                </NetworkSummaryListItem>
              ) : null}
              {firstUsableIp ? (
                <NetworkSummaryListItem label="First usable IP">
                  <CopyBox
                    aria-label="Copy IP address to clipboard"
                    relation="stacked"
                    size="small"
                    value={firstUsableIp}
                  >
                    <Body family="monospace">{firstUsableIp}</Body>
                  </CopyBox>
                </NetworkSummaryListItem>
              ) : null}
              {lastUsableIp ? (
                <NetworkSummaryListItem label="Last usable IP">
                  <CopyBox
                    aria-label="Copy IP address to clipboard"
                    relation="stacked"
                    size="small"
                    value={lastUsableIp}
                  >
                    <Body family="monospace">{lastUsableIp}</Body>
                  </CopyBox>
                </NetworkSummaryListItem>
              ) : null}
            </>
          ) : (
            <NetworkSummaryListItem label="IP assignment">
              <PositiveBadge ends="card">Dynamic</PositiveBadge>
            </NetworkSummaryListItem>
          )}
        </NetworkSummaryList>
      );
    },
    [controllers],
  );
  useRegisterCommands([
    state.nodeFactory.action({
      id: 'connect-all-locations',
      display: 'View all locations',
      label: 'View all locations',
      priority: Priority.High,
      icon: 'company',
      onSelect() {
        safeNavigate(
          makeLink(paths.pages.ConnectDashboardAllLocationsPage, {
            companyName: companySID,
          }),
        );
      },
    }),
  ]);
  return (
    <LocationDisplay
      companySID={companySID}
      contract={companyContract}
      leftPane={leftPane}
      renderNetworkDetails={renderNetworkDetails}
      sid={companyLocationSID!}
      isNetworkOnlyCustomer={access.isCustomerOfProduct(ProductTypes.NETWORK)}
    />
  );
}

import React, { useMemo } from 'react';

import { Small } from '@meterup/atto';
import { styled } from '@meterup/common';
import { EmDash } from '@meterup/connect-ui/src/constants';
import { logError } from '@meterup/connect-ui/src/Log.utils';

import useQuotesRequest from '../../hooks/useQuotesRequest';

export const ISPCell = styled(Small, {
  width: 150,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

type NoISPsPropsType = {
  quoteRequestSID: string;
};

export function NoISPsCell({ quoteRequestSID }: NoISPsPropsType) {
  const { data, isLoading, isError, error } = useQuotesRequest(quoteRequestSID);
  const { total } = useMemo(() => {
    if (!data) {
      return {};
    }
    const counts = data.quotes.reduce(
      (acc, quote) => {
        const { provider } = quote;
        if (!provider) {
          return acc;
        }
        if (!(provider.sid in acc)) {
          acc[provider.sid] = 0;
        }
        acc[provider.sid] += 1;
        return acc;
      },
      {} as Record<string, number>,
    );
    return {
      providerCounts: counts,
      total: Object.keys(counts).length,
    };
  }, [data]);

  if (isLoading || isError) {
    if (isError) {
      logError(error);
    }
    return <EmDash />;
  }

  return <ISPCell>{total} Found</ISPCell>;
}

import { useIsOperator } from '@meterup/authorization';
import { Navigate } from 'react-router';

import SwitchDetailView from '../../../../../components/Hardware/Switches/SwitchDetail';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/switches/:uuid/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Switch - Hardware',
});

const VALID_TABS = ['insights', 'ports', 'mac-table', 'boot-history'];

export default function SwitchDetailPage() {
  const network = useNetwork();
  const activeController = useActiveControllerForNetwork(network);
  const companyName = useCurrentCompany();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);

  const controllerSerialNumber = activeController?.hardwareDevice?.serialNumber;

  const pageParams = Nav.useRegionParams('root', paths.pages.SwitchDetailPage);

  if (!isSOSEnabled && !isOperator) {
    if (controllerSerialNumber) {
      return (
        <Navigate
          to={makeLink(paths.pages.LegacySwitchListPage, {
            companyName,
            controllerName: controllerSerialNumber,
          })}
        />
      );
    }

    return (
      <Navigate
        to={makeLink(paths.pages.IndividualNetworkRootPage, {
          companyName,
          networkSlug: network.slug,
        })}
      />
    );
  }

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags, ldFlags, pathParams }) =>
        Boolean(
          (permissions.hasPermission(PermissionType.PermPhyInterfaceRead) ||
            permissions.hasPermission(PermissionType.PermNetworkMetricsRead)) &&
            nosFlags[NosFeature.SOS] &&
            ldFlags['sos-ui'] &&
            VALID_TABS.includes(pathParams?.tab!),
        )
      }
      path={Meta().path}
      should404OnAccessDenied
    >
      <SwitchDetailView uuid={pageParams?.uuid!} tab={pageParams?.tab!} />
    </IsPermitted>
  );
}

import {
  Body,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useNetworkClients } from '../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useAPIClient } from '../../../providers/APIClientProvider';
import { useCurrentController } from '../../../providers/CurrentControllerProvider';
import { NoValue } from '../../NoValue';
import { StickyObjectHeader } from '../../Object/ObjectHeader';

type LegacySwitchSummaryProps = {
  macAddress: string;
};

function LegacySwitchDetail({ macAddress }: LegacySwitchSummaryProps) {
  const controllerName = useCurrentController();
  const api = useAPIClient();
  const [ipAddress, setIPAddress] = useState<string | null>(null);

  const switchData = useQuery(
    ['controller', controllerName, 'switches', macAddress],
    () => api.switch(macAddress),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(switchData, new ResourceNotFoundError('Switch not found'));

  const network = useNetwork();
  const connectedClients = useNetworkClients(network);

  useEffect(() => {
    setIPAddress((connectedClients ?? []).find((c) => c.macAddress === macAddress)?.ip ?? null);
  }, [connectedClients, macAddress]);

  return (
    <DrawerContent gutter="none">
      <StickyObjectHeader
        icon="switch"
        name={switchData.location}
        status={ipAddress ? 'online' : 'offline'}
      />
      <Section relation="stacked">
        <SectionHeader icon="information" heading="Metadata" />
        <SectionContent gutter="all">
          <SummaryList gutter="none">
            <SummaryListRow>
              <SummaryListKey>IP address</SummaryListKey>
              <SummaryListValue>
                {ipAddress ? (
                  <CopyBox
                    aria-label="Copy IP address to clipboard"
                    relation="stacked"
                    size="small"
                    value={ipAddress}
                  >
                    <Body family="monospace">{ipAddress}</Body>
                  </CopyBox>
                ) : (
                  <NoValue />
                )}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>MAC Address</SummaryListKey>
              <SummaryListValue>
                {switchData.mac ? (
                  <CopyBox
                    aria-label="Copy MAC address to clipboard"
                    relation="stacked"
                    size="small"
                    value={switchData.mac}
                  >
                    <Body family="monospace">{switchData.mac}</Body>
                  </CopyBox>
                ) : (
                  <NoValue />
                )}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>PoE Wattage</SummaryListKey>
              <SummaryListValue>
                {switchData.poe_wattage ? (
                  <CopyBox
                    aria-label="Copy PoE wattage to clipboard"
                    relation="stacked"
                    size="small"
                    value={`${switchData.poe_wattage.toFixed(0)}W`}
                  >
                    <Body family="monospace">{`${switchData.poe_wattage.toFixed(0)}W`}</Body>
                  </CopyBox>
                ) : (
                  <NoValue />
                )}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Ports</SummaryListKey>
              <SummaryListValue>
                {switchData.port_info ? (
                  <CopyBox
                    aria-label="Copy port info to clipboard"
                    relation="stacked"
                    size="small"
                    value={switchData.port_info}
                  >
                    <Body family="monospace">{switchData.port_info}</Body>
                  </CopyBox>
                ) : (
                  <NoValue />
                )}
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        </SectionContent>
      </Section>
    </DrawerContent>
  );
}

export function LegacySwitchSummaryDrawer({ macAddress }: LegacySwitchSummaryProps) {
  return (
    <Drawer>
      <DrawerHeader icon="switch" heading="Switch" onClose={useCloseDrawerCallback()} />
      <LegacySwitchDetail macAddress={macAddress} />
    </Drawer>
  );
}

import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLError,
  GraphQLErrorBoundary,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { Suspense } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import DeviceConfigEditorDialog from '../../../routes/pages/network/operators/device_config/DeviceConfigEditorDialog';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { blinkLEDsMutation, refreshConfigMutation } from '../../Devices/utils';
import IsPermitted from '../../permissions/IsPermitted';
import PowerDistributionUnitDeleteDialog from './PowerDistributionUnitDeleteDialog';
import { PowerDistributionUnitRebootDialog } from './PowerDistributionUnitRebootDialog';
import { PowerDistributionUnitQuery } from './utils';

export default function PowerDistributionUnitActions({
  serialNumber,
  status,
  uuid,
  view,
}: {
  serialNumber: string;
  status?: 'online' | 'offline';
  uuid: string;
  view: 'detail' | 'drawer' | 'edit' | 'list';
}) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const virtualDevice = useGraphQL(
    PowerDistributionUnitQuery,
    { uuid },
    { useErrorBoundary: false },
  ).data?.virtualDevice;
  const ledMutation = useGraphQLMutation(blinkLEDsMutation);
  const configMutation = useGraphQLMutation(refreshConfigMutation);
  const rebootDialogProps = useDialogState();
  const configDialogProps = useDialogState();
  const deleteDialogProps = useDialogState();

  const flash = () => {
    ledMutation.mutate(
      {
        serialNumber,
      },
      {
        onSuccess: () => {
          notify('Request sent to flash LEDs on this power distribution unit', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error flashing LEDs on this power distribution unit${
              gqlErr?.message ? `: ${gqlErr.message}` : ''
            }`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  };

  const refreshConfig = () => {
    configMutation.mutate(
      {
        serialNumber,
      },
      {
        onSuccess: () => {
          notify('Request sent to the power distribution unit to pull its config', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(`There was an error${gqlErr?.message ? `: ${gqlErr.message}` : ''}`, {
            variant: 'negative',
          });
        },
      },
    );
  };

  const openDetails = () => {
    navigate(
      makeLink(paths.pages.PowerDistributionUnitDetailPage, {
        companyName,
        networkSlug: network.slug,
        uuid,
        tab: 'insights',
      }),
    );
  };

  const isOffline = !status || status === 'offline';

  return (
    <>
      {view !== 'edit' && (
        <IsPermitted permissions={PermissionType.PermNetworkDevicesWrite}>
          <Button
            as={Link}
            to={makeDrawerLink(window.location, paths.drawers.PowerDistributionUnitEditPage, {
              companyName,
              networkSlug: network.slug,
              uuid,
            })}
            arrangement="leading-icon"
            icon="pencil"
            variant="secondary"
          >
            Edit
          </Button>
        </IsPermitted>
      )}
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>

        <DropdownMenuPopover align="end">
          {view !== 'detail' && (
            <DropdownMenuGroup>
              <DropdownMenuItem icon="pdu" onSelect={openDetails}>
                View details
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
          <DropdownMenuGroup>
            <IsPermitted
              anyPermissions={[
                PermissionType.PermNetworkDevicesWrite,
                PermissionType.PermHardwareDeviceRpcBlinkLeds,
              ]}
            >
              <DropdownMenuItem
                disabled={ledMutation.isLoading || isOffline}
                onSelect={flash}
                icon="flash-leds"
              >
                Flash LEDs
              </DropdownMenuItem>
            </IsPermitted>
            <IsPermitted permissions={PermissionType.PermNetworkDevicesWrite}>
              {virtualDevice && virtualDevice.hardwareDevice && (
                <DropdownMenuItem
                  disabled={isOffline}
                  onSelect={rebootDialogProps.openFromMenu}
                  icon="power-cycle"
                >
                  Reboot
                </DropdownMenuItem>
              )}
            </IsPermitted>
          </DropdownMenuGroup>
          <IsPermitted
            anyPermissions={[
              PermissionType.PermHardwareDeviceRpcRefreshConfig,
              PermissionType.PermNetworkDevicesWriteRestricted,
              PermissionType.PermNetworkDevicesWrite,
            ]}
          >
            <DropdownMenuGroup>
              <IsPermitted
                anyPermissions={[
                  PermissionType.PermNetworkDevicesWrite,
                  PermissionType.PermHardwareDeviceRpcRefreshConfig,
                ]}
              >
                <DropdownMenuItem
                  disabled={configMutation.isLoading || isOffline}
                  onSelect={refreshConfig}
                  icon="arrows-rotate"
                >
                  Refresh config
                </DropdownMenuItem>
              </IsPermitted>
              <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
                {virtualDevice && virtualDevice.hardwareDevice && (
                  <DropdownMenuItem onSelect={configDialogProps.openFromMenu} icon="code" internal>
                    Show config
                  </DropdownMenuItem>
                )}
              </IsPermitted>
            </DropdownMenuGroup>
          </IsPermitted>
          <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
            <DropdownMenuGroup>
              <DropdownMenuItem onSelect={deleteDialogProps.openFromMenu} icon="trash-can" internal>
                Delete
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </IsPermitted>
        </DropdownMenuPopover>
      </DropdownMenu>
      <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
        {virtualDevice && (
          <PowerDistributionUnitDeleteDialog
            state={deleteDialogProps.state}
            uuid={virtualDevice.UUID}
          />
        )}
      </IsPermitted>
      <IsPermitted permissions={PermissionType.PermNetworkDevicesWrite}>
        {virtualDevice && virtualDevice.hardwareDevice && (
          <>
            <PowerDistributionUnitRebootDialog
              state={rebootDialogProps.state}
              serialNumber={virtualDevice.hardwareDevice.serialNumber}
              label={virtualDevice.label}
            />
            <GraphQLErrorBoundary fallback={() => null}>
              <Suspense fallback={null}>
                <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
                  <DeviceConfigEditorDialog
                    state={configDialogProps.state}
                    serialNumber={virtualDevice.hardwareDevice.serialNumber}
                  />
                </IsPermitted>
              </Suspense>
            </GraphQLErrorBoundary>
          </>
        )}
      </IsPermitted>
    </>
  );
}

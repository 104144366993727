import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Badge,
  Box,
  Button,
  ComboBox,
  ComboBoxItem,
  FieldContainer,
  HStack,
  PrimaryField,
  SecondaryCheckboxField,
  space,
  styled,
  Text,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik, useFormikContext } from 'formik';
import { orderBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import * as z from 'zod';

import type { Network } from '../../hooks/useNetworksForCompany';
import { useActiveControllerForNetwork } from '../../hooks/useActiveControllerForNetwork';
import { withZodSchema } from '../../utils/withZodSchema';
import { formatTimestamp } from '../Devices/utils';
import { FieldProvider, NumberFieldProvider } from '../Form/FieldProvider';
import useCurrentControllers from '../Hardware/SecurityAppliance/useCurrentControllers';
import { DeviceLastDayCellularUsageQuery } from '../Hardware/SecurityAppliance/utils';
import { type NosVersion, NosVersionsShortQuery, SetNetworkNosMutation } from './utils';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/nos-upgrade/edit',
});

const validNosUpdateSchema = z.object({
  nosId: z.number(),
  forceUpdate: z.boolean().optional(),
  staggerUpgrade: z.boolean().optional(),
});
const nosUpdateSchema = validNosUpdateSchema.extend({
  nosId: z.number().nullish(),
  confirmInactiveCellularUpgrade: z.boolean(),
});
type NosUpgradeParams = z.infer<typeof nosUpdateSchema>;
const schemaValidator = withZodSchema(nosUpdateSchema);

const StyledForm = styled(Form, {
  display: 'contents',
});

function PendingUpgradeAlert() {
  const { virtualDevices } = useCurrentControllers();

  const virtualDevice = virtualDevices?.[0];

  if (!virtualDevice?.pendingNosVersion) return null;

  return (
    <Alert
      copy={`Pending upgrade to ${
        virtualDevice?.pendingNosVersion?.nosVersion?.version
      } on ${formatTimestamp(virtualDevice?.pendingNosVersion?.scheduledAt)}`}
      icon="information"
      variant="neutral"
      internal
    />
  );
}

function SubmitButton() {
  const { values } = useFormikContext<NosUpgradeParams>();

  return (
    <Box padding={{ x: space(12), y: space(8) }}>
      <Button type="submit" size="large" variant="secondary" width="100%">
        {values.forceUpdate ? 'Upgrade now' : 'Schedule upgrade'}
      </Button>
    </Box>
  );
}

export function NOSVersion({ nosVersion }: { nosVersion: NosVersion }) {
  return (
    <HStack spacing={space(6)}>
      {nosVersion.version}
      <Text size="12">
        (
        <Text family="monospace">
          {nosVersion.major}.{nosVersion.minor}.{nosVersion.patch}
        </Text>
        )
      </Text>
      {nosVersion.isDefault && (
        <Badge size="small" icon="checkmark" variant="positive" arrangement="leading-label">
          Default
        </Badge>
      )}
    </HStack>
  );
}

export default function EditNosUpgrade({ network }: { network: Network }) {
  const nosVersionsUnsorted = useGraphQL(NosVersionsShortQuery).data?.nosVersions;
  const nosversions = orderBy(nosVersionsUnsorted, (e) => e.version, 'asc');
  const setNetworkNosMutation = useGraphQLMutation(SetNetworkNosMutation);

  const controller = useActiveControllerForNetwork(network);
  const serialNumber = controller?.hardwareDevice?.serialNumber;

  const cellularUsage = useGraphQL(
    DeviceLastDayCellularUsageQuery,
    {
      serialNumber: serialNumber!,
    },
    { enabled: !!serialNumber, useErrorBoundary: false },
  );
  const hasCellularUsage = useMemo(
    () =>
      cellularUsage.data?.deviceLastDayCellularUsage?.uploadBytes &&
      cellularUsage.data?.deviceLastDayCellularUsage?.downloadBytes,
    [cellularUsage.data?.deviceLastDayCellularUsage],
  );

  const handleSubmit = useCallback(
    (v: NosUpgradeParams) => {
      if (v.nosId == null) return; // this will be caught by the validation already

      setNetworkNosMutation.mutate(
        {
          networkUUID: network.UUID,
          input: {
            force: v.forceUpdate,
            nosId: v.nosId,
            staggeredUpgradeIntervalMin: v.staggerUpgrade ? 10 : 0,
          },
        },
        {
          onSuccess: () => {
            notify('Successfully updated network NOS firmware.', { variant: 'positive' });
          },
          onError: (err) => {
            const gqlErr = getGraphQLError(err);
            notify(
              `There was an error updating NOS for this network ${
                gqlErr?.message ? `: ${gqlErr.message}` : ''
              }.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [setNetworkNosMutation, network.UUID],
  );

  const handleValidate = useCallback(
    (values: NosUpgradeParams) => {
      const schemaValidation = schemaValidator(values);

      if (serialNumber && !hasCellularUsage && !values.confirmInactiveCellularUpgrade) {
        schemaValidation.confirmInactiveCellularUpgrade =
          'Please confirm upgrade for network with inactive cellular connection.';
      }

      return schemaValidation;
    },
    [hasCellularUsage, serialNumber],
  );

  return (
    <Formik<NosUpgradeParams>
      initialValues={{
        nosId: network.pinnedNOSVersionID,
        forceUpdate: false,
        staggerUpgrade: false,
        confirmInactiveCellularUpgrade: false,
      }}
      validate={handleValidate}
      onSubmit={(values) => handleSubmit(values)}
      enableReinitialize
    >
      <StyledForm>
        <PendingUpgradeAlert />
        <FieldContainer>
          <NumberFieldProvider name="nosId" defaultValue={null}>
            <PrimaryField
              label="NOS firmware"
              element={
                <ComboBox placeholder="Select a NOS version">
                  {Object.values(nosversions).map((nos) => (
                    <ComboBoxItem
                      key={nos.id}
                      textValue={`${nos.version} (${nos.major}.${nos.minor}.${nos.patch})`}
                    >
                      <NOSVersion nosVersion={nos} />
                    </ComboBoxItem>
                  ))}
                </ComboBox>
              }
            />
          </NumberFieldProvider>
          <FieldProvider name="forceUpdate">
            <SecondaryCheckboxField
              label="Force upgrade now"
              description="All device in this network will be upgraded immediately! Maintenance window will be ignored."
            />
          </FieldProvider>
          <FieldProvider name="staggerUpgrade">
            <SecondaryCheckboxField
              label="Stagger device upgrades across this network"
              description="Upgrades devices one at a time 10min apart. Upgrades begin during maintenance window. COS is upgraded first followed by APs. Total time taken for upgrading all devices can exceed maintenance window."
            />
          </FieldProvider>
          {serialNumber && !hasCellularUsage && (
            <>
              <Alert
                relation="stacked"
                type="inline"
                variant="attention"
                icon="warning"
                heading="Device does not have an active bidirectional cellular connection."
                copy={
                  cellularUsage.data?.deviceLastDayCellularUsage
                    ? `${cellularUsage.data.deviceLastDayCellularUsage.downloadBytes} bytes downloaded, ${cellularUsage.data.deviceLastDayCellularUsage.uploadBytes} bytes uploaded.`
                    : 'Error fetching device cellular usage.'
                }
              />
              <FieldProvider name="confirmInactiveCellularUpgrade">
                <SecondaryCheckboxField label="Confirm upgrade for network without active cellular connection" />
              </FieldProvider>
            </>
          )}
          <SubmitButton />
        </FieldContainer>
      </StyledForm>
    </Formik>
  );
}

import { backgrounds, colors, darkThemeSelector, sizing, Small, styled } from '@meterup/atto';

const ChartEmptyInner = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  background: backgrounds.checkeredLight,
  backgroundColor: colors.bgNeutralLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: backgrounds.checkeredDark,
    backgroundColor: colors.bgNeutralDark,
  },
});

const ChartEmptyContainer = styled('div', {
  display: 'flex',
  height: '100%',
  padding: sizing.contentEndsOnly,
});

export default function ChartEmpty({ ...remaining }) {
  return (
    <ChartEmptyContainer {...remaining}>
      <ChartEmptyInner>
        <Small>No data to display</Small>
      </ChartEmptyInner>
    </ChartEmptyContainer>
  );
}

import type { MeterControllerConfig } from '@meterup/config';
import { MeterV1NetworkNATPortForward } from '@meterup/config';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';

import type { ValidPortForwardingRule } from './form_data';
import { saveConfigDiff } from '../../../../../api/saveConfigDiff';

export const upsertPortForwardingRule = (
  configModel: MeterControllerConfig,
  rule: MeterV1NetworkNATPortForward,
) =>
  produce(configModel, (draft) => {
    const idx = draft.nat.portForwards.findIndex((r) => r.stableId === rule.stableId);
    if (idx === -1) {
      draft.nat.portForwards.push(rule);
    } else {
      // eslint-disable-next-line no-param-reassign
      draft.nat.portForwards[idx] = rule;
    }
  });

export const deletePortForwardingRule = (
  configModel: MeterControllerConfig,
  rule: MeterV1NetworkNATPortForward,
) =>
  produce(configModel, (draft) => {
    const idx = draft.nat.portForwards.findIndex((r) => r.stableId === rule.stableId);
    draft.nat.portForwards.splice(idx, 1);
  });

const ruleFromFormData = (values: ValidPortForwardingRule): MeterV1NetworkNATPortForward =>
  MeterV1NetworkNATPortForward.fromProps(values.stableId ?? null, values);

export function useUpsertPortForwardingRuleMutation(
  controller: string,
  configModel: MeterControllerConfig,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async (values: ValidPortForwardingRule) => {
      const rule = ruleFromFormData(values);
      const updatedModel = upsertPortForwardingRule(configModel, rule);
      await saveConfigDiff(controller, updatedModel.toJSON(), configModel.toJSON());
      return rule;
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['controller', controller, 'config']);
      },
    },
  );
}

export function useRemovePortForwardingRuleMutation(
  controller: string,
  configModel: MeterControllerConfig,
  rule: MeterV1NetworkNATPortForward,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const updatedModel = deletePortForwardingRule(configModel, rule);

      await saveConfigDiff(controller, updatedModel.toJSON(), configModel.toJSON());
    },
    {
      onSettled: () => {
        queryClient.refetchQueries(['controller', controller, 'config']);
      },
    },
  );
}

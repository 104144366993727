import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import ClientVPNClientDrawer from '../../../../../components/ClientVPN/ClientDrawer';
import { ClientTab } from '../../../../../components/ClientVPN/utils';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/vpn/:serverUUID/clients/:clientUUID/:tab',
  title: 'Client - Client VPN - Secure tunnels',
});

export default function ClientVPNClientPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ClientVPNClientPage);
  if (drawerParams?.clientUUID && drawerParams.tab)
    return (
      <IsPermitted
        isPermitted={({ pathParams }) =>
          Object.values(ClientTab).includes(pathParams?.tab as ClientTab)
        }
        permissions={PermissionType.PermClientVpnClientRead}
        should404OnAccessDenied
        path={Meta().path}
      >
        <ClientVPNClientDrawer
          clientUUID={drawerParams.clientUUID}
          tab={drawerParams.tab as ClientTab}
        />
      </IsPermitted>
    );
}

import { Alert, Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '@meterup/common';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { useRemoveServiceSetMutation } from './hooks';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/ssids/:id/remove',
});

export default function LegacySSIDRemovePage() {
  const { id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacySSIDRemovePage),
  );
  const controller = useCurrentController();

  const configModel = useControllerConfig(controller);

  const serviceSetModel = configModel.getServiceSetByStableId(id);

  expectDefinedOrThrow(serviceSetModel);
  const navigate = useNavigate();

  const closeDrawer = useCloseDrawerCallback();

  const removeConfigMutation = useRemoveServiceSetMutation(controller, configModel, id, () => {
    closeDrawer();
  });

  return (
    <Drawer>
      <DrawerHeader icon="ssid" heading="Delete SSID" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <Alert
          icon="attention"
          heading="Confirmation required"
          copy={`Are you sure you wish to remove the SSID "${serviceSetModel?.ssid}"?`}
          variant="negative"
        />
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button onClick={() => navigate(-1)} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => removeConfigMutation.mutate()} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Drawer>
  );
}

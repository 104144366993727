import { Drawer, DrawerContent, DrawerHeader, Sections, Tab } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import {
  type SortingState,
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import type { AccessPoint } from '../../Wireless/utils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { type ObjectHeaderProps, StickyObjectHeader } from '../../Object/ObjectHeader';
import { DetailsWidget } from '../../Wireless/insights';
import { AccessPointQuery, ValidAPDrawerTabs } from '../../Wireless/utils';
import { isPortSlow } from '../Switches/utils';
import { getAccessPointStatus } from './AccessPoint';
import { AccessPointActions } from './AccessPointActions';
import AccessPointClientList from './AccessPointClientList';

type AccessPointSummaryProps = {
  uuid: string;
};

function AccessPointTabs({
  activeTab,
  setActiveTab,
}: {
  activeTab: string;
  setActiveTab: (val: ValidAPDrawerTabs) => void;
}) {
  return (
    <>
      <Tab
        icon="information"
        selected={activeTab === ValidAPDrawerTabs.AccessPoint}
        onClick={() => setActiveTab(ValidAPDrawerTabs.AccessPoint)}
      >
        Details
      </Tab>
      <Tab
        icon="client"
        selected={activeTab === ValidAPDrawerTabs.Clients}
        onClick={() => setActiveTab(ValidAPDrawerTabs.Clients)}
      >
        Clients
      </Tab>
    </>
  );
}

function apStatus(
  hardwareDevice:
    | Extract<
        NonNullable<AccessPoint['hardwareDevice']>,
        { __typename: 'AccessPointHardwareDevice' }
      >
    | null
    | undefined,
  isSOSEnabled: boolean,
): ObjectHeaderProps['status'] {
  if (hardwareDevice?.isConnectedToBackend) {
    if (hardwareDevice.phyInterfaceConnectedTo?.portSpeedMbps) {
      return isPortSlow(hardwareDevice.phyInterfaceConnectedTo) ? 'attention' : 'online';
    }

    if (!isSOSEnabled) {
      // If we don't have SOS meter switches, we can't know link speed
      return 'online';
    }

    return 'attention';
  }

  if (hardwareDevice?.disconnectedFromBackendAt) {
    return 'offline';
  }

  return undefined;
}

function AccessPointSummary({ uuid }: AccessPointSummaryProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const virtualDevice = useGraphQL(AccessPointQuery, { uuid, includeUptime }).data?.virtualDevice;
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);

  const isActiveTest = useIsActiveMatcher();
  const isAccessPointClientsSummaryPage = isActiveTest({
    path: paths.drawers.AccessPointClientsSummaryPage,
    end: true,
  });

  const accessPointLink = isAccessPointClientsSummaryPage
    ? makeLink(paths.pages.AccessPointClientsListDetailPage, {
        companyName,
        networkSlug: network.slug,
        uuid,
      })
    : makeLink(paths.pages.AccessPointPage, {
        networkSlug: network.slug,
        companyName,
        uuid,
        tab: 'insights',
      });

  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Access point not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('Access point not found.');
  }

  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'AccessPointHardwareDevice') return null;
  if (virtualDevice.__typename !== 'AccessPointVirtualDevice') return null;

  return (
    <>
      <StickyObjectHeader
        icon="access-point"
        name={virtualDevice.label}
        status={apStatus(hardwareDevice, isSOSEnabled)}
        link={accessPointLink}
        cta="View access point"
      />
      <Sections>
        <DetailsWidget
          virtualDevice={virtualDevice}
          hardwareDevice={hardwareDevice}
          relation="stacked"
        />
      </Sections>
    </>
  );
}

export default function AccessPointDrawer() {
  const { uuid, tab } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.AccessPointDrawerPage),
  );
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const closeDrawer = useCloseDrawerCallback();
  const navigate = useNavigate();
  const virtualDevice = useGraphQL(
    AccessPointQuery,
    { uuid, includeUptime },
    { useErrorBoundary: false },
  ).data?.virtualDevice;
  const setActiveTab = useCallback(
    (selectedTab: ValidAPDrawerTabs) => {
      navigate(
        makeDrawerLink(window.location, paths.drawers.AccessPointDrawerPage, {
          companyName,
          uuid,
          tab: selectedTab,
          networkSlug: network.slug,
        }),
      );
    },
    [navigate, companyName, network, uuid],
  );

  if (tab === 'config' && !isOperator) {
    throw new ResourceNotFoundError(`Invalid tab: config`);
  }

  const [clientsSortingState, setClientsSortingState] = useState<SortingState>([
    { id: 'client', desc: false },
  ]);
  const status = virtualDevice && getAccessPointStatus(virtualDevice);

  return (
    <Drawer>
      <DrawerHeader
        icon="access-point"
        heading="Access point"
        tabs={<AccessPointTabs activeTab={tab} setActiveTab={setActiveTab} />}
        actions={
          <AccessPointActions
            view="drawer"
            uuid={uuid}
            serialNumber={
              !virtualDevice?.hardwareDevice?.serialNumber
                ? 'Unknown serial number'
                : virtualDevice.hardwareDevice.serialNumber
            }
            status={status === 'online' ? 'online' : 'offline'}
          />
        }
        onClose={closeDrawer}
      />

      <DrawerContent gutter="none">
        {tab === 'access-point' && <AccessPointSummary uuid={uuid} />}
        {tab === 'clients' && (
          <AccessPointClientList
            uuid={uuid}
            view="drawer"
            sortingState={clientsSortingState}
            setSortingState={setClientsSortingState}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
}

import { Body, HStack, Icon, space } from '@meterup/atto';
import React from 'react';

import { formatKbpsAsBps } from '../../../utils/networkSpeedUtils';

interface UploadDownloadStatProps {
  downloadKbps: number;
  uploadKbps: number;
}

export function UploadDownloadStat({ downloadKbps, uploadKbps }: UploadDownloadStatProps) {
  return (
    <HStack align="center" spacing={space(12)}>
      <HStack align="center" spacing={space(8)}>
        <Icon icon="download" size={space(12)} />
        <Body>{formatKbpsAsBps(downloadKbps)}</Body>
      </HStack>
      <HStack align="center" spacing={space(8)}>
        <Icon icon="upload" size={space(12)} />
        <Body>{formatKbpsAsBps(uploadKbps)}</Body>
      </HStack>
    </HStack>
  );
}

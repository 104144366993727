import { Alert, Button, Section, SectionContent, SectionHeader, SummaryList } from '@meterup/atto';

import { CopyableMonoOrNoValue } from '../../../../Devices/Insights';
import { formatTimestamp } from '../../../../Devices/utils';

export function InspectorMetadata({
  hardwareDevice,
  virtualDevice,
  lastBootHistoryBuildName,
  deadMansToken,
}: {
  hardwareDevice?: any;
  virtualDevice?: any;
  lastBootHistoryBuildName?: any;
  deadMansToken?: any;
}) {
  return (
    <Section relation="stacked" size="x-small">
      <SectionHeader heading="Metadata" />
      <SectionContent gutter="all">
        <SummaryList
          gutter="none"
          size="x-small"
          pairs={[
            {
              label: 'Virtual device',
              value: (
                <CopyableMonoOrNoValue
                  label="Copy virtual device UUID"
                  value={virtualDevice.UUID}
                />
              ),
            },
            ...(hardwareDevice
              ? [
                  {
                    label: 'Serial number',
                    value: (
                      <CopyableMonoOrNoValue
                        label="Copy serial number"
                        value={hardwareDevice.serialNumber}
                      />
                    ),
                  },
                  {
                    label: 'WG tunnel IP',
                    value: (
                      <CopyableMonoOrNoValue
                        label="Copy WG tunnel IP"
                        value={hardwareDevice.tunnelIPAddress}
                      />
                    ),
                  },
                  ...(!hardwareDevice.isConnectedToBackend &&
                  hardwareDevice.disconnectedFromBackendAt
                    ? [
                        {
                          label: 'Offline since',
                          value: (
                            <CopyableMonoOrNoValue
                              label="Copy offline since"
                              value={formatTimestamp(hardwareDevice.disconnectedFromBackendAt)}
                            />
                          ),
                        },
                      ]
                    : []),
                  {
                    label: 'NOS version',
                    value: (
                      <CopyableMonoOrNoValue
                        label="Copy NOS version"
                        value={virtualDevice.nosVersion?.version}
                      />
                    ),
                  },
                  {
                    label: 'NOS build name',
                    value: (
                      <CopyableMonoOrNoValue
                        label="Copy NOS build name"
                        value={virtualDevice.nosVersion?.buildName}
                      />
                    ),
                  },
                  ...(virtualDevice?.pendingNosVersion
                    ? [
                        {
                          label: 'Pending NOS version',
                          value: (
                            <Alert
                              variant="attention"
                              icon="information"
                              heading={`Pending upgrade to ${virtualDevice?.pendingNosVersion?.nosVersion?.version} on ${formatTimestamp(virtualDevice?.pendingNosVersion?.scheduledAt)}`}
                            />
                          ),
                        },
                      ]
                    : []),
                  {
                    label: 'Booted build name',
                    value: (
                      <CopyableMonoOrNoValue
                        label="Copy booted build name"
                        value={lastBootHistoryBuildName ?? 'Unknown'}
                      />
                    ),
                  },
                  ...(lastBootHistoryBuildName &&
                  virtualDevice?.nosVersion?.id !== 2 &&
                  virtualDevice?.nosVersion?.buildName !== lastBootHistoryBuildName
                    ? [
                        {
                          label: 'Pending NOS version',
                          value: (
                            <Alert
                              variant="negative"
                              icon="warning"
                              heading={`NOS version ${virtualDevice?.nosVersion?.version} expected build ${virtualDevice?.nosVersion?.buildName} but device is running ${lastBootHistoryBuildName ?? 'Unknown'}`}
                            />
                          ),
                        },
                      ]
                    : []),
                  ...(deadMansToken
                    ? [
                        {
                          label: 'DeadMansSnitch',
                          value: (
                            <Button
                              as="a"
                              href={`https://deadmanssnitch.com/snitches/${deadMansToken}`}
                              target="_blank"
                              variant="secondary"
                              size="small"
                              arrangement="leading-label"
                            >
                              View
                            </Button>
                          ),
                        },
                      ]
                    : []),
                ]
              : []),
          ]}
        />
      </SectionContent>
    </Section>
  );
}

/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';

import {
  Body,
  Button,
  darkThemeSelector,
  formatMoney,
  HStack,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  Section,
  SectionContent,
  SectionHeader,
  space,
  styled,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { colors } from '@meterup/atto/src/stitches.config';
import type {
  AxiosAPIError,
  CompanyContractsResponse,
  Quote,
  QuotesRequest,
} from '@meterup/connect-api';
import { CompanyContractResponse, PortalOrigin } from '@meterup/connect-api';
import { get } from '@meterup/connect-api/src/axios';
import { getConnectUrl } from '@meterup/connect-api/src/config';
import LoadingLayout from '@meterup/connect-ui/src/Layouts/LoadingLayout';
import { HasTitle } from '@meterup/connect-ui/src/Layouts/types/common';
import { useQuery } from '@tanstack/react-query';
import { groupBy, minBy, size } from 'lodash-es';

import { usePrimarySecondaryContracts } from '../../hooks/usePrimarySecondaryISPs';
import ContractDetailBox from '../isps/ContractDetailBox';
import RenameLocation from './RenameLocation';

const TopLabel = styled('div', {
  padding: '$12 $28 $12',
  textAlign: 'center',
  '& + svg': {
    display: 'none',
  },
  '&:hover': {
    paddingRight: 8,
    textDecorationLine: 'underline',
    textDecorationStyle: 'dashed',
    textUnderlineOffset: 4,
    textDecorationThickness: '0.05em',
    textDecorationColor: colors.gray300,

    [darkThemeSelector]: {
      textDecorationColor: colors.gray400,
    },

    '& + svg': {
      display: 'inline-block',
    },
  },
});

const leftPaneWidthPx = 360;

const LeftPane = styled(VStack, {
  gap: '$16',
  position: 'sticky',
  left: 0,
  zIndex: 5,
  width: leftPaneWidthPx,
  minWidth: leftPaneWidthPx,
  height: '100%',
  display: 'flex',
  padding: '$32 $16',
  borderLeft: `1px solid ${colors.strokeNeutralLight}`,
  borderRight: `1px solid ${colors.strokeNeutralLight}`,

  [darkThemeSelector]: {
    borderLeftColor: colors.strokeNeutralDark,
    borderRightColor: colors.strokeNeutralDark,
  },
});

const RightPane = styled('div', {
  display: 'flex',
  width: '100%',
});

const Panes = styled('div', {
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
});

type LocationDisplayProps = HasTitle & {
  companySID: string;
  contract?: CompanyContractsResponse;
  leftPane?: React.ReactNode;
  renderNetworkDetails?: (contractResponse?: CompanyContractResponse) => React.ReactNode;
  sid: string;
  isNetworkOnlyCustomer: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function LocationDisplay({
  companySID,
  contract,
  leftPane,
  renderNetworkDetails,
  sid,
  isNetworkOnlyCustomer,
}: LocationDisplayProps) {
  const { primary: primaryContract, secondary: secondaryContract } = usePrimarySecondaryContracts({
    contracts: contract?.companyContracts,
  });
  const primary = primaryContract?.internetServicePlan;
  const quoteRequestSid = primary?.quote?.quoteRequestSid;
  const quotesPath = useMemo(() => {
    if (!quoteRequestSid) {
      return undefined;
    }
    return getConnectUrl(
      document.location,
      PortalOrigin.Connect,
      `/quoteRequests/${quoteRequestSid}/results`,
    );
  }, [quoteRequestSid]);

  const { data: quotes } = useQuery<unknown, AxiosAPIError, QuotesRequest>(
    ['quoteRequests', sid],
    () => get<QuotesRequest>(`v1/connect/quote-requests/${quoteRequestSid}/quotes`),
    {
      enabled: !!quoteRequestSid,
    },
  );
  // @ts-ignore
  const minQuotes: Quote[] = useMemo(() => {
    const nestedQuotes = quotes?.quotes;
    if (!nestedQuotes) {
      return [];
    }
    // @ts-ignore
    const grouped = groupBy(nestedQuotes, (q) => q.connectionType.name);
    return Object.keys(grouped)
      .filter(Boolean)
      .sort()
      .map((key) => minBy(grouped[key], 'monthlyFeeCents'));
  }, [quotes]);

  const renameDialogProps = useDialogState();

  if (!contract) {
    return <LoadingLayout />;
  }
  const { companyLocation } = contract;
  if (!companyLocation) {
    return <LoadingLayout />;
  }

  const { location, name } = companyLocation;
  const address = location?.address;
  const { address1, address2, city, state } = address || {};

  return (
    <>
      <RenameLocation state={renameDialogProps.state} contract={contract} />
      <Pane>
        <PaneHeader icon="location" heading="Location" />
        <PaneContent>
          <Panes>
            <LeftPane>
              <HStack
                spacing={space(4)}
                align="center"
                justify="center"
                onClick={renameDialogProps.state.open}
              >
                <Body as={TopLabel} weight="bold">
                  {name || address1}
                </Body>
                <Icon color={{ light: 'gray600', dark: 'gray100' }} icon="pencil" size={16} />
              </HStack>
              <SummaryList>
                <SummaryListRow>
                  <SummaryListKey>Address</SummaryListKey>
                  <SummaryListValue>{address1}</SummaryListValue>
                </SummaryListRow>
                {address2 && (
                  <SummaryListRow>
                    <SummaryListKey>Unit</SummaryListKey>
                    <SummaryListValue>{address2}</SummaryListValue>
                  </SummaryListRow>
                )}
                <SummaryListRow>
                  <SummaryListKey>Region</SummaryListKey>
                  <SummaryListValue>
                    {city}, {state}
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
              {leftPane}
              {size(minQuotes) > 0 && (
                <Section relation="standalone">
                  <SectionHeader icon="globe" heading="Quotes" />
                  <SectionContent>
                    <SummaryList>
                      {minQuotes.map((minQuote) => {
                        const { connectionType, monthlyFeeCents } = minQuote;
                        return (
                          <SummaryListRow key={`quote-starting-at-${minQuote.sid}`}>
                            <SummaryListKey>{connectionType?.name}</SummaryListKey>
                            <SummaryListValue>
                              Starting at{' '}
                              {formatMoney({
                                amount: monthlyFeeCents / 100,
                                hideCentsIfZero: false,
                              })}
                            </SummaryListValue>
                          </SummaryListRow>
                        );
                      })}
                    </SummaryList>
                  </SectionContent>
                </Section>
              )}
              {quotesPath && (
                <Button
                  variant="secondary"
                  size="large"
                  type="button"
                  width="100%"
                  icon="chevron-right"
                  arrangement="leading-label"
                  target="_blank"
                  rel="noreferrer"
                  as="a"
                  href={quotesPath}
                >
                  View quotes
                </Button>
              )}
            </LeftPane>
            <RightPane>
              {primaryContract && (
                <ContractDetailBox
                  companyLocation={companyLocation}
                  // @ts-ignore
                  companySID={companySID}
                  contractResponse={primaryContract}
                  isPrimary
                  quoteRequestSID={quoteRequestSid}
                  renderNetworkDetails={renderNetworkDetails}
                  isNetworkOnlyCustomer={isNetworkOnlyCustomer}
                />
              )}
              {secondaryContract && (
                <ContractDetailBox
                  companyLocation={companyLocation}
                  // @ts-ignore
                  companySID={companySID}
                  contractResponse={secondaryContract}
                  isPrimary={false}
                  quoteRequestSID={quoteRequestSid}
                  renderNetworkDetails={renderNetworkDetails}
                />
              )}
            </RightPane>
          </Panes>
        </PaneContent>
      </Pane>
    </>
  );
}

import type { Key } from 'react';
import {
  Badge,
  BadgeContainer,
  ComboBox,
  ComboBoxItem,
  HStack,
  Label,
  SelectTrigger,
  space,
  styled,
  ToggleInput,
  useViewport,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';

import { paths } from '../constants';
import { getActiveControllerForNetwork } from '../hooks/useActiveControllerForNetwork';
import { useControllerDataFromPathOrNull } from '../hooks/useControllerDataFromPath';
import { useNetworkSlugFromPathOrNull } from '../hooks/useNetworkFromPath';
import { type Network, useNetworksForCompany } from '../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { getNetworkAddress } from '../utils/network';

const LocationSwitcherOptionContainer = styled(HStack, {
  width: '100%',
});

const LocationSwitcherLabelContainer = styled('span', {
  flex: '1 1 auto',
});

const LocationSwitcherContainer = styled('div', {
  display: 'flex',
  width: '100%',
  minWidth: 0,

  '@tablet': {
    padding: '$6 0',
  },

  [`& ${SelectTrigger} ${LocationSwitcherOptionContainer} ${BadgeContainer}`]: {
    display: 'none',
  },
});

export function LocationSwitcherOption({
  network,
  label,
}: {
  network: Pick<Network, 'label' | 'isActive' | 'isTemplate'>;
  label?: string;
}) {
  const displayLabel = label || network.label;

  return (
    <LocationSwitcherOptionContainer spacing={space(4)} justify="between">
      <LocationSwitcherLabelContainer>{displayLabel}</LocationSwitcherLabelContainer>

      {network.isTemplate && <Badge size="small">Template</Badge>}
      {!network.isActive && <Badge size="small">Inactive</Badge>}
    </LocationSwitcherOptionContainer>
  );
}

const LocationSwitcherInactiveToggle = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

const LocationSwitcherActions = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

export function LocationSwitcher({
  networkSlug: propNetworkSlug,
  shouldNotChangePages,
  routePathsNotToChange,
}: {
  routePathsNotToChange?: string[];
  networkSlug?: string;
  shouldNotChangePages?: boolean;
}) {
  const { breakpoint } = useViewport();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const isOperator = useIsOperator({ respectDemoMode: true });

  const [showInactiveNetworks, setShowInactiveNetworks] = useState<boolean>(false);

  const currentControllerFromPath = useControllerDataFromPathOrNull()?.controllerNameFromPath;
  const networkSlugFromPath = useNetworkSlugFromPathOrNull();
  const networks = useNetworksForCompany(companyName);
  const loc = useLocation();

  const networkSlug = useMemo<string | undefined>(() => {
    if (propNetworkSlug) return propNetworkSlug;

    if (networkSlugFromPath) return networkSlugFromPath;

    if (currentControllerFromPath && !propNetworkSlug) {
      // find the network that has the current controller
      const matchingNetwork = networks.find((network) =>
        network.virtualDevices?.some(
          (vd) => vd.hardwareDevice?.serialNumber === currentControllerFromPath,
        ),
      );
      if (matchingNetwork) {
        return matchingNetwork.slug;
      }
    }

    return undefined;
  }, [networks, propNetworkSlug, networkSlugFromPath, currentControllerFromPath]);

  const ref = useRef(null);

  const selectedNetwork = useMemo(
    () => networks.find((network) => network.slug === networkSlug),
    [networks, networkSlug],
  );

  useEffect(() => {
    if (!selectedNetwork) return;

    if (!selectedNetwork.isActive) {
      setShowInactiveNetworks(true);
    }
  }, [selectedNetwork]);

  const hasInactiveNetworks = useMemo(
    () => networks.some((n) => !n.isActive || n.isTemplate),
    [networks],
  );

  const networksToShow = useMemo(() => {
    let filteredNetworks = networks;
    if (!showInactiveNetworks) {
      filteredNetworks = filteredNetworks.filter(
        (network) => network.isActive && !network.isTemplate,
      );
    }

    return filteredNetworks;
  }, [networks, showInactiveNetworks]);

  // Specifically looking at all networks here, not filtered
  if (networks.length <= 1) return null;

  const valueSelected = (selectedNetworkSlug: Key) => {
    if (typeof selectedNetworkSlug !== 'string')
      throw new Error('Unexpected key for selectedNetworkSlug');

    const network = networks.find((n) => n.slug === selectedNetworkSlug);
    const controllerName = getActiveControllerForNetwork(network ?? null)?.hardwareDevice
      ?.serialNumber;

    if (shouldNotChangePages && routePathsNotToChange?.length) {
      const pageMatch = routePathsNotToChange
        ?.map((path) =>
          matchPath(
            {
              path,
              end: false,
            },
            loc.pathname,
          ),
        )
        .filter((match) => match);
      const currentPage = pageMatch[0];
      if (currentPage && currentPage?.params) {
        navigate(
          makeLink(currentPage.pattern.path, {
            ...currentPage.params,
            networkSlug: selectedNetworkSlug,
            controllerName,
          }),
        );
      }
    } else {
      navigate(
        makeLink(paths.pages.IndividualNetworkRootPage, {
          networkSlug: selectedNetworkSlug,
          companyName,
        }),
      );
    }
  };

  const sortedNetworks = networksToShow
    .map((network) => {
      const networkAddress = getNetworkAddress(network);

      const label = networkAddress ? `[${network.label}] ${networkAddress}` : network.label;
      return { label, network };
    })
    .sort((a, b) => {
      const strippedA = a.label.replace(/\W/g, '');
      const strippedB = b.label.replace(/\W/g, '');
      return strippedA.localeCompare(strippedB);
    });

  return (
    <LocationSwitcherContainer>
      <ComboBox
        ref={ref}
        defaultItems={sortedNetworks}
        icon={breakpoint !== 'tablet' ? 'location' : undefined}
        onValueChange={valueSelected}
        value={networkSlug}
        size="large"
        aria-label="Switch location"
        canClearValue={false}
        placeholder="Select a location"
        actions={
          (isOperator || (selectedNetwork && !selectedNetwork.isActive)) && hasInactiveNetworks ? (
            <LocationSwitcherActions>
              <LocationSwitcherInactiveToggle>
                <Label htmlFor="LocationSwitch-toggleActive">Show inactive networks</Label>
                <ToggleInput
                  id="LocationSwitch-toggleActive"
                  selected={showInactiveNetworks}
                  onChange={setShowInactiveNetworks}
                  controlSize="small"
                />
              </LocationSwitcherInactiveToggle>
            </LocationSwitcherActions>
          ) : null
        }
      >
        {({ label, network }) => (
          <ComboBoxItem key={network.slug} textValue={label}>
            <LocationSwitcherOption network={network} label={label} />
          </ComboBoxItem>
        )}
      </ComboBox>
    </LocationSwitcherContainer>
  );
}

import {
  Body,
  Button,
  colors,
  darkThemeSelector,
  Heading,
  HStack,
  Pane,
  PaneContent,
  PaneFooter,
  PaneHeader,
  Small,
  space,
  styled,
  Tab,
  Tabs,
  VStack,
} from '@meterup/atto';
import React, { useState } from 'react';

import type { ErrorFallbackProps } from './utils';

const ErrorHeading = styled(Heading, {
  color: colors.red800,
});
ErrorHeading.displayName = 'ErrorHeading';

const CodeBlock = styled('pre', Body, {
  padding: '$16',
  backgroundColor: colors.gray50,
  overflow: 'auto',
  borderRadius: '$4',
  vStack: '$8',
  alignItems: 'flex-start',
  [darkThemeSelector]: {
    backgroundColor: colors.gray800,
  },
});
CodeBlock.displayName = 'CodeBlock';

const DevelopmentErrorPane = styled(Pane, {
  padding: '$0',
});
DevelopmentErrorPane.displayName = 'DevelopmentErrorPane';

const HeadingWrapper = styled(VStack, {
  paddingY: '$8',
});
HeadingWrapper.displayName = 'HeadingWrapper';

export function DevelopmentErrorFallback({
  error,
  componentStack,
  resetError,
}: ErrorFallbackProps) {
  const [currentStack, setCurrentStack] = useState<'error' | 'component'>('error');
  return (
    <DevelopmentErrorPane variant="full">
      <PaneHeader
        heading={
          <HeadingWrapper spacing={space(8)}>
            <HStack spacing={space(4)} align="baseline">
              <ErrorHeading size="20">Uncaught error:</ErrorHeading>
              <ErrorHeading family="monospace" size="20">
                {error.name}
              </ErrorHeading>
            </HStack>
            <HStack spacing={space(4)}>
              <Heading size="14">Message:</Heading>
              <Heading size="14" family="monospace">
                {error.message}
              </Heading>
            </HStack>
            <Button type="button" onClick={resetError} variant="secondary">
              Reset error
            </Button>
          </HeadingWrapper>
        }
        tabs={
          <Tabs>
            <Tab selected={currentStack === 'error'} onClick={() => setCurrentStack('error')}>
              Stack Trace
            </Tab>
            <Tab
              selected={currentStack === 'component'}
              onClick={() => setCurrentStack('component')}
            >
              Component Stack
            </Tab>
          </Tabs>
        }
      />
      <PaneContent>
        {currentStack === 'error' ? (
          <CodeBlock family="monospace">{error.stack}</CodeBlock>
        ) : (
          <CodeBlock family="monospace">{componentStack}</CodeBlock>
        )}
      </PaneContent>
      <PaneFooter
        actions={<Small> This error would be logged to Sentry in staging or production.</Small>}
      />
    </DevelopmentErrorPane>
  );
}

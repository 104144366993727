import { Pane, PaneContent, space, Tab } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import {
  checkDefinedOrThrow,
  DetailHeader,
  expectDefinedOrThrow,
  ResourceNotFoundError,
} from '@meterup/common';
import { useNavigate } from 'react-router';

import { paths } from '../../constants';
import { useActiveControllerForNetwork } from '../../hooks/useActiveControllerForNetwork';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import APInsights from './APInsights';
import ClientInsights from './ClientInsights';
import RFInsights from './RFInsights';

function InsightsTabs() {
  const { tab } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.LegacyInsightsPage));
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const isOperator = useIsOperator({ respectDemoMode: true });

  const navigateToTab = (newTab: string) => {
    navigate(
      makeLink(paths.pages.LegacyInsightsPage, {
        companyName,
        networkSlug: network.slug,
        tab: newTab,
      }),
    );
  };

  return (
    <>
      {(isOperator || flags['clients-chart']) && (
        <Tab
          icon="client"
          selected={tab === 'clients'}
          onClick={() => navigateToTab('clients')}
          internal={!flags['clients-chart']}
        >
          Clients
        </Tab>
      )}
      {(isOperator || flags['ap-charts']) && (
        <Tab
          icon="priority-medium"
          selected={tab === 'ap'}
          onClick={() => navigateToTab('ap')}
          internal={!flags['ap-charts']}
        >
          Access points
        </Tab>
      )}
      {(isOperator || flags['rf-utilization-chart']) && (
        <Tab
          icon="priority-medium"
          selected={tab === 'rf'}
          onClick={() => navigateToTab('rf')}
          internal={!flags['rf-utilization-chart']}
        >
          RF utilization
        </Tab>
      )}
    </>
  );
}

function InsightsView() {
  const { tab } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.LegacyInsightsPage));
  const network = useNetwork();
  const activeController = useActiveControllerForNetwork(network);
  expectDefinedOrThrow(
    activeController?.hardwareDevice?.serialNumber,
    new ResourceNotFoundError('Security appliance not found'),
  );

  return (
    <Pane>
      <DetailHeader icon="reporting" heading="Wireless insights" tabs={<InsightsTabs />} />
      <PaneContent spacing={space(16)}>
        {tab === 'clients' && <ClientInsights />}
        {tab === 'ap' && <APInsights />}
        {tab === 'rf' && <RFInsights />}
      </PaneContent>
    </Pane>
  );
}

export default InsightsView;

import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { useNetwork } from '../../../hooks/useNetworkFromPath';

export function useAccessPointsQuery({
  dataQuery,
  dataPoint,
}: {
  dataQuery: any;
  dataPoint: string;
}) {
  const network = useNetwork();
  const result = useGraphQL(dataQuery, { networkUUID: network.UUID });
  const queriedData = result.data ? (result.data as Record<string, any>)[dataPoint] : undefined;
  expectDefinedOrThrow(queriedData, new ResourceNotFoundError(`Unable to load ${dataPoint}`));
  return queriedData;
}

import type { z } from 'zod';
import {
  Alert,
  CompositeField,
  FieldContainer,
  PrimaryField,
  SecondaryField,
  SecondaryFieldComposite,
  Section,
  SectionContent,
  SectionHeader,
  Segment,
  Segments,
  Select,
  SelectItem,
  space,
  styled,
  TextInput,
  ToggleInput,
  VStack,
} from '@meterup/atto';
import { useFormikContext } from 'formik';
import { range } from 'lodash-es';

import type { CreateRadioProfileInputSchema } from '../../../gql/zod-types';
import type { ValidRadioProfileParams } from './utils';
import { RadioBand } from '../../../gql/graphql';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { FieldProvider, NumberFieldProvider } from '../../Form/FieldProvider';
import { FormikConditional } from '../../FormikConditional';
import ChannelSelection from './channelSelectionFields';
import { radioBandToField, radioBandToHuman } from './utils';

export function NameField() {
  return (
    <FieldContainer>
      <FieldProvider name="name">
        <PrimaryField label="Name" element={<TextInput />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export type BooleanToggleFieldProps = {
  field:
    | 'band2_4GAutoChannelIsEnabled'
    | 'band2_4GAutoChannelWidthIsEnabled'
    | 'band2_4GAutoTxPowerIsEnabled'
    | 'band5GAutoChannelIsEnabled'
    | 'band5GAutoTxPowerIsEnabled'
    | 'band5GAutoChannelWidthIsEnabled';
};

export function AutoManualToggleField({ field }: BooleanToggleFieldProps) {
  const { values, setFieldValue } =
    useFormikContext<z.infer<typeof CreateRadioProfileInputSchema>>();

  const handleClick = (val: Boolean) => {
    setFieldValue(field, val);
  };

  return (
    <Segments size="small">
      <Segment active={values[field] === true} onClick={() => handleClick(true)}>
        Auto
      </Segment>
      <Segment active={values[field] !== true} onClick={() => handleClick(false)}>
        Manual
      </Segment>
    </Segments>
  );
}

export type SectionToggleFieldProps = {
  field: 'band2_4GIsEnabled' | 'band5GIsEnabled';
};

export function SectionToggleField({ field }: SectionToggleFieldProps) {
  const { values, setFieldValue } =
    useFormikContext<z.infer<typeof CreateRadioProfileInputSchema>>();

  const handleChange = (selected: Boolean) => {
    setFieldValue(field, selected);
  };

  return (
    <ToggleInput
      aria-label={`Toggle ${field}`}
      selected={values[field] === true}
      onChange={handleChange}
    />
  );
}

export type BandSectionProps = {
  band: RadioBand;
};

type BandFieldProps = {
  band: RadioBand;
  autoEnabled: boolean;
};

const ChannelWidthSelector = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$8',
});

function ChannelWidthField({ autoEnabled, band }: BandFieldProps) {
  const channelWidths = [20];
  if (band === RadioBand.Band_5G) {
    channelWidths.push(40, 80);
  }

  const fieldName: 'band5GAutoChannelWidthIsEnabled' | 'band2_4GAutoChannelWidthIsEnabled' =
    `${radioBandToField(band)}AutoChannelWidthIsEnabled`;

  if (!autoEnabled) {
    return (
      <NumberFieldProvider name={`${radioBandToField(band)}ChannelWidthMHz`} defaultValue={null}>
        <SecondaryField
          label="Channel width"
          element={
            <Select aria-label={`${band} GHz channel width`} width="100%">
              {channelWidths.map((w) => (
                <SelectItem textValue={`${w.toString()} MHz`} key={w.toString()}>
                  {w.toString()} MHz
                </SelectItem>
              ))}
            </Select>
          }
        />
      </NumberFieldProvider>
    );
  }

  return (
    <SecondaryFieldComposite
      label="Channel width"
      fields={
        <ChannelWidthSelector>
          <AutoManualToggleField field={fieldName} />
          <FormikConditional<ValidRadioProfileParams>
            condition={(values) => values[fieldName] === false}
          >
            <NumberFieldProvider
              name={`${radioBandToField(band)}ChannelWidthMHz`}
              defaultValue={null}
            >
              <CompositeField
                label={null}
                element={
                  <Select aria-label={`${band} GHz channel width`} width="100%">
                    {channelWidths.map((w) => (
                      <SelectItem textValue={`${w.toString()} MHz`} key={w.toString()}>
                        {w.toString()} MHz
                      </SelectItem>
                    ))}
                  </Select>
                }
              />
            </NumberFieldProvider>
          </FormikConditional>
        </ChannelWidthSelector>
      }
    />
  );
}

function ChannelSelectionAndChannelWidthFields({
  autoEnabled,
  band,
  autoChannelWidthEnabled,
}: BandFieldProps & { autoChannelWidthEnabled: boolean }) {
  const { errors } = useFormikContext<ValidRadioProfileParams>();

  const fieldName: 'band5GAutoChannelIsEnabled' | 'band2_4GAutoChannelIsEnabled' =
    `${radioBandToField(band)}AutoChannelIsEnabled`;

  // If ACS is disabled, we only need to render the channel width field. We currently don't support
  // ACS for 2.4, so we only render the channel width field.
  if (!autoEnabled || band === RadioBand.Band_2_4G)
    return (
      <FieldContainer>
        <ChannelWidthField autoEnabled={false} band={band} />
      </FieldContainer>
    );

  return (
    <FieldContainer>
      <PrimaryField
        label="Channel assignment"
        element={<AutoManualToggleField field={fieldName} />}
      />
      <ChannelWidthField autoEnabled={autoChannelWidthEnabled} band={band} />
      <FormikConditional<ValidRadioProfileParams>
        condition={(values) => values[fieldName] === true}
      >
        <SecondaryField label="Allowed channels" element={<ChannelSelection band={band} />} />
      </FormikConditional>
      {errors && errors[fieldName] && (
        <Alert type="inline" relation="stacked" variant="negative" heading={errors[fieldName]} />
      )}
    </FieldContainer>
  );
}

function TransmitPowerfield({ autoEnabled, band }: BandFieldProps) {
  if (!autoEnabled) return null;

  const validPowers = range(1, 21);

  const fieldName: 'band5GAutoTxPowerIsEnabled' | 'band2_4GAutoTxPowerIsEnabled' =
    `${radioBandToField(band)}AutoTxPowerIsEnabled`;

  return (
    <FieldContainer>
      <PrimaryField
        element={<AutoManualToggleField field={fieldName} />}
        label="Radio transmit power range"
      />
      <FormikConditional<ValidRadioProfileParams>
        condition={(values) => values[fieldName] === true}
      >
        <NumberFieldProvider
          name={`${radioBandToField(band)}AutoTxPowerMindBm`}
          defaultValue={null}
        >
          <SecondaryField
            label="Min power"
            element={
              <Select aria-label={`${radioBandToHuman(band)} min tx power`} width="100%">
                {validPowers.map((w) => (
                  <SelectItem textValue={`${w.toString()} dBm`} key={w.toString()}>
                    {w.toString()} dBm
                  </SelectItem>
                ))}
              </Select>
            }
          />
        </NumberFieldProvider>

        <NumberFieldProvider
          name={`${radioBandToField(band)}AutoTxPowerMaxdBm`}
          defaultValue={null}
        >
          <SecondaryField
            label="Max power"
            element={
              <Select aria-label={`${radioBandToHuman(band)} max tx power`} width="100%">
                {validPowers.map((w) => (
                  <SelectItem textValue={`${w.toString()} dBm`} key={w.toString()}>
                    {w.toString()} dBm
                  </SelectItem>
                ))}
              </Select>
            }
          />
        </NumberFieldProvider>
      </FormikConditional>
    </FieldContainer>
  );
}

export function BandSection({ band }: BandSectionProps) {
  const [acs] = useNosFeaturesEnabled([NosFeature.WOS_ACS]);

  const enabledField: 'band2_4GIsEnabled' | 'band5GIsEnabled' =
    `${radioBandToField(band)}IsEnabled`;

  return (
    <Section relation="stacked">
      <SectionHeader
        icon="wifi"
        heading={radioBandToHuman(band)}
        actions={<SectionToggleField field={enabledField} />}
      />

      <FormikConditional<ValidRadioProfileParams>
        condition={(values) => values[enabledField] === true}
      >
        <SectionContent gutter="all">
          <VStack spacing={space(16)}>
            <ChannelSelectionAndChannelWidthFields
              band={band}
              autoEnabled={acs}
              autoChannelWidthEnabled={false}
            />
            <TransmitPowerfield band={band} autoEnabled={false} />
          </VStack>
        </SectionContent>
      </FormikConditional>
    </Section>
  );
}

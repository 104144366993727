import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Button, Pane, PaneContent, PaneHeader, Tab } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { Suspense, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';

import { KeyBoundSearchInput } from '../../../../components/KeyBoundSearchInput';
import NOSFeaturesList from '../../../../components/NOS/NOSFeaturesList';
import NOSVersionsList from '../../../../components/NOS/NOSVersionsList';
import { DetailLoadingFallback } from '../../../../components/Placeholders/DetailLoadingFallback';
import { ReactRouterLink } from '../../../../components/ReactRouterLink';
import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';
import { useSearchParamsState } from '../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/operator/nos/:tab',
  layout: 'OperatorLayout',
  title: 'NOS config - operator',
});

export enum ValidNOSTabs {
  Versions = 'versions',
  Features = 'features',
}

function NOSTabs({
  activeTab,
  setActiveTab,
}: {
  activeTab: ValidNOSTabs;
  setActiveTab: (val: ValidNOSTabs) => void;
}) {
  return (
    <>
      <Tab
        icon="version"
        selected={activeTab === ValidNOSTabs.Versions}
        onClick={() => setActiveTab(ValidNOSTabs.Versions)}
      >
        Versions
      </Tab>
      <Tab
        icon="flag"
        selected={activeTab === ValidNOSTabs.Features}
        onClick={() => setActiveTab(ValidNOSTabs.Features)}
      >
        Features
      </Tab>
    </>
  );
}

function NOSActions({ tab }: { tab: ValidNOSTabs }) {
  return (
    <>
      {tab === ValidNOSTabs.Versions && (
        <Button
          condense
          size="small"
          as={ReactRouterLink}
          to={makeDrawerLink(window.location, paths.drawers.NOSVersionDetailPage, {
            nosVersionID: 'create',
          })}
          variant="secondary"
          arrangement="leading-icon"
          icon="plus"
        >
          Add NOS version
        </Button>
      )}
      {tab === ValidNOSTabs.Features && (
        <Button
          condense
          size="small"
          as={ReactRouterLink}
          to={makeDrawerLink(window.location, paths.drawers.NOSFeatureDetailPage, {
            uuid: 'create',
          })}
          variant="secondary"
          arrangement="leading-icon"
          icon="plus"
        >
          Add NOS feature
        </Button>
      )}
      <KeyBoundSearchInput
        searchParamStateKey="filter"
        placeholder="..."
        aria-label={`Filter NOS ${tab}`}
        scope="scoped"
        minWidth="56px"
      />
    </>
  );
}

export default function NOSPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  const { tab } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.NOSPage)) as {
    tab: ValidNOSTabs;
  };
  if (!Object.values(ValidNOSTabs).includes(tab)) {
    throw new ResourceNotFoundError('Page not found');
  }

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const paneContentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const navigateToTab = useCallback(
    (newTab: ValidNOSTabs) => {
      navigate(
        makeLink(paths.pages.NOSPage, {
          tab: newTab,
        }),
      );
    },
    [navigate],
  );

  return (
    <Pane>
      <PaneHeader
        heading="NOS config"
        tabs={<NOSTabs activeTab={tab} setActiveTab={navigateToTab} />}
        actions={<NOSActions tab={tab} />}
      />
      <PaneContent gutter="bottom" ref={paneContentRef}>
        <Suspense fallback={<DetailLoadingFallback />}>
          {tab === ValidNOSTabs.Versions && (
            <NOSVersionsList globalFilter={globalFilter} containerRef={paneContentRef} />
          )}

          {tab === ValidNOSTabs.Features && (
            <NOSFeaturesList globalFilter={globalFilter} containerRef={paneContentRef} />
          )}
        </Suspense>
      </PaneContent>
    </Pane>
  );
}

import ISPs from '../../../../../components/NetworkWide/ISPs/ISPs';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/isps',
  layout: 'VirtualNetworkLayout',
  title: 'ISPs - Network-wide',
});

export default function ISPsPage() {
  return <ISPs />;
}

import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import React, { Suspense, useMemo } from 'react';

import { DrawerLoadingFallback } from '../../../../../components/Placeholders/DrawerLoadingFallback';
import { useParsedEventLogEvents } from '../../../../../components/useEventLogEvents';
import { paths } from '../../../../../constants';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import EventSummaryDrawer from './EventSummaryDrawer';

export function Meta() {
  return {
    path: '/org/:companyName/network/:networkSlug/insights/events/:uuid',
  };
}

export default function EventLogSummaryPage() {
  const network = useNetwork();
  const events = useParsedEventLogEvents({ networkUUID: network.UUID });
  const uuid = Nav.useRegionParams('drawer', paths.drawers.EventLogSummaryPage)?.uuid;
  const event = checkDefinedOrThrow(
    useMemo(() => {
      if (!events.length) return undefined;
      return events.find((e) => e.UUID === uuid);
    }, [events, uuid]),
    new ResourceNotFoundError('Event not found'),
  );

  return (
    <Suspense fallback={<DrawerLoadingFallback />}>
      <EventSummaryDrawer event={event} />
    </Suspense>
  );
}

import { Badge, Button, EmptyState, HStack, space, Tooltip } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { AlertTargetType } from '@meterup/common/src/gql/graphql';
import { Link } from 'react-router-dom';

import type { AlertReceiver } from '../utils';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import {
  useCurrentCompany,
  useCurrentCompany2,
} from '../../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { createColumnBuilder } from '../../../../Table/createColumnBuilder';
import { useAlertReceivers } from '../utils';

function TargetsGroup({ targets }: { targets: AlertReceiver['targets'] }) {
  return (
    <HStack align="center" spacing={space(4)} wrap="no-wrap">
      {targets.map((target) => (
        <Tooltip
          key={target.UUID}
          contents={
            <HStack align="center" spacing={space(4)} wrap="wrap">
              <Badge key={target.UUID} size="small" variant="neutral">
                {target.__typename === 'AlertTargetEmail' ? target.emailAddress : target.url}
              </Badge>
            </HStack>
          }
        >
          <Badge
            key={target.UUID}
            arrangement="leading-icon"
            icon={target.type === AlertTargetType.Email ? 'email' : 'chat'}
            size="small"
            variant="neutral"
          >
            {target.__typename === 'AlertTargetEmail' ? target.emailAddress : target.label}
          </Badge>
        </Tooltip>
      ))}
    </HStack>
  );
}

const builder = createColumnBuilder<AlertReceiver>();

const columns = [
  builder.data((row) => row.label, {
    id: 'label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
  }),
  builder.display({
    id: 'targets',
    header: 'Targets',
    cell: ({ row }) => TargetsGroup({ targets: row.targets }),
  }),
];

export default function Receivers() {
  const companyName = useCurrentCompany();
  const company = useCurrentCompany2();
  const receivers = useAlertReceivers({ companyUUID: company?.uuid });
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ReceiverEditPage);

  return receivers.length > 0 ? (
    <AutoTable
      columns={columns}
      data={receivers}
      isRowSelected={(row) => row.UUID === drawerParams?.uuid}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.ReceiverEditPage, {
          companyName,
          uuid: row.UUID,
        })
      }
    />
  ) : (
    <EmptyState
      icon="tag"
      heading="No receivers"
      action={
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.ReceiversAddPage, {
            companyName,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add receiver
        </Button>
      }
    />
  );
}

import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  space,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { checkDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { useCallback } from 'react';

import { paths } from '../../constants';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { useSafeCloseDrawer } from '../NetworkWide/CaptivePortals/utils';
import { ReactRouterLink } from '../ReactRouterLink';
import {
  deleteCompanyUser,
  reactivateCompanyUser,
  useCompanyUser,
  useInvalidateCompanyUsers,
} from './utils/queries';
import { userDisplay } from './utils/utils';

export default function UserActions({ uuid }: { uuid: string }) {
  const companySlug = useCurrentCompany();
  const data = useCompanyUser(uuid);
  const closeDrawer = useSafeCloseDrawer();
  const companyUser = checkDefinedOrThrow(
    data.data?.companyUser,
    new ResourceNotFoundError('User not found'),
  );
  const user = checkDefinedOrThrow(companyUser.user, new ResourceNotFoundError('User not found'));

  const dialogPropsDeactivate = useDialogState();
  const dialogPropsReactivate = useDialogState();

  const deleteMutation = useGraphQLMutation(deleteCompanyUser);
  const invalidateCompanyUsers = useInvalidateCompanyUsers(companySlug);
  const onDeactivateUser = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      deleteMutation.mutate(
        { uuid },
        {
          onSuccess() {
            notify('User removed', { variant: 'positive' });
            invalidateCompanyUsers();
            closeDrawer();
          },
          onError() {
            notify('Failed to remove user', { variant: 'negative' });
          },
        },
      );
    },
    [closeDrawer, deleteMutation, invalidateCompanyUsers, uuid],
  );

  const activateMutation = useGraphQLMutation(reactivateCompanyUser);
  const onReactivateUser = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      activateMutation.mutate(
        { uuid },
        {
          onSuccess() {
            notify('User reactivated', { variant: 'positive' });
            invalidateCompanyUsers();
            closeDrawer();
          },
          onError() {
            notify('Failed to reactivate user', { variant: 'negative' });
          },
        },
      );
    },
    [activateMutation, closeDrawer, invalidateCompanyUsers, uuid],
  );
  return (
    <>
      <Button
        as={ReactRouterLink}
        to={makeDrawerLink(window.location, paths.drawers.EditUserPage, {
          companyName: companySlug,
          uuid,
        })}
        arrangement="leading-icon"
        icon="pencil"
        size="small"
        variant="secondary"
      >
        Edit
      </Button>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          {companyUser.deletedAt ? (
            <DropdownMenuItem onSelect={dialogPropsReactivate.openFromMenu} icon="reset">
              Enable
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem onSelect={dialogPropsDeactivate.openFromMenu} icon="trash-can">
              Disable
            </DropdownMenuItem>
          )}
        </DropdownMenuPopover>
      </DropdownMenu>
      <Dialog state={dialogPropsDeactivate.state} preset="narrow">
        <DialogHeader icon="trash-can" heading="Deactive user" />
        <DialogContent gutter="all">
          <VStack spacing={space(12)}>
            <Alert
              icon="attention"
              variant="negative"
              heading="Deactivating a user will immediately remove their access to all company resources."
            />
            <Body>
              Are you sure you want to deactivate <strong>{userDisplay(user)}</strong>?
            </Body>
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={dialogPropsDeactivate.state.close}>
                Cancel
              </Button>
              <Button variant="destructive" type="button" onClick={onDeactivateUser}>
                Delete
              </Button>
            </>
          }
        />
      </Dialog>
      <Dialog state={dialogPropsReactivate.state} preset="narrow">
        <DialogHeader icon="reset" heading="Reactivate user" />
        <DialogContent gutter="all">
          <VStack spacing={space(12)}>
            <Alert
              icon="attention"
              variant="negative"
              heading="Reactivating a user will immediately restore their access to all company resources."
            />
            <Body>
              Are you sure you want to reactivate <strong>{userDisplay(user)}</strong>?
            </Body>
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={dialogPropsReactivate.state.close}>
                Cancel
              </Button>
              <Button variant="destructive" type="button" onClick={onReactivateUser}>
                Confirm
              </Button>
            </>
          }
        />
      </Dialog>
    </>
  );
}

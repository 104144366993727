import type { ExportableToCSV } from '@meterup/common';
import { useRef } from 'react';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature, useNosFeaturesEnabled } from '../../../../../hooks/useNosFeatures';
import { ClientsList2 } from './ClientsList2';
import ClientsListHeader2 from './ClientsListHeader2';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/clients',
  layout: 'VirtualNetworkLayout',
  title: 'Clients - Insights',
});

export default function ClientsList2Page() {
  const tableRef = useRef<ExportableToCSV>(null);

  const exportCSV = () => {
    tableRef.current?.exportToCSV();
  };

  const [isWOS2Enabled] = useNosFeaturesEnabled([NosFeature.WOS2]);

  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermNetworkDevicesRead)) && isWOS2Enabled
      }
      should404OnAccessDenied
    >
      <DetailPageLayout
        header={
          <ClientsListHeader2
            networkClientOptions={{ filter: { includeThroughput: false } }}
            exportToCSV={exportCSV}
          />
        }
        main={
          <ClientsList2
            networkClientOptions={{ filter: { includeThroughput: false } }}
            tableRef={tableRef}
          />
        }
      />
    </IsPermitted>
  );
}

import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import * as d3 from 'd3';
import { Suspense, useMemo } from 'react';

import { graphql } from '../../gql';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import {
  apUptimeMetricsToSeries,
  ChartsFallbackContainer,
  durationValueFormatter,
  getDurationSeconds,
  getStep,
} from '../../utils/chart_utils';
import ChartFilter from '../ChartFilter';
import TimeSeriesChart from '../Charts/TimeSeriesChart';
import { StackedSkeletons } from '../Placeholders/AppLoadingFallback';

const APUptimeQuery = graphql(`
  query APUptime($networkUUID: UUID!, $filter: MetricsFilterInput!) {
    apUptime(networkUUID: $networkUUID, filter: $filter) {
      metadata {
        minValue
        maxValue
      }
      values {
        timestamp
        value
        apName
      }
    }
  }
`);

function APMetricGraphs({
  networkUUID,
  duration,
  step,
  currentTimePeriod,
}: {
  networkUUID: string;
  duration: number;
  step: number;
  currentTimePeriod: string;
}) {
  const { data: metricsData } = useGraphQL(APUptimeQuery, {
    networkUUID,
    filter: {
      durationSeconds: duration,
      stepSeconds: step,
    },
  });

  expectDefinedOrThrow(metricsData, new ResourceNotFoundError('Unable to load metrics'));

  const series = useMemo(
    () => apUptimeMetricsToSeries(metricsData?.apUptime?.values),
    [metricsData?.apUptime?.values],
  );

  return (
    <TimeSeriesChart
      curve={d3.curveLinear}
      series={series}
      seriesType="line"
      seriesLabel="access-point-uptime"
      showSeriesGlyphs={false}
      timePeriod={currentTimePeriod}
      title="Uptime"
      tooltipBody="Measures the uptime of an access point."
      valueFormatter={durationValueFormatter}
    />
  );
}

function APInsights() {
  const network = useNetwork();
  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const queryClient = useQueryClient();

  const duration = useMemo(() => getDurationSeconds(currentTimePeriod), [currentTimePeriod]);
  const step = useMemo(() => getStep(currentTimePeriod), [currentTimePeriod]);

  const refetchMetrics = () => {
    queryClient.invalidateQueries(
      makeQueryKey(APUptimeQuery, {
        networkUUID: network.UUID,
        filter: {
          durationSeconds: duration,
          stepSeconds: step,
        },
      }),
    );
  };

  return (
    <>
      <ChartFilter
        currentTimePeriod={currentTimePeriod}
        setCurrentTimePeriod={setCurrentTimePeriod}
        refetchMetrics={refetchMetrics}
      />
      <Suspense
        fallback={
          <ChartsFallbackContainer>
            <StackedSkeletons />
          </ChartsFallbackContainer>
        }
      >
        <APMetricGraphs
          networkUUID={network.UUID}
          duration={duration}
          step={step}
          currentTimePeriod={currentTimePeriod}
        />
      </Suspense>
    </>
  );
}

export default APInsights;

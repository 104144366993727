import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import DeviceConfigEditor from './DeviceConfigEditor';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/operators/config/:serialNumber/overrides',
});

export default function DeviceConfigOverridesPage() {
  return <DeviceConfigEditor />;
}

import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import { graphql } from '../../gql';
import { aggregateStatsForNetworks } from './utils/aggregateStatsForNetworks';

export const hubNetworksOverviewQuery = graphql(`
  query HubNetworksOverview($companySlug: String!) {
    networksForCompany(companySlug: $companySlug) {
      slug
      label
      UUID
      isActive
      isTemplate
      mailingAddress {
        line1
        line2
        city
        subdivisionCode
        postalCode
        countryISO2
        timezone
        coordinates {
          latitude
          longitude
        }
      }
      virtualDevices {
        UUID
        deviceType
        hardwareDevice {
          isConnectedToBackend
        }
      }
    }
  }
`);

export function useHubNetworksOverview(companySlug?: string | null) {
  const resp = useGraphQL(
    hubNetworksOverviewQuery,
    { companySlug: companySlug as string },
    { enabled: !!companySlug },
  );

  // apiData is stable, so we pass that into useMemo
  const apiData = resp.data?.networksForCompany;

  return useMemo(
    () =>
      aggregateStatsForNetworks({
        locations: apiData ?? [],
        tagGroups: [],
      }),
    [apiData],
  );
}

import { useIsOperator } from '@meterup/authorization';
import { Navigate } from 'react-router';

import ServiceDiscovery from '../../../../components/Settings/Network/ServiceDiscovery/ServiceDiscovery';
import { paths } from '../../../../constants';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags';
import { useNetworkForSettingsPath } from './NetworkUtils';

export const Meta = () => ({
  path: '/org/:companyName/settings/network/:networkSlug/service-discovery',
  title: 'Service discovery - Network',
});

export default function SettingsNetworkServiceDiscoveryPage() {
  const isOperator = useIsOperator();
  const featureFlags = useFeatureFlags();
  const selectedNetwork = useNetworkForSettingsPath({
    path: paths.pages.SettingsNetworkServiceDiscoveryPage,
  });

  if (!isOperator && !featureFlags['service-discovery']) {
    return <Navigate to={paths.pages.SettingsNetworkGeneralPage} />;
  }

  return <ServiceDiscovery network={selectedNetwork} />;
}

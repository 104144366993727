import { Badge, EmptyState } from '@meterup/atto';
import { AutoTable } from '@meterup/common';

import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { screamingSnakeCaseToWords } from '../../../utils/strings';
import { createColumnBuilder } from '../../Table/createColumnBuilder';

const builder = createColumnBuilder<any>();

const columns = [
  builder.data((row) => row.event, {
    id: 'event',
    header: 'Event',
    meta: {
      isLeading: true,
    },
    cell: ({ row }) => row.event,
  }),
  builder.data((row) => row.severity, {
    id: 'severity',
    header: 'Severity',
    cell: ({ row }) => (
      <Badge size="small" variant={row.severity === 'high' ? 'negative' : 'attention'}>
        {screamingSnakeCaseToWords(row.severity)}
      </Badge>
    ),
  }),
  builder.data((row) => row.timestamp, {
    id: 'timestamp',
    header: 'Timestamp',
    meta: {
      alignment: 'end',
    },
  }),
];

const data = [
  {
    event: 'WAN saturated',
    severity: 'medium',
    timestamp: 'Jul 9, 2024, 11:19:08 AM',
  },
  {
    event: 'Outage detected',
    severity: 'high',
    timestamp: 'Jun 30, 2024, 02:04:58 PM',
  },
  {
    event: 'Blips of degradation',
    severity: 'medium',
    timestamp: 'Apr 12, 2024, 09:34:43 AM',
  },
  {
    event: 'SLA not met',
    severity: 'high',
    timestamp: 'Mar 23, 2024, 05:22:11 PM',
  },
];

export default function ISPEvents({ uuid }: { uuid: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return data.length > 0 ? (
    <AutoTable
      key="alerts-table"
      columns={columns}
      data={data}
      getLinkTo={() =>
        makeDrawerLink(window.location, paths.drawers.ISPEventDrawerPage, {
          companyName,
          networkSlug: network.slug,
          uuid,
          eventUUID: '8eb4e226-3df5-4b5c-be46-f90f425e04da',
        })
      }
    />
  ) : (
    <EmptyState icon="log" heading="No events" />
  );
}

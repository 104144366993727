import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import React from 'react';
import { Navigate, Outlet } from 'react-router';

import { DockPageLayout } from '../../../components/DockPageLayout';
import { MainAndDrawerLayout } from '../../../components/MainAndDrawerLayout';
import { DrawerRoutes } from '../../../components/route_elements';
import { paths } from '../../../constants';
import { ProductTypes, useUserProductsAccess } from '../../../hooks/useUserProductsAccess';

export const Meta: PagefileMetaFn = () => ({
  name: 'ConnectLayout',
  layout: 'AppLayout',
  title: 'Connect',
});

export default function ConnectLayout() {
  const access = useUserProductsAccess();
  if (!access.hasAccessToProduct(ProductTypes.CONNECT)) {
    return <Navigate to={paths.pages.RootPage} />;
  }

  return (
    <DockPageLayout main={<MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />} />
  );
}

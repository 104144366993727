import { createContext, useContext } from 'react';

export type WorkbenchSideProp = 'bottom' | 'end';

const WorkbenchSideContext = createContext<WorkbenchSideProp | undefined>(undefined);

export const WorkbenchSideProvider = WorkbenchSideContext.Provider;

export const useWorkbenchSide = (
  controlledValue?: WorkbenchSideProp,
  defaultValue: WorkbenchSideProp = 'bottom',
) => {
  const side = useContext(WorkbenchSideContext);
  return controlledValue ?? side ?? defaultValue;
};

import {
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  HStack,
  Pane,
  PaneContent,
  PaneHeader,
  SearchInput,
  space,
  Text,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { AutoTable, notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import type { ClientVPNServer } from './utils';
import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useActiveControllerForNetwork } from '../../hooks/useActiveControllerForNetwork';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useSecureTunnelsCrumbs } from '../../utils/routing';
import { NoValue } from '../NoValue';
import IsPermitted from '../permissions/IsPermitted';
import { ReactRouterLink } from '../ReactRouterLink';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { clientVPNServersQuery, deleteClientVPNServerMutation } from './utils';
import VPNClientsList, { AddClientButton } from './VPNClientsList';

const serverBuilder = createColumnBuilder<ClientVPNServer>();

function ServerActions({ row }: { row: ClientVPNServer }) {
  const network = useNetwork();

  const { state } = useDialogState();

  const deleteServer = useGraphQLMutation(deleteClientVPNServerMutation);

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    deleteServer.mutate(
      { uuid: row.UUID },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(clientVPNServersQuery, { networkUUID: network.UUID }),
          );
          notify('Successfully deleted VPN server.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem deleting the VPN server${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [network.UUID, row.UUID, deleteServer, queryClient]);

  return (
    <HStack spacing={space(4)}>
      <AddClientButton serverUUID={row.UUID} size="small" />
      <IsPermitted permissions={PermissionType.PermClientVpnWrite}>
        <DropdownMenu>
          <DropdownMenuButton
            variant="secondary"
            icon="overflow-horizontal"
            arrangement="hidden-label"
            size="small"
          >
            Actions
          </DropdownMenuButton>
          <DropdownMenuPopover align="end">
            <DropdownMenuGroup>
              <DropdownMenuItem onClick={state.open} icon="trash-can" internal>
                Delete
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuPopover>
        </DropdownMenu>
      </IsPermitted>
      <Dialog state={state} preset="narrow">
        <DialogHeader heading="Delete VPN server" icon="trash-can" />
        <DialogContent gutter="all">
          <VStack spacing={space(12)}>
            <Body>
              Are you sure you want to delete this VPN server? All clients will also be deleted.
            </Body>
            <Body>This cannot be undone.</Body>
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button type="button" onClick={state.close} variant="secondary">
                Cancel
              </Button>
              <Button type="button" onClick={handleDelete} variant="destructive">
                Delete
              </Button>
            </>
          }
        />
      </Dialog>
    </HStack>
  );
}

function MonoCellOrNoValue({ value }: { value: string | number | null | undefined }) {
  if (!value) return <NoValue />;

  return <Text family="monospace">{value}</Text>;
}

const serverColumns = [
  serverBuilder.data((row) => row.endpoint, {
    id: 'endpoint',
    header: 'Endpoint',
    meta: {
      isLeading: true,
    },
  }),
  serverBuilder.data((row) => row.address, {
    id: 'address',
    header: 'Address',
    cell: MonoCellOrNoValue,
  }),
  serverBuilder.data((row) => row.port, {
    id: 'port',
    header: 'Port',
    cell: MonoCellOrNoValue,
  }),
  serverBuilder.data((row) => row.dnsListenAddress, {
    id: 'dns-listen-address',
    header: 'DNS listen address',
    cell: MonoCellOrNoValue,
  }),
  serverBuilder.data((row) => row.clients.length, {
    id: 'clients',
    header: 'Clients',
  }),
  serverBuilder.display({
    id: 'actions',
    meta: {
      alignment: 'end',
    },
    cell: ServerActions,
  }),
];

function ClientVPNSubTable({ data, isNested }: { data: ClientVPNServer; isNested?: boolean }) {
  return <VPNClientsList serverUUID={data.UUID} clients={data.clients} isNested={isNested} />;
}

function ClientVPNContent() {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const closeDrawer = useCloseDrawerCallback();

  const servers = useGraphQL(clientVPNServersQuery, { networkUUID: network.UUID }).data
    ?.clientVPNServers;

  if (!servers?.length) {
    return (
      <EmptyState
        icon="vpn"
        heading="You have no VPN servers"
        action={
          <IsPermitted permissions={PermissionType.PermClientVpnWrite}>
            <Button
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.ClientVPNServerCreatePage, {
                companyName,
                networkSlug: network.slug,
              })}
              icon="plus"
              arrangement="leading-icon"
              variant="secondary"
            >
              Add VPN server
            </Button>
          </IsPermitted>
        }
      />
    );
  }

  return (
    <AutoTable
      columns={serverColumns}
      data={servers}
      renderSubTable={ClientVPNSubTable}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.ClientVPNServerUpdatePage, {
          companyName,
          networkSlug: network.slug,
          serverUUID: row.UUID,
        })
      }
      onRowDeselect={closeDrawer}
    />
  );
}

function ClientVPNActions() {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuButton variant="secondary" icon="overflow-horizontal" arrangement="hidden-label">
        Actions
      </DropdownMenuButton>
      <DropdownMenuPopover align="end">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              navigate(
                makeDrawerLink(window.location, paths.drawers.ClientVPNServerCreatePage, {
                  companyName,
                  networkSlug: network.slug,
                }),
              );
            }}
            icon="plus"
            internal
          >
            Add VPN server
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}

export default function ClientVPN() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const secureTunnelsCrumb = useSecureTunnelsCrumbs();
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const activeController = useActiveControllerForNetwork(network);
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');

  if (!isCOS2Enabled && activeController?.hardwareDevice) {
    return (
      <Navigate
        to={makeLink(paths.pages.LegacyVPNListPage, {
          companyName,
          controllerName: activeController?.hardwareDevice?.serialNumber,
        })}
      />
    );
  }

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...secureTunnelsCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.ClientVPNPage, { companyName, networkSlug: network.slug }),
              selected: true,
              label: 'Client VPN',
            },
          },
        ]}
        icon="vpn"
        heading="Client VPN"
        actions={
          <>
            <IsPermitted permissions={PermissionType.PermClientVpnClientWrite}>
              <ClientVPNActions />
            </IsPermitted>
            <SearchInput
              placeholder="..."
              aria-label="Filter clients"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent gutter="bottom">
        <ClientVPNContent />
      </PaneContent>
    </Pane>
  );
}

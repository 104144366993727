import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { ComboBox, ComboBoxItem, Drawer, DrawerContent, DrawerHeader, Label } from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { DiffEditor, Editor } from '@monaco-editor/react';
import { styled } from '@stitches/react';
import { DateTime } from 'luxon';
import { useContext, useEffect, useMemo, useState } from 'react';

import { formatTimestamp } from '../../../../../components/Devices/utils';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { ThemeContext } from '../../../../../providers/ThemeProvider';
import { useNetworkMutationAuditLog } from '../../../../pages/network/insights/logs/hooks/useNetworkMutationAuditLog';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/logs/mutations/:mutationAuditLogEntryUUID',
});

const WideDrawer = styled(Drawer, {
  variants: {
    extraWide: {
      false: {
        width: '40vw !important', // HACK :)
      },
      true: {
        width: '60vw !important', // HACK :)
      },
    },
  },
});
WideDrawer.displayName = 'WideDrawer';

const EditorContainer = styled('div', {
  height: '100%',
});

export default function NetworkMutationSummaryPage() {
  const { mutationAuditLogEntryUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.NetworkMutationSummaryPage),
  );

  const [queryStartDate] = useSearchParamsState<string>('startDate');
  const [queryEndDate] = useSearchParamsState<string>('endDate');
  const [includeMachine] = useSearchParamsState<boolean>('includeMachine', false);

  const [diffEventUUID, setDiffEventUUID] = useState<string | null>(null);

  const startDate = queryStartDate || DateTime.now().startOf('day').minus({ months: 3 }).toISO();
  const endDate = queryEndDate || DateTime.now().endOf('day').toISO();

  const filter = useMemo(
    () => ({
      minCreatedAt: DateTime.fromISO(startDate).toISO(),
      maxCreatedAt: DateTime.fromISO(endDate).toISO(),
      includeMachine,
    }),
    [startDate, endDate, includeMachine],
  );

  const events = useNetworkMutationAuditLog(filter);

  useEffect(() => {
    setDiffEventUUID(null);
  }, [events]);

  const selectedEvent = useMemo(
    () => events.find((event) => event.UUID === mutationAuditLogEntryUUID),
    [events, mutationAuditLogEntryUUID],
  );

  const diffEvent = useMemo(
    () => (diffEventUUID ? events.find((event) => event.UUID === diffEventUUID) : null),
    [events, diffEventUUID],
  );

  const pastEventsWithAction = useMemo(
    () =>
      selectedEvent
        ? events.filter(
            (event) =>
              event.action === selectedEvent.action && event.createdAt < selectedEvent.createdAt,
          )
        : [],
    [events, selectedEvent],
  );

  const { dark } = useContext(ThemeContext);

  return (
    <WideDrawer contentMode="stretch" extraWide={!!diffEvent}>
      <DrawerHeader icon="log" heading="Event" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <Label>
          <ComboBox
            canClearValue
            value={diffEventUUID}
            onValueChange={setDiffEventUUID}
            placeholder="Select past event to compare to"
          >
            {pastEventsWithAction.map((pastEvent) => (
              <ComboBoxItem key={pastEvent.UUID} textValue={formatTimestamp(pastEvent.createdAt)}>
                {formatTimestamp(pastEvent.createdAt)}
              </ComboBoxItem>
            ))}
          </ComboBox>
        </Label>
        <EditorContainer>
          {diffEvent ? (
            <DiffEditor
              originalLanguage="json"
              modifiedLanguage="json"
              theme={dark ? 'vs-dark' : 'light'}
              original={JSON.stringify(diffEvent, null, 2)}
              modified={JSON.stringify(selectedEvent, null, 2)}
              options={{ minimap: { enabled: false }, readOnly: true }}
            />
          ) : (
            <Editor
              language="json"
              value={JSON.stringify(selectedEvent, null, 2)}
              theme={dark ? 'vs-dark' : 'light'}
              options={{ minimap: { enabled: false }, readOnly: true }}
            />
          )}
        </EditorContainer>
      </DrawerContent>
    </WideDrawer>
  );
}

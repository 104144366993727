import type { QueryResultField } from '@meterup/graphql';
import { Badge, Icon, space, Text } from '@meterup/atto';
import { AutoTable } from '@meterup/common';

import type { IpSecTunnelsQueryQuery } from '../../../../../gql/graphql';
import { NoValue } from '../../../../../components/NoValue';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

type IPSecTunnelsListProps = {
  ipSecTunnels: IpSecTunnelsQueryQuery['ipSecTunnelsForNetwork'];
};

export type IpSecTunnelsQueryResult = QueryResultField<
  IpSecTunnelsQueryQuery,
  'ipSecTunnelsForNetwork'
>;

const builder = createColumnBuilder<IpSecTunnelsQueryResult>();
const columns = [
  builder.data((row) => (row.isEnabled ? 'Enabled' : 'Disabled'), {
    id: 'enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      tooltip: {
        contents: 'Enabled',
      },
      width: 40,
    },
    cell: ({ row }) =>
      row.isEnabled ? (
        <Icon
          icon="checkmark"
          color={{
            light: 'iconPositiveLight',
            dark: 'iconPositiveDark',
          }}
        />
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row?.right ?? '', {
    id: 'right',
    header: 'Destination address',
    cell: ({ row }) => {
      if (row?.right) {
        return <Text family="monospace">{row.right}</Text>;
      }
      return <NoValue />;
    },
  }),
  builder.data((row) => row.leftID, {
    id: 'leftID',
    header: 'Local IP',
    cell: ({ row }) => {
      if (row?.right) {
        return <Text family="monospace">{row.leftID}</Text>;
      }
      return <NoValue />;
    },
  }),
  builder.data((row) => row.rightID, {
    id: 'rightID',
    header: 'Remote IP',
    cell: ({ row }) => {
      if (row?.rightID) {
        return <Text family="monospace">{row.rightID}</Text>;
      }
      return <NoValue />;
    },
  }),
  builder.display({
    id: 'boundPhyInterface',
    header: 'Bound WAN port',
    cell: ({ row }) => {
      if (row?.boundPhyInterface) {
        return <Text family="monospace">{row.boundPhyInterface.virtualDevice.label}</Text>;
      }
      return <NoValue />;
    },
  }),
  builder.display({
    id: 'rightPrefixes',
    header: 'Remote networks',
    cell: ({ row }) => (
      <>
        {row.rightPrefixes.map((prefix, idx) => (
          <Text
            family="monospace"
            key={prefix}
          >{`${row.rightPrefixes.length > 1 && idx !== 0 ? ', ' : ''}${prefix}`}</Text>
        ))}
        {row.rightPrefixes.length <= 0 && <NoValue />}
      </>
    ),
  }),
  builder.display({
    id: 'boundVLANs',
    header: 'Local networks',
    cell: ({ row }) => (
      <>
        {row.boundVLANs.map((vlan, idx) => (
          <Text family="monospace" key={vlan.UUID}>
            {`${row.boundVLANs.length > 1 && idx !== 0 ? ', ' : ''}`}
            <Badge variant="neutral" size="small" icon="vlan" arrangement="leading-icon">
              {vlan.name}
            </Badge>
          </Text>
        ))}
        {row.boundVLANs.length <= 0 && <NoValue />}
      </>
    ),
  }),
];

export function IPSecTunnelsList({ ipSecTunnels }: IPSecTunnelsListProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.EditIPSecTunnelPage);
  const closeDrawer = useCloseDrawerCallback();
  return (
    <AutoTable
      size="auto"
      columns={columns}
      data={ipSecTunnels}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.EditIPSecTunnelPage, {
          IPSecUUID: row.UUID,
          companyName,
          networkSlug: network.slug,
        })
      }
      isRowSelected={(row) => row.UUID === drawerParams?.IPSecUUID}
      onRowDeselect={closeDrawer}
    />
  );
}

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  Small,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Tab,
  Text,
  useDialogState,
} from '@meterup/atto';
import { useState } from 'react';

import type { EditIPSecTunnelDrawerProps, IPSecTunnel } from './utils';
import { formatTimestamp } from '../../../../../components/Devices/utils';
import { NoValue } from '../../../../../components/NoValue';
import { PermissionType } from '../../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { usePermissions } from '../../../../../providers/PermissionsProvider';
import DeleteIPSecTunnnelDialog from './DeleteIPSecTunnelDialog';
import EditIPSecTunnelDrawer from './EditIPSecTunnelDrawer';
import RestartIPSecTunnnelDialog from './RestartIPSecTunnelDialog';

export function IPSecTunnelSummary({ tunnel }: { tunnel: IPSecTunnel }) {
  const { status } = tunnel;
  return (
    <DrawerContent gutter="none">
      {!status ? (
        <EmptyState icon="information" heading="There are no stats available" />
      ) : (
        <Sections>
          <Section relation="stacked">
            <SectionHeader icon="information" heading="Status" />
            <SectionContent gutter="all">
              <SummaryList gutter="none">
                <SummaryListRow>
                  <SummaryListKey>Observed at</SummaryListKey>
                  <SummaryListValue>
                    {status?.observedAt ? (
                      <Text size={12}>{formatTimestamp(status.observedAt)}</Text>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>State</SummaryListKey>
                  <SummaryListValue>
                    {status?.saStatus ? status.saStatus : <NoValue />}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>ID</SummaryListKey>
                  <SummaryListValue>
                    {status?.uniqueID ? status.uniqueID : <NoValue />}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Remote endpoint address</SummaryListKey>
                  <SummaryListValue>
                    {status?.remoteHostIP4Address ? (
                      <Small family="monospace">{status.remoteHostIP4Address}</Small>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Established at</SummaryListKey>
                  <SummaryListValue>
                    {status?.establishedAt ? (
                      <Text size={12}>{formatTimestamp(status.establishedAt)}</Text>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Rekeyed at</SummaryListKey>
                  <SummaryListValue>
                    {status?.rekeyAt ? (
                      <Text size={12}>{formatTimestamp(status.rekeyAt)}</Text>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Re-authenticated at</SummaryListKey>
                  <SummaryListValue>
                    {status?.reauthAt ? (
                      <Text size={12}>{formatTimestamp(status.reauthAt)}</Text>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>RX byte usage</SummaryListKey>
                  <SummaryListValue>
                    {status?.rxBytes != null ? status.rxBytes : <NoValue />}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>TX byte usage</SummaryListKey>
                  <SummaryListValue>
                    {status?.txBytes != null ? status.txBytes : <NoValue />}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>RX packet usage</SummaryListKey>
                  <SummaryListValue>
                    {status?.rxPackets != null ? status.rxPackets : <NoValue />}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Last inbound packet</SummaryListKey>
                  <SummaryListValue>
                    {status?.rxLast ? (
                      <Text size={12}>{formatTimestamp(status.rxLast)}</Text>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Last outbound packet</SummaryListKey>
                  <SummaryListValue>
                    {status?.txLast ? (
                      <Text size={12}>{formatTimestamp(status.txLast)}</Text>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Latency (ms)</SummaryListKey>
                  <SummaryListValue>{status.latencyMs}</SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
          <Section relation="stacked">
            <SectionHeader icon="information" heading="Child SA statistics" />
            <SectionContent gutter="all">
              <SummaryList gutter="none">
                <SummaryListRow>
                  <SummaryListKey>State</SummaryListKey>
                  <SummaryListValue>
                    {status?.childSAStatus ? status.childSAStatus : <NoValue />}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Rekeyed at</SummaryListKey>
                  <SummaryListValue>
                    {status?.childRekeyAt ? (
                      <Text size={12}>{formatTimestamp(status.childRekeyAt)}</Text>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>

                <SummaryListRow>
                  <SummaryListKey>Expiration</SummaryListKey>
                  <SummaryListValue>
                    {status?.childLifetime ? (
                      <Text size={12}>{formatTimestamp(status.childLifetime)}</Text>
                    ) : (
                      <NoValue />
                    )}
                  </SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
        </Sections>
      )}
    </DrawerContent>
  );
}

type IPSecDrawerTab = 'details' | 'config';
export default function IPSecTunnelDrawer({ tunnel }: EditIPSecTunnelDrawerProps) {
  const [activeTab, setActiveTab] = useState<IPSecDrawerTab>(tunnel ? 'details' : 'config');
  const { hasPermission } = usePermissions();
  const { state: deleteDialogState } = useDialogState();
  const { state: restartDialogState } = useDialogState();
  const closeDrawer = useCloseDrawerCallback();
  const heading = (() => {
    if (tunnel) {
      return `${activeTab === 'config' ? 'Edit ' : ''}IPSec tunnel`;
    }
    return 'Add IPSec tunnel';
  })();
  return (
    <Drawer>
      <DrawerHeader
        icon="ipsec-tunnel"
        onClose={closeDrawer}
        heading={heading}
        actions={
          tunnel ? (
            <DropdownMenu>
              <DropdownMenuButton
                variant="secondary"
                icon="overflow-horizontal"
                arrangement="hidden-label"
              >
                Actions
              </DropdownMenuButton>
              <DropdownMenuPopover align="end">
                <DropdownMenuItem icon="trash-can" onSelect={deleteDialogState.open}>
                  Delete
                </DropdownMenuItem>
                <DropdownMenuItem icon="arrows-rotate" onSelect={restartDialogState.open}>
                  Restart
                </DropdownMenuItem>
              </DropdownMenuPopover>
            </DropdownMenu>
          ) : undefined
        }
        tabs={
          tunnel && hasPermission(PermissionType.PermIpsecTunnelWrite) ? (
            <>
              <Tab
                icon="information"
                selected={activeTab === 'details'}
                onClick={() => setActiveTab('details')}
              >
                Details
              </Tab>
              <Tab
                icon="cog"
                selected={activeTab === 'config'}
                onClick={() => setActiveTab('config')}
              >
                Config
              </Tab>
            </>
          ) : undefined
        }
      />
      {tunnel && activeTab === 'details' && <IPSecTunnelSummary tunnel={tunnel} />}
      {activeTab === 'config' && <EditIPSecTunnelDrawer tunnel={tunnel ?? undefined} isInDrawer />}
      {tunnel && (
        <>
          <DeleteIPSecTunnnelDialog state={deleteDialogState} tunnel={tunnel} />
          <RestartIPSecTunnnelDialog state={restartDialogState} tunnel={tunnel} />
        </>
      )}
    </Drawer>
  );
}

import { graphqlClient } from '@meterup/graphql';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { useCallback, useMemo } from 'react';

import { graphql } from '../gql/gql';

const getNetworkClientsTxRxPerDay = graphql(`
  query getNetworkClientsTxRxPerDay($networkUUID: UUID!, $numDaysLookback: Int!) {
    networkClientsTxRxPerDay(networkUUID: $networkUUID, numDaysLookback: $numDaysLookback) {
      ip
      macAddress
      dateString
      txBytes
      rxBytes
    }
  }
`);

// Exports connected client history as CSV over the past 7 days
export function useClientsExporter({
  controllerAddress,
  networkUUID,
  numDaysLookback,
}: {
  controllerAddress: string;
  networkUUID: string;
  numDaysLookback: number;
}) {
  const generateCSVDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const generateCSVName = useCallback((address: string) => {
    const underscoredAddress = address.replace(/ /g, '_');
    return `${underscoredAddress}_connected_clients_${generateCSVDate()}`;
  }, []);

  const csvName = useMemo(
    () => generateCSVName(controllerAddress),
    [controllerAddress, generateCSVName],
  );

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: true,
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    filename: csvName,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvConfig = mkConfig(csvOptions);

  const downloadCSVHandler = async () => {
    const res = await graphqlClient.request(getNetworkClientsTxRxPerDay, {
      networkUUID,
      numDaysLookback,
    });

    const csv = generateCsv(csvConfig)(res.networkClientsTxRxPerDay);
    download(csvConfig)(csv);
  };

  return { downloadCSVHandler };
}

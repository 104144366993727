import { Pane, PaneContent, PaneHeader, PrimaryFieldComposite, space, VStack } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';

import IsPermitted from '../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../gql/graphql';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import ConfigureDirectorySync from '../user/ConfigureDirectorySync';
import ConfigureSSO from '../user/ConfigureSSO';
import { ssoOptionsQuery } from './authenticationQuery';

export function Meta() {
  return {
    path: '/org/:companyName/settings/org/authentication',
    title: 'Authentication - Org',
  };
}

export default function SettingsOrgAuthenticationPage() {
  const companySlug = useCurrentCompany();
  const ssoOptions = useGraphQL(
    ssoOptionsQuery,
    { companySlug: companySlug! },
    {
      enabled: !!companySlug,
      suspense: true,
    },
  );
  const { connections, connectedDirectory } = ssoOptions.data?.ssoOptions || {};
  return (
    <IsPermitted permissions={PermissionType.PermCompanyUpdate} should404OnAccessDenied>
      <Pane variant="adjusted">
        <PaneHeader icon="padlock" heading="Authentication" />
        <PaneContent gutter="all">
          <VStack spacing={space(16)}>
            <ConfigureSSO connections={connections} />
            <PrimaryFieldComposite
              label="User provisioning"
              description="Configure automatic creation, update, and removal of users."
              fields={<ConfigureDirectorySync connectedDirectory={connectedDirectory} />}
            />
          </VStack>
        </PaneContent>
      </Pane>
    </IsPermitted>
  );
}

import type { IconName } from '@meterup/atto';
import type { TickLabelProps } from '@visx/axis';
import type {
  RenderTooltipGlyphProps,
  RenderTooltipParams,
} from '@visx/xychart/lib/components/Tooltip';
import type { CurveFactory } from 'd3';
import type { ReactNode } from 'react';
import { Badge, Caption, HStack, sizing, Small, space } from '@meterup/atto';
import { colors, Tooltip as MeterTooltip } from '@meterup/common';
import { GlyphDot } from '@visx/glyph';
import {
  AreaSeries,
  Axis,
  BarSeries,
  DataProvider,
  LineSeries,
  Tooltip,
  XYChart,
} from '@visx/xychart';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useId } from 'react-aria';

import type { DataPoint, TimeSeries } from '../../utils/chart_utils';
// import { useLocalStorage } from '../../hooks/useLocalStorage';
import { ThemeContext } from '../../providers/ThemeProvider';
import {
  buildChartThemeImpl,
  dateFormatter,
  TooltipContainer,
  TooltipItem,
  useChartColors,
} from '../../utils/chart_utils';
import ChartBackground from './ChartAtoms/ChartBackground';
import ChartEmpty from './ChartAtoms/ChartEmpty';
import ChartGrid from './ChartAtoms/ChartGrid';
import { ChartLegend } from './ChartAtoms/ChartLegend';
import ChartSection from './ChartAtoms/ChartSection';
import { BASE_CHART_HEIGHT, defaultTickLabelProps } from './ChartAtoms/utils';

interface SeriesLineProps {
  curve: CurveFactory;
  title: string;
  series: TimeSeries;
  colorMap: Record<string, string>;
  seriesType: SeriesType;
  thresholdFn: (s: TimeSeries) => boolean;
  allSeries: TimeSeries[];
}

function dateAccessor(d: DataPoint) {
  return d ? new Date(d.timestamp) : null;
}

function valueAccessor(d: DataPoint) {
  return d.value;
}

function SeriesLine({
  allSeries,
  curve,
  series,
  title,
  colorMap,
  seriesType,
  thresholdFn,
}: SeriesLineProps) {
  // Props for area series
  const gradientId = useId();
  let lineProps;
  const gradientStopColor = colorMap[series.series_id];
  const isSeriesAboveThreshold = thresholdFn(series);

  if (isSeriesAboveThreshold || allSeries.length === 1) {
    lineProps = {
      stroke: gradientStopColor,
      strokeWidth: 2,
      strokeDasharray: '0 3',
    };
  } else {
    lineProps = {
      stroke: gradientStopColor,
      strokeWidth: 0.5,
      strokeDasharray: '0 4',
    };
  }

  if (seriesType === 'area') {
    return (
      <>
        <defs>
          <linearGradient
            id={gradientId}
            x1="50%"
            y1="100%"
            x2="50%"
            y2="0%"
            gradientUnits="objectBoundingBox"
          >
            <stop stopColor={gradientStopColor} stopOpacity="0" />
            <stop offset="0.5" stopColor={gradientStopColor} stopOpacity="0.14" />
            <stop offset="1" stopColor={gradientStopColor} stopOpacity="0.14" />
          </linearGradient>
        </defs>
        <AreaSeries
          dataKey={title}
          data={series.data}
          xAccessor={dateAccessor}
          yAccessor={valueAccessor}
          curve={curve}
          strokeWidth={1.25}
          renderLine
          fill={`url(#${gradientId})`}
          lineProps={lineProps}
        />
      </>
    );
  }

  if (seriesType === 'bar') {
    return (
      <BarSeries
        barPadding={0.4}
        dataKey={series.series_id}
        data={series.data}
        xAccessor={dateAccessor}
        yAccessor={valueAccessor}
        radius={4}
        radiusTop
        colorAccessor={(d) => colorMap[d.series_id]}
      />
    );
  }

  return (
    <LineSeries
      dataKey={series.series_id}
      data={series.data}
      xAccessor={dateAccessor}
      yAccessor={valueAccessor}
      curve={curve}
      strokeWidth={1.25}
      colorAccessor={(d) => colorMap[d]}
    />
  );
}

type SeriesType = 'area' | 'bar' | 'line';

export interface TimeSeriesChartProps {
  curve: CurveFactory;
  multiSeries?: boolean; // Allow multi series view
  series: TimeSeries[];
  seriesType: SeriesType;
  seriesLabel: string; // Unique name for the series type's state to be stored for the user.
  showLegend?: boolean;
  showSeriesGlyphs?: boolean; // Whether to show glyphs on hover for all series or just nearest datum
  showSeriesTooltips?: boolean; // Whether to show tooltips for all series or just nearest datum
  thresholdFn?: (s: TimeSeries) => boolean;
  tickLabelProps?: TickLabelProps<any>;
  timePeriod: string;
  title: string;
  icon?: IconName;
  titleBadge?: React.ReactNode;
  tooltipBody?: React.ReactNode;
  valueFormatter: (value: number) => string;
  yTickValueFormatter?: (value: number) => string;
  xTickValues?: Date[]; // Auto generated if not defined.
  yDomain?: number[]; // If not defined, y-domain is auto generated by XYChart
  yTickValues?: number[]; // Auto generated by y-domain if not defined.
  chartColors?: string[]; // Required if managing selection state outside this component
  colorMap?: Record<string, string>; // Required if managing selection state outside this component
  internal?: boolean;
  showYAxis?: boolean;
}

export default function TimeSeriesChart({
  curve,
  multiSeries = true,
  series,
  // seriesType = 'area',
  // seriesLabel,
  showLegend = true,
  showSeriesGlyphs = true,
  showSeriesTooltips = false,
  thresholdFn = () => true,
  tickLabelProps,
  timePeriod,
  title,
  icon,
  tooltipBody,
  valueFormatter,
  yTickValueFormatter,
  xTickValues,
  yDomain,
  yTickValues,
  chartColors: chartColorsProp,
  colorMap: colorMapProp,
  internal = false,
  showYAxis = true,
}: TimeSeriesChartProps) {
  const [selectedItem, setSelectedItem] = useState<string>(
    !multiSeries && series.length > 0 ? series[0].series_id : '',
  );

  // We need to pass in the color map as a prop if we manage the selected state outside the component
  // We generate the colormap inside the component if we manage the selected state with an internal legend
  const { chartColors: genChartColors, colorMap: genColorMap } = useChartColors(series);
  const chartColors = chartColorsProp ?? genChartColors;
  const colorMap = colorMapProp ?? genColorMap;

  const { dark } = useContext(ThemeContext);
  const theme = buildChartThemeImpl(chartColors, dark ? 'dark' : 'light');
  const showDatumGlyph = !showSeriesGlyphs;
  const tooltipKeys = useMemo(() => {
    if (selectedItem !== '') {
      return [selectedItem];
    }

    return series.map((s) => s.series_id);
  }, [series, selectedItem]);

  // Ensures there is a selected item when time period is changed
  useEffect(() => {
    if (!multiSeries && series.length > 0) {
      setSelectedItem(series[0].series_id);
    }
  }, [series, multiSeries]);

  const handleSelectItem = (item: string) => {
    if (item === selectedItem && multiSeries) {
      setSelectedItem('');
    } else if (item !== selectedItem) {
      setSelectedItem(item);
    }
  };

  const filterSeriesByItem = (seriesArg: TimeSeries[], item: string) =>
    seriesArg.filter((s) => s.series_id === item);

  const selectedSeries = useMemo(() => {
    if (!selectedItem && multiSeries) return series;

    return filterSeriesByItem(series, selectedItem);
  }, [selectedItem, multiSeries, series]);

  const renderTooltip = (params: RenderTooltipParams<DataPoint>): ReactNode => {
    const { tooltipData } = params;
    if (tooltipData === undefined) return null;
    if (tooltipData.nearestDatum === undefined) return null;
    if (tooltipData.nearestDatum.datum.value === null) return null;

    const val =
      showSeriesTooltips && tooltipKeys?.length && tooltipKeys?.length > 0 ? (
        tooltipKeys?.map((key) => (
          <TooltipItem key={`tooltip-${key}`}>
            <Small>{key}</Small>
            <Badge size="small">
              {valueFormatter(tooltipData.datumByKey[key]?.datum?.value ?? 0)}
            </Badge>
          </TooltipItem>
        ))
      ) : (
        <TooltipItem key={`tooltip-${tooltipData.nearestDatum.key}`}>
          <Small>{tooltipData.nearestDatum.key}</Small>
          <Badge size="small">{valueFormatter(tooltipData.nearestDatum.datum.value)}</Badge>
        </TooltipItem>
      );

    return (
      <TooltipContainer>
        <Caption>
          {dateFormatter(tooltipData.nearestDatum.datum.timestamp, timePeriod, 'MMMM d, t', false)}
        </Caption>
        {val}
      </TooltipContainer>
    );
  };

  const renderTooltipGlyph = useCallback(
    // datum: DataPoint
    ({
      x,
      y,
      size,
      onPointerMove,
      onPointerOut,
      onPointerUp,
      datum,
    }: RenderTooltipGlyphProps<DataPoint>) => {
      const handlers = { onPointerMove, onPointerOut, onPointerUp };
      return (
        <GlyphDot
          left={x}
          top={y}
          stroke={colors.white.toString()}
          fill={colorMap[datum.series_id]}
          r={size}
          {...handlers}
        />
      );
    },
    [colorMap],
  );

  const yScale = {
    type: 'linear' as const,
    ...(yDomain && { domain: yDomain }),
    zero: false,
  };

  // const [seriesTypeToggle, setSeriesTypeToggle] = useLocalStorage(seriesLabel, seriesType);
  const isEmpty = series.map((data) => data.data).flat().length === 0;

  const presetHeight = BASE_CHART_HEIGHT;
  const positionBottom = 20;
  const positionLeft = showYAxis ? 120 : sizing.primary;

  return (
    <ChartSection
      internal={internal}
      height={presetHeight}
      header={{
        icon,
        heading: (
          <HStack spacing={space(8)} align="baseline">
            {tooltipBody ? (
              <MeterTooltip
                side="right"
                sideOffset={6}
                align="start"
                showHint
                content={tooltipBody}
              >
                {title}
              </MeterTooltip>
            ) : (
              title
            )}
          </HStack>
        ),
        // actions: (
        //   <Segments size="small">
        //     <Segment
        //       onClick={() => setSeriesTypeToggle('area')}
        //       active={seriesTypeToggle === 'area'}
        //       arrangement="hidden-label"
        //       icon="chart-line"
        //     >
        //       Area
        //     </Segment>
        //     <Segment
        //       onClick={() => setSeriesTypeToggle('bar')}
        //       active={seriesTypeToggle === 'bar'}
        //       arrangement="hidden-label"
        //       icon="chart-bar"
        //     >
        //       Bar
        //     </Segment>
        //   </Segments>
        // ),
      }}
    >
      {isEmpty ? (
        <ChartEmpty style={{ height: `${presetHeight}px` }} />
      ) : (
        <DataProvider xScale={{ type: 'time' }} yScale={yScale} theme={theme}>
          <XYChart
            height={presetHeight}
            horizontal="auto"
            margin={{
              left: positionLeft,
              top: 16,
              bottom: positionBottom,
              right: sizing.primary,
            }}
            theme={theme}
          >
            <ChartBackground height={presetHeight} left={positionLeft} bottom={positionBottom} />
            <ChartGrid />
            {showYAxis && (
              <Axis
                orientation="left"
                numTicks={3}
                tickFormat={yTickValueFormatter ?? valueFormatter}
                strokeWidth={0}
                tickValues={yTickValues}
              />
            )}
            <Axis
              orientation="bottom"
              strokeWidth={0}
              tickValues={xTickValues}
              tickFormat={(d) => dateFormatter(d, timePeriod)}
              numTicks={3}
              tickLabelProps={tickLabelProps || defaultTickLabelProps}
            />
            {selectedSeries.map((s) => (
              <SeriesLine
                curve={curve}
                colorMap={colorMap}
                key={`${s.series_id}-${s.series_name}`}
                title={s.series_name || title}
                series={s}
                seriesType="area"
                thresholdFn={thresholdFn}
                allSeries={selectedSeries}
              />
            ))}
            <Tooltip<DataPoint>
              snapTooltipToDatumX
              showVerticalCrosshair
              showSeriesGlyphs={showSeriesGlyphs}
              unstyled
              applyPositionStyle
              renderTooltip={renderTooltip}
              renderGlyph={renderTooltipGlyph}
              showDatumGlyph={showDatumGlyph}
            />
          </XYChart>
        </DataProvider>
      )}
      {!isEmpty && showLegend && (
        <ChartLegend
          handleSelectItem={handleSelectItem}
          selectedItem={selectedItem}
          series={series}
          chartColors={chartColors}
        />
      )}
    </ChartSection>
  );
}

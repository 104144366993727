import type { SortingState } from '@tanstack/react-table';
import type { RefObject } from 'react';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { DeviceTarget, EmptyState, Pane, PaneContent, PaneHeader } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense, useRef, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { CopyableMonoOrNoValue } from '../../../../components/Devices/Insights';
import { SecurityApplianceDetailsTab } from '../../../../components/Hardware/SecurityAppliance/utils2';
import { KeyBoundSearchInput } from '../../../../components/KeyBoundSearchInput';
import { NoValue } from '../../../../components/NoValue';
import { DetailLoadingFallback } from '../../../../components/Placeholders/DetailLoadingFallback';
import { createColumnBuilder } from '../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../constants';
import { useSearchParamsState } from '../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../../../utils/main_and_drawer_navigation';
import { type SecurityApplianceForList, securityAppliancesQuery } from './utils';

export const Meta: PagefileMetaFn = () => ({
  path: '/operator/security-appliances',
  layout: 'OperatorLayout',
  title: 'Security appliances - Operator',
});

const builder = createColumnBuilder<SecurityApplianceForList>();

const columns = [
  builder.data((row) => row.serialNumber, {
    id: 'serial-number',
    header: 'Serial number',
    enableGlobalFilter: true,
    meta: {
      minWidth: 180,
      isLeading: true,
    },
    cell: ({ value }) => <CopyableMonoOrNoValue label="serial number" value={value} />,
  }),
  builder.data((row) => row.virtualDevice?.network.company?.name, {
    id: 'company',
    header: 'Assigned company',
    sortUndefined: 1,
    enableGlobalFilter: true,
    cell: ({ row, value }) =>
      row.virtualDevice?.network?.company ? (
        <DeviceTarget
          as={ReactRouterLink}
          to={makeDrawerLink(window.location, paths.drawers.CompanySummaryPage, {
            slug: row.virtualDevice.network.company.slug,
          })}
          type="company"
          aria-label={`Go to ${value}`}
          replace={false}
        >
          {value}
        </DeviceTarget>
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.virtualDevice?.label, {
    id: 'virtual-device',
    header: 'Virtual device',
    sortUndefined: 1,
    enableGlobalFilter: true,
    cell: ({ row, value }) =>
      row.virtualDevice?.network?.company ? (
        <DeviceTarget
          type="security-appliance"
          as={ReactRouterLink}
          to={makeLink(paths.pages.SecurityApplianceDetailPage, {
            companyName: row.virtualDevice.network.company.slug,
            networkSlug: row.virtualDevice.network.slug,
            uuid: row.virtualDevice.UUID,
            tab: SecurityApplianceDetailsTab.Insights,
          })}
          aria-label={`Go to ${value}`}
          replace={false}
        >
          {value}
        </DeviceTarget>
      ) : (
        <NoValue />
      ),
  }),
  builder.data((row) => row.virtualDevice?.network?.label, {
    id: 'network',
    header: 'Network',
    sortUndefined: 1,
    enableGlobalFilter: true,
    cell: ({ row, value }) =>
      row.virtualDevice?.network?.company ? (
        <DeviceTarget
          as={ReactRouterLink}
          to={makeLink(paths.pages.IndividualNetworkRootPage, {
            companyName: row.virtualDevice.network.company.slug,
            networkSlug: row.virtualDevice.network.slug,
          })}
          type="network"
          aria-label={`Go to ${value}`}
          replace={false}
        >
          {value}
        </DeviceTarget>
      ) : (
        <NoValue />
      ),
  }),
];

function SecurityAppliancesActions() {
  return (
    <KeyBoundSearchInput
      placeholder="..."
      aria-label="Filter security appliances"
      scope="scoped"
      minWidth="56px"
      searchParamStateKey="filter"
    />
  );
}

function SecurityApplianceListView({
  securityAppliances,
  globalFilter,
  tableContainerRef,
}: {
  securityAppliances: SecurityApplianceForList[];
  globalFilter: string | undefined;
  tableContainerRef: RefObject<HTMLDivElement>;
}) {
  const [sortingState, setSortingState] = useState<SortingState>([]);

  if (securityAppliances.length === 0) {
    return <EmptyState icon="security-appliance" heading="No security appliances found" />;
  }

  return (
    <AutoTable
      isVirtual
      tableContainerRef={tableContainerRef}
      columns={columns}
      data={securityAppliances}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
    />
  );
}

export default function SecurityApplianceListPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const securityAppliances =
    useGraphQL(securityAppliancesQuery, { limit: 5000 }).data?.hardwareDevices ?? [];
  const tableContainerRef = useRef(null);

  return (
    <Pane>
      <PaneHeader
        icon="security-appliance"
        heading="Security appliances"
        count={securityAppliances.length}
        actions={<SecurityAppliancesActions />}
      />
      <PaneContent gutter="bottom" ref={tableContainerRef}>
        <Suspense fallback={<DetailLoadingFallback />}>
          <SecurityApplianceListView
            securityAppliances={securityAppliances}
            tableContainerRef={tableContainerRef}
            globalFilter={globalFilter}
          />
        </Suspense>
      </PaneContent>
    </Pane>
  );
}

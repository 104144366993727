import type { NosVersionsShortQueryQuery } from '../../gql/graphql';
import { graphql } from '../../gql';

graphql(`
  fragment NosVersionFields on NosVersion {
    id
    version
    major
    minor
    patch
    isDefault
  }
`);

export const NosVersionQuery = graphql(`
  query NosVersionQuery($id: Int!) {
    nosVersions(id: $id) {
      ...NosVersionFields
    }
  }
`);

export const NosVersionsShortQuery = graphql(`
  query NosVersionsShortQuery {
    nosVersions {
      ...NosVersionFields
    }
  }
`);

export type NosVersion = NosVersionsShortQueryQuery['nosVersions'][number];

export const SetNetworkNosMutation = graphql(`
  mutation SetNetworkNosMutation($networkUUID: UUID!, $input: SetNetworkNosVersionInput!) {
    setNetworkNosVersion(networkUUID: $networkUUID, input: $input)
  }
`);

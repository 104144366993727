import IsOperator from '@meterup/authorization/src/components/IsOperator';
import { checkDefinedOrThrow } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import React, { Suspense } from 'react';

import EditMountDrawer from '../../../../../components/Design/RackElevations/EditMountDrawer';
import { rackElevationQuery } from '../../../../../components/Design/RackElevations/utils';
import { DrawerLoadingFallback } from '../../../../../components/Placeholders/DrawerLoadingFallback';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/rack-elevations/:elevationUUID/attached-device/:deviceUUID',
  title: 'Edit mount - Rack - Rack elevations - Design',
});

export default function EditRackMountPage() {
  const { elevationUUID, deviceUUID } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.EditRackMountPage),
  );
  const rackElevation = useGraphQL(rackElevationQuery, { UUID: elevationUUID }).data?.rackElevation;

  const device = rackElevation?.devices?.find((d) => d.UUID === deviceUUID);

  if (!rackElevation || !device) return null;
  return (
    <IsOperator>
      <Suspense fallback={<DrawerLoadingFallback />}>
        <EditMountDrawer elevation={rackElevation} device={device} />
      </Suspense>
    </IsOperator>
  );
}

import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';

import type { AlertReceiversQueryQuery, AlertTargetsQueryQuery } from '../../../../gql/graphql';
import { graphql } from '../../../../gql';
import {
  CreateAlertReceiverWithTargetsInputSchema,
  CreateAlertTargetWebhookInputSchema,
  UpdateAlertReceiverWithTargetsInputSchema,
  UpdateAlertTargetWebhookInputSchema,
} from '../../../../gql/zod-types';

export type AlertTargetEmail = AlertTargetsQueryQuery['alertTargets']['emails'][number];
export type AlertTargetWebhook = AlertTargetsQueryQuery['alertTargets']['webhooks'][number];
export type AlertTarget = AlertTargetEmail | AlertTargetWebhook;

export const receiverAddSchema = z
  .object({
    companyUUID: z.string(),
    input: CreateAlertReceiverWithTargetsInputSchema.pick({ label: true }).extend({
      label: z.string().nonempty({ message: 'Please provide a label.' }),
      emailAddresses: z.array(z.string().email()),
      targetUUIDs: z.array(z.string()),
    }),
  })
  .omit({ companyUUID: true })
  .superRefine(({ input: { emailAddresses, targetUUIDs } }, ctx) => {
    if (emailAddresses?.length === 0 && targetUUIDs?.length === 0) {
      for (const path of ['input.emailAddresses', 'input.targetUUIDs']) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'At least one webhook or email target must be provided.',
          path: [path],
        });
      }
    }
  });

export type ReceiverAddSchema = z.infer<typeof receiverAddSchema>;

export const receiverUpdateSchema = z
  .object({
    alertReceiverUUID: z.string(),
    input: UpdateAlertReceiverWithTargetsInputSchema.pick({ label: true }).extend({
      label: z.string().nonempty({ message: 'Please provide a label.' }),
      emailAddresses: z.array(z.string().email()),
      targetUUIDs: z.array(z.string()),
    }),
  })
  .omit({ alertReceiverUUID: true })
  .superRefine(({ input: { emailAddresses, targetUUIDs } }, ctx) => {
    if (emailAddresses?.length === 0 && targetUUIDs?.length === 0) {
      for (const path of ['input.emailAddresses', 'input.targetUUIDs']) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'At least one webhook or email target must be provided.',
          path: [path],
        });
      }
    }
  });

export type ReceiverUpdateSchema = z.infer<typeof receiverUpdateSchema>;

export const deleteAlertReceiverMutation = graphql(`
  mutation DeleteAlertReceiverMutation($alertReceiverUUID: UUID!) {
    deleteAlertReceiverWithTargets(alertReceiverUUID: $alertReceiverUUID) {
      UUID
    }
  }
`);

const alertTargetsQuery = graphql(`
  query AlertTargetsQuery($companyUUID: UUID!) {
    alertTargets(companyUUID: $companyUUID) {
      emails {
        UUID
        emailAddress
        type
      }
      webhooks {
        UUID
        url
        label
        type
        signingSecret
      }
    }
  }
`);

const alertTargetsWebhookQuery = graphql(`
  query alertTargetsWebhookQuery($companyUUID: UUID!) {
    alertTargetsWebhook(companyUUID: $companyUUID) {
      UUID
      signingSecret
      type
      url
      label
    }
  }
`);

type UseAlertTargetInput = {
  companyUUID: string | undefined;
};

function useAlertTargets({ companyUUID }: UseAlertTargetInput) {
  expectDefinedOrThrow(companyUUID);

  return checkDefinedOrThrow(
    useGraphQL(alertTargetsQuery, { companyUUID }).data?.alertTargets,
    new ResourceNotFoundError('No targets found'),
  );
}

export function useAlertTargetsWebhook({ companyUUID }: UseAlertTargetInput): AlertTargetWebhook[] {
  expectDefinedOrThrow(companyUUID);

  const sortWebhooks = (a: AlertTargetWebhook, b: AlertTargetWebhook) => {
    const labelA = a.label || '';
    const labelB = b.label || '';

    if (labelA === '' && labelB === '') return 0;
    if (labelA === '') return 1;
    if (labelB === '') return -1;

    return labelA.localeCompare(labelB);
  };

  const webhooks = checkDefinedOrThrow(
    useGraphQL(alertTargetsWebhookQuery, { companyUUID }).data?.alertTargetsWebhook,
    new ResourceNotFoundError('No webhook targets found'),
  );
  return useMemo(() => webhooks.sort(sortWebhooks), [webhooks]);
}

export function useAlertTargetsEmail({ companyUUID }: UseAlertTargetInput): AlertTargetEmail[] {
  const { emails } = useAlertTargets({ companyUUID });
  return useMemo(
    () => emails.sort((a, b) => a.emailAddress.localeCompare(b.emailAddress)),
    [emails],
  );
}

export function useAlertTargetsAll({ companyUUID }: UseAlertTargetInput): AlertTarget[] {
  const emails = useAlertTargetsEmail({ companyUUID });
  const webhooks = useAlertTargetsWebhook({ companyUUID });
  return useMemo(() => [...emails, ...webhooks], [emails, webhooks]);
}

export function useAlertTargetsAllByUUID({
  companyUUID,
}: UseAlertTargetInput): Map<string, AlertTarget> {
  const targets = useAlertTargetsAll({ companyUUID });
  const uuidToTargets = new Map<string, AlertTarget>();
  targets.forEach((target) => {
    uuidToTargets.set(target.UUID, target);
  });
  return uuidToTargets;
}

export type AlertReceiver = AlertReceiversQueryQuery['alertReceiversForCompany'][number];

const alertReceiversQuery = graphql(`
  query AlertReceiversQuery($companyUUID: UUID!) {
    alertReceiversForCompany(companyUUID: $companyUUID) {
      __typename
      UUID
      companyUUID
      label
      targets {
        __typename
        UUID
        type
        ... on AlertTargetEmail {
          emailAddress
        }
        ... on AlertTargetWebhook {
          url
          label
        }
      }
    }
  }
`);

type UseAlertReceiversInput = {
  companyUUID: string | undefined;
};

export function useAlertReceivers({ companyUUID }: UseAlertReceiversInput) {
  expectDefinedOrThrow(companyUUID);

  const receivers = checkDefinedOrThrow(
    useGraphQL(alertReceiversQuery, { companyUUID }).data?.alertReceiversForCompany,
    new ResourceNotFoundError('No receivers found'),
  );
  return useMemo(() => receivers?.sort((a, b) => a.label.localeCompare(b.label)), [receivers]);
}

export function useInvalidateAlertReceivers({ companyUUID }: UseAlertReceiversInput) {
  expectDefinedOrThrow(companyUUID);

  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries(makeQueryKey(alertReceiversQuery, { companyUUID }));
  }, [companyUUID, queryClient]);
}

function useAlertReceiverOrNull({ uuid, companyUUID }: { uuid: string; companyUUID: string }) {
  return (
    useGraphQL(alertReceiversQuery, { companyUUID }).data?.alertReceiversForCompany.find(
      (receiver) => receiver.UUID === uuid,
    ) ?? null
  );
}

export function useAlertReceiver(props: { uuid: string; companyUUID: string }) {
  return checkDefinedOrThrow(
    useAlertReceiverOrNull(props),
    new ResourceNotFoundError('No receiver found'),
  );
}

function useWebhookTargetOrNull({ uuid, companyUUID }: { uuid: string; companyUUID: string }) {
  return (
    useGraphQL(alertTargetsWebhookQuery, { companyUUID }).data?.alertTargetsWebhook.find(
      (target) => target.UUID === uuid,
    ) ?? null
  );
}

export function useWebhookTarget(props: { uuid: string; companyUUID: string }) {
  return checkDefinedOrThrow(
    useWebhookTargetOrNull(props),
    new ResourceNotFoundError('No webhook found'),
  );
}

export const webhookAddSchema = z
  .object({
    companyUUID: z.string(),
    input: CreateAlertTargetWebhookInputSchema.extend({
      label: z.string().nonempty({ message: 'Please provide a label.' }),
      url: z.string().nonempty({ message: 'Please provide a URL.' }),
    }),
  })
  .omit({ companyUUID: true });

export type WebhookAddSchema = z.infer<typeof webhookAddSchema>;

export const webhookUpdateSchema = z
  .object({
    UUID: z.string(),
    input: UpdateAlertTargetWebhookInputSchema.extend({
      label: z.string().nonempty({ message: 'Please provide a label.' }),
      url: z.string().nonempty({ message: 'Please provide a URL.' }),
    }),
  })
  .omit({ UUID: true });

export type WebhookUpdateSchema = z.infer<typeof webhookUpdateSchema>;

export function useInvalidateWebhookTargets({ companyUUID }: UseAlertReceiversInput) {
  expectDefinedOrThrow(companyUUID);

  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries(makeQueryKey(alertTargetsWebhookQuery, { companyUUID }));
  }, [companyUUID, queryClient]);
}

export const deleteAlertTargetWebhookMutation = graphql(`
  mutation DeleteAlertTargetWebhookMutation($UUID: UUID!) {
    deleteAlertTargetWebhook(UUID: $UUID) {
      UUID
    }
  }
`);

import type { SortingState } from '@tanstack/react-table';
import { Badge, HStack, Icon, space, Text } from '@meterup/atto';
import { AutoTable, expectDefinedOrThrow, ResourceNotFoundError, truthy } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import type { VLANQueryResult } from '../../Hardware/Switches/utils';
import { paths } from '../../../constants';
import { ClientAssignmentProtocol } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { Mono } from '../../Settings/Network/utils';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { vlansQuery } from './utils';

const builder = createColumnBuilder<VLANQueryResult>();

const columns = [
  builder.data((vlan) => (vlan.isEnabled ? 'Enabled' : 'Disabled'), {
    enableGlobalFilter: false,
    id: 'vlan-enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Enabled',
      },
    },
    cell: ({ row: vlan }) => {
      if (vlan.isEnabled) {
        return (
          <Icon
            icon="checkmark"
            color={{
              light: 'iconPositiveLight',
              dark: 'iconPositiveDark',
            }}
            size={space(16)}
          />
        );
      }

      return null;
    },
  }),
  builder.data((vlan) => vlan.vlanID.toString(), {
    id: 'vlan-id',
    header: 'ID',
    meta: {
      alignment: 'end',
      maxWidth: 80,
    },
  }),
  builder.data((vlan) => vlan.name, {
    id: 'vlan-name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
    cell: ({ row: vlan }) => {
      if (vlan.isDefault) {
        return (
          <HStack align="center" spacing={space(6)}>
            {vlan.name}
            <Badge size="small" variant="neutral">
              Default
            </Badge>
          </HStack>
        );
      }

      return <span>{vlan.name}</span>;
    },
  }),
  builder.data(
    (vlan) => {
      if (vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Dhcp) {
        return vlan.ipV4ClientAssignmentProtocol;
      }
      if (vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static) {
        return [vlan.ipV4ClientGateway, vlan.ipV4ClientPrefixLength].filter(truthy).join('/');
      }
      return '';
    },
    {
      id: 'vlan-subnet',
      header: 'IP configuration',
      meta: {
        alignment: 'start',
      },
      cell: ({ value }) =>
        value ? (
          <Text family="monospace">{value}</Text>
        ) : (
          <Badge variant="neutral" size="small">
            Layer 2 only
          </Badge>
        ),
    },
  ),
  builder.data((vlan) => vlan.description ?? '', {
    id: 'vlan-description',
    header: 'Description',
  }),
  builder.data((vlan) => (vlan.isInternal ? 'Internal' : 'External'), {
    id: 'vlan-read-only',
    header: 'Read-only',
    meta: {
      alignment: 'center',
    },
    enableGlobalFilter: false,
    cell: ({ row: vlan }) => {
      if (vlan.isInternal) {
        return <Icon icon="checkmark" size={12} />;
      }

      return null;
    },
  }),
];

export default function VLANList() {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;
  expectDefinedOrThrow(vlans, new ResourceNotFoundError('Unable to load VLANs'));

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const params = Nav.useRegionParams('drawer', paths.drawers.VLANDrawerPage);
  const onRowDeselect = useCloseDrawerCallback();

  return (
    <AutoTable
      columns={columns}
      data={vlans ?? []}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      onRowDeselect={onRowDeselect}
      isRowSelected={(vlan) => vlan.UUID === params?.uuid}
      getLinkTo={(vlan) =>
        makeDrawerLink(window.location, paths.drawers.VLANDrawerPage, {
          networkSlug: network.slug,
          companyName,
          uuid: vlan.UUID,
        })
      }
    />
  );
}

export function VLANsCopyDescription() {
  return (
    <>
      Copies VLANs from the <Mono>vlan</Mono> sections of the config. Copies IP and DHCP
      configurations, as well as <Mono>enable-mdns-reflection</Mono> for the VLAN and DHCP{' '}
      <Mono>static-clients</Mono>. The <Mono>tap-address</Mono> and <Mono>bridge-interfaces</Mono>{' '}
      fields are not copied as they are no longer used. The <Mono>apmgmt</Mono> VLAN's IP is changed
      to <Mono>10.102.0.2</Mono> when copying. The <Mono>novlan</Mono> VLAN is not copied. Will fail
      if a VLAN ID already exists in config 2 with a different name.
    </>
  );
}

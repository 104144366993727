import type { CellContext, ColumnMeta, Row, StringOrTemplateHeader } from '@tanstack/react-table';
import {
  type AccessorFnColumnDef,
  type ColumnSizingColumnDef,
  type DisplayColumnDef,
  type SortingColumnDef,
  type VisibilityColumnDef,
} from '@tanstack/table-core';
import React from 'react';

interface SharedColumnDef<RowType, ValueType>
  extends VisibilityColumnDef,
    SortingColumnDef<RowType>,
    ColumnSizingColumnDef {
  id: string;
  header?: StringOrTemplateHeader<RowType, ValueType>;
  meta?: ColumnMeta<RowType, ValueType>;
  enableGlobalFilter?: boolean;
}

export type RowWithHelpers<T> = Pick<
  Row<T>,
  'getIsSelected' | 'getCanSelect' | 'getToggleSelectedHandler' | 'toggleSelected' | 'index' | 'id'
> &
  T;

export function createColumnBuilder<RowType>() {
  return {
    data: <ValueType extends string | number | null | undefined>(
      accessorFn: (row: RowType) => ValueType,
      {
        cell,
        ...attrs
      }: SharedColumnDef<RowType, ValueType> & {
        cell?: (props: { value: ValueType; row: RowType }) => React.ReactNode | null;
      },
    ): AccessorFnColumnDef<RowType, ValueType> => ({
      accessorFn,
      ...attrs,
      ...(cell
        ? {
            cell: (props) =>
              React.createElement(cell, {
                row: props.row.original,
                value: props.getValue(),
              }),
          }
        : undefined),
    }),
    display: <ValueType extends string>({
      cell,
      ...attrs
    }: SharedColumnDef<RowType, ValueType> & {
      cell?: (props: {
        row: RowWithHelpers<RowType>;
        table: CellContext<RowType, ValueType>['table'];
      }) => React.ReactNode | null;
    }): DisplayColumnDef<RowType, ValueType> => ({
      // eslint-disable-next-line no-plusplus
      ...attrs,
      ...(cell
        ? {
            cell: (props) =>
              React.createElement(cell, {
                table: props.table,
                row: {
                  ...props.row.original,
                  getIsSelected: props.row.getIsSelected,
                  getCanSelect: props.row.getCanSelect,
                  getToggleSelectedHandler: props.row.getToggleSelectedHandler,
                  toggleSelected: props.row.toggleSelected,
                  index: props.row.index,
                  id: props.row.id,
                },
              }),
          }
        : undefined),
    }),
  };
}

import { Drawer, DrawerContent, DrawerHeader, SummaryList } from '@meterup/atto';

import { StickyObjectHeader } from '../../Object/ObjectHeader';

export default function ISPEventDrawer() {
  return (
    <Drawer>
      <DrawerHeader icon="log" heading="Event" />
      <DrawerContent gutter="none">
        <StickyObjectHeader icon="log" name="WAN saturated" status="medium" ends="card" />
        <SummaryList pairs={[{ label: 'Timestamp', value: 'Jul 9, 2024, 11:19:08 AM' }]} />
      </DrawerContent>
    </Drawer>
  );
}

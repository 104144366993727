import React from 'react';

export default function Allstream() {
  return (
    <svg
      width="113"
      height="20"
      viewBox="0 0 113 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.3867 19.1585L41.4169 19.1588C42.1976 18.4802 43.0771 16.6707 43.8553 14.7375H42.6989C42.122 16.8804 41.6453 18.7502 41.3867 19.1609"
        fill="#5FAFE1"
        fillOpacity="0.984314"
      />
      <path
        d="M44.337 0.729399C44.834 0.415636 44.8633 2.10734 44.5037 4.75642H45.7127C45.8928 1.58553 45.4246 -0.149359 44.9405 0.0101016C44.7242 0.0817088 44.5087 0.327936 44.2832 0.750265H44.3084C44.3179 0.741795 44.3269 0.731787 44.337 0.727167"
        fill="#5FAFE1"
        fillOpacity="0.984314"
      />
      <path
        d="M41.2913 19.2726C40.7932 19.587 40.8508 17.4363 41.2204 14.7344H40.0114C39.8223 17.9514 40.2029 20.1516 40.6875 19.9919C40.9034 19.921 41.1193 19.6738 41.3446 19.2516H41.3193C41.3095 19.2601 41.3014 19.2701 41.2913 19.2747"
        fill="#F59130"
      />
      <path
        d="M44.2413 0.838283H44.2103C43.4316 1.51539 42.6322 2.88548 41.8664 4.74873H43.0208C43.5859 2.67089 43.9832 1.24652 44.2413 0.835815"
        fill="#F59130"
      />
      <path
        d="M61.761 8.68016C59.7846 8.67247 58.8284 9.96323 58.5738 11.3906C58.2615 13.1427 59.2202 14.1176 60.7929 14.1209C61.5684 14.1209 62.164 14.0081 62.7485 13.7063L63.193 12.6714C62.7521 12.8451 62.0479 13.2336 61.1227 13.2317C59.3995 13.2317 59.6001 12.0315 59.7134 11.4384L63.5443 11.4461L63.604 11.1337C63.8514 9.72783 63.0281 8.68286 61.761 8.68016ZM61.5656 9.60845C62.4652 9.60845 62.547 10.2353 62.4891 10.5618V10.5649L59.9816 10.5572C60.2739 9.91672 60.8083 9.60722 61.5656 9.60845Z"
        fill="black"
      />
      <path
        d="M37.5321 8.60912C36.9149 8.60912 36.1862 8.70198 35.3884 8.90171L35.0271 9.9809C35.9116 9.60061 36.5806 9.45555 37.2863 9.45693C37.7333 9.45693 38.3472 9.54433 38.2657 10.0002C38.071 11.094 34.4939 10.4738 34.1345 12.4905C33.9381 13.5887 34.608 14.0887 35.7847 14.0908C36.2776 14.0908 36.6452 14.0369 36.9926 13.9814L37.381 13.025C37.0347 13.138 36.5349 13.2803 36.135 13.2796C35.6868 13.2796 35.1244 13.1523 35.2162 12.6362C35.3715 11.7714 37.0636 11.6771 38.0408 11.2638L37.5584 13.9676H38.8022L39.5044 10.033C39.6717 9.10028 39.0296 8.60973 37.5321 8.60673"
        fill="black"
      />
      <path
        d="M77.1257 8.68688C76.1577 8.68688 75.4235 9.04901 75.0114 9.50753C74.8028 9.07288 74.3109 8.68111 73.6049 8.67988C72.9992 8.67988 72.4383 8.81917 71.9798 9.2472L72.0675 8.75587H70.8234L69.8817 14.0328H71.1252L71.6788 10.9474C71.8342 10.0686 72.3761 9.66421 73.1464 9.66583C73.8018 9.66583 74.121 10.2754 73.9977 11.0549L73.4639 14.0374H74.7145L75.2496 10.9522C75.4069 10.0661 75.9556 9.66906 76.7254 9.67037C77.3808 9.67037 77.7116 10.2803 77.5764 11.0598L77.0451 14.0423H78.2927C78.2927 14.0423 78.7316 11.5617 78.8663 10.8055C79.0225 9.93062 78.6499 8.68458 77.1257 8.68166"
        fill="black"
      />
      <path
        d="M67.3784 8.65955C66.7615 8.65955 66.0325 8.75271 65.2342 8.95213L64.8737 10.0315C65.7581 9.65119 66.4272 9.5062 67.1326 9.50759C67.5797 9.50759 68.1938 9.59499 68.1123 10.0505C67.9176 11.1447 64.34 10.5246 63.9809 12.5408C63.7848 13.6391 64.4543 14.1391 65.6313 14.1416C66.124 14.1416 66.4915 14.0877 66.8392 14.0321L67.2274 13.0756C66.881 13.1887 66.3818 13.331 65.9814 13.3302C65.5335 13.3302 64.9707 13.2028 65.063 12.687C65.2173 11.8218 66.9101 11.7278 67.8872 11.3144L67.4048 14.0182H68.648L69.3502 10.0838C69.5178 9.15102 68.8762 8.66017 67.3784 8.65716"
        fill="black"
      />
      <path
        d="M46.028 9.95548C45.7893 11.2925 48.1325 11.8797 47.989 12.6789C47.9184 13.0787 47.4226 13.2051 47.0062 13.2039C46.4896 13.2031 45.9927 13.0491 45.547 12.8264L45.5448 13.8885C45.9552 14.008 46.4776 14.1006 46.9458 14.1014C47.5195 14.1014 48.9489 14.005 49.1869 12.6878C49.4335 11.3269 47.1371 10.7183 47.2672 9.99652C47.34 9.58666 47.8983 9.52068 48.1237 9.52122C48.7066 9.52122 49.0679 9.67737 49.3882 9.82105L49.3901 8.76711C49.0492 8.6838 48.582 8.61966 48.205 8.61889C46.414 8.61119 46.0895 9.60807 46.028 9.95548Z"
        fill="black"
      />
      <path
        d="M44.3855 5.49951L42.8747 13.9663H44.1184L45.6293 5.49968L44.3855 5.49951Z"
        fill="black"
      />
      <path d="M42.843 5.49951H41.5997L40.0891 13.9663H41.3327L42.843 5.49951Z" fill="black" />
      <path
        d="M56.3106 9.20893L56.3986 8.7176H55.1546L54.2131 13.9947H55.4563L55.9825 11.0487C56.1216 10.2672 56.7091 9.61878 57.6152 9.62039C57.8836 9.62093 58.1724 9.69755 58.4274 9.78478L58.52 8.73032C58.3261 8.67488 58.149 8.642 57.9369 8.64146C57.3309 8.64146 56.7697 8.78082 56.3106 9.20893Z"
        fill="black"
      />
      <path
        d="M52.6502 7.40958L51.3539 7.70524L51.1774 8.70729H50.5749L50.4182 9.58867H51.0215L50.5154 12.4582C50.3478 13.3977 50.5002 14.1119 51.9355 14.1148C52.2935 14.1153 52.6928 14.054 53.0587 13.9548L53.3518 13.0015C52.9003 13.131 52.5399 13.2262 52.2628 13.2257C51.8426 13.2257 51.6579 13.0136 51.7212 12.6619L52.2645 9.59114H53.8274L53.9849 8.70975H52.4204"
        fill="black"
      />
    </svg>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import WebhooksAdd from '../../../../../../components/Settings/Organization/Integrations/Webhooks/WebhooksAdd';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/org/integrations/webhooks/add',
  title: 'Add webhook - Webhooks - Integrations - Org',
});

export default function WebhooksAddPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  return <WebhooksAdd />;
}

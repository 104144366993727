import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';
import { useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import { paths } from '../../../../../constants';
import { useIsActiveMatcher } from '../../../../../hooks/useIsActive';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { DeviceConfigContextProvider } from './DeviceConfigContext';
import DeviceConfigPageHeader from './DeviceConfigPageHeader';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/operators/config/:serialNumber',
  layout: 'VirtualNetworkLayout',
});

export default function DeviceConfigLayout() {
  const overridesParams = Nav.useRegionParams('root', paths.pages.DeviceConfigOverridesPage);
  const historyParams = Nav.useRegionParams('root', paths.pages.DeviceConfigHistoryPage);
  const finalSerialNumber = overridesParams?.serialNumber ?? historyParams?.serialNumber;
  checkDefinedOrThrow(finalSerialNumber);
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const isActiveTest = useIsActiveMatcher();
  const isDeviceConfigHistoryPageActive = isActiveTest({
    path: paths.pages.DeviceConfigHistoryPage,
    end: false,
  });
  const isDeviceConfigOverridesPageActive = isActiveTest({
    path: paths.pages.DeviceConfigOverridesPage,
    end: false,
  });
  const navigate = useNavigate();
  const setSerialNumber = useCallback(
    (newSerialNumber: string) => {
      if (isDeviceConfigHistoryPageActive) {
        navigate(
          makeLink(paths.pages.DeviceConfigHistoryPage, {
            companyName,
            networkSlug: network.slug,
            serialNumber: newSerialNumber,
          }),
        );
      }
      if (isDeviceConfigOverridesPageActive) {
        navigate(
          makeLink(paths.pages.DeviceConfigOverridesPage, {
            companyName,
            networkSlug: network.slug,
            serialNumber: newSerialNumber,
          }),
        );
      }
    },
    [
      companyName,
      navigate,
      network,
      isDeviceConfigHistoryPageActive,
      isDeviceConfigOverridesPageActive,
    ],
  );
  return (
    <DetailPageLayout
      main={
        <DeviceConfigContextProvider
          serialNumber={finalSerialNumber}
          setSerialNumber={setSerialNumber}
        >
          <Outlet />
        </DeviceConfigContextProvider>
      }
      header={
        <DeviceConfigPageHeader
          serialNumber={finalSerialNumber}
          setSerialNumber={setSerialNumber}
        />
      }
    />
  );
}

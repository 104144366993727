import type { MeterV1NetworkVLAN } from '@meterup/config';
import type { SortingState } from '@tanstack/react-table';
import { EmptyState, Pane, PaneContent, PaneHeader, SearchInput, Small } from '@meterup/atto';
import { AutoTable, NeutralBadge, TaggedOrUntaggedBadge } from '@meterup/common';
import { orderBy } from 'lodash-es';
import React, { useMemo } from 'react';

import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

const builder = createColumnBuilder<MeterV1NetworkVLAN>();

const columns = [
  builder.data((d) => d.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.vlanId.toString(), {
    id: 'id',
    header: 'ID',
    meta: {
      alignment: 'end',
      maxWidth: 80,
    },
    cell: (p) => <NeutralBadge ends="card">{p.value}</NeutralBadge>,
  }),
  builder.data((d) => d.json.dhcp?.gateway ?? '', {
    id: 'gateway',
    header: 'Gateway',
    cell: (p) => <Small family="monospace">{p.value}</Small>,
  }),
  builder.data((d) => (d.untagged ? 'Untagged' : 'Tagged'), {
    id: 'tagging',
    header: 'Tagging',
    cell: (p) => <TaggedOrUntaggedBadge isUntagged={p.row.untagged} />,
  }),
];

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/network-wide/vlans',
  layout: 'NetworkLayout',
});

export default function LegacyVLANListPage() {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const model = useControllerConfig(controllerName);
  const onRowDeselect = useCloseDrawerCallback();

  const sortedVLANs = useMemo(
    () => orderBy(model.getCustomerFacingVLANs(), (d) => d.vlanId),
    [model],
  );

  const params = Nav.useRegionParams('drawer', paths.drawers.LegacyVLANSummaryPage);
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return (
    <Pane>
      <PaneHeader
        icon="vlan"
        heading="VLANs"
        actions={
          <SearchInput
            placeholder="..."
            aria-label="Filter VLANs"
            scope="scoped"
            value={globalFilter}
            onChange={setGlobalFilter}
            minWidth="56px"
          />
        }
      />
      <PaneContent>
        {sortedVLANs.length > 0 ? (
          <AutoTable
            data={sortedVLANs}
            columns={columns}
            sortingState={sortingState}
            globalFilter={globalFilter}
            onChangeSortingState={setSortingState}
            onRowDeselect={onRowDeselect}
            isRowSelected={(d) => d.name === params?.vlanName}
            getLinkTo={(d) =>
              makeDrawerLink(window.location, paths.drawers.LegacyVLANSummaryPage, {
                vlanName: d.name,
                controllerName,
                companyName,
              })
            }
          />
        ) : (
          <EmptyState icon="vlan" heading="No VLANs" />
        )}
      </PaneContent>
    </Pane>
  );
}

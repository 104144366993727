import type { OverlayTriggerState } from '@meterup/atto';
import { notify } from '@meterup/common';
import { makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { RackElevationDevice } from './utils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import {
  detachRackElevationDeviceMutation,
  mutationErrorMessage,
  rackElevationQuery,
  rackElevationsQuery,
} from './utils';

export default function DeleteMountDialog({
  uuid,
  device,
  state,
}: {
  uuid: string;
  device: RackElevationDevice;
  state: OverlayTriggerState;
}) {
  const close = useCloseDrawerCallback();
  const network = useNetwork();
  const queryClient = useQueryClient();
  const deleteMutation = useGraphQLMutation(detachRackElevationDeviceMutation);
  const elevation = useGraphQL(rackElevationQuery, { UUID: uuid }).data?.rackElevation;

  const handleDelete = useCallback(() => {
    if (deleteMutation.isLoading) return;
    deleteMutation.mutate(
      { UUID: device.UUID },
      {
        onSuccess: () => {
          notify('Successfully removed device from rack elevation.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(rackElevationsQuery, { networkUUID: network.UUID }),
          );
          queryClient.invalidateQueries(makeQueryKey(rackElevationQuery, { UUID: device.UUID }));
          state.close();
          close();
        },
        onError: (error) => {
          notify(mutationErrorMessage('There was an error removing this device', error), {
            variant: 'negative',
          });
        },
      },
    );
  }, [device.UUID, queryClient, deleteMutation, network.UUID, state, close]);

  if (!elevation) return null;

  return <DeleteDialog label={device.label} handleDelete={handleDelete} state={state} />;
}

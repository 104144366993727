import FloorPlans from '../../../../../components/FloorPlans/FloorPlans';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/design/floor-plans',
  layout: 'VirtualNetworkLayout',
  title: 'Floor plans - Design',
});

export default function FloorPlansPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermCompanyGet))
      }
      should404OnAccessDenied
    >
      <FloorPlans />
    </IsPermitted>
  );
}

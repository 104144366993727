import type { BadgeSize, BadgeVariant, BandVariant, IconName } from '@meterup/atto';
import {
  Badge,
  BadgeGroup,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Tooltip,
} from '@meterup/atto';
import { expectDefinedOrThrow, MetricsLoadError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';
import * as d3 from 'd3';
import { groupBy } from 'lodash-es';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import APIv2 from '../../api/apiv2';
import MetricsAPI, { ISPQuality } from '../../api/metrics';
import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useControllerDataFromPathOrNull } from '../../hooks/useControllerDataFromPath';
import { useCurrentControllerOrNull } from '../../hooks/useCurrentControllerOrNull';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useNetwork, useNetworkUUID } from '../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../providers/CurrentControllerProvider';
import { usePermissions } from '../../providers/PermissionsProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { SwitchesQuery } from '../Hardware/Switches/utils';
import { AccessPointsQuery } from '../Wireless/utils';

type StatsListProps = {
  wan: string;
};

type ISPQualityBadgeProps = {
  wan: string;
};

const typeLabels = {
  switch: 'switches',
  'access-point': 'access points',
  'security-appliance': 'security appliances',
};

export function DeviceBadge({
  offlineCount,
  onlineCount,
  size = 'small',
  type,
  variant,
}: {
  offlineCount: number;
  onlineCount: number;
  size?: BadgeSize;
  type: 'switch' | 'access-point' | 'security-appliance';
  variant?: 'condensed';
}) {
  const totalDeviceCount = offlineCount + onlineCount;
  if (variant === 'condensed') {
    let badgeVariant: BadgeVariant = 'positive';
    if (offlineCount > 0) {
      const offlinePercentage = offlineCount / totalDeviceCount;
      if (offlinePercentage > 0.5) {
        badgeVariant = 'negative';
      } else {
        badgeVariant = 'attention';
      }
    }
    if (onlineCount === 0 && offlineCount === 0) {
      badgeVariant = 'neutral';
    }
    // only 1 controller needs to be online
    if (onlineCount > 0 && type === 'security-appliance') {
      badgeVariant = 'positive';
    }

    return (
      <Tooltip contents={`${onlineCount}/${totalDeviceCount} ${typeLabels[type]} online`}>
        <Badge
          arrangement="leading-icon"
          ends="pill"
          icon={type}
          size={size}
          variant={badgeVariant}
        >
          {onlineCount}/{totalDeviceCount}
        </Badge>
      </Tooltip>
    );
  }
  return (
    <BadgeGroup alignment="center" size="small">
      {offlineCount > 0 && (
        <Badge arrangement="leading-icon" ends="pill" icon={type} size={size} variant="negative">
          {offlineCount} offline
        </Badge>
      )}
      {onlineCount === totalDeviceCount && (
        <Badge arrangement="leading-icon" ends="pill" icon={type} size={size} variant="positive">
          All online
        </Badge>
      )}
      {onlineCount > 0 && onlineCount !== totalDeviceCount && (
        <Badge arrangement="leading-icon" ends="pill" icon={type} size={size} variant="positive">
          {onlineCount} online
        </Badge>
      )}
    </BadgeGroup>
  );
}

export function ControllerBadge({ isOnline }: { isOnline: boolean }) {
  const variant = isOnline ? 'positive' : 'negative';

  return (
    <Tooltip contents={`Your security appliance is ${isOnline ? 'online' : 'offline'}`}>
      <Badge
        arrangement="leading-icon"
        ends="pill"
        icon="security-appliance"
        size="small"
        variant={variant}
      >
        {variant === 'positive' ? 'Online ' : 'Offline'}
      </Badge>
    </Tooltip>
  );
}

function ISPQualityBadge({ wan }: ISPQualityBadgeProps) {
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const controllerState = controller.lifecycle_status;

  const { data: ispQualityMetric } = useQuery(
    ['metrics', ISPQuality.series_id, controllerName, wan],
    () => MetricsAPI.fetchMetric(ISPQuality, { controllerName, controllerState, wan }),
    {
      suspense: true,
    },
  );
  expectDefinedOrThrow(
    ispQualityMetric,
    new MetricsLoadError('Unable to load ISP Quality metrics'),
  );

  let metricValue: number | undefined;
  let metricVariant: BandVariant = 'neutral';
  let icon: IconName = 'information';

  if (ispQualityMetric.length > 0) {
    const points = ispQualityMetric[0].data;
    const latestMetricPoint = points[points.length - 1];
    metricValue = latestMetricPoint.value;
    metricVariant = metricValue > 0.5 ? 'positive' : 'negative';
    icon = metricValue > 0.5 ? 'checkmark' : 'cross';
  }

  return (
    <Badge arrangement="leading-icon" ends="pill" icon={icon} size="small" variant={metricVariant}>
      {metricValue !== undefined ? d3.format('.2~%')(metricValue) : 'N/A'}
    </Badge>
  );
}

const useLoadDevices = () => {
  const { currentController, controllerNameFromPath } = useControllerDataFromPathOrNull();

  const flags = useFeatureFlags();
  const api = useMemo(
    () => (currentController ? new APIv2(currentController, flags) : null),
    [currentController, flags],
  );

  const { data } = useQuery(
    ['metrics', 'controller_devices', controllerNameFromPath],
    () => api?.devicesForController(),
    {
      suspense: true,
      enabled: !!controllerNameFromPath && !!api,
    },
  );

  return data;
};

function useAPCounts() {
  const data = useLoadDevices();
  const networkUUID = useNetworkUUID();
  const isWOS2 = useNosFeatureEnabled(NosFeature.WOS2);
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);

  const accessPointsData = useGraphQL(
    AccessPointsQuery,
    {
      networkUUID: networkUUID!,
      includeUptime,
    },
    {
      enabled: !!networkUUID && isWOS2,
    },
  ).data;

  const apCounts: { online: number; offline: number } = useMemo(() => {
    if (isWOS2) {
      const hardwareDevices =
        accessPointsData?.virtualDevicesForNetwork
          .map((vd) => vd.hardwareDevice)
          .filter((hd): hd is NonNullable<typeof hd> => !!hd && hd.isActive) ?? [];

      const grouped = groupBy(hardwareDevices, (hd) =>
        hd.isConnectedToBackend ? 'online' : 'offline',
      );
      return {
        online: grouped.online?.length ?? 0,
        offline: grouped.offline?.length ?? 0,
      };
    }

    if (data?.access_points) {
      const grouped = groupBy(data.access_points, (ap) => ap.connected_status);
      return {
        online: grouped.online?.length ?? 0,
        offline: grouped.offline?.length ?? 0,
      };
    }

    return {
      online: 0,
      offline: 0,
    };
  }, [data?.access_points, accessPointsData, isWOS2]);

  return apCounts;
}

function useSwitchCounts() {
  const networkUUID = useNetworkUUID();

  const switchesData = useGraphQL(
    SwitchesQuery,
    {
      networkUUID: networkUUID!,
    },
    {
      enabled: !!networkUUID,
    },
  ).data;

  const switchCounts: { online: number; offline: number } = useMemo(() => {
    if (switchesData?.virtualDevicesForNetwork) {
      const hardwareDevices = switchesData.virtualDevicesForNetwork
        .map((vd) => vd.hardwareDevice)
        .filter((hd): hd is NonNullable<typeof hd> => !!hd && hd.isActive);

      const grouped = groupBy(hardwareDevices, (hd) =>
        hd.isConnectedToBackend ? 'online' : 'offline',
      );
      return {
        online: grouped.online?.length ?? 0,
        offline: grouped.offline?.length ?? 0,
      };
    }
    return {
      online: 0,
      offline: 0,
    };
  }, [switchesData]);
  return switchCounts;
}

function useControllerConnectedStatus() {
  const data = useLoadDevices();
  return data?.connected_status === 'online';
}

/**
 * For Config 1 networks. For COS2 networks use StatsList2.
 */
export default function StatsList({ wan }: StatsListProps) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const controllerName = useCurrentControllerOrNull();

  const apCounts = useAPCounts();
  const switchCounts = useSwitchCounts();
  const controllerConnectedStatus = useControllerConnectedStatus();
  const isSOS = useNosFeatureEnabled(NosFeature.SOS);
  const isWOS2 = useNosFeatureEnabled(NosFeature.WOS2);

  return (
    <Section relation="stacked">
      <SectionHeader icon="information" heading="Stats" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>Security appliance</SummaryListKey>
            <SummaryListValue>
              <ControllerBadge isOnline={controllerConnectedStatus} />
            </SummaryListValue>
          </SummaryListRow>
          {(apCounts.online > 0 || apCounts.offline > 0) && (
            <SummaryListRow>
              <SummaryListKey>Access points</SummaryListKey>
              <SummaryListValue>
                {isWOS2 || controllerName ? (
                  <Link
                    to={
                      isWOS2
                        ? makeLink(paths.pages.AccessPointsPage, {
                            companyName,
                            networkSlug: network.slug,
                          })
                        : makeLink(paths.pages.LegacyAccessPointListPage, {
                            companyName,
                            controllerName: controllerName!,
                          })
                    }
                  >
                    <DeviceBadge
                      type="access-point"
                      offlineCount={apCounts.offline}
                      onlineCount={apCounts.online}
                    />
                  </Link>
                ) : (
                  <DeviceBadge
                    type="access-point"
                    offlineCount={apCounts.offline}
                    onlineCount={apCounts.online}
                  />
                )}
              </SummaryListValue>
            </SummaryListRow>
          )}
          {isSOS && (switchCounts.offline > 0 || switchCounts.online > 0) && (
            <SummaryListRow>
              <SummaryListKey>Switches</SummaryListKey>
              <SummaryListValue>
                <Link
                  to={makeLink(paths.pages.SwitchListPage, {
                    companyName,
                    networkSlug: network.slug,
                    tab: 'list',
                  })}
                >
                  <DeviceBadge
                    type="switch"
                    offlineCount={switchCounts.offline}
                    onlineCount={switchCounts.online}
                  />
                </Link>
              </SummaryListValue>
            </SummaryListRow>
          )}
          <SummaryListRow>
            <SummaryListKey>ISP quality</SummaryListKey>
            <SummaryListValue>
              <ISPQualityBadge wan={wan} />
            </SummaryListValue>
          </SummaryListRow>
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

import { FieldContainer, PrimaryToggleField } from '@meterup/atto';
import { DemoModeContext } from '@meterup/authorization';
import { useContext } from 'react';

export default function DemoModeToggle() {
  const { isDemoMode, setIsDemoMode } = useContext(DemoModeContext);
  return (
    <FieldContainer>
      <PrimaryToggleField
        selected={isDemoMode}
        onChange={setIsDemoMode}
        label="Demo mode"
        description="If enabled, the dashboard will hide features that only Meter employees can see."
        data-testid="Settings_userConfigure_demoModeToggle"
        internal
      />
    </FieldContainer>
  );
}

import {
  Button,
  DockTarget,
  DropdownMenu,
  DropdownMenuPopover,
  DropdownMenuTrigger,
  PrimaryField,
  space,
  styled,
  Textarea,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';

import { createUserFeedback } from '../../api/api';
import { useCurrentCompanyFromPathOrNull } from '../../hooks/useCurrentCompanyFromPath';

const FieldContainer = styled('div', {
  padding: '$8',
  width: '284px',
});

export function FeedbackDropdown() {
  const companyMembership = useCurrentCompanyFromPathOrNull();
  const [feedback, setFeedback] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const createUserFeedbackMutation = useMutation(
    async () =>
      companyMembership
        ? createUserFeedback(feedback, companyMembership.sid, window.location.href)
        : null,
    {
      onMutate: () => setFeedback(''),
      onSuccess: () => {
        notify('Feedback submitted!', {
          variant: 'positive',
          icon: 'checkmark',
        });
      },
      onError: () => {
        notify('There was an error sending the feedback. Please try again.', {
          variant: 'negative',
          icon: 'cross',
        });
      },
    },
  );

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = event.target.value;
    if (val) {
      setErrorMessage('');
    }
    setFeedback(val);
  };

  const handleFeedbackSubmit = () => {
    if (feedback.trim() !== '') {
      createUserFeedbackMutation.mutate();
    } else {
      setFeedback('');
      setErrorMessage('Feedback is required');
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DockTarget aria-label="Open the feedback menu" label="Feedback" icon="megaphone" arrow />
      </DropdownMenuTrigger>
      <DropdownMenuPopover side="right" align="end">
        <FieldContainer>
          <VStack spacing={space(8)}>
            <PrimaryField
              errorMessage={errorMessage}
              element={
                <Textarea
                  invalid={errorMessage !== ''}
                  value={feedback}
                  onChange={handleFeedbackChange}
                />
              }
              label="Any feedback?"
            />
            <Button
              type="button"
              variant="primary"
              arrangement="leading-label"
              icon="arrow-right"
              onClick={handleFeedbackSubmit}
              width="100%"
            >
              Send
            </Button>
          </VStack>
        </FieldContainer>
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}

import { HStack, styled } from '@meterup/atto';
import { colors, darkThemeSelector } from '@meterup/atto/src/stitches.config';

export const ListItemLabelInner = styled(HStack, {
  alignItems: 'center',
});

export const Divider = styled('div', {
  height: 2,
  flexGrow: 1,
  marginLeft: '$8',
  // flex: "none",
  order: 1,
  backgroundColor: colors.gray50,
  [darkThemeSelector]: {
    backgroundColor: colors.gray900,
  },
});

export const TopDivider = styled('div', {
  height: '$1',
  width: '100%',
  margin: '$4 auto',
  backgroundColor: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
});

export const BorderlessList = styled('div', {
  // backgroundColor: "pink",
  border: '0 none !important',
  boxShadow: 'unset !important',
  '& > div': {
    border: '0 none !important',
    boxShadow: 'unset !important',
    alignItems: 'center',
    flexGap: '$2',
    padding: '0 !important',
    '& dt': {
      flex: 0.4,
    },
    '& dd': {
      flex: 0.6,
      textAlign: 'left',
      justifyContent: 'start',
    },
  },
});

import { Tab } from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../../constants';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';

export enum IntegrationsValidTabs {
  Receivers = 'receivers',
  Services = 'services',
  Webhooks = 'webhooks',
}

export default function IntegrationsTabs({ activeTab }: { activeTab: IntegrationsValidTabs }) {
  const companyName = useCurrentCompany();
  return (
    <>
      {/* <Tab
        as={Link}
        to={makeLink(paths.pages.ServicesPage, {
          companyName,
        })}
        selected={activeTab === IntegrationsValidTabs.Services}
        icon="service"
      >
        Services
      </Tab> */}
      <Tab
        as={Link}
        to={makeLink(paths.pages.WebhooksPage, {
          companyName,
        })}
        selected={activeTab === IntegrationsValidTabs.Webhooks}
        icon="webhook"
      >
        Webhooks
      </Tab>
      <Tab
        as={Link}
        to={makeLink(paths.pages.ReceiversPage, {
          companyName,
        })}
        selected={activeTab === IntegrationsValidTabs.Receivers}
        icon="tag"
      >
        Receivers
      </Tab>
    </>
  );
}

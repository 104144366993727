import React from 'react';

import { PropsOf } from '@meterup/atto/src/utilities/types/polymorphicAsProp';
import { Link } from 'react-router-dom';

import { isAbsoluteURL } from './hooks/useSafeNavigate';

type SafeLinkProps = {
  children: React.ReactNode;
  newWindow?: boolean;
} & PropsOf<typeof Link>;

const NewWindowProps = {
  target: '_blank',
  rel: 'noopener',
};

export function SafeLink({ children, newWindow, to, ...props }: SafeLinkProps) {
  const allProps = newWindow ? { ...NewWindowProps, ...props } : props;
  if (isAbsoluteURL(to)) {
    return (
      <a href={to} {...allProps}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...allProps}>
      {children}
    </Link>
  );
}

import { Pane, PaneHeader, Tab } from '@meterup/atto';
import { Suspense } from 'react';
import { useNavigate } from 'react-router';

import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useNetworkWideCrumbs } from '../../utils/routing';
import IsPermitted from '../permissions/IsPermitted';
import { PaneContentFallback } from '../Placeholders/AppLoadingFallback';
import { ReactRouterLink } from '../ReactRouterLink';
import PolicyRouting, { AddPolicyRoutingRuleButton } from './PolicyRouting';
import RoutingTable from './RoutingTable';
import RoutingTableFilters from './RoutingTableFilters';
import StaticRoutes, { AddStaticRouteButton } from './StaticRoutes';
import { RoutingTab } from './utils';

function RoutingActions({ tab }: { tab: RoutingTab }) {
  switch (tab) {
    case RoutingTab.PolicyRouting:
      return <AddPolicyRoutingRuleButton />;
    case RoutingTab.StaticRoutes:
      return <AddStaticRouteButton />;
    default:
      return null;
  }
}

function RoutingContent({ tab }: { tab: RoutingTab }) {
  switch (tab) {
    case RoutingTab.PolicyRouting:
      return <PolicyRouting />;
    case RoutingTab.StaticRoutes:
      return <StaticRoutes />;
    case RoutingTab.RoutingTable:
      return <RoutingTable />;
  }
}

export function RoutingTabs({ tab }: { tab: RoutingTab }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();

  const setActiveTab = (newTab: RoutingTab) => {
    navigate(
      makeLink(paths.pages.RoutingPage, {
        companyName,
        networkSlug: network.slug,
        tab: newTab,
      }),
    );
  };

  return (
    <>
      <Tab
        icon="connection"
        selected={tab === RoutingTab.PolicyRouting}
        onClick={() => setActiveTab(RoutingTab.PolicyRouting)}
      >
        Policy routing
      </Tab>
      <Tab
        icon="ip-address"
        selected={tab === RoutingTab.StaticRoutes}
        onClick={() => setActiveTab(RoutingTab.StaticRoutes)}
      >
        Static routes
      </Tab>
      <Tab
        icon="list"
        selected={tab === RoutingTab.RoutingTable}
        onClick={() => setActiveTab(RoutingTab.RoutingTable)}
      >
        Routing table
      </Tab>
    </>
  );
}

export default function Routing({ tab }: { tab: RoutingTab }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const networkWideCrumb = useNetworkWideCrumbs();
  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...networkWideCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.RoutingPage, {
                companyName,
                networkSlug: network.slug,
                tab: 'policy-routing',
              }),
              selected: true,
              label: 'Routing',
            },
          },
        ]}
        icon="connection"
        heading="Routing"
        tabs={<RoutingTabs tab={tab} />}
        contentActions={
          <IsPermitted permissions={PermissionType.PermNetworkRouteWrite}>
            <RoutingActions tab={tab} />
          </IsPermitted>
        }
        views={tab === RoutingTab.RoutingTable && <RoutingTableFilters />}
      />
      <Suspense fallback={<PaneContentFallback />}>
        <RoutingContent tab={tab} />
      </Suspense>
    </Pane>
  );
}

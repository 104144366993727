import { Button, ControlGroup, PaneFooter, Select, SelectItem, Small } from '@meterup/atto';

import type { PaginationControls } from './utils/usePagination';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { pluralize } from '../../utils/strings';
import { useFilterAndGroup } from './filtering/useFilterAndGroup';
import { useHubNetworksOverview } from './useHubNetworksOverview';

function PaginationButtons({ controls }: { controls: PaginationControls }) {
  return (
    <ControlGroup>
      <Button
        size="small"
        arrangement="leading-icon"
        icon="arrow-left"
        variant="secondary"
        disabled={!controls.hasPrevPage}
        onClick={controls.goTo.prev}
      >
        Prev
      </Button>
      <Select
        controlSize="small"
        value={String(controls.page)}
        onValueChange={(idxStr) => {
          const idx = Number(idxStr);
          controls.goTo.page(idx);
        }}
        aria-label="Page"
        disabled={controls.pages.length === 1}
      >
        {controls.pages.map((p) => (
          <SelectItem aria-label={`${p}`} key={p}>
            {p + 1}
          </SelectItem>
        ))}
      </Select>
      <Button
        size="small"
        icon="arrow-right"
        variant="secondary"
        disabled={!controls.hasNextPage}
        onClick={controls.goTo.next}
      >
        Next
      </Button>
    </ControlGroup>
  );
}

export default function HubPagination({ pagination }: { pagination: PaginationControls }) {
  const companyName = useCurrentCompany();
  const networksOverview = useHubNetworksOverview(companyName);
  const filter = useFilterAndGroup(networksOverview.networks);
  return (
    <PaneFooter
      secondaryActions={
        <Small>
          {filter.data.rows.length} {pluralize(filter.data.rows.length, 'result')}
        </Small>
      }
      actions={filter.data.view !== 'map' && <PaginationButtons controls={pagination} />}
    />
  );
}

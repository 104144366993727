import { useCallback, useEffect, useMemo, useState } from 'react';

export function usePagination(rowCount: number, pageSize?: number) {
  const setPageSize = pageSize || 50;
  const [page, setPage] = useState(0);
  const totalPages = Math.ceil(rowCount / setPageSize);

  useEffect(() => {
    setPage(0);
  }, [rowCount]);

  const pages = useMemo(() => {
    const p: number[] = [];
    // ++ is clearest for this tbh
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalPages; i++) {
      p.push(i);
    }
    return p;
  }, [totalPages]);

  const hasNextPage = page < totalPages - 1;
  const hasPrevPage = page > 0;

  const startIndex = page * setPageSize;
  const endIndex = startIndex + setPageSize;

  const goToNextPage = useCallback(() => {
    if (!hasNextPage) return;
    setPage(page + 1);
  }, [page, hasNextPage]);

  const goToPrevPage = useCallback(() => {
    if (!hasPrevPage) return;
    setPage(page - 1);
  }, [page, hasPrevPage]);

  return {
    startIndex,
    endIndex,
    itemsPerPage: setPageSize,
    hasNextPage,
    hasPrevPage,
    page,
    pages,
    goTo: {
      next: goToNextPage,
      prev: goToPrevPage,
      page: setPage,
    },
  };
}

export type PaginationControls = ReturnType<typeof usePagination>;

import type { SortingState } from '@tanstack/react-table';
import { Badge, EmptyState, Text } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo, useRef, useState } from 'react';

import { graphql } from '../../../gql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { fromISOtoLocaleString } from '../../../utils/time';
import { SSIDDeviceTarget } from '../../DeviceTargets';
import { createColumnBuilder } from '../../Table/createColumnBuilder';

interface ScanTableItem {
  band: string;
  ssid: string;
  bssid: string;
  bw: number;
  channel: number;
  rssi: number;
  reportingInterface: string;
}

interface RawData {
  wosScanTable?: {
    scanTable?: ScanTableItem[];
  };
}

interface Stat {
  rawData: RawData;
  observedAt: string;
}

const APScanDataQuery = graphql(`
  query APScanDataQuery($networkUUID: UUID!, $virtualDeviceUUID: UUID!, $durationSeconds: Int!) {
    statsForVirtualDevices(
      networkUUID: $networkUUID
      filter: {
        virtualDeviceUUIDs: [$virtualDeviceUUID]
        types: [WOS_SCAN_TABLE]
        durationSeconds: $durationSeconds
      }
    ) {
      observedAt
      virtualDeviceUUID
      rawData
    }
  }
`);

function humanBand(band: string) {
  if (band === 'BAND_TYPE_5G') return '5 GHz';
  return '2.4 GHz';
}

function humanReportingInterface(reportingInterface: string) {
  if (reportingInterface === 'mon0') return '5G home channel';
  if (reportingInterface === 'mon1') return '2G home channel';
  return 'Scan channel';
}

const builder = createColumnBuilder<ScanTableItem & { observedAt: string }>();

const columns = [
  builder.data((s) => humanBand(s.band), {
    id: 'band',
    header: 'Band',
  }),
  builder.data((s) => humanReportingInterface(s.reportingInterface), {
    id: 'reporting-interface',
    header: 'Reported on',
  }),
  builder.data((s) => s.ssid, {
    id: 'ssid',
    header: 'SSID',
    cell: ({ value }) => <SSIDDeviceTarget ssidName={value} plainTextForeignSSIDs wrap={false} />,
  }),
  builder.data((s) => s.bssid, {
    id: 'bssid',
    header: 'BSSID',
    cell: ({ value }) => <Text family="monospace">{value}</Text>,
  }),
  builder.data((s) => s.bw.toString(), {
    id: 'bandwidth',
    header: 'Bandwidth',
    cell: ({ value }) => (
      <Badge variant="neutral" size="small">
        {value}
      </Badge>
    ),
  }),
  builder.data((s) => s.channel.toString(), {
    id: 'channel',
    header: 'Channel',
    cell: ({ value }) => (
      <Badge variant="neutral" size="small">
        {value}
      </Badge>
    ),
  }),
  builder.data((s) => s.rssi.toString(), {
    id: 'rssi',
    header: 'dBm',
    cell: ({ row, value }) => {
      const getVariant = (rssi: number) => {
        if (rssi <= -85) {
          return 'positive';
        }
        if (rssi >= -74) {
          return 'negative';
        }
        return 'attention';
      };

      return (
        <Badge arrangement="leading-icon" variant={getVariant(row.rssi)} size="small" ends="pill">
          {value}
        </Badge>
      );
    },
  }),
  builder.data((s) => fromISOtoLocaleString({ iso: s.observedAt }), {
    id: 'observedAt',
    header: 'Observed at',
  }),
];

export default function AccessPointScanDataList({
  virtualDeviceUUID,
  globalFilter,
}: {
  virtualDeviceUUID: string;
  globalFilter: string | undefined;
}) {
  const network = useNetwork();
  const [sortingState, setSortingState] = useState<SortingState>([
    { id: 'observed-at', desc: true },
    { id: 'bssid', desc: false },
    { id: 'band', desc: false },
  ]);

  const containerRef = useRef<HTMLDivElement>(null);

  const rawStats = useGraphQL(APScanDataQuery, {
    networkUUID: network.UUID,
    durationSeconds: 60,
    virtualDeviceUUID,
  }).data?.statsForVirtualDevices;

  const statsData = useMemo(() => {
    if (!rawStats || rawStats.length === 0) {
      return [];
    }

    const uniqueEntries = new Set<string>();

    return rawStats.flatMap((stat: Stat) =>
      (stat.rawData.wosScanTable?.scanTable ?? []).flatMap((tableItem: ScanTableItem) => {
        const key = `${tableItem.bssid}-${tableItem.band}-${tableItem.rssi}`;
        if (uniqueEntries.has(key)) {
          return [];
        }
        uniqueEntries.add(key);
        return [
          {
            band: tableItem.band,
            ssid: tableItem.ssid,
            bssid: tableItem.bssid,
            bw: tableItem.bw,
            channel: tableItem.channel,
            rssi: tableItem.rssi,
            reportingInterface: tableItem.reportingInterface,
            observedAt: stat.observedAt,
          },
        ];
      }),
    );
  }, [rawStats]);
  if (statsData.length === 0) {
    return <EmptyState icon="list" heading="No scan data available in the last minute" />;
  }

  return (
    <div ref={containerRef}>
      <AutoTable
        isVirtual
        tableContainerRef={containerRef}
        data={statsData}
        columns={columns}
        globalFilter={globalFilter}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
      />
    </div>
  );
}

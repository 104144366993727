import PortBatchEditDrawer from '../../../../../components/Hardware/Switches/SwitchPortBatchEditDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/switches/:virtualDeviceUUID/ports/batch-edit/:phyInterfaceUUIDs',
  title: 'Edit ports - Switch - Switches - Hardware',
});

export default function PortBatchEditPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.PortBatchEditPage);

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags, ldFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPhyInterfaceWrite) &&
            nosFlags[NosFeature.SOS] &&
            ldFlags['sos-ui'],
        )
      }
    >
      <PortBatchEditDrawer
        virtualDeviceUUID={drawerParams?.virtualDeviceUUID!}
        phyInterfaceUUIDs={drawerParams?.phyInterfaceUUIDs.split(',')!}
      />
    </IsPermitted>
  );
}

import type { Path, PathMatch } from 'react-router';
import { useIsOperator } from '@meterup/authorization';
import { useMemo } from 'react';
import { matchPath } from 'react-router';

import type { MeterLDFlags } from '../../feature_flags';
import type { PermissionType } from '../../gql/graphql';
import type { NosFeature } from '../../hooks/useNosFeatures';
import type { PermissionsContextValue } from '../../providers/PermissionsProvider';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useCompanyNosFeatures, useNosFeatures } from '../../hooks/useNosFeatures';
import { Nav } from '../../nav';
import { usePermissions } from '../../providers/PermissionsProvider';

export type PermissionArgs<ParamKey extends string> = {
  path?: Path['pathname'];
  allowOperatorAlways?: boolean;
} & (
  | {
      isPermitted: ({
        isOperator,
        ldFlags,
        permissions,
        nosFlags,
        pathParams,
      }: {
        isOperator: Readonly<boolean>;
        ldFlags: Readonly<MeterLDFlags>;
        nosFlags: Readonly<Record<NosFeature, boolean>>;
        companyNosFlags: Readonly<Record<NosFeature, boolean>>;
        permissions: Readonly<PermissionsContextValue>;
        pathParams: Readonly<PathMatch<ParamKey>['params']>;
      }) => boolean;
    }
  | {
      /**
       * A single permission, or array of permissions, all of which must be fullfilled to succeed.
       */
      permissions: PermissionType | PermissionType[];
    }
  | {
      /**
       * An array of permissions, any of which need to be fulfilled to succeed.
       */
      anyPermissions: PermissionType[];
    }
);

const defaultPermissionArgs = {
  allowOperatorAlways: true,
};

/**
 * Checks if the user has permission to access the depending resource based on various criteria.
 *
 * @param {Object} featureArgs Information about the page.
 * @param {Function} featureArgs.isPermitted Function returning a boolean indicating if the user has access.
 * @param {string} featureArgs.path Pathname pattern to match against the current route.
 * @param {PermissionType | PermissionType[]} featureArgs.permissions Single or array of PermissionType(s)
 *     required for access (conjunction AND).
 *
 * @returns {boolean} If the user passes the supplied permission check.
 */
export function useIsPermitted<ParamKey extends string>(
  featureArgs: PermissionArgs<ParamKey>,
): boolean {
  const isOperator = useIsOperator();
  const ldFlags = useFeatureFlags();
  const permissions = usePermissions();
  const nosFlags = useNosFeatures();
  const companyNosFlags = useCompanyNosFeatures();
  const rootLocation = Nav.useRegionLocation('root');
  const drawerLocation = Nav.useRegionLocation('drawer');

  const finalFeatureInfo = useMemo(
    () => ({ ...defaultPermissionArgs, ...featureArgs }),
    [featureArgs],
  );

  const match = useMemo(() => {
    if (finalFeatureInfo?.path) {
      const rootMatch = matchPath(
        { path: finalFeatureInfo.path, end: true },
        rootLocation?.pathname || '',
      );
      const drawerMatch = matchPath(
        { path: finalFeatureInfo.path, end: true },
        drawerLocation?.pathname || '',
      );
      return drawerMatch || rootMatch;
    }
    return null;
  }, [drawerLocation?.pathname, rootLocation.pathname, finalFeatureInfo.path]);

  const permitted = useMemo(() => {
    const pathParams = (match?.params || null) as PathMatch<ParamKey>['params'];
    if (finalFeatureInfo.allowOperatorAlways && isOperator) {
      return true;
    }

    if ('isPermitted' in finalFeatureInfo) {
      return finalFeatureInfo.isPermitted({
        isOperator,
        ldFlags,
        nosFlags,
        companyNosFlags,
        permissions,
        pathParams,
      });
    }

    if ('permissions' in finalFeatureInfo) {
      if (typeof finalFeatureInfo.permissions === 'string') {
        return permissions.hasPermission(finalFeatureInfo.permissions);
      }
      if (Array.isArray(finalFeatureInfo.permissions)) {
        return finalFeatureInfo.permissions.every((permission) =>
          permissions.hasPermission(permission),
        );
      }
    }

    if ('anyPermissions' in finalFeatureInfo) {
      return finalFeatureInfo.anyPermissions.some((permission) =>
        permissions.hasPermission(permission),
      );
    }

    return false;
  }, [isOperator, ldFlags, nosFlags, companyNosFlags, permissions, match, finalFeatureInfo]);

  return (
    (finalFeatureInfo.path && match && permitted) ||
    (finalFeatureInfo.path === undefined && permitted)
  );
}

import { FieldContainer, PrimaryToggleField } from '@meterup/atto';
import { useContext } from 'react';

import { SkipFeatureChecksForOperatorsContext } from '../../../../components/SkipFeatureChecksProvider';

export default function SkipFeatureChecksForOperatorsToggle() {
  const { skipFeatureChecks, setSkipFeatureChecks } = useContext(
    SkipFeatureChecksForOperatorsContext,
  );

  return (
    <FieldContainer>
      <PrimaryToggleField
        selected={skipFeatureChecks}
        onChange={setSkipFeatureChecks}
        label="Skip feature checks"
        description="If enabled, will skip feature checks for operators and all features will be shown as enabled and released publicly. This setting will persist for only the current session."
        internal
      />
    </FieldContainer>
  );
}

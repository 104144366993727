// This file does the heavy lifting of filtering and grouping the data for the Hub.
import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import type { AggregatedNetworkInfo } from '../utils/aggregateStatsForNetworks';
import type { Filters, ImmutableFilterValues } from './filters';
import type { GroupAndViewInput, QuickView } from './quickViews';
import { filterFns } from './filters';
import { defaultQuickView } from './quickViews';

type FilterAndViewState = {
  groupAndView: GroupAndViewInput;
  filterValues: ImmutableFilterValues;
  quickViewId: string | null;
};

function createImmutableFilter(
  unfilteredData: AggregatedNetworkInfo[],
  filterValues: ImmutableFilterValues,
) {
  const filteredData = unfilteredData.filter((row) => {
    for (const [k, fn] of filterFns) {
      const key = k as keyof Filters;

      /*
        Todo: would be good to find a way to tell TypeScript that we know that filterValues[key] matches
      */
      const v = filterValues[key];
      const didPass = (fn as any)(row, v);
      if (!didPass) {
        return false;
      }
    }
    return true;
  });

  return filteredData;
}

function groupByActionItem(networks: AggregatedNetworkInfo[]) {
  const groupedData = networks
    .map((network) => {
      const { deployment, ...restNetwork } = network;
      if (!deployment) return [];

      const { actionItems, ...restDeployment } = deployment;
      if (actionItems.length === 0) return [];

      return actionItems.map((ai) => ({
        ...ai,
        network: {
          ...restNetwork,
          deployment: { ...restDeployment },
        },
      }));
    })
    .flat();
  return groupedData;
}

export type NetworkInfoByActionItem = ReturnType<typeof groupByActionItem>[number];

export type FilterAndGroupOutput =
  | { groupBy: 'location'; view: 'list' | 'map' | 'card'; rows: AggregatedNetworkInfo[] }
  | { groupBy: 'action-item'; view: 'list'; rows: NetworkInfoByActionItem[] };

function filterAndGroupData(
  data: AggregatedNetworkInfo[],
  state: FilterAndViewState,
): FilterAndGroupOutput {
  // This may seem unnecessary, but it preserves TypeScript's knowledge that we have a discriminated union on key 'groupBy'
  const filteredData = createImmutableFilter(data, state.filterValues);
  if (state.groupAndView.groupBy === 'location') {
    return { groupBy: 'location', view: state.groupAndView.view, rows: filteredData };
  }
  return {
    groupBy: 'action-item',
    view: 'list',
    rows: groupByActionItem(filteredData),
  };
}

export function useFilterAndGroup(unfilteredData: AggregatedNetworkInfo[]) {
  const [state, setState] = useState<FilterAndViewState>({
    groupAndView: defaultQuickView.groupAndView,
    filterValues: defaultQuickView.filterValues,
    quickViewId: defaultQuickView.id,
  });

  // this is helpful to keep inputs feeling responsive.
  // when the value of data changes, the list/card/map re-renders and that's expensive w/ many items
  const [debouncedState] = useDebounce(state, 150);

  const data = useMemo(
    () => filterAndGroupData(unfilteredData, debouncedState),
    [unfilteredData, debouncedState],
  );

  const patch = useCallback((newFilterValues: Partial<ImmutableFilterValues>) => {
    setState((prev) => ({
      ...prev,
      filterValues: { ...prev.filterValues, ...newFilterValues },
      quickViewId: null, // if we previously had a quick view, the patching change means we have diverged from it.
    }));
  }, []);

  const applyQuickView = useCallback((quickView: QuickView) => {
    setState({ ...quickView, quickViewId: quickView.id });
  }, []);

  // We always set these together because only certain combinations of groupBy and view are valid.
  // This way we're always explicit about what we're doing.
  const setGroupAndView = useCallback((groupAndView: GroupAndViewInput) => {
    setState((prev) => ({ ...prev, groupAndView, quickViewId: null }));
  }, []);

  return {
    data,
    inputs: state,
    applyQuickView,
    setGroupAndView,
    patch,
  };
}

export type FilteredAndGroupedNetworks = ReturnType<typeof useFilterAndGroup>['data'];

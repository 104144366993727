import { Badge, BadgeGroup, Button, EmptyState, Icon } from '@meterup/atto';
import { type SortingState, AutoTable } from '@meterup/common';
import { useState } from 'react';

import type { AutoVPNGroup as AutoVPNGroupType, AutoVPNMember } from './utils';
import { paths } from '../../constants';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { NoValue } from '../NoValue';
import { ReactRouterLink } from '../ReactRouterLink';
import { createColumnBuilder } from '../Table/createColumnBuilder';

function ValueOrNoValue({ value }: { value: string | number | null | undefined }) {
  if (value != null && value !== '') return value;

  return <NoValue />;
}

const builder = createColumnBuilder<AutoVPNMember>();
const columns = [
  builder.data(({ network }) => network?.label ?? '', {
    id: 'network',
    header: 'Network',
    meta: {
      isLeading: true,
    },
    cell: ValueOrNoValue,
  }),
  builder.data((row) => row.uplink.address, {
    id: 'member-address',
    header: 'Member address',
    cell: ValueOrNoValue,
  }),
  builder.data(
    (row) =>
      `${row.permittedVLANs?.length ?? 0} ${row.permittedVLANs?.map((vlan) => vlan?.name).join(', ')}`,
    {
      id: 'vlans',
      header: 'VLANs',
      cell: ({ row }) => (
        <BadgeGroup relation="separate" size="small">
          {row.permittedVLANs?.map((vlan) => (
            <Badge icon="vlan" variant="neutral">
              {vlan?.name!}
            </Badge>
          ))}
        </BadgeGroup>
      ),
    },
  ),
  builder.data((row) => (row.isFailoverEnabled ? 'Enabled' : 'Disabled'), {
    id: 'failover-enabled',
    header: 'Failover enabled',
    cell: ({ row }) =>
      row.isFailoverEnabled ? (
        <Icon
          icon="checkmark"
          color={{
            light: 'iconPositiveLight',
            dark: 'iconPositiveDark',
          }}
        />
      ) : (
        <Icon
          icon="cross"
          color={{
            light: 'iconNegativeLight',
            dark: 'iconNegativeDark',
          }}
        />
      ),
  }),
];

export default function AutoVPNGroupMembers({ group }: { group: AutoVPNGroupType }) {
  const companyName = useCurrentCompany();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AutoVPNGroupMemberPage);
  const closeDrawer = useCloseDrawerCallback();
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      id: 'network',
      desc: false,
    },
  ]);

  if (group.members && group.members?.length > 0) {
    return (
      <AutoTable
        data={group.members}
        columns={columns}
        isRowSelected={(s) => s.UUID === drawerParams?.memberUUID}
        onRowDeselect={closeDrawer}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        enableSorting
        getLinkTo={(row) =>
          makeDrawerLink(window.location, paths.drawers.AutoVPNGroupMemberPage, {
            companyName,
            groupUUID: group.UUID!,
            memberUUID: row.UUID!,
          })
        }
      />
    );
  }

  return (
    <EmptyState
      icon="site-to-site"
      heading="No Auto VPN group members"
      action={
        <Button
          as={ReactRouterLink}
          to={makeDrawerLink(window.location, paths.drawers.AutoVPNMemberAddPage, {
            companyName,
            groupUUID: group.UUID,
          })}
          arrangement="leading-icon"
          icon="plus"
        >
          Add member
        </Button>
      }
    />
  );
}

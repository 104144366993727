import type { ServiceIconName } from '@meterup/atto';
import { HStack, ServiceIcon, Small, space } from '@meterup/atto';

import { SsoConnectionType, SsoDirectoryTypes } from '../../../../gql/graphql';

export function DirectoryTypeLogo({ type }: { type: SsoDirectoryTypes | SsoConnectionType }) {
  let providerName = '';
  let icon: ServiceIconName | null = null;
  switch (type) {
    case SsoDirectoryTypes.GSuiteDirectory:
    case SsoConnectionType.GoogleSaml:
      providerName = 'Google Suite';
      icon = 'google';
      break;
    case SsoDirectoryTypes.AzureScimv2_0:
    case SsoConnectionType.AzureSaml:
      providerName = 'Azure Active Directory';
      icon = 'microsoft';
      break;
    case SsoDirectoryTypes.OktaScimv2_0:
    case SsoConnectionType.OktaSaml:
      providerName = 'Okta';
      icon = 'okta';
      break;
    case SsoDirectoryTypes.BambooHr:
      providerName = 'BambooHR';
      icon = 'bamboo';
      break;
    case SsoDirectoryTypes.BreatheHr:
      providerName = 'BreatheHR';
      icon = 'breathe';
      break;
    case SsoDirectoryTypes.CezanneHr:
      providerName = 'CezanneHR';
      icon = 'cezanne';
      break;
    case SsoDirectoryTypes.CyberArk:
    case SsoConnectionType.CyberArkSaml:
      providerName = 'CyberArk';
      icon = 'cyberark';
      break;
    case SsoDirectoryTypes.FourthHr:
      providerName = 'FourthHR';
      icon = 'fourth';
      break;
    case SsoDirectoryTypes.GenericScimv2_0:
      providerName = 'GenericSCIMv2.0';
      break;
    case SsoDirectoryTypes.Hibob:
      providerName = 'HiBob';
      icon = 'hibob';
      break;
    case SsoDirectoryTypes.JumpCloud:
    case SsoConnectionType.JumpCloudSaml:
      providerName = 'JumpCloud';
      icon = 'jumpcloud';
      break;
    case SsoDirectoryTypes.OneLogin:
    case SsoConnectionType.OneLoginSaml:
      providerName = 'OneLogin';
      icon = 'onelogin';
      break;
    case SsoDirectoryTypes.PeopleHr:
      providerName = 'PeopleHR';
      icon = 'accesspeople';
      break;
    case SsoDirectoryTypes.Personio:
      providerName = 'Personio';
      icon = 'personio';
      break;
    case SsoDirectoryTypes.PingFederate:
    case SsoConnectionType.PingFederateSaml:
      providerName = 'PingFederate';
      icon = 'pingidentity';
      break;
    case SsoDirectoryTypes.Rippling:
    case SsoConnectionType.RipplingSaml:
      providerName = 'Rippling';
      icon = 'rippling';
      break;
    case SsoDirectoryTypes.Sftp:
      providerName = 'SFTP';
      break;
    case SsoDirectoryTypes.SftpWorkday:
    case SsoDirectoryTypes.Workday:
      providerName = 'Workday';
      icon = 'workday';
      break;
    // Following cases are specific for SSO connections only (SAML, OIDC, OAuth).
    case SsoConnectionType.Adfssaml:
      providerName = 'ADFS';
      break;
    case SsoConnectionType.AdpOidc:
      providerName = 'ADP0';
      break;
    case SsoConnectionType.Auth0Saml:
      providerName = 'Auth0';
      icon = 'auth0';
      break;
    case SsoConnectionType.CasSaml:
      providerName = 'CAS';
      icon = 'cas';
      break;
    case SsoConnectionType.DuoSaml:
      providerName = 'Duo';
      icon = 'duo';
      break;
    case SsoConnectionType.GenericSaml:
      providerName = 'SAML Provider';
      break;
    case SsoConnectionType.GenericOidc:
      providerName = 'OIDC Provider';
      break;
    case SsoConnectionType.GoogleOAuth:
      providerName = 'Google OAuth';
      icon = 'google';
      break;
    case SsoConnectionType.KeycloakSaml:
      providerName = 'Keycloak';
      icon = 'keycloak';
      break;
    case SsoConnectionType.LastPassSaml:
      providerName = 'LastPass';
      icon = 'lastpass';
      break;
    case SsoConnectionType.LoginGovOidc:
      providerName = 'Login.gov';
      icon = 'logingov';
      break;
    case SsoConnectionType.MicrosoftOAuth:
      providerName = 'Microsoft OAuth';
      icon = 'microsoft';
      break;
    case SsoConnectionType.MiniOrangeSaml:
      providerName = 'MiniOrange';
      icon = 'miniorange';
      break;
    case SsoConnectionType.NetIqSaml:
      providerName = 'NetIQ';
      icon = 'netiq';
      break;
    case SsoConnectionType.OracleSaml:
      providerName = 'Oracle';
      icon = 'oracle';
      break;
    case SsoConnectionType.PingOneSaml:
      providerName = 'PingOne';
      icon = 'pingidentity';
      break;
    case SsoConnectionType.SalesforceSaml:
      providerName = 'Salesforce';
      icon = 'salesforce';
      break;
    case SsoConnectionType.ShibbolethGenericSaml:
    case SsoConnectionType.ShibbolethSaml:
      providerName = 'Shibboleth';
      icon = 'shibboleth';
      break;
    case SsoConnectionType.SimpleSamlPhpSaml:
      providerName = 'SimpleSAMLphp';
      icon = 'simplesamlphp';
      break;
    case SsoConnectionType.VMwareSaml:
      providerName = 'VMWare';
      icon = 'vmware';
      break;
    default:
      return null;
  }
  return (
    <HStack align="center" spacing={space(6)} wrap="wrap">
      <Small>Connected via</Small>
      <HStack align="center" spacing={space(4)} wrap="no-wrap">
        {icon ? <ServiceIcon icon={icon} size={space(14)} /> : null}
        <Small weight="bold">{providerName}</Small>
      </HStack>
    </HStack>
  );
}

import { z } from 'zod';

import { graphql } from '../../../../../gql';
import { CopyIpSecTunnelFromConfig1ToConfig2InputSchema } from '../../../../../gql/zod-types';

export const copyIPSecTunnelFromConfig1Mutation = graphql(`
  mutation CopyIPSecTunnelFromConfig1ToConfig2(
    $networkUUID: UUID!
    $input: CopyIPSecTunnelFromConfig1ToConfig2Input!
  ) {
    copyIPSecTunnelFromConfig1ToConfig2(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const copyIPSecTunnelInputSchema = CopyIpSecTunnelFromConfig1ToConfig2InputSchema.extend({
  config1Name: z.string().nonempty({ message: 'Please select a config 1 IPSec tunnel.' }),
  presharedKey: z.string().nonempty({ message: 'Please provide a preshared key.' }),
});

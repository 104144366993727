import { Pane, PaneContent, PaneHeader, SearchInput } from '@meterup/atto';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useWirelessCrumbs } from '../../../utils/routing';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { useAccessPointsQuery } from '../utilities/query';
import SSIDsActions from './SSIDsActions';
import SSIDsList from './SSIDsList';
import { SSIDsQuery } from './SSIDsUtils';

export default function SSIDs() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const wirelessCrumb = useWirelessCrumbs();
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...wirelessCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.SSIDsPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'SSIDs',
            },
          },
        ]}
        icon="ssid"
        heading="SSIDs"
        count={
          useAccessPointsQuery({
            dataQuery: SSIDsQuery,
            dataPoint: 'ssidsForNetwork',
          }).length
        }
        actions={
          <>
            <IsPermitted permissions={PermissionType.PermSsidsWrite}>
              <SSIDsActions />
            </IsPermitted>
            <SearchInput
              placeholder="..."
              aria-label="Filter access points"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent gutter="bottom">
        <SSIDsList />
      </PaneContent>
    </Pane>
  );
}

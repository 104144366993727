import { PrimaryToggleField } from '@meterup/atto';
import { useMemo, useState } from 'react';

import type { AddEditFormProps } from './CRUDList';
import type { Schemas } from './useOnboardingDocument';
import { AddEditForm } from './CRUDList';
import {
  PrimaryNumericInput,
  PrimarySegmentToggle,
  PrimarySelect,
  PrimaryTextInput,
} from './FormFields';

type ISPDetails = Schemas['ISP_DETAILS'];
type ISPState = Omit<ISPDetails, 'handoff' | 'monthly_fee_cents'> & {
  handoff_type: 'ethernet' | 'fiber' | null;
  connector_type: 'SC' | 'LC' | null;
  mode: 'single_mode' | 'multi_mode' | null;
  monthly_fee_dollars: number;
};

// converts ISPDetails discriminated union to flat UI state
function configToState(config?: ISPDetails): ISPState {
  const state: ISPState = {
    provider: config?.provider ?? '',
    account_number: config?.account_number ?? '',
    account_holder_name: config?.account_holder_name ?? '',
    static_ip_info: config?.static_ip_info ?? '',
    bandwidth_mb_ps: config?.bandwidth_mb_ps ?? 0,
    product_type: config?.product_type ?? '',
    handoff_type: null,
    connector_type: null,
    mode: null,
    monthly_fee_dollars: config?.monthly_fee_cents ? config.monthly_fee_cents / 100 : 0,
    is_primary: config?.is_primary ?? true,
    did_add_ops_user: config?.did_add_ops_user ?? false,
  };

  if (!config) {
    return state;
  }

  if (config.handoff.type === 'fiber') {
    state.handoff_type = config.handoff.type;
    state.connector_type = config.handoff.connector_type;
    state.mode = config.handoff.mode;

    return state;
  }

  state.handoff_type = 'ethernet';

  return state;
}

// converts flat UI state to ISPDetails discriminated union
function stateToConfig(state: ISPState): ISPDetails | null {
  const {
    handoff_type: handoffType,
    connector_type: connectorType,
    mode,
    monthly_fee_dollars: monthlyFeeDollars,
    ...rest
  } = state;

  if (!handoffType) return null;

  if (rest.provider.length === 0) return null;
  if (rest.account_number.length === 0) return null;
  if (rest.account_holder_name.length === 0) return null;
  if (rest.static_ip_info.length === 0) return null;
  if (rest.product_type.length === 0) return null;
  if (monthlyFeeDollars === 0) return null;

  const monthlyFeeCents = monthlyFeeDollars * 100;

  if (handoffType === 'ethernet') {
    return {
      ...rest,
      monthly_fee_cents: monthlyFeeCents,
      handoff: {
        type: 'ethernet',
      },
    };
  }

  if (!connectorType || !mode) return null;

  return {
    ...rest,
    monthly_fee_cents: monthlyFeeCents,
    handoff: {
      type: 'fiber',
      connector_type: connectorType,
      mode,
    },
  };
}

const connectionTypes = ['Coax', 'Shared fiber', 'Dedicated fiber', 'Other'];

export function AddEditISPForm({
  defaultValue,
  onSubmit,
  onCancel,
}: AddEditFormProps<Schemas['ISP_DETAILS']>) {
  const [state, setState] = useState<ISPState>(configToState(defaultValue));

  function patchState<Key extends keyof ISPState>(key: Key, value: ISPState[Key]) {
    setState((prev) => ({ ...prev, [key]: value }));
  }

  const outputConfig = useMemo(() => stateToConfig(state), [state]);

  const isEditing = !!defaultValue;

  return (
    <AddEditForm
      isEditing={isEditing}
      canSubmit={outputConfig !== null}
      entityName="ISP"
      onSubmit={() => {
        if (outputConfig) {
          onSubmit(outputConfig);
        }
      }}
      onCancel={onCancel}
    >
      <PrimaryTextInput
        label="Provider"
        placeholder='e.g. "AT&T" or "Verizon"'
        value={state.provider}
        onChange={(v) => patchState('provider', v)}
      />

      <PrimaryTextInput
        label="Account number"
        value={state.account_number}
        onChange={(v) => patchState('account_number', v)}
      />

      <PrimaryTextInput
        label="Account holder name"
        placeholder="Jane Doe"
        value={state.account_holder_name}
        onChange={(v) => patchState('account_holder_name', v)}
      />
      <PrimaryTextInput
        label="Static IP information"
        description="If you have a static IP, please provide details here."
        value={state.static_ip_info}
        onChange={(v) => patchState('static_ip_info', v)}
      />
      <PrimaryNumericInput
        label="Bandwidth (megabits/second)"
        value={state.bandwidth_mb_ps}
        onChange={(v) => patchState('bandwidth_mb_ps', v)}
      />
      <PrimarySelect
        label="Product type"
        placeholder="Select product type"
        options={connectionTypes}
        value={state.product_type}
        onChange={(v) => patchState('product_type', v)}
      />
      <PrimaryNumericInput
        label="Monthly fee ($)"
        value={state.monthly_fee_dollars}
        onChange={(v) => patchState('monthly_fee_dollars', v)}
      />
      <PrimarySegmentToggle
        label="Is this your primary or backup ISP?"
        value={state.is_primary ? 'Primary' : 'Backup'}
        options={['Primary', 'Backup'] as const}
        onChange={(v) => patchState('is_primary', v === 'Primary')}
      />
      <PrimaryToggleField
        label="Have you added Meter as an authorized user in your ISP portal?"
        description="Check this box once you have added isp-ops@meter.com as an authorized user on your ISP Portal."
        selected={state.did_add_ops_user}
        onChange={(v) => patchState('did_add_ops_user', v)}
      />
      <PrimarySelect
        label="Handoff type"
        placeholder="Select handoff type"
        unSlugLabels
        options={['fiber', 'ethernet'] as const}
        value={state.handoff_type}
        onChange={(v) => patchState('handoff_type', v)}
      />
      {state.handoff_type === 'fiber' && (
        <>
          <PrimarySegmentToggle
            label="Mode"
            value={state.mode}
            unSlugLabels
            options={['single_mode', 'multi_mode'] as const}
            onChange={(v) => patchState('mode', v)}
          />
          <PrimarySegmentToggle
            label="Connector type"
            value={state.connector_type}
            options={['SC', 'LC'] as const}
            onChange={(v) => patchState('connector_type', v)}
          />
        </>
      )}
    </AddEditForm>
  );
}

import {
  Button,
  createCustomFieldPropsProvider,
  FieldContainer,
  HStack,
  MinimalCheckboxField,
  PrimaryField,
  space,
  styled,
  Subheading,
  Textarea,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { z } from 'zod';

import { graphql } from '../../gql';
import { type NotificationPreferencesQueryQuery, NetworkEventType } from '../../gql/graphql';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { getFieldProps, getInputProps } from '../../utils/inputs';
import { logError } from '../../utils/logError';
import { screamingSnakeCaseToWords } from '../../utils/strings';
import { withZodSchema } from '../../utils/withZodSchema';
import { ListFieldProvider } from '../Form/FieldProvider';
import { DiscardChangesButton } from '../form_buttons';

const notificationPreferencesQuery = graphql(`
  query NotificationPreferencesQuery($companySlug: String!, $controllerName: String!) {
    notificationPreferences(input: { companySlug: $companySlug, controllerName: $controllerName }) {
      companyPreferences {
        companySID
        emailAddresses
        additionalSlackChannels
        additionalEventTypes
      }

      networkPreferences {
        networkUUID
        emailAddresses
        additionalSlackChannels
        additionalEventTypes
      }
    }
  }
`);

const upsertCompanyNotificationPreferenceMutation = graphql(`
  mutation UpsertCompanyNotificationPreferenceMutation(
    $companySlug: String!
    $input: NotificationPreferenceInput
  ) {
    upsertCompanyNotificationPreference(companySlug: $companySlug, input: $input) {
      uuid
    }
  }
`);

const upsertControllerNotificationPreferenceMutation = graphql(`
  mutation UpsertControllerNotificationPreferenceMutation(
    $controllerName: String!
    $input: NotificationPreferenceInput
  ) {
    upsertNetworkNotificationPreference(controllerName: $controllerName, input: $input) {
      uuid
    }
  }
`);

interface NotificationPreferenceProps {
  preference: NonNullable<
    | NotificationPreferencesQueryQuery['notificationPreferences']['companyPreferences']
    | NotificationPreferencesQueryQuery['notificationPreferences']['networkPreferences']
  >;
}

const inputSchema = z.object({
  additionalEventTypes: z.array(z.nativeEnum(NetworkEventType)).optional(),
  emailAddresses: z
    .array(
      z.string().email({
        message:
          'Please double check that each entry is a valid email address. Please remove any trailing commas.',
      }),
    )
    .optional(),
  additionalSlackChannels: z
    .array(z.string())
    .refine((channels) => channels.every((channel) => /#[^\s]+/g.test(channel)), {
      message:
        'Slack channels should start with a #, and not contain any spaces. Please remove any trailing commas.',
    })
    .optional(),
});

type NotificationPreferenceInput = z.input<typeof inputSchema>;

export const NotificationTypesFieldProvider = createCustomFieldPropsProvider(
  ({ name }: { name: string }) => {
    const form = useFormikContext<any>();
    return {
      inputProps: {
        ...getInputProps(form, name),
        value: form.values[name] ?? [],
        onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
          const values = Array.from(event.target.selectedOptions).map((o) => o.value);
          if (values.length === 0) {
            form.setFieldTouched(name, false);
            form.setFieldValue(name, undefined, true);

            return;
          }

          form.setFieldTouched(name, true);
          form.setFieldValue(
            name,
            values.map((val) => NetworkEventType[val as keyof typeof NetworkEventType]),
            true,
          );
        },
      },
      fieldProps: getFieldProps(form, name),
    };
  },
);

export const GridContainer = styled('div', {
  display: 'grid',
  gridColumnGap: '$20',
  gridTemplateColumns: '1fr 1fr',
  '@maxLg': {
    gridTemplateColumns: '1fr',
  },
});

const StyledForm = styled(Form, {
  display: 'contents',
});

function renderNetworkEventType(input: (typeof NetworkEventType)[keyof typeof NetworkEventType]) {
  return screamingSnakeCaseToWords(input)
    .replace(/wan/i, 'WAN')
    .replace(/vpn/i, 'VPN')
    .replace(/lte/i, 'LTE');
}

function NotificationTypesInput() {
  const form = useFormikContext<{ additionalEventTypes: NetworkEventType[] }>();

  const options = useMemo(() => Array.from(Object.values(NetworkEventType)), []);

  return (
    <PrimaryField
      label="Additional event types"
      element={
        <>
          {options.map((val) => (
            <MinimalCheckboxField
              label={renderNetworkEventType(val)}
              key={val}
              value={val}
              checked={form.values.additionalEventTypes.includes(val)}
              onChange={(isSelected) => {
                const selectedOptions = new Set(form.values.additionalEventTypes);
                if (isSelected) {
                  selectedOptions.add(val);
                } else {
                  selectedOptions.delete(val);
                }
                form.setFieldValue('additionalEventTypes', Array.from(selectedOptions.values()));
              }}
            />
          ))}
        </>
      }
    />
  );
}

export function NotificationPreference({ preference }: NotificationPreferenceProps) {
  const controllerName = useCurrentController();
  const companySlug = useCurrentCompany();
  const queryClient = useQueryClient();

  const upsertCompanyNotificationPreference = useGraphQLMutation(
    upsertCompanyNotificationPreferenceMutation,
  );
  const upsertControllerNotificationPreference = useGraphQLMutation(
    upsertControllerNotificationPreferenceMutation,
  );

  const initialValues = useMemo(
    () => ({
      additionalEventTypes: preference.additionalEventTypes ?? [],
      emailAddresses: preference.emailAddresses ?? [],
      additionalSlackChannels: preference.additionalSlackChannels ?? [],
    }),
    [preference],
  );

  return (
    <Formik<NotificationPreferenceInput>
      validate={withZodSchema(inputSchema)}
      initialValues={initialValues}
      onSubmit={(values) => {
        if ('networkUUID' in preference) {
          upsertControllerNotificationPreference.mutate(
            {
              controllerName,
              input: values,
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(
                  makeQueryKey(notificationPreferencesQuery, {
                    companySlug,
                    controllerName,
                  }),
                );
                notify('Successfully saved notification preferences', {
                  variant: 'positive',
                });
              },
              onError: (error) => {
                logError(error);
                notify(
                  `There was a problem saving notification preferences${getGraphQLErrorMessageOrEmpty(error)}`,
                  {
                    variant: 'negative',
                  },
                );
              },
            },
          );
        } else {
          upsertCompanyNotificationPreference.mutate(
            {
              companySlug,
              input: values,
            },
            {
              onSuccess: () => {
                const key = makeQueryKey(notificationPreferencesQuery, {
                  companySlug,
                  controllerName,
                });
                // Invalidate the current page's query, prevent reload flash
                queryClient.invalidateQueries(key);
                // Reset other controller queries, ensure refetch on navigate
                queryClient.resetQueries({
                  predicate: (query) =>
                    // same graphql request
                    query.queryKey[0] === key[0] &&
                    // request contains variables object
                    !!query.queryKey[1] &&
                    typeof query.queryKey[1] === 'object' &&
                    // same company
                    'companySlug' in query.queryKey[1] &&
                    query.queryKey[1].companySlug === companySlug &&
                    // only for different controllers, same controller was invalidated above
                    (!('controllerName' in query.queryKey[1]) ||
                      query.queryKey[1].controllerName !== controllerName),
                });
                notify('Successfully saved notification preferences', {
                  variant: 'positive',
                });
              },
              onError: (error) => {
                logError(error);
                notify(
                  `There was a problem saving notification preferences${getGraphQLErrorMessageOrEmpty(error)}`,
                  {
                    variant: 'negative',
                  },
                );
              },
            },
          );
        }
      }}
    >
      <StyledForm>
        <VStack spacing={space(12)} align="stretch">
          <FieldContainer>
            <NotificationTypesInput />
            <ListFieldProvider name="emailAddresses">
              <PrimaryField label="Email addresses" element={<Textarea name="emailAddresses" />} />
            </ListFieldProvider>
            <ListFieldProvider name="additionalSlackChannels">
              <PrimaryField
                label="Slack channels"
                element={<Textarea name="additionalSlackChannels" />}
              />
            </ListFieldProvider>
          </FieldContainer>
          <HStack spacing={space(8)} justify="end">
            <DiscardChangesButton />
            <Button type="submit">Save</Button>
          </HStack>
        </VStack>
      </StyledForm>
    </Formik>
  );
}

export default function NotificationPreferences() {
  const controllerName = useCurrentController();
  const companySlug = useCurrentCompany();

  const notificationPreferencesResult = useGraphQL(notificationPreferencesQuery, {
    companySlug,
    controllerName,
  });

  const notificationPreferences = notificationPreferencesResult.data?.notificationPreferences ?? {};

  return (
    <GridContainer>
      <VStack spacing={space(8)} align="stretch">
        <Subheading>Company preferences</Subheading>
        <NotificationPreference
          preference={
            notificationPreferences.companyPreferences ?? {
              companySID: '',
            }
          }
        />
      </VStack>
      <VStack spacing={space(8)}>
        <Subheading>Network preferences</Subheading>
        <NotificationPreference
          preference={
            notificationPreferences.networkPreferences ?? {
              networkUUID: '',
            }
          }
        />
      </VStack>
    </GridContainer>
  );
}

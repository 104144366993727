import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { useCallback } from 'react';

import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetworkForSettingsPath } from '../../../../../routes/pages/settings/network/NetworkUtils';
import DeleteDialog from '../../../../Dialogs/DeleteDialog';
import {
  deleteNotificationSettingMutation,
  useInvalidateNotificationSettings,
  useNotificationSetting,
} from '../utils';

export default function AlertActions({ uuid }: { uuid: string }) {
  const deleteDialog = useDialogState();
  const deleteReceiverMutation = useGraphQLMutation(deleteNotificationSettingMutation);
  const closeDrawer = useCloseDrawerCallback();
  const network = useNetworkForSettingsPath({
    path: paths.pages.AlertsPage,
  });
  const invalidateNotificationSettings = useInvalidateNotificationSettings({
    networkUUID: network.UUID,
  });

  const handleDeleteNotificationSetting = useCallback(
    () =>
      deleteReceiverMutation.mutate(
        { UUID: uuid },
        {
          onSuccess() {
            invalidateNotificationSettings();
            closeDrawer();
            notify('Successfully deleted alert', {
              variant: 'positive',
            });
          },
          onError() {
            notify('Failed to delete alert', {
              variant: 'negative',
            });
          },
          onSettled: closeDrawer,
        },
      ),
    [deleteReceiverMutation, uuid, closeDrawer, invalidateNotificationSettings],
  );

  const alert = useNotificationSetting({ networkUUID: network.UUID, uuid });

  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onSelect={deleteDialog.state.open} icon="trash-can">
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>

      <DeleteDialog
        state={deleteDialog.state}
        label={alert.notificationDefinition?.name}
        handleDelete={handleDeleteNotificationSetting}
      />
    </>
  );
}

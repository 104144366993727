import type { OverlayTriggerState } from '@meterup/atto';
import {
  backgrounds,
  darkThemeSelector,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  LoadingIcon,
  Small,
  space,
  styled,
  Tab,
  useDialogState,
} from '@meterup/atto';
import { checkDefinedOrThrow } from '@meterup/common';
import { GraphQLErrorBoundary, useGraphQL } from '@meterup/graphql';
import { Suspense } from 'react';

import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { DeleteNetworkDialog } from '../../../Settings/Network/DeleteNetworkDialog';
import { networkQuery } from '../../../Settings/Network/utils';
import { useWorkbenchSide } from '../utils/useWorkbenchSide';
import { WorkbenchPane, WorkbenchPaneContent, WorkbenchPaneHeader } from '../WorkbenchPane';
import NetworkDetails from './NetworkDetails';
import NetworkFirmware from './NetworkFirmware';
import NetworkInsights from './NetworkInsights';
import NetworkLDFlags from './NetworkLDFlags';

const NetworkNoOp = styled(Small, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  background: backgrounds.checkeredLight,
  borderRadius: '$10',
  fontWeight: '$bold',

  [darkThemeSelector]: {
    background: backgrounds.checkeredDark,
  },
});

function NetworkErrorFallback({ error }: { error: Error }) {
  return <NetworkNoOp>There was a problem loading network data: {error.message}</NetworkNoOp>;
}

export enum WorkbenchNetworkTabs {
  Details = 'details',
  Firmware = 'firmware',
  Insights = 'insights',
  LDFlags = 'flags',
}

function DeleteNetworkDialogContainer({ state }: { state: OverlayTriggerState }) {
  const network = useNetwork();
  const { network: queriedNetwork } = checkDefinedOrThrow(
    useGraphQL(networkQuery, { uuid: network.UUID }).data,
  );

  return <DeleteNetworkDialog network={queriedNetwork} state={state} />;
}

export default function Network() {
  const network = useNetwork();
  const workbenchSide = useWorkbenchSide();

  const { state: deleteNetworkDialogState } = useDialogState();

  const [tab, setTab] = useLocalStorage(
    'dashboard.workbench.network.tab',
    WorkbenchNetworkTabs.Insights,
  );

  return (
    <WorkbenchPane>
      <WorkbenchPaneHeader
        icon={workbenchSide === 'bottom' ? 'network' : undefined}
        heading={workbenchSide === 'bottom' ? 'Network' : undefined}
        tabs={
          <>
            <Tab
              size="x-small"
              icon="reporting"
              label="Insights"
              onClick={() => setTab(WorkbenchNetworkTabs.Insights)}
              selected={tab === WorkbenchNetworkTabs.Insights}
            />
            <Tab
              size="x-small"
              icon="information"
              label="Details"
              onClick={() => setTab(WorkbenchNetworkTabs.Details)}
              selected={tab === WorkbenchNetworkTabs.Details}
            />
            <Tab
              size="x-small"
              icon="microchip"
              label="Firmware"
              onClick={() => setTab(WorkbenchNetworkTabs.Firmware)}
              selected={tab === WorkbenchNetworkTabs.Firmware}
            />
            <Tab
              size="x-small"
              icon="flag"
              label="Feature flags"
              onClick={() => setTab(WorkbenchNetworkTabs.LDFlags)}
              selected={tab === WorkbenchNetworkTabs.LDFlags}
            />
          </>
        }
        contentActions={
          <DropdownMenu>
            <DropdownMenuButton
              arrangement="hidden-label"
              icon="overflow-horizontal"
              size="x-small"
            >
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover>
              <DropdownMenuGroup>
                <DropdownMenuItem icon="trash-can" onSelect={deleteNetworkDialogState.open}>
                  Delete network
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuPopover>
          </DropdownMenu>
        }
      />
      <Suspense
        fallback={
          <NetworkNoOp>
            <LoadingIcon size={space(20)} />
          </NetworkNoOp>
        }
      >
        <GraphQLErrorBoundary fallback={NetworkErrorFallback} resetKey={network?.UUID}>
          <Suspense
            fallback={
              <WorkbenchPaneContent>
                <NetworkNoOp>
                  <LoadingIcon size={space(20)} />
                </NetworkNoOp>
              </WorkbenchPaneContent>
            }
          >
            {network && (
              <>
                {tab === WorkbenchNetworkTabs.Insights && <NetworkInsights />}
                {tab === WorkbenchNetworkTabs.Details && <NetworkDetails />}
                {tab === WorkbenchNetworkTabs.Firmware && <NetworkFirmware />}
                {tab === WorkbenchNetworkTabs.LDFlags && <NetworkLDFlags />}
              </>
            )}
            <DeleteNetworkDialogContainer state={deleteNetworkDialogState} />
          </Suspense>
        </GraphQLErrorBoundary>
      </Suspense>
    </WorkbenchPane>
  );
}

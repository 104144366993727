import {
  Alert,
  Body,
  Button,
  CompositeField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  PrimaryField,
  SecondaryField,
  SecondaryFieldComposite,
  space,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';

import type { UpdateEncryption8021XInput } from '../../../gql/graphql';
import type { ValidUpdateRadiusProfileParams } from '../../Wireless/SSIDs/SSIDsUtils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider, NumberFieldProvider } from '../../Form/FieldProvider';
import {
  DeleteRadiusProfileMutation,
  RadiusProfilesQuery,
  SSIDsQuery,
  UpdateRadiusProfileInputSchema,
  UpdateRadiusProfileMutation,
} from '../../Wireless/SSIDs/SSIDsUtils';

export default function RADIUSProfileEditDrawer({ uuid }: { uuid: string }) {
  const closeDrawer = useCloseDrawerCallback();
  const network = useNetwork();
  const { state } = useDialogState();
  const [showAuthPassword, setShowAuthPassword] = useState(false);
  const [showAcctPassword, setShowAcctPassword] = useState(false);

  const radiusProfiles = useGraphQL(RadiusProfilesQuery, { networkUUID: network.UUID }).data
    ?.encryption8021XsForNetwork;
  expectDefinedOrThrow(radiusProfiles, new ResourceNotFoundError('Unable to load RADIUS profiles'));

  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork ?? [];
  const profileInUse =
    ssids.findIndex(
      (s) => s.primaryEncryption8021X?.UUID === uuid || s.secondaryEncryption8021X?.UUID === uuid,
    ) !== -1;

  const profile = radiusProfiles.find((r) => r.UUID === uuid);
  expectDefinedOrThrow(profile, new ResourceNotFoundError('Unable to load RADIUS profile'));

  const [errorText, setErrorText] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const updateRadiusProfileMutation = useGraphQLMutation(UpdateRadiusProfileMutation);
  const deleteRadiusProfileMutation = useGraphQLMutation(DeleteRadiusProfileMutation);

  const handleDeleteRadiusProfile = useCallback(
    () =>
      deleteRadiusProfileMutation.mutate(
        { uuid: profile.UUID },
        {
          onSuccess() {
            queryClient.invalidateQueries(
              makeQueryKey(RadiusProfilesQuery, { networkUUID: network.UUID }),
            );
            closeDrawer();
            notify('RADIUS profile deleted successfully', { variant: 'positive' });
          },
          onError(err) {
            const gqlErr = getGraphQLError(err);
            notify(
              `There was an error deleting the RADIUS profile: ${gqlErr?.message ?? 'Unknown error'}`,
              { variant: 'negative' },
            );
          },
          onSettled: closeDrawer,
        },
      ),
    [deleteRadiusProfileMutation, profile.UUID, queryClient, closeDrawer, network.UUID],
  );

  return (
    <Drawer>
      <Formik<ValidUpdateRadiusProfileParams>
        initialValues={{
          label: profile.label,
          authServerIPAddress: profile.authServerIPAddress,
          authServerPort: profile.authServerPort,
          authServerSecret: profile.authServerSecret,
          accountingServerIPAddress: profile.accountingServerIPAddress,
          accountingServerPort: profile.accountingServerPort,
          accountingServerSecret: profile.accountingServerSecret,
        }}
        validate={withZodSchema(UpdateRadiusProfileInputSchema)}
        onSubmit={(values) => {
          setErrorText(null);

          const input: UpdateEncryption8021XInput = values;

          if (input.accountingServerIPAddress === '') {
            input.accountingServerIPAddress = null;
            input.accountingServerPort = null;
            input.accountingServerSecret = null;
          }

          updateRadiusProfileMutation.mutate(
            { uuid: profile.UUID, input },
            {
              onError: (error) => {
                const gqlError = getGraphQLError(error);
                setErrorText(gqlError?.message ?? 'Unknown error');
              },
              onSuccess: () => {
                queryClient.invalidateQueries(
                  makeQueryKey(RadiusProfilesQuery, { networkUUID: network.UUID }),
                );
                closeDrawer();
                notify(`Successfully updated RADIUS profile "${values.label}".`, {
                  variant: 'positive',
                });
              },
            },
          );
        }}
      >
        <Form>
          <DrawerHeader
            icon="radius"
            heading="Edit RADIUS profile"
            onClose={closeDrawer}
            actions={
              !profileInUse && (
                <>
                  <DropdownMenu>
                    <DropdownMenuButton
                      variant="secondary"
                      icon="overflow-horizontal"
                      arrangement="hidden-label"
                    >
                      Actions
                    </DropdownMenuButton>

                    <DropdownMenuPopover align="end">
                      <DropdownMenuItem onSelect={state.open} icon="trash-can">
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuPopover>
                  </DropdownMenu>
                  <Dialog state={state}>
                    <DialogHeader icon="radius" heading="Delete RADIUS profile" />
                    <DialogContent gutter="all">
                      <Body>
                        Are you sure you want to delete <strong>{profile.label}</strong>?
                      </Body>
                    </DialogContent>
                    <DialogFooter
                      actions={
                        <>
                          <Button onClick={handleDeleteRadiusProfile} variant="destructive">
                            Delete RADIUS profile
                          </Button>
                          <Button variant="secondary" onClick={state.close}>
                            Cancel
                          </Button>
                        </>
                      }
                    />
                  </Dialog>
                </>
              )
            }
          />
          <DrawerContent>
            <VStack spacing={space(16)}>
              <FieldProvider name="label">
                <PrimaryField element={<TextInput />} label="Label" />
              </FieldProvider>

              <FieldContainer>
                <PrimaryField element={null} label="Authentication server" />
                <FieldProvider name="authServerIPAddress">
                  <SecondaryField element={<TextInput />} label="IP address" />
                </FieldProvider>

                <NumberFieldProvider name="authServerPort" defaultValue={null}>
                  <SecondaryField element={<TextInput type="number" />} label="Port" />
                </NumberFieldProvider>

                <SecondaryFieldComposite
                  label="Secret"
                  fields={
                    <>
                      <FieldProvider name="authServerSecret">
                        <CompositeField
                          element={<TextInput type={showAuthPassword ? 'text' : 'password'} />}
                          label="Secret"
                        />
                      </FieldProvider>
                      <Button
                        variant="secondary"
                        arrangement="hidden-label"
                        icon={showAuthPassword ? 'eye-closed' : 'eye-open'}
                        size="small"
                        onClick={() => setShowAuthPassword(!showAuthPassword)}
                      >
                        Show password
                      </Button>
                    </>
                  }
                />
              </FieldContainer>

              <FieldContainer>
                <PrimaryField element={null} label="Accounting server" />
                <FieldProvider name="accountingServerIPAddress">
                  <SecondaryField element={<TextInput />} label="IP address" />
                </FieldProvider>

                <NumberFieldProvider name="accountingServerPort" defaultValue={null}>
                  <SecondaryField element={<TextInput type="number" />} label="Port" />
                </NumberFieldProvider>

                <SecondaryFieldComposite
                  label="Secret"
                  fields={
                    <>
                      <FieldProvider name="accountingServerSecret">
                        <CompositeField
                          element={<TextInput type={showAcctPassword ? 'text' : 'password'} />}
                          label="Secret"
                        />
                      </FieldProvider>
                      <Button
                        variant="secondary"
                        arrangement="hidden-label"
                        icon={showAcctPassword ? 'eye-closed' : 'eye-open'}
                        size="small"
                        onClick={() => setShowAcctPassword(!showAcctPassword)}
                      >
                        Show password
                      </Button>
                    </>
                  }
                />
              </FieldContainer>

              {errorText && (
                <Alert
                  heading="Error updating RADIUS profile"
                  copy={errorText}
                  variant="negative"
                />
              )}
            </VStack>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={updateRadiusProfileMutation.isLoading}
                  loading={updateRadiusProfileMutation.isLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

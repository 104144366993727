import { useMemo } from 'react';

import { HasRole } from '../core/typesHelpers';
import { RoleName } from '../gql/graphql';
import { useAuthorization } from '../providers/AuthorizationProvider';
import { useIsCompanyAdmin } from './useIsCompanyAdmin';
import { useIsTrueOperator } from './useIsTrueOperator';

type UseIsCompanyNetworkAdminProps = {
  companySlug: string;
  networkUUID: string;
};

export function useIsCompanyNetworkAdmin({
  companySlug,
  networkUUID,
}: UseIsCompanyNetworkAdminProps) {
  const authz = useAuthorization();
  const hasRole = useMemo(
    () =>
      authz.check(
        HasRole({
          roleName: RoleName.CompanyNetworkAdmin,
          companySlug,
          networkUUID,
        }),
      ),
    [authz, companySlug, networkUUID],
  );
  const isOperator = useIsTrueOperator();
  const isCompanyAdmin = useIsCompanyAdmin({ companySlug });
  return useMemo(
    () => hasRole || isOperator || isCompanyAdmin,
    [hasRole, isOperator, isCompanyAdmin],
  );
}

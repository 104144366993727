import {
  Button,
  ComboBox,
  ComboBoxItem,
  ComboBoxSection,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  Link,
  PrimaryField,
  Textarea,
} from '@meterup/atto';
import { isPrivateAddress, isValidCIDRString, notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';

import { MAX_PORT_NUMBER } from '../../constants';
import { type CreateClientVpnServerInput, PermissionType } from '../../gql/graphql';
import { CreateClientVpnServerInputSchema } from '../../gql/zod-types';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { usePermissions } from '../../providers/PermissionsProvider';
import { withZodSchema } from '../../utils/withZodSchema';
import { getPhyInterfaceLabel, uplinkPhyInterfacesQuery } from '../Firewall/utils';
import { FieldProvider, ListFieldProvider } from '../Form/FieldProvider';
import { NumberField, TextField, ToggleField } from '../Form/Fields';
import {
  type ClientVPNServer,
  clientVPNServersQuery,
  createClientVPNServerMutation,
  DEFAULT_DEFAULT_CLIENT_ALLOWED_IPS,
  DEFAULT_LISTEN_PORT,
  updateClientVPNServerMutation,
} from './utils';

const ADDRESS_MASK_MESSAGE = 'Please provide an address mask between 0 and 32.';
const LISTEN_PORT_MESSAGE = `Please provide a listen port between 0 and ${MAX_PORT_NUMBER}.`;
const DNS_LISTEN_ADDRESS_MESSAGE = 'Please provide a valid private IP address.';
const DEFAULT_CLIENT_ALLOWED_IPS_MESSAGE =
  'Please provide a newline-separated list of valid IP prefixes.';

const createServerInputSchema = CreateClientVpnServerInputSchema.extend({
  address: z.string().ip({ version: 'v4', message: 'Please provide a valid IP address.' }),
  addressMask: z
    .number()
    .int({ message: ADDRESS_MASK_MESSAGE })
    .min(0, { message: ADDRESS_MASK_MESSAGE })
    .max(32, { message: ADDRESS_MASK_MESSAGE }),
  listenPort: z
    .number()
    .int({ message: LISTEN_PORT_MESSAGE })
    .min(0, { message: LISTEN_PORT_MESSAGE })
    .max(MAX_PORT_NUMBER, { message: LISTEN_PORT_MESSAGE }),
  phyInterfaceUUID: z.string().nonempty({ message: 'Please select a WAN port.' }),
  dnsListenAddress: z
    .string()
    .ip({ version: 'v4', message: DNS_LISTEN_ADDRESS_MESSAGE })
    .refine((addrString) => !addrString || isPrivateAddress(addrString), {
      message: DNS_LISTEN_ADDRESS_MESSAGE,
    })
    .or(z.literal(''))
    .nullish(),
  defaultClientAllowedIPs: z
    .array(
      z.string().refine((entry) => isValidCIDRString(entry), {
        message: DEFAULT_CLIENT_ALLOWED_IPS_MESSAGE,
      }),
    )
    .nonempty(),
});

type CreateServerInputValues = z.input<typeof createServerInputSchema>;

export default function CreateClientVPNServerDrawer({ server }: { server?: ClientVPNServer }) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const createServer = useGraphQLMutation(createClientVPNServerMutation);
  const updateServer = useGraphQLMutation(updateClientVPNServerMutation);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    ({ phyInterfaceUUID, dnsListenAddress, ...values }: CreateServerInputValues) => {
      const input: CreateClientVpnServerInput = {
        ...values,
        // using || to cast empty string to null
        phyInterfaceUUID: phyInterfaceUUID || null,
        dnsListenAddress: dnsListenAddress || null,
      };

      if (server?.UUID) {
        updateServer.mutate(
          { uuid: server.UUID, input },
          {
            onSuccess() {
              closeDrawer();
              queryClient.invalidateQueries(
                makeQueryKey(clientVPNServersQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully updated VPN server.', {
                variant: 'positive',
              });
            },
            onError(err) {
              notify(
                `There was a problem updating the VPN server${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        createServer.mutate(
          { networkUUID: network.UUID, input },
          {
            onSuccess() {
              closeDrawer();
              queryClient.invalidateQueries(
                makeQueryKey(clientVPNServersQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully created VPN server.', {
                variant: 'positive',
              });
            },
            onError(err) {
              notify(
                `There was a problem creating the VPN server${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [server?.UUID, network.UUID, createServer, updateServer, queryClient, closeDrawer],
  );

  const uplinkPhyInterfaces = useGraphQL(uplinkPhyInterfacesQuery, { networkUUID: network.UUID })
    .data?.uplinkPhyInterfacesForNetwork;

  const sortedUplinkGroups = useMemo(() => {
    if (!uplinkPhyInterfaces) return [];

    const map = new Map<string, { key: string; label: string }[]>();

    for (const uplink of uplinkPhyInterfaces) {
      let arr = map.get(uplink.virtualDevice.label);
      if (!arr) {
        arr = [];
        map.set(uplink.virtualDevice.label, arr);
      }
      arr.push({
        key: uplink.UUID,
        label: getPhyInterfaceLabel(uplink, false),
      });
    }

    return Array.from(map.entries()).sort(([a], [b]) => a.localeCompare(b));
  }, [uplinkPhyInterfaces]);

  const { hasPermission } = usePermissions();
  const canWriteVPNServer = hasPermission(PermissionType.PermClientVpnWrite);

  return (
    <Drawer>
      <Formik<CreateServerInputValues>
        initialValues={{
          address: server?.address ?? '',
          addressMask: server?.addressMask ?? 24,
          listenPort: server?.port ?? DEFAULT_LISTEN_PORT,
          phyInterfaceUUID: server?.phyInterface?.UUID ?? '',
          dnsListenAddress: server?.dnsListenAddress ?? '',
          defaultClientAllowedIPs: (server?.defaultClientAllowedIPs ??
            DEFAULT_DEFAULT_CLIENT_ALLOWED_IPS) as [string, ...string[]],
          isFailoverEnabled: server?.isFailoverEnabled ?? true,
        }}
        onSubmit={handleSubmit}
        validate={withZodSchema(createServerInputSchema)}
      >
        <Form>
          <DrawerHeader
            icon="vpn"
            heading={`${server ? 'Edit' : 'Add'} VPN server`}
            onClose={closeDrawer}
          />
          <DrawerContent gutter="all">
            <TextField name="address" label="Address" disabled={!canWriteVPNServer} />
            <NumberField name="addressMask" label="Address mask" disabled={!canWriteVPNServer} />
            <NumberField name="listenPort" label="Listen port" disabled={!canWriteVPNServer} />
            <TextField
              name="dnsListenAddress"
              label="DNS listen address"
              description={
                <>
                  Must be a{' '}
                  <Link
                    href="https://en.wikipedia.org/wiki/Private_network#Private_IPv4_addresses"
                    target="_blank"
                  >
                    private IPv4 address
                  </Link>
                  .
                </>
              }
              disabled={!canWriteVPNServer}
              optional
            />
            <ListFieldProvider name="defaultClientAllowedIPs" defaultValue={[]}>
              <PrimaryField
                label="Default client allowed IPs"
                description={
                  <>
                    One IP prefix per line (
                    <Link
                      href="https://en.wikipedia.org/wiki/Classless_Inter-Domain_Routing#CIDR_notation"
                      target="_blank"
                    >
                      CIDRs
                    </Link>
                    ).
                  </>
                }
                element={<Textarea disabled={!canWriteVPNServer} />}
              />
            </ListFieldProvider>
            <FieldContainer>
              <FieldProvider name="phyInterfaceUUID">
                <PrimaryField
                  label="WAN"
                  element={
                    <ComboBox
                      placeholder="Select a WAN"
                      renderSelected={(option) =>
                        `${option.props['data-device']}: ${option.textValue}`
                      }
                      disabled={!canWriteVPNServer}
                    >
                      {sortedUplinkGroups.map(([deviceLabel, phyInterfaces]) => (
                        <ComboBoxSection title={deviceLabel}>
                          {phyInterfaces.map(({ key, label }) => (
                            <ComboBoxItem key={key} textValue={label} data-device={deviceLabel}>
                              {label}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxSection>
                      ))}
                    </ComboBox>
                  }
                />
              </FieldProvider>
            </FieldContainer>
            <ToggleField
              name="isFailoverEnabled"
              label="WAN failover"
              description="If enabled, the VPN will fail over alongside active WAN. If disabled, VPN will remain pinned to the selected WAN and will not fail over."
              disabled={!canWriteVPNServer}
            />
          </DrawerContent>
          {canWriteVPNServer && (
            <DrawerFooter
              actions={
                <>
                  <Button type="button" onClick={closeDrawer} variant="secondary">
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary">
                    Add
                  </Button>
                </>
              }
            />
          )}
        </Form>
      </Formik>
    </Drawer>
  );
}

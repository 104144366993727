import type { OverlayTriggerState } from '@meterup/atto';
import { Dialog, DialogContent, DialogHeader } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { uplinkPhyInterfacesQuery } from '../../Firewall/utils';
import SpeedTests from '../../SpeedTests/SpeedTests';
import { type Uplink } from './utils';

export default function SpeedTestDialog({ state }: { state: OverlayTriggerState }) {
  const network = useNetwork();

  const allUplinks = useGraphQL(uplinkPhyInterfacesQuery, {
    networkUUID: network.UUID,
  })?.data?.uplinkPhyInterfacesForNetwork;

  const uplinks = useMemo(
    () =>
      allUplinks?.filter(
        (phyInterface) =>
          phyInterface.isEnabled && phyInterface.virtualDevice.hardwareDevice?.isActive,
      ),
    [allUplinks],
  );

  function getUplinkDevices(uplinksList: Uplink[]) {
    const map = new Map<string, [Uplink, ...Uplink[]]>();
    if (!uplinksList) return [];

    for (const uplink of uplinksList) {
      const arr = map.get(uplink.virtualDevice.UUID);
      if (arr) {
        arr.push(uplink);
      } else {
        map.set(uplink.virtualDevice.UUID, [uplink]);
      }
    }

    for (const arr of map.values()) {
      arr.sort((a, b) => a.portNumber - b.portNumber);
    }

    return Array.from(map.entries()).sort(([, [a]], [, [b]]) =>
      a.virtualDevice.UUID.localeCompare(b.virtualDevice.label),
    );
  }

  const uplinkDevices = useMemo(() => getUplinkDevices(uplinks ?? []), [uplinks]);

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="speed-medium" heading="Speed test" />
      <DialogContent gutter="all">
        <SpeedTests uplinkDevices={uplinkDevices} />
      </DialogContent>
    </Dialog>
  );
}

import type { IconName } from '@meterup/atto';

import type { NetworkClient } from '../../../../../hooks/networkClients/useNetworkClients';
import type { TimePeriod } from '.';
import { createFilterEnumeration } from '../../../../../components/Table/createFilterEnumeration';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import {
  isAirplay,
  isPrinter,
  isSpeaker,
  isWired,
  isWireless,
} from '../../../../../utils/clientLists';

export const timePeriodLabel = (period: TimePeriod) => {
  switch (period) {
    case '5':
      return 'Last 5 minutes';
    case '10':
      return 'Last 10 minutes';
    case '30':
      return 'Last 30 minutes';
    case '60':
      return 'Last 1 hour';
    case '120':
      return 'Last 2 hours';
    case '43200':
      return 'Last 30 days';
    default:
      return '';
  }
};

interface FilterOption {
  key: string;
  icon: IconName;
  label: string;
  predicate: (c: NetworkClient) => boolean;
  requirements?: boolean[];
}

export const Filters = () => {
  const mdnsFiltersEnabled = useNosFeatureEnabled(NosFeature.MDNS_FILTERS);

  const tabs: FilterOption[] = [
    {
      key: 'wireless',
      icon: 'wifi',
      label: 'Wireless',
      predicate: isWireless,
    },
    {
      key: 'wired',
      icon: 'wired',
      label: 'Wired',
      predicate: isWired,
    },
    {
      key: 'printer',
      icon: 'printer',
      label: 'Printers',
      predicate: isPrinter,
      requirements: [mdnsFiltersEnabled],
    },
    {
      key: 'airplay',
      icon: 'airplay',
      label: 'AirPlay',
      predicate: isAirplay,
      requirements: [mdnsFiltersEnabled],
    },
    {
      key: 'speaker',
      icon: 'speaker',
      label: 'Speakers',
      predicate: isSpeaker,
      requirements: [mdnsFiltersEnabled],
    },
  ];

  const filteredTabs = tabs.filter(
    (tab: FilterOption) => !tab.requirements || tab.requirements.every(Boolean),
  );

  return createFilterEnumeration<NetworkClient>(filteredTabs, {
    urlKey: 'tab',
  });
};

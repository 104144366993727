import { type ButtonProps, Button, DrawerFooter } from '@meterup/atto';
import { useFormikContext } from 'formik';

export function VLANDetailsDrawerFooter() {
  const { resetForm, dirty } = useFormikContext();

  return (
    <DrawerFooter
      actions={
        <>
          <Button
            type="button"
            onClick={() => {
              resetForm();
            }}
            variant="secondary"
            disabled={!dirty}
          >
            Reset
          </Button>
          <Button type="submit">Save</Button>
        </>
      }
    />
  );
}

interface FormlessButtonProps extends ButtonProps {
  disabledIfClean?: boolean;
}

export function FormlessSubmitButton({ disabledIfClean = false, ...props }: FormlessButtonProps) {
  const { submitForm, dirty } = useFormikContext();

  return <Button onClick={submitForm} disabled={disabledIfClean ? !dirty : undefined} {...props} />;
}

export function FormlessResetButton({ disabledIfClean, ...props }: FormlessButtonProps) {
  const { resetForm, dirty } = useFormikContext();

  const handleReset = () => {
    resetForm();
  };

  return (
    <Button onClick={handleReset} disabled={disabledIfClean ? !dirty : undefined} {...props} />
  );
}

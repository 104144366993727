import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import EditFirewallRuleDrawer from '../../../../../components/Firewall/EditFirewallRuleDrawer';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall-rules/create',
  title: 'Add rule - Rules - Firewall',
});

export default function CreateFirewallRulePage() {
  const featureFlags = useFeatureFlags();
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator && !featureFlags['firewall-rules'])
    throw new ResourceNotFoundError('Page not found');

  return <EditFirewallRuleDrawer />;
}

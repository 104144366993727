import { Alert, Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteRuleForCompany, getRuleForCompany } from '../../../../../api/api';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { formatRuleContentName } from '../../../../../utils/content_filters';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/dns-security/rules/:id/remove',
});

export default function LegacyDNSSecurityCategoryRuleRemovePage() {
  const companyName = useCurrentCompany();
  const { id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacyDNSSecurityCategoryRuleRemovePage),
  );

  const rule =
    useQuery(['dns_security', companyName, id], () => getRuleForCompany(companyName, id), {
      suspense: true,
    }).data ?? null;

  expectDefinedOrThrow(rule, new ResourceNotFoundError('Rule not found'));

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const closeDrawer = useCloseDrawerCallback();

  const removeRuleMutation = useMutation(() => deleteRuleForCompany(companyName, id), {
    onSuccess: async () => {
      notify('Deleted rule', { variant: 'positive' });
      closeDrawer();
      await queryClient.invalidateQueries(['dns_security', companyName]);
    },
    onError: () => {
      notify('Failed to remove rule', { variant: 'negative' });
    },
  });

  return (
    <Drawer>
      <DrawerHeader icon="dns-security" heading="Delete rule" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <Alert
          icon="attention"
          heading="Confirmation required"
          copy={`Are you sure you wish to remove the filter rule for "${formatRuleContentName(
            rule,
          )}"?`}
          variant="negative"
        />
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button onClick={() => navigate(-1)} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => removeRuleMutation.mutate()} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Drawer>
  );
}

import { Drawer, DrawerHeader } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';

import { vlanQuery } from '../../../../../components/NetworkWide/VLANs/utils';
import VLANActions from '../../../../../components/NetworkWide/VLANs/VLANActions';
import VLANForm from '../../../../../components/NetworkWide/VLANs/VLANForm';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid/edit',
  title: 'Edit VLAN - VLANs - Network-wide',
});

export default function VLANEditPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.VLANEditPage);

  const vlan = useGraphQL(
    vlanQuery,
    {
      uuid: drawerParams?.uuid!,
    },
    {
      enabled: !!drawerParams?.uuid,
    },
  ).data?.vlan;

  const closeDrawer = useCloseDrawerCallback();

  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermVlanWrite) &&
        (isOperator || !!ldFlags['edit-vlan']) &&
        !!vlan &&
        !!drawerParams?.uuid &&
        (isOperator || !vlan?.isInternal)
      }
      path={Meta().path}
    >
      <Drawer>
        <DrawerHeader
          icon="vlan"
          heading="Edit VLAN"
          onClose={closeDrawer}
          actions={<VLANActions vlan={vlan!} view="edit" />}
        />
        <VLANForm vlan={vlan!} />
      </Drawer>
    </IsPermitted>
  );
}

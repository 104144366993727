import type { ReactNode } from 'react';
import {
  Alert,
  Body,
  Button,
  DrawerContent,
  DrawerFooter,
  HStack,
  Link,
  PrimaryField,
  Shortcut,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import {
  colors,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
  styled,
} from '@meterup/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { FixMeLater } from '../../../../../../../types/fixMeLater';
import type { ValidVPNOwnerData, VPNFormData } from './form_data';
import { createVPNClient, fetchVPNClient } from '../../../../../../../api/vpn';
import { FieldProvider } from '../../../../../../../components/Form/FieldProvider';
import { paths } from '../../../../../../../constants';
import { useControllerConfig } from '../../../../../../../hooks/useControllerConfig';
import { Nav } from '../../../../../../../nav';
import { useCurrentCompany } from '../../../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../../../providers/CurrentControllerProvider';
import { fontWeights } from '../../../../../../../stitches';
import { makeLink } from '../../../../../../../utils/main_and_drawer_navigation';
import { createWGConf, generateKeyPair } from '../../../../../../../utils/vpn';
import { toVPNClientCreateRequest, validVPNOwnerData } from './form_data';

const StyledForm = styled(Form, {
  display: 'contents',
});

const StepTitle = styled(Body, {
  fontWeight: fontWeights.bold,
  color: colors.gray700,
});

const Paragraph = styled('p', Body, {
  color: colors.gray700,
});

const FormStepContent = styled(DrawerContent, {});

function updateOwner(
  prevForm: VPNFormData,
  ownerData: ValidVPNOwnerData,
  onChange: (values: VPNFormData) => void,
) {
  const updatedForm = prevForm;
  updatedForm.owner = ownerData.email;
  updatedForm.name = ownerData.name;
  onChange(updatedForm);
}

function StepHeader({ stepNumber, title }: { stepNumber: ReactNode; title: ReactNode }) {
  return (
    <HStack align="center" spacing={space(6)}>
      <Shortcut keys={[stepNumber]} />
      <StepTitle>{title}</StepTitle>
    </HStack>
  );
}

export function OwnerStepForm({
  formValues,
  defaultEmail,
  defaultName,
  onChange,
  goToNextStep,
}: {
  formValues: VPNFormData;
  defaultEmail: string;
  defaultName: string;
  onChange: (values: VPNFormData) => void;
  goToNextStep: () => void;
}) {
  const controllerName = useCurrentController();
  const [keypair] = useState(generateKeyPair());

  const createClientMutation = useMutation(
    ['controllers', controllerName, 'vpn-clients'],
    () => {
      const req = toVPNClientCreateRequest(
        formValues.name ?? '',
        formValues.owner ?? '',
        keypair.publicKey,
      );
      return createVPNClient(controllerName, req.vpnClientData);
    },
    {
      onSuccess: (result) => {
        const updatedForm = formValues;
        updatedForm.private_key = keypair.privateKey;
        updatedForm.public_key = keypair.publicKey;
        updatedForm.sid = result.client!.sid;
        onChange(updatedForm);
        goToNextStep();
      },
    },
  );
  return (
    <Formik<ValidVPNOwnerData>
      initialValues={{
        email: defaultEmail,
        name: defaultName,
      }}
      validationSchema={toFormikValidationSchema(validVPNOwnerData)}
      onSubmit={(values) => {
        updateOwner(formValues, values, onChange);
        createClientMutation.mutate();
      }}
    >
      <StyledForm>
        <FormStepContent>
          <VStack spacing={space(4)}>
            <StepHeader stepNumber="1" title="VPN profile" />
          </VStack>

          <FieldProvider name="email">
            <PrimaryField
              label="Email address"
              description="Who will be using this profile?"
              element={<TextInput />}
            />
          </FieldProvider>

          <FieldProvider name="name">
            <PrimaryField
              label="Name"
              description="A distinct name used to identify this VPN profile in the dashboard."
              element={<TextInput />}
            />
          </FieldProvider>
        </FormStepContent>
        <DrawerFooter
          actions={
            <Button type="submit" variant="primary">
              Continue
            </Button>
          }
        />
      </StyledForm>
    </Formik>
  );
}

export function SetupStep({ formValues }: { formValues: VPNFormData }) {
  const [fileDownloaded, setFileDownloaded] = useState(false);
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const controllerConfig = useControllerConfig(controllerName);
  const vpnClientResponse = useQuery(
    ['controller', controllerName, 'vpn-clients', formValues.sid],
    async () => fetchVPNClient(controllerName, formValues.sid ?? ''),
    {
      suspense: true,
      refetchInterval: 1000,
    },
  ).data;

  const dnsServers = controllerConfig.vlans.find((vlan) => vlan.name === 'private')?.json.dhcp?.[
    'dns-servers'
  ];

  expectDefinedOrThrow(vpnClientResponse, new ResourceNotFoundError('VPN client response missing'));

  const vpnClient = vpnClientResponse.client;
  const vpnServer = vpnClientResponse.server;

  expectDefinedOrThrow(vpnClient, new ResourceNotFoundError('VPN client response missing'));
  expectDefinedOrThrow(vpnServer, new ResourceNotFoundError('VPN client response missing'));

  const wgCfg = createWGConf(
    vpnClient!.ip_address,
    vpnServer!.endpoint,
    vpnServer!.port as FixMeLater,
    vpnServer!.public_key as FixMeLater,
    formValues.private_key ?? '',
    vpnServer.default_client_allowed_ips,
    dnsServers,
  );

  const downloadFile = () => {
    const blob = new Blob([wgCfg], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    const filename = `meter-${vpnClient.name}`
      .replace(/([^a-z0-9()_-]+)/gi, '')
      .replace(' ', '-')
      .substring(0, 100);

    link.download = `${filename}.conf`;
    link.href = url;
    link.click();
    setFileDownloaded(true);
  };

  const finishCreation = () => {
    queryClient.refetchQueries(['controller', controllerName, 'vpn-clients']);
    notify('VPN client created', { variant: 'positive' });
    navigate(
      Nav.makeTo({
        root: makeLink(paths.pages.LegacyVPNListPage, {
          controllerName,
          companyName,
        }),
        drawer: makeLink(paths.drawers.VPNClientSummaryPage, {
          sid: vpnClient.sid,
          companyName,
          controllerName,
        }),
      }),
    );
  };

  return (
    <>
      <DrawerContent>
        <StepHeader stepNumber="2" title="Setup" />

        <Paragraph>
          Now download the unique configuration file for <strong>{vpnClient.user_email}</strong> and
          securely send it to them along with the guide:{' '}
          <Link
            href="https://meterup.zendesk.com/hc/en-us/articles/11587611861005-How-to-connect-your-Meter-Network-remotely-Client-VPN-"
            target="_blank"
            rel="noreferrer"
          >
            How to connect to your Meter Network remotely.
          </Link>
        </Paragraph>
        <Button
          size="large"
          variant="secondary"
          arrangement="leading-icon"
          icon="download"
          width="100%"
          onClick={downloadFile}
        >
          WireGuard config file
        </Button>
        <Alert
          icon="information"
          heading="We don't store private keys"
          copy={`This configuration file contains a unique private key that we don't store. Once you leave this setup flow, you won't be able to re-download the same configuration file for ${vpnClient.user_email}.`}
        />
      </DrawerContent>
      <DrawerFooter
        actions={
          <Button
            type="submit"
            variant="primary"
            onClick={() => finishCreation()}
            disabled={!fileDownloaded}
          >
            Done
          </Button>
        }
      />
    </>
  );
}

import { checkDefinedOrThrow } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { DetailPageLayout } from '../../../../../../components/DetailPageLayout';
import { AccessPointQuery } from '../../../../../../components/Wireless/utils';
import { paths } from '../../../../../../constants';
import { PermissionType } from '../../../../../../gql/graphql';
import { Nav } from '../../../../../../nav';
import { usePermissions } from '../../../../../../providers/PermissionsProvider';
import { ClientsList2 } from '../../../insights/clients/ClientsList2';
import ClientsListHeader2 from '../../../insights/clients/ClientsListHeader2';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/devices/:uuid',
});

export default function AccessPointClientsListDetailPage() {
  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.AccessPointClientsListDetailPage),
  );
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const virtualDevice = useGraphQL(
    AccessPointQuery,
    { uuid, includeUptime },
    { useErrorBoundary: false },
  ).data?.virtualDevice;

  return (
    <DetailPageLayout
      header={
        <ClientsListHeader2
          networkClientOptions={{
            filter: {
              apSerialNumber: virtualDevice?.hardwareDevice?.serialNumber,
            },
          }}
        />
      }
      main={
        <ClientsList2
          networkClientOptions={{
            filter: {
              apSerialNumber: virtualDevice?.hardwareDevice?.serialNumber,
            },
          }}
        />
      }
    />
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  HStack,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { AutoVpnGroupsForCompanyQuery } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetworksForCompany } from '../../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { AutoVPNGroupsQuery, deleteAutoVPNGroupMutation } from './utils';

type AutoVPNGroupDeleteDialogProps = {
  group: AutoVpnGroupsForCompanyQuery['autoVPNGroupsForCompany'][number];
  state: OverlayTriggerState;
};

export default function AutoVPNGroupDeleteDialog({ group, state }: AutoVPNGroupDeleteDialogProps) {
  const companySlug = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const networks = useNetworksForCompany(companySlug);
  const getSelectNetwork = useCallback(
    (networkUUID: string) => networks.find((net) => net.UUID === networkUUID) ?? undefined,
    [networks],
  );

  const deleteAutoVPNGroup = useGraphQLMutation(deleteAutoVPNGroupMutation);
  const { mutate, isError, error } = deleteAutoVPNGroup;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    mutate(
      { uuid: group.UUID! },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(makeQueryKey(AutoVPNGroupsQuery, { companySlug }));
          closeDrawer();
          close();
          notify('Auto VPN group deleted successfully.', {
            variant: 'positive',
          });
        },
      },
    );
  }, [mutate, queryClient, close, closeDrawer, companySlug, group.UUID]);

  if (!group) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete Auto VPN group" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to remove the Auto VPN group <Text weight="bold">{group.name}</Text> from
              your Meter networks.
            </>
          }
        />

        {!!group?.members?.length && (
          <SummaryList gutter="vertical">
            <SummaryListRow>
              <SummaryListKey>Affected member{group.members.length > 1 && 's'}</SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  {group.members.map((member) => (
                    <Badge
                      variant="neutral"
                      size="small"
                      icon="location"
                      arrangement="leading-icon"
                      key={member.UUID}
                    >
                      {getSelectNetwork(member.networkUUID)?.label}
                    </Badge>
                  ))}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        )}

        {isError && (
          <Alert
            icon="warning"
            variant="negative"
            heading="There was an error deleting the Auto VPN group"
            copy={error && error instanceof Error ? error.message : undefined}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

import type { DevicesForNetworkFilter } from '@meterup/common/src/gql/graphql';
import { useGraphQL } from '@meterup/graphql';
import { keyBy } from 'lodash-es';
import { useMemo } from 'react';

import type { VirtualDeviceType } from '../../../../../../gql/graphql';
import { devicesForNetwork } from '../../../../../../components/Design/RackElevations/utils';
import { deviceTypeForVirtualDeviceType } from '../../../../../../components/Devices/utils';
import { DeviceType } from '../../../../../../gql/graphql';
import { useNetworkUUID } from '../../../../../../hooks/useNetworkFromPath';

const deviceTypeOrder: { [key in DeviceType]: number } = {
  [DeviceType.Controller]: 1,
  [DeviceType.AccessPoint]: 2,
  [DeviceType.Switch]: 3,
  [DeviceType.PowerDistributionUnit]: 4,
};

export type Device = {
  UUID: string;
  label: string;
  deviceType: VirtualDeviceType;
  hardwareDevice?: { serialNumber: string } | null;
};

export const useVirtualDevices = (
  filter?: DevicesForNetworkFilter,
): { virtualDevices: Device[]; uuidToVirtualDeviceMap: Record<string, Device> } => {
  const networkUUID = useNetworkUUID();
  const virtualDevices = useGraphQL(
    devicesForNetwork,
    {
      networkUUID: networkUUID!,
      filter,
    },
    { enabled: !!networkUUID },
  ).data?.virtualDevicesForNetwork;
  return useMemo(() => {
    const uuidToVirtualDeviceMap = keyBy(virtualDevices, 'UUID');
    const sortedDevices: Device[] | undefined = virtualDevices?.sort((a, b) => {
      const order =
        deviceTypeOrder[deviceTypeForVirtualDeviceType[a.deviceType]] -
        deviceTypeOrder[deviceTypeForVirtualDeviceType[b.deviceType]];
      if (order === 0) {
        return a.label.localeCompare(b.label);
      }
      return order;
    });
    return {
      virtualDevices: sortedDevices ?? [],
      uuidToVirtualDeviceMap,
    };
  }, [virtualDevices]);
};

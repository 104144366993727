import type { TextareaProps, TextInputProps } from '@meterup/atto';
import {
  FieldContainer,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  Select,
  SelectItem,
  Textarea,
  TextInput,
} from '@meterup/atto';
import React from 'react';

import { FieldProvider, NumberFieldProvider } from './FieldProvider';

export function NumberField({
  label,
  name,
  description,
  internal,
  optional,
  placeholder,
  defaultValue = null,
  disabled = false,
}: {
  label: React.ReactNode;
  name: string;
  description?: React.ReactNode;
  internal?: boolean;
  optional?: boolean;
  placeholder?: string;
  defaultValue?: number | null;
  disabled?: boolean;
}) {
  return (
    <FieldContainer>
      <NumberFieldProvider name={name} defaultValue={defaultValue}>
        <PrimaryField
          internal={internal}
          label={label}
          optional={optional}
          element={<TextInput type="number" placeholder={placeholder} disabled={disabled} />}
          description={description}
        />
      </NumberFieldProvider>
    </FieldContainer>
  );
}

export function TextField({
  label,
  name,
  description,
  internal,
  optional,
  controls,
  ...elementProps
}: TextInputProps & {
  label: React.ReactNode;
  name: string;
  description?: React.ReactNode;
  internal?: boolean;
  optional?: boolean;
  controls?: React.ReactNode;
}) {
  return (
    <FieldContainer>
      <FieldProvider name={name}>
        <PrimaryField
          internal={internal}
          label={label}
          optional={optional}
          controls={controls}
          element={<TextInput {...elementProps} />}
          description={description}
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export function TextareaField({
  label,
  name,
  description,
  internal,
  optional,
  ...elementProps
}: TextareaProps & {
  label: React.ReactNode;
  name: string;
  description?: React.ReactNode;
  internal?: boolean;
  optional?: boolean;
  rows?: number;
}) {
  return (
    <FieldContainer>
      <FieldProvider name={name}>
        <PrimaryField
          internal={internal}
          label={label}
          optional={optional}
          element={<Textarea {...elementProps} />}
          description={description}
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export function PercentField({
  name,
  label,
  internal,
  indeterminate,
}: {
  name: string;
  label: React.ReactNode;
  internal?: boolean;
  indeterminate?: boolean;
}) {
  return (
    <FieldProvider name={name}>
      <NumberFieldProvider name={name} defaultValue={0}>
        <SecondaryField
          label={label}
          internal={internal}
          element={
            <Select width="100%" indeterminate={indeterminate}>
              {[...Array(101).keys()].map((p) => (
                <SelectItem key={p.toString()}>{p.toString()} %</SelectItem>
              ))}
            </Select>
          }
        />
      </NumberFieldProvider>
    </FieldProvider>
  );
}

export function ToggleField({
  name,
  label,
  description,
  internal,
  supportsIndeterminate,
  disabled,
}: {
  name: string;
  label: React.ReactNode;
  description?: React.ReactNode;
  internal?: boolean;
  supportsIndeterminate?: boolean;
  disabled?: boolean;
}) {
  return (
    <FieldProvider name={name}>
      <PrimaryToggleField
        internal={internal}
        label={label}
        supportsIndeterminate={supportsIndeterminate}
        description={description}
        disabled={disabled}
      />
    </FieldProvider>
  );
}

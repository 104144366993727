import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import SwitchEdit from '../../../../../components/Hardware/Switches/SwitchEdit';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/switches/:id/edit',
  title: 'Edit switch - Switch - Switches - Hardware',
});

export default function SwitchEditPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  const featureFlags = useFeatureFlags();

  if (!isOperator && !featureFlags['sos-ui']) {
    throw new ResourceNotFoundError('Sorry, page not found');
  }

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SwitchEditPage);

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
            permissions.hasPermission(PermissionType.PermSwitchProfileWrite) &&
            nosFlags[NosFeature.SOS],
        )
      }
      should404OnAccessDenied
    >
      <SwitchEdit uuid={drawerParams?.id!} />
    </IsPermitted>
  );
}

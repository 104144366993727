import { Button } from '@meterup/atto';

import { paths } from '../../../constants';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { ReactRouterLink } from '../../ReactRouterLink';

export function AddHotspot20Button() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  return (
    <Button
      condense
      size="small"
      as={ReactRouterLink}
      to={makeDrawerLink(window.location, paths.drawers.Hotspot20AddPage, {
        companyName,
        networkSlug: network.slug,
      })}
      variant="secondary"
      arrangement="leading-icon"
      icon="plus"
    >
      Add Hotspot 2.0 profile
    </Button>
  );
}

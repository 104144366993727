import type { PortForwardingRuleQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';

export const createPortForwardingRule = graphql(`
  mutation CreatePortForwardingRule($networkUUID: UUID!, $input: CreatePortForwardingRuleInput!) {
    createPortForwardingRule(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updatePortForwardingRule = graphql(`
  mutation UpdatePortForwardingRule($uuid: UUID!, $input: UpdatePortForwardingRuleInput!) {
    updatePortForwardingRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deletePortForwardingRule = graphql(`
  mutation DeletePortForwardingRule($uuid: UUID!) {
    deletePortForwardingRule(UUID: $uuid) {
      UUID
    }
  }
`);

export type PortForwardingRule = PortForwardingRuleQuery['portForwardingRule'];

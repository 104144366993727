import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, DrawerContent, DrawerFooter } from '@meterup/atto';
import type {
  APIError,
  AxiosAPIError,
  LocationsCreateRequest,
  Optional,
  QuoteRequest,
  QuoteRequestCreateRequest,
} from '@meterup/connect-api';
import { post } from '@meterup/connect-api/src/axios';
import useConnectionsQueryKey from '@meterup/connect-api/src/hooks/useConnectionsQueryKey';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Select, { SelectInstance, ValueContainerProps } from 'react-select';
import { useAsyncList } from 'react-stately';

import useFetchLocationPredictions, {
  FetchLocationItem,
} from '../hooks/useFetchLocationPredictions';
import { useIdentity } from '../hooks/useIdentity';
import usePredictionFormatter from '../hooks/usePredictionFormatter';
import { logError } from '../Log.utils';
import { FieldDescription, Form } from '../styles/Form';
import { DefaultPreventable } from '../utils/eventHandlers';
import formatLocationParts from '../utils/formatLocationParts';
import { ClearIndicator } from './Select/ClearIndicator';
import { useCustomStyles } from './Select/hooks/useCustomStyles';
import { IconValueContainer } from './Select/IconValueContainer';
import withIconValueContainer from './Select/withIconValueContainer';

const FILTER_FN = () => true;
export type AddLocationButtonsProps = {
  disabled: boolean;
  loading: boolean;
  onSubmit: (e: DefaultPreventable) => void;
};
type AddLocationProps = {
  buttons?: (props: AddLocationButtonsProps) => React.ReactNode;
  buttonText?: string;
  companySID?: string;
  dropdownPlaceholder?: string;
  emptyFieldDescription?: React.ReactNode;
  isAdmin?: boolean;
  onError?: (error: APIError) => void;
  onLoading?: (loading: boolean) => void;
  onSelectionChange?: (selection: Optional<FetchLocationItem>) => void;
  onSuccess?: (quoteRequest: QuoteRequest) => void;
};
const LocationIconValueContainer = withIconValueContainer<
  ValueContainerProps<google.maps.places.AutocompleteOptions>
>('location', IconValueContainer as any);

export default function AddLocation({
  buttons,
  buttonText,
  companySID,
  dropdownPlaceholder = 'Search places to see available Internet providers',
  emptyFieldDescription,
  isAdmin,
  onError,
  onLoading,
  onSelectionChange: onSelectionChangeCallback,
  onSuccess,
}: AddLocationProps) {
  const queryClient = useQueryClient();
  const identity = useIdentity();
  const [locationsCreateRequest, setLocationsCreateRequest] = useState<LocationsCreateRequest>();
  const textInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const queryKey = useConnectionsQueryKey();
  const createQuoteMutation = useMutation<QuoteRequest, AxiosAPIError, QuoteRequestCreateRequest>(
    (vars) => post(`v1/companies/${companySID}/connect/quote-requests?via-admin=${isAdmin}`, vars),
    {
      onSuccess: (result) => {
        if (onSuccess) {
          onSuccess(result);
        }
        if (onLoading) {
          onLoading(false);
        }
        return queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        logError(error);
        if (onLoading) {
          onLoading(false);
        }
        const errorResponse = error.response?.data;
        if (onError && errorResponse) {
          onError(errorResponse);
        }
      },
      onMutate: () => {
        if (onLoading) {
          onLoading(true);
        }
      },
    },
  );
  useEffect(() => {
    textInputRef.current?.focus();
  }, []);
  const fetchPredictions = useFetchLocationPredictions();
  const list = useAsyncList<FetchLocationItem>({
    async load({ filterText }) {
      const items = await fetchPredictions(filterText);
      return {
        items,
      };
    },
  });
  const formatPrediction = usePredictionFormatter();
  const [selectedOption, setSelectedOption] = useState<FetchLocationItem>();
  const onSelectionChange = useCallback(
    // eslint-disable-next-line consistent-return
    async (selected: FetchLocationItem | null) => {
      if (!selected) {
        setSelectedOption(undefined);
        setLocationsCreateRequest(undefined);
        return selected;
      }

      if (onSelectionChangeCallback) {
        onSelectionChangeCallback(selected);
      }
      setSelectedOption(selected);
      const formatted = await formatPrediction(selected);
      if (!formatted) {
        return undefined;
      }
      setLocationsCreateRequest(formatLocationParts(formatted));
      if (formRef.current) {
        formRef.current.focus();
      }
    },
    [formatPrediction, onSelectionChangeCallback],
  );
  const submitLocation = useCallback(
    async (e: DefaultPreventable) => {
      e.preventDefault();
      try {
        await createQuoteMutation.mutateAsync({
          companyName: companySID || '',
          contactName: '',
          contactEmail: identity?.username || '',
          contactTelephone: '',
          requestedDownloadKbps: 0,
          requestedUploadKbps: 0,
          contractMinimumMonths: 0,
          notes: '',
          existingMonthlyFeeCents: 0,
          existingProviderSid: '',
          location: locationsCreateRequest,
        });
      } catch (err) {
        logError(err);
      }
    },
    [companySID, createQuoteMutation, identity?.username, locationsCreateRequest],
  );
  const customStylesProvider = useCustomStyles<FetchLocationItem, false>({
    size: 'medium',
    showIndicator: true,
    controlPositioning: false,
    singleLine: true,
    capsuleStyle: false,
  });
  const getField = useCallback(
    (field: 'description' | 'id') => (item: FetchLocationItem) => {
      if (item[field]) {
        return item[field] ?? '';
      }
      return '';
    },
    [],
  );
  // const multiSelectRef = useRef<Select<
  //   AutocompletePrediction,
  //   false,
  //   GroupBase<AutocompletePrediction>
  // > | null>(null);
  const multiSelectRef = useRef<SelectInstance<FetchLocationItem>>(null);
  return (
    <Form ref={formRef} onSubmit={submitLocation}>
      <DrawerContent>
        <Select<FetchLocationItem>
          options={list.items}
          value={selectedOption}
          isLoading={list.isLoading}
          onInputChange={list.setFilterText}
          onChange={onSelectionChange}
          openMenuOnFocus
          styles={customStylesProvider}
          getOptionLabel={getField('description')}
          getOptionValue={getField('id')}
          menuPortalTarget={document.body}
          menuPlacement="bottom"
          menuPosition="absolute"
          noOptionsMessage={() => null}
          placeholder={dropdownPlaceholder}
          isClearable
          ref={multiSelectRef}
          filterOption={FILTER_FN}
          components={{
            ClearIndicator,
            ValueContainer: LocationIconValueContainer as any,
            DropdownIndicator: null,
            LoadingIndicator: undefined,
          }}
        />
        <FieldDescription>
          {locationsCreateRequest ? (
            <>
              Address: {locationsCreateRequest.address1}
              {locationsCreateRequest.address2 && ` ${locationsCreateRequest.address2}`},{' '}
              {locationsCreateRequest.city}, {locationsCreateRequest.state}
            </>
          ) : (
            emptyFieldDescription
          )}
        </FieldDescription>
      </DrawerContent>
      <DrawerFooter
        actions={
          buttons ? (
            buttons({
              disabled: !locationsCreateRequest,
              loading: createQuoteMutation.isLoading,
              onSubmit: submitLocation,
            })
          ) : (
            <Button
              variant="primary"
              loading={createQuoteMutation.isLoading}
              disabled={!locationsCreateRequest}
              type="submit"
            >
              {buttonText || 'Save'}
            </Button>
          )
        }
      />
    </Form>
  );
}
AddLocation.whyDidYouRender = true;

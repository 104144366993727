import { Button, SearchInput } from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import IsPermitted from '../../permissions/IsPermitted';

export default function OneToOneNATActions() {
  const network = useNetwork();
  const companyName = useCurrentCompany();

  return (
    <>
      <IsPermitted
        isPermitted={({ permissions, ldFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermOneToOneNatWrite) &&
              ldFlags['one-to-one-nat'],
          )
        }
      >
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.CreateOneToOneNATRulePage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add 1:1 NAT rule
        </Button>
      </IsPermitted>
      <SearchInput />
    </>
  );
}

import {
  Badge,
  Body,
  Column,
  Columns,
  CopyBox,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  HStack,
  Section,
  SectionContent,
  SectionHeader,
  space,
  styled,
  Text,
} from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { useEffect, useMemo, useState } from 'react';

import type { ControllerVirtualDevice } from './useCurrentControllers';
import { graphql } from '../../../gql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { getDurationSeconds, getStep } from '../../../utils/chart_utils';
import { timeAgo } from '../../../utils/formatTime';
import { UptimeChart } from '../../Charts/UptimeChart';
import { getPhyInterfaceLabel } from '../../Firewall/utils';
import { StickyFilterBar } from '../../Insights/FilterBar';
import { timePeriodLabel } from '../../Insights/Network/utils';

const hostMonitoringAvailabilityQuery = graphql(`
  query hostMonitoring(
    $networkUUID: UUID!
    $hostIPs: [String!]!
    $durationSeconds: Int!
    $stepSeconds: Int!
  ) {
    hostMonitoringForNetwork(
      networkUUID: $networkUUID
      filter: { durationSeconds: $durationSeconds, stepSeconds: $stepSeconds }
      hostIPs: $hostIPs
    ) {
      values {
        hostIPAddr
        virtualDeviceUUID
        timestamp
        value
        status
        srcEthPortNum
      }
    }
  }
`);

const UptimeGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(328px, 1fr))',
  gap: '$16',
});

export default function HostAvailability({
  virtualDevices,
}: {
  virtualDevices: ControllerVirtualDevice[];
}) {
  const networkUUID = useNetwork().UUID;

  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const { data: hostData, dataUpdatedAt } = useGraphQL(
    hostMonitoringAvailabilityQuery,
    {
      networkUUID,
      hostIPs: ['8.8.4.4', '9.9.9.9', '1.0.0.1', '8.8.8.8', '1.1.1.1', '208.69.38.205'],
      durationSeconds: getDurationSeconds(currentTimePeriod),
      stepSeconds: getStep(currentTimePeriod) * 5.0,
    },
    { refetchInterval: 6000 * 1000 },
  );

  const [updatedString, setUpdatedString] = useState(timeAgo.format(dataUpdatedAt, 'round'));

  // HACK
  const virtualDevicesMap = useMemo(
    () => new Map(virtualDevices.map((vd) => [vd.UUID, vd])),
    [virtualDevices],
  );

  useEffect(() => {
    const handle = setInterval(
      () => setUpdatedString(timeAgo.format(dataUpdatedAt, 'round')),
      1000,
    );
    return () => {
      clearInterval(handle);
    };
  }, [dataUpdatedAt]);

  interface AggregatedData {
    [virtualDeviceLabel: string]: {
      [ip: string]: {
        [portLabel: string]: {
          values: { value: number; timestamp: Date }[];
          totalRTT: number;
        };
      };
    };
  }

  const aggregatedData = useMemo(() => {
    const data: AggregatedData = {};

    hostData?.hostMonitoringForNetwork.forEach(({ values }) => {
      values.forEach((result) => {
        const hostIP = result.hostIPAddr;
        const virtualDevice = virtualDevicesMap.get(result.virtualDeviceUUID);

        if (!virtualDevice) return;

        const phyInterface = virtualDevice.phyInterfaces.find(
          (pi) => pi.portNumber === result.srcEthPortNum,
        );
        if (!phyInterface) return;

        const portLabel = getPhyInterfaceLabel(phyInterface, false);

        if (!data[virtualDevice.label]) {
          data[virtualDevice.label] = {};
        }

        if (!data[virtualDevice.label][hostIP]) {
          data[virtualDevice.label][hostIP] = {};
        }

        if (!data[virtualDevice.label][hostIP][portLabel]) {
          data[virtualDevice.label][hostIP][portLabel] = {
            values: [],
            totalRTT: 0,
          };
        }

        data[virtualDevice.label][hostIP][portLabel].totalRTT += result.value;
        data[virtualDevice.label][hostIP][portLabel].values.push({
          value: result.status === 'OK' ? 1 : 0,
          timestamp: new Date(result.timestamp),
        });
      });
    });
    return data;
  }, [hostData?.hostMonitoringForNetwork, virtualDevicesMap]);

  return (
    <Columns>
      <Column gutter="none" spacing={space(16)}>
        <StickyFilterBar>
          <Badge size="small" variant="neutral">
            {updatedString}
          </Badge>
          <DropdownMenu>
            <DropdownMenuButton
              variant="secondary"
              arrangement="leading-icon"
              icon="clock"
              size="small"
            >
              {timePeriodLabel(currentTimePeriod)}
            </DropdownMenuButton>
            <DropdownMenuPopover>
              <DropdownMenuRadioGroup
                value={currentTimePeriod}
                onValueChange={(val) => setCurrentTimePeriod(val as string)}
              >
                <DropdownMenuRadioItem value="1h">{timePeriodLabel('1h')}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="6h">{timePeriodLabel('6h')}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="24h">{timePeriodLabel('24h')}</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuPopover>
          </DropdownMenu>
        </StickyFilterBar>
        {Object.entries(aggregatedData)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([virtualDeviceLabel, hostsRecord]) => (
            <Section relation="stacked" key={virtualDeviceLabel}>
              <SectionHeader heading={virtualDeviceLabel} />
              <SectionContent gutter="all" spacing={space(12)}>
                <UptimeGrid>
                  {Object.entries(hostsRecord)
                    .sort(([a], [b]) => a.localeCompare(b))
                    .flatMap(([hostIP, portsRecord]) =>
                      Object.entries(portsRecord)
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([portLabel, result]) => (
                          <UptimeChart
                            key={`${hostIP}-${portLabel}`}
                            label={
                              <HStack spacing={space(16)} wrap="wrap">
                                <CopyBox aria-label="Copy host IP address" value={hostIP}>
                                  <Text family="monospace" size={14}>
                                    {hostIP}
                                  </Text>
                                </CopyBox>
                                <Body>{portLabel}</Body>
                              </HStack>
                            }
                            data={result.values}
                            badge={`${(result.totalRTT / (1000000 * result.values.length)).toFixed(2)} ms`}
                          />
                        )),
                    )}
                </UptimeGrid>
              </SectionContent>
            </Section>
          ))}
      </Column>
    </Columns>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import CaptivePortal from '../../../../../components/NetworkWide/CaptivePortals/CaptivePortal';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/captive-portals/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Captive portal - Network-wide',
});

export default function CaptivePortalPage() {
  const rootParams = Nav.useRegionParams('root', paths.pages.CaptivePortalPage);
  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, ldFlags, nosFlags }) =>
        permissions.hasPermission(PermissionType.PermCaptivePortalRead) &&
        (!!ldFlags['captive-portal'] || isOperator) &&
        nosFlags[NosFeature.COS2] &&
        nosFlags[NosFeature.CAPTIVE_PORTAL] &&
        ((rootParams?.tab === 'activity' && nosFlags[NosFeature.CAPTIVE_PORTAL_AUTH_EVENTS]) ||
          rootParams?.tab === 'configure')
      }
      should404OnAccessDenied
      allowOperatorAlways={false}
    >
      <CaptivePortal />
    </IsPermitted>
  );
}

import type { api } from '@meterup/proto';
import { isDefined, isIPWithinRange, safeParseAddress4 } from '@meterup/common';
import { useMemo } from 'react';

import { type NetworkClient, useNetworkClients } from './networkClients/useNetworkClients';
import { useControllerConfigOrNull } from './useControllerConfig';
import { useNetwork } from './useNetworkFromPath';
import { useVLANConfigModel } from './useVLANConfigModel';

export function useClientInVLANPredicate(controllerName: string, vlanName: string) {
  const vlan = useVLANConfigModel(controllerName, vlanName);

  return useMemo(() => {
    const range = vlan.dhcpRange;

    return (client: NetworkClient) => {
      const clientIP = safeParseAddress4(client.ip);

      return isDefined(clientIP) && isDefined(range) ? isIPWithinRange(clientIP, range) : false;
    };
  }, [vlan.dhcpRange]);
}

export function useVLANSFromClientMatcher(controllerName: string) {
  const config = useControllerConfigOrNull(controllerName);

  return useMemo(() => {
    if (isDefined(config)) {
      return (client: NetworkClient) => {
        const clientIP = safeParseAddress4(client.ip);

        return isDefined(clientIP)
          ? config.vlans.filter((vlan) =>
              isDefined(vlan.subnet) ? clientIP.isInSubnet(vlan.subnet) : false,
            )
          : [];
      };
    }

    return () => [];
  }, [config]);
}

export const useVLANClients = (controller: api.ControllerResponse, vlanName: string) => {
  const predicate = useClientInVLANPredicate(controller.name, vlanName);
  const network = useNetwork();

  const clients = useNetworkClients(network, { filter: { excludeMeterHardware: true } });

  return useMemo(() => (clients ?? []).filter(predicate), [predicate, clients]);
};

export const useVLANClientByMAC = (
  controller: api.ControllerResponse,
  vlanName: string,
  macAddress: string,
) => useVLANClients(controller, vlanName).find((c) => c.macAddress === macAddress);

import type { ButtonProps } from '@meterup/atto';
import { Button } from '@meterup/atto';
import { useFormikContext } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type PartialButtonProps = Partial<ButtonProps>;

export function CancelAndGoBackButton({
  children = 'Cancel',
  variant = 'secondary',
  ...props
}: PartialButtonProps) {
  const navigate = useNavigate();
  return (
    <Button {...props} variant={variant} onClick={() => navigate(-1)}>
      {children}
    </Button>
  );
}

export function DrawerFormSaveButton({
  type = 'submit',
  children = 'Save',
  ...props
}: PartialButtonProps) {
  return (
    <Button type={type} {...props}>
      {children}
    </Button>
  );
}

export function DiscardChangesButton({
  children = 'Discard',
  variant = 'secondary',
  ...props
}: PartialButtonProps) {
  const formik = useFormikContext<unknown>();

  return (
    <Button {...props} variant={variant} onClick={() => formik.resetForm()}>
      {children}
    </Button>
  );
}

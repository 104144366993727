import { Drawer, DrawerContent, DrawerHeader, Sections } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import {
  AutoVPNConnectionInfo,
  AutoVPNMemberActions,
  AutoVPNMemberDetails,
} from '../../../components/autoVPN/AutoVPNMember';
import { AutoVPNGroupQuery } from '../../../components/autoVPN/utils';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/auto-vpn/groups/:groupUUID/members/:memberUUID',
  title: 'Member - Group - Auto VPN - Network',
});

export default function AutoVPNGroupMemberPage() {
  const closeDrawer = useCloseDrawerCallback();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AutoVPNGroupMemberPage);

  const group = useGraphQL(
    AutoVPNGroupQuery,
    { uuid: drawerParams?.groupUUID! },
    {
      enabled: !!drawerParams?.groupUUID,
    },
  ).data?.autoVPNGroup;

  const member = useMemo(
    () => group?.members?.find((m) => m.UUID === drawerParams?.memberUUID),
    [group, drawerParams?.memberUUID],
  );

  return (
    <IsPermitted
      should404OnAccessDenied
      path={Meta().path}
      allowOperatorAlways={false}
      isPermitted={({ isOperator, permissions, companyNosFlags, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermAutoVpnRead) &&
        companyNosFlags[NosFeature.SITE_TO_SITE_VPN] &&
        (isOperator || !!ldFlags['site-to-site-vpn']) &&
        !!drawerParams?.groupUUID &&
        !!drawerParams?.memberUUID &&
        !!group &&
        !!member
      }
    >
      <Drawer>
        <DrawerHeader
          icon="site-to-site"
          heading={member?.network.label}
          actions={<AutoVPNMemberActions group={group!} member={member!} />}
          onClose={closeDrawer}
        />
        <DrawerContent gutter="none">
          <Sections>
            <AutoVPNMemberDetails member={member!} />
            <AutoVPNConnectionInfo member={member!} />
          </Sections>
        </DrawerContent>
      </Drawer>
    </IsPermitted>
  );
}

import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import React from 'react';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import SSIDForm from '../../../../../components/Wireless/SSIDs/SSIDForm';
import { SSIDsQuery } from '../../../../../components/Wireless/SSIDs/SSIDsUtils';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useNetworkUUID } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/ssids/:uuid/edit',
  title: 'Edit SSID - SSIDs - Wireless',
});

export default function SSIDEditPage() {
  const networkUUID = checkDefinedOrThrow(useNetworkUUID());
  const { uuid } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.SSIDEditPage));

  const ssids = useGraphQL(SSIDsQuery, { networkUUID }).data?.ssidsForNetwork;
  expectDefinedOrThrow(ssids, new ResourceNotFoundError('Unable to load SSIDs'));

  const ssid = ssids.find((s) => s.UUID === uuid);
  if (!ssid) throw new ResourceNotFoundError('SSID not found');

  return (
    <IsPermitted
      isPermitted={({ permissions, pathParams }) =>
        Boolean(permissions.hasPermission(PermissionType.PermSsidsWrite) && !!pathParams?.uuid)
      }
      path={Meta().path}
    >
      <SSIDForm ssid={ssid} />
    </IsPermitted>
  );
}

import {
  Alert,
  Badge,
  Body,
  Button,
  colors,
  CopyBox,
  darkThemeSelector,
  DeviceTarget,
  Drawer,
  DrawerContent,
  DrawerHeader,
  HStack,
  Icon,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  sizing,
  space,
  styled,
  SummaryList,
  Tooltip,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { DateTime } from 'luxon';
import { useState } from 'react';

import type { SSID } from './SSIDsUtils';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { getWiFiQRCodeConnectionString } from '../../../utils/getWiFiQRCodeConnectionString';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { screamingSnakeCaseToWords } from '../../../utils/strings';
import { StickyObjectHeader } from '../../Object/ObjectHeader';
import { ReactRouterLink } from '../../ReactRouterLink';
import SSIDActions from './SSIDActions';
import { SSIDQRCode } from './SSIDQRCode';
import { SSIDAccessPoints } from './SSIDsList';
import {
  encryptionProtocolHuman,
  enterpriseEncryptionProtocol,
  SSID_NO_VLAN_MESSAGE,
  SSIDsQuery,
} from './SSIDsUtils';

function SSIDNoPasswordInner({ ssid }: { ssid: SSID }) {
  if (enterpriseEncryptionProtocol(ssid.encryptionProtocol)) {
    return (
      <Tooltip contents="Authentication for this SSID is done by logging in with your credentials.">
        <HStack align="center" justify="center" spacing={space(6)}>
          <Body weight="bold">{encryptionProtocolHuman(ssid.encryptionProtocol)}</Body>
          <Icon icon="question" size={space(14)} />
        </HStack>
      </Tooltip>
    );
  }

  return (
    <HStack align="center" justify="center" spacing={space(6)}>
      <Body weight="bold">No password required</Body>
    </HStack>
  );
}

const SSIDPasswordContainer = styled(CopyBox, {
  justifyContent: 'center',
  width: '100%',
});

function SSIDPassword({ isShown, ssid }: { isShown: boolean; ssid: SSID }) {
  return ssid.password ? (
    <Body weight="bold">
      <SSIDPasswordContainer
        aria-label={`Copy ${ssid.ssid}'s password`}
        size="large"
        value={ssid.password}
      >
        {isShown ? ssid.password : '••••••••••••••'}
      </SSIDPasswordContainer>
    </Body>
  ) : (
    <SSIDNoPasswordInner ssid={ssid} />
  );
}

const SSIDAccessContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: sizing.contentSquish,
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },
});

function SSIDAccess({ ssid }: { ssid: SSID }) {
  const [revealDetails, setRevealDetails] = useState(false);
  return (
    <SSIDAccessContainer>
      <SSIDPassword isShown={revealDetails} ssid={ssid} />
      <SSIDQRCode
        isShown={revealDetails}
        data={getWiFiQRCodeConnectionString(ssid.ssid, ssid.password ?? '')}
      />
      <Button size="medium" variant="secondary" onClick={() => setRevealDetails(!revealDetails)}>
        {revealDetails ? 'Conceal' : 'Reveal'}
      </Button>
    </SSIDAccessContainer>
  );
}

function EnabledDisabledBadge({ isEnabled, label }: { isEnabled: boolean; label?: string }) {
  return isEnabled ? (
    <Badge arrangement="leading-icon" icon="checkmark" size="small" variant="positive">
      {label || 'Enabled'}
    </Badge>
  ) : (
    <Badge arrangement="leading-icon" icon="cross" size="small" variant="neutral">
      Disabled
    </Badge>
  );
}

export function SSIDDrawerContent({ ssid }: { ssid: SSID }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const [
    isNOSEnabled80211ax,
    isNOSEnabled80211r,
    isNOSEnabled80211w,
    isNOSEnabledClientAssociationSteering,
    isNOSEnabledClientIsolation,
    isNOSEnabled80211k,
    isNOSEnabled80211v,
  ] = useNosFeaturesEnabled([
    NosFeature.WOS_80211AX,
    NosFeature.WOS_80211R,
    NosFeature.WOS_80211W,
    NosFeature.WOS_CLIENT_ASSOCIATION_STEERING,
    NosFeature.WOS_CLIENT_ISOLATION,
    NosFeature.WOS_80211k,
    NosFeature.WOS_80211v,
  ]);

  return (
    <Sections>
      {!ssid.vlan && (
        <Section relation="stacked">
          <SectionContent gutter="horizontal">
            <Alert
              variant="attention"
              icon="attention"
              heading={SSID_NO_VLAN_MESSAGE}
              trailingButtons={
                <Button
                  as={ReactRouterLink}
                  to={makeDrawerLink(window.location, paths.drawers.SSIDEditPage, {
                    companyName,
                    networkSlug: network.slug,
                    uuid: ssid.UUID,
                  })}
                  arrangement="leading-icon"
                  icon="pencil"
                  size="small"
                  variant="secondary"
                >
                  Assign a VLAN
                </Button>
              }
            />
          </SectionContent>
        </Section>
      )}
      <Section relation="stacked">
        <SectionContent gutter="horizontal">
          <SSIDAccess ssid={ssid} />
        </SectionContent>
      </Section>
      <Section relation="stacked">
        <SectionHeader heading="Metadata" />
        <SectionContent gutter="all">
          <SummaryList
            gutter="none"
            pairs={[
              ...(ssid.vlan?.UUID
                ? [
                    {
                      label: 'VLAN',
                      value: (
                        <DeviceTarget
                          as={ReactRouterLink}
                          to={makeDrawerLink(window.location, paths.drawers.VLANDrawerPage, {
                            companyName,
                            networkSlug: network.slug,
                            uuid: ssid.vlan?.UUID,
                          })}
                          aria-label={`View ${ssid.vlan?.name}`}
                          type="vlan"
                        >
                          {ssid.vlan?.name}
                        </DeviceTarget>
                      ),
                    },
                  ]
                : []),
              {
                label: 'Access points',
                value: <SSIDAccessPoints ssid={ssid} />,
              },
            ]}
          />
        </SectionContent>
      </Section>
      <Section relation="stacked">
        <SectionHeader heading="Security" />
        <SectionContent gutter="all">
          <SummaryList
            gutter="none"
            pairs={[
              {
                label: 'Guest access',
                value: <EnabledDisabledBadge isEnabled={ssid.isGuest} />,
              },
              {
                label: 'Encryption',
                value: ssid.encryptionProtocol ? (
                  <Badge arrangement="leading-icon" icon="padlock" size="small" variant="neutral">
                    {ssid.encryptionProtocol}
                  </Badge>
                ) : (
                  <EnabledDisabledBadge isEnabled={false} />
                ),
              },
              ...(ssid.passwordRotationCadence
                ? [
                    {
                      label: 'Password rotation',
                      value: screamingSnakeCaseToWords(ssid.passwordRotationCadence),
                    },
                    ...(ssid.passwordLastRotatedAt
                      ? [
                          {
                            label: 'Last rotated',
                            value: DateTime.fromISO(ssid.passwordLastRotatedAt).toRelative(),
                          },
                        ]
                      : []),
                    ...(ssid.passwordNextRotationAt
                      ? [
                          {
                            label: 'Next rotation',
                            value: DateTime.fromISO(ssid.passwordNextRotationAt).toRelative(),
                          },
                        ]
                      : []),
                  ]
                : []),
            ]}
          />
        </SectionContent>
      </Section>
      <Section relation="stacked">
        <SectionHeader heading="Radios" />
        <SectionContent gutter="all">
          <SummaryList
            gutter="none"
            pairs={[
              {
                label: '2.4 GHz',
                value: ssid.isBand2GAllowed ? (
                  <Tooltip contents="Minimum basic rate for 2.4 GHz band" asChild={false}>
                    <EnabledDisabledBadge
                      isEnabled={ssid.isBand2GAllowed}
                      label={`${ssid.band2GMinimumBasicRateKbps} Kbps`}
                    />
                  </Tooltip>
                ) : (
                  <EnabledDisabledBadge isEnabled={false} />
                ),
              },
              {
                label: '5 GHz',
                value: ssid.isBand5GAllowed ? (
                  <Tooltip contents="Minimum basic rate for 2.4 GHz band" asChild={false}>
                    <EnabledDisabledBadge
                      isEnabled={ssid.isBand5GAllowed}
                      label={`${ssid.band5GMinimumBasicRateKbps} Kbps`}
                    />
                  </Tooltip>
                ) : (
                  <EnabledDisabledBadge isEnabled={false} />
                ),
              },
            ]}
          />
        </SectionContent>
      </Section>
      {(isNOSEnabled80211ax ||
        isNOSEnabled80211r ||
        isNOSEnabled80211w ||
        isNOSEnabled80211k ||
        isNOSEnabled80211v) && (
        <Section relation="stacked">
          <SectionHeader heading="Standards" />
          <SectionContent gutter="all">
            <SummaryList
              gutter="none"
              pairs={[
                ...(isNOSEnabled80211ax
                  ? [
                      {
                        label: '802.11ax',
                        value: <EnabledDisabledBadge isEnabled={!!ssid.is80211axEnabled} />,
                      },
                    ]
                  : []),
                ...(isNOSEnabled80211r
                  ? [
                      {
                        label: '802.11r',
                        value: <EnabledDisabledBadge isEnabled={!!ssid.roaming80211rIsEnabled} />,
                      },
                    ]
                  : []),
                ...(isNOSEnabled80211w
                  ? [
                      {
                        label: '802.11w',
                        value: (
                          <EnabledDisabledBadge
                            isEnabled={!!ssid.encryption80211wProtectedManagementFramesMode}
                            label={
                              ssid.encryption80211wProtectedManagementFramesMode
                                ? screamingSnakeCaseToWords(
                                    ssid.encryption80211wProtectedManagementFramesMode,
                                  )
                                : undefined
                            }
                          />
                        ),
                      },
                    ]
                  : []),
                ...(isNOSEnabled80211k
                  ? [
                      {
                        label: '802.11k',
                        value: <EnabledDisabledBadge isEnabled={!!ssid.roaming80211kIsEnabled} />,
                      },
                    ]
                  : []),
                ...(isNOSEnabled80211v
                  ? [
                      {
                        label: '802.11v',
                        value: <EnabledDisabledBadge isEnabled={!!ssid.roaming80211vIsEnabled} />,
                      },
                    ]
                  : []),
              ]}
            />
          </SectionContent>
        </Section>
      )}
      {(isNOSEnabledClientAssociationSteering ||
        isNOSEnabledClientIsolation ||
        isNOSEnabled80211k) && (
        <Section relation="stacked">
          <SectionHeader heading="Clients" />
          <SectionContent gutter="all">
            <SummaryList
              gutter="none"
              pairs={[
                ...(isNOSEnabledClientIsolation
                  ? [
                      {
                        label: 'Isolation',
                        value: <EnabledDisabledBadge isEnabled={!!ssid.isIsolateClientsEnabled} />,
                      },
                    ]
                  : []),
                ...(isNOSEnabledClientAssociationSteering
                  ? [
                      {
                        label: 'Association steering',
                        value: (
                          <EnabledDisabledBadge
                            isEnabled={!!ssid.clientSteeringAssociationSteeringIsEnabled}
                          />
                        ),
                      },
                    ]
                  : []),
                ...(isNOSEnabled80211k
                  ? [
                      {
                        label: 'Post association steering',
                        value: (
                          <EnabledDisabledBadge
                            isEnabled={!!ssid.clientSteeringPostassociationSteeringIsEnabled}
                          />
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          </SectionContent>
        </Section>
      )}
    </Sections>
  );
}

export default function SSIDDrawer({ uuid }: { uuid: string }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork;
  expectDefinedOrThrow(ssids, new ResourceNotFoundError('Unable to load SSIDs'));

  const ssid = ssids.find((s: SSID) => s.UUID === uuid);
  if (!ssid) throw new ResourceNotFoundError('SSID not found');

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Drawer>
      <DrawerHeader
        icon="ssid"
        heading="SSID"
        onClose={closeDrawer}
        actions={<SSIDActions uuid={ssid.UUID} view="drawer" />}
      />
      <DrawerContent gutter="bottom">
        <StickyObjectHeader
          icon="ssid"
          name={ssid.ssid}
          status={ssid.isEnabled ? 'enabled' : 'disabled'}
          lifecycle={ssid.isHidden ? 'hidden' : 'visible'}
          ends="card"
          tied
          link={makeLink(paths.pages.SSIDDetailsPage, {
            networkSlug: network.slug,
            companyName,
            uuid: ssid.UUID,
          })}
          cta="View SSID"
        />
        <SSIDDrawerContent ssid={ssid} />
      </DrawerContent>
    </Drawer>
  );
}

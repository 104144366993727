import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import type { PhyInterfaceQueryResult } from './utils';
import { paths } from '../../../constants';
import { type SwitchQueryQuery, PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import IsPermitted from '../../permissions/IsPermitted';
import { PortCycleMutation, PortsQuery } from './utils';

export default function SwitchPortActions({
  port,
  virtualDevice,
  view,
}: {
  port: PhyInterfaceQueryResult;
  virtualDevice: SwitchQueryQuery['virtualDevice'];
  view: 'detail' | 'drawer' | 'edit' | 'list';
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const queryClient = useQueryClient();
  const mutation = useGraphQLMutation(PortCycleMutation);

  const cycle = (mode: 'poe' | 'port') => {
    if (!virtualDevice.hardwareDevice?.serialNumber) return;

    const messages =
      mode === 'poe'
        ? {
            success: 'Request sent to cycle PoE+ on this Port',
            error: 'There was an error cycling PoE+ on this Port',
          }
        : {
            success: 'Request sent to cycle this Port',
            error: 'There was an error cycling this Port',
          };

    mutation.mutate(
      {
        portNumber: port.portNumber,
        serialNumber: virtualDevice.hardwareDevice.serialNumber,
        poeCycle: mode === 'poe',
        portCycle: mode === 'port',
      },
      {
        onSuccess: () => {
          notify(messages.success, {
            variant: 'positive',
          });
          queryClient.invalidateQueries(
            makeQueryKey(PortsQuery, { virtualDeviceUUID: virtualDevice.UUID }),
          );
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(`${messages.error}${gqlErr?.message ? `: ${gqlErr.message}` : ''}`, {
            variant: 'negative',
          });
        },
      },
    );
  };

  return (
    <>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermPhyInterfaceWrite) &&
              nosFlags[NosFeature.SOS],
          )
        }
      >
        {view !== 'edit' && (
          <Button
            as={Link}
            to={makeDrawerLink(window.location, paths.drawers.SwitchPortEditPage, {
              companyName,
              networkSlug: network.slug,
              virtualDeviceUUID: virtualDevice.UUID,
              phyInterfaceUUID: port.UUID,
            })}
            arrangement="leading-icon"
            icon="pencil"
            variant="secondary"
          >
            Edit
          </Button>
        )}
      </IsPermitted>
      <IsPermitted
        anyPermissions={[
          PermissionType.PermNetworkDevicesWrite,
          PermissionType.PermHardwareDeviceRpcPortCycle,
        ]}
      >
        {virtualDevice.hardwareDevice && (
          <DropdownMenu>
            <DropdownMenuButton
              variant="secondary"
              icon="overflow-horizontal"
              arrangement="hidden-label"
            >
              Actions
            </DropdownMenuButton>
            <DropdownMenuPopover align="end">
              {port.isPOEEnabled && (
                <DropdownMenuItem
                  disabled={mutation.isLoading}
                  onSelect={() => cycle('poe')}
                  icon="lightning-cycle"
                >
                  Cycle PoE+
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                disabled={mutation.isLoading}
                onSelect={() => cycle('port')}
                icon={port.portNumber % 2 === 0 ? 'ethernet-down-cycle' : 'ethernet-up-cycle'}
              >
                Cycle port
              </DropdownMenuItem>
            </DropdownMenuPopover>
          </DropdownMenu>
        )}
      </IsPermitted>
    </>
  );
}

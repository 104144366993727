import type { FieldArrayRenderProps } from 'formik';
import {
  Badge,
  Body,
  Button,
  ComboBox,
  ComboBoxItem,
  CompositeField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  MultiComboBox,
  MultiComboBoxItem,
  MultiComboBoxSection,
  PrimaryField,
  PrimaryFieldComposite,
  space,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { UpdateSsidMutationMutationVariables } from '../../../gql/graphql';
import type { SSIDsQueryResult } from '../SSIDs/SSIDsUtils';
import type { AddEditNaiRealmInput } from './utils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { withZodSchema } from '../../../utils/withZodSchema';
import { FieldProvider, MultiComboBoxFieldProvider } from '../../Form/FieldProvider';
import { SSIDsQuery, UpdateSSIDMutation } from '../SSIDs/SSIDsUtils';
import {
  AddEditNaiRealmInputSchema,
  parseHs20RecordToNAIRealmFormVals,
  parseNAIRealmFormValsToHs20Record,
  useHs20ValidTypes,
  useSsids,
} from './utils';

export function Hotspot20NaiRealmActions({ ssid }: { ssid: SSIDsQueryResult }) {
  const network = useNetwork();
  const updateSSIDMutation = useGraphQLMutation(UpdateSSIDMutation);
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const { state } = useDialogState();
  const handleDelete = useCallback(() => {
    const variables: UpdateSsidMutationMutationVariables = {
      uuid: ssid.UUID,
      input: {
        hs20NaiRealms: null,
      },
    };
    updateSSIDMutation.mutate(variables, {
      onSuccess() {
        closeDrawer();
        queryClient.invalidateQueries(makeQueryKey(SSIDsQuery, { networkUUID: network.UUID }));
        notify('Successfully deleted NAI Realm.', {
          variant: 'positive',
        });
      },
      onError(err) {
        notify(`There was a problem deleting the NAI realm${getGraphQLErrorMessageOrEmpty(err)}.`, {
          variant: 'negative',
        });
      },
    });
  }, [ssid, updateSSIDMutation, queryClient, network, closeDrawer]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuItem onClick={state.open} icon="trash-can" internal>
            Delete
          </DropdownMenuItem>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Dialog state={state} preset="narrow">
        <DialogHeader heading="Delete NAI realm" icon="trash-can" />
        <DialogContent gutter="all">
          <VStack spacing={space(12)}>
            <Body>Are you sure you want to delete this NAI Realm?</Body>
            <Body>This cannot be undone.</Body>
          </VStack>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button type="button" onClick={state.close} variant="secondary">
                Cancel
              </Button>
              <Button type="button" onClick={handleDelete} variant="destructive">
                Delete
              </Button>
            </>
          }
        />
      </Dialog>
    </>
  );
}

export function EapMethodFields({
  fieldArrayHelpers,
}: {
  fieldArrayHelpers: FieldArrayRenderProps;
}) {
  const { values } = useFormikContext<AddEditNaiRealmInput>();
  const { eapMethods, authParams } = useHs20ValidTypes();
  const hasHs20EapMethods = !!values?.hs20EapMethods.length;
  return (
    <VStack spacing={space(12)}>
      {hasHs20EapMethods &&
        values.hs20EapMethods.map((eapMethod, index: number) => (
          <FieldContainer key={eapMethod.id}>
            <PrimaryFieldComposite
              label="EAP method"
              controls={
                <Button
                  icon="cross"
                  variant="secondary"
                  size="small"
                  arrangement="hidden-label"
                  onClick={() => fieldArrayHelpers.remove(index)}
                >
                  Delete EAP method
                </Button>
              }
              fields={
                <>
                  <FieldProvider name={`hs20EapMethods[${index}].hs20EapMethodId`}>
                    <CompositeField
                      element={
                        <ComboBox maxWidth="100%" placeholder="Select type">
                          {eapMethods.map((method) => (
                            <ComboBoxItem
                              key={method.method}
                              textValue={`${method.method} ${method.description}`}
                            >
                              {method.method} {method.description}
                            </ComboBoxItem>
                          ))}
                        </ComboBox>
                      }
                      label="Type"
                    />
                  </FieldProvider>
                  <MultiComboBoxFieldProvider name={`hs20EapMethods[${index}].hs20AuthParamTuples`}>
                    <CompositeField
                      element={
                        <MultiComboBox placeholder="Select authentication method">
                          {authParams.map((param) => (
                            <MultiComboBoxSection key={param.param} title={param.description}>
                              {param.validValues.map((value) => (
                                <MultiComboBoxItem
                                  key={`[${param.param}:${value.value}]`}
                                  textValue={value.description}
                                >
                                  {value.description}
                                </MultiComboBoxItem>
                              ))}
                            </MultiComboBoxSection>
                          ))}
                        </MultiComboBox>
                      }
                      label="Authentication methods"
                    />
                  </MultiComboBoxFieldProvider>
                </>
              }
            />
          </FieldContainer>
        ))}
    </VStack>
  );
}

export default function Hotspot20NAIRealmEdit({ uuid }: { uuid: string }) {
  const closeDrawer = useCloseDrawerCallback();
  const updateSSIDMutation = useGraphQLMutation(UpdateSSIDMutation);
  const queryClient = useQueryClient();
  const network = useNetwork();
  const ssids = useSsids();
  const ssid = uuid ? ssids.find((s) => s.UUID === uuid) : null;
  const initialValues = parseHs20RecordToNAIRealmFormVals(ssid?.hs20NaiRealms);
  return (
    <Drawer>
      <Formik<AddEditNaiRealmInput>
        initialValues={
          initialValues?.length ? initialValues[0] : { hs20EapMethods: [], hs20NaiRealmName: '' }
        }
        onSubmit={(vals) => {
          if (updateSSIDMutation.isLoading) return;
          const input = parseNAIRealmFormValsToHs20Record(vals);
          const variables: UpdateSsidMutationMutationVariables = {
            uuid,
            input,
          };
          updateSSIDMutation.mutate(variables, {
            onSuccess() {
              closeDrawer();
              queryClient.invalidateQueries(
                makeQueryKey(SSIDsQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully updated NAI Realms.', {
                variant: 'positive',
              });
            },
            onError(err) {
              notify(
                `There was a problem updating the NAI Realms${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          });
        }}
        validate={withZodSchema(AddEditNaiRealmInputSchema)}
      >
        <Form>
          <DrawerHeader
            icon="pencil"
            heading={
              <>
                Edit NAI Realm for{' '}
                <Badge arrangement="leading-icon" icon="ssid" size="small" variant="neutral">
                  {ssid ? ssid.ssid : 'SSID'}
                </Badge>{' '}
              </>
            }
            actions={ssid ? <Hotspot20NaiRealmActions ssid={ssid} /> : undefined}
            onClose={closeDrawer}
          />
          <DrawerContent>
            <VStack spacing={space(16)}>
              <FieldProvider name="hs20NaiRealmName">
                <PrimaryField label="Name" element={<TextInput />} />
              </FieldProvider>
              <FieldArray
                name="hs20EapMethods"
                render={(fieldArrayHelpers) => (
                  <VStack spacing={space(12)}>
                    <EapMethodFields fieldArrayHelpers={fieldArrayHelpers} />
                    <Button
                      onClick={() => {
                        fieldArrayHelpers.push({
                          id: uuidv4(),
                          hs20EapMethodId: null,
                          hs20AuthParamTuples: [],
                        });
                      }}
                      variant="secondary"
                      icon="plus"
                      arrangement="leading-icon"
                    >
                      Add EAP method
                    </Button>
                  </VStack>
                )}
              />
            </VStack>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

import type { IconName } from '@meterup/atto';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  EmptyState,
  HStack,
  ManufacturerIcon,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  Small,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Table,
  TableBody,
  TableCell,
  TableCellBuffer,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '@meterup/atto';
import {
  expectDefinedOrThrow,
  getManufacturerIconName,
  lookupMACAddressOUI,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { SwitchQueryQuery } from '../../../gql/graphql';
import type { PhyInterfaceQueryResult } from './utils';
import { paths } from '../../../constants';
import { PermissionType, VirtualDeviceType } from '../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../../utils/types';
import { ClientHardwareWidget } from '../../clients';
import { NoValue } from '../../NoValue';
import { ObjectHeader, StickyObjectHeader } from '../../Object/ObjectHeader';
import IsPermitted from '../../permissions/IsPermitted';
import { DrawerContentLoadingFallback } from '../../Placeholders/DrawerLoadingFallback';
import CableTest from './CableTest';
import SwitchPortActions from './SwitchPortActions';
import {
  isPortConnectedToMeterDevice,
  isPortSlow,
  MACTableQuery,
  portMaxSpeed,
  portPowerDraw,
  portSpeedToLabel,
  portSpeedVariant,
  PortsQuery,
  portStatus,
  SwitchQuery,
} from './utils';

interface PortConfigProps {
  phyInterfaceUUID: string;
  virtualDeviceUUID: string;
}

interface SwitchPortDrawerProps extends PortConfigProps {}

export function SingleDeviceDetails({
  deviceType,
  uuid,
  macAddress,
  clientName,
  serialNumber,
  // config 2 APs will have isOnline defined and we use its value to display online/offline.
  // config 1 APs will not have it defined. So assume online since it is sending lldp
  isOnline = true,
}: {
  deviceType?: VirtualDeviceType;
  uuid?: string;
  macAddress: string;
  clientName?: string;
  serialNumber?: string;
  isOnline?: boolean;
}) {
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const activeController = useActiveControllerForNetwork(network);
  const controllerName = activeController?.hardwareDevice?.serialNumber;
  const onlineStatus = isOnline ? 'online' : 'offline';

  return (
    <>
      <ObjectHeader
        collapsed
        icon={
          deviceType
            ? deviceTypeToHardwareIconPropHardware(deviceType)
            : getManufacturerIconName(lookupMACAddressOUI(macAddress) ?? '')
        }
        name={clientName}
        status={deviceType ? onlineStatus : undefined}
      />
      <ClientHardwareWidget gutter="vertical" client={{ macAddress, clientName }} />
      {!deviceType && (
        <>
          {isWOS2Enabled && (
            <Button
              as={Link}
              variant="secondary"
              width="100%"
              size="large"
              icon="chevron-right"
              arrangement="leading-label"
              to={makeDrawerLink(
                {
                  pathname: makeLink(paths.pages.ClientsList2Page, {
                    networkSlug: network.slug,
                    companyName,
                  }),
                  search: window.location.search,
                },
                paths.drawers.ClientSummary2Page,
                {
                  networkSlug: network.slug,
                  companyName,
                  macAddress,
                },
              )}
            >
              View client
            </Button>
          )}
          {!isWOS2Enabled && controllerName && (
            <Button
              as={Link}
              variant="secondary"
              width="100%"
              size="large"
              icon="chevron-right"
              arrangement="leading-label"
              to={makeDrawerLink(
                {
                  pathname: makeLink(paths.pages.ClientsListPage, {
                    controllerName,
                    companyName,
                  }),
                  search: window.location.search,
                },
                paths.drawers.ClientSummaryPage,
                {
                  controllerName,
                  companyName,
                  macAddress,
                },
              )}
            >
              View client
            </Button>
          )}
        </>
      )}
      {deviceType === VirtualDeviceType.AccessPoint && (
        <>
          {isWOS2Enabled && uuid && (
            <Button
              as={Link}
              variant="secondary"
              width="100%"
              size="large"
              icon="chevron-right"
              arrangement="leading-label"
              to={makeLink(paths.pages.AccessPointPage, {
                networkSlug: network.slug,
                companyName,
                uuid,
                tab: 'insights',
              })}
            >
              View access point
            </Button>
          )}
          {!isWOS2Enabled && serialNumber && controllerName && (
            <Button
              as={Link}
              variant="secondary"
              width="100%"
              size="large"
              icon="chevron-right"
              arrangement="leading-label"
              to={makeLink(paths.pages.LegacyAccessPointDetailPage, {
                controllerName,
                companyName,
                deviceName: serialNumber,
              })}
            >
              View access point
            </Button>
          )}
        </>
      )}
      {isSOSEnabled && deviceType === VirtualDeviceType.Switch && uuid && (
        <Button
          as={Link}
          variant="secondary"
          width="100%"
          size="large"
          icon="chevron-right"
          arrangement="leading-label"
          to={makeLink(paths.pages.SwitchDetailPage, {
            networkSlug: network.slug,
            companyName,
            uuid,
            tab: 'ports',
          })}
        >
          View switch
        </Button>
      )}
      {isCOS2Enabled && deviceType === VirtualDeviceType.Controller && uuid && (
        <Button
          as={Link}
          variant="secondary"
          width="100%"
          size="large"
          icon="chevron-right"
          arrangement="leading-label"
          to={makeLink(paths.pages.SecurityApplianceDetailPage, {
            networkSlug: network.slug,
            companyName,
            uuid,
            tab: 'insights',
          })}
        >
          View security appliance
        </Button>
      )}
    </>
  );
}

type PortSummaryProps = {
  port: PhyInterfaceQueryResult;
  virtualDevice: SwitchQueryQuery['virtualDevice'];
};

export function MACTableDeviceDetailsFallback({
  port,
}: {
  port: Pick<PhyInterfaceQueryResult, 'virtualDeviceUUID' | 'portNumber'>;
}) {
  const macTable = useGraphQL(MACTableQuery, { virtualDeviceUUID: port.virtualDeviceUUID }).data
    ?.switchMACTable;

  const portEntries = useMemo(() => {
    if (!macTable) return [];

    return macTable.filter((entry) => entry.port === port.portNumber);
  }, [port.portNumber, macTable]);

  if (portEntries.length === 1) {
    const portEntry = portEntries[0];

    return <SingleDeviceDetails macAddress={portEntry.macAddress} />;
  }

  return <EmptyState heading="No devices connected" />;
}

function PortSummary({ port, virtualDevice }: PortSummaryProps) {
  const icon: IconName = port.portNumber % 2 === 0 ? 'ethernet-down' : 'ethernet-up';
  const devices = port.connectedDevices ?? [];

  return (
    <DrawerContent gutter="none">
      <StickyObjectHeader
        icon={icon}
        name={port.label ?? `Port ${port.portNumber}`}
        status={portStatus(port)}
      />
      <Sections>
        <Section relation="stacked">
          <SectionHeader heading="Metadata" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <SummaryListRow>
                <SummaryListKey>Port #</SummaryListKey>
                <SummaryListValue>{port.portNumber}</SummaryListValue>
              </SummaryListRow>
              <SummaryListRow variant={portSpeedVariant(port)}>
                <SummaryListKey>Speed</SummaryListKey>
                <SummaryListValue>
                  {port.portSpeedMbps ? portSpeedToLabel(port.portSpeedMbps) : <NoValue />}
                </SummaryListValue>
              </SummaryListRow>
              {isPortConnectedToMeterDevice(port) && isPortSlow(port) && (
                <Alert
                  variant="negative"
                  type="inline"
                  icon="attention"
                  copy={`Port's configuration is set to ${portSpeedToLabel(
                    port.forcedPortSpeedMbps ?? portMaxSpeed(port),
                  )} ${port.forcedPortSpeedMbps ?? portMaxSpeed(port) === port.portSpeedMbps ? 'and' : 'but'} last throughput speed detected was ${
                    port.portSpeedMbps ? portSpeedToLabel(port.portSpeedMbps) : 'unknown'
                  }`}
                />
              )}
              <SummaryListRow>
                <SummaryListKey>PoE</SummaryListKey>
                <SummaryListValue>
                  {port.isPOEEnabled ? `${portPowerDraw(port).toFixed(2)} W` : 'Disabled'}
                </SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
        </Section>
        {port.isSFP && port.sfpModuleInfo && (
          <Section relation="stacked">
            <SectionHeader heading="SFP module" />
            <SectionContent gutter="all">
              <SummaryList gutter="none">
                <SummaryListRow>
                  <SummaryListKey>Vendor</SummaryListKey>
                  <SummaryListValue>{port.sfpModuleInfo.vendor}</SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Description</SummaryListKey>
                  <SummaryListValue>
                    {port.sfpModuleInfo.partName} {port.sfpModuleInfo.moduleType}{' '}
                    {port.sfpModuleInfo.moduleSpeed}
                  </SummaryListValue>
                </SummaryListRow>
                <SummaryListRow>
                  <SummaryListKey>Serial</SummaryListKey>
                  <SummaryListValue>{port.sfpModuleInfo.serialNumber}</SummaryListValue>
                </SummaryListRow>
              </SummaryList>
            </SectionContent>
          </Section>
        )}
        <Section relation="stacked">
          <SectionHeader heading="Connection" />
          <SectionContent gutter="all">
            {devices.length > 0 ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {devices.length === 1 ? (
                  <SingleDeviceDetails
                    deviceType={devices[0].hardwareDevice?.virtualDevice?.deviceType}
                    uuid={devices[0].hardwareDevice?.virtualDevice?.UUID}
                    clientName={devices[0].hardwareDevice?.virtualDevice?.label}
                    macAddress={devices[0].macAddress}
                    serialNumber={devices[0].hardwareDevice?.serialNumber}
                    isOnline={devices[0].hardwareDevice?.isConnectedToBackend}
                  />
                ) : (
                  <Table>
                    <TableHead>
                      <TableHeadRow>
                        <TableCellBuffer side="leading" head />
                        <TableHeadCell>Device</TableHeadCell>
                        <TableCellBuffer side="trailing" head />
                      </TableHeadRow>
                    </TableHead>
                    <TableBody>
                      {devices.map((device) => (
                        <TableRow>
                          <TableCellBuffer side="leading" />
                          <TableCell>
                            <HStack spacing={space(8)}>
                              <ManufacturerIcon size={16} icon="unknown" />
                              <Small family="monospace">{device.macAddress}</Small>
                            </HStack>
                          </TableCell>
                          <TableCellBuffer side="trailing" />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </>
            ) : (
              <MACTableDeviceDetailsFallback port={port} />
            )}
          </SectionContent>
        </Section>
        <IsPermitted
          isPermitted={({ permissions, nosFlags }) =>
            Boolean(
              permissions.hasPermission(PermissionType.PermHardwareDeviceRpcPortCycle) &&
                nosFlags[NosFeature.SOS],
            )
          }
        >
          {port.isEthernet && virtualDevice.hardwareDevice && (
            <CableTest
              serialNumber={virtualDevice.hardwareDevice.serialNumber}
              portNumber={port.portNumber}
            />
          )}
        </IsPermitted>
      </Sections>
    </DrawerContent>
  );
}

export default function SwitchPortDrawer(props: SwitchPortDrawerProps) {
  const { phyInterfaceUUID, virtualDeviceUUID } = props;

  const portData = useGraphQL(PortsQuery, { virtualDeviceUUID }).data
    ?.phyInterfacesForVirtualDevice;
  expectDefinedOrThrow(
    portData,
    new ResourceNotFoundError(`PhyInterfaces not found for device ${virtualDeviceUUID}`),
  );

  const port = portData.find((p) => p.UUID === phyInterfaceUUID);
  expectDefinedOrThrow(
    port,
    new ResourceNotFoundError(`No PhyInterface found for UUID ${phyInterfaceUUID}`),
  );

  const virtualDevice = useGraphQL(SwitchQuery, { uuid: virtualDeviceUUID })?.data?.virtualDevice;
  expectDefinedOrThrow(
    virtualDevice,
    new ResourceNotFoundError(`No VirtualDevice found for switch UUID ${virtualDeviceUUID}`),
  );

  return (
    <Drawer>
      <DrawerHeader
        icon="ethernet"
        heading={port.label ?? `Port ${port.portNumber}`}
        onClose={useCloseDrawerCallback()}
        actions={<SwitchPortActions view="drawer" port={port} virtualDevice={virtualDevice} />}
      />
      <Suspense fallback={<DrawerContentLoadingFallback />}>
        <PortSummary {...props} port={port} virtualDevice={virtualDevice} />
      </Suspense>
    </Drawer>
  );
}

import type { metrics } from '@meterup/proto';
import { getMany, getOne, isDefinedAndNotEmpty, mutateVoid } from '@meterup/common';
import { api } from '@meterup/proto';
import { orderBy } from 'lodash-es';

import type { MeterLDFlags } from '../feature_flags';
import type { AccessPointDeviceData, NetworkPlansData, SwitchData } from './types';
import { axiosInstanceJSON } from './axiosInstanceJSON';
import { demoControllerDevices } from './data/demo_dashboard/devices';
import { demoEvents } from './data/demo_dashboard/events';
import { demoISPs } from './data/demo_dashboard/isps';

class APIv2 {
  controller: api.ControllerResponse;

  company_slug: string;

  demo: boolean;

  flags?: MeterLDFlags;

  constructor(controller_data: api.ControllerResponse, flags?: MeterLDFlags) {
    this.controller = controller_data;
    this.company_slug = controller_data.company_slug;
    this.demo = controller_data.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_DEMO;
    this.flags = flags;
  }

  /*
    GET /v1/controllers/${controllerName}/devices
   */
  public async devicesForController(): Promise<api.ControllerDeviceResponse | null> {
    return getOne(async () => {
      const results = this.demo
        ? await demoControllerDevices(this.company_slug, this.controller)
        : (
            await axiosInstanceJSON.get<api.ControllerDeviceResponse>(
              `/v1/controllers/${this.controller.name}/devices`,
            )
          ).data;

      return results;
    });
  }

  /*
    GET /v1/controllers/${controllerName}/network-interfaces
    Fetches list of network interfaces
   */
  // eslint-disable-next-line class-methods-use-this
  public async getNetworkInterfaces(controllerName: string) {
    return getMany(async () => {
      const response = await axiosInstanceJSON.get<api.NetworkInterfaceListResponse>(
        `/v1/controllers/${controllerName}/network-interfaces`,
      );
      return response.data.interfaces;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async fetchLatestUptimeMetric(name: string) {
    const result = await axiosInstanceJSON.get<metrics.GetMetrics2Response>('/v1/metrics2', {
      params: {
        series_id: 'latest_uptime',
        object_id: name,
        end_time: new Date().toISOString(), // not used
        duration_ms: 60,
      },
    });

    return result?.data?.data[0]?.values[0]?.value || 0;
  }

  /*
    Fetches an access point by name. Loads all from API with `devicesForController`.
   */
  public async accessPoint(
    accessPointName: string | null | undefined,
  ): Promise<AccessPointDeviceData | null> {
    return getOne(async () => {
      const devices = await this.devicesForController();
      if (!devices) {
        return null;
      }
      return (
        devices.access_points.find(
          (device) =>
            device.name === accessPointName ||
            device.serial_number.toLowerCase() === accessPointName?.toLowerCase(),
        ) ?? null
      );
    });
  }

  /*
    Fetches a switch by MAC address. Loads all from API with `devicesForController`.
   */
  public async switch(macAddress: string): Promise<SwitchData | null> {
    return getOne(async () => {
      const devices = await this.devicesForController();
      if (!devices) {
        return null;
      }
      return devices.switches.find((device) => device.mac === macAddress) ?? null;
    });
  }

  /*
      GET /v1/dashboard/controllers${controllerName}/internet-service-plans
   */
  public async isps(filterUninstalled = false) {
    return getMany(async () => {
      const result = this.demo
        ? await demoISPs(this.controller.name)
        : (
            await axiosInstanceJSON.get<NetworkPlansData>(
              `/v1/dashboard/controllers/${this.controller.name}/internet-service-plans`,
            )
          ).data;

      if (filterUninstalled) {
        return result.plans.filter((plan) => isDefinedAndNotEmpty(plan.network_interface));
      }

      return result.plans;
    });
  }

  /*
      Fetches all ISPS via the `isps` method and finds the one with a matching `sid`.
   */
  public async isp(sid: string) {
    return getOne(async () => {
      const connections = await this.isps();
      return connections.find((d) => d.sid === sid) ?? null;
    });
  }

  /*
    GET /v1/controllers/${controllerName}/events
   */
  public async controllerEvents() {
    return getMany(async () => {
      const currentDate = new Date();
      const fourWeeksAgo = currentDate.setDate(currentDate.getDate() - 28);
      const fourWeeksAgoUnix = Math.round(new Date(fourWeeksAgo).getTime() / 1000).toString();
      const result = this.demo
        ? await demoEvents(this.controller.name)
        : (
            await axiosInstanceJSON.get<api.AdhocControllerEventListResponse>(
              `/v1/controllers/${this.controller.name}/events?minimum-created-at=${fourWeeksAgoUnix}`,
            )
          ).data;
      return orderBy(result.events, (e) => e.created_at, 'desc');
    });
  }

  /*
    Fetches all Events via the `controllerEvents` methods and finds the one with a matching `sid`.
   */
  public async controllerEvent(sid: string) {
    return getOne(async () => {
      const events = await this.controllerEvents();
      return events.find((d) => d.sid === sid) ?? null;
    });
  }

  /*
      POST /v1/companies/${company}/mac-address-aliases

      This will store any aliases for demo controllers in localStorage, which we load later in i.e.
      `demoClients`.
   */
  public async upsertMACAddressAlias(mac: string, alias: string) {
    if (this.demo) {
      const aliases = JSON.parse(localStorage.getItem('mac_address_aliases') || '{}');
      aliases[mac] = alias;
      localStorage.setItem('mac_address_aliases', JSON.stringify(aliases));
    } else {
      mutateVoid(async () =>
        axiosInstanceJSON.post(`/v1/companies/${this.company_slug}/mac-address-aliases`, {
          mac,
          alias,
        }),
      );
    }
  }
}

export default APIv2;

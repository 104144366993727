import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  PaneHeader,
  SearchInput,
  useDialogState,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import { copyVLANsMutation, vlansQuery } from '../../../../../components/NetworkWide/VLANs/utils';
import VLANList, {
  VLANsCopyDescription,
} from '../../../../../components/NetworkWide/VLANs/VLANList';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink, makeLink } from '../../../../../utils/main_and_drawer_navigation';
import {
  useNavigateBack,
  useNavigateHome,
  useNetworkWideCrumbs,
} from '../../../../../utils/routing';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/network-wide/vlans',
  layout: 'VirtualNetworkLayout',
  title: 'VLANs - Network-wide',
});

function VLANsActionsMenu() {
  const network = useNetwork();
  const networkUUID = network.UUID;
  const copyVLANs = useGraphQLMutation(copyVLANsMutation);
  const queryClient = useQueryClient();
  const { state } = useDialogState();

  const { close } = state;

  const handleCopyVLANs = useCallback(
    () =>
      copyVLANs.mutate(
        { networkUUID },
        {
          onSuccess() {
            queryClient.invalidateQueries(makeQueryKey(vlansQuery, { networkUUID }));
            notify('VLANs copied successfully', { variant: 'positive' });
          },
          onError(err) {
            notify(
              `There was an error copying VLANs, please create them manually${getGraphQLErrorMessageOrEmpty(err)}.`,
              { variant: 'negative' },
            );
          },
          onSettled: close,
        },
      ),
    [copyVLANs, networkUUID, queryClient, close],
  );

  return (
    <>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
          internal
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={state.open} icon="copy" internal>
              Copy legacy VLANs
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Dialog state={state}>
        <DialogHeader icon="vlan" heading="Copy legacy VLANs" />
        <DialogContent gutter="all">
          <Body>
            <VLANsCopyDescription />
          </Body>
        </DialogContent>
        <DialogFooter
          actions={
            <>
              <Button variant="secondary" onClick={state.close}>
                Cancel
              </Button>
              <Button onClick={handleCopyVLANs}>Copy VLANs</Button>
            </>
          }
        />
      </Dialog>
    </>
  );
}

function VLANActions() {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const featureFlags = useFeatureFlags();
  const isOperator = useIsOperator();

  return (
    <>
      <IsPermitted
        isPermitted={({ isOperator: permittedOperator, permissions, ldFlags }) =>
          permissions.hasPermission(PermissionType.PermVlanWrite) &&
          (!!ldFlags['edit-vlan'] || permittedOperator)
        }
      >
        <Button
          as={ReactRouterLink}
          to={makeDrawerLink(window.location, paths.drawers.VLANsAddPage, {
            companyName,
            networkSlug: network.slug,
          })}
          variant="secondary"
          icon="plus"
          condense
          size="small"
          arrangement="leading-icon"
          internal={isOperator && !featureFlags['edit-vlan']}
        >
          Add VLAN
        </Button>
        <VLANsActionsMenu />
      </IsPermitted>
      <SearchInput
        placeholder="..."
        aria-label="Filter VLANs"
        scope="scoped"
        value={globalFilter}
        onChange={setGlobalFilter}
        minWidth="56px"
      />
    </>
  );
}

export default function VLANListPage() {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const networkWideCrumb = useNetworkWideCrumbs();

  return (
    <IsPermitted permissions={PermissionType.PermVlanRead} should404OnAccessDenied>
      <DetailPageLayout
        header={
          <PaneHeader
            back={back}
            home={home}
            crumbs={[
              ...networkWideCrumb,
              {
                type: 'page',
                page: {
                  as: ReactRouterLink,
                  to: makeLink(paths.pages.VLANListPage, {
                    companyName,
                    networkSlug: network.slug,
                  }),
                  selected: true,
                  label: 'VLANs',
                },
              },
            ]}
            icon="vlan"
            heading="VLANs"
            actions={<VLANActions />}
          />
        }
        main={<VLANList />}
      />
    </IsPermitted>
  );
}

import { Layout, LayoutDock, LayoutPage } from '@meterup/atto';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { FatalErrorFallback } from '../../components/ErrorFallback/ErrorFallback';
import { paths } from '../../constants';
import { NoHardwareError } from '../../errors';
import { useCurrentControllerOrNull } from '../../hooks/useCurrentControllerOrNull';
import { ProductTypes, useUserProductsAccess } from '../../hooks/useUserProductsAccess';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useCurrentOrDefaultNetwork } from '../../providers/hooks/useCurrentOrDefaultNetwork';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/org/:companyName',
});

function NoNetworksError() {
  const error = new NoHardwareError(
    'No hardware',
    'Your organization does not have any associated networks. Please contact support.',
  );

  return (
    <Layout>
      <LayoutDock />
      <LayoutPage>
        <FatalErrorFallback
          error={error}
          componentStack={null}
          eventId=""
          resetError={() => null}
        />
      </LayoutPage>
    </Layout>
  );
}

export default function CompanyRootPage() {
  const companyName = useCurrentCompany();
  const defaultNetwork = useCurrentOrDefaultNetwork();
  const controllerName = useCurrentControllerOrNull();
  const access = useUserProductsAccess();
  const [params] = useSearchParams();

  if (access.hasAccessToProduct(ProductTypes.NETWORK)) {
    if (defaultNetwork) {
      return (
        <Navigate
          to={{
            pathname: makeLink(paths.pages.IndividualNetworkRootPage, {
              companyName,
              networkSlug: defaultNetwork.slug,
            }),
            search: params.toString(),
          }}
          replace
        />
      );
    }

    if (isDefinedAndNotEmpty(controllerName)) {
      const pathname = makeLink(paths.pages.OverviewPage, {
        companyName,
        controllerName,
      });
      return <Navigate to={{ pathname, search: params.toString() }} replace />;
    }
  }

  if (access.hasAccessToProduct(ProductTypes.CONNECT)) {
    return (
      <Navigate
        to={makeLink(paths.pages.ConnectDashboardAllLocationsPage, {
          companyName,
        })}
        replace
      />
    );
  }

  return <NoNetworksError />;
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import React from 'react';

import AddUser from '../../../../../components/Users/AddUser';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/org/users/add',
  title: 'Add user - Users - Org',
});

export default function CreateUserPage() {
  return <AddUser />;
}

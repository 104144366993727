import {
  Column,
  Columns,
  PaneContent,
  Section,
  SectionContent,
  SectionHeader,
  useDialogState,
} from '@meterup/atto';
import { clientNameOrNull, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { useNetwork } from '../../hooks/useNetworkFromPath';
import { ClientHardwareWidget, WiredConnectionWidget } from '../clients';
import LANPingTestDialog from '../PingTest2/LANPingTestDialog';
import { vlansForPingQuery } from '../PingTest2/utils';
import { useClientByMAC } from './utils';

export default function WiredClientDetail({ macAddress }: { macAddress: string }) {
  const network = useNetwork();
  const client = useClientByMAC(macAddress);
  const pingTestDialogProps = useDialogState();
  const vlans = useGraphQL(vlansForPingQuery, {
    networkUUID: network.UUID,
  })?.data?.vlans;
  expectDefinedOrThrow(vlans, new ResourceNotFoundError('Unable to load vlans'));

  return (
    <PaneContent gutter="none">
      <Columns template="object">
        <Column>
          <Section relation="stacked">
            <SectionHeader heading="Metadata" />
            <SectionContent gutter="all">
              <ClientHardwareWidget client={client} />
            </SectionContent>
          </Section>
          <WiredConnectionWidget
            relation="stacked"
            client={client}
            openPingTestDialog={pingTestDialogProps.state.open}
          />
        </Column>
        <Column />
      </Columns>
      {client.connectedVLAN && (
        <LANPingTestDialog
          state={pingTestDialogProps.state}
          hostIp={client.ip}
          hostName={clientNameOrNull(client) ?? '-'}
          vlan={client.connectedVLAN}
        />
      )}
    </PaneContent>
  );
}

import { colors } from '@meterup/atto';
import { AnimatedGrid } from '@visx/xychart';
import { useContext } from 'react';

import { ThemeContext } from '../../../providers/ThemeProvider';

export default function ChartGrid() {
  const { dark } = useContext(ThemeContext);

  const strokeColor = !dark ? colors.strokeNeutralLight : colors.strokeNeutralDark;

  return (
    <AnimatedGrid
      rows={false}
      columns={false}
      lineStyle={{
        stroke: `${strokeColor}`,
        strokeDasharray: '4, 4',
        strokeLinecap: 'round',
        strokeWidth: 1,
      }}
    />
  );
}

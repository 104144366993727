import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import EditPortForwardingRuleDrawer from '../../../../../components/Firewall/PortForwarding/EditPortForwardingRuleDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/port-forwarding/create',
  title: 'Add rule - Port forwarding - Firewall',
});

export default function CreatePortForwardingRulePage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermPortForwardWrite) &&
            ldFlags['port-forwarding'],
        )
      }
      should404OnAccessDenied
    >
      <EditPortForwardingRuleDrawer />
    </IsPermitted>
  );
}

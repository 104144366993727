import {
  Badge,
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  HStack,
  Section,
  SectionContent,
  SectionHeader,
  SectionTarget,
  space,
  SummaryTable,
  SummaryTableArrow,
  SummaryTableCell,
  SummaryTableRow,
} from '@meterup/atto';
import { isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import type { CompanyNetworksQueryResult } from '../../../pages/operator/companies/utils';
import { StickyObjectHeader } from '../../../../components/Object/ObjectHeader';
import { ReactRouterLink } from '../../../../components/ReactRouterLink';
import { useCompanyUsers } from '../../../../components/Users/utils/queries';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../nav';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';
import { companyQuery } from '../../../pages/operator/companies/utils';

export const Meta = () => ({
  path: '/operator/companies/:slug',
  title: 'Company - Companies - Operator',
});

function NetworksSection({
  slug,
  networks,
  type,
}: {
  slug: string;
  networks: CompanyNetworksQueryResult[];
  type: 'active' | 'inactive';
}) {
  return (
    <Section relation="stacked">
      <SectionContent gutter="horizontal">
        <Section relation="standalone">
          <SectionHeader
            icon="location"
            heading={
              <HStack align="center" spacing={space(6)}>
                {type === 'active' ? 'Active' : 'Inactive'} networks
                <Badge variant="neutral" size="small">
                  {networks.length}
                </Badge>
              </HStack>
            }
          />
          <SectionContent>
            <SummaryTable>
              {networks.slice(0, 10).map((network) => (
                <SummaryTableRow
                  key={network.UUID}
                  as="a"
                  target="_blank"
                  href={makeLink(paths.pages.IndividualNetworkRootPage, {
                    companyName: slug,
                    networkSlug: network.slug,
                  })}
                >
                  <SummaryTableCell>
                    {network.label ? network.label : network.UUID}
                  </SummaryTableCell>
                  <SummaryTableArrow />
                </SummaryTableRow>
              ))}
            </SummaryTable>
            <SectionTarget
              tabIndex={0}
              as={Link}
              to={makeLink(paths.pages.CompanyNetworkListPage, {
                slug,
              })}
            >
              View all networks
            </SectionTarget>
          </SectionContent>
        </Section>
      </SectionContent>
    </Section>
  );
}

export default function CompanySummaryPage() {
  const { slug } = Nav.useRegionParams('drawer', paths.drawers.CompanySummaryPage)!;

  const company = useGraphQL(companyQuery, { slug }).data?.getCompany;
  if (!isDefinedAndNotEmpty(company)) throw new ResourceNotFoundError('Company not found');

  const activeNetworks = company.networks.filter((network) => network.isActive);
  const inactiveNetworks = company.networks.filter((network) => !network.isActive);
  const companyUsers = useCompanyUsers(slug);

  return (
    <Drawer>
      <DrawerHeader icon="company" heading="Company" onClose={useCloseDrawerCallback()} />
      <DrawerContent gutter="bottom">
        <StickyObjectHeader
          icon="company"
          name={company.name}
          link={makeLink(paths.pages.HubCardsPage, { companyName: slug })}
          target="_blank"
          cta="Open hub"
        />
        <Section relation="stacked">
          <SectionContent gutter="horizontal">
            <Button
              as={ReactRouterLink}
              to={makeLink(paths.pages.CompanyUsersPage, { slug })}
              variant="secondary"
              icon="user"
              arrangement="leading-icon"
              size="large"
            >
              <HStack spacing={space(6)}>
                View users
                {companyUsers.data?.companyUsers && (
                  <Badge variant="neutral" size="small">
                    {companyUsers.data.companyUsers.length}
                  </Badge>
                )}
              </HStack>
            </Button>
          </SectionContent>
        </Section>
        {activeNetworks.length > 0 && (
          <NetworksSection slug={slug} networks={activeNetworks} type="active" />
        )}
        {inactiveNetworks.length > 0 && (
          <NetworksSection slug={slug} networks={inactiveNetworks} type="inactive" />
        )}
      </DrawerContent>
    </Drawer>
  );
}

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import Hotspot20Edit from '../../../../../components/Wireless/Hotspot20s/Hotspot20Edit';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/wireless/hotspot2.0/add',
  title: 'Add hotspot 2.0 - Hotspot 2.0s - Wireless',
});

export default function Hotspot20AddPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, pathParams }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermCaptivePortalWrite) &&
            ldFlags['captive-portal'] &&
            !!pathParams?.uuid,
        )
      }
      path={Meta().path}
    >
      <Hotspot20Edit />
    </IsPermitted>
  );
}

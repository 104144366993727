import type { HardwareIconPropHardware, PortPropSpeed } from '@meterup/atto';
import type { QueryResultField } from '@meterup/graphql';
import { Badge, Body, HardwareIcon, HStack, ManufacturerIcon, space, styled } from '@meterup/atto';
import { getManufacturerIconName, gigabytes, lookupMACAddressOUI } from '@meterup/common';
import { useCallback } from 'react';
import { type To, useNavigate } from 'react-router';
import { bytes, Measure } from 'safe-units';
import { z } from 'zod';

import type {
  MacTableQueryQuery,
  PortsQueryQuery,
  SwitchesQueryQuery,
  SwitchesRootQueryQuery,
  SwitchPortStatsQueryQuery,
  SwitchQueryQuery,
  VlaNsQueryQuery,
} from '../../../gql/graphql';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { TrafficDirection, VirtualDeviceType } from '../../../gql/graphql';
import { UpdatePhyInterfaceInputSchema as BaseSchema } from '../../../gql/zod-types';
import { useActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../../utils/types';
import { NoValue } from '../../NoValue';
import { ValidAPDrawerTabs } from '../../Wireless/utils';

export type SwitchesRootQueryResult = QueryResultField<
  SwitchesRootQueryQuery,
  'virtualDevicesForNetwork'
>;

export type PhyInterfaceQueryResult = QueryResultField<
  PortsQueryQuery,
  'phyInterfacesForVirtualDevice'
>;
export type SwitchesQueryResult = QueryResultField<SwitchesQueryQuery, 'virtualDevicesForNetwork'>;
export type VLANQueryResult = QueryResultField<VlaNsQueryQuery, 'vlans'>;
export type MACTableQueryResult = QueryResultField<MacTableQueryQuery, 'switchMACTable'>;

export const UpdatePhyInterfaceInputSchema = BaseSchema.extend({
  frameAcceptTypeFilter: z.enum(['ALL', 'UNTAGGED_ONLY', 'TAGGED_ONLY']).nullish(),
});

export type ValidPhyInterfaceParams = z.infer<typeof UpdatePhyInterfaceInputSchema>;

export const ValidPortSpeeds = [10, 100, 1000, 2500, 10000] as const;
export type ValidPortSpeed = (typeof ValidPortSpeeds)[number];

export const ValidBridgePriorities = [
  0, 4096, 8192, 12288, 16384, 20480, 24576, 28672, 32768,
] as const;
export type ValidBridgePriority = (typeof ValidBridgePriorities)[number];

export const fallbackSwitchHardwareDeviceQuery = graphql(`
  query FallbackSwitchHardwareQuery($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      virtualDeviceUUID
    }
  }
`);

export const SwitchQuery = graphql(`
  query SwitchQuery($uuid: UUID!) {
    virtualDevice(UUID: $uuid) {
      __typename
      UUID
      label
      description
      networkUUID
      isConsoleEnabled
      deviceType
      deviceModel
      nosVersion {
        id
        buildName
        version
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }

      ... on SwitchVirtualDevice {
        switchProfile {
          UUID
          stpBridgePriority
        }

        phyInterfaces {
          UUID
          isEthernet
          isSFP
          isUplink
          portSpeedMbps
          maxSpeedMbps
        }
      }

      hardwareDevice {
        __typename
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        macAddress
        tunnelIPAddress
        deviceModel
        serialNumber
        deviceType
        bootHistory(count: 1) {
          buildName
        }

        ... on SwitchHardwareDevice {
          ipAddress
        }
      }
    }
  }
`);

export const SwitchesQuery = graphql(`
  query SwitchesQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {
      __typename
      UUID
      uptime
      label
      description
      deviceType
      deviceModel
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }

      ... on SwitchVirtualDevice {
        switchProfile {
          stpBridgePriority
        }

        phyInterfaces {
          UUID
          isEthernet
          isSFP
          isUplink
          portSpeedMbps
          maxSpeedMbps
        }
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }
        isDev @include(if: $includeIsDev)

        ... on SwitchHardwareDevice {
          stpInfo {
            isRootBridge
            rootBridgePortNumber
          }
          ipAddress
          isInCurrentControllerMACTable
          uptime
        }
      }
    }
  }
`);

export const PortsQuery = graphql(`
  query PortsQuery($virtualDeviceUUID: UUID!) {
    phyInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {
      UUID
      virtualDeviceUUID
      description
      forcedPortSpeedMbps
      isBoundToAllVLANs
      isEnabled
      isPOEEnabled
      isSTPEdgePortEnabled
      isSTPEnabled
      isTrunkPort
      isUplink
      isLoopbackDetected
      isBlockedDueToFlaps
      isSTPForwarding
      isIngressFilteringEnabled
      frameAcceptTypeFilter
      label
      portNumber
      isEthernet
      isSFP
      powerDraw
      portSpeedMbps
      maxSpeedMbps
      stpPortRole
      isStormControlEnabled
      stormControlBroadcastTrafficPercent
      stormControlUnknownMulticastTrafficPercent
      stormControlUnknownUnicastTrafficPercent
      allowedVLANs {
        UUID
        name
        vlanID
      }
      nativeVLAN {
        UUID
        name
        vlanID
      }
      throughputLastDay {
        direction
        value
      }
      connectedDevices {
        macAddress
        hardwareDevice {
          serialNumber
          isConnectedToBackend
          isActive
          deviceType
          virtualDevice {
            __typename
            deviceType
            UUID
            label
          }
        }
      }
      sfpModuleInfo {
        vendor
        serialNumber
        partName
        moduleSpeed
        moduleType
      }
    }
  }
`);

export const MACTableQuery = graphql(`
  query MACTableQuery($virtualDeviceUUID: UUID!) {
    switchMACTable(virtualDeviceUUID: $virtualDeviceUUID) {
      updatedAt
      macAddress
      port
      vlan {
        UUID
        name
        vlanID
      }
    }
  }
`);

export const UpdateSwitchProfileMutation = graphql(`
  mutation UpdateSwitchProfile($uuid: UUID!, $input: UpdateSwitchProfileInput!) {
    updateSwitchProfile(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const FindMACOnSwitchQuery = graphql(`
  query FindMACOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {
    findSwitchesForClientMAC(networkUUID: $networkUUID, macAddress: $mac) {
      virtualDevice {
        UUID
        label
        hardwareDevice {
          isConnectedToBackend
        }
      }
      phyInterface {
        UUID
        portNumber
      }
    }
  }
`);

export const FindLLDPEntryOnSwitchQuery = graphql(`
  query FindLLDPEntryOnSwitchQuery($networkUUID: UUID!, $mac: MacAddress!) {
    findSwitchLLDPEntryForMAC(networkUUID: $networkUUID, macAddress: $mac) {
      virtualDevice {
        UUID
        label
        hardwareDevice {
          isConnectedToBackend
        }
      }
      phyInterface {
        UUID
        portNumber
      }
    }
  }
`);

export const PortCycleMutation = graphql(`
  mutation PortCycleMutation(
    $portNumber: Int!
    $serialNumber: String!
    $poeCycle: Boolean!
    $portCycle: Boolean!
  ) {
    rpcSwitchPortCycle(
      input: {
        portNumber: $portNumber
        serialNumber: $serialNumber
        poeCycle: $poeCycle
        portCycle: $portCycle
      }
    )
  }
`);

export const DeadMansSnitchQuery = graphql(`
  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {
    deviceHeartbeatForDevice(serialNumber: $serialNumber) {
      token
    }
  }
`);

export function portPowerDraw(port: PhyInterfaceQueryResult) {
  if (!port.isPOEEnabled) return 0;
  if (port.powerDraw && port.powerDraw >= 0.005) return port.powerDraw;
  return 0;
}

export function portMaxSpeed(port: Pick<PhyInterfaceQueryResult, 'isSFP' | 'maxSpeedMbps'>) {
  if (port.maxSpeedMbps) return port.maxSpeedMbps;
  return port.isSFP ? 10000 : 1000;
}

export function isPortSlow(
  port: Pick<PhyInterfaceQueryResult, 'isSFP' | 'portSpeedMbps' | 'maxSpeedMbps'>,
): boolean {
  const max = portMaxSpeed(port);
  if (port.portSpeedMbps === null || port.portSpeedMbps === undefined) {
    return false;
  }
  return port.portSpeedMbps < max;
}

export function portStatus(port: PhyInterfaceQueryResult): 'connected' | 'disconnected' | 'error' {
  if (port.isLoopbackDetected || port.isBlockedDueToFlaps) return 'error';
  return (port.portSpeedMbps ?? 0) > 0 ? 'connected' : 'disconnected';
}

export function isPortConnectedToMeterDevice(
  port: Pick<PhyInterfaceQueryResult, 'connectedDevices'>,
): boolean {
  return !!port.connectedDevices?.[0]?.hardwareDevice;
}

export function portSpeedVariant(
  port: Pick<
    PhyInterfaceQueryResult,
    'isSFP' | 'portSpeedMbps' | 'maxSpeedMbps' | 'connectedDevices'
  >,
): 'negative' | 'attention' | undefined {
  if (isPortSlow(port)) {
    return isPortConnectedToMeterDevice(port) ? 'negative' : 'attention';
  }
  return undefined;
}

export function isPortBlocking(port: PhyInterfaceQueryResult) {
  if (port.isLoopbackDetected || port.isBlockedDueToFlaps) return true;
  return (port.portSpeedMbps ?? 0) > 0 && !port.isSTPForwarding;
}

export function portSpeedForAtto(
  port: Pick<PhyInterfaceQueryResult, 'portSpeedMbps'>,
): PortPropSpeed | undefined {
  switch (port.portSpeedMbps) {
    case 10:
    case 100:
    case 1000:
    case 2500:
    case 10000:
      return port.portSpeedMbps;
  }
  return undefined;
}

export function portSpeedToLabel(speedMbps: number) {
  if (speedMbps >= 1000) {
    const speedGbps = speedMbps / 1000;
    if (Number.isInteger(speedGbps)) {
      return `${speedGbps} Gbps`;
    }

    const fixedGbps = speedGbps.toFixed(2);
    const trimmedGbps = fixedGbps.replace(/0+$/, '').replace(/\.$/, '');
    return `${trimmedGbps} Gbps`;
  }

  return `${speedMbps} Mbps`;
}

export function portThroughput(port: PhyInterfaceQueryResult, direction: 'RX' | 'TX') {
  if (!port.throughputLastDay) return 0;

  const val = port.throughputLastDay.find((tld) => tld.direction === direction);
  if (!val) return 0;

  const gb = Measure.of(val.value, bytes).over(gigabytes).value;

  // Purely for display, we don't want `0.00` to show up in the frontend.
  if (gb < 0.005) return 0;

  return gb;
}

export function portConnection(
  port: PhyInterfaceQueryResult,
  truncateLabel = true,
  handleOnClick?: (port: PhyInterfaceQueryResult) => any,
  checkActive?: (port: PhyInterfaceQueryResult) => boolean,
): {
  hardware: HardwareIconPropHardware;
  label: string;
  onClick: (event: void) => any;
  active: boolean;
} | null {
  const connectedDevices = port.connectedDevices ?? [];
  if (connectedDevices.length !== 1) return null;

  const { hardwareDevice } = connectedDevices[0];
  if (!hardwareDevice) return null;

  // This is a bit hacky, but we're basically trying to clean up the label a little. If the device
  // has a label, but it is one of our generated labels (i.e. `meter-mw08-c0f1ba` or
  // `mc01710012510701`), we will try to parse it out to show something more meaningful. In this
  // case, it would be `0f1ba`. This is so our ports don't all who a connection of `meter...` as the
  // label gets truncated. If we have a user specified label, we will just let it through.

  const label = hardwareDevice.virtualDevice?.label ?? '';
  const meterDeviceRegex = /^(meter-m[a-z]\d{2}-|m(s|c|w))\S+$/;
  const isMeterLabel = meterDeviceRegex.test(label);

  return {
    hardware: deviceTypeToHardwareIconPropHardware(hardwareDevice.deviceType),
    label: isMeterLabel && truncateLabel ? label.slice(-4) : label,
    onClick: () => {
      if (handleOnClick) handleOnClick(port);
    },
    active: checkActive ? checkActive(port) : false,
  };
}

const PortNamePlaceholder = styled(Body, {
  opacity: 0.65,
});

export function PortName({ port }: { port: PhyInterfaceQueryResult }) {
  if (port.label) {
    return <span style={{ color: 'currentColor' }}>{port.label}</span>;
  }

  return (
    <PortNamePlaceholder
      style={{ color: 'currentColor' }}
    >{`Port ${port.portNumber}`}</PortNamePlaceholder>
  );
}

export function SwitchSTPInfo({ virtualDevice }: { virtualDevice: SwitchesQueryResult }) {
  if (
    virtualDevice.hardwareDevice &&
    virtualDevice.hardwareDevice.__typename !== 'SwitchHardwareDevice'
  )
    return null;
  if (virtualDevice.__typename !== 'SwitchVirtualDevice') return null;

  const isRootBridge = virtualDevice.hardwareDevice?.stpInfo?.isRootBridge ?? false;

  return (
    <HStack spacing={space(6)} align="center">
      {isRootBridge && (
        <Badge variant="neutral" size="small">
          Root
        </Badge>
      )}
      {virtualDevice.switchProfile.stpBridgePriority}
    </HStack>
  );
}

type MACTable = MacTableQueryQuery['switchMACTable'];

/**
 * Sorting value for Connected column. Logic should match `PortDevice` below.
 */
export function portConnectedValue({
  port,
  macTable,
}: {
  port: PhyInterfaceQueryResult;
  macTable?: MACTable;
}): string | undefined {
  const meterConnection = portConnection(port, false);

  if (meterConnection) {
    return `${meterConnection.hardware} ${meterConnection.label}`;
  }

  const devices = port.connectedDevices ?? [];

  if (devices.length === 0) {
    const portEntries = macTable?.filter((entry) => entry.port === port.portNumber) ?? [];

    if (portEntries.length === 1) {
      const portEntry = portEntries[0];
      return portEntry.macAddress;
    }

    return undefined;
  }

  if (devices.length === 1) {
    return devices[0].macAddress;
  }

  return devices.length.toString();
}

/**
 * Display node for Connected column. Logic should match `portConnectedValue` above.
 */
export function PortDevice({
  port,
  macTable,
}: {
  port: PhyInterfaceQueryResult;
  macTable?: MACTable;
}) {
  const meterConnection = portConnection(port, false);

  if (meterConnection) {
    return (
      <HStack spacing={space(8)} align="center">
        <HardwareIcon hardware={meterConnection.hardware} variant="simple" />
        {meterConnection.label}
      </HStack>
    );
  }

  const devices = port.connectedDevices ?? [];

  if (devices.length === 0) {
    const portEntries = macTable?.filter((entry) => entry.port === port.portNumber) ?? [];

    if (portEntries.length === 1) {
      const portEntry = portEntries[0];
      const manufacturerName = lookupMACAddressOUI(portEntry.macAddress);

      if (manufacturerName) {
        return (
          <HStack spacing={space(8)} align="center">
            <ManufacturerIcon icon={getManufacturerIconName(manufacturerName)} size={14} />
            {portEntry.macAddress}
          </HStack>
        );
      }

      return <span>{portEntry.macAddress}</span>;
    }

    return <NoValue />;
  }

  if (devices.length === 1) {
    const manufacturerName = lookupMACAddressOUI(devices[0].macAddress);

    if (manufacturerName) {
      return (
        <HStack spacing={space(8)} align="center">
          <ManufacturerIcon icon={getManufacturerIconName(manufacturerName)} size={14} />
          {devices[0].macAddress}
        </HStack>
      );
    }

    return <span>{devices[0].macAddress}</span>;
  }

  return <span>{devices.length} devices</span>;
}

/** Switch Insights Utils */

export function getRootBridgeUUID(switches: SwitchesRootQueryResult[]) {
  return (
    switches.find((s) => {
      if (s.hardwareDevice?.__typename !== 'SwitchHardwareDevice') return false;
      return s.hardwareDevice?.stpInfo?.isRootBridge === true;
    })?.UUID ?? null
  );
}

export const switchPortMetricsQuery = graphql(`
  query SwitchPortMetricsQuery($virtualDeviceUUID: UUID!, $filter: MetricsFilterInput!) {
    switchPortMetricsRate(virtualDeviceUUID: $virtualDeviceUUID, filter: $filter) {
      values {
        timestamp
        portNumber
        dropsPerSecond
        txErrPerSecond
        rxErrPerSecond
        totalRxBytesPerSecond
        totalTxBytesPerSecond
        multicastRxPacketsPerSecond
        multicastTxPacketsPerSecond
        broadcastRxPacketsPerSecond
        broadcastTxPacketsPerSecond
      }
    }
  }
`);

export const SwitchPortStatsQuery = graphql(`
  query switchPortStatsQuery($virtualDeviceUUID: UUID!) {
    switchPortStats(virtualDeviceUUID: $virtualDeviceUUID) {
      portNumber
      totalRxBytes
      totalTxBytes
    }
  }
`);

export function mergeStatsAndPhyInterfaces(
  stats: SwitchPortStatsQueryQuery | undefined,
  phyInterfaces: PortsQueryQuery | undefined,
  useStat: Boolean,
) {
  if (!useStat) return;
  phyInterfaces?.phyInterfacesForVirtualDevice.map((phyInterface) => {
    const portStats = stats?.switchPortStats.find((s) => s.portNumber === phyInterface.portNumber);
    const mergedPort = phyInterface;
    mergedPort.throughputLastDay = [
      {
        direction: TrafficDirection.Rx,
        value: portStats?.totalRxBytes || 0,
      },
      {
        direction: TrafficDirection.Tx,
        value: portStats?.totalTxBytes || 0,
      },
    ];
    return {
      mergedPort,
    };
  });
}

export type SwitchVirtualDevice = SwitchQueryQuery['virtualDevice'];

export function getConnectionTo(
  connectedDevice: NonNullable<PhyInterfaceQueryResult['connectedDevices']>[number],
  {
    networkSlug,
    companyName,
    controllerSerialNumber,
    isWOS2Enabled,
  }: {
    networkSlug: string;
    companyName: string;
    controllerSerialNumber: string | undefined;
    isWOS2Enabled: boolean;
  },
): To | undefined {
  const { hardwareDevice } = connectedDevice;

  if (hardwareDevice?.virtualDevice?.deviceType === VirtualDeviceType.Controller) {
    return makeDrawerLink(window.location, paths.drawers.SecurityApplianceSummaryPage, {
      companyName,
      networkSlug,
      uuid: hardwareDevice?.virtualDevice?.UUID,
    });
  }

  if (hardwareDevice?.virtualDevice?.deviceType === VirtualDeviceType.Switch) {
    return makeDrawerLink(window.location, paths.drawers.SwitchSummaryPage, {
      companyName,
      networkSlug,
      uuid: hardwareDevice?.virtualDevice?.UUID,
    });
  }

  if (
    controllerSerialNumber &&
    hardwareDevice?.virtualDevice?.deviceType === VirtualDeviceType.AccessPoint
  ) {
    if (isWOS2Enabled) {
      return makeDrawerLink(window.location, paths.drawers.AccessPointDrawerPage, {
        companyName,
        networkSlug,
        uuid: hardwareDevice?.virtualDevice.UUID,
        tab: ValidAPDrawerTabs.AccessPoint,
      });
    }
    return makeDrawerLink(window.location, paths.drawers.AccessPointSummary, {
      controllerName: controllerSerialNumber,
      companyName,
      deviceName: hardwareDevice?.serialNumber,
    });
  }

  return undefined;
}

export function useOnConnectionClick() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();
  const activeController = useActiveControllerForNetwork(network);
  const controllerSerialNumber = activeController?.hardwareDevice?.serialNumber;
  const isWOS2Enabled = useNosFeatureEnabled(NosFeature.WOS2);

  return useCallback(
    (port: Pick<PhyInterfaceQueryResult, 'connectedDevices'>) => {
      if (port.connectedDevices && port.connectedDevices.length > 0) {
        const to = getConnectionTo(port.connectedDevices[0], {
          networkSlug: network.slug,
          controllerSerialNumber,
          companyName,
          isWOS2Enabled,
        });
        if (to) return navigate(to);
      }

      return null;
    },
    [network.slug, companyName, controllerSerialNumber, navigate, isWOS2Enabled],
  );
}

export function getSwitchStatus(virtualDevice: SwitchQueryQuery['virtualDevice']) {
  if (!virtualDevice.hardwareDevice) return undefined;

  return virtualDevice.hardwareDevice.isConnectedToBackend ? 'online' : 'offline';
}

import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useDrawer } from './drawerContext';
import { DrawerProps } from './DrawerProps';

export function Drawer({ autoOpen = true, children }: DrawerProps) {
  const { openDrawer, setContent } = useDrawer();
  const location = useLocation();
  useEffect(() => {
    setContent(children, autoOpen);

    // return () => setContent(null, true);
  }, [children, setContent, autoOpen]);
  useEffect(
    // @ts-ignore
    () => {
      if (children) {
        openDrawer();
      }
    },
    [location, children, openDrawer],
  );
  return null;
}
Drawer.whyDidYouRender = true;

import {
  expectDefinedOrThrow,
  formatDataRateBits,
  kilobitsPerSecond,
  ResourceNotFoundError,
} from '@meterup/common';
import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import * as d3 from 'd3';
import { Suspense, useMemo } from 'react';

import { graphql } from '../../gql';
import { useActiveControllerForNetwork } from '../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import {
  ChartsFallbackContainer,
  clientMetricsToSeries,
  dbmValueFormatter,
  getDurationSeconds,
  getStep,
} from '../../utils/chart_utils';
import ChartFilter from '../ChartFilter';
import TimeSeriesChart from '../Charts/TimeSeriesChart';
import { StackedSkeletons } from '../Placeholders/AppLoadingFallback';

const ClientMetricsQuery = graphql(`
  query ClientMetrics($serialNumber: String!, $filter: MetricsFilterInput!) {
    clientMetrics(serialNumber: $serialNumber, filter: $filter) {
      metadata {
        signal {
          minValue
          maxValue
        }
        noise {
          minValue
          maxValue
        }
        tx {
          minValue
          maxValue
        }
        rx {
          minValue
          maxValue
        }
      }
      values {
        timestamp
        macAddress
        signal
        noise
        txRate
        rxRate
      }
    }
  }
`);
const dataRateValueFormatter = (value: number) => formatDataRateBits(value, kilobitsPerSecond);

function ClientMetricGraphs({
  serialNumber,
  duration,
  step,
  currentTimePeriod,
}: {
  serialNumber: string;
  duration: number;
  step: number;
  currentTimePeriod: string;
}) {
  const { data: metricsData } = useGraphQL(ClientMetricsQuery, {
    serialNumber,
    filter: {
      durationSeconds: duration,
      stepSeconds: step,
    },
  });
  expectDefinedOrThrow(metricsData, new ResourceNotFoundError('Unable to load metrics'));

  const series = useMemo(
    () => clientMetricsToSeries(metricsData?.clientMetrics?.values),
    [metricsData?.clientMetrics?.values],
  );

  return (
    <>
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.signal}
        seriesType="line"
        seriesLabel="client-signal"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Signal"
        tooltipBody="Measures the strength of the wireless connection received by the client from an access point."
        valueFormatter={dbmValueFormatter}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.noise}
        seriesType="line"
        seriesLabel="client-noise"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="Noise"
        tooltipBody="Measures the level of interference in a wireless environment."
        valueFormatter={dbmValueFormatter}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.rxRate}
        seriesType="line"
        seriesLabel="client-rx-rate"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="RX rate"
        tooltipBody="The rate of bits received by the client."
        valueFormatter={dataRateValueFormatter}
      />
      <TimeSeriesChart
        curve={d3.curveLinear}
        series={series.txRate}
        seriesType="line"
        seriesLabel="client-tx-rate"
        showSeriesGlyphs={false}
        timePeriod={currentTimePeriod}
        title="TX rate"
        tooltipBody="The rate of bits sent by the client."
        valueFormatter={dataRateValueFormatter}
      />
    </>
  );
}

function ClientInsights() {
  const network = useNetwork();
  const activeController = useActiveControllerForNetwork(network);
  expectDefinedOrThrow(
    activeController?.hardwareDevice?.serialNumber,
    new ResourceNotFoundError('Security appliance not found'),
  );
  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const queryClient = useQueryClient();
  const duration = useMemo(() => getDurationSeconds(currentTimePeriod), [currentTimePeriod]);
  const step = useMemo(() => getStep(currentTimePeriod), [currentTimePeriod]);

  const refetchMetrics = () => {
    queryClient.invalidateQueries(
      makeQueryKey(ClientMetricsQuery, {
        serialNumber: activeController!.hardwareDevice!.serialNumber,
        filter: {
          durationSeconds: duration,
          stepSeconds: step,
        },
      }),
    );
  };

  return (
    <>
      <ChartFilter
        currentTimePeriod={currentTimePeriod}
        setCurrentTimePeriod={setCurrentTimePeriod}
        refetchMetrics={refetchMetrics}
      />
      <Suspense
        fallback={
          <ChartsFallbackContainer>
            <StackedSkeletons />
          </ChartsFallbackContainer>
        }
      >
        <ClientMetricGraphs
          serialNumber={activeController.hardwareDevice.serialNumber}
          duration={duration}
          step={step}
          currentTimePeriod={currentTimePeriod}
        />
      </Suspense>
    </>
  );
}

export default ClientInsights;

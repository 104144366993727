import { ResourceNotFoundError } from '@meterup/common';
import React from 'react';

import type { PermissionArgs } from './useIsPermitted';
import { useIsPermitted } from './useIsPermitted';

type IsPermittedProps<ParamKey extends string> = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  should404OnAccessDenied?: boolean;
  /**
   * Fallback during rollout to not hide features from operators.
   * @default true
   */
  allowOperatorAlways?: boolean;
} & PermissionArgs<ParamKey>;

export default function IsPermitted<ParamKey extends string>({
  children,
  fallback,
  path,
  should404OnAccessDenied = false,
  allowOperatorAlways = true,
  ...rest
}: IsPermittedProps<ParamKey>) {
  const hasPermission = useIsPermitted({
    ...rest,
    path,
    allowOperatorAlways,
  });
  if (hasPermission) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  if (fallback) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{fallback}</>;
  }
  if (should404OnAccessDenied) {
    throw new ResourceNotFoundError('Page not found');
  }
  return undefined;
}

import type { OverlayTriggerState } from '@meterup/atto';
import { notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { deleteVirtualDeviceMutation } from '../../Devices/utils';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import useCurrentControllers from './useCurrentControllers';
import { ControllerQuery, ControllersForSecurityApplianceQuery } from './utils';
import { SecurityApplianceDetailsTab } from './utils2';

export default function SecurityApplianceDeleteDialog({
  state,
  uuid,
}: {
  state: OverlayTriggerState;
  uuid: string;
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const { virtualDevices } = useCurrentControllers();
  const navigate = useNavigate();
  const closeDrawer = useCloseDrawerCallback();

  const virtualDevice = useGraphQL(ControllerQuery, { uuid }).data?.virtualDevice;
  if (virtualDevice?.__typename !== 'ControllerVirtualDevice') {
    throw new ResourceNotFoundError('Security appliance not found.');
  }

  const queryClient = useQueryClient();
  const deleteVirtualDevice = useGraphQLMutation(deleteVirtualDeviceMutation);
  const handleDelete = useCallback(() => {
    if (deleteVirtualDevice.isLoading) return;

    deleteVirtualDevice.mutate(
      { uuid },
      {
        onSuccess: () => {
          notify('Successfully deleted security appliance.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(ControllersForSecurityApplianceQuery, { networkUUID: network.UUID }),
          );
          closeDrawer();
          const nextVirtualDevice = virtualDevices.find((vd) => vd.UUID !== uuid);
          if (nextVirtualDevice) {
            navigate(
              makeLink(paths.pages.SecurityApplianceDetailPage, {
                companyName,
                networkSlug: network.slug,
                uuid: nextVirtualDevice.UUID,
                tab: SecurityApplianceDetailsTab.Insights,
              }),
            );
          } else {
            navigate(
              makeLink(paths.pages.IndividualNetworkRootPage, {
                companyName,
                networkSlug: network.slug,
              }),
            );
          }
        },
        onError: (err) => {
          notify(
            `There was an error deleting this security appliance ${getGraphQLErrorMessageOrEmpty(
              err,
            )}. Please make sure it is removed from any rack elevations.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [
    deleteVirtualDevice,
    closeDrawer,
    companyName,
    network.UUID,
    network.slug,
    virtualDevices,
    navigate,
    queryClient,
    uuid,
  ]);

  return (
    <DeleteDialog
      state={state}
      label={virtualDevice?.label}
      handleDelete={handleDelete}
      alert={{
        heading: 'Deleting a security appliance can impact the network',
        copy: 'Any connected devices will no longer use this security appliance.',
      }}
    />
  );
}

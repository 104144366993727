import type { FormikHelpers } from 'formik';
import { Drawer, DrawerHeader } from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { WebhookUpdateSchema } from '../utils';
import { paths } from '../../../../../constants';
import { graphql } from '../../../../../gql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import {
  useCurrentCompany,
  useCurrentCompany2,
} from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { useInvalidateWebhookTargets, useWebhookTarget, webhookUpdateSchema } from '../utils';
import WebhookActions from './WebhookActions';
import WebhookForm from './WebhookForm';

const updateWebhookMutation = graphql(`
  mutation UpdateWebhookMutation($UUID: UUID!, $input: UpdateAlertTargetWebhookInput!) {
    updateAlertTargetWebhook(UUID: $UUID, input: $input) {
      UUID
    }
  }
`);

export default function WebhooksEdit({ webhookUUID }: { webhookUUID: string }) {
  const closeDrawer = useCloseDrawerCallback();
  const mutation = useGraphQLMutation(updateWebhookMutation);
  const companyName = useCurrentCompany();
  const company = useCurrentCompany2();
  const navigate = useNavigate();
  const onCancel = useCloseDrawerCallback();
  const invalidateWebhooks = useInvalidateWebhookTargets({ companyUUID: company?.uuid });

  const onSubmit = useCallback(
    (values: WebhookUpdateSchema, form: FormikHelpers<WebhookUpdateSchema>) => {
      const { input } = values;
      mutation.mutate(
        {
          UUID: webhookUUID,
          input: {
            label: input.label,
            url: input.url,
            signingSecret: input.signingSecret,
          },
        },
        {
          onSuccess() {
            notify('Successfully updated webook', {
              variant: 'positive',
            });
            form.resetForm();
            invalidateWebhooks();
            navigate(
              makeLink(paths.pages.WebhooksPage, {
                companyName,
              }),
            );
          },
          onError() {
            notify('Failed to  update webhook', {
              variant: 'negative',
            });
          },
        },
      );
    },
    [mutation, webhookUUID, invalidateWebhooks, navigate, companyName],
  );

  const webhook = useWebhookTarget({ uuid: webhookUUID, companyUUID: company?.uuid });
  return (
    <Drawer>
      <DrawerHeader
        icon="plus"
        heading="Edit webhook"
        onClose={closeDrawer}
        actions={<WebhookActions uuid={webhookUUID} />}
      />
      <Formik<WebhookUpdateSchema>
        initialValues={{
          input: {
            label: webhook.label ?? '',
            url: webhook.url,
            signingSecret: webhook.signingSecret,
          },
        }}
        validate={withZodSchema(webhookUpdateSchema)}
        onSubmit={onSubmit}
      >
        <Form>
          <WebhookForm onCancel={onCancel} />
        </Form>
      </Formik>
    </Drawer>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Badge,
  Button,
  colors,
  ControlGroup,
  darkThemeSelector,
  fontWeights,
  Layout,
  LayoutBanner,
  sizing,
  Small,
  styled,
  Text,
} from '@meterup/atto';
import { useIsDemoMode } from '@meterup/authorization';
import AlertBanner from '@meterup/common/src/components/AlertBanner';
import { ErrorBoundary } from '@sentry/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Suspense, useState } from 'react';
import { Outlet } from 'react-router';
import { useParams } from 'react-router-dom';

import { ErrorFallback } from '../../components/ErrorFallback/ErrorFallback';
import { AppLoadingFallback } from '../../components/Placeholders/AppLoadingFallback';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useLogoutHandler } from '../../hooks/useLogoutHandler';
import { useNetworkOrNull } from '../../hooks/useNetworkFromPath';
import { useIdentity } from '../../providers/IdentityDataProvider';
import { SearchParamsStateProvider } from '../../providers/SearchParamsStateProvider';

export const Meta: PagefileMetaFn = () => ({
  name: 'AppLayout',
});

const DemoBannerCopy = styled(Small, {
  fontWeight: fontWeights.bold,
  color: colors.headingBrandLight,
  textAlign: 'center',

  [darkThemeSelector]: {
    color: colors.headingBrandDark,
  },
});

const DemoBanner = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `$6 ${sizing.sides}`,
  background: colors.bgBrandLight,
  strokeAll: colors.strokeBrandLight,

  [darkThemeSelector]: {
    background: colors.bgBrandDark,
    strokeAll: colors.strokeBrandDark,
  },
});

const ImpersonationBannerFiller = styled('div', {
  width: '$48',
});

const ImpersonationBannerCopy = styled(Small, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  gap: '$6',
  fontWeight: fontWeights.bold,
  color: colors.internalHeadingLight,

  [darkThemeSelector]: {
    color: colors.internalHeadingDark,
  },
});

const ImpersonationBannerActions = styled(ControlGroup);

const ImpersonationBanner = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `$6 ${sizing.sides}`,
  background: colors.internalBgLight,
  strokeAll: colors.internalStrokeLight,

  [darkThemeSelector]: {
    background: colors.internalBgDark,
    strokeAll: colors.internalStrokeDark,
  },

  variants: {
    isDismissed: {
      true: {
        display: 'none',
      },
      false: {},
    },
  },
});

export default function AppLayout() {
  const params = useParams();
  const featureFlags = useFeatureFlags();
  const network = useNetworkOrNull();
  const demo = useIsDemoMode();
  const logout = useLogoutHandler();
  const identity = useIdentity();
  const isImpersonating = identity?.impersonator;
  const [dismissImpersonatingAlert, setDismissImpersonatingAlert] = useState(false);

  return (
    <SearchParamsStateProvider>
      <Layout>
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary fallback={ErrorFallback} onReset={reset}>
              <Suspense fallback={<AppLoadingFallback />}>
                <LayoutBanner>
                  <AlertBanner alertBanners={featureFlags['alert-banners']} params={params} />
                  {network?.isTemplate && (
                    <Alert
                      relation="stacked"
                      icon="globe"
                      heading="You are viewing a template network"
                      copy="This network is used as a starting point for future networks for this company. You cannot attach hardware to this network, and it will not serve traffic."
                    />
                  )}
                  {demo && (
                    <DemoBanner>
                      <DemoBannerCopy>You are viewing the dashboard in demo mode.</DemoBannerCopy>
                    </DemoBanner>
                  )}
                  {isImpersonating && (
                    <ImpersonationBanner isDismissed={dismissImpersonatingAlert}>
                      <ImpersonationBannerFiller />
                      <ImpersonationBannerCopy>
                        <Text>Impersonating</Text>
                        <Badge arrangement="leading-icon" icon="user" size="small" internal>
                          {identity.username}
                        </Badge>
                      </ImpersonationBannerCopy>
                      <ImpersonationBannerActions size="x-small">
                        <Button
                          internal
                          arrangement="hidden-label"
                          icon="power"
                          onClick={logout}
                          variant="secondary"
                        >
                          Sign out
                        </Button>
                        <Button
                          internal
                          arrangement="hidden-label"
                          icon="cross"
                          onClick={() => setDismissImpersonatingAlert(true)}
                          variant="secondary"
                        >
                          Dismiss
                        </Button>
                      </ImpersonationBannerActions>
                    </ImpersonationBanner>
                  )}
                </LayoutBanner>
                <Outlet />
              </Suspense>
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </Layout>
    </SearchParamsStateProvider>
  );
}

import type { PropsOf } from '@meterup/atto/src/utilities/types/polymorphicAsProp';
import {
  CopyBox,
  EmptyState,
  Section,
  SectionContent,
  SectionHeader,
  styled,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
} from '@meterup/atto';
import { type SortingState, AutoTable } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Duration } from 'luxon';
import { useState } from 'react';

import type { InsightsQueryQuery } from '../../gql/graphql';
import { NoValue } from '../NoValue';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import {
  DeviceUptimeQuery,
  formatCPULoad,
  formatMemoryUsage,
  formatMemoryUsageWithTotal,
  formatPoEInfo,
  formatTimestamp,
  LatestDeviceCPULoadQuery,
  LatestDeviceMemoryUsageQuery,
  switchPoEInfoQuery,
  switchVirtualDevicePoEInfoQuery,
  SystemInfoStatQuery,
} from './utils';

export function CopyableMonoOrNoValue({
  label,
  value,
  internal,
  size,
  wrap,
}: {
  label: string;
  value: string | number | undefined | null;
  internal?: boolean;
  size?: PropsOf<typeof Text>['size'];
  wrap?: boolean;
}) {
  return value ? (
    <CopyBox aria-label={`Copy ${label}`} value={value} wrap={wrap}>
      <Text family="monospace" size={size}>
        {value}
      </Text>
    </CopyBox>
  ) : (
    <NoValue internal={internal} />
  );
}

export function CopyableDateOrNoValue({
  label,
  value,
  internal,
  size,
  wrap,
}: {
  label: string;
  value: string | undefined | null;
  internal?: boolean;
  size?: PropsOf<typeof Text>['size'];
  wrap?: boolean;
}) {
  return value ? (
    <CopyBox aria-label={`Copy ${label}`} value={value} wrap={wrap}>
      <Text size={size}>{formatTimestamp(value)}</Text>
    </CopyBox>
  ) : (
    <NoValue internal={internal} />
  );
}

export function DetailsWidgetListPair({
  label,
  value,
  internal,
}: {
  label: string;
  value: string | number | undefined | null;
  internal?: boolean;
}) {
  return (
    <SummaryListRow internal={internal}>
      <SummaryListKey>{label}</SummaryListKey>
      <SummaryListValue>
        <CopyableMonoOrNoValue label={label} value={value} internal={internal} />
      </SummaryListValue>
    </SummaryListRow>
  );
}

type BootHistoryArray = InsightsQueryQuery['hardwareDevice']['bootHistory'];

const BootHistoryScrollable = styled('div', {
  overflow: 'auto',
});

const builder = createColumnBuilder<any>();

const columns = [
  builder.data((row) => row.bootCount, {
    id: 'bootCount',
    header: 'Boot count',
    meta: { width: 96 },
  }),
  builder.data((row) => row.buildName, {
    id: 'buildName',
    header: 'Build name',
  }),

  builder.data((row) => (row.rebootReason !== '' ? row.rebootReason : '-'), {
    id: 'rebootReason',
    header: 'Reboot reason',
  }),
  builder.data((row) => row.createdAt, {
    id: 'createdAt',
    header: 'Created at (UTC)',
    cell: (props) => <>{formatTimestamp(props.value)}</>,
  }),
];

export function BootHistoryTable({
  bootHistory,
  globalFilter,
}: {
  bootHistory: BootHistoryArray;
  globalFilter?: string;
}) {
  const [sortingState, setSortingState] = useState<SortingState>([]);
  return (
    <BootHistoryScrollable>
      {bootHistory && bootHistory.length > 0 && (
        <AutoTable
          size="auto"
          columns={columns}
          data={bootHistory}
          sortingState={sortingState}
          onChangeSortingState={setSortingState}
          isRowSelected={() => false}
          globalFilter={globalFilter}
        />
      )}
      {!bootHistory?.length && <EmptyState icon="switch" heading="No boot history available" />}
    </BootHistoryScrollable>
  );
}

export function BootHistoryWidget({
  bootHistory,
  globalFilter,
}: {
  bootHistory: BootHistoryArray;
  globalFilter?: string;
}) {
  return (
    <Section internal relation="stacked">
      <SectionHeader icon="clock" heading="Boot history" />
      <SectionContent gutter="none">
        <BootHistoryTable bootHistory={bootHistory} globalFilter={globalFilter} />
      </SectionContent>
    </Section>
  );
}

export function MetricsWidget({ serialNumber }: { serialNumber: string }) {
  const deviceUptime = useGraphQL(
    DeviceUptimeQuery,
    { serialNumber },
    { useErrorBoundary: false, suspense: false },
  );

  const latestDeviceCPULoad = useGraphQL(
    LatestDeviceCPULoadQuery,
    { serialNumber },
    { useErrorBoundary: false, suspense: false },
  );

  const latestDeviceMemoryUsage = useGraphQL(
    LatestDeviceMemoryUsageQuery,
    { serialNumber },
    { useErrorBoundary: false, suspense: false },
  );

  const poeInfo = useGraphQL(
    switchPoEInfoQuery,
    { serialNumber },
    { useErrorBoundary: false, suspense: false },
  );

  return (
    <Section relation="stacked">
      <SectionHeader icon="noise" heading="Metrics" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>Uptime</SummaryListKey>
            <SummaryListValue>
              {deviceUptime.isError || !deviceUptime.data
                ? 'N/A'
                : Duration.fromISO(deviceUptime?.data?.deviceUptime).toHuman({
                    unitDisplay: 'long',
                  })}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>CPU load</SummaryListKey>
            <SummaryListValue>
              {latestDeviceCPULoad.isError || !latestDeviceCPULoad.data
                ? 'N/A'
                : formatCPULoad(latestDeviceCPULoad?.data?.latestDeviceCPULoad.usedPercentage)}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Memory available</SummaryListKey>
            <SummaryListValue>
              {latestDeviceMemoryUsage.isError || !latestDeviceMemoryUsage.data
                ? 'N/A'
                : formatMemoryUsage(latestDeviceMemoryUsage.data.latestDeviceMemoryUsage)}
            </SummaryListValue>
          </SummaryListRow>
          {!poeInfo.isError &&
            poeInfo.data &&
            poeInfo.data.hardwareDevice.__typename === 'SwitchHardwareDevice' && (
              <SummaryListRow>
                <SummaryListKey>PoE power used</SummaryListKey>
                <SummaryListValue>
                  {formatPoEInfo(poeInfo.data.hardwareDevice?.poeInfo)}
                </SummaryListValue>
              </SummaryListRow>
            )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

export function MetricsStatWidget({ virtualDeviceUUID }: { virtualDeviceUUID: string }) {
  const sysInfo = useGraphQL(
    SystemInfoStatQuery,
    { uuid: virtualDeviceUUID },
    { useErrorBoundary: false, suspense: false },
  );

  const poeInfo = useGraphQL(
    switchVirtualDevicePoEInfoQuery,
    { uuid: virtualDeviceUUID },
    { useErrorBoundary: false, suspense: false },
  );

  return (
    <Section relation="stacked">
      <SectionHeader icon="noise" heading="Metrics" />
      <SectionContent gutter="all">
        <SummaryList gutter="none">
          <SummaryListRow>
            <SummaryListKey>Uptime</SummaryListKey>
            <SummaryListValue>
              {sysInfo.isError ||
              !sysInfo.data ||
              sysInfo.data?.systemInfoStatForVirtualDevice.length === 0
                ? 'N/A'
                : Duration.fromISO(sysInfo.data?.systemInfoStatForVirtualDevice[0].uptime).toHuman({
                    unitDisplay: 'long',
                  })}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>CPU load</SummaryListKey>
            <SummaryListValue>
              {sysInfo.isError ||
              !sysInfo.data ||
              sysInfo.data?.systemInfoStatForVirtualDevice.length === 0
                ? 'N/A'
                : formatCPULoad(sysInfo.data?.systemInfoStatForVirtualDevice[0].cpuUsedPercentage)}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Memory available</SummaryListKey>
            <SummaryListValue>
              {sysInfo.isError ||
              !sysInfo.data ||
              sysInfo.data?.systemInfoStatForVirtualDevice.length === 0
                ? 'N/A'
                : formatMemoryUsageWithTotal({
                    bytestTotal: sysInfo.data?.systemInfoStatForVirtualDevice[0].memoryBytesTotal,
                    bytesAvailable:
                      sysInfo.data?.systemInfoStatForVirtualDevice[0].memoryBytesAvailable,
                  })}
            </SummaryListValue>
          </SummaryListRow>
          {!poeInfo.isError &&
            poeInfo.data &&
            poeInfo.data?.virtualDevice.__typename === 'SwitchVirtualDevice' && (
              <SummaryListRow>
                <SummaryListKey>PoE power used</SummaryListKey>
                <SummaryListValue>
                  {formatPoEInfo(poeInfo.data?.virtualDevice?.poeInfo)}
                </SummaryListValue>
              </SummaryListRow>
            )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}

import type { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import type { MetricsAPIEndpoint } from '../../api/metrics';
import MetricsAPI from '../../api/metrics';
import { TimeSeriesChart } from '../TimeSeriesChart';

interface MetricGraphProps {
  metric: MetricsAPIEndpoint;
  controllerName: string;
  controllerState: api.LifecycleStatus;
  wan: string;
  timePeriod: string;
  tooltipBody: string;
  title: string;
  titleBadge?: React.ReactNode;
  valueFormatter: (value: number) => string;
  yTicks?: number[];
  yDomain?: number[];
}
export default function MetricGraph({
  metric,
  controllerName,
  controllerState,
  wan,
  timePeriod,
  title,
  tooltipBody,
  titleBadge,
  valueFormatter,
  yTicks,
  yDomain,
}: MetricGraphProps) {
  const getDurationMS = () => {
    const min = 60 * 1000;
    const hour = 60 * min;
    const day = 24 * hour;

    switch (timePeriod) {
      case '1h':
        return 1 * hour;
      case '6h':
        return 6 * hour;
      case '7d':
        return 7 * day;
      case '30d':
        return 30 * day;
      case '24h':
      default:
        return 24 * hour;
    }
  };

  const getStep = (endpoint: MetricsAPIEndpoint) => {
    // The `step` is the number of seconds to bucket a time series by. We can modify these, but
    // we will start with the following:
    //
    // Default: 5 minutes (this is what our emitter collects, so we're just defaulting to the native).
    // 7 days: 30 minute buckets (roughly 336 data points)
    // 30 days: 60 minute buckets (roughly 720 data points)
    switch (timePeriod) {
      case '7d':
        return 30 * 60;
      case '30d':
        return 60 * 60;
      default:
        if (endpoint.series_id === 'isp_quality') {
          return 60;
        }
        return 5 * 60;
    }
  };

  const { data, isError } = useQuery(
    ['metrics', metric.series_id, controllerName, wan, timePeriod],
    () =>
      MetricsAPI.fetchMetric(metric, {
        controllerName,
        controllerState,
        wan,
        durationMs: getDurationMS(),
        step: getStep(metric),
      }),
    {
      suspense: true,
    },
  );

  return (
    <TimeSeriesChart
      title={title}
      tooltipBody={tooltipBody}
      titleBadge={titleBadge}
      series={data!}
      valueFormatter={valueFormatter}
      yTicks={yTicks}
      yDomain={yDomain}
      isError={isError}
      timePeriod={timePeriod}
    />
  );
}

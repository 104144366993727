import VirtualDeviceCreateDrawer from '../../../../../components/Devices/VirtualDeviceCreateDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType, VirtualDeviceType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/power-distribution-units/create',
  title: 'Add power distribution unit - Power distribution units - Hardware',
});

export default function PowerDistributionUnitCreatePage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
        !!nosFlags[NosFeature.POS]
      }
      should404OnAccessDenied
    >
      <VirtualDeviceCreateDrawer deviceType={VirtualDeviceType.PowerDistributionUnit} />
    </IsPermitted>
  );
}

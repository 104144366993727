import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import Multicast from '../../../../../components/Firewall/Multicast/Multicast';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { PermissionType } from '../../../../../gql/graphql';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/multicast',
  layout: 'VirtualNetworkLayout',
  title: 'mDNS - Firewall',
});

export default function MulticastPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermFirewallRead) && ldFlags['multicast-mdns'],
        )
      }
      should404OnAccessDenied
    >
      <Multicast />
    </IsPermitted>
  );
}

import type { SortingState } from '@tanstack/react-table';
import { Badge, Icon, space, Tooltip } from '@meterup/atto';
import { AutoTable, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useRef, useState } from 'react';

import type { ApbssiDsQueryQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';
import { CopyableMonoOrNoValue } from '../../Devices/Insights';
import { SSIDDeviceTarget } from '../../DeviceTargets';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { radioBandToHuman } from '../../Wireless/RadioProfiles/utils';

type BSSID = NonNullable<
  (ApbssiDsQueryQuery['virtualDevice']['hardwareDevice'] & {
    __typename: 'AccessPointHardwareDevice';
  })['BSSIDs']
>[number];

const APBSSIDsQuery = graphql(`
  query APBSSIDsQuery($virtualDeviceUUID: UUID!) {
    virtualDevice(UUID: $virtualDeviceUUID) {
      hardwareDevice {
        __typename

        ... on AccessPointHardwareDevice {
          BSSIDs {
            SSID {
              ssid
            }
            BSSID
            isActive
            radioBand
          }
        }
      }
    }
  }
`);

const builder = createColumnBuilder<BSSID>();

const columns = [
  builder.data((s) => (s.isActive ? 'Active' : 'Inactive'), {
    id: 'is-active',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: ({ row }) => {
      if (row.isActive) {
        return (
          <Tooltip contents="BSSID is being broadcast">
            <Badge
              arrangement="hidden-label"
              size="small"
              ends="pill"
              icon="wifi"
              variant="positive"
            >
              Broadcast
            </Badge>
          </Tooltip>
        );
      }
      return (
        <Tooltip contents="BSSID is not being broadcast">
          <Badge arrangement="hidden-label" size="small" ends="pill" icon="wifi" variant="neutral">
            Not broadcast
          </Badge>
        </Tooltip>
      );
    },
  }),
  builder.data((s) => s.BSSID, {
    id: 'bssid',
    header: 'BSSID',
    cell: ({ value }) => <CopyableMonoOrNoValue label="BSSID" value={value} wrap={false} />,
  }),
  builder.data((s) => s.SSID.ssid, {
    id: 'ssid',
    header: 'SSID',
    cell: ({ value }) => <SSIDDeviceTarget ssidName={value} wrap={false} />,
  }),
  builder.data((s) => radioBandToHuman(s.radioBand), {
    id: 'band',
    header: 'Band',
  }),
];

export default function AccessPointBSSIDsList({
  virtualDeviceUUID,
  globalFilter,
}: {
  virtualDeviceUUID: string;
  globalFilter: string | undefined;
}) {
  const [sortingState, setSortingState] = useState<SortingState>([
    { id: 'is-active', desc: false },
    { id: 'ssid', desc: false },
  ]);

  const containerRef = useRef<HTMLDivElement>(null);

  const apHardwareDevice = useGraphQL(APBSSIDsQuery, {
    virtualDeviceUUID,
  }).data?.virtualDevice?.hardwareDevice;
  expectDefinedOrThrow(apHardwareDevice, new ResourceNotFoundError('Access point not found'));

  if (apHardwareDevice.__typename !== 'AccessPointHardwareDevice') {
    throw new Error('Expected AccessPointHardwareDevice');
  }

  return (
    <div ref={containerRef}>
      <AutoTable
        isVirtual
        tableContainerRef={containerRef}
        data={apHardwareDevice.BSSIDs}
        columns={columns}
        globalFilter={globalFilter}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
      />
    </div>
  );
}

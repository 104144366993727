import type { SortingState } from '@meterup/common';
import {
  Alert,
  Badge,
  Button,
  EmptyState,
  HStack,
  ProviderIcon,
  sizing,
  space,
  styled,
  Text,
} from '@meterup/atto';
import { AutoTable, useLocalStorage } from '@meterup/common';
import { Link } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { screamingSnakeCaseToWords } from '../../../utils/strings';
import IsPermitted from '../../permissions/IsPermitted';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { ISPProviderSlug, useISPs } from './utils';

const builder = createColumnBuilder<any>();

const columns = [
  builder.data((row) => row.provider?.name, {
    id: 'provider',
    header: 'Provider',
    meta: {
      isLeading: true,
    },
    cell: ({ row }) => (
      <HStack align="center" spacing={space(8)}>
        <ProviderIcon
          provider={ISPProviderSlug({ providerName: row.provider ? row.provider.name : 'unknown' })}
          size={space(20)}
        />
        <Text>{row.provider?.name}</Text>
      </HStack>
    ),
  }),
  builder.data((row) => row.connectionStatus, {
    id: 'status',
    header: 'Status',
    cell: ({ row }) => (
      <Badge size="small" variant={row.connectionStatus === 'PRIMARY' ? 'positive' : 'neutral'}>
        {screamingSnakeCaseToWords(row.connectionStatus)}
      </Badge>
    ),
  }),
  builder.data((row) => row.connectionProduct, {
    id: 'product',
    header: 'Product',
    cell: ({ row }) => <span>{screamingSnakeCaseToWords(row.connectionProduct)}</span>,
  }),
  builder.data((row) => row.hasStaticIP, {
    id: 'dhcp',
    header: 'DHCP',
    meta: {
      width: space(84),
    },
    cell: ({ row }) => (
      <Badge size="small" variant="neutral">
        {row.hasStaticIP ? 'Static' : 'Dynamic'}
      </Badge>
    ),
  }),
];

const SingularISPAlert = styled('div', {
  padding: sizing.squish,
  width: '100%',

  variants: {
    isDismissed: {
      true: {
        display: 'none',
      },
    },
  },
});

export default function ISPsList() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isps = useISPs({ companySlug: companyName, networkUUID: network.UUID });
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ISPDrawerPage);

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const [dismissSingularISPAlert, setDismissSingularISPAlert] = useLocalStorage(
    'dashboard.isps.singularAlert.dismissed',
    false,
  );

  if (isps.length === 0) {
    return (
      <EmptyState
        icon="globe"
        heading="No ISPs"
        action={
          <IsPermitted
            isPermitted={({ permissions }) =>
              permissions.hasPermission(PermissionType.PermIspWriteRestricted)
            }
          >
            <Button
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.ISPsAddDrawerPage, {
                companyName,
                networkSlug: network.slug,
              })}
              arrangement="leading-icon"
              icon="plus"
              internal
            >
              Add ISP
            </Button>
          </IsPermitted>
        }
      />
    );
  }

  return (
    <>
      {isps.length === 1 && (
        <SingularISPAlert isDismissed={dismissSingularISPAlert}>
          <Alert
            icon="attention"
            copy="Your Meter Network currently does not have a backup ISP. For redundancy and optimal performance, we recommend having a backup connection."
            variant="attention"
            dismissOnClick={() => setDismissSingularISPAlert(true)}
            isDismissed={dismissSingularISPAlert}
            trailingButtons={
              <Button
                onClick={() =>
                  window.open(
                    `mailto:connect@meter.com?subject=Backup ISP for ${companyName}`,
                    '_blank',
                  )
                }
                arrangement="leading-icon"
                icon="email"
                variant="primary"
              >
                Get ISP quotes
              </Button>
            }
          />
        </SingularISPAlert>
      )}
      <AutoTable
        key="isps-list"
        data={isps}
        columns={columns}
        getLinkTo={(row) =>
          makeDrawerLink(window.location, paths.drawers.ISPDrawerPage, {
            companyName,
            networkSlug: network.slug,
            isp: row.UUID,
          })
        }
        isRowSelected={(row) => row.UUID === drawerParams?.isp}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        globalFilter={globalFilter}
      />
    </>
  );
}

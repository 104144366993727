import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  DetailHeader,
  expectDefinedOrThrow,
  ResourceNotFoundError,
} from '@meterup/common';
import { orderBy } from 'lodash-es';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import { paths } from '../../../../../constants';
import { useNetworkClients } from '../../../../../hooks/networkClients/useNetworkClients';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { clientNameOrNull, manufacturerIconName } from '../../../../../utils/clientLists';

function ClientDetailPageHeader() {
  const { macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ClientDetailPage),
  );

  const network = useNetwork();
  const clientHistory = useNetworkClients(network, {
    filter: { macAddress, excludeMeterHardware: true },
  });

  const client = orderBy(clientHistory, (d) => d.lastSeen, 'desc')[0] ?? null;

  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));

  return (
    <DetailHeader
      icon={manufacturerIconName(client)}
      heading={clientNameOrNull(client) ?? client.macAddress ?? '-'}
      type="client"
    />
  );
}

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/clients/:macAddress',
  layout: 'NetworkLayout',
});

export default function ClientDetailLayout() {
  return <DetailPageLayout header={<ClientDetailPageHeader />} />;
}

import SecurityApplianceDetailView from '../../../../../components/Hardware/SecurityAppliance/SecurityApplianceDetail';
import { SecurityApplianceDetailsTab } from '../../../../../components/Hardware/SecurityAppliance/utils2';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/security-appliances/:uuid/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Security appliance - Hardware',
});

export default function SecurityApplianceDetailPage() {
  const pageParams = Nav.useRegionParams('root', paths.pages.SecurityApplianceDetailPage);

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags, pathParams }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
            nosFlags[NosFeature.COS2] &&
            Object.values(SecurityApplianceDetailsTab).includes(
              pathParams?.tab as SecurityApplianceDetailsTab,
            ),
        )
      }
      path={Meta().path}
      should404OnAccessDenied
    >
      <SecurityApplianceDetailView
        uuid={pageParams?.uuid!}
        tab={pageParams?.tab! as SecurityApplianceDetailsTab}
      />
    </IsPermitted>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Sidebar, SidebarGroup, SidebarGroups, SidebarTarget } from '@meterup/atto';
import { Navigate, Outlet } from 'react-router';

import { DockPageLayout } from '../../../components/DockPageLayout';
import { LocationSwitcher } from '../../../components/LocationSwitcher';
import { MainAndDrawerLayout } from '../../../components/MainAndDrawerLayout';
import { ReactRouterLink } from '../../../components/ReactRouterLink';
import { DrawerRoutes } from '../../../components/route_elements';
import { paths } from '../../../constants';
import { useCurrentControllerOrNull } from '../../../hooks/useCurrentControllerOrNull';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { ProductTypes, useUserProductsAccess } from '../../../hooks/useUserProductsAccess';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';

function LegacySidebarEntries({
  controllerName,
  onNavItemClick,
}: {
  controllerName: string;
  onNavItemClick?: () => void;
}) {
  const isActiveTest = useIsActiveMatcher();
  const companyName = useCurrentCompany();

  return (
    <Sidebar
      product="tunnel"
      heading="VPN"
      start={
        <LocationSwitcher
          shouldNotChangePages
          routePathsNotToChange={[paths.pages.LegacyVPNListPage, paths.pages.ClientVPNPage]}
        />
      }
    >
      <SidebarGroups>
        <SidebarGroup>
          <SidebarTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.LegacyVPNListPage, { companyName, controllerName })}
            label="Clients"
            isSelected={isActiveTest({ path: paths.pages.LegacyVPNListPage, end: false })}
            icon="client"
            onClick={onNavItemClick}
          />
        </SidebarGroup>
      </SidebarGroups>
    </Sidebar>
  );
}

function SidebarEntries({ onNavItemClick }: { onNavItemClick?: () => void }) {
  const isActiveTest = useIsActiveMatcher();
  const network = useNetwork();
  const companyName = useCurrentCompany();

  return (
    <Sidebar
      product="tunnel"
      heading="VPN"
      start={
        <LocationSwitcher
          shouldNotChangePages
          routePathsNotToChange={[paths.pages.ClientVPNPage, paths.pages.LegacyVPNListPage]}
        />
      }
    >
      <SidebarGroups>
        <SidebarGroup>
          <SidebarTarget
            as={ReactRouterLink}
            to={makeLink(paths.pages.ClientVPNPage, { companyName, networkSlug: network.slug })}
            label="Clients"
            isSelected={isActiveTest({ path: paths.pages.ClientVPNPage, end: false })}
            icon="client"
            onClick={onNavItemClick}
          />
        </SidebarGroup>
      </SidebarGroups>
    </Sidebar>
  );
}

export const Meta: PagefileMetaFn = () => ({
  name: 'TunnelLayout',
  layout: 'AppLayout',
});

function TunnelSidebar() {
  const controllerName = useCurrentControllerOrNull();
  const [isCOS2Enabled] = useNosFeaturesEnabled([NosFeature.COS2]);

  if (isCOS2Enabled) {
    return <SidebarEntries />;
  }

  if (controllerName && !isCOS2Enabled) {
    return <LegacySidebarEntries controllerName={controllerName} />;
  }

  return null;
}

export default function ConnectLayout() {
  const access = useUserProductsAccess();
  if (!access.hasAccessToProduct(ProductTypes.TUNNEL)) {
    return <Navigate to={paths.pages.RootPage} />;
  }

  return (
    <DockPageLayout
      sidebar={<TunnelSidebar />}
      main={<MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />}
    />
  );
}

import {
  Badge,
  Box,
  Button,
  Column,
  Columns,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  Pane,
  PaneContent,
  PaneHeader,
  Skeleton,
  space,
  VStack,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import {
  bitsPerSecond,
  checkDefinedOrThrow,
  formatDataRateBits,
  isDefined,
  ResourceNotFoundError,
} from '@meterup/common';
import { formatISPStatus } from '@meterup/common/src/topics/isp_status';
import { api } from '@meterup/proto';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as d3 from 'd3';
import { Suspense, useMemo } from 'react';
import { Navigate } from 'react-router';

import { ActiveClientCount, ISPQuality, ISPThroughput } from '../../../../../api/metrics';
import { StickyFilterBar } from '../../../../../components/Insights/FilterBar';
import MetricGraph from '../../../../../components/Overview/MetricGraph';
import StatsList from '../../../../../components/Overview/StatsList';
import PingTest from '../../../../../components/PingTest';
import { paths } from '../../../../../constants';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { useNetworkOrNull } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

const dataRateValueFormatter = (value: number) => formatDataRateBits(value, bitsPerSecond);
const percentValueFormatter = d3.format('.2~%');

function OverviewMetricsPage() {
  const [currentNetworkOrUndefined, setCurrentNetwork] = useSearchParamsState<api.ISPStatus>(
    'network',
    api.ISPStatus.IS_PRIMARY,
  );
  const currentNetwork = currentNetworkOrUndefined ?? api.ISPStatus.IS_PRIMARY;

  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const timePeriodLabel = (period: string) => {
    switch (period) {
      case '1h':
        return 'Last hour';
      case '6h':
        return 'Last 6 hours';
      case '7d':
        return 'Last 7 days';
      case '30d':
        return 'Last 30 days';
      case '24h':
      default:
        return 'Last 24 hours';
    }
  };

  const companyName = useCurrentCompany();
  const network = useNetworkOrNull();
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const controllerState = controller.lifecycle_status;
  const apiClient = useAPIClient();
  const queryClient = useQueryClient();
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const flags = useFeatureFlags();
  const isOperator = useIsOperator();

  const refreshQueries = () => {
    queryClient.refetchQueries({ queryKey: ['metrics'] });
  };

  const connections = useQuery(
    ['network_isp_info_installed', controllerName],
    () => apiClient.isps(true),
    {
      suspense: true,
      onSuccess: (data) => {
        if (data.length === 1) {
          setCurrentNetwork(data[0].status);
        }
      },
    },
  ).data;

  const NetworkRadioItems = useMemo(
    () =>
      (connections ?? []).map((conn) => (
        <DropdownMenuRadioItem key={`network-${conn.sid}`} value={conn.status}>
          {formatISPStatus(conn.status)}
        </DropdownMenuRadioItem>
      )),
    [connections],
  );

  const getWAN = (status: api.ISPStatus) => {
    const currentConn = (connections ?? []).find((conn) => conn.status === status);
    if (!isDefined(currentConn)) {
      // Possible that no ISPs are configured, or none for the interface.
      return 'wan0';
    }
    return checkDefinedOrThrow(
      currentConn.network_interface,
      new ResourceNotFoundError(`no network interface on ${currentConn.sid}`),
    );
  };

  if (network && isCOS2Enabled) {
    return (
      <Navigate
        to={{
          pathname: makeLink(paths.pages.InsightsNetworkPage, {
            companyName,
            networkSlug: network.slug,
          }),
        }}
        replace
      />
    );
  }

  return (
    <Pane>
      <PaneHeader icon="network" heading="Network" />
      <PaneContent>
        <Columns template="wide-narrow">
          <Column gutter="bottom">
            <Suspense
              fallback={
                <Box padding={{ x: space(16) }}>
                  <VStack spacing={space(12)}>
                    <Skeleton height={24} width="100%" radius={6} />
                    <Skeleton height={200} width="100%" radius={6} />
                    <Skeleton height={200} width="100%" radius={6} />
                    <Skeleton height={200} width="100%" radius={6} />
                  </VStack>
                </Box>
              }
            >
              <StickyFilterBar>
                {(connections ?? []).length > 1 && (
                  <DropdownMenu>
                    <DropdownMenuButton
                      variant="secondary"
                      arrangement="leading-icon"
                      icon="globe"
                      size="small"
                    >
                      {formatISPStatus(currentNetwork)}
                    </DropdownMenuButton>
                    <DropdownMenuPopover>
                      <DropdownMenuRadioGroup
                        value={currentNetwork}
                        onValueChange={(val) => setCurrentNetwork(val as api.ISPStatus)}
                      >
                        {NetworkRadioItems}
                      </DropdownMenuRadioGroup>
                    </DropdownMenuPopover>
                  </DropdownMenu>
                )}
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    arrangement="leading-icon"
                    icon="clock"
                    size="small"
                  >
                    {timePeriodLabel(currentTimePeriod)}
                  </DropdownMenuButton>
                  <DropdownMenuPopover>
                    <DropdownMenuRadioGroup
                      value={currentTimePeriod}
                      onValueChange={(val) => setCurrentTimePeriod(val as string)}
                    >
                      <DropdownMenuRadioItem value="1h">
                        {timePeriodLabel('1h')}
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="6h">
                        {timePeriodLabel('6h')}
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="24h">
                        {timePeriodLabel('24h')}
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="7d">
                        {timePeriodLabel('7d')}
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem value="30d">
                        {timePeriodLabel('30d')}
                      </DropdownMenuRadioItem>
                    </DropdownMenuRadioGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
                <Button
                  variant="secondary"
                  arrangement="hidden-label"
                  icon="arrows-rotate"
                  onClick={refreshQueries}
                  size="small"
                >
                  Refresh
                </Button>
              </StickyFilterBar>
              <MetricGraph
                metric={ActiveClientCount}
                title="Active clients"
                titleBadge={
                  <Badge
                    arrangement="leading-icon"
                    ends="pill"
                    icon="wifi"
                    size="small"
                    variant="neutral"
                  >
                    All SSIDs
                  </Badge>
                }
                tooltipBody="The number of clients sending traffic on the network. Includes wired and wireless clients and excludes Meter devices."
                valueFormatter={(d) => d.toFixed(0)}
                controllerName={controllerName}
                controllerState={controllerState}
                wan={getWAN(currentNetwork)}
                timePeriod={currentTimePeriod}
              />
              <MetricGraph
                title="ISP quality"
                tooltipBody="The percentage of successful pings to common web servers over each ISP connection."
                metric={ISPQuality}
                valueFormatter={percentValueFormatter}
                yTicks={[0, 1]}
                yDomain={[0, 2]}
                controllerName={controllerName}
                controllerState={controllerState}
                wan={getWAN(currentNetwork)}
                timePeriod={currentTimePeriod}
              />
              <MetricGraph
                metric={ISPThroughput}
                title="ISP throughput"
                tooltipBody="The number of bits sent to / received from the public internet."
                valueFormatter={dataRateValueFormatter}
                controllerName={controllerName}
                controllerState={controllerState}
                wan={getWAN(currentNetwork)}
                timePeriod={currentTimePeriod}
              />
            </Suspense>
          </Column>
          <Column gutter="vertical">
            <Suspense
              fallback={
                <Box padding={{ x: space(16) }}>
                  <VStack spacing={space(12)}>
                    <Skeleton height={120} width="100%" radius={6} />
                    <Skeleton height={200} width="100%" radius={6} />
                    <Skeleton height={160} width="100%" radius={6} />
                  </VStack>
                </Box>
              }
            >
              <StatsList wan={getWAN(currentNetwork)} />
              {!isCOS2Enabled && (isOperator || flags['controller-ping-ui']) && (
                <PingTest internal={!flags['controller-ping-ui']} />
              )}
            </Suspense>
          </Column>
        </Columns>
      </PaneContent>
    </Pane>
  );
}

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName',
  title: 'Insights',
});

export default function OverviewPage() {
  return <OverviewMetricsPage />;
}

import type { SortingState } from '@tanstack/react-table';
import {
  Badge,
  Button,
  EmptyState,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  SearchInput,
  space,
  Tab,
} from '@meterup/atto';
import { styled } from '@meterup/atto/src/stitches.config';
import { useIsOperator } from '@meterup/authorization';
import { observer, Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { AutoTable, isDefined, PanAndZoomRegion, preloadImage } from '@meterup/common';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import type { AccessPointDeviceData } from '../../../api/types';
import { fetchFloorPlan } from '../../../api/api';
import { paths } from '../../../constants';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../nav';
import { useAPIClient } from '../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import {
  formatAPRadiosForBand,
  getAPBadgeLabel,
  getAPBadgeProps,
  getAPNameForDevice,
  isOffline,
  isOnline,
  RadioNumbersOrDisabled,
} from '../../../utils/access_point_utils';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { createFilterEnumeration } from '../../Table/createFilterEnumeration';

const PanningContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: 0.5,
});

const DeviceFilters = createFilterEnumeration<AccessPointDeviceData>(
  [
    {
      key: 'all',
      icon: 'menu',
      label: 'All',
      predicate: () => true,
    },
    {
      key: 'online',
      icon: 'checkmark',
      label: 'Online',
      predicate: isOnline,
    },
    {
      key: 'offline',
      icon: 'cross',
      label: 'Offline',
      predicate: isOffline,
    },
    {
      key: 'draft',
      icon: 'minus',
      label: 'Draft',
      predicate: (d) => d.connected_status === 'draft',
    },
  ],
  {
    urlKey: 'tab',
  },
);

const builder = createColumnBuilder<AccessPointDeviceData>();

function EmptyStates({
  apCount,
  filteredAPCount,
  onResetFilters,
}: {
  apCount: number;
  filteredAPCount: number;
  onResetFilters: () => void;
}) {
  if (apCount === 0) {
    return (
      <EmptyState
        icon="access-point"
        heading="There are no access points associated with this network"
      />
    );
  }

  if (filteredAPCount === 0) {
    return (
      <EmptyState
        icon="filter"
        heading="Your filter returned no results"
        action={
          <Button icon="minus" arrangement="leading-icon" onClick={onResetFilters}>
            Reset filters
          </Button>
        }
      />
    );
  }

  return null;
}

enum AccessPointsTab {
  List = 'list',
  FloorPlan = 'floor-plan',
}

const AccessPoints = observer(() => {
  const companyName = useCurrentCompany();
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const apiClient = useAPIClient();
  const closeDrawer = useCloseDrawerCallback();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointSummary);

  const devices =
    useQuery(['devices_and_radios', controllerName], async () => apiClient.devicesForController(), {
      suspense: true,
    }).data?.access_points ?? [];

  const floorPlanURL = useQuery(['floor_plan', controllerName], async () => {
    const url = await fetchFloorPlan(controllerName);
    return url ? preloadImage(url) : null;
  }).data;

  const filteredDevices = DeviceFilters.useCurrentPredicate(devices);
  const resetFilters = DeviceFilters.useResetCallback();

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useState<SortingState>([{ id: 'name', desc: false }]);

  // TODO(DASH-3212): Move this into URL param
  const [activeTab, setActiveTab] = useState<AccessPointsTab>(AccessPointsTab.List);

  const navigate = useNavigate();
  const { state } = useCommand();

  const isOperator = useIsOperator({ respectDemoMode: true });

  const columns = useMemo(
    () => [
      builder.data((d) => d.connected_status, {
        id: 'status',
        header: () => <Icon icon="question" size={space(16)} />,
        meta: {
          alignment: 'center',
          width: 48,
          tooltip: {
            contents: 'Status',
          },
        },
        cell: (p) => (
          <Badge arrangement="hidden-label" size="small" ends="pill" {...getAPBadgeProps(p.row)}>
            {getAPBadgeLabel(p.row)}
          </Badge>
        ),
      }),
      builder.data((d) => getAPNameForDevice(d), {
        id: 'name',
        header: 'Name',
        meta: {
          isLeading: true,
        },
      }),
      ...(isOperator
        ? [
            builder.data((d) => d.name || '', {
              id: 'hostname',
              header: 'Hostname',
              meta: { internal: true },
            }),
          ]
        : []),
      builder.data((d) => (d.connected_clients || 0).toFixed(0), {
        id: 'clients',
        header: 'Clients',
        meta: {},
      }),
      builder.data((d) => formatAPRadiosForBand(d.radios, api.RadioBand.RB_5G) ?? '', {
        id: '5ghz',
        header: '5 GHz',
        meta: { width: 120 },
        cell: (p) => <RadioNumbersOrDisabled value={p.row.radios} band={api.RadioBand.RB_5G} />,
      }),
      builder.data((d) => formatAPRadiosForBand(d.radios, api.RadioBand.RB_2G) ?? '', {
        id: '2.4ghz',
        header: '2.4 GHz',
        meta: { width: 120 },
        cell: (p) => <RadioNumbersOrDisabled value={p.row.radios} band={api.RadioBand.RB_2G} />,
      }),
    ],
    [isOperator],
  );

  const nodes = useMemo(
    () =>
      state.ui.search.length > 0
        ? filteredDevices.map((device, index) =>
            state.nodeFactory.directory({
              id: `${device.name}_${index}`,
              display: device.location,
              label: device.location,
              group: `Search for "${state.ui.search}"`,
              children: [],
              priority: Priority.High,
              onEnter(dir) {
                const viewAccessPoint = dir.state.nodeFactory.action({
                  id: 'view-access-point',
                  display: 'View access point',
                  label: 'View access point',
                  group: device.location,
                  onSelect() {
                    navigate(
                      makeLink(paths.pages.LegacyAccessPointDetailPage, {
                        deviceName: device.name,
                        controllerName,
                        companyName,
                      }),
                    );
                  },
                });

                const viewWirelessInfo = dir.state.nodeFactory.action({
                  id: 'view-viewless-info',
                  display: 'View wireless info',
                  label: 'View wireless info',
                  group: device.location,
                  onSelect() {
                    navigate(
                      makeDrawerLink(window.location, paths.drawers.AddClientPage, {
                        companyName,
                        controllerName,
                      }),
                    );
                  },
                });

                const n = [viewAccessPoint, viewWirelessInfo];
                dir.addChildren(n);
                return () => {
                  dir.removeChildren(n);
                };
              },
            }),
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredDevices, state.ui.search],
  );

  useRegisterCommands(nodes, [state.ui.search]);

  const listView =
    filteredDevices.length > 0 ? (
      <AutoTable
        columns={columns}
        data={filteredDevices}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        globalFilter={globalFilter}
        getLinkTo={(row) =>
          row.location !== 'Wired'
            ? makeDrawerLink(window.location, paths.drawers.AccessPointSummary, {
                deviceName: row.name,
                controllerName,
                companyName,
              })
            : null
        }
        onRowDeselect={closeDrawer}
        isRowSelected={(d) => d.name === drawerParams?.deviceName}
      />
    ) : (
      <EmptyStates
        apCount={devices.length}
        filteredAPCount={filteredDevices.length}
        onResetFilters={resetFilters}
      />
    );

  const floorPlanView = (
    <PanningContainer>
      <PanAndZoomRegion>
        {isDefined(floorPlanURL) && (
          <img
            src={floorPlanURL}
            crossOrigin="anonymous"
            alt="Floor plan"
            style={{ maxHeight: '50vh', userSelect: 'none' }}
          />
        )}
      </PanAndZoomRegion>
    </PanningContainer>
  );

  return (
    <Pane>
      <PaneHeader
        icon="access-point"
        heading="Access points"
        count={devices.length}
        tabs={
          floorPlanURL && (
            <>
              <Tab
                icon="list"
                selected={activeTab === AccessPointsTab.List}
                onClick={() => setActiveTab(AccessPointsTab.List)}
              >
                List
              </Tab>
              <Tab
                icon="floorplan"
                selected={activeTab === AccessPointsTab.FloorPlan}
                onClick={() => setActiveTab(AccessPointsTab.FloorPlan)}
              >
                Floor plan
              </Tab>
            </>
          )
        }
        subtabs={activeTab === AccessPointsTab.List && <DeviceFilters.TabSwitcher />}
        actions={
          activeTab === AccessPointsTab.List && (
            <SearchInput
              aria-label="Filter access points"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              placeholder="..."
            />
          )
        }
      />
      <PaneContent>{activeTab === AccessPointsTab.List ? listView : floorPlanView}</PaneContent>
    </Pane>
  );
});

export default AccessPoints;

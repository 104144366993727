import type { FormikContextType } from 'formik';
import { useFormikContext } from 'formik';
import React from 'react';

interface FormikIfProps<T> {
  condition: (values: T, formik: FormikContextType<T>) => boolean;
  children?: React.ReactNode;
}

/**
 * Renders its children if the condition callback returns true for the current
 * form values.
 *
 * This component is a performance optimization to avoid re-rendering the
 * entire form as values change.
 */
export function FormikConditional<T extends any>(props: FormikIfProps<T>) {
  const formik = useFormikContext<T>();
  const { condition, children } = props;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return condition(formik.values, formik) ? <>{children}</> : null;
}

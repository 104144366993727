import { Drawer, DrawerHeader } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';
import React from 'react';

import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import ISPAddForm from './ISPAddForm';

export default function ISPsAdd() {
  const close = useCloseDrawerCallback();

  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  return (
    <Drawer>
      <DrawerHeader icon="globe" heading="Add ISP" onClose={close} />
      <ISPAddForm />
    </Drawer>
  );
}

import { graphql } from '../../../../gql';

export const ssoOptionsQuery = graphql(`
  query SSOOptionsQuery($companySlug: String!) {
    ssoOptions(input: { companySlug: $companySlug }) {
      connectedDirectory {
        createdAt
        state
        type
      }
      connections {
        connectedAt
        state
        type
      }
    }
  }
`);

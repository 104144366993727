import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import FirewallRules from '../../../../../components/Firewall/FirewallRules';
import { FirewallRulesTab } from '../../../../../components/Firewall/utils';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/firewall/rules/:tab',
  layout: 'VirtualNetworkLayout',
  title: 'Rules - Firewall',
});

export default function RulesPage() {
  const pageParams = Nav.useRegionParams('root', paths.pages.RulesPage);
  return (
    <IsPermitted
      isPermitted={({ isOperator, permissions, ldFlags, nosFlags }) =>
        permissions.hasPermission(PermissionType.PermFirewallRead) &&
        (isOperator || !!ldFlags['firewall-rules']) &&
        nosFlags[NosFeature.COS2] &&
        Object.values(FirewallRulesTab).includes(pageParams?.tab as FirewallRulesTab) &&
        (pageParams?.tab === FirewallRulesTab.VLANs ||
          (pageParams?.tab === FirewallRulesTab.WANs &&
            (isOperator || !!ldFlags['firewall-rules-wan'])) ||
          (pageParams?.tab === FirewallRulesTab.Rules &&
            (isOperator || !!ldFlags['firewall-rules-wan'])) ||
          (pageParams?.tab === FirewallRulesTab.InterVLANCommunication &&
            (isOperator || !!ldFlags['firewall-rules-inter-vlan'])))
      }
      path={Meta().path}
      should404OnAccessDenied
      allowOperatorAlways={false}
    >
      <FirewallRules tab={pageParams?.tab as FirewallRulesTab} />
    </IsPermitted>
  );
}

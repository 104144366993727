import { Pane, PaneContent, PaneHeader, SearchInput } from '@meterup/atto';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useWirelessCrumbs } from '../../../utils/routing';
import { ReactRouterLink } from '../../ReactRouterLink';
import { AddHotspot20Button } from './AddHotspot20Button';
import Hotspot20sList from './Hotspot20sList';
import { useSsids } from './utils';

export default function Hotspot20s() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const wirelessCrumb = useWirelessCrumbs();
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');

  const ssids = useSsids({ filter: (ssid) => !!ssid?.hs20HasProfile });
  const { hasPermission } = usePermissions();
  const canEditCaptivePortal = hasPermission(PermissionType.PermCaptivePortalWrite);

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...wirelessCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.Hotspot20sPage, {
                companyName,
                networkSlug: network.slug,
              }),
              selected: true,
              label: 'Hotspot 2.0 profiles',
            },
          },
        ]}
        icon="hotspot-2.0"
        heading="Hotspot 2.0 profiles"
        count={ssids?.length ?? 0}
        actions={
          <>
            {canEditCaptivePortal && <AddHotspot20Button />}
            <SearchInput
              placeholder="..."
              aria-label="Filter Hotspot 2.0 profiles"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent gutter="bottom">
        <Hotspot20sList ssids={ssids} />
      </PaneContent>
    </Pane>
  );
}

import type { FormikHelpers } from 'formik';
import { Drawer, DrawerHeader } from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { NotificationUpdateSchema } from '../utils';
import { paths } from '../../../../../constants';
import { graphql } from '../../../../../gql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useIdentity } from '../../../../../providers/IdentityDataProvider';
import { useNetworkForSettingsPath } from '../../../../../routes/pages/settings/network/NetworkUtils';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import {
  notificationUpdateSchema,
  useInvalidateNotificationSettings,
  useNotificationDefinitions,
  useNotificationSetting,
} from '../utils';
import AlertActions from './AlertActions';
import AlertForm from './AlertForm';

const updateNotificationSettingMutation = graphql(`
  mutation updateNotificationSettingMutation(
    $UUID: UUID!
    $input: UpdateNotificationSettingInput!
  ) {
    updateNotificationSetting(UUID: $UUID, input: $input) {
      UUID
    }
  }
`);

function argumentsInputToGQL(args: NotificationUpdateSchema['input']['arguments']) {
  return args.map((arg) => ({
    parameterUUID: arg.parameterUUID,
    value: arg.value,
  }));
}

export default function AlertsEdit({ alertUUID }: { alertUUID: string }) {
  const closeDrawer = useCloseDrawerCallback();
  const mutation = useGraphQLMutation(updateNotificationSettingMutation);
  const network = useNetworkForSettingsPath({
    path: paths.pages.AlertsPage,
  });
  const navigate = useNavigate();
  const companyName = useCurrentCompany();
  const onCancel = useCloseDrawerCallback();
  const invalidateSettings = useInvalidateNotificationSettings({ networkUUID: network.UUID });
  const identity = useIdentity();
  const definitions = useNotificationDefinitions();
  const alert = useNotificationSetting({ networkUUID: network.UUID, uuid: alertUUID });

  const selectedDefinition = useMemo(
    () => definitions.find((d) => d.name === alert.notificationDefinition?.name),
    [alert.notificationDefinition?.name, definitions],
  );

  // Augments the initial arguments with their types and required status from the alert definition for validation purposes.
  // Converts the arguments from the GQL format to the form format.
  function argumentsGQLToInput(
    args:
      | Omit<NotificationUpdateSchema['input']['arguments'][number], 'type' | 'required'>[]
      | undefined
      | null,
  ) {
    expectDefinedOrThrow(args, new ResourceNotFoundError('No arguments found'));

    return args.map((arg) => {
      const parameterDefinition = selectedDefinition?.parameters.find(
        (p) => p.uuid === arg.parameterUUID,
      );
      expectDefinedOrThrow(parameterDefinition, new ResourceNotFoundError('No parameter found'));
      return {
        parameterUUID: arg.parameterUUID,
        value: arg.value,
        type: parameterDefinition.type,
        required: parameterDefinition.required,
      };
    });
  }

  const onSubmit = useCallback(
    (values: NotificationUpdateSchema, form: FormikHelpers<NotificationUpdateSchema>) => {
      const { input } = values;
      mutation.mutate(
        {
          UUID: alertUUID,
          input: {
            alertReceiverUUID: input.alertReceiverUUID,
            isEnabled: input.isEnabled,
            arguments: argumentsInputToGQL(input.arguments),
            updatedBy: identity?.id,
          },
        },
        {
          onSuccess() {
            notify('Successfully updated alert', {
              variant: 'positive',
            });
            form.resetForm();
            invalidateSettings();
            navigate(
              makeLink(paths.pages.AlertsPage, {
                companyName,
                networkSlug: network.slug,
              }),
            );
          },
          onError() {
            notify('Failed to update alert', {
              variant: 'negative',
            });
          },
        },
      );
    },
    [mutation, alertUUID, identity?.id, invalidateSettings, navigate, companyName, network.slug],
  );

  return (
    <Drawer>
      <DrawerHeader
        icon="plus"
        heading="Edit alert"
        onClose={closeDrawer}
        actions={<AlertActions uuid={alertUUID} />}
      />
      <Formik<NotificationUpdateSchema>
        initialValues={{
          input: {
            alertReceiverUUID: alert.alertReceiverUUID,
            isEnabled: alert.isEnabled,
            arguments: argumentsGQLToInput(alert.arguments),
            notificationDefinitionName: alert.notificationDefinition?.name ?? '',
          },
        }}
        validate={withZodSchema(notificationUpdateSchema)}
        onSubmit={onSubmit}
      >
        <Form>
          <AlertForm onCancel={onCancel} isEdit />
        </Form>
      </Formik>
    </Drawer>
  );
}

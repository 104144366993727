import type { QueryResultField } from '@meterup/graphql';
import type { z } from 'zod';

import type { PowerDistributionUnitsQueryQuery, VlaNsQueryQuery } from '../../../gql/graphql';
import type { UpdateOutletInterfaceInputSchema } from '../../../gql/zod-types';
import { graphql } from '../../../gql';

export type PowerDistributionUnitsQueryResult = QueryResultField<
  PowerDistributionUnitsQueryQuery,
  'virtualDevicesForNetwork'
>;
export type VLANQueryResult = QueryResultField<VlaNsQueryQuery, 'vlans'>;

export const fallbackPowerDistributionUnitHardwareDeviceQuery = graphql(`
  query FallbackPowerDistributionUnitHardwareQuery($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      virtualDeviceUUID
    }
  }
`);

export const PowerDistributionUnitQuery = graphql(`
  query PowerDistributionUnitQuery($uuid: UUID!) {
    virtualDevice(UUID: $uuid) {
      __typename
      UUID
      label
      description
      networkUUID
      isConsoleEnabled
      deviceType
      deviceModel
      nosVersion {
        id
        buildName
        version
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }
      uptime

      ... on PowerDistributionUnitVirtualDevice {
        ipAddress
      }

      hardwareDevice {
        __typename
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        macAddress
        tunnelIPAddress
        deviceModel
        serialNumber
        deviceType
        bootHistory(count: 20) {
          bootCount
          buildName
          rebootReason
          createdAt
        }
      }
    }
  }
`);

export const PowerDistributionUnitsQuery = graphql(`
  query PowerDistributionUnitsQuery($networkUUID: UUID!, $includeIsDev: Boolean = false) {
    virtualDevicesForNetwork(
      networkUUID: $networkUUID
      filter: { deviceType: POWER_DISTRIBUTION_UNIT }
    ) {
      __typename
      UUID
      label
      description
      deviceType
      deviceModel
      nosVersion {
        id
        version
        buildName
      }
      pendingNosVersion {
        nosVersion {
          version
        }
        scheduledAt
      }
      uptime

      ... on PowerDistributionUnitVirtualDevice {
        ipAddress
      }

      hardwareDevice {
        __typename
        deviceType
        serialNumber
        macAddress
        isActive
        isConnectedToBackend
        disconnectedFromBackendAt
        bootHistory(count: 1) {
          buildName
        }
        isDev @include(if: $includeIsDev)
      }
    }
  }
`);

export const DeadMansSnitchQuery = graphql(`
  query deviceHeartbeatForDeviceQuery($serialNumber: String!) {
    deviceHeartbeatForDevice(serialNumber: $serialNumber) {
      token
    }
  }
`);

export const PowerDistributionUnitOutletsQuery = graphql(`
  query OutletsQuery($virtualDeviceUUID: UUID!) {
    outletInterfacesForVirtualDevice(virtualDeviceUUID: $virtualDeviceUUID) {
      UUID
      label
      outletNumber
      isEnabled
      powerCycleDelaySec
      description
      currentAmps
      powerWatts
      connectedVirtualDevice {
        UUID
        label
        deviceType
      }
      connectedVirtualDeviceUUID
    }
  }
`);

export type UpdateOutletInterfaceFormValues = z.input<typeof UpdateOutletInterfaceInputSchema>;

export const updateOutletInterfaceMutation = graphql(`
  mutation UpdateOutletInterfaceMutation($uuid: UUID!, $input: UpdateOutletInterfaceInput!) {
    updateOutletInterface(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

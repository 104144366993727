import { Drawer, DrawerHeader } from '@meterup/atto';
import { useState } from 'react';

import type { VPNFormData } from './form_data';
import { useCloseDrawerCallback } from '../../../../../../../hooks/useCloseDrawerCallback';
import { AddClientFormStep, Device } from './form_data';
import { OwnerStepForm, SetupStep } from './Steps';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vpn/onboarding/add',
});

export function RenderStep({
  step,
  values,
  goToNextStep,
  onChange,
}: {
  step: AddClientFormStep;
  values: VPNFormData;
  goToNextStep: () => void;
  onChange: (values: VPNFormData) => void;
}) {
  switch (step) {
    case AddClientFormStep.Owner:
      return (
        <OwnerStepForm
          defaultEmail={values.owner ?? ''}
          defaultName={values.name ?? ''}
          formValues={values}
          goToNextStep={goToNextStep}
          onChange={onChange}
        />
      );
    case AddClientFormStep.Config:
      return <SetupStep formValues={values} />;
    default:
      throw new Error('unexpected state transition');
  }
}

export default function AddClientForm() {
  const closeDrawer = useCloseDrawerCallback();

  const [step, setStep] = useState(AddClientFormStep.Owner);
  const [values, setValues] = useState<VPNFormData>({
    owner: '',
    device: Device.MacOS,
    name: '',
    public_key: '',
    private_key: '',
    sid: '',
    activated: false,
  });

  const goToNextStep = () => {
    setStep((prevStep) => {
      if (prevStep === AddClientFormStep.Owner) {
        return AddClientFormStep.Config;
      }
      throw new Error('unexpected state transition');
    });
  };

  return (
    <Drawer>
      <DrawerHeader icon="client" heading="Add client" onClose={closeDrawer} />
      <RenderStep step={step} values={values} goToNextStep={goToNextStep} onChange={setValues} />
    </Drawer>
  );
}

// eslint-disable-next-line import/no-cycle
import { route } from '../../routes';
import { RoutesType } from './routes';

export function defaultMakeRoute({ name, params }: RoutesType): string {
  if (name === 'results') {
    return route('results', { sid: params.quoteRequestSID });
  }

  return '';
}

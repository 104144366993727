import InsightsNetwork from '../../../../../components/Insights/Network/Network';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/network',
  layout: 'VirtualNetworkLayout',
  title: 'Network - Insights',
});

export default function InsightsNetworkPage() {
  return <InsightsNetwork />;
}

import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@meterup/atto';

import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { FilterBar } from '../../Insights/FilterBar';

export default function PowerDistributionUnitFilters() {
  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const timePeriodLabel = (period: string) => {
    switch (period) {
      case '1h':
        return 'Last hour';
      case '6h':
        return 'Last 6 hours';
      case '7d':
        return 'Last 7 days';
      case '30d':
        return 'Last 30 days';
      case '24h':
      default:
        return 'Last 24 hours';
    }
  };
  return (
    <FilterBar>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          arrangement="leading-icon"
          icon="clock"
          size="small"
        >
          {timePeriodLabel(currentTimePeriod)}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentTimePeriod}
            onValueChange={(val) => setCurrentTimePeriod(val)}
          >
            <DropdownMenuRadioItem value="1h">{timePeriodLabel('1h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="6h">{timePeriodLabel('6h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="24h">{timePeriodLabel('24h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="7d">{timePeriodLabel('7d')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="30d">{timePeriodLabel('30d')}</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Button variant="secondary" arrangement="hidden-label" icon="arrows-rotate" size="small">
        Refresh
      </Button>
    </FilterBar>
  );
}

import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@meterup/atto';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { FilterBar } from '../../Insights/FilterBar';
import { timePeriodLabel } from '../../Insights/Network/utils';
import { MetricBandFilter } from '../../Metrics/utils';
import { AccessPointsQuery } from '../../Wireless/utils';

export default function AccessPointsInsightsFilter() {
  const network = useNetwork();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const [currentTimePeriodOrUndefined, setCurrentTimePeriod] = useSearchParamsState<string>(
    'timePeriod',
    '24h',
  );
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const [currentAPOrUndefined, setCurrentAP] = useSearchParamsState<{
    UUID: string;
    label: string;
  }>('access-point', { UUID: 'All', label: 'All' });
  const currentAP = currentAPOrUndefined ?? { UUID: 'All', label: 'All' };

  const [currentBandOrUndefined, setCurrentBand] = useSearchParamsState<MetricBandFilter>(
    'band',
    MetricBandFilter.All,
  );
  const currentBand = currentBandOrUndefined ?? MetricBandFilter.All;

  const accessPoints = useGraphQL(AccessPointsQuery, {
    networkUUID: network.UUID,
    includeUptime,
  }).data?.virtualDevicesForNetwork;
  expectDefinedOrThrow(accessPoints, new ResourceNotFoundError('Unable to load access points'));

  const findAPByUUID = (uuid: string) =>
    accessPoints.find((ap) => ap.UUID === uuid) ?? { UUID: 'All', label: 'All' };

  return (
    <FilterBar>
      <DropdownMenu>
        <DropdownMenuButton variant="secondary" arrangement="leading-icon" size="small">
          AP: {currentAP.label}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentAP.UUID}
            onValueChange={(val) => setCurrentAP(findAPByUUID(val))}
          >
            {[{ UUID: 'All', label: 'All' }, ...accessPoints].map((ap) => (
              <DropdownMenuRadioItem value={ap.UUID} key={ap.UUID}>
                {ap.label}
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuButton variant="secondary" arrangement="leading-icon" size="small">
          Band: {currentBand === MetricBandFilter.All ? currentBand : `${currentBand} GHz`}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentBand}
            onValueChange={(val) => setCurrentBand(val as MetricBandFilter)}
          >
            <DropdownMenuRadioItem value={MetricBandFilter.All}>All</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value={MetricBandFilter.Band2_4}>2.4 GHz</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value={MetricBandFilter.Band5}>5 GHz</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          arrangement="leading-icon"
          icon="clock"
          size="small"
        >
          {timePeriodLabel(currentTimePeriod)}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentTimePeriod}
            onValueChange={(val) => setCurrentTimePeriod(val)}
          >
            <DropdownMenuRadioItem value="1h">{timePeriodLabel('1h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="6h">{timePeriodLabel('6h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="24h">{timePeriodLabel('24h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="7d">{timePeriodLabel('7d')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="30d">{timePeriodLabel('30d')}</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      <Button variant="secondary" arrangement="hidden-label" icon="arrows-rotate" size="small">
        Refresh
      </Button>
    </FilterBar>
  );
}

import { Badge, Button, EmptyState, HStack, Small, space } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { capitalize } from 'lodash-es';
import { Suspense } from 'react';
import { Link } from 'react-router-dom';

import type { RadioProfilesQueryResult } from './utils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { NoValue } from '../../NoValue';
import { createColumnBuilder } from '../../Table/createColumnBuilder';

enum BandMode {
  Disabled = 'disabled',
  Auto = 'auto',
  Manual = 'manual',
}

interface RadioProfileSubtableRow {
  name: string;
  band2_4mode?: BandMode;
  band2_4text?: string;
  band5mode?: BandMode;
  band5text?: string;
}

function Band({ mode, text }: { mode?: BandMode; text?: string }) {
  if (mode === BandMode.Disabled) return <NoValue />;

  return (
    <HStack spacing={space(6)} align="center">
      {mode && (
        <Badge size="small" variant={mode === BandMode.Auto ? 'brand' : 'neutral'}>
          {capitalize(mode)}
        </Badge>
      )}
      {text && <Small>{text}</Small>}
    </HStack>
  );
}

const builder = createColumnBuilder<RadioProfilesQueryResult>();

const columns = [
  builder.data((profile) => profile.name, {
    id: 'profile-name',
    header: 'Profile',
    meta: {
      isLeading: true,
    },
    cell: ({ row }) => (
      <HStack spacing={space(6)} align="center">
        {row.name}
        {row.isDefault && (
          <Badge size="small" variant="neutral">
            Default
          </Badge>
        )}
      </HStack>
    ),
  }),
  builder.data((profile) => (profile.virtualDevices.length ?? 0).toString(), {
    id: 'devices',
    header: 'Devices',
  }),
];

const subtableBuilder = createColumnBuilder<RadioProfileSubtableRow>();

const subtableColumns = [
  subtableBuilder.data((row) => row.name, {
    id: 'profile-name',
    header: '',
    meta: {
      isLeading: true,
      minWidth: 200,
    },
    cell: ({ value }) => <Small>{value}</Small>,
  }),
  subtableBuilder.data((row) => row.band2_4mode, {
    id: 'band24-mode',
    header: '2.4 GHz',
    meta: {
      minWidth: 200,
    },
    cell: ({ row }) => <Band mode={row.band2_4mode} text={row.band2_4text} />,
  }),
  subtableBuilder.data((row) => row.band5mode, {
    id: 'band5-mode',
    header: '5 GHz',
    meta: {
      minWidth: 200,
    },
    cell: ({ row }) => <Band mode={row.band5mode} text={row.band5text} />,
  }),
];

const bandMode = (enabled: boolean, auto: boolean) => {
  if (!enabled) return BandMode.Disabled;
  if (auto) return BandMode.Auto;
  return BandMode.Manual;
};

const bandTextForPower = (
  enabled: boolean,
  autoEnabled: boolean,
  min: number | undefined | null,
  max: number | undefined | null,
) => {
  if (!enabled || !autoEnabled || !min || !max) return undefined;
  return `${min} / ${max}`;
};

const bandTextForWidth = (
  enabled: boolean,
  autoEnabled: boolean,
  width: number | undefined | null,
) => {
  if (!enabled || autoEnabled || !width) return undefined;
  return `${width} Mhz`;
};

function Subtable({ data: profile, ...rest }: { data: RadioProfilesQueryResult }) {
  const [acs, width, power] = useNosFeaturesEnabled([
    NosFeature.WOS_ACS,
    NosFeature.WOS_AUTO_CHANNEL_WIDTH,
    NosFeature.WOS_AUTO_TRANSMIT_POWER,
  ]);

  const data: RadioProfileSubtableRow[] = [];

  if (acs) {
    data.push({
      name: 'Channel assignment',
      band2_4mode: bandMode(profile.band2_4GIsEnabled, profile.band2_4GAutoChannelIsEnabled),
      band5mode: bandMode(profile.band5GIsEnabled, profile.band5GAutoChannelIsEnabled),
    });
  }

  if (width) {
    data.push({
      name: 'Channel width',
      band2_4mode: bandMode(profile.band2_4GIsEnabled, profile.band2_4GAutoChannelWidthIsEnabled),
      band2_4text: bandTextForWidth(
        profile.band2_4GIsEnabled,
        profile.band2_4GAutoChannelWidthIsEnabled,
        profile.band2_4GChannelWidthMHz,
      ),
      band5mode: bandMode(profile.band5GIsEnabled, profile.band5GAutoChannelWidthIsEnabled),
      band5text: bandTextForWidth(
        profile.band5GIsEnabled,
        profile.band5GAutoChannelWidthIsEnabled,
        profile.band5GChannelWidthMHz,
      ),
    });
  }

  if (power) {
    data.push({
      name: 'Power min / max (dBm)',
      band2_4mode: bandMode(profile.band2_4GIsEnabled, profile.band2_4GAutoTxPowerIsEnabled),
      band2_4text: bandTextForPower(
        profile.band2_4GIsEnabled,
        profile.band2_4GAutoTxPowerIsEnabled,
        profile.band2_4GAutoTxPowerMindBm,
        profile.band2_4GAutoTxPowerMaxdBm,
      ),
      band5mode: bandMode(profile.band5GIsEnabled, profile.band5GAutoTxPowerIsEnabled),
      band5text: bandTextForPower(
        profile.band5GIsEnabled,
        profile.band5GAutoTxPowerIsEnabled,
        profile.band5GAutoTxPowerMindBm,
        profile.band5GAutoTxPowerMaxdBm,
      ),
    });
  }

  return <AutoTable {...rest} data={data} columns={subtableColumns} isNested />;
}

function renderSubTable(subAutoTableProps: { data: RadioProfilesQueryResult }) {
  return (
    <Suspense fallback={null}>
      <Subtable {...subAutoTableProps} />
    </Suspense>
  );
}

export default function RadioProfileListACSEnabled({
  profiles,
}: {
  profiles: RadioProfilesQueryResult[];
}) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const closeDrawer = useCloseDrawerCallback();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.RadioProfileEditPage);
  const { hasPermission } = usePermissions();
  const canEditRadioProfiles = hasPermission(PermissionType.PermRadioProfileWrite);

  if (profiles.length === 0) {
    return (
      <EmptyState
        icon="width"
        heading="No radio profiles"
        action={
          canEditRadioProfiles ? (
            <Button
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.RadioProfileCreatePage, {
                companyName,
                networkSlug: network.slug,
              })}
              arrangement="leading-icon"
              icon="plus"
            >
              Add radio profile
            </Button>
          ) : undefined
        }
      />
    );
  }

  return (
    <AutoTable
      key={JSON.stringify(profiles)}
      data={profiles}
      columns={columns}
      onRowDeselect={closeDrawer}
      isRowSelected={(r) => r.UUID === drawerParams?.uuid}
      getLinkTo={(row) =>
        canEditRadioProfiles
          ? makeDrawerLink(window.location, paths.drawers.RadioProfileEditPage, {
              companyName,
              networkSlug: network.slug,
              uuid: row.UUID,
            })
          : undefined
      }
      size="small"
      renderSubTable={renderSubTable}
    />
  );
}

import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  PrimaryField,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import { checkDefinedOrThrow, notify } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import { upsertMACAddressAliasMutation } from '../../../../../components/Clients/utils';
import { FieldProvider } from '../../../../../components/Form/FieldProvider';
import { paths } from '../../../../../constants';
import { getNetworkClients } from '../../../../../hooks/networkClients/useNetworkClients';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { useFilteredNetworkClients } from '../../../../pages/network/insights/clients/clients.hooks';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/clients/:macAddress/rename',
  title: 'Rename client - Client - Clients - Insights',
});

const upsertMACAddressAliasSchema = z.object({
  alias: z.string(),
  macAddress: z.string(),
  companySlug: z.string(),
});

export default function ClientRename2Page() {
  const { macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ClientRename2Page),
  );

  const queryClient = useQueryClient();
  const mutation = useGraphQLMutation(upsertMACAddressAliasMutation);
  const companySlug = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();

  const { clients } = useFilteredNetworkClients({
    network,
  });
  const alias = clients.find((client) => client.macAddress === macAddress)?.alias;

  return (
    <Drawer>
      <Formik<z.infer<typeof upsertMACAddressAliasSchema>>
        initialValues={{
          companySlug,
          macAddress,
          alias: alias ?? '',
        }}
        validate={withZodSchema(upsertMACAddressAliasSchema)}
        onSubmit={(values) => {
          if (mutation.isLoading) return;

          mutation.mutate(values, {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(getNetworkClients, { networkUUID: network.UUID }),
              );
              navigate(
                makeDrawerLink(window.location, paths.drawers.ClientSummary2Page, {
                  companyName: companySlug,
                  networkSlug: network.slug,
                  macAddress,
                }),
              );
              notify('Successfully renamed client.', { variant: 'positive' });
            },
          });
        }}
      >
        <Form>
          <DrawerHeader icon="pencil" heading="Rename client" onClose={useCloseDrawerCallback()} />
          <DrawerContent>
            <VStack spacing={space(16)}>
              {mutation.isError && mutation.error && (
                <Alert
                  heading="Error renaming client"
                  copy={getGraphQLError(mutation.error)?.message ?? 'Unknown error'}
                  variant="negative"
                />
              )}
              <FieldContainer>
                <FieldProvider name="alias">
                  <PrimaryField label="Alias" element={<TextInput />} />
                </FieldProvider>
              </FieldContainer>
            </VStack>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" disabled={mutation.isLoading} loading={mutation.isLoading}>
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';

import { Body, Heading, styled, VStack } from '@meterup/atto';
import { colors, darkThemeSelector } from '@meterup/atto/src/stitches.config';
import useConnectionsQueryKey from '@meterup/connect-api/src/hooks/useConnectionsQueryKey';
import AddLocation from '@meterup/connect-ui/src/components/AddLocation';
import { log } from '@meterup/connect-ui/src/Log.utils';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import * as ProviderSVGs from '../providers/svgs';

const GetStartedWrapper = styled('div', {
  display: 'flex',
  height: '100%',
});

const GetStartedInner = styled('div', {
  maxWidth: '65%',
});

const GetStartedPanel = styled('div', {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    side: {
      right: {
        strokeAll: colors.strokeApplicationLight,
        [darkThemeSelector]: {
          outlineColor: colors.strokeApplicationDark,
        },
      },
    },
  },
});

const GetStartedCopy = styled(VStack, {
  gap: '$32',
  padding: '0 $20',
});

export default function NoLocations() {
  const { companyName, companySID: companySIDInner } = useParams();
  const companySID = companySIDInner || companyName || '';
  const queryClient = useQueryClient();
  const queryKey = useConnectionsQueryKey();
  const onSuccess = useCallback(async () => {
    await queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);
  log('Keys', Object.keys(ProviderSVGs));

  return (
    <GetStartedWrapper>
      <GetStartedPanel>
        <GetStartedInner>
          <GetStartedCopy>
            <Heading>Get started</Heading>
            <Body weight="bold">Add your first location</Body>
          </GetStartedCopy>
          <AddLocation
            buttonText="Create dashboard"
            companySID={companySID}
            dropdownPlaceholder="Enter your business address"
            emptyFieldDescription="A location is used to automate quoting and installs, manage existing contracts, renewals, and billing."
            onSuccess={onSuccess}
          />
        </GetStartedInner>
      </GetStartedPanel>
      <GetStartedPanel side="right" />
    </GetStartedWrapper>
  );
}

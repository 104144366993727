import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

export enum StatusTabItems {
  Active = 'active',
  All = 'all',
  Contracts = 'contracts',
  Installs = 'installs',
  NeedsAttention = 'needs_attention',
  Quotes = 'quotes',
}
export type StatusTabItemsType = typeof StatusTabItems;
export type StatusTabItemKeys = keyof StatusTabItemsType;
export type StatusTabItemValues = (typeof StatusTabItems)[keyof typeof StatusTabItems];
export type StatusTabItemsByValType = { [key in StatusTabItemValues]: StatusTabItemKeys };
export const StatusTabItemsByVal: StatusTabItemsByValType = Object.keys(StatusTabItems).reduce(
  (acc, key) => {
    acc[StatusTabItems[key as StatusTabItemKeys] as StatusTabItemValues] = key as StatusTabItemKeys;
    return acc;
  },
  {} as StatusTabItemsByValType,
);
export const QueryParam = 'status';
export default function useActiveStatusTab() {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const filterValue = searchParams.get(QueryParam);
    let activeTabValue = StatusTabItems.All;
    if (filterValue && activeTabValue in StatusTabItemsByVal) {
      activeTabValue = filterValue as StatusTabItemValues;
    }
    return activeTabValue;
  }, [searchParams]);
}
export const VisibleTabs = [
  StatusTabItems.All,
  StatusTabItems.Contracts,
  StatusTabItems.Installs,
  StatusTabItems.Quotes,
] as const;

import { colors } from '@meterup/atto';
import { useContext, useEffect, useRef } from 'react';

import type { UptimeForInterface } from '../useUptimeStats';
import { ThemeContext } from '../../../providers/ThemeProvider';

const bgColorsLight = {
  good: colors.iconPositiveLight,
  medium: colors.iconNegativeLight,
  bad: colors.iconNegativeLight,
};

const bgColorsDark = {
  good: colors.iconPositiveDark,
  medium: colors.iconNegativeDark,
  bad: colors.iconNegativeDark,
};

export const poorQualityWaterMark = 98.0;
export const badQualityWaterMark = 95.0;

function paintChart(
  _ctx: CanvasRenderingContext2D,
  stats: UptimeForInterface['stats'],
  params: { height: number; width: number; isDarkModeEnabled: boolean },
) {
  const { height, width, isDarkModeEnabled } = params;
  const ctx = _ctx; // to stop eslint from complaining about assignment to a parameter
  ctx.clearRect(0, 0, width, height); // clear the canvas
  ctx.lineWidth = 1.5; // Bar width

  function drawBar({
    startX,
    barHeight,
    color,
  }: {
    startX: number;
    barHeight: number;
    color: string;
  }) {
    ctx.strokeStyle = color; // Line color
    ctx.beginPath();

    // Define the start point and the end point of the line

    ctx.moveTo(startX, height); // Start at the bottom of the canvas

    ctx.lineTo(startX, height - barHeight); // 0 is top of the canvas

    // Draw the line
    ctx.stroke();
  }

  const lineGap = width / stats.length;

  const baseColor = isDarkModeEnabled ? colors.tokenBgNeutralDark : colors.tokenBgNeutralLight;

  let x = 1; // w / 2 would draw the line vertically in the middle

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < stats.length; i++) {
    const stat = stats[i];

    const barHeight = stat.value * height;

    drawBar({ startX: x, barHeight: height, color: baseColor.value });

    let quality: 'good' | 'medium' | 'bad' = 'good';
    const value = stat.value * 100.0;
    if (value < poorQualityWaterMark) {
      quality = 'medium';
    }
    if (value < badQualityWaterMark) {
      quality = 'bad';
    }

    const lineColor = isDarkModeEnabled ? bgColorsDark[quality] : bgColorsLight[quality];

    drawBar({ startX: x, barHeight, color: lineColor.value });

    x += lineGap;
  }
}

export function CanvasChart({
  stats,
  width,
}: {
  stats: UptimeForInterface['stats'];
  width: number;
}) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { dark } = useContext(ThemeContext);

  useEffect(() => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    const h = 22;
    const w = width;

    canvasRef.current.height = h;
    canvasRef.current.width = w;

    canvasRef.current.style.width = `${w}px`;
    canvasRef.current.style.height = `${h}px`;

    // for Retina displays
    if (window.devicePixelRatio) {
      const scale = window.devicePixelRatio * 4;
      canvasRef.current.height = h * scale;
      canvasRef.current.width = w * scale;
      ctx.scale(scale, scale);
    }

    paintChart(ctx, stats, { width: w, height: h, isDarkModeEnabled: dark });
  }, [width, stats, dark]);

  return <canvas ref={canvasRef} style={{ backgroundColor: '' }} />;
}

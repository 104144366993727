import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@meterup/atto';

import { timePeriodLabel } from '../utils/chart_utils';
import SeriesFilter from './Charts/SeriesFilter';
import { FilterBar, StickyFilterBar } from './Insights/FilterBar';

export default function ChartFilter({
  currentTimePeriod,
  setCurrentTimePeriod,
  refetchMetrics,
  seriesIds,
  sticky = true,
}: {
  currentTimePeriod: string;
  setCurrentTimePeriod: (time: string) => void;
  refetchMetrics: () => void;
  seriesIds?: string[]; // Necessary for SeriesFilter
  sticky?: boolean;
}) {
  const FilterBarElement = sticky ? StickyFilterBar : FilterBar;
  return (
    <FilterBarElement>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          arrangement="leading-icon"
          icon="clock"
          size="small"
        >
          {timePeriodLabel(currentTimePeriod)}
        </DropdownMenuButton>
        <DropdownMenuPopover>
          <DropdownMenuRadioGroup
            value={currentTimePeriod}
            onValueChange={(val) => setCurrentTimePeriod(val)}
          >
            <DropdownMenuRadioItem value="1h">{timePeriodLabel('1h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="6h">{timePeriodLabel('6h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="24h">{timePeriodLabel('24h')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="7d">{timePeriodLabel('7d')}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="30d">{timePeriodLabel('30d')}</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuPopover>
      </DropdownMenu>
      {seriesIds && <SeriesFilter seriesIds={seriesIds} selectionLabel="ports selected" />}
      <Button
        variant="secondary"
        arrangement="hidden-label"
        icon="arrows-rotate"
        onClick={refetchMetrics}
        size="small"
      >
        Refresh
      </Button>
    </FilterBarElement>
  );
}

import {
  Alert,
  Button,
  Column,
  Columns,
  ControlGroup,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DrawerContent,
  EmptyState,
  FieldContainer,
  FloatingField,
  Section,
  SectionContent,
  SectionHeader,
  space,
  Table,
  TableBody,
  TableCell,
  TableCellBuffer,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  Text,
  TextInput,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { z } from 'zod';
import { toFormikValidate } from 'zod-formik-adapter';

import type {
  DHCPOption,
  DHCPReservedRange,
  DHCPRule,
  DHCPStaticMapping,
  VLAN,
  VLANWithDHCPRule,
  VLANWithStaticSubnet,
} from '../utils';
import {
  type CreateDhcpOptionInput,
  type CreateDhcpRuleInput,
  type CreateDhcpStaticMappingInput,
  type UpdateDhcpOptionInput,
  type UpdateDhcpRuleInput,
  type UpdateDhcpStaticMappingInput,
  PermissionType,
} from '../../../../gql/graphql';
import {
  CreateDhcpOptionInputSchema,
  CreateDhcpReservedRangeInputSchema,
  CreateDhcpRuleInputSchema,
  CreateDhcpStaticMappingInputSchema,
} from '../../../../gql/zod-types';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { FieldProvider, NumberFieldProvider } from '../../../Form/FieldProvider';
import { useIsPermitted } from '../../../permissions/useIsPermitted';
import {
  createDHCPOption,
  createDHCPReservedRange,
  createDHCPRuleMutation,
  createDHCPStaticMapping,
  deleteDHCPOption,
  deleteDHCPReservedRange,
  deleteDHCPRuleMutation,
  deleteDHCPStaticMapping,
  deriveDefaultDHCPIPRangeFromSubnet,
  dnsSearchDomainsSchema,
  leaseDurationHoursSchema,
  updateDHCPOption,
  updateDHCPReservedRange,
  updateDHCPRuleMutation,
  updateDHCPStaticMapping,
  vlanHasDHCPRule,
  vlanHasStaticIP,
  vlanQuery,
  vlansQuery,
} from '../utils';
import { VLANDHCPSummary } from '../VLANDrawer';
import { VLANDHCPFields } from '../VLANForm';
import { FormlessResetButton, FormlessSubmitButton, VLANDetailsDrawerFooter } from './Common';

const dhcpReservedRangeValuesSchema = CreateDhcpReservedRangeInputSchema.extend({
  startIPAddress: z.string().ip({ message: 'Please enter a valid IP address.' }), // TODO: validate in DHCP range
  endIPAddress: z.string().ip({ message: 'Please enter a valid IP address.' }), // TODO: validate in dhcp range
});

type DHCPReservedRangeValues = z.input<typeof dhcpReservedRangeValuesSchema>;

function DHCPReservedRangeRow({
  rule,
  reservedRange,
  onSuccess,
}: {
  rule: DHCPRule;
  reservedRange?: DHCPReservedRange;
  onSuccess: () => void;
}) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });
  const createReservedRange = useGraphQLMutation(createDHCPReservedRange);
  const updateReservedRange = useGraphQLMutation(updateDHCPReservedRange);
  const deleteReservedRange = useGraphQLMutation(deleteDHCPReservedRange);

  const { state } = useDialogState();
  const { close: closeDialog } = state;

  const handleDelete = useCallback(() => {
    if (!reservedRange?.UUID) return;

    deleteReservedRange.mutate(
      { uuid: reservedRange.UUID },
      {
        onSuccess: () => {
          notify('Successfully deleted DHCP reserved range.', {
            variant: 'positive',
          });
          closeDialog();
          onSuccess();
        },
        onError: (err) => {
          notify(
            `There was a problem deleting this DHCP reserved range${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [reservedRange?.UUID, deleteReservedRange, onSuccess, closeDialog]);

  const handleSubmit = useCallback(
    (input: DHCPReservedRangeValues) => {
      if (reservedRange?.UUID) {
        updateReservedRange.mutate(
          { uuid: reservedRange.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully updated DHCP reserved range.', {
                variant: 'positive',
              });
              onSuccess();
            },
            onError: (err) => {
              notify(
                `There was a problem updating this DHCP reserved range${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        createReservedRange.mutate(
          { ruleUUID: rule.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully created DHCP reserved range.', {
                variant: 'positive',
              });
              onSuccess();
            },
            onError: (err) => {
              notify(
                `There was a problem creating this DHCP reserved range${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [rule.UUID, reservedRange?.UUID, updateReservedRange, createReservedRange, onSuccess],
  );

  return (
    <>
      <Formik<DHCPReservedRangeValues>
        initialValues={{
          startIPAddress: reservedRange?.startIPAddress ?? '',
          endIPAddress: reservedRange?.endIPAddress ?? '',
        }}
        validate={toFormikValidate(dhcpReservedRangeValuesSchema)}
        onSubmit={handleSubmit}
      >
        <TableRow role="form" aria-label="Edit DHCP reserved range">
          <TableCellBuffer side="leading" />
          <TableCell>
            <FieldContainer>
              <FieldProvider name="startIPAddress">
                <FloatingField
                  label="Start IP address"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell>
            <FieldContainer>
              <FieldProvider name="endIPAddress">
                <FloatingField
                  label="End IP address"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          {canEditDHCPDNS && (
            <TableCell>
              <ControlGroup size="small">
                <FormlessResetButton
                  icon="arrows-rotate"
                  arrangement="hidden-label"
                  variant="secondary"
                  disabledIfClean
                >
                  Reset reserved range
                </FormlessResetButton>
                <FormlessSubmitButton
                  variant="primary"
                  icon="checkmark"
                  arrangement="hidden-label"
                  disabledIfClean
                >
                  Save reserved range
                </FormlessSubmitButton>
                {reservedRange && (
                  <Button
                    onClick={state.open}
                    variant="destructive"
                    icon="trash-can"
                    arrangement="hidden-label"
                  >
                    Delete reserved range
                  </Button>
                )}
              </ControlGroup>
            </TableCell>
          )}
          <TableCellBuffer side="trailing" />
        </TableRow>
      </Formik>
      {reservedRange && canEditDHCPDNS && (
        <Dialog state={state} preset="narrow">
          <DialogHeader icon="trash-can" heading="Delete DHCP reservedRange" />
          <DialogContent gutter="all">
            <Alert
              icon="information"
              variant="neutral"
              copy={
                <p>
                  You're about to remove the DHCP reserved range{' '}
                  <Text family="monospace">{reservedRange.startIPAddress}</Text>
                  {' - '}
                  <Text family="monospace">{reservedRange.endIPAddress}</Text>.
                </p>
              }
            />
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button onClick={state.close} variant="secondary">
                  Cancel
                </Button>
                <Button onClick={handleDelete} variant="destructive">
                  Delete
                </Button>
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
}

function VLANDHCPReservedRangesSection({
  vlan,
  onSuccess,
}: {
  vlan: VLANWithDHCPRule;
  onSuccess: () => void;
}) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });
  const sortedRanges = useMemo(() => {
    const ranges = vlan.dhcpRule.reservedRanges.slice();
    ranges.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    return ranges;
  }, [vlan.dhcpRule.reservedRanges]);

  return (
    <Section relation="stacked">
      <SectionHeader heading="Reserved ranges" />
      <SectionContent gutter="none">
        {canEditDHCPDNS || sortedRanges.length > 0 ? (
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableCellBuffer side="leading" head />
                <TableHeadCell>Start address</TableHeadCell>
                <TableHeadCell>End address</TableHeadCell>
                {canEditDHCPDNS && <TableHeadCell aria-label="Actions" style={{ width: '82px' }} />}
                <TableCellBuffer side="trailing" head />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {sortedRanges.map((reservedRange) => (
                <DHCPReservedRangeRow
                  key={reservedRange.UUID}
                  rule={vlan.dhcpRule}
                  reservedRange={reservedRange}
                  onSuccess={onSuccess}
                />
              ))}
              {canEditDHCPDNS && (
                <DHCPReservedRangeRow
                  key={sortedRanges.length}
                  rule={vlan.dhcpRule}
                  onSuccess={onSuccess}
                />
              )}
            </TableBody>
          </Table>
        ) : (
          <EmptyState heading="No reserved ranges configured" />
        )}
      </SectionContent>
    </Section>
  );
}

export const dhcpStaticMappingValuesSchema = CreateDhcpStaticMappingInputSchema.extend({
  macAddress: z.string().nonempty({ message: 'Please enter a valid MAC address.' }), // TODO: validate MAC address
  ipAddress: z.string().ip({ message: 'Please enter a valid IP address.' }), // TODO validate IP in DHCP range
  hostname: z.string().nullish(), // TODO: validate hostname
});

export type DHCPStaticMappingValues = z.input<typeof dhcpStaticMappingValuesSchema>;

function DHCPStaticMappingRow({
  rule,
  staticMapping,
  onSuccess,
}: {
  rule: DHCPRule;
  staticMapping?: DHCPStaticMapping;
  onSuccess: () => void;
}) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });
  const createStaticMapping = useGraphQLMutation(createDHCPStaticMapping);
  const updateStaticMapping = useGraphQLMutation(updateDHCPStaticMapping);
  const deleteStaticMapping = useGraphQLMutation(deleteDHCPStaticMapping);

  const { state } = useDialogState();
  const { close: closeDialog } = state;

  const handleDelete = useCallback(() => {
    if (!staticMapping?.UUID) return;

    deleteStaticMapping.mutate(
      { uuid: staticMapping.UUID },
      {
        onSuccess: () => {
          notify('Successfully deleted DHCP static mapping.', {
            variant: 'positive',
          });
          closeDialog();
          onSuccess();
        },
        onError: (err) => {
          notify(
            `There was a problem deleting this DHCP static mapping${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [staticMapping?.UUID, deleteStaticMapping, onSuccess, closeDialog]);

  const handleSubmit = useCallback(
    ({ name, hostname, ...values }: DHCPStaticMappingValues) => {
      if (staticMapping?.UUID) {
        const input: UpdateDhcpStaticMappingInput = {
          ...values,
          name: name || null,
          hostname: hostname || null,
        };

        updateStaticMapping.mutate(
          { uuid: staticMapping.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully updated DHCP static mapping.', {
                variant: 'positive',
              });
              onSuccess();
            },
            onError: (err) => {
              notify(
                `There was a problem updating this DHCP static mapping${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateDhcpStaticMappingInput = {
          ...values,
          name: name || null,
          hostname: hostname || null,
        };

        createStaticMapping.mutate(
          { ruleUUID: rule.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully created DHCP static mapping.', {
                variant: 'positive',
              });
              onSuccess();
            },
            onError: (err) => {
              notify(
                `There was a problem creating this DHCP static mapping${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [rule.UUID, staticMapping?.UUID, updateStaticMapping, createStaticMapping, onSuccess],
  );

  return (
    <>
      <Formik<DHCPStaticMappingValues>
        initialValues={{
          name: staticMapping?.name ?? '',
          macAddress: staticMapping?.macAddress ?? '',
          ipAddress: staticMapping?.ipAddress ?? '',
          hostname: staticMapping?.hostname ?? '',
        }}
        validate={toFormikValidate(dhcpStaticMappingValuesSchema)}
        onSubmit={handleSubmit}
      >
        <TableRow role="form" aria-label="Edit DHCP static mapping">
          <TableCellBuffer side="leading" />
          <TableCell>
            <FieldContainer>
              <FieldProvider name="name">
                <FloatingField
                  label="Name"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell>
            <FieldContainer>
              <FieldProvider name="macAddress">
                <FloatingField
                  label="MAC"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell>
            <FieldContainer>
              <FieldProvider name="ipAddress">
                <FloatingField
                  label="IP address"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell>
            <FieldContainer>
              <FieldProvider name="hostname">
                <FloatingField
                  label="Hostname"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          {canEditDHCPDNS && (
            <TableCell>
              <ControlGroup size="small">
                <FormlessResetButton
                  variant="secondary"
                  arrangement="hidden-label"
                  icon="arrows-rotate"
                  disabledIfClean
                >
                  Reset static mapping
                </FormlessResetButton>
                <FormlessSubmitButton
                  variant="primary"
                  arrangement="hidden-label"
                  icon="checkmark"
                  disabledIfClean
                >
                  Save static mapping
                </FormlessSubmitButton>
                {staticMapping && (
                  <Button
                    onClick={state.open}
                    variant="destructive"
                    icon="trash-can"
                    arrangement="hidden-label"
                  >
                    Delete static mapping
                  </Button>
                )}
              </ControlGroup>
            </TableCell>
          )}
          <TableCellBuffer side="trailing" />
        </TableRow>
      </Formik>
      {staticMapping && canEditDHCPDNS && (
        <Dialog state={state} preset="narrow">
          <DialogHeader icon="trash-can" heading="Delete DHCP static mapping" />
          <DialogContent gutter="all">
            <Alert
              icon="information"
              variant="neutral"
              copy={
                <p>
                  You're about to remove the DHCP static mapping{' '}
                  {staticMapping.name ? (
                    <Text weight="bold">{staticMapping.name}</Text>
                  ) : (
                    <>
                      for MAC address
                      <Text family="monospace">{staticMapping.macAddress}</Text>
                    </>
                  )}
                  .
                </p>
              }
            />
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button onClick={state.close} variant="secondary">
                  Cancel
                </Button>
                <Button onClick={handleDelete} variant="destructive">
                  Delete
                </Button>
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
}

function VLANDHCPStaticMappingsSection({
  vlan,
  onSuccess,
}: {
  vlan: VLANWithDHCPRule;
  onSuccess: () => void;
}) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });

  const sortedMappings = useMemo(() => {
    const mappings = vlan.dhcpRule.staticMappings.slice();
    mappings.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    return mappings;
  }, [vlan.dhcpRule.staticMappings]);

  return (
    <Section relation="stacked">
      <SectionHeader heading="Static mappings" />
      <SectionContent gutter="none">
        {canEditDHCPDNS || sortedMappings.length > 0 ? (
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableCellBuffer side="leading" head />
                <TableHeadCell>Name</TableHeadCell>
                <TableHeadCell>MAC address</TableHeadCell>
                <TableHeadCell>IP address</TableHeadCell>
                <TableHeadCell>Hostname</TableHeadCell>
                {canEditDHCPDNS && <TableHeadCell aria-label="Actions" style={{ width: '82px' }} />}
                <TableCellBuffer side="trailing" head />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {sortedMappings.map((staticMapping) => (
                <DHCPStaticMappingRow
                  key={staticMapping.UUID}
                  rule={vlan.dhcpRule}
                  staticMapping={staticMapping}
                  onSuccess={onSuccess}
                />
              ))}
              {canEditDHCPDNS && (
                <DHCPStaticMappingRow
                  key={sortedMappings.length}
                  rule={vlan.dhcpRule}
                  onSuccess={onSuccess}
                />
              )}
            </TableBody>
          </Table>
        ) : (
          <EmptyState heading="No static mappings configured" />
        )}
      </SectionContent>
    </Section>
  );
}

const dhcpOptionValuesSchema = CreateDhcpOptionInputSchema.extend({
  code: z
    .number({ invalid_type_error: 'Please enter a positive number code.' })
    .positive({ message: 'Please enter a positive number code. ' }),
});

type DHCPOptionValues = z.input<typeof dhcpOptionValuesSchema>;

function DHCPOptionRow({
  rule,
  option,
  onSuccess,
}: {
  rule: DHCPRule;
  option?: DHCPOption;
  onSuccess: () => void;
}) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });
  const createOption = useGraphQLMutation(createDHCPOption);
  const updateOption = useGraphQLMutation(updateDHCPOption);
  const deleteOption = useGraphQLMutation(deleteDHCPOption);

  const { state } = useDialogState();
  const { close: closeDialog } = state;

  const handleDelete = useCallback(() => {
    if (!option?.UUID) return;

    deleteOption.mutate(
      { uuid: option.UUID },
      {
        onSuccess: () => {
          notify('Successfully deleted DHCP option.', {
            variant: 'positive',
          });
          closeDialog();
          onSuccess();
        },
        onError: (err) => {
          notify(
            `There was a problem deleting this DHCP option${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [option?.UUID, deleteOption, onSuccess, closeDialog]);

  const handleSubmit = useCallback(
    ({ code, data, description }: DHCPOptionValues) => {
      if (option?.UUID) {
        const input: UpdateDhcpOptionInput = {
          data,
          description,
        };
        updateOption.mutate(
          { uuid: option.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully updated DHCP option.', {
                variant: 'positive',
              });
              onSuccess();
            },
            onError: (err) => {
              notify(
                `There was a problem updating this DHCP option${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateDhcpOptionInput = {
          code,
          data,
          description,
        };
        createOption.mutate(
          { ruleUUID: rule.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully created DHCP option.', {
                variant: 'positive',
              });
              onSuccess();
            },
            onError: (err) => {
              notify(
                `There was a problem creating this DHCP option${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [rule.UUID, option?.UUID, updateOption, createOption, onSuccess],
  );

  return (
    <>
      <Formik<DHCPOptionValues>
        initialValues={{
          code: option?.code ?? 0,
          data: option?.data ?? '',
          description: option?.description ?? '',
        }}
        validate={toFormikValidate(dhcpOptionValuesSchema)}
        onSubmit={handleSubmit}
      >
        <TableRow role="form" aria-label="Edit DHCP option">
          <TableCellBuffer side="leading" />
          <TableCell>
            <FieldContainer>
              <NumberFieldProvider name="code" defaultValue={null}>
                <FloatingField
                  label="Code"
                  element={<TextInput controlSize="small" disabled={!!option || !canEditDHCPDNS} />}
                />
              </NumberFieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell>
            <FieldContainer>
              <FieldProvider name="data">
                <FloatingField
                  label="Data"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          <TableCell>
            <FieldContainer>
              <FieldProvider name="description">
                <FloatingField
                  label="Description"
                  element={<TextInput controlSize="small" disabled={!canEditDHCPDNS} />}
                />
              </FieldProvider>
            </FieldContainer>
          </TableCell>
          {canEditDHCPDNS && (
            <TableCell>
              <ControlGroup size="small">
                <FormlessResetButton
                  variant="secondary"
                  arrangement="hidden-label"
                  icon="arrows-rotate"
                  disabledIfClean
                >
                  Reset option
                </FormlessResetButton>
                <FormlessSubmitButton
                  variant="primary"
                  arrangement="hidden-label"
                  icon="checkmark"
                  disabledIfClean
                >
                  Save option
                </FormlessSubmitButton>
                {option && (
                  <Button
                    onClick={state.open}
                    variant="destructive"
                    icon="trash-can"
                    arrangement="hidden-label"
                  >
                    Delete option
                  </Button>
                )}
              </ControlGroup>
            </TableCell>
          )}
          <TableCellBuffer side="trailing" />
        </TableRow>
      </Formik>
      {option && canEditDHCPDNS && (
        <Dialog state={state} preset="narrow">
          <DialogHeader icon="trash-can" heading="Delete DHCP option" />
          <DialogContent gutter="all">
            <Alert
              icon="information"
              variant="neutral"
              copy={
                <p>
                  You're about to remove the DHCP option with code{' '}
                  <Text family="monospace">{option.code}</Text>.
                </p>
              }
            />
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button onClick={state.close} variant="secondary">
                  Cancel
                </Button>
                <Button onClick={handleDelete} variant="destructive">
                  Delete
                </Button>
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
}

function VLANDHCPOptionsSection({
  vlan,
  onSuccess,
}: {
  vlan: VLANWithDHCPRule;
  onSuccess: () => void;
}) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });
  const sortedOptions = useMemo(() => {
    const options = vlan.dhcpRule.options.slice();
    options.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    return options;
  }, [vlan.dhcpRule.options]);

  return (
    <Section relation="stacked">
      <SectionHeader heading="Options" />
      <SectionContent gutter="none">
        {canEditDHCPDNS || sortedOptions.length > 0 ? (
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableCellBuffer side="leading" head />
                <TableHeadCell>Code</TableHeadCell>
                <TableHeadCell>Data</TableHeadCell>
                <TableHeadCell>Description</TableHeadCell>
                {canEditDHCPDNS && <TableHeadCell aria-label="Actions" style={{ width: '82px' }} />}
                <TableCellBuffer side="trailing" head />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {sortedOptions.map((option) => (
                <DHCPOptionRow
                  key={option.UUID}
                  rule={vlan.dhcpRule}
                  option={option}
                  onSuccess={onSuccess}
                />
              ))}
              {canEditDHCPDNS && (
                <DHCPOptionRow
                  key={sortedOptions.length}
                  rule={vlan.dhcpRule}
                  onSuccess={onSuccess}
                />
              )}
            </TableBody>
          </Table>
        ) : (
          <EmptyState heading="No DHCP options configured" />
        )}
      </SectionContent>
    </Section>
  );
}

function VLANDHCPDetailsContent({ vlan }: { vlan: VLANWithDHCPRule }) {
  const network = useNetwork();
  const queryClient = useQueryClient();
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(makeQueryKey(vlansQuery, { networkUUID: network.UUID }));
    queryClient.invalidateQueries(makeQueryKey(vlanQuery, { uuid: vlan.UUID }));
  }, [queryClient, vlan.UUID, network.UUID]);

  return (
    <VStack spacing={space(12)}>
      <VLANDHCPReservedRangesSection vlan={vlan} onSuccess={onSuccess} />
      <VLANDHCPStaticMappingsSection vlan={vlan} onSuccess={onSuccess} />
      <VLANDHCPOptionsSection vlan={vlan} onSuccess={onSuccess} />
    </VStack>
  );
}

const updateVLANDHCPSchema = CreateDhcpRuleInputSchema.omit({
  // Based on VLAN gateway info
  gatewayIPAddress: true,
  gatewayPrefixLength: true,

  dnsCacheIsEnabled: true,
  dnsCacheSize: true,
  dnsCacheMaxTTL: true,
  dnsUpstreamServers: true,
  dnsUseGatewayProxy: true,
  leaseDurationSeconds: true,
}).extend({
  dhcpIsEnabled: z.boolean(),
  leaseDurationHours: leaseDurationHoursSchema,
  dnsSearchDomains: dnsSearchDomainsSchema,
});

type EditVLANDHCPValues = z.input<typeof updateVLANDHCPSchema>;

function VLANDHCPDetailsDrawer({ vlan }: { vlan: VLANWithStaticSubnet }) {
  const network = useNetwork();
  const createMutation = useGraphQLMutation(createDHCPRuleMutation);
  const updateMutation = useGraphQLMutation(updateDHCPRuleMutation);
  const deleteMutation = useGraphQLMutation(deleteDHCPRuleMutation);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    ({ dhcpIsEnabled, leaseDurationHours, ...values }: EditVLANDHCPValues) => {
      if (vlan.dhcpRule?.UUID) {
        if (dhcpIsEnabled) {
          const input: UpdateDhcpRuleInput = {
            ...values,
            leaseDurationSeconds: Math.round(leaseDurationHours * 3600),
          };

          updateMutation.mutate(
            { uuid: vlan.dhcpRule.UUID, input },
            {
              onSuccess: () => {
                notify("Successfully updated VLAN's DHCP settings.", { variant: 'positive' });
                queryClient.invalidateQueries(
                  makeQueryKey(vlansQuery, { networkUUID: network.UUID }),
                );
                queryClient.invalidateQueries(makeQueryKey(vlanQuery, { uuid: vlan.UUID }));
              },
              onError: (err) => {
                notify(
                  `There was an error updating this VLAN's DHCP settings${getGraphQLErrorMessageOrEmpty(err)}.`,
                  {
                    variant: 'negative',
                  },
                );
              },
            },
          );
        } else {
          deleteMutation.mutate(
            { uuid: vlan.dhcpRule.UUID },
            {
              onSuccess: () => {
                notify('Successfully disabled DHCP on VLAN.', { variant: 'positive' });
                queryClient.invalidateQueries(makeQueryKey(vlanQuery, { uuid: vlan.UUID }));
              },
              onError: (err) => {
                notify(
                  `There was an error updating this VLAN's DHCP settings${getGraphQLErrorMessageOrEmpty(err)}.`,
                  {
                    variant: 'negative',
                  },
                );
              },
            },
          );
        }
      } else {
        if (!dhcpIsEnabled || !vlan.ipV4ClientGateway || vlan.ipV4ClientPrefixLength == null)
          return;

        const input: CreateDhcpRuleInput = {
          ...values,
          dnsSearchDomains: values.dnsSearchDomains ?? undefined,
          gatewayIPAddress: vlan.ipV4ClientGateway,
          gatewayPrefixLength: vlan.ipV4ClientPrefixLength,
          leaseDurationSeconds: Math.round(leaseDurationHours * 3600),
        };

        createMutation.mutate(
          { vlanUUID: vlan.UUID, input },
          {
            onSuccess: () => {
              notify("Successfully updated VLAN's DHCP settings.", { variant: 'positive' });
              queryClient.invalidateQueries(makeQueryKey(vlanQuery, { uuid: vlan.UUID }));
            },
            onError: (err) => {
              notify(
                `There was an error updating this VLAN's DHCP settings${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      createMutation,
      updateMutation,
      deleteMutation,
      queryClient,
      network.UUID,
      vlan.UUID,
      vlan.ipV4ClientGateway,
      vlan.ipV4ClientPrefixLength,
      vlan.dhcpRule?.UUID,
    ],
  );

  const [defaultStartIPAddress, defaultEndIPAddress] = useMemo(() => {
    if (!vlan.ipV4ClientGateway || vlan.ipV4ClientPrefixLength == null) return ['', ''];
    try {
      return deriveDefaultDHCPIPRangeFromSubnet(
        vlan.ipV4ClientGateway,
        vlan.ipV4ClientPrefixLength,
      );
    } catch (err) {
      return ['', ''];
    }
  }, [vlan.ipV4ClientGateway, vlan.ipV4ClientPrefixLength]);

  return (
    <Section relation="stacked">
      <SectionHeader heading="DHCP configuration" />
      <SectionContent>
        <Formik<EditVLANDHCPValues>
          validate={toFormikValidate(updateVLANDHCPSchema)}
          initialValues={{
            dhcpIsEnabled: !!vlan.dhcpRule,
            isIPv6: vlan.dhcpRule?.isIPv6 ?? false,
            startIPAddress: vlan.dhcpRule?.startIPAddress ?? defaultStartIPAddress,
            endIPAddress: vlan.dhcpRule?.endIPAddress ?? defaultEndIPAddress,
            leaseDurationHours: vlan?.dhcpRule?.leaseDurationSeconds
              ? vlan.dhcpRule.leaseDurationSeconds / 3600
              : 24,
            dnsSearchDomains: vlan?.dhcpRule?.dnsSearchDomains ?? undefined,
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <DrawerContent>
              <VLANDHCPFields />
            </DrawerContent>
            <VLANDetailsDrawerFooter />
          </Form>
        </Formik>
      </SectionContent>
    </Section>
  );
}

export function VLANDHCPDetails({ vlan }: { vlan: VLAN }) {
  const canEditDHCPDNS = useIsPermitted({
    isPermitted: ({ isOperator, ldFlags, permissions }) =>
      permissions.hasPermission(PermissionType.PermDhcpDnsWrite) &&
      (isOperator || !!ldFlags['edit-vlan']),
  });

  if (!vlanHasStaticIP(vlan)) {
    return <EmptyState heading="Static subnet required on VLAN to enable DHCP" />;
  }

  if (!vlanHasDHCPRule(vlan)) {
    return (
      <EmptyState
        heading="DHCP disabled"
        subheading="Please enable DHCP to configure advanced settings."
      />
    );
  }

  return (
    <Columns scroll="none" template="wide-narrow">
      <Column gutter="vertical">
        <VLANDHCPDetailsContent vlan={vlan as VLANWithDHCPRule} />
      </Column>
      <Column gutter="vertical">
        {canEditDHCPDNS ? (
          <VLANDHCPDetailsDrawer vlan={vlan} />
        ) : (
          <VLANDHCPSummary relation="stacked" vlan={vlan} view="detail" />
        )}
      </Column>
    </Columns>
  );
}

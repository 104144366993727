import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { BulkNosUpgradeQueryQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';

export const BulkNOSUpgradeQuery = graphql(`
  query BulkNOSUpgradeQuery($uuid: UUID!) {
    bulkNOSUpgrade(UUID: $uuid) {
      UUID
      createdAt
      updatedAt
      updatedBy
      nosVersionID
      networks {
        __typename
        UUID
        label
        slug
        companySlug
        company {
          name
        }
      }
    }
  }
`);

export type BulkNosUpgrade = BulkNosUpgradeQueryQuery['bulkNOSUpgrade'];

export function useBulkNOSUpgrade(uuid: string): BulkNosUpgrade | null {
  const { data } = useGraphQL(BulkNOSUpgradeQuery, { uuid });
  return useMemo(() => {
    if (data?.bulkNOSUpgrade) {
      const networks = data.bulkNOSUpgrade.networks.sort((a, b) => {
        if (a.company?.name && b.company?.name) {
          return a.company.name.localeCompare(b.company.name);
        }
        if (a?.companySlug && b?.companySlug) {
          return a.companySlug.localeCompare(b.companySlug);
        }
        return a.label.localeCompare(b.label);
      });
      return { ...data.bulkNOSUpgrade, networks };
    }
    return null;
  }, [data]);
}

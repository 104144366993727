import type { SearchInputProps } from '@meterup/atto';
import { SearchInput } from '@meterup/atto';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDebouncedCallback } from 'use-debounce';

import { useSearchParamsState } from '../providers/SearchParamsStateProvider';

type KeyBoundSearchInputProps = Omit<SearchInputProps, 'inputRef'> & {
  searchParamStateKey: string;
};

export function KeyBoundSearchInput({
  searchParamStateKey,
  ...restProps
}: KeyBoundSearchInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [querySearch, setQuerySearch] = useSearchParamsState<string>(searchParamStateKey, '');
  const [localQuerySearch, setLocalQuerySearch] = useState(querySearch ?? '');
  // update local state when query changes
  // edge case: the SearchParamsState does not initialize state when this component mounts on back button navigation
  useEffect(() => {
    if (querySearch) {
      setLocalQuerySearch(querySearch);
    }
  }, [querySearch]);
  const debouncedQuerySearch = useDebouncedCallback((val) => {
    setQuerySearch(val);
  }, 250);

  const handleQuerySearchChange = useCallback(
    (val: string) => {
      setLocalQuerySearch(val);
      debouncedQuerySearch(val);
    },
    [setLocalQuerySearch, debouncedQuerySearch],
  );
  useHotkeys(
    ['ctrl+f', 'meta+f'],
    () => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    },
    { preventDefault: true },
  );
  return (
    <SearchInput
      data-testid="searchInput"
      inputRef={inputRef}
      onChange={handleQuerySearchChange}
      value={localQuerySearch}
      {...restProps}
    />
  );
}

import type { FormikHelpers } from 'formik';
import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ISPAddSchema } from './utils';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { IspProduct, IspStatus } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../utils/withZodSchema';
import ISPFormShared from './ISPFormShared';
import { ispsAddScheme, useInvalidateISPs } from './utils';

const createISPMutation = graphql(`
  mutation CreateInternetServicePlanMutation(
    $companySlug: String!
    $networkUUID: UUID!
    $input: CreateInternetServicePlanInput!
  ) {
    createInternetServicePlan(companySlug: $companySlug, networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export default function ISPAddForm() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const [isUseDHCP, setIsUseDHCP] = useState(false);
  const mutation = useGraphQLMutation(createISPMutation);
  const navigate = useNavigate();
  const invalidateISPs = useInvalidateISPs({ companySlug: companyName, networkUUID: network.UUID });
  const onCancel = useCloseDrawerCallback();
  const onSubmit = useCallback(
    (values: ISPAddSchema, form: FormikHelpers<ISPAddSchema>) => {
      const { input } = values;
      mutation.mutate(
        {
          companySlug: companyName,
          networkUUID: network.UUID,
          input,
        },
        {
          onSuccess() {
            notify(`Successfully created ISP`, {
              variant: 'positive',
            });
            form.resetForm();
            invalidateISPs();
            navigate(
              makeLink(paths.pages.ISPsPage, {
                companyName,
                networkSlug: network.slug,
              }),
            );
          },
          onError(err) {
            notify(`Failed to create ISP${getGraphQLErrorMessageOrEmpty(err)}`, {
              variant: 'negative',
            });
          },
        },
      );
    },
    [companyName, invalidateISPs, mutation, navigate, network.UUID, network.slug],
  );

  return (
    <Formik<ISPAddSchema>
      initialValues={{
        input: {
          connection: {
            product: IspProduct.DedicatedFiber,
            status: IspStatus.Primary,
          },
          ipConfiguration: {},
          providerUUID: '',
        },
      }}
      validate={withZodSchema(ispsAddScheme)}
      onSubmit={onSubmit}
    >
      <Form>
        <ISPFormShared
          onCancel={onCancel}
          isUseDHCP={isUseDHCP}
          setIsUseDHCP={setIsUseDHCP}
          submitButtonLabel="Create"
        />
      </Form>
    </Formik>
  );
}

import { Drawer, DrawerHeader } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { ISPActions } from './ISPActions';
import ISPEditForm from './ISPEditForm';

export default function ISPEdit({ ispUUID }: { ispUUID: string }) {
  const close = useCloseDrawerCallback();

  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  return (
    <Drawer>
      <DrawerHeader
        icon="globe"
        heading="Edit ISP"
        actions={<ISPActions ispUUID={ispUUID} view="edit" />}
        onClose={close}
      />
      <ISPEditForm ispUUID={ispUUID} />
    </Drawer>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Sidebar, SidebarGroup, SidebarGroups, SidebarTarget } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { useLocalStorage } from '@meterup/common';
import { Navigate, Outlet } from 'react-router';

import { DNSSecurityTab } from '../../../components/DNSSecurity/utils';
import { DockPageLayout } from '../../../components/DockPageLayout';
import { FirewallRulesTab } from '../../../components/Firewall/utils';
import { SecurityApplianceDetailsTab } from '../../../components/Hardware/SecurityAppliance/utils2';
import { LocationSwitcher } from '../../../components/LocationSwitcher';
import { MainAndDrawerLayout } from '../../../components/MainAndDrawerLayout';
import { ReactRouterLink } from '../../../components/ReactRouterLink';
import { DrawerRoutes } from '../../../components/route_elements';
import { RoutingTab } from '../../../components/Routing/utils';
import { useShouldDangerouslySkipFeatureChecksForOperators } from '../../../components/SkipFeatureChecksProvider';
import WIFIDetails from '../../../components/WIFIDetails';
import { paths, RootConnectPage } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { useControllerDataFromPathOrNull } from '../../../hooks/useControllerDataFromPath';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { useNetworkOrNull } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { ProductTypes, useUserProductsAccess } from '../../../hooks/useUserProductsAccess';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';

export function NetworkSidebar({ onNavItemClick }: { onNavItemClick?: () => void }) {
  const controllerData = useControllerDataFromPathOrNull();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const skipFeatureChecksForOperators = useShouldDangerouslySkipFeatureChecksForOperators();

  const [insightsCollapsed, setInsightsCollapsed] = useLocalStorage(
    'dashboard.sidebar.insights.minimized',
    false,
  );

  const [hardwareCollapsed, setHardwareCollapsed] = useLocalStorage(
    'dashboard.sidebar.hardware.minimized',
    true,
  );

  const [networkWideCollapsed, setNetworkWideCollapsed] = useLocalStorage(
    'dashboard.sidebar.networkwide.minimized',
    true,
  );

  const [wirelessCollapsed, setWirelessCollapsed] = useLocalStorage(
    'dashboard.sidebar.wireless.minimized',
    true,
  );

  const [firewallCollapsed, setFirewallCollapsed] = useLocalStorage(
    'dashboard.sidebar.firewall.minimized',
    true,
  );

  const [designCollapsed, setDesignCollapsed] = useLocalStorage(
    'dashboard.sidebar.design.minimized',
    true,
  );

  const [secureTunnelsCollapsed, setSecureTunnelsCollapsed] = useLocalStorage(
    'dashboard.sidebar.securetunnels.minimized',
    true,
  );

  const [operatorsCollapsed, setOperatorsCollapsed] = useLocalStorage(
    'dashboard.sidebar.operators.minimized',
    true,
  );

  const [
    isSOSEnabled,
    isWOS2Enabled,
    isCOS2Enabled,
    isPOSEnabled,
    isCaptivePortalEnabled,
    isDNSSecurityEnabled,
    isFirewallRulesEnabled,
    isIPSecTunnelsEnabled,
    isPolicyRoutingEnabled,
    isRateLimitingEnabled,
    isOneToOneNATEnabled,
  ] = useNosFeaturesEnabled([
    NosFeature.SOS,
    NosFeature.WOS2,
    NosFeature.COS2,
    NosFeature.POS,

    NosFeature.CAPTIVE_PORTAL,
    NosFeature.DNS_SECURITY,
    NosFeature.FIREWALL_RULES,
    NosFeature.IPSEC_TUNNELS,
    NosFeature.POLICY_ROUTING,
    NosFeature.RATE_LIMITING,
    NosFeature.ONE_TO_ONE_NAT,
  ]);

  const network = useNetworkOrNull();
  const networkSlug = network?.slug ?? null;

  const defaultNetworkController =
    useActiveControllerForNetwork(network) ?? network?.virtualDevices[0];
  const controllerName =
    controllerData.currentController?.name ??
    defaultNetworkController?.hardwareDevice?.serialNumber;

  const isActiveTest = useIsActiveMatcher();
  const companyName = useCurrentCompany();
  const flags = useFeatureFlags();
  const access = useUserProductsAccess();
  const { hasPermission } = usePermissions();
  if (!access.shouldSeeProduct(ProductTypes.NETWORK)) {
    // I'm not sure what to do if you can't see network or connect...
    if (access.shouldSeeProduct(ProductTypes.CONNECT)) {
      return <Navigate to={RootConnectPage} />;
    }
  }
  const initialSerialNumber = defaultNetworkController?.hardwareDevice?.serialNumber;

  const hasAnyConfig2Enabled = isCOS2Enabled || isSOSEnabled || isWOS2Enabled;

  const shouldShowOnboarding =
    networkSlug &&
    hasPermission(PermissionType.PermNetworkRead) &&
    flags['onboarding-form'] &&
    (!network?.isActive || flags['onboarding-form-after-live']);

  const hasNetworkOrController = networkSlug || controllerName;

  const hasInsightsNetwork =
    networkSlug &&
    (isCOS2Enabled || isOperator) &&
    hasPermission(PermissionType.PermNetworkMetricsRead);
  const hasInsightsNetworkLEGACY =
    controllerName && !isCOS2Enabled && (isOperator || flags['controller-events']);
  const hasInsightsClients =
    networkSlug &&
    (isOperator || isWOS2Enabled) &&
    hasPermission(PermissionType.PermNetworkDevicesRead);
  const hasInsightsClientsLEGACY = controllerName && !isWOS2Enabled;
  const hasInsightsLogs = networkSlug && (isCOS2Enabled || !controllerName);
  const hasInsightsLogsLEGACY =
    controllerName && !isCOS2Enabled && (flags['controller-events'] || isOperator);
  const hasInsights =
    hasInsightsNetwork ||
    hasInsightsNetworkLEGACY ||
    hasInsightsClients ||
    hasInsightsClientsLEGACY ||
    hasInsightsLogs ||
    hasInsightsLogsLEGACY;

  const hasHardwarePDUs =
    networkSlug &&
    (isOperator || (isPOSEnabled && hasPermission(PermissionType.PermNetworkDevicesRead)));
  const hasHardwareSecurityAppliances =
    networkSlug &&
    hasPermission(PermissionType.PermNetworkDevicesRead) &&
    (isOperator || isCOS2Enabled);
  const hasHardwareSwitches =
    networkSlug &&
    (isOperator || (isSOSEnabled && hasPermission(PermissionType.PermNetworkDevicesRead)));
  const hasHardwareSwitchesLEGACY = controllerName && !isSOSEnabled;
  const hasHardwareAccessPoints =
    networkSlug &&
    (isOperator || (isWOS2Enabled && hasPermission(PermissionType.PermNetworkDevicesRead)));
  const hasHardwareAccessPointsLEGACY = controllerName && !isWOS2Enabled;
  const hasHardware =
    hasHardwarePDUs ||
    hasHardwareSecurityAppliances ||
    hasHardwareSwitches ||
    hasHardwareAccessPoints ||
    hasHardwareSwitchesLEGACY ||
    hasHardwareAccessPointsLEGACY;

  const hasNetworkWideISPs =
    networkSlug &&
    isCOS2Enabled &&
    hasPermission(PermissionType.PermIspRead) &&
    (isOperator || flags.isp);
  const hasNetworkWideISPsLEGACY = controllerName && !isCOS2Enabled;
  const hasNetworkWideVLANs =
    networkSlug && hasAnyConfig2Enabled && hasPermission(PermissionType.PermVlanRead);
  const hasNetworkWideVLANsLEGACY = controllerName && !hasAnyConfig2Enabled;
  const hasNetworkWideRouting =
    networkSlug &&
    hasPermission(PermissionType.PermPolicyRoutingRead) &&
    isPolicyRoutingEnabled &&
    (isOperator || flags['policy-routing']);
  const hasNetworkWideCaptivePortal =
    networkSlug &&
    hasPermission(PermissionType.PermCaptivePortalRead) &&
    isCOS2Enabled &&
    isCaptivePortalEnabled &&
    (isOperator || flags['captive-portal']);
  const hasNetworkWideRadiusProfiles =
    networkSlug && isWOS2Enabled && hasPermission(PermissionType.PermNetworkSettingsRead);
  const hasNetworkWide =
    hasNetworkWideISPs ||
    hasNetworkWideISPsLEGACY ||
    hasNetworkWideVLANs ||
    hasNetworkWideVLANsLEGACY ||
    hasNetworkWideRouting ||
    hasNetworkWideRadiusProfiles ||
    hasNetworkWideCaptivePortal;

  const hasWirelessSSIDs = networkSlug && isWOS2Enabled;
  const hasWirelessSSIDsLEGACY = controllerName && !isWOS2Enabled;
  const hasWirelessRadioProfiles =
    networkSlug && isWOS2Enabled && hasPermission(PermissionType.PermRadioProfileRead);
  const hasWirelessHotspot20 =
    networkSlug &&
    isWOS2Enabled &&
    hasPermission(PermissionType.PermCaptivePortalRead) &&
    (isOperator || flags['captive-portal']);
  const hasWireless =
    hasPermission(PermissionType.PermSsidsRead) &&
    (hasWirelessSSIDs ||
      hasWirelessSSIDsLEGACY ||
      hasWirelessRadioProfiles ||
      hasWirelessHotspot20);

  const hasFirewallRules =
    networkSlug &&
    hasPermission(PermissionType.PermFirewallRead) &&
    isCOS2Enabled &&
    isFirewallRulesEnabled &&
    (isOperator || flags['firewall-rules']);
  const hasFirewallDNSSecurity =
    networkSlug &&
    isCOS2Enabled &&
    isDNSSecurityEnabled &&
    (isOperator || flags['dns-security']) &&
    hasPermission(PermissionType.PermFirewallWrite);
  const hasFirewallDNSSecurityLEGACY =
    controllerName && flags['content-filtering-ui'] && !isCOS2Enabled;
  const hasFirewallRateLimiting =
    networkSlug &&
    hasPermission(PermissionType.PermRateLimitRead) &&
    isCOS2Enabled &&
    isRateLimitingEnabled &&
    (isOperator || flags['rate-limiting']);
  const hasFirewallPortForwarding = networkSlug && (isOperator || flags['port-forwarding']);
  const hasFirewallPortForwardingLEGACY = controllerName && !isCOS2Enabled;
  const hasFirewallMulticast =
    networkSlug &&
    hasPermission(PermissionType.PermFirewallRead) &&
    (flags['multicast-mdns'] || isOperator);
  const hasFirewallOneToOneNAT =
    networkSlug &&
    isOneToOneNATEnabled &&
    hasPermission(PermissionType.PermOneToOneNatRead) &&
    (flags['one-to-one-nat'] || isOperator);
  const hasFirewall =
    hasFirewallRules ||
    hasFirewallDNSSecurity ||
    hasFirewallDNSSecurityLEGACY ||
    hasFirewallRateLimiting ||
    hasFirewallPortForwarding ||
    hasFirewallDNSSecurityLEGACY ||
    hasFirewallMulticast ||
    hasFirewallOneToOneNAT;

  const hasDesignTopology =
    networkSlug &&
    (isOperator || isCOS2Enabled) &&
    hasPermission(PermissionType.PermNetworkDevicesRead);
  const hasDesignTopologyLEGACY = controllerName && !isCOS2Enabled;
  const hasDesignRackElevations =
    networkSlug &&
    (isOperator || flags['rack-elevation']) &&
    hasPermission(PermissionType.PermRackElevationRead);
  const hasDesignFloorPlans = networkSlug && hasPermission(PermissionType.PermCompanyGet);
  const hasDesignFloorPlansLEGACY = !networkSlug && controllerName;
  const hasDesign =
    hasDesignTopology ||
    hasDesignTopologyLEGACY ||
    hasDesignRackElevations ||
    hasDesignFloorPlans ||
    hasDesignFloorPlansLEGACY;

  const hasSecureTunnelsClientVPN =
    networkSlug && isCOS2Enabled && hasPermission(PermissionType.PermClientVpnRead);
  const hasSecureTunnelsClientVPNLEGACY = controllerName && !isCOS2Enabled;
  const hasSecureTunnelsIPSec =
    networkSlug &&
    isCOS2Enabled &&
    isIPSecTunnelsEnabled &&
    (isOperator || flags['ipsec-tunnels']) &&
    hasPermission(PermissionType.PermIpsecTunnelRead);
  const hasSecureTunnels =
    hasSecureTunnelsClientVPN || hasSecureTunnelsClientVPNLEGACY || hasSecureTunnelsIPSec;

  const hasOperatorsWirelessInsights =
    networkSlug &&
    !isWOS2Enabled &&
    isOperator &&
    (flags['clients-chart'] || flags['ap-charts'] || flags['rf-utilization-chart']);
  const hasOperatorsNotifications = isOperator && controllerName && !isCOS2Enabled;
  const hasOperatorsDeviceConfig =
    isOperator && networkSlug && initialSerialNumber && flags['device-config-operators'];
  const hasOperators =
    isOperator &&
    (hasOperatorsWirelessInsights || hasOperatorsNotifications || hasOperatorsDeviceConfig);

  return (
    <Sidebar product="network" heading="Network" start={<LocationSwitcher />} end={<WIFIDetails />}>
      <SidebarGroups collapsible>
        {(shouldShowOnboarding || isOperator) && (
          <SidebarGroup>
            <SidebarTarget
              as={ReactRouterLink}
              to={makeLink(paths.pages.OnboardingPage, {
                companyName,
                networkSlug: networkSlug || '',
              })}
              isSelected={isActiveTest({ path: paths.pages.OnboardingPage, end: true })}
              onClick={onNavItemClick}
              icon="upgrading"
              label="Onboarding"
              internal={isOperator && !shouldShowOnboarding}
            />
          </SidebarGroup>
        )}
        <>
          {/* Insights */}
          {hasInsights && (
            <SidebarGroup>
              {hasNetworkOrController && (
                <SidebarTarget
                  onClick={() => setInsightsCollapsed(!insightsCollapsed)}
                  isParent
                  isCollapsed={insightsCollapsed}
                  icon="reporting"
                  label="Insights"
                />
              )}
              <SidebarGroup isCollapsed={insightsCollapsed} isChild>
                {hasInsightsNetwork && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.InsightsNetworkPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.InsightsNetworkPage,
                    })}
                    onClick={onNavItemClick}
                    icon="network"
                    label="Network"
                    internal={isOperator && !isCOS2Enabled}
                  />
                )}
                {hasInsightsNetworkLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.OverviewPage, { companyName, controllerName })}
                    isSelected={isActiveTest({ path: paths.pages.OverviewPage, end: true })}
                    onClick={onNavItemClick}
                    icon="network"
                    label={isOperator ? 'Legacy network' : 'Network'}
                  />
                )}
                {/* Clients */}
                {hasInsightsClients && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.ClientsList2Page, {
                      companyName,
                      networkSlug,
                    })}
                    internal={isOperator && !isWOS2Enabled}
                    isSelected={
                      isActiveTest(paths.pages.ClientsList2Page) ||
                      isActiveTest({ path: paths.pages.ClientsList2Page, end: false })
                    }
                    onClick={onNavItemClick}
                    icon="client"
                    label="Clients"
                  />
                )}
                {hasInsightsClientsLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.ClientsListPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={
                      isActiveTest(paths.pages.ClientsListPage) ||
                      isActiveTest({ path: paths.pages.ClientsListPage, end: false })
                    }
                    onClick={onNavItemClick}
                    icon="client"
                    label={isOperator ? 'Legacy clients' : 'Clients'}
                  />
                )}
                {/* /Clients */}
                {hasInsightsLogs && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.EventLogPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={
                      isActiveTest(paths.pages.EventLogPage) ||
                      isActiveTest(paths.pages.NetworkMutationsListPage) ||
                      isActiveTest(paths.pages.StatsListPage) ||
                      isActiveTest(paths.pages.EventsListPage)
                    }
                    onClick={onNavItemClick}
                    icon="log"
                    label="Logs"
                  />
                )}
                {hasInsightsLogsLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.EventsListPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={
                      isActiveTest(paths.pages.NetworkMutationsListPage) ||
                      isActiveTest(paths.pages.StatsListPage) ||
                      isActiveTest(paths.pages.EventsListPage)
                    }
                    onClick={onNavItemClick}
                    icon="log"
                    label="Logs"
                    internal={!flags['controller-events']}
                  />
                )}
              </SidebarGroup>
            </SidebarGroup>
          )}
          {/* /Insights */}

          {/* Hardware */}
          {hasHardware && (
            <SidebarGroup>
              {hasNetworkOrController && (
                <SidebarTarget
                  onClick={() => setHardwareCollapsed(!hardwareCollapsed)}
                  isParent
                  isCollapsed={hardwareCollapsed}
                  icon="virtual-device"
                  label="Hardware"
                />
              )}
              <SidebarGroup isCollapsed={hardwareCollapsed} isChild>
                {/* Power distribution units */}
                {hasHardwarePDUs && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.PowerDistributionUnitListPage, {
                      companyName,
                      networkSlug,
                      tab: 'list',
                    })}
                    isSelected={
                      isActiveTest({
                        path: paths.pages.PowerDistributionUnitListPage,
                        end: false,
                      }) ||
                      isActiveTest({
                        path: paths.pages.PowerDistributionUnitDetailPage,
                        end: false,
                      })
                    }
                    onClick={onNavItemClick}
                    icon="pdu"
                    label="Power distribution units"
                    internal={!isPOSEnabled}
                  />
                )}
                {/* /Power distribution units */}

                {/* Security appliances */}
                {hasHardwareSecurityAppliances && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={
                      defaultNetworkController
                        ? makeLink(paths.pages.SecurityApplianceDetailPage, {
                            companyName,
                            networkSlug,
                            uuid: defaultNetworkController.UUID,
                            tab: SecurityApplianceDetailsTab.Insights,
                          })
                        : makeLink(paths.pages.SecurityApplianceEmptyStatePage, {
                            companyName,
                            networkSlug,
                          })
                    }
                    isSelected={isActiveTest({
                      path: paths.pages.SecurityApplianceEmptyStatePage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="security-appliance"
                    label="Security appliances"
                    internal={!isCOS2Enabled}
                  />
                )}
                {/* /Security appliances */}

                {/* Switches */}
                {hasHardwareSwitches && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.SwitchListPage, {
                      companyName,
                      networkSlug,
                      tab: 'list',
                    })}
                    internal={!isSOSEnabled}
                    isSelected={
                      isActiveTest({ path: paths.pages.SwitchListPage, end: false }) ||
                      isActiveTest({ path: paths.pages.SwitchDetailPage, end: false })
                    }
                    onClick={onNavItemClick}
                    icon="switch"
                    label="Switches"
                  />
                )}
                {hasHardwareSwitchesLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacySwitchListPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.LegacySwitchListPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="switch"
                    label={isOperator ? 'Legacy switches' : 'Switches'}
                  />
                )}
                {/* /Switches */}

                {/* Access points */}
                {hasHardwareAccessPoints && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.AccessPointsListPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.AccessPointsPage,
                      end: false,
                    })}
                    icon="access-point"
                    label="Access points"
                  />
                )}
                {hasHardwareAccessPointsLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacyAccessPointListPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.LegacyAccessPointListPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="access-point"
                    label={isOperator ? 'Legacy access points' : 'Access points'}
                  />
                )}
                {/* /Access points */}
              </SidebarGroup>
            </SidebarGroup>
          )}
          {/* /Hardware */}

          {/* Network-wide */}
          {hasNetworkWide && (
            <SidebarGroup>
              {hasNetworkOrController && (
                <SidebarTarget
                  onClick={() => setNetworkWideCollapsed(!networkWideCollapsed)}
                  isParent
                  isCollapsed={networkWideCollapsed}
                  icon="network"
                  label="Network-wide"
                />
              )}
              <SidebarGroup isCollapsed={networkWideCollapsed} isChild>
                {hasNetworkWideISPs && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.ISPsPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.ISPsPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="globe"
                    label="ISPs"
                    internal={!flags.isp}
                  />
                )}
                {hasNetworkWideISPsLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacyISPListPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest(paths.pages.LegacyISPListPage)}
                    onClick={onNavItemClick}
                    icon="globe"
                    label="ISPs"
                  />
                )}
                {hasNetworkWideVLANs && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.VLANListPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.VLANListPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="vlan"
                    label="VLANs"
                    internal={isOperator && !hasAnyConfig2Enabled}
                  />
                )}
                {hasNetworkWideVLANsLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacyVLANListPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.LegacyVLANListPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="vlan"
                    label={isOperator ? 'Legacy VLANs' : 'VLANs'}
                  />
                )}
                {hasNetworkWideRouting && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.RoutingPage, {
                      companyName,
                      networkSlug,
                      tab: RoutingTab.PolicyRouting,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.RoutingPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="connection"
                    label="Routing"
                    internal={!isPolicyRoutingEnabled || !flags['policy-routing']}
                  />
                )}
                {hasNetworkWideRadiusProfiles && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.RADIUSProfilesPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.RADIUSProfilesPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="radius"
                    label="RADIUS profiles"
                  />
                )}
                {hasNetworkWideCaptivePortal && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.CaptivePortalPage, {
                      companyName,
                      networkSlug,
                      tab: hasPermission(PermissionType.PermCaptivePortalWrite)
                        ? 'configure'
                        : 'activity',
                    })}
                    internal={!flags['captive-portal']}
                    isSelected={isActiveTest({
                      path: paths.pages.CaptivePortalPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="captive-portal"
                    label="Captive portal"
                  />
                )}
              </SidebarGroup>
            </SidebarGroup>
          )}
          {/* /Network-wide */}

          {/* Wireless */}
          {hasWireless && (
            <SidebarGroup>
              {hasNetworkOrController && (
                <SidebarTarget
                  onClick={() => setWirelessCollapsed(!wirelessCollapsed)}
                  isParent
                  isCollapsed={wirelessCollapsed}
                  icon="wifi"
                  label="Wireless"
                />
              )}

              <SidebarGroup isCollapsed={wirelessCollapsed} isChild>
                {hasWirelessSSIDs && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.SSIDsPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.SSIDsPage,
                      end: false,
                    })}
                    icon="ssid"
                    label="SSIDs"
                  />
                )}
                {hasWirelessSSIDsLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacySSIDListPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest(paths.pages.LegacySSIDListPage)}
                    onClick={onNavItemClick}
                    icon="ssid"
                    label={isOperator ? 'Legacy SSIDs' : 'SSIDs'}
                  />
                )}
                {hasWirelessRadioProfiles && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.RadioProfilesPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.RadioProfilesPage,
                      end: false,
                    })}
                    icon="radio-profile"
                    label="Radio profiles"
                  />
                )}
                {hasWirelessHotspot20 && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.Hotspot20sPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.Hotspot20sPage,
                      end: false,
                    })}
                    icon="hotspot-2.0"
                    label="Hotspot 2.0"
                    internal={!flags['captive-portal']}
                  />
                )}
              </SidebarGroup>
            </SidebarGroup>
          )}
          {/* /Wireless */}

          {/* Firewall */}
          {hasFirewall && (
            <SidebarGroup>
              {hasNetworkOrController && (
                <SidebarTarget
                  onClick={() => setFirewallCollapsed(!firewallCollapsed)}
                  isParent
                  isCollapsed={firewallCollapsed}
                  icon="traffic-shaping"
                  label="Firewall"
                />
              )}
              <SidebarGroup isCollapsed={firewallCollapsed} isChild>
                {hasFirewallRules && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.RulesPage, {
                      companyName,
                      networkSlug,
                      tab: FirewallRulesTab.VLANs,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.RulesPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="rules"
                    label="Rules"
                    internal={!isCOS2Enabled || !isFirewallRulesEnabled || !flags['firewall-rules']}
                  />
                )}
                {hasFirewallDNSSecurity && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.DNSSecurityPage, {
                      companyName,
                      networkSlug,
                      tab: DNSSecurityTab.VLANs,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.DNSSecurityPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="dns-security"
                    label="DNS security"
                    internal={!isCOS2Enabled || !isDNSSecurityEnabled || !flags['dns-security']}
                  />
                )}
                {hasFirewallDNSSecurityLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacyDNSSecurityPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.LegacyDNSSecurityPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="dns-security"
                    label={isCOS2Enabled ? 'Legacy DNS security' : 'DNS security'}
                  />
                )}
                {hasFirewallRateLimiting && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.RateLimitingPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.RateLimitingPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="access-control"
                    label="Rate limiting"
                    internal={!isCOS2Enabled || !isRateLimitingEnabled || !flags['rate-limiting']}
                  />
                )}
                {hasFirewallPortForwarding && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.PortForwardingPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.PortForwardingPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="port-forward"
                    label="Port forwarding"
                    internal={!flags['port-forwarding']}
                  />
                )}
                {hasFirewallPortForwardingLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacyPortForwardingRuleListPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.LegacyPortForwardingRuleListPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="port-forward"
                    label={isOperator ? 'Legacy port forwarding' : 'Port forwarding'}
                  />
                )}
                {hasFirewallOneToOneNAT && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.OneToOneNATPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.OneToOneNATPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="nat"
                    label="1:1 NAT"
                    internal={!flags['one-to-one-nat']}
                  />
                )}
                {hasFirewallMulticast && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.MulticastPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.MulticastPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="multicast-dns"
                    label="mDNS"
                    internal={!flags['multicast-mdns']}
                  />
                )}
              </SidebarGroup>
            </SidebarGroup>
          )}
          {/* /Firewall */}

          {/* Design */}
          {hasDesign && (
            <SidebarGroup>
              {hasNetworkOrController && (
                <SidebarTarget
                  onClick={() => setDesignCollapsed(!designCollapsed)}
                  isParent
                  isCollapsed={designCollapsed}
                  icon="eye-open"
                  label="Design"
                />
              )}
              <SidebarGroup isCollapsed={designCollapsed} isChild>
                {hasDesignTopology && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.Topology2Page, {
                      companyName,
                      networkSlug,
                    })}
                    internal={isOperator && !isCOS2Enabled}
                    isSelected={isActiveTest(paths.pages.Topology2Page)}
                    onClick={onNavItemClick}
                    icon="topology"
                    label="Topology"
                  />
                )}
                {hasDesignTopologyLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.TopologyPage, { companyName, controllerName })}
                    isSelected={isActiveTest({ path: paths.pages.TopologyPage, end: true })}
                    onClick={onNavItemClick}
                    icon="topology"
                    label={isOperator ? 'Legacy topology' : 'Topology'}
                  />
                )}
                {hasDesignRackElevations && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.RackElevationsPage, { companyName, networkSlug })}
                    isSelected={isActiveTest({ path: paths.pages.RackElevationsPage, end: true })}
                    onClick={onNavItemClick}
                    icon="rack"
                    label="Rack elevations"
                    internal={!flags['rack-elevation']}
                  />
                )}
                {hasDesignFloorPlans && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.FloorPlansPage, { companyName, networkSlug })}
                    isSelected={isActiveTest({ path: paths.pages.FloorPlansPage, end: true })}
                    onClick={onNavItemClick}
                    icon="floorplan"
                    label="Floor plans"
                  />
                )}
                {hasDesignFloorPlansLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.FloorPlansLegacyPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.FloorPlansLegacyPage,
                      end: true,
                    })}
                    onClick={onNavItemClick}
                    icon="floorplan"
                    label="Floor plans"
                  />
                )}
              </SidebarGroup>
            </SidebarGroup>
          )}
          {/* /Design */}

          {/* Secure tunnels */}
          {hasSecureTunnels && (
            <SidebarGroup>
              {hasNetworkOrController && (
                <SidebarTarget
                  onClick={() => setSecureTunnelsCollapsed(!secureTunnelsCollapsed)}
                  isParent
                  isCollapsed={secureTunnelsCollapsed}
                  icon="padlock"
                  label="Secure tunnels"
                />
              )}
              <SidebarGroup isCollapsed={secureTunnelsCollapsed} isChild>
                {hasSecureTunnelsClientVPN && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.ClientVPNPage, { companyName, networkSlug })}
                    isSelected={isActiveTest({ path: paths.pages.ClientVPNPage, end: false })}
                    onClick={onNavItemClick}
                    icon="vpn"
                    label="Client VPN"
                  />
                )}
                {hasSecureTunnelsClientVPNLEGACY && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacyVPNListPage, { companyName, controllerName })}
                    isSelected={isActiveTest({ path: paths.pages.LegacyVPNListPage, end: false })}
                    onClick={onNavItemClick}
                    icon="vpn"
                    label={isOperator ? 'Legacy Client VPN' : 'Client VPN'}
                  />
                )}
                {hasSecureTunnelsIPSec && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.IPSecTunnelsPage, {
                      companyName,
                      networkSlug,
                    })}
                    isSelected={isActiveTest({
                      path: paths.pages.IPSecTunnelsPage,
                      end: false,
                    })}
                    onClick={onNavItemClick}
                    icon="ipsec-tunnel"
                    label="IPSec"
                    internal={!isCOS2Enabled || !isIPSecTunnelsEnabled || !flags['ipsec-tunnels']}
                  />
                )}
              </SidebarGroup>
            </SidebarGroup>
          )}
          {/* /Secure tunnels */}

          {/* Operators */}
          {hasOperators && (
            <SidebarGroup>
              {hasNetworkOrController && (
                <SidebarTarget
                  onClick={() => setOperatorsCollapsed(!operatorsCollapsed)}
                  isParent
                  isCollapsed={operatorsCollapsed}
                  icon="user"
                  label="Operators"
                  internal
                />
              )}
              <SidebarGroup isCollapsed={operatorsCollapsed} isChild>
                {hasOperatorsWirelessInsights && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.LegacyInsightsPage, {
                      companyName,
                      networkSlug,
                      tab:
                        // eslint-disable-next-line no-nested-ternary
                        isOperator || flags['clients-chart']
                          ? 'clients'
                          : flags['ap-charts']
                            ? 'ap'
                            : 'rf',
                    })}
                    isSelected={isActiveTest(paths.pages.LegacyInsightsPage)}
                    onClick={onNavItemClick}
                    icon="reporting"
                    label="Wireless insights"
                    internal={
                      !flags['clients-chart'] &&
                      !flags['ap-charts'] &&
                      !flags['rf-utilization-chart']
                    }
                  />
                )}
                {hasOperatorsNotifications && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.NotificationPreferencesPage, {
                      companyName,
                      controllerName,
                    })}
                    isSelected={isActiveTest(paths.pages.NotificationPreferencesPage)}
                    onClick={onNavItemClick}
                    icon="megaphone"
                    label="Legacy notifications"
                    internal
                  />
                )}
                {hasOperatorsDeviceConfig && (
                  <SidebarTarget
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.DeviceConfigOverridesPage, {
                      companyName,
                      networkSlug,
                      serialNumber: initialSerialNumber,
                    })}
                    isSelected={
                      isActiveTest({ path: paths.pages.DeviceConfigOverridesPage, end: false }) ||
                      isActiveTest({ path: paths.pages.DeviceConfigHistoryPage, end: false })
                    }
                    onClick={onNavItemClick}
                    icon="code"
                    label="Device config"
                    internal={!skipFeatureChecksForOperators}
                  />
                )}
              </SidebarGroup>
            </SidebarGroup>
          )}
          {/* /Operators */}
        </>
      </SidebarGroups>
    </Sidebar>
  );
}

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName',
  name: 'NetworkLayout',
  layout: 'AppLayout',
});

export default function NetworkLayout() {
  return (
    <DockPageLayout
      sidebar={<NetworkSidebar />}
      main={<MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />}
    />
  );
}

import { useGraphQL } from '@meterup/graphql';

import { NosFeature, useNosFeatureEnabled } from '../../../../hooks/useNosFeatures';
import { DeadMansSnitchQuery, SwitchQuery } from '../../../Hardware/Switches/utils';
import { InspectorConfig } from './Units/InspectorConfig';
import { InspectorMetadata } from './Units/InspectorMetadata';
import { InspectorMetrics } from './Units/InspectorMetrics';
import { InspectorMetricsStats } from './Units/InspectorMetricsStats';

export default function InspectorSwitch({ uuid }: { uuid: string }) {
  const virtualDevice = useGraphQL(SwitchQuery, { uuid }).data?.virtualDevice;
  const doesSwitchUseStat = useNosFeatureEnabled(NosFeature.SOSUsesStat);
  const serialNumber = virtualDevice?.hardwareDevice?.serialNumber ?? '';

  const deadMansToken = useGraphQL(
    DeadMansSnitchQuery,
    { serialNumber },
    { enabled: !!serialNumber, useErrorBoundary: false },
  ).data?.deviceHeartbeatForDevice?.token;
  if (
    virtualDevice?.hardwareDevice &&
    virtualDevice.hardwareDevice.__typename !== 'SwitchHardwareDevice'
  )
    return null;
  if (virtualDevice?.__typename !== 'SwitchVirtualDevice') return null;

  return (
    <>
      <InspectorMetadata
        hardwareDevice={virtualDevice?.hardwareDevice}
        virtualDevice={virtualDevice}
        lastBootHistoryBuildName={virtualDevice?.hardwareDevice?.bootHistory?.[0]?.buildName}
        deadMansToken={deadMansToken}
      />
      {(doesSwitchUseStat && virtualDevice && (
        <InspectorMetricsStats virtualDeviceUUID={virtualDevice.UUID} />
      )) || <InspectorMetrics serialNumber={serialNumber} />}
      <InspectorConfig serialNumber={serialNumber} />
    </>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import PowerDistributionUnitDrawer from '../../../../../components/Hardware/PowerDistributionUnits/PowerDistributionUnitDrawer';
import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid',
  title: 'Power distribution unit - Power distribution units - Hardware',
});

export default function PowerDistributionUnitSummaryPage() {
  const drawerParams = Nav.useRegionParams(
    'drawer',
    paths.drawers.PowerDistributionUnitSummaryPage,
  );

  return (
    <IsPermitted
      isPermitted={({ permissions, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
            nosFlags[NosFeature.POS],
        )
      }
      should404OnAccessDenied
    >
      <PowerDistributionUnitDrawer uuid={drawerParams?.uuid!} />
    </IsPermitted>
  );
}

import type { BadgeSize, BadgeVariant } from '@meterup/atto';
import { Badge, BadgeGroup, Section, SectionContent, Stats, styled, Tooltip } from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash-es';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import APIv2 from '../../../api/apiv2';
import { paths } from '../../../constants';
import { useActiveControllerForNetwork } from '../../../hooks/useActiveControllerForNetwork';
import { useControllerDataFromPathOrNull } from '../../../hooks/useControllerDataFromPath';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork, useNetworkUUID } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { ControllersQuery } from '../../Hardware/SecurityAppliance/utils';
import { SecurityApplianceDetailsTab } from '../../Hardware/SecurityAppliance/utils2';
import { SwitchesQuery } from '../../Hardware/Switches/utils';
import { AccessPointsQuery } from '../../Wireless/utils';

const typeLabels = {
  switch: 'switches',
  'access-point': 'access points',
  'security-appliance': 'security appliances',
};

function NetworkStatsDeviceBadge({
  offlineCount,
  onlineCount,
  size = 'small',
  type,
  variant,
}: {
  offlineCount: number;
  onlineCount: number;
  size?: BadgeSize;
  type: 'switch' | 'access-point' | 'security-appliance';
  variant?: 'condensed';
}) {
  const totalDeviceCount = offlineCount + onlineCount;
  if (variant === 'condensed') {
    let badgeVariant: BadgeVariant = 'positive';
    if (offlineCount > 0) {
      const offlinePercentage = offlineCount / totalDeviceCount;
      if (offlinePercentage > 0.5) {
        badgeVariant = 'negative';
      } else {
        badgeVariant = 'attention';
      }
    }
    if (onlineCount === 0 && offlineCount === 0) {
      badgeVariant = 'neutral';
    }
    // only 1 controller needs to be online
    if (onlineCount > 0 && type === 'security-appliance') {
      badgeVariant = 'positive';
    }

    return (
      <Tooltip contents={`${onlineCount}/${totalDeviceCount} ${typeLabels[type]} online`}>
        <Badge
          arrangement="leading-icon"
          ends="pill"
          icon={type}
          size={size}
          variant={badgeVariant}
        >
          {onlineCount}/{totalDeviceCount}
        </Badge>
      </Tooltip>
    );
  }
  return (
    <BadgeGroup alignment="center" size="small">
      {offlineCount > 0 && (
        <Badge arrangement="leading-icon" ends="pill" icon={type} size={size} variant="negative">
          {offlineCount} offline
        </Badge>
      )}
      {onlineCount === totalDeviceCount && (
        <Badge arrangement="leading-icon" ends="pill" icon={type} size={size} variant="positive">
          All online
        </Badge>
      )}
      {onlineCount > 0 && onlineCount !== totalDeviceCount && (
        <Badge arrangement="leading-icon" ends="pill" icon={type} size={size} variant="positive">
          {onlineCount} online
        </Badge>
      )}
    </BadgeGroup>
  );
}

function useControllerCounts() {
  const networkUUID = useNetworkUUID();
  const controllersData = useGraphQL(
    ControllersQuery,
    {
      networkUUID: networkUUID!,
    },
    {
      enabled: !!networkUUID,
    },
  ).data;

  const controllerCounts: { online: number; offline: number } = useMemo(() => {
    if (controllersData?.virtualDevicesForNetwork) {
      const hardwareDevices = controllersData.virtualDevicesForNetwork
        .map((vd) => vd.hardwareDevice)
        .filter((hd): hd is NonNullable<typeof hd> => !!hd && hd.isActive);

      const grouped = groupBy(hardwareDevices, (hd) =>
        hd.isConnectedToBackend ? 'online' : 'offline',
      );
      return {
        online: grouped.online?.length ?? 0,
        offline: grouped.offline?.length ?? 0,
      };
    }
    return {
      online: 0,
      offline: 0,
    };
  }, [controllersData]);

  return controllerCounts;
}

const useLoadDevices = () => {
  const { currentController, controllerNameFromPath } = useControllerDataFromPathOrNull();

  const flags = useFeatureFlags();
  const api = useMemo(
    () => (currentController ? new APIv2(currentController, flags) : null),
    [currentController, flags],
  );

  const { data } = useQuery(
    ['metrics', 'controller_devices', controllerNameFromPath],
    () => api?.devicesForController(),
    {
      suspense: true,
      enabled: !!controllerNameFromPath && !!api,
    },
  );

  return data;
};

function useAPCounts() {
  const data = useLoadDevices();
  const networkUUID = useNetworkUUID();
  const isWOS2 = useNosFeatureEnabled(NosFeature.WOS2);

  const accessPointsData = useGraphQL(
    AccessPointsQuery,
    {
      networkUUID: networkUUID!,
    },
    {
      enabled: !!networkUUID && isWOS2,
    },
  ).data;

  const apCounts: { online: number; offline: number } = useMemo(() => {
    if (isWOS2) {
      const hardwareDevices =
        accessPointsData?.virtualDevicesForNetwork
          .map((vd) => vd.hardwareDevice)
          .filter((hd): hd is NonNullable<typeof hd> => !!hd && hd.isActive) ?? [];

      const grouped = groupBy(hardwareDevices, (hd) =>
        hd.isConnectedToBackend ? 'online' : 'offline',
      );
      return {
        online: grouped.online?.length ?? 0,
        offline: grouped.offline?.length ?? 0,
      };
    }

    if (data?.access_points) {
      const grouped = groupBy(data.access_points, (ap) => ap.connected_status);
      return {
        online: grouped.online?.length ?? 0,
        offline: grouped.offline?.length ?? 0,
      };
    }

    return {
      online: 0,
      offline: 0,
    };
  }, [data?.access_points, accessPointsData, isWOS2]);

  return apCounts;
}

function useSwitchCounts() {
  const networkUUID = useNetworkUUID();

  const switchesData = useGraphQL(
    SwitchesQuery,
    {
      networkUUID: networkUUID!,
    },
    {
      enabled: !!networkUUID,
    },
  ).data;

  const switchCounts: { online: number; offline: number } = useMemo(() => {
    if (switchesData?.virtualDevicesForNetwork) {
      const hardwareDevices = switchesData.virtualDevicesForNetwork
        .map((vd) => vd.hardwareDevice)
        .filter((hd): hd is NonNullable<typeof hd> => !!hd && hd.isActive);

      const grouped = groupBy(hardwareDevices, (hd) =>
        hd.isConnectedToBackend ? 'online' : 'offline',
      );
      return {
        online: grouped.online?.length ?? 0,
        offline: grouped.offline?.length ?? 0,
      };
    }
    return {
      online: 0,
      offline: 0,
    };
  }, [switchesData]);
  return switchCounts;
}

const StatsLink = styled(Link, {
  value: 'flex',
  justifyContent: 'center',
});

export default function NetworkStats() {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  const controllerCounts = useControllerCounts();
  const switchCounts = useSwitchCounts();
  const apCounts = useAPCounts();

  const activeControllerForNetwork = useActiveControllerForNetwork(network);
  const hasControllers = controllerCounts.online > 0 || controllerCounts.offline > 0;
  const hasSwitches = switchCounts.offline > 0 || switchCounts.online > 0;
  const hasAPs = apCounts.online > 0 || apCounts.offline > 0;

  if (!hasControllers && !hasSwitches && !hasAPs) return null;

  return (
    <Section relation="stacked">
      <SectionContent gutter="none">
        <Stats
          alignment="center"
          arrangement="leading-label"
          stats={[
            ...(hasControllers
              ? [
                  {
                    label: 'Security appliances',
                    value: (
                      <StatsLink
                        to={makeLink(paths.pages.SecurityApplianceDetailPage, {
                          companyName,
                          networkSlug: network.slug,
                          uuid: activeControllerForNetwork?.UUID!,
                          tab: SecurityApplianceDetailsTab.Insights,
                        })}
                      >
                        <NetworkStatsDeviceBadge
                          type="security-appliance"
                          offlineCount={controllerCounts.offline}
                          onlineCount={controllerCounts.online}
                          size="medium"
                        />
                      </StatsLink>
                    ),
                  },
                ]
              : []),
            ...(hasSwitches
              ? [
                  {
                    label: 'Switches',
                    value: (
                      <StatsLink
                        to={makeLink(paths.pages.SwitchListPage, {
                          companyName,
                          networkSlug: network.slug,
                          tab: 'list',
                        })}
                      >
                        <NetworkStatsDeviceBadge
                          type="switch"
                          offlineCount={switchCounts.offline}
                          onlineCount={switchCounts.online}
                          size="medium"
                        />
                      </StatsLink>
                    ),
                  },
                ]
              : []),
            ...(hasAPs
              ? [
                  {
                    label: 'Access points',
                    value: (
                      <StatsLink
                        to={makeLink(paths.pages.AccessPointsPage, {
                          companyName,
                          networkSlug: network.slug,
                        })}
                      >
                        <NetworkStatsDeviceBadge
                          type="access-point"
                          offlineCount={apCounts.offline}
                          onlineCount={apCounts.online}
                          size="medium"
                        />
                      </StatsLink>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </SectionContent>
    </Section>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import { notify } from '@meterup/common';
import { makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useNetwork } from '../../../hooks/useNetworkFromPath';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import {
  deleteRackElevationMutation,
  mutationErrorMessage,
  rackElevationQuery,
  rackElevationsQuery,
} from './utils';

export default function DeleteRackDialog({
  uuid,
  state,
}: {
  uuid: string;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const queryClient = useQueryClient();
  const deleteMutation = useGraphQLMutation(deleteRackElevationMutation);
  const elevation = useGraphQL(rackElevationQuery, { UUID: uuid }).data?.rackElevation;

  const queryKey = useMemo(
    () => makeQueryKey(rackElevationsQuery, { networkUUID: network.UUID }),
    [network.UUID],
  );

  const handleDelete = useCallback(() => {
    if (deleteMutation.isLoading) return;
    deleteMutation.mutate(
      { UUID: uuid },
      {
        onSuccess: () => {
          notify('Successfully deleted rack elevation.', { variant: 'positive' });
          queryClient.invalidateQueries(queryKey);
          state.close();
        },
        onError: (error) => {
          notify(mutationErrorMessage('There was an error deleting this rack elevation', error), {
            variant: 'negative',
          });
        },
      },
    );
  }, [queryClient, deleteMutation, uuid, queryKey, state]);

  if (!elevation) return null;

  return <DeleteDialog label={elevation.label} handleDelete={handleDelete} state={state} />;
}

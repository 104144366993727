import nacl from 'tweetnacl';

export const generateKeyPair = () => {
  const { publicKey, secretKey } = nacl.box.keyPair();
  return {
    publicKey: btoa(String.fromCharCode(...publicKey)),
    privateKey: btoa(String.fromCharCode(...secretKey)),
  };
};

export const createWGConf = (
  ip: string,
  endpoint: string,
  port: number,
  publicKey: string,
  privateKey: string,
  allowedIPs: string[],
  dnsServers: string[] = ['10.103.0.2'],
) =>
  `
[Interface]
PrivateKey = ${privateKey}
Address = ${ip}/32
DNS = ${dnsServers.join(', ')}

[Peer]
PublicKey = ${publicKey}
AllowedIPs = ${(allowedIPs.length > 0 ? allowedIPs : ['10.0.0.0/8', '224.0.0.0/4']).join(', ')}
Endpoint = ${endpoint}:${port}
PersistentKeepalive = 30`.trim();

import {
  Button,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  Link as AttoLink,
  MultiComboBox,
  MultiComboBoxItem,
  MultiComboBoxSection,
  PrimaryField,
  Text,
  Textarea,
  TextInput,
} from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../../../constants';
import {
  useCurrentCompany,
  useCurrentCompany2,
} from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import {
  FieldProvider,
  ListFieldProvider,
  MultiComboBoxFieldProvider,
} from '../../../../Form/FieldProvider';
import { useAlertTargetsWebhook } from '../utils';

type FormProps = {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function ReceiverForm({ onCancel }: FormProps) {
  const companyName = useCurrentCompany();
  const company = useCurrentCompany2();
  const webhooks = useAlertTargetsWebhook({ companyUUID: company?.uuid });
  return (
    <>
      <DrawerContent>
        <FieldProvider name="input.label">
          <PrimaryField label="Label" element={<TextInput />} />
        </FieldProvider>

        <FieldContainer>
          <PrimaryField
            label="Targets"
            description={
              <Text>
                Add one or many emails or{' '}
                <AttoLink
                  as={Link}
                  to={makeLink(paths.pages.WebhooksPage, {
                    companyName,
                  })}
                >
                  webhooks
                </AttoLink>
                .
              </Text>
            }
            element={null}
          />
          <ListFieldProvider name="input.emailAddresses">
            <PrimaryField
              description="One email address per line."
              label="Email addresses"
              element={<Textarea />}
            />
          </ListFieldProvider>
          <MultiComboBoxFieldProvider name="input.targetUUIDs">
            <PrimaryField
              label="Webhooks"
              element={
                <MultiComboBox>
                  <MultiComboBoxSection title="Webhooks">
                    {webhooks.map((webhook) => (
                      <MultiComboBoxItem key={webhook.UUID}>{webhook.label}</MultiComboBoxItem>
                    ))}
                  </MultiComboBoxSection>
                </MultiComboBox>
              }
            />
          </MultiComboBoxFieldProvider>
        </FieldContainer>
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button type="button" onClick={onCancel} variant="secondary">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </>
        }
      />
    </>
  );
}

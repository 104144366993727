import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { EmptyState } from '@meterup/atto';
import {
  AutoTable,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { DateTime } from 'luxon';

import type { DeviceConfigsQueryResult } from '../../../../../components/DeviceConfigs/utils';
import { DeviceConfigsQuery } from '../../../../../components/DeviceConfigs/utils';
import { NoValue } from '../../../../../components/NoValue';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

const builder = createColumnBuilder<DeviceConfigsQueryResult>();

const columns = [
  builder.data((config) => config.version.toString(), {
    id: 'config-version',
    header: 'Version',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((config) => config.acknowledgedAt ?? '', {
    id: 'config-acknowledged',
    header: `Acknowledged (${DateTime.local().toFormat('ZZZZZ')})`,
    cell: (d) =>
      isDefinedAndNotEmpty(d.value) ? (
        <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</>
      ) : (
        <NoValue />
      ),
  }),
  builder.data((config) => config.createdAt, {
    id: 'config-created',
    header: `Created (${DateTime.local().toFormat('ZZZZZ')})`,
    cell: (d) => (
      <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</>
    ),
  }),
];

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/operators/config/:serialNumber/history',
});

export default function DeviceConfigHistoryPage() {
  const network = useNetwork();
  const companyName = useCurrentCompany();

  const historyParams = Nav.useRegionParams('root', paths.pages.DeviceConfigHistoryPage);
  const serialNumber = historyParams?.serialNumber;
  expectDefinedOrThrow(
    serialNumber,
    new ResourceNotFoundError('Serial number of device not present.'),
  );

  const deviceConfigs = useGraphQL(DeviceConfigsQuery, { serialNumber }).data?.deviceConfigs ?? [];

  return deviceConfigs.length === 0 ? (
    <EmptyState heading={`No config changes for ${serialNumber}`} icon="code" />
  ) : (
    <AutoTable
      key="config-list"
      columns={columns}
      data={deviceConfigs}
      getLinkTo={(row) =>
        `${makeLink(paths.pages.DeviceConfigHistoryVersionPage, {
          companyName,
          networkSlug: network.slug,
          serialNumber,
        })}?leftVersion=${row.version - 1}&rightVersion=${row.version}&tab=diff`
      }
    />
  );
}

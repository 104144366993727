import type { TickLabelProps } from '@visx/axis';
import { colors, fonts, fontWeights } from '@meterup/atto';
import { buildChartTheme } from '@visx/xychart';
import { DateTime } from 'luxon';
import { useContext } from 'react';

import type { TimeSeries } from '../../../utils/chart_utils';
import { ThemeContext } from '../../../providers/ThemeProvider';

export const BASE_CHART_HEIGHT = 180;
export const DELTA_MINUTES_FOR_NOW = 10;

const repeatArray = (arr: string[], n: number) => Array.from({ length: n }).flatMap(() => arr);
export const lightColors = repeatArray(
  [
    '#5461C8',
    '#228B22',
    '#1E90FF',
    '#DC143C',
    '#4169E1',
    '#228B22',
    '#6A5ACD',
    '#FFA500',
    '#B22222',
    '#9ACD32',
    '#DB7093',
    '#00008B',
    '#D2691E',
    '#9932CC',
    '#8B0000',
    '#6B8E23',
    '#191970',
    '#800000',
    '#4B0082',
    '#FFD700',
    '#C71585',
    '#800080',
  ],
  30,
);

export const darkColors = repeatArray(
  [
    '#40E0D0',
    '#FFA07A',
    '#B0E0E6',
    '#98FB98',
    '#DDA0DD',
    '#CD5C5C',
    '#F08080',
    '#32CD32',
    '#FFC0CB',
    '#E0FFFF',
    '#FFE4C4',
    '#D8BFD8',
    '#E9967A',
    '#7CFC00',
    '#7FFFD4',
    '#BC8F8F',
    '#EE82EE',
    '#FFFACD',
    '#FFB6C1',
    '#DA70D6',
  ],
  30,
);

export const useChartColors = (series: TimeSeries[]) => {
  const { dark } = useContext(ThemeContext);
  const chartColors = dark ? darkColors : lightColors;

  const colorMap: Record<string, string> = {};
  series.forEach((s, idx) => {
    colorMap[s.series_id] = chartColors[idx];
  });

  return { chartColors, colorMap };
};

export const buildChartThemeImpl = (chartColors: string[], mode: 'light' | 'dark') =>
  mode === 'light'
    ? buildChartTheme({
        svgLabelSmall: {
          fontFamily: fonts.sans.toString(),
          fontWeight: fontWeights.regular.toString(),
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: 0,
          fill: colors.bodyNeutralLight.toString(),
        },
        xAxisLineStyles: {
          stroke: colors.strokeNeutralLight.toString(),
        },
        xTickLineStyles: {
          stroke: colors.strokeNeutralLight.toString(),
        },
        yTickLineStyles: {
          stroke: colors.strokeNeutralLight.toString(),
        },
        colors: chartColors,
        gridColor: colors.brand200.toString(),
        backgroundColor: colors.bgApplicationLight.toString(),
        gridColorDark: colors.strokeNeutralLight.toString(),
        tickLength: 4,
      })
    : buildChartTheme({
        svgLabelSmall: {
          fontFamily: fonts.sans.toString(),
          fontWeight: fontWeights.regular.toString(),
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: 0,
          fill: colors.bodyNeutralDark.toString(),
        },
        xAxisLineStyles: {
          stroke: colors.strokeNeutralDark.toString(),
        },
        xTickLineStyles: {
          stroke: colors.strokeNeutralDark.toString(),
        },
        yTickLineStyles: {
          stroke: colors.strokeNeutralDark.toString(),
        },
        colors: [colors.iconBrandDark.toString(), colors.iconPositiveDark.toString()],
        gridColor: colors.brand800.toString(),
        backgroundColor: colors.bgApplicationDark.toString(),
        gridColorDark: colors.strokeNeutralDark.toString(),
        tickLength: 4,
      });

export const defaultTickLabelProps: TickLabelProps<any> | undefined = (
  tickValue: Date,
  i: number,
  allValues: { value: Date; index: number }[],
) => {
  if (i === 0) {
    return { textAnchor: 'inherit' };
  }
  if (i === allValues.length - 1) {
    const props: TickLabelProps<any> = { textAnchor: 'end' };
    if (
      Math.abs(DateTime.fromJSDate(tickValue).diffNow('minutes').minutes) < DELTA_MINUTES_FOR_NOW
    ) {
      props.fill = colors.green600.toString();
    }
    return props;
  }
  return { textAnchor: 'middle' };
};

import type { FormikHelpers } from 'formik';
import {
  Button,
  Dialog,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { range } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import type { RackElevationDeviceType } from '../../../gql/graphql';
import type { RackElevation, ValidAttachmentParams } from './utils';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { withZodSchema } from '../../../utils/withZodSchema';
import {
  CreateVirtualDeviceForm,
  FieldTypeFields,
  FieldTypeToggleField,
  LabelField,
  SlotsFields,
} from './Fields';
import {
  attachDeviceToRackElevationMutation,
  calculateAvailableIndexes,
  getInitialStartIndex,
  mutationErrorMessage,
  rackElevationQuery,
  rackElevationsQuery,
  useAvailableDevices,
  validAttachmentParams,
} from './utils';

export default function AttachDeviceDrawer({
  endIndex,
  elevation,
  stopEditing,
}: {
  endIndex?: number;
  elevation: RackElevation;
  stopEditing: () => void;
}) {
  const queryClient = useQueryClient();
  const devices = useAvailableDevices(elevation.networkUUID);
  const availableIndexes = useMemo(() => calculateAvailableIndexes(elevation, []), [elevation]);

  const mutation = useGraphQLMutation(attachDeviceToRackElevationMutation);

  const handleSubmit = useCallback(
    (v: ValidAttachmentParams, { resetForm }: FormikHelpers<ValidAttachmentParams>) => {
      const rackMountUnitIndexes =
        v.startIndex === v.endIndex ? [v.startIndex] : range(v.startIndex, v.endIndex + 1);

      const baseParams = {
        rackElevationUUID: elevation.UUID,
        rackMountUnitIndexes,
        label: v.label,
      };

      const params =
        v.fieldType === 'meter_device'
          ? { ...baseParams, virtualDeviceUUID: v.virtualDeviceUUID }
          : { ...baseParams, type: v.type as RackElevationDeviceType };

      mutation.mutate(params, {
        onSuccess: () => {
          notify('Successfully added rack elevation device.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(rackElevationsQuery, { networkUUID: elevation.networkUUID }),
          );
          queryClient.invalidateQueries(makeQueryKey(rackElevationQuery, { UUID: elevation.UUID }));
          resetForm({
            values: {
              startIndex: v.startIndex,
              endIndex: v.endIndex,
              fieldType: v.fieldType,
              type: v.type,
              label: '',
              virtualDeviceUUID: '',
            },
          });
        },
        onError: (error) => {
          notify(
            mutationErrorMessage(
              'There was an error adding a device to this rack elevation',
              error,
            ),
            {
              variant: 'negative',
            },
          );
        },
      });
    },
    [mutation, queryClient, elevation.UUID, elevation.networkUUID],
  );

  const closeDrawer = useCloseDrawerCallback();

  const dialogProps = useDialogState();

  const initialEndIndex = useMemo(
    () =>
      endIndex != null && availableIndexes.includes(endIndex)
        ? endIndex
        : availableIndexes[availableIndexes.length - 1],
    [endIndex, availableIndexes],
  );

  return (
    <Drawer>
      <Formik<ValidAttachmentParams>
        validate={withZodSchema(validAttachmentParams)}
        initialValues={{
          endIndex: initialEndIndex,
          startIndex: getInitialStartIndex(initialEndIndex),
          fieldType: 'meter_device',
          type: '',
          label: '',
          virtualDeviceUUID: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader icon="attach" heading="Attach device" onClose={closeDrawer} />
          <DrawerContent>
            <FieldTypeToggleField />
            <LabelField />
            <FieldTypeFields elevation={elevation} devices={devices} action="create" />
            <SlotsFields availableIndexes={availableIndexes} endIndex={endIndex} />
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button variant="secondary" onClick={stopEditing}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </>
            }
            secondaryActions={
              <>
                <Button
                  arrangement="leading-icon"
                  icon="plus"
                  variant="secondary"
                  onClick={dialogProps.state.open}
                >
                  Add virtual device
                </Button>
                <Dialog preset="narrow" state={dialogProps.state}>
                  <DialogHeader icon="virtual-device" heading="Add virtual device" />
                  <CreateVirtualDeviceForm
                    networkUUID={elevation.networkUUID}
                    close={dialogProps.state.close}
                  />
                </Dialog>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

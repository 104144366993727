/* eslint-disable react-refresh/only-export-components */
import { css, darkThemeSelector, FocusRing, styled } from '@meterup/atto';
import LabelRow from '@meterup/connect-ui/src/components/LabelRow';
import { EditorMessage, FooterWrapper } from '@meterup/connect-ui/src/styles/Modal';
import { HorizontalPanelRow } from '@meterup/connect-ui/src/styles/Panels';

import colors from './components/Locations/Table/colors';
import WithTopShadow from './components/WithTopShadow';

export const RoundedGrayBorderStyle = css({
  borderRadius: '$8',
  boxShadow: '$fenceAllLight',
});

export const FlexCentered = css({
  gap: '$8',
  display: 'flex',
  alignItems: 'center',
});

export const FlexCenteredColumns = css(FlexCentered, {
  flexDirection: 'column',
});

export const PaneItem = styled('div', FlexCenteredColumns, {
  padding: '$16 $20',
  gap: '$4',
  height: '100%',
});

export function hasNSiblingsSelector(n: number, el: string = 'div') {
  return `&:first-child:nth-last-child(${n}), &:first-child:nth-last-child(${n}) ~ ${el}`;
}

export const HorizontalPanelBase = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '&:nth-child(even)': {
    boxShadow: '$fenceRightLight',

    [darkThemeSelector]: {
      boxShadow: '$fenceRightDark',
    },
  },

  '&:first-child': {
    // boxShadow: "$fenceLeftLight, $fenceTopLight, $fenceBottomLight",
  },

  '&:last-child': {
    // boxShadow: "$fenceRightLight, $fenceTopLight, $fenceBottomLight",
  },

  [`${hasNSiblingsSelector(6)}, ${hasNSiblingsSelector(4)}`]: {
    '&:last-child': {
      // boxShadow: "$fenceAllLight",
      boxShadow: '$fenceLeftLight',

      [darkThemeSelector]: {
        boxShadow: '$fenceLeftDark',
      },
    },
  },

  [`${hasNSiblingsSelector(2)}`]: {
    '&:first-child': {
      // boxShadow: "$fenceAllLight",
      boxShadow: '$fenceRightLight',

      [darkThemeSelector]: {
        boxShadow: '$fenceRightDark',
      },
    },
  },

  [`${hasNSiblingsSelector(1)}`]: {
    // boxShadow: "$fenceAllLight",
  },
});

export const HorizontalPanel = styled('div', HorizontalPanelBase, {});

export const PaneHighlight = styled('div', {
  alignItems: 'center',
  flex: 1,
  fontSize: '$16',
  lineHeight: '$24',
  fontWeight: '$500',
  fontColor: '$gray-700',
});

export const PaneLabel = styled('div', {
  fontSize: '$12',
  lineHeight: '$16',
  fontWeight: '$400',
  fontColor: '$gray-600',
});

export const CompactLabel = styled('div', {
  fontWeight: 600,
  fontSize: '$11',
  textTransform: 'uppercase',
  color: '$gray-500',
});

export const CompactHeaderDark = styled(CompactLabel, WithTopShadow, {
  backgroundColor: colors.tableHeaderBackground,
  color: '$gray-600',
  padding: '$8 $16',
});

export const fenceMedium = 'rgba(215,216,219,0.86)';
export const ToolsAndTabBarRow = styled(LabelRow, {
  boxShadow: '$fenceBottomLight',
});

export const EditorMessageLarge = styled(EditorMessage, {
  padding: '$8 $8 $8 $16',
  variants: {
    state: {
      entering: {
        maxHeight: '240px',
      },
      entered: {
        maxHeight: '240px',
      },
    },
  },
});

export const SummaryRowWrapper = styled('div', HorizontalPanelRow, {
  margin: '$20',
  boxShadow: '$fenceAllLight',
  borderRadius: '$8',
  overflow: 'hidden',
});

export const FloatingLocationEditor = styled('div', {
  background: '$white',
  width: '1000px',
  position: 'fixed',
  bottom: '$20',
  right: '$20',
  zIndex: 3,
});

export const EditorNotificationWrapper = styled('div', FooterWrapper, {
  '& > div': {
    borderRadius: 0,
  },
});

export const EditorControlsWrapper = styled('div', FooterWrapper, {
  padding: '$8 $12',
  backgroundColor: '$gray-50',

  [darkThemeSelector]: {
    backgroundColor: '$gray-800',
  },

  [`&:has(+ ${EditorMessage}), &:has(+ ${EditorNotificationWrapper})`]: {
    borderRadius: 'unset',
    boxShadow: '$fenceLeftLight, $fenceRightLight',

    [darkThemeSelector]: {
      boxShadow: '$fenceLeftDark, $fenceRightDark',
    },
  },
});

export const Button = styled('button', FocusRing, {
  alignItems: 'center',
  display: 'flex',
  borderRadius: '$6',
  flexDirection: 'row',
  gap: '$8',
  color: '$gray-700',
});

export const RenderedText = styled('span', { truncate: true });

import { Icon, space, TextInput } from '@meterup/atto';
import { DateTime } from 'luxon';

import { useSearchParamsState } from '../providers/SearchParamsStateProvider';
import { defaultEndDate, defaultStartDate } from './EventLogView';

export default function EventLogFilters() {
  const [startDate, setStartDate] = useSearchParamsState<string>('startDate', defaultStartDate);
  const [endDate, setEndDate] = useSearchParamsState<string>('endDate', defaultEndDate);

  return (
    <>
      <TextInput
        icon="calendar"
        type="date"
        value={DateTime.fromISO(startDate!).toISODate()}
        onChange={(value) => {
          setStartDate(DateTime.fromISO(value).startOf('day').toISO());
        }}
        inputProps={{
          max: DateTime.fromISO(endDate!).toISODate(),
        }}
        aria-label="Start Date"
      />
      <Icon color={{ light: 'gray500', dark: 'gray300' }} icon="arrow-right" size={space(12)} />
      <TextInput
        icon="calendar"
        type="date"
        inputProps={{
          min: DateTime.fromISO(startDate!).toISODate(),
          max: DateTime.now().toISODate(),
        }}
        value={DateTime.fromISO(endDate!).toISODate()}
        onChange={(value) => {
          setEndDate(DateTime.fromISO(value).endOf('day').toISO());
        }}
        aria-label="End Date"
      />
    </>
  );
}

import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  HStack,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
} from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { type IPSecTunnel, IPSecTunnelsQuery, rpcRestartIPSecTunnelMutation } from './utils';

type RestartIPSecTunnnelDialogProps = {
  tunnel: IPSecTunnel;
  state: OverlayTriggerState;
};

export default function RestartIPSecTunnnelDialog({
  tunnel,
  state,
}: RestartIPSecTunnnelDialogProps) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const restartIPSecTunnel = useGraphQLMutation(rpcRestartIPSecTunnelMutation);
  const { mutate, isError, error } = restartIPSecTunnel;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleRestart = useCallback(() => {
    const serialNumber = tunnel.boundPhyInterface?.virtualDevice?.hardwareDevice?.serialNumber;
    expectDefinedOrThrow(
      serialNumber,
      new ResourceNotFoundError('IPSec tunnel: Serial number for bound interface not found.'),
    );
    mutate(
      {
        serialNumber,
        ipSecTunnelUUID: tunnel.UUID,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(IPSecTunnelsQuery, { networkUUID: network.UUID }),
          );
          closeDrawer();
          close();
          notify('IPSec tunnel successfully initiated restart.', {
            variant: 'positive',
          });
        },
      },
    );
  }, [
    mutate,
    tunnel?.UUID,
    tunnel.boundPhyInterface,
    close,
    network.UUID,
    queryClient,
    closeDrawer,
  ]);

  if (!tunnel) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="ipsec-tunnel" heading="Restart IPSec tunnel" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={
            <>
              You're about to restart the IPSec tunnel <Text weight="bold">{tunnel.name}</Text> on
              your Meter network.
            </>
          }
        />

        {tunnel.boundVLANs?.length && (
          <SummaryList gutter="vertical">
            <SummaryListRow>
              <SummaryListKey>Affected VLAN{tunnel.boundVLANs.length > 1 && 's'}</SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  {tunnel.boundVLANs.map((binding) => (
                    <Badge
                      variant="neutral"
                      size="small"
                      icon="vlan"
                      arrangement="leading-icon"
                      key={binding.UUID}
                    >
                      {binding.name}
                    </Badge>
                  ))}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        )}

        {isError && (
          <Alert
            icon="warning"
            variant="negative"
            copy={`There was an error restarting the IPSec tunnel${getGraphQLErrorMessageOrEmpty(error)}.`}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleRestart} variant="destructive">
              Restart
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

import { PaneHeader, Tab } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';

import EventLogFilters from '../../../../../components/EventLogFilters';
import { KeyBoundSearchInput } from '../../../../../components/KeyBoundSearchInput';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { paths } from '../../../../../constants';
import { useActiveControllerForNetwork } from '../../../../../hooks/useActiveControllerForNetwork';
import { useControllerDataFromPathOrNull } from '../../../../../hooks/useControllerDataFromPath';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../../../../../hooks/useIsActive';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { useInsightsCrumbs, useNavigateBack, useNavigateHome } from '../../../../../utils/routing';
import NetworkMutationsFilters from './NetworkMutationsFilters';
import StatsFilters from './StatsFilters';

export default function LogsPageHeader() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const insightsCrumbs = useInsightsCrumbs();
  const { controllerNameFromPath } = useControllerDataFromPathOrNull();
  const activeControllerForNetwork = useActiveControllerForNetwork(network);

  const controllerName =
    controllerNameFromPath ?? activeControllerForNetwork?.hardwareDevice?.serialNumber;

  const isActiveTest = useIsActiveMatcher();

  const isControllerEventsActive = isActiveTest({
    path: paths.pages.EventsListPage,
    end: true,
  });
  const isNetworkMutationsActive = isActiveTest({
    path: paths.pages.NetworkMutationsListPage,
    end: true,
  });
  const isStatsActive = isActiveTest({
    path: paths.pages.StatsListPage,
    end: true,
  });
  const isEventLogActive = isActiveTest({
    path: paths.pages.EventLogPage,
    end: true,
  });

  const isOperator = useIsOperator({ respectDemoMode: true });
  const flags = useFeatureFlags();
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);

  return (
    <PaneHeader
      back={back}
      home={home}
      crumbs={[
        ...insightsCrumbs,
        {
          type: 'page',
          page: {
            as: ReactRouterLink,
            to: makeLink(paths.pages.EventLogPage, { companyName, networkSlug: network.slug }),
            selected: true,
            label: 'Logs',
          },
        },
      ]}
      icon="log"
      heading="Logs"
      contentActions={
        <>
          {isOperator && isNetworkMutationsActive && <NetworkMutationsFilters />}
          {isOperator && isStatsActive && <StatsFilters />}
          {isEventLogActive && <EventLogFilters />}
          <KeyBoundSearchInput
            placeholder="..."
            aria-label="Filter logs"
            scope="scoped"
            searchParamStateKey="search"
            minWidth="56px"
          />
        </>
      }
      {...(isOperator
        ? {
            tabs: (
              <>
                {!isCOS2Enabled && controllerName && flags['controller-events'] && (
                  <Tab
                    as={ReactRouterLink}
                    to={makeLink(paths.pages.EventsListPage, {
                      controllerName,
                      companyName,
                    })}
                    selected={isControllerEventsActive}
                  >
                    Events
                  </Tab>
                )}
                {network?.slug && (
                  <>
                    <Tab
                      as={ReactRouterLink}
                      to={makeLink(paths.pages.NetworkMutationsListPage, {
                        networkSlug: network.slug,
                        companyName,
                      })}
                      selected={isNetworkMutationsActive}
                      internal={!flags['network-mutation-audit-log']}
                    >
                      Network mutations
                    </Tab>
                    <Tab
                      as={ReactRouterLink}
                      to={makeLink(paths.pages.StatsListPage, {
                        networkSlug: network.slug,
                        companyName,
                      })}
                      selected={isStatsActive}
                      internal={!flags['stats-log']}
                    >
                      Stats
                    </Tab>
                  </>
                )}
              </>
            ),
          }
        : {})}
    />
  );
}

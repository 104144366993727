import { Pane, PaneContent, PaneHeader, space } from '@meterup/atto';
import IsOperator from '@meterup/authorization/src/components/IsOperator';
import { checkDefinedOrThrow } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Suspense } from 'react';

import { LocationSwitcher } from '../../../../components/LocationSwitcher';
import { StackedSkeletons } from '../../../../components/Placeholders/AppLoadingFallback';
import MaintenanceWindow from '../../../../components/Settings/Network/MaintenanceWindow';
import NetworkMultiWANAlgorithm from '../../../../components/Settings/Network/MultiWANAlgorithm';
import { networkQuery } from '../../../../components/Settings/Network/utils';
import { paths } from '../../../../constants';
import { useNetworkForSettingsPath } from './NetworkUtils';

export const Meta = () => ({
  path: '/org/:companyName/settings/network/:networkSlug/general',
  title: 'General - Network',
});

export default function SettingsNetworkGeneralPage() {
  const selectedNetwork = useNetworkForSettingsPath({
    path: paths.pages.SettingsNetworkGeneralPage,
  });

  const { network } = checkDefinedOrThrow(
    useGraphQL(networkQuery, { uuid: selectedNetwork.UUID }).data,
  );

  return (
    <Pane variant="adjusted">
      <PaneHeader
        icon="information"
        heading="General"
        views={
          <LocationSwitcher
            networkSlug={selectedNetwork.slug}
            shouldNotChangePages
            routePathsNotToChange={[paths.pages.SettingsNetworkGeneralPage]}
          />
        }
      />
      <PaneContent gutter="all" spacing={space(16)}>
        <Suspense fallback={<StackedSkeletons count={2} height={76} />}>
          <MaintenanceWindow network={selectedNetwork} key={selectedNetwork.UUID} />
        </Suspense>
        <IsOperator>
          <NetworkMultiWANAlgorithm network={network} />
        </IsOperator>
      </PaneContent>
    </Pane>
  );
}

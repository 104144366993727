import {
  Button,
  Card,
  Cards,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  Pane,
  PaneContent,
  PaneHeader,
  Skimmer,
  SkimmerContent,
  SkimmerHeader,
  Tab,
  useDialogState,
} from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import { paths } from '../../constants';
import { PermissionType } from '../../gql/graphql';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import IsPermitted from '../permissions/IsPermitted';
import { ReactRouterLink } from '../ReactRouterLink';
import AutoVPNGroupDeleteDialog from './AutoVPNGroupDeleteDialog';
import { AddAutoVPNGroupButton } from './AutoVPNGroupHeader';
import AutoVPNGroupMembers from './AutoVPNGroupMembers';
import AutoVPNGroupRoutes from './AutoVPNGroupRoutes';
import { type AutoVPNGroup, AutoVPNGroupTab } from './utils';
import { AutoVPNGroupsQuery } from './utils';

export default function AutoVPN({
  groupUUID,
  activeTab = AutoVPNGroupTab.Members,
}: {
  groupUUID?: string;
  activeTab?: AutoVPNGroupTab;
}) {
  const companySlug = useCurrentCompany();

  const groupsData = useGraphQL(AutoVPNGroupsQuery, { companySlug })?.data?.autoVPNGroupsForCompany;

  const { state: deleteDialogState } = useDialogState();

  const sortedGroups: AutoVPNGroup[] = useMemo(
    () => groupsData?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [groupsData],
  );

  const activeGroup = useMemo(
    () => sortedGroups.find((group) => group.UUID === groupUUID) ?? sortedGroups[0] ?? null,
    [groupUUID, sortedGroups],
  );

  return sortedGroups.length > 0 ? (
    <>
      <Skimmer layoutMode="detailed">
        <SkimmerHeader icon="auto-vpn" heading="Auto VPN" actions={<AddAutoVPNGroupButton />} />
        <SkimmerContent gutter="none">
          <Cards>
            {sortedGroups.map((group) => (
              <Card
                as={ReactRouterLink}
                to={makeLink(paths.pages.AutoVPNGroupPage, {
                  companyName: companySlug,
                  groupUUID: group.UUID,
                  activeTab,
                })}
                selected={group.UUID === activeGroup?.UUID}
                headline={group.name}
                stats={[
                  {
                    label: 'Hub',
                    value: group.hubNetwork.label,
                  },
                  ...(group.hubUplink
                    ? [
                        {
                          label: 'Subnet',
                          value: `${group.hubUplink.address}/${group.hubUplink.addressPrefixLength}`,
                        },
                      ]
                    : []),
                ]}
              />
            ))}
          </Cards>
        </SkimmerContent>
      </Skimmer>
      <Pane layoutMode="detailed">
        <PaneHeader
          icon="site-to-site"
          heading={activeGroup?.name ?? 'Auto VPN group'}
          tabs={
            <>
              <Tab
                as={ReactRouterLink}
                to={makeLink(paths.pages.AutoVPNGroupPage, {
                  companyName: companySlug,
                  groupUUID: activeGroup.UUID,
                  activeTab: AutoVPNGroupTab.Members,
                })}
                icon="site-to-site"
                selected={activeTab === AutoVPNGroupTab.Members}
              >
                Members
              </Tab>
              <Tab
                as={ReactRouterLink}
                to={makeLink(paths.pages.AutoVPNGroupPage, {
                  companyName: companySlug,
                  groupUUID: activeGroup.UUID,
                  activeTab: AutoVPNGroupTab.Routes,
                })}
                icon="connection"
                selected={activeTab === AutoVPNGroupTab.Routes}
              >
                Routes
              </Tab>
            </>
          }
          actions={
            activeGroup && (
              <IsPermitted permissions={PermissionType.PermAutoVpnWrite}>
                <Button
                  as={ReactRouterLink}
                  to={makeDrawerLink(window.location, paths.drawers.AutoVPNGroupConfigEditPage, {
                    companyName: companySlug,
                    groupUUID: activeGroup.UUID!,
                  })}
                  icon="pencil"
                  variant="secondary"
                  arrangement="leading-icon"
                >
                  Edit
                </Button>
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem icon="trash-can" onSelect={deleteDialogState.open}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
                {activeGroup && (
                  <AutoVPNGroupDeleteDialog state={deleteDialogState} group={activeGroup} />
                )}
              </IsPermitted>
            )
          }
          contentActions={
            activeGroup && (
              <IsPermitted permissions={PermissionType.PermAutoVpnWrite}>
                {activeTab === AutoVPNGroupTab.Members && (
                  <Button
                    as={ReactRouterLink}
                    to={makeDrawerLink(window.location, paths.drawers.AutoVPNMemberAddPage, {
                      companyName: companySlug,
                      groupUUID: activeGroup.UUID!,
                    })}
                    icon="plus"
                    variant="secondary"
                    arrangement="leading-icon"
                  >
                    Add member
                  </Button>
                )}
                {activeTab === AutoVPNGroupTab.Routes && (
                  <Button
                    as={ReactRouterLink}
                    to={makeDrawerLink(window.location, paths.drawers.AutoVPNGroupRoutesAddPage, {
                      companyName: companySlug,
                      groupUUID: activeGroup.UUID!,
                    })}
                    icon="plus"
                    variant="secondary"
                    arrangement="leading-icon"
                  >
                    Add route
                  </Button>
                )}
              </IsPermitted>
            )
          }
        />
        <PaneContent>
          {activeTab === AutoVPNGroupTab.Members && <AutoVPNGroupMembers group={activeGroup} />}
          {activeTab === AutoVPNGroupTab.Routes && <AutoVPNGroupRoutes group={activeGroup} />}
        </PaneContent>
      </Pane>
    </>
  ) : (
    <Pane>
      <PaneContent>
        <EmptyState
          icon="site-to-site"
          heading="No Auto VPN groups"
          action={
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(window.location, paths.drawers.AutoVPNGroupCreatePage, {
                companyName: companySlug,
              })}
              arrangement="leading-icon"
              icon="plus"
              variant="secondary"
            >
              Add group
            </Button>
          }
        />
      </PaneContent>
    </Pane>
  );
}

import { Button, Tooltip } from '@meterup/atto';
import React from 'react';

import { useTimedToggle } from '../hooks/useTimedToggle';
import { copyTextToClipboard } from '../utils/clipboard';

interface CopyToClipboardButtonProps {
  text: string;
}

export function CopyToClipboardButtonMinimal({ text }: CopyToClipboardButtonProps) {
  const { value, show } = useTimedToggle();

  const handleClick = async () => {
    await copyTextToClipboard(text);
    show();
  };

  return (
    <Tooltip contents={value ? 'Copied' : 'Copy to clipboard'}>
      <Button
        type="button"
        arrangement="hidden-label"
        size="small"
        variant="secondary"
        icon={value ? 'checkmark' : 'copy'}
        onClick={handleClick}
      >
        {value ? 'Copied' : 'Copy to clipboard'}
      </Button>
    </Tooltip>
  );
}

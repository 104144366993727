import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { getGraphQLError, useGraphQLMutation } from '@meterup/graphql';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import type { SwitchVirtualDevice } from './utils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import DeviceConfigEditorDialog from '../../../routes/pages/network/operators/device_config/DeviceConfigEditorDialog';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { blinkLEDsMutation, refreshConfigMutation } from '../../Devices/utils';
import IsPermitted from '../../permissions/IsPermitted';
import { useIsPermitted } from '../../permissions/useIsPermitted';
import SwitchDeleteDialog from './SwitchDeleteDialog';
import { SwitchRebootDialog } from './SwitchRebootDialog';
import { getSwitchStatus } from './utils';

type SwitchActionsProps = {
  virtualDevice: SwitchVirtualDevice;
  view: 'detail' | 'drawer' | 'edit' | 'list';
};

export default function SwitchActions({ virtualDevice, view }: SwitchActionsProps) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();

  const ledMutation = useGraphQLMutation(blinkLEDsMutation);
  const configMutation = useGraphQLMutation(refreshConfigMutation);

  const { state: rebootDialogState } = useDialogState();
  const { state: configDialogState } = useDialogState();
  const deleteDialogState = useDialogState();

  const status = getSwitchStatus(virtualDevice);

  const serialNumber = virtualDevice?.hardwareDevice?.serialNumber;

  const { mutate: mutateLED } = ledMutation;
  const flash = useCallback(() => {
    if (!serialNumber) return;

    mutateLED(
      {
        serialNumber,
      },
      {
        onSuccess: () => {
          notify('Request sent to flash LEDs on this switch', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error flashing LEDs on this switch${
              gqlErr?.message ? `: ${gqlErr.message}` : ''
            }.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [serialNumber, mutateLED]);

  const { mutate: mutateConfig } = configMutation;
  const refreshConfig = useCallback(() => {
    if (!serialNumber) return;

    mutateConfig(
      {
        serialNumber,
      },
      {
        onSuccess: () => {
          notify('Request sent to switch to pull its config', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(`There was an error${gqlErr?.message ? `: ${gqlErr.message}` : ''}`, {
            variant: 'negative',
          });
        },
      },
    );
  }, [serialNumber, mutateConfig]);

  const isOffline = !status || status === 'offline';

  const openDetails = () => {
    navigate(
      makeLink(paths.pages.SwitchDetailPage, {
        networkSlug: network.slug,
        companyName,
        uuid: virtualDevice.UUID,
        tab: 'insights',
      }),
    );
  };

  const canEditSwitchRestricted = useIsPermitted({
    isPermitted({ permissions, nosFlags }) {
      return Boolean(
        permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
          nosFlags[NosFeature.SOS],
      );
    },
  });

  return (
    <>
      {view !== 'edit' && (
        <IsPermitted
          isPermitted={({ permissions, nosFlags }) =>
            Boolean(
              permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
                permissions.hasPermission(PermissionType.PermSwitchProfileWrite) &&
                nosFlags[NosFeature.SOS],
            )
          }
        >
          <Button
            as={Link}
            to={makeDrawerLink(window.location, paths.drawers.SwitchEditPage, {
              companyName,
              networkSlug: network.slug,
              id: virtualDevice.UUID,
            })}
            arrangement="leading-icon"
            icon="pencil"
            variant="secondary"
          >
            Edit
          </Button>
        </IsPermitted>
      )}

      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesRead) &&
              nosFlags[NosFeature.SOS],
          )
        }
      >
        <DropdownMenu>
          <DropdownMenuButton
            variant="secondary"
            icon="overflow-horizontal"
            arrangement="hidden-label"
          >
            Actions
          </DropdownMenuButton>

          <DropdownMenuPopover align="end">
            {view !== 'detail' && (
              <DropdownMenuGroup>
                <DropdownMenuItem icon="switch" onSelect={openDetails}>
                  View details
                </DropdownMenuItem>
              </DropdownMenuGroup>
            )}

            <DropdownMenuGroup>
              <IsPermitted
                anyPermissions={[
                  PermissionType.PermNetworkDevicesWrite,
                  PermissionType.PermHardwareDeviceRpcBlinkLeds,
                ]}
              >
                <DropdownMenuItem
                  disabled={ledMutation.isLoading || isOffline}
                  onSelect={flash}
                  icon="flash-leds"
                >
                  Flash LEDs
                </DropdownMenuItem>
              </IsPermitted>
              <IsPermitted permissions={PermissionType.PermNetworkDevicesWrite}>
                <DropdownMenuItem
                  disabled={isOffline}
                  onSelect={rebootDialogState.open}
                  icon="power-cycle"
                >
                  Reboot
                </DropdownMenuItem>
              </IsPermitted>
            </DropdownMenuGroup>

            <IsPermitted
              anyPermissions={[
                PermissionType.PermHardwareDeviceRpcRefreshConfig,
                PermissionType.PermNetworkDevicesWriteRestricted,
                PermissionType.PermNetworkDevicesWrite,
              ]}
            >
              <DropdownMenuGroup>
                <IsPermitted
                  anyPermissions={[
                    PermissionType.PermHardwareDeviceRpcRefreshConfig,
                    PermissionType.PermNetworkDevicesWrite,
                  ]}
                >
                  <DropdownMenuItem
                    disabled={configMutation.isLoading || isOffline}
                    onSelect={refreshConfig}
                    icon="arrows-rotate"
                  >
                    Refresh config
                  </DropdownMenuItem>
                </IsPermitted>
                <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
                  <DropdownMenuItem onSelect={configDialogState.open} icon="code" internal>
                    Show config
                  </DropdownMenuItem>
                </IsPermitted>
              </DropdownMenuGroup>
            </IsPermitted>
            <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
              <DropdownMenuGroup>
                <DropdownMenuItem
                  disabled={!!virtualDevice?.hardwareDevice}
                  icon="trash-can"
                  onSelect={deleteDialogState.state.open}
                  title={
                    virtualDevice?.hardwareDevice
                      ? 'Please unassign any hardware before deleting the virtual device'
                      : undefined
                  }
                  internal
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </IsPermitted>
          </DropdownMenuPopover>
        </DropdownMenu>
      </IsPermitted>

      {virtualDevice && canEditSwitchRestricted && (
        <SwitchDeleteDialog state={deleteDialogState.state} uuid={virtualDevice.UUID} />
      )}
      {virtualDevice.hardwareDevice && canEditSwitchRestricted && (
        <>
          <SwitchRebootDialog
            state={rebootDialogState}
            serialNumber={virtualDevice.hardwareDevice.serialNumber}
            label={virtualDevice.label}
          />
          <DeviceConfigEditorDialog
            state={configDialogState}
            serialNumber={virtualDevice.hardwareDevice.serialNumber}
          />
        </>
      )}
    </>
  );
}

import type { TickLabelProps } from '@visx/axis';
import type { RenderTooltipParams } from '@visx/xychart/lib/components/Tooltip';
import type { ReactNode } from 'react';
import {
  backgrounds,
  Badge,
  Caption,
  HStack,
  Section,
  SectionContent,
  SectionHeader,
  sizing,
  Small,
  space,
} from '@meterup/atto';
import { darkThemeSelector, fonts, fontWeights, styled } from '@meterup/atto/src/stitches.config';
import { Tooltip as MeterTooltip } from '@meterup/common';
import {
  AreaSeries,
  Axis,
  buildChartTheme,
  DataContext,
  LineSeries,
  Tooltip,
  XYChart,
} from '@visx/xychart';
import { DateTime } from 'luxon';
import React, { useContext, useMemo } from 'react';
import { useId } from 'react-aria';

import type { TimeSeriesData, TimeSeriesDataValue } from '../api/types';
import { ThemeContext } from '../providers/ThemeProvider';
import { colors, shadows } from '../stitches';

// We'll treat any tick as `diffNow` within this range to be considered "now".
const DELTA_MINUTES_FOR_NOW = 10;

const lightTheme = buildChartTheme({
  svgLabelSmall: {
    fontFamily: fonts.sans.toString(),
    fontWeight: fontWeights.regular.toString(),
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 0,
    fill: colors.bodyNeutralLight.toString(),
  },
  colors: [colors.iconBrandLight.toString(), colors.iconPositiveDark.toString()],
  gridColor: colors.brand200.toString(),
  backgroundColor: colors.bgApplicationLight.toString(),
  gridColorDark: colors.strokeNeutralLight.toString(),
  tickLength: 4,
});

const darkTheme = buildChartTheme({
  svgLabelSmall: {
    fontFamily: fonts.sans.toString(),
    fontWeight: fontWeights.regular.toString(),
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 0,
    fill: colors.bodyNeutralDark.toString(),
  },
  colors: [colors.iconBrandDark.toString(), colors.iconPositiveDark.toString()],
  gridColor: colors.brand800.toString(),
  backgroundColor: colors.bgApplicationDark.toString(),
  gridColorDark: colors.strokeNeutralDark.toString(),
  tickLength: 4,
});

const TooltipItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$6',
  width: '100%',
});

const TooltipContainer = styled('div', {
  vStack: '$4',
  alignItems: 'flex-start',
  minWidth: '160px',
  padding: '$6 $8',
  borderRadius: '$8',
  background: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },
});

const ThemeAwareRect = styled('rect', {
  fill: colors.bgNeutralLight,

  [darkThemeSelector]: {
    fill: colors.bgNeutralDark,
  },
});

const ThemeAwareStrokeLeft = styled('line', {
  stroke: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    stroke: colors.strokeNeutralDark,
  },
});

const ThemeAwareStrokeBottom = styled('line', {
  stroke: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    stroke: colors.strokeNeutralDark,
  },
});

const ThemeAwareLine = styled('line', {
  stroke: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    stroke: colors.strokeNeutralDark,
  },
});

function CheckeredBackground() {
  const id = useId();
  const {
    width = 0,
    height = 0,
    margin = { left: 0, right: 0, top: 0, bottom: 0 },
  } = useContext(DataContext);

  const backgroundWidth = width > 0 ? width - margin.left : 0;

  return (
    <>
      <pattern id={id} x="0" y="0" width="8" height="8" patternUnits="userSpaceOnUse">
        <rect x="0" width="4" height="4" y="0" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="0" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="4" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="0" width="4" height="4" y="4" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
      </pattern>
      <ThemeAwareRect x={margin.left} width={backgroundWidth} height={height - margin.bottom} />
      <rect
        x={margin.left}
        width={backgroundWidth}
        height={height - margin.bottom}
        fill={`url(#${id})`}
      />
      <ThemeAwareLine
        x1={margin.left}
        x2={width - margin.right}
        y1={height - margin.bottom}
        y2={height - margin.bottom}
      />
      <ThemeAwareStrokeLeft x1={margin.left} x2={margin.left} y1={0} y2={height - margin.bottom} />
      <ThemeAwareStrokeBottom
        x1={margin.left}
        x2={width}
        y1={height - margin.bottom}
        y2={height - margin.bottom}
      />
    </>
  );
}

function StatusBadge({ isError }: { isError: Boolean }) {
  if (isError) {
    return (
      <MeterTooltip
        side="right"
        sideOffset={6}
        content="There was an error getting this data from the API. Please try again."
      >
        <Badge arrangement="leading-icon" ends="pill" icon="cross" size="small" variant="negative">
          Error
        </Badge>
      </MeterTooltip>
    );
  }

  return null;
}

function SeriesLine({
  data,
  index,
  title,
}: {
  data: TimeSeriesData;
  index: number;
  title: string;
}) {
  const { dark } = useContext(ThemeContext);
  const gradientId = useId();

  if (data.series_type === 'area') {
    let gradientStopColor;
    let lineProps: {
      stroke: string;
      strokeWidth: number;
      strokeDasharray?: string;
      strokeLinecap?: 'round';
    };

    if (index > 0) {
      gradientStopColor = dark
        ? colors.iconPositiveDark.toString()
        : colors.iconPositiveLight.toString();
      lineProps = {
        stroke: gradientStopColor,
        strokeWidth: 2,
        strokeDasharray: '0 4',
        strokeLinecap: 'round',
      };
    } else {
      gradientStopColor = dark ? colors.iconBrandDark.toString() : colors.iconBrandLight.toString();
      lineProps = { stroke: gradientStopColor, strokeWidth: 1.25 };
    }

    return (
      <>
        <defs>
          <linearGradient
            id={gradientId}
            x1="50%"
            y1="100%"
            x2="50%"
            y2="0%"
            gradientUnits="objectBoundingBox"
          >
            <stop stopColor={gradientStopColor} stopOpacity="0" />
            <stop offset="0.5" stopColor={gradientStopColor} stopOpacity="0.14" />
            <stop offset="1" stopColor={gradientStopColor} stopOpacity="0.14" />
          </linearGradient>
        </defs>
        <AreaSeries
          dataKey={title}
          data={data.data}
          xAccessor={(d) => d.timestamp}
          yAccessor={(d) => d.value}
          curve={data.curve}
          renderLine
          fill={`url(#${gradientId})`}
          lineProps={lineProps}
        />
      </>
    );
  }

  return (
    <LineSeries
      dataKey={title}
      data={data.data}
      xAccessor={(d) => d.timestamp}
      yAccessor={(d) => d.value}
      curve={data.curve}
      strokeWidth={1.25}
    />
  );
}

const ChartEmptyInner = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  background: backgrounds.checkeredLight,
  backgroundColor: colors.bgNeutralLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: backgrounds.checkeredDark,
    backgroundColor: colors.bgNeutralDark,
  },
});

const ChartEmpty = styled('div', {
  display: 'flex',
  padding: sizing.contentEndsOnly,
});

const ChartContainer = styled('div', {
  background: colors.bgApplicationLight,
  strokeTopBottom: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeTopBottom: colors.strokeNeutralDark,
  },
});

export interface TimeSeriesChartProps {
  title: string;
  titleBadge?: React.ReactNode;
  tooltipBody: React.ReactNode;
  series: TimeSeriesData[];
  valueFormatter: (value: number) => string;
  showYAxis?: boolean;
  yTicks?: number[];
  yDomain?: number[];
  isError: Boolean;
  timePeriod: string;
}

export function TimeSeriesChart({
  title,
  titleBadge,
  tooltipBody,
  series,
  valueFormatter,
  showYAxis = true,
  yTicks,
  yDomain,
  isError,
  timePeriod,
}: TimeSeriesChartProps) {
  const { dark } = useContext(ThemeContext);
  const chartTheme = dark ? darkTheme : lightTheme;

  const computedYDomain = useMemo((): number[] => {
    if (yDomain) return yDomain;

    const min = Math.min(...series.map((val) => val.range.min));
    const max = Math.max(...series.map((val) => val.range.max));
    const delta = max - min;

    // This 10% pad can be adjusted if necessary, but it visually seems to be pretty acceptable.
    // For area series, it adds 10% to the top of the chart, and for line series, it seems to keep
    // the line relatively centered.
    const percent = 10;
    const pad = Math.max((delta / 100) * percent, 5);

    // The domain goes from 0 to the sum of max + min + pad. Including the min does not change area
    // series, they still get drawn from 0 to the max value + 10%. Including the min has a major
    // effect on the line series, as it is the logic that keeps the line in the center of the graph.
    return [0, max + min + pad];
  }, [series, yDomain]);

  const computedXTickValues = useMemo(() => {
    const { data } = series[0] || {};

    // If we got no data in the response, we don't have any ticks to show.
    if (!data || data.length === 0) {
      return [];
    }

    const first = data[0];
    const mid = data[Math.floor(data.length / 2)];
    const last = data[data.length - 1];

    return [first.timestamp, mid.timestamp, last.timestamp];
  }, [series]);

  const tickLabelProps: TickLabelProps<any> = (
    tickValue: Date,
    i: number,
    allValues: { value: Date; index: number }[],
  ) => {
    if (i === 0) {
      return { textAnchor: 'start' };
    }
    if (i === allValues.length - 1) {
      const props: TickLabelProps<any> = { textAnchor: 'end' };
      if (
        Math.abs(DateTime.fromJSDate(tickValue).diffNow('minutes').minutes) < DELTA_MINUTES_FOR_NOW
      ) {
        props.fill = dark
          ? colors.iconPositiveDark.toString()
          : colors.iconPositiveLight.toString();
      }
      return props;
    }
    return { textAnchor: 'middle' };
  };

  const dateFormatter = (date: Date, format?: string, allowNow = true) => {
    const d = DateTime.fromJSDate(date);

    if (allowNow && Math.abs(d.diffNow('minutes').minutes) < DELTA_MINUTES_FOR_NOW) {
      return '• Now';
    }

    if (format) {
      return d.toFormat(format);
    }

    if (['1h', '6h', '24h'].includes(timePeriod)) {
      return d.toFormat('t');
    }

    return d.toFormat('LLL d');
  };

  const renderTooltip = (params: RenderTooltipParams<TimeSeriesDataValue>): ReactNode => {
    const { tooltipData } = params;
    if (tooltipData === undefined) return null;
    if (tooltipData.nearestDatum === undefined) return null;

    const vals = Object.entries(tooltipData.datumByKey).map(([k, v]) => (
      <TooltipItem key={`tooltip-${k}`}>
        <Small weight="bold">{k}</Small>
        <Badge size="small">{valueFormatter(v.datum.value)}</Badge>
      </TooltipItem>
    ));

    return (
      <TooltipContainer>
        <Caption weight="bold">
          {dateFormatter(tooltipData.nearestDatum.datum.timestamp, 'MMMM d, t', false)}
        </Caption>
        {vals}
      </TooltipContainer>
    );
  };

  const isEmpty = series.map((data) => data.data).flat().length === 0;

  const baseHeight = 180;

  return (
    <Section relation="stacked">
      <SectionHeader
        heading={
          <HStack spacing={space(8)} align="baseline">
            <MeterTooltip content={tooltipBody} side="right" sideOffset={6} align="start" showHint>
              {title}
            </MeterTooltip>
            {titleBadge}
            <StatusBadge isError={isError} />
          </HStack>
        }
      />
      <SectionContent gutter="horizontal">
        <ChartContainer>
          {isEmpty ? (
            <ChartEmpty style={{ height: `${baseHeight}px` }}>
              <ChartEmptyInner>
                <Small>No data to display</Small>
              </ChartEmptyInner>
            </ChartEmpty>
          ) : (
            <XYChart
              height={baseHeight}
              xScale={{ type: 'time' }}
              yScale={{ type: 'linear', domain: computedYDomain }}
              margin={{ top: 12, left: showYAxis ? 92 : 0, right: 0, bottom: 24 }}
              theme={chartTheme}
            >
              <CheckeredBackground />
              {showYAxis && (
                <Axis
                  orientation="left"
                  numTicks={4}
                  tickFormat={valueFormatter}
                  strokeWidth={0}
                  tickValues={yTicks}
                />
              )}
              <Axis
                orientation="bottom"
                strokeWidth={0}
                tickValues={computedXTickValues}
                tickFormat={(d) => dateFormatter(d)}
                tickLabelProps={tickLabelProps}
              />

              {series.map((data, index) => (
                <SeriesLine
                  key={`${data.series_id}-${data.series_name}`}
                  title={data.series_name || title}
                  data={data}
                  index={index}
                />
              ))}

              <Tooltip<TimeSeriesDataValue>
                snapTooltipToDatumX
                showVerticalCrosshair
                showSeriesGlyphs
                unstyled
                applyPositionStyle
                renderTooltip={renderTooltip}
              />
            </XYChart>
          )}
        </ChartContainer>
      </SectionContent>
    </Section>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import LogsPageHeader from './LogsPageHeader';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/logs',
  layout: 'VirtualNetworkLayout',
});

export default function NetworkLogsLayout() {
  return <DetailPageLayout header={<LogsPageHeader />} />;
}

import {
  Body,
  CopyBox,
  Drawer,
  DrawerContent,
  DrawerHeader,
  HStack,
  Port,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';

import { NoValue } from '../../../../components/NoValue';
import { StickyObjectHeader } from '../../../../components/Object/ObjectHeader';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useAPIClient } from '../../../../providers/APIClientProvider';
import { secToHuman } from '../../../../utils/time';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/details',
});

export default function ControllerPage() {
  const api = useAPIClient();

  const controller = useQuery(['controller_devices'], () => api.devicesForController(), {
    suspense: true,
  }).data;

  const networkInfo = api.controller;

  const uptime = useQuery(['controller_uptime', networkInfo.name], () =>
    api.fetchLatestUptimeMetric(networkInfo.name),
  ).data;

  return (
    <Drawer>
      <DrawerHeader
        icon="security-appliance"
        heading="Security appliance"
        onClose={useCloseDrawerCallback()}
      />
      <DrawerContent gutter="none">
        <StickyObjectHeader
          icon="security-appliance"
          name="Security appliance"
          status={controller?.connected_status === 'online' ? 'online' : 'offline'}
        />
        <Section relation="stacked">
          <SectionHeader icon="information" heading="Metadata" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <SummaryListRow>
                <SummaryListKey>
                  IP address {controller?.uses_dhcp ? '(Dynamic)' : '(Static)'}
                </SummaryListKey>
                <SummaryListValue>
                  {controller?.ip_address ? (
                    <Body family="monospace">{controller?.ip_address}</Body>
                  ) : (
                    <NoValue />
                  )}
                </SummaryListValue>
              </SummaryListRow>
              {isDefinedAndNotEmpty(uptime) && (
                <SummaryListRow>
                  <SummaryListKey>Uptime</SummaryListKey>
                  <SummaryListValue>{secToHuman(uptime)}</SummaryListValue>
                </SummaryListRow>
              )}
              <SummaryListRow>
                <SummaryListKey>WAN ports</SummaryListKey>
                <SummaryListValue>
                  <HStack spacing={space(8)}>
                    {controller?.wan_metrics.map((status, index) => (
                      <Port
                        port="ethernet"
                        number={index}
                        status={status.link_status_is_online ? 'connected' : 'disconnected'}
                        uplink={status.quality === 1 && status.link_status_is_online}
                        variant="simple"
                      />
                    ))}
                  </HStack>
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Timezone</SummaryListKey>
                <SummaryListValue>
                  {networkInfo?.timezone ? (
                    <Body family="monospace">{networkInfo?.timezone}</Body>
                  ) : (
                    <NoValue />
                  )}
                </SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
        </Section>
        <Section relation="stacked">
          <SectionHeader icon="information" heading="Tunnel" />
          <SectionContent gutter="all">
            <SummaryList gutter="none">
              <SummaryListRow>
                <SummaryListKey>Tunnel address</SummaryListKey>
                <SummaryListValue>
                  {networkInfo?.tunnel_addr ? (
                    <CopyBox
                      aria-label="Copy tunnel address"
                      relation="stacked"
                      size="small"
                      value={networkInfo?.tunnel_addr}
                    >
                      {networkInfo?.tunnel_addr}
                    </CopyBox>
                  ) : (
                    <NoValue />
                  )}
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Tunnel public key</SummaryListKey>
                <SummaryListValue>
                  {networkInfo?.tunnel_public_key ? (
                    <CopyBox
                      aria-label="Copy public key"
                      relation="stacked"
                      size="small"
                      value={String(networkInfo?.tunnel_public_key)}
                    >
                      {networkInfo?.tunnel_public_key}
                    </CopyBox>
                  ) : (
                    <NoValue />
                  )}
                </SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
        </Section>
      </DrawerContent>
    </Drawer>
  );
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { useSafeNavigate } from '@meterup/connect-ui/src/components/hooks/useSafeNavigate';
import { StatusTabItems } from '@meterup/connect-ui/src/hooks/useActiveStatusTab';
import useProcessedContracts from '@meterup/connect-ui/src/hooks/useProcessedContracts';
import LocationsDisplay from 'connect-frontend/src/dashboard/components/Locations/LocationsDisplay';
import {
  getQuoteSelectionStage,
  isPreSelectionStage,
} from 'connect-frontend/src/dashboard/components/Locations/LocationsTableColumns';
import React, { useMemo } from 'react';

import { BulkAddLocationsButton } from '../../../../components/Connect/BulkAddLocationsButton';
import NoLocations from '../../../../components/Connect/NoLocations';
import IsPermitted from '../../../../components/permissions/IsPermitted';
import { paths } from '../../../../constants';
import { PermissionType } from '../../../../gql/graphql';
import { useCompanyContracts } from '../../../../hooks/useContracts';
import { ProductTypes, useUserProductsAccess } from '../../../../hooks/useUserProductsAccess';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeConnectLink } from '../../../../utils/connect_helpers';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/connect/locations',
  layout: 'ConnectLayout',
  title: 'Locations - Connect',
});

export default function ConnectDashboardAllLocationsPage() {
  const companySID = useCurrentCompany();

  const makeLinkForConnect = makeConnectLink({});
  const { state } = useCommand();

  const contracts = useCompanyContracts(companySID);

  const buttons = useMemo(() => <BulkAddLocationsButton companySID={companySID} />, [companySID]);

  const { rawRows } = useProcessedContracts({
    activeFilterType: StatusTabItems.All,
    companySID,
    contracts,
    searchValue: '',
  });
  const access = useUserProductsAccess();

  const safeNavigate = useSafeNavigate();
  useRegisterCommands([
    state.nodeFactory.action({
      id: 'connect-all-locations',
      display: 'View all locations',
      label: 'View all locations',
      priority: Priority.High,
      icon: 'company',
      onSelect() {
        safeNavigate(
          makeLink(paths.pages.ConnectDashboardAllLocationsPage, {
            companyName: companySID,
          }),
        );
      },
    }),
    state.nodeFactory.directory({
      children:
        rawRows.flatMap((contractForDisplay) => {
          const { companyLocation } = contractForDisplay;
          const result = getQuoteSelectionStage(contractForDisplay);
          if (!companyLocation) {
            return [];
          }
          const { name, location, sid: companyLocationSID } = companyLocation;
          const locationName = name || location?.address?.address1 || 'Unnamed location';
          let displayName = `${locationName}: View details`;
          const navigateProps = {
            href: makeLink(paths.pages.ConnectDashboardLocationPage, {
              companyName: companySID,
              companyLocationSID,
            }),
          };
          if (isPreSelectionStage(result)) {
            displayName = `${locationName}: Select a quote`;
            navigateProps.href = makeLinkForConnect({
              name: 'results',
              params: result,
            });
          }
          return state.nodeFactory.action({
            id: `connect-location-${companyLocationSID}`,
            display: displayName,
            label: displayName,
            priority: Priority.Medium,
            icon: 'location',
            onSelect() {
              safeNavigate(navigateProps.href);
            },
          });
        }) ?? [],
      id: 'connect-locations',
      display: 'View details for a specific location',
      label: 'View details for a specific location',
      priority: Priority.High,
      icon: 'location',
    }),
  ]);

  if (!contracts || contracts.length === 0) {
    return <NoLocations />;
  }

  return (
    <IsPermitted
      isPermitted={({ permissions }) =>
        Boolean(permissions.hasPermission(PermissionType.PermCompanyUpdate))
      }
      should404OnAccessDenied
    >
      <LocationsDisplay
        buttons={buttons}
        contracts={contracts}
        hidden={
          access.isCustomerOfProduct(ProductTypes.NETWORK) ? ['renewalDate', 'contractTerm'] : []
        }
        makeURL={makeLinkForConnect}
      />
    </IsPermitted>
  );
}

import { useIsCompanyAdmin, useIsCompanyNetworkAdmin } from '@meterup/authorization';
import { useMemo } from 'react';

import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useNetworkOrNull } from './useNetworkFromPath';

export function useIsCurrentCompanyAdmin() {
  const companySlug = useCurrentCompany();
  return useIsCompanyAdmin({ companySlug });
}

export function useIsCurrentCompanyAndNetworkAdmin() {
  const companySlug = useCurrentCompany();
  const network = useNetworkOrNull();
  const isCompanyAdmin = useIsCompanyAdmin({ companySlug });
  const hasBaseRole = useIsCompanyNetworkAdmin({
    companySlug,
    networkUUID: network?.UUID ?? '',
  });
  return useMemo(() => {
    if (!network) {
      return isCompanyAdmin;
    }
    return hasBaseRole;
  }, [hasBaseRole, isCompanyAdmin, network]);
}

import 'reactflow/dist/style.css';

import type { Connection, Node, NodeProps, ReactFlowInstance } from 'reactflow';
import { Icon, Pane, PaneContent, PaneHeader, Small, space } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { ISPStatusBadge } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { api } from '@meterup/proto';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import ReactFlow, { addEdge, Handle, Position, useEdgesState, useNodesState } from 'reactflow';

import { paths } from '../../../../../constants';
import { graphql } from '../../../../../gql';
import { useNetworkAndActiveControllerForUUID } from '../../../../../hooks/useActiveControllerForNetwork';
import { NosFeature, useNosFeaturesEnabled } from '../../../../../hooks/useNosFeatures';
import { useAPIClient } from '../../../../../providers/APIClientProvider';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { makeDrawerLink, makeLink } from '../../../../../utils/main_and_drawer_navigation';
import {
  nodeHandle,
  nodeLine,
  TopologyArea,
  TopologyControls,
  TopologyLegend,
  TopologyLegendItem,
  TopologyMiniMap,
  TopologyNodeContainer,
} from './Topology2.page';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/design/topology',
  layout: 'NetworkLayout',
  title: 'Topology',
});

const SwitchCountQuery = graphql(`
  query SwitchCountQuery($networkUUID: UUID!) {
    virtualDevicesForNetwork(networkUUID: $networkUUID, filter: { deviceType: SWITCH }) {
      UUID
    }
  }
`);

function GenericNode({ data, sourcePosition = Position.Right }: NodeProps) {
  return (
    <>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
      <TopologyNodeContainer>
        <Small>{data.label}</Small>
      </TopologyNodeContainer>
    </>
  );
}

function ISPNode({ data, sourcePosition = Position.Bottom }: NodeProps) {
  return (
    <>
      <TopologyNodeContainer>
        <Icon icon="globe" size={space(16)} />
        <Small>{data.label}</Small>
        <ISPStatusBadge status={data.status} />
      </TopologyNodeContainer>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
    </>
  );
}

function HardwareNode({
  data,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}: NodeProps) {
  return (
    <>
      <Handle
        type="target"
        position={targetPosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
      <TopologyNodeContainer>
        <Icon icon={data.hardware} size={space(16)} />
        <Small>{data.label}</Small>
      </TopologyNodeContainer>
      <Handle
        type="source"
        position={sourcePosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
    </>
  );
}

function ClientNode({ data, targetPosition = Position.Top }: NodeProps) {
  return (
    <>
      <Handle
        type="target"
        position={targetPosition}
        isConnectable={false}
        style={{ ...nodeHandle }}
      />
      <TopologyNodeContainer>
        <Icon icon="client" size={space(16)} />
        <Small>{data.label}</Small>
      </TopologyNodeContainer>
    </>
  );
}

const nodeTypes = {
  genericNode: GenericNode,
  ispNode: ISPNode,
  hardwareNode: HardwareNode,
  clientNode: ClientNode,
};

export default function TopologyPage() {
  const navigate = useNavigate();
  const apiClient = useAPIClient();

  const pageOptions = { hideAttribution: true };

  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const networkAndController = useNetworkAndActiveControllerForUUID(controller.network_uuid);
  const companyName = useCurrentCompany();
  const [isSOSEnabled, isWOS2Enabled] = useNosFeaturesEnabled([NosFeature.SOS, NosFeature.WOS2]);

  const createEdgeId = (sourceId: string, targetId: string) => `edge-${sourceId}-${targetId}`;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const switchData = useGraphQL(SwitchCountQuery, { networkUUID: controller.network_uuid }).data
    ?.virtualDevicesForNetwork;

  useEffect(() => {
    const fetchData = async () => {
      const ispInfo = await apiClient.isps(true);
      const deviceInfo = await apiClient.devicesForController();
      if (deviceInfo) {
        const primaryISP = ispInfo.find((isp) => isp.status === api.ISPStatus.IS_PRIMARY);
        const backupISP = ispInfo.find((isp) => isp.status === api.ISPStatus.IS_BACKUP);
        const xSpacingForNodes = 200;

        const primaryISPNode = primaryISP
          ? {
              id: primaryISP.sid,
              type: 'ispNode',
              sourcePosition: Position.Right,
              data: { label: `${primaryISP.provider_name}`, status: primaryISP.status },
              position: {
                x: 0,
                y: backupISP ? -50 : 0,
              },
            }
          : null;

        const backupISPNode = backupISP
          ? {
              id: backupISP.sid,
              type: 'ispNode',
              sourcePosition: Position.Right,
              data: {
                label: `${backupISP.provider_name}`,
                status: backupISP.status,
              },
              position: {
                x: primaryISPNode?.position.x ?? 0,
                y: primaryISPNode ? primaryISPNode.position.y + 100 : 0,
              },
            }
          : null;

        const genericISPNode =
          !primaryISP && !backupISP
            ? {
                id: 'generic-isp-node',
                type: 'genericNode',
                sourcePosition: Position.Right,
                data: {
                  label: `ISP`,
                  status: 'Primary',
                },
                position: { x: 125, y: 0 },
              }
            : null;

        const ispNodes = [];
        if (primaryISPNode) ispNodes.push(primaryISPNode);
        if (backupISPNode) ispNodes.push(backupISPNode);
        if (genericISPNode) ispNodes.push(genericISPNode);

        const controllerNode = {
          id: 'controller',
          type: 'hardwareNode',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          data: { label: 'Security appliance', hardware: 'security-appliance' },
          position: { x: primaryISPNode?.position.x || 0 + xSpacingForNodes + 50, y: 0 },
        };

        const ispToControllerEdges = ispNodes.map((node) => ({
          id: createEdgeId(node.id, 'controller'),
          source: node.id,
          target: 'controller',
          type: 'default',
          animated: deviceInfo.connected_status === 'online',
          style: { ...nodeLine },
        }));

        const numSwitches = isSOSEnabled ? switchData?.length : deviceInfo.switches.length;

        const switchesNode = {
          id: 'switches',
          type: 'hardwareNode',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          data: { label: `${numSwitches} Switches`, hardware: 'switch' },
          position: {
            x: controllerNode.position.x + xSpacingForNodes,
            y: controllerNode.position.y,
          },
        };

        const midpointIndex = Math.floor(deviceInfo.access_points.length / 2);

        const deviceNodes = deviceInfo.access_points.map((device, index) => {
          let positionY;

          if (index < midpointIndex) {
            positionY = -100 * (midpointIndex - index);
          } else if (index === midpointIndex) {
            positionY = switchesNode.position.y;
          } else {
            positionY = 100 * (index - midpointIndex);
          }

          return {
            id: device.name,
            type: 'hardwareNode',
            sourcePosition: Position.Right,
            targetPosition: Position.Left,
            data: { label: device.location || `${device.name} →`, hardware: 'access-point' },
            position: { x: switchesNode.position.x + 200 + xSpacingForNodes, y: positionY },
          };
        });

        const controllerToSwitchesEdge = {
          id: createEdgeId('controller', 'switches'),
          source: 'controller',
          target: 'switches',
          type: 'default',
          animated: deviceInfo.connected_status === 'online',
          style: { ...nodeLine },
        };

        const switchesToDeviceEdges = deviceInfo.access_points.map((device) => ({
          id: createEdgeId('switches', device.name),
          source: 'switches',
          target: device.name,
          type: 'default',
          animated: device.connected_status === 'online',
          style: { ...nodeLine },
        }));

        const numClientsNodes = isWOS2Enabled
          ? []
          : deviceInfo.access_points.map((device, index) => ({
              id: `${device.name}-clients`,
              type: 'clientNode',
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
              data: { label: `${device.connected_clients} Clients ` },
              position: {
                x: deviceNodes[index].position.x + xSpacingForNodes,
                y: deviceNodes[index].position.y,
              },
            }));

        const numClientsEdges = isWOS2Enabled
          ? []
          : deviceInfo.access_points.map((device) => ({
              id: createEdgeId(device.name, `${device.name}-clients`),
              source: device.name,
              target: `${device.name}-clients`,
              type: 'default',
              animated: device.connected_status === 'online',
              style: {
                ...nodeLine,
              },
            }));

        const allNodes = [
          ...ispNodes,
          controllerNode,
          switchesNode,
          ...deviceNodes,
          ...numClientsNodes,
        ];
        const allEdges = [
          ...ispToControllerEdges,
          controllerToSwitchesEdge,
          ...switchesToDeviceEdges,
          ...numClientsEdges,
        ];

        setNodes(allNodes);
        setEdges(allEdges);
      }
    };

    fetchData();
  }, [
    controller,
    controllerName,
    setEdges,
    setNodes,
    apiClient,
    switchData,
    isSOSEnabled,
    isWOS2Enabled,
  ]);

  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);
  const isOperator = useIsOperator({ respectDemoMode: true });

  const onConnect = useCallback(
    (params: Connection) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  const onLoad = (_reactFlowInstance: ReactFlowInstance) => {
    reactFlowInstance.current = _reactFlowInstance;
  };

  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    if (node.data.label.includes('AP') && !isWOS2Enabled) {
      navigate(
        makeDrawerLink(window.location, paths.drawers.AccessPointSummary, {
          deviceName: node.id,
          controllerName,
          companyName,
        }),
      );
    } else if (node.data.label.includes('Clients')) {
      const deviceName = node.id.replace(/-clients(?=(?!clients).*)/, '');
      navigate(
        makeDrawerLink(window.location, paths.drawers.ClientsListPage, {
          deviceName,
          controllerName,
          companyName,
        }),
      );
    } else if (node.type && node.type.includes('ispNode')) {
      navigate(
        makeDrawerLink(window.location, paths.drawers.ISPDetailsPage, {
          controllerName,
          companyName,
          ispSid: node.id,
        }),
      );
    } else if (isOperator && node.data.label.includes('Security appliance')) {
      navigate(
        makeDrawerLink(window.location, paths.drawers.ControllerPage, {
          companyName,
          controllerName,
        }),
      );
    } else if (node.data.label.includes('Switches') && networkAndController?.network) {
      navigate(
        makeLink(paths.pages.SwitchListPage, {
          companyName,
          networkSlug: networkAndController.network.slug,
          tab: 'list',
        }),
      );
    }
  };

  useEffect(() => {
    if (reactFlowInstance.current) {
      setTimeout(() => {
        reactFlowInstance.current!.fitView({ padding: 0.1, minZoom: 1 });
      }, 100);
    }
  }, [nodes, edges]);

  return (
    <Pane>
      <PaneHeader icon="topology" heading="Topology" />
      <PaneContent gutter="all">
        <TopologyArea>
          <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView
            minZoom={0.2}
            onConnect={onConnect}
            onNodeClick={handleNodeClick}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={false}
            proOptions={pageOptions}
            onInit={onLoad}
          >
            <TopologyMiniMap />
            <TopologyLegend>
              <TopologyLegendItem type="inactive" label="Not in use" />
              <TopologyLegendItem type="active" label="In use" />
            </TopologyLegend>
            <TopologyControls showInteractive={false} />
          </ReactFlow>
        </TopologyArea>
      </PaneContent>
    </Pane>
  );
}

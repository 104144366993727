import { notify } from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useMemo, useState } from 'react';
import { z } from 'zod';

import { graphql } from '../../../../gql';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';

const DEVICE_TYPES_SCHEMA = z.union([
  z.literal('access_control'),
  z.literal('camera'),
  z.literal('conferencing_equipment'),
  z.literal('phone'),
  z.literal('printer'),
  z.literal('speaker'),
  z.literal('other'),
]);

export const deviceTypes = DEVICE_TYPES_SCHEMA.options.map((v) => v.value);
export type DeviceType = z.infer<typeof DEVICE_TYPES_SCHEMA>;

export const SCHEMAS = {
  CONTACT_INFO: z.object({
    name: z.string(),
    phone_number: z.string().nullable(),
    email: z.string().email().nullable(),
  }),
  DATE_TIME_WINDOWS: z.array(
    z.object({
      date: z.string(),
      start_time: z.object({
        hour: z.number().min(1).max(12),
        minute: z.number().min(0).max(59),
        meridiem: z.union([z.literal('am'), z.literal('pm')]),
      }),
      end_time: z.object({
        hour: z.number().min(1).max(12),
        minute: z.number().min(0).max(59),
        meridiem: z.union([z.literal('am'), z.literal('pm')]),
      }),
    }),
  ),
  FILE: z.object({
    s3_key: z.string(),
    file_name: z.string().nullish(),
    extension: z.string().startsWith('.').nullish(),
  }),
  ISP_DETAILS: z.object({
    provider: z.string(),
    account_number: z.string(),
    account_holder_name: z.string(),
    static_ip_info: z.string(),
    bandwidth_mb_ps: z.number().int().positive(),
    product_type: z.string(),
    handoff: z.discriminatedUnion('type', [
      z.object({ type: z.literal('ethernet') }),
      z.object({
        type: z.literal('fiber'),
        mode: z.union([z.literal('single_mode'), z.literal('multi_mode')]),
        connector_type: z.union([z.literal('SC'), z.literal('LC')]),
      }),
    ]),
    monthly_fee_cents: z.number().int().positive(),
    is_primary: z.boolean(),
    did_add_ops_user: z.boolean(),
  }),
  DEVICE_TYPES: DEVICE_TYPES_SCHEMA,
  DEVICE_CONFIG: z.discriminatedUnion('ip_assignment', [
    z.object({
      device_type: DEVICE_TYPES_SCHEMA,
      description: z.string(),
      ip_assignment: z.literal('dhcp'),
      reservation: z.discriminatedUnion('has_reservation', [
        z.object({
          has_reservation: z.literal(true),
          ip_address: z.string(),
          mac_address: z.string(),
        }),
        z.object({ has_reservation: z.literal(false) }),
        z.object({ has_reservation: z.literal('unsure') }),
      ]),
    }),
    z.object({
      device_type: DEVICE_TYPES_SCHEMA,
      description: z.string(),
      ip_assignment: z.literal('static'),
      config_description: z.string(),
    }),
  ]),
  CONDITIONAL_WITH_DETAILS: z.object({ is_selected: z.boolean(), details: z.string().optional() }),
  YES_NO_UNSURE: z.boolean().or(z.literal('unsure')),
};

export type Schemas = { [Key in keyof typeof SCHEMAS]: z.infer<(typeof SCHEMAS)[Key]> };

// All fields must be nullish because the document is submitted in parts
const ONBOARDING_DOCUMENT_SCHEMA = z.object({
  _schema_version: z.literal(1),
  desired_go_live_date: z.string().nullish(),
  on_site_point_of_contact: SCHEMAS.CONTACT_INFO.nullish(),
  technical_point_of_contact: SCHEMAS.CONTACT_INFO.nullish(),
  property_manager_contact: SCHEMAS.CONTACT_INFO.nullish(),
  riser_manager_contact: z
    .discriminatedUnion('has_riser_manager', [
      z.object({ has_riser_manager: z.literal(true), contact_info: SCHEMAS.CONTACT_INFO }),
      z.object({ has_riser_manager: z.literal(false) }),
      z.object({ has_riser_manager: z.literal('unsure') }),
    ])
    .nullish(),
  site_survey_windows: SCHEMAS.DATE_TIME_WINDOWS.nullish(), // TODO
  are_union_contractors_required: SCHEMAS.YES_NO_UNSURE.nullish(),
  access_details: z.string().nullish(),
  blank_floor_plan: SCHEMAS.FILE.nullish(),
  marked_up_floor_plan: SCHEMAS.FILE.nullish(),
  coi: z
    .discriminatedUnion('is_required', [
      z.object({ is_required: z.literal(true), file: SCHEMAS.FILE }),
      z.object({ is_required: z.literal(false) }),
      z.object({ is_required: z.literal('unsure') }),
    ])
    .nullish(),
  avg_wifi_users: z
    .discriminatedUnion('will_increase', [
      z.object({
        will_increase: z.literal(true),
        current_users: z.number().int().positive(),
        future_users: z.number().int().positive(),
      }),
      z.object({
        will_increase: z.literal(false),
        current_users: z.number().int().positive(),
      }),
      z.object({
        will_increase: z.literal('unsure'),
        current_users: z.number().int().positive().nullish(),
      }),
    ])
    .nullish(),
  existing_network_login_details: z
    .discriminatedUnion('willing_to_share', [
      z.object({
        willing_to_share: z.literal(true),
        platform_name: z.string(),
        username: z.string(),
        password: z.string(),
      }),
      z.object({
        willing_to_share: z.literal(false),
      }),
      z.object({
        willing_to_share: z.literal('unsure'),
      }),
    ])
    .nullish(),
  wifi_device_types: z
    .object({ types: z.array(z.string()), otherDescription: z.string().optional() })
    .nullish(),
  hardwired_devices: z
    .discriminatedUnion('has_hardwired_devices', [
      z.object({
        has_hardwired_devices: z.literal(true),
        devices: z.array(SCHEMAS.DEVICE_CONFIG),
        uploaded_device_configuration: SCHEMAS.FILE.nullish(),
      }),
      z.object({ has_hardwired_devices: z.literal(false) }),
      z.object({ has_hardwired_devices: z.literal('unsure') }),
    ])
    .nullish(),
  radius_profile_requirements: SCHEMAS.YES_NO_UNSURE.nullish(),
  port_forwarding_requirements: z
    .object({ has_requirements: SCHEMAS.YES_NO_UNSURE, details: z.string().optional() })
    .nullish(),
  servers_onsite: SCHEMAS.CONDITIONAL_WITH_DETAILS.nullish(),
  has_captive_portal: SCHEMAS.YES_NO_UNSURE.nullish(),
  other_requirements: z
    .object({ has_requirements: SCHEMAS.YES_NO_UNSURE, details: z.string().optional() })
    .nullish(),
  has_client_vpn: SCHEMAS.YES_NO_UNSURE.nullish(),
  has_site_to_site_vpn: SCHEMAS.YES_NO_UNSURE.nullish(),
  isp_details: z
    .discriminatedUnion('procured_by', [
      z.object({
        procured_by: z.literal('customer'),
        details: z.array(SCHEMAS.ISP_DETAILS),
      }),
      z.object({ procured_by: z.literal('meter_connect') }),
    ])
    .nullish(),
  alerting_email: z.string().email().nullish(),
  billing_contact: SCHEMAS.CONTACT_INFO.nullish(),
  authorized_administrators_contact: SCHEMAS.CONTACT_INFO.nullish(),
});

type OnboardingDocument = typeof ONBOARDING_DOCUMENT_SCHEMA;

export type DocState = z.infer<OnboardingDocument>;

export type SubmitValues<Key extends keyof DocState> = NonNullable<DocState[Key]>;

function createBlankDoc(): DocState {
  return {
    _schema_version: 1,
    on_site_point_of_contact: null,
    technical_point_of_contact: null,
    property_manager_contact: null,
    riser_manager_contact: null,
    site_survey_windows: null,
    are_union_contractors_required: null,
    access_details: null,
    blank_floor_plan: null,
    marked_up_floor_plan: null,
    coi: null,
    desired_go_live_date: null,
    avg_wifi_users: null,
    wifi_device_types: null,
    isp_details: null,
    alerting_email: null,
    billing_contact: null,
    authorized_administrators_contact: null,
    hardwired_devices: null,
    has_site_to_site_vpn: null,
    has_client_vpn: null,
    port_forwarding_requirements: null,
    other_requirements: null,
    servers_onsite: null,
    has_captive_portal: null,
  };
}

export const networkOnboardingDocumentQuery = graphql(`
  query NetworkForOnboardingDocument($networkUUID: UUID!) {
    network(UUID: $networkUUID) {
      UUID
      onboardingDocument {
        document
        delegations {
          UUID
          fieldName
          email
          description
        }
      }
    }
  }
`);

const upsertNetworkOnboardingDocumentMutation = graphql(`
  mutation UpsertNetworkOnboardingDocument($networkUUID: UUID!, $document: JSONObject!) {
    upsertNetworkOnboardingDocument(networkUUID: $networkUUID, document: $document) {
      UUID
    }
  }
`);

export function useOnboardingDocument() {
  const network = useNetwork();
  const networkOnboardingDocument = useGraphQL(networkOnboardingDocumentQuery, {
    networkUUID: network.UUID,
  }).data?.network?.onboardingDocument;
  const parsedOnboardingDocument = ONBOARDING_DOCUMENT_SCHEMA.safeParse(
    networkOnboardingDocument?.document,
  );
  const [state, setState] = useState<DocState>(
    parsedOnboardingDocument.success ? parsedOnboardingDocument.data : createBlankDoc(),
  );

  const delegationsFieldNameMap = useMemo(() => {
    const map = new Map();

    if (networkOnboardingDocument?.delegations) {
      for (const delegation of networkOnboardingDocument.delegations) {
        map.set(delegation.fieldName, delegation);
      }
    }

    return map;
  }, [networkOnboardingDocument]);

  const upsertOnboardingDocument = useGraphQLMutation(upsertNetworkOnboardingDocumentMutation);

  function patch<K extends keyof DocState>(key: K, value: DocState[K]) {
    setState((prev) => {
      const newState = { ...prev, [key]: value };
      try {
        ONBOARDING_DOCUMENT_SCHEMA.parse(newState);
        upsertOnboardingDocument.mutate(
          { networkUUID: network.UUID, document: newState },
          {
            onError(err) {
              notify(`Error saving onboarding${getGraphQLErrorMessageOrEmpty(err)}`, {
                variant: 'negative',
              });
              setState(prev);
            },
          },
        );
        return newState;
      } catch (err) {
        notify('Error saving onboarding', {
          variant: 'negative',
        });
        return prev;
      }
    });
  }

  return {
    state,
    patch,
    delegationsFieldNameMap,
  };
}

import type { FormikHelpers } from 'formik';
import {
  Button,
  CompositeField,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  HStack,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryField,
  PrimaryFieldComposite,
  PrimaryToggleField,
  Select,
  SelectItem,
  space,
  Textarea,
  TextInput,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import type { EditIPSecTunnelDrawerProps, IPSecTunnelCreateFormValues } from './utils';
import {
  getPhyInterfaceLabel,
  uplinkPhyInterfacesQuery,
} from '../../../../../components/Firewall/utils';
import {
  FieldProvider,
  ListFieldProvider,
  MultiComboBoxFieldProvider,
} from '../../../../../components/Form/FieldProvider';
import { FormikConditional } from '../../../../../components/FormikConditional';
import { vlanHasStaticIP, vlansQuery } from '../../../../../components/NetworkWide/VLANs/utils';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import {
  authenticationAlgos,
  createIPSecTunnelMutation,
  dhGroups,
  encryptionAlgos,
  humanReadableDHKeyExchangeGroup,
  IPSecTunnelCreateFormSchema,
  IPSecTunnelEditFormSchema,
  IPSecTunnelQuery,
  IPSecTunnelsQuery,
  parseCreateFormValsToIPSecTunnel,
  parseEditFormValsToIPSecTunnel,
  parseIPSecTunnelToFormVals,
  updateIPSecTunnelMutation,
  vlanLabelWithSubnet,
} from './utils';

export default function EditIPSecTunnelDrawer({ tunnel, isInDrawer }: EditIPSecTunnelDrawerProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const [showSecret, setShowSecret] = useState(false);
  const tunnelFormVals = useMemo(
    () => parseIPSecTunnelToFormVals(tunnel),
    [tunnel],
  ) as IPSecTunnelCreateFormValues;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const connections = useGraphQL(uplinkPhyInterfacesQuery, {
    networkUUID: network.UUID,
  })?.data?.uplinkPhyInterfacesForNetwork.filter(
    (phy) => phy.virtualDevice.hardwareDevice?.isActive,
  );

  const createMutation = useGraphQLMutation(createIPSecTunnelMutation);
  const updateMutation = useGraphQLMutation(updateIPSecTunnelMutation);
  const handleSubmit = useCallback(
    (
      formVals: IPSecTunnelCreateFormValues,
      { setFieldValue }: FormikHelpers<IPSecTunnelCreateFormValues>,
    ) => {
      if (tunnel) {
        updateMutation.mutate(
          { uuid: tunnel.UUID, input: parseEditFormValsToIPSecTunnel(formVals) },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(IPSecTunnelsQuery, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(makeQueryKey(IPSecTunnelQuery, { UUID: tunnel.UUID }));
              notify('Successfully updated IPSec tunnel configuration.', {
                variant: 'positive',
              });
              setFieldValue('presharedKey', '', false);
            },
            onError: (err) => {
              notify(
                `There was a problem updating the IPSec tunnel configuration${getGraphQLErrorMessageOrEmpty(
                  err,
                )}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        createMutation.mutate(
          {
            networkUUID: network.UUID,
            input: parseCreateFormValsToIPSecTunnel(formVals),
          },
          {
            onSuccess: (result) => {
              queryClient.invalidateQueries(
                makeQueryKey(IPSecTunnelsQuery, { networkUUID: network.UUID }),
              );
              notify('Successfully created IPSec tunnel configuration.', {
                variant: 'positive',
              });
              navigate(
                makeDrawerLink(window.location, paths.drawers.EditIPSecTunnelPage, {
                  IPSecUUID: result.createIPSecTunnel.UUID,
                  companyName,
                  networkSlug: network.slug,
                }),
              );
            },
            onError: (err) => {
              notify(
                `There was a problem creating your IPSec tunnel configuration${getGraphQLErrorMessageOrEmpty(
                  err,
                )}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [navigate, network, companyName, queryClient, tunnel, updateMutation, createMutation],
  );
  const closeDrawer = useCloseDrawerCallback();
  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;

  const vlanOptions = useMemo(
    () =>
      vlans
        ?.filter((vlan) => vlanHasStaticIP(vlan))
        .map((vlan) => ({
          label: vlanLabelWithSubnet(vlan),
          value: vlan.UUID,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [vlans],
  );

  const hasMultipleUplinkDevices = useMemo(
    () => new Set(connections?.map((pi) => pi.virtualDevice.UUID) ?? []).size > 1,
    [connections],
  );
  const UplinkPhyInterfaceItems = useMemo(
    () =>
      (connections ?? []).map((phyInterface) => (
        <SelectItem key={phyInterface.UUID}>
          {getPhyInterfaceLabel(phyInterface, hasMultipleUplinkDevices)}
        </SelectItem>
      )),
    [connections, hasMultipleUplinkDevices],
  );
  return (
    <Formik<IPSecTunnelCreateFormValues>
      initialValues={tunnelFormVals}
      validate={
        tunnel
          ? withZodSchema(IPSecTunnelEditFormSchema)
          : withZodSchema(IPSecTunnelCreateFormSchema)
      }
      onSubmit={handleSubmit}
    >
      <Form>
        <DrawerContent>
          <FieldContainer>
            <FieldProvider name="isEnabled">
              <PrimaryToggleField label="Enable" />
            </FieldProvider>
          </FieldContainer>
          <FieldContainer>
            <FieldProvider name="name">
              <PrimaryField label="Name" element={<TextInput />} />
            </FieldProvider>
          </FieldContainer>
          <FieldContainer>
            <FieldProvider name="leftID">
              <PrimaryField label="Local IP or FQDN" element={<TextInput />} />
            </FieldProvider>
          </FieldContainer>
          <FieldContainer>
            <FieldProvider name="rightID">
              <PrimaryField label="Remote IP or FQDN" element={<TextInput />} />
            </FieldProvider>
          </FieldContainer>
          <FieldContainer>
            <FieldProvider name="isInitiator">
              <PrimaryToggleField label="Initiator" />
            </FieldProvider>
            <FormikConditional<IPSecTunnelCreateFormValues>
              condition={({ isInitiator }) => !!isInitiator}
            >
              <FieldProvider name="right">
                <PrimaryField label="Destination address IP/FQDN" element={<TextInput />} />
              </FieldProvider>
              <FieldProvider name="authenticationAlgorithm">
                <PrimaryField
                  label="Authentication algorithm"
                  element={
                    <Select width="100%">
                      {authenticationAlgos.map((algo) => (
                        <SelectItem key={algo}>{algo}</SelectItem>
                      ))}
                    </Select>
                  }
                />
              </FieldProvider>
              <FieldProvider name="encryptionAlgorithm">
                <PrimaryField
                  label="Encryption algorithm"
                  element={
                    <Select width="100%">
                      {encryptionAlgos.map((algo) => (
                        <SelectItem key={algo}>{algo}</SelectItem>
                      ))}
                    </Select>
                  }
                />
              </FieldProvider>
              <FieldProvider name="keyExchangeDHGroup">
                <PrimaryField
                  label="DH key exchange"
                  element={
                    <Select width="100%">
                      {dhGroups.map((group) => (
                        <SelectItem key={group}>
                          {humanReadableDHKeyExchangeGroup(group)}
                        </SelectItem>
                      ))}
                    </Select>
                  }
                />
              </FieldProvider>
            </FormikConditional>
          </FieldContainer>
          <FieldContainer>
            <PrimaryFieldComposite
              label="Preshared key"
              description="Once the preshared key is set on creation, you will not be able to view it again. However, you can always update it."
              fields={
                <HStack align="stretch" spacing={space(12)}>
                  <FieldProvider name="presharedKey">
                    <CompositeField
                      label="Preshared key"
                      element={<TextInput width="100%" type={showSecret ? 'text' : 'password'} />}
                    />
                  </FieldProvider>
                  <Button
                    variant="secondary"
                    arrangement="hidden-label"
                    icon={showSecret ? 'eye-closed' : 'eye-open'}
                    onClick={() => setShowSecret(!showSecret)}
                  >
                    Show preshared key
                  </Button>
                </HStack>
              }
            />
          </FieldContainer>
          <FieldContainer>
            <ListFieldProvider name="rightPrefixes">
              <PrimaryField
                label="Remote networks"
                description="One subnet per line, e.g., 192.168.108.0/24."
                element={<Textarea />}
              />
            </ListFieldProvider>
          </FieldContainer>
          <FieldContainer>
            <MultiComboBoxFieldProvider name="vlanUUIDs">
              <PrimaryField
                label="Local networks"
                element={
                  <MultiComboBox placeholder="Select VLANs">
                    {vlanOptions.map((vlan) => (
                      <MultiComboBoxItem key={vlan.value} textValue={vlan.label}>
                        {vlan.label}
                      </MultiComboBoxItem>
                    ))}
                  </MultiComboBox>
                }
              />
            </MultiComboBoxFieldProvider>
          </FieldContainer>
          <FieldContainer>
            <FieldProvider name="phyInterfaceUUID">
              <PrimaryField
                label="Bound WAN port"
                element={
                  <Select placeholder="Select WAN port" width="100%">
                    {UplinkPhyInterfaceItems}
                  </Select>
                }
              />
            </FieldProvider>
          </FieldContainer>
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              {isInDrawer ? (
                <Button type="button" onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
              ) : (
                <Button type="reset" variant="secondary">
                  Reset
                </Button>
              )}
              <Button type="submit">{tunnel ? 'Save' : 'Add'}</Button>
            </>
          }
        />
      </Form>
    </Formik>
  );
}

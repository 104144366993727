import React, { useCallback } from 'react';

import { HStack, space, StackProps } from '@meterup/atto';
import { flexRender, Header } from '@tanstack/react-table';

import SortIcon from './SortIcon';

type SortableHeaderProps<TData, TValue> = {
  header: Header<TData, TValue>;
};
export default function SortableHeader<TData, TValue = unknown>({
  header,
}: SortableHeaderProps<TData, TValue>) {
  const {
    table: { setSorting },
  } = header.getContext();
  const { column, getContext, getSize, id } = header;
  const { columnDef, getCanSort, getIsSorted, getCanMultiSort, toggleSorting } = column;
  const stackProps: StackProps<'div'> = {
    style: {
      width: getSize(),
    },
  };
  const toggleSortingCallback = useCallback(
    (e: React.SyntheticEvent<MouseEvent>) => {
      e.stopPropagation();
      const sorted = getIsSorted();
      if (sorted === 'desc') {
        setSorting((prev) => prev.filter((s) => s.id !== id));
        return;
      }
      if (sorted === 'asc') {
        toggleSorting(true, getCanMultiSort());
        return;
      }
      toggleSorting(false, getCanMultiSort());
    },
    [getCanMultiSort, getIsSorted, id, setSorting, toggleSorting],
  );
  if (getCanSort()) {
    // @ts-ignore
    stackProps.onClick = toggleSortingCallback;
    stackProps.style!.cursor = 'pointer';
  }
  return (
    <HStack spacing={space(6)} key={`col-hdr-${id}-inner`} {...stackProps} justify="between">
      <span>{flexRender(columnDef.header, getContext())}</span>

      <SortIcon header={header} />
    </HStack>
  );
}

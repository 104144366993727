import { Column, Columns, Section, SectionContent, SectionHeader } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { NetworkForFilter } from '../../../../components/firmwareBulkUpgrader/hooks/useNetworksByFilter';
import type { RulesInput } from '../../../../components/firmwareBulkUpgrader/utils';
import type { NetworksFilter } from '../../../../gql/graphql';
import { BulkUpgraderConfirmForm } from '../../../../components/firmwareBulkUpgrader/BulkUpgraderConfirmForm';
import { BulkUpgraderFilterForm } from '../../../../components/firmwareBulkUpgrader/BulkUpgraderFilterForm';
import { useNetworksByFilter } from '../../../../components/firmwareBulkUpgrader/hooks/useNetworksByFilter';
import { NetworksPreviewList } from '../../../../components/firmwareBulkUpgrader/NetworksPreviewList';
import {
  createBulkUpgradeInputSchema,
  fieldsToNetworkFilter,
  RuleVariable,
} from '../../../../components/firmwareBulkUpgrader/utils';
import { DetailLoadingFallback } from '../../../../components/Placeholders/DetailLoadingFallback';
import { useBatchEdit } from '../../../../hooks/useBatchEdit';
import { withZodSchema } from '../../../../utils/withZodSchema';

export const Meta = () => ({
  path: '/operator/firmware-bulk-upgrader/add',
  layout: 'FirmwareBulkUpgraderLayout',
  title: 'Add upgrade - Firmware bulk upgrader - Operator',
});

const schemaValidator = withZodSchema(createBulkUpgradeInputSchema);

export default function AddFirmwareBulkUpgradePage() {
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  const [filters, setFilters] = useState<NetworksFilter>({});
  const { data, isLoading } = useNetworksByFilter(filters);

  const networks = useMemo(() => data?.networks ?? [], [data]);
  const [selectedNetworks, setSelectedNetworks] = useState<NetworkForFilter[]>([]);
  const { multiSelectedRows, onRowMultiSelectionChange } = useBatchEdit({
    initialState: [],
    onChange: (rows) => {
      const ids = Object.keys(rows);
      setSelectedNetworks(networks.filter((v) => ids.includes(v.UUID)));
    },
  });

  const handleValidate = useCallback((values: RulesInput) => {
    const validation = schemaValidator(values);
    const hasEmptyRules = values.rules.some((rule) => rule?.variable === null);
    if (hasEmptyRules) {
      validation.rules = 'Rule variable must be defined.';
    }
    return validation;
  }, []);

  return (
    <Formik<RulesInput>
      initialValues={{
        rules: [
          {
            id: uuidv4(),
            variable: RuleVariable.UpgradeSensitive,
            eq: false,
          },
        ],
      }}
      onSubmit={(fields) => {
        setFilters(fieldsToNetworkFilter(fields));
        onRowMultiSelectionChange({});
      }}
      validate={handleValidate}
    >
      <Columns template="flex" scroll="independent">
        <Column gutter="none" width="summary">
          <Section relation="stacked">
            <SectionHeader heading="Network selection criteria" />
            <SectionContent gutter="all">
              <BulkUpgraderFilterForm />
            </SectionContent>
          </Section>
          <Section relation="stacked">
            <SectionHeader heading="Submit bulk upgrade" />
            <SectionContent gutter="all">
              <BulkUpgraderConfirmForm selectedNetworks={selectedNetworks} />
            </SectionContent>
          </Section>
        </Column>
        <Column gutter="bottom" width="remaining" spacing={0}>
          {isLoading ? (
            <DetailLoadingFallback />
          ) : (
            <NetworksPreviewList
              multiSelectedRows={multiSelectedRows}
              onRowMultiSelectionChange={onRowMultiSelectionChange}
              networks={networks}
            />
          )}
        </Column>
      </Columns>
    </Formik>
  );
}

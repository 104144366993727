import { Button, DrawerFooter } from '@meterup/atto';
import { useFormikContext } from 'formik';

import { useSafeCloseDrawer } from '../NetworkWide/CaptivePortals/utils';

export default function FormFooter() {
  const closeDrawer = useSafeCloseDrawer();
  const form = useFormikContext();
  const inputsDisabled = form.isSubmitting || form.isValidating;

  return (
    <DrawerFooter
      actions={
        <>
          <Button variant="secondary" type="button" onClick={closeDrawer} disabled={inputsDisabled}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={inputsDisabled || !form.isValid}
            loading={form.isSubmitting}
          >
            Save
          </Button>
        </>
      }
    />
  );
}

import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { NetworksOverviewData } from './utils/aggregateStatsForNetworks';
import { graphql } from '../../gql';
import { getStepForDuration } from '../../utils/chart_utils';

const uplinkQuality = graphql(`
  query UptimeStatsForNetworks($networkUUIDs: [UUID!]!, $lookBack: Int!, $stepSeconds: Int!) {
    networksUplinkQualities(
      networkUUIDs: $networkUUIDs
      filter: { durationSeconds: $lookBack, stepSeconds: $stepSeconds }
    ) {
      values {
        phyInterfaceUUID
        timestamp
        value
      }
    }
  }
`);

export type UptimeForInterface = {
  stats: { timestamp: Date; value: number }[];
  label: string;
  phyInterfaceUUID: string;
};

export type UptimeByNetwork = Map<string, UptimeForInterface[]>;

export function useUptimeStatsByUUID(networkUUIDs: string[], lookBack: number = 86400) {
  const stepSeconds = getStepForDuration(lookBack);
  const resp = useGraphQL(
    uplinkQuality,
    { networkUUIDs, lookBack, stepSeconds },
    { suspense: true },
  );

  return useMemo(() => {
    const uptimeByNetwork: UptimeByNetwork = new Map();

    if (
      !resp.data?.networksUplinkQualities ||
      resp.data?.networksUplinkQualities?.length !== networkUUIDs.length
    )
      return uptimeByNetwork;

    networkUUIDs.forEach((networkUUID, index) => {
      const statsForNetwork = resp.data.networksUplinkQualities[index];
      const uptimeByInterface = new Map<string, UptimeForInterface>();

      let labelCount = 0;
      for (const stat of statsForNetwork.values) {
        if (!uptimeByInterface.has(stat.phyInterfaceUUID)) {
          uptimeByInterface.set(stat.phyInterfaceUUID, {
            // this label isn't technically correct, but it's good enough for demo
            // eventually we want to replace w/ the name of the ISP
            label: `wan${labelCount}`,
            stats: [],
            phyInterfaceUUID: stat.phyInterfaceUUID,
          });
          labelCount += 1;
        }
        const dataPoints = uptimeByInterface.get(stat.phyInterfaceUUID)!.stats;
        dataPoints.push({ timestamp: new Date(stat.timestamp), value: stat.value });
      }

      uptimeByNetwork.set(networkUUID, Array.from(uptimeByInterface.values()));
    });

    return uptimeByNetwork;
  }, [resp.data, networkUUIDs]);
}

export function useUptimeStats(networksOverview: NetworksOverviewData) {
  const networkUUIDs = useMemo(
    () => networksOverview.networks.map((network) => network.uuid),
    [networksOverview],
  );

  return useUptimeStatsByUUID(networkUUIDs);
}

import { useIsOperator } from '@meterup/authorization';
import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import * as d3 from 'd3';
import { useMemo } from 'react';

import TimeSeriesChart from '../../../../../../components/Charts/TimeSeriesChart';
import AccessPointsLayout from '../../../../../../components/Hardware/AccessPoints/AccessPoints';
import {
  getDurationSeconds,
  percentValueFormatter,
} from '../../../../../../components/Insights/Network/utils';
import {
  MetricBandFilter,
  metricBandFilterToRadioBand,
} from '../../../../../../components/Metrics/utils';
import {
  AccessPointsQuery,
  APChannelUtilizationByNetworkQuery,
  ValidAPListTabs,
} from '../../../../../../components/Wireless/utils';
import { PermissionType } from '../../../../../../gql/graphql';
import { useNetwork } from '../../../../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../../../../providers/SearchParamsStateProvider';
import {
  apChannelUtilizationByNetworkV2MetricsToSeries,
  getStep,
} from '../../../../../../utils/chart_utils';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/insights',
  layout: 'VirtualNetworkLayout',
});

function AccessPointsInsights() {
  const network = useNetwork();
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const [currentAPOrUndefined] = useSearchParamsState<{
    UUID: string;
    label: string;
  }>('access-point', { UUID: 'All', label: 'All' });
  const currentAP = currentAPOrUndefined ?? { UUID: 'All', label: 'All' };

  const [currentBandOrUndefined] = useSearchParamsState<MetricBandFilter>(
    'band',
    MetricBandFilter.All,
  );
  const currentBand = currentBandOrUndefined ?? MetricBandFilter.All;
  const step = getStep(currentTimePeriod);
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const accessPoints = useGraphQL(AccessPointsQuery, {
    networkUUID: network.UUID,
    includeUptime,
  }).data?.virtualDevicesForNetwork;
  expectDefinedOrThrow(accessPoints, new ResourceNotFoundError('Unable to load access points'));

  const { data } = useGraphQL(APChannelUtilizationByNetworkQuery, {
    networkUUID: network.UUID,
    filter: {
      timeFilter: {
        durationSeconds: getDurationSeconds(currentTimePeriod),
        stepSeconds: step,
      },
    },
  });

  const series = useMemo(() => {
    let allData = data?.channelUtilizationByNetwork ?? [];

    if (currentAP.UUID !== 'All') {
      allData = allData.filter((response) => response.virtualDeviceUUID === currentAP.UUID);
    }

    if (currentBand !== MetricBandFilter.All) {
      const radioBand = metricBandFilterToRadioBand(currentBand);
      allData = allData.filter((response) => response.band === radioBand);
    }

    return apChannelUtilizationByNetworkV2MetricsToSeries(allData, step, accessPoints);
  }, [data?.channelUtilizationByNetwork, step, currentAP.UUID, currentBand, accessPoints]);

  return (
    <TimeSeriesChart
      curve={d3.curveLinear}
      series={series}
      seriesType="area"
      seriesLabel="access-point-channel-utilization"
      showSeriesGlyphs
      showSeriesTooltips
      timePeriod={currentTimePeriod}
      title="Channel utilization"
      tooltipBody="The percentage of time the channel used by the access point was utilized."
      valueFormatter={percentValueFormatter}
      yTickValueFormatter={percentValueFormatter}
      multiSeries
      showLegend={false}
      yDomain={[0, 1.1]}
    />
  );
}

export default function AccessPointsInsightsPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  return (
    <AccessPointsLayout activeTab={ValidAPListTabs.Insights} content={<AccessPointsInsights />} />
  );
}

import type { SortingState } from '@tanstack/react-table';
import {
  Badge,
  Button,
  EmptyState,
  HStack,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  space,
  Text,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import {
  AutoTable,
  expectDefinedOrThrow,
  isDefined,
  ResourceNotFoundError,
  Tooltip,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { Duration } from 'luxon';
import { useMemo } from 'react';

import type { PowerDistributionUnitsQueryResult } from './utils';
import { paths } from '../../../constants';
import { PermissionType, VirtualDeviceType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { deviceHasMismatchedRealm } from '../../../utils/devices';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { useHardwareCrumbs, useNavigateBack, useNavigateHome } from '../../../utils/routing';
import { formatTimestamp } from '../../Devices/utils';
import { NoValue } from '../../NoValue';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { PowerDistributionUnitsQuery } from './utils';

const builder = createColumnBuilder<PowerDistributionUnitsQueryResult>();

const columns = [
  builder.data((row) => String(!!row.hardwareDevice?.isConnectedToBackend), {
    enableGlobalFilter: false,
    id: 'status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: ({ row }) => {
      if (row.hardwareDevice?.isConnectedToBackend) {
        return (
          <Badge
            arrangement="hidden-label"
            size="small"
            ends="pill"
            variant="positive"
            icon="checkmark"
          >
            Online
          </Badge>
        );
      }

      return (
        <Tooltip
          content={
            row.hardwareDevice?.disconnectedFromBackendAt
              ? `Offline since ${formatTimestamp(row.hardwareDevice.disconnectedFromBackendAt)}`
              : 'Never connected to backend'
          }
        >
          <Badge arrangement="hidden-label" size="small" ends="pill" variant="neutral" icon="cross">
            Offline
          </Badge>
        </Tooltip>
      );
    },
  }),
  builder.data((device) => device.label ?? '', {
    id: 'label',
    header: 'Label',
    meta: {
      isLeading: true,
    },
    cell: (props) => {
      if (props.row.label) {
        return <span>{props.row.label}</span>;
      }

      return <NoValue />;
    },
  }),
  builder.data((device) => device.hardwareDevice?.macAddress ?? '', {
    id: 'mac-address',
    header: 'MAC',
    cell: (props) => {
      if (props.row.hardwareDevice?.macAddress) {
        return <Text family="monospace">{props.row.hardwareDevice.macAddress}</Text>;
      }

      return <NoValue />;
    },
  }),
  builder.data(
    (device) =>
      device.__typename === 'PowerDistributionUnitVirtualDevice' ? device.ipAddress ?? '' : '',
    {
      id: 'ip-address',
      header: 'IP address',
      cell: (props) => {
        if (props.row.__typename === 'PowerDistributionUnitVirtualDevice' && props.row.ipAddress) {
          return <Text family="monospace">{props.row.ipAddress}</Text>;
        }

        return <NoValue />;
      },
    },
  ),
];

const useColumns = (hasMismatchedRealm: Boolean) => {
  const isOperator = useIsOperator({ respectDemoMode: true });

  return useMemo(() => {
    const c = isOperator
      ? [
          ...columns,
          builder.data(
            (device) => {
              if (
                device?.__typename !== 'PowerDistributionUnitVirtualDevice' ||
                !isDefined(device?.uptime)
              ) {
                return '0';
              }

              return Duration.fromISO(device.uptime).toHuman({ unitDisplay: 'narrow' });
            },
            {
              id: 'uptime',
              header: 'Uptime',
              meta: {
                alignment: 'end',
                internal: true,
              },
              enableGlobalFilter: false,
              cell: (props) => {
                if (props.value === '0') {
                  return <NoValue internal />;
                }

                return <span>{props.value}</span>;
              },
            },
          ),
          builder.data((device) => device.hardwareDevice?.serialNumber ?? '', {
            id: 'serial-number',
            header: 'Serial number',
            meta: {
              internal: true,
            },
            cell: (props) => {
              if (props.row.hardwareDevice?.serialNumber) {
                return <Text family="monospace">{props.row.hardwareDevice.serialNumber}</Text>;
              }

              return <NoValue internal />;
            },
          }),
          builder.data(
            (row) => {
              const nos = row.nosVersion;
              const boot = row.hardwareDevice?.bootHistory;

              if (!nos || !boot || boot.length === 0) {
                return '0';
              }

              return nos.version;
            },
            {
              id: 'nos-version',
              header: 'NOS version',
              meta: {
                internal: true,
              },
              cell: (props) => {
                if (props.value === '0') {
                  return <NoValue internal />;
                }

                const nos = props.row.nosVersion;
                const boot = props.row.hardwareDevice?.bootHistory;

                // A little hacky, but NOS version 2 (which is stable) is `no-upgrades` so whatever build is on the
                // device when its on NOS version 2 is valid.
                if (nos && boot && nos.id !== 2 && nos.buildName !== boot[0].buildName) {
                  return (
                    <Tooltip
                      asChild={false}
                      content={`NOS version ${nos.version} expected build ${nos.buildName} but device is running ${boot[0].buildName}`}
                    >
                      <HStack spacing={space(8)} align="center">
                        <Text family="monospace">{nos.version}</Text>
                        <Icon
                          icon="warning"
                          size={12}
                          color={{ light: 'internalBodyLight', dark: 'internalBodyDark' }}
                        />
                      </HStack>
                    </Tooltip>
                  );
                }

                return <span>{props.value}</span>;
              },
            },
          ),
        ]
      : columns;

    if (hasMismatchedRealm) {
      c.push(
        builder.data((device) => device.hardwareDevice?.serialNumber ?? '', {
          id: 'mismatched-realm',
          header: 'Mismatched Realm',
          meta: {
            internal: true,
          },
          cell: (props) => {
            if (deviceHasMismatchedRealm(props.row.hardwareDevice)) {
              return (
                <Tooltip asChild={false} content="Configured realm does not match device's IsDev">
                  <Badge
                    arrangement="hidden-label"
                    size="small"
                    ends="pill"
                    variant="negative"
                    icon="checkmark"
                  >
                    Mismatched Realm
                  </Badge>
                </Tooltip>
              );
            }
            return (
              <Badge
                arrangement="hidden-label"
                size="small"
                ends="pill"
                variant="positive"
                icon="checkmark"
              >
                Valid Realm
              </Badge>
            );
          },
        }),
      );
    }
    return c;
  }, [hasMismatchedRealm, isOperator]);
};

function PowerDistributionUnitsList() {
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const hardwareCrumb = useHardwareCrumbs();
  const companyName = useCurrentCompany();
  const drawerParams = Nav.useRegionParams(
    'drawer',
    paths.drawers.PowerDistributionUnitSummaryPage,
  );
  const { hasPermission } = usePermissions();
  const isOperator = useIsOperator({ respectDemoMode: true });
  const pdusData = useGraphQL(PowerDistributionUnitsQuery, {
    networkUUID: network.UUID,
    includeIsDev: hasPermission(PermissionType.PermNetworkDevicesReadRestricted),
  }).data;
  expectDefinedOrThrow(
    pdusData,
    new ResourceNotFoundError('Unable to load power distribution units'),
  );

  const pdus = useMemo(
    () =>
      pdusData.virtualDevicesForNetwork.filter(
        (s) =>
          s.deviceType === VirtualDeviceType.PowerDistributionUnit &&
          (isOperator || s.hardwareDevice?.isActive),
      ),
    [isOperator, pdusData],
  );
  const hasMismatchedRealm = useMemo(
    () => pdusData.virtualDevicesForNetwork.some((d) => deviceHasMismatchedRealm(d.hardwareDevice)),
    [pdusData],
  );
  const allColumns = useColumns(hasMismatchedRealm);

  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const closeDrawer = useCloseDrawerCallback();

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...hardwareCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.PowerDistributionUnitListPage, {
                companyName,
                networkSlug: network.slug,
                tab: 'list',
              }),
              selected: true,
              label: 'Power distribution units',
            },
          },
        ]}
        icon="pdu"
        heading="Power distribution units"
        count={pdus.length}
        actions={
          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
              !!nosFlags[NosFeature.POS]
            }
          >
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(window.location, paths.drawers.PowerDistributionUnitCreatePage, {
                companyName,
                networkSlug: network.slug,
              })}
              variant="secondary"
              arrangement="leading-icon"
              icon="plus"
            >
              Add power distribution unit
            </Button>
          </IsPermitted>
        }
      />
      <PaneContent gutter="bottom">
        {pdus.length ? (
          <AutoTable
            key={JSON.stringify(pdus)}
            columns={allColumns}
            data={pdus}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            isRowSelected={(s) => s.UUID === drawerParams?.uuid}
            onRowDeselect={closeDrawer}
            getLinkTo={(row) =>
              makeDrawerLink(window.location, paths.drawers.PowerDistributionUnitSummaryPage, {
                companyName,
                networkSlug: network.slug,
                uuid: row.UUID,
              })
            }
          />
        ) : (
          <EmptyState
            icon="pdu"
            heading="You have no power distribution units"
            action={
              isOperator ? (
                <Button
                  condense
                  size="small"
                  as={ReactRouterLink}
                  to={makeDrawerLink(
                    window.location,
                    paths.drawers.PowerDistributionUnitCreatePage,
                    {
                      companyName,
                      networkSlug: network.slug,
                    },
                  )}
                  variant="secondary"
                  arrangement="leading-icon"
                  icon="plus"
                >
                  Add power distribution unit
                </Button>
              ) : undefined
            }
          />
        )}
      </PaneContent>
    </Pane>
  );
}

export default PowerDistributionUnitsList;

import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import VirtualDeviceCreateDrawer from '../../../../../components/Devices/VirtualDeviceCreateDrawer';
import { VirtualDeviceType } from '../../../../../gql/graphql';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/hardware/access-points/create',
  title: 'Add access point - Access points - Hardware',
});

export default function AccessPointCreatePage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  if (!isOperator) throw new ResourceNotFoundError('Page not found');

  return <VirtualDeviceCreateDrawer deviceType={VirtualDeviceType.AccessPoint} />;
}

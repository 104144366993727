import {
  Badge,
  CompositeField,
  FieldContainer,
  Label,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryField,
  Segment,
  Segments,
  Select,
  SelectItem,
  Textarea,
  TextInput,
  ToggleInput,
} from '@meterup/atto';
import { useFormikContext } from 'formik';

import type { UpdateSecurityAppliancePhyInterfaceValues } from '../SecurityAppliance/utils2';
import type { ValidPhyInterfaceParams, VLANQueryResult } from './utils';
import {
  FieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
} from '../../Form/FieldProvider';
import { PercentField, ToggleField } from '../../Form/Fields';
import { FormikConditional } from '../../FormikConditional';
import { vlanHasStaticIP } from '../../NetworkWide/VLANs/utils';
import { portSpeedToLabel, ValidBridgePriorities, ValidPortSpeeds } from './utils';

export function StormControlBroadcastField({
  internal,
  supportsIndeterminate,
}: {
  internal?: boolean;
  supportsIndeterminate?: boolean;
}) {
  const { values } = useFormikContext<ValidPhyInterfaceParams>();
  const indeterminate =
    supportsIndeterminate && values.stormControlBroadcastTrafficPercent === undefined;

  return (
    <PercentField
      name="stormControlBroadcastTrafficPercent"
      label="Broadcast"
      indeterminate={indeterminate}
      internal={internal}
    />
  );
}

export function StormControlUnknownMulticastField({
  internal,
  supportsIndeterminate,
}: {
  internal?: boolean;
  supportsIndeterminate?: boolean;
}) {
  const { values } = useFormikContext<ValidPhyInterfaceParams>();
  const indeterminate =
    supportsIndeterminate && values.stormControlUnknownMulticastTrafficPercent === undefined;

  return (
    <PercentField
      name="stormControlUnknownMulticastTrafficPercent"
      label="Unknown Multicast"
      indeterminate={indeterminate}
      internal={internal}
    />
  );
}

export function StormControlUnknownUnicastField({
  internal,
  supportsIndeterminate,
}: {
  internal?: boolean;
  supportsIndeterminate?: boolean;
}) {
  const { values } = useFormikContext<ValidPhyInterfaceParams>();
  const indeterminate =
    supportsIndeterminate && values.stormControlUnknownUnicastTrafficPercent === undefined;

  return (
    <PercentField
      name="stormControlUnknownUnicastTrafficPercent"
      label="Unknown Unicast"
      indeterminate={indeterminate}
      internal={internal}
    />
  );
}

export function STPBridgePriorityField() {
  return (
    <FieldContainer>
      <NumberFieldProvider name="stpBridgePriority" defaultValue={null}>
        <PrimaryField
          label="STP bridge priority"
          element={
            <Select width="100%">
              {ValidBridgePriorities.map((s) => (
                <SelectItem key={s.toString()}>{s.toString()}</SelectItem>
              ))}
            </Select>
          }
        />
      </NumberFieldProvider>
    </FieldContainer>
  );
}

export function SwitchNameField() {
  return (
    <FieldContainer>
      <FieldProvider name="label">
        <PrimaryField label="Label" element={<TextInput />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function SwitchDescriptionField() {
  return (
    <FieldContainer>
      <FieldProvider name="description">
        <PrimaryField optional label="Description" element={<Textarea />} />
      </FieldProvider>
    </FieldContainer>
  );
}

export function SwitchIsConsoleEnabledField() {
  return (
    <FieldContainer>
      <ToggleField name="isConsoleEnabled" label="Enable console port" internal />
    </FieldContainer>
  );
}

export function ForcedPortSpeedField({
  maxSpeed = 1000,
  supportsIndeterminate,
  children,
}: {
  maxSpeed: number;
  supportsIndeterminate?: boolean;
  children?: React.ReactNode;
}) {
  const { values } = useFormikContext<
    ValidPhyInterfaceParams | UpdateSecurityAppliancePhyInterfaceValues
  >();
  const indeterminate = supportsIndeterminate && values.forcedPortSpeedMbps === undefined;
  const speeds = ValidPortSpeeds.filter((s) => s <= maxSpeed);

  const options = [
    {
      key: '',
      label: 'Auto',
    },
    ...speeds.map((s) => ({
      key: s,
      label: portSpeedToLabel(s),
    })),
  ];

  return (
    <FieldContainer>
      <NumberFieldProvider name="forcedPortSpeedMbps" defaultValue={null}>
        <PrimaryField
          label="Speed"
          element={
            <Select width="100%" indeterminate={indeterminate} placeholder="Auto">
              {options.map(({ key, label }) => (
                <SelectItem key={key}>{label}</SelectItem>
              ))}
            </Select>
          }
        />
      </NumberFieldProvider>

      {children}
    </FieldContainer>
  );
}

export function PortTypeField() {
  const { values, setFieldValue } = useFormikContext<ValidPhyInterfaceParams>();
  return (
    <FieldContainer>
      <FieldProvider name="isTrunkPort">
        <Segments>
          <Segment
            active={values.isTrunkPort === true}
            onClick={() => {
              setFieldValue('isTrunkPort', true);
              setFieldValue('isIngressFilteringEnabled', false);
              setFieldValue('frameAcceptTypeFilter', 'ALL');
            }}
          >
            Trunk
          </Segment>
          <Segment
            active={values.isTrunkPort === false}
            onClick={() => {
              setFieldValue('isTrunkPort', false);
              setFieldValue('isIngressFilteringEnabled', true);
              setFieldValue('frameAcceptTypeFilter', 'UNTAGGED_ONLY');
            }}
          >
            Access
          </Segment>
        </Segments>
      </FieldProvider>
    </FieldContainer>
  );
}

export function NativeVLANField({
  vlans,
  supportsIndeterminate,
}: {
  vlans: VLANQueryResult[];
  supportsIndeterminate?: boolean;
}) {
  const { values } = useFormikContext<ValidPhyInterfaceParams>();
  const label = values.isTrunkPort ? 'Native VLAN' : 'VLAN';
  const indeterminate = supportsIndeterminate && values.nativeVLANUUID === undefined;

  return (
    <FieldContainer>
      <FieldProvider name="nativeVLANUUID">
        <PrimaryField
          label={label}
          element={
            <Select aria-label={label} width="100%" indeterminate={indeterminate}>
              {vlans.map((vlan) => (
                <SelectItem key={vlan.UUID}>
                  {vlan.vlanID} - {vlan.name}
                  {!vlanHasStaticIP(vlan) && (
                    <>
                      {' '}
                      <Badge size="small" variant="neutral">
                        Layer 2 only
                      </Badge>
                    </>
                  )}
                </SelectItem>
              ))}
            </Select>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export function AllowedVLANField({
  vlans,
  supportsIndeterminate,
}: {
  vlans: VLANQueryResult[];
  supportsIndeterminate?: boolean;
}) {
  const { values, setFieldValue } = useFormikContext<ValidPhyInterfaceParams>();
  const indeterminate =
    supportsIndeterminate &&
    values.isBoundToAllVLANs === undefined &&
    values.allowedVLANUUIDs === undefined;

  return (
    <FieldContainer>
      <PrimaryField
        label="Allowed VLANs"
        controls={
          <Label aria-label="All">
            All
            <FieldProvider name="isBoundToAllVLANs">
              <CompositeField
                label="Allow all VLANs"
                element={
                  <ToggleInput
                    selected={values.isBoundToAllVLANs === true}
                    onChange={(v) => setFieldValue('isBoundToAllVLANs', v)}
                    aria-label="Allow all VLANs"
                    indeterminate={indeterminate}
                  />
                }
              />
            </FieldProvider>
          </Label>
        }
        element={
          <FormikConditional<ValidPhyInterfaceParams> condition={(v) => !v.isBoundToAllVLANs}>
            <MultiComboBoxFieldProvider name="allowedVLANUUIDs">
              <CompositeField
                label="Allowed VLAN(s)"
                element={
                  <MultiComboBox aria-label="Allowed VLANs" indeterminate={indeterminate}>
                    {vlans.map((vlan) => (
                      <MultiComboBoxItem key={vlan.UUID}>
                        {vlan.vlanID} - {vlan.name}
                        {!vlanHasStaticIP(vlan) && (
                          <>
                            {' '}
                            <Badge size="small" variant="neutral">
                              Layer 2 only
                            </Badge>
                          </>
                        )}
                      </MultiComboBoxItem>
                    ))}
                  </MultiComboBox>
                }
              />
            </MultiComboBoxFieldProvider>
          </FormikConditional>
        }
      />
    </FieldContainer>
  );
}

export function FrameAcceptTypeField({
  supportsIndeterminate,
}: {
  supportsIndeterminate?: boolean;
}) {
  const { values } = useFormikContext<ValidPhyInterfaceParams>();
  const indeterminate = supportsIndeterminate && values.frameAcceptTypeFilter === undefined;

  return (
    <FieldContainer>
      <FieldProvider name="frameAcceptTypeFilter">
        <PrimaryField
          label="Frame accept type"
          internal
          element={
            <Select width="100%" indeterminate={indeterminate}>
              <SelectItem key="ALL">All</SelectItem>
              <SelectItem key="TAGGED_ONLY">Tagged only</SelectItem>
              <SelectItem key="UNTAGGED_ONLY">Untagged only</SelectItem>
            </Select>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import NOSFeatureDetailDrawer from '../../../../components/NOS/NOSFeatureDetailDrawer';
import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';

export const Meta = () => ({
  path: '/operator/nos/features/:uuid',
  title: 'NOS feature - operator',
});

export default function NOSFeatureDetailPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  const { uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.NOSFeatureDetailPage),
  );

  return <NOSFeatureDetailDrawer uuid={uuid} />;
}

import { Drawer, DrawerHeader, Tab } from '@meterup/atto';
import React from 'react';

import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import EditUser from './EditUser';
import EditUserRoles from './EditUserRoles';

export default function EditUserDrawer({ uuid }: { uuid: string }) {
  const onClose = useCloseDrawerCallback();
  const [tab, setTab] = React.useState<'user' | 'role'>('user');

  return (
    <Drawer>
      <DrawerHeader
        icon="pencil"
        heading="Edit user"
        onClose={onClose}
        tabs={
          <>
            <Tab icon="user" onClick={() => setTab('user')} selected={tab === 'user'}>
              Details
            </Tab>
            <Tab icon="padlock" onClick={() => setTab('role')} selected={tab === 'role'}>
              Role
            </Tab>
          </>
        }
      />
      {tab === 'user' && <EditUser uuid={uuid} />}
      {tab === 'role' && <EditUserRoles uuid={uuid} />}
    </Drawer>
  );
}

import { DockSearchTarget } from '@meterup/atto';
import { useCommand } from '@meterup/command';
import React from 'react';

export interface CommandBarDialogProps {
  /** OnClick event of the item. */
  onClick?: (event: any) => void;
}

export function CommandBarDialog({ onClick }: CommandBarDialogProps) {
  const { state } = useCommand();
  const handleOnClick = (event: any) => {
    if (onClick) onClick(event);

    state.ui.toggle();
  };

  return (
    <DockSearchTarget
      onClick={handleOnClick}
      tabIndex={0}
      onKeyDown={(event: any) => {
        if (event.key === ' ') {
          event.preventDefault();
          handleOnClick(event);
        }
      }}
      aria-label="Open Search"
      label="Search"
    />
  );
}

import { EmptyState, Pane, PaneContent, PaneHeader } from '@meterup/atto';
import { Navigate } from 'react-router';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import IsPermitted from '../../permissions/IsPermitted';
import { AddSecurityApplianceButton } from './SecurityApplianceActions';
import useCurrentControllers from './useCurrentControllers';
import { SecurityApplianceDetailsTab } from './utils2';

export default function SecurityApplianceEmptyState() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const { virtualDevices } = useCurrentControllers();

  if (virtualDevices.length) {
    return (
      <Navigate
        to={makeLink(paths.pages.SecurityApplianceDetailPage, {
          companyName,
          networkSlug: network.slug,
          uuid: virtualDevices[0].UUID,
          tab: SecurityApplianceDetailsTab.Insights,
        })}
      />
    );
  }

  return (
    <Pane>
      <PaneHeader
        icon="security-appliance"
        heading="Security appliances"
        actions={
          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
                  permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
                  nosFlags[NosFeature.COS2],
              )
            }
          >
            <AddSecurityApplianceButton />
          </IsPermitted>
        }
      />
      <PaneContent>
        <EmptyState
          icon="security-appliance"
          heading="No security appliances found for this network"
          action={
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
                    permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
                    nosFlags[NosFeature.COS2],
                )
              }
            >
              <AddSecurityApplianceButton />
            </IsPermitted>
          }
        />
      </PaneContent>
    </Pane>
  );
}

import type { BadgeVariant, OverlayTriggerState } from '@meterup/atto';
import type { GraphQLError } from 'graphql/error';
import type { ClientError } from 'graphql-request';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  EmptyState,
  MinimalCheckboxField,
  Section,
  SectionContent,
  SectionHeader,
  space,
  VStack,
} from '@meterup/atto';
import { AutoTable, checkDefinedOrThrow, isDefined, notify } from '@meterup/common';
import { getGraphQLError, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

import type { AcsJobsForNetworkQueryQuery } from '../../gql/graphql';
import { graphql } from '../../gql';
import { AutoChannelSelectionResultType, PermissionType } from '../../gql/graphql';
import { useNetwork, useNetworkUUID } from '../../hooks/useNetworkFromPath';
import { usePermissions } from '../../providers/PermissionsProvider';
import { fromISOtoLocaleString } from '../../utils/time';
import { APWOS2DeviceTarget } from '../DeviceTargets';
import { NoValue } from '../NoValue';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { AccessPointsQuery } from '../Wireless/utils';

const RunACSForAccessPointMutation = graphql(`
  mutation RunACSForAccessPointMutation($virtualDeviceUUID: UUID!) {
    initializeAutoChannelSelectionForAccessPoint(
      virtualDeviceUUID: $virtualDeviceUUID
      input: { bands: BAND_5G }
    ) {
      UUID
      scheduledAt
      timeoutAt
    }
  }
`);

const RunACSForNetworkMutation = graphql(`
  mutation RunACSForNetworkMutation($networkUUID: UUID!) {
    initializeAutoChannelSelectionForNetwork(networkUUID: $networkUUID, input: { bands: BAND_5G }) {
      UUID
      scheduledAt
      timeoutAt
    }
  }
`);

const ACSJobsForNetworkQuery = graphql(`
  query ACSJobsForNetworkQuery($networkUUID: UUID!) {
    autoChannelSelectionJobsForNetwork(networkUUID: $networkUUID) {
      UUID
      scheduledAt
      timeoutAt
      completedAt
      virtualDeviceUUIDs
      results {
        timestamp
        virtualDeviceUUID
        resultType
        previous5GHzChannel
        new5GHzChannel
      }
    }
  }
`);

type ACSJob = NonNullable<
  AcsJobsForNetworkQueryQuery['autoChannelSelectionJobsForNetwork']
>[number];

type ACSJobResult = {
  uuid: string;
  label: string;
  previous5GHzChannel: number | null | undefined;
  new5GHzChannel: number | null | undefined;
  resultType: string;
};

enum ACSJobStatus {
  Failed = 'Failed',
  Success = 'Success',
  PartialSuccess = 'Partial Success',
  Timeout = 'Timeout',
  Running = 'Running',
  Scheduled = 'Scheduled',
}

export enum ACSJobType {
  Network = 'network',
  AccessPoint = 'access-point',
}

type AccessPointACSDialogProps = {
  state: OverlayTriggerState;
  label?: string;
  identifier: string;
  jobType: ACSJobType;
};

const acsJobStatus = (job: ACSJob): ACSJobStatus => {
  const numResults = job.results?.length ?? 0;
  const numDevices = job.virtualDeviceUUIDs.length;

  if (job.completedAt && numResults === 0 && numDevices > 0) {
    return ACSJobStatus.Timeout;
  }

  if (job.completedAt || numResults === numDevices) {
    const resultTypes = (job.results ?? []).map((result) => result.resultType);

    if (resultTypes.every((type) => type === AutoChannelSelectionResultType.FailedUnknownReason)) {
      return ACSJobStatus.Failed;
    }

    if (resultTypes.some((type) => type === AutoChannelSelectionResultType.FailedUnknownReason)) {
      return ACSJobStatus.PartialSuccess;
    }

    if (numResults < numDevices) {
      return ACSJobStatus.PartialSuccess;
    }

    return ACSJobStatus.Success;
  }

  if (numResults !== 0 && numResults < numDevices) {
    return ACSJobStatus.Running;
  }

  return ACSJobStatus.Scheduled;
};

const subtableBuilder = createColumnBuilder<ACSJobResult>();

const subtableColumns = [
  subtableBuilder.data((row) => row.label, {
    id: 'device',
    header: 'Device',
    cell: ({ row }) => <APWOS2DeviceTarget uuid={row.uuid} label={row.label} />,
  }),
  subtableBuilder.data((row) => row.previous5GHzChannel, {
    id: 'previous-channel',
    header: 'Previous channel',
    cell: ({ value }) => {
      if (value) {
        return (
          <Badge variant="neutral" size="small">
            {value}
          </Badge>
        );
      }

      return <NoValue />;
    },
  }),
  subtableBuilder.data((row) => row.new5GHzChannel, {
    id: 'new-channel',
    header: 'New channel',
    cell: ({ value }) => {
      if (value) {
        return (
          <Badge variant="neutral" size="small">
            {value}
          </Badge>
        );
      }

      return <NoValue />;
    },
  }),
  subtableBuilder.data((row) => row.resultType, {
    id: 'result',
    header: 'Result',
    cell: ({ value }) => {
      let badgeVariant: BadgeVariant = 'neutral';

      if (value === 'Success') {
        badgeVariant = 'positive';
      } else if (value === 'Failure' || value === 'Timeout') {
        badgeVariant = 'negative';
      }

      return (
        <Badge variant={badgeVariant} size="small" ends="pill">
          {value}
        </Badge>
      );
    },
  }),
];

const builder = createColumnBuilder<ACSJob>();

const columns = [
  builder.data((row) => row.results?.length ?? 0, {
    id: 'devices',
    header: 'Devices updated',
    cell: ({ row }) => (
      <Badge variant="neutral" size="small">
        {row.results?.length ?? 0}
      </Badge>
    ),
  }),
  builder.data((row) => row.virtualDeviceUUIDs.length, {
    id: 'scope',
    header: 'Scope',
    cell: ({ row }) => (
      <Badge variant="neutral" ends="pill" size="small">
        {row.virtualDeviceUUIDs.length === 1 ? 'Access point' : 'Network'}
      </Badge>
    ),
  }),
  builder.data((row) => acsJobStatus(row), {
    id: 'status',
    header: 'Status',
    cell: ({ value }) => {
      let badgeVariant: BadgeVariant = 'neutral';

      if (value === ACSJobStatus.Success) {
        badgeVariant = 'positive';
      }
      if (value === ACSJobStatus.Timeout || value === ACSJobStatus.Failed) {
        badgeVariant = 'negative';
      }
      if (value === ACSJobStatus.PartialSuccess) {
        badgeVariant = 'attention';
      }

      return (
        <Badge variant={badgeVariant} size="small" ends="pill">
          {value}
        </Badge>
      );
    },
  }),
  builder.data(
    (row) => fromISOtoLocaleString({ iso: row.scheduledAt, format: DateTime.DATETIME_MED }),
    {
      meta: {
        alignment: 'end',
      },
      id: 'scheduledAt',
      header: 'Date & time',
    },
  ),
];

function Subtable({ data: job, ...rest }: { data: ACSJob }) {
  const network = useNetwork();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const accessPoints = useGraphQL(AccessPointsQuery, {
    networkUUID: network.UUID,
    includeUptime,
  });

  const apMap = useMemo(
    () =>
      new Map<string, string>(
        (accessPoints.data?.virtualDevicesForNetwork ?? []).map((ap) => [ap.UUID, ap.label]),
      ),
    [accessPoints.data?.virtualDevicesForNetwork],
  );

  if ((job.results ?? []).length === 0) {
    return <EmptyState heading="No devices updated" />;
  }

  const data: ACSJobResult[] = (job.results ?? []).map((result) => {
    const apLabel = apMap.get(result.virtualDeviceUUID);

    const resultTypeToHuman = (resultType: AutoChannelSelectionResultType) => {
      switch (resultType) {
        case AutoChannelSelectionResultType.FailedUnknownReason:
          return 'Failure';
        case AutoChannelSelectionResultType.ChannelSelectedMinBss:
          return 'Min. BSS selected';
        case AutoChannelSelectionResultType.ChannelSelectedRandom:
          return 'Random';
      }
      return 'Success';
    };

    return {
      uuid: result.virtualDeviceUUID,
      label: apLabel ?? result.virtualDeviceUUID,
      previous5GHzChannel: result.previous5GHzChannel,
      new5GHzChannel: result.new5GHzChannel,
      resultType: resultTypeToHuman(result.resultType),
    };
  });

  const timeouts = job.virtualDeviceUUIDs.filter((uuid) => !data.find((d) => d.uuid === uuid));

  timeouts.forEach((uuid) => {
    data.push({
      uuid,
      label: apMap.get(uuid) ?? uuid,
      previous5GHzChannel: undefined,
      new5GHzChannel: undefined,
      resultType: 'Timeout',
    });
  });

  return (
    <AutoTable
      key={JSON.stringify(data)}
      {...rest}
      isNested
      data={data}
      columns={subtableColumns}
    />
  );
}

function RecentJobs({ jobs }: { jobs: ACSJob[] }) {
  return (
    <Section>
      <SectionHeader heading="Recent jobs" icon="log" />
      <SectionContent>
        <AutoTable data={jobs} columns={columns} renderSubTable={Subtable} />
      </SectionContent>
    </Section>
  );
}

function ACSScheduled({ timeoutAt }: { timeoutAt: string }) {
  return (
    <Alert
      variant="brand"
      copy={`ACS scheduled to be done by ${fromISOtoLocaleString({ iso: timeoutAt })}.`}
    />
  );
}

function RunACSSection({ state, label, identifier, jobType }: AccessPointACSDialogProps) {
  const networkUUID = checkDefinedOrThrow(useNetworkUUID());
  const [confirmed, setConfirmed] = useState(false);
  const apMutation = useGraphQLMutation(RunACSForAccessPointMutation);
  const networkMutation = useGraphQLMutation(RunACSForNetworkMutation);
  const [error, setError] = useState<GraphQLError | undefined>();

  const buttonLabel =
    jobType === ACSJobType.AccessPoint
      ? `Run ACS on ${label}`
      : 'Run ACS on every access point with ACS enabled';

  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const accessPoints = useGraphQL(AccessPointsQuery, { networkUUID, includeUptime }).data
    ?.virtualDevicesForNetwork;

  const hasValidAP = useMemo(
    () =>
      (accessPoints ?? []).find((ap) => {
        if (ap.__typename !== 'AccessPointVirtualDevice') return false;

        if (jobType === ACSJobType.AccessPoint) {
          return ap.UUID === identifier && ap.radioProfile?.band5GAutoChannelIsEnabled;
        }

        return ap.radioProfile?.band5GAutoChannelIsEnabled;
      }),
    [accessPoints, identifier, jobType],
  );

  const closeDialog = () => {
    setError(undefined);
    state.close();
  };

  const schedule = () => {
    const onError = (err: ClientError) => {
      const gqlErr = getGraphQLError(err);
      setError(gqlErr);
    };

    const handleSuccess = (timeoutAt: string) => {
      const time = fromISOtoLocaleString({
        iso: timeoutAt,
      });
      notify(
        `ACS scheduled to be done by ${time} on this ${jobType === ACSJobType.AccessPoint ? 'access point' : 'network'}`,
        {
          variant: 'positive',
        },
      );
      closeDialog();
    };

    if (jobType === ACSJobType.Network) {
      networkMutation.mutate(
        { networkUUID: identifier },
        {
          onSuccess: (data) => {
            handleSuccess(data.initializeAutoChannelSelectionForNetwork.timeoutAt);
          },
          onError,
        },
      );
    } else {
      apMutation.mutate(
        { virtualDeviceUUID: identifier },
        {
          onSuccess: (data) => {
            handleSuccess(data.initializeAutoChannelSelectionForAccessPoint.timeoutAt);
          },
          onError,
        },
      );
    }
  };

  return (
    <>
      {isDefined(error) && (
        <Alert
          icon="warning"
          variant="negative"
          heading="Failed to schedule ACS on access point"
          copy={`There was an error scheduling ACS: ${error.message ?? 'Unknown error'}`}
          relation="standalone"
        />
      )}
      <VStack align="stretch" spacing={space(8)}>
        {hasValidAP ? (
          <Alert
            variant="neutral"
            copy="You can run a one-time ACS event to try and fix any issues you’re experiencing. Please be aware, this may result in a degraded experience until the ACS event is completed."
            trailingButtons={
              <VStack spacing={space(8)} width="full">
                <MinimalCheckboxField
                  onChange={setConfirmed}
                  label="I understand that running ACS can cause a network disruption"
                />
                <Button
                  variant="primary"
                  size="medium"
                  onClick={schedule}
                  icon="play"
                  width="100%"
                  arrangement="leading-icon"
                  disabled={!confirmed}
                  loading={networkMutation.isLoading || apMutation.isLoading}
                >
                  {buttonLabel}
                </Button>
              </VStack>
            }
          />
        ) : (
          <Alert
            variant="attention"
            copy={
              jobType === ACSJobType.AccessPoint
                ? 'This access point is not on a radio profile with ACS enabled.'
                : 'No access points on this network are on a radio profile with ACS enabled.'
            }
          />
        )}
      </VStack>
    </>
  );
}

export function ACSDialog({ state, label, identifier, jobType }: AccessPointACSDialogProps) {
  const network = useNetwork();

  const jobs = useGraphQL(ACSJobsForNetworkQuery, { networkUUID: network.UUID }).data
    ?.autoChannelSelectionJobsForNetwork;

  const pendingJob = useMemo(() => {
    const pendingJobs = (jobs ?? []).filter((job) => !job.completedAt);

    if (jobType === ACSJobType.Network) {
      return pendingJobs[0];
    }

    return pendingJobs.find((job) => job.virtualDeviceUUIDs.includes(identifier));
  }, [jobs, identifier, jobType]);

  return (
    <Dialog state={state} preset="intermediate">
      <DialogHeader heading="Auto channel selection (ACS)" icon="channel-cycle" />
      <DialogContent gutter="all">
        <VStack spacing={space(12)}>
          {pendingJob ? (
            <ACSScheduled timeoutAt={pendingJob.timeoutAt} />
          ) : (
            <RunACSSection label={label} state={state} identifier={identifier} jobType={jobType} />
          )}
          {jobs && <RecentJobs jobs={jobs.slice(0, 10)} />}
        </VStack>
      </DialogContent>
      <DialogFooter
        actions={
          <Button variant="secondary" onClick={state.close}>
            Close
          </Button>
        }
      />
    </Dialog>
  );
}

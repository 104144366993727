import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { BulkNosUpgradesFilterInput, BulkNosUpgradesQueryQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';

export const BulkNOSUpgradesQuery = graphql(`
  query BulkNOSUpgradesQuery($filter: BulkNOSUpgradesFilterInput!) {
    bulkNOSUpgrades(filter: $filter) {
      UUID
      createdAt
      updatedAt
      updatedBy
      nosVersionID
      networks {
        UUID
        label
      }
    }
  }
`);

export type BulkNosUpgrade = BulkNosUpgradesQueryQuery['bulkNOSUpgrades'][number];

export function useBulkNOSUpgrades(
  filter: BulkNosUpgradesFilterInput,
): BulkNosUpgradesQueryQuery['bulkNOSUpgrades'] {
  const { data } = useGraphQL(BulkNOSUpgradesQuery, { filter });
  return useMemo(() => data?.bulkNOSUpgrades ?? [], [data]);
}

import type { SortingState } from '@tanstack/react-table';
import {
  Badge,
  Button,
  EmptyState,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  SearchInput,
  Small,
  space,
} from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { DateTime } from 'luxon';

import type { VPNClientWithPeerInfo } from '../api/types';
import { paths } from '../constants';
import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';
import { Nav } from '../nav';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../utils/main_and_drawer_navigation';
import { isActive } from '../utils/vpnClientLists';
import { ReactRouterLink } from './ReactRouterLink';
import { createColumnBuilder } from './Table/createColumnBuilder';
import { createFilterEnumeration } from './Table/createFilterEnumeration';

const builder = createColumnBuilder<VPNClientWithPeerInfo>();

const columns = [
  builder.data((d) => `${isActive(d)} ? 'Active' : 'Inactive'}`, {
    id: 'status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: (p) => (
      <Badge
        arrangement="hidden-label"
        variant={isActive(p.row) ? 'positive' : 'neutral'}
        icon={isActive(p.row) ? 'checkmark' : 'cross'}
        size="small"
        ends="pill"
      >
        {isActive(p.row) ? 'Active' : 'Inactive'}
      </Badge>
    ),
  }),
  builder.data((row) => row.vpnClient.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((row) => row.vpnClient.user_email ?? '', {
    id: 'user',
    header: 'User',
  }),
  builder.data((row) => row.vpnClient.ip_address, {
    id: 'ip',
    header: 'IP',
    meta: { width: 120 },
    cell: (props) => <Small family="monospace">{props.value}</Small>,
  }),
  builder.data((row) => row.vpnClient.created_at ?? '', {
    id: 'created',
    header: `Created`,
    meta: {},
    cell: (p) => <Small>{DateTime.fromISO(p.value).toLocaleString(DateTime.DATETIME_MED)}</Small>,
  }),
];

const Filters = createFilterEnumeration<VPNClientWithPeerInfo>(
  [
    {
      key: 'active',
      label: 'Active',
      predicate: isActive,
    },
  ],
  {
    urlKey: 'tab',
  },
);

function EmptyStates({
  clientCount,
  filteredClientCount,
  onResetFilters,
}: {
  clientCount: number;
  filteredClientCount: number;
  onResetFilters: () => void;
}) {
  if (clientCount === 0) {
    return <EmptyState icon="client" heading="There are no VPN clients on this network" />;
  }

  if (filteredClientCount === 0) {
    return (
      <EmptyState
        icon="filter"
        heading="Your filter returned no results"
        action={
          <Button icon="minus" arrangement="leading-icon" onClick={onResetFilters}>
            Reset filters
          </Button>
        }
      />
    );
  }

  return null;
}

export function LegacyVPNClientsList({
  clients,
  showTabSwitcher = true,
}: {
  clients: VPNClientWithPeerInfo[];
  showTabSwitcher?: boolean;
}) {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.VPNClientSummaryPage);

  const closeDrawer = useCloseDrawerCallback();
  const filteredClients = Filters.useCurrentPredicate(clients);
  const resetFilter = Filters.useResetCallback();

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return (
    <Pane>
      <PaneHeader
        icon="client"
        heading="Clients"
        tabs={showTabSwitcher && <Filters.TabSwitcher />}
        contentActions={
          <>
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(window.location, paths.drawers.AddClientForm, {
                controllerName,
                companyName,
              })}
              variant="secondary"
              icon="plus"
              condense
              size="small"
              arrangement="leading-icon"
            >
              Add client
            </Button>
            <SearchInput
              placeholder="..."
              aria-label="Filter clients"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent gutter="bottom">
        {filteredClients.length > 0 && (
          <AutoTable
            columns={columns}
            data={filteredClients}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            globalFilter={globalFilter}
            getLinkTo={(row) =>
              makeDrawerLink(window.location, paths.drawers.VPNClientSummaryPage, {
                sid: row.vpnClient.sid,
                controllerName,
                companyName,
              })
            }
            isRowSelected={(row) => row.vpnClient.sid === drawerParams?.sid}
            onRowDeselect={closeDrawer}
          />
        )}
        <EmptyStates
          clientCount={clients.length}
          filteredClientCount={filteredClients.length}
          onResetFilters={resetFilter}
        />
      </PaneContent>
    </Pane>
  );
}

import type { SortingState } from '@meterup/common';
import { Badge, EmptyState, Icon, Small, space, Tooltip } from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

import type { MeterLDFlags } from '../../../../feature_flags';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { getRealm, Realm } from '../../../../utils/realm';
import { createColumnBuilder } from '../../../Table/createColumnBuilder';
import { WorkbenchPaneContent } from '../WorkbenchPane';

type Flag = {
  key: string;
  name: string;
  kind: string;
  description: string;
  status: any;
  creationDate: number;
};

type APIResponse = {
  items?: Flag[];
};

const requestOptions = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${import.meta.env.LAUNCHDARKLY_API_TOKEN}`,
  },
};

function getLdApiUrl() {
  const realm = getRealm();
  const ldEnv = realm === Realm.PRODUCTION ? 'production' : 'staging';
  return `https://app.launchdarkly.com/api/v2/flags/default?env=${ldEnv}&limit=100&offset=0`;
}

async function fetchLdFlags(): Promise<APIResponse | null> {
  const resp = await fetch(getLdApiUrl(), requestOptions);
  if (resp.ok) {
    return (await resp?.json()) || [];
  }
  return null;
}

// TODO - paginate this
function useLdFlags() {
  const sdkClientFlags = useFeatureFlags();
  const network = useNetwork();
  const currentCompany = useCurrentCompany();
  const resp = useQuery(['ld_flags', currentCompany, network.UUID], () => fetchLdFlags(), {
    suspense: true,
  });
  return useMemo(
    () =>
      resp?.data?.items
        ?.filter((item) => item.key in sdkClientFlags)
        .map((flag) => ({
          ...flag,
          status: sdkClientFlags[flag.key as keyof MeterLDFlags],
        })) ?? [],
    [resp, sdkClientFlags],
  );
}

const builder = createColumnBuilder<Flag>();
export const columns = [
  builder.data((d) => (d.status ? 'Enabled' : 'Disabled'), {
    enableGlobalFilter: false,
    id: 'status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: ({ row }) => {
      if (typeof row.status === 'boolean') {
        return (
          <Tooltip asChild={false} contents={row.status ? 'Enabled' : 'Disabled'}>
            <Badge
              arrangement="hidden-label"
              size="small"
              ends="pill"
              icon="checkmark"
              variant={row.status ? 'positive' : 'negative'}
            >
              {row.status ? 'Enabled' : 'Disabled'}
            </Badge>
          </Tooltip>
        );
      }
      return (
        <Tooltip asChild={false} contents={JSON.stringify(row.status)}>
          <Badge
            arrangement="hidden-label"
            size="small"
            ends="pill"
            icon="code"
            variant={!Array.isArray(row.status) && row.status ? 'positive' : 'neutral'}
          >
            Variant
          </Badge>
        </Tooltip>
      );
    },
  }),
  builder.data((d) => d.key ?? '', {
    id: 'key',
    header: 'Key',
    cell: ({ value }) => (
      <Badge size="small" ends="pill" variant="neutral" arrangement="leading-icon" icon="flag">
        {value}
      </Badge>
    ),
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.kind ?? '', {
    id: 'kind',
    header: 'Kind',
    cell: ({ value }) => <Small>{value}</Small>,
  }),
  builder.data((d) => d.name ?? '', {
    id: 'name',
    header: 'Name',
    cell: ({ value }) => <Small>{value}</Small>,
  }),
  builder.data((d) => DateTime.fromMillis(d.creationDate).toISO(), {
    id: 'created',
    header: `Created`,
    cell: ({ value }) => (
      <Small>{DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)}</Small>
    ),
  }),
  builder.data((d) => d.description ?? '', {
    id: 'description',
    header: 'Description',
    cell: ({ value }) => <Small>{value}</Small>,
  }),
];

export default function NetworkLDFlags() {
  const flags = useLdFlags();
  const [sortingState, setSortingState] = useState<SortingState>([]);
  return (
    <WorkbenchPaneContent gutter="bottom">
      {flags.length > 0 ? (
        <AutoTable
          columns={columns}
          data={flags}
          sortingState={sortingState}
          onChangeSortingState={(state) => setSortingState(state)}
        />
      ) : (
        <EmptyState icon="flag" heading="Unable to load LaunchDarkly feature flags." />
      )}
    </WorkbenchPaneContent>
  );
}

import { Button, DrawerContent, DrawerFooter, PrimaryField, TextInput } from '@meterup/atto';

import { FieldProvider } from '../../../../Form/FieldProvider';

type FormProps = {
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function WebhookForm({ onCancel }: FormProps) {
  return (
    <>
      <DrawerContent>
        <FieldProvider name="input.label">
          <PrimaryField label="Label" element={<TextInput />} />
        </FieldProvider>
        <FieldProvider name="input.url">
          <PrimaryField label="URL" element={<TextInput />} />
        </FieldProvider>
        <FieldProvider name="input.signingSecret">
          <PrimaryField label="Secret" element={<TextInput />} />
        </FieldProvider>
      </DrawerContent>
      <DrawerFooter
        actions={
          <>
            <Button type="button" onClick={onCancel} variant="secondary">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </>
        }
      />
    </>
  );
}

import { styled } from '@meterup/atto';

import { FlexCentered, RoundedGrayBorderStyle } from '../../styles';

export const DetailBoxWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$20',
  maxWidth: 360,

  '& > a:hover': {
    textDecoration: 'none',
  },
});

export const PlainSection = styled('div', RoundedGrayBorderStyle, {});

export const TitleBar = styled('div', {
  padding: '$8 $16',
  display: 'flex',
  gap: '$16',
  alignItems: 'center',
});

export const TitleBarComponentLeft = styled('div', {});

export const TitleBarComponentMain = styled('div', {
  flex: 1,
});

export const TitleBarComponentRight = styled('div', {});

export const TitleBarH5 = styled('h5', FlexCentered);

export const LinkWithIcon = styled('a', FlexCentered, {
  color: '$gray-700',
  fontSize: '$14',
  lineHeight: '$20',
  fontWeight: '$400',
});

import type { MeterV1NetworkVLANStaticClient } from '@meterup/config';
import type { SortingState } from '@tanstack/react-table';
import {
  Badge,
  Button,
  EmptyState,
  Icon,
  Pane,
  PaneContent,
  PaneHeader,
  SearchInput,
  Small,
  space,
} from '@meterup/atto';
import {
  AutoTable,
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefined,
  ResourceNotFoundError,
} from '@meterup/common';
import { first, groupBy, mapValues, orderBy } from 'lodash-es';
import { useMemo } from 'react';

import type { NetworkClient } from '../../../../../hooks/networkClients/useNetworkClients';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useVLANClients } from '../../../../../hooks/useVLANClients';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentControllerData } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

interface ClientAndModel {
  client: NetworkClient | null;
  model: MeterV1NetworkVLANStaticClient;
}

const builder = createColumnBuilder<ClientAndModel>();

const columns = [
  builder.data((row) => (isDefined(row.client) ? 'Online' : 'Offline'), {
    enableGlobalFilter: false,
    id: 'status',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: (p) =>
      isDefined(p.row.client) ? (
        <Badge
          arrangement="hidden-label"
          variant="positive"
          icon="checkmark"
          size="small"
          ends="pill"
        >
          Online
        </Badge>
      ) : (
        <Badge arrangement="hidden-label" variant="neutral" icon="cross" size="small" ends="pill">
          Offline
        </Badge>
      ),
  }),
  builder.data((d) => d.model.name, {
    id: 'name',
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.model.macAddress, {
    id: 'mac',
    header: 'MAC',
    meta: { width: 136 },
    cell: (p) => <Small family="monospace">{p.value}</Small>,
  }),
  builder.data((d) => d.model.ipAddress, {
    id: 'ip-assignment',
    header: 'IP assignment',
    cell: (p) => <Small family="monospace">{p.value}</Small>,
  }),
];

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/static',
});

export default function VLANStaticIPListPage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANStaticIPListPage),
  );
  const controller = useCurrentControllerData();
  const controllerName = controller.name;
  const companyName = useCurrentCompany();
  const model = useVLANConfigModel(controllerName, vlanName);
  const clients = useVLANClients(controller, vlanName);

  const clientsByMAC = useMemo(
    () =>
      mapValues(
        groupBy(clients, (d) => d.macAddress),
        first,
      ),
    [clients],
  );

  expectDefinedOrThrow(
    model,
    new ResourceNotFoundError(`Configuration for controller ${controllerName} not found`),
  );

  const params = Nav.useRegionParams('drawer', paths.drawers.VLANStaticIPEditPage);
  const onRowDeselect = useCloseDrawerCallback();

  const sortedModelsWithClientsJoined = useMemo((): ClientAndModel[] => {
    const sortedModels = orderBy(model.staticClients, (c) => c.ip?.bigInteger());
    return sortedModels.map((d) => ({
      client: clientsByMAC[d.macAddress] ?? null,
      model: d,
    }));
  }, [clientsByMAC, model.staticClients]);

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return (
    <Pane>
      <PaneHeader
        actions={
          <>
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(window.location, paths.drawers.VLANStaticIPCreatePage, {
                vlanName,
                controllerName,
                companyName,
              })}
              variant="secondary"
              icon="plus"
              condense
              size="small"
              arrangement="leading-icon"
            >
              Add static IP
            </Button>
            <SearchInput
              placeholder="..."
              aria-label="Filter static IPs"
              scope="scoped"
              value={globalFilter}
              onChange={setGlobalFilter}
              minWidth="56px"
            />
          </>
        }
      />
      <PaneContent>
        {sortedModelsWithClientsJoined.length > 0 ? (
          <AutoTable
            data={sortedModelsWithClientsJoined}
            columns={columns}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            globalFilter={globalFilter}
            onRowDeselect={onRowDeselect}
            isRowSelected={(d) => d.model.macAddress === params?.macAddress}
            getLinkTo={(d) =>
              makeDrawerLink(window.location, paths.drawers.VLANStaticIPEditPage, {
                vlanName,
                macAddress: d.model.macAddress,
                controllerName,
                companyName,
              })
            }
          />
        ) : (
          <EmptyState
            icon="vlan"
            heading="No static IPs defined"
            action={
              <Button
                as={ReactRouterLink}
                to={makeDrawerLink(window.location, paths.drawers.VLANStaticIPCreatePage, {
                  vlanName,
                  controllerName,
                  companyName,
                })}
                icon="plus"
                arrangement="leading-icon"
              >
                Add static IP
              </Button>
            }
          />
        )}
      </PaneContent>
    </Pane>
  );
}

import {
  ControlGroup,
  darkThemeSelector,
  Icon,
  shadows,
  sizing,
  Small,
  space,
  styled,
} from '@meterup/atto';
import { colors } from '@meterup/common';
import React from 'react';

const FiltersIcon = styled(Icon, {
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const FilterBarStart = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

const FilterBarEnd = styled('div', {
  display: 'flex',
});

const FilterBarContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$12',
  width: '100%',

  variants: {
    stuck: {
      true: {
        padding: `${sizing.contentEnds} ${sizing.sides}`,
        background: colors.bgApplicationLight,
        boxShadow: shadows.stuckLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
          boxShadow: shadows.stuckDark,
        },
      },
      false: {
        padding: `0 ${sizing.sides} ${sizing.contentEnds}`,
      },
    },
  },
});

type FilterBarProps = {
  children: React.ReactNode;
  /**
   * Boolean to determine if the element is stuck or not.
   */
  stuck?: boolean;
};

export const FilterBar = React.forwardRef<HTMLDivElement, FilterBarProps>(
  ({ children, stuck }, ref) => (
    <FilterBarContainer stuck={stuck} ref={ref}>
      <FilterBarStart>
        <FiltersIcon icon="filter" size={space(12)} />
        <Small weight="bold">Filters</Small>
      </FilterBarStart>
      <FilterBarEnd>
        <ControlGroup relation="separate" size="small">
          {children}
        </ControlGroup>
      </FilterBarEnd>
    </FilterBarContainer>
  ),
);

const useStickyHeader = () => {
  const [isOutOfView, setIsOutOfView] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  // Make the sticky header visible when 70% of the "real" header is out of view.
  React.useLayoutEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      // ensures that the element is rendered with content without display: none; (a result of AppSkeleton)
      if (ref.current?.clientHeight) {
        setIsOutOfView(!entries[0].isIntersecting);
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.3,
      rootMargin: '0px 0px 0px 0px',
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, []);

  return { ref, isOutOfView };
};

const ZeroHeightStickyContainer = styled('div', {
  position: 'sticky',
  zIndex: 2,
  top: '0.5px',
  height: 0,
  opacity: 0,
  transition: 'opacity 150ms ease-out',

  variants: {
    visible: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
});

export function StickyFilterBar(props: FilterBarProps) {
  const { ref, isOutOfView } = useStickyHeader();

  return (
    <>
      <ZeroHeightStickyContainer visible={isOutOfView} aria-hidden="true">
        <FilterBar {...props} stuck />
      </ZeroHeightStickyContainer>
      <FilterBar {...props} stuck={false} ref={ref} />
    </>
  );
}

import { PrimaryField, Select, SelectItem, TextInput } from '@meterup/atto';
import { MeterV1NetworkNATPortForwardProtocol } from '@meterup/config';
import React from 'react';

import type { ValidPortForwardingRule } from './form_data';
import { createFieldProvider } from '../../../../../components/Form/FieldProvider';

const FieldProvider = createFieldProvider<ValidPortForwardingRule>();

function DescriptionField() {
  return (
    <FieldProvider name="description">
      <PrimaryField optional label="Description" element={<TextInput />} />
    </FieldProvider>
  );
}

function InternalIPField() {
  return (
    <FieldProvider name="internalIPAddress">
      <PrimaryField label="Internal IP" element={<TextInput />} />
    </FieldProvider>
  );
}

function InternalPortField() {
  return (
    <FieldProvider name="internalPort">
      <PrimaryField label="Internal port" element={<TextInput />} />
    </FieldProvider>
  );
}

function ExternalPortField() {
  return (
    <FieldProvider name="externalPort">
      <PrimaryField label="External port" element={<TextInput />} />
    </FieldProvider>
  );
}

function ProtocolField() {
  return (
    <FieldProvider name="protocol">
      <PrimaryField
        label="Protocol"
        element={
          <Select>
            <SelectItem key={MeterV1NetworkNATPortForwardProtocol.TCP}>TCP</SelectItem>
            <SelectItem key={MeterV1NetworkNATPortForwardProtocol.UDP}>UDP</SelectItem>
          </Select>
        }
      />
    </FieldProvider>
  );
}

export function FormFields() {
  return (
    <>
      <DescriptionField />
      <ExternalPortField />
      <ProtocolField />
      <InternalIPField />
      <InternalPortField />
    </>
  );
}

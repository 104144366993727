import { Skeleton } from '@meterup/atto';
import * as d3 from 'd3';
import { useMemo } from 'react';

import type { UplinkQualityProps } from './utils';
import { graphql } from '../../../gql';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { TimeSeriesChart } from '../../TimeSeriesChart';
import { percentValueFormatter } from './utils';

export const networkUplinkQualityQuery = graphql(`
  query networkUplinkQuality($networkUUID: UUID!, $filter: MetricsFilterInput!) {
    networkUplinkQuality(networkUUID: $networkUUID, filter: $filter) {
      values {
        timestamp
        value
        phyInterfaceUUID
      }
    }
  }
`);

export default function ISPQualityChart({
  data,
  isError,
  currentUplinkPhyInteface,
  uplinkName,
}: UplinkQualityProps) {
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';

  const parsedDataWithDate = useMemo(
    () =>
      data?.networkUplinkQuality.values
        .filter((val) => val.phyInterfaceUUID === currentUplinkPhyInteface)
        .map(({ timestamp, ...rest }) => ({
          timestamp: new Date(timestamp),
          ...rest,
        })),
    [data, currentUplinkPhyInteface],
  );

  if (parsedDataWithDate) {
    return (
      <TimeSeriesChart
        title={uplinkName ? `${uplinkName} quality` : 'ISP quality'}
        tooltipBody="The percentage of successful pings to common web servers over each ISP connection."
        valueFormatter={percentValueFormatter}
        yTicks={[0, 1]}
        yDomain={[0, 2]}
        series={[
          {
            data: parsedDataWithDate,
            curve: d3.curveStepBefore,
            range: {
              min: 0,
              max: 1,
            },
            series_type: 'area',
            series_id: 'ISPQuality',
            series_name: 'ISP quality',
          },
        ]}
        timePeriod={currentTimePeriod}
        showYAxis
        isError={isError}
      />
    );
  }
  return <Skeleton height={200} width="100%" radius={6} />;
}

import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';

import ReportEdit from '../../../../../../components/Settings/Network/Notifications/Reports/ReportEdit';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/network/:networkSlug/notifications/reports/edit',
  title: 'Edit report - Reports - Notifications - Network',
});

export default function ReportEditPage() {
  const isOperator = useIsOperator();

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found.');
  }

  return <ReportEdit />;
}

import { Alert, Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';

import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { RefreshOrContactSupportBoilerplate } from '../../ErrorFallback/ErrorFallback';
import { NetworkInfo } from './NetworkInfo';

export function AddClientDrawer() {
  return (
    <Drawer>
      <DrawerHeader heading="Wireless info" onClose={useCloseDrawerCallback()} />
      <DrawerContent>
        <ErrorBoundary
          fallback={
            <Alert
              heading="Unexpected error"
              copy={
                <>
                  We were unable to retrieve your network information.{' '}
                  <RefreshOrContactSupportBoilerplate />
                </>
              }
            />
          }
        >
          <NetworkInfo />
        </ErrorBoundary>
      </DrawerContent>
    </Drawer>
  );
}

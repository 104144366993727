import { Drawer, DrawerHeader } from '@meterup/atto';
import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';

import AutoVPNMemberAddEditForm from '../../../components/autoVPN/AutoVPNMemberAddEditForm';
import { AutoVPNGroupQuery } from '../../../components/autoVPN/utils';
import IsPermitted from '../../../components/permissions/IsPermitted';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';

export const Meta = () => ({
  path: '/org/:companyName/network/auto-vpn/groups/:groupUUID/members/add',
  title: 'Add member - Group - Auto VPN - Network',
});

export default function AutoVPNMemberAddPage() {
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AutoVPNMemberAddPage);
  expectDefinedOrThrow(drawerParams?.groupUUID, new ResourceNotFoundError('Group not found.'));

  const group = checkDefinedOrThrow(
    useGraphQL(
      AutoVPNGroupQuery,
      {
        uuid: drawerParams?.groupUUID!,
      },
      {
        enabled: !!drawerParams?.groupUUID,
      },
    )?.data?.autoVPNGroup,
    new ResourceNotFoundError('Group not found'),
  );

  const closeDrawer = useCloseDrawerCallback();

  return (
    <IsPermitted
      should404OnAccessDenied
      allowOperatorAlways={false}
      path={Meta().path}
      isPermitted={({ isOperator, permissions, companyNosFlags, ldFlags }) =>
        permissions.hasPermission(PermissionType.PermAutoVpnWrite) &&
        companyNosFlags[NosFeature.SITE_TO_SITE_VPN] &&
        (isOperator || !!ldFlags['site-to-site-vpn']) &&
        !!drawerParams?.groupUUID &&
        !!group
      }
    >
      <Drawer>
        <DrawerHeader icon="plus" heading="Add member" onClose={closeDrawer} />
        <AutoVPNMemberAddEditForm group={group} />
      </Drawer>
    </IsPermitted>
  );
}

import type { FormikHelpers } from 'formik';
import { Drawer, DrawerHeader } from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { AlertTargetType } from '@meterup/common/src/gql/graphql';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ReceiverAddSchema } from '../utils';
import { paths } from '../../../../../constants';
import { graphql } from '../../../../../gql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import {
  useCurrentCompany,
  useCurrentCompany2,
} from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { receiverAddSchema, useAlertTargetsAllByUUID, useInvalidateAlertReceivers } from '../utils';
import ReceiverForm from './ReceiverForm';

const createReceiverMutation = graphql(`
  mutation CreateAlertReceiverWithTargetsMutation(
    $companyUUID: UUID!
    $input: CreateAlertReceiverWithTargetsInput!
  ) {
    createAlertReceiverWithTargets(companyUUID: $companyUUID, input: $input) {
      UUID
    }
  }
`);

export default function ReceiversAdd() {
  const closeDrawer = useCloseDrawerCallback();
  const mutation = useGraphQLMutation(createReceiverMutation);
  const companyName = useCurrentCompany();
  const company = useCurrentCompany2();
  const navigate = useNavigate();
  const onCancel = useCloseDrawerCallback();
  const invalidateReceivers = useInvalidateAlertReceivers({ companyUUID: company?.uuid });
  const targets = useAlertTargetsAllByUUID({ companyUUID: company?.uuid });

  const buildTargetsInput = useCallback(
    ({ targetUUIDs, emailAddresses }: { targetUUIDs: string[]; emailAddresses: string[] }) => {
      const emailTargets = emailAddresses.map((emailAddress) => ({
        alertTargetType: AlertTargetType.Email,
        emailAddress,
      }));

      const otherTargets = targetUUIDs.map((uuid) => {
        const target = targets.get(uuid);
        expectDefinedOrThrow(target, new ResourceNotFoundError('Target not found'));
        return {
          alertTargetType: target.type,
          alertTargetUUID: target.UUID,
        };
      });

      return [...emailTargets, ...otherTargets];
    },
    [targets],
  );

  const onSubmit = useCallback(
    (values: ReceiverAddSchema, form: FormikHelpers<ReceiverAddSchema>) => {
      const { input } = values;
      mutation.mutate(
        {
          companyUUID: company?.uuid,
          input: {
            label: input.label,
            targets: buildTargetsInput({
              targetUUIDs: input.targetUUIDs,
              emailAddresses: input.emailAddresses,
            }),
          },
        },
        {
          onSuccess() {
            notify('Successfully created receiver', {
              variant: 'positive',
            });
            form.resetForm();
            invalidateReceivers();
            navigate(
              makeLink(paths.pages.ReceiversPage, {
                companyName,
              }),
            );
          },
          onError() {
            notify('Failed to create receiver', {
              variant: 'negative',
            });
          },
        },
      );
    },
    [mutation, company?.uuid, buildTargetsInput, invalidateReceivers, navigate, companyName],
  );

  return (
    <Drawer>
      <DrawerHeader icon="plus" heading="Add receiver" onClose={closeDrawer} />
      <Formik<ReceiverAddSchema>
        initialValues={{
          input: {
            label: '',
            emailAddresses: [],
            targetUUIDs: [],
          },
        }}
        validate={withZodSchema(receiverAddSchema)}
        onSubmit={onSubmit}
      >
        <Form>
          <ReceiverForm onCancel={onCancel} />
        </Form>
      </Formik>
    </Drawer>
  );
}

import type { FormikHelpers } from 'formik';
import { Drawer, DrawerHeader } from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { WebhookAddSchema } from '../utils';
import { paths } from '../../../../../constants';
import { graphql } from '../../../../../gql';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import {
  useCurrentCompany,
  useCurrentCompany2,
} from '../../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { useInvalidateWebhookTargets, webhookAddSchema } from '../utils';
import WebhookForm from './WebhookForm';

const createAlertTargetWebhookMutation = graphql(`
  mutation createAlertTargetWebhook($companyUUID: UUID!, $input: CreateAlertTargetWebhookInput!) {
    createAlertTargetWebhook(companyUUID: $companyUUID, input: $input) {
      UUID
      companyUUID
      signingSecret
      type
      url
      label
    }
  }
`);

export default function WebhooksAdd() {
  const closeDrawer = useCloseDrawerCallback();
  const mutation = useGraphQLMutation(createAlertTargetWebhookMutation);
  const companyName = useCurrentCompany();
  const company = useCurrentCompany2();
  const navigate = useNavigate();
  const onCancel = useCloseDrawerCallback();
  const invalidateWebhooks = useInvalidateWebhookTargets({ companyUUID: company?.uuid });

  const onSubmit = useCallback(
    (values: WebhookAddSchema, form: FormikHelpers<WebhookAddSchema>) => {
      const { input } = values;
      mutation.mutate(
        {
          companyUUID: company?.uuid,
          input: {
            label: input.label,
            url: input.url,
            signingSecret: input.signingSecret,
          },
        },
        {
          onSuccess() {
            notify('Successfully created webook', {
              variant: 'positive',
            });
            form.resetForm();
            invalidateWebhooks();
            navigate(
              makeLink(paths.pages.WebhooksPage, {
                companyName,
              }),
            );
          },
          onError() {
            notify('Failed to create webhook', {
              variant: 'negative',
            });
          },
        },
      );
    },
    [mutation, company?.uuid, invalidateWebhooks, navigate, companyName],
  );

  return (
    <Drawer>
      <DrawerHeader icon="plus" heading="Add webhook" onClose={closeDrawer} />
      <Formik<WebhookAddSchema>
        initialValues={{
          input: {
            label: '',
            url: '',
            signingSecret: '',
          },
        }}
        validate={withZodSchema(webhookAddSchema)}
        onSubmit={onSubmit}
      >
        <Form>
          <WebhookForm onCancel={onCancel} />
        </Form>
      </Formik>
    </Drawer>
  );
}

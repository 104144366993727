import {
  Badge,
  Button,
  colors,
  darkThemeSelector,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  fontWeights,
  selectors,
  shadows,
  Small,
  styled,
  SummaryList,
  Text,
  Tooltip,
  useDialogState,
} from '@meterup/atto';
import { useGraphQL } from '@meterup/graphql';
import { DateTime } from 'luxon';
import { useId, useMemo } from 'react';

import type { InternalNote } from './utils';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import Markdown from '../../../Markdown';
import { WorkbenchPane, WorkbenchPaneContent, WorkbenchPaneHeader } from '../WorkbenchPane';
import { DeleteNoteDialog } from './DeleteNoteDialog';
import { EditNoteDialog } from './EditNoteDialog';
import { NoteAttachmentDialog } from './NoteAttachmentDialog';
import { internalNotesForNetworkQuery } from './utils';

const NoteAttachmentSizer = styled('div', {
  display: 'block',
  width: '100%',
  height: '100%',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '$4',
});

const NoteAttachmentContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$40',
  height: '$40',
  padding: '$4',
  backgroundColor: colors.bgApplicationLight,
  boxShadow: shadows.manufacturerLight,
  borderRadius: '$8',
  cursor: 'pointer',

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    boxShadow: shadows.manufacturerDark,
  },
});

function NoteAttachment({ url }: { url: string }) {
  const uuid = useId();
  const { state: noteAttachmentDialogState } = useDialogState();
  return (
    <NoteAttachmentContainer
      key={`${url}-${uuid}`}
      onClick={() => noteAttachmentDialogState.open()}
    >
      <NoteAttachmentSizer style={{ backgroundImage: `url(${url})` }} />
      <NoteAttachmentDialog state={noteAttachmentDialogState} url={url} />
    </NoteAttachmentContainer>
  );
}

const NoteCopy = styled('div', {
  width: '100%',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',

  '& a': {
    color: colors.linkInitialLight,

    [darkThemeSelector]: {
      color: colors.linkInitialDark,
    },

    [selectors.hover]: {
      color: colors.linkHoverLight,

      [darkThemeSelector]: {
        color: colors.linkHoverDark,
      },
    },
  },

  variants: {
    pinned: {
      true: {
        '& h1, & h2, & h3, & h4': {
          color: colors.headingBrandLight,

          [darkThemeSelector]: {
            color: colors.headingBrandDark,
          },
        },

        '& p, & li, & span': {
          color: colors.bodyBrandLight,

          [darkThemeSelector]: {
            color: colors.bodyBrandDark,
          },
        },
      },
      false: {},
    },
  },
});

const NoteAttachments = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '$6',
});

const NoteAuthor = styled(Small, {
  fontWeight: fontWeights.bold,

  variants: {
    pinned: {
      true: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
      false: {},
    },
  },
});

const NoteMetadataStart = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const NoteDate = styled(Small, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',

  variants: {
    pinned: {
      true: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
    },
  },
});

const NoteDivider = styled('div', {
  width: '$2',
  height: '$12',
  background: colors.strokeNeutralLight,
  borderRadius: '99em',

  [darkThemeSelector]: {
    background: colors.strokeNeutralDark,
  },

  variants: {
    pinned: {
      true: {
        background: colors.strokeBrandLight,

        [darkThemeSelector]: {
          background: colors.strokeBrandDark,
        },
      },
    },
  },
});

const NoteEdits = styled(Small, {
  variants: {
    pinned: {
      true: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
      false: {},
    },
  },
});

const NoteMetadataEnd = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
});

const NoteMetadata = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '$6',
});

const NoteBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  padding: '$8 $12',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },

  variants: {
    pinned: {
      true: {
        background: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,

        [darkThemeSelector]: {
          background: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },
      },
      false: {},
    },
  },
});

const NoteContainer = styled('li', {
  position: 'relative',
  zIndex: 0,
});

function Note({ note }: { note: InternalNote }) {
  const editNoteDialogProps = useDialogState();
  const deleteNoteDialogProps = useDialogState();

  return (
    <NoteContainer>
      <NoteBox pinned={note.isImportant}>
        <NoteCopy pinned={note.isImportant}>
          <Markdown>{note.note}</Markdown>
        </NoteCopy>
        {note.photoURLs && note.photoURLs.length > 0 && (
          <NoteAttachments>{note.photoURLs.map((url) => NoteAttachment({ url }))}</NoteAttachments>
        )}
        <NoteMetadata>
          <NoteMetadataStart>
            <NoteAuthor pinned={note.isImportant}>{note.createdByUser.email}</NoteAuthor>
            <NoteDate pinned={note.isImportant}>
              <Tooltip
                contents={DateTime.fromISO(note.createdAt).toLocaleString(
                  DateTime.DATETIME_FULL_WITH_SECONDS,
                )}
              >
                <Text>{DateTime.fromISO(note.createdAt).toRelative()}</Text>
              </Tooltip>
              {note.updatedAt && note.updatedByUser && (
                <>
                  <NoteDivider pinned={note.isImportant} />
                  <Tooltip
                    contents={
                      <SummaryList
                        pairs={[
                          {
                            label: note.updatedByUser.email,
                            value: DateTime.fromISO(note.updatedAt).toLocaleString(
                              DateTime.DATETIME_FULL_WITH_SECONDS,
                            ),
                          },
                        ]}
                      />
                    }
                  >
                    <NoteEdits pinned={note.isImportant}>Edited</NoteEdits>
                  </Tooltip>
                </>
              )}
            </NoteDate>
          </NoteMetadataStart>
          <NoteMetadataEnd>
            {note.isImportant && (
              <Badge arrangement="leading-icon" icon="bookmark" size="small" variant="brand">
                Pinned
              </Badge>
            )}
            <DropdownMenu>
              <DropdownMenuButton
                arrangement="hidden-label"
                icon="overflow-horizontal"
                size="x-small"
              >
                Actions
              </DropdownMenuButton>
              <DropdownMenuPopover>
                <DropdownMenuGroup>
                  <DropdownMenuItem icon="pencil" onSelect={() => editNoteDialogProps.state.open()}>
                    Edit
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    icon="trash-can"
                    onSelect={() => deleteNoteDialogProps.state.open()}
                  >
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuPopover>
            </DropdownMenu>
            <EditNoteDialog state={editNoteDialogProps.state} note={note} />
            <DeleteNoteDialog state={deleteNoteDialogProps.state} note={note} />
          </NoteMetadataEnd>
        </NoteMetadata>
      </NoteBox>
    </NoteContainer>
  );
}

const NotesList = styled('ul', {
  position: 'relative',
  zIndex: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '$12',
  width: '100%',
  height: '100%',
});

export default function Notes() {
  const network = useNetwork();
  const notes = useGraphQL(internalNotesForNetworkQuery, { networkUUID: network.UUID }).data
    ?.internalNotesForNetwork;

  const sortedNotes = useMemo(
    () =>
      notes?.slice().sort((a, b) => {
        if (a.isImportant && !b.isImportant) return -1;
        if (!a.isImportant && b.isImportant) return 1;
        return (b.updatedAt ?? b.createdAt).localeCompare(a.updatedAt ?? a.createdAt);
      }) ?? [],
    [notes],
  );

  const addNoteDialogProps = useDialogState();

  return (
    <WorkbenchPane>
      <WorkbenchPaneHeader
        icon="note"
        heading="Notes"
        actions={
          <Button
            onClick={() => addNoteDialogProps.state.open()}
            arrangement="hidden-label"
            icon="plus"
            size="x-small"
            variant="secondary"
          >
            Add note
          </Button>
        }
      />
      <WorkbenchPaneContent>
        {sortedNotes?.length ? (
          <NotesList>
            {sortedNotes.map((note) => (
              <Note key={note.UUID} note={note} />
            ))}
          </NotesList>
        ) : (
          <EmptyState
            icon="note"
            heading="No notes"
            action={
              <Button
                onClick={() => addNoteDialogProps.state.open()}
                arrangement="leading-icon"
                icon="plus"
                variant="secondary"
              >
                Add note
              </Button>
            }
          />
        )}
        <EditNoteDialog state={addNoteDialogProps.state} />
      </WorkbenchPaneContent>
    </WorkbenchPane>
  );
}

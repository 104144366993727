import React from 'react';

import { Tab } from '@meterup/atto';
import { Link } from 'react-router-dom';

export type TabItemProps = {
  count: number;
  children: React.ReactNode;
  isActive?: boolean;
  path: string;
};
export function TabItem({ count = 0, isActive = false, children, path }: TabItemProps) {
  return (
    <Tab as={Link} to={path} selected={isActive} annotation={count}>
      {children}
    </Tab>
  );
}

import type { ServiceIconName } from '@meterup/atto';
import {
  Body,
  Button,
  colors,
  ControlGroup,
  darkThemeSelector,
  EmptyState,
  fontWeights,
  Link as AttoLink,
  ServiceIcon,
  sizing,
  styled,
  Text,
} from '@meterup/atto';
import { Link } from 'react-router-dom';

import { paths } from '../../../../../constants';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink, makeLink } from '../../../../../utils/main_and_drawer_navigation';

const ServiceName = styled(Body, {
  fontWeight: fontWeights.bold,
});

const ServiceDescription = styled(Body);

const ServiceLockup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const ServiceActions = styled(ControlGroup);

const Service = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '$12',
  padding: sizing.contentSquish,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$12',

  [darkThemeSelector]: {
    strokeAll: colors.strokeNeutralDark,
  },
});

const ServicesContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
});

const services = (companyName: string) => [
  {
    icon: 'slack',
    name: 'Slack',
    description: (
      <Text>
        Allows alerts to be sent to channels, groups, or as direct messages using{' '}
        <AttoLink
          as={Link}
          to={makeLink(paths.pages.ReceiversPage, {
            companyName,
          })}
        >
          Receivers
        </AttoLink>
        .
      </Text>
    ),
  },
];

export default function Services() {
  const companyName = useCurrentCompany();
  return services.length > 0 ? (
    <ServicesContainer>
      {services(companyName).map((service) => (
        <Service key={service.name}>
          <ServiceIcon icon={service.icon as ServiceIconName} size={40} />
          <ServiceLockup>
            <ServiceName>{service.name}</ServiceName>
            <ServiceDescription>{service.description}</ServiceDescription>
          </ServiceLockup>
          <ServiceActions>
            <Button
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.ServiceEditPage, {
                companyName,
              })}
              arrangement="leading-icon"
              icon="pencil"
              variant="secondary"
            >
              Edit
            </Button>
          </ServiceActions>
        </Service>
      ))}
    </ServicesContainer>
  ) : (
    <EmptyState
      icon="service"
      heading="No services"
      action={
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.ServicesAddPage, {
            companyName,
          })}
          arrangement="leading-icon"
          icon="plus"
          variant="secondary"
        >
          Add service
        </Button>
      }
    />
  );
}

import { useIsOperator } from '@meterup/authorization';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import NOSVersionDetailDrawer from '../../../../components/NOS/NOSVersionDetailDrawer';
import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';

export const Meta = () => ({
  path: '/operator/nos/versions/:nosVersionID',
  title: 'NOS version - operator',
});

export default function NOSVersionDetailPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });

  if (!isOperator) {
    throw new ResourceNotFoundError('Page not found');
  }

  const { nosVersionID } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.NOSVersionDetailPage),
  );

  return <NOSVersionDetailDrawer nosVersionID={nosVersionID} />;
}
